import 'reflect-metadata';
import { jsonArrayMember, jsonMember, jsonObject } from 'typedjson';
import AmgItemDataModel from './amg-item-data.model';
import AmgPagingDataModel from './amg-paging-data.model';

@jsonObject
export default class AmgVideoModel {
	@jsonArrayMember(AmgItemDataModel)
	public itemData: AmgItemDataModel[] = [];
	@jsonMember(AmgPagingDataModel)
	public pagingData: AmgPagingDataModel = {} as AmgPagingDataModel;
}
