export enum ReduxGeneralContentProps {
	CATEGORY = 'category',
	ADDITIONAL_CATEGORIES = 'additionalCategories',
	AUTHORS = 'authors',
	CUSTOM_AUTHOR = 'customAuthor',
	PUBLISHED_AT = 'publishedAt',
	PUBLISHED_UNTIL = 'publishedUntil',
	REGIONS = 'distributionRegions',
	CHANNELS = 'distributionChannels',
	ORIGIN = 'origin',
	STATUS = 'status',
	TYPE = 'type',
	PROPERTIES = 'properties',
}
