import TeamProfileWidgetModel from './team-profile-widget.model';
import { TeamProfileWidgetJson } from './team-profile-widget.json';
import TeamModel from '../../../../partials/team-select/models/team.model';
import SeasonModel from '../../fixtures/models/season/season.model';

export default class TeamProfileWidgetBuilder {
	readonly json: TeamProfileWidgetJson;

	constructor(team?: TeamProfileWidgetModel | TeamProfileWidgetJson) {
		if (team && team instanceof TeamProfileWidgetModel) {
			this.json = team.toJson();
		} else if (team) {
			this.json = team;
		} else {
			this.json = {} as TeamProfileWidgetJson;
		}
	}

	withTeam(team: TeamModel): TeamProfileWidgetBuilder {
		this.json.team = team;

		return this;
	}

	withSeason(season: SeasonModel): TeamProfileWidgetBuilder {
		this.json.season = season;

		return this;
	}

	withDisplayStatistics(displayStatistics: boolean): TeamProfileWidgetBuilder {
		this.json.displayStatistics = displayStatistics;

		return this;
	}

	withDisplayOdds(displayOdds: boolean): TeamProfileWidgetBuilder {
		this.json.displayOdds = displayOdds;

		return this;
	}

	withNoData(noData: boolean): TeamProfileWidgetBuilder {
		this.json.noData = noData;

		return this;
	}

	withThreeLetterCodeType(threeLetterCodeType: any): TeamProfileWidgetBuilder {
		this.json.threeLetterCodeType = threeLetterCodeType;

		return this;
	}

	build(): TeamProfileWidgetModel {
		return TeamProfileWidgetModel.fromJson(this.json);
	}
}
