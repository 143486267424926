import FansUnitedGameModel from '../models/fans-united-game.model';
import FansUnitedHttpService from './fans-united-http.service';
import FansUnitedGameMapper from '../models/fans-united-game.mapper';
import FansUnitedLeaderboardTemplatesModel from '../models/fans-united-leaderboard-templates.model';
import FansUnitedLeaderboardTemplatesMapper from '../models/fans-united-leaderboard-templates.mapper';
import { featuresService } from '../../../../../../../App';
import { FeatureTypes } from '../../../../../../../services/feature-service/features.enum';
import FansUnitedClassicQuizModel from '../models/fans-united-classic-quiz.model';
import FansUnitedClassicQuizMapper from '../models/fans-united-classic-quiz.mapper';
import FansUnitedEitherOrModel from '../models/fans-united-either-or.model';
import FansUnitedEitherOrMapper from '../models/fans-united-either-or.mapper';

export default class FansUnitedService {
	/**
	 * Requests the FU games listing from the fans-united api and returns a Promise<FansUnitedGameModel[]>
	 */
	public getFansUnitedGames = async (type: string, maxGames: number): Promise<FansUnitedGameModel[]> => {
		const games = await FansUnitedHttpService.getGames(type);
		const mapper = new FansUnitedGameMapper();
		if (games && games.data && games.data.data && games.data.data.length > 0) {
			return games.data.data.slice(0, maxGames).map((game: any) => mapper.responseToModel(game));
		} else {
			return [];
		}
	};

	/**
	 * Requests leaderboards templates from the fans-united api and returns a Promise<FansUnitedLeaderboardTemplatesModel[]>
	 */
	public getLeaderboards = async (maxTemplates: number): Promise<FansUnitedLeaderboardTemplatesModel[]> => {
		const leaderboards = await FansUnitedHttpService.getLeaderboardTemplates();
		const mapper = new FansUnitedLeaderboardTemplatesMapper();
		if (leaderboards && leaderboards.data && leaderboards.data.data && leaderboards.data.data.length > 0) {
			return leaderboards.data.data.slice(0, maxTemplates).map((leaderboard: any) => mapper.responseToModel(leaderboard));
		} else {
			return [];
		}
	};

	/**
	 * Requests classic quizzes templates from the fans-united  mini-games api and returns a Promise<FansUnitedClassicQuizModel[]>
	 */
	public getClassicQuizzes = async (maxQuizzes: number): Promise<FansUnitedClassicQuizModel[]> => {
		const ClassicQuizzes = await FansUnitedHttpService.getClassicQuizzes();
		const mapper = new FansUnitedClassicQuizMapper();
		if (ClassicQuizzes && ClassicQuizzes.data && ClassicQuizzes.data.data && ClassicQuizzes.data.data.length > 0) {
			return ClassicQuizzes.data.data.slice(0, maxQuizzes).map((quiz: Response) => mapper.responseToModel(quiz));
		} else {
			return [];
		}
	};

	/**
	 * Requests either/or games from the fans-united  mini-games api and returns a Promise<FansUnitedEitherOrModel[]>
	 */
	public getEitherOrGames = async (maxGames: number): Promise<FansUnitedEitherOrModel[]> => {
		const eitherOrGames = await FansUnitedHttpService.getEitherOrGames();
		const mapper = new FansUnitedEitherOrMapper();
		if (eitherOrGames && eitherOrGames.data && eitherOrGames.data.data && eitherOrGames.data.data.length > 0) {
			return eitherOrGames.data.data.slice(0, maxGames).map((eitherOrGame: Response) => mapper.responseToModel(eitherOrGame));
		} else {
			return [];
		}
	};

	public markFuGameModelAsSelected(games: FansUnitedGameModel[], gameId: string) {
		if (games) {
			games = games.map((game: FansUnitedGameModel) => {
				game.isSelected = game.id === gameId;

				return game;
			});
		}
		return games;
	}

	public markFuTemplateModelAsSelected(templates: FansUnitedLeaderboardTemplatesModel[], templateId: string) {
		if (templates) {
			templates = templates.map((template: FansUnitedLeaderboardTemplatesModel) => {
				template.isSelected = template.id === templateId;

				return template;
			});
		}
		return templates;
	}

	public markFuClassicQuizModelAsSelected(quizzes: FansUnitedClassicQuizModel[], quizId: string) {
		if (quizzes) {
			quizzes = quizzes.map((quiz: FansUnitedClassicQuizModel) => {
				quiz.isSelected = quiz.id === quizId;

				return quiz;
			});
		}
		return quizzes;
	}

	public markFuEitherOrGamesModelAsSelected(eitherOrGames: FansUnitedEitherOrModel[], eitherOrGameId: string) {
		if (eitherOrGames) {
			eitherOrGames = eitherOrGames.map((eitherOrGame: FansUnitedEitherOrModel) => {
				eitherOrGame.isSelected = eitherOrGame.id === eitherOrGameId;

				return eitherOrGame;
			});
		}
		return eitherOrGames;
	}

	public fansUnitedCredentialsPresent() {
		const config = featuresService.getFeatureConfig(FeatureTypes.FANS_UNITED);

		return !!(config && config.configuration && config.configuration.api_key && config.configuration.client_id);
	}
}
