import HighlightBlockBuilder from './highlight-block.builder';
import { HighlightBlockJson } from './highlight-block.json';

export default class HighlightBlockModel {
	readonly title: string;
	readonly description: any;

	private constructor(title: string, description: string) {
		this.title = title;
		this.description = description;
	}

	toJson(): HighlightBlockJson {
		return {
			title: this.title,
			description: this.description,
		};
	}

	static fromJson(json: HighlightBlockJson): HighlightBlockModel {
		return new HighlightBlockModel(json.title, json.description);
	}

	static builder(highlightBlock?: HighlightBlockJson): HighlightBlockBuilder {
		return new HighlightBlockBuilder(highlightBlock);
	}
}
