import * as React from 'react';
import { Button, Col, Row } from 'reactstrap';
import OnnetworkVideoModel from '../../../../../../../models/onnetwork-videos/onnetwork-video.model';

type Properties = {
	video: OnnetworkVideoModel;
	t: any;
	onSelect: (video: OnnetworkVideoModel) => void;
	playVideo: (bool: boolean, video: OnnetworkVideoModel) => void;
	copyEmbed: (e: any, embedCODE: string) => void;
	copyId: (e: any, sid: string) => void;
	selectedVideo: OnnetworkVideoModel;
	isMediaSidebar: boolean;
	index: number;
};

const VideoRow: React.FunctionComponent<Properties> = (props) => {
	const { video, t, selectedVideo, onSelect, playVideo, copyEmbed, copyId, isMediaSidebar } = props;
	const isSelectedVideo = selectedVideo && video.id === selectedVideo.id;
	const style = 'w-25 p-1 d-flex justify-content-center';

	const hasEmbedCode = video.embedCode && video.embedCode.code;
	const selectVideo = () => {
		if (hasEmbedCode) {
			onSelect(video);
		}
	};

	return (
		<Row
			title={`${hasEmbedCode ? video.title : t('no_embed_code')}`}
			className={`${hasEmbedCode ? '' : 'opacity-06'} w-100 border-bottom`}
			style={{ minHeight: '45px' }}
		>
			<Col
				id='onnetwork-select-video'
				className={`${isMediaSidebar ? 'font-size-13' : ''} py-1 pr-0 d-flex align-items-center`}
				sm='7'
				md='7'
				lg='7'
				onClick={selectVideo}
			>
				{video.title}
			</Col>
			<Col sm='5' md='5' lg='5' className='pl-0 py-1 d-flex'>
				<div className='d-flex justify-content-end w-100'>
					<div id='onnetwork-select-video-icon' onClick={selectVideo} className={style}>
						{isSelectedVideo && <i className='align-self-center fa-2x fa fa-check-circle text-warning' />}
					</div>
					<Button
						disabled={!hasEmbedCode}
						id='onnetwork-play-button'
						color='secondary'
						className={`${style} align-items-center align-self-center mr-1`}
						title={t('play_video')}
						onClick={() => playVideo(true, video)}
					>
						<i className='fa fa-play p-1' />
					</Button>
					<Button
						disabled={!hasEmbedCode}
						id='onnetwork-copy-embed-button'
						color='warning'
						className={`${style} align-items-center align-self-center mr-1`}
						title={t('copy_embedCode')}
						onClick={(e) => copyEmbed(e, video.embedCode.script)}
					>
						<i className='fa fa-copy p-1' />
					</Button>
					<Button
						disabled={!hasEmbedCode}
						id='onnetwork-copy-id'
						color='secondary'
						className={`${style} align-items-center align-self-center`}
						title={t('copy_id')}
						onClick={(e) => copyId(e, video.embedCode.code)}
					>
						<i className='fa fa-clone p-1' />
					</Button>
				</div>
			</Col>
		</Row>
	);
};

export default VideoRow;
