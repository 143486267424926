import * as React from 'react';
import { Properties } from './properties/template-select-container.properties';
import { selectedTemplateToOption, templatesToOptions } from './template-select.helper';
import Select from 'react-select';

const TemplateSelectContainer: React.FunctionComponent<Properties> = (props) => {
	const { selectedTemplate, templates, t, defaultValue } = props;
	const onTemplateSelect = (selection: any) => {
		props.onTemplateSelect(selection);
	};

	return (
		<Select
			defaultValue={defaultValue}
			options={templatesToOptions(templates, t)}
			value={selectedTemplate && selectedTemplateToOption(selectedTemplate, t)}
			onChange={onTemplateSelect}
			noOptionsMessage={(inputValue) => inputValue && t('no_options')}
		/>
	);
};

export default TemplateSelectContainer;
