import { AssetsTypesJson } from './assets-types.json';
import AssetsTypesBuilder from './assets-types.builder';

export default class AssetsTypesModel {
	readonly entity: string;
	readonly assetType: string;
	readonly dimensions: any;
	readonly contexts: any;

	private constructor(entity: string, assetType: string, dimensions: any, contexts: any) {
		this.entity = entity;
		this.assetType = assetType;
		this.dimensions = dimensions;
		this.contexts = contexts;
	}

	toJSON(): AssetsTypesJson {
		return {
			entity: this.entity,
			assetType: this.assetType,
			dimensions: this.dimensions,
			contexts: this.contexts,
		};
	}

	static fromJSON(json: AssetsTypesJson): AssetsTypesModel {
		return new AssetsTypesModel(json.entity, json.assetType, json.dimensions, json.contexts);
	}

	static builder(types?: AssetsTypesModel): AssetsTypesBuilder {
		return new AssetsTypesBuilder(types);
	}
}
