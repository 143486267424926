import { DynamicObjectWithKeys } from '../../constants/content-types';
import { store } from '../../store/store';
import ContentAttributes from '../content-attributes/content-attributes-model';
import BannerBuilder from './BannerBuilder';
import { BannerJson } from './BannerJson';

export default class Banner {
	readonly id: string;
	readonly title: string;
	readonly code: string;
	readonly createdAt: string;
	readonly updatedAt: string;
	readonly status: string;
	readonly properties: DynamicObjectWithKeys;

	private constructor(
		id: string,
		title: string,
		code: string,
		properties: DynamicObjectWithKeys,
		createdAt: string,
		updatedAt: string,
		status: string,
	) {
		this.id = id;
		this.title = title;
		this.code = code;
		this.properties = properties;
		this.createdAt = createdAt;
		this.updatedAt = updatedAt;
		this.status = status;
	}

	toJSON(): BannerJson {
		return {
			id: this.id,
			title: this.title,
			code: this.code,
			properties: this.properties,
			createdAt: this.createdAt,
			updatedAt: this.updatedAt,
			status: this.status,
		};
	}

	static fromJSON(json: BannerJson): Banner {
		return new Banner(json.id, json.title, json.code, json.properties, json.createdAt, json.updatedAt, json.status);
	}

	static builder(banner?: Banner): BannerBuilder {
		return new BannerBuilder(banner);
	}

	static extractDynamicPropertiesKeys(): string[] {
		const reduxStoreBannerProperties = store.getState().contentProperties.bannerProperties;
		return reduxStoreBannerProperties.map((bannerProperty: ContentAttributes) => bannerProperty.slug);
	}

	static extractDynamicPropertiesKeysWithDescription(): DynamicObjectWithKeys[] {
		const reduxStoreBannerProperties = store.getState().contentProperties.bannerProperties;

		return reduxStoreBannerProperties.map((bannerProperty: ContentAttributes) => {
			return { slug: bannerProperty.slug, description: bannerProperty.name };
		});
	}

	static extractDynamicPropertiesAsObjectWithValues(): DynamicObjectWithKeys {
		const reduxStoreBannerProperties = store.getState().contentProperties.bannerProperties || [];

		return reduxStoreBannerProperties.reduce(
			(obj: ContentAttributes, item: ContentAttributes) => Object.assign(obj, { [item.slug]: item.isDefault }),
			{},
		);
	}
}
