import * as React from 'react';
import { Properties, State } from './properties/SlugContainerProperties';
import Slug from './Slug';

export default class SlugContainer extends React.Component<Properties, State> {
	constructor(props: Properties) {
		super(props);
	}

	onAutoSlugCheckboxChange = (checked: boolean) => {
		this.props.onAutoSlugToggle(checked);
	};

	onSlugInputChange = (slug: string) => {
		this.props.onSlugChange(slug);
	};

	render() {
		const { t, slug, autoSlug } = this.props;

		return (
			<Slug
				t={t}
				isAutoSlug={autoSlug}
				slug={slug}
				onSlugInputChange={this.onSlugInputChange}
				onAutoSlugCheckbox={this.onAutoSlugCheckboxChange}
			/>
		);
	}
}
