import React, { FunctionComponent, useEffect, useState } from 'react';
import {
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	FormGroup,
	Col,
	Input,
	Label,
	Tooltip,
	ButtonGroup,
	Alert,
	Row,
} from 'reactstrap';
import Select from 'react-select';
import ErrorHandler from '../../Blocky/partials/error/error-handler-component';
import {
	notifyUsers,
	populateOneSignalModalFields,
	recipientsToOptions,
	deliveryTypesToOptions,
	areOneSignalFieldsValid,
	getRecipientsFromFeatureConfig,
	populateOneSignalDefaultModalFieldsFromFeature,
	findDefaultDeliveryType,
	findDefaultRecipient,
	getContentNotificationUrl,
} from './OneSignal.helper';
import { checkObjectPropertiesExists, isCharRestrictionOkay } from '../../../../global-helpers/global.helpers';
import { fieldsCharsLength, oneSignalDeliveryTypesArray, ONESIGNAL_DELIVERY_TYPES } from './OneSignal.constants';
import { toast } from 'react-toastify';
import Flatpickr from 'react-flatpickr';
import moment from 'moment';
import '../../EditorToolbar/EditorToolbar.scss';
import { availableContentTypes, entitiesType } from '../../../../services/content-models-service/ContentModelService';
import './OneSignal.scss';

type Props = {
	t: any;
	content: entitiesType;
	contentType: availableContentTypes;
	showButtonWithTooltip?: boolean;
};

const OneSignalModal: FunctionComponent<Props> = ({ t, content, contentType, showButtonWithTooltip }) => {
	const [modalOpenFlag, setModalOpenFlag] = useState(false);
	const recipientsFromFeatureConfig = getRecipientsFromFeatureConfig();
	const [oneSignalState, setOneSignalState] = useState(populateOneSignalDefaultModalFieldsFromFeature(recipientsFromFeatureConfig.default));
	const notifyButtonEnabledFlag = areOneSignalFieldsValid(oneSignalState);
	const [isOpenNotifyUsers, toggleNotifyUsersTooltip] = React.useState(false);

	useEffect(() => {
		const populatedModalState = populateOneSignalModalFields(oneSignalState, content);
		setOneSignalState(populatedModalState);
	}, [
		content.title,
		content.subtitle,
		checkObjectPropertiesExists(content, 'image', 'data', 'urls', 'uploaded', 'original') && content.image.data.urls.uploaded.original,
	]);

	const toggle = async () => {
		if (!modalOpenFlag) {
			try {
				const notificationUrl = await getContentNotificationUrl(content, contentType, t);
				notificationUrl && setOneSignalState({ ...oneSignalState, url: notificationUrl });
			} catch (error) {
				error && toast.warn(error);
			}
		}
		setModalOpenFlag(!modalOpenFlag);
		modalOpenFlag && setOneSignalState({ ...oneSignalState, sendDate: '' }); // clear sendDate field
	};

	const onInputFieldChange = (ev: any) => {
		const el = ev.target;
		setOneSignalState({ ...oneSignalState, [el.name]: el.value });
	};

	// multiselect
	const onSelectRecipientsChange = (selectedObjValues: any, selectFieldName: string) => {
		if (selectFieldName) {
			if (selectedObjValues && selectedObjValues.length > 0) {
				const selectedValues = [...selectedObjValues].map((selectedObj) => selectedObj.value);
				setOneSignalState({ ...oneSignalState, [selectFieldName]: selectedValues });
			} else {
				setOneSignalState({ ...oneSignalState, [selectFieldName]: [] });
			}
		}
	};

	const onDeliveryChange = (selectedDelivery: any) =>
		setOneSignalState({ ...oneSignalState, delivery: selectedDelivery.value, sendDate: '' });

	const sendNotification = () =>
		notifyUsers(oneSignalState, t('notify_send_success'))
			.catch(() => toast.error(t('general_error')))
			.finally(() => toggle());

	const onDateChange = (date: Date[]) => {
		date && date.length > 0
			? setOneSignalState({ ...oneSignalState, sendDate: date[0].toString() })
			: setOneSignalState({ ...oneSignalState, sendDate: '' });
	};

	const openOneSignalLink = () =>
		oneSignalState.url ? window.open(oneSignalState.url, '_blank') : toast.warn(t('insert_notification_url'));

	return (
		<>
			{!showButtonWithTooltip ? (
				<Button size='sm' color='warning' className='ml-2 text-nowrap' onClick={toggle}>
					<i className={'fa fa-bell-o'} /> {t('notify_users')}
				</Button>
			) : (
				<ButtonGroup>
					<Button id='notify-users-button' onClick={toggle}>
						<i className='fa-regular fa-bell fa-lg'></i>
					</Button>
					<Tooltip
						placement='top'
						isOpen={isOpenNotifyUsers}
						className='editor-toolbar-tooltip'
						target='notify-users-button'
						toggle={() => toggleNotifyUsersTooltip(!isOpenNotifyUsers)}
					>
						{t('notify_users')}
					</Tooltip>
				</ButtonGroup>
			)}

			<Modal isOpen={modalOpenFlag} toggle={toggle}>
				<ModalHeader toggle={toggle}> {t('notify_users')} </ModalHeader>
				<ModalBody>
					<FormGroup row className='mb-2'>
						<Col md='12' className='mb-4'>
							<Label> {t('notification_image')} </Label>
							<br />
							{oneSignalState.imageURL ? (
								<img src={oneSignalState.imageURL} alt={t('notification_image')} />
							) : (
								<span className='bold'> - {t('no_image_added')} </span>
							)}
						</Col>
						<Col md='12' className='mb-4'>
							<Label> {t('delivery')} </Label>
							<Select
								type='text'
								placeholder={t('select_delivery')}
								options={deliveryTypesToOptions(oneSignalDeliveryTypesArray, t)}
								onChange={onDeliveryChange}
								value={findDefaultDeliveryType(oneSignalDeliveryTypesArray, t, oneSignalState)}
								defaultValue={oneSignalState.recipients}
							/>
							{!oneSignalState.delivery && <ErrorHandler t={t} errorMessage={t('select_one_entity')} />}
						</Col>
						{oneSignalState.delivery === ONESIGNAL_DELIVERY_TYPES.DELAYED && (
							<Col md='12' className='mb-4'>
								<Label> {t('action_time')} </Label>
								<Flatpickr
									className={`date-picker h-38`}
									placeholder={t('select_date')}
									onChange={onDateChange}
									options={{
										enableTime: true,
										minDate: moment(new Date()).add(5, 'm').toDate(),
										defaultDate: '',
										locale: 'en',
										mode: 'single',
										time_24hr: true,
										dateFormat: 'Y-m-d, H:i',
									}}
								/>
								{!oneSignalState.sendDate && <ErrorHandler t={t} errorMessage={t('select_date')} />}
							</Col>
						)}
						<Col md='12' className='mb-4'>
							<Label> {t('notification_url')} </Label>
							<Row>
								<Col sm='12' md='11'>
									<Input type='url' placeholder={t('notification_url')} name='url' onChange={onInputFieldChange} value={oneSignalState.url} />
								</Col>
								<Col md='1' className='test-link-before-sending-notification-wrapper mobile-d-none'>
									<i
										onClick={openOneSignalLink}
										className='fa fa-external-link cursor-pointer'
										aria-hidden='true'
										title={t('test_notification_url')}
									></i>
								</Col>
							</Row>
							{!isCharRestrictionOkay(oneSignalState.url, fieldsCharsLength.url.minimum, fieldsCharsLength.url.maximum) && (
								<ErrorHandler t={t} errorMessage={t('minimum_3_symbols_maximum_255_symbols')} />
							)}
						</Col>
						<Col md='12' className='mb-4'>
							<Label> {t('notification_title')} </Label>
							<Input type='text' placeholder={t('notification_title')} name='title' onChange={onInputFieldChange} value={oneSignalState.title} />
							{!isCharRestrictionOkay(oneSignalState.title, fieldsCharsLength.title.minimum, fieldsCharsLength.title.maximum) && (
								<ErrorHandler t={t} errorMessage={t('minimum_3_symbols_maximum_40_symbols')} />
							)}
						</Col>
						<Col md='12' className='mb-4'>
							<Label> {t('notification_description')} </Label>
							<Input
								type='text'
								placeholder={t('notification_description')}
								name='description'
								onChange={onInputFieldChange}
								value={oneSignalState.description}
							/>
							{!isCharRestrictionOkay(
								oneSignalState.description,
								fieldsCharsLength.description.minimum,
								fieldsCharsLength.description.maximum,
							) && <ErrorHandler t={t} errorMessage={t('minimum_3_symbols_maximum_40_symbols')} />}
						</Col>
						<Col md='12' className='mb-4'>
							<Label> {t('recipients')} </Label>
							<Select
								type='text'
								placeholder={t('select_recipients')}
								isMulti={true}
								options={recipientsToOptions(recipientsFromFeatureConfig.recipients)}
								onChange={(data) => onSelectRecipientsChange(data, 'recipients')}
								value={findDefaultRecipient(recipientsFromFeatureConfig.recipients, oneSignalState)}
								defaultValue={oneSignalState.recipients}
							/>
							{oneSignalState.recipients.length === 0 && <ErrorHandler t={t} errorMessage={t('select_minimum_one_entity')} />}
						</Col>
						{content && content.status !== 'active' && (
							<Col md='12'>
								<Alert color='warning' className='font-weight-bold'>
									{t('warning_check_data_before_send_notification')}
								</Alert>
							</Col>
						)}
					</FormGroup>
				</ModalBody>
				<ModalFooter>
					<Button color='primary' onClick={sendNotification} disabled={!notifyButtonEnabledFlag}>
						{t('notify_users')}
					</Button>
					<Button color='secondary' onClick={toggle}>
						{t('cancel')}
					</Button>
				</ModalFooter>
			</Modal>
		</>
	);
};

export default OneSignalModal;
