import ListModel from '../../../../../models/v2/list/list';
import ListItemModel from '../../../../../models/v2/list/list-item';
import { areLockPositionsValid, areNumberOfItemsValid } from './alerts.helper';
import Author from '../../../../../models/author/Author';
import { imageApiProcessUrl, imagePlaceholderBase64 } from '../../../../../global-helpers/global.constants';
import { capitalize, extractMainContentTypeBasedOnUrl, isObjectEmpty } from '../../../../../global-helpers/global.helpers';
import { ContentTypes } from '../../../../../constants/content-types';
import { extractContentTypePluralNaming } from '../../../../../services/content-models-service/ContentModelService';

export const shouldShowListItem = (selectedList: ListModel, contentModelId: string) => {
	if (selectedList.items && selectedList.items.length > 0) {
		const listItemsIds = selectedList.items.map((item: ListItemModel) => item.data.id);
		return listItemsIds.includes(contentModelId) ? true : false;
	}

	return false;
};

export const getImageSrcOrPlaceholderImage = (imagePath: string): string => {
	return imagePath ? `${imageApiProcessUrl}${imagePath}` : imagePlaceholderBase64;
};

export const disableSaveListButton = (selectedList: ListModel | null): boolean => {
	return !(selectedList && areNumberOfItemsValid(selectedList) && areLockPositionsValid(selectedList));
};

export const displayAuthorNamesString = (t: any, authors: Author[]): string => {
	const authorsString = authors && authors.length > 0 ? authors.map((author: Author) => author.name).join(', ') : '';
	return authorsString ? `${t('authors')}: ${authorsString}` : '';
};

export const populateListItemsLockedPosition = (listItems: ListItemModel[], lockedPositions: number[]): ListItemModel[] => {
	return listItems.map((listItem: ListItemModel, index: number) =>
		lockedPositions.includes(index) ? { ...listItem, lockedPosition: index } : { ...listItem, lockedPosition: null },
	);
};

export const populateListLockedPositions = (selectedList: ListModel): ListModel => {
	const selectedListItems = selectedList.items || [];
	const selectedListLockedIndexes = selectedList.lockedPositions || [];
	const populatedListItems = populateListItemsLockedPosition(selectedListItems, selectedListLockedIndexes);
	selectedList.items = populatedListItems;
	return selectedList;
};

export const generateNewListItem = (contentModel: any): ListItemModel => {
	const listTypeLowercase = extractMainContentTypeBasedOnUrl();
	return {
		type: (listTypeLowercase && listTypeLowercase.toLocaleUpperCase()) || '',
		meta: {},
		data: contentModel,
		lockedPosition: null,
	} as ListItemModel;
};

export const extractListIdsFromListItems = (listItems: ListItemModel[], contentType: ContentTypes): number[] => {
	if (listItems && listItems.length > 0) {
		return listItems
			.filter((item: ListItemModel) => item.type === contentType.toLocaleUpperCase())
			.map((item: ListItemModel) => item.data.id);
	}
	return [];
};

export const generateStatisticsQueryParams = (listItems: ListItemModel[], contentType: ContentTypes): string => {
	if (listItems && listItems.length > 0) {
		const contentTypeIds = extractListIdsFromListItems(listItems, contentType);
		const contentTypeIdsAsString = contentTypeIds.join(',');
		const namingQuery = extractContentTypePluralNaming(contentType);
		return contentTypeIds.length > 0 ? `&${namingQuery}=${contentTypeIdsAsString}` : '';
	}
	return '';
};

export const populateListItemsWithContentStatistics = (contentStatistics: any, list: ListModel) => {
	const remappedListItems: ListItemModel[] = [];
	const listItemsDuplicate: ListItemModel[] = list.items.length > 0 ? [...list.items] : [];

	listItemsDuplicate.forEach((item: ListItemModel) => {
		contentStatistics.forEach((statistic: any) => {
			if (statistic.id === item.data.id) {
				item.data.viewsCount = statistic.data.views;
				item.data.commentsCount = statistic.data.comments;
				remappedListItems.push(item);
			}
		});
	});

	return { ...list, items: remappedListItems };
};

export const extractContentValueByTheGivenKey = (content: any, statistic: any): number => {
	let statisticKey = statistic.json_key.replaceAll('.', ' ').split(' ');
	statisticKey = statisticKey
		.map((el: any, index: number) => {
			if (index !== 0) {
				return capitalize(el);
			}
			return el;
		})
		.join('');

	return (content && content[statisticKey] && content[statisticKey].count) || 0;
};

export const isListEmpty = (list: any): boolean => {
	return isObjectEmpty(list) ? true : Object.values(list).filter((value: any) => value !== undefined).length === 0;
};
