import React from 'react';
import { Name } from '../fields/name';
import { withRouter } from 'react-router-dom';
import {
	SOCIALS_TEAM,
	SOCIAL_TYPES_METHODS,
	isSaveDisabled,
	constructRequestFromEntry,
	remapBlacklistFromResponse,
	teamSave,
	checkIfNextPageIsEntityRelated,
} from '../helpers/manual-data.helper';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Col, Row, Button, Card, CardHeader, Collapse, Label } from 'reactstrap';
import CountrySelect from '../fields/country';
import CustomFootballSearch from '../../../../Partials/Shared/search-entity/football-search.component';
import Type from '../fields/type';
import YearFounded from '../fields/year-founded';
import Social from '../fields/social';
import { Properties, State } from './properties/team-edit.properties';
import {
	TEAM_ENTITY_REQUEST,
	TEAM_ENTITY_RECEIVED,
	returnObjectForTeamEntityRequest,
	returnObjectForTeamEntityUpdate,
	postTeamAssets,
	TEAM_ENTITY_UPDATE_SUCCESS,
	TEAM_ENTITY_POST_ASSETS_SUCCESS,
	teamSearchFilterUpdate,
} from '../../../../../store/action-creators/team-action-creator';
import Project from '../../../../../models/project/Project';
import SidebarCollapsibleElements from '../../../../Partials/Sidebar/sidebar-collapsible-elements/sidebar-collapsible-elements';
import { ContentTypes } from '../../../../Partials/Blocky/constants/block.types';
import SidebarElementsToggle from '../../../../Partials/Sidebar/sidebar-collapsible-elements/sidebar-elements-toggle/sidebar-elements-toggle.component';
import { actionService } from '../../../../../App';
import Blacklisted from '../blacklisted-container.tsx/blacklisted-container';
import HttpService from '../../../../../services/rest/HttpService';
import BlacklistModal from '../blacklist-modal/blacklist-modal';
import BlacklistModel from '../../../../../models/team-manual-data/blacklist/blacklist';
import TeamManualDataModel from '../../../../../models/team-manual-data/team-manual-data.model';
import { AssetsMediaComponent } from '../assets-media/assets-media.component';
import AssetsModel from '../../../../../models/team-manual-data/assets/assets';
import { removeAssetsFromAssetsModel } from '../assets-media/helpers/assets-image-upload.helper';
import { ThreeLetterCode } from '../fields/three-letter-code';
import { Subscription } from 'rxjs';
import TeamEditSquadsComponent from './team-edit-squads/team-edit-squads.component';
import { toast } from 'react-toastify';
import { extractNotEmptyTranslations, remapTranslationTeamFromResponse } from '../../../translations/helpers/translations.helpers';
import TranslateTeam from '../../../translations/subcomponents/modal/translate-team.component';
import EnumItem from '../../../../../models/enum/EnumItem';
import TranslationTeamModel from '../../../translations/models/translations-team.model';
import TeamEditCoachComponent from './team-edit-coach/team-edit-coach.component';
import { COACH_ENTITY_UPDATE_SUCCESS } from '../../../../../store/action-creators/coach-action-creator';
import ColorPickerComponent from '../../../../Partials/Blocky/blocks/widgets/match/components/color-picker.component';
import MatchWidgetModel from '../../../../Partials/Blocky/blocks/widgets/match/models/match-widget.model';
import TeamModel from '../../../../Partials/Blocky/partials/team-select/models/team.model';
import MatchModel from '../../../../Partials/Blocky/partials/match-select/models/match.model';
import axios from 'axios';
import { ShortName } from '../fields/short-name';
import {
	ContentSidebarChangeCollapseStatus,
	onChangeCollapseStatus,
	onSidebarElemSort,
	toggleSidebarEditMode,
} from '../../../../../services/content-sidebar/content-sidebar.helper';
import { FormattedSidebarSectionValue } from '../../../../Partials/Sidebar/sidebar-collapsible-elements/utils/temp-sidebar.types';
import GlobalModal from '../../../../Partials/Modals/Global/global-modal';
import {
	appendBeforeUnloadResetTempSidebar,
	defaultTempSidebarFromApiResponse,
	overwriteTempSidebarByContentType,
	saveSidebarSettingsByContentType,
} from '../../../../Partials/Sidebar/sidebar-collapsible-elements/utils/temp-sidebar.helper';

class TeamEditComponent extends React.Component<Properties, State> {
	private registerActionSubscription = new Subscription();

	constructor(props: any) {
		super(props);
		this.state = {
			isContentLoading: false,
			isSidebarInEdit: false,
			teamDataEntry: TeamManualDataModel.builder(props.team).build(),
			assets: AssetsModel.builder(props.assets).build(),
			isOpenModal: false,
			isOpenSquadPlayers: true,
			isOpenTranslation: true,
			teamToTranslate: TranslationTeamModel.builder(
				remapTranslationTeamFromResponse(props.team, props.team.assets && props.team.assets.logo && props.team.assets.logo.url),
			).build(),
			languageToTranslate: EnumItem.builder().withId('bg').withName('bg').build(),
			isOpenCoach: true,
			selectedHomeTeamColor: '',
			selectedAwayTeamColor: '',
			openSidebarSettingsModalFlag: false,
		};
	}

	componentDidMount(): void {
		this.initPageTitle();
		this.registerActionEventListener();
		this.props.getTeamEntity(this.props.match.params.id, this.props.project);
		appendBeforeUnloadResetTempSidebar(ContentTypes.team);
	}

	componentDidUpdate(prevProps: Readonly<Properties>): void {
		if (!TeamManualDataModel.builder(prevProps.team).equals(this.props.team)) {
			this.setState({
				...this.state,
				teamDataEntry: this.props.team,
				teamToTranslate: TranslationTeamModel.builder(remapTranslationTeamFromResponse(this.props.team, this.props.team.assets.logo)).build(),
			});
			this.getBlacklistEntityData(this.props.team);
			this.checkIfTeamHasShirtColors();
		}
	}

	checkIfTeamHasShirtColors = () => {
		if (this.props.team.shirtColor) {
			const getHomeTeamShirtColor = this.props.team.shirtColor.filter((el: any) => el.type === 'home');
			const getAwayTeamShirtColor = this.props.team.shirtColor.filter((el: any) => el.type === 'away');

			this.setState({
				...this.state,
				teamDataEntry: this.props.team,
				teamToTranslate: TranslationTeamModel.builder(remapTranslationTeamFromResponse(this.props.team, this.props.team.assets.logo)).build(),
				selectedHomeTeamColor: getHomeTeamShirtColor.length !== 0 ? getHomeTeamShirtColor[0].color_code : '',
				selectedAwayTeamColor: getAwayTeamShirtColor.length !== 0 ? getAwayTeamShirtColor[0].color_code : '',
			});
		} else {
			this.setState({
				...this.state,
				teamDataEntry: this.props.team,
				teamToTranslate: TranslationTeamModel.builder(remapTranslationTeamFromResponse(this.props.team, this.props.team.assets.logo)).build(),
				selectedHomeTeamColor: '',
				selectedAwayTeamColor: '',
			});
		}
	};

	registerActionEventListener() {
		this.registerActionSubscription = actionService.onActionReceived().subscribe((action: string) => {
			if (action === TEAM_ENTITY_REQUEST) this.toggleContentLoadingState(true);
			if (action === TEAM_ENTITY_RECEIVED) this.toggleContentLoadingState(false);
			if (action === TEAM_ENTITY_UPDATE_SUCCESS || action === TEAM_ENTITY_POST_ASSETS_SUCCESS) {
				this.props.getTeamEntity(this.props.match.params.id, this.props.project);
			}
			if (action === COACH_ENTITY_UPDATE_SUCCESS) {
				this.props.getTeamEntity(this.props.match.params.id, this.props.project);
			}
		});
	}

	getBlacklistEntityData = (team: TeamManualDataModel) => {
		const params = constructRequestFromEntry([team], 'TEAM', 'ENTITY');
		HttpService.getBlacklist(`/v2/blacklist/search`, params)
			.then((response: any) => {
				const blacklist = response.data;

				if (blacklist && blacklist.length > 0) {
					this.setState({
						...this.state,
						teamDataEntry: TeamManualDataModel.builder(team).withBlacklist(remapBlacklistFromResponse(blacklist[0])).build(),
					});
				}
			})
			.catch((error: unknown) => console.error(error));
	};

	componentWillUnmount() {
		this.registerActionSubscription.unsubscribe();
		checkIfNextPageIsEntityRelated('teams') && this.props.updateSearchFilterTeam('');
	}

	toggleContentLoadingState = (isLoading: boolean) => this.setState({ ...this.state, isContentLoading: isLoading });

	initPageTitle = () => (document.title = this.props.t('team_edit'));

	onNameChange = (e: any) => {
		const updatedTeamToTranslate = this.state.teamToTranslate;
		updatedTeamToTranslate['team']['name'] = e.target.value;

		this.setState({
			...this.state,
			teamDataEntry: TeamManualDataModel.builder(this.state.teamDataEntry).withName(e.target.value).build(),
			teamToTranslate: TranslationTeamModel.builder(updatedTeamToTranslate).build(),
		});
	};

	onCountryChange = (country: any) => {
		const updatedTeamToTranslate = this.state.teamToTranslate;
		updatedTeamToTranslate['country']['name'] = country.name;
		updatedTeamToTranslate['country']['id'] = country.id;

		this.setState({
			...this.state,
			teamDataEntry: TeamManualDataModel.builder(this.state.teamDataEntry).withCountry(country).build(),
			teamToTranslate: TranslationTeamModel.builder(updatedTeamToTranslate).build(),
		});
	};

	onCoachChange = (coach: any) => {
		this.setState({ ...this.state, teamDataEntry: TeamManualDataModel.builder(this.state.teamDataEntry).withCoach(coach).build() });
	};

	onVenueChange = (venue: any) => {
		this.setState({ ...this.state, teamDataEntry: TeamManualDataModel.builder(this.state.teamDataEntry).withVenue(venue).build() });
	};

	onDateChange = (e: any) => {
		this.setState({
			...this.state,
			teamDataEntry: TeamManualDataModel.builder(this.state.teamDataEntry).withFounded(e.target.value).build(),
		});
	};

	onThreeLetterCodeChange = (e: any) => {
		const updatedTeamToTranslate = this.state.teamToTranslate;
		updatedTeamToTranslate['team']['threeLetterCode'] = e.target.value;
		this.setState({
			...this.state,
			teamDataEntry: TeamManualDataModel.builder(this.state.teamDataEntry)
				.withThreeLetterCode(e.target.value === '' ? null : e.target.value)
				.build(),
			teamToTranslate: TranslationTeamModel.builder(updatedTeamToTranslate).build(),
		});
	};

	onShortNameChange = (e: any) => {
		const updatedTeamToTranslate = this.state.teamToTranslate;
		updatedTeamToTranslate['team']['shortName'] = e.target.value;
		this.setState({
			...this.state,
			teamDataEntry: TeamManualDataModel.builder(this.state.teamDataEntry)
				.withShortName(e.target.value === '' ? null : e.target.value)
				.build(),
			teamToTranslate: TranslationTeamModel.builder(updatedTeamToTranslate).build(),
		});
	};

	onPresidentChange = (president: any) => {
		this.setState({ ...this.state, teamDataEntry: TeamManualDataModel.builder(this.state.teamDataEntry).withPresident(president).build() });
	};

	onSocialChange = (e: any, key: string) => {
		const { teamDataEntry } = this.state;
		const social = SOCIAL_TYPES_METHODS[key](teamDataEntry.social, e.target.value);
		this.setState({ ...this.state, teamDataEntry: TeamManualDataModel.builder(teamDataEntry).withSocial(social).build() });
	};

	toggleSidebarEditMode = () => {
		const newState = toggleSidebarEditMode({ ...this.state }, ContentTypes.team);
		this.setState(newState);
	};

	onNationalChange = (type: any) => {
		this.setState({ ...this.state, teamDataEntry: TeamManualDataModel.builder(this.state.teamDataEntry).withType(type).build() });
	};

	onTeamSave = () => {
		this.props.teamEntityUpdate(teamSave(this.state.teamDataEntry), this.props.project, this.state.assets);
	};

	saveTeamColors = () => {
		let colors = [];

		if (this.state.selectedHomeTeamColor) {
			colors.push({ type: 'home', color_code: this.state.selectedHomeTeamColor });
		}

		if (this.state.selectedAwayTeamColor) {
			colors.push({ type: 'away', color_code: this.state.selectedAwayTeamColor });
		}

		const params = {
			entity_type: 'team',
			entity_id: this.props.team.id,
			colors: colors,
		};

		HttpService.setTeamColors(`/v2/teams/colors`, params)
			.then()
			.catch((error: unknown) => console.error(error));
	};

	onAssetsSave = () => {
		this.saveTeamColors();
		this.props.saveTeamAssets({ teamId: this.state.teamDataEntry.id, project: this.props.project, assets: this.state.assets });
	};

	updateHomeTeamColor = (color: string) => {
		this.setState({ ...this.state, selectedHomeTeamColor: color.hex });
	};

	updateAwayTeamColor = (color: string) => {
		this.setState({ ...this.state, selectedAwayTeamColor: color.hex });
	};

	updateAssets = (assets: AssetsModel) => {
		this.setState({ ...this.state, assets: assets });
	};

	removeFromBlacklist = () => {
		const { teamDataEntry } = this.state;

		HttpService.removeFromBlacklist(teamDataEntry.blacklist.id)
			.then((response: any) => {
				this.setState({
					...this.state,
					teamDataEntry: TeamManualDataModel.builder(teamDataEntry).withBlacklist(BlacklistModel.builder().build()).build(),
					isOpenModal: false,
				});
			})
			.catch((error: any) => {
				this.setState({ ...this.state, isOpenModal: false });
			});
	};

	toggleModal = (toggle: boolean) => {
		this.setState({ ...this.state, isOpenModal: toggle });
	};

	deleteAssetsFromDataEntry = (type: string) => {
		const { assets } = this.state;

		this.props.getTeamEntity(this.props.match.params.id, this.props.project);
		this.setState({ ...this.state, assets: removeAssetsFromAssetsModel(type, assets) });
	};

	getDataEntry = () => {
		this.props.getTeamEntity(this.props.match.params.id, this.props.project);
	};

	toggleSquadPlayers = () => {
		this.setState({ ...this.state, isOpenSquadPlayers: !this.state.isOpenSquadPlayers });
	};

	toggleTranslation = () => {
		this.setState({ ...this.state, isOpenTranslation: !this.state.isOpenTranslation });
	};

	toggleCoach = () => {
		this.setState({ ...this.state, isOpenCoach: !this.state.isOpenCoach });
	};

	saveTranslation = (translations: any[], teamPlayersTranslation: any[]) => {
		HttpService.instanceFootball()
			.post('/translations', JSON.parse(JSON.stringify(extractNotEmptyTranslations([...translations, ...teamPlayersTranslation]))))
			.then(() => {
				toast.success(this.props.t('translation_successfull'));
			})
			.catch(() => {
				toast.error(this.props.t('translation_failed'));
			});
	};

	updateWidgetModelState = (model: MatchWidgetModel) => this.setState({ ...this.state, widgetModel: model });

	onTeamChange = (teams: TeamModel[]) => {
		this.updateWidgetModelState(
			MatchWidgetModel.builder(this.state.widgetModel).withTeams(teams).withMatch(MatchModel.builder().build()).build(),
		);
	};

	render() {
		const { t, project, profile, analytics } = this.props;

		const {
			teamDataEntry,
			isSidebarInEdit,
			isContentLoading,
			assets,
			isOpenModal,
			isOpenSquadPlayers,
			teamToTranslate,
			languageToTranslate,
			isOpenTranslation,
			isOpenCoach,
			openSidebarSettingsModalFlag,
		} = this.state;

		const sidebarComponentsMap = {
			media: (
				<AssetsMediaComponent
					t={t}
					currentProject={project}
					assets={assets}
					dataEntry={teamDataEntry}
					updateAssets={this.updateAssets}
					updateHomeTeamColor={this.updateHomeTeamColor}
					updateAwayTeamColor={this.updateAwayTeamColor}
					theSelectedHomeTeamColor={this.state.selectedHomeTeamColor}
					theSelectedAwayTeamColor={this.state.selectedAwayTeamColor}
					dataType='team'
					deleteAssetsFromDataEntry={this.deleteAssetsFromDataEntry}
					homeTeamPopoverClass={'popover-home'}
					awayTeamPopoverClass={'popover-away'}
				/>
			),
		};

		return (
			<div className='animated fadeIn'>
				<Row>
					<Col col='8' lg='8' md='12' sm='12' xs='12'>
						{teamDataEntry.blacklist && teamDataEntry.blacklist.id && (
							<Blacklisted t={t} type='team' isContentLoading={isContentLoading} toggleModal={this.toggleModal} />
						)}
						<div className='card'>
							<div className='card-header d-flex align-items-center '>
								<div className='mr-auto mb-0'>{t('team_info')}</div>
								<Button
									size='sm'
									color='primary'
									disabled={isSaveDisabled(teamDataEntry, ContentTypes.team)}
									className='text-uppercase font-weight-bold'
									onClick={this.onTeamSave}
									id='team-manual-data-entry-edit-save-top'
								>
									<i className='fa fa-floppy-o' /> {t('save_team')}
								</Button>
								<Button
									size='sm'
									color='primary'
									className='ml-2 text-uppercase font-weight-bold'
									onClick={this.onAssetsSave}
									id='team-manual-data-entry-edit-save-assets-top'
								>
									<i className='fa fa-floppy-o' /> {t('save_assets')}
								</Button>
							</div>
							<div className={`card-body ${isContentLoading ? 'loading-overlay' : ''}`}>
								<Row>
									<div className='alert alert-info w-100' role='alert'>
										{t('enter_the_team_name_in_english')}
									</div>
								</Row>
								<Row>
									<Name value={teamDataEntry.name} onChange={this.onNameChange} t={t} type='team' />
									<Type value={teamDataEntry.type} t={t} onChange={this.onNationalChange} />
								</Row>
								<Row>
									<CountrySelect isRequired value={teamDataEntry.country} onChange={this.onCountryChange} t={t} />
									<Col xs='6'>
										<CustomFootballSearch
											isClearable
											shouldShowLabel
											label='venue'
											isMulti={false}
											type='venue'
											value={teamDataEntry.venue}
											lang={project.language}
											onChange={this.onVenueChange}
											t={t}
										/>
									</Col>
								</Row>
								<Row>
									<YearFounded value={teamDataEntry.founded} onChange={this.onDateChange} t={t} />
									<Col xs='6'>
										<CustomFootballSearch
											isClearable
											shouldShowLabel
											label='coach'
											isMulti={false}
											type='coach'
											value={teamDataEntry.coach}
											lang={project.language}
											onChange={this.onCoachChange}
											t={t}
										/>
									</Col>

									{/* This will be added when BE endpoint for president is ready */}
									{/* <President value={teamDataEntry.president} onChange={this.onPresidentChange} t={t} /> */}
								</Row>
								<Row>
									<Col xs='6'>
										<ThreeLetterCode t={t} onChange={this.onThreeLetterCodeChange} value={teamDataEntry.threeLetterCode} type='team_abbreviation' />
									</Col>
									<Col xs='6'>
										<ShortName t={t} onChange={this.onShortNameChange} value={teamDataEntry.shortName} type='team_short_name' />
									</Col>
								</Row>
							</div>
						</div>
						<Card className='animated fadeIn'>
							<CardHeader className='cursor-pointer d-flex align-items-center' onClick={this.toggleTranslation}>
								{t('translate_team')}
								<i className={`ml-auto fa fa-lg fa-angle-${isOpenTranslation ? 'down' : 'left'}`} />
							</CardHeader>
							<Collapse isOpen={isOpenTranslation} className='p-3'>
								<TranslateTeam
									t={t}
									team={teamToTranslate}
									teamId={teamToTranslate.team.id}
									searchLanguage={EnumItem.builder().withId('en').withName('en').build()}
									languageToTranslate={languageToTranslate}
									saveTranslation={this.saveTranslation}
									getOnlyTeamPlayers
								/>
							</Collapse>
						</Card>
						<Card className='animated fadeIn'>
							<CardHeader className='cursor-pointer d-flex align-items-center' onClick={this.toggleSquadPlayers}>
								{t('team_players')}
								<i className={`ml-auto fa fa-lg fa-angle-${isOpenSquadPlayers ? 'down' : 'left'}`} />
							</CardHeader>
							<Collapse isOpen={isOpenSquadPlayers} className='p-3'>
								<TeamEditSquadsComponent t={t} />
							</Collapse>
						</Card>
						<Card className='animated fadeIn'>
							<CardHeader className='cursor-pointer d-flex align-items-center' onClick={this.toggleCoach}>
								{t('team_coach')}
								<i className={`ml-auto fa fa-lg fa-angle-${isOpenCoach ? 'down' : 'left'}`} />
							</CardHeader>
							<Collapse isOpen={isOpenCoach} className='p-3'>
								<TeamEditCoachComponent t={t} coach={teamDataEntry.coach} teamId={teamDataEntry.id} />
							</Collapse>
						</Card>
						<div className='card'>
							<div className='card-header'>{t('social_media_highlights')}</div>
							<div className={`card-body ${isContentLoading ? 'loading-overlay' : ''}`}>
								<Row className='mb-2'>
									{Object.keys(SOCIALS_TEAM).map((key: string) => {
										const value = teamDataEntry.social && teamDataEntry.social[key] ? teamDataEntry.social[key] : '';

										return <Social key={key} t={t} value={value} onChange={(e: any) => this.onSocialChange(e, key)} type={SOCIALS_TEAM[key]} />;
									})}
								</Row>
								<Row>
									<Button
										disabled={isSaveDisabled(teamDataEntry, ContentTypes.team)}
										color='primary'
										className='text-uppercase font-weight-bold'
										onClick={this.onTeamSave}
										id='team-manual-data-entry-edit-save-bottom'
									>
										<i className='fa fa-floppy-o' /> {t('save_team')}
									</Button>
									<Button
										color='primary'
										className='ml-2 text-uppercase font-weight-bold'
										onClick={this.onAssetsSave}
										id='team-manual-data-entry-edit-save-assets-bottom'
									>
										<i className='fa fa-floppy-o' /> {t('save_assets')}
									</Button>
								</Row>
							</div>
						</div>
					</Col>
					<Col col='4' lg='4' md='12' sm='12' xs='12' className='position-relative'>
						<SidebarElementsToggle t={t} toggleSidebarEditMode={this.toggleSidebarEditMode} isSidebarInEdit={isSidebarInEdit} />
						<SidebarCollapsibleElements
							isSidebarInEdit={isSidebarInEdit}
							onElemSort={(settingsValue: FormattedSidebarSectionValue[]) => onSidebarElemSort(settingsValue, ContentTypes.team)}
							onChangeCollapseStatus={(data: ContentSidebarChangeCollapseStatus) => onChangeCollapseStatus(data)}
							contentType={ContentTypes.team}
							sidebarComponentsMap={sidebarComponentsMap}
							t={t}
						/>
					</Col>
				</Row>
				<BlacklistModal
					t={t}
					type='team'
					analytics={analytics}
					contentToDelete={teamDataEntry}
					onDeleteContent={this.removeFromBlacklist}
					toggleModal={this.toggleModal}
					isToggledModal={isOpenModal}
				/>
				<GlobalModal
					isOpen={openSidebarSettingsModalFlag}
					t={this.props.t}
					headerContent='save_sidebar_settings'
					bodyContent='unsaved_changes'
					submitFunction={() => saveSidebarSettingsByContentType(ContentTypes.team, t)}
					beforeCloseFunction={() =>
						overwriteTempSidebarByContentType(ContentTypes.team, defaultTempSidebarFromApiResponse[ContentTypes.team].value)
					}
				/>
			</div>
		);
	}
}

function mapStateToProps(state: any) {
	return {
		team: state.team.team,
		project: state.project.currentProject,
		profile: state.profile.profile,
	};
}

function mapDispatchToProps(dispatch: any) {
	return {
		dispatch: (action: any) => dispatch(action),
		teamEntityUpdate: (teamData: TeamManualDataModel, project: Project, assets: AssetsModel) =>
			dispatch(returnObjectForTeamEntityUpdate(teamData, project, assets)),
		saveTeamAssets: (payload: { teamId: string; project: Project; assets: AssetsModel }) => dispatch(postTeamAssets(payload)),
		getTeamEntity: (id: string, project: Project) => dispatch(returnObjectForTeamEntityRequest(id, project)),
		updateSearchFilterTeam: (text: string) => dispatch(teamSearchFilterUpdate(text)),
	};
}

export default compose(connect(mapStateToProps, mapDispatchToProps), withRouter)(TeamEditComponent);
