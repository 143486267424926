import React, { ChangeEvent, useEffect, useState } from 'react';
import { ImageInput } from './image-input.component';
import { Col, Label, Row, FormGroup, Input, Tooltip } from 'reactstrap';
import { Properties } from './properties/image-properties.properties';
import ImageBlockModel from '../../blocks/content/image-block/models/image-block.model';
import GalleryBlockModel from '../../blocks/content/gallery-block/models/gallery-block.model';
import ContentWidget from '../../blocks/content/content-block/models/content-widget.model';
import AuthorSelectMultiContainer from '../../../Sidebar/GeneralContentAttributes/subcomponents/AuthorSelectMulti/AuthorSelectMultiContainer';
import Author from '../../../../../models/author/Author';
import { extractRatios } from './image-ratios/ImageRatiosHelper';
import ImageRatioSelectContainer from './image-ratios/ImageRatioSelectContainer';
import { featuresService } from '../../../../../App';
import { FeatureTypes } from '../../../../../services/feature-service/features.enum';
import { checkIfValidForWatermark, extractPathNameFromImage } from '../../../../Resources/Images/Subcomponents/helpers/image-cells.helpers';
import { toast } from 'react-toastify';
import WatermarkService from '../../../../../services/watermark-service/WatermarkService';
import Watermark from '../../../../../models/watermarks/Watermark';
import './image-properties.scss';
import { ContentTypes } from '../../../../../constants/content-types';
import { openTypes } from '../../constants/link.constants';

export const OPEN_TYPES = { newWindow: 'NEW_WINDOW', sameWindow: 'SAME_WINDOW' };

export const ImageProperties: React.FunctionComponent<Properties> = ({
	t,
	blockId,
	blockData,
	onChange,
	isImageBlock,
	contentType,
	authors,
	displayDescriptionCheckbox,
	owners,
	hideWatermarkCheckbox,
}) => {
	let model: ImageBlockModel | GalleryBlockModel | ContentWidget;
	let watermarkService = new WatermarkService();

	const [defaultWatermark, setDefaultWatermark] = useState(Watermark.builder().build());
	const [isToolTipOpen, setToolTipOpen] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		initWaterMarkService();
		watermarkService.getDefaultWatermark().then((resp) => {
			setDefaultWatermark(resp);
		});
	}, []);

	const initWaterMarkService = () => {
		if (featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.WATERMARK)) {
			watermarkService = new WatermarkService();
			return true;
		} else return false;
	};

	const onInputChange = (e: any, type: string) => {
		switch (contentType) {
			case 'content':
				model =
					type === 'width'
						? ContentWidget.builder(blockData).withWidth(e.target.value).build()
						: ContentWidget.builder(blockData).withAlignment(e.target.value).build();
				break;
			case 'image':
				model =
					type === 'width'
						? ImageBlockModel.builder(blockData).withWidth(e.target.value).build()
						: ImageBlockModel.builder(blockData).withAlignment(e.target.value).build();
				break;
			case 'gallery':
				model =
					type === 'width'
						? GalleryBlockModel.builder(blockData).withWidth(e.target.value).build()
						: GalleryBlockModel.builder(blockData).withAlignment(e.target.value).build();
				break;
			default:
				break;
		}
		onChange(model);
	};

	const updateDescription = (e: any) => {
		onChange(ImageBlockModel.builder(blockData).withDescription(e.target.value).withAddDescriptionToMedia(true).build());
	};

	const onAuthorChange = (authors: Author[]) => {
		onChange(ImageBlockModel.builder(blockData).withAuthors(authors).build());
	};

	const onAddDescriptionToImageChecked = (e: any, item: any) => {
		onChange(ImageBlockModel.builder(item).withAddDescriptionToMedia(e.target.checked).build());
	};

	const onImageRatioChange = (imageRatio: any) => {
		onChange(ImageBlockModel.builder(blockData).withImageRatio(imageRatio).build());
	};

	const onImageAltChange = (event: any) => {
		onChange(ImageBlockModel.builder(blockData).withAlt(event.target.value).build());
	};

	const onImageCaptionChange = (event: any) => {
		onChange(ImageBlockModel.builder(blockData).withCaption(event.target.value).build());
	};

	const updateLinkContent = (link: string, linkText: string, openType: string) => {
		const target = openType === OPEN_TYPES.newWindow ? 'target="_blank"' : 'target="_self"';

		return `<a href="${link}" ${target}>${linkText}</a>`;
	};

	const onImageLinkChange = (event: ChangeEvent<HTMLInputElement>) => {
		const content = updateLinkContent(event.target.value, blockData.linkText, blockData.linkOpenType);

		onChange(ImageBlockModel.builder(blockData).withLink(event.target.value).withLinkContent(content).build());
	};

	const onImageLinkTextChange = (event: ChangeEvent<HTMLInputElement>) => {
		const content = updateLinkContent(blockData.link, event.target.value, blockData.linkOpenType);

		onChange(ImageBlockModel.builder(blockData).withLinkText(event.target.value).withLinkContent(content).build());
	};

	const onImageLinkTargetChange = (event: ChangeEvent<HTMLInputElement>) => {
		const content = updateLinkContent(blockData.link, blockData.linkText, event.target.value);

		onChange(ImageBlockModel.builder(blockData).withLinkOpenType(event.target.value).withLinkContent(content).build());
	};

	const onAddWatermarkChecked = (event: any) => {
		onChange(ImageBlockModel.builder(blockData).withAddWatermarkChecked(event.target.checked).build());
		setIsLoading(true);
		let imagePath = extractPathNameFromImage(blockData.image);

		if (imagePath && imagePath.length > 0) {
			watermarkService
				.addWatermarkSingleImage(blockData.image.path, blockData.image.id, defaultWatermark.id)
				.then((resp: any) => {
					onChange(ImageBlockModel.builder(blockData).withImage(resp.data.data).build());
					setIsLoading(false);
					toast.success(t('add_watermark_success'));
				})
				.catch((error) => {
					setIsLoading(false);
					toast.error(t('add_watermark_failed'));
					console.log(error);
				});
		}
	};

	const onRemoveWatermarkChecked = (event: any) => {
		onChange(ImageBlockModel.builder(blockData).withRemoveWatermarkChecked(event.target.checked).build());
		setIsLoading(true);
		let imagePath = extractPathNameFromImage(blockData.image);

		if (imagePath && imagePath.length > 0) {
			watermarkService
				.deleteWatermarkSingleImage(blockData.image.path, blockData.image.id)
				.then((resp: any) => {
					onChange(ImageBlockModel.builder(blockData).withImage(resp.data.data).build());
					setIsLoading(false);
					toast.success(t('delete_watermark_success'));
				})
				.catch((error) => {
					setIsLoading(false);
					toast.error(t('delete_watermark_failed'));
					console.log(error);
				});
		}
	};

	const toggle = () => setToolTipOpen(!isToolTipOpen);

	const extractedRatios = extractRatios(blockData.image);

	const width = blockData.width;
	const alignment = blockData.alignment;
	const alt = blockData.alt;
	const caption = blockData.caption;
	const linkOpenType = blockData.linkOpenType;

	return (
		<>
			{isImageBlock && (
				<>
					{!hideWatermarkCheckbox &&
						featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.WATERMARK) &&
						checkIfValidForWatermark(blockData.image, owners) &&
						((blockData.image && !blockData.image.generic) || !blockData.image.generic.watermark) && (
							<Row className={isLoading ? 'loading-overlay' : ''}>
								<Col>
									{!isLoading && (
										<FormGroup key={'formgroup-add-watermark-image-block-key'}>
											<input type='checkbox' className='mx-2 mb-0' id='add-watermark-image-block' onChange={(event) => onAddWatermarkChecked(event)} />
											<Label htmlFor='watermark'>{t('add_watermark')}</Label>
											<div className='watermark-tooltip' id='image-blocky-add-watermark-checkbox-tooltip'>
												<i className='mx-2 mb-0 fa fa-info-circle text-muted mr-1' />
												<Tooltip placement='bottom-start' isOpen={isToolTipOpen} target='image-blocky-add-watermark-checkbox-tooltip' toggle={toggle}>
													{t('add_watermark_checkbox')}
												</Tooltip>
											</div>
										</FormGroup>
									)}
								</Col>
							</Row>
						)}
					{!hideWatermarkCheckbox &&
						featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.WATERMARK) &&
						blockData.image &&
						blockData.image.generic &&
						blockData.image.generic.watermark && (
							<Row className={isLoading ? 'loading-overlay' : ''}>
								<Col>
									{!isLoading && (
										<FormGroup key={'formgroup-remove-watermark-image-block-key'}>
											<input
												type='checkbox'
												className='mx-2 mb-0'
												id='remove-watermark-image-block'
												onChange={(event) => onRemoveWatermarkChecked(event)}
											/>
											<Label htmlFor='watermark'>{t('remove_watermark')}</Label>
											<div className='watermark-tooltip' id='image-blocky-remove-watermark-checkbox-tooltip'>
												<i className='fa fa-info-circle text-muted mr-1' />
												<Tooltip
													placement='bottom-start'
													isOpen={isToolTipOpen}
													target='image-blocky-remove-watermark-checkbox-tooltip'
													toggle={toggle}
												>
													{t('remove_watermark_checkbox')}
												</Tooltip>
											</div>
										</FormGroup>
									)}
								</Col>
							</Row>
						)}
					<Row>
						<Col>
							<FormGroup>
								<Label>{t('image_description')}:</Label>
								<Input
									type='text'
									placeholder={`${t('image_description_placeholder')}`}
									value={
										blockData.description !== null && blockData.description !== undefined
											? blockData.description
											: blockData.image && blockData.image.description
											? blockData.image.description
											: ''
									}
									onChange={updateDescription}
								/>
								{blockData.description && blockData.description.length > 255 && (
									<label className='text-danger' htmlFor={`image-block-description-error-message-${blockData.id}`}>
										{t('description_limit_exceed')}
									</label>
								)}
							</FormGroup>
							{displayDescriptionCheckbox && (
								<FormGroup>
									<Row className='mt-2'>
										<Col>
											<input
												className={'mr-1'}
												id={`image-block-description-checkbox-${blockData.id}`}
												checked={!!(blockData && blockData.description && blockData.addDescriptionToMedia)}
												onChange={(event: any) => onAddDescriptionToImageChecked(event, blockData)}
												type={'checkbox'}
												key={`image-block-description-checkbox-${blockData.id}`}
											/>
											<label htmlFor={`image-block-description-checkbox-${blockData.id}`}>{t('add_description_to_image')}</label>
										</Col>
									</Row>
								</FormGroup>
							)}
						</Col>
					</Row>
					<Row className='mb-3'>
						<Col>
							<Label>{t('authors')}</Label>
							<AuthorSelectMultiContainer
								authors={authors}
								selectedAuthors={
									blockData.authors !== null && blockData.authors !== undefined
										? blockData.authors
										: blockData.image && blockData.image.authors
										? blockData.image.authors
										: []
								}
								onAuthorSelect={onAuthorChange}
								t={t}
							/>
						</Col>
					</Row>
					{extractedRatios && extractedRatios.length >= 1 && (
						<Row className='mb-3'>
							<Col>
								<Label>{t('ratios')}</Label>
								<ImageRatioSelectContainer
									imageRatios={extractedRatios}
									selectedImageRatio={
										blockData.imageRatio !== null && blockData.imageRatio !== undefined
											? blockData.imageRatio
											: blockData.image && blockData.image.urls
											? blockData.image.urls
											: []
									}
									onImageRatioSelect={onImageRatioChange}
									t={t}
								/>
							</Col>
						</Row>
					)}
				</>
			)}
			<Row>
				<Col>
					<Label className='font-weight-bold'>{t('image_properties')}</Label>
				</Col>
			</Row>
			<Row>
				<Col>
					<Label className='mr-2'>{t('image_width')}:</Label>
					<ImageInput blockId={blockId} type='width' modelVal={width} onChange={onInputChange} value='25' label='25%' />
					<ImageInput blockId={blockId} type='width' modelVal={width} onChange={onInputChange} value='50' label='50%' />
					<ImageInput blockId={blockId} type='width' modelVal={width} onChange={onInputChange} value='75' label='75%' />
					<ImageInput blockId={blockId} type='width' modelVal={width} onChange={onInputChange} value='100' label='100%' />
				</Col>
			</Row>
			<Row>
				<Col>
					<Label className='mr-2'>{t('image_alignment')}:</Label>
					<ImageInput blockId={blockId} type='align' modelVal={alignment} onChange={onInputChange} value='left' label={t('left')} />
					<ImageInput blockId={blockId} type='align' modelVal={alignment} onChange={onInputChange} value='center' label={t('center')} />
					<ImageInput blockId={blockId} type='align' modelVal={alignment} onChange={onInputChange} value='right' label={t('right')} />
				</Col>
			</Row>
			{contentType === ContentTypes.IMAGE && (
				<Row>
					<Col>
						<FormGroup>
							<Label className='mr-2'>{t('image_alt')}:</Label>
							<Input
								key={`input-image-${blockId}-alt`}
								type='text'
								id={`input-image-${blockId}-${alt}--alt`}
								value={
									blockData.alt !== null && blockData.alt !== undefined
										? blockData.alt
										: blockData.image && blockData.image.alt
										? blockData.image.alt
										: ''
								}
								placeholder={t('enter_image_alt')}
								onChange={onImageAltChange}
							/>
							{blockData.alt && blockData.alt.length > 255 && (
								<label className='text-danger' htmlFor={`image-block-alt-error-message-${blockData.id}`}>
									{t('alt_limit_exceed')}
								</label>
							)}
						</FormGroup>
					</Col>
				</Row>
			)}
			{contentType === ContentTypes.IMAGE && (
				<Row>
					<Col>
						<FormGroup>
							<Label className='mr-2'>{t('image_caption')}:</Label>
							<Input
								key={`input-image-${blockId}-caption`}
								type='textarea'
								rows='3'
								id={`input-image-${blockId}-${caption}-caption`}
								value={
									blockData.caption !== null && blockData.caption !== undefined
										? blockData.caption
										: blockData.image && blockData.image.caption
										? blockData.image.caption
										: ''
								}
								placeholder={t('enter_image_caption')}
								onChange={onImageCaptionChange}
							/>
							{blockData.caption && blockData.caption.length > 255 && (
								<label className='text-danger' htmlFor={`image-block-caption-error-message-${blockData.id}`}>
									{t('caption_limit_exceed')}
								</label>
							)}
						</FormGroup>
					</Col>
				</Row>
			)}
			{contentType === ContentTypes.IMAGE && (
				<>
					<Row>
						<Col>
							<FormGroup>
								<Label className='mr-2'>{t('link')}:</Label>
								<Input type='text' value={blockData.link} placeholder={t('enter_link')} onChange={onImageLinkChange} />
							</FormGroup>
						</Col>
					</Row>
					<Row>
						<Col>
							<FormGroup>
								<Label>{t('text')}:</Label>
								<Input type='textarea' rows={5} value={blockData.linkText} placeholder={t('enter_link_text')} onChange={onImageLinkTextChange} />
							</FormGroup>
						</Col>
					</Row>
					<Row className='mb-3'>
						<Col>
							<div className='form-check form-check-inline'>
								<Input
									className='form-check-input'
									type='radio'
									key={`input-image-link-${blockId}-${blockData.linkOpenType}`}
									onChange={(e: ChangeEvent<HTMLInputElement>) => onImageLinkTargetChange(e)}
									id={`open-in-new-${blockId}`}
									checked={linkOpenType === openTypes['newWindow']}
									value={openTypes['newWindow']}
								/>
								<Label className='form-check-label' check htmlFor={`open-in-new-${blockData.id}`}>
									{t('open_in_new_window')}
								</Label>
							</div>
							<div className='form-check form-check-inline'>
								<Input
									className='form-check-input'
									type='radio'
									key={`input-image-link-${blockId}-${blockData.linkOpenType}`}
									onChange={(e: ChangeEvent<HTMLInputElement>) => onImageLinkTargetChange(e)}
									id={`open-in-same-${blockId}`}
									checked={linkOpenType === openTypes['sameWindow']}
									value={openTypes['sameWindow']}
								/>
								<Label className='form-check-label' check htmlFor={`open-in-same-${blockData.id}`}>
									{t('open_in_the_same_window')}
								</Label>
							</div>
						</Col>
					</Row>
				</>
			)}
		</>
	);
};
