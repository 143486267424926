import { SquadStatisticsWidgetJson } from './squad-statistics-widget.json';
import SquadStatisticsWidgetBuilder from './squad-statistics-widget.builder';
import TeamModel from '../../../../partials/team-select/models/team.model';
import SeasonModel from '../../player-profile/models/season/season.model';

export default class SquadStatisticsWidgetModel {
	readonly team: TeamModel;
	readonly season: SeasonModel;
	readonly noData: boolean;
	readonly playerPosition: any[];
	readonly statisticColumns: any[];

	private constructor(team: TeamModel, playerPosition: any[], statisticColumns: any[], noData: boolean, season: SeasonModel) {
		this.team = team;
		this.season = season;
		this.noData = noData;
		this.playerPosition = playerPosition;
		this.statisticColumns = statisticColumns;
	}

	toJson(): SquadStatisticsWidgetJson {
		return {
			team: this.team,
			season: this.season,
			playerPosition: this.playerPosition,
			statisticColumns: this.statisticColumns,
			noData: this.noData,
		};
	}

	static fromJson(json: SquadStatisticsWidgetJson): SquadStatisticsWidgetModel {
		return new SquadStatisticsWidgetModel(json.team, json.playerPosition, json.statisticColumns, json.noData, json.season);
	}

	static builder(team?: SquadStatisticsWidgetModel): SquadStatisticsWidgetBuilder {
		return new SquadStatisticsWidgetBuilder(team);
	}
}
