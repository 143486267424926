import 'reflect-metadata';
import { jsonMember, jsonObject } from 'typedjson';

@jsonObject()
export default class PaginationMeta {
	@jsonMember(Number)
	public count: number = 0;
	@jsonMember(Number, { name: 'current_page' })
	public currentPage: number = 0;
	@jsonMember(Number, { name: 'per_page' })
	public perPage: number = 0;
	@jsonMember(Number)
	public total: number = 0;
	@jsonMember(Number, { name: 'total_pages' })
	public totalPages: number = 0;
	@jsonMember(Number)
	public limit: number = 0;
	@jsonMember(Number)
	public offset: number = 0;
}
