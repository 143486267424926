import React, { useEffect, useState } from 'react';
import { Col, Label, Row } from 'reactstrap';
import HttpService from '../../../../../../services/rest/HttpService';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../../../store/store';
import { mapResponseListToPlayerSeasonsModel, playerSeasonToOption } from '../helpers/player-select/player-season-select-component.helper';
import Select from 'react-select';
import { createTournamentGroupOptions } from '../../../partials/player-seasons/helpers/players-seasons-select.helper';
import { customOption } from '../../../partials/shared/custom-select-option';
import EntitySeasonsSelectModel from '../../../partials/player-seasons/model/entity-seasons-select.model';
import ErrorHandler from '../error-handler/error-handler';
import { ContentTypes } from '../../../../../../constants/content-types';
import { useTranslation } from 'react-i18next';

type Properties = {
	onEntitySeasonSelect: (season: EntitySeasonsSelectModel | null) => void;
	onPlayerTeamNameSelect?: (teamName: string) => void;
	selectedSeason: EntitySeasonsSelectModel;
	isValid: boolean;
	isMulti?: boolean;
	isClearable?: boolean;
	isRequired?: boolean;
	isBlocky: boolean;
	className?: string;
	preselectSeason?: boolean;
	entityId: string | number;
	entityType: string;
	id: string;
};

const EntitySeasonSelectContainer: React.FunctionComponent<Properties> = (props) => {
	const [t] = useTranslation();
	const [entitySeasonsOptions, setEntitySeasonsOptions] = useState<EntitySeasonsSelectModel[]>([]);
	const projectLanguage = useSelector((state: AppState) => state.project.currentProject.language);

	useEffect(() => {
		if (props.entityId && props.entityType === ContentTypes.PLAYER) {
			requestPlayerSeasonInformation(props.entityId);
		}

		if (props.entityId && props.entityType === ContentTypes.TEAM) {
			requestTeamSeasonInformation(props.entityId);
		}

		if (!props.entityId) {
			setEntitySeasonsOptions([]);
		}
	}, [props.entityId, props.selectedSeason]);

	const updateEntitySeasonsOptionsState = (seasons: any[]) => {
		const { onEntitySeasonSelect, selectedSeason, isBlocky, isMulti, preselectSeason } = props;
		if (
			(preselectSeason && !selectedSeason) ||
			(selectedSeason && (selectedSeason.seasonId === undefined || selectedSeason.seasonId.length < 1) && !isMulti) ||
			(isMulti && isBlocky)
		) {
			onEntitySeasonSelect(seasons[0].options[0].data);
		}
		setEntitySeasonsOptions(seasons);
	};

	const requestPlayerSeasonInformation = (playerId: number) => {
		props.onPlayerTeamNameSelect && props.onPlayerTeamNameSelect('');
		HttpService.getPlayerSeasonStatistics(playerId, projectLanguage)
			.then((response: any) => {
				const modelList = mapResponseListToPlayerSeasonsModel(response.data);
				const options = createTournamentGroupOptions(modelList);
				updateEntitySeasonsOptionsState(options);
				response.data.map((item: any) => {
					if (item.season.id === selectedSeason.seasonId) {
						props.onPlayerTeamNameSelect && props.onPlayerTeamNameSelect(item.team.name);
					}
				});
			})
			.catch((e: any) => e);
	};

	const requestTeamSeasonInformation = (teamId: any) => {
		HttpService.getSeasonsStatisticsByTeamId(teamId, projectLanguage)
			.then((response: any) => {
				const modelList = mapResponseListToPlayerSeasonsModel(response.data);
				const options = createTournamentGroupOptions(modelList);
				updateEntitySeasonsOptionsState(options);
			})
			.catch((e: any) => e);
	};

	const { isRequired, onEntitySeasonSelect, selectedSeason, isValid, isClearable } = props;
	return (
		<Row data-qa={props.id}>
			<Col>
				<Label htmlFor={props.id}>{t('tournament_seasons')}:</Label>
				{isRequired && <span className='text-danger'>*</span>}
				<Select
					id={props.id}
					menuPortalTarget={document.body}
					menuPosition='absolute'
					placeholder={t('select')}
					isClearable={isClearable}
					formatOptionLabel={customOption}
					options={entitySeasonsOptions}
					value={selectedSeason && selectedSeason.seasonId && playerSeasonToOption(selectedSeason)}
					noOptionsMessage={(inputValue) => inputValue && t('no_options')}
					onChange={(selection: any) => (selection ? onEntitySeasonSelect(selection.data) : onEntitySeasonSelect(null))}
					styles={{
						menuPortal: (baseStyles) => ({
							...baseStyles,
							zIndex: 10000,
						}),
					}}
				/>
				{isRequired && <ErrorHandler isValueSelected={!selectedSeason} arePropsValid={isValid} t={t} errorMessage='field_is_required' />}
			</Col>
		</Row>
	);
};

export default EntitySeasonSelectContainer as React.ComponentType<Properties>;
