import React from 'react';
import { Col, FormGroup, Label, Row, Input } from 'reactstrap';
import TeamModel from '../../../../partials/team-select/models/team.model';
import TeamSelect from '../../../../partials/team-select/team-select.component';
import { State } from './properties/team-profile-edit.properties';
import { Properties } from './properties/team-profile-edit.properties';
import TeamProfileWidgetModel from '../models/team-profile-widget.model';
import OddsWidgetModel from '../../odds/models/odds-widget.model';
import OddsWidgetEdit from '../../odds/components/odds-widget-edit.component';
import { blockWrapperService, blockManagementService } from '../../../../subcomponents/blocky.component';
import BlockValidation from '../../../../helpers/block-validation.helper';
import { featuresService } from '../../../../../../../App';
import { FeatureTypes } from '../../../../../../../services/feature-service/features.enum';
import { prefillMatchFromRelated } from '../../helpers/widgets-prefill.helpers';
import MatchModel from '../../../../partials/match-select/models/match.model';
import ThreeLetterCodeTypeSelect from '../../../../partials/three-letter-code-type-select/three-letter-code-type-select';
import SeasonSelectByIdContainer from '../../../../partials/season-select/season-select-by-id.component.jsx';
import SeasonModel from '../../fixtures/models/season/season.model';

export default class TeamProfileEdit extends React.Component<Properties, State> {
	constructor(props: Properties) {
		super(props);
		const matchContentData = props.contentData
			? prefillMatchFromRelated(props.contentData.related.matches)
			: MatchModel.builder().withHomeTeam(TeamModel.builder().build()).build();

		this.state = {
			teamProfile:
				props.block && props.block.data && props.block.data.preview
					? TeamProfileWidgetModel.builder(props.block.data.preview.teamProfile).build()
					: TeamProfileWidgetModel.builder()
							.withTeam(matchContentData.homeTeam)
							.withDisplayStatistics(true)
							.withDisplayOdds(false)
							.withThreeLetterCodeType('')
							.build(),
			oddsModel:
				props.block && props.block.data && props.block.data.preview && props.block.data.preview.odds
					? OddsWidgetModel.builder(props.block.data.preview.odds).build()
					: OddsWidgetModel.builder().withBookmakers([]).withCanSelectMarkets(false).withDisplayHeader(false).build(),
			isValid: true,
		};
	}

	componentDidMount(): void {
		blockWrapperService.registerOnSaveFunc({ blockId: this.props.block.id, func: this.onSave });
	}

	onSave = (index: number) => {
		const data = { teamProfile: this.state.teamProfile, odds: this.state.oddsModel };
		const validation = new BlockValidation();
		const isValid = validation.validate(this.props.block, data);

		this.setState({ ...this.state, isValid });

		const bookmakerSelectionEnabled = featuresService.areFeaturesEnabled([FeatureTypes.ALLOW_BOOKMAKER_SELECTION]);
		const odds = OddsWidgetModel.builder(this.state.oddsModel)
			.withBookmakers(bookmakerSelectionEnabled ? this.state.oddsModel.bookmakers : [])
			.withTeam(this.state.teamProfile.team)
			.build();

		if (isValid) {
			const block = Object.assign({}, this.props.block);
			block.data.preview = { teamProfile: this.state.teamProfile, odds };

			blockManagementService.blockUpdate(block, index);
		} else {
			return isValid;
		}
	};

	componentWillUnmount(): void {
		blockWrapperService.unregisterOnSaveFunc(this.props.block.id);
	}

	onTeamChange = (team: TeamModel, season: SeasonModel) => {
		this.setState({
			...this.state,
			teamProfile: TeamProfileWidgetModel.builder(this.state.teamProfile).withTeam(team).withSeason(season).build(),
			isValid: true,
		});
	};

	updateWidgetModelState = (model: TeamProfileWidgetModel) => {
		this.setState({ ...this.state, teamProfile: model });
	};

	onSeasonChange = (season: SeasonModel) => {
		this.updateWidgetModelState(TeamProfileWidgetModel.builder(this.state.teamProfile).withSeason(season).build());
	};

	onDisplayStatisticsChange = (event: any) => {
		this.setState({
			...this.state,
			teamProfile: TeamProfileWidgetModel.builder(this.state.teamProfile).withDisplayStatistics(event.target.checked).build(),
		});
	};

	onDisplayOddsChange = (event: any) => {
		this.setState({
			...this.state,
			teamProfile: TeamProfileWidgetModel.builder(this.state.teamProfile).withDisplayOdds(event.target.checked).build(),
		});
	};

	isTeamSelected = (team: TeamModel) => {
		return team !== undefined && team !== null && team.id !== null && team.id !== undefined;
	};

	onOddsWidgetChange = (oddsModel: OddsWidgetModel) => this.setState({ ...this.state, oddsModel });

	onThreeLetterCodeTypeChange = (value: any) => {
		this.setState({
			...this.state,
			teamProfile: TeamProfileWidgetModel.builder(this.state.teamProfile).withThreeLetterCodeType(value).build(),
		});
	};

	render() {
		const { teamProfile, isValid } = this.state;
		const { t, block, contentData } = this.props;

		return (
			<>
				<Row>
					<Col>
						<FormGroup>
							<TeamSelect
								inputId='team-profile-team-select'
								isMulti={false}
								t={t}
								value={teamProfile.team ? teamProfile.team : {}}
								onSelect={(team: TeamModel, season: SeasonModel) => this.onTeamChange(team, season)}
								isValid={this.state.isValid}
							/>
						</FormGroup>
					</Col>
				</Row>
				{teamProfile.team && teamProfile.team.id && teamProfile.team.id.toString().length > 0 && (
					<Row>
						<Col>
							<FormGroup>
								<SeasonSelectByIdContainer
									withLabel
									isValid={isValid}
									t={this.props.t}
									selectedSeason={teamProfile.season}
									onSeasonSelect={this.onSeasonChange}
									teamId={teamProfile.team && teamProfile.team.id ? teamProfile.team.id : ''}
								/>
							</FormGroup>
						</Col>
					</Row>
				)}
				<ThreeLetterCodeTypeSelect
					t={this.props.t}
					isClearable={true}
					onThreeLetterCodeTypeChange={this.onThreeLetterCodeTypeChange}
					selectedThreeLetterCodeType={teamProfile.threeLetterCodeType}
				/>
				<Row className={'mb-2'}>
					<Col>
						<FormGroup key={`display-header`} check className='radio'>
							<Input
								key={`display-header-input`}
								className='form-check-input'
								type='checkbox'
								id={`display-header-checkbox-${block.id}`}
								name='radios'
								onChange={this.onDisplayStatisticsChange}
								checked={teamProfile.displayStatistics}
							/>
							<Label key={`display-header-label`} check className='form-check-label' for={`display-header-checkbox-${block.id}`}>
								{t('display_statistics')}
							</Label>
						</FormGroup>
					</Col>
				</Row>

				<Row className={'mb-2'}>
					<Col>
						<FormGroup key={`display-odds-markets`} check className='radio'>
							<Input
								key={`display-odds-markets-input`}
								className='form-check-input'
								type='checkbox'
								id={`display-odds-markets-checkbox-${block.id}`}
								name='radios'
								onChange={this.onDisplayOddsChange}
								checked={teamProfile.displayOdds}
							/>
							<Label key={`display-odds-markets-label`} check className='form-check-label' for={`display-odds-markets-checkbox-${block.id}`}>
								{t('display_odds')}
							</Label>
						</FormGroup>
					</Col>
				</Row>
				<Row className={'mb-3'}>
					<Col>
						{teamProfile.displayOdds && teamProfile.team && teamProfile.team.id && (
							<OddsWidgetEdit
								block={block}
								contentData={contentData}
								isStandAlone={false}
								useTeamSelect={false}
								useMatchSelect={true}
								team={teamProfile.team}
								t={t}
								onOddsWidgetChange={this.onOddsWidgetChange}
								autoSelectFirstMatch={true}
								displayOddsLabel={true}
								isValid={this.state.isValid}
							/>
						)}
					</Col>
				</Row>
			</>
		);
	}
}
