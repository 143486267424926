import { toast } from 'react-toastify';
import i18n from '../../i18n';
import {
	PROFILE_PASSWORD_UPDATE,
	PROFILE_PASSWORD_UPDATE_FAILED,
	PROFILE_PASSWORD_UPDATE_SUCCESS,
	PROFILE_UPDATE,
	PROFILE_UPDATE_FAILED,
	PROFILE_UPDATE_SUCCESS,
} from '../action-creators/ProfileActionCreators';
import { actionService } from '../../App';
import RequestMapper from '../../models/RequestMapper';

/**
 * Remap data coming from the API to the model defined in the application
 */
export function profileMiddleware() {
	return function (next: any) {
		return function (action: any) {
			if (action) {
				if (action.type === PROFILE_UPDATE) {
					actionService.emitGeneralAction(PROFILE_UPDATE);
					action.payload.originalProfile = action.payload.profile;
					action.payload.profile = RequestMapper.mapProfile(action.payload.profile);
				}

				if (action.type === PROFILE_UPDATE_FAILED) {
					actionService.emitGeneralAction(PROFILE_UPDATE_FAILED);
					toast.error(i18n.t('profile_update_failed'));
				}

				if (action.type === PROFILE_UPDATE_SUCCESS) {
					actionService.emitGeneralAction(PROFILE_UPDATE_SUCCESS);
					toast.success(i18n.t('profile_update_success'));
				}

				if (action.type === PROFILE_PASSWORD_UPDATE) {
					actionService.emitGeneralAction(PROFILE_PASSWORD_UPDATE);
					action.payload.originalProfile = action.payload.profile;
					action.payload = RequestMapper.mapProfilePassword(action.payload.password);
				}

				if (action.type === PROFILE_PASSWORD_UPDATE_FAILED) {
					actionService.emitGeneralAction(PROFILE_PASSWORD_UPDATE_FAILED);
					toast.error(i18n.t('profile_password_update_failed'));
				}

				if (action.type === PROFILE_PASSWORD_UPDATE_SUCCESS) {
					actionService.emitGeneralAction(PROFILE_PASSWORD_UPDATE_SUCCESS);
					toast.success(i18n.t('profile_password_update_success'));
				}
			}

			next(action);
		};
	};
}
