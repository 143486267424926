export const REQUEST_ARTICLE_COMMENTS_POLICIES_RECEIVED = '[ACTION] REQUEST_ARTICLE_COMMENTS_POLICIES_RECEIVED';
export const REQUEST_VIDEO_COMMENTS_POLICIES_RECEIVED = '[ACTION] REQUEST_VIDEO_COMMENTS_POLICIES_RECEIVED';
export const REQUEST_GALLERY_COMMENTS_POLICIES_RECEIVED = '[ACTION] REQUEST_GALLERY_COMMENTS_POLICIES_RECEIVED';
export const REQUEST_POLLS_COMMENTS_POLICIES_RECEIVED = '[ACTION] REQUEST_POLLS_COMMENTS_POLICIES_RECEIVED';

export const requestArticleCommentsPoliciesReceived = (commentsPolicies: any) => ({
	type: REQUEST_ARTICLE_COMMENTS_POLICIES_RECEIVED,
	payload: commentsPolicies,
});

export const requestVideoCommentsPoliciesReceived = (commentsPolicies: any) => ({
	type: REQUEST_VIDEO_COMMENTS_POLICIES_RECEIVED,
	payload: commentsPolicies,
});

export const requestPollsCommentsPoliciesReceived = (commentsPolicies: any) => ({
	type: REQUEST_POLLS_COMMENTS_POLICIES_RECEIVED,
	payload: commentsPolicies,
});

export const requestGalleryCommentsPoliciesReceived = (commentsPolicies: any) => ({
	type: REQUEST_GALLERY_COMMENTS_POLICIES_RECEIVED,
	payload: commentsPolicies,
});
