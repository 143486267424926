import React from 'react';
import { Button, Col, Input, Row } from 'reactstrap';
import MatchScoreModel from '../../models/match/match-score.model';
import { scoreUpdateMethods } from '../helpers/match-details.helper';

type Properties = {
	t: any;
	score: MatchScoreModel | null;
	onChange: (score: MatchScoreModel) => void;
	updateDropdown: (scoreType: string) => void;
};

const MatchScoreComponent: React.FunctionComponent<Properties> = ({ t, score, onChange, updateDropdown }) => {
	// The *scoreType argument corresponds to: 'total', 'halfTime', ... and is used to define the correct model update method: 'withTotal', 'withHalfTime', ...
	// The *team argument corresponds to 'home'/'away' property in each score type in the MatchScoreModel. Ex.: halfTime:  { home: number, away: number }
	// The *scoreByType argument is the current value, for ex.: { home: number, away: number } of score type property in the MatchScoreModel.
	const onInputChange = (e: any, scoreType: string, team: string, scoreByType: any) => {
		const newScore = MatchScoreModel.builder(score)
			[scoreUpdateMethods[scoreType]]({ ...scoreByType, [team]: Number(e.target.value) })
			.build();
		// The method output is the new MatchScoreModel with updated home/away score in total, halfTime,
		// regularTime, extraTime, penaltyShootout and, aggregate score types in the model.
		onChange(newScore);
	};

	// The onScoreRemove method updates MatchScoreModel score type with null value.
	const onScoreRemove = (scoreType: string) => {
		onChange(MatchScoreModel.builder(score)[scoreUpdateMethods[scoreType]](null).build());
		updateDropdown(scoreType);
	};

	return (
		<Row className='p-3 bg-light border-left border-right  animated fadeIn'>
			{score && // The *score prop is the MatchScoreModel in MatchDetailsModel.
				Object.keys(score).map((scoreItem: any) => {
					if (score[scoreItem]) {
						// The *scoreItem is the corresponding prop in the model: total, halfTime, ...
						return (
							<Col key={scoreItem} xs='4' md='2' className='text-center my-1'>
								<div className='w-100 bg-white rounded d-flex align-items-center justify-content-center'>
									<div className='text-truncate px-1'>{t(scoreItem)}</div>
									<Button color='base' outline className='p-0 m-1' onClick={() => onScoreRemove(scoreItem)}>
										<i title={t('remove')} className='text-muted fa fa-close' />
									</Button>
								</div>
								<Row>
									{
										// The *score[scoreItem] is the value of each prop in the MatchScoreModel: { home: number, away: number }.
										Object.keys(score[scoreItem]).map((teamSore: any) => {
											return (
												<Col key={`${scoreItem}-${teamSore}`}>
													<small className='p-1'>{t(teamSore)}</small>
													<Input
														min={0}
														type='number'
														className='text-center'
														value={score[scoreItem][teamSore]} // The value of *home or *away team in each score type prop of the model.
														id={`${scoreItem}-score-update-${teamSore}-team`}
														onChange={(e: any) => onInputChange(e, scoreItem, teamSore, score[scoreItem])}
													/>
												</Col>
											);
										})
									}
								</Row>
							</Col>
						);
					}
					return null;
				})}
		</Row>
	);
};

export default MatchScoreComponent;
