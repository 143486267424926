import { USER_LOGOUT } from '../action-creators/ProfileActionCreators';
import SportsTypesModel from '../../models/v2/sports-types/sports-types.model';
import { SPORTS_RECEIVED } from '../action-creators/ProjectResourcesActionCreators';
interface InitialState {
	sportsTypes: SportsTypesModel[];
}
const initialState: InitialState = {
	sportsTypes: [] as SportsTypesModel[],
};
function sportsReducer(state: any = initialState, action: any) {
	if (action.type === SPORTS_RECEIVED) {
		return Object.assign({}, state, {
			sportsTypes: action.payload.slice(),
		});
	}
	if (action.type === USER_LOGOUT) {
		state = initialState;
	}
	return state;
}
export default sportsReducer;
