import 'reflect-metadata';
import { AnyT, jsonMember, jsonObject } from 'typedjson';
import BlacklistModel from '../../blacklist/blaclist';
import TeamModel from '../../Team/Entity/TeamModel';

@jsonObject()
export default class MatchModel {
	@jsonMember(Number)
	public id: number = 0;

	@jsonMember(AnyT, { name: 'event_status' })
	public eventStatus: any;

	@jsonMember(String, { name: 'start_time' })
	public startTime: string = '';

	@jsonMember(String, { name: 'finished_at' })
	public finishedAt: string = '';

	@jsonMember(String, { name: 'started_at' })
	public startedAt: string = '';

	@jsonMember(TeamModel, { name: 'home_team' })
	public homeTeam: TeamModel = new TeamModel();

	@jsonMember(TeamModel, { name: 'away_team' })
	public awayTeam: TeamModel = new TeamModel();

	@jsonMember(Number, { name: 'goal_home' })
	public homeGoal: number = 0;

	@jsonMember(Number, { name: 'goal_away' })
	public awayGoal: number = 0;

	@jsonMember(AnyT, { name: 'tournament_season_stage' })
	public tournamentSeasonStage: any;

	@jsonMember(String)
	public type: string | null = null;

	@jsonMember(Number)
	public minutes: number | null = null;

	@jsonMember(String)
	public round: string | null = null;

	@jsonMember(String, { name: 'updated_at' })
	public updatedAt: string | null = null;

	@jsonMember(Boolean, { name: 'live_updates' })
	public liveUpdates: boolean | null = null;

	@jsonMember(Boolean, { name: 'lineup_available' })
	public lineupAvailable: boolean | null = null;

	@jsonMember(BlacklistModel)
	public blacklist: BlacklistModel | null = null;

	@jsonMember(Boolean)
	public isSelected: boolean = false;

	@jsonMember(String)
	public slug: string = '';
}
