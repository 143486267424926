import React, { useEffect, useState } from 'react';
import { DebounceInput } from 'react-debounce-input';
import Select from 'react-select';
import { SortableHandle } from 'react-sortable-hoc';
import { Button, Col, Row } from 'reactstrap';
import PlayerManualDataModel from '../../../../../models/player-manual-data/player-manual-data.model';
import { customOption } from '../../../../Partials/Blocky/partials/shared/custom-select-option';
import { itemsToOptions, itemToOption, optionToItem } from '../../../../Partials/Shared/react-select-helpers/react-select.helpers';
import EventModel from '../../models/event/event.model';
import { mapEventType } from '../helpers/events-remappers.helper';

type Properties = {
	t: any;
	eventPlayers: PlayerManualDataModel[];
	event: EventModel;
	onChange: (event: EventModel, index: number) => void;
	onRemove: (id: string) => void;
	itemIndex: number;
};

const DragHandle = SortableHandle(() => (
	<span className='cursor-draggable mr-2'>
		<i className='fa fa-bars'></i>
	</span>
));

const EventRow: React.FunctionComponent<Properties> = ({ t, eventPlayers, event, onChange, onRemove, itemIndex }) => {
	const [isActive, setActive] = useState(event.teamPosition === 'HOME' ? true : false);
	const options = itemsToOptions(eventPlayers, false, t, 'player');
	const onTeamChange = () => setActive((isActive) => !isActive);

	const onSelect = (value: any, type: string) => onChange(EventModel.builder(event)[type](value).build(), itemIndex);

	useEffect(() => onSelect(isActive ? 'HOME' : 'AWAY', 'withTeamPosition'), [isActive]);

	return (
		<Row className='p-1'>
			<Col md='3' className='mb-1'>
				<div className='d-flex align-items-center'>
					<DragHandle />
					<Select
						onChange={(selection: any) => onSelect(optionToItem(selection), 'withPrimaryPlayer')}
						value={itemToOption(event.primaryPlayer, false, t, 'player')}
						placeholder={t('select')}
						formatOptionLabel={customOption}
						inputId={`primary-player-select-${event.id}`}
						className='w-100'
						options={options}
					/>
				</div>
			</Col>
			<Col md='1' className='mb-1'>
				<div className='d-flex align-items-center'>
					<DebounceInput
						onChange={(e: any) => onSelect(e.target.value, 'withMinute')}
						value={event.minute ? event.minute : 0}
						className='px-1 form-control'
						placeholder={t('MM')}
						id={`event-minutes-input-${event.id}`}
						type='number'
						min={0}
						max={120}
					/>
				</div>
			</Col>
			<Col md='2' className='mb-1'>
				<Select
					onChange={(selection: any) => onSelect(optionToItem(selection).id, 'withTypeCode')}
					value={itemToOption(event.typeCode ? { id: event.typeCode, name: event.typeCode.toLowerCase() } : null, false, t, '')}
					placeholder={t('select')}
					inputId={`event-type-select-${event.id}`}
					className='w-100'
					options={itemsToOptions(mapEventType(), false, t, '')}
				/>
			</Col>
			<Col md='2' className='mb-1'>
				<div className='cursor-pointer px-1 d-flex align-items-center justify-content-center' onClick={onTeamChange}>
					<div
						id={`event-home-toggle-${event.id}`}
						className={`${
							event.teamPosition === 'HOME' ? 'bg-success' : 'bg-light opacity-06'
						} background-transition rounded-left border-muted p-2`}
					>
						{t('home')}
					</div>
					<div
						id={`event-away-toggle-${event.id}`}
						className={`${
							event.teamPosition === 'AWAY' ? 'bg-success' : 'bg-light opacity-06'
						} background-transition rounded-right border-muted p-2`}
					>
						{t('away')}
					</div>
				</div>
			</Col>
			<Col md='3' className='mb-1'>
				<Select
					onChange={(selection: any) => onSelect(optionToItem(selection), 'withSecondaryPlayer')}
					value={itemToOption(event.secondaryPlayer, false, t, 'player')}
					placeholder={t('select')}
					formatOptionLabel={customOption}
					inputId={`secondary-player-select-${event.id}`}
					className='w-100'
					options={options}
				/>
			</Col>
			<Col md='1' className='text-right mb-1'>
				<Button onClick={() => onRemove(event.id)} id={`event-remove-button-${event.id}`} color='danger'>
					<i className='fa fa-trash' />
				</Button>
			</Col>
		</Row>
	);
};

export default EventRow;
