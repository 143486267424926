export const REQUEST_ARTICLE_STATUSES_RECEIVED = '[ACTION] REQUEST_ARTICLE_STATUSES_RECEIVED';
export const REQUEST_VIDEO_STATUSES_RECEIVED = '[ACTION] REQUEST_VIDEO_STATUSES_RECEIVED';
export const REQUEST_GALLERY_STATUSES_RECEIVED = '[ACTION] REQUEST_GALLERY_STATUSES_RECEIVED';
export const REQUEST_POLLS_STATUSES_RECEIVED = '[ACTION] REQUEST_POLLS_STATUSES_RECEIVED';
export const REQUEST_TAG_STATUSES_RECEIVED = '[ACTION] REQUEST_TAG_STATUSES_RECEIVED';
export const REQUEST_WIKI_STATUSES_RECEIVED = '[ACTION] REQUEST_WIKI_STATUSES_RECEIVED';
export const REQUEST_LIST_STATUSES_RECEIVED = '[ACTION] REQUEST_LIST_STATUSES_RECEIVED';
export const REQUEST_BANNER_STATUSES_RECEIVED = '[ACTION] REQUEST_BANNER_STATUSES_RECEIVED';

export const requestArticleStatusesReceived = (statuses: any) => ({
	type: REQUEST_ARTICLE_STATUSES_RECEIVED,
	payload: statuses,
});

export const requestVideoStatusesReceived = (statuses: any) => ({
	type: REQUEST_VIDEO_STATUSES_RECEIVED,
	payload: statuses,
});

export const requestPollsStatusesReceived = (statuses: any) => ({
	type: REQUEST_POLLS_STATUSES_RECEIVED,
	payload: statuses,
});

export const requestGalleryStatusesReceived = (statuses: any) => ({
	type: REQUEST_GALLERY_STATUSES_RECEIVED,
	payload: statuses,
});

export const requestWikiStatusesReceived = (statuses: any) => ({
	type: REQUEST_WIKI_STATUSES_RECEIVED,
	payload: statuses,
});

export const requestListStatusesReceived = (statuses: any) => ({
	type: REQUEST_LIST_STATUSES_RECEIVED,
	payload: statuses,
});

export const requestBannerStatusesReceived = (statuses: any) => ({
	type: REQUEST_BANNER_STATUSES_RECEIVED,
	payload: statuses,
});
