import * as React from 'react';
import { Label, Col, Input } from 'reactstrap';
import EntityModel from '../models/entity.model';
import EnumItem from '../../../../models/enum/EnumItem';
import SearchEntitiesComponent from './search-entities.component';
import SingleTranslationComponent from './single-translation.component';
import { updateTranslationShortName } from '../helpers/translations.helpers';

interface Properties {
	t: any;
	searchLanguage: EnumItem;
	entity: EntityModel;
	selectedEntity: EnumItem;
	onEntitySelect: (entity: EntityModel) => any;
	translationChange: (translations: any[]) => any;
	translationLanguage: EnumItem;
	translations: any[];
}

const MatchStatus: React.FunctionComponent<Properties> = ({
	t,
	onEntitySelect,
	entity,
	selectedEntity,
	searchLanguage,
	translations,
	translationLanguage,
	translationChange,
}) => {
	const onShortNameTranslationChange = (e: any, index: number) => {
		const updatedTranslations = updateTranslationShortName(translations, index, e);
		translationChange(updatedTranslations);
	};

	const translatedTranslate = t('translate');
	return (
		<>
			<SearchEntitiesComponent
				t={t}
				entity={entity}
				entityType={selectedEntity}
				onEntitySelect={onEntitySelect}
				searchLanguage={searchLanguage}
			/>
			{translations.length > 0 ? (
				<SingleTranslationComponent
					translations={translations}
					t={t}
					entity={entity}
					selectedEntity={selectedEntity}
					language={translationLanguage}
					translationChange={translationChange}
				/>
			) : (
				<Col xs='6'>
					<Label>{translatedTranslate}</Label>
					<Input id='disabled-status' placeholder={translatedTranslate} disabled />
				</Col>
			)}

			<Col xs='6'>
				<Label>{t('status_short_name')}</Label>
				<Input disabled id='status_short-name-input' value={entity.shortName !== null ? entity.shortName : ''} />
			</Col>
			<Col xs='6'>
				<Label>{translatedTranslate}</Label>
				{translations.length > 0 ? (
					<Input
						id='translate_status_short-name-input'
						placeholder={translatedTranslate}
						value={translations[0].content.short_name !== null ? translations[0].content.short_name : ''}
						onChange={(e: any) => onShortNameTranslationChange(e, 0)}
					/>
				) : (
					<Input id='disabled-short-name' placeholder={translatedTranslate} value='' disabled />
				)}
			</Col>
		</>
	);
};

export default MatchStatus;
