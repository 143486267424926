export const Entities = {
	team: 'TEAM',
	player: 'PLAYER',
	coach: 'COACH',
};

export const AssetsTypes = {
	IMAGE: 'image',
	THUMBNAIL: 'thumbnail',
	LOGO: 'logo',
	HOME_KIT: 'homeKit',
	AWAY_KIT: 'awayKit',
	SQUAD_IMAGE: 'squadImage',
};
