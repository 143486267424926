import { ImageUrlsJson } from './ImageUrlsJson';
import ImageUrlsBuilder from './ImageUrlsBuilder';
import ImageRatio from './ratio/ImageRatio';
import ImageUrlsUploaded from './ImageUrlsUploaded/ImageUrlsUploaded';

export default class ImageUrls {
	readonly ratio23x9: ImageRatio;
	readonly ratio16x9: ImageRatio;
	readonly ratio9x16: ImageRatio;
	readonly ratio4x3: ImageRatio;
	readonly ratio3x4: ImageRatio;
	readonly ratio2x3: ImageRatio;
	readonly ratio3x2: ImageRatio;
	readonly ratio1x1: ImageRatio;
	readonly cropped: string;
	readonly uploaded: ImageUrlsUploaded;

	private constructor(
		ratio23x9: ImageRatio,
		ratio16x9: ImageRatio,
		ratio9x16: ImageRatio,
		ratio4x3: ImageRatio,
		ratio3x4: ImageRatio,
		ratio2x3: ImageRatio,
		ratio3x2: ImageRatio,
		ratio1x1: ImageRatio,
		cropped: string,
		uploaded: ImageUrlsUploaded,
	) {
		this.ratio23x9 = ratio23x9;
		this.ratio16x9 = ratio16x9;
		this.ratio9x16 = ratio9x16;
		this.ratio4x3 = ratio4x3;
		this.ratio3x4 = ratio3x4;
		this.ratio2x3 = ratio2x3;
		this.ratio3x2 = ratio3x2;
		this.ratio1x1 = ratio1x1;
		this.cropped = cropped;
		this.uploaded = uploaded;
	}

	toJSON(): ImageUrlsJson {
		return {
			ratio23x9: this.ratio23x9,
			ratio16x9: this.ratio16x9,
			ratio9x16: this.ratio9x16,
			ratio4x3: this.ratio4x3,
			ratio3x4: this.ratio3x4,
			ratio2x3: this.ratio2x3,
			ratio3x2: this.ratio3x2,
			ratio1x1: this.ratio1x1,
			cropped: this.cropped,
			uploaded: this.uploaded,
		};
	}

	static fromJSON(json: ImageUrlsJson): ImageUrls {
		return new ImageUrls(
			json.ratio23x9,
			json.ratio16x9,
			json.ratio9x16,
			json.ratio4x3,
			json.ratio3x4,
			json.ratio2x3,
			json.ratio3x2,
			json.ratio1x1,
			json.cropped,
			json.uploaded,
		);
	}

	static builder(urls?: ImageUrls): ImageUrlsBuilder {
		return new ImageUrlsBuilder(urls);
	}
}
