import BlockConfigModel from '../../models/block-config.model/block-config.model';
import BlockModel from '../../models/block.model';
import { FansUnitedWidgetsIds } from '../../constants/block.types';

//This function generates a config div based on the parameters it is given, so that it can be passed to the FU widgets and avoid repetitive code.
const constructFansUnitedWidgetContent = (config: BlockConfigModel) => {
	if (config) {
		const generateConfigDiv = (widgetId: string, matchId: string, market: string, playerId?: number, teamAgainstId?: string) => {
			let defaultConfig = `data-widget-id="${widgetId}" data-match-id="${matchId}" data-market="${market}"`;

			if (playerId) defaultConfig = defaultConfig.concat(' ', `data-player-id="${playerId}"`);
			if (teamAgainstId) defaultConfig = defaultConfig.concat(' ', `data-team-id="${teamAgainstId}"`);

			return `<div ${defaultConfig}></div>`;
		};

		return generateConfigDiv(
			config.widgetId,
			config.options.matchId,
			config.options.market,
			config.options.playerId,
			config.options.teamAgainstId,
		);
	}
};

//Used for 1x2, Double chance, Correct score and Half-time/Full-time widgets
const widgetGenericConfig = (block: BlockModel): BlockModel => {
	const options = {
		matchId: block.data.widget && block.data.widget.match ? block.data.widget.match.id : null,
		market: block.data.widget && block.data.widget.market && block.data.widget.market.value ? block.data.widget.market.value : null,
		fuMarketName: block.data.widget && block.data.widget.market && block.data.widget.market.name ? block.data.widget.market.name : null,
	};

	const blockConfig = BlockConfigModel.builder().withOptions(options).withId(FansUnitedWidgetsIds[block.data.widget_type]).build();
	const blockContent = constructFansUnitedWidgetContent(blockConfig);
	block.data.config = blockConfig;
	block.data.content = blockContent;

	return block;
};

// Used for Team and Player predictions widgets
const widgetYesNoConfig = (block: BlockModel): BlockModel => {
	const options = {
		matchId: block.data.widget && block.data.widget.match ? block.data.widget.match.id : null,
		playerId: block.data.widget && block.data.widget.player && block.data.widget.player.id ? block.data.widget.player.id : null,
		teamAgainstId:
			block.data.widget && block.data.widget.teamAgainst && block.data.widget.teamAgainst.id ? block.data.widget.teamAgainst.id : null,
		market: block.data.widget && block.data.widget.market && block.data.widget.market.value ? block.data.widget.market.value : null,
		fuMarketName: block.data.widget && block.data.widget.market && block.data.widget.market.name ? block.data.widget.market.name : null,
	};

	const blockConfig = BlockConfigModel.builder().withOptions(options).withId(FansUnitedWidgetsIds[block.data.widget_type]).build();
	const blockContent = constructFansUnitedWidgetContent(blockConfig);
	block.data.config = blockConfig;
	block.data.content = blockContent;

	return block;
};

export const isFansUnitedBlock = (name: string) => {
	return (
		name === 'widget1x2' ||
		name === 'widgetDoubleChance' ||
		name === 'widgetCorrectScore' ||
		name === 'widgetHtFt' ||
		name === 'widgetTeamPredictions' ||
		name === 'widgetPlayerPredictions'
	);
};

export const mapFansUnitedWidgetConfig = {
	widget1x2: widgetGenericConfig,
	widgetDoubleChance: widgetGenericConfig,
	widgetCorrectScore: widgetGenericConfig,
	widgetHtFt: widgetGenericConfig,
	widgetPlayerPredictions: widgetYesNoConfig,
	widgetTeamPredictions: widgetYesNoConfig,
};
