import React, { FunctionComponent, useState } from 'react';
import { Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import SuggestionInsideTab from './suggestions/suggestions';
import { ContentTypes } from '../../../../../../constants/content-types';
import SearchTypes from './search-types';
import { availableContentTypes } from '../../../../../../services/content-models-service/ContentModelService';
import SearchInsideTab from './search/search';
import { showPagination } from '../../helpers/general.helper';
import ReactPaginate from 'react-paginate';
import { PaginationProps } from '../../../Media/subcomponents/media-main-image/subcomponents/image-listing/properties/ImageListPaginateProps';
import PaginationMeta from '../../../../../../models/v2/Pagination/PaginationModel';

type Properties = {
	t: any;
	pagination: PaginationMeta;
	onPageChange: (pageNumber: number) => void;
};

const RelatedContentPagination: FunctionComponent<Properties> = ({ t, pagination, onPageChange }) => {
	if (showPagination(pagination)) {
		return (
			<div className='mt-2'>
				<ReactPaginate
					{...PaginationProps(t, pagination.currentPage)}
					pageCount={pagination.totalPages}
					forcePage={pagination.currentPage ? pagination.currentPage - 1 : 0}
					onPageChange={({ selected }) => onPageChange(selected + 1)}
				/>
			</div>
		);
	}

	return null;
};

export default RelatedContentPagination;
