import * as React from 'react';
import { Button, ButtonGroup, Col, Row } from 'reactstrap';
import { DebounceInput } from 'react-debounce-input';
import { SortableHandle } from 'react-sortable-hoc';
import GalleryItem from '../../../../../models/gallery/item/GalleryItem';
import './GallerySortableItem.scss';
import ConditionalRenderWrapper from '../../../../Partials/BaseComponents/ConditionalRenderWraper/ConditionalRenderWrapper';
import ImageEditAndCropButtons from '../../../Images/image-buttons.component';
import LazyLoad, { forceCheck } from 'react-lazyload';
import { toast } from 'react-toastify';

type SortableItemProps = {
	onItemUpdate: (item: GalleryItem) => any;
	addImageForAction: (e: any, item: GalleryItem) => void;
	item: GalleryItem;
	onEdit: (imageId: string) => any;
	onCrop: (imageId: string) => any;
	onImageClick: (image: any) => any;
	t: any;
	selected: boolean;
	toggleItemsForUpdate: (itemId: string) => any;
	toggleModal: (item: GalleryItem, index: number) => any;
	index: number;
	displayDescriptionCheckbox?: boolean;
};

const DragHandle = SortableHandle(() => (
	<span className={'cursor-draggable'}>
		<i className={'fa fa-bars'}></i>
	</span>
));

// TODO Refactor
const GallerySortableItem: React.FunctionComponent<SortableItemProps> = (props) => {
	const { toggleItemsForUpdate, toggleModal, onImageClick, onItemUpdate, addImageForAction, displayDescriptionCheckbox } = props;
	const { t, item, onCrop, onEdit, selected, index } = props;

	const onCommentChange = (text: string, item: any) => {
		let addDescriptionToImage = !!(text && text !== '');

		if (text && text.length <= 255) {
			onItemUpdate(GalleryItem.builder(item).withComment(text).withDescription(text).withAddDescriptionToMedia(addDescriptionToImage).build());
		} else {
			onItemUpdate(GalleryItem.builder(item).withComment(text).withDescription(text).withAddDescriptionToMedia(false).build());
			toast.error(props.t('description_limit_exceed'));
		}
	};

	const onAddDescriptionToImageChecked = (e: any, item: any) => {
		if ((item.comment && item.comment.length <= 255) || item.comment === null) {
			onItemUpdate(GalleryItem.builder(item).withDescription(item.comment).withAddDescriptionToMedia(e.target.checked).build());
		} else {
			onItemUpdate(GalleryItem.builder(item).withComment(item.comment).withDescription(item.comment).withAddDescriptionToMedia(false).build());
			toast.error(props.t('description_limit_exceed'));
		}
	};

	return (
		<div>
			<ConditionalRenderWrapper expectedPermissions={['read_images', 'write_images', 'delete_images']}>
				<li
					onClick={(e: any) => addImageForAction(e, item)}
					key={`gallery-item-${item.type}-${item.data.id}`}
					className={`mb-3 row gallery-item bg-gray-100 border rounded ${selected ? 'border-info' : 'border-light'}`}
				>
					<Col xs='3' sm='3' md='3' className='d-flex align-items-center'>
						<Col className='px-1 h-100 d-flex flex-column align-items-center position-relative'>
							<input className='position-absolute mt-3' onChange={() => toggleItemsForUpdate(item.data.id)} type='checkbox' checked={selected} />
							<div className='flex-fill d-flex align-items-center'>
								<DragHandle />
							</div>
						</Col>
						<Col xs='10' sm='10' md='10' className='py-3 text-center'>
							<LazyLoad placeholder={<i className={'fa fa-spinner fa-pulse'}></i>} height={300} offset={50}>
								{forceCheck()}
								<div className='image-container'>
									<div className='expand-item' onClick={() => onImageClick(item.data)}>
										<img className='img-fluid w-100 rounded' src={item.data.urls.uploaded.thumbnail} alt={item.data.description} />
									</div>
									<div className='gallery-image-footer'>
										<ButtonGroup className='actions-wrapper display-block w-100'>
											<ImageEditAndCropButtons imageId={item.data.id} onEdit={onEdit} onCrop={onCrop} />
										</ButtonGroup>
									</div>
								</div>
							</LazyLoad>
						</Col>
					</Col>
					<Col sm='9' md='9' className='mt-4 pl-2 pr-5'>
						<div className={'h-100'}>
							<DebounceInput
								className='form-control-lg form-control w-100'
								placeholder={t('gallery_image_comment_placeholder')}
								rows={3}
								element={'textarea'}
								debounceTimeout={500}
								value={item.comment ? item.comment : ''}
								onChange={(event: any) => onCommentChange(event.target.value, item)}
							/>
							{displayDescriptionCheckbox && (
								<Row className='mt-2'>
									<Col>
										<input
											className={'mr-1'}
											id={`gallery-item-description-${item.data.id}`}
											checked={item.addDescriptionToMedia}
											onChange={(event: any) => onAddDescriptionToImageChecked(event, item)}
											type={'checkbox'}
											key={`gallery-item-description${item.data.id}`}
										/>
										<label htmlFor={`gallery-item-description-${item.data.id}`}>{t('add_description_to_image')}</label>
									</Col>
								</Row>
							)}
						</div>
					</Col>

					<Button onClick={() => toggleModal(item, index)} className='delete-button btn btn-rounded btn-sm btn-danger mx-1 align-self-end'>
						<i className={'fa fa-trash'} title={'Remove'}></i>
					</Button>
				</li>
			</ConditionalRenderWrapper>
		</div>
	);
};

export default GallerySortableItem;
