import { Col, FormGroup, Row } from 'reactstrap';
import React from 'react';
import { Properties, State } from './properties/dugout-video-container.properties';
import DugoutVideoSelect from '../../../partials/dugout-video-select/dugout-video-select.component';
import DugoutVideosModel from '../../../../../../models/dugout-videos/dugout-videos.model';
import VideoCells from './video-cells/video-cells';
import Scrollbar from 'react-custom-scrollbars';
import ErrorHandler from '../../../partials/error/error-handler-component';

class DugoutVideoContainer extends React.Component<Properties, State> {
	constructor(props: any) {
		super(props);
		this.state = {
			dugoutVideos: null,
			toggleLoadingState: false,
		};
	}

	onVideosChange = (dugoutVideos: DugoutVideosModel, lang: string) => {
		this.setState({ ...this.state, dugoutVideos });
		this.props.updateSearchText(dugoutVideos.title, lang);
	};

	toggleLoadingState = (loading: boolean) => {
		this.setState({ ...this.state, toggleLoadingState: loading });
	};

	render() {
		const { t, onSelect, selectedDugoutVideo, isMediaSidebar, linkedTags } = this.props;
		const { dugoutVideos, toggleLoadingState } = this.state;

		return (
			<div className={'mb-2'}>
				<div>
					<Row>
						<Col>
							<FormGroup>
								<DugoutVideoSelect
									t={t}
									onSelect={this.onVideosChange}
									onSelectedDugoutVideoChange={onSelect}
									toggleLoadingState={this.toggleLoadingState}
									searchText={this.props.block && this.props.block.data && this.props.block.data.preview && this.props.block.data.preview.title}
									selectedLang={this.props.block && this.props.block.data && this.props.block.data.preview && this.props.block.data.preview.language}
									linkedTags={linkedTags}
								/>
							</FormGroup>
						</Col>
					</Row>
					<Row></Row>
					<div className={`${toggleLoadingState ? 'loading-overlay' : ''}`}>
						{dugoutVideos && dugoutVideos.videoList && dugoutVideos.videoList.length > 0 ? (
							<div style={{ height: '400px' }} className='pb-1'>
								<Scrollbar ref='scrollbars'>
									<VideoCells
										t={t}
										onSelect={onSelect}
										isMediaSidebar={isMediaSidebar}
										videos={dugoutVideos.videoList}
										toggleLoadingState={toggleLoadingState}
										selectedVideo={selectedDugoutVideo}
									/>
								</Scrollbar>
							</div>
						) : (
							<div className='text-center'>{t('no_data')}</div>
						)}
					</div>
					{this.props.inBlocky && (
						<div className='p-1'>
							{!this.props.isValid && selectedDugoutVideo && selectedDugoutVideo.id === undefined && (
								<ErrorHandler errorMessage='no_selected_video' />
							)}
						</div>
					)}
				</div>
			</div>
		);
	}
}

export default DugoutVideoContainer;
