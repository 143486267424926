import { store } from '../../store/store';
import axios from 'axios';
import Watermark from '../../models/watermarks/Watermark';
import { getFirstWatermark, setGenericWatermarkToFalse, setGenericWatermarkToTrue } from './watermark-helper';
import { returnFileName } from '../../views/Resources/Galleries/GalleryItems/helpers/gallery-items.helpers';
import REACT_APP_URLS from '../../global-helpers/global-url.helpers';

export default class WatermarkService {
	instanceImageApi = () => {
		const projectHeader = store.getState().project.currentProject.domain;

		const IMAGE_API_URL = store.getState().project.currentProject.imageApiUrl
			? store.getState().project.currentProject.imageApiUrl
			: REACT_APP_URLS.REACT_APP_IMAGE_API_BASE_URL;
		//Required headers: project domain, content-type, auth
		return axios.create({
			baseURL: IMAGE_API_URL,
			headers: {
				'content-type': 'multipart/form-data',
				Project: projectHeader,
				'X-Project': projectHeader,
				Authorization: `Basic ${btoa('imageapi:Prd6kfOIma8wMRYeT68Q')}`,
			},
		});
	};

	//Extracts the default watermark from the /watermarks endpoint.
	// Currently it will use only the first watermark from the response.
	getDefaultWatermark = () => {
		return this.instanceImageApi()
			.get('/watermarks', { headers: { 'X-Update': 1 } })
			.then((response) => {
				if (getFirstWatermark(response)) {
					return Watermark.builder(response.data[0]).build();
				} else {
					return Watermark.builder().build();
				}
			});
	};

	deleteWatermarkSingleImage = (imagePath: string, imageID: string) => {
		return this.instanceImageApi()
			.delete(`/images/${returnFileName(imagePath)}/watermark`)
			.then((response) => {
				return setGenericWatermarkToFalse(imageID, response.data.path);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	addWatermarkSingleImage = (imagePath: string, imageID: string, watermarkImageId: string) => {
		const form = new FormData();
		form.append('image_path', imagePath);
		form.append('watermark_id', watermarkImageId);
		form.append('delete_existing', 'no');

		return this.instanceImageApi()
			.post('/watermarks/image', form)
			.then((response) => {
				return setGenericWatermarkToTrue(imageID, response.data.path);
			})
			.catch((error) => {
				console.log(error);
			});
	};
}
