import * as React from 'react';
import Select from 'react-select';
import { customOption } from '../../../custom-select-options/custom-select-option';
import { optionToUser, remapUsersFromResponse, usersToOptions, userToOption } from './helpers/user-select.helper';
import UserModel from './models/user.model';
import Project from '../../../../../../models/project/Project';
import HttpService from '../../../../../../services/rest/HttpService';

type Properties = {
	selectedUser: UserModel;
	onUserSelect: any;
	t: any;
	isClearable?: boolean;
	profileId: string;
	currentProject: Project;
};

const UserSelectContainer: React.FunctionComponent<Properties> = (props) => {
	const { selectedUser, t, isClearable, profileId } = props;
	const [users, updateUsers] = React.useState([]);

	React.useEffect(() => {
		fetchAllUsers();
	}, []);

	const fetchAllUsers = () => {
		const headers = { Project: props.currentProject.domain };

		HttpService.getAllUsers(`projects/${props.currentProject.id}/admins?page=1&limit=200`, null, headers)
			.then((response: any) => {
				const users = remapUsersFromResponse(response.data.data);
				updateUsers(users);
			})
			.catch(() => undefined);
	};

	const onUserSelect = (selections: any) => {
		props.onUserSelect(optionToUser(selections));
	};

	return (
		<Select
			id='advanced-filters-user-select'
			value={selectedUser && selectedUser.id ? userToOption(selectedUser, profileId, t) : null}
			options={usersToOptions(users, profileId, t)}
			onChange={onUserSelect}
			formatOptionLabel={customOption}
			placeholder={t('select_user')}
			isClearable={isClearable}
		/>
	);
};

export default UserSelectContainer;
