import React from 'react';
import { Name } from '../fields/name';
import { SOCIALS_PLAYER, SOCIAL_TYPES_METHODS, isSaveDisabled, playerSave } from '../helpers/manual-data.helper';
import { connect } from 'react-redux';
import { Col, Row, Button } from 'reactstrap';
import CountrySelect from '../fields/country';
import moment from 'moment';
import Social from '../fields/social';
import { Properties, State } from './properties/player-create.properties';
import {
	PLAYER_ENTITY_CREATE_SUCCESS,
	returnObjectForPlayerEntityCreate,
} from '../../../../../store/action-creators/player-action-creator';
import Project from '../../../../../models/project/Project';
import SidebarCollapsibleElements from '../../../../Partials/Sidebar/sidebar-collapsible-elements/sidebar-collapsible-elements';
import { ContentTypes } from '../../../../Partials/Blocky/constants/block.types';
import SidebarElementsToggle from '../../../../Partials/Sidebar/sidebar-collapsible-elements/sidebar-elements-toggle/sidebar-elements-toggle.component';
import PlayerPositionSelect from '../fields/player-position';
import ActiveToggle from '../fields/active';
import BirthCitySelect from '../fields/birth-city';
import AddCityButton from '../fields/add-city-button.component';
import BirthDateSelect from '../fields/birth-date';
import WeightSelect from '../fields/weight-select';
import HeightSelect from '../fields/height-select';
import PlayerProfileModel from '../../../../../models/player-manual-data/player-profile/player-profile';
import PlayerManualDataModel from '../../../../../models/player-manual-data/player-manual-data.model';
import EnumItem from '../../../../../models/enum/EnumItem';
import AssetsModel from '../../../../../models/team-manual-data/assets/assets';
import { AssetsMediaComponent } from '../assets-media/assets-media.component';
import {
	ContentSidebarChangeCollapseStatus,
	onChangeCollapseStatus,
	onSidebarElemSort,
	toggleSidebarEditMode,
} from '../../../../../services/content-sidebar/content-sidebar.helper';
import { FormattedSidebarSectionValue } from '../../../../Partials/Sidebar/sidebar-collapsible-elements/utils/temp-sidebar.types';
import GlobalModal from '../../../../Partials/Modals/Global/global-modal';
import {
	appendBeforeUnloadResetTempSidebar,
	defaultTempSidebarFromApiResponse,
	overwriteTempSidebarByContentType,
	saveSidebarSettingsByContentType,
} from '../../../../Partials/Sidebar/sidebar-collapsible-elements/utils/temp-sidebar.helper';

class PlayerCreateComponent extends React.Component<Properties, State> {
	constructor(props: any) {
		super(props);
		this.state = {
			isActive: false,
			isSidebarInEdit: false,
			playerDataEntry: PlayerManualDataModel.builder().withName('').withActive(false).build(),
			assets: AssetsModel.builder().build(),
			openSidebarSettingsModalFlag: false,
		};
	}

	componentDidMount() {
		window.addEventListener(PLAYER_ENTITY_CREATE_SUCCESS, (data: any) => {
			this.props.history.push(`/smp/players/edit/${data.detail}`);
		});
		appendBeforeUnloadResetTempSidebar(ContentTypes.player);
	}

	onNameChange = (e: any) => {
		this.setState({
			...this.state,
			playerDataEntry: PlayerManualDataModel.builder(this.state.playerDataEntry).withName(e.target.value).build(),
		});
	};

	onCountryChange = (country: any) => {
		this.setState({
			...this.state,
			playerDataEntry: PlayerManualDataModel.builder(this.state.playerDataEntry).withCountry(country).build(),
		});
	};

	onBirthCityChange = (birthCity: EnumItem) => {
		this.setState({
			...this.state,
			playerDataEntry: PlayerManualDataModel.builder(this.state.playerDataEntry).withBirthCity(birthCity).build(),
		});
	};

	onBirthDateChange = (date: any) => {
		this.setState({
			...this.state,
			playerDataEntry: PlayerManualDataModel.builder(this.state.playerDataEntry)
				.withBirthdate(date ? moment(date).format('YYYY-MM-DD') : '')
				.build(),
		});
	};

	onPositionChange = (position: any) => {
		this.setState({
			...this.state,
			playerDataEntry: PlayerManualDataModel.builder(this.state.playerDataEntry).withPosition(position).build(),
		});
	};

	onWeightChange = (e: any) => {
		this.setState({
			...this.state,
			playerDataEntry: PlayerManualDataModel.builder(this.state.playerDataEntry)
				.withProfile(PlayerProfileModel.builder(this.state.playerDataEntry.profile).withWeight(e.target.value).build())
				.build(),
		});
	};

	onHeightChange = (e: any) => {
		this.setState({
			...this.state,
			playerDataEntry: PlayerManualDataModel.builder(this.state.playerDataEntry)
				.withProfile(PlayerProfileModel.builder(this.state.playerDataEntry.profile).withHeight(e.target.value).build())
				.build(),
		});
	};

	onSocialChange = (e: any, key: string) => {
		const { playerDataEntry } = this.state;
		const social = SOCIAL_TYPES_METHODS[key](playerDataEntry.social, e.target.value);
		this.setState({ ...this.state, playerDataEntry: PlayerManualDataModel.builder(playerDataEntry).withSocial(social).build() });
	};

	toggleSidebarEditMode = () => {
		const newState = toggleSidebarEditMode({ ...this.state }, ContentTypes.player);
		this.setState(newState);
	};

	onActiveChange = () => {
		this.setState({
			...this.state,
			isActive: !this.state.isActive,
			playerDataEntry: PlayerManualDataModel.builder(this.state.playerDataEntry).withActive(!this.state.isActive).build(),
		});
	};

	updateAssets = (assets: AssetsModel) => {
		this.setState({ ...this.state, assets: assets });
	};

	onPlayerSave = () => this.props.playerEntityCreate(playerSave(this.state.playerDataEntry), this.props.project, this.state.assets);

	render() {
		const { t, project } = this.props;
		const { playerDataEntry, isActive, isSidebarInEdit, assets, openSidebarSettingsModalFlag } = this.state;
		const sidebarComponentsMap = {
			media: (
				<AssetsMediaComponent
					t={t}
					currentProject={project}
					assets={assets}
					dataEntry={playerDataEntry}
					updateAssets={this.updateAssets}
					dataType='player'
				/>
			),
		};

		return (
			<div className='animated fadeIn'>
				<Row>
					<Col col='8' lg='8' md='12' sm='12' xs='12'>
						<div className='card'>
							<div className='card-header d-flex align-items-center '>
								<div className='mr-auto mb-0'>{t('player_info')}</div>
								<Button
									size='sm'
									color='primary'
									disabled={isSaveDisabled(playerDataEntry, ContentTypes.player)}
									className='text-uppercase font-weight-bold'
									onClick={this.onPlayerSave}
									id='player-manual-data-entry-create-save-top'
								>
									<i className='fa fa-floppy-o' /> {t('save_player')}
								</Button>
							</div>
							<div className='card-body'>
								<Row>
									<div className='alert alert-info w-100' role='alert'>
										{t('enter_the_player_name_in_english')}
									</div>
								</Row>
								<Row>
									<Name value={playerDataEntry.name} onChange={this.onNameChange} t={t} type='player' />
									<BirthDateSelect value={playerDataEntry.birthdate} t={t} onChange={this.onBirthDateChange} />
								</Row>
								<Row>
									<CountrySelect value={playerDataEntry.country} onChange={this.onCountryChange} t={t} />
									<HeightSelect value={playerDataEntry.profile ? playerDataEntry.profile.height : ''} onChange={this.onHeightChange} t={t} />
								</Row>
								<Row>
									<BirthCitySelect value={playerDataEntry.birthCity} lang={project.language} onChange={this.onBirthCityChange} t={t} />
									<WeightSelect value={playerDataEntry.profile && playerDataEntry.profile.weight} onChange={this.onWeightChange} t={t} />
								</Row>
								<Row>
									<AddCityButton t={t} preselectCity={this.onBirthCityChange} />
									<ActiveToggle isActive={isActive} t={t} onChange={this.onActiveChange} />
								</Row>
							</div>
						</div>
						<div className='card'>
							<div className='card-header'>{t('professional_experience')}</div>
							<div className='card-body'>
								<Row className='mb-2'>
									<PlayerPositionSelect value={playerDataEntry.position} t={t} onChange={this.onPositionChange} />
								</Row>
							</div>
						</div>
						<div className='card'>
							<div className='card-header'>{t('social_media_highlights')}</div>
							<div className='card-body'>
								<Row className='mb-2'>
									{Object.keys(SOCIALS_PLAYER).map((key: string) => {
										const value = playerDataEntry.social && playerDataEntry.social[key] ? playerDataEntry.social[key] : '';

										return <Social key={key} t={t} value={value} onChange={(e: any) => this.onSocialChange(e, key)} type={SOCIALS_PLAYER[key]} />;
									})}
								</Row>
								<Row>
									<Button
										disabled={isSaveDisabled(playerDataEntry, ContentTypes.player)}
										color='primary'
										className='text-uppercase font-weight-bold'
										onClick={this.onPlayerSave}
										id='player-manual-data-entry-create-save-bottom'
									>
										<i className='fa fa-floppy-o' /> {t('save_player')}
									</Button>
								</Row>
							</div>
						</div>
					</Col>
					<Col col='4' lg='4' md='12' sm='12' xs='12' className='position-relative'>
						<SidebarElementsToggle t={t} toggleSidebarEditMode={this.toggleSidebarEditMode} isSidebarInEdit={isSidebarInEdit} />
						<SidebarCollapsibleElements
							isSidebarInEdit={isSidebarInEdit}
							onElemSort={(settingsValue: FormattedSidebarSectionValue[]) => onSidebarElemSort(settingsValue, ContentTypes.player)}
							onChangeCollapseStatus={(data: ContentSidebarChangeCollapseStatus) => onChangeCollapseStatus(data)}
							contentType={ContentTypes.player}
							sidebarComponentsMap={sidebarComponentsMap}
							t={t}
						/>
					</Col>
				</Row>
				<GlobalModal
					isOpen={openSidebarSettingsModalFlag}
					t={this.props.t}
					headerContent='save_sidebar_settings'
					bodyContent='unsaved_changes'
					submitFunction={() => saveSidebarSettingsByContentType(ContentTypes.player, t)}
					beforeCloseFunction={() =>
						overwriteTempSidebarByContentType(ContentTypes.player, defaultTempSidebarFromApiResponse[ContentTypes.player].value)
					}
				/>
			</div>
		);
	}
}

function mapStateToProps(state: any) {
	return {
		project: state.project.currentProject,
		profile: state.profile.profile,
	};
}

function mapDispatchToProps(dispatch: any) {
	return {
		playerEntityCreate: (playerData: PlayerManualDataModel, project: Project, assets: AssetsModel) =>
			dispatch(returnObjectForPlayerEntityCreate(playerData, project, assets)),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(PlayerCreateComponent);
