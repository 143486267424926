import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Col, Row } from 'reactstrap';
import '../../../../style/live-blog.style.scss';
import { DATA_QA_ATTRIBUTES } from '../../../../../../Partials/Sidebar/general-content/constants/data-qa.attributes';
import { liveBlogMainMediaTypesOptions, mainMediaTypesToOption, mainMediaTypesToOptions } from './helpers/main-media-type-select.helper';
import LiveBlogModel from '../../../../models/live-blog.model';
type Properties = {
	t: any;
	setLiveBlogMainMediaType: (type: string) => void;
	liveBlog: LiveBlogModel;
};

export const LiveBlogMainMediaTypeSelect: React.FunctionComponent<Properties> = ({ t, liveBlog, setLiveBlogMainMediaType }) => {
	const [mediaType, setMediaType] = useState<string>(
		liveBlog.main_media_type && liveBlog.main_media_type.length > 0 ? liveBlog.main_media_type : liveBlogMainMediaTypesOptions[0],
	);

	useEffect(() => {
		setMediaType(mediaType);
		setLiveBlogMainMediaType(mediaType);
	}, []);

	const onMainMediaTypeSelect = (type: string) => {
		setMediaType(type);
		setLiveBlogMainMediaType(type);
	};

	return (
		<Row className='mb-3 main-media-type' data-qa={`${DATA_QA_ATTRIBUTES.LIVE_BLOG_MAIN_MEDIA_TYPE}`}>
			<Col>
				<Select
					id={DATA_QA_ATTRIBUTES.LIVE_BLOG_MAIN_MEDIA_TYPE}
					options={mainMediaTypesToOptions(liveBlogMainMediaTypesOptions, t)}
					value={mainMediaTypesToOption(mediaType, t)}
					placeholder={t('select')}
					noOptionsMessage={(inputValue) => inputValue && t('no_options')}
					onChange={(selection: any) => onMainMediaTypeSelect(selection && selection.value)}
				/>
			</Col>
		</Row>
	);
};
