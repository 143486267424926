import { Properties, State } from './properties/content-tags.properties';
import * as React from 'react';
import HttpService from '../../../../../../services/rest/HttpService';
import AsyncSelect from 'react-select/async';
import _ from 'lodash';
import { optionToRelated, relatedToOptions, responseToOption } from './helpers/content-tags-container.helper';

export default class ContentTagsContainer extends React.Component<Properties, State> {
	constructor(props: Properties) {
		super(props);
		this.state = {
			newValue: '',
			selectedOptions: props.related ? relatedToOptions(props.related) : [],
		};
	}

	private onInputChange = (input: string) => {
		this.setState({ newValue: input });

		return input;
	};

	private onSelect = (selections: any) => {
		// This is done because of https://github.com/JedWatson/react-select/issues/3632
		selections = selections === null ? [] : selections;
		const related = selections
			.filter((selection: any) => Object.entries(selection).length > 0)
			.map((selection: any) => optionToRelated(selection));
		this.props.onSelect(related);
	};

	private loadTagsData = (input: string, callback: any) => {
		let totalTags = 0;
		let currentPage = 1;
		let requestUrls: any[] = [];
		let tagsCopy: any[] = [];
		if (input.length > 3) {
			let headers = { Project: this.props.currentProject.domain };
			HttpService.get(`/tags/search?query=${input.trim()}&page=${currentPage}&limit=200`, null, headers).then((response: any) => {
				currentPage += 1;
				totalTags = response.data.meta.pagination.total_pages;
				tagsCopy.push(...response.data.data.map((tag: any) => responseToOption(tag)));

				for (let i = currentPage; i <= totalTags; i++) {
					requestUrls.push(HttpService.get(`/tags/search?query=${input.trim()}&page=${i}&limit=200`, null, headers));
				}
				Promise.all(requestUrls).then((response) => {
					response.forEach((itemsPerPage) => {
						tagsCopy.push(...itemsPerPage.data.data.map((tag: any) => responseToOption(tag)));
					});
					callback([...tagsCopy]);
				});
			});
		}
	};

	componentDidUpdate(prevProps: Readonly<Properties>, prevState: Readonly<State>): void {
		if (prevProps.related !== this.props.related) {
			this.setState({
				...this.state,
				selectedOptions: relatedToOptions(this.props.related),
			});
		}
	}

	render() {
		return (
			<AsyncSelect
				inputId='content-tags-select'
				noOptionsMessage={(inputValue) => inputValue && this.props.t('no_options')}
				isMulti={true}
				loadOptions={_.debounce(this.loadTagsData, 500)}
				value={this.state.selectedOptions}
				onChange={this.onSelect}
				onInputChange={_.debounce(this.onInputChange, 500)}
				placeholder={this.props.t('select')}
			/>
		);
	}
}
