import { ProfileJson } from './ProfileJson';
import Profile from './Profile';
import Permission from '../permission/Permission';
import { ProfileRequestJson } from './ProfileRequestJson';

export default class ProfileBuilder {
	private json: ProfileJson;

	constructor(profile?: Profile | ProfileJson) {
		if (profile && profile instanceof Profile) {
			this.json = profile.toJSON();
		} else if (profile) {
			this.json = profile;
		} else {
			this.json = {} as ProfileJson;
		}
	}

	withIsLoggedIn(isLoggedIn: boolean): ProfileBuilder {
		this.json.isLoggedIn = isLoggedIn;

		return this;
	}

	withIsAuthenticated(isAuthenticated: boolean): ProfileBuilder {
		this.json.isAuthenticated = isAuthenticated;

		return this;
	}

	withAreProjectResourcesReady(areProjectResourcesReady: boolean): ProfileBuilder {
		this.json.areProjectResourcesReady = areProjectResourcesReady;

		return this;
	}

	withSelectedProjectId(selectedProjectId: string): ProfileBuilder {
		this.json.selectedProjectId = selectedProjectId;

		return this;
	}

	withEmail(email: string): ProfileBuilder {
		this.json.email = email;

		return this;
	}

	withName(name: string): ProfileBuilder {
		this.json.name = name;

		return this;
	}

	withId(id: string): ProfileBuilder {
		this.json.id = id;

		return this;
	}

	withIsActive(isActive: boolean): ProfileBuilder {
		this.json.isActive = isActive;

		return this;
	}

	withLanguage(language: string): ProfileBuilder {
		this.json.language = language;

		return this;
	}

	withOrganization(organization: string): ProfileBuilder {
		this.json.organization = organization;

		return this;
	}

	withLastLoginDate(lastLoginDate: string): ProfileBuilder {
		this.json.lastLoginDate = lastLoginDate;

		return this;
	}

	withPhone(phone: string): ProfileBuilder {
		this.json.phone = phone;

		return this;
	}

	withPermissions(permissions: Permission[]): ProfileBuilder {
		this.json.permissions = permissions;

		return this;
	}

	build(): Profile {
		return Profile.fromJSON(this.json);
	}

	equals(profile: Profile): boolean {
		const idEqual = this.json.id === profile.id;
		const nameEqual = this.json.name === profile.name;
		const languageEqual = this.json.language === profile.language;
		const isPhoneEqual = this.json.phone === profile.phone;
		const isEmailEqual = this.json.email === profile.email;

		return idEqual && nameEqual && languageEqual && isPhoneEqual && isEmailEqual;
	}

	toRequestJson(): ProfileRequestJson {
		let json = {} as ProfileRequestJson;
		json.id = this.json.id;
		json.name = this.json.name;
		json.phone = this.json.phone;
		json.email = this.json.email;
		json.language = this.json.language;
		return json;
	}
}
