import React, { FunctionComponent } from 'react';
import './buttons.scss';
import { ButtonProps, emptyFunction } from './rounded';

export const RoundedRedButton: FunctionComponent<ButtonProps> = ({ text, title, maxWidth, onClickAction }) => {
	return (
		<div
			className='designed-button rounded red'
			title={title || 'Button'}
			style={{ maxWidth: maxWidth || 'auto' }}
			onClick={onClickAction || emptyFunction}
		>
			{text}
		</div>
	);
};

export default RoundedRedButton;
