import { USER_LOGOUT } from '../action-creators/ProfileActionCreators';
import PaginationMeta from '../../models/pagination/PaginationMeta';
import {
	COACH_LISTING_RECEIVED,
	COACH_ENTITY_RECEIVED,
	SEARCH_COACH_LISTING_RECEIVED,
	COACH_BLACKLIST_RECEIVED,
} from '../action-creators/coach-action-creator';
import CoachManualDataModel from '../../models/coach-manual-data/coach-manual-data.model';
import { COACH_SEARCH_FILTER_UPDATE } from '../action-creators/coach-action-creator';

interface InitialState {
	coaches: CoachManualDataModel[];
	pagination: PaginationMeta;
	coachDeleted: boolean;
	coach: CoachManualDataModel;
	searchFilter: string;
}
const initialState: InitialState = {
	coaches: [],
	coach: CoachManualDataModel.builder().build(),
	coachDeleted: false,
	pagination: PaginationMeta.builder().build(),
	searchFilter: '',
};

function coachReducer(state: any = initialState, action: any) {
	if (action.type === COACH_LISTING_RECEIVED) {
		return Object.assign({}, state, {
			coaches: action.payload.coaches,
			pagination: action.payload.contentPagination,
		});
	}

	if (action.type === COACH_BLACKLIST_RECEIVED) {
		return Object.assign({}, state, {
			coaches: action.payload,
		});
	}

	if (action.type === SEARCH_COACH_LISTING_RECEIVED) {
		return Object.assign({}, state, {
			coaches: action.payload.data,
		});
	}

	if (action.type === COACH_ENTITY_RECEIVED) {
		return Object.assign({}, state, {
			coach: action.payload.coach,
		});
	}

	if (action.type === COACH_SEARCH_FILTER_UPDATE) {
		return Object.assign({}, state, {
			searchFilter: action.payload,
		});
	}

	if (action.type === USER_LOGOUT) {
		state = initialState;
	}

	return state;
}

export default coachReducer;
