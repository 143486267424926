export const SORT_DIRECTION_OPTIONS = (t: any) => [
	{ value: 'asc', label: t('asc') },
	{ value: 'desc', label: t('desc') },
];

export const selectOptions = (value: string, t: any) => {
	switch (value) {
		case 'asc': {
			return SORT_DIRECTION_OPTIONS(t)[0];
		}
		case 'desc': {
			return SORT_DIRECTION_OPTIONS(t)[1];
		}
		default: {
			return null;
		}
	}
};
