import Project from '../../models/project/Project';
import { USER_PROJECTS_FAILED, USER_PROJECTS_RECEIVED } from '../action-creators/UserBundleActionCreators';
import { AVAILABLE_PROJECTS, CLEAR_PROJECTS_RESOURCES_LOADED } from '../../constants/constants';
import {
	CURRENT_PROJECT_SELECT,
	DISTRIBUTION_CHANNELS_RECEIVED,
	DISTRIBUTION_REGIONS_RECEIVED,
	LANGUAGE_CODE_UPDATE,
	LANGUAGE_CODE_UPDATE_SUCCESS,
	PROJECT_FEATURES_RECEIVED,
	PROJECT_RESOURCES_FAILED,
	PROJECT_RESOURCES_RECEIVED,
} from '../action-creators/ProjectResourcesActionCreators';
import Profile from '../../models/profile/Profile';
import { USER_LOGOUT } from '../action-creators/ProfileActionCreators';
import { actionService } from '../../App';
import { toast } from 'react-toastify';
import i18n from '../../i18n';
import SentryService from '../../services/sentry/sentry';

interface InitialState {
	projects: Project[];
	profile: Profile;
	availableProjects: Project[];
	currentProject: Project;
	projectResourcesLoaded: boolean;
}

const initialState: InitialState = {
	projects: [],
	profile: Profile.builder().build(),
	availableProjects: [],
	currentProject: Project.builder().build(),
	projectResourcesLoaded: false,
};

function projectReducer(state: any = initialState, action: any) {
	if (action.type === USER_PROJECTS_RECEIVED) {
		state = Object.assign({}, state, {
			projects: action.payload.projects,
			profile: action.payload.profile,
		});
	}

	if (action.type === USER_PROJECTS_FAILED) {
		state = Object.assign({}, state.error, {
			requestUserProjectsError: action.payload,
		});
	}

	if (action.type === AVAILABLE_PROJECTS) {
		state = Object.assign({}, state, {
			availableProjects: action.payload,
		});
	}

	if (action.type === PROJECT_RESOURCES_RECEIVED) {
		state = Object.assign({}, state, {
			projectResourcesLoaded: action.payload,
		});
	}

	if (action.type === CURRENT_PROJECT_SELECT) {
		state = Object.assign({}, state, {
			currentProject: action.payload,
		});

		SentryService.setProject(action.payload.domain);
	}

	if (action.type === PROJECT_RESOURCES_FAILED) {
		state = Object.assign({}, state.error, {
			projectResourcesError: action.payload,
		});
	}

	if (action.type === CLEAR_PROJECTS_RESOURCES_LOADED) {
		state = Object.assign({}, state, {
			projectResourcesLoaded: false,
		});
	}

	if (action.type === PROJECT_FEATURES_RECEIVED) {
		const currentProject = Project.builder(state.currentProject).withFeatures(action.payload).build();

		if (currentProject.domain && currentProject.domain.length > 0) {
			state = Object.assign({}, state, {
				currentProject,
			});
		}
	}

	if (action.type === DISTRIBUTION_REGIONS_RECEIVED) {
		const currentProject = Project.builder(state.currentProject).withDistributionRegions(action.payload).build();

		if (currentProject.domain && currentProject.domain.length > 0) {
			state = Object.assign({}, state, {
				currentProject,
			});
		}
	}

	if (action.type === DISTRIBUTION_CHANNELS_RECEIVED) {
		const currentProject = Project.builder(state.currentProject).withDistributionChannels(action.payload).build();

		if (currentProject.domain && currentProject.domain.length > 0) {
			state = Object.assign({}, state, {
				currentProject,
			});
		}
	}

	if (action.type === LANGUAGE_CODE_UPDATE) {
		state = Object.assign({}, state, {
			currentProject: action.payload,
		});
	}

	if (action.type === LANGUAGE_CODE_UPDATE_SUCCESS) {
		actionService.emitGeneralAction(LANGUAGE_CODE_UPDATE_SUCCESS);
		toast.success(i18n.t('language_changed'));
	}

	if (action.type === USER_LOGOUT) {
		state = initialState;
	}

	return state;
}

export default projectReducer;
