import 'reflect-metadata';
import { jsonMember, jsonObject } from 'typedjson';

@jsonObject
export default class AdminModel {
	@jsonMember(String)
	public id: string = '';
	@jsonMember(String, { name: 'full_name' })
	public fullName: string = '';
	@jsonMember(String)
	public organization: string = '';
	@jsonMember(String)
	public email: string = '';
	@jsonMember(String)
	public phone: string = '';
	@jsonMember(String)
	public language: string = '';
	@jsonMember(Boolean, { name: 'is_active' })
	public isActive: boolean = false;
	@jsonMember(String, { name: 'last_login_date' })
	public lastLoginDate: string = '';
}
