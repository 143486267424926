import moment from 'moment';
import { MatchesFilters } from '../../../../../store/reducers/match-lineups-edit.reducer';

// Preselect 30 days period initially.
export const defaultDate = [moment().subtract(10, 'days').format('YYYY-MM-DD'), moment().add(20, 'days').format('YYYY-MM-DD')];

// Format the dates for BE.
export const appendDateStandardSuffixTo = (date: string) => `${date}T23:59:59-00:00`;

export const appendDateStandardSuffixFrom = (date: string) => `${date}T00:00:00-00:00`;

type UrlConfig = {
	teamIds: string;
	status: string;
	order: string;
	dateFrom: string;
	dateTo: string;
	tournamentIds: string;
	seasonIds: string;
	stageIds: string;
	roundIds: string;
};

export const constructMatchesGetUrl = (config: UrlConfig) => {
	const { tournamentIds, teamIds, status, order, seasonIds, stageIds, roundIds, dateFrom, dateTo } = config;

	const fromStartTime = dateFrom ? `&from_start_time=${appendDateStandardSuffixFrom(dateFrom)}` : '';
	const toStartTime = dateTo ? `&to_start_time=${appendDateStandardSuffixTo(dateTo)}` : '';
	const rounds = roundIds && `roundIds`.length > 0 ? `&rounds=${roundIds}` : '';
	const statusTypes = status && status.length > 0 ? `&status_types=${status}` : '';
	const teamIdsQuery = teamIds && `teamIds`.length > 0 ? `&team_ids=${teamIds}` : '';
	const tournamentIdsQuery = tournamentIds && `tournamentIds`.length > 0 ? `&tournament_ids=${tournamentIds}` : '';
	const seasonIdsQuery = seasonIds && `seasonIds`.length > 0 ? `&season_ids=${seasonIds}` : '';
	const stageIdsQuery = stageIds && `stageIds`.length > 0 ? `&stage_ids=${stageIds}` : '';
	const sortDirectionQuery = order && order.length > 0 ? `&sort_direction=${order}` : '';

	// Remove unnecessary '&' element of first query parameter
	let url = `/matches?${teamIdsQuery}${tournamentIdsQuery}${seasonIdsQuery}${stageIdsQuery}${rounds}${statusTypes}${sortDirectionQuery}${fromStartTime}${toStartTime}`;
	let formattedUrl = url.slice(0, 9) + url.slice(10);

	return formattedUrl;
};

/**
 * Sets the proper filter depending on the filter type (Tournament, Season, Stage)
 * 1. Tournament
 *  - Sets the tournament season, if the currently selected tournament is the same as the one in the state
 *  - Sets the tournament season stage, if the currently selected tournament is the same as the on in the state
 *  - Sets the tournament season stage round, if the currently selected tournament is the same as the on in the state
 * 2. Season
 *  - Sets the season stage, if the currently selected season is the same as the one in the state
 *  - Sets the season stage round, if the currently selected season is the same as the one in the state
 * 3. Stage
 *  - Sets the stage, if the currently selected stage is the same as the one in the state
 *  - Sets the stage round, if the currently selected stage is the same as the one in the state
 * @param filters
 * @param data
 * @param type
 */

export const mapFiltersToState = (filters: MatchesFilters, data: any, type: string): MatchesFilters => {
	switch (type) {
		case 'tournament': {
			return {
				tournament: data,
				season: filters.tournament ? (filters.tournament.id === data.id ? filters.season : null) : null,
				stage: filters.tournament ? (filters.tournament.id === data.id ? filters.stage : null) : null,
				round: filters.tournament ? (filters.tournament.id === data.id ? filters.round : null) : null,
			};
		}
		case 'season': {
			return {
				...filters,
				season: data,
				stage: filters.season ? (filters.season.id === data.id ? filters.stage : null) : null,
				round: filters.season ? (filters.season.id === data.id ? filters.round : null) : null,
			};
		}
		case 'stage': {
			return {
				...filters,
				stage: data,
				round: filters.stage ? (filters.stage.id === data.id ? filters.round : null) : null,
			};
		}
		default: {
			return {
				...filters,
				round: data,
			};
		}
	}
};
