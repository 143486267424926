import ErrorResponseModel from '../../models/error-response/ErrorResponseModel';
import Project from '../../models/project/Project';
import Tag from '../../models/tag/Tag';

export const TAG_LISTING_REQUEST = '[ACTION] TAG_LISTING_REQUEST';
export const TAG_LISTING_RECEIVED = '[ACTION] TAG_LISTING_RECEIVED';
export const TAG_LISTING_FAILED = '[ACTION] TAG_LISTING_FAILED';

export const TAG_LISTING_UPDATE = '[ACTION] TAG_LISTING_UPDATE';

export const TAG_SEARCH = '[ACTION] TAG_SEARCH';
export const TAG_SEARCH_FAILED = '[ACTION] TAG_SEARCH_FAILED';

export const TAG_ENTITY_DELETE = '[ACTION] TAG_ENTITY_DELETE';
export const TAG_ENTITY_DELETE_SUCCESS = '[ACTION] TAG_ENTITY_DELETE_SUCCESS';
export const TAG_ENTITY_DELETE_FAILED = '[ACTION] TAG_ENTITY_DELETE_FAILED';

export const TAG_ENTITY_CREATE = '[ACTION] TAG_ENTITY_CREATE';
export const TAG_ENTITY_CREATE_SUCCESS = '[ACTION] TAG_ENTITY_CREATE_SUCCESS';
export const TAG_ENTITY_CREATE_FAILED = '[ACTION] TAG_ENTITY_CREATE_FAILED';

export const TAG_ENTITY_UPDATE = '[ACTION] TAG_ENTITY_UPDATE';
export const TAG_ENTITY_UPDATE_FAILED = '[ACTION] TAG_ENTITY_UPDATE_FAILED';
export const TAG_ENTITY_UPDATE_SUCCESS = '[ACTION] TAG_ENTITY_UPDATE_SUCCESS';

export const TAG_ENTITY_REQUEST = '[ACTION] TAG_ENTITY_REQUEST';
export const TAG_ENTITY_RECEIVED = '[ACTION] TAG_ENTITY_RECEIVED';
export const TAG_ENTITY_REQUEST_FAILED = '[ACTION] TAG_ENTITY_REQUEST_FAILED';

export function returnObjectForTagListingReceived(tags: any) {
	return {
		type: TAG_LISTING_RECEIVED,
		payload: tags,
	};
}

export function returnObjectForTagListingFailed(error: any) {
	return {
		type: TAG_LISTING_FAILED,
		payload: ErrorResponseModel.builder().fromResponse(error).build(),
	};
}

export function returnObjectForTagListingRequest(page: string, project: Project, text: string) {
	return {
		type: TAG_LISTING_REQUEST,
		payload: { page, project, text },
	};
}

export function returnObjectForTagEntityCreate(tag: Tag, project: Project) {
	return {
		type: TAG_ENTITY_CREATE,
		payload: { tag, project },
	};
}

export function returnObjectForTagEntityCreateSuccess(id: string) {
	return {
		type: TAG_ENTITY_CREATE_SUCCESS,
		payload: id,
	};
}

export function returnObjectForTagListingUpdate(tags: Tag[]) {
	return {
		type: TAG_LISTING_UPDATE,
		payload: tags,
	};
}

export function returnObjectForTagEntityUpdate(tag: Tag, project: Project) {
	return {
		type: TAG_ENTITY_UPDATE,
		payload: { tag, project },
	};
}

export function returnObjectForTagEntityUpdateSuccess() {
	return {
		type: TAG_ENTITY_UPDATE_SUCCESS,
	};
}

export function returnObjectForTagEntityUpdateFailed() {
	return {
		type: TAG_ENTITY_UPDATE_FAILED,
	};
}

export function returnObjectForTagEntityCreateFailed() {
	return {
		type: TAG_ENTITY_CREATE_FAILED,
	};
}

export function returnObjectForTagEntityRequest(id: string, project: Project) {
	return {
		type: TAG_ENTITY_REQUEST,
		payload: { id, project },
	};
}

export function returnObjectForTagEntityReceived(tag: any) {
	return {
		type: TAG_ENTITY_RECEIVED,
		payload: tag,
	};
}

export function returnObjectForTagSearch(text: string, project: Project) {
	return {
		type: TAG_SEARCH,
		payload: { text, project },
	};
}

export function returnObjectForTagSearchFailed(error: any) {
	return {
		type: TAG_SEARCH_FAILED,
		payload: ErrorResponseModel.builder().fromResponse(error).build(),
	};
}

export function returnObjectForTagEntityDelete(id: string, project: Project) {
	return {
		type: TAG_ENTITY_DELETE,
		payload: { id, project },
	};
}

export function returnObjectForTagEntityDeleteSuccess() {
	return {
		type: TAG_ENTITY_DELETE_SUCCESS,
	};
}

export function returnObjectForTagEntityDeleteFailed() {
	return {
		type: TAG_ENTITY_DELETE_FAILED,
	};
}
