import React, { FunctionComponent } from 'react';
import Masonry from 'react-masonry-css';
import { imagePlaceholderBase64 } from '../../../../../../../global-helpers/global.constants';
import Image from '../../../../../../../models/image/Image';
import './styles/gallery-block-masonry.scss';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

type ImageCellsProps = {
	images: Image[];
	galleryItems: Image[];
	onGalleryItemsChange: (data: Image[]) => void;
};

const ImageCells: FunctionComponent<ImageCellsProps> = ({ images, onGalleryItemsChange, galleryItems }) => {
	const [t] = useTranslation();

	const onImageSelect = (image: Image) => {
		let imagesWithSameId = galleryItems.filter((item: Image) => item.id === image.id);
		if (!imagesWithSameId.length) {
			const items = [];
			items.push(image);
			onGalleryItemsChange(items);
		} else {
			toast.warn(t('gallery_item_already_added'));
		}
	};

	return (
		<Masonry
			breakpointCols={{
				default: 3,
				1380: 3,
				1300: 2,
				990: 4,
				560: 3,
				520: 2,
			}}
			className='gallery-block-masonry-grid'
			columnClassName='gallery-block-masonry-grid_column'
		>
			{images.map((image: Image) => {
				if (image && image.urls) {
					const imageSrc = image.urls && image.urls.uploaded && image.urls.uploaded.embed ? image.urls.uploaded.embed : imagePlaceholderBase64;
					return (
						<img
							src={imageSrc}
							id={`image-cell-${image.id}`}
							key={`image-preview-key-${image.id}`}
							className='img-fluid w-100 mb-1 cursor-pointer'
							onClick={() => onImageSelect(image)}
							alt={image.description || ''}
							onError={(i: any) => (i.target ? (i.target.src = imagePlaceholderBase64) : '')}
						/>
					);
				}
			})}
		</Masonry>
	);
};

export default ImageCells;
