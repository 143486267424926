import DugoutVideosModel from './dugout-videos.model';
import { DugoutVideosJson } from './dugout-videos-json';
import { DugoutVideosRequestJson } from './dugout-videos-request-json';
import DugoutVideoModel from './dugout-video/dugout-video.model';

export default class DugoutVideosBuilder {
	private json: DugoutVideosJson;

	constructor(videos?: DugoutVideosModel | DugoutVideosJson) {
		if (videos && videos instanceof DugoutVideosModel) {
			this.json = videos.toJSON();
		} else if (videos) {
			this.json = videos;
		} else {
			this.json = {} as DugoutVideosJson;
		}
	}

	public withTitle(title: string) {
		this.json.title = title;

		return this;
	}

	public withVideoList(videoList: DugoutVideoModel[]) {
		this.json.videoList = videoList;

		return this;
	}

	public build() {
		return DugoutVideosModel.fromJSON(this.json);
	}

	toRequestJson(): DugoutVideosRequestJson {
		let json = {} as DugoutVideosRequestJson;
		json.title = this.json.title;
		json.playlist = this.json.videoList;

		return json;
	}
}
