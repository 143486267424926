import React, { FunctionComponent, useEffect, useState } from 'react';
import { Button, Col, FormGroup, Input, InputGroup, InputGroupAddon, Row } from 'reactstrap';
import { ContentTypes } from '../../../../../constants/content-types';
import TournamentGroupModel from '../../../../../models/v2/Tournament/Group/TournamentGroupModel';
import ContentTitleContainer from '../../../../Partials/BaseComponents/ListingComponent/subcomponents/ContentTitle/ContentTitleContainer';
import NewContentContainer from '../../../../Partials/BaseComponents/ListingComponent/subcomponents/NewContent/NewContentContainer';
import { getTournamentsGroups } from '../../helpers/TournamentsGroupsService';
import TournamentsGroupRows from './TournamentsGroupRows';
import './TournamentGroupsList.scss';

type Props = {
	t: any;
	analytics: any;
};

const TournamentGroupsList: FunctionComponent<Props> = ({ t, analytics }) => {
	const [defaultTournamentsGroups, setDefaultTournamentsGroups] = useState([] as TournamentGroupModel[]);
	const [tempTournamentsGroups, setTempTournamentsGroups] = useState([] as TournamentGroupModel[]);
	const [searchedTextValue, setSearchedTextValue] = useState('');

	document.title = t('tournament_groups');

	useEffect(() => {
		getTournamentsGroups(t('error_loading_tournaments_groups')).then((response) => {
			const tournamentsGroupsReversed = response.reverse(); // to display the newest result first
			setDefaultTournamentsGroups(tournamentsGroupsReversed);
			setTempTournamentsGroups(tournamentsGroupsReversed);
		});
	}, []);

	const onSearchContent = () => {
		const result = defaultTournamentsGroups.filter((tournamentGroup) => tournamentGroup.name.includes(searchedTextValue));
		setTempTournamentsGroups(result);
	};

	return (
		<div className='card animated fadeIn tournament-groups-list'>
			<ContentTitleContainer contentType={ContentTypes.TOURNAMENT_GROUPS} t={t} />
			<div className='card-body'>
				<FormGroup row className='mb-2 tournament-group-search-wrapper'>
					<Col md='4'>
						<NewContentContainer analytics={analytics} t={t} contentType={ContentTypes.TOURNAMENT_GROUPS} display={true} />
					</Col>
					<Col md='8'>
						<InputGroup>
							<Input
								type='text'
								id='search-input'
								name='search-input'
								placeholder={t('search_for_tournament_order')}
								onChange={(el) => setSearchedTextValue(el.target.value)}
								onKeyPress={(key) => (key.charCode === 13 ? onSearchContent() : '')}
								value={searchedTextValue}
							/>
							<InputGroupAddon addonType='append'>
								<Button type='button' color='primary' id='content-listing-search-button' onClick={onSearchContent}>
									<i className={'fa fa-search'}>&nbsp;</i>
									{t('search')}
								</Button>
							</InputGroupAddon>
						</InputGroup>
					</Col>
				</FormGroup>
				<Row className='font-weight-bold py-2 px-2 border-top border-bottom tablet-d-none'>
					<Col md='4'>{t('name')}</Col>
					<Col md='6'>{t('description')}</Col>
					<Col md='2' className='text-right'>
						{t('actions')}
					</Col>
				</Row>
				<TournamentsGroupRows tournamentsGroups={tempTournamentsGroups} t={t} />
			</div>
		</div>
	);
};

export default TournamentGroupsList;
