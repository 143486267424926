const getDataAttributes = (element: Element): Record<string, any> => {
	const dataAttributes: Record<string, string> = {};

	for (const attr of element.attributes) {
		if (attr.name.startsWith('data-')) {
			dataAttributes[attr.name] = attr.value;
		}
	}

	return dataAttributes;
};

export default getDataAttributes;
