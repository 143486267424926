import React from 'react';
import './video-autolink.scss';
import { featuresService } from '../../../../../App';

export type Properties = {
	updateSearchWithTag: (name: string) => any;
	linkedTags: any;
	featureType: string;
};

class VideoAutoLinking extends React.Component<Properties> {
	render() {
		const { linkedTags, featureType } = this.props;
		const featureConfig = featuresService.getFeatureConfig(featureType);

		return featureConfig.autotagging_enabled && linkedTags && linkedTags.length > 0
			? linkedTags.map((tag: any) => (
					<div
						key={tag.id}
						onClick={() => this.props.updateSearchWithTag(tag.name)}
						className='d-flex align-items-center p-0 mr-2 btn btn-sm btn-link dugout-tags'
					>
						{tag.name}
					</div>
			  ))
			: null;
	}
}

export default VideoAutoLinking;
