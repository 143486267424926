import GameModel from '../../../../../../../models/v2/game/game.model';
import SportBookmakerModel from '../../../../../../../models/sport-odds/sport-bookmaker.model';
import { SportOddsWidgetJson } from './sport-odds-widget.json';
import SportOddsWidgetBuilder from './sport-odds-widget.builder';
import SportTeamModel from '../../../../partials/sports-team-select/models/sport-team.model';
import OddsModel from '../../../../../../../models/sport-odds/odds.model';

export default class SportOddsWidgetModel {
	readonly team: SportTeamModel;
	readonly game: GameModel;
	readonly bookmakers: SportBookmakerModel[] | null;
	readonly market: any = { name: '', value: '' };
	readonly oddsList: OddsModel[];

	private constructor(team: SportTeamModel, game: GameModel, bookmakers: SportBookmakerModel[] | null, market: any, oddsList: OddsModel[]) {
		this.game = game;
		this.team = team;
		this.bookmakers = bookmakers;
		this.market = market;
		this.oddsList = oddsList;
	}

	toJson(): SportOddsWidgetJson {
		return {
			team: this.team,
			game: this.game,
			bookmakers: this.bookmakers,
			market: this.market,
			oddsList: this.oddsList,
		};
	}

	static fromJson(json: SportOddsWidgetJson): SportOddsWidgetModel {
		return new SportOddsWidgetModel(json.team, json.game, json.bookmakers, json.market, json.oddsList);
	}

	static builder(odds?: SportOddsWidgetModel): SportOddsWidgetBuilder {
		return new SportOddsWidgetBuilder(odds);
	}
}
