import React, { FunctionComponent, useState } from 'react';
import { connect } from 'react-redux';
import { Button, ButtonGroup, ButtonToolbar, Tooltip, UncontrolledTooltip, Collapse, Alert, Card, CardBody } from 'reactstrap';
import { auditLogService, featuresService } from '../../../App';
import Profile from '../../../models/profile/Profile';
import Project from '../../../models/project/Project';
import { FeatureTypes } from '../../../services/feature-service/features.enum';
import { redoBlocky, undoBlocky } from '../../../store/action-creators/BlockyActionCreator';
import BlockModel from '../Blocky/models/block.model';
import UndoRedoBlockyModel from '../Blocky/models/UndoRedoBlockyMode';
import './EditorToolbar.scss';
import OneSignalModal from '../../Partials/Modals/OneSignal/OneSignalModal';
import { availableContentTypes } from '../../../services/content-models-service/ContentModelService';
import { ToolbarSave, saveProperty } from './toolbar-save';

type Properties = {
	t: any;
	contentType: availableContentTypes;
	blocksUndoRedo: UndoRedoBlockyModel;
	profile: Profile;
	project: Project;
	undoBlocky: () => any;
	redoBlocky: () => any;
	onChange: (blocks: BlockModel[]) => void;
	save: saveProperty;
	onPreview: Function | null; // will be null if the functionality is not available
	onCopyToProject: Function | null;
	onAuditLog: Function | null;
	content?: any;
	showNotifyUsers?: boolean;
	hideUndoRedoButtons?: boolean;
};

const EditorToolbar: FunctionComponent<Properties> = ({
	t,
	contentType,
	blocksUndoRedo,
	profile,
	project,
	undoBlocky,
	redoBlocky,
	onChange,
	save,
	onPreview,
	onCopyToProject,
	onAuditLog,
	content,
	showNotifyUsers,
	hideUndoRedoButtons,
}) => {
	const isUndoRedoEnabled =
		featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.CONTENT_BLOCKY_UNDO_REDO) &&
		featuresService.checkAvailableUndoRedoPageFromFeature(contentType) &&
		!hideUndoRedoButtons;
	const isContentPreviewEnabled = project && project.previewUrl && project.previewUrl.length > 0 && onPreview !== null;
	const isAuditLogEnabled = auditLogService.isFeatureEnabled(featuresService, profile.id) && onAuditLog !== null;
	const isCopyToProjectEnabled = onCopyToProject !== null;
	const isOneSignalEnabled = featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.ONESIGNAL_INTEGRATION) && showNotifyUsers;

	const [tooltipState, toggleTooltip] = React.useState({
		undo: false,
		redo: false,
		preview: false,
		copy: false,
		auditLog: false,
		editorToggle: false,
	});

	const undoBlockyStep = () => {
		if (isUndoRedoEnabled && blocksUndoRedo.past.length > 0) {
			const lastBlockyFutureIndex = blocksUndoRedo.past.length - 1;
			const newPresentBlocky = blocksUndoRedo.past[lastBlockyFutureIndex] as BlockModel[];
			onChange(newPresentBlocky);
			undoBlocky();
		}
	};

	const redoBlockyStep = () => {
		if (isUndoRedoEnabled && blocksUndoRedo.future.length > 0) {
			const newPresentBlocky = blocksUndoRedo.future[0] as BlockModel[];
			onChange(newPresentBlocky);
			redoBlocky();
		}
	};

	const viewAuditLog = () => {
		onAuditLog !== null && onAuditLog();
		window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
	};

	const [isOpen, setIsOpen] = useState(false);

	const toggle = () => setIsOpen(!isOpen);

	return (
		<div className='editor-toolbar-container'>
			{isOpen ? (
				<ButtonToolbar className='editor-button-toolbar'>
					<Collapse horizontal='true' isOpen={isOpen}>
						<Card>
							<CardBody>
								{isUndoRedoEnabled && (
									<ButtonGroup>
										<div id='undo-button'>
											<Button onClick={undoBlockyStep} disabled={blocksUndoRedo.past.length === 0}>
												<i className='fa-solid fa-rotate-left fa-lg'></i>
											</Button>
										</div>
										<Tooltip
											placement='top'
											isOpen={tooltipState.undo}
											className='editor-toolbar-tooltip'
											target='undo-button'
											toggle={() => toggleTooltip({ ...tooltipState, undo: !tooltipState.undo })}
										>
											{t('Undo')}
										</Tooltip>
										<div id='redo-button'>
											<Button onClick={redoBlockyStep} disabled={blocksUndoRedo.future.length === 0}>
												<i className='fa-solid fa-rotate-right fa-lg'></i>
											</Button>
										</div>
										<Tooltip
											placement='top'
											isOpen={tooltipState.redo}
											className='editor-toolbar-tooltip'
											target='redo-button'
											toggle={() => toggleTooltip({ ...tooltipState, redo: !tooltipState.redo })}
										>
											{t('Redo')}
										</Tooltip>
									</ButtonGroup>
								)}
								{isContentPreviewEnabled && (
									<ButtonGroup>
										<Button id='on-preview-button' onClick={() => onPreview()}>
											<i className='fa-solid fa-arrows-to-eye fa-lg'></i>
										</Button>
										<Tooltip
											placement='top'
											isOpen={tooltipState.preview}
											className='editor-toolbar-tooltip'
											target='on-preview-button'
											toggle={() => toggleTooltip({ ...tooltipState, preview: !tooltipState.preview })}
										>
											{t('preview')}
										</Tooltip>
									</ButtonGroup>
								)}
								{isCopyToProjectEnabled && (
									<ButtonGroup>
										<Button id='on-copy-to-project-button' onClick={() => onCopyToProject()}>
											<i className='fa-regular fa-paste fa-lg'></i>
										</Button>
										<Tooltip
											placement='top'
											isOpen={tooltipState.copy}
											className='editor-toolbar-tooltip'
											target='on-copy-to-project-button'
											toggle={() => toggleTooltip({ ...tooltipState, copy: !tooltipState.copy })}
										>
											{t('copy_to_project')}
										</Tooltip>
									</ButtonGroup>
								)}
								{isAuditLogEnabled && (
									<ButtonGroup>
										<Button id='audit-log-button' onClick={viewAuditLog}>
											<i className='fa-solid fa-clock-rotate-left fa-lg'></i>
										</Button>
										<Tooltip
											placement='top'
											isOpen={tooltipState.auditLog}
											className='editor-toolbar-tooltip'
											target='audit-log-button'
											toggle={() => toggleTooltip({ ...tooltipState, auditLog: !tooltipState.auditLog })}
										>
											{t('audit_log')}
										</Tooltip>
									</ButtonGroup>
								)}
								{isOneSignalEnabled && <OneSignalModal t={t} contentType={contentType} content={content} showButtonWithTooltip />}
								<ToolbarSave save={save} t={t} />
							</CardBody>
						</Card>
					</Collapse>
				</ButtonToolbar>
			) : null}
			<ButtonGroup className='editor-toggle-btn'>
				<Button id='editor-toggle-button' onClick={toggle}>
					{isOpen ? <i className='fa-solid fa-chevron-right fa-lg'></i> : <i className='fa-solid fa-chevron-left fa-lg'></i>}
				</Button>
				<Tooltip
					placement='top'
					isOpen={tooltipState.editorToggle}
					className='editor-toolbar-tooltip'
					target='editor-toggle-button'
					toggle={() => toggleTooltip({ ...tooltipState, editorToggle: !tooltipState.editorToggle })}
				>
					{t('toggle_editor_toolbar')}
				</Tooltip>
			</ButtonGroup>
		</div>
	);
};

function mapDispatchToProps(dispatch: any) {
	const isUndoRedoEnabled = featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.CONTENT_BLOCKY_UNDO_REDO);
	const maximumRedoUndoStates: number = featuresService.extractUndoRedoStepsFromBlockyUndoRedoFeature();
	return {
		undoBlocky: () => isUndoRedoEnabled && dispatch(undoBlocky(maximumRedoUndoStates)),
		redoBlocky: () => isUndoRedoEnabled && dispatch(redoBlocky(maximumRedoUndoStates)),
	};
}

function mapStateToProps(state: any) {
	return {
		project: state.project.currentProject,
		profile: state.profile.profile,
		blocksUndoRedo: state.blocky.blocksUndoRedo as UndoRedoBlockyModel,
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(EditorToolbar);
