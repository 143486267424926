import { USER_LOGOUT } from '../action-creators/ProfileActionCreators';
import Related from '../../models/related/Related';
import {
	ARTICLE_EDIT_RELATED_CONTENT_TEMP_UPDATE,
	ARTICLE_RELATED_CONTENT_TEMP_CLEAR,
	ARTICLE_RELATED_CONTENT_TEMP_UPDATE,
	GALLERY_EDIT_RELATED_CONTENT_TEMP_UPDATE,
	GALLERY_RELATED_CONTENT_TEMP_CLEAR,
	GALLERY_RELATED_CONTENT_TEMP_UPDATE,
	VIDEO_EDIT_RELATED_CONTENT_TEMP_UPDATE,
	VIDEO_RELATED_CONTENT_TEMP_CLEAR,
	VIDEO_RELATED_CONTENT_TEMP_UPDATE,
	WIKI_EDIT_RELATED_CONTENT_TEMP_UPDATE,
	WIKI_RELATED_CONTENT_TEMP_CLEAR,
	WIKI_RELATED_CONTENT_TEMP_UPDATE,
} from '../action-creators/RelatedContentTempActionCreator';
import { ARTICLE_COPY_RELATED_RECEIVED, ARTICLE_ENTITY_RELATED_RECEIVED } from '../action-creators/ArticleActionCreator';
import { ARTICLE_TEMP_CLEAR } from '../action-creators/ArticleTempActionCreator';
import { VIDEO_COPY_RELATED_RECEIVED, VIDEO_RELATED_RECEIVED } from '../action-creators/VideoActionCreator';
import { VIDEO_TEMP_CLEAR } from '../action-creators/VideoTempActionCreator';
import { GALLERY_TEMP_CLEAR } from '../action-creators/GalleryTempActionCreator';
import { GALLERY_COPY_RELATED_RECEIVED, GALLERY_RELATED_RECEIVED } from '../action-creators/GalleryActionCreator';
import { PROJECT_RESOURCES_REQUEST } from '../../constants/constants';
import { WIKI_COPY_RELATED_RECEIVED, WIKI_ENTITY_RELATED_RECEIVED } from '../action-creators/WikiActionCreator';
import { WIKI_TEMP_CLEAR } from '../action-creators/WikiTempActionCreator';

interface InitialState {
	articleRelated: Related[];
	articleEditRelated: Related[];
	videoRelated: Related[];
	videoEditRelated: Related[];
	galleryRelated: Related[];
	galleryEditRelated: Related[];
	wikiRelated: Related[];
	wikiEditRelated: Related[];
}
const initialState: InitialState = {
	articleRelated: [],
	articleEditRelated: [],
	videoRelated: [],
	videoEditRelated: [],
	galleryRelated: [],
	galleryEditRelated: [],
	wikiRelated: [],
	wikiEditRelated: [],
};

function relatedContentTempReducer(state: any = initialState, action: any) {
	if (action.type === PROJECT_RESOURCES_REQUEST) {
		return Object.assign({}, state, initialState);
	}

	if (action.type === ARTICLE_ENTITY_RELATED_RECEIVED) {
		return Object.assign({}, state, {
			articleEditRelated: action.payload.relatedContent,
		});
	}

	if (action.type === ARTICLE_COPY_RELATED_RECEIVED) {
		return Object.assign({}, state, {
			articleRelated: action.payload.relatedContent,
		});
	}

	if (action.type === ARTICLE_RELATED_CONTENT_TEMP_UPDATE) {
		return Object.assign({}, state, {
			articleRelated: action.payload,
		});
	}

	if (action.type === ARTICLE_EDIT_RELATED_CONTENT_TEMP_UPDATE) {
		return Object.assign({}, state, {
			articleEditRelated: action.payload,
		});
	}

	if (action.type === ARTICLE_RELATED_CONTENT_TEMP_CLEAR || action.type === ARTICLE_TEMP_CLEAR) {
		return Object.assign({}, state, {
			articleRelated: null,
		});
	}

	if (action.type === VIDEO_RELATED_RECEIVED) {
		return Object.assign({}, state, {
			videoEditRelated: action.payload.relatedContent,
		});
	}

	if (action.type === VIDEO_COPY_RELATED_RECEIVED) {
		return Object.assign({}, state, {
			videoRelated: action.payload.relatedContent,
		});
	}

	if (action.type === VIDEO_RELATED_CONTENT_TEMP_UPDATE) {
		return Object.assign({}, state, {
			videoRelated: action.payload,
		});
	}

	if (action.type === VIDEO_EDIT_RELATED_CONTENT_TEMP_UPDATE) {
		return Object.assign({}, state, {
			videoEditRelated: action.payload,
		});
	}

	if (action.type === VIDEO_RELATED_CONTENT_TEMP_CLEAR || action.type === VIDEO_TEMP_CLEAR) {
		return Object.assign({}, state, {
			videoRelated: null,
		});
	}

	if (action.type === GALLERY_RELATED_RECEIVED) {
		return Object.assign({}, state, {
			galleryEditRelated: action.payload.relatedContent,
		});
	}

	if (action.type === GALLERY_COPY_RELATED_RECEIVED) {
		return Object.assign({}, state, {
			galleryRelated: action.payload.relatedContent,
		});
	}

	if (action.type === GALLERY_RELATED_CONTENT_TEMP_UPDATE) {
		return Object.assign({}, state, {
			galleryRelated: action.payload,
		});
	}

	if (action.type === GALLERY_EDIT_RELATED_CONTENT_TEMP_UPDATE) {
		return Object.assign({}, state, {
			galleryEditRelated: action.payload,
		});
	}

	if (action.type === GALLERY_RELATED_CONTENT_TEMP_CLEAR || action.type === GALLERY_TEMP_CLEAR) {
		return Object.assign({}, state, {
			galleryRelated: null,
		});
	}

	if (action.type === WIKI_ENTITY_RELATED_RECEIVED) {
		return Object.assign({}, state, {
			wikiEditRelated: action.payload.relatedContent,
		});
	}

	if (action.type === WIKI_COPY_RELATED_RECEIVED) {
		return Object.assign({}, state, {
			wikiRelated: action.payload.relatedContent,
		});
	}

	if (action.type === WIKI_RELATED_CONTENT_TEMP_UPDATE) {
		return Object.assign({}, state, {
			wikiRelated: action.payload,
		});
	}

	if (action.type === WIKI_EDIT_RELATED_CONTENT_TEMP_UPDATE) {
		return Object.assign({}, state, {
			wikiEditRelated: action.payload,
		});
	}

	if (action.type === WIKI_RELATED_CONTENT_TEMP_CLEAR || action.type === WIKI_TEMP_CLEAR) {
		return Object.assign({}, state, {
			wikiRelated: null,
		});
	}

	if (action.type === USER_LOGOUT) {
		state = initialState;
	}

	return state;
}

export default relatedContentTempReducer;
