import React, { useEffect, useState } from 'react';
import * as ReactSortableHOC from 'react-sortable-hoc';
import { arrayMove, SortEnd } from 'react-sortable-hoc';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from '../../../store/store';
import './styles/authors-rows-container.scss';
import { AuthorsList } from './authors-sortable.component';
import AuthorsReorderModal from './authors-modal/authors-reorder.modal.component';
import Author from '../../../models/author/Author';
import { returnObjectForAuthorListingUpdate } from '../../../store/action-creators/AuthorActionCreators';

type ModalState = { isShown: boolean; data: { authors: Author[]; shouldUpdate: boolean } };

type RowsProps = {
	content: any[];
	onDelete: (id: string, title: string) => any;
	analytics: any;
	history: any;
	t: any;
	itemIndex?: number;
};

const SortableRowsList = ReactSortableHOC.SortableContainer(AuthorsList);

export const AuthorsRowsContainer: React.FunctionComponent<RowsProps> = ({ content, onDelete, analytics, history, t }) => {
	// We keep modal in state prevState of authors and shoudUpdate prop because we need to visualise the author new place
	// and return it if the user cancels the reordering action.
	const [showModal, toggleModal] = useState<ModalState>({
		isShown: false,
		data: { authors: [], shouldUpdate: false },
	});
	const [sortingIndex, setSortingIndex] = useState<any>({});

	const authors = useSelector((state: AppState) => state.author.authorListing);
	const dispatch = useDispatch();
	const shouldSort = authors && authors.length > 1;

	const onSortEnd = (sortEnd: SortEnd) => {
		if (shouldSort && sortEnd.oldIndex !== sortEnd.newIndex) {
			// onSortEnd we update lists state with new item positions and open modal to confirm the operation.
			// If the user cancels the operation we update the state with its previous value kept in *showModal.data.lists
			toggleModal({ isShown: true, data: { authors: [...authors], shouldUpdate: false } });
			// We pass sorting indexes in modal component to use them for eventually patch list update.
			setSortingIndex(sortEnd);
			const authorsCopy = [...authors];
			const newAuthors = arrayMove(authorsCopy, sortEnd.oldIndex, sortEnd.newIndex);
			dispatch(returnObjectForAuthorListingUpdate(newAuthors));
		}
	};

	useEffect(() => {
		// If showModal.data.shoudUpdate is true we update authors with previous state.
		if (showModal.data.shouldUpdate === true) {
			dispatch(returnObjectForAuthorListingUpdate(showModal.data.authors));
		}
	}, [showModal.data.shouldUpdate]);

	if (content && content.length > 0) {
		return (
			<tr className='w-100 p-0 d-flex bg-white sortable-list-table-row'>
				<td className='w-100 p-0'>
					<SortableRowsList
						t={t}
						onSortEnd={onSortEnd}
						useDragHandle
						content={content}
						onDelete={onDelete}
						analytics={analytics}
						history={history}
					/>
					{showModal.isShown && <AuthorsReorderModal t={t} showModal={showModal} sortEnd={sortingIndex} toggleModal={toggleModal} />}
				</td>
			</tr>
		);
	}
	return <tr />;
};
