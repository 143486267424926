import ImagoImagesModel from '../../../blocks/content/imago-image-block/models/imago-images.model';
import ImagoImageModel from '../../../blocks/content/imago-image-block/models/imago-image.model';
import { featuresService } from '../../../../../../App';
import { FeatureTypes } from '../../../../../../services/feature-service/features.enum';

const getSizeConfig = () => {
	return featuresService &&
		featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.IMAGO_INTEGRATION) &&
		featuresService.getFeatureConfig(FeatureTypes.IMAGO_INTEGRATION).size
		? featuresService.getFeatureConfig(FeatureTypes.IMAGO_INTEGRATION).size
		: 'mediums';
};

const getImagoURLConfig = () => {
	return featuresService &&
		featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.IMAGO_INTEGRATION) &&
		featuresService.getFeatureConfig(FeatureTypes.IMAGO_INTEGRATION).baseUrl
		? featuresService.getFeatureConfig(FeatureTypes.IMAGO_INTEGRATION).baseUrl
		: 'https://api1.imago-images.de';
};

export const previewImagoImage = (db: string, imageId: string) => {
	let size = getSizeConfig();
	let baseURL = getImagoURLConfig();
	return `${baseURL}/${filterImageDB(db)}/${imageId}/${size}`;
};

const filterImageDB = (db: string) => {
	let sport = 'sp';
	let stock = 'st';

	if (db === 'sport') {
		return sport;
	} else {
		return stock;
	}
};

export const remapImagoImagesFromResponse = (response: any) => {
	if (response && Object.entries(response).length > 0) {
		return ImagoImagesModel.builder()
			.withTotalPhotos(response[0] && response[0].total)
			.withImagesList(remapImagoImageList(response[1] && response[1].pictures))
			.build();
	}

	return {};
};

export const remapImagoImageList = (response: any) => {
	if (response && response.length > 0) {
		return response.map((image: any) => remapImagoImageFromResponse(image));
	}

	return [];
};

export const remapImagoImageFromResponse = (response: any) => {
	if (response && Object.entries(response).length > 0) {
		return ImagoImageModel.builder()
			.withId(response.pictureid)
			.withDescription(response.caption)
			.withType(response.db)
			.withSource(response.source)
			.withWidth(response.width)
			.withHeight(response.height)
			.withImage(previewImagoImage(response.db, response.pictureid))
			.withDate(response.datecreated)
			.build();
	}

	return {};
};
