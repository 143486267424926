import React, { useContext, useEffect, useRef, useState } from 'react';
import * as ReactSortableHOC from 'react-sortable-hoc';
import { SortableHandle } from 'react-sortable-hoc';
import { RowTitleLink } from '../../Partials/BaseComponents/ListingComponent/subcomponents/Rows/subcomponents/RowTitleLink';
import { RowEditButton } from '../../Partials/BaseComponents/ListingComponent/subcomponents/Rows/subcomponents/RowEditButton';
import { RowDeleteButton } from '../../Partials/BaseComponents/ListingComponent/subcomponents/Rows/subcomponents/RowDeleteButton';
import { ConditionalRenderContext } from '../../Partials/BaseComponents/ConditionalRenderWraper/ConditionalRenderWrapper';
import Tag from '../../../models/tag/Tag';
import { Input } from 'reactstrap';
import { toast } from 'react-toastify';
import { ORDERED_TAGS_LIMIT } from './Helpers/TagHelper';

type TagRowProps = {
	content: any;
	onDelete: (id: string, title: string) => any;
	analytics: any;
	history: any;
	t: any;
	itemManualIndex: number;
	updateTagsFromInput: (inputIndex: number, currentIndex: number) => any;
	loading: boolean;
	backgroundStyleIndex: number;
};

type TagsProps = {
	content: any;
	onDelete: (id: string, title: string) => any;
	analytics: any;
	history: any;
	t: any;
	updateTagsFromInput: (inputIndex: number, currentIndex: number) => any;
	loading: boolean;
};

const DragHandle = SortableHandle(() => {
	return (
		<span className='cursor-draggable px-2'>
			<i className='fa fa-bars' />
		</span>
	);
});

export const TagRow: React.FunctionComponent<TagRowProps> = ({
	content,
	history,
	analytics,
	t,
	onDelete,
	itemManualIndex,
	updateTagsFromInput,
	backgroundStyleIndex,
	loading,
}) => {
	const context = useContext(ConditionalRenderContext);
	const [newIndex, setIndex] = useState();
	const [width, setWidth] = useState(0);
	const [useInput, setUseInput] = useState(false);
	const span = useRef();

	useEffect(() => {
		// @ts-ignore
		setWidth(span && span.current && span.current.offsetWidth);
	}, [newIndex]);

	useEffect(() => {
		setIndex(itemManualIndex + 1);
	}, [itemManualIndex]);

	const onIndexChange = (e: any) => {
		setIndex(e.target.value.trim(' '));
		setUseInput(true);
	};

	const updateTags = (inputIndex: any, currentIndex: number) => {
		if (newIndex === '' || newIndex.length <= 0) {
			toast.error(t('index_must_not_be_empty'));
			setIndex(itemManualIndex + 1);
		} else if (isNaN(newIndex) || newIndex < 1 || newIndex > ORDERED_TAGS_LIMIT) {
			toast.error(t('index_out_of_bounds'));
			setIndex(itemManualIndex + 1);
		} else {
			inputIndex = Number(newIndex - 1);
			currentIndex = itemManualIndex;
			updateTagsFromInput(inputIndex, currentIndex);
		}
		setUseInput(false);
		setIndex(itemManualIndex + 1);
	};

	const updateTagsOnEnter = (e: any, inputIndex: any, currentIndex: number) => {
		if (e.key === 'Enter') {
			if (newIndex === '' || newIndex.length <= 0) {
				toast.error(t('index_must_not_be_empty'));
				setIndex(itemManualIndex + 1);
			} else if (isNaN(newIndex) || newIndex < 1 || newIndex > ORDERED_TAGS_LIMIT) {
				toast.error(t('index_out_of_bounds'));
				setIndex(itemManualIndex + 1);
			} else {
				inputIndex = Number(newIndex - 1);
				currentIndex = itemManualIndex;
				updateTagsFromInput(inputIndex, currentIndex);
			}
			setUseInput(false);
			setIndex(itemManualIndex + 1);
		}
	};

	return (
		<div className={`py-2 d-flex border-bottom align-items-center w-100 ${backgroundStyleIndex % 2 === 1 ? '' : 'bg-light'}`}>
			{content.weight <= ORDERED_TAGS_LIMIT ? (
				<div className='align-items-center d-flex w-50 px-2'>
					{!useInput ? (
						<DragHandle />
					) : (
						<span className='cursor-draggable px-2'>
							<i className='fa fa-bars' />
						</span>
					)}
					<span className='span' ref={span}>
						{newIndex}
					</span>
					<Input
						className='index-input'
						style={{ width }}
						value={newIndex}
						onKeyDown={updateTagsOnEnter}
						onBlur={updateTags}
						onChange={onIndexChange}
						disabled={loading}
						type='text'
					/>
					<RowTitleLink
						contentTitle={content.title}
						editContentPath={`/smp/tags/edit/${content.id}`}
						canEditContent={context.hasWritePermission}
						analytics={analytics}
						contentId={`tag-id-${content.id}`}
					/>
				</div>
			) : (
				<div className='align-items-center d-flex w-50 px-2'>
					<RowTitleLink
						contentTitle={content.title}
						editContentPath={`/smp/tags/edit/${content.id}`}
						canEditContent={context.hasWritePermission}
						analytics={analytics}
						contentId={`tag-id-${content.id}`}
					/>
				</div>
			)}

			<div className='w-50 d-flex justify-content-end px-2'>
				<div className='d-flex justify-content-end flex-wrap'>
					<RowEditButton
						contentId={content.id}
						display={context.hasWritePermission}
						editContentPath={`/smp/tags/edit/${content.id}`}
						t={t}
						analytics={analytics}
						history={history}
					/>
					<RowDeleteButton
						analytics={analytics}
						display={context.hasDeletePermission}
						t={t}
						onDelete={onDelete}
						contentTitle={content.title}
						contentId={content.id}
					/>
				</div>
			</div>
		</div>
	);
};

const SortableRow = ReactSortableHOC.SortableElement(TagRow);

export const TagsList: React.FunctionComponent<TagsProps> = ({
	content,
	onDelete,
	analytics,
	history,
	t,
	updateTagsFromInput,
	loading,
}) => {
	return (
		<div>
			{content.map((tag: Tag, index: number) => {
				return (
					<SortableRow
						key={tag.id + index}
						backgroundStyleIndex={index + 1}
						content={tag}
						loading={loading}
						index={tag.weight}
						itemManualIndex={tag.weight}
						onDelete={onDelete}
						updateTagsFromInput={updateTagsFromInput}
						t={t}
						analytics={analytics}
						history={history}
					/>
				);
			})}
		</div>
	);
};
