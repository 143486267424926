export const getUniqueOnly = (array: any, filterByProperty: any) => {
	const result = [];
	const map = new Map();

	for (const item of array) {
		if (!map.has(item[filterByProperty])) {
			map.set(item[filterByProperty], true);
			result.push({ ...item });
		}
	}

	return result;
};

export const uniqueValuesOnly = (arr: any[]) => {
	return arr.filter((x, i, a) => a.indexOf(x) === i);
};
