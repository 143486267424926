import React from 'react';
import { Col, Row } from 'reactstrap';
import FootballTeamSquadWidgetModel from '../models/football-team-squad.model';
import { generateRefreshTimeOptionViewText } from '../../../partials/refresh-time-input/helper';

type Properties = {
	preview: FootballTeamSquadWidgetModel;
	t: any;
};

export const FootballTeamSquadView: React.FunctionComponent<Properties> = ({ preview, t }) => {
	if (preview && preview.team && preview.team.id) {
		return (
			<>
				<Row className='px-4' data-qa='football-team--squad-block-view'>
					<Col className='text-left'>
						<Row className='mb-2'>
							<Col>
								{preview.team && preview.team.id && (
									<div className='single-row'>
										<strong>{t('team')}:</strong> {preview.team.name}
									</div>
								)}
								{preview.tournamentSeason && preview.tournamentSeason.tournamentName && (
									<div className='single-row'>
										<strong>{t('tournament')}:</strong> {preview.tournamentSeason.tournamentName}
									</div>
								)}
								{preview.tournamentSeason && preview.tournamentSeason.seasonName && (
									<div className='single-row'>
										<strong>{t('season')}:</strong> {preview.tournamentSeason.seasonName}
									</div>
								)}
							</Col>
						</Row>
						<Row className='single-row'>
							<Col>
								<strong>{t('select_view')}:</strong>
								<span>{preview.headerDefaultOption && preview.headerDefaultOption.label && ` ${preview.headerDefaultOption.label}`}</span>
							</Col>
						</Row>
						<hr />
					</Col>
				</Row>
				{generateRefreshTimeOptionViewText(preview.refreshTime)}
			</>
		);
	}

	return null;
};
