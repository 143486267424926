import React, { FunctionComponent } from 'react';
import { Button, ButtonGroup } from 'reactstrap';
import BlockableButtonWrapper from '../BaseComponents/BlockableButton/BlockableButtonWrapper';

export type saveProperty = {
	invokeFunction: () => any;
	blockOnActions: string[];
	releaseOnActions: string[];
	blockUntilCorrectContentIsLoaded?: boolean;
};

type Properties = {
	t: any;
	save: saveProperty;
};

export const ToolbarSave: FunctionComponent<Properties> = ({ t, save }) => {
	return (
		<ButtonGroup>
			<BlockableButtonWrapper
				blockOnActions={save.blockOnActions}
				releaseOnActions={save.releaseOnActions}
				blockOverride={!!save.blockUntilCorrectContentIsLoaded}
			>
				<Button onClick={save.invokeFunction} color='primary'>
					{t('save')}
				</Button>
			</BlockableButtonWrapper>
		</ButtonGroup>
	);
};
