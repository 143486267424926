import BannerBlockModel from './banner-block.model';
import { BannerBlockJson } from './banner-block.json';

export default class BannerBlockBuilder {
	private json: BannerBlockJson;

	constructor(bannerBlock?: BannerBlockModel | BannerBlockJson) {
		if (bannerBlock && bannerBlock instanceof BannerBlockModel) {
			this.json = bannerBlock.toJson();
		} else if (bannerBlock) {
			this.json = bannerBlock;
		} else {
			this.json = {} as BannerBlockJson;
		}
	}

	withId(id: string): BannerBlockBuilder {
		this.json.id = id;

		return this;
	}

	withTitle(title: string): BannerBlockBuilder {
		this.json.title = title;

		return this;
	}

	withCode(code: string): BannerBlockBuilder {
		this.json.code = code;

		return this;
	}

	build(): BannerBlockModel {
		return BannerBlockModel.fromJson(this.json);
	}
}
