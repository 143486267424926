import React, { FunctionComponent } from 'react';
import { Card, CardBody, CardHeader, Col, Table } from 'reactstrap';
import TournamentModel from '../../../../../models/v2/Tournament/Entity/TournamentModel';

type Props = {
	t: any;
	tournament: TournamentModel;
};

export const TournamentInfo: FunctionComponent<Props> = ({ t, tournament }) => {
	return (
		<Col col='8' lg='8' md='12' sm='12' xs='12'>
			<div className='animated fadeIn'>
				{/*Tournament info card*/}
				<Card className='tournament-edit-info'>
					<CardHeader className='card-header d-flex align-items-center'>
						<b className='mr-auto mb-0'>{t('tournament')}</b>
					</CardHeader>
					<CardBody>
						<Table xs='2' className='table'>
							<thead>
								<tr>
									<th>{t('name')}</th>
									<th>{t('country')}</th>
									<th className='tablet-d-none'>{t('type')}</th>
									<th className='mobile-d-none'>{t('gender')}</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>{tournament.name}</td>
									<td>
										{tournament.country.name ? tournament.country.name : '-'}
										{tournament.country.urlFlag ? <img className='ml-2' width={24} src={tournament.country.urlFlag} alt='flag' /> : ''}
									</td>
									<td className='tablet-d-none'>{tournament.type}</td>
									<td className='mobile-d-none'>{tournament.gender}</td>
								</tr>
							</tbody>
						</Table>
					</CardBody>
				</Card>
			</div>
		</Col>
	);
};
