import { VideoUrlsJson } from './video-urls.json';
import UrlsBuilder from './video-urls.builder';

export default class VideoUrls {
	readonly type: string;
	readonly url: string;

	private constructor(type: string, url: string) {
		this.type = type;
		this.url = url;
	}

	toJSON(): VideoUrlsJson {
		return {
			type: this.type,
			url: this.url,
		};
	}

	static fromJSON(json: VideoUrlsJson): VideoUrls {
		return new VideoUrls(json.type, json.url);
	}

	static builder(videoUrl?: VideoUrls): UrlsBuilder {
		return new UrlsBuilder(videoUrl);
	}
}
