import React, { FunctionComponent, useEffect } from 'react';
import Select from 'react-select';
import { ValueType } from 'react-select/src/types';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import SportsTypesModel from '../../../../../../models/v2/sports-types/sports-types.model';
import { availableSportsToOption, sportTypeToOption } from '../../../../../Partials/Sidebar/tags-refactored/helpers/sport-select.helper';
import { DATA_QA_ATTRIBUTES } from '../../../../../Partials/Sidebar/general-content/constants/data-qa.attributes';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../../../store/store';
import { SportTypes } from '../../../../../../constants/sport.types';
import LiveBlogModel from '../../../models/live-blog.model';

type Properties = {
	t: any;
	liveBlog: LiveBlogModel;
	setSelectedSport: Function;
};

export const LiveBlogSportSelect: FunctionComponent<Properties> = ({ t, liveBlog, setSelectedSport }) => {
	const availableSports = useSelector((state: AppState) => state.sports.sportsTypes);
	const sports = availableSports.filter((sport: SportsTypesModel) => sport.sport === SportTypes.FOOTBALL);
	const defaultValue = !liveBlog.sport || (liveBlog.sport && liveBlog.sport.length < 1) ? sports[0] : liveBlog.sport;

	useEffect(() => {
		if (!liveBlog.sport || (liveBlog.sport && liveBlog.sport.length === 0 && defaultValue)) {
			setSelectedSport(defaultValue);
		}
	}, []);

	const selectedSportAsOption = sportTypeToOption(sports[0]);
	const availableSportsAsOptions = availableSportsToOption(sports);

	const changeSport = (selectedSportOption: ValueType<any>) => setSelectedSport(selectedSportOption.data);

	return (
		<Row className='mb-3'>
			<Col>
				<FormGroup>
					<Label htmlFor={DATA_QA_ATTRIBUTES.LIVE_BLOG_SPORTS}>
						<strong>{t('sport')}</strong>
					</Label>
					<span className='text-danger'>*</span>
					<Select
						id={DATA_QA_ATTRIBUTES.LIVE_BLOG_SPORTS}
						placeholder={t('select')}
						onChange={changeSport}
						value={selectedSportAsOption}
						options={availableSportsAsOptions}
					/>
					<h6 className='mt-1' style={{ color: 'gray' }}>
						{t('live_blog_sport_tip')}
					</h6>
				</FormGroup>
			</Col>
		</Row>
	);
};
