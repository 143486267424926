import React, { FunctionComponent, useEffect } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { FormGroup, Label } from 'reactstrap';
import {
	autoGenerateReduxPropsNamingForContentModels,
	generateReduxPropsUpdateActionByContentType,
} from '../../../../../../services/content-models-service/ContentModelService';
import {
	extractContentModeBasedOnUrl,
	extractMainContentTypeBasedOnUrl,
	isArrayEmpty,
} from '../../../../../../global-helpers/global.helpers';
import { ContentMode } from '../../../../../../constants/content-types';
import { SelectMenuOptionType } from '../../../../../../models/v2/general/select.model';
import { contentAttributesToOptions, extractDefaultValue } from '../../helpers/general-content-attributes.helper';
import ContentAttribute from '../../../../../../models/v2/content-attributes/content-attributes.model';
import { DATA_QA_ATTRIBUTES } from '../../constants/data-qa.attributes';
import { ReduxGeneralContentProps } from '../../constants/redux-general-content.attributes';

type Properties = {
	t: any;
	selectedDistributionChannels: ContentAttribute[];
	distributedChannels: ContentAttribute[];
	updateTempProperty: (propertyObject: Record<string, any>) => void;
};

const DistributedChannelsMultiselect: FunctionComponent<Properties> = ({
	t,
	selectedDistributionChannels,
	distributedChannels,
	updateTempProperty,
}) => {
	useEffect(() => {
		if (extractContentModeBasedOnUrl() === ContentMode.CREATE && selectedDistributionChannels && selectedDistributionChannels.length === 0) {
			const defaultDistributedChannel = extractDefaultValue(distributedChannels);
			defaultDistributedChannel && updateTempProperty({ [ReduxGeneralContentProps.CHANNELS]: [defaultDistributedChannel] });
		}
	}, []);

	const onDistributedRegionsSelect = (distributedRegionsData: Array<SelectMenuOptionType>) => {
		if (distributedRegionsData && distributedRegionsData.length > 0) {
			const distributedChannelsForRedux = distributedRegionsData.map((distributedChannel: SelectMenuOptionType) => distributedChannel.data);
			updateTempProperty({ [ReduxGeneralContentProps.CHANNELS]: distributedChannelsForRedux });
		} else {
			updateTempProperty({ [ReduxGeneralContentProps.CHANNELS]: [] });
		}
	};

	return isArrayEmpty(distributedChannels) ? null : (
		<FormGroup>
			<Label htmlFor={DATA_QA_ATTRIBUTES.DISTRIBUTION_CHANNELS}>{t('distribution_channels')}</Label>
			<Select
				id={DATA_QA_ATTRIBUTES.DISTRIBUTION_CHANNELS}
				value={contentAttributesToOptions(selectedDistributionChannels)}
				options={contentAttributesToOptions(distributedChannels)}
				onChange={onDistributedRegionsSelect}
				isMulti
				placeholder={t('select')}
				noOptionsMessage={(inputValue) => inputValue && t('no_options')}
			/>
		</FormGroup>
	);
};

function mapStateToProps(state: any) {
	const { tempPropertyNaming, nestedTempPropertyNaming } = autoGenerateReduxPropsNamingForContentModels();

	return {
		selectedDistributionChannels: state[tempPropertyNaming][nestedTempPropertyNaming].distributionChannels || [],
		distributedChannels: state.project.currentProject.distributionChannels || [],
	};
}

function mapDispatchToProps(dispatch: Function) {
	const contentTypeFromUrl = extractMainContentTypeBasedOnUrl();
	const functionForDispatch = generateReduxPropsUpdateActionByContentType(contentTypeFromUrl);

	return {
		updateTempProperty: (propertyObject: Record<string, any>) =>
			functionForDispatch && dispatch(functionForDispatch(propertyObject, extractContentModeBasedOnUrl())),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(DistributedChannelsMultiselect);
