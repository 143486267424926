import HighlightBlockModel from './highlight-block.model';
import { HighlightBlockJson } from './highlight-block.json';

export default class HighlightBlockBuilder {
	private json: HighlightBlockJson;

	constructor(highlightBlock?: HighlightBlockModel | HighlightBlockJson) {
		if (highlightBlock && highlightBlock instanceof HighlightBlockModel) {
			this.json = highlightBlock.toJson();
		} else if (highlightBlock) {
			this.json = highlightBlock;
		} else {
			this.json = {} as HighlightBlockJson;
		}
	}

	withTitle(title: string): HighlightBlockBuilder {
		this.json.title = title;

		return this;
	}

	withDescription(description: any): HighlightBlockBuilder {
		this.json.description = description;

		return this;
	}

	build(): HighlightBlockModel {
		return HighlightBlockModel.fromJson(this.json);
	}
}
