import { TypedJSON } from 'typedjson';
import FootballConnectionResponseModel from './response-football-connection.model';

export const modelFootballConnectionToFootballConnectionResponse = (response: any) => {
	const image = (response && response.display_asset && response.display_asset.url) || undefined;
	const modifiedResponse = {
		...response,
		url_image: response.url_image || image,
		url_logo: response.url_logo || image,
		url_thumb: response.url_thumb || image,
	};

	const serializer = new TypedJSON(FootballConnectionResponseModel);
	const result = serializer.parse(modifiedResponse);

	if (result) {
		return result;
	}

	return new FootballConnectionResponseModel();
};
