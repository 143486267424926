import React from 'react';
import { ViewTypes } from '../../../constants/general.constants';
import { connect } from 'react-redux';
import OnnetworkVideoEdit from './onnetwork-video-edit.component';
import OnnetworkVideoView from './onnetwork-video-block-view.component';
import OnnetworkVideoModel from '../../../../../../models/onnetwork-videos/onnetwork-video.model';
import BlockModel from '../../../models/block.model';

type Properties = {
	t: any;
	view: ViewTypes;
	block: BlockModel;
	blocks: BlockModel[];
	contentData: any;
};

type State = {
	isLoading: boolean;
};

class OnnetworkVideoBlock extends React.Component<Properties, State> {
	constructor(props: Properties) {
		super(props);
		this.state = {
			isLoading: false,
		};
	}

	//TODO store onnetwork video id in block preview and request details in 'View' mode
	render() {
		const { t, view, block, contentData, blocks } = this.props;

		if (view === ViewTypes.edit) {
			return (
				<OnnetworkVideoEdit
					t={t}
					block={block}
					blocks={blocks}
					linkedTags={(contentData && contentData.related && contentData.related.tags) || undefined}
				/>
			);
		} else if (view === ViewTypes.normal) {
			return <OnnetworkVideoView t={t} video={block.data.preview ? block.data.preview.video : OnnetworkVideoModel.builder().build()} />;
		}
	}
}

function mapStateToProps(state: any) {
	return {
		currentProject: state.project.currentProject,
		lang: state.profile.profile.language,
	};
}

export default connect(mapStateToProps)(OnnetworkVideoBlock) as React.ComponentType<any>;
