import debounce from 'lodash.debounce';
import * as React from 'react';
import { DebounceInput } from 'react-debounce-input';
import { useTranslation } from 'react-i18next';
import { FormGroup, Label } from 'reactstrap';
import CollaborativeEditor from '../../../Pages/Live Blog/components/subcomponents/collaborative-editor/collaborative-editor';
import { ToolbarAction } from '../../../Pages/Live Blog/components/subcomponents/collaborative-editor/collaborative-editor-toolbar';

type BioProps = {
	onChange: (text: string) => any;
	richText: boolean;
	value?: string;
};

export const Bio: React.FunctionComponent<BioProps> = ({ onChange, richText, value }) => {
	const initialValue = React.useMemo(() => value || '', []);
	const [t] = useTranslation();

	return (
		<FormGroup>
			<Label htmlFor='bio'>{t('bio')}</Label>
			{richText ? (
				<CollaborativeEditor
					editorClassName='bio-input'
					subDocumentId='bio'
					content={initialValue}
					editable
					actions={[
						ToolbarAction.BOLD,
						ToolbarAction.ITALIC,
						ToolbarAction.UNDERLINE,
						ToolbarAction.LINK,
						ToolbarAction.ORDERED_LIST,
						ToolbarAction.UNORDERED_LIST,
						ToolbarAction.TEXT_ALIGN_LEFT,
						ToolbarAction.TEXT_ALIGN_CENTER,
						ToolbarAction.TEXT_ALIGN_RIGHT,
						ToolbarAction.TEXT_ALIGN_JUSTIFY,
						ToolbarAction.SPECIAL_CHARACTERS,
					]}
					onUpdate={debounce((editor) => {
						onChange(editor.getHTML());
					}, 500)}
					enableCode={false}
				/>
			) : (
				<DebounceInput
					element='textarea'
					name='bio-input'
					id='bio-input'
					rows='7'
					className='form-control-lg form-control'
					onChange={(event: any) => onChange(event.target.value)}
					value={value ? value : ''}
					placeholder={t('bio_placeholder')}
				/>
			)}
		</FormGroup>
	);
};
