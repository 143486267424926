import React, { Component } from 'react';
import ReactPaginate from 'react-paginate';
import HttpService from '../../../../../services/rest/HttpService';
import { Button, Col, Input, InputGroup, InputGroupAddon, Row } from 'reactstrap';
import ImageCells from './image-cells.component';
import Image from '../../../../../models/image/Image';
import { imageListFromResponse, createRequestImageUrl } from './helpers/image-listing.helper';
import { PaginationProps } from '../../../Sidebar/Media/subcomponents/media-main-image/subcomponents/image-listing/properties/ImageListPaginateProps';
import { Properties, State } from './properties/image-listing.properties';
import PaginationMeta from '../../../../../models/pagination/PaginationMeta';
import ModelMapper from '../../../../../models/ModelMapper';
import AdvancedContentFilters from '../../../Shared/advanced-content-filter/advanced-content-filter.component';

export default class ImageListingContainer extends Component<Properties, State> {
	constructor(props: Properties) {
		super(props);
		this.state = {
			imageList: [Image.builder().build()],
			currentPage: 1,
			pagination: PaginationMeta.builder().build(),
			isLoading: false,
			contentSearchInput: '',
			advancedContentSearchInput: '',
		};
	}

	componentDidMount() {
		this.requestImages(0, '', '');
	}

	componentDidUpdate(prevProps: Readonly<Properties>, prevState: Readonly<State>, snapshot?: any) {
		if (prevProps.watermarkChanged !== this.props.watermarkChanged) {
			this.requestImages(0, '', '');
		}
	}

	requestImages(page: number, textSearch: string, advancedContent: string) {
		this.setState((state: State) => {
			return { ...state, isLoading: true };
		});
		const url = createRequestImageUrl(textSearch, advancedContent, page);

		HttpService.get(url, 0, { Project: this.props.domain })
			.then((response: any) => {
				const imageList = imageListFromResponse(response.data.data);
				const pagination = ModelMapper.remapMetaPagination(response.data.meta.pagination);
				this.updateImageListAndPaginationState(imageList, pagination, page);
			})
			.catch((e: any) => e);
	}

	updateImageListAndPaginationState = (imageList: Image[], pagination: PaginationMeta, currentPage: number) => {
		this.setState((state: State) => {
			return {
				...state,
				currentPage,
				imageList,
				pagination,
				isLoading: false,
			};
		});
	};

	onContentSearchInput = (e: any) => {
		const searchText = e.target.value;
		const { advancedContentSearchInput } = this.state;

		if (searchText.length < 1) {
			this.setState({ ...this.state, contentSearchInput: searchText }, () => this.requestImages(1, '', advancedContentSearchInput));
		} else {
			this.setState({ ...this.state, contentSearchInput: searchText });
		}
	};

	updateAdvancedInputState = (text: string) => {
		this.setState({ ...this.state, advancedContentSearchInput: text });
	};

	onContentSearch = (e: any) => {
		const { advancedContentSearchInput, contentSearchInput } = this.state;

		if (e.keyCode === 13 && this.state.contentSearchInput.length > 0) {
			this.requestImages(1, contentSearchInput, advancedContentSearchInput);
		}
	};

	onSearch = () => {
		const { advancedContentSearchInput, contentSearchInput } = this.state;
		this.requestImages(1, contentSearchInput, advancedContentSearchInput);
	};

	getAll = () => {
		this.requestImages(1, '*', '');
	};

	render() {
		const { imageList, pagination, contentSearchInput, currentPage, isLoading, advancedContentSearchInput } = this.state;
		const { selectedImageId, t, onImageSelect } = this.props;

		return (
			<>
				<Row className='mb-3'>
					<Col>
						<InputGroup>
							<Input
								type='text'
								id='block-image-search-input'
								name='block-image-search-input'
								placeholder={`${t('search')}`}
								onInput={this.onContentSearchInput}
								bssize='sm'
								onKeyDown={this.onContentSearch}
							/>
							<InputGroupAddon addonType='append'>
								<Button type='button' color='primary' bssize='sm' onClick={this.onSearch}>
									<i className='fa fa-search pr-1' />
									{t('search')}
								</Button>
							</InputGroupAddon>
						</InputGroup>
						<AdvancedContentFilters
							t={t}
							searchContent={this.onSearch}
							updateAdvancedSearchText={this.updateAdvancedInputState}
							filtersInSidebar
							getAllContent={this.getAll}
							contentSearchInput={this.state.contentSearchInput}
							showOriginSelect={true}
							calledFrom='image-listing'
							saveFiltersHistory={false}
						/>
					</Col>
				</Row>
				<Row className={isLoading ? 'loading-overlay' : ' mb-3 text-left'}>
					<Col>
						<ImageCells images={imageList} selectedImageId={selectedImageId ? selectedImageId : ''} onSelect={onImageSelect} />
					</Col>
				</Row>
				<Row className='text-left'>
					<Col>
						<ReactPaginate
							{...PaginationProps(t, currentPage)}
							pageCount={pagination.totalPages}
							forcePage={currentPage ? currentPage - 1 : 0}
							onPageChange={(data) => this.requestImages(data.selected + 1, contentSearchInput, advancedContentSearchInput)}
						/>
					</Col>
				</Row>
			</>
		);
	}
}
