import { call, put, takeEvery } from 'redux-saga/effects';
import { toggleModal } from '../action-creators/UiActionCreator';
import { actionService } from '../../App';
import {
	POLL_LISTING_REQUEST,
	returnObjectForPollsListingReceived,
	returnObjectForPollEntityCreateSuccess,
	returnObjectForPollEntityCreateFailed,
	POLL_ENTITY_CREATE,
	returnObjectForPollEntityReceived,
	POLL_ENTITY_REQUEST,
	returnObjectForPollEntityUpdateSuccess,
	returnObjectForPollEntityUpdateFailed,
	POLL_ENTITY_UPDATE,
	POLL_ENTITY_DELETE,
	returnObjectForPollEntityDeleteSuccess,
	returnObjectForPollEntityDeleteFailed,
	POLL_ENTITY_OPTION_DELETE,
	POLL_ENTITY_OPTION_UPDATE,
	returnObjectForPollEntityOptionDeleteFailed,
	returnObjectForPollEntityOptionUpdateFailed,
	POLL_ENTITY_OPTION_CREATE,
	returnObjectForPollEntityOptionCreateFailed,
	returnObjectForPollEntityOptionCreateSuccess,
	POLL_ENTITY_OPTION_CREATE_SUCCESS,
	returnObjectForPollEntityOptionUpdateSuccess,
} from '../action-creators/polls-action-creator';
import { onError } from '../action-creators/GeneralActions';
import HttpService from '../../services/rest/HttpService';
import ErrorResponseModel from '../../models/error-response/ErrorResponseModel';

function* fetchPolls(action: any) {
	try {
		let headers = { Project: action.payload.project.domain };
		const polls = yield call(HttpService.get, `/polls?page=${action.payload.page}`, null, headers);
		yield put(returnObjectForPollsListingReceived(polls));
	} catch (error) {
		yield put(onError(error));
	}
}

function* fetchPoll(action: any) {
	try {
		let headers = { Project: action.payload.project.domain };
		const poll = yield call(HttpService.get, `polls/${action.payload.id}`, null, headers);
		yield put(returnObjectForPollEntityReceived(poll));
	} catch (error) {
		yield put(onError(error));
	}
}

function* postPoll(action: any) {
	let headers = { Project: action.payload.project.domain };
	let pollResponse: any = {};

	try {
		let pollPayload = action.payload.poll;
		pollResponse = yield call(HttpService.post, '/polls', pollPayload, headers);
		const pollId = pollResponse.data.data.id;
		yield put(returnObjectForPollEntityCreateSuccess(pollId));
	} catch (error) {
		yield put(returnObjectForPollEntityCreateFailed());
		actionService.emitError(error);
		window.errorService.emit(ErrorResponseModel.builder().fromResponse(error).build());
	}
}

function* patchPoll(action: any) {
	let headers = { Project: action.payload.project.domain };

	try {
		let pollId = action.payload.poll.id;
		let pollPayload = action.payload.poll;
		yield call(HttpService.patch, `/polls/${pollId}`, pollPayload, headers);
		yield put(returnObjectForPollEntityUpdateSuccess());
		yield put(toggleModal(false));
	} catch (error) {
		actionService.emitError(error);
		yield put(returnObjectForPollEntityUpdateFailed());
		yield put(onError(error));
	}
}

function* deletePoll(action: any) {
	let headers = { Project: action.payload.project.domain };

	try {
		let id = action.payload.id;
		yield call(HttpService.delete, `/polls/${id}`, null, headers);
		yield put(returnObjectForPollEntityDeleteSuccess());
		yield put(toggleModal(false));
	} catch (error) {
		yield put(returnObjectForPollEntityDeleteFailed());
		yield put(onError(error));
	}
}

function* patchPollOption(action: any) {
	let headers = { Project: action.payload.project.domain };

	try {
		let optionPayload = {
			text: action.payload.option.text,
			image: null,
		};
		let optionId = action.payload.option.id;

		let pollId = action.payload.poll.id;
		let pollPayload = {
			title: action.payload.poll.title,
			text: action.payload.poll.text,
		};

		yield call(HttpService.patch, `/polls/${pollId}/options/${optionId}`, optionPayload, headers);
		yield call(HttpService.patch, `/polls/${pollId}`, pollPayload, headers);
		yield put(returnObjectForPollEntityOptionUpdateSuccess());
	} catch (error) {
		actionService.emitError(error);
		yield put(returnObjectForPollEntityOptionUpdateFailed());
		yield put(onError(error));
	}
}

function* deletePollOption(action: any) {
	let headers = { Project: action.payload.project.domain };

	try {
		let optionPayload = {
			text: action.payload.optionToDelete.text,
			image: null,
		};
		let optionId = action.payload.optionToDelete.id;

		let pollId = action.payload.poll.id;
		let pollPayload = {
			title: action.payload.poll.title,
			text: action.payload.poll.text,
		};

		yield call(HttpService.delete, `/polls/${pollId}/options/${optionId}`, optionPayload, headers);
		yield call(HttpService.patch, `/polls/${pollId}`, pollPayload, headers);
		yield put(toggleModal(false));
	} catch (error) {
		yield put(returnObjectForPollEntityOptionDeleteFailed());
		yield put(onError(error));
	}
}

function* postNewOption(action: any) {
	let headers = { Project: action.payload.project.domain };

	try {
		let optionPayload = {
			text: action.payload.text,
			weight: action.payload.index,
			image: null,
		};

		let pollId = action.payload.poll.id;

		yield call(HttpService.post, `/polls/${pollId}/options`, optionPayload, headers);
		yield put(returnObjectForPollEntityOptionCreateSuccess(pollId, action.payload.project));
	} catch (error) {
		actionService.emitError(error);
		yield put(returnObjectForPollEntityOptionCreateFailed());
		yield put(onError(error));
	}
}

function* pollSaga() {
	yield takeEvery(POLL_LISTING_REQUEST, fetchPolls);
	yield takeEvery(POLL_ENTITY_CREATE, postPoll);
	yield takeEvery(POLL_ENTITY_REQUEST, fetchPoll);
	yield takeEvery(POLL_ENTITY_UPDATE, patchPoll);
	yield takeEvery(POLL_ENTITY_DELETE, deletePoll);
	yield takeEvery(POLL_ENTITY_OPTION_DELETE, deletePollOption);
	yield takeEvery(POLL_ENTITY_OPTION_UPDATE, patchPollOption);
	yield takeEvery(POLL_ENTITY_OPTION_CREATE, postNewOption);
	yield takeEvery(POLL_ENTITY_OPTION_CREATE_SUCCESS, fetchPoll);
}
export default pollSaga;
