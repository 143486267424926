import React from 'react';
import { contentToOption, contentToOptions } from '../../helpers/select.helper';
import Select from 'react-select';
import { Properties, State } from './properties/tournament-select.properties';
import HttpService, { PLAYER_INFO_TYPE } from '../../../../../../services/rest/HttpService';

export default class PlayerTournamentSelect extends React.Component<Properties, State> {
	constructor(props: Properties) {
		super(props);
		this.state = {
			tournamentOptions: [],
		};
	}

	componentDidMount() {
		if (this.props.playerId) {
			this.requestPlayerInformation(this.props.playerId);
		}
	}

	componentDidUpdate(prevProps: Readonly<Properties>, prevState: Readonly<State>) {
		if (this.props.playerId !== prevProps.playerId) {
			this.requestPlayerInformation(this.props.playerId);
		}
	}

	setTournamentOptions = (options: []) => {
		this.setState({
			tournamentOptions: options,
		});
	};

	onTournamentSelect = (selection: any) => {
		this.props.onTournamentSelect(selection.data);
	};

	requestPlayerInformation = (playerId: string) => {
		HttpService.getPlayerMatchesInfo(playerId, PLAYER_INFO_TYPE.TOURNAMENTS, this.props.currentProject.language)
			.then((response: any) => {
				this.setTournamentOptions(contentToOptions(response));
			})
			.catch((e: any) => e);
	};

	render() {
		const { tournamentOptions } = this.state;
		const { selectedTournament } = this.props;

		return <Select options={tournamentOptions} value={[contentToOption(selectedTournament)]} onChange={this.onTournamentSelect} />;
	}
}
