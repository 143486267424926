import React from 'react';

type Props = {
	className: string;
};

const Loader: React.FunctionComponent<Props> = ({ className }) => {
	return <div className={className}></div>;
};

export default Loader;
