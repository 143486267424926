import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Label } from 'reactstrap';
import CompetitionModel from '../../../../../models/v2/competition/entity/competition.model';
import SportsHttpService from '../../../../../services/rest/sports-http-service';
import { competitionsToOptions, competitionToOption, responseToCompetitionModel } from './helpers/competition-select.helper';
import { customOption } from '../shared/custom-select-option';
import { SportTypes } from '../../../../../constants/sport.types';
import { SelectMenuOptionType } from '../../../../../models/v2/general/select.model';
import ErrorHandler from '../../v2/partials/error-handler/error-handler';
import { useTranslation } from 'react-i18next';

type Properties = {
	onCompetitionSelect: (competition: CompetitionModel) => void;
	selectedCompetition: CompetitionModel;
	isValid: boolean;
	isClearable?: boolean;
	isRequired: boolean;
	isBlocky: boolean;
	language: string;
	withLabel?: boolean;
	label?: string;
	sport: string;
	id: string;
};

const CompetitionSelectComponent: React.FunctionComponent<Properties> = (props) => {
	const [competitionOptions, setCompetitionsOptions] = useState<CompetitionModel[]>([]);
	const [t] = useTranslation();

	useEffect(() => {
		getCompetitions(props.sport, props.language);
	}, []);

	const updateCompetitionsOptionsState = (competitions: CompetitionModel[]) => {
		const { onCompetitionSelect, selectedCompetition, isBlocky } = props;
		if (
			!selectedCompetition ||
			(selectedCompetition && (selectedCompetition.id === undefined || selectedCompetition.id.length < 1) && isBlocky)
		) {
			onCompetitionSelect(competitions[0]);
		}
		setCompetitionsOptions(competitions);
	};

	const getCompetitions = (sport: string, lang: string) => {
		SportsHttpService.getCompetitions(sport, lang)
			.then((response: any) => {
				if (sport === SportTypes.TENNIS) {
					return updateCompetitionsOptionsState(response.data.data.map((item: CompetitionModel) => responseToCompetitionModel(item)));
				}
				updateCompetitionsOptionsState(response.data.competitions.map((item: CompetitionModel) => responseToCompetitionModel(item)));
			})
			.catch((e: any) => e);
	};

	return (
		<div data-qa={props.id}>
			<Label htmlFor={props.id}>{t('competition')}</Label>
			{props.isRequired && <span className='text-danger'>*</span>}
			<Select
				id={props.id}
				menuPortalTarget={document.body}
				menuPosition='absolute'
				className='mb-2 w-100'
				placeholder={t('select')}
				formatOptionLabel={customOption}
				options={competitionsToOptions(competitionOptions)}
				value={props.selectedCompetition && props.selectedCompetition.id ? competitionToOption(props.selectedCompetition) : []}
				noOptionsMessage={(inputValue) => inputValue && t('no_options')}
				isClearable={props.isClearable}
				onChange={(selection: SelectMenuOptionType) => {
					if (selection) {
						props.onCompetitionSelect(selection.data);
					} else {
						props.onCompetitionSelect({} as CompetitionModel);
					}
				}}
			/>
			{
				<div className='mt-2'>
					<ErrorHandler
						isValueSelected={!props.isValid && !props.selectedCompetition.id}
						arePropsValid={props.isValid}
						t={t}
						errorMessage='field_is_required'
					/>
				</div>
			}
		</div>
	);
};

export default CompetitionSelectComponent as React.ComponentType<Properties>;
