import React, { FunctionComponent } from 'react';
import FootballConnectionsSelect from './football-connections';
import TournamentSelect from './tournament-select';
import SportsTypesModel from '../../../../../../models/v2/sports-types/sports-types.model';

type Properties = {
	t: any;
	selectedSport: SportsTypesModel;
};

export const FootballConnectionsWrapper: FunctionComponent<Properties> = ({ t, selectedSport }) => {
	return (
		<>
			<FootballConnectionsSelect t={t} selectedSport={selectedSport} />
			<TournamentSelect t={t} selectedSport={selectedSport} />
		</>
	);
};
