import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import './video-play.scss';
import DangerouslySetInner from '../../social-feed-selection-sidebar/subcomponents/dangerously-set-inner.component';

export type Properties = {
	onClose: any;
	open: boolean;
	t: any;
	title: string;
	url: string;
};

class VideoPlayModal extends React.Component<Properties> {
	render() {
		const { open, t, onClose, title, url } = this.props;

		return (
			<Modal isOpen={open}>
				<ModalHeader
					toggle={() => {
						onClose(false);
					}}
				>
					<DangerouslySetInner html={title} />
				</ModalHeader>
				<ModalBody>
					<div className='iframe-container'>
						<div className='iframe-inner-container'>
							<iframe src={url} title={title} className='iframe' width='100%' frameBorder='0' allowFullScreen scrolling='no'></iframe>
						</div>
					</div>
				</ModalBody>
				<ModalFooter>
					<Button
						id='video-cancel-button'
						color='danger'
						className={'text-light'}
						onClick={() => {
							this.props.onClose(false);
						}}
					>
						{t('cancel')}
					</Button>
				</ModalFooter>
			</Modal>
		);
	}
}

export default VideoPlayModal as React.ComponentType<any>;
