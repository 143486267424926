import { toast } from 'react-toastify';
import HttpService from '../../../../../services/rest/HttpService';
import ImagoImageModel from '../../../Blocky/blocks/content/imago-image-block/models/imago-image.model';
import { ContentTypes } from '../../../../../constants/content-types';
import MainMedia from '../../../../../models/mainmedia/MainMedia';
import MainMediaBuilder from '../../../../../models/mainmedia/MainMediaBuilder';
import Watermark from '../../../../../models/watermarks/Watermark';

export const UPLOAD_STEPS = ['processing', 'uploading', 'tagging', 'none'];

export const UPLOAD_STEP_STYLE = {
	processing: 'image-uploading-loader-container',
	uploading: 'image-uploading-loader-container',
	tagging: 'image-uploading-loader-container',
	none: 'hide-image-loader',
};

export const UPLOAD_STEP_TEXT = {
	processing: 'processing_images',
	uploading: 'uploading_images',
	tagging: 'tagging_images_data',
	none: '',
};
export const MAX_DIMENS_ERROR = 'image_dimens_limit_error';
export const GENERAL_ERROR = 'processing_image_failed';
export const NO_RELATED_CONTENT = 'image_upload_related_error';
export const IMAGE_RELATED_CONTENT_ADD_FAILED = '10004';
export const IMAGE_RELATED_CONTENT_MISSING = '10005';
export const FILE_DIMENS_TOO_BIG = '10001';
export const FILE_UPLOAD_TIMEOUT = '10002';
export const FILE_UPLOAD_FAILED_CONTENT_API = '10003';
export const MAX_DIMENS_SIZE = 75000000;
export const PARTIAL_STRING_ERROR_FILE_SIZE_DROPZONE = 'File is too big';

export const handleErrors = (error: string, t: any) => {
	if (error === PARTIAL_STRING_ERROR_FILE_SIZE_DROPZONE) {
		toast.error(t('image_size_limit_error'));
	}

	if (error === FILE_UPLOAD_TIMEOUT) {
		toast.error(t('image_upload_error'));
	}

	if (error === FILE_DIMENS_TOO_BIG) {
		toast.error(t('image_dimens_limit_error'));
	}

	if (error === FILE_UPLOAD_FAILED_CONTENT_API) {
		toast.error(t('image_upload_related_error'));
	}

	if (error === IMAGE_RELATED_CONTENT_ADD_FAILED) {
		toast.error('image_related_content_add_failed');
	}

	if (error === IMAGE_RELATED_CONTENT_MISSING) {
		toast.error(t(NO_RELATED_CONTENT));
	}
};

export const imagoToJson = (image: ImagoImageModel, imagePath: string, projectOriginId: any) => {
	return {
		generic: {
			imago_id: image.id,
			type: image.type,
			width: image.width,
			height: image.height,
			image_url: image.image,
			description: image.description,
			source: image.source,
			dateCreated: image.dateCreated,
		},
		origin_id: projectOriginId,
		path: imagePath,
		urls: {
			uploaded: {
				original: imagePath,
				gallery: `${imagePath}?operations=fit(1920:)`,
				thumbnail: `${imagePath}?operations=autocrop(256:256)`,
				embed: `${imagePath}?operations=fit(770:)`,
			},
		},
	};
};

export const appendUploadingClass = (isLoading: boolean) => {
	return isLoading ? 'fa fa-spinner fa-pulse' : 'fa fa-cloud-upload';
};

export const constructRequestFromFilePath = (image: ImagoImageModel, imagePaths: string[], originId: any, headers: any) => {
	return imagePaths.map((path: string) => HttpService.post('/images', imagoToJson(image, path, originId), headers));
};

export const constructRequestsFootballRelatedImages = (imageIds: string[], related: any, headers: any) => {
	return imageIds.map((image: any) => HttpService.post(`/images/${image.id}/related`, related, headers));
};

export const checkIfStateContainsImagoID = (imagoID: string, stateImagoID: string[]) => {
	return stateImagoID.includes(imagoID);
};

export const buildMainMediaFromImago = (media: any, res: any, image: any) => {
	return MainMedia.builder(media)
		.withResourceId(res[0].data.data.id)
		.withProvider('smp')
		.withDescription(image.source)
		.withResourceType('image')
		.withResourceSubtype('main_image')
		.withGeneric({ imago_id: image.id })
		.build();
};

export const checkIfOnEditScreen = (urlPath: string[]) => urlPath.includes('edit');

export const returnValueFromStore = (contentType: string, value: any, editScreen: boolean) => {
	if (editScreen) {
		let dataGenericID =
			value && value[0] && value[0].data && value[0].data.generic && value[0].data.generic.imago_id ? value[0].data.generic.imago_id : '';
		let mainMediaGenericID = value && value[0] && value[0].generic && value[0].generic.imago_id ? value[0].generic.imago_id : '';
		return [dataGenericID, mainMediaGenericID];
	} else return value && value[0] && value[0].generic && value[0].generic.imago_id ? [value[0].generic.imago_id] : [];
};

export const getImagoIDinMainMedia = (contentType: string, props: any, editScreen: boolean) => {
	switch (contentType) {
		case ContentTypes.ARTICLE:
			if (editScreen) {
				return returnValueFromStore(ContentTypes.ARTICLE, props.mainMediaGenericFieldArticleEdit, editScreen);
			} else {
				return returnValueFromStore(ContentTypes.ARTICLE, props.mainMediaGenericFieldArticle, editScreen);
			}
		case ContentTypes.GALLERY:
			if (editScreen) {
				return returnValueFromStore(ContentTypes.GALLERY, props.mainMediaGenericFieldGalleryEdit, editScreen);
			} else {
				return returnValueFromStore(ContentTypes.GALLERY, props.mainMediaGenericFieldGallery, editScreen);
			}
		case ContentTypes.VIDEO:
			if (editScreen) {
				return returnValueFromStore(ContentTypes.VIDEO, props.mainMediaGenericFieldVideoEdit, editScreen);
			} else {
				return returnValueFromStore(ContentTypes.VIDEO, props.mainMediaGenericFieldVideo, editScreen);
			}
		default:
			return [];
	}
};

export const addImagoImageIDtoState = (stateArr: object[], image: ImagoImageModel, mainMediaObj: MainMedia) => {
	let newImagoIsUploadedArr = stateArr;
	let imagoID = image.id;
	let newArrValue = { [imagoID]: mainMediaObj };
	newImagoIsUploadedArr.push(newArrValue);

	return newImagoIsUploadedArr;
};

export const checkIfArrayContainsSameImagoID = (array: object[], imagoID: string) => {
	let imagoImageIsAlreadyUploaded = false;
	array.forEach((imageObject) => {
		if (Object.keys(imageObject).includes(imagoID)) {
			imagoImageIsAlreadyUploaded = true;
		}
	});
	return imagoImageIsAlreadyUploaded;
};

export const findImageForMainMedia = (stateArrayMap: object[], imagoID: string) => {
	let reuseMainMedia = MainMedia.builder().build();
	stateArrayMap.map((item) => {
		if (Object.keys(item).includes(imagoID)) {
			reuseMainMedia = Object.values(item)[0];
		} else {
			return MainMedia.builder().build();
		}
	});
	return reuseMainMedia;
};

export const checkIfWatermarkIsValid = (defaultWatermark: Watermark) => {
	return !!(defaultWatermark.id && defaultWatermark.opacity && defaultWatermark.position);
};
