import { RelatedJson } from './RelatedJson';
import RelatedBuilder from './RelatedBuilder';

export default class Related {
	readonly provider: string;
	readonly type: string;
	readonly data: any;

	private constructor(provider: string, type: string, data: any) {
		this.provider = provider;
		this.type = type;
		this.data = data;
	}

	toJSON(): RelatedJson {
		return {
			provider: this.provider,
			type: this.type,
			data: this.data,
		};
	}

	static fromJSON(json: RelatedJson): Related {
		return new Related(json.provider, json.type, json.data);
	}

	static builder(urls?: Related): RelatedBuilder {
		return new RelatedBuilder(urls);
	}
}
