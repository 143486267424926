import * as React from 'react';
import { Button, Col, Row, FormGroup, Input, InputGroup, InputGroupAddon } from 'reactstrap';
import ReactPaginate from 'react-paginate';
import Image from '../../../../../../../models/image/Image';
import PaginationMeta from '../../../../../../../models/pagination/PaginationMeta';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { PaginationProps } from '../../../../Media/subcomponents/media-main-image/subcomponents/image-listing/properties/ImageListPaginateProps';
import ErrorAsync from '../../../../../BaseComponents/ErrorComponent/ErrorAsync';
import { ImageCells } from '../../../../Media/subcomponents/media-main-image/subcomponents/image-listing/ImageCells';
import AdvancedContentFilter from '../../../../../Shared/advanced-content-filter/advanced-content-filter.component';

export type Properties = {
	onPageChange: (title?: string, page?: string) => void;
	onImagesOpen: (areOpen: boolean) => void;
	onImageSelect: (image: Image) => void;
	onImageSearch: (title: string, page: string) => void;
	pagination: PaginationMeta;
	images: Image[];
	isContentLoading: boolean;
	contentType?: string;
	selectedImageId?: string | null;
};

export const MainMediaAdditionalImageListing: React.FunctionComponent<Properties> = (props) => {
	const [t] = useTranslation();
	const [contentSearchInput, setContentSearchInput] = useState('');
	const [advancedContentSearchInput, setAdvancedContentSearchInput] = useState('');
	const [currentPage, setCurrentPage] = useState('1');
	const [pageSearchQuery, setPageSearchQuery] = useState('');

	const onContentSearchInput = (event: any) => {
		let searchText = event.target.value;
		setContentSearchInput(event.target.value);

		if (searchText.length < 1 && advancedContentSearchInput.length < 0) {
			props.onImageSearch(searchText, currentPage);
		}
	};

	const onContentSearch = (event: any) => {
		if (event.keyCode === 13 && contentSearchInput.length > 0) {
			onSearchContent();
		}
	};

	const onSearchContent = () => {
		const isContentSearchInputEmpty = contentSearchInput.trim().length === 0;
		const isAdvancedContentSearchInputEmpty = advancedContentSearchInput.trim().length === 0;

		if (isContentSearchInputEmpty && isAdvancedContentSearchInputEmpty) {
			setContentSearchInput('');
			setAdvancedContentSearchInput('');
			setPageSearchQuery('');
			props.onImageSearch('', currentPage);
		}

		const contentSearch = !isContentSearchInputEmpty ? contentSearchInput + advancedContentSearchInput : `*${advancedContentSearchInput}`;
		(!isContentSearchInputEmpty || !isAdvancedContentSearchInputEmpty) && props.onImageSearch(contentSearch, currentPage);
		setPageSearchQuery(contentSearch);
	};

	const onPageChange = (page: any) => {
		const isContentSearchInputEmpty = contentSearchInput.trim().length === 0;
		const isAdvancedContentSearchInputEmpty = advancedContentSearchInput.trim().length === 0;

		if (isContentSearchInputEmpty && isAdvancedContentSearchInputEmpty) {
			setPageSearchQuery('');
			props.onPageChange('', page);
		}

		const contentSearch = !isContentSearchInputEmpty ? contentSearchInput + advancedContentSearchInput : `*${advancedContentSearchInput}`;
		(!isContentSearchInputEmpty || !isAdvancedContentSearchInputEmpty) && props.onPageChange(contentSearch, page);
		setPageSearchQuery(contentSearch);
	};

	const updateAdvancedInputState = (text: string) => setAdvancedContentSearchInput(text);

	const getAllContent = () => {
		props.onImageSearch('', currentPage);
	};

	const { images, pagination, contentType, selectedImageId } = props;

	return (
		<>
			<FormGroup row className='mb-0'>
				<Col md='12'>
					<InputGroup>
						<Input
							type='text'
							id={`search-input-${contentType ? contentType : ''}`}
							name='search-input'
							placeholder={t('content_search_placeholder')}
							onChange={onContentSearchInput}
							onKeyDown={onContentSearch}
							value={contentSearchInput}
						/>
						<InputGroupAddon addonType='append'>
							<Button type='button' color='primary' onClick={onSearchContent}>
								<i className={'fa fa-search'}>&nbsp;</i>
								{t('search')}
							</Button>
						</InputGroupAddon>
					</InputGroup>
				</Col>
			</FormGroup>
			<ErrorAsync t={t} errorType='query' />
			<Row className='mb-2'>
				<Col className='ml-auto'>
					<AdvancedContentFilter
						t={t}
						filtersInSidebar={true}
						updateAdvancedSearchText={updateAdvancedInputState}
						searchContent={onSearchContent}
						getAllContent={getAllContent}
						contentSearchInput={contentSearchInput}
						showOriginSelect={true}
						calledFrom='main-media'
						saveFiltersHistory={false}
						showTypeSelect={false}
					/>
				</Col>
			</Row>
			<div className={`${props.isContentLoading ? 'loading-overlay' : ''}`}>
				{images && images.length > 0 && (
					<ImageCells images={images} selectedImageId={selectedImageId ? selectedImageId : ''} onImageSelect={props.onImageSelect} />
				)}
				{images && images.length === 0 && <h6 className='d-flex justify-content-center mb-2'>{t('no_data_found')}</h6>}

				<ReactPaginate
					{...PaginationProps(t, pagination.currentPage)}
					pageCount={pagination.totalPages ? pagination.totalPages : 0}
					onPageChange={(data: any) => onPageChange(data.selected + 1)}
				/>
			</div>
		</>
	);
};
