import { SeoComponentModel } from '../../../Partials/Sidebar/Seo/models/SeoComponentModel';
import { UrlsComponentModel } from '../../../Partials/Sidebar/Urls/models/UrlsComponentModel';
import Tag from '../../../../models/tag/Tag';
import Seo from '../../../../models/seo/Seo';
import Urls from '../../../../models/urls/Urls';
import Global from '../../../Partials/Blocky/helpers/global.helpers';

export const ORDERED_TAGS_LIMIT = 1000;

export function seoComponentModelToTag(seoModel: SeoComponentModel, tag: Tag): Tag {
	const seo: Seo = Seo.builder(tag.seo)
		.withKeywords(seoModel.keywords)
		.withFollow(seoModel.following)
		.withIndex(seoModel.indexing)
		.withTitle(Global.stripEmoji(seoModel.title))
		.withDescription(seoModel.description)
		.withSlug(Global.stripEmoji(seoModel.slug))
		.withAutoSlug(seoModel.autoSlug)
		.withAutoTitle(seoModel.autoTitle)
		.build();

	return Tag.builder(tag).withSeo(seo).build();
}

export function tagToSeoComponentModel(tag: Tag): SeoComponentModel {
	return {
		keywords: tag.seo && tag.seo.keywords ? tag.seo.keywords : [],
		following: tag.seo ? tag.seo.follow : true,
		indexing: tag.seo ? tag.seo.index : true,
		title: tag.seo && tag.seo.title ? Global.stripEmoji(tag.seo.title) : '',
		description: tag.seo && tag.seo.description ? tag.seo.description : '',
		slug: tag.seo && tag.seo.slug ? Global.stripEmoji(tag.seo.slug) : '',
		contentTitle: tag && tag.title ? Global.stripEmoji(tag.title) : '',
		autoSlug: tag && tag.seo ? tag.seo.autoSlug : false,
		autoTitle: tag && tag.seo ? tag.seo.autoTitle : false,
	};
}

export function tagToUrlsComponentModelForEdit(tag: Tag): UrlsComponentModel {
	if (tag) {
		return {
			externalUrl: tag.urls && tag.urls.externalUrl ? tag.urls.externalUrl : '',
			canonicalUrl: tag.urls && tag.urls.canonicalUrl ? tag.urls.canonicalUrl : '',
			redirectType: tag.seo && tag.seo.redirectType ? tag.seo.redirectType : '',
		};
	}

	return {} as UrlsComponentModel;
}

export function urlsComponentModelToCategory(urlsModel: UrlsComponentModel, tag: Tag): Tag {
	const urls: Urls = Urls.builder(tag.urls).withCanonicalUrl(urlsModel.canonicalUrl).withExternalUrl(urlsModel.externalUrl).build();
	const seo: Seo = Seo.builder(tag.seo).withRedirectType(urlsModel.redirectType).build();

	return Tag.builder(tag).withSeo(seo).withUrls(urls).build();
}

export function checkIfValidForSave(tag: Tag, orderedTags: number) {
	return orderedTags >= ORDERED_TAGS_LIMIT && tag.orderType.id === 'ordered';
}
