import * as React from 'react';
import { Properties } from './properties/AuthorSelectMultiContainerProps';
import Select from 'react-select';
import { customOption } from '../../../../Shared/custom-select-options/custom-select-option';
import { authorsFromOptionSelections, authorsToOptions } from './AuhtorSelectHelper';

const AuthorSelectMultiContainer: React.FunctionComponent<Properties> = (props) => {
	const { selectedAuthors, authors } = props;

	const onAuthorSelect = (selections: any[]) => {
		props.onAuthorSelect(authorsFromOptionSelections(selections));
	};

	return (
		<Select
			inputId='content-author-select'
			value={authorsToOptions(selectedAuthors)}
			options={authorsToOptions(authors)}
			onChange={onAuthorSelect}
			isMulti={true}
			formatOptionLabel={customOption}
			placeholder={props.t('select')}
			noOptionsMessage={(inputValue) => inputValue && props.t('no_options')}
		/>
	);
};

export default AuthorSelectMultiContainer;
