import React, { FunctionComponent, useEffect, useState } from 'react';
import LiveBlogModel from '../../../models/live-blog.model';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import DateTimeSelect from '../../../../../Partials/Sidebar/general-content/subcomponents/date-time/date-time-select';
import ErrorAsync from '../../../../../Partials/BaseComponents/ErrorComponent/ErrorAsync';
import { DATA_QA_ATTRIBUTES } from '../../../../../Partials/Sidebar/general-content/constants/data-qa.attributes';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

type Properties = {
	liveBlog: LiveBlogModel;
	setLiveBlogEndTime: (date: string | null) => void;
};

export const LiveBlogEndTimeSelect: FunctionComponent<Properties> = ({ liveBlog, setLiveBlogEndTime }) => {
	const { t } = useTranslation();
	const startDate = moment(liveBlog.start_time ? liveBlog.start_time : '').toISOString();
	const [isAfter, setIsAfter] = useState(true);

	const onEndTimeSelect = (dates: Date[] | null) => {
		const selectedDate = dates && dates.length >= 0 ? dates[0] : '';
		setLiveBlogEndTime(moment(selectedDate).toISOString());
	};

	useEffect(() => {
		if (liveBlog.end_time && moment(startDate).isAfter(moment(liveBlog.end_time))) {
			setLiveBlogEndTime(null);
		}
	}, [startDate]);


	useEffect(() => {
		if (liveBlog.end_time && moment(startDate).isSame(liveBlog.end_time)) {
			setIsAfter(false)
		}
		return () => {
			setIsAfter(true)
		}
		
	}, [startDate, liveBlog.end_time])

	return (
		<Row className='mb-3'>
			<Col>
				<FormGroup>
					<Label htmlFor={DATA_QA_ATTRIBUTES.LIVE_BLOG_END_TIME}>
						<strong>{t('end_time')}</strong>
					</Label>
					<DateTimeSelect
						t={t}
						date={liveBlog.end_time ? liveBlog.end_time : ''}
						onChange={onEndTimeSelect}
						id={DATA_QA_ATTRIBUTES.LIVE_BLOG_END_TIME}
						minDate={startDate ? startDate : ''}
						isInvalid={!isAfter}
					/>
					{!isAfter && <p className="mt-2 text-danger">{t('end_date_error')}</p>}
					<ErrorAsync t={t} errorType='date_id' />
				</FormGroup>
			</Col>
		</Row>
	);
};
