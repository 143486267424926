import React from 'react';
import { Col, FormGroup, Label, Row, Input } from 'reactstrap';
import PlayerSelect from '../../../../partials/player-select/player-select.component';
import { State } from './properties/player-profile-edit.properties';
import { Properties } from './properties/player-profile-edit.properties';
import PlayerProfile from '../models/player-profile-widget.model';
import OddsWidgetModel from '../../odds/models/odds-widget.model';
import OddsWidgetEdit from '../../odds/components/odds-widget-edit.component';
import { blockWrapperService, blockManagementService } from '../../../../subcomponents/blocky.component';
import PlayerModel from '../../../../partials/player-select/models/player.model';
import TeamModel from '../../../../partials/team-select/models/team.model';
import TournamentModel from '../models/tournament-model/tournament.model';
import SeasonModel from '../models/season/season.model';
import PlayerTeamSelect from '../../../../partials/player-specific-select/team-select/player-team-select.component';
import PlayerTournamentSelect from '../../../../partials/player-specific-select/tournament-select/player-tournament-select.component';
import PlayerSeasonSelect from '../../../../partials/player-specific-select/season-select/player-season-select.component';
import { remapTeamFromResponce } from '../../../../partials/team-select/helpers/team-select.helper';
import BlockValidation from '../../../../helpers/block-validation.helper';
import HttpService from '../../../../../../../services/rest/HttpService';
import { assetsPlaceholder } from '../../../../../../../constants/assetsPlaceholder';
import { FeatureTypes } from '../../../../../../../services/feature-service/features.enum';
import { featuresService } from '../../../../../../../App';
import ThreeLetterCodeTypeSelect from '../../../../partials/three-letter-code-type-select/three-letter-code-type-select';

export default class PlayerProfileEdit extends React.Component<Properties, State> {
	constructor(props: any) {
		super(props);
		this.state = {
			playerProfile:
				props.block && props.block.data && props.block.data.preview && props.block.data.preview.playerProfile
					? PlayerProfile.builder(props.block.data.preview.playerProfile).build()
					: PlayerProfile.builder().withDisplayStatistics(true).withDisplayOdds(false).withThreeLetterCodeType('').build(),
			oddsModel:
				props.block && props.block.data && props.block.data.preview && props.block.data.preview.odds
					? OddsWidgetModel.builder(props.block.data.preview.odds).build()
					: OddsWidgetModel.builder().withBookmakers([]).withCanSelectMarkets(false).withDisplayHeader(false).build(),
			isValid: true,
		};
	}

	componentDidMount(): void {
		blockWrapperService.registerOnSaveFunc({ blockId: this.props.block.id, func: this.onSave });
	}

	onSave = (index: number) => {
		const data = { playerProfile: this.state.playerProfile, oddsModel: this.state.oddsModel };
		const validation = new BlockValidation();
		const isValid = validation.validate(this.props.block, data);
		this.setState({ ...this.state, isValid });
		const bookmakerSelectionEnabled = featuresService.areFeaturesEnabled([FeatureTypes.ALLOW_BOOKMAKER_SELECTION]);
		const odds = OddsWidgetModel.builder(this.state.oddsModel)
			.withBookmakers(bookmakerSelectionEnabled ? this.state.oddsModel.bookmakers : [])
			.build();

		if (isValid) {
			const block = Object.assign({}, this.props.block);
			block.data.preview = { playerProfile: this.state.playerProfile, odds };

			blockManagementService.blockUpdate(block, index);
		} else {
			return isValid;
		}
	};

	componentWillUnmount(): void {
		blockWrapperService.unregisterOnSaveFunc(this.props.block.id);
	}

	onPlayerChange = (player: PlayerModel) => {
		this.setDefaultTeam(player);
		this.setState({
			...this.state,
			playerProfile: PlayerProfile.builder()
				.withDisplayStatistics(true)
				.withDisplayOdds(false)
				.withThreeLetterCodeType(this.state.playerProfile.threeLetterCodeType)
				.build(),
		});
	};

	onDisplayStatisticsChange = (event: any) => {
		this.setState({
			...this.state,
			playerProfile: PlayerProfile.builder(this.state.playerProfile).withDisplayStatistics(event.target.checked).build(),
		});
	};

	onFilterStatisticsChange = (e: any) => {
		const { playerProfile } = this.state;

		if (e.target.value === 'team') {
			this.setState({
				...this.state,
				playerProfile: PlayerProfile.builder(playerProfile)
					.withTournament(TournamentModel.builder().build())
					.withStatisticType(e.target.value)
					.build(),
			});
		}

		if (e.target.value === 'tournament') {
			this.setState({
				...this.state,
				playerProfile: PlayerProfile.builder(playerProfile).withTeam(TeamModel.builder().build()).withStatisticType(e.target.value).build(),
			});
		}

		if (e.target.value === 'season') {
			const tournament = TournamentModel.builder(this.state.playerProfile.tournament).withSeason(SeasonModel.builder().build()).build();
			this.setState({
				...this.state,
				playerProfile: PlayerProfile.builder(playerProfile).withTournament(tournament).withStatisticType(e.target.value).build(),
			});
		}
	};

	onTournamentSelect(tournament: TournamentModel) {
		this.setState({
			...this.state,
			playerProfile: PlayerProfile.builder(this.state.playerProfile)
				.withTournament(TournamentModel.builder(tournament).withSeason(SeasonModel.builder().build()).build())
				.withTeam(TeamModel.builder().build())
				.build(),
		});
	}

	onSeasonSelect(season: any) {
		const tournament = TournamentModel.builder()
			.withName(season.tournament)
			.withId('')
			.withSeason(SeasonModel.builder(season).withStage({}).build())
			.build();
		const team = TeamModel.builder().build();

		this.setState({
			...this.state,
			playerProfile: PlayerProfile.builder(this.state.playerProfile).withTournament(tournament).withTeam(team).build(),
		});
	}

	onDisplayOddsChange = (event: any) => {
		this.setState({
			...this.state,
			playerProfile: PlayerProfile.builder(this.state.playerProfile).withDisplayOdds(event.target.checked).build(),
		});
	};

	onTeamChange = (team: TeamModel) => {
		this.setState({
			...this.state,
			playerProfile: PlayerProfile.builder(this.state.playerProfile)
				.withTeam(remapTeamFromResponce(team))
				.withTeamForOdds(remapTeamFromResponce(team))
				.withTournament(TournamentModel.builder().build())
				.build(),
		});
	};

	/**
	 * By default sets the team of the selected player
	 * @param player
	 */
	setDefaultTeam = (player: PlayerModel) => {
		HttpService.getPlayerById(player.id).then((response: any) => {
			let team = response.data.club && response.data.club.team ? response.data.club.team : {};
			let teamModel = TeamModel.builder()
				.withId(team.id ? team.id : '')
				.withName(team.name ? team.name : '')
				.withLogo(team.url_logo ? team.url_logo : assetsPlaceholder.team)
				.withType(team.type)
				.build();
			this.setState({
				...this.state,
				playerProfile: PlayerProfile.builder(this.state.playerProfile).withPlayer(player).withTeamForOdds(teamModel).build(),
			});
		});
	};

	onOddsWidgetChange = (oddsModel: OddsWidgetModel) => this.setState({ ...this.state, oddsModel });

	onThreeLetterCodeTypeChange = (value: any) => {
		this.setState({
			...this.state,
			playerProfile: PlayerProfile.builder(this.state.playerProfile).withThreeLetterCodeType(value).build(),
			isValid: true,
		});
	};

	render() {
		const { playerProfile } = this.state;
		const { t } = this.props;

		return (
			<>
				<Row>
					<Col>
						<FormGroup>
							<PlayerSelect
								inputId='player-profile-player-select'
								t={t}
								value={playerProfile.player ? playerProfile.player : {}}
								onSelect={(player: PlayerModel) => this.onPlayerChange(player)}
								isValid={this.state.isValid}
							/>
						</FormGroup>
					</Col>
				</Row>
				{playerProfile.player && playerProfile.displayStatistics && (
					<Row className={'mb-2'}>
						<Col>
							<FormGroup key={`filter-statistics-team`} check inline>
								<Label check>
									<Input
										type='radio'
										id={`filter-statistics-team-${this.props.block.id}`}
										name='filter-statistics-label-team'
										value='team'
										checked={playerProfile.statisticType === 'team'}
										onChange={this.onFilterStatisticsChange}
									/>
									{t('filter_statistics_by_team')}
								</Label>
							</FormGroup>
							<FormGroup key={`filter-statistics-tournament`} check inline>
								<Label check>
									<Input
										type='radio'
										id={`filter-statistics-tournament-${this.props.block.id}`}
										name='filter-statistics-label-tournament'
										value='tournament'
										checked={playerProfile.statisticType === 'tournament'}
										onChange={this.onFilterStatisticsChange}
									/>
									{t('filter_statistics_by_tournament')}
								</Label>
							</FormGroup>
							<FormGroup key={`filter-statistics-season`} check inline>
								<Label check>
									<Input
										type='radio'
										id={`filter-statistics-season-${this.props.block.id}`}
										name='filter-statistics-label-season'
										value='season'
										checked={playerProfile.statisticType === 'season'}
										onChange={this.onFilterStatisticsChange}
									/>
									{t('filter_statistics_by_season')}
								</Label>
							</FormGroup>
						</Col>
					</Row>
				)}
				{playerProfile.statisticType === 'team' && playerProfile.displayStatistics && (
					<Row>
						<Col>
							<FormGroup>
								<Label>{t('team')}</Label>
								<PlayerTeamSelect
									playerId={playerProfile.player.id}
									selectedTeam={playerProfile.team}
									onTeamSelect={(team: any) => {
										this.onTeamChange(team);
									}}
									currentProject={this.props.currentProject}
								/>
							</FormGroup>
						</Col>
					</Row>
				)}
				{playerProfile.statisticType === 'tournament' && playerProfile.displayStatistics && (
					<>
						<Row>
							<Col>
								<FormGroup>
									<Label>{t('tournament')}</Label>
									<PlayerTournamentSelect
										playerId={playerProfile.player.id}
										selectedTournament={playerProfile.tournament}
										onTournamentSelect={(tournament: any) => {
											this.onTournamentSelect(tournament);
										}}
										currentProject={this.props.currentProject}
									/>
								</FormGroup>
							</Col>
						</Row>
					</>
				)}
				{playerProfile.statisticType === 'season' && playerProfile.displayStatistics && (
					<>
						<Row>
							<Col>
								<FormGroup>
									<Label>{t('season')}</Label>
									<PlayerSeasonSelect
										playerId={playerProfile.player.id}
										value={playerProfile.tournament.season}
										selectedTournamentName={playerProfile.tournament.name}
										onSeasonSelect={(season: SeasonModel) => {
											this.onSeasonSelect(season);
										}}
										currentProject={this.props.currentProject}
									/>
								</FormGroup>
							</Col>
						</Row>
					</>
				)}
				<ThreeLetterCodeTypeSelect
					t={this.props.t}
					isClearable={true}
					onThreeLetterCodeTypeChange={this.onThreeLetterCodeTypeChange}
					selectedThreeLetterCodeType={playerProfile.threeLetterCodeType}
				/>
				<Row className={'mb-2'}>
					<Col>
						<FormGroup key={`display-header`} check className='radio'>
							<Input
								key={`display-header-input`}
								className='form-check-input'
								type='checkbox'
								id={`display-header-checkbox-${this.props.block.id}`}
								name='radios'
								onChange={this.onDisplayStatisticsChange}
								checked={playerProfile.displayStatistics}
							/>
							<Label key={`display-header-label`} check className='form-check-label' for={`display-header-checkbox-${this.props.block.id}`}>
								{t('display_statistics')}
							</Label>
						</FormGroup>
					</Col>
				</Row>

				<Row className={'mb-2'}>
					<Col>
						<FormGroup key={`display-odds-markets`} check className='radio'>
							<Input
								key={`display-odds-markets-input`}
								className='form-check-input'
								type='checkbox'
								id={`display-odds-markets-checkbox-${this.props.block.id}`}
								name='radios'
								onChange={this.onDisplayOddsChange}
								checked={playerProfile.displayOdds}
							/>
							<Label
								key={`display-odds-markets-label`}
								check
								className='form-check-label'
								for={`display-odds-markets-checkbox-${this.props.block.id}`}
							>
								{t('display_odds')}
							</Label>
						</FormGroup>
					</Col>
				</Row>
				{playerProfile.displayOdds && (
					<OddsWidgetEdit
						block={this.props.block}
						isStandAlone={false}
						useTeamSelect
						useMatchSelect={true}
						team={playerProfile.teamForOdds}
						t={this.props.t}
						onOddsWidgetChange={this.onOddsWidgetChange}
						autoSelectFirstMatch={true}
						displayOddsLabel={true}
						isValid={this.state.isValid}
					/>
				)}
			</>
		);
	}
}
