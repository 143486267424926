import { jsonArrayMember, jsonMember, jsonObject } from 'typedjson';
import SportOddsBookmakerModel from '../../../../../../../models/sport-odds/sport-odds-bookmaker.model';
import MatchModel from '../../../../../../../models/v2/match/entity/match.model';

@jsonObject
export default class StakesModel {
	@jsonMember(MatchModel)
	public match: MatchModel = new MatchModel();

	@jsonArrayMember(SportOddsBookmakerModel)
	public bookmakers: SportOddsBookmakerModel[] = [];

	@jsonMember(Number)
	public amount: number = 10;

	@jsonMember(Boolean)
	public fixedAmount: boolean = true;
}
