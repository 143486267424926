import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Col, Label, Row } from 'reactstrap';
import SportsHttpService from '../../../../../services/rest/sports-http-service';
import { customOption } from '../shared/custom-select-option';
import ErrorHandler from '../error/error-handler-component';
import BasketballSeasonModel from '../../../../../models/v2/basketball-season/entity/basketball-season.model';
import BasketballStandingsModel from '../../blocks/widgets/basketball-standings/models/basketball-standings.model';
import { responseToBasketballSeasonModel, seasonsToOptions, seasonToOption } from './helpers/basketball-season-select.helper';
import { useTranslation } from 'react-i18next';
import { SelectMenuOptionType } from '../../../../../models/v2/general/select.model';
import BasketballTeamProgrammeWidgetModel from '../../v2/widgets-v2/basketball-team-programme/models/basketball-team-programme.model';
import BasketballTournamentProgrammeWidgetModel from '../../v2/widgets-v2/basketball-tournament-programme/models/basketball-tournament-programme.model';

type Properties = {
	onSeasonSelect: (season: BasketballSeasonModel) => void;
	blockData: BasketballStandingsModel | BasketballTeamProgrammeWidgetModel | BasketballTournamentProgrammeWidgetModel;
	selectedSeason: BasketballSeasonModel;
	isValid: boolean;
	isClearable?: boolean;
	isRequired?: boolean;
	isBlocky: boolean;
	language: string;
	withLabel?: boolean;
	label?: string;
	sport: string;
	id: string;
	preselectSeason?: boolean;
	isMulti?: string;
	teamId?: string;
};

const BasketballSeasonSelectComponent: React.FunctionComponent<Properties> = (props) => {
	const [seasonsOptions, setSeasonsOptions] = useState<BasketballSeasonModel[]>([]);
	const [t] = useTranslation();

	useEffect(() => {
		if (props.blockData.competition && props.blockData.competition.id) {
			getSeasons(props.sport, props.blockData.competition.id, props.language);
		}
	}, []);

	useEffect(() => {
		if (props.blockData.competition && props.blockData.competition.id) {
			getSeasons(props.sport, props.blockData.competition.id, props.language);
		}

		if (!props.blockData.competition) {
			setSeasonsOptions([]);
		}
	}, [props.blockData.competition && props.blockData.competition.id]);

	useEffect(() => {
		if (props.teamId) {
			getSeasons(props.sport, '', props.language, props.teamId);
		}

		if (!props.teamId || (props.teamId && props.teamId === '')) {
			setSeasonsOptions([]);
		}
	}, [props.teamId]);

	const updateSeasonsOptionsState = (seasons: BasketballSeasonModel[]) => {
		const { preselectSeason, selectedSeason, isMulti, isBlocky, onSeasonSelect } = props;
		let preSelectFirstSeasonOption =
			preselectSeason &&
			(!selectedSeason || (selectedSeason && (selectedSeason.id === undefined || selectedSeason.id === '') && !isMulti)) &&
			isBlocky;

		if (preSelectFirstSeasonOption) {
			onSeasonSelect(seasons[0]);
		}

		setSeasonsOptions(seasons);
	};

	const getSeasons = (sport: string, competitionId: string, lang: string, teamId?: string) => {
		SportsHttpService.getSeasons(sport, competitionId, lang, teamId)
			.then((response: any) => {
				updateSeasonsOptionsState(response.data.seasons.map((item: BasketballSeasonModel) => responseToBasketballSeasonModel(item)));
			})
			.catch((e: any) => e);
	};

	return (
		<Row data-qa={props.id}>
			<Col>
				<Label htmlFor={props.id}>{t('season')}</Label>
				{props.isRequired && <span className='text-danger'>*</span>}
				<Select
					id={props.id}
					menuPortalTarget={document.body}
					menuPosition='absolute'
					className='w-100'
					placeholder={t('select')}
					formatOptionLabel={customOption}
					options={seasonsToOptions(seasonsOptions)}
					value={props.selectedSeason && props.selectedSeason.id ? seasonToOption(props.selectedSeason) : []}
					noOptionsMessage={(inputValue) => inputValue && t('no_options')}
					isClearable={props.isClearable}
					onChange={(selection: SelectMenuOptionType) => {
						if (selection) {
							props.onSeasonSelect(selection.data);
						} else {
							props.onSeasonSelect({} as BasketballSeasonModel);
						}
					}}
				/>
				{!props.isValid && !props.selectedSeason && <ErrorHandler t={t} errorMessage='field_is_required' />}
			</Col>
		</Row>
	);
};

export default BasketballSeasonSelectComponent as React.ComponentType<Properties>;
