import React, { FunctionComponent } from 'react';
import TagsInput from 'react-tagsinput';
import { ReduxURLsProps } from '../../constants/redux-seo.attributes';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import { DATA_QA_ATTRIBUTES } from '../../constants/data-qa.attributes';
import { generateKeywordsProps } from '../../helpers/keywords.helper';
import { connect } from 'react-redux';
import { autoGenerateReduxPropsNamingForContentModels } from '../../../../../../services/content-models-service/ContentModelService';

type Properties = {
	t: any;
	seoKeywords: string[];
	onSeoChange: (data: string[], propertyName: ReduxURLsProps) => void;
};

const SeoKeywordsInput: FunctionComponent<Properties> = ({ t, seoKeywords, onSeoChange }) => {
	const onSeoKeywordsChange = (keywords: string[]) => {
		onSeoChange(keywords, ReduxURLsProps.KEYWORDS);
	};

	return (
		<Row>
			<Col>
				<FormGroup>
					<Label htmlFor={DATA_QA_ATTRIBUTES.KEYWORDS}>{t('seo_keywords')}</Label>
					<TagsInput inputProps={generateKeywordsProps(t)} value={seoKeywords} onChange={onSeoKeywordsChange} />
				</FormGroup>
			</Col>
		</Row>
	);
};

function mapStateToProps(state: any) {
	const { tempPropertyNaming, nestedTempPropertyNaming } = autoGenerateReduxPropsNamingForContentModels();

	return {
		seoKeywords:
			(state[tempPropertyNaming][nestedTempPropertyNaming].seo && state[tempPropertyNaming][nestedTempPropertyNaming].seo.keywords) || [],
	};
}

export default connect(mapStateToProps)(SeoKeywordsInput);
