import { relatedConstants } from '../../../../../constants/related.constants';
import Related from '../../../../../models/related/Related';
import FootballConnectionResponseModel from '../../../../../models/v2/football-connection/response-football-connection.model';
import {
	entityTypesWithoutSeason,
	remapReduxSportsConnectionPropertyToFootballConnectionResponseModel,
} from './football-connection-select.helper';
import { filterReduxRelatedProperties } from './utils';
import SportsTypesModel from '../../../../../models/v2/sports-types/sports-types.model';
import { SportTypes } from '../../../../../constants/sport.types';

export const isSuggestedEntityAlreadyExist = (
	suggestedEntity: FootballConnectionResponseModel,
	entityType: string,
	reduxEntities: FootballConnectionResponseModel[],
) => {
	return reduxEntities.findIndex((el) => el.entity_type === entityType && el.id === suggestedEntity.id) >= 0;
};

export const isEntityTypeValidForSuggestedEntities = (entityType: string) => {
	return (
		entityType === relatedConstants.types.coach ||
		entityType === relatedConstants.types.team ||
		entityType === relatedConstants.types.tournament ||
		entityType === relatedConstants.types.competition ||
		entityType === relatedConstants.types.player
	);
};

export const isSuggestedEntityAlreadyExistAsFootballConnection = (
	suggestedEntity: FootballConnectionResponseModel,
	entityType: string,
	reduxSportConnections: Related[],
	selectedSport: SportsTypesModel,
) => {
	const filteredDataRedux = filterReduxRelatedProperties(selectedSport, reduxSportConnections, entityTypesWithoutSeason);
	const filteredFootballData =
		remapReduxSportsConnectionPropertyToFootballConnectionResponseModel(filteredDataRedux && filteredDataRedux.filtered) || [];

	return isSuggestedEntityAlreadyExist(suggestedEntity, entityType, filteredFootballData);
};

export const generateFootballConnectionWithEntityType = (
	id: string,
	entityType: string,
	entity: FootballConnectionResponseModel,
): FootballConnectionResponseModel => {
	const initialParams = { hide_from_suggested: false, origin_ids: [id] };

	return {
		...entity,
		...initialParams,
		entity_type: entityType,
	};
};

export const filterSuggestedEntitiesForRender = (
	reduxSuggestedEntities: FootballConnectionResponseModel[],
	reduxSportConnections: Related[],
	selectedSport: SportsTypesModel,
) => {
	// this is done because the new autotagging returns competition instead of tournament
	const normalizedSuggestedEntities = reduxSuggestedEntities.map((el) => {
		return selectedSport.sport === SportTypes.FOOTBALL && el.entity_type === relatedConstants.types.competition
			? { ...el, entity_type: relatedConstants.types.tournament }
			: el;
	});

	return normalizedSuggestedEntities.filter(
		(el) =>
			el.hide_from_suggested !== true &&
			!isSuggestedEntityAlreadyExistAsFootballConnection(el, el.entity_type || '', reduxSportConnections, selectedSport),
	);
};
