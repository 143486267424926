import { Button } from 'reactstrap';
import React, { useEffect, useState } from 'react';
import Scrollbar from 'react-custom-scrollbars';
import AmgItemDataModel from '../models/amg-item-data.model';
import AmgVideoModel from '../models/amg-video.model';
import ErrorHandler from '../../../../partials/error/error-handler-component';
import { amgVideosService } from '../../../../../../../App';
import { AmgVideoListing } from './amg-video-listing.component';
import BlockModel from '../../../../models/block.model';
import { AMG_VIDEO_SECTION_CODE } from '../services/amg-videos.service';

type Properties = {
	t: any;
	onSelect: (video: AmgItemDataModel) => void;
	updatePreviewState?: (page: number) => void;
	selectedVideo: AmgItemDataModel;
	isMediaSidebar: boolean;
	isValid?: boolean;
	inBlocky: boolean;
	page: number;
	blocks?: BlockModel[];
};

export const AmgVideoContainer: React.FunctionComponent<Properties> = ({
	t,
	updatePreviewState,
	onSelect,
	isMediaSidebar,
	isValid,
	page,
	inBlocky,
	selectedVideo,
}) => {
	const [videos, setVideos] = useState<AmgVideoModel[]>([]);
	const [toggleLoading, setToggleLoading] = useState<boolean>(false);
	const [currentPage, setCurrentPage] = useState<number>(page);
	const [shouldRequest, setShouldRequest] = useState<boolean>(false);

	useEffect(() => {
		if (currentPage) {
			getVideos(currentPage);
		}
	}, []);

	useEffect(() => {
		if (shouldRequest === true) {
			getVideos(currentPage);
		}
	}, [shouldRequest]);

	const getVideos = (page: any) => {
		setToggleLoading(true);
		amgVideosService
			.getAmgVideos(AMG_VIDEO_SECTION_CODE, page, '20')
			.then((videos) => {
				setVideos(videos);
				setToggleLoading(false);
				setShouldRequest(false);
				updatePreviewState && updatePreviewState(page);
			})
			.catch((e) => {
				setToggleLoading(false);
				setShouldRequest(false);
				console.log(e);
			});
	};

	const onPageNext = () => {
		setCurrentPage(currentPage + 1);
		setShouldRequest(true);
	};

	const onPagePrevious = () => {
		setCurrentPage(currentPage - 1);
		setShouldRequest(true);
	};

	return (
		<div className={`${toggleLoading ? 'loading-overlay rounded opacity-06' : ''} py-2`} data-qa='amg-videos-container'>
			{videos && videos[0] && videos[0].itemData && videos[0].itemData.length > 0 ? (
				<div style={{ height: `${isMediaSidebar ? 400 : ''}px` }} className='pb-2'>
					{isMediaSidebar ? (
						<Scrollbar className='bg-light rounded'>
							<AmgVideoListing t={t} onSelect={onSelect} isMediaSidebar={isMediaSidebar} videos={videos} selectedVideo={selectedVideo} />
						</Scrollbar>
					) : (
						<div className='bg-white rounded'>
							<AmgVideoListing t={t} onSelect={onSelect} isMediaSidebar={isMediaSidebar} videos={videos} selectedVideo={selectedVideo} />
						</div>
					)}
				</div>
			) : (
				<div className='text-center'>{t('no_data')}</div>
			)}
			{videos && (
				<div className='m-2 d-flex flex-row justify-content-end'>
					<Button
						id='amg-video-prev-page'
						size='sm'
						outline
						className='mr-2 d-flex align-items-center'
						color='primary'
						disabled={currentPage < 2}
						onClick={onPagePrevious}
					>
						<i className='fa fa-2x fa-angle-left px-1' />
					</Button>
					<Button
						id='amg-video-next-page'
						size='sm'
						className='d-flex align-items-center'
						outline
						color='primary'
						disabled={videos[0] && videos[0].itemData.length < 10}
						onClick={onPageNext}
					>
						<i className='fa fa-2x fa-angle-right px-1' />
					</Button>
				</div>
			)}
			{inBlocky && (
				<div className='p-1'>
					{!isValid && selectedVideo && selectedVideo.id === undefined && <ErrorHandler t={t} errorMessage='no_selected_video' />}
				</div>
			)}
		</div>
	);
};
