import { VenueJson } from './venue.json';
import VenueModel from './venue.model';

export default class VenueBuilder {
	readonly json: VenueJson;

	constructor(venue?: VenueModel | VenueJson) {
		if (venue && venue instanceof VenueModel) {
			this.json = venue.toJson();
		} else if (venue) {
			this.json = venue;
		} else {
			this.json = {} as VenueJson;
		}
	}

	withId(id: string): VenueBuilder {
		this.json.id = id;

		return this;
	}

	withName(name: string): VenueBuilder {
		this.json.name = name;

		return this;
	}

	build(): VenueModel {
		return VenueModel.fromJson(this.json);
	}
}
