import React from 'react';
import BlockModel from '../../../../models/block.model';
import { ViewTypes } from '../../../../constants/general.constants';
import BlockUpdateController from '../../../block-update-controller.component';
import InstatPlayerPerformanceSeasonBlockEdit from './instat-team-performance-season-block-edit.component';
import Row from 'reactstrap/lib/Row';

type Properties = {
	block: BlockModel;
	view: ViewTypes;
	t: any;
};

const InstatTeamPerformanceSeasonBlock: React.FunctionComponent<Properties> = (props) => {
	const { view, t, block } = props;

	if (view === ViewTypes.edit) {
		return <InstatPlayerPerformanceSeasonBlockEdit block={block} t={t} />;
	} else if (view === ViewTypes.normal) {
		const tournamentName =
			block.data.preview && block.data.preview.selectedTournamentTeam ? block.data.preview.selectedTournamentTeam.name : '';
		const seasonName = block.data.preview && block.data.preview.selectedSeasonTeam ? block.data.preview.selectedSeasonTeam.name : '';

		return (
			<div className='px-4'>
				<Row>
					<h5>
						<strong className='mr-1'>{t('stats_for_team')}:</strong>
						{block.data.team && block.data.team.info ? block.data.team.info.name : ''}
					</h5>
				</Row>
				<Row>
					<h5>
						<strong className='mr-1'>{t('season')}:</strong>
						{tournamentName} - {seasonName}
					</h5>
				</Row>
			</div>
		);
	}

	return null;
};

export default BlockUpdateController(InstatTeamPerformanceSeasonBlock);
