import React, { FunctionComponent, useState } from 'react';
import { Button, Col, FormGroup, Label, Row, Tooltip } from 'reactstrap';
import Related from '../../../../../../models/related/Related';
import { connect } from 'react-redux';
import { extractActionForUpdatingSportsConnections, extractRelatedPropertiesNameByUrl } from '../../helpers/utils';
import { footballConnectionResponseToRelatedModel } from '../../helpers/football-connection-select.helper';
import FootballConnectionResponseModel from '../../../../../../models/v2/football-connection/response-football-connection.model';
import { extractParticipantIdsFromRedux } from '../../helpers/events.helper';
import SuggestedEntityDataFetcher from './suggested-entity.fetch-component';
import { hideSuggestedEntity } from '../../../../../../store/action-creators/suggested-entities';
import { filterSuggestedEntitiesForRender } from '../../helpers/suggested-entities.helper';
import SportsTypesModel from '../../../../../../models/v2/sports-types/sports-types.model';

type Properties = {
	t: any;
	reduxSuggestedEntities: FootballConnectionResponseModel[];
	reduxSportConnections: Related[];
	updateSportsConnection: Function;
	hideSuggestedEntity: Function;
	selectedSport: SportsTypesModel;
};

const SuggestedEntities: FunctionComponent<Properties> = ({
	t,
	reduxSuggestedEntities,
	reduxSportConnections,
	updateSportsConnection,
	hideSuggestedEntity,
	selectedSport,
}) => {
	const participantsIds = extractParticipantIdsFromRedux(selectedSport, reduxSportConnections);
	const [tooltipOpen, setTooltipOpen] = useState(false);
	const filteredSuggestedEntities = filterSuggestedEntitiesForRender(reduxSuggestedEntities, reduxSportConnections, selectedSport);

	const addSuggestedEntity = (suggestedEntity: FootballConnectionResponseModel) => {
		const suggestedEntityAsRelated = footballConnectionResponseToRelatedModel(suggestedEntity, selectedSport);
		const newFootballConnectionsReduxData = reduxSportConnections.concat(suggestedEntityAsRelated);
		updateSportsConnection(newFootballConnectionsReduxData);
	};

	const addAllSuggestedEntities = () => {
		const suggestedEntitiesAsRelated = filteredSuggestedEntities.map((suggestedEntity) =>
			footballConnectionResponseToRelatedModel(suggestedEntity, selectedSport),
		);
		const newFootballConnectionsReduxData = [...reduxSportConnections, ...suggestedEntitiesAsRelated];
		updateSportsConnection(newFootballConnectionsReduxData);
	};

	return (
		<FormGroup>
			{participantsIds.map((participantId) => (
				<SuggestedEntityDataFetcher footballTeamId={participantId} selectedSport={selectedSport} />
			))}
			<Label>
				{t('recognized_entities_title')}
				<i style={{ fontSize: '17px' }} id='suggested-entities-tooltip-sidebar' className='fa fa-question-circle-o'></i>
				<Tooltip placement='top' isOpen={tooltipOpen} target='suggested-entities-tooltip-sidebar' toggle={() => setTooltipOpen(!tooltipOpen)}>
					{t('recognized_entities_info')}
				</Tooltip>
			</Label>
			<div>
				<Row>
					{filteredSuggestedEntities.map((suggestedEntity) => {
						return (
							<div
								key={`linked-entity-tag-key-${suggestedEntity.id}`}
								style={{ marginBottom: '5px' }}
								className={`linked-entity-pill mr-1 ${suggestedEntity.entity_type}-border`}
							>
								<div className='linked-entity-pill-name mr-1' onClick={() => addSuggestedEntity(suggestedEntity)}>
									{suggestedEntity.name}
								</div>
								<div className='linked-entity-pill-remove' onClick={() => hideSuggestedEntity(suggestedEntity)}>
									<i className='fa fa-times' />
								</div>
							</div>
						);
					})}
				</Row>
				{filteredSuggestedEntities && filteredSuggestedEntities.length > 0 && (
					<Row>
						<Col>
							<div>
								<Button
									id='add-all-suggested-entities'
									className='mb-2'
									color='outline-primary'
									style={{ width: '100%' }}
									bssize='sm'
									onClick={addAllSuggestedEntities}
								>
									{t('add_all')}
								</Button>
							</div>
						</Col>
					</Row>
				)}
			</div>
		</FormGroup>
	);
};

function mapStateToProps(state: any) {
	const nestedReduxPropertyName = extractRelatedPropertiesNameByUrl();

	return {
		reduxSuggestedEntities: state.suggestedEntities.suggestedEntities || [],
		reduxSportConnections: (nestedReduxPropertyName && state.tempSportsRelated[nestedReduxPropertyName]) || [],
	};
}

function mapDispatchToProps(dispatch: any) {
	const updateFootballConnectionRelatedAction = extractActionForUpdatingSportsConnections();

	return {
		updateSportsConnection: (rel: Related[]) => updateFootballConnectionRelatedAction && dispatch(updateFootballConnectionRelatedAction(rel)),
		hideSuggestedEntity: (suggestedEntity: FootballConnectionResponseModel) => dispatch(hideSuggestedEntity(suggestedEntity)),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(SuggestedEntities);
