import i18next from 'i18next';
import { toast } from 'react-toastify';
import { BlockAddOrUpdate } from './block-management.service';

export type SaveFunc = { blockId: string; func: (index: number) => void };

export default class BlockWrapperService {
	private onSaveRegister: any[] = [];

	public save = (updateObj: BlockAddOrUpdate) => {
		const index = this.onSaveRegister.findIndex((saveFunc: SaveFunc) => saveFunc.blockId === updateObj.block.id);
		if (index >= 0) {
			if (this.onSaveRegister[index].func(updateObj.index) === false) {
				return false;
			}

			this.onSaveRegister[index].func(updateObj.index);
		}
	};

	public saveAll = (): boolean => {
		for (let i = 0; i < this.onSaveRegister.length; i++) {
			const fn = this.onSaveRegister[i];

			try {
				const result = fn.func(i);

				if (result === false) {
					return false;
				}
			} catch (e) {
				toast.error(i18next.t('error_occurred'));
				console.error(e);
			}
		}

		return true;
	};

	public registerOnSaveFunc = (saveFunc: SaveFunc) => {
		this.onSaveRegister.push(saveFunc);
	};

	public unregisterOnSaveFunc = (blockId: string) => {
		const index = this.onSaveRegister.findIndex((saveFunc: SaveFunc) => saveFunc.blockId === blockId);

		if (index >= 0) {
			this.onSaveRegister.splice(index, 1);
		}
	};
}
