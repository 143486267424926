import React, { useEffect, useState } from 'react';
import InstatHttpService from '../../../../../../../../services/rest/instat/InstatHttpService';
import Select from 'react-select';

type Properties = {
	value: any;
	tournamentId: string;
	seasonId: string;
	onSelect: (value: any | null) => void;
};

const selectedTeamToOption = (value: any) => {
	return value
		? {
				value: value.id,
				label: value.name,
				short_name: value.short_name,
		  }
		: null;
};

const optionToModel = (option: any) => {
	return {
		id: option.value,
		name: option.label,
		short_name: option.short_name,
	};
};

const TeamSelect: React.FunctionComponent<Properties> = (props) => {
	const [teamsOptions, setTeamsOptions] = useState([]);
	const [initialLoad, setInitialLoad] = useState(true);

	useEffect(() => {
		if (initialLoad) {
			const http = new InstatHttpService();
			http
				.getTeamsByTournament(props.tournamentId, props.seasonId)
				.then((teams) => {
					const options = teams.map((team: any) => selectedTeamToOption(team));
					setInitialLoad(false);
					setTeamsOptions(options);
				})
				.catch(() => {
					setInitialLoad(false);
					setTeamsOptions([]);
				});
		}
	});

	useEffect(() => {
		const http = new InstatHttpService();
		http
			.getTeamsByTournament(props.tournamentId, props.seasonId)
			.then((teams) => {
				const options = teams.map((team: any) => selectedTeamToOption(team));
				setTeamsOptions(options);
			})
			.catch(() => {
				setInitialLoad(false);
				setTeamsOptions([]);
			});
	}, [props.seasonId]);

	return (
		<Select
			value={selectedTeamToOption(props.value)}
			options={teamsOptions}
			isClearable
			onChange={(selection: any) => {
				props.onSelect(selection ? optionToModel(selection) : null);
			}}
		/>
	);
};

export default TeamSelect;
