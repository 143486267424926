import { ContentMode } from '../../constants/content-types';
import Video from '../../models/video/Video';

export const VIDEO_TEMP_UPDATE = '[ACTION] VIDEO_TEMP_UPDATE';
export const VIDEO_TEMP_EDIT_UPDATE = '[ACTION] VIDEO_TEMP_EDIT_UPDATE';
export const VIDEO_TEMP_CLEAR = '[ACTION] VIDEO_TEMP_CLEAR';
export const VIDEO_TEMP_EDIT_UPDATE_BEFORE_REDIRECTING = '[ACTION] VIDEO_TEMP_EDIT_UPDATE_BEFORE_REDIRECTING';
export const VIDEO_TEMP_PROPERTY_UPDATE = '[ACTION] VIDEO_TEMP_PROPERTY_UPDATE';

export function updateTempVideo(video: Video) {
	return {
		type: VIDEO_TEMP_UPDATE,
		payload: video,
	};
}

export function updateTempEditVideo(video: Video) {
	return {
		type: VIDEO_TEMP_EDIT_UPDATE,
		payload: video,
	};
}

export function updateTempVideoProperty(propertyObject: Record<string, any>, contentMode: ContentMode) {
	return {
		type: VIDEO_TEMP_PROPERTY_UPDATE,
		payload: propertyObject,
		contentMode,
	};
}

export function updateTempEditVideoBeforeRedirecting() {
	return {
		type: VIDEO_TEMP_EDIT_UPDATE_BEFORE_REDIRECTING,
	};
}

export function clearTempVideo() {
	return {
		type: VIDEO_TEMP_CLEAR,
	};
}
