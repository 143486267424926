import { ContentMode } from '../../constants/content-types';
import { CustomEntitiesSearchFiltersModel, Meta } from '../../views/Pages/CustomEntities/models/models';

export const UPDATE_CUSTOM_ENTITY = '[ACTION] UPDATE_CUSTOM_ENTITY';
export const RESET_CUSTOM_ENTITY = '[ACTION] RESET_CUSTOM_ENTITY';
export const RESET_CUSTOM_ENTITY_WITHOUT_CORE_FIELD = '[ACTION] RESET_CUSTOM_ENTITY_WITHOUT_CORE_FIELD';
export const CUSTOM_ENTITIES_PAGINATION_UPDATE = '[ACTION] CUSTOM_ENTITIES_PAGINATION_UPDATE';
export const CUSTOM_ENTITIES_FILTERS_UPDATE = '[ACTION] CUSTOM_ENTITIES_FILTERS_UPDATE';

export function updateCustomEntity(mode: ContentMode, dataForUpdate: Record<string, any>) {
	return {
		type: UPDATE_CUSTOM_ENTITY,
		payload: { mode, dataForUpdate },
	};
}

export function resetCustomEntity() {
	return {
		type: RESET_CUSTOM_ENTITY,
	};
}

export function resetCustomEntityWithoutCoreFields(contentMode: ContentMode) {
	return {
		type: RESET_CUSTOM_ENTITY_WITHOUT_CORE_FIELD,
		payload: { contentMode },
	};
}

export function returnObjectForCustomEntitiesPaginationUpdate(pagination: Meta) {
	return {
		type: CUSTOM_ENTITIES_PAGINATION_UPDATE,
		payload: { pagination },
	};
}

export function returnObjectForCustomEntitiesFiltersUpdate(filters: CustomEntitiesSearchFiltersModel) {
	return {
		type: CUSTOM_ENTITIES_FILTERS_UPDATE,
		payload: { filters },
	};
}
