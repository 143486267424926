import StandingsWidgetModel from '../../../blocks/widgets/standings/models/standings-widget.model';
import { GroupJson } from './group.json';
import GroupModel from './group.model';

export default class GroupBuilder {
	readonly json: GroupJson;

	constructor(group?: GroupModel | GroupJson) {
		if (group && group instanceof GroupModel) {
			this.json = group.toJson();
		} else if (group) {
			this.json = group;
		} else {
			this.json = {} as GroupJson;
		}
	}

	withId(id: string): GroupBuilder {
		this.json.id = id;

		return this;
	}

	withName(name: string): GroupBuilder {
		this.json.name = name;

		return this;
	}

	withStanding(standing: StandingsWidgetModel[]): GroupBuilder {
		this.json.standing = standing;

		return this;
	}

	build(): GroupModel {
		return GroupModel.fromJson(this.json);
	}
}
