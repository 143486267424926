import { call, put, takeEvery } from 'redux-saga/effects';
import HttpService from '../../services/rest/HttpService';
import {
	CATEGORY_ENTITY_CREATE,
	CATEGORY_SEARCH,
	CATEGORY_ENTITY_REQUEST,
	CATEGORY_ENTITY_DELETE,
	CATEGORY_ENTITY_UPDATE,
	CATEGORY_LISTING_REQUEST,
	ALL_CATEGORY_LISTING_REQUEST,
	returnObjectForCategoryEntityDeleteSuccess,
	returnObjectForCategoryEntityDeleteFailed,
	returnObjectForCategoryEntityCreateSuccess,
	returnObjectForCategoryEntityUpdateSuccess,
	returnObjectForCategoryEntityReceived,
	returnObjectForCategoryListingReceived,
	returnObjectForAdminCategoryListingReceived,
	returnObjectForCategoryEntityUpdateFailed,
	returnObjectForCategoryEntityCreateFailed,
	returnObjectForAllCategoryListingReceived,
} from '../action-creators/CategoriesActionCreators';
import { toggleLoadingState, toggleModal } from '../action-creators/UiActionCreator';
import { onError } from '../action-creators/GeneralActions';
import { actionService } from '../../App';
import ErrorResponseModel from '../../models/error-response/ErrorResponseModel';

function* fetchCategoryListingFromApi(action: any) {
	yield put(toggleLoadingState(true));

	try {
		let headers = { Project: action.payload.project.domain };
		const categories = yield call(HttpService.get, `/categories?page=${action.payload.page}&limit=200`, null, headers);
		yield put(returnObjectForCategoryListingReceived(categories));
		yield put(toggleLoadingState(false));
	} catch (error) {
		yield put(onError(error));
	}
}

function* fetchAdminCategoryListingFromApi(action: any) {
	yield put(toggleLoadingState(true));

	try {
		let headers = { Project: action.payload.project.domain };

		let adminCategories = yield call(HttpService.get, `/admins/${action.payload.adminId}/categories`, null, headers);
		yield put(returnObjectForAdminCategoryListingReceived(adminCategories.data.data));
		yield put(toggleLoadingState(false));
	} catch (error) {
		yield put(onError(error));
	}
}

function* searchCategoryFromApi(action: any) {
	yield put(toggleLoadingState(true));

	try {
		let headers = { Project: action.payload.project.domain };
		const categories = yield call(HttpService.get, `/categories/search?query=${action.payload.text}`, null, headers);
		yield put(returnObjectForCategoryListingReceived(categories));
		yield put(toggleLoadingState(false));
	} catch (error) {
		yield put(onError(error));
	}
}

function* fetchCategoryEntityFromApi(action: any) {
	yield put(toggleLoadingState(true));

	try {
		let headers = { Project: action.payload.project.domain };
		const category = yield call(HttpService.get, `/categories/${action.payload.id}`, null, headers);
		yield put(returnObjectForCategoryEntityReceived(category));
		yield put(toggleLoadingState(false));
	} catch (error) {
		yield put(onError(error));
	}
}

function* postCategoryEntityToApi(action: any) {
	let headers = { Project: action.payload.project.domain };
	let categoryResponse: any = {};

	try {
		let categoryPayload = action.payload.category;
		categoryResponse = yield call(HttpService.post, '/categories', categoryPayload, headers);
		yield put(returnObjectForCategoryEntityCreateSuccess(categoryResponse.data.data.id));
	} catch (error) {
		yield put(returnObjectForCategoryEntityCreateFailed(ErrorResponseModel.builder().fromResponse(error).build()));
		yield put(onError(error));
		actionService.emitError(error);
	}

	try {
		const categories = yield call(HttpService.get, `/categories?page=1&limit=200`, null, headers);
		yield put(returnObjectForAllCategoryListingReceived(categories.data.data));
		yield put(toggleLoadingState(false));
	} catch (error) {
		yield put(onError(error));
	}
}

function* patchCategoryEntityToApi(action: any) {
	let headers = { Project: action.payload.project.domain };

	try {
		let categoryPayload = action.payload.category;
		let id = action.payload.category.id;
		yield call(HttpService.patch, `/categories/${id}`, categoryPayload, headers);
		yield put(returnObjectForCategoryEntityUpdateSuccess());
	} catch (error) {
		yield put(returnObjectForCategoryEntityUpdateFailed(ErrorResponseModel.builder().fromResponse(error).build()));
		yield put(onError(error));
		actionService.emitError(error);
	}

	try {
		const categories = yield call(HttpService.get, `/categories?page=1&limit=200`, null, headers);
		yield put(returnObjectForAllCategoryListingReceived(categories.data.data));
		yield put(toggleLoadingState(false));
	} catch (error) {
		yield put(onError(error));
	}
}

function* deleteCategoryEntityFromApi(action: any) {
	let headers = { Project: action.payload.project.domain };

	try {
		let id = action.payload.id;
		yield call(HttpService.delete, `/categories/${id}`, null, headers);
		yield put(returnObjectForCategoryEntityDeleteSuccess());
		yield put(toggleModal(false));
	} catch (error) {
		yield put(toggleModal(false));
		actionService.emitError(error);
		yield put(returnObjectForCategoryEntityDeleteFailed(error));
		yield put(onError(error));
	}

	try {
		const categories = yield call(HttpService.get, `/categories?page=1&limit=200`, null, headers);
		yield put(returnObjectForAllCategoryListingReceived(categories.data.data));
		yield put(returnObjectForCategoryListingReceived(categories));
		yield put(toggleLoadingState(false));
	} catch (error) {
		yield put(onError(error));
	}
}

function* categorySaga() {
	yield takeEvery(ALL_CATEGORY_LISTING_REQUEST, fetchAdminCategoryListingFromApi);
	yield takeEvery(CATEGORY_LISTING_REQUEST, fetchCategoryListingFromApi);
	yield takeEvery(CATEGORY_SEARCH, searchCategoryFromApi);
	yield takeEvery(CATEGORY_ENTITY_REQUEST, fetchCategoryEntityFromApi);
	yield takeEvery(CATEGORY_ENTITY_CREATE, postCategoryEntityToApi);
	yield takeEvery(CATEGORY_ENTITY_UPDATE, patchCategoryEntityToApi);
	yield takeEvery(CATEGORY_ENTITY_DELETE, deleteCategoryEntityFromApi);
}

export default categorySaga;
