import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Col, Label, Row } from 'reactstrap';
import HttpService from '../../../../../../services/rest/HttpService';
import { customOption } from '../../../partials/shared/custom-select-option';
import ErrorHandler from '../error-handler/error-handler';
import { SelectMenuOptionType } from '../../../../../../models/v2/general/select.model';
import { useTranslation } from 'react-i18next';
import MatchModel from '../../../../../../models/v2/match/entity/match.model';
import { matchesToOptions, matchToOption, responseToMatchModel } from '../helpers/match-select/match-by-team-select.helper';

type Properties = {
	onMatchSelect: Function;
	selectedMatch: MatchModel;
	marketValueType: string;
	teamId: string;
	isValid: boolean;
	isRequired?: boolean;
	id: string;
	limit: string;
};

const MatchesByTeamIdComponent: React.FunctionComponent<Properties> = (props) => {
	const [t] = useTranslation();
	const [matchesOptions, setMatchesOptions] = useState<MatchModel[]>([]);

	useEffect(() => {
		if (props.teamId) {
			getTeamsById(props.teamId);
		}
	}, [props.teamId, props.marketValueType]);

	const updateMatchesOptionsState = (matches: MatchModel[]) => {
		const { onMatchSelect, selectedMatch } = props;

		if (!selectedMatch) {
			onMatchSelect(matches[0]);
		}
		setMatchesOptions(matches);
	};

	const getTeamsById = (teamId: string) => {
		if (teamId) {
			return HttpService.getMatchesFromCurrentDateByTeamId(teamId)
				.then((response: any) => {
					updateMatchesOptionsState(response.data.map((item: MatchModel) => responseToMatchModel(item)));
				})
				.catch((e: any) => e);
		}
	};

	const { isRequired, onMatchSelect, selectedMatch, isValid } = props;
	const matchNotSelected = selectedMatch === undefined || selectedMatch === null;

	return (
		<Row data-qa={props.id}>
			<Col>
				<Label htmlFor={props.id}>{t('match')}:</Label>
				{isRequired && <span className='text-danger'>*</span>}
				<Select
					id={props.id}
					className='mb-2'
					options={matchesToOptions(matchesOptions)}
					value={selectedMatch && selectedMatch.id ? matchToOption(selectedMatch) : []}
					noOptionsMessage={(inputValue) => inputValue && t('no_options')}
					isClearable={!matchNotSelected}
					placeholder={t('select')}
					formatOptionLabel={customOption}
					onChange={(selection: SelectMenuOptionType) => {
						selection ? onMatchSelect(selection.data) : onMatchSelect(null);
					}}
				/>
				{isRequired && <ErrorHandler arePropsValid={isValid} isValueSelected={matchNotSelected} t={t} errorMessage='field_is_required' />}
			</Col>
		</Row>
	);
};

export default MatchesByTeamIdComponent as React.ComponentType<Properties>;
