import React from 'react';
import { connect } from 'react-redux';
import { autoGenerateReduxPropsNamingForContentModels } from '../../../../../../services/content-models-service/ContentModelService';
import Project from '../../../../../../models/project/Project';
import { isCustomDataPopulatedWithWatermark } from '../../helpers/general';
import ImageUploadButtonContainer from '../../../../Shared/image-upload/image-upload-button-container';
import { getUploadedImage } from '../../helpers/requests';
import { DATA_QA_ATTRIBUTES } from '../../constants/data-qa.attributes';

type Properties = {
	project: Project;
	watermarkAdded: boolean;
	t: any;
};

const UploadMainImage: React.FunctionComponent<Properties> = ({ t, project, watermarkAdded }) => {
	const onImageUpload = (id: string) => id && getUploadedImage(id, project.domain);
	const toggleUploading = (uploading: boolean) => {
		/* this is empty because we doesn't support loader in 'ImageUploadButtonContainer' and if I place it - the styles are broken */
	};

	return (
		<ImageUploadButtonContainer
			t={t}
			imageUploadButtonId={DATA_QA_ATTRIBUTES.UPLOAD_IMAGE}
			project={project}
			blockStyle={true}
			onImageUploadSuccess={onImageUpload}
			display
			applyQuickWatermark={watermarkAdded}
			toggleUploading={toggleUploading}
		/>
	);
};

function mapStateToProps(state: any) {
	const { tempPropertyNaming, nestedTempPropertyNaming } = autoGenerateReduxPropsNamingForContentModels();
	const genericRedux = state[tempPropertyNaming][nestedTempPropertyNaming].generic || null;

	return {
		project: state.project.currentProject,
		watermarkAdded: isCustomDataPopulatedWithWatermark(genericRedux),
	};
}

export default connect(mapStateToProps)(UploadMainImage);
