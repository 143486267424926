import * as React from 'react';
import Select from 'react-select';
import { Col, Label, Row } from 'reactstrap';
import { contentToOptions, contentToOption } from '../helpers/select-with-translation.helper';

type Properties = {
	t: any;
	isClearable: boolean;
	selectedThreeLetterCodeType: any;
	onThreeLetterCodeTypeChange: (threeLetterCodeType: any) => any;
};

const ThreeLetterCodeTypeSelect: React.FunctionComponent<Properties> = (props) => {
	const { t, isClearable, onThreeLetterCodeTypeChange, selectedThreeLetterCodeType } = props;

	const onContentChange = (selection: any) => {
		onThreeLetterCodeTypeChange(selection && selection.data ? selection.data : '');
	};

	const types = [
		{ id: 'mobile', name: t('mobile_resolutions') },
		{ id: 'all', name: t('all_resolutions') },
	];

	return (
		<>
			<Label>{t('show_team_abbreviation')}</Label>
			<Row className='mb-2'>
				<Col>
					<Select
						inputId='three-letter-code-type-select'
						value={selectedThreeLetterCodeType && contentToOption(selectedThreeLetterCodeType, t)}
						options={contentToOptions(types, t)}
						onChange={onContentChange}
						placeholder={t('select_three_letter_code_type')}
						isClearable={isClearable}
					/>
				</Col>
			</Row>
		</>
	);
};

export default ThreeLetterCodeTypeSelect;
