import AmgVideoModel from '../models/amg-video.model';
import AmgVideosHttpService from './amg-videos-http.service';
import AmgVideoMapper from '../models/amg-video.mapper';
import AmgItemDataModel from '../models/amg-item-data.model';

export const AMG_VIDEO_SECTION_CODE = '2cc90cb7-cf4c-42d6-8b5f-162a7d4c4896';

export default class AmgVideosService {
	/*
	 * Requests the Steam AMG videos listing and returns a Promise<AmgVideosModel[]>
	 */
	public getAmgVideos = async (section: string, pageIndex: string, pageSize: string): Promise<AmgVideoModel[]> => {
		const videos = await AmgVideosHttpService.getVideos(section, pageIndex, pageSize);
		const mapper = new AmgVideoMapper();
		if (videos && videos.data && videos.data.sections && videos.data.sections.length > 0) {
			return videos.data.sections.map((video: any) => mapper.responseToModel(video));
		} else {
			return [];
		}
	};

	public constructAmgVideoContent = (video: AmgItemDataModel) => {
		if (video && video.mediaData && video.mediaData.entryId && video.mediaData.entryId.length > 0) {
			return `<iframe id='kaltura_player_1663760714' src='<https://open/http.mp.streamamg.com/p/3001401/sp/300140100/embedIframeJs/uiconf_id/30029423/partner_id/3001401?iframeembed=true&playerId=kaltura_player_1663760714&entry_id=${video.mediaData.entryId}>' width='560' height='315' allowfullscreen webkitallowfullscreen mozAllowFullScreen allow='autoplay; fullscreen; encrypted-media' frameborder='0'></iframe>`;
		}

		return '';
	};
}
