import { Col, FormGroup, Row } from 'reactstrap';
import React from 'react';
import SpotlightAudioSelect from '../../../../partials/spotlight-audio-select/spotlight-audio-select.component';
import AudioContainer from '../audio-rows/audios-container';
import ErrorHandler from '../../../../partials/error/error-handler-component';
import SpotlightAudioModel from '../../../../../../../models/spotlight-audio/spotlight-audio.model';
import BlockModel from '../../../../models/block.model';

type Properties = {
	t: any;
	onSelect: (audio: SpotlightAudioModel | null) => void;
	block?: BlockModel;
	selectedAudio: SpotlightAudioModel;
	blocks: BlockModel[];
	isValid?: boolean;
	inBlocky: boolean;
};

export type State = {
	audios: SpotlightAudioModel[] | null;
	toggleLoadingState: boolean;
	shouldRequest: boolean;
};

class SpotlightContainer extends React.Component<Properties, any> {
	constructor(props: any) {
		super(props);
		this.state = {
			audios: null,
			toggleLoadingState: false,
			shouldRequest: false,
		};
	}

	setAudios = (audios: SpotlightAudioModel[] | null) => {
		this.setState((state: State) => ({ ...state, audios, shouldRequest: false }));
	};

	toggleLoadingState = (loading: boolean) => this.setState((state: State) => ({ ...state, toggleLoadingState: loading }));

	render() {
		const { t, onSelect, selectedAudio, blocks, block } = this.props;
		const { audios, toggleLoadingState, shouldRequest } = this.state;

		return (
			<div>
				<Row>
					<Col>
						<FormGroup>
							<SpotlightAudioSelect
								t={t}
								shouldRequest={shouldRequest}
								setAudios={this.setAudios}
								onSelectedAudioChange={onSelect}
								toggleLoadingState={this.toggleLoadingState}
								blocks={blocks}
								filters={block && block.data && block.data.preview && block.data.preview.audio && block.data.preview.audio.filters}
							/>
						</FormGroup>
					</Col>
				</Row>
				<div className={`${toggleLoadingState ? 'loading-overlay rounded opacity-06' : ''} py-2`}>
					{audios && audios.length > 0 ? (
						<div className='pb-2'>
							<div className='bg-white rounded'>
								<AudioContainer t={t} onSelect={onSelect} audios={audios} toggleLoadingState={toggleLoadingState} selectedAudio={selectedAudio} />
							</div>
						</div>
					) : (
						<div className='text-center'>{t('no_data')}</div>
					)}
				</div>
				{this.props.inBlocky && (
					<div className='p-1'>
						{!this.props.isValid && selectedAudio && selectedAudio.homeTeam === undefined && (
							<ErrorHandler t={this.props.t} errorMessage='no_selected_audio' />
						)}
					</div>
				)}
			</div>
		);
	}
}

export default SpotlightContainer;
