import React from 'react';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import ErrorHandler from '../../../partials/error/error-handler-component';
import BlockModel from '../../../models/block.model';
import BlockValidation from '../../../helpers/block-validation.helper';
import { blockWrapperService, blockManagementService } from '../../../subcomponents/blocky.component';
import { createBlock } from '../../partials/helpers/blocky-sidebar-communication/blocky-sidebar-communication.helper';
import { WidgetsV2Ids } from '../../../constants/block.types';
import LimitInput from '../../../partials/limit-input/limit-input.component';
import TennisRankingModel from './models/tennis-ranking.model';
import { SelectMenuOptionType } from '../../../../../../models/v2/general/select.model';
import { onRefreshTimeChangeFromMainComponent } from '../../partials/refresh-time-input/helper';
import RefreshTimeWrapper from '../../partials/refresh-time-input/refresh-time-wrapper';
import OffsetInput from '../../../partials/offset-input/offset-input.component';
import StandingTypesSelectComponent from '../../../partials/standing-types-select/standing-types-select.component';
import { SportTypes } from '../../../../../../constants/sport.types';
import { SPORT_SELECT_ATTRIBUTES } from '../helpers/component-attributes.constants';
import AvailableStandingTypeModel from '../../../../../../models/v2/standing-types/available-standing-type.model';
import RankingPlayersSelectComponent from '../../../partials/ranking-players-select/ranking-players-select-container.component';
import RankingPlayerModel from '../../../../../../models/v2/ranking-player/ranking-player.model';

enum SCOPE {
	CURRENT = 'CURRENT',
}

type Properties = {
	block: BlockModel;
	t: any;
	contentData: any;
	contentLanguage: string;
};

type State = {
	widgetModel: TennisRankingModel;
	isValid: boolean;
};

export default class TennisRankingWidgetEdit extends React.Component<Properties, State> {
	constructor(props: Properties) {
		super(props);
		this.state = {
			widgetModel: createBlock(
				WidgetsV2Ids.widgetTennisRanking,
				props.block.data.preview && props.block.data.preview.ranking,
				props.contentData,
			),
			isValid: true,
		};
	}

	componentDidMount(): void {
		blockWrapperService.registerOnSaveFunc({ blockId: this.props.block.id, func: this.onSave });
	}

	componentWillUnmount(): void {
		blockWrapperService.unregisterOnSaveFunc(this.props.block.id);
	}

	onSave = (index: number) => {
		const data = { widgetModel: this.state.widgetModel };
		const validation = new BlockValidation();
		const isValid = validation.validate(this.props.block, data) === true;
		this.setState({ ...this.state, isValid });

		if (isValid) {
			const block = Object.assign({}, this.props.block);
			block.data.preview = { ranking: this.state.widgetModel };
			blockManagementService.blockUpdate(block, index);
		} else {
			return isValid;
		}
	};

	updateWidgetModelState = (model: TennisRankingModel) => {
		this.setState({ ...this.state, widgetModel: model });
	};

	onLimitChange = (limit: string) => {
		this.updateWidgetModelState(TennisRankingModel.builder(this.state.widgetModel).withLimit(limit).withHighlightPlayers([]).build());
	};

	onOffsetChange = (offset: string) => {
		this.updateWidgetModelState(TennisRankingModel.builder(this.state.widgetModel).withOffset(offset).withHighlightPlayers([]).build());
	};

	onStandingTypeSelect = (standingType: AvailableStandingTypeModel) => {
		this.updateWidgetModelState(
			TennisRankingModel.builder(this.state.widgetModel).withStandingType(standingType).withHighlightPlayers([]).build(),
		);
	};

	onSelectPlayers = (e: any) => {
		this.updateWidgetModelState(
			TennisRankingModel.builder(this.state.widgetModel).withSelectPlayers(e.target.checked).withHighlightPlayers([]).build(),
		);
	};

	onRankingPlayersHighlight = (rankingPlayers: RankingPlayerModel[]) => {
		this.updateWidgetModelState(TennisRankingModel.builder(this.state.widgetModel).withHighlightPlayers(rankingPlayers).build());
	};

	onRefreshTimeChange = (refreshTimeData: SelectMenuOptionType) => {
		onRefreshTimeChangeFromMainComponent(refreshTimeData, TennisRankingModel, this.state.widgetModel, this.updateWidgetModelState);
	};

	render() {
		const { t, contentLanguage } = this.props;
		const { widgetModel, isValid } = this.state;

		return (
			<>
				<div data-qa='tennis-single-event-edit'>
					<Row>
						<Col>
							<FormGroup>
								<LimitInput onLimitChange={this.onLimitChange} value={widgetModel.limit} isValid={isValid} isRequired />
							</FormGroup>
						</Col>
					</Row>
					<Row>
						<Col>
							<FormGroup>
								<OffsetInput
									onOffsetChange={this.onOffsetChange}
									value={widgetModel.offset}
									isValid={isValid}
									isRequired
									type={WidgetsV2Ids.widgetTennisRanking}
								/>
							</FormGroup>
						</Col>
					</Row>
					<Row className='mb-2'>
						<Col>
							<StandingTypesSelectComponent
								onStandingTypeSelect={this.onStandingTypeSelect}
								selectedStandingType={
									widgetModel.availableStandingType && widgetModel.availableStandingType.id
										? widgetModel.availableStandingType
										: ({} as AvailableStandingTypeModel)
								}
								isValid={isValid}
								language={contentLanguage}
								isRequired
								isClearable
								isBlocky
								sport={SportTypes.TENNIS}
								id={`${SportTypes.TENNIS}-${SPORT_SELECT_ATTRIBUTES.STANDING_TYPE_SELECT}`}
								scope={SCOPE.CURRENT}
							/>
						</Col>
					</Row>
					<Row className='mb-2'>
						<Col>
							<hr />
							<FormGroup key='select-players' check inline>
								<Label>
									<Input
										key={`display-select-players-input-${this.props.block.id}`}
										className='form-check-input'
										type='checkbox'
										id={`select-players-${this.props.block.id}`}
										name='select-players-input'
										checked={widgetModel.selectPlayers}
										onChange={this.onSelectPlayers}
									/>

									{t('highlight_players')}
								</Label>
							</FormGroup>

							{(!widgetModel.availableStandingType || widgetModel.availableStandingType.id === '') && widgetModel.selectPlayers && (
								<ErrorHandler errorMessage='select_standing_type_first' />
							)}
						</Col>
					</Row>
					{widgetModel.selectPlayers && widgetModel.availableStandingType && widgetModel.availableStandingType.id ? (
						<Row className='mb-2'>
							<Col>
								<RankingPlayersSelectComponent
									onRankingPlayersSelect={this.onRankingPlayersHighlight}
									t={t}
									selectedPlayers={widgetModel.highlightPlayers && widgetModel.highlightPlayers.length > 0 ? widgetModel.highlightPlayers : []}
									rankingId={widgetModel.availableStandingType && widgetModel.availableStandingType.id ? widgetModel.availableStandingType.id : ''}
									standingTypeName={
										widgetModel.availableStandingType && widgetModel.availableStandingType.standingType.name
											? widgetModel.availableStandingType.standingType.name
											: ''
									}
									isValid={isValid}
									language={contentLanguage}
									isRequired={false}
									isClearable
									isBlocky
									sport={SportTypes.TENNIS}
									id={`${SportTypes.TENNIS}-${SPORT_SELECT_ATTRIBUTES.HIGHLIGHT_PLAYERS_SELECT}`}
									offset={widgetModel.offset}
									limit={widgetModel.limit}
								/>
							</Col>
						</Row>
					) : null}
					<hr />
					<Row className='mb-3'>
						<Col>
							<strong>{t('current_ranking_message')}</strong>
						</Col>
					</Row>
					{!isValid && !widgetModel.limit && (
						<div className='mb-3'>
							<ErrorHandler errorMessage='no_limit_selected' />
						</div>
					)}
					<RefreshTimeWrapper value={this.state.widgetModel.refreshTime} onRefreshTimeChange={this.onRefreshTimeChange} />
				</div>
			</>
		);
	}
}
