import React from 'react';
import { HighlightView } from './highlight-view.component';
import HighlightEdit from './highlight-edit.component';
import BlockUpdateController from '../../block-update-controller.component';
import { ViewTypes } from '../../../constants/general.constants';
import { Properties } from './properties/highlight-block.properties';
import HighlightBlockModel from './models/highlight-block.model';

const HighlightBlock: React.FunctionComponent<Properties> = (props) => {
	if (props.view === ViewTypes.edit) {
		return <HighlightEdit block={props.block} t={props.t} />;
	} else if (props.view === ViewTypes.normal) {
		return (
			<HighlightView
				t={props.t}
				preview={props.block.data && props.block.data.preview ? props.block.data.preview.highlightBlock : HighlightBlockModel.builder().build()}
			/>
		);
	}

	return null;
};

export default BlockUpdateController(HighlightBlock);
