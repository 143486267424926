import React, { ChangeEvent, FunctionComponent, KeyboardEvent, useEffect, useState } from 'react';
import { Button, Col, Row, Collapse, FormGroup, Input, InputGroup, InputGroupAddon } from 'reactstrap';
import ReactPaginate from 'react-paginate';
import PaginationMeta from '../../../../../../../models/v2/Pagination/PaginationModel';
import { connect } from 'react-redux';
import ErrorAsync from '../../../../../BaseComponents/ErrorComponent/ErrorAsync';
import AdvancedContentFilters from '../../../../../../Partials/Shared/advanced-content-filter/advanced-content-filter.component';
import { useTranslation } from 'react-i18next';
import { getAllImagesRequest, searchImagesRequest } from '../../../../../Sidebar/media-refactored/helpers/requests';
import { areAnyFiltersSelected } from '../../../../../Sidebar/media-refactored/helpers/general';
import { PaginationProps } from '../../../../../Sidebar/media-refactored/constants/react-paginate';
import ImageCells from './image-cells';
import Image from '../../../../../../../models/image/Image';
import { setStateVariablesAfterRequest, DATA_QA_ATTRIBUTES } from '../helpers/gallery-block.helper';

type Properties = {
	projectDomain: string;
	onGalleryItemsChange: (data: Image[]) => void;
	galleryItems: Image[];
};

const GalleryBlockImageListing: FunctionComponent<Properties> = ({ projectDomain, onGalleryItemsChange, galleryItems }) => {
	const [t] = useTranslation();
	const [isCollapseOpen, setIsCollapseOpen] = useState(false);
	const [images, setImages] = useState<Image[]>([]);
	const [pagination, setPagination] = useState<PaginationMeta>(new PaginationMeta());
	const [isContentLoading, setIsContentLoading] = useState(false);
	const [contentSearchInput, setContentSearchInput] = useState('');
	const [advancedContentSearchInput, setAdvancedContentSearchInput] = useState('');

	useEffect(() => {
		isCollapseOpen && checkFiltersAndSendRequest();
	}, [isCollapseOpen, pagination.currentPage]);

	const searchImages = () => {
		setIsContentLoading(true);
		const responsePromise = searchImagesRequest(projectDomain, contentSearchInput, advancedContentSearchInput, pagination.currentPage);
		setStateVariablesAfterRequest(responsePromise, setImages, setPagination, setIsContentLoading);
	};

	const requestAllImages = () => {
		setIsContentLoading(true);
		const responsePromise = getAllImagesRequest(projectDomain, pagination.currentPage);
		setStateVariablesAfterRequest(responsePromise, setImages, setPagination, setIsContentLoading);
	};

	const checkFiltersAndSendRequest = () =>
		areAnyFiltersSelected(contentSearchInput, advancedContentSearchInput) ? searchImages() : requestAllImages();

	const onContentSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
		const searchedText = event.target.value || '';
		setContentSearchInput(searchedText);
	};

	return (
		<>
			<Button
				className='m-0 p-0 mt-3'
				color='link'
				onClick={() => setIsCollapseOpen(!isCollapseOpen)}
				aria-expanded={isCollapseOpen}
				aria-controls={DATA_QA_ATTRIBUTES.TOGGLE_IMAGES}
				id={DATA_QA_ATTRIBUTES.TOGGLE_IMAGES_BUTTON}
			>
				{t('toggle_uploaded_images')}
			</Button>
			<Collapse isOpen={isCollapseOpen} id={DATA_QA_ATTRIBUTES.TOGGLE_IMAGES} className='py-2'>
				<FormGroup row className='mb-0'>
					<Col md='12'>
						<InputGroup>
							<Input
								type='text'
								id={DATA_QA_ATTRIBUTES.IMAGES_CONTENT_SEARCH}
								name='search-input'
								placeholder={t('content_search_placeholder')}
								onChange={onContentSearchChange}
								onKeyDown={(event: KeyboardEvent<HTMLInputElement>) => event.keyCode === 13 && checkFiltersAndSendRequest()}
								value={contentSearchInput}
							/>
							<InputGroupAddon addonType='append'>
								<Button id={DATA_QA_ATTRIBUTES.IMAGES_CONTENT_SEARCH_BUTTON} type='button' color='primary' onClick={checkFiltersAndSendRequest}>
									<i className={'fa fa-search'}>&nbsp;</i>
									{t('search')}
								</Button>
							</InputGroupAddon>
						</InputGroup>
					</Col>
				</FormGroup>
				<ErrorAsync t={t} errorType='query' />
				<Row className='mb-2' id={DATA_QA_ATTRIBUTES.IMAGES_ADVANCED_CONTENT_FILTERS}>
					<Col className='ml-auto'>
						<AdvancedContentFilters
							t={t}
							filtersInSidebar={true}
							updateAdvancedSearchText={(text: string) => setAdvancedContentSearchInput(text)}
							searchContent={searchImages}
							getAllContent={requestAllImages}
							contentSearchInput={contentSearchInput}
							showOriginSelect={true}
							calledFrom='gallery-block'
							saveFiltersHistory={false}
							showTypeSelect={false}
						/>
					</Col>
				</Row>
				<div className={`${isContentLoading ? 'loading-overlay' : ''}`}>
					{images && images.length > 0 && <ImageCells galleryItems={galleryItems} images={images} onGalleryItemsChange={onGalleryItemsChange} />}
					{images && images.length === 0 && <h6 className='d-flex justify-content-center mb-2'>{t('no_data_found')}</h6>}
					<ReactPaginate
						{...PaginationProps(t, pagination.currentPage)}
						pageCount={pagination.totalPages ? pagination.totalPages : 0}
						onPageChange={({ selected }) => setPagination({ ...pagination, currentPage: selected + 1 })}
					/>
				</div>
			</Collapse>
		</>
	);
};

function mapStateToProps(state: any) {
	return {
		projectDomain: state.project.currentProject.domain || '',
	};
}

export default connect(mapStateToProps)(GalleryBlockImageListing);
