import React, { useState } from 'react';
import Select from 'react-select';
import { Label, Tooltip } from 'reactstrap';
import { Formation } from '../helpers/response-remappers.helper';
import { assetsPlaceholder, footballField } from '../../../../../constants/assetsPlaceholder';
import LineupPlayerModel from '../../models/player-lineup/lineup-player.model';
import { setMargin, itemsToOptions, itemToOption } from '../helpers/team-formation-select.helper';

type Properties = {
	t: any;
	teamFormation: Formation;
	teamId: string;
	formations: Formation[];
	onSelect: (formation: Formation | null) => void;
};

const TeamFormationSelect: React.FunctionComponent<Properties> = ({ t, teamFormation, formations, onSelect, teamId }) => {
	const [tooltipState, toggle] = useState<{}>({});

	const toggleTooltip = (id: string) => toggle((tooltipState: any) => ({ [id]: !tooltipState[id] }));

	const onSelectionChange = (selection: any) => {
		if (selection) {
			onSelect(selection.data);
		} else {
			onSelect(null);
		}
	};

	return (
		<div className='mb-1 d-flex flex-wrap formation-container'>
			<div className='pr-1 pb-1 formation-select'>
				<Label htmlFor='team-formation-select'>{t('formation')}</Label>
				<Select
					className='w-100'
					isClearable
					inputId={`team-formation-select-${teamId}`}
					placeholder={t('select')}
					options={itemsToOptions(formations)}
					value={itemToOption(teamFormation)}
					onChange={onSelectionChange}
				/>
			</div>
			{teamFormation.formation && (
				<div className='team-formation-wrapper pb-1'>
					<img className='p-1 border rounded animated fadeIn shadow' src={footballField} alt={t('football')} />
					{teamFormation.positions.map((player: LineupPlayerModel, index: number) => (
						<div
							id={`tooltip-player-position-${player.player.id}`}
							className={`x-${player.positionX}-y-${player.positionY}`}
							key={`image-${index}`}
							style={{ marginBottom: setMargin(player.positionX, index, teamFormation.positions) }}
						>
							{player.player.id && (
								<Tooltip
									placement='top'
									isOpen={tooltipState[player.player.id]}
									target={`tooltip-player-position-${player.player.id}`}
									toggle={() => toggleTooltip(player.player.id)}
								>
									{player.player.name}
								</Tooltip>
							)}
							<div className='position-relative'>
								<div className='lineup-shirt'>{player.shirtNumber ? player.shirtNumber : ''}</div>
								<img width={25} height={25} src={assetsPlaceholder.lineup} />
							</div>
						</div>
					))}
				</div>
			)}
		</div>
	);
};

export default TeamFormationSelect;
