import moment from 'moment';
import { TypedJSON } from 'typedjson';
import MatchModelV2 from '../../../widgets-v2/football-single-event/models/MatchModelV2.model';
import MatchModel from '../../../../partials/match-select/models/match.model';

export enum MATCH_STATUS_TYPES {
	finished = 'finished',
	interrupted = 'interrupted',
	live = 'live',
	not_started = 'not_started',
	postponed = 'postponed',
}

export const responseToMatchModelV2 = (response: any) => {
	const serializer = new TypedJSON(MatchModelV2);
	return serializer.parse(response) as MatchModelV2;
};

export const removePastMatches = (matches: MatchModelV2[]) => {
	return matches.filter((match: any) => moment().diff(match.kickoffTime, 'days') < 1 && match.status.code !== MATCH_STATUS_TYPES.postponed);
};

export const markMatchModelAsSelected = (matches: MatchModelV2[], matchId: string, matchIds: string[], isMulti: boolean) => {
	if (matches && !isMulti) {
		matches = matches.map((match: MatchModelV2) => {
			match.isSelected = match.id === matchId;

			return match;
		});
	}

	if (matches && isMulti) {
		matches = matches.map((match: MatchModelV2) => {
			const index = matchIds.findIndex((id) => match.id === id);
			match.isSelected = index > -1;

			return match;
		});
	}

	return matches;
};

export const convertTeamIdsToMatchSearchUrls = (teamIds: string[], limit: number) => {
	let urls = [];

	if (teamIds && teamIds.length > 0) {
		urls.push(`v2/matches?team_ids=${teamIds.join(',')}&limit=${limit}&offset=0&sort_direction=asc&status_types=NOT_STARTED`);
		urls.push(`v2/matches?team_ids=${teamIds.join(',')}&limit=${limit}&offset=0&sort_direction=desc&status_types=FINISHED`);
		urls.push(`v2/matches?team_ids=${teamIds.join(',')}&limit=${limit}&offset=0&sort_direction=asc&status_types=LIVE`);
		urls.push(`v2/matches?team_ids=${teamIds.join(',')}&limit=${limit}&offset=0&sort_direction=asc&status_types=INTERRUPTED`);
	}

	return urls;
};

export const matchListToOptionList = (matchList: MatchModelV2[]) => {
	if (matchList && matchList.length > 0) {
		return matchList.map((match: MatchModelV2) => matchToOption(match));
	}

	return [];
};

export const matchToOption = (match: MatchModelV2) => {
	if (match && match.homeTeam && match.homeTeam.id) {
		return {
			value: match.id,
			label: `${moment(match.kickoffTime).format('DD MMMM YYYY')},
             ${match.homeTeam.name} - ${match.awayTeam.name}`,
			data: match,
		};
	}

	return { value: '', label: '' };
};
