import React from 'react';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import { blockManagementService, blockWrapperService } from '../../../../subcomponents/blocky.component';
import BlockValidation from '../../../../helpers/block-validation.helper';
import BlockModel from '../../../../models/block.model';
import PlayerSeasonSelectComponent from '../../../partials/player-select/player-season-select.component';
import EntitySeasonsSelectModel from '../../../../partials/player-seasons/model/entity-seasons-select.model';
import TeamModel from '../../../../../../../models/v2/Team/Entity/TeamModel';
import TeamSelectComponent from '../../../partials/team-select/team-select.component';
import { ContentTypes } from '../../../../../../../constants/content-types';
import FootballTeamH2HWidgetModel from '../models/football-team-h2h.model';
import { SportTypes } from '../../../../../../../constants/sport.types';
import { SPORT_SELECT_ATTRIBUTES } from '../../helpers/component-attributes.constants';
import { createBlock } from '../../../partials/helpers/blocky-sidebar-communication/blocky-sidebar-communication.helper';
import { WidgetsV2Ids } from '../../../../constants/block.types';
import { ElementsSelect } from '../../../partials/elements-select/widget-elements-select';
import { teamH2HStatsOptions } from '../../football-team/constants/football-team.constants';
import { onRefreshTimeChangeFromMainComponent } from '../../../partials/refresh-time-input/helper';
import { SelectMenuOptionType } from '../../../../../../../models/v2/general/select.model';
import RefreshTimeWrapper from '../../../partials/refresh-time-input/refresh-time-wrapper';
type Properties = {
	block: BlockModel;
	t: any;
	contentData: any;
	contentLanguage: string;
};

type State = {
	widgetModel: FootballTeamH2HWidgetModel;
	isValid: boolean;
};

export default class FootballTeamH2HEditComponent extends React.Component<Properties, State> {
	constructor(props: Properties) {
		super(props);
		this.state = {
			widgetModel: createBlock(
				WidgetsV2Ids.widgetFootballTeamH2H,
				props.block.data.preview && props.block.data.preview.widgetModel,
				props.contentData,
				props.block,
			),
			isValid: false,
		};
	}

	componentDidMount(): void {
		blockWrapperService.registerOnSaveFunc({ blockId: this.props.block.id, func: this.onSave });
	}

	onSave = (index: number) => {
		const validation = new BlockValidation();
		const data = { widgetModel: this.state.widgetModel };
		const isValid = validation.validate(this.props.block, data) === true;

		this.setState({ ...this.state, isValid });

		if (isValid) {
			const block = Object.assign({}, this.props.block);
			block.data.preview = { widgetModel: this.state.widgetModel };
			blockManagementService.blockUpdate(block, index);
		} else {
			return isValid;
		}
	};

	componentWillUnmount(): void {
		blockWrapperService.unregisterOnSaveFunc(this.props.block.id);
	}

	updateWidgetModelState = (model: FootballTeamH2HWidgetModel) => {
		this.setState({ ...this.state, widgetModel: model });
	};

	onTeam1Change = (team: TeamModel) => {
		this.updateWidgetModelState(
			FootballTeamH2HWidgetModel.builder(this.state.widgetModel)
				.withTeamOne(team)
				.withTeamOneSeason({} as EntitySeasonsSelectModel)
				.build(),
		);
	};

	onTeam2Change = (team: TeamModel) => {
		this.updateWidgetModelState(
			FootballTeamH2HWidgetModel.builder(this.state.widgetModel)
				.withTeamTwo(team)
				.withTeamTwoSeason({} as EntitySeasonsSelectModel)
				.build(),
		);
	};

	onTeam1SeasonsChange = (season: EntitySeasonsSelectModel) => {
		this.updateWidgetModelState(FootballTeamH2HWidgetModel.builder(this.state.widgetModel).withTeamOneSeason(season).build());
	};

	onTeam2SeasonsChange = (season: EntitySeasonsSelectModel) => {
		this.updateWidgetModelState(FootballTeamH2HWidgetModel.builder(this.state.widgetModel).withTeamTwoSeason(season).build());
	};

	onStatsParametersChange = (statsParameters: string[]) => {
		this.updateWidgetModelState(FootballTeamH2HWidgetModel.builder(this.state.widgetModel).withStats(statsParameters).build());
	};

	onRefreshTimeChange = (refreshTimeData: SelectMenuOptionType) => {
		onRefreshTimeChangeFromMainComponent(refreshTimeData, FootballTeamH2HWidgetModel, this.state.widgetModel, this.updateWidgetModelState);
	};

	render() {
		const { widgetModel, isValid } = this.state;
		const { t } = this.props;
		return (
			<>
				<div data-qa='football-team-h2h-block-edit'>
					<Row>
						<Col>
							<FormGroup>
								<TeamSelectComponent
									isMulti={false}
									isBlocky
									isValid={isValid}
									selectedTeam={widgetModel.team1 ? widgetModel.team1 : {}}
									onTeamSelect={(team: TeamModel) => this.onTeam1Change(team)}
									t={t}
									isClearable
									isRequired={true}
									searchByKeyword={true}
									id={`${SportTypes.FOOTBALL}-${SPORT_SELECT_ATTRIBUTES.TEAM_SELECT}-1`}
								/>
							</FormGroup>
						</Col>
					</Row>
					{widgetModel.team1 && widgetModel.team1.id && widgetModel.team1.id.toString().length > 0 && (
						<Row>
							<Col>
								<FormGroup>
									<PlayerSeasonSelectComponent
										isClearable
										isRequired={true}
										isBlocky
										entityId={widgetModel.team1.id}
										selectedSeason={widgetModel.team1TournamentSeason}
										onEntitySeasonSelect={this.onTeam1SeasonsChange}
										isValid={isValid}
										t={t}
										entityType={ContentTypes.TEAM}
										id={`${SportTypes.FOOTBALL}-${SPORT_SELECT_ATTRIBUTES.PLAYER_SEASON_SELECT}-1`}
									/>
								</FormGroup>
							</Col>
						</Row>
					)}
					<hr />
					<Row>
						<Col>
							<FormGroup>
								<TeamSelectComponent
									isMulti={false}
									isBlocky
									isValid={isValid}
									selectedTeam={widgetModel.team2 ? widgetModel.team2 : {}}
									onTeamSelect={(team: TeamModel) => this.onTeam2Change(team)}
									t={t}
									isClearable={true}
									isRequired
									searchByKeyword={true}
									id={`${SportTypes.FOOTBALL}-${SPORT_SELECT_ATTRIBUTES.TEAM_SELECT}-2`}
								/>
							</FormGroup>
						</Col>
					</Row>
					{widgetModel.team2 && widgetModel.team2.id && widgetModel.team2.id.toString().length > 0 && (
						<Row>
							<Col>
								<FormGroup>
									<PlayerSeasonSelectComponent
										isClearable
										isRequired={true}
										isBlocky
										entityId={widgetModel.team2.id}
										selectedSeason={widgetModel.team2TournamentSeason}
										onEntitySeasonSelect={this.onTeam2SeasonsChange}
										isValid={isValid}
										t={t}
										entityType={ContentTypes.TEAM}
										id={`${SportTypes.FOOTBALL}-${SPORT_SELECT_ATTRIBUTES.PLAYER_SEASON_SELECT}-2`}
									/>
								</FormGroup>
							</Col>
						</Row>
					)}
					{widgetModel.team1TournamentSeason &&
						widgetModel.team2TournamentSeason &&
						widgetModel.team1TournamentSeason.seasonId !== '' &&
						widgetModel.team2TournamentSeason.seasonId !== '' && (
							<Row className='mt-2' data-qa='football-team-h2h-elements-select'>
								<Col>
									<FormGroup>
										<Label htmlFor={`${SportTypes.FOOTBALL}-${SPORT_SELECT_ATTRIBUTES.ELEMENTS}`}>{t('select_stats')}</Label>
										<ElementsSelect value={widgetModel.stats} onSelect={this.onStatsParametersChange} t={t} isMulti elements={teamH2HStatsOptions} />
									</FormGroup>
								</Col>
							</Row>
						)}
					<RefreshTimeWrapper value={this.state.widgetModel.refreshTime} onRefreshTimeChange={this.onRefreshTimeChange} />
				</div>
			</>
		);
	}
}
