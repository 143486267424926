import UserModel from './user.model';
import { WikiPagesAdvancedFilterJson } from './wiki-pages-advanced-filter.json';
import ContentAttributesModel from './content-attributes.model';
import WikiPagesAdvanceFilterBuilder from './wiki-pages-advance-filter.builder';
import RelatedModel from './related.model';

export default class WikiPagesAdvancedFilterModel {
	readonly searchText: string;
	readonly relatedSports: RelatedModel[];
	readonly relatedTags: RelatedModel[];
	readonly createdBy: UserModel;
	readonly date: string[];
	readonly tournament: RelatedModel;
	readonly season: RelatedModel;
	readonly sportsType: any;
	readonly type: ContentAttributesModel[];
	readonly status: string = '';

	private constructor(
		searchText: string,
		relatedSports: RelatedModel[],
		relatedTags: RelatedModel[],
		createdBy: UserModel,
		date: string[],
		tournament: RelatedModel,
		season: RelatedModel,
		sportsType: any,
		type: ContentAttributesModel[],
		status: string,
	) {
		this.searchText = searchText;
		this.relatedSports = relatedSports;
		this.relatedTags = relatedTags;
		this.createdBy = createdBy;
		this.date = date;
		this.tournament = tournament;
		this.season = season;
		this.sportsType = sportsType;
		this.type = type;
		this.status = status;
	}

	toJSON(): WikiPagesAdvancedFilterJson {
		return {
			searchText: this.searchText,
			relatedSports: this.relatedSports,
			relatedTags: this.relatedTags,
			createdBy: this.createdBy,
			date: this.date,
			tournament: this.tournament,
			season: this.season,
			sportsType: this.sportsType,
			type: this.type,
			status: this.status,
		};
	}

	static fromJSON(json: WikiPagesAdvancedFilterJson): WikiPagesAdvancedFilterModel {
		return new WikiPagesAdvancedFilterModel(
			json.searchText,
			json.relatedSports,
			json.relatedTags,
			json.createdBy,
			json.date,
			json.tournament,
			json.season,
			json.sportsType,
			json.type,
			json.status,
		);
	}

	static builder(content?: WikiPagesAdvancedFilterModel): WikiPagesAdvanceFilterBuilder {
		return new WikiPagesAdvanceFilterBuilder(content);
	}
}
