import React, { FunctionComponent, useEffect, useState } from 'react';
import { Label, Tooltip } from 'reactstrap';
import { DATA_QA_ATTRIBUTES } from '../../constants/data-qa.attributes';
import Select from 'react-select';
import ListModel from '../../../../../../models/v2/list/list';
import { listToOption, listsToOptions } from '../../helpers/list-select.helper';
import { getListDetailsAndPopulateWithStatistics, getListsContent } from '../../helpers/list-http.helper';
import { connect } from 'react-redux';
import { SelectMenuOptionType } from '../../../../../../models/v2/general/select.model';
import { ValueType } from 'react-select/src/types';
import { updateListInRedux } from '../../../../../../store/action-creators/ListActionCreators';
import { toggleShowListImages } from '../../../../../../store/action-creators/UiActionCreator';
import { isListEmpty } from '../../helpers/general.helper';

type Properties = {
	t: any;
	selectedList: ListModel | null;
	projectDomain: string;
	shouldShowListImages: boolean;
	profileId: string;
	updateListInRedux: (list: ListModel) => void;
	toggleShowListImages: (profileId: string) => void;
};

const ListSelect: FunctionComponent<Properties> = ({
	t,
	selectedList,
	projectDomain,
	updateListInRedux,
	shouldShowListImages,
	profileId,
	toggleShowListImages,
}) => {
	const [tooltipOpen, setTooltipOpen] = useState<boolean>(false);
	const [lists, setLists] = useState<ListModel[]>([]);

	useEffect(() => {
		getListsContent(projectDomain, setLists);
	}, []);

	const onChangeList = async (option: ValueType<SelectMenuOptionType>) => {
		// @ts-ignore -> because it doesn't found '.data' but its in the model :/
		const selectedListId = (option && option.value) || null;
		if (selectedListId) {
			const remappedListData = await getListDetailsAndPopulateWithStatistics(selectedListId, projectDomain);
			updateListInRedux(remappedListData);
		}
	};

	return (
		<>
			<div className='d-flex'>
				<Label htmlFor={DATA_QA_ATTRIBUTES.LIST_SELECT}>{t('select_list')}</Label>
				<div
					id={DATA_QA_ATTRIBUTES.LIST_SHOW_IMAGES}
					className='ml-auto'
					onClick={() => toggleShowListImages(profileId)}
					style={{ top: -23, right: 0 }}
				>
					<i className='fa fa-info-circle text-muted mr-1' />
					<i className={`fa fa-toggle-${shouldShowListImages ? 'on text-success' : 'off text-muted'}`} />
				</div>
				<Tooltip placement='left' isOpen={tooltipOpen} target={DATA_QA_ATTRIBUTES.LIST_SHOW_IMAGES} toggle={() => setTooltipOpen(!tooltipOpen)}>
					{t(`${shouldShowListImages ? 'show' : 'hide'}_images`)}
				</Tooltip>
			</div>
			<div className='mb-2'>
				<Select
					id={DATA_QA_ATTRIBUTES.LIST_SELECT}
					options={listsToOptions(lists)}
					onChange={onChangeList}
					value={selectedList && listToOption(selectedList)}
					placeholder={t('select')}
					noOptionsMessage={(inputValue) => inputValue && t('no_options')}
				/>
			</div>
		</>
	);
};

function mapStateToProps(state: any) {
	return {
		shouldShowListImages: state.ui.shouldShowListImages[state.profile.profile.id] || false,
		profileId: state.profile.profile.id || '',
		projectDomain: state.project.currentProject.domain || '',
		selectedList: (!isListEmpty(state.list.list) && state.list.list) || null,
	};
}

function mapDispatchToProps(dispatch: Function) {
	return {
		updateListInRedux: (list: ListModel) => dispatch(updateListInRedux(list)),
		toggleShowListImages: (profileId: string) => dispatch(toggleShowListImages(profileId)),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(ListSelect);
