import Feature from '../../models/feature/Feature';
import { FeatureTypes } from './features.enum';
import { store } from '../../store/store';
import { featuresService } from '../../App';

export default class FeatureService {
	/**
	 * Checks if a specific list of features are enabled in the project. This function does not check their "enabled" status
	 * @param features
	 */
	public areFeaturesEnabled = (features: FeatureTypes[]): boolean => {
		const featuresList = store.getState().project.currentProject.features;

		return this.checkEnabled(features, featuresList);
	};

	/**
	 * Retrieves the additional configuration passed to the data object in the feature
	 * @param featureId
	 */
	getFeatureConfig = (featureId: string) => {
		try {
			const features = store.getState().project.currentProject.features ? store.getState().project.currentProject.features : [];
			const item = features.find((feature: Feature) => feature.id === featureId);
			return item ? item.data : {};
		} catch (error) {
			return {};
		}
	};

	/**
	 * Checks if specific features are added and ACTIVATED in the specific project. If the feature exists but the
	 * "enabled" field is set to false "checkFeatureIsSetAndEnabled" will return "false". If the feature is not set at all
	 * "checkFeatureIsSetAndEnabled" will return "false"
	 * @param featureId
	 */
	checkFeatureIsSetAndEnabled = (featureId: string) => {
		const features: Feature[] = store.getState().project.currentProject.features ? store.getState().project.currentProject.features : [];

		const item = features.find((feature: Feature) => feature.id === featureId);

		return item !== undefined && item !== null && item.enabled;
	};

	/**
	 * TODO documentation is required for this function, as it's use case is not clear
	 * @param featureId
	 */
	checkFeatureDataField = (featureId: string) => {
		const features = store.getState().project.currentProject.features ? store.getState().project.currentProject.features : [];

		const featureSelected = features.find((feature: Feature) => feature.id === featureId);

		if (featureSelected.data !== undefined && featureSelected.data !== null) {
			return featureSelected.data;
		}

		return null;
	};

	/**
	 * Checks if the @{features} passed are enabled
	 * @param features
	 * @param featuresList
	 */
	public checkEnabled(features: FeatureTypes[], featuresList: Feature[]): boolean {
		const existingFeatures = this.extractFeatures(features, featuresList);

		if (existingFeatures.length === 1 && existingFeatures[0].id === FeatureTypes.FOOTBALL) {
			return existingFeatures[0].enabled;
		}

		if (existingFeatures.length > 0) {
			const numberOfEnabledFeatures = existingFeatures.filter((feature: Feature) => feature.enabled).length;
			const numberOfFeaturesToBeChecked = features.length;

			return numberOfEnabledFeatures === numberOfFeaturesToBeChecked;
		} else {
			return true;
		}
	}

	/**
	 * Extracts the searched features from the list of existing ones.
	 * If features or featuresList is null/undefined an empty array is returned
	 * @param features
	 * @param featuresList
	 */
	public extractFeatures(features: FeatureTypes[], featuresList: Feature[]): Feature[] {
		if (features && featuresList) {
			return featuresList.filter((feature: Feature) => features.includes(feature.id as FeatureTypes));
		} else {
			return [];
		}
	}

	/**
   +   * Extracts the admins array from the data object of the CONTENT_ANALYTICS feature
   +   * if such is found. If not,
   +   */
	extractAdminsFromContentAnalyticsFeature = () => {
		let featureConfig = this.getFeatureConfig(FeatureTypes.CONTENT_ANALYTICS);
		return featureConfig && featureConfig.admins && featureConfig.admins.length > 0 ? featureConfig.admins : [];
	};

	/**
   +   * Check if the current admin's id exists in the Content Analytics 'admins' prop array.
   +   */
	public adminIsAuthorizedForContentAnalytics() {
		let admins = this.extractAdminsFromContentAnalyticsFeature();
		return admins.includes(store.getState().project.profile.profile.id);
	}

	/**
   +   * Extracts the admins array from the data object of the CONTENT_ANALYTICS feature
   +   * if such is found. If not,
   +   */
	extractAdminsFromTournamentGroupsFeature = () => {
		let featureConfig = this.getFeatureConfig(FeatureTypes.MANUAL_DATA_ENTRY_TOURNAMENT_GROUPS);
		return featureConfig && featureConfig.admins && featureConfig.admins.length > 0 ? featureConfig.admins : [];
	};

	/**
   +   * Check if the current admin's id exists in the Content Analytics 'admins' prop array.
   +   */

	public adminIsAuthorizedForTournamentGroupsOrder() {
		let admins = this.extractAdminsFromTournamentGroupsFeature();
		return admins.includes(store.getState().project.profile.profile.id);
	}

	/**
   +   * Extracts the undo/redo steps from the data object of the CONTENT_BLOCKY_UNDO_REDO feature
   +   * if such is found. If not -> set default steps */
	public extractUndoRedoStepsFromBlockyUndoRedoFeature(): number {
		let featureConfig = this.getFeatureConfig(FeatureTypes.CONTENT_BLOCKY_UNDO_REDO);
		return featureConfig && featureConfig.maximumSteps ? +featureConfig.maximumSteps : 5;
	}

	/**
   +   * Extracts the undo/redo pages from the data object of the CONTENT_BLOCKY_UNDO_REDO feature
   +   * if such is found. If not -> set no pages */
	public checkAvailableUndoRedoPageFromFeature(page: string): boolean {
		try {
			let featureConfig = this.getFeatureConfig(FeatureTypes.CONTENT_BLOCKY_UNDO_REDO);
			if (featureConfig && featureConfig.pages && featureConfig.pages.length > 0) {
				return featureConfig.pages.includes(page);
			}
			return false;
		} catch {
			return false;
		}
	}

	public checkIfFUManagerIsEnabled(manager: string) {
		const fansUnitedFeatureConfig = featuresService.getFeatureConfig(FeatureTypes.FANS_UNITED);

		switch (manager) {
			case 'top-x':
				return (
					featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.FANS_UNITED) &&
					fansUnitedFeatureConfig.hasOwnProperty('managers') &&
					fansUnitedFeatureConfig.managers.hasOwnProperty('top_x') &&
					fansUnitedFeatureConfig.managers.top_x
				);
			case 'match-quiz':
				return (
					featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.FANS_UNITED) &&
					fansUnitedFeatureConfig.hasOwnProperty('managers') &&
					fansUnitedFeatureConfig.managers.hasOwnProperty('match_quiz') &&
					fansUnitedFeatureConfig.managers.match_quiz
				);
			case 'leaderboard':
				return (
					featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.FANS_UNITED) &&
					fansUnitedFeatureConfig.hasOwnProperty('managers') &&
					fansUnitedFeatureConfig.managers.hasOwnProperty('leaderboard') &&
					fansUnitedFeatureConfig.managers.leaderboard
				);
			case 'classic-quiz':
				return (
					featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.FANS_UNITED) &&
					fansUnitedFeatureConfig.hasOwnProperty('managers') &&
					fansUnitedFeatureConfig.managers.hasOwnProperty('classic_quiz') &&
					fansUnitedFeatureConfig.managers.classic_quiz
				);
			case 'either-or':
				return (
					featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.FANS_UNITED) &&
					fansUnitedFeatureConfig.hasOwnProperty('managers') &&
					fansUnitedFeatureConfig.managers.hasOwnProperty('either_or') &&
					fansUnitedFeatureConfig.managers.either_or
				);
			case 'discussions':
				return (
					featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.FANS_UNITED) &&
					fansUnitedFeatureConfig.hasOwnProperty('managers') &&
					fansUnitedFeatureConfig.managers.hasOwnProperty('discussions') &&
					fansUnitedFeatureConfig.managers.discussions
				);
		}
	}
}
