import React, { FunctionComponent } from 'react';
import AddedElementsWrapper from './subcomponents/added-elements-sortable/added-elements-sortable';
import { DATA_QA_ATTRIBUTES } from './constants/data-qa.attributes';
import RelatedContentInsideTabs from './subcomponents/tabs/tabs';
import './related-content.scss';

type Properties = {
	t: any;
};

const SidebarRelatedContent: FunctionComponent<Properties> = ({ t }) => {
	return (
		<div id={DATA_QA_ATTRIBUTES.RELATED_CONTENT_CONTAINER}>
			<AddedElementsWrapper t={t} />
			<RelatedContentInsideTabs t={t} />
		</div>
	);
};

export default SidebarRelatedContent;
