export enum DATA_QA_ATTRIBUTES {
	SEO_WRAPPER = 'sidebar-seo-wrapper',
	TITLE = 'sidebar-seo-title-input',
	COPY_FROM_TITLE = 'sidebar-seo-copy-title-checkbox',
	DESCRIPTION = 'sidebar-seo-description-input',
	KEYWORDS = 'sidebar-seo-keywords-input',
	SLUG = 'sidebar-seo-slug-input',
	COPY_FROM_SEO_TITLE = 'sidebar-seo-copy-from-seo-title-input',
	INDEXING = 'sidebar-seo-indexing-checkbox',
	FOLLOWING = 'sidebar-seo-following-input',
	JSON_LD = 'sidebar-seo-jsonld-input',
}
