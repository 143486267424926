import * as React from 'react';
import { useEffect, useState } from 'react';
import { multiLingualService } from '../../../../App';
import WikiPagesAdvancedFilterModel from './models/wiki-pages-advanced-filter.model';
import {
	checkIfTheWikiPageFiltersAreEmpty,
	constructWikiPageSearchContentQuery,
	generateFiltersMdClass,
} from './helpers/wiki-pages-advanced-content-filter.helper';
import { Subject } from 'rxjs';
import { Button, Col, Label, Row } from 'reactstrap';
import ConditionalRenderWrapper from '../../BaseComponents/ConditionalRenderWraper/ConditionalRenderWrapper';
import WikiPagesAdvancedFiltersFieldsComponent from './wiki-pages-advanced-filters-fields.component';
import DateTimePickerContainer from '../../Sidebar/GeneralContentAttributes/subcomponents/DateTimePicker/DateTimePickerContainer';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../../store/store';
import { returnObjectForWikiFiltersUpdate } from '../../../../store/action-creators/WikiActionCreator';

type Properties = {
	t: any;
	updateWikiPagesAdvancedSearchText: (input: string) => void;
	searchContent: any;
	filtersInSidebar: boolean;
	getAllContent: any;
	contentSearchInput: string;
	calledFrom?: string;
	contentType?: string;
	saveFiltersHistory?: boolean;
};

const WikiPagesAdvancedContentFilter: React.FunctionComponent<Properties> = (props) => {
	const dispatch = useDispatch();
	const clearService = new Subject<void>();
	const wikipageTypes = useSelector((state: AppState) => state.types.wikipageTypes);
	const profileId = useSelector((state: AppState) => state.profile.profile.id);
	const project = useSelector((state: AppState) => state.project.currentProject);
	const wikiPagesFilters = useSelector((state: AppState) => state.wikiPages.filters);
	const statuses = useSelector((state: AppState) => state.statuses);
	const sports = useSelector((state: AppState) => state.sports.sportsTypes);

	const langCode = multiLingualService.checkIfProjectIsMultiLingual(project.languages)
		? project.languages.defaultLanguageCode.languageCode
		: project.language;

	const [toggleAdvancedFilters, setToggleAdvanceFilter] = useState<boolean>(false);
	const [wikiPagesAdvancedContentModel, setWikiPagesAdvancedContentModel] = useState<WikiPagesAdvancedFilterModel>(
		wikiPagesFilters ? wikiPagesFilters : WikiPagesAdvancedFilterModel.builder().build(),
	);

	useEffect(() => {
		const wikiPagesAdvancedContentSearchText = constructWikiPageSearchContentQuery(wikiPagesAdvancedContentModel);
		props.updateWikiPagesAdvancedSearchText(wikiPagesAdvancedContentSearchText);
		const areFiltersEmpty = checkIfTheWikiPageFiltersAreEmpty(wikiPagesFilters);
		!areFiltersEmpty && toggleAdvanceFilters();
	}, []);

	useEffect(() => {
		setWikiPagesAdvancedContentModel(WikiPagesAdvancedFilterModel.builder().build());
	}, [project]);

	const onDateSelect = (dates: string[]) => {
		const advancedContentModel = WikiPagesAdvancedFilterModel.builder(wikiPagesAdvancedContentModel).withDate(dates).build();
		updateWikiPagesAdvancedSearchText(advancedContentModel);
	};

	const updateWikiPagesAdvancedSearchText = (advancedContentModel: WikiPagesAdvancedFilterModel) => {
		const advancedContentSearchText = constructWikiPageSearchContentQuery(advancedContentModel);

		props.updateWikiPagesAdvancedSearchText(advancedContentSearchText);
		props.saveFiltersHistory !== false &&
			// Update filters in the store
			dispatch(
				returnObjectForWikiFiltersUpdate(
					WikiPagesAdvancedFilterModel.builder(advancedContentModel)
						.withSearchText(props.contentSearchInput)
						.withSportsType(advancedContentModel.sportsType)
						.build(),
				),
			);

		setWikiPagesAdvancedContentModel(advancedContentModel);
		if (
			props.contentSearchInput.match(/^ *$/) !== null &&
			advancedContentSearchText.match(/^ *$/) !== null &&
			!advancedContentModel.sportsType
		) {
			props.getAllContent();
		}
	};

	const clearAdvancedFilters = () => {
		clearService.next();
		updateWikiPagesAdvancedSearchText(WikiPagesAdvancedFilterModel.builder().build());
	};

	const toggleAdvanceFilters = () => {
		setToggleAdvanceFilter(!toggleAdvancedFilters);
	};

	const { t, filtersInSidebar, calledFrom } = props;
	const showClearFiltersButton = checkIfTheWikiPageFiltersAreEmpty(wikiPagesAdvancedContentModel);
	return (
		<>
			<Row>
				<Col className='d-flex'>
					<button className='btn btn-link ml-auto' onClick={toggleAdvanceFilters}>
						{toggleAdvancedFilters ? `${t('hide_advanced_filters')}` : `${t('show_advanced_filters')}`}
					</button>
					{!showClearFiltersButton && (
						<button className='btn btn-link text-danger' onClick={clearAdvancedFilters}>
							{t('clear_filters')}
						</button>
					)}
				</Col>
			</Row>
			{toggleAdvancedFilters && (
				<ConditionalRenderWrapper expectedPermissions={['read_admins']}>
					<Row>
						<WikiPagesAdvancedFiltersFieldsComponent
							currentProject={project}
							t={t}
							updateWikiPagesAdvancedSearchText={updateWikiPagesAdvancedSearchText}
							profileId={profileId}
							filtersInSidebar={filtersInSidebar}
							wikiPageAdvancedFilterModel={wikiPagesAdvancedContentModel}
							wikipageTypes={wikipageTypes}
							contentLanguage={langCode}
							statuses={statuses}
							sports={sports}
						/>
						<Col col='6' sm='12' md={generateFiltersMdClass(filtersInSidebar)} className={`${filtersInSidebar ? 'mb-2' : 'mb-0'}`}>
							<Label htmlFor='date'>{t('date_from_to')}</Label>
							<DateTimePickerContainer
								id={`wiki-pages-advanced-filter-date-${calledFrom ? calledFrom : ''}`}
								date={wikiPagesAdvancedContentModel.date}
								dateFormat='Y.m.d'
								resetButton
								clearService={clearService}
								t={t}
								mode='range'
								enableTime={false}
								onDateChange={onDateSelect}
							/>
						</Col>
					</Row>
					<Row className='mt-3 mb-3'>
						<Col>
							<Button type='button' color='primary' id='wiki-pages-advanced-filters-search-button' onClick={props.searchContent}>
								<i className={'fa fa-search'}>&nbsp;</i>
								{t('search')}
							</Button>
							<Button
								id='wiki-pages-advanced-filters-clear-button'
								type='button'
								color='white'
								className='btn btn-outline-danger ld-ext-right px-2 ml-2'
								onClick={clearAdvancedFilters}
							>
								{t('clear_filters')}
							</Button>
						</Col>
					</Row>
				</ConditionalRenderWrapper>
			)}
		</>
	);
};

export default WikiPagesAdvancedContentFilter;
