import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SortEnd } from 'react-sortable-hoc';
import { toast } from 'react-toastify';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Author from '../../../../models/author/Author';
import HttpService from '../../../../services/rest/HttpService';
import { returnObjectForAuthorListingRequest, returnObjectForAuthorSearch } from '../../../../store/action-creators/AuthorActionCreators';
import { AppState } from '../../../../store/store';

type ModalState = { isShown: boolean; data: { authors: Author[]; shouldUpdate: boolean } };

type Properties = {
	showModal: ModalState;
	t: any;
	toggleModal: (showModal: ModalState) => void;
	sortEnd: SortEnd;
};

const AuthorsReorderModal: React.FunctionComponent<Properties> = ({ t, showModal, toggleModal, sortEnd }) => {
	const [prevAuthors, setAuthors] = useState<Author[]>([]);
	const { oldIndex, newIndex } = sortEnd;

	const currentPage = useSelector((state: AppState) => state.author.pagination.currentPage);
	const project = useSelector((state: AppState) => state.project.currentProject);
	const dispatch = useDispatch();

	useEffect(() => {
		showModal.isShown && setAuthors([...showModal.data.authors]);
	}, []);

	const toggleModalState = (shouldUpdate: boolean) =>
		toggleModal({ isShown: false, data: { authors: prevAuthors, shouldUpdate: shouldUpdate } });

	const updateAuthors = () => {
		// Define previous item index depending on drag direction.
		const prevItemIndex = newIndex !== 0 && newIndex < oldIndex ? newIndex - 1 : newIndex;
		const params = { previous_id: newIndex !== 0 ? prevAuthors[prevItemIndex].id : null };

		HttpService.patch(`/authors/${prevAuthors[oldIndex].id}`, params, { Project: project.domain })
			.then(() => {
				// Close the modal and get the updated author.
				dispatch(returnObjectForAuthorListingRequest(currentPage, project, ''));
				toggleModalState(false);
			})
			.catch((e: any) => {
				e.response.status === 403 && toast.error(t('authors_permissions_error'));
			});
	};

	return (
		<Modal isOpen={showModal.isShown}>
			{/* Close the modal and set the prevAuthors in state. */}
			<ModalHeader className='pl-3 pr-3 pt-2 pb-2 bg-info' toggle={() => toggleModalState(true)}>
				{t('rearrange_authors')}
			</ModalHeader>
			<ModalBody className='pb-2'>
				{prevAuthors[oldIndex] && (
					<>
						<div className='text-center'>{t('rearrange_authors_body')}</div>
						<div className='pt-2 font-weight-bold text-center text-truncate'>{prevAuthors[oldIndex].name}</div>
					</>
				)}
			</ModalBody>
			<ModalFooter className='border-0'>
				{/* Close the modal and set the prevAuthor in state. */}
				<Button id='modal-authors-cancel-button' color='base' onClick={() => toggleModalState(true)} className='ml-auto'>
					{t('close')}
				</Button>
				<Button id='modal-authors-confirm-button' color='info' onClick={updateAuthors}>
					{t('confirm')}
				</Button>
			</ModalFooter>
		</Modal>
	);
};

export default AuthorsReorderModal;
