import { MatchDetailsJson } from './match-details.json';
import MatchDetailsBuilder from './match-details.builder';
import EnumItem from '../../../../../models/enum/EnumItem';
import TeamModel from '../../../../Partials/Blocky/partials/team-select/models/team.model';
import RoundModel from '../../../../Partials/Blocky/blocks/widgets/fixtures/models/round/round.model';
import MatchSeasonModel from './match-season.model';
import MatchScoreModel from './match-score.model';
import BlacklistModel from '../../../../../models/blacklist/blacklist';

export default class MatchDetailsModel {
	readonly id: string;
	readonly type: string;
	readonly minutes: number;
	readonly eventStatus: { id: string; name: string; type: string; code: string };
	readonly startTime: string;
	readonly finishedAt: string;
	readonly homeTeam: TeamModel | null;
	readonly awayTeam: TeamModel | null;
	readonly venue: EnumItem | null;
	readonly referees: EnumItem[] | null;
	readonly stage: EnumItem | null;
	readonly round: RoundModel | null;
	readonly coverage: string;
	readonly group: EnumItem | null;
	readonly kickoffTime: string;
	readonly score: MatchScoreModel | null;
	readonly spectators: number | null;
	readonly season: MatchSeasonModel | null;
	readonly blacklist: BlacklistModel;

	private constructor(
		id: string,
		type: string,
		minutes: number,
		eventStatus: { id: string; name: string; type: string; code: string },
		startTime: string,
		finishedAt: string,
		homeTeam: TeamModel | null,
		awayTeam: TeamModel | null,
		venue: EnumItem | null,
		referees: EnumItem[] | null,
		stage: EnumItem | null,
		round: RoundModel | null,
		coverage: string,
		group: EnumItem | null,
		kickoffTime: string,
		score: MatchScoreModel | null,
		spectators: number | null,
		season: MatchSeasonModel | null,
		blacklist: BlacklistModel,
	) {
		this.id = id;
		this.type = type;
		this.minutes = minutes;
		this.eventStatus = eventStatus;
		this.startTime = startTime;
		this.finishedAt = finishedAt;
		this.homeTeam = homeTeam;
		this.awayTeam = awayTeam;
		this.venue = venue;
		this.referees = referees;
		this.stage = stage;
		this.round = round;
		this.coverage = coverage;
		this.group = group;
		this.kickoffTime = kickoffTime;
		this.score = score;
		this.spectators = spectators;
		this.season = season;
		this.blacklist = blacklist;
	}

	toJSON(): MatchDetailsJson {
		return {
			id: this.id,
			type: this.type,
			minutes: this.minutes,
			eventStatus: this.eventStatus,
			startTime: this.startTime,
			finishedAt: this.finishedAt,
			homeTeam: this.homeTeam,
			awayTeam: this.awayTeam,
			venue: this.venue,
			referees: this.referees,
			stage: this.stage,
			round: this.round,
			coverage: this.coverage,
			group: this.group,
			kickoffTime: this.kickoffTime,
			score: this.score,
			spectators: this.spectators,
			season: this.season,
			blacklist: this.blacklist,
		};
	}

	static fromJSON(json: MatchDetailsJson): MatchDetailsModel {
		return new MatchDetailsModel(
			json.id,
			json.type,
			json.minutes,
			json.eventStatus,
			json.startTime,
			json.finishedAt,
			json.homeTeam,
			json.awayTeam,
			json.venue,
			json.referees,
			json.stage,
			json.round,
			json.coverage,
			json.group,
			json.kickoffTime,
			json.score,
			json.spectators,
			json.season,
			json.blacklist,
		);
	}

	static builder(match?: MatchDetailsModel): MatchDetailsBuilder {
		return new MatchDetailsBuilder(match);
	}
}
