export enum ReduxURLsProps {
	TITLE = 'title',
	AUTO_TITLE = 'autoTitle',
	AUTO_SLUG = 'autoSlug',
	DESCRIPTION = 'description',
	SLUG = 'slug',
	KEYWORDS = 'keywords',
	INDEX = 'index',
	FOLLOW = 'follow',
	JSON_LD = 'jsonld',
}
