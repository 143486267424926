import * as React from 'react';
import { SortableHandle } from 'react-sortable-hoc';
import { sidebarIcons } from '../../../../../constants/content-types';
import CustomCollapsibleElement from '../../../BaseComponents/CustomCollapsibleElement/CustomCollapsibleElement';

type SortableItemProps = {
	elementKey: string;
	sequence: number;
	status: string;
	t: any;
	isSidebarInEdit: boolean;
	onChangeCollapseStatus: (settings: any[]) => void;
	sidebarSettings: any;
	contentType: string;
};

const DragHandle = SortableHandle(() => (
	<span className='cursor-draggable mr-3 text-muted'>
		<i className='animated fadeIn fa fa-bars' />
	</span>
));

class SidebarSortableElement extends React.Component<SortableItemProps> {
	render() {
		const { sequence, status, elementKey, t, children, isSidebarInEdit, onChangeCollapseStatus, sidebarSettings, contentType } = this.props;

		return (
			<CustomCollapsibleElement
				contentType={contentType}
				sidebarSettings={sidebarSettings}
				onChangeCollapseStatus={onChangeCollapseStatus}
				sequence={sequence}
				drag={isSidebarInEdit ? <DragHandle /> : null}
				key={`collapsible-sidebar-element-${sequence}`}
				status={status}
				title={t(elementKey)}
				isSidebarInEdit={isSidebarInEdit}
				iconClass={sidebarIcons[elementKey]}
			>
				{children}
			</CustomCollapsibleElement>
		);
	}
}

export default SidebarSortableElement;
