import * as React from 'react';
import { FormGroup } from 'reactstrap';
import ErrorAsync from '../../BaseComponents/ErrorComponent/ErrorAsync';
import { DebounceInput } from 'react-debounce-input';

type NameProps = {
	t: any;
	onChange: any;
	value?: string;
};

export const Name: React.FunctionComponent<NameProps> = ({ onChange, t, value }) => {
	return (
		<FormGroup>
			<DebounceInput
				type='text'
				id='name'
				value={value ? value : ''}
				onChange={(event: any) => onChange(event.target.value)}
				debounceTimeout={300}
				className='form-control-lg form-control'
				placeholder={t('name_placeholder')}
				required
			/>
			<ErrorAsync t={t} errorType='name' />
			<ErrorAsync t={t} errorType='full_name' />
		</FormGroup>
	);
};
