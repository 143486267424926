import React, { FunctionComponent, useEffect, useState } from 'react';
import { Button, Col, Label, Row } from 'reactstrap';
import LiveBlogModel from '../../../models/live-blog.model';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../../../store/store';
import { isArrayEmpty } from '../../../../../../global-helpers/global.helpers';
import { DATA_QA_ATTRIBUTES } from '../../../../../Partials/Sidebar/general-content/constants/data-qa.attributes';
import '../../../style/live-blog.style.scss';
import LiveBlogCollaboratorsModel from '../../../models/live-blog-collaborators.model';
import { emptyCollaboratorRowAvailable, findIsCollaboratorAlreadySelected, removeCollaboratorById } from './collaborators.helper';
import { CollaboratorsModal } from './collaborator-modal.component';
import './styles/collaborators.style.scss';
import { CollaboratorInfoCard } from './collaborator-info-card.component';

type Properties = {
	t: any;
	liveBlog: LiveBlogModel;
	setLiveBlogCollaborators: (collaborators: LiveBlogCollaboratorsModel[]) => void;
};

export const LiveBlogCollaboratorsSelect: FunctionComponent<Properties> = ({ t, liveBlog, setLiveBlogCollaborators }) => {
	const availableAuthors = useSelector((state: AppState) => state.author.authors);
	const [toggle, setToggle] = useState<boolean>(false);
	const [selectedCollaboratorForEdit, setSelectedCollaboratorForEdit] = useState<LiveBlogCollaboratorsModel>(
		{} as LiveBlogCollaboratorsModel,
	);

	const handleAddCollaboratorRow = (collaborator: LiveBlogCollaboratorsModel) => {
		if (!emptyCollaboratorRowAvailable(liveBlog.collaborators)) {
			const newCollaboratorArr = [...liveBlog.collaborators, collaborator].reverse();
			setLiveBlogCollaborators(newCollaboratorArr);
		}
	};

	const handleDeleteRow = (collaboratorId: string) => {
		const newCollaboratorArr = removeCollaboratorById(liveBlog.collaborators, collaboratorId);
		setLiveBlogCollaborators(newCollaboratorArr);
	};

	const onLiveBlogCollaboratorSelect = (collaborator: LiveBlogCollaboratorsModel) => {
		let isAlreadyExist = findIsCollaboratorAlreadySelected(liveBlog.collaborators, collaborator);

		if (isAlreadyExist) {
			const array = liveBlog.collaborators.filter((element: LiveBlogCollaboratorsModel) => {
				return element.author.id !== collaborator.author.id;
			});
			const newCollaboratorArr = [...array, collaborator].reverse();
			setLiveBlogCollaborators(newCollaboratorArr);
			setSelectedCollaboratorForEdit({} as LiveBlogCollaboratorsModel);
		} else {
			handleAddCollaboratorRow(collaborator);
			setSelectedCollaboratorForEdit({} as LiveBlogCollaboratorsModel);
		}
	};

	const toggleModal = () => {
		setToggle(!toggle);
	};

	const selectCollaboratorForEdit = (collaborator: LiveBlogCollaboratorsModel, newCollaborator: boolean = true) => {
		newCollaborator ? setSelectedCollaboratorForEdit({} as LiveBlogCollaboratorsModel) : setSelectedCollaboratorForEdit(collaborator);
	};

	const removeSelectedCollaborator = () => {
		setSelectedCollaboratorForEdit({} as LiveBlogCollaboratorsModel);
	};

	return isArrayEmpty(availableAuthors) ? null : (
		<>
			<Row>
				<Col>
					<Label htmlFor={DATA_QA_ATTRIBUTES.LIVE_BLOG_COLLABORATORS}>
						<strong>{t('collaborators')}</strong>
					</Label>
				</Col>
			</Row>
			{liveBlog.collaborators &&
				liveBlog.collaborators.length > 0 &&
				liveBlog.collaborators.map((collaborator: LiveBlogCollaboratorsModel, index: number) => (
					<div className='card collaborators-list' key={DATA_QA_ATTRIBUTES.LIVE_BLOG_COLLABORATORS + index}>
						<CollaboratorInfoCard
							t={t}
							collaborator={collaborator}
							setToggle={setToggle}
							handleDeleteRow={handleDeleteRow}
							index={index}
							setSelectedCollaboratorForEdit={selectCollaboratorForEdit}
						/>
					</div>
				))}
			{toggle && (
				<CollaboratorsModal
					toggleModal={setToggle}
					t={t}
					isOpen={toggle}
					collaboratorOptions={availableAuthors}
					pickNewCollaborator={onLiveBlogCollaboratorSelect}
					initialAdd={liveBlog.collaborators.length < 1}
					selectedCollaboratorForEdit={selectedCollaboratorForEdit}
					removeSelectedCollaborator={removeSelectedCollaborator}
				/>
			)}
			<Row>
				<Button className='ml-1' color='primary' outline onClick={toggleModal}>
					<i className='fa fa-plus mx-1' />
					{t('add_collaborator')}
				</Button>
			</Row>
		</>
	);
};
