import React, { Component } from 'react';
import { Trans } from 'react-i18next';
import { Button, Dropdown } from 'reactstrap';
import ButtonDropdown from 'reactstrap/es/ButtonDropdown';
import DropdownItem from 'reactstrap/es/DropdownItem';
import DropdownMenu from 'reactstrap/es/DropdownMenu';
import DropdownToggle from 'reactstrap/es/DropdownToggle';
import { getSportIcon } from '../../../../../../global-helpers/global-icons.helpers';
import { getEntityDomainMedia, getEntityMedia, remapAdditionalTags } from './helpers/drop-down-entity-tag.component.helpers';
import i18n from 'i18next';

const ADD_ALL_ICON = require('./icons/add-all.svg');

export default class DropdownEntityTag extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isOpen: false,
		};
		this.firstOccurrenceRef = React.createRef();
	}

	toggle = (event) => {
		if (this.firstOccurrenceRef.current.contains(event.target)) {
			return;
		}

		this.setState({ isOpen: !this.state.isOpen });
	};

	insertTag = (tag, firstOnly) => {
		tag['firstOnly'] = firstOnly;
		this.props.onTagInsert(tag);
	};

	render() {
		const { tag, t } = this.props;
		const { isOpen } = this.state;

		return (
			<ButtonDropdown size='m' className={'mr-3 margin-b-1'}>
				<Button
					innerRef={this.firstOccurrenceRef}
					className='first-occurrence'
					outline
					color='secondary'
					onClick={() => {
						this.setState({ isOpen: !this.state.isOpen });
					}}
				>
					{tag ? tag.text : ''}
				</Button>
				{tag && tag.more && tag.more > 1 && (
					<Dropdown isOpen={isOpen} toggle={this.toggle} setActiveFromChild>
						<DropdownToggle caret color='primary' className='more-occurrence-dropdown'>
							{tag.more}
						</DropdownToggle>
						<DropdownMenu>
							{tag.moreTags &&
								tag.moreTags.length > 1 &&
								tag.moreTags.map((tagAdd, index) => {
									const isCustomEntity = tagAdd.hasOwnProperty('contained_in_domain');
									return (
										<DropdownItem
											className='additional-tags-dropdown-item'
											key={index}
											onClick={() => {
												this.insertTag(remapAdditionalTags(tagAdd, tag), false);
											}}
										>
											<div
												className={'option-logo option-logo-' + tagAdd.entity_type}
												style={{ backgroundImage: `url("${getEntityMedia(tagAdd)}")` }}
											/>
											<div className='additional-tags-dropdown-item-name'>{tagAdd.name}</div>
											<div className='additional-tags-dropdown-item-secondary-info'>
												<div className='additional-tags-dropdown-item-sport'>
													{!isCustomEntity && (
														<>
															<div
																className='additional-tags-dropdown-item-sport-icon'
																style={{ backgroundImage: `url("${getSportIcon(tagAdd.sport)}")` }}
															/>
															{t(tagAdd.sport)}
														</>
													)}
													{isCustomEntity && (
														<div
															className='additional-tags-dropdown-item-sport-icon'
															style={{ backgroundImage: `url("${getEntityDomainMedia(tagAdd)}")` }}
														/>
													)}
												</div>
												{isCustomEntity && tagAdd.contained_in_domain && <div>{i18n.t(tagAdd.contained_in_domain.name)}</div>}
												{tagAdd.entity_type && isCustomEntity && (
													<>
														<div>/</div>
														<div>{i18n.t(tagAdd.entity_type)}</div>
													</>
												)}
												{tagAdd.country && !isCustomEntity && (
													<>
														<div>/</div>
														<div>{tagAdd.country.name}</div>
													</>
												)}
												{tagAdd.gender && (
													<>
														<div>/</div>
														<div>{t(tagAdd.gender.toLowerCase())}</div>
													</>
												)}
											</div>
										</DropdownItem>
									);
								})}
						</DropdownMenu>
					</Dropdown>
				)}
				{tag && tag.more && tag.more === 1 && (
					<Dropdown isOpen={isOpen} toggle={this.toggle} setActiveFromChild>
						<DropdownToggle className='insert-occurrence-dropdown' outline caret color='secondary' />
						<DropdownMenu>
							<DropdownItem
								className='additional-options-dropdown-item'
								key='dropdown-item-all-occurrences'
								onClick={() => {
									this.insertTag(tag, false);
								}}
							>
								<div className='custom-icon' style={{ backgroundImage: `url(${ADD_ALL_ICON})` }} />
								<Trans i18nKey='insert_all_occurrences_of' values={{ tagName: tag.name }} />
							</DropdownItem>
							<DropdownItem
								className='additional-options-dropdown-item'
								key='dropdown-item-first-occurrence'
								onClick={() => {
									this.insertTag(tag, true);
								}}
							>
								<i className='fa fa-circle-plus' />
								<Trans i18nKey='insert_first_occurrence_of' values={{ tagName: tag.name }} />
							</DropdownItem>
						</DropdownMenu>
					</Dropdown>
				)}
			</ButtonDropdown>
		);
	}
}
