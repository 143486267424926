import * as React from 'react';
import Select from 'react-select';
import { Properties } from './properties/content-attributes.properties';
import { attributesToOptions, optionsToAttributes, attributeToOption, optionToAttribute } from './helpers/content-attributes.helpers';
import { FormGroup, Col, Row, Label } from 'reactstrap';

const ContentAttributesSelectContainer: React.FunctionComponent<Properties> = (props) => {
	const {
		contentAttributes,
		selectedAttributes,
		isClearable,
		placeholder,
		type,
		onContentAttributesSelect,
		isMulti,
		label,
		inputId,
	} = props;

	const select = (selection: any) => (isMulti ? optionsToAttributes(selection) : optionToAttribute(selection));
	const selected = (selectedAttributes: any) =>
		isMulti ? attributesToOptions(selectedAttributes, isMulti) : attributeToOption(selectedAttributes, isMulti);
	const onSelect = (selection: any) => onContentAttributesSelect(select(selection), type);

	return (
		<>
			{contentAttributes && contentAttributes.length > 0 && (
				<Row>
					<Col>
						<Label htmlFor={`formgroup-select-${type}-key`}>{label}</Label>
						<FormGroup key={`formgroup-select-${type}-key`}>
							<Select
								inputId={inputId}
								options={attributesToOptions(contentAttributes, true)}
								onChange={onSelect}
								isMulti={isMulti}
								value={selected(selectedAttributes)}
								placeholder={placeholder}
								isClearable={isClearable}
							/>
						</FormGroup>
					</Col>
				</Row>
			)}
		</>
	);
};

export default ContentAttributesSelectContainer;
