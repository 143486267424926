import axios from 'axios';

export default class AmgVideosHttpService {
	static instance = () => {
		return axios.create({
			baseURL:
				'https://livescore-cm.streamamg.com/api/v1/23295533-cfd0-47a7-968d-e8aeed90509c/gJnoWSCqjOpHG6UB3etF7S2n6CnafhFohfOeYns2RtP4FPXZ8R/en/feed/e206180f-789c-4e11-949c-a06194dbdfe6',
			timeout: 30000,
		});
	};

	static getVideos = (section: string, pageIndex: string, pageSize: string) => {
		return AmgVideosHttpService.instance().get(`/sections?section=${section}&pageIndex=${pageIndex}&pageSize=${pageSize}`);
	};
}
