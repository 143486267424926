import React from 'react';
import Select from 'react-select';
import { selectedItemsToOptions } from '../championship-filter-select/helpers/championship-filter-select.helper';
import { statisticsColumns, statisticsColumnsKeepers } from './helpers/statistics-columns.helpers';

type Properties = {
	onStatisticsColumnsSelect: any;
	value: any[];
	t: any;
	isMulti: boolean;
	playerPosition: string;
};

const StatisticsColumnsSelect: React.FunctionComponent<Properties> = ({ value, t, onStatisticsColumnsSelect, playerPosition }) => {
	const statisticsColumnsSelect = (selections: any) => {
		if (onStatisticsColumnsSelect) {
			onStatisticsColumnsSelect(selections);
		}
	};

	const statisticsColumnsPlayer = playerPosition && playerPosition === 'keeper' ? statisticsColumnsKeepers : statisticsColumns;

	return (
		<>
			<Select
				isMulti
				options={statisticsColumnsPlayer(t)}
				onChange={statisticsColumnsSelect}
				value={selectedItemsToOptions(value)}
				noOptionsMessage={(inputValue) => inputValue && t('no_options')}
			/>
		</>
	);
};

export default StatisticsColumnsSelect;
