export const gringingFace = {
	codes: '1F600',
	character: '😀',
	title: 'grinning face',
	category: 'Smileys & Emotion (face-smiling)',
	group: 'Smileys & Emotion',
	subgroup: 'face-smiling',
};
export const emojiList = [
	gringingFace,
	{
		codes: '1F603',
		character: '😃',
		title: 'grinning face with big eyes',
		category: 'Smileys & Emotion (face-smiling)',
		group: 'Smileys & Emotion',
		subgroup: 'face-smiling',
	},
	{
		codes: '1F604',
		character: '😄',
		title: 'grinning face with smiling eyes',
		category: 'Smileys & Emotion (face-smiling)',
		group: 'Smileys & Emotion',
		subgroup: 'face-smiling',
	},
	{
		codes: '1F601',
		character: '😁',
		title: 'beaming face with smiling eyes',
		category: 'Smileys & Emotion (face-smiling)',
		group: 'Smileys & Emotion',
		subgroup: 'face-smiling',
	},
	{
		codes: '1F606',
		character: '😆',
		title: 'grinning squinting face',
		category: 'Smileys & Emotion (face-smiling)',
		group: 'Smileys & Emotion',
		subgroup: 'face-smiling',
	},
	{
		codes: '1F605',
		character: '😅',
		title: 'grinning face with sweat',
		category: 'Smileys & Emotion (face-smiling)',
		group: 'Smileys & Emotion',
		subgroup: 'face-smiling',
	},
	{
		codes: '1F923',
		character: '🤣',
		title: 'rolling on the floor laughing',
		category: 'Smileys & Emotion (face-smiling)',
		group: 'Smileys & Emotion',
		subgroup: 'face-smiling',
	},
	{
		codes: '1F602',
		character: '😂',
		title: 'face with tears of joy',
		category: 'Smileys & Emotion (face-smiling)',
		group: 'Smileys & Emotion',
		subgroup: 'face-smiling',
	},
	{
		codes: '1F642',
		character: '🙂',
		title: 'slightly smiling face',
		category: 'Smileys & Emotion (face-smiling)',
		group: 'Smileys & Emotion',
		subgroup: 'face-smiling',
	},
	{
		codes: '1F643',
		character: '🙃',
		title: 'upside-down face',
		category: 'Smileys & Emotion (face-smiling)',
		group: 'Smileys & Emotion',
		subgroup: 'face-smiling',
	},
	{
		codes: '1F609',
		character: '😉',
		title: 'winking face',
		category: 'Smileys & Emotion (face-smiling)',
		group: 'Smileys & Emotion',
		subgroup: 'face-smiling',
	},
	{
		codes: '1F60A',
		character: '😊',
		title: 'smiling face with smiling eyes',
		category: 'Smileys & Emotion (face-smiling)',
		group: 'Smileys & Emotion',
		subgroup: 'face-smiling',
	},
	{
		codes: '1F607',
		character: '😇',
		title: 'smiling face with halo',
		category: 'Smileys & Emotion (face-smiling)',
		group: 'Smileys & Emotion',
		subgroup: 'face-smiling',
	},
	{
		codes: '1F970',
		character: '🥰',
		title: 'smiling face with hearts',
		category: 'Smileys & Emotion (face-affection)',
		group: 'Smileys & Emotion',
		subgroup: 'face-affection',
	},
	{
		codes: '1F60D',
		character: '😍',
		title: 'smiling face with heart-eyes',
		category: 'Smileys & Emotion (face-affection)',
		group: 'Smileys & Emotion',
		subgroup: 'face-affection',
	},
	{
		codes: '1F929',
		character: '🤩',
		title: 'star-struck',
		category: 'Smileys & Emotion (face-affection)',
		group: 'Smileys & Emotion',
		subgroup: 'face-affection',
	},
	{
		codes: '1F618',
		character: '😘',
		title: 'face blowing a kiss',
		category: 'Smileys & Emotion (face-affection)',
		group: 'Smileys & Emotion',
		subgroup: 'face-affection',
	},
	{
		codes: '1F617',
		character: '😗',
		title: 'kissing face',
		category: 'Smileys & Emotion (face-affection)',
		group: 'Smileys & Emotion',
		subgroup: 'face-affection',
	},
	{
		codes: '1F61A',
		character: '😚',
		title: 'kissing face with closed eyes',
		category: 'Smileys & Emotion (face-affection)',
		group: 'Smileys & Emotion',
		subgroup: 'face-affection',
	},
	{
		codes: '1F619',
		character: '😙',
		title: 'kissing face with smiling eyes',
		category: 'Smileys & Emotion (face-affection)',
		group: 'Smileys & Emotion',
		subgroup: 'face-affection',
	},
	{
		codes: '1F60B',
		character: '😋',
		title: 'face savoring food',
		category: 'Smileys & Emotion (face-tongue)',
		group: 'Smileys & Emotion',
		subgroup: 'face-tongue',
	},
	{
		codes: '1F61B',
		character: '😛',
		title: 'face with tongue',
		category: 'Smileys & Emotion (face-tongue)',
		group: 'Smileys & Emotion',
		subgroup: 'face-tongue',
	},
	{
		codes: '1F61C',
		character: '😜',
		title: 'winking face with tongue',
		category: 'Smileys & Emotion (face-tongue)',
		group: 'Smileys & Emotion',
		subgroup: 'face-tongue',
	},
	{
		codes: '1F92A',
		character: '🤪',
		title: 'zany face',
		category: 'Smileys & Emotion (face-tongue)',
		group: 'Smileys & Emotion',
		subgroup: 'face-tongue',
	},
	{
		codes: '1F61D',
		character: '😝',
		title: 'squinting face with tongue',
		category: 'Smileys & Emotion (face-tongue)',
		group: 'Smileys & Emotion',
		subgroup: 'face-tongue',
	},
	{
		codes: '1F911',
		character: '🤑',
		title: 'money-mouth face',
		category: 'Smileys & Emotion (face-tongue)',
		group: 'Smileys & Emotion',
		subgroup: 'face-tongue',
	},
	{
		codes: '1F917',
		character: '🤗',
		title: 'hugging face',
		category: 'Smileys & Emotion (face-hand)',
		group: 'Smileys & Emotion',
		subgroup: 'face-hand',
	},
	{
		codes: '1F92D',
		character: '🤭',
		title: 'face with hand over mouth',
		category: 'Smileys & Emotion (face-hand)',
		group: 'Smileys & Emotion',
		subgroup: 'face-hand',
	},
	{
		codes: '1F92B',
		character: '🤫',
		title: 'shushing face',
		category: 'Smileys & Emotion (face-hand)',
		group: 'Smileys & Emotion',
		subgroup: 'face-hand',
	},
	{
		codes: '1F914',
		character: '🤔',
		title: 'thinking face',
		category: 'Smileys & Emotion (face-hand)',
		group: 'Smileys & Emotion',
		subgroup: 'face-hand',
	},
	{
		codes: '1F910',
		character: '🤐',
		title: 'zipper-mouth face',
		category: 'Smileys & Emotion (face-neutral-skeptical)',
		group: 'Smileys & Emotion',
		subgroup: 'face-neutral-skeptical',
	},
	{
		codes: '1F928',
		character: '🤨',
		title: 'face with raised eyebrow',
		category: 'Smileys & Emotion (face-neutral-skeptical)',
		group: 'Smileys & Emotion',
		subgroup: 'face-neutral-skeptical',
	},
	{
		codes: '1F610',
		character: '😐',
		title: 'neutral face',
		category: 'Smileys & Emotion (face-neutral-skeptical)',
		group: 'Smileys & Emotion',
		subgroup: 'face-neutral-skeptical',
	},
	{
		codes: '1F611',
		character: '😑',
		title: 'expressionless face',
		category: 'Smileys & Emotion (face-neutral-skeptical)',
		group: 'Smileys & Emotion',
		subgroup: 'face-neutral-skeptical',
	},
	{
		codes: '1F636',
		character: '😶',
		title: 'face without mouth',
		category: 'Smileys & Emotion (face-neutral-skeptical)',
		group: 'Smileys & Emotion',
		subgroup: 'face-neutral-skeptical',
	},
	{
		codes: '1F60F',
		character: '😏',
		title: 'smirking face',
		category: 'Smileys & Emotion (face-neutral-skeptical)',
		group: 'Smileys & Emotion',
		subgroup: 'face-neutral-skeptical',
	},
	{
		codes: '1F612',
		character: '😒',
		title: 'unamused face',
		category: 'Smileys & Emotion (face-neutral-skeptical)',
		group: 'Smileys & Emotion',
		subgroup: 'face-neutral-skeptical',
	},
	{
		codes: '1F644',
		character: '🙄',
		title: 'face with rolling eyes',
		category: 'Smileys & Emotion (face-neutral-skeptical)',
		group: 'Smileys & Emotion',
		subgroup: 'face-neutral-skeptical',
	},
	{
		codes: '1F62C',
		character: '😬',
		title: 'grimacing face',
		category: 'Smileys & Emotion (face-neutral-skeptical)',
		group: 'Smileys & Emotion',
		subgroup: 'face-neutral-skeptical',
	},
	{
		codes: '1F925',
		character: '🤥',
		title: 'lying face',
		category: 'Smileys & Emotion (face-neutral-skeptical)',
		group: 'Smileys & Emotion',
		subgroup: 'face-neutral-skeptical',
	},
	{
		codes: '1F60C',
		character: '😌',
		title: 'relieved face',
		category: 'Smileys & Emotion (face-sleepy)',
		group: 'Smileys & Emotion',
		subgroup: 'face-sleepy',
	},
	{
		codes: '1F614',
		character: '😔',
		title: 'pensive face',
		category: 'Smileys & Emotion (face-sleepy)',
		group: 'Smileys & Emotion',
		subgroup: 'face-sleepy',
	},
	{
		codes: '1F62A',
		character: '😪',
		title: 'sleepy face',
		category: 'Smileys & Emotion (face-sleepy)',
		group: 'Smileys & Emotion',
		subgroup: 'face-sleepy',
	},
	{
		codes: '1F924',
		character: '🤤',
		title: 'drooling face',
		category: 'Smileys & Emotion (face-sleepy)',
		group: 'Smileys & Emotion',
		subgroup: 'face-sleepy',
	},
	{
		codes: '1F634',
		character: '😴',
		title: 'sleeping face',
		category: 'Smileys & Emotion (face-sleepy)',
		group: 'Smileys & Emotion',
		subgroup: 'face-sleepy',
	},
	{
		codes: '1F637',
		character: '😷',
		title: 'face with medical mask',
		category: 'Smileys & Emotion (face-unwell)',
		group: 'Smileys & Emotion',
		subgroup: 'face-unwell',
	},
	{
		codes: '1F912',
		character: '🤒',
		title: 'face with thermometer',
		category: 'Smileys & Emotion (face-unwell)',
		group: 'Smileys & Emotion',
		subgroup: 'face-unwell',
	},
	{
		codes: '1F915',
		character: '🤕',
		title: 'face with head-bandage',
		category: 'Smileys & Emotion (face-unwell)',
		group: 'Smileys & Emotion',
		subgroup: 'face-unwell',
	},
	{
		codes: '1F922',
		character: '🤢',
		title: 'nauseated face',
		category: 'Smileys & Emotion (face-unwell)',
		group: 'Smileys & Emotion',
		subgroup: 'face-unwell',
	},
	{
		codes: '1F92E',
		character: '🤮',
		title: 'face vomiting',
		category: 'Smileys & Emotion (face-unwell)',
		group: 'Smileys & Emotion',
		subgroup: 'face-unwell',
	},
	{
		codes: '1F927',
		character: '🤧',
		title: 'sneezing face',
		category: 'Smileys & Emotion (face-unwell)',
		group: 'Smileys & Emotion',
		subgroup: 'face-unwell',
	},
	{
		codes: '1F975',
		character: '🥵',
		title: 'hot face',
		category: 'Smileys & Emotion (face-unwell)',
		group: 'Smileys & Emotion',
		subgroup: 'face-unwell',
	},
	{
		codes: '1F976',
		character: '🥶',
		title: 'cold face',
		category: 'Smileys & Emotion (face-unwell)',
		group: 'Smileys & Emotion',
		subgroup: 'face-unwell',
	},
	{
		codes: '1F974',
		character: '🥴',
		title: 'woozy face',
		category: 'Smileys & Emotion (face-unwell)',
		group: 'Smileys & Emotion',
		subgroup: 'face-unwell',
	},
	{
		codes: '1F635',
		character: '😵',
		title: 'dizzy face',
		category: 'Smileys & Emotion (face-unwell)',
		group: 'Smileys & Emotion',
		subgroup: 'face-unwell',
	},
	{
		codes: '1F92F',
		character: '🤯',
		title: 'exploding head',
		category: 'Smileys & Emotion (face-unwell)',
		group: 'Smileys & Emotion',
		subgroup: 'face-unwell',
	},
	{
		codes: '1F920',
		character: '🤠',
		title: 'cowboy hat face',
		category: 'Smileys & Emotion (face-hat)',
		group: 'Smileys & Emotion',
		subgroup: 'face-hat',
	},
	{
		codes: '1F973',
		character: '🥳',
		title: 'partying face',
		category: 'Smileys & Emotion (face-hat)',
		group: 'Smileys & Emotion',
		subgroup: 'face-hat',
	},
	{
		codes: '1F60E',
		character: '😎',
		title: 'smiling face with sunglasses',
		category: 'Smileys & Emotion (face-glasses)',
		group: 'Smileys & Emotion',
		subgroup: 'face-glasses',
	},
	{
		codes: '1F913',
		character: '🤓',
		title: 'nerd face',
		category: 'Smileys & Emotion (face-glasses)',
		group: 'Smileys & Emotion',
		subgroup: 'face-glasses',
	},
	{
		codes: '1F9D0',
		character: '🧐',
		title: 'face with monocle',
		category: 'Smileys & Emotion (face-glasses)',
		group: 'Smileys & Emotion',
		subgroup: 'face-glasses',
	},
	{
		codes: '1F615',
		character: '😕',
		title: 'confused face',
		category: 'Smileys & Emotion (face-concerned)',
		group: 'Smileys & Emotion',
		subgroup: 'face-concerned',
	},
	{
		codes: '1F61F',
		character: '😟',
		title: 'worried face',
		category: 'Smileys & Emotion (face-concerned)',
		group: 'Smileys & Emotion',
		subgroup: 'face-concerned',
	},
	{
		codes: '1F641',
		character: '🙁',
		title: 'slightly frowning face',
		category: 'Smileys & Emotion (face-concerned)',
		group: 'Smileys & Emotion',
		subgroup: 'face-concerned',
	},
	{
		codes: '2639 FE0F',
		character: '☹️',
		title: 'frowning face',
		category: 'Smileys & Emotion (face-concerned)',
		group: 'Smileys & Emotion',
		subgroup: 'face-concerned',
	},
	{
		codes: '1F62E',
		character: '😮',
		title: 'face with open mouth',
		category: 'Smileys & Emotion (face-concerned)',
		group: 'Smileys & Emotion',
		subgroup: 'face-concerned',
	},
	{
		codes: '1F62F',
		character: '😯',
		title: 'hushed face',
		category: 'Smileys & Emotion (face-concerned)',
		group: 'Smileys & Emotion',
		subgroup: 'face-concerned',
	},
	{
		codes: '1F632',
		character: '😲',
		title: 'astonished face',
		category: 'Smileys & Emotion (face-concerned)',
		group: 'Smileys & Emotion',
		subgroup: 'face-concerned',
	},
	{
		codes: '1F633',
		character: '😳',
		title: 'flushed face',
		category: 'Smileys & Emotion (face-concerned)',
		group: 'Smileys & Emotion',
		subgroup: 'face-concerned',
	},
	{
		codes: '1F97A',
		character: '🥺',
		title: 'pleading face',
		category: 'Smileys & Emotion (face-concerned)',
		group: 'Smileys & Emotion',
		subgroup: 'face-concerned',
	},
	{
		codes: '1F626',
		character: '😦',
		title: 'frowning face with open mouth',
		category: 'Smileys & Emotion (face-concerned)',
		group: 'Smileys & Emotion',
		subgroup: 'face-concerned',
	},
	{
		codes: '1F627',
		character: '😧',
		title: 'anguished face',
		category: 'Smileys & Emotion (face-concerned)',
		group: 'Smileys & Emotion',
		subgroup: 'face-concerned',
	},
	{
		codes: '1F628',
		character: '😨',
		title: 'fearful face',
		category: 'Smileys & Emotion (face-concerned)',
		group: 'Smileys & Emotion',
		subgroup: 'face-concerned',
	},
	{
		codes: '1F630',
		character: '😰',
		title: 'anxious face with sweat',
		category: 'Smileys & Emotion (face-concerned)',
		group: 'Smileys & Emotion',
		subgroup: 'face-concerned',
	},
	{
		codes: '1F625',
		character: '😥',
		title: 'sad but relieved face',
		category: 'Smileys & Emotion (face-concerned)',
		group: 'Smileys & Emotion',
		subgroup: 'face-concerned',
	},
	{
		codes: '1F622',
		character: '😢',
		title: 'crying face',
		category: 'Smileys & Emotion (face-concerned)',
		group: 'Smileys & Emotion',
		subgroup: 'face-concerned',
	},
	{
		codes: '1F62D',
		character: '😭',
		title: 'loudly crying face',
		category: 'Smileys & Emotion (face-concerned)',
		group: 'Smileys & Emotion',
		subgroup: 'face-concerned',
	},
	{
		codes: '1F631',
		character: '😱',
		title: 'face screaming in fear',
		category: 'Smileys & Emotion (face-concerned)',
		group: 'Smileys & Emotion',
		subgroup: 'face-concerned',
	},
	{
		codes: '1F616',
		character: '😖',
		title: 'confounded face',
		category: 'Smileys & Emotion (face-concerned)',
		group: 'Smileys & Emotion',
		subgroup: 'face-concerned',
	},
	{
		codes: '1F623',
		character: '😣',
		title: 'persevering face',
		category: 'Smileys & Emotion (face-concerned)',
		group: 'Smileys & Emotion',
		subgroup: 'face-concerned',
	},
	{
		codes: '1F61E',
		character: '😞',
		title: 'disappointed face',
		category: 'Smileys & Emotion (face-concerned)',
		group: 'Smileys & Emotion',
		subgroup: 'face-concerned',
	},
	{
		codes: '1F613',
		character: '😓',
		title: 'downcast face with sweat',
		category: 'Smileys & Emotion (face-concerned)',
		group: 'Smileys & Emotion',
		subgroup: 'face-concerned',
	},
	{
		codes: '1F629',
		character: '😩',
		title: 'weary face',
		category: 'Smileys & Emotion (face-concerned)',
		group: 'Smileys & Emotion',
		subgroup: 'face-concerned',
	},
	{
		codes: '1F62B',
		character: '😫',
		title: 'tired face',
		category: 'Smileys & Emotion (face-concerned)',
		group: 'Smileys & Emotion',
		subgroup: 'face-concerned',
	},
	{
		codes: '1F971',
		character: '🥱',
		title: 'yawning face',
		category: 'Smileys & Emotion (face-concerned)',
		group: 'Smileys & Emotion',
		subgroup: 'face-concerned',
	},
	{
		codes: '1F624',
		character: '😤',
		title: 'face with steam from nose',
		category: 'Smileys & Emotion (face-negative)',
		group: 'Smileys & Emotion',
		subgroup: 'face-negative',
	},
	{
		codes: '1F621',
		character: '😡',
		title: 'pouting face',
		category: 'Smileys & Emotion (face-negative)',
		group: 'Smileys & Emotion',
		subgroup: 'face-negative',
	},
	{
		codes: '1F620',
		character: '😠',
		title: 'angry face',
		category: 'Smileys & Emotion (face-negative)',
		group: 'Smileys & Emotion',
		subgroup: 'face-negative',
	},
	{
		codes: '1F92C',
		character: '🤬',
		title: 'face with symbols on mouth',
		category: 'Smileys & Emotion (face-negative)',
		group: 'Smileys & Emotion',
		subgroup: 'face-negative',
	},
	{
		codes: '1F608',
		character: '😈',
		title: 'smiling face with horns',
		category: 'Smileys & Emotion (face-negative)',
		group: 'Smileys & Emotion',
		subgroup: 'face-negative',
	},
	{
		codes: '1F47F',
		character: '👿',
		title: 'angry face with horns',
		category: 'Smileys & Emotion (face-negative)',
		group: 'Smileys & Emotion',
		subgroup: 'face-negative',
	},
	{
		codes: '1F480',
		character: '💀',
		title: 'skull',
		category: 'Smileys & Emotion (face-negative)',
		group: 'Smileys & Emotion',
		subgroup: 'face-negative',
	},
	{
		codes: '2620 FE0F',
		character: '☠️',
		title: 'skull and crossbones',
		category: 'Smileys & Emotion (face-negative)',
		group: 'Smileys & Emotion',
		subgroup: 'face-negative',
	},
	{
		codes: '1F44B',
		character: '👋',
		title: 'waving hand',
		category: 'People & Body (hand-fingers-open)',
		group: 'People & Body',
		subgroup: 'hand-fingers-open',
	},
	{
		codes: '1F44B 1F3FB',
		character: '👋🏻',
		title: 'waving hand light skin tone',
		category: 'People & Body (hand-fingers-open)',
		group: 'People & Body',
		subgroup: 'hand-fingers-open',
	},
	{
		codes: '1F44B 1F3FC',
		character: '👋🏼',
		title: 'waving hand medium-light skin tone',
		category: 'People & Body (hand-fingers-open)',
		group: 'People & Body',
		subgroup: 'hand-fingers-open',
	},
	{
		codes: '1F44B 1F3FD',
		character: '👋🏽',
		title: 'waving hand medium skin tone',
		category: 'People & Body (hand-fingers-open)',
		group: 'People & Body',
		subgroup: 'hand-fingers-open',
	},
	{
		codes: '1F44B 1F3FE',
		character: '👋🏾',
		title: 'waving hand medium-dark skin tone',
		category: 'People & Body (hand-fingers-open)',
		group: 'People & Body',
		subgroup: 'hand-fingers-open',
	},
	{
		codes: '1F44B 1F3FF',
		character: '👋🏿',
		title: 'waving hand dark skin tone',
		category: 'People & Body (hand-fingers-open)',
		group: 'People & Body',
		subgroup: 'hand-fingers-open',
	},
	{
		codes: '1F91A',
		character: '🤚',
		title: 'raised back of hand',
		category: 'People & Body (hand-fingers-open)',
		group: 'People & Body',
		subgroup: 'hand-fingers-open',
	},
	{
		codes: '1F91A 1F3FB',
		character: '🤚🏻',
		title: 'raised back of hand light skin tone',
		category: 'People & Body (hand-fingers-open)',
		group: 'People & Body',
		subgroup: 'hand-fingers-open',
	},
	{
		codes: '1F91A 1F3FC',
		character: '🤚🏼',
		title: 'raised back of hand medium-light skin tone',
		category: 'People & Body (hand-fingers-open)',
		group: 'People & Body',
		subgroup: 'hand-fingers-open',
	},
	{
		codes: '1F91A 1F3FD',
		character: '🤚🏽',
		title: 'raised back of hand medium skin tone',
		category: 'People & Body (hand-fingers-open)',
		group: 'People & Body',
		subgroup: 'hand-fingers-open',
	},
	{
		codes: '1F91A 1F3FE',
		character: '🤚🏾',
		title: 'raised back of hand medium-dark skin tone',
		category: 'People & Body (hand-fingers-open)',
		group: 'People & Body',
		subgroup: 'hand-fingers-open',
	},
	{
		codes: '1F91A 1F3FF',
		character: '🤚🏿',
		title: 'raised back of hand dark skin tone',
		category: 'People & Body (hand-fingers-open)',
		group: 'People & Body',
		subgroup: 'hand-fingers-open',
	},
	{
		codes: '1F590',
		character: '🖐',
		title: 'hand with fingers splayed',
		category: 'People & Body (hand-fingers-open)',
		group: 'People & Body',
		subgroup: 'hand-fingers-open',
	},
	{
		codes: '1F590 1F3FB',
		character: '🖐🏻',
		title: 'hand with fingers splayed: light skin tone',
		category: 'People & Body (hand-fingers-open)',
		group: 'People & Body',
		subgroup: 'hand-fingers-open',
	},
	{
		codes: '1F590 1F3FC',
		character: '🖐🏼',
		title: 'hand with fingers splayed: medium-light skin tone',
		category: 'People & Body (hand-fingers-open)',
		group: 'People & Body',
		subgroup: 'hand-fingers-open',
	},
	{
		codes: '1F590 1F3FD',
		character: '🖐🏽',
		title: 'hand with fingers splayed: medium skin tone',
		category: 'People & Body (hand-fingers-open)',
		group: 'People & Body',
		subgroup: 'hand-fingers-open',
	},
	{
		codes: '1F590 1F3FE',
		character: '🖐🏾',
		title: 'hand with fingers splayed: medium-dark skin tone',
		category: 'People & Body (hand-fingers-open)',
		group: 'People & Body',
		subgroup: 'hand-fingers-open',
	},
	{
		codes: '1F590 1F3FF',
		character: '🖐🏿',
		title: 'hand with fingers splayed: dark skin tone',
		category: 'People & Body (hand-fingers-open)',
		group: 'People & Body',
		subgroup: 'hand-fingers-open',
	},
	{
		codes: '270B',
		character: '✋',
		title: 'raised hand',
		category: 'People & Body (hand-fingers-open)',
		group: 'People & Body',
		subgroup: 'hand-fingers-open',
	},
	{
		codes: '270B 1F3FB',
		character: '✋🏻',
		title: 'raised hand light skin tone',
		category: 'People & Body (hand-fingers-open)',
		group: 'People & Body',
		subgroup: 'hand-fingers-open',
	},
	{
		codes: '270B 1F3FC',
		character: '✋🏼',
		title: 'raised hand medium-light skin tone',
		category: 'People & Body (hand-fingers-open)',
		group: 'People & Body',
		subgroup: 'hand-fingers-open',
	},
	{
		codes: '270B 1F3FD',
		character: '✋🏽',
		title: 'raised hand medium skin tone',
		category: 'People & Body (hand-fingers-open)',
		group: 'People & Body',
		subgroup: 'hand-fingers-open',
	},
	{
		codes: '270B 1F3FE',
		character: '✋🏾',
		title: 'raised hand medium-dark skin tone',
		category: 'People & Body (hand-fingers-open)',
		group: 'People & Body',
		subgroup: 'hand-fingers-open',
	},
	{
		codes: '270B 1F3FF',
		character: '✋🏿',
		title: 'raised hand dark skin tone',
		category: 'People & Body (hand-fingers-open)',
		group: 'People & Body',
		subgroup: 'hand-fingers-open',
	},
	{
		codes: '1F596',
		character: '🖖',
		title: 'vulcan salute',
		category: 'People & Body (hand-fingers-open)',
		group: 'People & Body',
		subgroup: 'hand-fingers-open',
	},
	{
		codes: '1F596 1F3FB',
		character: '🖖🏻',
		title: 'vulcan salute light skin tone',
		category: 'People & Body (hand-fingers-open)',
		group: 'People & Body',
		subgroup: 'hand-fingers-open',
	},
	{
		codes: '1F596 1F3FC',
		character: '🖖🏼',
		title: 'vulcan salute medium-light skin tone',
		category: 'People & Body (hand-fingers-open)',
		group: 'People & Body',
		subgroup: 'hand-fingers-open',
	},
	{
		codes: '1F596 1F3FD',
		character: '🖖🏽',
		title: 'vulcan salute medium skin tone',
		category: 'People & Body (hand-fingers-open)',
		group: 'People & Body',
		subgroup: 'hand-fingers-open',
	},
	{
		codes: '1F596 1F3FE',
		character: '🖖🏾',
		title: 'vulcan salute medium-dark skin tone',
		category: 'People & Body (hand-fingers-open)',
		group: 'People & Body',
		subgroup: 'hand-fingers-open',
	},
	{
		codes: '1F596 1F3FF',
		character: '🖖🏿',
		title: 'vulcan salute dark skin tone',
		category: 'People & Body (hand-fingers-open)',
		group: 'People & Body',
		subgroup: 'hand-fingers-open',
	},
	{
		codes: '1F44C',
		character: '👌',
		title: 'OK hand',
		category: 'People & Body (hand-fingers-partial)',
		group: 'People & Body',
		subgroup: 'hand-fingers-partial',
	},
	{
		codes: '1F44C 1F3FB',
		character: '👌🏻',
		title: 'OK hand light skin tone',
		category: 'People & Body (hand-fingers-partial)',
		group: 'People & Body',
		subgroup: 'hand-fingers-partial',
	},
	{
		codes: '1F44C 1F3FC',
		character: '👌🏼',
		title: 'OK hand medium-light skin tone',
		category: 'People & Body (hand-fingers-partial)',
		group: 'People & Body',
		subgroup: 'hand-fingers-partial',
	},
	{
		codes: '1F44C 1F3FD',
		character: '👌🏽',
		title: 'OK hand medium skin tone',
		category: 'People & Body (hand-fingers-partial)',
		group: 'People & Body',
		subgroup: 'hand-fingers-partial',
	},
	{
		codes: '1F44C 1F3FE',
		character: '👌🏾',
		title: 'OK hand medium-dark skin tone',
		category: 'People & Body (hand-fingers-partial)',
		group: 'People & Body',
		subgroup: 'hand-fingers-partial',
	},
	{
		codes: '1F44C 1F3FF',
		character: '👌🏿',
		title: 'OK hand dark skin tone',
		category: 'People & Body (hand-fingers-partial)',
		group: 'People & Body',
		subgroup: 'hand-fingers-partial',
	},
	{
		codes: '1F90F',
		character: '🤏',
		title: 'pinching hand',
		category: 'People & Body (hand-fingers-partial)',
		group: 'People & Body',
		subgroup: 'hand-fingers-partial',
	},
	{
		codes: '1F90F 1F3FB',
		character: '🤏🏻',
		title: 'pinching hand light skin tone',
		category: 'People & Body (hand-fingers-partial)',
		group: 'People & Body',
		subgroup: 'hand-fingers-partial',
	},
	{
		codes: '1F90F 1F3FC',
		character: '🤏🏼',
		title: 'pinching hand medium-light skin tone',
		category: 'People & Body (hand-fingers-partial)',
		group: 'People & Body',
		subgroup: 'hand-fingers-partial',
	},
	{
		codes: '1F90F 1F3FD',
		character: '🤏🏽',
		title: 'pinching hand medium skin tone',
		category: 'People & Body (hand-fingers-partial)',
		group: 'People & Body',
		subgroup: 'hand-fingers-partial',
	},
	{
		codes: '1F90F 1F3FE',
		character: '🤏🏾',
		title: 'pinching hand medium-dark skin tone',
		category: 'People & Body (hand-fingers-partial)',
		group: 'People & Body',
		subgroup: 'hand-fingers-partial',
	},
	{
		codes: '1F90F 1F3FF',
		character: '🤏🏿',
		title: 'pinching hand dark skin tone',
		category: 'People & Body (hand-fingers-partial)',
		group: 'People & Body',
		subgroup: 'hand-fingers-partial',
	},
	{
		codes: '270C FE0F',
		character: '✌️',
		title: 'victory hand',
		category: 'People & Body (hand-fingers-partial)',
		group: 'People & Body',
		subgroup: 'hand-fingers-partial',
	},
	{
		codes: '270C 1F3FB',
		character: '✌🏻',
		title: 'victory hand light skin tone',
		category: 'People & Body (hand-fingers-partial)',
		group: 'People & Body',
		subgroup: 'hand-fingers-partial',
	},
	{
		codes: '270C 1F3FC',
		character: '✌🏼',
		title: 'victory hand medium-light skin tone',
		category: 'People & Body (hand-fingers-partial)',
		group: 'People & Body',
		subgroup: 'hand-fingers-partial',
	},
	{
		codes: '270C 1F3FD',
		character: '✌🏽',
		title: 'victory hand medium skin tone',
		category: 'People & Body (hand-fingers-partial)',
		group: 'People & Body',
		subgroup: 'hand-fingers-partial',
	},
	{
		codes: '270C 1F3FE',
		character: '✌🏾',
		title: 'victory hand medium-dark skin tone',
		category: 'People & Body (hand-fingers-partial)',
		group: 'People & Body',
		subgroup: 'hand-fingers-partial',
	},
	{
		codes: '270C 1F3FF',
		character: '✌🏿',
		title: 'victory hand dark skin tone',
		category: 'People & Body (hand-fingers-partial)',
		group: 'People & Body',
		subgroup: 'hand-fingers-partial',
	},
	{
		codes: '1F91E',
		character: '🤞',
		title: 'crossed fingers',
		category: 'People & Body (hand-fingers-partial)',
		group: 'People & Body',
		subgroup: 'hand-fingers-partial',
	},
	{
		codes: '1F91E 1F3FB',
		character: '🤞🏻',
		title: 'crossed fingers light skin tone',
		category: 'People & Body (hand-fingers-partial)',
		group: 'People & Body',
		subgroup: 'hand-fingers-partial',
	},
	{
		codes: '1F91E 1F3FC',
		character: '🤞🏼',
		title: 'crossed fingers medium-light skin tone',
		category: 'People & Body (hand-fingers-partial)',
		group: 'People & Body',
		subgroup: 'hand-fingers-partial',
	},
	{
		codes: '1F91E 1F3FD',
		character: '🤞🏽',
		title: 'crossed fingers medium skin tone',
		category: 'People & Body (hand-fingers-partial)',
		group: 'People & Body',
		subgroup: 'hand-fingers-partial',
	},
	{
		codes: '1F91E 1F3FE',
		character: '🤞🏾',
		title: 'crossed fingers medium-dark skin tone',
		category: 'People & Body (hand-fingers-partial)',
		group: 'People & Body',
		subgroup: 'hand-fingers-partial',
	},
	{
		codes: '1F91E 1F3FF',
		character: '🤞🏿',
		title: 'crossed fingers dark skin tone',
		category: 'People & Body (hand-fingers-partial)',
		group: 'People & Body',
		subgroup: 'hand-fingers-partial',
	},
	{
		codes: '1F91F',
		character: '🤟',
		title: 'love-you gesture',
		category: 'People & Body (hand-fingers-partial)',
		group: 'People & Body',
		subgroup: 'hand-fingers-partial',
	},
	{
		codes: '1F91F 1F3FB',
		character: '🤟🏻',
		title: 'love-you gesture light skin tone',
		category: 'People & Body (hand-fingers-partial)',
		group: 'People & Body',
		subgroup: 'hand-fingers-partial',
	},
	{
		codes: '1F91F 1F3FC',
		character: '🤟🏼',
		title: 'love-you gesture medium-light skin tone',
		category: 'People & Body (hand-fingers-partial)',
		group: 'People & Body',
		subgroup: 'hand-fingers-partial',
	},
	{
		codes: '1F91F 1F3FD',
		character: '🤟🏽',
		title: 'love-you gesture medium skin tone',
		category: 'People & Body (hand-fingers-partial)',
		group: 'People & Body',
		subgroup: 'hand-fingers-partial',
	},
	{
		codes: '1F91F 1F3FE',
		character: '🤟🏾',
		title: 'love-you gesture medium-dark skin tone',
		category: 'People & Body (hand-fingers-partial)',
		group: 'People & Body',
		subgroup: 'hand-fingers-partial',
	},
	{
		codes: '1F91F 1F3FF',
		character: '🤟🏿',
		title: 'love-you gesture dark skin tone',
		category: 'People & Body (hand-fingers-partial)',
		group: 'People & Body',
		subgroup: 'hand-fingers-partial',
	},
	{
		codes: '1F918',
		character: '🤘',
		title: 'sign of the horns',
		category: 'People & Body (hand-fingers-partial)',
		group: 'People & Body',
		subgroup: 'hand-fingers-partial',
	},
	{
		codes: '1F918 1F3FB',
		character: '🤘🏻',
		title: 'sign of the horns light skin tone',
		category: 'People & Body (hand-fingers-partial)',
		group: 'People & Body',
		subgroup: 'hand-fingers-partial',
	},
	{
		codes: '1F918 1F3FC',
		character: '🤘🏼',
		title: 'sign of the horns medium-light skin tone',
		category: 'People & Body (hand-fingers-partial)',
		group: 'People & Body',
		subgroup: 'hand-fingers-partial',
	},
	{
		codes: '1F918 1F3FD',
		character: '🤘🏽',
		title: 'sign of the horns medium skin tone',
		category: 'People & Body (hand-fingers-partial)',
		group: 'People & Body',
		subgroup: 'hand-fingers-partial',
	},
	{
		codes: '1F918 1F3FE',
		character: '🤘🏾',
		title: 'sign of the horns medium-dark skin tone',
		category: 'People & Body (hand-fingers-partial)',
		group: 'People & Body',
		subgroup: 'hand-fingers-partial',
	},
	{
		codes: '1F918 1F3FF',
		character: '🤘🏿',
		title: 'sign of the horns dark skin tone',
		category: 'People & Body (hand-fingers-partial)',
		group: 'People & Body',
		subgroup: 'hand-fingers-partial',
	},
	{
		codes: '1F919',
		character: '🤙',
		title: 'call me hand',
		category: 'People & Body (hand-fingers-partial)',
		group: 'People & Body',
		subgroup: 'hand-fingers-partial',
	},
	{
		codes: '1F919 1F3FB',
		character: '🤙🏻',
		title: 'call me hand light skin tone',
		category: 'People & Body (hand-fingers-partial)',
		group: 'People & Body',
		subgroup: 'hand-fingers-partial',
	},
	{
		codes: '1F919 1F3FC',
		character: '🤙🏼',
		title: 'call me hand medium-light skin tone',
		category: 'People & Body (hand-fingers-partial)',
		group: 'People & Body',
		subgroup: 'hand-fingers-partial',
	},
	{
		codes: '1F919 1F3FD',
		character: '🤙🏽',
		title: 'call me hand medium skin tone',
		category: 'People & Body (hand-fingers-partial)',
		group: 'People & Body',
		subgroup: 'hand-fingers-partial',
	},
	{
		codes: '1F919 1F3FE',
		character: '🤙🏾',
		title: 'call me hand medium-dark skin tone',
		category: 'People & Body (hand-fingers-partial)',
		group: 'People & Body',
		subgroup: 'hand-fingers-partial',
	},
	{
		codes: '1F919 1F3FF',
		character: '🤙🏿',
		title: 'call me hand dark skin tone',
		category: 'People & Body (hand-fingers-partial)',
		group: 'People & Body',
		subgroup: 'hand-fingers-partial',
	},
	{
		codes: '1F448',
		character: '👈',
		title: 'backhand index pointing left',
		category: 'People & Body (hand-single-finger)',
		group: 'People & Body',
		subgroup: 'hand-single-finger',
	},
	{
		codes: '1F448 1F3FB',
		character: '👈🏻',
		title: 'backhand index pointing left light skin tone',
		category: 'People & Body (hand-single-finger)',
		group: 'People & Body',
		subgroup: 'hand-single-finger',
	},
	{
		codes: '1F448 1F3FC',
		character: '👈🏼',
		title: 'backhand index pointing left medium-light skin tone',
		category: 'People & Body (hand-single-finger)',
		group: 'People & Body',
		subgroup: 'hand-single-finger',
	},
	{
		codes: '1F448 1F3FD',
		character: '👈🏽',
		title: 'backhand index pointing left medium skin tone',
		category: 'People & Body (hand-single-finger)',
		group: 'People & Body',
		subgroup: 'hand-single-finger',
	},
	{
		codes: '1F448 1F3FE',
		character: '👈🏾',
		title: 'backhand index pointing left medium-dark skin tone',
		category: 'People & Body (hand-single-finger)',
		group: 'People & Body',
		subgroup: 'hand-single-finger',
	},
	{
		codes: '1F448 1F3FF',
		character: '👈🏿',
		title: 'backhand index pointing left dark skin tone',
		category: 'People & Body (hand-single-finger)',
		group: 'People & Body',
		subgroup: 'hand-single-finger',
	},
	{
		codes: '1F449',
		character: '👉',
		title: 'backhand index pointing right',
		category: 'People & Body (hand-single-finger)',
		group: 'People & Body',
		subgroup: 'hand-single-finger',
	},
	{
		codes: '1F449 1F3FB',
		character: '👉🏻',
		title: 'backhand index pointing right light skin tone',
		category: 'People & Body (hand-single-finger)',
		group: 'People & Body',
		subgroup: 'hand-single-finger',
	},
	{
		codes: '1F449 1F3FC',
		character: '👉🏼',
		title: 'backhand index pointing right medium-light skin tone',
		category: 'People & Body (hand-single-finger)',
		group: 'People & Body',
		subgroup: 'hand-single-finger',
	},
	{
		codes: '1F449 1F3FD',
		character: '👉🏽',
		title: 'backhand index pointing right medium skin tone',
		category: 'People & Body (hand-single-finger)',
		group: 'People & Body',
		subgroup: 'hand-single-finger',
	},
	{
		codes: '1F449 1F3FE',
		character: '👉🏾',
		title: 'backhand index pointing right medium-dark skin tone',
		category: 'People & Body (hand-single-finger)',
		group: 'People & Body',
		subgroup: 'hand-single-finger',
	},
	{
		codes: '1F449 1F3FF',
		character: '👉🏿',
		title: 'backhand index pointing right dark skin tone',
		category: 'People & Body (hand-single-finger)',
		group: 'People & Body',
		subgroup: 'hand-single-finger',
	},
	{
		codes: '1F446',
		character: '👆',
		title: 'backhand index pointing up',
		category: 'People & Body (hand-single-finger)',
		group: 'People & Body',
		subgroup: 'hand-single-finger',
	},
	{
		codes: '1F446 1F3FB',
		character: '👆🏻',
		title: 'backhand index pointing up light skin tone',
		category: 'People & Body (hand-single-finger)',
		group: 'People & Body',
		subgroup: 'hand-single-finger',
	},
	{
		codes: '1F446 1F3FC',
		character: '👆🏼',
		title: 'backhand index pointing up medium-light skin tone',
		category: 'People & Body (hand-single-finger)',
		group: 'People & Body',
		subgroup: 'hand-single-finger',
	},
	{
		codes: '1F446 1F3FD',
		character: '👆🏽',
		title: 'backhand index pointing up medium skin tone',
		category: 'People & Body (hand-single-finger)',
		group: 'People & Body',
		subgroup: 'hand-single-finger',
	},
	{
		codes: '1F446 1F3FE',
		character: '👆🏾',
		title: 'backhand index pointing up medium-dark skin tone',
		category: 'People & Body (hand-single-finger)',
		group: 'People & Body',
		subgroup: 'hand-single-finger',
	},
	{
		codes: '1F446 1F3FF',
		character: '👆🏿',
		title: 'backhand index pointing up dark skin tone',
		category: 'People & Body (hand-single-finger)',
		group: 'People & Body',
		subgroup: 'hand-single-finger',
	},
	{
		codes: '1F595',
		character: '🖕',
		title: 'middle finger',
		category: 'People & Body (hand-single-finger)',
		group: 'People & Body',
		subgroup: 'hand-single-finger',
	},
	{
		codes: '1F595 1F3FB',
		character: '🖕🏻',
		title: 'middle finger light skin tone',
		category: 'People & Body (hand-single-finger)',
		group: 'People & Body',
		subgroup: 'hand-single-finger',
	},
	{
		codes: '1F595 1F3FC',
		character: '🖕🏼',
		title: 'middle finger medium-light skin tone',
		category: 'People & Body (hand-single-finger)',
		group: 'People & Body',
		subgroup: 'hand-single-finger',
	},
	{
		codes: '1F595 1F3FD',
		character: '🖕🏽',
		title: 'middle finger medium skin tone',
		category: 'People & Body (hand-single-finger)',
		group: 'People & Body',
		subgroup: 'hand-single-finger',
	},
	{
		codes: '1F595 1F3FE',
		character: '🖕🏾',
		title: 'middle finger medium-dark skin tone',
		category: 'People & Body (hand-single-finger)',
		group: 'People & Body',
		subgroup: 'hand-single-finger',
	},
	{
		codes: '1F595 1F3FF',
		character: '🖕🏿',
		title: 'middle finger dark skin tone',
		category: 'People & Body (hand-single-finger)',
		group: 'People & Body',
		subgroup: 'hand-single-finger',
	},
	{
		codes: '1F447',
		character: '👇',
		title: 'backhand index pointing down',
		category: 'People & Body (hand-single-finger)',
		group: 'People & Body',
		subgroup: 'hand-single-finger',
	},
	{
		codes: '1F447 1F3FB',
		character: '👇🏻',
		title: 'backhand index pointing down light skin tone',
		category: 'People & Body (hand-single-finger)',
		group: 'People & Body',
		subgroup: 'hand-single-finger',
	},
	{
		codes: '1F447 1F3FC',
		character: '👇🏼',
		title: 'backhand index pointing down medium-light skin tone',
		category: 'People & Body (hand-single-finger)',
		group: 'People & Body',
		subgroup: 'hand-single-finger',
	},
	{
		codes: '1F447 1F3FD',
		character: '👇🏽',
		title: 'backhand index pointing down medium skin tone',
		category: 'People & Body (hand-single-finger)',
		group: 'People & Body',
		subgroup: 'hand-single-finger',
	},
	{
		codes: '1F447 1F3FE',
		character: '👇🏾',
		title: 'backhand index pointing down medium-dark skin tone',
		category: 'People & Body (hand-single-finger)',
		group: 'People & Body',
		subgroup: 'hand-single-finger',
	},
	{
		codes: '1F447 1F3FF',
		character: '👇🏿',
		title: 'backhand index pointing down dark skin tone',
		category: 'People & Body (hand-single-finger)',
		group: 'People & Body',
		subgroup: 'hand-single-finger',
	},
	{
		codes: '261D FE0F',
		character: '☝️',
		title: 'index pointing up',
		category: 'People & Body (hand-single-finger)',
		group: 'People & Body',
		subgroup: 'hand-single-finger',
	},
	{
		codes: '261D 1F3FB',
		character: '☝🏻',
		title: 'index pointing up light skin tone',
		category: 'People & Body (hand-single-finger)',
		group: 'People & Body',
		subgroup: 'hand-single-finger',
	},
	{
		codes: '261D 1F3FC',
		character: '☝🏼',
		title: 'index pointing up medium-light skin tone',
		category: 'People & Body (hand-single-finger)',
		group: 'People & Body',
		subgroup: 'hand-single-finger',
	},
	{
		codes: '261D 1F3FD',
		character: '☝🏽',
		title: 'index pointing up medium skin tone',
		category: 'People & Body (hand-single-finger)',
		group: 'People & Body',
		subgroup: 'hand-single-finger',
	},
	{
		codes: '261D 1F3FE',
		character: '☝🏾',
		title: 'index pointing up medium-dark skin tone',
		category: 'People & Body (hand-single-finger)',
		group: 'People & Body',
		subgroup: 'hand-single-finger',
	},
	{
		codes: '261D 1F3FF',
		character: '☝🏿',
		title: 'index pointing up dark skin tone',
		category: 'People & Body (hand-single-finger)',
		group: 'People & Body',
		subgroup: 'hand-single-finger',
	},
	{
		codes: '1F44D',
		character: '👍',
		title: 'thumbs up',
		category: 'People & Body (hand-fingers-closed)',
		group: 'People & Body',
		subgroup: 'hand-fingers-closed',
	},
	{
		codes: '1F44D 1F3FB',
		character: '👍🏻',
		title: 'thumbs up light skin tone',
		category: 'People & Body (hand-fingers-closed)',
		group: 'People & Body',
		subgroup: 'hand-fingers-closed',
	},
	{
		codes: '1F44D 1F3FC',
		character: '👍🏼',
		title: 'thumbs up medium-light skin tone',
		category: 'People & Body (hand-fingers-closed)',
		group: 'People & Body',
		subgroup: 'hand-fingers-closed',
	},
	{
		codes: '1F44D 1F3FD',
		character: '👍🏽',
		title: 'thumbs up medium skin tone',
		category: 'People & Body (hand-fingers-closed)',
		group: 'People & Body',
		subgroup: 'hand-fingers-closed',
	},
	{
		codes: '1F44D 1F3FE',
		character: '👍🏾',
		title: 'thumbs up medium-dark skin tone',
		category: 'People & Body (hand-fingers-closed)',
		group: 'People & Body',
		subgroup: 'hand-fingers-closed',
	},
	{
		codes: '1F44D 1F3FF',
		character: '👍🏿',
		title: 'thumbs up dark skin tone',
		category: 'People & Body (hand-fingers-closed)',
		group: 'People & Body',
		subgroup: 'hand-fingers-closed',
	},
	{
		codes: '1F44E',
		character: '👎',
		title: 'thumbs down',
		category: 'People & Body (hand-fingers-closed)',
		group: 'People & Body',
		subgroup: 'hand-fingers-closed',
	},
	{
		codes: '1F44E 1F3FB',
		character: '👎🏻',
		title: 'thumbs down light skin tone',
		category: 'People & Body (hand-fingers-closed)',
		group: 'People & Body',
		subgroup: 'hand-fingers-closed',
	},
	{
		codes: '1F44E 1F3FC',
		character: '👎🏼',
		title: 'thumbs down medium-light skin tone',
		category: 'People & Body (hand-fingers-closed)',
		group: 'People & Body',
		subgroup: 'hand-fingers-closed',
	},
	{
		codes: '1F44E 1F3FD',
		character: '👎🏽',
		title: 'thumbs down medium skin tone',
		category: 'People & Body (hand-fingers-closed)',
		group: 'People & Body',
		subgroup: 'hand-fingers-closed',
	},
	{
		codes: '1F44E 1F3FE',
		character: '👎🏾',
		title: 'thumbs down medium-dark skin tone',
		category: 'People & Body (hand-fingers-closed)',
		group: 'People & Body',
		subgroup: 'hand-fingers-closed',
	},
	{
		codes: '1F44E 1F3FF',
		character: '👎🏿',
		title: 'thumbs down dark skin tone',
		category: 'People & Body (hand-fingers-closed)',
		group: 'People & Body',
		subgroup: 'hand-fingers-closed',
	},
	{
		codes: '270A',
		character: '✊',
		title: 'raised fist',
		category: 'People & Body (hand-fingers-closed)',
		group: 'People & Body',
		subgroup: 'hand-fingers-closed',
	},
	{
		codes: '270A 1F3FB',
		character: '✊🏻',
		title: 'raised fist light skin tone',
		category: 'People & Body (hand-fingers-closed)',
		group: 'People & Body',
		subgroup: 'hand-fingers-closed',
	},
	{
		codes: '270A 1F3FC',
		character: '✊🏼',
		title: 'raised fist medium-light skin tone',
		category: 'People & Body (hand-fingers-closed)',
		group: 'People & Body',
		subgroup: 'hand-fingers-closed',
	},
	{
		codes: '270A 1F3FD',
		character: '✊🏽',
		title: 'raised fist medium skin tone',
		category: 'People & Body (hand-fingers-closed)',
		group: 'People & Body',
		subgroup: 'hand-fingers-closed',
	},
	{
		codes: '270A 1F3FE',
		character: '✊🏾',
		title: 'raised fist medium-dark skin tone',
		category: 'People & Body (hand-fingers-closed)',
		group: 'People & Body',
		subgroup: 'hand-fingers-closed',
	},
	{
		codes: '270A 1F3FF',
		character: '✊🏿',
		title: 'raised fist dark skin tone',
		category: 'People & Body (hand-fingers-closed)',
		group: 'People & Body',
		subgroup: 'hand-fingers-closed',
	},
	{
		codes: '1F44A',
		character: '👊',
		title: 'oncoming fist',
		category: 'People & Body (hand-fingers-closed)',
		group: 'People & Body',
		subgroup: 'hand-fingers-closed',
	},
	{
		codes: '1F44A 1F3FB',
		character: '👊🏻',
		title: 'oncoming fist light skin tone',
		category: 'People & Body (hand-fingers-closed)',
		group: 'People & Body',
		subgroup: 'hand-fingers-closed',
	},
	{
		codes: '1F44A 1F3FC',
		character: '👊🏼',
		title: 'oncoming fist medium-light skin tone',
		category: 'People & Body (hand-fingers-closed)',
		group: 'People & Body',
		subgroup: 'hand-fingers-closed',
	},
	{
		codes: '1F44A 1F3FD',
		character: '👊🏽',
		title: 'oncoming fist medium skin tone',
		category: 'People & Body (hand-fingers-closed)',
		group: 'People & Body',
		subgroup: 'hand-fingers-closed',
	},
	{
		codes: '1F44A 1F3FE',
		character: '👊🏾',
		title: 'oncoming fist medium-dark skin tone',
		category: 'People & Body (hand-fingers-closed)',
		group: 'People & Body',
		subgroup: 'hand-fingers-closed',
	},
	{
		codes: '1F44A 1F3FF',
		character: '👊🏿',
		title: 'oncoming fist dark skin tone',
		category: 'People & Body (hand-fingers-closed)',
		group: 'People & Body',
		subgroup: 'hand-fingers-closed',
	},
	{
		codes: '1F91B',
		character: '🤛',
		title: 'left-facing fist',
		category: 'People & Body (hand-fingers-closed)',
		group: 'People & Body',
		subgroup: 'hand-fingers-closed',
	},
	{
		codes: '1F91B 1F3FB',
		character: '🤛🏻',
		title: 'left-facing fist light skin tone',
		category: 'People & Body (hand-fingers-closed)',
		group: 'People & Body',
		subgroup: 'hand-fingers-closed',
	},
	{
		codes: '1F91B 1F3FC',
		character: '🤛🏼',
		title: 'left-facing fist medium-light skin tone',
		category: 'People & Body (hand-fingers-closed)',
		group: 'People & Body',
		subgroup: 'hand-fingers-closed',
	},
	{
		codes: '1F91B 1F3FD',
		character: '🤛🏽',
		title: 'left-facing fist medium skin tone',
		category: 'People & Body (hand-fingers-closed)',
		group: 'People & Body',
		subgroup: 'hand-fingers-closed',
	},
	{
		codes: '1F91B 1F3FE',
		character: '🤛🏾',
		title: 'left-facing fist medium-dark skin tone',
		category: 'People & Body (hand-fingers-closed)',
		group: 'People & Body',
		subgroup: 'hand-fingers-closed',
	},
	{
		codes: '1F91B 1F3FF',
		character: '🤛🏿',
		title: 'left-facing fist dark skin tone',
		category: 'People & Body (hand-fingers-closed)',
		group: 'People & Body',
		subgroup: 'hand-fingers-closed',
	},
	{
		codes: '1F91C',
		character: '🤜',
		title: 'right-facing fist',
		category: 'People & Body (hand-fingers-closed)',
		group: 'People & Body',
		subgroup: 'hand-fingers-closed',
	},
	{
		codes: '1F91C 1F3FB',
		character: '🤜🏻',
		title: 'right-facing fist light skin tone',
		category: 'People & Body (hand-fingers-closed)',
		group: 'People & Body',
		subgroup: 'hand-fingers-closed',
	},
	{
		codes: '1F91C 1F3FC',
		character: '🤜🏼',
		title: 'right-facing fist medium-light skin tone',
		category: 'People & Body (hand-fingers-closed)',
		group: 'People & Body',
		subgroup: 'hand-fingers-closed',
	},
	{
		codes: '1F91C 1F3FD',
		character: '🤜🏽',
		title: 'right-facing fist medium skin tone',
		category: 'People & Body (hand-fingers-closed)',
		group: 'People & Body',
		subgroup: 'hand-fingers-closed',
	},
	{
		codes: '1F91C 1F3FE',
		character: '🤜🏾',
		title: 'right-facing fist medium-dark skin tone',
		category: 'People & Body (hand-fingers-closed)',
		group: 'People & Body',
		subgroup: 'hand-fingers-closed',
	},
	{
		codes: '1F91C 1F3FF',
		character: '🤜🏿',
		title: 'right-facing fist dark skin tone',
		category: 'People & Body (hand-fingers-closed)',
		group: 'People & Body',
		subgroup: 'hand-fingers-closed',
	},
	{
		codes: '1F44F',
		character: '👏',
		title: 'clapping hands',
		category: 'People & Body (hands)',
		group: 'People & Body',
		subgroup: 'hands',
	},
	{
		codes: '1F44F 1F3FB',
		character: '👏🏻',
		title: 'clapping hands light skin tone',
		category: 'People & Body (hands)',
		group: 'People & Body',
		subgroup: 'hands',
	},
	{
		codes: '1F44F 1F3FC',
		character: '👏🏼',
		title: 'clapping hands medium-light skin tone',
		category: 'People & Body (hands)',
		group: 'People & Body',
		subgroup: 'hands',
	},
	{
		codes: '1F44F 1F3FD',
		character: '👏🏽',
		title: 'clapping hands medium skin tone',
		category: 'People & Body (hands)',
		group: 'People & Body',
		subgroup: 'hands',
	},
	{
		codes: '1F44F 1F3FE',
		character: '👏🏾',
		title: 'clapping hands medium-dark skin tone',
		category: 'People & Body (hands)',
		group: 'People & Body',
		subgroup: 'hands',
	},
	{
		codes: '1F44F 1F3FF',
		character: '👏🏿',
		title: 'clapping hands dark skin tone',
		category: 'People & Body (hands)',
		group: 'People & Body',
		subgroup: 'hands',
	},
	{
		codes: '1F64C',
		character: '🙌',
		title: 'raising hands',
		category: 'People & Body (hands)',
		group: 'People & Body',
		subgroup: 'hands',
	},
	{
		codes: '1F64C 1F3FB',
		character: '🙌🏻',
		title: 'raising hands light skin tone',
		category: 'People & Body (hands)',
		group: 'People & Body',
		subgroup: 'hands',
	},
	{
		codes: '1F64C 1F3FC',
		character: '🙌🏼',
		title: 'raising hands medium-light skin tone',
		category: 'People & Body (hands)',
		group: 'People & Body',
		subgroup: 'hands',
	},
	{
		codes: '1F64C 1F3FD',
		character: '🙌🏽',
		title: 'raising hands medium skin tone',
		category: 'People & Body (hands)',
		group: 'People & Body',
		subgroup: 'hands',
	},
	{
		codes: '1F64C 1F3FE',
		character: '🙌🏾',
		title: 'raising hands medium-dark skin tone',
		category: 'People & Body (hands)',
		group: 'People & Body',
		subgroup: 'hands',
	},
	{
		codes: '1F64C 1F3FF',
		character: '🙌🏿',
		title: 'raising hands dark skin tone',
		category: 'People & Body (hands)',
		group: 'People & Body',
		subgroup: 'hands',
	},
	{
		codes: '1F450',
		character: '👐',
		title: 'open hands',
		category: 'People & Body (hands)',
		group: 'People & Body',
		subgroup: 'hands',
	},
	{
		codes: '1F450 1F3FB',
		character: '👐🏻',
		title: 'open hands light skin tone',
		category: 'People & Body (hands)',
		group: 'People & Body',
		subgroup: 'hands',
	},
	{
		codes: '1F450 1F3FC',
		character: '👐🏼',
		title: 'open hands medium-light skin tone',
		category: 'People & Body (hands)',
		group: 'People & Body',
		subgroup: 'hands',
	},
	{
		codes: '1F450 1F3FD',
		character: '👐🏽',
		title: 'open hands medium skin tone',
		category: 'People & Body (hands)',
		group: 'People & Body',
		subgroup: 'hands',
	},
	{
		codes: '1F450 1F3FE',
		character: '👐🏾',
		title: 'open hands medium-dark skin tone',
		category: 'People & Body (hands)',
		group: 'People & Body',
		subgroup: 'hands',
	},
	{
		codes: '1F450 1F3FF',
		character: '👐🏿',
		title: 'open hands dark skin tone',
		category: 'People & Body (hands)',
		group: 'People & Body',
		subgroup: 'hands',
	},
	{
		codes: '1F932',
		character: '🤲',
		title: 'palms up together',
		category: 'People & Body (hands)',
		group: 'People & Body',
		subgroup: 'hands',
	},
	{
		codes: '1F932 1F3FB',
		character: '🤲🏻',
		title: 'palms up together light skin tone',
		category: 'People & Body (hands)',
		group: 'People & Body',
		subgroup: 'hands',
	},
	{
		codes: '1F932 1F3FC',
		character: '🤲🏼',
		title: 'palms up together medium-light skin tone',
		category: 'People & Body (hands)',
		group: 'People & Body',
		subgroup: 'hands',
	},
	{
		codes: '1F932 1F3FD',
		character: '🤲🏽',
		title: 'palms up together medium skin tone',
		category: 'People & Body (hands)',
		group: 'People & Body',
		subgroup: 'hands',
	},
	{
		codes: '1F932 1F3FE',
		character: '🤲🏾',
		title: 'palms up together medium-dark skin tone',
		category: 'People & Body (hands)',
		group: 'People & Body',
		subgroup: 'hands',
	},
	{
		codes: '1F932 1F3FF',
		character: '🤲🏿',
		title: 'palms up together dark skin tone',
		category: 'People & Body (hands)',
		group: 'People & Body',
		subgroup: 'hands',
	},
	{
		codes: '1F91D',
		character: '🤝',
		title: 'handshake',
		category: 'People & Body (hands)',
		group: 'People & Body',
		subgroup: 'hands',
	},
	{
		codes: '1F64F',
		character: '🙏',
		title: 'folded hands',
		category: 'People & Body (hands)',
		group: 'People & Body',
		subgroup: 'hands',
	},
	{
		codes: '1F64F 1F3FB',
		character: '🙏🏻',
		title: 'folded hands light skin tone',
		category: 'People & Body (hands)',
		group: 'People & Body',
		subgroup: 'hands',
	},
	{
		codes: '1F64F 1F3FC',
		character: '🙏🏼',
		title: 'folded hands medium-light skin tone',
		category: 'People & Body (hands)',
		group: 'People & Body',
		subgroup: 'hands',
	},
	{
		codes: '1F64F 1F3FD',
		character: '🙏🏽',
		title: 'folded hands medium skin tone',
		category: 'People & Body (hands)',
		group: 'People & Body',
		subgroup: 'hands',
	},
	{
		codes: '1F64F 1F3FE',
		character: '🙏🏾',
		title: 'folded hands medium-dark skin tone',
		category: 'People & Body (hands)',
		group: 'People & Body',
		subgroup: 'hands',
	},
	{
		codes: '1F64F 1F3FF',
		character: '🙏🏿',
		title: 'folded hands dark skin tone',
		category: 'People & Body (hands)',
		group: 'People & Body',
		subgroup: 'hands',
	},
	{
		codes: '1F4AA',
		character: '💪',
		title: 'flexed biceps',
		category: 'People & Body (body-parts)',
		group: 'People & Body',
		subgroup: 'body-parts',
	},
	{
		codes: '1F4AA 1F3FB',
		character: '💪🏻',
		title: 'flexed biceps light skin tone',
		category: 'People & Body (body-parts)',
		group: 'People & Body',
		subgroup: 'body-parts',
	},
	{
		codes: '1F4AA 1F3FC',
		character: '💪🏼',
		title: 'flexed biceps medium-light skin tone',
		category: 'People & Body (body-parts)',
		group: 'People & Body',
		subgroup: 'body-parts',
	},
	{
		codes: '1F4AA 1F3FD',
		character: '💪🏽',
		title: 'flexed biceps medium skin tone',
		category: 'People & Body (body-parts)',
		group: 'People & Body',
		subgroup: 'body-parts',
	},
	{
		codes: '1F4AA 1F3FE',
		character: '💪🏾',
		title: 'flexed biceps medium-dark skin tone',
		category: 'People & Body (body-parts)',
		group: 'People & Body',
		subgroup: 'body-parts',
	},
	{
		codes: '1F4AA 1F3FF',
		character: '💪🏿',
		title: 'flexed biceps dark skin tone',
		category: 'People & Body (body-parts)',
		group: 'People & Body',
		subgroup: 'body-parts',
	},
	{
		codes: '1F9BE',
		character: '🦾',
		title: 'mechanical arm',
		category: 'People & Body (body-parts)',
		group: 'People & Body',
		subgroup: 'body-parts',
	},
	{
		codes: '1F9BF',
		character: '🦿',
		title: 'mechanical leg',
		category: 'People & Body (body-parts)',
		group: 'People & Body',
		subgroup: 'body-parts',
	},
	{
		codes: '1F9B5',
		character: '🦵',
		title: 'leg',
		category: 'People & Body (body-parts)',
		group: 'People & Body',
		subgroup: 'body-parts',
	},
	{
		codes: '1F9B5 1F3FB',
		character: '🦵🏻',
		title: 'leg light skin tone',
		category: 'People & Body (body-parts)',
		group: 'People & Body',
		subgroup: 'body-parts',
	},
	{
		codes: '1F9B5 1F3FC',
		character: '🦵🏼',
		title: 'leg medium-light skin tone',
		category: 'People & Body (body-parts)',
		group: 'People & Body',
		subgroup: 'body-parts',
	},
	{
		codes: '1F9B5 1F3FD',
		character: '🦵🏽',
		title: 'leg medium skin tone',
		category: 'People & Body (body-parts)',
		group: 'People & Body',
		subgroup: 'body-parts',
	},
	{
		codes: '1F9B5 1F3FE',
		character: '🦵🏾',
		title: 'leg medium-dark skin tone',
		category: 'People & Body (body-parts)',
		group: 'People & Body',
		subgroup: 'body-parts',
	},
	{
		codes: '1F9B5 1F3FF',
		character: '🦵🏿',
		title: 'leg dark skin tone',
		category: 'People & Body (body-parts)',
		group: 'People & Body',
		subgroup: 'body-parts',
	},
	{
		codes: '1F9B6',
		character: '🦶',
		title: 'foot',
		category: 'People & Body (body-parts)',
		group: 'People & Body',
		subgroup: 'body-parts',
	},
	{
		codes: '1F9B6 1F3FB',
		character: '🦶🏻',
		title: 'foot light skin tone',
		category: 'People & Body (body-parts)',
		group: 'People & Body',
		subgroup: 'body-parts',
	},
	{
		codes: '1F9B6 1F3FC',
		character: '🦶🏼',
		title: 'foot medium-light skin tone',
		category: 'People & Body (body-parts)',
		group: 'People & Body',
		subgroup: 'body-parts',
	},
	{
		codes: '1F9B6 1F3FD',
		character: '🦶🏽',
		title: 'foot medium skin tone',
		category: 'People & Body (body-parts)',
		group: 'People & Body',
		subgroup: 'body-parts',
	},
	{
		codes: '1F9B6 1F3FE',
		character: '🦶🏾',
		title: 'foot medium-dark skin tone',
		category: 'People & Body (body-parts)',
		group: 'People & Body',
		subgroup: 'body-parts',
	},
	{
		codes: '1F9B6 1F3FF',
		character: '🦶🏿',
		title: 'foot dark skin tone',
		category: 'People & Body (body-parts)',
		group: 'People & Body',
		subgroup: 'body-parts',
	},
	{
		codes: '1F442',
		character: '👂',
		title: 'ear',
		category: 'People & Body (body-parts)',
		group: 'People & Body',
		subgroup: 'body-parts',
	},
	{
		codes: '1F442 1F3FB',
		character: '👂🏻',
		title: 'ear light skin tone',
		category: 'People & Body (body-parts)',
		group: 'People & Body',
		subgroup: 'body-parts',
	},
	{
		codes: '1F442 1F3FC',
		character: '👂🏼',
		title: 'ear medium-light skin tone',
		category: 'People & Body (body-parts)',
		group: 'People & Body',
		subgroup: 'body-parts',
	},
	{
		codes: '1F442 1F3FD',
		character: '👂🏽',
		title: 'ear medium skin tone',
		category: 'People & Body (body-parts)',
		group: 'People & Body',
		subgroup: 'body-parts',
	},
	{
		codes: '1F442 1F3FE',
		character: '👂🏾',
		title: 'ear medium-dark skin tone',
		category: 'People & Body (body-parts)',
		group: 'People & Body',
		subgroup: 'body-parts',
	},
	{
		codes: '1F442 1F3FF',
		character: '👂🏿',
		title: 'ear dark skin tone',
		category: 'People & Body (body-parts)',
		group: 'People & Body',
		subgroup: 'body-parts',
	},
	{
		codes: '1F9BB',
		character: '🦻',
		title: 'ear with hearing aid',
		category: 'People & Body (body-parts)',
		group: 'People & Body',
		subgroup: 'body-parts',
	},
	{
		codes: '1F9BB 1F3FB',
		character: '🦻🏻',
		title: 'ear with hearing aid light skin tone',
		category: 'People & Body (body-parts)',
		group: 'People & Body',
		subgroup: 'body-parts',
	},
	{
		codes: '1F9BB 1F3FC',
		character: '🦻🏼',
		title: 'ear with hearing aid medium-light skin tone',
		category: 'People & Body (body-parts)',
		group: 'People & Body',
		subgroup: 'body-parts',
	},
	{
		codes: '1F9BB 1F3FD',
		character: '🦻🏽',
		title: 'ear with hearing aid medium skin tone',
		category: 'People & Body (body-parts)',
		group: 'People & Body',
		subgroup: 'body-parts',
	},
	{
		codes: '1F9BB 1F3FE',
		character: '🦻🏾',
		title: 'ear with hearing aid medium-dark skin tone',
		category: 'People & Body (body-parts)',
		group: 'People & Body',
		subgroup: 'body-parts',
	},
	{
		codes: '1F9BB 1F3FF',
		character: '🦻🏿',
		title: 'ear with hearing aid dark skin tone',
		category: 'People & Body (body-parts)',
		group: 'People & Body',
		subgroup: 'body-parts',
	},
	{
		codes: '1F443',
		character: '👃',
		title: 'nose',
		category: 'People & Body (body-parts)',
		group: 'People & Body',
		subgroup: 'body-parts',
	},
	{
		codes: '1F443 1F3FB',
		character: '👃🏻',
		title: 'nose light skin tone',
		category: 'People & Body (body-parts)',
		group: 'People & Body',
		subgroup: 'body-parts',
	},
	{
		codes: '1F443 1F3FC',
		character: '👃🏼',
		title: 'nose medium-light skin tone',
		category: 'People & Body (body-parts)',
		group: 'People & Body',
		subgroup: 'body-parts',
	},
	{
		codes: '1F443 1F3FD',
		character: '👃🏽',
		title: 'nose medium skin tone',
		category: 'People & Body (body-parts)',
		group: 'People & Body',
		subgroup: 'body-parts',
	},
	{
		codes: '1F443 1F3FE',
		character: '👃🏾',
		title: 'nose medium-dark skin tone',
		category: 'People & Body (body-parts)',
		group: 'People & Body',
		subgroup: 'body-parts',
	},
	{
		codes: '1F443 1F3FF',
		character: '👃🏿',
		title: 'nose dark skin tone',
		category: 'People & Body (body-parts)',
		group: 'People & Body',
		subgroup: 'body-parts',
	},
	{
		codes: '1F9E0',
		character: '🧠',
		title: 'brain',
		category: 'People & Body (body-parts)',
		group: 'People & Body',
		subgroup: 'body-parts',
	},
	{
		codes: '1F9B7',
		character: '🦷',
		title: 'tooth',
		category: 'People & Body (body-parts)',
		group: 'People & Body',
		subgroup: 'body-parts',
	},
	{
		codes: '1F9B4',
		character: '🦴',
		title: 'bone',
		category: 'People & Body (body-parts)',
		group: 'People & Body',
		subgroup: 'body-parts',
	},
	{
		codes: '1F440',
		character: '👀',
		title: 'eyes',
		category: 'People & Body (body-parts)',
		group: 'People & Body',
		subgroup: 'body-parts',
	},
	{
		codes: '1F441 FE0F',
		character: '👁️',
		title: 'eye',
		category: 'People & Body (body-parts)',
		group: 'People & Body',
		subgroup: 'body-parts',
	},
	{
		codes: '1F445',
		character: '👅',
		title: 'tongue',
		category: 'People & Body (body-parts)',
		group: 'People & Body',
		subgroup: 'body-parts',
	},
	{
		codes: '1F444',
		character: '👄',
		title: 'mouth',
		category: 'People & Body (body-parts)',
		group: 'People & Body',
		subgroup: 'body-parts',
	},
	{
		codes: '1F93A',
		character: '🤺',
		title: 'person fencing',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F3C7',
		character: '🏇',
		title: 'horse racing',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F3C7 1F3FB',
		character: '🏇🏻',
		title: 'horse racing light skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F3C7 1F3FC',
		character: '🏇🏼',
		title: 'horse racing medium-light skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F3C7 1F3FD',
		character: '🏇🏽',
		title: 'horse racing medium skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F3C7 1F3FE',
		character: '🏇🏾',
		title: 'horse racing medium-dark skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F3C7 1F3FF',
		character: '🏇🏿',
		title: 'horse racing dark skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '26F7 FE0F',
		character: '⛷️',
		title: 'skier',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F3C2',
		character: '🏂',
		title: 'snowboarder',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F3C2 1F3FB',
		character: '🏂🏻',
		title: 'snowboarder light skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F3C2 1F3FC',
		character: '🏂🏼',
		title: 'snowboarder medium-light skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F3C2 1F3FD',
		character: '🏂🏽',
		title: 'snowboarder medium skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F3C2 1F3FE',
		character: '🏂🏾',
		title: 'snowboarder medium-dark skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F3C2 1F3FF',
		character: '🏂🏿',
		title: 'snowboarder dark skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F3CC FE0F',
		character: '🏌️',
		title: 'person golfing',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F3CC 1F3FB',
		character: '🏌🏻',
		title: 'person golfing light skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F3CC 1F3FC',
		character: '🏌🏼',
		title: 'person golfing medium-light skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F3CC 1F3FD',
		character: '🏌🏽',
		title: 'person golfing medium skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F3CC 1F3FE',
		character: '🏌🏾',
		title: 'person golfing medium-dark skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F3CC 1F3FF',
		character: '🏌🏿',
		title: 'person golfing dark skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F3CC FE0F 200D 2642 FE0F',
		character: '🏌️‍♂️',
		title: 'man golfing',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F3CC 1F3FB 200D 2642 FE0F',
		character: '🏌🏻‍♂️',
		title: 'man golfing light skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F3CC 1F3FC 200D 2642 FE0F',
		character: '🏌🏼‍♂️',
		title: 'man golfing medium-light skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F3CC 1F3FD 200D 2642 FE0F',
		character: '🏌🏽‍♂️',
		title: 'man golfing medium skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F3CC 1F3FE 200D 2642',
		character: '🏌🏾‍♂',
		title: 'man golfing medium-dark skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F3CC 1F3FF 200D 2642 FE0F',
		character: '🏌🏿‍♂️',
		title: 'man golfing dark skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F3CC FE0F 200D 2640 FE0F',
		character: '🏌️‍♀️',
		title: 'woman golfing',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F3CC 1F3FB 200D 2640 FE0F',
		character: '🏌🏻‍♀️',
		title: 'woman golfing light skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F3CC 1F3FC 200D 2640 FE0F',
		character: '🏌🏼‍♀️',
		title: 'woman golfing medium-light skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F3CC 1F3FD 200D 2640 FE0F',
		character: '🏌🏽‍♀️',
		title: 'woman golfing medium skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F3CC 1F3FE 200D 2640 FE0F',
		character: '🏌🏾‍♀️',
		title: 'woman golfing medium-dark skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F3CC 1F3FF 200D 2640 FE0F',
		character: '🏌🏿‍♀️',
		title: 'woman golfing dark skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F3C4',
		character: '🏄',
		title: 'person surfing',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F3C4 1F3FB',
		character: '🏄🏻',
		title: 'person surfing light skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F3C4 1F3FC',
		character: '🏄🏼',
		title: 'person surfing medium-light skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F3C4 1F3FD',
		character: '🏄🏽',
		title: 'person surfing medium skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F3C4 1F3FE',
		character: '🏄🏾',
		title: 'person surfing medium-dark skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F3C4 1F3FF',
		character: '🏄🏿',
		title: 'person surfing dark skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F3C4 200D 2642 FE0F',
		character: '🏄‍♂️',
		title: 'man surfing',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F3C4 1F3FB 200D 2642 FE0F',
		character: '🏄🏻‍♂️',
		title: 'man surfing light skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F3C4 1F3FC 200D 2642 FE0F',
		character: '🏄🏼‍♂️',
		title: 'man surfing medium-light skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F3C4 1F3FD 200D 2642 FE0F',
		character: '🏄🏽‍♂️',
		title: 'man surfing medium skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F3C4 1F3FE 200D 2642 FE0F',
		character: '🏄🏾‍♂️',
		title: 'man surfing medium-dark skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F3C4 1F3FF 200D 2642 FE0F',
		character: '🏄🏿‍♂️',
		title: 'man surfing dark skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F3C4 200D 2640 FE0F',
		character: '🏄‍♀️',
		title: 'woman surfing',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F3C4 1F3FB 200D 2640 FE0F',
		character: '🏄🏻‍♀️',
		title: 'woman surfing light skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F3C4 1F3FC 200D 2640 FE0F',
		character: '🏄🏼‍♀️',
		title: 'woman surfing medium-light skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F3C4 1F3FD 200D 2640 FE0F',
		character: '🏄🏽‍♀️',
		title: 'woman surfing medium skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F3C4 1F3FE 200D 2640 FE0F',
		character: '🏄🏾‍♀️',
		title: 'woman surfing medium-dark skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F3C4 1F3FF 200D 2640 FE0F',
		character: '🏄🏿‍♀️',
		title: 'woman surfing dark skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F6A3',
		character: '🚣',
		title: 'person rowing boat',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F6A3 1F3FB',
		character: '🚣🏻',
		title: 'person rowing boat light skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F6A3 1F3FC',
		character: '🚣🏼',
		title: 'person rowing boat medium-light skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F6A3 1F3FD',
		character: '🚣🏽',
		title: 'person rowing boat medium skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F6A3 1F3FE',
		character: '🚣🏾',
		title: 'person rowing boat medium-dark skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F6A3 1F3FF',
		character: '🚣🏿',
		title: 'person rowing boat dark skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F6A3 200D 2642 FE0F',
		character: '🚣‍♂️',
		title: 'man rowing boat',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F6A3 1F3FB 200D 2642 FE0F',
		character: '🚣🏻‍♂️',
		title: 'man rowing boat light skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F6A3 1F3FC 200D 2642 FE0F',
		character: '🚣🏼‍♂️',
		title: 'man rowing boat medium-light skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F6A3 1F3FD 200D 2642 FE0F',
		character: '🚣🏽‍♂️',
		title: 'man rowing boat medium skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F6A3 1F3FE 200D 2642 FE0F',
		character: '🚣🏾‍♂️',
		title: 'man rowing boat medium-dark skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F6A3 1F3FF 200D 2642 FE0F',
		character: '🚣🏿‍♂️',
		title: 'man rowing boat dark skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F6A3 200D 2640 FE0F',
		character: '🚣‍♀️',
		title: 'woman rowing boat',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F6A3 1F3FB 200D 2640 FE0F',
		character: '🚣🏻‍♀️',
		title: 'woman rowing boat light skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F6A3 1F3FC 200D 2640 FE0F',
		character: '🚣🏼‍♀️',
		title: 'woman rowing boat medium-light skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F6A3 1F3FD 200D 2640 FE0F',
		character: '🚣🏽‍♀️',
		title: 'woman rowing boat medium skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F6A3 1F3FE 200D 2640 FE0F',
		character: '🚣🏾‍♀️',
		title: 'woman rowing boat medium-dark skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F6A3 1F3FF 200D 2640 FE0F',
		character: '🚣🏿‍♀️',
		title: 'woman rowing boat dark skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F3CA',
		character: '🏊',
		title: 'person swimming',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F3CA 1F3FB',
		character: '🏊🏻',
		title: 'person swimming light skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F3CA 1F3FC',
		character: '🏊🏼',
		title: 'person swimming medium-light skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F3CA 1F3FD',
		character: '🏊🏽',
		title: 'person swimming medium skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F3CA 1F3FE',
		character: '🏊🏾',
		title: 'person swimming medium-dark skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F3CA 1F3FF',
		character: '🏊🏿',
		title: 'person swimming dark skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F3CA 200D 2642 FE0F',
		character: '🏊‍♂️',
		title: 'man swimming',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F3CA 1F3FB 200D 2642 FE0F',
		character: '🏊🏻‍♂️',
		title: 'man swimming light skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F3CA 1F3FC 200D 2642 FE0F',
		character: '🏊🏼‍♂️',
		title: 'man swimming medium-light skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F3CA 1F3FD 200D 2642 FE0F',
		character: '🏊🏽‍♂️',
		title: 'man swimming medium skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F3CA 1F3FE 200D 2642 FE0F',
		character: '🏊🏾‍♂️',
		title: 'man swimming medium-dark skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F3CA 1F3FF 200D 2642 FE0F',
		character: '🏊🏿‍♂️',
		title: 'man swimming dark skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F3CA 200D 2640 FE0F',
		character: '🏊‍♀️',
		title: 'woman swimming',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F3CA 1F3FB 200D 2640 FE0F',
		character: '🏊🏻‍♀️',
		title: 'woman swimming light skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F3CA 1F3FC 200D 2640 FE0F',
		character: '🏊🏼‍♀️',
		title: 'woman swimming medium-light skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F3CA 1F3FD 200D 2640 FE0F',
		character: '🏊🏽‍♀️',
		title: 'woman swimming medium skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F3CA 1F3FE 200D 2640 FE0F',
		character: '🏊🏾‍♀️',
		title: 'woman swimming medium-dark skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F3CA 1F3FF 200D 2640 FE0F',
		character: '🏊🏿‍♀️',
		title: 'woman swimming dark skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '26F9 FE0F',
		character: '⛹️',
		title: 'person bouncing ball',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '26F9 1F3FB',
		character: '⛹🏻',
		title: 'person bouncing ball light skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '26F9 1F3FC',
		character: '⛹🏼',
		title: 'person bouncing ball medium-light skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '26F9 1F3FD',
		character: '⛹🏽',
		title: 'person bouncing ball medium skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '26F9 1F3FE',
		character: '⛹🏾',
		title: 'person bouncing ball medium-dark skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '26F9 1F3FF',
		character: '⛹🏿',
		title: 'person bouncing ball dark skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '26F9 FE0F 200D 2642 FE0F',
		character: '⛹️‍♂️',
		title: 'man bouncing ball',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '26F9 1F3FB 200D 2642 FE0F',
		character: '⛹🏻‍♂️',
		title: 'man bouncing ball light skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '26F9 1F3FC 200D 2642 FE0F',
		character: '⛹🏼‍♂️',
		title: 'man bouncing ball medium-light skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '26F9 1F3FD 200D 2642 FE0F',
		character: '⛹🏽‍♂️',
		title: 'man bouncing ball medium skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '26F9 1F3FE 200D 2642 FE0F',
		character: '⛹🏾‍♂️',
		title: 'man bouncing ball medium-dark skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '26F9 1F3FF 200D 2642 FE0F',
		character: '⛹🏿‍♂️',
		title: 'man bouncing ball dark skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '26F9 FE0F 200D 2640 FE0F',
		character: '⛹️‍♀️',
		title: 'woman bouncing ball',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '26F9 1F3FB 200D 2640 FE0F',
		character: '⛹🏻‍♀️',
		title: 'woman bouncing ball light skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '26F9 1F3FC 200D 2640 FE0F',
		character: '⛹🏼‍♀️',
		title: 'woman bouncing ball medium-light skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '26F9 1F3FD 200D 2640 FE0F',
		character: '⛹🏽‍♀️',
		title: 'woman bouncing ball medium skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '26F9 1F3FE 200D 2640 FE0F',
		character: '⛹🏾‍♀️',
		title: 'woman bouncing ball medium-dark skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '26F9 1F3FF 200D 2640 FE0F',
		character: '⛹🏿‍♀️',
		title: 'woman bouncing ball dark skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F3CB FE0F',
		character: '🏋️',
		title: 'person lifting weights',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F3CB 1F3FB',
		character: '🏋🏻',
		title: 'person lifting weights light skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F3CB 1F3FC',
		character: '🏋🏼',
		title: 'person lifting weights medium-light skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F3CB 1F3FD',
		character: '🏋🏽',
		title: 'person lifting weights medium skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F3CB 1F3FE',
		character: '🏋🏾',
		title: 'person lifting weights medium-dark skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F3CB 1F3FF',
		character: '🏋🏿',
		title: 'person lifting weights dark skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F3CB FE0F 200D 2642 FE0F',
		character: '🏋️‍♂️',
		title: 'man lifting weights',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F3CB 1F3FB 200D 2642 FE0F',
		character: '🏋🏻‍♂️',
		title: 'man lifting weights light skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F3CB 1F3FC 200D 2642 FE0F',
		character: '🏋🏼‍♂️',
		title: 'man lifting weights medium-light skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F3CB 1F3FD 200D 2642 FE0F',
		character: '🏋🏽‍♂️',
		title: 'man lifting weights medium skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F3CB 1F3FE 200D 2642 FE0F',
		character: '🏋🏾‍♂️',
		title: 'man lifting weights medium-dark skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F3CB 1F3FF 200D 2642 FE0F',
		character: '🏋🏿‍♂️',
		title: 'man lifting weights dark skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F3CB FE0F 200D 2640 FE0F',
		character: '🏋️‍♀️',
		title: 'woman lifting weights',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F3CB 1F3FB 200D 2640 FE0F',
		character: '🏋🏻‍♀️',
		title: 'woman lifting weights light skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F3CB 1F3FC 200D 2640 FE0F',
		character: '🏋🏼‍♀️',
		title: 'woman lifting weights medium-light skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F3CB 1F3FD 200D 2640 FE0F',
		character: '🏋🏽‍♀️',
		title: 'woman lifting weights medium skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F3CB 1F3FE 200D 2640 FE0F',
		character: '🏋🏾‍♀️',
		title: 'woman lifting weights medium-dark skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F3CB 1F3FF 200D 2640 FE0F',
		character: '🏋🏿‍♀️',
		title: 'woman lifting weights dark skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F6B4',
		character: '🚴',
		title: 'person biking',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F6B4 1F3FB',
		character: '🚴🏻',
		title: 'person biking light skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F6B4 1F3FC',
		character: '🚴🏼',
		title: 'person biking medium-light skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F6B4 1F3FD',
		character: '🚴🏽',
		title: 'person biking medium skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F6B4 1F3FE',
		character: '🚴🏾',
		title: 'person biking medium-dark skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F6B4 1F3FF',
		character: '🚴🏿',
		title: 'person biking dark skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F6B4 200D 2642 FE0F',
		character: '🚴‍♂️',
		title: 'man biking',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F6B4 1F3FB 200D 2642 FE0F',
		character: '🚴🏻‍♂️',
		title: 'man biking light skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F6B4 1F3FC 200D 2642 FE0F',
		character: '🚴🏼‍♂️',
		title: 'man biking medium-light skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F6B4 1F3FD 200D 2642 FE0F',
		character: '🚴🏽‍♂️',
		title: 'man biking medium skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F6B4 1F3FE 200D 2642 FE0F',
		character: '🚴🏾‍♂️',
		title: 'man biking medium-dark skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F6B4 1F3FF 200D 2642 FE0F',
		character: '🚴🏿‍♂️',
		title: 'man biking dark skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F6B4 200D 2640 FE0F',
		character: '🚴‍♀️',
		title: 'woman biking',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F6B4 1F3FB 200D 2640 FE0F',
		character: '🚴🏻‍♀️',
		title: 'woman biking light skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F6B4 1F3FC 200D 2640 FE0F',
		character: '🚴🏼‍♀️',
		title: 'woman biking medium-light skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F6B4 1F3FD 200D 2640 FE0F',
		character: '🚴🏽‍♀️',
		title: 'woman biking medium skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F6B4 1F3FE 200D 2640 FE0F',
		character: '🚴🏾‍♀️',
		title: 'woman biking medium-dark skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F6B4 1F3FF 200D 2640 FE0F',
		character: '🚴🏿‍♀️',
		title: 'woman biking dark skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F6B5',
		character: '🚵',
		title: 'person mountain biking',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F6B5 1F3FB',
		character: '🚵🏻',
		title: 'person mountain biking light skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F6B5 1F3FC',
		character: '🚵🏼',
		title: 'person mountain biking medium-light skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F6B5 1F3FD',
		character: '🚵🏽',
		title: 'person mountain biking medium skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F6B5 1F3FE',
		character: '🚵🏾',
		title: 'person mountain biking medium-dark skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F6B5 1F3FF',
		character: '🚵🏿',
		title: 'person mountain biking dark skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F6B5 200D 2642 FE0F',
		character: '🚵‍♂️',
		title: 'man mountain biking',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F6B5 1F3FB 200D 2642 FE0F',
		character: '🚵🏻‍♂️',
		title: 'man mountain biking light skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F6B5 1F3FC 200D 2642 FE0F',
		character: '🚵🏼‍♂️',
		title: 'man mountain biking medium-light skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F6B5 1F3FD 200D 2642 FE0F',
		character: '🚵🏽‍♂️',
		title: 'man mountain biking medium skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F6B5 1F3FE 200D 2642 FE0F',
		character: '🚵🏾‍♂️',
		title: 'man mountain biking medium-dark skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F6B5 1F3FF 200D 2642 FE0F',
		character: '🚵🏿‍♂️',
		title: 'man mountain biking dark skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F6B5 200D 2640 FE0F',
		character: '🚵‍♀️',
		title: 'woman mountain biking',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F6B5 1F3FB 200D 2640 FE0F',
		character: '🚵🏻‍♀️',
		title: 'woman mountain biking light skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F6B5 1F3FC 200D 2640 FE0F',
		character: '🚵🏼‍♀️',
		title: 'woman mountain biking medium-light skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F6B5 1F3FD 200D 2640 FE0F',
		character: '🚵🏽‍♀️',
		title: 'woman mountain biking medium skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F6B5 1F3FE 200D 2640 FE0F',
		character: '🚵🏾‍♀️',
		title: 'woman mountain biking medium-dark skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F6B5 1F3FF 200D 2640 FE0F',
		character: '🚵🏿‍♀️',
		title: 'woman mountain biking dark skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F938',
		character: '🤸',
		title: 'person cartwheeling',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F938 1F3FB',
		character: '🤸🏻',
		title: 'person cartwheeling light skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F938 1F3FC',
		character: '🤸🏼',
		title: 'person cartwheeling medium-light skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F938 1F3FD',
		character: '🤸🏽',
		title: 'person cartwheeling medium skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F938 1F3FE',
		character: '🤸🏾',
		title: 'person cartwheeling medium-dark skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F938 1F3FF',
		character: '🤸🏿',
		title: 'person cartwheeling dark skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F938 200D 2642 FE0F',
		character: '🤸‍♂️',
		title: 'man cartwheeling',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F938 1F3FB 200D 2642 FE0F',
		character: '🤸🏻‍♂️',
		title: 'man cartwheeling light skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F938 1F3FC 200D 2642 FE0F',
		character: '🤸🏼‍♂️',
		title: 'man cartwheeling medium-light skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F938 1F3FD 200D 2642 FE0F',
		character: '🤸🏽‍♂️',
		title: 'man cartwheeling medium skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F938 1F3FE 200D 2642 FE0F',
		character: '🤸🏾‍♂️',
		title: 'man cartwheeling medium-dark skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F938 1F3FF 200D 2642 FE0F',
		character: '🤸🏿‍♂️',
		title: 'man cartwheeling dark skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F938 200D 2640 FE0F',
		character: '🤸‍♀️',
		title: 'woman cartwheeling',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F938 1F3FB 200D 2640 FE0F',
		character: '🤸🏻‍♀️',
		title: 'woman cartwheeling light skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F938 1F3FC 200D 2640 FE0F',
		character: '🤸🏼‍♀️',
		title: 'woman cartwheeling medium-light skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F938 1F3FD 200D 2640 FE0F',
		character: '🤸🏽‍♀️',
		title: 'woman cartwheeling medium skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F938 1F3FE 200D 2640 FE0F',
		character: '🤸🏾‍♀️',
		title: 'woman cartwheeling medium-dark skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F938 1F3FF 200D 2640 FE0F',
		character: '🤸🏿‍♀️',
		title: 'woman cartwheeling dark skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F93C',
		character: '🤼',
		title: 'people wrestling',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F93C 200D 2642 FE0F',
		character: '🤼‍♂️',
		title: 'men wrestling',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F93C 200D 2640 FE0F',
		character: '🤼‍♀️',
		title: 'women wrestling',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F93D',
		character: '🤽',
		title: 'person playing water polo',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F93D 1F3FB',
		character: '🤽🏻',
		title: 'person playing water polo: light skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F93D 1F3FC',
		character: '🤽🏼',
		title: 'person playing water polo: medium-light skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F93D 1F3FD',
		character: '🤽🏽',
		title: 'person playing water polo: medium skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F93D 1F3FE',
		character: '🤽🏾',
		title: 'person playing water polo: medium-dark skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F93D 1F3FF',
		character: '🤽🏿',
		title: 'person playing water polo: dark skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F93D 200D 2642 FE0F',
		character: '🤽‍♂️',
		title: 'man playing water polo',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F93D 1F3FB 200D 2642 FE0F',
		character: '🤽🏻‍♂️',
		title: 'man playing water polo: light skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F93D 1F3FC 200D 2642 FE0F',
		character: '🤽🏼‍♂️',
		title: 'man playing water polo: medium-light skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F93D 1F3FD 200D 2642 FE0F',
		character: '🤽🏽‍♂️',
		title: 'man playing water polo: medium skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F93D 1F3FE 200D 2642 FE0F',
		character: '🤽🏾‍♂️',
		title: 'man playing water polo: medium-dark skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F93D 1F3FF 200D 2642 FE0F',
		character: '🤽🏿‍♂️',
		title: 'man playing water polo: dark skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F93D 200D 2640 FE0F',
		character: '🤽‍♀️',
		title: 'woman playing water polo',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F93D 1F3FB 200D 2640 FE0F',
		character: '🤽🏻‍♀️',
		title: 'woman playing water polo: light skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F93D 1F3FC 200D 2640 FE0F',
		character: '🤽🏼‍♀️',
		title: 'woman playing water polo: medium-light skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F93D 1F3FD 200D 2640 FE0F',
		character: '🤽🏽‍♀️',
		title: 'woman playing water polo: medium skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F93D 1F3FE 200D 2640 FE0F',
		character: '🤽🏾‍♀️',
		title: 'woman playing water polo: medium-dark skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F93D 1F3FF 200D 2640 FE0F',
		character: '🤽🏿‍♀️',
		title: 'woman playing water polo: dark skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F93E',
		character: '🤾',
		title: 'person playing handball',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F93E 1F3FB',
		character: '🤾🏻',
		title: 'person playing handball light skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F93E 1F3FC',
		character: '🤾🏼',
		title: 'person playing handball medium-light skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F93E 1F3FD',
		character: '🤾🏽',
		title: 'person playing handball medium skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F93E 1F3FE',
		character: '🤾🏾',
		title: 'person playing handball medium-dark skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F93E 1F3FF',
		character: '🤾🏿',
		title: 'person playing handball dark skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F93E 200D 2642 FE0F',
		character: '🤾‍♂️',
		title: 'man playing handball',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F93E 1F3FB 200D 2642 FE0F',
		character: '🤾🏻‍♂️',
		title: 'man playing handball light skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F93E 1F3FC 200D 2642 FE0F',
		character: '🤾🏼‍♂️',
		title: 'man playing handball medium-light skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F93E 1F3FD 200D 2642 FE0F',
		character: '🤾🏽‍♂️',
		title: 'man playing handball medium skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F93E 1F3FE 200D 2642 FE0F',
		character: '🤾🏾‍♂️',
		title: 'man playing handball medium-dark skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F93E 1F3FF 200D 2642 FE0F',
		character: '🤾🏿‍♂️',
		title: 'man playing handball dark skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F93E 200D 2640 FE0F',
		character: '🤾‍♀️',
		title: 'woman playing handball',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F93E 1F3FB 200D 2640 FE0F',
		character: '🤾🏻‍♀️',
		title: 'woman playing handball light skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F93E 1F3FC 200D 2640 FE0F',
		character: '🤾🏼‍♀️',
		title: 'woman playing handball medium-light skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F93E 1F3FD 200D 2640 FE0F',
		character: '🤾🏽‍♀️',
		title: 'woman playing handball medium skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F93E 1F3FE 200D 2640 FE0F',
		character: '🤾🏾‍♀️',
		title: 'woman playing handball medium-dark skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F93E 1F3FF 200D 2640 FE0F',
		character: '🤾🏿‍♀️',
		title: 'woman playing handball dark skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F939',
		character: '🤹',
		title: 'person juggling',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F939 1F3FB',
		character: '🤹🏻',
		title: 'person juggling light skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F939 1F3FC',
		character: '🤹🏼',
		title: 'person juggling medium-light skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F939 1F3FD',
		character: '🤹🏽',
		title: 'person juggling medium skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F939 1F3FE',
		character: '🤹🏾',
		title: 'person juggling medium-dark skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F939 1F3FF',
		character: '🤹🏿',
		title: 'person juggling dark skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F939 200D 2642',
		character: '🤹‍♂',
		title: 'man juggling',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F939 1F3FB 200D 2642',
		character: '🤹🏻‍♂',
		title: 'man juggling light skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F939 1F3FC 200D 2642 FE0F',
		character: '🤹🏼‍♂️',
		title: 'man juggling medium-light skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F939 1F3FD 200D 2642 FE0F',
		character: '🤹🏽‍♂️',
		title: 'man juggling medium skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F939 1F3FE 200D 2642 FE0F',
		character: '🤹🏾‍♂️',
		title: 'man juggling medium-dark skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F939 1F3FF 200D 2642 FE0F',
		character: '🤹🏿‍♂️',
		title: 'man juggling dark skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F939 200D 2640 FE0F',
		character: '🤹‍♀️',
		title: 'woman juggling',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F939 1F3FB 200D 2640 FE0F',
		character: '🤹🏻‍♀️',
		title: 'woman juggling light skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F939 1F3FC 200D 2640 FE0F',
		character: '🤹🏼‍♀️',
		title: 'woman juggling medium-light skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F939 1F3FD 200D 2640 FE0F',
		character: '🤹🏽‍♀️',
		title: 'woman juggling medium skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F939 1F3FE 200D 2640 FE0F',
		character: '🤹🏾‍♀️',
		title: 'woman juggling medium-dark skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '1F939 1F3FF 200D 2640 FE0F',
		character: '🤹🏿‍♀️',
		title: 'woman juggling dark skin tone',
		category: 'People & Body (person-sport)',
		group: 'People & Body',
		subgroup: 'person-sport',
	},
	{
		codes: '26BD',
		character: '⚽',
		title: 'soccer ball',
		category: 'Activities (sport)',
		group: 'Activities',
		subgroup: 'sport',
	},
	{
		codes: '26BE',
		character: '⚾',
		title: 'baseball',
		category: 'Activities (sport)',
		group: 'Activities',
		subgroup: 'sport',
	},
	{
		codes: '1F94E',
		character: '🥎',
		title: 'softball',
		category: 'Activities (sport)',
		group: 'Activities',
		subgroup: 'sport',
	},
	{
		codes: '1F3C0',
		character: '🏀',
		title: 'basketball',
		category: 'Activities (sport)',
		group: 'Activities',
		subgroup: 'sport',
	},
	{
		codes: '1F3D0',
		character: '🏐',
		title: 'volleyball',
		category: 'Activities (sport)',
		group: 'Activities',
		subgroup: 'sport',
	},
	{
		codes: '1F3C8',
		character: '🏈',
		title: 'american football',
		category: 'Activities (sport)',
		group: 'Activities',
		subgroup: 'sport',
	},
	{
		codes: '1F3C9',
		character: '🏉',
		title: 'rugby football',
		category: 'Activities (sport)',
		group: 'Activities',
		subgroup: 'sport',
	},
	{
		codes: '1F3BE',
		character: '🎾',
		title: 'tennis',
		category: 'Activities (sport)',
		group: 'Activities',
		subgroup: 'sport',
	},
	{
		codes: '1F94F',
		character: '🥏',
		title: 'flying disc',
		category: 'Activities (sport)',
		group: 'Activities',
		subgroup: 'sport',
	},
	{
		codes: '1F3CF',
		character: '🏏',
		title: 'cricket game',
		category: 'Activities (sport)',
		group: 'Activities',
		subgroup: 'sport',
	},
	{
		codes: '1F3D1',
		character: '🏑',
		title: 'field hockey',
		category: 'Activities (sport)',
		group: 'Activities',
		subgroup: 'sport',
	},
	{
		codes: '1F3D2',
		character: '🏒',
		title: 'ice hockey',
		category: 'Activities (sport)',
		group: 'Activities',
		subgroup: 'sport',
	},
	{
		codes: '1F94D',
		character: '🥍',
		title: 'lacrosse',
		category: 'Activities (sport)',
		group: 'Activities',
		subgroup: 'sport',
	},
	{
		codes: '1F3D3',
		character: '🏓',
		title: 'ping pong',
		category: 'Activities (sport)',
		group: 'Activities',
		subgroup: 'sport',
	},
	{
		codes: '1F3F8',
		character: '🏸',
		title: 'badminton',
		category: 'Activities (sport)',
		group: 'Activities',
		subgroup: 'sport',
	},
	{
		codes: '1F94A',
		character: '🥊',
		title: 'boxing glove',
		category: 'Activities (sport)',
		group: 'Activities',
		subgroup: 'sport',
	},
	{
		codes: '1F94B',
		character: '🥋',
		title: 'martial arts uniform',
		category: 'Activities (sport)',
		group: 'Activities',
		subgroup: 'sport',
	},
	{
		codes: '1F945',
		character: '🥅',
		title: 'goal net',
		category: 'Activities (sport)',
		group: 'Activities',
		subgroup: 'sport',
	},
	{
		codes: '26F3',
		character: '⛳',
		title: 'flag in hole',
		category: 'Activities (sport)',
		group: 'Activities',
		subgroup: 'sport',
	},
	{
		codes: '26F8 FE0F',
		character: '⛸️',
		title: 'ice skate',
		category: 'Activities (sport)',
		group: 'Activities',
		subgroup: 'sport',
	},
	{
		codes: '1F3A3',
		character: '🎣',
		title: 'fishing pole',
		category: 'Activities (sport)',
		group: 'Activities',
		subgroup: 'sport',
	},
	{
		codes: '1F93F',
		character: '🤿',
		title: 'diving mask',
		category: 'Activities (sport)',
		group: 'Activities',
		subgroup: 'sport',
	},
	{
		codes: '1F3BD',
		character: '🎽',
		title: 'running shirt',
		category: 'Activities (sport)',
		group: 'Activities',
		subgroup: 'sport',
	},
	{
		codes: '1F3BF',
		character: '🎿',
		title: 'skis',
		category: 'Activities (sport)',
		group: 'Activities',
		subgroup: 'sport',
	},
	{
		codes: '1F6F7',
		character: '🛷',
		title: 'sled',
		category: 'Activities (sport)',
		group: 'Activities',
		subgroup: 'sport',
	},
	{
		codes: '1F94C',
		character: '🥌',
		title: 'curling stone',
		category: 'Activities (sport)',
		group: 'Activities',
		subgroup: 'sport',
	},
	{
		codes: '1F3C1',
		character: '🏁',
		title: 'chequered flag',
		category: 'Flags (flag)',
		group: 'Flags',
		subgroup: 'flag',
	},
	{
		codes: '1F6A9',
		character: '🚩',
		title: 'triangular flag',
		category: 'Flags (flag)',
		group: 'Flags',
		subgroup: 'flag',
	},
	{
		codes: '1F38C',
		character: '🎌',
		title: 'crossed flags',
		category: 'Flags (flag)',
		group: 'Flags',
		subgroup: 'flag',
	},
	{
		codes: '1F3F4',
		character: '🏴',
		title: 'black flag',
		category: 'Flags (flag)',
		group: 'Flags',
		subgroup: 'flag',
	},
	{
		codes: '1F3F3',
		character: '🏳',
		title: 'white flag',
		category: 'Flags (flag)',
		group: 'Flags',
		subgroup: 'flag',
	},
	{
		codes: '1F3F3 200D 1F308',
		character: '🏳‍🌈',
		title: 'rainbow flag',
		category: 'Flags (flag)',
		group: 'Flags',
		subgroup: 'flag',
	},
	{
		codes: '1F3F3 FE0F 200D 26A7',
		character: '🏳️‍⚧',
		title: 'transgender flag',
		category: 'Flags (flag)',
		group: 'Flags',
		subgroup: 'flag',
	},
	{
		codes: '1F3F4 200D 2620 FE0F',
		character: '🏴‍☠️',
		title: 'pirate flag',
		category: 'Flags (flag)',
		group: 'Flags',
		subgroup: 'flag',
	},
	{
		codes: '1F1E6 1F1E8',
		character: '🇦🇨',
		title: 'flag: Ascension Island',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1E6 1F1E9',
		character: '🇦🇩',
		title: 'flag: Andorra',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1E6 1F1EA',
		character: '🇦🇪',
		title: 'flag: United Arab Emirates',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1E6 1F1EB',
		character: '🇦🇫',
		title: 'flag: Afghanistan',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1E6 1F1EC',
		character: '🇦🇬',
		title: 'flag: Antigua & Barbuda',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1E6 1F1EE',
		character: '🇦🇮',
		title: 'flag: Anguilla',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1E6 1F1F1',
		character: '🇦🇱',
		title: 'flag: Albania',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1E6 1F1F2',
		character: '🇦🇲',
		title: 'flag: Armenia',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1E6 1F1F4',
		character: '🇦🇴',
		title: 'flag: Angola',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1E6 1F1F6',
		character: '🇦🇶',
		title: 'flag: Antarctica',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1E6 1F1F7',
		character: '🇦🇷',
		title: 'flag: Argentina',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1E6 1F1F8',
		character: '🇦🇸',
		title: 'flag: American Samoa',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1E6 1F1F9',
		character: '🇦🇹',
		title: 'flag: Austria',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1E6 1F1FA',
		character: '🇦🇺',
		title: 'flag: Australia',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1E6 1F1FC',
		character: '🇦🇼',
		title: 'flag: Aruba',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1E6 1F1FD',
		character: '🇦🇽',
		title: 'flag: Åland Islands',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1E6 1F1FF',
		character: '🇦🇿',
		title: 'flag: Azerbaijan',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1E7 1F1E6',
		character: '🇧🇦',
		title: 'flag: Bosnia & Herzegovina',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1E7 1F1E7',
		character: '🇧🇧',
		title: 'flag: Barbados',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1E7 1F1E9',
		character: '🇧🇩',
		title: 'flag: Bangladesh',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1E7 1F1EA',
		character: '🇧🇪',
		title: 'flag: Belgium',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1E7 1F1EB',
		character: '🇧🇫',
		title: 'flag: Burkina Faso',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1E7 1F1EC',
		character: '🇧🇬',
		title: 'flag: Bulgaria',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1E7 1F1ED',
		character: '🇧🇭',
		title: 'flag: Bahrain',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1E7 1F1EE',
		character: '🇧🇮',
		title: 'flag: Burundi',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1E7 1F1EF',
		character: '🇧🇯',
		title: 'flag: Benin',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1E7 1F1F1',
		character: '🇧🇱',
		title: 'flag: St. Barthélemy',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1E7 1F1F2',
		character: '🇧🇲',
		title: 'flag: Bermuda',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1E7 1F1F3',
		character: '🇧🇳',
		title: 'flag: Brunei',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1E7 1F1F4',
		character: '🇧🇴',
		title: 'flag: Bolivia',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1E7 1F1F6',
		character: '🇧🇶',
		title: 'flag: Caribbean Netherlands',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1E7 1F1F7',
		character: '🇧🇷',
		title: 'flag: Brazil',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1E7 1F1F8',
		character: '🇧🇸',
		title: 'flag: Bahamas',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1E7 1F1F9',
		character: '🇧🇹',
		title: 'flag: Bhutan',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1E7 1F1FB',
		character: '🇧🇻',
		title: 'flag: Bouvet Island',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1E7 1F1FC',
		character: '🇧🇼',
		title: 'flag: Botswana',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1E7 1F1FE',
		character: '🇧🇾',
		title: 'flag: Belarus',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1E7 1F1FF',
		character: '🇧🇿',
		title: 'flag: Belize',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1E8 1F1E6',
		character: '🇨🇦',
		title: 'flag: Canada',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1E8 1F1E8',
		character: '🇨🇨',
		title: 'flag: Cocos (Keeling) Islands',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1E8 1F1E9',
		character: '🇨🇩',
		title: 'flag: Congo - Kinshasa',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1E8 1F1EB',
		character: '🇨🇫',
		title: 'flag: Central African Republic',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1E8 1F1EC',
		character: '🇨🇬',
		title: 'flag: Congo - Brazzaville',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1E8 1F1ED',
		character: '🇨🇭',
		title: 'flag: Switzerland',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1E8 1F1EE',
		character: '🇨🇮',
		title: 'flag: Côte d’Ivoire',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1E8 1F1F0',
		character: '🇨🇰',
		title: 'flag: Cook Islands',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1E8 1F1F1',
		character: '🇨🇱',
		title: 'flag: Chile',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1E8 1F1F2',
		character: '🇨🇲',
		title: 'flag: Cameroon',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1E8 1F1F3',
		character: '🇨🇳',
		title: 'flag: China',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1E8 1F1F4',
		character: '🇨🇴',
		title: 'flag: Colombia',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1E8 1F1F5',
		character: '🇨🇵',
		title: 'flag: Clipperton Island',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1E8 1F1F7',
		character: '🇨🇷',
		title: 'flag: Costa Rica',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1E8 1F1FA',
		character: '🇨🇺',
		title: 'flag: Cuba',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1E8 1F1FB',
		character: '🇨🇻',
		title: 'flag: Cape Verde',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1E8 1F1FC',
		character: '🇨🇼',
		title: 'flag: Curaçao',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1E8 1F1FD',
		character: '🇨🇽',
		title: 'flag: Christmas Island',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1E8 1F1FE',
		character: '🇨🇾',
		title: 'flag: Cyprus',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1E8 1F1FF',
		character: '🇨🇿',
		title: 'flag: Czechia',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1E9 1F1EA',
		character: '🇩🇪',
		title: 'flag: Germany',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1E9 1F1EC',
		character: '🇩🇬',
		title: 'flag: Diego Garcia',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1E9 1F1EF',
		character: '🇩🇯',
		title: 'flag: Djibouti',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1E9 1F1F0',
		character: '🇩🇰',
		title: 'flag: Denmark',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1E9 1F1F2',
		character: '🇩🇲',
		title: 'flag: Dominica',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1E9 1F1F4',
		character: '🇩🇴',
		title: 'flag: Dominican Republic',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1E9 1F1FF',
		character: '🇩🇿',
		title: 'flag: Algeria',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1EA 1F1E6',
		character: '🇪🇦',
		title: 'flag: Ceuta & Melilla',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1EA 1F1E8',
		character: '🇪🇨',
		title: 'flag: Ecuador',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1EA 1F1EA',
		character: '🇪🇪',
		title: 'flag: Estonia',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1EA 1F1EC',
		character: '🇪🇬',
		title: 'flag: Egypt',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1EA 1F1ED',
		character: '🇪🇭',
		title: 'flag: Western Sahara',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1EA 1F1F7',
		character: '🇪🇷',
		title: 'flag: Eritrea',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1EA 1F1F8',
		character: '🇪🇸',
		title: 'flag: Spain',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1EA 1F1F9',
		character: '🇪🇹',
		title: 'flag: Ethiopia',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1EA 1F1FA',
		character: '🇪🇺',
		title: 'flag: European Union',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1EB 1F1EE',
		character: '🇫🇮',
		title: 'flag: Finland',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1EB 1F1EF',
		character: '🇫🇯',
		title: 'flag: Fiji',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1EB 1F1F0',
		character: '🇫🇰',
		title: 'flag: Falkland Islands',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1EB 1F1F2',
		character: '🇫🇲',
		title: 'flag: Micronesia',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1EB 1F1F4',
		character: '🇫🇴',
		title: 'flag: Faroe Islands',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1EB 1F1F7',
		character: '🇫🇷',
		title: 'flag: France',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1EC 1F1E6',
		character: '🇬🇦',
		title: 'flag: Gabon',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1EC 1F1E7',
		character: '🇬🇧',
		title: 'flag: United Kingdom',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1EC 1F1E9',
		character: '🇬🇩',
		title: 'flag: Grenada',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1EC 1F1EA',
		character: '🇬🇪',
		title: 'flag: Georgia',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1EC 1F1EB',
		character: '🇬🇫',
		title: 'flag: French Guiana',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1EC 1F1EC',
		character: '🇬🇬',
		title: 'flag: Guernsey',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1EC 1F1ED',
		character: '🇬🇭',
		title: 'flag: Ghana',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1EC 1F1EE',
		character: '🇬🇮',
		title: 'flag: Gibraltar',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1EC 1F1F1',
		character: '🇬🇱',
		title: 'flag: Greenland',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1EC 1F1F2',
		character: '🇬🇲',
		title: 'flag: Gambia',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1EC 1F1F3',
		character: '🇬🇳',
		title: 'flag: Guinea',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1EC 1F1F5',
		character: '🇬🇵',
		title: 'flag: Guadeloupe',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1EC 1F1F6',
		character: '🇬🇶',
		title: 'flag: Equatorial Guinea',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1EC 1F1F7',
		character: '🇬🇷',
		title: 'flag: Greece',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1EC 1F1F8',
		character: '🇬🇸',
		title: 'flag: South Georgia & South Sandwich Islands',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1EC 1F1F9',
		character: '🇬🇹',
		title: 'flag: Guatemala',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1EC 1F1FA',
		character: '🇬🇺',
		title: 'flag: Guam',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1EC 1F1FC',
		character: '🇬🇼',
		title: 'flag: Guinea-Bissau',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1EC 1F1FE',
		character: '🇬🇾',
		title: 'flag: Guyana',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1ED 1F1F0',
		character: '🇭🇰',
		title: 'flag: Hong Kong SAR China',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1ED 1F1F2',
		character: '🇭🇲',
		title: 'flag: Heard & McDonald Islands',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1ED 1F1F3',
		character: '🇭🇳',
		title: 'flag: Honduras',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1ED 1F1F7',
		character: '🇭🇷',
		title: 'flag: Croatia',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1ED 1F1F9',
		character: '🇭🇹',
		title: 'flag: Haiti',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1ED 1F1FA',
		character: '🇭🇺',
		title: 'flag: Hungary',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1EE 1F1E8',
		character: '🇮🇨',
		title: 'flag: Canary Islands',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1EE 1F1E9',
		character: '🇮🇩',
		title: 'flag: Indonesia',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1EE 1F1EA',
		character: '🇮🇪',
		title: 'flag: Ireland',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1EE 1F1F1',
		character: '🇮🇱',
		title: 'flag: Israel',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1EE 1F1F2',
		character: '🇮🇲',
		title: 'flag: Isle of Man',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1EE 1F1F3',
		character: '🇮🇳',
		title: 'flag: India',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1EE 1F1F4',
		character: '🇮🇴',
		title: 'flag: British Indian Ocean Territory',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1EE 1F1F6',
		character: '🇮🇶',
		title: 'flag: Iraq',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1EE 1F1F7',
		character: '🇮🇷',
		title: 'flag: Iran',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1EE 1F1F8',
		character: '🇮🇸',
		title: 'flag: Iceland',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1EE 1F1F9',
		character: '🇮🇹',
		title: 'flag: Italy',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1EF 1F1EA',
		character: '🇯🇪',
		title: 'flag: Jersey',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1EF 1F1F2',
		character: '🇯🇲',
		title: 'flag: Jamaica',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1EF 1F1F4',
		character: '🇯🇴',
		title: 'flag: Jordan',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1EF 1F1F5',
		character: '🇯🇵',
		title: 'flag: Japan',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1F0 1F1EA',
		character: '🇰🇪',
		title: 'flag: Kenya',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1F0 1F1EC',
		character: '🇰🇬',
		title: 'flag: Kyrgyzstan',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1F0 1F1ED',
		character: '🇰🇭',
		title: 'flag: Cambodia',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1F0 1F1EE',
		character: '🇰🇮',
		title: 'flag: Kiribati',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1F0 1F1F2',
		character: '🇰🇲',
		title: 'flag: Comoros',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1F0 1F1F3',
		character: '🇰🇳',
		title: 'flag: St. Kitts & Nevis',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1F0 1F1F5',
		character: '🇰🇵',
		title: 'flag: North Korea',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1F0 1F1F7',
		character: '🇰🇷',
		title: 'flag: South Korea',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1F0 1F1FC',
		character: '🇰🇼',
		title: 'flag: Kuwait',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1F0 1F1FE',
		character: '🇰🇾',
		title: 'flag: Cayman Islands',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1F0 1F1FF',
		character: '🇰🇿',
		title: 'flag: Kazakhstan',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1F1 1F1E6',
		character: '🇱🇦',
		title: 'flag: Laos',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1F1 1F1E7',
		character: '🇱🇧',
		title: 'flag: Lebanon',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1F1 1F1E8',
		character: '🇱🇨',
		title: 'flag: St. Lucia',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1F1 1F1EE',
		character: '🇱🇮',
		title: 'flag: Liechtenstein',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1F1 1F1F0',
		character: '🇱🇰',
		title: 'flag: Sri Lanka',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1F1 1F1F7',
		character: '🇱🇷',
		title: 'flag: Liberia',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1F1 1F1F8',
		character: '🇱🇸',
		title: 'flag: Lesotho',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1F1 1F1F9',
		character: '🇱🇹',
		title: 'flag: Lithuania',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1F1 1F1FA',
		character: '🇱🇺',
		title: 'flag: Luxembourg',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1F1 1F1FB',
		character: '🇱🇻',
		title: 'flag: Latvia',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1F1 1F1FE',
		character: '🇱🇾',
		title: 'flag: Libya',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1F2 1F1E6',
		character: '🇲🇦',
		title: 'flag: Morocco',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1F2 1F1E8',
		character: '🇲🇨',
		title: 'flag: Monaco',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1F2 1F1E9',
		character: '🇲🇩',
		title: 'flag: Moldova',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1F2 1F1EA',
		character: '🇲🇪',
		title: 'flag: Montenegro',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1F2 1F1EB',
		character: '🇲🇫',
		title: 'flag: St. Martin',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1F2 1F1EC',
		character: '🇲🇬',
		title: 'flag: Madagascar',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1F2 1F1ED',
		character: '🇲🇭',
		title: 'flag: Marshall Islands',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1F2 1F1F0',
		character: '🇲🇰',
		title: 'flag: North Macedonia',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1F2 1F1F1',
		character: '🇲🇱',
		title: 'flag: Mali',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1F2 1F1F2',
		character: '🇲🇲',
		title: 'flag: Myanmar (Burma)',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1F2 1F1F3',
		character: '🇲🇳',
		title: 'flag: Mongolia',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1F2 1F1F4',
		character: '🇲🇴',
		title: 'flag: Macao SAR China',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1F2 1F1F5',
		character: '🇲🇵',
		title: 'flag: Northern Mariana Islands',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1F2 1F1F6',
		character: '🇲🇶',
		title: 'flag: Martinique',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1F2 1F1F7',
		character: '🇲🇷',
		title: 'flag: Mauritania',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1F2 1F1F8',
		character: '🇲🇸',
		title: 'flag: Montserrat',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1F2 1F1F9',
		character: '🇲🇹',
		title: 'flag: Malta',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1F2 1F1FA',
		character: '🇲🇺',
		title: 'flag: Mauritius',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1F2 1F1FB',
		character: '🇲🇻',
		title: 'flag: Maldives',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1F2 1F1FC',
		character: '🇲🇼',
		title: 'flag: Malawi',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1F2 1F1FD',
		character: '🇲🇽',
		title: 'flag: Mexico',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1F2 1F1FE',
		character: '🇲🇾',
		title: 'flag: Malaysia',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1F2 1F1FF',
		character: '🇲🇿',
		title: 'flag: Mozambique',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1F3 1F1E6',
		character: '🇳🇦',
		title: 'flag: Namibia',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1F3 1F1E8',
		character: '🇳🇨',
		title: 'flag: New Caledonia',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1F3 1F1EA',
		character: '🇳🇪',
		title: 'flag: Niger',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1F3 1F1EB',
		character: '🇳🇫',
		title: 'flag: Norfolk Island',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1F3 1F1EC',
		character: '🇳🇬',
		title: 'flag: Nigeria',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1F3 1F1EE',
		character: '🇳🇮',
		title: 'flag: Nicaragua',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1F3 1F1F1',
		character: '🇳🇱',
		title: 'flag: Netherlands',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1F3 1F1F4',
		character: '🇳🇴',
		title: 'flag: Norway',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1F3 1F1F5',
		character: '🇳🇵',
		title: 'flag: Nepal',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1F3 1F1F7',
		character: '🇳🇷',
		title: 'flag: Nauru',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1F3 1F1FA',
		character: '🇳🇺',
		title: 'flag: Niue',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1F3 1F1FF',
		character: '🇳🇿',
		title: 'flag: New Zealand',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1F4 1F1F2',
		character: '🇴🇲',
		title: 'flag: Oman',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1F5 1F1E6',
		character: '🇵🇦',
		title: 'flag: Panama',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1F5 1F1EA',
		character: '🇵🇪',
		title: 'flag: Peru',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1F5 1F1EB',
		character: '🇵🇫',
		title: 'flag: French Polynesia',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1F5 1F1EC',
		character: '🇵🇬',
		title: 'flag: Papua New Guinea',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1F5 1F1ED',
		character: '🇵🇭',
		title: 'flag: Philippines',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1F5 1F1F0',
		character: '🇵🇰',
		title: 'flag: Pakistan',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1F5 1F1F1',
		character: '🇵🇱',
		title: 'flag: Poland',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1F5 1F1F2',
		character: '🇵🇲',
		title: 'flag: St. Pierre & Miquelon',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1F5 1F1F3',
		character: '🇵🇳',
		title: 'flag: Pitcairn Islands',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1F5 1F1F7',
		character: '🇵🇷',
		title: 'flag: Puerto Rico',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1F5 1F1F8',
		character: '🇵🇸',
		title: 'flag: Palestinian Territories',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1F5 1F1F9',
		character: '🇵🇹',
		title: 'flag: Portugal',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1F5 1F1FC',
		character: '🇵🇼',
		title: 'flag: Palau',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1F5 1F1FE',
		character: '🇵🇾',
		title: 'flag: Paraguay',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1F6 1F1E6',
		character: '🇶🇦',
		title: 'flag: Qatar',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1F7 1F1EA',
		character: '🇷🇪',
		title: 'flag: Réunion',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1F7 1F1F4',
		character: '🇷🇴',
		title: 'flag: Romania',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1F7 1F1F8',
		character: '🇷🇸',
		title: 'flag: Serbia',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1F7 1F1FA',
		character: '🇷🇺',
		title: 'flag: Russia',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1F7 1F1FC',
		character: '🇷🇼',
		title: 'flag: Rwanda',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1F8 1F1E6',
		character: '🇸🇦',
		title: 'flag: Saudi Arabia',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1F8 1F1E7',
		character: '🇸🇧',
		title: 'flag: Solomon Islands',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1F8 1F1E8',
		character: '🇸🇨',
		title: 'flag: Seychelles',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1F8 1F1E9',
		character: '🇸🇩',
		title: 'flag: Sudan',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1F8 1F1EA',
		character: '🇸🇪',
		title: 'flag: Sweden',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1F8 1F1EC',
		character: '🇸🇬',
		title: 'flag: Singapore',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1F8 1F1ED',
		character: '🇸🇭',
		title: 'flag: St. Helena',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1F8 1F1EE',
		character: '🇸🇮',
		title: 'flag: Slovenia',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1F8 1F1EF',
		character: '🇸🇯',
		title: 'flag: Svalbard & Jan Mayen',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1F8 1F1F0',
		character: '🇸🇰',
		title: 'flag: Slovakia',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1F8 1F1F1',
		character: '🇸🇱',
		title: 'flag: Sierra Leone',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1F8 1F1F2',
		character: '🇸🇲',
		title: 'flag: San Marino',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1F8 1F1F3',
		character: '🇸🇳',
		title: 'flag: Senegal',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1F8 1F1F4',
		character: '🇸🇴',
		title: 'flag: Somalia',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1F8 1F1F7',
		character: '🇸🇷',
		title: 'flag: Suriname',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1F8 1F1F8',
		character: '🇸🇸',
		title: 'flag: South Sudan',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1F8 1F1F9',
		character: '🇸🇹',
		title: 'flag: São Tomé & Príncipe',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1F8 1F1FB',
		character: '🇸🇻',
		title: 'flag: El Salvador',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1F8 1F1FD',
		character: '🇸🇽',
		title: 'flag: Sint Maarten',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1F8 1F1FE',
		character: '🇸🇾',
		title: 'flag: Syria',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1F8 1F1FF',
		character: '🇸🇿',
		title: 'flag: Eswatini',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1F9 1F1E6',
		character: '🇹🇦',
		title: 'flag: Tristan da Cunha',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1F9 1F1E8',
		character: '🇹🇨',
		title: 'flag: Turks & Caicos Islands',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1F9 1F1E9',
		character: '🇹🇩',
		title: 'flag: Chad',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1F9 1F1EB',
		character: '🇹🇫',
		title: 'flag: French Southern Territories',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1F9 1F1EC',
		character: '🇹🇬',
		title: 'flag: Togo',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1F9 1F1ED',
		character: '🇹🇭',
		title: 'flag: Thailand',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1F9 1F1EF',
		character: '🇹🇯',
		title: 'flag: Tajikistan',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1F9 1F1F0',
		character: '🇹🇰',
		title: 'flag: Tokelau',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1F9 1F1F1',
		character: '🇹🇱',
		title: 'flag: Timor-Leste',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1F9 1F1F2',
		character: '🇹🇲',
		title: 'flag: Turkmenistan',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1F9 1F1F3',
		character: '🇹🇳',
		title: 'flag: Tunisia',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1F9 1F1F4',
		character: '🇹🇴',
		title: 'flag: Tonga',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1F9 1F1F7',
		character: '🇹🇷',
		title: 'flag: Turkey',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1F9 1F1F9',
		character: '🇹🇹',
		title: 'flag: Trinidad & Tobago',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1F9 1F1FB',
		character: '🇹🇻',
		title: 'flag: Tuvalu',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1F9 1F1FC',
		character: '🇹🇼',
		title: 'flag: Taiwan',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1F9 1F1FF',
		character: '🇹🇿',
		title: 'flag: Tanzania',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1FA 1F1E6',
		character: '🇺🇦',
		title: 'flag: Ukraine',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1FA 1F1EC',
		character: '🇺🇬',
		title: 'flag: Uganda',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1FA 1F1F2',
		character: '🇺🇲',
		title: 'flag: U.S. Outlying Islands',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1FA 1F1F3',
		character: '🇺🇳',
		title: 'flag: United Nations',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1FA 1F1F8',
		character: '🇺🇸',
		title: 'flag: United States',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1FA 1F1FE',
		character: '🇺🇾',
		title: 'flag: Uruguay',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1FA 1F1FF',
		character: '🇺🇿',
		title: 'flag: Uzbekistan',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1FB 1F1E6',
		character: '🇻🇦',
		title: 'flag: Vatican City',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1FB 1F1E8',
		character: '🇻🇨',
		title: 'flag: St. Vincent & Grenadines',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1FB 1F1EA',
		character: '🇻🇪',
		title: 'flag: Venezuela',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1FB 1F1EC',
		character: '🇻🇬',
		title: 'flag: British Virgin Islands',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1FB 1F1EE',
		character: '🇻🇮',
		title: 'flag: U.S. Virgin Islands',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1FB 1F1F3',
		character: '🇻🇳',
		title: 'flag: Vietnam',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1FB 1F1FA',
		character: '🇻🇺',
		title: 'flag: Vanuatu',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1FC 1F1EB',
		character: '🇼🇫',
		title: 'flag: Wallis & Futuna',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1FC 1F1F8',
		character: '🇼🇸',
		title: 'flag: Samoa',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1FD 1F1F0',
		character: '🇽🇰',
		title: 'flag: Kosovo',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1FE 1F1EA',
		character: '🇾🇪',
		title: 'flag: Yemen',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1FE 1F1F9',
		character: '🇾🇹',
		title: 'flag: Mayotte',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1FF 1F1E6',
		character: '🇿🇦',
		title: 'flag: South Africa',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1FF 1F1F2',
		character: '🇿🇲',
		title: 'flag: Zambia',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F1FF 1F1FC',
		character: '🇿🇼',
		title: 'flag: Zimbabwe',
		category: 'Flags (country-flag)',
		group: 'Flags',
		subgroup: 'country-flag',
	},
	{
		codes: '1F3F4 E0067 E0062 E0065 E006E E0067 E007F',
		character: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
		title: 'flag: England',
		category: 'Flags (subdivision-flag)',
		group: 'Flags',
		subgroup: 'subdivision-flag',
	},
	{
		codes: '1F3F4 E0067 E0062 E0073 E0063 E0074 E007F',
		character: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
		title: 'flag: Scotland',
		category: 'Flags (subdivision-flag)',
		group: 'Flags',
		subgroup: 'subdivision-flag',
	},
	{
		codes: '1F3F4 E0067 E0062 E0077 E006C E0073 E007F',
		character: '🏴󠁧󠁢󠁷󠁬󠁳󠁿',
		title: 'flag: Wales',
		category: 'Flags (subdivision-flag)',
		group: 'Flags',
		subgroup: 'subdivision-flag',
	},
	{
		codes: '1F3C6',
		character: '🏆',
		title: 'trophy',
		category: 'Activities (award-medal)',
		group: 'Activities',
		subgroup: 'award-medal',
	},
	{
		codes: '1F3C5',
		character: '🏅',
		title: 'sports medal',
		category: 'Activities (award-medal)',
		group: 'Activities',
		subgroup: 'award-medal',
	},
	{
		codes: '1F947',
		character: '🥇',
		title: '1st place medal',
		category: 'Activities (award-medal)',
		group: 'Activities',
		subgroup: 'award-medal',
	},
	{
		codes: '1F948',
		character: '🥈',
		title: '2nd place medal',
		category: 'Activities (award-medal)',
		group: 'Activities',
		subgroup: 'award-medal',
	},
	{
		codes: '1F949',
		character: '🥉',
		title: '3rd place medal',
		category: 'Activities (award-medal)',
		group: 'Activities',
		subgroup: 'award-medal',
	},
	{
		codes: '1F3F5 FE0F',
		character: '🏵️',
		title: 'rosette',
		category: 'Animals & Nature (plant-flower)',
		group: 'Animals & Nature',
		subgroup: 'plant-flower',
	},
	{
		codes: '1F397 FE0F',
		character: '🎗️',
		title: 'reminder ribbon',
		category: 'Activities (event)',
		group: 'Activities',
		subgroup: 'event',
	},
	{
		codes: '1F39F FE0F',
		character: '🎟️',
		title: 'admission tickets',
		category: 'Activities (event)',
		group: 'Activities',
		subgroup: 'event',
	},
	{
		codes: '1F3AB',
		character: '🎫',
		title: 'ticket',
		category: 'Activities (event)',
		group: 'Activities',
		subgroup: 'event',
	},
	{
		codes: '1F3AA',
		character: '🎪',
		title: 'circus tent',
		category: 'Travel & Places (place-other)',
		group: 'Travel & Places',
		subgroup: 'place-other',
	},
	{
		codes: '1F3B2',
		character: '🎲',
		title: 'game die',
		category: 'Activities (game)',
		group: 'Activities',
		subgroup: 'game',
	},
	{
		codes: '265F FE0F',
		character: '♟️',
		title: 'chess pawn',
		category: 'Activities (game)',
		group: 'Activities',
		subgroup: 'game',
	},
	{
		codes: '1F3AF',
		character: '🎯',
		title: 'direct hit',
		category: 'Activities (game)',
		group: 'Activities',
		subgroup: 'game',
	},
	{
		codes: '1F3B3',
		character: '🎳',
		title: 'bowling',
		category: 'Activities (sport)',
		group: 'Activities',
		subgroup: 'sport',
	},
	{
		codes: '1F3AE',
		character: '🎮',
		title: 'video game',
		category: 'Activities (game)',
		group: 'Activities',
		subgroup: 'game',
	},
	{
		codes: '1F3B0',
		character: '🎰',
		title: 'slot machine',
		category: 'Activities (game)',
		group: 'Activities',
		subgroup: 'game',
	},
	{
		codes: '1F3B1',
		character: '🎱',
		title: 'pool 8 ball',
		category: 'Activities (game)',
		group: 'Activities',
		subgroup: 'game',
	},
	{
		codes: '1F3CE FE0F',
		character: '🏎️',
		title: 'racing car',
		category: 'Travel & Places (transport-ground)',
		group: 'Travel & Places',
		subgroup: 'transport-ground',
	},
	{
		codes: '1F3DF FE0F',
		character: '🏟️',
		title: 'stadium',
		category: 'Travel & Places (place-building)',
		group: 'Travel & Places',
		subgroup: 'place-building',
	},
	{
		codes: '1F30D',
		character: '🌍',
		title: 'globe showing Europe-Africa',
		category: 'Travel & Places (place-map)',
		group: 'Travel & Places',
		subgroup: 'place-map',
	},
	{
		codes: '1F30E',
		character: '🌎',
		title: 'globe showing Americas',
		category: 'Travel & Places (place-map)',
		group: 'Travel & Places',
		subgroup: 'place-map',
	},
	{
		codes: '1F30F',
		character: '🌏',
		title: 'globe showing Asia-Australia',
		category: 'Travel & Places (place-map)',
		group: 'Travel & Places',
		subgroup: 'place-map',
	},
	{
		codes: '1F981',
		character: '🦁',
		title: 'lion',
		category: 'Animals & Nature (animal-mammal)',
		group: 'Animals & Nature',
		subgroup: 'animal-mammal',
	},
	{
		codes: '1F525',
		character: '🔥',
		title: 'fire',
		category: 'Travel & Places (sky & weather)',
		group: 'Travel & Places',
		subgroup: 'sky & weather',
	},
];

export const intitEmojiList = () => {
	if (!window.SMP365) {
		window.SMP365 = {};
	}
	window.SMP365.emojiList = emojiList;
};
