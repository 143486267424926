import React from 'react';
import Select from 'react-select';
import { contentToOption, selectedItemsToOptions } from './helper/generic-select.helper';

type Properties = {
	onGenericOptionSelect: any;
	value: any;
	t: any;
	isMulti: boolean;
	options: any;
};

const GenericSelect: React.FunctionComponent<Properties> = ({ value, t, options, onGenericOptionSelect, isMulti }) => {
	const GenericOptionSelect = (selections: any) => {
		if (onGenericOptionSelect) {
			onGenericOptionSelect(selections);
		}
	};
	return (
		<>
			<Select
				placeholder={t('select')}
				isMulti={isMulti}
				options={options(t)}
				onChange={GenericOptionSelect}
				value={isMulti ? selectedItemsToOptions(value) : contentToOption(value)}
				noOptionsMessage={(inputValue) => inputValue && t('no_options')}
			/>
		</>
	);
};

export default GenericSelect;
