import React from 'react';
import { ViewTypes } from '../../../../constants/general.constants';
import { Properties } from './properties/team-players-widget.properties';
import { TeamPlayersView } from './team-players-widget-view.component';
import TeamPlayersWidgetEdit from './team-players-widget-edit.component';
import TeamPlayersWidgetModel from '../models/team-players-widget.model';
import { WidgetPreview } from '../../widget-preview/widget-preview.component';
import BlockUpdateController from '../../../block-update-controller.component';

const TeamPlayersWidget: React.FunctionComponent<Properties> = (props) => {
	if (props.view === ViewTypes.edit) {
		return <TeamPlayersWidgetEdit block={props.block} t={props.t} />;
	}

	if (props.view === ViewTypes.normal) {
		return (
			<TeamPlayersView
				blockPreview={
					props.block.data.preview && props.block.data.preview.teamPlayers
						? props.block.data.preview.teamPlayers
						: TeamPlayersWidgetModel.builder().build()
				}
				t={props.t}
			/>
		);
	}

	if (props.view === ViewTypes.preview) {
		return <WidgetPreview blockContent={props.block.data.content} config={props.block.data.config} />;
	}

	return null;
};

export default BlockUpdateController(TeamPlayersWidget);
