import React from 'react';
import Select from 'react-select';
import EnumItem from '../../../../models/enum/EnumItem';
import HttpService from '../../../../services/rest/HttpService';
import CustomFlagOption from '../../../Partials/Blocky/partials/shared/custom-select-flag-option';
import { itemToOption, itemsToOptions } from '../../../Partials/Shared/react-select-helpers/react-select.helpers';
import { Col, Label } from 'reactstrap';
import { connect } from 'react-redux';

type Properties = {
	t: any;
	onSelect: (lang: EnumItem) => void;
	isSearchLang: boolean;
	language: EnumItem;
	profileLang: string;
};

type State = {
	translationLanguages: EnumItem[];
};

class TranslationLanguageSelect extends React.Component<Properties, State> {
	constructor(props: Properties) {
		super(props);
		this.state = {
			translationLanguages: [],
		};
	}
	componentDidMount() {
		this.getLanguages();
	}

	getLanguages = () => {
		HttpService.instanceFootball()
			.get('/v2/languages')
			.then((response: any) => {
				const languages = response.data.map((language: any) => EnumItem.builder().withId(language.code).withName(language.code).build());
				if (!this.props.language.id) {
					this.props.onSelect(languages.filter((lang: EnumItem) => lang.id === this.props.profileLang)[0]);
				}
				this.setState({ ...this.state, translationLanguages: languages });
			})
			.catch((e: any) => e);
	};

	onLanguageChange = (selection: any) => {
		this.props.onSelect(selection.data);
	};

	render() {
		const { translationLanguages } = this.state;
		const { isSearchLang, t, language } = this.props;
		const containerStyle = isSearchLang ? 'd-flex flex-row align-items-center justify-content-end' : '';
		const labelStyle = isSearchLang ? 'mb-0 mr-2' : '';

		return (
			<Col xs='6' className={containerStyle}>
				<Label className={labelStyle}>{isSearchLang ? t('search_in') : t('translate_to')} </Label>
				<Select
					inputId={`${isSearchLang ? 'search' : 'translate'}-language-select`}
					className={`${isSearchLang ? 'w-50' : ''}`}
					options={itemsToOptions(translationLanguages, false, t, '')}
					value={itemToOption(language, false, t, '')}
					onChange={this.onLanguageChange}
					formatOptionLabel={CustomFlagOption}
					placeholder={t('select')}
				/>
			</Col>
		);
	}
}

function mapStateToProps(state: any) {
	return {
		profileLang: state.profile.profile.language.split('-')[0],
	};
}

export default connect(mapStateToProps, null)(TranslationLanguageSelect) as React.ComponentType<any>;
