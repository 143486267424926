import * as React from 'react';
import { DebounceInput } from 'react-debounce-input';
import { FormFeedback, FormGroup, Label } from 'reactstrap';
import ErrorHandler from '../error/error-handler-component';
import { WidgetsV2Ids } from '../../constants/block.types';
import { useTranslation } from 'react-i18next';

export type Properties = {
	value: string;
	onOffsetChange: (e: any) => void;
	item?: any;
	isRequired?: boolean;
	isValid?: boolean;
	type: string;
};

const OffsetInput: React.FunctionComponent<Properties> = (props) => {
	const { value, onOffsetChange, isRequired, isValid, item, type } = props;
	const [t] = useTranslation();

	const setNewOffsetConvention = type === WidgetsV2Ids.widgetTennisRanking || type === WidgetsV2Ids.widgetFootballStandings;
	const setNewOffsetConventionValue = Number(value) + 1;

	return (
		<FormGroup data-qa='offset-select'>
			<Label>{setNewOffsetConvention ? t('start_from_position') : t('offset')}:</Label>
			{isRequired && <span className='text-danger'>*</span>}
			<DebounceInput
				data-qa='offset-select'
				type='number'
				className='form-control'
				value={value ? (setNewOffsetConvention ? setNewOffsetConventionValue.toString() : value) : ''}
				min={1}
				placeholder={t('select_offset')}
				debounceTimeout={500}
				onChange={(event: any) => {
					if (setNewOffsetConvention && event.target.value === '0') {
						return;
					}
					onOffsetChange(setNewOffsetConvention ? (Number(event.target.value) - 1).toString() : event.target.value);
				}}
			/>
			<FormFeedback>
				{t('field_is_required')} {item && item.id && value && value === ''}
			</FormFeedback>
			{type !== WidgetsV2Ids.widgetFootballStandings && !isValid && (!value || value === '') && (
				<ErrorHandler errorMessage='field_is_required' />
			)}
		</FormGroup>
	);
};

export default OffsetInput;
