import React, { useContext } from 'react';
import { SortableHandle } from 'react-sortable-hoc';
import * as ReactSortableHOC from 'react-sortable-hoc';
import { RowTitleLink } from '../../Partials/BaseComponents/ListingComponent/subcomponents/Rows/subcomponents/RowTitleLink';
import { RowEditButton } from '../../Partials/BaseComponents/ListingComponent/subcomponents/Rows/subcomponents/RowEditButton';
import { RowDeleteButton } from '../../Partials/BaseComponents/ListingComponent/subcomponents/Rows/subcomponents/RowDeleteButton';
import { ConditionalRenderContext } from '../../Partials/BaseComponents/ConditionalRenderWraper/ConditionalRenderWrapper';
import Author from '../../../models/author/Author';

type AuthorRowProps = {
	content: any;
	onDelete: (id: string, title: string) => any;
	analytics: any;
	history: any;
	t: any;
	itemIndex: number;
};

type RowsProps = {
	content: any;
	onDelete: (id: string, title: string) => any;
	analytics: any;
	history: any;
	t: any;
	itemIndex?: number;
};

const DragHandle = SortableHandle(() => {
	return (
		<span className='cursor-draggable px-2'>
			<i className='fa fa-bars'></i>
		</span>
	);
});

export const AuthorRow: React.FunctionComponent<AuthorRowProps> = ({ content, history, analytics, t, onDelete, itemIndex }) => {
	const context = useContext(ConditionalRenderContext);

	return (
		<div className={`py-2 d-flex border-bottom align-items-center w-100 ${itemIndex % 2 === 1 ? '' : 'bg-light'}`}>
			<div className='align-items-center d-flex w-50 px-2'>
				<DragHandle />
				<RowTitleLink
					contentTitle={content.title}
					editContentPath={`/smp/authors/edit/${content.id}`}
					canEditContent={context.hasWritePermission}
					analytics={analytics}
					contentId={`author-id-${content.id}`}
				/>
			</div>
			<div className='w-50 d-flex justify-content-end px-2'>
				<div className='d-flex justify-content-end flex-wrap'>
					<RowEditButton
						contentId={content.id}
						display={context.hasWritePermission}
						editContentPath={`/smp/authors/edit/${content.id}`}
						t={t}
						analytics={analytics}
						history={history}
					/>
					<RowDeleteButton
						analytics={analytics}
						display={context.hasDeletePermission}
						t={t}
						onDelete={onDelete}
						contentTitle={content.title}
						contentId={content.id}
					/>
				</div>
			</div>
		</div>
	);
};

const SortableRow = ReactSortableHOC.SortableElement(AuthorRow);

export const AuthorsList: React.FunctionComponent<RowsProps> = ({ content, onDelete, analytics, history, t }) => {
	return (
		<div>
			{content.map((author: Author, index: number) => {
				return (
					<SortableRow
						key={author.id}
						content={author}
						index={index}
						itemIndex={index}
						onDelete={onDelete}
						t={t}
						analytics={analytics}
						history={history}
					/>
				);
			})}
		</div>
	);
};
