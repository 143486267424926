import * as React from 'react';
import Related from '../../../../../models/related/Related';
import * as ReactSortableHOC from 'react-sortable-hoc';
import ContentSortableItem from './content-sortable-item';

type ContentSortableListProps = {
	related: Related[];
	onDelete: (related: Related) => any;
	t: any;
};

const SortableItem = ReactSortableHOC.SortableElement(ContentSortableItem);

const ContentSortableList: React.FunctionComponent<ContentSortableListProps> = (props) => {
	const { related, t } = props;

	return (
		<ul className='list-group'>
			{related.map((rel: Related, index: number) => (
				<SortableItem
					related={rel}
					key={`key-name:${rel.type}-key-id:${rel.data.id}`}
					index={index}
					itemIndex={index}
					onDelete={props.onDelete}
					t={t}
				/>
			))}
		</ul>
	);
};

export default ContentSortableList;
