import React from 'react';
import * as ReactSortableHOC from 'react-sortable-hoc';
import { Button } from 'reactstrap';
import LineupPlayerModel from '../../models/player-lineup/lineup-player.model';

type PropsLineup = {
	player: LineupPlayerModel;
	removeLineup: (player: LineupPlayerModel, idx: number) => void;
	idx: number;
};

const SortableLineup: React.FunctionComponent<PropsLineup> = (props) => {
	const { player, removeLineup, idx } = props;

	return (
		<div
			id={`lineup-row-${player.player.id}`}
			className='cursor-pointer px-1 py-1 border border-light rounded my-1 d-flex align-items-center'
		>
			<i className='fa fa-arrows p-1 mr-1 text-muted' />
			<span className='t-shirt mr-2 p-2'>{player.shirtNumber ? player.shirtNumber : <span>&nbsp;</span>}</span> {player.player.name}
			<Button className='ml-auto py-1 px-2' id={`remove-lineup-${player.player.id}`} color='danger' onClick={() => removeLineup(player, idx)}>
				<i className='fa fa-trash' />
			</Button>
		</div>
	);
};

const SortableItem = ReactSortableHOC.SortableElement(SortableLineup);

type Properties = {
	t: any;
	lineups: LineupPlayerModel[];
	removeLineup: (player: LineupPlayerModel, i: number) => void;
};

const LineupsList: React.FunctionComponent<Properties> = (props) => {
	const { lineups, removeLineup } = props;

	return (
		<div className='card-body p-2'>
			{lineups.map((player: LineupPlayerModel, index: number) => {
				if (player.player.id) {
					return <SortableItem idx={index} removeLineup={removeLineup} key={`lineup-${player.player.id}`} index={index} player={player} />;
				}
			})}
		</div>
	);
};

export default LineupsList;
