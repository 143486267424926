import React, { FunctionComponent, useState } from 'react';
import Select from 'react-select';
import ContentTypeMapper from './content-type.mapper';
import { featuresService } from '../../../App';
import { FeatureTypes } from '../../../services/feature-service/features.enum';

export const ContentTypes = (t: any) => [
	{ value: 'Article', label: t('article') },
	{ value: 'Gallery', label: t('gallery') },
	{ value: 'Video', label: t('video') },
	{ value: 'WikiPage', label: t('wiki_pages') },
	{ value: 'Lists', label: t('lists') },
	{ value: 'Tag', label: t('tag') },
	{ value: 'Author', label: t('author') },
	{ value: 'Image', label: t('image') },
	{ value: 'Banner', label: t('banner') },
	{ value: 'Category', label: t('category') },
];

export const ContentTypeSelect: FunctionComponent<{
	value: any;
	onContentTypeSelect: (type: any) => void;
	className: string;
	t: any;
	disabled: boolean;
}> = ({ value, onContentTypeSelect, className, t, disabled }) => {
	const types = ContentTypes(t).filter((type: any) => {
		if (type && type.value === 'WikiPage') {
			return featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.WIKI_PAGES);
		} else {
			return type;
		}
	});
	const mapper = new ContentTypeMapper();
	const options = types.map((type: any) => {
		return mapper.modelToOption(type, t);
	});

	const optionValue = mapper.modelToOption(value, t);

	return (
		<Select
			isClearable={true}
			isDisabled={disabled}
			menuPortalTarget={document.body}
			options={options}
			className={className}
			placeholder={t('select')}
			value={value ? optionValue : null}
			onChange={(selection: any) => {
				onContentTypeSelect(selection);
			}}
			on
		/>
	);
};
