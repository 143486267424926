import '../image-cells/ImageCells.scss';
import EnumItem from '../../../../../models/enum/EnumItem';
import ContentAttributes from '../../../../../models/content-attributes/content-attributes-model';
import Author from '../../../../../models/author/Author';
import GalleryItem from '../../../../../models/gallery/item/GalleryItem';
import Image from '../../../../../models/image/Image';

export const ownerToSelectOption = (item: EnumItem) => {
	return { value: item.id, label: item.name };
};

export const imageOwnersToSelectOptions = (owners: EnumItem[]) => {
	if (owners && owners.length > 0) {
		return owners.map((item: EnumItem) => {
			return { value: item.id, label: item.name };
		});
	}

	return [];
};

export const extractDefaultOrigin = (owners: ContentAttributes[]) => {
	if (owners && owners.length > 0) {
		const defaultValue = owners.filter((item: ContentAttributes) => item.isDefault === true);

		return defaultValue && defaultValue[0] && { value: defaultValue[0].id, label: defaultValue[0].name };
	}

	return {};
};

// Potential addition of 3rd party flag in the Content API for project origins to be able to get the images only eligable for watermark
// TODO Refactor after Content API supports type for origins for a given project
export const checkIfValidForWatermark = (image: Image, owners: ContentAttributes[]) => {
	const ownersNotValidForWater = [extractNonDefaultOrigin(owners)];

	let result = [];
	let isValid: boolean;
	ownersNotValidForWater.forEach((item: any) => {
		result = item && item.length > 0 && item.filter((origin: any) => image && image.owner && image.owner.name === origin.label);
	});

	isValid = result.length === 0;

	return isValid;
};

export const extractNonDefaultOrigin = (owners: ContentAttributes[]) => {
	if (owners && owners.length > 0) {
		const nonDefaultValues = owners.filter((item: ContentAttributes) => !item.isDefault);

		return nonDefaultValues.map((item: any) => {
			return { value: item.id, label: item.name };
		});
	}

	return {};
};

export const extractImageAuthorsIds = (authors: Author[]) => {
	if (authors && authors.length > 0) {
		return authors.map((item: Author) => {
			return item.id;
		});
	}
	return [];
};

export const extractPathNameFromImage = (imageItem: Image) => {
	//Returns id of image file from image path.
	if (imageItem && imageItem.path && imageItem.path.length > 0) {
		return imageItem.path.split('/').pop();
	}

	return '';
};
