import MainMedia from '../../../../../models/mainmedia/MainMedia';
import { ContentTypes } from '../../../../../constants/content-types';

export function addMainMediaIfNotExists(toAdd: MainMedia, mainMediaList: MainMedia[]): MainMedia[] {
	let index: number = 0;
	if (mainMediaList && mainMediaList.length > 0) {
		index = mainMediaList.findIndex((mainMedia: MainMedia) => {
			return toAdd.resourceType === mainMedia.resourceType && toAdd.resourceSubtype === mainMedia.resourceSubtype;
		});

		if (index === -1 && Object.keys(toAdd).length !== 0 && toAdd.resourceType !== undefined) {
			mainMediaList.push(toAdd);
		}
	} else {
		if (toAdd && Object.keys(toAdd).length !== 0 && toAdd.resourceType !== undefined) {
			mainMediaList = [toAdd];
		}
	}

	index =
		mainMediaList &&
		mainMediaList.findIndex((mainMedia: MainMedia) => {
			return mainMedia.resourceType === ContentTypes.IMAGE;
		});

	if (index === -1) {
		return mainMediaList;
	} else {
		return mainMediaList ? removeEmptyMainMediaObject(mainMediaList) : [];
	}
}

export function removeMainMedia(type: string, subType: string, mainMediaList: MainMedia[]) {
	if (mainMediaList && mainMediaList.length) {
		const index = mainMediaList.findIndex((item: MainMedia) => {
			return type === item.resourceType && subType === item.resourceSubtype;
		});

		if (index !== -1) {
			mainMediaList.splice(index, 1);
		}
	}

	return mainMediaList ? mainMediaList : [];
}

export function extractMainImageMedia(mainMediaList: MainMedia[]): MainMedia {
	if (mainMediaList && mainMediaList.length > 0) {
		let mainImageMedia = mainMediaList.filter((media: MainMedia) => media.resourceType === 'image' && media.resourceSubtype === 'main_image');

		return mainImageMedia && mainImageMedia.length > 0 ? mainImageMedia[0] : ({} as MainMedia);
	}

	return {} as MainMedia;
}

export function updateMainMediaInList(toUpdate: MainMedia, mainMediaList: MainMedia[]) {
	let index: number = 0;

	if (toUpdate && Object.keys(toUpdate).length !== 0 && mainMediaList && mainMediaList.length > 0) {
		index = mainMediaList.findIndex((item: MainMedia) => {
			return item.resourceType === toUpdate.resourceType && item.resourceSubtype === toUpdate.resourceSubtype;
		});

		if (index !== -1) {
			mainMediaList[index] = toUpdate;

			return mainMediaList;
		} else {
			mainMediaList.push(toUpdate);
		}
	} else {
		mainMediaList = [];
		toUpdate && mainMediaList.push(toUpdate);
	}

	return removeEmptyMainMediaObject(mainMediaList);
}

export function removeEmptyMainMediaObject(mainMediaList: MainMedia[]) {
	return mainMediaList.filter(
		(item: MainMedia) => (item.resourceType === ContentTypes.IMAGE ? item.resourceId !== undefined : item) || Object.keys(item).length !== 0,
	);
}
