import * as React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import SpotlightAudioModel from '../../../../../../../models/spotlight-audio/spotlight-audio.model';
import moment from 'moment';

type Properties = {
	audio: SpotlightAudioModel;
	t: any;
	onSelect: (audio: SpotlightAudioModel) => void;
	selectedAudio: SpotlightAudioModel;
	index: number;
};

const AudioRow: React.FunctionComponent<Properties> = (props) => {
	const { audio, selectedAudio, onSelect } = props;
	const isSelectedAudio = selectedAudio && audio.matchId === selectedAudio.matchId && audio.language === selectedAudio.language;
	const selectAudio = () => {
		onSelect(audio);
	};

	return (
		<FormGroup key={`key-${audio.matchId + audio.language}`} className='w-100 pr-2 pt-2 pb-2 border-bottom' check>
			<Input
				className='form-check-input'
				onChange={selectAudio}
				type='radio'
				id={`${audio.matchId + audio.language}`}
				checked={isSelectedAudio}
				name={`inline-radio-${audio.matchId}`}
				value={audio.matchId + audio.language}
			/>
			<Label className='form-check-label' check htmlFor={`${audio.matchId + audio.language}`}>
				{`${moment(audio.kickOff).format('DD MMM YYYY')} - `}
				<span>{audio.homeTeam}</span>
				{`${audio.awayTeam ? ' - ' : ''}`}
				<span>{audio.awayTeam}</span>
				{`${audio.competition ? ' | ' : ''}`}
				<span>{audio.competition}</span>
			</Label>
		</FormGroup>
	);
};

export default AudioRow;
