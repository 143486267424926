import React from 'react';
import Select from 'react-select';
import { Label } from 'reactstrap';
import {
	columnsToOptionsV2,
	columnsToOptionV2,
	selectionsToColumnsV2,
} from '../../../partials/columns-select/helpers/columns-select.helper';

type Properties = {
	onColumnsSelect: any;
	value: any;
	t: any;
	isMulti: boolean;
	columns: any;
};

export const ColumnSelect: React.FunctionComponent<Properties> = (props) => {
	const { onColumnsSelect, value, t, columns } = props;
	return (
		<>
			{props.isMulti ? (
				<>
					<Label>{t('select_columns')}:</Label>
					<Select
						id='columns-select'
						options={columnsToOptionsV2(columns, t)}
						value={columnsToOptionsV2(value, t)}
						isMulti={true}
						placeholder={t('select')}
						noOptionsMessage={(inputValue) => inputValue && t('no_options')}
						onChange={(selections: any) => onColumnsSelect(selectionsToColumnsV2(selections))}
					/>
				</>
			) : (
				<>
					<Label>{t('select_column')}:</Label>
					<Select
						id='column-select'
						options={columnsToOptionsV2(columns, t)}
						value={value && value.id ? columnsToOptionV2(value, t) : []}
						isMulti={false}
						placeholder={t('select')}
						isClearable={true}
						noOptionsMessage={(inputValue) => inputValue && t('no_options')}
						onChange={(selection: any) => onColumnsSelect(selection && selection.data)}
					/>
				</>
			)}
		</>
	);
};
