import Social from '../social/social';
import BlacklistModel from './blacklist/blacklist';
import PlayerProfileModel from './player-profile/player-profile';
import EnumItem from '../enum/EnumItem';
import { PlayerManualDataJson } from './player-manual-data.json';
import PlayerManualDataModel from './player-manual-data.model';
import { PlayerManualDataRequestJson } from './player-manual-data-request.json';
import AssetsModel from '../team-manual-data/assets/assets';

export default class PlayerManualDataBuilder {
	private json: PlayerManualDataJson;

	constructor(player?: PlayerManualDataModel | PlayerManualDataJson) {
		if (player && player instanceof PlayerManualDataModel) {
			this.json = player.toJSON();
		} else if (player) {
			this.json = player;
		} else {
			this.json = {} as PlayerManualDataJson;
		}
	}

	withId(id: string): PlayerManualDataBuilder {
		this.json.id = id;

		return this;
	}

	withName(name: string): PlayerManualDataBuilder {
		this.json.name = name;

		return this;
	}

	withActive(active: boolean): PlayerManualDataBuilder {
		this.json.active = active;

		return this;
	}

	withCountry(country: any): PlayerManualDataBuilder {
		this.json.country = country;

		return this;
	}

	withBirthCity(birthCity: EnumItem): PlayerManualDataBuilder {
		this.json.birthCity = birthCity;

		return this;
	}

	withBirthdate(birthdate: string): PlayerManualDataBuilder {
		this.json.birthdate = birthdate;

		return this;
	}

	withProfile(profile: PlayerProfileModel): PlayerManualDataBuilder {
		this.json.profile = profile;

		return this;
	}

	withPosition(position: EnumItem): PlayerManualDataBuilder {
		this.json.position = position;

		return this;
	}

	withSocial(social: Social): PlayerManualDataBuilder {
		this.json.social = social;

		return this;
	}

	withBlacklist(blacklist: BlacklistModel): PlayerManualDataBuilder {
		this.json.blacklist = blacklist;

		return this;
	}

	withAssets(assets: AssetsModel): PlayerManualDataBuilder {
		this.json.assets = assets;

		return this;
	}

	build(): PlayerManualDataModel {
		return PlayerManualDataModel.fromJSON(this.json);
	}

	equals(player: PlayerManualDataModel): boolean {
		const idEqual = this.json.id === player.id;
		const nameEqual = this.json.name === player.name;
		const isActiveEqual = this.json.active === player.active;
		const birthCityEqual = this.json.birthCity === player.birthCity;
		const birthdateEqual = this.json.birthdate === player.birthdate;
		const profileEqual = this.json.profile ? this.json.profile === player.profile : true;
		const positionEqual = this.json.position === player.position;
		const isSocialEqueal = this.json.social ? this.json.social === player.social : true;

		return idEqual && nameEqual && birthCityEqual && isActiveEqual && isSocialEqueal && birthdateEqual && profileEqual && positionEqual;
	}

	toRequestJson(): PlayerManualDataRequestJson {
		let json = {} as PlayerManualDataRequestJson;
		json.name = this.json.name;
		json.active = this.json.active;
		json.country_id = this.json.country ? `${this.json.country.id}` : '';
		json.birthdate = this.json.birthdate ? this.json.birthdate : null;

		if (this.json.profile) {
			const profile: any = {};

			if (this.json.profile.height) profile.height = this.json.profile.height;

			if (this.json.profile.weight) profile.weight = this.json.profile.weight;
			json.profile = profile;
		}

		if (this.json.birthCity) json.birth_city_id = this.json.birthCity.id;

		if (this.json.birthdate) json.birthdate = this.json.birthdate;

		if (this.json.position && this.json.position.id) json.position = this.json.position.id;

		if (this.json.id) json.id = `${this.json.id}`;

		if (this.json.social) json.social = Social.builder(this.json.social).toRequestJson();

		return json;
	}
}
