import React from 'react';
import BlockUpdateController from '../../../block-update-controller.component';
import BlockModel from '../../../../models/block.model';
import { ViewTypes } from '../../../../constants/general.constants';
import { WidgetPreview } from '../../widget-preview/widget-preview.component';
import TeamFormEdit from './team-form-edit.component';
import { TeamFormView } from './team-form-view.component';

type Properties = {
	block: BlockModel;
	view: ViewTypes;
	t: any;
	lang: string;
	contentData: any;
};

const TeamFormComponent: React.FunctionComponent<Properties> = (props) => {
	if (props.view === ViewTypes.edit) {
		return <TeamFormEdit t={props.t} block={props.block} contentData={props.contentData} />;
	}
	if (props.view === ViewTypes.normal) {
		return <TeamFormView t={props.t} block={props.block.data.preview} />;
	}
	if (props.view === ViewTypes.preview) {
		return <WidgetPreview blockContent={props.block.data.content} config={props.block.data.config} />;
	}
	return null;
};

export default BlockUpdateController(TeamFormComponent);
