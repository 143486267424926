import TopScorersWidgetModel from './top-scorers-widget.model';
import { TopScorersWidgetJson } from './top-scorers-widget.json';
import TournamentModel from '../../player-profile/models/tournament-model/tournament.model';
import PlayerModel from '../../../../partials/player-select/models/player.model';
import TeamModel from '../../../../partials/team-select/models/team.model';

export default class TopScorersWidgetBuilder {
	readonly json: TopScorersWidgetJson;

	constructor(topScorers?: TopScorersWidgetModel | TopScorersWidgetJson) {
		if (topScorers && topScorers instanceof TopScorersWidgetModel) {
			this.json = topScorers.toJson();
		} else if (topScorers) {
			this.json = topScorers;
		} else {
			this.json = {} as TopScorersWidgetJson;
		}
	}

	withTournament(tournament: TournamentModel): TopScorersWidgetBuilder {
		this.json.tournament = tournament;

		return this;
	}

	withTopScorers(topScorers: PlayerModel[]): TopScorersWidgetBuilder {
		this.json.topScorers = topScorers;

		return this;
	}

	withSelections(selections: any[]): TopScorersWidgetBuilder {
		this.json.selections = selections;

		return this;
	}

	withTeam(team: TeamModel): TopScorersWidgetBuilder {
		this.json.team = team;

		return this;
	}

	withFilter(filter: boolean): TopScorersWidgetBuilder {
		this.json.filter = filter;

		return this;
	}

	withThreeLetterCodeType(threeLetterCodeType: any): TopScorersWidgetBuilder {
		this.json.threeLetterCodeType = threeLetterCodeType;

		return this;
	}

	build(): TopScorersWidgetModel {
		return TopScorersWidgetModel.fromJson(this.json);
	}
}
