import CategoriesHttp from '../../../../../services/rest/CategoriesHttp';

export default class CategoryPermissionsService {
	private http = new CategoriesHttp();
	private categories: any[] = [];
	private admin: any = null;

	public setCategories = (categories: any[]) => {
		this.categories = categories;
	};

	public getCategories = () => {
		return this.categories;
	};

	public setAdmin = (admin: any) => {
		this.admin = admin;
	};

	public getAdmin = () => {
		return this.admin;
	};

	/**
	 * Requests admin and all categories, after receiving them generates an array containing the category name, id and isPermitted value,
	 * which is true or false, depends on the permissions that the user has for the category.
	 * @param adminId
	 */
	public requestCategories = (adminId: string) => {
		if (adminId === null) {
			this.categories = [];
			return new Promise((res, rej) => res([]));
		}

		return this.http.getCategoriesAndAdminCategories(adminId).then((resp) => {
			const adminCategories = resp[0];
			const allCategories = resp[1];
			this.categories = this.generateAdminActiveCategories(adminCategories, allCategories);
			return this.categories;
		});
	};

	/**
	 * Generates an array containing the category name, id and isPermitted value,
	 * which is true or false, depends on the permissions that the user has for the category.
	 * @param adminCategories
	 * @param allCategories
	 */
	public generateAdminActiveCategories = (adminCategories: any[], allCategories: any[]) => {
		return allCategories.map((category) => {
			const item = adminCategories.find((adminCategory) => `${adminCategory.id}` === `${category.id}`);
			return this.constructCategoryItem(category, !!item);
		});
	};

	/**
	 * Get an array of all admins for the current project
	 */
	public getAllAdmins = () => {
		return this.http.getAdmins().then((admins) => {
			return admins.map((admin: any) => this.constructAdminItem(admin));
		});
	};

	public areAllCategoriesPermitted = (categories: any[]) => {
		const notPermittedCategory = categories.find((category: any) => category.isPermitted === false);

		if (notPermittedCategory) {
			return false;
		} else {
			return true;
		}
	};

	public permitAllCategories = (permit: boolean, categories: any[]) => {
		return categories.map((category: any) => {
			category.isPermitted = permit;
			return category;
		});
	};

	public permitCategory = (selectedCategory: any, categories: any[], isPermitted: boolean) => {
		return categories.map((category: any) => {
			if (selectedCategory.id === category.id) {
				category.isPermitted = isPermitted;
			}
			return category;
		});
	};

	private extractPermittedCategoryIds = () => {
		return this.categories.filter((category: any) => category.isPermitted).map((category: any) => `${category.id}`);
	};

	updateAdminPermissions = (admin: any) => {
		const permittedCategoryIds = this.extractPermittedCategoryIds();
		return this.http.updateAdminCategoryPermissions(admin.id, permittedCategoryIds);
	};

	filterCategoriesByTitle = (nameFilter: string, categories: any[]) => {
		if (nameFilter.length > 0) {
			return categories.filter((category: any) => category.title.toLowerCase().includes(nameFilter.toLowerCase()));
		} else {
			return categories;
		}
	};

	/**
	 * Filter admins by name
	 * @param nameFilter
	 * @param admins
	 */
	filterAdminsByName = (nameFilter: string, admins: any[]) => {
		if (nameFilter.length > 0) {
			return admins.filter((admin: any) => admin.name.toLowerCase().includes(nameFilter.toLowerCase()));
		} else {
			return admins;
		}
	};

	/**
	 * Constructs an object
	 * @param category
	 * @param isPermitted
	 */
	private constructCategoryItem = (category: any, isPermitted: boolean) => {
		return {
			title: category.title,
			id: category.id,
			indent: category.indent,
			isPermitted: isPermitted,
			weight: category.weight,
		};
	};

	/**
	 * Constructs an object
	 * @param admin
	 */
	private constructAdminItem = (admin: any) => {
		return {
			name: admin.full_name,
			id: admin.id,
		};
	};
}
