import React from 'react';
import Select from 'react-select';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { selectOptions, SORT_DIRECTION_OPTIONS } from '../helpers/sort-direction-select/sort-direction-select-component.helper';
import { SPORT_SELECT_ATTRIBUTES } from '../../widgets-v2/helpers/component-attributes.constants';

type Properties = {
	onFansUnitedEnabledCheck: (checked: any) => void;
	onFansUnitedExpandCheck: (checked: any) => void;
	fansUnitedEnabledChecked: boolean;
	fansUnitedExpandedChecked: boolean;
	t: any;
	blockId: string;
	sport: string;
};

const FansUnitedCheckboxComponent: React.FunctionComponent<Properties> = (props) => {
	const {
		t,
		blockId,
		fansUnitedEnabledChecked,
		sport,
		fansUnitedExpandedChecked,
		onFansUnitedEnabledCheck,
		onFansUnitedExpandCheck,
	} = props;

	return (
		<div data-qa={`${sport}-fans-united-checkboxes-component`} className='mb-2'>
			<Row>
				<Col>
					<FormGroup key='enable-fans-united' check className='radio'>
						<Input
							key='enable-fans-united-input'
							className='form-check-input'
							type='checkbox'
							id={`${sport}-${SPORT_SELECT_ATTRIBUTES.FANS_UNITED_ENABLED}-${blockId}`}
							name='radios'
							onChange={onFansUnitedEnabledCheck}
							checked={fansUnitedEnabledChecked}
							data-qa={`${sport}-${SPORT_SELECT_ATTRIBUTES.FANS_UNITED_ENABLED}`}
						/>
						<Label
							htmlFor={`${sport}-${SPORT_SELECT_ATTRIBUTES.FANS_UNITED_ENABLED}`}
							check
							key='enable-fans-united-label'
							className='form-check-label'
							for={`enable-fans-united-checkbox-${blockId}`}
						/>
						{t('fans_united_enabled')}
					</FormGroup>
				</Col>
			</Row>
			{fansUnitedEnabledChecked && (
				<Row className='mt-2'>
					<Col>
						<FormGroup key='expand-fans-united' check className='radio'>
							<Input
								key='expand-fans-united-input'
								className='form-check-input'
								type='checkbox'
								id={`${sport}-${SPORT_SELECT_ATTRIBUTES.FANS_UNITED_EXPANDED}-${blockId}`}
								name='radios'
								onChange={onFansUnitedExpandCheck}
								checked={fansUnitedExpandedChecked}
								data-qa={`${sport}-${SPORT_SELECT_ATTRIBUTES.FANS_UNITED_EXPANDED}`}
							/>
							<Label
								htmlFor={`${sport}-${SPORT_SELECT_ATTRIBUTES.FANS_UNITED_EXPANDED}`}
								check
								key='expand-fans-united-label'
								className='form-check-label'
								for={`expand-fans-united-checkbox-${blockId}`}
							/>
							{t('fans_united_expanded')}
						</FormGroup>
					</Col>
				</Row>
			)}
		</div>
	);
};

export default FansUnitedCheckboxComponent as React.ComponentType<Properties>;
