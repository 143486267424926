import React from 'react';
import HttpService from '../../../../../services/rest/HttpService';
import { teamPlayersToOptions, remapResponceToModel } from './helpers/team-players-select';
import Select from 'react-select';
import { Properties, State } from './properties/team-players-select.properties';
import { Col, Label, Row } from 'reactstrap';
import { customOption } from '../shared/custom-select-option';

export default class TeamPlayersSelect extends React.Component<Properties, State> {
	constructor(props: any) {
		super(props);
		this.state = {
			teamPlayersOptions: [],
		};
	}

	componentDidMount() {
		if (this.props.teamId) {
			this.getTeamPlayers(this.props.teamId);
		}
	}

	componentDidUpdate(prevProps: any) {
		if (prevProps.teamId !== this.props.teamId) {
			this.getTeamPlayers(this.props.teamId);
		}
	}

	getTeamPlayers(teamId: string) {
		if (teamId) {
			HttpService.getTeamPlayers(teamId)
				.then((response: any) => {
					this.setState({
						...this.state,
						teamPlayersOptions: response.data.map((player: any) => remapResponceToModel(player.player)),
					});
				})
				.catch((e: any) => e);
		}
	}

	render() {
		if (this.props.teamId === undefined || this.props.teamId === null || this.props.teamId.length < 1) {
			return null;
		}

		const { teamPlayersOptions } = this.state;
		const { onPlayerSelect, selectedPlayers, label } = this.props;

		return (
			<Row>
				<Col>
					<Label>{label}:</Label>
					<Select
						options={teamPlayersToOptions(teamPlayersOptions)}
						formatOptionLabel={customOption}
						value={teamPlayersToOptions(selectedPlayers)}
						onChange={(selections: any) => (selections ? onPlayerSelect(selections.map((selection: any) => selection.data)) : onPlayerSelect([]))}
						isMulti
					/>
				</Col>
			</Row>
		);
	}
}
