import { MatchJson } from './match.json';
import MatchBuilder from './match.builder';
import EnumItem from '../../../../../models/enum/EnumItem';
import TeamModel from '../../../../Partials/Blocky/partials/team-select/models/team.model';
import BlacklistModel from '../../../../../models/blacklist/blacklist';

export default class MatchModel {
	readonly id: string;
	readonly type: string;
	readonly minutes: number;
	readonly eventStatus: { id: string; name: string; type: string; code: string };
	readonly startTime: string;
	readonly finishedAt: string;
	readonly homeTeam: TeamModel | null;
	readonly awayTeam: TeamModel | null;
	readonly venue: EnumItem | null;
	readonly referee: EnumItem | null;
	readonly stage: EnumItem | null;
	readonly round: string;
	readonly goalHome: number;
	readonly goalAway: number;
	readonly updatedAt: string;
	readonly liveUpdates: boolean;
	readonly lineupAvailable: boolean;
	readonly blacklist: BlacklistModel;

	private constructor(
		id: string,
		type: string,
		minutes: number,
		eventStatus: { id: string; name: string; type: string; code: string },
		startTime: string,
		finishedAt: string,
		homeTeam: TeamModel | null,
		awayTeam: TeamModel | null,
		venue: EnumItem | null,
		referee: EnumItem | null,
		stage: EnumItem | null,
		round: string,
		goalHome: number,
		goalAway: number,
		updatedAt: string,
		liveUpdates: boolean,
		lineupAvailable: boolean,
		blacklist: BlacklistModel,
	) {
		this.id = id;
		this.type = type;
		this.minutes = minutes;
		this.eventStatus = eventStatus;
		this.startTime = startTime;
		this.finishedAt = finishedAt;
		this.homeTeam = homeTeam;
		this.awayTeam = awayTeam;
		this.venue = venue;
		this.referee = referee;
		this.stage = stage;
		this.round = round;
		this.goalHome = goalHome;
		this.goalAway = goalAway;
		this.updatedAt = updatedAt;
		this.liveUpdates = liveUpdates;
		this.lineupAvailable = lineupAvailable;
		this.blacklist = blacklist;
	}

	toJSON(): MatchJson {
		return {
			id: this.id,
			type: this.type,
			minutes: this.minutes,
			eventStatus: this.eventStatus,
			startTime: this.startTime,
			finishedAt: this.finishedAt,
			homeTeam: this.homeTeam,
			awayTeam: this.awayTeam,
			venue: this.venue,
			referee: this.referee,
			stage: this.stage,
			round: this.round,
			goalHome: this.goalHome,
			goalAway: this.goalAway,
			updatedAt: this.updatedAt,
			liveUpdates: this.liveUpdates,
			lineupAvailable: this.lineupAvailable,
			blacklist: this.blacklist,
		};
	}

	static fromJSON(json: MatchJson): MatchModel {
		return new MatchModel(
			json.id,
			json.type,
			json.minutes,
			json.eventStatus,
			json.startTime,
			json.finishedAt,
			json.homeTeam,
			json.awayTeam,
			json.venue,
			json.referee,
			json.stage,
			json.round,
			json.goalHome,
			json.goalAway,
			json.updatedAt,
			json.liveUpdates,
			json.lineupAvailable,
			json.blacklist,
		);
	}

	static builder(match?: MatchModel): MatchBuilder {
		return new MatchBuilder(match);
	}
}
