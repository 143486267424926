import MatchModel from '../../../partials/match-select/models/match.model';
import TeamModel from '../../../partials/team-select/models/team.model';
import Related from '../../../../../../models/related/Related';
import { relatedConstants } from '../../../../../../constants/related.constants';
import { filterEventsByProviderSingleEvent } from '../../../v2/partials/helpers/blocky-sidebar-communication/blocky-sidebar-communication.helper';

export const remapMatchFromRelated = (relatedMatch: Related): MatchModel => {
	if (relatedMatch.type && relatedMatch.type === 'match') {
		return MatchModel.builder()
			.withId(relatedMatch.data.id)
			.withHomeTeam(remapTeamFromRelated(relatedMatch.data.home_team))
			.withAwayTeam(remapTeamFromRelated(relatedMatch.data.away_team))
			.withStartTime(relatedMatch.data.start_time)
			.withType(relatedMatch.data.event_status.type)
			.build();
	}

	return MatchModel.builder().withHomeTeam(TeamModel.builder().build()).build();
};

export const remapTeamFromRelated = (relatedTeam: any): TeamModel => {
	if (relatedTeam) {
		return TeamModel.builder()
			.withId(relatedTeam.id)
			.withLogo(relatedTeam.url_logo)
			.withName(relatedTeam.name)
			.withType(relatedTeam.type)
			.build();
	}

	return TeamModel.builder().build();
};

// prefillMatchFromRelated method will take first selected match from Tags Sidebar and will be remapped to MatchModel along with
// remapping of it`s home and away teams to match TeamModel so We can use homeTeam property as prefill data for team in widgets.

export const prefillMatchFromRelated = (selectedMatches: Related[]): MatchModel => {
	selectedMatches = filterEventsByProviderSingleEvent(selectedMatches, relatedConstants.providers.football);

	if (selectedMatches && selectedMatches.length > 0) {
		return remapMatchFromRelated(selectedMatches[0]);
	}

	return MatchModel.builder().withHomeTeam(TeamModel.builder().build()).build();
};
