import { MatchListStageRoundJson } from './match-list-stage-round-widget.json';
import TournamentModel from '../../player-profile/models/tournament-model/tournament.model';
import MatchListStageRoundModel from './match-list-stage-round-widget.model';
import SeasonModel from '../../player-profile/models/season/season.model';
import StageModel from '../../fixtures/models/stage/stage.model';
import RoundModel from '../../fixtures/models/round/round.model';
import MatchListConfigModel from '../../../../partials/match-list-config/models/match-list-config.model';

export default class MatchListStageRoundWidgetBuilder {
	readonly json: MatchListStageRoundJson;

	constructor(model?: MatchListStageRoundModel | MatchListStageRoundJson) {
		if (model && model instanceof MatchListStageRoundModel) {
			this.json = model.toJson();
		} else if (model) {
			this.json = model;
		} else {
			this.json = {} as MatchListStageRoundJson;
		}
	}

	withTournament = (tournament: TournamentModel): MatchListStageRoundWidgetBuilder => {
		this.json.tournament = tournament;

		return this;
	};

	withSeason = (season: SeasonModel): MatchListStageRoundWidgetBuilder => {
		this.json.season = season;

		return this;
	};

	withStage = (stage: StageModel): MatchListStageRoundWidgetBuilder => {
		this.json.stage = stage;

		return this;
	};

	withRound = (round: RoundModel | null): MatchListStageRoundWidgetBuilder => {
		this.json.round = round;

		return this;
	};

	withConfig = (config: MatchListConfigModel): MatchListStageRoundWidgetBuilder => {
		this.json.config = config;

		return this;
	};

	build(): MatchListStageRoundModel {
		return MatchListStageRoundModel.fromJson(this.json);
	}
}
