import Tag from '../../models/tag/Tag';
import {
	TAG_ENTITY_DELETE_FAILED,
	TAG_ENTITY_DELETE_SUCCESS,
	TAG_ENTITY_RECEIVED,
	TAG_LISTING_RECEIVED,
	TAG_LISTING_UPDATE,
} from '../action-creators/TagActionCreators';
import { USER_LOGOUT } from '../action-creators/ProfileActionCreators';
import PaginationMeta from '../../models/pagination/PaginationMeta';

interface InitialState {
	tag: Tag;
	tags: Tag[];
	tagListing: Tag[];
	pagination: PaginationMeta;
	tagDeleted: boolean;
}

const initialState: InitialState = {
	tag: Tag.builder().build(),
	tags: [],
	tagListing: [],
	tagDeleted: false,
	pagination: PaginationMeta.builder().build(),
};

function tagReducer(state: any = initialState, action: any) {
	if (action.type === TAG_LISTING_RECEIVED) {
		state = Object.assign({}, state, {
			tags: action.payload,
			pagination: action.payload.contentPagination,
		});
	}

	if (action.type === TAG_ENTITY_RECEIVED) {
		return Object.assign({}, state, {
			tag: action.payload.tag,
		});
	}

	if (action.type === TAG_ENTITY_DELETE_SUCCESS) {
		return Object.assign({}, state, {
			tagDeleted: true,
		});
	}

	if (action.type === TAG_LISTING_UPDATE) {
		state = Object.assign({}, state, {
			tags: action.payload,
		});
	}

	if (action.type === TAG_ENTITY_DELETE_FAILED) {
		return Object.assign({}, state, {
			tagDeleted: false,
		});
	}

	if (action.type === USER_LOGOUT) {
		state = initialState;
	}

	return state;
}

export default tagReducer;
