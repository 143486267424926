import { MatchScoreJson } from './match-score.json';
import { MatchScoreRequestJson } from './match-score-request.json';
import MatchScoreModel from './match-score.model';

export default class MatchScoreBuilder {
	private json: MatchScoreJson;

	constructor(score?: MatchScoreModel | MatchScoreJson | null) {
		if (score && score instanceof MatchScoreModel) {
			this.json = score.toJSON();
		} else if (score) {
			this.json = score;
		} else {
			this.json = {} as MatchScoreJson;
		}
	}

	withTotal(total: { home: number; away: number } | null): MatchScoreBuilder {
		this.json.total = total;

		return this;
	}

	withHalfTime(halfTime: { home: number; away: number } | null): MatchScoreBuilder {
		this.json.halfTime = halfTime;

		return this;
	}

	withRegularTime(regularTime: { home: number; away: number } | null): MatchScoreBuilder {
		this.json.regularTime = regularTime;

		return this;
	}

	withExtraTime(extraTime: { home: number; away: number } | null): MatchScoreBuilder {
		this.json.extraTime = extraTime;

		return this;
	}

	withPenaltyShootout(penaltyShootout: { home: number; away: number } | null): MatchScoreBuilder {
		this.json.penaltyShootout = penaltyShootout;

		return this;
	}

	withAggregate(aggregate: { home: number; away: number } | null): MatchScoreBuilder {
		this.json.aggregate = aggregate;

		return this;
	}

	toRequestJson(): MatchScoreRequestJson {
		let json = {} as MatchScoreRequestJson;

		json.total = this.json.total;
		json.half_time = this.json.halfTime;
		json.regular_time = this.json.regularTime;
		json.extra_time = this.json.extraTime;
		json.penalty_shootout = this.json.penaltyShootout;
		json.aggregate = this.json.aggregate;

		return json;
	}

	build(): MatchScoreModel {
		return MatchScoreModel.fromJSON(this.json);
	}
}
