import { WikiPagesAdvancedFilterJson } from './wiki-pages-advanced-filter.json';
import WikiPagesAdvancedFilterModel from './wiki-pages-advanced-filter.model';
import RelatedModel from './related.model';
import UserModel from './user.model';
import ContentAttributesModel from './content-attributes.model';

export default class WikiPagesAdvanceFilterBuilder {
	private json: WikiPagesAdvancedFilterJson;

	constructor(content?: WikiPagesAdvancedFilterModel | WikiPagesAdvancedFilterJson) {
		if (content && content instanceof WikiPagesAdvancedFilterModel) {
			this.json = content.toJSON();
		} else if (content) {
			this.json = content;
		} else {
			this.json = {} as WikiPagesAdvancedFilterJson;
		}
	}

	withSearchText(searchText: string): WikiPagesAdvanceFilterBuilder {
		this.json.searchText = searchText;

		return this;
	}

	withRelatedSports(relatedSports: RelatedModel[]): WikiPagesAdvanceFilterBuilder {
		this.json.relatedSports = relatedSports;

		return this;
	}

	withRelatedTags(relatedTags: RelatedModel[]): WikiPagesAdvanceFilterBuilder {
		this.json.relatedTags = relatedTags;

		return this;
	}

	withCreatedBy(createdBy: UserModel): WikiPagesAdvanceFilterBuilder {
		this.json.createdBy = createdBy;

		return this;
	}

	withDate(date: string[]): WikiPagesAdvanceFilterBuilder {
		this.json.date = date;

		return this;
	}

	withTournament(tournament: RelatedModel): WikiPagesAdvanceFilterBuilder {
		this.json.tournament = tournament;

		return this;
	}

	withSeason(season: RelatedModel): WikiPagesAdvanceFilterBuilder {
		this.json.season = season;

		return this;
	}

	withSportsType(sportsType: any): WikiPagesAdvanceFilterBuilder {
		this.json.sportsType = sportsType;

		return this;
	}

	withType(type: ContentAttributesModel[]): WikiPagesAdvanceFilterBuilder {
		this.json.type = type;

		return this;
	}

	withStatus(status: string): WikiPagesAdvanceFilterBuilder {
		this.json.status = status;

		return this;
	}

	build(): WikiPagesAdvancedFilterModel {
		return WikiPagesAdvancedFilterModel.fromJSON(this.json);
	}

	toRequestJson(): WikiPagesAdvancedFilterJson {
		let json = {} as WikiPagesAdvancedFilterJson;
		json.relatedSports = this.json.relatedSports;
		json.relatedTags = this.json.relatedTags;
		json.date = this.json.date;
		json.season = this.json.season;
		json.type = this.json.type;

		return json;
	}
}
