import * as React from 'react';
import { Col, Button, Tooltip } from 'reactstrap';
import EnumItem from '../../../../../models/enum/EnumItem';
import AddCityModal from '../add-city-modal/add-city-modal.component';

const AddCityButton: React.FunctionComponent<{ t: any; preselectCity: (city: EnumItem) => void }> = ({ t, preselectCity }) => {
	const [isOpen, toggle] = React.useState(false);
	const [isToggledModal, toggleModal] = React.useState(false);

	const toggleTooltip = () => toggle((isOpen: boolean) => !isOpen);

	return (
		<>
			<Col xs='6'>
				<Button id='add-birth-city-button' className='border border-secondary' color='base' onClick={() => toggleModal(true)}>
					{t('add_city')}
				</Button>
				<i style={{ fontSize: '28px' }} className='align-middle mx-2 fa fa-question-circle-o' id='add-birth-city-tooltip' />
				<Tooltip placement='right' isOpen={isOpen} target='add-birth-city-tooltip' toggle={toggleTooltip}>
					{t('add_city_info')}
				</Tooltip>
			</Col>
			{isToggledModal && <AddCityModal preselectCity={preselectCity} t={t} isToggledModal={isToggledModal} toggleModal={toggleModal} />}
		</>
	);
};

export default AddCityButton;
