import { TypedJSON } from 'typedjson';
import TeamModel from '../../../../../../../models/v2/Team/Entity/TeamModel';
import { assetsPlaceholder } from '../../../../../../../constants/assetsPlaceholder';
import PlayerModel from '../../../../../../../models/v2/player/entity/player.model';

export const responseToTeamModel = (response: any) => {
	const serializer = new TypedJSON(TeamModel);
	const remmapedTeam = serializer.parse(response) as TeamModel;

	return remmapedTeam;
};

export const responseToPlayerModel = (response: any) => {
	const serializer = new TypedJSON(PlayerModel);
	const remmapedPlayer = serializer.parse(response.player) as PlayerModel;

	return remmapedPlayer;
};

export const remapTeamsFromResponse = (teams: any) => teams.map((team: any) => responseToTeamModel(team));

export const remapPlayersFromResponse = (players: any) => players.map((player: any) => responseToPlayerModel(player));

export const teamToOption = (team: TeamModel) => {
	if (team) {
		return {
			value: team.id,
			label: team.name,
			data: team,
			logo: team.urlLogo !== '' ? team.urlLogo : assetsPlaceholder.team,
			type: 'team',
		};
	} else {
		return {};
	}
};

export const teamsToOptions = (teams: TeamModel[]) => {
	if (teams && teams.length > 0) {
		return teams.map((team) => {
			return teamToOption(team);
		});
	}

	return [];
};

export function topScorerToOption(topScorer: PlayerModel) {
	if (topScorer) {
		return {
			value: topScorer.id,
			label: topScorer.name,
			data: topScorer,
			logo: topScorer.urlThumb,
			type: 'player',
		};
	}

	return {};
}

export function topScorersToOptions(topScorers: PlayerModel[]) {
	if (topScorers && topScorers.length > 0) {
		return topScorers.map((topScorer) => topScorerToOption(topScorer));
	}

	return [];
}

export const selectionsToPlayers = (selections: PlayerModel[]) => {
	if (selections && selections.length > 0) {
		return selections.map((selection: any) => selection.data);
	}

	return [];
};

export const mostDecoratedPlayersToOptions = (players: PlayerModel[]) => {
	if (players && players.length > 0) {
		return players.map((player: PlayerModel) => mostDecoratedPlayerToOption(player));
	}

	return [];
};

export const mostDecoratedPlayerToOption = (player: PlayerModel) => {
	if (player) {
		return {
			value: player.id,
			label: player.name,
			logo: player.urlThumb,
			type: 'player',
			data: player,
		};
	}

	return {};
};
