import * as React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import SpotlightAudioModel from '../../../../../../../models/spotlight-audio/spotlight-audio.model';
import AudioRow from './audio-row';

type Properties = {
	audios: SpotlightAudioModel[];
	analytics?: any;
	t: any;
	toggleLoadingState: boolean;
	onSelect: (audio: SpotlightAudioModel) => void;
	selectedAudio: SpotlightAudioModel;
};

export default class AudioContainer extends React.Component<Properties> {
	constructor(props: any) {
		super(props);
	}

	render() {
		const { audios, t, onSelect, selectedAudio } = this.props;

		return (
			<Scrollbars style={{ height: 200 }}>
				<div className='p-2'>
					{audios &&
						audios.length > 0 &&
						audios.map((audio: SpotlightAudioModel, index: number) => {
							if (audio && audio.homeTeam && audio.homeTeam.length > 0 && audio.matchId) {
								return (
									<AudioRow
										key={`${audio.matchId + Math.random()}`}
										t={t}
										audio={audio}
										onSelect={onSelect}
										index={index}
										selectedAudio={selectedAudio}
									/>
								);
							}
						})}
				</div>
			</Scrollbars>
		);
	}
}
