import ModelMapper from '../../models/ModelMapper';
import { toast } from 'react-toastify';
import i18n from '../../i18n';
import { actionService } from '../../App';
import { SEARCH_IS_APPLIED } from '../action-creators/UiActionCreator';
import {
	TEAM_LISTING_RECEIVED,
	TEAM_ENTITY_RECEIVED,
	TEAM_ENTITY_CREATE,
	TEAM_ENTITY_UPDATE,
	TEAM_ENTITY_CREATE_SUCCESS,
	TEAM_ENTITY_CREATE_FAILED,
	TEAM_ENTITY_UPDATE_SUCCESS,
	TEAM_ENTITY_UPDATE_FAILED,
	TEAM_ENTITY_POST_ASSETS_SUCCESS,
	TEAM_ENTITY_POST_ASSETS_FAILED,
	SEARCH_TEAM_LISTING_RECEIVED,
	TEAM_BLACKLIST_RECEIVED,
	TEAM_LISTING_WITH_MANUAL_DATA_FILTER_RECEIVED,
} from '../action-creators/team-action-creator';
import TeamManualDataModel from '../../models/team-manual-data/team-manual-data.model';
import PaginationMeta from '../../models/pagination/PaginationMeta';

export function teamMiddleware() {
	return function (next: any) {
		return function (action: any) {
			if (action) {
				if (action.type === TEAM_LISTING_RECEIVED) {
					action.payload.teams = ModelMapper.remapTeamsFromResponse(action.payload.data.teams);
					action.payload.contentPagination = ModelMapper.remapMetaPagination(action.payload.data.page_meta);
				}

				if (action.type === TEAM_LISTING_WITH_MANUAL_DATA_FILTER_RECEIVED) {
					const teams = action.payload.teams ? action.payload.teams : [];
					action.payload.teams = ModelMapper.remapTeamsFromResponse(teams);
					action.payload.contentPagination = PaginationMeta.builder()
						.withCount(action.payload.teams.length)
						.withCurrentPage(1)
						.withOffset(0)
						.build();
				}

				if (action.type === TEAM_BLACKLIST_RECEIVED) {
					action.payload.teams = ModelMapper.remapTeamsFromResponse(action.payload);
				}

				if (action.type === TEAM_ENTITY_RECEIVED) {
					action.payload.team = ModelMapper.remapTeamFromResponse(action.payload.data);
				}

				if (action.type === TEAM_ENTITY_CREATE) {
					actionService.emitGeneralAction(TEAM_ENTITY_CREATE);
					action.payload.team = TeamManualDataModel.builder(action.payload.team).toRequestJson();
				}

				if (action.type === TEAM_ENTITY_UPDATE) {
					actionService.emitGeneralAction(TEAM_ENTITY_UPDATE);
					action.payload.team = TeamManualDataModel.builder(action.payload.team).toRequestJson();
				}

				if (action.type === TEAM_ENTITY_CREATE_SUCCESS) {
					actionService.emitGeneralAction(TEAM_ENTITY_CREATE_SUCCESS);
					window.dispatchEvent(new CustomEvent(TEAM_ENTITY_CREATE_SUCCESS, { detail: action.payload }));
					toast.success(i18n.t('team_create_success'));
				}

				if (action.type === TEAM_ENTITY_CREATE_FAILED) {
					actionService.emitGeneralAction(TEAM_ENTITY_CREATE_FAILED);
					//TODO Replace  window.dispatchEvent with the actionService
					window.dispatchEvent(new CustomEvent(TEAM_ENTITY_CREATE_FAILED, { detail: action.payload }));
					toast.error(i18n.t('team_create_failed'));
				}

				if (action.type === TEAM_ENTITY_UPDATE_SUCCESS) {
					actionService.emitGeneralAction(TEAM_ENTITY_UPDATE_SUCCESS);
					toast.success(i18n.t('team_update_success'));
				}

				if (action.type === TEAM_ENTITY_UPDATE_FAILED) {
					actionService.emitGeneralAction(TEAM_ENTITY_UPDATE_FAILED);
					toast.error(i18n.t('team_update_failed'));
				}

				if (action.type === TEAM_ENTITY_POST_ASSETS_SUCCESS) {
					actionService.emitGeneralAction(TEAM_ENTITY_POST_ASSETS_SUCCESS);
					toast.success(i18n.t('team_assets_update_success'));
				}

				if (action.type === TEAM_ENTITY_POST_ASSETS_FAILED) {
					actionService.emitGeneralAction(TEAM_ENTITY_POST_ASSETS_FAILED);
					toast.error(i18n.t('team_assets_update_failed'));
				}

				if (action.type === SEARCH_IS_APPLIED) {
					actionService.emitGeneralAction(SEARCH_IS_APPLIED);
				}

				if (action.type === SEARCH_TEAM_LISTING_RECEIVED) {
					action.payload.teams = ModelMapper.remapTeamsFromResponse(action.payload.data);
				}
			}

			next(action);
		};
	};
}
