export type OptionTypeData = {
	value: string;
	label: string;
	data?: any;
};

export type SelectAction = {
	action: string;
	name: string | undefined;
	removedValue: any;
};

export enum SelectActionValue {
	REMOVE = 'remove-value',
}
