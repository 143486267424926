import { AssetsJson } from './assets.json';
import AssetsModel from './assets';
import { AssetsRequestJson } from './assets.request-json';

export default class AssetsBuilder {
	private json: AssetsJson;

	constructor(assets?: AssetsModel | AssetsJson) {
		if (assets && assets instanceof AssetsModel) {
			this.json = assets.toJSON();
		} else if (assets) {
			this.json = assets;
		} else {
			this.json = {} as AssetsJson;
		}
	}

	withLogo(logo: string): AssetsBuilder {
		this.json.logo = logo;

		return this;
	}

	withHomeKit(homeKit: string): AssetsBuilder {
		this.json.homeKit = homeKit;

		return this;
	}

	withAwayKit(awayKit: string): AssetsBuilder {
		this.json.awayKit = awayKit;

		return this;
	}

	withSquadImage(squadImage: string): AssetsBuilder {
		this.json.squadImage = squadImage;

		return this;
	}

	withImage(image: string): AssetsBuilder {
		this.json.image = image;

		return this;
	}

	withThumbnail(thumbnail: string): AssetsBuilder {
		this.json.thumbnail = thumbnail;

		return this;
	}

	build(): AssetsModel {
		return AssetsModel.fromJSON(this.json);
	}

	toRequestJson(): AssetsRequestJson {
		let json = {} as AssetsRequestJson;

		json.logo = this.json.logo ? this.json.logo : '';
		json.url_home_kit = this.json.homeKit ? this.json.homeKit : '';
		json.url_away_kit = this.json.awayKit ? this.json.awayKit : '';
		json.url_squad_image = this.json.squadImage ? this.json.squadImage : '';

		return json;
	}
}
