import { ReactPaginateProps } from 'react-paginate';

export const PaginationProps = (t: any, currentPage: number): ReactPaginateProps => {
	return {
		previousLabel: t('previous_page'),
		nextLabel: t('next_page'),
		breakLabel: '...',
		pageCount: 0,
		breakClassName: 'break-me',
		marginPagesDisplayed: 2,
		pageRangeDisplayed: 1,
		containerClassName: 'smp-pagination',
		activeClassName: 'active',
		forcePage: currentPage - 1,
	};
};
