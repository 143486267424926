export enum ReduxURLsProps {
	EXTERNAL_URL = 'externalUrl',
	CANONICAL_URL = 'canonicalUrl',
	DESKTOP_URL = 'publicUrlDesktop',
	MOBILE_URL = 'publicUrlMobile',
	AMP_URL = 'publicUrlAmp',
}

export type UrlsComponentModel = {
	[ReduxURLsProps.EXTERNAL_URL]: string;
	[ReduxURLsProps.CANONICAL_URL]: string;
	[ReduxURLsProps.DESKTOP_URL]?: string;
	[ReduxURLsProps.MOBILE_URL]?: string;
	[ReduxURLsProps.AMP_URL]?: string;
};
