import { PlayerProfileJson } from './player-profile.json';
import PlayerProfileBuilder from './player-profile.builder';

export default class PlayerProfileModel {
	readonly height: string;
	readonly weight: string;

	private constructor(height: string, weight: string) {
		this.height = height;
		this.weight = weight;
	}

	toJson(): PlayerProfileJson {
		return {
			height: this.height,
			weight: this.weight,
		};
	}

	static fromJson(json: PlayerProfileJson): PlayerProfileModel {
		return new PlayerProfileModel(json.height, json.weight);
	}
	static builder(profile?: PlayerProfileModel): PlayerProfileBuilder {
		return new PlayerProfileBuilder(profile);
	}
}
