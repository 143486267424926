import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Col, Label, Row } from 'reactstrap';
import { customOption } from '../shared/custom-select-option';
import ErrorHandler from '../error/error-handler-component';
import { stagesToOptions, stageToOption } from './helpers/basketball-stage-select.helper';
import TennisSeasonStagesModel from '../../../../../models/v2/tennis-competition-season/entity/tennis-season-stages.model';
import TennisPlayoffModel from '../../v2/widgets-v2/tennis-playoff/models/tennis-playoff.model';
import TennisStageSelectModel from './models/tennis-stage-select.model';

type Properties = {
	onStageSelect: (selection: TennisSeasonStagesModel) => any;
	blockData: TennisPlayoffModel;
	selectedStage: TennisSeasonStagesModel;
	t: any;
	isValid: boolean;
	isClearable?: boolean;
	isRequired?: boolean;
	isBlocky: boolean;
	language: string;
	withLabel?: boolean;
	label?: string;
	sport: string;
	id: string;
	stages: TennisSeasonStagesModel[];
};

const TennisStageSelectComponent: React.FunctionComponent<Properties> = (props) => {
	const [stagesOptions, setStagesOptions] = useState<TennisSeasonStagesModel[]>([]);

	const { stages } = props;

	useEffect(() => {
		updateStagesOptionsState(stages);
	}, [stages]);

	const updateStagesOptionsState = (stages: TennisSeasonStagesModel[]) => {
		setStagesOptions(stages);
	};

	return (
		<Row data-qa={props.id}>
			<Col>
				<Label htmlFor={props.id}>{props.t('stage')}</Label>
				{props.isRequired && <span className='text-danger'>*</span>}
				<Select
					id={props.id}
					menuPortalTarget={document.body}
					menuPosition='absolute'
					className='w-100'
					placeholder={props.t('select')}
					formatOptionLabel={customOption}
					options={stagesToOptions(stagesOptions)}
					value={props.selectedStage && props.selectedStage.id ? stageToOption(props.selectedStage) : []}
					noOptionsMessage={(inputValue) => inputValue && props.t('no_options')}
					isClearable={props.isClearable}
					onChange={(selection: TennisStageSelectModel) => {
						if (selection && selection.data !== null) {
							props.onStageSelect(selection.data);
						} else {
							props.onStageSelect({} as TennisSeasonStagesModel);
						}
					}}
				/>
				{!props.isValid && !props.selectedStage && <ErrorHandler t={props.t} errorMessage='field_is_required' />}
			</Col>
		</Row>
	);
};

export default TennisStageSelectComponent as React.ComponentType<Properties>;
