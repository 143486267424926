import React from 'react';
import _ from 'lodash';
import HttpService from '../../../../../services/rest/HttpService';
import debounce from 'lodash.debounce';
import * as helpers from './helpers/team-select.helper';
import { checkIfSeasonIdIsPresent } from './helpers/team-select.helper';
import { customOption } from '../shared/custom-select-option';
import { Properties, RequiredProperties, State } from './properties/team-select.properties';
import AsyncSelect from 'react-select/async';
import { Label } from 'reactstrap';
import ErrorHandler from '../error/error-handler-component';

class TeamSelect extends React.Component<Properties, State> {
	constructor(props: any) {
		super(props);
		this.state = {
			teamNameInput: '',
			keepDefaultOptions: null,
		};
	}

	searchTeam = (search: any, callback: any) => {
		HttpService.getTeam(search)
			.then((response: any) => {
				const options = response.data.map((item: any) => helpers.responseToOption(item));
				callback(options);
			})
			.catch((e: any) => e);
	};

	searchMultipleTeams = (search: any, callback: any) => {
		HttpService.getTeam(search)
			.then((response: any) => {
				const options = response.data.map((item: any) => helpers.responseToOption(item));
				callback(options);
			})
			.catch((e: any) => e);
	};

	onTeamNameInputChangeState = (input: string) => {
		this.setState({ ...this.state, teamNameInput: input });
		return input;
	};

	displayLabel(display: boolean, text: string) {
		if (display !== undefined && !display) {
			return null;
		}

		if (display && text) {
			return <Label>{this.props.t(text)}</Label>;
		}

		return <Label>{this.props.t('team')}:</Label>;
	}

	onSelect = (teams: any) => {
		if (teams && teams.length > 0) {
			this.props.onSelect(teams.map((team: any) => team.data));
		} else {
			this.props.onSelect([]);
		}
	};

	getAllAvailableTeamsForTheSeason = (search: any, callback: any) => {
		if (this.props.seasonInfo) {
			HttpService.getTeamsBySeasonId(this.props.seasonInfo.id)
				.then((response: any) => {
					let options = null;

					if (typeof search === 'string') {
						const getFilteredItems = response.data.filter((item: any) => item.name.toLowerCase().includes(search));
						options = getFilteredItems.map((item: any) => helpers.responseToOption(item));
					} else {
						options = response.data.map((item: any) => helpers.responseToOption(item));
					}

					this.setState({ keepDefaultOptions: options });
					callback(options);
				})
				.catch((e: any) => e);
		}
	};

	render() {
		const { onSelect, labelText, withLabel, isMulti, value, t, inputId } = this.props;

		return (
			<>
				<div data-qa='team-select-component-v1'>
					{this.displayLabel(withLabel, labelText)}

					{isMulti ? (
						<AsyncSelect
							className='mb-2'
							inputId={inputId}
							noOptionsMessage={(inputValue) => inputValue && t('no_options')}
							isMulti
							loadOptions={_.debounce(this.searchMultipleTeams, 500)}
							onInputChange={debounce(this.onTeamNameInputChangeState, 500)}
							value={helpers.teamsToOption(value)}
							formatOptionLabel={customOption}
							onChange={this.onSelect}
							placeholder={t('search')}
						/>
					) : this.props.searchThroughAllTeams ? (
						<AsyncSelect
							className='mb-2'
							inputId={inputId}
							value={
								this.props.value
									? {
											id: this.props.value.id,
											logo: this.props.value.logo,
											value: this.props.value.name,
											label: this.props.value.name,
											threeLetterCode: this.props.value.threeLetterCode,
											type: this.props.value.type,
									  }
									: []
							}
							noOptionsMessage={(inputValue) => inputValue && t('no_options')}
							placeholder={t('search')}
							loadOptions={debounce(this.searchTeam, 500)}
							onInputChange={debounce(this.onTeamNameInputChangeState, 500)}
							formatOptionLabel={customOption}
							onChange={(selection: any) => onSelect(helpers.remapTeamFromResponce(selection.data))}
						/>
					) : (
						<AsyncSelect
							className='mb-2'
							inputId={inputId}
							value={
								this.props.value
									? {
											id: this.props.value.id,
											logo: this.props.value.logo,
											value: this.props.value.name,
											label: this.props.value.name,
											threeLetterCode: this.props.value.threeLetterCode,
											type: this.props.value.type,
									  }
									: []
							}
							noOptionsMessage={(inputValue) => inputValue && t('no_options')}
							placeholder={t('search')}
							loadOptions={debounce(
								checkIfSeasonIdIsPresent(this.props.seasonInfo) ? this.getAllAvailableTeamsForTheSeason : this.searchTeam,
								500,
							)}
							onInputChange={debounce(this.onTeamNameInputChangeState, 500)}
							formatOptionLabel={customOption}
							onChange={(selection: any) => onSelect(helpers.remapTeamFromResponce(selection.data))}
							defaultOptions={checkIfSeasonIdIsPresent(this.props.seasonInfo) ? this.state.keepDefaultOptions : null}
							onFocus={checkIfSeasonIdIsPresent(this.props.seasonInfo) ? this.getAllAvailableTeamsForTheSeason : null}
						/>
					)}
					{!this.props.isValid &&
						(!isMulti ? !this.props.value || (this.props.value && !this.props.value.id) : this.props.value && this.props.value.length === 0) && (
							<ErrorHandler errorMessage='field_is_required' />
						)}
				</div>
			</>
		);
	}
}

export default TeamSelect as React.ComponentType<RequiredProperties>;
