import EnumItem from '../../models/enum/EnumItem';
import { IMAGE_OWNERS_REQUEST_RECEIVED } from '../action-creators/ImageOwnersActionCreator';
import ModelMapper from '../../models/ModelMapper';

export function imageOwnersMiddleware() {
	return function (next: any) {
		return function (action: any) {
			if (action) {
				if (action.type === IMAGE_OWNERS_REQUEST_RECEIVED) {
					action.payload = ModelMapper.remapResponseToContentAttributesList(action.payload.data.data);
				}
			}
			next(action);
		};
	};
}
