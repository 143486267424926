import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Col, FormFeedback, Label, Row } from 'reactstrap';
import { customOption } from '../../../partials/shared/custom-select-option';
import PlayerModel from '../../../../../../models/v2/player/entity/player.model';
import HttpService from '../../../../../../services/rest/HttpService';
import {
	mostDecoratedPlayersToOptions,
	mostDecoratedPlayerToOption,
	remapPlayersFromResponse,
	selectionsToPlayers,
} from '../helpers/top-scorers-selects/top-scorers-select-components.helper';
import SeasonModel from '../../../../../../models/v2/Season/Entity/SeasonModel';

type Properties = {
	onPlayersSelect: any;
	selectedHighlightedPlayers: any;
	selectedSeason: SeasonModel;
	t: any;
	isMulti: boolean;
	setPlayers: any;
	player: PlayerModel;
	offset: string;
	id: string;
};

export const FootballMostDecoratedPlayersSelectContainer: React.FunctionComponent<Properties> = (props) => {
	const [playersOptions, setPlayersOptions] = useState<PlayerModel[]>([]);

	useEffect(() => {
		if (props.selectedSeason && props.selectedSeason.id) {
			getSeasonDecoratedPlayers(props.selectedSeason.id);
		}
	}, [props.selectedSeason]);

	const updatePlayersOptionsState = (players: PlayerModel[]) => {
		setPlayersOptions(players);
	};

	const getSeasonDecoratedPlayers = (seasonId: number) => {
		if (seasonId) {
			HttpService.getDecoratedPlayersBySeasonId(seasonId)
				.then((response: any) => {
					const options = remapPlayersFromResponse(response.data);
					updatePlayersOptionsState(options);
					props.setPlayers(options);
				})
				.catch((e: any) => e);
		}
	};

	const { onPlayersSelect, selectedHighlightedPlayers, t, player } = props;

	return props.isMulti ? (
		<Row data-qa={props.id}>
			<Col>
				<Label>{t('pick_players_highlight')}:</Label>
				<Select
					id={props.id}
					options={mostDecoratedPlayersToOptions(playersOptions)}
					value={mostDecoratedPlayersToOptions(selectedHighlightedPlayers)}
					isMulti={true}
					placeholder={t('select')}
					formatOptionLabel={customOption}
					noOptionsMessage={(inputValue) => inputValue && t('no_options')}
					onChange={(selections: any) => onPlayersSelect(selectionsToPlayers(selections))}
				/>
			</Col>
		</Row>
	) : (
		<Row data-qa={props.id}>
			<Col>
				<Label>{t('pick_player')}:</Label>
				<Select
					id={props.id}
					options={mostDecoratedPlayersToOptions(playersOptions)}
					value={props.player && props.player.id ? mostDecoratedPlayerToOption(player) : {}}
					isMulti={false}
					placeholder={t('select')}
					formatOptionLabel={customOption}
					isClearable={!!(props.player && props.player.id)}
					noOptionsMessage={(inputValue) => inputValue && t('no_options')}
					onChange={(selection: any) => props.onPlayersSelect(selection && selection.data)}
				/>
				<FormFeedback>
					{t('field_is_required')}
					{props.offset && props.offset.length > 0 && props.player && !props.player.id}
				</FormFeedback>
			</Col>
		</Row>
	);
};
