import React from 'react';
import { Col, Label, Row } from 'reactstrap';
import ListModel from '../../../../../../models/v2/list/list';
import { connect } from 'react-redux';
import { imagePlaceholderBase64 } from '../../../../../../global-helpers/global.constants';
import LiveBlogModel from '../../../models/live-blog.model';
import { disableSaveListButton, isListEmpty } from '../../../../../Partials/Sidebar/list-refactored/helpers/general.helper';
import { ContentTypes } from '../../../../../../constants/content-types';
import CategoryModel from '../../../../../../models/v2/category/category.model';
import { extractMainImageObjectFromArray } from '../../../../../Partials/Sidebar/media-refactored/helpers/general';
import { displayAuthorNamesString, getImageSrcOrPlaceholderImage, shouldShowListItem } from './live-blog-lists.helper';
import placeholderImage from '../../../../../../assets/img/image-placeholder.png';
import LiveBlogListItemAddButtons from './live-blog-list-add-buttons.component';
import moment from 'moment';
import { generateContentTypeIcon } from '../../../../../../services/content-models-service/ContentModelService';
import { capitalize } from '../../../../../../global-helpers/global.helpers';
import { DATA_QA_ATTRIBUTES } from '../../../../../Partials/Sidebar/general-content/constants/data-qa.attributes';
import LiveBlogListAlerts from './live-blog-list-alerts.component';
import { areLockPositionsValid, areNumberOfItemsValid } from '../../../../../Partials/Sidebar/list-refactored/helpers/alerts.helper';

type Properties = {
	t: any;
	selectedList: ListModel | null;
	shouldShowListImages: boolean;
	liveBlog: LiveBlogModel;
};

const LiveBlogListAddItemContainer: React.FunctionComponent<Properties> = ({ t, selectedList, shouldShowListImages, liveBlog }) => {
	const contentModelId = liveBlog.slug ? liveBlog.slug : '';
	const contentModelTitle = liveBlog.title ? liveBlog.title : '';
	const contentModelCategory = liveBlog.category ? liveBlog.category : new CategoryModel();
	const contentTypeBasedOnUrl = ContentTypes.LIVE_BLOG;
	const image = extractMainImageObjectFromArray(liveBlog.main_media);
	const imageSrc = image ? getImageSrcOrPlaceholderImage(image.data) : placeholderImage;
	const disabled = !contentModelTitle || !contentModelCategory;
	const labelStyle = disabled ? 'text-danger' : '';
	const authorData = (liveBlog.authors && liveBlog.authors.length > 0 && displayAuthorNamesString(t, liveBlog.authors)) || '';
	const date = liveBlog.start_time && liveBlog.start_time.length > 0 ? liveBlog.start_time : '';
	const contentIcon = generateContentTypeIcon(ContentTypes.LIVE_BLOG);

	if (!selectedList) {
		return null;
	}

	const listItemIsAddedToTheList = shouldShowListItem(selectedList, contentModelId);
	if (listItemIsAddedToTheList) {
		return null;
	}

	return (
		<Row>
			<Col>
				<Label className={labelStyle}>
					{disabled ? t(`fill_${contentTypeBasedOnUrl}_required_fileds`) : t(`add_current_${contentTypeBasedOnUrl}_to_list`)}
				</Label>
				{!disabled && (
					<li className='btn list-group-item mb-2 p1 cursor-pointer'>
						<Row>
							<Col className='d-flex'>
								<div className='list-content d-flex align-items-center w-100'>
									<div className='mr-2'>{<LiveBlogListItemAddButtons liveBlog={liveBlog} t={t} selectedList={selectedList} />}</div>
									<div className={'d-flex justify-content-between align-items-center overflow-hidden'}>
										{shouldShowListImages && (
											<div className='mr-2'>
												<img
													alt={contentModelTitle}
													src={imageSrc}
													style={{ width: '100px', borderRadius: '15%', height: '80px' }}
													className='live-blog-sortable-list-item-image'
													onError={(i: any) => (i.target.src = imagePlaceholderBase64)}
												/>
											</div>
										)}
										<div className='overflow-hidden live-blog-list-item-row'>
											<h6 className='mb-0 text-left d-flex'>
												<i className={contentIcon} title={capitalize(ContentTypes.LIVE_BLOG)} />
												<label htmlFor={DATA_QA_ATTRIBUTES.LIVE_BLOG_LIST_MODAL_ADD} className='text-dark ml-1 mb-0'>
													{contentModelTitle}
												</label>
											</h6>
											<div className='flex-column content-item-list-data text-left'>
												<small>{`${t('date_published')}: ${moment(date).format('DD MMMM YYYY, HH:mm')}`}</small>
												<small>{authorData}</small>
											</div>
										</div>
									</div>
								</div>
							</Col>
						</Row>
					</li>
				)}
			</Col>
		</Row>
	);
};

function mapStateToProps(state: any) {
	return {
		selectedList: (!isListEmpty(state.list.list) && state.list.list) || null,
		shouldShowListImages: state.ui.shouldShowListImages[state.profile.profile.id] || false,
	};
}

export default connect(mapStateToProps)(LiveBlogListAddItemContainer);
