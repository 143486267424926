import * as React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import GBVisionAudioModel from '../../../../../../../../models/v2/gb-vision-audio/gb-vision-audio-model';
import GBVisionAudioRow from './gb-vision-audio-row';

type Properties = {
	audios: GBVisionAudioModel[];
	analytics?: any;
	t: any;
	toggleLoadingState: boolean;
	onSelect: (audio: GBVisionAudioModel) => void;
	selectedAudio: GBVisionAudioModel;
};

export const GBVisionAudioContainer: React.FunctionComponent<Properties> = (props) => {
	const { audios, t, onSelect, selectedAudio } = props;

	return (
		<Scrollbars style={{ height: 200 }}>
			<div className='p-2'>
				{audios &&
					audios.length > 0 &&
					audios.map((audio: GBVisionAudioModel, index: number) => {
						if (audio && audio.home && audio.home.length > 0 && audio.id) {
							return (
								<GBVisionAudioRow
									key={`${audio.id + Math.random()}`}
									t={t}
									audio={audio}
									onSelect={onSelect}
									index={index}
									selectedAudio={selectedAudio}
								/>
							);
						}
					})}
			</div>
		</Scrollbars>
	);
};
