import { ContentMode } from '../../constants/content-types';
import Gallery from '../../models/gallery/Gallery';

export const GALLERY_TEMP_UPDATE = '[ACTION] GALLERY_TEMP_UPDATE';
export const GALLERY_TEMP_EDIT_UPDATE = '[ACTION] GALLERY_TEMP_EDIT_UPDATE';
export const GALLERY_TEMP_CLEAR = '[ACTION] GALLERY_TEMP_CLEAR';
export const GALLERY_TEMP_EDIT_UPDATE_BEFORE_REDIRECTING = '[ACTION] GALLERY_TEMP_EDIT_UPDATE_BEFORE_REDIRECTING';
export const GALLERY_TEMP_PROPERTY_UPDATE = '[ACTION] GALLERY_TEMP_PROPERTY_UPDATE';

export function updateTempGallery(gallery: Gallery) {
	return {
		type: GALLERY_TEMP_UPDATE,
		payload: gallery,
	};
}

export function updateTempEditGallery(gallery: Gallery) {
	return {
		type: GALLERY_TEMP_EDIT_UPDATE,
		payload: gallery,
	};
}

export function updateTempGalleryProperty(propertyObject: Record<string, any>, contentMode: ContentMode) {
	return {
		type: GALLERY_TEMP_PROPERTY_UPDATE,
		payload: propertyObject,
		contentMode,
	};
}

export function updateTempEditGalleryBeforeRedirecting() {
	return {
		type: GALLERY_TEMP_EDIT_UPDATE_BEFORE_REDIRECTING,
	};
}

export function clearTempGallery() {
	return {
		type: GALLERY_TEMP_CLEAR,
	};
}
