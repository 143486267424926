import React from 'react';
import { connect } from 'react-redux';
import { Properties, State } from './properties/gallery-block.properties';
import { ViewTypes } from '../../../constants/general.constants';
import HttpService from '../../../../../../services/rest/HttpService';
import GalleryEdit from './gallery-edit.component';
import GalleryBlockModel from './models/gallery-block.model';
import { remapResponceToGallery } from './helpers/gallery-block.helper';
import { GalleryView } from './gallery-view.component';
import ContentNotFoundController from '../content-not-found-controller.component';

class GalleryBlock extends React.Component<Properties, State> {
	constructor(props: any) {
		super(props);
		this.state = {
			isLoading: false,
			gallery: GalleryBlockModel.builder().build(),
			width: props.block.data.id && props.block.data.preview ? props.block.data.preview.gallery.width : '',
			alignment: props.block.data.id && props.block.data.preview ? props.block.data.preview.gallery.alignment : '',
			mainImage: props.block.data.id && props.block.data.preview ? props.block.data.preview.gallery.mainImage : null,
		};
	}

	componentDidMount() {
		const { block } = this.props;

		if (block && block.data.id) {
			this.getGallery(block.data.id);
		}
	}

	componentDidUpdate(prevProps: Readonly<Properties>, prevState: Readonly<State>) {
		if (this.props.block.id !== prevProps.block.id) {
			this.getGallery(this.props.block.id);
		}
	}

	toggleIsLoadingState(isLoading: boolean) {
		this.setState({ ...this.state, isLoading });
	}

	getGallery(galleryId: string) {
		this.toggleIsLoadingState(true);
		const header = { Project: this.props.currentProject.domain };

		HttpService.get(`/galleries/${galleryId}`, null, header)
			.then((response: any) => {
				let gallery = GalleryBlockModel.builder(remapResponceToGallery(response.data.data))
					.withWidth(this.state.width)
					.withAlignment(this.state.alignment)
					.withMainImage(this.state.mainImage)
					.build();
				this.setState({ ...this.state, isLoading: false, gallery });
			})
			.catch((err: any) => {
				err.response && err.response.status === 404 && this.props.setIsContentFound(false);
				this.toggleIsLoadingState(false);
			});
	}

	render() {
		const { block, view, t, renderView } = this.props;

		if (view === ViewTypes.edit) {
			return (
				<GalleryEdit
					t={t}
					gallery={this.state.gallery}
					currentProject={this.props.currentProject}
					block={block}
					contentData={this.props.contentData}
					contentLanguage={this.props.contentLanguage}
				/>
			);
		} else if (view === ViewTypes.normal) {
			return renderView(
				<GalleryView
					preview={block.data && block.data.preview ? block.data.preview.gallery : GalleryBlockModel.builder().build()}
					isLoading={this.state.isLoading}
					t={t}
				/>,
			);
		}
	}
}

function mapStateToProps(state: any) {
	return {
		currentProject: state.project.currentProject,
		lang: state.profile.profile.language,
	};
}

export default connect(mapStateToProps)(ContentNotFoundController(GalleryBlock)) as React.ComponentType<any>;
