import React from 'react';
import { Col, FormGroup, Input, Label, Row, Tooltip } from 'reactstrap';
import Select from 'react-select';
import MatchListConfigModel from './models/match-list-config.model';
import { OddsConfiguration } from './models/odds-configuration.json';
import DateTimePickerContainer from '../../../Sidebar/GeneralContentAttributes/subcomponents/DateTimePicker/DateTimePickerContainer';
import { Subject } from 'rxjs';
import './match-listing-config.component.scss';
import OddClientBookmakers from '../available-bookmakers-select/odd-client-bookmakers.component';
import OddClientBookmakersModel from '../available-bookmakers-select/models/odd-client-bookmakers.model';
import ThreeLetterCodeTypeSelect from '../three-letter-code-type-select/three-letter-code-type-select';

type Properties = {
	model: MatchListConfigModel;
	onChange: (model: MatchListConfigModel) => void;
	t: any;
};

type State = {
	openTooltip: boolean;
};

const VIEW_OPTIONS = (t: any) => [
	{ value: 'normal', label: t('view_normal') },
	{ value: 'horizontal', label: t('view_horizontal') },
];

const GROUPING_OPTIONS = (t: any) => [
	{ value: 'tournament', label: t('grouping_tournament') },
	{ value: 'round', label: t('grouping_round') },
];

const ORDER_OPTIONS = (t: any) => [{ value: 'date', label: t('order_date') }];

const ORDER_DIRECTION_OPTIONS = (t: any) => [
	{ value: 'asc', label: t('order_direction_asc') },
	{ value: 'desc', label: t('order_direction_desc') },
];

export default class MatchListConfig extends React.Component<Properties, State, any> {
	readonly clearFromService = new Subject<void>();
	readonly clearToService = new Subject<void>();

	constructor(props: Properties) {
		super(props);

		this.state = {
			openTooltip: false,
		};
	}

	onViewChange = (view: any) => {
		const modelBuilder = MatchListConfigModel.builder(this.props.model);
		modelBuilder.withView(view.value);
		this.props.onChange(modelBuilder.build());
	};

	onTitleChange = (event: any) => {
		const modelBuilder = MatchListConfigModel.builder(this.props.model);
		this.props.onChange(modelBuilder.withTitle(event.target.value).build());
	};

	onViewMoreLabelChange = (event: any) => {
		const modelBuilder = MatchListConfigModel.builder(this.props.model);
		this.props.onChange(modelBuilder.withViewMoreLabel(event.target.value).build());
	};

	onShowToggleButtonChange = (event: any) => {
		const modelBuilder = MatchListConfigModel.builder(this.props.model);
		const configuration: OddsConfiguration = { showToggleButton: event.target.checked };
		this.props.onChange(modelBuilder.withOddsConfiguration(configuration).build());
	};

	onFromChange = (date: string) => {
		if (date && date.length < 1) {
			this.resetDate('from');
		}

		const modelBuilder = MatchListConfigModel.builder(this.props.model);
		modelBuilder.withFrom(date);
		this.props.onChange(modelBuilder.build());
	};

	onToChange = (date: string) => {
		if (date && date.length < 1) {
			this.resetDate('to');
		}

		const modelBuilder = MatchListConfigModel.builder(this.props.model);
		modelBuilder.withTo(date);
		this.props.onChange(modelBuilder.build());
	};

	onOrderChange = (order: any) => {
		const modelBuilder = MatchListConfigModel.builder(this.props.model);
		modelBuilder.withOrder(order ? order.value : order);
		this.props.onChange(modelBuilder.build());
	};

	onOrderDirectionChange = (orderDirection: any) => {
		const modelBuilder = MatchListConfigModel.builder(this.props.model);
		modelBuilder.withOrderDirection(orderDirection ? orderDirection.value : orderDirection);
		this.props.onChange(modelBuilder.build());
	};

	onGroupingChange = (grouping: any) => {
		const modelBuilder = MatchListConfigModel.builder(this.props.model);
		modelBuilder.withGrouping(grouping ? grouping.value : grouping);
		this.props.onChange(modelBuilder.build());
	};

	onBookmakerSelect = (bookmaker: OddClientBookmakersModel | OddClientBookmakersModel[] | null) => {
		const modelBuilder = MatchListConfigModel.builder(this.props.model);

		if (bookmaker === null || bookmaker instanceof OddClientBookmakersModel) {
			modelBuilder.withBookmaker(bookmaker);
			this.props.onChange(modelBuilder.build());
		}
	};

	selectOptions = (value: string, t: any) => {
		switch (value) {
			case 'horizontal': {
				return VIEW_OPTIONS(t)[1];
			}
			case 'normal': {
				return VIEW_OPTIONS(t)[0];
			}
			case 'tournament': {
				return GROUPING_OPTIONS(t)[0];
			}
			case 'round': {
				return GROUPING_OPTIONS(t)[1];
			}
			case 'date': {
				return ORDER_OPTIONS(t)[0];
			}
			case 'asc': {
				return ORDER_DIRECTION_OPTIONS(t)[0];
			}
			case 'desc': {
				return ORDER_DIRECTION_OPTIONS(t)[1];
			}
			default: {
				return null;
			}
		}
	};

	// Date time picker UI update of date
	resetDate = (dateToReset: string) => {
		if (dateToReset === 'from') {
			this.clearFromService.next();
		} else if (dateToReset === 'to') {
			this.clearToService.next();
		}
	};

	toggle = () => {
		this.setState({ ...this.state, openTooltip: !this.state.openTooltip });
	};

	onThreeLetterCodeTypeChange = (value: any) => {
		this.props.onChange(MatchListConfigModel.builder(this.props.model).withThreeLetterCodeType(value).build());
	};

	render() {
		let { t, model } = this.props;
		model = model ? model : ({} as MatchListConfigModel);
		const view = model.view ? model.view : 'normal';

		return (
			<div>
				<hr></hr>
				<Row>
					<Col>
						<Label>
							<strong>{t('match_list_stage_round_view')}</strong>
						</Label>
					</Col>
				</Row>
				<Row>
					<Col>
						<Select
							value={this.selectOptions(view, t)}
							options={VIEW_OPTIONS(t)}
							placeholder={t('match_list_stage_round_view_placeholder')}
							onChange={this.onViewChange}
						/>
					</Col>
				</Row>
				<Row className='mt-2'>
					<Col>
						<ThreeLetterCodeTypeSelect
							t={this.props.t}
							isClearable={true}
							onThreeLetterCodeTypeChange={this.onThreeLetterCodeTypeChange}
							selectedThreeLetterCodeType={model.threeLetterCodeType}
						/>
					</Col>
				</Row>
				<hr></hr>
				<Row style={{ marginBottom: '15px' }}>
					<Col>
						<Label>
							<strong>{t('match_list_stage_round_header_config')}</strong>
						</Label>
					</Col>
				</Row>
				<Row style={{ marginBottom: '15px' }}>
					<Col>
						<Label>{t('match_list_stage_round_header_title')}</Label>
						<Input
							value={model.title}
							placeholder={t('match_list_stage_round_header_title_placeholder')}
							type='text'
							onChange={this.onTitleChange}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<FormGroup className='form-check-input-override'>
							<Label for='check-box-show-toggle-button' style={{ marginRight: '5px' }}>
								{t('match_list_stage_round_odds_toggle_btn')}
							</Label>
							<Input
								checked={model.oddsConfiguration ? model.oddsConfiguration.showToggleButton : false}
								name='check-box-show-toggle-button'
								type='checkbox'
								onChange={this.onShowToggleButtonChange}
							/>
						</FormGroup>
					</Col>
				</Row>
				<hr></hr>
				<Row style={{ marginBottom: '15px' }}>
					<Col>
						<Label className='mr-1'>
							<strong>{t('match_list_stage_round_view_more')}</strong>
						</Label>
						<i style={{ fontSize: '17px' }} className='fa fa-question-circle-o' id='match-list-widget-tooltip'></i>
						<Tooltip placement='top' isOpen={this.state.openTooltip} target={'match-list-widget-tooltip'} toggle={this.toggle}>
							{t('match_list_stage_round_view_more_tooltip')}
						</Tooltip>
					</Col>
				</Row>
				<Row style={{ marginBottom: '15px' }}>
					<Col>
						<Label>{t('match_list_stage_round_view_more_label')}</Label>
						<Input
							value={model.viewMoreLabel}
							placeholder={t('match_list_stage_round_view_more_label')}
							type='text'
							onChange={this.onViewMoreLabelChange}
						/>
					</Col>
				</Row>
				<hr></hr>
				<Row style={{ marginBottom: '10px' }}>
					<Col>
						<Label>
							<strong>{t('match_list_stage_round_filters')}</strong>
						</Label>
					</Col>
				</Row>
				<Row style={{ marginBottom: '10px' }}>
					<Col>
						<Label>{t('match_list_stage_round_date_from')}</Label>
						<DateTimePickerContainer
							id='match-list-stage-round-date-from'
							dateFormat='Y.m.d, H:i'
							resetButton
							date={model.from}
							clearService={this.clearFromService}
							t={t}
							enableTime={true}
							placeholder={t('match_list_stage_round_date_from_placeholder')}
							onDateChange={this.onFromChange}
						/>
					</Col>
					<Col>
						<Label>{t('match_list_stage_round_date_to')}</Label>
						<DateTimePickerContainer
							id='match-list-stage-round-date-to'
							dateFormat='Y.m.d, H:i'
							resetButton
							date={model.to}
							clearService={this.clearToService}
							t={t}
							enableTime={true}
							placeholder={t('match_list_stage_round_date_to_placeholder')}
							onDateChange={this.onToChange}
						/>
					</Col>
				</Row>
				<Row style={{ marginBottom: '20px' }}>
					<Col sm={6} xs={12} style={{ marginBottom: '10px' }}>
						<Label>{t('match_list_stage_round_matches_order')}</Label>
						<Select
							value={this.selectOptions(model.order, t)}
							options={ORDER_OPTIONS(t)}
							isClearable
							placeholder={t('match_list_stage_round_matches_order_placeholder')}
							onChange={this.onOrderChange}
						/>
					</Col>
					{model.order && model.order === 'date' && (
						<Col sm={6} xs={12}>
							<Label>{t('match_list_stage_round_order_direction')}</Label>
							<Select
								value={this.selectOptions(model.orderDirection, t)}
								options={ORDER_DIRECTION_OPTIONS(t)}
								placeholder={t('match_list_stage_round_order_direction_placeholder')}
								onChange={this.onOrderDirectionChange}
							/>
						</Col>
					)}
					{view === 'normal' && (
						<Col sm={6} xs={12}>
							<Label>{t('match_list_stage_round_grouping')}</Label>
							<Select
								value={this.selectOptions(model.grouping, t)}
								options={GROUPING_OPTIONS(t)}
								isClearable
								placeholder={t('match_list_stage_round_grouping_placeholder')}
								onChange={this.onGroupingChange}
							/>
						</Col>
					)}
					<Col sm={6} xs={12}>
						<OddClientBookmakers
							value={model.bookmaker}
							isMulti={false}
							onChange={this.onBookmakerSelect}
							isClearable={true}
							label={t('select_bookmaker')}
						/>
					</Col>
				</Row>
			</div>
		);
	}
}
