import React from 'react';
import { connect } from 'react-redux';
import Input from 'reactstrap/lib/Input';
import Global from '../helpers/global.helpers';
import BlockModel from '../models/block.model';
import { EditorTypes } from '../constants/block.types';
import BlocksGenerator from '../generators/blocks.generator';
import BlockModelBuilder from '../models/block-model.builder';
import { Properties, State } from './properties/blocky-sortable.properties';
import * as helpers from './helpers/blocky-sortable-helper';
import {
	blocksCopiedToState,
	initializeAutolinking,
	setBlocksToCopy,
	updateBlockyUndoRedo,
} from '../../../../store/action-creators/BlockyActionCreator';
import { Button, ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { ALL_OCCURRENCES, FIRST_OCCURRENCES } from '../services/auto-tag.service';
import { autoTagService } from './blocky.component';
import { appServiceWorker, featuresService } from '../../../../App';
import { FeatureTypes } from '../../../../services/feature-service/features.enum';
import { copyToClipboard, pasteFromClipboard } from './helpers/copy-paste-blocks.helper';
import { toast } from 'react-toastify';
import { capitalize, isEditScreen } from '../../../../global-helpers/global.helpers';
import { ContentTypes } from '../../../../constants/content-types';

class BlockySortable extends React.Component<Properties, State> {
	constructor(props: Properties) {
		super(props);
		this.state = {
			isPasteBlocksOpen: false,
			isOpenPasteMenu: false,
			isOpenTagsMenu: false,
			isOpenCopyPasteMenu: false,
			pasteOption: '',
			pasteBlocksOption: '',
			insertFirstOccurrenceOption: '',
		};
	}

	componentDidMount() {
		if (this.props.blocks && this.props.blocks.length === 0) {
			const data = {
				data: { type: EditorTypes.paragraph, content: '<p></p>' },
				id: `${Global.makeId(6)}`,
				type: EditorTypes.editor,
			};
			const block = new BlockModelBuilder(data).build();
			this.props.addBlockAt(block, -1);
		}
	}

	componentDidUpdate(prevProps: Properties) {
		if (prevProps.blocks.length > 0 && this.props.blocks.length === 0) {
			const data = {
				data: { type: EditorTypes.paragraph, content: '<p></p>' },
				id: `${Global.makeId(6)}`,
				type: EditorTypes.editor,
			};
			const block = new BlockModelBuilder(data).build();
			this.props.addBlockAt(block, -1);
		}

		if (prevProps.areBlocksCopied !== this.props.areBlocksCopied) {
			!this.props.areBlocksCopied &&
				this.setState((state) => {
					return { ...state, pasteBlocksOption: '' };
				});
		}
	}

	setPasteOption = (option: any) => {
		this.setState((state) => {
			return { ...state, pasteOption: option };
		});
	};

	pasteAtBottom = (e: any) => {
		this.setPasteOption('bottom');
		this.setState((state: State) => {
			return { ...state, isOpenPasteMenu: false };
		});
	};

	copyContentBlocks = (e: any) => {
		if (this.props.blocksToCopy.length > 0) {
			copyToClipboard(e, this.props, true);
		} else {
			this.props.blocksCopiedToState(false);
			toast.error(this.props.t('no_selected_blocks'));
		}
	};

	pasteContentBlocks = (e: any, top: boolean, bottom: boolean) => {
		this.setState((state) => {
			return { ...state, pasteBlocksOption: top ? 'top' : 'bottom' };
		});
		pasteFromClipboard(e, this.props, top, bottom);
		const blockStateWithCopied = top
			? [...this.props.blocksToCopy, ...this.props.blocks]
			: [...this.props.blocks, ...this.props.blocksToCopy];
		this.props.blockyUndoRedoFlag && this.props.updateBlockyUndoRedo(blockStateWithCopied, [...this.props.blocks]);
	};

	onPaste(e: any) {
		const pastedText = helpers.pastedText(e);
		const pasteIndex = this.state.pasteOption === 'bottom' ? this.props.blocks.length + 1 : 0;
		appServiceWorker.postMessage({ text: pastedText, pasteIndex: pasteIndex });
		appServiceWorker.onmessage = (message: any) => {
			const paragraphs = message.data;
			this.props.addMultipleBlocksAt(paragraphs, pasteIndex);
			this.setPasteOption('');
		};
	}

	closeTextarea = () => this.setPasteOption('');

	togglePasteMenu = () => {
		this.setState((state) => {
			return { ...state, isOpenPasteMenu: !this.state.isOpenPasteMenu };
		});
	};

	togglePasteBlocksMenu = () => {
		this.setState((state) => {
			return { ...state, isPasteBlocksOpen: !this.state.isPasteBlocksOpen };
		});
	};

	toggleTagsMenu = () => {
		this.setState((state) => {
			return { ...state, isOpenTagsMenu: !this.state.isOpenTagsMenu };
		});
	};

	emitTagAction = (action: any) => {
		this.setState((state) => {
			return { ...state, insertFirstOccurrenceOption: action ? 'insert_first_occurrence' : 'insert_all_occurrences' };
		});
		autoTagService.autoTagSubject.next(action);
	};

	render() {
		const {
			blocks,
			t,
			areBlocksCopied,
			contentLanguage,
			entityType,
			contentType,
			relatedTags,
			relatedMatches,
			relatedSports,
			contentDataLanguage,
			contentDataTranslationGroup,
			contentDataCategory,
			contentDataAdditionalCategories,
		} = this.props;
		const { isOpenPasteMenu, isOpenTagsMenu, pasteOption, isPasteBlocksOpen, pasteBlocksOption, insertFirstOccurrenceOption } = this.state;
		const delButtonStyle = { top: 0, right: 0 };
		const isEntityLinkingEnabled = featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.AUTO_TAGGING);
		const autoTagConfig = featuresService.getFeatureConfig(FeatureTypes.AUTO_TAGGING)
			? featuresService.getFeatureConfig(FeatureTypes.AUTO_TAGGING)
			: null;
		const isEntityLinkingManual = isEntityLinkingEnabled && autoTagConfig ? autoTagConfig.isManual : false;
		const blockyContentData = {
			category: contentDataCategory,
			additionalCategories: contentDataAdditionalCategories,
			related: {
				tags: relatedTags,
				matches: relatedMatches,
				sports: relatedSports,
			},
			language: contentDataLanguage,
			contentType: contentDataTranslationGroup && contentDataTranslationGroup.contentType,
		};

		return (
			<div>
				<div className='d-flex'>
					<Button outline title='Ctrl + Shift + K' color='primary' className='mr-3 mb-3' onClick={this.copyContentBlocks}>
						{t('copy_blocks')}
					</Button>
					{this.props.blocksToCopy.length > 0 && areBlocksCopied && (
						<ButtonDropdown isOpen={isPasteBlocksOpen} toggle={this.togglePasteBlocksMenu} className='mr-3 mb-3'>
							<Button title='Ctrl + Shift + V' outline color='primary' onClick={(e: any) => this.pasteContentBlocks(e, false, true)}>
								{pasteBlocksOption.length === 0 ? t('paste_blocks_at_bottom') : t(`paste_blocks_at_${pasteBlocksOption}`)}
							</Button>
							<DropdownToggle outline caret color='primary' />
							<DropdownMenu>
								<DropdownItem
									title='Ctrl + Shift + A'
									key='dropdown-item-all-occurrences'
									onClick={(e: any) => this.pasteContentBlocks(e, true, false)}
								>
									{t('paste_blocks_at_top')}
								</DropdownItem>
								<DropdownItem
									title='Ctrl + Shift + V'
									key='dropdown-item-first-occurrence'
									onClick={(e: any) => this.pasteContentBlocks(e, false, true)}
								>
									{t('paste_blocks_at_bottom')}
								</DropdownItem>
							</DropdownMenu>
						</ButtonDropdown>
					)}
					{isEntityLinkingEnabled && isEntityLinkingManual && (
						<ButtonDropdown isOpen={isOpenTagsMenu} toggle={this.toggleTagsMenu} className='ml-auto mr-3 mb-3'>
							<Button outline color='primary' onClick={() => this.emitTagAction(FIRST_OCCURRENCES)}>
								{insertFirstOccurrenceOption.length === 0 ? t('insert_first_occurrence') : t(insertFirstOccurrenceOption)}
							</Button>
							<DropdownToggle outline caret color='primary' />
							<DropdownMenu>
								<DropdownItem key='dropdown-item-all-occurrences' onClick={() => this.emitTagAction(ALL_OCCURRENCES)}>
									{t('insert_all_occurrences')}
								</DropdownItem>
								<DropdownItem key='dropdown-item-first-occurrence' onClick={() => this.emitTagAction(FIRST_OCCURRENCES)}>
									{t('insert_first_occurrence')}
								</DropdownItem>
							</DropdownMenu>
						</ButtonDropdown>
					)}
					<ButtonDropdown isOpen={isOpenPasteMenu} toggle={this.togglePasteMenu} className='mb-3'>
						<Button outline color='primary' onClick={(e) => this.pasteAtBottom(e)}>
							{pasteOption.length === 0 ? t('paste_at_bottom') : t(`paste_at_${pasteOption}`)}
						</Button>
						<DropdownToggle outline caret color='primary' />
						<DropdownMenu>
							<DropdownItem key='dropdown-paste_blocks_at_top' className='px-1' onClick={(e) => this.setPasteOption('top')}>
								{t('paste_at_top')}
							</DropdownItem>
							<DropdownItem key='dropdown-paste_blocks_at_bottom' className='px-1' onClick={(e) => this.setPasteOption('bottom')}>
								{t('paste_at_bottom')}
							</DropdownItem>
						</DropdownMenu>
					</ButtonDropdown>
				</div>
				<div className={`${pasteOption.length > 0 ? 'd-block mb-3' : 'd-none'} position-relative`}>
					<Input
						type='textarea'
						className='d-block form-control'
						rows={4}
						value={''}
						onPaste={(e: any) => this.onPaste(e)}
						bsSize='sm'
						placeholder={t(`paste_text_at_${pasteOption}`)}
					/>
					<div style={delButtonStyle} className='position-absolute px-1 pointer' onClick={this.closeTextarea}>
						<i className='fa fa-close text-danger' title={t('close')} />
					</div>
				</div>
				{blocks
					? blocks.map((block: BlockModel, index: number) =>
							new BlocksGenerator().createBlock(
								block,
								index,
								t,
								blockyContentData,
								blocks.length,
								blocks,
								contentLanguage,
								entityType,
								contentType,
							),
					  )
					: null}
			</div>
		);
	}
}

function mapDispatchToProps(dispatch: any) {
	const maximumRedoUndoStates: number = featuresService.extractUndoRedoStepsFromBlockyUndoRedoFeature();

	return {
		updateBlocksToCopy: (blocks: BlockModel[]) => dispatch(setBlocksToCopy(blocks)),
		blocksCopiedToState: (areBlocksCopied: boolean) => dispatch(blocksCopiedToState(areBlocksCopied)),
		initializeAutolinking: (placeholderName: string) => dispatch(initializeAutolinking(placeholderName)),
		updateBlockyUndoRedo: (newBlockArray: BlockModel[], oldBlockArray: BlockModel[]) =>
			dispatch(updateBlockyUndoRedo(newBlockArray, oldBlockArray, maximumRedoUndoStates)),
	};
}

function mapStateToProps(state: any, componentProps: Properties) {
	const contentType = componentProps.contentType === ContentTypes.WIKI_PAGE ? 'wiki' : componentProps.contentType;
	const relatedPropPrefix = isEditScreen() ? 'Edit' : '';
	const reduxPropNaming = contentType + relatedPropPrefix;
	const tempPropertyNaming = `temp${capitalize(contentType)}`;

	return {
		contentDataTranslationGroup: state[tempPropertyNaming][reduxPropNaming].translationGroup,
		contentDataLanguage: state[tempPropertyNaming][reduxPropNaming].language,
		contentDataCategory: state[tempPropertyNaming][reduxPropNaming].category,
		contentDataAdditionalCategories: state[tempPropertyNaming][reduxPropNaming].additionalCategories || [],
		relatedSports: state.tempSportsRelated[`${reduxPropNaming}Related`],
		relatedTags: state.tempTagsRelated[`${reduxPropNaming}Related`],
		relatedMatches: state.tempMatchRelated[`${reduxPropNaming}Related`],
		blocksToCopy: state.blocky.blocksToCopy,
		areBlocksCopied: state.blocky.areBlocksCopied,
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(BlockySortable);
