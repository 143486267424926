import React, { FunctionComponent, useEffect, useState } from 'react';
import { Button, FormGroup } from 'reactstrap';
import Scrollbar from 'react-custom-scrollbars';
import { extractParticipantIdsFromRedux } from '../../helpers/events.helper';
import { EventStatusesTypes } from '../../../../../../constants/event.types';
import MatchRow from './match-row';
import { connect } from 'react-redux';
import { SportTypes } from '../../../../../../constants/sport.types';
import { extractRelatedPropertiesNameByUrl, filterReduxRelatedProperties } from '../../helpers/utils';
import { relatedConstants } from '../../../../../../constants/related.constants';
import ResponseMatchModel from '../../../../../../models/v2/match/entity/response-match.model';
import Related from '../../../../../../models/related/Related';
import TournamentResponseModel from '../../../../../../models/v2/Tournament/Entity/response-tournament.model';
import SeasonResponseModel from '../../../../../../models/v2/Season/Entity/response-season.model';
import { loadMoreMatches } from '../../helpers/events-request';
import SportsTypesModel from '../../../../../../models/v2/sports-types/sports-types.model';

type Properties = {
	t: any;
	activeTab: EventStatusesTypes;
	tournamentFilter: TournamentResponseModel | null;
	seasonFilter: SeasonResponseModel | null;
	dateFromFilter: string;
	dateToFilter: string;
	participantsIds: string[];
	alreadyAddedMatchesIds: number[];
	selectedSport: SportsTypesModel;
};

const MatchesScrollbar: FunctionComponent<Properties> = ({
	t,
	activeTab,
	tournamentFilter,
	seasonFilter,
	dateFromFilter,
	dateToFilter,
	participantsIds,
	alreadyAddedMatchesIds,
	selectedSport,
}) => {
	const [loading, setLoading] = useState<boolean>(false);
	const [entitiesLimit, setEntitiesLimit] = useState<number>(0);
	const [loadedMatches, setLoadedMatches] = useState<ResponseMatchModel[] | null>(null);
	const areMatchesAvailable = !!loadedMatches && loadedMatches.length > 0;
	const loadedMatchesWithoutSelected = areMatchesAvailable
		? loadedMatches && loadedMatches.filter((loadedMatch) => !alreadyAddedMatchesIds.includes(loadedMatch.id))
		: [];

	const loadMore = (resetLimit: boolean = false) => {
		setLoading(true);
		loadMoreMatches(
			activeTab,
			tournamentFilter,
			seasonFilter,
			dateFromFilter,
			dateToFilter,
			participantsIds,
			resetLimit ? 0 : entitiesLimit,
			setLoadedMatches,
			setEntitiesLimit,
			setLoading,
			selectedSport,
		);
	};

	const resetEntitiesLimitAndLoadData = () => {
		setEntitiesLimit(0);
		loadMore(true);
	};

	useEffect(resetEntitiesLimitAndLoadData, [
		participantsIds.length,
		activeTab,
		tournamentFilter,
		seasonFilter,
		dateFromFilter,
		dateToFilter,
	]);

	return (
		<FormGroup>
			<Scrollbar style={{ height: areMatchesAvailable ? 300 : 100 }} className={loading ? 'loading-overlay' : ''}>
				{areMatchesAvailable ? (
					<>
						{loadedMatchesWithoutSelected &&
							loadedMatchesWithoutSelected.map((event: ResponseMatchModel) => <MatchRow match={event} key={`event-row-${event.id}`} />)}
						<div className='d-flex justify-content-center'>
							{loadedMatches && entitiesLimit === loadedMatches.length && (
								<Button id='load-more-matches-button' color='primary' outline className='w-80 mt-3 mb-2' onClick={() => loadMore()}>
									{t('load_more')}
								</Button>
							)}
						</div>
					</>
				) : (
					<div className='text-center p-3'>{t('no_matches_found')}</div>
				)}
			</Scrollbar>
		</FormGroup>
	);
};

function mapStateToProps(state: any, componentProps: { selectedSport: SportsTypesModel }) {
	const relatedPropertiesNestedProp = extractRelatedPropertiesNameByUrl();
	const reduxRelatedDataForSportsConnection: Related[] | null = relatedPropertiesNestedProp
		? state.tempSportsRelated[relatedPropertiesNestedProp]
		: null;
	const reduxRelatedDataForMatches: Related[] | null = relatedPropertiesNestedProp
		? state.tempMatchRelated[relatedPropertiesNestedProp]
		: null;
	const alreadyAddedMatchesInRedux = filterReduxRelatedProperties(componentProps.selectedSport, reduxRelatedDataForMatches, [
		relatedConstants.types.match,
	]);

	return {
		participantsIds: extractParticipantIdsFromRedux(componentProps.selectedSport, reduxRelatedDataForSportsConnection),
		alreadyAddedMatchesIds:
			alreadyAddedMatchesInRedux && alreadyAddedMatchesInRedux.filtered ? alreadyAddedMatchesInRedux.filtered.map((el) => el.data.id) : [],
	};
}

export default connect(mapStateToProps)(MatchesScrollbar);
