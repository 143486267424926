import React from 'react';
import { Col, Row } from 'reactstrap';

type Properties = {
	block: any;
	t: any;
};

export const TeamFormView: React.FunctionComponent<Properties> = ({ block, t }) => {
	if (block && block.teamForm && block.teamForm.team && block.teamForm.team.id) {
		return (
			<>
				<Row className={'m-2 ml-4 d-flex align-items-center'}>
					<img src={block.teamForm.team ? block.teamForm.team.logo : ''} height='50' className='mx-1' alt='' />
					<h4>
						{block.teamForm.threeLetterCodeType &&
						block.teamForm.threeLetterCodeType !== '' &&
						block.teamForm.team &&
						block.teamForm.team.threeLetterCode !== null
							? block.teamForm.team.threeLetterCode
							: block.teamForm.team
							? block.teamForm.team.name
							: ''}
					</h4>
				</Row>
				<Row className='px-4'>
					<Col>
						<hr></hr>
					</Col>
				</Row>
				<Row className='px-4'>
					{block.teamForm.title && (
						<Col>
							<strong>{t('title')}:</strong>
							<span className='ml-2'>{block.teamForm.title}</span>
						</Col>
					)}
				</Row>
				<Row className='px-4'>
					{block.teamForm.limit && block.teamForm.limit > 0 && (
						<Col>
							<strong>{t('Number of matches')}:</strong>
							<span className='ml-2'>{block.teamForm.limit}</span>
						</Col>
					)}
				</Row>
				{block.teamForm.threeLetterCodeType && block.teamForm.threeLetterCodeType !== '' && (
					<Row className='px-4'>
						<Col>
							<strong>{t('display_team_three_letter_code')}:</strong>
							<span className='ml-2'>
								{block.teamForm.threeLetterCodeType && block.teamForm.threeLetterCodeType.name && t(`${block.teamForm.threeLetterCodeType.name}`)}
							</span>
						</Col>
					</Row>
				)}
			</>
		);
	}
	return null;
};
