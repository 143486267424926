import { SquadStatisticsWidgetJson } from './squad-statistics-widget.json';
import TeamModel from '../../../../partials/team-select/models/team.model';
import SquadStatisticsWidgetModel from './squad-statistics-widget.model';
import SeasonModel from '../../player-profile/models/season/season.model';

export default class SquadStatisticsWidgetBuilder {
	readonly json: SquadStatisticsWidgetJson;

	constructor(team?: SquadStatisticsWidgetModel | SquadStatisticsWidgetJson) {
		if (team && team instanceof SquadStatisticsWidgetModel) {
			this.json = team.toJson();
		} else if (team) {
			this.json = team;
		} else {
			this.json = {} as SquadStatisticsWidgetJson;
		}
	}

	withTeam(team: TeamModel): SquadStatisticsWidgetBuilder {
		this.json.team = team;
		return this;
	}

	withSeason(season: SeasonModel): SquadStatisticsWidgetBuilder {
		this.json.season = season;
		return this;
	}

	withNoData(noData: boolean): SquadStatisticsWidgetBuilder {
		this.json.noData = noData;
		return this;
	}

	withPlayerPosition(playerPosition: any[]): SquadStatisticsWidgetBuilder {
		this.json.playerPosition = playerPosition;

		return this;
	}

	withStatisticColumns(statisticColumns: any[]): SquadStatisticsWidgetBuilder {
		this.json.statisticColumns = statisticColumns;

		return this;
	}

	build(): SquadStatisticsWidgetModel {
		return SquadStatisticsWidgetModel.fromJson(this.json);
	}
}
