import ListItem from '../../../../../models/list/list-item/ListItem';

export const deleteItemFromList = (isChecked: boolean, itemToDelete: ListItem, listItems: ListItem[]) => {
	if (isChecked) {
		listItems.splice(findIndex(listItems, itemToDelete), 1);
	} else {
		listItems.splice(itemToDelete.weight, 0, itemToDelete);
	}
	return listItems;
};

export const findIndex = (listItems: ListItem[], itemToDelete: ListItem) => {
	return listItems.findIndex((item: ListItem) => item.weight === itemToDelete.weight);
};
