import BannerBlockBuilder from './banner-block.builder';
import { BannerBlockJson } from './banner-block.json';

export default class BannerBlockModel {
	readonly id: string;
	readonly title: string;
	readonly code: string;

	private constructor(id: string, title: string, code: string) {
		this.id = id;
		this.title = title;
		this.code = code;
	}

	toJson(): BannerBlockJson {
		return {
			id: this.id,
			title: this.title,
			code: this.code,
		};
	}

	static fromJson(json: BannerBlockJson): BannerBlockModel {
		return new BannerBlockModel(json.id, json.title, json.code);
	}

	static builder(bannerBlock?: BannerBlockJson): BannerBlockBuilder {
		return new BannerBlockBuilder(bannerBlock);
	}
}
