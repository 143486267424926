import React, { FunctionComponent, useEffect } from 'react';
import { connect } from 'react-redux';
import { extractRelatedPropertiesNameByUrl } from '../../helpers/utils';
import { useReactQuery } from '../../../../../../services/react-query/react-query.helper';
import { relatedConstants } from '../../../../../../constants/related.constants';
import HttpService from '../../../../../../services/rest/HttpService';
import FootballConnectionResponseModel from '../../../../../../models/v2/football-connection/response-football-connection.model';
import { addSuggestedEntity } from '../../../../../../store/action-creators/suggested-entities';
import { generateFootballConnectionWithEntityType } from '../../helpers/suggested-entities.helper';
import SportsTypesModel from '../../../../../../models/v2/sports-types/sports-types.model';

type Properties = {
	reduxSportConnectionsLength: number;
	footballTeamId: string;
	addSuggestedEntity: Function;
	selectedSport: SportsTypesModel;
};

// This component is used only to fetch data with react query -> it is not rendering any HTML
const SuggestedEntityDataFetcher: FunctionComponent<Properties> = ({
	reduxSportConnectionsLength,
	footballTeamId,
	addSuggestedEntity,
	selectedSport,
}) => {
	const { data } = useReactQuery([selectedSport.sport, relatedConstants.types.team, footballTeamId], () =>
		HttpService.getTeamById(footballTeamId),
	);
	const reactQueryData = (data && data.data) || null;

	useEffect(() => {
		if (reactQueryData) {
			const coach = reactQueryData.coach as FootballConnectionResponseModel;
			const tournament = (reactQueryData.current_league && reactQueryData.current_league.tournament) || null;

			if (coach) {
				const footballConnectionWithEntityType = generateFootballConnectionWithEntityType(footballTeamId, relatedConstants.types.coach, coach);
				addSuggestedEntity(footballConnectionWithEntityType);
			}

			if (tournament) {
				const footballConnectionWithEntityType = generateFootballConnectionWithEntityType(
					footballTeamId,
					relatedConstants.types.competition,
					tournament as FootballConnectionResponseModel,
				);
				addSuggestedEntity(footballConnectionWithEntityType);
			}
		}
	}, [reduxSportConnectionsLength, reactQueryData]);

	return null;
};

function mapStateToProps(state: any) {
	const nestedReduxPropertyName = extractRelatedPropertiesNameByUrl();

	return {
		reduxSportConnectionsLength: nestedReduxPropertyName && state.tempSportsRelated[nestedReduxPropertyName].length,
	};
}

function mapDispatchToProps(dispatch: any) {
	return {
		addSuggestedEntity: (data: FootballConnectionResponseModel) => dispatch(addSuggestedEntity(data)),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(SuggestedEntityDataFetcher);
