import PlayerCareerWidgetModel from './player-career-widget.model';
import { PlayerCareerWidgetJson } from './player-career-widget.json';
import PlayerModel from '../../../../partials/player-select/models/player.model';

export default class PlayerCareerWidgetBuilder {
	readonly json: PlayerCareerWidgetJson;

	constructor(playerCareer?: PlayerCareerWidgetModel | PlayerCareerWidgetJson) {
		if (playerCareer && playerCareer instanceof PlayerCareerWidgetModel) {
			this.json = playerCareer.toJson();
		} else if (playerCareer) {
			this.json = playerCareer;
		} else {
			this.json = {} as PlayerCareerWidgetJson;
		}
	}

	withPlayer(player: PlayerModel): PlayerCareerWidgetBuilder {
		this.json.player = player;

		return this;
	}

	withChampionshipFilters(championshipFilters: any[]): PlayerCareerWidgetBuilder {
		this.json.championshipFilters = championshipFilters;

		return this;
	}

	withStatisticsColumns(statisticColumns: any[]): PlayerCareerWidgetBuilder {
		this.json.statisticColumns = statisticColumns;

		return this;
	}

	withTableColumns(tableColumns: any[]): PlayerCareerWidgetBuilder {
		this.json.tableColumns = tableColumns;

		return this;
	}

	withThreeLetterCodeType(threeLetterCodeType: any): PlayerCareerWidgetBuilder {
		this.json.threeLetterCodeType = threeLetterCodeType;

		return this;
	}

	build(): PlayerCareerWidgetModel {
		return PlayerCareerWidgetModel.fromJson(this.json);
	}
}
