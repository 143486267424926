import TournamentGroupModel from '../../../../models/v2/Tournament/Group/TournamentGroupModel';
import { responseToTournamentGroupModel } from '../../../../models/v2/Tournament/Group/TournamentGroupMapper';
import HttpService from '../../../../services/rest/HttpService';
import { toast } from 'react-toastify';
import TournamentOrderModel from '../../../../models/v2/Tournament/TournamentOrder/TournamentOrderModel';
import TournamentModel from '../../../../models/v2/Tournament/Entity/TournamentModel';
import { responseToModelTournament } from '../../../../models/v2/Tournament/Entity/TournamentMapper';
import { DisabledSaveModel } from './TournamentsGroupsUiService';

export const getTournamentsGroups = async (errorMessage: string = '') => {
	try {
		const responseArray = await HttpService.getAllTournamentsGroups();
		let data = (await responseArray.data.groups) as TournamentGroupModel[];
		if (data && data.length > 0) {
			return data.map((response: any) => {
				return response ? responseToTournamentGroupModel(response) : new TournamentGroupModel();
			});
		}
		return [] as TournamentGroupModel[];
	} catch (e) {
		errorMessage && toast.error(errorMessage);
		return [] as TournamentGroupModel[];
	}
};

export const getTournamentsGroupByCode = async (code: string, errorMessage: string = '') => {
	try {
		const responseArray = await HttpService.getTournamentsGroupByCode(code);
		let data = (await responseArray.data) as TournamentGroupModel;
		return data.code && data.name ? responseToTournamentGroupModel(data) : new TournamentGroupModel();
	} catch (e) {
		errorMessage && toast.error(errorMessage);
		return new TournamentGroupModel();
	}
};

export const loadSearchTournaments = async (selectInput: string, lang: string = 'en') => {
	try {
		const responseArray = await HttpService.getFootballData(selectInput, lang, 'tournament');
		let data = (await responseArray.data) as TournamentModel[];
		if (data && data.length > 0) {
			return data.map((response: any) => {
				return response ? responseToModelTournament(response) : new TournamentModel();
			});
		}
		return [] as TournamentModel[];
	} catch (e) {
		return [] as TournamentModel[];
	}
};

const removeEmptyTournament = (tournamentsOrder: TournamentOrderModel[]) => {
	return tournamentsOrder.filter((tournamentOrder: TournamentOrderModel) => tournamentOrder.tournament.id !== '');
};

const tournamentGroupDataToRequestData = (data: TournamentGroupModel) => {
	try {
		const neccessaryData = Object.assign({}, data) as any;
		neccessaryData.tournaments = removeEmptyTournament(neccessaryData.tournaments).map((tournamentOrder: TournamentOrderModel) => {
			return { tournament_id: tournamentOrder.tournament.id, sort_order: tournamentOrder.sortOrder };
		});

		return neccessaryData;
	} catch (e) {
		return TournamentGroupModel;
	}
};

const isResponseStatusOkay = (response: any) => response.status >= 200 && response.status < 300;

const tournamentGroupDataErrorHandler = async (response: any, backupSuccess: string, backupError: string) => {
	const responseJSON = (await response.json()) || null;
	if (isResponseStatusOkay(response)) {
		responseJSON.message ? toast.success(responseJSON.message) : toast.success(backupSuccess);
	} else {
		responseJSON.message ? toast.error(responseJSON.message) : toast.error(backupError);
	}
};

const isTournamentGroupInfoDataCorrect = (data: TournamentGroupModel): boolean => {
	if (!data.code || !data.name) {
		return false;
	}

	return true;
};

export const createTournamentsGroup = async (
	data: TournamentGroupModel,
	history: any,
	successMessage: string = '',
	errorMessage: string = '',
) => {
	try {
		if (isTournamentGroupInfoDataCorrect(data)) {
			const neccessaryData = tournamentGroupDataToRequestData(data);
			const response = await HttpService.createTournamentsGroup(neccessaryData);
			isResponseStatusOkay(response) && history.push(`/smp/tournament-groups/edit/${data.code}`);
			tournamentGroupDataErrorHandler(response, successMessage, errorMessage);
		} else {
			toast.error(errorMessage);
		}
	} catch (e) {
		errorMessage && toast.error(errorMessage);
	}
};

export const updateTournamentsGroup = async (
	data: TournamentGroupModel,
	setDisabledSave: Function,
	setDefaultTournamentsGroup: Function,
	setTournamentsGroup: Function,
	successMessage: string = '',
	errorMessage: string = '',
) => {
	try {
		if (isTournamentGroupInfoDataCorrect(data)) {
			const tournamentCode = data.code;
			const neccessaryData = tournamentGroupDataToRequestData(data);
			delete neccessaryData.code;
			const response = await HttpService.updateTournamentsGroupByCode(tournamentCode, neccessaryData);
			tournamentGroupDataErrorHandler(response, successMessage, errorMessage);
			if (isResponseStatusOkay(response)) {
				const updatedGroupState = Object.assign({}, data);
				updatedGroupState.tournaments = removeEmptyTournament(updatedGroupState.tournaments);
				setDefaultTournamentsGroup(updatedGroupState);
				setTournamentsGroup(updatedGroupState);
				setDisabledSave(new DisabledSaveModel());
			}
		} else {
			toast.error(errorMessage);
		}
	} catch (e) {
		errorMessage && toast.error(errorMessage);
	}
};
