import React, { FunctionComponent, useState } from 'react';
import './image-dropzone.scss';
import { OnImageUploaded } from './image-dropzone';
import GeneralModal from '../modal/modal';
import { useTranslation } from 'react-i18next';
import { getFullDate } from '../../../../global-helpers/global-dates.helper';

type Props = {
	data: OnImageUploaded;
	onRemoveImage: (data: OnImageUploaded) => void;
};

const ImagePreview: FunctionComponent<Props> = ({ data, onRemoveImage }) => {
	const { t } = useTranslation();
	const { original_filename, timestamp } = data;
	const [imagePath, setImagePath] = useState('');

	const onPreviewImage = () => {
		data.url ? setImagePath(data.url) : setImagePath(data.fullImageUrl);
	};

	return (
		<div className='image-preview'>
			<div className='thumbnail'>
				<div className='image'></div>
			</div>
			<div className='info'>
				<span> {original_filename || t('image_name_placeholder')} </span>
				<span className='date'> {getFullDate(new Date(timestamp))}</span>
			</div>
			<div className='actions'>
				<div className='preview' onClick={onPreviewImage}></div>
				<div className='remove' onClick={() => onRemoveImage(data)}></div>
			</div>
			<GeneralModal
				isDisplayed={!!imagePath}
				bodyHTML={[<img key={imagePath} src={imagePath} alt={data.original_filename} />, <br key={data.original_filename} />]}
				title={t('preview_image')}
				onClose={() => setImagePath('')}
				cancelButtonFlag={false}
			/>
		</div>
	);
};

export default ImagePreview;
