import { SocialJson } from './social-json';
import SocialBuilder from './social-builder';

export default class Social {
	readonly web: string;
	readonly twitterId: string;
	readonly facebookId: string;
	readonly instagramId: string;
	readonly wikipediaId: string;
	readonly youtubeId: string;

	private constructor(web: string, twitterId: string, facebookId: string, instagramId: string, wikipediaId: string, youtubeId: string) {
		this.web = web;
		this.twitterId = twitterId;
		this.facebookId = facebookId;
		this.instagramId = instagramId;
		this.wikipediaId = wikipediaId;
		this.youtubeId = youtubeId;
	}

	toJSON(): SocialJson {
		return {
			web: this.web,
			twitterId: this.twitterId,
			facebookId: this.facebookId,
			instagramId: this.instagramId,
			wikipediaId: this.wikipediaId,
			youtubeId: this.youtubeId,
		};
	}

	static fromJSON(json: SocialJson): Social {
		return new Social(json.web, json.twitterId, json.facebookId, json.instagramId, json.wikipediaId, json.youtubeId);
	}

	static builder(social?: Social): SocialBuilder {
		return new SocialBuilder(social);
	}
}
