import { FormGroup, Input, Label } from 'reactstrap';
import moment from 'moment';
import React from 'react';
import MatchModel from '../../../../../../../models/v2/match/entity/match.model';

type Properties = {
	isMulti: boolean;
	onMatchSelect: any;
	onMatchesSelect: any;
	matchesFinished: MatchModel[];
	finishedMatchesAreSelected: any;
	t: any;
	applyBoldCss: (teamName: string) => void;
	selectAllMatches: (matches: any, event: any) => void;
};

export const MatchesFinished: React.FunctionComponent<Properties> = (props) => {
	if (props.matchesFinished.length < 1) {
		return <div className='text-center p-3'>{props.t('no_matches_found')}</div>;
	}

	return (
		<>
			<div data-qa='matches-finished-tab'>
				{props.isMulti && (
					<FormGroup className='mb-2 pb-2 border-bottom' check>
						<Input
							className='form-check-input'
							onChange={(event) => props.selectAllMatches(props.matchesFinished, event)}
							type='checkbox'
							checked={props.matchesFinished[0].isSelected && props.finishedMatchesAreSelected}
							id={`select-all-finished-matches${props.matchesFinished.length}`}
							name={props.t('select_all_matches')}
						/>
						<Label className='form-check-label' check htmlFor={`select-all-finished-matches${props.matchesFinished.length}`}>
							{props.t('select_all_matches')}
						</Label>
					</FormGroup>
				)}
				{props.matchesFinished.map((match) => {
					return (
						<FormGroup key={`key-${match.id}`} className='mb-2 pb-2 border-bottom' check>
							<Input
								className='form-check-input'
								onChange={(event) => {
									if (props.isMulti) {
										props.onMatchesSelect(match, event);
									} else {
										props.onMatchSelect(match);
									}
								}}
								type={props.isMulti ? 'checkbox' : 'radio'}
								id={`${match.id}`}
								name={`inline-radio-${match.id}`}
								checked={match.isSelected}
							/>
							<Label className='form-check-label' check htmlFor={`${match.id}`}>
								{`${moment(match.finishedAt).format('DD MMM YYYY')} - `}
								<span className={`${props.applyBoldCss(match.homeTeam.name)}`}>{match.homeTeam.name}</span>
								{` ${match.homeGoal}:${match.awayGoal} `}
								<span className={`${props.applyBoldCss(match.awayTeam.name)}`}>{match.awayTeam.name}</span>
							</Label>
						</FormGroup>
					);
				})}
			</div>
		</>
	);
};

export default MatchesFinished as React.ComponentType<Properties>;
