import 'reflect-metadata';
import { AnyT, jsonMember, jsonObject } from 'typedjson';

@jsonObject()
export default class MainMediaModel {
	@jsonMember(String)
	public description: string | null = null;

	@jsonMember(String, { name: 'resource_id' })
	public resourceId: string | null = null;

	@jsonMember(String, { name: 'resource_type' })
	public resourceType: string | null = null;

	@jsonMember(String, { name: 'resource_subtype' })
	public resourceSubtype: string | null = null;

	@jsonMember(String)
	public provider: string | null = null;

	@jsonMember(AnyT)
	public data: any = null;

	@jsonMember(Boolean)
	public addDescriptionToMedia: boolean = false;

	@jsonMember(Boolean)
	public addWatermarkToImageMedia: boolean = false;

	@jsonMember(AnyT)
	public generic: any = null;
}
