import classNames from 'classnames';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import { assetsPlaceholder } from '../../../../../../../constants/assetsPlaceholder';
import { imagePlaceHolder } from '../../../../../../../constants/resource-constants';
import { generateRefreshTimeOptionViewText } from '../../../partials/refresh-time-input/helper';
import StakesModel from '../models/stakes-widget.model';

type Properties = {
	preview: StakesModel;
};

export const StakesViewComponent: React.FunctionComponent<Properties> = ({ preview }) => {
	const [t] = useTranslation();

	if (!preview || !preview.match || !preview.match.id) {
		return null;
	}

	return (
		<div>
			<Row className='pt-2 pl-4 pr-4'>
				<Col className='text-left'>
					<Row className='mb-1'>
						<Col>
							<div className='mb-1 font-weight-bold d-inline-block mr-5'>
								{preview.match.startTime ? moment(preview.match.startTime).format('MMM Do YYYY, HH:mm') : ''}
							</div>
							<div className='d-inline-block'>
								<strong className='p-1'>{preview.match.homeTeam ? preview.match.homeTeam.name : ''}</strong>
								<img
									height='40'
									alt={preview.match.homeTeam ? preview.match.homeTeam.name : ''}
									src={
										preview.match.homeTeam.urlLogo && preview.match.homeTeam.urlLogo.length > 0
											? preview.match.homeTeam.urlLogo
											: assetsPlaceholder.team
									}
									className='h-40 mx-1'
								/>
							</div>
							<div className='d-inline-block font-weight-bold'>:</div>
							<div className='d-inline-block'>
								<img
									height='40'
									alt={preview.match.awayTeam ? preview.match.awayTeam.name : ''}
									src={
										preview.match.awayTeam.urlLogo && preview.match.awayTeam.urlLogo.length > 0
											? preview.match.awayTeam.urlLogo
											: assetsPlaceholder.team
									}
									className='h-40 mx-1'
								/>
								<strong className='p-1'>{preview.match.awayTeam ? preview.match.awayTeam.name : ''}</strong>
							</div>
						</Col>
					</Row>
					<Row>
						<Col>
							<hr />
						</Col>
					</Row>
					<Row className='mb-2'>
						<Col>
							{t('show')}:
							{preview.bookmakers &&
								preview.bookmakers.length > 0 &&
								preview.bookmakers.map((bookmaker) => (
									<img
										key={bookmaker.id}
										height='20'
										alt={bookmaker.name}
										className='h-20 mx-1'
										src={bookmaker.assets[0] ? bookmaker.assets[0].logo : imagePlaceHolder}
										title={bookmaker.name}
									/>
								))}
						</Col>
					</Row>
					<Row className='mb-2'>
						<Col>
							{t('amount')}:{!isNaN(preview.amount) && <strong> {preview.amount}</strong>}
						</Col>
					</Row>
					<Row className='mb-2'>
						<Col>
							{t('fixed_amount')}:
							<strong
								className={classNames({
									'text-success': preview.fixedAmount,
									'text-danger': !preview.fixedAmount,
								})}
							>
								{preview.fixedAmount ? ` ${t('yes')}` : ` ${t('no')}`}
							</strong>
						</Col>
					</Row>
				</Col>
			</Row>
			{generateRefreshTimeOptionViewText(preview.refreshTime)}
		</div>
	);
};
