import React, { FunctionComponent, useState } from 'react';
import moment from 'moment';
import Profile from '../../../../../models/profile/Profile';
import WikiRowModel from '../../../../../models/Wikipages/Row/WikiRowModel';
import { Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Nav } from 'reactstrap';
import { Link } from 'react-router-dom';
import { generateLinkTitleByRow } from '../TableListing.helper';

type TableRowsProps = {
	t: any;
	content: WikiRowModel[];
	profile: Profile;
	setSelectedRowForDelete: Function;
};

const dateFormat = 'DD.MM.YYYY, HH:mm';
const permissionName = 'delete_wikipages';

export const TableRows: FunctionComponent<TableRowsProps> = ({ t, content, profile, setSelectedRowForDelete }) => {
	const lang = profile.language.split('_')[0];
	const pageName = generateLinkTitleByRow(content);
	const [dropdownOpen, setDropdownOpen] = useState({ id: '', isOpen: false });
	const permissionForDelete = profile.permissions.findIndex((el) => el.name === permissionName) >= 0;

	const toggleDropdown = (id: string) => {
		setDropdownOpen({ id, isOpen: !dropdownOpen.isOpen });
	};

	return (
		<tbody>
			{content && content.length > 0 ? (
				content.map((contentEntity: WikiRowModel) => {
					return (
						<tr key={`table-row-${contentEntity.id}`} data-qa={`table-row-${contentEntity.id}`}>
							<td className='w-title col-md-4 col-sm-6 align-middle'>
								<Link to={`/smp/${pageName}/edit/${contentEntity.id}`}>
									{contentEntity.strapline && contentEntity.strapline !== '' && (
										<span className={'pl-1 pr-1 d-inline-block'} style={{ backgroundColor: '#c5c5c5' }}>{`${contentEntity.strapline}`}</span>
									)}
									<span className={`pl-1 text-break}`}>{contentEntity.title}</span>
								</Link>
							</td>
							<td className='col-md-2 align-middle'>{contentEntity.type}</td>
							<td className='col-md-1 col-sm-2 text-center align-middle'>
								{' '}
								{contentEntity.status === 'active' ? (
									<i className='fa-lg fa fa-check-circle text-success mx-auto' />
								) : (
									<i className='fa-lg fa fa-times-circle text-danger mx-auto' />
								)}
							</td>
							<td className='d-none d-md-table-cell col-md-3 align-middle'>{moment(contentEntity.publishedAt).locale(lang).format(dateFormat)}</td>
							<td className='col-md-2 align-middle'>
								<Nav className='justify-content-end'>
									<Dropdown nav isOpen={dropdownOpen.id == contentEntity.id && dropdownOpen.isOpen} toggle={() => toggleDropdown(contentEntity.id)}>
										<DropdownToggle id={`toggle-menu-caret-${contentEntity.id}`} nav caret className='d-flex align-items-center p-0 text-secondary'>
											<Link to={`/smp/${pageName}/edit/${contentEntity.id}`}>
												<Button className='btn-warning'>
													<i className='fa fa-pencil p-1' />
													{t('edit')}
												</Button>
											</Link>
										</DropdownToggle>
										<DropdownMenu>
											<DropdownItem className='p-0 border-0'>
												{permissionForDelete && (
													<Button
														className='btn btn-danger d-flex flex-fill justify-content-center dropdown-row-btn'
														onClick={() => setSelectedRowForDelete(contentEntity)}
													>
														<i className='fa fa-trash p-1 text-white' /> {t('delete')}
													</Button>
												)}
											</DropdownItem>
										</DropdownMenu>
									</Dropdown>
								</Nav>
							</td>
						</tr>
					);
				})
			) : (
				<h6 className='d-flex justify-content-center mt-4'>{t('no_data_found')}</h6>
			)}
		</tbody>
	);
};
