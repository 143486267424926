import * as React from 'react';
import { Properties } from './properties/status-select.properties';
import Select from 'react-select';
import { contentToOption, contentToOptions } from '../type-select/helpers/type-select.helper';

const StatusSelect: React.FunctionComponent<Properties> = (props) => {
	const { statuses, t, selectedStatus, onStatusSelect, isClearable, contentType } = props;

	const onContentChange = (selection: any) => {
		onStatusSelect(selection && selection.value ? selection.value : '');
	};

	return (
		<div data-qa={`${contentType}-status-select`}>
			<Select
				inputId='content-status-select'
				value={selectedStatus ? contentToOption(selectedStatus, t, statuses) : ''}
				options={contentToOptions(statuses, t)}
				onChange={onContentChange}
				placeholder={t('select')}
				isClearable={isClearable}
			/>
		</div>
	);
};

export default StatusSelect;
