import { TypedJSON } from 'typedjson';
import AdminModel from './admin.model';
import { store } from '../../../store/store';

export default class AdminMapper {
	public responseToModel = (response: any) => {
		const serializer = new TypedJSON(AdminModel);
		return serializer.parse(response);
	};

	public optionToModel = (option: any) => {
		const model: AdminModel = option.data;
		return model;
	};

	public modelToOption = (model: AdminModel, t?: any) => {
		if (t) {
			return {
				value: model.id,
				label: model.id === store.getState().profile.profile.id ? t('me') : model.fullName,
				data: model,
			};
		} else {
			return {
				value: model.id,
				label: model.fullName,
				data: model,
			};
		}
	};
}
