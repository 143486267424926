import { call, put, takeEvery } from 'redux-saga/effects';
import HttpService from '../../services/rest/HttpService';
import { toggleLoadingState, searchIsApplied } from '../action-creators/UiActionCreator';
import { onError } from '../action-creators/GeneralActions';
import {
	returnObjectForPlayerListingReceived,
	returnObjectForPlayerEntityCreateSuccess,
	returnObjectForPlayerEntityUpdateSuccess,
	returnObjectForPlayerEntityUpdateFailed,
	returnObjectForPlayerEntityCreateFailed,
	returnObjectForPlayerEntityReceived,
	playerAssetsPostSuccess,
	playerAssetsPostFailed,
	PLAYER_ENTITY_POST_ASSETS,
	PLAYER_LISTING_REQUEST,
	returnObjectForSearchPlayerListingReceived,
	PLAYER_SEARCH,
	PLAYER_ENTITY_CREATE,
	PLAYER_ENTITY_REQUEST,
	PLAYER_ENTITY_UPDATE,
	returnObjectForPlayerBlacklistReceived,
} from '../action-creators/player-action-creator';
import { actionService } from '../../App';
import {
	remapPlayerBlacklisted,
	constructRequestFromEntry,
	constructRequestFromAssets,
} from '../../views/Resources/manual-data-entries/subcomponents/helpers/manual-data.helper';

function* fetchPlayers(action: any) {
	yield put(toggleLoadingState(true));

	try {
		const players = yield call(HttpService.instanceFootball().get, `/v2/players?limit=20&offset=${(action.payload.page - 1) * 20}`);
		const playersForBlacklist = constructRequestFromEntry(players.data.players, 'PLAYER', 'ENTITY');
		const blacklist = yield call(HttpService.getBlacklist, `/v2/blacklist/search`, playersForBlacklist);
		yield put(returnObjectForPlayerListingReceived(players));
		yield put(returnObjectForPlayerBlacklistReceived(remapPlayerBlacklisted(players.data.players, blacklist.data)));
		yield put(toggleLoadingState(false));
	} catch (error) {
		yield put(onError(error));
	}
}

function* fetchPlayerEntityFromApi(action: any) {
	try {
		let headers = { Project: action.payload.project.domain };
		const player = yield call(HttpService.instanceFootball().get, `/v2/players/${action.payload.id}`, null, headers);
		yield put(returnObjectForPlayerEntityReceived(player));
	} catch (error) {
		yield put(onError(error));
	}
}

function* searchPlayers(action: any) {
	yield put(toggleLoadingState(true));

	try {
		let text = action.payload.text;
		let lang = action.payload.lang;
		let type = action.payload.type;
		const players = yield call(HttpService.getFootballData, text, lang, type);
		yield put(returnObjectForSearchPlayerListingReceived(players));
		const playersForBlacklist = constructRequestFromEntry(players.data, 'PLAYER', 'ENTITY');
		const blacklist = yield call(HttpService.getBlacklist, `/v2/blacklist/search`, playersForBlacklist);
		yield put(returnObjectForPlayerBlacklistReceived(remapPlayerBlacklisted(players.data, blacklist.data)));
		yield put(toggleLoadingState(false));
		yield put(searchIsApplied());
	} catch (error) {
		yield put(onError(error));
	}
}

function* postPlayerEntityToApi(action: any) {
	let headers = { Project: action.payload.project.domain };
	let playerResponse: any = {};

	try {
		let playerPayload = action.payload.player;
		playerResponse = yield call(HttpService.instanceFootball().post, '/v2/players', playerPayload, headers);

		let assets = constructRequestFromAssets(action.payload.assets, 'PLAYER', playerResponse.data.id);
		yield call(HttpService.instanceFootball().post, `/v2/assets/`, assets, headers);

		yield put(returnObjectForPlayerEntityCreateSuccess(playerResponse.data.id));
	} catch (error) {
		yield put(returnObjectForPlayerEntityCreateFailed());
		yield put(onError(error));
		actionService.emitError(error);
	}
}

function* patchPlayerEntityToApi(action: any) {
	let headers = { Project: action.payload.project.domain };

	try {
		let playerPayload = action.payload.player;
		let id = action.payload.player.id;

		yield call(HttpService.instanceFootball().put, `/v2/players/${id}`, playerPayload, headers);
		yield put(returnObjectForPlayerEntityUpdateSuccess());
	} catch (error) {
		yield put(returnObjectForPlayerEntityUpdateFailed());
		yield put(onError(error));
		actionService.emitError(error);
	}
}
function* patchPlayerAssets(action: any) {
	let headers = { Project: action.payload.project.domain };

	try {
		let assets = constructRequestFromAssets(action.payload.assets, 'PLAYER', action.payload.playerId);
		yield call(HttpService.instanceFootball().post, `/v2/assets/`, assets, headers);
		yield put(playerAssetsPostSuccess());
	} catch (error) {
		yield put(playerAssetsPostFailed());
		yield put(onError(error));
		actionService.emitError(error);
	}
}

function* playerSaga() {
	yield takeEvery(PLAYER_LISTING_REQUEST, fetchPlayers);
	yield takeEvery(PLAYER_SEARCH, searchPlayers);
	yield takeEvery(PLAYER_ENTITY_CREATE, postPlayerEntityToApi);
	yield takeEvery(PLAYER_ENTITY_REQUEST, fetchPlayerEntityFromApi);
	yield takeEvery(PLAYER_ENTITY_UPDATE, patchPlayerEntityToApi);
	yield takeEvery(PLAYER_ENTITY_POST_ASSETS, patchPlayerAssets);
}

export default playerSaga;
