import ContentAttributes from '../../../../../../../models/content-attributes/content-attributes-model';

export const contentToOptions = (content: ContentAttributes[], t: any): any[] => {
	return content && content.length > 0 ? content.map((item: ContentAttributes) => contentToOption(item.slug, t, content)) : [];
};

export const contentToOption = (item: string, t: any, content: ContentAttributes[]) => {
	return {
		value: item,
		label: extractContentName(content, item, t),
	};
};

export const typesToOptions = (types: ContentAttributes[], t: any): any[] => {
	let options: any[] = [];
	if (types) {
		options = types.map((type: ContentAttributes) => returnMappedTypes(type, t));
	}

	return options;
};

export const returnMappedTypes = (type: ContentAttributes, t: any) => {
	return {
		value: type.slug,
		label: t(`${type.name}`),
		type,
	};
};

export function selectedTypesToOptions(types: ContentAttributes[]) {
	let selectedOptions: any[] = [];
	if (types) {
		selectedOptions = types.map((type: ContentAttributes) => typeToOption(type));
	}

	return selectedOptions;
}

export function typeToOption(type: any) {
	let option = {};
	if (type) {
		option = { label: type.label, value: type.value, type };
	}
	return option;
}

export function selectedOptionsToTypes(selections: any[]) {
	let types: ContentAttributes[] = [];
	if (selections) {
		types = selections.map((selection: any) => selection);
	}

	return types;
}

export const remapContentItemsFromResponse = (item: any) => {
	if (item && Object.entries(item).length > 0) {
		return ContentAttributes.builder().withId(item.id).withName(item.name).withSlug(item.slug).withDefault(item.default).build();
	}

	return ContentAttributes.builder().build();
};

export const extractContentName = (content: ContentAttributes[], slug: string, t: any) => {
	if (slug && slug.length > 0) {
		const item = content && content.filter((item: ContentAttributes) => item.slug === slug)[0];

		return t(item && item.name);
	}

	return '';
};
