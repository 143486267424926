import { FilterJson } from './filter.json';
import FilterBuilder from './filter.builder';

export default class FilterModel {
	readonly filterSport: any = [];
	readonly filterLanguage: any = [];
	readonly filterCompetition: any = [];

	private constructor(filterSport: any, filterLanguage: any, filterCompetition: any) {
		this.filterSport = filterSport;
		this.filterLanguage = filterLanguage;
		this.filterCompetition = filterCompetition;
	}

	toJson(): FilterJson {
		return {
			filterSport: this.filterSport,
			filterLanguage: this.filterLanguage,
			filterCompetition: this.filterCompetition,
		};
	}

	static fromJson(json: FilterJson): FilterModel {
		return new FilterModel(json.filterSport, json.filterLanguage, json.filterCompetition);
	}
	static builder(filter?: FilterModel): FilterBuilder {
		return new FilterBuilder(filter);
	}
}
