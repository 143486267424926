import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import FixturesWidgetEdit from './fixtures-widget-edit.component';
import { FixturesWidgetView } from './fixtures-widget-view.component';
import { ViewTypes } from '../../../../constants/general.constants';
import OddsWidgetModel from '../../odds/models/odds-widget.model';
import FixtureModel from '../models/fixtures-widget.model';
import { Properties } from './properties/fixtures-widget-container.properties';
import { WidgetPreview } from '../../widget-preview/widget-preview.component';
import BlockUpdateController from '../../../block-update-controller.component';

class FixturesWidget extends React.Component<Properties> {
	render() {
		const { block, t, view } = this.props;

		if (view === ViewTypes.edit) {
			return (
				<FixturesWidgetEdit
					t={t}
					block={block}
					fixtures={block.data.preview && block.data.preview.fixtures}
					odds={block.data.preview && block.data.preview.odds}
					upcomingMatchesAreSelected={block.data.preview && block.data.preview.fixtures.upcomingMatchesAreSelected}
					finishedMatchesAreSelected={block.data.preview && block.data.preview.fixtures.finishedMatchesAreSelected}
				/>
			);
		}

		if (view === ViewTypes.normal) {
			return (
				<FixturesWidgetView
					t={t}
					showAllBookmakers
					oddsPreview={block.data.preview && block.data.preview.odds ? block.data.preview.odds : OddsWidgetModel.builder().build()}
					fixturesPreview={block.data.preview && block.data.preview.fixtures ? block.data.preview.fixtures : FixtureModel.builder().build()}
				/>
			);
		}

		if (view === ViewTypes.preview) {
			return <WidgetPreview blockContent={block.data.content} config={block.data.config} />;
		}

		return null;
	}
}

function mapStateToProps(state: any) {
	return {
		lang: state.profile.profile.language,
		currentProject: state.project.currentProject,
	};
}

export default compose(connect(mapStateToProps, null), BlockUpdateController)(FixturesWidget);
