import React, { FunctionComponent, useEffect, useState } from 'react';
import {
	ExtendedNodeData,
	FullTree,
	NodeData,
	OnMovePreviousAndNextLocation,
	OnVisibilityToggleData,
	SortableTreeWithoutDndContext as SortableTree,
	TreeItem,
} from 'react-sortable-tree';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import { connect } from 'react-redux';
import { pushDataToModal, sortDataInRedux } from '../../../../../store/action-creators/multisport-action-creator';
import { MultisportModalsType } from '../modals/constants';
import { populateCurrentlyCollapsedElements } from '../../helpers/utils';
import { MultisportCompetitionModel } from '../../models/competition.model';
import MultisportEventModel from '../../models/event.model';
import { useTranslation } from 'react-i18next';
import { changeTreeState, generateTreeDataTitle } from '../../helpers/sortable-tree.helper';

type Properties = {
	previewEventsTree: TreeItem[];
	displayRemoveEntityModal: (entity: MultisportCompetitionModel | MultisportEventModel, previewSection: string) => void;
	updateEventsOrderInRedux: (updatedTreeData: TreeItem[]) => void;
};

const TournamentPreviewData: FunctionComponent<Properties> = ({
	previewEventsTree,
	displayRemoveEntityModal,
	updateEventsOrderInRedux,
}) => {
	const { t } = useTranslation();
	const isTouchDevice = !!('ontouchstart' in window || navigator.maxTouchPoints);
	const dndBackend = isTouchDevice ? TouchBackend : HTML5Backend;
	const [treeDataWithCollapse, setTreeDataWithCollapse] = useState<TreeItem[]>([]);

	useEffect(() => {
		const treeDataWithCollapsePopulated = populateCurrentlyCollapsedElements(treeDataWithCollapse, previewEventsTree);
		setTreeDataWithCollapse(treeDataWithCollapsePopulated);
	}, [previewEventsTree]);

	return (
		<DndProvider backend={dndBackend}>
			<SortableTree
				getNodeKey={(node: any) => node.node.id}
				className='mb-2'
				treeData={treeDataWithCollapse}
				onChange={() => undefined}
				onVisibilityToggle={({ treeData }: OnVisibilityToggleData) => setTreeDataWithCollapse(treeData)}
				onMoveNode={(data: NodeData & FullTree & OnMovePreviousAndNextLocation) => changeTreeState(data, updateEventsOrderInRedux)}
				style={{ height: '400px', width: '100%' }}
				rowHeight={68}
				maxDepth={2}
				generateNodeProps={({ node }: ExtendedNodeData) => ({
					title: generateTreeDataTitle(node, displayRemoveEntityModal),
				})}
			/>
		</DndProvider>
	);
};

function mapDispatchToProps(dispatch: any) {
	return {
		displayRemoveEntityModal: (entity: MultisportCompetitionModel | MultisportEventModel, previewSection: string) =>
			dispatch(pushDataToModal(MultisportModalsType.RemoveEntity, { entity, previewSection })),
		updateEventsOrderInRedux: (updatedTreeData: TreeItem[]) => dispatch(sortDataInRedux(updatedTreeData)),
	};
}

export default connect(null, mapDispatchToProps)(TournamentPreviewData);
