const copyKeys = {
	ctrlKey: true,
	shiftKey: true,
	keyCode: 75, //code for key "K"
};

const pasteKeysBottom = {
	ctrlKey: true,
	shiftKey: true,
	keyCode: 86, //code for key "V"
};

const pasteKeysTop = {
	ctrlKey: true,
	shiftKey: true,
	keyCode: 65, //code for key "A"
};

export const keyMap = {
	copyKeys,
	pasteBottom: pasteKeysBottom,
	pasteTop: pasteKeysTop,
};
