import React from 'react';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { Properties, RequiredProperties, State } from './properties/SeoProperties';
import SlugContainer from './subcomponents/Slug/SlugContainer';
import KeywordsContainer from './subcomponents/Keywords/KeywordsContainer';
import 'react-tagsinput/react-tagsinput.css';
import SeoTitleContainer from './subcomponents/SeoTitle/SeoTitleContainer';
import { connect } from '../../../../store/store.connect';
import { AppState } from '../../../../store/store';
import { DebounceInput } from 'react-debounce-input';
import { slugifyString } from '../seo-refactored/helpers/slug.helper';

@connect<Properties, AppState>(
	(state) => ({
		isToggledModal: state.ui.isToggleModal,
	}),
	(dispatch) => ({
		dispatch: (action: any) => dispatch(action),
	}),
)
class SeoComponent extends React.Component<Properties, State> {
	constructor(props: any) {
		super(props);
		this.state = {
			tooltipOpen: false,
		};
	}

	componentDidMount() {
		const { value } = this.props;

		if (value && value.autoTitle !== false) {
			this.onAutoTitleToggle(true);
		}

		if (value && value.autoSlug !== false) {
			this.onAutoSlugToggle(true);
		}
	}

	toggle = () => {
		this.setState({
			tooltipOpen: !this.state.tooltipOpen,
		});
	};

	//TODO remove this method in the next task which will add the JSON-LD to the other SEO compojents
	displayJsonLdField = (type: string) => {
		let display = false;
		if (type === 'article') {
			display = true;
		} else if (type === 'video') {
			display = true;
		} else if (type === 'gallery') {
			display = true;
		} else if (type === 'author') {
			display = true;
		}

		return display;
	};

	onKeywordsChange = (keywords: string[]) => {
		const value = this.props.value;
		value.keywords = keywords;
		this.props.onChange(value);
	};

	onSeoTitleChange = (title: string) => {
		const value = this.props.value;
		value.title = title;
		value.slug = value.autoSlug ? slugifyString(value.title) : value.slug;
		this.props.onChange(value);
	};

	onSeoDescriptionChange = (event: any) => {
		const value = this.props.value;
		value.description = event.target.value;
		this.props.onChange(value);
	};

	onSlugChange = (slug: string) => {
		const value = this.props.value;
		value.slug = slugifyString(slug);
		this.props.onChange(value);
	};

	onAutoTitleToggle = (toggle: boolean) => {
		const value = this.props.value;
		value.autoTitle = toggle;
		value.title = toggle ? value.contentTitle : value.title;
		value.slug = value.autoSlug ? slugifyString(value.title) : value.slug;
		this.props.onChange(value);
	};

	onAutoSlugToggle = (toggle: boolean) => {
		const value = this.props.value;
		value.autoSlug = toggle;
		value.slug = toggle ? slugifyString(value.title) : value.slug;
		this.props.onChange(value);
	};

	onAllowIndexingChange = (event: any) => {
		const value = this.props.value;
		value.indexing = event.target.checked;
		this.props.onChange(value);
	};

	onAllowFollowingChange = (event: any) => {
		const value = this.props.value;
		value.following = event.target.checked;
		this.props.onChange(value);
	};

	onJsonLdChange = (event: any) => {
		const value = this.props.value;
		value.jsonld = event.target.value;
		this.props.onChange(value);
	};

	render() {
		const { t, value, isToggledModal, contentType } = this.props;
		return (
			<div id='sidebar-seo' className={`${isToggledModal ? 'loading-overlay' : ''}`}>
				<Row>
					<Col>
						<SeoTitleContainer
							t={t}
							title={value ? value.title : ''}
							contentTitle={value.contentTitle}
							autoTitle={value.autoTitle}
							onAutoTitleChange={this.onAutoTitleToggle}
							onTitleChange={this.onSeoTitleChange}
						/>
					</Col>
				</Row>

				<FormGroup>
					<Row>
						<Col>
							<Label htmlFor='seo_description'>{t('seo_description')}</Label>
							<Input
								type='textarea'
								id='seo_description'
								placeholder={t('seo_description_placeholder')}
								rows='2'
								value={value && value.description ? value.description : ''}
								onChange={this.onSeoDescriptionChange}
							/>
						</Col>
					</Row>
				</FormGroup>

				<Row>
					<Col>
						<FormGroup>
							<Label htmlFor='seo_keywords'>{t('seo_keywords')}</Label>
							<KeywordsContainer t={t} keywords={value && value.keywords ? value.keywords : []} onKeywordsChange={this.onKeywordsChange} />
						</FormGroup>
					</Col>
				</Row>

				<Row className='mb-2'>
					<Col>
						<SlugContainer
							t={t}
							onSlugChange={this.onSlugChange}
							onAutoSlugToggle={this.onAutoSlugToggle}
							autoSlug={value.autoSlug}
							seoTitle={value && value.title ? value.title : ''}
							slug={value && value.slug ? value.slug : ''}
						/>
					</Col>
				</Row>

				<Row>
					<Col>
						<FormGroup check inline>
							<Input
								className='form-check-input'
								type='checkbox'
								id='allow-indexing'
								name='allow-indexing'
								checked={value ? value.indexing : true}
								onChange={this.onAllowIndexingChange}
								value='option1'
							/>
							<Label className='form-check-label' check htmlFor='allow-indexing'>
								{t('allow_indexing')}
							</Label>
						</FormGroup>
					</Col>
				</Row>

				<Row>
					<Col>
						<FormGroup check inline>
							<Input
								className='form-check-input'
								type='checkbox'
								id='allow-following'
								name='allow-following'
								checked={value ? value.following : true}
								onChange={this.onAllowFollowingChange}
								value='option1'
							/>
							<Label className='form-check-label' check htmlFor='allow-following'>
								{t('allow_following')}
							</Label>
						</FormGroup>
					</Col>
				</Row>
				{this.displayJsonLdField(contentType) && (
					<Row>
						<Col>
							<FormGroup>
								<Label htmlFor='seo_jsold'>{t('seo_jsonld')}</Label>
								<DebounceInput
									element={'textarea'}
									id='seo_jsonld'
									debounceTimeout={500}
									placeholder={t('seo_jsonld_placeholder')}
									rows='4'
									className='form-control-lg form-control'
									value={value && value.jsonld ? value.jsonld : ''}
									onChange={this.onJsonLdChange}
								/>
							</FormGroup>
						</Col>
					</Row>
				)}
			</div>
		);
	}
}

export default withTranslation()(SeoComponent) as React.ComponentType<RequiredProperties>;
