import React from 'react';
import { Col, Row } from 'reactstrap';
import FootballTeamWidgetModel from '../models/football-team-widget.model';
import OddsModelV2 from '../../odds/models/odds-widget.model';
import { imagePlaceHolder } from '../../../../../../../constants/resource-constants';
import { featuresService } from '../../../../../../../App';
import { FeatureTypes } from '../../../../../../../services/feature-service/features.enum';
import { generateRefreshTimeOptionViewText } from '../../../partials/refresh-time-input/helper';
import SportOddsBookmakerModel from '../../../../../../../models/sport-odds/sport-odds-bookmaker.model';

type Properties = {
	preview: FootballTeamWidgetModel;
	oddsPreview: OddsModelV2;
	t: any;
};

export const FootballTeamView: React.FunctionComponent<Properties> = ({ preview, oddsPreview, t }) => {
	if (preview && preview.team && preview.team.id) {
		return (
			<>
				<Row className='px-4' data-qa='football-team-block-view'>
					<Col className='text-left'>
						<Row className='mb-2'>
							<Col>
								{preview.team && preview.team.id && (
									<div className='single-row'>
										<strong>{t('team')}:</strong> {preview.team.name}
									</div>
								)}
								{preview.tournamentSeason && preview.tournamentSeason.tournamentName && (
									<div className='single-row'>
										<strong>{t('tournament')}:</strong> {preview.tournamentSeason.tournamentName}
									</div>
								)}
								{preview.tournamentSeason && preview.tournamentSeason.seasonName && (
									<div className='single-row'>
										<strong>{t('season')}:</strong> {preview.tournamentSeason.seasonName}
									</div>
								)}
								{preview.match && preview.match.id && (
									<div className='single-row'>
										<strong>{t('match')}:</strong>{' '}
										{preview.match &&
											preview.match.homeTeam &&
											preview.match.homeTeam.name &&
											preview.match.awayTeam &&
											preview.match.awayTeam.name &&
											preview.match.homeTeam &&
											preview.match.homeTeam.name + ' - ' + preview.match.awayTeam.name}
									</div>
								)}
							</Col>
						</Row>
						<Row className='single-row'>
							<Col className='text-left'>
								<strong>{t('display_tabs')}:</strong>
								<strong>
									<span className={preview.displayTabs ? 'text-success' : 'text-danger'}>
										{preview.displayTabs ? ` ${t('yes')}` : ` ${t('no')}`}
									</span>
								</strong>
							</Col>
						</Row>
						{preview.displayTabs && preview.tabs && preview.tabs.length > 0 && (
							<Row className='single-row'>
								<Col className='text-left'>
									<strong>{t('tabs')}: </strong>
									{preview.tabs.map((info, index) => {
										return (
											<span key={`team-profile-tabs-parameters-key-${index}`} className='pl-1'>
												{t(`${info}`)}
												{index + 1 === preview.tabs.length ? '' : ','}
											</span>
										);
									})}
								</Col>
							</Row>
						)}
						{preview.displayTabs && preview.defaultTab && preview.defaultTab.value && (
							<Row className='single-row'>
								<Col className='text-left'>
									<strong>{t('default_tabs')}:</strong>
									<span>{preview.displayTabs && preview.defaultTab && ` ${preview.defaultTab.label}`}</span>
								</Col>
							</Row>
						)}
						<hr />
						<Row className='single-row'>
							<Col className='text-left'>
								<Row>
									<Col className='text-left'>
										{t('display_odds')}:
										<strong>
											<span className={preview.displayOdds ? 'text-success' : 'text-danger'}>
												{preview.displayOdds ? ` ${t('yes')}` : ` ${t('no')}`}
											</span>
										</strong>
									</Col>
								</Row>
								{preview.displayOdds && oddsPreview.bookmakers && oddsPreview.bookmakers.length > 0 && (
									<Row className='mt-2'>
										<Col className='text-left'>
											{t('show')}:
											{oddsPreview.bookmakers.map((bookmaker: SportOddsBookmakerModel) => {
												return (
													<img
														key={bookmaker.id}
														height='20'
														alt={bookmaker.name}
														className='h-20 mx-1'
														src={bookmaker.logo ? bookmaker.logo : imagePlaceHolder}
														title={bookmaker.name}
													/>
												);
											})}
										</Col>
									</Row>
								)}
								{preview.displayOdds && oddsPreview.market && oddsPreview.market.name && oddsPreview.market.name.length > 0 && (
									<Row className='mt-2'>
										<Col className='text-left'>
											<span>{t('default_market')}: </span>
											<strong>{t(oddsPreview.market.name)}</strong>
										</Col>
									</Row>
								)}
								<Row className='mt-2 mb-2'>
									{preview.infoParameters && preview.infoParameters.length > 0 && (
										<Col>
											<strong>{t('selected_info')}:</strong>
											{preview.infoParameters.map((info, index) => {
												return (
													<span key={`team-profile-info-parameters-key-${index}`} className='pl-1'>
														{t(`${info}_title`)}
														{index + 1 === preview.infoParameters.length ? '' : ','}
													</span>
												);
											})}
										</Col>
									)}
								</Row>
								<Row className='mt-2 mb-2'>
									{preview.statsParameters && preview.statsParameters.length > 0 && (
										<Col>
											<strong>{t('selected_stats')}:</strong>
											{preview.statsParameters.map((info, index) => {
												return (
													<span key={`team-profile-stats-parameters-key-${index}`} className='pl-1'>
														{t(`${info}_title`)}
														{index + 1 === preview.statsParameters.length ? '' : ','}
													</span>
												);
											})}
										</Col>
									)}
								</Row>
								{featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.FANS_UNITED) && (
									<Row>
										<Col className='text-left'>
											{t('fans_united_enabled')}:
											<strong>
												<span className={preview.fansUnitedEnabled ? 'text-success' : 'text-danger'}>
													{preview.fansUnitedEnabled ? ` ${t('yes')}` : ` ${t('no')}`}
												</span>
											</strong>
										</Col>
									</Row>
								)}
								{featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.FANS_UNITED) && (
									<Row>
										<Col className='text-left'>
											{t('fans_united_expanded')}:
											<strong>
												<span className={preview.fansUnitedEnabled && preview.fansUnitedExpanded ? 'text-success' : 'text-danger'}>
													{preview.fansUnitedEnabled && preview.fansUnitedExpanded ? ` ${t('yes')}` : ` ${t('no')}`}
												</span>
											</strong>
										</Col>
									</Row>
								)}
							</Col>
						</Row>
					</Col>
				</Row>
				{generateRefreshTimeOptionViewText(preview.refreshTime)}
			</>
		);
	}

	return null;
};
