import React from 'react';
import { Col, Row } from 'reactstrap';
import { Properties } from './properties/top-scorers-widget-view.properties';

export const TopScorersWidgetView: React.FunctionComponent<Properties> = ({ blockPreview, t }) => {
	if (blockPreview && blockPreview.tournament && blockPreview.tournament.id) {
		return (
			<>
				<Row className='my-2 mx-4 text-left'>
					<Col>
						<div>
							<strong>{t('tournament')}:</strong>
							<img className='ml-1 mr-1' width='35px' src={blockPreview.tournament.logo} alt={blockPreview.tournament.name} />
							{blockPreview.tournament.name}, {blockPreview.tournament.season.name}
						</div>
					</Col>
				</Row>
				{blockPreview.team && blockPreview.team.id ? (
					<Row className='my-2 mx-4 text-left'>
						<Col>
							<div>
								<strong>{t('team')}: </strong>
								{blockPreview.threeLetterCodeType && blockPreview.threeLetterCodeType !== '' && blockPreview.team.threeLetterCode !== null
									? blockPreview.team.threeLetterCode
									: blockPreview.team.name}
							</div>
						</Col>
					</Row>
				) : (
					''
				)}
				<Row className='my-2 mx-4 text-left'>
					<Col>
						<strong>{t('highlighted_players')}:</strong>
						{blockPreview.topScorers && blockPreview.topScorers.length > 0 ? (
							blockPreview.topScorers.map((player, index) => {
								return (
									<span key={`player-top-scorer-key-${index}`}>
										<img className='img-circle ml-2' width='20px' src={player.logo} alt={player.name} /> {player.name}
										{index + 1 === blockPreview.topScorers.length ? '' : ','}
									</span>
								);
							})
						) : (
							<span> {t('no_players_selected')}</span>
						)}
					</Col>
				</Row>
				{blockPreview.threeLetterCodeType && blockPreview.threeLetterCodeType !== '' && (
					<Row className='my-2 mx-4 text-left'>
						<Col className='text-left'>
							<strong>{t('display_team_three_letter_code')}:</strong>
							<span className='ml-2'>
								{blockPreview.threeLetterCodeType && blockPreview.threeLetterCodeType.name && t(`${blockPreview.threeLetterCodeType.name}`)}
							</span>
						</Col>
					</Row>
				)}
			</>
		);
	}

	return null;
};
