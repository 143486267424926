import { TypedJSON } from 'typedjson';
import { assetsPlaceholder } from '../../../../../../../constants/assetsPlaceholder';
import TeamModel from '../../../../../../../models/v2/Team/Entity/TeamModel';
import LiveBlogTeamModel from '../../../../../../Pages/Live Blog/models/teams/live-blog-team.model';

export const responseToTeamModel = (response: any) => {
	const serializer = new TypedJSON(TeamModel);
	const remmapedTeam = serializer.parse(response) as TeamModel;

	return remmapedTeam;
};

export const teamsToOptions = (teams: TeamModel[]) => {
	if (teams && teams.length > 0) {
		return teams.map((team) => {
			return teamToOption(team);
		});
	}

	return [];
};

export const teamToOption = (team: TeamModel | any) => {
	if (team && team.id) {
		return {
			value: team.id,
			label: team.name,
			data: team,
			logo: team.urlLogo ? team.urlLogo : team.url_logo ? team.url_logo : assetsPlaceholder.team,
			type: 'team',
		};
	} else {
		return {};
	}
};

export const responseToOption = (team: any) => {
	if (team && team.id) {
		return {
			value: team.id,
			label: team.name,
			logo: team.url_logo ? team.url_logo : assetsPlaceholder.team,
			type: 'team',
			data: team,
		};
	}

	return {};
};

export const remapTeamsToOptions = (teams: TeamModel[]) => teams.map((team: any) => teamToOption(team));

export const checkIfSeasonIdIsPresent = (seasonId?: number) => {
	if (seasonId) {
		return true;
	}
};

export const extractTeamsFromEvent = (existingTeams: LiveBlogTeamModel[], event: any) => {
	let teams: LiveBlogTeamModel[] = [];
	if (event && event.id) {
		teams = [...existingTeams, event.home_team, event.away_team];
		teams = teams.filter((v, i, a) => a && a.findIndex((v2) => ['id'] && ['id'].every((k) => v2 && v && v2[k] === v[k])) === i);
		return teams;
	}

	return teams;
};

export const filterTeamsArray = (teams: LiveBlogTeamModel[]) => {
	let map = new Map();

	return teams.filter((el) => {
		const val = map.get(el.name);
		if (val) {
			if (el.id < val) {
				map.delete(el.name);
				map.set(el.name, el.id);
				return true;
			} else {
				return false;
			}
		}
		map.set(el.name, el.id);
		return true;
	});
};
