import 'reflect-metadata';
import { TypedJSON } from 'typedjson';
import FansUnitedClassicQuizModel from './fans-united-classic-quiz.model';

export default class FansUnitedClassicQuizMapper {
	public responseToModel = (response: Response): FansUnitedClassicQuizModel => {
		const serializer = new TypedJSON(FansUnitedClassicQuizModel);
		const remmapedQuiz = serializer.parse(response) as FansUnitedClassicQuizModel;
		remmapedQuiz['isSelected'] = false;

		return remmapedQuiz;
	};
	public modelToRequestBody = (model: FansUnitedClassicQuizModel) => {
		if (model) {
			return {
				id: model.id,
				title: model.title,
				description: model.description,
				type: model.type,
				status: model.status,
				images: model.images,
				labels: model.labels,
				time: model.time,
				participation_count: model.participation_count,
				questions_count: model.questions_count,
				average_score: model.average_score,
				perfect_score: model.perfect_score,
				created_at: model.created_at,
				updated_at: model.updated_at,
			};
		}
		return new FansUnitedClassicQuizModel();
	};
}
