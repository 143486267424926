import BlockModel from '../models/block.model';
import { mapWidgetConfig } from '../subcomponents/helpers/block-config.helper';
import Global from '../helpers/global.helpers';
import { ContentTypes, FansUnitedWidgetsTypes, WidgetTypes, WidgetTypesV2 } from '../constants/block.types';
import { mapFansUnitedWidgetConfig } from '../subcomponents/helpers/fans-united-block.helper';
import { mapWidgetV2Config } from '../v2/widgets-v2/helpers/block-v2-config.helper';

export default class BlockConfigService {
	public configWidget(block: BlockModel) {
		const blockInstance = BlockModel.builder(block).build();
		return new Promise((resolve, reject) => {
			try {
				switch (block.type) {
					case WidgetTypes.widget: {
						block.data['changeId'] = Global.makeId(7);
						resolve(mapWidgetConfig[block.data.widget_type](blockInstance));
						break;
					}
					case WidgetTypesV2.widget: {
						block.data['changeId'] = Global.makeId(7);
						resolve(mapWidgetV2Config[block.data.widget_type](blockInstance));
						break;
					}
					case FansUnitedWidgetsTypes.widget: {
						block.data['changeId'] = Global.makeId(7);
						if (
							block.data.widget_type !== FansUnitedWidgetsTypes.topX &&
							block.data.widget_type !== FansUnitedWidgetsTypes.matchQuiz &&
							block.data.widget_type !== FansUnitedWidgetsTypes.leaderboard &&
							block.data.widget_type !== FansUnitedWidgetsTypes.classicQuiz &&
							block.data.widget_type !== FansUnitedWidgetsTypes.eitherOr
						) {
							resolve(mapFansUnitedWidgetConfig[block.data.widget_type](blockInstance));
						}
						break;
					}
					case ContentTypes[block.type]: {
						block.data['changeId'] = Global.makeId(7);
						resolve(block);
						break;
					}
					default: {
						resolve(block);
						break;
					}
				}
			} catch (e) {
				console.log(e);
				reject(e);
			}
		});
	}
}
