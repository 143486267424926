import moment from 'moment';
import * as React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { Col, FormGroup, Input, Label, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import GameModel from '../../../../../models/v2/game/game.model';
import {
	extractFirstTeamsFromGameName,
	extractSecondTeamsFromGameName,
} from '../../../Shared/advanced-content-filter/subcomponents/v2/sports-select/sports-connections.helper';

export type Properties = {
	selectedTeamNames: any;
	isMulti: boolean;
	onGameSelect: any;
	onGamesSelect: any;
	gamesFinished: any[];
	gamesNotStarted: any[];
	gamesInterrupted: any[];
	updateCheckAllGamesState: any;
	finishedGamesAreSelected: any;
	upcomingGamesAreSelected?: any;
	interruptedGamesAreSelected?: any;
	t: any;
};

export type State = {
	games: any[];
	activeTab: any[];
};

enum SportStatuses {
	FINISHED = 'finished',
	LIVE = 'live',
	UPCOMING = 'upcoming',
	INTERRUPTED = 'interrupted',
}

export default class Games extends React.Component<Properties, State> {
	constructor(props: any) {
		super(props);
		this.state = {
			activeTab: new Array(4).fill('1'),
			games: [...props.gamesFinished, ...props.gamesNotStarted, ...this.props.gamesInterrupted].filter((game) => game.isSelected),
		};
	}

	toggle = (tabPane: number, tab: string) => {
		const newArray = this.state.activeTab.slice();
		newArray[tabPane] = tab;
		this.setState({
			activeTab: newArray,
		});
	};

	onGameSelect = (game: GameModel) => {
		this.props.onGameSelect(game);
	};

	onGamesSelect = (game: GameModel, event: any) => {
		let { games } = this.state;
		const { onGamesSelect } = this.props;

		games = this.handleGameCheck(game, games, event.target.checked);
		this.updateGamesState(games);
		this.updateCheckAllGamesState(false, games);

		if (onGamesSelect) {
			onGamesSelect(games);
		}
	};

	selectAllGames = (selectedGames: any, event: any) => {
		const { onGamesSelect } = this.props;

		selectedGames = this.handleGamesCheck(selectedGames, event.target.checked);

		if (onGamesSelect) {
			onGamesSelect(selectedGames);
		}
	};

	handleGameCheck(game: GameModel, games: GameModel[], checked: boolean) {
		if (checked) {
			games.push(game);
		} else {
			games = games.filter((item) => item.id !== game.id);
		}

		return games;
	}

	handleGamesCheck = (games: GameModel[], checked: boolean) => {
		this.updateCheckAllGamesState(checked, games);
		let selectedGames = [];

		if (!checked) {
			let gameIds = games.concat(games).map((game: GameModel) => game.id);
			const onlyUnique = (value: any, index: any, self: any) => self.indexOf(value) === index;
			const uniqueIds = gameIds.filter(onlyUnique);
			selectedGames = this.state.games.filter((game: GameModel) => !uniqueIds.includes(game.id));
		} else {
			selectedGames = this.state.games.concat(games);
		}

		return selectedGames;
	};

	updateGamesState = (games: GameModel[]) => this.setState({ ...this.state, games });

	updateCheckAllGamesState = (checked: boolean, selectedGames: GameModel[]) => {
		this.state.activeTab[0] === '1'
			? this.props.updateCheckAllGamesState(checked, SportStatuses.UPCOMING, selectedGames, this.state.activeTab[0])
			: this.props.updateCheckAllGamesState(checked, SportStatuses.FINISHED, selectedGames, this.state.activeTab[0]);
	};

	applyBoldCss(teamName: string) {
		return this.props.selectedTeamNames.includes(teamName) ? 'font-weight-bold' : '';
	}

	applyInProgressClass(type: string) {
		return type === SportStatuses.LIVE ? 'border-pulsate-right' : '';
	}

	// printMinutesIfGameInProgress(game: GameModel) {
	// 	return game.statusType === SportStatuses.LIVE ? `(${game.minutes}")` : '';
	// }

	toggleGoals(game: GameModel) {
		return game.statusType === SportStatuses.LIVE ? ` ${game.results[0].value}:${game.results[0].value} ` : ` - `;
	}

	renderGamesFinished(games: GameModel[], isMulti: boolean) {
		const { t } = this.props;

		if (games.length < 1) {
			return <div className='text-center p-3'>{t('no_games_found')}</div>;
		}

		return (
			<>
				{isMulti && (
					<FormGroup className='mb-2 pb-2 border-bottom' check>
						<Input
							className='form-check-input'
							onChange={(event) => this.selectAllGames(games, event)}
							type='checkbox'
							checked={games[0].isSelected && this.props.finishedGamesAreSelected}
							id={`select-all-finished-games${games.length}`}
							name={t('select_all_games')}
						/>
						<Label className='form-check-label' check htmlFor={`select-all-finished-games${games.length}`}>
							{t('select_all_games')}
						</Label>
					</FormGroup>
				)}
				{games.map((game) => {
					return (
						<FormGroup key={`key-${game.id}`} className='mb-2 pb-2 border-bottom' check>
							<Input
								className='form-check-input'
								onChange={(event) => {
									if (isMulti) {
										this.onGamesSelect(game, event);
									} else {
										this.onGameSelect(game);
									}
								}}
								// @ts-ignore
								type={`${isMulti ? 'checkbox' : 'radio'}`}
								id={`${game.id}`}
								name={`inline-radio-${game.id}`}
								checked={game.isSelected}
							/>
							<Label className='form-check-label' check htmlFor={`${game.id}`}>
								{`${moment(game.startTime).format('DD MMM YYYY')} - `}
								<span className={this.applyBoldCss(extractFirstTeamsFromGameName(game.name))}>{extractFirstTeamsFromGameName(game.name)}</span>
								{` ${game.results[0].value}:${game.results[1].value} `}
								<span className={this.applyBoldCss(extractSecondTeamsFromGameName(game.name))}>{extractSecondTeamsFromGameName(game.name)}</span>
							</Label>
						</FormGroup>
					);
				})}
			</>
		);
	}

	renderGamesNotStarted(games: GameModel[], isMulti: boolean) {
		const { t } = this.props;

		if (games.length < 1) {
			return <div className='text-center p-3'>{t('no_games_found')}</div>;
		}
		return (
			<>
				{isMulti && (
					<FormGroup className='mb-2 pb-2 border-bottom' check>
						<Input
							className='form-check-input'
							onChange={(event) => this.selectAllGames(games, event)}
							type='checkbox'
							checked={games[0].isSelected && this.props.upcomingGamesAreSelected}
							id={`select-all-upcoming-games${games.length}`}
							name={t('select_all_games')}
						/>
						<Label className='form-check-label' check htmlFor={`select-all-upcoming-games${games.length}`}>
							{t('select_all_games')}
						</Label>
					</FormGroup>
				)}
				{games.map((game) => {
					return (
						<FormGroup key={`key-${game.id}`} className={`mb-2 pb-2 border-bottom ${this.applyInProgressClass(game.statusType)}`} check>
							<Input
								className='form-check-input'
								onChange={(event: any) => {
									if (isMulti) {
										this.onGamesSelect(game, event);
									} else {
										this.onGameSelect(game);
									}
								}}
								// @ts-ignore
								type={`${isMulti ? 'checkbox' : 'radio'}`}
								id={`${game.id}`}
								checked={game.isSelected}
								name={`inline-radio-${game.id}`}
								value={game.id}
							/>

							<Label className='form-check-label' check htmlFor={`${game.id}`}>
								{`${moment(game.startTime).format('DD MMM YYYY')} - `}
								<span className={this.applyBoldCss(extractFirstTeamsFromGameName(game.name))}>{extractFirstTeamsFromGameName(game.name)}</span>
								{this.toggleGoals(game)}
								<span className={this.applyBoldCss(extractSecondTeamsFromGameName(game.name))}>{extractSecondTeamsFromGameName(game.name)}</span>
							</Label>
						</FormGroup>
					);
				})}
			</>
		);
	}

	renderGamesInterrupted(games: GameModel[], isMulti: boolean) {
		const { t } = this.props;

		if (games.length < 1) {
			return <div className='text-center p-3'>{t('no_games_found')}</div>;
		}

		return (
			<>
				{isMulti && (
					<FormGroup className='mb-2 pb-2 border-bottom' check>
						<Input
							className='form-check-input'
							onChange={(event) => this.selectAllGames(games, event)}
							type='checkbox'
							checked={games[0].isSelected && this.props.interruptedGamesAreSelected}
							id={`select-all-interrupted-games${games.length}`}
							name={t('select_all_games')}
						/>
						<Label className='form-check-label' check htmlFor={`select-all-interrupted-games${games.length}`}>
							{t('select_all_games')}
						</Label>
					</FormGroup>
				)}
				{games.map((game) => {
					return (
						<FormGroup key={`key-${game.id}`} className={`mb-2 pb-2 border-bottom ${this.applyInProgressClass(game.statusType)}`} check>
							<Input
								className='form-check-input'
								onChange={(event: any) => {
									if (isMulti) {
										this.onGamesSelect(game, event);
									} else {
										this.onGameSelect(game);
									}
								}}
								// @ts-ignore
								type={`${isMulti ? 'checkbox' : 'radio'}`}
								id={`${game.id}`}
								checked={game.isSelected}
								name={`inline-radio-${game.id}`}
								value={game.id}
							/>
							<Label className='form-check-label' check htmlFor={`${game.id}`}>
								{`${moment(game.startTime).format('DD MMM YYYY')} - `}
								<span className={this.applyBoldCss(extractFirstTeamsFromGameName(game.name))}>{extractFirstTeamsFromGameName(game.name)}</span>
								{this.toggleGoals(game)}
								<span className={this.applyBoldCss(extractSecondTeamsFromGameName(game.name))}>{extractSecondTeamsFromGameName(game.name)}</span>
							</Label>
						</FormGroup>
					);
				})}
			</>
		);
	}

	render() {
		const { gamesFinished, gamesNotStarted, gamesInterrupted, isMulti, t } = this.props;

		return (
			<div id='game-tagging'>
				<Row>
					<Col>
						<Nav tabs className={''}>
							<NavItem>
								<NavLink
									active={this.state.activeTab[0] === '1'}
									onClick={() => {
										this.toggle(0, '1');
									}}
								>
									<i className='fa fa-calendar'></i> {t('upcoming_games')}
								</NavLink>
							</NavItem>
							<NavItem>
								<NavLink active={this.state.activeTab[0] === '2'} onClick={() => this.toggle(0, '2')}>
									<i className='fa fa-calendar-times-o'></i> {t('past_games')}
								</NavLink>
							</NavItem>
							<NavItem>
								<NavLink active={this.state.activeTab[0] === '3'} onClick={() => this.toggle(0, '3')}>
									<i className='fa fa-calendar-minus-o'></i> {t('interrupted_games')}
								</NavLink>
							</NavItem>
						</Nav>
						<TabContent activeTab={this.state.activeTab[0]}>
							<TabPane tabId='1' className='p-2 pb-0 bg-white'>
								<FormGroup className='mt-1'>
									<Scrollbars style={{ height: 300 }}>{this.renderGamesNotStarted(gamesNotStarted, isMulti)}</Scrollbars>
								</FormGroup>
							</TabPane>
							<TabPane tabId='2' className='p-2 pb-0 bg-white'>
								<FormGroup className='mt-1'>
									<Scrollbars style={{ height: 300 }}>{this.renderGamesFinished(gamesFinished, isMulti)}</Scrollbars>
								</FormGroup>
							</TabPane>
							<TabPane tabId='3' className='p-2 pb-0 bg-white'>
								<FormGroup className='mt-1'>
									<Scrollbars style={{ height: 300 }}>{this.renderGamesInterrupted(gamesInterrupted, isMulti)}</Scrollbars>
								</FormGroup>
							</TabPane>
						</TabContent>
					</Col>
				</Row>
			</div>
		);
	}
}
