export const marketOptions = (t: any) => {
	return [
		{ value: '1x2', label: t('1x2') },
		{ value: 'overUnder', label: t('overUnder') },
		{ value: 'both_score', label: t('both_score') },
		{ value: 'double_chance', label: t('double_chance') },
	];
};

export const marketOptionsV2 = (t: any) => {
	return [
		{ value: '1x2', label: t('1x2') },
		{ value: '12', label: t('12') },
		{ value: 'OVER_UNDER', label: t('OVER_UNDER') },
		{ value: 'BOTH_TO_SCORE', label: t('BOTH_TO_SCORE') },
		{ value: 'DOUBLE_CHANCE', label: t('DOUBLE_CHANCE') },
		{ value: 'DRAW_NO_BET', label: t('DRAW_NO_BET') },
		{ value: 'GAME_HANDICAP', label: t('GAME_HANDICAP') },
		//TODO uncomment when this market is supported
		// { value: 'FIRST_TEAM_TO_SCORE', label: t('FIRST_TEAM_TO_SCORE') },
	];
};

export const marketValueTypesOptions = (t: any) => {
	return [
		{ value: 'DECIMAL', label: t('DECIMAL') },
		{ value: 'FRACTIONAL', label: t('FRACTIONAL') },
		{ value: 'MONEYLINE', label: t('MONEYLINE') },
	];
};
