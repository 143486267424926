import ContentAttributes from '../../models/content-attributes/content-attributes-model';
import { USER_LOGOUT } from '../action-creators/ProfileActionCreators';
import {
	REQUEST_ARTICLE_PROPERTIES_RECEIVED,
	REQUEST_BANNER_PROPERTIES_RECEIVED,
	REQUEST_GALLERY_PROPERTIES_RECEIVED,
	REQUEST_VIDEO_PROPERTIES_RECEIVED,
	REQUEST_WIKI_PROPERTIES_RECEIVED,
} from '../action-creators/properties-action-creator';
import { propertiesCompatibilityData } from '../properties-compatibility.data';

interface InitialState {
	articleProperties: ContentAttributes[];
	videoProperties: ContentAttributes[];
	galleryProperties: ContentAttributes[];
	wikipageProperties: ContentAttributes[];
	bannerProperties: ContentAttributes[];
}

const initialState: InitialState = propertiesCompatibilityData;

function propertiesReducer(state: any = initialState, action: any) {
	if (action.type === REQUEST_ARTICLE_PROPERTIES_RECEIVED) {
		return Object.assign({}, state, {
			articleProperties: action.payload.slice(),
		});
	}

	if (action.type === REQUEST_VIDEO_PROPERTIES_RECEIVED) {
		return Object.assign({}, state, {
			videoProperties: action.payload.slice(),
		});
	}

	if (action.type === REQUEST_GALLERY_PROPERTIES_RECEIVED) {
		return Object.assign({}, state, {
			galleryProperties: action.payload.slice(),
		});
	}

	if (action.type === REQUEST_WIKI_PROPERTIES_RECEIVED) {
		return Object.assign({}, state, {
			wikipageProperties: action.payload.slice(),
		});
	}

	if (action.type === REQUEST_BANNER_PROPERTIES_RECEIVED) {
		return Object.assign({}, state, {
			bannerProperties: action.payload.slice(),
		});
	}

	if (action.type === USER_LOGOUT) {
		state = initialState;
	}

	return state;
}

export default propertiesReducer;
