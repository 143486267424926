import ContentAttribute from '../../../../../models/v2/content-attributes/content-attributes.model';

export const extractPropertyNameFromRedux = (contentProperties: ContentAttribute[], searchKey: string) => {
	try {
		const el = contentProperties.find((el) => el.slug === searchKey);
		return el ? el.name : '';
	} catch (error) {
		return '';
	}
};
