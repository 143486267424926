import React from 'react';
import Select from 'react-select';
import { Label } from 'reactstrap';
import { customOption } from '../shared/custom-select-option';
import HttpService from '../../../../../services/rest/HttpService';
import { topScorersToOptions, remapPlayerFromResponse } from './helpers/top-scorers-select.helper';
import { Properties, State } from './properties/top-scorer-select.properties';
import PlayerModel from '../player-select/models/player.model';

export default class TopScorerSelectContainer extends React.Component<Properties, State> {
	constructor(props: Properties) {
		super(props);
		this.state = {
			topScorersOptions: [],
			selectedTopScorers: [],
		};
	}

	componentDidMount() {
		const { teamId, seasonId, isFilterApplied } = this.props;

		if (teamId && isFilterApplied) {
			this.getTopScorersByTeam(teamId);
		} else {
			if (seasonId) {
				this.getTopScorersBySeason(seasonId);
			}
		}
	}

	componentDidUpdate(prevProps: Properties) {
		if (prevProps.seasonId !== this.props.seasonId && this.props.seasonId) {
			this.getTopScorersBySeason(this.props.seasonId);
		}

		if (prevProps.teamId !== this.props.teamId && this.props.seasonId) {
			this.props.teamId && this.props.isFilterApplied
				? this.getTopScorersByTeam(this.props.teamId)
				: this.getTopScorersBySeason(this.props.seasonId);
		}
	}

	updateTopScorersOptionsState = (topScorers: PlayerModel[]) => this.setState({ ...this.state, topScorersOptions: topScorers });

	updateSelectedTopScorersState = (selectedTopScorers: PlayerModel[]) => this.setState({ ...this.state, selectedTopScorers });

	getTopScorersByTeam = (teamId: string) => {
		HttpService.getTeamPlayers(teamId)
			.then((response: any) => {
				this.updateTopScorersOptionsState(remapPlayerFromResponse(response.data));
			})
			.catch((e: any) => e);
	};

	getTopScorersBySeason = (seasonId: string) => {
		HttpService.getTopScorersBySeasonId(seasonId)
			.then((response: any) => {
				this.updateTopScorersOptionsState(remapPlayerFromResponse(response.data));
			})
			.catch((e: any) => e);
	};

	render() {
		const { topScorersOptions } = this.state;
		const { onTopScorersSelect, seasonId, selectedTopScorers, t } = this.props;

		if (!seasonId) {
			return null;
		}

		return (
			<>
				<Label>{t('pick_players_highlight')}</Label>
				<Select
					options={topScorersToOptions(topScorersOptions)}
					value={topScorersToOptions(selectedTopScorers)}
					isMulti
					placeholder={t('select')}
					formatOptionLabel={customOption}
					noOptionsMessage={(inputValue) => inputValue && t('no_options')}
					onChange={(selections: any) => onTopScorersSelect(selections)}
				/>
			</>
		);
	}
}
