import React, { useEffect } from 'react';
import * as ReactSortableHOC from 'react-sortable-hoc';
import { featuresService, multiLingualService } from '../../../../../App';
import { FeatureTypes } from '../../../../../services/feature-service/features.enum';
import ContentAttributes from '../../../../../models/content-attributes/content-attributes-model';
import SidebarSortableElement from './sidebar-sortable-element';
import { ContentMode } from '../../../../../constants/content-types';
import Project from '../../../../../models/project/Project';
import { FormattedSidebarSectionValue, SidebarSettingsEntity } from '../utils/temp-sidebar.types';

type Properties = {
	sidebarSettings: SidebarSettingsEntity;
	sidebarComponentsMap: any;
	contentType: string;
	t: any;
	types?: ContentAttributes[];
	statuses?: ContentAttributes[];
	commentsPolicies?: ContentAttributes[];
	onChangeCollapseStatus: (elem: any) => void;
	isSidebarInEdit: boolean;
	contentMode?: string;
	project?: Project;
};

const SortableElement = ReactSortableHOC.SortableElement(SidebarSortableElement);

const SidebarSortableElementsList: React.FunctionComponent<Properties> = (props) => {
	const {
		sidebarSettings,
		sidebarComponentsMap,
		contentType,
		t,
		types,
		statuses,
		commentsPolicies,
		isSidebarInEdit,
		onChangeCollapseStatus,
		contentMode,
		project,
	} = props;
	const isCustomDataEnabled = featuresService.areFeaturesEnabled([FeatureTypes.GENERIC_FIELD]);
	const isContentFooterEnabled = featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.CONTENT_FOOTER);

	return (
		<div>
			{sidebarSettings &&
				sidebarSettings.value &&
				sidebarSettings.value.length > 0 &&
				sidebarSettings.value
					.filter((element: FormattedSidebarSectionValue) => {
						if (element.key === 'content_footer') {
							return isContentFooterEnabled;
						}
						if (element.key === 'custom_data') {
							return isCustomDataEnabled;
						}
						if (
							element.key === 'translations' &&
							((contentMode && contentMode === ContentMode.CREATE) ||
								(project && !multiLingualService.checkIfProjectIsMultiLingual(project.languages)))
						) {
							return false;
						}
						return true;
					})
					.map((element: FormattedSidebarSectionValue, index: number) => {
						return element.key === 'general' &&
							types &&
							types.length === 0 &&
							statuses &&
							statuses.length === 0 &&
							commentsPolicies &&
							commentsPolicies.length === 0 ? null : (
							<SortableElement
								isSidebarInEdit={isSidebarInEdit}
								t={t}
								sequence={index}
								index={index}
								onChangeCollapseStatus={onChangeCollapseStatus}
								key={`key-name:${contentType}-key-id:${index}`}
								elementKey={element.key}
								sidebarSettings={sidebarSettings.value}
								contentType={contentType}
								status={element.state.opened ? 'open' : 'closed'}
							>
								{sidebarComponentsMap[element.key]}
							</SortableElement>
						);
					})}
		</div>
	);
};

export default SidebarSortableElementsList;
