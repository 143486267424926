import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import REACT_APP_URLS, { Environments } from '../../../../../../global-helpers/global-url.helpers';

const LiveBlogEditorialAdminErrorFallback: FC = () => {
	const [t] = useTranslation();
	const [countdown, setCountdown] = useState(REACT_APP_URLS.REACT_APP_ENV === Environments.INTEGRATION ? 30 : 5);

	useEffect(() => {
		const countdownInterval = setInterval(() => {
			setCountdown((prev) => prev - 1);
		}, 1000);

		return () => {
			clearInterval(countdownInterval);
		};
	}, []);

	useEffect(() => {
		if (countdown === 1) {
			window.location.reload();
		}
	}, [countdown]);

	return <div className='live-blog-editorial-admin-error-fallback'>{t('fatal_error', { countdown })}</div>;
};

export default LiveBlogEditorialAdminErrorFallback;
