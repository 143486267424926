import { RefereeJson } from './referee.json';
import RefereeModel from './referee.model';

export default class RefereeBuilder {
	readonly json: RefereeJson;

	constructor(referee?: RefereeModel | RefereeJson) {
		if (referee && referee instanceof RefereeModel) {
			this.json = referee.toJson();
		} else if (referee) {
			this.json = referee;
		} else {
			this.json = {} as RefereeJson;
		}
	}

	withId(id: string): RefereeBuilder {
		this.json.id = id;

		return this;
	}

	withName(name: string): RefereeBuilder {
		this.json.name = name;

		return this;
	}

	build(): RefereeModel {
		return RefereeModel.fromJson(this.json);
	}
}
