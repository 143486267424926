import React from 'react';
import { Properties } from './properties/image-cells.properties';
import Image from '../../../../../models/image/Image';
import './styles/image-cells.scss';
import './styles/image-blocky-masonry.scss';
import Masonry from 'react-masonry-css';

const ImageCells: React.FunctionComponent<Properties> = ({ onSelect, images, selectedImageId }) => {
	return (
		<Masonry
			breakpointCols={{
				default: 4,
				1380: 4,
				1300: 3,
				1120: 2,
				989: 3,
				600: 2,
			}}
			className='image-blocky-masonry-grid'
			columnClassName='image-blocky-grid_column'
		>
			{images.map((image: Image) => {
				if (image && image.urls) {
					return (
						<img
							alt={`${image.description}`}
							key={`image-cell-key-${image.id}`}
							src={image.urls.uploaded.embed}
							onClick={() => onSelect(image)}
							className={`img-fluid w-100 cursor-pointer mb-1 mr-1 ${image.id === selectedImageId ? 'selected-main-image' : ''}`}
						/>
					);
				} else return null;
			})}
		</Masonry>
	);
};

export default ImageCells;
