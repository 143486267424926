import React, { ChangeEvent, FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { extractContentModeBasedOnUrl, extractMainContentTypeBasedOnUrl, isObjectEmpty } from '../../../../global-helpers/global.helpers';
import {
	autoGenerateReduxPropsNamingForContentModels,
	generateReduxPropsUpdateActionByContentType,
} from '../../../../services/content-models-service/ContentModelService';
import { FormGroup } from 'reactstrap';
import { DebounceInput } from 'react-debounce-input';

type Properties = {
	t: any;
	genericRedux: Record<string, any> | null;
	updateTempProperty: (propertyObject: Record<string, any>) => void;
};

const SidebarCustomData: FunctionComponent<Properties> = ({ t, genericRedux, updateTempProperty }) => {
	const onCustomDataChange = (event: ChangeEvent<HTMLInputElement>) => {
		const inputText = event.target.value;
		try {
			if (!inputText) {
				updateTempProperty({ generic: null });
			} else {
				const textToJson = JSON.parse(inputText);
				!isObjectEmpty(textToJson) && updateTempProperty({ generic: { ...textToJson } });
			}
		} catch (error) {}
	};

	return (
		<FormGroup>
			<DebounceInput
				element='textarea'
				className='form-control mb-2'
				id='sidebar-custom-data-input'
				placeholder={t('sidebar_generic_placeholder')}
				rows='7'
				debounceTimeout={500}
				value={genericRedux ? JSON.stringify(genericRedux) : ''}
				onChange={onCustomDataChange}
			/>
		</FormGroup>
	);
};

function mapStateToProps(state: any) {
	const { tempPropertyNaming, nestedTempPropertyNaming } = autoGenerateReduxPropsNamingForContentModels();

	return {
		genericRedux: state[tempPropertyNaming][nestedTempPropertyNaming].generic || null,
	};
}

function mapDispatchToProps(dispatch: Function) {
	const contentTypeFromUrl = extractMainContentTypeBasedOnUrl();
	const functionForDispatch = generateReduxPropsUpdateActionByContentType(contentTypeFromUrl);

	return {
		updateTempProperty: (propertyObject: Record<string, any>) =>
			functionForDispatch && dispatch(functionForDispatch(propertyObject, extractContentModeBasedOnUrl())),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(SidebarCustomData);
