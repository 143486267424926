import BlacklistModel from './blacklist';
import { BlacklistJson } from './blacklist.json';

export default class BlacklistBuilder {
	private json: BlacklistJson;

	constructor(status?: BlacklistModel | BlacklistJson) {
		if (status && status instanceof BlacklistModel) {
			this.json = status.toJson();
		} else if (status) {
			this.json = status;
		} else {
			this.json = {} as BlacklistJson;
		}
	}

	withId(id: string): BlacklistBuilder {
		this.json.id = id;

		return this;
	}

	withEntityId(entityId: string): BlacklistBuilder {
		this.json.entity_id = entityId;

		return this;
	}

	withEntity(entity: string): BlacklistBuilder {
		this.json.entity = entity;

		return this;
	}

	withType(type: string): BlacklistBuilder {
		this.json.type = type;

		return this;
	}

	build(): BlacklistModel {
		return BlacklistModel.fromJson(this.json);
	}
}
