export enum DATA_QA_ATTRIBUTES {
	MAIN_MEDIA_WRAPPER = 'sidebar-main-media-content-wrapper',
	TOGGLE_IMAGES = 'sidebar-main-media-content-toggle-images-list',
	TOGGLE_IMAGES_BUTTON = 'sidebar-main-media-content-toggle-images-list-button',
	IMAGES_CONTENT_SEARCH = 'sidebar-main-media-content-search-input',
	IMAGES_CONTENT_SEARCH_BUTTON = 'sidebar-main-media-content-search-button',
	IMAGES_ADVANCED_CONTENT_FILTERS = 'sidebar-main-media-content-advanced-content-filters-wrapper',
	TOGGLE_IMAGO_IMAGES = 'sidebar-main-media-content-toggle-imago-images-list',
	IMAGO_MODAL = 'sidebar-main-media-content-imago-modal-wrapper',
	IMAGO_MODAL_CLOSE = 'sidebar-main-media-content-imago-modal-close-button',
	IMAGO_MODAL_SAVE = 'sidebar-main-media-content-imago-modal-save-button',
	CROP_IMAGE = 'sidebar-main-media-content-crop-button',
	EDIT_IMAGE = 'sidebar-main-media-content-edit-button',
	UPLOAD_IMAGE = 'sidebar-main-media-content-upload-button',
	EXTERNAL_MAIN_MEDIA_SELECT = 'sidebar-main-media-content-external-main-media-select',
	MAIN_IMAGE_DESCRIPTION = 'sidebar-main-media-content-image-description-input',
	MAIN_IMAGE_DESCRIPTION_CHECKBOX = 'sidebar-main-media-content-image-description-checkbox',
	MAIN_IMAGE_WATERMARK_CHECKBOX = 'sidebar-main-media-content-image-watermark-checkbox',
	TOGGLE_GETTY_IMAGES = 'sidebar-main-media-content-toggle-getty-images-list',
	GETTY_MODAL = 'sidebar-main-media-content-getty-modal-wrapper',
	GETTY_MODAL_CLOSE = 'sidebar-main-media-content-getty-modal-close-button',
	GETTY_MODAL_SAVE = 'sidebar-main-media-content-getty-modal-save-button',
}
