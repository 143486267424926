import React from 'react';
import { FacebookShareButton, TwitterShareButton } from 'react-share';
import { Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import './styles/social-urls.component.scss';

type Properties = {
	url: string;
	t: any;
	type: string;
};

export const SocialMediaComponent: React.FunctionComponent<Properties> = ({ url, t, type }) => {
	return (
		<InputGroup className='social-media-container'>
			<InputGroupAddon addonType='prepend'>
				<InputGroupText className='p-0'>
					<FacebookShareButton
						id={`facebook-share-button-${type}`}
						disabled={url.length === 0}
						url={url}
						className='social-media-button'
						translate='true'
					>
						<div className='w-100 h-100 d-flex justify-content-center align-items-center'>
							<i className='fa fa-facebook' aria-hidden='true'></i>
						</div>
					</FacebookShareButton>
				</InputGroupText>
				<InputGroupText className='p-0'>
					<TwitterShareButton
						id={`twitter-share-button-${type}`}
						disabled={url.length === 0}
						url={url}
						className='social-media-button'
						translate='true'
					>
						<div className='w-100 h-100 d-flex justify-content-center align-items-center'>
							<i className='fa fa-twitter' aria-hidden='true'></i>
						</div>
					</TwitterShareButton>
				</InputGroupText>
			</InputGroupAddon>
			<Input id={`social-input-${type}`} placeholder={t(`${type}_url`)} disabled value={url} />
		</InputGroup>
	);
};
