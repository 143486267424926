import { assetsPlaceholder } from '../../constants/assetsPlaceholder';
import { CountryJson } from './country.json';
import Country from './country.model';

export default class CountryBuilder {
	private json: CountryJson;

	constructor(country?: Country) {
		this.json = country ? country.toJSON() : ({} as CountryJson);
	}

	withId(id: string): CountryBuilder {
		this.json.id = id;

		return this;
	}

	withName(name: string): CountryBuilder {
		this.json.name = name;

		return this;
	}

	withFlag(flag: string): CountryBuilder {
		this.json.flag = flag ? flag : assetsPlaceholder.flag;

		return this;
	}

	fromResponse(responseItem: any) {
		this.json.id = responseItem.id;
		this.json.name = responseItem.name;

		return this;
	}

	build(): Country {
		return Country.fromJSON(this.json);
	}
}
