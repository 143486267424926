import React, { FunctionComponent, useEffect, useState } from 'react';
import SearchDateDesign from '../../../../Partials/design-components/search-date/search-date';
import { DATA_QA } from '../../helpers/data-qa-attributes';
import MultisportHttpService from '../../../../../services/rest/multisport-http-service';
import SearchBySportInputExtended from '../../../../Partials/design-components/search-sport-extended/search-sport-extended';
import { responseToMultisportModel } from '../../models/sport.mapper';
import MultisportModel from '../../models/sport.model';
import EventsListState from './events-list-state';
import { connect } from 'react-redux';
import { setMultisportEventSearchDate } from '../../../../../store/action-creators/multisport-action-creator';
import { useTranslation } from 'react-i18next';
import { isDateObjValid } from '../../../../../global-helpers/global-dates.helper';
import { toast } from 'react-toastify';

type Props = {
	configurationDate: Date;
	setEventSearchDate: (date: Date) => void;
	eventSearchDate: string | null;
};

export const EventsList: FunctionComponent<Props> = ({ configurationDate, setEventSearchDate, eventSearchDate }) => {
	const { t } = useTranslation();
	const eventSearchDateAsObj = eventSearchDate ? new Date(eventSearchDate) : new Date();
	const [sportEventsLoading, setSportEventsLoading] = useState(false);
	const [sportsEvents, setSportsEvents] = useState<MultisportModel[]>([]);

	const searchForEventsByDate = (date: Date | null) => {
		if (isDateObjValid(date)) {
			setSportEventsLoading(true);
			setEventSearchDate(date || new Date());
			MultisportHttpService.getSportEventsByDate(date!!, configurationDate)
				.then((response) => {
					if (response && response.data) {
						const result = response.data.map((responseSportData: Array<any>) => responseToMultisportModel(responseSportData));
						setSportsEvents(result);
					} else {
						setSportsEvents([]);
					}
				})
				.catch(() => toast.error(t('multisport_config_error_fetch')))
				.finally(() => setSportEventsLoading(false));
		}
	};

	useEffect(() => {
		searchForEventsByDate(eventSearchDateAsObj);
	}, []);

	return (
		<div id={DATA_QA.EVENTS_LIST_WRAPPER}>
			<h3> {t('list_of_events')} </h3>
			<span> {t('list_of_events_sub')} </span>
			<SearchBySportInputExtended fieldId='search' configurationDate={configurationDate} />
			<SearchDateDesign
				date={eventSearchDateAsObj}
				invokeFunc={searchForEventsByDate}
				fieldId='search-date'
				labelText={t('search_events_in_list')}
			/>
			<EventsListState isLoading={sportEventsLoading} sportsEvents={sportsEvents} />
		</div>
	);
};

function mapStateToProps(state: any) {
	return {
		configurationDate: state.multisportConfiguration.configurationDate
			? new Date(state.multisportConfiguration.configurationDate)
			: new Date(),
		eventSearchDate: state.multisportConfiguration.eventSearchDate || null,
	};
}

function mapDispatchToProps(dispatch: any) {
	return {
		setEventSearchDate: (date: Date) => dispatch(setMultisportEventSearchDate(date)),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(EventsList);
