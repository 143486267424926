import { TranslationGroupJson } from './translation-group-json';
import TranslationGroupModel from './translation-group-model';
import { TranslationGroupRequestJson } from './translation-group-request-json';
import TranslationsModel from './translations/translations-model';

export default class TranslationGroupBuilder {
	private json: TranslationGroupJson;

	constructor(translationGroup?: TranslationGroupModel) {
		if (translationGroup && translationGroup instanceof TranslationGroupModel) {
			this.json = translationGroup.toJSON();
		} else if (translationGroup) {
			this.json = translationGroup;
		} else {
			this.json = {} as TranslationGroupJson;
		}
	}

	withId(id: string): TranslationGroupBuilder {
		this.json.id = id;

		return this;
	}

	withContentType(contentType: string): TranslationGroupBuilder {
		this.json.contentType = contentType;

		return this;
	}

	withMasterContentId(masterContentId: string): TranslationGroupBuilder {
		this.json.masterContentId = masterContentId;

		return this;
	}

	withTranslations(translations: TranslationsModel[]): TranslationGroupBuilder {
		this.json.translations = translations;

		return this;
	}

	toRequestJson(): TranslationGroupRequestJson {
		let json = {} as TranslationGroupRequestJson;
		json.id = this.json.id;
		json.contentType = this.json.contentType;
		json.masterContentId = this.json.masterContentId;
		json.translations = this.json.translations;
		return json;
	}

	fromResponse(responseItem: any) {
		this.json.id = responseItem.id;
		this.json.contentType = responseItem.contentType;
		this.json.masterContentId = responseItem.masterContentId;
		this.json.translations = responseItem.translations;
		return this;
	}

	build(): TranslationGroupModel {
		return TranslationGroupModel.fromJSON(this.json);
	}
}
