import React from 'react';
import GallerySelect from './select/gallery-select.component';
import { Col, FormGroup, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import GalleryCreate from './create/gallery-create.component';
import { Properties, State } from './properties/gallery-edit.properties';

export default class GalleryEdit extends React.Component<Properties, State> {
	constructor(props: Properties) {
		super(props);
		this.state = {
			activeTab: new Array(4).fill('1'),
		};
	}

	toggle = (tabPane: any, tab: any) => {
		const newArray = this.state.activeTab.slice();
		newArray[tabPane] = tab;
		this.setState({ activeTab: newArray });
	};

	render() {
		return (
			<div className={'block bg-light mb-2'}>
				<div className={'block-content'}>
					<div id='match-tagging'>
						<Row>
							<Col>
								<Nav tabs className={''}>
									<NavItem>
										<NavLink
											id='gallery-block-select-tab'
											active={this.state.activeTab[0] === '1'}
											onClick={() => {
												this.toggle(0, '1');
											}}
										>
											<i className='fa fa-search'></i> {this.props.t('select')}
										</NavLink>
									</NavItem>
									<NavItem>
										<NavLink
											id='gallery-block-create-tab'
											active={this.state.activeTab[0] === '2'}
											onClick={() => {
												this.toggle(0, '2');
											}}
										>
											<i className='fa fa-plus'></i> {this.props.t('create')}
										</NavLink>
									</NavItem>
								</Nav>
								<TabContent activeTab={this.state.activeTab[0]}>
									<TabPane tabId='1' className={'p-3 pb-0 bg-white'}>
										<FormGroup>
											{this.state.activeTab[0] === '1' && (
												<GallerySelect
													currentProject={this.props.currentProject}
													t={this.props.t}
													block={this.props.block}
													contentData={this.props.contentData}
													gallery={this.props.gallery}
												/>
											)}
										</FormGroup>
									</TabPane>
									<TabPane tabId='2' className={'p-3 pb-0 bg-white'}>
										<FormGroup>
											{this.state.activeTab[0] === '2' && (
												<GalleryCreate
													t={this.props.t}
													block={this.props.block}
													currentProject={this.props.currentProject}
													contentData={this.props.contentData}
													contentLanguage={this.props.contentLanguage}
												/>
											)}
										</FormGroup>
									</TabPane>
								</TabContent>
							</Col>
						</Row>
					</div>
				</div>
			</div>
		);
	}
}
