import React from 'react';
import Select from 'react-select';
import HttpService from '../../../../../services/rest/HttpService';
import { Properties, State } from './properties/player-season-select.properties';
import { createTournamentGroupOptions, mapResponseListToPlayerSeasonsModel } from './helpers/players-seasons-select.helper';
import ErrorHandler from '../error/error-handler-component';

export default class PlayerSeasonsSelect extends React.Component<Properties, State> {
	constructor(props: any) {
		super(props);
		this.state = {
			options: [],
		};
	}

	componentDidMount() {
		if (this.props.playerId) {
			this.requestPlayerInformation(this.props.playerId);
		}
	}

	componentDidUpdate(prevProps: any) {
		if (prevProps.playerId !== this.props.playerId) {
			this.onSelect([]);
			this.requestPlayerInformation(this.props.playerId);
		}
	}

	requestPlayerInformation = (playerId: any) => {
		HttpService.getPlayerSeasonStatistics(playerId, this.props.currentProject.language)
			.then((response: any) => {
				const modelList = mapResponseListToPlayerSeasonsModel(response.data);
				const options = createTournamentGroupOptions(modelList);
				this.updateSeasonsState(options);
			})
			.catch((e: any) => e);
	};

	updateSeasonsState = (options: any) => this.setState({ ...this.state, options });

	onSelect = (selections: any) => {
		if (this.props.onSelect) {
			this.props.onSelect(selections);
		}
	};

	render() {
		if (this.state.options) {
			return (
				<>
					<Select
						options={this.state.options}
						className='mb-2'
						value={this.props.value}
						isMulti
						placeholder={this.props.t('select')}
						onChange={this.onSelect}
					/>
					{!this.props.isValid && (!this.props.value || (this.props.value && this.props.value.length === 0)) && (
						<ErrorHandler t={this.props.t} errorMessage='field_is_required' />
					)}
				</>
			);
		}

		return null;
	}
}
