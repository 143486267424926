import * as React from 'react';
import { ButtonGroup, Button } from 'reactstrap';
import './video-cells.scss';
import LazyLoad from 'react-lazy-load';
import { assetsPlaceholder } from '../../../../../../../constants/assetsPlaceholder';
import { stripHtml } from '../helpers/dugout-video-edit.helper';

export default class LazyLoadVideoCell extends React.Component {
	onVideoSelect = () => this.props.onSelect(this.props.video);

	render() {
		const { video, t, selectedVideo, isMediaSidebar } = this.props;
		const videoWidth = isMediaSidebar ? 180 : 240;
		const videoHeight = videoWidth * 0.56;
		const isSelectedVideo = selectedVideo && video.id === selectedVideo.id;
		const addItemStyle = isSelectedVideo ? 'selected-item' : '';

		return (
			<LazyLoad height={videoHeight} width={videoWidth} className='mb-5'>
				<div className='image-container' title={stripHtml(video.title)}>
					<div onClick={this.onVideoSelect} className={`add-item position-relative text-center ${addItemStyle}`}>
						{isSelectedVideo && <i className='fa-4x fa fa-check-circle selected-video text-warning animated fadeIn' />}
						<img
							className='img-fluid w-100 image rounded-top'
							onError={(i) => (i.target.src = assetsPlaceholder.blankPlaceholder)}
							alt={video.title}
							src={video.thumbnail}
						/>
					</div>
					<div className='image-footer'>
						{video.title && video.title.length > 0 && (
							<div className={'image-description px-2 py-1 small overflow-hidden text-wrap'}>{stripHtml(video.title)}</div>
						)}
						<ButtonGroup className='actions-wrapper position-absolute display-block w-100'>
							<Button block color='secondary' className='p-0 py-1 m-0' title={t('play_video')} onClick={() => this.props.playVideo(true, video)}>
								<i className='fa fa-play' />
							</Button>
							<Button
								block
								color='warning'
								className='p-0 py-1 m-0'
								title={t('copy_embedCode')}
								onClick={(e) => this.props.copyEmbed(e, video.embedCode)}
							>
								<i className='fa fa-copy' />
							</Button>
							<Button block color='secondary' className='p-0 py-1 m-0' title={t('copy_id')} onClick={(e) => this.props.copyId(e, video.id)}>
								<i className='fa fa-clone' />
							</Button>
						</ButtonGroup>
					</div>
				</div>
			</LazyLoad>
		);
	}
}
