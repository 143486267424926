import { USER_LOGOUT } from '../action-creators/ProfileActionCreators';
import ContentAttributes from '../../models/content-attributes/content-attributes-model';
import {
	REQUEST_ARTICLE_COMMENTS_POLICIES_RECEIVED,
	REQUEST_VIDEO_COMMENTS_POLICIES_RECEIVED,
	REQUEST_POLLS_COMMENTS_POLICIES_RECEIVED,
	REQUEST_GALLERY_COMMENTS_POLICIES_RECEIVED,
} from '../action-creators/comment-policy-action.creator';

interface InitialState {
	articleCommentsPolicies: ContentAttributes[];
	videoCommentsPolicies: ContentAttributes[];
	imageCommentsPolicies: ContentAttributes[];
	galleryCommentsPolicies: ContentAttributes[];
	pollCommentsPolicies: ContentAttributes[];
}

const initialState: InitialState = {
	articleCommentsPolicies: [] as ContentAttributes[],
	videoCommentsPolicies: [] as ContentAttributes[],
	imageCommentsPolicies: [] as ContentAttributes[],
	galleryCommentsPolicies: [] as ContentAttributes[],
	pollCommentsPolicies: [] as ContentAttributes[],
};

function commentsPoliciesReducer(state: any = initialState, action: any) {
	if (action.type === REQUEST_ARTICLE_COMMENTS_POLICIES_RECEIVED) {
		return Object.assign({}, state, {
			articleCommentsPolicies: action.payload.slice(),
		});
	}

	if (action.type === REQUEST_VIDEO_COMMENTS_POLICIES_RECEIVED) {
		return Object.assign({}, state, {
			videoCommentsPolicies: action.payload.slice(),
		});
	}

	if (action.type === REQUEST_POLLS_COMMENTS_POLICIES_RECEIVED) {
		return Object.assign({}, state, {
			pollCommentsPolicies: action.payload.slice(),
		});
	}

	if (action.type === REQUEST_GALLERY_COMMENTS_POLICIES_RECEIVED) {
		return Object.assign({}, state, {
			galleryCommentsPolicies: action.payload.slice(),
		});
	}

	if (action.type === USER_LOGOUT) {
		state = initialState;
	}

	return state;
}

export default commentsPoliciesReducer;
