import React, { FunctionComponent, useState } from 'react';
import { Button, Col, FormGroup, Label, Row } from 'reactstrap';
import './styles/style.scss';
import '../../../style/live-blog.style.scss';
import LiveBlogModel from '../../../models/live-blog.model';
import { DATA_QA_ATTRIBUTES } from '../../../../../Partials/Sidebar/general-content/constants/data-qa.attributes';
import MainMediaModel from '../../../../../../models/v2/main-media/main-media.model';
import { LiveBlogMainMediaTypeSelect } from './components/live-blog-main-media-type-select.component';
import { MainMediaTypes } from './components/helpers/main-media-type-select.helper';
import { extractMainImageObjectFromArray, imageToMainImageModel } from '../../../../../Partials/Sidebar/media-refactored/helpers/general';
import HttpService from '../../../../../../services/rest/HttpService';
import { responseToModelImage } from '../../../../../../models/v2/image/image.mapper';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../../../store/store';
import { LiveBlogMainImage } from './components/main-image.component';
import ImageUploadDropzoneContainer from './live-blog-image-upload.component';
import { MainMediaImageOperations } from './main-media-image-operations/main-media-image-operations-wrapper';
import { ExternalMainMediaContainer } from './external-media-container/external-media-container';
import ImageModel from '../../../../../../models/v2/image/image.model';
import { setMainMediaData } from './helpers/live-blog-image.helper';
import { MainMediaContentModal } from './live-blog-main-media-modal/main-media-modal.component';

type Properties = {
	t: any;
	setLiveBlogMainMedia: (media: MainMediaModel[]) => void;
	setLiveBlogMainMediaType: (type: string) => void;
	liveBlog: LiveBlogModel;
};
export const LiveBlogMainMediaComponent: FunctionComponent<Properties> = ({
	t,
	setLiveBlogMainMedia,
	setLiveBlogMainMediaType,
	liveBlog,
}) => {
	const project = useSelector((state: AppState) => state.project.currentProject);
	const media: MainMediaModel = extractMainImageObjectFromArray(liveBlog.main_media) || new MainMediaModel();
	const [toggleImages, setToggleImages] = useState<boolean>(false);

	const onMainMediaTypeSelect = (type: string) => {
		setLiveBlogMainMediaType(type);
	};

	const onChange = (id: string) => {
		const headers = { Project: project.domain };
		return HttpService.get(`/images/${id}`, null, headers)
			.then((response: any) => {
				const imageRemapped = responseToModelImage(response.data.data);
				const imageRemappedAsMainMedia = imageToMainImageModel(imageRemapped);
				const newData = setMainMediaData(liveBlog.main_media, imageRemappedAsMainMedia, media);
				setLiveBlogMainMedia(newData);
			})
			.catch((error: unknown) => console.error(error));
	};

	const onAlreadyUploadedMainMediaImagesChange = (image: ImageModel) => {
		const imageRemappedAsMainMedia = imageToMainImageModel(image);
		const mediaData = setMainMediaData(liveBlog.main_media, imageRemappedAsMainMedia, media);
		setLiveBlogMainMedia(mediaData);
		setToggleImages(false);
	};

	const toggleImageListing = () => {
		setToggleImages(!toggleImages);
	};

	return (
		<div style={{ marginTop: '-8%' }}>
			<Label className='main-media-label' htmlFor={DATA_QA_ATTRIBUTES.LIVE_BLOG_MAIN_IMAGE}>
				<strong>{t('main_media')}</strong>
			</Label>
			<span className='text-danger'>*</span>
			<LiveBlogMainMediaTypeSelect t={t} liveBlog={liveBlog} setLiveBlogMainMediaType={onMainMediaTypeSelect} />
			<Row className='mb-2 live-blog-image-container'>
				<Col>
					{liveBlog.main_media_type === MainMediaTypes.image && (
						<LiveBlogMainImage t={t} mainMedia={liveBlog.main_media} projectDomain={project.domain} />
					)}
					{liveBlog.main_media_type === MainMediaTypes.image &&
						(!media.resourceId ? (
							<FormGroup className='mt-1'>
								<ImageUploadDropzoneContainer
									t={t}
									imageUploadButtonId={DATA_QA_ATTRIBUTES.LIVE_BLOG_IMAGE_UPLOAD_CONTAINER}
									project={project}
									onImageUploadSuccess={onChange}
									display
									toggleUploading={() => undefined}
								/>
							</FormGroup>
						) : (
							<FormGroup className='mt-1'>
								<MainMediaImageOperations
									mainMedia={liveBlog.main_media}
									t={t}
									onImageUploadSuccess={onChange}
									project={project}
									toggleUploading={() => undefined}
								/>
							</FormGroup>
						))}
					{liveBlog.main_media_type !== MainMediaTypes.image && (
						<ExternalMainMediaContainer
							mainMedia={liveBlog.main_media}
							t={t}
							setLiveBlogMainMedia={setLiveBlogMainMedia}
							mainMediaType={liveBlog.main_media_type ? liveBlog.main_media_type : ''}
						/>
					)}
					{liveBlog.main_media_type === MainMediaTypes.image && (
						<>
							<Button
								className='m-0 p-0'
								color='link'
								onClick={toggleImageListing}
								aria-expanded={toggleImages}
								aria-controls='toggle-live-blog-image-listing'
								id={DATA_QA_ATTRIBUTES.LIVE_BLOG_TOGGLE_IMAGES}
							>
								{t('toggle_uploaded_images')}
							</Button>
							{toggleImages && (
								<MainMediaContentModal
									t={t}
									liveBlog={liveBlog}
									toggleModal={toggleImageListing}
									onChange={onAlreadyUploadedMainMediaImagesChange}
									isOpen={toggleImages}
								/>
							)}
						</>
					)}
				</Col>
			</Row>
		</div>
	);
};
