import { SocialJson } from './social-json';
import Social from './social';
import { SocialRequestJson } from './social-request-json';

export default class SocialBuilder {
	private json: SocialJson;

	constructor(social?: Social | SocialJson) {
		if (social && social instanceof Social) {
			this.json = social.toJSON();
		} else if (social) {
			this.json = social;
		} else {
			this.json = {} as SocialJson;
		}
	}

	withWeb(web: string): SocialBuilder {
		this.json.web = web;

		return this;
	}

	withTwitterId(twitterId: string): SocialBuilder {
		this.json.twitterId = twitterId;

		return this;
	}

	withFacebookId(facebookId: string): SocialBuilder {
		this.json.facebookId = facebookId;

		return this;
	}

	withInstagramId(instagramId: string): SocialBuilder {
		this.json.instagramId = instagramId;

		return this;
	}

	withWikipediaId(wikipediaId: string): SocialBuilder {
		this.json.wikipediaId = wikipediaId;

		return this;
	}

	withYoutubeId(youtubeId: string): SocialBuilder {
		this.json.youtubeId = youtubeId;

		return this;
	}

	build(): Social {
		return Social.fromJSON(this.json);
	}

	toRequestJson(): SocialRequestJson {
		let json = {} as SocialRequestJson;

		if (this.json.web) json.web = this.json.web;

		if (this.json.twitterId) json.twitter_id = this.json.twitterId;

		if (this.json.facebookId) json.facebook_id = this.json.facebookId;

		if (this.json.instagramId) json.instagram_id = this.json.instagramId;

		if (this.json.wikipediaId) json.wikipedia_id = this.json.wikipediaId;

		if (this.json.youtubeId) json.youtube_channel_id = this.json.youtubeId;

		return json;
	}
}
