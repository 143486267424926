import moment from 'moment';
import * as React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { Badge, Col, FormGroup, Input, Label, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import FansUnitedGameModel from '../../blocks/widgets/fans-united/models/fans-united-game.model';
import FansUnitedLeaderboardTemplatesModel from '../../blocks/widgets/fans-united/models/fans-united-leaderboard-templates.model';
import { FansUnitedWidgetsTypes } from '../../constants/block.types';
import { useTranslation } from 'react-i18next';
import FansUnitedClassicQuizModel from '../../blocks/widgets/fans-united/models/fans-united-classic-quiz.model';
import FansUnitedEitherOrModel from '../../blocks/widgets/fans-united/models/fans-united-either-or.model';

export type Properties = {
	onContentSelect: (item: FansUnitedClassicQuizModel | FansUnitedGameModel | FansUnitedLeaderboardTemplatesModel) => void;
	games: FansUnitedGameModel[];
	templates: FansUnitedLeaderboardTemplatesModel[];
	quizzes?: FansUnitedClassicQuizModel[];
	eitherOrGames?: FansUnitedEitherOrModel[];
	componentType: string;
};

export const FansUnitedContent: React.FunctionComponent<Properties> = ({
	onContentSelect,
	games,
	templates,
	quizzes,
	eitherOrGames,
	componentType,
}) => {
	const activeTab = new Array(4).fill('1');
	const [t] = useTranslation();

	const onSelect = (content: any) => {
		onContentSelect(content);
	};

	const renderGames = (games: FansUnitedGameModel[]) => {
		if (games.length < 1) {
			return <div className='text-center p-3'>{t('no_games_found')}</div>;
		}
		return (
			<>
				{games.map((game) => {
					return (
						<FormGroup key={`key-${game.id}`} className={`mb-2 pb-2 border-bottom`} check>
							<Input
								className='form-check-input'
								onChange={() => {
									onSelect(game);
								}}
								type='radio'
								id={`${game.id}`}
								checked={game.isSelected}
								name={`inline-radio-${game.id}`}
								value={game.id}
							/>
							<Label className='form-check-label' check htmlFor={`${game.id}`}>
								{`${moment(game.predictions_cutoff).format('DD MMM YYYY')} - `}
								<Badge color={`${game.status === 'OPEN' ? 'success' : 'secondary'} `}>{`${game.status}`}</Badge>
								<span className='ml-1'>{game.title}</span>
							</Label>
						</FormGroup>
					);
				})}
			</>
		);
	};

	const renderTemplates = (templates: FansUnitedLeaderboardTemplatesModel[]) => {
		if (templates.length < 1) {
			return <div className='text-center p-3'>{t('no_templates_found')}</div>;
		}
		return (
			<>
				{templates.map((template) => {
					return (
						<FormGroup key={`key-${template.id}`} className={`mb-2 pb-2 border-bottom`} check>
							<Input
								className='form-check-input'
								onChange={() => {
									onSelect(template);
								}}
								type='radio'
								id={`${template.id}`}
								checked={template.isSelected}
								name={`inline-radio-${template.id}`}
								value={template.id}
							/>
							<Label className='form-check-label' check htmlFor={`${template.id}`}>
								{`${moment(template.created_at).format('DD MMM YYYY')} - `}
								<span>{template.name}</span>
							</Label>
						</FormGroup>
					);
				})}
			</>
		);
	};

	const renderQuizzes = (quizzes: FansUnitedClassicQuizModel[]) => {
		if (quizzes.length < 1) {
			return <div className='text-center p-3'>{t('no_quizzes_found')}</div>;
		}
		return (
			<>
				{quizzes.map((quiz) => {
					return (
						<FormGroup key={`key-${quiz.id}`} className={`mb-2 pb-2 border-bottom`} check>
							<Input
								className='form-check-input'
								onChange={() => {
									onSelect(quiz);
								}}
								type='radio'
								id={`${quiz.id}`}
								checked={quiz.isSelected}
								name={`inline-radio-${quiz.id}`}
								value={quiz.id ? quiz.id : ''}
							/>
							<Label className='form-check-label' check htmlFor={`${quiz.id}`}>
								{`${moment(quiz.created_at).format('MMM Do YYYY, HH:mm')} - `}
								<Badge color={`${quiz.status === 'ACTIVE' ? 'success' : 'secondary'} `}>{`${quiz.status}`}</Badge>
								<span className='ml-1'>{quiz.title}</span>
							</Label>
						</FormGroup>
					);
				})}
			</>
		);
	};

	const renderEitherOrGames = (games: FansUnitedEitherOrModel[]) => {
		if (games.length < 1) {
			return <div className='text-center p-3'>{t('no_either_or_found')}</div>;
		}
		return (
			<>
				{games.map((game) => {
					return (
						<FormGroup key={`key-${game.id}`} className={`mb-2 pb-2 border-bottom`} check>
							<Input
								className='form-check-input'
								onChange={() => {
									onSelect(game);
								}}
								type='radio'
								id={`${game.id}`}
								checked={game.isSelected}
								name={`inline-radio-${game.id}`}
								value={game.id ? game.id : ''}
							/>
							<Label className='form-check-label' check htmlFor={`${game.id}`}>
								{`${moment(game.created_at).format('MMM Do YYYY, HH:mm')} - `}
								<Badge color={`${game.status === 'ACTIVE' ? 'success' : 'secondary'} `}>{`${game.status}`}</Badge>
								<span className='ml-1'>{game.title}</span>
							</Label>
						</FormGroup>
					);
				})}
			</>
		);
	};

	return (
		<>
			{componentType === FansUnitedWidgetsTypes.leaderboard && templates && (
				<div id='leaderboard-template-tagging' data-qa='widget-fans-united-leaderboards-content'>
					<Row>
						<Col>
							<Nav tabs className={''}>
								<NavItem>
									<NavLink active={activeTab[0] === '1'}>
										<i className='fa fa-calendar' /> {t('leaderboard_templates')}
									</NavLink>
								</NavItem>
							</Nav>
							<TabContent activeTab={activeTab[0]}>
								<TabPane tabId='1' className='p-2 pb-0 bg-white'>
									<FormGroup className='mt-1'>
										<Scrollbars style={{ height: 300 }}>{renderTemplates(templates)}</Scrollbars>
									</FormGroup>
								</TabPane>
							</TabContent>
						</Col>
					</Row>
				</div>
			)}
			{componentType !== FansUnitedWidgetsTypes.classicQuiz &&
				componentType !== FansUnitedWidgetsTypes.leaderboard &&
				componentType !== FansUnitedWidgetsTypes.eitherOr && (
					<div id='game-tagging' data-qa='widget-fans-united-games-content'>
						<Row>
							<Col>
								<Nav tabs className={''}>
									<NavItem>
										<NavLink active={activeTab[0] === '1'}>
											<i className='fa fa-calendar' /> {t('games')}
										</NavLink>
									</NavItem>
								</Nav>
								<TabContent activeTab={activeTab[0]}>
									<TabPane tabId='1' className='p-2 pb-0 bg-white'>
										<FormGroup className='mt-1'>
											<Scrollbars style={{ height: 300 }}>{renderGames(games)}</Scrollbars>
										</FormGroup>
									</TabPane>
								</TabContent>
								<Label>
									<strong className='ml-1'>{'Games are ordered by prediction cutoff date'}</strong>
								</Label>
							</Col>
						</Row>
					</div>
				)}
			{componentType === FansUnitedWidgetsTypes.classicQuiz && quizzes && (
				<div id='classic-quiz-tagging' data-qa='widget-fans-united-classic-quizzes-content'>
					<Row>
						<Col>
							<Nav tabs>
								<NavItem>
									<NavLink active={activeTab[0] === '1'}>
										<i className='fa fa-calendar' /> {t('classic_quizzes')}
									</NavLink>
								</NavItem>
							</Nav>
							<TabContent activeTab={activeTab[0]}>
								<TabPane tabId='1' className='p-2 pb-0 bg-white'>
									<FormGroup className='mt-1'>
										<Scrollbars style={{ height: 300 }}>{renderQuizzes(quizzes)}</Scrollbars>
									</FormGroup>
								</TabPane>
							</TabContent>
						</Col>
					</Row>
				</div>
			)}
			{componentType === FansUnitedWidgetsTypes.eitherOr && eitherOrGames && (
				<div id='classic-quiz-tagging' data-qa='widget-fans-united-either-or-games-content'>
					<Row>
						<Col>
							<Nav tabs>
								<NavItem>
									<NavLink active={activeTab[0] === '1'}>
										<i className='fa fa-calendar' /> {t('either_or_games')}
									</NavLink>
								</NavItem>
							</Nav>
							<TabContent activeTab={activeTab[0]}>
								<TabPane tabId='1' className='p-2 pb-0 bg-white'>
									<FormGroup className='mt-1'>
										<Scrollbars style={{ height: 300 }}>{renderEitherOrGames(eitherOrGames)}</Scrollbars>
									</FormGroup>
								</TabPane>
							</TabContent>
						</Col>
					</Row>
				</div>
			)}
		</>
	);
};
