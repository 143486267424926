import * as React from 'react';
import './image-cells.scss';
import { copyToClipboard } from './helpers/video-cells.helper';
import LazyLoadImageCell from './lazy-load-image-cell';
import ImagoImageModel from '../models/imago-image.model';
import ImagePreviewModal from '../../../../../Shared/image-preview-modal/image-preview-modal';
import Masonry from 'react-masonry-css';
import './masonry-style.scss';
type Properties = {
	images: ImagoImageModel[];
	analytics?: any;
	t: any;
	toggleLoadingState: boolean;
	onSelect: (image: ImagoImageModel) => any;
	selectedImage?: ImagoImageModel;
	isMediaSidebar?: boolean;
};

export type State = {
	isOpenModal: boolean;
	imageToView: ImagoImageModel | null;
};
export default class ImageCells extends React.Component<Properties, State> {
	constructor(props: any) {
		super(props);
		this.state = {
			isOpenModal: false,
			imageToView: null,
		};
	}

	copyThumbnail = (e: any, thumbnail: string) => {
		copyToClipboard(e, thumbnail, this.props.t, 'thumbnail');
	};

	previewImage = (open: boolean, image: ImagoImageModel) => {
		this.setState({ ...this.state, isOpenModal: open, imageToView: image });
	};

	onClose = (open: boolean) => {
		this.setState({ ...this.state, isOpenModal: open, imageToView: null });
	};

	render() {
		const { images, t, onSelect, selectedImage } = this.props;
		const { isOpenModal, imageToView } = this.state;

		return (
			<div>
				<Masonry breakpointCols={2} className='my-masonry-grid' columnClassName='my-masonry-grid_column'>
					{images &&
						images.length > 0 &&
						images.map((image: ImagoImageModel, index: number) => {
							return (
								<LazyLoadImageCell
									key={`${image.id + Math.random()}`}
									t={t}
									image={image}
									onSelect={onSelect}
									copyThumbnail={this.copyThumbnail}
									previewImage={this.previewImage}
									index={index}
									selectedImage={selectedImage}
								/>
							);
						})}
					{imageToView && (
						<ImagePreviewModal
							t={t}
							onClose={this.onClose}
							url={imageToView.image}
							title={imageToView.dateCreated.slice(0, 10)}
							open={isOpenModal}
						/>
					)}
				</Masonry>
			</div>
		);
	}
}
