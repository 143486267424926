import { ContentTypes } from '../../constants/content-types';
import { TOGGLE_CONTENT_LOADING_STATE, TOGGLE_MODAL } from '../action-creators/UiActionCreator';
import { TOGGLE_LOADING_STATE } from '../action-creators/UiActionCreator';
import { TOGGLE_SHOW_LIST_IMAGES } from '../action-creators/UiActionCreator';

interface InitialState {
	isToggleModal: boolean;
	toggleLoadingState: boolean;
	contentLoadingState: Record<string, boolean>;
	shouldShowListImages: any;
}

const initialState: InitialState = {
	isToggleModal: false,
	toggleLoadingState: false,
	contentLoadingState: {
		[ContentTypes.ARTICLE]: false,
		[ContentTypes.VIDEO]: false,
		[ContentTypes.GALLERY]: false,
		[ContentTypes.WIKI_PAGE]: false,
	},
	shouldShowListImages: false,
};

function uiReducer(state: any = initialState, action: any) {
	if (action.type === TOGGLE_MODAL) {
		return { ...state, isToggleModal: action.payload };
	}

	if (action.type === TOGGLE_LOADING_STATE) {
		return Object.assign({}, state, {
			toggleLoadingState: action.payload,
		});
	}

	if (action.type === TOGGLE_CONTENT_LOADING_STATE) {
		const { contentType, value } = action.payload;
		return Object.assign({}, state, {
			contentLoadingState: { ...state.contentLoadingState, [contentType]: value },
		});
	}

	if (action.type === TOGGLE_SHOW_LIST_IMAGES) {
		const showImages = state.shouldShowListImages && state.shouldShowListImages[action.payload] ? state.shouldShowListImages : {};
		showImages[action.payload] = state.shouldShowListImages[action.payload] ? !state.shouldShowListImages[action.payload] : true;

		return Object.assign({}, state, {
			shouldShowListImages: showImages,
		});
	}

	return state;
}

export default uiReducer;
