import { TeamPlayerJson } from './team-player.json';
import TeamPlayerBuilder from './team-player.builder';
import TeamModel from '../../team-select/models/team.model';

export default class TeamPlayerModel {
	readonly id: string;
	readonly name: string;
	readonly logo: string;
	readonly team: TeamModel;
	readonly birthdate: string;
	readonly position: string;
	readonly active: boolean;

	private constructor(id: string, name: string, logo: string, team: TeamModel, birthdate: string, position: string, active: boolean) {
		this.id = id;
		this.name = name;
		this.logo = logo;
		this.team = team;
		this.birthdate = birthdate;
		this.position = position;
		this.active = active;
	}

	toJson(): TeamPlayerJson {
		return {
			id: this.id,
			name: this.name,
			logo: this.logo,
			team: this.team,
			birthdate: this.birthdate,
			position: this.position,
			active: this.active,
		};
	}

	static fromJson(json: TeamPlayerJson): TeamPlayerModel {
		return new TeamPlayerModel(json.id, json.name, json.logo, json.team, json.birthdate, json.position, json.active);
	}

	static builder(teamPlayer?: TeamPlayerModel): TeamPlayerBuilder {
		return new TeamPlayerBuilder(teamPlayer);
	}
}
