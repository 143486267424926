import React, { FunctionComponent } from 'react';
import ListModel from '../../../../../../models/v2/list/list';
import { connect } from 'react-redux';
import ListAddItemContainer from '../list-add-item/list-add-item';
import ListSave from '../list-save/list-save';
import ListAlerts from '../list-alerts/list-alerts';
import ListContentSortableWrapper from '../sortable/sortable-wrapper';
import { isListEmpty } from '../../helpers/general.helper';

type Properties = {
	t: any;
	selectedList: ListModel | null;
};

const ListListingContainer: FunctionComponent<Properties> = ({ t, selectedList }) => {
	const isContentLoading = false;

	return (
		<div className='animated fadeIn'>
			<div className='mb-2'>{t('list_items')}</div>
			{!selectedList && <h5 className='mb-2'>{t('no_list_selected')}</h5>}
			<div>
				<div className={`${isContentLoading ? 'loading-overlay' : ''}`}>
					<ListContentSortableWrapper t={t} />
					<ListAlerts t={t} />
					<ListAddItemContainer t={t} />
					<ListSave t={t} />
				</div>
			</div>
		</div>
	);
};

function mapStateToProps(state: any) {
	return {
		selectedList: (!isListEmpty(state.list.list) && state.list.list) || null,
	};
}

export default connect(mapStateToProps)(ListListingContainer);
