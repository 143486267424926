import React from 'react';
import Select from 'react-select';
import Scrollbar from 'react-custom-scrollbars';
import HttpSocialService from './http-social-service';
import Post from '../../../models/feed/post/post';
import Filters from './subcomponents/filters';
import Sources from './subcomponents/sources';
import Posts from './subcomponents/posts';
import * as helpers from './helpers/dropdown-feed.helper';
import {
	FEED_ENTITY_UPDATE_SUCCESS,
	FEED_ENTITY_CREATE_SUCCESS,
	FEED_ENTITY_DELETE_SUCCESS,
	returnObjectForSelectedFilterSources,
	returnObjectForSelectedDate,
	returnObjectForSourceTabUpdate,
	returnObjectForSelectedFeed,
	returnObjectForUpdateNotifications,
} from '../../../store/action-creators/feeds-action-creator';
import { getNotifications, getAllLastPosts } from './helpers/notification.helper';
import { Properties, State } from './properties/social-feed-container.properties';
import ModelMapper from '../../../models/ModelMapper';
import Feed from '../../../models/feed/feed';
import './styles/container-style.scss';
import { compose } from 'redux';
import { connect } from 'react-redux';
import FacebookRefresh from './subcomponents/properties/facebook-refresh.component';
import { analyticsService } from '../../../App';
import onClickOutside from 'react-onclickoutside';
import { Subscription } from 'rxjs';
import { actionService } from '../../../App';

class SocialFeedContainer extends React.Component<Properties, State> {
	socialFeedSubscription: Subscription = {} as Subscription;
	private interval: number = -1;

	constructor(props: any) {
		super(props);
		this.state = {
			isContentLoading: false,
			feeds: [],
			selectFeedValue: {},
			collapsed: true,
			posts: [Post.builder().build()],
			newPosts: [Post.builder().build()],
			sourcePosts: [Post.builder().build()],
		};
	}

	componentDidMount() {
		!this.props.selectedDate.value && this.props.updateSelectedDate(helpers.dateFilterOptions(this.props.t)[0]);
		this.feedsRequest();
		this.feedsUpdateListener();
	}

	feedsUpdateListener = () => {
		this.socialFeedSubscription = actionService.onActionReceived().subscribe((action: string) => {
			if (action === FEED_ENTITY_UPDATE_SUCCESS || action === FEED_ENTITY_CREATE_SUCCESS || action === FEED_ENTITY_DELETE_SUCCESS) {
				this.feedsRequest();
			}
		});
	};

	componentDidUpdate(prevProps: Readonly<Properties>, prevState: Readonly<State>, snapshot?: any) {
		if (prevProps.currentProject.id !== this.props.currentProject.id) {
			this.feedsRequest();
		}
	}

	componentWillUnmount() {
		window.clearInterval(this.interval);
		this.socialFeedSubscription.unsubscribe();
	}

	handleClickOutside = () => {
		this.setState({ ...this.state, collapsed: true });
		analyticsService.sendEvent('Click', 'News tracker', 'Close');
	};

	fetchInitialData() {
		const { selectedFeed, selectedDate, sourceTab, selectedFilterSources } = this.props;
		const selectedSources =
			selectedFilterSources !== null && selectedFilterSources.length === 0
				? helpers.extractTypeFilterOptions(selectedFeed.sources)
				: selectedFilterSources;

		if (selectedSources !== null) {
			if (selectedSources.length > 0) {
				this.sourcePostsRequest(sourceTab.id, selectedFeed.id, selectedDate.value, selectedSources);
			}

			// Set interval request to fetch new posts on every 5m.
			if (this.interval > -1) {
				window.clearInterval(this.interval);
			}

			if (selectedFeed.id && selectedSources.length > 0) {
				this.interval = window.setInterval(() => this.newPostsRequest(selectedFeed.id, '5m', selectedSources), 300000);
			}
			this.props.updateSelectedFilterSources(selectedSources);
			this.setState({
				...this.state,
				selectFeedValue: helpers.feedToOption(selectedFeed),
				isContentLoading: selectedSources.length < 1 ? false : true,
			});
		}
	}

	feedsRequest() {
		const { currentProject, profile } = this.props;
		this.setState({ ...this.state, isContentLoading: true });
		HttpSocialService.requestFeeds(currentProject.domain, profile.id).then((result: any) => {
			if (result.data.data.length > 0) {
				const activeFeeds = result.data.data
					.filter((response: any) => response.active)
					.map((response: any) => ModelMapper.remapFeedFromResponse(response));
				const isSelectedFeedActive = activeFeeds.filter((feed: Feed) => feed.id === this.props.selectedFeed.id);
				const selectedFeed =
					activeFeeds && activeFeeds.length > 0
						? this.props.selectedFeed.id && isSelectedFeedActive.length > 0
							? this.props.selectedFeed
							: activeFeeds[0]
						: Feed.builder().withId('-1').build();

				this.sourcesRequest(selectedFeed.id);
				this.props.updateSelectedFeed(selectedFeed);
				this.setState({
					...this.state,
					selectFeedValue: helpers.feedToOption(selectedFeed),
					feeds: ModelMapper.remapFeedsFromResponse(result.data),
					isContentLoading: activeFeeds.length > 0,
				});
			} else {
				const selectedFeed = Feed.builder().withId('-1').build();
				this.sourcesRequest(selectedFeed.id);
				this.props.updateSelectedFeed(selectedFeed);

				this.setState({ ...this.state, isContentLoading: false });
			}
		});
	}

	sourcesRequest = (id: string) => {
		if (id === '-1') {
			this.setState({ ...this.state, posts: [] });
			this.props.updateNotifications([]);
			window.clearInterval(this.interval);

			return;
		}

		const { currentProject, profile } = this.props;
		HttpSocialService.requestFeedsSources(currentProject.domain, profile.id, id).then((result: any) => {
			const selectedFeed = ModelMapper.remapFeedFromResponse(result.data.data);
			this.props.updateSelectedFeed(selectedFeed);
			this.fetchInitialData();
		});
	};
	// Get new posts and define the notifications with getNotifications method from notification helper.
	// Add the new posts to all posts and new posts to current source posts.
	// Defined notifications will be added to old notifications. The notifications state will be updated only with items with > 0 notifications
	// Clearing of source notifications will be made when the source is changed.
	newPostsRequest = (id: string, date: any, selectedFilterSources: any) => {
		const types = selectedFilterSources.map((type: any) => type.value);
		const { currentProject, profile } = this.props;
		HttpSocialService.requestPostsByTypePeriod(currentProject.domain, profile.id, id, date, types.join(','))
			.then((response: any) => {
				const newPosts = response.data.data.map((post: any) =>
					ModelMapper.remapPostFromResponse(post, this.state.selectFeedValue.feed.sources),
				);
				const newNotifications = getNotifications(this.props.selectedFeed.sources, newPosts, this.state.posts).filter(
					(notification: any) => notification.notifications > 0,
				);
				const allNewPosts = getAllLastPosts(newPosts, this.state.posts, 'All');
				const sourceNewPosts = getAllLastPosts(newPosts, this.state.sourcePosts, this.props.sourceTab.location);
				this.props.updateNotifications([...this.props.notifications, ...newNotifications]);
				this.setState((prevState: any) => {
					return {
						...this.state,
						newPosts,
						posts: Object.assign(prevState.posts, allNewPosts),
						sourcePosts: Object.assign(prevState.sourcePosts, sourceNewPosts),
					};
				});
			})
			.catch((err: any) => this.setState({ ...this.state, newPosts: [] }));
	};

	sourcePostsRequest = (sourceId: string, selectedFeedId: string, date: any, selectedFilterSources: any) => {
		this.setState({ ...this.state, isContentLoading: true });

		if (sourceId === 'All') {
			if (selectedFilterSources === null || selectedFilterSources.length === 0) {
				this.setState({ ...this.state, posts: [] });
			}

			if (selectedFilterSources !== null && selectedFilterSources.length > 0) {
				const types = selectedFilterSources.map((type: any) => type.value);
				const { currentProject, profile } = this.props;
				HttpSocialService.requestPostsByTypePeriod(currentProject.domain, profile.id, selectedFeedId, date, types.join(','))
					.then((response: any) => {
						const posts = response.data.data.map((post: any) => ModelMapper.remapPostFromResponse(post, this.state.selectFeedValue.feed.sources));
						this.setState({ ...this.state, posts, isContentLoading: false });
					})
					.catch((err: any) => this.setState({ ...this.state, posts: [], isContentLoading: false }));
			}
		}

		if (sourceId !== 'All') {
			const { currentProject, profile } = this.props;
			HttpSocialService.requestPostsBySourcePeriod(currentProject.domain, profile.id, selectedFeedId, date, sourceId)
				.then((response: any) => {
					const posts = response.data.data.map((post: any) => ModelMapper.remapPostFromResponse(post, this.state.selectFeedValue.feed.sources));
					this.setState({ ...this.state, sourcePosts: posts, isContentLoading: false });
				})
				.catch((err: any) => this.setState({ ...this.state, sourcePosts: [], isContentLoading: false }));
		}
	};

	onFeedChange = (feed: any) => {
		analyticsService.sendEvent('Click', 'News tracker', `Change feed - ${feed.feed.name}`);
		this.sourcesRequest(feed.feed.id);
		this.sourcePostsRequest('All', feed.feed.id, this.props.selectedDate.value, this.props.selectedFilterSources);
		this.props.updateNotifications([]);
		this.props.sourceTabUpdate({ id: 'All', location: '' });
		const selectedFilterSources = helpers.extractTypeFilterOptions(feed.feed.sources);
		this.props.updateSelectedFilterSources(selectedFilterSources);
		this.setState({
			...this.state,
			selectFeedValue: helpers.feedToOption(feed.feed),
			newPosts: [],
			isContentLoading: true,
		});
	};

	onDateFilterChange = (date: any) => this.props.updateSelectedDate(date);

	onTypeFilterChange = (type: any) => this.props.updateSelectedFilterSources(type);

	clearFilters = (source: string, id: string) => {
		const selectedFilterSources = helpers.extractTypeFilterOptions(this.props.selectedFeed.sources);
		this.props.updateSelectedDate(helpers.dateFilterOptions(this.props.t)[0]);
		this.props.updateSelectedFilterSources(selectedFilterSources);
		this.sourcePostsRequest(source, id, helpers.dateFilterOptions(this.props.t)[0].value, selectedFilterSources);
	};

	onSourceChange = (sourceId: string, sourceLocation: string) => {
		const filteredNotifications = this.props.notifications.filter((item: any) => item.sourceLocation !== sourceLocation);
		this.props.updateNotifications(filteredNotifications);
		this.props.sourceTabUpdate({ id: sourceId, location: sourceLocation });
		this.sourcePostsRequest(sourceId, this.props.selectedFeed.id, this.props.selectedDate.value, this.props.selectedFilterSources);
		this.state.collapsed && this.toggleSidebar();
	};

	toggleSidebar = () => {
		analyticsService.sendEvent('Click', 'News tracker', `${!this.state.collapsed ? 'Open' : 'Close'}`);
		this.setState((prevState: any) => ({ ...this.state, collapsed: !prevState.collapsed }));
	};

	render() {
		const { isContentLoading, feeds, collapsed, selectFeedValue, posts, sourcePosts } = this.state;
		const { sourceTab } = this.props;
		const toggleStyle = collapsed && 'shown';
		const loadingStyle = isContentLoading ? 'shadow-sm loading-overlay' : 'shadow-sm ';

		return (
			<div className={`feed-container shadow bg-white ${toggleStyle}`}>
				<Scrollbar className='bg-light' autoHide style={{ width: '70px' }}>
					<Sources
						t={this.props.t}
						selectedFeed={this.props.selectedFeed}
						sourceTab={sourceTab ? sourceTab.id : ''}
						toggleSidebar={this.toggleSidebar}
						onSourceChange={this.onSourceChange}
						collapsed={collapsed}
						notifications={this.props.notifications}
					/>
				</Scrollbar>
				<Scrollbar className={loadingStyle} autoHide>
					<div className='d-flex flex-column w-100 pr-1'>
						<div className='d-flex justufy-center p-2'>
							<Select value={selectFeedValue} options={helpers.remapFeedsToOptions(feeds)} onChange={this.onFeedChange} className='w-100' />
						</div>
						<div className='d-flex flex-column w-100 p-1'>
							<Filters
								t={this.props.t}
								onChangeDate={this.onDateFilterChange}
								onChangeType={this.onTypeFilterChange}
								clearFilters={this.clearFilters}
								sourceTab={sourceTab ? sourceTab.id : ''}
								selectedFeedId={this.props.selectedFeed ? this.props.selectedFeed.id : ''}
								dateFilterOptions={helpers.dateFilterOptions}
								selectedDate={this.props.selectedDate}
								selectedFilterSources={this.props.selectedFilterSources}
								feedSources={helpers.extractTypeFilterOptions(this.props.selectedFeed ? this.props.selectedFeed.sources : [])}
								sourcePostsRequest={this.sourcePostsRequest}
							/>
							<div>
								<Posts posts={sourceTab && sourceTab.id === 'All' ? posts : sourcePosts} t={this.props.t} profile={this.props.profile} />
							</div>
						</div>
					</div>
				</Scrollbar>
				<FacebookRefresh />
			</div>
		);
	}
}

function mapStateToProps(state: any) {
	return {
		selectedFeed: state.feed.selectedFeed,
		notifications: state.feed.notifications,
		selectedDate: state.feed.selectedDate,
		sourceTab: state.feed.sourceTab,
		selectedFilterSources: state.feed.selectedFilterSources,
	};
}

function mapDispatchToProps(dispatch: any) {
	return {
		updateSelectedFeed: (feed: Feed) => dispatch(returnObjectForSelectedFeed(feed)),
		updateSelectedDate: (date: Feed) => dispatch(returnObjectForSelectedDate(date)),
		sourceTabUpdate: (sourceTab: any) => dispatch(returnObjectForSourceTabUpdate(sourceTab)),
		updateSelectedFilterSources: (selectedSources: any) => dispatch(returnObjectForSelectedFilterSources(selectedSources)),
		updateNotifications: (notifications: any[]) => dispatch(returnObjectForUpdateNotifications(notifications)),
	};
}
export default compose(connect(mapStateToProps, mapDispatchToProps), onClickOutside)(SocialFeedContainer);
