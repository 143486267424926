import React from 'react';
import { Col, FormGroup, Row } from 'reactstrap';
import { blockManagementService, blockWrapperService } from '../../../../subcomponents/blocky.component';
import BlockValidation from '../../../../helpers/block-validation.helper';
import ErrorHandler from '../../../../partials/error/error-handler-component';
import SportTeamModel from '../../../../partials/sports-team-select/models/sport-team.model';
import GamesContainer from '../../../../partials/games/games-container';
import BlockModel from '../../../../models/block.model';
import GameWidgetModel from '../models/game-widget.model';
import GameModel from '../../../../../../../models/v2/game/game.model';
import SportOddsWidgetModel from '../../sports-odds/models/sport-odds-widget.model';
import SportOddsWidgetEdit from '../../sports-odds/components/sport-odds-widget-edit.component';
import { featuresService } from '../../../../../../../App';
import { FeatureTypes } from '../../../../../../../services/feature-service/features.enum';
import { SportTypes } from '../../../../../../../constants/sport.types';
import { ContentTypes } from '../../../../../../../constants/content-types';
import SportsEntitySelect from '../../../../partials/sports-team-select/sports-entity-select.component';
import DisplayOddsComponent from '../../../../partials/bookmaker-select/display-odds.component';
import { SPORT_SELECT_ATTRIBUTES } from '../../../../v2/widgets-v2/helpers/component-attributes.constants';
import { createBlock } from '../../../../v2/partials/helpers/blocky-sidebar-communication/blocky-sidebar-communication.helper';
import { WidgetsV2Ids } from '../../../../constants/block.types';
import { onRefreshTimeChangeFromMainComponent } from '../../../../v2/partials/refresh-time-input/helper';
import { SelectMenuOptionType } from '../../../../../../../models/v2/general/select.model';
import RefreshTimeWrapper from '../../../../v2/partials/refresh-time-input/refresh-time-wrapper';
import { MatchStatuses } from '../../../../../../../constants/event.types';

type Properties = {
	block: BlockModel;
	t: any;
	contentData: any;
	contentLanguage: string;
};

type State = {
	widgetModel: GameWidgetModel;
	isValid: boolean;
	oddsModel: SportOddsWidgetModel;
};

export default class BasketballSingleEventWidgetEdit extends React.Component<Properties, State> {
	constructor(props: Properties) {
		super(props);
		this.state = {
			widgetModel: createBlock(
				WidgetsV2Ids.widgetBasketballSingleEvent,
				props.block.data.preview && props.block.data.preview.game,
				props.contentData,
			),
			oddsModel:
				props.block && props.block.data.preview && props.block.data.preview.odds
					? SportOddsWidgetModel.builder(props.block.data.preview.odds).build()
					: SportOddsWidgetModel.builder().withBookmakers(null).build(),
			isValid: true,
		};
	}

	componentDidMount(): void {
		blockWrapperService.registerOnSaveFunc({ blockId: this.props.block.id, func: this.onSave });
	}

	componentWillUnmount(): void {
		blockWrapperService.unregisterOnSaveFunc(this.props.block.id);
	}

	componentDidUpdate(prevProps: Readonly<Properties>, prevState: Readonly<State>, snapshot?: any) {
		if (
			this.state.widgetModel.displayOdds !== prevState.widgetModel.displayOdds &&
			!this.state.widgetModel.displayOdds &&
			this.state.widgetModel.teams.length < 1
		) {
			const emptyOddsModel = SportOddsWidgetModel.builder().withBookmakers(null).build();

			this.setState({ ...this.state, oddsModel: emptyOddsModel });
		}
	}

	onSave = (index: number) => {
		const data = { gameModel: this.state.widgetModel, oddsModel: this.state.oddsModel };
		const validation = new BlockValidation();
		const isValid = validation.validate(this.props.block, data) === true;
		this.setState({ ...this.state, isValid });

		const bookmakerSelectionEnabled = featuresService && featuresService.areFeaturesEnabled([FeatureTypes.ALLOW_BOOKMAKER_SELECTION]);
		const odds = SportOddsWidgetModel.builder(this.state.oddsModel)
			.withBookmakers(bookmakerSelectionEnabled ? this.state.oddsModel.bookmakers : null)
			.build();
		if (isValid) {
			const block = Object.assign({}, this.props.block);
			block.data.preview = { game: this.state.widgetModel, odds };
			blockManagementService.blockUpdate(block, index);
		} else {
			return isValid;
		}
	};

	updateWidgetModelState = (model: GameWidgetModel) => this.setState({ ...this.state, widgetModel: model });

	onTeamChange = (teams: SportTeamModel[]) => {
		this.updateWidgetModelState(
			GameWidgetModel.builder(this.state.widgetModel)
				.withTeams(teams)
				.withGame({} as GameModel)
				.withDisplayOdds(false)
				.build(),
		);
	};

	onGameChange = (game: any) => {
		const displayOdds = game && game.statusType !== MatchStatuses.FINISHED.toUpperCase();
		const widgetModel = GameWidgetModel.builder(this.state.widgetModel).withGame(game).withDisplayOdds(displayOdds).build();
		const oddsModel = SportOddsWidgetModel.builder().withBookmakers(null).build();

		this.setState({
			...this.state,
			isValid: true,
			widgetModel,
			oddsModel,
		});
	};

	onDisplayOddsChange = (e: any) => {
		this.updateWidgetModelState(GameWidgetModel.builder(this.state.widgetModel).withDisplayOdds(e.target.checked).build());
	};

	onOddsWidgetChange = (oddsModel: SportOddsWidgetModel) => {
		this.setState({ ...this.state, oddsModel });
	};

	onRefreshTimeChange = (refreshTimeData: SelectMenuOptionType) => {
		onRefreshTimeChangeFromMainComponent(refreshTimeData, GameWidgetModel, this.state.widgetModel, this.updateWidgetModelState);
	};

	render() {
		const { widgetModel, isValid, oddsModel } = this.state;
		const { t } = this.props;

		return (
			<>
				<Row>
					<Col>
						<FormGroup>
							<SportsEntitySelect
								inputId={`${SportTypes.BASKETBALL}-team-select`}
								isMulti={true}
								t={t}
								value={widgetModel.teams ? widgetModel.teams : []}
								onSelect={(team: SportTeamModel[]) => this.onTeamChange(team)}
								isValid={isValid}
								sport={SportTypes.BASKETBALL}
								entityType={ContentTypes.TEAM}
								isRequired
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					<Col>
						<FormGroup>
							<GamesContainer
								t={t}
								entityIds={
									widgetModel && widgetModel.teams && widgetModel.teams.length > 0
										? [widgetModel.teams.filter((team: any) => team).map((team: any) => team.id)]
										: []
								}
								isMulti={false}
								maxNotStartedGames={20}
								maxFinishedGames={20}
								maxInterruptedGames={20}
								teamNames={
									widgetModel && widgetModel.teams && widgetModel.teams.length > 0
										? widgetModel.teams.filter((team: any) => team).map((team: SportTeamModel) => team.name)
										: ['']
								}
								selectedGameId={widgetModel && widgetModel.game ? widgetModel.game.id : ''}
								onSelect={this.onGameChange}
								sport={SportTypes.BASKETBALL}
							/>
						</FormGroup>
					</Col>
				</Row>
				<hr />
				{widgetModel && widgetModel.game && widgetModel.game.statusType !== 'finished' && (
					<DisplayOddsComponent
						t={t}
						block={this.props.block}
						displayOddsChecked={widgetModel.displayOdds ? widgetModel.displayOdds : false}
						onDisplayOddsChange={this.onDisplayOddsChange}
						id={`${SportTypes.BASKETBALL}-${SPORT_SELECT_ATTRIBUTES.DISPLAY_ODDS_SELECT}`}
					/>
				)}
				{widgetModel && widgetModel.game && widgetModel.game.statusType !== 'finished' && widgetModel.displayOdds && (
					<SportOddsWidgetEdit
						contentData={this.props.contentData}
						block={this.props.block}
						t={this.props.t}
						game={widgetModel && widgetModel.game ? widgetModel.game : oddsModel.game}
						onOddsWidgetChange={this.onOddsWidgetChange}
						displayOddsLabel={true}
						isValid={this.state.isValid}
						contentLanguage={this.props.contentLanguage}
						currentOdds={this.state.oddsModel}
						sport={SportTypes.BASKETBALL}
						isRequired={false}
					/>
				)}
				<hr />
				{!isValid && widgetModel.game && !widgetModel.game.id && (
					<div className='mb-3'>
						<ErrorHandler t={t} errorMessage='no_games_selected' />
					</div>
				)}
				<RefreshTimeWrapper value={this.state.widgetModel.refreshTime} onRefreshTimeChange={this.onRefreshTimeChange} />
			</>
		);
	}
}
