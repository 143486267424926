import React, { FunctionComponent, useState } from 'react';
import { DATA_QA } from '../../../helpers/data-qa-attributes';
import { chevronStyleGenerator } from '../../../helpers/icons';
import CountBubble from '../../../../../Partials/design-components/count-bubble/count-bubble';
import { getSportIcon } from '../../../../../../global-helpers/global-icons.helpers';
import MultisportCompetitionModelWrapper from '../../../models/competition-wrapper.model';
import TournamentSection from './tournament-section';
import { getSportEventsCount } from '../../../helpers/utils';
import { useTranslation } from 'react-i18next';

type Props = {
	sport: string;
	competitions: MultisportCompetitionModelWrapper[];
};

export const SportSection: FunctionComponent<Props> = ({ sport, competitions }) => {
	const { t } = useTranslation();
	const [isSportSectionVisible, setIsSportSectionVisible] = useState(true);
	const sportEventsCount = getSportEventsCount(competitions);
	const toggleSportSection = () => setIsSportSectionVisible(!isSportSectionVisible);

	return (
		<div className={DATA_QA.SPORT_SECTION_EVENTS}>
			<div className={DATA_QA.SPORT_SECTION_HEADER}>
				<div className='sport-logo' style={{ backgroundImage: `url("${getSportIcon(sport)}")` }}></div>
				<span>{sport}</span>
				<CountBubble count={sportEventsCount} title={t('sport_events_count')} />
				<i className={`${chevronStyleGenerator(isSportSectionVisible)} icon-end`} onClick={toggleSportSection} />
			</div>
			<div className={DATA_QA.SPORT_SECTION_TOURNAMENT_DATA_WRAPPER}>
				{isSportSectionVisible &&
					competitions.map((tournament: MultisportCompetitionModelWrapper) => (
						<TournamentSection key={tournament.competition.id} competition={tournament} />
					))}
			</div>
		</div>
	);
};

export default SportSection;
