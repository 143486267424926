import Project from '../../models/project/Project';
import ErrorResponseModel from '../../models/error-response/ErrorResponseModel';
import Related from '../../models/related/Related';
import List from '../../models/list/List';
import PaginationMeta from '../../models/pagination/PaginationMeta';
import Wiki from '../../models/wiki/Wiki';

export const WIKI_PAGES_LISTING_REQUEST = '[ACTION] WIKI_PAGES_LISTING_REQUEST';
export const WIKI_PAGES_LISTING_RECEIVED = '[ACTION] WIKI_PAGES_LISTING_RECEIVED';
export const WIKI_PAGES_LISTING_FAILED = '[ACTION] WIKI_PAGES_LISTING_FAILED';

export const WIKI_ENTITY_RELATED_RECEIVED = '[ACTION] WIKI_ENTITY_RELATED_RECEIVED';
export const WIKI_COPY_RELATED_RECEIVED = '[ACTION] WIKI_COPY_RELATED_RECEIVED';
export const WIKI_ENTITY_RELATED_CREATE_SUCCESS = '[ACTION] WIKI_ENTITY_RELATED_CREATE_SUCCESS';
export const WIKI_ENTITY_RELATED_UPDATE_SUCCESS = '[ACTION] WIKI_ENTITY_RELATED_UPDATE_SUCCESS';

export const WIKI_ENTITY_REQUEST = '[ACTION] WIKI_ENTITY_REQUEST';
export const WIKI_ENTITY_RECEIVED = '[ACTION] WIKI_ENTITY_RECEIVED';
export const WIKI_ENTITY_FAILED = '[ACTION] WIKI_ENTITY_FAILED';

export const WIKI_PAGES_SEARCH = '[ACTION] WIKI_PAGES_SEARCH';
export const WIKI_PAGES_SEARCH_FAILED = '[ACTION] WIKI_PAGES_SEARCH_FAILED';

export const WIKI_ENTITY_CREATE = '[ACTION] WIKI_ENTITY_CREATE';
export const WIKI_ENTITY_CREATE_SUCCESS = '[ACTION] WIKI_ENTITY_CREATE_SUCCESS';
export const WIKI_ENTITY_CREATE_FAILED = '[ACTION] WIKI_ENTITY_CREATE_FAILED';

export const WIKI_ENTITY_UPDATE = '[ACTION] WIKI_ENTITY_UPDATE';
export const WIKI_ENTITY_UPDATE_FAILED = '[ACTION] WIKI_ENTITY_UPDATE_FAILED';
export const WIKI_ENTITY_UPDATE_SUCCESS = '[ACTION] WIKI_ENTITY_UPDATE_SUCCESS';

export const WIKI_PAGES_ENTITY_DELETE = '[ACTION] WIKI_PAGES_ENTITY_DELETE';
export const WIKI_PAGES_ENTITY_DELETE_SUCCESS = '[ACTION] WIKI_PAGES_ENTITY_DELETE_SUCCESS';
export const WIKI_PAGES_ENTITY_DELETE_FAILED = '[ACTION] WIKI_PAGES_ENTITY_DELETE_FAILED';

export const WIKI_CONTENT_STATISTICS_RECEIVED = '[ACTION] WIKI_CONTENT_STATISTICS_RECEIVED';
export const CONTENT_STATISTICS_WIKI_ENTITY_RECEIVED = '[ACTION] CONTENT_STATISTICS_WIKI_ENTITY_RECEIVED';

export const WIKI_PAGES_PAGINATION_UPDATE = '[ACTION] WIKI_PAGES_PAGINATION_UPDATE';

export const WIKI_PAGES_FILTERS_UPDATE = '[ACTION] WIKI_PAGES_FILTERS_UPDATE';

export const WIKI_ALREADY_EXISTS = '[ACTION] WIKI_ALREADY_EXISTS';
export const WIKI_RELATED_RECEIVED = '[ACTION] WIKI_RELATED_RECEIVED';

export const WIKI_CREATE_RESOURCES_SUCCESS = '[ACTION] WIKI_CREATE_RESOURCES_SUCCESS';

export function returnObjectForWikiListingRequest(page: string, project: Project, text: string) {
	return {
		type: WIKI_PAGES_LISTING_REQUEST,
		payload: { page, project, text },
	};
}

export function returnObjectForWikiPaginationUpdate(pagination: PaginationMeta) {
	return {
		type: WIKI_PAGES_PAGINATION_UPDATE,
		payload: { pagination },
	};
}

export function returnObjectForWikiListingReceived(wikiPages: any) {
	return {
		type: WIKI_PAGES_LISTING_RECEIVED,
		payload: wikiPages,
	};
}

export function returnObjectForWikiListingFailed(error: any) {
	return {
		type: WIKI_PAGES_LISTING_FAILED,
		payload: ErrorResponseModel.builder().fromResponse(error).build(),
	};
}

export function returnObjectForWikiEntityCreate(wiki: Wiki, related: Related[], project: Project, list: null | List = null) {
	return {
		type: WIKI_ENTITY_CREATE,
		payload: { wiki, related, project, list },
	};
}

export function returnObjectForWikiLEntityCreateSuccess(id: string) {
	return {
		type: WIKI_ENTITY_CREATE_SUCCESS,
		payload: id,
	};
}

export function returnObjectForWikiLEntityRelatedCreateSuccess(id: string) {
	return {
		type: WIKI_ENTITY_RELATED_CREATE_SUCCESS,
		payload: id,
	};
}

export function returnObjectForWikiLEntityUpdate(wiki: Wiki, related: Related[], project: Project) {
	return {
		type: WIKI_ENTITY_UPDATE,
		payload: { wiki, related, project },
	};
}

export function returnObjectForWikiLEntityUpdateSuccess() {
	return {
		type: WIKI_ENTITY_UPDATE_SUCCESS,
	};
}
export function returnObjectForWikiLEntityUpdateFailed() {
	return {
		type: WIKI_ENTITY_UPDATE_FAILED,
	};
}

export function returnObjectForWikiLEntityRelatedUpdateSuccess() {
	return {
		type: WIKI_ENTITY_RELATED_UPDATE_SUCCESS,
	};
}

export function returnObjectForWikiLEntityCreateFailed() {
	return {
		type: WIKI_ENTITY_CREATE_FAILED,
	};
}

export function returnObjectForWikiLEntityRequest(id: string, project: Project) {
	return {
		type: WIKI_ENTITY_REQUEST,
		payload: { id, project },
	};
}

export function returnObjectForWikiLEntityReceived(wiki: any) {
	return {
		type: WIKI_ENTITY_RECEIVED,
		payload: wiki,
	};
}

export function returnObjectForWikiLEntityRelatedReceived(related: any, sports: any) {
	return {
		type: WIKI_ENTITY_RELATED_RECEIVED,
		payload: { related, sports },
	};
}

export function wikiCopyRelatedReceived(related: any, sports: any) {
	return {
		type: WIKI_COPY_RELATED_RECEIVED,
		payload: { related, sports },
	};
}

export function returnObjectForWikiSearch(text: string, project: Project) {
	return {
		type: WIKI_PAGES_SEARCH,
		payload: { text, project },
	};
}

export function returnObjectForWikiSearchFailed(error: any) {
	return {
		type: WIKI_PAGES_SEARCH_FAILED,
		payload: ErrorResponseModel.builder().fromResponse(error).build(),
	};
}

export function returnObjectForWikiDelete(id: string, project: Project) {
	return {
		type: WIKI_PAGES_ENTITY_DELETE,
		payload: { id, project },
	};
}

export function returnObjectForWikiDeleteSuccess() {
	return {
		type: WIKI_PAGES_ENTITY_DELETE_SUCCESS,
	};
}

export function returnObjectForWikiDeleteFailed() {
	return {
		type: WIKI_PAGES_ENTITY_DELETE_FAILED,
	};
}

export function returnObjectForWikiContentStatisticsReceived(wikiPages: Wiki[], contentStatistics: any) {
	return {
		type: WIKI_CONTENT_STATISTICS_RECEIVED,
		payload: { wikiPages, contentStatistics },
	};
}

export function returnObjectForContentStatisticsWikiEntityReceived(wiki: Wiki, contentStatistics: any) {
	return {
		type: CONTENT_STATISTICS_WIKI_ENTITY_RECEIVED,
		payload: { wiki, contentStatistics },
	};
}

export function returnObjectForWikiFiltersUpdate(filters: any) {
	return {
		type: WIKI_PAGES_FILTERS_UPDATE,
		payload: { filters },
	};
}

export function returnObjectForWikiAlreadyExists() {
	return {
		type: WIKI_ALREADY_EXISTS,
	};
}

export function triggerWikiCreateResourcesSuccess(id: number) {
	return {
		type: WIKI_CREATE_RESOURCES_SUCCESS,
		payload: id,
	};
}
