import React, { FunctionComponent } from 'react';
import { CountrySelect } from './country-select';
import InputDesign from '../input/input';
import { ITelephone } from '../../../Pages/CustomEntities/models/models';
import './phone.scss';

type Props = {
	invokeFunc: (phoneData: ITelephone) => void;
	fieldId: string;
	labelText?: string;
	placeholderText?: string;
	phoneData: ITelephone | null;
};

const PhoneInputDesign: FunctionComponent<Props> = ({ invokeFunc, fieldId, phoneData, labelText, placeholderText }) => {
	const onCountryChange = (country: ITelephone) => country && invokeFunc({ ...phoneData, ...country });
	const onNumberChange = (number: string) =>
		phoneData ? invokeFunc({ ...phoneData, number }) : invokeFunc({ country_code: '', country_iso_code: '', number });

	return (
		<div className='custom-phone-input-wrapper'>
			{labelText && <label htmlFor={fieldId}> {labelText}</label>}
			<div className='custom-phone-inputs-holder'>
				<CountrySelect selectedIsoCode={phoneData ? phoneData.country_iso_code : ''} invokeFunc={onCountryChange} />
				<InputDesign
					invokeFunc={onNumberChange}
					fieldId={fieldId}
					placeholderText={placeholderText}
					value={phoneData ? phoneData.number : ''}
				/>
			</div>
		</div>
	);
};

export default PhoneInputDesign;
