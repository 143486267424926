import * as React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import { DebounceInput } from 'react-debounce-input';
import { Properties, State } from './properties/SeoTitleProps';

export default class SeoTitle extends React.Component<Properties, State> {
	constructor(props: Properties) {
		super(props);
		this.state = {
			title: props.value,
		};
	}

	onSeoTitleChange = (event: any) => {
		this.props.onTitleChange(event.target.value);
	};

	onAutoTitleChange = (event: any) => {
		this.props.onAutoTitleChange(event.target.checked);
	};

	render() {
		const { t, value, isAutoTitle } = this.props;

		return (
			<>
				<FormGroup className={'mb-1'}>
					<Label htmlFor='seo_title'>{t('seo_title')}</Label>
					<DebounceInput
						id='seo_title'
						placeholder={t('seo_title_placeholder')}
						rows='2'
						className='form-control-lg form-control sm-text'
						debounceTimeout={300}
						disabled={isAutoTitle}
						value={value ? value : ''}
						onChange={this.onSeoTitleChange}
					/>
				</FormGroup>
				<FormGroup check inline className={'mb-3'}>
					<Input
						className='form-check-input'
						type='checkbox'
						id='generate-seo-title-automatically'
						name='generate-seo-title-automatically'
						checked={isAutoTitle}
						onChange={this.onAutoTitleChange}
						value={value ? value : ''}
					/>
					<Label className='form-check-label' check htmlFor='generate-seo-title-automatically'>
						{t('generate_auto_seo_title_from_title')}
					</Label>
				</FormGroup>
			</>
		);
	}
}
