import Related from '../../../../../models/related/Related';
import PaginationMeta from '../../../../../models/v2/Pagination/PaginationModel';
import { availableContentTypes, extractContentTypePluralNaming } from '../../../../../services/content-models-service/ContentModelService';
import HttpService from '../../../../../services/rest/HttpService';
import { store } from '../../../../../store/store';
import { formatResponseAsMetaResource, formatResponseAsRelatedResource } from './general.helper';

type SearchedRelatedResources = {
	related: Related[];
	pagination: PaginationMeta;
};

const formatListResponseResult = (resp: any): SearchedRelatedResources => {
	return {
		related: formatResponseAsRelatedResource(resp),
		pagination: formatResponseAsMetaResource(resp),
	};
};

const defaultFormatListResponse = Promise.resolve({ related: [], pagination: new PaginationMeta() });

export const generateRelatedResourcesRequest = (
	searchedContentType: availableContentTypes,
	header: any,
	language: string = 'en',
	page: number = 1,
	searchedText: string = '*',
	suggestedQuery: string = '',
) => {
	const pageQuery = page === 0 ? 1 : page;
	const contentTypePluralNaming = extractContentTypePluralNaming(searchedContentType);
	const languageQuery =
		store.getState().project.currentProject.languages && store.getState().project.currentProject.languages.multiple
			? `&language=${language}`
			: '';
	return HttpService.get(
		`/v2/${contentTypePluralNaming}/search?page=${pageQuery}${suggestedQuery}${languageQuery}&query=${searchedText}`,
		null,
		header,
	).then((resp: any) => formatListResponseResult(resp));
};

export const requestSuggestedRelatedResources = (
	searchedContentType: availableContentTypes,
	suggestedQuery: string,
	header: any,
	language: string = 'en',
	page: number = 1,
): Promise<SearchedRelatedResources> => {
	return suggestedQuery
		? generateRelatedResourcesRequest(searchedContentType, header, language, page, '*', suggestedQuery)
		: defaultFormatListResponse;
};

export const requestSearchedRelatedResources = (
	searchedContentType: availableContentTypes,
	searchedText: string,
	header: any,
	language: string = 'en',
	page: number = 1,
): Promise<SearchedRelatedResources> => {
	return searchedText
		? generateRelatedResourcesRequest(searchedContentType, header, language, page, searchedText)
		: defaultFormatListResponse;
};
