import { PaginationMetaJson } from './PaginationMetaJson';
import PaginationMeta from './PaginationMeta';

export default class PaginationMetaBuilder {
	private json: PaginationMetaJson;

	constructor(pagionationMeta?: PaginationMeta) {
		this.json = pagionationMeta ? pagionationMeta.toJSON() : <PaginationMetaJson>{};
	}

	withCount(count: number): PaginationMetaBuilder {
		this.json.count = count;

		return this;
	}

	withCurrentPage(currentPage: number): PaginationMetaBuilder {
		this.json.currentPage = currentPage;

		return this;
	}

	withPerPage(perPage: number): PaginationMetaBuilder {
		this.json.perPage = perPage;

		return this;
	}

	withTotal(total: number): PaginationMetaBuilder {
		this.json.total = total;

		return this;
	}

	withTotalPages(totalPages: number): PaginationMetaBuilder {
		this.json.totalPages = totalPages;

		return this;
	}

	withLimit(limit: number): PaginationMetaBuilder {
		this.json.limit = limit;

		return this;
	}

	withOffset(offset: number): PaginationMetaBuilder {
		this.json.offset = offset;

		return this;
	}

	build(): PaginationMeta {
		return PaginationMeta.fromJSON(this.json);
	}
}
