import { featuresService } from '../../../../../App';
import { ContentTypes } from '../../../../../constants/content-types';
import MainMediaModel from '../../../../../models/v2/main-media/main-media.model';
import { FeatureTypes } from '../../../../../services/feature-service/features.enum';
import {
	MainMediaSelectMenuOptions,
	MainMediaSelectOptionsValue,
	embedType,
	embedVideoAsSelectOption,
	galleryAsSelectOption,
	mainMediaSelectOptions,
	videoAsSelectOption,
	mainMediaLiveBlogSelectOptions,
} from '../constants/general';

export const generateOptionsWithFilteredFeatures = (t: any) => {
	return mainMediaSelectOptions(t).filter((option) => {
		if (option.value === MainMediaSelectOptionsValue.EMBED_VIDEO || option.value === MainMediaSelectOptionsValue.MEDIA_VIDEO) {
			return featuresService.areFeaturesEnabled([FeatureTypes.VIDEO]);
		}

		return true;
	});
};

export const removeOptionsForVideoContentType = (options: MainMediaSelectMenuOptions[]) => {
	return options.filter((opt) => opt.value === MainMediaSelectOptionsValue.EMBED_VIDEO);
};

export const generateOptionsWithLiveBlogFilteredFeatures = (t: any) => {
	return mainMediaLiveBlogSelectOptions(t).filter((option) => {
		if (option.value === MainMediaSelectOptionsValue.EMBED_VIDEO || option.value === MainMediaSelectOptionsValue.MEDIA_VIDEO) {
			return featuresService.areFeaturesEnabled([FeatureTypes.VIDEO]);
		}

		return true;
	});
};

export const remapOptionToMainMediaModel = (menuOption: MainMediaSelectMenuOptions): MainMediaModel => {
	const mainMediaStarterModel = new MainMediaModel();
	mainMediaStarterModel.resourceType = menuOption.type;
	mainMediaStarterModel.resourceSubtype = menuOption.subType;
	return mainMediaStarterModel;
};

export const isVideo = (resource: MainMediaModel) => resource.resourceType === ContentTypes.VIDEO && resource.resourceSubtype === null;
export const isGallery = (resource: MainMediaModel) => resource.resourceType === ContentTypes.GALLERY && resource.resourceSubtype === null;
export const isEmbedVideo = (resource: MainMediaModel) =>
	resource.resourceType === embedType && resource.resourceSubtype === ContentTypes.VIDEO;

export const isValidResourceType = (resource: MainMediaModel) => isVideo(resource) || isGallery(resource) || isEmbedVideo(resource);

export const isTypeAlreadyExist = (alreadySelected: MainMediaSelectMenuOptions[], selectedEl: MainMediaSelectMenuOptions) => {
	return alreadySelected.findIndex((el) => el.type === selectedEl.type && el.subType === selectedEl.subType) >= 0;
};

export const externalMediaOptionsToMainModel = (options: MainMediaSelectMenuOptions[], mainMediaRedux: MainMediaModel[]) => {
	return options.map((option) => {
		const populatedItemInRedux =
			mainMediaRedux.find((mainMedia) => mainMedia.resourceType === option.type && mainMedia.resourceSubtype === option.subType) || null;
		return populatedItemInRedux ? populatedItemInRedux : remapOptionToMainMediaModel(option);
	});
};

export const mainModelToExternalOptions = (mainMediaRedux: MainMediaModel[], t: any): MainMediaSelectMenuOptions[] => {
	return mainMediaRedux
		.filter((mainMedia) => isValidResourceType(mainMedia))
		.map((el) => {
			if (isVideo(el)) return videoAsSelectOption(t);
			if (isGallery(el)) return galleryAsSelectOption(t);
			return embedVideoAsSelectOption(t);
		});
};

export const findEmbedVideoCode = (mainMediaRedux: MainMediaModel[]): string => {
	const embedVideoEl = mainMediaRedux.find((el) => isEmbedVideo(el)) || null;
	if (embedVideoEl && embedVideoEl.data && embedVideoEl.data.embedCode) {
		return embedVideoEl.data.embedCode;
	} else if (embedVideoEl && embedVideoEl.data && embedVideoEl.data.embed_code) {
		return embedVideoEl.data.embed_code;
	}

	return '';
};
