import { BlockModelJson } from './block.json';
import BlockModelBuilder from './block-model.builder';

export default class BlockModel {
	readonly data: any = {};
	readonly type: string = '';
	readonly id: string = '';

	constructor(id: string, data: any, type: string) {
		this.id = id;
		this.data = data;
		this.type = type;
	}

	toJson(): BlockModelJson {
		return {
			id: this.id,
			data: this.data,
			type: this.type,
		};
	}

	static fromJson(json: BlockModelJson): BlockModel {
		return new BlockModel(json.id, json.data, json.type);
	}

	static builder(block?: BlockModel): BlockModelBuilder {
		return new BlockModelBuilder(block);
	}
}
