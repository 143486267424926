import FeatureService from '../../../../services/feature-service/feature.service';
import AuditLogFeatureValidation from './audit-log-feature.validation';
import HttpService from '../../../../services/rest/HttpService';
import AuditLogMapper from '../../../../models/v2/audit-log/audit-log.mapper';
import PaginationMeta from '../../../../models/pagination/PaginationMeta';
import ModelMapper from '../../../../models/ModelMapper';
import { store } from '../../../../store/store';
import { ReactPaginateProps } from 'react-paginate';
import { ContentTypes } from '../../../../constants/content-types';

type UrlConfig = {
	adminId: string;
	contentType: string;
	action: string;
	from: string;
	to: string;
};

type contentUrlConfig = {
	contentType: string;
	contentId: string;
	from: string;
	to: string;
};

export const PaginationProps = (t: any, currentPage: number): ReactPaginateProps => {
	return {
		previousLabel: t('previous'),
		nextLabel: t('next'),
		breakLabel: '...',
		pageCount: 0,
		breakClassName: 'break-me',
		marginPagesDisplayed: 2,
		pageRangeDisplayed: 1,
		containerClassName: 'smp-pagination',
		activeClassName: 'active',
		forcePage: currentPage - 1,
	};
};

export default class AuditLogService {
	public pagination: PaginationMeta = {} as PaginationMeta;
	/**
   +   * Performs a check if the feature audit_log is enabled and if the current user has permissions to view
   +   * the audit log feature
   +   * @param featureService
   +   * @param userId
   +   */
	public isFeatureEnabled = (featureService: FeatureService, userId: string) => {
		const featureValidation = new AuditLogFeatureValidation();
		return featureValidation.isFeatureEnabled(featureService, userId);
	};

	public convertContentType = (type: string) => {
		if (type === 'article') {
			return 'Article';
		} else if (type === 'video') {
			return 'Video';
		} else if (type === 'gallery') {
			return 'Gallery';
		} else if (type === ContentTypes.WIKI_PAGE) {
			return 'WikiPage';
		}

		return '';
	};

	public checkIfActionTimeIsObject = (actionTime: any) => {
		return typeof actionTime === 'object';
	};

	public setBadgeColor = (action: string) => {
		if (action === 'update') {
			return 'bg-primary';
		} else if (action === 'create') {
			return 'bg-success';
		} else {
			return 'bg-danger';
		}
	};

	public responseToMetaPagination = (response: any): PaginationMeta => {
		return ModelMapper.remapMetaPagination(response);
	};

	public constructAuditLogContentGetUrl = (config: contentUrlConfig, page: string) => {
		const { contentType, contentId, from, to } = config;

		return `/action/logs/content?content_type=${contentType}${from}${to}&content_id=${contentId}&page=${page}`;
	};

	public constructAuditLogGetUrl = (config: UrlConfig, page: string) => {
		const { adminId, contentType, action, from, to } = config;
		let urlResult = `/action/logs${adminId ? '/' : '?'}`;

		if (adminId) {
			urlResult += `admin?admin_id=${adminId}&`;
		}

		if (action) {
			urlResult += `action=${action}&`;
		}

		if (contentType) {
			urlResult += `content_type=${contentType}`;
		}

		urlResult += `${from}${to}&page=${page}`;
		return urlResult;
	};

	public getActionLogForContent = async (id: string, type: string, from?: string, to?: string, page?: string): Promise<any> => {
		const project = store.getState().project.currentProject.domain;
		const headers = { Project: project };
		const mapper = new AuditLogMapper();

		page = page ? page : '1';
		from = from ? from : '';
		to = to ? to : '';

		const actionLog = await HttpService.instance(headers).get(
			this.constructAuditLogContentGetUrl(
				{
					contentType: type,
					contentId: id,
					from: from ? `&from=${from}` : '',
					to: to ? `&to=${to}` : '',
				},
				page,
			),
		);

		if (actionLog.data && actionLog.data.meta && actionLog.data.meta.pagination) {
			this.pagination = this.responseToMetaPagination(actionLog.data.meta.pagination);
		}

		if (actionLog.data && actionLog.data.data && actionLog.data.data.length > 0) {
			return actionLog.data.data.map((action: any) => mapper.responseToModel(action));
		} else {
			return [];
		}
	};

	public getActionLogForAdmin = async (
		adminId: string,
		action?: string,
		contentType?: string,
		from?: string,
		to?: string,
		page?: string,
	): Promise<any> => {
		const project = store.getState().project.currentProject.domain;
		const headers = { Project: project };
		const mapper = new AuditLogMapper();

		page = page ? page : '1';
		from = from ? from : '';
		to = to ? to : '';

		const actionLog = await HttpService.instance(headers).get(
			this.constructAuditLogGetUrl(
				{
					adminId: adminId ? adminId : '',
					contentType: contentType ? contentType : '',
					action: action ? action : '',
					from: from ? `&from=${from}` : '',
					to: to ? `&to=${to}` : '',
				},
				page,
			),
		);
		if (actionLog.data && actionLog.data.meta && actionLog.data.meta.pagination) {
			this.pagination = this.responseToMetaPagination(actionLog.data.meta.pagination);
		}

		if (actionLog.data && actionLog.data.data && actionLog.data.data.length > 0) {
			return actionLog.data.data.map((action: any) => mapper.responseToModel(action));
		} else {
			return [];
		}
	};
}
