import React from 'react';
import { Row, Col, Input } from 'reactstrap';
import PlayerModel from '../../../Partials/Blocky/partials/player-select/models/player.model';
import { updateTranslationName, mapMultipleTranslations } from '../helpers/translations.helpers';
import { entityTypeEnum } from '../helpers/translations.constants';
import EnumItem from '../../../../models/enum/EnumItem';

type Properties = {
	t: any;
	language: EnumItem;
	teamPlayers: PlayerModel[];
	translationChange: (translations: any[]) => void;
	translations: any[];
};

export default class TeamPlayersTranslationsContainer extends React.Component<Properties> {
	constructor(props: Properties) {
		super(props);
		this.state = {
			translations: mapMultipleTranslations(props.teamPlayers, props.language, entityTypeEnum.PLAYER),
		};
	}

	onTranslationChange = (e: any, index: number) => {
		const translations = updateTranslationName(this.props.translations, index, e);
		this.props.translationChange(translations);
	};

	render() {
		const { t, teamPlayers, translations } = this.props;

		return (
			<>
				{teamPlayers.map((player: PlayerModel, index: number) => {
					return (
						<Row key={player.id} className='mb-3'>
							<Col xs='6'>
								<Input disabled value={player.name} />
							</Col>
							<Col xs='6'>
								<Input
									placeholder={t('translate')}
									value={translations[index] && translations[index].content ? translations[index].content.name : ''}
									onChange={(e: any) => this.onTranslationChange(e, index)}
								/>
							</Col>
						</Row>
					);
				})}
			</>
		);
	}
}
