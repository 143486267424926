import React from 'react';
import { Button } from 'reactstrap';
import { ConditionalRenderContext } from '../../Partials/BaseComponents/ConditionalRenderWraper/ConditionalRenderWrapper';

type Properties = {
	imageId: string;
	onEdit: (imageId: string) => any;
	onCrop: (imageId: string) => any;
};

class ImageEditAndCropButtons extends React.Component<Properties> {
	render() {
		const { imageId, onEdit, onCrop } = this.props;
		return (
			<ConditionalRenderContext.Consumer>
				{(value) => {
					return (
						value.hasReadPermission && (
							<>
								<Button id={`image-edit-btn-${imageId}`} block color='warning' className='p-0 py-1 m-0' onClick={() => onEdit(imageId)}>
									<i className='fa fa-pencil'> </i>
								</Button>
								<Button id={`image-crop-btn-${imageId}`} block color='secondary' className='p-0 py-1 m-0' onClick={() => onCrop(imageId)}>
									<i className={'fa fa-crop'}> </i>
								</Button>
							</>
						)
					);
				}}
			</ConditionalRenderContext.Consumer>
		);
	}
}

export default ImageEditAndCropButtons;
