import { ListItemMetaJson } from './ListItemMetaJson';
import ListItemMetaBuilder from './ListItemMetaBuilder';

export default class ListItemMeta {
	readonly title: string;
	readonly subtitle: string;
	readonly imageId: string;
	readonly from?: string;
	readonly to?: string;

	constructor(title: string, subtitle: string, imageId: string, from?: string, to?: string) {
		this.title = title;
		this.subtitle = subtitle;
		this.imageId = imageId;
		this.from = from;
		this.to = to;
	}

	toJSON(): ListItemMetaJson {
		return {
			title: this.title,
			subtitle: this.subtitle,
			imageId: this.imageId,
			from: this.from,
			to: this.to,
		};
	}

	static fromJSON(json: ListItemMetaJson): ListItemMeta {
		return new ListItemMeta(json.title, json.subtitle, json.imageId, json.from, json.to);
	}

	static builder(list?: ListItemMeta): ListItemMetaBuilder {
		return new ListItemMetaBuilder(list);
	}
}
