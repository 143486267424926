import { Button, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import React, { useState } from 'react';
import { DATA_QA_ATTRIBUTES } from '../../../../../Partials/Sidebar/general-content/constants/data-qa.attributes';
import LiveBlogCollaboratorsModel from '../../../models/live-blog-collaborators.model';
import Select from 'react-select';
import { authorsToOptions, returnMappedAuthors } from '../../../helpers/authors-select.helper';
import { customOption } from '../../../../../Partials/Shared/custom-select-options/custom-select-option';
import AuthorModel from '../../../../../../models/v2/author/author.model';
import AdditionalInfoModel from '../../../models/additional-info.model';

type Properties = {
	t: any;
	isOpen: boolean;
	toggleModal: (isOpen: boolean) => any;
	pickNewCollaborator: Function;
	collaboratorOptions: AuthorModel[];
	initialAdd: boolean;
	selectedCollaboratorForEdit: LiveBlogCollaboratorsModel;
	removeSelectedCollaborator: Function;
};

export const CollaboratorsModal: React.FunctionComponent<Properties> = ({
	t,
	collaboratorOptions,
	toggleModal,
	isOpen,
	pickNewCollaborator,
	selectedCollaboratorForEdit,
	initialAdd,
	removeSelectedCollaborator,
}) => {
	const [selectedCollaborator, setSelectedCollaborator] = useState(initialAdd ? ({} as AuthorModel) : selectedCollaboratorForEdit.author);
	const [collaboratorAdditionalInfo, setCollaboratorAdditionalInfo] = useState<AdditionalInfoModel>(
		initialAdd
			? ({ description: '' } as AdditionalInfoModel)
			: selectedCollaboratorForEdit.additional_info
			? selectedCollaboratorForEdit.additional_info
			: ({ description: '' } as AdditionalInfoModel),
	);
	const [selectedCollaboratorModel, setSelectedCollaboratorModel] = useState(
		initialAdd ? ({} as LiveBlogCollaboratorsModel) : selectedCollaboratorForEdit,
	);

	const onCollaboratorSelect = (data: any) => {
		if (data && data.data && data.data.id) {
			setSelectedCollaborator(data.data);
			setSelectedCollaboratorModel({ author: data.data, additional_info: new AdditionalInfoModel() });
		} else {
			setSelectedCollaborator({} as AuthorModel);
			setSelectedCollaboratorModel({ author: {} as AuthorModel, additional_info: new AdditionalInfoModel() });
		}
	};

	const onCollaboratorInfoSelect = (input: any) => {
		setCollaboratorAdditionalInfo({ description: input });
		setSelectedCollaboratorModel({ author: selectedCollaborator, additional_info: { description: input } });
	};

	const isInEditMode =
		selectedCollaboratorForEdit.author && selectedCollaboratorForEdit.author.id && selectedCollaboratorForEdit.author.id.length > 0;

	return (
		<div data-qa={DATA_QA_ATTRIBUTES.LIVE_BLOG_COLLABORATORS_MODAL}>
			<Modal isOpen={isOpen}>
				<ModalHeader
					toggle={() => {
						removeSelectedCollaborator();
						toggleModal(false);
					}}
				>
					{t('add_collaborator')}
				</ModalHeader>
				<ModalBody>
					<div data-children='.item'>
						<div className='item'>
							<Label htmlFor={DATA_QA_ATTRIBUTES.LIVE_BLOG_AUTHORS}>
								<strong>{t('author')}</strong>
							</Label>
							<span className='text-danger'>*</span>
							<Select
								id={DATA_QA_ATTRIBUTES.LIVE_BLOG_COLLABORATORS}
								value={returnMappedAuthors(selectedCollaborator)}
								options={authorsToOptions(collaboratorOptions)}
								onChange={(selections: any) => onCollaboratorSelect(selections)}
								isClearable={!!(selectedCollaborator && selectedCollaborator.id)}
								formatOptionLabel={customOption}
								placeholder={t('select')}
								noOptionsMessage={(inputValue) => inputValue && t('no_options')}
								isDisabled={!!isInEditMode}
							/>
						</div>
					</div>
					<div data-children='.item'>
						<div className='item'>
							<Label className='mt-2' htmlFor={DATA_QA_ATTRIBUTES.LIVE_BLOG_COLLABORATORS_ADDITIONAL_INFO}>
								<strong>{t('additional_info')}</strong>
							</Label>
							<Input
								type='textarea'
								rows={2}
								id={DATA_QA_ATTRIBUTES.LIVE_BLOG_COLLABORATORS_ADDITIONAL_INFO}
								value={collaboratorAdditionalInfo.description ? collaboratorAdditionalInfo.description : ''}
								onChange={(e: any) => onCollaboratorInfoSelect(e.target.value)}
								placeholder={t('select')}
							/>
						</div>
					</div>
				</ModalBody>
				<ModalFooter>
					<Button
						id={DATA_QA_ATTRIBUTES.LIVE_BLOG_COLLABORATORS_MODAL_CLOSE}
						color='danger'
						className='text-light'
						onClick={() => {
							removeSelectedCollaborator();
							toggleModal(false);
						}}
					>
						{t('close')}
					</Button>
					<Button
						disabled={!selectedCollaborator || !selectedCollaborator.id}
						id={DATA_QA_ATTRIBUTES.LIVE_BLOG_COLLABORATORS_MODAL_ADD}
						color='primary'
						className='text-light'
						onClick={() => {
							pickNewCollaborator(selectedCollaboratorModel);
							toggleModal(false);
						}}
					>
						{t('add')}
					</Button>
				</ModalFooter>
			</Modal>
		</div>
	);
};
