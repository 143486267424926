import EmbedBlockBuilder from './embed-block.builder';
import { EmbedBlockJson } from './embed-block.json';
import { ValidationTypeJson } from './embed-block-validation-type.json';

export default class EmbedBlockModel {
	readonly type: string;
	readonly content: string;
	readonly validationType: ValidationTypeJson;

	private constructor(type: string, content: string, validationType: ValidationTypeJson) {
		this.type = type;
		this.content = content;
		this.validationType = validationType;
	}

	toJson(): EmbedBlockJson {
		return {
			type: this.type,
			content: this.content,
			validationType: this.validationType,
		};
	}

	static fromJson(json: EmbedBlockJson): EmbedBlockModel {
		return new EmbedBlockModel(json.type, json.content, json.validationType);
	}
	static builder(embed?: EmbedBlockJson): EmbedBlockBuilder {
		return new EmbedBlockBuilder(embed);
	}
}
