import {
	USER_STATISTICS_CONTENT_RECEIVED,
	ALL_STATISTICS_CONTENT_RECEIVED,
	PLAYER_STATISTICS_RECEIVED,
	CATEGORY_RELATED_STATISTICS_RECEIVED,
	TEAM_STATISTICS_RECEIVED,
} from '../action-creators/statistics-creator';
import ModelMapper from '../../models/ModelMapper';

export function statisticsMiddleware() {
	return function (next: any) {
		return function (action: any) {
			if (action) {
				if (action.type === USER_STATISTICS_CONTENT_RECEIVED) {
					let resourceType = action.payload.resourceType;
					action.payload.userStatistic.data = ModelMapper.remapStatisticsFromResponse(action.payload.userStatistic.data, resourceType);
				}

				if (action.type === ALL_STATISTICS_CONTENT_RECEIVED) {
					let resourceType = action.payload.resourceType;
					action.payload.allStatistic.data = ModelMapper.remapStatisticsFromResponse(action.payload.allStatistic.data, resourceType);
				}

				if (action.type === PLAYER_STATISTICS_RECEIVED) {
					let resourceType = action.payload.resourceType;
					action.payload.playerStatistic.data = ModelMapper.remapStatisticsFromResponse(action.payload.playerStatistic.data, resourceType);
				}

				if (action.type === TEAM_STATISTICS_RECEIVED) {
					let resourceType = action.payload.resourceType;
					action.payload.teamStatistic.data = ModelMapper.remapStatisticsFromResponse(action.payload.teamStatistic.data, resourceType);
				}

				if (action.type === CATEGORY_RELATED_STATISTICS_RECEIVED) {
					let resourceType = action.payload.resourceType;
					action.payload.categoryStatistic.data = ModelMapper.remapStatisticsFromResponse(action.payload.categoryStatistic.data, resourceType);
				}
			}

			next(action);
		};
	};
}
