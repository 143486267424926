export const contentToOption = (content: any) => {
	if (content) {
		return {
			value: content.value,
			label: content.label,
			data: content,
		};
	}

	return {};
};

export function selectedItemsToOptions(values: any[]) {
	let selectedItems: any[] = [];

	if (values && values.length > 0) {
		selectedItems = values.map((value: any[]) => contentToOption(value));
	}

	return selectedItems;
}
