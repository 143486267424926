import React, { Component } from 'react';
import { connect } from 'react-redux';
import arrayMove from 'array-move';
import BlockManagementService, { BlockAddOrUpdate } from '../services/block-management.service';
import {
	blocksCopiedToState,
	setBlocksToCopy,
	updateBlockyUndoRedo,
	updateBlockyUndoRedoPresent,
} from '../../../../store/action-creators/BlockyActionCreator';
import { copyToClipboard, pasteFromClipboard } from './helpers/copy-paste-blocks.helper';
import BlockWrapperService from '../services/block-wrapper.service';
import { Subscription } from 'rxjs';
import BlockModel from '../models/block.model';
import * as ReactSortableHOC from 'react-sortable-hoc';
import { SortEnd } from 'react-sortable-hoc';
import BlockySortable from './blocky-sortable.component';
import { Properties } from './properties/blocky.component.properties';
import AutoTagService from '../services/auto-tag.service';
import Global from '../helpers/global.helpers';
import { ContentTypes } from '../constants/block.types';
import EmbedBlockModel from '../blocks/content/embed-block/models/embed-block.model';
import { customBlocksService, featuresService } from '../../../../App';
import { clearCustomBlocks, extractCustomBlocks } from '../constants/block-list.constants';
import UndoRedoBlockyModel from '../models/UndoRedoBlockyMode';
import { FeatureTypes } from '../../../../services/feature-service/features.enum';
import { updateBlockWithUndoRedo } from './helpers/blocky-helper';
import { getDefaultType, validationOptions } from '../blocks/content/embed-block/helpers/embed-block-edit.helper';

const BlockySortableList = ReactSortableHOC.SortableContainer(BlockySortable);

export const blockManagementService: BlockManagementService = new BlockManagementService();
export const blockWrapperService: BlockWrapperService = new BlockWrapperService();
export const autoTagService: AutoTagService = new AutoTagService();

class Blocky extends Component<Properties> {
	private subscriberList: Subscription[] = [];
	private isUndoRedoEnabled: boolean =
		featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.CONTENT_BLOCKY_UNDO_REDO) &&
		featuresService.checkAvailableUndoRedoPageFromFeature(this.props.contentType);

	componentWillUnmount(): void {
		document.removeEventListener('insertEmbedInBody', this.insertEmbed);
		document.removeEventListener('keydown', this.copyPasteBlocks);
		this.subscriberList.forEach((subscription: Subscription) => {
			subscription.unsubscribe();
		});
		clearCustomBlocks();
	}

	insertEmbed = (event: any) => {
		const preview = EmbedBlockModel.builder()
			.withContent(event.detail.content)
			.withType(event.detail.type)
			.withValidationType(getDefaultType(validationOptions, this.props.t))
			.build();
		const data = {
			embed_type: event.detail.type ? event.detail.type : 'other',
			content: event.detail.content,
			preview: { embedBlock: preview },
			changeId: Global.makeId(7),
		};

		const block = BlockModel.builder().withId(Global.makeId(6)).withType(ContentTypes.embed).withData(data).build();
		this.addBlockAt(block, this.props.blocks.length);
	};

	componentDidMount(): void {
		document.addEventListener('insertEmbedInBody', this.insertEmbed);
		document.addEventListener('keydown', this.copyPasteBlocks);
		const sub1 = blockManagementService.onBlockUpdate().subscribe((data: BlockAddOrUpdate) => {
			this.updateBlock(data.block, data.index);
		});

		const sub2 = blockManagementService.onBlockRemove().subscribe((index: number) => {
			this.removeBlock(index);
		});

		const sub3 = blockManagementService.onBlockAdd().subscribe((blockAdd: BlockAddOrUpdate) => {
			this.addBlockAt(blockAdd.block, blockAdd.index);
		});

		this.subscriberList.push(sub1);
		this.subscriberList.push(sub2);
		this.subscriberList.push(sub3);

		if (customBlocksService.isFeatureEnabled(featuresService)) {
			extractCustomBlocks().then((r) => {});
		}
	}

	private copyPasteBlocks = async (e: any) => {
		if (this.props.blocksToCopy.length > 0) {
			pasteFromClipboard(e, this.props, false, false);
			copyToClipboard(e, this.props, false);
		}
	};

	private onSortEnd = (sortEnd: SortEnd) => {
		let blocks = [...this.props.blocks];
		blocks = arrayMove(blocks, sortEnd.oldIndex, sortEnd.newIndex);
		this.isUndoRedoEnabled && this.props.updateBlockyUndoRedo(blocks, [...this.props.blocks]);
		this.props.onChange(blocks);
	};

	private addBlockAt = (data: BlockModel, index: number) => {
		const blocks = [...this.props.blocks];
		blocks.splice(index + 1, 0, data);
		this.isUndoRedoEnabled && this.props.updateBlockyUndoRedo(blocks, [...this.props.blocks]);
		this.props.onChange(blocks);
	};

	private addMultipleBlocksAt = (data: BlockModel[], index: number) => {
		const blocks = [...this.props.blocks];
		blocks.splice(index, 0, ...data);
		this.isUndoRedoEnabled && this.props.updateBlockyUndoRedo(blocks, [...this.props.blocks]);
		this.props.onChange(blocks);
	};

	private removeBlock = (index: number) => {
		const blocks = [...this.props.blocks];
		blocks.splice(index, 1);
		this.isUndoRedoEnabled && this.props.updateBlockyUndoRedo(blocks, [...this.props.blocks]);
		this.props.onChange(blocks);
	};

	private updateBlock = (data: BlockModel, index: number) => {
		const newBlocksState = [...this.props.blocks];
		newBlocksState[index] = data;
		this.isUndoRedoEnabled
			? updateBlockWithUndoRedo(
					data,
					index,
					[...this.props.blocks],
					newBlocksState,
					this.props.updateBlockyUndoRedo,
					this.props.updateBlockyUndoRedoPresent,
					this.props.onChange,
			  )
			: this.props.onChange(newBlocksState);
	};

	render() {
		const { t, contentLanguage, blocks, entityType, contentType } = this.props;

		return (
			<div className={'blocky-main-container'}>
				<div id='blocky-wrapper' className={'blocky mb-3'}>
					<BlockySortableList
						t={t}
						useDragHandle
						contentLanguage={contentLanguage}
						entityType={entityType}
						blocks={blocks}
						onSortEnd={this.onSortEnd}
						onChange={this.props.onChange}
						addBlockAt={this.addBlockAt}
						addMultipleBlocksAt={this.addMultipleBlocksAt}
						contentType={contentType}
						isUndoRedoEnabled={this.isUndoRedoEnabled}
					/>
				</div>
			</div>
		);
	}
}

function mapDispatchToProps(dispatch: any) {
	const isUndoRedoEnabled = featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.CONTENT_BLOCKY_UNDO_REDO);
	const maximumRedoUndoStates: number = featuresService.extractUndoRedoStepsFromBlockyUndoRedoFeature();

	return {
		updateBlocksToCopy: (blocks: BlockModel[]) => dispatch(setBlocksToCopy(blocks)),
		blocksCopiedToState: (areBlocksCopied: boolean) => dispatch(blocksCopiedToState(areBlocksCopied)),
		updateBlockyUndoRedo: (newBlockArray: BlockModel[], oldBlockArray: BlockModel[]) =>
			isUndoRedoEnabled && dispatch(updateBlockyUndoRedo(newBlockArray, oldBlockArray, maximumRedoUndoStates)),
		updateBlockyUndoRedoPresent: (newBlockArray: BlockModel[]) => isUndoRedoEnabled && dispatch(updateBlockyUndoRedoPresent(newBlockArray)),
	};
}

function mapStateToProps(state: any) {
	return {
		blocksToCopy: state.blocky.blocksToCopy,
		blocksUndoRedo: state.blocky.blocksUndoRedo as UndoRedoBlockyModel,
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Blocky);
