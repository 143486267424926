import { onlyUnique } from '../../market-value-select/helper/market-value-select.helper';

export const createTournamentGroupOptions = (response: any) => {
	const tournaments = extractTournaments(response);
	const options: any = [];
	tournaments.forEach((tournament: any) => {
		const tournamentGroups: any = [];
		response.forEach((model: any) => {
			if (model.tournament.name === tournament) {
				tournamentGroups.push({
					label: model.tournament.name + ' - ' + model.name,
					value: model.id,
					data: model,
				});
			}
		});
		const option = { label: tournament, options: [...tournamentGroups] };
		options.push(option);
	});

	return options;
};

export const extractTournaments = (modelList: any) => {
	if (modelList && modelList.length > 0) {
		let tournaments = modelList.map((model: any) => model.tournament.name);

		return onlyUnique(tournaments);
	}

	return [];
};

export function seasonToOption(season: any) {
	if (season && Object.entries(season).length > 0) {
		return { label: season.label, value: season.id, data: season };
	}

	return {};
}
