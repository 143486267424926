import Related from '../../../../../../models/related/Related';

export function extractTeamIds(relatedContent: Related[]) {
	let teamIds: string[] = [];

	if (relatedContent && relatedContent.length > 0) {
		teamIds = relatedContent.filter((related: Related) => related.type === 'team').map((related: Related) => related.data.id);
	}

	return teamIds;
}

export function extractTournamentIds(relatedContent: Related[]) {
	let tournamentIds: string[] = [];

	if (relatedContent && relatedContent.length > 0) {
		tournamentIds = relatedContent.filter((related: Related) => related.type === 'tournament').map((related: Related) => related.data.id);
	}

	return tournamentIds;
}

export function extractSeasonIds(relatedContent: Related[]) {
	let seasonIds: string[] = [];

	if (relatedContent && relatedContent.length > 0) {
		seasonIds = relatedContent.filter((related: Related) => related.type === 'season').map((related: Related) => related.data.id);
	}

	return seasonIds;
}

export function extractCompetitionIds(relatedContent: Related[]) {
	let competitionIds: string[] = [];

	if (relatedContent && relatedContent.length > 0) {
		competitionIds = relatedContent.filter((related: Related) => related.type === 'competition').map((related: Related) => related.data.id);
	}

	return competitionIds;
}

export function extractCoachIds(relatedContent: Related[]) {
	let coachIds: string[] = [];

	if (relatedContent && relatedContent.length > 0) {
		coachIds = relatedContent.filter((related: Related) => related.type === 'coach').map((related: Related) => related.data.id);
	}

	return coachIds;
}

export function extractPlayerIds(relatedContent: Related[]) {
	let playerIds: string[] = [];

	if (relatedContent && relatedContent.length > 0) {
		playerIds = relatedContent.filter((related: Related) => related.type === 'player').map((related: Related) => related.data.id);
	}

	return playerIds;
}

export function extractVenueIds(relatedContent: Related[]) {
	let venueIds: string[] = [];

	if (relatedContent && relatedContent.length > 0) {
		venueIds = relatedContent.filter((related: Related) => related.type === 'venue').map((related: Related) => related.data.id);
	}

	return venueIds;
}

export function extractArenaIds(relatedContent: Related[]) {
	let arenaIds: string[] = [];

	if (relatedContent && relatedContent.length > 0) {
		arenaIds = relatedContent.filter((related: Related) => related.type === 'arena').map((related: Related) => related.data.id);
	}

	return arenaIds;
}

export function extractMatchIds(relatedContent: Related[]) {
	let matchIds: string[] = [];

	if (relatedContent && relatedContent.length > 0) {
		matchIds = relatedContent.filter((related: Related) => related.type === 'match').map((related: Related) => related.data.id);
	}

	return matchIds;
}

export function extractGameIds(relatedContent: Related[]) {
	let gameIds: string[] = [];

	if (relatedContent && relatedContent.length > 0) {
		gameIds = relatedContent.filter((related: Related) => related.type === 'game').map((related: Related) => related.data.id);
	}

	return gameIds;
}

export function responseToRelated(response: any, type: string): Related {
	let related: Related = {} as Related;

	if (response && type) {
		related = Related.builder().withType(type).withProvider('smp').withData(response).build();
	}

	return related;
}

export function contentToRelated(response: any[], type: string): Related[] {
	let related: Related[] = [];

	if (response && response.length > 0) {
		related = response.map((res: any) => responseToRelated(res, type));
	}

	return related;
}

export function addRelatedIfNotExists(toAdd: Related, existing: Related[]): Related[] {
	existing = existing ? existing : [];

	if (toAdd) {
		let existingIndex = findRelatedContentIndex(toAdd, existing);

		if (existingIndex === -1) {
			existing.push(toAdd);
		}
	}

	return existing;
}

export function decideSuggestionsPriority(
	teamIds: string[],
	playerIds: string[],
	matchIds: string[],
	gameIds: string[],
	tournamentIds: string[],
): any {
	if (matchIds && matchIds.length > 0) {
		return { type: 'match', requestQuery: `&matchId=${matchIds[0]}` };
	}

	if (gameIds && gameIds.length > 0) {
		return { type: 'game', requestQuery: `&gameIds=${gameIds[0]}` };
	}

	if (teamIds && teamIds.length > 0) {
		return { type: 'team', requestQuery: `&teamIds=${teamIds.join(',')}` };
	}

	if (playerIds && playerIds.length > 0) {
		return { type: 'player', requestQuery: `&playerIds=${playerIds.join(',')}` };
	}

	if (tournamentIds && tournamentIds.length > 0) {
		return { type: 'tournament', requestQuery: `&tournamentIds=${tournamentIds.join(',')}` };
	}

	return { type: 'none', requestQuery: '' };
}

export function findRelatedContentIndex(toAdd: Related, existing: Related[]) {
	return existing.findIndex((related: Related) => related.type === toAdd.type && related.data.id === toAdd.data.id);
}
