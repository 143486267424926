import VideoUrls from '../../../../../../../models/urls/video-urls/video-urls';

export const optionToVideoUrls = (option: any) => {
	if (option && option.value.length > 0) {
		return VideoUrls.builder().withType(option.value).build();
	}

	return [];
};

export const pathToVideoUrls = (path: any, videoUrl: VideoUrls) => {
	if (path && videoUrl) {
		return VideoUrls.builder(videoUrl).withUrl(path).build();
	}
	return VideoUrls.builder(videoUrl).withUrl('').build();
};
