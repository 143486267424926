import { FixturesJson } from './fixtures-widget.json';
import FixturesBuilder from './fixtures-widget.builder';
import MatchModel from '../../../../partials/match-select/models/match.model';
import TeamModel from '../../../../partials/team-select/models/team.model';
import TournamentModel from '../../player-profile/models/tournament-model/tournament.model';
import RoundModel from './round/round.model';
import StageModel from './stage/stage.model';
import SeasonModel from '../../player-profile/models/season/season.model';

export default class FixturesModel {
	readonly matches: MatchModel[];
	readonly team: TeamModel;
	readonly tournament: TournamentModel;
	readonly season: SeasonModel;
	readonly round: RoundModel;
	readonly stage: StageModel;
	readonly noData: boolean;
	readonly displayOdds: boolean;
	readonly title: string;

	private constructor(
		matches: MatchModel[],
		team: TeamModel,
		tournament: TournamentModel,
		season: SeasonModel,
		stage: StageModel,
		round: RoundModel,
		noData: boolean,
		displayOdds: boolean,
		title: string,
	) {
		this.matches = matches;
		this.team = team;
		this.tournament = tournament;
		this.season = season;
		this.stage = stage;
		this.round = round;
		this.noData = noData;
		this.displayOdds = displayOdds;
		this.title = title;
	}

	toJson(): FixturesJson {
		return {
			matches: this.matches,
			team: this.team,
			tournament: this.tournament,
			season: this.season,
			stage: this.stage,
			round: this.round,
			noData: this.noData,
			displayOdds: this.displayOdds,
			title: this.title,
		};
	}

	static fromJson(json: FixturesJson): FixturesModel {
		return new FixturesModel(
			json.matches,
			json.team,
			json.tournament,
			json.season,
			json.stage,
			json.round,
			json.noData,
			json.displayOdds,
			json.title,
		);
	}

	static builder(fixture?: FixturesModel): FixturesBuilder {
		return new FixturesBuilder(fixture);
	}
}
