import React, { FunctionComponent } from 'react';
import Masonry from 'react-masonry-css';
import './style/main-media-masonry.scss';
import ImageModel from '../../../../../../../models/v2/image/image.model';
import { imagePlaceholderBase64 } from '../../../../../../../global-helpers/global.constants';
import { imageToMainImageModel } from '../../../helpers/general';
import { onMainImageMediaUpdate } from '../../../helpers/redux-updater';

type ImageCellsProps = {
	images: ImageModel[];
};

const ImageCells: FunctionComponent<ImageCellsProps> = ({ images }) => {
	const onImageSelect = (image: ImageModel) => {
		const imageToMainImage = imageToMainImageModel(image);
		onMainImageMediaUpdate(imageToMainImage);
	};

	return (
		<Masonry
			breakpointCols={{
				default: 3,
				1380: 3,
				1300: 2,
				990: 4,
				560: 3,
				520: 2,
			}}
			className='main-media-masonry-grid'
			columnClassName='main-media-masonry-grid_column'
		>
			{images.map((image: ImageModel) => {
				if (image && image.urls) {
					const imageSrc = image.urls && image.urls.uploaded && image.urls.uploaded.embed ? image.urls.uploaded.embed : imagePlaceholderBase64;
					return (
						<img
							src={imageSrc}
							id={`image-cell-${image.id}`}
							key={`image-preview-key-${image.id}`}
							className='img-fluid  w-100 mb-1 cursor-pointer'
							onClick={() => onImageSelect(image)}
							alt={image.description || ''}
							onError={(i: any) => (i.target.src = imagePlaceholderBase64)}
						/>
					);
				}
			})}
		</Masonry>
	);
};

export default ImageCells;
