import { ContextJson } from './context.json.js';
import ContextBuilder from './context.builder';

export default class Context {
	readonly groupBy: string;
	readonly startDate: string;
	readonly endDate: string;

	private constructor(groupBy: string, startDate: string, endDate: string) {
		this.groupBy = groupBy;
		this.startDate = startDate;
		this.endDate = endDate;
	}

	toJSON(): ContextJson {
		return {
			groupBy: this.groupBy,
			startDate: this.startDate,
			endDate: this.endDate,
		};
	}

	static fromJSON(json: ContextJson): Context {
		return new Context(json.groupBy, json.startDate, json.endDate);
	}

	static builder(context?: Context): ContextBuilder {
		return new ContextBuilder(context);
	}
}
