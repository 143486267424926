import { TypedJSON } from 'typedjson';
import StageModel from '../../../../../../../models/v2/Stage/Entity/StageModel';

export const stageToOption = (content: any) => {
	if (content && content.id) {
		return {
			label: content.name,
			value: content.id,
			data: content,
		};
	}

	return {};
};

export const stagesToOptions = (response: any) => {
	if (response && response.length > 0) {
		return response.map((content: any) => {
			return stageToOption(content);
		});
	}

	return [];
};

export const responseToStageModel = (response: any) => {
	const serializer = new TypedJSON(StageModel);
	const remmapedStage = serializer.parse(response) as StageModel;

	return remmapedStage;
};
