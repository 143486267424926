import OnnetworkVideoModel from '../../../../../../models/onnetwork-videos/onnetwork-video.model';

export const remapVideoFromResponse = (item: any, currentWebsite: string) => {
	if (item) {
		return OnnetworkVideoModel.builder()
			.withId(item.id)
			.withTitle(item.title)
			.withDescription(item.description)
			.withTags(item.tags)
			.withEmbedCode(constructEmbedCode(item.webcodes, currentWebsite))
			.build();
	}
	return {};
};

export const remapVideoList = (data: any, currentWebsite: string) => {
	if (data && data.result && data.result.length > 0) {
		return data.result.map((video: any) => remapVideoFromResponse(video, currentWebsite));
	}

	return [];
};

export const constructEmbedCode = (webcodes: any[], currentWebsite: string) => {
	const code = extractCode(webcodes, currentWebsite);
	if (code) {
		return { script: `<script type="text/javascript" src="https://video.onnetwork.tv/embed.php?sid=${code}"></script>`, code: code };
	}
	return { script: code, code: code };
};

export const extractCode = (webcodes: any[], currentWebsite: string) => {
	if (webcodes && webcodes.length > 0) {
		const result = webcodes.filter((website: any) => website.website === currentWebsite);

		if (result.length > 0) {
			return result[0].sid;
		}
	}
	return '';
};
