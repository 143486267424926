import PlayerModel from '../models/player.model';
import { assetsPlaceholder } from '../../../../../../constants/assetsPlaceholder';

export const remapPlayerFromResponce = (player: any) => {
	return PlayerModel.builder()
		.withId(player.id)
		.withLogo(player.url_thumb)
		.withName(player.name)
		.withHeadshot(player.type)
		.withTeam(player.team)
		.withPosition(player.position)
		.build();
};

export const playerToOption = (player: any) => {
	if (player) {
		return {
			value: player.id,
			label: player.name,
			data: player.originalData,
			logo: player.logo ? player.logo : assetsPlaceholder.player,
			type: 'player',
		};
	}

	return {};
};

export const responseToOption = (player: any) => {
	if (player) {
		return {
			value: player.id,
			label: player.name,
			data: player,
			logo: player.url_thumb ? player.url_thumb : assetsPlaceholder.player,
			type: 'player',
		};
	}

	return {};
};
