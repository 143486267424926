import 'reflect-metadata';
import { AnyT, jsonMember, jsonObject } from 'typedjson';

@jsonObject
export default class FansUnitedLeaderboardTemplatesModel {
	@jsonMember(String)
	public id: string = '';
	@jsonMember(String)
	public name: string = '';
	@jsonMember(String)
	public type: string = '';
	@jsonMember(String)
	public from_date: string = '';
	@jsonMember(String)
	public to_date: string = '';
	@jsonMember(AnyT)
	public markets: any;
	@jsonMember(AnyT)
	public team_ids: any;
	@jsonMember(AnyT)
	public match_ids: any;
	@jsonMember(AnyT)
	public game_ids: any;
	@jsonMember(AnyT)
	public game_types: any;
	@jsonMember(AnyT)
	public competition_ids: any;
	@jsonMember(String)
	public rules: string = '';
	@jsonMember(AnyT)
	public flags: any;
	@jsonMember(String)
	public description: string = '';
	@jsonMember(String)
	public created_at: string = '';
	@jsonMember(String)
	public updated_at: string = '';
	@jsonMember(Boolean)
	public isSelected: boolean = false;
}
