import { PlayerJson } from './player.json';
import PlayerBuilder from './player.builder';
import TeamModel from '../../team-select/models/team.model';

export default class PlayerModel {
	readonly id: string;
	readonly name: string;
	readonly logo: string;
	readonly headshot: string;
	readonly team: TeamModel;
	readonly position: string;

	private constructor(id: string, name: string, logo: string, headshot: string, team: TeamModel, position: string) {
		this.id = id;
		this.name = name;
		this.logo = logo;
		this.headshot = headshot;
		this.team = team;
		this.position = position;
	}

	toJson(): PlayerJson {
		return {
			id: this.id,
			name: this.name,
			url_thumb: this.logo,
			headshot: this.headshot,
			team: this.team,
			position: this.position,
		};
	}

	static fromJson(json: PlayerJson): PlayerModel {
		return new PlayerModel(json.id, json.name, json.url_thumb, json.headshot, json.team, json.position);
	}

	static builder(player?: PlayerModel): PlayerBuilder {
		return new PlayerBuilder(player);
	}
}
