import { ErrorResponseJson } from './ErrorResponseJson';
import ErrorResponseModel from './ErrorResponseModel';
import ErrorModel from '../error/ErrorModel';

export default class ErrorResponseBuilder {
	private json: ErrorResponseJson;

	constructor(error?: ErrorResponseModel) {
		this.json = error ? error.toJSON() : ({ errors: [] } as ErrorResponseJson);
	}

	fromResponse(error: any, endpoint?: string) {
		if (error.response && error.response.data && error.response.data.errors) {
			Object.keys(error.response.data.errors).forEach((key: string) => {
				const errorModel: ErrorModel = ErrorModel.builder()
					.withType(key)
					.withError(error.response.data.errors[key])
					.withCode(error.response.status)
					.build();

				this.json.errors.push(errorModel);
			});
		} else if (error.response && error.response.data && error.response.data.message) {
			const errorModel: ErrorModel = ErrorModel.builder().withError(error.response.data.message).withCode(error.response.status).build();
			this.json.errors.push(errorModel);
		}

		return this;
	}

	build(): ErrorResponseModel {
		return ErrorResponseModel.fromJSON(this.json);
	}
}
