import React from 'react';
import { Properties } from './properties/image-input.properties';

export const ImageInput: React.FunctionComponent<Properties> = ({ modelVal, value, label, onChange, type, blockId }) => {
	return (
		<div className='form-check form-check-inline'>
			<input
				key={`input-image-${blockId}-${value}`}
				className='form-check-input'
				type='radio'
				id={`input-image-${blockId}-${value}`}
				value={value}
				checked={modelVal === value}
				onChange={(e: any) => onChange(e, type)}
			/>
			<label key={`input-image-label-${blockId}-${value}`} className='form-check-label' htmlFor={`input-image-${blockId}-${value}`}>
				{label}
			</label>
		</div>
	);
};
