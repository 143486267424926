import * as React from 'react';
import Select from 'react-select';
import { imageRatioFromOptionSelections, imageRatiosToOptions, imageRatioToOptions } from './ImageRatiosHelper';

export type Properties = {
	imageRatios: any[];
	selectedImageRatio: any;
	onImageRatioSelect: any;
	t: any;
};

const ImageRatioSelectContainer: React.FunctionComponent<Properties> = (props) => {
	const { selectedImageRatio, imageRatios } = props;

	const onImageRatioSelect = (selection: any) => {
		props.onImageRatioSelect(imageRatioFromOptionSelections(selection));
	};

	return (
		<Select
			inputId='image-ratio-select'
			value={imageRatioToOptions(selectedImageRatio).ratio.length <= 0 ? '' : imageRatioToOptions(selectedImageRatio)}
			options={imageRatiosToOptions(imageRatios)}
			onChange={onImageRatioSelect}
			placeholder={props.t('select')}
		/>
	);
};

export default ImageRatioSelectContainer;
