import { TypedJSON } from 'typedjson';
import LiveBlogTournamentModel from './live-blog-tournament-model';
import { convertIdToString } from '../../helpers/utility.helper';

export const responseToLiveBlogTournamentModel = (response: any): LiveBlogTournamentModel => {
	const serializer = new TypedJSON(LiveBlogTournamentModel);
	let remappedTournamentData = serializer.parse(response) as LiveBlogTournamentModel;

	if (remappedTournamentData) {
		remappedTournamentData.id = convertIdToString(remappedTournamentData.id);
	}

	return remappedTournamentData;
};
