import React from 'react';
import { SortableHandle } from 'react-sortable-hoc';
import { Button, Col, Row } from 'reactstrap';
import moment from 'moment';
import ListItemMeta from '../../../../../models/list/list-item/list-item-meta/ListItemMeta';
import Project from '../../../../../models/project/Project';
import { ContentItemOverride } from './ContentItemOverride';
import { capitalize } from '../../../../../global-helpers/global.helpers';
import { connect } from 'react-redux';
import ContentItemStatisticsComponent from './content-item-statistics.component';
import { Link } from 'react-router-dom';
import { ContentTypes } from '../../../../../constants/content-types';
import '../subcomponents/Scss/ContentItemData.scss';
import { returnCorrectUrlPathToContent } from '../list-content.helper';

export type ContentItemModel = {
	listItemId: string;
	title: string;
	image: string;
	publishedAt: string;
	icon: string;
	meta: ListItemMeta;
	type: string;
	views: any;
	comments: any;
	authors: string[];
	createdBy: string;
	entityType: string;
	subtitle: string;
};

type Properties = {
	value: ContentItemModel;
	project: Project;
	onDelete: () => any;
	onEdit: () => any;
	onClear: () => any;
	t: any;
	rightSidebarList: boolean;
	shouldShowListImages: any;
	itemIndex: number;
	profileId: string;
	isLocked: boolean;
};

type State = {};
const DragHandle = SortableHandle(() => (
	<div className={'cursor-draggable mr-2'}>
		<i className='fa fa-bars'></i>
	</div>
));

class ContentItem extends React.Component<Properties, State> {
	onDelete() {
		if (this.props.onDelete) {
			this.props.onDelete();
		}
	}

	isTagOrSportsConnection = (type: string) => {
		return type === 'TAG' || type === 'RELATEDCONTENT';
	};

	render() {
		const { value, project, t, onClear, onEdit, onDelete, rightSidebarList } = this.props;
		const { itemIndex, shouldShowListImages, profileId, isLocked } = this.props;
		const style = !rightSidebarList ? 'd-inline-block w-100' : 'd-inline-flex w-100';
		const styleList = rightSidebarList ? 'mb-1 p-1' : '';
		let showAuthorData = value.authors && value.authors.length > 0;
		let showCreatedBy = value.createdBy;
		let authorData = value.authors && `${t('authors')}: ${value.authors.join(', ')}`;
		let createdByData = `${t('created_by')}: ${value.createdBy}`;
		let connectionType = `${t('type')}: ${t(value.entityType)}`;
		const link =
			value.type.toLocaleLowerCase() === ContentTypes.LIVE_BLOG
				? `/smp/live-blogs/editorial-admin/${value.listItemId}`
				: `/smp/${
						value.type && value.type.toLocaleLowerCase() === ContentTypes.GALLERY ? 'gallerie' : value.type && value.type.toLowerCase()
				  }s/edit/${value.listItemId}`;
		return (
			<li className={`list-group-item ${styleList} ${isLocked ? 'disabled' : ''}`}>
				<Row className='position-relative '>
					<Col className={`d-flex justify-content-between ${rightSidebarList ? 'align-items-center' : ''}`}>
						<div className='list-content d-flex justify-content-between align-items-center w-80'>
							{isLocked && <i style={{ left: '50%' }} className='position-absolute p-1 fa-4x opacity-02 fa fa-lock text-muted' />}
							<span className='px-1 font-weight-bold'>{itemIndex + 1}.</span>
							{!rightSidebarList && <DragHandle />}
							<div className={`${style}`}>
								<div className={'d-flex align-items-center overflow-hidden'}>
									{!rightSidebarList && value.type !== 'TAG' && (
										<div className={'image-wrapper mr-3'}>
											<img src={value.image} className={'max-width-100 rounded'} />
										</div>
									)}
									{rightSidebarList && shouldShowListImages[profileId] && (
										<div className='image-wrapper mr-2'>
											<img src={value.image} height='60' width='60' className='max-width-100 rounded-circle' />
										</div>
									)}
									<div className={'content-wrapper overflow-hidden'}>
										<h6 className='mb-0'>
											<i className={`fa ${value.icon}`} title={capitalize(value.type)}></i>
											{value.type === 'RELATEDCONTENT' ? (
												<label className='text-dark ml-1'>{value.title}</label>
											) : (
												<Link to={link} className='text-dark ml-1' style={{ pointerEvents: 'all' }}>
													{value.title}
												</Link>
											)}
										</h6>
										{!this.isTagOrSportsConnection(value.type) && value.type.toLocaleLowerCase() !== ContentTypes.LIVE_BLOG && (
											<div className='d-flex'>
												<ContentItemStatisticsComponent t={t} value={value} style='pr-2 pt-1 d-flex' />
											</div>
										)}

										<div className='flex-column content-item-list-data'>
											{value.type === 'RELATEDCONTENT' ? (
												`${connectionType}`
											) : value.type === 'TAG' ? (
												`${createdByData}`
											) : rightSidebarList ? (
												<>
													<small>{`${t('date_published')}: ${moment(value.publishedAt).format('DD MMMM YYYY, HH:mm')}`}</small>
													<small>
														{showAuthorData && `${authorData}`}
														{showAuthorData && showCreatedBy && ` | `}
														{showCreatedBy && `${createdByData}`}
													</small>
												</>
											) : (
												<>
													<p>{`${t('date_published')}: ${moment(value.publishedAt).format('DD MMMM YYYY, HH:mm')}`}</p>
													<p>
														{showAuthorData && `${authorData}`}
														{showAuthorData && showCreatedBy && ` | `}
														{showCreatedBy && `${createdByData}`}
													</p>
												</>
											)}
										</div>
									</div>
								</div>
							</div>
						</div>
					</Col>
					<Col className='action-buttons'>
						<div>
							{!rightSidebarList && (
								<>
									{!this.isTagOrSportsConnection(value.type) && (
										<Button
											style={{ pointerEvents: 'all' }}
											id={`content-item-image-edit-btn-${itemIndex}`}
											color={'warning'}
											onClick={() => onEdit()}
											className='mr-1 mb-1 w-100'
										>
											<i className={'fa fa-pencil'}></i> {t('edit')}
										</Button>
									)}
									<Button
										disabled={isLocked}
										id={`content-item-image-remove-btn-${itemIndex}`}
										color={'danger'}
										onClick={() => onDelete()}
										className='mb-1 w-100'
									>
										<i className={'fa fa-trash'}></i> {t('remove')}
									</Button>
								</>
							)}
							{rightSidebarList && (
								<Button
									disabled={isLocked}
									id={`content-item-image-delete-btn-${itemIndex}`}
									color={'danger'}
									onClick={() => onDelete()}
									className={'mb-0'}
								>
									<i className={'fa fa-trash'}></i>
								</Button>
							)}
						</div>
					</Col>
				</Row>
				{!rightSidebarList && (
					<Row>
						<Col>
							<ContentItemOverride t={t} project={project} meta={value.meta} onClear={() => onClear()} />
						</Col>
					</Row>
				)}
			</li>
		);
	}
}

function mapStateToProps(state: any) {
	return {
		shouldShowListImages: state.ui.shouldShowListImages,
		profileId: state.profile.profile.id,
	};
}

export default connect(mapStateToProps)(ContentItem);
