import React from 'react';
import { ViewTypes } from '../../../../constants/general.constants';
import TeamComparisonEdit from './team-comparison-widget-edit.component';
import { TeamComparisonWidgetView } from './team-comparison-widget-view.component';
import { Properties } from './properties/team-comparison-widget.properties';
import OddsWidgetModel from '../../odds/models/odds-widget.model';
import TeamComparisonModel from '../models/team-comparison.model';
import BlockUpdateController from '../../../block-update-controller.component';
import { WidgetPreview } from '../../widget-preview/widget-preview.component';

const TeamComparisonWidget: React.FunctionComponent<Properties> = (props) => {
	if (props.view === ViewTypes.edit) {
		return <TeamComparisonEdit t={props.t} block={props.block} />;
	}

	if (props.view === ViewTypes.normal) {
		return (
			<TeamComparisonWidgetView
				t={props.t}
				previewOdds={
					props.block.data.preview && props.block.data.preview.odds ? props.block.data.preview.odds : OddsWidgetModel.builder().build()
				}
				preview={
					props.block.data.preview && props.block.data.preview.teamComparison
						? props.block.data.preview.teamComparison
						: TeamComparisonModel.builder().build()
				}
			/>
		);
	}

	if (props.view === ViewTypes.preview) {
		return <WidgetPreview blockContent={props.block.data.content} config={props.block.data.config} />;
	}

	return null;
};

export default BlockUpdateController(TeamComparisonWidget);
