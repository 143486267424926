import GroupModel from '../../../../../partials/stage-groups-select/models/group.model';

export const createGroups = (groups: GroupModel[]) => {
	if (groups && groups.length > 0) {
		return groups.map((item: GroupModel, index: number) => (index + 1 < groups.length ? `${item.name}, ` : item.name));
	}

	return null;
};

export const extractStangingFromGroup = (groups: GroupModel[]) => {
	let standing: any[] = [];

	if (groups && groups.length > 0) {
		groups.forEach((group: GroupModel) => {
			if (group.standing && group.standing.length > 0) {
				standing.push(group.standing);
			}
		});
	}

	return standing.flat(1);
};
