import BlacklistModel from '../blacklist/blacklist';
import AssetsModel from '../team-manual-data/assets/assets';
import { CoachManualDataJson } from './coach-manual-data.json';
import CoachManualDataModel from './coach-manual-data.model';
import { CoachManualDataRequestJson } from './coach-manual-data-request.json';

export default class CoachManualDataBuilder {
	private json: CoachManualDataJson;

	constructor(coach?: CoachManualDataModel | CoachManualDataJson) {
		if (coach && coach instanceof CoachManualDataModel) {
			this.json = coach.toJSON();
		} else if (coach) {
			this.json = coach;
		} else {
			this.json = {} as CoachManualDataJson;
		}
	}

	withId(id: string): CoachManualDataBuilder {
		this.json.id = id;

		return this;
	}

	withName(name: string): CoachManualDataBuilder {
		this.json.name = name;

		return this;
	}

	withCountry(country: any): CoachManualDataBuilder {
		this.json.country = country;

		return this;
	}

	withBirthdate(birthdate: string): CoachManualDataBuilder {
		this.json.birthdate = birthdate;

		return this;
	}

	withBlacklist(blacklist: BlacklistModel): CoachManualDataBuilder {
		this.json.blacklist = blacklist;

		return this;
	}

	withAssets(assets: AssetsModel): CoachManualDataBuilder {
		this.json.assets = assets;

		return this;
	}

	withURLs(url_image: string, url_thumb: string): CoachManualDataBuilder {
		this.json.url_image = url_image;
		this.json.url_thumb = url_thumb;

		return this;
	}

	build(): CoachManualDataModel {
		return CoachManualDataModel.fromJSON(this.json);
	}

	equals(coach: CoachManualDataModel): boolean {
		const idEqual = this.json.id === coach.id;
		const nameEqual = this.json.name === coach.name;
		const birthdateEqual = this.json.birthdate === coach.birthdate;
		const assetsEqual = this.json.assets === coach.assets;

		return idEqual && nameEqual && birthdateEqual && assetsEqual;
	}

	toRequestJson(): CoachManualDataRequestJson {
		let json = {} as CoachManualDataRequestJson;
		json.name = this.json.name;
		json.country_id = this.json.country ? `${this.json.country.id}` : '';
		json.birthdate = this.json.birthdate ? this.json.birthdate : null;

		if (this.json.birthdate) json.birthdate = this.json.birthdate;

		if (this.json.id) json.id = `${this.json.id}`;

		return json;
	}
}
