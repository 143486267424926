import TranslationsModel from '../../../../../models/translation-groups/translations/translations-model';

export const translationsToOptions = (items: TranslationsModel[], isMulti: boolean, t: any) => {
	if (items && items.length > 0) {
		return items.map((item: any) => translationToOption(item, isMulti, t));
	}

	return [];
};

export const translationToOption = (data: TranslationsModel, isMulti: boolean, t: any) => {
	if (data && data.id) {
		return { label: t(data.language), value: data.language, data };
	}

	return isMulti ? {} : [];
};
