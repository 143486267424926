import { OddClientBookmakersJson } from './odd-client-bookmakers.json';
import OddClientBookmakersBuilder from './odd-client-bookmakers.builder';

export default class OddClientBookmakersModel {
	readonly id: string;
	readonly name: string;
	readonly url: string;
	readonly logo: string;

	private constructor(id: string, name: string, url: string, logo: string) {
		this.id = id;
		this.name = name;
		this.url = url;
		this.logo = logo;
	}

	toJson(): OddClientBookmakersJson {
		return {
			id: this.id,
			name: this.name,
			url: this.url,
			logo: this.logo,
		};
	}

	static fromJson(json: OddClientBookmakersJson): OddClientBookmakersModel {
		return new OddClientBookmakersModel(json.id, json.name, json.url, json.logo);
	}
	static builder(match?: OddClientBookmakersModel | OddClientBookmakersJson): OddClientBookmakersBuilder {
		return new OddClientBookmakersBuilder(match);
	}
}
