import { WatermarkJson } from './WatermarkJson';
import Watermark from './Watermark';
import { WatermarkRequestJson } from './WatermarkRequestJson';

export default class WatermarkBuilder {
	private json: WatermarkJson;

	constructor(watermark?: Watermark | WatermarkJson) {
		if (watermark && watermark instanceof Watermark) {
			this.json = watermark.toJSON();
		} else if (watermark) {
			this.json = watermark;
		} else {
			this.json = {} as WatermarkJson;
		}
	}

	withId(id: string): WatermarkBuilder {
		this.json.id = id;

		return this;
	}

	withName(name: string): WatermarkBuilder {
		this.json.name = name;

		return this;
	}

	withOpacity(opacity: string): WatermarkBuilder {
		this.json.opacity = opacity;

		return this;
	}
	withPosition(position: string): WatermarkBuilder {
		this.json.opacity = position;

		return this;
	}

	withProject(project: string): WatermarkBuilder {
		this.json.project = project;

		return this;
	}

	withWatermarkImagePath(watermarkImagePath: string): WatermarkBuilder {
		this.json.watermarkImagePath = watermarkImagePath;

		return this;
	}

	build(): Watermark {
		return Watermark.fromJSON(this.json);
	}

	toRequestJson(): WatermarkRequestJson {
		let json = {} as WatermarkRequestJson;

		json.id = this.json.id;
		json.name = this.json.name;
		json.opacity = this.json.opacity;
		json.position = this.json.position;
		json.project = this.json.project;
		json.project = this.json.project;
		json.watermarkImagePath = this.json.watermarkImagePath;

		return json;
	}
}
