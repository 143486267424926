import { RoundJson } from './round.json';
import RoundBuilder from './round.builder';

export default class RoundModel {
	readonly round: string;
	readonly startTime: string;
	readonly endTime: string;

	private constructor(round: string, startTime: string, endTime: string) {
		this.round = round;
		this.startTime = startTime;
		this.endTime = endTime;
	}

	toJson(): RoundJson {
		return {
			round: this.round,
			startTime: this.startTime,
			endTime: this.endTime,
		};
	}

	static fromJson(json: RoundJson): RoundModel {
		return new RoundModel(json.round, json.startTime, json.endTime);
	}

	static builder(round?: RoundModel): RoundBuilder {
		return new RoundBuilder(round);
	}
}
