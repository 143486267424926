import { LanguageCodeJson } from './language-code-json';
import LanguageCodeModel from './language-code-model';
import { LanguageCodeRequestJson } from './language-code-request-json';

export default class LanguageCodeBuilder {
	private json: LanguageCodeJson;

	constructor(languageCode?: LanguageCodeModel) {
		this.json = languageCode ? languageCode.toJSON() : ({} as LanguageCodeJson);
	}

	withLanguageCode(languageCode: string): LanguageCodeBuilder {
		this.json.languageCode = languageCode;

		return this;
	}

	toRequestJson(): LanguageCodeRequestJson {
		let json = {} as LanguageCodeRequestJson;
		json.language_code = this.json.languageCode;

		return json;
	}

	build(): LanguageCodeModel {
		return LanguageCodeModel.fromJSON(this.json);
	}
}
