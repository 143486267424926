import classNames from 'classnames';
import React, { FC, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Button, Popover, PopoverBody } from 'reactstrap';
import CUSTOM_EXTENSIONS from '../../../helpers/collaborative-editor-extensions.helper';
import getEventIcon from '../../../helpers/get-event-icon';
import { LiveBlogPostModel } from '../../../models/live-blog-post.model';
import CollaborativeEditor from '../collaborative-editor/collaborative-editor';

import '../../../style/live-blog-post.scss';

type LiveBlogPostProps = Pick<
	LiveBlogPostModel,
	| 'id'
	| 'body'
	| 'minute'
	| 'injury_minute'
	| 'author'
	| 'is_highlight'
	| 'is_pinned'
	| 'display_timestamp'
	| 'sponsors'
	| 'sport_event_type'
> & {
	locked?: boolean;
	editedBy?: string;
	onDelete: () => void;
	onEdit: () => void;
	onPin: (pinned: boolean) => void;
	onHighlight: (highlight: boolean) => void;
	toDelete?: boolean;
};

const LiveBlogPost: FC<LiveBlogPostProps> = ({
	id,
	body,
	minute,
	injury_minute,
	author,
	is_pinned = false,
	is_highlight = false,
	display_timestamp,
	locked = false,
	editedBy,
	onDelete,
	onEdit,
	onPin,
	onHighlight,
	toDelete = false,
	sponsors,
	sport_event_type,
}) => {
	const [t] = useTranslation();
	const [isMenuPopoverOpened, setIsMenuPopoverOpened] = useState(false);
	const [editorResetToken, setEditorResetToken] = useState<{} | null>(null);

	const closeMenu = () => setIsMenuPopoverOpened(false);

	useEffect(() => {
		if (toDelete) {
			setEditorResetToken(new Object());
		}
	}, [toDelete]);

	return (
		<div
			className={classNames('live-blog-post', {
				highlighted: is_highlight,
				locked,
				'with-author': !!author,
				'with-minute': !!minute,
				'with-sponsors': !!sponsors,
				'to-delete': toDelete,
				'automatic-with-image': !!sport_event_type,
			})}
		>
			{!!minute && (
				<div
					className={classNames('live-blog-post-minute', {
						'with-injury': !!injury_minute,
					})}
				>
					{`${minute}`}
					{injury_minute && `+${injury_minute}'`}
					{!injury_minute && "'"}
				</div>
			)}
			{!!sport_event_type && (
				<div className='live-blog-post-automatic-image' style={{ backgroundImage: `url(${getEventIcon(sport_event_type.code)})` }}></div>
			)}
			{!!author && <div className='live-blog-post-author'>{t('by_author', { authorName: author.name })}</div>}
			<div className='live-blog-post-content'>
				<CollaborativeEditor
					content={body}
					customNodes={CUSTOM_EXTENSIONS}
					editable={false}
					subDocumentId={`${id}-preview`}
					resetToken={editorResetToken}
					openModalCallback={undefined}
				/>
			</div>
			{!!sponsors && (
				<div className='live-blog-post-sponsor-logos'>
					{sponsors.map((sponsor) => (
						<div className='live-blog-post-sponsor-logo' key={sponsor.id} style={{ backgroundImage: `url("${sponsor.url_logo}")` }}></div>
					))}
				</div>
			)}
			<div className='live-blog-post-timestamp'>
				<i className='fa-calendar-days fa' />
				{new Date(display_timestamp).toLocaleString()}
			</div>
			<div className='live-blog-post-menu-button' id={'menuIcon' + id}>
				<i
					className={classNames('fa', {
						'fa-lock': locked,
						'fa-ellipsis-vertical': !locked,
					})}
				/>
			</div>
			<Popover
				key={`popover-${locked ? 'locked' : 'unlocked'}-${id}`}
				className='live-blog-post-menu'
				placement={locked ? 'bottom' : 'bottom-start'}
				isOpen={isMenuPopoverOpened}
				target={'menuIcon' + id}
				toggle={() => setIsMenuPopoverOpened(!isMenuPopoverOpened)}
				hideArrow={!locked}
				trigger={locked ? 'hover' : 'legacy'}
			>
				<PopoverBody>
					{locked ? (
						<Trans i18nKey='currently_edited_by' values={{ editorName: editedBy }} />
					) : (
						<>
							<Button
								onClick={() => {
									onEdit();
									closeMenu();
								}}
								outline
							>
								<i className='fa-edit fa' />
								{t('edit')}
							</Button>
							<Button
								onClick={() => {
									onDelete();
									closeMenu();
								}}
								outline
							>
								<i className='fa-trash fa' />
								{t('delete')}
							</Button>
							<Button
								onClick={() => {
									onPin(!is_pinned);
									closeMenu();
								}}
								outline
							>
								<i className='fa-thumbtack fa' />
								{t(is_pinned ? 'remove_pin' : 'pin')}
							</Button>
							<Button
								onClick={() => {
									onHighlight(!is_highlight);
									closeMenu();
								}}
								outline
							>
								<i className='fa-lightbulb fa' />
								{t(is_highlight ? 'remove_highlight' : 'highlight')}
							</Button>
						</>
					)}
				</PopoverBody>
			</Popover>
		</div>
	);
};

export default LiveBlogPost;
