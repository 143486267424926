import React from 'react';
import { compose } from 'redux';
import BlockModel from '../../../../Partials/Blocky/models/block.model';
import { ViewTypes } from '../../../../Partials/Blocky/constants/general.constants';
import BlockUpdateController from '../../../../Partials/Blocky/blocks/block-update-controller.component';
import GettyImagesView from './getty-image-block-view.component';
import GettyImageModel from '../../models/getty-image.model';
import GettyImagesEdit from './getty-image-block-edit.component';

type Properties = {
	block: BlockModel;
	view: ViewTypes;
	contentData: any;
	contentLanguage: string;
};

const GettyImagesBlockComponent: React.FunctionComponent<Properties> = (props) => {
	const { view, block } = props;

	if (view === ViewTypes.edit) {
		return <GettyImagesEdit block={block} />;
	}

	if (view === ViewTypes.normal) {
		return <GettyImagesView image={block.data.preview ? block.data.preview.gettyImage : new GettyImageModel()} block={block} />;
	}

	return null;
};

export default compose(BlockUpdateController)(GettyImagesBlockComponent);
