import React, { useEffect } from 'react';
import Select from 'react-select';
import { Label } from 'reactstrap';
import { FeatureTypes } from '../../../../../services/feature-service/features.enum';
import { ContentTypes } from '../../constants/block.types';
import { mapTypesToOption, typeToOption } from './helpers/audio-type-select.helper';

export const audioTypes = [
	{ value: ContentTypes.gbVision, featureId: FeatureTypes.GB_VISION_INTEGRATION },
	{ value: ContentTypes.spotlightAudio, featureId: FeatureTypes.SPOTLIGHT_AUDIO_INTEGRATION },
];

interface AudioTypes {
	onAudioTypeSelect: (value: string) => any;
	value: string;
	t: any;
}

const AudioTypeSelect: React.FunctionComponent<AudioTypes> = ({ value, t, onAudioTypeSelect }) => {
	const audioTypeSelect = (selection: any) => {
		if (onAudioTypeSelect && selection && selection.value) {
			return onAudioTypeSelect(selection.value);
		}
		return onAudioTypeSelect('');
	};

	return (
		<>
			<Label htmlFor={'audio-type-select'}>{t('choose_audio_type')}:</Label>
			<Select
				id='audio-type-select'
				options={mapTypesToOption(audioTypes, t)}
				onChange={audioTypeSelect}
				value={typeToOption(t, value)}
				noOptionsMessage={(inputValue) => inputValue && t('no_options')}
				placeholder={t('select')}
			/>
		</>
	);
};

export default AudioTypeSelect;
