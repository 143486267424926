import * as axios from 'axios';
import REACT_APP_URLS from '../../../global-helpers/global-url.helpers';
// import HttpService from "../../../services/rest/HttpService";

const REACT_APP_NEWS_FEED_URL = REACT_APP_URLS.REACT_APP_NEWS_FEED_URL;

export default class HttpSocialService {
	static instance = (domain, profileId, url) =>
		axios.create({
			baseURL: url ? url : REACT_APP_NEWS_FEED_URL,
			timeout: 30000,
			headers: { 'X-Project': domain, 'X-USER': profileId },
		});

	static requestFeeds(domain, profileId) {
		return HttpSocialService.instance(domain, profileId).get('feeds');
	}

	static requestFeedsSources(domain, profileId, id) {
		return HttpSocialService.instance(domain, profileId).get(`feeds/${id}`);
	}

	static requestPostsByTypePeriod(domain, profileId, id, period, types) {
		return HttpSocialService.instance(domain, profileId).get(`feeds/${id}/posts?period=${period}&types=${types}`);
	}

	static requestPostsBySourcePeriod(domain, profileId, id, period, sourceId) {
		return HttpSocialService.instance(domain, profileId).get(`feeds/${id}/posts?period=${period}&sources=${sourceId}`);
	}
}
