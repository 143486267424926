import OddsV2Model from '../models/v2/odds-v2.model';
import MatchModel from '../../../../../../../models/v2/match/entity/match.model';

export const buildInitialModel = (currentOdds: OddsV2Model, match: MatchModel) => {
	const event = match ? match : new MatchModel();
	let oddsModel = new OddsV2Model();
	if (currentOdds) {
		oddsModel = currentOdds;
		oddsModel.match = event;
	} else {
		oddsModel.match = event;
		oddsModel.mainBookmaker = null;
		oddsModel.bookmakers = [];
		oddsModel.market = { name: '1x2', value: '1x2' };
		oddsModel.canSelectMarkets = false;
		oddsModel.displayMatchHeader = false;
	}

	return oddsModel;
};
