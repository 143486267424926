import EventModel from '../../models/event/event.model';
import { remapPlayerProfileLineup } from './response-remappers.helper';

export const eventTypes = [
	'GOAL',
	'OWN_GOAL',
	'PENALTY_GOAL',
	'PENALTY_MISS',
	'ASSIST',
	'YELLOW_CARD',
	'YELLOW_RED_CARD',
	'RED_CARD',
	'SUBSTITUTION',
	'PENALTY_SHOOTOUT_SCORED',
	'PENALTY_SHOOTOUT_MISSED',
];

export const mapEventType = () => {
	return eventTypes.map((type: string) => {
		return { name: type.toLowerCase(), id: type };
	});
};

export const remapEventFromResponse = (event: any) => {
	return EventModel.builder()
		.withId(event.id)
		.withMatchId(event.match_id)
		.withTeamId(event.team_id)
		.withMinute(event.minute)
		.withScore(event.score)
		.withTeamPosition(event.team_position)
		.withTypeCode(event.type_code)
		.withPrimaryPlayer(remapPlayerProfileLineup(event.primary_player))
		.withSecondaryPlayer(remapPlayerProfileLineup(event.secondary_player))
		.build();
};

export const remapEventsFromResponse = (events: any[]) => {
	if (events && events.length > 0) {
		return events.map((event: any) => remapEventFromResponse(event));
	}
	return [];
};

export const remapEventsForSave = (events: EventModel[]) => {
	if (events && events.length > 0) {
		return events.map((event: EventModel, index: number) => EventModel.builder(event).toRequestJson(index));
	}
	return [];
};
