import SportTeamModel from '../../../../partials/sports-team-select/models/sport-team.model';
import { GameWidgetJson } from './game-widget.json';
import GameWidgetBuilder from './game-widget.builder';
import GameModel from '../../../../../../../models/v2/game/game.model';
import { RefreshTimeValuesType } from '../../../../v2/partials/refresh-time-input/helper';

export default class GameWidgetModel {
	readonly game: GameModel;
	readonly teams: SportTeamModel[];
	readonly displayOdds: boolean = false;
	readonly refreshTime: RefreshTimeValuesType;

	private constructor(game: GameModel, teams: SportTeamModel[], displayOdds: boolean, refreshTime: RefreshTimeValuesType) {
		this.game = game;
		this.teams = teams;
		this.displayOdds = displayOdds;
		this.refreshTime = refreshTime;
	}

	toJson(): GameWidgetJson {
		return {
			game: this.game,
			teams: this.teams,
			displayOdds: this.displayOdds,
			refreshTime: this.refreshTime,
		};
	}

	static fromJson(json: GameWidgetJson): GameWidgetModel {
		return new GameWidgetModel(json.game, json.teams, json.displayOdds, json.refreshTime);
	}

	static builder(gameWidget?: GameWidgetModel): GameWidgetBuilder {
		return new GameWidgetBuilder(gameWidget);
	}
}
