import { YoutubeSportsJson } from './youtube-sports-json';
import YoutubeSportsBuilder from './youtube-sports.builder';
import YoutubeVideoModel from './youtube-video/youtube-video.model';

export default class YoutubeSportsModel {
	readonly title: string;
	readonly videoList: YoutubeVideoModel[];
	readonly nextPageToken: string;
	readonly prevPageToken: string;
	readonly pageInfo: any;

	private constructor(title: string, videoList: YoutubeVideoModel[], nextPageToken: string, prevPageToken: string, pageInfo: any) {
		this.title = title;
		this.videoList = videoList;
		this.nextPageToken = nextPageToken;
		this.prevPageToken = prevPageToken;
		this.pageInfo = pageInfo;
	}

	toJSON(): YoutubeSportsJson {
		return {
			title: this.title,
			videoList: this.videoList,
			nextPageToken: this.nextPageToken,
			prevPageToken: this.prevPageToken,
			pageInfo: this.pageInfo,
		};
	}

	static fromJSON(json: YoutubeSportsJson): YoutubeSportsModel {
		return new YoutubeSportsModel(json.title, json.videoList, json.nextPageToken, json.prevPageToken, json.pageInfo);
	}

	static builder(videos?: YoutubeSportsModel): YoutubeSportsBuilder {
		return new YoutubeSportsBuilder(videos);
	}
}
