import SportOddsBookmakerModel from '../../../../../../../models/sport-odds/sport-odds-bookmaker.model';
import CompetitionModel from '../../../../../../../models/v2/competition/entity/competition.model';
import BasketballSeasonModel from '../../../../../../../models/v2/basketball-season/entity/basketball-season.model';
import BasketballStageModel from '../../../../../../../models/v2/Stage/Entity/basketball-stage.model';
import BasketballRoundModel from '../../../../../../../models/v2/round/entity/basketball-round.model';
import { BasketballTeamProgrammeWidgetJson } from './basketball-team-programme.json';
import BasketballTeamProgrammeWidgetModel from './basketball-team-programme.model';
import SportTeamModel from '../../../../partials/sports-team-select/models/sport-team.model';
import { RefreshTimeValuesType } from '../../../partials/refresh-time-input/helper';

export default class BasketballTeamProgrammeWidgetBuilder {
	readonly json: BasketballTeamProgrammeWidgetJson;

	constructor(model?: BasketballTeamProgrammeWidgetModel | BasketballTeamProgrammeWidgetJson) {
		if (model && model instanceof BasketballTeamProgrammeWidgetModel) {
			this.json = model.toJson();
		} else if (model) {
			this.json = model;
		} else {
			this.json = {} as BasketballTeamProgrammeWidgetJson;
		}
	}

	withTeam = (team: SportTeamModel): BasketballTeamProgrammeWidgetBuilder => {
		this.json.team = team;

		return this;
	};

	withComeptition = (competition: CompetitionModel): BasketballTeamProgrammeWidgetBuilder => {
		this.json.competition = competition;

		return this;
	};

	withSeason = (season: BasketballSeasonModel): BasketballTeamProgrammeWidgetBuilder => {
		this.json.season = season;

		return this;
	};

	withStage = (stage: BasketballStageModel): BasketballTeamProgrammeWidgetBuilder => {
		this.json.stage = stage;

		return this;
	};

	withRounds = (rounds: BasketballRoundModel[]): BasketballTeamProgrammeWidgetBuilder => {
		this.json.rounds = rounds;

		return this;
	};

	withSortDirectionResults = (sortDirectionResults: string): BasketballTeamProgrammeWidgetBuilder => {
		this.json.sortDirectionResults = sortDirectionResults;

		return this;
	};

	withSortDirectionFixtures = (sortDirectionFixtures: string): BasketballTeamProgrammeWidgetBuilder => {
		this.json.sortDirectionFixtures = sortDirectionFixtures;

		return this;
	};

	withDisplayOdds(displayOdds: boolean): BasketballTeamProgrammeWidgetBuilder {
		this.json.displayOdds = displayOdds;

		return this;
	}

	withBookmakers(bookmakers: SportOddsBookmakerModel[] | null): BasketballTeamProgrammeWidgetBuilder {
		this.json.bookmakers = bookmakers;

		return this;
	}

	withMatchType = (matchType: string): BasketballTeamProgrammeWidgetBuilder => {
		this.json.matchType = matchType;

		return this;
	};

	withRefreshTime(refreshTime: RefreshTimeValuesType): BasketballTeamProgrammeWidgetBuilder {
		this.json.refreshTime = refreshTime;

		return this;
	}

	build(): BasketballTeamProgrammeWidgetModel {
		return BasketballTeamProgrammeWidgetModel.fromJson(this.json);
	}
}
