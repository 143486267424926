import TeamFormWidgetModel from './team-form-widget.model';
import { TeamFormWidgetJson } from './team-form-widget.json';
import TeamModel from '../../../../partials/team-select/models/team.model';
import TournamentModel from '../../player-profile/models/tournament-model/tournament.model';
import SeasonModel from '../../player-profile/models/season/season.model';

export default class TeamFormWidgetBuilder {
	readonly json: TeamFormWidgetJson;

	constructor(team?: TeamFormWidgetModel | TeamFormWidgetJson) {
		if (team && team instanceof TeamFormWidgetModel) {
			this.json = team.toJson();
		} else if (team) {
			this.json = team;
		} else {
			this.json = {} as TeamFormWidgetJson;
		}
	}

	withTeam(team: TeamModel): TeamFormWidgetBuilder {
		this.json.team = team;
		return this;
	}

	withTournament(tournament: TournamentModel[]): TeamFormWidgetBuilder {
		this.json.tournament = tournament;
		return this;
	}

	withLimit(limit: string): TeamFormWidgetBuilder {
		this.json.limit = limit;
		return this;
	}

	withSeason(season: SeasonModel): TeamFormWidgetBuilder {
		this.json.season = season;
		return this;
	}

	withTitle(title: string): TeamFormWidgetBuilder {
		this.json.title = title;
		return this;
	}

	withNoData(noData: boolean): TeamFormWidgetBuilder {
		this.json.noData = noData;
		return this;
	}

	withThreeLetterCodeType(threeLetterCodeType: any): TeamFormWidgetBuilder {
		this.json.threeLetterCodeType = threeLetterCodeType;

		return this;
	}

	build(): TeamFormWidgetModel {
		return TeamFormWidgetModel.fromJson(this.json);
	}
}
