import ContentAttributes from '../models/content-attributes/content-attributes-model';

// This is done because we want the clients to have backward compatibility
// Other way is to logout all clients but this is not the best approach
// This logic should be removed after 2-3 months
export const propertiesCompatibilityData = {
	articleProperties: [
		{
			id: '2023031708425265136',
			name: 'Live',
			slug: 'live',
			isDefault: false,
		},
		{
			id: '2023031708425377801',
			name: 'Important',
			slug: 'important',
			isDefault: false,
		},
		{
			id: '2023031708425412524',
			name: 'Ads enabled',
			slug: 'run_ads',
			isDefault: true,
		},
		{
			id: '2023031708425557489',
			name: 'Adult content',
			slug: 'is_adult_content',
			isDefault: false,
		},
		{
			id: '2023031708425641986',
			name: 'Sensitive content',
			slug: 'is_sensitive_content',
			isDefault: false,
		},
		{
			id: '2023031708425796346',
			name: 'Betting content',
			slug: 'betting_content',
			isDefault: false,
		},
	] as ContentAttributes[],
	videoProperties: [
		{
			id: '2023031708426305221',
			name: 'Ads enabled',
			slug: 'run_ads',
			isDefault: true,
		},
		{
			id: '2023031708426467460',
			name: 'Adult content',
			slug: 'is_adult_content',
			isDefault: false,
		},
		{
			id: '2023031708426581184',
			name: 'Sensitive content',
			slug: 'is_sensitive_content',
			isDefault: false,
		},
		{
			id: '2023031708426681082',
			name: 'Important',
			slug: 'important',
			isDefault: false,
		},
		{
			id: '2023031708426735436',
			name: 'Betting content',
			slug: 'betting_content',
			isDefault: false,
		},
	] as ContentAttributes[],
	galleryProperties: [
		{
			id: '2023031708425911931',
			name: 'Ads enabled',
			slug: 'run_ads',
			isDefault: true,
		},
		{
			id: '2023031708426037002',
			name: 'Adult content',
			slug: 'is_adult_content',
			isDefault: false,
		},
		{
			id: '2023031708426158544',
			name: 'Sensitive content',
			slug: 'is_sensitive_content',
			isDefault: false,
		},
		{
			id: '2023031708426233908',
			name: 'Betting content',
			slug: 'betting_content',
			isDefault: false,
		},
	] as ContentAttributes[],
	wikipageProperties: [
		{
			id: '2023031708426806308',
			name: 'Important',
			slug: 'important',
			isDefault: false,
		},
		{
			id: '2023031708426903092',
			name: 'Ads enabled',
			slug: 'run_ads',
			isDefault: true,
		},
	] as ContentAttributes[],
	bannerProperties: [
		{
			id: '2023031708425867105',
			name: 'Is Preferred?',
			slug: 'is_preferred',
			isDefault: false,
		},
	] as ContentAttributes[],
};
