import React from 'react';
import { featuresService } from '../../../App';
import { FeatureTypes } from '../../../services/feature-service/features.enum';
import { useTranslation } from 'react-i18next';
import { DiscussionsManager } from 'fansunited-widget-discussions';

export const DiscussionsManagerComponent: React.FunctionComponent = () => {
	const config = featuresService.getFeatureConfig(FeatureTypes.FANS_UNITED);
	const [t] = useTranslation();
	const labels = {
		userNotFound: t('fu_userNotFound'),
		noPostsFound: t('fu_noPostsFound'),
		posts: t('fu_posts'),
		users: t('fu_users'),
		dictionary: t('fu_dictionary'),
		sortSelectPlaceholder: t('fu_sortSelectPlaceholder'),
		typeSelectPlaceholder: t('fu_typeSelectPlaceholder'),
		moderatedLabelCheckbox: t('fu_moderatedLabelCheckbox'),
		deletedLabelCheckbox: t('fu_deletedLabelCheckbox'),
		profileIdsSelectPlaceholder: t('fu_profileIdsSelectPlaceholder'),
		discussionsSelectPlaceholder: t('fu_discussionsSelectPlaceholder'),
		loading: t('fu_loading'),
		noOptionsMessage: t('fu_noOptionsMessage'),
		moderated: t('fu_moderated'),
		onDate: t('fu_onDate'),
		reason: t('fu_reason'),
		user: t('fu_user'),
		deletedAt: t('fu_deletedAt'),
		showOlderVersions: t('fu_showOlderVersions'),
		moderateLabel: t('fu_moderateLabel'),
		timeFormat: t('fu_timeFormat'),
		inDiscussion: t('fu_inDiscussion'),
		moderationInfo: t('fu_moderationInfo'),
		byStaffMemberWithId: t('fu_byStaffMemberWithId'),
		at: t('fu_at'),
		na: t('fu_na'),
		filterBy: t('fu_filterBy'),
		sortBy: t('fu_sortBy'),
		moderatePosts: t('fu_moderatePosts'),
		previous: t('fu_previous'),
		next: t('fu_next'),
		name: t('fu_name'),
		tier: t('fu_tier'),
		points: t('fu_points'),
		moderate: t('fu_moderate'),
		ban: t('fu_ban'),
		deleteLabel: t('fu_deleteLabel'),
		moderateLinks: t('fu_moderateLinks'),
		linksWhitelistDescription: t('fu_linksWhitelistDescription'),
		moderatorId: t('fu_moderatorId'),
		moderatorIdDescription: t('fu_moderatorIdDescription'),
		updateDiscussionsFeatureMessage: t('fu_updateDiscussionsFeatureMessage'),
		updateDiscussionsFeatureErrorMessage: t('fu_updateDiscussionsFeatureErrorMessage'),
		savingStaffIdErrorMessage: t('fu_savingStaffIdErrorMessage'),
		getDiscussionsFeatureErrorMessage: t('fu_getDiscussionsFeatureErrorMessage'),
		forbiddenWordsDescription: t('fu_forbiddenWordsDescription'),
		moderationReason: t('fu_moderationReason'),
		save: t('fu_save'),
		cancel: t('cancel'),
		getPostsErrorMessage: t('fu_getPostsErrorMessage'),
		getProfilesErrorMessage: t('fu_getProfilesErrorMessage'),
		getDiscussionsErrorMessage: t('fu_getDiscussionsErrorMessage'),
		validationBanProfileErrorMessage: t('fu_validationBanProfileErrorMessage'),
		banProfileMessage: t('fu_banProfileMessage'),
		banProfileErrorMessage: t('fu_banProfileErrorMessage'),
		banPeriodLabel: t('fu_banPeriodLabel'),
		banReasonLabel: t('fu_banReasonLabel'),
		day: t('fu_day'),
		days: t('fu_days'),
		indefinite: t('fu_indefinite'),
		customPeriod: t('fu_customPeriod'),
		moderateProfileModalDescription: t('fu_moderateProfileModalDescription'),
		moderateProfileMessage: t('fu_moderateProfileMessage'),
		moderateProfileErrorMessage: t('fu_moderateProfileErrorMessage'),
		validationModerateReasonErrorMessage: t('fu_validationModerateReasonErrorMessage'),
		moderatePostsMessage: t('fu_moderatePostsMessage'),
		moderatePostsErrorMessage: t('fu_moderatePostsErrorMessage'),
	};

	return config &&
		config.configuration &&
		config.configuration.api_key &&
		config.configuration.client_id &&
		config.configuration.language ? (
		<div className='p-4'>
			<DiscussionsManager
				fansUnitedApiKey={config.configuration.api_key}
				fansUnitedLanguage={config.configuration.language}
				fansUnitedClientId={config.configuration.client_id}
				labels={labels}
			/>
		</div>
	) : null;
};
