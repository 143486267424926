import * as React from 'react';

type Properties = {
	display: boolean;
	editContentPath: string;
	history: any;
	t: any;
	analytics: any;
	contentId?: string;
	contentType?: string;
};

export const RowEditButton: React.FunctionComponent<Properties> = ({ display, editContentPath, history, t, analytics, contentId }) => {
	const btnStyle = 'm-1 btn btn-sm btn-warning d-flex flex-fill align-items-center justify-content-center cursor-pointer';

	const onEditContent = () => {
		analytics.sendEvent('Click', 'List Page', 'Edit Button');
		history.push(editContentPath);
	};

	if (display) {
		return (
			<div id={`content-edit-btn-${contentId}`} className={btnStyle} onClick={onEditContent}>
				<i className='fa fa-pencil p-1' />
				{t('edit')}
			</div>
		);
	}

	return null;
};
