import 'reflect-metadata';
import { jsonArrayMember, jsonMember, jsonObject } from 'typedjson';
import MultisportCompetitionModelWrapper from './competition-wrapper.model';

@jsonObject()
export default class MultisportModel {
	@jsonMember(String)
	public sport: string = '';
	@jsonArrayMember(MultisportCompetitionModelWrapper)
	public competitions: MultisportCompetitionModelWrapper[] = [];
}
