import { FootballTopScorersJson } from './football-top-scorers.json';
import FootballTopScorersModel from './football-top-scorers.model';
import TournamentModel from '../../../../../../../models/v2/Tournament/Entity/TournamentModel';
import PlayerModel from '../../../../../../../models/v2/player/entity/player.model';
import TeamModel from '../../../../../../../models/v2/Team/Entity/TeamModel';
import SeasonModel from '../../../../../../../models/v2/Season/Entity/SeasonModel';
import { RefreshTimeValuesType } from '../../refresh-time-input/helper';

export default class FootballTopScorersBuilder {
	readonly json: FootballTopScorersJson;

	constructor(topScorers?: FootballTopScorersModel | FootballTopScorersJson) {
		if (topScorers && topScorers instanceof FootballTopScorersModel) {
			this.json = topScorers.toJson();
		} else if (topScorers) {
			this.json = topScorers;
		} else {
			this.json = {} as FootballTopScorersJson;
		}
	}

	withTournament(tournament: TournamentModel): FootballTopScorersBuilder {
		this.json.tournament = tournament;

		return this;
	}

	withSeason(season: SeasonModel): FootballTopScorersBuilder {
		this.json.season = season;

		return this;
	}

	withTopScorers(topScorers: PlayerModel[]): FootballTopScorersBuilder {
		this.json.topScorers = topScorers;

		return this;
	}

	withPlayers(players: PlayerModel[]): FootballTopScorersBuilder {
		this.json.players = players;

		return this;
	}

	withTeams(teams: TeamModel[]): FootballTopScorersBuilder {
		this.json.teams = teams;

		return this;
	}

	withFilter(filter: boolean): FootballTopScorersBuilder {
		this.json.filter = filter;

		return this;
	}

	withLimit(limit: string): FootballTopScorersBuilder {
		this.json.limit = limit;

		return this;
	}

	withStartFrom(startFrom: string): FootballTopScorersBuilder {
		this.json.startFrom = startFrom;

		return this;
	}

	withStats(stats: string[]): FootballTopScorersBuilder {
		this.json.stats = stats;

		return this;
	}

	withRefreshTime(refreshTime: RefreshTimeValuesType): FootballTopScorersBuilder {
		this.json.refreshTime = refreshTime;

		return this;
	}

	build(): FootballTopScorersModel {
		return FootballTopScorersModel.fromJson(this.json);
	}
}
