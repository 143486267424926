import React from 'react';
import { Col, FormGroup, Label, Row, Input } from 'reactstrap';
import HighlightBlockModel from './models/highlight-block.model';
import { Properties, State } from './properties/highlight-block-edit.properties';
import ErrorHandler from '../../../partials/error/error-handler-component';
import BlockValidation from '../../../helpers/block-validation.helper';
import { blockWrapperService, blockManagementService } from '../../../subcomponents/blocky.component';

export default class HighlightEdit extends React.Component<Properties, State> {
	constructor(props: Properties) {
		super(props);
		this.state = {
			highlightBlock:
				props.block.data && props.block.data.preview
					? props.block.data.preview.highlightBlock
					: HighlightBlockModel.builder().withDescription('').withTitle('').build(),
			isValid: true,
		};
	}

	componentDidMount(): void {
		blockWrapperService.registerOnSaveFunc({ blockId: this.props.block.id, func: this.onSave });
	}

	onSave = (index: number) => {
		const data = { highlightBlock: this.state.highlightBlock };
		const validation = new BlockValidation();
		const isValid = validation.validate(this.props.block, data);
		this.setState({ ...this.state, isValid });

		if (isValid) {
			const block = Object.assign({}, this.props.block);
			block.data.title = this.state.highlightBlock.title;
			block.data.description = this.state.highlightBlock.description;
			block.data.preview = { highlightBlock: this.state.highlightBlock };
			blockManagementService.blockUpdate(block, index);
		} else {
			return isValid;
		}
	};

	componentWillUnmount(): void {
		blockWrapperService.unregisterOnSaveFunc(this.props.block.id);
	}

	updateDescription = (e: any) => {
		const { highlightBlock } = this.state;
		this.setState({
			...this.state,
			highlightBlock: HighlightBlockModel.builder(highlightBlock).withDescription(e.target.value).build(),
		});
	};

	updateTitle = (e: any) => {
		const { highlightBlock } = this.state;
		this.setState({
			...this.state,
			highlightBlock: HighlightBlockModel.builder(highlightBlock).withTitle(e.target.value).build(),
		});
	};

	render() {
		const { highlightBlock, isValid } = this.state;

		return (
			<>
				<Row>
					<Col>
						<FormGroup>
							<Label>{this.props.t('title')}:</Label>
							<Input type='text' value={highlightBlock.title} onChange={this.updateTitle} />
						</FormGroup>
					</Col>
				</Row>
				{!isValid && (!highlightBlock.title || (highlightBlock.title && highlightBlock.title.length === 0)) && (
					<ErrorHandler errorMessage='field_is_required' />
				)}
				<Row>
					<Col>
						<FormGroup>
							<Label>{this.props.t('description')}:</Label>
							<Input type='textarea' rows={5} value={highlightBlock.description} onChange={this.updateDescription} />
						</FormGroup>
					</Col>
				</Row>
				<Row className='mb-2'>
					<Col>
						{!isValid && (!highlightBlock.description || (highlightBlock.description && highlightBlock.description.length === 0)) && (
							<ErrorHandler errorMessage='field_is_required' />
						)}
					</Col>
				</Row>
			</>
		);
	}
}
