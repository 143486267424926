import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import MainMediaModel from '../../../../../../models/v2/main-media/main-media.model';
import { autoGenerateReduxPropsNamingForContentModels } from '../../../../../../services/content-models-service/ContentModelService';
import ConditionalRenderWrapper from '../../../../BaseComponents/ConditionalRenderWraper/ConditionalRenderWrapper';
import Project from '../../../../../../models/project/Project';
import { featuresService } from '../../../../../../App';
import { FeatureTypes } from '../../../../../../services/feature-service/features.enum';
import ImageEditModal from '../../../../../Resources/Images/Subcomponents/image-edit-modal/ImageEditModal';
import { extractMainImageObjectFromArray } from '../../helpers/general';
import { DATA_QA_ATTRIBUTES } from '../../constants/data-qa.attributes';

type Properties = {
	project: Project;
	mainMediaImage: MainMediaModel | null;
	t: any;
};

const EditMainImage: React.FunctionComponent<Properties> = ({ t, project, mainMediaImage }) => {
	const [isModalOpen, setIsModalOpen] = useState(false);

	return (
		<>
			<ConditionalRenderWrapper expectedPermissions={['write_images']}>
				<Button
					id={DATA_QA_ATTRIBUTES.EDIT_IMAGE}
					disabled={!mainMediaImage}
					block
					color='warning'
					className='edit-button'
					onClick={() => setIsModalOpen(true)}
				>
					<i className='fa fa-pencil' /> {t('edit_image')}
				</Button>
			</ConditionalRenderWrapper>
			{mainMediaImage && (
				<ImageEditModal
					onClose={(imageUpdated: boolean, watermarkAdded: boolean, watermarkRemoved: boolean) => setIsModalOpen(false)}
					imageId={mainMediaImage.resourceId}
					currentProject={project}
					open={isModalOpen}
					watermarkEnabled={featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.WATERMARK)}
					t={t}
					isPlayingSurface={false}
					isImagoImage={false}
				/>
			)}
		</>
	);
};

function mapStateToProps(state: any) {
	const { tempPropertyNaming, nestedTempPropertyNaming } = autoGenerateReduxPropsNamingForContentModels();
	const mainMediaRedux = state[tempPropertyNaming][nestedTempPropertyNaming].mainMedia || [];

	return {
		mainMediaImage: extractMainImageObjectFromArray(mainMediaRedux),
		project: state.project.currentProject,
	};
}

export default connect(mapStateToProps)(EditMainImage);
