import Related from '../../models/related/Related';

export const ARTICLE_RELATED_MATCH_TEMP_UPDATE = '[ACTION] ARTICLE_RELATED_MATCH_TEMP_UPDATE';
export const ARTICLE_EDIT_RELATED_MATCH_TEMP_UPDATE = '[ACTION] ARTICLE_EDIT_RELATED_MATCH_TEMP_UPDATE';
export const ARTICLE_RELATED_MATCH_TEMP_CLEAR = '[ACTION] ARTICLE_RELATED_MATCH_TEMP_CLEAR';

export const VIDEO_RELATED_MATCH_TEMP_UPDATE = '[ACTION] VIDEO_RELATED_MATCH_TEMP_UPDATE';
export const VIDEO_EDIT_RELATED_MATCH_TEMP_UPDATE = '[ACTION] VIDEO_EDIT_RELATED_MATCH_TEMP_UPDATE';
export const VIDEO_RELATED_MATCH_TEMP_CLEAR = '[ACTION] VIDEO_RELATED_MATCH_TEMP_CLEAR';

export const GALLERY_RELATED_MATCH_TEMP_UPDATE = '[ACTION] GALLERY_RELATED_MATCH_TEMP_UPDATE';
export const GALLERY_EDIT_RELATED_MATCH_TEMP_UPDATE = '[ACTION] GALLERY_EDIT_RELATED_MATCH_TEMP_UPDATE';
export const GALLERY_RELATED_MATCH_TEMP_CLEAR = '[ACTION] GALLERY_RELATED_MATCH_TEMP_CLEAR';

export const WIKI_RELATED_MATCH_TEMP_UPDATE = '[ACTION] WIKI_RELATED_MATCH_TEMP_UPDATE';
export const WIKI_EDIT_RELATED_MATCH_TEMP_UPDATE = '[ACTION] WIKI_EDIT_RELATED_MATCH_TEMP_UPDATE';
export const WIKI_RELATED_MATCH_TEMP_CLEAR = '[ACTION] WIKI_RELATED_MATCH_TEMP_CLEAR';

export function updateArticleMatchTempRelated(related: Related[]) {
	return {
		type: ARTICLE_RELATED_MATCH_TEMP_UPDATE,
		payload: related,
	};
}

export function updateArticleEditMatchTempRelated(related: Related[]) {
	return {
		type: ARTICLE_EDIT_RELATED_MATCH_TEMP_UPDATE,
		payload: related,
	};
}

export function clearArticleMatchTempRelated() {
	return {
		type: ARTICLE_RELATED_MATCH_TEMP_CLEAR,
	};
}

export function updateVideoMatchTempRelated(related: Related[]) {
	return {
		type: VIDEO_RELATED_MATCH_TEMP_UPDATE,
		payload: related,
	};
}

export function updateVideoEditMatchTempRelated(related: Related[]) {
	return {
		type: VIDEO_EDIT_RELATED_MATCH_TEMP_UPDATE,
		payload: related,
	};
}

export function clearVideoMatchTempRelated() {
	return {
		type: VIDEO_RELATED_MATCH_TEMP_CLEAR,
	};
}

export function updateGalleryMatchTempRelated(related: Related[]) {
	return {
		type: GALLERY_RELATED_MATCH_TEMP_UPDATE,
		payload: related,
	};
}

export function updateGalleryEditMatchTempRelated(related: Related[]) {
	return {
		type: GALLERY_EDIT_RELATED_MATCH_TEMP_UPDATE,
		payload: related,
	};
}

export function clearGalleryMatchTempRelated() {
	return {
		type: GALLERY_RELATED_MATCH_TEMP_CLEAR,
	};
}

export function updateWikiMatchTempRelated(related: Related[]) {
	return {
		type: WIKI_RELATED_MATCH_TEMP_UPDATE,
		payload: related,
	};
}

export function updateWikiEditMatchTempRelated(related: Related[]) {
	return {
		type: WIKI_EDIT_RELATED_MATCH_TEMP_UPDATE,
		payload: related,
	};
}

export function clearWikiMatchTempRelated() {
	return {
		type: WIKI_RELATED_MATCH_TEMP_CLEAR,
	};
}
