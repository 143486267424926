import * as React from 'react';
import Select from 'react-select';
import { originToSelectOption, originsToSelectOptions } from './helpers/origin-select.helper';
import ContentAttributes from '../../../../../../models/content-attributes/content-attributes-model';

type Properties = {
	selectedOrigin: ContentAttributes;
	origins: ContentAttributes[];
	onOriginSelect: (arg0: any) => any;
	t: any;
	isClearable: boolean;
};

const OriginSelect: React.FunctionComponent<Properties> = (props) => {
	const { selectedOrigin, origins, onOriginSelect, t, isClearable } = props;

	const onContentChange = (selection: any) => {
		onOriginSelect(selection && selection.value ? selection.origin : '');
	};

	return (
		<Select
			value={selectedOrigin && selectedOrigin.id ? originToSelectOption(selectedOrigin) : null}
			className={'mb-1'}
			options={originsToSelectOptions(origins)}
			onChange={onContentChange}
			placeholder={t('select')}
			isClearable={isClearable}
		/>
	);
};

export default OriginSelect;
