import i18n from 'i18next';
import en from './locales/en-US';
import bg from './locales/bg-BG';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

i18n
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		resources: {
			en: { ...en },
			bg: { ...bg },
		},
		fallbackLng: 'en',
		debug: false,
		ns: ['translations'],
		defaultNS: 'translations',
		interpolation: {
			escapeValue: false,
			formatSeparator: ',',
		},
	});

export default i18n;
