import React from 'react';
import { Button, Col, Label, Row } from 'reactstrap';
import { ContentTypes } from '../../../constants/content-types';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Properties, State } from './Properties/TagEditProperties';
import { Title } from '../../Partials/Fields/title/TitleComponent';
import Tag from '../../../models/tag/Tag';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Project from '../../../models/project/Project';
import {
	returnObjectForTagEntityRequest,
	returnObjectForTagEntityUpdate,
	TAG_ENTITY_CREATE,
	TAG_ENTITY_CREATE_FAILED,
	TAG_ENTITY_CREATE_SUCCESS,
	TAG_ENTITY_RECEIVED,
	TAG_ENTITY_REQUEST,
} from '../../../store/action-creators/TagActionCreators';
import { Description } from '../../Partials/Fields/description/DescriptionComponent';
import Urls from '../../Partials/Sidebar/Urls/urls.component';
import Seo from '../../Partials/Sidebar/Seo/SeoComponent';
import { UrlsComponentModel } from '../../Partials/Sidebar/Urls/models/UrlsComponentModel';
import {
	checkIfValidForSave,
	ORDERED_TAGS_LIMIT,
	seoComponentModelToTag,
	tagToSeoComponentModel,
	tagToUrlsComponentModelForEdit,
	urlsComponentModelToCategory,
} from './Helpers/TagHelper';
import { SeoComponentModel } from '../../Partials/Sidebar/Seo/models/SeoComponentModel';
import MainMedia from '../../../models/mainmedia/MainMedia';
import MainMediaContainer from '../../Partials/Sidebar/Media/MainMediaListContainer';
import BlockableButtonWrapper from '../../Partials/BaseComponents/BlockableButton/BlockableButtonWrapper';
import { actionService } from '../../../App';
import SeoModel from '../../../models/seo/Seo';
import SidebarCollapsibleElements from '../../Partials/Sidebar/sidebar-collapsible-elements/sidebar-collapsible-elements';
import GeneralContentTypeAttribute from '../../Partials/Sidebar/general-content-attribute-custom/general-content-attribute-custom';
import { GeneralContentCustomModel } from '../../Partials/Sidebar/general-content-attribute-custom/models/general-content-attribute-custom.model';
import SidebarElementsToggle from '../../Partials/Sidebar/sidebar-collapsible-elements/sidebar-elements-toggle/sidebar-elements-toggle.component';
import { Subscription } from 'rxjs';
import { OrderTypeSelect } from '../../Partials/Fields/tag-order-type/order-type-select.component';
import HttpService from '../../../services/rest/HttpService';
import {
	ContentSidebarChangeCollapseStatus,
	onChangeCollapseStatus,
	onSidebarElemSort,
	toggleSidebarEditMode,
} from '../../../services/content-sidebar/content-sidebar.helper';
import { FormattedSidebarSectionValue } from '../../Partials/Sidebar/sidebar-collapsible-elements/utils/temp-sidebar.types';
import GlobalModal from '../../Partials/Modals/Global/global-modal';
import {
	appendBeforeUnloadResetTempSidebar,
	defaultTempSidebarFromApiResponse,
	overwriteTempSidebarByContentType,
	saveSidebarSettingsByContentType,
} from '../../Partials/Sidebar/sidebar-collapsible-elements/utils/temp-sidebar.helper';

class TagEdit extends React.Component<Properties, State> {
	actionServiceSubscription: Subscription = {} as Subscription;

	constructor(props: Properties) {
		super(props);
		this.state = {
			tag: Tag.builder(props.tag).build(),
			isContentLoading: false,
			isSidebarInEdit: false,
			displayOrderTypeLimitMessage: false,
			orderedTags: 0,
			openSidebarSettingsModalFlag: false,
		};
		this.registerActionEventListener();
	}

	registerActionEventListener() {
		this.actionServiceSubscription = actionService.onActionReceived().subscribe((action: string) => {
			if (action === TAG_ENTITY_REQUEST) {
				this.toggleContentLoadingState(true);
			}

			if (action === TAG_ENTITY_RECEIVED) {
				this.toggleContentLoadingState(false);
			}
		});
	}

	toggleContentLoadingState(isLoading: boolean) {
		this.setState({
			...this.state,
			isContentLoading: isLoading,
		});
	}

	componentDidMount(): void {
		let headers = { Project: this.props.project.domain };
		let totalOrderedNumber;
		this.initPageTitle();
		this.props.getTag(this.props.match.params.id, this.props.project);
		appendBeforeUnloadResetTempSidebar(ContentTypes.TAG);

		HttpService.get(`/tags?order_type=ordered`, null, headers).then((response: any) => {
			if (response && response.data) {
				totalOrderedNumber = response.data.meta && response.data.meta.pagination && response.data.meta.pagination.total;
				this.setState({ orderedTags: totalOrderedNumber });
			}
		});
	}

	componentDidUpdate(prevProps: Readonly<Properties>, prevState: Readonly<State>, snapshot?: any): void {
		if (!Tag.builder(prevProps.tag).equals(this.props.tag)) {
			this.setState({ tag: this.props.tag });
		}
	}

	componentWillUnmount() {
		this.actionServiceSubscription.unsubscribe();
	}

	initPageTitle() {
		document.title = this.props.t('tag_edit');
	}

	onTitleChange(title: string) {
		const seo = SeoModel.builder(this.state.tag.seo).withAutoUpdateSeo(title).build();
		const tag = Tag.builder(this.state.tag).withSeo(seo).withTitle(title).build();
		this.setState({ tag });
	}

	onOrderTypeChange(orderType: any) {
		const tag = Tag.builder(this.state.tag).withOrderType(orderType).build();
		this.setState({ tag });

		if (checkIfValidForSave(tag, this.state.orderedTags) && tag.weight >= ORDERED_TAGS_LIMIT) {
			this.setState({ displayOrderTypeLimitMessage: true });
		} else {
			this.setState({ displayOrderTypeLimitMessage: false });
		}
	}

	onSeoChange(seoModel: SeoComponentModel) {
		const tag = seoComponentModelToTag(seoModel, this.state.tag);
		this.setState({ tag });
	}

	onMainMediaChange(mainMedia: MainMedia[]) {
		let tag = Tag.builder(this.state.tag).withMainMedia(mainMedia).build();
		this.setState({ tag });
	}

	onUrlsChange(urlsModel: UrlsComponentModel) {
		const tag = urlsComponentModelToCategory(urlsModel, this.state.tag);
		this.setState({ tag });
	}

	onDescriptionChange(description: string) {
		const tag = Tag.builder(this.state.tag).withDescription(description).build();
		this.setState({ tag });
	}

	onSubmit() {
		this.props.updateTag(this.state.tag, this.props.project);
	}

	onGeneralContentChange(data: GeneralContentCustomModel) {
		let tag = Tag.builder(this.state.tag)
			.withType(data.type ? data.type : '')
			.build();
		this.setState({ ...this.state, tag });
	}

	toggleSidebarEditMode = () => {
		const newState = toggleSidebarEditMode({ ...this.state }, ContentTypes.TAG);
		this.setState(newState);
	};

	render() {
		const { t, project, types } = this.props;
		const { isSidebarInEdit, displayOrderTypeLimitMessage, openSidebarSettingsModalFlag } = this.state;
		const seoModel = tagToSeoComponentModel(this.state.tag);
		const urlsModel = tagToUrlsComponentModelForEdit(this.state.tag);
		const sidebarComponentsMap = {
			general: (
				<GeneralContentTypeAttribute
					onChange={this.onGeneralContentChange.bind(this)}
					value={{ type: this.state.tag.type, status: '' }}
					contentType={ContentTypes.TAG}
					isNewContent={false}
				/>
			),
			media: (
				<MainMediaContainer
					applyWatermark={false}
					onChange={this.onMainMediaChange.bind(this)}
					value={this.state.tag ? this.state.tag.mainMedia : ({} as MainMedia[])}
					currentProject={project}
					contentType={ContentTypes.TAG}
				/>
			),
			urls: <Urls contentType={ContentTypes.TAG} onChange={this.onUrlsChange.bind(this)} value={urlsModel} />,
			seo: <Seo onChange={this.onSeoChange.bind(this)} value={seoModel} />,
		};
		return (
			<div className='animated fadeIn'>
				<Row>
					<Col col='8' lg='8' md='12' sm='12' xs='12'>
						<div className='card'>
							<div className='card-header'>
								<div className={'d-flex align-items-center'}>
									<span className={'mr-auto mb-0 text-truncate'}>
										{t('edit')}: {this.props.tag ? this.props.tag.title : ''}
									</span>
									{displayOrderTypeLimitMessage ? (
										<Button
											disabled={true}
											size='sm'
											color='primary'
											id='tag-create-save-top'
											className={'ml-auto'}
											onClick={this.onSubmit.bind(this)}
										>
											<i className='fa fa-floppy-o'></i> {t('save_tag')}
										</Button>
									) : (
										<BlockableButtonWrapper
											blockOnActions={[TAG_ENTITY_CREATE]}
											releaseOnActions={[TAG_ENTITY_CREATE_FAILED, TAG_ENTITY_CREATE_SUCCESS]}
										>
											<Button size='sm' color='primary' id='tag-create-save-top' className={'ml-auto'} onClick={this.onSubmit.bind(this)}>
												<i className='fa fa-floppy-o'></i> {t('save_tag')}
											</Button>
										</BlockableButtonWrapper>
									)}
								</div>
							</div>
							<div className='card-body'>
								<div className={`${this.state.isContentLoading ? 'loading-overlay' : ''}`}>
									<Row>
										<Col xs='12'>
											<Label htmlFor='title'>{t('title')}</Label>
											<Title t={t} onChange={this.onTitleChange.bind(this)} value={this.state.tag ? this.state.tag.title : ''} />
										</Col>
									</Row>
									<Row>
										<Col xs='12'>
											<Label htmlFor='order_type'>{t('order_type')}</Label>
											<OrderTypeSelect t={t} onChange={this.onOrderTypeChange.bind(this)} value={this.state.tag ? this.state.tag.orderType : ''} />
										</Col>
										{displayOrderTypeLimitMessage && (
											<Row style={{ marginTop: '-25px', marginLeft: '5px' }}>
												<div className={'alert alert-danger'}>{this.props.t('ordered_tags_limit_exceeded')}</div>
											</Row>
										)}
									</Row>
									<Row>
										<Col xs='12'>
											<Description
												t={t}
												onChange={this.onDescriptionChange.bind(this)}
												showValidation={true}
												value={this.state.tag ? this.state.tag.description : ''}
											/>
										</Col>
									</Row>
									{displayOrderTypeLimitMessage ? (
										<Row className={'mt-4'}>
											<Col xs='12'>
												<Button
													disabled={true}
													size='sm'
													color='primary'
													id='tag-create-save-top'
													className={'ml-auto'}
													onClick={this.onSubmit.bind(this)}
												>
													<i className='fa fa-floppy-o'></i> {t('save_tag')}
												</Button>
											</Col>
										</Row>
									) : (
										<Row className={'mt-4'}>
											<Col xs='12'>
												<BlockableButtonWrapper
													blockOnActions={[TAG_ENTITY_CREATE]}
													releaseOnActions={[TAG_ENTITY_CREATE_FAILED, TAG_ENTITY_CREATE_SUCCESS]}
												>
													<Button size='sm' color='primary' id='tag-create-save-top' className={'ml-auto'} onClick={this.onSubmit.bind(this)}>
														<i className='fa fa-floppy-o'></i> {t('save_tag')}
													</Button>
												</BlockableButtonWrapper>
											</Col>
										</Row>
									)}
								</div>
							</div>
						</div>
					</Col>
					<Col col='4' lg='4' md='12' sm='12' xs='12' className='position-relative'>
						<SidebarElementsToggle t={t} toggleSidebarEditMode={this.toggleSidebarEditMode} isSidebarInEdit={isSidebarInEdit} />
						<SidebarCollapsibleElements
							isSidebarInEdit={isSidebarInEdit}
							onElemSort={(settingsValue: FormattedSidebarSectionValue[]) => onSidebarElemSort(settingsValue, ContentTypes.TAG)}
							onChangeCollapseStatus={(data: ContentSidebarChangeCollapseStatus) => onChangeCollapseStatus(data)}
							contentType={ContentTypes.TAG}
							sidebarComponentsMap={sidebarComponentsMap}
							t={t}
							types={types[`${ContentTypes.TAG}Types`]}
							statuses={[]}
						/>
					</Col>
				</Row>
				<GlobalModal
					isOpen={openSidebarSettingsModalFlag}
					t={this.props.t}
					headerContent='save_sidebar_settings'
					bodyContent='unsaved_changes'
					submitFunction={() => saveSidebarSettingsByContentType(ContentTypes.TAG, t)}
					beforeCloseFunction={() =>
						overwriteTempSidebarByContentType(ContentTypes.TAG, defaultTempSidebarFromApiResponse[ContentTypes.TAG].value)
					}
				/>
			</div>
		);
	}
}

function mapStateToProps(state: any) {
	return {
		tag: state.tag.tag,
		project: state.project.currentProject,
		profile: state.profile.profile,
		types: state.types,
	};
}

function mapDispatchToProps(dispatch: any) {
	return {
		updateTag: (tag: Tag, project: Project) => dispatch(returnObjectForTagEntityUpdate(tag, project)),
		getTag: (id: string, project: Project) => dispatch(returnObjectForTagEntityRequest(id, project)),
	};
}

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps), withRouter)(TagEdit) as React.ComponentType;
