import { LineupPlayerJson } from './lineup-player.json';
import { LineupPlayerRequestJson } from './lineup-player-request.json';
import LineupPlayerModel from './lineup-player.model';
import PlayerManualDataModel from '../../../../../models/player-manual-data/player-manual-data.model';

export default class LineupPlayerBuilder {
	private json: LineupPlayerJson;

	constructor(player?: LineupPlayerModel | LineupPlayerJson) {
		if (player && player instanceof LineupPlayerModel) {
			this.json = player.toJSON();
		} else if (player) {
			this.json = player;
		} else {
			this.json = {} as LineupPlayerJson;
		}
	}

	withPositionX(positionX: number | null): LineupPlayerBuilder {
		this.json.positionX = positionX;

		return this;
	}

	withPositionY(positionY: number | null): LineupPlayerBuilder {
		this.json.positionY = positionY;

		return this;
	}

	withPlayer(player: PlayerManualDataModel): LineupPlayerBuilder {
		this.json.player = player;

		return this;
	}

	withShirtNumber(shirtNumber: number): LineupPlayerBuilder {
		this.json.shirtNumber = shirtNumber;

		return this;
	}

	withTypeId(typeId: string | null): LineupPlayerBuilder {
		this.json.typeId = typeId;

		return this;
	}

	build(): LineupPlayerModel {
		return LineupPlayerModel.fromJSON(this.json);
	}

	toRequestJson(): LineupPlayerRequestJson {
		let json = {} as LineupPlayerRequestJson;
		json.position_x = this.json.positionX;
		json.position_y = this.json.positionY;
		json.shirt_number = this.json.shirtNumber > 0 ? Number(this.json.shirtNumber) : null;
		json.player_id = this.json.player.id;
		json.type_id = this.json.typeId;

		return json;
	}
}
