import {
	RESET_CUSTOM_ENTITY,
	RESET_CUSTOM_ENTITY_WITHOUT_CORE_FIELD,
	UPDATE_CUSTOM_ENTITY,
} from '../action-creators/custom-entities-action-creator';
import { USER_LOGOUT } from '../action-creators/ProfileActionCreators';
import { CustomEntitiesSearchFiltersModel, Meta } from '../../views/Pages/CustomEntities/models/models';
import { CUSTOM_ENTITIES_FILTERS_UPDATE, CUSTOM_ENTITIES_PAGINATION_UPDATE } from '../action-creators/custom-entities-action-creator';
import { isArrayEmpty, isObjectEmpty } from '../../global-helpers/global.helpers';
import TranslationsModel from '../../models/v2/sports-types/translations.model';

interface InitialState {
	pagination: Meta;
	filters: CustomEntitiesSearchFiltersModel;
	create: Record<string, any>;
	edit: Record<string, any>;
}

const initialState: InitialState = {
	pagination: { offset: 0 } as Meta,
	filters: {} as CustomEntitiesSearchFiltersModel,
	create: {
		contained_in_domain: {},
	},
	edit: {
		contained_in_domain: {},
	},
};

function customEntitiesReducer(state: any = initialState, action: any) {
	if (action.type === UPDATE_CUSTOM_ENTITY) {
		const { mode, dataForUpdate } = action.payload;
		return Object.assign({}, state, { ...state, [mode]: { ...state[mode], ...dataForUpdate } });
	}

	if (action.type === RESET_CUSTOM_ENTITY) {
		return Object.assign({}, state, { ...state, create: initialState.create, edit: initialState.edit });
	}

	if (action.type === RESET_CUSTOM_ENTITY_WITHOUT_CORE_FIELD) {
		const { contentMode } = action.payload;
		const currentCoreFields = {
			contained_in_domain: isObjectEmpty(state[contentMode].contained_in_domain) ? {} : state[contentMode].contained_in_domain,
			entity_type: state[contentMode].entity_type,
			name: state[contentMode].name,
			slug: state[contentMode].slug,
			default_language: state[contentMode].default_language,
			translations:
				Array.isArray(state[contentMode].translations) && !isArrayEmpty(state[contentMode].translations)
					? state[contentMode].translations.map((transl: TranslationsModel) => {
							// this map is because in some entity's types we have more than these 4 fields.
							// We want to keep only these 4 fields because they are the same for all entity types.
							return {
								name: transl.name,
								language: transl.language,
								short_name: transl.short_name,
								three_letter_code: transl.three_letter_code,
							};
					  })
					: [],
		};
		return Object.assign({}, state, { ...state, [contentMode]: { ...currentCoreFields } });
	}

	if (action.type === CUSTOM_ENTITIES_PAGINATION_UPDATE) {
		return Object.assign({}, state, {
			pagination: action.payload.pagination,
		});
	}

	if (action.type === CUSTOM_ENTITIES_FILTERS_UPDATE) {
		return Object.assign({}, state, {
			filters: action.payload.filters,
			pagination: initialState.pagination,
		});
	}

	if (action.type === USER_LOGOUT) {
		state = initialState;
	}

	return state;
}

export default customEntitiesReducer;
