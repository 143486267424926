import { PaginationMetaJson } from './PaginationMetaJson';
import PaginationMetaBuilder from './PaginationMetaBuilder';

export default class PaginationMeta {
	readonly count: number;
	readonly currentPage: number;
	readonly perPage: number;
	readonly total: number;
	readonly totalPages: number;
	readonly limit: number;
	readonly offset: number;

	constructor(count: number, currentPage: number, perPage: number, total: number, totalPages: number, limit: number, offset: number) {
		this.count = count;
		this.currentPage = currentPage;
		this.perPage = perPage;
		this.total = total;
		this.totalPages = totalPages;
		this.limit = limit;
		this.offset = offset;
	}

	toJSON(): PaginationMetaJson {
		return {
			count: this.count,
			currentPage: this.currentPage,
			perPage: this.perPage,
			total: this.total,
			totalPages: this.totalPages,
			limit: this.limit,
			offset: this.offset,
		};
	}

	static fromJSON(json: PaginationMetaJson): PaginationMeta {
		return new PaginationMeta(json.count, json.currentPage, json.perPage, json.total, json.totalPages, json.limit, json.offset);
	}

	static builder(pagination?: PaginationMeta): PaginationMetaBuilder {
		return new PaginationMetaBuilder(pagination);
	}
}
