import { TypedJSON } from 'typedjson';

export default class MatchModel {
	id: number = 0;
	homeTeam: any;
	awayTeam: any;
	startTime: string = '';
	eventStatus: string = '';
	match: any;
}

export const mapResponseToMatchModel = (data: any) => {
	let itemsList: any = [];
	if (data && data.length > 0) {
		data.map((item: any) => {
			item.items
				.filter((match: any) => match.event_status && match.event_status.type && match.event_status.type === 'finished')
				.map((match: any) => {
					itemsList = itemsList.concat(extractMatchFromResponse(match));
				});
		});
		return itemsList;
	}

	return [];
};

export const mapResponseToMatchModelWithUpcoming = (data: any) => {
	let itemsList: any = [];
	if (data && data.length > 0) {
		data.map((item: any) => {
			item.items.map((match: any) => {
				itemsList = itemsList.concat(extractMatchFromResponse(match));
			});
		});
		return itemsList;
	}

	return [];
};

export const extractMatchFromResponse = (item: any) => {
	const model = new MatchModel();

	model.id = item.id;
	model.startTime = item.start_time;
	model.homeTeam = item.home_team;
	model.awayTeam = item.away_team;
	model.eventStatus = item.event_status;
	model.match = item;

	return model;
};

export const extractMatches = (modelList: MatchModel[]) => {
	if (modelList && modelList.length > 0) {
		let matches = modelList.map((model: MatchModel) => model);

		return onlyUnique(matches);
	}

	return [];
};

export const createMatchesOptions = (modelList: MatchModel[]) => {
	let options: any = [];

	if (modelList && modelList.length > 0) {
		const matches = extractMatches(modelList);
		matches.forEach((match: any) => {
			const option = {
				label: `${match.homeTeam.name}  -  ${match.awayTeam.name} (${match.match.event_status.name})`,
				value: match.id,
				data: match,
			};
			options.push(option);
		});
	}

	return options;
};

export const matchesToOptions = (matches: MatchModel[]) => {
	if (matches && matches.length > 0) {
		return matches.map((match) => {
			return matchToOption(match);
		});
	}

	return [];
};

export const matchToOption = (match: MatchModel) => {
	if (match && match.homeTeam && match.homeTeam.name && match.awayTeam && match.awayTeam.name) {
		return {
			value: match.id,
			label: `${match.homeTeam.name}  -  ${match.awayTeam.name} (${match.match.event_status.name})`,
			data: match,
			type: 'match',
		};
	} else {
		return {};
	}
};

export const responseToMatchModel = (response: any) => {
	const serializer = new TypedJSON(MatchModel);
	const remmapedMatch = serializer.parse(response) as MatchModel;

	return remmapedMatch;
};

const onlyUnique = (array: any[]) => {
	return array.filter((value: any, index: any, a: any) => a.indexOf(value) === index);
};
