import React from 'react';
import { ViewTypes } from '../../../../constants/general.constants';
import { WidgetPreview } from '../../widget-preview/widget-preview.component';
import BlockUpdateController from '../../../block-update-controller.component';
import MostDecoratedPlayersEdit from './most-decorated-players-widget-edit.component';
import { MostDecoratedPlayersView } from './most-decorated-players-widget-view.component';
import DecoratedPlayersWidgetModel from '../models/decorated-players-widget.model';
import BlockModel from '../../../../models/block.model';

export type Properties = {
	block: BlockModel;
	view: ViewTypes;
	t: any;
	lang: string;
};

const MostDecoratedPlayersWidget: React.FunctionComponent<Properties> = (props) => {
	if (props.view === ViewTypes.edit) {
		return <MostDecoratedPlayersEdit block={props.block} t={props.t} />;
	}

	if (props.view === ViewTypes.normal) {
		return (
			<MostDecoratedPlayersView
				preview={props.block.data.preview ? props.block.data.preview.decoratedPlayers : DecoratedPlayersWidgetModel.builder().build()}
				t={props.t}
			/>
		);
	}

	if (props.view === ViewTypes.preview) {
		return <WidgetPreview blockContent={props.block.data.content} config={props.block.data.config} />;
	}

	return null;
};

export default BlockUpdateController(MostDecoratedPlayersWidget);
