export const championshipFilter = (t: any) => {
	return [
		{ value: 'regional', label: t('regional') },
		{ value: 'national', label: t('national_team') },
		{ value: 'international', label: t('international') },
	];
};

export function selectedItemsToOptions(values: any[]) {
	let selectedItems: any[] = [];

	if (values && values.length > 0) {
		selectedItems = values.map((value: any[]) => contentToOption(value));
	}

	return selectedItems;
}

export const contentToOption = (content: any) => {
	if (content) {
		return {
			value: content.value,
			label: content.label,
		};
	}

	return {};
};
