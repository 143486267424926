import * as React from 'react';
import { Properties } from './properties/type-select.properties';
import Select from 'react-select';
import { contentToOption, contentToOptions } from './helpers/type-select.helper';

const TypesSelect: React.FunctionComponent<Properties> = (props) => {
	const { types, t, selectedType, onTypeSelect, isClearable, isMulti } = props;

	const onContentChange = (selection: any) => {
		onTypeSelect(selection && selection.value ? selection.value : '');
	};

	return (
		<Select
			inputId='content-type-select'
			value={selectedType ? contentToOption(selectedType, t, types) : ''}
			options={contentToOptions(types, t)}
			onChange={onContentChange}
			placeholder={t('select')}
			isClearable={isClearable}
			isMulti={isMulti}
		/>
	);
};

export default TypesSelect;
