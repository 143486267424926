import * as React from 'react';
import Select from 'react-select';
import * as helpers from '../../../Partials/Shared/react-select-helpers/react-select.helpers';
import HttpService from '../../../../services/rest/HttpService';
import { Label, FormGroup, Col } from 'reactstrap';
import { remapEntitiesFromResponse } from '../helpers/translations.helpers';
import EntityModel from '../models/entity.model';
import EnumItem from '../../../../models/enum/EnumItem';

type Properties = {
	t: any;
	onChange: (selection: EntityModel) => void;
	value: EntityModel;
	searchLanguage: EnumItem;
	type: string;
};

const selectTypes = {
	match_status: '/event_status',
	standing_rule: '/standing_rules',
};

const EntitySelect: React.FunctionComponent<Properties> = ({ t, onChange, value, searchLanguage, type }) => {
	const [options, setOptions] = React.useState([]);

	const onEntitySelect = (selection: any) => onChange(helpers.optionToItem(selection));

	React.useEffect(() => {
		searchLanguage &&
			HttpService.instanceFootball(searchLanguage.id)
				.get(selectTypes[type])
				.then((response: any) => {
					const statuses = remapEntitiesFromResponse(response.data, type);
					const options: any = helpers.itemsToOptions(statuses, false, t, '');
					if (value && value.id) {
						onChange(statuses.filter((option: any) => option.id === value.id)[0]);
					}
					setOptions(options);
				})
				.catch((error: unknown) => console.error(error));
	}, [t, searchLanguage, type]);

	return (
		<Col xs='6'>
			<FormGroup>
				<Label htmlFor={`${type}_select`}>{t(type)}</Label>
				<Select
					inputId={`${type}_select`}
					value={helpers.itemToOption(value, false, t, '')}
					options={options}
					onChange={onEntitySelect}
					placeholder={t(`select_${type}`)}
					noOptionsMessage={(inputValue) => inputValue && t('no_options')}
				/>
			</FormGroup>
		</Col>
	);
};

export default EntitySelect;
