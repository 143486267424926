import React, { FunctionComponent } from 'react';
import CategoriesSelects from './subcomponents/categories/categories';
import AuthorsMultiselect from './subcomponents/authors/authors-select';
import CustomAuthorInput from './subcomponents/authors/custom-author';
import StatusSelect from './subcomponents/status/status-select';
import PropertiesCheckboxes from './subcomponents/properties/properties-container';
import TypeSelect from './subcomponents/type/type-select';
import DateTimesWrapper from './subcomponents/date-time/date-time-container';
import DistributedRegionsMultiselect from './subcomponents/distributed-regions/distributed-regions-select';
import DistributedChannelsMultiselect from './subcomponents/distributed-channels/distributed-channels-select';
import OriginSelect from './subcomponents/origin/origin-select';
import CommentsPoliciesSelect from './subcomponents/comments-policies/comments-policies-select';
import { DATA_QA_ATTRIBUTES } from './constants/data-qa.attributes';
import { wrapComponents } from './helpers/general-content-attributes.helper';

type Properties = {
	t: any;
};

const SidebarGeneralContent: FunctionComponent<Properties> = ({ t }) => {
	const componentsStartRows = [
		[<CategoriesSelects key={DATA_QA_ATTRIBUTES.MAIN_CATEGORY} t={t} />],
		[<AuthorsMultiselect key={DATA_QA_ATTRIBUTES.AUTHOR} t={t} />],
		[<CustomAuthorInput key={DATA_QA_ATTRIBUTES.CUSTOM_AUTHOR} t={t} />],
		[<StatusSelect key={DATA_QA_ATTRIBUTES.STATUS} t={t} />],
		[<PropertiesCheckboxes key={DATA_QA_ATTRIBUTES.PROPERTIES} t={t} />, <TypeSelect key={DATA_QA_ATTRIBUTES.TYPE} t={t} />],
	];

	const componentsEndRows = [
		[<DistributedRegionsMultiselect key={DATA_QA_ATTRIBUTES.DISTRIBUTION_REGIONS} t={t} />],
		[<DistributedChannelsMultiselect key={DATA_QA_ATTRIBUTES.DISTRIBUTION_CHANNELS} t={t} />],
		[<OriginSelect key={DATA_QA_ATTRIBUTES.ORIGIN} t={t} />],
		[<CommentsPoliciesSelect key={DATA_QA_ATTRIBUTES.COMMENTS_POLICIES} t={t} />],
	];

	return (
		<div id={DATA_QA_ATTRIBUTES.GENERAL_CONTENT_WRAPPER}>
			{wrapComponents(componentsStartRows)}
			<DateTimesWrapper t={t} />
			{wrapComponents(componentsEndRows)}
		</div>
	);
};

export default SidebarGeneralContent;
