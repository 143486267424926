import FieldValidationModel from './field-validation.model';
import i18n from '../../i18n';

export default class MainMediaValidator {
	/**
	 * This function accepts the content and the main_media fields ("image", "gallery", "video") which have been passed with the
	 * feature configuration.
	 * Performs a check of the main media, the cases are the following:
	 * 1. There is no MainMedia object - Returns a @FieldValidationModel with @filled = false, field (not valid)
	 * 2. There is a MainMedia object and it is valid - Returns a @FieldValidationModel with @filled = true, field (valid)
	 * 3. There is a MainMedia object but it is not valid - Returns a @FieldValidationModel with @filled = false, field (not valid)
	 *
	 * After the check has been performed the function returns a @FieldValidationModel, containing information about the validity,
	 * field name and a message that might be displayed.
	 * @param content: any (Article/Video/Gallery object)
	 * @param fields: string[]
	 * @return FieldValidationModel
	 */
	public validate = (content: any, fields: string[]): FieldValidationModel => {
		const mainMedia = content.mainMedia;
		const fieldValidation: FieldValidationModel = new FieldValidationModel();

		if (mainMedia && mainMedia.length > 0) {
			const validatedFields = this.checkMainMediaFields(mainMedia, fields);
			const isMainMediaValid = this.isMainMediaValid(fields, validatedFields);

			if (isMainMediaValid) {
				fieldValidation.field = 'main_media';
				fieldValidation.filled = true;
			} else {
				const missingFields = this.extractMissingFields(validatedFields);
				const missingFieldsI18n = this.transformFieldNamesToI18N(missingFields);

				fieldValidation.field = 'main_media';
				fieldValidation.filled = false;
				fieldValidation.message = `${i18n.t('missing_main_media_fields')} ${missingFieldsI18n.join(', ')}`;
			}
		} else {
			fieldValidation.field = 'main_media';
			fieldValidation.filled = false;
			fieldValidation.message = i18n.t('no_main_media_object');
		}

		return fieldValidation;
	};

	/**
	 * @TODO Create a model for the object that is being returned
	 * Performs a check of all of the required fields in the main_media object (possible fields: image, gallery, video).
	 * After the check is performed an array of objects is created with the following fields:
	 *  - exists: boolean (the status of the field, if it is added or not)
	 *  - name: string (the name of the field that has been checked)
	 * @param mainMedia: any (MainMedia object)
	 * @param fields: string[] (the fields that need to be validated, as required by the feature config)
	 * @return any[]
	 */
	private checkMainMediaFields = (mainMedia: any, fields: string[]): any[] => {
		const checkedFields: any[] = [];

		fields.forEach((mainMediaContentValue: string) => {
			const filteredMainMedia = mainMedia.find((mainMediaContent: any) => mainMediaContent.resourceType === mainMediaContentValue);

			if (filteredMainMedia) {
				checkedFields.push({ exists: true, name: mainMediaContentValue });
			} else {
				checkedFields.push({ exists: false, name: mainMediaContentValue });
			}
		});

		return checkedFields;
	};

	/**
	 * Performs a check of all of the validated main_media fields and returns the status, if all are valid or if there are
	 * some invalid.
	 * @param fields: string[] (all of the fields of the main_media that need to be checked)
	 * @param validatedFields: any[] (as returned by @checkMainMediaFields)
	 * @return boolean
	 */
	private isMainMediaValid = (fields: string[], validatedFields: any[]): boolean => {
		if (validatedFields && validatedFields.length > 0) {
			const numberOfExistingFields = validatedFields.filter((field: any) => field.exists).length;
			const numberOfFieldsForValidation = fields.length;

			return numberOfExistingFields === numberOfFieldsForValidation;
		}

		return false;
	};

	/**
	 * Returns all of the field names translated by i18n
	 * @param missingFields
	 */
	private transformFieldNamesToI18N = (missingFields: string[]) => {
		return missingFields.map((field: string) => i18n.t(field));
	};

	/**
	 * Returns all of the fields that are missing.
	 * @param fields: any[] (as returned by @checkMainMediaFields)
	 * @return string[]
	 */
	private extractMissingFields = (fields: any[]): string[] => {
		return fields.filter((field: any) => !field.exists).map((field: any) => field.name);
	};
}
