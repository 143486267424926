import axios from 'axios';
import { store } from '../../../../store/store';
import CustomBlockIconModel from '../custom-block/custom-block-icon.model';
import REACT_APP_URLS from '../../../../global-helpers/global-url.helpers';

const CUSTOMIZATION_API_URL = REACT_APP_URLS.REACT_APP_CUSTOMIZATION_API_BASE_URL;

export default class CustomizationApiHttpService {
	static instance = (headers: any) => {
		return axios.create({
			baseURL: CUSTOMIZATION_API_URL,
			timeout: 30000,
			headers: {
				...headers,
				authorization: 'Basic Y3VzdG9taXphdGlvbmFwaTpOYWEzY1dNVHZtdnZ6d2Y3UVUzNw==',
			},
		});
	};

	static getCustomBlocksListing = () => {
		const project = store && store.getState().project.currentProject.domain;
		const headers = { 'X-Project': project };

		return CustomizationApiHttpService.instance(headers).get('/custom-blocks');
	};

	static getCustomBlock = (id: string) => {
		const project = store.getState().project.currentProject.domain;
		const headers = { 'X-Project': project };

		return CustomizationApiHttpService.instance(headers).get(`/custom-blocks/${id}`);
	};

	static updateCustomBlock = (
		id: string,
		name: string,
		applicationUrl: string,
		icon: CustomBlockIconModel = {
			type: 'ICON',
			value: 'fa fa-puzzle-piece',
		},
		status: string = 'DISABLED',
	) => {
		const project = store.getState().project.currentProject.domain;
		const headers = { 'X-Project': project };

		let payloadIcon = icon;

		if (payloadIcon.value === '') {
			payloadIcon.value = 'fa fa-';
		}

		let data = {
			name: name,
			icon: icon,
			status: status,
			application_url: applicationUrl,
		};

		return CustomizationApiHttpService.instance(headers).put(`/custom-blocks/${id}`, data);
	};

	static deleteCustomBlock = (id: string) => {
		const project = store.getState().project.currentProject.domain;
		const headers = { 'X-Project': project };

		return CustomizationApiHttpService.instance(headers).delete(`/custom-blocks/${id}`);
	};

	static postCustomBlock = (
		name: string,
		applicationUrl: string,
		icon: CustomBlockIconModel = {
			type: 'ICON',
			value: 'fa fa-puzzle-piece',
		},
		status: string = 'DISABLED',
	) => {
		const project = store.getState().project.currentProject.domain;
		const headers = { 'X-Project': project };

		let data = {
			name: name,
			icon: icon,
			status: status,
			application_url: applicationUrl,
		};

		return CustomizationApiHttpService.instance(headers).post(`/custom-blocks`, data);
	};
}
