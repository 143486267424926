import PlayerH2hModel from './player-h2h.model';
import { PlayerH2HJson } from './player-h2h.json';
import PlayerTeamModel from '../../../../partials/team-players-select/models/team-player.model';

export default class PlayerH2hBuilder {
	readonly json: PlayerH2HJson;

	constructor(playerH2H?: PlayerH2hModel | PlayerH2HJson) {
		if (playerH2H && playerH2H instanceof PlayerH2hModel) {
			this.json = playerH2H.toJson();
		} else if (playerH2H) {
			this.json = playerH2H;
		} else {
			this.json = {} as PlayerH2HJson;
		}
	}

	withPlayerOne(player1: PlayerTeamModel): PlayerH2hBuilder {
		this.json.player1 = player1;

		return this;
	}

	withPlayerTwo(player2: PlayerTeamModel): PlayerH2hBuilder {
		this.json.player2 = player2;

		return this;
	}

	withPlayerOneSeason(player1Seasons: any[]): PlayerH2hBuilder {
		this.json.player1Seasons = player1Seasons;

		return this;
	}

	withPlayerTwoSeason(player2Seasons: any[]): PlayerH2hBuilder {
		this.json.player2Seasons = player2Seasons;

		return this;
	}

	withDisplayOdds(displayOdds: boolean): PlayerH2hBuilder {
		this.json.displayOdds = displayOdds;

		return this;
	}

	withThreeLetterCodeType(threeLetterCodeType: any): PlayerH2hBuilder {
		this.json.threeLetterCodeType = threeLetterCodeType;

		return this;
	}

	build(): PlayerH2hModel {
		return PlayerH2hModel.fromJson(this.json);
	}
}
