import ModelMapper from '../../models/ModelMapper';
import { toast } from 'react-toastify';
import i18n from '../../i18n';
import { actionService } from '../../App';
import { SEARCH_IS_APPLIED } from '../action-creators/UiActionCreator';
import {
	PLAYER_LISTING_RECEIVED,
	PLAYER_ENTITY_RECEIVED,
	PLAYER_ENTITY_CREATE,
	PLAYER_ENTITY_UPDATE,
	PLAYER_ENTITY_CREATE_SUCCESS,
	PLAYER_ENTITY_CREATE_FAILED,
	PLAYER_ENTITY_UPDATE_SUCCESS,
	PLAYER_ENTITY_UPDATE_FAILED,
	SEARCH_PLAYER_LISTING_RECEIVED,
	PLAYER_BLACKLIST_RECEIVED,
	PLAYER_ENTITY_POST_ASSETS_SUCCESS,
	PLAYER_ENTITY_POST_ASSETS_FAILED,
} from '../action-creators/player-action-creator';
import PlayerManualDataModel from '../../models/player-manual-data/player-manual-data.model';

export function playerMiddleware() {
	return function (next: any) {
		return function (action: any) {
			if (action) {
				if (action.type === PLAYER_LISTING_RECEIVED) {
					action.payload.players = ModelMapper.remapPlayersFromResponse(action.payload.data.players);
					action.payload.contentPagination = ModelMapper.remapMetaPagination(action.payload.data.page_meta);
				}

				if (action.type === PLAYER_BLACKLIST_RECEIVED) {
					action.payload.players = ModelMapper.remapPlayersFromResponse(action.payload);
				}

				if (action.type === PLAYER_ENTITY_RECEIVED) {
					action.payload.player = ModelMapper.remapPlayerFromResponse(action.payload.data);
				}

				if (action.type === PLAYER_ENTITY_CREATE) {
					actionService.emitGeneralAction(PLAYER_ENTITY_CREATE);
					action.payload.player = PlayerManualDataModel.builder(action.payload.player).toRequestJson();
				}

				if (action.type === PLAYER_ENTITY_UPDATE) {
					actionService.emitGeneralAction(PLAYER_ENTITY_UPDATE);
					action.payload.player = PlayerManualDataModel.builder(action.payload.player).toRequestJson();
				}

				if (action.type === PLAYER_ENTITY_CREATE_SUCCESS) {
					actionService.emitGeneralAction(PLAYER_ENTITY_CREATE_SUCCESS);
					window.dispatchEvent(new CustomEvent(PLAYER_ENTITY_CREATE_SUCCESS, { detail: action.payload }));
					toast.success(i18n.t('player_create_success'));
				}

				if (action.type === PLAYER_ENTITY_CREATE_FAILED) {
					actionService.emitGeneralAction(PLAYER_ENTITY_CREATE_FAILED);
					//TODO Replace  window.dispatchEvent with the actionService
					window.dispatchEvent(new CustomEvent(PLAYER_ENTITY_CREATE_FAILED, { detail: action.payload }));
					toast.error(i18n.t('player_create_failed'));
				}

				if (action.type === PLAYER_ENTITY_UPDATE_SUCCESS) {
					actionService.emitGeneralAction(PLAYER_ENTITY_UPDATE_SUCCESS);
					toast.success(i18n.t('player_update_success'));
				}

				if (action.type === PLAYER_ENTITY_UPDATE_FAILED) {
					actionService.emitGeneralAction(PLAYER_ENTITY_UPDATE_FAILED);
					toast.error(i18n.t('player_update_failed'));
				}

				if (action.type === PLAYER_ENTITY_POST_ASSETS_SUCCESS) {
					actionService.emitGeneralAction(PLAYER_ENTITY_POST_ASSETS_SUCCESS);
					toast.success(i18n.t('player_assets_update_success'));
				}

				if (action.type === PLAYER_ENTITY_POST_ASSETS_FAILED) {
					actionService.emitGeneralAction(PLAYER_ENTITY_POST_ASSETS_FAILED);
					toast.error(i18n.t('player_assets_update_failed'));
				}

				if (action.type === SEARCH_IS_APPLIED) {
					actionService.emitGeneralAction(SEARCH_IS_APPLIED);
				}

				if (action.type === SEARCH_PLAYER_LISTING_RECEIVED) {
					action.payload.players = ModelMapper.remapPlayersFromResponse(action.payload.data);
				}
			}

			next(action);
		};
	};
}
