import TeamModel from '../../../../partials/team-select/models/team.model';
import MatchModel from '../../../../partials/match-select/models/match.model';

export const activeTabOptions = (t: any) => {
	return [
		{ label: t('overview'), value: 'overview' },
		{ label: t('details'), value: 'details' },
		{ label: t('squads'), value: 'squads' },
		{ label: t('stats'), value: 'stats' },
		{ label: t('standings'), value: 'standings' },
		{ label: t('odds'), value: 'odds' },
		{ label: t('h2h'), value: 'h2h' },
	];
};

export function getActiveTab(value: any, t: any) {
	if (value) {
		return activeTabOptions(t).filter((option) => option.value === value);
	}

	return [];
}

export function remapMatchFromOption(selection: any) {
	if (selection) {
		return MatchModel.builder()
			.withId(selection.id)
			.withHomeTeam(TeamModel.builder(selection.data.home_team).build())
			.withAwayTeam(TeamModel.builder(selection.data.away_team).build())
			.withStartTime(selection.startTime)
			.withType(selection.type)
			.build();
	} else return MatchModel.builder().build();
}

export function extractMatchColors(matchColorResponse: any) {
	//Returns an object for home and away team colors according to the v2/matches/{id} response. color_code can be null.
	let awayTeamShirtColor = matchColorResponse.data.away_team && matchColorResponse.data.away_team.shirt_color;
	let homeTeamShirtColor = matchColorResponse.data.home_team && matchColorResponse.data.home_team.shirt_color;

	return { awayTeamColor: awayTeamShirtColor, homeTeamColor: homeTeamShirtColor };
}

export const checkIfMatchContainsColors = (matchData: any) => {
	//Check if match colors exist, at least one is not null
	let awayTeamShirtColor = matchData.data.away_team && matchData.data.away_team.shirt_color;
	let homeTeamShirtColor = matchData.data.home_team && matchData.data.home_team.shirt_color;
	return awayTeamShirtColor || homeTeamShirtColor;
};

export const extractTeamIDsFromMatch = (matchData: any) => {
	return {
		awayTeam: matchData.data.away_team.id,
		homeTeam: matchData.data.home_team.id,
	};
};

export const checkIfTeamContainsColor = (responseFromTeamEndpoint: any, index: number) => {
	if (
		responseFromTeamEndpoint.data &&
		responseFromTeamEndpoint.data.shirt_colors &&
		responseFromTeamEndpoint.data.shirt_colors[index] &&
		responseFromTeamEndpoint.data.shirt_colors[index].color_code
	) {
		return responseFromTeamEndpoint.data.shirt_colors[index].color_code;
	} else return null;
};

export const returnErrorForColors = (isBlockyValid: boolean, homeColor: string | null, awayColor: string | null) => {
	return (!isBlockyValid && homeColor === null) || (!isBlockyValid && awayColor === null) || (!isBlockyValid && awayColor === homeColor);
};

export const returnErrorForMatchCenterColors = (color: string) => {
	return color.length <= 0 || color === '';
};

export const areColorsTheSame = (color1: string, color2: string) => {
	return color1.length > 0 && color2.length > 0 && color1 === color2;
};

export const generateCorrectError = (homeColor: string | null, awayColor: string | null) => {
	if (homeColor === null || homeColor === '' || awayColor === '' || awayColor === null) {
		return 'no_color_selected';
	} else if (homeColor === awayColor) {
		return 'warning_shirt_colors_are_the_same';
	} else {
		return 'error_occurred';
	}
};

export const areMatchColorsTheSame = (homeTeamColor: string, awayTeamColor: string) => {
	//return true if the homeTeamColor and the awayTeamColor are the same string. If at least one of them is null, the function returns false.
	return homeTeamColor === awayTeamColor;
};

export const checkIfAwayShirtColorExists = (response: any) => {
	return response.data && response.data.shirt_colors && response.data.shirt_colors[1] && response.data.shirt_colors[1].color_code;
};

export const checkIfColorsAvailable = (homeTeamColor: string, awayTeamColor: string) => {
	return homeTeamColor === awayTeamColor && homeTeamColor !== null && awayTeamColor !== null;
};

export const checkForOddsPrerequisites = (displayOddsChecked: boolean, teams: any[]) => {
	return displayOddsChecked && teams && teams.length > 0;
};

export const listenForOutsideClicks = (
	listening: any,
	setListening: any,
	menuRef: any,
	toggleTeamColorPicker: any,
	toggle: boolean,
	blockId: string,
) => {
	return () => {
		const colorPicker = window.document.getElementById(blockId);
		if (listening) return;
		if (!menuRef.current) return;
		setListening(true);
		if (colorPicker) {
			[`click`, `touchstart`].forEach((type) => {
				colorPicker.addEventListener(`click`, (evt) => {
					if (menuRef.current.contains(evt.target)) return;
					toggleTeamColorPicker(toggle);
				});
			});
		}
	};
};
