import { SourceJson } from './source.json';
import SourceBuilder from './source.builder';

export default class Source {
	readonly id: string;
	readonly location: string;
	readonly name: string;
	readonly type: string;
	readonly subtype: string;
	readonly thumbnail: string;

	private constructor(id: string, location: string, name: string, type: string, subtype: string, thumbnail: string) {
		this.id = id;
		this.location = location;
		this.name = name;
		this.type = type;
		this.subtype = subtype;
		this.thumbnail = thumbnail;
	}

	toJson(): SourceJson {
		return {
			id: this.id,
			location: this.location,
			name: this.name,
			type: this.type,
			subtype: this.subtype,
			thumbnail: this.thumbnail,
		};
	}

	static fromJson(json: SourceJson): Source {
		return new Source(json.id, json.location, json.name, json.type, json.subtype, json.thumbnail);
	}

	static builder(source?: Source): SourceBuilder {
		return new SourceBuilder(source);
	}
}
