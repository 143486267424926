import 'reflect-metadata';
import { TypedJSON } from 'typedjson';
import FansUnitedEitherOrModel from './fans-united-either-or.model';

export default class FansUnitedEitherOrMapper {
	public responseToModel = (response: Response): FansUnitedEitherOrModel => {
		const serializer = new TypedJSON(FansUnitedEitherOrModel);
		const remmapedEitherOrGame = serializer.parse(response) as FansUnitedEitherOrModel;
		remmapedEitherOrGame['isSelected'] = false;

		return remmapedEitherOrGame;
	};
	public modelToRequestBody = (model: FansUnitedEitherOrModel) => {
		if (model) {
			return {
				id: model.id,
				title: model.title,
				description: model.description,
				type: model.type,
				status: model.status,
				images: model.images,
				labels: model.labels,
				points: model.points,
				lives: model.lives,
				winning_condition: model.winning_condition,
				custom_fields: model.custom_fields,
				created_at: model.created_at,
				updated_at: model.updated_at,
			};
		}
		return new FansUnitedEitherOrModel();
	};
}
