import React, { ChangeEvent } from 'react';
import './styles/imago-images-block.component.scss';
import { blockManagementService, blockWrapperService } from '../../../subcomponents/blocky.component';
import BlockValidation from '../../../helpers/block-validation.helper';
import ImagoImageContainer from './imago-image-container.component';
import BlockModel from '../../../models/block.model';
import ImagoImageModel from './models/imago-image.model';
import { openTypes } from '../../../constants/link.constants';
import { OPEN_TYPES } from '../../../partials/image-properties/image-properties.component';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import i18n from 'i18next';

export type Properties = {
	block: BlockModel;
	t: any;
	blocks: BlockModel[];
};

export type State = {
	selectedImagoImage: ImagoImageModel;
	isValid: boolean;
	searchText: string;
	searchIn: string;
	pageOffset: number;
	selectedImageType: string;
	link: string;
	linkText: string;
	linkOpenType: string;
	linkContent: string;
};

class ImagoImageEdit extends React.Component<Properties, State> {
	constructor(props: Properties) {
		super(props);
		this.state = {
			selectedImagoImage:
				props.block && props.block.data && props.block.data.id ? props.block.data.preview.image : ImagoImageModel.builder().build(),
			isValid: true,
			searchText: props.block && props.block.data && props.block.data.preview ? props.block.data.preview.searchText : '',
			searchIn: '',
			pageOffset: props.block && props.block.data && props.block.data.preview ? props.block.data.preview.pageOffset : 0,
			selectedImageType: '',
			link:
				props.block && props.block.data && props.block.data.preview && props.block.data.preview.link && props.block.data.preview.link.length > 0
					? props.block.data.preview.link
					: '',
			linkText:
				props.block &&
				props.block.data &&
				props.block.data.preview &&
				props.block.data.preview.linkText &&
				props.block.data.preview.linkText.length > 0
					? props.block.data.preview.linkText
					: '',
			linkOpenType:
				props.block &&
				props.block.data &&
				props.block.data.preview &&
				props.block.data.preview.linkOpenType &&
				props.block.data.preview.linkOpenType &&
				props.block.data.preview.linkOpenType.length > 0
					? props.block.data.preview.linkOpenType
					: openTypes['sameWindow'],
			linkContent:
				props.block &&
				props.block.data &&
				props.block.data.preview &&
				props.block.data.preview.linkContent &&
				props.block.data.preview.linkContent.length > 0
					? props.block.data.preview.linkContent
					: '',
		};
	}

	componentDidMount(): void {
		blockWrapperService.registerOnSaveFunc({ blockId: this.props.block.id, func: this.onSave });
	}

	onSave = (index: number) => {
		const { selectedImagoImage, searchIn, pageOffset, selectedImageType, linkContent, link, linkOpenType, linkText } = this.state;
		const data = { imagoImage: selectedImagoImage };
		const validation = new BlockValidation();
		const isValid = validation.validate(this.props.block, data);
		this.setState({ ...this.state, isValid });

		if (isValid) {
			const block = Object.assign({}, this.props.block);
			block.data.id = selectedImagoImage.id;
			block.data.content = ImagoImageModel.builder()
				.withId(selectedImagoImage.id)
				.withType(selectedImagoImage.type)
				.withSource(selectedImagoImage.source)
				.withWidth(selectedImagoImage.width)
				.withHeight(selectedImagoImage.height)
				.withImage(selectedImagoImage.image)
				.withDescription(selectedImagoImage.description)
				.withDate(selectedImagoImage.dateCreated)
				.build();
			block.data.preview = {
				image: selectedImagoImage,
				searchInput: searchIn,
				pageOffset: pageOffset,
				selectedImageType: selectedImageType,
				link: link,
				linkText: linkText,
				linkOpenType: linkOpenType,
				linkContent: linkContent,
			};
			blockManagementService.blockUpdate(block, index);
		} else {
			return isValid;
		}
	};

	selectImagoImage = (image: ImagoImageModel) => {
		this.setState({ ...this.state, selectedImagoImage: image });
	};

	componentWillUnmount(): void {
		blockWrapperService.unregisterOnSaveFunc(this.props.block.id);
	}

	updateSearchInput = (search: string, pageOffset: number, imageType: string) => {
		this.setState({ ...this.state, searchText: search, searchIn: search, pageOffset: pageOffset, selectedImageType: imageType });
	};

	updateLinkContent = (link: string, linkText: string, openType: string) => {
		const target = openType === OPEN_TYPES.newWindow ? 'target="_blank"' : 'target="_self"';

		return `<a href="${link}" ${target}>${linkText}</a>`;
	};

	onImageLinkChange = (event: ChangeEvent<HTMLInputElement>) => {
		const content = this.updateLinkContent(event.target.value, this.state.linkText, this.state.linkOpenType);

		this.setState({ ...this.state, link: event.target.value, linkContent: content });
	};

	onImageLinkTextChange = (event: ChangeEvent<HTMLInputElement>) => {
		const content = this.updateLinkContent(this.state.link, event.target.value, this.state.linkOpenType);

		this.setState({ ...this.state, linkText: event.target.value, linkContent: content });
	};

	onImageLinkTargetChange = (event: ChangeEvent<HTMLInputElement>) => {
		const content = this.updateLinkContent(this.state.link, this.state.linkText, event.target.value);

		this.setState({ ...this.state, linkOpenType: event.target.value, linkContent: content });
	};

	render() {
		const { t, blocks, block } = this.props;
		const { link, linkText, linkOpenType, selectedImagoImage } = this.state;

		return (
			<div>
				<div className={'mb-2'}>
					<ImagoImageContainer
						t={t}
						isMediaSidebar={false}
						onSelect={this.selectImagoImage}
						updateSearchInput={this.updateSearchInput}
						block={this.props.block}
						selectedImagoImage={this.state.selectedImagoImage}
						blocks={blocks}
						isValid={this.state.isValid}
						inBlocky={true}
						pageOffset={this.state.pageOffset}
						{...this.props}
					/>
				</div>
				{selectedImagoImage.id && (
					<div className='mb-2'>
						<>
							<Row>
								<Col>
									<FormGroup>
										<Label className='mr-2'>{i18n.t('link')}:</Label>
										<Input type='text' value={link} placeholder={i18n.t('enter_link')} onChange={this.onImageLinkChange} />
									</FormGroup>
								</Col>
							</Row>
							<Row>
								<Col>
									<FormGroup>
										<Label>{i18n.t('text')}:</Label>
										<Input type='textarea' rows={5} value={linkText} placeholder={i18n.t('enter_link_text')} onChange={this.onImageLinkTextChange} />
									</FormGroup>
								</Col>
							</Row>
							<Row className='mb-3'>
								<Col>
									<div className='form-check form-check-inline'>
										<Input
											className='form-check-input'
											type='radio'
											key={`input-image-link-${block.id}-${linkOpenType}`}
											onChange={(e: ChangeEvent<HTMLInputElement>) => this.onImageLinkTargetChange(e)}
											id={`open-in-new-${block.id}`}
											checked={linkOpenType === openTypes['newWindow']}
											value={openTypes['newWindow']}
										/>
										<Label className='form-check-label' check htmlFor={`open-in-new-${block.id}`}>
											{i18n.t('open_in_new_window')}
										</Label>
									</div>
									<div className='form-check form-check-inline'>
										<Input
											className='form-check-input'
											type='radio'
											key={`input-image-link-${block.id}-${linkOpenType}`}
											onChange={(e: ChangeEvent<HTMLInputElement>) => this.onImageLinkTargetChange(e)}
											id={`open-in-same-${block.id}`}
											checked={linkOpenType === openTypes['sameWindow']}
											value={openTypes['sameWindow']}
										/>
										<Label className='form-check-label' check htmlFor={`open-in-same-${block.id}`}>
											{i18n.t('open_in_the_same_window')}
										</Label>
									</div>
								</Col>
							</Row>
						</>
					</div>
				)}
			</div>
		);
	}
}

export default ImagoImageEdit;
