import 'reflect-metadata';
import { jsonMember, jsonObject } from 'typedjson';

@jsonObject()
export default class WikiRowModel {
	@jsonMember(String)
	public id: string = '';
	@jsonMember(String)
	public title: string = '';
	@jsonMember(String, { name: 'published_at' })
	public publishedAt: string = '';
	@jsonMember(String, { name: 'updated_at' })
	public updatedAt: string = '';
	@jsonMember(String, { name: 'created_at' })
	public createdAt: string = '';
	@jsonMember(String)
	public status: string = '';
	@jsonMember(String)
	public type: string = '';
	@jsonMember(String)
	public entity_type: string = '';
	@jsonMember(String)
	public language: string = '';
	@jsonMember(String)
	public strapline: string = '';
}
