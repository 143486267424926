import React from 'react';
import FootballSingleEventWidgetModel from '../models/football-single-event-widget.model';
import { Col, Row } from 'reactstrap';
import { assetsPlaceholder } from '../../../../../../../constants/assetsPlaceholder';
import moment from 'moment';
import { OddsWidgetView } from '../../odds/components/odds-widget-view.component';
import OddsModelV2 from '../../odds/models/odds-widget.model';
import { featuresService } from '../../../../../../../App';
import { FeatureTypes } from '../../../../../../../services/feature-service/features.enum';
import { generateRefreshTimeOptionViewText } from '../../../partials/refresh-time-input/helper';

type Properties = {
	t: any;
	matchPreview: FootballSingleEventWidgetModel;
	oddsPreview: OddsModelV2;
};

export const FootballSingleEventViewComponent: React.FunctionComponent<Properties> = ({ matchPreview, t, oddsPreview }) => {
	if (matchPreview && matchPreview.match && matchPreview.match.id) {
		const homeTeamName = matchPreview.match.homeTeam.name;
		const awayTeamName = matchPreview.match.awayTeam.name;

		return (
			<div data-qa='football-single-event-view'>
				{matchPreview.displayOdds && <OddsWidgetView blockPreview={oddsPreview} t={t} />}

				<Row className={`${!matchPreview.displayOdds ? 'p-4 text-center' : 'pb-4 pl-4 pr-4'}`}>
					<Col className='text-center'>
						{!matchPreview.displayOdds && (
							<>
								<Row className='mb-1'>
									<Col>{moment(matchPreview.match.startTime ? matchPreview.match.startTime : '').format('MMM Do YYYY, HH:mm')}</Col>
								</Row>
								<Row className='mb-1'>
									<Col className='text-right'>
										<strong>{homeTeamName}</strong>
										<img
											height='40'
											alt={matchPreview.match.homeTeam ? matchPreview.match.homeTeam.name : ''}
											src={matchPreview.match.homeTeam.urlLogo ? matchPreview.match.homeTeam.urlLogo : assetsPlaceholder.team}
										/>
									</Col>

									<Col className='text-left'>
										<img
											height='40'
											alt={matchPreview.match.awayTeam ? matchPreview.match.awayTeam.name : ''}
											src={matchPreview.match.awayTeam.urlLogo ? matchPreview.match.awayTeam.urlLogo : assetsPlaceholder.team}
										/>
										<strong>{awayTeamName}</strong>
									</Col>
								</Row>
							</>
						)}
						<Row>
							<Col className='text-left'>
								{t('display_odds')}:
								<strong>
									<span className={matchPreview.displayOdds ? 'text-success' : 'text-danger'}>
										{matchPreview.displayOdds ? ` ${t('yes')}` : ` ${t('no')}`}
									</span>
								</strong>
							</Col>
						</Row>
						{featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.FANS_UNITED) && (
							<Row>
								<Col className='text-left'>
									{t('fans_united_enabled')}:
									<strong>
										<span className={matchPreview.fansUnitedEnabled ? 'text-success' : 'text-danger'}>
											{matchPreview.fansUnitedEnabled ? ` ${t('yes')}` : ` ${t('no')}`}
										</span>
									</strong>
								</Col>
							</Row>
						)}
						{featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.FANS_UNITED) && (
							<Row>
								<Col className='text-left'>
									{t('fans_united_expanded')}:
									<strong>
										<span className={matchPreview.fansUnitedEnabled && matchPreview.fansUnitedExpanded ? 'text-success' : 'text-danger'}>
											{matchPreview.fansUnitedEnabled && matchPreview.fansUnitedExpanded ? ` ${t('yes')}` : ` ${t('no')}`}
										</span>
									</strong>
								</Col>
							</Row>
						)}
					</Col>
				</Row>
				{generateRefreshTimeOptionViewText(matchPreview.refreshTime)}
			</div>
		);
	}

	return null;
};
