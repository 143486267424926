import Context from './context/context';
import { StatisticsJson } from './statistics.json';
import StatisticsBuilder from './statistics.builder';
import Totals from './totals/totals';
import Statistic from './statistic/statistic';

export default class Statistics {
	readonly context: Context;
	readonly timeseries: Statistic[];
	readonly totals: Totals;

	private constructor(context: Context, timeseries: Statistic[], totals: Totals) {
		this.context = context;
		this.timeseries = timeseries;
		this.totals = totals;
	}

	toJSON(): StatisticsJson {
		return {
			context: this.context,
			timeseries: this.timeseries,
			totals: this.totals,
		};
	}

	static fromJSON(json: StatisticsJson): Statistics {
		return new Statistics(json.context, json.timeseries, json.totals);
	}

	static builder(statistics?: Statistics): StatisticsBuilder {
		return new StatisticsBuilder(statistics);
	}
}
