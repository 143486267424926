import * as React from 'react';
import { Properties, State } from './properties/RedirectTypeSelectContainerProps';
import Select from 'react-select';
import { optionToRedirectType, redirectTypesToOptions, redirectTypeToOption } from './helpers/RedirectTypesSelectHelper';

export default class RedirectTypeSelectContainer extends React.Component<Properties, State> {
	private redirectTypes = ['permanent', 'temporary'];

	onRedirectTypeSelect = (option: any) => {
		this.props.onRedirectTypeChange(optionToRedirectType(option));
	};

	render() {
		const { t, redirectType } = this.props;

		return (
			<Select
				options={redirectTypesToOptions(this.redirectTypes, t)}
				inputId='urls-type-of-redirect'
				value={redirectType && redirectTypeToOption(redirectType, t)}
				onChange={this.onRedirectTypeSelect}
				isClearable={redirectType ? true : false}
				placeholder={t('select')}
				noOptionsMessage={(inputValue) => inputValue && t('no_options')}
			/>
		);
	}
}
