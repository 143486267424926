import BookmakerModel from '../models/bookmaker.model';

export const bookmakerToOption = (bookmaker: BookmakerModel) => {
	if (bookmaker && bookmaker.id) {
		return { value: bookmaker.id, label: bookmaker.name, data: bookmaker, logo: bookmaker.logo, type: 'bookmaker' };
	}

	return {};
};

export const optionListToBookmakerList = (selectionList: any) => {
	if (selectionList && selectionList.length > 0) {
		return selectionList.map((selection: any) => {
			return selection.data;
		});
	}

	return [];
};

export const bookmakerListToOptionList = (bookmakerList: BookmakerModel[]) => {
	if (bookmakerList && bookmakerList.length > 0) {
		return bookmakerList.map((bookmaker: BookmakerModel) => bookmakerToOption(bookmaker));
	}

	return [];
};
