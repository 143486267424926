import React from 'react';
import Select from 'react-select';
import { championshipFilter, selectedItemsToOptions } from './helpers/championship-filter-select.helper';

type Properties = {
	onChampionshipFilterSelect: any;
	value: any[];
	t: any;
	isMulti: boolean;
};

const ChampionshipFilterSelect: React.FunctionComponent<Properties> = ({ value, t, onChampionshipFilterSelect }) => {
	const ChampionshipFilterSelect = (selections: any) => {
		if (onChampionshipFilterSelect) {
			onChampionshipFilterSelect(selections);
		}
	};

	return (
		<>
			<Select
				isMulti
				options={championshipFilter(t)}
				onChange={ChampionshipFilterSelect}
				value={selectedItemsToOptions(value)}
				noOptionsMessage={(inputValue) => inputValue && t('no_options')}
			/>
		</>
	);
};

export default ChampionshipFilterSelect;
