import { GeneralContentModel } from '../../models/GeneralContentModel';
import { propertiesByContentType } from './properties/PropertiesByContentType';
import { ContentTypes } from '../../../../../../constants/content-types';
import ContentAttributes from '../../../../../../models/content-attributes/content-attributes-model';

export const filteredContent = (data: GeneralContentModel, contentType: ContentTypes) => {
	return data && contentType ? propertiesByContentType[contentType].filter((prop: any) => data[prop.value]) : [];
};

export const isPropertySelected = (key: string, data: GeneralContentModel): boolean => {
	return data && data.properties && data.properties[key] ? data.properties[key] : false;
};

export const extractPropertyNameFromRedux = (contentProperties: ContentAttributes[], searchKey: string) => {
	try {
		const el = contentProperties.find((el) => el.slug === searchKey);
		return el ? el.name : '';
	} catch (error) {
		return '';
	}
};
