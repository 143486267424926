import Statistic from '../../../../models/statistics/statistic/statistic';
//@ts-ignore
import { CustomTooltips } from '@coreui/coreui-plugin-chartjs-custom-tooltips';
import { types } from './helper.ts/charts.helper';

export const typeStatistic = ['all', 'article', 'video', 'gallery', 'image'];
export const cardColors = ['bg-info', 'bg-primary', 'bg-warning', 'bg-danger'];
export const lineColors = ['#4fc2f7', '#039be5', '#ffc108', '#f44336 '];

export const dataSet = (t: any, date: string, data: any, color: string, label?: string) => {
	return {
		labels: date,
		datasets: [
			{
				label: t(label),
				backgroundColor: color,
				borderColor: 'rgba(255,255,255,.55)',
				data,
			},
		],
	};
};

export const chartOptions = (t: any, date: string, data: Statistic, color: string) => {
	return {
		tooltips: {
			enabled: false,
			custom: CustomTooltips,
		},
		maintainAspectRatio: false,
		legend: {
			display: false,
		},
		scales: {
			xAxes: [
				{
					gridLines: {
						color: 'transparent',
						zeroLineColor: 'transparent',
					},
					ticks: {
						fontSize: 2,
						fontColor: 'transparent',
					},
				},
			],
			yAxes: [
				{
					display: false,
					ticks: {
						display: false,
						min: Math.min.apply(Math, dataSet(t, date, data, color).datasets[0].data) - 5,
						max: Math.max.apply(Math, dataSet(t, date, data, color).datasets[0].data) + 5,
					},
				},
			],
		},
		elements: {
			line: {
				tension: 0.00001,
				borderWidth: 1,
			},
			point: {
				radius: 4,
				hitRadius: 10,
				hoverRadius: 4,
			},
		},
	};
};

export const mainChart = (t: any, date: string[], counts: number[], selectedType: string) => {
	const setData = () => {
		return counts.map((count: any, index: number) => {
			return {
				label: selectedType === 'all' ? t(types[index]) : t(selectedType),
				backgroundColor: 'transparent',
				borderColor: lineColors[index],
				pointHoverBackgroundColor: '#fff',
				borderWidth: 2,
				data: count,
			};
		});
	};

	return {
		labels: date,
		datasets: setData(),
	};
};

export const mainChartOpts = (count: number[], shouldShowStatistics: boolean) => {
	const max = count.flat(1).reduce((a: number, b: number) => Math.max(a, b), 0);

	return {
		tooltips: {
			enabled: true,
			intersect: true,
			mode: 'index',
			position: 'nearest',
			callbacks: {
				labelColor(tooltipItem: any, chart: any) {
					return { backgroundColor: chart.data.datasets[tooltipItem.datasetIndex].borderColor };
				},
			},
		},
		maintainAspectRatio: false,
		legend: {
			display: shouldShowStatistics,
		},
		scales: {
			xAxes: [
				{
					gridLines: {
						drawOnChartArea: false,
					},
				},
			],
			yAxes: [
				{
					ticks: {
						beginAtZero: true,
						maxTicksLimit: 5,
						stepSize: Math.ceil(max / 5),
						max: max > 0 ? max : 1,
					},
				},
			],
		},
		elements: {
			point: {
				radius: 0,
				hitRadius: 10,
				hoverRadius: 4,
				hoverBorderWidth: 3,
			},
		},
	};
};
