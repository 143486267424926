import { EventJson } from './event.json';
import { EventRequestJson } from './event-request.json';
import EventModel from './event.model';
import PlayerManualDataModel from '../../../../../models/player-manual-data/player-manual-data.model';

export default class EventBuilder {
	private json: EventJson;

	constructor(event?: EventModel | EventJson) {
		if (event && event instanceof EventModel) {
			this.json = event.toJSON();
		} else if (event) {
			this.json = event;
		} else {
			this.json = {} as EventJson;
		}
	}

	withId(id: string): EventBuilder {
		this.json.id = id;

		return this;
	}

	withMatchId(matchId: string): EventBuilder {
		this.json.matchId = matchId;

		return this;
	}

	withTeamId(teamId: string): EventBuilder {
		this.json.teamId = teamId;

		return this;
	}

	withTeamPosition(teamPosition: string): EventBuilder {
		this.json.teamPosition = teamPosition;

		return this;
	}

	withTypeCode(typeCode: string): EventBuilder {
		this.json.typeCode = typeCode;

		return this;
	}

	withMinute(minute: string): EventBuilder {
		this.json.minute = minute;

		return this;
	}

	withScore(score: number): EventBuilder {
		this.json.score = score;

		return this;
	}

	withPrimaryPlayer(primaryPlayer: PlayerManualDataModel): EventBuilder {
		this.json.primaryPlayer = primaryPlayer;

		return this;
	}

	withSecondaryPlayer(secondaryPlayer: PlayerManualDataModel): EventBuilder {
		this.json.secondaryPlayer = secondaryPlayer;

		return this;
	}

	build(): EventModel {
		return EventModel.fromJSON(this.json);
	}

	toRequestJson(index: number): EventRequestJson {
		let json = {} as EventRequestJson;
		const regExp = new RegExp(`temp-id`, 'i');
		if (this.json.id && !regExp.test(this.json.id)) {
			json.id = this.json.id;
		}
		json.minute = Number(this.json.minute);
		json.team_position = this.json.teamPosition;
		json.type_code = this.json.typeCode;

		if (this.json.primaryPlayer) {
			json.primary_player_id = this.json.primaryPlayer.id;
		}
		if (this.json.secondaryPlayer) {
			json.secondary_player_id = this.json.secondaryPlayer.id;
		}
		json.sort_order = index;

		return json;
	}
}
