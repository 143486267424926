import Social from '../../../../../models/social/social';
import BlacklistModel from '../../../../../models/team-manual-data/blacklist/blacklist';
import { ContentTypes } from '../../../../Partials/Blocky/constants/block.types';
import PlayerManualDataModel from '../../../../../models/player-manual-data/player-manual-data.model';
import TeamManualDataModel from '../../../../../models/team-manual-data/team-manual-data.model';
import SquadPlayerModel from '../../../../../models/team-manual-data/squad-player/squad-player.model';
import { itemToOption } from '../../../../Partials/Shared/react-select-helpers/react-select.helpers';
import AssetsModel from '../../../../../models/team-manual-data/assets/assets';
import CoachManualDataModel from '../../../../../models/coach-manual-data/coach-manual-data.model';
import MatchModel from '../../../match-lineups/models/match/match.model';

enum Assets {
	logo = 'LOGO',
	homeKit = 'HOME_KIT',
	awayKit = 'AWAY_KIT',
	squadImage = 'SQUAD_IMAGE',
	image = 'IMAGE',
	thumbnail = 'THUMBNAIL',
}

export const constructRequestFromEntry = (items: any, entity: string, type: null | string = null, context: null | string = null) => {
	if (items && items.length > 0) {
		return items.map((item: any) => ({ type, entity, entity_id: item.id, context }));
	}

	return [];
};

export const constructRequestFromAssets = (assets: AssetsModel, entity: string, entityId: string) => {
	if (assets && Object.entries(assets).length > 0) {
		return Object.keys(assets)
			.map(
				(key) =>
					assets[key] && {
						type: Assets[key],
						entity,
						entity_id: entityId,
						path: cutString(assets[key], entity),
					},
			)
			.filter((asset: any) => asset);
	}

	return [];
};

export const cutString = (path: string, entity: string) => {
	if (path && path.length > 0) {
		const splitText = path.split('');
		const reversedText = splitText.reverse();
		const text = reversedText.join('');
		const position = path.includes(entity.toLowerCase()) ? text.split('/', 3).join('/').length : text.split('/', 2).join('/').length;
		return path.substring(path.length - position, path.length);
	}
	return '';
};

export const SOCIALS_TEAM = {
	web: 'globe',
	instagramId: 'instagram',
	facebookId: 'facebook',
	wikipediaId: 'wikipedia-w',
	twitterId: 'twitter',
};

export const SOCIALS_PLAYER = {
	...SOCIALS_TEAM,
	youtubeId: 'youtube-play',
};

export const SOCIAL_TYPES_METHODS = {
	web: (socialData: Social, value: string) => Social.builder(socialData).withWeb(value).build(),
	twitterId: (socialData: Social, value: string) => Social.builder(socialData).withTwitterId(value).build(),
	facebookId: (socialData: Social, value: string) => Social.builder(socialData).withFacebookId(value).build(),
	youtubeId: (socialData: Social, value: string) => Social.builder(socialData).withYoutubeId(value).build(),
	instagramId: (socialData: Social, value: string) => Social.builder(socialData).withInstagramId(value).build(),
	wikipediaId: (socialData: Social, value: string) => Social.builder(socialData).withWikipediaId(value).build(),
};

export const trimSocials = (socials: Social) => {
	if (socials) {
		const socialsToSave = Social.builder().build();
		Object.keys(socials).forEach((key: string) => {
			if (socials[key]) {
				socialsToSave[key] = socials[key].trim();
			}
		});

		return socialsToSave;
	}

	return socials;
};

export const teamSave = (teamDataEntry: TeamManualDataModel) => {
	if (teamDataEntry) {
		return TeamManualDataModel.builder(teamDataEntry).withSocial(trimSocials(teamDataEntry.social)).build();
	}

	return TeamManualDataModel.builder().build();
};

export const playerSave = (playerDataEntry: PlayerManualDataModel) => {
	if (playerDataEntry) {
		return PlayerManualDataModel.builder(playerDataEntry)
			.withBirthCity(playerDataEntry.birthCity)
			.withSocial(trimSocials(playerDataEntry.social))
			.build();
	}

	return PlayerManualDataModel.builder().build();
};

export const coachSave = (coachDataEntry: CoachManualDataModel) => {
	if (coachDataEntry) {
		return CoachManualDataModel.builder(coachDataEntry).build();
	}

	return CoachManualDataModel.builder().build();
};

export const isSaveDisabled = (item: any, type: string) => {
	const regExpDidgits = /\d{4}/;
	const regExpName = /^.{3,}$/;

	if (item) {
		return (
			!item.country ||
			!item.name ||
			!regExpName.test(item.name.trim()) ||
			(type === ContentTypes.team && !!item.founded && (!regExpDidgits.test(`${item.founded}`.trim()) || item.founded.length > 4)) ||
			(type === ContentTypes.team && !item.type)
		);
	}

	return true;
};

export const findIndexById = (id: string, squad: SquadPlayerModel[]) => squad.findIndex((x) => x.id === id);

export const blacklistItemIndex = (id: string, items: any[], type: string) =>
	items.findIndex((item) => item.key.entity_id.toString() === id.toString() && item.key.type === type);

export const remapTeamBlacklisted = (teams: TeamManualDataModel[], blacklist: any) => {
	const teamsWithBlackListStatus: any[] = [];

	if (teams && teams.length > 0) {
		teams.forEach((team: TeamManualDataModel) => {
			const teamIndex = blacklistItemIndex(team.id, blacklist, 'ENTITY');
			const squadIndex = blacklistItemIndex(team.id, blacklist, 'RELATION');
			const groupedBlacklists = [];

			if (teamIndex > -1) {
				groupedBlacklists.push(remapBlacklistFromResponse(blacklist[teamIndex]));
			}

			if (squadIndex > -1) {
				groupedBlacklists.push(remapBlacklistFromResponse(blacklist[squadIndex]));
			}

			teamsWithBlackListStatus.push(TeamManualDataModel.builder(team).withBlacklists(groupedBlacklists).build());
		});
	}

	return teamsWithBlackListStatus;
};

export const remapPlayerBlacklisted = (players: PlayerManualDataModel[], blacklist: any) => {
	let playersWithBlackListStatus: any[] = [];
	players.forEach((player: PlayerManualDataModel) => {
		blacklist.map((list: any) => {
			if (list.key.entity_id.toString() === player.id.toString()) {
				return playersWithBlackListStatus.push(PlayerManualDataModel.builder(player).withBlacklist(remapBlacklistFromResponse(list)).build());
			}
		});

		if (
			(playersWithBlackListStatus.length > 0 && playersWithBlackListStatus[playersWithBlackListStatus.length - 1].id !== player.id) ||
			playersWithBlackListStatus.length === 0
		) {
			playersWithBlackListStatus.push(PlayerManualDataModel.builder(player).withBlacklist(BlacklistModel.builder().build()).build());
		}
	});

	return playersWithBlackListStatus;
};

export const remapCoachBlacklisted = (coaches: CoachManualDataModel[], blacklist: any) => {
	let coachesWithBlackListStatus: any[] = [];
	coaches.forEach((coach: CoachManualDataModel) => {
		blacklist.map((list: any) => {
			if (list.key.entity_id.toString() === coach.id.toString()) {
				return coachesWithBlackListStatus.push(CoachManualDataModel.builder(coach).withBlacklist(remapBlacklistFromResponse(list)).build());
			}
		});

		if (
			(coachesWithBlackListStatus.length > 0 && coachesWithBlackListStatus[coachesWithBlackListStatus.length - 1].id !== coach.id) ||
			coachesWithBlackListStatus.length === 0
		) {
			coachesWithBlackListStatus.push(CoachManualDataModel.builder(coach).withBlacklist(BlacklistModel.builder().build()).build());
		}
	});

	return coachesWithBlackListStatus;
};

export const remapMatchBlacklisted = (matches: MatchModel[], blacklist: any) => {
	let matchesWithBlackListStatus: any[] = [];
	matches.forEach((match: MatchModel) => {
		blacklist.map((list: any) => {
			if (+list.key.entity_id === match.id) {
				return matchesWithBlackListStatus.push(MatchModel.builder(match).withBlacklist(remapBlacklistFromResponse(list)).build());
			}
		});

		if (
			(matchesWithBlackListStatus.length > 0 && matchesWithBlackListStatus[matchesWithBlackListStatus.length - 1].id !== match.id) ||
			matchesWithBlackListStatus.length === 0
		) {
			matchesWithBlackListStatus.push(MatchModel.builder(match).withBlacklist(BlacklistModel.builder().build()).build());
		}
	});

	return matchesWithBlackListStatus;
};

export const remapBlacklistFromResponse = (response: any) => {
	if (response) {
		return BlacklistModel.builder()
			.withId(response.id)
			.withEntityId(response.key.entity_id)
			.withEntity(response.key.entity)
			.withType(response.key.type)
			.build();
	}

	return BlacklistModel.builder().build();
};

export const remapSquadPlayers = (response: any) => {
	if (response && response.players && response.players.length > 0) {
		return response.players.map((player: any, index: number) =>
			SquadPlayerModel.builder()
				.withId(`${index}-${player.player.id}`)
				.withName(player.player.name)
				.withStartDate(player.start_date)
				.withEndtDate(player.end_date)
				.withShirtNumber(player.shirt_number)
				.withStatus(player.status)
				.withContractType(player.contract_type)
				.withBirthdate(player.player.birthdate)
				.withImage(player.player.assets && player.player.assets.thumb && player.player.assets.thumb.url ? player.player.assets.thumb.url : '')
				.build(),
		);
	}

	return [];
};

export const remapSelectionToSquadPlayer = (player: any, tempId: string) => {
	if (player && player.id) {
		return SquadPlayerModel.builder()
			.withId(`${tempId}-${player.id}`)
			.withName(player.name)
			.withStatus('ACTIVE')
			.withBirthdate(player.birthdate)
			.withImage(player.image)
			.build();
	}

	return SquadPlayerModel.builder().build();
};

export const defineModalProps = (playerAction: string, removeAction: any, deleteAction: any) => {
	return playerAction === 'remove' ? removeAction : deleteAction;
};

export const filterEmptyPlayers = (squad: SquadPlayerModel[]) => {
	if (squad && squad.length > 0) {
		return squad.filter((player: SquadPlayerModel) => player.name);
	}

	return [];
};

export const remapSquadToJson = (squad: SquadPlayerModel[]) => {
	if (squad && squad.length > 0) {
		return filterEmptyPlayers(squad).map((player: SquadPlayerModel) => SquadPlayerModel.builder(player).toRequestJson());
	}

	return [];
};

export const disableSquadSave = (squad: SquadPlayerModel[]) => {
	if (squad && squad.length > 0) {
		return squad.filter((player: SquadPlayerModel) => player.name).length === 0;
	}

	if (squad && squad.length === 0) {
		return false;
	}

	return true;
};

export const contractValue = (player: SquadPlayerModel, t: any) => {
	if (player && player.contractType) {
		return itemToOption({ id: player.contractType, name: player.contractType.toLowerCase() }, false, t, '');
	}

	return [];
};

export const contractTypes = [
	{ id: 'LOAN', name: 'loan' },
	{ id: 'PERMANENT', name: 'permanent' },
	{ id: 'UNKNOWN', name: 'unknown' },
];

export const checkIfNextPageIsEntityRelated = (entityType: string) => {
	//Check if the next route location contains the entity type within the URL.
	const newLoc: any = window.location.href.split('/');
	return !newLoc.includes(entityType);
};

export const checkIfPaginationShouldRender = (hidePaginationProp: boolean | undefined, searchApplied: boolean, searchFilterText: string) =>
	(!hidePaginationProp && searchApplied && searchFilterText.length === 0) || (!hidePaginationProp && !searchApplied);
