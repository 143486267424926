import React, { FunctionComponent, useState } from 'react';
import { Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import SuggestionInsideTab from './suggestions/suggestions';
import { ContentTypes } from '../../../../../../constants/content-types';
import SearchTypes from './search-types';
import { availableContentTypes } from '../../../../../../services/content-models-service/ContentModelService';
import SearchInsideTab from './search/search';

type Properties = {
	t: any;
};

const RelatedContentInsideTabs: FunctionComponent<Properties> = ({ t }) => {
	const [activeTabIndex, setActiveTabIndex] = useState(0);
	const [searchType, setSearchType] = useState<availableContentTypes>(ContentTypes.ARTICLE);

	return (
		<Row>
			<Col>
				<Nav tabs>
					<NavItem>
						<NavLink id='content-tab-suggestions' active={activeTabIndex === 0} onClick={() => setActiveTabIndex(0)}>
							<i className='fa fa-lightbulb-o'></i> {t('suggestions')}
						</NavLink>
					</NavItem>
					<NavItem>
						<NavLink id='content-tab-search' active={activeTabIndex === 1} onClick={() => setActiveTabIndex(1)}>
							<i className='fa fa-search'></i> {t('search')}
						</NavLink>
					</NavItem>
				</Nav>
				<TabContent activeTab={activeTabIndex}>
					<TabPane tabId={activeTabIndex}>
						<SearchTypes t={t} searchType={searchType} setSearchType={setSearchType} />
						{activeTabIndex === 0 ? <SuggestionInsideTab t={t} searchType={searchType} /> : <SearchInsideTab t={t} searchType={searchType} />}
					</TabPane>
				</TabContent>
			</Col>
		</Row>
	);
};

export default RelatedContentInsideTabs;
