export const REQUEST_ARTICLE_PROPERTIES_RECEIVED = '[ACTION] REQUEST_ARTICLE_PROPERTIES_RECEIVED';
export const REQUEST_VIDEO_PROPERTIES_RECEIVED = '[ACTION] REQUEST_VIDEO_PROPERTIES_RECEIVED';
export const REQUEST_GALLERY_PROPERTIES_RECEIVED = '[ACTION] REQUEST_GALLERY_PROPERTIES_RECEIVED';
export const REQUEST_WIKI_PROPERTIES_RECEIVED = '[ACTION] REQUEST_WIKI_PROPERTIES_RECEIVED';
export const REQUEST_BANNER_PROPERTIES_RECEIVED = '[ACTION] REQUEST_BANNER_PROPERTIES_RECEIVED';

export const requestArticlePropertiesReceived = (properties: any) => ({
	type: REQUEST_ARTICLE_PROPERTIES_RECEIVED,
	payload: properties,
});

export const requestVideoPropertiesReceived = (properties: any) => ({
	type: REQUEST_VIDEO_PROPERTIES_RECEIVED,
	payload: properties,
});

export const requestGalleryPropertiesReceived = (properties: any) => ({
	type: REQUEST_GALLERY_PROPERTIES_RECEIVED,
	payload: properties,
});

export const requestWikiPropertiesReceived = (properties: any) => ({
	type: REQUEST_WIKI_PROPERTIES_RECEIVED,
	payload: properties,
});

export const requestBannerPropertiesReceived = (properties: any) => ({
	type: REQUEST_BANNER_PROPERTIES_RECEIVED,
	payload: properties,
});
