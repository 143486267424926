export const REQUEST_ARTICLE_ORIGINS_RECEIVED = '[ACTION] REQUEST_ARTICLE_ORIGINS_RECEIVED';
export const REQUEST_VIDEO_ORIGINS_RECEIVED = '[ACTION] REQUEST_VIDEO_ORIGINS_RECEIVED';
export const REQUEST_GALLERY_ORIGINS_RECEIVED = '[ACTION] REQUEST_GALLERY_ORIGINS_RECEIVED';
export const REQUEST_IMAGE_ORIGINS_RECEIVED = '[ACTION] REQUEST_IMAGE_ORIGINS_RECEIVED';

export const requestArticleOriginsReceived = (origins: any) => ({
	type: REQUEST_ARTICLE_ORIGINS_RECEIVED,
	payload: origins,
});

export const requestVideoOriginsReceived = (origins: any) => ({
	type: REQUEST_VIDEO_ORIGINS_RECEIVED,
	payload: origins,
});

export const requestGalleryOriginsReceived = (origins: any) => ({
	type: REQUEST_GALLERY_ORIGINS_RECEIVED,
	payload: origins,
});

export const requestImageOriginsReceived = (origins: any) => ({
	type: REQUEST_IMAGE_ORIGINS_RECEIVED,
	payload: origins,
});
