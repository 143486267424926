import Project from '../../models/project/Project';
import ErrorResponseModel from '../../models/error-response/ErrorResponseModel';
import AssetsModel from '../../models/team-manual-data/assets/assets';
import CoachManualDataModel from '../../models/coach-manual-data/coach-manual-data.model';
import { TEAM_SEARCH_FILTER_UPDATE } from './team-action-creator';

export const COACH_LISTING_REQUEST = '[ACTION] COACH_LISTING_REQUEST';
export const COACH_LISTING_RECEIVED = '[ACTION] COACH_LISTING_RECEIVED';
export const COACH_LISTING_FAILED = '[ACTION] COACH_LISTING_FAILED';

export const COACH_ENTITY_REQUEST = '[ACTION] COACH_ENTITY_REQUEST';
export const COACH_ENTITY_RECEIVED = '[ACTION] COACH_ENTITY_RECEIVED';
export const COACH_ENTITY_FAILED = '[ACTION] COACH_ENTITY_FAILED';

export const COACH_SEARCH = '[ACTION] COACH_SEARCH';
export const COACH_SEARCH_FAILED = '[ACTION] COACH_SEARCH_FAILED';

export const COACH_ENTITY_CREATE = '[ACTION] COACH_ENTITY_CREATE';
export const COACH_ENTITY_CREATE_SUCCESS = '[ACTION] COACH_ENTITY_CREATE_SUCCESS';
export const COACH_ENTITY_CREATE_FAILED = '[ACTION] COACH_ENTITY_CREATE_FAILED';

export const COACH_ENTITY_UPDATE = '[ACTION] COACH_ENTITY_UPDATE';
export const COACH_ENTITY_UPDATE_FAILED = '[ACTION] COACH_ENTITY_UPDATE_FAILED';
export const COACH_ENTITY_UPDATE_SUCCESS = '[ACTION] COACH_ENTITY_UPDATE_SUCCESS';

export const COACH_ENTITY_POST_ASSETS = '[ACTION] COACH_ENTITY_POST_ASSETS';
export const COACH_ENTITY_POST_ASSETS_SUCCESS = '[ACTION] COACH_ENTITY_POST_ASSETS_SUCCESS';
export const COACH_ENTITY_POST_ASSETS_FAILED = '[ACTION] COACH_ENTITY_POST_ASSETS_FAILED';

export const SEARCH_COACH_LISTING_RECEIVED = '[ACTION] SEARCH_COACH_LISTING_RECEIVED';

export const COACH_BLACKLIST_REQUEST = '[ACTION] COACH_BLACKLIST_REQUEST';
export const COACH_BLACKLIST_RECEIVED = '[ACTION] COACH_BLACKLIST_RECEIVED';
export const COACH_ENTITY_BLACKLIST_REMOVE_SUCCESS = '[ACTION] COACH_ENTITY_BLACKLIST_REMOVE_SUCCESS';
export const COACH_SEARCH_FILTER_UPDATE = '[ACTION] COACH_SEARCH_FILTER_UPDATE';

export function returnObjectForCoachListingRequest(page: string, project: Project) {
	return {
		type: COACH_LISTING_REQUEST,
		payload: { page, project },
	};
}

export function returnObjectForCoachListingReceived(coaches: any) {
	return {
		type: COACH_LISTING_RECEIVED,
		payload: coaches,
	};
}

export function returnObjectForCoachBlacklistReceived(coaches: any) {
	return {
		type: COACH_BLACKLIST_RECEIVED,
		payload: coaches,
	};
}

export function returnObjectForCoachListingFailed(error: any) {
	return {
		type: COACH_LISTING_FAILED,
		payload: ErrorResponseModel.builder().fromResponse(error).build(),
	};
}

export function returnObjectForCoachEntityCreate(coach: CoachManualDataModel, project: Project, assets: AssetsModel) {
	return {
		type: COACH_ENTITY_CREATE,
		payload: { coach, project, assets },
	};
}

export function returnObjectForCoachEntityCreateSuccess(id: string) {
	return {
		type: COACH_ENTITY_CREATE_SUCCESS,
		payload: id,
	};
}

export function returnObjectForCoachEntityUpdate(coach: CoachManualDataModel, project: Project, assets: AssetsModel) {
	return {
		type: COACH_ENTITY_UPDATE,
		payload: { coach, project, assets },
	};
}

export function returnObjectForCoachEntityUpdateSuccess() {
	return {
		type: COACH_ENTITY_UPDATE_SUCCESS,
	};
}
export function returnObjectForCoachEntityUpdateFailed() {
	return {
		type: COACH_ENTITY_UPDATE_FAILED,
	};
}

export function returnObjectForCoachEntityCreateFailed() {
	return {
		type: COACH_ENTITY_CREATE_FAILED,
	};
}

export function returnObjectForCoachEntityRequest(id: string, project: Project) {
	return {
		type: COACH_ENTITY_REQUEST,
		payload: { id, project },
	};
}

export function returnObjectForCoachEntityReceived(coach: any) {
	return {
		type: COACH_ENTITY_RECEIVED,
		payload: coach,
	};
}

export function returnObjectForCoachSearch(text: string, lang: string, type: string) {
	return {
		type: COACH_SEARCH,
		payload: { text, lang, type },
	};
}

export function returnObjectForCoachSearchFailed(error: any) {
	return {
		type: COACH_SEARCH_FAILED,
		payload: ErrorResponseModel.builder().fromResponse(error).build(),
	};
}

export function returnObjectForSearchCoachListingReceived(coaches: any) {
	return {
		type: SEARCH_COACH_LISTING_RECEIVED,
		payload: coaches,
	};
}

export function postCoachAssets(payload: { coachId: string; project: Project; assets: AssetsModel }) {
	return {
		type: COACH_ENTITY_POST_ASSETS,
		payload: payload,
	};
}

export function coachAssetsPostSuccess() {
	return {
		type: COACH_ENTITY_POST_ASSETS_SUCCESS,
	};
}

export function coachAssetsPostFailed() {
	return {
		type: COACH_ENTITY_POST_ASSETS_FAILED,
	};
}

export function coachBlacklistRemoveSuccess() {
	return {
		type: COACH_ENTITY_BLACKLIST_REMOVE_SUCCESS,
	};
}

export function coachSearchFilterUpdate(text: string) {
	return {
		type: COACH_SEARCH_FILTER_UPDATE,
		payload: text,
	};
}
