import {
	ARTICLE_ALREADY_EXISTS,
	ARTICLE_CONTENT_STATISTICS_RECEIVED,
	ARTICLE_COPY_RELATED_RECEIVED,
	ARTICLE_CREATE_RESOURCES_SUCCESS,
	ARTICLE_ENTITY_CREATE,
	ARTICLE_ENTITY_CREATE_FAILED,
	ARTICLE_ENTITY_CREATE_SUCCESS,
	ARTICLE_ENTITY_DELETE_SUCCESS,
	ARTICLE_ENTITY_RECEIVED,
	ARTICLE_ENTITY_RELATED_CREATE_SUCCESS,
	ARTICLE_ENTITY_RELATED_RECEIVED,
	ARTICLE_ENTITY_UPDATE,
	ARTICLE_ENTITY_UPDATE_FAILED,
	ARTICLE_ENTITY_UPDATE_SUCCESS,
	ARTICLE_LISTING_RECEIVED,
	CONTENT_STATISTICS_ARTICLE_ENTITY_RECEIVED,
} from '../action-creators/ArticleActionCreator';
import ModelMapper from '../../models/ModelMapper';
import { toast } from 'react-toastify';
import i18n from '../../i18n';
import Article from '../../models/article/Article';
import { relatedConstants } from '../../constants/related.constants';
import { actionService, analyticsService } from '../../App';
import { SEARCH_IS_APPLIED } from '../action-creators/UiActionCreator';
import { ContentTypes } from '../../constants/content-types';
import {
	extractSportsRelatedFromResponse,
	extractEventEntitiesFromSports,
} from '../../views/Partials/Shared/advanced-content-filter/subcomponents/v2/sports-select/sports-connections.helper';

export function articleMiddleware() {
	return function (next: any) {
		return function (action: any) {
			if (action) {
				if (action.type === ARTICLE_LISTING_RECEIVED) {
					action.payload.articles = ModelMapper.remapArticlesFromResponse(action.payload.data.data);
					action.payload.contentPagination = ModelMapper.remapMetaPagination(action.payload.data.meta.pagination);
				}

				if (action.type === ARTICLE_ENTITY_RECEIVED) {
					action.payload.articleEdit = ModelMapper.remapArticleFromResponse(action.payload.data.data);
				}

				if (action.type === ARTICLE_ENTITY_RELATED_RECEIVED || action.type === ARTICLE_COPY_RELATED_RECEIVED) {
					const response = action.payload.related.data.data;
					const sportsEventEntities = extractEventEntitiesFromSports(action.payload.sports);

					let allRelated = [];
					const relatedFootball = ModelMapper.extractFootballRelatedFromResponse(response);
					allRelated.push(...relatedFootball);
					const relatedSports = extractSportsRelatedFromResponse(response, action.payload.sports);
					allRelated.push(...relatedSports);

					action.payload.relatedSports = allRelated;

					action.payload.tagsRelated = ModelMapper.extractRelatedTypeFromResponse(
						response,
						relatedConstants.types.tag,
						relatedConstants.providers.smp,
					);

					action.payload.relatedContent = ModelMapper.extractRelatedContentFromResponse(response);

					let relatedEvents = [];
					const relatedMatches = ModelMapper.extractRelatedTypeFromResponse(
						response,
						relatedConstants.types.match,
						relatedConstants.providers.football,
					);
					relatedEvents.push(...relatedMatches);
					const relatedGames = ModelMapper.extractSportsEntitiesRelatedTypeFromResponse(
						response,
						sportsEventEntities.flat(),
						relatedConstants.providers.sports,
					);
					relatedEvents.push(...relatedGames);
					action.payload.matchRelated = relatedEvents;
				}

				if (action.type === ARTICLE_ENTITY_CREATE) {
					actionService.emitGeneralAction(ARTICLE_ENTITY_CREATE);
					action.payload.article = Article.builder(action.payload.article).toRequestJson();
					action.payload.related = ModelMapper.remapRelatedListToRelatedJsonList(action.payload.related.slice());
				}

				if (action.type === ARTICLE_ENTITY_UPDATE) {
					actionService.emitGeneralAction(ARTICLE_ENTITY_UPDATE);
					action.payload.article = Article.builder(action.payload.article).toRequestJson();
					action.payload.related = ModelMapper.remapRelatedListToRelatedJsonList(action.payload.related);
				}

				if (action.type === ARTICLE_ENTITY_CREATE_SUCCESS) {
					actionService.emitGeneralAction(ARTICLE_ENTITY_CREATE_SUCCESS);
					window.dispatchEvent(new CustomEvent(ARTICLE_ENTITY_CREATE_SUCCESS, { detail: action.payload }));
					analyticsService.sendWidgetAnalytic();
					toast.success(i18n.t('article_create_success'));
				}

				if (action.type === ARTICLE_CREATE_RESOURCES_SUCCESS) {
					actionService.emitGeneralAction(ARTICLE_CREATE_RESOURCES_SUCCESS);
					window.dispatchEvent(new CustomEvent(ARTICLE_CREATE_RESOURCES_SUCCESS, { detail: action.payload }));
					// not sure if its not better to display the toast notification for successfully created article here
					// otherwise it can display that the article is created successfully but the related data to have a problem..
					// TODO to be discussed with the team
				}

				if (action.type === ARTICLE_ENTITY_CREATE_FAILED) {
					actionService.emitGeneralAction(ARTICLE_ENTITY_CREATE_FAILED);
					//TODO Replace  window.dispatchEvent with the actionService
					window.dispatchEvent(new CustomEvent(ARTICLE_ENTITY_CREATE_FAILED, { detail: action.payload }));
					toast.error(i18n.t('article_create_failed'));
				}

				if (action.type === ARTICLE_ENTITY_RELATED_CREATE_SUCCESS) {
					//TODO Replace  window.dispatchEvent with the actionService
					window.dispatchEvent(new CustomEvent(ARTICLE_ENTITY_RELATED_CREATE_SUCCESS, { detail: action.payload }));
				}

				if (action.type === ARTICLE_ENTITY_UPDATE_SUCCESS) {
					actionService.emitGeneralAction(ARTICLE_ENTITY_UPDATE_SUCCESS);
					analyticsService.sendWidgetAnalytic();
					toast.success(i18n.t('article_update_success'));
				}

				if (action.type === ARTICLE_ENTITY_UPDATE_FAILED) {
					actionService.emitGeneralAction(ARTICLE_ENTITY_UPDATE_FAILED);
					toast.error(i18n.t('article_update_failed'));
				}

				if (action.type === ARTICLE_ENTITY_DELETE_SUCCESS) {
					toast.success(i18n.t('article_deleted_success'));
					window.dispatchEvent(new CustomEvent(action.type));
				}

				if (action.type === SEARCH_IS_APPLIED) {
					actionService.emitGeneralAction(SEARCH_IS_APPLIED);
				}

				if (action.type === ARTICLE_CONTENT_STATISTICS_RECEIVED) {
					action.payload.articles = ModelMapper.remmapListingContentWithContentStatisticsFromResponse(
						action.payload.contentStatistics,
						action.payload.articles,
						ContentTypes.ARTICLE,
					);
				}

				if (action.type === CONTENT_STATISTICS_ARTICLE_ENTITY_RECEIVED) {
					action.payload.article = ModelMapper.remmapContentWithContentStatistics(
						action.payload.contentStatistics[0],
						action.payload.article,
						ContentTypes.ARTICLE,
					);
				}

				if (action.type === ARTICLE_ALREADY_EXISTS) {
					actionService.emitGeneralAction(ARTICLE_ALREADY_EXISTS);
					toast.error(`${i18n.t('article_with_this_code_already_exists')}`);
				}
			}

			next(action);
		};
	};
}
