import React from 'react';
import { Label, Col, Row, FormGroup } from 'reactstrap';

type Properties = {
	onSelect: any;
	images: any;
	selectedImageId: string;
	t: any;
};

export default class GalleryMainImage extends React.Component<Properties> {
	onSelect = (image: any) => {
		const { onSelect } = this.props;
		onSelect(image);
	};

	render() {
		const { images, selectedImageId } = this.props;

		return (
			<Row>
				<Col>
					<FormGroup className={'d-flex flex-column'}>
						<Label for={`gallery-title`}>{this.props.t('select_cover_image')}:</Label>
						<Row className='pl-2'>
							{images.map((image: any) => {
								return (
									<div key={image.id} className='mr-2 mb-2 d-inline-block'>
										<img
											src={image.urls.uploaded.thumbnail}
											alt={image.path}
											onClick={() => {
												this.onSelect(image);
											}}
											className={`img-fluid  max-width-100 cursor-pointer mb-1 mr-1 ${image.id === selectedImageId ? 'selected-main-image' : ''}`}
										/>
									</div>
								);
							})}
						</Row>
					</FormGroup>
				</Col>
			</Row>
		);
	}
}
