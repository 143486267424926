import React, { FunctionComponent } from 'react';
import './search-sport-extended.scss';
import MultisportEventModel from '../../../Pages/Multisport-widget/models/event.model';
import { store } from '../../../../store/store';
import { connect } from 'react-redux';
import { addMultisportEntities } from '../../../../store/action-creators/multisport-action-creator';
import {
	TOP_EVENTS_SPORT,
	checkIfEntityExistInPreviewSection,
	extractLogoFromCompetition,
	formatSportEntityDate,
} from '../../../Pages/Multisport-widget/helpers/utils';
import MultisportModel from '../../../Pages/Multisport-widget/models/sport.model';
import CirclePlusIcon from '../icons/circle-plus';
import StarIcon from '../icons/star';
import { useTranslation } from 'react-i18next';

type Props = {
	events: MultisportEventModel[];
	addMultisportEntities: (events: MultisportEventModel[], isTopEvents: boolean) => void;
	areResultsLoaded: boolean;
	previewData: MultisportModel[];
	configurationDate: Date;
};

export const EventsSearchOutput: FunctionComponent<Props> = ({
	events,
	areResultsLoaded,
	addMultisportEntities,
	previewData,
	configurationDate,
}) => {
	const { t } = useTranslation();
	const addToSection = (event: MultisportEventModel, isTopEvents: boolean = false) => addMultisportEntities([event], isTopEvents);

	if (!areResultsLoaded) {
		return (
			<div className='event-entities-no-info-wrapper'>
				<i className='fas fa-info-circle'></i>
				<span> {t('fu_loadingMessage')} </span>
			</div>
		);
	}

	if (events && events.length === 0 && areResultsLoaded) {
		return (
			<div className='event-entities-no-info-wrapper'>
				<i className='fas fa-info-circle'></i>
				<span> {t('no_results_n_try_again')} </span>
			</div>
		);
	}

	return (
		<div className='event-entities-wrapper'>
			{events.map((event: MultisportEventModel) => {
				const { date, hours, isAddableToSection } = formatSportEntityDate(
					event.start_time,
					configurationDate,
					store.getState().project.currentProject.timezone,
				);

				const checkIfInTopSection = checkIfEntityExistInPreviewSection(TOP_EVENTS_SPORT, event, previewData);
				const checkIfInSportSection = checkIfEntityExistInPreviewSection(event.sport, event, previewData);

				return (
					<div key={event.id} className='entity-wrapper'>
						<div className='game-info'>
							<span className='game-title'> {event.name} </span>
							<div className='game-time'>
								<span className='game-hour'> {hours} </span>
								<span className='game-date'> {date} </span>
								<span className='game-status'> {event.status_type} </span>
							</div>
						</div>
						<div className='action-icons'>
							<img className='competition-node-img' src={extractLogoFromCompetition(event.competition)} />
							<StarIcon action={() => !checkIfInTopSection && addToSection(event, true)} isActive={checkIfInTopSection} />
							<CirclePlusIcon
								action={() => !checkIfInSportSection && addToSection(event)}
								isActive={checkIfInSportSection}
								disabled={!isAddableToSection}
							/>
						</div>
					</div>
				);
			})}
		</div>
	);
};

function mapStateToProps(state: any) {
	return {
		previewData: state.multisportConfiguration.previewData || [],
		configurationDate: state.multisportConfiguration.configurationDate
			? new Date(state.multisportConfiguration.configurationDate)
			: new Date(),
	};
}

function mapDispatchToProps(dispatch: any) {
	return {
		addMultisportEntities: (events: MultisportEventModel[], isTopEvents: boolean) => dispatch(addMultisportEntities(events, isTopEvents)),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(EventsSearchOutput);
