import React from 'react';
import { Properties } from './properties/standings-widget.properties';
import StandingsEdit from './standings-widget-edit.component';
import { StandingsView } from './standings-widget-view.component';
import { ViewTypes } from '../../../../constants/general.constants';
import { WidgetPreview } from '../../widget-preview/widget-preview.component';
import StandingsWidgetModel from '../models/standings-widget.model';
import BlockUpdateController from '../../../block-update-controller.component';

const StandingsWidget: React.FunctionComponent<Properties> = (props) => {
	if (props.view === ViewTypes.edit) {
		return <StandingsEdit block={props.block} t={props.t} />;
	}

	if (props.view === ViewTypes.normal) {
		return (
			<StandingsView
				preview={props.block.data.preview ? props.block.data.preview.standings : StandingsWidgetModel.builder().build()}
				t={props.t}
			/>
		);
	}

	if (props.view === ViewTypes.preview) {
		return <WidgetPreview blockContent={props.block.data.content} config={props.block.data.config} />;
	}

	return null;
};

export default BlockUpdateController(StandingsWidget);
