import React from 'react';
import OnnetworkContainer from './onnetwork-video-container.component';
import { blockManagementService, blockWrapperService } from '../../../subcomponents/blocky.component';
import BlockValidation from '../../../helpers/block-validation.helper';
import OnnetworkVideoModel from '../../../../../../models/onnetwork-videos/onnetwork-video.model';
import BlockModel from '../../../models/block.model';

export type Properties = {
	block: BlockModel;
	t: any;
	blocks: BlockModel[];
	linkedTags: any;
};

export type State = {
	selectedVideo: OnnetworkVideoModel;
	isValid: boolean;
	searchText: string;
	page: number;
};

class OnnetworkVideoEdit extends React.Component<Properties, State> {
	constructor(props: Properties) {
		super(props);
		this.state = {
			selectedVideo:
				props.block && props.block.data && props.block.data.id ? props.block.data.preview.video : OnnetworkVideoModel.builder().build(),
			isValid: true,
			searchText: '',
			page: props.block && props.block.data && props.block.data.preview ? props.block.data.preview.page : 0,
		};
	}

	componentDidMount(): void {
		blockWrapperService.registerOnSaveFunc({ blockId: this.props.block.id, func: this.onSave });
	}

	onSave = (index: number) => {
		const { selectedVideo } = this.state;
		const data = { onnetworkVideo: selectedVideo };
		const validation = new BlockValidation();
		const isValid = validation.validate(this.props.block, data);
		this.setState({ ...this.state, isValid });

		if (isValid) {
			const block = Object.assign({}, this.props.block);
			block.data.id = selectedVideo.id;
			block.data.content = selectedVideo.embedCode.script;
			block.data.preview = { video: selectedVideo, title: this.state.searchText, page: this.state.page };
			blockManagementService.blockUpdate(block, index);
		} else {
			return isValid;
		}
	};

	selectVideo = (video: OnnetworkVideoModel) => {
		this.setState({ ...this.state, selectedVideo: video });
	};

	componentWillUnmount(): void {
		blockWrapperService.unregisterOnSaveFunc(this.props.block.id);
	}

	updatePreviewState = (search: string, page: number) => {
		this.setState({ ...this.state, searchText: search, page: page });
	};

	render() {
		return (
			<OnnetworkContainer
				isMediaSidebar={false}
				onSelect={this.selectVideo}
				updatePreviewState={this.updatePreviewState}
				selectedVideo={this.state.selectedVideo}
				isValid={this.state.isValid}
				inBlocky={true}
				page={this.state.page}
				{...this.props}
			/>
		);
	}
}

export default OnnetworkVideoEdit;
