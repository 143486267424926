export const INSTAT_STATS = {
	shortStatsAll: ['452', '2', '3', '19', '572', '573', '17', '24', '36', '43', '277', '321', '324', '630', '47', '49', '61', '105', '257'],
	longStatsAll: [
		'452',

		'2',

		'3',

		'12',

		'19',

		'37',

		'40',

		'41',

		'108',

		'109',

		'110',

		'111',

		'112',

		'113',

		'114',

		'115',

		'186',

		'187',

		'188',

		'189',

		'190',

		'191',

		'572',

		'573',

		'-10',

		'-9',

		'-8',

		'-7',

		'-6',

		'-5',

		'-4',

		'17',

		'24',

		'36',

		'43',

		'225',

		'226',

		'227',

		'228',

		'229',

		'230',

		'231',

		'232',

		'277',

		'321',

		'324',

		'630',

		'47',

		'49',

		'61',

		'105',

		'220',

		'221',

		'223',

		'224',

		'257',

		'271',

		'272',

		'275',
	],
	shortStatsPasses: ['452', '2', '3', '19', '572', '573'],
	longStatsPasses: [
		'452',
		'2',
		'3',
		'12',
		'19',
		'37',
		'40',
		'41',
		'108',
		'109',
		'110',
		'111',
		'112',
		'113',
		'114',
		'115',
		'186',
		'187',
		'188',
		'189',
		'190',
		'191',
		'572',
		'573',
	],

	shorStatsChallenges: ['452', '17', '24', '36', '43', '277', '321', '324', '630'],
	longStatsChallenges: [
		'452',
		'-10',
		'-9',
		'-8',
		'-7',
		'-6',
		'-5',
		'-4',
		'17',
		'24',
		'36',
		'43',
		'225',
		'226',
		'227',
		'228',
		'229',
		'230',
		'231',
		'232',
		'277',
		'321',
		'324',
		'630',
	],

	shortStatsShots: ['452', '47', '49', '61', '105', '257'],
	longStatsShots: ['452', '47', '49', '61', '105', '220', '221', '223', '224', '257', '271', '272', '275'],
};
