import React, { useRef } from 'react';
import { useState, useEffect } from 'react';
import { Card, CardBody, CardHeader, Collapse } from 'reactstrap';
import { connect } from 'react-redux';
import { tempSidebar } from '../../Sidebar/sidebar-collapsible-elements/utils/temp-sidebar.helper';
import { ContentSidebarChangeCollapseStatus } from '../../../../services/content-sidebar/content-sidebar.helper';

type Properties = {
	contentType: string;
	sidebarSettings: any;
	status: string;
	title: string;
	iconClass: string;
	drag: any;
	isSidebarInEdit: boolean;
	onChangeCollapseStatus: (settings: any) => void;
	sequence: number;
	profileId: string;
	children: ChildNode;
};

type State = {
	collapse: boolean;
	status: string;
	fadeIn: boolean;
	timeout: number;
};

const CustomCollapsibleElement = (props: Properties) => {
	const [state, setState] = useState<State>({
		collapse: props.status === 'open',
		status: props.status ? props.status : 'closed',
		fadeIn: true,
		timeout: 100,
	});

	const onEntering = () => {
		setState({ ...state, status: 'opening' });
	};

	const onEntered = () => {
		setState({ ...state, status: 'open' });
	};

	const onExiting = () => {
		setState({ ...state, status: 'closing' });
	};

	const onExited = () => {
		setState({ ...state, status: 'closed' });
	};

	const toggle = () => {
		changeStatus(state.collapse ? 'close' : 'open');
		setState({ ...state, collapse: !state.collapse });
	};

	const changeStatus = (status: string) => {
		const { isSidebarInEdit, onChangeCollapseStatus, sequence, contentType, sidebarSettings } = props;

		if (onChangeCollapseStatus) {
			const structuredData: ContentSidebarChangeCollapseStatus = {
				isSidebarInEdit,
				sequence,
				contentType,
				sidebarSettings,
				status,
			};

			onChangeCollapseStatus(structuredData);
		}
	};

	const displayButton = () => {
		if (state.status === 'open') {
			return <i className='fa fa-toggle-on'></i>;
		} else {
			return <i className='fa fa-toggle-off'></i>;
		}
	};

	const { isSidebarInEdit, drag, iconClass, sequence, title, children } = props;

	return (
		<Card className={`mb-1 ${isSidebarInEdit ? 'opacity-06' : 'opacity-1'}`}>
			<CardHeader id={`collapsible-element-${title.toLocaleLowerCase()}-toggle-button`} onClick={toggle} className='cursor-pointer'>
				{drag && (
					<>
						<span className='p-1 font-weight-bold text-muted animated fadeIn'>{sequence + 1}.</span>
						{drag}
					</>
				)}
				<i className={iconClass}></i>
				<strong>{title}</strong>
				<div className='card-header-actions'>{displayButton()}</div>
			</CardHeader>
			<Collapse isOpen={state.collapse} onEntering={onEntering} onEntered={onEntered} onExiting={onExiting} onExited={onExited}>
				<CardBody style={{ pointerEvents: isSidebarInEdit ? 'none' : 'initial' }}>{children}</CardBody>
			</Collapse>
		</Card>
	);
};

function mapStateToProps(state: any) {
	return {
		profileId: state.profile.profile.id,
	};
}
export default connect(mapStateToProps, null)(CustomCollapsibleElement) as React.ComponentType<any>;
