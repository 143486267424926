import React, { useEffect } from 'react';
import { useSelector, useDispatch, connect } from 'react-redux';
import { TabContent, TabPane, Nav, NavItem, NavLink, Button } from 'reactstrap';
import HttpService from '../../../services/rest/HttpService';
import { AppState } from '../../../store/store';
import MatchDetails from './subcomponents/match/match-details.component';
import { toast } from 'react-toastify';
import { requestMatchLineup, updateMatchCreateLineup } from '../../../store/action-creators/match-lineups-action-creator';
import MatchDetailsModel from './models/match/match-details.model';
import { validateByEventStatus, validateMatchScore, matchDetailsSaveDisable } from './subcomponents/helpers/match-details.helper';

type Properties = {
	t: any;
	history: any;
};

const MatchCreate: React.FunctionComponent<Properties> = (props) => {
	const { t, history } = props;

	const match = useSelector((state: AppState) => state.matchLineupsCreate.match);

	const dispatch = useDispatch();
	const matchUpdate = (match: MatchDetailsModel | null) => dispatch(updateMatchCreateLineup(match));

	const createMatch = () => {
		const matchDetailsRequest = MatchDetailsModel.builder(match).toRequestJson();
		HttpService.instanceFootball()
			.post(`/v2/matches`, matchDetailsRequest)
			.then((response: any) => {
				const matchId = response.data.id;
				matchUpdate(MatchDetailsModel.builder().withKikoffTime(match.kickoffTime).build());
				dispatch(requestMatchLineup(response.data));
				history.push(`/smp/matches/edit/${matchId}`);
				toast.success(t('match_create_success'));
			})
			.catch(() => toast.error(t('match_create_failed')));
	};

	useEffect(() => {
		document.title = t('create_match');
	}, []);

	const phaseStartedIsNotValid = match && validateByEventStatus(match.eventStatus, 'phaseStartedAt') && !match.startTime;

	const scoreIsNotValid = match && validateByEventStatus(match.eventStatus, 'score') && validateMatchScore(match.score);

	const isMatchDetailsSaveDisabled = matchDetailsSaveDisable(match, phaseStartedIsNotValid, scoreIsNotValid);
	return (
		<div className='nav-tabs-wrapper animated fadeIn'>
			<Nav tabs className='mb-2'>
				<NavItem>
					<NavLink className='active bg-transparent'>
						<i className='fa fa-futbol-o mr-2' />
						{t('match_details')}
					</NavLink>
				</NavItem>
				<NavItem className='d-flex align-items-center ml-auto'>
					<Button disabled={isMatchDetailsSaveDisabled} onClick={createMatch} id='save-match-button-top' color='primary' size='sm'>
						<i className='fa fa-floppy-o mr-1' />
						{t('save_match')}
					</Button>
				</NavItem>
			</Nav>
			<TabContent activeTab='1' className='border-0 bg-transparent'>
				<TabPane tabId='1' className='px-0'>
					{match && <MatchDetails isLoading={false} onChange={matchUpdate} t={t} match={match} />}
					<Button disabled={isMatchDetailsSaveDisabled} onClick={createMatch} id='save-details-match-bottom' color='primary'>
						<i className='fa fa-floppy-o mr-1' />
						{t('save_match')}
					</Button>
				</TabPane>
			</TabContent>
		</div>
	);
};

export default MatchCreate;
