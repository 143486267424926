import React from 'react';
import { Col, Row } from 'reactstrap';
import DecoratedPlayersWidgetModel from '../models/decorated-players-widget.model';

export type Properties = {
	preview: DecoratedPlayersWidgetModel;
	t: any;
};

export const MostDecoratedPlayersView: React.FunctionComponent<Properties> = ({ preview, t }) => {
	if (preview && preview.tournament && preview.tournament.id) {
		return (
			<Row className='px-4'>
				<Col className='text-left'>
					<Row className='mb-2'>
						<Col>
							<div>
								<strong>{t('tournament')}:</strong>
								<img className='ml-1 mr-1' width='35px' src={preview.tournament.logo} alt={preview.tournament.name} />
								{preview.tournament.name}, {preview.season.name}
							</div>
						</Col>
					</Row>
					<Row>
						<Col className='px-4'>
							<div style={{ padding: '5px' }}>
								<hr></hr>
							</div>
						</Col>
					</Row>
					<Row>
						{preview.columns && preview.columns.length > 0 && (
							<Col>
								<strong>{t('columns_to_display')}:</strong>
								{preview.columns.map((column, index) => {
									return (
										<span key={`decorated-players-column-key-${index}`} className='pl-1'>
											{t(`${column}_title`)}
											{index + 1 === preview.columns.length ? '' : ','}
										</span>
									);
								})}
							</Col>
						)}
					</Row>
					<Row className='mb-2 d-flex justify-content-between'>
						<Col>
							<strong>{t('highlighted_players')}:</strong>
							{preview.highlightPlayers && preview.highlightPlayers.length > 0 ? (
								preview.highlightPlayers.map((player, index) => {
									return (
										<span key={`player-standings-key-${index}`}>
											<img className='img-circle ml-2' width='20px' src={player.headshot} alt={player.name} /> {player.name}
											{index + 1 === preview.highlightPlayers.length ? '' : ','}
										</span>
									);
								})
							) : (
								<span> {t('no_players_selected')}</span>
							)}
						</Col>
					</Row>
					<Row>
						{preview.startFrom && preview.startFrom.length > 0 && (
							<Col>
								<strong>{t('start_from_position')}:</strong>
								<span className='ml-2'>{preview.startFrom}</span>
							</Col>
						)}
					</Row>
					<Row className='mb-2'>
						{preview.limit && preview.limit.length > 0 && (
							<Col>
								<strong>{t('show_number_of_players')}:</strong>
								<span className='ml-2'>{preview.limit}</span>
							</Col>
						)}
					</Row>
					<Row>
						{preview.player && preview.player.id && (
							<Col>
								<strong>{t('selected_player')}:</strong>
								<span>
									<img className='img-circle ml-2' width='20px' src={preview.player.headshot} alt={preview.player.name} />
									{preview.player.name}
								</span>
							</Col>
						)}
					</Row>
					<Row>
						{preview.playerOffset && preview.playerOffset.length > 0 && (
							<Col>
								<strong>{t('offset')}:</strong>
								<span className='ml-2'>{preview.playerOffset}</span>
							</Col>
						)}
					</Row>
					{preview.threeLetterCodeType && preview.threeLetterCodeType !== '' && (
						<Row>
							<Col>
								<strong>{t('display_team_three_letter_code')}:</strong>
								<span className='ml-2'>
									{preview.threeLetterCodeType && preview.threeLetterCodeType.name && t(`${preview.threeLetterCodeType.name}`)}
								</span>
							</Col>
						</Row>
					)}
				</Col>
			</Row>
		);
	}

	return null;
};
