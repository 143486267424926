import Statistics from '../../models/statistics/statistics';
import Project from '../../models/project/Project';

export const USER_STATISTICS_CONTENT_REQUEST = '[ACTION] USER_STATISTICS_CONTENT_REQUEST';
export const USER_STATISTICS_CONTENT_RECEIVED = '[ACTION] USER_STATISTICS_CONTENT_RECEIVED';
export const USER_STATISTICS_CONTENT_FAILED = '[ACTION] USER_STATISTICS_CONTENT_FAILED';

export const ALL_STATISTICS_CONTENT_REQUEST = '[ACTION] ALL_STATISTICS_CONTENT_REQUEST';
export const ALL_STATISTICS_CONTENT_RECEIVED = '[ACTION] ALL_STATISTICS_CONTENT_RECEIVED';
export const ALL_STATISTICS_CONTENT_FAILED = '[ACTION] ALL_STATISTICS_CONTENT_FAILED';

export const PLAYER_STATISTICS_REQUEST = '[ACTION] PLAYER_STATISTICS_REQUEST';
export const PLAYER_STATISTICS_RECEIVED = '[ACTION] PLAYER_STATISTICS_RECEIVED';
export const PLAYER_STATISTICS_FAILED = '[ACTION] PLAYER_STATISTICS_FAILED';

export const TEAM_STATISTICS_REQUEST = '[ACTION] TEAM_STATISTICS_REQUEST';
export const TEAM_STATISTICS_RECEIVED = '[ACTION] TEAM_STATISTICS_RECEIVED';
export const TEAM_STATISTICS_FAILED = '[ACTION] TEAM_STATISTICS_FAILED';

export const CATEGORY_RELATED_STATISTICS_REQUEST = '[ACTION] CATEGORY_RELATED_STATISTICS_REQUEST';
export const CATEGORY_RELATED_STATISTICS_RECEIVED = '[ACTION] CATEGORY_RELATED_STATISTICS_RECEIVED';
export const CATEGORY_RELATED_STATISTICS_FAILED = '[ACTION] CATEGORY_RELATED_STATISTICS_FAILED';

export const returnObjectForUserStatisticsContentRequest = (startDate: string, createdBy: string, project: Project) => ({
	type: USER_STATISTICS_CONTENT_REQUEST,
	payload: { startDate, createdBy, project },
});

export const returnObjectForUserStatisticsContentReceived = (userStatistic: Statistics, resourceType: string) => ({
	type: USER_STATISTICS_CONTENT_RECEIVED,
	payload: { userStatistic, resourceType },
});

export const returnObjectForAllStatisticsContentRequest = (startDate: string, project: Project) => ({
	type: ALL_STATISTICS_CONTENT_REQUEST,
	payload: { startDate, project },
});

export const returnObjectForAllStatisticsContentReceived = (allStatistic: Statistics, resourceType: string) => ({
	type: ALL_STATISTICS_CONTENT_RECEIVED,
	payload: { allStatistic, resourceType },
});

export const returnObjectForPlayerStatisticsRequest = (
	resourceType: string,
	relatedType: string,
	relatedId: string,
	startDate: string,
	endDate: string,
	project: Project,
) => ({
	type: PLAYER_STATISTICS_REQUEST,
	payload: {
		resourceType,
		startDate,
		endDate,
		relatedType,
		relatedId,
		project,
	},
});

export const returnObjectForPlayerStatisticsReceived = (playerStatistic: Statistics, resourceType: string) => ({
	type: PLAYER_STATISTICS_RECEIVED,
	payload: { playerStatistic, resourceType },
});

export const returnObjectForTeamStatisticsRequest = (
	resourceType: string,
	relatedType: string,
	relatedId: string,
	startDate: string,
	endDate: string,
	project: Project,
) => ({
	type: TEAM_STATISTICS_REQUEST,
	payload: {
		resourceType,
		startDate,
		endDate,
		relatedType,
		relatedId,
		project,
	},
});

export const returnObjectForTeamStatisticsReceived = (teamStatistic: Statistics, resourceType: string) => ({
	type: TEAM_STATISTICS_RECEIVED,
	payload: { teamStatistic, resourceType },
});

export const returnObjectForCategoryRelatedStatisticsRequest = (
	resourceType: string,
	startDate: string,
	endDate: string,
	categoryId: string,
	project: Project,
) => ({
	type: CATEGORY_RELATED_STATISTICS_REQUEST,
	payload: { resourceType, startDate, endDate, categoryId, project },
});

export const returnObjectForCategoryRelatedStatisticsReceived = (categoryStatistic: Statistics, resourceType: string) => ({
	type: CATEGORY_RELATED_STATISTICS_RECEIVED,
	payload: { categoryStatistic, resourceType },
});
