import 'reflect-metadata';
import { TypedJSON } from 'typedjson';
import AuditLogModel from './audit-log.model';
import ActorModel from './actor.model';
import ContentModel from './content.model';

export default class AuditLogMapper {
	public responseToModel = (response: any): AuditLogModel => {
		const serializer = new TypedJSON(AuditLogModel);
		return serializer.parse(response) as AuditLogModel;
	};
	public modelToRequestBody = (model: AuditLogModel) => {
		if (model) {
			return {
				action: model.action,
				actor: this.actorToRequest(model.actor),
				content: this.contentToRequest(model.content),
				project: model.project,
				action_time: model.action_time,
			};
		}
		return new AuditLogModel();
	};

	private actorToRequest = (actor: ActorModel) => {
		if (actor) {
			return {
				id: actor.id,
				name: actor.name,
				type: actor.type,
			};
		}
	};

	private contentToRequest = (content: ContentModel) => {
		if (content) {
			return {
				id: content.id,
				name: content.name,
				type: content.type,
			};
		}
	};
}
