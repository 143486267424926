import React, { useEffect, useRef } from 'react';
import { noPreviewImage } from '../../../../../../constants/resource-constants';
import Hls from 'hls.js';

type Properties = {
	url: string;
	videoType: string;
};

const VideoElement: React.FunctionComponent<Properties> = (props) => {
	const { url, videoType } = props;
	const videoRef = useRef<HTMLVideoElement>(null);
	const ALLOWED_VIDEO_TYPES = ['mp4', 'webm', 'ogg', 'avi', 'hls', 'm3u8'];

	useEffect(() => {
		if (videoType === 'm3u8' || videoType === 'hls') {
			if (Hls.isSupported()) {
				const hls = new Hls();
				hls.loadSource(url);
				hls.attachMedia(videoRef.current!);
				hls.on(Hls.Events.MANIFEST_PARSED, () => {
					videoRef.current!.play().catch((error) => console.error('Error playing video:', error));
				});
				return () => {
					hls.destroy();
				};
			} else if (videoRef.current!.canPlayType('application/vnd.apple.mpegurl')) {
				videoRef.current!.src = url;
				videoRef.current!.play().catch((error) => console.error('Error playing video:', error));
			}
		}
	}, [url, videoType]);

	if (!ALLOWED_VIDEO_TYPES.includes(videoType)) {
		return <img src={noPreviewImage} alt='Video format not supported' />;
	}

	return (
		<video ref={videoRef} controls={true} width='100%' muted>
			{!(videoType === 'm3u8' || videoType === 'hls') && <source src={url} type={`video/${videoType}`} />}
		</video>
	);
};

export default VideoElement;
