import Project from '../../models/project/Project';
import ErrorResponseModel from '../../models/error-response/ErrorResponseModel';
import Feed from '../../models/feed/feed';

export const FEED_LISTING_REQUEST = '[ACTION] FEED_LISTING_REQUEST';
export const FEED_LISTING_RECEIVED = '[ACTION] FEED_LISTING_RECEIVED';
export const FEED_LISTING_FAILED = '[ACTION] FEED_LISTING_FAILED';

export const FEED_ENTITY_REQUEST = '[ACTION] FEED_ENTITY_REQUEST';
export const FEED_ENTITY_RECEIVED = '[ACTION] FEED_ENTITY_RECEIVED';

export const FEED_ENTITY_CREATE = '[ACTION] FEED_ENTITY_CREATE';
export const FEED_ENTITY_CREATE_SUCCESS = '[ACTION] FEED_ENTITY_CREATE_SUCCESS';
export const FEED_ENTITY_CREATE_FAILED = '[ACTION] FEED_ENTITY_CREATE_FAILED';

export const FEED_ENTITY_UPDATE = '[ACTION] FEED_ENTITY_UPDATE';
export const FEED_ENTITY_UPDATE_FAILED = '[ACTION] FEED_ENTITY_UPDATE_FAILED';
export const FEED_ENTITY_UPDATE_SUCCESS = '[ACTION] FEED_ENTITY_UPDATE_SUCCESS';

export const SOURCE_LISTING_REQUEST = '[ACTION] SOURCE_LISTING_REQUEST';
export const SOURCE_LISTING_RECEIVED = '[ACTION] SOURCE_LISTING_RECEIVED';
export const SOURCE_LISTING_FAILED = '[ACTION] SOURCE_LISTING_FAILED';

export const FEED_ENTITY_DELETE = '[ACTION] FEED_ENTITY_DELETE';
export const FEED_ENTITY_DELETE_SUCCESS = '[ACTION] FEED_ENTITY_DELETE_SUCCESS';
export const FEED_ENTITY_DELETE_FAILED = '[ACTION] FEED_ENTITY_DELETE_FAILED';

export const TEMPLATE_LISTING_REQUEST = '[ACTION] TEMPLATE_LISTING_REQUEST';
export const TEMPLATE_LISTING_RECEIVED = '[ACTION] TEMPLATE_LISTING_RECEIVED';
export const TEMPLATE_LISTING_FAILED = '[ACTION] TEMPLATE_LISTING_FAILED';

export const TEMPLATE_ENTITY_REQUEST = '[ACTION] TEMPLATE_ENTITY_REQUEST';
export const TEMPLATE_ENTITY_RECEIVED = '[ACTION] TEMPLATE_ENTITY_RECEIVED';
export const TEMPLATE_ENTITY_REQUEST_FAILED = '[ACTION] TEMPLATE_ENTITY_REQUEST_FAILED';

export const SOURCE_ENTITY_REQUEST = '[ACTION] SOURCE_ENTITY_REQUEST';
export const SOURCE_ENTITY_RECEIVED = '[ACTION] SOURCE_ENTITY_RECEIVED';
export const SOURCE_ENTITY_FAILED = '[ACTION] SOURCE_ENTITY_FAILED';

export const SELECTED_FEED_UPDATE = '[ACTION] SELECTED_FEED_UPDATE';
export const SELECTED_DATE_UPDATE = '[ACTION] SELECTED_DATE_UPDATE';
export const SOURCE_TAB_UPDATE = '[ACTION] SOURCE_TAB_UPDATE';
export const NOTIFICATIONS_UPDATE = '[ACTION] NOTIFICATIONS_UPDATE';
export const SELECTED_FILTER_SOURCES_UPDATE = '[ACTION] SELECTED_FILTER_SOURCES_UPDATE';

export const returnObjectForFeedsListingRequest = (page: string, project: Project, profileId: string) => ({
	type: FEED_LISTING_REQUEST,
	payload: { page, project, profileId },
});

export const returnObjectForFeedsListingReceived = (feed: any) => ({
	type: FEED_LISTING_RECEIVED,
	payload: feed,
});

export const returnObjectForFeedListingFailed = (error: any) => ({
	type: FEED_LISTING_FAILED,
	payload: ErrorResponseModel.builder().fromResponse(error).build(),
});

export const returnObjectForFeedEntityCreate = (feed: Feed, project: Project, profileId: string) => ({
	type: FEED_ENTITY_CREATE,
	payload: { feed, project, profileId },
});

export const returnObjectForFeedEntityRequest = (id: string, project: Project, profileId: string) => ({
	type: FEED_ENTITY_REQUEST,
	payload: { id, project, profileId },
});

export const returnObjectForFeedEntityReceived = (feed: any) => ({
	type: FEED_ENTITY_RECEIVED,
	payload: feed,
});

export const returnObjectForFeedEntityCreateSuccess = (id: string) => ({
	type: FEED_ENTITY_CREATE_SUCCESS,
	payload: id,
});

export const returnObjectForFeedEntityCreateFailed = () => ({
	type: FEED_ENTITY_CREATE_FAILED,
});

export const returnObjectForFeedEntityUpdate = (feed: Feed, project: Project, profileId: string) => ({
	type: FEED_ENTITY_UPDATE,
	payload: { feed, project, profileId },
});

export const returnObjectForFeedEntityUpdateSuccess = () => ({
	type: FEED_ENTITY_UPDATE_SUCCESS,
});

export const returnObjectForFeedEntityUpdateFailed = () => ({
	type: FEED_ENTITY_UPDATE_FAILED,
});

export const returnObjectForSourceListingReceived = (source: any) => ({
	type: SOURCE_LISTING_RECEIVED,
	payload: source,
});

export const returnObjectForFeedEntityDelete = (id: string, project: Project, profileId: string) => ({
	type: FEED_ENTITY_DELETE,
	payload: { id, project, profileId },
});

export const returnObjectForFeedEntityDeleteSuccess = () => ({
	type: FEED_ENTITY_DELETE_SUCCESS,
});

export const returnObjectForTemplatesListingRequest = (page: string, project: Project, profileId: string) => ({
	type: TEMPLATE_LISTING_REQUEST,
	payload: { page, project, profileId },
});

export const returnObjectForTemplatesListingReceived = (templates: any) => ({
	type: TEMPLATE_LISTING_RECEIVED,
	payload: templates,
});

export const returnObjectForTemplateEntityRequest = (id: string, project: Project) => ({
	type: TEMPLATE_ENTITY_REQUEST,
	payload: { id, project },
});

export const returnObjectForTemplateEntityReceived = (template: any) => ({
	type: TEMPLATE_ENTITY_RECEIVED,
	payload: template,
});

export const returnObjectForSourceEntityRequest = (location: string, project: Project, profileId: string) => ({
	type: SOURCE_ENTITY_REQUEST,
	payload: { location, project, profileId },
});

export const returnObjectForSelectedFeed = (feed: Feed) => ({
	type: SELECTED_FEED_UPDATE,
	payload: feed,
});

export const returnObjectForUpdateNotifications = (notifications: any[]) => ({
	type: NOTIFICATIONS_UPDATE,
	payload: notifications,
});

export const returnObjectForSelectedDate = (date: any) => ({
	type: SELECTED_DATE_UPDATE,
	payload: date,
});

export const returnObjectForSourceTabUpdate = (sourceTab: any) => ({
	type: SOURCE_TAB_UPDATE,
	payload: sourceTab,
});

export const returnObjectForSelectedFilterSources = (selectedSources: any) => ({
	type: SELECTED_FILTER_SOURCES_UPDATE,
	payload: selectedSources,
});
