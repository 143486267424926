import * as React from 'react';
import './video-cells.scss';
import YoutubeVideoRow from './youtube-video-row';
import { copyToClipboard } from '../../dugout-video-block/video-cells/helpers/video-cells.helper';
import YoutubeVideoModel from '../../../../../../../models/youtube-sports/youtube-video/youtube-video.model';
import VideoPlayModal from '../../../../../Shared/video-play-modal/video-play-modal.component';

type Properties = {
	videos: any[];
	analytics?: any;
	t: any;
	toggleLoadingState: boolean;
	onSelect: (video: any) => void;
	selectedVideo: any;
};

export type State = {
	isOpenModal: boolean;
	videoToPlay: any | null;
};
export default class YoutubeVideoContainer extends React.Component<Properties, State> {
	constructor(props: any) {
		super(props);
		this.state = {
			isOpenModal: false,
			videoToPlay: null,
		};
	}

	copyId = (e: any, id: string) => {
		copyToClipboard(e, id.replace(/\"/g, ''), this.props.t, 'id');
	};

	copyEmbed = (e: any, embedCode: string) => {
		copyToClipboard(e, embedCode, this.props.t, 'embedCode');
	};

	playVideo = (open: boolean, video: YoutubeVideoModel) => {
		this.setState({ ...this.state, isOpenModal: open, videoToPlay: video });
	};

	onClose = (open: boolean) => {
		this.setState({ ...this.state, isOpenModal: open, videoToPlay: null });
	};

	render() {
		const { videos, t, onSelect, selectedVideo } = this.props;
		const { isOpenModal, videoToPlay } = this.state;

		return (
			<div className='youtube-video-cells-container'>
				{videos &&
					videos.length > 0 &&
					videos.map((video: any) => {
						return (
							<YoutubeVideoRow
								key={`${video.id + Math.random()}`}
								t={t}
								video={video}
								onSelect={onSelect}
								copyEmbed={this.copyEmbed}
								playVideo={this.playVideo}
								copyId={this.copyId}
								selectedVideo={selectedVideo}
							/>
						);
					})}
				{videoToPlay && (
					<VideoPlayModal
						t={t}
						onClose={this.onClose}
						title={videoToPlay.snippet.title}
						url={`https://www.youtube.com/embed/${videoToPlay.id.videoId}?autoplay=1`}
						open={isOpenModal}
					/>
				)}
			</div>
		);
	}
}
