import React from 'react';
import Select from 'react-select';
import { Col, Label, Row } from 'reactstrap';
import HttpService from '../../../../../services/rest/HttpService';
import { Properties, State } from './properties/season-select.properties';
import { remapSeasonFromResponse, seasonsToOptions, seasonToOption } from './helpers/season-select.helper';
import SeasonModel from '../../blocks/widgets/player-profile/models/season/season.model';
import ErrorHandler from '../error/error-handler-component';

export default class SeasonSelectContainer extends React.Component<Properties, State> {
	constructor(props: Properties) {
		super(props);
		this.state = {
			seasonsOptions: [],
		};
	}

	componentDidMount() {
		if (this.props.tournamentId) {
			this.getSeasons(this.props.tournamentId);
		}
	}

	componentDidUpdate(prevProps: Properties) {
		/**
		 * This checks if the user selects the same tournament that's already been selected and if there are seasons for it,
		 *  so that props.selectedSeason can be prefilled with the first option
		 */
		const preselectSeasonOnSameTournamentSelection =
			prevProps.tournamentId === this.props.tournamentId && !this.props.selectedSeason && this.state.seasonsOptions.length > 0;
		const checkIfTournamentChangesOrSeasonHasNoId =
			prevProps.tournamentId !== this.props.tournamentId ||
			(this.props.selectedSeason && (!this.props.selectedSeason.id || this.props.selectedSeason.id.length < 1));

		if (this.props.tournamentId && checkIfTournamentChangesOrSeasonHasNoId) {
			this.getSeasons(this.props.tournamentId);
		} else if (preselectSeasonOnSameTournamentSelection) {
			this.props.onSeasonSelect(this.state.seasonsOptions[0]);
		}
	}

	updateSeasonsOptionsState(seasons: SeasonModel[]) {
		const { onSeasonSelect, selectedSeason } = this.props;

		if (!selectedSeason || (selectedSeason && (selectedSeason.id === undefined || selectedSeason.id.length < 1))) {
			onSeasonSelect(seasons[0]);
		}
		this.setState({ ...this.state, seasonsOptions: seasons });
	}

	getSeasons(tournamentId: string) {
		if (tournamentId) {
			HttpService.getTournamentById(tournamentId, this.props.lang)
				.then((response: any) => {
					this.updateSeasonsOptionsState(remapSeasonFromResponse(response.data));
				})
				.catch((e: any) => e);
		}
	}

	render() {
		const { onSeasonSelect, selectedSeason, tournamentId, t, label, withLabel } = this.props;

		if (tournamentId === undefined || tournamentId === null || tournamentId.length < 1) {
			return null;
		}

		return (
			<Row>
				<Col>
					{withLabel && <Label>{t('season')}:</Label>}
					{label && <Label>{t(label)}</Label>}
					<Select
						menuPortalTarget={document.body}
						menuPosition='absolute'
						placeholder={t('select')}
						options={seasonsToOptions(this.state.seasonsOptions)}
						value={seasonToOption(selectedSeason)}
						noOptionsMessage={(inputValue) => inputValue && t('no_options')}
						onChange={(selection: any) => onSeasonSelect(selection.data)}
						styles={{
							menuPortal: (baseStyles) => ({
								...baseStyles,
								zIndex: 10000,
							}),
						}}
					/>
					{!this.props.isValid && !this.props.selectedSeason && <ErrorHandler t={this.props.t} errorMessage='field_is_required' />}
				</Col>
			</Row>
		);
	}
}
