import Country from '../../../../models/v2/football/country/country.model';
import TournamentModel from '../../../../models/v2/Tournament/Entity/TournamentModel';
import { FILTER_TYPE } from '../constants/Contstants';

export default class TournamentFilterFactory {
	public filter = (tournaments: TournamentModel[], searchName: string, countryFilter: Country[]): TournamentModel[] => {
		const filterType = this.decideFilterType(tournaments, searchName, countryFilter);

		switch (filterType) {
			case FILTER_TYPE.MIXED:
				return this.filterByTournamentNameAndCounties(tournaments, searchName, countryFilter);
			case FILTER_TYPE.BY_NAME:
				return this.filterByTournamentName(tournaments, searchName);
			case FILTER_TYPE.BY_COUNTRY:
				return this.filterByCountries(tournaments, countryFilter);
			case FILTER_TYPE.NONE:
				return tournaments;
			default:
				return tournaments;
		}
	};

	private decideFilterType = (tournaments: TournamentModel[], searchName: string, countryFilter: Country[]): FILTER_TYPE => {
		const isByName = this.isSearchByName(searchName);
		const isByCountry = this.isFilterByCountry(countryFilter);

		if (tournaments === undefined || tournaments === null || tournaments.length < 1) {
			return FILTER_TYPE.NONE;
		}

		if (isByName && isByCountry) {
			return FILTER_TYPE.MIXED;
		}

		if (isByName) {
			return FILTER_TYPE.BY_NAME;
		}

		if (isByCountry) {
			return FILTER_TYPE.BY_COUNTRY;
		}

		return FILTER_TYPE.NONE;
	};

	private filterByTournamentName = (tournaments: TournamentModel[], searchName: string): TournamentModel[] => {
		return tournaments.filter((tournament) => {
			return tournament.name.toLocaleLowerCase().includes(searchName.toLowerCase());
		});
	};

	private filterByCountries = (tournaments: TournamentModel[], countryFilter: Country[]): TournamentModel[] => {
		const countiesIdsFilter = this.extractCountryIds(countryFilter);

		return tournaments.filter((tournament) => {
			const country = tournament.country;
			return country ? countiesIdsFilter.includes(country.id) : true;
		});
	};

	private filterByTournamentNameAndCounties = (
		tournaments: TournamentModel[],
		searchName: string,
		countryFilter: Country[],
	): TournamentModel[] => {
		const countiesIdsFilter = this.extractCountryIds(countryFilter);

		return tournaments.filter((tournament) => {
			const includesName = tournament.name.toLocaleLowerCase().includes(searchName.toLowerCase());
			const country = tournament.country;
			const includesCountries = country ? countiesIdsFilter.includes(country.id) : true;
			return includesName && includesCountries;
		});
	};

	private extractCountryIds = (countries: Country[]) => {
		return countries.map((country) => country.id);
	};

	private isSearchByName = (searchName: string) => {
		return searchName !== undefined && searchName !== null && searchName.length > 0;
	};

	private isFilterByCountry = (countriesFilter: Country[]) => {
		return countriesFilter && countriesFilter.length > 0;
	};
}
