// This is copied component from 'Urls' folder before the sidebar refactoring
import React, { FunctionComponent } from 'react';
import Select from 'react-select';
import { Button, Col, FormGroup, Label, Row } from 'reactstrap';
import { featuresService } from '../../../../../../App';
import { FeatureTypes } from '../../../../../../services/feature-service/features.enum';
import ErrorAsync from '../../../../BaseComponents/ErrorComponent/ErrorAsync';
import { optionToVideoUrls, pathToVideoUrls } from './helpers/video-urls.component.helper';
import { DebounceInput } from 'react-debounce-input';
import { extractVideoType } from '../../../../Blocky/blocks/content/content-block/subcomponents/helpers/preview-content.helper';
import VideoElement from './video-element.component';
import VideoUrlModel from '../../../../../../models/v2/video-url/video-url.model';
import { redirectTypeToOption, redirectTypesToOptions, removeUsedRedirectTypes } from '../../helpers/video-url.helper';

const validator = require('validator');

type Properties = {
	urls: any[];
	t: any;
	onVideoUrlsChange: (value: VideoUrlModel[]) => any;
};

const VideoUrlsComponent: FunctionComponent<Properties> = ({ urls, t, onVideoUrlsChange }) => {
	const videoTypesConfig = featuresService.getFeatureConfig(FeatureTypes.VIDEO_URLS);
	const maxVideoUrls = videoTypesConfig && videoTypesConfig.types ? videoTypesConfig.types.length : 0;
	const unUsedTypes = removeUsedRedirectTypes(urls, videoTypesConfig.types);

	const onUrlsTypeChange = (option: any, index: number) => {
		urls[index] = optionToVideoUrls(option);
		onVideoUrlsChange(urls);
	};

	const onUrlsChange = (e: any, index: number) => {
		urls[index] = pathToVideoUrls(e.target.value, urls[index]);
		onVideoUrlsChange(urls);
	};

	const addUrl = () => {
		urls.push({ type: '', url: '' });
		if (urls.length <= maxVideoUrls) {
			onVideoUrlsChange(urls);
		}
	};

	const removeUrl = (index: number) => {
		urls.splice(index, 1);
		onVideoUrlsChange(urls);
	};

	return (
		<>
			{urls.map((data: any, index: number) => {
				return (
					<div className='border-bottom mb-3' key={'url-video-' + index}>
						<Row>
							<Col md={11} className='mt-1'>
								<Select
									options={redirectTypesToOptions(unUsedTypes, t)}
									inputId='video-types'
									value={data.type && data.type.length > 0 ? redirectTypeToOption(data.type, t) : null}
									onChange={(option: any) => onUrlsTypeChange(option, index)}
									isClearable={videoTypesConfig.types ? true : false}
									placeholder={t('select')}
									noOptionsMessage={(inputValue) => inputValue && t('no_options')}
								/>
							</Col>
							<Col md={1} className='pl-0 mt-1'>
								<div onClick={() => removeUrl(index)} className='btn btn-sm btn-danger d-flex flex-fill justify-content-center cursor-pointer'>
									<i className={`fa fa-trash p-2 text-white`} />
								</div>
							</Col>
						</Row>
						<Row className='mt-2'>
							<Col md={12}>
								<FormGroup>
									<DebounceInput
										type='text'
										debounceTimeout={500}
										id='video-url'
										className='form-control'
										placeholder={t('video_url_placeholder')}
										value={data.url ? data.url : ''}
										onChange={(event: any) => onUrlsChange(event, index)}
									/>
									{data.url && validator.isURL(data.url) && (
										<Row key={data.url + index} className='mb-2'>
											<Col>
												<Label>{t('preview_video_url')}:</Label>
												<div className='d-flex'>
													<VideoElement url={data.url} videoType={extractVideoType(data.url)} />
												</div>
											</Col>
										</Row>
									)}
									<ErrorAsync t={t} errorType='video_files' />
								</FormGroup>
							</Col>
						</Row>
					</div>
				);
			})}
			{urls.length < maxVideoUrls ? (
				<Button
					size='md'
					color='link'
					id='article-edit-save-top'
					style={{ width: '100%' }}
					className={'ml-auto mr-2 text-nowrap border border-radius-2'}
					onClick={addUrl}
				>
					<span>{t('add_url')}</span>
				</Button>
			) : null}
		</>
	);
};

export default VideoUrlsComponent;
