import ErrorResponseModel from '../../models/error-response/ErrorResponseModel';

export const GENERAL_ERROR = '[ACTION] GENERAL_ERROR';
export const CLEAR_ERRORS = '[ACTION] CLEAR_ERRORS';
export const SET_LOADING_STATE = '[ACTION] SET_LOADING_STATE';

export function onError(error: any) {
	return {
		type: GENERAL_ERROR,
		payload: ErrorResponseModel.builder().fromResponse(error).build(),
	};
}

export function clearErrors() {
	return {
		type: CLEAR_ERRORS,
		payload: {},
	};
}

export function setLoadingState(loadingStateFlag: boolean) {
	return {
		type: SET_LOADING_STATE,
		payload: loadingStateFlag,
	};
}
