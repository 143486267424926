import React, { FunctionComponent, useEffect, useState } from 'react';
import { Button, Col, Row } from 'reactstrap';
import TournamentOrderModel from '../../../../../../models/v2/Tournament/TournamentOrder/TournamentOrderModel';
import TournamentGroupModel from '../../../../../../models/v2/Tournament/Group/TournamentGroupModel';
import {
	clearAlreadySelectedEl,
	DisabledSaveModel,
	emptyTournamentRowAvailable,
	findIsTournamentAlreadySelected,
	findLastSortOrder,
	modifyTournamentSortOrderBasedOnArrayIndex,
	removeTournamentOrderById,
} from '../../../helpers/TournamentsGroupsUiService';
import ContentSortableList from '../DragAndDrop/SortableList';
import * as ReactSortableHOC from 'react-sortable-hoc';
import TournamentModel from '../../../../../../models/v2/Tournament/Entity/TournamentModel';
import { toast } from 'react-toastify';

type Props = {
	t: any;
	tournamentsGroup: TournamentGroupModel;
	disabledSave: DisabledSaveModel;
	setTournamentsGroup: Function;
	setDisabledSave: Function;
};

const TournamentsGroupBody: FunctionComponent<Props> = ({ t, tournamentsGroup, setTournamentsGroup, disabledSave, setDisabledSave }) => {
	const [disableAddRow, setDisableAddRow] = useState(false);

	useEffect(() => {
		// disable 'add row' button
		emptyTournamentRowAvailable(tournamentsGroup.tournaments) ? setDisableAddRow(true) : setDisableAddRow(false);
	}, [tournamentsGroup.tournaments]);

	const handleAddRow = () => {
		if (!emptyTournamentRowAvailable(tournamentsGroup.tournaments)) {
			const newTournamentsOrderArr = [...tournamentsGroup.tournaments, new TournamentOrderModel()];
			setTournamentsGroup({ ...tournamentsGroup, tournaments: newTournamentsOrderArr });
		}
	};

	const pickNewTournament = (el: any) => {
		if (el.data) {
			const selectedTournament = el.data as TournamentModel;
			let isAlreadyExist = findIsTournamentAlreadySelected(tournamentsGroup.tournaments, selectedTournament);

			if (!isAlreadyExist) {
				const withouSelectedTournament = tournamentsGroup.tournaments.filter((tournamentOrder) => tournamentOrder.tournament.id !== ''); // removing old tournament element without id (used only for select input element rendering)
				const lastSortOrder = findLastSortOrder(tournamentsGroup.tournaments);
				const newTournamentOrderEl = { sortOrder: lastSortOrder + 1, tournament: selectedTournament } as TournamentOrderModel;
				const withSelectedTorunament = [...withouSelectedTournament, newTournamentOrderEl];
				setTournamentsGroup({ ...tournamentsGroup, tournaments: withSelectedTorunament });
				setDisabledSave({ ...disabledSave, changedTournaments: true });
			} else {
				clearAlreadySelectedEl(el);
				toast.error(t('tournament_already_selected'));
			}
			setDisableAddRow(false);
		}
	};

	const handleDeleteRow = (tournamentId: string) => {
		const newTournamentsOrderArr = removeTournamentOrderById(tournamentsGroup.tournaments, tournamentId);
		setTournamentsGroup({ ...tournamentsGroup, tournaments: newTournamentsOrderArr });
		setDisabledSave({ ...disabledSave, changedTournaments: true });
	};

	const onSortEnd = (sortEnd: ReactSortableHOC.SortEnd) => {
		const currentTournaments: TournamentOrderModel[] = [...tournamentsGroup.tournaments];
		let newTournamentsOrder = ReactSortableHOC.arrayMove(currentTournaments, sortEnd.oldIndex, sortEnd.newIndex);
		newTournamentsOrder = modifyTournamentSortOrderBasedOnArrayIndex(newTournamentsOrder);
		setTournamentsGroup({ ...tournamentsGroup, tournaments: newTournamentsOrder });
		setDisabledSave({ ...disabledSave, changedTournaments: true });
	};

	return (
		<div className='animated fadeIn'>
			<Row>
				<Col col='12' lg='12' md='12' sm='12' xs='12'>
					<div className='card'>
						<div className='card-header d-flex align-items-center '>
							<div className='mr-auto mb-0'>{t('select_tournaments')}</div>
						</div>
						<div className='card-body'>
							{tournamentsGroup.tournaments.length > 0 && (
								<ContentSortableList
									t={t}
									tournamentsGroup={tournamentsGroup}
									handleDeleteRow={handleDeleteRow}
									pickNewTournament={pickNewTournament}
									onSortEnd={onSortEnd}
								/>
							)}
							<Button color='primary' outline disabled={disableAddRow} onClick={handleAddRow}>
								<i className='fa fa-plus mx-1' />
								{t('add_row')}
							</Button>
						</div>
					</div>
				</Col>
			</Row>
		</div>
	);
};

export default TournamentsGroupBody;
