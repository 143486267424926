import { TranslationGroupJson } from './translation-group-json';
import TranslationGroupBuilder from './translation-group-builder';
import TranslationsModel from './translations/translations-model';

export default class TranslationGroupModel {
	readonly id: string = '';
	readonly contentType: string = '';
	readonly masterContentId: string = '';
	readonly translations: TranslationsModel[] = [];

	private constructor(id: string, contentType: string, masterContentId: string, translations: TranslationsModel[]) {
		this.id = id;
		this.contentType = contentType;
		this.masterContentId = masterContentId;
		this.translations = translations;
	}

	toJSON(): TranslationGroupJson {
		return {
			id: this.id,
			contentType: this.contentType,
			masterContentId: this.masterContentId,
			translations: this.translations,
		};
	}

	static fromJSON(json: TranslationGroupJson) {
		return new TranslationGroupModel(json.id, json.contentType, json.masterContentId, json.translations);
	}

	static builder(translationGroup?: TranslationGroupModel): TranslationGroupBuilder {
		return new TranslationGroupBuilder(translationGroup);
	}
}
