import React from 'react';
import { Col, FormGroup, Row } from 'reactstrap';
import TournamentSelectContainer from '../../../../partials/tournament-select/tournament-select-container.component';
import TournamentModel from '../../player-profile/models/tournament-model/tournament.model';
import MatchListStageRoundModel from '../models/match-list-stage-round-widget.model';
import BlockModel from '../../../../models/block.model';
import SeasonModel from '../../player-profile/models/season/season.model';
import SeasonSelectContainer from '../../../../partials/season-select/season-select-container.component';
import StageSelectContainer from '../../../../partials/stage-select/stage-select-container.component';
import StageModel from '../../fixtures/models/stage/stage.model';
import RoundSelectContainer from '../../../../partials/round-select/round-select-container.component';
import RoundModel from '../../fixtures/models/round/round.model';
import MatchListConfig from '../../../../partials/match-list-config/match-list-config.component';
import MatchListConfigModel from '../../../../partials/match-list-config/models/match-list-config.model';
import { blockManagementService, blockWrapperService } from '../../../../subcomponents/blocky.component';

type Properties = {
	block: BlockModel;
	t: any;
};
type State = {
	model: MatchListStageRoundModel;
};

export default class MatchListStageRoundEdit extends React.Component<Properties, State> {
	constructor(props: Properties) {
		super(props);
		const emptyConfig = MatchListConfigModel.builder().withView('normal').build();
		this.state = {
			model:
				props.block.data.preview && props.block.data.preview.matchListStageRound
					? props.block.data.preview.matchListStageRound
					: MatchListStageRoundModel.builder().withConfig(emptyConfig).build(),
		};
	}

	componentWillUnmount(): void {
		blockWrapperService.unregisterOnSaveFunc(this.props.block.id);
	}

	componentDidMount(): void {
		blockWrapperService.registerOnSaveFunc({ blockId: this.props.block.id, func: this.onSave });
	}

	private onSave = (index: number) => {
		const block = Object.assign({}, this.props.block);
		block.data.preview = { matchListStageRound: MatchListStageRoundModel.builder(this.state.model).build() };
		blockManagementService.blockUpdate(block, index);
	};

	private onTournamentSelect = (tournament: TournamentModel) => {
		const model = Object.assign({}, this.state.model);
		const emptySeason = SeasonModel.builder().build();
		const emptyStage = StageModel.builder().build();
		const emptyRound = RoundModel.builder().build();
		const modelNew = MatchListStageRoundModel.builder(model)
			.withTournament(tournament)
			.withSeason(emptySeason)
			.withStage(emptyStage)
			.withRound(emptyRound)
			.build();
		this.setState({ model: modelNew });
	};

	private onSeasonSelect = (season: SeasonModel) => {
		const model = Object.assign({}, this.state.model);
		const emptyStage = StageModel.builder().build();
		const emptyRound = RoundModel.builder().build();
		const modelNew = MatchListStageRoundModel.builder(model).withSeason(season).withStage(emptyStage).withRound(emptyRound).build();
		this.setState({ model: modelNew });
	};

	private onStageSelect = (stage: StageModel) => {
		const model = Object.assign({}, this.state.model);
		const emptyRound = null;
		const modelNew = MatchListStageRoundModel.builder(model).withStage(stage).withRound(emptyRound).build();
		this.setState({ model: modelNew });
	};

	private onRoundSelect = (round: RoundModel) => {
		const model = Object.assign({}, this.state.model);
		const roundNew = RoundModel.builder(round)
			.withRound(round && round.round ? round.round : '')
			.build();
		const modelNew = MatchListStageRoundModel.builder(model).withRound(roundNew).build();
		this.setState({ model: modelNew });
	};

	private onConfigChange = (config: MatchListConfigModel) => {
		const model = Object.assign({}, this.state.model);
		const modelNew = MatchListStageRoundModel.builder(model).withConfig(config).build();
		this.setState({ model: modelNew });
	};

	render() {
		const { model } = this.state;
		const { t } = this.props;

		return (
			<div>
				<Row>
					<Col>
						<FormGroup>
							<TournamentSelectContainer
								withLabel
								isBlocky
								isValid={true}
								selectedTournament={model.tournament}
								onTournamentSelect={this.onTournamentSelect}
								t={t}
							/>
						</FormGroup>
					</Col>
					<Col>
						<FormGroup>
							<SeasonSelectContainer
								withLabel
								isValid={true}
								selectedSeason={model.season}
								tournamentId={model.tournament && model.tournament.id ? model.tournament.id : ''}
								onSeasonSelect={this.onSeasonSelect}
								t={t}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					<Col>
						<FormGroup>
							<StageSelectContainer
								withLabel
								selectedStage={model.stage}
								seasonId={model.season && model.season.id ? model.season.id : ''}
								onStageSelect={this.onStageSelect}
								t={t}
							/>
						</FormGroup>
					</Col>
					<Col>
						<FormGroup>
							<RoundSelectContainer
								withLabel
								selectedRound={model.round}
								isClearable={true}
								stageId={model.stage ? model.stage.id : ''}
								onRoundSelect={this.onRoundSelect}
								t={t}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					<Col>
						<MatchListConfig model={model.config} onChange={this.onConfigChange} t={t} />
					</Col>
				</Row>
			</div>
		);
	}
}
