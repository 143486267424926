import * as React from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import Image from '../../../../../../../models/image/Image';
import HttpService from '../../../../../../../services/rest/HttpService';
import ModelMapper from '../../../../../../../models/ModelMapper';
import { assetsPlaceholder } from '../../../../../../../constants/assetsPlaceholder'; // This only needs to be imported once in your app
import './image.style.scss';

export type Properties = {
	imageId: string;
	projectId: string;
	hideIfNoImage?: boolean;
	imageClassName?: string;
};

export type State = {
	imageLoading: boolean;
	enlargeImage: boolean;
	image: Image;
};

export default class LiveBlogImageContainer extends React.Component<Properties, State> {
	constructor(props: Properties) {
		super(props);
		this.state = {
			imageLoading: false,
			image: {} as Image,
			enlargeImage: false,
		};
	}

	componentDidMount(): void {
		const { imageId, projectId } = this.props;

		if (imageId) {
			this.getImage(imageId, projectId);
		}
	}

	componentDidUpdate(prevProps: Readonly<Properties>, prevState: Readonly<State>, snapshot?: any): void {
		const { imageId, projectId } = this.props;

		if (imageId !== prevProps.imageId) {
			this.getImage(imageId, projectId);
		}
	}

	getImage(imageId: string, projectId: string) {
		this.toggleImage({} as Image, true);
		const headers = { Project: projectId };
		HttpService.get(`images/${imageId}`, null, headers)
			.then((response: any) => {
				let image = ModelMapper.remapImageFromResponse(response.data.data);
				this.toggleImage(image, false);
			})
			.catch((error: any) => this.toggleImage({} as Image, false));
	}

	toggleImage(image: Image, loading: boolean): void {
		this.setState({
			...this.state,
			image,
			imageLoading: loading,
		});
	}

	extractImageSrc(image: Image): string {
		let thumbnail = assetsPlaceholder.imagePlaceholder;

		if (image && image.urls && image.urls.uploaded && image.urls.uploaded.thumbnail) {
			thumbnail = image.urls.uploaded.thumbnail;
		}

		return thumbnail;
	}

	toggleImageEnlarge(enlarge: boolean) {
		this.setState({
			...this.state,
			enlargeImage: enlarge,
		});
	}

	isImageAvailable(image: Image): boolean {
		return (
			Object.entries(image).length > 0 &&
			Object.entries(image.urls).length > 0 &&
			Object.entries(image.urls.uploaded).length > 0 &&
			image.urls.uploaded.original.length > 0
		);
	}

	displayImage(image: Image, imageLoading: boolean) {
		const { enlargeImage } = this.state;

		if (imageLoading) {
			return <div id={'async-image-loader-placeholder'} className={'loading-overlay bg-light'}></div>;
		} else {
			return (
				<>
					<div
						className='live-blog-image'
						onClick={() => {
							if (this.isImageAvailable(image)) {
								this.toggleImageEnlarge(true);
							}
						}}
					>
						<img src={this.extractImageSrc(image)} className='live-blog-main-image' alt={image.description} />
					</div>
					{enlargeImage && (
						<Lightbox
							mainSrc={image.urls.uploaded.gallery}
							onCloseRequest={() => {
								this.toggleImageEnlarge(false);
							}}
						/>
					)}
				</>
			);
		}
	}

	render() {
		const { image, imageLoading } = this.state;

		return <>{this.displayImage(image, imageLoading)}</>;
	}
}
