import React, { FunctionComponent } from 'react';
import { DATA_QA } from '../../helpers/data-qa-attributes';
import SearchDateDesign from '../../../../Partials/design-components/search-date/search-date';
import { useTranslation } from 'react-i18next';

type Props = {
	selectConfigureDate: (date: Date | null) => void;
	date: Date;
};

const ConfigureDateSection: FunctionComponent<Props> = ({ selectConfigureDate, date }) => {
	const { t } = useTranslation();

	return (
		<div id={DATA_QA.CONFIGURE_DATE_WRAPPER}>
			<div>
				<h3> {t('configure_for_date')} </h3>
				<span> {t('configure_for_date_sub')} </span>
			</div>
			<SearchDateDesign invokeFunc={selectConfigureDate} fieldId='search-config-date' date={date} />
		</div>
	);
};

export default ConfigureDateSection;
