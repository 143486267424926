import * as React from 'react';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import * as helpers from '../react-select-helpers/react-select.helpers';
import HttpService from '../../../../services/rest/HttpService';
import { customOption } from '../../Blocky/partials/shared/custom-select-option';
import { Label, FormGroup } from 'reactstrap';
import { AppState } from '../../../../store/store';

type Props = {
	t: any;
	onChange: (selection: any) => void;
	value: any;
	url: string;
	type: string;
	isMulti: boolean;
	isRequired: boolean;
	preselectValue: boolean;
	dataRemapper: (data: any) => any;
};

const CustomFootballSelect: React.FunctionComponent<Props> = ({
	t,
	onChange,
	value,
	url,
	type,
	isMulti,
	isRequired,
	dataRemapper,
	preselectValue,
}) => {
	const [options, setOptions] = React.useState([]);

	const onCustomFootballSelect = (selection: any) => onChange(helpers.optionToItem(selection));
	const language = useSelector((state: AppState) => state.project.currentProject.language);

	React.useEffect(() => {
		HttpService.instanceFootball(language)
			.get(url)
			.then((response: any) => {
				const options: any = helpers.itemsToOptions(dataRemapper(response.data), isMulti, t, type);
				preselectValue && !value && onCustomFootballSelect(options.filter((option: any) => option.value === '4')[0]);
				setOptions(options);
			})
			.catch((error: unknown) => console.error(error));
	}, [t]);

	const inputValue = isMulti ? helpers.itemsToOptions(value, isMulti, t, type) : helpers.itemToOption(value, isMulti, t, type);

	return (
		<FormGroup>
			<Label htmlFor={`cms-select-${type}`}>{t(type)}</Label>
			{isRequired && <span className='text-danger'>*</span>}
			<Select
				className={`${!value && isRequired ? 'border-danger border rounded score-select' : ''}`}
				isMulti={isMulti}
				inputId={`cms-select-${type}`}
				value={inputValue}
				options={options}
				onChange={onCustomFootballSelect}
				placeholder={t(`select_${type}`)}
				formatOptionLabel={customOption}
				noOptionsMessage={(inputValue) => inputValue && t('no_options')}
			/>
		</FormGroup>
	);
};

export default CustomFootballSelect;
