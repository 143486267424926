import { VenueJson } from './venue.json';
import VenueBuilder from './venue.builder';

export default class VenueModel {
	readonly id: string;
	readonly name: string;

	private constructor(id: string, name: string) {
		this.id = id;
		this.name = name;
	}

	toJson(): VenueJson {
		return {
			id: this.id,
			name: this.name,
		};
	}

	static fromJson(json: VenueJson): VenueModel {
		return new VenueModel(json.id, json.name);
	}

	static builder(venue?: VenueModel): VenueBuilder {
		return new VenueBuilder(venue);
	}
}
