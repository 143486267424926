import { LineupPlayerJson } from './lineup-player.json';
import LineupPlayerBuilder from './lineup-player.builder';
import PlayerManualDataModel from '../../../../../models/player-manual-data/player-manual-data.model';

export default class LineupPlayerModel {
	readonly positionX: number | null;
	readonly positionY: number | null;
	readonly player: PlayerManualDataModel;
	readonly shirtNumber: number;
	readonly typeId: string | null;

	private constructor(
		positionX: number | null,
		positionY: number | null,
		player: PlayerManualDataModel,
		shirtNumber: number,
		typeId: string | null,
	) {
		this.positionX = positionX;
		this.positionY = positionY;
		this.player = player;
		this.shirtNumber = shirtNumber;
		this.typeId = typeId;
	}

	toJSON(): LineupPlayerJson {
		return {
			positionX: this.positionX,
			positionY: this.positionY,
			player: this.player,
			shirtNumber: this.shirtNumber,
			typeId: this.typeId,
		};
	}

	static fromJSON(json: LineupPlayerJson): LineupPlayerModel {
		return new LineupPlayerModel(json.positionX, json.positionY, json.player, json.shirtNumber, json.typeId);
	}

	static builder(player?: LineupPlayerModel): LineupPlayerBuilder {
		return new LineupPlayerBuilder(player);
	}
}
