import React from 'react';
import { Col, FormGroup, Row, Input, Label } from 'reactstrap';
import { connect } from 'react-redux';
import PlayerSelect from '../../../../partials/player-select/player-select.component';
import PlayerSeasonsSelect from '../../../../partials/player-seasons/player-seasons-select.component';
import OddsWidgetEdit from '../../odds/components/odds-widget-edit.component';
import PlayerH2hModel from '../models/player-h2h.model';
import OddsWidgetModel from '../../odds/models/odds-widget.model';
import TeamPlayerModel from '../../../../partials/team-players-select/models/team-player.model';
import { stripDataOptions } from '../helpers/player-h2h-edit.helper';
import { blockWrapperService, blockManagementService } from '../../../../subcomponents/blocky.component';
import { Properties, State } from './properties/player-h2h-widget-edit.properties';
import BlockValidation from '../../../../helpers/block-validation.helper';
import { featuresService } from '../../../../../../../App';
import { FeatureTypes } from '../../../../../../../services/feature-service/features.enum';
import ThreeLetterCodeTypeSelect from '../../../../partials/three-letter-code-type-select/three-letter-code-type-select';

class PlayerH2HWidgetEdit extends React.Component<Properties, State> {
	constructor(props: any) {
		super(props);
		this.state = {
			playerH2HModel:
				props.block && props.block.data.preview && props.block.data.preview.playerH2H
					? PlayerH2hModel.builder(props.block.data.preview.playerH2H).build()
					: PlayerH2hModel.builder().withDisplayOdds(false).build(),
			oddsModel:
				props.block && props.block.data.preview && props.block.data.preview.odds
					? OddsWidgetModel.builder(props.block.data.preview.odds).build()
					: OddsWidgetModel.builder().withBookmakers([]).withCanSelectMarkets(false).withDisplayHeader(false).build(),
			isValid: true,
		};
	}

	componentDidMount(): void {
		blockWrapperService.registerOnSaveFunc({ blockId: this.props.block.id, func: this.onSave });
	}

	onSave = (index: number) => {
		const data = { playerH2HModel: this.state.playerH2HModel, oddsModel: this.state.oddsModel };
		const validation = new BlockValidation();
		const isValid = validation.validate(this.props.block, data);
		this.setState({ ...this.state, isValid });
		const bookmakerSelectionEnabled = featuresService.areFeaturesEnabled([FeatureTypes.ALLOW_BOOKMAKER_SELECTION]);
		const odds = OddsWidgetModel.builder(this.state.oddsModel)
			.withBookmakers(bookmakerSelectionEnabled ? this.state.oddsModel.bookmakers : [])
			.build();

		if (isValid) {
			const block = Object.assign({}, this.props.block);
			block.data.preview = { playerH2H: this.state.playerH2HModel, odds };

			blockManagementService.blockUpdate(block, index);
		} else {
			return isValid;
		}
	};

	componentWillUnmount(): void {
		blockWrapperService.unregisterOnSaveFunc(this.props.block.id);
	}

	onOddsWidgetChange = (oddsModel: OddsWidgetModel) => this.setState({ ...this.state, oddsModel });

	updatePlayerH2hModelState = (model: PlayerH2hModel) => this.setState({ ...this.state, playerH2HModel: model });

	updatePlayer1State = (player: TeamPlayerModel) => {
		this.updatePlayerH2hModelState(PlayerH2hModel.builder(this.state.playerH2HModel).withPlayerOne(player).build());
	};

	updatePlayer2State = (player: TeamPlayerModel) => {
		this.updatePlayerH2hModelState(PlayerH2hModel.builder(this.state.playerH2HModel).withPlayerTwo(player).build());
	};

	updatePlayer1SeasonsState = (seasons: any[]) => {
		this.updatePlayerH2hModelState(PlayerH2hModel.builder(this.state.playerH2HModel).withPlayerOneSeason(seasons).build());
	};

	updatePlayer2SeasonsState = (seasons: any[]) => {
		this.updatePlayerH2hModelState(PlayerH2hModel.builder(this.state.playerH2HModel).withPlayerTwoSeason(seasons).build());
	};

	onDisplayOddsChange = (e: any) => {
		this.updatePlayerH2hModelState(PlayerH2hModel.builder(this.state.playerH2HModel).withDisplayOdds(e.target.checked).build());
	};

	onThreeLetterCodeTypeChange = (value: any) => {
		this.updatePlayerH2hModelState(PlayerH2hModel.builder(this.state.playerH2HModel).withThreeLetterCodeType(value).build());
	};

	render() {
		const { playerH2HModel } = this.state;

		return (
			<>
				<Row>
					<Col>
						<PlayerSelect
							inputId='player-h2h-player-one-select'
							t={this.props.t}
							value={playerH2HModel.player1}
							withLabel
							labelText={`${this.props.t('player')} 1`}
							onSelect={this.updatePlayer1State}
							isValid={this.state.isValid}
						/>
					</Col>
				</Row>
				{playerH2HModel.player1 && playerH2HModel.player1.id && playerH2HModel.player1.id.toString().length > 0 && (
					<Row>
						<Col>
							<FormGroup>
								<label>{this.props.t('tournament_seasons')}</label>
								<PlayerSeasonsSelect
									t={this.props.t}
									value={playerH2HModel.player1Seasons}
									playerId={playerH2HModel.player1.id}
									onSelect={(selections: any[]) => this.updatePlayer1SeasonsState(stripDataOptions(selections))}
									currentProject={this.props.currentProject}
									isValid={this.state.isValid}
								/>
							</FormGroup>
						</Col>
					</Row>
				)}
				<hr />
				<Row>
					<Col>
						<FormGroup>
							<PlayerSelect
								inputId='player-h2h-player-two-select'
								t={this.props.t}
								value={playerH2HModel.player2}
								withLabel
								labelText={`${this.props.t('player')} 2`}
								onSelect={this.updatePlayer2State}
								isValid={this.state.isValid}
							/>
						</FormGroup>
					</Col>
				</Row>
				{playerH2HModel.player2 && playerH2HModel.player2.id && playerH2HModel.player2.id.toString().length > 0 && (
					<Row>
						<Col>
							<FormGroup>
								<label>{this.props.t('tournament_seasons')}</label>
								<PlayerSeasonsSelect
									t={this.props.t}
									value={playerH2HModel.player2Seasons}
									playerId={playerH2HModel.player2.id}
									onSelect={(selections: any[]) => this.updatePlayer2SeasonsState(stripDataOptions(selections))}
									currentProject={this.props.currentProject}
									isValid={this.state.isValid}
								/>
							</FormGroup>
						</Col>
					</Row>
				)}

				<ThreeLetterCodeTypeSelect
					t={this.props.t}
					isClearable={true}
					onThreeLetterCodeTypeChange={this.onThreeLetterCodeTypeChange}
					selectedThreeLetterCodeType={playerH2HModel.threeLetterCodeType}
				/>

				<Row className='mb-2'>
					<Col>
						<FormGroup key='cablockn-select-markets' check className='radio'>
							<Input
								className='form-check-input'
								type='checkbox'
								id={`display-odds-markets-checkbox-${this.props.block.id}`}
								name='radios'
								onChange={this.onDisplayOddsChange}
								checked={playerH2HModel ? playerH2HModel.displayOdds : false}
							/>
							<Label check className='form-check-label' for={`display-odds-markets-checkbox-${this.props.block.id}`}>
								{this.props.t('display_odds')}
							</Label>
						</FormGroup>
					</Col>
				</Row>
				{playerH2HModel && playerH2HModel.displayOdds && (
					<OddsWidgetEdit
						block={this.props.block}
						isStandAlone={false}
						useTeamSelect
						useMatchSelect
						t={this.props.t}
						onOddsWidgetChange={this.onOddsWidgetChange}
						autoSelectFirstMatch
						displayOddsLabel
						displayHeader={false}
						isValid={this.state.isValid}
					/>
				)}
			</>
		);
	}
}

function mapStateToProps(state: any) {
	return {
		currentProject: state.project.currentProject,
	};
}

export default connect(mapStateToProps)(PlayerH2HWidgetEdit) as React.ComponentType<any>;
