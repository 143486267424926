import React from 'react';
import {
	FIXTURES_BLOCK,
	MATCH_BLOCK,
	MATCH_LIST_STAGE_ROUND,
	ODDS_BLOCK,
	PLAYER_CAREER_BLOCK,
	PLAYER_H2H_BLOCK,
	PLAYER_PROFILE_BLOCK,
	SQUAD_TEAM_PLAYERS_BLOCK,
	STANDINGS_BLOCK,
	TEAM_COMPARISON_BLOCK,
	TEAM_FORM_BLOCK,
	TEAM_PROFILE_BLOCK,
	TOP_SCORERS_BLOCK,
	MOST_DECORATED_PLAYERS_BLOCK,
	SQUAD_STATISTICS_BLOCK,
} from '../../../Partials/Blocky/constants/block-list.constants';
import { Row, Col } from 'reactstrap';
import Button from 'reactstrap/lib/Button';

type Properties = {
	t: any;
	onSelect: (data: any) => void;
};
type State = {};

export default class WidgetButtonList extends React.Component<Properties, State> {
	private onSelect = (data: any) => {
		const { onSelect } = this.props;
		onSelect(JSON.parse(JSON.stringify(data)));
	};

	render() {
		const { t } = this.props;
		const widgetCardDeck1 = [ODDS_BLOCK, TEAM_PROFILE_BLOCK, SQUAD_TEAM_PLAYERS_BLOCK, PLAYER_PROFILE_BLOCK, MATCH_BLOCK];
		const widgetCardDeck2 = [TOP_SCORERS_BLOCK, STANDINGS_BLOCK, PLAYER_H2H_BLOCK, TEAM_COMPARISON_BLOCK, MATCH_LIST_STAGE_ROUND];
		const widgetCardDeck3 = [TEAM_FORM_BLOCK, PLAYER_CAREER_BLOCK, MOST_DECORATED_PLAYERS_BLOCK, SQUAD_STATISTICS_BLOCK];
		return (
			<div className='widget-list-container'>
				<Row className='mb-2'>
					<Col>
						<div className='card-deck'>
							{widgetCardDeck1.map((item) => {
								return (
									<div
										className='card mr-1 mb-2 cursor-pointer'
										key={`key-widget-button-${item.name}`}
										onClick={() => this.onSelect(JSON.parse(JSON.stringify(item.default_data)))}
									>
										<div
											style={{
												width: '100%',
												height: '150px',
												backgroundImage: `url('${item.preview}')`,
												backgroundPosition: 'center center',
												backgroundRepeat: 'no-repeat',
												backgroundSize: 'contain',
											}}
										></div>
										<hr></hr>
										<div className='card-body'>
											<h5 className='card-title'>{t(item.name)}</h5>
											<p className='card-text'>{t(item.description)}</p>
										</div>
										<div className='card-footer'>
											<Button color='primary' onClick={() => this.onSelect(JSON.parse(JSON.stringify(item.default_data)))}>
												{t('widget_config_btn')}
											</Button>
										</div>
									</div>
								);
							})}
						</div>
					</Col>
				</Row>
				<Row>
					<Col>
						<div className='card-deck'>
							{widgetCardDeck2.map((item) => {
								return (
									<div
										className='card mr-1 mb-2 cursor-pointer'
										key={`key-widget-button-${item.name}`}
										onClick={() => this.onSelect(JSON.parse(JSON.stringify(item.default_data)))}
									>
										<div
											style={{
												width: '100%',
												height: '150px',
												backgroundImage: `url('${item.preview}')`,
												backgroundPosition: 'center center',
												backgroundRepeat: 'no-repeat',
												backgroundSize: 'contain',
											}}
										></div>
										<hr></hr>
										<div className='card-body'>
											<h5 className='card-title'>{t(item.name)}</h5>
											<p className='card-text'>{t(item.description)}</p>
										</div>
										<div className='card-footer'>
											<Button color='primary' onClick={() => this.onSelect(JSON.parse(JSON.stringify(item.default_data)))}>
												{t('widget_config_btn')}
											</Button>
										</div>
									</div>
								);
							})}
						</div>
					</Col>
				</Row>
				<Row>
					<Col>
						<div className='card-deck'>
							{widgetCardDeck3.map((item) => {
								return (
									<div
										className='card mr-1 mb-2 cursor-pointer'
										key={`key-widget-button-${item.name}`}
										onClick={() => this.onSelect(JSON.parse(JSON.stringify(item.default_data)))}
									>
										<div
											style={{
												width: '100%',
												height: '150px',
												backgroundImage: `url('${item.preview}')`,
												backgroundPosition: 'center center',
												backgroundRepeat: 'no-repeat',
												backgroundSize: 'contain',
											}}
										></div>
										<hr></hr>
										<div className='card-body'>
											<h5 className='card-title'>{t(item.name)}</h5>
											<p className='card-text'>{t(item.description)}</p>
										</div>
										<div className='card-footer'>
											<Button color='primary' onClick={() => this.onSelect(JSON.parse(JSON.stringify(item.default_data)))}>
												{t('widget_config_btn')}
											</Button>
										</div>
									</div>
								);
							})}
						</div>
					</Col>
				</Row>
			</div>
		);
	}
}
