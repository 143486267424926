import { PlayerProfileWidgetJson } from './player-profile-widget.json';
import PlayerProfileWidgetBuilder from './player-profile-widget.builder';
import TeamModel from '../../../../partials/team-select/models/team.model';
import PlayerModel from '../../../../partials/player-select/models/player.model';
import TournamentModel from './tournament-model/tournament.model';

export default class PlayerProfileWidgetModel {
	readonly player: PlayerModel;
	readonly team: TeamModel;
	readonly teamForOdds: TeamModel;
	readonly displayStatistics: boolean;
	readonly statisticType: string;
	readonly displayOdds: boolean;
	readonly tournament: TournamentModel;
	readonly threeLetterCodeType: string = '';

	private constructor(
		player: PlayerModel,
		team: TeamModel,
		teamForOdds: TeamModel,
		displayStatistics: boolean,
		statisticType: string,
		displayOdds: boolean,
		tournament: TournamentModel,
		threeLetterCodeType: string,
	) {
		this.player = player;
		this.team = team;
		this.teamForOdds = teamForOdds;
		this.displayStatistics = displayStatistics;
		this.statisticType = statisticType;
		this.displayOdds = displayOdds;
		this.tournament = tournament;
		this.threeLetterCodeType = threeLetterCodeType;
	}

	toJson(): PlayerProfileWidgetJson {
		return {
			player: this.player,
			team: this.team,
			teamForOdds: this.teamForOdds,
			displayStatistics: this.displayStatistics,
			statisticType: this.statisticType,
			displayOdds: this.displayOdds,
			tournament: this.tournament,
			threeLetterCodeType: this.threeLetterCodeType,
		};
	}

	static fromJson(json: PlayerProfileWidgetJson): PlayerProfileWidgetModel {
		return new PlayerProfileWidgetModel(
			json.player,
			json.team,
			json.teamForOdds,
			json.displayStatistics,
			json.statisticType,
			json.displayOdds,
			json.tournament,
			json.threeLetterCodeType,
		);
	}

	static builder(playerProfile?: PlayerProfileWidgetModel): PlayerProfileWidgetBuilder {
		return new PlayerProfileWidgetBuilder(playerProfile);
	}
}
