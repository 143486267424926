export const instatStatisticsOptions = (t: any) => {
	return [
		{ value: 'shortStatsPasses', label: t('short_stats_passes') },
		{ value: 'shorStatsChallenges', label: t('short_stats_challenges') },
		{ value: 'shortStatsShots', label: t('short_stats_shots') },
		{ value: 'shortStatsAll', label: t('short_stats_all') },
		{ value: 'longStatsPasses', label: t('long_stats_passes') },
		{ value: 'longStatsChallenges', label: t('long_stats_challenges') },
		{ value: 'longStatsShots', label: t('long_stats_shots') },
		{ value: 'longStatsAll', label: t('long_stats_all') },
	];
};

export const contentToOption = (content: any) => {
	if (content) {
		return {
			value: content.value,
			label: content.label,
		};
	}

	return {};
};
