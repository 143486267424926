import * as React from 'react';
import { FormGroup, Label, Col, Input } from 'reactstrap';

type YearFoundedProps = {
	t: any;
	onChange: any;
	value: string;
};

const YearFounded: React.FunctionComponent<YearFoundedProps> = ({ onChange, t, value }) => {
	return (
		<Col xs='6'>
			<FormGroup>
				<Label htmlFor='manual-data-entry-year-founded'>{t('year_founded')}</Label>
				<Input
					type='text'
					id='team-manual-data-entry-year-founded'
					value={value ? value : ''}
					onChange={onChange}
					className='form-control'
					placeholder={t('year_founded')}
				/>
			</FormGroup>
		</Col>
	);
};

export default YearFounded;
