import React, { FunctionComponent } from 'react';
import { FormGroup, Label } from 'reactstrap';
import Select from 'react-select';
import { generateOptionsWithFilteredFeatures, removeOptionsForVideoContentType } from '../../helpers/external-main-media';
import { MainMediaSelectMenuOptions } from '../../constants/general';
import { ValueType } from 'react-select/src/types';
import { DATA_QA_ATTRIBUTES } from '../../constants/data-qa.attributes';
import { extractMainContentTypeBasedOnUrl } from '../../../../../../global-helpers/global.helpers';
import { ContentTypes } from '../../../../../../constants/content-types';

type Properties = {
	t: any;
	onChange: (data: ValueType<MainMediaSelectMenuOptions>) => void;
};

const ExternalMainMediaSelect: FunctionComponent<Properties> = ({ t, onChange }) => {
	let options = generateOptionsWithFilteredFeatures(t);
	options = extractMainContentTypeBasedOnUrl() === ContentTypes.VIDEO ? removeOptionsForVideoContentType(options) : options;

	return (
		<FormGroup>
			<Label htmlFor={DATA_QA_ATTRIBUTES.EXTERNAL_MAIN_MEDIA_SELECT}>{t('choose_main_media')}</Label>
			<Select
				options={options}
				onChange={onChange}
				placeholder={t('select')}
				noOptionsMessage={(inputValue) => inputValue && t('no_options')}
				inputId={DATA_QA_ATTRIBUTES.EXTERNAL_MAIN_MEDIA_SELECT}
			/>
		</FormGroup>
	);
};

export default ExternalMainMediaSelect;
