import React, { ChangeEvent, FunctionComponent, useEffect, useState } from 'react';
import { IMAGE_PROCESSING_PREFIX } from '../../constants/Contstants';
import { assetsPlaceholder } from '../../../../../constants/assetsPlaceholder';
import HttpService from '../../../../../services/rest/HttpService';
import './ImageUpload.scss';
import { ImageCrop } from './ImageCrop';
import { Button, Card, CardBody, CardHeader, Col } from 'reactstrap';
import { uiService } from '../../TournamentContainer';
import TournamentModel from '../../models/Tournament/TournamentModel';
import Loader from '../../../../Partials/Shared/loader/Loader';

type Props = {
	t: any;
	tournament: TournamentModel;
	updateTournament: React.Dispatch<React.SetStateAction<TournamentModel>>;
	tournamentFetchedFlag: boolean;
};

export const ImageUpload: FunctionComponent<Props> = ({ updateTournament, t, tournament, tournamentFetchedFlag }) => {
	let imageFileInputRef = {} as HTMLInputElement | null;
	const [imageUrl, setImageUrl] = useState('');
	const [imageLoading, setImageLoading] = useState(true);

	//Used only for displaying the current image
	useEffect(() => {
		if (tournamentFetchedFlag) {
			setImageLoading(true);
			if (tournament.urlLogo) {
				HttpService.testImageUrl(tournament.urlLogo)
					.then(() => {
						setImageUrl(tournament.urlLogo);
					})
					.catch((e: any) => {
						if (e.response.status === 404) {
							setImageUrl(`${IMAGE_PROCESSING_PREFIX}${tournament.urlLogo}`);
						}
					})
					.finally(() => setImageLoading(false));
			} else {
				setImageUrl(assetsPlaceholder.tournament);
				setImageLoading(false);
			}
		}
	}, [tournament.urlLogo, tournamentFetchedFlag]);

	const onUploadTournamentImage = () => {
		uiService.onUploadTournamentImage(
			tournament,
			imageUrl,
			updateTournament,
			setImageLoading,
			t('error_occurred'),
			t('image_upload_success'),
		);
	};

	const onImageChange = (evt: ChangeEvent<HTMLInputElement>) => {
		uiService.onImageSelect(evt, tournament, updateTournament, setImageLoading, t('error_occurred'));
	};

	return (
		<Col col='4' lg='4' md='12' sm='12' xs='12'>
			<div className='animated fadeIn'>
				<Card className='tournament-edit-info'>
					<CardHeader className='card-header d-flex align-items-center'>
						<b className='mr-auto mb-0'>{t('logo')}</b>
					</CardHeader>
					<CardBody>
						<div className='d-flex image-upload-container-v2 flex-column flex-sm-column flex-md-row align-items-center'>
							<div className='position-relative image-preview-container mr-md-3 mr-0 mb-sm-2 mb-2 mb-md-0'>
								{imageLoading ? (
									<Loader className='loading-overlay-h-auto loading-overlay-h-fill loading-overlay-w-fill' />
								) : (
									<img className='mr-2' src={imageUrl} />
								)}
							</div>
							<div className='d-flex flex-column justify-content-center'>
								<div className='input-group mb-2'>
									<input type='text' className='form-control' placeholder={imageUrl} disabled={true} />
									<button className='text-white btn btn-info btn-secondary' onClick={() => (imageFileInputRef ? imageFileInputRef.click() : '')}>
										{' '}
										{t('browse')}
									</button>
									<input
										type='file'
										ref={(ref) => (imageFileInputRef = ref)}
										style={{ display: 'none' }}
										//@ts-ignore
										onClick={(event) => {
											event.target.value = null;
										}}
										onChange={onImageChange}
									/>
								</div>
								<div className='mb-2'>
									<ImageCrop src={imageUrl} t={t} tournamentId={tournament.id} setDisplayedImageUrl={setImageUrl} />
								</div>
								<Button color='primary' onClick={() => onUploadTournamentImage()}>
									{' '}
									{t('save_assets')}{' '}
								</Button>
							</div>
						</div>
					</CardBody>
				</Card>
			</div>
		</Col>
	);
};
