import React from 'react';
import { DebounceInput } from 'react-debounce-input';
import { useTranslation } from 'react-i18next';
import AuthorSocialModel from '../../../../models/v2/author/social.model';
import { Alert, FormGroup, Label } from 'reactstrap';

const validator = require('validator');

type AuthorSocialProps = {
	onChange: (slug: string, text: string) => void;
	social: AuthorSocialModel;
};

export const AuthorSocial: React.FunctionComponent<AuthorSocialProps> = ({ onChange, social }) => {
	const { t } = useTranslation();
	const idEl = `author-social-${social.slug}`;

	return (
		<FormGroup>
			<Label htmlFor={idEl}> {social.name} </Label>
			<DebounceInput
				id={idEl}
				className='form-control-lg form-control mb-3'
				onChange={(event) => onChange(social.slug, event.target.value || '')}
				value={social && social.value ? social.value : ''}
				placeholder={t('author_social_name', { socialName: social.name })}
			/>
			{social && social.value && social.value.length > 0 && !validator.isURL(social.value) && (
				<Alert color='warning'>{t('not_valid_url')}</Alert>
			)}
		</FormGroup>
	);
};
