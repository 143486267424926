import * as React from 'react';
import Related from '../../../../../models/related/Related';
import { Badge, Button, Label } from 'reactstrap';
import { SortableHandle } from 'react-sortable-hoc';
import moment from 'moment';
import { printMinutesIfMatchInProgress, toggleGoals } from '../../../Sidebar/tags/subcomponents/matches/helpers/match.helper';
import { Link } from 'react-router-dom';
import { ContentTypes } from '../../../../../constants/content-types';
import { EMBED_ICON } from '../../../Sidebar/Media/subcomponents/media-content/helpers/MainContentMediaContainer';
import { generateFinishedEventNameResultLabelHtml, generateLiveEventNameResultLabelHtml } from '../helpers/general';

type SortableItemProps = {
	onDelete: (related: Related) => any;
	related: Related;
	t: any;
	itemIndex: number;
};
const DragHandle = SortableHandle(() => (
	<span className={'cursor-draggable'}>
		<i className={'fa fa-bars'}></i>
	</span>
));

const ContentSortableItem: React.FunctionComponent<SortableItemProps> = (props) => {
	const deleteItem = () => props.onDelete(related);

	const contentTypeLogo = {
		article: () => <i className={'fa fa-file-text-o'}></i>,
		video: () => <i className={'fa fa-play'}></i>,
		gallery: () => <i className={'fa fa-camera'}></i>,
	};

	const { related, t, itemIndex } = props;
	return (
		<li
			key={`related-content-item-${related.type}-${related.data.id}`}
			className='list-group-item d-flex justify-content-between align-items-center'
		>
			<div className='d-flex justify-content-between align-items-center'>
				<div className='mr-3'>
					<DragHandle />
				</div>
				<div>
					{related.type !== ContentTypes.MATCH && !related.data.status_type && related.data && related.data.status === 'active' ? (
						<i className={`option-logo mr-2 fa-lg fa fa-check-circle text-success`} />
					) : (
						related.type !== ContentTypes.MATCH &&
						!related.data.status_type && <i className={`option-logo mr-2 fa-lg fa fa-times-circle text-danger`} />
					)}
					{related.data &&
						related.data.main_media &&
						related.data.main_media.map((media: any) => {
							return related.type.toLocaleLowerCase() === ContentTypes.VIDEO && media.resource_type === 'embed' && EMBED_ICON;
						})}
					{related.type !== ContentTypes.MATCH && !related.data.status_type && contentTypeLogo[related.type]()}{' '}
					{related && related.type !== ContentTypes.MATCH && !related.data.status_type && (
						<>
							<Link
								to={`/smp/${related.type.toLocaleLowerCase() === ContentTypes.GALLERY ? 'gallerie' : related.type.toLowerCase()}s/edit/${
									related.data.id
								}`}
								className='text-dark ml-1'
							>
								{related.data.title}
							</Link>
							<div>
								<Badge className='mt-1 text-dark search-options-published-date'>
									{moment(related.data.published_at).format('DD MMM YYYY, HH:mm')}
								</Badge>
							</div>
						</>
					)}
					{related && related.provider === 'football-api' && related.data.finished_at && (
						<Label className='form-check-label' check htmlFor={`${related.data.id}`}>
							{`${moment(related.data.finished_at).format('DD MMM YYYY')} - `}
							<span>{related.data.home_team.name}</span>
							{` ${related.data.goal_home}:${related.data.goal_away} `}
							<span>{related.data.away_team.name}</span>
						</Label>
					)}
					{related && related.provider === 'football-api' && !related.data.finished_at && (
						<Label className='form-check-label' check htmlFor={`${related.data.id}`}>
							{`${moment(related.data.start_time).format('DD MMM YYYY')} - `}
							<span>{related.data.home_team.name}</span>
							{toggleGoals(related.data)}
							<span>{related.data.away_team.name}</span> {printMinutesIfMatchInProgress(related.data)}
						</Label>
					)}
					{related &&
						related.provider === 'sports-search-api' &&
						related.data.status_type === 'FINISHED' &&
						generateFinishedEventNameResultLabelHtml(related.data)}
					{related &&
						related.provider === 'sports-search-api' &&
						related.data.status_type !== 'FINISHED' &&
						generateLiveEventNameResultLabelHtml(related.data)}
				</div>
			</div>
			<Button id={`remove-related-${related.type}-${itemIndex}`} onClick={deleteItem} className='btn btn-sm btn-danger mx-1'>
				<i className='fa fa-trash'></i> {t('remove')}
			</Button>
		</li>
	);
};

export default ContentSortableItem;
