import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { blService, uiService } from './TournamentContainer';
import { Button, Label } from 'reactstrap';
import './styles/TournamentListingComponent.scss';
import { TournamentSearch } from './subcomponents/TournamentSelect/tournament-search.component';
import { CountrySelect } from './subcomponents/CountrySelect/country-select.component';
import { assetsPlaceholder } from '../../../constants/assetsPlaceholder';
import TournamentModel from '../../../models/v2/Tournament/Entity/TournamentModel';
import Country from '../../../models/v2/Country/Country.model';

type Props = {
	t: any;
	history: any;
	tournamentOrder: string;
};

export const TournamentListing: FunctionComponent<Props> = ({ t, history, tournamentOrder }) => {
	const hideOnMobileStyle = 'd-none d-md-block d-lg-block d-xl-block';
	const [defaultTournaments, setDefaultTournaments] = useState([] as TournamentModel[]);
	const [searchedTournaments, setSearchedTournaments] = useState([] as TournamentModel[]);
	const [keepSearchFlag, setKeepSearchFlag] = useState(false);
	const [searchName, setSearchName] = useState('');
	const [countryFilter, setCountryFilter] = useState([] as Country[]);

	// We need state ref for 'componentwillunmount in functional component'
	const keepSearchFlagRef = useRef<boolean>(keepSearchFlag);
	const searchNameRef = useRef<string>(searchName);
	const countryFilterRef = useRef<Country[]>(countryFilter);
	useEffect(() => {
		keepSearchFlagRef.current = keepSearchFlag;
		countryFilterRef.current = countryFilter;
		searchNameRef.current = searchName;
	}); // update ref based on the state values

	useEffect(() => {
		// Set correct search filters
		const keptSearchValue = uiService.getSearchValueFromLocalStorage();
		keptSearchValue && setKeepSearchFlag(true);
		setSearchName(keptSearchValue);
		const keptCountryFilter = uiService.getCountryFilterFromLocalStorage();
		keptCountryFilter.length > 0 && setKeepSearchFlag(true);
		setCountryFilter(keptCountryFilter);

		// Update the tournaments list state
		blService.getTournamentList(tournamentOrder).then((tournamentList) => {
			setDefaultTournaments(tournamentList);
			setSearchedTournaments(
				keptSearchValue || keptCountryFilter.length > 0
					? blService.filterTournaments(tournamentList, keptSearchValue, keptCountryFilter)
					: tournamentList,
			);
		});

		const saveLatestFilter = () =>
			keepSearchFlagRef.current
				? uiService.setFiltersInLocalStorage(searchNameRef.current, countryFilterRef.current)
				: uiService.removeFiltersFromLocalStorage();
		window.addEventListener('beforeunload', saveLatestFilter); // if the user refresh the page

		return () => {
			saveLatestFilter();
			window.removeEventListener('beforeunload', saveLatestFilter);
		}; // componentwillunmount in functional component.
	}, []);

	// Update the searched name text state
	useEffect(() => {
		const filteredTournaments = blService.filterTournaments(defaultTournaments, searchName, countryFilter);
		setSearchedTournaments(filteredTournaments);
	}, [searchName.length, countryFilter.length]);

	document.title = t('tournaments');

	const redirectToEdit = (tournamentId: string) => {
		history.push(`/smp/tournaments/edit/${tournamentId}`);
	};

	return (
		<div className='card animated fadeIn'>
			<div className='card-header'>
				<div className='d-flex align-items-center'>
					<span className='mr-auto mb-0'>{t('tournaments')}</span>
				</div>
			</div>
			<div className='card-body'>
				<div className='px-1 py-1'>
					<div className='row d-flex flex-row align-items-center justify-content-end mb-2'>
						<div className='d-flex col-12 col-md-4 mb-3 mb-md-0 d-flex countries-select-container'>
							<CountrySelect
								t={t}
								onChange={setCountryFilter}
								value={countryFilter}
								options={blService.extractCountriesFromTournaments(defaultTournaments)}
							/>
						</div>
						<div className='col-12 col-md-5 text-right d-flex flex-column'>
							<TournamentSearch t={t} onChange={setSearchName} value={searchName} />
						</div>
					</div>
					<div className='row'>
						<div className='col-12 text-right form-check mb-2'>
							<input
								checked={keepSearchFlag}
								className='mr-1 form-check-input'
								id='keep-search'
								name='keep-search'
								type='checkbox'
								onChange={() => setKeepSearchFlag(!keepSearchFlag)}
							/>
							<Label className='cursor-pointer form-check-label' for='keep-search'>
								{t('keep_search')}
							</Label>
						</div>
					</div>
					<div className='row font-weight-bold py-2 px-2 border-top border-bottom'>
						<div className='col-6 col-md-4'>{t('name')}</div>
						<div className={`col-md-3 ${hideOnMobileStyle}`}>{t('country')}</div>
						<div className={`col-md-2 ${hideOnMobileStyle}`}>{t('type')}</div>
						<div className='col-6 col-md-3 text-right'>{t('actions')}</div>
					</div>
					{searchedTournaments.length > 0 ? (
						searchedTournaments.map((tournament: TournamentModel) => {
							return (
								<div key={tournament.id} className='row d-flex flex-row align-items-center py-2 px-2 border-bottom row-style'>
									<div className='col-6 col-md-4 tournament-list-name-col cursor-pointer' onClick={() => redirectToEdit(tournament.id)}>
										<div
											className='tournament-list-logo'
											style={{ backgroundImage: tournament.urlLogo ? `url("${tournament.urlLogo}")` : `url("${assetsPlaceholder.stage}")` }}
										></div>
										{tournament.name}
									</div>
									<div className={`col-md-3 ${hideOnMobileStyle}`}>{tournament.country.name}</div>
									<div className={`col-md-2 ${hideOnMobileStyle}`}>{tournament.type}</div>
									<div className='col-6 col-md-3 text-right'>
										<Button id={`match-edit-btn-${tournament.id}`} color='warning' onClick={() => redirectToEdit(tournament.id)}>
											<i className='fa fa-pencil p-1' />
											{t('edit')}
										</Button>
									</div>
								</div>
							);
						})
					) : (
						<h6 className='d-flex justify-content-center mt-4'>{t('no_data_found')}</h6>
					)}
				</div>
			</div>
		</div>
	);
};
