import { QueryFunction, useQuery } from 'react-query';
import { store } from '../../store/store';

export enum ReactQueryStatuses {
	LOADING = 'loading',
	SUCCESS = 'success',
	IDLE = 'idle',
	ERROR = 'error',
}

const cacheTimeMs = 900000; // set default cache time to 15min

export const ReactQuerySettings = {
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
			cacheTime: cacheTimeMs,
		},
	},
};

// Custom hook wrapper for react-query
export const useReactQuery = (uniqueKeys: string[], requestPromise: QueryFunction<any>) => {
	const appendUniqueUserData = store.getState().profile.profile.id || ''; // always append user id as unique react-query identificator
	const { data, status, refetch } = useQuery([...uniqueKeys, appendUniqueUserData], requestPromise);
	return { data, status, refetch };
};

export const isReactQueryFetchedSuccessfully = (status: string) => {
	return status === ReactQueryStatuses.SUCCESS;
};

export const isReactQueryLoading = (status: string) => {
	return status === ReactQueryStatuses.LOADING;
};

export const isReactQueryError = (status: string) => {
	return status === ReactQueryStatuses.ERROR;
};
