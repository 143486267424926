import i18next from 'i18next';
import { featuresService } from '../App';
import { FeatureTypes } from '../services/feature-service/features.enum';

export const displayListsNestedElements = () => {
	const result = [
		{
			name: i18next.t('editorial'),
			customTitle: 'editorial',
			url: '/smp/lists',
			icon: 'fa fa-list-ul',
		},
	];

	if (featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.SCHEDULED_LISTS)) {
		result.push({
			name: i18next.t('scheduled'),
			customTitle: 'scheduled_list',
			url: '/smp/scheduled-lists',
			icon: 'fa fa-list-ul',
		});
	}

	result.push({
		name: i18next.t('tags_and_sports'),
		customTitle: 'tags_and_sports',
		url: '/smp/tags-and-sports-connections-lists',
		icon: 'fa fa-list-ul',
	});

	return result;
};
