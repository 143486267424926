import React from 'react';
import { Row, Col, Label, Button, FormGroup } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Properties, State } from './properties/feed-create.properties';
import { Title } from '../../Partials/Fields/title/TitleComponent';
import { compose } from 'redux';
import Project from '../../../models/project/Project';
import BlockableButtonWrapper from '../../Partials/BaseComponents/BlockableButton/BlockableButtonWrapper';
import { AppState } from '../../../store/store';
import { connect } from '../../../store/store.connect';
import {
	returnObjectForFeedEntityCreate,
	FEED_ENTITY_CREATE_SUCCESS,
	returnObjectForTemplatesListingRequest,
	returnObjectForSourceEntityRequest,
	FEED_ENTITY_CREATE,
	FEED_ENTITY_CREATE_FAILED,
} from '../../../store/action-creators/feeds-action-creator';
import Feed from '../../../models/feed/feed';
import { Active } from '../../Partials/Fields/active/ActiveComponent';
import SourceListContainer from './feed-source-list/source-list-container';
import Source from '../../../models/feed/sources/source';
import { CopyFromTemplate } from '../../Partials/Fields/copy-from-template/copy-from-template';
import TemplateSelectContainer from './feed-source-list/subcomponents/template-select-container/template-select';
import HoToUseFeedManagement from './how-to-use/how-to.component';

@connect<Properties, AppState>(
	(state) => ({
		feed: state.feed.feed,
		project: state.project.currentProject,
		templates: state.feed.templates,
		profileId: state.profile.profile.id,
	}),
	(dispatch) => ({
		dispatch: (action: any) => dispatch(action),
		postFeed: (feed: Feed, project: Project, profileId: string) => dispatch(returnObjectForFeedEntityCreate(feed, project, profileId)),
		getTemplates: (page: string, project: Project, profileId: string) =>
			dispatch(returnObjectForTemplatesListingRequest(page, project, profileId)),
		getSource: (location: string, project: Project, profileId: string) =>
			dispatch(returnObjectForSourceEntityRequest(location, project, profileId)),
	}),
)
class FeedCreate extends React.Component<Properties, State> {
	constructor(props: Properties) {
		super(props);
		this.state = {
			feed: Feed.builder().withName('').withActive(true).build(),
			template: Feed.builder().build(),
			copyFromTemplate: false,
		};
	}

	componentDidMount(): void {
		this.initPageTitle();
		this.initFeedCreateSuccessListener();
		this.props.getTemplates('all', this.props.currentProject, this.props.profileId);
	}

	componentDidUpdate(prevProps: Readonly<Properties>, prevState: Readonly<State>): void {
		if (prevProps.feed !== this.props.feed) {
			this.setState({ feed: this.props.feed });
		}
	}

	initFeedCreateSuccessListener() {
		window.addEventListener(FEED_ENTITY_CREATE_SUCCESS, (data: any) => {
			this.props.history.push(`/smp/news-tracker/edit/${data.detail}`);
		});
	}

	initPageTitle() {
		document.title = this.props.t('feed_create');
	}

	onTitleChange = (title: string) => {
		const feed = Feed.builder(this.state.feed).withName(title).build();
		this.setState({ feed });
	};

	copyFromTemplate = (copyFromTemplate: boolean) => {
		this.setState({
			...this.state,
			copyFromTemplate,
			feed: !copyFromTemplate ? Feed.builder().build() : this.props.templates[0],
		});
	};

	onTemplateSelect = (template: any) => {
		this.setState({
			...this.state,
			feed: template.feed,
		});
	};

	onSortEnd = (sources: Source[]) => {
		this.setState({ feed: Feed.builder(this.state.feed).withSource(sources).build() });
	};

	onActiveChange = (active: boolean) => {
		this.setState({ feed: Feed.builder(this.state.feed).withActive(active).build() });
	};

	onSaveSource = (source: Source, index: number) => {
		let sources = this.state.feed.sources;
		sources[index] = Source.builder()
			.withId(source.id)
			.withName(source.name)
			.withType(source.type)
			.withSubtype(source.subtype)
			.withLocation(source.location)
			.withThumbnail(source.thumbnail)
			.build();

		this.setState({
			...this.state,
			feed: Feed.builder(this.state.feed).withSource(sources).build(),
		});
	};

	onFeedSourceRemove = (itemIndex: number) => {
		let sources = this.state.feed.sources;
		sources.splice(itemIndex, 1);
		const feed = Feed.builder(this.state.feed).withSource(sources).build();
		this.setState({ feed });
	};

	addNewSource = () => {
		let sources = this.state.feed.sources;
		sources = sources ? sources.concat(Source.builder().build()) : [Source.builder().build()];
		this.setState({
			feed: Feed.builder(this.state.feed).withSource(sources).build(),
		});
	};

	onSubmit = () => {
		this.props.postFeed(this.state.feed, this.props.project, this.props.profileId);
	};

	render() {
		const { t, templates } = this.props;

		return (
			<div className='animated fadeIn'>
				<Row>
					<Col col='12' lg='12' md='12' sm='12' xs='12'>
						<div className='card'>
							<div className='card-header'>
								<div className={'d-flex align-items-center'}>
									<span className={'mr-auto mb-0'}>{t('new_feed')}</span>
									<BlockableButtonWrapper
										blockOnActions={[FEED_ENTITY_CREATE]}
										releaseOnActions={[FEED_ENTITY_CREATE_SUCCESS, FEED_ENTITY_CREATE_FAILED]}
									>
										<Button size='sm' color='primary' id='feed-create-save-top' className={'ml-auto'} onClick={this.onSubmit}>
											<i className='fa fa-floppy-o'></i> {t('save_feed')}
										</Button>
									</BlockableButtonWrapper>
								</div>
							</div>
							<div className='card-body'>
								<div>
									<Row>
										<Col xs='12' style={{ maxWidth: '700px' }}>
											<Label htmlFor='title'>{t('title')}</Label>
											<Title t={t} onChange={this.onTitleChange} value={this.state.feed ? this.state.feed.name : ''} />
										</Col>
									</Row>
									{this.props.templates && (
										<Row className='mb-1'>
											<Col xs='12'>
												<CopyFromTemplate
													t={t}
													onChange={this.copyFromTemplate}
													value={this.state.copyFromTemplate}
													isChecked={this.state.copyFromTemplate}
												/>
											</Col>
										</Row>
									)}

									{this.state.copyFromTemplate && (
										<Row>
											<Col xs='12'>
												<FormGroup key={'formgroup-select-type-key'} className='w-25 mr-2'>
													<Label htmlFor='title'>{t('templates')}</Label>
													<TemplateSelectContainer
														selectedTemplate={this.state.feed.name}
														defaultValue={templates[0].name}
														templates={templates}
														onTemplateSelect={this.onTemplateSelect}
														t={t}
													/>
												</FormGroup>
											</Col>
										</Row>
									)}

									<Row>
										<Col xs='12'>
											<Active t={t} onChange={this.onActiveChange} value={this.state.feed.active} isChecked={this.state.feed.active} />
										</Col>
									</Row>

									<Row>
										<Col xs='12'>
											<SourceListContainer
												sources={this.state.feed.sources}
												onDeleteContent={this.onFeedSourceRemove}
												onSortEnd={this.onSortEnd}
												onSaveSource={this.onSaveSource}
												t={t}
												getSource={this.props.getSource}
												project={this.props.project}
												profileId={this.props.profileId}
											/>
										</Col>
									</Row>

									<Row className={'mt-2'}>
										<Col xs='12'>
											<Button onClick={this.addNewSource} className={'btn btn-outline-secondary'} title={'Add source'} color={'white'}>
												<i className='fa fa-plus'></i>
											</Button>
										</Col>
									</Row>

									<Row className={'mt-4'}>
										<Col xs='12'>
											<BlockableButtonWrapper
												blockOnActions={[FEED_ENTITY_CREATE]}
												releaseOnActions={[FEED_ENTITY_CREATE_SUCCESS, FEED_ENTITY_CREATE_FAILED]}
											>
												<Button color={'primary'} id='feed-create-save-bottom' className={'text-uppercase font-weight-bold'} onClick={this.onSubmit}>
													<i className='fa fa-floppy-o'></i> {t('save_feed')}
												</Button>
											</BlockableButtonWrapper>
										</Col>
									</Row>
								</div>
							</div>
						</div>
					</Col>
				</Row>
				<Row>
					<Col>
						<HoToUseFeedManagement t={t} />
					</Col>
				</Row>
			</div>
		);
	}
}

export default compose(withTranslation(), withRouter)(FeedCreate) as React.ComponentType;
