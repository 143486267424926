import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { DATA_QA_ATTRIBUTES } from '../../../../../../Partials/Sidebar/media-refactored/constants/data-qa.attributes';
import Project from '../../../../../../../models/project/Project';
import MainMediaModel from '../../../../../../../models/v2/main-media/main-media.model';
import { featuresService } from '../../../../../../../App';
import { FeatureTypes } from '../../../../../../../services/feature-service/features.enum';
import ConditionalRenderWrapper from '../../../../../../Partials/BaseComponents/ConditionalRenderWraper/ConditionalRenderWrapper';
import ImageEditModal from '../../../../../../Resources/Images/Subcomponents/image-edit-modal/ImageEditModal';
import { extractMainImageObjectFromArray } from '../../../../../../Partials/Sidebar/media-refactored/helpers/general';
import '../styles/style.scss';

type Properties = {
	project: Project;
	mainMedia: MainMediaModel[];
	t: any;
};

export const LiveBlogMainImageEdit: React.FunctionComponent<Properties> = ({ t, project, mainMedia }) => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const mainMediaImage: MainMediaModel = extractMainImageObjectFromArray(mainMedia) || new MainMediaModel();

	return (
		<>
			<ConditionalRenderWrapper expectedPermissions={['write_images']}>
				<Button
					id={DATA_QA_ATTRIBUTES.EDIT_IMAGE}
					disabled={!mainMediaImage}
					className='edit-image-button'
					block
					onClick={() => setIsModalOpen(true)}
				>
					<i className='fa fa-pencil' /> {t('edit_image')}
				</Button>
			</ConditionalRenderWrapper>
			{mainMediaImage && (
				<ImageEditModal
					onClose={(imageUpdated: boolean, watermarkAdded: boolean, watermarkRemoved: boolean) => setIsModalOpen(false)}
					imageId={mainMediaImage.resourceId}
					currentProject={project}
					open={isModalOpen}
					watermarkEnabled={featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.WATERMARK)}
					t={t}
					isPlayingSurface={false}
					isImagoImage={false}
				/>
			)}
		</>
	);
};
