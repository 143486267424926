import React from 'react';
import { Properties } from '../properties/sources-lists.properties';
import { Label } from 'reactstrap';
import * as ReactSortableHOC from 'react-sortable-hoc';
import SortableSource from './sortable-source';
import Source from '../../../../../models/feed/sources/source';
import './sortable-source.scss';

const SortableItem = ReactSortableHOC.SortableElement(SortableSource);

const SourcesLists: React.FunctionComponent<Properties> = (props) => {
	return (
		<ul className='list-group'>
			{props.sources && props.sources.length > 0 && (
				<Label htmlFor='sources' className={'mt-2 mb-2'}>
					{props.t('sources')}
				</Label>
			)}
			{props.sources &&
				props.sources.map((source: Source, index: number) => (
					<SortableItem
						key={source.location ? `${source.location}-${source.id}` : index}
						source={source}
						itemIndex={index}
						index={index}
						sources={props.sources}
						onDeleteContent={props.onDeleteContent}
						onSaveSource={props.onSaveSource}
						t={props.t}
						getSource={props.getSource}
						project={props.project}
						profileId={props.profileId}
					/>
				))}
		</ul>
	);
};

export default SourcesLists;
