export enum DATA_QA_ATTRIBUTES {
	LIST_CONTAINER = 'sidebar-list-content-wrapper',
	LIST_SELECT = 'sidebar-list-content-list-select',
	LIST_PRESENT_IN_CONTAINER = 'sidebar-list-content-present-in-wrapper',
	LIST_SHOW_IMAGES = 'sidebar-list-content-show-images-wrapper',
	LIST_ITEMS_CONTAINER = 'sidebar-list-content-items-wrapper',
	LIST_ADD_ITEM = 'sidebar-list-content-add-item-button',
	LIST_ADD_ITEM_TOP = 'sidebar-list-content-add-item-top-button',
	LIST_SAVE = 'sidebar-list-save-button',
}
