import _ from 'lodash';
import React, { FC, useContext, useEffect, useMemo, useRef, useState } from 'react';
import DatePicker from 'react-flatpickr';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { toast } from 'react-toastify';
import { Button, Input } from 'reactstrap';
import AuthorModel from '../../../../../../models/v2/author/author.model';
import HttpService from '../../../../../../services/rest/HttpService';
import { OddClientBookmakersFullModel } from '../../../../../Partials/Blocky/partials/available-bookmakers-select/models/odd-client-bookmakers-full.model';
import { customOption } from '../../../../../Partials/Shared/custom-select-options/custom-select-option';
import { selectedAuthorsToOptions } from '../../../helpers/authors-select.helper';
import { LiveBlogTypes } from '../../../helpers/utility.helper';
import { LiveBlogPostModel } from '../../../models/live-blog-post.model';
import LiveBlogModel from '../../../models/live-blog.model';
import { LiveBlogEditorialAdminContext } from '../../main-components/live-blog-editorial-admin.component';

import '../../../style/new-post-config.scss';

const TagsSelect: FC<{
	value: any[];
	setValue: React.Dispatch<React.SetStateAction<any[] | null>>;
	tournamentIds: number[];
}> = ({ value, setValue, tournamentIds }) => {
	const [t] = useTranslation();
	const [tags, setTags] = useState<any[] | null>(value);
	const liveBlogConfiguration = useContext(LiveBlogEditorialAdminContext);

	const modifyTags = (tagsSelection: any) => {
		if (tagsSelection && tagsSelection.length > 0) {
			const selectedTags = tagsSelection.map((tag: any) => tag.data);

			setTags(selectedTags);
			setValue(selectedTags);
		} else {
			setTags(null);
			setValue(null);
		}
	};

	const tagsResponseToOptions = (tags: any[] | null) => {
		if (tags && tags.length > 0) {
			return tags.map((tag) => ({
				value: tag.id,
				label: tag.name || tag.title,
				data: tag,
				logo: tag.url_thumb || tag.url_logo,
				type: tag.entity_type,
			}));
		}

		return null;
	};

	const loadOnType = (input: string, callback: Function) => {
		if (liveBlogConfiguration && input.length > 2) {
			HttpService.getLiveBlogPostTags(liveBlogConfiguration.language, input, tournamentIds)
				.then(({ data }: { data: any[] }) => {
					const loadedTagsAsOptions = tagsResponseToOptions(
						data.map((dataPoint) => (dataPoint.id ? { ...dataPoint, id: dataPoint.id.toString() } : dataPoint)),
					);

					callback(loadedTagsAsOptions);
				})
				.catch((e: unknown) => {
					toast.error(t('error_fetching_data'));
					console.error(e);
				});
		}
	};

	return (
		<AsyncSelect
			className='select'
			isMulti={true}
			isClearable={true}
			placeholder={t('select')}
			noOptionsMessage={(inputValue) => inputValue && t('no_options')}
			loadOptions={_.debounce(loadOnType, 300)}
			onChange={modifyTags}
			value={tagsResponseToOptions(tags)}
			formatOptionLabel={customOption}
		/>
	);
};

const SponsorsSelect: FC<{
	value: OddClientBookmakersFullModel[];
	setValue: React.Dispatch<React.SetStateAction<OddClientBookmakersFullModel[]>>;
}> = ({ value, setValue }) => {
	const [t] = useTranslation();
	const [sponsorsList, setSponsorsList] = useState<OddClientBookmakersFullModel[]>([]);

	useEffect(() => {
		HttpService.getAllBookmakers()
			.then(({ data }: { data: OddClientBookmakersFullModel[] }) => {
				setSponsorsList(data);
			})
			.catch((e: unknown) => e);
	}, []);

	const sponsorToOption = (sponsor: OddClientBookmakersFullModel) => ({
		value: sponsor.id,
		label: sponsor.name,
		data: sponsor,
		logo: sponsor.url_logo,
		type: 'bookmaker',
	});

	return (
		<Select
			className='select'
			value={value && value.length > 0 ? value.map(sponsorToOption) : []}
			options={sponsorsList.map(sponsorToOption)}
			placeholder={t('select')}
			noOptionsMessage={(inputValue) => inputValue && t('no_options')}
			isMulti
			formatOptionLabel={(option) =>
				customOption(option, undefined, {
					borderRadius: 3.5,
					maxWidth: 'unset',
					width: '75px',
					height: '15px',
					backgroundPosition: 'center',
					backgroundSize: 'contain',
				})
			}
			onChange={(selections: any) => setValue(selections ? selections.map(({ data }: { data: OddClientBookmakersFullModel }) => data) : [])}
		/>
	);
};

const SportEventsSelect: FC<{
	value: ConfigData['sport_event'];
	setValue: React.Dispatch<React.SetStateAction<string[]>>;
}> = ({ value, setValue }) => {
	const [t] = useTranslation();
	const liveBlogConfiguration = useContext(LiveBlogEditorialAdminContext);

	if (!liveBlogConfiguration || !liveBlogConfiguration.sport_events) {
		return null;
	}

	const sportEventToOption = (sportEvent: LiveBlogModel['sport_events'][number] | null) =>
		sportEvent && {
			value: sportEvent.id,
			label: sportEvent.home_team.name + ' - ' + sportEvent.away_team.name,
			data: sportEvent,
		};

	return (
		<Select
			className='select'
			value={value ? sportEventToOption(liveBlogConfiguration.sport_events.find(({ id }) => value === id) || null) : null}
			options={liveBlogConfiguration.sport_events.map(sportEventToOption)}
			placeholder={t('select')}
			noOptionsMessage={(inputValue) => inputValue && t('no_options')}
			onChange={(selection: any) => setValue(selection ? selection.value : null)}
		/>
	);
};

const ToggleEditor: FC<{
	label: string;
	options?: any[];
	setValue: React.Dispatch<React.SetStateAction<any>>;
	type: 'author' | 'minute' | 'sportEvents' | 'sportTags' | 'sponsors';
	value: any;
}> = ({ label, options = [], setValue, value, type }) => {
	const [t] = useTranslation();
	const [inputValue, setInputValue] = useState<string>(value || '');

	useEffect(() => {
		setValue(inputValue || null);
	}, [inputValue, setValue]);

	useEffect(() => {
		if (value === null) {
			setInputValue('');
		}
	}, [value]);

	return (
		<div className='toggle-editor'>
			<i
				className={`fa fa-2x fa-toggle-${value !== null ? 'on' : 'off'}`}
				onClick={() => {
					value === null ? setValue(inputValue) : setValue(null);
				}}
			/>
			{label}
			{value !== null &&
				{
					minute: <Input type='number' value={inputValue} onChange={(event) => setInputValue(event.target.value)} />,
					author: (
						<Select
							className='select'
							value={selectedAuthorsToOptions([value])[0]}
							options={selectedAuthorsToOptions(options)}
							onChange={(selections: any) => setValue({ id: selections.data.id, name: selections.data.name })}
							isClearable={false}
							formatOptionLabel={customOption}
							placeholder={t('select')}
							noOptionsMessage={(inputValue) => inputValue && t('no_options')}
						/>
					),
					sportEvents: <SportEventsSelect value={value} setValue={setValue} />,
					sportTags: <TagsSelect value={value} setValue={setValue} tournamentIds={options} />,
					sponsors: <SponsorsSelect value={value} setValue={setValue} />,
				}[type]}
		</div>
	);
};

export type ConfigData = Pick<
	LiveBlogPostModel,
	'display_timestamp' | 'minute' | 'injury_minute' | 'author' | 'sport_event' | 'sport_tags' | 'sponsors'
>;

export interface NewPostConfigProps {
	initialData?: ConfigData;
	onDataChange: (data: ConfigData) => void;
	autoTime?: boolean;
	isLiveNewsType: boolean;
}

export const getNewLiveBlogPostInitialConfig: () => ConfigData = () => ({
	display_timestamp: new Date().toISOString(),
	minute: null,
	injury_minute: null,
	author: null,
	sport_event: null,
	sport_tags: null,
	sponsors: null,
});

const NewPostConfig: FC<NewPostConfigProps> = ({
	initialData = getNewLiveBlogPostInitialConfig(),
	onDataChange,
	autoTime = true,
	isLiveNewsType,
}) => {
	const [t] = useTranslation();
	const [displayTime, setDisplayTime] = useState<Date>(new Date(initialData.display_timestamp));
	const [tentativeDisplayTime, setTentativeDisplayTime] = useState<Date>(displayTime);
	const [minute, setMinute] = useState<ConfigData['minute']>(initialData.minute);
	const [injuryMinute, setInjuryMinute] = useState<ConfigData['injury_minute']>(initialData.injury_minute);
	const [author, setAuthor] = useState<ConfigData['author']>(initialData.author);
	const [sportEvent, setSportEvent] = useState<ConfigData['sport_event']>(initialData.sport_event);
	const [sportTags, setSportTags] = useState<ConfigData['sport_tags']>(initialData.sport_tags);
	const [sponsors, setSponsors] = useState<ConfigData['sponsors']>(initialData.sponsors);
	const [timeEditorDetails, setTimeEditorDetails] = useState<{
		auto: boolean;
		editMode: boolean;
	}>({
		auto: autoTime,
		editMode: false,
	});
	const timeSyncInterval = useRef<number>();
	const liveBlogConfiguration = useContext(LiveBlogEditorialAdminContext);

	useEffect(() => {
		setDisplayTime(new Date(initialData.display_timestamp));
		setMinute(initialData.minute);
		setInjuryMinute(initialData.injury_minute);
		setAuthor(initialData.author);
		setSportEvent(initialData.sport_event);
		setSportTags(initialData.sport_tags);
		setSponsors(initialData.sponsors);
	}, [initialData]);

	useEffect(() => {
		timeSyncInterval.current = window.setInterval(() => setDisplayTime(new Date()), 30000);

		return () => {
			if (timeSyncInterval.current !== undefined) {
				clearInterval(timeSyncInterval.current);
			}
		};
	}, []);

	useEffect(() => {
		if (!timeEditorDetails.auto) {
			clearInterval(timeSyncInterval.current);
		}
	}, [timeEditorDetails.auto]);

	useEffect(() => {
		if (!timeEditorDetails.editMode) {
			return;
		}

		const clickEventListener = (event: MouseEvent) => {
			if (event.target instanceof Element && event.target.closest('.new-post-config-time, .flatpickr-calendar')) {
				return;
			}

			setTimeEditorDetails({
				auto: timeEditorDetails.auto,
				editMode: false,
			});
			setTentativeDisplayTime(displayTime);
		};

		document.body.addEventListener('click', clickEventListener);

		return () => document.body.removeEventListener('click', clickEventListener);
	}, [timeEditorDetails.editMode]);

	useEffect(() => {
		onDataChange({
			display_timestamp: displayTime.toISOString(),
			minute,
			injury_minute: injuryMinute,
			author,
			sport_event: sportEvent,
			sport_tags: sportTags,
			sponsors,
		});
	}, [onDataChange, displayTime, minute, injuryMinute, author, sportEvent, sportTags, sponsors]);

	useEffect(() => {
		if (!minute) {
			setInjuryMinute(null);
		}
	}, [minute]);

	const authors = useMemo(
		() => (liveBlogConfiguration ? liveBlogConfiguration.collaborators.map(({ author }) => author as AuthorModel) : []),
		[liveBlogConfiguration],
	);

	const tournamentIds = useMemo(() => {
		try {
			return liveBlogConfiguration && liveBlogConfiguration.type === 'SPORT_EVENT' && liveBlogConfiguration.sport_events
				? liveBlogConfiguration.sport_events
						.map(({ tournament_season_stage }) => tournament_season_stage.tournament_id as number)
						.filter((tournamentId, index, array) => array.indexOf(tournamentId) === index)
				: [];
		} catch (e) {
			return [];
		}
	}, [liveBlogConfiguration]);

	return (
		<div className='new-post-config-container'>
			<div className='new-post-config-time'>
				{!timeEditorDetails.editMode && (
					<div className='new-post-config-time-preview'>
						{displayTime.toLocaleString()}
						<Button
							color='primary'
							outline
							onClick={() =>
								setTimeEditorDetails({
									auto: false,
									editMode: true,
								})
							}
						>
							{t('change')}
						</Button>
					</div>
				)}
				{timeEditorDetails.editMode && (
					<div className='new-post-config-time-edit'>
						<DatePicker
							options={{ enableTime: true }}
							value={displayTime}
							onChange={(dates) => setTentativeDisplayTime(dates[0] || new Date())}
						/>
						<Button
							color='primary'
							outline
							onClick={() => {
								setTimeEditorDetails({
									auto: false,
									editMode: false,
								});
								setDisplayTime(tentativeDisplayTime);
							}}
						>
							{t('apply')}
						</Button>
					</div>
				)}
			</div>
			{!isLiveNewsType && (
				<>
					<ToggleEditor label={t('add_minute')} setValue={setMinute} type='minute' value={minute} />
					{minute && <ToggleEditor label={t('add_injury_minute')} setValue={setInjuryMinute} type='minute' value={injuryMinute} />}
				</>
			)}

			<ToggleEditor
				label={t('author_name')}
				options={authors}
				setValue={setAuthor}
				type='author'
				value={authors.find(({ id }) => author && author.id === id) || author}
			/>
			{liveBlogConfiguration && liveBlogConfiguration.sport_events && liveBlogConfiguration.sport_events.length > 1 && (
				<ToggleEditor label={t('event')} setValue={setSportEvent} type='sportEvents' value={sportEvent} />
			)}
			<ToggleEditor label={t('sport_tag')} options={tournamentIds} setValue={setSportTags} type='sportTags' value={sportTags} />
			<ToggleEditor label={t('sponsors')} setValue={setSponsors} type='sponsors' value={sponsors} />
		</div>
	);
};

function mapStateToProps(state: any) {
	return {
		isLiveNewsType:
			state.liveBlogEditorial &&
			state.liveBlogEditorial.configuration &&
			state.liveBlogEditorial.configuration.type === LiveBlogTypes.LIVE_NEWS,
	};
}

export default connect(mapStateToProps)(NewPostConfig);
