import { featuresService } from '../../../../App';
import { FeatureTypes } from '../../../../services/feature-service/features.enum';

export function previewFormPost(body, url, relatedPost) {
	body.related = relatedPost;
	if (featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.CONTENT_PREVIEW)) {
		const config = featuresService.getFeatureConfig(FeatureTypes.CONTENT_PREVIEW);
		const headers = config ? constructHeaders(config.headers) : {};
		window.open(url, 'view');
		fetch(url, {
			method: 'POST',
			headers: {
				...headers,
			},
			body: JSON.stringify(body),
		}).catch((error) => {
			console.error('Error:', error);
		});
	} else {
		const form = createForm(body, url);
		document.body.appendChild(form);
		window.open('', 'view');
		form.submit();
	}
}

function constructHeaders(headersFromFeature) {
	const headers = {};
	if (headersFromFeature) {
		headersFromFeature.forEach((header) => {
			const key = Object.keys(header)[0];
			headers[key] = header[key];
		});
	}

	return headers;
}

function createForm(body, url) {
	let form = document.createElement('form');
	const formFieldList = createFormFields(body);

	form.setAttribute('method', 'post');
	form.setAttribute('method', 'post');
	form.setAttribute('action', url);
	form.setAttribute('target', 'view');

	formFieldList.forEach((formField) => {
		form.appendChild(formField);
	});

	return form;
}

function createFormFields(body) {
	const formFieldList = [];
	Object.keys(body).forEach((key) => {
		let hiddenField = document.createElement('input');
		hiddenField.setAttribute('type', 'hidden');
		hiddenField.setAttribute('name', key);
		hiddenField.setAttribute('value', JSON.stringify(body[key]));
		formFieldList.push(hiddenField);
	});

	return formFieldList;
}
