import React from 'react';
import { noPreviewImage } from '../../../../../../../constants/resource-constants';
import { Col, Row } from 'reactstrap';
import moment from 'moment';
import GBVisionAudioModel from '../../../../../../../models/v2/gb-vision-audio/gb-vision-audio-model';

type Properties = {
	t: any;
	audio: GBVisionAudioModel;
};

const GBVisionAudioView: React.FunctionComponent<Properties> = (props) => {
	const { audio, t } = props;

	if (audio && audio.home) {
		return (
			<>
				<Row>
					<Col className='px-4'>
						<strong>{t('type')}:</strong> {t('gbVision')}
					</Col>
				</Row>
				<Row>
					<Col className='px-4'>
						<strong>{t('event')}:</strong> {`${audio.home} ${audio.away ? '- ' + audio.away : ''}`}
						<br />
						<br />
						<strong>{t('date')}:</strong> {`${moment(`${audio.date} ${audio.time}`).format('DD MMM YYYY, HH:mm')}`}
					</Col>
				</Row>
				<Row>
					<Col className='px-4'>
						<div style={{ padding: '5px' }}>
							<hr></hr>
						</div>
					</Col>
				</Row>
			</>
		);
	}

	return (
		<div className='position-relative text-center'>
			<img alt='' src={noPreviewImage} />
		</div>
	);
};

export default GBVisionAudioView;
