import React, { FunctionComponent, useEffect, useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { CustomEntityBasic } from '../../../models/models';
import { useTranslation } from 'react-i18next';
import { DATA_QA } from '../../../helpers/data-qa.properties';

type ModalDeleteProps = {
	selectedRowForDelete: CustomEntityBasic | undefined;
	setSelectedRowForDelete: Function;
	deleteContent: (id: string) => void;
};

export const CustomEntityModalDelete: FunctionComponent<ModalDeleteProps> = ({
	selectedRowForDelete,
	setSelectedRowForDelete,
	deleteContent,
}) => {
	const [modalOpenFlag, setModalOpenFlag] = useState(false);
	const [t] = useTranslation();

	useEffect(() => {
		setModalOpenFlag(!!(selectedRowForDelete && selectedRowForDelete.id && selectedRowForDelete.id.length > 0));
	}, [selectedRowForDelete]);

	const deleteEntity = () => {
		const idForDelete = selectedRowForDelete && selectedRowForDelete.id ? selectedRowForDelete.id : '';
		if (idForDelete) {
			deleteContent(idForDelete);
			closeModal();
		}
	};

	const closeModal = () => setSelectedRowForDelete(null);

	return (
		<Modal data-qa={DATA_QA.CUSTOM_ENTITIES_DELETE_MODAL} isOpen={modalOpenFlag} className='custom-entities-delete-modal'>
			<ModalHeader
				className='border-0'
				toggle={() => {
					closeModal();
				}}
			>
				<span className='custom-entities-delete-modal-text-header'>{t('delete_entity')}</span>
			</ModalHeader>
			<ModalBody className='border-0'>
				<span className='custom-entities-delete-modal-text'>
					{`${t('custom_entity_delete_notice_1st_half')} "${selectedRowForDelete && selectedRowForDelete.name}" ${t(
						'custom_entity_delete_notice_2nd_half',
					)}`}
					<br />
					<br />
					<span className='custom-entities-delete-modal-text'>{`${t('custom_entity_delete_notice_3rd_half')}`}</span>
				</span>
			</ModalBody>
			<ModalFooter className='border-0'>
				<div className='custom-entities-delete-modal-btns'>
					<Button className='cancel-delete-btn' color='base' onClick={closeModal}>
						<span className='cancel-delete-btn-text'>{t('cancel')}</span>
					</Button>
					<Button color='danger' className='mr-auto' onClick={deleteEntity}>
						<span className='confirm-delete-btn-text'>{t('delete')}</span>
					</Button>
				</div>
			</ModalFooter>
		</Modal>
	);
};
