import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { analyticsService } from '../../../../../App';

export const CreateLiveBlogButtonComponent: FunctionComponent<any> = ({ t }) => {
	return (
		<>
			<Link
				to={'/smp/live-blogs/configuration/create'}
				id='new-live-blog'
				className='btn btn-dark btn-create-new'
				onClick={() => analyticsService.sendEvent('Click', 'List Page', 'Create live blog')}
			>
				<i className={'fa fa-plus-circle'}>&nbsp;</i>
				{t('new_live_blog')}
			</Link>
		</>
	);
};
