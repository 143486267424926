import React, { Component } from 'react';
import { Col, FormGroup, Label, Row, Input } from 'reactstrap';
import TeamSelect from '../../../../partials/team-select/team-select.component';
import TeamPlayersSelect from '../../../../partials/team-players-select/team-players-select.component';
import TeamPlayersWidgetModel from '../models/team-players-widget.model';
import { Properties, State } from './properties/team-players-widget-edit.properties';
import TeamModel from '../../../../partials/team-select/models/team.model';
import { blockManagementService, blockWrapperService } from '../../../../subcomponents/blocky.component';
import TeamPlayerModel from '../../../../partials/team-players-select/models/team-player.model';
import BlockValidation from '../../../../helpers/block-validation.helper';

export default class TeamPlayersWidgetEdit extends Component<Properties, State> {
	constructor(props: Properties) {
		super(props);
		this.state = {
			teamPlayersModel:
				props.block && props.block.data.preview && props.block.data.preview.teamPlayers
					? TeamPlayersWidgetModel.builder(props.block.data.preview.teamPlayers).build()
					: TeamPlayersWidgetModel.builder()
							.withDisplayUnknown(true)
							.withDisplayDefenders(true)
							.withDisplayForwards(true)
							.withDisplayGoalkeepers(true)
							.withDisplayMidfielders(true)
							.withTeam(TeamModel.builder().build())
							.build(),
			isValid: true,
		};
	}

	componentDidMount(): void {
		blockWrapperService.registerOnSaveFunc({ blockId: this.props.block.id, func: this.onSave });
	}

	componentWillUnmount(): void {
		blockWrapperService.unregisterOnSaveFunc(this.props.block.id);
	}

	onSave = (index: number) => {
		const validation = new BlockValidation();
		const data = { teamPlayers: this.state.teamPlayersModel };
		const isValid = validation.validate(this.props.block, data);

		this.setState({ ...this.state, isValid });

		if (isValid) {
			const block = Object.assign({}, this.props.block);
			block.data.preview = { teamPlayers: this.state.teamPlayersModel };
			blockManagementService.blockUpdate(block, index);
		} else {
			return isValid;
		}
	};

	updateWidgetModelState = (model: TeamPlayersWidgetModel) => this.setState({ ...this.state, teamPlayersModel: model });

	onTeamChange = (team: TeamModel) => {
		this.updateWidgetModelState(
			TeamPlayersWidgetModel.builder(this.state.teamPlayersModel).withTeam(team).withInjuredPlayers([]).withSuspendedPlayers([]).build(),
		);
	};

	onDisplayGoalkeepersChange = (e: any) => {
		this.updateWidgetModelState(TeamPlayersWidgetModel.builder(this.state.teamPlayersModel).withDisplayGoalkeepers(e.target.checked).build());
	};

	onDisplayDefendersChange = (e: any) => {
		this.updateWidgetModelState(TeamPlayersWidgetModel.builder(this.state.teamPlayersModel).withDisplayDefenders(e.target.checked).build());
	};

	onDisplayMidfieldersChange = (e: any) => {
		this.updateWidgetModelState(TeamPlayersWidgetModel.builder(this.state.teamPlayersModel).withDisplayMidfielders(e.target.checked).build());
	};

	onDisplayForwardsChange = (e: any) => {
		this.updateWidgetModelState(TeamPlayersWidgetModel.builder(this.state.teamPlayersModel).withDisplayForwards(e.target.checked).build());
	};

	onDisplayUnknownChange = (e: any) => {
		this.updateWidgetModelState(TeamPlayersWidgetModel.builder(this.state.teamPlayersModel).withDisplayUnknown(e.target.checked).build());
	};

	onInjuredPlayerSelect = (players: TeamPlayerModel[]) => {
		this.updateWidgetModelState(TeamPlayersWidgetModel.builder(this.state.teamPlayersModel).withInjuredPlayers(players).build());
	};

	onSuspendedPlayerSelect = (players: TeamPlayerModel[]) => {
		this.updateWidgetModelState(TeamPlayersWidgetModel.builder(this.state.teamPlayersModel).withSuspendedPlayers(players).build());
	};

	render() {
		const { teamPlayersModel, isValid } = this.state;

		return (
			<>
				<Row>
					<Col>
						<FormGroup>
							<TeamSelect
								inputId='team-players-team-select'
								isValid={isValid}
								t={this.props.t}
								isMulti={false}
								value={teamPlayersModel.team}
								onSelect={this.onTeamChange}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					<Col>
						<FormGroup>
							<TeamPlayersSelect
								label={this.props.t('select_injured_players')}
								selectedPlayers={teamPlayersModel.injuredPlayers ? teamPlayersModel.injuredPlayers : []}
								teamId={teamPlayersModel.team ? teamPlayersModel.team.id : ''}
								onPlayerSelect={this.onInjuredPlayerSelect}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					<Col>
						<FormGroup>
							<TeamPlayersSelect
								label={this.props.t('select_suspended_players')}
								selectedPlayers={teamPlayersModel.suspendedPlayers ? teamPlayersModel.suspendedPlayers : []}
								teamId={teamPlayersModel.team ? teamPlayersModel.team.id : ''}
								onPlayerSelect={this.onSuspendedPlayerSelect}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row className='mb-2'>
					<Col>
						<FormGroup key='display-goalkeepers' check className='radio'>
							<Input
								key='display-goalkeepers-input'
								className='form-check-input'
								type='checkbox'
								id={`display-goalkeepers-checkbox-${this.props.block.id}`}
								name='radios'
								onChange={this.onDisplayGoalkeepersChange}
								checked={teamPlayersModel.displayKeeper}
							/>
							<Label
								key='display-goalkeepers-label'
								check
								className='form-check-label'
								for={`display-goalkeepers-checkbox-${this.props.block.id}`}
							>
								{this.props.t('display_goalkeepers')}
							</Label>
						</FormGroup>
					</Col>
				</Row>
				<Row className='mb-2'>
					<Col>
						<FormGroup key='display-defenders' check className='radio'>
							<Input
								key='display-defenders-input'
								className='form-check-input'
								type='checkbox'
								id={`display-defenders-checkbox-${this.props.block.id}`}
								name='radios'
								onChange={this.onDisplayDefendersChange}
								checked={teamPlayersModel.displayDefender}
							/>
							<Label key='display-defenders-label' check className='form-check-label' for={`display-defenders-checkbox-${this.props.block.id}`}>
								{this.props.t('display_defenders')}
							</Label>
						</FormGroup>
					</Col>
				</Row>
				<Row className='mb-2'>
					<Col>
						<FormGroup key='display-midfielders' check className='radio'>
							<Input
								key='display-midfielders-input'
								className='form-check-input'
								type='checkbox'
								id={`display-midfielders-checkbox-${this.props.block.id}`}
								name='radios'
								onChange={this.onDisplayMidfieldersChange}
								checked={teamPlayersModel.displayMidfielder}
							/>
							<Label
								key='display-midfielders-label'
								check
								className='form-check-label'
								for={`display-midfielders-checkbox-${this.props.block.id}`}
							>
								{this.props.t('display_midfielders')}
							</Label>
						</FormGroup>
					</Col>
				</Row>
				<Row className='mb-2'>
					<Col>
						<FormGroup key='display-forwards' check className='radio'>
							<Input
								key='display-forwards-input'
								className='form-check-input'
								type='checkbox'
								id={`display-forwards-checkbox-${this.props.block.id}`}
								name='radios'
								onChange={this.onDisplayForwardsChange}
								checked={teamPlayersModel.displayForward}
							/>
							<Label key='display-forwards-label' check className='form-check-label' for={`display-forwards-checkbox-${this.props.block.id}`}>
								{this.props.t('display_forwards')}
							</Label>
						</FormGroup>
					</Col>
				</Row>
				<Row className='mb-2'>
					<Col>
						<FormGroup key='display-unknown' check className='radio'>
							<Input
								key='display-unknown-input'
								className='form-check-input'
								type='checkbox'
								id={`display-unknown-checkbox-${this.props.block.id}`}
								name='radios'
								onChange={this.onDisplayUnknownChange}
								checked={teamPlayersModel.displayUnknown}
							/>
							<Label key='display-unknown-label' check className='form-check-label' for={`display-unknown-checkbox-${this.props.block.id}`}>
								{this.props.t('display_unknown')}
							</Label>
						</FormGroup>
					</Col>
				</Row>
			</>
		);
	}
}
