import React, { FunctionComponent, useState } from 'react';
import { DATA_QA } from '../../helpers/data-qa-attributes';
import TournamentPreviewData from './tournament-data-preview';
import { chevronStyleGenerator } from '../../helpers/icons';
import CountBubble from '../../../../Partials/design-components/count-bubble/count-bubble';
import MultisportModel from '../../models/sport.model';
import { formatEventsPreviewToDndTree } from '../../helpers/drag-drop';
import { TOP_EVENTS_SPORT, getSportEventsCount } from '../../helpers/utils';
import { useTranslation } from 'react-i18next';
import { capitalize } from '../../../../../global-helpers/global.helpers';

type Props = {
	previewEvents: MultisportModel;
};

const EventsPreview: FunctionComponent<Props> = ({ previewEvents }) => {
	const { t } = useTranslation();
	const isTopEventsSection = previewEvents.sport === TOP_EVENTS_SPORT;
	const [isSectionVisible, setIsSectionVisible] = useState(true);
	const togglePreviewSection = () => setIsSectionVisible(!isSectionVisible);
	const eventsDndTree = formatEventsPreviewToDndTree(previewEvents);
	const eventsCount = getSportEventsCount(previewEvents.competitions);

	return (
		<div className={DATA_QA.EVENTS_PREVIEW_WRAPPER}>
			<div className={DATA_QA.PREVIEW_TOGGLER}>
				<h3> {isTopEventsSection ? t('top_events_preview') : t('sport_preview_dynamic', { sport: capitalize(previewEvents.sport) })}</h3>
				<CountBubble count={eventsCount} title={t('preview_events_count')} />
				<i className={`${chevronStyleGenerator(isSectionVisible)} icon-end`} onClick={togglePreviewSection} />
			</div>
			{isSectionVisible && (
				<>
					{isTopEventsSection && <span className='top-events-info'>{t('top_events')}</span>}
					<div className={DATA_QA.PREVIEW_TOURNAMENT_DATA}>
						{eventsDndTree.length > 0 && <TournamentPreviewData previewEventsTree={eventsDndTree} />}
					</div>
				</>
			)}
		</div>
	);
};

export default EventsPreview;
