import { TournamentJson } from './match-season-tournament.json';
import TournamentBuilder from './match-season-tournament.builder';
import Country from '../../../../../models/country/country.model';

export default class TournamentModel {
	readonly id: string;
	readonly name: string;
	readonly country: Country | null;
	readonly logo: string;

	private constructor(id: string, name: string, country: Country | null, logo: string) {
		this.id = id;
		this.name = name;
		this.country = country;
		this.logo = logo;
	}

	toJson(): TournamentJson {
		return {
			id: this.id,
			name: this.name,
			country: this.country,
			logo: this.logo,
		};
	}

	static fromJson(json: TournamentJson): TournamentModel {
		return new TournamentModel(json.id, json.name, json.country, json.logo);
	}

	static builder(tournament?: TournamentModel | null): TournamentBuilder {
		return new TournamentBuilder(tournament);
	}
}
