import TennisRoundModel from '../../../../../../models/v2/round/tennis-round/tennis-round.model';

export const tennisRoundsToOptions = (rounds: TennisRoundModel[]) => {
	if (rounds && rounds.length > 0) {
		return rounds.map((round) => {
			return tennisRoundToOption(round);
		});
	}
	return [] as TennisRoundModel[];
};
export const tennisRoundToOption = (round: TennisRoundModel) => {
	if (round) {
		return {
			value: round.id,
			label: round.name,
			data: round,
			type: 'round',
		};
	} else {
		return {} as TennisRoundModel;
	}
};
