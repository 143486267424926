import React, { useContext, useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { SortableTreeWithoutDndContext as SortableTree, TreeItem, getVisibleNodeCount } from 'react-sortable-tree';
import HttpService from '../../../services/rest/HttpService';
import {
	returnObjectForAllCategoryListingRequest,
	returnObjectForCategoryListingRequest,
} from '../../../store/action-creators/CategoriesActionCreators';
import { toggleLoadingState } from '../../../store/action-creators/UiActionCreator';
import { RowTitleLink } from '../../Partials/BaseComponents/ListingComponent/subcomponents/Rows/subcomponents/RowTitleLink';
import { RowEditButton } from '../../Partials/BaseComponents/ListingComponent/subcomponents/Rows/subcomponents/RowEditButton';
import { RowDeleteButton } from '../../Partials/BaseComponents/ListingComponent/subcomponents/Rows/subcomponents/RowDeleteButton';
import { ConditionalRenderContext } from '../../Partials/BaseComponents/ConditionalRenderWraper/ConditionalRenderWrapper';
import { DndProvider } from 'react-dnd';
import i18n from '../../../i18n';
import { toast } from 'react-toastify';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import 'react-sortable-tree/style.css';
import './styles/category-tree.component.scss';
import { CategoryDragAndDropModal } from './category-modal/category-drag-and-drop.modal';
import { AppState } from '../../../store/store';
import Project from '../../../models/project/Project';
import { extractParams } from './Helpers/category-tree.helper';

type Properties = {
	categoryTree: TreeItem[];
	onDelete: (id: string, title: string) => any;
	analytics: any;
	history: any;
	t: any;
};

export const CategoryTree: React.FunctionComponent<Properties> = ({ categoryTree, analytics, t, history, onDelete }) => {
	const categoryRef = useRef(null);
	const context = useContext(ConditionalRenderContext);

	const isTouchDevice = !!('ontouchstart' in window || navigator.maxTouchPoints);
	const dndBackend = isTouchDevice ? TouchBackend : HTML5Backend;
	const elementHeight = document.body.scrollHeight - 380;

	const currentPage: string = useSelector((store: AppState) => store.category.pagination.currentPage, shallowEqual);
	const adminId: string = useSelector((store: AppState) => store.profile.profile.id, shallowEqual);
	const project: Project = useSelector((store: AppState) => store.project.currentProject, shallowEqual);
	const dispatch = useDispatch();

	const [treeData, updateTreeData] = useState(categoryTree ? categoryTree : []);
	const [showModal, toggleModal] = useState(false);
	const [movedNode, updateMovedNode] = useState();
	const [categoryRowHeight, updateRowHeight] = useState(62);
	const [prevTreeState, updatePrevTreeState] = useState([] as TreeItem[]);

	const prevTreeDataRef = useRef([] as TreeItem[]);

	// set prev tree data state
	useEffect(() => {
		prevTreeDataRef.current = treeData;
	}, [treeData]);

	let prevTreeData: TreeItem[] = [];
	prevTreeData = prevTreeDataRef.current;

	React.useEffect(() => {
		updateTreeData(categoryTree);
		if (categoryRef !== null && categoryRef.current !== null) {
			//@ts-ignore
			updateRowHeight(categoryRef.current.clientHeight);
		}
	}, [categoryTree, categoryRef]);

	const updateCategory = (movedNode: any) => {
		HttpService.patch(`/categories/${movedNode.node.id}`, extractParams(movedNode), { Project: project.domain })
			.then(() => {
				dispatch(returnObjectForAllCategoryListingRequest(currentPage, project, adminId));
				dispatch(returnObjectForCategoryListingRequest(currentPage, project, adminId));
				dispatch(toggleLoadingState(true));
				toggleModal(false);
			})
			.catch((e: any) => {
				e.response.status === 403 && toast.error(i18n.t('category_permissions_error'));
			});
	};

	const toggleConfirmModal = (showModal: boolean, movedNode: any) => {
		toggleModal(showModal);
		updateMovedNode(movedNode);
	};

	const cancelDrag = (showModal: boolean) => {
		toggleModal(showModal);
		updateTreeData(prevTreeState);
		prevTreeDataRef.current = prevTreeState;
	};

	const handleChange = (treeData: TreeItem[]) => {
		updateTreeData(treeData);
		updatePrevTreeState(prevTreeData);
	};

	return (
		<>
			<DndProvider backend={dndBackend}>
				<SortableTree
					className='category-tree mb-2'
					getNodeKey={(node: any) => node.node.id}
					treeData={treeData}
					onChange={(treeData: TreeItem[]) => handleChange(treeData)}
					onMoveNode={(movedNode: any) => toggleConfirmModal(true, movedNode)}
					style={{ height: `${elementHeight}px` }}
					innerStyle={{ height: `${elementHeight}px` }}
					rowHeight={categoryRowHeight}
					generateNodeProps={(content: any) => ({
						title: (
							<div
								key={`table-row`}
								ref={categoryRef}
								id={`category-row-${content.node.id}`}
								className={`d-flex justify-content-between align-items-center category-row-container`}
							>
								<div className='align-middle category-tree-title'>
									<RowTitleLink
										contentTitle={content.node.titleInTree}
										editContentPath={`/smp/categories/edit/${content.node.id}`}
										canEditContent={context.hasWritePermission}
										analytics={analytics}
										contentId={`category-id-${content.node.id}`}
										showStatus={true}
										active={content.node.active}
										t={t}
										titleStyle='text-truncate'
									/>
								</div>
								<div className='d-flex justify-content-end flex-wrap category-tree-btns'>
									<RowEditButton
										contentId={content.node.id}
										display={context.hasWritePermission}
										editContentPath={`/smp/categories/edit/${content.node.id}`}
										t={t}
										analytics={analytics}
										history={history}
									/>
									<RowDeleteButton
										analytics={analytics}
										display={context.hasDeletePermission}
										t={t}
										onDelete={onDelete}
										contentTitle={content.node.titleInTree}
										contentId={content.node.id}
									/>
								</div>
							</div>
						),
					})}
				/>
			</DndProvider>
			<CategoryDragAndDropModal t={t} showModal={showModal} toggleModal={cancelDrag} movedNode={movedNode} updateCategory={updateCategory} />
		</>
	);
};
