import React, { useEffect, useState } from 'react';
import InstatHttpService from '../../../../../../../../services/rest/instat/InstatHttpService';
import Select from 'react-select';

type Properties = {
	value: any;
	tournamentId: string;
	onSelect: (value: any | null) => void;
};

const selectedTeamToOption = (value: any) => {
	return value
		? {
				value: value.id,
				label: value.name,
		  }
		: null;
};

const optionToModel = (option: any) => {
	return {
		id: option.id,
		name: option.label,
	};
};

const SeasonSelect: React.FunctionComponent<Properties> = (props) => {
	const [teamsOptions, setTeamsOptions] = useState([]);
	const http = new InstatHttpService();

	useEffect(() => {
		if (teamsOptions.length < 1) {
			http.getSeasonsByTournament(props.tournamentId).then((seasons) => {
				if (seasons && seasons.length > 0) {
					const seasonsReversed: any = [...seasons].reverse();
					const options = seasonsReversed.map((team: any) => {
						return {
							id: team.id,
							label: team.name,
						};
					});
					setTeamsOptions(options);
				}
			});
		}
	}, [props.tournamentId]);

	useEffect(() => {
		http.getSeasonsByTournament(props.tournamentId).then((seasons) => {
			if (seasons && seasons.length > 0) {
				const seasonsReversed: any = [...seasons].reverse();
				const options = seasonsReversed.map((team: any) => {
					return {
						id: team.id,
						label: team.name,
					};
				});
				setTeamsOptions(options);
			}
		});
	}, [props.tournamentId]);

	return (
		<Select
			value={selectedTeamToOption(props.value)}
			options={teamsOptions}
			isClearable
			onChange={(selection: any) => {
				props.onSelect(selection ? optionToModel(selection) : null);
			}}
		/>
	);
};

export default SeasonSelect;
