import React, { FunctionComponent, ChangeEvent, useEffect } from 'react';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { DATA_QA_ATTRIBUTES } from '../../constants/data-qa.attributes';
import { slugifyString } from '../../helpers/slug.helper';
import { autoGenerateReduxPropsNamingForContentModels } from '../../../../../../services/content-models-service/ContentModelService';
import { connect } from 'react-redux';

type Properties = {
	t: any;
	seoTitle: string;
	seoSlug: string;
	isGenerateFromSeoTitleChecked: boolean;
	setIsGenerateFromSeoTitleChecked: (checked: boolean) => void;
};

const SeoGenerateFromSeoTitleCheckbox: FunctionComponent<Properties> = ({
	t,
	seoTitle,
	seoSlug,
	isGenerateFromSeoTitleChecked,
	setIsGenerateFromSeoTitleChecked,
}) => {
	useEffect(() => {
		slugifyString(seoTitle) === seoSlug && setIsGenerateFromSeoTitleChecked(true);
	}, []);

	const onGenerateFromSeoTitleChange = (event: ChangeEvent<HTMLInputElement>) => {
		const checked = event.target.checked || false;
		setIsGenerateFromSeoTitleChecked(checked);
	};

	return (
		<Row>
			<Col>
				<FormGroup check className='radio'>
					<Input
						id={DATA_QA_ATTRIBUTES.COPY_FROM_SEO_TITLE}
						className='form-check-input'
						type='checkbox'
						name='radios'
						onChange={onGenerateFromSeoTitleChange}
						checked={isGenerateFromSeoTitleChecked}
					/>
					<Label check className='form-check-label' for={DATA_QA_ATTRIBUTES.COPY_FROM_SEO_TITLE}>
						{t('generate_auto_seo_title')}
					</Label>
				</FormGroup>
			</Col>
		</Row>
	);
};

function mapStateToProps(state: any) {
	const { tempPropertyNaming, nestedTempPropertyNaming } = autoGenerateReduxPropsNamingForContentModels();

	return {
		seoTitle:
			(state[tempPropertyNaming][nestedTempPropertyNaming].seo && state[tempPropertyNaming][nestedTempPropertyNaming].seo.title) || '',
		seoSlug: (state[tempPropertyNaming][nestedTempPropertyNaming].seo && state[tempPropertyNaming][nestedTempPropertyNaming].seo.slug) || '',
	};
}

export default connect(mapStateToProps)(SeoGenerateFromSeoTitleCheckbox);
