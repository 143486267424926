import { TypedJSON } from 'typedjson';
import BasketballRoundModel from '../../../../../../../models/v2/round/entity/basketball-round.model';
import RoundModel from '../../../../../../../models/v2/round/entity/round-model';

export const responseToRoundModel = (response: any) => {
	const serializer = new TypedJSON(RoundModel);
	const remmapedRound = serializer.parse(response) as RoundModel;

	return remmapedRound;
};

export const roundsToOptions = (rounds: RoundModel[] | BasketballRoundModel[]) => {
	if (rounds && rounds.length > 0) {
		return rounds.map((round) => {
			return roundToOption(round);
		});
	}

	return [];
};

export const roundToOption = (round: RoundModel | BasketballRoundModel) => {
	if (round) {
		return {
			value: round.id,
			label: round.name,
			data: round,
			type: 'round',
		};
	} else {
		return {};
	}
};
