import { SearchContentModel } from '../../../Partials/Shared/search-content-by-type/models/search-content.model';
import ListItem from '../../../../models/list/list-item/ListItem';
import { ContentTypes } from '../../../../constants/content-types';
import ModelMapper from '../../../../models/ModelMapper';
import ListItemMeta from '../../../../models/list/list-item/list-item-meta/ListItemMeta';
import List from '../../../../models/list/List';
import { responseToLiveBlogModel } from '../../../Pages/Live Blog/models/live-blog.mapper';

export const cssIconByContentType = {
	article: 'fa-file-text',
	video: 'fa-video-camera',
	gallery: 'fa-camera',
	tag: 'fa-tags',
	sports_connections: `fa-medal`,
	live_blog: 'fa-solid fa-newspaper',
};

export const searchContentModelToListItem = (model: SearchContentModel): ListItem => {
	if (model.type.toLowerCase() === ContentTypes.ARTICLE) {
		return ListItem.builder()
			.withData(ModelMapper.remapArticleFromResponse(model.data))
			.withType(model.type)
			.withMeta({} as ListItemMeta)
			.build();
	}

	if (model.type.toLowerCase() === ContentTypes.VIDEO) {
		return ListItem.builder()
			.withData(ModelMapper.remapVideoFromResponse(model.data))
			.withType(model.type)
			.withMeta({} as ListItemMeta)
			.build();
	}

	if (model.type.toLowerCase() === ContentTypes.GALLERY) {
		return ListItem.builder()
			.withData(ModelMapper.remapGalleryFromResponse(model.data))
			.withType(model.type)
			.withMeta({} as ListItemMeta)
			.build();
	}

	if (model.type.toLowerCase() === ContentTypes.TAG) {
		return ListItem.builder()
			.withData(ModelMapper.remapTagFromResponse(model.data))
			.withType(model.type)
			.withMeta({} as ListItemMeta)
			.build();
	}

	if (model.type.toLowerCase() === ContentTypes.LIVE_BLOG) {
		return ListItem.builder()
			.withData(responseToLiveBlogModel(model.data))
			.withType(model.type)
			.withMeta({} as ListItemMeta)
			.build();
	}

	if (
		model.type.toLowerCase() === ContentTypes.COACH ||
		model.type.toLowerCase() === ContentTypes.TEAM ||
		model.type.toLowerCase() === ContentTypes.PLAYER ||
		model.type.toLowerCase() === ContentTypes.TOURNAMENT ||
		model.type.toLowerCase() === ContentTypes.VENUE ||
		model.type.toLowerCase() === ContentTypes.COMPETITION ||
		model.type.toLowerCase() === ContentTypes.PRESIDENT ||
		model.type.toLowerCase() === ContentTypes.ARENA
	) {
		return ListItem.builder()
			.withData({
				id: model.data.id.toString(),
				title: model.data.name,
				type: model.data.entity_type,
				image: extractCorrectImageUrl(model),
				entity_type: model.data.entity_type,
			})
			.withType('RELATEDCONTENT')
			.withMeta({} as ListItemMeta)
			.build();
	}

	return {} as ListItem;
};

export const extractCorrectImageUrl = (model: SearchContentModel) => {
	if (model.type.toLowerCase() === ContentTypes.TEAM || model.type.toLowerCase() === ContentTypes.TOURNAMENT) {
		return model.data.url_logo ? model.data.url_logo : model.data.display_asset && model.data.display_asset.url;
	} else if (model.type.toLowerCase() === ContentTypes.PLAYER || model.type.toLowerCase() === ContentTypes.COACH) {
		return model.data.url_thumb ? model.data.url_thumb : model.data.display_asset && model.data.display_asset.url;
	} else if (model.type.toLowerCase() === ContentTypes.VENUE) {
		return model.data.url_image;
	} else if (model.type.toLowerCase() === ContentTypes.ARENA) {
		return model.data.display_asset && model.data.display_asset.url;
	} else if (model.type.toLowerCase() === ContentTypes.COMPETITION) {
		return model.data.display_asset && model.data.display_asset.url;
	}
};

export const isNumberofItemsValid = (list: List): boolean => {
	if (list.configuration === undefined || list.configuration === null) {
		return true;
	}

	const minItems = list.configuration.minItems ? parseInt(list.configuration.minItems) : list.configuration.minItems;
	const maxItems = list.configuration.maxItems ? parseInt(list.configuration.maxItems) : list.configuration.maxItems;
	const numberOfItems = list.items.length;

	if ((minItems === undefined || minItems === null) && (maxItems === undefined || maxItems === null)) {
		return true;
	} else if ((minItems === undefined || minItems === null) && maxItems && numberOfItems <= maxItems) {
		return true;
	} else if ((maxItems === undefined || maxItems === null) && minItems && numberOfItems >= minItems) {
		return true;
	}

	return numberOfItems <= maxItems && numberOfItems >= minItems;
};

export const validateLockPositions = (list: List) => {
	if (list && list.items && list.lockedPositions) {
		const lockedItems = list.items.filter((item: ListItem, index: number) => list.lockedPositions.includes(index));
		return lockedItems.length < list.lockedPositions.length;
	}
	return false;
};

export const lockPositionsAlert = (list: List) => {
	if (list && list.lockedPositions) {
		return list.lockedPositions.map((position: number) => position + 1).join(', ');
	}
	return '';
};

export const addToListIfNotExists = (itemToAdd: ListItem, items: ListItem[], addItemToTop?: boolean): ListItem[] => {
	const selectedItems = items ? items : [];
	const index = selectedItems.findIndex((item: ListItem) => {
		return (
			item.data &&
			item.data.id === itemToAdd.data.id &&
			item.data.entity_type === itemToAdd.data.entity_type &&
			item.data.name === itemToAdd.data.name
		);
	});
	if (index === -1) {
		addItemToTop ? selectedItems.unshift(itemToAdd) : selectedItems.push(itemToAdd);
	}

	return selectedItems;
};

export const extractSearchOptionId = (itemOption: SearchContentModel) => {
	return itemOption && itemOption.id;
};

export const returnCorrectUrlPathToContent = (option: ListItem) => {
	return option.type.toLowerCase() === `${ContentTypes.LIVE_BLOG}`
		? `/smp/live-blogs/editorial-admin/${option.data.id}`
		: `/smp/${option.type.toLowerCase()}s/edit/${option.data.id}`;
};
