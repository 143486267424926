import StandingsWidgetModel from './standings-widget.model';
import { StandingsWidgetJson } from './standings-widget.json';
import TournamentModel from '../../player-profile/models/tournament-model/tournament.model';
import StageModel from '../../fixtures/models/stage/stage.model';
import SeasonModel from '../../fixtures/models/season/season.model';
import TeamModel from '../../../../partials/team-select/models/team.model';

export default class StandingsWidgetBuilder {
	readonly json: StandingsWidgetJson;

	constructor(standings?: StandingsWidgetModel | StandingsWidgetJson) {
		if (standings && standings instanceof StandingsWidgetModel) {
			this.json = standings.toJson();
		} else if (standings) {
			this.json = standings;
		} else {
			this.json = {} as StandingsWidgetJson;
		}
	}

	withTournament(tournament: TournamentModel): StandingsWidgetBuilder {
		this.json.tournament = tournament;

		return this;
	}

	withSeason(season: SeasonModel): StandingsWidgetBuilder {
		this.json.season = season;

		return this;
	}

	withStage(stage: StageModel): StandingsWidgetBuilder {
		this.json.stage = stage;

		return this;
	}

	withGroup(group: any): StandingsWidgetBuilder {
		this.json.group = group;

		return this;
	}

	withStandings(stageStandings: any): StandingsWidgetBuilder {
		this.json.stageStandings = stageStandings;

		return this;
	}

	withLimit(limit: string): StandingsWidgetBuilder {
		this.json.limit = limit;

		return this;
	}

	withStartFrom(startFrom: string): StandingsWidgetBuilder {
		this.json.startFrom = startFrom;

		return this;
	}

	withHighlightTeams(highlightTeams: TeamModel[]): StandingsWidgetBuilder {
		this.json.highlightTeams = highlightTeams;

		return this;
	}

	withTeam(team: TeamModel): StandingsWidgetBuilder {
		this.json.team = team;

		return this;
	}

	withOffset(offset: string): StandingsWidgetBuilder {
		this.json.offset = offset;

		return this;
	}

	withDisplayLastMatchesResults(displayLastMatchesResults: boolean): StandingsWidgetBuilder {
		this.json.displayLastMatchesResults = displayLastMatchesResults;

		return this;
	}

	withThreeLetterCodeType(threeLetterCodeType: any): StandingsWidgetBuilder {
		this.json.threeLetterCodeType = threeLetterCodeType;

		return this;
	}

	build(): StandingsWidgetModel {
		return StandingsWidgetModel.fromJson(this.json);
	}
}
