import LanguageCodeModel from '../../../../../../models/content-languages/language-code/language-code-model';

export const languageCodesToOptions = (items: LanguageCodeModel[], t: any) => {
	if (items && items.length > 0) {
		return items.map((item: any) => codeToOption(item, t));
	}

	return [];
};

export const codeToOption = (data: LanguageCodeModel, t: any) => {
	if (data && data.languageCode) {
		return { label: t(data.languageCode), value: data.languageCode, data, logo: data.languageCode };
	} else if (data) {
		return { label: t(data), value: data, data, logo: data };
	}

	return {};
};
