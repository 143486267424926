import 'reflect-metadata';
import { jsonMember, jsonObject } from 'typedjson';

@jsonObject
export default class AmgPagingDataModel {
	@jsonMember(Number)
	public totalCount: number = 0;
	@jsonMember(Number)
	public itemCount: number = 0;
	@jsonMember(Number)
	public pageCount: number = 0;
	@jsonMember(Number)
	public pageSize: number = 0;
	@jsonMember(Number)
	public pageIndex: number = 0;
}
