import BlockModel from './block.model';
import { BlockModelJson } from './block.json';

export default class BlockModelBuilder {
	readonly json: BlockModelJson;

	constructor(block?: BlockModel | BlockModelJson) {
		if (block && block instanceof BlockModel) {
			this.json = block.toJson();
		} else if (block) {
			this.json = block;
		} else {
			this.json = {} as BlockModelJson;
		}
	}

	withId(id: string) {
		this.json.id = id;

		return this;
	}

	withData(data: any) {
		this.json.data = Object.assign({}, data);

		return this;
	}

	withType(type: string) {
		this.json.type = type;

		return this;
	}

	build() {
		return BlockModel.fromJson(this.json);
	}
}
