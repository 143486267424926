import { FootballLineupsJson } from './football-lineups.json';
import FootballLineupsBuilder from './football-lineups.builder';
import { RefreshTimeValuesType } from '../../../partials/refresh-time-input/helper';
import TeamModel from '../../../../../../../models/v2/Team/Entity/TeamModel';
import MatchModel from '../../../../../../../models/v2/match/entity/match.model';

export default class FootballLineupsModel {
	readonly match: MatchModel;
	readonly teams: TeamModel[];
	readonly homeTeamColor: string = '';
	readonly awayTeamColor: string = '';
	readonly coachParameters: Record<string, any> = {};
	readonly firstTeamParameters: Record<string, any> = {};
	readonly substitutesParameters: Record<string, any> = {};
	readonly refreshTime: RefreshTimeValuesType;

	private constructor(
		match: MatchModel,
		teams: TeamModel[],
		homeTeamColor: string,
		awayTeamColor: string,
		coachParameters: Record<string, any>,
		firstTeamParameters: Record<string, any>,
		substitutesParameters: Record<string, any>,
		refreshTime: RefreshTimeValuesType,
	) {
		this.match = match;
		this.teams = teams;
		this.homeTeamColor = homeTeamColor;
		this.awayTeamColor = awayTeamColor;
		this.coachParameters = coachParameters;
		this.firstTeamParameters = firstTeamParameters;
		this.substitutesParameters = substitutesParameters;
		this.refreshTime = refreshTime;
	}

	toJson(): FootballLineupsJson {
		return {
			match: this.match,
			teams: this.teams,
			homeTeamColor: this.homeTeamColor,
			awayTeamColor: this.awayTeamColor,
			coachParameters: this.coachParameters,
			firstTeamParameters: this.firstTeamParameters,
			substitutesParameters: this.substitutesParameters,
			refreshTime: this.refreshTime,
		};
	}

	static fromJson(json: FootballLineupsJson): FootballLineupsModel {
		return new FootballLineupsModel(
			json.match,
			json.teams,
			json.homeTeamColor,
			json.awayTeamColor,
			json.coachParameters,
			json.firstTeamParameters,
			json.substitutesParameters,
			json.refreshTime,
		);
	}

	static builder(footballLineupsWidget?: FootballLineupsModel): FootballLineupsBuilder {
		return new FootballLineupsBuilder(footballLineupsWidget);
	}
}
