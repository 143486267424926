import React from 'react';
import { Button, Col, Input, Label, Row } from 'reactstrap';
import { Properties } from './properties/selections.properties';

export default class SelectionsComponent extends React.Component<Properties> {
	addSelection = () => {
		const { selections, onSelectionChange } = this.props;
		selections.push({ offset: this.getPreviousSelectionSum(selections.length), limit: 0 });
		onSelectionChange(selections);
	};

	removeSelection = (index: number) => {
		const { selections, onSelectionChange } = this.props;
		selections.splice(index, 1);
		onSelectionChange(selections);
	};

	onOffsetChange = (offset: string, index: number) => {
		const { selections, onSelectionChange } = this.props;
		selections[index].offset = parseInt(offset) < 0 ? 0 : this.blockToLimitOfPreviousSelection(parseInt(offset), index);
		onSelectionChange(selections);
	};

	onLimitChange = (limit: string, index: number) => {
		const { selections, onSelectionChange } = this.props;
		selections[index].limit = parseInt(limit) < 0 ? 0 : parseInt(limit);
		onSelectionChange(selections);
	};

	blockToLimitOfPreviousSelection = (offset: number, index: number) => {
		const { selections } = this.props;

		if (selections.length < 2 || index - 1 < 0) {
			return offset;
		}

		const previousLimit = selections[index - 1].limit;
		const previousOffset = selections[index - 1].offset;
		const previousSelectSum = previousLimit + previousOffset;

		return previousSelectSum <= offset ? offset : previousSelectSum;
	};

	getPreviousSelectionSum = (index: number) => {
		const { selections } = this.props;

		if (selections.length < 1) {
			return 0;
		}

		const previousLimit = selections[index - 1].limit;
		const previousOffset = selections[index - 1].offset;

		return previousLimit + previousOffset;
	};

	render() {
		const { display, selections, t } = this.props;

		if (display === undefined || !display) {
			return null;
		}

		return (
			<>
				<Row className='mb-1'>
					<Col>
						<hr />
						<h6>{this.props.t('preview_details')}</h6>
					</Col>
				</Row>
				{selections && selections.length < 1 ? (
					<Row className='mb-2'>
						<Col>
							<div className='text-center'>{t('no_ranges_added')}</div>
						</Col>
					</Row>
				) : (
					<Row>
						<Col md={4} className='text-left'>
							<Label>{this.props.t('start_from_position')}:</Label>
						</Col>
						<Col md={8} className='text-left'>
							<Label>{this.props.t('show')}:</Label>
						</Col>
					</Row>
				)}
				{selections &&
					selections.map((selection: any, index: number) => {
						return (
							<Row className='mb-2' key={`index-${index}`}>
								<Col>
									<Input
										type='number'
										placeholder='Offset'
										onChange={(event) => this.onOffsetChange(event.target.value, index)}
										value={selection.offset}
									/>
								</Col>
								<Col>
									<Input
										type='number'
										placeholder='Limit'
										onChange={(event) => this.onLimitChange(event.target.value, index)}
										value={selection.limit}
									/>
								</Col>
								<Col>
									<Button bssize='sm' color='outline-secondary' onClick={() => this.removeSelection(index)}>
										<i className='fa fa-minus'></i>
									</Button>
								</Col>
							</Row>
						);
					})}
				<Row>
					<Col className='text-center'>
						<Button bssize='sm' color='outline-secondary' onClick={this.addSelection.bind(this)}>
							<i className='fa fa-plus'></i>
						</Button>
					</Col>
				</Row>
			</>
		);
	}
}
