import React from 'react';
import { Properties } from './properties/sources.properties';
import { analyticsService } from '../../../../App';
import { socialTypes } from '../helpers/dropdown-feed.helper';

const Sources: React.FunctionComponent<Properties> = ({
	notifications,
	selectedFeed,
	sourceTab,
	toggleSidebar,
	collapsed,
	onSourceChange,
	t,
}) => {
	const sourceChange = (id: string, sourceLocation: string) => {
		analyticsService.sendEvent('Click', 'News tracker', `Source-${sourceLocation}`);
		onSourceChange(id, sourceLocation);
	};

	const onChangeAll = () => {
		analyticsService.sendEvent('Click', 'News tracker', 'Source-All');
		sourceChange('All', 'All');
	};

	const sourceTabNotification = (sourceLocation: string) => notifications.filter((item: any) => item.sourceLocation === sourceLocation);

	const allNotifications = notifications ? notifications.reduce((acc: number, item: any) => acc + item.notifications, 0) : [];

	return (
		<React.Fragment>
			<div className='toggle-feeds-btn' onClick={() => toggleSidebar()}>
				<i className={collapsed ? 'icon-arrow-left' : 'icon-arrow-right'}></i>
			</div>
			<div onClick={onChangeAll} className={`source-thumbnail border ${sourceTab === 'All' ? 'selected' : ''}`} title={`${t('all')}`}>
				<div className='source-name'>{t('all')}</div>
				{allNotifications > 0 && selectedFeed.sources.length > 0 && <span className='notifications'>{allNotifications}</span>}
			</div>
			{selectedFeed &&
				selectedFeed.sources &&
				selectedFeed.sources.map((source: any) => {
					return (
						<div
							title={`${source.subtype === 'HASHTAG' ? '#' : ''}${source.name}`}
							onClick={() => sourceChange(source.id, source.location)}
							key={source.id}
							className={`source-thumbnail border ${sourceTab === source.id ? 'selected' : ''}`}
						>
							{source.thumbnail ? (
								<img className='rounded-circle' alt={source.name} src={source.thumbnail} width='40' />
							) : (
								<div className='source-name'>
									{source.subtype === 'HASHTAG' ? <span>#</span> : ''}
									{source.name
										.match(/\b(\w)/g)
										.join('')
										.toUpperCase()}
								</div>
							)}
							<span className='type shadow'>
								<i className={`fa fa-${socialTypes[source.type.toLowerCase()]}`} />
							</span>
							{sourceTabNotification(source.location).length > 0 && sourceTabNotification(source.location)[0].notifications > 0 && (
								<span className='notifications'>
									{sourceTabNotification(source.location).reduce((acc: number, item: any) => acc + item.notifications, 0)}
								</span>
							)}
						</div>
					);
				})}
		</React.Fragment>
	);
};

export default Sources;
