import React from 'react';
import { useTranslation } from 'react-i18next';
import { compose } from 'redux';
import { SportBlockTypes, SportTypes, WidgetScriptTypes } from '../../../../../../../constants/sport.types';
import BlockUpdateController from '../../../../blocks/block-update-controller.component';
import { MonorepoWidgetPreview } from '../../../../blocks/widgets/widget-preview/monorepo-widget-preview.component';
import { ViewTypes } from '../../../../constants/general.constants';
import BlockModel from '../../../../models/block.model';
import StakesModel from '../models/stakes.model';
import { StakesViewComponent } from './stakes-view.component';
import FootballStakesWidgetEdit from './stakes-widget-edit.component';

type Properties = {
	block: BlockModel;
	view: ViewTypes;
	contentData: any;
	contentLanguage: string;
};

const FootballStakes: React.FunctionComponent<Properties> = (props) => {
	const { view, block, contentData, contentLanguage } = props;
	const [t] = useTranslation();

	if (view === ViewTypes.edit) {
		return <FootballStakesWidgetEdit block={block} t={t} contentData={contentData} contentLanguage={contentLanguage} />;
	}

	if (view === ViewTypes.preview) {
		return (
			<MonorepoWidgetPreview
				blockContent={props.block.data.content}
				config={props.block.data.config}
				type={WidgetScriptTypes.FOOTBALL_WIDGETS}
				blockType={SportBlockTypes.EVENT_BLOCK}
				sport={SportTypes.FOOTBALL}
			/>
		);
	}

	if (view === ViewTypes.normal) {
		return (
			<StakesViewComponent
				preview={
					block && block.data && block.data.preview && block.data.preview.widgetModel ? block.data.preview.widgetModel : new StakesModel()
				}
			/>
		);
	}

	return null;
};

export default compose(BlockUpdateController)(FootballStakes);
