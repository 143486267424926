import React from 'react';
import { Row, Col, FormGroup, Label, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Title } from '../../Partials/Fields/title/TitleComponent';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Project from '../../../models/project/Project';
import Banner from '../../../models/banner/Banner';
import { Properties, State } from './Properties/BannerCreateProperties';
import {
	BANNER_ENTITY_CREATE,
	BANNER_ENTITY_CREATE_FAILED,
	BANNER_ENTITY_CREATE_SUCCESS,
	returnObjectForBannerEntityCreate,
} from '../../../store/action-creators/BannerActionCreators';
import { Code } from '../../Partials/Fields/code/CodeComponent';
import BlockableButtonWrapper from '../../Partials/BaseComponents/BlockableButton/BlockableButtonWrapper';
import StatusSelect from '../../Partials/Sidebar/GeneralContentAttributes/subcomponents/status-select/status-select';
import { ContentTypes } from '../../Partials/Blocky/constants/block.types';
import { DynamicObjectWithKeys } from '../../../constants/content-types';
import ContentPropertiesContainer from '../../Partials/Sidebar/GeneralContentAttributes/subcomponents/ContentProperties/ContentPropertiesContainer';

class BannerCreate extends React.Component<Properties, State> {
	componentDidMount(): void {
		this.initPageTitle();
		this.initBannerCreateSuccessListener();
	}

	initPageTitle() {
		document.title = this.props.t('banner_create');
	}

	constructor(props: Properties) {
		super(props);
		this.state = {
			banner: Banner.builder().withDynamicPropertiesInitially(Banner.extractDynamicPropertiesAsObjectWithValues()).build(),
		};
	}

	initBannerCreateSuccessListener() {
		window.addEventListener(BANNER_ENTITY_CREATE_SUCCESS, (data: any) => {
			this.props.history.push(`/smp/banners/edit/${data.detail}`);
		});
	}

	onTitleChange(title: string) {
		this.setState({ banner: Banner.builder(this.state.banner).withTitle(title).build() });
	}

	onCodeChange(code: string) {
		this.setState({ banner: Banner.builder(this.state.banner).withCode(code).build() });
	}

	onDynamicPropertiesChange(dynamicProperties: DynamicObjectWithKeys) {
		try {
			const currentProperties = { ...this.state.banner.properties };
			currentProperties[dynamicProperties.key] = dynamicProperties.checked;
			const formattedData = Object.keys(currentProperties).map((dynamicPropertyKey) => {
				return { name: dynamicPropertyKey, slug: dynamicPropertyKey, value: !!currentProperties[dynamicPropertyKey] };
			});
			const banner = Banner.builder(this.state.banner).withDynamicProperties(formattedData).build();
			this.setState({ banner });
		} catch (e) {}
	}

	onStatusChange(status: string) {
		this.setState({ banner: Banner.builder(this.state.banner).withStatus(status).build() });
	}

	onSubmit() {
		this.props.postBanner(this.state.banner, this.props.project);
	}

	render() {
		const { t, statuses } = this.props;
		return (
			<div className='animated fadeIn'>
				<Row>
					<Col col='12' lg='12' md='12' sm='12' xs='12'>
						<div className='card'>
							<div className='card-header'>
								<div className={'d-flex align-items-center'}>
									<span className={'mr-auto mb-0'}>{t('banner_create_title')}</span>
									<BlockableButtonWrapper
										blockOnActions={[BANNER_ENTITY_CREATE]}
										releaseOnActions={[BANNER_ENTITY_CREATE_SUCCESS, BANNER_ENTITY_CREATE_FAILED]}
									>
										<Button size='sm' color='primary' id='banner-create-save-top' className={'ml-auto'} onClick={this.onSubmit.bind(this)}>
											<i className='fa fa-floppy-o'></i> {t('save_banner')}
										</Button>
									</BlockableButtonWrapper>
								</div>
							</div>
							<div className='card-body'>
								<Row>
									<Col xs='12'>
										<Label htmlFor='title'>{t('title')}</Label>
										<Title t={t} onChange={this.onTitleChange.bind(this)} value={this.state.banner.title} />
									</Col>
								</Row>
								<Row>
									<Col xs='12'>
										<Code t={t} onChange={this.onCodeChange.bind(this)} value={this.state.banner.code} />
									</Col>
								</Row>
								<Row>
									<Col xs='12'>
										<Label htmlFor='title'>{t('status')}</Label>
										<StatusSelect
											t={t}
											contentType={ContentTypes.banner}
											isClearable
											onStatusSelect={this.onStatusChange.bind(this)}
											selectedStatus={this.state.banner.status}
											statuses={statuses && statuses[`${ContentTypes.banner}Statuses`]}
										/>
									</Col>
								</Row>

								<Row className='mt-4'>
									<Col xs='12'>
										<ContentPropertiesContainer
											t={t}
											onPropertySelected={this.onDynamicPropertiesChange.bind(this)}
											content={{ properties: this.state.banner.properties }}
											contentType={ContentTypes.banner}
										/>
									</Col>
								</Row>

								<Row className={'mt-4'}>
									<Col>
										<BlockableButtonWrapper
											blockOnActions={[BANNER_ENTITY_CREATE]}
											releaseOnActions={[BANNER_ENTITY_CREATE_SUCCESS, BANNER_ENTITY_CREATE_FAILED]}
										>
											<Button
												color={'primary'}
												id='banner-create-save-bottom'
												className={'text-uppercase font-weight-bold'}
												onClick={this.onSubmit.bind(this)}
											>
												<i className='fa fa-floppy-o'></i> {t('save_banner')}
											</Button>
										</BlockableButtonWrapper>
									</Col>
								</Row>
							</div>
						</div>
					</Col>
				</Row>
			</div>
		);
	}
}

function mapStateToProps(state: any) {
	return {
		banner: state.banner,
		project: state.project.currentProject,
		statuses: state.statuses,
	};
}

function mapDispatchToProps(dispatch: any) {
	return {
		postBanner: (banner: Banner, project: Project) => dispatch(returnObjectForBannerEntityCreate(banner, project)),
	};
}

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(BannerCreate) as React.ComponentType;
