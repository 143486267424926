import { Observable, Subject } from 'rxjs';
import BlockModel from '../models/block.model';
import BlockConfigService from '../services/block-config.service';

export type BlockAddOrUpdate = {
	block: BlockModel;
	index: number;
};

export default class BlockManagementService {
	private addBlockSubject: Subject<BlockAddOrUpdate> = {} as Subject<BlockAddOrUpdate>;
	private blockUpdateSubject: Subject<BlockAddOrUpdate> = {} as Subject<BlockAddOrUpdate>;
	private blockRemoveSubject: Subject<number> = {} as Subject<number>;

	constructor() {
		this.addBlockSubject = new Subject<BlockAddOrUpdate>();
		this.blockUpdateSubject = new Subject<BlockAddOrUpdate>();
		this.blockRemoveSubject = new Subject<number>();
	}

	public blockAdd = (data: BlockAddOrUpdate): void => {
		this.addBlockSubject.next(data);
	};

	public onBlockAdd = (): Observable<any> => {
		return this.addBlockSubject.asObservable();
	};

	public blockUpdate = (block: BlockModel, index: number): void => {
		new BlockConfigService()
			.configWidget(block)
			.then(() => {
				this.blockUpdateSubject.next({ block, index });
			})
			.catch((e: any) => e);
	};

	public onBlockUpdate = (): Observable<BlockAddOrUpdate> => {
		return this.blockUpdateSubject.asObservable();
	};

	public blockRemove = (blockIndex: number): void => {
		this.blockRemoveSubject.next(blockIndex);
	};

	public onBlockRemove = (): Observable<number> => {
		return this.blockRemoveSubject.asObservable();
	};
}
