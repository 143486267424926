import React from 'react';
import { Col, Row } from 'reactstrap';
import FootballTeamMatchStatsWidgetModel from '../models/football-team-match-stats.model';
import { imagePlaceHolder } from '../../../../../../../constants/resource-constants';
import OddsV2Model from '../../odds/models/v2/odds-v2.model';
import { teamMatchStatsOptions } from '../../football-team/constants/football-team.constants';
import SportOddsBookmakerModel from '../../../../../../../models/sport-odds/sport-odds-bookmaker.model';

type Properties = {
	preview: FootballTeamMatchStatsWidgetModel;
	oddsPreview: OddsV2Model;
	t: any;
};

export const FootballTeamMatchStatsViewComponent: React.FunctionComponent<Properties> = ({ preview, oddsPreview, t }) => {
	const stats = preview.stats && preview.stats.length > 0 ? preview.stats : teamMatchStatsOptions;

	if (preview.team1 && preview.team1.id && preview.team2 && preview.team2.id) {
		return (
			<>
				<Row data-qa='football-team-match-stats-view' className={'pb-4 pl-4 pr-4'}>
					<Col className='text-center'>
						<img
							className='rounded-circle'
							height='50'
							src={preview.team1 && preview.team1.urlLogo && preview.team1.urlLogo}
							alt={preview.team1.name}
						/>
						<h4>{preview.team1.name}</h4>
						{preview.team1TournamentSeason && preview.team1TournamentSeason.seasonId && (
							<div className='single-row'>
								{preview.team1TournamentSeason.tournamentName} - {preview.team1TournamentSeason.seasonName}
							</div>
						)}
					</Col>
					<Col className='mr-3 d-flex flex-column justify-content-center align-items-center'>
						<span>{t('vs')}</span>
					</Col>
					<Col className='text-center'>
						<img
							className='rounded-circle'
							height='50'
							src={preview.team2 && preview.team2.urlLogo && preview.team2.urlLogo}
							alt={preview.team2.name}
						/>
						<h4>{preview.team2.name}</h4>
						{preview.team2TournamentSeason && preview.team2TournamentSeason.seasonId && (
							<div className='single-row'>
								{preview.team2TournamentSeason.tournamentName} - {preview.team2TournamentSeason.seasonName}
							</div>
						)}
					</Col>
				</Row>
				<Row className={'pb-4 pl-4 pr-4'}>
					<Col>
						{preview.match && preview.match.id && (
							<div className='single-row'>
								<strong>{t('match') + ': '}</strong>
								{preview.match &&
									preview.match.homeTeam &&
									preview.match.homeTeam.name &&
									preview.match.awayTeam &&
									preview.match.awayTeam.name &&
									preview.match.homeTeam &&
									preview.match.homeTeam.name + ' - ' + preview.match.awayTeam.name}
							</div>
						)}
					</Col>
				</Row>
				{stats && stats.length > 0 && (
					<Row className={'pb-4 pl-4 pr-4'}>
						<Col>
							<strong>{t('selected_stats')}:</strong>
							{stats.map((stat: string, index: number) => {
								return (
									<span key={`team-match-stats-stat-key-${index}`} className='pl-1'>
										{t(`${stat}_title`)}
										{index + 1 === stats.length ? '' : ','}
									</span>
								);
							})}
						</Col>
					</Row>
				)}
				<hr />
				<Row className='single-row pb-4 pl-4 pr-4'>
					<Col className='text-left'>
						<Row>
							<Col className='text-left'>
								{t('display_odds')}:
								<strong>
									<span className={preview.displayOdds ? 'text-success' : 'text-danger'}>
										{preview.displayOdds ? ` ${t('yes')}` : ` ${t('no')}`}
									</span>
								</strong>
							</Col>
						</Row>
						{preview.displayOdds && oddsPreview.bookmakers && oddsPreview.bookmakers.length > 0 && (
							<Row className='mt-2'>
								<Col className='text-left'>
									{t('show')}:
									{oddsPreview.bookmakers.map((bookmaker: SportOddsBookmakerModel) => {
										return (
											<img
												key={bookmaker.id}
												height='20'
												alt={bookmaker.name}
												className='h-20 mx-1'
												src={bookmaker.logo ? bookmaker.logo : imagePlaceHolder}
												title={bookmaker.name}
											/>
										);
									})}
								</Col>
							</Row>
						)}
						{preview.displayOdds && oddsPreview.market && oddsPreview.market.name && oddsPreview.market.name.length > 0 && (
							<Row className='mt-2'>
								<Col className='text-left'>
									<span>{t('default_market')}: </span>
									<strong>{t(oddsPreview.market.name)}</strong>
								</Col>
							</Row>
						)}
					</Col>
				</Row>
			</>
		);
	}

	return null;
};
