import Source from './source';
import { SourceJson } from './source.json';
import { SourceRequestJson } from './source.request-json';

export default class SourceBuilder {
	private json: SourceJson;

	constructor(source?: Source | SourceJson) {
		if (source && source instanceof Source) {
			this.json = source.toJson();
		} else if (source) {
			this.json = source;
		} else {
			this.json = {} as SourceJson;
		}
	}

	withId(id: string): SourceBuilder {
		this.json.id = id;

		return this;
	}

	withName(name: string): SourceBuilder {
		this.json.name = name;

		return this;
	}

	withLocation(location: string): SourceBuilder {
		this.json.location = location;

		return this;
	}

	withType(type: string): SourceBuilder {
		this.json.type = type;

		return this;
	}

	withSubtype(subtype: string): SourceBuilder {
		this.json.subtype = subtype;

		return this;
	}

	withThumbnail(thumbnail: string): SourceBuilder {
		this.json.thumbnail = thumbnail;

		return this;
	}

	toRequestJson(): SourceRequestJson {
		let json = {} as SourceRequestJson;
		json.id = this.json.id;
		json.name = this.json.name;
		json.type = this.json.type;
		json.subtype = this.json.subtype;
		json.location = this.json.location;
		json.thumbnail = this.json.thumbnail;

		return json;
	}

	build(): Source {
		return Source.fromJson(this.json);
	}
}
