import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { SPORT_SELECT_ATTRIBUTES } from '../../widgets-v2/helpers/component-attributes.constants';
import { useTranslation } from 'react-i18next';

export enum LINEUPS_ELEMENTS {
	coach = 'coach',
	substitutes = 'substitutes',
	firstTeam = 'first_team',
	open = 'opened',
	closed = 'closed',
}

type Properties = {
	setElement: (element: string) => void;
	propertyToSet: string;
	label: string;
	toggledProperty: boolean;
};

export const LineupsElementsSelect: React.FunctionComponent<Properties> = ({ setElement, toggledProperty, label, propertyToSet }) => {
	const [t] = useTranslation();
	const [toggled, setToggled] = useState<boolean>(toggledProperty);

	useEffect(() => {
		onElementToggle();
	}, [toggled]);

	const setToggle = () => {
		setToggled(!toggled);
	};

	const onElementToggle = () => {
		switch (propertyToSet) {
			case LINEUPS_ELEMENTS.coach:
				return toggled ? setElement(LINEUPS_ELEMENTS.coach) : setElement('');
			case LINEUPS_ELEMENTS.firstTeam:
				return toggled ? setElement(LINEUPS_ELEMENTS.firstTeam) : setElement('');
			case LINEUPS_ELEMENTS.substitutes:
				return toggled ? setElement(LINEUPS_ELEMENTS.substitutes) : setElement('');
			case LINEUPS_ELEMENTS.open:
				return toggled ? setElement(LINEUPS_ELEMENTS.open) : setElement(LINEUPS_ELEMENTS.closed);
			case LINEUPS_ELEMENTS.closed:
				return toggled ? setElement(LINEUPS_ELEMENTS.open) : setElement(LINEUPS_ELEMENTS.closed);
		}
	};

	return (
		<Row className='mb-3' data-qa={SPORT_SELECT_ATTRIBUTES.LINEUPS_ELEMENTS}>
			<Col>
				<div
					id={SPORT_SELECT_ATTRIBUTES.LINEUPS_ELEMENTS}
					className='cursor-pointer p-1 d-flex align-items-center border-muted rounded'
					onClick={setToggle}
				>
					<i className={`fa fa-2x fa-toggle-${toggled ? 'on text-success' : 'off text-muted'}`} />
					<span className='ml-1'>{t(`${label}`)}</span>
				</div>
			</Col>
		</Row>
	);
};
