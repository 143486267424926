import axios, { AxiosInstance } from 'axios';
import { featuresService } from '../../../App';
import { FeatureTypes } from '../../feature-service/features.enum';
import REACT_APP_URLS from '../../../global-helpers/global-url.helpers';

export default class ImagoHttpService {
	private instance: AxiosInstance = {} as AxiosInstance;

	constructor() {
		this.createImagoImageInstance();
	}

	private createImagoImageInstance = () => {
		const headers = {
			'x-api-user':
				featuresService &&
				featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.IMAGO_INTEGRATION) &&
				featuresService.getFeatureConfig(FeatureTypes.IMAGO_INTEGRATION) &&
				featuresService.getFeatureConfig(FeatureTypes.IMAGO_INTEGRATION).username,
			'x-api-key':
				featuresService &&
				featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.IMAGO_INTEGRATION) &&
				featuresService.getFeatureConfig(FeatureTypes.IMAGO_INTEGRATION) &&
				featuresService.getFeatureConfig(FeatureTypes.IMAGO_INTEGRATION).key,
			'Content-Type': 'application/json',
		};
		this.instance = axios.create({
			baseURL: REACT_APP_URLS.REACT_APP_IMAGO_PROXY_URL,
			timeout: 30000,
			headers: { ...headers },
		});
	};

	public getImagoImages(search: string, pageOffset: number, type: string) {
		let data = {};
		if (type === 'both') {
			data = JSON.stringify({
				querystring: search,
				size: 15,
				db: '',
				from: pageOffset ? pageOffset : 0,
			});
		} else {
			data = JSON.stringify({
				querystring: search,
				size: 15,
				db: type,
				from: pageOffset ? pageOffset : 0,
			});
		}

		return this.instance.post('/search', data);
	}

	public resolutionConfig = () => {
		let resolutionConfig =
			featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.IMAGO_INTEGRATION) &&
			featuresService.getFeatureConfig(FeatureTypes.IMAGO_INTEGRATION) &&
			featuresService.getFeatureConfig(FeatureTypes.IMAGO_INTEGRATION).resolution;
		return resolutionConfig ? resolutionConfig : 8;
	};

	public async downloadImagoImage(type: string, pcitureId: string) {
		let data = JSON.stringify({
			pictureid: pcitureId,
			db: type,
			res: this.resolutionConfig(),
		});

		return this.instance.post('/download', data, { responseType: 'blob' });
	}
}
