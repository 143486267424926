export const LINK_TYPES = {
	link: 'link',
	article: 'article',
	video: 'video',
	gallery: 'gallery',
	audio: 'audio',
	document: 'document',
};

export const openTypes = {
	newWindow: 'NEW_WINDOW',
	sameWindow: 'SAME_WINDOW',
};
