export const selectionsToSeasons = (selections: any[]) => {
	if (selections && selections.length > 0) {
		return selections.map((selection: any) => {
			return selection.data;
		});
	}

	return [];
};

export const contentToOption = (content: any) => {
	if (content && content.id) {
		return {
			label: content.tournament + ' - ' + content.name,
			value: content.id,
			data: content,
		};
	}

	return {};
};

export const contentToOptions = (response: any) => {
	if (response && response.length > 0) {
		return response.map((content: any) => {
			return contentToOption(content);
		});
	}

	return [];
};

export const remapOptions = (content: any, tournament: any) => {
	if (content && content.length > 0 && tournament) {
		return content.map((content: any) => {
			return contentToOptionWithTournament(content, tournament);
		});
	}

	return {};
};

export const contentToOptionWithTournament = (content: any, tournament: any) => {
	if (content && content.id > 0 && tournament) {
		return {
			id: content.id,
			name: content.name,
			active: content.active,
			tournament: tournament,
		};
	}

	return {};
};
