import { MainMediaJson } from './MainMediaJson';
import MainMedia from './MainMedia';

export default class MainMediaBuilder {
	private json: MainMediaJson;

	constructor(mainMedia?: MainMedia | MainMediaJson) {
		if (mainMedia && mainMedia instanceof MainMedia) {
			this.json = mainMedia.toJSON();
		} else if (mainMedia) {
			this.json = mainMedia;
		} else {
			this.json = {} as MainMediaJson;
		}
	}

	withDescription(description: string): MainMediaBuilder {
		this.json.description = description;

		return this;
	}

	withGeneric(generic: any): MainMediaBuilder {
		this.json.generic = generic;

		return this;
	}

	withResourceId(resourceId: string): MainMediaBuilder {
		this.json.resourceId = resourceId;

		return this;
	}

	withResourceType(resourceType: string): MainMediaBuilder {
		this.json.resourceType = resourceType;

		return this;
	}

	withResourceSubtype(resourceSubtype: string): MainMediaBuilder {
		this.json.resourceSubtype = resourceSubtype;

		return this;
	}

	withProvider(provider: string): MainMediaBuilder {
		this.json.provider = provider;

		return this;
	}

	withData(data: any): MainMediaBuilder {
		this.json.data = data;

		return this;
	}

	withAddDescriptionToMedia(addDescriptionToMedia: boolean): MainMediaBuilder {
		this.json.addDescriptionToMedia = addDescriptionToMedia;

		return this;
	}

	withAddWatermarkToImageMedia(addWatermarkToImageMedia: boolean): MainMediaBuilder {
		this.json.addWatermarkToImageMedia = addWatermarkToImageMedia;

		return this;
	}

	isEmpty(): boolean {
		if (this.json) {
			const isDescriptionSet = this.json.description && this.json.description.length > 0;
			const isResourceIdSet = this.json.resourceId && this.json.resourceId.length > 0;
			const isResourceTypeSet = this.json.resourceType && this.json.resourceType.length > 0;
			const isResourceSubtypeSet = this.json.resourceSubtype && this.json.resourceSubtype.length > 0;
			const isProviderSet = this.json.provider && this.json.provider.length > 0;
			const isDataSet = this.json.data && this.json.data.length > 0;

			return !(isDescriptionSet || isResourceIdSet || isResourceTypeSet || isResourceSubtypeSet || isProviderSet || isDataSet);
		}

		return false;
	}

	build(): MainMedia {
		return MainMedia.fromJSON(this.json);
	}
}
