import React, { useEffect } from 'react';
import BlockConfigModel from '../../../models/block-config.model/block-config.model';
import { buildMonoRepoIframeUrl } from '../../../v2/widgets-v2/helpers/block-utility.helper';
import '../../../partials/monorepo-dangerously-set-inner/helper/monorepo-iframe.style.scss';
export type Properties = {
	blockContent: BlockConfigModel;
	config?: any;
	type: string;
	blockType?: string;
	blockId?: string;
	sport: string;
};

export const MonorepoWidgetPreview: React.FunctionComponent<Properties> = ({ blockContent, config, blockId, sport }) => {
	const gameId = config && config.options && config.options.gameId ? config.options.gameId : '';

	useEffect(() => {
		return function cleanup() {
			clearEventRequest(gameId);
		};
	});

	if (blockContent) {
		return (
			<div className='monorepo-block-iframe-wrapper'>
				<iframe className='monorepo-block-iframe' id={blockId} src={buildMonoRepoIframeUrl(config, sport)} />
			</div>
		);
	}

	return null;
};

const clearEventRequest = (gameId: string) => {
	window.dispatchEvent(
		new CustomEvent('unregister_event', {
			detail: {
				gameId,
			},
		}),
	);

	if (window.removeEventsRegister) {
		window.removeEventsRegister();
		window.removeSingleRequestEventListener();
	}
};
