import React from 'react';
import { compose } from 'redux';
import { ViewTypes } from '../../../constants/general.constants';
import BlockModel from '../../../models/block.model';
import BlockUpdateController from '../../../blocks/block-update-controller.component';
import { SportBlockTypes, SportTypes, WidgetScriptTypes } from '../../../../../../constants/sport.types';
import { MonorepoWidgetPreview } from '../../../blocks/widgets/widget-preview/monorepo-widget-preview.component';
import TennisTournamentProgrammeModel from './models/tennis-tournament-programme.model';
import { TennisTournamentProgrammeViewComponent } from './tennis-tournament-programme-view.component';
import TennisTournamentProgrammeEditComponent from './tennis-tournament-programme-edit.component';

type Properties = {
	block: BlockModel;
	view: ViewTypes;
	t: any;
	contentData: any;
	contentLanguage: string;
};

const TennisTournamentProgrammeWidget: React.FunctionComponent<Properties> = (props) => {
	const { view, t, block, contentData, contentLanguage } = props;

	if (view === ViewTypes.edit) {
		return <TennisTournamentProgrammeEditComponent block={block} contentData={contentData} contentLanguage={contentLanguage} t={t} />;
	}
	if (view === ViewTypes.preview) {
		return (
			<MonorepoWidgetPreview
				blockId={props.block.id}
				blockContent={props.block.data.content}
				config={props.block.data.config}
				type={WidgetScriptTypes.TENNIS_WIDGETS}
				blockType={SportBlockTypes.TOURNAMENT_BLOCK}
				sport={SportTypes.TENNIS}
			/>
		);
	}

	if (view === ViewTypes.normal) {
		return (
			<TennisTournamentProgrammeViewComponent
				t={t}
				preview={
					props.block.data.preview && props.block.data.preview.widgetModel
						? props.block.data.preview.widgetModel
						: TennisTournamentProgrammeModel.builder().build()
				}
			/>
		);
	}

	return null;
};

export default compose(BlockUpdateController)(TennisTournamentProgrammeWidget);
