import React, { ChangeEvent, useState } from 'react';
import { Button, Col, FormGroup, Label, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { compose } from 'redux';
import { connect, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { DebounceInput } from 'react-debounce-input';
import Project from '../../../../../../../models/project/Project';
import MainMedia from '../../../../../../../models/mainmedia/MainMedia';
import { featuresService } from '../../../../../../../App';
import { FeatureTypes } from '../../../../../../../services/feature-service/features.enum';
import ImagoHttpService from '../../../../../../../services/rest/imago/ImagoHttpService';
import Image from '../../../../../../../models/image/Image';
import ImagoImageModel from '../../../../../Blocky/blocks/content/imago-image-block/models/imago-image.model';
import {
	addImagoImageIDtoState,
	buildMainMediaFromImago,
	checkIfArrayContainsSameImagoID,
	checkIfOnEditScreen,
	checkIfStateContainsImagoID,
	constructRequestFromFilePath,
	findImageForMainMedia,
	getImagoIDinMainMedia,
} from '../../../../../Shared/image-upload/helpers/imago-image-upload.helper';
import HttpService from '../../../../../../../services/rest/HttpService';
import { ContentTypes, MainMediaTypes } from '../../../../../../../constants/content-types';
import AsyncImageContainer from '../../../../../BaseComponents/AsyncImage/AsyncImageContainer';
import ImageUploadButtonContainer from '../../../../../Shared/image-upload/image-upload-button-container';
import ConditionalRenderWrapper from '../../../../../BaseComponents/ConditionalRenderWraper/ConditionalRenderWrapper';
import ImageEditModal from '../../../../../../Resources/Images/Subcomponents/image-edit-modal/ImageEditModal';
import ErrorAsync from '../../../../../BaseComponents/ErrorComponent/ErrorAsync';
import { MainMediaContentModal } from '../../../../Media/subcomponents/media-modals/main-media-content-modal.component';
import ImageCropContainer from '../../../../../Shared/image-crop/image-crop-container';
import { AppState } from '../../../../../../../store/store';
import '../styles/main-media-images.style.scss';
import { DATA_QA_ATTRIBUTES } from '../helpers/main-media-additional-items.helper';
import { MainMediaAdditionalImageContentModal } from './main-media-additional-image-container.component';

export type Properties = {
	media: MainMedia;
	onMainImageMediaUpdate: (mainMedia: MainMedia) => void;
	projectOriginId: Project;
	mediaErrorIndex: number;
	contentType: string;
	additionalImagesModalOpened: boolean;
};

export const MainMediaAdditionalImage: React.FunctionComponent<Properties> = (props) => {
	const [t] = useTranslation();
	const imagoEnabled = featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.IMAGO_INTEGRATION);
	const imagoHttp = new ImagoHttpService();
	const project = useSelector((state: AppState) => state.project.currentProject);

	const [displayCropModal, setDisplayCropModal] = useState<boolean>(false);
	const [addDescriptionToImage, setAddDescriptionToImage] = useState<boolean>(false);
	const [imagoCollapseIsOpen, setImagoCollapseIsOpen] = useState<boolean>(false);
	const [additionalImagesModalIsOpen, setAdditionalImagesModalIsOpen] = useState<boolean>(false);
	const [imagoIsUploaded, setImagoIsUploaded] = useState<object[]>([]);
	const [addedImagoMainMedia, setAddedImagoMainMedia] = useState<boolean>(false);
	const [showEditModal, setShowEditModal] = useState<boolean>(false);
	const [imageId, setImageId] = useState<string>('');

	const onImageSelect = (image: Image) => {
		const { media } = props;
		const mainMedia = MainMedia.builder(media)
			.withResourceId(image.id)
			.withProvider('smp')
			.withResourceType(ContentTypes.IMAGE)
			.withResourceSubtype(media.resourceSubtype)
			.withData(image)
			.build();
		setAddedImagoMainMedia(false);
		setAdditionalImagesModalIsOpen(false);
		props.onMainImageMediaUpdate(mainMedia);
	};

	const setImageAsMainMedia = async (image: ImagoImageModel) => {
		setImagoCollapseIsOpen(false);
		await uploadImagoImage(image);
	};

	const uploadImagoImage = async (image: ImagoImageModel) => {
		const { media, projectOriginId, contentType } = props;
		const headers = { Project: project.domain, 'content-type': 'multipart/form-data' };
		const form = new FormData();
		let imagePaths: string[] = [];

		//Download the Imago Image
		let editScreen = checkIfOnEditScreen(window.location.href.split('/'));
		let imagoID: string[] = getImagoIDinMainMedia(contentType, props, editScreen);

		if (!checkIfStateContainsImagoID(image.id, imagoID) && !checkIfArrayContainsSameImagoID(imagoIsUploaded, image.id)) {
			await imagoHttp.downloadImagoImage(image.type, image.id).then(async (res: any) => {
				const payload = res.data;
				if (payload && payload.size && image && image.id) {
					form.append('file', payload, `${image.id}.jpeg`);

					// Post the imago image on the image API
					try {
						await HttpService.postImg('/upload', form, headers).then((res: any) => {
							const imagePath = res.data.path;
							imagePaths.push(imagePath);

							// Create the image on the Content API
							if (imagePaths && imagePaths.length > 0) {
								HttpService.all(constructRequestFromFilePath(image, imagePaths, projectOriginId[0].id, { Project: project.domain })).then(
									(res: any) => {
										//Set image as MainMedia
										let mainMedia = buildMainMediaFromImago(media, res, image);
										props.onMainImageMediaUpdate(mainMedia);
										setImagoIsUploaded(addImagoImageIDtoState(imagoIsUploaded, image, mainMedia));
										toast.success(t('imago_success'));
									},
								);
							}
						});
					} catch (err) {
						if (err.response) {
							if (err.response.status.toString() === '415') {
								toast.error(t('imago_upload_fail'));
								toast.info(t('imago_415_info'));
							} else {
								toast.error(t('imago_upload_fail'));
							}
						}
					}
				}
			});
		} else {
			if (checkIfArrayContainsSameImagoID(imagoIsUploaded, image.id)) {
				let reuseMainMedia = findImageForMainMedia(imagoIsUploaded, image.id);
				props.onMainImageMediaUpdate(reuseMainMedia);
				toast.success(t('imago_success'));
			} else {
				toast.error(t('image_update_failed'));
			}
		}
	};

	const onDescriptionChange = (event: ChangeEvent<HTMLInputElement>) => {
		const { media } = props;

		const mainMedia = MainMedia.builder(media)
			.withProvider('smp')
			.withResourceType(ContentTypes.IMAGE)
			.withDescription(event.target.value)
			.withResourceSubtype(media.resourceSubtype)
			.build();

		props.onMainImageMediaUpdate(mainMedia);
		setAddDescriptionToImage(addDescriptionToImage);
	};

	const onImageUpload = (imageId: string, autoCrop: boolean, image: Image) => {
		const { media } = props;

		const mainMedia = MainMedia.builder(media)
			.withResourceId(imageId)
			.withProvider('smp')
			.withResourceType(ContentTypes.IMAGE)
			.withResourceSubtype(media.resourceSubtype)
			.withData(image)
			.build();
		window.entityUpdateService.emitImagesUpdated();
		props.onMainImageMediaUpdate(mainMedia);
	};

	const onCropModalClose = () => {
		toggleCropModalState(false);
	};

	const toggleCropModalState = (display: boolean) => {
		setDisplayCropModal(display);
	};

	const isMediaObjEmpty = (media: MainMedia) => {
		return Object.entries(media).length < 1 || !media.resourceId;
	};

	const toggleImagoCollapse = () => {
		setImagoCollapseIsOpen(!imagoCollapseIsOpen);
	};

	const toggleAdditionalImagesModal = () => {
		setAdditionalImagesModalIsOpen(!additionalImagesModalIsOpen);
	};

	const toggleEditModal = (imageId: string, isOpen: boolean) => {
		const id = props.media.resourceId;
		setShowEditModal(isOpen);
		setImageId(id);
	};

	const { media, mediaErrorIndex } = props;

	return (
		<div id={DATA_QA_ATTRIBUTES.MAIN_MEDIA_ADDITIONAL_IMAGE_SELECT_COMPONENT}>
			<Row>
				<Col>
					<Label htmlFor={DATA_QA_ATTRIBUTES.MAIN_MEDIA_ADDITIONAL_IMAGE_SELECT_COMPONENT} className='font-weight-bold'>
						{t('choose_image')}
					</Label>
				</Col>
			</Row>
			<Row>
				<Col col='12' sm='12' md='6' className='mb-2'>
					<AsyncImageContainer
						isWatermarkAdded={false}
						isWatermarkRemoved={false}
						imageId={media && media.resourceId ? media.resourceId : ''}
						projectId={project.domain}
					/>
				</Col>
				<Col col='12' sm='12' md='6' className='mb-4'>
					<FormGroup>
						<Label htmlFor='image_description'>{t('image_description')}</Label>
						<DebounceInput
							name='image_description'
							id='image_description'
							element='textarea'
							rows='3'
							value={media && media.description ? media.description : ''}
							onChange={onDescriptionChange}
							className='form-control-lg form-control'
							placeholder={t('image_description_placeholder')}
							debounceTimeout={300}
							disabled={!media.resourceId}
						/>
						{media.description && media.description.length > 255 && (
							<label className='text-danger' htmlFor={`image-description-error-message-${media.resourceId}`}>
								{t('description_limit_exceed')}
							</label>
						)}
					</FormGroup>
				</Col>
			</Row>
			<Row className='mb-3'>
				<Col>
					<FormGroup className='mt-1'>
						<div className='container image-operations-container'>
							<Col className='image-upload-operations-container' col='4' lg='4' md='12' sm='14' xs='16'>
								<ImageUploadButtonContainer
									t={t}
									imageUploadButtonId='main-media-image-upload-button'
									project={project}
									blockStyle={true}
									onImageUploadSuccess={onImageUpload}
									display
									applyQuickWatermark={false}
								/>
							</Col>
							<ConditionalRenderWrapper expectedPermissions={['write_images']}>
								<Col className='image-operations-buttons' col='4' lg='4' md='12' sm='14' xs='16'>
									<Button
										id={`main-media-image-edit-btn-${imageId}`}
										disabled={isMediaObjEmpty(media)}
										block
										color='warning'
										className='edit-button'
										onClick={() => toggleEditModal(imageId, true)}
									>
										<i className='fa fa-pencil' /> {t('edit_image')}
									</Button>
								</Col>
							</ConditionalRenderWrapper>
							<Col className='image-operations-buttons' col='4' lg='4' md='12' sm='14' xs='16'>
								<Button
									className='crop-button'
									block
									color='secondary'
									disabled={isMediaObjEmpty(media)}
									onClick={() => {
										toggleCropModalState(true);
									}}
								>
									<i className={'fa fa-crop'} /> {t('crop_image')}
								</Button>
							</Col>
						</div>
					</FormGroup>
				</Col>
			</Row>
			<ErrorAsync t={t} errorType='main_media' mainMedia={`main_media.${mediaErrorIndex}.description`} />
			<Row>
				<Col>
					<Button
						className='m-0 p-0'
						color='link'
						onClick={toggleAdditionalImagesModal}
						aria-expanded={additionalImagesModalIsOpen}
						aria-controls='toggle-images-for-additional-main-media'
					>
						{t('toggle_uploaded_images')}
					</Button>
					<MainMediaAdditionalImageContentModal
						onSelect={onImageSelect}
						isOpen={additionalImagesModalIsOpen}
						toggleModal={toggleAdditionalImagesModal}
						project={project}
						contentType={ContentTypes.MAIN_MEDIA}
						selectedMainMediaImage={media}
					/>
				</Col>
			</Row>
			<Row>
				<Col>
					{imagoEnabled && (
						<Button
							className='m-0 p-0'
							color='link'
							onClick={toggleImagoCollapse}
							aria-expanded={imagoCollapseIsOpen}
							aria-controls='toggle-sidebar-images-list-for-main-media'
						>
							{t('toggle_imago')}
						</Button>
					)}
					<MainMediaContentModal
						t={t}
						onSelect={setImageAsMainMedia}
						isOpen={imagoCollapseIsOpen}
						toggleModal={toggleImagoCollapse}
						project={project}
						contentType={MainMediaTypes.IMAGO_IMAGE}
					/>
				</Col>
			</Row>
			<Row>
				<Col className={'text-center my-2'}>
					<hr />
				</Col>
			</Row>
			<ImageEditModal
				onClose={() => {
					toggleEditModal('', false);
				}}
				imageId={imageId}
				currentProject={project}
				open={showEditModal}
				watermarkEnabled={featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.WATERMARK)}
				t={t}
				isPlayingSurface={false}
				isImagoImage={false}
			/>
			<ImageCropContainer
				t={t}
				isWatermarkAdded={false}
				isWatermarkRemoved={false}
				imageId={media.resourceId}
				currentProject={project}
				open={displayCropModal}
				onClose={onCropModalClose}
			/>
		</div>
	);
};

function mapStateToProps(state: any) {
	return {
		projectOriginId: state.origins.imageOrigins,
	};
}

export default compose(connect(mapStateToProps))(MainMediaAdditionalImage);
