import React, { useEffect, useState } from 'react';
import * as ReactSortableHOC from 'react-sortable-hoc';
import { arrayMove, SortEnd } from 'react-sortable-hoc';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../store/store';
import './style/tags-rows-container.scss';
import { TagsList } from './tags-sortable.component';
import TagsReorderModal from './tags-modal/tags-reorder.modal.component';
import Tag from '../../../models/tag/Tag';
import { returnObjectForTagListingUpdate } from '../../../store/action-creators/TagActionCreators';
import { toast } from 'react-toastify';
import { Button } from 'reactstrap';
import HttpService from '../../../services/rest/HttpService';
import ModelMapper from '../../../models/ModelMapper';
import { ORDERED_TAGS_LIMIT } from './Helpers/TagHelper';

type ModalState = {
	isShown: boolean;
	data: { tags: Tag[]; shouldUpdate: boolean };
	tagChanged: boolean;
};

type RowsProps = {
	content: any[];
	onDelete: (id: string, title: string) => any;
	analytics: any;
	history: any;
	t: any;
	itemIndex?: number;
	searchInput: string;
	searchApplied: boolean;
	isTagDeleted: boolean;
};

const SortableRowsList = ReactSortableHOC.SortableContainer(TagsList);
const LOAD_LIMIT = 200;

export const TagsRowsContainer: React.FunctionComponent<RowsProps> = ({ content, onDelete, analytics, history, t, isTagDeleted }) => {
	// We keep modal in state prevState of tags and shouldUpdate prop because we need to visualise the tag new place
	// and return it if the user cancels the reordering action.
	const [showModal, toggleModal] = useState<ModalState>({
		isShown: false,
		data: { tags: [], shouldUpdate: false },
		tagChanged: false,
	});
	const dispatch = useDispatch();
	const [sortingIndex, setSortingIndex] = useState<any>({});
	const [totalOrderedTags, setTotalOrderedTags] = useState<Tag[]>([]);
	const [loading, setLoading] = useState<boolean>(false);

	let [currentPage, setCurrentPage] = useState(1);

	const project = useSelector((state: AppState) => state.project.currentProject);
	const tags = useSelector((state: AppState) => state.tag.tags);

	let [totalTagsNumber, setTotalTagsNumber] = useState<number>(0);
	const shouldSort = tags && tags.length > 1;

	// Currently, shown tags
	const [currentTags, setCurrentTags] = useState<Tag[]>([]);

	const [moreItemsAvailable, setMoreItemsAvailable] = useState(!(tags.contentPagination && tags.contentPagination.totalPages === 1));

	useEffect(() => {
		getAllTags().then((res: any) => {
			setTotalOrderedTags(res);
			setCurrentTags(res);
			setCorrectPage(res);
			dispatch(returnObjectForTagListingUpdate(res));
		});
	}, []);

	//If a tag is updated get the new updated list
	useEffect(() => {
		if (showModal.tagChanged) {
			setCurrentPage(1);
			setTotalOrderedTags([]);
			setCurrentTags([]);

			getAllTags().then((res: any) => {
				setTotalOrderedTags(res);
				setCurrentTags(res);
				setCorrectPage(res);
				dispatch(returnObjectForTagListingUpdate(res));
			});

			if (tags.contentPagination && tags.contentPagination.totalPages === 1) {
				setMoreItemsAvailable(false);
			} else {
				setMoreItemsAvailable(true);
			}
		}
	}, [showModal.tagChanged]);

	useEffect(() => {
		if (currentTags.length >= totalTagsNumber) {
			setMoreItemsAvailable(false);
		} else {
			setMoreItemsAvailable(true);
		}
	}, [currentTags]);

	useEffect(() => {
		if (isTagDeleted) {
			setCurrentPage(1);
			setTotalOrderedTags([]);
			setCurrentTags([]);
			getAllTags().then((res: any) => {
				setTotalOrderedTags(res);
				setCurrentTags(res);
				setCorrectPage(res);
				dispatch(returnObjectForTagListingUpdate(res));
			});
		}
	}, [isTagDeleted]);

	useEffect(() => {
		if (tags.contentPagination && tags.contentPagination.totalPages === 1) {
			setMoreItemsAvailable(false);
		} else if (tags.contentPagination && tags.contentPagination.totalPages > 1) {
			setMoreItemsAvailable(true);
		}
	}, [tags.contentPagination]);

	// Upon clicking the 'Load More' button
	useEffect(() => {
		setCurrentPage(currentPage);
	}, [currentPage]);

	useEffect(() => {
		// If showModal.data.shouldUpdate is true we update tags with previous state.
		if (showModal.data.shouldUpdate) {
			setCorrectPage(currentTags);
			dispatch(returnObjectForTagListingUpdate(currentTags));
		}
	}, [showModal.data.shouldUpdate]);

	const getAllTags = () => {
		let headers = { Project: project.domain };
		let fullListTags: Tag[] = [];
		let requestUrls: any[] = [];
		let currentPage = 1;
		let totalTags = 0;
		let requestLimit = 0;
		setLoading(true);
		return HttpService.get(`/tags?page=${currentPage}&limit=200`, null, headers).then((res: any) => {
			currentPage += 1;
			fullListTags.push(...ModelMapper.remapTagsFromResponse(res.data.data));
			totalTags = res.data.meta.pagination.total_pages;
			requestLimit = totalTags >= 5 ? 5 : totalTags;
			setLoading(false);
			for (let i = currentPage; i <= requestLimit; i++) {
				requestUrls.push(HttpService.get(`/tags?page=${i}&limit=200`, null, headers));
			}

			return Promise.all(requestUrls).then((response) => {
				response.forEach((itemsPerPage) => {
					setTotalTagsNumber(itemsPerPage.data.meta.pagination.total);
					fullListTags.push(...ModelMapper.remapTagsFromResponse(itemsPerPage.data.data));
					setLoading(false);
				});
				return fullListTags;
			});
		});
	};

	const setCorrectPage = (tags: any) => {
		if (tags.length >= 1000) {
			setCurrentPage(6);
		}
	};

	const onLoadMore = () => {
		const headers = { Project: project.domain };
		// The first if is the logic after the first 1000 tags
		if (currentTags.length >= 1000 && totalTagsNumber > 1000) {
			let tagsCopy = [...currentTags];
			HttpService.get(`/tags?page=${currentPage}&limit=200`, null, headers).then((response: any) => {
				response.data.data.length < LOAD_LIMIT ? setMoreItemsAvailable(false) : setMoreItemsAvailable(true);
				let newTags = response.data.data.map((tag: any) => {
					return ModelMapper.remapTagFromResponse(tag);
				});
				tagsCopy.push(...newTags);
				setCurrentTags([...tagsCopy]);
				if (tagsCopy.length === 1000) {
					setCorrectPage(tagsCopy);
				} else {
					setCurrentPage(currentPage + 1);
				}
				dispatch(returnObjectForTagListingUpdate(tagsCopy));
			});
		}
	};

	const onSortEnd = (sortEnd: SortEnd) => {
		if (shouldSort && sortEnd.oldIndex !== sortEnd.newIndex && sortEnd.newIndex < ORDERED_TAGS_LIMIT) {
			// onSortEnd we update lists state with new item positions and open modal to confirm the operation.
			// If the user cancels the operation we update the state with its previous value kept in *showModal.data.lists
			totalTagsNumber >= 200
				? toggleModal({
						isShown: true,
						data: { tags: [...totalOrderedTags], shouldUpdate: false },
						tagChanged: false,
				  })
				: toggleModal({
						isShown: true,
						data: { tags: [...tags], shouldUpdate: false },
						tagChanged: false,
				  });

			// We pass sorting indexes in modal component to use them for eventually patch list update.
			setSortingIndex(sortEnd);
			const tagsCopy = totalTagsNumber >= 200 ? [...totalOrderedTags] : [...tags];
			const newTags = arrayMove(tagsCopy, sortEnd.oldIndex, sortEnd.newIndex);
			dispatch(returnObjectForTagListingUpdate(newTags));
		}
	};

	const updateTagsFromInput = (newIndex: number, oldIndex: number) => {
		if (shouldSort && newIndex !== oldIndex) {
			if (newIndex - 1 > totalTagsNumber || isNaN(newIndex) || newIndex > ORDERED_TAGS_LIMIT) {
				toast.error(t('index_out_of_bounds'));
			} else {
				totalTagsNumber >= 200
					? toggleModal({
							isShown: true,
							data: { tags: [...totalOrderedTags], shouldUpdate: false },
							tagChanged: false,
					  })
					: toggleModal({
							isShown: true,
							data: { tags: [...tags], shouldUpdate: false },
							tagChanged: false,
					  });
				setSortingIndex({ oldIndex: oldIndex, newIndex: newIndex });
				const tagsCopy = totalTagsNumber >= 200 ? [...totalOrderedTags] : [...tags];
				const newTags = arrayMove(tagsCopy, oldIndex, newIndex);
				dispatch(returnObjectForTagListingUpdate(newTags));
			}
		}
	};
	if (content && content.length > 0) {
		return (
			<tr className='w-100 p-0 d-flex bg-white sortable-list-table-row'>
				<td className='w-100 p-0'>
					<SortableRowsList
						t={t}
						onSortEnd={onSortEnd}
						useDragHandle
						content={content}
						onDelete={onDelete}
						analytics={analytics}
						history={history}
						updateTagsFromInput={updateTagsFromInput}
						loading={loading}
					/>
					{showModal.isShown && (
						<TagsReorderModal currentPage={currentPage} t={t} showModal={showModal} sortEnd={sortingIndex} toggleModal={toggleModal} />
					)}
					{moreItemsAvailable && currentTags.length !== totalTagsNumber ? (
						<div className='d-flex justify-content-center'>
							<Button id='load-more-tags-button' color='primary' onClick={() => onLoadMore()} className='w-100 mt-3 mb-2'>
								{t('load_more')}
							</Button>
						</div>
					) : (
						<div className='d-flex justify-content-center'>
							<label id='no-more-tags-label' className='w-100 mt-3 mb-2'>
								<strong>{t('no_more_tags_available')}</strong>
							</label>
						</div>
					)}
				</td>
			</tr>
		);
	}
	return <tr />;
};
