import {
	REQUEST_ARTICLE_TYPES_RECEIVED,
	REQUEST_GALLERY_TYPES_RECEIVED,
	REQUEST_IMAGE_TYPES_RECEIVED,
	REQUEST_LIST_TYPES_RECEIVED,
	REQUEST_TAG_TYPES_RECEIVED,
	REQUEST_VIDEO_TYPES_RECEIVED,
	REQUEST_WIKI_PAGES_TYPES_RECEIVED,
} from '../action-creators/types-action.creator';
import { remapContentItemsFromResponse } from '../../views/Partials/Sidebar/GeneralContentAttributes/subcomponents/type-select/helpers/type-select.helper';

export function typesMiddleware() {
	return function (next: any) {
		return function (action: any) {
			if (action) {
				if (action.type === REQUEST_ARTICLE_TYPES_RECEIVED) {
					action.payload = action.payload.data.data.map((item: any) => remapContentItemsFromResponse(item));
				}

				if (action.type === REQUEST_VIDEO_TYPES_RECEIVED) {
					action.payload = action.payload.data.data.map((item: any) => remapContentItemsFromResponse(item));
				}

				if (action.type === REQUEST_IMAGE_TYPES_RECEIVED) {
					action.payload = action.payload.data.data.map((item: any) => remapContentItemsFromResponse(item));
				}

				if (action.type === REQUEST_GALLERY_TYPES_RECEIVED) {
					action.payload = action.payload.data.data.map((item: any) => remapContentItemsFromResponse(item));
				}

				if (action.type === REQUEST_TAG_TYPES_RECEIVED) {
					action.payload = action.payload.data.data.map((item: any) => remapContentItemsFromResponse(item));
				}

				if (action.type === REQUEST_LIST_TYPES_RECEIVED) {
					action.payload = action.payload.data.data.map((item: any) => remapContentItemsFromResponse(item));
				}

				if (action.type === REQUEST_WIKI_PAGES_TYPES_RECEIVED) {
					action.payload = action.payload.data.data.map((item: any) => remapContentItemsFromResponse(item));
				}
			}
			next(action);
		};
	};
}
