import Project from '../../models/project/Project';
import ErrorResponseModel from '../../models/error-response/ErrorResponseModel';
import TeamManualDataModel from '../../models/team-manual-data/team-manual-data.model';
import AssetsModel from '../../models/team-manual-data/assets/assets';

export const TEAM_LISTING_REQUEST = '[ACTION] TEAM_LISTING_REQUEST';
export const TEAM_LISTING_REQUEST_WITH_MANUAL_DATA_FILTER = '[ACTION] TEAM_LISTING_REQUEST_WITH_MANUAL_DATA_FILTER';
export const TEAM_LISTING_WITH_MANUAL_DATA_FILTER_RECEIVED = '[ACTION] TEAM_LISTING_REQUEST_WITH_MANUAL_DATA_FILTER';
export const TEAM_LISTING_RECEIVED = '[ACTION] TEAM_LISTING_RECEIVED';
export const TEAM_LISTING_FAILED = '[ACTION] TEAM_LISTING_FAILED';

export const TEAM_ENTITY_REQUEST = '[ACTION] TEAM_ENTITY_REQUEST';
export const TEAM_ENTITY_RECEIVED = '[ACTION] TEAM_ENTITY_RECEIVED';
export const TEAM_ENTITY_FAILED = '[ACTION] TEAM_ENTITY_FAILED';

export const TEAM_SEARCH = '[ACTION] TEAM_SEARCH';
export const TEAM_SEARCH_FILTER_UPDATE = '[ACTION] TEAM_SEARCH_FILTER_UPDATE';
export const TEAM_SEARCH_FAILED = '[ACTION] TEAM_SEARCH_FAILED';

export const TEAM_ENTITY_CREATE = '[ACTION] TEAM_ENTITY_CREATE';
export const TEAM_ENTITY_CREATE_SUCCESS = '[ACTION] TEAM_ENTITY_CREATE_SUCCESS';
export const TEAM_ENTITY_CREATE_FAILED = '[ACTION] TEAM_ENTITY_CREATE_FAILED';

export const TEAM_ENTITY_UPDATE = '[ACTION] TEAM_ENTITY_UPDATE';
export const TEAM_ENTITY_UPDATE_FAILED = '[ACTION] TEAM_ENTITY_UPDATE_FAILED';
export const TEAM_ENTITY_UPDATE_SUCCESS = '[ACTION] TEAM_ENTITY_UPDATE_SUCCESS';

export const TEAM_ENTITY_POST_ASSETS = '[ACTION] TEAM_ENTITY_POST_ASSETS';
export const TEAM_ENTITY_POST_ASSETS_FAILED = '[ACTION] TEAM_ENTITY_POST_ASSETS_FAILED';
export const TEAM_ENTITY_POST_ASSETS_SUCCESS = '[ACTION] TEAM_ENTITY_POST_ASSETS_SUCCESS';

export const SEARCH_TEAM_LISTING_RECEIVED = '[ACTION] SEARCH_TEAM_LISTING_RECEIVED';

export const TEAM_BLACKLIST_REQUEST = '[ACTION] TEAM_BLACKLIST_REQUEST';
export const TEAM_BLACKLIST_RECEIVED = '[ACTION] TEAM_BLACKLIST_RECEIVED';

export function returnObjectForTeamListingRequest(page: string, project: Project) {
	return {
		type: TEAM_LISTING_REQUEST,
		payload: { page, project },
	};
}

export function returnObjectForTeamListingWithManualDataFilterRequest(teamsToFilter: string[], project: Project) {
	return {
		type: TEAM_LISTING_REQUEST_WITH_MANUAL_DATA_FILTER,
		payload: { teamsToFilter, project },
	};
}

export function returnObjectForTeamListingReceived(teams: any) {
	return {
		type: TEAM_LISTING_RECEIVED,
		payload: teams,
	};
}

export function returnObjectForTeamListingWithManualDataFilterReceived(teams: any) {
	return {
		type: TEAM_LISTING_WITH_MANUAL_DATA_FILTER_RECEIVED,
		payload: teams,
	};
}

export function returnObjectForTeamBlacklistReceived(teams: any) {
	return {
		type: TEAM_BLACKLIST_RECEIVED,
		payload: teams,
	};
}

export function returnObjectForTeamListingFailed(error: any) {
	return {
		type: TEAM_LISTING_FAILED,
		payload: ErrorResponseModel.builder().fromResponse(error).build(),
	};
}

export function returnObjectForTeamEntityCreate(team: TeamManualDataModel, project: Project, assets: AssetsModel) {
	return {
		type: TEAM_ENTITY_CREATE,
		payload: { team, project, assets },
	};
}

export function returnObjectForTeamEntityCreateSuccess(id: string) {
	return {
		type: TEAM_ENTITY_CREATE_SUCCESS,
		payload: id,
	};
}

export function returnObjectForTeamEntityUpdate(team: TeamManualDataModel, project: Project, assets: AssetsModel) {
	return {
		type: TEAM_ENTITY_UPDATE,
		payload: { team, project, assets },
	};
}

export function returnObjectForTeamEntityUpdateSuccess() {
	return {
		type: TEAM_ENTITY_UPDATE_SUCCESS,
	};
}

export function returnObjectForTeamEntityUpdateFailed() {
	return {
		type: TEAM_ENTITY_UPDATE_FAILED,
	};
}

export function returnObjectForTeamEntityCreateFailed() {
	return {
		type: TEAM_ENTITY_CREATE_FAILED,
	};
}

export function returnObjectForTeamEntityRequest(id: string, project: Project) {
	return {
		type: TEAM_ENTITY_REQUEST,
		payload: { id, project },
	};
}

export function returnObjectForTeamEntityReceived(team: any) {
	return {
		type: TEAM_ENTITY_RECEIVED,
		payload: team,
	};
}

export function returnObjectForTeamSearch(text: string, lang: string, type: string) {
	return {
		type: TEAM_SEARCH,
		payload: { text, lang, type },
	};
}

export function returnObjectForTeamSearchFailed(error: any) {
	return {
		type: TEAM_SEARCH_FAILED,
		payload: ErrorResponseModel.builder().fromResponse(error).build(),
	};
}

export function returnObjectForSearchTeamListingReceived(teams: any) {
	return {
		type: SEARCH_TEAM_LISTING_RECEIVED,
		payload: teams,
	};
}

export function postTeamAssets(payload: { teamId: string; project: Project; assets: AssetsModel }) {
	return {
		type: TEAM_ENTITY_POST_ASSETS,
		payload: payload,
	};
}

export function teamAssetsPostSuccess() {
	return {
		type: TEAM_ENTITY_POST_ASSETS_SUCCESS,
	};
}

export function teamAssetsPostFailed() {
	return {
		type: TEAM_ENTITY_POST_ASSETS_FAILED,
	};
}

export function teamSearchFilterUpdate(text: string) {
	return {
		type: TEAM_SEARCH_FILTER_UPDATE,
		payload: text,
	};
}
