import React, { FunctionComponent } from 'react';
import { featuresService } from '../../../../../../App';
import { FeatureTypes } from '../../../../../../services/feature-service/features.enum';
import { extractContentValueByTheGivenKey } from '../../helpers/general.helper';

type Properties = {
	listItemData: any;
	t: any;
};

const ContentItemStatisticsComponent: FunctionComponent<Properties> = ({ t, listItemData }) => {
	const contentStatisticsConfig = featuresService.getFeatureConfig(FeatureTypes.CONTENT_STATISTICS);

	if (!featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.CONTENT_STATISTICS) || !contentStatisticsConfig.mappings) {
		return null;
	}

	return contentStatisticsConfig.mappings.map((statistic: any) => {
		return (
			<div key={statistic.display_key} className='pr-2 pt-1 d-flex'>
				<p className='pr-1 mb-0'>{`${t(statistic.display_key)}: `}</p>
				<strong>{extractContentValueByTheGivenKey(listItemData, statistic)}</strong>
			</div>
		);
	});
};

export default ContentItemStatisticsComponent;
