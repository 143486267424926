import { call, put, takeEvery } from 'redux-saga/effects';
import HttpService from '../../services/rest/HttpService';
import {
	AUTHOR_ENTITY_CREATE,
	AUTHOR_SEARCH,
	AUTHOR_ENTITY_REQUEST,
	AUTHOR_ENTITY_DELETE,
	AUTHOR_ENTITY_UPDATE,
	returnObjectForAuthorEntityDeleteSuccess,
	returnObjectForAuthorEntityCreateSuccess,
	returnObjectForAuthorEntityUpdateSuccess,
	returnObjectForAuthorEntityReceived,
	returnObjectForAuthorListingReceived,
	AUTHOR_LISTING_REQUEST,
	returnObjectForAuthorEntityCreateFailed,
	returnObjectForAuthorEntityUpdateFailed,
	returnObjectForProjectAuthorListingReceived,
} from '../action-creators/AuthorActionCreators';
import { toggleLoadingState, toggleModal, searchIsApplied } from '../action-creators/UiActionCreator';
import { onError } from '../action-creators/GeneralActions';
import { actionService } from '../../App';

function* fetchAuthorListingFromApi(action: any) {
	yield put(toggleLoadingState(true));

	try {
		let headers = { Project: action.payload.project.domain };
		const constructURL = action.payload.text && action.payload.text.length > 0 ? `/search?query=${action.payload.text}&` : '?';
		const authors = yield call(HttpService.get, `/authors${constructURL}page=${action.payload.page}&limit=200`, null, headers);
		yield put(returnObjectForAuthorListingReceived(authors));
		yield put(toggleLoadingState(false));
	} catch (error) {
		yield put(onError(error));
	}
}

function* searchAuthorFromApi(action: any) {
	yield put(toggleLoadingState(true));

	try {
		let headers = { Project: action.payload.project.domain };
		const authors = yield call(HttpService.get, `/authors/search?query=${action.payload.text}`, null, headers);
		yield put(returnObjectForAuthorListingReceived(authors));
		yield put(toggleLoadingState(false));
		yield put(searchIsApplied());
	} catch (error) {
		yield put(onError(error));
	}
}

function* fetchAuthorEntityFromApi(action: any) {
	try {
		let headers = { Project: action.payload.project.domain };
		const author = yield call(HttpService.get, `/authors/${action.payload.id}`, null, headers);
		yield put(returnObjectForAuthorEntityReceived(author));
	} catch (error) {
		yield put(onError(error));
	}
}

function* postAuthorEntityToApi(action: any) {
	let headers = { Project: action.payload.project.domain };
	let authorResponse: any = {};

	try {
		let authorPayload = action.payload.author;
		authorResponse = yield call(HttpService.post, '/authors', authorPayload, headers);
		yield put(returnObjectForAuthorEntityCreateSuccess(authorResponse.data.data.id));
	} catch (error) {
		yield put(returnObjectForAuthorEntityCreateFailed());
		yield put(onError(error));
		actionService.emitError(error);
	}
}

function* patchAuthorEntityToApi(action: any) {
	let headers = { Project: action.payload.project.domain };

	try {
		let authorPayload = action.payload.author;
		let id = action.payload.author.id;
		yield call(HttpService.patch, `/authors/${id}`, authorPayload, headers);
		yield put(returnObjectForAuthorEntityUpdateSuccess());
	} catch (error) {
		yield put(returnObjectForAuthorEntityUpdateFailed());
		yield put(onError(error));
		actionService.emitError(error);
	}
}

function* deleteAuthorEntityFromApi(action: any) {
	try {
		let headers = { Project: action.payload.project.domain };
		let id = action.payload.id;
		yield call(HttpService.delete, `/authors/${id}`, null, headers);
		yield put(returnObjectForAuthorEntityDeleteSuccess());
		yield put(toggleModal(false));
	} catch (error) {
		yield put(onError(error));
	}
}

function* fetchProjectAuthors(action: any) {
	try {
		let headers = { Project: action.payload.project.domain };
		let allAuthors = yield call(HttpService.getAllPages, `/authors`, null, headers, 200);
		let tempAuthors: any[] = [];
		allAuthors = allAuthors.map((item: any) => item.data.data);
		allAuthors.forEach((item: any[]) => tempAuthors.push(...item));

		yield put(returnObjectForProjectAuthorListingReceived(tempAuthors));
	} catch (error) {
		onError(error);
	}
}

function* authorSaga() {
	yield takeEvery(AUTHOR_LISTING_REQUEST, fetchAuthorListingFromApi);
	yield takeEvery(AUTHOR_SEARCH, searchAuthorFromApi);
	yield takeEvery(AUTHOR_ENTITY_REQUEST, fetchAuthorEntityFromApi);
	yield takeEvery(AUTHOR_ENTITY_CREATE, postAuthorEntityToApi);
	yield takeEvery(AUTHOR_ENTITY_CREATE, fetchProjectAuthors);
	yield takeEvery(AUTHOR_ENTITY_UPDATE, patchAuthorEntityToApi);
	yield takeEvery(AUTHOR_ENTITY_UPDATE, fetchProjectAuthors);
	yield takeEvery(AUTHOR_ENTITY_DELETE, deleteAuthorEntityFromApi);
	yield takeEvery(AUTHOR_ENTITY_DELETE, fetchProjectAuthors);
}

export default authorSaga;
