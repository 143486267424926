import React from 'react';
import BlockModel from '../../../../models/block.model';
import { ViewTypes } from '../../../../constants/general.constants';
import InstatTeamComparisonBlockEdit from './instat-team-comparison-block-edit.component';
import BlockUpdateController from '../../../block-update-controller.component';

type Properties = {
	block: BlockModel;
	view: ViewTypes;
	t: any;
};

const InstatTeamComparisonBlock: React.FunctionComponent<Properties> = (props) => {
	const { view, t, block } = props;

	if (view === ViewTypes.edit) {
		return <InstatTeamComparisonBlockEdit block={block} t={t} />;
	} else if (view === ViewTypes.normal) {
		const team1Name = block.data.team1 && block.data.team1.info ? block.data.team1.info.name : null;
		const team2Name = block.data.team2 && block.data.team2.info ? block.data.team2.info.name : null;

		if (team1Name && team2Name) {
			return (
				<div className='px-4 d-flex flex-row'>
					<h5 className='mr-3 mb-0'>
						<strong>{block.data.team1.info.name}</strong>
					</h5>
					<strong className='mr-3 d-flex flex-column justify-content-center align-items-center'>vs</strong>
					<h5 className='mb-0'>
						<strong>{block.data.team2.info.name}</strong>
					</h5>
				</div>
			);
		} else {
			return null;
		}
	}

	return null;
};

export default BlockUpdateController(InstatTeamComparisonBlock);
