import 'reflect-metadata';
import { jsonMember, jsonObject } from 'typedjson';

@jsonObject
export default class Translation {
	@jsonMember(String)
	public name: string = '';

	@jsonMember(String)
	public language: string = '';
}
