import Related from '../../../../../models/related/Related';
import { appendJWFilter } from '../../../../Resources/Videos/Helpers/VideoHelper';
import {
	extractPlayerIds,
	extractTeamIds,
} from '../../../Sidebar/related-content/subcomponents/helpers/content-by-suggestions-container.helper';
import AdvancedFilterModel from '../models/advanced-content-filter.model';

// Check if the selected entity is tournament or competition and sets the correct query parameter in the request
export const setCorrectQueryParameter = (entity: any, id: string) => {
	if (entity && entity === 'tournament') {
		return `&tournamentIds=${id}`;
	} else {
		return `&competitionIds=${id}`;
	}
};

export const extractTypeNames = (data: any) => {
	let typeNames: string[] = [];

	if (data && data.length > 0) {
		typeNames = data.map((item: any) => item.value);
	}

	return typeNames;
};

export const constructSearchContentQuery = (data: any) => {
	if (data) {
		const teamIds = extractTeamIds(data.relatedSports);
		const playerIds = extractPlayerIds(data.relatedSports);
		const tagIds = extractTagIds(data.relatedTags);
		const articleTypes = extractTypeNames(data.type);
		const teamIdsQuery = teamIds.length > 0 ? `&teamIds=${teamIds}` : '';
		const playerIdsQuery = playerIds.length > 0 ? `&playerIds=${playerIds}` : '';
		const tagIdsQuery = tagIds.length > 0 ? `&tagIds=${tagIds}` : '';
		const category = data.category && data.category.id && data.category.id.length > 0 ? `&category=${data.category.id}` : '';
		const from = data.date && data.date.length > 0 ? `&from=${data.date[0]}` : '';
		const to = data.date && data.date.length > 0 ? `&to=${data.date[1]}` : '';
		const createdBy = data.createdBy && data.createdBy.id && data.createdBy.id.length > 0 ? `&createdBy=${data.createdBy.id}` : '';
		const imageOrigin = data.imageOrigin && data.imageOrigin.id && data.imageOrigin.id.length > 0 ? `&originId=${data.imageOrigin.id}` : '';
		const videoOrigin = data.videoOrigin && data.videoOrigin.id && data.videoOrigin.id.length > 0 ? `&originId=${data.videoOrigin.id}` : '';
		const tournamentOrCompetitionId =
			data.tournament && data.tournament.data ? setCorrectQueryParameter(data.tournament.type, data.tournament.data.id) : '';
		const language = data.language && data.language.language ? `&language=${data.language.language.languageCode}` : '';
		const type = articleTypes.length > 0 ? `&type=${articleTypes}` : '';
		const status = data.status && data.status.length > 0 ? `&status=${data.status}` : '';
		const properties =
			(data.properties &&
				data.properties.length > 0 &&
				[...data.properties].map((propertySelected) => `&${propertySelected}=true`).join('')) ||
			'';

		return (
			teamIdsQuery +
			playerIdsQuery +
			tagIdsQuery +
			category +
			from +
			to +
			createdBy +
			imageOrigin +
			videoOrigin +
			tournamentOrCompetitionId +
			language +
			type +
			status +
			properties
		);
	}

	return '';
};

export const extractTagIds = (relatedContent: Related[]) => {
	let tagIds: string[] = [];

	if (relatedContent && relatedContent.length > 0) {
		tagIds = relatedContent.map((related: Related) => related.data.id);
	}

	return tagIds;
};

export const checkIfTheFiltersAreEmpty = (model: AdvancedFilterModel) => {
	if (model && Object.entries(model).length > 0) {
		const isCategoryEmpty = !model.category || (model.category && Object.entries(model.category).length === 0);
		const isCreatedByEmpty = !model.createdBy || (model.createdBy && Object.entries(model.createdBy).length === 0);
		const isDateEmpty = !model.date || (model.date && model.date.length === 0);
		const isRelatedSportsEmpty = !model.relatedSports || (model.relatedSports && model.relatedSports.length === 0);
		const isRelatedTagsEmpty = !model.relatedTags || (model.relatedTags && model.relatedTags.length === 0);
		const isTournamentEmpty = !model.tournament || (model.tournament && !model.tournament.type);
		const isImageOriginEmpty = !model.imageOrigin || (model.imageOrigin && Object.entries(model.imageOrigin).length === 0);
		const isVideoOriginEmpty = !model.videoOrigin || (model.videoOrigin && Object.entries(model.videoOrigin).length === 0);
		const isLanguageEmpty = !model.language || (model.language && Object.entries(model.language).length === 0);
		const isSportsTypeEmpty = !model.sportsType || (model.sportsType && model.sportsType.length === 0);
		const isTypeEmpty = !model.type || (model.type && model.type.length === 0);
		const arePropertiesEmpty = !model.properties || (model.properties && model.properties.length === 0);

		return (
			isCategoryEmpty &&
			isCreatedByEmpty &&
			isDateEmpty &&
			isRelatedSportsEmpty &&
			isRelatedTagsEmpty &&
			isImageOriginEmpty &&
			isVideoOriginEmpty &&
			isTournamentEmpty &&
			isLanguageEmpty &&
			isSportsTypeEmpty &&
			isTypeEmpty &&
			arePropertiesEmpty
		);
	}

	return true;
};

export const extractSearchQueryFromFilters = (filters: AdvancedFilterModel) => {
	let query: string = '';

	if (filters && Object.entries(filters).length > 0) {
		const searchText = filters.searchText && filters.searchText.length > 0 ? filters.searchText : '*';
		const advancedContentSearchText = constructSearchContentQuery(filters);

		query = searchText + advancedContentSearchText;
	}

	return query;
};
