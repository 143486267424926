import React, { useEffect, useState } from 'react';
import Image from '../../../../../../../models/image/Image';
import { CardHeader, Col, FormGroup, Label, Row, UncontrolledCollapse } from 'reactstrap';
import { Properties } from './properties/image-select.properties';
import { ImageProperties } from '../../../../partials/image-properties/image-properties.component';
import ImageListingContainer from '../../../../partials/image-listing/image-listing-container.component';
import ImageBlockModel from '../models/image-block.model';
import './image-select.component.scss';

const ImageSelect: React.FunctionComponent<Properties> = ({ blockData, blockId, t, onChange, domain, authors, owners }) => {
	const [isOpen, setIsOpen] = useState(false);
	const [watermarkChanged, setWatermarkChanged] = useState(false);

	useEffect(() => {
		if (blockData.image.id) {
			setIsOpen(true);
		} else {
			setIsOpen(false);
		}
	}, []);

	// Check if any of the checkboxes is checked and flag that the image watermark is changed so the Image Listing is reloaded with the new images
	useEffect(() => {
		setWatermarkChanged(!watermarkChanged);
	}, [blockData.addWatermarkChecked, blockData.removeWatermarkChecked]);

	const updateImage = (image: Image) => {
		onChange(
			ImageBlockModel.builder(blockData)
				.withDescription(image.description ? image.description : null)
				.withImage(image)
				.withAuthors(image.authors ? image.authors : [])
				.withImageRatio(blockData.imageRatio && blockData.imageRatio.label ? blockData.imageRatio : [])
				.withAlt(image.alt ? image.alt : '')
				.withCaption(image.caption ? image.caption : '')
				.withRemoveWatermarkChecked(false)
				.withAddWatermarkChecked(false)
				.build(),
		);
		setIsOpen(true);
	};

	const toggle = () => {
		setIsOpen(!isOpen);
	};

	return (
		<>
			<Row>
				<Col>
					<FormGroup>
						<Label>{t('choose_image')}:</Label>
						<ImageListingContainer
							domain={domain}
							watermarkChanged={watermarkChanged}
							t={t}
							selectedImageId={blockData.image ? blockData.image.id : ''}
							onImageSelect={updateImage}
						/>
					</FormGroup>
				</Col>
			</Row>
			<Row>
				<Col>
					<CardHeader className='cursor-pointer d-flex align-items-center' id='togglerImageProperties' onClick={toggle}>
						<strong>{t('image_properties')}</strong>
						<div className='image-properties-toggler card-header-actions'>
							{isOpen ? <i className='fa fa-toggle-on' /> : <i className='fa fa-toggle-off' />}
						</div>
					</CardHeader>
				</Col>
			</Row>
			<UncontrolledCollapse isOpen={isOpen} toggler='#togglerImageProperties' className='p-3'>
				<ImageProperties
					t={t}
					isImageBlock
					blockId={blockId}
					blockData={blockData}
					authors={authors}
					onChange={onChange}
					contentType='image'
					displayDescriptionCheckbox={true}
					owners={owners}
					hideWatermarkCheckbox={!(blockData.image && blockData.image.id)}
				/>
			</UncontrolledCollapse>
		</>
	);
};

export default ImageSelect;
