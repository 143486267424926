import React, { FunctionComponent, ChangeEvent } from 'react';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { DATA_QA_ATTRIBUTES } from '../../constants/data-qa.attributes';
import { ReduxURLsProps } from '../../constants/redux-seo.attributes';
import { autoGenerateReduxPropsNamingForContentModels } from '../../../../../../services/content-models-service/ContentModelService';
import { connect } from 'react-redux';

type Properties = {
	t: any;
	seoFollowing: boolean;
	onSeoChange: (data: boolean, propertyName: ReduxURLsProps) => void;
};

const SeoFollowingCheckbox: FunctionComponent<Properties> = ({ t, seoFollowing, onSeoChange }) => {
	const onFollowingChange = (event: ChangeEvent<HTMLInputElement>) => {
		const checked = event.target.checked || false;
		onSeoChange(checked, ReduxURLsProps.FOLLOW);
	};

	return (
		<Row>
			<Col>
				<FormGroup check className='radio'>
					<Input
						id={DATA_QA_ATTRIBUTES.FOLLOWING}
						className='form-check-input'
						type='checkbox'
						name='radios'
						value={ReduxURLsProps.FOLLOW}
						onChange={onFollowingChange}
						checked={seoFollowing}
					/>
					<Label check className='form-check-label' for={DATA_QA_ATTRIBUTES.FOLLOWING}>
						{t('allow_following')}
					</Label>
				</FormGroup>
			</Col>
		</Row>
	);
};

function mapStateToProps(state: any) {
	const { tempPropertyNaming, nestedTempPropertyNaming } = autoGenerateReduxPropsNamingForContentModels();

	return {
		seoFollowing:
			(state[tempPropertyNaming][nestedTempPropertyNaming].seo && !!state[tempPropertyNaming][nestedTempPropertyNaming].seo.follow) || false,
	};
}

export default connect(mapStateToProps)(SeoFollowingCheckbox);
