import React, { useEffect, useState } from 'react';
import { Button, Col, Row } from 'reactstrap';
import EnumItem from '../../../../models/enum/EnumItem';
import HttpService from '../../../../services/rest/HttpService';
import SeasonModel from '../../../Partials/Blocky/blocks/widgets/player-profile/models/season/season.model';
import { remapTeamsFromResponce } from '../../../Partials/Blocky/partials/team-select/helpers/team-select.helper';
import TeamModel from '../../../Partials/Blocky/partials/team-select/models/team.model';
import EditSeasonTeamsModal from './modal/edit-season-teams.modal';

type Properties = {
	t: any;
	selectedSeason: SeasonModel;
	searchLanguage: EnumItem;
	translationLanguage: EnumItem;
};

const SeasonTeamsComponent: React.FunctionComponent<Properties> = (props) => {
	const [isContentLoading, updateloading] = useState<boolean>(true);
	const [teamsForSeason, updateTeams] = useState<TeamModel[]>([]);
	const [isOpenModal, toggleModal] = useState<boolean>(false);
	const [teamForEdit, updateTeamForEdit] = useState<TeamModel>(TeamModel.builder().build());

	const { t, selectedSeason, searchLanguage, translationLanguage } = props;

	useEffect(() => {
		getTeamsForSeason();
	}, [selectedSeason.id]);

	const getTeamsForSeason = () => {
		HttpService.getTeamsBySeasonId(selectedSeason.id, searchLanguage.id)
			.then((response: any) => {
				const teamsForSeason = remapTeamsFromResponce(response.data);
				updateTeams(teamsForSeason);
				updateloading(false);
			})
			.catch((e: any) => updateloading(false));
	};

	const onEditTeam = (team: TeamModel) => {
		updateTeamForEdit(team);
		toggleModal(true);
	};

	return (
		<div className={`${isContentLoading ? 'loading-overlay' : ''}`}>
			{teamsForSeason && teamsForSeason.length > 0 ? (
				teamsForSeason.map((team: TeamModel, index: number) => {
					return (
						<Row
							key={team.id}
							id={`team-row-${team.id}`}
							className={`py-2 border-bottom align-items-center w-100 ${index % 2 === 1 ? '' : 'bg-light'} m-0`}
						>
							<Col xs={10}>
								<div className='text-dark btn btn-link cursor-pointer' onClick={() => onEditTeam(team)}>
									<span>{team.name}</span>
								</div>
							</Col>
							<Col xs={2} className='text-right'>
								<Button id={`team-teanslate-btn-${team.id}`} className='shadow-none' color='info' onClick={() => onEditTeam(team)}>
									{t('translate')}
								</Button>
							</Col>
						</Row>
					);
				})
			) : (
				<div className='p-4 text-center'>{t('no_teams_for_this_season')}</div>
			)}
			{isOpenModal && (
				<EditSeasonTeamsModal
					t={t}
					open={isOpenModal}
					onClose={() => toggleModal(false)}
					team={teamForEdit}
					searchLanguage={searchLanguage}
					languageToTranslate={translationLanguage}
				/>
			)}
		</div>
	);
};

export default SeasonTeamsComponent;
