import { GeneralContentModel } from '../../../Partials/Sidebar/GeneralContentAttributes/models/GeneralContentModel';
import Video from '../../../../models/video/Video';
import { UrlsComponentModel } from '../../../Partials/Sidebar/Urls/models/UrlsComponentModel';
import Urls from '../../../../models/urls/Urls';
import Seo from '../../../../models/seo/Seo';
import { SeoComponentModel } from '../../../Partials/Sidebar/Seo/models/SeoComponentModel';
import Related from '../../../../models/related/Related';
import { RelatedContentModel } from '../../../Partials/Sidebar/related-content/models/related-content.model';
import Author from '../../../../models/author/Author';
import { TagsModel } from '../../../Partials/Sidebar/tags/models/tags.model';
import Global from '../../../Partials/Blocky/helpers/global.helpers';
import ContentAttributes from '../../../../models/content-attributes/content-attributes-model';
import { getCurrentUserUrl } from '../../../../global-helpers/global.helpers';
import { store } from '../../../../store/store';
import { ContentTypes } from '../../../../constants/content-types';

// Related
export function relatedContentsToTagsModel(
	relatedSports: Related[],
	relatedTags: Related[],
	relatedMatches: Related[],
	tags: any[],
): TagsModel {
	return {
		relatedSports,
		relatedTags,
		relatedMatches,
		linkedTags: tags,
	};
}

export function relatedContentsToRelatedContentModel(
	relatedSports: Related[],
	relatedContent: Related[],
	relatedMatches: Related[],
): RelatedContentModel {
	return {
		relatedSports,
		relatedContent,
		relatedMatches,
	};
}

// General content
export function generalContentModelToVideo(genContent: GeneralContentModel, video: Video) {
	return Video.builder(video)
		.withPublishedUntil(genContent.publishedUntil)
		.withPublishedAt(genContent.publishedAt)
		.withCategory(genContent.category)
		.withAdditionalCategories(genContent.categories)
		.withAuthors(genContent.authors)
		.withCustomAuthor(genContent.customAuthor)
		.withStatus(genContent.status)
		.withType(genContent.type)
		.withDistributionRegions(genContent.distributionRegions)
		.withDistributionChannels(genContent.distributionChannels)
		.withOrigin(genContent.origin)
		.withComments({ policy: genContent.commentsPolicies })
		.withLanguage(genContent.language)
		.build();
}

export function videoToGeneralContentModelForCreate(video: Video, type: ContentAttributes, status: ContentAttributes) {
	let genContentType: string = (type && type.slug) || '';
	let genContentStatus: string = (status && status.slug) || '';
	if (video && typeof video.type == 'string') genContentType = video.type;
	if (video && typeof video.status == 'string') genContentStatus = video.status;

	let genContent: GeneralContentModel = {
		type: genContentType || '',
		status: genContentStatus || '',
		customAuthor: video.customAuthor,
		authors: video.authors,
		categories: video.additionalCategories,
		category: video.category,
		publishedAt: video.publishedAt,
		publishedUntil: video.publishedUntil,
		allowComments: false,
		distributionRegions: video.distributionRegions,
		distributionChannels: video.distributionChannels,
		origin: video.origin,
		commentsPolicies: video.comments && video.comments.policy,
		language: video.language,
		properties: video.properties,
	};

	return genContent;
}

export function videoToGeneralContentModelForEdit(video: Video) {
	let genContent = {} as GeneralContentModel;

	if (video) {
		genContent = {
			type: video.type,
			status: video.status,
			customAuthor: video.customAuthor,
			authors: video.authors,
			categories: video.additionalCategories,
			category: video.category,
			publishedAt: video.publishedAt,
			publishedUntil: video.publishedUntil,
			allowComments: false,
			distributionRegions: video.distributionRegions,
			distributionChannels: video.distributionChannels,
			origin: video.origin,
			commentsPolicies: video.comments && video.comments.policy,
			language: video.language,
			properties: video.properties,
		};
	}

	return genContent;
}

export function getDefaultAuthor(authors: Author[]) {
	return authors.find(function (author) {
		return author.isDefault;
	});
}

export function appendDefaultAuthorIfNoneAdded(video: Video, defaultAuthor: Author): Video {
	if (
		video &&
		(video.authors === null || video.authors === undefined || video.authors.length < 1 || video.authors[0].id !== defaultAuthor.id) &&
		Object.entries(defaultAuthor).length > 0
	) {
		video = Video.builder(video).withAuthors([defaultAuthor]).build();
	}

	return video;
}

// URLs
export function urlsComponentModelToVideo(urlsModel: UrlsComponentModel, video: Video): Video {
	const urls: Urls = Urls.builder(video.urls)
		.withCanonicalUrl(urlsModel.canonicalUrl)
		.withExternalUrl(urlsModel.externalUrl)
		.withPublicUrlDesktop(urlsModel.publicUrlDesktop ? urlsModel.publicUrlDesktop : '')
		.withPublicUrlMobile(urlsModel.publicUrlMobile ? urlsModel.publicUrlMobile : '')
		.withPublicUrlAmp(urlsModel.publicUrlAmp ? urlsModel.publicUrlAmp : '')
		.build();
	const seo: Seo = Seo.builder(video.seo).withRedirectType(urlsModel.redirectType).build();

	return Video.builder(video)
		.withSeo(seo)
		.withUrls(urls)
		.withVideoFiles(urlsModel.videoUrls ? urlsModel.videoUrls : [])
		.build();
}

export function videoToUrlsComponentModelForEdit(video: Video): UrlsComponentModel {
	if (video) {
		return {
			externalUrl: video.urls && video.urls.externalUrl ? video.urls.externalUrl : '',
			canonicalUrl: video.urls && video.urls.canonicalUrl ? video.urls.canonicalUrl : '',
			redirectType: video.seo && video.seo.redirectType ? video.seo.redirectType : '',
			publicUrlDesktop: video.urls && video.urls.publicUrlDesktop ? video.urls.publicUrlDesktop : '',
			publicUrlMobile: video.urls && video.urls.publicUrlMobile ? video.urls.publicUrlMobile : '',
			publicUrlAmp: video.urls && video.urls.publicUrlAmp ? video.urls.publicUrlAmp : '',
			videoUrls: video.videoFiles ? video.videoFiles : [],
		};
	}

	return {} as UrlsComponentModel;
}

export function videoToUrlsComponentModelForCreate(video: Video): UrlsComponentModel {
	return {
		externalUrl: video.urls && video.urls.externalUrl ? video.urls.externalUrl : '',
		canonicalUrl: video.urls && video.urls.canonicalUrl ? video.urls.canonicalUrl : '',
		redirectType: video.seo && video.seo.redirectType ? video.seo.redirectType : '',
		publicUrlDesktop: video.urls && video.urls.publicUrlDesktop ? video.urls.publicUrlDesktop : '',
		publicUrlMobile: video.urls && video.urls.publicUrlMobile ? video.urls.publicUrlMobile : '',
		publicUrlAmp: video.urls && video.urls.publicUrlAmp ? video.urls.publicUrlAmp : '',
		videoUrls: video.videoFiles ? video.videoFiles : [],
	};
}

// SEO related

export function seoComponentModelToVideo(seoModel: SeoComponentModel, video: Video): Video {
	const seo: Seo = Seo.builder(video.seo)
		.withKeywords(seoModel.keywords)
		.withFollow(seoModel.following)
		.withIndex(seoModel.indexing)
		.withTitle(Global.stripEmoji(seoModel.title))
		.withDescription(seoModel.description)
		.withSlug(Global.stripEmoji(seoModel.slug))
		.withAutoSlug(seoModel.autoSlug)
		.withAutoTitle(seoModel.autoTitle)
		.withJsonLD(seoModel.jsonld)
		.build();

	return Video.builder(video).withSeo(seo).build();
}

export function videoToSeoComponentModelForEdit(video: Video): SeoComponentModel {
	if (video) {
		return {
			keywords: video.seo && video.seo.keywords ? video.seo.keywords : [],
			following: video.seo ? video.seo.follow : true,
			indexing: video.seo ? video.seo.index : true,
			title: video.seo && video.seo.title ? video.seo.title : '',
			description: video.seo && video.seo.description ? video.seo.description : '',
			slug: video.seo && video.seo.slug ? video.seo.slug : '',
			contentTitle: video.title ? video.title : '',
			autoSlug: video.seo ? video.seo.autoSlug : false,
			autoTitle: video.seo ? video.seo.autoTitle : false,
			jsonld: video.seo ? video.seo.jsonld : '',
		};
	}

	return {} as SeoComponentModel;
}

export function videoToSeoComponentModel(video: Video): SeoComponentModel {
	return {
		keywords: video.seo && video.seo.keywords ? video.seo.keywords : [],
		following: video.seo ? video.seo.follow : true,
		indexing: video.seo ? video.seo.index : true,
		title: video.seo && video.seo.title ? Global.stripEmoji(video.seo.title) : '',
		description: video.seo && video.seo.description ? video.seo.description : '',
		slug: video.seo && video.seo.slug ? Global.stripEmoji(video.seo.slug) : '',
		contentTitle: video && video.title ? Global.stripEmoji(video.title) : '',
		autoSlug: video && video.seo ? video.seo.autoSlug : false,
		autoTitle: video && video.seo ? video.seo.autoTitle : false,
		jsonld: video && video.seo ? video.seo.jsonld : '',
	};
}
export const jwPlayerOrigin = 'jwplayer';

export const isJWOriginAvailable = (): boolean => {
	try {
		return store.getState().origins.videoOrigins.findIndex((videoOrigin: ContentAttributes) => videoOrigin.slug === jwPlayerOrigin) >= 0;
	} catch (error) {
		console.error(error);
	}
	return false;
};

export const appendJWFilter = (filters: any) => {
	if (filters) {
		filters.searchText = filters.searchText ? filters.searchText : '';
		if (isJWOriginAvailable()) {
			filters.videoOrigin = store
				.getState()
				.origins.videoOrigins.find((videoOrigin: ContentAttributes) => videoOrigin.slug === jwPlayerOrigin);
		}
	}

	return filters;
};

export const isJWSection = (currentUrl: string = getCurrentUserUrl()): boolean => {
	return currentUrl.includes('/smp/jw-videos');
};

// if there are no origin available -> do not render any data
export const shouldRenderJwRows = (contentType: ContentTypes) => {
	return isJWSection() && isJWOriginAvailable() && contentType === ContentTypes.VIDEO;
};

// this is because we are applying by default the videoOrigin filter
export const hideClearFiltersButtonForJWSection = (): boolean => {
	if (isJWSection()) {
		const reduxState = store.getState();
		const jwFilters = (reduxState && reduxState.video && reduxState.video.jwFilters) || null;
		if (jwFilters) {
			const jwFiltersKeys = Object.keys(jwFilters);
			return jwFiltersKeys.length < 3; // includes searchText and videoOrigin by default
		}
	}
	return false;
};
