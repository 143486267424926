import 'reflect-metadata';
import { jsonMember, jsonObject } from 'typedjson';

@jsonObject()
export default class RoundModel {
	@jsonMember(String)
	public id: string = '';
	@jsonMember(String)
	public key: string = '';
	@jsonMember(String)
	public name: string = '';
	@jsonMember(String)
	public status: string = '';
	@jsonMember(String)
	public type: string = '';
	@jsonMember(String, { name: 'start_date' })
	public startDate: string = '';
}
