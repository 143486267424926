import * as React from 'react';

type Properties = {
	display: boolean;
	editContentPath: string;
	history: any;
	t: any;
	analytics: any;
	type: string;
};

export const RowTeamsEditButton: React.FunctionComponent<Properties> = ({ display, editContentPath, history, t, analytics, type }) => {
	// split path to get the last index
	const contentId = editContentPath.split('/').slice(-1)[0];
	const btnStyle = 'm-1 btn btn-sm btn-warning w-100 d-flex flex-fill align-items-center justify-content-center';

	const onEditContent = () => {
		analytics.sendEvent('Click', 'List Page', 'Edit Button');
		history.push(editContentPath);
	};

	if (display) {
		return (
			<div
				id={`edit-btn-${type}-${contentId}`}
				className={btnStyle}
				onClick={onEditContent}
				style={{ minWidth: `${type === 'team' ? '100px' : '120px'}` }}
			>
				<i className='fa fa-pencil-square-o p-1' />
				{t('edit')} {t(type)}
			</div>
		);
	}

	return null;
};
