import PlayerModel from './player.model';
import { PlayerJson } from './player.json';
import { assetsPlaceholder } from '../../../../../../../../constants/assetsPlaceholder';

export default class PlayerBuilder {
	private json: PlayerJson;

	constructor(player?: PlayerModel | PlayerJson) {
		if (player && player instanceof PlayerModel) {
			this.json = player.toJson();
		} else if (player) {
			this.json = player;
		} else {
			this.json = {} as PlayerJson;
		}
	}

	withId(id: string): PlayerBuilder {
		this.json.id = id;

		return this;
	}

	withName(name: string): PlayerBuilder {
		this.json.name = name;

		return this;
	}

	withHeadshot(headshot: string): PlayerBuilder {
		this.json.url_thumb = headshot ? headshot : assetsPlaceholder.player;

		return this;
	}

	withImage(image: string): PlayerBuilder {
		this.json.url_image = image ? image : assetsPlaceholder.player;

		return this;
	}

	build(): PlayerModel {
		return PlayerModel.fromJson(this.json);
	}
}
