import { TournamentJson } from './tournament.json';
import TournamentModel from './tournament.model';
import SeasonModel from '../season/season.model';
import Country from '../../../../../../../../models/country/country.model';

export default class TournamentBuilder {
	readonly json: TournamentJson;

	constructor(tournament?: TournamentModel | TournamentJson | null) {
		if (tournament && tournament instanceof TournamentModel) {
			this.json = tournament.toJson();
		} else if (tournament) {
			this.json = tournament;
		} else {
			this.json = {} as TournamentJson;
		}
	}

	withId(id: string): TournamentBuilder {
		this.json.id = id;

		return this;
	}

	withName(name: string): TournamentBuilder {
		this.json.name = name;

		return this;
	}

	withLogo(logo: string): TournamentBuilder {
		this.json.logo = logo;

		return this;
	}

	withSeason(season: SeasonModel): TournamentBuilder {
		this.json.season = season;

		return this;
	}

	withCountry(country: Country | null): TournamentBuilder {
		this.json.country = country;

		return this;
	}

	build(): TournamentModel {
		return TournamentModel.fromJson(this.json);
	}
}
