import SportOddsWidgetModel from './sport-odds-widget.model';
import { SportOddsWidgetJson } from './sport-odds-widget.json';
import GameModel from '../../../../../../../models/v2/game/game.model';
import SportTeamModel from '../../../../partials/sports-team-select/models/sport-team.model';
import SportBookmakerModel from '../../../../../../../models/sport-odds/sport-bookmaker.model';
import OddsModel from '../../../../../../../models/sport-odds/odds.model';

export default class SportOddsWidgetBuilder {
	readonly json: SportOddsWidgetJson;

	constructor(odds?: SportOddsWidgetModel | SportOddsWidgetJson) {
		if (odds && odds instanceof SportOddsWidgetModel) {
			this.json = odds.toJson();
		} else if (odds) {
			this.json = odds;
		} else {
			this.json = {} as SportOddsWidgetJson;
		}
	}

	withTeam(team: SportTeamModel): SportOddsWidgetBuilder {
		this.json.team = team;

		return this;
	}

	withGame(game: GameModel): SportOddsWidgetBuilder {
		this.json.game = game;

		return this;
	}

	withBookmakers(bookmakers: SportBookmakerModel[] | null): SportOddsWidgetBuilder {
		this.json.bookmakers = bookmakers;

		return this;
	}

	withMarket(market: any): SportOddsWidgetBuilder {
		this.json.market = market;

		return this;
	}

	withOddsList(oddsList: OddsModel[]): SportOddsWidgetBuilder {
		this.json.oddsList = oddsList;

		return this;
	}

	build(): SportOddsWidgetModel {
		return SportOddsWidgetModel.fromJson(this.json);
	}
}
