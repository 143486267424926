import Author from '../../../../../../models/author/Author';

export function authorsToOptions(authors: Author[]): any[] {
	return authors ? authors.filter((author: Author) => author && author.active).map((author: Author) => returnMappedAuthors(author)) : [];
}

export const returnMappedAuthors = (author: Author) => {
	return {
		value: author.id,
		label: author.name,
		logo: author.avatarImage,
		author,
	};
};

export function authorsFromOptionSelections(selections: any[]) {
	return selections ? selections.map((selection: any) => selection.author) : [];
}
