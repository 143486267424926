import React from 'react';
import { compose } from 'redux';
import { SportBlockTypes, SportTypes, WidgetScriptTypes } from '../../../../../../../constants/sport.types';
import { ViewTypes } from '../../../../constants/general.constants';
import BlockModel from '../../../../models/block.model';
import BlockUpdateController from '../../../block-update-controller.component';
import OddsWidgetModel from '../../odds/models/odds-widget.model';
import { MonorepoWidgetPreview } from '../../widget-preview/monorepo-widget-preview.component';
import GameWidgetModel from '../models/game-widget.model';
import BasketballSingleEventWidgetEdit from './basketball-single-event-edit.component';
import { BasketballSingleEventWidgetView } from './basketball-single-event-view.component';

type Properties = {
	block: BlockModel;
	view: ViewTypes;
	t: any;
	contentData: any;
	contentLanguage: string;
};

const BasketballSingleEventWidget: React.FunctionComponent<Properties> = (props) => {
	const { view, t, block, contentData, contentLanguage } = props;

	if (view === ViewTypes.edit) {
		return <BasketballSingleEventWidgetEdit block={block} t={t} contentData={contentData} contentLanguage={contentLanguage} />;
	}

	if (view === ViewTypes.preview) {
		return (
			<MonorepoWidgetPreview
				blockContent={props.block.data.content}
				config={props.block.data.config}
				type={WidgetScriptTypes.BASKETBALL_WIDGETS}
				blockType={SportBlockTypes.EVENT_BLOCK}
				sport={SportTypes.BASKETBALL}
			/>
		);
	}

	if (view === ViewTypes.normal) {
		return (
			<BasketballSingleEventWidgetView
				t={props.t}
				gamePreview={
					props.block.data.preview && props.block.data.preview.game ? props.block.data.preview.game : GameWidgetModel.builder().build()
				}
				oddsPreview={
					props.block.data.preview && props.block.data.preview.odds ? props.block.data.preview.odds : OddsWidgetModel.builder().build()
				}
			/>
		);
	}

	return null;
};

export default compose(BlockUpdateController)(BasketballSingleEventWidget);
