import * as React from 'react';
import { Properties } from './properties/ContentPropertiesContainerProperties';
import ContentProperty from './ContentProperty';
import { extractPropertyNameFromRedux, isPropertySelected } from './ContentPropertiesHelper';
import { connect } from 'react-redux';

class ContentPropertiesContainer extends React.Component<Properties> {
	render() {
		const { onPropertySelected, contentProperties, content } = this.props;

		return content && content.properties && Object.keys(content.properties).length > 0 ? (
			Object.keys(content.properties).map((propertySlug: string) => {
				return (
					<ContentProperty
						name={extractPropertyNameFromRedux(contentProperties, propertySlug)}
						value={propertySlug}
						key={propertySlug}
						uniqueKey={propertySlug}
						isChecked={isPropertySelected(propertySlug, this.props.content)}
						onPropertySelect={onPropertySelected}
						t={this.props.t}
					/>
				);
			})
		) : (
			<></>
		);
	}
}

function mapStateToProps(state: any, compProps: any) {
	const propertyName = compProps.contentType + 'Properties';

	return {
		contentProperties: state.contentProperties[propertyName] || [],
	};
}

export default connect(mapStateToProps)(ContentPropertiesContainer);
