import React, { FunctionComponent } from 'react';
import { Col, Input, Label, Row } from 'reactstrap';
import { DATA_QA_ATTRIBUTES } from '../../../../../Partials/Sidebar/general-content/constants/data-qa.attributes';
import ErrorAsync from '../../../../../Partials/BaseComponents/ErrorComponent/ErrorAsync';
import LiveBlogModel from '../../../models/live-blog.model';

type Properties = {
	t: any;
	liveBlog: LiveBlogModel;
	setLiveBlogTitle: any;
};

export const LiveBlogTitleSelect: FunctionComponent<Properties> = ({ t, liveBlog, setLiveBlogTitle }) => {
	const onTitleChange = (input: string) => {
		setLiveBlogTitle(input);
	};

	return (
		<Row className='mb-3'>
			<Col>
				<Label htmlFor={DATA_QA_ATTRIBUTES.LIVE_BLOG_TITLE}>
					<strong>{t('event_title')}</strong>
				</Label>
				<span className='text-danger'>*</span>
				<Input
					value={liveBlog.title ? liveBlog.title : ''}
					onChange={(input: any) => onTitleChange(input)}
					className='form-control mb-2 border-dark'
					type='text'
					placeholder={t('title_placeholder')}
					id='title'
				/>
				<ErrorAsync t={t} errorType='title_id' />
			</Col>
		</Row>
	);
};
