import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import MainMediaModel from '../../../../../../models/v2/main-media/main-media.model';
import { autoGenerateReduxPropsNamingForContentModels } from '../../../../../../services/content-models-service/ContentModelService';
import ErrorAsync from '../../../../BaseComponents/ErrorComponent/ErrorAsync';
import { imageResourceType } from '../../constants/general';
import { extractMainImageObjectFromArray } from '../../helpers/general';
import { toast } from 'react-toastify';

type Properties = {
	t: any;
	mainMediaRedux: MainMediaModel[];
	areMandatoryFieldsFilled?: boolean;
	updateMandatoryFieldsFilled: (value: boolean) => void;
};

const MainImageErrors: React.FunctionComponent<Properties> = ({
	t,
	mainMediaRedux,
	areMandatoryFieldsFilled,
	updateMandatoryFieldsFilled,
}) => {
	const imageMediaErrorIndex = mainMediaRedux && mainMediaRedux.findIndex((media) => media.resourceType === imageResourceType);

	useEffect(() => {
		const mainImageExists = extractMainImageObjectFromArray(mainMediaRedux);
		if (mainImageExists === null && areMandatoryFieldsFilled !== undefined && !areMandatoryFieldsFilled) {
			toast.error(t('main_image_must_not_be_empty'));
			updateMandatoryFieldsFilled(true);
		}
	}, [areMandatoryFieldsFilled]);

	return (
		<>
			<ErrorAsync t={t} errorType='main_media' mainMedia={`main_media.${imageMediaErrorIndex}.description`} />
		</>
	);
};

function mapStateToProps(state: any) {
	const { tempPropertyNaming, nestedTempPropertyNaming } = autoGenerateReduxPropsNamingForContentModels();
	return {
		mainMediaRedux: state[tempPropertyNaming][nestedTempPropertyNaming].mainMedia || [],
	};
}

export default connect(mapStateToProps)(MainImageErrors);
