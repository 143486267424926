import { ImageUrlsJson } from './ImageUrlsJson';
import ImageUrls from './ImageUrls';
import ImageUrlsUploaded from './ImageUrlsUploaded/ImageUrlsUploaded';
import ImageRatio from './ratio/ImageRatio';

export default class ImageUrlsBuilder {
	private json: ImageUrlsJson;

	constructor(imageUrls?: ImageUrls | ImageUrlsJson) {
		if (imageUrls && imageUrls instanceof ImageUrls) {
			this.json = imageUrls.toJSON();
		} else if (imageUrls) {
			this.json = imageUrls;
		} else {
			this.json = {} as ImageUrlsJson;
		}
	}

	with23x9(ratio23x9: ImageRatio): ImageUrlsBuilder {
		this.json.ratio23x9 = ratio23x9;

		return this;
	}

	with16x9(ratio16x9: ImageRatio): ImageUrlsBuilder {
		this.json.ratio16x9 = ratio16x9;

		return this;
	}

	with9x16(ratio9x16: ImageRatio): ImageUrlsBuilder {
		this.json.ratio9x16 = ratio9x16;

		return this;
	}

	with4x3(ratio4x3: ImageRatio): ImageUrlsBuilder {
		this.json.ratio4x3 = ratio4x3;

		return this;
	}

	with3x4(ratio3x4: ImageRatio): ImageUrlsBuilder {
		this.json.ratio3x4 = ratio3x4;

		return this;
	}

	with2x3(ratio2x3: ImageRatio): ImageUrlsBuilder {
		this.json.ratio2x3 = ratio2x3;

		return this;
	}

	with3x2(ratio3x2: ImageRatio): ImageUrlsBuilder {
		this.json.ratio3x2 = ratio3x2;

		return this;
	}

	with1x1(ratio1x1: ImageRatio): ImageUrlsBuilder {
		this.json.ratio1x1 = ratio1x1;

		return this;
	}

	withCropped(cropped: string): ImageUrlsBuilder {
		this.json.cropped = cropped;

		return this;
	}

	withUploaded(uploaded: ImageUrlsUploaded): ImageUrlsBuilder {
		this.json.uploaded = uploaded;

		return this;
	}

	build(): ImageUrls {
		return ImageUrls.fromJSON(this.json);
	}
}
