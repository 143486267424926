import * as React from 'react';
import { FormGroup, Label, Col, Input } from 'reactstrap';
import ErrorHandler from '../../../../Partials/Blocky/partials/error/error-handler-component';

type HeightSelect = {
	t: any;
	onChange: any;
	value: string;
};

const HeightSelect: React.FunctionComponent<HeightSelect> = ({ onChange, t, value }) => {
	value = value ? value : '';
	const regExpOnlyDigits = /^[0-9]*$/;

	return (
		<Col xs='6'>
			<FormGroup>
				<Label htmlFor='team-manual-data-entry-height'>{t('height')}</Label>
				<Input
					type='text'
					id='team-manual-data-entry-height'
					value={value ? value : ''}
					onChange={onChange}
					className='form-control'
					placeholder={t('in_centimeters')}
				/>
				{!regExpOnlyDigits.test(value.trim()) && <ErrorHandler t={t} errorMessage='value_should_be_digit' />}
			</FormGroup>
		</Col>
	);
};

export default HeightSelect;
