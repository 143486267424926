import * as React from 'react';
import { Label } from 'reactstrap';
import Select from 'react-select';
import { CustomEntitiesSearchFiltersModel } from '../../../models/models';
import { useTranslation } from 'react-i18next';
import { DATA_QA } from '../../../helpers/data-qa.properties';
import { SelectMenuOptionType } from '../../../../../../models/v2/general/select.model';
import {
	CUSTOM_ENTITIES_ENTITY_TYPES_OPTIONS,
	customEntitiesAdvancedFiltersStyles,
	selectEntityTypesOptions,
} from '../../../helpers/custom-entities.helper';
import '../../../styles/custom-entities-listing.style.scss';

type Properties = {
	updateCustomEntitiesAdvancedSearchText: (customEntitiesAdvancedFiltersModel: CustomEntitiesSearchFiltersModel) => void;
	customEntitiesAdvancedFiltersModel: CustomEntitiesSearchFiltersModel;
};

export const CustomEntitiesEntityTypesSelect: React.FunctionComponent<Properties> = ({
	updateCustomEntitiesAdvancedSearchText,
	customEntitiesAdvancedFiltersModel,
}) => {
	const [t] = useTranslation();

	const onEntityTypeSelect = (types: SelectMenuOptionType[]) => {
		let advancedContentModel = customEntitiesAdvancedFiltersModel;
		advancedContentModel.entity_types = types && types.length > 0 ? types.map((type) => type.data) : [];
		updateCustomEntitiesAdvancedSearchText(advancedContentModel);
	};

	return (
		<div data-qa={DATA_QA.CUSTOM_ENTITIES_ENTITY_TYPE_SELECT}>
			<Label className='custom-entities-advanced-search-labels' htmlFor={DATA_QA.CUSTOM_ENTITIES_ENTITY_TYPE_SELECT}>
				{t('entity_type_filter')}
			</Label>
			{
				// @ts-ignore
				<Select
					isMulti
					styles={customEntitiesAdvancedFiltersStyles}
					isClearable
					id={DATA_QA.CUSTOM_ENTITIES_ENTITY_TYPE_SELECT}
					options={CUSTOM_ENTITIES_ENTITY_TYPES_OPTIONS}
					value={selectEntityTypesOptions(customEntitiesAdvancedFiltersModel.entity_types)}
					placeholder={t('select')}
					noOptionsMessage={(inputValue) => inputValue && t('no_options')}
					onChange={onEntityTypeSelect}
				/>
			}
		</div>
	);
};
