import * as React from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { assetsPlaceholder } from '../../../../../../constants/assetsPlaceholder';
import { AssetsTypes } from '../constants/assets.constants';
import '../styles/assets-media.component.style.scss';

type Properties = {
	projectId: string;
	imageClassName?: string;
	toggleImageEnlarge: any;
	imageLoading: boolean;
	enlargeImage: boolean;
	url: string;
	type: string;
};

export const UploadedImageComponent: React.FunctionComponent<Properties> = (props) => {
	const { imageLoading, enlargeImage, url, imageClassName, toggleImageEnlarge, type } = props;

	return (
		<div className={imageClassName}>
			{imageLoading ? (
				<div id={'async-image-loader-placeholder'} className={'loading-overlay bg-light image-loader h-100'}></div>
			) : (
				<>
					<div
						className={url ? 'expand-item h-100 asset-expand-item' : ''}
						onClick={() => {
							url && toggleImageEnlarge(true);
						}}
					>
						<img
							src={url ? url : assetsPlaceholder.imagePlaceholder}
							className={`${url && type === AssetsTypes.SQUAD_IMAGE ? 'asset-squad-image' : 'w-100 img-fluid'}`}
							alt={url}
						/>
					</div>
					{enlargeImage && (
						<Lightbox
							mainSrc={url}
							onCloseRequest={() => {
								toggleImageEnlarge(false);
							}}
						/>
					)}
				</>
			)}
		</div>
	);
};
