import 'reflect-metadata';
import { AnyT, jsonArrayMember, jsonMember, jsonObject } from 'typedjson';
import Country from '../../Country/Country.model';
import SeasonResponseModel from '../../Season/Entity/response-season.model';
import SeasonModel from '../../Season/Entity/SeasonModel';

@jsonObject()
export default class TournamentResponseModel {
	@jsonMember(AnyT)
	public id: any = '';
	@jsonMember(String)
	public name: string = '';
	@jsonMember(String)
	public region: string = '';
	@jsonMember(String)
	public gender: string = '';
	@jsonMember(Boolean)
	public regional_league: boolean = false;
	@jsonMember(String)
	public type: string = '';
	@jsonMember(String)
	public url_logo: string = '';
	@jsonMember(Country)
	public country: Country = new Country();
	@jsonArrayMember(() => SeasonResponseModel)
	public seasons?: SeasonResponseModel[] = [];
	@jsonMember(String)
	public entity_type?: string = '';
	@jsonMember(String)
	public slug: string | null = null;
}
