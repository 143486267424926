import TeamModel from '../models/team.model';
import { assetsPlaceholder } from '../../../../../../constants/assetsPlaceholder';

export const teamToOption = (team: any) => {
	if (team && team.id) {
		return {
			value: team.id,
			label: team.name,
			data: team,
			logo: team.logo,
			type: team.type,
		};
	}

	return {};
};

export const teamsToOption = (teams: any) => {
	let options: any[] = [];

	if (teams && teams.length > 0) {
		return teams.map((team: any) => responseToOption(team));
	}

	return options;
};

export const remapTeamFromResponce = (team: any) => {
	return TeamModel.builder()
		.withId(team.id)
		.withLogo(team.url_logo)
		.withName(team.name)
		.withType(team.type)
		.withThreeLetterCode(team.three_letter_code)
		.build();
};

export const remapTeamsFromResponce = (teams: any) => teams.map((team: any) => remapTeamFromResponce(team));

export const responseToOption = (team: any) => {
	if (team && team.id) {
		return {
			value: team.id,
			label: team.name,
			logo: team.url_logo ? team.url_logo : assetsPlaceholder.team,
			type: 'team',
			data: team,
		};
	}

	return {};
};

export const checkIfSeasonIdIsPresent = (seasonId: number) => {
	if (seasonId) {
		return true;
	}
};
