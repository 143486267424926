import Related from '../../models/related/Related';
import { Subject } from 'rxjs';

export const EDITOR_BLOCK_TYPE = 'editor_block';
export const EDITOR_BLOCK_PARAGRAPH_TYPE = 'paragraph';

export default class TagsService {
	readonly tagsSubject: Subject<any[]> = {} as Subject<any[]>;

	constructor() {
		this.tagsSubject = new Subject<any[]>();
	}

	addTagsFromBody = (footballConnections: Related[], blocks: any[]) => {
		let entityTags = blocks && blocks.length > 0 ? this.extractTagsFromBlocks(JSON.parse(JSON.stringify(blocks))) : [];

		if (entityTags && entityTags.length > 0) {
			entityTags = this.hideTagsWithExistingFootballEntities(footballConnections, entityTags);
			entityTags = this.removeFields(entityTags, 'removed');
			this.updateTags(entityTags, 'addTagsFromBody');
		} else {
			this.updateTags([], 'addTagsFromBody');
		}
	};

	public updateTagsForContent = (tags: any[]) => {
		this.updateTags(tags, 'updateTagsForContent');
	};

	public getTagsSubject = (): Subject<any[]> => {
		return this.tagsSubject;
	};

	private updateTags = (tags: any[], calledFrom: string) => {
		this.tagsSubject.next(tags);
	};

	public hideTagsWithExistingFootballEntities = (footballConnections: Related[], tags: any[]) => {
		if (tags && tags.length > 0) {
			return tags.map((tag: any) => {
				const index =
					footballConnections && footballConnections.length > 0
						? footballConnections
								.filter((related: Related) => related && related.data)
								.findIndex((related: Related) => related.type === tag.subtype && `${related.data.id}` === `${tag.id}`)
						: -1;

				tag['hide'] = !(index === -1);

				return tag;
			});
		}

		return [];
	};

	private extractTagsFromBlocks = (blocks: any[]) => {
		let entityTags: any[] = [];
		blocks &&
			blocks
				.filter(
					(block: any) =>
						block.type === EDITOR_BLOCK_TYPE &&
						block.data &&
						block.data.type === EDITOR_BLOCK_PARAGRAPH_TYPE &&
						block.data.tags &&
						block.data.tags.length > 0,
				)
				.forEach((block: any) => entityTags.push(...block.data.tags));

		return this.getUniqueOnly(entityTags, 'id');
	};

	private removeFields = (tags: any[], fieldName: string) => {
		tags = tags.map((tag: any) => {
			delete tag[fieldName];

			return tag;
		});

		return tags;
	};

	public markTagForRemoval = (tags: any[], tagToRemove: any, isHidden: boolean) => {
		tags = tags.map((tag: any) => {
			if (tag.id === tagToRemove.id) {
				tag['hide'] = isHidden;
			}

			return tag;
		});

		return tags;
	};

	public resetHiddenState = (tags: any[]) => {
		return tags.map((tag: any) => {
			tag['hide'] = false;

			return tag;
		});
	};

	public getUniqueOnly = (array: any[], filterByProperty: any) => {
		const result = [];
		const map = new Map();

		for (const item of array) {
			if (!map.has(item[filterByProperty])) {
				map.set(item[filterByProperty], true);
				result.push({ ...item });
			}
		}

		return result;
	};
}
