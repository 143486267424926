import React from 'react';
import { noPreviewImage } from '../../../../../../../constants/resource-constants';
import SpotlightAudioModel from '../../../../../../../models/spotlight-audio/spotlight-audio.model';
import { Col, Row } from 'reactstrap';
import moment from 'moment';

type Properties = {
	t: any;
	audio: SpotlightAudioModel;
};

const SpotlightAudioView: React.FunctionComponent<Properties> = (props) => {
	const { audio, t } = props;

	if (audio && audio.homeTeam) {
		return (
			<>
				<Row>
					<Col className='px-4'>
						<strong>{t('type')}:</strong> {t('spotlightAudio')}
					</Col>
				</Row>
				<Row>
					<Col className='px-4'>
						<strong>{t('event')}:</strong> {`${audio.homeTeam} ${audio.awayTeam ? '- ' + audio.awayTeam : ''}`}
						<br />
						<br />
						<strong>{t('competition')}:</strong> {audio.competition}
						<br />
						<br />
						<strong>{t('date')}:</strong> {moment(audio.kickOff).format('DD-MM-YYYY')}
					</Col>
				</Row>
				<Row>
					<Col className='px-4'>
						<div style={{ padding: '5px' }}>
							<hr></hr>
						</div>
					</Col>
				</Row>
			</>
		);
	}

	return (
		<div className='position-relative text-center'>
			<img alt='' src={noPreviewImage} />
		</div>
	);
};

export default SpotlightAudioView;
