import React, { FunctionComponent } from 'react';
import Select from 'react-select';
import { ValueType } from 'react-select/src/types';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import { DATA_QA_ATTRIBUTES } from '../../constants/data-qa';
import { availableSportsToOption, sportTypeToOption } from '../../helpers/sport-select.helper';
import SportsTypesModel from '../../../../../../models/v2/sports-types/sports-types.model';

type Properties = {
	t: any;
	selectedSport: SportsTypesModel;
	availableSports: SportsTypesModel[];
	setSelectedSport: Function;
};

const SportSelect: FunctionComponent<Properties> = ({ t, selectedSport, setSelectedSport, availableSports }) => {
	const selectedSportAsOption = sportTypeToOption(selectedSport);
	const availableSportsAsOptions = availableSportsToOption(availableSports);

	const changeSport = (selectedSportOption: ValueType<any>) => setSelectedSport(selectedSportOption.data);

	return (
		<Row>
			<Col>
				<FormGroup>
					<Label htmlFor={DATA_QA_ATTRIBUTES.SPORT_SELECT}>{t('sport')}</Label>
					<Select
						id={DATA_QA_ATTRIBUTES.SPORT_SELECT}
						placeholder={t('select')}
						onChange={changeSport}
						value={selectedSportAsOption}
						options={availableSportsAsOptions}
					/>
				</FormGroup>
			</Col>
		</Row>
	);
};

export default SportSelect;
