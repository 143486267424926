import { RefereeJson } from './referee.json';
import RefereeBuilder from './referee.builder';

export default class RefereeModel {
	readonly id: string;
	readonly name: string;

	private constructor(id: string, name: string) {
		this.id = id;
		this.name = name;
	}

	toJson(): RefereeJson {
		return {
			id: this.id,
			name: this.name,
		};
	}

	static fromJson(json: RefereeJson): RefereeModel {
		return new RefereeModel(json.id, json.name);
	}

	static builder(referee?: RefereeModel): RefereeBuilder {
		return new RefereeBuilder(referee);
	}
}
