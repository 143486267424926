import React, { useEffect } from 'react';
import Select from 'react-select';
import { Label } from 'reactstrap';
import { marketOptions, marketOptionsV2 } from './helpers/market-select.helpers';

type Properties = {
	onMarketSelect: any;
	value: any;
	t: any;
	limitTo1x2?: boolean;
	id: string;
	isWidgetV2?: boolean;
};

const MarketSelect: React.FunctionComponent<Properties> = ({ value, t, onMarketSelect, limitTo1x2, id, isWidgetV2 = true }) => {
	useEffect(() => {
		if (value && value.length > 0) {
			onMarketSelect(value);
		}
	}, []);

	const propertyValueToOption = (value: any) => {
		if (value && value.length > 0) {
			const marketOptionValue = isWidgetV2
				? marketOptionsV2(t).find((option) => option.value === value)
				: marketOptions(t).find((option) => option.value === value);

			if (marketOptionValue) {
				return marketOptionValue;
			}
		}

		return { value: '', label: '' };
	};

	const marketSelect = (selection: any) => {
		selection ? onMarketSelect(selection.value) : onMarketSelect(null);
	};

	const getOptions = () => {
		const funcForExecuting = isWidgetV2 ? marketOptionsV2 : marketOptions;
		return limitTo1x2 ? [funcForExecuting(t)[0]] : funcForExecuting(t);
	};

	return (
		<div data-qa={id}>
			<Label htmlFor={id}>{t('choose_default_market')}:</Label>
			<Select
				options={getOptions()}
				onChange={marketSelect}
				value={propertyValueToOption(value)}
				isClearable={value && value.length > 0}
				noOptionsMessage={(inputValue) => inputValue && t('no_options')}
				placeholder={t('select')}
				id={id}
			/>
		</div>
	);
};

export default MarketSelect;
