import * as React from 'react';
import Select from 'react-select';
import * as helpers from '../../../../Partials/Shared/react-select-helpers/react-select.helpers';
import HttpService from '../../../../../services/rest/HttpService';
import { Label, FormGroup, Col } from 'reactstrap';
import { remapEntitiesFromResponse } from '../../../translations/helpers/translations.helpers';
import EnumItem from '../../../../../models/enum/EnumItem';
import { customOption } from '../../../../Partials/Blocky/partials/shared/custom-select-option';

type CountryProps = {
	t: any;
	onChange: (selection: any) => void;
	value: any;
	searchLanguage?: EnumItem;
	isRequired?: boolean;
	colWidth?: string;
};

const CountrySelect: React.FunctionComponent<CountryProps> = ({ t, onChange, value, isRequired, searchLanguage, colWidth }) => {
	const [options, setOptions] = React.useState([]);

	const onCountrySelect = (selection: any) => onChange(helpers.optionToItem(selection));

	React.useEffect(() => {
		HttpService.instanceFootball(searchLanguage ? searchLanguage.id : null)
			.get('/countries')
			.then((response: any) => {
				const options: any = helpers.itemsToOptions(remapEntitiesFromResponse(response.data, 'country'), false, t, 'country');
				setOptions(options);
			})
			.catch((error: unknown) => console.error(error));
	}, [t, searchLanguage]);

	return (
		<Col xs={colWidth ? colWidth : '6'}>
			<FormGroup>
				<Label htmlFor='manual-data-entry-country'>
					{t('country')}
					{isRequired}
					<span className='text-danger'>*</span>
				</Label>
				<Select
					inputId='manual-data-entry-country'
					value={helpers.itemToOption(value, false, t, 'country')}
					options={options}
					onChange={onCountrySelect}
					placeholder={t('select_country')}
					formatOptionLabel={customOption}
					noOptionsMessage={(inputValue) => inputValue && t('no_options')}
				/>
			</FormGroup>
		</Col>
	);
};

export default CountrySelect;
