import React from 'react';
import MatchListStageRoundModel from '../models/match-list-stage-round-widget.model';
import { Col, Row } from 'reactstrap';
import './match-list-stage-round-view.component.scss';
import moment from 'moment';
import { OddsConfiguration } from '../../../../partials/match-list-config/models/odds-configuration.json';
import MatchListConfigModel from '../../../../partials/match-list-config/models/match-list-config.model';

type Properties = {
	preview: MatchListStageRoundModel;
	t: any;
};

export const MatchListStageRoundView: React.FunctionComponent<Properties> = ({ preview, t }) => {
	const config = preview.config ? preview.config : ({} as MatchListConfigModel);
	const oddsConfig = config && config.oddsConfiguration ? config.oddsConfiguration : ({} as OddsConfiguration);
	const showToggleButton =
		oddsConfig && oddsConfig.showToggleButton !== undefined && oddsConfig.showToggleButton !== null ? oddsConfig.showToggleButton : false;

	if (preview.tournament) {
		return (
			<>
				<Row>
					<Col className='px-4'>
						<div className='single-row'>
							<strong>{t('grouping_tournament')}:</strong> {preview.tournament.name}
						</div>
						<div className='single-row'>
							<strong>{t('season')}:</strong> {preview.season.name}
						</div>
						{preview.round && preview.round.round && preview.round.round.length > 0 && (
							<div className='single-row'>
								<strong>{t('round')}:</strong> {preview.round.round}
							</div>
						)}
					</Col>
				</Row>
				<Row>
					<Col className='px-4'>
						<div style={{ padding: '5px' }}>
							<hr></hr>
						</div>
					</Col>
				</Row>
				<Row className='mb-1'>
					<Col className='px-4'>
						{config.title && (
							<div className='single-row'>
								<strong>{t('title')}:</strong> {config.title}
							</div>
						)}
						<div className='single-row'>
							<strong>{t('match_list_stage_round_odds_toggle_btn')}:</strong> {JSON.stringify(showToggleButton)}
						</div>
					</Col>
				</Row>
				<Row>
					<Col className='px-4'>
						{config.view && (
							<div className='single-row'>
								<strong>{t('match_list_stage_round_view')}:</strong> {t(config.view)}
							</div>
						)}

						{config.viewMoreLabel && (
							<div className='single-row'>
								<strong>{t('view_more')}:</strong> {config.viewMoreLabel}
							</div>
						)}

						{config.from && config.from.length > 0 && (
							<div className='single-row'>
								<strong>{t('match_list_stage_round_date_from')}:</strong> {moment(config.from).format('YYYY-MM-DD')}
							</div>
						)}
						{config.to && config.to.length > 0 && (
							<div className='single-row'>
								<strong>{t('match_list_stage_round_date_to')}:</strong> {moment(config.to).format('YYYY-MM-DD')}
							</div>
						)}
						{config.order && config.order.length > 0 && (
							<div className='single-row'>
								<strong>{t('match_list_stage_round_matches_order')}:</strong> {t(config.order)}
							</div>
						)}
						{config.order && config.order.length > 0 && (
							<div className='single-row'>
								<strong>{t('match_list_stage_round_order_direction')}:</strong> {t(config.orderDirection)}
							</div>
						)}
						{config.grouping && config.grouping.length > 0 && (
							<div className='single-row'>
								<strong>{t('match_list_stage_round_grouping')}:</strong> {t(config.grouping)}
							</div>
						)}
					</Col>
				</Row>
				<Row>
					<Col className='px-4'>
						{config.threeLetterCodeType && config.threeLetterCodeType !== '' && (
							<div className='single-row'>
								<strong>{t('display_team_three_letter_code')}:</strong>
								<span className='ml-2'>
									{config.threeLetterCodeType && config.threeLetterCodeType.name && t(`${config.threeLetterCodeType.name}`)}
								</span>
							</div>
						)}
					</Col>
				</Row>
			</>
		);
	} else {
		return null;
	}
};
