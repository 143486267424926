import React, { useEffect } from 'react';
import Select from 'react-select';
import { Col, Label, Row } from 'reactstrap';
import { DATA_QA_ATTRIBUTES } from '../../../../../Partials/Sidebar/general-content/constants/data-qa.attributes';
import LiveBlogModel from '../../../models/live-blog.model';
import '../../../style/live-blog.style.scss';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../../../store/store';
import CustomFlagOption from '../../../../../Partials/Blocky/partials/shared/custom-select-flag-option';
import { codeToOption, languageCodesToOptions } from './live-blog-language-select.helper';

type Properties = {
	t: any;
	liveBlog: LiveBlogModel;
	setLiveBlogLanguage: (language: string | null) => void;
};

export const LiveBlogLanguageSelect: React.FunctionComponent<Properties> = ({ t, liveBlog, setLiveBlogLanguage }) => {
	const currentProject = useSelector((state: AppState) => state.project.currentProject);
	const defaultValue =
		!liveBlog.language || (liveBlog.language && liveBlog.language.length < 1)
			? currentProject.languages.defaultLanguageCode.languageCode
			: liveBlog.language;

	useEffect(() => {
		setLiveBlogLanguage(defaultValue);
	}, []);

	const onLanguageSelect = (language: any) => {
		language.data ? setLiveBlogLanguage(language.data.languageCode) : setLiveBlogLanguage(null);
	};

	return (
		<Row className='mb-3 language'>
			<Col>
				<Label htmlFor={DATA_QA_ATTRIBUTES.LIVE_BLOG_LANGUAGE}>
					<strong>{t('language')}</strong>
					<span className='text-danger'>*</span>
				</Label>
				<Select
					id={DATA_QA_ATTRIBUTES.LIVE_BLOG_LANGUAGE}
					options={languageCodesToOptions(currentProject.languages.all, t)}
					value={codeToOption(defaultValue, t)}
					formatOptionLabel={CustomFlagOption}
					placeholder={t('select')}
					noOptionsMessage={(inputValue) => inputValue && t('no_options')}
					onChange={(selection: any) => onLanguageSelect(selection)}
				/>
			</Col>
		</Row>
	);
};
