import 'reflect-metadata';
import { jsonArrayMember, jsonMember, jsonObject } from 'typedjson';
import GettyImageModel from './getty-image.model';

@jsonObject()
export default class GettyImagesListingModel {
	@jsonArrayMember(GettyImageModel)
	public images: GettyImageModel[] = [];
	@jsonMember(Number, { name: 'result_count' })
	public resultCount: number | null = null;
}
