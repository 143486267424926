import React, { ChangeEvent } from 'react';
import ImageUploadContainer from '../../../../partials/image-upload/image-upload-container';
import Image from '../../../../../../../models/image/Image';
import { useTranslation } from 'react-i18next';
import Related from '../../../../../../../models/related/Related';
import GalleryBlockImageListing from '../listing/uploaded-images-listing.component';
import GalleryBlockModel from '../models/gallery-block.model';
import { Col, Row } from 'reactstrap';
import AddedGalleryItems from './components/added-gallery-items.component';
import { DATA_QA_ATTRIBUTES } from '../helpers/gallery-block.helper';
import GalleryBlockImagoImageListing from '../listing/imago-images-listing.component';
import GalleryBlockGettyImageListing from '../listing/getty-images-listing.component';

type Properties = {
	onCopySportsRelated: (checked: boolean) => void;
	sportsRelatedContent: Related[];
	onImagesUploaded: (data: Image[]) => void;
	onGalleryItemsChange: (data: Image[]) => void;
	copySportsRelated: boolean;
	gallery: GalleryBlockModel;
	blockId?: string;
};

export const GalleryItems: React.FunctionComponent<Properties> = (props) => {
	const [t] = useTranslation();

	const onCopySportsRelatedChecked = (e: ChangeEvent<HTMLInputElement>) => {
		onCopySportsRelated(e.target.checked);
	};

	const { onCopySportsRelated, sportsRelatedContent, onImagesUploaded, copySportsRelated, blockId } = props;
	return (
		<div data-qa={DATA_QA_ATTRIBUTES.GALLERY_BLOCK_ITEMS_WRAPPER}>
			<ImageUploadContainer
				t={t}
				display
				blockId={blockId}
				imageUploadButtonId={`blocky-gallery-items-upload-button-${blockId}`}
				copySportsRelated={copySportsRelated}
				sportsRelatedContent={sportsRelatedContent}
				onCopySportsRelated={(checked: boolean) => {
					onCopySportsRelated(checked);
				}}
				onMultiImageUploadSuccess={(data: Image[]) => {
					onImagesUploaded(data);
				}}
				isSingleUpload={false}
				onImageUploadSuccess={() => undefined}
			/>
			{props.gallery.items && props.gallery.items.length > 0 && (
				<Row>
					<Col>
						<AddedGalleryItems galleryItems={props.gallery.items} />
					</Col>
				</Row>
			)}
			<GalleryBlockImageListing galleryItems={props.gallery.items} onGalleryItemsChange={props.onGalleryItemsChange} />
			<GalleryBlockImagoImageListing galleryItems={props.gallery.items} onGalleryItemsChange={props.onGalleryItemsChange} />
			<GalleryBlockGettyImageListing galleryItems={props.gallery.items} onGalleryItemsChange={props.onGalleryItemsChange} />
			<Row className='mb-1 mt-2'>
				<Col>
					<input
						className='mr-1'
						id={`copy-sports-connections-per-image-${blockId}`}
						checked={copySportsRelated}
						onChange={onCopySportsRelatedChecked}
						type='checkbox'
					/>
					<label htmlFor={`copy-sports-connections-per-image-${blockId}`}>{t('copy_sports_connections_img')}</label>
				</Col>
			</Row>
		</div>
	);
};
