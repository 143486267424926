import * as React from 'react';
import debounce from 'lodash.debounce';
import AsyncSelect from 'react-select/async';
import * as helpers from '../../../../Partials/Shared/react-select-helpers/react-select.helpers';
import HttpService from '../../../../../services/rest/HttpService';
import { Label, FormGroup, Col } from 'reactstrap';

type BirthCityProps = {
	t: any;
	onChange: (selection: any) => void;
	value: any;
	lang: string;
};

const BirthCitySelect: React.FunctionComponent<BirthCityProps> = ({ t, onChange, value, lang }) => {
	const onBirthCitySelect = (selection: any) => onChange(helpers.optionToItem(selection));

	const loadOptions = (search: string, callback: any) => {
		if (search.length > 2) {
			HttpService.getFootballData(search, lang.split('-')[0], 'city')
				.then((response: any) => {
					const options: any = helpers.itemsToOptions(response.data, false, t, 'city');
					callback([...options]);
				})
				.catch((error: unknown) => console.error(error));
		}
	};

	return (
		<Col xs='6'>
			<FormGroup>
				<Label htmlFor='player-manual-data-entry-birth-city'>{t('birth_city')}</Label>
				<AsyncSelect
					inputId='player-manual-data-entry-birth-city'
					value={helpers.itemToOption(value, false, t, 'birth_city')}
					loadOptions={debounce(loadOptions, 500)}
					onChange={onBirthCitySelect}
					placeholder={t('select_birth_city')}
					noOptionsMessage={(inputValue) => inputValue && t('no_options')}
				/>
			</FormGroup>
		</Col>
	);
};

export default BirthCitySelect;
