import 'reflect-metadata';
import { jsonMember, jsonObject } from 'typedjson';
import { assetsPlaceholder } from '../../../constants/assetsPlaceholder';

@jsonObject()
class Logo {
	@jsonMember(String)
	public url: string = assetsPlaceholder.player;
}

@jsonObject()
class Assets {
	@jsonMember(Logo)
	public logo: Logo = new Logo();
}

@jsonObject()
export default class RankingPlayerModel {
	@jsonMember(String)
	public id: string = '';
	@jsonMember(String)
	public name: string = '';
	@jsonMember(String, { name: 'short_name' })
	public shortName: string = '';
	@jsonMember(String)
	public slug: string = '';
	@jsonMember(String, { name: 'three_letter_code' })
	public threeLetterCode: string = '';
	@jsonMember(String, { name: 'birth_date' })
	public birthdate: string = '';
	@jsonMember(String, { name: 'entity_type' })
	public entityType: string = '';
	@jsonMember(Assets)
	public assets: Assets = new Assets();
}
