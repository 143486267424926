import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

type Properties = {
	t: any;
	showModal: boolean;
	toggleModal: (toggle: boolean) => any;
	removeFromSquad: () => void;
	buttonText: string;
	bodyText: string;
};

const RemovePlayerModal: React.FunctionComponent<Properties> = ({ t, showModal, toggleModal, removeFromSquad, bodyText, buttonText }) => {
	return (
		<Modal isOpen={showModal}>
			<ModalHeader className='pl-3 pr-3 pt-2 pb-2 bg-danger' toggle={() => toggleModal(false)}>
				{t('attention')}
			</ModalHeader>
			<ModalBody className='pb-2'>
				<div>{bodyText}</div>
			</ModalBody>
			<ModalFooter className='border-0'>
				<Button id='modal-cancel-button' color='base' onClick={() => toggleModal(false)} className='ml-auto'>
					{t('close')}
				</Button>
				<Button id='modal-remove-button' color='danger' onClick={removeFromSquad}>
					{buttonText}
				</Button>
			</ModalFooter>
		</Modal>
	);
};

export default RemovePlayerModal;
