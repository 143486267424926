import TeamModel from '../../team-select/models/team.model';
import { MatchJson } from './match.json';
import MatchBuilder from './match.builder';

export default class MatchModel {
	readonly id: string = '';
	readonly startTime: string = '';
	readonly type: string = '';
	readonly homeTeam: TeamModel;
	readonly awayTeam: TeamModel;

	private constructor(id: string, startTime: string, type: string, homeTeam: TeamModel, awayTeam: TeamModel) {
		this.id = id;
		this.startTime = startTime;
		this.type = type;
		this.homeTeam = homeTeam;
		this.awayTeam = awayTeam;
	}

	toJson(): MatchJson {
		return {
			id: this.id,
			startTime: this.startTime,
			type: this.type,
			homeTeam: this.homeTeam,
			awayTeam: this.awayTeam,
		};
	}

	static fromJson(json: MatchJson): MatchModel {
		return new MatchModel(json.id, json.startTime, json.type, json.homeTeam, json.awayTeam);
	}
	static builder(match?: MatchModel): MatchBuilder {
		return new MatchBuilder(match);
	}
}
