import TournamentModel from '../../player-profile/models/tournament-model/tournament.model';
import SeasonModel from '../../fixtures/models/season/season.model';
import PlayerModel from './player/player.model';
import { DecoratedPlayersWidgetJson } from './decorated-players-widget.json';
import DecoratedPlayersWidgetModel from './decorated-players-widget.model';
import TeamModel from '../../../../partials/team-select/models/team.model';

export default class DecoratedPlatersWidgetBuilder {
	readonly json: DecoratedPlayersWidgetJson;

	constructor(decoratedPlayers?: DecoratedPlayersWidgetModel | DecoratedPlayersWidgetJson) {
		if (decoratedPlayers && decoratedPlayers instanceof DecoratedPlayersWidgetModel) {
			this.json = decoratedPlayers.toJson();
		} else if (decoratedPlayers) {
			this.json = decoratedPlayers;
		} else {
			this.json = {} as DecoratedPlayersWidgetJson;
		}
	}

	withTournament(tournament: TournamentModel): DecoratedPlatersWidgetBuilder {
		this.json.tournament = tournament;

		return this;
	}

	withSeason(season: SeasonModel): DecoratedPlatersWidgetBuilder {
		this.json.season = season;

		return this;
	}

	withLimit(limit: string): DecoratedPlatersWidgetBuilder {
		this.json.limit = limit;

		return this;
	}

	withStartFrom(startFrom: string): DecoratedPlatersWidgetBuilder {
		this.json.startFrom = startFrom;

		return this;
	}

	withHighlightPlayers(highlightPlayers: PlayerModel[]): DecoratedPlatersWidgetBuilder {
		this.json.highlightPlayers = highlightPlayers;

		return this;
	}

	withTeam(team: TeamModel): DecoratedPlatersWidgetBuilder {
		this.json.team = team;

		return this;
	}

	withPlayer(player: PlayerModel): DecoratedPlatersWidgetBuilder {
		this.json.player = player;

		return this;
	}

	withOffset(offset: string): DecoratedPlatersWidgetBuilder {
		this.json.offset = offset;

		return this;
	}

	withPlayers(players: PlayerModel[]): DecoratedPlatersWidgetBuilder {
		this.json.players = players;

		return this;
	}

	withColumns(columns: string[]): DecoratedPlatersWidgetBuilder {
		this.json.columns = columns;

		return this;
	}

	withCard(card: string): DecoratedPlatersWidgetBuilder {
		this.json.card = card;

		return this;
	}

	withPlayerOffset(playerOffset: string): DecoratedPlatersWidgetBuilder {
		this.json.playerOffset = playerOffset;

		return this;
	}

	withThreeLetterCodeType(threeLetterCodeType: any): DecoratedPlatersWidgetBuilder {
		this.json.threeLetterCodeType = threeLetterCodeType;

		return this;
	}

	build(): DecoratedPlayersWidgetModel {
		return DecoratedPlayersWidgetModel.fromJson(this.json);
	}
}
