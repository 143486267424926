import { GroupJson } from './group.json';
import GroupBuilder from './group.builder';
import StandingsWidgetModel from '../../../blocks/widgets/standings/models/standings-widget.model';

export default class GroupModel {
	readonly id: string;
	readonly name: string;
	readonly standing: StandingsWidgetModel[];

	private constructor(id: string, name: string, standing: StandingsWidgetModel[]) {
		this.id = id;
		this.name = name;
		this.standing = standing;
	}

	toJson(): GroupJson {
		return {
			id: this.id,
			name: this.name,
			standing: this.standing,
		};
	}

	static fromJson(json: GroupJson): GroupModel {
		return new GroupModel(json.id, json.name, json.standing);
	}

	static builder(group?: GroupModel): GroupBuilder {
		return new GroupBuilder(group);
	}
}
