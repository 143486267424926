import React, { FunctionComponent, useState } from 'react';
import { ContentTypes } from '../../../../../constants/content-types';
import { LiveBlogSearchComponent } from '../subcomponents/live-blog-search.component';
import { CreateLiveBlogButtonComponent } from '../subcomponents/live-blog-create-button.component';
import { Button, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Nav, Row } from 'reactstrap';
import LiveBlogModel from '../../models/live-blog.model';
import { Link } from 'react-router-dom';
import moment from 'moment';
import LiveBlogListingModel from '../../models/live-blog-listing.model';
import { ModalDelete } from './delete-modal/modal-delete.component';
import '../../style/live-blog-listing.style.scss';
import HttpService from '../../../../../services/rest/HttpService';
import ReactPaginate from 'react-paginate';
import { reactPaginateProps } from '../../../../Partials/BaseComponents/TableListing/TableListing.helper';
import Project from '../../../../../models/project/Project';
import { determineListingIcon, extractLiveBlogsSearchQueryFromFilters } from './helpers/live-blog-listing.helper';
import { returnObjectForLiveBlogsDeleteSuccess } from '../../../../../store/action-creators/LiveBlogsActionCreator';
import { LiveBlogsAdvancedContentFilter } from '../subcomponents/live-blog-advanced-search/live-blogs-advanced-content-filters.component';
import LiveBlogAdvancedFiltersModel from '../../models/live-blog-advanced-filters.model';
import Profile from '../../../../../models/profile/Profile';
import { toast } from 'react-toastify';
import i18n from '../../../../../i18n';

type Properties = {
	t: any;
	content: LiveBlogListingModel | null;
	handlePageClick: Function;
	contentPagination: any;
	searchContent: any;
	filters: LiveBlogAdvancedFiltersModel;
	getContent: any;
	updateContentFilters: (filters: any) => void;
	project: Project;
	setCurrentPage: Function;
	currentPage: number;
	profile: Profile;
};

export const LiveBlogTableListingContainer: FunctionComponent<Properties> = ({
	t,
	content,
	handlePageClick,
	filters,
	contentPagination,
	searchContent,
	getContent,
	updateContentFilters,
	project,
	setCurrentPage,
	currentPage,
	profile,
}) => {
	const lang = profile.language.split('_')[0];
	const searchQueryFromFilters = extractLiveBlogsSearchQueryFromFilters(filters);
	const permissionDelete = 'delete_liveblogs';
	const universalPermission = '*';

	const [selectedRowForDelete, setSelectedRowForDelete] = useState<null | LiveBlogModel>(null);
	const [contentSearchInput, setContentSearchInput] = useState<string>(filters && filters.searchText ? filters.searchText : '');
	const [pageSearchQuery, setPageSearchQuery] = useState<string>('');
	const [advancedContentSearchInput, setAdvancedContentSearchInput] = useState<string>('');
	const [dropdownOpen, setDropdownOpen] = useState({ id: '', isOpen: false });
	const [toggleFilters, setToggleAdvancedFilters] = useState<boolean>(
		!!(searchQueryFromFilters && searchQueryFromFilters.length > 0 && searchQueryFromFilters !== ''),
	);
	const permissionForDelete = profile.permissions.findIndex((el) => el.name === permissionDelete || el.name === universalPermission) >= 0;

	const onPageClick = (data: any) => {
		const selectedPage = data.selected + 1;
		setCurrentPage(selectedPage);
		const searchQueryFromFilters = extractLiveBlogsSearchQueryFromFilters(filters);
		// Get content with filters
		if (searchQueryFromFilters.length > 0 && searchQueryFromFilters !== '') {
			getContent(selectedPage, project, searchQueryFromFilters);
			// Get content without filters
		} else {
			const searchQuery =
				pageSearchQuery.length > 0 && searchQueryFromFilters.length > 0 && searchQueryFromFilters !== '' ? pageSearchQuery : '';
			getContent(selectedPage, project, searchQuery);
		}

		if (handlePageClick) {
			handlePageClick(selectedPage);
		}
	};

	const updateInputState = (text: string, pageSearchQuery: string) => {
		updateFilters(text);
		setContentSearchInput(text);
		setPageSearchQuery(pageSearchQuery);
		if (pageSearchQuery === '' || pageSearchQuery.length === 0) {
			setCurrentPage(1);
		}
	};

	const updateAdvancedInputState = (text: string) => {
		updateFilters(contentSearchInput);
		setAdvancedContentSearchInput(text);

		if (text === '' || text.length === 0) {
			setCurrentPage(1);
		}
	};

	const updateFilters = (searchText: string) => {
		let f = filters;
		if (f) {
			f.searchText = searchText;
		}

		if (updateContentFilters) {
			updateContentFilters(f);
		}
	};

	const updatePageSearchQuery = (text: string) => {
		setPageSearchQuery(text);

		if (text === '' || text.length === 0) {
			setPageSearchQuery(text);
			setCurrentPage(1);
		}
	};

	const search = () => {
		if (contentSearchInput.match(/^ *$/) !== null && advancedContentSearchInput.match(/^ *$/) !== null) {
			updateInputState('', '');
			updateAdvancedInputState('');
			getContent(currentPage, project);
		} else {
			const contentSearch =
				contentSearchInput.length > 0 ? `title=${contentSearchInput}` + advancedContentSearchInput : `${advancedContentSearchInput}`;
			searchContent(contentSearch, project);
			updatePageSearchQuery(contentSearch);
		}
	};

	const getAllContent = () => {
		getContent(currentPage, project);
	};

	const toggleDropdown = (id: string) => {
		setDropdownOpen({ id, isOpen: !dropdownOpen.isOpen });
	};

	const deleteLiveBlog = () => {
		const id = selectedRowForDelete && selectedRowForDelete.id ? selectedRowForDelete.id : '';
		const searchQueryFromFilters = extractLiveBlogsSearchQueryFromFilters(filters);

		if (id && id.length > 0) {
			return HttpService.deleteLiveBlogById(id)
				.then(() => {
					returnObjectForLiveBlogsDeleteSuccess();
					toast.success(i18n.t('live_blog_delete_success'));
					return getContent(currentPage, project, searchQueryFromFilters);
				})
				.catch(() => {
					toast.error(i18n.t('live_blog_delete_failed'));
				});
		}

		return null;
	};

	const toggleAdvancedFilters = () => {
		setToggleAdvancedFilters(!toggleFilters);
	};

	return (
		<div className='card-body'>
			<div className='content-list-header'>
				<Row>
					<Col col='6' sm='12' md='6' className='d-flex flex-column ml-auto btn-create-new-wrapper mb-2'>
						<CreateLiveBlogButtonComponent t={t} />
					</Col>
					<Col col='6' sm='12' md='6' className='d-flex flex-column ml-auto'>
						<LiveBlogSearchComponent
							t={t}
							project={project}
							searchContent={searchContent}
							updateSearchText={updateInputState}
							currentPage={contentPagination && contentPagination.currentPage}
							getContent={getContent}
							contentSearchInput={contentSearchInput}
							advancedContentSearchInput={advancedContentSearchInput}
							setToggle={toggleAdvancedFilters}
						/>
					</Col>
				</Row>
				<Row>
					<Col className='ml-auto'>
						{toggleFilters && (
							<LiveBlogsAdvancedContentFilter
								t={t}
								filtersInSidebar={false}
								getAllContent={getAllContent}
								searchContent={search}
								contentSearchInput={contentSearchInput}
								updateLiveBlogsAdvancedSearchText={updateAdvancedInputState}
							/>
						)}
					</Col>
				</Row>
			</div>
			<table className='table table-striped content-list-table' data-qa={`table-${ContentTypes.LIVE_BLOG}`}>
				<thead>
					<tr>
						<th className='w-title col-md-4 col-sm-6'>{t('title')}</th>
						<th className={`col-md-2`}>{t('status')}</th>
						<th className={`d-none d-md-table-cell col-md-3`}>{t('start_time')}</th>
						<th className='col-md-2 text-right'>{t('actions')}</th>
					</tr>
				</thead>
				<tbody>
					{content && content.data && content.data.length > 0 ? (
						content.data.map((blog: LiveBlogModel, index: number) => {
							return (
								<tr key={index} data-qa={`table-row-${ContentTypes.LIVE_BLOG}`}>
									<td className='w-title col-md-4 col-sm-6 align-middle'>
										<Link to={`/smp/live-blogs/editorial-admin/${blog.id}`}>
											{determineListingIcon(blog)}
											<span className={`pl-1 text-break text-dark`}>{blog.title}</span>
										</Link>
									</td>
									<td className={`col-md-2 align-middle`}>{blog.status}</td>
									<td className={`d-none d-md-table-cell col-md-3 align-middle`}>
										{blog.start_time && moment(blog.start_time).locale(lang).format('DD MMMM YYYY, HH:mm')}
									</td>
									<td className='col-md-2 align-middle'>
										<Nav className='justify-content-end'>
											<Dropdown
												nav
												className='justify-content-end'
												isOpen={dropdownOpen.id == blog.id && dropdownOpen.isOpen}
												toggle={() => toggleDropdown(blog.id ? blog.id : '')}
											>
												<DropdownToggle id={`toggle-menu-caret-${blog.id}`} nav caret className='d-flex align-items-center p-0 text-secondary'>
													<Link to={`/smp/live-blogs/configuration/edit/${blog.id}`}>
														<Button className='btn-warning'>
															<i className='fa fa-pencil p-1' />
															{t('edit')}
														</Button>
													</Link>
												</DropdownToggle>
												<DropdownMenu>
													<DropdownItem className='p-0 border-0'>
														{permissionForDelete && (
															<Button
																className='btn btn-danger d-flex flex-fill justify-content-center dropdown-row-btn'
																onClick={() => setSelectedRowForDelete(blog)}
															>
																<i className='fa fa-trash p-1 text-white' /> {t('delete')}
															</Button>
														)}
													</DropdownItem>
												</DropdownMenu>
											</Dropdown>
										</Nav>
									</td>
								</tr>
							);
						})
					) : (
						<tr>
							<h6 className='d-flex justify-content-center mt-4'>{t('no_data_found')}</h6>
						</tr>
					)}
				</tbody>
			</table>
			{content && content.meta && <ReactPaginate {...reactPaginateProps(t, contentPagination, onPageClick)} />}
			<ModalDelete
				t={t}
				selectedRowForDelete={selectedRowForDelete}
				setSelectedRowForDelete={setSelectedRowForDelete}
				deleteContent={deleteLiveBlog}
			/>
		</div>
	);
};
