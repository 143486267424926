import { ImageJson } from './ImageJson';
import ImageBuilder from './ImageBuilder';
import EnumItem from '../enum/EnumItem';
import ImageUrls from './urls/ImageUrls';

export default class Image {
	readonly id: string;
	readonly description: string;
	readonly owner: EnumItem;
	readonly type: string;
	readonly urls: ImageUrls;
	readonly generic: any;
	readonly alt: string;
	readonly caption: string;
	readonly createdAt: string;
	readonly updatedAt: string;
	readonly authors: any;
	readonly path: string;

	private constructor(
		id: string,
		description: string,
		owner: EnumItem,
		type: string,
		urls: any,
		generic: any,
		alt: string,
		caption: string,
		createdAt: string,
		updatedAt: string,
		authors: any,
		path: string,
	) {
		this.id = id;
		this.description = description;
		this.owner = owner;
		this.type = type;
		this.urls = urls;
		this.generic = generic;
		this.alt = alt;
		this.caption = caption;
		this.createdAt = createdAt;
		this.updatedAt = updatedAt;
		this.authors = authors;
		this.path = path;
	}

	toJSON(): ImageJson {
		return {
			id: this.id,
			description: this.description,
			owner: this.owner,
			type: this.type,
			urls: this.urls,
			generic: this.generic,
			alt: this.alt,
			caption: this.caption,
			createdAt: this.createdAt,
			updatedAt: this.updatedAt,
			authors: this.authors,
			path: this.path,
		};
	}

	static fromJSON(json: ImageJson): Image {
		return new Image(
			json.id,
			json.description,
			json.owner,
			json.type,
			json.urls,
			json.generic,
			json.alt,
			json.caption,
			json.createdAt,
			json.updatedAt,
			json.authors,
			json.path,
		);
	}

	static builder(image?: Image): ImageBuilder {
		return new ImageBuilder(image);
	}
}
