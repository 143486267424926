import React, { FunctionComponent } from 'react';
import { findEmbedVideoCode, isEmbedVideo, isGallery, isVideo } from '../../helpers/external-main-media';
import MainMediaModel from '../../../../../../models/v2/main-media/main-media.model';
import MainContentMediaContainer from '../../../Media/subcomponents/media-content/MainContentMediaContainer';
import MainMedia from '../../../../../../models/mainmedia/MainMedia';
import { ContentTypes } from '../../../../../../constants/content-types';
import { connect } from 'react-redux';
import Project from '../../../../../../models/project/Project';
import { onExternalResourceUpdate } from '../../helpers/redux-updater';
import MediaVideoEmbed from '../../../Media/subcomponents/media-embed/MediaVideoEmbed';
import { extractMainContentTypeBasedOnUrl } from '../../../../../../global-helpers/global.helpers';
import { autoGenerateReduxPropsNamingForContentModels } from '../../../../../../services/content-models-service/ContentModelService';

type Properties = {
	t: any;
	project: Project;
	mainMediaRedux: MainMediaModel[];
	onRemoveElement: (type: string, subType: string) => any;
};

const ExternalMainMediaList: FunctionComponent<Properties> = ({ t, project, mainMediaRedux, onRemoveElement }) => {
	const onExternalMediaChange = (media: MainMedia) => {
		const oldMainMediaToNew = { ...media } as MainMediaModel;
		onExternalResourceUpdate(oldMainMediaToNew);
	};

	return (
		<>
			{mainMediaRedux.map((item: MainMediaModel, index: number) => {
				const mainMediaAsOldModel = item as MainMedia;

				if (isVideo(item)) {
					return (
						<MainContentMediaContainer
							onRemove={onRemoveElement}
							value={mainMediaAsOldModel}
							index={index}
							key='video-type-media-key'
							onContentSelect={onExternalMediaChange}
							t={t}
							project={project}
							contentType={ContentTypes.VIDEO}
						/>
					);
				} else if (isGallery(item)) {
					return (
						<MainContentMediaContainer
							onRemove={onRemoveElement}
							value={mainMediaAsOldModel}
							index={index}
							key='gallery-type-media-key'
							onContentSelect={onExternalMediaChange}
							t={t}
							project={project}
							contentType={ContentTypes.GALLERY}
						/>
					);
				} else if (isEmbedVideo(item)) {
					return (
						<MediaVideoEmbed
							key='embed-video-type-media-key'
							index={index}
							t={t}
							onChange={onExternalMediaChange}
							embedCode={findEmbedVideoCode(mainMediaRedux)}
							onRemove={onRemoveElement}
							blocks={[]}
							contentType={extractMainContentTypeBasedOnUrl() || ContentTypes.ARTICLE}
						/>
					);
				}
			})}
		</>
	);
};

function mapStateToProps(state: any) {
	const { tempPropertyNaming, nestedTempPropertyNaming } = autoGenerateReduxPropsNamingForContentModels();

	return {
		mainMediaRedux: state[tempPropertyNaming][nestedTempPropertyNaming].mainMedia || [],
		project: state.project.currentProject,
	};
}

export default connect(mapStateToProps)(ExternalMainMediaList);
