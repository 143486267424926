import BlockModel from '../../models/block.model';

export const updateBlockWithUndoRedo = (
	data: BlockModel,
	blockIndex: number,
	currentBodyBlocksState: BlockModel[],
	newestBodyBlocksState: BlockModel[],
	updateBlockyUndoRedo: Function,
	updateBlockyUndoRedoPresent: Function,
	onChange: Function,
) => {
	try {
		const previousBlockContentState = currentBodyBlocksState[blockIndex].data.content || null;
		const currentBlockContentState = data.data.content || null;
		if (previousBlockContentState !== currentBlockContentState) {
			// content changed
			if (!previousBlockContentState && data.type === 'link') {
				// only for block element of type 'link' which does not have content first initially
				updateBlockyUndoRedoPresent(newestBodyBlocksState);
			} else {
				updateBlockyUndoRedo(newestBodyBlocksState, [...currentBodyBlocksState]);
			}
			onChange(newestBodyBlocksState);
		} else if (currentBlockContentState === null && previousBlockContentState === null) {
			onChange(newestBodyBlocksState);
		} else {
			const previousBlockTagsState = currentBodyBlocksState[blockIndex].data.tags || [];
			const currentBlockTagsState = data.data.tags || [];
			const bothStateTagsEmptyFlag = previousBlockTagsState.length === 0 && currentBlockTagsState.length === 0;
			if (previousBlockTagsState !== currentBlockTagsState && !bothStateTagsEmptyFlag) {
				// tagging changed
				updateBlockyUndoRedoPresent(newestBodyBlocksState);
				onChange(newestBodyBlocksState);
			}
		}
	} catch {}
};
