import React, { FunctionComponent, useState } from 'react';
import WikiListModel from '../../../../models/Wikipages/List/WikiListModel';
import { TableHead } from './subcomponents/TableHead';
import { TableRows } from './subcomponents/TableRows';
import Profile from '../../../../models/profile/Profile';
import './styles.scss';
import ReactPaginate from 'react-paginate';
import { generateLinkTitleByContentType, reactPaginateProps } from './TableListing.helper';
import ModalDelete from './subcomponents/ModalDelete';
import WikiRowModel from '../../../../models/Wikipages/Row/WikiRowModel';
import Project from '../../../../models/project/Project';
import { extractWikiPageSearchQueryFromFilters } from '../../Shared/wiki-pages-advanced-content-filter/helpers/wiki-pages-advanced-content-filter.helper';
import WikiPagesAdvancedFilterModel from '../../Shared/wiki-pages-advanced-content-filter/models/wiki-pages-advanced-filter.model';
import SearchBar from '../ListingComponent/subcomponents/SearchBar/SearchBar';
import { Col, Row } from 'reactstrap';
import WikiPagesAdvancedContentFilterComponent from '../../Shared/wiki-pages-advanced-content-filter/wiki-pages-advanced-content-filter.component';
import { Link } from 'react-router-dom';

type TableListingProps = {
	t: any;
	profile: Profile;
	content: WikiListModel | null;
	handlePageClick: Function;
	contentPagination: any;
	searchContent: any;
	contentType: string;
	filters: any;
	getContent: any;
	updateContentFilters: (filters: any) => any;
	project: Project;
	analytics: any;
	setCurrentPage: Function;
	currentPage: number;
};

const TableListing: FunctionComponent<TableListingProps> = ({
	t,
	profile,
	content,
	handlePageClick,
	filters,
	contentType,
	contentPagination,
	searchContent,
	getContent,
	updateContentFilters,
	project,
	analytics,
	setCurrentPage,
	currentPage,
}) => {
	const [selectedRowForDelete, setSelectedRowForDelete] = useState<null | WikiRowModel>(null);
	const [contentSearchInput, setContentSearchInput] = useState<string>(filters && filters.searchText ? filters.searchText : '');
	const [pageSearchQuery, setPageSearchQuery] = useState<string>('');
	const [advancedContentSearchInput, setAdvancedContentSearchInput] = useState<string>('');

	const onPageClick = (data: any) => {
		const selectedPage = data.selected + 1;
		setCurrentPage(selectedPage);
		const searchQueryFromFilters = extractWikiPageSearchQueryFromFilters(filters);
		// Get content with filters
		if (searchQueryFromFilters.length > 0 && searchQueryFromFilters !== '*') {
			getContent(selectedPage, project, searchQueryFromFilters);
			// Get content without filters
		} else {
			const searchQuery =
				pageSearchQuery.length > 0 && searchQueryFromFilters.length > 0 && searchQueryFromFilters !== '*' ? pageSearchQuery : '';
			getContent(selectedPage, project, searchQuery);
		}

		if (handlePageClick) {
			handlePageClick(selectedPage);
		}
	};

	const updateInputState = (text: string, pageSearchQuery: string) => {
		updateFilters(text);
		setContentSearchInput(text);
		setPageSearchQuery(pageSearchQuery);
		if (pageSearchQuery === '' || pageSearchQuery.length === 0) {
			setCurrentPage(1);
		}
	};

	const updateAdvancedInputState = (text: string) => {
		updateFilters(contentSearchInput);
		setAdvancedContentSearchInput(text);

		if (text === '' || text.length === 0) {
			setCurrentPage(1);
		}
	};

	const updateFilters = (searchText: string) => {
		if (updateContentFilters) {
			updateContentFilters(WikiPagesAdvancedFilterModel.builder(filters).withSearchText(searchText).build());
		}
	};

	const updatePageSearchQuery = (text: string) => {
		setPageSearchQuery(text);

		if (text === '' || text.length === 0) {
			setPageSearchQuery(text);
			setCurrentPage(1);
		}
	};

	const search = () => {
		if (contentSearchInput.match(/^ *$/) !== null && advancedContentSearchInput.match(/^ *$/) !== null) {
			updateInputState('', '');
			updateAdvancedInputState('');
			getContent(currentPage, project);
		} else {
			const contentSearch = contentSearchInput.length > 0 ? contentSearchInput + advancedContentSearchInput : `*${advancedContentSearchInput}`;
			searchContent(contentSearch, project);
			updatePageSearchQuery(contentSearch);
		}
	};

	const getAllContent = () => {
		getContent(currentPage, project);
	};

	return (
		<>
			<div className='content-list-header'>
				<Row>
					<Col col='6' sm='12' md='6' className='d-flex flex-column ml-auto btn-create-new-wrapper'>
						<Link to={`/smp/${generateLinkTitleByContentType(contentType)}/create`} className='btn btn-primary btn-create-new'>
							<i className={'fa fa-plus-circle'}>&nbsp;</i>
							{t('new_wiki')}
						</Link>
					</Col>
					<Col col='6' sm='12' md='6' className='d-flex flex-column ml-auto'>
						<SearchBar
							showSearchBar={true}
							t={t}
							currentProject={project}
							searchContent={searchContent}
							currentPage={contentPagination && contentPagination.currentPage}
							advancedContentSearchInput={advancedContentSearchInput}
							getContent={getContent}
							analytics={analytics}
							updateSearchText={updateInputState}
							contentSearchInput={contentSearchInput}
							updateFilters={updateFilters}
						/>
					</Col>
				</Row>
				<Row>
					<Col className='ml-auto'>
						<WikiPagesAdvancedContentFilterComponent
							t={t}
							searchContent={search}
							filtersInSidebar={false}
							getAllContent={getAllContent}
							contentSearchInput={contentSearchInput}
							contentType={contentType}
							updateWikiPagesAdvancedSearchText={updateAdvancedInputState}
						/>
					</Col>
				</Row>
			</div>
			<table className='table table-striped content-list-table' data-qa={`table-${contentType}`}>
				<TableHead t={t} />
				<TableRows
					t={t}
					content={content && content.data ? content.data : []}
					profile={profile}
					setSelectedRowForDelete={setSelectedRowForDelete}
				/>
			</table>
			{content && content.meta && content.meta.pagination && <ReactPaginate {...reactPaginateProps(t, contentPagination, onPageClick)} />}
			<ModalDelete t={t} selectedRowForDelete={selectedRowForDelete} setSelectedRowForDelete={setSelectedRowForDelete} />
		</>
	);
};

export default TableListing;
