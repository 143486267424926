import React from 'react';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { blockManagementService, blockWrapperService } from '../../../../subcomponents/blocky.component';
import BlockValidation from '../../../../helpers/block-validation.helper';
import BlockModel from '../../../../models/block.model';
import PlayerSeasonSelectComponent from '../../../partials/player-select/player-season-select.component';
import EntitySeasonsSelectModel from '../../../../partials/player-seasons/model/entity-seasons-select.model';
import MatchByStageOrSeasonSelectContainer from '../../../partials/match-select/match-by-stage-select.component';
import MatchModel from '../../../../../../../models/v2/match/entity/match.model';
import FootballTeamWidgetModel from '../models/football-team-widget.model';
import TeamModel from '../../../../../../../models/v2/Team/Entity/TeamModel';
import TeamSelectComponent from '../../../partials/team-select/team-select.component';
import { ContentTypes } from '../../../../../../../constants/content-types';
import GenericSelect from '../../../../partials/generic-select/generic-select-component';
import { getDefaultType } from '../../../../blocks/content/embed-block/helpers/embed-block-edit.helper';
import {
	teamProfileInfoTabOptions,
	teamProfileStatsTabOptions,
	WidgetTeamDefaultTabs,
	WidgetTeamDefaultTabsWithMatches,
	WidgetTeamTabs,
} from '../constants/football-team.constants';
import { SportTypes } from '../../../../../../../constants/sport.types';
import { SPORT_SELECT_ATTRIBUTES } from '../../helpers/component-attributes.constants';
import OddsModelV2 from '../../odds/models/odds-widget.model';
import { featuresService } from '../../../../../../../App';
import { FeatureTypes } from '../../../../../../../services/feature-service/features.enum';
import { OddsEditComponent } from '../../odds/components/odds-edit.component';
import { createBlock } from '../../../partials/helpers/blocky-sidebar-communication/blocky-sidebar-communication.helper';
import { WidgetsV2Ids } from '../../../../constants/block.types';
import FansUnitedEnabledComponent from '../../../partials/fans-united/fans-united-enabled.component';
import { ElementsSelect } from '../../../partials/elements-select/widget-elements-select';
import { SelectMenuOptionType } from '../../../../../../../models/v2/general/select.model';
import { onRefreshTimeChangeFromMainComponent } from '../../../partials/refresh-time-input/helper';
import RefreshTimeWrapper from '../../../partials/refresh-time-input/refresh-time-wrapper';
import { MatchStatuses } from '../../../../../../../constants/event.types';
type Properties = {
	block: BlockModel;
	t: any;
	contentData: any;
	contentLanguage: string;
};

type State = {
	widgetModel: FootballTeamWidgetModel;
	oddsModel: OddsModelV2;
	isValid: boolean;
};

export default class FootballTeamEditComponent extends React.Component<Properties, State> {
	constructor(props: Properties) {
		super(props);
		this.state = {
			widgetModel: createBlock(
				WidgetsV2Ids.widgetFootballTeam,
				props.block.data.preview && props.block.data.preview.widgetModel,
				props.contentData,
			),
			oddsModel:
				props.block && props.block.data.preview && props.block.data.preview.odds
					? OddsModelV2.builder(props.block.data.preview.odds).build()
					: OddsModelV2.builder().withMainBookmaker(null).build(),
			isValid: false,
		};
	}

	componentDidMount(): void {
		blockWrapperService.registerOnSaveFunc({ blockId: this.props.block.id, func: this.onSave });
	}

	componentDidUpdate(prevProps: Readonly<Properties>, prevState: Readonly<State>, snapshot?: any) {
		if (
			(this.state.widgetModel.displayOdds !== prevState.widgetModel.displayOdds &&
				!this.state.widgetModel.displayOdds &&
				this.state.widgetModel.team &&
				!this.state.widgetModel.team.id) ||
			this.state.widgetModel.match !== prevState.widgetModel.match
		) {
			const emptyOddsModel = OddsModelV2.builder().build();

			this.setState({ ...this.state, oddsModel: emptyOddsModel });
		}
	}

	onSave = (index: number) => {
		const validation = new BlockValidation();
		const data = { widgetModel: this.state.widgetModel, oddsModel: this.state.oddsModel };
		const isValid = validation.validate(this.props.block, data) === true;

		this.setState({ ...this.state, isValid });

		const bookmakerSelectionEnabled = featuresService && featuresService.areFeaturesEnabled([FeatureTypes.ALLOW_BOOKMAKER_SELECTION]);
		const odds = OddsModelV2.builder(this.state.oddsModel)
			.withMainBookmaker(bookmakerSelectionEnabled ? this.state.oddsModel.mainBookmaker : null)
			.build();

		if (isValid) {
			const block = Object.assign({}, this.props.block);
			block.data.preview = { widgetModel: this.state.widgetModel, odds };
			blockManagementService.blockUpdate(block, index);
		} else {
			return isValid;
		}
	};

	componentWillUnmount(): void {
		blockWrapperService.unregisterOnSaveFunc(this.props.block.id);
	}

	updateWidgetModelState = (model: FootballTeamWidgetModel) => {
		this.setState({ ...this.state, widgetModel: model });
	};

	onTeamChange = (team: TeamModel) => {
		this.updateWidgetModelState(
			FootballTeamWidgetModel.builder(this.state.widgetModel)
				.withTeam(team)
				.withMatch({} as MatchModel)
				.withTournamentSeason({} as EntitySeasonsSelectModel)
				.build(),
		);
	};

	onSeasonSelect = (season: EntitySeasonsSelectModel) => {
		this.updateWidgetModelState(
			FootballTeamWidgetModel.builder(this.state.widgetModel)
				.withTournamentSeason(season)
				.withMatch({} as MatchModel)
				.build(),
		);
	};

	onDisplayTabsChange = (event: any) => {
		this.updateWidgetModelState(FootballTeamWidgetModel.builder(this.state.widgetModel).withDisplayTabs(event.target.checked).build());
	};

	onTabsChange = (tabs: SelectMenuOptionType[] | null) => {
		const tabsToStringArray = tabs && tabs.length > 0 ? tabs.map((dataOptions: SelectMenuOptionType) => dataOptions.value.toString()) : [];
		this.updateWidgetModelState(FootballTeamWidgetModel.builder(this.state.widgetModel).withTabs(tabsToStringArray).build());
	};

	onDefaultTabChange = (tab: any) => {
		this.updateWidgetModelState(FootballTeamWidgetModel.builder(this.state.widgetModel).withDefaultTab(tab).build());
	};

	onMatchSelect = (match: MatchModel) => {
		const displayOdds = match && match.eventStatus && match.eventStatus.code !== MatchStatuses.FINISHED;

		this.updateWidgetModelState(
			FootballTeamWidgetModel.builder(this.state.widgetModel)
				.withMatch(match)
				.withDefaultTab(null)
				.withFansUnitedEnabled(false)
				.withFansUnitedExpanded(false)
				.withDisplayOdds(displayOdds)
				.build(),
		);
	};

	onDisplayOddsChange = (e: any) => {
		this.updateWidgetModelState(FootballTeamWidgetModel.builder(this.state.widgetModel).withDisplayOdds(e.target.checked).build());
	};

	onFansUnitedEnabledChange = (event: any) => {
		this.updateWidgetModelState(
			FootballTeamWidgetModel.builder(this.state.widgetModel)
				.withFansUnitedEnabled(event.target.checked)
				.withFansUnitedExpanded(event.target.checked)
				.build(),
		);
	};

	onFansUnitedExpandedChange = (event: any) => {
		this.updateWidgetModelState(FootballTeamWidgetModel.builder(this.state.widgetModel).withFansUnitedExpanded(event.target.checked).build());
	};

	onOddsWidgetChange = (oddsModel: OddsModelV2) => this.setState({ ...this.state, oddsModel });

	onInfoParametersChange = (infoParameters: string[]) => {
		this.updateWidgetModelState(FootballTeamWidgetModel.builder(this.state.widgetModel).withInfoParameters(infoParameters).build());
	};

	onStatsParametersChange = (statsParameters: string[]) => {
		this.updateWidgetModelState(FootballTeamWidgetModel.builder(this.state.widgetModel).withStatsParameters(statsParameters).build());
	};

	onRefreshTimeChange = (refreshTimeData: SelectMenuOptionType) => {
		onRefreshTimeChangeFromMainComponent(refreshTimeData, FootballTeamWidgetModel, this.state.widgetModel, this.updateWidgetModelState);
	};

	render() {
		const { widgetModel, isValid, oddsModel } = this.state;
		const { t, block } = this.props;
		const bookmakerSelectionEnabled = featuresService.areFeaturesEnabled([FeatureTypes.ALLOW_BOOKMAKER_SELECTION]);
		const matchNotStarted = widgetModel.match && widgetModel.match.eventStatus && widgetModel.match.eventStatus.code !== 'finished';
		const defaultTab =
			widgetModel.match && widgetModel.match.id
				? getDefaultType(WidgetTeamDefaultTabsWithMatches, t)
				: getDefaultType(WidgetTeamDefaultTabs, t);

		return (
			<div data-qa='football-team-block-edit'>
				<Row>
					<Col>
						<FormGroup>
							<TeamSelectComponent
								isMulti={false}
								isBlocky
								isValid={isValid}
								selectedTeam={widgetModel.team ? widgetModel.team : {}}
								onTeamSelect={(team: TeamModel) => this.onTeamChange(team)}
								t={t}
								isClearable
								isRequired
								searchByKeyword={true}
								id={`${SportTypes.FOOTBALL}-${SPORT_SELECT_ATTRIBUTES.TEAM_SELECT}`}
							/>
						</FormGroup>
					</Col>
				</Row>
				{widgetModel.team && widgetModel.team.id && widgetModel.team.id.toString().length > 0 && (
					<Row>
						<Col>
							<FormGroup>
								<PlayerSeasonSelectComponent
									isClearable
									isRequired={true}
									isBlocky
									entityId={widgetModel.team.id}
									selectedSeason={widgetModel.tournamentSeason}
									onEntitySeasonSelect={this.onSeasonSelect}
									isValid={isValid}
									t={t}
									entityType={ContentTypes.TEAM}
									id={`${SportTypes.FOOTBALL}-${SPORT_SELECT_ATTRIBUTES.PLAYER_SEASON_SELECT}`}
								/>
							</FormGroup>
						</Col>
					</Row>
				)}
				<Row>
					{widgetModel.tournamentSeason && widgetModel.tournamentSeason.seasonId && (
						<Col>
							<FormGroup>
								<MatchByStageOrSeasonSelectContainer
									isMulti={false}
									isBlocky
									isValid={isValid}
									selectedMatch={widgetModel.match}
									seasonId={widgetModel.tournamentSeason && widgetModel.tournamentSeason.seasonId ? widgetModel.tournamentSeason.seasonId : null}
									onMatchSelect={this.onMatchSelect}
									t={this.props.t}
									isClearable
									preselectMatch={false}
									isRequired={false}
									team={widgetModel.team}
									teamName={widgetModel.team && widgetModel.team.name}
									entityType={ContentTypes.TEAM}
									id={`${SportTypes.FOOTBALL}-${SPORT_SELECT_ATTRIBUTES.MATCH_BY_STAGE_SELECT}`}
								/>
							</FormGroup>
						</Col>
					)}
				</Row>
				<Row className='mb-2'>
					<Col>
						<FormGroup key={`display-tabs`} check className='radio'>
							<Input
								key='display-tabs-input'
								className='form-check-input'
								type='checkbox'
								id={`display-tabs-checkbox-${this.props.block.id}`}
								name='radios'
								onChange={this.onDisplayTabsChange}
								checked={widgetModel.displayTabs}
								data-qa='display-tabs-checkbox'
							/>
							<Label check key='display-tabs-label' className='form-check-label' for={`display-tabs-checkbox-${this.props.block.id}`} />
							{this.props.t('display_tabs')}
						</FormGroup>
					</Col>
				</Row>
				{widgetModel.displayTabs && (
					<>
						<Row className='mt-2' data-qa='football-player-profile-tabs-select'>
							<Col>
								<FormGroup>
									<Label>{t('tabs')}</Label>
									<GenericSelect
										t={t}
										isMulti={true}
										options={WidgetTeamTabs}
										value={widgetModel.tabs ? WidgetTeamTabs(t).filter((el) => widgetModel.tabs.includes(el.value.toString())) : []}
										onGenericOptionSelect={this.onTabsChange}
									/>
								</FormGroup>
							</Col>
						</Row>
						<Row className='mt-2' data-qa='football-team-profile-default-tab-select'>
							<Col>
								<FormGroup>
									<Label htmlFor={`${SportTypes.FOOTBALL}-${SPORT_SELECT_ATTRIBUTES.DEFAULT_TAB}`}>{t('default_tab')}</Label>
									<GenericSelect
										t={t}
										isMulti={false}
										options={widgetModel.match && widgetModel.match.id ? WidgetTeamDefaultTabsWithMatches : WidgetTeamDefaultTabs}
										value={widgetModel.defaultTab ? widgetModel.defaultTab : this.onDefaultTabChange(defaultTab)}
										onGenericOptionSelect={this.onDefaultTabChange}
									/>
								</FormGroup>
							</Col>
						</Row>
					</>
				)}
				{widgetModel.team && widgetModel.team.id > 0 && (
					<>
						<Row className='mt-2' data-qa='football-team-profile-elements-select'>
							<Col>
								<FormGroup>
									<Label htmlFor={`${SportTypes.FOOTBALL}-${SPORT_SELECT_ATTRIBUTES.ELEMENTS}`}>{t('select_info')}</Label>
									<ElementsSelect
										value={widgetModel.infoParameters}
										onSelect={this.onInfoParametersChange}
										t={t}
										isMulti
										elements={teamProfileInfoTabOptions}
									/>
								</FormGroup>
							</Col>
						</Row>
						<Row className='mt-2' data-qa='football-team-profile-elements-select'>
							<Col>
								<FormGroup>
									<Label htmlFor={`${SportTypes.FOOTBALL}-${SPORT_SELECT_ATTRIBUTES.ELEMENTS}`}>{t('select_stats')}</Label>
									<ElementsSelect
										value={widgetModel.statsParameters}
										onSelect={this.onStatsParametersChange}
										t={t}
										isMulti
										elements={teamProfileStatsTabOptions}
									/>
								</FormGroup>
							</Col>
						</Row>
					</>
				)}
				{bookmakerSelectionEnabled && matchNotStarted && (
					<Row className='mb-2'>
						<Col>
							<FormGroup key={`display-odds-markets`} check className='radio'>
								<Input
									key={`display-odds-markets-input`}
									className='form-check-input'
									type='checkbox'
									id={`${SportTypes.FOOTBALL}-${SPORT_SELECT_ATTRIBUTES.DISPLAY_ODDS_SELECT}`}
									name='radios'
									onChange={this.onDisplayOddsChange}
									checked={widgetModel.displayOdds ? widgetModel.displayOdds : false}
								/>
								<Label
									htmlFor={`${SportTypes.FOOTBALL}-${SPORT_SELECT_ATTRIBUTES.DISPLAY_ODDS_SELECT}`}
									key={`display-odds-markets-label`}
									check
									className='form-check-label'
									for={`display-odds-markets-checkbox-${this.props.block.id}`}
								>
									{this.props.t('display_odds')}
								</Label>
							</FormGroup>
						</Col>
					</Row>
				)}
				{widgetModel && widgetModel.displayOdds && bookmakerSelectionEnabled && matchNotStarted && (
					<OddsEditComponent
						widgetType={WidgetsV2Ids.widgetFootballTeam}
						block={this.props.block}
						isStandAlone={false}
						t={t}
						match={widgetModel && widgetModel.match ? widgetModel.match : oddsModel.match}
						onOddsWidgetChange={this.onOddsWidgetChange}
						displayOddsLabel={true}
						isValid={this.state.isValid}
						currentOdds={oddsModel}
					/>
				)}
				{featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.FANS_UNITED) && matchNotStarted && (
					<FansUnitedEnabledComponent
						fansUnitedEnabledChecked={widgetModel.fansUnitedEnabled}
						fansUnitedExpandedChecked={widgetModel.fansUnitedExpanded}
						blockId={block.id}
						t={t}
						onFansUnitedEnabledCheck={this.onFansUnitedEnabledChange}
						onFansUnitedExpandCheck={this.onFansUnitedExpandedChange}
						sport={SportTypes.FOOTBALL}
					/>
				)}
				<RefreshTimeWrapper value={this.state.widgetModel.refreshTime} onRefreshTimeChange={this.onRefreshTimeChange} />
			</div>
		);
	}
}
