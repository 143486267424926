import SportOddsBookmakerModel from '../../../../../../../models/sport-odds/sport-odds-bookmaker.model';
import TeamModel from '../../../../../../../models/v2/Team/Entity/TeamModel';
import MatchModel from '../../../../../../../models/v2/match/entity/match.model';
import { RefreshTimeValuesType } from '../../../partials/refresh-time-input/helper';
import { StakesJson } from './stakes-widget.json';
import StakesModel from './stakes-widget.model';

export default class StakesBuilder {
	readonly json: StakesJson;

	constructor(odds?: StakesModel | StakesJson) {
		if (odds && odds instanceof StakesModel) {
			this.json = odds.toJson();
		} else if (odds) {
			this.json = odds;
		} else {
			this.json = {} as StakesJson;
		}
	}

	withTeam(team: TeamModel): this {
		this.json.team = team;

		return this;
	}

	withMatch(match: MatchModel): this {
		this.json.match = match;

		return this;
	}

	withAmount(amount: number): this {
		this.json.amount = amount;

		return this;
	}

	withFixedAmount(fixedAmount: boolean): this {
		this.json.fixedAmount = fixedAmount;

		return this;
	}

	withBookmakers(bookmakers: SportOddsBookmakerModel[] | null): this {
		this.json.bookmakers = bookmakers;

		return this;
	}

	withRefreshTime(refreshTime: RefreshTimeValuesType): this {
		this.json.refreshTime = refreshTime;

		return this;
	}

	build(): StakesModel {
		return StakesModel.fromJson(this.json);
	}
}
