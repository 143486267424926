import React from 'react';
import { store } from '../../../../store/store';
import Article from '../../../../models/article/Article';
import HttpService from '../../../../services/rest/HttpService';
import { previewFormPost } from '../../../Resources/Articles/Helpers/ArticlePreviewHelper';
import { ContentTypes } from '../../Blocky/constants/block.types';
import ModelMapper from '../../../../models/ModelMapper';

export function postArticlePreview(articleId: string) {
	const project = store.getState().project.currentProject;
	HttpService.getArticle(articleId).then((res: any) => {
		const article = ModelMapper.remapArticleFromResponse(res.data.data);
		const articleRequest = Article.builder(article).toRequestJson();
		const headers = { Project: project.domain };

		const previewRequest = HttpService.post('/articles/preview', articleRequest, headers);
		const relatedRequest = HttpService.get(`v2/articles/${articleId}/related`, null, headers);

		Promise.all([previewRequest, relatedRequest])
			.then((response: any) => {
				previewFormPost(response[0].data.data, project.previewUrl, response[1].data.data);
			})
			.catch((error: any) => {});
	});
}

type Properties = {
	contentId: string;
	display: boolean;
	t: any;
	rowIndex: number;
};

export const ArticlePreviewButton: React.FunctionComponent<Properties> = ({ contentId, display, t, rowIndex }) => {
	const buttonStyle = 'm-1 btn btn-sm btn-info d-flex flex-fill justify-content-center';

	if (display) {
		return (
			<div id={`article-preview-btn-${rowIndex}`} className={buttonStyle} onClick={() => postArticlePreview(contentId)}>
				<i className={'fa fa-icon fa-eye p-1 text-secondary'} /> {t('preview')} {t(ContentTypes.article)}
			</div>
		);
	}

	return null;
};
