import React from 'react';
import BlockUpdateController from '../../block-update-controller.component';
import FunctionalIFrameComponent from '../../../v2/custom-block/custom-block.iframe';
import BlockModel from '../../../models/block.model';
import { blockManagementService } from '../../../subcomponents/blocky.component';

export type Properties = {
	block: BlockModel;
	blockIndex: number;
	t: any;
};

const CustomBlock: React.FunctionComponent<Properties> = (props) => {
	return (
		<FunctionalIFrameComponent
			onCustomBlockSubmit={(block: BlockModel) => {
				blockManagementService.blockUpdate(block, props.blockIndex);
			}}
			index={props.blockIndex}
			block={props.block}
			id={props.block.data.id}
			title={props.block.data.name}
			appURL={props.block.data.application_url}
		/>
	);
};

export default BlockUpdateController(CustomBlock);
