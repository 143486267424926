import { MainMediaJson } from './MainMediaJson';
import MainMediaBuilder from './MainMediaBuilder';

export default class MainMedia {
	readonly description: string;
	readonly resourceId: string;
	readonly resourceType: string;
	readonly resourceSubtype: string;
	readonly provider: string;
	readonly data: any;
	readonly addDescriptionToMedia: boolean;
	readonly addWatermarkToImageMedia: boolean;
	readonly generic: any;

	private constructor(
		description: string,
		resourceId: string,
		resourceType: string,
		resourceSubtype: string,
		provider: string,
		data: any,
		addDescriptionToMedia: boolean = false,
		addWatermarkToImageMedia: boolean,
		generic: any,
	) {
		this.description = description;
		this.resourceId = resourceId;
		this.resourceType = resourceType;
		this.resourceSubtype = resourceSubtype;
		this.provider = provider;
		this.data = data;
		this.addDescriptionToMedia = addDescriptionToMedia;
		this.addWatermarkToImageMedia = addWatermarkToImageMedia;
		this.generic = generic;
	}

	toJSON(): MainMediaJson {
		return {
			description: this.description,
			resourceId: this.resourceId,
			resourceType: this.resourceType,
			resourceSubtype: this.resourceSubtype,
			provider: this.provider,
			data: this.data,
			addDescriptionToMedia: this.addDescriptionToMedia,
			addWatermarkToImageMedia: this.addWatermarkToImageMedia,
			generic: this.generic,
		};
	}

	static fromJSON(json: MainMediaJson): MainMedia {
		return new MainMedia(
			json.description,
			json.resourceId,
			json.resourceType,
			json.resourceSubtype,
			json.provider,
			json.data,
			json.addDescriptionToMedia,
			json.addWatermarkToImageMedia,
			json.generic,
		);
	}

	static builder(mainMedia?: MainMedia): MainMediaBuilder {
		return new MainMediaBuilder(mainMedia);
	}
}
