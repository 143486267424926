import EnumItem from '../../models/enum/EnumItem';
import { USER_LOGOUT } from '../action-creators/ProfileActionCreators';
import { IMAGE_OWNERS_REQUEST_RECEIVED } from '../action-creators/ImageOwnersActionCreator';

interface InitialState {
	owners: EnumItem[];
}

const initialState: InitialState = {
	owners: [] as EnumItem[],
};

function imageOwnersReducer(state: any = initialState, action: any) {
	if (action.type === IMAGE_OWNERS_REQUEST_RECEIVED) {
		return Object.assign({}, state, {
			owners: action.payload.slice(),
		});
	}

	if (action.type === USER_LOGOUT) {
		state = initialState;
	}

	return state;
}

export default imageOwnersReducer;
