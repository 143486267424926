import { Label } from 'reactstrap';
import React from 'react';
import HttpService from '../../../../services/rest/HttpService';
import { relatedTypesMap, responseToOptions } from './helpers/search-content-by-type-container.helper';
import ListItem from '../../../../models/list/list-item/ListItem';
import { capitalize } from '../../../../global-helpers/global.helpers';
import { cssIconByContentType } from '../../../Resources/Lists/ListsContent/list-content.helper';
import SuggestedListPagination from './suggested-list-pagination.component';
import PaginationMeta from '../../../../models/pagination/PaginationMeta';
import ModelMapper from '../../../../models/ModelMapper';
import { Link } from 'react-router-dom';
import ListItemAddButtons from '../../Sidebar/lists/subcomponents/list-item-add-buttons.component';
import List from '../../../../models/list/List';
import { multiLingualService } from '../../../../App';
import { store } from '../../../../store/store';

type Properties = {
	type: string;
	domain: string;
	onRelatedContentSelect: (option: any, addItemToTop?: boolean) => void;
	t: any;
	list: List;
	listItems: ListItem[];
	toggleFirstLockPositionError: (toggleError: boolean) => any;
	showAddToTopToggle: boolean;
	contentLanguage: string;
};

export default class SuggestedListTagsOrSportsConnectionsOptions extends React.Component<Properties, any> {
	constructor(props: Properties) {
		super(props);

		this.state = {
			contentPagination: PaginationMeta.builder().build(),
			isLoading: false,
			options: [],
		};
	}

	componentDidMount() {
		const { type } = this.props;
		this.getContent(type, 1);
	}

	componentDidUpdate(prevProps: Readonly<Properties>, prevState: Readonly<any>, snapshot?: any) {
		if (prevProps.type !== this.props.type) {
			this.getContent(this.props.type, 1);
		}
	}

	private setLoading = (loading: boolean) => {
		this.setState({ isLoading: loading });
	};

	private requestFilterBuilder = (type: string, selectedPage: number) => {
		let request = `/${type}/search?query=*`;
		let requestFilter = '';

		if (requestFilter.length > 0) {
			request = request + requestFilter + `&page=${selectedPage}`;
		} else {
			return '';
		}

		return request;
	};

	private getContent = (type: string, selectedPage: number): any => {
		const { domain } = this.props;

		this.setLoading(true);
		const header = { Project: domain };
		const filterByCategoryEndpoint = this.requestFilterBuilder(type, selectedPage);
		const contentListEndpoint = type === relatedTypesMap.tags + 's' && `/${type}?page=${selectedPage}`;
		const endpoint = filterByCategoryEndpoint.length > 0 ? filterByCategoryEndpoint : contentListEndpoint;

		type === relatedTypesMap.tags + 's' &&
			HttpService.get(endpoint, null, header)
				.then((response: any) => {
					const options = responseToOptions(response.data.data, relatedTypesMap[type]);
					this.setState({
						isLoading: false,
						contentPagination: ModelMapper.remapMetaPagination(response.data.meta.pagination),
						options: options,
					});
				})
				.catch(() => this.setLoading(false));
	};

	render() {
		const { onRelatedContentSelect, t, list, listItems, type, showAddToTopToggle } = this.props;
		const { contentPagination, isLoading, options } = this.state;

		return (
			<div className='my-2'>
				<Label>
					<strong>{t(type)}:</strong>
				</Label>
				<ul className={`${isLoading ? 'loading-overlay' : ''} list-group`}>
					{options &&
						options.length > 0 &&
						options
							.filter((option: any) => listItems && !listItems.find((el: any) => el.data && option.data && el.data.id === option.data.id))
							.map((option: any) => {
								return (
									<li key={`option-data-${option.data.id}`} className='list-group-item d-flex align-items-center'>
										<div className='mr-2'>
											<ListItemAddButtons
												t={t}
												currentContent={option}
												list={list}
												onListItemAdd={onRelatedContentSelect}
												toggleFirstLockPositionError={this.props.toggleFirstLockPositionError}
												showAddToTopToggle={showAddToTopToggle}
											/>
										</div>

										<i className={`fa ${cssIconByContentType[option.type]} p-1`} title={capitalize(option.type)} />
										<div className='d-flex flex-column mr-auto'>
											<strong className='p-1'>
												<Link to={`/smp/${type}/edit/${option.data.id}`} className='text-dark'>
													{option.data.title}
												</Link>
											</strong>
											<div className='d-flex pl-1 pr-1'>{`${t('created_by')}: ${t(option.data.created_by.full_name)}`}</div>
										</div>
									</li>
								);
							})}
					{options && options.length === 0 && <div />}
				</ul>
				<div className='mt-2'>
					<SuggestedListPagination t={t} type={type} getContent={this.getContent} contentPagination={contentPagination} />
				</div>
			</div>
		);
	}
}
