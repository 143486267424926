import React from 'react';
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import '../../styles/getty.styles.scss';
import { useTranslation } from 'react-i18next';
import { GETTY_IMAGES_TYPES } from '../../helpers/getty-images.helper';

type Properties = {
	changeSelectedImageType: (type: string) => void;
	isOpenDropdown: boolean;
	toggleImagesTypeMenu: () => void;
	selectedType: string;
};

export const GettyImageSearchTypeSelect: React.FunctionComponent<Properties> = (props) => {
	const [t] = useTranslation();

	return (
		<ButtonDropdown isOpen={props.isOpenDropdown} toggle={props.toggleImagesTypeMenu} className='lang-dropdown'>
			<DropdownToggle caret data-dropup-auto='false' className='dropdown-btn-lang'>
				{t(props.selectedType)}
			</DropdownToggle>
			<DropdownMenu>
				{GETTY_IMAGES_TYPES.map((type) => (
					<DropdownItem style={{ textTransform: 'lowercase' }} title={t(type)} key={type} onClick={() => props.changeSelectedImageType(type)}>
						{t(type)}
					</DropdownItem>
				))}
			</DropdownMenu>
		</ButtonDropdown>
	);
};
