import moment from 'moment';
import { TypedJSON } from 'typedjson';
import MatchModel from '../../../../../../../models/v2/match/entity/match.model';

export const responseToMatchModel = (response: any) => {
	const serializer = new TypedJSON(MatchModel);
	const remappedGame = serializer.parse(response) as MatchModel;

	return remappedGame;
};

export const removePastMatches = (matches: MatchModel[]) => {
	return matches.filter((match: any) => moment().diff(match.startTime, 'days') < 1 && match.code !== 'postponed');
};

export const markMatchModelAsSelected = (matches: MatchModel[], matchId: number, matchIds: number[], isMulti: boolean) => {
	if (matches && !isMulti) {
		matches = matches.map((match: MatchModel) => {
			match.isSelected = match.id === matchId;

			return match;
		});
	}

	if (matches && isMulti) {
		matches = matches.map((match: MatchModel) => {
			const index = matchIds.findIndex((id) => match.id === id);
			match.isSelected = index > -1;

			return match;
		});
	}

	return matches;
};

export const convertTeamIdsToMatchSearchUrls = (teamIds: string[]) => {
	let urls = [];

	if (teamIds && teamIds.length > 0) {
		urls.push(`matches?team_ids=${teamIds.join(',')}&status_types=notstarted`);
		urls.push(`matches?team_ids=${teamIds.join(',')}&status_types=finished`);
		urls.push(`matches?team_ids=${teamIds.join(',')}&status_types=inprogress`);
		urls.push(`matches?team_ids=${teamIds.join(',')}&status_types=interrupted`);
	}

	return urls;
};
