import React from 'react';
import { ViewTypes } from '../../../constants/general.constants';
import { Properties, State } from './properties/imago-image-block.properties';
import { connect } from 'react-redux';
import ImagoImageView from './imago-image-block-view.component';
import ImagoImageEdit from './imago-image-edit.component';
import ImagoImageModel from './models/imago-image.model';

class ImagoImageBlock extends React.Component<Properties, State> {
	constructor(props: any) {
		super(props);
		this.state = {
			isLoading: false,
		};
	}

	//TODO store dugout video id in block preview and request details in 'View' mode
	render() {
		const { t, view, block } = this.props;

		if (view === ViewTypes.edit) {
			return <ImagoImageEdit t={t} block={block} blocks={this.props.blocks} />;
		} else if (view === ViewTypes.normal) {
			return (
				<ImagoImageView
					isLoading={this.state.isLoading}
					imagoImage={block.data.preview ? block.data.preview.image : ImagoImageModel.builder().build()}
					block={block}
				/>
			);
		}
	}
}

function mapStateToProps(state: any) {
	return {
		currentProject: state.project.currentProject,
		lang: state.profile.profile.language,
	};
}

export default connect(mapStateToProps)(ImagoImageBlock) as React.ComponentType<any>;
