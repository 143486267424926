import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { extractContentModeBasedOnUrl, extractMainContentTypeBasedOnUrl } from '../../../../global-helpers/global.helpers';
import {
	autoGenerateReduxPropsNamingForContentModels,
	generateReduxPropsUpdateActionByContentType,
} from '../../../../services/content-models-service/ContentModelService';
import { ReduxURLsProps, UrlsComponentModel } from './constants/redux-urls.attributes';
import ExternalUrlInput from './subcomponents/external-url/external-url';
import CanonicalUrlInput from './subcomponents/canonical-url/canonical-url';
import RedirectionTypeSelect from './subcomponents/redirection-type/redirection-type';
import PublicUrlInput from './subcomponents/public-url/public-url';
import VideoUrlsContainer from './subcomponents/video-urls/video-urls-container';

type Properties = {
	t: any;
	urlsRedux: UrlsComponentModel;
	updateTempProperty: (propertyObject: Record<string, any>) => void;
};

const SidebarURLs: FunctionComponent<Properties> = ({ t, urlsRedux, updateTempProperty }) => {
	const onFieldChange = (dataForRedux: string, urlsPropertyName: ReduxURLsProps) => {
		updateTempProperty({
			urls: {
				...urlsRedux,
				[urlsPropertyName]: dataForRedux,
			},
		});
	};

	return (
		<div>
			<ExternalUrlInput externalUrl={urlsRedux[ReduxURLsProps.EXTERNAL_URL]} onChange={onFieldChange} t={t} />
			<CanonicalUrlInput canonicalUrl={urlsRedux[ReduxURLsProps.CANONICAL_URL]} onChange={onFieldChange} t={t} />
			<RedirectionTypeSelect t={t} />
			{/* the component below will be rendered only for video as content type */}
			<VideoUrlsContainer t={t} />
			<PublicUrlInput t={t} url={urlsRedux.publicUrlDesktop || ''} propertyName={ReduxURLsProps.DESKTOP_URL} />
			<PublicUrlInput t={t} url={urlsRedux.publicUrlMobile || ''} propertyName={ReduxURLsProps.MOBILE_URL} />
			<PublicUrlInput t={t} url={urlsRedux.publicUrlAmp || ''} propertyName={ReduxURLsProps.AMP_URL} />
		</div>
	);
};

function mapStateToProps(state: any) {
	const { tempPropertyNaming, nestedTempPropertyNaming } = autoGenerateReduxPropsNamingForContentModels();

	return {
		urlsRedux: state[tempPropertyNaming][nestedTempPropertyNaming].urls || {},
	};
}

function mapDispatchToProps(dispatch: Function) {
	const contentTypeFromUrl = extractMainContentTypeBasedOnUrl();
	const functionForDispatch = generateReduxPropsUpdateActionByContentType(contentTypeFromUrl);

	return {
		updateTempProperty: (propertyObject: Record<string, any>) =>
			functionForDispatch && dispatch(functionForDispatch(propertyObject, extractContentModeBasedOnUrl())),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(SidebarURLs);
