import React, { useState } from 'react';
import { FormGroup, Input } from 'reactstrap';
import { assetsPlaceholder } from '../../../../constants/assetsPlaceholder';

type Props = {
	change: (text: any) => any;
	avatar: string;
	authorName: string;
};

export const ImageUpload: React.FunctionComponent<Props> = ({ change, avatar, authorName }) => {
	const [img, setSrc] = useState({ src: '', isLoading: true });
	const avatarSrc = (src: string) => (src ? src : assetsPlaceholder.player);

	const onChange = (e: any) => {
		change(e.target.files[0]);

		if (e.target.files[0]) {
			setSrc({ ...img, src: URL.createObjectURL(e.target.files[0]) });
		} else {
			setSrc({ ...img, src: '' });
		}
	};

	const onSrcLoad = () => setSrc({ ...img, isLoading: false });

	return (
		<FormGroup className='d-flex flex-wrap align-items-center'>
			<div className={img.isLoading ? 'loading-overlay' : ''}>
				{img.src.length > 0 ? (
					<img
						onLoad={onSrcLoad}
						className='border rounded mr-2'
						width='150'
						height='150'
						alt={authorName}
						src={avatarSrc(img.src)}
						onError={(i: any) => (i.target.src = assetsPlaceholder.player)}
					/>
				) : (
					<img
						onLoad={onSrcLoad}
						className='border rounded mr-2'
						width='150'
						height='150'
						alt={authorName}
						src={avatarSrc(avatar)}
						onError={(i: any) => (i.target.src = assetsPlaceholder.player)}
					/>
				)}
			</div>
			<Input accept='image/x-png,image/gif,image/jpeg' className='form-control w-50' type='file' onChange={onChange} />
		</FormGroup>
	);
};
