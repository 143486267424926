import Profile from '../../models/profile/Profile';
import { REQUEST_USER_BUNDLE_FAILED, REQUEST_USER_BUNDLE_SUCCESS } from '../action-creators/UserBundleActionCreators';
import {
	LANGUAGE_UPDATE_SUCCESS,
	PROFILE_FAILED,
	PROFILE_RECEIVED,
	PROFILE_UPDATE_SUCCESS,
	USER_LOGOUT,
} from '../action-creators/ProfileActionCreators';

interface InitialState {
	profile: Profile;
}

const initialState = (): InitialState => {
	return {
		profile: Profile.builder().build(),
	};
};

function profileReducer(state: any = initialState(), action: any) {
	if (action.type === REQUEST_USER_BUNDLE_SUCCESS) {
		state.error = {};

		return Object.assign({}, state, {
			profile: state.profile = { ...action.payload },
		});
	}

	if (action.type === REQUEST_USER_BUNDLE_FAILED) {
		return Object.assign({}, state.error, {
			error: state.error = { ...action.payload },
		});
	}

	if (action.type === PROFILE_RECEIVED) {
		state = Object.assign({}, state, {
			profile: action.payload,
		});
	}

	if (action.type === PROFILE_FAILED) {
		state = Object.assign({}, state.error, {
			error: action.payload,
		});
	}

	if (action.type === PROFILE_UPDATE_SUCCESS || action.type === LANGUAGE_UPDATE_SUCCESS) {
		state = Object.assign({}, state, {
			profile: action.payload,
		});
	}

	if (action.type === USER_LOGOUT) {
		state = initialState();
	}

	return state;
}

export default profileReducer;
