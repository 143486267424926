import Related from '../../models/related/Related';

export const ARTICLE_RELATED_SPORTS_TEMP_UPDATE = '[ACTION] RELATED_SPORTS_TEMP_UPDATE';
export const ARTICLE_EDIT_RELATED_SPORTS_TEMP_UPDATE = '[ACTION] ARTICLE_EDIT_RELATED_SPORTS_TEMP_UPDATE';
export const ARTICLE_RELATED_SPORTS_TEMP_CLEAR = '[ACTION] RELATED_SPORTS_TEMP_CLEAR';

export const VIDEO_RELATED_SPORTS_TEMP_UPDATE = '[ACTION] VIDEO_RELATED_SPORTS_TEMP_UPDATE';
export const VIDEO_EDIT_RELATED_SPORTS_TEMP_UPDATE = '[ACTION] VIDEO_EDIT_RELATED_SPORTS_TEMP_UPDATE';
export const VIDEO_RELATED_SPORTS_TEMP_CLEAR = '[ACTION] VIDEO_RELATED_SPORTS_TEMP_CLEAR';

export const GALLERY_RELATED_SPORTS_TEMP_UPDATE = '[ACTION] GALLERY_RELATED_SPORTS_TEMP_UPDATE';
export const GALLERY_EDIT_RELATED_SPORTS_TEMP_UPDATE = '[ACTION] GALLERY_EDIT_RELATED_SPORTS_TEMP_UPDATE';
export const GALLERY_RELATED_SPORTS_TEMP_CLEAR = '[ACTION] GALLERY_RELATED_SPORTS_TEMP_CLEAR';

export const WIKI_RELATED_SPORTS_TEMP_UPDATE = '[ACTION] WIKI_RELATED_SPORTS_TEMP_UPDATE';
export const WIKI_EDIT_RELATED_SPORTS_TEMP_UPDATE = '[ACTION] WIKI_EDIT_RELATED_SPORTS_TEMP_UPDATE';
export const WIKI_RELATED_SPORTS_TEMP_CLEAR = '[ACTION] WIKI_RELATED_SPORTS_TEMP_CLEAR';

export function updateArticleSportsTempRelated(related: Related[]) {
	return {
		type: ARTICLE_RELATED_SPORTS_TEMP_UPDATE,
		payload: related,
	};
}

export function updateArticleEditSportsTempRelated(related: Related[]) {
	return {
		type: ARTICLE_EDIT_RELATED_SPORTS_TEMP_UPDATE,
		payload: related,
	};
}

export function clearArticleSportsTempRelated() {
	return {
		type: ARTICLE_RELATED_SPORTS_TEMP_CLEAR,
	};
}

export function updateVideoSportsTempRelated(related: Related[]) {
	return {
		type: VIDEO_RELATED_SPORTS_TEMP_UPDATE,
		payload: related,
	};
}

export function updateVideoEditSportsTempRelated(related: Related[]) {
	return {
		type: VIDEO_EDIT_RELATED_SPORTS_TEMP_UPDATE,
		payload: related,
	};
}

export function clearVideoSportsTempRelated() {
	return {
		type: VIDEO_RELATED_SPORTS_TEMP_CLEAR,
	};
}

export function updateGallerySportsTempRelated(related: Related[]) {
	return {
		type: GALLERY_RELATED_SPORTS_TEMP_UPDATE,
		payload: related,
	};
}

export function updateGalleryEditSportsTempRelated(related: Related[]) {
	return {
		type: GALLERY_EDIT_RELATED_SPORTS_TEMP_UPDATE,
		payload: related,
	};
}

export function clearGallerySportsTempRelated() {
	return {
		type: GALLERY_RELATED_SPORTS_TEMP_CLEAR,
	};
}

export function updateWikiSportsTempRelated(related: Related[]) {
	return {
		type: WIKI_RELATED_SPORTS_TEMP_UPDATE,
		payload: related,
	};
}

export function updateWikiEditSportsTempRelated(related: Related[]) {
	return {
		type: WIKI_EDIT_RELATED_SPORTS_TEMP_UPDATE,
		payload: related,
	};
}

export function clearWikiSportsTempRelated() {
	return {
		type: WIKI_RELATED_SPORTS_TEMP_CLEAR,
	};
}
