export const widgetOptionsFieldMap = {
	goalkeeper: ['played', 'conceded', 'cleansheets', 'started', 'substitute_in', 'substitute_out', 'yellow_cards', 'red_cards'],
	keeper: ['played', 'conceded', 'cleansheets', 'started', 'substitute_in', 'substitute_out', 'yellow_cards', 'red_cards'],
	defender: [
		'played',
		'minutes_per_game',
		'conceded',
		'cleansheets',
		'goals',
		'assists',
		'started',
		'substitute_in',
		'substitute_out',
		'yellow_cards',
		'red_cards',
	],
	midfielder: [
		'played',
		'minutes_per_game',
		'goals',
		'assists',
		'conceded',
		'cleansheets',
		'started',
		'substitute_in',
		'substitute_out',
		'yellow_cards',
		'red_cards',
	],
	forward: [
		'played',
		'minutes_per_game',
		'goals',
		'minutes_per_goal',
		'assists',
		'conceded',
		'cleansheets',
		'started',
		'substitute_in',
		'substitute_out',
		'yellow_cards',
		'red_cards',
	],
};
