import LiveBlogModel from '../../views/Pages/Live Blog/models/live-blog.model';
import { LIVE_BLOG_EDITORIAL_CLOSE, LIVE_BLOG_EDITORIAL_OPEN } from '../action-creators/LiveBlogEditorialActionCreator';

function liveBlogEditorialReducer(state: { configuration: LiveBlogModel | null } = { configuration: null }, action: any) {
	if (action.type === LIVE_BLOG_EDITORIAL_OPEN) {
		return Object.assign({}, state, {
			configuration: action.payload,
		});
	}

	if (action.type === LIVE_BLOG_EDITORIAL_CLOSE) {
		return Object.assign({}, state, {
			configuration: null,
		});
	}

	return state;
}

export default liveBlogEditorialReducer;
