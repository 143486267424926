import { toast } from 'react-toastify';
import HttpService from '../../../../../services/rest/HttpService';
import ModelMapper from '../../../../../models/ModelMapper';
import Image from '../../../../../models/image/Image';

export const UPLOAD_STEPS = ['processing', 'uploading', 'tagging', 'none'];

export const UPLOAD_STEP_STYLE = {
	processing: 'image-uploading-loader-container',
	uploading: 'image-uploading-loader-container',
	tagging: 'image-uploading-loader-container',
	none: 'hide-image-loader',
};

export const UPLOAD_STEP_TEXT = {
	processing: 'processing_images',
	uploading: 'uploading_images',
	tagging: 'tagging_images_data',
	none: '',
};
export const MAX_DIMENS_ERROR = 'image_dimens_limit_error';
export const GENERAL_ERROR = 'processing_image_failed';
export const NO_RELATED_CONTENT = 'image_upload_related_error';
export const IMAGE_RELATED_CONTENT_ADD_FAILED = '10004';
export const IMAGE_RELATED_CONTENT_MISSING = '10005';
export const FILE_DIMENS_TOO_BIG = '10001';
export const FILE_UPLOAD_TIMEOUT = '10002';
export const FILE_UPLOAD_FAILED_CONTENT_API = '10003';
export const MAX_DIMENS_SIZE = 75000000;
export const PARTIAL_STRING_ERROR_FILE_SIZE_DROPZONE = 'File is too big';
export const FILE_RESOLUTION_TOO_LARGE_DROPZONE = 'Uploaded file has too large resolution';

export const returnErrorsMessage = (error: string, t: any) => {
	if (error === PARTIAL_STRING_ERROR_FILE_SIZE_DROPZONE) {
		return t('image_size_limit_error');
	}

	if (error === FILE_UPLOAD_TIMEOUT) {
		return t('image_upload_error');
	}

	if (error === FILE_DIMENS_TOO_BIG || error === FILE_RESOLUTION_TOO_LARGE_DROPZONE) {
		return t('image_dimens_limit_error');
	}

	if (error === FILE_UPLOAD_FAILED_CONTENT_API) {
		return t('image_upload_related_error');
	}

	if (error === IMAGE_RELATED_CONTENT_ADD_FAILED) {
		return 'image_related_content_add_failed';
	}

	if (error === IMAGE_RELATED_CONTENT_MISSING) {
		return t(NO_RELATED_CONTENT);
	}
};

export const handleErrors = (error: string, t: any) => {
	if (error === PARTIAL_STRING_ERROR_FILE_SIZE_DROPZONE) {
		toast.error(t('image_size_limit_error'));
	}

	if (error === FILE_UPLOAD_TIMEOUT) {
		toast.error(t('image_upload_error'));
	}

	if (error === FILE_DIMENS_TOO_BIG || error === FILE_RESOLUTION_TOO_LARGE_DROPZONE) {
		toast.error(t('image_dimens_limit_error'));
	}

	if (error === FILE_UPLOAD_FAILED_CONTENT_API) {
		toast.error(t('image_upload_related_error'));
	}

	if (error === IMAGE_RELATED_CONTENT_ADD_FAILED) {
		toast.error('image_related_content_add_failed');
	}

	if (error === IMAGE_RELATED_CONTENT_MISSING) {
		toast.error(t(NO_RELATED_CONTENT));
	}
};

export const toJson = (imagePath: string, watermark?: boolean) => {
	return watermark
		? {
				generic: { watermark: true },
				path: imagePath,
				urls: {
					uploaded: {
						original: imagePath,
						gallery: `${imagePath}?operations=fit(1920:)`,
						thumbnail: `${imagePath}?operations=autocrop(256:256)`,
						embed: `${imagePath}?operations=fit(770:)`,
					},
				},
		  }
		: {
				path: imagePath,
				urls: {
					uploaded: {
						original: imagePath,
						gallery: `${imagePath}?operations=fit(1920:)`,
						thumbnail: `${imagePath}?operations=autocrop(256:256)`,
						embed: `${imagePath}?operations=fit(770:)`,
					},
				},
		  };
};

export const appendUploadingClass = (isLoading: boolean) => {
	return isLoading ? 'fa fa-spinner fa-pulse' : 'fa fa-cloud-upload';
};

export const imageListMultipleResponseToImageList = (responseList: any): Image[] => {
	const normalizedImageResponse = responseList.map((response: any) => response.data.data);

	return ModelMapper.remapImageListFromResponse(normalizedImageResponse);
};

export const constructRequestsFromFilePaths = (imagePaths: string[], headers: any, watermark?: boolean) => {
	return imagePaths.map((path: string) => HttpService.post('/images', toJson(path, watermark), headers));
};

export const constructRequestsSportsRelatedImages = (imageIds: string[], related: any, headers: any) => {
	return imageIds.map((image: any) => HttpService.post(`/images/${image.id}/related`, related, headers));
};
