import React, { useState } from 'react';
import { noPreviewImage } from '../../../../../../constants/resource-constants';
import OnnetworkVideoModel from '../../../../../../models/onnetwork-videos/onnetwork-video.model';

type Properties = {
	t: any;
	video: OnnetworkVideoModel;
};

const OnnetworkVideoView: React.FunctionComponent<Properties> = (props) => {
	const { video } = props;
	const [isLoading, setLoading] = useState(true);
	const src = video && video.embedCode && video.embedCode.code ? `https://video.onnetwork.tv/poster.php?video=${video.id}` : noPreviewImage;

	if (video && video.title) {
		return (
			<div className={`${isLoading ? 'loading-overlay' : ''}`}>
				<div className='block-content'>
					<div className={`d-flex content-container`}>
						{video.title ? (
							<img onLoad={() => setLoading(false)} className='content-image img-fluid mb-2 mb-md-0 rounded' alt={video.title} src={src} />
						) : (
							<img alt='' src={noPreviewImage} className='content-image img-fluid mb-2 mb-md-0 rounded' />
						)}
						<div className='gallery-title text-left'>
							<h6 className='m-0 p-0'>{video.title}</h6>
						</div>
					</div>
				</div>
			</div>
		);
	}

	return (
		<div className='position-relative block-content'>
			<img alt='' src={noPreviewImage} className='no-preview-image img-fluid mb-2 mb-md-0 rounded' />
		</div>
	);
};

export default OnnetworkVideoView;
