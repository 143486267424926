import Image from '../../../../../../models/image/Image';

export const imageRatiosToOptions = (imageRatios: any[]): any[] => {
	return imageRatios ? imageRatios.map((imageRatio: any) => returnMappedRatios(imageRatio)) : [];
};

export const imageRatioToOptions = (imageRatio: any): any => {
	return imageRatio ? returnMappedRatios(imageRatio) : [];
};

export const returnMappedRatios = (ratio: any) => {
	return {
		label: ratio[0] && ratio[0].replace('ratio', ''),
		value: ratio[1],
		ratio,
	};
};

export const extractRatios = (image: Image) => {
	return (
		image &&
		image.urls &&
		Object.entries(image.urls).filter(
			([key, value]) =>
				key.includes('ratio') &&
				value !== undefined &&
				value !== null &&
				value.bottom !== null &&
				value.left !== null &&
				value.right !== null &&
				value.top !== null,
		)
	);
};

export function imageRatioFromOptionSelections(selection: any) {
	return selection ? selection.ratio : {};
}
