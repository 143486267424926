import React, { FunctionComponent, ChangeEvent } from 'react';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { DATA_QA_ATTRIBUTES } from '../../constants/data-qa.attributes';
import { ReduxURLsProps } from '../../constants/redux-seo.attributes';
import { autoGenerateReduxPropsNamingForContentModels } from '../../../../../../services/content-models-service/ContentModelService';
import { connect } from 'react-redux';

type Properties = {
	t: any;
	seoIndexing: boolean;
	onSeoChange: (data: boolean, propertyName: ReduxURLsProps) => void;
};

const SeoIndexingCheckbox: FunctionComponent<Properties> = ({ t, seoIndexing, onSeoChange }) => {
	const onIndexingChange = (event: ChangeEvent<HTMLInputElement>) => {
		const checked = event.target.checked || false;
		onSeoChange(checked, ReduxURLsProps.INDEX);
	};

	return (
		<Row>
			<Col>
				<FormGroup check className='radio'>
					<Input
						id={DATA_QA_ATTRIBUTES.INDEXING}
						className='form-check-input'
						type='checkbox'
						name='radios'
						value={ReduxURLsProps.INDEX}
						onChange={onIndexingChange}
						checked={seoIndexing}
					/>
					<Label check className='form-check-label' for={DATA_QA_ATTRIBUTES.INDEXING}>
						{t('allow_indexing')}
					</Label>
				</FormGroup>
			</Col>
		</Row>
	);
};

function mapStateToProps(state: any) {
	const { tempPropertyNaming, nestedTempPropertyNaming } = autoGenerateReduxPropsNamingForContentModels();

	return {
		seoIndexing:
			(state[tempPropertyNaming][nestedTempPropertyNaming].seo && !!state[tempPropertyNaming][nestedTempPropertyNaming].seo.index) || false,
	};
}

export default connect(mapStateToProps)(SeoIndexingCheckbox);
