import React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import { Properties } from './properties/search-content-by-type.properties';
import { featuresService } from '../../../../App';
import { FeatureTypes } from '../../../../services/feature-service/features.enum';
import { ContentTypes } from '../../../../constants/content-types';

class SearchContentByType extends React.Component<Properties> {
	onSearchTypeSelect = (event: any) => {
		this.props.onSearchTypeSelect(event.target.value);
	};

	render() {
		const { type } = this.props;

		return (
			<div id='search-content-by-type' className={'mb-3'}>
				<FormGroup>
					<FormGroup check inline>
						<Input
							className='form-check-input'
							type='radio'
							onChange={this.onSearchTypeSelect}
							id='search-type-radio-articles'
							name='inline-radios-search-type'
							checked={type === 'articles'}
							value='articles'
						/>
						<Label className='form-check-label' check htmlFor='search-type-radio-articles'>
							{this.props.t('articles')}
						</Label>
					</FormGroup>
					{featuresService.areFeaturesEnabled([FeatureTypes.VIDEO]) ? (
						<FormGroup check inline>
							<Input
								className='form-check-input'
								type='radio'
								onChange={this.onSearchTypeSelect}
								id='search-type-radio-videos'
								name='inline-radios-search-type'
								checked={type === 'videos'}
								value='videos'
							/>
							<Label className='form-check-label' check htmlFor='search-type-radio-videos'>
								{this.props.t('videos')}
							</Label>
						</FormGroup>
					) : null}
					<FormGroup check inline>
						<Input
							className='form-check-input'
							type='radio'
							onChange={this.onSearchTypeSelect}
							id='search-type-radio-galleries'
							name='inline-radios-search-type'
							checked={type === 'galleries'}
							value='galleries'
						/>
						<Label className='form-check-label' check htmlFor='search-type-radio-galleries'>
							{this.props.t('galleries')}
						</Label>
					</FormGroup>
					{featuresService.areFeaturesEnabled([FeatureTypes.LIVE_BLOG]) ? (
						<FormGroup check inline>
							<Input
								className='form-check-input'
								type='radio'
								onChange={this.onSearchTypeSelect}
								id='search-type-radio-live-blogs'
								name='inline-radios-search-type'
								checked={type === `${ContentTypes.LIVE_BLOG}s`}
								value='live_blogs'
							/>
							<Label className='form-check-label' check htmlFor='search-type-radio-live-blogs'>
								{this.props.t('live_blogs')}
							</Label>
						</FormGroup>
					) : null}
				</FormGroup>
			</div>
		);
	}
}

export default SearchContentByType;
