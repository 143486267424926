import { SelectMenuOptionType } from '../../../../../models/v2/general/select.model';
import ListModel from '../../../../../models/v2/list/list';

export const listsToOptions = (lists: ListModel[]): SelectMenuOptionType[] => {
	return lists && lists.length > 0 ? lists.map((list: ListModel) => listToOption(list)) : [];
};

export const listToOption = (list: ListModel): SelectMenuOptionType => {
	return {
		value: list.id,
		label: list.title,
		data: list,
	};
};
