import React, { FunctionComponent, useState } from 'react';
import './select.scss';
import classNames from 'classnames';
import { generateNoOptionsField } from '../../../Pages/CustomEntities/helpers/custom-entities.helper';

export type SelectOption = { name: string; value: string | number; data?: any; image?: string };

type Props = {
	invokeFunc: (option: SelectOption) => void;
	fieldId: string;
	labelText?: string;
	placeholderText?: string;
	options: SelectOption[];
	selectedValue?: string | number;
	disabled?: boolean;
	disabledValue?: string;
	errorMessage?: string;
	infoMessage?: {
		title?: string;
		description?: string;
	};
	zIndex?: number;
};

const SelectInputDesign: FunctionComponent<Props> = ({
	invokeFunc,
	fieldId,
	labelText,
	placeholderText,
	options,
	selectedValue,
	disabled,
	disabledValue,
	errorMessage,
	infoMessage,
	zIndex,
}) => {
	const [toggleInfoPanel, setToggleInfoPanel] = useState<boolean>(false);
	const [toggleOptions, setToggleOptions] = useState<boolean>(false);
	const selectedOption = disabled && disabledValue ? { name: disabledValue } : options.find((option) => option.value === selectedValue);
	const toggleOptionsVisibility = () => !disabled && setToggleOptions(!toggleOptions);

	const onSelect = (option: SelectOption) => {
		invokeFunc(option);
		toggleOptionsVisibility();
	};

	const onInfoHover = () => setToggleInfoPanel(!toggleInfoPanel);

	return (
		<div className='custom-select-input-wrapper' aria-disabled={!!disabled}>
			<div className='info'>
				{labelText && <label htmlFor={fieldId}> {labelText}</label>}

				{infoMessage && <div className='info-icon' onClick={onInfoHover} />}
				{toggleInfoPanel && infoMessage && (
					<div className='info-message'>
						{infoMessage.title && <div className='info-message-title'> {infoMessage.title} </div>}
						{infoMessage.description && <div className='info-message-description'> {infoMessage.description} </div>}
					</div>
				)}
			</div>
			<div
				id={fieldId}
				className='custom-select-value-holder'
				onClick={toggleOptionsVisibility}
				data-placeholder={!(selectedOption && selectedOption.name)}
			>
				{selectedOption && selectedOption.name ? selectedOption.name : placeholderText}
				<div className={classNames('select-icon', { 'toggled-options': toggleOptions })} />
			</div>
			{errorMessage && <div className='error-message'> {errorMessage} </div>}
			<div
				className='custom-select-options'
				style={{ display: toggleOptions ? 'block' : 'none', zIndex: zIndex ? zIndex : 2 }}
				onMouseLeave={toggleOptions ? toggleOptionsVisibility : undefined}
			>
				{options && options.length < 1
					? generateNoOptionsField(labelText)
					: options.map((option) => (
							<div className='custom-select-option' key={option.value} onClick={() => onSelect(option)} title={option.name}>
								{option.image && <img src={option.image} alt={option.name} />}
								{option.name}
							</div>
					  ))}
			</div>
		</div>
	);
};

export default SelectInputDesign;
