import BookmakerModel from './bookmaker.model';
import { BookmakerJson } from './bookmaker.json';
import { imagePlaceHolder } from '../../../../../../constants/resource-constants';

export default class BookmakerBuilder {
	private json: BookmakerJson;

	constructor(bookmaker?: BookmakerModel | BookmakerJson) {
		if (bookmaker && bookmaker instanceof BookmakerModel) {
			this.json = bookmaker.toJson();
		} else if (bookmaker) {
			this.json = bookmaker;
		} else {
			this.json = {} as BookmakerJson;
		}
	}

	withId(id: string): BookmakerBuilder {
		this.json.id = id;

		return this;
	}

	withName(name: string): BookmakerBuilder {
		this.json.name = name;

		return this;
	}

	withLogo(logo: any): BookmakerBuilder {
		this.json.logo = logo ? logo : imagePlaceHolder;

		return this;
	}

	withOverUnder(overUnder: any): BookmakerBuilder {
		this.json.overUnder = overUnder;

		return this;
	}

	build(): BookmakerModel {
		return BookmakerModel.fromJson(this.json);
	}
}
