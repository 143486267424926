import React from 'react';
import { connect } from 'react-redux';
import Project from '../../../../models/project/Project';
import ListingComponent from '../../../Partials/BaseComponents/ListingComponent/ListingComponent';
import { Properties, State } from '../Properties/VideoListingProperties';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import {
	VIDEO_DELETE_SUCCESS,
	videoDelete,
	videoDeleteFailed,
	videoSearch,
	videosRequest,
} from '../../../../store/action-creators/VideoActionCreator';
import ConditionalRenderWrapper from '../../../Partials/BaseComponents/ConditionalRenderWraper/ConditionalRenderWrapper';
import { ContentTypes } from '../../../../constants/content-types';
import { actionService, featuresService, multiLingualService } from '../../../../App';
import { FeatureTypes } from '../../../../services/feature-service/features.enum';
import PaginationMeta from '../../../../models/pagination/PaginationMeta';
import { returnObjectForArticlePaginationUpdate } from '../../../../store/action-creators/ArticleActionCreator';
import { returnObjectForGalleryPaginationUpdate } from '../../../../store/action-creators/GalleryActionCreator';
import { returnObjectForImagesPaginationUpdate } from '../../../../store/action-creators/ImageActionCreator';
import AdvancedFilterModel from '../../../Partials/Shared/advanced-content-filter/models/advanced-content-filter.model';
import { extractSearchQueryFromFilters } from '../../../Partials/Shared/advanced-content-filter/helpers/advanced-content-filter.helper';
import { returnObjectForContentFiltersUpdate } from '../../../../store/action-creators/content-resources';
import { remmapContentFilters } from '../../Articles/Helpers/ArticleHelper';
import { LANGUAGE_CODE_UPDATE_SUCCESS } from '../../../../store/action-creators/ProjectResourcesActionCreators';
import Video from '../../../../models/video/Video';
import { Subscription } from 'rxjs';
import { updateTempVideo } from '../../../../store/action-creators/VideoTempActionCreator';
import TranslationGroupModel from '../../../../models/translation-groups/translation-group-model';
import { returnObjectForWikiPaginationUpdate } from '../../../../store/action-creators/WikiActionCreator';
import { appendJWFilter, shouldRenderJwRows } from '../Helpers/VideoHelper';

// this is class component because it is copied from VideoListing component
class JWListing extends React.Component<Properties, State> {
	private actionServiceSubject = new Subscription();

	constructor(props: Properties) {
		super(props);
		this.state = {
			currentPage: this.props.pagination.currentPage ? this.props.pagination.currentPage.toString() : '1',
		};
		this.registerActionEventListener();
	}

	getVideosListener = () => this.applyFilters(this.props.filters);

	registerActionEventListener() {
		window.addEventListener(VIDEO_DELETE_SUCCESS, this.getVideosListener);

		this.actionServiceSubject = actionService.onActionReceived().subscribe((action: string) => {
			if (action === LANGUAGE_CODE_UPDATE_SUCCESS) {
				this.setState({ currentPage: '1' });
				this.getVideosListener();

				//Update language in temp video
				const video = Video.builder(this.props.tempVideo)
					.withId('temp-id')
					.withLanguage(this.props.currentProject.languages.defaultLanguageCode.languageCode)
					.build();
				this.props.updateTempVideo(video);
			}
		});
	}

	setCurrentPage(page: string) {
		this.props.analytics.sendPageView(this.props.location.pathname, `${parseInt(page) > 1 ? 'JW Listing - Page ' + page : 'JW Listing'}`);
		this.setState({
			...this.state,
			currentPage: page,
		});
	}

	componentDidMount(): void {
		this.initPageTitle();
		this.props.analytics.sendPageView(this.props.location.pathname, 'JW Listing');

		// Controls for when the user tries to directly access a video if the video feature is disabled
		if (!(featuresService.areFeaturesEnabled([FeatureTypes.VIDEO]) && featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.JW_VIDEO))) {
			this.props.history.push('/dashboard');
		}
		this.applyFilters(this.props.filters);
		this.props.updateArticlesPagination(PaginationMeta.builder().withCurrentPage(1).build());
		this.props.updateGalleriesPagination(PaginationMeta.builder().withCurrentPage(1).build());
		this.props.updateImagesPagination(PaginationMeta.builder().withCurrentPage(1).build());
		this.props.updateWikiPagesPagination(PaginationMeta.builder().withCurrentPage(1).build());
		const filters = remmapContentFilters({}, {}, {}, {}, {}, {}, {}, {}, this.props.filters, {} as AdvancedFilterModel);
		this.props.updateContentFilters(filters);
	}

	componentDidUpdate(prevProps: Readonly<Properties>, prevState: Readonly<State>, snapshot?: any) {
		if (this.state.currentPage !== prevState.currentPage) {
			this.registerActionEventListener();
		}
	}

	componentWillUnmount() {
		window.removeEventListener(VIDEO_DELETE_SUCCESS, this.getVideosListener);
		this.actionServiceSubject.unsubscribe();
	}

	initPageTitle() {
		document.title = this.props.t('jw_videos');
	}

	applyFilters = (filters: AdvancedFilterModel) => {
		const resultFilters = appendJWFilter(filters || {});
		const query = extractSearchQueryFromFilters(resultFilters);
		this.props.getVideos(this.props.pagination.currentPage, this.props.currentProject, query);
	};

	onDeleteContent = (id: string) => {
		this.props.deleteVideo(id, this.props.currentProject);
		if (
			this.props.tempVideo.translationGroup &&
			multiLingualService.checkIfProjectIsMultiLingual(this.props.currentProject.languages) &&
			id === this.props.tempVideo.translationGroup.masterContentId
		) {
			const tempVideo = Video.builder(this.props.tempVideo).withTranslationGroup(TranslationGroupModel.builder().build()).build();
			this.props.updateTempVideo(tempVideo);
		}
	};

	render() {
		return (
			<ConditionalRenderWrapper expectedPermissions={['read_videos', 'write_videos', 'delete_videos']}>
				<ListingComponent
					contentType={ContentTypes.VIDEO}
					getContent={this.props.getVideos}
					analytics={this.props.analytics}
					searchContent={this.props.searchVideos}
					currentProject={this.props.currentProject}
					onPageChange={this.setCurrentPage.bind(this)}
					history={this.props.history}
					onDeleteContent={(id: string) => this.onDeleteContent(id)}
					content={shouldRenderJwRows(ContentTypes.VIDEO) ? this.props.videos : []}
					contentPagination={this.props.pagination}
					showSearchBar={true}
				/>
			</ConditionalRenderWrapper>
		);
	}
}

function mapStateToProps(state: any) {
	return {
		profile: state.profile.profile,
		videos: state.video.videos,
		videoDeleted: state.video.videoDeleted,
		currentProject: state.project.currentProject,
		pagination: state.video.pagination,
		filters: state.video.jwFilters,
		tempVideo: state.tempVideo.video,
	};
}

function mapDispatchToProps(dispatch: any) {
	return {
		getVideos: (page: string, project: Project, text: string) => {
			dispatch(videosRequest(page, project, text));
		},
		searchVideos: (text: string, project: Project) => dispatch(videoSearch(text, project)),
		deleteVideo: (id: string, project: Project) => dispatch(videoDelete(id, project)),
		clearVideoDeleted: () => dispatch(videoDeleteFailed()),
		updateArticlesPagination: (pagination: PaginationMeta) => dispatch(returnObjectForArticlePaginationUpdate(pagination)),
		updateGalleriesPagination: (pagination: PaginationMeta) => dispatch(returnObjectForGalleryPaginationUpdate(pagination)),
		updateImagesPagination: (pagination: PaginationMeta) => dispatch(returnObjectForImagesPaginationUpdate(pagination)),
		updateWikiPagesPagination: (pagination: PaginationMeta) => dispatch(returnObjectForWikiPaginationUpdate(pagination)),
		updateContentFilters: (filters: any) => dispatch(returnObjectForContentFiltersUpdate(filters)),
		updateTempVideo: (video: Video) => dispatch(updateTempVideo(video)),
	};
}

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(JWListing) as React.ComponentType;
