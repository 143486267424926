import TournamentModel from '../../../blocks/widgets/player-profile/models/tournament-model/tournament.model';
import { assetsPlaceholder } from '../../../../../../constants/assetsPlaceholder';
import Country from '../../../../../../models/country/country.model';

export function tournamentToOption(tournament: TournamentModel) {
	if (tournament) {
		return {
			value: tournament.id,
			label: tournament.name,
			data: tournament,
			logo: tournament.logo,
			type: 'tournament',
		};
	} else {
		return {};
	}
}

export const selectionsToTournaments = (selections: any[]) => {
	if (selections && selections.length > 0) {
		return selections.map((selection: any) => selection.data);
	}
	return [];
};

export function tournamentsToOptions(tournaments: TournamentModel[]) {
	if (tournaments && tournaments.length > 0) {
		return tournaments.map((tournament) => {
			return tournamentToOption(tournament);
		});
	}

	return [];
}

export function remapTournamentFromResponse(response: any) {
	if (response && response.length > 0) {
		return response.map((tournament: any) => {
			return TournamentModel.builder()
				.withId(tournament.id)
				.withName(tournament.name)
				.withLogo(tournament.url_logo ? tournament.url_logo : assetsPlaceholder.tournament)
				.withCountry(
					tournament.country
						? Country.builder().withId(tournament.country.id).withName(tournament.country.name).withFlag(tournament.country.url_flag).build()
						: null,
				)
				.build();
		});
	}

	return [];
}
