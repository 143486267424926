import React from 'react';
import { Col, Row } from 'reactstrap';

type Properties = {
	block: any;
	t: any;
};

export const PlayerCareerView: React.FunctionComponent<Properties> = ({ block, t }) => {
	if (block && block.playerCareer && block.playerCareer.player && block.playerCareer.player.id) {
		return (
			<>
				<Row className={'m-2 ml-4 d-flex align-items-center'}>
					<img src={block.playerCareer.player ? block.playerCareer.player.logo : ''} height='50' className='mx-1 rounded-circle' alt='' />
					<h4>{block.playerCareer.player ? block.playerCareer.player.name : ''}</h4>
				</Row>
				<Row className='px-4'>
					<Col>
						<hr></hr>
					</Col>
				</Row>
				<Row className={'m-2 ml-4 d-flex align-items-center'}>
					<Col className='text-left'>
						<strong>{t('championship_filter')}: </strong>

						{block.playerCareer.championshipFilters && block.playerCareer.championshipFilters.length > 0
							? block.playerCareer.championshipFilters.map((filter: any, index: number) => {
									return `${t(filter.label)}${index < block.playerCareer.championshipFilters.length - 1 ? ', ' : ''}`;
							  })
							: `${t('regional')}, ${t('national_team')}, ${t('international')}`}
					</Col>
				</Row>
				<Row className={'m-2 ml-4 d-flex align-items-center'}>
					<Col className='text-left'>
						<strong>{t('statistic_columns')}: </strong>

						{block.playerCareer.statisticColumns && block.playerCareer.statisticColumns.length > 0
							? block.playerCareer.statisticColumns.map((column: any, index: number) => {
									return `${t(column.label)}${index < block.playerCareer.statisticColumns.length - 1 ? ', ' : ''}`;
							  })
							: block.playerCareer.player.position && block.playerCareer.player.position === 'keeper'
							? `${t('played_title')}, ${t('cleansheets_title')}, ${t('conceded_title')}, ${t('yellowCards_title')}, ${t('redCards_title')}`
							: `${t('played_title')}, ${t('minutes_title')}, ${t('goals_title')}, ${t('assists_title')}, ${t('yellowCards_title')}, ${t(
									'redCards_title',
							  )}`}
					</Col>
				</Row>
				<Row className={'m-2 ml-4 d-flex align-items-center'}>
					<Col className='text-left'>
						<strong>{t('table_columns')}: </strong>

						{block.playerCareer.tableColumns && block.playerCareer.tableColumns.length > 0
							? block.playerCareer.tableColumns.map((column: any, index: number) => {
									return `${t(column.label)}${index < block.playerCareer.tableColumns.length - 1 ? ', ' : ''}`;
							  })
							: `${t('season')}, ${t('team')}, ${t('tournament')}`}
					</Col>
				</Row>
				{block.playerCareer.threeLetterCodeType && block.playerCareer.threeLetterCodeType !== '' && (
					<Row className={'m-2 ml-4 d-flex align-items-center'}>
						<Col className='text-left'>
							<strong>{t('display_team_three_letter_code')}:</strong>
							<span className='ml-2'>
								{block.playerCareer.threeLetterCodeType &&
									block.playerCareer.threeLetterCodeType.name &&
									t(`${block.playerCareer.threeLetterCodeType.name}`)}
							</span>
						</Col>
					</Row>
				)}
			</>
		);
	}

	return null;
};
