import React from 'react';
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import '../imago-image-select.scss';

const types = ['sport', 'stock', 'both'];

type Properties = {
	t: any;
	changeSelectedImageType: any;
	isOpenDropdown: any;
	toggleImagesTypeMenu: any;
	selectedType: any;
};

export default class ImageTypeSelect extends React.Component<Properties> {
	render() {
		return (
			<ButtonDropdown isOpen={this.props.isOpenDropdown} toggle={this.props.toggleImagesTypeMenu} className='lang-dropdown'>
				<DropdownToggle caret data-dropup-auto='false' className={'dropdown-btn-lang'}>
					{this.props.t(this.props.selectedType)}
				</DropdownToggle>
				<DropdownMenu>
					{types.map((type) => (
						<DropdownItem
							style={{ textTransform: 'lowercase' }}
							className='image-type-value'
							title={this.props.t(type)}
							key={type}
							onClick={() => this.props.changeSelectedImageType(type)}
						>
							{this.props.t(type)}
						</DropdownItem>
					))}
				</DropdownMenu>
			</ButtonDropdown>
		);
	}
}
