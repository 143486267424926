import { call, put, takeEvery } from 'redux-saga/effects';
import HttpService from '../../services/rest/HttpService';
import { toggleLoadingState, searchIsApplied } from '../action-creators/UiActionCreator';
import { onError } from '../action-creators/GeneralActions';
import {
	returnObjectForTeamListingReceived,
	returnObjectForTeamEntityCreateSuccess,
	returnObjectForTeamEntityUpdateSuccess,
	returnObjectForTeamEntityUpdateFailed,
	returnObjectForTeamEntityCreateFailed,
	returnObjectForTeamEntityReceived,
	teamAssetsPostSuccess,
	teamAssetsPostFailed,
	TEAM_LISTING_REQUEST,
	returnObjectForSearchTeamListingReceived,
	TEAM_SEARCH,
	TEAM_ENTITY_CREATE,
	TEAM_ENTITY_REQUEST,
	TEAM_ENTITY_UPDATE,
	TEAM_ENTITY_POST_ASSETS,
	returnObjectForTeamBlacklistReceived,
	TEAM_LISTING_REQUEST_WITH_MANUAL_DATA_FILTER,
	returnObjectForTeamListingWithManualDataFilterReceived,
} from '../action-creators/team-action-creator';
import { actionService } from '../../App';
import {
	constructRequestFromAssets,
	constructRequestFromEntry,
	remapTeamBlacklisted,
} from '../../views/Resources/manual-data-entries/subcomponents/helpers/manual-data.helper';

function* fetchTeams(action: any) {
	yield put(toggleLoadingState(true));

	try {
		const teams = yield call(HttpService.getTeams, `/v2/teams?limit=20&offset=${(action.payload.page - 1) * 20}`);
		const teamsForBlacklist = constructRequestFromEntry(teams.data.teams, 'TEAM', 'ENTITY');
		const squadForBlacklist = constructRequestFromEntry(teams.data.teams, 'TEAM', 'RELATION', 'players');
		const blacklist = yield call(HttpService.getBlacklist, `/v2/blacklist/search`, [...teamsForBlacklist, ...squadForBlacklist]);
		yield put(returnObjectForTeamListingReceived(teams));
		yield put(returnObjectForTeamBlacklistReceived(remapTeamBlacklisted(teams.data.teams, blacklist.data)));
		yield put(toggleLoadingState(false));
	} catch (error) {
		yield put(onError(error));
	}
}

function* fetchTeamsWithManualDataFilter(action: any) {
	yield put(toggleLoadingState(true));

	try {
		const teams = yield call(HttpService.getTeamsBatch, action.payload.teamsToFilter);
		const teamsForBlacklist = constructRequestFromEntry(teams, 'TEAM', 'ENTITY');
		const squadForBlacklist = constructRequestFromEntry(teams, 'TEAM', 'RELATION', 'players');
		const blacklist = yield call(HttpService.getBlacklist, `/v2/blacklist/search`, [...teamsForBlacklist, ...squadForBlacklist]);
		yield put(returnObjectForTeamListingWithManualDataFilterReceived(teams));
		yield put(returnObjectForTeamBlacklistReceived(remapTeamBlacklisted(teams, blacklist.data)));
		yield put(toggleLoadingState(false));
	} catch (error) {
		yield put(onError(error));
	}
}

function* fetchTeamEntityFromApi(action: any) {
	try {
		let headers = { Project: action.payload.project.domain };
		const team = yield call(HttpService.instanceFootball().get, `/v2/teams/${action.payload.id}`, null, headers);
		yield put(returnObjectForTeamEntityReceived(team));
	} catch (error) {
		yield put(onError(error));
	}
}

function* searchTeams(action: any) {
	yield put(toggleLoadingState(true));

	try {
		let text = action.payload.text;
		let lang = action.payload.lang;
		let type = action.payload.type;
		const teams = yield call(HttpService.getFootballData, text, lang, type);
		const teamsForBlacklist = constructRequestFromEntry(teams.data, 'TEAM', 'ENTITY');
		const squadForBlacklist = constructRequestFromEntry(teams.data, 'TEAM', 'RELATION', 'players');
		const blacklist = yield call(HttpService.getBlacklist, `/v2/blacklist/search`, [...teamsForBlacklist, ...squadForBlacklist]);
		yield put(returnObjectForTeamBlacklistReceived(remapTeamBlacklisted(teams.data, blacklist.data)));
		yield put(returnObjectForSearchTeamListingReceived(teams));
		yield put(toggleLoadingState(false));
		yield put(searchIsApplied());
	} catch (error) {
		yield put(onError(error));
	}
}

function* postTeamEntityToApi(action: any) {
	let headers = { Project: action.payload.project.domain };
	let teamResponse: any = {};

	try {
		let teamPayload = action.payload.team;

		teamResponse = yield call(HttpService.instanceFootball().post, '/v2/teams', teamPayload, headers);

		let assets = constructRequestFromAssets(action.payload.assets, 'TEAM', teamResponse.data.id);
		yield call(HttpService.instanceFootball().post, `/v2/assets/`, assets, headers);

		yield put(returnObjectForTeamEntityCreateSuccess(teamResponse.data.id));
	} catch (error) {
		yield put(returnObjectForTeamEntityCreateFailed());
		yield put(onError(error));
		actionService.emitError(error);
	}
}

function* patchTeamEntityToApi(action: any) {
	let headers = { Project: action.payload.project.domain };

	try {
		let teamPayload = action.payload.team;
		let id = action.payload.team.id;

		yield call(HttpService.instanceFootball().put, `/v2/teams/${id}`, teamPayload, headers);
		yield put(returnObjectForTeamEntityUpdateSuccess());
	} catch (error) {
		yield put(returnObjectForTeamEntityUpdateFailed());
		yield put(onError(error));
		actionService.emitError(error);
	}
}

function* postTeamAssets(action: any) {
	let headers = { Project: action.payload.project.domain };

	try {
		let assets = constructRequestFromAssets(action.payload.assets, 'TEAM', action.payload.teamId);

		yield call(HttpService.instanceFootball().post, `/v2/assets/`, assets, headers);
		yield put(teamAssetsPostSuccess());
	} catch (error) {
		yield put(teamAssetsPostFailed());
		yield put(onError(error));
		actionService.emitError(error);
	}
}

function* teamSaga() {
	yield takeEvery(TEAM_LISTING_REQUEST, fetchTeams);
	yield takeEvery(TEAM_LISTING_REQUEST_WITH_MANUAL_DATA_FILTER, fetchTeamsWithManualDataFilter);
	yield takeEvery(TEAM_SEARCH, searchTeams);
	yield takeEvery(TEAM_ENTITY_CREATE, postTeamEntityToApi);
	yield takeEvery(TEAM_ENTITY_POST_ASSETS, postTeamAssets);
	yield takeEvery(TEAM_ENTITY_REQUEST, fetchTeamEntityFromApi);
	yield takeEvery(TEAM_ENTITY_UPDATE, patchTeamEntityToApi);
}

export default teamSaga;
