import OddsModel from '../../../../../../models/sport-odds/odds.model';
import SportOddsWidgetModel from '../../../blocks/widgets/sports-odds/models/sport-odds-widget.model';
import { MARKET_TYPES } from '../../../constants/odds.constants';

export const extractMarketTypes = (oddsWidgetModel: SportOddsWidgetModel, t: any) => {
	if (oddsWidgetModel && oddsWidgetModel.oddsList && oddsWidgetModel.oddsList.length > 0) {
		const marketTypes = oddsWidgetModel.oddsList
			.filter((odd: OddsModel) => {
				if (odd.bookmaker && oddsWidgetModel.bookmakers) {
					return odd.bookmaker.id === oddsWidgetModel.bookmakers[0].id;
				}
				return false;
			})
			.map((odd: OddsModel) => {
				return odd.markets.map((market: any) => {
					return market.type.name;
				});
			})
			.flat()
			//TO DO REMOVE WHEN WIDGET IS UPDATED
			.filter((market: any) => {
				return market === MARKET_TYPES.TWO_WAY;
			});
		//TO DO REMOVE WHEN WIDGET IS UPDATED

		return mapMarketsToOption(t, marketTypes);
	}

	return [];
};

const mapMarketsToOption = (t: any, markets: any) => {
	if (markets && markets.length > 0)
		return markets.map((market: any) => {
			return marketToOption(t, market);
		});

	return [];
};

export const marketToOption = (t: any, market: any) => {
	if (market && market.length > 0) return { value: market, label: t(market) };

	return [];
};
