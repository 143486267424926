import { GalleryItemJson } from './GalleryItemJson';
import GalleryItemBuilder from './GalleryItemBuilder';
import Image from '../../image/Image';

export default class GalleryItem {
	readonly id: string;
	readonly comment: string;
	readonly type: string;
	readonly data: Image;
	readonly addDescriptionToMedia: boolean;
	readonly description: string;
	readonly generic: any;

	private constructor(
		id: string,
		comment: string,
		type: string,
		data: Image,
		addDescriptionToMedia: boolean = false,
		description: string,
		generic: any,
	) {
		this.id = id;
		this.comment = comment;
		this.type = type;
		this.data = data;
		this.addDescriptionToMedia = addDescriptionToMedia;
		this.description = description;
		this.generic = generic;
	}

	toJSON(): GalleryItemJson {
		return {
			id: this.id,
			comment: this.comment,
			type: this.type,
			data: this.data,
			addDescriptionToMedia: this.addDescriptionToMedia,
			description: this.description,
			generic: this.generic,
		};
	}

	static fromJSON(json: GalleryItemJson): GalleryItem {
		return new GalleryItem(json.id, json.comment, json.type, json.data, json.addDescriptionToMedia, json.description, json.generic);
	}

	static builder(urls?: GalleryItem): GalleryItemBuilder {
		return new GalleryItemBuilder(urls);
	}
}
