import HttpService from '../../../../services/rest/HttpService';
import Country from '../../../../models/v2/football/country/country.model';
import TournamentFilterFactory from './TournamentFilterFactory';
import StageModel from '../../../../models/v2/Stage/Entity/StageModel';
import { responseToModelStage } from '../../../../models/v2/Stage/Entity/StageMapper';
import TeamModel from '../../../../models/v2/Team/Entity/TeamModel';
import { responseToModelTeam } from '../../../../models/v2/Team/Entity/TeamMapper';
import TournamentModel from '../../../../models/v2/Tournament/Entity/TournamentModel';
import { responseToModelTournament } from '../../../../models/v2/Tournament/Entity/TournamentMapper';
import REACT_APP_URLS from '../../../../global-helpers/global-url.helpers';

export default class TournamentService {
	private tournamentFilterFactory: TournamentFilterFactory = new TournamentFilterFactory();

	public getTournamentList = async (clientOrder: string): Promise<TournamentModel[]> => {
		try {
			const responseArray = await HttpService.getTournaments(clientOrder);
			if (responseArray.data && responseArray.data.length > 0) {
				return responseArray.data.map((response: any) => {
					if (response) {
						return responseToModelTournament(response);
					}
					return new TournamentModel();
				});
			}

			return [];
		} catch (e) {
			return [];
		}
	};

	public getTournament = async (id: string, lang: string = 'en'): Promise<TournamentModel> => {
		try {
			const response = await HttpService.getTournamentById(id, lang);
			return responseToModelTournament(response.data);
		} catch (e) {
			return new TournamentModel();
		}
	};

	public getTournamentStages = async (id: string, lang: string = 'en'): Promise<StageModel[]> => {
		try {
			const responseArray = await HttpService.getStagesBySeasonId(id, lang);
			const responseData = (await responseArray.data.stages) || new Array<StageModel>();
			if (responseData && responseData.length > 0) {
				return responseData.map((response: any) => {
					return response ? responseToModelStage(response) : new StageModel();
				});
			}
			return responseData;
		} catch (e) {
			return new Array<StageModel>();
		}
	};

	public getTournamentStageTeams = async (id: string, lang: string = 'en'): Promise<TeamModel[]> => {
		try {
			const responseArray = await HttpService.getTournamentStageTeams(id, lang);
			const responseData = (await responseArray.data) || new Array<TeamModel>();
			if (responseData && responseData.length > 0) {
				return responseData.map((response: any) => {
					return response ? responseToModelTeam(response) : new TeamModel();
				});
			}
			return responseData;
		} catch (e) {
			return new Array<TeamModel>();
		}
	};

	public searchTournamentStageTeams = async (teamName: string): Promise<TeamModel[]> => {
		try {
			const responseArray = await HttpService.getTeam(teamName);
			const responseData = (await responseArray.data) || new Array<TeamModel>();
			if (responseData && responseData.length > 0) {
				return responseData.map((response: any) => {
					return response ? responseToModelTeam(response) : new TeamModel();
				});
			}
			return responseData;
		} catch (e) {
			return new Array<TeamModel>();
		}
	};

	public updateTournamentStageTeams = async (stageId: string, teams: any): Promise<any> => {
		try {
			return await HttpService.updateTournamentStageTeams(stageId, teams);
		} catch (e) {
			return Promise.reject();
		}
	};

	public filterTournaments = (tournaments: TournamentModel[], searchName: string, countryFilter: Country[]): TournamentModel[] => {
		return this.tournamentFilterFactory.filter(tournaments, searchName, countryFilter);
	};

	public extractCountriesFromTournaments = (tournaments: TournamentModel[] = []): Country[] => {
		let countries: Country[] = tournaments.map((tournament: TournamentModel) => tournament.country);
		countries = this.getUniqueOnly(countries, 'id');
		return countries;
	};

	public updateTournamentLogo = async (tournament: TournamentModel, file: File) => {
		tournament.urlLogo = await this.getLogoPath(file);
		return tournament;
	};

	public updateTournamentImageResource = async (tournamentId: number, path: string): Promise<boolean> => {
		try {
			await HttpService.postAsset(tournamentId, 'LOGO', 'TOURNAMENT', path);
			return true;
		} catch (e) {
			return false;
		}
	};

	private getLogoPath = async (file: File): Promise<string> => {
		const headers = { Project: 'assets', 'X-Project': 'assets', 'content-type': 'multipart/form-data' };
		const formData = new FormData();
		formData.append('file', file);
		const response = await HttpService.postImg('/upload', formData, headers);
		if (response.data.path) {
			return REACT_APP_URLS.REACT_APP_IMAGE_API_BASE_URL + '/process/' + response.data.path;
		}
		return '';
	};

	private getUniqueOnly = (array: any, filterByProperty: any) => {
		const result = [];
		const map = new Map();

		for (const item of array) {
			if (!map.has(item[filterByProperty])) {
				map.set(item[filterByProperty], true);
				result.push({ ...item });
			}
		}

		return result;
	};
}
