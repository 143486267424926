import React from 'react';
import { Col, Row } from 'reactstrap';
import { Properties } from './properties/team-players-widget-view.properties';
import TeamPlayerModel from '../../../../partials/team-players-select/models/team-player.model';

export const TeamPlayersView: React.FunctionComponent<Properties> = ({ blockPreview, t }) => {
	if (blockPreview && blockPreview.team && blockPreview.team.id) {
		return (
			<Row className='p4'>
				<Col className='text-left'>
					<Row>
						<Col className='m-2 ml-4 d-flex align-items-center'>
							<img height='50' alt={blockPreview.team.name} src={blockPreview.team.logo} className='h-40 mx-1' />
							<h4>{blockPreview.team.name}</h4>
						</Col>
					</Row>
					<Row className='px-4'>
						<Col>
							<hr></hr>
						</Col>
					</Row>
					<Row className='px-4'>
						<Col className='text-left'>
							{blockPreview.injuredPlayers && blockPreview.injuredPlayers.length > 0 && (
								<div className='mb-1'>
									<b>{t('injured_players')}: </b>
									{blockPreview.injuredPlayers.map(
										(player: TeamPlayerModel, index: number) => ` ${player.name}${index === blockPreview.injuredPlayers.length - 1 ? '' : ','}`,
									)}
								</div>
							)}
						</Col>
					</Row>
					<Row className='px-4'>
						<Col className='text-left'>
							{blockPreview.suspendedPlayers && blockPreview.suspendedPlayers.length > 0 && (
								<div className='mb-1'>
									<b>{t('suspended_players')}: </b>
									{blockPreview.suspendedPlayers.map(
										(player: TeamPlayerModel, index: number) => ` ${player.name}${index === blockPreview.suspendedPlayers.length - 1 ? '' : ','}`,
									)}
								</div>
							)}
						</Col>
					</Row>
					<Row className='mb-2 px-4'>
						<Col>
							<span className='mr-2 text-nowrap'>
								{t('display_goalkeepers')}:{' '}
								<strong>
									<span className={blockPreview.displayKeeper ? 'text-success' : 'text-danger'}>
										{blockPreview.displayKeeper ? t('yes') : t('no')}
									</span>
								</strong>
								,
							</span>
							<span className='mr-2 text-nowrap'>
								{t('display_defenders')}:{' '}
								<strong>
									<span className={blockPreview.displayDefender ? 'text-success' : 'text-danger'}>
										{blockPreview.displayDefender ? t('yes') : t('no')}
									</span>
								</strong>
								,
							</span>
							<span className='mr-2 text-nowrap'>
								{t('display_midfielders')}:{' '}
								<strong>
									<span className={blockPreview.displayMidfielder ? 'text-success' : 'text-danger'}>
										{blockPreview.displayMidfielder ? t('yes') : t('no')}
									</span>
								</strong>
								,
							</span>
							<span className='mr-2 text-nowrap'>
								{t('display_forwards')}:{' '}
								<strong>
									<span className={blockPreview.displayForward ? 'text-success' : 'text-danger'}>
										{blockPreview.displayForward ? t('yes') : t('no')}
									</span>
								</strong>
								,
							</span>
							<span className='mr-2 text-nowrap'>
								{t('display_unknown')}:{' '}
								<strong>
									<span className={blockPreview.displayUnknown ? 'text-success' : 'text-danger'}>
										{blockPreview.displayUnknown ? t('yes') : t('no')}
									</span>
								</strong>
								,
							</span>
						</Col>
					</Row>
				</Col>
			</Row>
		);
	}

	return null;
};
