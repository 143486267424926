import Image from '../../../../../../models/image/Image';
import ModelMapper from '../../../../../../models/ModelMapper';

export const normalizePage = (page: number) => {
	return page < 1 ? 1 : page;
};

export const imageListFromResponse = (response: any) => {
	if (response) {
		return response.map((image: Image) => {
			return ModelMapper.remapImageFromResponse(image);
		});
	}

	return [];
};

export const createRequestImageUrl = (contentSearchInput: string, advancedContentSearchInput: string, page: number) => {
	advancedContentSearchInput = advancedContentSearchInput ? advancedContentSearchInput : '';

	if (contentSearchInput.length > 0 && advancedContentSearchInput.length > 0) {
		return `/v2/images/search?query=${contentSearchInput}&page=${normalizePage(page)}${advancedContentSearchInput}`;
	} else if (contentSearchInput.length > 0 && advancedContentSearchInput.length < 1) {
		return `/v2/images/search?query=${contentSearchInput}&page=${normalizePage(page)}`;
	} else if (contentSearchInput.length < 1 && advancedContentSearchInput.length > 0) {
		return `/v2/images/search?query=*&page=${normalizePage(page)}${advancedContentSearchInput}`;
	}

	return `/images?page=${normalizePage(page)}`;
};
