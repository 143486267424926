export const relatedConstants = {
	types: {
		team: 'team',
		player: 'player',
		match: 'match',
		article: 'article',
		video: 'video',
		gallery: 'gallery',
		tag: 'tag',
		coach: 'coach',
		tournament: 'tournament',
		venue: 'venue',
		president: 'team_president',
		presidentOnly: 'president',
		season: 'season',
		competition: 'competition',
		game: 'game',
		arena: 'arena',
		wiki: 'wiki',
		event: 'event',
		race: 'race',
	},
	providers: {
		football: 'football-api',
		sports: 'sports-search-api',
		smp: 'smp',
	},
	relatedTypes: {
		relatedSports: 'relatedSports',
		relatedMatches: 'relatedMatches',
	},
};

const relatedConstantsTypesArray = Object.values(relatedConstants.types);
export type relatedConstantsTypes = typeof relatedConstantsTypesArray[number];
