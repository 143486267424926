import React, { ChangeEvent, FunctionComponent, KeyboardEvent, useState } from 'react';
import { Button, Input } from 'reactstrap';
import { DATA_QA } from '../../../helpers/data-qa.properties';
import { useTranslation } from 'react-i18next';
import '../../../styles/custom-entities-listing.style.scss';
import { LIMIT } from '../../../helpers/custom-entities.helper';
import { getFilterIconAsCssUrl, getSearchIconAsCssUrl } from '../../../../../../global-helpers/global-icons.helpers';

type Props = {
	currentOffset: number;
	getContent: (searchQuery: string, offset: number, limit: number) => void;
	advancedContentSearchInput: string;
	contentSearchInput: string;
	updateSearchText: (text: string, pageSearchQuery: string) => void;
	setToggle: (toggle: boolean) => void;
	setCurrentOffset: (offset: number) => void;
};

export const CustomEntitySearchComponent: FunctionComponent<Props> = ({
	advancedContentSearchInput,
	getContent,
	contentSearchInput,
	currentOffset,
	updateSearchText,
	setToggle,
	setCurrentOffset,
}) => {
	const [t] = useTranslation();
	const [toggleFilters, setToggleAdvancedFilters] = useState(false);
	const [searchQuery, setSearchQuery] = useState(contentSearchInput ? contentSearchInput : '');

	const toggleAdvancedFilters = () => {
		setToggleAdvancedFilters(!toggleFilters);
		setToggle(!toggleFilters);
	};

	const onContentSearchInput = (data: ChangeEvent<HTMLInputElement>) => {
		let searchText = data.target.value;
		setSearchQuery(data.target.value);

		if (searchText.length < 1 && advancedContentSearchInput.length < 1) {
			getContent('', 0, LIMIT);
			updateSearchText('', '');
		} else {
			updateSearchText(searchText, advancedContentSearchInput);
		}
	};

	const search = (text: string) => {
		if (text.match(/^ *$/) !== null && advancedContentSearchInput.match(/^ *$/) !== null) {
			setSearchQuery('');
			updateSearchText('', '');
			getContent('', 0, LIMIT);
		} else {
			setCurrentOffset(0);
			getContent(advancedContentSearchInput, currentOffset, LIMIT);
			updateSearchText(text, advancedContentSearchInput);
		}
	};

	const onContentSearch = (event: KeyboardEvent) => {
		if (event.keyCode === 13) {
			search(searchQuery);
		}
	};

	return (
		<div data-qa={DATA_QA.SEARCH_FIELD} className='custom-entities-search-input-group'>
			<Input
				className='custom-entities-search-input-field'
				type='text'
				id='custom-entities-search-input'
				name='custom-entities-search-input'
				placeholder={t('search_for_entity')}
				onChange={(el) => onContentSearchInput(el)}
				onKeyDown={onContentSearch}
				value={contentSearchInput}
				element='input'
			/>
			<div className='search-bar-btns'>
				<Button className='custom-entities-search-button' id='custom-entity-listing-search-button' onClick={() => search(searchQuery)}>
					<div className='search-btns-icons' style={{ backgroundImage: getSearchIconAsCssUrl() }} />
				</Button>
				<Button className={`advanced-filters-toggle-button ${toggleFilters ? 'advance-filters-toggled' : ''}`} onClick={toggleAdvancedFilters}>
					<div className='search-btns-icons' style={{ backgroundImage: getFilterIconAsCssUrl() }} />
				</Button>
			</div>
		</div>
	);
};
