import { SquadPlayerJson } from './squad-player.json';
import SquadPlayerBuilder from './squad-player.builder';
export default class SquadPlayerModel {
	readonly id: string;
	readonly name: string;
	readonly status: string;
	readonly contractType: string;
	readonly startDate: string;
	readonly endDate: string;
	readonly shirtNumber: number;
	readonly birthdate: string;
	readonly image: string;

	private constructor(
		id: string,
		name: string,
		status: string,
		contractType: string,
		startDate: string,
		endDate: string,
		shirtNumber: number,
		birthdate: string,
		image: string,
	) {
		this.id = id;
		this.name = name;
		this.status = status;
		this.contractType = contractType;
		this.startDate = startDate;
		this.endDate = endDate;
		this.shirtNumber = shirtNumber;
		this.birthdate = birthdate;
		this.image = image;
	}

	toJSON(): SquadPlayerJson {
		return {
			id: this.id,
			name: this.name,
			status: this.status,
			contractType: this.contractType,
			startDate: this.startDate,
			endDate: this.endDate,
			shirtNumber: this.shirtNumber,
			birthdate: this.birthdate,
			image: this.image,
		};
	}

	static fromJSON(json: SquadPlayerJson): SquadPlayerModel {
		return new SquadPlayerModel(
			json.id,
			json.name,
			json.status,
			json.contractType,
			json.startDate,
			json.endDate,
			json.shirtNumber,
			json.birthdate,
			json.image,
		);
	}

	static builder(player?: SquadPlayerModel): SquadPlayerBuilder {
		return new SquadPlayerBuilder(player);
	}
}
