import React, { useEffect, useState } from 'react';
import { Col, Row, Button } from 'reactstrap';
import SquadPlayerModel from '../../../../../models/team-manual-data/squad-player/squad-player.model';
import { search } from '../../../../Partials/Shared/search-in-text/search-in-text.helper';
import DateTimePickerContainer from '../../../../Partials/Sidebar/GeneralContentAttributes/subcomponents/DateTimePicker/DateTimePickerContainer';
import { DebounceInput } from 'react-debounce-input';
import Loader from 'react-spinners/BeatLoader';

type Properties = {
	players: SquadPlayerModel[];
	startDateChange: (player: SquadPlayerModel, date: string) => void;
	endDateChange: (player: SquadPlayerModel, date: string) => void;
	removeFromSquad: (player: any) => void;
	isContentLoading: boolean;
	t: any;
};

const HistoricalPlayers: React.FunctionComponent<Properties> = (props) => {
	const { players, t, startDateChange, endDateChange, removeFromSquad, isContentLoading } = props;

	const initialPlayers = [...players].filter((player: SquadPlayerModel) => player.status !== 'ACTIVE');

	const [historicalPlayers, setPlayers] = useState([...initialPlayers]);

	const [searchLoading, setLoad] = useState(false);

	const [searchValue, setValue] = useState('');

	const [showPlayers, showPlayersToggle] = useState(false);

	useEffect(() => {
		if (players && players.length > 0) {
			setPlayers([...players].filter((player: SquadPlayerModel) => player.status !== 'ACTIVE'));
		}
	}, [players]);

	const onKeyDown = (e: any) => {
		setLoad(true);

		if (e.keyCode === 13) {
			setLoad(false);
		}
	};

	const onPlayerSearch = (value: string) => {
		setValue(value);
		search(value.trim(), t, initialPlayers, 'name').then((list: any[]) => {
			setPlayers(list);
			setLoad(false);
		});
	};

	if (initialPlayers.length === 0) {
		return (
			<>
				<div className={`card-header font-weight-bold ${isContentLoading ? 'loading-overlay' : ''}`}>{t('historical_players')}</div>
				<p className='text-center pt-3'>{t('no_historical_players')}</p>
			</>
		);
	}

	return (
		<>
			<div className='card-header d-flex align-items-center'>
				<div className='flex-1 mr-auto font-weight-bold'>{t('historical_players')}</div>
				<Button
					color='primary'
					outline
					id='historical-players-show-all-button'
					bssize='sm'
					onClick={() => {
						onPlayerSearch('');
						showPlayersToggle(!showPlayers);
					}}
				>
					{!showPlayers ? t('show_all') : t('hide_all')}
				</Button>
				<div className='position-relative mb-0'>
					{showPlayers && (
						<DebounceInput
							type='text'
							value={searchValue}
							onKeyDown={onKeyDown}
							debounceTimeout={500}
							id='historical-players-filter-input'
							className='ml-2 form-control-md form-control pr-0'
							placeholder={t('filter_by_name')}
							onChange={(e: any) => onPlayerSearch(e.target.value)}
						/>
					)}
					{!searchLoading && searchValue.trim().length > 0 && (
						<i
							className='fa fa-times position-absolute p-2 pointer-events-allowed'
							onClick={() => onPlayerSearch('')}
							style={{ top: '5px', right: '-5px' }}
						/>
					)}
					{searchLoading && (
						<div className='position-absolute h-100 d-flex align-items-center opacity-03' style={{ top: '0', right: '10px' }}>
							<Loader size={5} />
						</div>
					)}
				</div>
			</div>
			{showPlayers && (
				<div className={`card-body p-1 animated fadeIn ${isContentLoading || searchLoading ? 'loading-overlay' : ''}`}>
					{historicalPlayers.length > 0 && (
						<Row className='px-3 py-2 font-weight-bold'>
							<Col sm='3'>{t('player')}</Col>
							<Col sm='2'>{t('start_date')}</Col>
							<Col sm='2'>{t('end_date')}</Col>
							<Col sm='5' className='text-right'>
								{t('actions')}
							</Col>
						</Row>
					)}
					{historicalPlayers.length === 0 && <p className='text-center pt-3'>{t('no_matches')}</p>}
					{historicalPlayers.length > 0 &&
						historicalPlayers.map((player: SquadPlayerModel, index: number) => (
							<Row
								key={player.id}
								id={`historical-player-row-${player.id}-${index}`}
								className='px-3 py-2 align-items-center row-striped border-left border-right'
							>
								<Col sm='3'>
									<div title={player.name} className='pt-2 pr-2 pb-2'>
										{player.name}
									</div>
								</Col>
								<Col sm='2'>
									<DateTimePickerContainer
										id={`historical-player-start-date-${player.id}-${index}`}
										enableTime={false}
										dateFormat='Y.m.d'
										date={player.startDate}
										t={t}
										placeholder={t('select_date')}
										onDateChange={(date: string) => startDateChange(player, date)}
									/>
								</Col>
								<Col sm='2'>
									<DateTimePickerContainer
										id={`historical-player-end-date-${player.id}-${index}`}
										enableTime={false}
										dateFormat='Y.m.d'
										date={player.endDate}
										t={t}
										placeholder={t('select_date')}
										onDateChange={(date: string) => endDateChange(player, date)}
									/>
								</Col>
								<Col sm='5'>
									<Row>
										<Button
											onClick={() => removeFromSquad({ player, action: 'delete' })}
											id={`historical-player-delete-assosiation-${player.id}-${index}`}
											color='danger'
											className='ml-auto px-1 py-2'
										>
											<i className='fa fa-trash mx-1' />
											{t('delete_association')}
										</Button>
									</Row>
								</Col>
							</Row>
						))}
				</div>
			)}
		</>
	);
};

export default HistoricalPlayers;
