import { ContentAttributesJson } from './content-attributes-json';
import ContentAttributesBuilder from './content-attributes-builder';

export default class ContentAttributes {
	readonly id: string = '';
	readonly name: string = '';
	readonly slug: string = '';
	readonly createdBy: any = {};
	readonly createdAt: string = '';
	readonly updatedAt: string = '';
	readonly description: string = '';
	readonly isDefault: boolean = false;

	private constructor(
		id: string,
		name: string,
		slug: string,
		createdBy: any,
		createdAt: string,
		updatedAt: string,
		description: string,
		isDefault: boolean,
	) {
		this.id = id;
		this.name = name;
		this.slug = slug;
		this.createdBy = createdBy;
		this.createdAt = createdAt;
		this.updatedAt = updatedAt;
		this.description = description;
		this.isDefault = isDefault;
	}

	toJSON(): ContentAttributesJson {
		return {
			id: this.id,
			name: this.name,
			slug: this.slug,
			createdBy: this.createdBy,
			createdAt: this.createdAt,
			updatedAt: this.updatedAt,
			description: this.description,
			isDefault: this.isDefault,
		};
	}

	static fromJSON(json: ContentAttributesJson): ContentAttributesJson {
		return new ContentAttributes(
			json.id,
			json.name,
			json.slug,
			json.createdBy,
			json.createdAt,
			json.updatedAt,
			json.description,
			json.isDefault,
		);
	}

	static builder(region?: ContentAttributes): ContentAttributesBuilder {
		return new ContentAttributesBuilder(region);
	}
}
