import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

type Properties = {
	t: any;
	showModal: boolean;
	toggleModal: (toggle: boolean) => any;
	movedNode: any;
	updateCategory: (movedNode: any) => any;
};

export const CategoryDragAndDropModal: React.FunctionComponent<Properties> = ({ t, showModal, toggleModal, movedNode, updateCategory }) => {
	return (
		<Modal isOpen={showModal}>
			<ModalHeader className='pl-3 pr-3 pt-2 pb-2 bg-info' toggle={() => toggleModal(false)}>
				{t('rearrange_category')}
			</ModalHeader>
			<ModalBody className='pb-2'>
				<div className='overflow-hidden'>
					{t('rearrange_category_body')}
					<strong>{` ${movedNode ? movedNode.node.titleInTree : ''}`}</strong>?
				</div>
			</ModalBody>
			<ModalFooter className='border-0'>
				<Button id='modal-drag-cancel-button' color='base' onClick={() => toggleModal(false)} className='ml-auto'>
					{t('close')}
				</Button>
				<Button id='modal-drag-confirm-button' color='info' onClick={() => updateCategory(movedNode)}>
					{t('confirm')}
				</Button>
			</ModalFooter>
		</Modal>
	);
};
