import {
	MATCH_LINEUPS_UPDATE,
	MATCH_EVENTS_RECEIVED,
	MATCH_TEAMS_UPDATE,
	MATCH_DATE_RANGE_UPDATE,
	MATCH_FILTERS_UPDATE,
	MATCH_BLACKLIST_RECEIVED,
} from '../action-creators/match-lineups-action-creator';
import MatchDetailsModel from '../../views/Resources/match-lineups/models/match/match-details.model';
import TournamentModel from '../../views/Partials/Blocky/blocks/widgets/player-profile/models/tournament-model/tournament.model';
import StageModel from '../../views/Partials/Blocky/blocks/widgets/fixtures/models/stage/stage.model';
import RoundModel from '../../views/Partials/Blocky/blocks/widgets/fixtures/models/round/round.model';
import MatchSeasonModel from '../../views/Resources/match-lineups/models/match/match-season.model';

export type MatchesFilters = {
	tournament: TournamentModel | null;
	season: MatchSeasonModel | null;
	stage: StageModel | null;
	round: RoundModel | null;
};

interface InitialState {
	match: MatchDetailsModel | null;
	matches: MatchDetailsModel[];
	teams: MatchDetailsModel[];
	dateRange: string[];
	filters: MatchesFilters;
}
const initialState: InitialState = {
	match: null,
	matches: [],
	teams: [],
	dateRange: [],
	filters: { tournament: null, season: null, stage: null, round: null },
};

function matchLineupsReducer(state: any = initialState, action: any) {
	if (action.type === MATCH_LINEUPS_UPDATE) {
		return Object.assign({}, state, {
			match: action.payload,
		});
	}

	if (action.type === MATCH_EVENTS_RECEIVED) {
		return Object.assign({}, state, {
			matches: action.payload,
		});
	}

	if (action.type === MATCH_TEAMS_UPDATE) {
		return Object.assign({}, state, {
			teams: action.payload,
		});
	}

	if (action.type === MATCH_DATE_RANGE_UPDATE) {
		return Object.assign({}, state, {
			dateRange: action.payload,
		});
	}

	if (action.type === MATCH_FILTERS_UPDATE) {
		return Object.assign({}, state, {
			filters: action.payload,
		});
	}

	if (action.type === MATCH_BLACKLIST_RECEIVED) {
		return Object.assign({}, state, {
			matches: action.payload,
		});
	}

	return state;
}

export default matchLineupsReducer;
