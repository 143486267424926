import 'reflect-metadata';
import { jsonArrayMember, jsonMember, jsonObject } from 'typedjson';
import LiveBlogModel from './live-blog.model';
import PaginationMetaModel from './live-blog-meta.model';

@jsonObject()
export default class LiveBlogListingModel {
	@jsonArrayMember(LiveBlogModel)
	public data: LiveBlogModel[] = [];
	@jsonMember(PaginationMetaModel)
	public meta: PaginationMetaModel = new PaginationMetaModel();
}
