import TeamModel from '../../../../partials/team-select/models/team.model';
import { TeamPlayersWidgetJson } from './team-players-widget.json';
import TeamPlayersWidgetBuilder from './team-players-widget.builder';
import TeamPlayerModel from '../../../../partials/team-players-select/models/team-player.model';

export default class TeamPlayersWidgetModel {
	readonly team: TeamModel;
	readonly players: TeamPlayerModel[] = [];
	readonly injuredPlayers: TeamPlayerModel[] = [];
	readonly suspendedPlayers: TeamPlayerModel[] = [];
	readonly displayKeeper: boolean = false;
	readonly displayDefender: boolean = false;
	readonly displayMidfielder: boolean = false;
	readonly displayForward: boolean = false;
	readonly displayUnknown: boolean = false;

	private constructor(
		team: TeamModel,
		players: any[],
		injuredPlayers: any[],
		suspendedPlayers: any[],
		displayKeeper: boolean,
		displayDefender: boolean,
		displayMidfielder: boolean,
		displayForward: boolean,
		displayUnknown: boolean,
	) {
		this.team = team;
		this.players = players;
		this.injuredPlayers = injuredPlayers;
		this.suspendedPlayers = suspendedPlayers;
		this.displayKeeper = displayKeeper;
		this.displayDefender = displayDefender;
		this.displayMidfielder = displayMidfielder;
		this.displayForward = displayForward;
		this.displayUnknown = displayUnknown;
	}

	toJson(): TeamPlayersWidgetJson {
		return {
			team: this.team,
			players: this.players,
			injuredPlayers: this.injuredPlayers,
			suspendedPlayers: this.suspendedPlayers,
			displayKeeper: this.displayKeeper,
			displayDefender: this.displayDefender,
			displayMidfielder: this.displayMidfielder,
			displayForward: this.displayForward,
			displayUnknown: this.displayUnknown,
		};
	}

	static fromJson(json: TeamPlayersWidgetJson): TeamPlayersWidgetModel {
		return new TeamPlayersWidgetModel(
			json.team,
			json.players,
			json.injuredPlayers,
			json.suspendedPlayers,
			json.displayKeeper,
			json.displayDefender,
			json.displayMidfielder,
			json.displayForward,
			json.displayUnknown,
		);
	}

	static builder(teamPlayers?: TeamPlayersWidgetModel): TeamPlayersWidgetBuilder {
		return new TeamPlayersWidgetBuilder(teamPlayers);
	}
}
