import {
	REQUEST_ARTICLE_COMMENTS_POLICIES_RECEIVED,
	REQUEST_VIDEO_COMMENTS_POLICIES_RECEIVED,
	REQUEST_POLLS_COMMENTS_POLICIES_RECEIVED,
	REQUEST_GALLERY_COMMENTS_POLICIES_RECEIVED,
} from '../action-creators/comment-policy-action.creator';
import ModelMapper from '../../models/ModelMapper';

export function commentsPoliciesMiddleware() {
	return function (next: any) {
		return function (action: any) {
			if (action) {
				if (action.type === REQUEST_ARTICLE_COMMENTS_POLICIES_RECEIVED) {
					action.payload = ModelMapper.remapResponseToContentAttributesList(action.payload.data.data);
				}

				if (action.type === REQUEST_VIDEO_COMMENTS_POLICIES_RECEIVED) {
					action.payload = ModelMapper.remapResponseToContentAttributesList(action.payload.data.data);
				}

				if (action.type === REQUEST_POLLS_COMMENTS_POLICIES_RECEIVED) {
					action.payload = ModelMapper.remapResponseToContentAttributesList(action.payload.data.data);
				}

				if (action.type === REQUEST_GALLERY_COMMENTS_POLICIES_RECEIVED) {
					action.payload = ModelMapper.remapResponseToContentAttributesList(action.payload.data.data);
				}
			}
			next(action);
		};
	};
}
