import React, { FunctionComponent, useEffect, useState } from 'react';
import Select from 'react-select';
import { Col, Label, Row } from 'reactstrap';
import { DATA_QA_ATTRIBUTES } from '../../../../../Partials/Sidebar/general-content/constants/data-qa.attributes';
import LiveBlogModel from '../../../models/live-blog.model';
import OddClientBookmakersModel from '../../../../../Partials/Blocky/partials/available-bookmakers-select/models/odd-client-bookmakers-full.model';
import HttpService from '../../../../../../services/rest/HttpService';
import { customOption } from '../../../../../Partials/Shared/custom-select-options/custom-select-option';

type Properties = {
	t: any;
	liveBlog: LiveBlogModel;
	setLiveBlogSponsors: (sponsors: OddClientBookmakersModel[]) => void;
};

export const LiveBlogSponsorsSelect: FunctionComponent<Properties> = ({ t, setLiveBlogSponsors, liveBlog }) => {
	const [sponsorsList, setSponsorsList] = useState<OddClientBookmakersModel[]>([]);

	useEffect(() => {
		HttpService.getAllBookmakers()
			.then(({ data }: { data: OddClientBookmakersModel[] }) => {
				setSponsorsList(data);
			})
			.catch((e: unknown) => e);
	}, []);

	const sponsorToOption = (sponsor: OddClientBookmakersModel) => ({
		value: sponsor.id,
		label: sponsor.name,
		data: sponsor,
		logo: sponsor.url_logo,
		type: 'bookmaker',
	});

	return (
		<Row className='mb-3 sponsors'>
			<Col>
				<Label htmlFor={DATA_QA_ATTRIBUTES.LIVE_BLOG_SPONSORS}>
					<strong>{t('sponsors')}</strong>
				</Label>
				<Select
					id={DATA_QA_ATTRIBUTES.LIVE_BLOG_SPONSORS}
					value={liveBlog.sponsors && liveBlog.sponsors.length > 0 ? liveBlog.sponsors.map(sponsorToOption) : []}
					options={sponsorsList.map(sponsorToOption)}
					onChange={(selections: any) =>
						setLiveBlogSponsors(selections ? selections.map(({ data }: { data: OddClientBookmakersModel }) => data) : [])
					}
					placeholder={t('select')}
					noOptionsMessage={(inputValue) => inputValue && t('no_options')}
					isMulti
					formatOptionLabel={(option) =>
						customOption(option, undefined, {
							borderRadius: 3.5,
							maxWidth: 'unset',
							width: '75px',
							height: '15px',
							backgroundPosition: 'center',
							backgroundSize: 'contain',
						})
					}
				/>
			</Col>
		</Row>
	);
};
