import React, { useEffect } from 'react';
import ReactPaginate, { ReactPaginateProps } from 'react-paginate';
import { PAGINATE_PROPS } from '../../BaseComponents/ListingComponent/subcomponents/AuthorizedListing/properties/PaginateProps';
import PaginationMeta from '../../../../models/pagination/PaginationMeta';

type Properties = {
	type: string;
	t: any;
	getContent: (type: string, selectedPage: number) => any;
	contentPagination: PaginationMeta;
};

const SuggestedListPagination: React.FunctionComponent<Properties> = (props) => {
	const { type, t, getContent, contentPagination } = props;

	const reactPaginateProps = (t: any, contentPagination: any): ReactPaginateProps => {
		return {
			...PAGINATE_PROPS,
			previousLabel: t('previous_page'),
			nextLabel: t('next_page'),
			pageCount: contentPagination ? contentPagination.totalPages : 0,
			onPageChange: handlePageClick,
			forcePage: contentPagination.currentPage - 1,
		};
	};

	const handlePageClick = (data: any) => {
		const selectedPage = data.selected + 1;
		getContent(type, selectedPage);
	};

	return <ReactPaginate {...reactPaginateProps(t, contentPagination)} />;
};

export default SuggestedListPagination;
