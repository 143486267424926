import React from 'react';
import debounce from 'lodash.debounce';
import { customOption } from '../shared/custom-select-option';
import AsyncSelect from 'react-select/async';
import { Label } from 'reactstrap';
import { Properties, State } from './properties/player-properties';
import HttpService from '../../../../../services/rest/HttpService';
import { playerToOption } from './helpers/player-select.helper';
import { remapPlayerFromResponce, responseToOption } from './helpers/player-select.helper';
import ErrorHandler from '../error/error-handler-component';

export default class PlayerSelect extends React.Component<Properties, State> {
	defaultLabel = (<Label>{this.props.t('player')}:</Label>);

	constructor(props: Properties) {
		super(props);
		this.state = {
			playerNameInput: '',
		};
	}

	searchPlayer(search: string, callback: any) {
		HttpService.getPlayer(search)
			.then((response: any) => {
				const options = response.data.map((player: any) => responseToOption(player));
				callback(options);
			})
			.catch((e: any) => e);
	}

	onPlayerNameInputChangeState = (input: string) => {
		this.setState({
			...this.state,
			playerNameInput: input,
		});

		return input;
	};

	displayLabel(display: any, text: any) {
		if (display !== undefined && !display) {
			return null;
		}

		if (display === undefined || display === null) {
			return this.defaultLabel;
		}

		if (display) {
			return text ? <Label>{text}</Label> : this.defaultLabel;
		}
	}

	render() {
		const { onSelect, labelText, withLabel, value, t, inputId } = this.props;

		return (
			<>
				{this.displayLabel(withLabel, labelText)}
				<AsyncSelect
					inputId={inputId}
					className='mb-2'
					placeholder={t('search')}
					loadOptions={debounce(this.searchPlayer.bind(this), 500)}
					onInputChange={debounce(this.onPlayerNameInputChangeState, 500)}
					value={playerToOption(value)}
					formatOptionLabel={customOption}
					noOptionsMessage={(inputValue) => inputValue && t('no_options')}
					onChange={(selection: any) => onSelect(remapPlayerFromResponce(selection.data))}
				/>
				{!this.props.isValid && (!this.props.value || (this.props.value && Object.entries(this.props.value).length === 0)) && (
					<ErrorHandler t={this.props.t} errorMessage='field_is_required' />
				)}
			</>
		);
	}
}
