import { call, put, takeEvery } from 'redux-saga/effects';
import HttpService from '../../services/rest/HttpService';
import { actionService } from '../../App';
import { onError } from '../action-creators/GeneralActions';
import {
	LANGUAGE_UPDATE,
	LANGUAGE_UPDATE_SUCCESS,
	PROFILE_PASSWORD_UPDATE,
	PROFILE_PASSWORD_UPDATE_SUCCESS,
	PROFILE_UPDATE,
	PROFILE_UPDATE_SUCCESS,
	PROFILE_UPDATE_FAILED,
	PROFILE_PASSWORD_UPDATE_FAILED,
} from '../action-creators/ProfileActionCreators';

function* updateUserProfile(action: any) {
	try {
		let headers = {};
		yield call(HttpService.patch, '/profile', action.payload.profile, headers);
		yield put({ type: PROFILE_UPDATE_SUCCESS, payload: action.payload.originalProfile });
	} catch (error) {
		yield put(onError(error));
		yield put({ type: PROFILE_UPDATE_FAILED });
		actionService.emitError(error);
	}
}

function* updateUserLanguage(action: any) {
	try {
		let headers = {};
		yield call(HttpService.patch, '/profile', action.payload.profile, headers);
		yield put({ type: LANGUAGE_UPDATE_SUCCESS, payload: action.payload.originalProfile });
	} catch (error) {
		yield put(onError(error));
		actionService.emitError(error);
	}
}

function* updateUserPassword(action: any) {
	try {
		let headers = {};
		yield call(HttpService.patch, '/profile', { password: action.payload.password }, headers);
		yield put({ type: PROFILE_PASSWORD_UPDATE_SUCCESS, payload: action.payload.originalProfile });
	} catch (error) {
		yield put(onError(error));
		yield put({ type: PROFILE_PASSWORD_UPDATE_FAILED });
		actionService.emitError(error);
	}
}

function* profileSaga() {
	yield takeEvery(PROFILE_UPDATE, updateUserProfile);
	yield takeEvery(LANGUAGE_UPDATE, updateUserLanguage);
	yield takeEvery(PROFILE_PASSWORD_UPDATE, updateUserPassword);
}

export default profileSaga;
