import * as React from 'react';
import './styles/blacklisted-container.scss';

type Properties = {
	t: any;
	isContentLoading: boolean;
	toggleModal: (toggle: boolean) => any;
	type: string;
};

const Blacklisted: React.FunctionComponent<Properties> = ({ t, isContentLoading, toggleModal, type }) => {
	const icon = type === 'squad' ? 'users' : 'user';

	return (
		<div className={`blacklisted-container p-3 mb-2 ${isContentLoading ? 'loading-overlay' : ''}`}>
			<div className='blacklisted-message pb-1'>
				<i className={`text-white fa fa-${icon} rounded-circle mr-1 team-edit-icon`} />
				<strong>{t(`blacklisted_${type}_message`)}</strong> {t(`blacklisted_${type}_info`)}
			</div>
			<button className='btn btn-link ml-0 p-0 mt-1 text-dark' onClick={() => toggleModal(true)}>
				<strong>
					<u>{t('remove_from_blacklist')}</u>
				</strong>
			</button>
		</div>
	);
};

export default Blacklisted;
