import { OnnetworkVideoJson } from './onnetwork-video.json';
import OnnetworkVideoBuilder from './onnetwork-video.builder';

export default class OnnetworkVideoModel {
	readonly id: string = '';
	readonly title: string = '';
	readonly description: string = '';
	readonly tags: string = '';
	readonly embedCode: { script: string; code: string };

	private constructor(id: string, title: string, description: string, tags: string, embedCode: { script: string; code: string }) {
		this.id = id;
		this.title = title;
		this.description = description;
		this.tags = tags;
		this.embedCode = embedCode;
	}

	toJson(): OnnetworkVideoJson {
		return {
			id: this.id,
			title: this.title,
			description: this.description,
			tags: this.tags,
			embedCode: this.embedCode,
		};
	}

	static fromJSON(json: OnnetworkVideoJson): OnnetworkVideoModel {
		return new OnnetworkVideoModel(json.id, json.title, json.description, json.tags, json.embedCode);
	}

	static builder(video?: OnnetworkVideoModel): OnnetworkVideoBuilder {
		return new OnnetworkVideoBuilder(video);
	}
}
