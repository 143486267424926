import ImagoImageModel from './imago-image.model';
import { ImagoImageJson } from './imago-image.json';
import { ImagoImageRequestJson } from './imago-image.request-json';

export default class ImagoImageBuilder {
	private json: ImagoImageJson;

	constructor(image?: ImagoImageModel | ImagoImageJson) {
		if (image && image instanceof ImagoImageModel) {
			this.json = image.toJson();
		} else if (image) {
			this.json = image;
		} else {
			this.json = {} as ImagoImageJson;
		}
	}

	public withId(id: string): ImagoImageBuilder {
		this.json.id = id;

		return this;
	}

	public withDescription(description: string): ImagoImageBuilder {
		this.json.description = description;

		return this;
	}

	public withType(type: string): ImagoImageBuilder {
		this.json.type = type;

		return this;
	}

	public withWidth(width: string): ImagoImageBuilder {
		this.json.width = width;

		return this;
	}

	public withHeight(height: string): ImagoImageBuilder {
		this.json.height = height;

		return this;
	}

	public withSource(source: string): ImagoImageBuilder {
		this.json.source = source;

		return this;
	}

	public withImage(image: any): ImagoImageBuilder {
		this.json.image = image;

		return this;
	}

	public withDate(date: any): ImagoImageBuilder {
		this.json.dateCreated = date;

		return this;
	}

	toRequestJson(): ImagoImageRequestJson {
		let json = {} as ImagoImageRequestJson;
		json.pictureid = this.json.id;
		json.caption = this.json.description;
		json.db = this.json.type;
		json.width = this.json.width;
		json.height = this.json.height;
		json.source = this.json.source;
		json.image = this.json.image;
		json.datecreated = this.json.dateCreated;

		return json;
	}

	public build(): ImagoImageModel {
		return ImagoImageModel.fromJSON(this.json);
	}
}
