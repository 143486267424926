import React from 'react';
import EmbedEdit from './embed-edit.component';
import { EmbedView } from './embed-view.component';
import { Properties } from './properties/embed-block.properties';
import { ViewTypes } from '../../../constants/general.constants';
import BlockUpdateController from '../../block-update-controller.component';
import EmbedBlockModel from './models/embed-block.model';
import EmbedSocialView from './subcomponents/embed-social-view.component';
import EmbedVideoView from './subcomponents/embed-video-view.component';

const EmbedBlock: React.FunctionComponent<Properties> = (props) => {
	if (props.view === ViewTypes.edit) {
		return <EmbedEdit block={props.block} t={props.t} />;
	} else if (props.view === ViewTypes.normal) {
		if (
			props.block.data &&
			props.block.data.preview &&
			props.block.data.preview.embedBlock &&
			props.block.data.preview.embedBlock.type === 'social'
		) {
			return <EmbedSocialView embed={props.block.data.preview.embedBlock.content} t={props.t} />;
		} else if (
			props.block.data &&
			props.block.data.preview &&
			props.block.data.preview.embedBlock &&
			props.block.data.preview.embedBlock.type === 'video'
		) {
			return <EmbedVideoView embed={props.block.data.preview.embedBlock.content} t={props.t} />;
		} else {
			return (
				<EmbedView
					preview={props.block.data && props.block.data.preview ? props.block.data.preview.embedBlock : EmbedBlockModel.builder().build()}
					t={props.t}
				/>
			);
		}
	}

	return null;
};

export default BlockUpdateController(EmbedBlock);
