import REACT_APP_URLS, { Environments } from '../global-helpers/global-url.helpers';

export const sportFootball = {
	sport: 'football',
	title: 'Football',
	entity_types: [
		{
			name: 'player',
			type: '',
		},
		{
			name: 'team',
			type: 'event_participant',
		},
		{
			name: 'coach',
			type: '',
		},
		{
			name: 'venue',
			type: '',
		},
		{
			name: 'referee',
			type: '',
		},
		{
			name: 'tournament',
			type: '',
		},
		{
			name: 'city',
			type: '',
		},
		{
			name: 'president',
			type: '',
		},
		{
			name: 'country',
			type: '',
		},
	],
};

export const sportBasketball = {
	sport: 'basketball',
	title: 'Basketball',
	entity_types: [
		{
			name: 'player',
			type: '',
		},
		{
			name: 'team',
			type: 'event_participant',
		},
		{
			name: 'coach',
			type: '',
		},
		{
			name: 'competition',
			type: '',
		},
		{
			name: 'game',
			type: 'event',
		},
		{
			name: 'arena',
			type: '',
		},
	],
};

export const sportTennis = {
	sport: 'tennis',
	title: 'Tennis',
	entity_types: [
		{
			name: 'player',
			type: 'event_participant',
		},
		{
			name: 'competition',
			type: '',
		},
		{
			name: 'match',
			type: 'event',
		},
		{
			name: 'team',
			type: 'event_participant',
		},
	],
};

export const defaultSportsForMigration = [sportFootball];

// Integration data
const generateHardcodedDataForIntegration = (projectId: string | null): any[] => {
	switch (projectId) {
		case '2018060808173315768': // sportal bg
			return [sportFootball, sportBasketball, sportTennis];
		case '20204289757785737': // smp-frontend
			return [sportFootball, sportBasketball, sportTennis];
		case '2018060808173315769': // smp.demo
			return [sportFootball, sportTennis];
		default:
			return defaultSportsForMigration;
	}
};

// Staging data
const generateHardcodedDataForStaging = (projectId: string | null): any[] => {
	switch (projectId) {
		case '2023427801303870': // Sportal bg
			return [sportFootball, sportBasketball, sportTennis];
		default:
			return defaultSportsForMigration;
	}
};

// Production data
const generateHardcodedDataForClients = (projectId: string | null): any[] => {
	switch (projectId) {
		case '2020528145311867659': // Sportal BG
			return [sportFootball, sportBasketball];
		case '202233110265939679': // Sportal Blic Serbia
			return [sportFootball, sportBasketball];
		case '20221111413498747': // Sportal Greece
			return [sportFootball, sportBasketball];
		case '20232171572518791': // Ringier Slovakia
			return [sportFootball, sportTennis];
		default:
			return defaultSportsForMigration;
	}
};

export const generateHardcodedDataBasedOnEnv = (projectId: string | null): any[] => {
	switch (REACT_APP_URLS.REACT_APP_ENV) {
		case Environments.INTEGRATION:
			return generateHardcodedDataForIntegration(projectId);
		case Environments.STAGING:
			return generateHardcodedDataForStaging(projectId);
		default:
			return generateHardcodedDataForClients(projectId);
	}
};
