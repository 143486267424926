import React from 'react';
import { Properties, State } from './properties/dugout-video-edit.properties';
import './styles/dugout-video-block.component.scss';
import DugoutVideoContainer from './dugout-video-container.component';
import { blockManagementService, blockWrapperService } from '../../../subcomponents/blocky.component';
import BlockValidation from '../../../helpers/block-validation.helper';
import DugoutVideoModel from '../../../../../../models/dugout-videos/dugout-video/dugout-video.model';
import { constructDugoutVideoContent } from './helpers/dugout-video-edit.helper';

class DugoutVideoEdit extends React.Component<Properties, State> {
	constructor(props: any) {
		super(props);
		this.state = {
			selectedDugoutVideo:
				props.block && props.block.data && props.block.data.id ? props.block.data.preview.video : DugoutVideoModel.builder().build(),
			isValid: true,
			searchText: '',
			selectedLang: '',
		};
	}

	componentDidMount(): void {
		blockWrapperService.registerOnSaveFunc({ blockId: this.props.block.id, func: this.onSave });
	}

	onSave = (index: number) => {
		const { selectedDugoutVideo, selectedLang } = this.state;
		const data = { dugoutVideo: selectedDugoutVideo };
		const validation = new BlockValidation();
		const isValid = validation.validate(this.props.block, data);
		this.setState({ ...this.state, isValid });

		if (isValid) {
			const block = Object.assign({}, this.props.block);
			block.data.id = selectedDugoutVideo.id;
			block.data.content = selectedDugoutVideo.embedCode;
			block.data.contentIframe = constructDugoutVideoContent(selectedDugoutVideo);
			block.data.preview = { video: selectedDugoutVideo, title: this.state.searchText, language: selectedLang };
			blockManagementService.blockUpdate(block, index);
		} else {
			return isValid;
		}
	};

	selectDugoutVideo = (video: DugoutVideoModel) => {
		this.setState({ ...this.state, selectedDugoutVideo: video });
	};

	componentWillUnmount(): void {
		blockWrapperService.unregisterOnSaveFunc(this.props.block.id);
	}

	updateSearchText = (search: string, lang: string) => {
		this.setState({ ...this.state, searchText: search, selectedLang: lang });
	};

	render() {
		const { t, linkedTags } = this.props;

		return (
			<div className={'mb-2'}>
				<DugoutVideoContainer
					t={t}
					isMediaSidebar={false}
					onSelect={this.selectDugoutVideo}
					updateSearchText={this.updateSearchText}
					block={this.props.block}
					selectedDugoutVideo={this.state.selectedDugoutVideo}
					isValid={this.state.isValid}
					inBlocky={true}
					linkedTags={linkedTags}
				/>
			</div>
		);
	}
}

export default DugoutVideoEdit;
