import { SelectMenuOptionType } from '../../../../../models/v2/general/select.model';
import { redirectTypes } from '../constants/redirection-types';

export const redirectTypesToOptions = (t: any): SelectMenuOptionType[] => {
	return redirectTypes.map((redType: string) => {
		return { value: redType, label: t(redType), data: redType };
	});
};

export const redirectTypeToOption = (selectedValue: string, t: any): SelectMenuOptionType | null => {
	return selectedValue ? { value: selectedValue, label: t(selectedValue), data: selectedValue } : null;
};
