import 'reflect-metadata';
import { jsonMember, jsonObject } from 'typedjson';

@jsonObject
export default class TranslationsModel {
	@jsonMember(String)
	public language: string = '';
	@jsonMember(String)
	public name: string = '';
	@jsonMember(String)
	public short_name: string = '';
	@jsonMember(String)
	public three_letter_code: string = '';
	@jsonMember(String)
	public address?: string = '';
}
