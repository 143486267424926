import 'reflect-metadata';
import { jsonMember, jsonObject } from 'typedjson';

@jsonObject
export default class GBVisionAudioModel {
	@jsonMember(String)
	public id: string | null = null;
	@jsonMember(String, { name: 'Home' })
	public home: string | null = null;
	@jsonMember(String, { name: 'Away' })
	public away: string | null = null;
	@jsonMember(String, { name: 'Time' })
	public time: string | null = null;
	@jsonMember(String, { name: 'Date' })
	public date: string | null = null;
	@jsonMember(String)
	public streamhls: string | null = null;
}
