import React from 'react';
import { Col, Row } from 'reactstrap';
import ErrorHandler from '../../../partials/error/error-handler-component';
import BlockModel from '../../../models/block.model';
import BlockValidation from '../../../helpers/block-validation.helper';
import { blockWrapperService, blockManagementService } from '../../../subcomponents/blocky.component';
import { createBlock } from '../../partials/helpers/blocky-sidebar-communication/blocky-sidebar-communication.helper';
import { WidgetsV2Ids } from '../../../constants/block.types';
import CompetitionSelectComponent from '../../../partials/competition-select/competition-select.component';
import CompetitionModel from '../../../../../../models/v2/competition/entity/competition.model';
import TennisPlayoffModel from './models/tennis-playoff.model';
import { SportTypes } from '../../../../../../constants/sport.types';
import { SPORT_SELECT_ATTRIBUTES } from '../helpers/component-attributes.constants';
import TennisCompetitionSeasonSelectComponent from '../../../partials/season-select/tennis-season-select.component';
import TennisCompetitionSeasonModel from '../../../../../../models/v2/tennis-competition-season/entity/tennis-competition-season.model';
import TennisSeasonStagesModel from '../../../../../../models/v2/tennis-competition-season/entity/tennis-season-stages.model';
import TennisStageSelectComponent from '../../../partials/stage-select/tennis-stage-select.component';
import { onRefreshTimeChangeFromMainComponent } from '../../partials/refresh-time-input/helper';
import { SelectMenuOptionType } from '../../../../../../models/v2/general/select.model';
import RefreshTimeWrapper from '../../partials/refresh-time-input/refresh-time-wrapper';
type Properties = {
	block: BlockModel;
	t: any;
	contentData: any;
	contentLanguage: string;
};

type State = {
	widgetModel: TennisPlayoffModel;
	isValid: boolean;
};

export default class TennisPlayoffWidgetEdit extends React.Component<Properties, State> {
	constructor(props: Properties) {
		super(props);
		this.state = {
			widgetModel: createBlock(
				WidgetsV2Ids.widgetTennisPlayoff,
				props.block.data.preview && props.block.data.preview.widgetModel,
				props.contentData,
			),
			isValid: true,
		};
	}

	componentDidMount(): void {
		blockWrapperService.registerOnSaveFunc({ blockId: this.props.block.id, func: this.onSave });
	}

	componentWillUnmount(): void {
		blockWrapperService.unregisterOnSaveFunc(this.props.block.id);
	}

	onSave = (index: number) => {
		const data = { widgetModel: this.state.widgetModel };
		const validation = new BlockValidation();
		const isValid = validation.validate(this.props.block, data) ? true : false;
		this.setState({ ...this.state, isValid });

		const playoffModel = TennisPlayoffModel.builder(this.state.widgetModel).build();

		if (isValid) {
			const block = Object.assign({}, this.props.block);
			block.data.preview = { widgetModel: playoffModel };
			blockManagementService.blockUpdate(block, index);
		} else {
			return isValid;
		}
	};

	updateWidgetModelState = (model: TennisPlayoffModel) => {
		this.setState({ ...this.state, widgetModel: model });
	};

	onCompetitionSelect = (competition: CompetitionModel) => {
		this.updateWidgetModelState(
			TennisPlayoffModel.builder(this.state.widgetModel)
				.withTournament(competition)
				.withSeason({} as TennisCompetitionSeasonModel)
				.withStage({} as TennisSeasonStagesModel)
				.withStages([] as TennisSeasonStagesModel[])
				.build(),
		);
	};

	onSeasonAndStagesSelect = (season: TennisCompetitionSeasonModel, stages: TennisSeasonStagesModel[]) => {
		this.updateWidgetModelState(
			TennisPlayoffModel.builder(this.state.widgetModel)
				.withSeason(season)
				.withStage({} as TennisSeasonStagesModel)
				.withStages(stages)
				.build(),
		);
	};

	onStageSelect = (stage: TennisSeasonStagesModel) => {
		this.updateWidgetModelState(TennisPlayoffModel.builder(this.state.widgetModel).withStage(stage).build());
	};

	onRefreshTimeChange = (refreshTimeData: SelectMenuOptionType) => {
		onRefreshTimeChangeFromMainComponent(refreshTimeData, TennisPlayoffModel, this.state.widgetModel, this.updateWidgetModelState);
	};

	render() {
		const { t, contentLanguage } = this.props;
		const { widgetModel, isValid } = this.state;

		return (
			<div data-qa='tennis-playoff-edit-component'>
				<Row className='mb-2'>
					<Col>
						<CompetitionSelectComponent
							onCompetitionSelect={this.onCompetitionSelect}
							selectedCompetition={
								widgetModel.competition && widgetModel.competition.id && widgetModel.competition.id.length > 0
									? widgetModel.competition
									: ({} as CompetitionModel)
							}
							isValid={isValid}
							language={contentLanguage}
							isRequired
							isClearable
							isBlocky
							sport={SportTypes.TENNIS}
							id={`${SportTypes.TENNIS}-${SPORT_SELECT_ATTRIBUTES.COMPETITION_SELECT}`}
						/>
					</Col>
				</Row>
				<Row className='mb-2'>
					<Col>
						<TennisCompetitionSeasonSelectComponent
							onSeasonAndStagesSelect={this.onSeasonAndStagesSelect}
							selectedSeason={
								widgetModel.season && widgetModel.season.value && widgetModel.season.value.length > 0
									? widgetModel.season
									: ({} as TennisCompetitionSeasonModel)
							}
							isValid={isValid}
							blockData={widgetModel}
							language={contentLanguage}
							isClearable
							isBlocky
							sport={SportTypes.TENNIS}
							id={`${SportTypes.TENNIS}-${SPORT_SELECT_ATTRIBUTES.SEASON_SELECT}`}
						/>
					</Col>
				</Row>
				<Row className='mb-2'>
					<Col>
						<TennisStageSelectComponent
							onStageSelect={this.onStageSelect}
							t={t}
							selectedStage={
								widgetModel.stage && widgetModel.stage.id && widgetModel.stage.id.length > 0 ? widgetModel.stage : ({} as TennisSeasonStagesModel)
							}
							stages={widgetModel.stages}
							isValid={isValid}
							blockData={widgetModel}
							language={contentLanguage}
							isClearable
							isBlocky
							sport={SportTypes.TENNIS}
							id={`${SportTypes.TENNIS}-${SPORT_SELECT_ATTRIBUTES.STAGE_SELECT}`}
						/>
					</Col>
				</Row>
				{(!isValid && widgetModel.competition && widgetModel.competition.id === '') ||
					(widgetModel.competition === null && (
						<div className='mb-3'>
							<ErrorHandler t={t} errorMessage='no_competition_selected' />
						</div>
					))}
				<RefreshTimeWrapper value={this.state.widgetModel.refreshTime} onRefreshTimeChange={this.onRefreshTimeChange} />
			</div>
		);
	}
}
