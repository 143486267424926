import LanguageCodeBuilder from './language-code-builder';
import { LanguageCodeJson } from './language-code-json';

export default class LanguageCodeModel {
	readonly languageCode: string = '';

	private constructor(languageCode: string) {
		this.languageCode = languageCode;
	}

	toJSON(): LanguageCodeJson {
		return {
			languageCode: this.languageCode,
		};
	}

	static fromJSON(json: LanguageCodeJson) {
		return new LanguageCodeModel(json.languageCode);
	}

	static builder(languageCode?: LanguageCodeModel): LanguageCodeBuilder {
		return new LanguageCodeBuilder(languageCode);
	}
}
