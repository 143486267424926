import React from 'react';
import { Col, Row } from 'reactstrap';
import FootballTeamFormWidgetModel from '../models/football-team-form.model';
import { generateRefreshTimeOptionViewText } from '../../../partials/refresh-time-input/helper';

type Properties = {
	preview: FootballTeamFormWidgetModel;
	t: any;
};

export const FootballTeamFormView: React.FunctionComponent<Properties> = ({ preview, t }) => {
	if (preview && preview.team && preview.team.id) {
		return (
			<>
				<Row className='px-4' data-qa='football-team-form-block-view'>
					<Col className='text-left'>
						<Row className='mb-2'>
							<Col>
								{preview.team && preview.team.id && (
									<div className='single-row'>
										<strong>{t('team')}:</strong> {preview.team.name}
										<img src={preview.team.urlLogo ? preview.team.urlLogo : ''} height='35' className='mx-1' alt={`${preview.team.name}`} />
									</div>
								)}
								{preview.tournament && preview.tournament.id && (
									<div className='single-row'>
										<strong>{t('tournament')}:</strong> {preview.tournament.name}
									</div>
								)}
								{preview.season && preview.season.id && (
									<div className='single-row'>
										<strong>{t('season')}:</strong> {preview.season.name}
									</div>
								)}
							</Col>
						</Row>
						<Row className='mb-2'>
							<Col>
								{preview.limit && (
									<div className='single-row'>
										<strong>{t('limit')}:</strong> {preview.limit}
									</div>
								)}
								{preview.title && preview.title.length > 0 && (
									<div className='single-row'>
										<strong>{t('title')}:</strong> {preview.title}
									</div>
								)}
							</Col>
						</Row>
					</Col>
				</Row>
				{generateRefreshTimeOptionViewText(preview.refreshTime)}
			</>
		);
	}

	return null;
};
