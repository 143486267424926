import React from 'react';
import { Badge, Col, Row } from 'reactstrap';
import moment from 'moment';
import FansUnitedWidgetModel from '../../models/fans-united-widget.model';
import { useTranslation } from 'react-i18next';

type Properties = {
	widgetClassicQuizManager: FansUnitedWidgetModel;
};

export const WidgetClassicQuizManagerView: React.FunctionComponent<Properties> = ({ widgetClassicQuizManager }) => {
	const [t] = useTranslation();

	if (widgetClassicQuizManager && widgetClassicQuizManager.classicQuiz && widgetClassicQuizManager.classicQuiz.id) {
		return (
			<div data-qa='widget-classic-quiz-view'>
				<Row className='d-flex p-2  align-items-center mb-1'>
					<Col className='ml-2'>
						<strong>
							{moment(widgetClassicQuizManager.classicQuiz.created_at ? widgetClassicQuizManager.classicQuiz.created_at : '').format(
								'MMM Do YYYY, HH:mm',
							)}
						</strong>
					</Col>
					<Col className='mr-1'>
						<strong>{widgetClassicQuizManager.classicQuiz.title}</strong>
					</Col>
				</Row>
				<Row className='px-4'>
					<Col>
						<hr />
					</Col>
				</Row>
				<Row className='px-4 pt-1'>
					{widgetClassicQuizManager.classicQuiz && widgetClassicQuizManager.classicQuiz.status && (
						<Col className='text-left'>
							<span>{t('status')}: </span>
							<Badge
								color={`${widgetClassicQuizManager.classicQuiz.status === 'ACTIVE' ? 'success' : 'secondary'} `}
							>{`${widgetClassicQuizManager.classicQuiz.status}`}</Badge>
						</Col>
					)}
				</Row>
				<Row className='px-4 pt-1'>
					{widgetClassicQuizManager.classicQuiz && widgetClassicQuizManager.classicQuiz.description && (
						<Col className='text-left'>
							<span>{t('description')}: </span>
							<strong>{t(widgetClassicQuizManager.classicQuiz.description)}</strong>
						</Col>
					)}
				</Row>
				<Row className='px-4 pt-1'>
					{widgetClassicQuizManager.classicQuiz && widgetClassicQuizManager.classicQuiz.questions_count && (
						<Col className='text-left'>
							<span>{t('questions_count')}: </span>
							<strong>{widgetClassicQuizManager.classicQuiz.questions_count}</strong>
						</Col>
					)}
				</Row>
			</div>
		);
	}

	return null;
};
