import 'reflect-metadata';
import { jsonMember, jsonObject } from 'typedjson';
import AssetsModel from '../../../../models/v2/sports-types/assets.model';
import Country from '../../../../models/v2/Country/Country.model';

@jsonObject()
export class MultisportCompetitionModel {
	@jsonMember(String)
	public id: string = '';
	@jsonMember(String)
	public name: string = '';
	@jsonMember(String)
	public slug: string = '';
	@jsonMember(String)
	public entity_type: string = '';
	@jsonMember(String)
	public sport: string = '';
	@jsonMember(AssetsModel)
	public display_asset: AssetsModel = new AssetsModel();
	@jsonMember(String)
	public country_id: string = '';
	@jsonMember(Country)
	public country: Country = new Country();
	@jsonMember(String)
	public gender: string = '';
}
