import React from 'react';
import { ViewTypes } from '../../../../constants/general.constants';
import OddsWidgetEdit from './odds-widget-edit.component';
import { OddsWidgetView } from './odds-widget-view.component';
import BlockModel from '../../../../models/block.model';
import OddsWidgetModel from '../models/odds-widget.model';
import BlockUpdateController from '../../../block-update-controller.component';
import { WidgetPreview } from '../../widget-preview/widget-preview.component';

type Properties = {
	block: BlockModel;
	view: ViewTypes;
	t: any;
	lang: string;
	contentData: any;
};

const OddsWidget: React.FunctionComponent<Properties> = (props) => {
	if (props.view === ViewTypes.edit) {
		return (
			<OddsWidgetEdit
				useTeamSelect
				useMatchSelect
				isStandAlone
				block={props.block}
				t={props.t}
				autoSelectFirstMatch={true}
				displayOddsLabel={false}
				displayHeader
				contentData={props.contentData}
			/>
		);
	}

	if (props.view === ViewTypes.normal) {
		return (
			<OddsWidgetView
				isStandAlone
				blockPreview={props.block.data.preview ? props.block.data.preview.odds : OddsWidgetModel.builder().build()}
				t={props.t}
				displayHeader
			/>
		);
	}

	if (props.view === ViewTypes.preview) {
		return <WidgetPreview blockContent={props.block.data.content} config={props.block.data.config} />;
	}

	return null;
};

export default BlockUpdateController(OddsWidget);
