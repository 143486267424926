import { FootballSingleEventWidgetJson } from './football-single-event-widget.json';
import FootballSingleEventWidgetBuilder from './football-single-event-widget.builder';
import MatchModel from '../../../../../../../models/v2/match/entity/match.model';
import TeamModel from '../../../../../../../models/v2/Team/Entity/TeamModel';
import { RefreshTimeValuesType } from '../../../partials/refresh-time-input/helper';

export default class FootballSingleEventWidgetModel {
	readonly match: MatchModel;
	readonly teams: TeamModel[];
	readonly displayOdds: boolean = false;
	readonly fansUnitedEnabled: boolean = false;
	readonly fansUnitedExpanded: boolean = false;
	readonly refreshTime: RefreshTimeValuesType = null;

	private constructor(
		match: MatchModel,
		teams: TeamModel[],
		displayOdds: boolean,
		fansUnitedEnabled: boolean,
		fansUnitedExpanded: boolean,
		refreshTime: RefreshTimeValuesType,
	) {
		this.match = match;
		this.teams = teams;
		this.displayOdds = displayOdds;
		this.fansUnitedEnabled = fansUnitedEnabled;
		this.fansUnitedExpanded = fansUnitedExpanded;
		this.refreshTime = refreshTime;
	}

	toJson(): FootballSingleEventWidgetJson {
		return {
			match: this.match,
			teams: this.teams,
			displayOdds: this.displayOdds,
			fansUnitedEnabled: this.fansUnitedEnabled,
			fansUnitedExpanded: this.fansUnitedExpanded,
			refreshTime: this.refreshTime,
		};
	}

	static fromJson(json: FootballSingleEventWidgetJson): FootballSingleEventWidgetModel {
		return new FootballSingleEventWidgetModel(
			json.match,
			json.teams,
			json.displayOdds,
			json.fansUnitedEnabled,
			json.fansUnitedExpanded,
			json.refreshTime,
		);
	}

	static builder(footballSingleEventWidget?: FootballSingleEventWidgetModel): FootballSingleEventWidgetBuilder {
		return new FootballSingleEventWidgetBuilder(footballSingleEventWidget);
	}
}
