import LiveBlogCollaboratorsModel from '../../../models/live-blog-collaborators.model';
import AuthorModel from '../../../../../../models/v2/author/author.model';

export const findEmptyCollaboratorsArrIndex = (model: LiveBlogCollaboratorsModel[]) => {
	if (model) {
		const newRowIndex = model.findIndex((modelData) => modelData.author.name === '' && modelData.author.id === '');
		return newRowIndex;
	}
};

export const emptyCollaboratorRowAvailable = (collaborators: LiveBlogCollaboratorsModel[]) => {
	const newRowIndex = findEmptyCollaboratorsArrIndex(collaborators);
	return newRowIndex && newRowIndex >= 0;
};

export const removeCollaboratorById = (collaborators: LiveBlogCollaboratorsModel[], searchedCollaboratorId: string) => {
	return collaborators.filter((collaborator) => collaborator.author.id !== searchedCollaboratorId);
};

export const findIsCollaboratorAlreadySelected = (
	collaborators: LiveBlogCollaboratorsModel[],
	currentlySelectedEl: LiveBlogCollaboratorsModel,
) => {
	return (
		collaborators.findIndex(
			(collaborator: LiveBlogCollaboratorsModel) =>
				collaborator && collaborator.author && collaborator.author.id == currentlySelectedEl.author.id,
		) >= 0
	);
};

export const filterAvailableCollaboratorsAlreadySelected = (
	availableCollaborators: AuthorModel[],
	selectedCollaborators: LiveBlogCollaboratorsModel[],
) => {
	if (selectedCollaborators && selectedCollaborators.length > 0) {
		return availableCollaborators.filter((author: AuthorModel) => {
			return selectedCollaborators.some((collab) => {
				return author.id !== collab.author.id;
			});
		});
	} else {
		return availableCollaborators;
	}
};
