import BlockModel from '../../../../../models/block.model';
import { ViewTypes } from '../../../../../constants/general.constants';
import React from 'react';
import BlockUpdateController from '../../../../block-update-controller.component';
import { compose } from 'redux';
import { WidgetTopXManagerView } from '../top-x/widget-topx-manager-view.component';
import WidgetMatchQuizEditComponent from './widget-match-quiz-edit.component';

type Properties = {
	block: BlockModel;
	view: ViewTypes;
	t: any;
};

const WidgetMatchQuiz: React.FunctionComponent<Properties> = (props) => {
	if (props.view === ViewTypes.edit) {
		return <WidgetMatchQuizEditComponent block={props.block} t={props.t} />;
	}

	if (props.view === ViewTypes.normal) {
		return <WidgetTopXManagerView widgetTopXManager={props.block.data.widget} t={props.t} />;
	}

	return null;
};

export default compose(BlockUpdateController)(WidgetMatchQuiz);
