import React, { useEffect } from 'react';
import Select from 'react-select';
import { Col, Label, Row } from 'reactstrap';
import { DATA_QA_ATTRIBUTES } from '../../../../../Partials/Sidebar/general-content/constants/data-qa.attributes';
import LiveBlogModel from '../../../models/live-blog.model';
import { liveBlogStatusOptions, statusesToOption, statusesToOptions } from './status.helper';
import '../../../style/live-blog.style.scss';
type Properties = {
	t: any;
	liveBlog: LiveBlogModel;
	setLiveBlogStatus: (status: string) => void;
};

export const LiveBlogStatusSelect: React.FunctionComponent<Properties> = ({ t, liveBlog, setLiveBlogStatus }) => {
	const defaultValue = !liveBlog.status || (liveBlog.status && liveBlog.status.length < 1) ? liveBlogStatusOptions[0] : liveBlog.status;

	useEffect(() => {
		if (!liveBlog.status) {
			setLiveBlogStatus(defaultValue);
		}
	}, [liveBlog]);

	const onStatusSelect = (status: string) => {
		setLiveBlogStatus(status);
	};

	return (
		<Row className='mb-3 status'>
			<Col>
				<Label htmlFor={DATA_QA_ATTRIBUTES.LIVE_BLOG_STATUS}>
					<strong>{t('status')}</strong>
				</Label>
				<Select
					id={DATA_QA_ATTRIBUTES.LIVE_BLOG_STATUS}
					options={statusesToOptions(liveBlogStatusOptions, t)}
					value={statusesToOption(defaultValue, t)}
					placeholder={t('select')}
					noOptionsMessage={(inputValue) => inputValue && t('no_options')}
					onChange={(selection: any) => onStatusSelect(selection && selection.value)}
				/>
			</Col>
		</Row>
	);
};
