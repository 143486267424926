import React from 'react';
import { blockManagementService, blockWrapperService } from '../../../../../subcomponents/blocky.component';
import BlockValidation from '../../../../../helpers/block-validation.helper';
import { Col, FormGroup, Row } from 'reactstrap';
import FansUnitedWidgetModel from '../../models/fans-united-widget.model';
import ErrorHandler from '../../../../../partials/error/error-handler-component';
import BlockModel from '../../../../../models/block.model';
import { FansUnitedContentContainer } from '../../../../../partials/fans-united/fu-managers-content-container';
import { FansUnitedWidgetsTypes } from '../../../../../constants/block.types';
import FansUnitedLeaderboardTemplatesModel from '../../models/fans-united-leaderboard-templates.model';

type Properties = {
	block: BlockModel;
	t: any;
};

type State = {
	widgetModel: FansUnitedWidgetModel;
	isValid: boolean;
};

export default class WidgetLeaderboardEditComponent extends React.Component<Properties, State> {
	constructor(props: Properties) {
		super(props);
		this.state = {
			widgetModel:
				props.block && props.block.data.widget && props.block.data.widget.template && props.block.data.widget.template.id
					? FansUnitedWidgetModel.builder(props.block.data.widget).build()
					: FansUnitedWidgetModel.builder().build(),
			isValid: true,
		};
	}

	componentDidMount(): void {
		blockWrapperService.registerOnSaveFunc({ blockId: this.props.block.id, func: this.onSave });
	}

	componentWillUnmount(): void {
		blockWrapperService.unregisterOnSaveFunc(this.props.block.id);
	}

	onSave = (index: number) => {
		const data = { widget: this.state.widgetModel };
		const validation = new BlockValidation();
		const isValid = validation.validate(this.props.block, data);
		// @ts-ignore
		this.setState({ ...this.state, isValid });
		if (isValid) {
			const block = Object.assign({}, this.props.block);
			block.data.widget = {
				template: this.state.widgetModel.template,
			};
			blockManagementService.blockUpdate(block, index);
		} else {
			return isValid;
		}
	};

	onTemplateChange = (template: FansUnitedLeaderboardTemplatesModel) => {
		const widgetModel = FansUnitedWidgetModel.builder(this.state.widgetModel).withTemplate(template).build();
		this.setState({ ...this.state, isValid: true, widgetModel });
	};

	render() {
		const { widgetModel, isValid } = this.state;
		const { t } = this.props;

		return (
			<>
				<div data-qa='widget-leaderboard-edit'>
					<Row>
						<Col>
							<FormGroup>
								<FansUnitedContentContainer
									selectedId={widgetModel && widgetModel.template && widgetModel.template.id ? widgetModel.template.id : ''}
									onSelect={this.onTemplateChange}
									maxItems={20}
									type={''}
									componentType={FansUnitedWidgetsTypes.leaderboard}
								/>
							</FormGroup>
						</Col>
					</Row>
					{(!isValid && widgetModel.template && !widgetModel.template.id) ||
						(!isValid && !widgetModel.template && (
							<div className='mb-3'>
								<ErrorHandler t={t} errorMessage='no_template_selected' />
							</div>
						))}
				</div>
			</>
		);
	}
}
