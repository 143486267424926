import ModelMapper from '../../models/ModelMapper';
import { toast } from 'react-toastify';
import i18n from '../../i18n';
import {
	ALL_CATEGORY_LISTING_RECEIVED,
	CATEGORY_ENTITY_CREATE,
	CATEGORY_ENTITY_CREATE_FAILED,
	CATEGORY_ENTITY_DELETE_FAILED,
	CATEGORY_ENTITY_CREATE_SUCCESS,
	CATEGORY_ENTITY_DELETE_SUCCESS,
	CATEGORY_ENTITY_RECEIVED,
	CATEGORY_ENTITY_UPDATE,
	CATEGORY_ENTITY_UPDATE_FAILED,
	CATEGORY_ENTITY_UPDATE_SUCCESS,
	CATEGORY_LISTING_RECEIVED,
} from '../action-creators/CategoriesActionCreators';
import Category from '../../models/category/Category';
import { actionService } from '../../App';

export function categoryMiddleware() {
	return function (next: any) {
		return function (action: any) {
			if (action) {
				/**
				 * Тhe purpose of this function is to check if creating/updating a category fails because of unsuccessful slug overwriting in the 'SEO' tab.
				 * For example a user creates a category and manually changes the slug to 'test', then creates a new category with the name 'test' without touching the slug.
				 * The slug will automatically take the category's name and when the user tries to save the category, he won't be able to see why it is failing, because the
				 * 'SEO' tab in the sidebar is collapsed by default. If the issue is not caused by the slug, the user will receive a generic failure message.
				 */
				const notifyWhenCategoryCreateOrUpdateFails = (customMessage: string) => {
					const SEO_ERROR_TYPE = 'seo';

					if (action.payload && action.payload.errors[0] && action.payload.errors[0].type === SEO_ERROR_TYPE) {
						return toast.error(i18n.t(action.payload.errors[0].error));
					}

					toast.error(i18n.t(customMessage));
				};

				if (action.type === CATEGORY_ENTITY_DELETE_SUCCESS) {
					toast.success(i18n.t('category_deleted_success'));
					window.dispatchEvent(new Event(action.type));
				}

				if (action.type === ALL_CATEGORY_LISTING_RECEIVED) {
					action.payload = ModelMapper.remapCategoriesFromResponse(action.payload);
				}

				if (action.type === CATEGORY_LISTING_RECEIVED) {
					action.payload.categories = ModelMapper.remapCategoriesFromResponse(action.payload.data.data);
					action.payload.contentPagination = ModelMapper.remapMetaPagination(action.payload.data.meta.pagination);
				}

				if (action.type === CATEGORY_ENTITY_RECEIVED) {
					action.payload.category = ModelMapper.remapCategoryFromResponse(action.payload.data.data);
				}

				if (action.type === CATEGORY_ENTITY_CREATE) {
					actionService.emitGeneralAction(CATEGORY_ENTITY_CREATE);
					action.payload.category = Category.builder(action.payload.category).toRequestJson();
				}

				if (action.type === CATEGORY_ENTITY_CREATE_SUCCESS) {
					actionService.emitGeneralAction(CATEGORY_ENTITY_CREATE_SUCCESS);
					// TODO replace window.dispatchEvent with actionService
					window.dispatchEvent(new CustomEvent(CATEGORY_ENTITY_CREATE_SUCCESS, { detail: action.payload }));
					toast.success(i18n.t('category_create_success'));
				}

				if (action.type === CATEGORY_ENTITY_CREATE_FAILED) {
					actionService.emitGeneralAction(CATEGORY_ENTITY_CREATE_FAILED);
					notifyWhenCategoryCreateOrUpdateFails('category_create_failed');
				}

				if (action.type === CATEGORY_ENTITY_UPDATE) {
					actionService.emitGeneralAction(CATEGORY_ENTITY_UPDATE);
					action.payload.category = Category.builder(action.payload.category).toRequestJson();
				}

				if (action.type === CATEGORY_ENTITY_UPDATE_SUCCESS) {
					actionService.emitGeneralAction(CATEGORY_ENTITY_UPDATE_SUCCESS);
					toast.success(i18n.t('category_update_success'));
				}

				if (action.type === CATEGORY_ENTITY_UPDATE_FAILED) {
					actionService.emitGeneralAction(CATEGORY_ENTITY_UPDATE_FAILED);
					notifyWhenCategoryCreateOrUpdateFails('category_update_failed');
				}

				if (action.type === CATEGORY_ENTITY_DELETE_FAILED) {
					actionService.emitGeneralAction(CATEGORY_ENTITY_DELETE_FAILED);
					toast.error(`${i18n.t(action.payload)} ${i18n.t('category_delete_failed')}`);
				}
			}

			next(action);
		};
	};
}
