import Project from '../../models/project/Project';
import ErrorResponseModel from '../../models/error-response/ErrorResponseModel';
import Poll from '../../models/polls/poll';
import PollOption from '../../models/polls/option/poll-option';
import { OptionToDelete } from '../../views/Resources/polls/properties/poll-edit.properties';

export const POLL_LISTING_REQUEST = '[action] POLL_LISTING_REQUEST';
export const POLL_LISTING_RECEIVED = '[action] POLL_LISTING_RECEIVED';
export const POLL_LISTING_FAILED = '[action] POLL_LISTING_FAILED';

export const POLL_ENTITY_REQUEST = '[action] POLL_ENTITY_REQUEST';
export const POLL_ENTITY_RECEIVED = '[action] POLL_ENTITY_RECEIVED';
export const POLL_ENTITY_FAILED = '[action] POLL_ENTITY_FAILED';

export const POLL_ENTITY_CREATE = '[action] POLL_ENTITY_CREATE';
export const POLL_ENTITY_CREATE_SUCCESS = '[action] POLL_ENTITY_CREATE_SUCCESS';
export const POLL_ENTITY_CREATE_FAILED = '[action] POLL_ENTITY_CREATE_FAILED';

export const POLL_ENTITY_UPDATE = '[action] POLL_ENTITY_UPDATE';
export const POLL_ENTITY_UPDATE_SUCCESS = '[action] POLL_ENTITY_UPDATE_SUCCESS';
export const POLL_ENTITY_UPDATE_FAILED = '[action] POLL_ENTITY_UPDATE_FAILED';

export const POLL_ENTITY_DELETE = '[action] POLL_ENTITY_DELETE';
export const POLL_ENTITY_DELETE_SUCCESS = '[action] POLL_ENTITY_DELETE_SUCCESS';
export const POLL_ENTITY_DELETE_FAILED = '[action] POLL_ENTITY_DELETE_FAILED';

export const POLL_ENTITY_OPTION_DELETE = '[action] POLL_ENTITY_OPTION_DELETE';
export const POLL_ENTITY_OPTION_DELETE_SUCCESS = '[action] POLL_ENTITY_OPTION_DELETE_SUCCESS';
export const POLL_ENTITY_OPTION_DELETE_FAILED = '[action] POLL_ENTITY_OPTION_DELETE_FAILED';

export const POLL_ENTITY_OPTION_UPDATE = '[action] POLL_ENTITY_OPTION_UPDATE';
export const POLL_ENTITY_OPTION_UPDATE_SUCCESS = '[action] POLL_ENTITY_OPTION_UPDATE_SUCCESS';
export const POLL_ENTITY_OPTION_UPDATE_FAILED = '[action] POLL_ENTITY_OPTION_UPDATE_FAILED';

export const POLL_ENTITY_OPTION_CREATE = '[action] POLL_ENTITY_OPTION_CREATE';
export const POLL_ENTITY_OPTION_CREATE_SUCCESS = '[action] POLL_ENTITY_OPTION_CREATE_SUCCESS';
export const POLL_ENTITY_OPTION_CREATE_FAILED = '[action] POLL_ENTITY_OPTION_CREATE_FAILED';

export const returnObjectForPollsListingRequest = (page: string, project: Project) => ({
	type: POLL_LISTING_REQUEST,
	payload: { page, project },
});

export const returnObjectForPollsListingReceived = (polls: any) => ({
	type: POLL_LISTING_RECEIVED,
	payload: polls,
});

export const returnObjectForPollListingFailed = (error: any) => ({
	type: POLL_LISTING_FAILED,
	payload: ErrorResponseModel.builder().fromResponse(error).build(),
});

export const returnObjectForPollEntityCreate = (poll: Poll, project: Project) => ({
	type: POLL_ENTITY_CREATE,
	payload: { poll, project },
});

export const returnObjectForPollEntityCreateSuccess = (id: string) => ({
	type: POLL_ENTITY_CREATE_SUCCESS,
	payload: id,
});

export const returnObjectForPollEntityCreateFailed = () => ({
	type: POLL_ENTITY_CREATE_FAILED,
});

export const returnObjectForPollEntityRequest = (id: string, project: Project) => ({
	type: POLL_ENTITY_REQUEST,
	payload: { id, project },
});

export const returnObjectForPollEntityReceived = (poll: Poll) => ({
	type: POLL_ENTITY_RECEIVED,
	payload: poll,
});

export const returnObjectForPollEntityUpdate = (poll: Poll, project: Project) => ({
	type: POLL_ENTITY_UPDATE,
	payload: { poll, project },
});

export const returnObjectForPollEntityUpdateSuccess = () => ({
	type: POLL_ENTITY_UPDATE_SUCCESS,
});

export const returnObjectForPollEntityUpdateFailed = () => ({
	type: POLL_ENTITY_UPDATE_FAILED,
});

export const returnObjectForPollEntityDelete = (id: string, project: Project) => ({
	type: POLL_ENTITY_DELETE,
	payload: { id, project },
});

export const returnObjectForPollEntityDeleteSuccess = () => ({
	type: POLL_ENTITY_DELETE_SUCCESS,
});

export const returnObjectForPollEntityDeleteFailed = () => ({
	type: POLL_ENTITY_DELETE_FAILED,
});

export const returnObjectForPollEntityOptionDelete = (poll: Poll, optionToDelete: OptionToDelete, project: Project) => ({
	type: POLL_ENTITY_OPTION_DELETE,
	payload: { poll, optionToDelete, project },
});

export const returnObjectForPollEntityOptionDeleteSuccess = () => ({
	type: POLL_ENTITY_OPTION_DELETE_SUCCESS,
});

export const returnObjectForPollEntityOptionDeleteFailed = () => ({
	type: POLL_ENTITY_OPTION_DELETE_FAILED,
});

export const returnObjectForPollEntityOptionUpdate = (poll: Poll, option: PollOption, project: Project) => ({
	type: POLL_ENTITY_OPTION_UPDATE,
	payload: { poll, option, project },
});

export const returnObjectForPollEntityOptionUpdateSuccess = () => ({
	type: POLL_ENTITY_OPTION_UPDATE_SUCCESS,
});

export const returnObjectForPollEntityOptionUpdateFailed = () => ({
	type: POLL_ENTITY_OPTION_UPDATE_FAILED,
});

export const returnObjectForPollEntityOptionCreate = (poll: Poll, text: string, index: number, project: Project) => ({
	type: POLL_ENTITY_OPTION_CREATE,
	payload: { poll, text, index, project },
});

export const returnObjectForPollEntityOptionCreateSuccess = (pollId: string, project: Project) => ({
	type: POLL_ENTITY_OPTION_CREATE_SUCCESS,
	payload: { id: pollId, project },
});

export const returnObjectForPollEntityOptionCreateFailed = () => ({
	type: POLL_ENTITY_OPTION_CREATE_FAILED,
});
