export function tournamentSeasonNotSelected(model: any) {
	let disabled =
		model === undefined ||
		model.tournament === null ||
		model.tournament === undefined ||
		model.tournament.season === null ||
		model.tournament.season === undefined ||
		model.tournament.season.id === null ||
		model.tournament.season.id === undefined ||
		model.tournament.season.id.length < 1;

	return disabled;
}
