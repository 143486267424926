import 'reflect-metadata';
import { jsonMember, jsonObject } from 'typedjson';
import CompetitionModel from '../../competition/entity/competition.model';

@jsonObject()
export default class BasketballSeasonModel {
	@jsonMember(String)
	public id: string = '';
	@jsonMember(String)
	public name: string = '';
	@jsonMember(CompetitionModel)
	public competition: CompetitionModel = new CompetitionModel();
	@jsonMember(String)
	public gender: string = '';
	@jsonMember(String)
	public region: string = '';
	@jsonMember(String, { name: 'entity_type' })
	public entityType: string = '';
	@jsonMember(String)
	public status: string = '';
}
