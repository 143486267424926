import React, { FunctionComponent } from 'react';
import { FormGroup, Label } from 'reactstrap';
import Select from 'react-select';
import { SelectMenuOptionType } from '../../../../../../models/v2/general/select.model';
import { RefreshTimeValuesType, getSelectedRefreshTimeOption, refreshTimeOptions } from './helper';
import { useTranslation } from 'react-i18next';

interface Properties {
	value: RefreshTimeValuesType;
	onRefreshTimeChange: (e: SelectMenuOptionType) => void;
}

const RefreshTimeInput: FunctionComponent<Properties> = ({ value, onRefreshTimeChange }) => {
	const { t } = useTranslation();

	return (
		<FormGroup data-qa='refresh-time-select'>
			<Label>{t('refresh_time')}:</Label>
			<Select
				isClearable
				options={refreshTimeOptions}
				value={getSelectedRefreshTimeOption(value)}
				placeholder={t('refresh_time_placeholder')}
				onChange={onRefreshTimeChange}
			/>
		</FormGroup>
	);
};

export default RefreshTimeInput;
