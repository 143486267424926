import Gallery from '../../models/gallery/Gallery';
import {
	GALLERY_DELETE_FAILED,
	GALLERY_DELETE_SUCCESS,
	GALLERIES_RECEIVED,
	GALLERIES_CONTENT_STATISTICS_RECEIVED,
	GALLERY_PAGINATION_UPDATE,
	GALLERY_FILTERS_UPDATE,
} from '../action-creators/GalleryActionCreator';
import { USER_LOGOUT } from '../action-creators/ProfileActionCreators';
import PaginationMeta from '../../models/pagination/PaginationMeta';
import AdvancedFilterModel from '../../views/Partials/Shared/advanced-content-filter/models/advanced-content-filter.model';

interface InitialState {
	galleries: Gallery[];
	pagination: PaginationMeta;
	galleryDeleted: boolean;
	filters: AdvancedFilterModel;
}

const initialState: InitialState = {
	galleries: [],
	galleryDeleted: false,
	pagination: PaginationMeta.builder().build(),
	filters: AdvancedFilterModel.builder().build(),
};

function galleryReducer(state: any = initialState, action: any) {
	if (action.type === GALLERIES_RECEIVED) {
		return Object.assign({}, state, {
			galleries: action.payload.galleries,
			pagination: action.payload.contentPagination,
		});
	}

	if (action.type === GALLERY_PAGINATION_UPDATE) {
		return Object.assign({}, state, {
			pagination: action.payload.pagination,
		});
	}

	if (action.type === GALLERY_DELETE_SUCCESS) {
		return Object.assign({}, state, {
			galleryDeleted: true,
		});
	}

	if (action.type === GALLERY_DELETE_FAILED) {
		return Object.assign({}, state, {
			galleryDeleted: false,
		});
	}

	if (action.type === GALLERIES_CONTENT_STATISTICS_RECEIVED) {
		return Object.assign({}, state, {
			galleries: action.payload.galleries,
		});
	}

	if (action.type === GALLERY_FILTERS_UPDATE) {
		return Object.assign({}, state, {
			filters: action.payload.filters,
		});
	}

	if (action.type === USER_LOGOUT) {
		state = initialState;
	}

	return state;
}

export default galleryReducer;
