import React from 'react';
import { Col, Input, Row } from 'reactstrap';
import moment from 'moment';
import { capitalize } from '../../../../global-helpers/global.helpers';
import { constructContentItemModel } from '../ListsContent/subcomponents/helpers/content-item-container.helper';
import ListItem from '../../../../models/list/list-item/ListItem';

type Properties = {
	value: ListItem;
	t: any;
	itemIndex: number;
	deleteItem: (event: any, value: ListItem) => any;
	isLocked: boolean;
};

const DeleteListItem: React.FunctionComponent<Properties> = (props) => {
	const { value, t, itemIndex, deleteItem, isLocked } = props;
	const item = constructContentItemModel(value);
	return (
		<li className='list-group-item mb-1 p-1'>
			<Row>
				<Col className='d-flex justify-content-between align-items-center'>
					<div className='list-content d-flex justify-content-between align-items-center w-80'>
						{isLocked ? (
							<>
								<i style={{ left: '50%' }} className='position-absolute p-1 fa-4x opacity-02 fa fa-lock text-muted' />
								<i style={{ fontSize: '19px' }} className='opacity-02 fa fa-lock text-muted' />
							</>
						) : (
							<Input
								key={`delete-list-item-input`}
								className='mt-0 ml-0 position-relative'
								type='checkbox'
								id={`delete-list-item-id`}
								name='radios'
								onChange={(event: any) => deleteItem(event.target.checked, value)}
							/>
						)}
						<span className='px-1 font-weight-bold'>{itemIndex + 1}.</span>
						<div className='d-inline-flex w-100'>
							<div className={'d-flex align-items-center overflow-hidden'}>
								{item.type !== 'TAG' && (
									<div className={'image-wrapper mr-3'}>
										<img alt={item.title} src={item.image} className={'max-width-50'} />
									</div>
								)}
								<div className={'content-wrapper overflow-hidden'}>
									<h6 className='mb-0 text-truncate'>
										<i className={`fa ${item.icon}`} title={capitalize(item.type)}></i> {item.title}
									</h6>
									{item.type !== 'RELATEDCONTENT' && item.type !== 'TAG' && (
										<small>{`${t('date_published')}: ${moment(item.publishedAt).format('DD MMMM YYYY, HH:mm')}`}</small>
									)}
									{item.type === 'RELATEDCONTENT' && <small>{`${t('type')}: ${item.entityType}`}</small>}
									{item.type === 'TAG' && <small>{`${t('created_by')}: ${item.createdBy}`}</small>}
								</div>
							</div>
						</div>
					</div>
				</Col>
			</Row>
		</li>
	);
};

export default DeleteListItem;
