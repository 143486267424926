import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import React, { useState } from 'react';
import { LiveBlogImageSelectContainer } from '../live-blog-image-select.component';
import LiveBlogModel from '../../../../models/live-blog.model';
import ImageModel from '../../../../../../../models/v2/image/image.model';
import { DATA_QA_ATTRIBUTES } from '../../../../../../Partials/Sidebar/general-content/constants/data-qa.attributes';
import MainMediaModel from '../../../../../../../models/v2/main-media/main-media.model';
import { extractMainImageObjectFromArray } from '../../../../../../Partials/Sidebar/media-refactored/helpers/general';

type Properties = {
	t: any;
	isOpen: boolean;
	toggleModal: (isOpen: boolean) => any;
	liveBlog: LiveBlogModel;
	onChange: (image: ImageModel) => void;
};

export const MainMediaContentModal: React.FunctionComponent<Properties> = ({ t, toggleModal, isOpen, onChange, liveBlog }) => {
	const media: MainMediaModel = extractMainImageObjectFromArray(liveBlog.main_media) || new MainMediaModel();
	const [selectedMainMediaItem, setSelectedMainMediaItem] = useState(media.resourceId ? media.data : '');
	const [mediaId, setMediaId] = useState<string>('');

	const onContentSelect = (item: ImageModel) => {
		if (item && item.id) {
			setMediaId(item.id);
			setSelectedMainMediaItem(item);
		}
	};

	return (
		<div data-qa={DATA_QA_ATTRIBUTES.LIVE_BLOG_MAIN_IMAGE_LISTING_MODAL}>
			<Modal isOpen={isOpen}>
				<ModalHeader
					toggle={() => {
						toggleModal(false);
					}}
				/>
				<ModalBody>
					<div data-children='.item'>
						<div className='item'>
							<LiveBlogImageSelectContainer selectedImageId={selectedMainMediaItem} t={t} onChange={onContentSelect} />
						</div>
					</div>
				</ModalBody>
				<ModalFooter>
					<Button
						id={DATA_QA_ATTRIBUTES.LIVE_BLOG_MAIN_IMAGE_LISTING_MODAL_CLOSE}
						color='danger'
						className='text-light'
						onClick={() => {
							toggleModal(false);
						}}
					>
						{t('close')}
					</Button>
					<Button
						disabled={!mediaId}
						id={DATA_QA_ATTRIBUTES.LIVE_BLOG_MAIN_IMAGE_LISTING_MODAL_SAVE}
						color='primary'
						className='text-light'
						onClick={() => {
							onChange(selectedMainMediaItem);
						}}
					>
						{t('save')}
					</Button>
				</ModalFooter>
			</Modal>
		</div>
	);
};
