import { call, put, takeEvery } from 'redux-saga/effects';
import HttpService from '../../services/rest/HttpService';
import { toggleLoadingState, toggleModal, searchIsApplied } from '../action-creators/UiActionCreator';
import {
	BANNER_ENTITY_CREATE,
	BANNER_SEARCH,
	BANNER_ENTITY_REQUEST,
	BANNER_ENTITY_DELETE,
	BANNER_ENTITY_UPDATE,
	BANNER_LISTING_REQUEST,
	returnObjectForBannerListingReceived,
	returnObjectForBannerEntityDeleteSuccess,
	returnObjectForBannerEntityCreateSuccess,
	returnObjectForBannerEntityUpdateSuccess,
	returnObjectForBannerEntityReceived,
	returnObjectForBannerEntityCreateFailed,
	returnObjectForBannerEntityUpdateFailed,
} from '../action-creators/BannerActionCreators';
import { onError } from '../action-creators/GeneralActions';
import { actionService } from '../../App';

function* fetchBannerListingFromApi(action: any) {
	yield put(toggleLoadingState(true));

	try {
		let headers = { Project: action.payload.project.domain };
		const constructURL = action.payload.text && action.payload.text.length > 0 ? `/search?query=${action.payload.text}&` : '?';
		const banners = yield call(HttpService.get, `/banners${constructURL}page=${action.payload.page}`, null, headers);
		yield put(returnObjectForBannerListingReceived(banners));
		yield put(toggleLoadingState(false));
	} catch (error) {
		yield put(onError(error));
	}
}

function* searchBannerFromApi(action: any) {
	yield put(toggleLoadingState(true));

	try {
		let headers = { Project: action.payload.project.domain };
		const banners = yield call(HttpService.get, `/banners/search?query=${action.payload.text}`, null, headers);
		yield put(returnObjectForBannerListingReceived(banners));
		yield put(toggleLoadingState(false));
		yield put(searchIsApplied());
	} catch (error) {
		yield put(onError(error));
	}
}

function* fetchBannerEntityFromApi(action: any) {
	try {
		let headers = { Project: action.payload.project.domain };
		const banner = yield call(HttpService.get, `/banners/${action.payload.id}`, null, headers);
		yield put(returnObjectForBannerEntityReceived(banner));
	} catch (error) {
		yield put(onError(error));
	}
}

function* postBannerEntityToApi(action: any) {
	let headers = { Project: action.payload.project.domain };
	let bannerResponse: any = {};

	try {
		let bannerPayload = action.payload.banner;
		bannerResponse = yield call(HttpService.post, '/banners', bannerPayload, headers);
		yield put(returnObjectForBannerEntityCreateSuccess(bannerResponse.data.data.id));
	} catch (error) {
		yield put(returnObjectForBannerEntityCreateFailed());
		yield put(onError(error));
		actionService.emitError(error);
	}
}

function* patchBannerEntityToApi(action: any) {
	let headers = { Project: action.payload.project.domain };

	try {
		let bannerPayload = action.payload.banner;
		let id = action.payload.banner.id;
		yield call(HttpService.patch, `/banners/${id}`, bannerPayload, headers);
		yield put(returnObjectForBannerEntityUpdateSuccess());
	} catch (error) {
		yield put(returnObjectForBannerEntityUpdateFailed());
		yield put(onError(error));
		actionService.emitError(error);
	}
}

function* deleteBannerEntityFromApi(action: any) {
	try {
		let headers = { Project: action.payload.project.domain };
		let id = action.payload.id;
		yield call(HttpService.delete, `/banners/${id}`, null, headers);
		yield put(returnObjectForBannerEntityDeleteSuccess());
		yield put(toggleModal(false));
	} catch (error) {
		yield put(onError(error));
	}
}

function* bannerSaga() {
	yield takeEvery(BANNER_LISTING_REQUEST, fetchBannerListingFromApi);
	yield takeEvery(BANNER_SEARCH, searchBannerFromApi);
	yield takeEvery(BANNER_ENTITY_REQUEST, fetchBannerEntityFromApi);
	yield takeEvery(BANNER_ENTITY_CREATE, postBannerEntityToApi);
	yield takeEvery(BANNER_ENTITY_UPDATE, patchBannerEntityToApi);
	yield takeEvery(BANNER_ENTITY_DELETE, deleteBannerEntityFromApi);
}

export default bannerSaga;
