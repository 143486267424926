import React from 'react';
import { Col, Row } from 'reactstrap';
import KnockoutWidgetV2Model from '../models/knockout-widget-v2.model';
import { generateRefreshTimeOptionViewText } from '../../../partials/refresh-time-input/helper';

type Properties = {
	preview: KnockoutWidgetV2Model;
	t: any;
};

export const FootballKnockoutView: React.FunctionComponent<Properties> = ({ preview, t }) => {
	if (preview && preview.tournament && preview.tournament.id) {
		return (
			<>
				<Row className='px-4' data-qa='knockout-view-component'>
					<Col className='text-left'>
						<Row className='mb-2'>
							<Col>
								<div>
									<strong>{t('tournament')}:</strong>
									<img className='ml-1 mr-1' width='35px' src={preview.tournament.urlLogo} alt={preview.tournament.name} />
									{preview.tournament.name} {preview.season && preview.season.name && `- ${preview.season.name}`}
									{`${preview.stage && preview.stage.name ? `(${preview.stage.name})` : ''}`}
								</div>
							</Col>
						</Row>
					</Col>
				</Row>
				{generateRefreshTimeOptionViewText(preview.refreshTime)}
			</>
		);
	}

	return null;
};
