import React, { useState } from 'react';
import { noPreviewImage } from '../../../../../../constants/resource-constants';

type Properties = {
	t: any;
	video: any;
};

export const AmgVideoView: React.FunctionComponent<Properties> = (props) => {
	const { video } = props;
	const [isLoading, setLoading] = useState(true);
	const src =
		video && video.selectedVideo && video.selectedVideo.mediaData && video.selectedVideo.mediaData.thumbnailUrl
			? video.selectedVideo.mediaData.thumbnailUrl
			: noPreviewImage;
	if (video && video.selectedVideo && video.selectedVideo.metaData && video.selectedVideo.metaData.title) {
		return (
			<div className={`${isLoading ? 'loading-overlay' : ''}`} data-qa='amg-video-block'>
				<div className='block-content'>
					<div className={`d-flex content-container`}>
						{video.selectedVideo.metaData.title ? (
							<img
								onLoad={() => setLoading(false)}
								className='content-image img-fluid mb-2 mb-md-0 rounded'
								alt={video.selectedVideo.metaData.title}
								src={src}
							/>
						) : (
							<img alt='' src={noPreviewImage} className='content-image img-fluid mb-2 mb-md-0 rounded' />
						)}
						<div className='gallery-title text-left'>
							<h6 className='m-0 p-0'>{video.selectedVideo.metaData.title}</h6>
						</div>
					</div>
				</div>
			</div>
		);
	}

	return (
		<div className='position-relative block-content'>
			<img alt='' src={noPreviewImage} className='no-preview-image img-fluid mb-2 mb-md-0 rounded' />
		</div>
	);
};
