import * as React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';

type IsDefaultProps = {
	t: any;
	onChange: (isDefault: boolean) => any;
	isChecked: boolean;
	value?: boolean;
};

export const IsDefault: React.FunctionComponent<IsDefaultProps> = ({ onChange, t, isChecked }) => {
	return (
		<FormGroup key={`is-default`} check className='radio'>
			<Input
				key={`is-default-input`}
				className='form-check-input'
				type='checkbox'
				id={`is-default-id`}
				name='radios'
				onChange={(event: any) => onChange(event.target.checked)}
				checked={isChecked}
			/>
			<Label key={`is-default-label`} check className='form-check-label' for={`is-default-id`}>
				{t('is_default')}
			</Label>
		</FormGroup>
	);
};
