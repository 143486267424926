import moment from 'moment';
import { EventStatuses, EventStatusesTypes, MatchStatuses, MatchStatusesTypes } from '../../../../../constants/event.types';
import { relatedConstants } from '../../../../../constants/related.constants';
import { SportTypes } from '../../../../../constants/sport.types';
import Related from '../../../../../models/related/Related';
import EventsMeta from '../../../../../models/v2/events-meta/events-meta.model';
import ResponseMatchModel from '../../../../../models/v2/match/entity/response-match.model';
import SeasonResponseModel from '../../../../../models/v2/Season/Entity/response-season.model';
import EventModel from '../../../../../models/v2/sports-types/eventModel';
import TournamentResponseModel from '../../../../../models/v2/Tournament/Entity/response-tournament.model';
import {
	updateArticleEditMatchTempRelated,
	updateArticleMatchTempRelated,
	updateGalleryEditMatchTempRelated,
	updateGalleryMatchTempRelated,
	updateVideoEditMatchTempRelated,
	updateVideoMatchTempRelated,
	updateWikiEditMatchTempRelated,
	updateWikiMatchTempRelated,
} from '../../../../../store/action-creators/RelatedMatchTempActionCreator';
import { ReduxRelatedProperties } from '../constants/redux-related-properties';
import {
	extractEventProviderBySportType,
	extractRelatedPropertiesNameByUrl,
	filterReduxRelatedProperties,
	FilterReduxRelatedPropertiesType,
} from './utils';
import SportsTypesModel from '../../../../../models/v2/sports-types/sports-types.model';

export const applyInProgressClass = (type: string | null) => {
	return type === MatchStatuses.IN_PROGRESS ? 'border-pulsate-right' : '';
};

export const printMinutesIfMatchInProgress = (match: ResponseMatchModel) => {
	return match.type === MatchStatuses.IN_PROGRESS ? `(${match.minutes})` : '';
};

export const toggleGoals = (match: ResponseMatchModel) => {
	return match.type === MatchStatuses.IN_PROGRESS || match.type === MatchStatuses.FINISHED
		? ` ${match.goal_home}:${match.goal_away} `
		: ` - `;
};

export const toggleScore = (event: EventModel) => {
	return event.status === EventStatuses.LIVE || event.status === EventStatuses.FINISHED ? ` ${event.homeScore}:${event.awayScore} ` : ` - `;
};

export const checkAreFiltersSelected = (
	selectedTournament: TournamentResponseModel | null,
	selectedSeason: SeasonResponseModel | null,
	dateFrom: string,
	dateTo: string,
): boolean => {
	return !!selectedTournament || !!selectedSeason || dateFrom !== '' || dateTo !== '';
};

export const extractStatusesTypesBasedOnActiveTab = (selectedSport: SportsTypesModel, activeTab: EventStatusesTypes) => {
	const enumToCheck = selectedSport.sport === SportTypes.FOOTBALL ? MatchStatuses : EventStatuses;

	switch (activeTab) {
		case EventStatuses.UPCOMING:
			return [enumToCheck.UPCOMING, selectedSport.sport === SportTypes.FOOTBALL ? MatchStatuses.IN_PROGRESS : EventStatuses.LIVE];
		case EventStatuses.FINISHED:
			return [enumToCheck.FINISHED];
		case EventStatuses.INTERRUPTED:
			return [enumToCheck.INTERRUPTED];
		default:
			return [];
	}
};

// This method is used to extract saved participants ids for specific sport
export const extractParticipantIdsFromRedux = (selectedSport: SportsTypesModel, reduxRelatedData: Related[] | null): string[] => {
	const filterByEntityTypes =
		selectedSport.sport === SportTypes.TENNIS ? [relatedConstants.types.player, relatedConstants.types.team] : [relatedConstants.types.team];
	const filteredData = filterReduxRelatedProperties(selectedSport, reduxRelatedData, filterByEntityTypes);
	const participantAsRelatedModels = filteredData && filteredData.filtered && filteredData.filtered.length > 0 ? filteredData.filtered : [];
	const participantsIds: string[] = participantAsRelatedModels.map((x: Related) => x.data.id);
	return participantsIds;
};

const formatDateFilter = (date: string): string => {
	try {
		return moment(date).format('YYYY-MM-DD') + 'T00:00:00-00:00';
	} catch (error) {
		return '';
	}
};

// this method will generate url for fetching football matches
export const extractDataForFetchingFootballMatches = (
	statusTypes: MatchStatusesTypes[],
	tournamentFilter: TournamentResponseModel | null,
	seasonFilter: SeasonResponseModel | null,
	dateFromFilter: string,
	dateToFilter: string,
	participantsIds: string[],
	limit: number = 10,
) => {
	const hardcodedParams = statusTypes.includes(MatchStatuses.FINISHED) ? `&limit=${limit}` : `&sort_direction=asc&limit=${limit}`;
	const statusTypesToString = statusTypes.join(',');
	const tournamentId = (tournamentFilter && tournamentFilter.id) || '';
	const seasonId = (seasonFilter && seasonFilter.id) || '';
	const dateFromFormatted = dateFromFilter ? formatDateFilter(dateFromFilter) : dateFromFilter;
	const dateToFormatted = dateToFilter ? formatDateFilter(dateToFilter) : dateToFilter;

	// prettier-ignore
	let generatedUrl = `/matches?team_ids=${participantsIds.join(',')}&status_types=${statusTypesToString}&tournament_ids=${tournamentId}&season_ids=${seasonId}&from_start_time=${dateFromFormatted}&to_start_time=${dateToFormatted}${hardcodedParams}`;
	return generatedUrl;
};

// this method will generate url for fetching events
export const extractDataForFetchingEvents = (
	selectedSport: SportsTypesModel,
	statusTypes: EventStatusesTypes[],
	dateFromFilter: string,
	dateToFilter: string,
	participantsIds: string[],
	meta: EventsMeta,
	language: string,
) => {
	let coreParams = statusTypes.includes(EventStatuses.FINISHED) ? '' : '&sort_direction=ASC';
	if (!meta || meta.limit === null || meta.total === null) {
		coreParams += '&limit=10';
	} else if (meta.limit < meta.total) {
		coreParams += `&limit=${meta.limit + 10}`;
	} else {
		coreParams += `&limit=${meta.limit}`;
	}
	const statusTypesToString = statusTypes.join(',');
	const dateFromFormatted = dateFromFilter ? formatDateFilter(dateFromFilter) : dateFromFilter;
	const dateToFormatted = dateToFilter ? formatDateFilter(dateToFilter) : dateToFilter;
	const translationLanguage = language && language.length > 0 ? `&translation_language=${language}` : '';
	// prettier-ignore
	return `/events?participants_filter=${participantsIds.join(',')}&status_type=${statusTypesToString}&from_start_time=${dateFromFormatted}&to_start_time=${dateToFormatted}&sport=${selectedSport.sport}${coreParams}${translationLanguage}`;
};

// The 2 methods below extracts the action that should be dispatched to update the selected events
// Method 1
const extractCorrectDispatchMethodByPropertyName = (propertyName: string | null) => {
	switch (propertyName) {
		case ReduxRelatedProperties.articleEditRelated:
			return (relatedData: Related[]) => updateArticleEditMatchTempRelated(relatedData);
		case ReduxRelatedProperties.articleRelated:
			return (relatedData: Related[]) => updateArticleMatchTempRelated(relatedData);
		case ReduxRelatedProperties.videoEditRelated:
			return (relatedData: Related[]) => updateVideoEditMatchTempRelated(relatedData);
		case ReduxRelatedProperties.videoRelated:
			return (relatedData: Related[]) => updateVideoMatchTempRelated(relatedData);
		case ReduxRelatedProperties.galleryEditRelated:
			return (relatedData: Related[]) => updateGalleryEditMatchTempRelated(relatedData);
		case ReduxRelatedProperties.galleryRelated:
			return (relatedData: Related[]) => updateGalleryMatchTempRelated(relatedData);
		case ReduxRelatedProperties.wikiEditRelated:
			return (relatedData: Related[]) => updateWikiEditMatchTempRelated(relatedData);
		case ReduxRelatedProperties.wikiRelated:
			return (relatedData: Related[]) => updateWikiMatchTempRelated(relatedData);
		default:
			return null;
	}
};

// Method 2
export const extractActionForUpdatingSelectedEvents = () => {
	const nestedReduxPropertyName = extractRelatedPropertiesNameByUrl();
	const functionForDispatch = extractCorrectDispatchMethodByPropertyName(nestedReduxPropertyName);
	return functionForDispatch;
};

export const remapEventAsRelatedModel = (event: EventModel | null): Related | null => {
	if (event) {
		return {
			provider: extractEventProviderBySportType(event.sport as SportTypes),
			type: event.entity_type || relatedConstants.types.match,
			data: event,
		} as Related;
	}

	return null;
};

// TODO remove when FOOTBALL is transferred to sport-search-api
export const remapMatchAsRelatedModel = (match: ResponseMatchModel | null): Related | null => {
	if (match) {
		return {
			provider: extractEventProviderBySportType(SportTypes.FOOTBALL),
			type: relatedConstants.types.match,
			data: match,
		} as Related;
	}

	return null;
};

export const extractAlreadyAddedEvents = (
	selectedSport: SportsTypesModel,
	reduxRelatedDataObject: any, // object with Related[] values
): FilterReduxRelatedPropertiesType => {
	const relatedPropertiesNestedProp = extractRelatedPropertiesNameByUrl();
	if (reduxRelatedDataObject && relatedPropertiesNestedProp && reduxRelatedDataObject[relatedPropertiesNestedProp]) {
		const reduxRelatedDataForEvents: Related[] = reduxRelatedDataObject[relatedPropertiesNestedProp];
		return filterAddedEvents(selectedSport, reduxRelatedDataForEvents);
	}
	return null;
};

export const filterAddedEvents = (selectedSport: SportsTypesModel, reduxRelatedData: Related[]) => {
	const entityTypesFilter =
		selectedSport.sport === SportTypes.FOOTBALL
			? [relatedConstants.types.match]
			: [relatedConstants.types.match, relatedConstants.types.game];
	const alreadyAddedEventsInRedux = filterReduxRelatedProperties(selectedSport, reduxRelatedData, entityTypesFilter);
	return alreadyAddedEventsInRedux;
};
