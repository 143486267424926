import { PlayerCareerWidgetJson } from './player-career-widget.json';
import PlayerCareerWidgetBuilder from './player-career-widget.builder';
import PlayerModel from '../../../../partials/player-select/models/player.model';

export default class PlayerCareerWidgetModel {
	readonly player: PlayerModel;
	readonly championshipFilters: any[];
	readonly statisticColumns: any[];
	readonly tableColumns: any[];
	readonly threeLetterCodeType: any = '';

	private constructor(
		player: PlayerModel,
		championshipFilters: any[],
		statisticColumns: any[],
		tableColumns: any[],
		threeLetterCodeType: any,
	) {
		this.player = player;
		this.championshipFilters = championshipFilters;
		this.statisticColumns = statisticColumns;
		this.tableColumns = tableColumns;
		this.threeLetterCodeType = threeLetterCodeType;
	}

	toJson(): PlayerCareerWidgetJson {
		return {
			player: this.player,
			championshipFilters: this.championshipFilters,
			statisticColumns: this.statisticColumns,
			tableColumns: this.tableColumns,
			threeLetterCodeType: this.threeLetterCodeType,
		};
	}

	static fromJson(json: PlayerCareerWidgetJson): PlayerCareerWidgetModel {
		return new PlayerCareerWidgetModel(
			json.player,
			json.championshipFilters,
			json.statisticColumns,
			json.tableColumns,
			json.threeLetterCodeType,
		);
	}

	static builder(playerCareer?: PlayerCareerWidgetModel): PlayerCareerWidgetBuilder {
		return new PlayerCareerWidgetBuilder(playerCareer);
	}
}
