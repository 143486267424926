import { TypedJSON } from 'typedjson';
import LiveBlogTournamentSeasonResponseModel from './live-blog-tournament-season.model';
import { convertIdToString } from '../../helpers/utility.helper';

export const responseToLiveBlogSeasonModel = (response: any) => {
	const serializer = new TypedJSON(LiveBlogTournamentSeasonResponseModel);
	let remappedSeasonData = serializer.parse(response);

	if (remappedSeasonData) {
		remappedSeasonData.id = convertIdToString(remappedSeasonData.id);
	}

	return remappedSeasonData;
};
