import React from 'react';
import { Col, FormGroup, Row } from 'reactstrap';
import { blockManagementService, blockWrapperService } from '../../../../subcomponents/blocky.component';
import BlockValidation from '../../../../helpers/block-validation.helper';
import BlockModel from '../../../../models/block.model';
import TournamentModel from '../../../../../../../models/v2/Tournament/Entity/TournamentModel';
import StageModel from '../../../../../../../models/v2/Stage/Entity/StageModel';
import SeasonModel from '../../../../../../../models/v2/Season/Entity/SeasonModel';
import TournamentSelectComponent from '../../../partials/tournament-select/tournament-select.component';
import SeasonSelectComponent from '../../../partials/season-select/season-select.component';
import StageSelectComponent from '../../../partials/stage-select/stage-select.component';
import KnockoutWidgetV2Model from '../models/knockout-widget-v2.model';
import { SportTypes } from '../../../../../../../constants/sport.types';
import { SPORT_SELECT_ATTRIBUTES } from '../../helpers/component-attributes.constants';
import { createBlock } from '../../../partials/helpers/blocky-sidebar-communication/blocky-sidebar-communication.helper';
import { WidgetsV2Ids } from '../../../../constants/block.types';
import { SelectMenuOptionType } from '../../../../../../../models/v2/general/select.model';
import { onRefreshTimeChangeFromMainComponent } from '../../../partials/refresh-time-input/helper';
import RefreshTimeWrapper from '../../../partials/refresh-time-input/refresh-time-wrapper';
type Properties = {
	block: BlockModel;
	t: any;
	contentData: any;
	contentLanguage: string;
};

type State = {
	widgetModel: KnockoutWidgetV2Model;
	isValid: boolean;
};

export default class FootballKnockoutSchemeEditComponent extends React.Component<Properties, State> {
	constructor(props: Properties) {
		super(props);
		this.state = {
			widgetModel: createBlock(
				WidgetsV2Ids.widgetFootballKnockout,
				props.block.data.preview && props.block.data.preview.widgetModel,
				props.contentData,
			),
			isValid: true,
		};
	}

	componentDidMount(): void {
		blockWrapperService.registerOnSaveFunc({ blockId: this.props.block.id, func: this.onSave });
	}

	onSave = (index: number) => {
		const validation = new BlockValidation();
		const data = { widgetModel: this.state.widgetModel };
		const isValid = validation.validate(this.props.block, data) === true;

		this.setState({ ...this.state, isValid });

		const knockoutModel = KnockoutWidgetV2Model.builder(this.state.widgetModel).build();

		if (isValid) {
			const block = Object.assign({}, this.props.block);
			block.data.preview = { widgetModel: knockoutModel };
			blockManagementService.blockUpdate(block, index);
		} else {
			return isValid;
		}
	};

	componentWillUnmount(): void {
		blockWrapperService.unregisterOnSaveFunc(this.props.block.id);
	}

	updateWidgetModelState = (model: KnockoutWidgetV2Model) => {
		this.setState({ ...this.state, widgetModel: model });
	};

	onTournamentSelect = (tournament: TournamentModel) => {
		this.updateWidgetModelState(
			KnockoutWidgetV2Model.builder(this.state.widgetModel)
				.withTournament(tournament)
				.withSeason({} as SeasonModel)
				.withStage({} as StageModel)
				.build(),
		);
	};

	onSeasonSelect = (season: SeasonModel) => {
		this.updateWidgetModelState(
			KnockoutWidgetV2Model.builder(this.state.widgetModel)
				.withSeason(season)
				.withStage({} as StageModel)
				.build(),
		);
	};

	onStageSelect = (stage: any) => {
		this.updateWidgetModelState(KnockoutWidgetV2Model.builder(this.state.widgetModel).withStage(stage).build());
	};

	onRefreshTimeChange = (refreshTimeData: SelectMenuOptionType) => {
		onRefreshTimeChangeFromMainComponent(refreshTimeData, KnockoutWidgetV2Model, this.state.widgetModel, this.updateWidgetModelState);
	};

	render() {
		const { widgetModel, isValid } = this.state;

		return (
			<>
				<div data-qa='knockout-edit-component'>
					<Row>
						<Col>
							<FormGroup>
								<TournamentSelectComponent
									isMulti={false}
									isBlocky
									isValid={isValid}
									selectedTournament={widgetModel.tournament}
									onTournamentSelect={this.onTournamentSelect}
									t={this.props.t}
									id={`${SportTypes.FOOTBALL}-${SPORT_SELECT_ATTRIBUTES.TOURNAMENT_SELECT}`}
								/>
							</FormGroup>
						</Col>
					</Row>
					<Row>
						<Col>
							<FormGroup>
								<SeasonSelectComponent
									withLabel
									isBlocky
									isValid={isValid}
									selectedSeason={widgetModel.season}
									isRequired
									preselectSeason
									tournamentId={widgetModel.tournament && widgetModel.tournament.id ? widgetModel.tournament.id : ''}
									onSeasonSelect={this.onSeasonSelect}
									t={this.props.t}
									isClearable
									id={`${SportTypes.FOOTBALL}-${SPORT_SELECT_ATTRIBUTES.SEASON_SELECT}`}
								/>
							</FormGroup>
						</Col>
					</Row>
					<Row>
						<Col>
							<FormGroup>
								<StageSelectComponent
									withLabel
									isRequired={true}
									isValid={isValid}
									selectedStage={widgetModel.stage}
									seasonId={widgetModel.season && widgetModel.season.id && widgetModel.season.id}
									onStageSelect={this.onStageSelect}
									t={this.props.t}
									preselectStage
									isClearable
									id={`${SportTypes.FOOTBALL}-${SPORT_SELECT_ATTRIBUTES.STAGE_SELECT}`}
								/>
							</FormGroup>
						</Col>
					</Row>

					<RefreshTimeWrapper value={this.state.widgetModel.refreshTime} onRefreshTimeChange={this.onRefreshTimeChange} />
				</div>
			</>
		);
	}
}
