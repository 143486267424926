import * as React from 'react';
import { Properties, State } from './properties/BreadCrumbsCustomProps';
import { BreadCrumbsModel } from './models/BreadCrumbsModel';
import { Link } from 'react-router-dom';

export default class BreadCrumbsCustom extends React.Component<Properties, State> {
	renderRouteLinks(route: any) {
		if (route && route.breadcrumbs) {
			return route.breadcrumbs.map((item: BreadCrumbsModel, index: number) => {
				if (index < route.breadcrumbs.length - 1) {
					return (
						<li key={`link-breadcrumb-key-${index}`} className={'breadcrumb-item'}>
							<Link to={item.path}>{item.name}</Link>
						</li>
					);
				} else {
					return (
						<li key={`link-breadcrumb-key-${index}`} className={'breadcrumb-item active'}>
							{item.name}
						</li>
					);
				}
			});
		}

		return null;
	}

	extractCurrentRoute(routes: any, location: any) {
		if (routes && location) {
			return routes.filter((route: any) => {
				return (
					route.path === this.convertToEditPathIfNeeded(location.pathname) || route.path === location.pathname.replace(/\/[\w-]+$/, '/:id')
				);
			})[0];
		}

		return null;
	}

	convertToEditPathIfNeeded(path: string) {
		if (path.includes('custom-entities/edit')) {
			path = `${path.split('edit')[0]}edit/:entityType/:id`;
		} else if (path.includes('edit')) {
			path = `${path.split('edit')[0]}edit/:id`;
		}

		if (path.includes('quick_access')) {
			path = '/smp/quick_access/:id';
		}

		return path;
	}

	render() {
		const { routes, location } = this.props;
		const currentRoute = this.extractCurrentRoute(routes, location);

		return <ol className='breadcrumb'>{this.renderRouteLinks(currentRoute)}</ol>;
	}
}
