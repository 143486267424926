import PostBuilder from './post-builder';
import { PostJson } from './post-json';

export default class Post {
	readonly id: string;
	readonly type: string;
	readonly subtype: string;
	readonly embed: string;
	readonly url: string;
	readonly timestamp: string;
	readonly sourceLocation: string;
	readonly sourceName: string;
	readonly sourceThumbnail: string;

	private constructor(
		id: string,
		type: string,
		subtype: string,
		embed: string,
		url: string,
		timestamp: string,
		sourceLocation: string,
		sourceName: string,
		sourceThumbnail: string,
	) {
		this.id = id;
		this.type = type;
		this.subtype = subtype;
		this.embed = embed;
		this.url = url;
		this.timestamp = timestamp;
		this.sourceLocation = sourceLocation;
		this.sourceName = sourceName;
		this.sourceThumbnail = sourceThumbnail;
	}

	toJSON(): PostJson {
		return {
			origin_id: this.id,
			type: this.type,
			subtype: this.subtype,
			embed: this.embed,
			origin_location: this.url,
			published_at: this.timestamp,
			source_location: this.sourceLocation,
			sourceName: this.sourceName,
			source_thumbnail: this.sourceThumbnail,
		};
	}

	static fromJSON(json: PostJson): Post {
		return new Post(
			json.origin_id,
			json.type,
			json.subtype,
			json.embed,
			json.origin_location,
			json.published_at,
			json.source_location,
			json.sourceName,
			json.source_thumbnail,
		);
	}

	static builder(source?: Post): PostBuilder {
		return new PostBuilder(source);
	}
}
