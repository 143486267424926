import React from 'react';
import CustomCollapsibleElement from '../../../Partials/BaseComponents/CustomCollapsibleElement/CustomCollapsibleElement';
import { Col, Row } from 'reactstrap';

export default class HoToUseFeedManagement extends React.Component<{ t: any }, {}> {
	render() {
		const { t } = this.props;

		return (
			<CustomCollapsibleElement status={'close'} title={t('social_feed_monitor_usability')} iconClass={'fa fa-info-circle '}>
				<Row>
					<Col>
						<h5>{t('feeds_how_to_0')}</h5>
						<p>{t('feeds_how_to_1')}</p>
						<ul>
							<li>{t('feeds_how_to_2_li_1')}</li>
							<li>{t('feeds_how_to_2_li_2')}</li>
							<li>{t('feeds_how_to_2_li_3')}</li>
							<li>{t('feeds_how_to_2_li_4')}</li>
						</ul>
						<p>{t('feeds_how_to_3')}</p>
						<p>{t('feeds_how_to_4')}</p>
						<p>{t('feeds_how_to_5')}</p>
						<h5>{t('feeds_how_to_6')}</h5>
						<p>
							{t('feeds_how_to_7')}{' '}
							<a href='https://www.espn.co.uk/espn/rss/football/news' target='_blank'>
								https://www.espn.co.uk/espn/rss/football/news
							</a>
						</p>

						<h5>{t('feeds_how_to_8')}</h5>
						<p>{t('feeds_how_to_9')}</p>
						<h6 className='text-success pl-3'>{t('feeds_how_to_10')}</h6>
						<p className='pl-3'>
							<a href='https://www.youtube.com/channel/UC6yW44UGJJBvYTlfC7CRg2Q' target='_blank'>
								https://www.youtube.com/channel/UC6yW44UGJJBvYTlfC7CRg2Q
							</a>
						</p>
						<h6 className='text-danger pl-3'>{t('feeds_how_to_11')}</h6>
						<p className='pl-3'>
							<a href='https://www.youtube.com/user/SkySportsOfficial' target='_blank'>
								https://www.youtube.com/user/SkySportsOfficial
							</a>
						</p>
						<h5>{t('feeds_how_to_12')}</h5>
						<p>
							{t('feeds_how_to_13')}{' '}
							<a href='https://twitter.com/Cristiano' target='_blank'>
								https://twitter.com/Cristiano
							</a>
						</p>
						<h5>{t('feeds_how_to_14')}</h5>
						<p>
							{t('feeds_how_to_15')}{' '}
							<a href='https://www.instagram.com/cristiano' target='_blank'>
								https://www.instagram.com/cristiano
							</a>
						</p>
					</Col>
				</Row>
			</CustomCollapsibleElement>
		);
	}
}
