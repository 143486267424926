import axios, { AxiosInstance } from 'axios';
import { featuresService } from '../../../App';
import { getUniqueOnlyByType } from '../../../views/Partials/Blocky/services/helpers/suggested-entities.helper';
import { FeatureTypes } from '../../feature-service/features.enum';
import * as tournamentsJson from './instat_tournaments.json';
import { store } from '../../../store/store';

const INSTAT_BASE_URL = 'https://service.instatfootball.com';

export default class InstatHttpService {
	private instance: AxiosInstance = {} as AxiosInstance;
	private key = '';
	private id = '';
	private lang = '';

	constructor() {
		this.createInstatInstance();
		this.key = featuresService.getFeatureConfig(FeatureTypes.INSTAT_INTEGRATION).key;
		this.id = featuresService.getFeatureConfig(FeatureTypes.INSTAT_INTEGRATION).id;
		this.lang = store.getState().project.currentProject.language ? store.getState().project.currentProject.language.split('-')[0] : 'en';
	}

	private createInstatInstance = () => {
		this.instance = axios.create({
			baseURL: `${INSTAT_BASE_URL}`,
			timeout: 30000,
		});
	};

	public getAllTournaments = (query: string) => {
		return new Promise((resolve, reject) => {
			const tournaments = tournamentsJson.data.tournament.filter((tournament: any) =>
				tournament.name.toLowerCase().includes(query.toLowerCase()),
			);
			resolve(tournaments);
		});
	};

	public getTeamsByTournament = (tournamentId: string, seasonId: string) => {
		return this.instance
			.get(`feed.php?id=${this.id}&key=${this.key}&tpl=32&tournament_id=${tournamentId}&season_id=${seasonId}&lang=${this.lang}&format=json`)
			.then((resp) => resp.data.data.row);
	};

	public getSeasonsByTournament = (tournamentId: string) => {
		return this.instance
			.get(`feed.php?id=${this.id}&key=${this.key}&tpl=225&tournament_id=${tournamentId}&lang=${this.lang}&format=json`)
			.then((resp) => resp.data.data.season);
	};

	public getTeamAggStats = (tournamentId: string, seasonId: string, teamId: string) => {
		return this.instance
			.get(
				`feed.php?id=${this.id}&key=${this.key}&tpl=62&tournament_id=${tournamentId}&season_id=${seasonId}&team_id=${teamId}&lang=${this.lang}&format=json`,
			)
			.then((resp) => {
				return resp.data.data ? resp.data.data.row : [];
			});
	};

	public getPlayerAggStats = (tournamentId: string, seasonId: string, playerId: string) => {
		return this.instance
			.get(
				`feed.php?id=${this.id}&key=${this.key}&tpl=61&tournament_id=${tournamentId}&season_id=${seasonId}&player_id=${playerId}&lang=${this.lang}&format=json`,
			)
			.then((resp) => {
				return resp.data.data ? resp.data.data.row : [];
			});
	};

	public getTeamSquad = (teamId: string) => {
		return this.instance.get(`feed.php?id=${this.id}&key=${this.key}&tpl=5&team_id=${teamId}&lang=${this.lang}&format=json`).then((resp) => {
			return resp.data.data ? resp.data.data.row : [];
		});
	};

	public getMatchesBySeason = (tournamentId: string, seasonId: string) => {
		return this.instance
			.get(
				`feed.php?id=${this.id}&key=${this.key}&tpl=35&tournament_id=${tournamentId}&season_id=${seasonId}&lang=${this.lang}&format=json&analyzed=1`,
			)
			.then((resp) => {
				return resp.data.data ? resp.data.data.row : [];
			});
	};

	public getMatchGoals = (matchId: string) => {
		return this.instance
			.get(`feed.php?id=${this.id}&key=${this.key}&tpl=36&match_id=${matchId}&lang=${this.lang}&format=json`)
			.then((resp) => {
				return resp.data.data
					? resp.data.data.row.filter(
							(goalEvent: any) =>
								goalEvent !== undefined && goalEvent !== null && (goalEvent.action_id === '8010' || goalEvent.action_id === '8020'),
					  )
					: [];
			});
	};

	public getMatchGoalkeepers = (matchId: string) => {
		return this.instance
			.get(`feed.php?id=${this.id}&key=${this.key}&tpl=36&match_id=${matchId}&lang=${this.lang}&format=json`)
			.then((resp) => {
				return resp.data.data
					? this.remapGoalkeepersFromResponse(
							getUniqueOnlyByType(
								resp.data.data.row.filter(
									(goalkeeper: any) => goalkeeper !== undefined && goalkeeper !== null && goalkeeper.position_name === 'Goalkeeper',
								),
								'player_id',
							),
					  )
					: [];
			});
	};

	public getMatchTeamsStats = (matchId: string) => {
		return this.instance
			.get(`feed.php?id=${this.id}&key=${this.key}&tpl=42&match_id=${matchId}&lang=${this.lang}&format=json`)
			.then((resp) => {
				return resp.data.data ? resp.data.data.team : [];
			});
	};

	public getMatchPlayersStats = (matchId: string) => {
		return this.instance
			.get(`feed.php?id=${this.id}&key=${this.key}&tpl=40&match_id=${matchId}&lang=${this.lang}&format=json`)
			.then((resp) => {
				return resp.data.data ? resp.data.data.team : [];
			});
	};

	public remapGoalkeepersFromResponse = (goalkeepersStats: any) => {
		if (goalkeepersStats && goalkeepersStats.length > 0) {
			return goalkeepersStats.map((stats: any) => {
				const goalkeepersObj = {
					id: stats.player_id,
					name: stats.player_name,
					teamId: stats.team_id,
					logo: `https://instatscout.com/images/players/180/${stats.player_id}.png`,
				};
				return goalkeepersObj;
			});
		}
		return null;
	};
}
