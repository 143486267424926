import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import EnumItem from '../../../../../models/enum/EnumItem';
import HttpService from '../../../../../services/rest/HttpService';
import './edit-season-teams.modal.scss';
import { extractNotEmptyTranslations } from '../../helpers/translations.helpers';
import TeamModel from '../../../../Partials/Blocky/partials/team-select/models/team.model';
import { toast } from 'react-toastify';
import TranslateTeam from './translate-team.component';

type Properties = {
	t: any;
	open: boolean;
	onClose: (open: boolean) => any;
	searchLanguage: EnumItem;
	team: TeamModel;
	languageToTranslate: EnumItem;
};

const EditSeasonTeamsModal: React.FunctionComponent<Properties> = (props) => {
	const { open, t, onClose, searchLanguage, team, languageToTranslate } = props;

	const saveTranslation = (translations: any[], teamPlayersTranslation: any[]) => {
		HttpService.instanceFootball()
			.post('/v2/translations', JSON.parse(JSON.stringify(extractNotEmptyTranslations([...translations, ...teamPlayersTranslation]))))
			.then(() => {
				onClose(false);
				toast.success(t('translation_successfull'));
			})
			.catch(() => {
				toast.error(t('translation_failed'));
				onClose(false);
			});
	};

	return (
		<Modal modalClassName='edit-season-teams-modal' isOpen={open}>
			<ModalHeader toggle={() => onClose(false)}>{t('translate_team')}</ModalHeader>
			<ModalBody>
				<TranslateTeam
					t={t}
					team={team}
					teamId={team.id}
					searchLanguage={searchLanguage}
					languageToTranslate={languageToTranslate}
					saveTranslation={saveTranslation}
				/>
			</ModalBody>
		</Modal>
	);
};

export default EditSeasonTeamsModal;
