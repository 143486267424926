import 'reflect-metadata';
import { AnyT, jsonMember, jsonObject } from 'typedjson';
import MetaModel from '../Meta/MetaModel';

@jsonObject
export default class ListItemModel {
	@jsonMember(String)
	public type: string = '';

	@jsonMember(Number)
	public weight: number = 0;

	@jsonMember(MetaModel)
	public meta: MetaModel | null = null;

	@jsonMember(AnyT)
	public data: any = null;

	@jsonMember(Number)
	public lockedPosition: number | null = null;
}
