import { ValidationTypeJson } from '../models/embed-block-validation-type.json';

export const validationOptions = (t: any) => {
	return [
		{ value: 'text', label: t('plain_text'), default: true },
		{ value: 'json', label: t('json'), default: false },
		{ value: 'html', label: t('html'), default: false },
	];
};

export const checkJSONSyntax = (text: string) => {
	if (typeof text !== 'string') {
		return false;
	}
	try {
		var json = JSON.parse(text);
		return typeof json === 'object';
	} catch (error) {
		return false;
	}
};

export const checkHTMLSyntax = (html: string) => {
	let doc = document.createElement('div');
	doc.innerHTML = html;

	for (let c = doc.childNodes, i = c.length; i--; ) {
		if (c[i].nodeType == 1 && !html.includes('<!DOCTYPE html>')) {
			return true;
		}
	}

	return false;
};

export function checkComplexHTMLSyntax(html: string) {
	const parser = new DOMParser();
	const htmlForParser = `<xml>${html}</xml>`
		.replace(/(src)=".*?&.*?"/g, '$1="OMITTED"')
		.replace(/<script[\s\S]+?<\/script>/gm, '<script>OMITTED</script>')
		.replace(/<style[\s\S]+?<\/style>/gm, '<style>OMITTED</style>')
		.replace(/<pre[\s\S]+?<\/pre>/gm, '<pre>OMITTED</pre>')
		.replace(/&nbsp;/g, '&#160;')
		.replace(/&mdash;/g, '&#8212;');

	const doc = parser.parseFromString(htmlForParser, 'text/xml');
	if (doc.documentElement.querySelector('parsererror') && !html.includes('<!DOCTYPE html>')) {
		return doc.documentElement.querySelector('parsererror');
	}

	if (checkHTMLSyntax(html)) {
		return true;
	} else {
		return false;
	}
}

export const getDefaultType = (options: any, t: any) => {
	const defaultOption = options(t).find((el: ValidationTypeJson) => el.default === true);
	return defaultOption ? defaultOption : {};
};
