import { ContentTypes, SelectOptions } from '../../../../../../../constants/content-types';

const articleProperties: SelectOptions[] = [
	{ value: 'runAds', label: 'ads_enabled_prop', query: 'run_ads' },
	{ value: 'adultContent', label: 'adult_content_prop', query: 'adult_content' },
	{ value: 'important', label: 'important_prop', query: 'important' },
	{ value: 'live', label: 'live_prop', query: 'live' },
	{ value: 'sensitiveContent', label: 'sensitive_content', query: 'sensitive_content' },
	{ value: 'bettingContent', label: 'betting_content', query: 'betting_content' },
];

const galleryProperties: SelectOptions[] = [
	{ value: 'runAds', label: 'ads_enabled_prop', query: 'run_ads' },
	{ value: 'adultContent', label: 'adult_content_prop', query: 'adult_content' },
	{ value: 'sensitiveContent', label: 'sensitive_content', query: 'sensitive_content' },
	{ value: 'bettingContent', label: 'betting_content', query: 'betting_content' },
];

const videoProperties: SelectOptions[] = [
	{ value: 'runAds', label: 'ads_enabled_prop', query: 'run_ads' },
	{ value: 'adultContent', label: 'adult_content_prop', query: 'adult_content' },
	{ value: 'important', label: 'important_prop', query: 'important' },
	{ value: 'sensitiveContent', label: 'sensitive_content', query: 'sensitive_content' },
	{ value: 'bettingContent', label: 'betting_content', query: 'betting_content' },
];

const wikiProperties: SelectOptions[] = [];

export const propertiesByContentType = {
	[ContentTypes.ARTICLE]: articleProperties,
	[ContentTypes.GALLERY]: galleryProperties,
	[ContentTypes.VIDEO]: videoProperties,
	[ContentTypes.WIKI_PAGE]: wikiProperties,
};
