import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { RowModel } from './models/RowModel';
import { Badge, Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Nav } from 'reactstrap';
import { ArticlePreviewButton } from '../../../ArticlePreviewButton/ArticlePreviewButton';
import { ConditionalRenderContext } from '../../../ConditionalRenderWraper/ConditionalRenderWrapper';
import { RowEditButton } from './subcomponents/RowEditButton';
import { RowDeleteButton } from './subcomponents/RowDeleteButton';
import { RowTitleLink } from './subcomponents/RowTitleLink';
import { AppState, store } from '../../../../../../store/store';
import Project from '../../../../../../models/project/Project';
import PopoverItem from './PopoverItem';
import Profile from '../../../../../../models/profile/Profile';
import RowCopyButton from './subcomponents/RowCopyButton';
import { ContentTypes } from '../../../../Blocky/constants/block.types';
import './styles/rows.scss';
import { RowTeamsEditButton } from '../../../../../Resources/manual-data-entries/subcomponents/row-edit-button.component';
import BlacklistModel from '../../../../../../models/player-manual-data/blacklist/blacklist';
import { TreeItem } from 'react-sortable-tree';
import { CategoryTree } from '../../../../../Resources/Categories/category-tree.component';
import { ListsRowsContainer } from '../../../../../Resources/Lists/lists-rows-container.component';
import RowContentStatisticsComponent from './subcomponents/row-content-statistic/row-content-statistics.component';
import { AuthorsRowsContainer } from '../../../../../Resources/Authors/authors-rows-container.component';
import { TagsRowsContainer } from '../../../../../Resources/Tags/tags-rows-container.component';
import { Subscription } from 'rxjs';
import { actionService, multiLingualService } from '../../../../../../App';
import { AUTHOR_LISTING_REQUEST, AUTHOR_SEARCH } from '../../../../../../store/action-creators/AuthorActionCreators';
import { RowFootballEntityTitle } from './subcomponents/RowFootballEntityTitle';
import {
	returnObjectForTagListingUpdate,
	TAG_ENTITY_DELETE_SUCCESS,
	TAG_LISTING_REQUEST,
	TAG_SEARCH,
} from '../../../../../../store/action-creators/TagActionCreators';
import { DISPLAY_MODAL } from '../../../../Modals/CopyToProjects/CopyToProjectsModal';
import { useDispatch, useSelector } from 'react-redux';
import { RowEditTranslationButton } from './subcomponents/multi-lingual/RowEditTranslationButton';
import RowCreateTranslationButton from './subcomponents/multi-lingual/RowCreateTranslationButton';
import HttpService from '../../../../../../services/rest/HttpService';
import ModelMapper from '../../../../../../models/ModelMapper';
import MainMedia from '../../../../../../models/mainmedia/MainMedia';

type RowsProps = {
	content?: RowModel[];
	categoryTree?: TreeItem[];
	onDelete: (id: string, title: string) => any;
	analytics: any;
	history: any;
	t: any;
	contentToCopy?: any;
	searchQuery: string;
	itemDeleted: boolean;
};

function isProjectPreviewUrlAvailable(project: Project): boolean {
	return project.previewUrl !== undefined && project.previewUrl !== null && project.previewUrl.length > 0;
}

export const RowsArticle: React.FunctionComponent<RowsProps> = ({ content, contentToCopy, onDelete, analytics, history, t }) => {
	const context = useContext(ConditionalRenderContext);
	const currentProject: Project = store.getState().project.currentProject;
	const profile: Profile = store.getState().profile.profile;
	const contentLanguages = useSelector((state: AppState) => state.project.currentProject.languages);
	const [availableLanguages, setAvailableLanguages] = useState<any[]>([]);

	const [dropdownOpen, setDropdownOpen] = useState({ id: '', isOpen: false });

	useEffect(() => {
		let languages = multiLingualService.extractAvailableLanguages(contentLanguages);
		if (multiLingualService.checkIfProjectIsMultiLingual(contentLanguages)) {
			setAvailableLanguages(languages);
		}
	}, []);

	const toggle = (id: string) =>
		setDropdownOpen((prevState: any) => {
			return { id, isOpen: !prevState.isOpen };
		});

	if (content === undefined) {
		return null;
	}

	return (
		<React.Fragment>
			{content.map((content: RowModel, index: number) => {
				return (
					<tr key={`table-row ${index}`}>
						<td className='align-middle'>
							<RowTitleLink
								contentTitle={content.title}
								strapline={content.strapline}
								editContentPath={`/smp/articles/edit/${content.id}`}
								canEditContent={context.hasWritePermission}
								analytics={analytics}
								contentId={`article-id-${content.id}`}
							/>
						</td>
						{multiLingualService.checkIfProjectIsMultiLingual(contentLanguages) && multiLingualService.checkIfContentCanBeTranslated(content)
							? availableLanguages &&
							  availableLanguages.map((item: any) => {
									return (
										<td key={`translation-action-row-buttons ${index + Math.random()}`} className='d-none d-md-table-cell align-middle text-center'>
											{multiLingualService.checkIfContentIsTranslatedInGroup(content, item.languageCode) ? (
												<RowEditTranslationButton
													display={context.hasWritePermission}
													contentId={content.id}
													editContentPath={multiLingualService.getCorrectContentTranslation(content, item.languageCode, `${ContentTypes.article}s`)}
													t={t}
													analytics={analytics}
													history={history}
													contentType={ContentTypes.article}
												/>
											) : (
												<RowCreateTranslationButton
													contentType={ContentTypes.article}
													createContentPath='/smp/articles/create'
													history={history}
													content={contentToCopy[index]}
													display={context.hasWritePermission}
													t={t}
													analytics={analytics}
													languageCode={item.languageCode}
													translationGroup={content.translationGroup}
												/>
											)}
										</td>
									);
							  })
							: multiLingualService.checkIfProjectIsMultiLingual(contentLanguages) && (
									<td colSpan={availableLanguages && availableLanguages.length} className='d-none d-sm-table-cell align-middle text-center'>
										<div className='d-flex'>
											<div style={{ width: '100%' }} className='non-translatable bg-gray text-white text-center'>
												{t('cant_translate')}
											</div>
										</div>
									</td>
							  )}
						<td className='d-none d-sm-table-cell align-middle text-center'>
							{content.status === 'active' ? (
								<i className='fa-lg fa fa-check-circle text-success mx-2' />
							) : (
								<i className='fa-lg fa fa-times-circle text-danger mx-2' />
							)}
						</td>
						<PopoverItem t={t} content={content} lang={profile.language} />
						<td className='d-none d-sm-table-cell align-middle'>
							{moment(content.publishedAt).lang(profile.language.split('_')[0]).format('DD.MM.YYYY, HH:mm')}
						</td>
						<td className='d-none d-sm-table-cell align-middle'>{content.mainCategory}</td>
						<RowContentStatisticsComponent content={content} />
						<td className='d-sm-table-cell align-middle'>
							<Nav className='justify-content-end'>
								<Dropdown nav isOpen={dropdownOpen.id === content.id && dropdownOpen.isOpen} toggle={() => toggle(content.id)}>
									{context.hasWritePermission && (
										<DropdownToggle id={`toggle-menu-caret-${content.id}`} nav caret className='d-flex align-items-center p-0 text-secondary'>
											<RowEditButton
												display={context.hasWritePermission}
												contentId={content.id}
												editContentPath={`/smp/articles/edit/${content.id}`}
												t={t}
												analytics={analytics}
												history={history}
												contentType={ContentTypes.article}
											/>
										</DropdownToggle>
									)}
									<DropdownMenu className='p-0 listing-dropdown-menu'>
										{context.hasWritePermission && !multiLingualService.checkIfProjectIsMultiLingual(contentLanguages) && (
											<DropdownItem className='p-0 border-0'>
												<RowCopyButton
													contentType={ContentTypes.article}
													createContentPath='/smp/articles/create'
													history={history}
													content={contentToCopy[index]}
													display={context.hasWritePermission}
													t={t}
													analytics={analytics}
												/>
											</DropdownItem>
										)}
										<DropdownItem className='p-0 border-0'>
											<div
												className='m-1 d-flex flex-fill justify-content-center btn btn-primary btn-success'
												onClick={() =>
													actionService.emitUiAction({
														type: DISPLAY_MODAL,
														data: {
															contentTitle: content.title,
															contentId: content.id,
															contentType: ContentTypes.article,
														},
													})
												}
											>
												<i className={'fa fa-copy p-1 mr-1 text-white'} />
												{t('copy_to_project')}
											</div>
										</DropdownItem>
										{context.hasReadPermission && (
											<DropdownItem className='p-0 border-0'>
												<ArticlePreviewButton
													rowIndex={index}
													t={t}
													contentId={content.id}
													display={context.hasReadPermission && isProjectPreviewUrlAvailable(currentProject)}
												/>
											</DropdownItem>
										)}
										{context.hasDeletePermission && (
											<DropdownItem className='p-0 border-0'>
												<RowDeleteButton
													contentType={ContentTypes.article}
													dropdownItem
													t={t}
													analytics={analytics}
													display={context.hasDeletePermission}
													onDelete={onDelete}
													contentTitle={content.title}
													content={content}
													contentId={content.id}
												/>
											</DropdownItem>
										)}
									</DropdownMenu>
								</Dropdown>
							</Nav>
						</td>
					</tr>
				);
			})}
		</React.Fragment>
	);
};

export const RowsVideo: React.FunctionComponent<RowsProps> = ({ content, contentToCopy, onDelete, analytics, history, t }) => {
	const context = useContext(ConditionalRenderContext);
	const profile: Profile = store.getState().profile.profile;
	const contentLanguages = useSelector((state: AppState) => state.project.currentProject.languages);
	const [dropdownOpen, setDropdownOpen] = useState({ id: '', isOpen: false });
	const [availableLanguages, setAvailableLanguages] = useState<any[]>([]);
	useEffect(() => {
		let languages = multiLingualService.extractAvailableLanguages(contentLanguages);
		if (multiLingualService.checkIfProjectIsMultiLingual(contentLanguages)) {
			setAvailableLanguages(languages);
		}
	}, []);

	const toggle = (id: string) =>
		setDropdownOpen((prevState: any) => {
			return { id, isOpen: !prevState.isOpen };
		});

	if (content === undefined) {
		return null;
	}

	return (
		<React.Fragment>
			{content.map((content: RowModel, index: number) => {
				return (
					<tr key={`table-row ${index}`}>
						<td className='align-middle'>
							{content &&
								content.mainMedia &&
								content.mainMedia.map((media: MainMedia) => {
									return (
										media.resourceType === ContentTypes.embed && (
											<Badge className='mt-1 text-white mr-2 video-origin'>
												<i className='fa-lg fa fa-code mx-2' />
											</Badge>
										)
									);
								})}
							{content.origin && content.origin.name && (
								<Badge className='mt-1 text-white mr-2 video-origin'>
									<i className='fa fa-play p-1' />
									{content.origin.name}
								</Badge>
							)}
							<RowTitleLink
								contentTitle={content.title}
								strapline={content.strapline}
								editContentPath={`/smp/videos/edit/${content.id}`}
								canEditContent={context.hasWritePermission}
								analytics={analytics}
								contentId={`video-id-${content.id}`}
							/>
						</td>
						{multiLingualService.checkIfProjectIsMultiLingual(contentLanguages) && multiLingualService.checkIfContentCanBeTranslated(content)
							? availableLanguages &&
							  availableLanguages.map((item: any) => {
									return (
										<td key={`translation-action-row-buttons ${index + Math.random()}`} className='d-none d-md-table-cell align-middle text-center'>
											{multiLingualService.checkIfContentIsTranslatedInGroup(content, item.languageCode) ? (
												<RowEditTranslationButton
													display={context.hasWritePermission}
													contentId={content.id}
													editContentPath={multiLingualService.getCorrectContentTranslation(content, item.languageCode, `${ContentTypes.video}s`)}
													t={t}
													analytics={analytics}
													history={history}
													contentType={ContentTypes.video}
												/>
											) : (
												<RowCreateTranslationButton
													contentType={ContentTypes.video}
													createContentPath='/smp/videos/create'
													history={history}
													content={contentToCopy[index]}
													display={context.hasWritePermission}
													t={t}
													analytics={analytics}
													languageCode={item.languageCode}
													translationGroup={content.translationGroup}
												/>
											)}
										</td>
									);
							  })
							: multiLingualService.checkIfProjectIsMultiLingual(contentLanguages) && (
									<td colSpan={availableLanguages && availableLanguages.length} className='d-none d-sm-table-cell align-middle text-center'>
										<div className='d-flex'>
											<div style={{ width: '100%' }} className='non-translatable bg-gray text-white text-center'>
												{t('cant_translate')}
											</div>
										</div>
									</td>
							  )}
						<td className='d-none d-sm-table-cell align-middle text-center'>
							{content.status === 'active' ? (
								<i className='fa-lg fa fa-check-circle text-success mx-2' />
							) : (
								<i className='fa-lg fa fa-times-circle text-danger mx-2' />
							)}
						</td>
						<PopoverItem t={t} content={content} lang={profile.language} />
						<td className='d-none d-sm-table-cell align-middle'>
							{moment(content.publishedAt).lang(profile.language.split('_')[0]).format('DD.MM.YYYY, HH:mm')}
						</td>
						<td className='d-none d-sm-table-cell align-middle'>{content.mainCategory}</td>
						<RowContentStatisticsComponent content={content} />
						<td className='align-middle d-sm-table-cell'>
							<Nav className='justify-content-end'>
								<Dropdown nav isOpen={dropdownOpen.id === content.id && dropdownOpen.isOpen} toggle={() => toggle(content.id)}>
									{context.hasWritePermission && (
										<DropdownToggle id={`toggle-menu-caret-${content.id}`} nav caret className='d-flex align-items-center p-0 text-secondary'>
											<RowEditButton
												contentId={content.id}
												display={context.hasWritePermission}
												editContentPath={`/smp/videos/edit/${content.id}`}
												t={t}
												analytics={analytics}
												history={history}
											/>
										</DropdownToggle>
									)}
									<DropdownMenu className='p-0 listing-dropdown-menu'>
										{context.hasWritePermission && !multiLingualService.checkIfProjectIsMultiLingual(contentLanguages) && (
											<DropdownItem className='p-0 border-0'>
												<RowCopyButton
													contentType={ContentTypes.video}
													createContentPath='/smp/videos/create'
													content={contentToCopy[index]}
													history={history}
													display={context.hasWritePermission}
													t={t}
													analytics={analytics}
												/>
											</DropdownItem>
										)}
										{context.hasDeletePermission && (
											<DropdownItem className='p-0 border-0'>
												<RowDeleteButton
													contentType={ContentTypes.video}
													dropdownItem
													analytics={analytics}
													display={context.hasDeletePermission}
													t={t}
													onDelete={onDelete}
													contentTitle={content.title}
													content={content}
													contentId={content.id}
												/>
											</DropdownItem>
										)}
									</DropdownMenu>
								</Dropdown>
							</Nav>
						</td>
					</tr>
				);
			})}
		</React.Fragment>
	);
};

export const RowsGallery: React.FunctionComponent<RowsProps> = ({ content, contentToCopy, onDelete, analytics, history, t }) => {
	const context = useContext(ConditionalRenderContext);
	const profile: Profile = store.getState().profile.profile;
	const contentLanguages = useSelector((state: AppState) => state.project.currentProject.languages);
	const [dropdownOpen, setDropdownOpen] = useState({ id: '', isOpen: false });
	const [availableLanguages, setAvailableLanguages] = useState<any[]>([]);

	useEffect(() => {
		let languages = multiLingualService.extractAvailableLanguages(contentLanguages);
		if (multiLingualService.checkIfProjectIsMultiLingual(contentLanguages)) {
			setAvailableLanguages(languages);
		}
	}, []);

	const toggle = (id: string) =>
		setDropdownOpen((prevState: any) => {
			return { id, isOpen: !prevState.isOpen };
		});

	if (content === undefined) {
		return null;
	}

	return (
		<React.Fragment>
			{content.map((content: RowModel, index: number) => {
				return (
					<tr key={`table-row ${index}`}>
						<td className='align-middle'>
							<RowTitleLink
								contentTitle={content.title}
								strapline={content.strapline}
								editContentPath={`/smp/galleries/edit/${content.id}`}
								canEditContent={context.hasWritePermission}
								analytics={analytics}
								contentId={`gallery-id-${content.id}`}
							/>
						</td>
						{multiLingualService.checkIfProjectIsMultiLingual(contentLanguages) && multiLingualService.checkIfContentCanBeTranslated(content)
							? availableLanguages &&
							  availableLanguages.map((item: any) => {
									return (
										<td key={`translation-action-row-buttons ${index + Math.random()}`} className='d-none d-md-table-cell align-middle text-center'>
											{multiLingualService.checkIfContentIsTranslatedInGroup(content, item.languageCode) ? (
												<RowEditTranslationButton
													display={context.hasWritePermission}
													contentId={content.id}
													editContentPath={multiLingualService.getCorrectContentTranslation(content, item.languageCode, 'galleries')}
													t={t}
													analytics={analytics}
													history={history}
													contentType={ContentTypes.gallery}
												/>
											) : (
												<RowCreateTranslationButton
													contentType={ContentTypes.gallery}
													createContentPath='/smp/galleries/create'
													history={history}
													content={contentToCopy[index]}
													display={context.hasWritePermission}
													t={t}
													analytics={analytics}
													languageCode={item.languageCode}
													translationGroup={content.translationGroup}
												/>
											)}
										</td>
									);
							  })
							: multiLingualService.checkIfProjectIsMultiLingual(contentLanguages) && (
									<td colSpan={availableLanguages && availableLanguages.length} className='d-none d-sm-table-cell align-middle text-center'>
										<div className='d-flex'>
											<div style={{ width: '100%' }} className='non-translatable bg-gray text-white text-center'>
												{t('cant_translate')}
											</div>
										</div>
									</td>
							  )}
						<td className='d-none d-sm-table-cell align-middle text-center'>
							{content.status === 'active' ? (
								<i className='fa-lg fa fa-check-circle text-success mx-2' />
							) : (
								<i className='fa-lg fa fa-times-circle text-danger mx-2' />
							)}
						</td>
						<PopoverItem t={t} content={content} lang={profile.language} />
						<td className='d-none d-sm-table-cell align-middle'>
							{moment(content.publishedAt).lang(profile.language.split('_')[0]).format('DD.MM.YYYY, HH:mm')}
						</td>
						<td className='d-none d-sm-table-cell align-middle'>{content.mainCategory}</td>
						<RowContentStatisticsComponent content={content} />
						<td className='align-middle d-sm-table-cell'>
							<Nav className='justify-content-end'>
								<Dropdown nav isOpen={dropdownOpen.id === content.id && dropdownOpen.isOpen} toggle={() => toggle(content.id)}>
									{context.hasWritePermission && (
										<DropdownToggle id={`toggle-menu-caret-${content.id}`} nav caret className='d-flex align-items-center p-0 text-secondary'>
											<RowEditButton
												contentId={content.id}
												display={context.hasWritePermission}
												editContentPath={`/smp/galleries/edit/${content.id}`}
												t={t}
												analytics={analytics}
												history={history}
											/>
										</DropdownToggle>
									)}
									<DropdownMenu className='p-0 listing-dropdown-menu'>
										{context.hasWritePermission && !multiLingualService.checkIfProjectIsMultiLingual(contentLanguages) && (
											<DropdownItem className='p-0 border-0'>
												<RowCopyButton
													contentType={ContentTypes.gallery}
													createContentPath='/smp/galleries/create'
													content={contentToCopy[index]}
													history={history}
													display={context.hasWritePermission}
													t={t}
													analytics={analytics}
												/>
											</DropdownItem>
										)}
										{context.hasDeletePermission && (
											<DropdownItem className='p-0 border-0'>
												<RowDeleteButton
													contentType={ContentTypes.gallery}
													dropdownItem
													analytics={analytics}
													display={context.hasDeletePermission}
													t={t}
													onDelete={onDelete}
													contentTitle={content.title}
													content={content}
													contentId={content.id}
												/>
											</DropdownItem>
										)}
									</DropdownMenu>
								</Dropdown>
							</Nav>
						</td>
					</tr>
				);
			})}
		</React.Fragment>
	);
};

export const RowsBanner: React.FunctionComponent<RowsProps> = ({ content, onDelete, analytics, history, t }) => {
	const context = useContext(ConditionalRenderContext);

	if (content === undefined) {
		return null;
	}

	return (
		<React.Fragment>
			{content.map((content: RowModel, index: number) => {
				return (
					<tr key={`table-row ${index}`}>
						<td className='align-middle'>
							<RowTitleLink
								contentTitle={content.title}
								editContentPath={`/smp/banners/edit/${content.id}`}
								canEditContent={context.hasWritePermission}
								analytics={analytics}
								contentId={`banner-id-${content.id}`}
							/>
						</td>
						<td className='align-middle d-sm-table-cell'>
							<div className='d-flex justify-content-end flex-wrap'>
								<RowEditButton
									contentId={content.id}
									display={context.hasWritePermission}
									editContentPath={`/smp/banners/edit/${content.id}`}
									t={t}
									analytics={analytics}
									history={history}
								/>
								<RowDeleteButton
									analytics={analytics}
									display={context.hasDeletePermission}
									t={t}
									onDelete={onDelete}
									contentTitle={content.title}
									contentId={content.id}
								/>
							</div>
						</td>
					</tr>
				);
			})}
		</React.Fragment>
	);
};

export const RowsList: React.FunctionComponent<RowsProps> = ({ content, onDelete, analytics, history, t }) => {
	return <ListsRowsContainer content={content} onDelete={onDelete} analytics={analytics} history={history} t={t} />;
};

export const RowsAuthor: React.FunctionComponent<RowsProps> = ({ content, onDelete, analytics, history, t, searchQuery }) => {
	const context = useContext(ConditionalRenderContext);
	let authorsSubscription: Subscription = {} as Subscription;
	const [isSearchApplied, updateSearchState] = useState<boolean>(false);

	useEffect(() => {
		authorsSubscription = actionService.onActionReceived().subscribe((action: string) => {
			if (action === AUTHOR_SEARCH) {
				updateSearchState(true);
			}

			if (action === AUTHOR_LISTING_REQUEST && searchQuery && searchQuery.length === 0) {
				updateSearchState(false);
			}
		});

		return () => {
			// Clean up the subscription
			authorsSubscription.unsubscribe();
		};
	});

	useEffect(() => {
		if (typeof searchQuery === 'string' && searchQuery.length === 0) {
			updateSearchState(false);
		}
	}, [searchQuery]);

	if (content === undefined) {
		return null;
	}

	if (isSearchApplied && typeof searchQuery === 'string' && searchQuery.length > 0) {
		return (
			<React.Fragment>
				{content.map((content: RowModel, index: number) => {
					return (
						<tr key={`table-row ${index}`}>
							<td className='align-middle'>
								<RowTitleLink
									contentTitle={content.title}
									editContentPath={`/smp/authors/edit/${content.id}`}
									canEditContent={context.hasWritePermission}
									analytics={analytics}
									contentId={`author-id-${content.id}`}
								/>
							</td>
							<td className='align-middle d-sm-table-cell'>
								<div className='d-flex justify-content-end flex-wrap'>
									<RowEditButton
										contentId={content.id}
										display={context.hasWritePermission}
										editContentPath={`/smp/authors/edit/${content.id}`}
										t={t}
										analytics={analytics}
										history={history}
									/>
									<RowDeleteButton
										analytics={analytics}
										display={context.hasDeletePermission}
										t={t}
										onDelete={onDelete}
										contentTitle={content.title}
										contentId={content.id}
									/>
								</div>
							</td>
						</tr>
					);
				})}
			</React.Fragment>
		);
	}

	return <AuthorsRowsContainer content={content} onDelete={onDelete} analytics={analytics} history={history} t={t} />;
};

export const RowsTag: React.FunctionComponent<RowsProps> = ({ content, onDelete, analytics, history, t, searchQuery, itemDeleted }) => {
	const context = useContext(ConditionalRenderContext);
	const dispatch = useDispatch();
	const LOAD_LIMIT = 20;
	const [isSearchApplied, updateSearchState] = useState<boolean>(false);
	const project = useSelector((state: AppState) => state.project.currentProject);

	const tags = useSelector((state: AppState) => state.tag.tags);
	const [tagDeleted, setTagDeleted] = useState<boolean>(false);
	const [moreItemsAvailable, setMoreItemsAvailable] = useState(!(tags.contentPagination && tags.contentPagination.totalPages === 1));

	let [currentPage, setCurrentPage] = useState(1);
	let tagsSubscription: Subscription = {} as Subscription;

	useEffect(() => {
		tagsSubscription = actionService.onActionReceived().subscribe((action: string) => {
			if (action === TAG_SEARCH) {
				updateSearchState(true);
			}

			if (action === TAG_LISTING_REQUEST && searchQuery && searchQuery.length === 0) {
				updateSearchState(false);
			}
		});

		return () => {
			// Clean up the subscription
			tagsSubscription.unsubscribe();
		};
	});

	useEffect(() => {
		tagsSubscription = actionService.onActionReceived().subscribe((action: string) => {
			if (action === TAG_ENTITY_DELETE_SUCCESS) {
				setTagDeleted(true);
			} else {
				setTagDeleted(false);
			}
		});
	}, [content]);

	useEffect(() => {
		if (typeof searchQuery === 'string' && searchQuery.length === 0) {
			updateSearchState(false);
		}
	}, [searchQuery]);

	useEffect(() => {
		if (tags.contentPagination && tags.contentPagination.totalPages === 1) {
			setMoreItemsAvailable(false);
		} else if (tags.contentPagination && tags.contentPagination.totalPages > 1) {
			setMoreItemsAvailable(true);
		}
	}, [tags.contentPagination]);

	useEffect(() => {
		setCurrentPage(currentPage);
	}, [currentPage]);

	const onLoadMore = () => {
		let headers = { Project: project.domain };
		const tagsCopy = [...tags];
		setCurrentPage(currentPage + 1);
		HttpService.get(`/tags/search?query=${searchQuery}&page=${currentPage + 1}&limit=20`, null, headers).then((response: any) => {
			response.data.data.length < LOAD_LIMIT ? setMoreItemsAvailable(false) : setMoreItemsAvailable(true);
			let newTags = response.data.data.map((tag: any) => {
				return ModelMapper.remapTagFromResponse(tag);
			});
			tagsCopy.push(...newTags);
			dispatch(returnObjectForTagListingUpdate(tagsCopy));
		});
	};

	if (content === undefined) {
		return null;
	}

	if (isSearchApplied && typeof searchQuery === 'string' && searchQuery.length > 0) {
		return (
			<React.Fragment>
				{content.map((content: RowModel, index: number) => {
					return (
						<tr key={`table-row ${index}`}>
							<td className='align-middle'>
								<RowTitleLink
									contentTitle={content.title}
									editContentPath={`/smp/tags/edit/${content.id}`}
									canEditContent={context.hasWritePermission}
									analytics={analytics}
									contentId={`tag-id-${content.id}`}
								/>
							</td>
							<td className='d-flex justify-content-end px-2'>
								<div className='d-flex justify-content-end flex-wrap'>
									<RowEditButton
										contentId={content.id}
										display={context.hasWritePermission}
										editContentPath={`/smp/tags/edit/${content.id}`}
										t={t}
										analytics={analytics}
										history={history}
									/>
									<RowDeleteButton
										analytics={analytics}
										display={context.hasDeletePermission}
										t={t}
										onDelete={onDelete}
										contentTitle={content.title}
										contentId={content.id}
									/>
								</div>
							</td>
						</tr>
					);
				})}
				{moreItemsAvailable ? (
					<Button style={{ width: '125%' }} id='load-more-tags-button' color='primary' onClick={() => onLoadMore()} className='mt-4 mb-2'>
						{t('load_more')}
					</Button>
				) : (
					<div className='d-flex justify-content-center'>
						<label id='no-more-tags-label' className='w-100 mt-3 mb-2'>
							<strong>{t('no_more_tags_available')}</strong>
						</label>
					</div>
				)}
			</React.Fragment>
		);
	} else {
		return (
			<TagsRowsContainer
				content={content}
				searchApplied={isSearchApplied}
				isTagDeleted={tagDeleted}
				searchInput={searchQuery}
				onDelete={onDelete}
				analytics={analytics}
				history={history}
				t={t}
			/>
		);
	}
};

export const RowsCategory: React.FunctionComponent<RowsProps> = ({ categoryTree, onDelete, analytics, history, t }) => {
	if (categoryTree === undefined) {
		return null;
	}

	return <CategoryTree categoryTree={categoryTree ? categoryTree : []} onDelete={onDelete} analytics={analytics} history={history} t={t} />;
};

export const RowsPoll: React.FunctionComponent<RowsProps> = ({ content, onDelete, analytics, history, t }) => {
	const context = useContext(ConditionalRenderContext);

	if (content === undefined) {
		return null;
	}

	return (
		<React.Fragment>
			{content.map((content: RowModel, index: number) => {
				return (
					<tr key={`table-row ${index}`}>
						<td className='align-middle'>
							<RowTitleLink
								contentTitle={content.title}
								editContentPath={`/smp/polls/edit/${content.id}`}
								canEditContent={context.hasWritePermission}
								analytics={analytics}
								contentId={`poll-id-${content.id}`}
							/>
						</td>
						<td className='align-middle d-sm-table-cell'>
							<div className='d-flex justify-content-end flex-wrap'>
								<RowEditButton
									contentId={content.id}
									display={context.hasWritePermission}
									editContentPath={`/smp/polls/edit/${content.id}`}
									t={t}
									analytics={analytics}
									history={history}
								/>
								<RowDeleteButton
									t={t}
									analytics={analytics}
									display={context.hasDeletePermission}
									onDelete={onDelete}
									contentTitle={content.title}
									contentId={content.id}
								/>
							</div>
						</td>
					</tr>
				);
			})}
		</React.Fragment>
	);
};

export const RowsFeed: React.FunctionComponent<RowsProps> = ({ content, onDelete, analytics, history, t }) => {
	const context = useContext(ConditionalRenderContext);

	if (content === undefined) {
		return null;
	}

	return (
		<React.Fragment>
			{content.map((content: RowModel, index: number) => {
				return (
					<tr key={`table-row ${index}`}>
						<td className='align-middle'>
							<RowTitleLink
								contentTitle={content.title}
								editContentPath={`/smp/news-tracker/edit/${content.id}`}
								canEditContent={context.hasWritePermission}
								analytics={analytics}
								contentId={`feed-id-${content.id}`}
							/>
						</td>
						<td className='align-middle d-sm-table-cell'>
							<div className='d-flex justify-content-end flex-wrap'>
								<RowEditButton
									contentId={content.id}
									display={context.hasWritePermission}
									editContentPath={`/smp/news-tracker/edit/${content.id}`}
									t={t}
									analytics={analytics}
									history={history}
								/>
								<RowDeleteButton
									analytics={analytics}
									display={context.hasDeletePermission}
									t={t}
									onDelete={onDelete}
									contentTitle={content.title}
									contentId={content.id}
								/>
							</div>
						</td>
					</tr>
				);
			})}
		</React.Fragment>
	);
};

export const RowsTeam: React.FunctionComponent<RowsProps> = ({ content, analytics, history, t }) => {
	if (content === undefined) {
		return null;
	}

	return (
		<React.Fragment>
			{content.map((content: RowModel, index: number) => {
				return (
					<tr key={`table-row ${index}`}>
						<td className='align-middle'>
							<RowFootballEntityTitle
								img={content.img}
								contentTitle={content.title}
								editContentPath={`/smp/teams/edit/${content.id}`}
								canEditContent={true}
								analytics={analytics}
								contentId={`team-id-${content.id}`}
							/>
						</td>
						<td className='d-none d-sm-table-cell align-middle'> {content.country} </td>
						<td className='d-none d-sm-table-cell align-middle'>
							<div className='d-flex align-items-center'>
								{content.blacklists &&
									content.blacklists.length > 0 && [
										<div key='' className='blacklisted bg-warning text-dark text-center'>
											{t('blacklisted')}
										</div>,
										content.blacklists.map((blacklist: BlacklistModel) => {
											const icon = blacklist.type === 'RELATION' ? 'users' : 'user';

											return (
												<div key={`${blacklist.type}-${blacklist.entityId}`} className='d-flex my-1'>
													<i className={`text-white fa fa-${icon} rounded-circle mr-1 team-edit-icon ml-1`} />
												</div>
											);
										}),
									]}
							</div>
						</td>
						<td className='d-none align-middle d-sm-table-cell'>
							<div className='d-flex'>
								<RowTeamsEditButton
									display={true}
									editContentPath={`/smp/teams/edit/${content.id}`}
									t={t}
									analytics={analytics}
									history={history}
									type='team'
								/>

								<RowTeamsEditButton
									display={true}
									editContentPath={`/smp/squad/edit/${content.id}`}
									t={t}
									analytics={analytics}
									history={history}
									type='players'
								/>
							</div>
						</td>
					</tr>
				);
			})}
		</React.Fragment>
	);
};

export const RowsPlayer: React.FunctionComponent<RowsProps> = ({ content, analytics, history, t }) => {
	if (content === undefined) {
		return null;
	}

	return (
		<React.Fragment>
			{content.map((content: RowModel, index: number) => {
				return (
					<tr key={`table-row ${index}`}>
						<td className='align-middle'>
							<RowFootballEntityTitle
								img={content.img}
								birthdate={content.birthdate}
								contentTitle={content.title}
								editContentPath={`/smp/players/edit/${content.id}`}
								canEditContent={true}
								analytics={analytics}
								contentId={`player-id-${content.id}`}
							/>
						</td>
						<td className='d-none d-sm-table-cell align-middle'> {content.country} </td>
						<td className='d-none d-sm-table-cell align-middle'>
							{content.blacklist && content.blacklist.id && (
								<div className='d-flex'>
									<div className='blacklisted bg-warning text-dark text-center'>{t('blacklisted')}</div>
									<i className='text-white fa fa-user rounded-circle mr-1 team-edit-icon ml-1' />
								</div>
							)}
						</td>
						<td className='d-none align-middle d-sm-table-cell'>
							<div className='d-flex'>
								<RowTeamsEditButton
									display={true}
									editContentPath={`/smp/players/edit/${content.id}`}
									t={t}
									analytics={analytics}
									history={history}
									type='player'
								/>
							</div>
						</td>
					</tr>
				);
			})}
		</React.Fragment>
	);
};

export const RowsCoach: React.FunctionComponent<RowsProps> = ({ content, analytics, history, t }) => {
	if (content === undefined) {
		return null;
	}
	return (
		<React.Fragment>
			{content.map((content: RowModel, index: number) => {
				return (
					<tr key={`table-row ${index}`}>
						<td className='align-middle'>
							<RowFootballEntityTitle
								contentTitle={content.title}
								img={content.img}
								birthdate={content.birthdate}
								editContentPath={`/smp/coaches/edit/${content.id}`}
								canEditContent={true}
								analytics={analytics}
								contentId={`coach-id-${content.id}`}
							/>
						</td>
						<td className='d-none d-sm-table-cell align-middle'> {content.country} </td>
						<td className='d-none d-sm-table-cell align-middle'>
							{content.blacklist && content.blacklist.id && (
								<div className='d-flex'>
									<div className='blacklisted bg-warning text-dark text-center'>{t('blacklisted')}</div>
									<i className='text-white fa fa-user rounded-circle mr-1 team-edit-icon ml-1' />
								</div>
							)}
						</td>
						<td className='d-none align-middle d-sm-table-cell'>
							<div className='d-flex'>
								<RowTeamsEditButton
									display={true}
									editContentPath={`/smp/coaches/edit/${content.id}`}
									t={t}
									analytics={analytics}
									history={history}
									type='coach'
								/>
							</div>
						</td>
					</tr>
				);
			})}
		</React.Fragment>
	);
};
