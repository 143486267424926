import { Col, FormGroup, Label, Row } from 'reactstrap';
import React, { Component } from 'react';
import AsyncSelect from 'react-select/async';
import debounce from 'lodash.debounce';
import HttpService from '../../../../../../services/rest/HttpService';
import { Properties } from './properties/banner-block-edit.properties';
import BannerBlockModel from './models/banner-block.model';
import ErrorHandler from '../../../partials/error/error-handler-component';

export default class BannerEdit extends Component<Properties> {
	searchBanner = (search: string, callback: any) => {
		HttpService.get(`/banners/search?page=0&query=${search}`, null, { Project: this.props.domain })
			.then((response: any) => {
				const options = response.data.data.map((item: any) => this.bannerToOption(item));
				callback(options);
			})
			.catch((e: any) => e);
	};

	bannerToOption = (item: any) => {
		if (item && item.id) {
			return { value: item.id, label: item.title };
		} else return {};
	};

	updateTitleAndId = (selection: any) => {
		this.props.updateBannerState(BannerBlockModel.builder(this.props.banner).withId(selection.value).withTitle(selection.label).build());
	};

	render() {
		return (
			<Row>
				<Col>
					<FormGroup>
						<Label>{this.props.t('change_banner')}:</Label>
						<AsyncSelect
							className='mb-3'
							loadOptions={debounce(this.searchBanner, 500)}
							value={this.bannerToOption(this.props.banner)}
							onChange={this.updateTitleAndId}
							noOptionsMessage={(inputValue) => inputValue && this.props.t('no_options')}
						/>
						{!this.props.isValid && (!this.props.banner.id || (this.props.banner.id && this.props.banner.id.length === 0)) && (
							<ErrorHandler errorMessage='field_is_required' />
						)}
					</FormGroup>
				</Col>
			</Row>
		);
	}
}
