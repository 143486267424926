import { ReactPaginateProps } from 'react-paginate';
import { ContentTypes } from '../../../../constants/content-types';
import WikiRowModel from '../../../../models/Wikipages/Row/WikiRowModel';
import { PAGINATE_PROPS } from '../ListingComponent/subcomponents/AuthorizedListing/properties/PaginateProps';

export const reactPaginateProps = (t: any, contentPagination: any, handlePageClick: any): ReactPaginateProps => {
	return {
		...PAGINATE_PROPS,
		previousLabel: t('previous_page'),
		nextLabel: t('next_page'),
		pageCount: contentPagination ? contentPagination.totalPages : 0,
		onPageChange: handlePageClick,
		forcePage: contentPagination ? contentPagination.currentPage - 1 : 0,
	};
};

export const generateLinkTitleByContentType = (entityType: string) => {
	switch (entityType) {
		case ContentTypes.WIKI_PAGE:
			return 'wiki-pages';
		default:
			return null;
	}
};

export const generateLinkTitleByRow = (content: WikiRowModel[]) => {
	if (content && content.length > 0) {
		const firstEntityType = content[0].entity_type;
		return generateLinkTitleByContentType(firstEntityType);
	}
};
