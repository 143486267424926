import React from 'react';
import * as ReactSortableHOC from 'react-sortable-hoc';
import ContentItemContainer from './ContentItemContainer';
import ListItem from '../../../../../models/list/list-item/ListItem';
import Project from '../../../../../models/project/Project';
import ListItemMeta from '../../../../../models/list/list-item/list-item-meta/ListItemMeta';
import List from '../../../../../models/list/List';

type Properties = {
	list: List;
	t: any;
	project: Project;
	onDelete: (itemIndex: number) => any;
	onMetaChange: (meta: ListItemMeta, index: number) => any;
	rightSidebarList: boolean;
};

const SortableItem = ReactSortableHOC.SortableElement(ContentItemContainer);

export default class ListContentSortableList extends React.Component<Properties> {
	render() {
		const { list, t, project, rightSidebarList } = this.props;
		const styleUl = rightSidebarList ? 'mb-2' : 'mb-4';

		const items = list && list.items ? list.items : [];
		return (
			<ul className={`list-group ${styleUl}`}>
				{items.map((item: ListItem, index: number) => (
					<SortableItem
						disabled={list.lockedPositions && list.lockedPositions.includes(index)}
						isLocked={list.lockedPositions && list.lockedPositions.includes(index)}
						value={item}
						t={t}
						key={`key-sortable-${item.data && item.data.id + index}-${item.type}`}
						project={project}
						onMetaChange={(meta: ListItemMeta) => this.props.onMetaChange(meta, index)}
						onDelete={(itemIndex: number) => this.props.onDelete(itemIndex)}
						itemIndex={index}
						index={index}
						rightSidebarList={rightSidebarList}
					/>
				))}
			</ul>
		);
	}
}
