import DugoutVideoModel from './dugout-video.model';
import { DugoutVideoJson } from './dugout-video.json';
import { DugoutVideoRequestJson } from './dugout-video.request-json';

export default class DugoutVideoBuilder {
	private json: DugoutVideoJson;

	constructor(video?: DugoutVideoModel | DugoutVideoJson) {
		if (video && video instanceof DugoutVideoModel) {
			this.json = video.toJson();
		} else if (video) {
			this.json = video;
		} else {
			this.json = {} as DugoutVideoJson;
		}
	}

	public withId(id: string): DugoutVideoBuilder {
		this.json.id = id;

		return this;
	}

	public withTitle(title: string): DugoutVideoBuilder {
		this.json.title = title;

		return this;
	}

	public withEmbedCode(embedCode: string): DugoutVideoBuilder {
		this.json.embedCode = embedCode;

		return this;
	}

	public withTumbnail(thumbnail: string): DugoutVideoBuilder {
		this.json.thumbnail = thumbnail;

		return this;
	}

	public withHash(hash: string): DugoutVideoBuilder {
		this.json.hash = hash;

		return this;
	}

	public withDescription(description: string): DugoutVideoBuilder {
		this.json.description = description;

		return this;
	}

	toRequestJson(): DugoutVideoRequestJson {
		let json = {} as DugoutVideoRequestJson;
		json.mediaid = this.json.id;
		json.title = this.json.title;
		json.embed_code = this.json.embedCode;
		json.thumbnail_320 = this.json.thumbnail;
		json.description = this.json.description;
		json.embed_hash = this.json.hash;

		return json;
	}

	public build(): DugoutVideoModel {
		return DugoutVideoModel.fromJSON(this.json);
	}
}
