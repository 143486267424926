import React, { ComponentType } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { ModalProperties, RequiredModalProperties } from './properties/modal.properties';
import { connect } from '../../../../../../store/store.connect';
import { AppState } from '../../../../../../store/store';
import * as uiActions from '../../../../../../store/action-creators/UiActionCreator';

@connect<ModalProperties, AppState>(
	() => ({}),
	(dispatch) => ({
		dispatch: (action: any) => dispatch(action),
	}),
)
class ModalContainer extends React.Component<ModalProperties, {}> {
	closeModal = () => {
		this.props.dispatch(uiActions.toggleModal(false));
	};

	render() {
		const { analytics, t, isToggledModal, contentToDelete, onDeleteContent, action } = this.props;

		return (
			<Modal isOpen={isToggledModal} className={'modal-danger'}>
				<ModalHeader
					toggle={() => {
						analytics.sendEvent('Click', 'List Page', 'Delete Close (X)');
						this.closeModal();
					}}
				>
					{action ? t('remove') : t('delete_noun')}
				</ModalHeader>
				<ModalBody className='text-break'>
					<strong>{t('attention_modal')}</strong> {action ? t('remove_notice') : `${t('delete_notice')}:`}
					<strong> {contentToDelete.title} </strong>
					{action && `${t('from_gallery')}.`}
					<br />
					{t('continue_confirmation')}
					<br />
				</ModalBody>
				<ModalFooter>
					<Button
						color='danger'
						id='modal-delete-button'
						className={'mr-auto'}
						onClick={() => {
							analytics.sendEvent('Click', 'List Page', 'Delete Confirmation');
							onDeleteContent(contentToDelete.id, contentToDelete.title);
						}}
					>
						{action ? `${t('remove_action')} ${t('from_gallery')}` : t('delete')}
					</Button>{' '}
					<Button
						id='modal-close-button'
						color='base'
						onClick={() => {
							analytics.sendEvent('Click', 'List Page', 'Delete Cancel');
							this.closeModal();
						}}
					>
						{t('cancel')}
					</Button>
				</ModalFooter>
			</Modal>
		);
	}
}

export default ModalContainer as ComponentType<RequiredModalProperties | ModalProperties>;
