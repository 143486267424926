import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { FormGroup, Label } from 'reactstrap';
import { SelectMenuOptionType } from '../../../../../../models/v2/general/select.model';
import { redirectTypeToOption, redirectTypesToOptions } from '../../helpers/redirection-type.helper';
import { DATA_QA_ATTRIBUTES } from '../../constants/data-qa.attributes';
import { extractContentModeBasedOnUrl, extractMainContentTypeBasedOnUrl } from '../../../../../../global-helpers/global.helpers';
import {
	autoGenerateReduxPropsNamingForContentModels,
	generateReduxPropsUpdateActionByContentType,
} from '../../../../../../services/content-models-service/ContentModelService';
import SeoReduxModel from '../../../../../../models/v2/seo/seo-redux.model';

type Properties = {
	t: any;
	seoRedux: SeoReduxModel;
	updateTempProperty: (propertyObject: Record<string, any>) => void;
};

const RedirectionTypeSelect: FunctionComponent<Properties> = ({ t, seoRedux, updateTempProperty }) => {
	const selectedRedirectionType = seoRedux.redirectType;

	const onRedirectionTypeSelect = (selectedRedirectionOption: SelectMenuOptionType) => {
		const redirectionType = (selectedRedirectionOption && selectedRedirectionOption.value) || '';
		updateTempProperty({
			seo: {
				...seoRedux,
				redirectType: redirectionType,
			},
		});
	};

	return (
		<FormGroup>
			<Label htmlFor={DATA_QA_ATTRIBUTES.TYPE_REDIRECT}>{t('status')}</Label>
			<Select
				id={DATA_QA_ATTRIBUTES.TYPE_REDIRECT}
				value={redirectTypeToOption(selectedRedirectionType, t)}
				options={redirectTypesToOptions(t)}
				onChange={onRedirectionTypeSelect}
				placeholder={t('select')}
				isClearable
				noOptionsMessage={(inputValue) => inputValue && t('no_options')}
			/>
		</FormGroup>
	);
};

function mapStateToProps(state: any) {
	const { tempPropertyNaming, nestedTempPropertyNaming } = autoGenerateReduxPropsNamingForContentModels();

	return {
		seoRedux: state[tempPropertyNaming][nestedTempPropertyNaming].seo || {},
	};
}

function mapDispatchToProps(dispatch: Function) {
	const contentTypeFromUrl = extractMainContentTypeBasedOnUrl();
	const functionForDispatch = generateReduxPropsUpdateActionByContentType(contentTypeFromUrl);

	return {
		updateTempProperty: (propertyObject: Record<string, any>) =>
			functionForDispatch && dispatch(functionForDispatch(propertyObject, extractContentModeBasedOnUrl())),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(RedirectionTypeSelect);
