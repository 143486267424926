import Post from '../../../../models/feed/post/post';
import Source from '../../../../models/feed/sources/source';

export const getNotifications = (sources: Source[], newPosts: Post[], prevPosts: Post[]) => {
	const sourcesLastPostId = getSourcesLastPostIds(sources, prevPosts);

	return getNumberOfNotifications(sourcesLastPostId, newPosts);
};

export const getSourcesLastPostIds = (sources: Source[], prevPosts: Post[]) => {
	const lastPosts = sources.map((source: Source) => {
		const sourcePosts = prevPosts.filter((post: Post) => post.sourceLocation === source.location);

		if (sourcePosts && sourcePosts.length > 0) {
			return { sourceLocation: source.location, lastPostId: sourcePosts[0].id };
		} else {
			return { sourceLocation: source.location, lastPostId: '-2' };
		}
	});

	return lastPosts;
};

const getNumberOfNotifications = (sourcesLastPostId: any, newPosts: Post[]) => {
	const sourceNotifications = sourcesLastPostId.map((sourceLastPost: any) => {
		const postsBySource = newPosts.filter((post: Post) => post.sourceLocation === sourceLastPost.sourceLocation);
		const lastPostIndex = postsBySource.findIndex((post: Post) => post.id === sourceLastPost.lastPostId);

		// if the last post does not exist in the new posts this means all of the posts are new
		if (lastPostIndex === -1) {
			const numberOfNotifications = postsBySource.length;

			return { sourceLocation: sourceLastPost.sourceLocation, notifications: numberOfNotifications };
		} else {
			// if the last post exists in the new posts get the difference between
			// the last post and the number of the other posts
			return { sourceLocation: sourceLastPost.sourceLocation, notifications: lastPostIndex };
		}
	});

	return sourceNotifications;
};

export const getAllLastPosts = (newPosts: Post[], prevPosts: Post[], sourceLocation: string) => {
	if (sourceLocation === 'All') {
		return newPosts.filter((item: Post) => !prevPosts.includes(item[item.id]));
	} else {
		return newPosts.filter((post: Post) => post.sourceLocation === sourceLocation).filter((item: Post) => !prevPosts.includes(item[item.id]));
	}
};
