import * as React from 'react';
import { useEffect, useState } from 'react';
import { Subject } from 'rxjs';
import { Button, Col, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../../../../store/store';
import LiveBlogAdvancedFiltersModel from '../../../models/live-blog-advanced-filters.model';
import { constructLiveBlogSearchContentQuery } from '../../listing/helpers/live-blog-listing.helper';
import { returnObjectForLiveBlogsFiltersUpdate } from '../../../../../../store/action-creators/LiveBlogsActionCreator';
import { LiveBlogsAdvancedFiltersFields } from './live-blogs-advanced-filters-fields.component';

type Properties = {
	t: any;
	updateLiveBlogsAdvancedSearchText: (input: string) => void;
	searchContent: any;
	filtersInSidebar: boolean;
	getAllContent: any;
	contentSearchInput: string;
	saveFiltersHistory?: boolean;
};

export const LiveBlogsAdvancedContentFilter: React.FunctionComponent<Properties> = (props) => {
	const dispatch = useDispatch();
	const clearService = new Subject<void>();
	const project = useSelector((state: AppState) => state.project.currentProject);
	const liveBlogsFilters = useSelector((state: AppState) => state.liveBlogs.filters);
	const [liveBlogsAdvancedContentModel, setLiveBlogsAdvancedContentModel] = useState<LiveBlogAdvancedFiltersModel>(
		liveBlogsFilters ? liveBlogsFilters : ({} as LiveBlogAdvancedFiltersModel),
	);

	useEffect(() => {
		const liveBlogsAdvancedContentSearchText = constructLiveBlogSearchContentQuery(liveBlogsAdvancedContentModel);
		props.updateLiveBlogsAdvancedSearchText(liveBlogsAdvancedContentSearchText);
	}, []);

	const updateLiveBlogsAdvancedSearchText = (advancedContentModel: LiveBlogAdvancedFiltersModel) => {
		const advancedContentSearchText = constructLiveBlogSearchContentQuery(advancedContentModel);
		props.updateLiveBlogsAdvancedSearchText(advancedContentSearchText);

		let filters = advancedContentModel;
		if (filters) {
			filters.searchText = props.contentSearchInput;
			filters.sport = advancedContentModel.sport;
		}

		props.saveFiltersHistory !== false && dispatch(returnObjectForLiveBlogsFiltersUpdate(filters));
		setLiveBlogsAdvancedContentModel(advancedContentModel);
		if (props.contentSearchInput.match(/^ *$/) !== null && advancedContentSearchText.match(/^ *$/) !== null && !advancedContentModel.sport) {
			props.getAllContent();
		}
	};

	const clearAdvancedFilters = () => {
		clearService.next();
		updateLiveBlogsAdvancedSearchText({} as LiveBlogAdvancedFiltersModel);
	};

	const { t } = props;
	return (
		<>
			<Row className='mt-3'>
				<LiveBlogsAdvancedFiltersFields
					liveBlogsAdvancedFilterModel={liveBlogsAdvancedContentModel}
					t={t}
					filtersInSidebar={false}
					updateLiveBlogsAdvancedSearchText={updateLiveBlogsAdvancedSearchText}
					currentProject={project}
				/>
			</Row>
			<Row className='mt-3 mb-3 float-right'>
				<Col>
					<Button type='button' color='warning' id='live-blogs-advanced-filters-search-button' onClick={props.searchContent}>
						<i className={'fa fa-search'}>&nbsp;</i>
					</Button>
					<Button id='live-blogs-advanced-filters-clear-button' className='ml-1' type='button' color='danger' onClick={clearAdvancedFilters}>
						<i className={'fa fa-eraser'}>&nbsp;</i>
					</Button>
				</Col>
			</Row>
		</>
	);
};
