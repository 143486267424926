import 'reflect-metadata';
import { jsonMember, jsonObject } from 'typedjson';
import Country from '../Country/Country.model';
import Category from '../../category/Category';
import CategoryModel from '../category/category.model';

@jsonObject()
export default class TennisTournamentCategoryModel {
	@jsonMember(String)
	public id: string = '';
	@jsonMember(String, { name: 'name' })
	public name: string = '';
	@jsonMember(String, { name: 'entity_type' })
	public entityType: string = '';
}
