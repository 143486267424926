import MainMediaValidator from './main-media.validator';
import FieldValidationModel from './field-validation.model';
import { ValidationFieldsEnum } from './validation-fields.enum';
import FeatureService from '../feature-service/feature.service';
import { featuresService } from '../../App';
import { FeatureTypes } from '../feature-service/features.enum';

export class MandatoryFieldsService {
	private validatedFields: FieldValidationModel[] = [];

	/**
	 * Validates all of the mandatory fields for a specific content type.
	 * @param content: any (Article/Video/Gallery object)
	 * @param contentType: string
	 * @return boolean
	 */
	public validateByContentType = (content: any, contentType: string): boolean => {
		this.clearValidationFields();
		const mandatoryFieldsConfig = featuresService.getFeatureConfig(FeatureTypes.MANDATORY_FIELDS);
		if (mandatoryFieldsConfig && mandatoryFieldsConfig[contentType]) {
			const contentMandatoryFields = mandatoryFieldsConfig[contentType];
			return this.validate(content, contentMandatoryFields);
		} else {
			return true;
		}
	};

	/**
	 * Clears the @this.validatedFields. Used only when performing a new validation (at the beginning of @validateByContentType)
	 */
	private clearValidationFields = () => {
		this.validatedFields = [];
	};

	/**
	 * Iterates through all of the mandatory fields and uses the key of the mandatory field as a parameter for the @checkField function.
	 * Example: If the mandatory field is "main_media": {...} it takes the key, "main_media" and uses it as a parameter for @checkField.
	 * After all the checks are done the function returns a boolean determining if all of the mandatory fields are valid.
	 * @param content: any (Article/Video/Gallery object)
	 * @param mandatoryFields
	 */
	private validate = (content: any, mandatoryFields: any): boolean => {
		if (
			content !== null &&
			content !== undefined &&
			mandatoryFields !== null &&
			mandatoryFields !== undefined &&
			Object.keys(mandatoryFields).length > 0
		) {
			const fieldsKeys = Object.keys(mandatoryFields);

			fieldsKeys.forEach((fieldKey: string) => {
				this.validatedFields.push(this.checkField(content, fieldKey, mandatoryFields[fieldKey]));
			});

			return this.areFieldsValid(this.validatedFields);
		}

		return true;
	};

	/**
	 * Determines if all of the mandatory fields are valid by iterating all of of the @FieldValidationModel objects.
	 * This function works only with @FieldValidationModel[].
	 * @param fields: FieldValidationModel[]
	 * @return boolean
	 */
	private areFieldsValid = (fields: FieldValidationModel[]): boolean => {
		const notValidFields = fields.filter((field: FieldValidationModel) => !field.filled);

		return notValidFields.length === 0;
	};

	/**
	 * This function acts as a factory for validating different types of mandatory fields. Each newly added mandatory field
	 * must be a separate class which has a validate function which will return FieldValidationModel.
	 * For each new mandatory field check a new case must be created.
	 * The function returns a @FieldValidationModel
	 *
	 * @param content: any (Article/Video/Gallery object)
	 * @param mandatoryField: string
	 * @param values: any (configuration of the specific feature, retrieved from the features endpoint)
	 * @return FieldValidationModel
	 */
	private checkField = (content: any, mandatoryField: string, values: any): FieldValidationModel => {
		switch (mandatoryField) {
			case ValidationFieldsEnum.MAIN_MEDIA: {
				const validator = new MainMediaValidator();
				return validator.validate(content, values);
			}
			default: {
				return {} as FieldValidationModel;
			}
		}
	};

	/**
	 * Convenience function for retrieving a specific validated mandatory field, accepts ValidationFieldsEnum.
	 * If no such field exists null is returned.
	 * @param field: ValidationFieldsEnum
	 * @return FieldValidationModel | null
	 */
	public getValidationField = (field: ValidationFieldsEnum): FieldValidationModel | null => {
		const existingValidationField = this.validatedFields.find((validationField: FieldValidationModel) => validationField.field === field);

		return existingValidationField ? existingValidationField : null;
	};

	/**
	 * Convenience function for checking if a specific validated mandatory field is valid.
	 * @param field: ValidationFieldsEnum
	 * @return boolean
	 */
	public isFieldValid = (field: ValidationFieldsEnum) => {
		const existingValidationField = this.validatedFields.find((validationField: FieldValidationModel) => validationField.field === field);

		if (existingValidationField) {
			return existingValidationField.filled;
		} else {
			return true;
		}
	};
}
