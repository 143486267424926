import ListConfigurationBuilder from './ListConfigurationBuilder';
import { ListConfigurationJson } from './ListConfigurationJson';
import Category from '../../category/Category';
import Related from '../../related/Related';

export default class ListConfiguration {
	readonly minItems: string;
	readonly maxItems: string;
	readonly category: Category;
	readonly sports: Related[];

	private constructor(minItems: string, maxItems: string, category: Category, sports: Related[]) {
		this.minItems = minItems;
		this.maxItems = maxItems;
		this.category = category;
		this.sports = sports;
	}

	toJSON(): ListConfigurationJson {
		return {
			minItems: this.minItems,
			maxItems: this.maxItems,
			category: this.category,
			sports: this.sports,
		};
	}

	static fromJSON(json: ListConfigurationJson): ListConfiguration {
		return new ListConfiguration(json.minItems, json.maxItems, json.category, json.sports);
	}

	static builder(list?: ListConfiguration): ListConfigurationBuilder {
		return new ListConfigurationBuilder(list);
	}
}
