import { UrlsJson } from './UrlsJson';
import UrlsBuilder from './UrlsBuilder';
import VideoUrls from './video-urls/video-urls';

export default class Urls {
	readonly externalUrl: string;
	readonly canonicalUrl: string;
	readonly publicUrlDesktop: string;
	readonly publicUrlMobile: string;
	readonly publicUrlAmp: string;
	readonly videoUrls: VideoUrls[];

	private constructor(
		externalUrl: string,
		canonicalUrl: string,
		publicUrlDesktop: string,
		publicUrlMobile: string,
		publicUrlAmp: string,
		videoUrls: VideoUrls[],
	) {
		this.externalUrl = externalUrl;
		this.canonicalUrl = canonicalUrl;
		this.publicUrlDesktop = publicUrlDesktop;
		this.publicUrlMobile = publicUrlMobile;
		this.publicUrlAmp = publicUrlAmp;
		this.videoUrls = videoUrls;
	}

	toJSON(): UrlsJson {
		return {
			externalUrl: this.externalUrl,
			canonicalUrl: this.canonicalUrl,
			publicUrlDesktop: this.publicUrlDesktop,
			publicUrlMobile: this.publicUrlMobile,
			publicUrlAmp: this.publicUrlAmp,
			videoUrls: this.videoUrls,
		};
	}

	static fromJSON(json: UrlsJson): Urls {
		return new Urls(json.externalUrl, json.canonicalUrl, json.publicUrlDesktop, json.publicUrlMobile, json.publicUrlAmp, json.videoUrls);
	}

	static builder(urls?: Urls): UrlsBuilder {
		return new UrlsBuilder(urls);
	}
}
