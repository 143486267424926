import React from 'react';
import { Row, Col } from 'reactstrap';
import { Properties } from './properties/banner-block-view.properties';

export const BannerView: React.FunctionComponent<Properties> = ({ preview, t, isLoading }) => {
	return (
		<Row className={isLoading ? 'loading-overlay' : ''}>
			<Col>
				<h3 className='mb-2 text-center'>
					<i className='fa fa-square-o' /> {t('banner_block')}
				</h3>
				<p className='mb-2 text-center'>
					{t('banner')}: <strong>{preview.title}</strong>
				</p>
			</Col>
		</Row>
	);
};
