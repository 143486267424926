import React from 'react';
import moment from 'moment';
import '../Dashboard.scss';
import { Card, CardBody, Row, Col, CardTitle } from 'reactstrap';
import { Line } from 'react-chartjs-2';
import * as chartOptions from '../charts/chart-options';
import Statistic from '../../../../models/statistics/statistic/statistic';
import DateTimePickerContainer from '../../../Partials/Sidebar/GeneralContentAttributes/subcomponents/DateTimePicker/DateTimePickerContainer';
import Select from 'react-select';
import {
	typesToOptions,
	selectedTypeToOption,
	footballSelectionToOption,
	shouldShowStatistics,
	updateInputsRequests,
	initialStatisticsDates,
	extractChartOptionsData,
} from './helper.ts/charts.helper';
import { Properties, State } from './properties/filtered-chart.properties';
import HttpService from '../../../../services/rest/HttpService';
import AsyncSelect from 'react-select/async';
import { customOption } from '../../../Partials/Sidebar/tags/subcomponents/football-connections/option-component';
import _ from 'lodash';
import {
	playerToOption,
	convertTeamToOption,
} from '../../../Partials/Sidebar/tags/subcomponents/football-connections/helpers/football-conections-container.helper';
import {
	categoriesToOptions,
	categoryToOption,
} from '../../../Partials/Sidebar/GeneralContentAttributes/subcomponents/CategorySelect/CategorySelectHelper';
import './filtered-chart.scss';

class FilteredChart extends React.Component<Properties, State> {
	constructor(props: any) {
		super(props);
		this.state = {
			selectedType: '',
			selectedFootballOption: {
				label: '',
				logo: '',
				id: '',
				type: '',
			},
			selectedDates: [],
			selectedCategory: null,
		};
	}

	onPublishedFromChange = (dates: []) => {
		this.setState({ ...this.state, selectedDates: dates });
	};

	onTypeSelect = (selection: any) => {
		this.setState({ ...this.state, selectedType: selection.value });
	};

	onSelectFootballData = (selections: any) => {
		// This is done because of https://github.com/JedWatson/react-select/issues/3632
		selections = selections === null ? [] : [selections];
		const footballData = footballSelectionToOption(selections);
		this.setState({ ...this.state, selectedFootballOption: footballData });
	};

	onChangeCategory = (event: any) => this.setState({ ...this.state, selectedCategory: event.category });

	loadFootballData = (input: string, callback: any) => {
		const { type, footballApiUrl } = this.props;

		if (input.length > 3) {
			HttpService.requestFootballData(type, input, footballApiUrl).then((response: any) => {
				let optionsTeams =
					type === 'team' &&
					response.data.map((team: any) => {
						return convertTeamToOption(team);
					});
				let optionsPlayers =
					type === 'player' &&
					response.data.map((player: any) => {
						return playerToOption(player);
					});
				type === 'player' && callback([...optionsPlayers]);
				type === 'team' && callback([...optionsTeams]);
			});
		}
	};

	componentDidUpdate(prevProps: Readonly<Properties>, prevState: Readonly<State>): void {
		// We need to make request when all chart inputs are populated and when some of the
		// populated inputs is changed. Here is made logical comparison of prev state and this state
		// to make request when component state properties are updated.
		updateInputsRequests(this.props, this.state, prevState);
	}

	render() {
		const { t, type, data, allCategories, adminCategories, profile } = this.props;
		const { selectedFootballOption, selectedCategory, selectedType, selectedDates } = this.state;

		const showStatistics = shouldShowStatistics(data, selectedType, selectedFootballOption, selectedDates, selectedCategory);

		const dataStatisticDates = showStatistics
			? Object.keys(data.timeseries).map((key) => data.timeseries[key].map((value: Statistic) => moment(value.date).format('DD MMM')))
			: initialStatisticsDates(profile.language);

		const dataStatisticCount = showStatistics ? extractChartOptionsData(data, 'count') : initialStatisticsDates(profile.language);

		return (
			<Card>
				<CardBody>
					<Row>
						<Col sm='3' className='title mb-3'>
							<CardTitle className='mb-0 bold text-left'>
								<h4 className='mb-0'>{t(type)}</h4>
							</CardTitle>
							<div className='small text-muted text-left'>{t('statistic')}</div>
						</Col>
						<Col className='select mb-3' sm='3'>
							{adminCategories && allCategories ? (
								<Select
									className='text-left'
									onChange={this.onChangeCategory}
									options={categoriesToOptions(allCategories, adminCategories)}
									noOptionsMessage={(inputValue) => inputValue && t('no_options')}
									placeholder={selectedCategory ? selectedCategory : t('select_category')}
									value={selectedCategory ? categoryToOption(selectedCategory) : null}
								/>
							) : (
								<AsyncSelect
									noOptionsMessage={(inputValue) => inputValue && t('no_options')}
									value={selectedFootballOption.id === '' ? [] : selectedFootballOption}
									loadOptions={_.debounce(this.loadFootballData, 500)}
									placeholder={selectedFootballOption.label !== '' ? selectedFootballOption.label : `${t('select')} ${t(type)}...`}
									onChange={this.onSelectFootballData}
									formatOptionLabel={customOption}
								/>
							)}
						</Col>
						<Col className='select mb-3' sm='3'>
							<Select
								className='text-left'
								options={typesToOptions(chartOptions.typeStatistic, t)}
								onChange={this.onTypeSelect}
								noOptionsMessage={(inputValue) => inputValue && t('no_options')}
								placeholder={selectedType ? selectedType : t('select_resource')}
								value={selectedType && selectedTypeToOption(selectedType, t)}
							/>
						</Col>
						<Col className='select mb-3' sm='3'>
							<DateTimePickerContainer
								id={`filtered-chart-${type}`}
								dateFormat='Y.m.d'
								t={t}
								mode='range'
								enableTime={false}
								onDateChange={this.onPublishedFromChange}
							/>
						</Col>
					</Row>
					<div className='chart-wrapper' style={{ height: 300 + 'px', marginTop: 40 + 'px' }}>
						<Line
							data={chartOptions.mainChart(t, dataStatisticDates[0], dataStatisticCount, selectedType)}
							options={chartOptions.mainChartOpts(dataStatisticCount, showStatistics)}
							height={300}
						/>
					</div>
				</CardBody>
			</Card>
		);
	}
}

export default FilteredChart;
