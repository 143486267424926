import * as React from 'react';
import debounce from 'lodash.debounce';
import AsyncSelect from 'react-select/async';
import * as helpers from '../react-select-helpers/react-select.helpers';
import HttpService from '../../../../services/rest/HttpService';
import { customOption } from '../../Blocky/partials/shared/custom-select-option';
import { Label, FormGroup } from 'reactstrap';
import { remapEntitiesFromResponse } from '../../../Resources/translations/helpers/translations.helpers';

type Props = {
	t: any;
	onChange: (selection: any) => void;
	value: any;
	lang: string;
	type: string;
	label: string;
	isMulti: boolean;
	shouldShowLabel: boolean;
	isClearable: boolean;
};

const CustomFootballSearch: React.FunctionComponent<Props> = (props) => {
	const { t, onChange, value, lang, type, isMulti, label, shouldShowLabel, isClearable } = props;

	const onCustomFootballSearch = (selection: any) =>
		isMulti ? onChange(helpers.optionsToItems(selection)) : onChange(helpers.optionToItem(selection));

	const loadOptions = (search: string, callback: any) => {
		if (search.length > 2) {
			HttpService.getFootballData(search, lang.split('-')[0], type)
				.then((response: any) => {
					const options: any = helpers.itemsToOptions(remapEntitiesFromResponse(response.data, type), isMulti, t, type);
					callback([...options]);
				})
				.catch((error: unknown) => console.error(error));
		}
	};
	const inputValue = isMulti ? helpers.itemsToOptions(value, isMulti, t, type) : helpers.itemToOption(value, isMulti, t, type);

	return (
		<FormGroup>
			{shouldShowLabel && label && <Label htmlFor={`manual-data-entry-${label}`}>{t(label)}</Label>}
			<AsyncSelect
				isClearable={isClearable}
				inputId={`manual-data-entry-${type}`}
				value={inputValue}
				loadOptions={debounce(loadOptions, 500)}
				onChange={onCustomFootballSearch}
				placeholder={t(`search_${label}`)}
				isMulti={isMulti}
				formatOptionLabel={customOption}
				noOptionsMessage={(inputValue) => inputValue && t('no_options')}
			/>
		</FormGroup>
	);
};

export default CustomFootballSearch;
