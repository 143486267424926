export default class AffiliatesService {
	checkIfAdminHasAffiliate = (adminId: string, affiliates: any) => {
		let adminHasAffiliate: boolean = false;
		affiliates.affiliates.map((affiliate: any) => {
			if (affiliate.admin_id === adminId) {
				adminHasAffiliate = true;
			}
		});

		return adminHasAffiliate;
	};

	getAdminAffiliates = (adminId: string, affiliates: any) => {
		const adminAffiliates = affiliates.affiliates.filter((affiliate: any) => affiliate.admin_id === adminId);
		if (adminAffiliates.length > 0) {
			return adminAffiliates[0];
		}
		return {};
	};

	constructAffiliateUrl = (adminAffiliate: any, template: string) => {
		if (template && template !== null && template.length > 0) {
			return template
				.replace('{aff_id}', adminAffiliate.affiliate_config.aff_id)
				.replace('{site_id}', adminAffiliate.affiliate_config.site_id)
				.replace('{add_id}', adminAffiliate.affiliate_config.add_id);
		}

		return '';
	};

	constructAffiliateConfig = (adminId: string, affiliates: any) => {
		if (this.checkIfAdminHasAffiliate(adminId, affiliates)) {
			const adminAffiliate = this.getAdminAffiliates(adminId, affiliates);
			return this.constructAffiliateUrl(adminAffiliate, affiliates.static_template);
		}

		return '';
	};
}
