import React from 'react';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { Properties } from './properties/link-types.properties';
import { LINK_TYPES } from '../../../../constants/link.constants';

export const LinkTypes: React.FunctionComponent<Properties> = ({ value, blockId, t, onChange }) => {
	return (
		<>
			<Label>{t('link_type')}: </Label>
			<Row className='mb-2'>
				<Col>
					{Object.keys(LINK_TYPES).map((key, index) => {
						return (
							<FormGroup check inline key={`link-type-col-${key}-${index}`}>
								<Input
									className='form-check-input'
									type='radio'
									onChange={onChange}
									id={`link-type-${index}-${blockId}`}
									name={`link-type-radio-buttons-${key}-${blockId}`}
									checked={value === key}
									value={key}
								/>
								<Label className='form-check-label' check htmlFor={`link-type-${index}-${blockId}`}>
									{t(LINK_TYPES[key])}
								</Label>
							</FormGroup>
						);
					})}
				</Col>
			</Row>
		</>
	);
};
