import React, { FunctionComponent } from 'react';
import { Label, Button } from 'reactstrap';
import moment from 'moment';
import {
	applyInProgressClass,
	toggleGoals,
	printMinutesIfMatchInProgress,
	extractActionForUpdatingSelectedEvents,
	remapMatchAsRelatedModel,
} from '../../helpers/events.helper';
import Related from '../../../../../../models/related/Related';
import { connect } from 'react-redux';
import { store } from '../../../../../../store/store';
import { extractRelatedPropertiesNameByUrl } from '../../helpers/utils';
import ResponseMatchModel from '../../../../../../models/v2/match/entity/response-match.model';

export type Properties = {
	match: ResponseMatchModel;
	updateSelectedEvents: Function;
};
const MatchRow: FunctionComponent<Properties> = ({ match, updateSelectedEvents }) => {
	if (!match) return null;
	const liClass = `list-group-item d-flex align-items-center ${match.finished_at !== '' && applyInProgressClass(match.type)}`;

	const onMatchSelect = (selectedMatch: ResponseMatchModel) => {
		const reduxNestedProperty = extractRelatedPropertiesNameByUrl();
		const relatedMatchesResult: Related[] = (reduxNestedProperty && store.getState().tempMatchRelated[reduxNestedProperty]) || [];
		const selectedMatchAsRelatedModel = remapMatchAsRelatedModel(selectedMatch);
		selectedMatchAsRelatedModel && relatedMatchesResult.push(selectedMatchAsRelatedModel);
		updateSelectedEvents(relatedMatchesResult as Related[]);
	};

	return (
		<li className={liClass} id={`match-line-tags-${match.id}`}>
			<Button className='btn btn-light btn-sm mr-2' id={`add-match-line-button-${match.id}`} onClick={() => onMatchSelect(match)}>
				<i className='fa fa-plus'></i>
			</Button>
			{match.finished_at ? (
				<Label className='form-check-label'>
					{`${moment(match.finished_at).format('DD MMM YYYY')} - `}
					<span>{match.home_team.name}</span>
					{` ${match.goal_home}:${match.goal_away} `}
					<span>{match.away_team.name}</span>
				</Label>
			) : (
				<Label className='form-check-label'>
					{`${moment(match.start_time).format('DD MMM YYYY')} - `}
					<span>{match.home_team.name}</span>
					{toggleGoals(match)}
					<span>{match.away_team.name}</span>
					{printMinutesIfMatchInProgress(match)}
				</Label>
			)}
		</li>
	);
};

function mapDispatchToProps(dispatch: any) {
	const actionForUpdatingSelectedEvents = extractActionForUpdatingSelectedEvents();

	return {
		updateSelectedEvents: (related: Related[]) => actionForUpdatingSelectedEvents && dispatch(actionForUpdatingSelectedEvents(related)),
	};
}

export default connect(null, mapDispatchToProps)(MatchRow);
