import ContentAttributes from '../../../../../../../models/content-attributes/content-attributes-model';

export const attributeToOption = (data: ContentAttributes, isMulti: boolean) => {
	if (data && data.id) {
		return { label: data.name, value: data.id, data };
	}

	return null;
};

export const attributesToOptions = (attributes: ContentAttributes[], isMulti: boolean) => {
	if (attributes && attributes.length > 0) {
		return attributes.map((attribute: ContentAttributes) => attributeToOption(attribute, isMulti));
	}

	return [];
};

export const optionsToAttributes = (selection: any) => {
	if (selection && selection.length > 0) {
		return selection.map((item: any) => item.data);
	}

	return {};
};

export const optionToAttribute = (selection: any) => {
	if (selection && selection.data) {
		return selection.data;
	}

	return {};
};
