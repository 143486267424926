import * as React from 'react';
import Select from 'react-select';
import { ValueType } from 'react-select/src/types';
import { ContentTypes, SelectOptions } from '../../../../../../constants/content-types';
import { availableContentTypes, getModelByContentType } from '../../../../../../services/content-models-service/ContentModelService';

export type Properties = {
	contentType: availableContentTypes;
	onPropertySelect: Function;
	selectedProperties: string[];
	t: any;
	isClearable: boolean;
};

const extractPropertiesLabelFromRedux = (contentType: availableContentTypes = ContentTypes.ARTICLE, propertySlug: string) => {
	const contentModel = getModelByContentType(contentType);
	const reduxPropertiesWithDesc = contentModel ? contentModel.extractDynamicPropertiesKeysWithDescription() : [];
	const foundEl = reduxPropertiesWithDesc.find((el) => el.slug === propertySlug);
	return foundEl && foundEl.description ? foundEl.description : propertySlug;
};

const getOptionsByContentType = (contentType: availableContentTypes = ContentTypes.ARTICLE) => {
	const contentModel = getModelByContentType(contentType);
	const reduxProperties = contentModel ? contentModel.extractDynamicPropertiesAsObjectWithValues() : {};

	return Object.keys(reduxProperties).length > 0
		? Object.keys(reduxProperties).map((propertySlug: string) => {
				return {
					label: extractPropertiesLabelFromRedux(contentType, propertySlug),
					value: propertySlug,
				};
		  })
		: null;
};

const getOptionsBySelectedQuery = (contentType: availableContentTypes = ContentTypes.ARTICLE, selectedQueries: string[]) => {
	return selectedQueries.map((selection) => {
		return {
			label: extractPropertiesLabelFromRedux(contentType, selection),
			value: selection,
		};
	});
};

const PropertyMultiSelect: React.FunctionComponent<Properties> = (props) => {
	const { contentType, t, onPropertySelect, selectedProperties, isClearable } = props;

	const onPropertyChange = (values: ValueType<SelectOptions>) => {
		const selections = values as SelectOptions[];
		const selectedQueryParams: string[] = [];
		if (selections && selections.length > 0) {
			selections.forEach((selection) => {
				selection && selectedQueryParams.push(selection.value);
			});
		}
		onPropertySelect(selectedQueryParams);
	};

	return (
		<Select
			inputId='content-properties-select'
			data-qa='content-properties-select'
			value={getOptionsBySelectedQuery(contentType, selectedProperties)}
			options={getOptionsByContentType(contentType)}
			onChange={onPropertyChange}
			placeholder={t('select')}
			isClearable={isClearable}
			isMulti={true}
		/>
	);
};

export default PropertyMultiSelect;
