import React from 'react';

type ListingHeadersProps = {
	t: any;
};

export const TableHead: React.FunctionComponent<ListingHeadersProps> = ({ t }) => {
	return (
		<thead>
			<tr>
				<th className='w-title col-md-4 col-sm-6'>{t('title')}</th>
				<th className='col-md-2'>{t('type')}</th>
				<th className='col-md-1 col-sm-2 text-center'>{t('active')}</th>
				<th className='d-none d-md-table-cell col-md-3'>{t('published_at')}</th>
				<th className='col-md-2 text-right'>{t('actions')}</th>
			</tr>
		</thead>
	);
};
