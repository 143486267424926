export const appendUploadingClass = (isLoading: boolean) => {
	return isLoading ? 'fa fa-spinner fa-pulse' : 'fa fa-cloud-upload';
};

export const isSingleFileUplaod = (isSingleUpload: boolean) => {
	if (isSingleUpload) {
		return isSingleUpload;
	}

	return false;
};

export const normalizeResponse = (responseList: any[]) => {
	if (responseList && responseList.length > 0) {
		return responseList.map((response: any) => response.data.data);
	}

	return [];
};

export const extractSportsRelatedData = (related: any) => {
	const tempRelated = [];

	if (related && related.sports && related.sports.length > 0) {
		tempRelated.push(...related.sports);
	}

	if (related && related.matches && related.matches.length > 0) {
		tempRelated.push(...related.matches);
	}

	return tempRelated;
};
