import React from 'react';
import { Label, InputGroup, Input, InputGroupAddon, Button, Row } from 'reactstrap';
import { Properties, State } from './properties/dugout-video-select.properties';
import HttpService from '../../../../../services/rest/HttpService';
import { remapDugoutVideosFromResponse } from './helpers/dugout-video-select.helper';
import './dugout-video-select.scss';
import LanguageSelect from './language-select/language-select.component';
import VideoAutoLinking from '../video-autolinking/video-autolink';
import { featuresService } from '../../../../../App';
import { FeatureTypes } from '../../../../../services/feature-service/features.enum';
import DugoutVideoModel from '../../../../../models/dugout-videos/dugout-video/dugout-video.model';

export default class DugoutVideoSelect extends React.Component<Properties, State> {
	dugoutVideoConfig = featuresService.getFeatureConfig(FeatureTypes.DUGOUT_INTEGRATION);

	constructor(props: Properties) {
		super(props);
		this.state = {
			contentSearchInput: props.searchText && props.searchText.length > 0 ? props.searchText : '',
			isOpenDropdown: false,
			selectedLanguage: props.selectedLang ? props.selectedLang : this.dugoutVideoConfig.lang ? this.dugoutVideoConfig.lang : 'en',
		};
	}

	componentDidMount() {
		const { searchText } = this.props;

		if (searchText && searchText.length > 0) {
			this.searchDugoutVideos(searchText);
		}
	}

	onContentSearchInput = (event: any) => {
		this.onVideoInputChangeState(event.target.value);

		if (event.target.value.length < 1) {
			this.props.onSelect([], this.state.selectedLanguage);
			this.props.onSelectedDugoutVideoChange(DugoutVideoModel.builder().build());
		}
	};

	onContentSearch = (event: any) => {
		if (event.keyCode === 13 && this.state.contentSearchInput.trim().length > 0) {
			this.searchDugoutVideos(this.state.contentSearchInput);
		}
	};

	clearSearch = () => {
		this.onVideoInputChangeState('');
		this.props.onSelect([], this.state.selectedLanguage);
		this.props.onSelectedDugoutVideoChange(DugoutVideoModel.builder().build());
	};

	searchDugoutVideos(search: string) {
		this.props.toggleLoadingState(true);
		const { selectedLanguage } = this.state;
		HttpService.getDugoutVideos(
			'https://embed.dugout.com/feeds/',
			`?type=search&q=${encodeURI(search)}&lang=${selectedLanguage}&token=${this.dugoutVideoConfig.token}&output=json`,
		)
			.then((response: any) => {
				this.props.toggleLoadingState(false);
				this.props.onSelect(remapDugoutVideosFromResponse(response.data), selectedLanguage);
			})
			.catch((e: any) => e, this.props.toggleLoadingState(true));
	}

	onVideoInputChangeState = (input: string) => {
		this.setState({ ...this.state, contentSearchInput: input });

		return input;
	};

	updateSearchWithTag = (input: string) => {
		const search = this.state.contentSearchInput + ' ' + input;
		this.setState({ ...this.state, contentSearchInput: search });
		this.searchDugoutVideos(search);
	};

	toggleLanguagesMenu = () => {
		this.setState((state: State) => {
			return { ...state, isOpenDropdown: !this.state.isOpenDropdown };
		});
	};

	changeSelectedLanguage = (lang: string) => {
		this.setState({ ...this.state, selectedLanguage: lang, contentSearchInput: '' });
	};

	componentDidUpdate(prevProps: Readonly<Properties>, prevState: Readonly<State>) {
		if (prevState.selectedLanguage !== this.state.selectedLanguage) {
			this.setState({ ...this.state, selectedLanguage: this.state.selectedLanguage });
		}
	}

	render() {
		const { t, linkedTags } = this.props;
		const { contentSearchInput, isOpenDropdown, selectedLanguage } = this.state;

		return (
			<>
				<Label>{this.props.t('choose_video')}:</Label>
				<InputGroup className='position-relative d-flex flex-direction-row flex-nowrap'>
					<LanguageSelect
						t={t}
						changeSelectedLanguage={this.changeSelectedLanguage}
						isOpenDropdown={isOpenDropdown}
						toggleLanguagesMenu={this.toggleLanguagesMenu}
						selectedLanguage={selectedLanguage}
					/>
					<InputGroup className='position-relative d-flex'>
						<Input
							type='text'
							id='search-dugout-input'
							name='search-input'
							className='rounded-0 pr-4'
							placeholder={t('content_search_placeholder')}
							onChange={this.onContentSearchInput}
							onKeyDown={this.onContentSearch}
							value={contentSearchInput}
						/>
						{contentSearchInput && contentSearchInput.length > 0 && (
							<div onClick={this.clearSearch} className='p-2 text-muted position-absolute' style={{ top: 0, right: 0 }}>
								<i className='fa fa-times' />
							</div>
						)}
					</InputGroup>
					<InputGroupAddon addonType='append'>
						<Button
							id='search-dugout-video'
							type='button'
							color='primary'
							disabled={contentSearchInput.trim().length === 0}
							onClick={() => this.searchDugoutVideos(contentSearchInput)}
						>
							<i className={'fa fa-search'}>&nbsp;</i>
							{t('search')}
						</Button>
					</InputGroupAddon>
				</InputGroup>
				<Row className={'m-0 mt-2'}>
					<VideoAutoLinking
						featureType={FeatureTypes.DUGOUT_INTEGRATION}
						updateSearchWithTag={this.updateSearchWithTag}
						linkedTags={linkedTags}
					/>
				</Row>
			</>
		);
	}
}
