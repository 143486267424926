import React from 'react';
import Button from 'reactstrap/lib/Button';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';

type Properties = {
	blockIndex: number;
	children: any;
	onCancel: () => void;
	onEdit: () => void;
	onRemove: () => void;
	t: any;
	dragHandler: any;
	name: string;
	id: string;
	changeBlocksToCopy: (value: boolean) => void;
	selected: boolean;
	blocksLength: number;
};

export const PreviewView = ({
	children,
	onCancel,
	onEdit,
	onRemove,
	t,
	name,
	dragHandler,
	changeBlocksToCopy,
	blockIndex,
	id,
	selected,
	blocksLength,
}: Properties) => {
	const rowStyle = { maxWidth: '900px', margin: '0 auto' };
	const toggleCopyBlock = (e: any) => changeBlocksToCopy(e.target.checked);

	return (
		<div className='block bg-light mb-2 rounded border'>
			<h6 className='p-1 d-flex align-items-center'>
				{dragHandler}
				<input className='mr-1' id={`copy-block-${name}-${id}`} onChange={toggleCopyBlock} checked={selected} type='checkbox' />
				<label className='m-0' htmlFor={`copy-block-${name}-${id}`}>
					{t(name)}
				</label>
			</h6>
			<div className='block-actions d-none'>
				<div className='btn-group btn-group-sm' role='group' aria-label='Basic example'>
					<Button id={`preview-cancel-block-btn-${blockIndex}`} color='info' onClick={onCancel}>
						<i className='fa fa-ban'></i> {t('cancel')}
					</Button>
					<Button id={`preview-edit-block-btn-${blockIndex}`} color='warning' onClick={onEdit}>
						<i className='fa fa-pencil'></i> {t('edit')}
					</Button>
					{blocksLength > 1 && (
						<Button id={`preview-remove-block-btn-${blockIndex}`} color='danger' onClick={onRemove}>
							<i className='fa fa-trash'></i> {t('remove')}
						</Button>
					)}
				</div>
			</div>
			<Row style={rowStyle} className='bg-white'>
				<Col>{children}</Col>
			</Row>
		</div>
	);
};
