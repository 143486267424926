import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { FormGroup, Label } from 'reactstrap';
import {
	autoGenerateReduxPropsNamingForContentModels,
	generateReduxPropsUpdateActionByContentType,
} from '../../../../../../../services/content-models-service/ContentModelService';
import {
	extractContentModeBasedOnUrl,
	extractMainContentTypeBasedOnUrl,
	isArrayEmpty,
	isObjectEmpty,
} from '../../../../../../../global-helpers/global.helpers';
import { SelectMenuOptionType } from '../../../../../../../models/v2/general/select.model';
import { DATA_QA_ATTRIBUTES } from '../../../constants/data-qa.attributes';
import CategoryModel from '../../../../../../../models/v2/category/category.model';
import { categoriesToOptions } from '../../../helpers/category-select.helper';
import { ReduxGeneralContentProps } from '../../../constants/redux-general-content.attributes';
import ErrorAsync from '../../../../../BaseComponents/ErrorComponent/ErrorAsync';

type Properties = {
	t: any;
	categoriesRedux: CategoryModel[];
	adminCategoriesRedux: CategoryModel[];
	selectedCategory: CategoryModel | null;
	updateTempProperty: (propertyObject: Record<string, any>) => void;
};

const MainCategorySelect: FunctionComponent<Properties> = ({
	t,
	categoriesRedux,
	adminCategoriesRedux,
	selectedCategory,
	updateTempProperty,
}) => {
	const onCategorySelect = (selectedCategoryOption: SelectMenuOptionType) => {
		const selectedCategory = (selectedCategoryOption && selectedCategoryOption.data) || null;
		updateTempProperty({ [ReduxGeneralContentProps.CATEGORY]: selectedCategory });
	};

	return isArrayEmpty(categoriesRedux) ? null : (
		<FormGroup>
			<Label htmlFor={DATA_QA_ATTRIBUTES.MAIN_CATEGORY}>{t('main_category')}</Label>
			<Select
				id={DATA_QA_ATTRIBUTES.MAIN_CATEGORY}
				value={selectedCategory && categoriesToOptions([selectedCategory], adminCategoriesRedux)}
				options={categoriesToOptions(categoriesRedux, adminCategoriesRedux)}
				onChange={onCategorySelect}
				placeholder={t('select')}
				noOptionsMessage={(inputValue) => inputValue && t('no_options')}
			/>
			<ErrorAsync t={t} errorType='category_id' />
		</FormGroup>
	);
};

function mapStateToProps(state: any) {
	const { tempPropertyNaming, nestedTempPropertyNaming } = autoGenerateReduxPropsNamingForContentModels();

	// We are doing this because if there are NOT selected comment policy the value is stored as empty object in Redux
	// So here we add logic to assign 'null' value if it's empty so we should check by null, not by empty object
	// If we change it directly in  redux many problems can occurs
	const selectedCategory = state[tempPropertyNaming][nestedTempPropertyNaming].category;

	return {
		selectedCategory: !isObjectEmpty(selectedCategory) ? selectedCategory : null,
		categoriesRedux: state.category.allCategories || [], // all available categories
		adminCategoriesRedux: state.category.adminCategories || [], // categories only for the current user
	};
}

function mapDispatchToProps(dispatch: Function) {
	const contentTypeFromUrl = extractMainContentTypeBasedOnUrl();
	const functionForDispatch = generateReduxPropsUpdateActionByContentType(contentTypeFromUrl);

	return {
		updateTempProperty: (propertyObject: Record<string, any>) =>
			functionForDispatch && dispatch(functionForDispatch(propertyObject, extractContentModeBasedOnUrl())),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(MainCategorySelect);
