import { Button, Col, FormGroup, Row } from 'reactstrap';
import React from 'react';
import ImageCells from './image-cells/image-cells';
import Scrollbar from 'react-custom-scrollbars';
import ErrorHandler from '../../../partials/error/error-handler-component';
import ImagoImageSelect from '../../../partials/imago-image-select/imago-image-select.component';
import BlockModel from '../../../models/block.model';
import ImagoImageModel from './models/imago-image.model';
import ImagoImagesModel from './models/imago-images.model';

type Properties = {
	t: any;
	onSelect: (image: ImagoImageModel) => void;
	updateSearchInput?: (searchInput: string, pageOffset: number, selectedImageType: string) => void;
	block?: BlockModel;
	selectedImagoImage: ImagoImageModel;
	isMediaSidebar: boolean;
	blocks: BlockModel[];
	isValid?: boolean;
	inBlocky: boolean;
	pageOffset?: number;
};

export type State = {
	imagoImages: ImagoImagesModel | null;
	toggleLoadingState: boolean;
	shouldRequest: boolean;
	pageOffset: number;
};

class ImagoImageContainer extends React.Component<Properties, State> {
	constructor(props: any) {
		super(props);
		this.state = {
			imagoImages: null,
			toggleLoadingState: false,
			shouldRequest: false,
			pageOffset: props.pageOffset,
		};
	}

	setImages = (imagoImages: ImagoImagesModel, pageOffset: number, searchInput: string, selectedImageType: string) => {
		this.setState((state: State) => ({ ...state, imagoImages, pageOffset, shouldRequest: false }));
		this.props.updateSearchInput && this.props.updateSearchInput(searchInput, pageOffset, selectedImageType);
	};

	toggleLoadingState = (loading: boolean) => this.setState({ ...this.state, toggleLoadingState: loading });

	onPageUp = () => this.setState({ ...this.state, shouldRequest: true, pageOffset: this.state.pageOffset + 15 });

	onPageDown = () => this.setState({ ...this.state, shouldRequest: true, pageOffset: this.state.pageOffset - 15 });

	render() {
		const { t, onSelect, selectedImagoImage, isMediaSidebar, blocks } = this.props;
		const { imagoImages, toggleLoadingState, shouldRequest, pageOffset } = this.state;
		return (
			<div className={'mb-2'}>
				<div>
					<Row>
						<Col>
							<FormGroup>
								<ImagoImageSelect
									t={t}
									onSelect={this.setImages}
									shouldRequest={shouldRequest}
									selectedImageType={
										this.props.block && this.props.block.data && this.props.block.data.preview && this.props.block.data.preview.selectedImageType
									}
									onSelectedImagoImageChange={onSelect}
									toggleLoadingState={this.toggleLoadingState}
									searchInput={
										this.props.block && this.props.block.data && this.props.block.data.preview && this.props.block.data.preview.searchInput
									}
									searchText={
										this.props.block && this.props.block.data && this.props.block.data.preview && this.props.block.data.preview.searchInput
									}
									blocks={blocks}
									pageOffset={pageOffset}
								/>
							</FormGroup>
						</Col>
					</Row>
					<Row></Row>
					<div className={`${toggleLoadingState ? 'loading-overlay' : ''}`}>
						{imagoImages && imagoImages.imageList && imagoImages.imageList.length > 0 ? (
							<div style={{ height: '500px' }} className='pb-1'>
								<Scrollbar ref='scrollbars'>
									<ImageCells
										t={t}
										onSelect={onSelect}
										isMediaSidebar={isMediaSidebar}
										images={imagoImages.imageList}
										toggleLoadingState={toggleLoadingState}
										selectedImage={selectedImagoImage}
									/>
								</Scrollbar>
							</div>
						) : (
							<div className='text-center'>{t('no_data')}</div>
						)}
						{imagoImages && (
							<div className='m-2 d-flex flex-row justify-content-end'>
								<Button
									id='imagoimages-prev-page'
									size='sm'
									outline
									className='mr-2 d-flex align-items-center'
									color='primary'
									disabled={pageOffset === 0}
									onClick={this.onPageDown}
								>
									<i className='fa fa-2x fa-angle-left px-1' />
									{t('previous')}
								</Button>
								<Button
									id='imagoimages-next-page'
									size='sm'
									disabled={pageOffset + 15 >= imagoImages.totalPhotos}
									className='d-flex align-items-center'
									outline
									color='primary'
									onClick={this.onPageUp}
								>
									{t('next')}
									<i className='fa fa-2x fa-angle-right px-1' />
								</Button>
							</div>
						)}
					</div>
					{this.props.inBlocky && (
						<div className='p-1'>
							{!this.props.isValid && selectedImagoImage && selectedImagoImage.id === undefined && (
								<ErrorHandler errorMessage='no_selected_image' />
							)}
						</div>
					)}
				</div>
			</div>
		);
	}
}

export default ImagoImageContainer;
