import { string } from 'prop-types';
import 'reflect-metadata';
import { AnyT, jsonMember, jsonObject } from 'typedjson';

export interface OddClientBookmakersFullModel {
	id: string;
	name: string;
	url: string;
	country: string | null;
	links: {
		applicationId: string;
		betslipLink: string;
		fallbackLink: string;
	}[];
	url_logo: string;
}

@jsonObject()
export default class OddClientBookmakersModel {
	@jsonMember(String)
	public id: string = '';
	@jsonMember(String)
	public name: string = '';
	@jsonMember(String)
	public url: string = '';
	@jsonMember(String)
	public country: string = '';
	@jsonMember(AnyT)
	public links: any = {
		applicationId: string,
		betslipLink: string,
		fallbackLink: string,
	};
	@jsonMember(String)
	public url_logo: string = '';
}
