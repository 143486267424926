import { featuresService } from '../../../../App';
import { FeatureTypes } from '../../../../services/feature-service/features.enum';
import axios, { AxiosInstance } from 'axios';
import { handleGettyImageApiError } from './getty-images.helper';

let accessToken: string | null = null;
let tokenExpiry: number = 0;

export default class GettyApiService {
	private httpInstance: AxiosInstance = {} as AxiosInstance;
	private gettyFeatureConfig = featuresService.getFeatureConfig(FeatureTypes.GETTY_IMAGES);

	constructor() {
		this.createGettyHttpInstance();
	}

	private createGettyHttpInstance = () => {
		const headers = {
			'Content-Type': 'application/json',
		};
		this.httpInstance = axios.create({
			timeout: 30000,
			headers: { ...headers },
		});
	};

	public async getAccessToken() {
		const clientId = featuresService.getFeatureConfig(FeatureTypes.GETTY_IMAGES).apiKey;
		const clientSecret = featuresService.getFeatureConfig(FeatureTypes.GETTY_IMAGES).apiSecret;

		const authString = `${clientId}:${clientSecret}`;
		const base64AuthString = btoa(authString);

		const bodyFormData = new URLSearchParams();
		bodyFormData.append('grant_type', 'client_credentials');

		try {
			const response = await axios.post('https://authentication.gettyimages.com/oauth2/token', bodyFormData, {
				headers: {
					Authorization: `Basic ${base64AuthString}`,
					'Content-Type': 'application/x-www-form-urlencoded',
				},
			});

			accessToken = response.data.access_token;
			tokenExpiry = new Date().getTime() + response.data.expires_in * 1000; // Setting the expiry time
			return accessToken;
		} catch (error) {
			console.error('Error fetching access token:', error);
		}
	}

	public getGettyEditorialImages = async (phrase: string, pageOffset: number) => {
		const currentTime = new Date().getTime();
		const displaySize = this.gettyFeatureConfig.displaySize;

		if (!accessToken || currentTime >= tokenExpiry) {
			await this.getAccessToken(); // Get a new token if the current one is expired or not set
		}
		try {
			const response = await axios.get(`https://api.gettyimages.com/v3/search/images/editorial`, {
				headers: {
					Authorization: `Bearer ${accessToken}`,
					'api-key': `${this.gettyFeatureConfig.apiKey}`,
				},
				params: {
					phrase: `${phrase}`,
					fields: `detail_set,${displaySize},download_sizes,product_types,display_set`,
					page: `${pageOffset}`,
					page_size: `20`,
				},
			});

			return response.data;
		} catch (error) {
			handleGettyImageApiError(error);
		}
	};

	public getGettyCreativeImages = async (phrase: string, pageOffset: number) => {
		const currentTime = new Date().getTime();
		const displaySize = this.gettyFeatureConfig.displaySize;

		if (!accessToken || currentTime >= tokenExpiry) {
			await this.getAccessToken(); // Get a new token if the current one is expired or not set
		}
		try {
			const response = await axios.get(`https://api.gettyimages.com/v3/search/images/creative`, {
				headers: {
					Authorization: `Bearer ${accessToken}`,
					'api-key': `${this.gettyFeatureConfig.apiKey}`,
				},
				params: {
					phrase: `${phrase}`,
					fields: `detail_set,${displaySize},download_sizes,product_types,display_set`,
					page: `${pageOffset}`,
					page_size: `20`,
				},
			});

			return response.data;
		} catch (error) {
			handleGettyImageApiError(error);
		}
	};

	public getGettyImageDetails = async (pictureId: string) => {
		const currentTime = new Date().getTime();

		if (!accessToken || currentTime >= tokenExpiry) {
			await this.getAccessToken(); // Get a new token if the current one is expired or not set
		}
		try {
			const response = await axios.get(`https://api.gettyimages.com/v3/images/${pictureId}`, {
				headers: {
					Authorization: `Bearer ${accessToken}`,
					'api-key': `${this.gettyFeatureConfig.apiKey}`,
				},
				params: {
					fields: `detail_set,download_sizes,display_sizes,product_types,display_set`,
				},
			});

			return response.data;
		} catch (error) {
			handleGettyImageApiError(error);
		}
	};

	public async downloadGettyImage(pictureId: string, downloadSize: string, productType: string) {
		const currentTime = new Date().getTime();
		let size = 0;

		if (!accessToken || currentTime >= tokenExpiry) {
			await this.getAccessToken(); // Get a new token if the current one is expired or not set
		}
		try {
			return await axios
				.get(`https://api.gettyimages.com/v3/images/${pictureId}`, {
					headers: {
						Authorization: `Bearer ${accessToken}`,
						'api-key': `${this.gettyFeatureConfig.apiKey}`,
					},
					params: {
						fields: `detail_set,download_sizes,display_sizes,product_types,display_set`,
					},
				})
				.then((res: any) => {
					res.data &&
						res.data.images[0].download_sizes.map((current: any) => {
							if (current.name === downloadSize) {
								size = current.height;
							}
						});

					try {
						return axios
							.post(
								`https://api.gettyimages.com/v3/downloads/images/${pictureId}?auto_download=false`,
								{},
								{
									headers: {
										'Api-Key': `${this.gettyFeatureConfig.apiKey}`,
										Authorization: `Bearer ${accessToken}`,
										'Content-Type': 'application/json',
									},
									params: {
										height: `${size}`,
										product_type: `${productType}`,
									},
								},
							)
							.then((res: any) => {
								const downloadUri = res.data.uri;
								return this.httpInstance.get(downloadUri, { responseType: 'blob' });
							});
					} catch (error) {
						handleGettyImageApiError(error);
					}
				});
		} catch (error) {
			handleGettyImageApiError(error);
		}
	}
}
