import Related from '../../models/related/Related';

export const ARTICLE_RELATED_TAG_TEMP_UPDATE = '[ACTION] RELATED_TAG_TEMP_UPDATE';
export const ARTICLE_EDIT_RELATED_TAG_TEMP_UPDATE = '[ACTION] ARTICLE_EDIT_RELATED_TAG_TEMP_UPDATE';
export const ARTICLE_RELATED_TAG_TEMP_CLEAR = '[ACTION] RELATED_TAG_TEMP_CLEAR';

export const VIDEO_RELATED_TAG_TEMP_UPDATE = '[ACTION] VIDEO_RELATED_TAG_TEMP_UPDATE';
export const VIDEO_EDIT_RELATED_TAG_TEMP_UPDATE = '[ACTION] VIDEO_EDIT_RELATED_TAG_TEMP_UPDATE';
export const VIDEO_RELATED_TAG_TEMP_CLEAR = '[ACTION] VIDEO_RELATED_TAG_TEMP_CLEAR';

export const GALLERY_RELATED_TAG_TEMP_UPDATE = '[ACTION] GALLERY_RELATED_TAG_TEMP_UPDATE';
export const GALLERY_EDIT_RELATED_TAG_TEMP_UPDATE = '[ACTION] GALLERY_EDIT_RELATED_TAG_TEMP_UPDATE';
export const GALLERY_RELATED_TAG_TEMP_CLEAR = '[ACTION] GALLERY_RELATED_TAG_TEMP_CLEAR';

export const WIKI_RELATED_TAG_TEMP_UPDATE = '[ACTION] WIKI_RELATED_TAG_TEMP_UPDATE';
export const WIKI_EDIT_RELATED_TAG_TEMP_UPDATE = '[ACTION] WIKI_EDIT_RELATED_TAG_TEMP_UPDATE';
export const WIKI_RELATED_TAG_TEMP_CLEAR = '[ACTION] WIKI_RELATED_TAG_TEMP_CLEAR';

export function updateArticleTagTempRelated(related: Related[]) {
	return {
		type: ARTICLE_RELATED_TAG_TEMP_UPDATE,
		payload: related,
	};
}

export function updateEditArticleTagTempRelated(related: Related[]) {
	return {
		type: ARTICLE_EDIT_RELATED_TAG_TEMP_UPDATE,
		payload: related,
	};
}

export function clearArticleTagTempRelated() {
	return {
		type: ARTICLE_RELATED_TAG_TEMP_CLEAR,
	};
}

export function updateVideoTagTempRelated(related: Related[]) {
	return {
		type: VIDEO_RELATED_TAG_TEMP_UPDATE,
		payload: related,
	};
}

export function updateVideoEditTagTempRelated(related: Related[]) {
	return {
		type: VIDEO_EDIT_RELATED_TAG_TEMP_UPDATE,
		payload: related,
	};
}

export function clearVideoTagTempRelated() {
	return {
		type: VIDEO_RELATED_TAG_TEMP_CLEAR,
	};
}

export function updateGalleryTagTempRelated(related: Related[]) {
	return {
		type: GALLERY_RELATED_TAG_TEMP_UPDATE,
		payload: related,
	};
}

export function updateGalleryEditTagTempRelated(related: Related[]) {
	return {
		type: GALLERY_EDIT_RELATED_TAG_TEMP_UPDATE,
		payload: related,
	};
}

export function clearGalleryTagTempRelated() {
	return {
		type: GALLERY_RELATED_TAG_TEMP_CLEAR,
	};
}

export function updateWikiTagTempRelated(related: Related[]) {
	return {
		type: WIKI_RELATED_TAG_TEMP_UPDATE,
		payload: related,
	};
}

export function updateEditWikiTagTempRelated(related: Related[]) {
	return {
		type: WIKI_EDIT_RELATED_TAG_TEMP_UPDATE,
		payload: related,
	};
}

export function clearWikiTagTempRelated() {
	return {
		type: WIKI_RELATED_TAG_TEMP_CLEAR,
	};
}
