import BlockModel from '../../../../../models/block.model';
import { ViewTypes } from '../../../../../constants/general.constants';
import React from 'react';
import BlockUpdateController from '../../../../block-update-controller.component';
import { compose } from 'redux';
import WidgetPlayerPredictionsEdit from './widget-player-predictions-edit.component';
import { WidgetPlayerPredictionsView } from './widget-player-predictions-view.component';

type Properties = {
	block: BlockModel;
	view: ViewTypes;
	t: any;
	lang: string;
	contentData: any;
	bookmakers: [];
};

const WidgetPlayerPredictions: React.FunctionComponent<Properties> = (props) => {
	if (props.view === ViewTypes.edit) {
		return <WidgetPlayerPredictionsEdit contentData={props.contentData} block={props.block} t={props.t} />;
	}

	if (props.view === ViewTypes.normal) {
		return (
			<WidgetPlayerPredictionsView
				widgetPlayerPredictions={props.block.data.widget}
				t={props.t}
				bookmakers={props.block.data.widget && props.block.data.widget.odds ? props.block.data.widget.odds : []}
			/>
		);
	}

	return null;
};

export default compose(BlockUpdateController)(WidgetPlayerPredictions);
