import { TypedJSON } from 'typedjson';
import AvailableStandingTypeModel from '../../../../../../models/v2/standing-types/available-standing-type.model';

enum STANDING_TYPES {
	ALL = 'ALL',
}

export const responseToStandingTypeModel = (response: any) => {
	const serializer = new TypedJSON(AvailableStandingTypeModel);
	const remmapedStandingType = serializer.parse(response) as AvailableStandingTypeModel;

	return remmapedStandingType;
};

export const standingTypesToOptions = (standingTypes: AvailableStandingTypeModel[]) => {
	if (standingTypes && standingTypes.length > 0) {
		const availableStandingTypes = standingTypes.map((standingType) => {
			return standingTypeToOption(standingType);
		});

		const allStandingTypes = {
			value: STANDING_TYPES.ALL,
			label: STANDING_TYPES.ALL,
			data: { id: availableStandingTypes[0].data ? availableStandingTypes[0].data.id : '', standingType: { name: STANDING_TYPES.ALL } },
		};

		return [allStandingTypes, ...availableStandingTypes];
	}

	return [];
};

export const standingTypeToOption = (availableStanding: AvailableStandingTypeModel) => {
	if (availableStanding && availableStanding.standingType) {
		return {
			value: availableStanding.standingType.id,
			label: availableStanding.standingType.name,
			data: availableStanding,
		};
	} else {
		return {};
	}
};
