import React, { FunctionComponent, useEffect } from 'react';
import ListSelect from './subcomponents/list-select/list-select';
import { Col, FormGroup, Row } from 'reactstrap';
import { DATA_QA_ATTRIBUTES } from './constants/data-qa.attributes';
import ListListingContainer from './subcomponents/list-listing/list-listing';
import ListPresentIn from './subcomponents/list-present-in/list-present-in';
import { updateListInRedux } from '../../../../store/action-creators/ListActionCreators';
import { connect } from 'react-redux';

type Properties = {
	t: any;
	resetListInRedux: Function;
};

const SidebarList: FunctionComponent<Properties> = ({ t, resetListInRedux }) => {
	useEffect(() => {
		return () => resetListInRedux();
	}, []);

	return (
		<div id={DATA_QA_ATTRIBUTES.LIST_CONTAINER}>
			<ListPresentIn t={t} />
			<Row>
				<Col>
					<FormGroup>
						<ListSelect t={t} />
						<ListListingContainer t={t} />
					</FormGroup>
				</Col>
			</Row>
		</div>
	);
};

function mapDispatchToProps(dispatch: Function) {
	return {
		resetListInRedux: () => dispatch(updateListInRedux(null)),
	};
}

export default connect(null, mapDispatchToProps)(SidebarList);
