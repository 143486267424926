import Related from '../../../../../../../models/related/Related';
import { relatedConstants } from '../../../../../../../constants/related.constants';
import { SearchContentModel } from '../../../../../Shared/search-content-by-type/models/search-content.model';

export function relatedToOption(related: Related) {
	if (related && related.data && Object.entries(related).length > 0) {
		return { value: related.data.id, label: related.data.title, type: relatedConstants.types.tag, data: related.data };
	}

	return {};
}

export function relatedToOptions(relatedContent: Related[]) {
	let options: any[] = [];

	if (relatedContent && relatedContent.length > 0) {
		options = relatedContent
			.filter((related: Related) => related && related.type === relatedConstants.types.tag)
			.map((related: Related) => relatedToOption(related));
	}

	return options;
}

export function responseToOption(response: any) {
	if (response && Object.entries(response).length > 0) {
		return { value: response.id, label: response.title, type: relatedConstants.types.tag, data: response };
	}

	return {};
}

export function optionToRelated(option: any): Related {
	return Related.builder().withType(option.type).withProvider(relatedConstants.providers.smp).withData(option.data).build();
}

export function optionToSearchContentModel(option: any): SearchContentModel {
	if (
		option.type === 'team' ||
		option.type === 'player' ||
		option.type === 'tournament' ||
		option.type === 'coach' ||
		option.type === 'venue' ||
		option.type === 'president' ||
		option.type === 'competition'
	) {
		return {
			id: option.data.id,
			title: option.data.name,
			type: option.type,
			data: option.data,
		};
	} else {
		return {
			id: option.data.id,
			title: option.data.title,
			type: option.type,
			data: option.data,
		};
	}
}
