import React, { ChangeEvent, FunctionComponent, useEffect, useState } from 'react';
import {
	autoGenerateReduxPropsNamingForContentModels,
	availableContentTypes,
} from '../../../../../../../services/content-models-service/ContentModelService';
import Related from '../../../../../../../models/related/Related';
import { extractRelatedPropertiesNameByUrl } from '../../../../tags-refactored/helpers/utils';
import { connect } from 'react-redux';
import { requestSearchedRelatedResources } from '../../../helpers/request.helper';
import RelatedContentList from '../listing';
import { FormGroup } from 'reactstrap';
import { DATA_QA_ATTRIBUTES } from '../../../constants/data-qa.attributes';
import { DebounceInput } from 'react-debounce-input';
import PaginationMeta from '../../../../../../../models/v2/Pagination/PaginationModel';
import RelatedContentPagination from '../pagination';

type Properties = {
	t: any;
	searchType: availableContentTypes;
	language: string;
	reduxSportConnections: Related[];
	reduxEventsRelated: Related[];
	projectDomain: string;
};

const SearchInsideTab: FunctionComponent<Properties> = ({ t, searchType, projectDomain, language }) => {
	const [isContentLoading, setIsContentLoading] = useState(false);
	const [searchedText, setSearchedText] = useState('');
	const [pagination, setPagination] = useState<PaginationMeta>(new PaginationMeta());
	const [relatedData, setRelatedData] = useState<Related[]>([]);

	useEffect(() => {
		setIsContentLoading(true);
		const header = { Project: projectDomain };
		requestSearchedRelatedResources(searchType, searchedText, header, language, pagination.currentPage)
			.then(({ related, pagination }) => {
				setRelatedData(related);
				setPagination(pagination);
				setIsContentLoading(false);
			})
			.catch(() => setIsContentLoading(false));
	}, [searchType, searchedText, pagination.currentPage]);

	const onSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
		const inputText = event.target.value || '';
		setSearchedText(inputText);
	};

	const onPageChange = (selectedPage: number) => setPagination({ ...pagination, currentPage: selectedPage });

	return (
		<div className={`${isContentLoading ? 'loading-overlay' : ''}`}>
			<FormGroup>
				<DebounceInput
					id={DATA_QA_ATTRIBUTES.SEARCH_INPUT}
					debounceTimeout={300}
					type='text'
					className='form-control'
					placeholder={t('search')}
					value={searchedText}
					onChange={onSearchChange}
				/>
			</FormGroup>
			<RelatedContentList t={t} relatedData={relatedData} />
			<RelatedContentPagination t={t} pagination={pagination} onPageChange={onPageChange} />
		</div>
	);
};

function mapStateToProps(state: any) {
	const { tempPropertyNaming, nestedTempPropertyNaming } = autoGenerateReduxPropsNamingForContentModels();
	const nestedReduxPropertyName = extractRelatedPropertiesNameByUrl();

	return {
		contentModelId: state[tempPropertyNaming][nestedTempPropertyNaming].id || '',
		language: state.project.currentProject.language || 'en',
		projectDomain: state.project.currentProject.domain || '',
		reduxSportConnections: (nestedReduxPropertyName && state.tempSportsRelated[nestedReduxPropertyName]) || [],
		reduxEventsRelated: (nestedReduxPropertyName && state.tempMatchRelated[nestedReduxPropertyName]) || [],
		alreadyAddedRelatedContentRedux: (nestedReduxPropertyName && state.tempContentRelated[nestedReduxPropertyName]) || [],
	};
}

export default connect(mapStateToProps)(SearchInsideTab);
