import React from 'react';
import SearchContentByTagsOrSportsConnections from './search-content-by-tags-or-sports-connections';
import { Properties, State } from './properties/search-content-by-type-container.properties';
import {
	relatedTypesMap,
	responseSportsConnectionsToOptions,
	responseTagsToOptions,
} from './helpers/search-content-by-type-container.helper';
import { optionToSearchContentModel } from '../../Sidebar/tags/subcomponents/content-tags/helpers/content-tags-container.helper';
import { analyticsService, featuresService, sportsConnectionsService } from '../../../../App';
import { DebounceInput } from 'react-debounce-input';
import ModelMapper from '../../../../models/ModelMapper';
import PaginationMeta from '../../../../models/pagination/PaginationMeta';
import SearchOptionsContainer from './search-options-container';
import Loader from 'react-spinners/BeatLoader';
import SuggestedListTagsOrSportsConnectionsOptions from './suggested-list-tags-or-sports-connections.container';
import { SportsTypeSelect } from '../advanced-content-filter/subcomponents/v2/sports-select/sports-type-select.component';
import { requests } from './helpers/tags-and-sports-connections-search.helper';
import SportsTypesModel from '../../../../models/v2/sports-types/sports-types.model';
import EntityTypesModel from '../../../../models/v2/sports-types/entity-types.model';
import { excludeEntityTypes } from '../advanced-content-filter/subcomponents/v2/sports-select/sports-connections.helper';
import { FeatureTypes } from '../../../../services/feature-service/features.enum';

class SearchContentByTagsOrSportsConnectionsContainer extends React.Component<Properties, State> {
	constructor(props: Properties) {
		super(props);
		this.state = {
			searchType: 'tags',
			sportType: featuresService.areFeaturesEnabled([FeatureTypes.FOOTBALL, FeatureTypes.FOOTBALL_CONNECTIONS])
				? this.props.sports[0]
				: new SportsTypesModel(),
			options: [],
			inputValue: '',
			isLoading: false,
			currentPage: 1,
			pagination: PaginationMeta.builder().build(),
		};
	}

	componentDidUpdate(prevProps: Readonly<Properties>, prevState: Readonly<State>, snapshot?: any) {
		if (this.state.sportType !== prevState.sportType) {
			this.setState((state: State) => {
				return { ...state, isLoading: false, options: [], currentPage: 1 };
			});
		}
	}

	onInputChange = (e: any) => {
		this.setState({ ...this.state, inputValue: e.target.value });
		if (this.state.sportType.sport !== '') {
			this.requestContent(this.state.currentPage, e.target.value);
		}
	};

	requestContent = async (page: number, search: string) => {
		const header = { Project: this.props.currentProject.domain };
		this.setState((state: State) => {
			return { ...state, isLoading: true };
		});

		let availableEntities: any = [];
		let entities = excludeEntityTypes(this.state.sportType.entity_types, true);
		entities &&
			entities.map((entity: EntityTypesModel) => {
				return availableEntities.push(entity.name);
			});

		if (search.length > 3) {
			this.state.searchType === relatedTypesMap.tags + 's'
				? await requests[this.state.searchType](page, search, header)
						.then((response: any) => {
							const pagination = ModelMapper.remapMetaPagination(response.data.meta.pagination);
							const options = responseTagsToOptions(response.data.data, relatedTypesMap[this.state.searchType]);
							this.setState((state: State) => {
								return { ...state, options, isLoading: false, currentPage: page, pagination };
							});
						})
						.catch(() => this.resetState())
				: await requests[this.state.searchType](
						search,
						this.state.sportType.sport,
						this.props.list.language,
						this.state.sportType.sport,
						availableEntities,
				  )
						.then((response: any) => {
							const options = responseSportsConnectionsToOptions(
								sportsConnectionsService.isFootballSelected(this.state.sportType.sport) ? response.data : response.data.results,
								relatedTypesMap[this.state.searchType],
							);
							this.setState((state: State) => {
								return { ...state, options, isLoading: false, currentPage: page };
							});
						})
						.catch(() => this.resetState());
		} else this.resetState();
	};

	resetState = () => {
		this.setState((state: State) => {
			return { ...state, isLoading: false, options: [], currentPage: 1 };
		});
	};

	onSelect = (selection: string) => {
		this.setState({ ...this.state, searchType: selection, options: [], currentPage: 1, inputValue: '' }, () => {
			this.state.inputValue.length > 0 && this.requestContent(this.state.currentPage, this.state.inputValue);
		});
	};

	onSportsTypeSelect = (type: any) => {
		this.setState({ sportType: type, inputValue: '' });
	};

	onRelatedContentSelect = (optionSelected: any, addItemToTop?: boolean) => {
		if (this.props.onRelatedContentSelect) {
			this.props.onRelatedContentSelect(optionToSearchContentModel(optionSelected), addItemToTop);
		}
	};

	onContentSelect = (optionSelected: any) => {
		if (this.props.onContentSelect) {
			this.props.onContentSelect(optionToSearchContentModel(optionSelected));
		}
		analyticsService.sendEvent('Added from search', 'Sidebar - Related content', optionSelected.type);
	};

	render() {
		const { searchType, inputValue, isLoading, options, currentPage, pagination, sportType } = this.state;
		return (
			<>
				<div className='position-relative mb-2'>
					<DebounceInput
						type='text'
						id={`search-content-by-tags-or-sports-connections-${searchType}`}
						value={inputValue}
						onChange={this.onInputChange}
						debounceTimeout={500}
						className='form-control'
						placeholder={this.props.t('search')}
						required
					/>
					{isLoading && (
						<div className='position-absolute h-100 d-flex align-items-center opacity-03' style={{ top: '0', right: '10px' }}>
							<Loader size={5} />
						</div>
					)}
				</div>
				<SearchContentByTagsOrSportsConnections t={this.props.t} type={searchType} onSearchTypeSelect={this.onSelect} />
				{searchType === relatedTypesMap.sports_connections && (
					<SportsTypeSelect
						sports={this.props.sports}
						selectedSportsType={sportType}
						onSportsTypeSelect={this.onSportsTypeSelect}
						t={this.props.t}
					/>
				)}
				<SearchOptionsContainer
					listContent={this.props.listContent}
					listItems={this.props.list ? this.props.list.items : []}
					options={options}
					isLoading={isLoading}
					currentPage={currentPage}
					pagination={pagination}
					value={inputValue}
					t={this.props.t}
					requestContent={this.requestContent}
					onRelatedContentSelect={this.onRelatedContentSelect}
					list={this.props.list}
					toggleFirstLockPositionError={this.props.toggleFirstLockPositionError}
					showAddToTopToggle={this.props.showAddToTopToggle}
					type={searchType}
				/>
				{this.props.listContent && searchType === relatedTypesMap.tags + 's' && (
					<SuggestedListTagsOrSportsConnectionsOptions
						listItems={this.props.list.items ? this.props.list.items : []}
						list={this.props.list}
						t={this.props.t}
						onRelatedContentSelect={this.onRelatedContentSelect}
						domain={this.props.currentProject.domain}
						type={searchType}
						toggleFirstLockPositionError={this.props.toggleFirstLockPositionError}
						showAddToTopToggle={this.props.showAddToTopToggle}
						contentLanguage={this.props.list.language}
					/>
				)}
			</>
		);
	}
}

export default SearchContentByTagsOrSportsConnectionsContainer;
