import { USER_LOGOUT } from '../action-creators/ProfileActionCreators';
import Video from '../../models/video/Video';
import {
	VIDEO_TEMP_CLEAR,
	VIDEO_TEMP_EDIT_UPDATE,
	VIDEO_TEMP_PROPERTY_UPDATE,
	VIDEO_TEMP_UPDATE,
} from '../action-creators/VideoTempActionCreator';
import { CONTENT_STATISTICS_VIDEO_ENTITY_RECEIVED, VIDEO_CREATE_SUCCESS, VIDEO_RECEIVED } from '../action-creators/VideoActionCreator';
import { PROJECT_RESOURCES_REQUEST } from '../../constants/constants';
import { createStartingEntity } from '../../services/content-models-service/ContentModelService';
import { ContentMode, ContentTypes } from '../../constants/content-types';

interface InitialState {
	video: Video;
	videoEdit: Video;
}
const initialState: InitialState = {
	video: createStartingEntity(ContentTypes.VIDEO, true) as Video,
	videoEdit: Video.builder().build(),
};

function videoTempReducer(state: any = initialState, action: any) {
	if (action.type === PROJECT_RESOURCES_REQUEST) {
		return Object.assign({}, state, initialState);
	}

	if (action.type === VIDEO_TEMP_UPDATE) {
		return Object.assign({}, state, {
			video: action.payload,
		});
	}

	if (action.type === VIDEO_TEMP_CLEAR || action.type === VIDEO_CREATE_SUCCESS) {
		return initialState;
	}

	if (action.type === VIDEO_TEMP_EDIT_UPDATE) {
		return Object.assign({}, state, {
			videoEdit: action.payload,
		});
	}

	if (action.type === VIDEO_TEMP_PROPERTY_UPDATE) {
		const propertyName = `video${action.contentMode === ContentMode.EDIT ? 'Edit' : ''}`;

		return Object.assign({}, state, {
			[propertyName]: { ...state[propertyName], ...action.payload },
		});
	}

	if (action.type === VIDEO_RECEIVED) {
		return Object.assign({}, state, {
			videoEdit: action.payload.videoEdit,
		});
	}

	if (action.type === CONTENT_STATISTICS_VIDEO_ENTITY_RECEIVED) {
		return Object.assign({}, state, {
			videoEdit: action.payload.video,
		});
	}

	if (action.type === USER_LOGOUT) {
		state = initialState;
	}

	return state;
}

export default videoTempReducer;
