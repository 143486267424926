import React from 'react';
import TeamSelect from '../../../../partials/team-select/team-select.component';
import TeamModel from '../../../../partials/team-select/models/team.model';
import { blockManagementService, blockWrapperService } from '../../../../subcomponents/blocky.component';
import BlockValidation from '../../../../helpers/block-validation.helper';
import { Col, FormGroup, Label, Row, Input } from 'reactstrap';
import { Properties, State } from './properties/squad-statistics-edit.properties';
import SquadStatisticsWidgetModel from '../models/squad-statistics-widget.model';
import PlayerPositionMultiSelect from '../../../../partials/player-position-multi-select/player-position-multi-select.component';
import StatisticSquadColumnsSelect from '../../../../partials/statistic-columns-squad-select/statistic-columns-squad.component';
import { statisticColumnsByPosition, defaultValue, removeFromState, updateColumns } from './helpers/squad-statistics-edit.helper';
import TeamSeason from '../../../../partials/team-seasons/team-season.component';
import SeasonModel from '../../player-profile/models/season/season.model';

export default class SquadStatisticsEdit extends React.Component<Properties, State> {
	constructor(props: Properties) {
		super(props);

		this.state = {
			squadStatistics:
				props.block && props.block.data && props.block.data.preview
					? SquadStatisticsWidgetModel.builder(props.block.data.preview.squadStatistics).build()
					: SquadStatisticsWidgetModel.builder()
							.withTeam(TeamModel.builder().build())
							.withSeason(SeasonModel.builder().build())
							.withPlayerPosition([])
							.withStatisticColumns(statisticColumnsByPosition(this.props.t))
							.build(),
			isValid: true,
		};
	}

	componentDidMount(): void {
		blockWrapperService.registerOnSaveFunc({ blockId: this.props.block.id, func: this.onSave });
	}

	componentDidUpdate(prevProps: Properties, prevState: State) {
		if (this.state.squadStatistics.playerPosition !== prevState.squadStatistics.playerPosition) {
			if (this.state.squadStatistics.playerPosition.length < prevState.squadStatistics.playerPosition.length) {
				const player = removeFromState(prevState.squadStatistics.playerPosition, this.state.squadStatistics.playerPosition);
				if (player && player[0]) {
					this.onStatisticsColumnsChange([], player[0].value);
				}
			}
		}
	}

	onSave = (index: number) => {
		const data = { squadStatistics: this.state.squadStatistics };
		const validation = new BlockValidation();
		const isValid = validation.validate(this.props.block, data);
		this.setState({ ...this.state, isValid: isValid });

		if (isValid) {
			const block = Object.assign({}, this.props.block);
			block.data.preview = { squadStatistics: this.state.squadStatistics };

			blockManagementService.blockUpdate(block, index);
		} else {
			return isValid;
		}
	};

	componentWillUnmount(): void {
		blockWrapperService.unregisterOnSaveFunc(this.props.block.id);
	}

	onTeamChange = (team: TeamModel) => {
		this.setState({
			...this.state,
			squadStatistics: SquadStatisticsWidgetModel.builder(this.state.squadStatistics)
				.withTeam(team)
				.withSeason(SeasonModel.builder().build())
				.withPlayerPosition([])
				.withStatisticColumns(statisticColumnsByPosition(this.props.t))
				.build(),
			isValid: true,
		});
	};

	onSeasonSelect = (season: SeasonModel) => {
		this.setState({
			...this.state,
			squadStatistics: SquadStatisticsWidgetModel.builder(this.state.squadStatistics).withSeason(season).build(),
			isValid: true,
		});
	};

	onPlayerPositionChange = (playerPosition: any[]) => {
		playerPosition = playerPosition ? playerPosition : [];

		this.setState({
			...this.state,
			squadStatistics: SquadStatisticsWidgetModel.builder(this.state.squadStatistics).withPlayerPosition(playerPosition).build(),
		});
	};

	onStatisticsColumnsChange = (statisticColumns: any, playerPosition: string) => {
		const { squadStatistics } = this.state;
		statisticColumns = statisticColumns ? defaultValue(this.props.t).concat(statisticColumns) : defaultValue(this.props.t);

		let columns = updateColumns(squadStatistics.statisticColumns, playerPosition, statisticColumns);

		this.setState({
			...this.state,
			squadStatistics: SquadStatisticsWidgetModel.builder(squadStatistics).withStatisticColumns(columns).build(),
		});
	};

	render() {
		const { squadStatistics } = this.state;
		const { t } = this.props;

		return (
			<>
				<Row>
					<Col>
						<FormGroup>
							<TeamSelect
								inputId='squad-statistics-team-select'
								isMulti={false}
								t={t}
								value={squadStatistics.team ? squadStatistics.team : {}}
								onSelect={(team: TeamModel) => this.onTeamChange(team)}
								isValid={this.state.isValid}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					<Col>
						<FormGroup>
							<TeamSeason
								isValid={this.state.isValid}
								selectedSeason={squadStatistics.season}
								teamId={squadStatistics.team && squadStatistics.team.id ? squadStatistics.team.id : ''}
								onSeasonSelect={this.onSeasonSelect}
								t={t}
							/>
						</FormGroup>
					</Col>
				</Row>
				{squadStatistics.team.id && (
					<Row>
						<Col>
							<FormGroup>
								<Label>{t('select_position')}</Label>

								<PlayerPositionMultiSelect
									t={this.props.t}
									isMulti={true}
									value={squadStatistics.playerPosition ? squadStatistics.playerPosition : []}
									onPlayerPositionSelect={this.onPlayerPositionChange}
								/>
							</FormGroup>
						</Col>
					</Row>
				)}

				{squadStatistics.team.id &&
					squadStatistics.playerPosition &&
					squadStatistics.playerPosition.length > 0 &&
					squadStatistics.statisticColumns &&
					squadStatistics.statisticColumns.length > 0 &&
					squadStatistics.playerPosition.map((position) => {
						return squadStatistics.statisticColumns.map((item, index) => {
							if (item.position === position.value) {
								return (
									<Row key={index}>
										<Col>
											<FormGroup>
												<Label>{`${t('statistic_columns')}: ${t(item.position).toLowerCase()}`}</Label>

												<StatisticSquadColumnsSelect
													t={this.props.t}
													isMulti={true}
													value={
														squadStatistics.statisticColumns[index].columns.length > 0 ? squadStatistics.statisticColumns[index].columns : defaultValue(t)
													}
													onStatisticsColumnsSelect={(selection: any[], position: string) => this.onStatisticsColumnsChange(selection, item.position)}
													playerPosition={item.position}
												/>
											</FormGroup>
										</Col>
									</Row>
								);
							}
						});
					})}
			</>
		);
	}
}
