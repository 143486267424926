import WatermarkBuilder from './WatermarkBuilder';
import { WatermarkJson } from './WatermarkJson';

export default class Watermark {
	readonly id: string;
	readonly name: string;
	readonly opacity: string;
	readonly position: string;
	readonly project: string;
	readonly watermarkImagePath: string;

	private constructor(id: string, name: string, opacity: string, position: string, project: string, watermarkImagePath: string) {
		this.id = id;
		this.name = name;
		this.opacity = opacity;
		this.position = position;
		this.project = project;
		this.watermarkImagePath = watermarkImagePath;
	}

	toJSON(): WatermarkJson {
		return {
			id: this.id,
			name: this.name,
			opacity: this.opacity,
			position: this.position,
			project: this.project,
			watermarkImagePath: this.watermarkImagePath,
		};
	}

	static fromJSON(json: WatermarkJson): Watermark {
		return new Watermark(json.id, json.name, json.opacity, json.position, json.project, json.watermarkImagePath);
	}

	static builder(watermark?: Watermark): WatermarkBuilder {
		return new WatermarkBuilder(watermark);
	}
}
