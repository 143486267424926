import * as React from 'react';
import { Link } from 'react-router-dom';
import { ContentTypes } from '../../../../../../constants/content-types';
import './EditPermissions.scss';
import ConditionalRenderWrapper from '../../../ConditionalRenderWraper/ConditionalRenderWrapper';

type Properties = {
	t: any;
	analytics: any;
	contentType: ContentTypes;
};

export const CategoryEditPermissions: React.FunctionComponent<Properties> = ({ t, analytics, contentType }) => {
	if (contentType !== ContentTypes.CATEGORY) {
		return null;
	}

	return (
		<ConditionalRenderWrapper expectedPermissions={['write_categories']}>
			<div className='d-flex flex-row justify-content-end align-items-center'>
				<Link
					to={'/smp/categories/permissions'}
					id='content-permissions-button'
					className='btn btn-primary w-150'
					style={{ maxWidth: '150px' }}
					onClick={() => {
						analytics.sendEvent('Click', 'List Page Categories', 'Edit permissions');
					}}
				>
					<i className={'fa fa-plus-circle'}>&nbsp;</i>
					{t('category_edit_permissions_title')}
				</Link>
			</div>
		</ConditionalRenderWrapper>
	);
};
