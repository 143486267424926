import { SeasonJson } from './season.json';
import SeasonBuilder from './season.builder';

export default class SeasonModel {
	readonly id: string;
	readonly name: string;
	readonly stage: {};
	readonly active: boolean;

	private constructor(id: string, name: string, stage: {}, active: boolean) {
		this.id = id;
		this.name = name;
		this.stage = stage;
		this.active = active;
	}

	toJson(): SeasonJson {
		return {
			id: this.id,
			name: this.name,
			stage: this.stage,
			active: this.active,
		};
	}

	static fromJson(json: SeasonJson): SeasonModel {
		return new SeasonModel(json.id, json.name, json.stage, json.active);
	}

	static builder(season?: SeasonModel): SeasonBuilder {
		return new SeasonBuilder(season);
	}
}
