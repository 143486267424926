import { relatedConstants } from '../../../../../constants/related.constants';
import Related from '../../../../../models/related/Related';
import { responseToModelTags } from '../../../../../models/v2/tag/tag.mapper';
import TagModel from '../../../../../models/v2/tag/tag.model';
import {
	updateArticleTagTempRelated,
	updateEditArticleTagTempRelated,
	updateEditWikiTagTempRelated,
	updateGalleryEditTagTempRelated,
	updateGalleryTagTempRelated,
	updateVideoEditTagTempRelated,
	updateVideoTagTempRelated,
	updateWikiTagTempRelated,
} from '../../../../../store/action-creators/RelatedTagsTempActionCreator';
import { ReduxRelatedProperties } from '../constants/redux-related-properties';
import { extractRelatedPropertiesNameByUrl } from './utils';

export const remapTagModelsToReduxRelated = (tags: TagModel[] | null): Related[] => {
	if (!tags || tags.length < 1) {
		return [];
	} else {
		return tags.map((tag) => {
			return { provider: relatedConstants.providers.smp, type: tag.entity_type, data: tag } as Related;
		});
	}
};

export const tagsResponseToOptions = (tags: TagModel[] | null) => {
	if (tags && tags.length > 0) {
		return tags.map((tag: TagModel) => {
			return { value: tag.id, label: tag.title, data: tag };
		});
	}

	return null;
};

// Redux store the data in the following format:
// { type: 'tag', provider: 'smp', data: TagResponse (but not as model) }
export const remapReduxTagsPropertyToTagModel = (reduxData: any): TagModel[] | null => {
	const nestedReduxPropertyName = extractRelatedPropertiesNameByUrl();
	const nestedReduxPropertyValue = nestedReduxPropertyName && reduxData[nestedReduxPropertyName];
	if (reduxData && nestedReduxPropertyValue && nestedReduxPropertyValue.length > 0) {
		return nestedReduxPropertyValue.map(({ data }: any) => responseToModelTags(data));
	}
	return null;
};

// The 2 methods below extracts the action that should be dispatched to update the football connection data
// Method 1
const extractCorrectDispatchMethodByPropertyName = (propertyName: string | null) => {
	switch (propertyName) {
		case ReduxRelatedProperties.articleEditRelated:
			return (relatedData: Related[]) => updateEditArticleTagTempRelated(relatedData);
		case ReduxRelatedProperties.articleRelated:
			return (relatedData: Related[]) => updateArticleTagTempRelated(relatedData);
		case ReduxRelatedProperties.videoEditRelated:
			return (relatedData: Related[]) => updateVideoEditTagTempRelated(relatedData);
		case ReduxRelatedProperties.videoRelated:
			return (relatedData: Related[]) => updateVideoTagTempRelated(relatedData);
		case ReduxRelatedProperties.galleryEditRelated:
			return (relatedData: Related[]) => updateGalleryEditTagTempRelated(relatedData);
		case ReduxRelatedProperties.galleryRelated:
			return (relatedData: Related[]) => updateGalleryTagTempRelated(relatedData);
		case ReduxRelatedProperties.wikiEditRelated:
			return (relatedData: Related[]) => updateEditWikiTagTempRelated(relatedData);
		case ReduxRelatedProperties.wikiRelated:
			return (relatedData: Related[]) => updateWikiTagTempRelated(relatedData);
		default:
			return null;
	}
};

// Method 2
export const generateFunctionForDispatch = () => {
	const nestedReduxPropertyName = extractRelatedPropertiesNameByUrl();
	const functionForDispatch = extractCorrectDispatchMethodByPropertyName(nestedReduxPropertyName);
	return functionForDispatch;
};
