import { BehaviorSubject, Subject } from 'rxjs';
import { BlockAddOrUpdate } from './block-management.service';

export const ALL_OCCURRENCES = false;
export const FIRST_OCCURRENCES = true;

export default class AutoTagService {
	readonly autoTagSubject: Subject<any> = {} as Subject<any>;

	constructor() {
		this.autoTagSubject = new Subject<any>();
	}
}
