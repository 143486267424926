import {
	REQUEST_LIST_STATUSES_RECEIVED,
	REQUEST_GALLERY_STATUSES_RECEIVED,
	REQUEST_POLLS_STATUSES_RECEIVED,
	REQUEST_ARTICLE_STATUSES_RECEIVED,
	REQUEST_VIDEO_STATUSES_RECEIVED,
	REQUEST_WIKI_STATUSES_RECEIVED,
	REQUEST_BANNER_STATUSES_RECEIVED,
} from '../action-creators/status-action-creator';
import { remapContentItemsFromResponse } from '../../views/Partials/Sidebar/GeneralContentAttributes/subcomponents/type-select/helpers/type-select.helper';

export function statusesMiddleware() {
	return function (next: any) {
		return function (action: any) {
			if (action) {
				if (action.type === REQUEST_ARTICLE_STATUSES_RECEIVED) {
					action.payload = action.payload.data.data.map((item: any) => remapContentItemsFromResponse(item));
				}

				if (action.type === REQUEST_VIDEO_STATUSES_RECEIVED) {
					action.payload = action.payload.data.data.map((item: any) => remapContentItemsFromResponse(item));
				}

				if (action.type === REQUEST_POLLS_STATUSES_RECEIVED) {
					action.payload = action.payload.data.data.map((item: any) => remapContentItemsFromResponse(item));
				}

				if (action.type === REQUEST_GALLERY_STATUSES_RECEIVED) {
					action.payload = action.payload.data.data.map((item: any) => remapContentItemsFromResponse(item));
				}

				if (action.type === REQUEST_WIKI_STATUSES_RECEIVED) {
					action.payload = action.payload.data.data.map((item: any) => remapContentItemsFromResponse(item));
				}

				if (action.type === REQUEST_LIST_STATUSES_RECEIVED) {
					action.payload = action.payload.data.data.map((item: any) => remapContentItemsFromResponse(item));
				}

				if (action.type === REQUEST_BANNER_STATUSES_RECEIVED) {
					action.payload = action.payload.data.data.map((item: any) => remapContentItemsFromResponse(item));
				}
			}

			next(action);
		};
	};
}
