import CompetitionModel from '../../../../../../../models/v2/competition/entity/competition.model';
import TennisCompetitionSeasonModel from '../../../../../../../models/v2/tennis-competition-season/entity/tennis-competition-season.model';
import SportOddsBookmakerModel from '../../../../../../../models/sport-odds/sport-odds-bookmaker.model';
import TennisTournamentModel from '../../../../../../../models/v2/tennis-tournament/tennis-tournament.model';
import TennisRoundModel from '../../../../../../../models/v2/round/tennis-round/tennis-round.model';
import { TennisAthleteProgrammeJson } from './tennis-athlete-programme.json';
import TennisAthleteProgrammeBuilder from './tennis-athlete-programme.builder';
import PlayerModel from '../../../../../../../models/v2/player/entity/player.model';
import { RefreshTimeValuesType } from '../../../partials/refresh-time-input/helper';

export default class TennisAthleteProgrammeModel {
	readonly athlete: PlayerModel;
	readonly competition: CompetitionModel;
	readonly season: TennisCompetitionSeasonModel;
	readonly tournament: TennisTournamentModel;
	readonly tournaments: TennisTournamentModel[];
	readonly rounds: TennisRoundModel[];
	readonly availableRounds: TennisRoundModel[];
	readonly sortDirectionFixtures: string;
	readonly sortDirectionResults: string;
	readonly displayOdds: boolean = false;
	readonly bookmakers: SportOddsBookmakerModel[] | null;
	readonly refreshTime: RefreshTimeValuesType;

	private constructor(
		athlete: PlayerModel,
		competition: CompetitionModel,
		season: TennisCompetitionSeasonModel,
		tournament: TennisTournamentModel,
		tournaments: TennisTournamentModel[],
		rounds: TennisRoundModel[],
		availableRounds: TennisRoundModel[],
		sortDirectionFixtures: string,
		sortDirectionResults: string,
		displayOdds: boolean,
		bookmakers: SportOddsBookmakerModel[] | null,
		refreshTime: RefreshTimeValuesType,
	) {
		this.athlete = athlete;
		this.competition = competition;
		this.season = season;
		this.tournament = tournament;
		this.tournaments = tournaments;
		this.rounds = rounds;
		this.availableRounds = availableRounds;
		this.sortDirectionFixtures = sortDirectionFixtures;
		this.sortDirectionResults = sortDirectionResults;
		this.displayOdds = displayOdds;
		this.bookmakers = bookmakers;
		this.refreshTime = refreshTime;
	}

	toJson(): TennisAthleteProgrammeJson {
		return {
			athlete: this.athlete,
			competition: this.competition,
			season: this.season,
			tournament: this.tournament,
			tournaments: this.tournaments,
			rounds: this.rounds,
			availableRounds: this.availableRounds,
			sortDirectionFixtures: this.sortDirectionFixtures,
			sortDirectionResults: this.sortDirectionResults,
			displayOdds: this.displayOdds,
			bookmakers: this.bookmakers,
			refreshTime: this.refreshTime,
		};
	}

	static fromJson(json: TennisAthleteProgrammeJson): TennisAthleteProgrammeModel {
		return new TennisAthleteProgrammeModel(
			json.athlete,
			json.competition,
			json.season,
			json.tournament,
			json.tournaments,
			json.rounds,
			json.availableRounds,
			json.sortDirectionFixtures,
			json.sortDirectionResults,
			json.displayOdds,
			json.bookmakers,
			json.refreshTime,
		);
	}

	static builder(athleteProgrammeWidget?: TennisAthleteProgrammeModel): TennisAthleteProgrammeBuilder {
		return new TennisAthleteProgrammeBuilder(athleteProgrammeWidget);
	}
}
