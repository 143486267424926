import React from 'react';
import { SelectMenuOptionType } from '../../../../models/v2/general/select.model';
import { FormatOptionLabelMeta } from 'react-select/src/Select';
import { countryPhoneData } from './data';

export const customStyles = {
	control: (provided: Record<string, string>) => ({
		...provided,
		minHeight: '36px',
		height: '36px',
	}),
	valueContainer: (provided: Record<string, string>) => ({
		...provided,
		height: '36px',
	}),
};

export const formatLabel = (option: SelectMenuOptionType, labelMeta: FormatOptionLabelMeta<SelectMenuOptionType>) => {
	const isMenuOption = labelMeta.context === 'menu';
	const wrapperClass = isMenuOption ? 'custom-select-option' : 'custom-select-option selected-option';
	const imageClass = isMenuOption ? 'image-option-preview' : 'image-preview';
	const optionTextClass = isMenuOption ? 'option-text' : 'preview-option-text';

	return (
		<div className={wrapperClass} key={option.value} title={option.label}>
			{option.logo && <div className={imageClass} style={{ backgroundImage: `url(${option.logo})` }} />}
			<span className={optionTextClass}>{isMenuOption ? option.label : option.data.country_code}</span>
		</div>
	);
};

const emptyOption = {
	label: 'No country',
	value: '',
	logo: '',
	data: {
		country_iso_code: '',
		country_code: '',
	},
};

const optionsData: SelectMenuOptionType[] = Object.keys(countryPhoneData).map((countryPhoneDataKey) => {
	const countryPhoneObj = countryPhoneData[countryPhoneDataKey];

	return {
		label: countryPhoneObj.label,
		value: countryPhoneObj.country_iso_code,
		logo: `https://purecatamphetamine.github.io/country-flag-icons/3x2/${countryPhoneObj.country_iso_code}.svg`,
		data: {
			country_iso_code: countryPhoneObj.country_iso_code,
			country_code: countryPhoneObj.country_code,
		},
	};
});

export const options: SelectMenuOptionType[] = [emptyOption, ...optionsData];
