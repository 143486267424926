import { extractContentModeBasedOnUrl, extractMainContentTypeBasedOnUrl } from '../../global-helpers/global.helpers';
import { store } from '../../store/store';
import { FansUnitedWidgetsTypes, WidgetTypes, WidgetTypesV2 } from '../../views/Partials/Blocky/constants/block.types';
import { generateReduxPropsNamingForContentModels } from '../content-models-service/ContentModelService';

type WidgetAnalyticData = {
	widgetVersion: string;
	widgetType: string;
	sport: string;
};

export type WidgetAnalytic = {
	contentType: string;
	contentTypeId: string;
	contentStatus: string;
	projectName: string;
	userId: string;
	userEmail: string;
	widgetsAnalytics: Record<string, string>;
	widgetsCount: number;
};

export const getAnalyticsData = (): WidgetAnalytic | null => {
	const storeState = store.getState();
	const contentType = extractMainContentTypeBasedOnUrl();
	const contentMode = extractContentModeBasedOnUrl();

	if (storeState && contentType && contentMode) {
		const { tempPropertyNaming, nestedTempPropertyNaming } = generateReduxPropsNamingForContentModels(contentType, contentMode);
		const contentBody = storeState[tempPropertyNaming][nestedTempPropertyNaming].body;

		return {
			contentType,
			contentTypeId: storeState[tempPropertyNaming][nestedTempPropertyNaming].id,
			contentStatus: storeState[tempPropertyNaming][nestedTempPropertyNaming].status || '',
			projectName: storeState.project.currentProject.domain,
			userId: storeState.profile.profile.id,
			userEmail: storeState.profile.profile.email,
			widgetsAnalytics: extractAndFormatWidgetAnalyticsData(contentBody),
			widgetsCount: extractOnlyWidgetsElementsFromBody(contentBody).length,
		};
	}

	return null;
};

const extractOnlyWidgetsElementsFromBody = (bodyContent: Array<any>): Array<any> => {
	const widgetTypes = [WidgetTypesV2.widget, FansUnitedWidgetsTypes.widget, WidgetTypes.widget];
	return bodyContent.filter((bodyEl) => widgetTypes.includes(bodyEl.type));
};

const extractCoreWidgetAnalyticsData = (bodyContent: Array<any> | null | undefined): WidgetAnalyticData[] => {
	if (bodyContent && bodyContent.length > 0) {
		const extractedWidgets = extractOnlyWidgetsElementsFromBody(bodyContent);
		return extractedWidgets.map((widget) => {
			return {
				widgetVersion: widget.type,
				widgetType: widget.data.widget_type,
				sport: widget.data.sport || 'unknown sport', // old saved widgets should be re-created to have 'sport' field
			};
		});
	}

	return [];
};

const remapWidgetAnalyticsDataAsNestedObjects = (
	widgetsContent: WidgetAnalyticData[],
): Record<string, Record<string, Record<string, number>>> => {
	const result = {};
	// created multiple forEach loops because we cannot assign result[currentValue.sport][currentValue.widgetVersion][currentValue.widgetType] directly
	// create sports layer
	widgetsContent.forEach((el) => (result[el.sport] = {}));

	// create widget version sublayer
	widgetsContent.forEach((el) => (result[el.sport][el.widgetVersion] = {}));

	// create widget types sublayer
	widgetsContent.forEach((el) => (result[el.sport][el.widgetVersion][el.widgetType] = 0));

	// populate widget count data
	widgetsContent.forEach((currentValue) => {
		const widgetCount = result[currentValue.sport][currentValue.widgetVersion][currentValue.widgetType];

		if (widgetCount > 0) {
			// if the user added 2+ identical types of widget (identical sport, version and type of the widget)
			result[currentValue.sport][currentValue.widgetVersion][currentValue.widgetType] = widgetCount + 1;
		} else {
			result[currentValue.sport][currentValue.widgetVersion][currentValue.widgetType] = 1;
		}
	});

	return result;
};

const formatWidgetAnalyticAsText = (
	widgetAnalyticNestedObj: Record<string, Record<string, Record<string, number>>>,
): Record<string, string> => {
	const result: Record<string, string> = {};
	for (let sport in widgetAnalyticNestedObj) {
		let textForGoogleAnalytics = '';
		const widgetVersionsObj = widgetAnalyticNestedObj[sport];
		const widgetVersions = Object.keys(widgetVersionsObj);
		widgetVersions.forEach((widgetVersion: string) => {
			textForGoogleAnalytics += `[${widgetVersion}] - `;
			const widgetTypesObj = widgetVersionsObj[widgetVersion];
			const widgetTypes = Object.keys(widgetTypesObj);
			widgetTypes.forEach((widgetType: string) => {
				const widgetTypeOccurringCount = widgetTypesObj[widgetType];
				textForGoogleAnalytics += `${widgetType} (${widgetTypeOccurringCount}); `;
			});
		});
		result[sport] = textForGoogleAnalytics;
	}

	return result;
};

export const extractAndFormatWidgetAnalyticsData = (bodyContent: Array<any> | null | undefined): Record<string, string> => {
	const extractedWidgetAnalyticsData = extractCoreWidgetAnalyticsData(bodyContent);
	const widgetAnalyticsDataAsNestedObject = remapWidgetAnalyticsDataAsNestedObjects(extractedWidgetAnalyticsData);
	const formattedWidgetAnalyticsAsText = formatWidgetAnalyticAsText(widgetAnalyticsDataAsNestedObject);
	return formattedWidgetAnalyticsAsText;
};
