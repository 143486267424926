import Author from '../../../../../models/author/Author';
import { SelectMenuOptionType } from '../../../../../models/v2/general/select.model';

export const authorsToOptions = (authors: Author[]): SelectMenuOptionType[] => {
	return authors && authors.length > 0
		? authors.filter((author: Author) => author && author.active).map((author: Author) => returnMappedAuthors(author))
		: [];
};

export const returnMappedAuthors = (author: Author) => {
	return {
		value: author.id,
		label: author.name,
		logo: author.avatarImage,
		data: author,
	};
};

export const authorsFromOptionSelections = (selections: SelectMenuOptionType[]) => {
	return selections ? selections.map((selection: SelectMenuOptionType) => selection.data) : [];
};
