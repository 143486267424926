import { LineupJson } from './lineup.json';
import LineupBuilder from './lineup.builder';
import TeamLineupModel from '../team-lineup/team-lineup.model';
import BlacklistModel from '../../../../../models/blacklist/blacklist';

export default class LineupModel {
	readonly matchId: string;
	readonly homeTeam: TeamLineupModel;
	readonly awayTeam: TeamLineupModel;
	readonly status: string;
	readonly blacklist: BlacklistModel;

	private constructor(matchId: string, homeTeam: any, awayTeam: any, status: string, blacklist: BlacklistModel) {
		this.matchId = matchId;
		this.homeTeam = homeTeam;
		this.awayTeam = awayTeam;
		this.status = status;
		this.blacklist = blacklist;
	}

	toJSON(): LineupJson {
		return {
			matchId: this.matchId,
			homeTeam: this.homeTeam,
			awayTeam: this.awayTeam,
			status: this.status,
			blacklist: this.blacklist,
		};
	}

	static fromJSON(json: LineupJson): LineupModel {
		return new LineupModel(json.matchId, json.homeTeam, json.awayTeam, json.status, json.blacklist);
	}

	static builder(lineup?: LineupModel): LineupBuilder {
		return new LineupBuilder(lineup);
	}
}
