import { toast } from 'react-toastify';
import i18n from '../../i18n';
import { actionService } from '../../App';
import { SEARCH_IS_APPLIED } from '../action-creators/UiActionCreator';
import { LIVE_BLOGS_ENTITY_DELETE_SUCCESS, LIVE_BLOGS_LISTING_RECEIVED } from '../action-creators/LiveBlogsActionCreator';
import { responseToPaginationModel } from '../../models/v2/Pagination/pagination.mapper';
import { responseToLiveBlogListingModel } from '../../views/Pages/Live Blog/models/live-blog-listing.mapper';

export function liveBlogsMiddleware() {
	return function (next: any) {
		return function (action: any) {
			if (action) {
				if (action.type === LIVE_BLOGS_LISTING_RECEIVED) {
					action.payload.liveBlogs = responseToLiveBlogListingModel(action.payload.data);
					action.payload.contentPagination = responseToPaginationModel(action.payload.data.meta);
				}

				if (action.type === LIVE_BLOGS_ENTITY_DELETE_SUCCESS) {
					toast.success(i18n.t('live_blog_deleted_success'));
					window.dispatchEvent(new CustomEvent(action.type));
				}

				if (action.type === SEARCH_IS_APPLIED) {
					actionService.emitGeneralAction(SEARCH_IS_APPLIED);
				}
			}

			next(action);
		};
	};
}
