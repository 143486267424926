import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';
import EnumItem from '../../../models/enum/EnumItem';
import HttpService from '../../../services/rest/HttpService';
import { Row, Col, Label, Button, Input, Collapse, CardHeader, UncontrolledCollapse } from 'reactstrap';
import { TranslationEntities, entityTypeEnum } from './helpers/translations.constants';
import TranslationEntitySelect from './subcomponents/translation-entity-select.component';
import TeamTranslationsContainer from './subcomponents/team-translations.component';
import StagesTranslationsContainer from './subcomponents/stages-translations.component';
import VenuesTranslationsContainer from './subcomponents/venues-translations.component';
import RefereesTranslationsComponent from './subcomponents/referees-translations.component';
import TranslationLanguageSelect from './subcomponents/translation-language-select.component';
import { Properties, State } from './properites/translations.properties';
import PlayerModel from '../../Partials/Blocky/partials/player-select/models/player.model';
import TeamPlayersTranslationsContainer from './subcomponents/team-players-translations.component';
import {
	remapTranslationTeamFromResponse,
	mapTranslation,
	mapTeamTranslations,
	filterOtherTranslations,
	mapMultipleTranslations,
	updateTranslationLang,
	orderTranslationSearchResponse,
	areTranslationsEmpty,
	extractNotEmptyTranslations,
	filterPlayerTranslations,
	orderTranslationSearchOtherResponse,
} from './helpers/translations.helpers';
import SingleTranslationComponent from './subcomponents/single-translation.component';
import SearchEntitiesComponent from './subcomponents/search-entities.component';
import { assetsPlaceholder } from '../../../constants/assetsPlaceholder';
import TranslationTeamModel from './models/translations-team.model';
import EntityModel from './models/entity.model';
import StageModel from '../../Partials/Blocky/blocks/widgets/fixtures/models/stage/stage.model';
import SeasonSelectContainer from '../../Partials/Blocky/partials/season-select/season-select-container.component';
import SeasonModel from '../../Partials/Blocky/blocks/widgets/player-profile/models/season/season.model';
import VenueModel from '../../Partials/Blocky/blocks/widgets/fixtures/models/venue/venue.model';
import RefereeModel from '../../Partials/Blocky/blocks/widgets/fixtures/models/referees/referee.model';
import SeasonTeamsComponent from './subcomponents/season-teams.component';
import PaginationMeta from '../../../models/pagination/PaginationMeta';
import { returnObjectForArticlePaginationUpdate } from '../../../store/action-creators/ArticleActionCreator';
import { returnObjectForVideoPaginationUpdate } from '../../../store/action-creators/VideoActionCreator';
import { returnObjectForGalleryPaginationUpdate } from '../../../store/action-creators/GalleryActionCreator';
import { returnObjectForImagesPaginationUpdate } from '../../../store/action-creators/ImageActionCreator';
import { returnObjectForContentFiltersUpdate } from '../../../store/action-creators/content-resources';
import { remmapContentFilters } from '../Articles/Helpers/ArticleHelper';
import MatchStatus from './subcomponents/match-status.component';

class TranslationsComponent extends React.Component<Properties, State> {
	constructor(props: Properties) {
		super(props);
		this.state = {
			translationLanguage: EnumItem.builder().build(),
			searchLanguage: EnumItem.builder().build(),
			entity: EntityModel.builder().build(),
			entityType: EnumItem.builder().build(),
			teamEntity: null,
			teamPlayers: [],
			stages: [],
			venues: [],
			referees: [],
			translations: [],
			teamPlayersTranslation: [],
			stagesTranslation: [],
			venuesTranslation: [],
			refereesTranslation: [],
			loading: false,
			isOpen: false,
			selectedSeason: SeasonModel.builder().build(),
		};
	}

	componentDidMount() {
		this.props.updateArticlesPagination(PaginationMeta.builder().withCurrentPage(1).build());
		this.props.updateVideosPagination(PaginationMeta.builder().withCurrentPage(1).build());
		this.props.updateGalleriesPagination(PaginationMeta.builder().withCurrentPage(1).build());
		this.props.updateImagesPagination(PaginationMeta.builder().withCurrentPage(1).build());
		const filters = remmapContentFilters({}, {}, {}, {}, {});
		this.props.updateContentFilters(filters);
	}

	// When the user change translation language we need to search for new translations
	onLanguageChange = (language: EnumItem) => {
		const translations = updateTranslationLang(this.state.translations, language);
		this.setState((state) => {
			return { ...state, translationLanguage: language, translations };
		});

		if (this.state.teamEntity) {
			this.searchTeamPLayersTranslations(this.state.teamPlayers, this.state.teamEntity, language);
		}

		if (this.state.stages && this.state.stages.length > 0 && this.state.entity.id && this.state.entity.id !== entityTypeEnum.TOURNAMENT) {
			this.searchStagesTranslations(this.state.stages, language);
		}

		if (this.state.venues && this.state.venues.length > 0 && this.state.entity.id && this.state.entity.id !== entityTypeEnum.TOURNAMENT) {
			this.searchVenuesTranslations(this.state.venues, language);
		}

		if (this.state.referees && this.state.referees.length > 0 && this.state.entity.id && this.state.entity.id !== entityTypeEnum.TOURNAMENT) {
			this.searchRefereesTranslations(this.state.referees, language);
		}

		if (this.state.entity && this.state.entity.id && this.state.entity.id !== entityTypeEnum.TEAM && !this.state.teamEntity) {
			this.searchSingleTranslations(this.state.entity, language);
		}
	};

	onSearchLanguageChange = (language: EnumItem) => this.setState({ ...this.state, searchLanguage: language });

	getTournamentStages = (id: string) => {
		const headers = { Project: this.props.currentProject.domain };
		const acceptLang = this.state.searchLanguage ? this.state.searchLanguage.id : this.props.currentProject.language;
		HttpService.instanceFootball(acceptLang.split('-')[0])
			.get(`/stages?tournament_ids=${id}`, headers)
			.then((response: any) => {
				const stages = response.data && response.data.length > 0 ? response.data.map((stage: any) => StageModel.builder(stage).build()) : [];
				this.searchStagesTranslations(stages, this.state.translationLanguage);
			})
			.catch(() => this.setState({ ...this.state, loading: false }));
	};

	getSeasonStages = (id: string) => {
		const headers = { Project: this.props.currentProject.domain };
		const acceptLang = this.state.searchLanguage ? this.state.searchLanguage.id : this.props.currentProject.language;
		HttpService.instanceFootball(acceptLang.split('-')[0])
			.get(`/tournaments/seasons/${id}`, headers)
			.then((response: any) => {
				const stages =
					response.data && response.data.stages && response.data.stages.length > 0
						? response.data.stages.map((stage: any) => StageModel.builder(stage).build())
						: [];
				this.searchStagesTranslations(stages, this.state.translationLanguage);
			})
			.catch(() => this.setState({ ...this.state, loading: false }));
	};

	getSeasonVenues = (id: string) => {
		const headers = { Project: this.props.currentProject.domain };
		const acceptLang = this.state.searchLanguage ? this.state.searchLanguage.id : this.props.currentProject.language;
		HttpService.instanceFootball(acceptLang.split('-')[0])
			.get(`/v2/venues?season_ids=${id}&limit=30&offset=0`, headers)
			.then((response: any) => {
				const venues =
					response.data.venues && response.data.venues.length > 0
						? response.data.venues.map((venue: any) => VenueModel.builder(venue).build())
						: [];
				this.searchVenuesTranslations(venues, this.state.translationLanguage);
			})
			.catch(() => this.setState({ ...this.state, loading: false }));
	};

	getSeasonReferees = (id: string) => {
		const headers = { Project: this.props.currentProject.domain };
		const acceptLang = this.state.searchLanguage ? this.state.searchLanguage.id : this.props.currentProject.language;
		HttpService.instanceFootball(acceptLang.split('-')[0])
			.get(`/v2/referees?season_ids=${id}&limit=30&offset=0`, headers)
			.then((response: any) => {
				const referees =
					response.data.referees && response.data.referees.length > 0
						? response.data.referees.map((referee: any) => RefereeModel.builder(referee).build())
						: [];
				this.searchRefereesTranslations(referees, this.state.translationLanguage);
			})
			.catch(() => this.setState({ ...this.state, loading: false }));
	};

	// When user select team entity we get all team data from footballAPI
	getTeamData = (id: string) => {
		const headers = { Project: this.props.currentProject.domain };
		const acceptLang = this.state.searchLanguage ? this.state.searchLanguage.id : this.props.currentProject.language;
		HttpService.instanceFootball(acceptLang.split('-')[0])
			.get(`/teams/${id}`, null, headers)
			.then((response: any) => this.getTeamPlayersData(id, remapTranslationTeamFromResponse(response.data)))
			.catch(() => this.setState({ ...this.state, loading: false }));
	};

	// When user select team entity we get all team players data from footballAPI
	getTeamPlayersData = (id: string, teamEntity: TranslationTeamModel) => {
		const headers = { Project: this.props.currentProject.domain };
		const acceptLang = this.state.searchLanguage ? this.state.searchLanguage.id : this.props.currentProject.language;
		HttpService.instanceFootball(acceptLang.split('-')[0])
			.get(`/teams/${id}/players`, null, headers)
			.then((response: any) => {
				const teamPlayers =
					response.data && response.data.length > 0 ? response.data.map((player: any) => PlayerModel.builder(player.player).build()) : [];
				this.searchTeamPLayersTranslations(teamPlayers, teamEntity, this.state.translationLanguage);
			})
			.catch(() => this.setState({ ...this.state, loading: false }));
	};

	// Search for existing translations for tournament stages case and map them to state
	searchStagesTranslations = (stages: StageModel[], language: EnumItem) => {
		const mapEntityToTranslation = [...mapMultipleTranslations(stages, language, entityTypeEnum.STAGE)];
		const translationsForSearch = mapEntityToTranslation.map((translation: any) => translation.key);
		const headers = { Project: this.props.currentProject.domain };
		HttpService.instanceFootball()
			.post(`/v2/translations/search`, translationsForSearch, headers)
			.then((response: any) => {
				const orderedResponse = orderTranslationSearchResponse([...mapEntityToTranslation], response);
				this.setState((state: State) => {
					return {
						...state,
						stages,
						stagesTranslation: filterPlayerTranslations(orderedResponse, entityTypeEnum.STAGE),
						loading: false,
					};
				});
			})
			.catch(() => this.setState({ ...this.state, loading: false }));
	};

	// Search for existing translations for tournament venues case and map them to state
	searchVenuesTranslations = (venues: VenueModel[], language: EnumItem) => {
		const mapEntityToTranslation = [...mapMultipleTranslations(venues, language, entityTypeEnum.VENUE)];
		const translationsForSearch = mapEntityToTranslation.map((translation: any) => translation.key);
		const headers = { Project: this.props.currentProject.domain };
		HttpService.instanceFootball()
			.post(`/v2/translations/search`, translationsForSearch, headers)
			.then((response: any) => {
				const orderedResponse = orderTranslationSearchOtherResponse([...mapEntityToTranslation], response);
				this.setState((state: State) => {
					return {
						...state,
						venues,
						venuesTranslation: filterPlayerTranslations(orderedResponse, entityTypeEnum.VENUE),
						loading: false,
					};
				});
			})
			.catch(() => this.setState({ ...this.state, loading: false }));
	};

	searchRefereesTranslations = (referees: RefereeModel[], language: EnumItem) => {
		const mapEntityToTranslation = [...mapMultipleTranslations(referees, language, entityTypeEnum.REFEREE)];
		const translationsForSearch = mapEntityToTranslation.map((translation: any) => translation.key);
		const headers = { Project: this.props.currentProject.domain };
		HttpService.instanceFootball()
			.post(`/v2/translations/search`, translationsForSearch, headers)
			.then((response: any) => {
				const orderedResponse = orderTranslationSearchOtherResponse([...mapEntityToTranslation], response);
				this.setState((state: State) => {
					return {
						...state,
						referees,
						refereesTranslation: filterPlayerTranslations(orderedResponse, entityTypeEnum.REFEREE),
						loading: false,
					};
				});
			})
			.catch(() => this.setState({ ...this.state, loading: false }));
	};

	// Search for existing translations for team case and map them to state
	searchTeamPLayersTranslations = (teamPlayers: PlayerModel[], teamEntity: TranslationTeamModel, language: EnumItem) => {
		const mapEntityToTranslation = [
			...mapTeamTranslations(teamEntity, language),
			...mapMultipleTranslations(teamPlayers, language, entityTypeEnum.PLAYER),
		];
		const translationsForSearch = mapEntityToTranslation.map((translation: any) => translation.key);
		HttpService.instanceFootball()
			.post(`/v2/translations/search`, translationsForSearch)
			.then((response: any) => {
				const orderedResponse = orderTranslationSearchResponse(mapEntityToTranslation, response);

				this.setState((state: State) => {
					return {
						...state,
						teamPlayers,
						teamEntity,
						translations: filterOtherTranslations(orderedResponse, entityTypeEnum.PLAYER),
						teamPlayersTranslation: filterPlayerTranslations(orderedResponse, entityTypeEnum.PLAYER),
						loading: false,
					};
				});
			})
			.catch(() => this.setState({ ...this.state, loading: false }));
	};

	//Search for existing translations for other entities case and map them to state
	searchSingleTranslations = (entity: EntityModel, language: EnumItem) => {
		const mapEntityToTranslation = [mapTranslation({ id: entity.id }, language, this.state.entityType.id)];
		const translations = mapEntityToTranslation.map((translation: any) => translation.key);

		HttpService.instanceFootball()
			.post(`/v2/translations/search`, translations)
			.then((response: any) => {
				const translations = response.data && response.data.length > 0 ? response.data : mapEntityToTranslation;
				this.setState((state: State) => {
					return { ...state, entity, translations, selectedSeason: SeasonModel.builder().build() };
				});
			})
			.catch(() => this.setState({ ...this.state, loading: false }));
	};

	onEntityChange = (entity: EnumItem) => {
		this.setState({
			...this.state,
			entityType: entity,
			teamEntity: null,
			teamPlayers: [],
			translations: [],
			stages: [],
			venues: [],
			referees: [],
			teamPlayersTranslation: [],
			stagesTranslation: [],
			venuesTranslation: [],
			refereesTranslation: [],
			entity: EntityModel.builder().build(),
			selectedSeason: SeasonModel.builder().build(),
			isOpen: false,
		});
	};

	onEntitySelect = (entity: any) => {
		const { entityType } = this.state;

		if (entityType && entityType.name === TranslationEntities.TEAM) {
			this.setState({ ...this.state, entity, translations: [], teamPlayersTranslation: [], stagesTranslation: [], loading: true });
			this.getTeamData(entity.id);
		}

		if (entityType && entityType.name !== TranslationEntities.TEAM) {
			this.searchSingleTranslations(entity, this.state.translationLanguage);
		}
	};

	onTranslationChange = (translations: any[]) => this.setState({ ...this.state, translations });

	onTeamPlayersTranslationChange = (translations: any[]) => this.setState({ ...this.state, teamPlayersTranslation: translations });

	onStagesTranslationChange = (translations: any[]) => this.setState({ ...this.state, stagesTranslation: translations });

	onVenuesTranslationChange = (translations: any[]) => this.setState({ ...this.state, venuesTranslation: translations });

	onRefereesTranslationChange = (translations: any[]) => this.setState({ ...this.state, refereesTranslation: translations });

	saveTranslation = () => {
		const { translations, teamPlayersTranslation, stagesTranslation, venuesTranslation, refereesTranslation } = this.state;
		HttpService.instanceFootball()
			.post(
				'/v2/translations',
				JSON.parse(
					JSON.stringify(
						extractNotEmptyTranslations([
							...translations,
							...teamPlayersTranslation,
							...stagesTranslation,
							...venuesTranslation,
							...refereesTranslation,
						]),
					),
				),
			)
			.then(() => toast.success(this.props.t('translation_successfull')))
			.catch(() => toast.error(this.props.t('translation_faild')));
	};

	onSeasonSelect = (selectedSeason: SeasonModel) => {
		this.setState({ ...this.state, selectedSeason: selectedSeason });
		this.getSeasonVenues(selectedSeason.id);
		this.getSeasonReferees(selectedSeason.id);
		this.getSeasonStages(selectedSeason.id);
	};

	toggle = () => {
		this.setState({ ...this.state, isOpen: !this.state.isOpen });
	};

	render() {
		const { t } = this.props;
		const {
			entityType,
			entity,
			translations,
			teamEntity,
			teamPlayers,
			translationLanguage,
			loading,
			searchLanguage,
			teamPlayersTranslation,
			stages,
			venues,
			referees,
			stagesTranslation,
			venuesTranslation,
			refereesTranslation,
			selectedSeason,
			isOpen,
		} = this.state;

		const logoOpacity = teamEntity && teamEntity.team && !loading ? { opacity: 1 } : { opacity: 0.1 };
		const buttonDisabled = !entityType.id || !entity.id || areTranslationsEmpty([...translations, ...teamPlayersTranslation]);

		return (
			<div className='animated fadeIn'>
				<Row>
					<Col col='12' lg='12' md='12' sm='12' xs='12'>
						<div className='card'>
							<div className='d-flex align-items-center card-header'>
								<div className='mr-auto'>{t('translations')}</div>
								<Button size='sm' id='upper-save-translation-button' disabled={buttonDisabled} color='primary' onClick={this.saveTranslation}>
									{t('save_translation')}
								</Button>
							</div>
							<div className='card-body'>
								<div>
									<Row>
										<TranslationLanguageSelect language={translationLanguage} isSearchLang={false} t={t} onSelect={this.onLanguageChange} />
										<TranslationEntitySelect t={t} onSelect={this.onEntityChange} />
									</Row>
								</div>
							</div>
						</div>
						{entityType && entityType.id && (
							<div className='card animated fadeIn'>
								<div className='d-flex align-items-center card-header'>
									<Col xs='6'>{t(entityType.name)}</Col>
									<TranslationLanguageSelect language={searchLanguage} isSearchLang t={t} onSelect={this.onSearchLanguageChange} />
								</div>
								<div className='card-body'>
									{entityType.name === TranslationEntities.MATCH_STATUS ? (
										<Row className='mb-4'>
											<MatchStatus
												translations={translations}
												t={t}
												entity={entity}
												selectedEntity={entityType}
												searchLanguage={searchLanguage}
												translationLanguage={translationLanguage}
												translationChange={this.onTranslationChange}
												onEntitySelect={this.onEntitySelect}
											/>
										</Row>
									) : (
										<Row className='mb-4'>
											<SearchEntitiesComponent
												t={t}
												entity={entity}
												entityType={entityType}
												onEntitySelect={this.onEntitySelect}
												searchLanguage={searchLanguage}
											/>
											{entityType.name !== TranslationEntities.TEAM && translations && translations.length > 0 && (
												<SingleTranslationComponent
													translations={translations}
													t={t}
													entity={entity}
													selectedEntity={entityType}
													language={translationLanguage}
													translationChange={this.onTranslationChange}
												/>
											)}
											{entityType.name !== TranslationEntities.TEAM && translations.length === 0 && (
												<Col xs='6'>
													<Label>{t('translate')}</Label>
													<Input placeholder={t('translate')} disabled />
												</Col>
											)}
											{entityType.name === TranslationEntities.TEAM && (
												<Col xs='6' className='text-center'>
													<img
														style={logoOpacity}
														height='65'
														src={teamEntity && teamEntity.team.logo ? teamEntity.team.logo : assetsPlaceholder.team}
													/>
												</Col>
											)}
										</Row>
									)}
									{entityType.name === TranslationEntities.TOURNAMENT && entity && entity.id && (
										<Row>
											<Col xs='6'>
												<SeasonSelectContainer
													label='season'
													t={t}
													onSeasonSelect={this.onSeasonSelect}
													selectedSeason={selectedSeason}
													tournamentId={entity.id}
													lang={searchLanguage.id}
												/>
											</Col>
										</Row>
									)}
									<div className={`${loading ? 'loading-overlay' : ''}`}>
										{teamEntity && Object.keys(teamEntity).length > 0 && translations && translations.length > 0 && (
											<TeamTranslationsContainer
												translations={[...translations]}
												t={t}
												teamEntity={teamEntity}
												language={translationLanguage}
												translationChange={this.onTranslationChange}
											/>
										)}
									</div>
									{!teamPlayers && !stages && (
										<Button disabled={buttonDisabled} id='save-translation-button-no-players' color='primary' onClick={this.saveTranslation}>
											{t('save_translation')}
										</Button>
									)}
								</div>
							</div>
						)}
						<div className={`${loading ? 'loading-overlay' : ''}`}>
							{entityType.name === TranslationEntities.TOURNAMENT && selectedSeason && selectedSeason.id && (
								<div className={`card animated fadeIn fadeOut" ${loading ? 'loading-overlay' : ''}`}>
									<CardHeader className='cursor-pointer d-flex align-items-center' id='togglerTeams' onClick={this.toggle}>
										{t('teams')}
										<i className='ml-auto fa fa-lg fa-angle-down' />
									</CardHeader>
									<Row className='m-0 px-3 py-2 border-bottom'>
										<Col xs='10'>{t('team_name')}</Col>
										<Col xs='2' className='text-right'>
											{t('action')}
										</Col>
									</Row>
									<div className='card-body'>
										<UncontrolledCollapse isOpen={!isOpen} toggler='#togglerTeams' className='p-3'>
											<SeasonTeamsComponent
												t={t}
												selectedSeason={selectedSeason}
												searchLanguage={searchLanguage}
												translationLanguage={translationLanguage}
											/>
										</UncontrolledCollapse>
									</div>
								</div>
							)}
						</div>
						<div className={`${loading ? 'loading-overlay' : ''}`}>
							{entityType.name === TranslationEntities.TOURNAMENT && translations && translations.length > 0 && (
								<div className={`card animated fadeIn fadeOut" ${loading ? 'loading-overlay' : ''}`}>
									<CardHeader className='cursor-pointer d-flex align-items-center' id='togglerVenues'>
										{t('tournament_venues')}
										<i className='ml-auto fa fa-lg fa-angle-down' />
									</CardHeader>
									<Row className='m-0 px-3 py-2 border-bottom'>
										<Col xs='6'>{t('venue_name')}</Col>
										<Col xs='6'>{t('translation')}</Col>
									</Row>
									<div className='card-body'>
										<UncontrolledCollapse toggler='#togglerVenues' className='p-3'>
											<VenuesTranslationsContainer
												t={t}
												translations={venuesTranslation}
												venues={venues}
												language={translationLanguage}
												translationChange={this.onVenuesTranslationChange}
											/>
										</UncontrolledCollapse>
									</div>
								</div>
							)}
						</div>
						<div className={`${loading ? 'loading-overlay' : ''}`}>
							{entityType.name === TranslationEntities.TOURNAMENT && translations && translations.length > 0 && (
								<div className={`card animated fadeIn fadeOut" ${loading ? 'loading-overlay' : ''}`}>
									<CardHeader className='cursor-pointer d-flex align-items-center' id='togglerReferees'>
										{t('tournament_referees')}
										<i className='ml-auto fa fa-lg fa-angle-down' />
									</CardHeader>
									<Row className='m-0 px-3 py-2 border-bottom'>
										<Col xs='6'>{t('referee_name')}</Col>
										<Col xs='6'>{t('translation')}</Col>
									</Row>
									<div className='card-body'>
										<UncontrolledCollapse toggler='#togglerReferees' className='p-3'>
											<RefereesTranslationsComponent
												t={t}
												translations={refereesTranslation}
												referees={referees}
												language={translationLanguage}
												translationChange={this.onRefereesTranslationChange}
											/>
										</UncontrolledCollapse>
									</div>
								</div>
							)}
						</div>
						<div className={`${loading ? 'loading-overlay' : ''}`}>
							{entityType.name === TranslationEntities.TOURNAMENT && translations && translations.length > 0 && (
								<div className={`card animated fadeIn fadeOut" ${loading ? 'loading-overlay' : ''}`}>
									<CardHeader className='cursor-pointer d-flex align-items-center' id='togglerStages'>
										{t('tournament_stages')}
										<i className='ml-auto fa fa-lg fa-angle-down' />
									</CardHeader>
									<Row className='m-0 px-3 py-2 border-bottom'>
										<Col xs='6'>{t('stage_name')}</Col>
										<Col xs='6'>{t('translation')}</Col>
									</Row>
									<div className='card-body'>
										<UncontrolledCollapse toggler='#togglerStages' className='p-3'>
											<StagesTranslationsContainer
												t={t}
												translations={stagesTranslation}
												stages={stages}
												language={translationLanguage}
												translationChange={this.onStagesTranslationChange}
											/>
										</UncontrolledCollapse>
										<Button id='save-translation-button' disabled={buttonDisabled} color='primary' onClick={this.saveTranslation}>
											{t('save_translation')}
										</Button>
									</div>
								</div>
							)}
						</div>
						{teamPlayers && teamPlayers.length > 0 && teamPlayersTranslation && teamPlayersTranslation.length > 0 && (
							<div className={`card animated fadeIn fadeOut" ${loading ? 'loading-overlay' : ''}`}>
								<div className='card-header'>{t('team_players')}</div>
								<Row className='m-0 px-3 py-2 border-bottom'>
									<Col xs='6'>{t('player_name')}</Col>
									<Col xs='6'>{t('translation')}</Col>
								</Row>
								<div className='card-body'>
									<TeamPlayersTranslationsContainer
										t={t}
										translations={teamPlayersTranslation}
										teamPlayers={teamPlayers}
										language={translationLanguage}
										translationChange={this.onTeamPlayersTranslationChange}
									/>
									<Button id='save-translation-button' disabled={buttonDisabled} color='primary' onClick={this.saveTranslation}>
										{t('save_translation')}
									</Button>
								</div>
							</div>
						)}
					</Col>
				</Row>
			</div>
		);
	}
}

function mapStateToProps(state: any) {
	return {
		currentProject: state.project.currentProject,
	};
}

function mapDispatchToProps(dispatch: any) {
	return {
		updateGalleriesPagination: (pagination: PaginationMeta) => dispatch(returnObjectForGalleryPaginationUpdate(pagination)),
		updateVideosPagination: (pagination: PaginationMeta) => dispatch(returnObjectForVideoPaginationUpdate(pagination)),
		updateArticlesPagination: (pagination: PaginationMeta) => dispatch(returnObjectForArticlePaginationUpdate(pagination)),
		updateImagesPagination: (pagination: PaginationMeta) => dispatch(returnObjectForImagesPaginationUpdate(pagination)),
		updateContentFilters: (filters: any) => dispatch(returnObjectForContentFiltersUpdate(filters)),
	};
}

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(TranslationsComponent) as React.ComponentType<any>;
