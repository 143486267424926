import { featuresService } from '../../../../App';
import { FeatureTypes } from '../../../../services/feature-service/features.enum';

const emojiRegEx = require('emoji-regex');

export default class Global {
	static makeId = (length: number) => {
		let result = '';
		let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
		let charactersLength = characters.length;

		for (let i = 0; i < length; i++) {
			result += characters.charAt(Math.floor(Math.random() * charactersLength));
		}

		return result;
	};

	static stripEmoji = (text: string) => {
		if (text) {
			return text.replace(emojiRegEx(), '');
		}

		return text;
	};

	static createQuickAccessMenu = (t: any) => {
		const isQuickAccessEnabled = featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.QUICK_ACCESS);
		const quickAccessData = isQuickAccessEnabled ? featuresService.getFeatureConfig(FeatureTypes.QUICK_ACCESS) : { links: [] };

		const links = quickAccessData.links.map((link: any) => {
			return {
				name: link.name,
				url: `/smp/quick_access/${link.list_id}`,
				icon: 'fa fa-list-ul',
			};
		});

		return isQuickAccessEnabled
			? [
					{
						title: true,
						name: t('quick_access'),
						wrapper: {
							element: '',
							attributes: {},
						},
					},
					...links,
			  ]
			: [];
	};
}
