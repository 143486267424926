import React from 'react';
import { compose } from 'redux';
import { TeamProfileView } from './team-profile-widget-view.component';
import TeamProfileEdit from './team-profile-edit.component';
import { ViewTypes } from '../../../../constants/general.constants';
import BlockModel from '../../../../models/block.model';
import { WidgetPreview } from '../../widget-preview/widget-preview.component';
import BlockUpdateController from '../../../block-update-controller.component';

type Properties = {
	block: BlockModel;
	view: ViewTypes;
	t: any;
	contentData: any;
};

class TeamProfile extends React.Component<Properties> {
	render() {
		const { view, t, block, contentData } = this.props;

		if (view === ViewTypes.edit) {
			return <TeamProfileEdit block={block} t={t} contentData={contentData} />;
		}

		if (view === ViewTypes.preview) {
			return <WidgetPreview blockContent={this.props.block.data.content} config={this.props.block.data.config} />;
		}

		if (view === ViewTypes.normal) {
			return <TeamProfileView t={this.props.t} block={this.props.block.data.preview} />;
		}

		return null;
	}
}

export default compose(BlockUpdateController)(TeamProfile);
