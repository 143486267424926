import React, { FunctionComponent } from 'react';
import { Col } from 'reactstrap';
import InputDesign from '../../../../../Partials/design-components/input/input';
import { connect } from 'react-redux';
import { extractContentModeBasedOnUrl } from '../../../../../../global-helpers/global.helpers';
import { updateCustomEntity } from '../../../../../../store/action-creators/custom-entities-action-creator';
import { useTranslation } from 'react-i18next';
import { UpdateCustomEntityReduxType } from '../../../helpers/custom-entities.helper';
import { IGeo } from '../../../models/models';

type Props = {
	customEntityGeo: IGeo;
	updateCustomEntity: UpdateCustomEntityReduxType;
};

const GeoLocationsTypes = {
	LATITUDE: 'latitude',
	LONGITUDE: 'longitude',
};

const GeoLocations: FunctionComponent<Props> = ({ customEntityGeo, updateCustomEntity }) => {
	const { t } = useTranslation();

	const updateGeoInRedux = (name: string, value: string) => {
		const updatedGeoEl = { ...customEntityGeo, [name]: value };
		updateCustomEntity({ geo: updatedGeoEl });
	};

	return (
		<>
			{Object.keys(GeoLocationsTypes).map((key) => {
				const locationString = GeoLocationsTypes[key];
				const locationStringTranslated = t(locationString);

				return (
					<Col md='6' key={locationString}>
						<InputDesign
							invokeFunc={(text: string) => updateGeoInRedux(locationString, text)}
							fieldId={locationString}
							labelText={locationStringTranslated}
							placeholderText={locationStringTranslated}
							value={customEntityGeo[locationString]}
						/>
					</Col>
				);
			})}
		</>
	);
};

function mapStateToProps(state: any) {
	const contentMode = extractContentModeBasedOnUrl();

	return {
		customEntityGeo: state.customEntities[contentMode].geo || {},
	};
}

function mapDispatchToProps(dispatch: any) {
	const contentMode = extractContentModeBasedOnUrl();

	return {
		updateCustomEntity: (dataForUpdate: Record<string, unknown>) => dispatch(updateCustomEntity(contentMode, dataForUpdate)),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(GeoLocations);
