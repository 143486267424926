import Project from '../../models/project/Project';
import ErrorResponseModel from '../../models/error-response/ErrorResponseModel';
import PaginationMetaModel from '../../views/Pages/Live Blog/models/live-blog-meta.model';

export const LIVE_BLOGS_LISTING_REQUEST = '[ACTION] LIVE_BLOGS_LISTING_REQUEST';
export const LIVE_BLOGS_LISTING_RECEIVED = '[ACTION] LIVE_BLOGS_LISTING_RECEIVED';
export const LIVE_BLOGS_LISTING_FAILED = '[ACTION] LIVE_BLOGS_LISTING_FAILED';

export const LIVE_BLOGS_SEARCH = '[ACTION] LIVE_BLOGS_SEARCH';
export const LIVE_BLOGS_SEARCH_FAILED = '[ACTION] LIVE_BLOGS_SEARCH_FAILED';

export const LIVE_BLOGS_ENTITY_DELETE = '[ACTION] LIVE_BLOGS_ENTITY_DELETE';
export const LIVE_BLOGS_ENTITY_DELETE_SUCCESS = '[ACTION] LIVE_BLOGS_ENTITY_DELETE_SUCCESS';
export const LIVE_BLOGS_ENTITY_DELETE_FAILED = '[ACTION] LIVE_BLOGS_ENTITY_DELETE_FAILED';

export const LIVE_BLOGS_PAGINATION_UPDATE = '[ACTION] LIVE_BLOGS_PAGINATION_UPDATE';

export const LIVE_BLOGS_FILTERS_UPDATE = '[ACTION] LIVE_BLOGS_FILTERS_UPDATE';

export function returnObjectForLiveBlogsListingRequest(page: string, project: Project, text: string) {
	return {
		type: LIVE_BLOGS_LISTING_REQUEST,
		payload: { page, project, text },
	};
}

export function returnObjectForLiveBlogsPaginationUpdate(pagination: PaginationMetaModel) {
	return {
		type: LIVE_BLOGS_PAGINATION_UPDATE,
		payload: { pagination },
	};
}

export function returnObjectForLiveBlogsListingReceived(liveBlogs: any) {
	return {
		type: LIVE_BLOGS_LISTING_RECEIVED,
		payload: liveBlogs,
	};
}

export function returnObjectForLiveBlogsListingFailed(error: any) {
	return {
		type: LIVE_BLOGS_LISTING_FAILED,
		payload: ErrorResponseModel.builder().fromResponse(error).build(),
	};
}

export function returnObjectForLiveBlogsSearch(text: string, project: Project) {
	return {
		type: LIVE_BLOGS_SEARCH,
		payload: { text, project },
	};
}

export function returnObjectForLiveBlogsSearchFailed(error: any) {
	return {
		type: LIVE_BLOGS_SEARCH_FAILED,
		payload: ErrorResponseModel.builder().fromResponse(error).build(),
	};
}

export function returnObjectForLiveBlogsDelete(id: string, project: Project) {
	return {
		type: LIVE_BLOGS_ENTITY_DELETE,
		payload: { id, project },
	};
}

export function returnObjectForLiveBlogsDeleteSuccess() {
	return {
		type: LIVE_BLOGS_ENTITY_DELETE_SUCCESS,
	};
}

export function returnObjectForLiveBlogsDeleteFailed() {
	return {
		type: LIVE_BLOGS_ENTITY_DELETE_FAILED,
	};
}

export function returnObjectForLiveBlogsFiltersUpdate(filters: any) {
	return {
		type: LIVE_BLOGS_FILTERS_UPDATE,
		payload: { filters },
	};
}
