import * as React from 'react';
import { useState } from 'react';
import { Col, Label, Row } from 'reactstrap';
import Project from '../../../../../models/project/Project';
import AssetsModel from '../../../../../models/team-manual-data/assets/assets';
import AssetsTypesModel from '../../../../../models/team-manual-data/assets/assetsTypes/assets-types.model';
import HttpService from '../../../../../services/rest/HttpService';
import { AssetsTypes, Entities } from './constants/assets.constants';
import { constructImageUrl } from './helpers/assets-image-upload.helper';
import { constructDeleteParamsForAsset, remmapAssetsTypes } from './helpers/assets-media.helper';
import { ImageUploadRowComponent } from './subcomponents/image-upload-row.component';
import './styles/assets-media.component.style.scss';
import ColorPickerComponent from '../../../../Partials/Blocky/blocks/widgets/match/components/color-picker.component';

type Properties = {
	t: any;
	currentProject: Project;
	dataEntry: any;
	updateAssets: (assets: AssetsModel) => any;
	assets: AssetsModel;
	dataType: string;
	deleteAssetsFromDataEntry?: (type: string) => any;
	updateHomeTeamColor: (homeTeamColor: string) => any;
	updateAwayTeamColor: (awayTeamColor: string) => any;
	theSelectedHomeTeamColor: string;
	theSelectedAwayTeamColor: string;
	homeTeamPopoverClass: string;
	awayTeamPopoverClass: string;
};

export const AssetsMediaComponent: React.FunctionComponent<Properties> = (props) => {
	const [assetsTypes, updateAssetsTypes] = React.useState([]);

	const [selectedHomeTeamColor, setSelectedHomeTeamColor] = useState('');
	const [selectedAwayTeamColor, setSelectedAwayTeamColor] = useState('');
	const [displayHomeTeamColorPicker, setDisplayHomeTeamColorPicker] = useState(false);
	const [displayAwayTeamColorPicker, setDisplayAwayTeamColorPicker] = useState(false);
	const [showTeamColorPicker, setTeamColorPickerVisibility] = useState(false);

	React.useEffect(() => {
		displayColorPicker();
	}, []);

	React.useEffect(() => {
		getAssetsTypes();
	}, []);

	const displayColorPicker = () => {
		const pageUrl = window.location.href;
		const getLastElementsFromUrl = pageUrl.split('/').slice(-3, -1).join('/');

		if (getLastElementsFromUrl === 'teams/edit' && props.dataType === 'team') {
			setTeamColorPickerVisibility(true);
		} else {
			setTeamColorPickerVisibility(false);
		}
	};

	const getAssetsTypes = () => {
		HttpService.getAssetsTypes()
			.then((response: any) => {
				let assetsTypes = response.data.filter((assetType: any) => assetType.entity === Entities[props.dataType]);
				updateAssetsTypes(remmapAssetsTypes(assetsTypes));
			})
			.catch((error: any) => error);
	};

	const deleteAsset = (type: string) => {
		const params = constructDeleteParamsForAsset(type, dataType, dataEntry.id);
		HttpService.deleteAssets(params)
			.then(() => {
				deleteAssetsFromDataEntry && deleteAssetsFromDataEntry(type);
			})
			.catch((e: any) => e);
	};

	React.useEffect(() => {
		setSelectedHomeTeamColor(props.theSelectedHomeTeamColor);
		setSelectedAwayTeamColor(props.theSelectedAwayTeamColor);
	}, [props.theSelectedHomeTeamColor, props.theSelectedAwayTeamColor]);

	const toggleHomeTeamColorPicker = (toggle: boolean) => {
		setDisplayHomeTeamColorPicker(toggle);
	};

	const toggleAwayTeamColorPicker = (toggle: boolean) => {
		setDisplayAwayTeamColorPicker(toggle);
	};

	const { t, currentProject, dataEntry, assets, dataType, deleteAssetsFromDataEntry } = props;

	return (
		<div className='d-flex flex-column'>
			{showTeamColorPicker && (
				<Row>
					<Col xs='6'>
						<strong className='team-color-picker-label'>{t('home_team_kit')}</strong>
						<div onClick={() => toggleHomeTeamColorPicker(true)} className='swatch teams-color-picker-box'>
							<div className={'color ' + (!selectedHomeTeamColor ? 'no-color-selected-bg' : '')} style={{ background: selectedHomeTeamColor }} />
						</div>

						{displayHomeTeamColorPicker && (
							<div className={'popover ' + props.homeTeamPopoverClass}>
								<div className='cover' onClick={() => toggleHomeTeamColorPicker(false)} />
								<ColorPickerComponent
									color={selectedHomeTeamColor}
									handleChangeColor={props.updateHomeTeamColor}
									toggleTeamColorPicker={toggleHomeTeamColorPicker}
								/>
							</div>
						)}

						{!selectedHomeTeamColor && <p className='text-danger'>{t('no_color_selected')}</p>}
					</Col>

					<Col xs='6'>
						<strong className='team-color-picker-label'>{t('away_team_kit')}</strong>
						<div onClick={() => toggleAwayTeamColorPicker(true)} className='swatch teams-color-picker-box'>
							<div className={'color ' + (!selectedAwayTeamColor ? 'no-color-selected-bg' : '')} style={{ background: selectedAwayTeamColor }} />
						</div>

						{displayAwayTeamColorPicker && (
							<div className={'popover ' + props.awayTeamPopoverClass}>
								<div className='cover' onClick={() => toggleAwayTeamColorPicker(false)} />
								<ColorPickerComponent
									color={selectedAwayTeamColor}
									handleChangeColor={props.updateAwayTeamColor}
									toggleTeamColorPicker={toggleAwayTeamColorPicker}
								/>
							</div>
						)}

						{!selectedAwayTeamColor && <p className='text-danger'>{t('no_color_selected')}</p>}
					</Col>
					<hr className='w-100' />
				</Row>
			)}

			{assetsTypes.length > 0 &&
				assetsTypes.map((type: AssetsTypesModel, index: number) => (
					<div key={`${type.assetType}${index}`}>
						<div>
							<Label>
								<strong>{t(`${AssetsTypes[type.assetType]}`)}</strong>
							</Label>
							<ImageUploadRowComponent
								index={index}
								t={t}
								currentProject={currentProject}
								type={AssetsTypes[type.assetType]}
								updateAssets={props.updateAssets}
								assets={assets}
								url={
									assets && assets[AssetsTypes[type.assetType]]
										? constructImageUrl(assets[AssetsTypes[type.assetType]], currentProject)
										: dataEntry.assets && dataEntry.assets[AssetsTypes[type.assetType]]
								}
								dataAssetUrl={dataEntry.assets && dataEntry.assets[AssetsTypes[type.assetType]]}
								deleteAsset={deleteAsset}
								assetType={assetsTypes[index]}
							/>
						</div>
						<hr className='w-100' />
					</div>
				))}
		</div>
	);
};
