import { RoundJson } from './round.json';
import RoundModel from './round.model';

export default class RoundBuilder {
	readonly json: RoundJson;

	constructor(stage?: RoundModel | RoundJson) {
		if (stage && stage instanceof RoundModel) {
			this.json = stage.toJson();
		} else if (stage) {
			this.json = stage;
		} else {
			this.json = {} as RoundJson;
		}
	}

	withRound(round: string): RoundBuilder {
		this.json.round = round;

		return this;
	}

	withStartTime(startTime: string): RoundBuilder {
		this.json.startTime = startTime;

		return this;
	}

	withEndTime(endTime: string): RoundBuilder {
		this.json.endTime = endTime;

		return this;
	}

	build(): RoundModel {
		return RoundModel.fromJson(this.json);
	}
}
