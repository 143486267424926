import 'reflect-metadata';
import { TypedJSON } from 'typedjson';
import SportsEntityModel from './sports-entity.model';
import SportsEntitiesModel from './sports-entities.model';
import TranslationsModel from './translations.model';
import AssetsModel from './assets.model';

export default class SportsEntityMapper {
	public responseToModel = (response: any): SportsEntitiesModel => {
		const serializer = new TypedJSON(SportsEntitiesModel);
		return serializer.parse(response) as SportsEntitiesModel;
	};

	public responseToEntityModel = (response: any): SportsEntityModel => {
		const serializer = new TypedJSON(SportsEntityModel);
		return serializer.parse(response) as SportsEntityModel;
	};

	public modelToRequestBody = (model: SportsEntitiesModel) => {
		if (model) {
			return {
				results: this.sportsEntitiesToRequest(model.results),
			};
		}
		return new SportsEntitiesModel();
	};

	private sportsEntitiesToRequest = (sportEntities: SportsEntityModel[]) => {
		return sportEntities
			? sportEntities.map((entity: SportsEntityModel) => {
					return {
						id: entity.id,
						name: entity.name,
						translated_name: entity.translated_name,
						sport: entity.sport,
						entity_type: entity.entity_type,
						birthdate: entity.birthdate ? entity.birthdate : null,
						competition_ids: entity.competition_ids ? entity.competition_ids : null,
						country_id: entity.country_id ? entity.country_id : null,
						type: entity.type,
						gender: entity.gender ? entity.gender : null,
						display_asset: this.assetsToRequest(entity.display_asset),
					};
			  })
			: [];
	};

	private assetsToRequest = (assets: AssetsModel) => {
		if (assets) {
			return {
				url: assets.url,
			};
		}

		return {};
	};
}
