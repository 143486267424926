import { Properties, State } from './properties/ListingHeaderContainerProps';
import * as React from 'react';
import {
	HeadersArticle,
	HeadersAuthor,
	HeadersBanner,
	HeadersCategory,
	HeadersList,
	HeadersTag,
	HeadersPoll,
	HeadersFeed,
	HeadersTeam,
	HeadersPlayer,
	HeadersCoach,
} from './ListingHeaders';

export default class ListingHeadersContainer extends React.Component<Properties, State> {
	private headers = (t: any) => {
		return {
			article: <HeadersArticle t={t} showLanguageFlag={true} />,
			video: <HeadersArticle t={t} showLanguageFlag={true} />,
			gallery: <HeadersArticle t={t} showLanguageFlag={true} />,
			banner: <HeadersBanner t={t} />,
			list: <HeadersList t={t} />,
			author: <HeadersAuthor t={t} />,
			tag: <HeadersTag t={t} />,
			category: <HeadersCategory t={t} />,
			poll: <HeadersPoll t={t} />,
			feed: <HeadersFeed t={t} />,
			team: <HeadersTeam t={t} />,
			player: <HeadersPlayer t={t} />,
			coach: <HeadersCoach t={t} />,
		};
	};

	render() {
		return this.headers(this.props.t)[this.props.contentType];
	}
}
