import LiveBlogTournamentSeasonResponseModel from '../../../models/seasons/live-blog-tournament-season.model';
import LiveBlogTeamModel from '../../../models/teams/live-blog-team.model';

export const returnActiveSeason = (seasons: LiveBlogTournamentSeasonResponseModel[]) => {
	let activeSeason = {} as LiveBlogTournamentSeasonResponseModel;
	if (seasons && seasons.length > 0) {
		seasons.map((season: LiveBlogTournamentSeasonResponseModel) => {
			if (season && season.active) {
				activeSeason = season;
			}
		});

		return activeSeason;
	}

	return null;
};

export const extractTeamIds = (teams: LiveBlogTeamModel[]) => {
	let teamIds: string[] = [];

	if (teams && teams.length > 0) {
		teamIds = teams.map((team: LiveBlogTeamModel) => team.id);
	}

	return teamIds;
};
