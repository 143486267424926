import { MatchSeasonJson } from './match-season.json';
import MatchSeasonModel from './match-season.model';
import TournamentModel from './match-season-tournament.model';

export default class MatchSeasonBuilder {
	readonly json: MatchSeasonJson;

	constructor(tournament?: MatchSeasonModel | MatchSeasonJson | null) {
		if (tournament && tournament instanceof MatchSeasonModel) {
			this.json = tournament.toJson();
		} else if (tournament) {
			this.json = tournament;
		} else {
			this.json = {} as MatchSeasonJson;
		}
	}

	withId(id: string): MatchSeasonBuilder {
		this.json.id = id;

		return this;
	}

	withName(name: string): MatchSeasonBuilder {
		this.json.name = name;

		return this;
	}

	withStatus(status: string): MatchSeasonBuilder {
		this.json.status = status;

		return this;
	}

	withTournament(tournament: TournamentModel | null): MatchSeasonBuilder {
		this.json.tournament = tournament;

		return this;
	}

	build(): MatchSeasonModel {
		return MatchSeasonModel.fromJson(this.json);
	}
}
