import React from 'react';
import { Col, Row } from 'reactstrap';
import { SelectMenuOptionType } from '../../../../../../models/v2/general/select.model';
import i18next from 'i18next';

export const WidgetV2RefreshTimes = ['never', 'super_slow', 'slow', 'medium', 'fast', 'super_fast'];

export type RefreshTimeValuesType = typeof WidgetV2RefreshTimes[number] | null;

export const refreshTimeOptions: SelectMenuOptionType[] = WidgetV2RefreshTimes.map((el) => {
	return {
		value: el,
		label: i18next.t(el),
		data: el,
	};
});

export const getSelectedRefreshTimeOption = (value: RefreshTimeValuesType): SelectMenuOptionType | null => {
	return refreshTimeOptions.find((el: SelectMenuOptionType) => el.value === value) || null;
};

export const onRefreshTimeChangeFromMainComponent = (
	refreshTimeDataFromSelectMenu: SelectMenuOptionType,
	blockyModel: any,
	widgetModelContent: any,
	updateWidgetModelStateFunc: Function,
) => {
	const selectedValue = (refreshTimeDataFromSelectMenu && (refreshTimeDataFromSelectMenu.value as string)) || null;
	const updatedModel = blockyModel.builder(widgetModelContent).withRefreshTime(selectedValue).build();
	updateWidgetModelStateFunc(updatedModel);
};

export const generateRefreshTimeOptionViewText = (value: RefreshTimeValuesType): JSX.Element | null => {
	if (value) {
		return (
			<Row className='pb-2 pl-4 pr-4' data-qa='refresh-time-view-row'>
				<Col className='text-center'>
					<Row>
						<Col className='text-left'>
							<strong>{i18next.t('refresh_time')}: </strong>
							<span>{i18next.t(value)}</span>
						</Col>
					</Row>
				</Col>
			</Row>
		);
	}
	return null;
};
