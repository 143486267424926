export function blockButton(actionReceived: string, blockableActions: string[], releaseActions: string[]): boolean {
	if (blockableActions.includes(actionReceived)) {
		return true;
	}

	if (releaseActions.includes(actionReceived)) {
		return false;
	}

	return false;
}
