import React, { FunctionComponent } from 'react';
import { Col, Input, InputGroup, InputGroupAddon, InputGroupText, Label, Row } from 'reactstrap';
import { ReduxURLsProps } from '../../constants/redux-urls.attributes';
import { FacebookShareButton, TwitterShareButton } from 'react-share';
import { DATA_QA_ATTRIBUTES } from '../../constants/data-qa.attributes';
import { getLabel, getPlaceholder } from '../../helpers/public-url.helper';

type Properties = {
	t: any;
	url: string;
	propertyName: ReduxURLsProps;
};

const PublicUrlInput: FunctionComponent<Properties> = ({ t, url, propertyName }) => {
	return (
		<Row>
			<Col>
				<Label htmlFor={propertyName}>{getLabel(t, propertyName)}</Label>
				<InputGroup className='social-media-container'>
					<InputGroupAddon addonType='prepend'>
						<InputGroupText className='p-0'>
							<FacebookShareButton
								id={`facebook-share-button-${propertyName}`}
								disabled={url.length === 0}
								url={url}
								className='social-media-button'
								translate='true'
							>
								<div className='w-100 h-100 d-flex justify-content-center align-items-center'>
									<i className='fa fa-facebook' aria-hidden='true'></i>
								</div>
							</FacebookShareButton>
						</InputGroupText>
						<InputGroupText className='p-0'>
							<TwitterShareButton
								id={`twitter-share-button-${propertyName}`}
								disabled={url.length === 0}
								url={url}
								className='social-media-button'
								translate='true'
							>
								<div className='w-100 h-100 d-flex justify-content-center align-items-center'>
									<i className='fa fa-twitter' aria-hidden='true'></i>
								</div>
							</TwitterShareButton>
						</InputGroupText>
					</InputGroupAddon>
					<Input id={DATA_QA_ATTRIBUTES[propertyName]} placeholder={getPlaceholder(t, propertyName)} disabled value={url} />
				</InputGroup>
			</Col>
		</Row>
	);
};

export default PublicUrlInput;
