import { Properties, State } from './Properties/GalleryItemsContainerProps';
import * as React from 'react';
import GalleryItems from './GalleryItems';
import GalleryItem from '../../../../models/gallery/item/GalleryItem';

export default class GalleryItemsContainer extends React.Component<Properties, State> {
	onItemsChange(items: GalleryItem[]) {
		this.props.onChange(items);
	}
	onQuickWatermarkChange(isEnabled: boolean) {
		this.props.onQuickWatermarkChange(isEnabled);
	}

	onWatermarkRemoval(items: GalleryItem[]) {
		this.props.onWatermarkRemoval(items);
	}

	onWatermarkAdd(items: GalleryItem[]) {
		this.props.onWatermarkAdd(items);
	}

	render() {
		const { t, project, items, analytics, quickWatermarkChecked } = this.props;
		return (
			<GalleryItems
				onWatermarkRemoval={this.onWatermarkRemoval.bind(this)}
				onWatermarkAdd={this.onWatermarkAdd.bind(this)}
				quickWatermarkChecked={quickWatermarkChecked}
				t={t}
				project={project}
				onQuickWatermarkChange={this.onQuickWatermarkChange.bind(this)}
				onItemsChange={this.onItemsChange.bind(this)}
				items={items ? items : []}
				analytics={analytics}
			>
				{this.props.children}
			</GalleryItems>
		);
	}
}
