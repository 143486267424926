import React from 'react';

type Properties = {
	errorMessage: string;
	t: any;
	isValueSelected: boolean;
	arePropsValid: boolean;
};

const ErrorHandler: React.FunctionComponent<Properties> = ({ errorMessage, t, isValueSelected, arePropsValid }) => {
	return <h6 className={'text-danger'}>{isValueSelected && !arePropsValid && t(errorMessage)}</h6>;
};

export default ErrorHandler;
