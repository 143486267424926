import { WidgetTypes, WidgetTypesV2 } from '../../../views/Partials/Blocky/constants/block.types';

export const getSports = (state: any) => state.sports.sportsTypes;

export const modifyContentResponseAsRelated = (contentResponse: any) => {
	return Object.assign(
		{},
		{
			...contentResponse,
			data: {
				...contentResponse.data,
				data: contentResponse.data.data.related_content, // this is the new property that we will receive when we pass '?optional_data=related_content' to api call
			},
		},
	);
};

// this method is for backward compatibility of basketball/tennis widgets
// because we transfered tennis and basketball widgets as type "widget_smp_V2" instead of "widget_smp"
// check issues SFE-3878 and SFE-4035
// we can remove this logic after we are sure that the editors do not EDIT refactored widgets in old articles
export const transferRefactoredWidgetsAsV2 = (bodyData: Record<any, any>[] | null | undefined): Record<any, any>[] => {
	// isBasketballOrTennisWidget should NOT be used anywhere else because its name is misleading -> it should stays only in this scope
	const isBasketballOrTennisWidget = (bodyObj: Record<any, any>): boolean => {
		const widgetType = bodyObj && bodyObj.data && bodyObj.data.widget_type;

		if (widgetType) {
			const basketballTypes = [WidgetTypesV2.basketballSingleEvent, WidgetTypesV2.basketballLivescore, WidgetTypesV2.basketballStandings];
			const tennisTypes = [
				WidgetTypesV2.tennisSingleEvent,
				WidgetTypesV2.tennisLivescore,
				WidgetTypesV2.tennisRanking,
				WidgetTypesV2.tennisPlayoff,
			];

			return basketballTypes.includes(widgetType) || tennisTypes.includes(widgetType);
		}

		return false;
	};

	if (bodyData && bodyData.length > 0) {
		return bodyData.map((bodyObj: Record<any, any>) => {
			if (bodyObj.type === WidgetTypes.widget && isBasketballOrTennisWidget(bodyObj)) {
				return { ...bodyObj, type: WidgetTypesV2.widget };
			}
			return bodyObj;
		});
	}

	return [];
};
