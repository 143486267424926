import React, { FunctionComponent, useState, useEffect } from 'react';
import TournamentGroupModel from '../../../../../models/v2/Tournament/Group/TournamentGroupModel';
import TournamentsGroupBody from '../Shared/BodyCard/TournamentsGroupBody';
import TournamentsGroupInfo from '../Shared/InfoCard/TournamentsGroupInfo';
import { getTournamentsGroupByCode } from '../../helpers/TournamentsGroupsService';
import { DisabledSaveModel } from '../../helpers/TournamentsGroupsUiService';
import './LoaderTournamentsGroupsEdit.scss';
import Loader from '../../../../Partials/Shared/loader/Loader';

type Props = {
	t: any;
	tournamentId: number;
	location: any;
	history: any;
};

const TournamentsGroupEdit: FunctionComponent<Props> = ({ t, location, history }) => {
	const [disabledSave, setDisabledSave] = useState(new DisabledSaveModel());
	const [defaultTournamentsGroup, setDefaultTournamentsGroup] = useState(new TournamentGroupModel());
	const [tournamentsGroup, setTournamentsGroup] = useState(new TournamentGroupModel());

	useEffect(() => {
		const urlParams = location.pathname.split('/');
		const lastParam = urlParams[urlParams.length - 1];
		getTournamentsGroupByCode(lastParam, t('error_loading_tournaments_group')).then((group) => {
			setTournamentsGroup(group);
			setDefaultTournamentsGroup(group);
		});
	}, []);

	document.title = t('tournament_groups_edit_title');

	if (!defaultTournamentsGroup.code) {
		return (
			<div className='loader-edit-tournaments-group'>
				<Loader className='loading-overlay-h-auto' />
			</div>
		);
	} else {
		return (
			<>
				<TournamentsGroupInfo
					isNewGroup={false}
					history={history}
					t={t}
					tournamentsGroup={tournamentsGroup}
					setTournamentsGroup={setTournamentsGroup}
					defaultTournamentsGroup={defaultTournamentsGroup}
					setDefaultTournamentsGroup={setDefaultTournamentsGroup}
					disabledSave={disabledSave}
					setDisabledSave={setDisabledSave}
				/>

				<TournamentsGroupBody
					t={t}
					tournamentsGroup={tournamentsGroup}
					setTournamentsGroup={setTournamentsGroup}
					disabledSave={disabledSave}
					setDisabledSave={setDisabledSave}
				/>
			</>
		);
	}
};

export default TournamentsGroupEdit;
