import React, { FunctionComponent } from 'react';
import { Col, Label, Row } from 'reactstrap';
import * as ReactSortableHOC from 'react-sortable-hoc';
import RelatedContentSortableList from './sortable-list';
import { extractRelatedPropertiesNameByUrl } from '../../../tags-refactored/helpers/utils';
import Related from '../../../../../../models/related/Related';
import { generateRelatedUpdateActionByContentType } from '../../helpers/general.helper';
import { extractContentModeBasedOnUrl, extractMainContentTypeBasedOnUrl } from '../../../../../../global-helpers/global.helpers';
import { connect } from 'react-redux';

type Properties = {
	t: any;
	relatedContentInRedux: Related[];
	updateRelatedContent: (rel: Related[]) => void;
};

const SortableList = ReactSortableHOC.SortableContainer(RelatedContentSortableList);

const AddedElementsWrapper: FunctionComponent<Properties> = ({ t, relatedContentInRedux, updateRelatedContent }) => {
	const onSortRelatedItem = (sortEnd: ReactSortableHOC.SortEnd) => {
		const allItems = [...relatedContentInRedux];
		const newRelatedOrder = ReactSortableHOC.arrayMove(allItems, sortEnd.oldIndex, sortEnd.newIndex);
		updateRelatedContent(newRelatedOrder);
	};

	return (
		<Row className='mb-4'>
			<Col>
				<Label className='font-weight-bold'>{t('selected_related_objects')}</Label>
				<SortableList t={t} onSortEnd={onSortRelatedItem} key={relatedContentInRedux.length} />
			</Col>
		</Row>
	);
};

function mapStateToProps(state: any) {
	const relatedPropertiesNestedProp = extractRelatedPropertiesNameByUrl();
	const relatedContent = relatedPropertiesNestedProp ? state.tempContentRelated[relatedPropertiesNestedProp] : [];

	return {
		relatedContentInRedux: relatedContent || [],
	};
}

function mapDispatchToProps(dispatch: Function) {
	const contentType = extractMainContentTypeBasedOnUrl();
	const contentMode = extractContentModeBasedOnUrl();
	const generatedActionByContentType = generateRelatedUpdateActionByContentType(contentType, contentMode);

	return {
		updateRelatedContent: (related: Related[]) => generatedActionByContentType && dispatch(generatedActionByContentType(related)),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(AddedElementsWrapper);
