import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const useRestOnDemand = <T>(promise: () => Promise<T> | void, skip: boolean = false) => {
	const [t] = useTranslation();
	const [refetchTrigger, setRefetchTrigger] = useState<number>();
	const [data, setData] = useState<T>();

	const returnsPromise = (fn: () => any): fn is () => Promise<T> => {
		return !skip;
	};

	useEffect(() => {
		if (skip || !returnsPromise(promise)) {
			return;
		}

		promise()
			.then((response) => setData(response))
			.catch((e) => {
				toast.error(t('error_fetching_data'));
				console.error(e);
			});
	}, [refetchTrigger, skip]);

	const refetchOnDemand = useCallback(() => setRefetchTrigger(Math.random()), []);

	return { data, refetchOnDemand };
};

export default useRestOnDemand;
