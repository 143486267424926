import React, { FunctionComponent, useEffect, useState } from 'react';
import MainMediaModel from '../../../../../../../models/v2/main-media/main-media.model';
import { videoResourceType } from '../../../../../../Partials/Sidebar/media-refactored/constants/general';
import {
	extractExternalObjectFromArray,
	selectionToMainMediaModel,
} from '../../../../../../Partials/Sidebar/media-refactored/helpers/general';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../../../../store/store';
import MainMedia from '../../../../../../../models/mainmedia/MainMedia';
import { MainContentMediaContainer } from './external-main-content-media-select.component';
import { findEmbedVideoCode } from '../../../../../../Partials/Sidebar/media-refactored/helpers/external-main-media';
import { MainMediaTypes } from '../components/helpers/main-media-type-select.helper';
import { MediaVideoEmbed } from './external-main-media-video-embed.component';
import { setMainMediaData } from '../helpers/live-blog-image.helper';

type Properties = {
	t: any;
	mainMedia: MainMediaModel[];
	setLiveBlogMainMedia: (mainMediaModel: MainMediaModel[]) => void;
	mainMediaType: string;
};

export const ExternalMainMediaContainer: FunctionComponent<Properties> = ({ t, mainMedia, mainMediaType, setLiveBlogMainMedia }) => {
	const [mediaData, setMediaData] = useState<MainMediaModel>(
		extractExternalObjectFromArray(mainMedia, mainMediaType) || new MainMediaModel(),
	);
	const currentProject = useSelector((state: AppState) => state.project.currentProject);

	useEffect(() => {
		setMediaData(extractExternalObjectFromArray(mainMedia, mainMediaType) || new MainMediaModel());
	}, [mainMedia, mainMediaType]);

	const onExternalMainMediaAdd = (data: MainMediaModel) => {
		if (data && data.data && mainMediaType !== MainMediaTypes.embed) {
			const mediaDataModel = selectionToMainMediaModel(data.data, mainMediaType);
			const oldMainMediaToNew = { ...mediaDataModel } as MainMediaModel;
			const newData = setMainMediaData(mainMedia, mediaDataModel, oldMainMediaToNew);
			setMediaData(extractExternalObjectFromArray(newData, mainMediaType) || new MainMediaModel());
			setLiveBlogMainMedia(newData);
			return mediaDataModel;
		} else {
			const mediaDataModel = selectionToMainMediaModel(data.data, mainMediaType, videoResourceType);
			const oldMainMediaToNew = { ...mediaDataModel } as MainMediaModel;
			const newData = setMainMediaData(mainMedia, mediaDataModel, oldMainMediaToNew);
			setMediaData(extractExternalObjectFromArray(newData, mainMediaType) || new MainMediaModel());
			setLiveBlogMainMedia(newData);
			return oldMainMediaToNew;
		}
	};

	const onRemoveElement = (type: string, subType: string) => {
		const withoutRemovedEl = mainMedia.filter((el) => !(el.resourceType === type && el.resourceSubtype === subType));
		setLiveBlogMainMedia(withoutRemovedEl);
	};

	const mainMediaModel = mediaData as MainMedia;

	return (
		<>
			{mainMediaType === MainMediaTypes.gallery || mainMediaType === MainMediaTypes.video ? (
				<MainContentMediaContainer
					value={mainMediaModel}
					index={1}
					key={`${mainMediaType}-type-media-key`}
					onContentSelect={onExternalMainMediaAdd}
					t={t}
					project={currentProject}
					contentType={mainMediaType}
					onRemove={onRemoveElement}
				/>
			) : (
				<MediaVideoEmbed
					t={t}
					onChange={onExternalMainMediaAdd}
					embedCode={findEmbedVideoCode(mainMedia)}
					contentType={mainMediaType}
					index={2}
					onRemove={onRemoveElement}
				/>
			)}
		</>
	);
};
