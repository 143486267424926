import React, { useState } from 'react';
import Select from 'react-select';
import { analyticsService } from '../../../../App';
import { Properties } from './properties/filters.properties';

const Filters: React.FunctionComponent<Properties> = ({
	onChangeDate,
	onChangeType,
	feedSources,
	clearFilters,
	sourceTab,
	selectedFeedId,
	dateFilterOptions,
	selectedFilterSources,
	t,
	selectedDate,
	sourcePostsRequest,
}) => {
	const [toggleFilters, setToggleFilters] = useState(false);

	const toggle = () => {
		analyticsService.sendEvent('Click', 'News tracker', `${toggleFilters === false ? 'Show' : 'Hide'} filters`);
		setToggleFilters(toggleFilters === false ? true : false);
	};

	const filtersClear = () => {
		analyticsService.sendEvent('Click', 'News tracker', 'Clear filters');
		clearFilters(sourceTab, selectedFeedId);
	};

	const applyFilters = () => {
		analyticsService.sendEvent('Click', 'News tracker', `Apply filters ${selectedFilterSources} ${dateFilterOptions(t)}`);
		sourcePostsRequest(sourceTab, selectedFeedId, selectedDate.value, selectedFilterSources);
	};

	return (
		<React.Fragment>
			<div className={`d-flex justify-content-center ${!toggleFilters && 'mb-2'}`}>
				<button className='btn btn-link mr-2 ml-2' onClick={toggle}>
					{toggleFilters ? `${t('hide')}` : `${t('show')}`} {t('filters')}
				</button>
				{!toggleFilters && (
					<button className='btn btn-light mr-2 ml-2' onClick={filtersClear}>
						{t('clear')} {t('filters')}
					</button>
				)}
			</div>
			{toggleFilters && (
				<div className='m-1'>
					<div className='d-flex w-85 m-1'>
						<Select
							value={selectedDate}
							options={dateFilterOptions(t)}
							onChange={onChangeDate}
							className='w-100 m-1'
							placeholder={selectedDate ? selectedDate : t('select')}
							noOptionsMessage={(inputValue) => inputValue && t('no_options')}
						/>
					</div>
					<div className='d-flex w-85 m-1'>
						<Select
							value={selectedFilterSources}
							options={feedSources}
							onChange={onChangeType}
							className='w-100 m-1'
							isMulti
							placeholder={t('select')}
							isDisabled={sourceTab === 'All' ? false : true}
							noOptionsMessage={(inputValue) => inputValue && t('no_options')}
						/>
					</div>
					<div className='d-flex flex-row justify-content-between'>
						<button onClick={applyFilters} className='btn btn-primary w-50 m-2'>
							{t('apply')}
						</button>
						<button onClick={filtersClear} className='btn btn-outline-primary w-50 m-2'>
							{t('clear')}
						</button>
					</div>
				</div>
			)}
		</React.Fragment>
	);
};

export default Filters;
