import React from 'react';
import { connect } from 'react-redux';
import { Button, Col, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Tooltip } from 'reactstrap';
import { ModalProperties, State } from './properties/gallery-items-update-modal.properties';
import GalleryItem from '../../../../../models/gallery/item/GalleryItem';
import FootballConnectionsContainer from '../../../../Partials/Sidebar/tags/subcomponents/football-connections/football-connections-container';
import Related from '../../../../../models/related/Related';
import Select from 'react-select';
import { imageOwnersToSelectOptions, ownerToSelectOption } from '../../../Images/Subcomponents/helpers/image-cells.helpers';
import EnumItem from '../../../../../models/enum/EnumItem';
import { defineContentToUpdate } from '../helpers/gallery-items.helpers';
import TypesSelect from '../../../../Partials/Sidebar/GeneralContentAttributes/subcomponents/type-select/type-select';
import AuthorSelectMultiContainer from '../../../../Partials/Sidebar/GeneralContentAttributes/subcomponents/AuthorSelectMulti/AuthorSelectMultiContainer';
import Author from '../../../../../models/author/Author';
import { featuresService, sportsConnectionsService } from '../../../../../App';
import { FeatureTypes } from '../../../../../services/feature-service/features.enum';
import {
	filterFootballRelated,
	removeDuplicateItems,
} from '../../../../Partials/Shared/advanced-content-filter/subcomponents/v2/sports-select/sports-connections.helper';
import SportsTypesModel from '../../../../../models/v2/sports-types/sports-types.model';
import { SportsConnectionsContainer } from '../../../../Partials/Shared/advanced-content-filter/subcomponents/v2/sports-select/sports-connections-container';
import { SportsTypeSelect } from '../../../../Partials/Shared/advanced-content-filter/subcomponents/v2/sports-select/sports-type-select.component';

class UpdateGalleryItemsModal extends React.Component<ModalProperties, State> {
	constructor(props: any) {
		super(props);
		this.state = {
			selectedProvider: null,
			selectedType: null,
			selectedAuthors: [],
			selectedDescription: null,
			tooltipOpen: false,
			sportsType: featuresService.areFeaturesEnabled([FeatureTypes.FOOTBALL, FeatureTypes.FOOTBALL_CONNECTIONS])
				? this.props.sports[0]
				: ({} as SportsTypesModel),
			footballRelated: [],
			sportsRelated: [],
		};
	}

	componentDidUpdate(prevProps: ModalProperties) {
		if (prevProps.content && prevProps.content.length > 0 && this.props.content.length === 0) {
			this.closeModal();
		}
	}

	closeModal = () => this.props.toggleModal(false);

	updateContent = () => {
		const content = defineContentToUpdate(this.props.updateAction.value, this.state);
		this.props.onContentUpdate(content);
		this.setState({
			...this.state,
			footballRelated: [],
			sportsRelated: [],
			selectedProvider: null,
			selectedType: null,
			selectedAuthors: [],
			selectedDescription: null,
		});
		this.closeModal();
	};

	onFootballRelatedChange = (footballRelated: Related[]) => this.setState({ ...this.state, footballRelated });

	onSportsRelatedChange = (related: Related[]) => {
		const { sportsRelated, sportsType } = this.state;
		const tempData = sportsRelated.filter((element: Related) => element.data.sport !== sportsType.sport);
		let relatedData = related.concat(tempData);

		this.setState({ ...this.state, sportsRelated: removeDuplicateItems(relatedData) });
	};

	onOwnerChange = (selection: any) => {
		const owner = EnumItem.builder().withId(selection.value).withName(selection.label).build();
		this.setState({ ...this.state, selectedProvider: owner });
	};

	onTypeChange = (selection: any) => this.setState({ ...this.state, selectedType: selection });

	onAuthorChange = (authors: Author[]) => this.setState({ ...this.state, selectedAuthors: authors });

	showSelectedImages = () => {
		if (this.props.updateAction.value === 'deleteWatermark') {
			return this.props.watermarkedContent;
		} else if (this.props.updateAction.value === 'addWatermark') {
			return this.props.contentForWatermark;
		} else return this.props.content;
	};

	displayWatermarkModalOptions = () => {
		if (this.props.updateAction.value === 'deleteWatermark') {
			return this.props.watermarkedContent.length > 0;
		} else if (this.props.updateAction.value === 'addWatermark') {
			return this.props.contentForWatermark.length > 0;
		} else return true;
	};

	displaySelectedImages = () => {
		if (this.showSelectedImages().length === 0) {
			if (this.props.updateAction.value === 'deleteWatermark') {
				return this.props.t('no_watermarked_images');
			} else if (this.props.updateAction.value === 'addWatermark') {
				return this.props.t('all_images_are_watermarked');
			}
		}
		return (
			<div>
				{this.props.updateAction.value !== 'delete' && (
					<div className='mb-3'>
						<strong>{this.props.t('selected_images')}:</strong>
					</div>
				)}
				<div className='d-flex flex-wrap justify-content-center mb-3'>
					{this.showSelectedImages().map((item: GalleryItem) => {
						return (
							<div key={item.data.id} className='gallery-item-update-image m-1'>
								<img className='rounded' height='70' src={item.data.urls.uploaded.thumbnail} alt={item.data.description} />
								<Button
									id='gallery-items-toggle-update'
									onClick={() => this.props.toggleItemsForUpdate(item.data.id)}
									className='btn btn-sm btn-danger mt-0 mr-0 delete-button'
								>
									<i className='fa fa-close' />
								</Button>
							</div>
						);
					})}
				</div>
			</div>
		);
	};

	toggle = () => this.setState((state: State) => ({ ...state, tooltipOpen: !this.state.tooltipOpen }));

	onSportsTypesSelect = (type: any) => {
		this.setState({ ...this.state, sportsType: type });
	};

	render() {
		const { sportsType, footballRelated, sportsRelated } = this.state;
		const { t, isToggledModal, content, updateAction, owners, types, authors, sports } = this.props;
		if (updateAction && content.length > 0) {
			return (
				<Modal isOpen={isToggledModal} className='rounded'>
					<ModalHeader className={`${updateAction.value === 'delete' ? 'bg-danger' : 'bg-info'}`} toggle={this.closeModal}>
						{updateAction.title}
					</ModalHeader>
					<ModalBody>
						<Row>
							<Col>
								{updateAction.value === 'delete' && (
									<div className='mb-3 text-center'>
										<strong>
											{t('attention_modal')} {t('remove_notice')}:{' '}
										</strong>
									</div>
								)}
								{updateAction.value === 'addWatermark' && (
									<div className='d-flex' id='gallery-items-edit-modal'>
										<div className='ml-auto' style={{ top: -23, right: 0 }}>
											<i className='fa fa-info-circle text-muted mr-1' />
										</div>
										<Tooltip placement='right' isOpen={this.state.tooltipOpen} target='gallery-items-edit-modal' toggle={this.toggle}>
											{t('selected_images_for_watermark')}
										</Tooltip>
									</div>
								)}
								<div className='mb-3 text-center'>{this.displaySelectedImages()}</div>
								{updateAction.value === 'sportsConnections' && (
									<SportsTypeSelect sports={sports} t={t} selectedSportsType={sportsType} onSportsTypeSelect={this.onSportsTypesSelect} />
								)}
								{sportsType && sportsConnectionsService.isFootballSelected(sportsType.sport)
									? updateAction.value === 'sportsConnections' && (
											<div className='mb-3'>
												<Label>{t('football_connections')}</Label>
												<FootballConnectionsContainer
													inputId='gallery-items-sports-connections-select'
													related={filterFootballRelated(footballRelated)}
													footballApiUrl={this.props.project.footballApiUrl}
													onSelect={(related: Related[]) => this.onFootballRelatedChange(related)}
													allowMoreFootballConnections={true}
													t={t}
													contentLanguage={this.props.contentLanguage}
												/>
											</div>
									  )
									: updateAction.value === 'sportsConnections' && (
											<div className='mb-3'>
												<Label htmlFor='sports-connections'>{t('sports_connections')}</Label>
												<SportsConnectionsContainer
													related={sportsRelated}
													onSportsConnectionSelect={(related: Related[]) => this.onSportsRelatedChange(related)}
													contentLanguage={this.props.contentLanguage}
													t={t}
													sportType={sportsType.sport}
													sportsEntities={sportsType.entity_types}
													allowMoreEntityTypes={true}
												/>
											</div>
									  )}
								{updateAction.value === 'origin' && (
									<div className='mb-3'>
										<Label>{t('origins')}</Label>
										<Select
											inputId='gallery-items-origin-select'
											placeholder={t('select_origin')}
											value={this.state.selectedProvider ? ownerToSelectOption(this.state.selectedProvider) : null}
											className={'mb-1'}
											options={imageOwnersToSelectOptions(owners)}
											onChange={this.onOwnerChange}
										/>
									</div>
								)}
								{updateAction.value === 'type' && (
									<div className='mb-3'>
										<Label>{t('type')}</Label>
										<TypesSelect isClearable selectedType={this.state.selectedType} t={t} types={types} onTypeSelect={this.onTypeChange} isMulti />
									</div>
								)}
								{updateAction.value === 'authors' && (
									<div className='mb-3'>
										<Label>{t('authors')}</Label>
										<AuthorSelectMultiContainer
											authors={authors}
											selectedAuthors={this.state.selectedAuthors}
											onAuthorSelect={this.onAuthorChange}
											t={t}
										/>
									</div>
								)}
								{this.displayWatermarkModalOptions() && <div className='mb-3 text-center'>{t('continue_confirmation')}</div>}
							</Col>
						</Row>
					</ModalBody>
					{this.displayWatermarkModalOptions() && (
						<ModalFooter>
							<Button
								color={`${updateAction.value === 'delete' ? 'danger' : 'primary'}`}
								id={`gallery-items-${updateAction.value === 'delete' ? 'delete' : 'update'}-button`}
								className='mr-auto'
								onClick={this.updateContent}
							>
								{updateAction.label}
							</Button>
							<Button id='gallery-items-edit-cancel' color='base' onClick={this.closeModal}>
								{t('cancel')}
							</Button>
						</ModalFooter>
					)}
				</Modal>
			);
		}

		return (
			<Modal isOpen={isToggledModal}>
				<ModalHeader className='bg-info'>
					<div>{t('no_selected_images')}</div>
				</ModalHeader>
				<ModalBody>
					<Row>
						<Col>
							<div className='text-center p-3'>{t('please_select_images')}</div>
						</Col>
					</Row>
				</ModalBody>
				<ModalFooter>
					<Button id='cancel-button' color='base' onClick={this.closeModal}>
						{t('cancel')}
					</Button>
				</ModalFooter>
			</Modal>
		);
	}
}

function mapStateToProps(state: any) {
	return {
		owners: state.imageOwners.owners,
		types: state.types.imageTypes,
		authors: state.author.authors,
		sports: state.sports.sportsTypes,
	};
}

export default connect(mapStateToProps, null)(UpdateGalleryItemsModal) as React.ComponentType<any>;
