import FeatureService from '../../../../services/feature-service/feature.service';
import { FeatureTypes } from '../../../../services/feature-service/features.enum';
import { featuresService } from '../../../../App';

export default class AuditLogFeatureValidation {
	/**
   +   * Performs a check if the feature audit_log is enabled and if the current user has permissions to view
   +   * the audit log feature
   +   * @param featureService
   +   * @param userId
   +   */
	public isFeatureEnabled = (featureService: FeatureService, userId: string) => {
		const isAuditLogEnabled = featureService.checkFeatureIsSetAndEnabled(FeatureTypes.AUDIT_LOG);
		const isFeatureEnabledForCurrentUser = this.isFeatureEnabledForCurrentUser(userId);

		return isAuditLogEnabled && isFeatureEnabledForCurrentUser;
	};

	/**
   +   * Performs a check if the current user is part of the admins that can see and user the feature audit_log.
   +   * If no admins are added to the feature configuration it won't be visible to anyone.
   +   * @param userId
   +   */
	private isFeatureEnabledForCurrentUser = (userId: string) => {
		const featureConfig = featuresService.getFeatureConfig(FeatureTypes.AUDIT_LOG);
		const adminIds = featureConfig && featureConfig.admins ? (featureConfig.admins as string[]) : [];

		return adminIds.length > 0 ? adminIds.includes(userId) : false;
	};
}
