import React, { FunctionComponent } from 'react';
import { DATA_QA_ATTRIBUTES } from '../../../../../../Partials/Sidebar/general-content/constants/data-qa.attributes';
import Project from '../../../../../../../models/project/Project';
import MainMediaModel from '../../../../../../../models/v2/main-media/main-media.model';
import ImageUploadButtonOperationsContainer from './live-blog-image-upload-operations.component';
import { LiveBlogMainImageEdit } from './live-blog-main-image-edit.component';
import { Col } from 'reactstrap';
import { LiveBlogCropMainImage } from './live-blog-crop-image.component';
import '../styles/style.scss';

type Properties = {
	t: any;
	project: Project;
	onImageUploadSuccess: (id: string, isAutoCrop?: boolean) => any;
	toggleUploading: (uploading: boolean) => any;
	mainMedia: MainMediaModel[];
};

export const MainMediaImageOperations: FunctionComponent<Properties> = ({
	t,
	mainMedia,
	onImageUploadSuccess,
	toggleUploading,
	project,
}) => {
	return (
		<>
			<div className='container image-operations-container'>
				<Col className='image-operations-buttons' col='4' lg='4' md='12' sm='14' xs='16'>
					<ImageUploadButtonOperationsContainer
						t={t}
						imageUploadButtonId={DATA_QA_ATTRIBUTES.LIVE_BLOG_IMAGE_UPLOAD_CONTAINER_OPERATIONS}
						project={project}
						onImageUploadSuccess={onImageUploadSuccess}
						display
						toggleUploading={toggleUploading}
					/>
				</Col>
				<Col className='image-operations-buttons' col='4' lg='4' md='12' sm='14' xs='16'>
					<LiveBlogMainImageEdit mainMedia={mainMedia} project={project} t={t} />
				</Col>
				<Col className='image-operations-buttons' col='4' lg='4' md='12' sm='14' xs='16'>
					<LiveBlogCropMainImage mainMedia={mainMedia} project={project} t={t} />
				</Col>
			</div>
		</>
	);
};
