import React from 'react';
import ContentWidgetEdit from './content-block-edit.component';
import ContentWidgetView from './content-block-view.component';
import { ViewTypes } from '../../../constants/general.constants';
import { Properties, State } from './properties/content.properties';
import { connect } from 'react-redux';
import ContentWidget from './models/content-widget.model';
import HttpService from '../../../../../../services/rest/HttpService';
import { remapResponseToContent } from './helpers/content-blocks.helper';
import ContentNotFoundController from '../content-not-found-controller.component';

class ContentBlock extends React.Component<Properties, State> {
	constructor(props: any) {
		super(props);
		this.state = {
			isLoading: false,
			content: ContentWidget.builder().build(),
		};
	}

	componentDidMount() {
		const { block } = this.props;

		if (block && block.data.id) {
			this.getContent(block.data.id);
		}
	}

	componentDidUpdate(prevProps: Readonly<Properties>, prevState: Readonly<State>) {
		if (this.state.content.id !== prevState.content.id) {
			this.getContent(this.state.content.id);
		}
	}

	getContent = (contentId: string) => {
		this.toggleIsLoadingState(true);
		const header = { Project: this.props.currentProject.domain };
		if (contentId) {
			HttpService.get(`/${this.props.block.type}s/${contentId}`, null, header)
				.then((response: any) => {
					let content = remapResponseToContent(response.data.data);
					this.updateContentState(content);
					this.toggleIsLoadingState(false);
				})
				.catch((err: any) => {
					err.response && err.response.status === 404 && this.props.setIsContentFound(false);
					this.toggleIsLoadingState(false);
				});
		}
	};

	updateContentState = (content: any) => {
		this.setState({ ...this.state, content });
	};

	toggleIsLoadingState(isLoading: boolean) {
		this.setState({ ...this.state, isLoading });
	}

	render() {
		const { block, view, t, renderView, contentLanguage, entityType } = this.props;

		if (view === ViewTypes.edit) {
			return (
				<ContentWidgetEdit
					currentProject={this.props.currentProject}
					block={block}
					content={this.state.content}
					contentLanguage={contentLanguage}
					entityType={entityType}
					t={t}
					updateContent={this.updateContentState}
				/>
			);
		} else if (view === ViewTypes.normal) {
			return renderView(
				<ContentWidgetView
					content={this.state.content}
					isLoading={this.state.isLoading}
					type={block.type}
					startAt={block.data.startAt}
					data={block.data}
					t={t}
				/>,
			);
		}
	}
}

function mapStateToProps(state: any) {
	return {
		currentProject: state.project.currentProject,
		lang: state.profile.profile.language,
	};
}

export default connect(mapStateToProps)(ContentNotFoundController(ContentBlock)) as React.ComponentType<any>;
