import slugify from '@mediaplatform/slugify';
import { emojiRegex, slugRegex } from '../constants/slug.regex';
import { store } from '../../../../../store/store';

export const removeTextEmojis = (text: string = '') => {
	return text ? text.replace(emojiRegex(), '') : '';
};

const customSlugifyDoubleChars = (text: string) => {
	// Perform multi-character replacements first
	const multiCharMap = {
		μπ: 'mp',
		ντ: 'd',
		γκ: 'g',
		γγ: 'g',
		τσ: 'ts',
		τζ: 'tz',
	};

	// Replace each multi-character set first because the slugify library will replace each character individually
	Object.keys(multiCharMap).forEach((key) => {
		text = text.replace(key, multiCharMap[key]);
	});

	return text;
};

export const slugifyString = (text: string, removeEmojis: boolean = true) => {
	const reduxStore = store.getState();
	const languageForLocale =
		(reduxStore &&
			reduxStore.project &&
			reduxStore.project.currentProject &&
			reduxStore.project.currentProject.languages &&
			reduxStore.project.currentProject.languages.defaultLanguageCode &&
			reduxStore.project.currentProject.languages.defaultLanguageCode.languageCode) ||
		undefined;

	const textWithoutEmojis = removeEmojis ? removeTextEmojis(text) : text;
	const updatedText = customSlugifyDoubleChars(textWithoutEmojis);
	return slugify(updatedText, { replacement: '-', remove: slugRegex, lower: true, locale: languageForLocale });
};
