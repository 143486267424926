import * as React from 'react';
import { FormGroup, Label, Input } from 'reactstrap';

type ShortNameProps = {
	t: any;
	onChange: any;
	value: string;
	type: string;
};

export const ShortName: React.FunctionComponent<ShortNameProps> = ({ onChange, t, value, type }) => {
	return (
		<FormGroup>
			<Label htmlFor={`${type}-manual-data-entry-name`}>{t(`${type}`)}</Label>
			<Input
				type='text'
				id={`${type}-manual-data-entry-name`}
				value={value ? value : ''}
				onChange={onChange}
				className='form-control'
				minLength={3}
				maxLength={20}
				placeholder={t(`team_short_name`)}
			/>
			{value && value.length > 0 && value.length < 3 && <small className='text-danger'>{t('short_name_validation')}</small>}
		</FormGroup>
	);
};
