import React from 'react';
import { CropModel, Properties, State } from './properties/image-crop-container.properties';
import Image from '../../../../models/image/Image';
import HttpService from '../../../../services/rest/HttpService';
import ModelMapper from '../../../../models/ModelMapper';
import BeatLoader from 'react-spinners/BeatLoader';
import { css } from '@emotion/core';
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import Cropper from 'cropperjs';
import 'cropperjs/dist/cropper.css';
import './image-crop-container.scss';
import Select from 'react-select';
import _ from 'lodash';
import { getCropModelFromCropperInfo, imageUrlsToRequestImageUrls } from './helpers/image-crop-container.helper';
import { toast } from 'react-toastify';
import { aspectRatioSelectOptions } from './helpers/aspect-ratio-select-options';

const override = css`
	display: block;
	margin: 50px;
	text-align: center;
	border-color: red;
`;

export default class ImageCropContainer extends React.Component<Properties, State> {
	private cropper = {} as Cropper;

	constructor(props: Properties) {
		super(props);
		this.state = {
			image: {} as Image,
			imageLoading: true,
			imageRequestFailed: false,
			cropInfo: {} as CropModel,
			aspectRatioSelected: '16x9',
		};
	}

	componentDidMount(): void {
		this.requestImage();
	}

	componentDidUpdate(prevProps: Readonly<Properties>): void {
		const { isWatermarkAdded, isWatermarkRemoved } = this.props;
		if (
			prevProps.imageId !== this.props.imageId ||
			isWatermarkAdded !== prevProps.isWatermarkAdded ||
			isWatermarkRemoved !== prevProps.isWatermarkRemoved ||
			(prevProps.open === false && this.props.open === true)
		) {
			this.requestImage();
		}
	}

	private requestImage() {
		if (this.props.open) {
			const { imageId, currentProject } = this.props;

			const headers = { Project: currentProject.domain };

			this.setState({ ...this.state, imageLoading: true });
			if (imageId) {
				HttpService.get(`/images/${imageId}`, null, headers)
					.then((response: any) => {
						const image = ModelMapper.remapImageFromResponse(response.data.data);

						this.setImageRequestFailedState(false);
						this.setImageState(image, false);
					})
					.catch((error: any) => {
						this.setImageRequestFailedState(true);
					});
			}
		}
	}

	private updateImage() {
		const { currentProject, t } = this.props;
		const { image, aspectRatioSelected, cropInfo } = this.state;

		const headers = { Project: currentProject.domain };

		HttpService.patch(`/images/${image.id}`, imageUrlsToRequestImageUrls(cropInfo, aspectRatioSelected), headers)
			.then((response: any) => {
				toast.success(t('image_update_success'));
				this.setAspectRatioSelectedState('16x9');

				this.props.onClose();
			})
			.catch((error: any) => {
				toast.error(t('image_update_failed'));
			});
	}

	private setAspectRatioSelectedState(aspectRatio: string) {
		this.setState({
			...this.state,
			aspectRatioSelected: aspectRatio,
		});
	}

	private setImageState(image: Image, isLoading: boolean) {
		this.setState({
			...this.state,
			imageLoading: isLoading,
			image,
		});
	}

	private setImageRequestFailedState(failed: boolean) {
		this.setState({
			...this.state,
			imageRequestFailed: failed,
		});
	}

	private setCropInfoStateDebounced = _.debounce((cropInfo: CropModel) => {
		this.setState({
			...this.state,
			cropInfo,
		});
	}, 500);

	loadCropper(img: any, aspectRatio: number) {
		let self = this;

		if (typeof this.cropper.destroy === 'function') {
			this.cropper.destroy();
		}

		this.cropper = new Cropper(img, {
			aspectRatio: aspectRatio !== 0 ? aspectRatio : 16 / 9,
			zoomable: false,
			viewMode: 1,
			checkCrossOrigin: false,
			crop(event: any) {
				let cropModel = getCropModelFromCropperInfo(event.detail.x, event.detail.y, event.detail.width, event.detail.height);
				self.setCropInfoStateDebounced(cropModel);
			},
		});
	}

	onAspectRationSelect = (selection: any) => {
		selection && selection.label && this.setAspectRatioSelectedState(selection.label);
		this.cropper.setAspectRatio(selection.value);
	};

	displayImage() {
		const { image, imageLoading, imageRequestFailed } = this.state;
		const { t } = this.props;

		if (imageRequestFailed) {
			return <div>{t('image_load_failed_crop')}</div>;
		}

		if (imageLoading) {
			return <BeatLoader css={override} sizeUnit={'px'} size={15} color={'#039be5'} loading={true} />;
		}

		if (!imageLoading && image) {
			return (
				<div>
					<img
						id={'image-crop-container'}
						src={image.urls.uploaded.original}
						onLoad={(event: any) => {
							this.loadCropper(event.target, 0);
						}}
						alt={'Image for cropping'}
					/>
				</div>
			);
		}

		return null;
	}

	render() {
		const { t, open, onClose } = this.props;

		return (
			<Modal size={'xl'} isOpen={open}>
				<ModalHeader
					toggle={() => {
						onClose();
					}}
				>
					{t('image_crop')}
				</ModalHeader>
				<ModalBody>
					<Row className={'mb-3'}>
						<Col>
							<Select
								options={aspectRatioSelectOptions}
								defaultValue={{ value: 16 / 9, label: '16x9' }}
								onChange={this.cropper && this.cropper.setAspectRatio && this.onAspectRationSelect}
							/>
						</Col>
					</Row>
					<Row className={'mb-3'}>
						<Col className={'text-center'}>{this.displayImage()}</Col>
					</Row>
				</ModalBody>
				<ModalFooter>
					<Button
						className={'btn-info mr-3'}
						onClick={() => {
							this.updateImage();
						}}
					>
						{t('save_crop')}
					</Button>

					<Button
						className={'btn-danger text-light'}
						onClick={() => {
							onClose();
						}}
					>
						{t('cancel')}
					</Button>
				</ModalFooter>
			</Modal>
		);
	}
}
