import React, { FunctionComponent, useEffect } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { FormGroup, Label } from 'reactstrap';
import {
	autoGenerateReduxPropsNamingForContentModels,
	generateReduxPropsUpdateActionByContentType,
} from '../../../../../../services/content-models-service/ContentModelService';
import {
	extractContentModeBasedOnUrl,
	extractMainContentTypeBasedOnUrl,
	isArrayEmpty,
} from '../../../../../../global-helpers/global.helpers';
import { ContentMode } from '../../../../../../constants/content-types';
import { SelectMenuOptionType } from '../../../../../../models/v2/general/select.model';
import { contentAttributesToOptions, extractDefaultValue } from '../../helpers/general-content-attributes.helper';
import ContentAttribute from '../../../../../../models/v2/content-attributes/content-attributes.model';
import { DATA_QA_ATTRIBUTES } from '../../constants/data-qa.attributes';
import { ReduxGeneralContentProps } from '../../constants/redux-general-content.attributes';

type Properties = {
	t: any;
	selectedDistributionRegions: ContentAttribute[];
	distributedRegions: ContentAttribute[];
	updateTempProperty: (propertyObject: Record<string, any>) => void;
};

const DistributedRegionsMultiselect: FunctionComponent<Properties> = ({
	t,
	selectedDistributionRegions,
	distributedRegions,
	updateTempProperty,
}) => {
	useEffect(() => {
		if (extractContentModeBasedOnUrl() === ContentMode.CREATE && selectedDistributionRegions && selectedDistributionRegions.length === 0) {
			const defaultDistributedRegion = extractDefaultValue(distributedRegions);
			defaultDistributedRegion && updateTempProperty({ [ReduxGeneralContentProps.REGIONS]: [defaultDistributedRegion] });
		}
	}, []);

	const onDistributedRegionsSelect = (distributedRegionsData: Array<SelectMenuOptionType>) => {
		if (distributedRegionsData && distributedRegionsData.length > 0) {
			const distributedRegionsForRedux = distributedRegionsData.map((distributedRegion: SelectMenuOptionType) => distributedRegion.data);
			updateTempProperty({ [ReduxGeneralContentProps.REGIONS]: distributedRegionsForRedux });
		} else {
			updateTempProperty({ [ReduxGeneralContentProps.REGIONS]: [] });
		}
	};

	return isArrayEmpty(distributedRegions) ? null : (
		<FormGroup>
			<Label htmlFor={DATA_QA_ATTRIBUTES.DISTRIBUTION_REGIONS}>{t('distribution_regions')}</Label>
			<Select
				id={DATA_QA_ATTRIBUTES.DISTRIBUTION_REGIONS}
				value={contentAttributesToOptions(selectedDistributionRegions)}
				options={contentAttributesToOptions(distributedRegions)}
				onChange={onDistributedRegionsSelect}
				isMulti
				placeholder={t('select')}
				noOptionsMessage={(inputValue) => inputValue && t('no_options')}
			/>
		</FormGroup>
	);
};

function mapStateToProps(state: any) {
	const { tempPropertyNaming, nestedTempPropertyNaming } = autoGenerateReduxPropsNamingForContentModels();

	return {
		selectedDistributionRegions: state[tempPropertyNaming][nestedTempPropertyNaming].distributionRegions || [],
		distributedRegions: state.project.currentProject.distributionRegions || [],
	};
}

function mapDispatchToProps(dispatch: Function) {
	const contentTypeFromUrl = extractMainContentTypeBasedOnUrl();
	const functionForDispatch = generateReduxPropsUpdateActionByContentType(contentTypeFromUrl);

	return {
		updateTempProperty: (propertyObject: Record<string, any>) =>
			functionForDispatch && dispatch(functionForDispatch(propertyObject, extractContentModeBasedOnUrl())),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(DistributedRegionsMultiselect);
