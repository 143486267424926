import Feed from '../../../../../../models/feed/feed';

export const selectedTemplateToOption = (template: string, t: any) => {
	let selectedOption: any = {};

	if (template && template.length > 0) {
		selectedOption = { label: t(template), value: template };
	}

	return selectedOption;
};

export const templatesToOptions = (templates: Feed[], t: any) => {
	let options: any[] = [];

	if (templates && templates.length > 0) {
		options = templates.map((template: Feed) => {
			return { label: t(template.name), value: template.name, feed: template };
		});
	}

	return options;
};
