import TournamentModel from '../../../../../../../models/v2/Tournament/Entity/TournamentModel';
import PlayerModel from '../../../../../../../models/v2/player/entity/player.model';
import SeasonModel from '../../../../../../../models/v2/Season/Entity/SeasonModel';
import { FootballMostDecoratedPlayersJson } from './football-most-decorated-players.json';
import FootballMostDecoratedPlayersBuilder from './football-most-decorated-players.builder';
import { RefreshTimeValuesType } from '../../refresh-time-input/helper';

export default class FootballMostDecoratedPlayersModel {
	readonly tournament: TournamentModel;
	readonly season: SeasonModel;
	readonly highlightPlayers: PlayerModel[];
	readonly player: PlayerModel;
	readonly players: PlayerModel[];
	readonly offset: string;
	readonly limit: string = '20';
	readonly startFrom: string = '1';
	readonly columns: string[];
	readonly card: string;
	readonly playerOffset: string;
	readonly refreshTime: RefreshTimeValuesType;

	private constructor(
		tournament: TournamentModel,
		season: SeasonModel,
		highlightPlayers: PlayerModel[],
		player: PlayerModel,
		players: PlayerModel[],
		offset: string,
		limit: string,
		startFrom: string,
		columns: string[],
		card: string,
		playerOffset: string,
		refreshTime: RefreshTimeValuesType,
	) {
		this.tournament = tournament;
		this.season = season;
		this.highlightPlayers = highlightPlayers;
		this.player = player;
		this.players = players;
		this.offset = offset;
		this.limit = limit;
		this.startFrom = startFrom;
		this.columns = columns;
		this.card = card;
		this.playerOffset = playerOffset;
		this.refreshTime = refreshTime;
	}

	toJson(): FootballMostDecoratedPlayersJson {
		return {
			tournament: this.tournament,
			season: this.season,
			highlightPlayers: this.highlightPlayers,
			player: this.player,
			players: this.players,
			offset: this.offset,
			limit: this.limit,
			startFrom: this.startFrom,
			columns: this.columns,
			card: this.card,
			playerOffset: this.playerOffset,
			refreshTime: this.refreshTime,
		};
	}

	static fromJson(json: FootballMostDecoratedPlayersJson): FootballMostDecoratedPlayersModel {
		return new FootballMostDecoratedPlayersModel(
			json.tournament,
			json.season,
			json.highlightPlayers,
			json.player,
			json.players,
			json.offset,
			json.limit,
			json.startFrom,
			json.columns,
			json.card,
			json.playerOffset,
			json.refreshTime,
		);
	}

	static builder(mostDecoratedPlayers?: FootballMostDecoratedPlayersModel): FootballMostDecoratedPlayersBuilder {
		return new FootballMostDecoratedPlayersBuilder(mostDecoratedPlayers);
	}
}
