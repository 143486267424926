import { StatisticJson } from './statistic.json';
import StatisticBuilder from './statistic.builder';

export default class Statistic {
	readonly date: string;
	readonly count: number;
	readonly type: string;

	private constructor(date: string, count: number, type: string) {
		this.date = date;
		this.count = count;
		this.type = type;
	}

	toJSON(): StatisticJson {
		return {
			date: this.date,
			count: this.count,
			type: this.type,
		};
	}

	static fromJSON(json: StatisticJson): Statistic {
		return new Statistic(json.date, json.count, json.type);
	}

	static builder(statistic?: Statistic): StatisticBuilder {
		return new StatisticBuilder(statistic);
	}
}
