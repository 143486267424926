import { GameWidgetJson } from './game-widget.json';
import GameWidgetModel from './game-widget.model';
import SportTeamModel from '../../../../partials/sports-team-select/models/sport-team.model';
import GameModel from '../../../../../../../models/v2/game/game.model';
import { RefreshTimeValuesType } from '../../../../v2/partials/refresh-time-input/helper';

export default class GameWidgetBuilder {
	readonly json: GameWidgetJson;

	constructor(game?: GameWidgetModel | GameWidgetJson) {
		if (game && game instanceof GameWidgetModel) {
			this.json = game.toJson();
		} else if (game) {
			this.json = game;
		} else {
			this.json = {} as GameWidgetJson;
		}
	}

	withGame(game: GameModel): GameWidgetBuilder {
		this.json.game = game;

		return this;
	}

	withTeams(teams: SportTeamModel[]): GameWidgetBuilder {
		this.json.teams = teams;

		return this;
	}

	withDisplayOdds(displayOdds: boolean): GameWidgetBuilder {
		this.json.displayOdds = displayOdds;

		return this;
	}

	withRefreshTime(refreshTime: RefreshTimeValuesType): GameWidgetBuilder {
		this.json.refreshTime = refreshTime;

		return this;
	}

	build(): GameWidgetModel {
		return GameWidgetModel.fromJson(this.json);
	}
}
