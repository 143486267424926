import React, { FunctionComponent, useEffect, useState } from 'react';
import { ContentAnalyticsCard } from './content-analytics-card.component';
import { ADMIN_ENTITY, CATEGORY_ENTITY } from './service/content-analytics.service';

export const ContentAnalytics: FunctionComponent<any> = () => {
	return (
		<div>
			<ContentAnalyticsCard entityType={ADMIN_ENTITY} />
			<ContentAnalyticsCard entityType={CATEGORY_ENTITY} />
		</div>
	);
};
