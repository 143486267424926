import TournamentOrderModel from '../../../../models/v2/Tournament/TournamentOrder/TournamentOrderModel';
import TournamentGroupModel from '../../../../models/v2/Tournament/Group/TournamentGroupModel';
import TournamentModel from '../../../../models/v2/Tournament/Entity/TournamentModel';
import { assetsPlaceholder } from '../../../../constants/assetsPlaceholder';
import { toast } from 'react-toastify';

export class DisabledSaveModel {
	public changedGroupInfoName: boolean = false;
	public changedGroupInfoDescription: boolean = false;
	public validNameInputData: boolean = true;
	public validDescriptionInputData: boolean = true;
	public changedTournaments: boolean = false;
}

export const shouldSaveTournamentsGroupBeDisabled = (disabledSaveModel: DisabledSaveModel) => {
	const isInputContentChanged = disabledSaveModel.changedGroupInfoName || disabledSaveModel.changedGroupInfoDescription;
	const isInputDataCorrect = disabledSaveModel.validNameInputData && disabledSaveModel.validDescriptionInputData;

	if (disabledSaveModel.changedTournaments && isInputDataCorrect) {
		return false;
	}

	return !isInputContentChanged || !isInputDataCorrect || disabledSaveModel.changedTournaments;
};

export const stringToSnakeCase = (value: string) => {
	const valueMaxLength = value.substring(0, 20);
	return valueMaxLength.trim().replaceAll(' ', '_').toLocaleLowerCase();
};

export const observedCharLengthRestriction = (value: string, maxLength: number) => {
	return maxLength > value.length;
};

export const tournamentGroupInfoChange = (
	el: HTMLInputElement,
	defaultTournamentsGroup: TournamentGroupModel,
	tournamentsGroup: TournamentGroupModel,
	setTournamentsGroup: Function,
	disabledSave: DisabledSaveModel,
	setDisabledSave: Function,
	nameCharsMaxLength: number,
	descriptionCharsMaxLength: number,
	isNewGroup: boolean,
	notEnglishTextMessage: string,
) => {
	const elId = el.id;
	const elValue = el.value;
	if (elId === 'name') {
		if (onlyLatinCharacters(elValue)) {
			const changedGroupInfoName = defaultTournamentsGroup.name !== elValue;
			setDisabledSave({
				...disabledSave,
				changedGroupInfoName,
				validNameInputData: !!elValue && observedCharLengthRestriction(elValue, nameCharsMaxLength),
			});
			if (isNewGroup) {
				const code = stringToSnakeCase(elValue.toString());
				setTournamentsGroup({ ...tournamentsGroup, [elId]: elValue, code } as TournamentGroupModel);
			} else {
				setTournamentsGroup({ ...tournamentsGroup, [elId]: elValue } as TournamentGroupModel);
			}
		} else {
			toast.error(notEnglishTextMessage);
		}
	} else if (elId === 'description') {
		const changedGroupInfoDescription = defaultTournamentsGroup.description !== elValue;
		setDisabledSave({
			...disabledSave,
			changedGroupInfoDescription,
			validDescriptionInputData: observedCharLengthRestriction(elValue, descriptionCharsMaxLength),
		});
		setTournamentsGroup({ ...tournamentsGroup, [elId]: elValue } as TournamentGroupModel);
	}
};

export const findEmptyTournamentArrIndex = (tournamentsOrder: TournamentOrderModel[]) => {
	const newRowIndex = tournamentsOrder.findIndex(
		(tournamentsOrder) =>
			tournamentsOrder.tournament.name === '' &&
			tournamentsOrder.tournament.gender === '' &&
			tournamentsOrder.tournament.type === '' &&
			tournamentsOrder.tournament.region === '',
	);

	return newRowIndex;
};

export const emptyTournamentRowAvailable = (tournamentsOrder: TournamentOrderModel[]) => {
	const newRowIndex = findEmptyTournamentArrIndex(tournamentsOrder);
	return newRowIndex >= 0;
};

export const removeTournamentOrderById = (tournamentsOrder: TournamentOrderModel[], searchedTournamentOrderId: string) => {
	return tournamentsOrder.filter((tournamentsOrder) => tournamentsOrder.tournament.id !== searchedTournamentOrderId);
};

export const tournamentLabelWithCountry = (tournament: TournamentModel) => {
	if (tournament.name && tournament.country.name) {
		const tournamentCountryLabel = `(${tournament.country.name})`;
		return `${tournament.name} ${tournamentCountryLabel}`;
	} else if (tournament.name) {
		return tournament.name;
	} else {
		return '';
	}
};

export const tournamentsToOptions = (searchedTournaments: TournamentModel[]) => {
	return [...searchedTournaments].map((tournament) => {
		return {
			value: tournament.name,
			label: tournamentLabelWithCountry(tournament),
			logo: tournament.urlLogo ? tournament.urlLogo : assetsPlaceholder.tournament,
			type: tournament.type,
			data: tournament,
		};
	});
};

export const notSelectedTournamentsToOptions = (allTournaments: TournamentModel[], alreadySelectedTournaments: TournamentOrderModel[]) => {
	const filteredTournaments = [...allTournaments].filter(
		(tournament) => alreadySelectedTournaments.findIndex((selectedTournament) => selectedTournament.tournament.id == tournament.id) < 0,
	);
	return tournamentsToOptions(filteredTournaments);
};

export const modifyTournamentSortOrderBasedOnArrayIndex = (tournamentsOrder: TournamentOrderModel[]): TournamentOrderModel[] => {
	return [...tournamentsOrder].map((tournamentOrder, index) => {
		return {
			...tournamentOrder,
			sortOrder: index + 1,
		};
	});
};

export const isEmptyTournament = (tournamentOrder: TournamentOrderModel) => {
	return tournamentOrder.tournament.id === '';
};

export const findLastSortOrder = (tournamentsOrder: TournamentOrderModel[]) => {
	let lastSortOrder = 0;
	if (tournamentsOrder.length > 0) {
		tournamentsOrder.forEach((tournamentOrder) => {
			if (tournamentOrder.sortOrder > lastSortOrder) {
				lastSortOrder = tournamentOrder.sortOrder as number;
			}
		});
	}
	return lastSortOrder;
};

export const findIsTournamentAlreadySelected = (tournamentsOrder: TournamentOrderModel[], currentlySelectedEl: TournamentModel) => {
	return tournamentsOrder.findIndex((tournamentOrder: TournamentOrderModel) => tournamentOrder.tournament.id == currentlySelectedEl.id) >= 0;
};

export const clearAlreadySelectedEl = (selectEl: any) => {
	selectEl.label = null;
	selectEl.value = null;
};

export const onlyLatinCharacters = (str: string) => {
	if (!!str) {
		const otherChars = /[^\u0000-\u007f]/.test(str);
		return !otherChars;
	}

	return true;
};
