import { ProjectJson } from './ProjectJson';
import ProjectBuilder from './ProjectBuilder';
import Feature from '../feature/Feature';
import ContentAttributes from '../content-attributes/content-attributes-model';
import ContentLanguagesModel from '../content-languages/content-languages-model';

export default class Project {
	readonly id: string;
	readonly title: string;
	readonly domain: string;
	readonly language: string;
	readonly languages: ContentLanguagesModel;
	readonly colorScheme: string;
	readonly googleAnalyticsId: string;
	readonly facebookPixelCode: string;
	readonly articleVoting: boolean;
	readonly domainImages: string;
	readonly domainVideos: string;
	readonly commentsApproval: boolean;
	readonly commentsRegisteredUsersOnly: boolean;
	readonly commentsRating: boolean;
	readonly commentsThreading: boolean;
	readonly commentsViewCensored: boolean;
	readonly defaultOddClient: string;
	readonly footballApiUrl: string;
	readonly defaultTournamentOrder: string;
	readonly timezone: string;
	readonly previewUrl: string;
	readonly imageApiUrl: string;
	readonly features: Feature[];
	readonly distributionChannels: ContentAttributes[];
	readonly distributionRegions: ContentAttributes[];

	private constructor(
		id: string,
		title: string,
		domain: string,
		language: string,
		languages: ContentLanguagesModel,
		colorScheme: string,
		googleAnalyticsId: string,
		facebookPixelCode: string,
		articleVoting: boolean,
		domainImages: string,
		domainVideos: string,
		commentsApproval: boolean,
		commentsRegisteredUsersOnly: boolean,
		commentsRating: boolean,
		commentsThreading: boolean,
		commentsViewCensored: boolean,
		defaultOddClient: string,
		footballApiUrl: string,
		defaultTournamentOrder: string,
		timezone: string,
		previewUrl: string,
		imageApiUrl: string,
		features: Feature[],
		distributionChannels: ContentAttributes[],
		distributionRegions: ContentAttributes[],
	) {
		this.id = id;
		this.title = title;
		this.domain = domain;
		this.language = language;
		this.languages = languages;
		this.colorScheme = colorScheme;
		this.googleAnalyticsId = googleAnalyticsId;
		this.facebookPixelCode = facebookPixelCode;
		this.articleVoting = articleVoting;
		this.domainImages = domainImages;
		this.domainVideos = domainVideos;
		this.commentsApproval = commentsApproval;
		this.commentsRegisteredUsersOnly = commentsRegisteredUsersOnly;
		this.commentsRating = commentsRating;
		this.commentsThreading = commentsThreading;
		this.commentsViewCensored = commentsViewCensored;
		this.defaultOddClient = defaultOddClient;
		this.footballApiUrl = footballApiUrl;
		this.defaultTournamentOrder = defaultTournamentOrder;
		this.timezone = timezone;
		this.previewUrl = previewUrl;
		this.imageApiUrl = imageApiUrl;
		this.features = features;
		this.distributionChannels = distributionChannels;
		this.distributionRegions = distributionRegions;
	}

	static fromJSON(json: ProjectJson): Project {
		return new Project(
			json.id,
			json.title,
			json.domain,
			json.language,
			json.languages,
			json.colorScheme,
			json.googleAnalyticsId,
			json.facebookPixelCode,
			json.articleVoting,
			json.domainImages,
			json.domainVideos,
			json.commentsApproval,
			json.commentsRegisteredUsersOnly,
			json.commentsRating,
			json.commentsThreading,
			json.commentsViewCensored,
			json.defaultOddClient,
			json.footballApiUrl,
			json.defaultTournamentOrder,
			json.timezone,
			json.previewUrl,
			json.imageApiUrl,
			json.features,
			json.distributionChannels,
			json.distributionRegions,
		);
	}

	toJSON(): ProjectJson {
		return {
			id: this.id,
			title: this.title,
			domain: this.domain,
			language: this.language,
			languages: this.languages,
			colorScheme: this.colorScheme,
			googleAnalyticsId: this.googleAnalyticsId,
			facebookPixelCode: this.facebookPixelCode,
			articleVoting: this.articleVoting,
			domainImages: this.domainImages,
			domainVideos: this.domainVideos,
			commentsApproval: this.commentsApproval,
			commentsRegisteredUsersOnly: this.commentsRegisteredUsersOnly,
			commentsRating: this.commentsRating,
			commentsThreading: this.commentsThreading,
			commentsViewCensored: this.commentsViewCensored,
			defaultOddClient: this.defaultOddClient,
			footballApiUrl: this.footballApiUrl,
			defaultTournamentOrder: this.defaultTournamentOrder,
			timezone: this.timezone,
			previewUrl: this.previewUrl,
			imageApiUrl: this.imageApiUrl,
			features: this.features,
			distributionChannels: this.distributionChannels,
			distributionRegions: this.distributionRegions,
		};
	}

	static builder(project?: Project): ProjectBuilder {
		return new ProjectBuilder(project);
	}
}
