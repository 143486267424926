import { StatisticsJson } from './statistics.json';
import Statistics from './statistics';
import Context from './context/context';
import { StatisticsRequestJson } from './statistics-request.json';
import Totals from './totals/totals';
import Statistic from './statistic/statistic';

export default class StatisticsBuilder {
	private json: StatisticsJson;

	constructor(statistics?: Statistics | StatisticsJson) {
		if (statistics && statistics instanceof Statistics) {
			this.json = statistics.toJSON();
		} else if (statistics) {
			this.json = statistics;
		} else {
			this.json = {} as StatisticsJson;
		}
	}

	withContext(context: Context): StatisticsBuilder {
		this.json.context = context;

		return this;
	}

	withTimeseries(timeseries: Statistic[]): StatisticsBuilder {
		this.json.timeseries = timeseries;

		return this;
	}

	withTotals(totals: Totals): StatisticsBuilder {
		this.json.totals = totals;

		return this;
	}

	build(): Statistics {
		return Statistics.fromJSON(this.json);
	}

	toRequestJson(): StatisticsRequestJson {
		let json = {} as StatisticsRequestJson;
		json.context = this.json.context;
		json.timeseries = this.json.timeseries;
		json.totals = this.json.totals;

		return json;
	}
}
