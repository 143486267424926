import SeasonModel from '../../../blocks/widgets/player-profile/models/season/season.model';

export function seasonToOption(season: any) {
	if (season && Object.entries(season).length > 0) {
		return { value: season.id, label: season.name, data: season };
	}

	return {};
}

export function seasonsToOptions(seasons: any[]) {
	if (seasons && seasons.length > 0) {
		return seasons.map((season: any) => {
			return seasonToOption(season);
		});
	}

	return [];
}

export function remapSeasonFromResponse(response: any) {
	if (response.seasons && response.seasons.length > 0) {
		return response.seasons.map((season: any) => {
			return SeasonModel.builder().withId(season.id).withName(`${response.name} - ${season.name}`).withActive(season.active).build();
		});
	}

	return [];
}

export function remapSeasonByIdFromResponse(response: any) {
	if (response && response.length > 0) {
		return response.map((season: any) => {
			return SeasonModel.builder().withId(season.id).withName(`${season.tournament.name} - ${season.name}`).withActive(season.active).build();
		});
	}

	return [];
}
