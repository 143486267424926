import { AnyT, jsonMember, jsonObject } from 'typedjson';
import TeamModelV2 from '../../../../../../../models/v2/Team/Entity/TeamModel';
import StageModel from '../../../../../../../models/v2/Stage/Entity/StageModel';
import MinuteModel from '../../../partials/matches/models/MinuteModel';
import ScoreModel from '../../../partials/matches/models/ScoreModel';
import SeasonModelV2 from '../../../../../../../models/v2/Season/Entity/SeasonModelV2';
import StatusModel from '../../../partials/matches/models/StatusModel';
import RoundModel from '../../../../../../../models/v2/round/entity/round-model';

@jsonObject
export default class MatchModelV2 {
	@jsonMember(String)
	public id: string = '';

	@jsonMember(String, { name: 'kickoff_time' })
	public kickoffTime: string = '';

	@jsonMember(TeamModelV2, { name: 'home_team' })
	public homeTeam: TeamModelV2 = new TeamModelV2();

	@jsonMember(TeamModelV2, { name: 'away_team' })
	public awayTeam: TeamModelV2 = new TeamModelV2();

	@jsonMember(ScoreModel)
	public score: ScoreModel = new ScoreModel();

	@jsonMember(SeasonModelV2)
	public season: SeasonModelV2 = new SeasonModelV2();

	@jsonMember(AnyT)
	public group: any;

	@jsonMember(RoundModel)
	public round: RoundModel = new RoundModel();

	@jsonMember(StageModel)
	public stage: StageModel = new StageModel();

	@jsonMember(StatusModel)
	public status: StatusModel = new StatusModel();

	@jsonMember(String, { name: 'finished_at' })
	public finishedAt: string = '';

	@jsonMember(AnyT)
	public winner: any;

	@jsonMember(MinuteModel)
	public minute: MinuteModel = new MinuteModel();

	@jsonMember(Boolean)
	public isSelected: boolean = false;
}
