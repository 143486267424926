import React from 'react';
import { Name } from '../fields/name';
import { ThreeLetterCode } from '../fields/three-letter-code';
import { SOCIALS_TEAM, SOCIAL_TYPES_METHODS, isSaveDisabled, teamSave } from '../helpers/manual-data.helper';
import { connect } from 'react-redux';
import { Col, Row, Button } from 'reactstrap';
import CountrySelect from '../fields/country';
import CustomFootballSearch from '../../../../Partials/Shared/search-entity/football-search.component';
import YearFounded from '../fields/year-founded';
import Type from '../fields/type';
import Social from '../fields/social';
import { Properties, State } from './properties/team-create.properties';
import { returnObjectForTeamEntityCreate, TEAM_ENTITY_CREATE_SUCCESS } from '../../../../../store/action-creators/team-action-creator';
import Project from '../../../../../models/project/Project';
import SidebarCollapsibleElements from '../../../../Partials/Sidebar/sidebar-collapsible-elements/sidebar-collapsible-elements';
import { ContentTypes } from '../../../../Partials/Blocky/constants/block.types';
import SidebarElementsToggle from '../../../../Partials/Sidebar/sidebar-collapsible-elements/sidebar-elements-toggle/sidebar-elements-toggle.component';
import EnumItem from '../../../../../models/enum/EnumItem';
import { TeamType, TeamPositions } from '../../../../../models/player-manual-data/constants/manual-entries.constants';
import TeamManualDataModel from '../../../../../models/team-manual-data/team-manual-data.model';
import { AssetsMediaComponent } from '../assets-media/assets-media.component';
import AssetsModel from '../../../../../models/team-manual-data/assets/assets';
import axios from 'axios';
import { ShortName } from '../fields/short-name';
import {
	ContentSidebarChangeCollapseStatus,
	onChangeCollapseStatus,
	onSidebarElemSort,
	toggleSidebarEditMode,
} from '../../../../../services/content-sidebar/content-sidebar.helper';
import { FormattedSidebarSectionValue } from '../../../../Partials/Sidebar/sidebar-collapsible-elements/utils/temp-sidebar.types';
import GlobalModal from '../../../../Partials/Modals/Global/global-modal';
import {
	appendBeforeUnloadResetTempSidebar,
	defaultTempSidebarFromApiResponse,
	overwriteTempSidebarByContentType,
	saveSidebarSettingsByContentType,
} from '../../../../Partials/Sidebar/sidebar-collapsible-elements/utils/temp-sidebar.helper';

class TeamCreateComponent extends React.Component<Properties, State> {
	constructor(props: any) {
		super(props);
		this.state = {
			isNational: false,
			isSidebarInEdit: false,
			teamDataEntry: TeamManualDataModel.builder()
				.withName('')
				.withFounded('')
				.withThreeLetterCode(null)
				.withShortName(null)
				.withType(EnumItem.builder().withId(TeamType.club).withName(TeamPositions.CLUB).build())
				.build(),
			assets: AssetsModel.builder().build(),
			openSidebarSettingsModalFlag: false,
		};
	}

	componentDidMount() {
		window.addEventListener(TEAM_ENTITY_CREATE_SUCCESS, (data: any) => {
			this.props.history.push(`/smp/teams/edit/${data.detail}`);
		});
		appendBeforeUnloadResetTempSidebar(ContentTypes.team);
	}

	onNameChange = (e: any) => {
		this.setState({ ...this.state, teamDataEntry: TeamManualDataModel.builder(this.state.teamDataEntry).withName(e.target.value).build() });
	};

	onCountryChange = (country: any) => {
		this.setState({ ...this.state, teamDataEntry: TeamManualDataModel.builder(this.state.teamDataEntry).withCountry(country).build() });
	};

	onCoachChange = (coach: any) => {
		this.setState({ ...this.state, teamDataEntry: TeamManualDataModel.builder(this.state.teamDataEntry).withCoach(coach).build() });
	};

	onVenueChange = (venue: any) => {
		this.setState({ ...this.state, teamDataEntry: TeamManualDataModel.builder(this.state.teamDataEntry).withVenue(venue).build() });
	};

	onDateChange = (e: any) => {
		this.setState({
			...this.state,
			teamDataEntry: TeamManualDataModel.builder(this.state.teamDataEntry).withFounded(e.target.value).build(),
		});
	};

	onPresidentChange = (president: any) => {
		this.setState({ ...this.state, teamDataEntry: TeamManualDataModel.builder(this.state.teamDataEntry).withPresident(president).build() });
	};

	onThreeLetterCodeChange = (e: any) => {
		this.setState({
			...this.state,
			teamDataEntry: TeamManualDataModel.builder(this.state.teamDataEntry)
				.withThreeLetterCode(e.target.value === '' ? null : e.target.value)
				.build(),
		});
	};

	onShortNameChange = (e: any) => {
		this.setState({
			...this.state,
			teamDataEntry: TeamManualDataModel.builder(this.state.teamDataEntry)
				.withShortName(e.target.value === '' ? null : e.target.value)
				.build(),
		});
	};

	onSocialChange = (e: any, key: string) => {
		const { teamDataEntry } = this.state;
		const social = SOCIAL_TYPES_METHODS[key](teamDataEntry.social, e.target.value);
		this.setState({ ...this.state, teamDataEntry: TeamManualDataModel.builder(teamDataEntry).withSocial(social).build() });
	};

	toggleSidebarEditMode = () => {
		const newState = toggleSidebarEditMode({ ...this.state }, ContentTypes.team);
		this.setState(newState);
	};

	onNationalChange = (type: any) => {
		this.setState({ ...this.state, teamDataEntry: TeamManualDataModel.builder(this.state.teamDataEntry).withType(type).build() });
	};

	updateAssets = (assets: AssetsModel) => {
		this.setState({ ...this.state, assets: assets });
	};

	onTeamSave = () => this.props.teamEntityCreate(teamSave(this.state.teamDataEntry), this.props.project, this.state.assets);

	render() {
		const { t, project } = this.props;
		const { teamDataEntry, isSidebarInEdit, assets, openSidebarSettingsModalFlag } = this.state;
		const sidebarComponentsMap = {
			media: (
				<AssetsMediaComponent
					t={t}
					currentProject={project}
					assets={assets}
					dataEntry={teamDataEntry}
					updateAssets={this.updateAssets}
					dataType='team'
				/>
			),
		};

		return (
			<div className='animated fadeIn'>
				<Row>
					<Col col='8' lg='8' md='12' sm='12' xs='12'>
						<div className='card'>
							<div className='card-header d-flex align-items-center '>
								<div className='mr-auto mb-0'>{t('team_info')}</div>
								<Button
									size='sm'
									color='primary'
									disabled={isSaveDisabled(teamDataEntry, ContentTypes.team)}
									className='text-uppercase font-weight-bold'
									onClick={this.onTeamSave}
									id='team-manual-data-entry-create-save-top'
								>
									<i className='fa fa-floppy-o' /> {t('save_team')}
								</Button>
							</div>
							<div className='card-body'>
								<Row>
									<div className='alert alert-info w-100' role='alert'>
										{t('enter_the_team_name_in_english')}
									</div>
								</Row>
								<Row>
									<Name value={teamDataEntry.name} onChange={this.onNameChange} t={t} type='team' />
									<Type value={teamDataEntry.type} t={t} onChange={this.onNationalChange} />
								</Row>
								<Row>
									<CountrySelect isRequired value={teamDataEntry.country} onChange={this.onCountryChange} t={t} />
									<Col xs='6'>
										<CustomFootballSearch
											isClearable
											shouldShowLabel
											label='venue'
											isMulti={false}
											type='venue'
											value={teamDataEntry.venue}
											lang={project.language}
											onChange={this.onVenueChange}
											t={t}
										/>
									</Col>
								</Row>
								<Row>
									<YearFounded value={teamDataEntry.founded} onChange={this.onDateChange} t={t} />
									<Col xs='6'>
										<CustomFootballSearch
											isClearable
											shouldShowLabel
											label='coach'
											isMulti={false}
											type='coach'
											value={teamDataEntry.coach}
											lang={project.language}
											onChange={this.onCoachChange}
											t={t}
										/>
									</Col>
									{/* This will be added when BE endpoint for president is ready */}
									{/* <President value={teamDataEntry.president} onChange={this.onPresidentChange} t={t} /> */}
								</Row>
								<Row>
									<Col xs='6'>
										<ThreeLetterCode t={t} onChange={this.onThreeLetterCodeChange} value={teamDataEntry.threeLetterCode} type='team_abbreviation' />
									</Col>
									<Col xs='6'>
										<ShortName t={t} onChange={this.onShortNameChange} value={teamDataEntry.shortName} type='team_short_name' />
									</Col>
								</Row>
							</div>
						</div>
						<div className='card'>
							<div className='card-header'>{t('social_media_highlights')}</div>
							<div className='card-body'>
								<Row className='mb-2'>
									{Object.keys(SOCIALS_TEAM).map((key: string) => {
										const value = teamDataEntry.social && teamDataEntry.social[key] ? teamDataEntry.social[key] : '';

										return <Social key={key} t={t} value={value} onChange={(e: any) => this.onSocialChange(e, key)} type={SOCIALS_TEAM[key]} />;
									})}
								</Row>
								<Row>
									<Button
										disabled={isSaveDisabled(teamDataEntry, ContentTypes.team)}
										color='primary'
										className='text-uppercase font-weight-bold'
										onClick={this.onTeamSave}
										id='team-manual-data-entry-create-save-bottom'
									>
										<i className='fa fa-floppy-o' /> {t('save_team')}
									</Button>
								</Row>
							</div>
						</div>
					</Col>
					<Col col='4' lg='4' md='12' sm='12' xs='12' className='position-relative'>
						<SidebarElementsToggle t={t} toggleSidebarEditMode={this.toggleSidebarEditMode} isSidebarInEdit={isSidebarInEdit} />
						<SidebarCollapsibleElements
							isSidebarInEdit={isSidebarInEdit}
							onElemSort={(settingsValue: FormattedSidebarSectionValue[]) => onSidebarElemSort(settingsValue, ContentTypes.team)}
							onChangeCollapseStatus={(data: ContentSidebarChangeCollapseStatus) => onChangeCollapseStatus(data)}
							contentType={ContentTypes.team}
							sidebarComponentsMap={sidebarComponentsMap}
							t={t}
						/>
					</Col>
				</Row>
				<GlobalModal
					isOpen={openSidebarSettingsModalFlag}
					t={this.props.t}
					headerContent='save_sidebar_settings'
					bodyContent='unsaved_changes'
					submitFunction={() => saveSidebarSettingsByContentType(ContentTypes.team, t)}
					beforeCloseFunction={() =>
						overwriteTempSidebarByContentType(ContentTypes.team, defaultTempSidebarFromApiResponse[ContentTypes.team].value)
					}
				/>
			</div>
		);
	}
}

function mapStateToProps(state: any) {
	return {
		project: state.project.currentProject,
		profile: state.profile.profile,
	};
}

function mapDispatchToProps(dispatch: any) {
	return {
		teamEntityCreate: (teamData: TeamManualDataModel, project: Project, assets: AssetsModel) =>
			dispatch(returnObjectForTeamEntityCreate(teamData, project, assets)),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(TeamCreateComponent);
