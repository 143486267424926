import * as React from 'react';
import GoogleAnalytics from '../analytics/GoogleAnalytics';
import { analyticsService } from '../../App';

type Properties = {
	init: (projectId: string) => any;
};

const withGoogleAnalytics = <P extends object>(Component: React.ComponentType<P>) =>
	class WithAnalytics extends React.Component<P & Properties> {
		analytics = {} as GoogleAnalytics;

		onInit(projectId: string) {
			analyticsService.init(projectId);
		}

		sendPageView(pagePath: string, pageTitle: string) {
			analyticsService.sendPageView(pagePath, pageTitle);
		}

		sendEvent(action: string, category: string, label: string) {
			analyticsService.sendEvent(action, category, label);
		}

		render() {
			const { ...props } = this.props;

			return (
				<Component
					{...(props as P)}
					analytics={{
						init: (projectId: string) => {
							this.onInit(projectId);
						},
						sendEvent: (action: string, category: string, label: string) => {
							this.sendEvent(action, category, label);
						},
						sendPageView: (pagePath: string, pageTitle: string) => {
							this.sendPageView(pagePath, pageTitle);
						},
					}}
				/>
			);
		}
	};

export default withGoogleAnalytics;
