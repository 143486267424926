import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Col, Label, Row } from 'reactstrap';
import HttpService from '../../../../../../services/rest/HttpService';
import ErrorHandler from '../error-handler/error-handler';
import TeamModel from '../../../../../../models/v2/Team/Entity/TeamModel';
import { remapPlayersFromResponse, topScorersToOptions } from '../helpers/top-scorers-selects/top-scorers-select-components.helper';
import { customOption } from '../../../partials/shared/custom-select-option';
import PlayerModel from '../../../../../../models/v2/player/entity/player.model';
import { getUniqueOnly } from '../../../shared/global-functions/global-functions';
import { async } from 'rxjs/internal/scheduler/async';

type Properties = {
	onTopScorersSelect: any;
	selectedTeams: TeamModel[];
	seasonId: number;
	selectedTopScorers: PlayerModel[];
	t: any;
	isFilterApplied: boolean;
	isValid: boolean;
	isRequired?: boolean;
	id: string;
	setPlayers: (players: PlayerModel[]) => void;
	players: PlayerModel[];
};

const FootballTopScorersSelect: React.FunctionComponent<Properties> = (props) => {
	const [topScorersOptions, setTopScorersOptions] = useState<PlayerModel[]>([]);

	useEffect(() => {
		if (props.selectedTeams.length === 1) {
			props.selectedTeams.forEach((team: TeamModel) => {
				return getInitialTopScorersByTeam(team.id);
			});
		} else if (props.isFilterApplied && props.selectedTeams && props.selectedTeams.length > 1) {
			return getTopScorersByTeam(props.selectedTeams[props.selectedTeams.length - 1].id);
		} else {
			if (props.seasonId) {
				getTopScorersBySeason(props.seasonId);
			}
		}
	}, [props.selectedTeams, props.isFilterApplied]);

	const updateSelectedTopScorersState = (players: PlayerModel[]) => {
		setTopScorersOptions(players);
	};

	const getTopScorersBySeason = (seasonId: number) => {
		if (seasonId) {
			HttpService.getTopScorersBySeasonId(seasonId)
				.then((response: any) => {
					updateSelectedTopScorersState(remapPlayersFromResponse(response.data));
					props.setPlayers(remapPlayersFromResponse(response.data));
				})
				.catch((e: any) => e);
		}
	};

	const getTopScorersByTeam = (teamId: any) => {
		teamId &&
			HttpService.getTeamPlayers(teamId)
				.then((response: any) => {
					let finalData = [...topScorersOptions, ...remapPlayersFromResponse(response.data)];
					finalData = getUniqueOnly(finalData, 'id');
					updateSelectedTopScorersState(finalData);
					props.setPlayers(finalData);
				})
				.catch((e: any) => e);
	};

	const getInitialTopScorersByTeam = (teamId: any) => {
		teamId &&
			HttpService.getTeamPlayers(teamId)
				.then((response: any) => {
					updateSelectedTopScorersState(remapPlayersFromResponse(response.data));
					props.setPlayers(remapPlayersFromResponse(response.data));
				})
				.catch((e: any) => e);
	};

	const topScorersSelected =
		props.selectedTopScorers === undefined || props.selectedTopScorers === null || props.selectedTopScorers.length < 1;

	if (!props.seasonId) {
		return null;
	} else {
		return (
			<Row data-qa={props.id}>
				<Col>
					<Label htmlFor={props.id}>{props.t('pick_players_highlight')}:</Label>
					{props.isRequired && <span className='text-danger'>*</span>}
					<Select
						isMulti
						placeholder={props.t('select')}
						formatOptionLabel={customOption}
						options={topScorersToOptions(topScorersOptions)}
						value={topScorersToOptions(props.selectedTopScorers)}
						noOptionsMessage={(inputValue) => inputValue && props.t('no_options')}
						onChange={(selections: any) => props.onTopScorersSelect(selections)}
					/>
					{props.isRequired && (
						<ErrorHandler t={props.t} isValueSelected={!topScorersSelected} arePropsValid={props.isValid} errorMessage='field_is_required' />
					)}
				</Col>
			</Row>
		);
	}
};

export default FootballTopScorersSelect as React.ComponentType<Properties>;
