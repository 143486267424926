import React from 'react';
import { ViewTypes } from '../../../../constants/general.constants';
import MatchWidgetEdit from './match-widget-edit.component';
import { MatchWidgetView } from './match-widget-view.component';
import BlockModel from '../../../../models/block.model';
import OddsWidgetModel from '../../odds/models/odds-widget.model';
import MatchWidgetModel from '../models/match-widget.model';
import BlockUpdateController from '../../../block-update-controller.component';
import { WidgetPreview } from '../../widget-preview/widget-preview.component';

type Properties = {
	block: BlockModel;
	view: ViewTypes;
	t: any;
	contentData: any;
	lang: string;
};

const MatchWidget: React.FunctionComponent<Properties> = (props) => {
	if (props.view === ViewTypes.edit) {
		return <MatchWidgetEdit t={props.t} block={props.block} contentData={props.contentData} />;
	}
	if (props.view === ViewTypes.normal) {
		return (
			<MatchWidgetView
				t={props.t}
				oddsPreview={
					props.block.data.preview && props.block.data.preview.odds ? props.block.data.preview.odds : OddsWidgetModel.builder().build()
				}
				matchPreview={
					props.block.data.preview && props.block.data.preview.match ? props.block.data.preview.match : MatchWidgetModel.builder().build()
				}
			/>
		);
	}

	if (props.view === ViewTypes.preview) {
		return <WidgetPreview blockContent={props.block.data.content} config={props.block.data.config} />;
	}

	return null;
};

export default BlockUpdateController(MatchWidget);
