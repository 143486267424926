import * as React from 'react';
import Select from 'react-select';
import { PlayerPositionType } from '../../../../../models/player-manual-data/constants/manual-entries.constants';
import * as helpers from '../../../../Partials/Shared/react-select-helpers/react-select.helpers';
import { Label, FormGroup, Col } from 'reactstrap';
import EnumItem from '../../../../../models/enum/EnumItem';

type PLayerProps = {
	t: any;
	onChange: (selection: any) => void;
	value: any;
};

const PlayerPositionSelect: React.FunctionComponent<PLayerProps> = ({ t, onChange, value }) => {
	const options = Object.keys(PlayerPositionType).map((key: string) => {
		return helpers.itemToOption(EnumItem.builder().withId(PlayerPositionType[key]).withName(key).build(), false, t, '');
	});

	const onPlayerPositionSelect = (selection: any) => onChange(helpers.optionToItem(selection));

	return (
		<Col xs='6'>
			<FormGroup>
				<Label htmlFor='player-manual-data-entry-position'>{t('player_position')}</Label>
				<Select
					inputId='player-manual-data-entry-position'
					value={helpers.itemToOption(value, false, t, '')}
					options={options}
					onChange={onPlayerPositionSelect}
					placeholder={t('select_position')}
				/>
			</FormGroup>
		</Col>
	);
};

export default PlayerPositionSelect;
