import TournamentModel from '../../../../../../../models/v2/Tournament/Entity/TournamentModel';
import SeasonModel from '../../../../../../../models/v2/Season/Entity/SeasonModel';
import StageModel from '../../../../../../../models/v2/Stage/Entity/StageModel';
import KnockoutWidgetV2Model from './knockout-widget-v2.model';
import { KnockoutWidgetV2Json } from './knockout-widget-v2.json';
import { RefreshTimeValuesType } from '../../refresh-time-input/helper';

export default class KnockoutWidgetV2Builder {
	readonly json: KnockoutWidgetV2Json;

	constructor(standings?: KnockoutWidgetV2Model | KnockoutWidgetV2Json) {
		if (standings && standings instanceof KnockoutWidgetV2Model) {
			this.json = standings.toJson();
		} else if (standings) {
			this.json = standings;
		} else {
			this.json = {} as KnockoutWidgetV2Json;
		}
	}

	withTournament(tournament: TournamentModel): KnockoutWidgetV2Builder {
		this.json.tournament = tournament;

		return this;
	}

	withSeason(season: SeasonModel): KnockoutWidgetV2Builder {
		this.json.season = season;

		return this;
	}

	withStage(stage: StageModel): KnockoutWidgetV2Builder {
		this.json.stage = stage;

		return this;
	}

	withRefreshTime(refreshTime: RefreshTimeValuesType): KnockoutWidgetV2Builder {
		this.json.refreshTime = refreshTime;

		return this;
	}

	build(): KnockoutWidgetV2Model {
		return KnockoutWidgetV2Model.fromJson(this.json);
	}
}
