import { RelatedJson } from './RelatedJson';
import Related from './Related';

export default class RelatedBuilder {
	private json: RelatedJson;

	constructor(urls?: Related | RelatedJson) {
		if (urls && urls instanceof Related) {
			this.json = urls.toJSON();
		} else if (urls) {
			this.json = urls;
		} else {
			this.json = {} as RelatedJson;
		}
	}

	withProvider(provider: string): RelatedBuilder {
		this.json.provider = provider;

		return this;
	}

	withType(type: string): RelatedBuilder {
		this.json.type = type;

		return this;
	}

	withData(data: any): RelatedBuilder {
		this.json.data = data;

		return this;
	}

	build(): Related {
		return Related.fromJSON(this.json);
	}
}
