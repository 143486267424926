import React, { ChangeEvent, FunctionComponent } from 'react';
import { DebounceInput } from 'react-debounce-input';
import { connect } from 'react-redux';
import { FormGroup, Label } from 'reactstrap';
import { extractContentModeBasedOnUrl, extractMainContentTypeBasedOnUrl } from '../../../../../../global-helpers/global.helpers';
import {
	autoGenerateReduxPropsNamingForContentModels,
	generateReduxPropsUpdateActionByContentType,
} from '../../../../../../services/content-models-service/ContentModelService';
import { DATA_QA_ATTRIBUTES } from '../../constants/data-qa.attributes';
import { ReduxGeneralContentProps } from '../../constants/redux-general-content.attributes';
import ErrorAsync from '../../../../BaseComponents/ErrorComponent/ErrorAsync';

type Properties = {
	t: any;
	customAuthorRedux: string;
	updateTempProperty: (propertyObject: Record<string, any>) => void;
};

const CustomAuthorInput: FunctionComponent<Properties> = ({ t, customAuthorRedux, updateTempProperty }) => {
	const onCustomAuthorChange = (event: ChangeEvent<HTMLInputElement>) => {
		const customAuthor = event.target.value;
		updateTempProperty({ [ReduxGeneralContentProps.CUSTOM_AUTHOR]: customAuthor });
	};

	return (
		<FormGroup>
			<Label htmlFor={DATA_QA_ATTRIBUTES.CUSTOM_AUTHOR}>{t('custom_author')}</Label>
			<DebounceInput
				type='text'
				id={DATA_QA_ATTRIBUTES.CUSTOM_AUTHOR}
				placeholder={t('author_just_for_content')}
				className='form-control'
				debounceTimeout={300}
				value={customAuthorRedux}
				onChange={onCustomAuthorChange}
			/>
			<ErrorAsync t={t} errorType='custom_author' />
		</FormGroup>
	);
};

function mapStateToProps(state: any) {
	const { tempPropertyNaming, nestedTempPropertyNaming } = autoGenerateReduxPropsNamingForContentModels();

	return {
		customAuthorRedux: state[tempPropertyNaming][nestedTempPropertyNaming].customAuthor || '',
	};
}

function mapDispatchToProps(dispatch: Function) {
	const contentTypeFromUrl = extractMainContentTypeBasedOnUrl();
	const functionForDispatch = generateReduxPropsUpdateActionByContentType(contentTypeFromUrl);

	return {
		updateTempProperty: (propertyObject: Record<string, any>) =>
			functionForDispatch && dispatch(functionForDispatch(propertyObject, extractContentModeBasedOnUrl())),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomAuthorInput);
