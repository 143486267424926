import React from 'react';
import LinkEdit from './link-edit.component';
import { LinkView } from './link-view.component';
import LinkBlockModel from './models/link-block.model';
import { Properties } from './properties/link-block.properties';
import { ViewTypes } from '../../../constants/general.constants';
import BlockUpdateController from '../../block-update-controller.component';

const LinkBlock: React.FunctionComponent<Properties> = (props) => {
	if (props.view === ViewTypes.edit) {
		return <LinkEdit block={props.block} t={props.t} />;
	} else if (props.view === ViewTypes.normal) {
		return (
			<LinkView
				t={props.t}
				preview={props.block.data && props.block.data.preview ? props.block.data.preview.linkBlock : LinkBlockModel.builder().build()}
			/>
		);
	}

	return null;
};

export default BlockUpdateController(LinkBlock);
