import FixturesModel from '../../models/fixtures-widget.model';
import MatchModel from '../../../../../partials/match-select/models/match.model';
import { remapTeamFromResponce } from '../../../../../partials/team-select/helpers/team-select.helper';

export const extractMatchIdsFromFixtures = (fixtures: FixturesModel) => {
	return fixtures && fixtures.matches ? fixtures.matches.map((match: any) => match.id) : [];
};

export const createMatchFromMatchSelect = (selection: any) => {
	const match = MatchModel.builder()
		.withId(selection.id)
		.withHomeTeam(remapTeamFromResponce(selection.data.home_team))
		.withAwayTeam(remapTeamFromResponce(selection.data.away_team))
		.withStartTime(selection.startTime)
		.build();

	return match;
};

export const createMatchListFromMatchSelectList = (selections: any) => {
	return selections.map((selection: any) => createMatchFromMatchSelect(selection));
};
