import React from 'react';
import Select from 'react-select';
import { selectedItemsToOptions } from '../championship-filter-select/helpers/championship-filter-select.helper';
import { tableColumnsFilter } from './helpers/table-columns-select.helpers';
type Properties = {
	onTableColumnsSelect: any;
	value: any[];
	t: any;
	isMulti: boolean;
};

const TableColumnsSelect: React.FunctionComponent<Properties> = ({ value, t, onTableColumnsSelect }) => {
	const TableColumnsSelect = (selections: any) => {
		if (onTableColumnsSelect) {
			onTableColumnsSelect(selections);
		}
	};

	return (
		<>
			<Select
				isMulti
				options={tableColumnsFilter(t)}
				onChange={TableColumnsSelect}
				value={selectedItemsToOptions(value)}
				noOptionsMessage={(inputValue) => inputValue && t('no_options')}
			/>
		</>
	);
};

export default TableColumnsSelect;
