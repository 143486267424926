import moment from 'moment';
import { extractParticipantNameAndResult } from '../../../Shared/advanced-content-filter/subcomponents/v2/sports-select/sports-connections.helper';
import * as React from 'react';
import { Label } from 'reactstrap';

export const generateFinishedEventNameResultLabelHtml = (relatedData: any): JSX.Element => {
	const homeParticipantNameAndResult = extractParticipantNameAndResult(1, relatedData);
	const awayParticipantNameAndResult = extractParticipantNameAndResult(2, relatedData);

	return (
		<Label className='form-check-label' check htmlFor={`${relatedData.id}`}>
			{`${moment(relatedData.start_time).format('DD MMM YYYY')} - `}
			<span>{homeParticipantNameAndResult.name}</span>
			{` ${homeParticipantNameAndResult.result}:${awayParticipantNameAndResult.result} `}
			<span>{awayParticipantNameAndResult.name}</span>
		</Label>
	);
};

export const generateLiveEventNameResultLabelHtml = (relatedData: any): JSX.Element => {
	const homeParticipantNameAndResult = extractParticipantNameAndResult(1, relatedData);
	const awayParticipantNameAndResult = extractParticipantNameAndResult(2, relatedData);

	return (
		<Label className='form-check-label' check htmlFor={`${relatedData.id}`}>
			{`${moment(relatedData.start_time).format('DD MMM YYYY')} - `}
			<span>{homeParticipantNameAndResult.name}</span>
			{relatedData.status_type === 'LIVE' || relatedData.status_type === 'FINISHED' || relatedData.status_type === 'INTERRUPTED'
				? ` (${homeParticipantNameAndResult.result}:${awayParticipantNameAndResult.result}) `
				: ` - `}
			<span>{awayParticipantNameAndResult.name}</span>
		</Label>
	);
};
