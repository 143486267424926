import FootballTeamWidgetModel from './football-team-widget.model';
import { FootballTeamWidgetJson } from './football-team.json';
import TeamModel from '../../../../../../../models/v2/Team/Entity/TeamModel';
import MatchModel from '../../../../../../../models/v2/match/entity/match.model';
import EntitySeasonsSelectModel from '../../../../partials/player-seasons/model/entity-seasons-select.model';
import { RefreshTimeValuesType } from '../../refresh-time-input/helper';

export default class FootballTeamWidgetBuilder {
	readonly json: FootballTeamWidgetJson;

	constructor(team?: FootballTeamWidgetModel | FootballTeamWidgetJson) {
		if (team && team instanceof FootballTeamWidgetModel) {
			this.json = team.toJson();
		} else if (team) {
			this.json = team;
		} else {
			this.json = {} as FootballTeamWidgetJson;
		}
	}

	withTournamentSeason = (tournamentSeason: EntitySeasonsSelectModel): FootballTeamWidgetBuilder => {
		this.json.tournamentSeason = tournamentSeason;

		return this;
	};

	withTeam(team: TeamModel): FootballTeamWidgetBuilder {
		this.json.team = team;

		return this;
	}

	withMatch(match: MatchModel): FootballTeamWidgetBuilder {
		this.json.match = match;

		return this;
	}

	withDisplayTabs(displayTabs: boolean): FootballTeamWidgetBuilder {
		this.json.displayTabs = displayTabs;

		return this;
	}

	withTabs(tabs: string[]): FootballTeamWidgetBuilder {
		this.json.tabs = tabs;

		return this;
	}

	withDefaultTab(defaultTab: any): FootballTeamWidgetBuilder {
		this.json.defaultTab = defaultTab;

		return this;
	}

	withDisplayOdds(displayOdds: boolean): FootballTeamWidgetBuilder {
		this.json.displayOdds = displayOdds;

		return this;
	}

	withFansUnitedEnabled(fansUnitedEnabled: boolean): FootballTeamWidgetBuilder {
		this.json.fansUnitedEnabled = fansUnitedEnabled;

		return this;
	}

	withFansUnitedExpanded(fansUnitedExpanded: boolean): FootballTeamWidgetBuilder {
		this.json.fansUnitedExpanded = fansUnitedExpanded;

		return this;
	}

	withInfoParameters(infoParameters: string[]): FootballTeamWidgetBuilder {
		this.json.infoParameters = infoParameters;

		return this;
	}

	withStatsParameters(statsParameters: string[]): FootballTeamWidgetBuilder {
		this.json.statsParameters = statsParameters;

		return this;
	}

	withRefreshTime(refreshTime: RefreshTimeValuesType): FootballTeamWidgetBuilder {
		this.json.refreshTime = refreshTime;

		return this;
	}

	build(): FootballTeamWidgetModel {
		return FootballTeamWidgetModel.fromJson(this.json);
	}
}
