import axios, { AxiosInstance } from 'axios';
import { store } from '../../store/store';
import REACT_APP_URLS from '../../global-helpers/global-url.helpers';
const encodeUrl = require('encodeurl');

export default class ContentAnalyticsHttp {
	CONTENT_ANALYTICS_BASE_URL = REACT_APP_URLS.REACT_APP_CONTENT_ANALYTICS_BASE_URL;
	readonly instance: AxiosInstance = {} as AxiosInstance;

	constructor() {
		const url = this.CONTENT_ANALYTICS_BASE_URL;
		const projectDomain = store.getState().project.currentProject.domain;
		this.instance = axios.create({
			baseURL: url,
			headers: { 'X-Project': projectDomain },
		});
	}

	public getStatisticsByEntityIdsAndDate = (entity: string, entities: any[], from: string, to: string): Promise<any> => {
		const columns = { admin: 'admin_id', category: 'category_id' };
		const ids = entities.map((entityData: any) => entityData.id);

		const filter = this.generateFilterArray(columns[entity], ids);
		let url = `/v1/reports/ga4?event=content_view&columns=${columns[entity]},date&metrics=event_count&date_from=${from}&date_to=${to}${filter}`;
		return this.instance.get(url);
	};

	generateFilterArray = (columnType: string, ids: string[]) => {
		let queryIds = ids.map((id: string) => {
			return `"${id}"`;
		});

		let filterParam = `[{"type":"in","column":"${columnType}","value":[${queryIds}]}]`;
		return ids ? `&filter=${encodeURIComponent(filterParam)}` : null;
	};
}
