import * as React from 'react';
import { Button, Row } from 'reactstrap';
import Project from '../../../../../../models/project/Project';
import AssetsModel from '../../../../../../models/team-manual-data/assets/assets';
import ImageUploadButtonContainer from '../../../../../Partials/Shared/image-upload/image-upload-button-container';
import {
	addAssetAutoCropDimensions,
	addAssetManuallyCropDimensions,
	cutOriginalImageUrl,
	updateAssetsModelWithImageType,
} from '../helpers/assets-image-upload.helper';
import { UploadedImageComponent } from './uploaded-image.component';
import '../styles/assets-media.component.style.scss';
import AssetsTypesModel from '../../../../../../models/team-manual-data/assets/assetsTypes/assets-types.model';
import AssetsImageCropContainer, { CropModel } from './assets-image-crop.component';
import { useEffect } from 'react';
import axios from 'axios';

type Properties = {
	t: any;
	currentProject: Project;
	type: string;
	updateAssets: (assets: AssetsModel) => any;
	assets: AssetsModel;
	url: string;
	index: number;
	deleteAsset: (type: string) => any;
	dataAssetUrl: string;
	assetType: AssetsTypesModel;
};

export const ImageUploadRowComponent: React.FunctionComponent<Properties> = (props) => {
	const [imageLoading, updateImageLoading] = React.useState(false);
	const [enlargeImage, updateEnlargeImage] = React.useState(false);
	const [displayCropModal, toggleCropModalState] = React.useState(false);
	const [defaultImageUrl, updateDefaultImageUrl] = React.useState('');

	const onImageUpload = (imagePath: string, isAutoCrop?: boolean, cropInfo?: CropModel) => {
		const { assets, type, assetType, updateAssets } = props;
		const urlWithDimensions = isAutoCrop
			? addAssetAutoCropDimensions(imagePath, assetType)
			: addAssetManuallyCropDimensions(imagePath, cropInfo);
		updateDefaultImageUrl(urlWithDimensions);
		const updatedAssets = updateAssetsModelWithImageType(type, assets, urlWithDimensions);
		updateAssets(updatedAssets ? updatedAssets : AssetsModel.builder(assets).build());
	};

	const toggleImageEnlarge = (enlarge: boolean) => {
		updateEnlargeImage(enlarge);
	};

	const toggleUploading = (uploading: boolean) => {
		updateImageLoading(uploading);
	};

	const isMediaObjEmpty = (url: string) => {
		if (url) {
			return url.length < 1;
		}
		return true;
	};

	const { t, currentProject, type, url, index, deleteAsset, dataAssetUrl } = props;

	return (
		<div className='d-flex image-upload-container'>
			<UploadedImageComponent
				projectId={currentProject.id}
				toggleImageEnlarge={toggleImageEnlarge}
				imageLoading={imageLoading}
				enlargeImage={enlargeImage}
				imageClassName='assets-image-container'
				url={url && url.length > 0 ? url : dataAssetUrl}
				type={type}
			/>
			<div className='image-input-container'>
				<div className='d-flex image-input-container'>
					<input className='w-100 image-upload-info ml-2' placeholder={url} disabled />
					<ImageUploadButtonContainer
						t={t}
						imageUploadButtonId={`${type}-image-upload-button`}
						project={currentProject}
						blockStyle={false}
						onImageUploadSuccess={onImageUpload}
						display
						label='browse'
						assetsProject
						toggleUploading={toggleUploading}
					/>
					{dataAssetUrl && dataAssetUrl.length > 0 && (
						<Button id={`${type}${index}-delete-btn`} color='danger' size='sm' className='delete-btn' onClick={() => deleteAsset(type)}>
							<span>
								<i className='fa fa-trash'></i>
							</span>
						</Button>
					)}
				</div>
				<div className='ml-2'>
					<Button
						className='crop-button'
						block
						color='secondary'
						disabled={isMediaObjEmpty(url && url.length > 0 ? url : dataAssetUrl)}
						onClick={() => toggleCropModalState(true)}
					>
						<i className={'fa fa-crop'}></i> {t('crop_image')}
					</Button>
				</div>
			</div>
			<AssetsImageCropContainer
				t={t}
				url={cutOriginalImageUrl(defaultImageUrl && defaultImageUrl.length > 0 ? defaultImageUrl : url, currentProject)}
				currentProject={currentProject}
				open={displayCropModal}
				onClose={() => toggleCropModalState(false)}
				updateImage={onImageUpload}
			/>
		</div>
	);
};
