import React, { useEffect, useState } from 'react';
import DangerouslySetInner from '../../../../social-feed-selection-sidebar/subcomponents/dangerously-set-inner.component';
import { noPreviewImage } from '../../../../../../constants/resource-constants';
import YoutubeVideoModel from '../../../../../../models/youtube-sports/youtube-video/youtube-video.model';
import moment from 'moment';
import HttpService from '../../../../../../services/rest/HttpService';
import { featuresService } from '../../../../../../App';
import { FeatureTypes } from '../../../../../../services/feature-service/features.enum';

type Properties = {
	t: any;
	youtubeVideo: YoutubeVideoModel;
};

const YoutubeSportsView: React.FunctionComponent<Properties> = ({ youtubeVideo }) => {
	const youtubeSportsConfig = featuresService.getFeatureConfig(FeatureTypes.YOUTUBE_SPORTS);
	const [isLoading, setLoading] = useState<boolean>(false);
	const [videoExist, updateVideoState] = useState<boolean>(true);

	useEffect(() => {
		if (youtubeVideo && youtubeVideo.id) {
			HttpService.getYoutubeSportsVideos(
				'https://www.googleapis.com/youtube/v3/videos',
				`?part=snippet&id=${youtubeVideo.id.videoId}&type=video&videoCategoryId=17&key=${youtubeSportsConfig.key}`,
			)
				.then((response: any) => {
					setLoading(false);
					if (response && response.items && response.items.length === 0) {
						updateVideoState(false);
					}
				})
				.catch((e: any) => e, updateVideoState(false));
		}
	}, [youtubeVideo]);

	if (youtubeVideo && youtubeVideo.snippet && youtubeVideo.snippet.thumbnails.medium.url && !videoExist) {
		return (
			<div className={`${isLoading ? 'loading-overlay' : ''}`}>
				<div className='block-content'>
					<div className={`d-flex content-container`}>
						{youtubeVideo.snippet.thumbnails.medium.url ? (
							<img
								className='content-image img-fluid mb-2 mb-md-0 rounded'
								alt={youtubeVideo.snippet.title}
								src={youtubeVideo.snippet.thumbnails.medium.url}
							/>
						) : (
							<img alt='' src={noPreviewImage} className='content-image img-fluid mb-2 mb-md-0 rounded' />
						)}
						{youtubeVideo.snippet.title && (
							<div className='gallery-title text-left'>
								<h6 className='m-0 p-0'>
									<DangerouslySetInner html={youtubeVideo.snippet.title} />
								</h6>
								<p>
									<small className={'mb-2'}>{moment(youtubeVideo.snippet.publishedAt).format('DD MMMM YYYY, HH:mm')}</small>
								</p>
							</div>
						)}
					</div>
				</div>
			</div>
		);
	}

	return (
		<div className='position-relative block-content'>
			<img alt='' src={noPreviewImage} className='no-preview-image img-fluid mb-2 mb-md-0 rounded' />
		</div>
	);
};

export default YoutubeSportsView;
