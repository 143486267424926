import React, { FunctionComponent, useState } from 'react';
import { connect } from 'react-redux';
import { extractContentModeBasedOnUrl } from '../../../../../../global-helpers/global.helpers';
import { useTranslation } from 'react-i18next';
import { DATA_QA } from '../../../helpers/data-qa.properties';
import { ContentMode } from '../../../../../../constants/content-types';
import { CustomEntitiesTypes, UpdateCustomEntityReduxType, generateRemoveModalContent } from '../../../helpers/custom-entities.helper';
import ImageDropzone, { OnImageUploaded } from '../../../../../Partials/design-components/image-dropzone/image-dropzone';
import { ICustomEntityType } from '../../../models/models';
import { updateCustomEntity } from '../../../../../../store/action-creators/custom-entities-action-creator';
import { generateImagePath } from '../../../../../Partials/design-components/image-dropzone/helper';
import REACT_APP_URLS from '../../../../../../global-helpers/global-url.helpers';
import GeneralModal from '../../../../../Partials/design-components/modal/modal';

export enum ImageTypes {
	DISPLAY_IMAGE = 'display_asset',
	ICON = 'icon',
}

type Props = {
	customEntity: ICustomEntityType;
	updateCustomEntity: UpdateCustomEntityReduxType;
	isCreateMode: boolean;
};

const ImagesUploads: FunctionComponent<Props> = ({ customEntity, updateCustomEntity }) => {
	const { t } = useTranslation();
	const [imageRemoveType, setImageRemoveType] = useState<ImageTypes | null>(null);

	const onImageUploadSuccess = (imageData: OnImageUploaded, imageType: ImageTypes) => {
		const imageDataForRedux = {
			url: imageData.fullImageUrl,
			original_filename: imageData.originalFilename,
			path: imageData.path,
			timestamp: new Date().toISOString(),
		};
		updateCustomEntity({ [imageType]: imageDataForRedux });
	};

	const onRemoveImage = (imageType: ImageTypes) => {
		updateCustomEntity({ [imageType]: null });
		setImageRemoveType(null);
	};

	if (customEntity.entity_type === CustomEntitiesTypes.ROLE) {
		return null;
	}

	return (
		<div id={DATA_QA.UPLOADS_CONTAINER}>
			{[ImageTypes.DISPLAY_IMAGE, ImageTypes.ICON].map((imageType) => {
				return (
					<React.Fragment key={imageType}>
						<span>{t(imageType)}</span>
						<ImageDropzone
							assetsProject
							onImageUploaded={(data) => onImageUploadSuccess(data, imageType)}
							previewData={customEntity[imageType]}
							onRemoveImage={() => setImageRemoveType(imageType)}
						/>
					</React.Fragment>
				);
			})}
			<GeneralModal
				isDisplayed={!!imageRemoveType}
				bodyHTML={generateRemoveModalContent('remove_image_description')}
				title={t('remove')}
				onClose={() => setImageRemoveType(null)}
				actionButtonText={t('remove')}
				isPositiveAction={false}
				action={() => imageRemoveType && onRemoveImage(imageRemoveType)}
			/>
		</div>
	);
};

function mapStateToProps(state: any) {
	const contentMode = extractContentModeBasedOnUrl();

	return {
		isCreateMode: contentMode === ContentMode.CREATE,
		customEntity: state.customEntities[contentMode] || {},
		imageApiURL: state.project.currentProject.imageApiUrl || REACT_APP_URLS.REACT_APP_IMAGE_API_BASE_URL,
	};
}

function mapDispatchToProps(dispatch: any) {
	const contentMode = extractContentModeBasedOnUrl();

	return {
		updateCustomEntity: (dataForUpdate: Record<string, unknown>) => dispatch(updateCustomEntity(contentMode, dataForUpdate)),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(ImagesUploads);
