import React from 'react';
import { Col, Row } from 'reactstrap';
import FootballPlayerH2HWidgetModel from '../models/football-player-h2h.model';
import { generateRefreshTimeOptionViewText } from '../../../partials/refresh-time-input/helper';

type Properties = {
	preview: FootballPlayerH2HWidgetModel;
	t: any;
};

export const FootballPlayerH2hViewComponent: React.FunctionComponent<Properties> = ({ preview, t }) => {
	if (preview.player1 && preview.player1.id && preview.player2 && preview.player2.id) {
		return (
			<>
				<Row data-qa='football-player-h2h-view' className={'pb-4 pl-4 pr-4'}>
					<Col className='text-center'>
						<img
							className='rounded-circle'
							height='50'
							src={preview.player1 && preview.player1.urlThumb && preview.player1.urlThumb}
							alt={preview.player1.name}
						/>
						<h4>{preview.player1.name}</h4>
						{preview.player1TournamentSeason && preview.player1TournamentSeason.seasonId && (
							<div className='single-row'>
								{preview.player1TournamentSeason.tournamentName} - {preview.player1TournamentSeason.seasonName}
							</div>
						)}
					</Col>
					<Col className='mr-3 d-flex flex-column justify-content-center align-items-center'>
						<span>{t('vs')}</span>
					</Col>
					<Col className='text-center'>
						<img
							className='rounded-circle'
							height='50'
							src={preview.player2 && preview.player2.urlThumb && preview.player2.urlThumb}
							alt={preview.player2.name}
						/>
						<h4>{preview.player2.name}</h4>
						{preview.player2TournamentSeason && preview.player2TournamentSeason.seasonId && (
							<div className='single-row'>
								{preview.player2TournamentSeason.tournamentName} - {preview.player2TournamentSeason.seasonName}
							</div>
						)}
					</Col>
					<Row>
						{preview.stats && preview.stats.length > 0 && (
							<Col>
								<strong>{t('selected_stats')}:</strong>
								{preview.stats.map((stat, index) => {
									return (
										<span key={`player-h2h-stat-key-${index}`} className='pl-1'>
											{t(`${stat}_title`)}
											{index + 1 === preview.stats.length ? '' : ','}
										</span>
									);
								})}
							</Col>
						)}
					</Row>
				</Row>
				{generateRefreshTimeOptionViewText(preview.refreshTime)}
			</>
		);
	}

	return null;
};
