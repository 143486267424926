import React, { FunctionComponent } from 'react';
import { Col, Input, Label, Row } from 'reactstrap';
import { DATA_QA_ATTRIBUTES } from '../../../../../Partials/Sidebar/general-content/constants/data-qa.attributes';
import ErrorAsync from '../../../../../Partials/BaseComponents/ErrorComponent/ErrorAsync';
import LiveBlogModel from '../../../models/live-blog.model';

type Properties = {
	t: any;
	liveBlog: LiveBlogModel;
	setLiveBlogDescription: any;
};

export const LiveBlogDescriptionSelect: FunctionComponent<Properties> = ({ t, liveBlog, setLiveBlogDescription }) => {
	const onDescriptionChange = (input: string) => {
		setLiveBlogDescription(input);
	};

	return (
		<Row className='mb-3'>
			<Col>
				<Label htmlFor={DATA_QA_ATTRIBUTES.LIVE_BLOG_DESCRIPTION}>
					<strong>{t('description')}</strong>
				</Label>
				<Input
					value={liveBlog.description ? liveBlog.description : ''}
					onChange={(input: any) => onDescriptionChange(input)}
					className='form-control mb-2 border-dark'
					type='textarea'
					rows='2'
					element='textarea'
					placeholder={t('description_placeholder')}
					id='description'
				/>
				<ErrorAsync t={t} errorType='description_id' />
			</Col>
		</Row>
	);
};
