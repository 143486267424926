import React, { FunctionComponent, useState } from 'react';
import Select from 'react-select';
import ContentTypeMapper from './content-type.mapper';

export const ActionTypes = (t: any) => [
	{ value: 'create', label: t('created') },
	{ value: 'update', label: t('updated') },
	{ value: 'delete', label: t('deleted') },
];

export const ActionTypeSelect: FunctionComponent<{
	value: any;
	onActionTypeSelect: (type: any) => void;
	className: string;
	t: any;
	disabled: boolean;
}> = ({ value, onActionTypeSelect, className, t, disabled }) => {
	const actionTypes = ActionTypes(t);
	const mapper = new ContentTypeMapper();
	const options = actionTypes.map((action: any) => {
		return mapper.modelToOption(action, t);
	});

	const optionValue = mapper.modelToOption(value, t);
	return (
		<Select
			isClearable={true}
			isDisabled={disabled}
			menuPortalTarget={document.body}
			options={options}
			className={className}
			placeholder={t('select')}
			value={value ? optionValue : null}
			onChange={(selection: any) => {
				onActionTypeSelect(selection);
			}}
			on
		/>
	);
};
