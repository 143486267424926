import React, { FunctionComponent } from 'react';
import { DebounceInput } from 'react-debounce-input';
import './input.scss';

type Props = {
	invokeFunc: (text: string) => void;
	fieldId: string;
	labelText?: string;
	placeholderText?: string;
	value?: string;
	invokeFuncTime?: number;
	disabled?: boolean;
	onBlurInvokeFunc?: (text: string) => void;
	errorMessage?: string;
};

const InputDesign: FunctionComponent<Props> = ({
	invokeFunc,
	fieldId,
	labelText,
	invokeFuncTime,
	placeholderText,
	disabled,
	value = '',
	errorMessage,
	onBlurInvokeFunc,
}) => {
	const onTextChange = (event: React.ChangeEvent<HTMLInputElement>) => invokeFunc(event.target.value);

	return (
		<div className='custom-input-wrapper' id={fieldId} aria-disabled={!!disabled}>
			{labelText && <label htmlFor={fieldId}> {labelText}</label>}
			<DebounceInput
				element='input'
				type='text'
				id={fieldId}
				className='custom-input'
				placeholder={placeholderText}
				debounceTimeout={invokeFuncTime || 0}
				onChange={onTextChange}
				onBlur={(event) => onBlurInvokeFunc && onBlurInvokeFunc(event.currentTarget.value)}
				value={value}
				disabled={!!disabled}
			/>
			{errorMessage && <div className='error-message'> {errorMessage} </div>}
		</div>
	);
};

export default InputDesign;
