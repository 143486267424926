import 'reflect-metadata';
import { jsonMember, jsonObject } from 'typedjson';
import AssetsModel from '../../../../models/v2/sports-types/assets.model';

@jsonObject()
export class ParticipantModel {
	@jsonMember(String)
	public id: string = '';
	@jsonMember(String)
	public name: string = '';
	@jsonMember(String)
	public slug: string = '';
	@jsonMember(String)
	public entity_type: string = '';
	@jsonMember(String)
	public position: string = '';
	@jsonMember(AssetsModel)
	public display_asset: AssetsModel = new AssetsModel();
	@jsonMember(String)
	public winner: string = '';
}

@jsonObject()
export class ParticipantModelWrapper {
	@jsonMember(ParticipantModel)
	public participant: ParticipantModel = new ParticipantModel();
}
