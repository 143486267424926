import { UserJson } from './user.json';
import UserModel from './user.model';
import { UserRequestJson } from './user-request.json';

export default class UserBuilder {
	private json: UserJson;

	constructor(content?: UserModel | UserJson) {
		if (content && content instanceof UserModel) {
			this.json = content.toJSON();
		} else if (content) {
			this.json = content;
		} else {
			this.json = {} as UserJson;
		}
	}

	withId(id: string): UserBuilder {
		this.json.id = id;

		return this;
	}

	withName(name: string): UserBuilder {
		this.json.name = name;

		return this;
	}

	withActive(active: boolean): UserBuilder {
		this.json.active = active;

		return this;
	}

	build(): UserModel {
		return UserModel.fromJSON(this.json);
	}

	toRequestJson(): UserRequestJson {
		let json = {} as UserRequestJson;
		json.id = this.json.id;
		json.full_name = this.json.name;
		json.is_active = this.json.active;

		return json;
	}
}
