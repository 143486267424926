import React from 'react';
import { Label, InputGroup, Input, InputGroupAddon, Button, Row } from 'reactstrap';
import HttpService from '../../../../../services/rest/HttpService';
import { remapVideoList } from './helpers/onnetwork-video-select.helper';
import './onnetwork-video-select.scss';
import VideoAutoLinking from '../video-autolinking/video-autolink';
import { featuresService } from '../../../../../App';
import { FeatureTypes } from '../../../../../services/feature-service/features.enum';
import OnnetworkVideoModel from '../../../../../models/onnetwork-videos/onnetwork-video.model';
import SuggestedEntitiesModel from '../../../../../models/suggested-entities/suggested-entities.model';
import BlockModel from '../../models/block.model';

export type Properties = {
	setVideos: (videos: OnnetworkVideoModel[] | null, page: number, search: string) => void;
	t: any;
	toggleLoadingState: (loading: boolean) => any;
	searchText: string;
	blocks: BlockModel[];
	onSelectedVideoChange: (video: OnnetworkVideoModel) => any;
	linkedTags: SuggestedEntitiesModel[];
	page: number;
	shouldRequest: boolean;
};

export type State = {
	contentSearchInput: string;
	website: string;
};

export default class OnnetworkVideoSelect extends React.Component<Properties, State> {
	private videoConfig = featuresService.getFeatureConfig(FeatureTypes.ON_NETWORK_INTEGRATION);

	constructor(props: Properties) {
		super(props);
		this.state = {
			contentSearchInput: props.searchText && props.searchText.length > 0 ? props.searchText : '',
			website: this.videoConfig.website,
		};
	}

	componentDidMount() {
		const { searchText, page } = this.props;

		if (searchText && searchText.length > 0) {
			this.searchVideos(searchText, page);
		}
	}

	componentDidUpdate(prevProps: Readonly<Properties>) {
		const { contentSearchInput } = this.state;
		const { shouldRequest } = this.props;

		if (shouldRequest && prevProps.shouldRequest !== shouldRequest && contentSearchInput.length > 0) {
			this.searchVideos(contentSearchInput.trim(), this.props.page);
		}
	}

	onContentSearchInput = (event: any) => {
		this.onVideoInputChangeState(event.target.value);

		if (event.target.value.length < 1) {
			this.props.setVideos([], 0, '');
			this.props.onSelectedVideoChange(OnnetworkVideoModel.builder().build());
		}
	};

	onContentSearch = (event: any) => {
		if (event.keyCode === 13 && this.state.contentSearchInput.trim().length > 0) {
			this.searchVideos(this.state.contentSearchInput, 0);
		}
	};

	clearSearch = () => {
		this.onVideoInputChangeState('');
		this.props.setVideos(null, 0, '');
		this.props.onSelectedVideoChange(OnnetworkVideoModel.builder().build());
	};

	searchVideos(search: string, page: number) {
		this.props.toggleLoadingState(true);

		let formData = new FormData();
		formData.append('apikey', this.videoConfig.token);
		formData.append(
			'json',
			JSON.stringify({
				action: {
					actionname: 'listwebsitevideos',
					actionparams: {
						websiteid: this.state.website,
						page: page,
						count: '10',
						expublic: '0',
						exvcm: '0',
						exavail: '0',
						sort: '0',
						searchstring: search,
						searchfields: '7',
					},
				},
			}),
		);
		HttpService.getOnnetworkVideos('https://api.onnetwork.tv/', 'api.php', formData)
			.then((response: any) => {
				this.props.setVideos(remapVideoList(response.data, this.state.website), page, search);
				this.props.toggleLoadingState(false);
			})
			.catch(() => this.props.toggleLoadingState(false));
	}

	onVideoInputChangeState = (input: string) => {
		this.setState({ ...this.state, contentSearchInput: input });

		return input;
	};

	updateSearchWithTag = (input: string) => {
		const search = this.state.contentSearchInput + ' ' + input;
		this.setState({ ...this.state, contentSearchInput: search });
		this.searchVideos(search, 0);
	};

	render() {
		const { t, linkedTags } = this.props;
		const { contentSearchInput } = this.state;

		return (
			<>
				<Label>{this.props.t('choose_video')}:</Label>
				<InputGroup className='position-relative d-flex flex-direction-row flex-nowrap'>
					<InputGroup className='position-relative d-flex'>
						<Input
							type='text'
							id='search-onnetwork-video-input'
							name='search-input'
							className='rounded-0 pr-4'
							placeholder={t('content_search_placeholder')}
							onChange={this.onContentSearchInput}
							onKeyDown={this.onContentSearch}
							value={contentSearchInput}
						/>
						{contentSearchInput && contentSearchInput.length > 0 && (
							<div onClick={this.clearSearch} id='clear-onnetwork-video' className='p-2 text-muted position-absolute' style={{ top: 0, right: 0 }}>
								<i className='fa fa-times' />
							</div>
						)}
					</InputGroup>
					<InputGroupAddon addonType='append'>
						<Button
							id='search-onnetwork-video-button'
							type='button'
							color='primary'
							disabled={contentSearchInput.trim().length === 0}
							onClick={() => this.searchVideos(contentSearchInput, 0)}
						>
							<i className='fa fa-search pr-1' />
							{t('search')}
						</Button>
					</InputGroupAddon>
				</InputGroup>
				<Row className='m-0 mt-2'>
					<VideoAutoLinking
						featureType={FeatureTypes.ON_NETWORK_INTEGRATION}
						updateSearchWithTag={this.updateSearchWithTag}
						linkedTags={linkedTags}
					/>
				</Row>
			</>
		);
	}
}
