import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Col, Row } from 'reactstrap';
import HttpService from '../../../../../services/rest/HttpService';
import { AppState } from '../../../../../store/store';
import EventModel from '../../models/event/event.model';
import LineupPlayerModel from '../../models/player-lineup/lineup-player.model';
import { eventTypes, remapEventsFromResponse } from '../helpers/events-remappers.helper';
import arrayMove from 'array-move';
import EventsSortableComponent from './events-sortable.component';
import * as ReactSortableHOC from 'react-sortable-hoc';

type Properties = {
	squadHomeTeam: LineupPlayerModel[];
	squadAwayTeam: LineupPlayerModel[];
	t: any;
	events: EventModel[];
	setEvents: (event: EventModel[]) => void;
};

const EventsSortableContainer = ReactSortableHOC.SortableContainer(EventsSortableComponent);

const MatchEvents: React.FunctionComponent<Properties> = ({ squadHomeTeam, squadAwayTeam, t, events, setEvents }) => {
	const [isLoading, setLoadings] = useState<boolean>(false);

	const match = useSelector((state: AppState) => state.matchLineupsEdit.match);
	const eventPlayers = [...squadHomeTeam, ...squadAwayTeam].map((lineup: LineupPlayerModel) => lineup.player);

	const onEventChange = (event: EventModel, index: number) => {
		const eventsCopy = [...events];
		eventsCopy[index] = event;
		setEvents(eventsCopy);
	};

	const onEventRemove = (id: string) => {
		const eventsCopy = [...events].filter((event: EventModel) => event.id !== id);
		setEvents(eventsCopy);
	};

	const addEventsRow = () => {
		setEvents([
			...events,
			EventModel.builder().withId(`temp-id-${events.length}`).withTypeCode(eventTypes[0]).withMinute('0').withTeamPosition('HOME').build(),
		]);
	};

	const onSortEnd = (sortEnd: ReactSortableHOC.SortEnd) => {
		let newEvents = [...events];
		newEvents = arrayMove(newEvents, sortEnd.oldIndex, sortEnd.newIndex);
		setEvents(newEvents);
	};

	const getMatchEvents = (id: string) => {
		setLoadings(true);
		HttpService.instanceFootball()
			.get(`/v2/matches/${id}/events`)
			.then((response: any) => {
				setEvents(remapEventsFromResponse(response.data.events));
				setLoadings(false);
			})
			.catch(() => setLoadings(false));
	};

	useEffect(() => {
		getMatchEvents(match.id);
	}, [match]);

	return (
		<>
			<Row className='border-bottom px-1 py-2 mb-2 font-weight-bold'>
				<Col md='3'>
					<span className='ml-4'>{t('player_name')}</span>
					<span className='text-danger'>*</span>
				</Col>
				<Col md='1'>
					{t('minute')}
					<span className='text-danger'>*</span>
				</Col>
				<Col md='2'>
					{t('event_type')}
					<span className='text-danger'>*</span>
				</Col>
				<Col md='2' className='text-center'>
					{t('team')}
					<span className='text-danger'>*</span>
				</Col>
				<Col md='3'>
					{t('assist')}/{t('substitution')}/{t('related_player')}
				</Col>
				<Col md='1' className='text-right'>
					{t('actions')}
				</Col>
			</Row>
			<div className={`${isLoading ? 'loading-overlay' : ''}`}>
				<EventsSortableContainer
					useDragHandle
					onSortEnd={onSortEnd}
					events={events}
					t={t}
					eventPlayers={eventPlayers}
					onEventChange={onEventChange}
					onEventRemove={onEventRemove}
				/>
				<Button onClick={addEventsRow} outline color='primary' id='add-event-row-button' className='mt-3'>
					<i className='fa fa-plus mr-1' />
					{t('add_row')}
				</Button>
			</div>
		</>
	);
};

export default MatchEvents;
