import React, { ChangeEvent, KeyboardEvent, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { useTranslation, withTranslation } from 'react-i18next';
import { Button, Col, Input, InputGroup, InputGroupAddon, Row } from 'reactstrap';
import ReactPaginate from 'react-paginate';
import 'react-image-lightbox/style.css';
import ContentAttributes from '../../../../../models/content-attributes/content-attributes-model';
import Project from '../../../../../models/project/Project';
import Image from '../../../../../models/image/Image';
import Profile from '../../../../../models/profile/Profile';
import PaginationMeta from '../../../../../models/pagination/PaginationMeta';
import AdvancedFilterModel from '../../../../Partials/Shared/advanced-content-filter/models/advanced-content-filter.model';
import { ContentTypes } from '../../../../../constants/content-types';
import { extractSearchQueryFromFilters } from '../../../../Partials/Shared/advanced-content-filter/helpers/advanced-content-filter.helper';
import { remmapContentFilters } from '../../../../Resources/Articles/Helpers/ArticleHelper';
import { PaginationProps } from '../../../../Partials/Sidebar/Media/subcomponents/media-main-image/subcomponents/image-listing/properties/ImageListPaginateProps';
import ConditionalRenderWrapper from '../../../../Partials/BaseComponents/ConditionalRenderWraper/ConditionalRenderWrapper';
import AdvancedContentFilter from '../../../../Partials/Shared/advanced-content-filter/advanced-content-filter.component';
import ImageEditModal from '../../../../Resources/Images/Subcomponents/image-edit-modal/ImageEditModal';
import ImageCropContainer from '../../../../Partials/Shared/image-crop/image-crop-container';
import LightboxContainer from '../../../../Resources/Images/Subcomponents/lightbox/LightboxContainer';
import { ImageCells } from '../../../../Resources/Images/Subcomponents/image-cells/ImageCells';
import { returnObjectForContentFiltersUpdate } from '../../../../../store/action-creators/content-resources';
import {
	gettyImageRequest,
	gettyImageSearchRequest,
	returnObjectForGettyImagesFiltersUpdate,
} from '../../../../../store/action-creators/ImageActionCreator';
import { Title } from '../../../../Partials/BaseComponents/ListingComponent/subcomponents/ContentTitle/ContentTitle';

export type Properties = {
	getGettyImages: (page: string, text: string, origin: ContentAttributes[], project: Project) => void;
	searchImage: (text: string, project: Project) => void;
	currentProject: Project;
	gettyImages: Image[];
	profile: Profile;
	pagination: PaginationMeta;
	types: ContentAttributes[];
	origins: ContentAttributes[];
	imageFilters: AdvancedFilterModel;
	imageFiltersUpdate: (filters: AdvancedFilterModel) => void;
	updateContentFilters: (filters: any) => void;
};

const GettyImageListingContainer: React.FunctionComponent<Properties> = (props) => {
	const [t] = useTranslation();
	const [imageSearchInput, setImageSearchInput] = useState(
		props.imageFilters && props.imageFilters.searchText ? props.imageFilters.searchText : '',
	);
	const [imageId, setImageId] = useState('');
	const [showCropModal, setShowCropModal] = useState(false);
	const [showEditModal, setShowEditModal] = useState(false);
	const [enlargeImage, setEnlargeImage] = useState(false);
	const [imageToEnlarge, setImageToEnlarge] = useState({} as Image);
	const [isContentLoading, setIsContentLoading] = useState(false);
	const [advancedContentSearchInput, setAdvancedContentSearchInput] = useState(
		props.imageFilters ? extractSearchQueryFromFilters(props.imageFilters) : '',
	);
	const [pageSearchQuery, setPageSearchQuery] = useState('');

	useEffect(() => {
		initPageTitle();
		requestImages();
		const filters = remmapContentFilters({}, {}, {}, {}, {}, {}, {}, {}, {}, props.imageFilters);
		props.updateContentFilters(filters);
	}, []);

	const initPageTitle = () => {
		document.title = `Getty ${t('images')}`;
	};

	const requestImages = (page?: string) => {
		const { currentProject, imageFilters } = props;
		const searchQuery = pageSearchQuery.length > 0 ? pageSearchQuery : '';
		const origin = props.origins.filter((origin: ContentAttributes) => origin.slug === ContentTypes.GETTY);

		const queryFromFilters = extractSearchQueryFromFilters(imageFilters);

		queryFromFilters.length > 0 && queryFromFilters !== '*'
			? // get images by selected filters
			  props.getGettyImages(page ? page : '1', queryFromFilters, origin, currentProject)
			: props.getGettyImages(page ? page : '1', searchQuery, origin, currentProject);
	};

	const toggleEditModal = (imageId: string, isOpen: boolean) => {
		setShowEditModal(isOpen);
		setImageId(imageId);
	};

	const toggleCropModal = (imageId: string, isOpen: boolean) => {
		setShowCropModal(isOpen);
		setImageId(imageId);
	};

	const toggleImageEnlarge = (image: Image, enlarge: boolean) => {
		setEnlargeImage(enlarge);
		setImageToEnlarge(image);
	};

	const displayPagination = (pagination: PaginationMeta) => {
		return (
			<ReactPaginate
				{...PaginationProps(t, pagination.currentPage)}
				pageCount={pagination.totalPages ? pagination.totalPages : 0}
				onPageChange={(data: any) => onPageChange(data.selected + 1)}
			/>
		);
	};

	const onContentSearchInput = (data: ChangeEvent<HTMLInputElement>) => {
		let searchText = data.target.value;
		setImageSearchInput(searchText);
		props.imageFiltersUpdate(AdvancedFilterModel.builder(props.imageFilters).withSearchText(searchText).build());

		if (searchText.length < 1 && advancedContentSearchInput.length < 1) {
			props.imageFiltersUpdate(AdvancedFilterModel.builder(props.imageFilters).withSearchText('').build());
			setImageSearchInput('');
			setPageSearchQuery('');
			requestImages();
		}
	};

	const onImageSearch = (event: KeyboardEvent<HTMLInputElement>) => {
		if (event.keyCode === 13 && (imageSearchInput.length > 0 || advancedContentSearchInput.length > 0)) {
			onSearchContent(imageSearchInput + advancedContentSearchInput);
		}
	};

	const onSearchContent = (text: string) => {
		if (text.trim().length === 0 && advancedContentSearchInput.trim().length === 0) {
			setImageSearchInput('');
			setPageSearchQuery('');
			requestImages();
		} else {
			const origin = props.origins.filter((origin: ContentAttributes) => origin.slug === ContentTypes.GETTY);
			const searchText =
				text.length > 0
					? text + advancedContentSearchInput + `&originId=${origin[0].id}`
					: `*${advancedContentSearchInput + `&originId=${origin[0].id}`}`;
			props.searchImage(searchText, props.currentProject);
			setPageSearchQuery(searchText);
		}
	};

	const onPageChange = (page: string) => {
		if (imageSearchInput.trim().length === 0 && advancedContentSearchInput.trim().length === 0) {
			setImageSearchInput('');
			setPageSearchQuery('');
			requestImages(page);
		} else {
			const searchText = imageSearchInput.length > 0 ? imageSearchInput + advancedContentSearchInput : `*${advancedContentSearchInput}`;
			requestImages(page);
			setPageSearchQuery(searchText);
		}
	};

	const updateAdvancedInputState = (text: string) => {
		props.imageFiltersUpdate(AdvancedFilterModel.builder(props.imageFilters).withSearchText(imageSearchInput).build());
		setAdvancedContentSearchInput(text);
	};

	const filterContent = () => {
		if (imageSearchInput.trim().length === 0 && advancedContentSearchInput.trim().length === 0) {
			setImageSearchInput('');
			setPageSearchQuery('');
			requestImages();
		} else {
			const origin = props.origins.filter((origin: ContentAttributes) => origin.slug === ContentTypes.GETTY);
			const searchText =
				imageSearchInput.length > 0
					? imageSearchInput + advancedContentSearchInput + `&originId=${origin[0].id}`
					: `*${advancedContentSearchInput + `&originId=${origin[0].id}`}`;
			props.searchImage(searchText, props.currentProject);
			setPageSearchQuery(searchText);
		}
	};

	const getAllImages = () => {
		const origin = props.origins.filter((origin: ContentAttributes) => origin.name === ContentTypes.GETTY);
		props.getGettyImages('1', '', origin, currentProject);
	};

	const { gettyImages, currentProject, pagination } = props;

	return (
		<ConditionalRenderWrapper expectedPermissions={['read_images', 'write_images', 'delete_images']}>
			<div className='animated fadeIn'>
				<div className='card'>
					<Title title={`Getty ${t('images')}`} />
					<div className='card-body'>
						<div className={`${isContentLoading ? 'loading-overlay' : ''}`}>
							<Row>
								<Col md='6' className='d-flex flex-column ml-auto'>
									<InputGroup>
										<Input
											type='text'
											id='getty-search-input'
											name='search-input'
											placeholder={t('content_search_placeholder')}
											onChange={onContentSearchInput}
											onKeyDown={onImageSearch}
											value={imageSearchInput}
										/>
										<InputGroupAddon addonType='append'>
											<Button type='button' color='primary' id='getty-search-button' onClick={() => onSearchContent(imageSearchInput)}>
												<i className={'fa fa-search'}>&nbsp;</i>
												{t('search')}
											</Button>
										</InputGroupAddon>
									</InputGroup>
								</Col>
							</Row>
							<Row className='mb-2'>
								<Col className='ml-auto'>
									<AdvancedContentFilter
										t={t}
										filtersInSidebar={true}
										searchContent={filterContent}
										updateAdvancedSearchText={updateAdvancedInputState}
										getAllContent={getAllImages}
										contentSearchInput={imageSearchInput}
										showOriginSelect={false}
										calledFrom='getty-image-listing'
										contentType={ContentTypes.GETTY}
										showTypeSelect={false}
									/>
								</Col>
							</Row>
							<Row className={'mb-3'}>
								<ImageCells
									images={gettyImages}
									onEdit={(imageId: string) => {
										toggleEditModal(imageId, true);
									}}
									onImageClick={(image: Image) => {
										toggleImageEnlarge(image, true);
									}}
									onCrop={(imageId: string) => {
										toggleCropModal(imageId, true);
									}}
									t={t}
								/>
							</Row>
							{gettyImages && gettyImages.length === 0 && <h6 className='d-flex justify-content-center mb-2'>{t('no_data_found')}</h6>}
						</div>
						<ImageEditModal
							onClose={(imageUpdated: boolean) => {
								toggleEditModal('', false);

								if (imageUpdated) {
									requestImages();
								}
							}}
							imageId={imageId}
							currentProject={currentProject}
							open={showEditModal}
							t={t}
							isImagoImage={true}
						/>
						{displayPagination(pagination)}
					</div>
				</div>
			</div>
			<ImageCropContainer
				t={t}
				imageId={imageId}
				currentProject={currentProject}
				open={showCropModal}
				onClose={() => {
					toggleCropModal('', false);
				}}
			/>
			{enlargeImage && (
				<LightboxContainer t={t} currentProject={currentProject} imageToEnlarge={imageToEnlarge} toggleImageEnlarge={toggleImageEnlarge} />
			)}
		</ConditionalRenderWrapper>
	);
};

function mapStateToProps(state: any) {
	return {
		profile: state.profile.profile,
		gettyImages: state.image.gettyImages,
		currentProject: state.project.currentProject,
		pagination: state.image.pagination,
		origins: state.imageOwners.owners,
		imageFilters: state.image.gettyFilters,
	};
}

function mapDispatchToProps(dispatch: any) {
	return {
		getGettyImages: (page: string, text: string, origin: ContentAttributes[], project: Project) =>
			dispatch(gettyImageRequest(page, text, origin, project)),
		searchImage: (text: string, project: Project) => dispatch(gettyImageSearchRequest(text, project)),
		imageFiltersUpdate: (filters: any) => dispatch(returnObjectForGettyImagesFiltersUpdate(filters)),
		updateContentFilters: (filters: any) => dispatch(returnObjectForContentFiltersUpdate(filters)),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(GettyImageListingContainer);
