import React from 'react';
import '../Dashboard.scss';
import { Card, CardBody } from 'reactstrap';
import { Properties } from './properties/global-chart.properties';
import { Line } from 'react-chartjs-2';
import * as chartOprions from '../charts/chart-options';
import { types } from '../charts/helper.ts/charts.helper';

const GlobalChart: React.FunctionComponent<Properties> = ({ data, index, dates, t }) => {
	return (
		<>
			<div className='col-12 col-sm-6 col-lg-3'>
				<Card className={`text-white ${chartOprions.cardColors[index]} ml-1 mr-1`}>
					<CardBody className='card-body'>
						<div className='text-value text-left'>{data[6]}</div>
						<div className='text-left'>
							{t(types[index])} {t('today')}
						</div>
						<div className='chart-wrapper mt-3' style={{ height: '70px' }}>
							<Line
								data={chartOprions.dataSet(t, dates[index], data, chartOprions.lineColors[index], types[index])}
								options={chartOprions.chartOptions(t, dates[index], data, chartOprions.lineColors[index])}
							/>
						</div>
					</CardBody>
				</Card>
			</div>
		</>
	);
};

export default GlobalChart;
