import { call, put, takeEvery } from 'redux-saga/effects';
import HttpService from '../../services/rest/HttpService';
import { actionService, featuresService } from '../../App';
import { toggleLoadingState, toggleModal } from '../action-creators/UiActionCreator';
import {
	LIST_ENTITY_CREATE,
	LIST_ENTITY_DELETE,
	LIST_ENTITY_REQUEST,
	LIST_ENTITY_UPDATE,
	LIST_LISTING_REQUEST,
	LIST_SEARCH,
	returnObjectForListEntityCreateFailed,
	returnObjectForListEntityCreateSuccess,
	returnObjectForListEntityDeleteSuccess,
	returnObjectForListEntityReceived,
	returnObjectForListEntityUpdateFailed,
	returnObjectForListEntityUpdateSuccess,
	returnObjectForListItemsContentStatisticsReceived,
	returnObjectForListListingReceived,
} from '../action-creators/ListActionCreators';
import { onError } from '../action-creators/GeneralActions';
import { FeatureTypes } from '../../services/feature-service/features.enum';
import { constructContentStatisticsSearchQuery } from '../../views/Resources/Lists/ListsContent/subcomponents/helpers/content-item-container.helper';
import { getListType } from '../../views/Resources/Lists/helpers/list.helper';

function* fetchListListingFromApi(action: any) {
	yield put(toggleLoadingState(true));

	try {
		let headers = { Project: action.payload.project.domain };
		let type = getListType();
		const lists = yield call(HttpService.get, `/v2/lists?page=${action.payload.page}&content_type=${type}&limit=200`, null, headers);
		yield put(returnObjectForListListingReceived(lists));
		yield put(toggleLoadingState(false));
	} catch (error) {
		yield put(onError(error));
	}
}

function* searchListFromApi(action: any) {
	yield put(toggleLoadingState(true));

	try {
		let headers = { Project: action.payload.project.domain };
		const lists = yield call(HttpService.get, `/v2/lists/search?query=${action.payload.text}`, null, headers);
		yield put(returnObjectForListListingReceived(lists));
		yield put(toggleLoadingState(false));
	} catch (error) {
		yield put(onError(error));
	}
}

function* fetchListEntityFromApi(action: any) {
	let listResponse: any = {};
	try {
		let headers = { Project: action.payload.project.domain };
		listResponse = yield call(HttpService.get, `/lists/${action.payload.id}?include_inactive_items=true`, null, headers);
		yield put(returnObjectForListEntityReceived(listResponse));
	} catch (error) {
		yield put(onError(error));
	}

	if (
		!window.location.hash.includes('/smp/tags-and-sports-connections-lists/edit/') &&
		!window.location.hash.includes('/smp/tags-and-sports-connections-lists/edit-content/')
	) {
		try {
			if (featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.CONTENT_STATISTICS) && listResponse.data.data.items.length > 0) {
				const contentStatisticsConfig = featuresService.getFeatureConfig(FeatureTypes.CONTENT_STATISTICS);
				const listItemsContentStatistics = yield call(
					HttpService.getContentStatistics,
					contentStatisticsConfig.request_headers[0],
					`${contentStatisticsConfig.url}${constructContentStatisticsSearchQuery(listResponse.data.data.items)}`,
				);

				yield put(returnObjectForListItemsContentStatisticsReceived(listResponse.list, listItemsContentStatistics.data));
			}
		} catch (error) {
			yield put(onError(error));
		}
	}
}

function* postListEntityToApi(action: any) {
	let headers = { Project: action.payload.project.domain };
	let listResponse: any = {};

	try {
		let listPayload = action.payload.list;
		listResponse = yield call(HttpService.post, '/lists', listPayload, headers);
		yield put(returnObjectForListEntityCreateSuccess(listResponse.data.data.id));
	} catch (error) {
		yield put(returnObjectForListEntityCreateFailed());
		yield put(onError(error));
		actionService.emitError(error);
	}
}

function* patchListEntityToApi(action: any) {
	let headers = { Project: action.payload.project.domain };
	let listResponse: any = {};

	try {
		let listPayload = action.payload.list;
		let id = action.payload.list.id;
		listResponse = yield call(HttpService.patch, `/lists/${id}?include_inactive_items=true`, listPayload, headers);
		yield put(returnObjectForListEntityReceived(listResponse));
		yield put(returnObjectForListEntityUpdateSuccess());
	} catch (error) {
		const errorDetailedMessage = (error && error.response && error.response.data && error.response.data.message) || undefined;
		yield put(returnObjectForListEntityUpdateFailed(errorDetailedMessage));
		yield put(onError(error));
		actionService.emitError(error);
	}

	if (
		!window.location.hash.includes('/smp/tags-and-sports-connections-lists/edit/') &&
		!window.location.hash.includes('/smp/tags-and-sports-connections-lists/edit-content/')
	) {
		try {
			if (featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.CONTENT_STATISTICS) && listResponse.data.data.items.length > 0) {
				const contentStatisticsConfig = featuresService.getFeatureConfig(FeatureTypes.CONTENT_STATISTICS);

				const listItemsContentStatistics = yield call(
					HttpService.getContentStatistics,
					contentStatisticsConfig.request_headers[0],
					`${contentStatisticsConfig.url}${constructContentStatisticsSearchQuery(listResponse.data.data.items)}`,
				);
				yield put(returnObjectForListItemsContentStatisticsReceived(listResponse.list, listItemsContentStatistics.data));
			}
		} catch (error) {
			yield put(onError(error));
		}
	}
}

function* deleteListEntityFromApi(action: any) {
	try {
		let headers = { Project: action.payload.project.domain };
		let id = action.payload.id;
		yield call(HttpService.delete, `/lists/${id}`, null, headers);
		yield put(returnObjectForListEntityDeleteSuccess());
		yield put(toggleModal(false));
	} catch (error) {
		yield put(onError(error));
	}
}

function* listSaga() {
	yield takeEvery(LIST_LISTING_REQUEST, fetchListListingFromApi);
	yield takeEvery(LIST_SEARCH, searchListFromApi);
	yield takeEvery(LIST_ENTITY_REQUEST, fetchListEntityFromApi);
	yield takeEvery(LIST_ENTITY_CREATE, postListEntityToApi);
	yield takeEvery(LIST_ENTITY_UPDATE, patchListEntityToApi);
	yield takeEvery(LIST_ENTITY_DELETE, deleteListEntityFromApi);
}

export default listSaga;
