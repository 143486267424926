import { MandatoryFieldsTypes } from '../../../constants/mandatory-fields-constants';
import { ContentTypes } from '../../../constants/content-types';

export const checkAreMandatoryFieldsFilled = (content: any, mandatoryFields: any) => {
	const areFilledMandatoryFields = Object.keys(mandatoryFields).map((key: string) => {
		if (mandatoryFields[key] && key === ContentTypes.MAIN_MEDIA) {
			const hasMainImage = mainMediaHasImage(content);
			return hasMainImage.length > 0;
		} else {
			return !!(content[MandatoryFieldsTypes[key]] && content[MandatoryFieldsTypes[key]].length > 0);
		}
	});
	return !areFilledMandatoryFields.includes(false);
};

export const mainMediaHasImage = (content: any) => {
	if (content && content.mainMedia) {
		return content.mainMedia.filter((item: any) => item.resourceId);
	} else return [];
};
