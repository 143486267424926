import React from 'react';
import { Col, Row } from 'reactstrap';
import StandingsWidgetV2Model from '../models/standings-widget-v2.model';
import { createGroups } from '../../../partials/helpers/group-stage-select/group-stage-select.helper';
import { generateRefreshTimeOptionViewText } from '../../../partials/refresh-time-input/helper';
import { useTranslation } from 'react-i18next';

type Properties = {
	preview: StandingsWidgetV2Model;
};

export const FootballStandingsView: React.FunctionComponent<Properties> = ({ preview }) => {
	const [t] = useTranslation();

	if (preview && preview.tournament && preview.tournament.id) {
		return (
			<>
				<Row className='px-4' data-qa='standings-view-component'>
					<Col className='text-left'>
						<Row className='mb-2'>
							<Col>
								<div>
									<strong>{t('tournament')}:</strong>
									<img className='ml-1 mr-1' width='35px' src={preview.tournament.urlLogo} alt={preview.tournament.name} />
									{preview.tournament.name} {preview.season && preview.season.name && `- ${preview.season.name}`}
									{`${preview.stage && preview.stage.name ? `(${preview.stage.name})` : ''}`}
								</div>
							</Col>
						</Row>
						<Row className='mb-2'>
							<Col>
								{preview.tournament && preview.season && preview.stage && preview.group && preview.group.length > 0 && (
									<p>
										<strong>{t('selected_groups')}:</strong> {createGroups(preview.group)}
									</p>
								)}
							</Col>
						</Row>
						<Row className='mb-2'>
							<Col>
								<strong>{t('highlighted_teams')}:</strong>
								{preview.highlightTeams && preview.highlightTeams.length > 0 ? (
									preview.highlightTeams.map((team, index) => {
										return (
											<span key={`team-standings-key-${index}`}>
												<img className='img-circle ml-2' width='20px' src={team.urlLogo} alt={team.name} /> {team.name}
												{index + 1 === preview.highlightTeams.length ? '' : ','}
											</span>
										);
									})
								) : (
									<span> {t('no_teams_selected')}</span>
								)}
							</Col>
						</Row>
						<Row className='mt-2'>
							{preview.offset ? (
								<Col>
									<strong>{t('start_from_position')}:</strong>
									<span className='ml-2'>{Number(preview.offset) + 1}</span>
								</Col>
							) : null}
						</Row>
						<Row className='mt-2'>
							{preview.limit && preview.limit.length > 0 && (
								<Col>
									<strong>{t('limit')}:</strong>
									<span className='ml-2'>{preview.limit}</span>
								</Col>
							)}
						</Row>
					</Col>
				</Row>
				{generateRefreshTimeOptionViewText(preview.refreshTime)}
			</>
		);
	}

	return null;
};
