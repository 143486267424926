import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Card, CardBody, CardHeader, Label, Input } from 'reactstrap';
import RoundSelectContainer from '../../../../Partials/Blocky/partials/round-select/round-select-container.component';
import SeasonSelectContainer from '../../../../Partials/Blocky/partials/season-select/season-select-container.component';
import StageSelectContainer from '../../../../Partials/Blocky/partials/stage-select/stage-select-container.component';
import TeamSelectComponent from '../../../../Partials/Blocky/partials/team-select/team-select.component';
import TournamentSelect from '../../../../Partials/Blocky/partials/tournament-select/tournament-select-container.component';
import DateTimePickerContainer from '../../../../Partials/Sidebar/GeneralContentAttributes/subcomponents/DateTimePicker/DateTimePickerContainer';
import CustomFootballSelect from '../../../../Partials/Shared/search-entity/football-select.component';
import CustomFootballSearch from '../../../../Partials/Shared/search-entity/football-search.component';
import MatchDetailsModel from '../../models/match/match-details.model';
import TeamModel from '../../../../Partials/Blocky/partials/team-select/models/team.model';
import EnumItem from '../../../../../models/enum/EnumItem';
import TournamentModel from '../../../../Partials/Blocky/blocks/widgets/player-profile/models/tournament-model/tournament.model';
import MatchSeasonModel from '../../models/match/match-season.model';
import RoundModel from '../../../../Partials/Blocky/blocks/widgets/fixtures/models/round/round.model';
import { AppState } from '../../../../../store/store';
import {
	remapSelectedTournament,
	remapSelectedSeason,
	filterScoreOptions,
	remapSelectedScore,
	addScoreTypeToModel,
	remapEventStatus,
	validateByEventStatus,
	EventStatus,
	validateMatchScore,
	matchCoverageEnum,
} from '../helpers/match-details.helper';
import StageGroupsSelectContainer from '../../../../Partials/Blocky/partials/stage-groups-select/stage-groups-select-container.component';
import MatchScoreComponent from './match-score.component';
import Select from 'react-select';
import { itemsToOptions, itemToOption } from '../../../../Partials/Shared/react-select-helpers/react-select.helpers';
import MatchScoreModel from '../../models/match/match-score.model';
import moment from 'moment';
import { mapFiltersToState } from '../helpers/match-search.helper';
import { updateMatchFilters } from '../../../../../store/action-creators/match-lineups-action-creator';

type Properties = {
	t: any;
	match: MatchDetailsModel;
	onChange: (match: MatchDetailsModel | null) => void;
	isLoading: boolean;
};

const MatchDetails: React.FunctionComponent<Properties> = ({ t, match, onChange, isLoading }) => {
	const language = useSelector((state: AppState) => state.project.currentProject.language);
	const filters = useSelector((state: AppState) => state.matchLineupsEdit.filters);

	const [selectedType, onSelectType] = useState<{ id: string; name: string }>({} as { id: string; name: string });
	const [isActive, setActive] = useState(match.coverage === 'LIVE' ? true : false);
	const [checkBoxStatus, setCheckBoxStatus] = useState(false);

	const dispatch = useDispatch();

	// If selected score type is removed we deselect the type value.
	const deselectType = (scoreType: string) =>
		selectedType && scoreType === selectedType.id && onSelectType({} as { id: string; name: string });

	// The *type argument corresponds to the builder method, which should update the model.
	const onSelect = (value: any, type: string) => {
		return onChange(MatchDetailsModel.builder(match)[type](value).build());
	};

	const onTournamentSelect = (tournament: TournamentModel) => {
		dispatch(updateMatchFilters(mapFiltersToState(filters, tournament, 'tournament')));
		onChange(remapSelectedTournament(match, tournament));
	};

	const onSeasonSelect = (season: MatchSeasonModel) => {
		dispatch(updateMatchFilters(mapFiltersToState(filters, season, 'season')));
		onChange(remapSelectedSeason(match, season));
	};

	const onRoundSelect = (round: RoundModel) => {
		dispatch(updateMatchFilters(mapFiltersToState(filters, round, 'round')));
		onSelect(round, 'withRound');
	};

	const onStageSelect = (stage: EnumItem) => {
		dispatch(updateMatchFilters(mapFiltersToState(filters, stage, 'stage')));
		onChange(MatchDetailsModel.builder(match).withGroup(null).withRound(null).withStage(stage).build());
	};

	const onScoreUpdate = (score: MatchScoreModel) => onChange(remapSelectedScore(match, score));

	const onScoreTypeSelect = (selection: any) => {
		onChange(remapSelectedScore(match, addScoreTypeToModel(match.score, selection.data.id)));
		onSelectType(selection.data);
	};

	const onCoverageChange = () => setActive((isActive) => !isActive);

	const isFinished = validateByEventStatus(match.eventStatus, 'finished');

	useEffect(() => onSelect(isActive ? 'LIVE' : 'NOT_LIVE', 'withCoverage'), [isActive]);

	const shouldShowScore = !validateByEventStatus(match.eventStatus, 'notStarted');

	useEffect(() => {
		if (!match.score || (match.score && !match.score.total)) {
			onScoreTypeSelect(itemToOption({ id: 'total', name: 'total' }, false, t, ''));
		}
	}, [shouldShowScore]);

	useEffect(() => {
		if (isFinished && !match.finishedAt) {
			onSelect(moment(match.kickoffTime).add(90, 'minutes').format(), 'withFinishedAt');
		}
	}, [isFinished]);

	const scoreTypeOptions = itemsToOptions(filterScoreOptions(match.score), false, t, '');

	const phaseStartedIsNotValid = validateByEventStatus(match.eventStatus, 'phaseStartedAt') && !match.startTime;

	const scoreIsNotValid = validateByEventStatus(match.eventStatus, 'score') && validateMatchScore(match.score);

	const handleChange = (e: any) => {
		const checked = e.target.checked;
		setCheckBoxStatus(checked);
	};

	if (match) {
		return (
			<Card className='animated fadeIn'>
				<CardHeader>
					<div className='d-flex align-items-center'>
						<span className='mr-auto mb-0'>{t('match_details')}</span>
					</div>
				</CardHeader>
				<CardBody className={`px-1 ${isLoading ? 'loading-overlay' : ''}`}>
					<Row className='pb-3 px-3'>
						<Col xs='6' md='6'>
							<Label>
								{t('search_through_all_teams')}
								<Input className='matches-search-toggle' type='checkbox' onChange={handleChange} />
							</Label>
						</Col>
					</Row>
					<Row className='pb-3 px-3'>
						<Col xs='6' md='6'>
							<Label>
								{t('home_team')}
								<span className='text-danger'>*</span>
							</Label>
							<TeamSelectComponent
								withLabel={false}
								inputId='home-team-select'
								t={t}
								value={match.homeTeam}
								onSelect={(team: TeamModel) => onSelect(team, 'withHomeTeam')}
								isMulti={false}
								isValid
								seasonInfo={match.season}
								searchThroughAllTeams={checkBoxStatus}
							/>
						</Col>
						<Col xs='6' md='6'>
							<Label>
								{t('away_team')}
								<span className='text-danger'>*</span>
							</Label>
							<TeamSelectComponent
								withLabel={false}
								inputId='away-team-select'
								t={t}
								value={match.awayTeam}
								onSelect={(team: TeamModel) => onSelect(team, 'withAwayTeam')}
								isMulti={false}
								isValid
								seasonInfo={match.season}
								searchThroughAllTeams={checkBoxStatus}
							/>
						</Col>
					</Row>
					<Row className='pb-0 px-3'>
						<Col xs='12' md={isFinished ? '4' : '6'}>
							<Label>
								{t('kickoff_time')}
								<span className='text-danger'>*</span>
							</Label>
							<DateTimePickerContainer
								className={`form-group ${!match.kickoffTime ? 'border-danger border' : ''}`}
								t={t}
								resetButton
								id='match-kickoff-time-picker'
								date={match.kickoffTime}
								dateFormat='Y.m.d, H:i'
								enableTime
								onDateChange={(time: string) => onSelect(time, 'withKikoffTime')}
							/>
						</Col>
						<Col xs='12' md={isFinished ? '4' : '6'}>
							<Label>
								{t('phase_started_at')}
								{validateByEventStatus(match.eventStatus, 'phaseStartedAt') && <span className='text-danger'>*</span>}
							</Label>
							<DateTimePickerContainer
								t={t}
								className={`form-group ${phaseStartedIsNotValid ? 'border-danger border' : ''}`}
								resetButton
								id='match-start-time-picker'
								date={match.startTime}
								dateFormat='Y.m.d, H:i'
								enableTime
								onDateChange={(time: string) => onSelect(time, 'withStartTime')}
							/>
						</Col>
						{isFinished && (
							<Col className='form-group' xs='12' md='4'>
								<Label>{t('finish_time')}</Label>
								<DateTimePickerContainer
									t={t}
									resetButton
									id='match-finished-time-picker'
									date={match.finishedAt}
									dateFormat='Y.m.d, H:i'
									enableTime
									onDateChange={(time: string) => onSelect(time, 'withFinishedAt')}
								/>
							</Col>
						)}
					</Row>
					<Row className='pb-0 px-3 border-bottom border-light'>
						<Col className='form-group' md={shouldShowScore ? '4' : '6'}>
							<CustomFootballSelect
								preselectValue
								isRequired
								dataRemapper={remapEventStatus}
								isMulti={false}
								url='/event_status'
								type='status'
								value={match.eventStatus}
								onChange={(status: EventStatus) => onSelect(status, 'withEventStatus')}
								t={t}
							/>
						</Col>
						<Col xs='12' md={shouldShowScore ? '4' : '6'}>
							<Label>{t('spectators')}</Label>
							<Input
								id='match-spectators-input'
								type='number'
								min={0}
								value={match.spectators ? match.spectators : 0}
								onChange={(e: any) => onSelect(e.target.value, 'withSpectators')}
							/>
						</Col>
						{shouldShowScore && (
							<Col className='form-group' xs='12' md='4'>
								<Label>{t('score_type')}</Label>
								{validateByEventStatus(match.eventStatus, 'score') && <span className='text-danger'>*</span>}
								<Select
									className={`${scoreIsNotValid ? 'border-danger border rounded score-select' : ''}`}
									isDisabled={scoreTypeOptions.length === 0}
									inputId='match-score-type-select'
									onChange={onScoreTypeSelect}
									placeholder={t('add_score')}
									value={itemToOption(selectedType, false, t, '')}
									options={scoreTypeOptions}
								/>
								{scoreIsNotValid && <small className='text-danger'>{t('total_score_required')}</small>}
							</Col>
						)}
					</Row>
					{shouldShowScore && scoreTypeOptions.length != 6 && (
						<MatchScoreComponent updateDropdown={deselectType} t={t} score={match.score} onChange={onScoreUpdate} />
					)}
					<Row className='py-3 px-3 border-bottom border-light'>
						<Col className='form-group' xs='12' md='4'>
							<Label>{t('coverage')}</Label>
							<div
								id='match-coverage-toggle'
								className='cursor-pointer p-1 d-flex align-items-center border border-muted rounded'
								onClick={onCoverageChange}
							>
								<i className={`fa fa-2x fa-toggle-${isActive ? 'on text-success' : 'off text-muted'}`} />
								<span className='px-2 animated fadeIn'>{t(matchCoverageEnum[match.coverage])}</span>
							</div>
						</Col>
						<Col xs='12' md='4'>
							<CustomFootballSearch
								isClearable={false}
								shouldShowLabel
								label='referee'
								isMulti={false}
								type='referee'
								lang={language}
								value={match.referees ? match.referees[0] : null}
								onChange={(referee: EnumItem) => onSelect([referee], 'withReferees')}
								t={t}
							/>
						</Col>
						<Col xs='12' md='4'>
							<CustomFootballSearch
								isClearable
								shouldShowLabel
								label='venue'
								isMulti={false}
								type='venue'
								lang={language}
								value={match.venue}
								onChange={(venue: EnumItem) => onSelect(venue, 'withVenue')}
								t={t}
							/>
						</Col>
					</Row>
					<Row className='py-3 px-3'>
						<Col className='form-group' xs='12' md='4'>
							<Label>{t('country')}</Label>
							{match.season && match.season.tournament && match.season.tournament.country && (
								<div className='rounded border p-2 bg-light d-flex align-items-center'>
									<img className='mr-2 rounded' width='20' src={match.season.tournament.country.flag} />
									<span>{match.season.tournament.country.name}</span>
								</div>
							)}
						</Col>
						<Col className='form-group' xs='12' md='4'>
							<TournamentSelect
								label='tournament'
								isBlocky
								isValid
								t={t}
								isMulti={false}
								onTournamentSelect={onTournamentSelect}
								selectedTournament={match.season ? match.season.tournament : null}
							/>
						</Col>
						<Col className='form-group' xs='12' md='4'>
							<SeasonSelectContainer
								label='season'
								t={t}
								onSeasonSelect={onSeasonSelect}
								selectedSeason={match.season}
								tournamentId={match.season && match.season.tournament ? match.season.tournament.id : ''}
							/>
						</Col>
					</Row>
					<Row className='pb-3 px-3'>
						<Col className='form-group'>
							<StageSelectContainer
								isRequired
								label='stage'
								selectedStage={match.stage}
								seasonId={match.season ? match.season.id : ''}
								t={t}
								onStageSelect={onStageSelect}
							/>
						</Col>
						<Col className='form-group'>
							<RoundSelectContainer
								className={!match.round ? 'round-select' : ''}
								isRequired
								label='round'
								t={t}
								selectedRound={match.round}
								stageId={match.stage ? match.stage.id : ''}
								onRoundSelect={(round: RoundModel) => onSelect(round, 'withRound')}
							/>
						</Col>
						<Col>
							<Label>{t('round_enter_by_hand')}:</Label>
							<Input
								type='number'
								value={match.round ? match.round.round : 1}
								onChange={(event) => onRoundSelect(RoundModel.builder().withRound(event.target.value).build())}
							/>
						</Col>
						<Col className='form-group'>
							<StageGroupsSelectContainer
								className={!match.group ? 'round-select' : ''}
								isRequired
								label='stage_groups'
								isMulti={false}
								stageId={match.stage ? match.stage.id : ''}
								selectedStageGroups={match.group}
								onStageGroupSelect={(group: EnumItem) => onSelect(group, 'withGroup')}
								t={t}
							/>
						</Col>
					</Row>
				</CardBody>
			</Card>
		);
	}
	return null;
};

export default MatchDetails;
