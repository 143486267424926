import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import {
	autoGenerateReduxPropsNamingForContentModels,
	generateReduxPropsUpdateActionByContentType,
} from '../../../../../../services/content-models-service/ContentModelService';
import { extractContentModeBasedOnUrl, extractMainContentTypeBasedOnUrl } from '../../../../../../global-helpers/global.helpers';
import { DATA_QA_ATTRIBUTES } from '../../constants/data-qa.attributes';
import { updateDateTimeReduxState } from '../../helpers/date-time-select.helper';
import DateTimeSelect from './date-time-select';
import { ReduxGeneralContentProps } from '../../constants/redux-general-content.attributes';

type Properties = {
	t: any;
	publishedFrom: string;
	publishedUntil: string;
	updateTempProperty: (propertyObject: Record<string, any>) => void;
};

const DateTimesWrapper: FunctionComponent<Properties> = ({ t, publishedFrom, publishedUntil, updateTempProperty }) => {
	const onPublishedFromChange = (dates: Date[] | null) =>
		updateDateTimeReduxState(dates, updateTempProperty, ReduxGeneralContentProps.PUBLISHED_AT);
	const onPublishedUntilChange = (dates: Date[] | null) =>
		updateDateTimeReduxState(dates, updateTempProperty, ReduxGeneralContentProps.PUBLISHED_UNTIL);

	return (
		<Row>
			<Col>
				<FormGroup>
					<Label htmlFor={DATA_QA_ATTRIBUTES.PUBLISHED_FROM}>{t('published_from')}</Label>
					<DateTimeSelect t={t} date={publishedFrom} onChange={onPublishedFromChange} id={DATA_QA_ATTRIBUTES.PUBLISHED_FROM} />
				</FormGroup>
			</Col>
			<Col>
				<FormGroup>
					<Label htmlFor={DATA_QA_ATTRIBUTES.PUBLISHED_TO}>{t('published_until')}</Label>
					<DateTimeSelect t={t} date={publishedUntil} onChange={onPublishedUntilChange} id={DATA_QA_ATTRIBUTES.PUBLISHED_TO} />
				</FormGroup>
			</Col>
		</Row>
	);
};

function mapStateToProps(state: any) {
	const { tempPropertyNaming, nestedTempPropertyNaming } = autoGenerateReduxPropsNamingForContentModels();

	return {
		publishedFrom: state[tempPropertyNaming][nestedTempPropertyNaming].publishedAt || '',
		publishedUntil: state[tempPropertyNaming][nestedTempPropertyNaming].publishedUntil || '',
	};
}

function mapDispatchToProps(dispatch: Function) {
	const contentTypeFromUrl = extractMainContentTypeBasedOnUrl();
	const functionForDispatch = generateReduxPropsUpdateActionByContentType(contentTypeFromUrl);

	return {
		updateTempProperty: (propertyObject: Record<string, any>) =>
			functionForDispatch && dispatch(functionForDispatch(propertyObject, extractContentModeBasedOnUrl())),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(DateTimesWrapper);
