import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import OnnetworkVideoModel from '../../../../../../../models/onnetwork-videos/onnetwork-video.model';

export type Properties = {
	video: OnnetworkVideoModel;
	onClose: (bool: boolean) => void;
	open: boolean;
	t: any;
};

const VideoPlayModal: React.FunctionComponent<Properties> = (props) => {
	const attachScript = () => {
		const { video } = props;

		if (video && video.embedCode && video.embedCode.code) {
			const script = document.createElement('script');
			script.src = `https://video.onnetwork.tv/embed.php?sid=${video.embedCode.code}`;
			script.async = true;
			(document.getElementById('onnetwork-modal-body-video') as HTMLFormElement).appendChild(script);
		}
	};

	const { t, onClose, open, video } = props;

	return (
		<Modal onOpened={attachScript} isOpen={open}>
			<ModalHeader toggle={() => onClose(false)}>{video.title}</ModalHeader>
			<ModalBody id='onnetwork-modal-body-video'>
				{video && video.embedCode && !video.embedCode.code && <p className='text-center p-4'>{t('no_embed_code')}</p>}
			</ModalBody>
			<ModalFooter>
				<Button id='onnetwork-video-cancel-button' color='danger' className={'text-light'} onClick={() => props.onClose(false)}>
					{t('cancel')}
				</Button>
			</ModalFooter>
		</Modal>
	);
};

export default VideoPlayModal as React.ComponentType<any>;
