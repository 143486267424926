import React, { FunctionComponent } from 'react';
import { GenIconButtonProps } from './constants';
import { getPlusIcon } from '../../../../global-helpers/global-icons.helpers';
import './icons.scss';

const CirclePlusIcon: FunctionComponent<GenIconButtonProps> = ({ size = '16px', action, isActive = false, disabled = false }) => {
	const generatedClass = isActive ? 'circle-plus active' : 'circle-plus';
	return (
		<img
			src={getPlusIcon(isActive, disabled)}
			className={generatedClass}
			onClick={!disabled ? action : undefined}
			width={size}
			height={size}
			aria-disabled={disabled}
		/>
	);
};

export default CirclePlusIcon;
