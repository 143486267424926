import { jsonMember, jsonObject } from 'typedjson';

@jsonObject
export default class MarketModel {
	@jsonMember(String)
	public name: string = '';

	@jsonMember(String)
	public value: string = '';
}
