import FootballMostDecoratedPlayersModel from './football-most-decorated-players.model';
import { FootballMostDecoratedPlayersJson } from './football-most-decorated-players.json';
import TournamentModel from '../../../../../../../models/v2/Tournament/Entity/TournamentModel';
import SeasonModel from '../../../../../../../models/v2/Season/Entity/SeasonModel';
import PlayerModel from '../../../../../../../models/v2/player/entity/player.model';
import TeamModel from '../../../../../../../models/v2/Team/Entity/TeamModel';
import { RefreshTimeValuesType } from '../../refresh-time-input/helper';

export default class FootballMostDecoratedPlayersBuilder {
	readonly json: FootballMostDecoratedPlayersJson;

	constructor(mostDecoratedPlayers?: FootballMostDecoratedPlayersModel | FootballMostDecoratedPlayersJson) {
		if (mostDecoratedPlayers && mostDecoratedPlayers instanceof FootballMostDecoratedPlayersModel) {
			this.json = mostDecoratedPlayers.toJson();
		} else if (mostDecoratedPlayers) {
			this.json = mostDecoratedPlayers;
		} else {
			this.json = {} as FootballMostDecoratedPlayersJson;
		}
	}

	withTournament(tournament: TournamentModel): FootballMostDecoratedPlayersBuilder {
		this.json.tournament = tournament;

		return this;
	}

	withSeason(season: SeasonModel): FootballMostDecoratedPlayersBuilder {
		this.json.season = season;

		return this;
	}

	withLimit(limit: string): FootballMostDecoratedPlayersBuilder {
		this.json.limit = limit;

		return this;
	}

	withStartFrom(startFrom: string): FootballMostDecoratedPlayersBuilder {
		this.json.startFrom = startFrom;

		return this;
	}

	withHighlightPlayers(highlightPlayers: PlayerModel[]): FootballMostDecoratedPlayersBuilder {
		this.json.highlightPlayers = highlightPlayers;

		return this;
	}

	withPlayer(player: PlayerModel): FootballMostDecoratedPlayersBuilder {
		this.json.player = player;

		return this;
	}

	withOffset(offset: string): FootballMostDecoratedPlayersBuilder {
		this.json.offset = offset;

		return this;
	}

	withPlayers(players: PlayerModel[]): FootballMostDecoratedPlayersBuilder {
		this.json.players = players;

		return this;
	}

	withColumns(columns: string[]): FootballMostDecoratedPlayersBuilder {
		this.json.columns = columns;

		return this;
	}

	withCard(card: string): FootballMostDecoratedPlayersBuilder {
		this.json.card = card;

		return this;
	}

	withPlayerOffset(playerOffset: string): FootballMostDecoratedPlayersBuilder {
		this.json.playerOffset = playerOffset;

		return this;
	}

	withRefreshTime(refreshTime: RefreshTimeValuesType): FootballMostDecoratedPlayersBuilder {
		this.json.refreshTime = refreshTime;

		return this;
	}

	build(): FootballMostDecoratedPlayersModel {
		return FootballMostDecoratedPlayersModel.fromJson(this.json);
	}
}
