export type ContentFooterType = {
	id: number;
	text: string;
};

export function footersToOptions(footers: ContentFooterType[]) {
	return footers ? footers.map((footer: any) => returnMappedFooters(footer)) : [];
}

export const returnMappedFooters = (footer: ContentFooterType) => {
	return {
		value: footer.id,
		label: footer.text,
	};
};

export function footersFromOptionSelections(selections: string, availableFooters: ContentFooterType[]) {
	return selections ? selections.split(' | ').map((selection: any) => remapFooter(selection, availableFooters)) : [];
}

export function convertContentFooterArrayToString(selections: any[]) {
	return selections
		? selections
				.map((selection: any) => {
					return selection.label;
				})
				.join(' | ')
		: '';
}

export function remapFooter(footer: string, availableFooters: ContentFooterType[]) {
	return {
		value: getOriginalIndex(footer, availableFooters),
		label: footer,
	};
}

export function getOriginalIndex(text: string, availableFooters: ContentFooterType[]) {
	return text && text.length > 0 && availableFooters && availableFooters.length > 0
		? availableFooters.map((footer: ContentFooterType) => (footer.text === text ? footer.id : null)).filter((index) => index !== null)[0]
		: null;
}
