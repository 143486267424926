import * as React from 'react';
import Select from 'react-select';
import { selectedOptionsToTypes, selectedTypesToOptions, typesToOptions } from './helpers/type-select.helper';
import ContentAttributes from '../../../../../../models/content-attributes/content-attributes-model';

export type Properties = {
	selectedTypes: ContentAttributes[] | any;
	onTypeSelect?: any;
	types: ContentAttributes[] | any;
	t: any;
	isClearable: boolean;
};

const TypesMultiSelect: React.FunctionComponent<Properties> = (props) => {
	const { types, t, selectedTypes, onTypeSelect, isClearable } = props;

	const onTypeChange = (selection: any[]) => {
		onTypeSelect(selectedOptionsToTypes(selection));
	};

	return (
		<Select
			inputId='content-type-select'
			value={selectedTypesToOptions(selectedTypes)}
			options={typesToOptions(types, t)}
			onChange={onTypeChange}
			placeholder={t('select')}
			isClearable={isClearable}
			isMulti={true}
		/>
	);
};

export default TypesMultiSelect;
