import React from 'react';
import { useTranslation } from 'react-i18next';

type Properties = {
	errorMessage: string;
};

const ErrorHandler: React.FunctionComponent<Properties> = ({ errorMessage }) => {
	const [t] = useTranslation();

	return <h6 className={'text-danger'}>{t(errorMessage)}</h6>;
};

export default ErrorHandler;
