import 'reflect-metadata';
import { AnyT, jsonMember, jsonObject } from 'typedjson';

@jsonObject()
export default class StageModel {
	@jsonMember(AnyT)
	public id: any;
	@jsonMember(String)
	public name: string = '';
	@jsonMember(String)
	public type: string = '';
	@jsonMember(String, { name: 'start_date' })
	public startDate: string = '';
	@jsonMember(String, { name: 'end_date' })
	public endDate: string = '';
	@jsonMember(Number, { name: 'order_in_season' })
	public orderInSeason: number = 0;
	@jsonMember(String)
	public coverage: string = '';
	@jsonMember(String)
	public status: string = '';
	@jsonMember(String)
	public slug: string | null = null;
}
