import './App.scss';

import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import { persistor, store } from './store/store';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import ProjectSelect from './views/Pages/ProjectSelect/ProjectSelect';
import PrivateRoute from './routing/PrivateRoute';
import { I18nextProvider } from 'react-i18next';
import i18n from 'i18next';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ErrorService from './services/error-service/ErrorService';
import EntityActionService from './services/entity-update-service/EntityActionService';
import ServiceProvider from './services/service-provider/ServiceProvider';
import GoogleAnalytics from './services/analytics/GoogleAnalytics';
import FeatureService from './services/feature-service/feature.service';
import { intitEmojiList } from './views/Partials/Shared/emoticons/emoji-list';
import Login from './views/Pages/Login/Login';
import DefaultLayout from './containers/DefaultLayout/DefaultLayout';
import WordCountService from './services/word-count-service/word-count-service';
import MultiLingualService from './services/multi-lingual-service/MultiLingualService';
import CustomBlocksService from './models/v2/custom-blocks/services/custom-blocks.service';
import AuditLogService from './views/Resources/audit-log/services/audit-log.service';
import SportsConnectionsService from './views/Partials/Shared/advanced-content-filter/subcomponents/v2/services/sports-connections.service';
import FansUnitedService from './views/Partials/Blocky/blocks/widgets/fans-united/services/fans-united.service';
import { WidgetScriptTypes } from './constants/sport.types';
import { addLoaderScript, appendCrazyEggScript, detectUserLocationChange } from './global-helpers/global.helpers';
import AmgVideosService from './views/Partials/Blocky/blocks/content/amg-block/services/amg-videos.service';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQuerySettings } from './services/react-query/react-query.helper';
import REACT_APP_URLS from './global-helpers/global-url.helpers';

// Create a query client
const queryClient = new QueryClient(ReactQuerySettings);
export const appServiceWorker = new Worker('/assets/sw.js');

export const actionService: EntityActionService = new EntityActionService();
export const featuresService: FeatureService = new FeatureService();
export const analyticsService: GoogleAnalytics = new GoogleAnalytics('');
export const wordCountService: WordCountService = new WordCountService();
export const multiLingualService: MultiLingualService = new MultiLingualService();
export const customBlocksService: CustomBlocksService = new CustomBlocksService();
export const auditLogService: AuditLogService = new AuditLogService();
export const sportsConnectionsService: SportsConnectionsService = new SportsConnectionsService();
export const fansUnitedService: FansUnitedService = new FansUnitedService();
export const amgVideosService: AmgVideosService = new AmgVideosService();

const loading = () => <div className='animated fadeIn pt-3 text-center'>Loading...</div>;

declare global {
	interface Window {
		errorService: ErrorService;
		entityUpdateService: EntityActionService;
		removeEventsRegister: () => void;
		removeSingleRequestEventListener: () => void;
		SMP365: any;
	}
}

const footballLoaderUrl = REACT_APP_URLS.REACT_APP_WIDGETS_LOADER_API_URL ? REACT_APP_URLS.REACT_APP_WIDGETS_LOADER_API_URL : '';

class App extends Component {
	constructor(props: any) {
		super(props);
		intitEmojiList();
	}

	componentDidMount(): void {
		new ErrorService().init();
		new EntityActionService().init();

		appendCrazyEggScript();
		addLoaderScript(footballLoaderUrl, WidgetScriptTypes.WIDGETS);
		detectUserLocationChange();
	}

	render() {
		return (
			<I18nextProvider i18n={i18n}>
				<Provider store={store}>
					<QueryClientProvider client={queryClient}>
						<PersistGate loading={loading()} persistor={persistor}>
							<ServiceProvider actionService={actionService} featuresService={featuresService}>
								<HashRouter>
									<ToastContainer />
									<Switch>
										<Route exact path='/login' name='Login Page' component={Login} />
										<PrivateRoute exact path='/project-select' name='Project Selection' component={ProjectSelect} />
										<PrivateRoute path='/' name='Home' component={DefaultLayout} />
									</Switch>
								</HashRouter>
							</ServiceProvider>
						</PersistGate>
					</QueryClientProvider>
				</Provider>
			</I18nextProvider>
		);
	}
}

export default App;
