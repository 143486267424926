import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import React, { useState } from 'react';
import { MainMediaContentTypes, MainMediaTypes } from '../../../../../constants/content-types';
import GettyImageModel from '../../models/getty-image.model';
import { useTranslation } from 'react-i18next';
import { DATA_QA_ATTRIBUTES } from '../../../../Partials/Sidebar/media-refactored/constants/data-qa.attributes';
import { GettyImageContainer } from '../partials/getty-image-container.component';
import GettyApiService from '../../helpers/getty-api.service';
import { responseToGettyImageModel } from '../../models/getty-image.mapper';

type Properties = {
	onSelect: (item: GettyImageModel) => void;
	isOpen: boolean;
	toggleModal: (isOpen: boolean) => void;
	gettyInstance: GettyApiService;
	onDownLoadSizeSelect: (size: string) => void;
	selectedItemDownloadSize: string;
	requestsExecuting: boolean;
};

const GettyModal: React.FunctionComponent<Properties> = ({
	toggleModal,
	isOpen,
	onSelect,
	gettyInstance,
	onDownLoadSizeSelect,
	selectedItemDownloadSize,
	requestsExecuting,
}) => {
	const [selectedMainMediaItem, setSelectedMainMediaItem] = useState<MainMediaContentTypes | null>(null);
	const [mediaId, setMediaId] = useState<string>('');

	const [t] = useTranslation();

	const onContentSelect = async (item: MainMediaContentTypes) => {
		if (item.id) {
			await gettyInstance.getGettyImageDetails(item.id).then((res: any) => {
				const selectedImage = responseToGettyImageModel(res.images[0]);
				setMediaId(selectedImage ? selectedImage.id : '');
				setSelectedMainMediaItem(selectedImage);
			});
		}
	};

	return (
		<div id={DATA_QA_ATTRIBUTES.GETTY_MODAL}>
			<Modal size='xl' isOpen={isOpen}>
				<ModalHeader toggle={() => toggleModal(false)}>{t(`${MainMediaTypes.GETTY_IMAGE}`)}</ModalHeader>
				<ModalBody>
					<div data-children='.item'>
						<div className='item'>
							<GettyImageContainer
								onSelect={onContentSelect}
								selectedImage={selectedMainMediaItem as GettyImageModel}
								inBlocky={false}
								gettyInstance={gettyInstance}
								onDownLoadSizeSelect={onDownLoadSizeSelect}
								selectedItemDownloadSize={selectedItemDownloadSize}
							/>
						</div>
					</div>
				</ModalBody>
				<ModalFooter>
					<Button id={DATA_QA_ATTRIBUTES.GETTY_MODAL_CLOSE} color='danger' className='text-light' onClick={() => toggleModal(false)}>
						{t('close')}
					</Button>
					<Button
						disabled={!mediaId || selectedItemDownloadSize.length === 0 || requestsExecuting}
						id={DATA_QA_ATTRIBUTES.GETTY_MODAL_SAVE}
						className='btn-dark text-light'
						onClick={() => onSelect(selectedMainMediaItem as GettyImageModel)}
					>
						{t('save')}
					</Button>
				</ModalFooter>
			</Modal>
		</div>
	);
};

export default GettyModal;
