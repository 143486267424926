import { SportTypes } from '../constants/sport.types';

export const getSportIcon = (sport: SportTypes): string => {
	switch (sport) {
		case SportTypes.FOOTBALL:
			return require('./../assets/icons/sports/football.svg');
		case SportTypes.BASKETBALL:
			return require('./../assets/icons/sports/basketball.svg');
		case SportTypes.TENNIS:
			return require('./../assets/icons/sports/tennis.svg');
		case SportTypes.CYCLING:
			return require('./../assets/icons/sports/cycling.svg');
		case SportTypes.HANDBALL:
			return require('./../assets/icons/sports/handball.svg');
		case SportTypes.ICE_HOCKEY:
			return require('./../assets/icons/sports/ice-hockey.svg');
		case SportTypes.MOTORSPORTS:
			return require('./../assets/icons/sports/motorsports.svg');
		default:
			return '';
	}
};

export const getDeleteIcon = require('./../assets/icons/general/delete.svg');

export const getInfoIcon = require('./../assets/icons/general/info.svg');

export const getCloseIcon = require('./../assets/icons/general/close.svg');

export const getAddIcon = require('./../assets/icons/general/add-icon.svg');

export const getCustomEntitiesDeleteIcon = require('./../assets/icons/general/delete-icon.svg');

export const getEditIcon = require('./../assets/icons/general/edit-icon.svg');

export const getFilterIcon = require('./../assets/icons/general/filter-icon.svg');

export const getSearchIcon = require('./../assets/icons/general/search-icon.svg');

export const getStarIcon = (isActive: boolean = false): string => {
	return isActive ? require('./../assets/icons/general/star-active.svg') : require('./../assets/icons/general/star.svg');
};

export const getPlusIcon = (isActive: boolean = false, isDisabled: boolean = false): string => {
	if (isDisabled) {
		return require('./../assets/icons/general/plus-circle-disabled.svg');
	}
	return isActive ? require('./../assets/icons/general/plus-circle-active.svg') : require('./../assets/icons/general/plus-circle.svg');
};

export const getAddIconAsCssUrl = (): string => {
	return `url("${getAddIcon}")`;
};

export const getDeleteIconAsCssUrl = (): string => {
	return `url("${getCustomEntitiesDeleteIcon}")`;
};

export const getEditIconAsCssUrl = (): string => {
	return `url("${getEditIcon}")`;
};

export const getFilterIconAsCssUrl = (): string => {
	return `url("${getFilterIcon}")`;
};

export const getSearchIconAsCssUrl = (): string => {
	return `url("${getSearchIcon}")`;
};
