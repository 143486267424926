import 'reflect-metadata';
import { jsonMember, jsonObject } from 'typedjson';
import TournamentModel from '../../Tournament/Entity/TournamentModel';

@jsonObject()
export default class SeasonModel {
	@jsonMember(Number)
	public id: number = 0;
	@jsonMember(String)
	public name: string = '';
	@jsonMember(Boolean)
	public active: boolean = false;
	@jsonMember(Object)
	public stage: object = {};
	@jsonMember(() => TournamentModel)
	public tournament?: TournamentModel;
	@jsonMember(String)
	public slug: string | null = null;
}
