import Author from '../author/Author';
import Urls from '../urls/Urls';
import Seo from '../seo/Seo';
import Category from '../category/Category';
import { VideoJson } from './VideoJson';
import Video from './Video';
import MainMedia from '../mainmedia/MainMedia';
import { MainMediaJson } from '../mainmedia/MainMediaJson';
import { VideoRequestJson } from './VideoRequestJson';
import ContentAttributes from '../content-attributes/content-attributes-model';
import BlockModel from '../../views/Partials/Blocky/models/block.model';
import VideoUrls from '../urls/video-urls/video-urls';
import { filterEmptyBlocks, removeTagsFromParagraphBlocks } from '../helpers/content-helpers';
import TranslationGroupModel from '../translation-groups/translation-group-model';
import { DynamicContentProperty } from '../../constants/content-types';

export default class VideoBuilder {
	private json: VideoJson;

	constructor(video?: Video | VideoJson) {
		if (video && video instanceof Video) {
			this.json = video.toJSON();
		} else if (video) {
			this.json = video;
		} else {
			this.json = {} as VideoJson;
		}
	}

	withId(id: string): VideoBuilder {
		this.json.id = id;

		return this;
	}

	withTitle(title: string): VideoBuilder {
		this.json.title = title;

		return this;
	}

	withSubtitle(subtitle: string): VideoBuilder {
		this.json.subtitle = subtitle;

		return this;
	}

	withStrapline(strapline: string): VideoBuilder {
		this.json.strapline = strapline;

		return this;
	}

	withType(type: string): VideoBuilder {
		this.json.type = type;

		return this;
	}

	withBody(body: BlockModel[]): VideoBuilder {
		this.json.body = body;

		return this;
	}

	withPublishedAt(publishedAt: string): VideoBuilder {
		this.json.publishedAt = publishedAt;

		return this;
	}

	withUpdatedAt(updatedAt: string): VideoBuilder {
		this.json.updatedAt = updatedAt;

		return this;
	}

	withPublishedUntil(publishedUntil: string): VideoBuilder {
		this.json.publishedUntil = publishedUntil;

		return this;
	}

	withStatus(status: string): VideoBuilder {
		this.json.status = status;

		return this;
	}

	withComments(comments: any): VideoBuilder {
		this.json.comments = comments;

		return this;
	}

	withCustomAuthor(customAuthor: string): VideoBuilder {
		this.json.customAuthor = customAuthor;

		return this;
	}

	withAuthors(authors: Author[]): VideoBuilder {
		this.json.authors = authors;

		return this;
	}

	withImage(image: any): VideoBuilder {
		this.json.image = image;

		return this;
	}

	withGeneric(generic: any): VideoBuilder {
		this.json.generic = generic;

		return this;
	}

	withContentFooter(footer: string): VideoBuilder {
		this.json.footer = footer;

		return this;
	}

	withUrls(urls: Urls): VideoBuilder {
		this.json.urls = urls;

		return this;
	}

	withSeo(seo: Seo): VideoBuilder {
		this.json.seo = seo;

		return this;
	}

	withCategory(category: Category): VideoBuilder {
		this.json.category = category;

		return this;
	}

	withAdditionalCategories(additionalCategories: Category[]): VideoBuilder {
		this.json.additionalCategories = additionalCategories;

		return this;
	}

	withDistributionRegions(distributionRegions: ContentAttributes[]): VideoBuilder {
		this.json.distributionRegions = distributionRegions;

		return this;
	}

	withDistributionChannels(distributionChannels: ContentAttributes[]): VideoBuilder {
		this.json.distributionChannels = distributionChannels;

		return this;
	}

	withOrigin(origin: any): VideoBuilder {
		this.json.origin = origin;

		return this;
	}

	withMainMedia(mainMedia: MainMedia[]): VideoBuilder {
		this.json.mainMedia = mainMedia;

		return this;
	}

	withCreatedBy(createdBy: any): VideoBuilder {
		this.json.createdBy = createdBy;

		return this;
	}

	withCreatedAt(createdAt: string): VideoBuilder {
		this.json.createdAt = createdAt;

		return this;
	}

	withVideoFiles(videoFiles: VideoUrls[]): VideoBuilder {
		this.json.videoFiles = videoFiles;

		return this;
	}

	withViewsCount(viewsCount: any): VideoBuilder {
		this.json.viewsCount = viewsCount;

		return this;
	}

	withCommentsCount(commentsCount: any): VideoBuilder {
		this.json.commentsCount = commentsCount;

		return this;
	}

	withLanguage(language: any): VideoBuilder {
		this.json.language = language;

		return this;
	}

	withTranslationGroup(translationGroup: TranslationGroupModel): VideoBuilder {
		this.json.translationGroup = translationGroup;

		return this;
	}

	// this is used to set dynamic 'properties' placed in general content tab
	withDynamicProperties(dynamicProperties: DynamicContentProperty[]): VideoBuilder {
		this.json.properties = dynamicProperties.reduce((obj, item) => Object.assign(obj, { [item.slug]: item.value }), {});

		return this;
	}

	toRequestJson(): VideoRequestJson {
		const authorsForRequest = this.json.authors
			? this.json.authors.filter((author: any) => author && author.id).map((author: Author) => author.id)
			: [];

		let json = {} as VideoRequestJson;
		json.seo = {
			slug: '',
			title: '',
			description: '',
			keywords: [],
			index: false,
			follow: false,
			redirect_type: '',
			jsonld: '',
		};

		json.id = this.json.id;
		json.title = this.json.title;
		json.subtitle = this.json.subtitle;
		json.strapline = this.json.strapline;
		json.body = removeTagsFromParagraphBlocks(filterEmptyBlocks(this.json.body));
		json.category_id = this.json && this.json.category ? this.json.category.id : '';
		json.published_at = this.json.publishedAt;
		json.updated_at = this.json.updatedAt;
		json.published_until = this.json.publishedUntil;
		json.status = this.json.status;
		json.allow_comments = true;
		json.comment_collection_id = '';
		json.authors = authorsForRequest;
		json.custom_author = this.json.customAuthor;
		json.language = this.json.language ? this.json.language : '';
		json.image_id = '';
		json.image_description = '';
		json.additional_categories = this.json.additionalCategories
			? this.json.additionalCategories.map((category: Category) => category.id)
			: [];
		json.generic = this.json.generic;
		json.footer = this.json.footer;
		json.published_regions =
			this.json.distributionRegions && this.json.distributionRegions.length > 0
				? this.json.distributionRegions.map((region: ContentAttributes) => region.id)
				: [];
		json.published_channels =
			this.json.distributionChannels && this.json.distributionChannels.length > 0
				? this.json.distributionChannels.map((channel: ContentAttributes) => channel.id)
				: [];
		json.origin_id = this.json.origin && this.json.origin.id ? this.json.origin.id : '';
		json.external_url = this.json.urls && this.json.urls.externalUrl ? this.json.urls.externalUrl : '';
		json.canonical_url = this.json.urls && this.json.urls.canonicalUrl ? this.json.urls.canonicalUrl : '';
		json.seo.slug = this.json.seo && this.json.seo.slug ? this.json.seo.slug.toLowerCase() : '';
		json.seo.title = this.json.seo && this.json.seo.title ? this.json.seo.title : '';
		json.seo.description = this.json.seo && this.json.seo.description ? this.json.seo.description : '';
		json.seo.keywords = this.json.seo && this.json.seo.keywords ? this.json.seo.keywords : [];
		json.seo.index = this.json.seo && this.json.seo.index ? this.json.seo.index : false;
		json.seo.follow = this.json.seo && this.json.seo.follow ? this.json.seo.follow : false;
		json.seo.redirect_type = this.json.seo && this.json.seo.redirectType ? this.json.seo.redirectType : '';
		json.type = this.json.type ? this.json.type : '';
		json.seo.jsonld = this.json.seo && this.json.seo.jsonld ? this.json.seo.jsonld : '';
		json.comment_policy_id =
			this.json.comments && this.json.comments.policy && this.json.comments.policy.id ? this.json.comments.policy.id : '';
		json.main_media = this.json.mainMedia
			? this.json.mainMedia.map((media: MainMediaJson) => {
					return {
						resource_id: media.resourceId,
						resource_type: media.resourceType,
						resource_subtype: media.resourceSubtype,
						provider: media.provider,
						description: media.description,
						data: media.data,
					};
			  })
			: [];
		json.video_files = this.json.videoFiles;
		json.translation_group_id = this.json && this.json.translationGroup && this.json.translationGroup.id ? this.json.translationGroup.id : '';

		// this will extract 'properties' object and will populate the 'json' object with its dynamic entities
		Object.keys(this.json.properties).forEach((propertyKey: string) => (json[propertyKey] = this.json.properties[propertyKey]));

		return json;
	}

	isEmpty(): boolean {
		if (this.json) {
			const isTitleSet = this.json.title && this.json.title.length > 0;
			const isStraplineSet = this.json.strapline && this.json.strapline.length > 0;
			const isSubtitleSet = this.json.subtitle && this.json.subtitle.length > 0;
			const isBodySet =
				this.json.body &&
				((this.json.body.length === 1 &&
					this.json.body[0].data.content &&
					this.json.body[0].data.content.replace(/(<([^>]+)>)/gi, '') !== '') ||
					this.json.body.length > 1);
			const isCategorySet = this.json.category;
			const isAdditionalCategoriesSet = this.json.additionalCategories && this.json.additionalCategories.length > 0;
			const isPublishedAtSet = this.json.publishedAt && this.json.publishedAt.length > 0;
			const isPublishedUntilSet = this.json.publishedUntil && this.json.publishedUntil.length > 0;
			const isCustomAuthorSet = this.json.customAuthor && this.json.customAuthor.length > 0;
			const isSeoSet = !Seo.builder(this.json.seo).isEmpty();
			const isUrlsSet = !Urls.builder(this.json.urls).isEmpty();
			const isMainMediaSet = this.json.mainMedia && this.json.mainMedia.length > 0;
			const areVideoFilesEmpty = this.json.videoFiles && this.json.videoFiles.length > 0;

			return !(
				isTitleSet ||
				isStraplineSet ||
				isSubtitleSet ||
				isBodySet ||
				isPublishedAtSet ||
				isPublishedUntilSet ||
				isCustomAuthorSet ||
				isAdditionalCategoriesSet ||
				isSeoSet ||
				isUrlsSet ||
				isMainMediaSet ||
				isCategorySet ||
				areVideoFilesEmpty
			);
		}

		return true;
	}

	build(): Video {
		return Video.fromJSON(this.json);
	}
}
