import Social from '../social/social';
import BlacklistModel from './blacklist/blacklist';
import EnumItem from '../enum/EnumItem';
import { TeamManualDataJson } from './team-manual-data.json';
import TeamManualDataBuilder from './team-manual-data.builder';
import AssetsModel from './assets/assets';

export default class TeamManualDataModel {
	readonly id: string;
	readonly name: string;
	readonly type: EnumItem;
	readonly country: any;
	readonly coach: any;
	readonly venue: any;
	readonly president: any;
	readonly founded: string;
	readonly threeLetterCode: any;
	readonly social: Social;
	readonly blacklist: BlacklistModel;
	readonly blacklists: BlacklistModel[];
	readonly assets: AssetsModel;
	readonly shirtColor: any;
	readonly shortName: string;

	private constructor(
		id: string,
		name: string,
		type: EnumItem,
		country: any,
		coach: any,
		venue: string,
		president: string,
		founded: string,
		threeLetterCode: any,
		social: Social,
		blacklist: BlacklistModel,
		blacklists: BlacklistModel[],
		assets: AssetsModel,
		shirtColor: any,
		shortName: string,
	) {
		this.id = id;
		this.name = name;
		this.type = type;
		this.country = country;
		this.coach = coach;
		this.venue = venue;
		this.president = president;
		this.founded = founded;
		this.threeLetterCode = threeLetterCode;
		this.social = social;
		this.blacklist = blacklist;
		this.blacklists = blacklists;
		this.assets = assets;
		this.shirtColor = shirtColor;
		this.shortName = shortName;
	}

	toJSON(): TeamManualDataJson {
		return {
			id: this.id,
			name: this.name,
			type: this.type,
			country: this.country,
			coach: this.coach,
			venue: this.venue,
			president: this.president,
			founded: this.founded,
			threeLetterCode: this.threeLetterCode,
			social: this.social,
			blacklist: this.blacklist,
			blacklists: this.blacklists,
			assets: this.assets,
			shirtColor: this.shirtColor,
			shortName: this.shortName,
		};
	}

	static fromJSON(json: TeamManualDataJson): TeamManualDataModel {
		return new TeamManualDataModel(
			json.id,
			json.name,
			json.type,
			json.country,
			json.coach,
			json.venue,
			json.president,
			json.founded,
			json.threeLetterCode,
			json.social,
			json.blacklist,
			json.blacklists,
			json.assets,
			json.shirtColor,
			json.shortName,
		);
	}

	static builder(team?: TeamManualDataModel): TeamManualDataBuilder {
		return new TeamManualDataBuilder(team);
	}
}
