import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import React, { useState } from 'react';
import ImagoImageListingContainer from '../media-main-image/subcomponents/imago-image-listing/ImagoImageListingContainer';
import Project from '../../../../../../models/project/Project';
import ImagoImageModel from '../../../../Blocky/blocks/content/imago-image-block/models/imago-image.model';
import BlockModel from '../../../../Blocky/models/block.model';
import { MainMediaContentTypes, MainMediaTypes } from '../../../../../../constants/content-types';
import DugoutVideoContainer from '../../../../Blocky/blocks/content/dugout-video-block/dugout-video-container.component';
import DugoutVideoModel from '../../../../../../models/dugout-videos/dugout-video/dugout-video.model';
import OnnetworkContainer from '../../../../Blocky/blocks/content/onnetwork-video-block/onnetwork-video-container.component';
import OnnetworkVideoModel from '../../../../../../models/onnetwork-videos/onnetwork-video.model';
import { AmgVideoContainer } from '../../../../Blocky/blocks/content/amg-block/components/amg-video-container.component';
import AmgItemDataModel from '../../../../Blocky/blocks/content/amg-block/models/amg-item-data.model';

type Properties = {
	t: any;
	onSelect: (item: any) => void;
	isOpen: boolean;
	toggleModal: (isOpen: boolean) => any;
	project?: Project;
	blocks?: BlockModel[];
	contentType: string;
};

export const MainMediaContentModal: React.FunctionComponent<Properties> = ({
	t,
	toggleModal,
	contentType,
	blocks,
	isOpen,
	onSelect,
	project,
}) => {
	const [selectedMainMediaItem, setSelectedMainMediaItem] = useState<MainMediaContentTypes>();
	const [mediaId, setMediaId] = useState<string>('');

	const onContentSelect = async (item: MainMediaContentTypes) => {
		setMediaId(item.id);
		setSelectedMainMediaItem(item);
	};

	return (
		<div data-qa='main-media-modal'>
			<Modal isOpen={isOpen}>
				<ModalHeader
					toggle={() => {
						toggleModal(false);
					}}
				>
					{t(`${contentType}`)}
				</ModalHeader>
				<ModalBody>
					<div data-children='.item'>
						<div className='item'>
							{contentType === MainMediaTypes.IMAGO_IMAGE && (
								<ImagoImageListingContainer
									selectedImagoImage={selectedMainMediaItem as ImagoImageModel}
									currentProject={project ? project : ({} as Project)}
									onImageSelect={onContentSelect}
									t={t}
								/>
							)}
							{contentType === MainMediaTypes.DUGOUT_VIDEO && (
								<DugoutVideoContainer
									blocks={blocks ? blocks : []}
									isMediaSidebar
									selectedDugoutVideo={selectedMainMediaItem as DugoutVideoModel}
									onSelect={onContentSelect}
									t={t}
									updateSearchText={() => undefined}
									inBlocky={false}
								/>
							)}
							{contentType === MainMediaTypes.ONNETWORK_VIDEO && (
								<OnnetworkContainer
									blocks={blocks ? blocks : []}
									isMediaSidebar
									selectedVideo={selectedMainMediaItem as OnnetworkVideoModel}
									onSelect={onContentSelect}
									t={t}
									inBlocky={false}
								/>
							)}
							{contentType === MainMediaTypes.AMG_VIDEO && (
								<AmgVideoContainer
									blocks={blocks ? blocks : []}
									isMediaSidebar
									selectedVideo={selectedMainMediaItem as AmgItemDataModel}
									onSelect={onContentSelect}
									t={t}
									inBlocky={false}
									page={1}
								/>
							)}
						</div>
					</div>
				</ModalBody>
				<ModalFooter>
					<Button
						id='imago-modal-close-button'
						color='danger'
						className={'text-light'}
						onClick={() => {
							toggleModal(false);
						}}
					>
						{t('close')}
					</Button>
					<Button
						disabled={!mediaId}
						id='imago-modal-save-button'
						color='primary'
						className={'text-light'}
						onClick={() => {
							onSelect(selectedMainMediaItem);
						}}
					>
						{t('save')}
					</Button>
				</ModalFooter>
			</Modal>
		</div>
	);
};
