import React, { FunctionComponent } from 'react';
import RoundedBlueButton from '../buttons/rounded-blue';
import RoundedRedButton from '../buttons/rounded-red';
import './modal.scss';
import RoundedButton from '../buttons/rounded';
import { getCloseIcon } from '../../../../global-helpers/global-icons.helpers';
import { useTranslation } from 'react-i18next';

type Props = {
	isDisplayed: boolean;
	actionButtonText?: string;
	isPositiveAction?: boolean;
	action?: (args: any) => void;
	title?: string;
	bodyHTML: JSX.Element[];
	onClose: () => void;
	cancelButtonFlag?: boolean;
};

export const GeneralModal: FunctionComponent<Props> = ({
	isDisplayed,
	actionButtonText,
	title,
	bodyHTML,
	isPositiveAction = true,
	cancelButtonFlag = true,
	action,
	onClose,
}) => {
	const { t } = useTranslation();

	if (isDisplayed) {
		return (
			<>
				<div className='general-modal-background'></div>
				<div className='general-modal'>
					<div id='header'>
						{title && <h3>{title}</h3>}
						<div className='close-header-btn' onClick={onClose} style={{ backgroundImage: `url("${getCloseIcon}")` }} />
					</div>

					{bodyHTML && <div>{bodyHTML}</div>}
					<div id='general-modal-action-buttons'>
						{cancelButtonFlag && <RoundedButton text={t('cancel')} title={t('cancel')} onClickAction={onClose} />}

						{actionButtonText && isPositiveAction && (
							<RoundedBlueButton text={actionButtonText} title={actionButtonText} onClickAction={action} />
						)}
						{actionButtonText && !isPositiveAction && (
							<RoundedRedButton text={actionButtonText} title={actionButtonText} onClickAction={action} />
						)}
					</div>
				</div>
			</>
		);
	}

	return null;
};

export default GeneralModal;
