import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Button, FormGroup, Input, Label, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import {
	Formation,
	LineupStatus,
	PlayersType,
	remapFormationsFromResponse,
	remapMatchLineupFromResponse,
	remapPlayersTypes,
	remapTeamLineupToRequest,
} from './subcomponents/helpers/response-remappers.helper';
import HttpService from '../../../services/rest/HttpService';
import { AppState } from '../../../store/store';
import LineupModel from './models/lineup/lineup.model';
import MatchDetails from './subcomponents/match/match-details.component';
import Team from './subcomponents/team/team.component';
import MatchEvents from './subcomponents/events/events.component';
import LineupPlayerModel from './models/player-lineup/lineup-player.model';
import { toast } from 'react-toastify';
import { areTeamLineupsFilled, removePlaceholdersFromLineups } from './subcomponents/helpers/lists-update.helper';
import { updateMatchLineup } from '../../../store/action-creators/match-lineups-action-creator';
import MatchDetailsModel from './models/match/match-details.model';
import { toggleLoadingState } from '../../../store/action-creators/UiActionCreator';
import { matchDetailsSaveDisable, validateByEventStatus, validateMatchScore } from './subcomponents/helpers/match-details.helper';
import { remapEventsForSave, remapEventsFromResponse } from './subcomponents/helpers/events-remappers.helper';
import EventModel from './models/event/event.model';
import Blacklisted from '../manual-data-entries/subcomponents/blacklisted-container.tsx/blacklisted-container';
import { constructRequestFromEntry, remapBlacklistFromResponse } from '../manual-data-entries/subcomponents/helpers/manual-data.helper';
import BlacklistModel from '../../../models/blacklist/blacklist';
import BlacklistModal from '../manual-data-entries/subcomponents/blacklist-modal/blacklist-modal';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import ColorPickerComponent from '../../Partials/Blocky/blocks/widgets/match/components/color-picker.component';

type Properties = {
	t: any;
	analytics: any;
	match: any;
};

const MatchEdit: React.FunctionComponent<RouteComponentProps<{}> & Properties> = (props) => {
	const { t } = props;
	const match = useSelector((state: AppState) => state.matchLineupsEdit.match);
	const isLoading = useSelector((state: AppState) => state.ui.toggleLoadingState);

	const [events, setEvents] = useState<EventModel[]>([]);
	const [eventsBlacklist, setEventsBlacklist] = useState<BlacklistModel>(BlacklistModel.builder().build());

	// Update match state on changing methods
	const dispatch = useDispatch();

	const matchUpdate = (match: MatchDetailsModel | null) => {
		if (match && match.blacklist) {
			dispatch(updateMatchLineup(match));
		} else {
			dispatch(updateMatchLineup(match));
			match && getBlacklistEntityData(match.id, 'MATCH', 'ENTITY', null);
		}
	};
	const setLoading = (isLoading: boolean) => dispatch(toggleLoadingState(isLoading));

	const [activeTab, setActiveTab] = useState<string>('1');

	// Players types from '/event_player_types' used as enum.
	const [playersTypes, setPlayersTypes] = useState<PlayersType>({} as PlayersType);
	// Current match lineup information (matchId, status, homeTeam, awayTeam).
	const [lineup, setLineup] = useState<LineupModel>({} as LineupModel);
	const [formations, setFormations] = useState<Formation[]>([]);
	// Home and away teams starting 11 players and formation for the current match.
	const [homeTeamFormation, selectHomeTeamFormation] = useState<Formation>({ formation: null, positions: [] });
	const [awayTeamFormation, selectAwayTeamFormation] = useState<Formation>({ formation: null, positions: [] });
	// Home and away teams substitutes for the current match.
	const [homeSquad, setHomeSquad] = useState<LineupPlayerModel[]>([]);
	const [homeSubstitutes, setHomeSubstitutes] = useState<LineupPlayerModel[]>([]);
	// Home and away teams squad players not included in the current match.
	const [awaySquad, setAwaySquad] = useState<LineupPlayerModel[]>([]);
	const [awaySubstitutes, setAwaySubstitutes] = useState<LineupPlayerModel[]>([]);
	// Home and away teams id.
	const [homeTeamId, setHomeTeamId] = useState('');
	const [awayTeamId, setAwayTeamId] = useState('');
	// Home and away teams shirt colors.
	const [selectedHomeTeamColor, setSelectedHomeTeamColor] = useState('');
	const [selectedAwayTeamColor, setSelectedAwayTeamColor] = useState('');
	const [matchResponseColors, setMatchResponseColors] = useState<any[]>([]);

	const [teamShirtColorsAreTheSame, setTeamShirtColorsAreTheSame] = useState(true);

	const [displayHomeTeamColorPicker, setDisplayHomeTeamColorPicker] = useState(false);
	const [displayAwayTeamColorPicker, setDisplayAwayTeamColorPicker] = useState(false);
	const [saveColorsPrompt, setSaveColorsPrompt] = useState(false);

	const [isOpenModal, toggleBlacklistModal] = useState<boolean>(false);

	const changeLineupStatus = (event: any) => {
		const lineupStatus = event.target.checked ? LineupStatus.CONFIRMED : LineupStatus.UNCONFIRMED;
		setLineup(LineupModel.builder(lineup).withStatus(lineupStatus).build());
	};

	const toggle = (tab: string) => {
		if (activeTab !== tab) {
			setActiveTab(tab);
		}
	};

	const matchSave = () => {
		const homeTeam = remapTeamLineupToRequest(lineup.homeTeam, homeTeamFormation, homeSubstitutes);
		const awayTeam = remapTeamLineupToRequest(lineup.awayTeam, awayTeamFormation, awaySubstitutes);
		const matchLineup = LineupModel.builder(lineup).withHomeTeam(homeTeam).withAwayTeam(awayTeam).build();
		const lineupRequest = LineupModel.builder(matchLineup).toRequestJson();

		HttpService.instanceFootball()
			.put(`/v2/matches/${match.id}/lineups`, lineupRequest)
			.then(() => {
				getBlacklistEntityData(match.id, 'MATCH', 'RELATION', 'lineup');
				toast.success(t('match_update_success'));
			})
			.catch(() => toast.error(t('match_update_failed')));
	};

	const matchDetailsSave = () => {
		const matchDetailsRequest = MatchDetailsModel.builder(match).toRequestJson();
		HttpService.instanceFootball()
			.put(`/v2/matches/${match.id}`, matchDetailsRequest)
			.then(() => {
				getLineups(match.id);
				getBlacklistEntityData(match.id, 'MATCH', 'ENTITY', null);
				toast.success(t('match_update_success'));
			})
			.catch(() => toast.error(t('match_update_failed')));
	};

	const matchEventsSave = () => {
		const eventsRequest = remapEventsForSave(events);
		HttpService.instanceFootball()
			.put(`/v2/matches/${match.id}/events`, { events: eventsRequest })
			.then((response: any) => {
				setEvents(remapEventsFromResponse(response.data.events));
				getBlacklistEntityData(match.id, 'MATCH', 'RELATION', 'events');
				toast.success(t('events_update_success'));
			})
			.catch(() => toast.error(t('events_update_failed')));
	};

	const teamColorsSave = () => {
		saveSelectedTeamColors();
	};

	const getFormations = () => {
		HttpService.instanceFootball()
			.get(`/v2/lineup_formations`)
			.then((response: any) => {
				setLoading(false);
				setFormations(remapFormationsFromResponse(response.data));
			})
			.catch(() => undefined);
	};

	const getLineups = (id: string) => {
		HttpService.instanceFootball()
			.get(`/v2/matches/${id}/lineups`)
			.then((response: any) => {
				setLineup(remapMatchLineupFromResponse(response.data, match));
			})
			.catch(() => undefined);
	};

	const getPlayerTypes = () => {
		HttpService.instanceFootball()
			.get('/event_player_types')
			.then((response: any) => setPlayersTypes(remapPlayersTypes(response.data)))
			.catch(() => undefined);
	};

	const toggleHomeTeamColorPicker = (toggle: boolean) => {
		setDisplayHomeTeamColorPicker(toggle);
	};

	const toggleAwayTeamColorPicker = (toggle: boolean) => {
		setDisplayAwayTeamColorPicker(toggle);
	};

	const handleHomeTeamChangeColor = (color: any) => {
		setSelectedHomeTeamColor(color.hex);
	};

	const handleAwayTeamChangeColor = (color: any) => {
		setSelectedAwayTeamColor(color.hex);
	};

	const saveSelectedTeamColors = () => {
		const selectedTeamColors = [];
		if (selectedHomeTeamColor) {
			selectedTeamColors.push({ type: 'home', color_code: selectedHomeTeamColor });
		}
		if (selectedAwayTeamColor) {
			selectedTeamColors.push({ type: 'away', color_code: selectedAwayTeamColor });
		}

		const params = {
			entity_type: 'match',
			entity_id: props.match.params.id,
			colors: selectedTeamColors,
		};

		HttpService.saveTeamColors(`/v2/teams/colors`, params)
			.then(() => {
				toast.success(t('team_colors_success'));
				setSaveColorsPrompt(false);
			})
			.catch((error: unknown) => console.error(error));
	};

	const getTeamShirtColors = (teamId: string, teamType: string, useMatchColor: boolean, teamChanged: boolean) => {
		const homeTeamShirtIndex = 0;
		const awayTeamShirtIndex = 1;
		teamId &&
			HttpService.getTeamColors(teamId)
				.then((res: any) => {
					if (res) {
						if (teamType === 'home') {
							if (res.data.shirt_colors) {
								setSelectedHomeTeamColor(res.data.shirt_colors[homeTeamShirtIndex].color_code);
							} else {
								setSelectedHomeTeamColor('');
							}
						}

						if (teamType === 'away') {
							if (res.data.shirt_colors) {
								if (!teamChanged) {
									if (useMatchColor && res.data.shirt_colors[homeTeamShirtIndex].color_code) {
										setSelectedAwayTeamColor(res.data.shirt_colors[homeTeamShirtIndex].color_code);
									} else {
										setSelectedAwayTeamColor(res.data.shirt_colors[awayTeamShirtIndex].color_code);
									}
								}

								if (teamChanged) {
									if (selectedHomeTeamColor === res.data.shirt_colors[homeTeamShirtIndex].color_code) {
										setSelectedAwayTeamColor(res.data.shirt_colors[awayTeamShirtIndex].color_code);
									} else {
										setSelectedAwayTeamColor(res.data.shirt_colors[homeTeamShirtIndex].color_code);
									}
								}
							} else {
								setSelectedAwayTeamColor('');
							}
						}
					}
				})
				.catch(() => undefined);
	};

	const checkIfTeamColorsAreTheSame = (homeTeam: string, awayTeam: string) => {
		if (homeTeam === awayTeam) {
			homeTeam === null && awayTeam === null ? setTeamShirtColorsAreTheSame(false) : setTeamShirtColorsAreTheSame(true);
		} else {
			setTeamShirtColorsAreTheSame(false);
		}
	};

	const getMatchTeamColors = (homeTeamId: string, awayTeamId: string) => {
		HttpService.getMatchById(props.match.params.id)
			.then((res: any) => {
				setMatchResponseColors([res.data.home_team.shirt_color, res.data.away_team.shirt_color]);
				checkIfTeamColorsAreTheSame(res.data.home_team.shirt_color, res.data.away_team.shirt_color);

				if (res.data.home_team.shirt_color) {
					setSelectedHomeTeamColor(res.data.home_team.shirt_color);
				} else {
					getTeamShirtColors(homeTeamId, 'home', true, false);
				}

				if (res.data.away_team.shirt_color) {
					if (res.data.home_team && res.data.home_team.shirt_color === res.data.away_team && res.data.away_team.shirt_color) {
						getTeamShirtColors(awayTeamId, 'away', false, false);
					} else {
						setSelectedAwayTeamColor(res.data.away_team.shirt_color);
					}
				} else {
					getTeamShirtColors(awayTeamId, 'away', true, false);
				}
			})
			.catch((error: unknown) => console.error(error));
	};

	useEffect(() => {
		checkIfTeamColorsAreTheSame(selectedHomeTeamColor, selectedAwayTeamColor);
	}, [selectedHomeTeamColor, selectedAwayTeamColor]);

	useEffect(() => {
		if (match && match.homeTeam.id && match.awayTeam.id) {
			setHomeTeamId(match.homeTeam.id);
			setAwayTeamId(match.awayTeam.id);
		}
	}, [match && match.id]);

	useEffect(() => {
		getMatchTeamColors(homeTeamId, awayTeamId);
	}, [homeTeamId, awayTeamId]);

	useEffect(() => {
		if (match && match.homeTeam && match.awayTeam && homeTeamId && awayTeamId) {
			if (homeTeamId !== match.homeTeam.id) {
				getTeamShirtColors(match.homeTeam.id, 'home', true, true);
			}

			if (awayTeamId !== match.awayTeam.id) {
				getTeamShirtColors(match.awayTeam.id, 'away', true, true);
			}
		}
	}, [homeTeamId, awayTeamId, match]);

	useEffect(() => {
		if (awayTeamId) {
			if (selectedHomeTeamColor === selectedAwayTeamColor) {
				checkIfTeamColorsAreTheSame(selectedHomeTeamColor, selectedAwayTeamColor);
			}
		}

		if (
			(matchResponseColors[0] !== selectedHomeTeamColor && selectedHomeTeamColor) ||
			(matchResponseColors[1] !== selectedAwayTeamColor && selectedAwayTeamColor)
		) {
			setSaveColorsPrompt(true);
		} else {
			setSaveColorsPrompt(false);
		}
	}, [awayTeamId, selectedHomeTeamColor, selectedAwayTeamColor]);

	const getBlacklistEntityData = (id: string, entity: string, type: string, context: string | null) => {
		const params = constructRequestFromEntry([{ id: id }], entity, type, context);
		HttpService.getBlacklist(`/v2/blacklist/search`, params)
			.then((response: any) => {
				const blacklist = response.data;
				if (blacklist && blacklist.length > 0) {
					context === null &&
						dispatch(updateMatchLineup(MatchDetailsModel.builder(match).withBlacklist(remapBlacklistFromResponse(blacklist[0])).build()));
					context === 'lineup' && setLineup(LineupModel.builder(lineup).withBlacklist(remapBlacklistFromResponse(blacklist[0])).build());
					context === 'events' && setEventsBlacklist(remapBlacklistFromResponse(blacklist[0]));
				}
			})
			.catch((error: unknown) => console.error(error));
	};

	const removeFromBlacklist = () => {
		HttpService.removeFromBlacklist(contentToDeleteFromBlacklist[activeTab].blacklist.id)
			.then((response: any) => {
				activeTab === '1' &&
					dispatch(updateMatchLineup(MatchDetailsModel.builder(match).withBlacklist(BlacklistModel.builder().build()).build()));
				activeTab === '2' && setLineup(LineupModel.builder(lineup).withBlacklist(BlacklistModel.builder().build()).build());
				activeTab === '3' && setEventsBlacklist(BlacklistModel.builder().build());
				toggleBlacklistModal(false);
			})
			.catch((error: any) => {
				toggleBlacklistModal(false);
			});
	};

	useEffect(() => {
		setLoading(true);
		getPlayerTypes();
		document.title = t('edit_match');
	}, []);

	useEffect(() => {
		playersTypes && Object.keys(playersTypes).length > 0 && getFormations();
	}, [playersTypes]);

	useEffect(() => {
		match && formations && formations.length > 0 && getLineups(props.match.params.id);
	}, [formations]);

	useEffect(() => {
		if (match && match.id === props.match.params.id) {
			getBlacklistEntityData(props.match.params.id, 'MATCH', 'ENTITY', null);
		}
	}, [match && match.id]);

	useEffect(() => {
		activeTab === '2' && getBlacklistEntityData(props.match.params.id, 'MATCH', 'RELATION', 'lineup');
		activeTab === '3' && getBlacklistEntityData(props.match.params.id, 'MATCH', 'RELATION', 'events');
	}, [activeTab]);

	const matchSaveDisabled = !areTeamLineupsFilled(homeTeamFormation.positions) || !areTeamLineupsFilled(awayTeamFormation.positions);

	const phaseStartedIsNotValid = match && validateByEventStatus(match.eventStatus, 'phaseStartedAt') && !match.startTime;

	const scoreIsNotValid = match && validateByEventStatus(match.eventStatus, 'score') && validateMatchScore(match.score);

	const isMatchDetailsSaveDisabled = matchDetailsSaveDisable(match, phaseStartedIsNotValid, scoreIsNotValid);

	const isEventsSaveDisable = events.filter((event: EventModel) => event.primaryPlayer === undefined).length > 0;

	const tabsSave = {
		'1': { disable: isMatchDetailsSaveDisabled, save: matchDetailsSave },
		'2': { disable: matchSaveDisabled, save: matchSave },
		'3': { disable: isEventsSaveDisable, save: matchEventsSave },
		'4': { disable: teamShirtColorsAreTheSame, save: teamColorsSave },
	};

	const blacklistType = {
		'1': 'match',
		'2': 'lineup',
		'3': 'events',
		'4': 'team_colors',
	};

	const contentToDeleteFromBlacklist = {
		'1': match,
		'2': lineup,
		'3': { blacklist: eventsBlacklist },
	};

	return (
		<div className='nav-tabs-wrapper animated fadeIn'>
			{match && match.blacklist && match.blacklist.id && activeTab === '1' && (
				<Blacklisted t={t} type='match' isContentLoading={isLoading} toggleModal={toggleBlacklistModal} />
			)}
			{lineup && lineup.blacklist && lineup.blacklist.id && activeTab === '2' && (
				<Blacklisted t={t} type='lineup' isContentLoading={isLoading} toggleModal={toggleBlacklistModal} />
			)}
			{eventsBlacklist.id && activeTab === '3' && (
				<Blacklisted t={t} type='events' isContentLoading={isLoading} toggleModal={toggleBlacklistModal} />
			)}
			<Nav tabs className='mb-2'>
				<NavItem>
					<NavLink className={`${activeTab === '1' ? 'active' : ''} bg-transparent`} onClick={() => toggle('1')}>
						<i className='fa fa-futbol-o mr-2' />
						{t('match_details')}
					</NavLink>
				</NavItem>
				<NavItem>
					<NavLink className={`${activeTab === '2' ? 'active' : ''} bg-transparent`} onClick={() => toggle('2')}>
						<i className='fa fa-shield mr-2' />
						{t('teams')}
					</NavLink>
				</NavItem>
				<NavItem>
					<NavLink className={`${activeTab === '3' ? 'active' : ''} bg-transparent`} onClick={() => toggle('3')}>
						<i className='fa fa-calendar-check-o mr-2' />
						{t('events')}
					</NavLink>
				</NavItem>
				<NavItem>
					<NavLink className={`${activeTab === '4' ? 'active' : ''} bg-transparent`} onClick={() => toggle('4')}>
						<i className='fa fas fa-tint mr-2' />
						{t('team_colors')}
					</NavLink>
				</NavItem>
				<NavItem className='d-flex align-items-center ml-auto'>
					<Button disabled={tabsSave[activeTab].disable} onClick={tabsSave[activeTab].save} id='save-match-button-top' color='primary' size='sm'>
						<i className='fa fa-floppy-o mr-1' />
						{t(`save_${blacklistType[activeTab]}`)}
					</Button>
				</NavItem>
			</Nav>
			<TabContent activeTab={activeTab} className='border-0 bg-transparent'>
				<TabPane tabId='1' className='px-0'>
					{match && <MatchDetails isLoading={isLoading} onChange={matchUpdate} t={t} match={match} />}
					<Button disabled={isMatchDetailsSaveDisabled} onClick={matchDetailsSave} id='save-details-match-bottom' color='primary'>
						<i className='fa fa-floppy-o mr-1' />
						{t('save_match')}
					</Button>
				</TabPane>
				{lineup && lineup.homeTeam && lineup.awayTeam && (
					<TabPane tabId='2' className='px-0 pt-1'>
						<FormGroup check className='radio mb-2 ml-4'>
							<Input
								type='checkbox'
								id='lineup-status-checkbox'
								name='radios'
								onChange={changeLineupStatus}
								checked={lineup.status === LineupStatus.CONFIRMED}
							/>
							<Label check className='form-check-label' for='lineup-status-checkbox'>
								{t(lineup.status.toLocaleLowerCase())} {t('lineup')}
							</Label>
						</FormGroup>
						<Row>
							<Team
								t={t}
								squad={homeSquad}
								playersTypes={playersTypes}
								substitutes={homeSubstitutes}
								setSquad={setHomeSquad}
								teamFormation={homeTeamFormation}
								selectFormation={selectHomeTeamFormation}
								setSubstitutes={setHomeSubstitutes}
								team={match.homeTeam}
								formations={formations}
								teamLineup={lineup.homeTeam}
							/>
							<Team
								t={t}
								squad={awaySquad}
								playersTypes={playersTypes}
								substitutes={awaySubstitutes}
								setSquad={setAwaySquad}
								teamFormation={awayTeamFormation}
								selectFormation={selectAwayTeamFormation}
								setSubstitutes={setAwaySubstitutes}
								team={match.awayTeam}
								formations={formations}
								teamLineup={lineup.awayTeam}
							/>
						</Row>
						<Button disabled={matchSaveDisabled} onClick={matchSave} id='save-teams-bottom' color='primary'>
							<i className='fa fa-floppy-o mr-1' />
							{t('save_lineup')}
						</Button>
					</TabPane>
				)}
				{activeTab === '3' && (
					<TabPane tabId='3' className='px-0 pt-1'>
						<div className='animated fadeIn card'>
							<div className='card-header'>{t('events')}</div>
							<div className='card-body'>
								<MatchEvents
									events={events}
									setEvents={setEvents}
									t={t}
									squadAwayTeam={[...removePlaceholdersFromLineups(awayTeamFormation.positions), ...awaySubstitutes, ...awaySquad]}
									squadHomeTeam={[...removePlaceholdersFromLineups(homeTeamFormation.positions), ...homeSubstitutes, ...homeSquad]}
								/>
							</div>
						</div>
						<Button disabled={isEventsSaveDisable} onClick={matchEventsSave} id='save-event-bottom' color='primary'>
							<i className='fa fa-floppy-o mr-1' />
							{t('save_events')}
						</Button>
					</TabPane>
				)}

				{activeTab === '4' && (
					<TabPane tabId='4' className='px-0 pt-1'>
						<div className='animated fadeIn card'>
							<div className='card-header'>{t('team_colors')}</div>
							<div className='card-body'>
								<div className='row'>
									{teamShirtColorsAreTheSame && (
										<Alert className='align-middle' color='warning'>
											{props.t('warning_shirt_colors_are_the_same')}
										</Alert>
									)}
									{saveColorsPrompt && (
										<Alert className='align-middle' color='warning'>
											{props.t('unsaved_team_colors')}
										</Alert>
									)}
								</div>
								<div className='row'>
									<div className={window.screen.width < 500 ? 'col-12' : 'col-5'}>
										<div className='match-teams-color-picker-wrapper'>
											<div className='row'>
												<label htmlFor='for-home-team' className='mr-2 mb-0 team-color-picker-label'>
													{t('home_team_kit') + ':'}
												</label>
												<label htmlFor='for-home-team' className='mr-2 mb-0 team-label'>
													{match.homeTeam.name}
												</label>
												<div onClick={() => toggleHomeTeamColorPicker(true)} className='swatch teams-color-picker-box'>
													<div
														className={'color ' + (!selectedHomeTeamColor ? 'no-color-selected-bg' : '')}
														style={{ background: selectedHomeTeamColor }}
													/>
												</div>
											</div>
											{displayHomeTeamColorPicker && (
												<div className='popover'>
													<div className='cover' onClick={() => toggleHomeTeamColorPicker(false)} />
													<ColorPickerComponent
														color={selectedHomeTeamColor ? selectedHomeTeamColor : 'transparent'}
														handleChangeColor={handleHomeTeamChangeColor}
														toggleTeamColorPicker={toggleHomeTeamColorPicker}
													/>
												</div>
											)}
										</div>

										{!selectedHomeTeamColor && <p className='text-danger'>{t('no_color_selected')}</p>}
									</div>
									<div className={window.screen.width < 500 ? 'col-12' : 'col-5'}>
										<div className='match-teams-color-picker-wrapper'>
											<div className='row'>
												<label htmlFor='for-home-team' className='mr-2 mb-0 team-color-picker-label'>
													{t('away_team_kit') + ':'}
												</label>
												<label htmlFor='for-home-team' className='mr-2 mb-0 team-label'>
													{match.awayTeam.name}
												</label>
												<div onClick={() => toggleAwayTeamColorPicker(true)} className='swatch teams-color-picker-box'>
													<div
														className={'color ' + (!selectedAwayTeamColor ? 'no-color-selected-bg' : '')}
														style={{ background: selectedAwayTeamColor }}
													/>
												</div>
											</div>
											{displayAwayTeamColorPicker && (
												<div className='popover'>
													<div className='cover' onClick={() => toggleAwayTeamColorPicker(false)} />
													<ColorPickerComponent
														color={selectedAwayTeamColor ? selectedAwayTeamColor : 'transparent'}
														handleChangeColor={handleAwayTeamChangeColor}
														toggleTeamColorPicker={toggleAwayTeamColorPicker}
													/>
												</div>
											)}
										</div>
										{!selectedAwayTeamColor && <p className='text-danger'>{t('no_color_selected')}</p>}
									</div>
								</div>
							</div>
						</div>
						<Button disabled={teamShirtColorsAreTheSame} onClick={teamColorsSave} id='save-colors-bottom' color='primary'>
							<i className='fa fa-floppy-o mr-1' />
							{t('save_team_colors')}
						</Button>
					</TabPane>
				)}
			</TabContent>
			<BlacklistModal
				t={t}
				type={blacklistType[activeTab]}
				analytics={props.analytics}
				contentToDelete={contentToDeleteFromBlacklist[activeTab]}
				onDeleteContent={removeFromBlacklist}
				toggleModal={toggleBlacklistModal}
				isToggledModal={isOpenModal}
			/>
		</div>
	);
};

export default withRouter(MatchEdit);
