import React, { FunctionComponent, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import './image-dropzone.scss';
import { connect } from 'react-redux';
import REACT_APP_URLS from '../../../../global-helpers/global-url.helpers';
import { uploadImageFile } from './helper';
import ImagePreview from './image-preview';
import { useTranslation } from 'react-i18next';

export type OnImageUploaded = {
	path: string;
	fullImageUrl: string;
	original_filename: string;
	originalFilename?: string; // because image.api return camelCase property...
	url: string;
	timestamp: string; // because JS with numbers is pain...
};

export type OnImageCallback = (data: OnImageUploaded) => void;

type Props = {
	multipleFiles?: boolean;
	disabled?: boolean;
	minSize?: number;
	maxSize?: number;
	onImageUploaded?: OnImageCallback;
	onRemoveImage: (data: OnImageUploaded) => void;
	previewData?: OnImageUploaded;
	imageApiURL: string;
	assetsProject?: boolean;
	projectDomain: string;
	customImageApiData?: Record<string, string>;
};

const ImageDropzone: FunctionComponent<Props> = ({
	imageApiURL,
	projectDomain,
	multipleFiles = true,
	disabled = false,
	minSize,
	maxSize,
	assetsProject = false,
	onImageUploaded,
	onRemoveImage,
	previewData,
	customImageApiData,
}) => {
	const { t } = useTranslation();

	const iterateFile = (file: any) => {
		const projectHeader = assetsProject ? 'assets' : projectDomain;
		uploadImageFile(file, imageApiURL, projectHeader, onImageUploaded, customImageApiData);
	};

	const onDrop = useCallback((acceptedFiles) => {
		multipleFiles ? acceptedFiles.forEach((file: any) => iterateFile(file)) : iterateFile(acceptedFiles[0]);
	}, []);

	const { getRootProps, getInputProps } = useDropzone({ onDrop, multiple: multipleFiles, disabled, minSize, maxSize });

	return (
		<div className='react-dropzone-wrapper'>
			<div
				{...getRootProps({
					className: 'react-dropzone-custom-class',
				})}
			>
				<input {...getInputProps()} />
				<div className='dropzone-icon-upload' />
				<div className='dropzone-dnd-text-wrapper'>
					<span className='dropzone-dnd-text'> {t('drag_drop_image_1')}</span> &nbsp;
					<span className='dropzone-dnd-text link-style'> {t('choose_file')}</span> &nbsp;
					<span className='dropzone-dnd-text'> {t('drag_drop_image_2')}</span>
				</div>

				<span className='dropzone-dnd-subtext'>
					{t('image_files_format_description')} {t('image_files_description')}
				</span>
			</div>
			{previewData && <ImagePreview data={previewData} onRemoveImage={onRemoveImage} />}
		</div>
	);
};

function mapStateToProps(state: any) {
	return {
		imageApiURL: state.project.currentProject.imageApiUrl || REACT_APP_URLS.REACT_APP_IMAGE_API_BASE_URL,
		projectDomain: state.project.currentProject.domain,
	};
}

export default connect(mapStateToProps)(ImageDropzone);
