import 'reflect-metadata';
import { jsonMember, jsonObject } from 'typedjson';

@jsonObject()
export default class EventsMeta {
	@jsonMember(Number)
	public total: number | null = null;
	@jsonMember(Number)
	public limit: number | null = null;
}
