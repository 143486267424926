import ModelMapper from '../../models/ModelMapper';
import { toast } from 'react-toastify';
import i18n from '../../i18n';
import {
	BANNER_ENTITY_CREATE,
	BANNER_ENTITY_CREATE_FAILED,
	BANNER_ENTITY_CREATE_SUCCESS,
	BANNER_ENTITY_DELETE_SUCCESS,
	BANNER_ENTITY_RECEIVED,
	BANNER_ENTITY_UPDATE,
	BANNER_ENTITY_UPDATE_FAILED,
	BANNER_ENTITY_UPDATE_SUCCESS,
	BANNER_LISTING_RECEIVED,
} from '../action-creators/BannerActionCreators';
import Banner from '../../models/banner/Banner';
import { actionService } from '../../App';
import { SEARCH_IS_APPLIED } from '../action-creators/UiActionCreator';

/**
 * Remap data coming from the API to the model defined in the application
 */
export function bannerMiddleware() {
	return function (next: any) {
		return function (action: any) {
			if (action) {
				if (action.type === BANNER_LISTING_RECEIVED) {
					const paginationData = action.payload.data.meta.pagination;
					action.payload = ModelMapper.remapBannersFromResponse(action.payload.data.data);
					action.payload.contentPagination = ModelMapper.remapMetaPagination(paginationData);
				}

				if (action.type === BANNER_ENTITY_RECEIVED) {
					action.payload.banner = ModelMapper.remapBannerFromResponse(action.payload.data.data);
				}

				if (action.type === BANNER_ENTITY_CREATE) {
					actionService.emitGeneralAction(BANNER_ENTITY_CREATE);
					action.payload.banner = Banner.builder(action.payload.banner).toRequestJson();
				}

				if (action.type === BANNER_ENTITY_CREATE_FAILED) {
					actionService.emitGeneralAction(BANNER_ENTITY_CREATE_FAILED);
					toast.error(i18n.t('banner_create_failed'));
				}

				if (action.type === BANNER_ENTITY_CREATE_SUCCESS) {
					actionService.emitGeneralAction(BANNER_ENTITY_CREATE_SUCCESS);
					//TODO Replace window.dispatchEvent with actionService
					window.dispatchEvent(new CustomEvent(BANNER_ENTITY_CREATE_SUCCESS, { detail: action.payload }));
					toast.success(i18n.t('banner_create_success'));
				}

				if (action.type === BANNER_ENTITY_DELETE_SUCCESS) {
					toast.success(i18n.t('banner_deleted_success'));
					//TODO Replace window.dispatchEvent with actionService
					window.dispatchEvent(new Event(action.type));
				}

				if (action.type === BANNER_ENTITY_UPDATE) {
					actionService.emitGeneralAction(BANNER_ENTITY_UPDATE);
					action.payload.banner = Banner.builder(action.payload.banner).toRequestJson();
				}

				if (action.type === BANNER_ENTITY_UPDATE_FAILED) {
					actionService.emitGeneralAction(BANNER_ENTITY_UPDATE_FAILED);
					toast.error(i18n.t('banner_update_failed'));
				}

				if (action.type === BANNER_ENTITY_UPDATE_SUCCESS) {
					actionService.emitGeneralAction(BANNER_ENTITY_UPDATE_SUCCESS);
					toast.success(i18n.t('banner_update_success'));
				}

				if (action.type === SEARCH_IS_APPLIED) {
					actionService.emitGeneralAction(SEARCH_IS_APPLIED);
				}
			}

			next(action);
		};
	};
}
