import { TranslationsJson } from './translations-json';
import TranslationsModel from './translations-model';
import { TranslationsRequestJson } from './translations-request-json';

export default class TranslationsBuilder {
	private json: TranslationsJson;

	constructor(translations?: TranslationsModel) {
		this.json = translations ? translations.toJSON() : ({} as TranslationsJson);
	}

	withId(id: string): TranslationsBuilder {
		this.json.id = id;

		return this;
	}

	withLanguage(language: string): TranslationsBuilder {
		this.json.language = language;

		return this;
	}

	toRequestJson(): TranslationsRequestJson {
		let json = {} as TranslationsRequestJson;
		json.id = this.json.id;
		json.language = this.json.language;

		return json;
	}

	build(): TranslationsModel {
		return TranslationsModel.fromJSON(this.json);
	}
}
