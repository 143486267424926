import { Properties, State } from './properties/BlockableButtonProps';
import React from 'react';
import { Subscription } from 'rxjs';
import { blockButton } from './helpers/BlockableButtonHelper';
import { ServiceContext } from '../../../../services/service-provider/ServiceProvider';

export default class BlockableButtonWrapper extends React.Component<Properties, State> {
	static contextType = ServiceContext;
	context!: React.ContextType<typeof ServiceContext>;

	actionServiceSubscription = {} as Subscription;
	constructor(props: Properties) {
		super(props);
		this.state = {
			block: false,
		};
	}

	componentDidMount(): void {
		const { blockOnActions, releaseOnActions } = this.props;
		const context: React.ContextType<typeof ServiceContext> = this.context;

		this.actionServiceSubscription = context.actionService.onGeneralAction().subscribe((action: string) => {
			const disableButton = blockButton(action, blockOnActions, releaseOnActions);
			this.toggleBlock(disableButton);
		});
	}

	updateButtonElement(blocked: boolean) {
		const { children, blockOverride } = this.props;

		if (blockOverride !== undefined && blockOverride) {
			return React.cloneElement(children as React.ReactElement<any>, { disabled: blockOverride });
		}

		if (children) {
			return React.cloneElement(children as React.ReactElement<any>, { disabled: blocked });
		}

		return null;
	}

	toggleBlock(block: boolean) {
		this.setState({
			block,
		});
	}

	componentWillUnmount(): void {
		this.actionServiceSubscription.unsubscribe();
	}

	render() {
		return this.updateButtonElement(this.state.block);
	}
}
