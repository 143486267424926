import { TypedJSON } from 'typedjson';
import BasketballSeasonModel from '../../../../../../models/v2/basketball-season/entity/basketball-season.model';

export const responseToBasketballSeasonModel = (response: any) => {
	const serializer = new TypedJSON(BasketballSeasonModel);
	return serializer.parse(response) as BasketballSeasonModel;
};

export const seasonsToOptions = (seasons: BasketballSeasonModel[]) => {
	if (seasons && seasons.length > 0) {
		return seasons.map((season) => {
			return seasonToOption(season);
		});
	}

	return [];
};

export const seasonToOption = (season: BasketballSeasonModel) => {
	const tournamentName = season && season.competition && season.competition.name ? `${season.competition.name}-` : '';
	if (season) {
		return {
			value: season.id,
			label: tournamentName + season.name,
			data: season,
			type: 'season',
		};
	} else {
		return {};
	}
};
