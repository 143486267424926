import 'reflect-metadata';
import { jsonMember, jsonObject } from 'typedjson';
import SeoModel from '../seo/seo.model';

@jsonObject()
export default class CategoryModel {
	@jsonMember(String)
	public id: string = '';
	@jsonMember(String)
	public title: string = '';
	@jsonMember(String)
	public description: string = '';
	@jsonMember(Boolean)
	public active: boolean = false;
	@jsonMember(SeoModel)
	public seo: SeoModel | null = null;
}
