import { toast } from 'react-toastify';
import { featuresService } from '../../../../App';
import { checkObjectPropertiesExists, getStartOfTextByCharsCount, isCharRestrictionOkay } from '../../../../global-helpers/global.helpers';
import { availableContentTypes, entitiesType } from '../../../../services/content-models-service/ContentModelService';
import { FeatureTypes } from '../../../../services/feature-service/features.enum';
import { fieldsCharsLength, oneSignalEndpoint, ONESIGNAL_DELIVERY_TYPES } from './OneSignal.constants';
import OneSignalModel from './OneSignal.model';

export const recipientsToOptions = (recipients: string[]) => {
	return [...recipients].map((recipient: string) => {
		return {
			label: recipient,
			value: recipient,
		};
	});
};

export const findDefaultRecipient = (recipients: string[], oneSignalState: OneSignalModel) => {
	// will set all recipients based on the oneSignalState
	return recipientsToOptions(recipients).filter((option) => oneSignalState.recipients.includes(option.value));
};

export const areOneSignalFieldsValid = (oneSignalState: OneSignalModel): boolean => {
	const urlValidFlag = isCharRestrictionOkay(oneSignalState.url, fieldsCharsLength.url.minimum, fieldsCharsLength.url.maximum);
	const titleValidFlag = isCharRestrictionOkay(oneSignalState.title, fieldsCharsLength.title.minimum, fieldsCharsLength.title.maximum);
	const descriptionValidFlag = isCharRestrictionOkay(
		oneSignalState.description,
		fieldsCharsLength.description.minimum,
		fieldsCharsLength.description.maximum,
	);
	const recipientsValid = oneSignalState.recipients.length > 0;
	const fieldsValid = urlValidFlag && titleValidFlag && descriptionValidFlag && recipientsValid;

	if (oneSignalState.delivery === ONESIGNAL_DELIVERY_TYPES.DELAYED) {
		return fieldsValid && !!oneSignalState.sendDate;
	}

	return fieldsValid;
};

export const deliveryTypesToOptions = (deliveryOptions: string[], t: any) => {
	return [...deliveryOptions].map((deliveryOpt: string) => {
		switch (deliveryOpt) {
			case ONESIGNAL_DELIVERY_TYPES.IMMEDIATELY:
				return {
					label: t('immediately'),
					value: deliveryOpt,
				};
			case ONESIGNAL_DELIVERY_TYPES.DELAYED:
				return {
					label: t('delayed'),
					value: deliveryOpt,
				};
			case ONESIGNAL_DELIVERY_TYPES.INTELLIGENT:
				return {
					label: t('intelligent'),
					value: deliveryOpt,
				};
			default:
				return {
					label: '',
					value: '',
				};
		}
	});
};

export const findDefaultDeliveryType = (deliveryOptions: string[], t: any, oneSignalState: OneSignalModel) => {
	const deliveryStateToLowerCase = oneSignalState && oneSignalState.delivery && oneSignalState.delivery.toLocaleLowerCase();
	return deliveryTypesToOptions(deliveryOptions, t).filter((option) => option.value === deliveryStateToLowerCase);
};

const textToNotifyValue = (text: string = '', lang: string = 'en') => {
	return { [lang]: text };
};

export const notifyUsers = (oneSignalState: OneSignalModel, successMessage: string): Promise<any> => {
	const { appId, apiKey } = featuresService.getFeatureConfig(FeatureTypes.ONESIGNAL_INTEGRATION).authorization;
	if (appId && apiKey) {
		const apiObjectSend = {
			app_id: appId,
			url: oneSignalState.url,
			headings: textToNotifyValue(oneSignalState.title),
			contents: textToNotifyValue(oneSignalState.description),
			included_segments: oneSignalState.recipients,
			firefox_icon: oneSignalState.imageURL,
			chrome_web_icon: oneSignalState.imageURL,
		};

		if (oneSignalState.delivery === ONESIGNAL_DELIVERY_TYPES.INTELLIGENT) {
			apiObjectSend['delayed_option'] = 'last-active';
		} else if (oneSignalState.delivery === ONESIGNAL_DELIVERY_TYPES.DELAYED && oneSignalState.sendDate) {
			apiObjectSend['send_after'] = oneSignalState.sendDate;
		}

		return fetch(oneSignalEndpoint, {
			method: 'POST',
			headers: {
				Authorization: `Bearer ${apiKey}`,
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(apiObjectSend),
		}).then((response) => {
			response.status === 200 && toast.success(successMessage);
			return response.json();
		});
	}

	return Promise.reject('No app-id or api-key found.');
};

export const populateOneSignalModalFields = (currentOneSignalState: OneSignalModel, content: entitiesType): OneSignalModel => {
	const newModalContent = Object.assign({}, currentOneSignalState) as OneSignalModel;

	if (content.title) {
		newModalContent.title = content.title;
	}

	if (content.subtitle) {
		newModalContent.description = content.subtitle;
	}

	const imageURL = checkObjectPropertiesExists(content, 'image', 'data', 'urls', 'uploaded', 'original')
		? content.image.data.urls.uploaded.original
		: '';
	if (imageURL) {
		newModalContent.imageURL = imageURL;
	}

	return newModalContent;
};

type recipientsFromFeatureConfig = { default: string | null; recipients: [] };
export const getRecipientsFromFeatureConfig = (): recipientsFromFeatureConfig => {
	const result = {
		default: null,
		recipients: [],
	};

	const recipientsFromFeatureConfig = featuresService.getFeatureConfig(FeatureTypes.ONESIGNAL_INTEGRATION).segments || null;
	if (recipientsFromFeatureConfig) {
		if (recipientsFromFeatureConfig.default) {
			result.default = recipientsFromFeatureConfig.default || '';
		}

		if (recipientsFromFeatureConfig.all) {
			result.recipients = recipientsFromFeatureConfig.all;
		}
	}

	return result as recipientsFromFeatureConfig;
};

const getDefaultDeliveryFromFeatureConfig = (): string => {
	const deliveryFromFeatureConfig = featuresService.getFeatureConfig(FeatureTypes.ONESIGNAL_INTEGRATION).delivery || null;
	if (deliveryFromFeatureConfig && deliveryFromFeatureConfig.default) {
		return deliveryFromFeatureConfig.default.toLocaleLowerCase();
	}
	return '';
};

export const populateOneSignalDefaultModalFieldsFromFeature = (defaultRecipients: string | null): OneSignalModel => {
	const modalContent = new OneSignalModel();
	modalContent.delivery = getDefaultDeliveryFromFeatureConfig();
	modalContent.recipients = defaultRecipients ? [defaultRecipients] : [];
	return modalContent;
};

export const getContentNotificationUrl = async (contentData: entitiesType, entity_type: availableContentTypes, t: any): Promise<any> => {
	let { url, headers } = featuresService.getFeatureConfig(FeatureTypes.ONESIGNAL_INTEGRATION).populate_url || null;
	const bodyData = {
		id: contentData.id,
		entity_type,
	};

	if (url) {
		try {
			const request = await fetch(url, {
				method: 'POST',
				headers,
				body: JSON.stringify(bodyData),
			});

			if (request && request.status >= 200 && request.status < 300) {
				const data = await request.json();
				const notificationUrl = await data.url;
				return Promise.resolve(notificationUrl);
			}
		} catch (error) {
			return Promise.reject(t('get_notification_url_error'));
		}
	} else {
		return Promise.reject(null);
	}
};
