import classNames from 'classnames';
import React, { FC, useState } from 'react';

import '../../../style/table-config.scss';

const TableConfig: FC<{ onSelect: (row: number, col: number) => void }> = ({ onSelect }) => {
	const [selected, setSelected] = useState<{ row: number; col: number }>({ row: 0, col: 0 });

	const arr: boolean[][] = Array(10).fill(Array(10).fill(true));
	const els: JSX.Element[] = [];

	arr.forEach((subArr, i) => {
		subArr.forEach((value, j) => {
			const row = i + 1;
			const col = j + 1;

			els.push(
				<div
					key={`${row}x${col}`}
					className={classNames('table-config-square', {
						highlighted: row <= selected.row && col <= selected.col,
						selected: row === selected.row && col === selected.col,
					})}
					data-row={row}
					data-col={col}
					onMouseOver={() => setSelected({ row, col })}
					onClick={() => onSelect(row, col)}
				></div>,
			);
		});
	});

	return (
		<>
			<div className='table-config-container'>{els}</div>
			<span>
				{selected.row}x{selected.col}
			</span>
		</>
	);
};

export default TableConfig;
