import { BasketballStandingsJson } from './basketball-standings.json';
import BasketballStandingsModel from './basketball-standings.model';
import CompetitionModel from '../../../../../../../models/v2/competition/entity/competition.model';
import BasketballSeasonModel from '../../../../../../../models/v2/basketball-season/entity/basketball-season.model';
import BasketballStageModel from '../../../../../../../models/v2/Stage/Entity/basketball-stage.model';
import { RefreshTimeValuesType } from '../../../../v2/partials/refresh-time-input/helper';

export default class BasketballStandingsBuilder {
	readonly json: BasketballStandingsJson;

	constructor(standings?: BasketballStandingsModel | BasketballStandingsJson) {
		if (standings && standings instanceof BasketballStandingsModel) {
			this.json = standings.toJson();
		} else if (standings) {
			this.json = standings;
		} else {
			this.json = {} as BasketballStandingsJson;
		}
	}

	withTournament(competition: CompetitionModel): BasketballStandingsBuilder {
		this.json.competition = competition;

		return this;
	}

	withSeason(season: BasketballSeasonModel): BasketballStandingsBuilder {
		this.json.season = season;

		return this;
	}

	withStage(stage: BasketballStageModel): BasketballStandingsBuilder {
		this.json.stage = stage;

		return this;
	}

	withHeaderDefaultOption(headerDefaultOption: string | null): BasketballStandingsBuilder {
		this.json.headerDefaultOption = headerDefaultOption;

		return this;
	}

	withRefreshTime(refreshTime: RefreshTimeValuesType): BasketballStandingsBuilder {
		this.json.refreshTime = refreshTime;

		return this;
	}

	build(): BasketballStandingsModel {
		return BasketballStandingsModel.fromJson(this.json);
	}
}
