import striptags from 'striptags';
export const pastedText = (e: any) => {
	return e.clipboardData.getData('text/html').length > 0 ? e.clipboardData.getData('text/html') : e.clipboardData.getData('Text');
};
export const extractText = (text: any): string[] => {
	if (detectBrowser() === 'Firefox' || detectBrowser() === 'Chrome') {
		text = parseTextToParagraphs(text);
	}
	return [...text.trim().matchAll('<\\s*(p|h1|h2|h3|h4|h5|h6)[^>]*>((.|\\s)*?)<\\s*/\\s*(p|h1|h2|h3|h4|h5|h6)>')].map((item) => item[0]);
};
export const defineParagraphs = (extractedText: string[], text: string): string[] => {
	return extractedText.length === 0
		? [text].map((text: any) => `<p>${striptags(text)}</p>`)
		: extractedText.map((text: any) => `<p>${striptags(text)}</p>`);
};

const parseTextToParagraphs = (text: string) => {
	let textArray = text
		.split(/[\n]/g)
		.filter((item) => item.replace(/(<([^>]+)>)/gi, '').trim().length !== 0)
		.map((item: string) => {
			return `<p>${item}</p>`;
		});

	return textArray.join('');
};

const detectBrowser = () => {
	if ((navigator.userAgent.indexOf('Opera') || navigator.userAgent.indexOf('OPR')) != -1) {
		return 'Opera';
	} else if (navigator.userAgent.indexOf('Chrome') != -1) {
		return 'Chrome';
	} else if (navigator.userAgent.indexOf('Safari') != -1) {
		return 'Safari';
	} else if (navigator.userAgent.indexOf('Firefox') != -1) {
		return 'Firefox';
		//@ts-ignore
	} else if (navigator.userAgent.indexOf('MSIE') != -1 || !!document.documentMode == true) {
		return 'IE';
	} else {
		return 'Unknown';
	}
};
