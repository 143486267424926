import { UserJson } from './user.json';
import UserBuilder from './user.builder';

export default class UserModel {
	readonly id: string;
	readonly name: string;
	readonly active: boolean;

	private constructor(id: string, name: string, active: boolean) {
		this.id = id;
		this.name = name;
		this.active = active;
	}

	toJSON(): UserJson {
		return {
			id: this.id,
			name: this.name,
			active: this.active,
		};
	}

	static fromJSON(json: UserJson): UserModel {
		return new UserModel(json.id, json.name, json.active);
	}

	static builder(user?: UserModel): UserBuilder {
		return new UserBuilder(user);
	}
}
