import { Observable, Subject } from 'rxjs';
import List from '../../models/list/List';
import SentryService from '../sentry/sentry';

export default class EntityActionService {
	private imagesUpdate: Subject<any> = {} as Subject<any>;
	private generalEntityAction: Subject<any> = {} as Subject<any>;
	private listEntityReceived: Subject<any> = {} as Subject<any>;
	private projectResources: Subject<any> = {} as Subject<any>;
	private actionListener: Subject<any> = {} as Subject<any>;
	private errorSubj: Subject<any> = {} as Subject<any>;
	private uiActionsSubj: Subject<any> = {} as Subject<any>;

	constructor() {
		this.imagesUpdate = new Subject<any>();
		this.generalEntityAction = new Subject<any>();
		this.projectResources = new Subject<any>();
		this.listEntityReceived = new Subject<List>();
		this.actionListener = new Subject<List>();
		this.errorSubj = new Subject<any>();
		this.uiActionsSubj = new Subject<any>();
	}

	init() {
		window.entityUpdateService = this;
	}

	emitUiAction(data: any): any {
		this.uiActionsSubj.next(data);
	}

	onUIAction(): Observable<any> {
		return this.uiActionsSubj.asObservable();
	}

	emitImagesUpdated(): void {
		this.imagesUpdate.next();
	}

	onImagesUpdated(): Observable<any> {
		return this.imagesUpdate.asObservable();
	}

	emitGeneralAction(action: string) {
		this.generalEntityAction.next(action);
	}

	onGeneralAction() {
		return this.generalEntityAction.asObservable();
	}

	emitListEntityReceived(list: List) {
		this.listEntityReceived.next(list);
	}

	onListEntityReceived(): Observable<List> {
		return this.listEntityReceived.asObservable();
	}

	emitProjectResourcesAction(action: any) {
		return this.projectResources.next(action);
	}

	onProjectResourcesLoadAction() {
		return this.projectResources.asObservable();
	}

	emitAction(action: string) {
		this.actionListener.next(action);
	}

	emitError(error: any) {
		SentryService.captureException(error);
		this.errorSubj.next(error);
	}

	onError(): Observable<any> {
		return this.errorSubj.asObservable();
	}

	onActionReceived() {
		return this.actionListener.asObservable();
	}
}
