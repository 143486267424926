import React, { FunctionComponent, ChangeEvent } from 'react';
import { FormGroup, Input, Label } from 'reactstrap';

type Properties = {
	name: string;
	slug: string;
	value: boolean;
	onPropertyChange: (event: ChangeEvent<HTMLInputElement>) => void;
};

const ContentProperty: FunctionComponent<Properties> = ({ name, slug, value, onPropertyChange }) => {
	return (
		<FormGroup key={`content-property-key-${slug}`} check className='radio'>
			<Input className='form-check-input' type='checkbox' name='radios' value={slug} onChange={onPropertyChange} checked={value} />
			<Label check className='form-check-label' for={slug}>
				{name}
			</Label>
		</FormGroup>
	);
};

export default ContentProperty;
