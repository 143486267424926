import React, { FunctionComponent } from 'react';
import './buttons.scss';

export type ButtonProps = {
	text: string;
	title?: string;
	maxWidth?: string;
	onClickAction?: (event: any) => void;
	disabled?: boolean;
};

export const emptyFunction = () => undefined;

export const RoundedButton: FunctionComponent<ButtonProps> = ({ text, title, maxWidth, onClickAction }) => {
	return (
		<div
			className='designed-button rounded gray-border'
			title={title || 'Button'}
			style={{ maxWidth: maxWidth || 'auto' }}
			onClick={onClickAction || emptyFunction}
		>
			{text}
		</div>
	);
};

export default RoundedButton;
