import React, { FunctionComponent, useEffect, useState } from 'react';
import WikiListModel from '../../../../models/Wikipages/List/WikiListModel';
import Project from '../../../../models/project/Project';
import Profile from '../../../../models/profile/Profile';
import TableListing from '../../../Partials/BaseComponents/TableListing/TableListing';
import { connect } from 'react-redux';
import { ContentTypes } from '../../../../constants/content-types';
import ContentTitleContainer from '../../../Partials/BaseComponents/ListingComponent/subcomponents/ContentTitle/ContentTitleContainer';
import PaginationMeta from '../../../../models/pagination/PaginationMeta';
import { Subscription } from 'rxjs';
import { actionService } from '../../../../App';
import { LANGUAGE_CODE_UPDATE_SUCCESS } from '../../../../store/action-creators/ProjectResourcesActionCreators';
import { remmapContentFilters } from '../../../Resources/Articles/Helpers/ArticleHelper';
import WikiPagesAdvancedFilterModel from '../../../Partials/Shared/wiki-pages-advanced-content-filter/models/wiki-pages-advanced-filter.model';
import { extractWikiPageSearchQueryFromFilters } from '../../../Partials/Shared/wiki-pages-advanced-content-filter/helpers/wiki-pages-advanced-content-filter.helper';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { returnObjectForGalleryPaginationUpdate } from '../../../../store/action-creators/GalleryActionCreator';
import { returnObjectForVideoPaginationUpdate } from '../../../../store/action-creators/VideoActionCreator';
import { returnObjectForImagesPaginationUpdate } from '../../../../store/action-creators/ImageActionCreator';
import { returnObjectForArticlePaginationUpdate } from '../../../../store/action-creators/ArticleActionCreator';
import {
	WIKI_PAGES_ENTITY_DELETE_SUCCESS,
	returnObjectForWikiFiltersUpdate,
	returnObjectForWikiListingRequest,
	returnObjectForWikiSearch,
} from '../../../../store/action-creators/WikiActionCreator';
import { returnObjectForContentFiltersUpdate } from '../../../../store/action-creators/content-resources';
import AdvancedFilterModel from '../../../Partials/Shared/advanced-content-filter/models/advanced-content-filter.model';

type WikiListing = {
	t: any;
	project: Project;
	profile: Profile;
	getWikiPages: any;
	pagination: any;
	wikiPages: WikiListModel;
	updateArticlesPagination: (pagination: PaginationMeta) => any;
	updateVideosPagination: (pagination: PaginationMeta) => any;
	updateGalleriesPagination: (pagination: PaginationMeta) => any;
	updateImagesPagination: (pagination: PaginationMeta) => any;
	updateContentFilters: (filters: any) => any;
	wikiPagesFilters: WikiPagesAdvancedFilterModel;
	searchWikiPages: any;
	wikiPagesFiltersUpdate: (filters: any) => any;
	analytics: any;
};

const WikiListing: FunctionComponent<WikiListing> = ({
	t,
	project,
	pagination,
	profile,
	wikiPagesFilters,
	getWikiPages,
	updateVideosPagination,
	updateGalleriesPagination,
	searchWikiPages,
	wikiPages,
	updateImagesPagination,
	updateArticlesPagination,
	updateContentFilters,
	wikiPagesFiltersUpdate,
	analytics,
}) => {
	let actionServiceSubject = new Subscription();
	const [selectedPage, setSelectedPage] = useState<number>(pagination.currentPage || 1);
	const permissionForRead = profile.permissions.findIndex((el) => el.name === 'read_wikipages') >= 0;

	const getWikiPagesListener = () => applyFilters(wikiPagesFilters);

	const registerActionEventListener = () => {
		actionServiceSubject = actionService.onActionReceived().subscribe((action: string) => {
			if (action === LANGUAGE_CODE_UPDATE_SUCCESS) {
				setSelectedPage(1);
				getWikiPagesListener();
			}

			if (action === WIKI_PAGES_ENTITY_DELETE_SUCCESS) {
				getWikiPagesListener();
			}
		});
	};

	useEffect(() => {
		registerActionEventListener();
		applyFilters(wikiPagesFilters);
		updateArticlesPagination(PaginationMeta.builder().withCurrentPage(1).build());
		updateVideosPagination(PaginationMeta.builder().withCurrentPage(1).build());
		updateGalleriesPagination(PaginationMeta.builder().withCurrentPage(1).build());
		updateImagesPagination(PaginationMeta.builder().withCurrentPage(1).build());
		const filters = remmapContentFilters({}, {}, {}, {}, {}, {}, wikiPagesFilters, {}, {}, {} as AdvancedFilterModel);
		updateContentFilters(filters);
		initPageTitle();

		return () => {
			actionServiceSubject.unsubscribe();
		};
	}, [pagination.currentPage]);

	const initPageTitle = () => {
		document.title = t('wiki_pages');
	};

	const handlePageClick = (page: any) => {
		setSelectedPage(page);
	};

	const applyFilters = (filters: WikiPagesAdvancedFilterModel) => {
		const query = extractWikiPageSearchQueryFromFilters(filters);
		query.length > 0 && query !== '*' ? getWikiPages(pagination.currentPage, project, query) : getWikiPages(pagination.currentPage, project);
	};

	return (
		<div className='animated fadeIn' data-qa={`page-content-wrapper-${ContentTypes.WIKI_PAGE}`}>
			<div className='card'>
				<ContentTitleContainer contentType={ContentTypes.WIKI_PAGE} t={t} />
				<div className='card-body'>
					{permissionForRead && (
						<TableListing
							t={t}
							profile={profile}
							contentPagination={pagination}
							searchContent={searchWikiPages}
							content={wikiPages}
							handlePageClick={handlePageClick}
							contentType={ContentTypes.WIKI_PAGE}
							filters={wikiPagesFilters}
							getContent={getWikiPages}
							updateContentFilters={wikiPagesFiltersUpdate}
							project={project}
							analytics={analytics}
							setCurrentPage={setSelectedPage}
							currentPage={selectedPage}
						/>
					)}
				</div>
			</div>
		</div>
	);
};

function mapStateToProps(state: any) {
	return {
		wikiPages: state.wikiPages.wikiPages,
		project: state.project.currentProject,
		profile: state.profile.profile,
		wikiPagesFilters: state.wikiPages.filters,
		pagination: state.wikiPages.pagination,
	};
}

function mapDispatchToProps(dispatch: any) {
	return {
		getWikiPages: (page: string, project: Project, text: string) => dispatch(returnObjectForWikiListingRequest(page, project, text)),
		searchWikiPages: (text: string, project: Project) => dispatch(returnObjectForWikiSearch(text, project)),
		updateArticlesPagination: (pagination: PaginationMeta) => dispatch(returnObjectForArticlePaginationUpdate(pagination)),
		updateGalleriesPagination: (pagination: PaginationMeta) => dispatch(returnObjectForGalleryPaginationUpdate(pagination)),
		updateVideosPagination: (pagination: PaginationMeta) => dispatch(returnObjectForVideoPaginationUpdate(pagination)),
		updateImagesPagination: (pagination: PaginationMeta) => dispatch(returnObjectForImagesPaginationUpdate(pagination)),
		updateContentFilters: (filters: any) => dispatch(returnObjectForContentFiltersUpdate(filters)),
		wikiPagesFiltersUpdate: (filters: any) => dispatch(returnObjectForWikiFiltersUpdate(filters)),
	};
}

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(WikiListing);
