import { USER_LOGOUT } from '../action-creators/ProfileActionCreators';
import ContentAttributes from '../../models/content-attributes/content-attributes-model';
import {
	REQUEST_ARTICLE_ORIGINS_RECEIVED,
	REQUEST_VIDEO_ORIGINS_RECEIVED,
	REQUEST_GALLERY_ORIGINS_RECEIVED,
	REQUEST_IMAGE_ORIGINS_RECEIVED,
} from '../action-creators/origins-action.creator';

interface InitialState {
	articleOrigins: ContentAttributes[];
	videoOrigins: ContentAttributes[];
	imageOrigins: ContentAttributes[];
	galleryOrigins: ContentAttributes[];
}

const initialState: InitialState = {
	articleOrigins: [] as ContentAttributes[],
	videoOrigins: [] as ContentAttributes[],
	imageOrigins: [] as ContentAttributes[],
	galleryOrigins: [] as ContentAttributes[],
};

function originsReducer(state: any = initialState, action: any) {
	if (action.type === REQUEST_ARTICLE_ORIGINS_RECEIVED) {
		return Object.assign({}, state, {
			articleOrigins: action.payload.slice(),
		});
	}

	if (action.type === REQUEST_VIDEO_ORIGINS_RECEIVED) {
		return Object.assign({}, state, {
			videoOrigins: action.payload.slice(),
		});
	}

	if (action.type === REQUEST_GALLERY_ORIGINS_RECEIVED) {
		return Object.assign({}, state, {
			galleryOrigins: action.payload.slice(),
		});
	}

	if (action.type === REQUEST_IMAGE_ORIGINS_RECEIVED) {
		return Object.assign({}, state, {
			imageOrigins: action.payload.slice(),
		});
	}

	if (action.type === USER_LOGOUT) {
		state = initialState;
	}

	return state;
}

export default originsReducer;
