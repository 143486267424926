import {
	POLL_LISTING_RECEIVED,
	POLL_ENTITY_CREATE,
	POLL_ENTITY_CREATE_FAILED,
	POLL_ENTITY_CREATE_SUCCESS,
	POLL_ENTITY_RECEIVED,
	POLL_ENTITY_UPDATE,
	POLL_ENTITY_UPDATE_SUCCESS,
	POLL_ENTITY_UPDATE_FAILED,
	POLL_ENTITY_DELETE_SUCCESS,
	POLL_ENTITY_DELETE_FAILED,
	POLL_ENTITY_OPTION_UPDATE_SUCCESS,
	POLL_ENTITY_OPTION_DELETE_SUCCESS,
	POLL_ENTITY_OPTION_UPDATE_FAILED,
	POLL_ENTITY_OPTION_DELETE_FAILED,
	POLL_ENTITY_OPTION_CREATE_FAILED,
	POLL_ENTITY_OPTION_CREATE_SUCCESS,
} from '../action-creators/polls-action-creator';
import ModelMapper from '../../models/ModelMapper';
import { actionService } from '../../App';
import Poll from '../../models/polls/poll';
import i18n from '../../i18n';
import { toast } from 'react-toastify';

export function pollMiddleware() {
	return function (next: any) {
		return function (action: any) {
			if (action) {
				if (action.type === POLL_LISTING_RECEIVED) {
					action.payload.polls = ModelMapper.remapPollsFromResponse(action.payload.data.data);
					action.payload.contentPagination = ModelMapper.remapMetaPagination(action.payload.data.meta.pagination);
				}

				if (action.type === POLL_ENTITY_RECEIVED) {
					action.payload.poll = ModelMapper.remapPollFromResponse(action.payload.data.data);
				}

				if (action.type === POLL_ENTITY_CREATE) {
					actionService.emitGeneralAction(POLL_ENTITY_CREATE);
					action.payload.poll = Poll.builder(action.payload.poll).toRequestJson();
				}

				if (action.type === POLL_ENTITY_CREATE_FAILED) {
					actionService.emitGeneralAction(POLL_ENTITY_CREATE_FAILED);
					toast.error(i18n.t('poll_create_failed'));
				}

				if (action.type === POLL_ENTITY_CREATE_SUCCESS) {
					actionService.emitGeneralAction(POLL_ENTITY_CREATE_SUCCESS);
					window.dispatchEvent(new CustomEvent(POLL_ENTITY_CREATE_SUCCESS, { detail: action.payload }));
					toast.success(i18n.t('poll_create_success'));
				}

				if (action.type === POLL_ENTITY_UPDATE) {
					actionService.emitGeneralAction(POLL_ENTITY_UPDATE);
					action.payload.poll = Poll.builder(action.payload.poll).toRequestJson();
				}

				if (action.type === POLL_ENTITY_UPDATE_SUCCESS) {
					actionService.emitGeneralAction(POLL_ENTITY_UPDATE_SUCCESS);
					toast.success(i18n.t('poll_update_success'));
				}

				if (action.type === POLL_ENTITY_UPDATE_FAILED) {
					actionService.emitGeneralAction(POLL_ENTITY_UPDATE_FAILED);
					toast.error(i18n.t('poll_update_failed'));
				}

				if (action.type === POLL_ENTITY_DELETE_SUCCESS) {
					toast.success(i18n.t('poll_deleted_success'));

					window.dispatchEvent(new Event(action.type));
				}

				if (action.type === POLL_ENTITY_DELETE_FAILED) {
					toast.error(i18n.t('poll_deleted_failed'));
				}

				if (action.type === POLL_ENTITY_OPTION_UPDATE_SUCCESS) {
					actionService.emitGeneralAction(POLL_ENTITY_OPTION_UPDATE_SUCCESS);
				}

				if (action.type === POLL_ENTITY_OPTION_UPDATE_FAILED) {
					actionService.emitGeneralAction(POLL_ENTITY_OPTION_UPDATE_FAILED);
					toast.error(i18n.t('poll_option_update_failed'));
				}

				if (action.type === POLL_ENTITY_OPTION_DELETE_SUCCESS) {
					actionService.emitGeneralAction(POLL_ENTITY_OPTION_DELETE_SUCCESS);
				}

				if (action.type === POLL_ENTITY_OPTION_DELETE_FAILED) {
					actionService.emitGeneralAction(POLL_ENTITY_OPTION_DELETE_FAILED);
					toast.error(i18n.t('poll_option_delete_failed'));
				}

				if (action.type === POLL_ENTITY_OPTION_CREATE_SUCCESS) {
					actionService.emitGeneralAction(POLL_ENTITY_OPTION_CREATE_SUCCESS);
				}

				if (action.type === POLL_ENTITY_OPTION_CREATE_FAILED) {
					actionService.emitGeneralAction(POLL_ENTITY_OPTION_CREATE_FAILED);
					toast.error(i18n.t('poll_option_create_failed'));
				}
			}
			next(action);
		};
	};
}
