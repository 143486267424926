import { POLL_LISTING_RECEIVED, POLL_ENTITY_RECEIVED } from '../action-creators/polls-action-creator';
import Poll from '../../models/polls/poll';
import PaginationMeta from '../../models/pagination/PaginationMeta';
import { USER_LOGOUT } from '../action-creators/ProfileActionCreators';

interface InitialState {
	poll: Poll;
	polls: Poll[];
	pagination: PaginationMeta;
}

const initialState: InitialState = {
	poll: Poll.builder().build(),
	polls: [],
	pagination: PaginationMeta.builder().build(),
};

function pollReducer(state: any = initialState, action: any) {
	if (action.type === POLL_LISTING_RECEIVED) {
		return Object.assign({}, state, {
			polls: action.payload.polls,
			pagination: action.payload.contentPagination,
		});
	}

	if (action.type === POLL_ENTITY_RECEIVED) {
		return Object.assign({}, state, {
			poll: action.payload.poll,
		});
	}

	if (action.type === USER_LOGOUT) {
		state = initialState;
	}

	return state;
}

export default pollReducer;
