import * as React from 'react';
import { FunctionComponent, useState, useEffect } from 'react';
import 'cropperjs/dist/cropper.css';
import HttpService from '../../../../../services/rest/HttpService';
import { addAssetManuallyCropDimensions } from '../../../../Resources/manual-data-entries/subcomponents/assets-media/helpers/assets-image-upload.helper';
import { getCropModelFromCropperInfo } from '../../../../Partials/Shared/image-crop/helpers/image-crop-container.helper';
import { toast } from 'react-toastify';
import { CropperModal } from './CropperModal';

type Props = {
	src: string;
	t: any;
	tournamentId: number;
	setDisplayedImageUrl: any;
};

export const ImageCrop: FunctionComponent<Props> = ({ src, t, tournamentId, setDisplayedImageUrl }) => {
	const cropButtonDisabled = src === undefined || src === null || src.length < 1;
	const [openImageCropModal, setOpenImageCropModal] = useState(false);
	const [defaultImage, setDefaultImage] = useState('');

	useEffect(() => {
		if (src.includes('?operations=crop(')) {
			const defaultUrl = src.split('?operations=crop(')[0];
			setDefaultImage(defaultUrl);
		} else if (!cropButtonDisabled) {
			setDefaultImage(src);
		}
	}, [src]);

	const onCropImage = (data: any) => {
		const cropper = data.target.cropper;
		const cropperData = cropper.getData();
		const cropperPositions = getCropModelFromCropperInfo(cropperData.x, cropperData.y, cropperData.width, cropperData.height);
		const absoulteImagePathFirstPart = src.split('/assets/')[0] + '/assets/';
		const relativeImagePathLastPart = defaultImage.split('/assets/')[1];

		const newImagePath = addAssetManuallyCropDimensions(relativeImagePathLastPart, cropperPositions);
		HttpService.postAsset(tournamentId, 'LOGO', 'TOURNAMENT', newImagePath)
			.then(() => {
				const fullCroppedImageUrl = absoulteImagePathFirstPart + newImagePath;
				setDisplayedImageUrl(fullCroppedImageUrl);
				toast.success(t('image_update_success'));
			})
			.catch(() => toast.error(t('image_update_failed')));
	};

	return (
		<div className='image-crop-container'>
			<button disabled={cropButtonDisabled} className='btn btn-secondary' onClick={() => setOpenImageCropModal(true)}>
				<i className={'fa fa-crop'} /> {t('crop_image')}
			</button>
			<CropperModal imageUrl={defaultImage} isOpen={openImageCropModal} toggle={setOpenImageCropModal} t={t} onCrop={onCropImage} />
		</div>
	);
};
