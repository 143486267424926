import React from 'react';
import moment from 'moment';
import { Col, Row } from 'reactstrap';
import { Properties } from './properties/match-widget-view.properties';
import { getActiveTab } from '../helpers/match-widget.helper';
import { OddsWidgetView } from '../../odds/components/odds-widget-view.component';
import { assetsPlaceholder } from '../../../../../../../constants/assetsPlaceholder';

export const MatchWidgetView: React.FunctionComponent<Properties> = ({ oddsPreview, matchPreview, t }) => {
	if (matchPreview && matchPreview.match && matchPreview.match.id) {
		const homeTeamColor = matchPreview.homeTeamColor;
		const awayTeamColor = matchPreview.awayTeamColor;
		const homeTeamName = matchPreview.match.homeTeam
			? matchPreview.threeLetterCodeType && matchPreview.threeLetterCodeType !== '' && matchPreview.match.homeTeam.threeLetterCode !== null
				? matchPreview.match.homeTeam.threeLetterCode
				: matchPreview.match.homeTeam.name
			: '';

		const awayTeamName = matchPreview.match.awayTeam
			? matchPreview.threeLetterCodeType && matchPreview.threeLetterCodeType !== '' && matchPreview.match.awayTeam.threeLetterCode !== null
				? matchPreview.match.awayTeam.threeLetterCode
				: matchPreview.match.awayTeam.name
			: '';

		return (
			<>
				{matchPreview.displayOdds && (
					<OddsWidgetView
						isStandAlone={false}
						blockPreview={oddsPreview}
						t={t}
						displayHeader
						displayThreeLetterCode={matchPreview.threeLetterCodeType && matchPreview.threeLetterCodeType !== '' ? true : false}
					/>
				)}

				<Row className={`${!matchPreview.displayOdds ? 'p-4 text-center' : 'pb-4 pl-4 pr-4'}`}>
					<Col className='text-center'>
						{!matchPreview.displayOdds && (
							<>
								<Row className='mb-1'>
									<Col>{moment(matchPreview.match.startTime ? matchPreview.match.startTime : '').format('MMM Do YYYY, HH:mm')}</Col>
								</Row>
								<Row className='mb-1'>
									<Col className='text-right'>
										<strong>{homeTeamName}</strong>
										<img
											height='40'
											alt={matchPreview.match.homeTeam ? matchPreview.match.homeTeam.name : ''}
											src={matchPreview.match.homeTeam.logo ? matchPreview.match.homeTeam.logo : assetsPlaceholder.team}
										/>
									</Col>
									<Col className='text-left'>
										<img
											height='40'
											alt={matchPreview.match.awayTeam ? matchPreview.match.awayTeam.name : ''}
											src={matchPreview.match.awayTeam.logo ? matchPreview.match.awayTeam.logo : assetsPlaceholder.team}
										/>
										<strong>{awayTeamName}</strong>
									</Col>
								</Row>
							</>
						)}
						<Row>
							<Col className='text-left'>
								{t('display_odds')}:
								<strong>
									<span className={matchPreview.displayOdds ? 'text-success' : 'text-danger'}>
										{matchPreview.displayOdds ? ` ${t('yes')}` : ` ${t('no')}`}
									</span>
								</strong>
							</Col>
						</Row>
						<Row>
							<Col className='text-left'>
								{t('active_tab')}:
								<strong>{matchPreview.activeTab ? ` ${getActiveTab(matchPreview.activeTab, t)[0].label}` : ` ${t('none_selected')}`}</strong>
							</Col>
						</Row>
						<Row className='pt-1'>
							<Col className='text-left d-flex'>
								{t('team_colors')}:
								<div className='ml-2'>
									<svg xmlns='http://www.w3.org/2000/svg' width='30.033' height='25.002' viewBox='0 0 30.033 25.002'>
										<path
											id='shirt'
											d='M349.149,493.75c-1.1.681-1.236,1.216-2.123,1.8-1.138.753-1.5.636-1.988-.723-.649-1.819-1.292-3.644-1.948-5.46-.214-.594-.227-1.129.372-1.457,2.571-1.409,4.382-2.787,7.264-3.3a13.175,13.175,0,0,1,2.932-.06c.651.029.83.54.843,1.2.035,1.795,1.791,2.2,3.488,2.362,1.506.146,3.743-.049,4.107-1.634a3.136,3.136,0,0,0,.073-.5c.086-1.262.264-1.543,1.453-1.372a23.066,23.066,0,0,1,4.548,1.049c1.622.585,2.524,1.465,4.093,2.217a1.089,1.089,0,0,1,.606,1.566c-.71,1.913-1.371,3.848-2.065,5.769-.346.958-.847,1.111-1.66.586-.883-.57-1.154-1.114-2.05-1.661a5.789,5.789,0,0,0-.708-.279c.116.928.187,1.72.319,2.5.215,1.279-.241,6.374-.092,7.66.25,2.151-.333,3.359-2.21,4.178a15.489,15.489,0,0,1-4.046,1.132,17.78,17.78,0,0,1-7.8-.608,12.714,12.714,0,0,1-1.915-.8,3.4,3.4,0,0,1-1.995-3.9c.26-1.556.044-6.933.321-8.486C349.057,495.032,349.073,494.527,349.149,493.75Z'
											transform='translate(-342.956 -484.5)'
											fill={homeTeamColor}
										/>
									</svg>
									<strong className='mr-1 ml-1'>{homeTeamName},</strong>
								</div>
								<div className='ml-2'>
									<svg xmlns='http://www.w3.org/2000/svg' width='30.033' height='25.002' viewBox='0 0 30.033 25.002'>
										<path
											id='shirt'
											d='M349.149,493.75c-1.1.681-1.236,1.216-2.123,1.8-1.138.753-1.5.636-1.988-.723-.649-1.819-1.292-3.644-1.948-5.46-.214-.594-.227-1.129.372-1.457,2.571-1.409,4.382-2.787,7.264-3.3a13.175,13.175,0,0,1,2.932-.06c.651.029.83.54.843,1.2.035,1.795,1.791,2.2,3.488,2.362,1.506.146,3.743-.049,4.107-1.634a3.136,3.136,0,0,0,.073-.5c.086-1.262.264-1.543,1.453-1.372a23.066,23.066,0,0,1,4.548,1.049c1.622.585,2.524,1.465,4.093,2.217a1.089,1.089,0,0,1,.606,1.566c-.71,1.913-1.371,3.848-2.065,5.769-.346.958-.847,1.111-1.66.586-.883-.57-1.154-1.114-2.05-1.661a5.789,5.789,0,0,0-.708-.279c.116.928.187,1.72.319,2.5.215,1.279-.241,6.374-.092,7.66.25,2.151-.333,3.359-2.21,4.178a15.489,15.489,0,0,1-4.046,1.132,17.78,17.78,0,0,1-7.8-.608,12.714,12.714,0,0,1-1.915-.8,3.4,3.4,0,0,1-1.995-3.9c.26-1.556.044-6.933.321-8.486C349.057,495.032,349.073,494.527,349.149,493.75Z'
											transform='translate(-342.956 -484.5)'
											fill={awayTeamColor}
										/>
									</svg>
									<strong className='mr-1 ml-1'>{awayTeamName}</strong>
								</div>
							</Col>
						</Row>
						{matchPreview.threeLetterCodeType && matchPreview.threeLetterCodeType !== '' && (
							<Row>
								<Col className='text-left'>
									{t('display_team_three_letter_code')}:
									<strong>
										<span className='ml-2'>
											{matchPreview.threeLetterCodeType && matchPreview.threeLetterCodeType.name && t(`${matchPreview.threeLetterCodeType.name}`)}
										</span>
									</strong>
								</Col>
							</Row>
						)}
					</Col>
				</Row>
			</>
		);
	}

	return null;
};
