import React from 'react';
import { Col, FormGroup, Input, Label, Row, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { Properties, State } from './properties/fixtures-widget-edit.properties';
import OddsWidgetModel from '../../odds/models/odds-widget.model';
import TeamSelect from '../../../../partials/team-select/team-select.component';
import TeamModel from '../../../../partials/team-select/models/team.model';
import OddsWidgetEdit from '../../odds/components/odds-widget-edit.component';
import TournamentSelectContainer from '../../../../partials/tournament-select/tournament-select-container.component';
import TournamentModel from '../../player-profile/models/tournament-model/tournament.model';
import SeasonModel from '../../player-profile/models/season/season.model';
import SeasonSelectContainer from '../../../../partials/season-select/season-select-container.component';
import StageModel from '../models/stage/stage.model';
import StageSelectContainer from '../../../../partials/stage-select/stage-select-container.component';
import RoundSelectContainer from '../../../../partials/round-select/round-select-container.component';
import RoundModel from '../models/round/round.model';
import MatchesContainer from '../../../../partials/matches/matches-container';
import { extractMatchIdsFromFixtures, createMatchListFromMatchSelectList } from './helpers/fixtures-widget.helper';
import FixturesModel from '../models/fixtures-widget.model';
import MatchWidgetModel from '../../match/models/match-widget.model';
import { blockWrapperService, blockManagementService } from '../../../../subcomponents/blocky.component';
import ErrorHandler from '../../../../partials/error/error-handler-component';
import BlockValidation from '../../../../helpers/block-validation.helper';
import { featuresService } from '../../../../../../../App';
import { FeatureTypes } from '../../../../../../../services/feature-service/features.enum';

export default class FixturesWidgetEdit extends React.Component<Properties, State> {
	constructor(props: Properties) {
		super(props);
		this.state = {
			fixtures: props.fixtures ? props.fixtures : FixturesModel.builder().withDisplayOdds(true).build(),
			oddsModel: props.odds ? props.odds : OddsWidgetModel.builder().withBookmakers([]).build(),
			activeTab: new Array(4).fill('1'),
			upcomingMatchesAreSelected: props.upcomingMatchesAreSelected ? props.upcomingMatchesAreSelected : false,
			finishedMatchesAreSelected: props.finishedMatchesAreSelected ? props.finishedMatchesAreSelected : false,
			isValid: true,
		};
	}

	componentDidMount(): void {
		blockWrapperService.registerOnSaveFunc({ blockId: this.props.block.id, func: this.onSave });
	}

	onSave = (index: number) => {
		const data = { fixtures: this.state.fixtures, oddsModel: this.state.oddsModel };
		const validation = new BlockValidation();
		const isValid = validation.validate(this.props.block, data);
		this.setState({ ...this.state, isValid });
		const bookmakerSelectionEnabled = featuresService.areFeaturesEnabled([FeatureTypes.ALLOW_BOOKMAKER_SELECTION]);
		const odds = OddsWidgetModel.builder(this.state.oddsModel)
			.withBookmakers(bookmakerSelectionEnabled ? this.state.oddsModel.bookmakers : [])
			.build();

		if (isValid) {
			const block = Object.assign({}, this.props.block);
			block.data.preview = { fixtures: this.state.fixtures, odds };
			block.data.preview.fixtures.upcomingMatchesAreSelected = this.state.upcomingMatchesAreSelected;
			block.data.preview.fixtures.finishedMatchesAreSelected = this.state.finishedMatchesAreSelected;

			blockManagementService.blockUpdate(block, index);
		} else {
			return isValid;
		}
	};

	componentWillUnmount(): void {
		blockWrapperService.unregisterOnSaveFunc(this.props.block.id);
	}

	toggle = (tabPane: number, tab: string) => {
		const newArray = this.state.activeTab.slice();
		newArray[tabPane] = tab;
		this.setState({
			activeTab: newArray,
		});
	};

	updateWidgetModelState = (model: FixturesModel) => {
		this.setState({ fixtures: model });
	};

	onTeamChange = (team: TeamModel) => {
		this.updateWidgetModelState(FixturesModel.builder(this.state.fixtures).withTeam(team).build());
	};

	onMatchesChange = (selections: any) => {
		const fixtures = FixturesModel.builder(this.state.fixtures).withMatches(createMatchListFromMatchSelectList(selections)).build();
		this.setState((state: any) => {
			return {
				...state,
				fixtures,
				isValid: true,
			};
		});
	};
	onTournamentSelect = (tournament: TournamentModel) => {
		this.setState({
			fixtures: FixturesModel.builder(this.state.fixtures).withTournament(tournament).withSeason(SeasonModel.builder().build()).build(),
		});
	};

	onSeasonSelect = (season: SeasonModel) => {
		this.setState({
			fixtures: FixturesModel.builder(this.state.fixtures).withSeason(season).withStage(StageModel.builder().build()).build(),
		});
	};

	onStageSelect = (stage: StageModel) => {
		this.setState({
			fixtures: FixturesModel.builder(this.state.fixtures).withStage(stage).build(),
		});
	};

	onRoundSelect = (round: RoundModel) => {
		this.setState({
			fixtures: FixturesModel.builder(this.state.fixtures).withRound(round).build(),
		});
	};

	onTitleChange = (title: string) => {
		this.setState({
			fixtures: FixturesModel.builder(this.state.fixtures).withTitle(title).build(),
		});
	};

	onDisplayOddsChange = (event: any) => {
		this.setState({
			fixtures: FixturesModel.builder(this.state.fixtures).withDisplayOdds(event.target.checked).build(),
		});
	};

	updateCheckAllMatchesState = (checked: any, type: string, matches: MatchWidgetModel[], tab: string) => {
		if (tab === '1' && type === 'upcoming') {
			this.setState({ ...this.state, upcomingMatchesAreSelected: checked });
		} else {
			this.setState({ ...this.state, finishedMatchesAreSelected: checked });
		}
	};

	onOddsWidgetChange = (oddsModel: OddsWidgetModel) => this.setState({ ...this.state, oddsModel });

	renderFixtureTeams(fixtures: FixturesModel) {
		return (
			<>
				<Row>
					<Col>
						<FormGroup>
							<TeamSelect
								value={fixtures.team}
								inputId='fixtures-team-select'
								isMulti={false}
								onSelect={(team: TeamModel) => {
									this.onTeamChange(team);
								}}
								t={this.props.t}
								isValid={this.state.isValid}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					<Col>
						<FormGroup>
							<MatchesContainer
								teamIds={fixtures && fixtures.team && fixtures.team.id ? [fixtures.team.id] : []}
								maxNotStartedMatches={100}
								maxFinishedMatches={20}
								maxInterruptedMatches={20}
								teamNames={['']}
								isMulti={true}
								selectedMatchIds={extractMatchIdsFromFixtures(fixtures)}
								onSelect={(selections: any) => {
									this.onMatchesChange(selections);
								}}
								updateCheckAllMatchesState={this.updateCheckAllMatchesState}
								upcomingMatchesAreSelected={this.state.upcomingMatchesAreSelected}
								finishedMatchesAreSelected={this.state.finishedMatchesAreSelected}
								t={this.props.t}
							/>
						</FormGroup>
					</Col>
				</Row>
			</>
		);
	}

	renderFixtureStages(fixtures: FixturesModel) {
		return (
			<>
				<Row>
					<Col>
						<FormGroup>
							<TournamentSelectContainer
								withLabel
								t={this.props.t}
								isBlocky
								selectedTournament={fixtures.tournament}
								onTournamentSelect={(tournament: TournamentModel) => {
									this.onTournamentSelect(tournament);
								}}
								isValid={this.state.isValid}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					<Col>
						<FormGroup>
							{fixtures.tournament && fixtures.tournament.id && (
								<SeasonSelectContainer
									withLabel
									t={this.props.t}
									tournamentId={fixtures.tournament.id}
									selectedSeason={fixtures.season}
									onSeasonSelect={(season: SeasonModel) => {
										this.onSeasonSelect(season);
									}}
								/>
							)}
						</FormGroup>
					</Col>
				</Row>
				<Row>
					<Col>
						<FormGroup>
							{fixtures.season && fixtures.season.id && (
								<StageSelectContainer
									withLabel
									selectedStage={fixtures.stage}
									seasonId={fixtures.season.id}
									onStageSelect={(stage: StageModel) => {
										this.onStageSelect(stage);
									}}
									t={this.props.t}
								/>
							)}
						</FormGroup>
					</Col>
				</Row>
				<Row>
					<Col>
						<FormGroup>
							{fixtures.stage && fixtures.stage.id && (
								<RoundSelectContainer
									withLabel
									selectedRound={fixtures.round}
									stageId={fixtures.stage.id}
									onRoundSelect={(round: RoundModel) => {
										this.onRoundSelect(round);
									}}
									t={this.props.t}
								/>
							)}
						</FormGroup>
					</Col>
				</Row>
				<Row>
					<Col>
						<FormGroup>
							<MatchesContainer
								round={fixtures.round}
								maxNotStartedMatches={100}
								maxFinishedMatches={20}
								teamNames={['']}
								isMulti={true}
								selectedMatchIds={extractMatchIdsFromFixtures(fixtures)}
								stageId={fixtures.stage && fixtures.stage.id}
								onSelect={(selections: any) => {
									this.onMatchesChange(selections);
								}}
								updateCheckAllMatchesState={this.updateCheckAllMatchesState}
								upcomingMatchesAreSelected={this.state.upcomingMatchesAreSelected}
								finishedMatchesAreSelected={this.state.finishedMatchesAreSelected}
								t={this.props.t}
							/>
						</FormGroup>
					</Col>
				</Row>
			</>
		);
	}

	render() {
		const { fixtures } = this.state;
		const bookmakerSelectionEnabled = featuresService.areFeaturesEnabled([FeatureTypes.ALLOW_BOOKMAKER_SELECTION]);

		return (
			<div className={'block'}>
				<div className={'block-content'}>
					<Row className={'mb-3'}>
						<Col>
							<Label>{this.props.t('title')}:</Label>
							<Input
								value={fixtures.title ? fixtures.title : ''}
								onChange={(event) => {
									this.onTitleChange(event.target.value);
								}}
								type={'text'}
							/>
						</Col>
					</Row>
					<Row>
						<Col>
							<Nav tabs className={''}>
								<NavItem>
									<NavLink
										active={this.state.activeTab[0] === '1'}
										onClick={() => {
											this.toggle(0, '1');
										}}
									>
										{this.props.t('teams')}
									</NavLink>
								</NavItem>
								<NavItem>
									<NavLink
										active={this.state.activeTab[0] === '2'}
										onClick={() => {
											this.toggle(0, '2');
										}}
									>
										{this.props.t('stages')}
									</NavLink>
								</NavItem>
							</Nav>
							<TabContent activeTab={this.state.activeTab[0]} className={'mb-2'}>
								<TabPane tabId='1' className={'p-3 pb-0 bg-white'}>
									<FormGroup className={'mt-1'}>{this.state.activeTab[0] === '1' && this.renderFixtureTeams(fixtures)}</FormGroup>
								</TabPane>
								<TabPane tabId='2' className={'p-3 pb-0 bg-white'}>
									<FormGroup className={'mt-1'}>{this.state.activeTab[0] === '2' && this.renderFixtureStages(fixtures)}</FormGroup>
								</TabPane>
							</TabContent>
						</Col>
					</Row>

					<Row className='mb-2'>
						<Col>
							<FormGroup key={`display-odds-markets`} check className='radio'>
								<Input
									key={`display-odds-markets-input`}
									className='form-check-input'
									type='checkbox'
									id={`display-odds-markets-checkbox-${this.props.block.id}`}
									name='radios'
									onChange={this.onDisplayOddsChange}
									checked={fixtures.displayOdds}
								/>
								<Label
									key={`display-odds-markets-label`}
									check
									className='form-check-label'
									for={`display-odds-markets-checkbox-${this.props.block.id}`}
								>
									{this.props.t('display_odds')}
								</Label>
							</FormGroup>
						</Col>
					</Row>

					{fixtures.displayOdds && bookmakerSelectionEnabled && (
						<OddsWidgetEdit
							contentData={this.props.contentData}
							showAllBookmakers
							block={this.props.block}
							isStandAlone={false}
							useTeamSelect={false}
							useMatchSelect={false}
							t={this.props.t}
							onOddsWidgetChange={this.onOddsWidgetChange}
							autoSelectFirstMatch={true}
							displayOddsLabel={true}
							isValid={this.state.isValid}
						/>
					)}
					{!this.state.isValid && <ErrorHandler errorMessage='no_matches_selected' />}
				</div>
			</div>
		);
	}
}
