import React from 'react';
import Select from 'react-select';

import { instatStatisticsOptions, contentToOption } from './helpers/stats-select.helper';

type Properties = {
	onInstatStatisticsSelect: any;
	value: any;
	t: any;
	isMulti: boolean;
};

const InstatStatisticsMultiSelect: React.FunctionComponent<Properties> = ({ value, t, onInstatStatisticsSelect }) => {
	const InstatStatisticsSelect = (selections: any) => {
		if (onInstatStatisticsSelect) {
			onInstatStatisticsSelect(selections);
		}
	};

	return (
		<>
			<Select
				// isMulti
				options={instatStatisticsOptions(t)}
				onChange={InstatStatisticsSelect}
				value={contentToOption(value)}
				noOptionsMessage={(inputValue) => inputValue && t('no_options')}
			/>
		</>
	);
};

export default InstatStatisticsMultiSelect;
