import * as React from 'react';
import { Properties } from './properties/MainMediaProps';
import MainMedia from '../../../../models/mainmedia/MainMedia';
import MainContentMediaContainer from './subcomponents/media-content/MainContentMediaContainer';
import MainImageMedia from './subcomponents/media-main-image/MainImageMedia';
import Project from '../../../../models/project/Project';
import Select from 'react-select';
import { FormGroup } from 'reactstrap';
import MediaVideoEmbed from './subcomponents/media-embed/MediaVideoEmbed';
import { gallerySelectOptions, selectOptions } from './helpers/MainMediaListSelectOptions';
import { featuresService } from '../../../../App';
import { FeatureTypes } from '../../../../services/feature-service/features.enum';
import { ContentTypes } from '../../../../constants/content-types';
import {
	checkIfCorrectTypes,
	DISPLAY_CHECKBOX_CONTENT_TYPES,
} from '../media-refactored/subcomponents/additional-image-media/helpers/main-media-additional-items.helper';

export const MainMediaList: React.FunctionComponent<Properties> = (props) => {
	const onMainMediaChange = (mainMedia: MainMedia) => {
		props.onMediaUpdate(mainMedia);
	};

	const onMediaRemove = (type: string, subType: string) => {
		props.onRemove(type, subType);
	};

	const onMainMediaAdd = (selection: any) => {
		props.onMediaAdd(
			MainMedia.builder()
				.withDescription('')
				.withResourceSubtype(selection.subType)
				.withResourceType(selection.type)
				.withData(selection.data)
				.build(),
		);
	};

	const displayMainMediaList = (mainMedia: MainMedia[], project: Project, t: any) => {
		if (mainMedia) {
			return mainMedia.map((item: MainMedia, index) => {
				if (item.resourceType === ContentTypes.VIDEO && item.resourceSubtype === null) {
					return (
						<MainContentMediaContainer
							onRemove={onMediaRemove}
							value={item}
							index={index}
							key={'video-type-media-key'}
							onContentSelect={onMainMediaChange}
							t={t}
							project={project}
							contentType={ContentTypes.VIDEO}
						/>
					);
				}

				if (item.resourceType === ContentTypes.GALLERY && item.resourceSubtype === null) {
					return (
						<MainContentMediaContainer
							onRemove={onMediaRemove}
							value={item}
							index={index}
							key={'gallery-type-media-key'}
							onContentSelect={onMainMediaChange}
							t={t}
							project={project}
							contentType={ContentTypes.GALLERY}
						/>
					);
				}

				if (item.resourceType === ContentTypes.EMBED && item.resourceSubtype === ContentTypes.VIDEO) {
					return (
						<MediaVideoEmbed
							key={`key-${item.resourceType}`}
							index={index}
							t={t}
							onChange={onMainMediaChange}
							embedCode={item.data ? item.data.embedCode : ''}
							onRemove={onMediaRemove}
							blocks={props.blocks}
							contentType={props.contentType}
						/>
					);
				}
			});
		}

		return null;
	};

	const { mainMediaList, project, mainImage, t, resourceType, contentType, additionalMainMediaItemsOpen } = props;
	const imageMediaErrorIndex = mainMediaList && mainMediaList.findIndex((media) => media.resourceType === ContentTypes.IMAGE);
	const selectOptionsWithFilteredFeatures = selectOptions(t).filter((option) => {
		if (option.value === 'embed-video' || option.value === 'media-video') {
			return featuresService.areFeaturesEnabled([FeatureTypes.VIDEO]);
		}

		return true;
	});

	return (
		<>
			<MainImageMedia
				applyWatermark={props.applyWatermark}
				contentType={contentType}
				media={mainImage}
				mediaErrorIndex={imageMediaErrorIndex}
				currentProject={project}
				t={t}
				onMainImageMediaUpdate={onMainMediaChange}
				displayDescriptionCheckbox={checkIfCorrectTypes(contentType, DISPLAY_CHECKBOX_CONTENT_TYPES)}
				additionalMainMediaItemsOpen={additionalMainMediaItemsOpen}
			/>
			{displayMainMediaList(mainMediaList, project, t)}
			{contentType !== ContentTypes.GALLERY && (
				<FormGroup>
					<label>{t('choose_main_media')}</label>
					<Select
						options={resourceType.length === 0 ? selectOptionsWithFilteredFeatures : gallerySelectOptions(t)}
						onChange={onMainMediaAdd}
						placeholder={t('select')}
						noOptionsMessage={(inputValue) => inputValue && t('no_options')}
						inputId={'choose-main-media-drop-down'}
					/>
				</FormGroup>
			)}
		</>
	);
};
