import { EventJson } from './event.json';
import EventBuilder from './event.builder';
import PlayerManualDataModel from '../../../../../models/player-manual-data/player-manual-data.model';

export default class EventModel {
	readonly id: string;
	readonly matchId: string;
	readonly teamId: string;
	readonly teamPosition: string;
	readonly typeCode: string;
	readonly minute: string | null;
	readonly score: number | null;
	readonly primaryPlayer: PlayerManualDataModel | null;
	readonly secondaryPlayer: PlayerManualDataModel | null;

	private constructor(
		id: string,
		matchId: string,
		teamId: string,
		teamPosition: string,
		typeCode: string,
		minute: string | null,
		score: number | null,
		primaryPlayer: PlayerManualDataModel | null,
		secondaryPlayer: PlayerManualDataModel | null,
	) {
		this.id = id;
		this.matchId = matchId;
		this.teamId = teamId;
		this.teamPosition = teamPosition;
		this.typeCode = typeCode;
		this.minute = minute;
		this.score = score;
		this.primaryPlayer = primaryPlayer;
		this.secondaryPlayer = secondaryPlayer;
	}

	toJSON(): EventJson {
		return {
			id: this.id,
			matchId: this.matchId,
			teamId: this.teamId,
			teamPosition: this.teamPosition,
			typeCode: this.typeCode,
			minute: this.minute,
			score: this.score,
			primaryPlayer: this.primaryPlayer,
			secondaryPlayer: this.secondaryPlayer,
		};
	}

	static fromJSON(json: EventJson): EventModel {
		return new EventModel(
			json.id,
			json.matchId,
			json.teamId,
			json.teamPosition,
			json.typeCode,
			json.minute,
			json.score,
			json.primaryPlayer,
			json.secondaryPlayer,
		);
	}

	static builder(lineup?: EventModel): EventBuilder {
		return new EventBuilder(lineup);
	}
}
