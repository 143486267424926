import 'reflect-metadata';
import { TypedJSON } from 'typedjson';
import CustomBlockListingRowModel from './custom-block-listing-row.model';

export default class CustomBlockListingRowMapper {
	public responseToModel = (response: any) => {
		const serializer = new TypedJSON(CustomBlockListingRowModel);
		return serializer.parse(response);
	};
}
