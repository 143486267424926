import React, { FunctionComponent } from 'react';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import ImageModel from '../../../../../../models/v2/image/image.model';
import { LiveBlogImageListing } from './image-listing.component';
import { DATA_QA_ATTRIBUTES } from '../../../../../Partials/Sidebar/general-content/constants/data-qa.attributes';

type Properties = {
	t: any;
	onChange: (image: ImageModel) => void;
	selectedImageId: ImageModel;
};

export const LiveBlogImageSelectContainer: FunctionComponent<Properties> = ({ t, onChange, selectedImageId }) => {
	const updateImage = (image: ImageModel) => {
		onChange(image);
	};

	return (
		<Row>
			<Col>
				<FormGroup>
					<Label htmlFor={DATA_QA_ATTRIBUTES.LIVE_BLOG_IMAGE_LISTING_CONTAINER}>{t('choose_image')}:</Label>
					<LiveBlogImageListing
						t={t}
						selectedImageId={selectedImageId && selectedImageId.id && selectedImageId.id.length > 0 ? selectedImageId.id : ''}
						onImageSelect={updateImage}
					/>
				</FormGroup>
			</Col>
		</Row>
	);
};
