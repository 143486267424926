import { TeamLineupJson } from './team-lineup.json';
import TeamLineupBuilder from './team-lineup.builder';
import LineupPlayerModel from '../player-lineup/lineup-player.model';

export default class TeamLineupModel {
	readonly teamId: string;
	readonly coach: any;
	readonly players: LineupPlayerModel[];
	readonly formation: string | null;

	private constructor(teamId: string, coach: any, players: LineupPlayerModel[], formation: string | null) {
		this.teamId = teamId;
		this.coach = coach;
		this.players = players;
		this.formation = formation;
	}

	toJSON(): TeamLineupJson {
		return {
			teamId: this.teamId,
			coach: this.coach,
			players: this.players,
			formation: this.formation,
		};
	}

	static fromJSON(json: TeamLineupJson): TeamLineupModel {
		return new TeamLineupModel(json.teamId, json.coach, json.players, json.formation);
	}

	static builder(lineup?: TeamLineupModel): TeamLineupBuilder {
		return new TeamLineupBuilder(lineup);
	}
}
