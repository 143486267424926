import React from 'react';
import { Col, Input, Label, Row } from 'reactstrap';
import { DATA_QA_ATTRIBUTES } from '../../../../../Partials/Sidebar/general-content/constants/data-qa.attributes';
import LiveBlogModel from '../../../models/live-blog.model';
import '../../../style/live-blog.style.scss';
type Properties = {
	t: any;
	liveBlog: LiveBlogModel;
	setLiveBlogPagination: (pagination: number) => void;
};

export const LiveBlogPaginationSelect: React.FunctionComponent<Properties> = ({ t, liveBlog, setLiveBlogPagination }) => {
	const onPaginationSelect = (pagination: number) => {
		setLiveBlogPagination(pagination);
	};

	return (
		<Row className='mb-3'>
			<Col>
				<Label htmlFor={DATA_QA_ATTRIBUTES.LIVE_BLOG_PAGINATION}>
					<strong>{t('pagination')}</strong>
				</Label>
				<Input
					id={DATA_QA_ATTRIBUTES.LIVE_BLOG_PAGINATION}
					type='number'
					value={liveBlog.pagination}
					placeholder={t('select')}
					min={1}
					onChange={(selection: any) => onPaginationSelect(selection.target.value)}
				/>
			</Col>
		</Row>
	);
};
