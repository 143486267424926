import List from '../../models/list/List';
import {
	LIST_ENTITY_DELETE_FAILED,
	LIST_ENTITY_DELETE_SUCCESS,
	LIST_ENTITY_RECEIVED,
	LIST_LISTING_RECEIVED,
	LIST_LISTING_UPDATE,
	CONTENT_CREATE_LIST_TO_SAVE,
	LIST_ITEMS_CONTENT_STATISTICS_RECEIVED,
	LIST_ENTITY_UPDATE_DIRECTLY,
} from '../action-creators/ListActionCreators';
import { USER_LOGOUT } from '../action-creators/ProfileActionCreators';
import PaginationMeta from '../../models/pagination/PaginationMeta';

interface InitialState {
	list: List;
	lists: List[];
	pagination: PaginationMeta;
	listDeleted: boolean;
	contentCreateList: List | null;
}

const initialState: InitialState = {
	list: List.builder().build(),
	lists: [],
	listDeleted: false,
	pagination: PaginationMeta.builder().build(),
	contentCreateList: null,
};

function listReducer(state: any = initialState, action: any) {
	if (action.type === LIST_LISTING_RECEIVED) {
		state = Object.assign({}, state, {
			lists: action.payload,
			pagination: action.payload.contentPagination,
		});
	}

	if (action.type === LIST_LISTING_UPDATE) {
		state = Object.assign({}, state, {
			lists: action.payload,
		});
	}

	if (action.type === LIST_ENTITY_RECEIVED) {
		return Object.assign({}, state, {
			list: action.payload.list,
		});
	}

	if (action.type === LIST_ENTITY_UPDATE_DIRECTLY) {
		// if the list is null, set it as empty object
		const backwardCompatibilityData = action.payload ? action.payload : {};
		return Object.assign({}, state, {
			list: backwardCompatibilityData,
		});
	}

	if (action.type === LIST_ENTITY_DELETE_SUCCESS) {
		return Object.assign({}, state, {
			listDeleted: true,
		});
	}

	if (action.type === LIST_ENTITY_DELETE_FAILED) {
		return Object.assign({}, state, {
			listDeleted: false,
		});
	}
	if (action.type === CONTENT_CREATE_LIST_TO_SAVE) {
		return Object.assign({}, state, {
			contentCreateList: action.payload,
		});
	}

	if (action.type === LIST_ITEMS_CONTENT_STATISTICS_RECEIVED) {
		return Object.assign({}, state, {
			list: action.payload.list,
		});
	}

	if (action.type === USER_LOGOUT) {
		state = initialState;
	}

	return state;
}

export default listReducer;
