import ContentLanguagesBuilder from './content-languages-builder';
import { ContentLanguagesJson } from './content-languages-json';
import LanguageCodeModel from './language-code/language-code-model';

export default class ContentLanguagesModel {
	readonly all: LanguageCodeModel[];
	readonly defaultLanguageCode: LanguageCodeModel;
	readonly multiple: boolean = false;

	private constructor(all: LanguageCodeModel[], defaultLanguageCode: LanguageCodeModel, multiple: boolean) {
		this.all = all;
		this.defaultLanguageCode = defaultLanguageCode;
		this.multiple = multiple;
	}

	toJSON(): ContentLanguagesJson {
		return {
			all: this.all,
			defaultLanguageCode: this.defaultLanguageCode,
			multiple: this.multiple,
		};
	}

	static fromJSON(json: ContentLanguagesJson) {
		return new ContentLanguagesModel(json.all, json.defaultLanguageCode, json.multiple);
	}

	static builder(languageCodes?: ContentLanguagesModel): ContentLanguagesBuilder {
		return new ContentLanguagesBuilder(languageCodes);
	}
}
