import * as React from 'react';
import { Label, Col, FormGroup } from 'reactstrap';
import { TeamPositions } from '../../../../../models/player-manual-data/constants/manual-entries.constants';
import * as helpers from '../../../../Partials/Shared/react-select-helpers/react-select.helpers';
import Select from 'react-select';
import EnumItem from '../../../../../models/enum/EnumItem';

type TypeProps = {
	t: any;
	onChange: any;
	value: any;
};

const Type: React.FunctionComponent<TypeProps> = ({ onChange, t, value }) => {
	const options = Object.keys(TeamPositions).map((key: string) => {
		return helpers.itemToOption(EnumItem.builder().withId(key).withName(TeamPositions[key]).build(), false, t, '');
	});

	const onTeamType = (selection: any) => onChange(helpers.optionToItem(selection));

	return (
		<Col xs='6'>
			<FormGroup>
				<Label htmlFor='team-manual-data-entry-type'>
					{t('type')}
					<span className='text-danger'>*</span>
				</Label>
				<Select
					inputId='team-manual-data-entry-type'
					value={helpers.itemToOption(value, false, t, '')}
					options={options}
					onChange={onTeamType}
					placeholder={t('select_type')}
				/>
			</FormGroup>
		</Col>
	);
};

export default Type;
