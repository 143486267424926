import { MatchDetailsJson } from './match-details.json';
import { MatchDetailsRequestJson } from './match-details-request.json';
import MatchDetailsModel from './match-details.model';
import EnumItem from '../../../../../models/enum/EnumItem';
import TeamModel from '../../../../Partials/Blocky/partials/team-select/models/team.model';
import RoundModel from '../../../../Partials/Blocky/blocks/widgets/fixtures/models/round/round.model';
import MatchSeasonModel from './match-season.model';
import moment from 'moment';
import MatchScoreModel from './match-score.model';
import { validateByEventStatus } from '../../subcomponents/helpers/match-details.helper';
import BlacklistModel from '../../../../../models/blacklist/blacklist';

export default class MatchDetailsBuilder {
	private json: MatchDetailsJson;

	constructor(team?: MatchDetailsModel | MatchDetailsJson) {
		if (team && team instanceof MatchDetailsModel) {
			this.json = team.toJSON();
		} else if (team) {
			this.json = team;
		} else {
			this.json = {} as MatchDetailsJson;
		}
	}

	withId(id: string): MatchDetailsBuilder {
		this.json.id = id;

		return this;
	}

	withType(type: string): MatchDetailsBuilder {
		this.json.type = type;

		return this;
	}

	withMinutes(minutes: number): MatchDetailsBuilder {
		this.json.minutes = minutes;

		return this;
	}

	withEventStatus(eventStatus: { id: string; name: string; type: string; code: string }): MatchDetailsBuilder {
		this.json.eventStatus = eventStatus;

		return this;
	}

	withStartTime(startTime: string): MatchDetailsBuilder {
		this.json.startTime = startTime ? startTime : '';

		return this;
	}

	withFinishedAt(finishedAt: string): MatchDetailsBuilder {
		this.json.finishedAt = finishedAt ? finishedAt : '';

		return this;
	}

	withHomeTeam(homeTeam: TeamModel | null): MatchDetailsBuilder {
		this.json.homeTeam = homeTeam;

		return this;
	}

	withAwayTeam(awayTeam: TeamModel | null): MatchDetailsBuilder {
		this.json.awayTeam = awayTeam;

		return this;
	}

	withReferees(referees: EnumItem[] | null): MatchDetailsBuilder {
		this.json.referees = referees;

		return this;
	}

	withVenue(venue: EnumItem | null): MatchDetailsBuilder {
		this.json.venue = venue;

		return this;
	}

	withStage(stage: EnumItem | null): MatchDetailsBuilder {
		this.json.stage = stage;

		return this;
	}

	withRound(round: RoundModel | null): MatchDetailsBuilder {
		this.json.round = round;

		return this;
	}

	withCoverage(coverage: string): MatchDetailsBuilder {
		this.json.coverage = coverage ? coverage : '';

		return this;
	}

	withGroup(group: EnumItem | null): MatchDetailsBuilder {
		this.json.group = group ? group : null;

		return this;
	}

	withKikoffTime(kickoffTime: string): MatchDetailsBuilder {
		this.json.kickoffTime = kickoffTime ? kickoffTime : '';

		return this;
	}

	withScore(score: MatchScoreModel | null): MatchDetailsBuilder {
		this.json.score = score ? score : null;

		return this;
	}

	withSpectators(spectators: number | null): MatchDetailsBuilder {
		this.json.spectators = spectators ? spectators : null;

		return this;
	}

	withSeason(season: MatchSeasonModel | null): MatchDetailsBuilder {
		this.json.season = season ? season : null;

		return this;
	}

	withBlacklist(blacklist: BlacklistModel): MatchDetailsBuilder {
		this.json.blacklist = blacklist;

		return this;
	}

	toRequestJson(): MatchDetailsRequestJson {
		let json = {} as MatchDetailsRequestJson;

		if (this.json.eventStatus) {
			json.status_id = this.json.eventStatus.id;
		}

		if (this.json.stage) {
			json.stage_id = `${this.json.stage.id}`;
		}

		if (this.json.homeTeam) {
			json.home_team_id = this.json.homeTeam.id;
		}

		if (this.json.awayTeam) {
			json.away_team_id = this.json.awayTeam.id;
		}

		if (this.json.round) {
			json.round_key = this.json.round.round;
		}

		if (this.json.group) {
			json.group_id = `${this.json.group.id}`;
		}

		if (this.json.venue) {
			json.venue_id = this.json.venue.id;
		}

		if (this.json.referees && this.json.referees.length > 0) {
			json.referees = this.json.referees.map((referee: any) => ({
				referee_id: `${referee.id}`,
				role: 'REFEREE',
			}));
		}

		if (this.json.spectators) {
			json.spectators = Number(this.json.spectators);
		}

		if (this.json.score && !validateByEventStatus(this.json.eventStatus, 'notStarted')) {
			json.score = MatchScoreModel.builder(this.json.score).toRequestJson();
		} else {
			json.score = null;
		}

		if (this.json.coverage) {
			json.coverage = this.json.coverage;
		}

		if (this.json.finishedAt && validateByEventStatus(this.json.eventStatus, 'finished')) {
			json.finished_at = moment(this.json.finishedAt).utc().format();
		}

		if (this.json.startTime) {
			json.phase_started_at = moment(this.json.startTime).utc().format();
		}

		if (this.json.kickoffTime) {
			json.kickoff_time = moment(this.json.kickoffTime).utc().format();
		}

		return json;
	}

	build(): MatchDetailsModel {
		return MatchDetailsModel.fromJSON(this.json);
	}
}
