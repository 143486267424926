import { TranslationsJson } from './translations-json';
import TranslationsBuilder from './translations-builder';

export default class TranslationsModel {
	readonly id: string = '';
	readonly language: string = '';

	private constructor(id: string, language: string) {
		this.id = id;
		this.language = language;
	}

	toJSON(): TranslationsJson {
		return {
			id: this.id,
			language: this.language,
		};
	}

	static fromJSON(json: TranslationsJson) {
		return new TranslationsModel(json.id, json.language);
	}

	static builder(translations?: TranslationsModel): TranslationsBuilder {
		return new TranslationsBuilder(translations);
	}
}
