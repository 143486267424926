import { ContextJson } from './context.json.js';
import { ContextRequestJson } from './context-request.json.js';
import Context from './context';

export default class ContextBuilder {
	private json: ContextJson;

	constructor(statistics?: Context | ContextJson) {
		if (statistics && statistics instanceof Context) {
			this.json = statistics.toJSON();
		} else if (statistics) {
			this.json = statistics;
		} else {
			this.json = {} as ContextJson;
		}
	}

	withGroupBy(groupBy: string): ContextBuilder {
		this.json.groupBy = groupBy;

		return this;
	}

	withStartDate(startDate: string): ContextBuilder {
		this.json.startDate = startDate;

		return this;
	}

	withEndDate(endDate: string): ContextBuilder {
		this.json.endDate = endDate;

		return this;
	}

	build(): Context {
		return Context.fromJSON(this.json);
	}

	toRequestJson(): ContextRequestJson {
		let json = {} as ContextRequestJson;

		json.group_by = this.json.groupBy;
		json.start_date = this.json.startDate;
		json.end_date = this.json.endDate;

		return json;
	}
}
