import React from 'react';
import { Button, Col, FormGroup, Label, Row } from 'reactstrap';
import AsyncSelect from 'react-select/async';
import _ from 'lodash';
import Project from '../../../../../../../models/project/Project';
import MainMedia from '../../../../../../../models/mainmedia/MainMedia';
import * as httpServices from '../../../../../../Partials/Sidebar/Media/subcomponents/media-content/helpers/MainContentMediaHttpService';
import * as helpers from '../../../../../../Partials/Sidebar/Media/subcomponents/media-content/helpers/MainContentMediaContainer';
import ModelMapper from '../../../../../../../models/ModelMapper';
import ErrorAsync from '../../../../../../Partials/BaseComponents/ErrorComponent/ErrorAsync';
import { customOption } from '../../../../../../Partials/Sidebar/Media/subcomponents/media-content/helpers/MainContentMediaContainer';
import { MainMediaTypes } from '../components/helpers/main-media-type-select.helper';

export type Properties = {
	contentType: string;
	project: Project;
	onContentSelect: (media: MainMedia) => any;
	value: MainMedia;
	t: any;
	index: number;
	onRemove: (type: string, subType: string) => any;
};

export type State = {
	newValue: string;
	optionValue: any;
};

export class MainContentMediaContainer extends React.Component<Properties, State> {
	constructor(props: Properties) {
		super(props);
		this.state = {
			newValue: '',
			optionValue: helpers.contentToOption(props.value),
		};
	}

	componentDidMount(): void {
		this.requestContentData();
	}

	private onInputChange = (input: string) => {
		this.setState({ ...this.state, newValue: input });
	};

	private requestContent = (search: string, callback: any) => {
		const { contentType, project } = this.props;
		const header = { Project: project.domain };

		httpServices.contentDataSearch(contentType, search, header).then((response: any) => {
			let options = helpers.responseToOptions(response.data.data, contentType);
			callback(options);
		});
	};

	onContentSelect(selection: any, subtype: any) {
		const { contentType } = this.props;
		const media = MainMedia.builder()
			.withResourceId(selection.value)
			.withResourceType(contentType)
			.withResourceSubtype(subtype)
			.withProvider('smp')
			.withData(selection.data)
			.build();

		this.props.onContentSelect(media);
	}

	requestContentData() {
		const { contentType, value, project } = this.props;
		const header = { Project: project.domain };
		if (value && value.resourceId && value.resourceType === contentType) {
			httpServices.contentDataRequest(contentType, value.resourceId, header).then((response: any) => {
				const content = response.data.data;
				this.setState({
					...this.state,
					optionValue: {
						value: value.resourceId,
						label: content.title,
						data:
							value.resourceType === MainMediaTypes.gallery
								? ModelMapper.remapGalleryFromResponse(content)
								: ModelMapper.remapVideoFromResponse(content),
					},
				});
			});
		}
	}

	componentDidUpdate(prevProps: Readonly<Properties>): void {
		if (prevProps.value && this.props.value && prevProps.value.resourceId !== this.props.value.resourceId) {
			this.setState({
				...this.state,
				optionValue: helpers.contentToOption(this.props.value),
			});
			this.requestContentData();
		}
	}

	onRemove(contentType: string, subtype: any) {
		this.props.onRemove(contentType, subtype);
	}

	render() {
		const { contentType, t, index } = this.props;
		const { optionValue } = this.state;
		return (
			<div className='mt-4' id='sidebar-media-video'>
				<Col col='12' sm='12' md='12' className='mb-2 p-0'>
					<FormGroup>
						<Label>{helpers.displaySearchTitle(contentType, t)}</Label>
						<div className='d-flex align-items-center'>
							<div style={{flex: 1, marginRight: '4px'}}>
								<AsyncSelect
									noOptionsMessage={(inputValue) => inputValue && t('no_options')}
									value={optionValue}
									loadOptions={_.debounce(this.requestContent.bind(this), 500)}
									onInputChange={_.debounce(this.onInputChange, 500)}
									onChange={(selection: any) => {
										this.onContentSelect(selection, null);
									}}
									inputId={'choose-main-media-from-' + contentType + '-library-drop-down'}
									formatOptionLabel={customOption}
									key={contentType}
								
								/>
							</div>
							{optionValue.value && optionValue.value.length > 0 && (
								<Button
									className='bg-danger border-0'
									id={'main-media-from-' + contentType + '-library-remove-btn'}
									title={t('button_remove')}
									onClick={() => {
										this.onRemove(contentType, null);
									}}
								>
									<i className='fa fa-trash' />
								</Button>
							)}
						</div>
						<ErrorAsync t={t} errorType='main_media' mainMedia={`main_media.${index}.provider`} />
					</FormGroup>
				</Col>
			</div>
		);
	}
}
