import React, { FunctionComponent, useEffect, useState } from 'react';
import { Button, Col, Label, Row } from 'reactstrap';
import LiveBlogModel from '../../../models/live-blog.model';
import { DATA_QA_ATTRIBUTES } from '../../../../../Partials/Sidebar/general-content/constants/data-qa.attributes';
import '../../../style/live-blog.style.scss';
import { updateListInRedux } from '../../../../../../store/action-creators/ListActionCreators';
import { connect } from 'react-redux';
import LiveBlogListPresentIn from './live-blog-lists-card.component';
import LiveBlogListsModal from './live-blog-lists-modal.component';
import LiveBlogListItemsModal from './live-blog-list-items-modal.component';
import { isEditScreen } from '../../../../../../global-helpers/global.helpers';

type Properties = {
	t: any;
	liveBlog: LiveBlogModel;
	resetListInRedux: Function;
};

const LiveBlogListsComponent: FunctionComponent<Properties> = ({ t, liveBlog, resetListInRedux }) => {
	const [toggle, setToggle] = useState<boolean>(false);
	const [toggleListItems, setToggleListItems] = useState<boolean>(false);

	useEffect(() => {
		if (window.location.hash.includes('/smp/live-blogs/configuration/edit/')) {
			resetListInRedux();
			return () => resetListInRedux();
		}
	}, []);

	const toggleModal = () => {
		setToggle(!toggle);
	};

	return (
		<>
			<Row>
				<Col>
					<Label className='list-label' htmlFor={DATA_QA_ATTRIBUTES.LIST_CONTAINER}>
						<strong>{t('lists')}</strong>
					</Label>
				</Col>
			</Row>
			{toggle && <LiveBlogListsModal toggleModal={setToggle} setToggleListItemsModal={setToggleListItems} t={t} isOpen={toggle} />}
			{toggleListItems && (
				<LiveBlogListItemsModal
					liveBlog={liveBlog}
					toggleModal={setToggleListItems}
					setToggleListItemsModal={setToggleListItems}
					t={t}
					isOpen={toggleListItems}
				/>
			)}
			<Row>
				<Button className='list-button' color='primary' outline onClick={toggleModal}>
					<i className='fa fa-plus mx-1' />
					{t('add_to_list')}
				</Button>
			</Row>
			{isEditScreen() && (
				<LiveBlogListPresentIn t={t} liveBlogId={liveBlog.id ? liveBlog.id : ''} setToggleListItemsModal={setToggleListItems} />
			)}
		</>
	);
};

function mapDispatchToProps(dispatch: Function) {
	return {
		resetListInRedux: () => dispatch(updateListInRedux(null)),
	};
}

export default connect(null, mapDispatchToProps)(LiveBlogListsComponent);
