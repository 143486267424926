import React, { FunctionComponent, useEffect, useState } from 'react';
import './audit-log-style.scss';
import { auditLogService } from '../../../App';
import AuditLogModel from '../../../models/v2/audit-log/audit-log.model';
import { Button, Col, Label, Row } from 'reactstrap';
import { store } from '../../../store/store';
import momentTz from 'moment-timezone';
import ReactPaginate from 'react-paginate';
import PaginationMeta from '../../../models/pagination/PaginationMeta';
import { AdminSelect } from './admin-select.component';
import AdminModel from '../../../models/v2/admin/admin.model';
import { PaginationProps } from './services/audit-log.service';
import { ContentTypes, ContentTypeSelect } from './content-type-select.component';
import { ActionTypes, ActionTypeSelect } from './action-select.component';
import { DatePicker } from '../content-analytics/date-picker/date-time.component';

type Props = {
	t: any;
};

export const AuditLogPage: FunctionComponent<Props> = ({ t }) => {
	const [loading, setLoading] = useState<boolean>(true);
	const [auditLog, setAuditLog] = useState<AuditLogModel[]>([]);
	const [currentPage, setCurrentPage] = useState<string>('1');
	const [pagination, setPagination] = useState({} as PaginationMeta);
	const [admin, setAdmin] = useState<AdminModel>({} as AdminModel);
	const [contentType, setContentType] = useState({ value: 'Article', label: t('article') });
	const [action, setAction] = useState({ value: 'create', label: t('created') });
	const [dateRange, setDateRange] = useState([] as any[]);
	const [dateFilterApplied, setDateFilterApplied] = useState<boolean>(false);

	const project = store.getState().project.currentProject;
	momentTz.tz.setDefault(project.timezone);

	useEffect(() => {
		requestActionLogData(setAuditLog, currentPage);
	}, []);

	useEffect(() => {
		setCurrentPage('1');
	}, [admin, contentType, action, dateRange[0], dateRange[1]]);

	const requestActionLogData = (setAuditLog: (arg: AuditLogModel[]) => void, page: string) => {
		setLoading(true);
		auditLogService
			.getActionLogForAdmin(
				admin && admin.id && admin.id,
				action ? action.value : '',
				contentType ? contentType.value : '',
				dateFilterApplied && dateRange[0] && dateRange[0].length > 0 && dateRange[0],
				dateFilterApplied && dateRange[1] && dateRange[1].length > 0 && dateRange[1],
				page,
			)
			.then((action: AuditLogModel[]) => {
				setAuditLog(action);
				setPagination(auditLogService.pagination);
				setLoading(false);
			})
			.catch((e: any) => {
				console.error(e);
			});
	};

	const onPageChange = (page: any) => {
		setCurrentPage(page);
		requestActionLogData(setAuditLog, page);
	};

	return (
		<div className='card'>
			<div className='card-header'>
				<label style={{ maxWidth: '80%' }} className='m-0 ml-2 font-weight-bold text-truncate'>
					{t('audit_log')}
				</label>
			</div>
			<div className='card-body'>
				<div className='audit-log-filter-row'>
					<Col>
						<AuditLogAdminFilter
							selectedEntity={admin}
							onEntitySelect={(entity: any) => setAdmin(entity)}
							onContentTypeSelect={(entity: any) => setContentType(entity)}
							onActionTypeSelect={(entity: any) => setAction(entity)}
							t={t}
						/>
					</Col>
					<Col>
						<AuditLogContentTypeFilter
							disabled={!admin.id ? true : false}
							selectedEntity={contentType}
							onContentTypeSelect={(entity: any) => setContentType(entity)}
							t={t}
						/>
					</Col>
					<Col>
						<AuditLogActionTypeFilter
							disabled={!admin.id ? true : false}
							selectedEntity={action}
							onActionTypeSelect={(entity: any) => setAction(entity)}
							t={t}
						/>
					</Col>
					<Col>
						<div className='audit-entity-select'>
							<Label className='font-weight-bold'>{t('date_from_to')}</Label>
							<DatePicker
								onDateChange={(date: any[], dateFilterApplied?: boolean) => {
									setDateFilterApplied(dateFilterApplied ? dateFilterApplied : false);
									setDateRange(date);
								}}
								hidePredefinedFilters={true}
								auditLogPage={true}
								resetButton={true}
							/>
						</div>
					</Col>
					<Col>
						<Button
							type='button'
							color='primary'
							className='audit-log-search-button'
							id='audit-log-filters-search-button'
							onClick={() => requestActionLogData(setAuditLog, currentPage)}
						>
							<i className={'fa fa-search'}>&nbsp;</i>
							{t('search')}
						</Button>
					</Col>
				</div>
				<div className={loading ? 'loading-overlay-h-auto' : ''}>
					<div>
						<Row className='font-weight-bold py-2 border-top border-bottom'>
							<Col className='text-left w-40'>{t('type')}</Col>
							<Col className='text-center w-40 '>{t('admin')}</Col>
							<Col className='text-center w-40 '>{t('title')}</Col>
							<Col className='text-center w-40 '>{t('content_type')}</Col>
							<Col className='text-right w-60 '>{t('action_time')}</Col>
						</Row>
						{auditLog.length < 1 && (
							<div>
								<h5 className='d-flex justify-content-center mb-2 mt-5'>{t('no_data_found')}</h5>
							</div>
						)}

						{auditLog &&
							auditLog.length > 0 &&
							auditLog.map((action: AuditLogModel, index) => {
								return (
									<Row
										key={action && action.content && action.content.id + Math.random()}
										className={`py-2 border-bottom align-items-center ${index % 2 === 1 ? '' : 'bg-light'}`}
									>
										<Col className='text-left'>
											<div className='d-flex'>
												<div className={`audit-log-badge ${auditLogService.setBadgeColor(action && action.action)} text-white text-center`}>
													{window.innerWidth > 556 && action && action.action}
												</div>
											</div>
										</Col>
										<Col className='text-center text-truncate'>{action && action.actor && action.actor.name}</Col>
										<Col className='text-center text-truncate'>{action && action.content && action.content.name}</Col>
										<Col className='text-center'>{action && action.content && action.content.type}</Col>
										<Col className='text-right  '>
											{momentTz(
												action && action.content && auditLogService.checkIfActionTimeIsObject(action.action_time)
													? action.action_time.date
													: action.action_time,
											).format('DD MMM YYYY, HH:mm')}
										</Col>
									</Row>
								);
							})}
					</div>
				</div>
				<div className='py-2'>
					<ReactPaginate
						{...PaginationProps(t, pagination.currentPage)}
						pageCount={pagination.totalPages ? pagination.totalPages : 0}
						onPageChange={(data: any) => onPageChange(data.selected + 1)}
					/>
				</div>
			</div>
		</div>
	);
};

const AuditLogAdminFilter: FunctionComponent<{
	selectedEntity: any;
	onEntitySelect: (entity: any) => void;
	onContentTypeSelect: (entity: any) => void;
	onActionTypeSelect: (entity: any) => void;
	t: any;
}> = ({ selectedEntity, onEntitySelect, onContentTypeSelect, onActionTypeSelect, t }) => {
	return (
		<div className='audit-entity-select'>
			<Label className='font-weight-bold'>{t('admin')}</Label>
			<AdminSelect
				className='admin-select mb-4'
				value={selectedEntity}
				onAdminSelect={(admin: AdminModel) => onEntitySelect(admin)}
				setDefaultContentType={() => onContentTypeSelect(ContentTypes(t)[0])}
				setDefaultActionType={() => onActionTypeSelect(ActionTypes(t)[0])}
				t={t}
			/>
		</div>
	);
};

const AuditLogContentTypeFilter: FunctionComponent<{
	selectedEntity: any;
	onContentTypeSelect: (entity: any) => void;
	t: any;
	disabled: boolean;
}> = ({ selectedEntity, onContentTypeSelect, t, disabled }) => {
	return (
		<div className='audit-entity-select'>
			<Label className='font-weight-bold'>{t('content_type')}</Label>
			<ContentTypeSelect
				disabled={disabled}
				className='admin-select mb-4'
				value={selectedEntity}
				onContentTypeSelect={(content: any) => onContentTypeSelect(content)}
				t={t}
			/>
		</div>
	);
};

const AuditLogActionTypeFilter: FunctionComponent<{
	selectedEntity: any;
	onActionTypeSelect: (entity: any) => void;
	t: any;
	disabled: boolean;
}> = ({ selectedEntity, onActionTypeSelect, t, disabled }) => {
	return (
		<div className='audit-entity-select'>
			<Label className='font-weight-bold'>{t('action')}</Label>
			<ActionTypeSelect
				disabled={disabled}
				className='admin-select mb-4'
				value={selectedEntity}
				onActionTypeSelect={(content: any) => onActionTypeSelect(content)}
				t={t}
			/>
		</div>
	);
};
