import HttpService from '../../../../../services/rest/HttpService';
import SportsConnectionsHttpService from '../../advanced-content-filter/subcomponents/v2/services/sports-connections-http.service';

export const setCorrectSportsSearch = (type: string, lang: string, search: string, sportType?: any, availableEntities?: any) => {
	switch (type) {
		case 'football': {
			return HttpService.getFootballData(search, lang, 'tournament,player,team,president,venue,coach');
		}
		default:
			return SportsConnectionsHttpService.getSportsData(search, sportType, availableEntities, lang);
	}
};

export const requests = {
	tags: (page: number, search: string, header: any) => HttpService.get(`/tags/search?page=${page}&query=${search}`, null, header),
	sports_connections: (search: string, name: string, language: string, sportType?: any, availableEntities?: any) =>
		setCorrectSportsSearch(name, language, search, sportType, availableEntities),
};
