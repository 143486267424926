import ErrorResponseModel from '../../models/error-response/ErrorResponseModel';

export const USER_PROJECTS_RECEIVED = '[ACTION] USER_PROJECTS_RECEIVED';
export const USER_PROJECTS_FAILED = '[ACTION] USER_PROJECTS_FAILED';

export const REQUEST_USER_BUNDLE_SUCCESS = '[ACTION] REQUEST_USER_BUNDLE_SUCCESS';
export const REQUEST_USER_BUNDLE = '[ACTION] REQUEST_USER_BUNDLE';
export const REQUEST_USER_BUNDLE_FAILED = '[ACTION] REQUEST_USER_BUNDLE_FAILED';

export function requestUserBundle(username: string, password: string) {
	return {
		type: REQUEST_USER_BUNDLE,
		payload: { username, password },
	};
}

export function requestUserBundleFailed(error: any) {
	return {
		type: REQUEST_USER_BUNDLE_FAILED,
		payload: ErrorResponseModel.builder().fromResponse(error).build(),
	};
}

export function userProjectsReceived(projects: any) {
	return {
		type: USER_PROJECTS_RECEIVED,
		payload: projects,
	};
}

export function userProjectsFailed(error: any) {
	return {
		type: USER_PROJECTS_FAILED,
		payload: ErrorResponseModel.builder().fromResponse(error).build(),
	};
}
