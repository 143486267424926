import React, { FunctionComponent, ChangeEvent, useEffect } from 'react';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import { DebounceInput } from 'react-debounce-input';
import { DATA_QA_ATTRIBUTES } from '../../constants/data-qa.attributes';
import { ReduxURLsProps } from '../../constants/redux-seo.attributes';
import { removeTextEmojis } from '../../helpers/slug.helper';
import { connect } from 'react-redux';
import { autoGenerateReduxPropsNamingForContentModels } from '../../../../../../services/content-models-service/ContentModelService';

type Properties = {
	t: any;
	seoTitle: string;
	contentTitle: string;
	isDisabled: boolean;
	onSeoChange: (data: string, propertyName: ReduxURLsProps) => void;
};

const SeoTitleInput: FunctionComponent<Properties> = ({ t, seoTitle, contentTitle, isDisabled, onSeoChange }) => {
	useEffect(() => {
		isDisabled && onSeoChange(removeTextEmojis(contentTitle), ReduxURLsProps.TITLE);
	}, [isDisabled, contentTitle]);

	const onSeoTitleChange = (event: ChangeEvent<HTMLInputElement>) => {
		const inputText = event.target.value || '';
		onSeoChange(removeTextEmojis(inputText), ReduxURLsProps.TITLE);
	};

	return (
		<Row>
			<Col>
				<FormGroup>
					<Label htmlFor={DATA_QA_ATTRIBUTES.TITLE}>{t('seo_title')}</Label>
					<DebounceInput
						id={DATA_QA_ATTRIBUTES.TITLE}
						disabled={isDisabled}
						debounceTimeout={300}
						type='text'
						className='form-control'
						placeholder={t('seo_title')}
						value={seoTitle}
						onChange={onSeoTitleChange}
					/>
				</FormGroup>
			</Col>
		</Row>
	);
};

function mapStateToProps(state: any) {
	const { tempPropertyNaming, nestedTempPropertyNaming } = autoGenerateReduxPropsNamingForContentModels();

	return {
		contentTitle: state[tempPropertyNaming][nestedTempPropertyNaming].title || '',
		seoTitle:
			(state[tempPropertyNaming][nestedTempPropertyNaming].seo && state[tempPropertyNaming][nestedTempPropertyNaming].seo.title) || '',
	};
}

export default connect(mapStateToProps)(SeoTitleInput);
