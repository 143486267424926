import LiveBlogTournamentSeasonResponseModel from '../../../models/seasons/live-blog-tournament-season.model';
import LiveBlogTournamentModel from '../../../models/tournaments/live-blog-tournament-model';

export const seasonsToOptions = (seasons: LiveBlogTournamentSeasonResponseModel[]) => {
	if (seasons && seasons.length > 0) {
		return seasons.map((season: LiveBlogTournamentSeasonResponseModel) => {
			return { value: season.id, label: season.name, data: season };
		});
	}

	return null;
};

export const tournamentToOptions = (tournaments: LiveBlogTournamentModel[]) => {
	if (tournaments && tournaments.length > 0) {
		return tournaments.map((tournament: LiveBlogTournamentModel) => {
			return { value: tournament.id, label: tournament.name, data: tournament };
		});
	}

	return null;
};
