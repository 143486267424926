import { EnumItemJson } from './EnumItemJson';
import EnumItem from './EnumItem';

export default class EnumItemBuilder {
	private json: EnumItemJson;

	constructor(enumItem?: EnumItem) {
		this.json = enumItem ? enumItem.toJSON() : ({} as EnumItemJson);
	}

	withId(id: string): EnumItemBuilder {
		this.json.id = id;

		return this;
	}

	withName(name: string): EnumItemBuilder {
		this.json.name = name;

		return this;
	}

	fromResponse(responseItem: any) {
		this.json.id = responseItem.id;
		this.json.name = responseItem.name;

		return this;
	}

	build(): EnumItem {
		return EnumItem.fromJSON(this.json);
	}
}
