import React from 'react';
import DugoutVideoModel from '../../../../../../models/dugout-videos/dugout-video/dugout-video.model';
import DangerouslySetInner from '../../../../social-feed-selection-sidebar/subcomponents/dangerously-set-inner.component';
import { noPreviewImage } from '../../../../../../constants/resource-constants';

type Properties = {
	t: any;
	isLoading: boolean;
	dugoutVideo: DugoutVideoModel;
};

const DugoutVideoView: React.FunctionComponent<Properties> = ({ isLoading, t, dugoutVideo }) => {
	if (dugoutVideo && dugoutVideo.thumbnail) {
		return (
			<div className={`${isLoading ? 'loading-overlay' : ''}`}>
				<div className='block-content'>
					<div className={`d-flex content-container`}>
						{dugoutVideo.thumbnail ? (
							<img className='content-image img-fluid mb-2 mb-md-0 rounded' alt={dugoutVideo.title} src={dugoutVideo.thumbnail} />
						) : (
							<img alt='' src={noPreviewImage} className='content-image img-fluid mb-2 mb-md-0 rounded' />
						)}
						{dugoutVideo.title && (
							<div className='gallery-title text-left'>
								<h6 className='m-0 p-0'>
									<DangerouslySetInner html={dugoutVideo.title} />
								</h6>
							</div>
						)}
					</div>
				</div>
			</div>
		);
	}

	return (
		<div className='position-relative block-content'>
			<img alt='' src={noPreviewImage} className='no-preview-image img-fluid mb-2 mb-md-0 rounded' />
		</div>
	);
};

export default DugoutVideoView;
