import React from 'react';
import { Button, Col, FormGroup, Label, Row } from 'reactstrap';
import { DebounceInput } from 'react-debounce-input';
import MainMedia from '../../../../../../models/mainmedia/MainMedia';
import { Properties, State } from './properties/MediaVideoEmbedProps';
import ErrorAsync from '../../../../BaseComponents/ErrorComponent/ErrorAsync';
import { amgVideosService, featuresService } from '../../../../../../App';
import { FeatureTypes } from '../../../../../../services/feature-service/features.enum';
import DugoutVideoModel from '../../../../../../models/dugout-videos/dugout-video/dugout-video.model';
import OnnetworkVideoModel from '../../../../../../models/onnetwork-videos/onnetwork-video.model';
import AmgItemDataModel from '../../../../Blocky/blocks/content/amg-block/models/amg-item-data.model';
import { ContentTypes, MainMediaTypes } from '../../../../../../constants/content-types';
import { MainMediaContentModal } from '../media-modals/main-media-content-modal.component';

function onEmbedCodeChange(value: string, onChange: (media: MainMedia) => any) {
	const media = MainMedia.builder()
		.withData({ embedCode: value })
		.withResourceSubtype('video')
		.withResourceType('embed')
		.withProvider('external')
		.build();
	onChange(media);
}

export default class MediaVideoEmbed extends React.Component<Properties, State> {
	constructor(props: Properties) {
		super(props);
		this.state = {
			showDugout: false,
			showOnnetwork: false,
			selectedDugoutVideo: DugoutVideoModel.builder().build(),
			selectedOnnetworkVideo: OnnetworkVideoModel.builder().build(),
			showAmgVideo: false,
			selectedAmgVideo: {} as AmgItemDataModel,
		};
	}

	selectDugoutVideo = (video: DugoutVideoModel) => {
		this.setState({
			...this.state,
			showDugout: false,
			selectedDugoutVideo: video,
			selectedOnnetworkVideo: OnnetworkVideoModel.builder().build(),
			selectedAmgVideo: {} as AmgItemDataModel,
		});

		if (video.embedCode) {
			onEmbedCodeChange(video.embedCode, this.props.onChange);
		}
	};

	selectOnnetworkVideo = (video: OnnetworkVideoModel) => {
		this.setState({
			...this.state,
			showOnnetwork: false,
			selectedOnnetworkVideo: video,
			selectedDugoutVideo: DugoutVideoModel.builder().build(),
			selectedAmgVideo: {} as AmgItemDataModel,
		});

		if (video.embedCode && video.embedCode.script) {
			onEmbedCodeChange(video.embedCode.script, this.props.onChange);
		}
	};

	selectAmgVideo = (video: AmgItemDataModel) => {
		this.setState({
			...this.state,
			selectedAmgVideo: video,
			showAmgVideo: false,
			selectedOnnetworkVideo: OnnetworkVideoModel.builder().build(),
			selectedDugoutVideo: DugoutVideoModel.builder().build(),
		});
		onEmbedCodeChange(
			video.mediaData && video.mediaData.entryId ? amgVideosService.constructAmgVideoContent(video) : '',
			this.props.onChange,
		);
	};

	toggleDugoutVideo = () => {
		this.setState({ ...this.state, showDugout: !this.state.showDugout });
	};

	toggleOnnetworkVideo = () => {
		this.setState({ ...this.state, showOnnetwork: !this.state.showOnnetwork });
	};

	toggleAmgVideo = () => {
		this.setState({ ...this.state, showAmgVideo: !this.state.showAmgVideo });
	};

	render() {
		const { t, onChange, embedCode, onRemove, index, blocks } = this.props;
		const { showDugout, showOnnetwork, showAmgVideo } = this.state;

		return (
			<div id='sidebar-media-video'>
				<Row className='mb-2'>
					<Col>
						<Label className='font-weight-bold'>{t('embed_video_title')}</Label>
						<Button className='pull-right bg-danger border-0 float-right' title={t('button_remove')} onClick={() => onRemove('embed', 'video')}>
							<i className='fa fa-trash'></i>
						</Button>
					</Col>
				</Row>
				<Row>
					<Col col='12' sm='12' md='12' className='mb-2'>
						<FormGroup>
							<DebounceInput
								className='form-control'
								element={'textarea'}
								rows={4}
								value={embedCode ? embedCode : ''}
								placeholder={t('embed_video_placeholder')}
								onChange={(event: any) => onEmbedCodeChange(event.target.value, onChange)}
							/>
						</FormGroup>
						<ErrorAsync t={t} errorType='main_media' mainMedia={`main_media.${index}.provider`} />
					</Col>
				</Row>

				{featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.DUGOUT_INTEGRATION) && (
					<>
						<Button
							className='m-0 p-0'
							color='link'
							onClick={this.toggleDugoutVideo}
							aria-expanded={showDugout}
							aria-controls='toggle-sidebar-dugout-video'
							id='toggle-sidebar-dugout-video'
						>
							{t('seacrh_toggle_dugout_videos')}
						</Button>
						<MainMediaContentModal
							blocks={blocks ? blocks : []}
							t={t}
							onSelect={this.selectDugoutVideo}
							isOpen={showDugout}
							toggleModal={this.toggleDugoutVideo}
							contentType={MainMediaTypes.DUGOUT_VIDEO}
						/>
						<Row>
							<Col className={'text-center my-2'}>
								<hr />
							</Col>
						</Row>
					</>
				)}
				{featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.ON_NETWORK_INTEGRATION) && (
					<>
						<Button
							className='m-0 p-0'
							color='link'
							onClick={this.toggleOnnetworkVideo}
							aria-expanded={showOnnetwork}
							aria-controls='toggle-sidebar-onnetwork-video'
							id='toggle-sidebar-onnetwork-video'
						>
							{t('seacrh_toggle_onnetwork_videos')}
						</Button>
						<MainMediaContentModal
							blocks={blocks ? blocks : []}
							t={t}
							onSelect={this.selectOnnetworkVideo}
							isOpen={showOnnetwork}
							toggleModal={this.toggleOnnetworkVideo}
							contentType={MainMediaTypes.ONNETWORK_VIDEO}
						/>
						<Row>
							<Col className={'text-center my-2'}>
								<hr />
							</Col>
						</Row>
					</>
				)}
				{/* {featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.AMG_INTEGRATION) && this.props.contentType !== ContentTypes.WIKI_PAGE && (
					<>
						<Button
							className='m-0 p-0'
							color='link'
							onClick={this.toggleAmgVideo}
							aria-expanded={showAmgVideo}
							aria-controls='toggle-sidebar-amg-video'
							id='toggle-sidebar-amg-video'
						>
							{t('search_toggle_amg_videos')}
						</Button>
						<MainMediaContentModal
							blocks={blocks ? blocks : []}
							t={t}
							onSelect={this.selectAmgVideo}
							isOpen={showAmgVideo}
							toggleModal={this.toggleAmgVideo}
							contentType={MainMediaTypes.AMG_VIDEO}
						/>
						<Row>
							<Col className={'text-center my-2'}>
								<hr />
							</Col>
						</Row>
					</>
				)} */}
			</div>
		);
	}
}
