import React from 'react';
import Select from 'react-select';
import EnumItem from '../../../../models/enum/EnumItem';
import HttpService from '../../../../services/rest/HttpService';
import { itemToOption, itemsToOptions } from '../../../Partials/Shared/react-select-helpers/react-select.helpers';
import { Col, Label } from 'reactstrap';
import { remapTranslationTypeFromResponse } from '../helpers/translations.helpers';
import { TranslationEntities } from '../helpers/translations.constants';

type Properties = {
	t: any;
	onSelect: (entity: EnumItem) => void;
};

type State = {
	translationEntities: EnumItem[];
	selectedEntity: EnumItem | null;
};

export default class TranslationEntitySelect extends React.Component<Properties, State> {
	constructor(props: Properties) {
		super(props);
		this.state = {
			translationEntities: [],
			selectedEntity: null,
		};
	}
	componentDidMount() {
		this.getEntities();
	}

	getEntities = () => {
		HttpService.instanceFootball()
			.get('/v2/translations/entities')
			.then((response: any) => {
				const entities = remapTranslationTypeFromResponse(response).filter(
					(entity: EnumItem) =>
						entity.name !== TranslationEntities.STAGE && entity.name !== TranslationEntities.SEASON && entity.name !== TranslationEntities.GROUP,
				);
				this.setState({ ...this.state, translationEntities: entities });
			})
			.catch((e: any) => e);
	};

	onEntityChange = (selection: any) => {
		this.setState({ ...this.state, selectedEntity: selection.data });
		this.props.onSelect(selection.data);
	};

	render() {
		const { selectedEntity, translationEntities } = this.state;

		return (
			<Col xs='6'>
				<Label>{this.props.t('entity')}</Label>
				<Select
					options={itemsToOptions(translationEntities, false, this.props.t, '')}
					value={itemToOption(selectedEntity, false, this.props.t, '')}
					inputId='translation-entity-select'
					onChange={this.onEntityChange}
					placeholder={this.props.t('select')}
				/>
			</Col>
		);
	}
}
