export const columnsToOption = (column: any, t: any) => {
	if (column) {
		return {
			value: column,
			label: t(`${column}_title`),
		};
	}

	return {};
};

export const selectionsToColumns = (selections: any[]) => {
	if (selections && selections.length > 0) {
		return selections.map((selection: any) => selection.value);
	}

	return [];
};

export const columnsToOptions = (columns: any[], t: any) => {
	if (columns && columns.length > 0) {
		return columns.map((column: string) => columnsToOption(column, t));
	}

	return [];
};

export const selectionsToColumnsV2 = (selections: any[]) => {
	if (selections && selections.length > 0) {
		return selections.map((selection: any) => selection.value);
	}

	return [];
};

export const columnsToOptionsV2 = (columns: any[], t: any) => {
	if (columns && columns.length > 0) {
		return columns.map((column: string) => columnsToOptionV2(column, t));
	}

	return [];
};

export const columnsToOptionV2 = (column: any, t: any) => {
	if (column) {
		return {
			value: column,
			label: t(`${column}_title`),
		};
	}

	return {};
};
