import React, { ChangeEvent, FunctionComponent } from 'react';
import './search.scss';
import { DebounceInput } from 'react-debounce-input';
import { useTranslation } from 'react-i18next';

type Props = {
	invokeFunc: (text: string) => void;
	fieldId: string;
	invokeFuncTime?: number;
	labelText?: string;
	placeholderText?: string;
	invokeFocus?: (isFocused: boolean) => void;
};

export const SearchInputDesign: FunctionComponent<Props> = ({
	invokeFunc,
	invokeFuncTime,
	fieldId,
	labelText,
	placeholderText,
	invokeFocus,
}) => {
	const { t } = useTranslation();
	const search = (event: ChangeEvent<HTMLInputElement>) => invokeFunc(event.target.value);

	const onFocus = (isFocused: boolean) => {
		if (invokeFocus) {
			invokeFocus(isFocused);
		}
	};

	return (
		<div className='custom-search-input-wrapper'>
			<DebounceInput
				element='input'
				type='text'
				id={fieldId}
				className='custom-search-input'
				placeholder={placeholderText || t('search')}
				debounceTimeout={invokeFuncTime || 500}
				onChange={search}
				onFocus={() => onFocus(true)}
				onBlur={() => onFocus(false)}
			/>
			<i className='custom-search-input-icon fas fa-search'></i>
		</div>
	);
};

export default SearchInputDesign;
