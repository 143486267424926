import { AdvancedFilterJson } from './advanced-content-filter.json';
import AdvancedFilterBuilder from './advanced-content-filter.builder';
import Related from '../../../../../models/related/Related';
import Category from '../../../../../models/category/Category';
import UserModel from '../subcomponents/user-select/models/user.model';
import ContentAttributes from '../../../../../models/content-attributes/content-attributes-model';

export default class AdvancedFilterModel {
	readonly searchText: string;
	readonly relatedSports: Related[];
	readonly relatedTags: Related[];
	readonly category: Category;
	readonly createdBy: UserModel;
	readonly date: string[];
	readonly imageOrigin: ContentAttributes;
	readonly videoOrigin: ContentAttributes;
	readonly tournament: Related;
	readonly language: any;
	readonly sportsType: any;
	readonly type: ContentAttributes[];
	readonly status: string = '';
	readonly properties?: string[];

	private constructor(
		searchText: string,
		relatedSports: Related[],
		relatedTags: Related[],
		category: Category,
		createdBy: UserModel,
		date: string[],
		imageOrigin: ContentAttributes,
		videoOrigin: ContentAttributes,
		tournament: Related,
		language: any,
		sportsType: any,
		type: ContentAttributes[],
		status: string,
		properties: string[],
	) {
		this.searchText = searchText;
		this.relatedSports = relatedSports;
		this.relatedTags = relatedTags;
		this.category = category;
		this.createdBy = createdBy;
		this.date = date;
		this.imageOrigin = imageOrigin;
		this.videoOrigin = videoOrigin;
		this.tournament = tournament;
		this.language = language;
		this.sportsType = sportsType;
		this.type = type;
		this.status = status;
		this.properties = properties;
	}

	toJSON(): AdvancedFilterJson {
		return {
			searchText: this.searchText,
			relatedSports: this.relatedSports,
			relatedTags: this.relatedTags,
			category: this.category,
			createdBy: this.createdBy,
			date: this.date,
			imageOrigin: this.imageOrigin,
			videoOrigin: this.videoOrigin,
			tournament: this.tournament,
			language: this.language,
			sportsType: this.sportsType,
			type: this.type,
			status: this.status,
			properties: this.properties,
		};
	}

	static fromJSON(json: AdvancedFilterJson): AdvancedFilterModel {
		return new AdvancedFilterModel(
			json.searchText,
			json.relatedSports,
			json.relatedTags,
			json.category,
			json.createdBy,
			json.date,
			json.imageOrigin,
			json.videoOrigin,
			json.tournament,
			json.language,
			json.sportsType,
			json.type,
			json.status,
			json.properties || [],
		);
	}

	static builder(content?: AdvancedFilterModel): AdvancedFilterBuilder {
		return new AdvancedFilterBuilder(content);
	}
}
