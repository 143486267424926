import moment from 'moment';
import * as React from 'react';
import { Properties, State } from './properties/matches.properties';
import { Scrollbars } from 'react-custom-scrollbars';
import { Col, FormGroup, Input, Label, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import { MatchModel } from '../../../Sidebar/tags/subcomponents/matches/models/match.model';

export default class Matches extends React.Component<Properties, State> {
	constructor(props: any) {
		super(props);
		this.state = {
			activeTab: new Array(4).fill('1'),
			matches: [...props.matchesFinished, ...props.matchesNotStarted, ...this.props.matchesInterrupted].filter((match) => match.isSelected),
		};
	}

	toggle = (tabPane: number, tab: string) => {
		const newArray = this.state.activeTab.slice();
		newArray[tabPane] = tab;
		this.setState({
			activeTab: newArray,
		});
	};

	onMatchSelect = (match: MatchModel) => {
		this.props.onMatchSelect(match);
	};

	onMatchesSelect = (match: MatchModel, event: any) => {
		let { matches } = this.state;
		const { onMatchesSelect } = this.props;

		matches = this.handleMatchCheck(match, matches, event.target.checked);
		this.updateMatchesState(matches);
		this.updateCheckAllMatchesState(false, matches);

		if (onMatchesSelect) {
			onMatchesSelect(matches);
		}
	};

	selectAllMatches = (selectedMatches: any, event: any) => {
		const { onMatchesSelect } = this.props;

		selectedMatches = this.handleMatchesCheck(selectedMatches, event.target.checked);

		if (onMatchesSelect) {
			onMatchesSelect(selectedMatches);
		}
	};

	handleMatchCheck(match: MatchModel, matches: MatchModel[], checked: boolean) {
		if (checked) {
			matches.push(match);
		} else {
			matches = matches.filter((item) => item.id !== match.id);
		}

		return matches;
	}

	handleMatchesCheck = (matches: MatchModel[], checked: boolean) => {
		this.updateCheckAllMatchesState(checked, matches);
		let selectedMatches = [];

		if (!checked) {
			let matchIds = matches.concat(matches).map((match: MatchModel) => match.id);
			const onlyUnique = (value: any, index: any, self: any) => self.indexOf(value) === index;
			const uniqueIds = matchIds.filter(onlyUnique);
			selectedMatches = this.state.matches.filter((match: MatchModel) => !uniqueIds.includes(match.id));
		} else {
			selectedMatches = this.state.matches.concat(matches);
		}

		return selectedMatches;
	};

	updateMatchesState = (matches: MatchModel[]) => this.setState({ ...this.state, matches });

	updateCheckAllMatchesState = (checked: boolean, selectedMatches: MatchModel[]) => {
		this.state.activeTab[0] === '1'
			? this.props.updateCheckAllMatchesState(checked, 'upcoming', selectedMatches, this.state.activeTab[0])
			: this.props.updateCheckAllMatchesState(checked, 'finished', selectedMatches, this.state.activeTab[0]);
	};

	applyBoldCss(teamName: string) {
		return this.props.selectedTeamNames.includes(teamName) ? 'font-weight-bold' : '';
	}

	applyInProgressClass(type: string) {
		return type === 'inprogress' ? 'border-pulsate-right' : '';
	}

	printMinutesIfMatchInProgress(match: MatchModel) {
		return match.type === 'inprogress' ? `(${match.minutes}")` : '';
	}

	toggleGoals(match: MatchModel) {
		return match.type === 'inprogress' ? ` ${match.goalHome}:${match.goalAway} ` : ` - `;
	}

	renderMatchesFinished(matches: MatchModel[], isMulti: boolean) {
		const { t } = this.props;

		if (matches.length < 1) {
			return <div className='text-center p-3'>{t('no_matches_found')}</div>;
		}

		return (
			<>
				{isMulti && (
					<FormGroup className='mb-2 pb-2 border-bottom' check>
						<Input
							className='form-check-input'
							onChange={(event) => this.selectAllMatches(matches, event)}
							type='checkbox'
							checked={matches[0].isSelected && this.props.finishedMatchesAreSelected}
							id={`select-all-finished-matches${matches.length}`}
							name={t('select_all_matches')}
						/>
						<Label className='form-check-label' check htmlFor={`select-all-finished-matches${matches.length}`}>
							{t('select_all_matches')}
						</Label>
					</FormGroup>
				)}
				{matches.map((match) => {
					return (
						<FormGroup key={`key-${match.id}`} className='mb-2 pb-2 border-bottom' check>
							<Input
								className='form-check-input'
								onChange={(event) => {
									if (isMulti) {
										this.onMatchesSelect(match, event);
									} else {
										this.onMatchSelect(match);
									}
								}}
								// @ts-ignore
								type={`${isMulti ? 'checkbox' : 'radio'}`}
								id={`${match.id}`}
								name={`inline-radio-${match.id}`}
								checked={match.isSelected}
							/>
							<Label className='form-check-label' check htmlFor={`${match.id}`}>
								{`${moment(match.finishedAt).format('DD MMM YYYY')} - `}
								<span className={this.applyBoldCss(match.homeTeam)}>{match.homeTeam}</span>
								{` ${match.goalHome}:${match.goalAway} `}
								<span className={this.applyBoldCss(match.awayTeam)}>{match.awayTeam}</span>
							</Label>
						</FormGroup>
					);
				})}
			</>
		);
	}

	renderMatchesNotStarted(matches: MatchModel[], isMulti: boolean) {
		const { t } = this.props;

		if (matches.length < 1) {
			return <div className='text-center p-3'>{t('no_matches_found')}</div>;
		}

		return (
			<>
				{isMulti && (
					<FormGroup className='mb-2 pb-2 border-bottom' check>
						<Input
							className='form-check-input'
							onChange={(event) => this.selectAllMatches(matches, event)}
							type='checkbox'
							checked={matches[0].isSelected && this.props.upcomingMatchesAreSelected}
							id={`select-all-upcoming-matches${matches.length}`}
							name={t('select_all_matches')}
						/>
						<Label className='form-check-label' check htmlFor={`select-all-upcoming-matches${matches.length}`}>
							{t('select_all_matches')}
						</Label>
					</FormGroup>
				)}
				{matches.map((match) => {
					return (
						<FormGroup key={`key-${match.id}`} className={`mb-2 pb-2 border-bottom ${this.applyInProgressClass(match.type)}`} check>
							<Input
								className='form-check-input'
								onChange={(event: any) => {
									if (isMulti) {
										this.onMatchesSelect(match, event);
									} else {
										this.onMatchSelect(match);
									}
								}}
								// @ts-ignore
								type={`${isMulti ? 'checkbox' : 'radio'}`}
								id={`${match.id}`}
								checked={match.isSelected}
								name={`inline-radio-${match.id}`}
								value={match.id}
							/>
							<Label className='form-check-label' check htmlFor={`${match.id}`}>
								{`${moment(match.startTime).format('DD MMM YYYY')} - `}
								<span className={this.applyBoldCss(match.homeTeam)}>{match.homeTeam}</span>
								{this.toggleGoals(match)}
								<span className={this.applyBoldCss(match.awayTeam)}>{match.awayTeam}</span> {this.printMinutesIfMatchInProgress(match)}
							</Label>
						</FormGroup>
					);
				})}
			</>
		);
	}

	renderMatchesInterrupted(matches: MatchModel[], isMulti: boolean) {
		const { t } = this.props;

		if (matches.length < 1) {
			return <div className='text-center p-3'>{t('no_matches_found')}</div>;
		}

		return (
			<>
				{isMulti && (
					<FormGroup className='mb-2 pb-2 border-bottom' check>
						<Input
							className='form-check-input'
							onChange={(event) => this.selectAllMatches(matches, event)}
							type='checkbox'
							checked={matches[0].isSelected && this.props.interruptedMatchesAreSelected}
							id={`select-all-interrupted-matches${matches.length}`}
							name={t('select_all_matches')}
						/>
						<Label className='form-check-label' check htmlFor={`select-all-interrupted-matches${matches.length}`}>
							{t('select_all_matches')}
						</Label>
					</FormGroup>
				)}
				{matches.map((match) => {
					return (
						<FormGroup key={`key-${match.id}`} className={`mb-2 pb-2 border-bottom ${this.applyInProgressClass(match.type)}`} check>
							<Input
								className='form-check-input'
								onChange={(event: any) => {
									if (isMulti) {
										this.onMatchesSelect(match, event);
									} else {
										this.onMatchSelect(match);
									}
								}}
								// @ts-ignore
								type={`${isMulti ? 'checkbox' : 'radio'}`}
								id={`${match.id}`}
								checked={match.isSelected}
								name={`inline-radio-${match.id}`}
								value={match.id}
							/>
							<Label className='form-check-label' check htmlFor={`${match.id}`}>
								{`${moment(match.startTime).format('DD MMM YYYY')} - `}
								<span className={this.applyBoldCss(match.homeTeam)}>{match.homeTeam}</span>
								{this.toggleGoals(match)}
								<span className={this.applyBoldCss(match.awayTeam)}>{match.awayTeam}</span> {this.printMinutesIfMatchInProgress(match)}
							</Label>
						</FormGroup>
					);
				})}
			</>
		);
	}

	render() {
		const { matchesFinished, matchesNotStarted, matchesInterrupted, isMulti, t, hideMatchStatusTabs } = this.props;
		return (
			<div id='match-tagging'>
				<Row>
					<Col>
						<Nav tabs className={''}>
							<NavItem>
								<NavLink
									active={this.state.activeTab[0] === '1'}
									onClick={() => {
										this.toggle(0, '1');
									}}
								>
									<i className='fa fa-calendar'></i> {t('upcoming_matches')}
								</NavLink>
							</NavItem>
							<NavItem>
								{!hideMatchStatusTabs && (
									<NavLink active={this.state.activeTab[0] === '2'} onClick={() => this.toggle(0, '2')}>
										<i className='fa fa-calendar-times-o'></i> {t('past_matches')}
									</NavLink>
								)}
							</NavItem>
							<NavItem>
								{!hideMatchStatusTabs && (
									<NavLink active={this.state.activeTab[0] === '3'} onClick={() => this.toggle(0, '3')}>
										<i className='fa fa-calendar-minus-o'></i> {t('interrupted_matches')}
									</NavLink>
								)}
							</NavItem>
						</Nav>
						<TabContent activeTab={this.state.activeTab[0]}>
							<TabPane tabId='1' className='p-2 pb-0 bg-white'>
								<FormGroup className='mt-1'>
									<Scrollbars style={{ height: 300 }}>{this.renderMatchesNotStarted(matchesNotStarted, isMulti)}</Scrollbars>
								</FormGroup>
							</TabPane>
							<TabPane tabId='2' className='p-2 pb-0 bg-white'>
								{!hideMatchStatusTabs && (
									<FormGroup className='mt-1'>
										<Scrollbars style={{ height: 300 }}>{this.renderMatchesFinished(matchesFinished, isMulti)}</Scrollbars>
									</FormGroup>
								)}
							</TabPane>
							<TabPane tabId='3' className='p-2 pb-0 bg-white'>
								{!hideMatchStatusTabs && (
									<FormGroup className='mt-1'>
										<Scrollbars style={{ height: 300 }}>{this.renderMatchesInterrupted(matchesInterrupted, isMulti)}</Scrollbars>
									</FormGroup>
								)}
							</TabPane>
						</TabContent>
					</Col>
				</Row>
			</div>
		);
	}
}
