import * as React from 'react';
import { useEffect, useState } from 'react';
import FootballConnectionsContainer from '../../Sidebar/tags/subcomponents/football-connections/football-connections-container';
import { Col, FormGroup, Label } from 'reactstrap';
import AdvancedFilterModel from './models/advanced-content-filter.model';
import ContentTagsContainer from '../../Sidebar/tags/subcomponents/content-tags/content-tags-container';
import CategorySelectContainer from '../../Sidebar/GeneralContentAttributes/subcomponents/CategorySelect/CategorySelectContainer';
import Category from '../../../../models/category/Category';
import Related from '../../../../models/related/Related';
import UserModel from './subcomponents/user-select/models/user.model';
import { ConditionalRenderContext } from '../../BaseComponents/ConditionalRenderWraper/ConditionalRenderWrapper';
import UserSelectContainer from './subcomponents/user-select/user-select-container';
import OriginSelect from './subcomponents/origin-select/origin-select-container';
import ContentAttributes from '../../../../models/content-attributes/content-attributes-model';
import Project from '../../../../models/project/Project';
import { SportsTypeSelect } from './subcomponents/v2/sports-select/sports-type-select.component';
import TournamentSelect from './subcomponents/tournament-select/tournament-select';
import { featuresService, multiLingualService, sportsConnectionsService } from '../../../../App';
import SportsTypesModel from '../../../../models/v2/sports-types/sports-types.model';
import { CompetitionSelect } from './subcomponents/v2/sports-select/competition-select';
import { SportsConnectionsContainer } from './subcomponents/v2/sports-select/sports-connections-container';
import { FeatureTypes } from '../../../../services/feature-service/features.enum';
import TypesMultiSelect from '../../Sidebar/GeneralContentAttributes/subcomponents/type-select/TypeSelectMultiContainer';
import StatusSelect from '../../Sidebar/GeneralContentAttributes/subcomponents/status-select/status-select';
import PropertyMultiSelect from './subcomponents/properties-select/PropertySelectMultiContainer';
import { ContentTypes } from '../../../../constants/content-types';

type Properties = {
	currentProject: Project;
	t: any;
	updateAdvancedSearchText: (advancedContentModel: AdvancedFilterModel) => any;
	allCategories: Category[];
	profileId: string;
	filtersInSidebar: boolean;
	imageOrigins: ContentAttributes[];
	videoOrigins: ContentAttributes[];
	showOriginSelect: boolean;
	calledFrom?: string;
	advancedContentModel: AdvancedFilterModel;
	contentType: ContentTypes;
	articleTypes: ContentAttributes[];
	showTypeSelect: boolean;
	statuses: ContentAttributes[];
	sports?: SportsTypesModel[];
};

const AdvancedFiltersFields: React.FunctionComponent<Properties> = (props) => {
	const [sportsType, setSportsType] = useState<SportsTypesModel>(
		props.advancedContentModel.sportsType && props.advancedContentModel.sportsType.sport ? props.advancedContentModel.sportsType : {},
	);
	let langCode = multiLingualService.checkIfProjectIsMultiLingual(props.currentProject.languages)
		? props.currentProject.languages.defaultLanguageCode.languageCode
		: props.currentProject.language;

	useEffect(() => {
		setSportsType(
			props.advancedContentModel.sportsType && props.advancedContentModel.sportsType.sport
				? props.advancedContentModel.sportsType
				: new SportsTypesModel(),
		);
	}, [props.advancedContentModel]);

	const onSportsDataSelect = (value: Related[]) => {
		const advancedContentModel = AdvancedFilterModel.builder(props.advancedContentModel).withRelatedSports(value).build();
		props.updateAdvancedSearchText(advancedContentModel);
	};

	const onSportsTypeSelect = (type: any) => {
		setSportsType(type);
		const advancedContentModel = AdvancedFilterModel.builder(props.advancedContentModel)
			.withSportsType(type)
			.withRelatedSports([])
			.withTournament({} as Related)
			.build();
		props.updateAdvancedSearchText(advancedContentModel);
	};

	const onTournamentDataSelect = (value: Related) => {
		const advancedContentModel = AdvancedFilterModel.builder(props.advancedContentModel).withTournament(value).build();
		props.updateAdvancedSearchText(advancedContentModel);
	};

	const onTagsDataSelect = (tags: Related[]) => {
		const advancedContentModel = AdvancedFilterModel.builder(props.advancedContentModel).withRelatedTags(tags).build();
		props.updateAdvancedSearchText(advancedContentModel);
	};

	const onCategorySelect = (category: Category) => {
		const advancedContentModel = AdvancedFilterModel.builder(props.advancedContentModel).withCategory(category).build();
		props.updateAdvancedSearchText(advancedContentModel);
	};

	const onUserChange = (user: UserModel) => {
		const advancedContentModel = AdvancedFilterModel.builder(props.advancedContentModel).withCreatedBy(user).build();
		props.updateAdvancedSearchText(advancedContentModel);
	};

	const onOriginChange = (origin: ContentAttributes) => {
		const advancedContentModel = AdvancedFilterModel.builder(props.advancedContentModel).withOrigin(origin, contentType).build();
		props.updateAdvancedSearchText(advancedContentModel);
	};

	const onTypeChange = (type: ContentAttributes[]) => {
		const advancedContentModel = AdvancedFilterModel.builder(props.advancedContentModel).withType(type).build();
		props.updateAdvancedSearchText(advancedContentModel);
	};

	const onStatusSelect = (status: string) => {
		const advancedContentModel = AdvancedFilterModel.builder(props.advancedContentModel).withStatus(status).build();
		props.updateAdvancedSearchText(advancedContentModel);
	};

	const onPropertySelect = (properties: string[]) => {
		const advancedContentModel = AdvancedFilterModel.builder(props.advancedContentModel).withProperties(properties).build();

		props.updateAdvancedSearchText(advancedContentModel);
	};

	const {
		currentProject,
		t,
		filtersInSidebar,
		showOriginSelect,
		showTypeSelect,
		articleTypes,
		calledFrom,
		advancedContentModel,
		contentType,
		statuses,
		sports,
	} = props;

	const notImageListing = ![ContentTypes.IMAGE, ContentTypes.IMAGO_IMAGE, ContentTypes.GETTY].includes(contentType);

	return (
		<>
			{!filtersInSidebar && (
				<Col col='6' sm='12' md={`${filtersInSidebar ? '0' : '4'}`}>
					<FormGroup>
						<Label htmlFor='tags'>{t('tags')}</Label>
						<ContentTagsContainer related={advancedContentModel.relatedTags} onSelect={onTagsDataSelect} currentProject={currentProject} t={t} />
					</FormGroup>
				</Col>
			)}
			{!filtersInSidebar && (
				<Col col='6' sm='12' md={`${filtersInSidebar ? '0' : '4'}`}>
					<FormGroup key={'formgroup-select-category-key'}>
						<Label htmlFor='title'>{t('categories')}</Label>
						<CategorySelectContainer
							inputId='advanced-filters-category-select'
							t={t}
							categories={props.allCategories}
							adminCategories={props.allCategories}
							selectedCategory={
								advancedContentModel.category && advancedContentModel.category.id ? advancedContentModel.category : Category.builder().build()
							}
							onCategorySelect={onCategorySelect}
							isClearable={true}
						/>
					</FormGroup>
				</Col>
			)}
			{showOriginSelect && (
				<Col col='6' sm='12' md={`${filtersInSidebar ? '0' : '4'}`}>
					<FormGroup>
						<Label>{t('origins')}</Label>
						<OriginSelect
							selectedOrigin={contentType === ContentTypes.VIDEO ? advancedContentModel.videoOrigin : advancedContentModel.imageOrigin}
							onOriginSelect={onOriginChange}
							origins={contentType === ContentTypes.VIDEO ? props.videoOrigins : props.imageOrigins}
							isClearable={true}
							t={t}
						/>
					</FormGroup>
				</Col>
			)}
			{showTypeSelect && articleTypes && articleTypes.length > 0 && (
				<Col col='6' sm='12' md={`${filtersInSidebar ? '0' : '4'}`}>
					<FormGroup key={'formgroup-types-key'}>
						<Label>{t('type')}</Label>
						<TypesMultiSelect t={t} isClearable onTypeSelect={onTypeChange} types={articleTypes} selectedTypes={advancedContentModel.type} />
					</FormGroup>
				</Col>
			)}
			{notImageListing && (
				<Col col='6' sm='12' md={`${filtersInSidebar ? '0' : '4'}`}>
					<FormGroup key={'formgroup-properties-key'}>
						<Label>{t('properties')}</Label>
						<PropertyMultiSelect
							t={t}
							isClearable
							onPropertySelect={onPropertySelect}
							selectedProperties={advancedContentModel.properties || []}
							contentType={contentType}
						/>
					</FormGroup>
				</Col>
			)}
			<ConditionalRenderContext.Consumer>
				{(value) => {
					return (
						value.hasReadPermission && (
							<Col col='6' sm='12' md={`${filtersInSidebar ? '0' : '4'}`}>
								<FormGroup key={'formgroup-select-author-key'}>
									<Label htmlFor='created_by'>
										{calledFrom === 'image-listing' || calledFrom === 'main-media' ? t('uploaded_by') : t('created_by')}
									</Label>
									<UserSelectContainer
										selectedUser={advancedContentModel.createdBy}
										onUserSelect={onUserChange}
										isClearable={true}
										profileId={props.profileId}
										t={t}
										currentProject={currentProject}
									/>
								</FormGroup>
							</Col>
						)
					);
				}}
			</ConditionalRenderContext.Consumer>
			<Col col='6' sm='12' md={`${filtersInSidebar ? '0' : '4'}`}>
				<FormGroup>
					<SportsTypeSelect sports={sports} selectedSportsType={advancedContentModel.sportsType} onSportsTypeSelect={onSportsTypeSelect} t={t} />
				</FormGroup>
			</Col>
			{/*{//TODO remove is football selected check when the football data is migrated to sports-search-a}*/}
			{featuresService.areFeaturesEnabled([FeatureTypes.FOOTBALL, FeatureTypes.FOOTBALL_CONNECTIONS]) &&
			sportsConnectionsService.isFootballSelected(sportsType.sport) ? (
				<Col col='6' sm='12' md={`${filtersInSidebar ? '0' : '4'}`}>
					<FormGroup>
						<Label htmlFor='football-connections'>{t('football_connections')}</Label>
						<FootballConnectionsContainer
							related={advancedContentModel.relatedSports}
							inputId={`advanced-filters-football-connections-select-${calledFrom ? calledFrom : ''}`}
							onSelect={onSportsDataSelect}
							footballApiUrl={currentProject.footballApiUrl}
							allowMoreFootballConnections={false}
							t={t}
							contentLanguage={langCode}
						/>
					</FormGroup>
				</Col>
			) : (
				sportsType.sport !== '' && (
					<Col col='6' sm='12' md={`${filtersInSidebar ? '0' : '4'}`}>
						<FormGroup>
							<Label htmlFor='sports-connections'>{t('sports_connections')}</Label>
							<SportsConnectionsContainer
								related={advancedContentModel.relatedSports}
								onSportsConnectionSelect={onSportsDataSelect}
								t={t}
								contentLanguage={langCode}
								sportType={sportsType.sport}
								sportsEntities={sportsType.entity_types}
								allowMoreEntityTypes={false}
							/>
						</FormGroup>
					</Col>
				)
			)}
			{sportsConnectionsService.isFootballSelected(sportsType.sport)
				? !filtersInSidebar && (
						<Col col='6' sm='12' md={`${filtersInSidebar ? '0' : '4'}`}>
							<FormGroup>
								<Label htmlFor='tournament-select'>{t('tournament')}</Label>
								<TournamentSelect
									related={advancedContentModel.tournament}
									onSelect={onTournamentDataSelect}
									footballApiUrl={currentProject.footballApiUrl}
									t={t}
								/>
							</FormGroup>
						</Col>
				  )
				: !filtersInSidebar &&
				  sportsType.sport && (
						<Col col='6' sm='12' md={`${filtersInSidebar ? '0' : '4'}`}>
							<FormGroup>
								<Label htmlFor='competition-select'>{t('competition')}</Label>
								<CompetitionSelect
									related={advancedContentModel.tournament}
									onCompetitionSelect={onTournamentDataSelect}
									sportType={sportsType.sport}
									contentLanguage={langCode}
									t={t}
								/>
							</FormGroup>
						</Col>
				  )}
			{!filtersInSidebar && (
				<Col col='6' sm='12' md={`${filtersInSidebar ? '0' : '4'}`}>
					<FormGroup>
						<Label htmlFor='date'>{t('select_status')}</Label>
						<StatusSelect
							isClearable
							contentType={contentType}
							selectedStatus={advancedContentModel.status ? advancedContentModel.status : ''}
							statuses={statuses && statuses[`${contentType}Statuses`]}
							onStatusSelect={onStatusSelect}
							t={t}
						/>
					</FormGroup>
				</Col>
			)}
		</>
	);
};

export default AdvancedFiltersFields;
