import TeamPlayerModel from '../models/team-player.model';
import { assetsPlaceholder } from '../../../../../../constants/assetsPlaceholder';
import TeamModel from '../../team-select/models/team.model';

export function teamPlayerToOption(player: any) {
	if (player) {
		return {
			value: player.id,
			label: player.name,
			data: player,
			logo: player.logo,
			type: 'player',
		};
	}

	return {};
}

export function teamPlayersToOptions(players: any[]) {
	return players.map((player) => {
		return teamPlayerToOption(player);
	});
}

export const remapResponceToModel = (player: any, team?: TeamModel) => {
	return TeamPlayerModel.builder()
		.withId(player.id)
		.withName(player.name)
		.withPosition(player.position)
		.withTeam(<TeamModel>team)
		.withActive(player.active)
		.withBirthDate(player.birthdate)
		.withLogo(player.url_image ? player.url_image : assetsPlaceholder.player)
		.build();
};
