import { TypedJSON } from 'typedjson';
import { assetsPlaceholder } from '../../../../../../../constants/assetsPlaceholder';
import TournamentModel from '../../../../../../../models/v2/Tournament/Entity/TournamentModel';

export const responseToTournamentModel = (response: any) => {
	const serializer = new TypedJSON(TournamentModel);
	const remmapedTournament = serializer.parse(response) as TournamentModel;
	return remmapedTournament;
};

export const tournamentsToOptions = (tournaments: TournamentModel[]) => {
	if (tournaments && tournaments.length > 0) {
		return tournaments.map((tournament) => {
			return tournamentToOption(tournament);
		});
	}

	return [];
};

export const tournamentToOption = (tournament: TournamentModel) => {
	if (tournament) {
		return {
			value: tournament.id,
			label: tournament.name,
			data: tournament,
			logo: tournament.urlLogo !== '' ? tournament.urlLogo : assetsPlaceholder.tournament,
			type: 'tournament',
		};
	} else {
		return {};
	}
};
