//Actions
export const AVAILABLE_PROJECTS = '[ACTION] AVAILABLE_PROJECTS';

export const PROJECT_RESOURCES_REQUEST = '[ACTION] PROJECT_RESOURCES_REQUEST';
export const POLLING_PROJECT_RESOURCES = '[ACTION] POLLING_PROJECT_RESOURCES';
export const CLEAR_PROJECTS_RESOURCES_LOADED = '[ACTION] CLEAR_PROJECTS_RESOURCES_LOADED';
// END Actions

export const SPORTS_RELATED_TYPES = {
	PLAYER: 'player',
	COACH: 'coach',
	TOURNAMENT: 'tournament',
	TEAM: 'team',
	MATCH: 'match',
	VENUE: 'venue',
	ARENA: 'arena',
	COMPETITION: 'competition',
};

//Global
export const TOKEN_KEY = 'token';
export const REFRESH_TOKEN_KEY = 'refresh_token';
export const TOKEN_USER_ID = 'user_id';
// END Global

//TEMP Sidebar Collapsible Elements
export const sidebarElementsList = {
	article: [
		{ key: 'general', defaultOpen: 'open' },
		{ key: 'translations', defaultOpen: 'closed' },
		{ key: 'media', defaultOpen: 'closed' },
		{ key: 'content_footer', defaultOpen: 'closed' },
		{ key: 'tags', defaultOpen: 'closed' },
		{ key: 'related_content', defaultOpen: 'closed' },
		{ key: 'seo', defaultOpen: 'closed' },
		{ key: 'urls', defaultOpen: 'closed' },
		{ key: 'list', defaultOpen: 'closed' },
		{ key: 'custom_data', defaultOpen: 'closed' },
	],
	video: [
		{ key: 'general', defaultOpen: 'open' },
		{ key: 'translations', defaultOpen: 'closed' },
		{ key: 'media', defaultOpen: 'closed' },
		{ key: 'content_footer', defaultOpen: 'closed' },
		{ key: 'tags', defaultOpen: 'closed' },
		{ key: 'related_content', defaultOpen: 'closed' },
		{ key: 'seo', defaultOpen: 'closed' },
		{ key: 'urls', defaultOpen: 'closed' },
		{ key: 'list', defaultOpen: 'closed' },
		{ key: 'custom_data', defaultOpen: 'closed' },
	],
	gallery: [
		{ key: 'general', defaultOpen: 'open' },
		{ key: 'translations', defaultOpen: 'closed' },
		{ key: 'media', defaultOpen: 'closed' },
		{ key: 'content_footer', defaultOpen: 'closed' },
		{ key: 'tags', defaultOpen: 'closed' },
		{ key: 'related_content', defaultOpen: 'closed' },
		{ key: 'seo', defaultOpen: 'closed' },
		{ key: 'urls', defaultOpen: 'closed' },
		{ key: 'list', defaultOpen: 'closed' },
		{ key: 'custom_data', defaultOpen: 'closed' },
	],
	category: [
		{ key: 'media', defaultOpen: 'open' },
		{ key: 'urls', defaultOpen: 'closed' },
		{ key: 'seo', defaultOpen: 'closed' },
	],
	tag: [
		{ key: 'general', defaultOpen: 'open' },
		{ key: 'media', defaultOpen: 'closed' },
		{ key: 'urls', defaultOpen: 'closed' },
		{ key: 'seo', defaultOpen: 'closed' },
	],
	list: [{ key: 'general', defaultOpen: 'open' }],
	poll: [
		{ key: 'general', defaultOpen: 'open' },
		{ key: 'media', defaultOpen: 'closed' },
	],
	team: [{ key: 'media', defaultOpen: 'open' }],
	player: [{ key: 'media', defaultOpen: 'open' }],
	coach: [{ key: 'media', defaultOpen: 'open' }],
	author: [{ key: 'seo', defaultOpen: 'open' }],
	wikipage: [
		{ key: 'general', defaultOpen: 'open' },
		{ key: 'media', defaultOpen: 'closed' },
		{ key: 'content_footer', defaultOpen: 'closed' },
		{ key: 'tags', defaultOpen: 'closed' },
		{ key: 'related_content', defaultOpen: 'closed' },
		{ key: 'seo', defaultOpen: 'closed' },
		{ key: 'custom_data', defaultOpen: 'closed' },
	],
};
