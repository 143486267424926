import * as React from 'react';
import { FormGroup, Label, Col, Input } from 'reactstrap';

type NameProps = {
	t: any;
	onChange: any;
	value: string;
	type: string;
};

export const Name: React.FunctionComponent<NameProps> = ({ onChange, t, value, type }) => {
	return (
		<Col xs='6'>
			<FormGroup>
				<Label htmlFor={`${type}-manual-data-entry-name`}>
					{t(`${type}_name`)}
					<span className='text-danger'>*</span>
				</Label>
				<Input
					type='text'
					id={`${type}-manual-data-entry-name`}
					value={value ? value : ''}
					onChange={onChange}
					className='form-control'
					placeholder={t(`enter_${type}_name`)}
					required
				/>
			</FormGroup>
		</Col>
	);
};
