export const aspectRatioSelectOptions = [
	{ value: 23 / 9, label: '23x9' },
	{ value: 16 / 9, label: '16x9' },
	{ value: 9 / 16, label: '9x16' },
	{ value: 4 / 3, label: '4x3' },
	{ value: 3 / 4, label: '3x4' },
	{ value: 2 / 3, label: '2x3' },
	{ value: 3 / 2, label: '3x2' },
	{ value: 1 / 1, label: '1x1' },
];
