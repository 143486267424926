import React from 'react';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import BlockModel from '../../models/block.model';

type Properties = {
	t: any;
	block: BlockModel;
	displayOddsChecked: boolean;
	onDisplayOddsChange: any;
	id: string;
};

type RequiredProperties = {
	t: any;
	block: BlockModel;
	displayOddsChecked: boolean;
	onDisplayOddsChange: any;
	id: string;
};

const DisplayOdds: React.FunctionComponent<Properties> = (props) => {
	return (
		<>
			<Row data-qa={props.id} className='mb-2'>
				<Col>
					<FormGroup key={`display-odds-markets`} check className='radio'>
						<Input
							key={props.id}
							className='form-check-input'
							type='checkbox'
							id={`${props.id}-${props.block.id}`}
							name='radios'
							onChange={props.onDisplayOddsChange}
							checked={props.displayOddsChecked}
						/>
						<Label key={`display-odds-markets-label`} check className='form-check-label' for={`${props.id}-${props.block.id}`}>
							{props.t('display_odds')}
						</Label>
					</FormGroup>
				</Col>
			</Row>
		</>
	);
};

export default DisplayOdds as React.ComponentType<RequiredProperties>;
