import React, { useEffect } from 'react';
import Select from 'react-select';
import { Col, Label, Row } from 'reactstrap';
import { DATA_QA_ATTRIBUTES } from '../../../../../Partials/Sidebar/general-content/constants/data-qa.attributes';
import LiveBlogModel from '../../../models/live-blog.model';
import '../../../style/live-blog.style.scss';
import { returnOptionsByFeature, typesToOption, typesToOptions } from './live-blog-type-select.helper';
import { LiveBlogTypes } from '../../../helpers/utility.helper';
type Properties = {
	t: any;
	liveBlog: LiveBlogModel;
	setLiveBlogType: (type: string) => void;
};

export const LiveBlogTypeSelect: React.FunctionComponent<Properties> = ({ t, liveBlog, setLiveBlogType }) => {
	const defaultValue = !liveBlog.type || (liveBlog.type && liveBlog.type.length < 1) ? returnOptionsByFeature()[0] : liveBlog.type;
	useEffect(() => {
		if (liveBlog.type === LiveBlogTypes.LIVE_NEWS) {
			liveBlog.competitions = null;
			liveBlog.teams = null;
			liveBlog.sport_events = [];
			liveBlog.match_header = false;
		}

		if (!liveBlog.type) {
			setLiveBlogType(defaultValue);
		}
	}, [liveBlog]);

	const onTypeSelect = (type: string) => {
		setLiveBlogType(type);
	};

	return (
		<Row className='mb-3 type'>
			<Col>
				<Label htmlFor={DATA_QA_ATTRIBUTES.LIVE_BLOG_TYPE}>
					<strong>{t('type')}</strong>
				</Label>
				<Select
					id={DATA_QA_ATTRIBUTES.LIVE_BLOG_TYPE}
					options={typesToOptions(returnOptionsByFeature(), t)}
					value={typesToOption(defaultValue, t)}
					placeholder={t('select')}
					noOptionsMessage={(inputValue) => inputValue && t('no_options')}
					onChange={(selection: any) => onTypeSelect(selection && selection.value)}
				/>
			</Col>
		</Row>
	);
};
