import { AnyT, jsonMember, jsonObject } from 'typedjson';
import TeamModelV2 from '../../../../../../../models/v2/Team/Entity/TeamModel';
import StageModel from '../../../../../../../models/v2/Stage/Entity/StageModel';
import SeasonModel from '../../../../../../../models/v2/Season/Entity/SeasonModel';
import TournamentModel from '../../../../../../../models/v2/Tournament/Entity/TournamentModel';
import VenueModel from '../../../../blocks/widgets/fixtures/models/venue/venue.model';
import TotalScoreModel from './TotalScore.model';

@jsonObject
export default class ScoreModel {
	@jsonMember(TotalScoreModel, { name: 'total' })
	public total: TotalScoreModel = new TotalScoreModel();
}
