import React, { FunctionComponent } from 'react';
import { Label, Button } from 'reactstrap';
import moment from 'moment';
import {
	applyInProgressClass,
	extractActionForUpdatingSelectedEvents,
	remapEventAsRelatedModel,
	toggleScore,
} from '../../helpers/events.helper';
import EventModel from '../../../../../../models/v2/sports-types/eventModel';
import { EventStatuses } from '../../../../../../constants/event.types';
import { connect } from 'react-redux';
import Related from '../../../../../../models/related/Related';
import { extractRelatedPropertiesNameByUrl } from '../../helpers/utils';
import { store } from '../../../../../../store/store';

export type Properties = {
	event: EventModel;
	updateSelectedEvents: Function;
};
const EventRow: FunctionComponent<Properties> = ({ event, updateSelectedEvents }) => {
	if (!event) return null;
	const liClass = `list-group-item d-flex align-items-center ${event.status !== EventStatuses.LIVE && applyInProgressClass(event.type)}`;

	const onEventSelect = (selectedEvent: EventModel) => {
		const reduxNestedProperty = extractRelatedPropertiesNameByUrl();
		const relatedMatchesResult: Related[] = (reduxNestedProperty && store.getState().tempMatchRelated[reduxNestedProperty]) || [];
		const selectedEventAsRelatedModel = remapEventAsRelatedModel(selectedEvent.data);
		selectedEventAsRelatedModel && relatedMatchesResult.push(selectedEventAsRelatedModel);
		updateSelectedEvents(relatedMatchesResult);
	};

	return (
		<li className={liClass} id={`event-line-tags-${event.id}`}>
			<Button className='btn btn-light btn-sm mr-2' id={`add-event-line-button-${event.id}`} onClick={() => onEventSelect(event)}>
				<i className='fa fa-plus'></i>
			</Button>
			<Label className='form-check-label'>
				{`${moment(event.startTime).format('DD MMM YYYY')} - `}
				<span>{event.homeTeam}</span>
				{toggleScore(event)}
				<span>{event.awayTeam}</span>
			</Label>
		</li>
	);
};

function mapDispatchToProps(dispatch: any) {
	const actionForUpdatingSelectedEvents = extractActionForUpdatingSelectedEvents();

	return {
		updateSelectedEvents: (related: Related[]) => actionForUpdatingSelectedEvents && dispatch(actionForUpdatingSelectedEvents(related)),
	};
}

export default connect(null, mapDispatchToProps)(EventRow);
