import 'reflect-metadata';
import { AnyT, jsonMember, jsonObject } from 'typedjson';
import LiveBlogTeamModel from '../../../../models/teams/live-blog-team.model';
import BlacklistModel from '../../../../../../../models/v2/blacklist/blaclist';

@jsonObject()
class LiveBlogMatchEventStatusModel {
	@jsonMember(String)
	public type: string = '';

	@jsonMember(String)
	public name: string = '';

	@jsonMember(String)
	public short_name: string = '';

	@jsonMember(String)
	public code: string = '';

	@jsonMember(String)
	public uuid: string = '';
}

@jsonObject()
class TournamentSeasonStageModel {
	@jsonMember(Number)
	public id: number = 0;

	@jsonMember(String)
	public name: string = '';

	@jsonMember(String)
	public slug: string = '';

	@jsonMember(Boolean)
	public cup: boolean = false;

	@jsonMember(Number)
	public tournament_season_id: number = 0;

	@jsonMember(Number)
	public tournament_id: number = 0;

	@jsonMember(String)
	public uuid: string | null = null;
}

@jsonObject()
class VenueModel {
	@jsonMember(Number)
	public id: number = 0;

	@jsonMember(String)
	public name: string = '';

	@jsonMember(String)
	public slug: string = '';

	@jsonMember(String)
	public uuid: string | null = null;

	@jsonMember(String)
	public url_image: string | null = null;
}

@jsonObject()
export default class LiveBlogMatchModel {
	@jsonMember(AnyT)
	public id: string | number = '';

	@jsonMember(LiveBlogMatchEventStatusModel)
	public event_status: LiveBlogMatchEventStatusModel = new LiveBlogMatchEventStatusModel();

	@jsonMember(String)
	public start_time: string = '';

	@jsonMember(String)
	public slug: string = '';

	@jsonMember(String)
	public started_at: string = '';

	@jsonMember(String)
	public finished_at: string = '';

	@jsonMember(LiveBlogTeamModel)
	public home_team: LiveBlogTeamModel = new LiveBlogTeamModel();

	@jsonMember(LiveBlogTeamModel)
	public away_team: LiveBlogTeamModel = new LiveBlogTeamModel();

	@jsonMember(Number)
	public goal_home: number = 0;

	@jsonMember(Number)
	public goal_away: number = 0;

	@jsonMember(Number)
	public minute: number | null = null;

	@jsonMember(String)
	public type: string | null = null;

	@jsonMember(String)
	public round: string | null = null;

	@jsonMember(TournamentSeasonStageModel)
	public tournament_season_stage: TournamentSeasonStageModel = new TournamentSeasonStageModel();

	@jsonMember(Boolean)
	public live_updates: boolean | null = null;

	@jsonMember(Boolean)
	public lineup_available: boolean | null = null;

	@jsonMember(String)
	public updated_at: string | null = null;

	@jsonMember(VenueModel)
	public venue: VenueModel = new VenueModel();

	@jsonMember(BlacklistModel)
	public blacklist: BlacklistModel | null = null;
}
