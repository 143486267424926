import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Col, Label, Row } from 'reactstrap';
import HttpService from '../../../../../../services/rest/HttpService';
import ErrorHandler from '../error-handler/error-handler';
import SeasonModel from '../../../../../../models/v2/Season/Entity/SeasonModel';
import TeamModel from '../../../../../../models/v2/Team/Entity/TeamModel';
import { remapTeamsFromResponse, teamsToOptions } from '../helpers/top-scorers-selects/top-scorers-select-components.helper';
import { customOption } from '../../../partials/shared/custom-select-option';

type Properties = {
	onTeamSelect: any;
	selectedSeason: SeasonModel;
	selectedTeams: TeamModel[];
	t: any;
	isValid: boolean;
	isClearable?: boolean;
	isRequired?: boolean;
	isBlocky: boolean;
	withLabel?: boolean;
	id: string;
};

const FootballTopScorersTeamSelect: React.FunctionComponent<Properties> = (props) => {
	const [teamOptions, setTeamOptions] = useState<TeamModel[]>([]);

	useEffect(() => {
		if (props.selectedSeason && props.selectedSeason.id) {
			getTeams(props.selectedSeason.id);
		}
	}, []);

	useEffect(() => {
		if (props.selectedSeason && props.selectedSeason.id) {
			getTeams(props.selectedSeason.id);
		}
	}, [props.selectedSeason.id]);

	const updateTeamsOptionsState = (teams: TeamModel[]) => {
		setTeamOptions(teams);
	};

	const getTeams = (seasonId: number) => {
		if (seasonId) {
			HttpService.getTopScorersTeamBySeasonId(seasonId)
				.then((response: any) => {
					updateTeamsOptionsState(remapTeamsFromResponse(response));
				})
				.catch((e: any) => e);
		}
	};

	const teamsSelected = props.selectedTeams === undefined || props.selectedTeams === null || props.selectedTeams.length <= 0;

	return (
		<Row data-qa={props.id}>
			<Col>
				<Label htmlFor={props.id}>{props.t('teams')}:</Label>
				{props.isRequired && <span className='text-danger'>*</span>}
				<Select
					isMulti
					// isDisabled={props.selectedTeams.length >= 1}
					isClearable={!teamsSelected}
					placeholder={props.t('select')}
					formatOptionLabel={customOption}
					options={teamsToOptions(teamOptions)}
					value={teamsToOptions(props.selectedTeams)}
					onChange={(selections: any) => {
						props.onTeamSelect(selections);
					}}
				/>
				{props.isRequired && (
					<ErrorHandler t={props.t} isValueSelected={!teamsSelected} arePropsValid={props.isValid} errorMessage='field_is_required' />
				)}
			</Col>
		</Row>
	);
};

export default FootballTopScorersTeamSelect as React.ComponentType<Properties>;
