import { GeneralContentModel } from '../../../Partials/Sidebar/GeneralContentAttributes/models/GeneralContentModel';
import { UrlsComponentModel } from '../../../Partials/Sidebar/Urls/models/UrlsComponentModel';
import Urls from '../../../../models/urls/Urls';
import Seo from '../../../../models/seo/Seo';
import { SeoComponentModel } from '../../../Partials/Sidebar/Seo/models/SeoComponentModel';
import Related from '../../../../models/related/Related';
import { RelatedContentModel } from '../../../Partials/Sidebar/related-content/models/related-content.model';
import Gallery from '../../../../models/gallery/Gallery';
import Author from '../../../../models/author/Author';
import { TagsModel } from '../../../Partials/Sidebar/tags/models/tags.model';
import Global from '../../../Partials/Blocky/helpers/global.helpers';
import ContentAttributes from '../../../../models/content-attributes/content-attributes-model';

export function generalContentModelToGallery(genContent: GeneralContentModel, gallery: Gallery) {
	return Gallery.builder(gallery)
		.withPublishedUntil(genContent.publishedUntil)
		.withPublishedAt(genContent.publishedAt)
		.withCategory(genContent.category)
		.withAdditionalCategories(genContent.categories)
		.withAuthors(genContent.authors)
		.withCustomAuthor(genContent.customAuthor)
		.withStatus(genContent.status)
		.withAllowComments(genContent.allowComments)
		.withDistributionRegions(genContent.distributionRegions)
		.withDistributionChannels(genContent.distributionChannels)
		.withType(genContent.type)
		.withOrigin(genContent.origin)
		.withComments({ policy: genContent.commentsPolicies })
		.withLanguage(genContent.language)
		.build();
}

export function galleryToGeneralContentModelForCreate(gallery: Gallery, type: ContentAttributes, status: ContentAttributes) {
	let genContentType: string = (type && type.slug) || '';
	let genContentStatus: string = (status && status.slug) || '';
	if (gallery && typeof gallery.type == 'string') genContentType = gallery.type;
	if (gallery && typeof gallery.status == 'string') genContentStatus = gallery.status;

	let genContent: GeneralContentModel = {
		type: genContentType || '',
		status: genContentStatus || '',
		customAuthor: gallery.customAuthor,
		authors: gallery.authors,
		categories: gallery.additionalCategories,
		category: gallery.category,
		publishedAt: gallery.publishedAt,
		publishedUntil: gallery.publishedUntil,
		allowComments: gallery.allowComments,
		distributionRegions: gallery.distributionRegions,
		distributionChannels: gallery.distributionChannels,
		origin: gallery.origin,
		commentsPolicies: gallery.comments && gallery.comments.policy,
		properties: gallery.properties,
		language: gallery.language,
	};

	return genContent;
}

export function galleryToGeneralContentModel(gallery: Gallery) {
	let genContent: GeneralContentModel = {
		type: gallery.type,
		status: gallery.status,
		customAuthor: gallery.customAuthor,
		authors: gallery.authors,
		categories: gallery.additionalCategories,
		category: gallery.category,
		publishedAt: gallery.publishedAt,
		publishedUntil: gallery.publishedUntil,
		allowComments: gallery.allowComments,
		distributionRegions: gallery.distributionRegions,
		distributionChannels: gallery.distributionChannels,
		origin: gallery.origin,
		commentsPolicies: gallery.comments && gallery.comments.policy,
		properties: gallery.properties,
		language: gallery.language,
	};

	return genContent;
}

export function urlsComponentModelToGallery(urlsModel: UrlsComponentModel, gallery: Gallery): Gallery {
	const urls: Urls = Urls.builder(gallery.urls)
		.withCanonicalUrl(urlsModel.canonicalUrl)
		.withExternalUrl(urlsModel.externalUrl)
		.withPublicUrlDesktop(urlsModel.publicUrlDesktop ? urlsModel.publicUrlDesktop : '')
		.withPublicUrlMobile(urlsModel.publicUrlMobile ? urlsModel.publicUrlMobile : '')
		.withPublicUrlAmp(urlsModel.publicUrlAmp ? urlsModel.publicUrlAmp : '')
		.build();
	const seo: Seo = Seo.builder(gallery.seo).withRedirectType(urlsModel.redirectType).build();

	return Gallery.builder(gallery).withSeo(seo).withUrls(urls).build();
}

export function galleryToUrlsComponentModel(gallery: Gallery): UrlsComponentModel {
	return {
		externalUrl: gallery.urls && gallery.urls.externalUrl ? gallery.urls.externalUrl : '',
		canonicalUrl: gallery.urls && gallery.urls.canonicalUrl ? gallery.urls.canonicalUrl : '',
		redirectType: gallery.seo && gallery.seo.redirectType ? gallery.seo.redirectType : '',
		publicUrlDesktop: gallery.urls && gallery.urls.publicUrlDesktop ? gallery.urls.publicUrlDesktop : '',
		publicUrlMobile: gallery.urls && gallery.urls.publicUrlMobile ? gallery.urls.publicUrlMobile : '',
		publicUrlAmp: gallery.urls && gallery.urls.publicUrlAmp ? gallery.urls.publicUrlAmp : '',
	};
}

export function appendDefaultAuthorIfNoneAdded(gallery: Gallery, defaultAuthor: Author): Gallery {
	if (
		gallery &&
		(gallery.authors === null || gallery.authors === undefined || gallery.authors.length < 1 || gallery.authors[0].id !== defaultAuthor.id) &&
		Object.entries(defaultAuthor).length > 0
	) {
		gallery = Gallery.builder(gallery).withAuthors([defaultAuthor]).build();
	}

	return gallery;
}

export function seoComponentModelToGallery(seoModel: SeoComponentModel, gallery: Gallery): Gallery {
	const seo: Seo = Seo.builder(gallery.seo)
		.withKeywords(seoModel.keywords)
		.withFollow(seoModel.following)
		.withIndex(seoModel.indexing)
		.withTitle(Global.stripEmoji(seoModel.title))
		.withDescription(seoModel.description)
		.withSlug(Global.stripEmoji(seoModel.slug))
		.withAutoSlug(seoModel.autoSlug)
		.withAutoTitle(seoModel.autoTitle)
		.withJsonLD(seoModel.jsonld)
		.build();

	return Gallery.builder(gallery).withSeo(seo).build();
}

export function galleryToSeoComponentModel(gallery: Gallery): SeoComponentModel {
	return {
		keywords: gallery.seo && gallery.seo.keywords ? gallery.seo.keywords : [],
		following: gallery.seo ? gallery.seo.follow : true,
		indexing: gallery.seo ? gallery.seo.index : true,
		title: gallery.seo && gallery.seo.title ? Global.stripEmoji(gallery.seo.title) : '',
		description: gallery.seo && gallery.seo.description ? gallery.seo.description : '',
		slug: gallery.seo && gallery.seo.slug ? Global.stripEmoji(gallery.seo.slug) : '',
		contentTitle: gallery && gallery.title ? Global.stripEmoji(gallery.title) : '',
		autoSlug: gallery && gallery.seo ? gallery.seo.autoSlug : false,
		autoTitle: gallery && gallery.seo ? gallery.seo.autoTitle : false,
		jsonld: gallery && gallery.seo ? gallery.seo.jsonld : '',
	};
}

export function relatedContentsToTagsModel(
	relatedSports: Related[],
	relatedTags: Related[],
	relatedMatches: Related[],
	tags: any[],
): TagsModel {
	return {
		relatedSports,
		relatedTags,
		relatedMatches,
		linkedTags: tags,
	};
}

export function relatedContentsToRelatedContentModel(
	relatedSports: Related[],
	relatedContent: Related[],
	relatedMatches: Related[],
): RelatedContentModel {
	return {
		relatedSports,
		relatedContent,
		relatedMatches,
	};
}

export function getDefaultAuthor(authors: Author[]) {
	return authors.find(function (author) {
		return author.isDefault;
	});
}

export function isGalleryItemsTabActive(activeTabIndex: string) {
	const galleryItemsTabIndex = '2';

	return activeTabIndex === galleryItemsTabIndex;
}
