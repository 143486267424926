import { imagePlaceHolder } from '../../../../../../../constants/resource-constants';
import MainMedia from '../../../../../../../models/mainmedia/MainMedia';

export const extractMainImageSrc = (mainMedia: MainMedia[]) => {
	let mediaMainImage = MainMedia.builder().build();
	let imageSrc = imagePlaceHolder;

	if (mainMedia && mainMedia.length > 0) {
		const foundMedia = mainMedia.find((media: MainMedia) => media.resourceType === 'image' && media.resourceSubtype === 'main_image');

		if (foundMedia) {
			mediaMainImage = foundMedia;
		}
	}

	if (mediaMainImage && mediaMainImage.data) {
		imageSrc =
			mediaMainImage.data.urls && mediaMainImage.data.urls.uploaded && mediaMainImage.data.urls.uploaded.thumbnail
				? mediaMainImage.data.urls.uploaded.thumbnail
				: imagePlaceHolder;
	}

	return imageSrc;
};
