import {
	extractArenaIds,
	extractCoachIds,
	extractPlayerIds,
	extractTeamIds,
	extractVenueIds,
} from '../../../Sidebar/related-content/subcomponents/helpers/content-by-suggestions-container.helper';
import { extractTagIds, extractTypeNames } from '../../advanced-content-filter/helpers/advanced-content-filter.helper';
import WikiPagesAdvancedFilterModel from '../models/wiki-pages-advanced-filter.model';

export const constructWikiPageSearchContentQuery = (data: any) => {
	if (data) {
		const teamIds = extractTeamIds(data.relatedSports);
		const playerIds = extractPlayerIds(data.relatedSports);
		const coachIds = extractCoachIds(data.relatedSports);
		const venueIds = extractVenueIds(data.relatedSports);
		const arenaIds = extractArenaIds(data.relatedSports);
		const tagIds = extractTagIds(data.relatedTags);
		const articleTypes = extractTypeNames(data.type);

		const teamIdsQuery = teamIds.length > 0 ? `&teamIds=${teamIds}` : '';
		const playerIdsQuery = playerIds.length > 0 ? `&playerIds=${playerIds}` : '';
		const coachIdsQuery = coachIds.length > 0 ? `&coachIds=${coachIds}` : '';
		const venueIdsQuery = venueIds.length > 0 ? `&venueIds=${venueIds}` : '';
		const seasonIdsQuery = data.season && data.season.data && data.season.data.id ? `&seasonIds=${data.season.data.id}` : '';
		const arenaIdsQuery = arenaIds.length > 0 ? `&arenaIds=${arenaIds}` : '';
		const tagIdsQuery = tagIds.length > 0 ? `&tagIds=${tagIds}` : '';
		const tournamentOrCompetitionId =
			data.tournament && data.tournament.data ? setCorrectQueryParameter(data.tournament.type, data.tournament.data.id) : '';
		const from = data.date && data.date.length > 0 ? `&from=${data.date[0]}` : '';
		const to = data.date && data.date.length > 0 ? `&to=${data.date[1]}` : '';
		const createdBy = data.createdBy && data.createdBy.id && data.createdBy.id.length > 0 ? `&createdBy=${data.createdBy.id}` : '';
		const type = articleTypes.length > 0 ? `&type=${articleTypes}` : '';
		const statusQuery = data.status && data.status.length > 0 ? `&status=${data.status}` : '';

		return (
			teamIdsQuery +
			playerIdsQuery +
			tagIdsQuery +
			from +
			to +
			createdBy +
			tournamentOrCompetitionId +
			seasonIdsQuery +
			coachIdsQuery +
			venueIdsQuery +
			arenaIdsQuery +
			type +
			statusQuery
		);
	}

	return '';
};

export const setCorrectQueryParameter = (entity: any, id: string) => {
	if (entity && entity === 'tournament') {
		return `&tournamentIds=${id}`;
	} else {
		return `&competitionIds=${id}`;
	}
};

export const checkIfTheWikiPageFiltersAreEmpty = (model: WikiPagesAdvancedFilterModel) => {
	if (model && Object.entries(model).length > 0) {
		const isCreatedByEmpty = !model.createdBy || (model.createdBy && Object.entries(model.createdBy).length === 0);
		const isDateEmpty = !model.date || (model.date && model.date.length === 0);
		const isRelatedSportsEmpty = !model.relatedSports || (model.relatedSports && model.relatedSports.length === 0);
		const isRelatedTagsEmpty = !model.relatedTags || (model.relatedTags && model.relatedTags.length === 0);
		const isTournamentEmpty = !model.tournament || (model.tournament && !model.tournament.type);
		const isSeasonEmpty = !model.season || (model.season && model.season.data && model.season.data.id === 0);
		const isSportsTypeEmpty = !model.sportsType || (model.sportsType && model.sportsType.length === 0);
		const isTypeEmpty = !model.type || (model.type && model.type.length === 0);

		return (
			isCreatedByEmpty &&
			isDateEmpty &&
			isRelatedSportsEmpty &&
			isSeasonEmpty &&
			isRelatedTagsEmpty &&
			isSportsTypeEmpty &&
			isTournamentEmpty &&
			isTypeEmpty
		);
	}

	return true;
};

export const extractWikiPageSearchQueryFromFilters = (filters: WikiPagesAdvancedFilterModel) => {
	let query: string = '';

	if (filters && Object.entries(filters).length > 0) {
		const searchText = filters.searchText && filters.searchText.length > 0 ? filters.searchText : '*';
		const advancedContentSearchText = constructWikiPageSearchContentQuery(filters);

		query = searchText + advancedContentSearchText;
	}

	return query;
};

export const generateFiltersMdClass = (filtersInSidebar: boolean) => {
	return filtersInSidebar ? '0' : '3';
};
