import React from 'react';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { Properties, RequiredProperties, State } from './properties/UrlsProperties';
import RedirectTypeSelectContainer from './subcomponents/RedirectTypeSelectContainer';
import { ContentTypes } from '../../../../constants/content-types';
import ErrorAsync from '../../BaseComponents/ErrorComponent/ErrorAsync';
import { SocialMediaComponent } from './subcomponents/social-urls.component';
import VideoUrlsComponent from './subcomponents/video-urls/video-urls.component';
import VideoUrls from '../../../../models/urls/video-urls/video-urls';
import { featuresService } from '../../../../App';
import { FeatureTypes } from '../../../../services/feature-service/features.enum';

class UrlsComponent extends React.Component<Properties, State> {
	onExternalUrlChange = (event: any) => {
		const tempValue = this.props.value;
		tempValue.externalUrl = event.target.value;
		this.props.onChange(tempValue);
	};

	onCanonicalUrlChange = (event: any) => {
		const tempValue = this.props.value;
		tempValue.canonicalUrl = event.target.value;
		this.props.onChange(tempValue);
	};

	onRedirectTypeSelect = (redirectType: string) => {
		const tempValue = this.props.value;
		tempValue.redirectType = redirectType;
		this.props.onChange(tempValue);
	};

	onVideoUrlsSelect = (videoUrls: VideoUrls[]) => {
		const tempValue = this.props.value;
		tempValue.videoUrls = videoUrls;
		this.props.onChange(tempValue);
	};

	render() {
		const { t, value, contentType } = this.props;
		const videoUrlsSelectionEnabled = featuresService && featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.VIDEO_URLS);

		return (
			<div id='sidebar-urls'>
				<Row>
					<Col>
						<FormGroup>
							<Label htmlFor='external_url'>{t('external_url')}</Label>
							<Input
								type='text'
								id='external_url'
								placeholder={t('external_url_placeholder')}
								value={value && value.externalUrl ? value.externalUrl : ''}
								onChange={this.onExternalUrlChange}
							/>
							<ErrorAsync t={t} errorType='external_url' />
						</FormGroup>
					</Col>
				</Row>

				<Row>
					<Col>
						<FormGroup>
							<Label htmlFor='urls-type-of-redirect'>{t('type_redirect')}</Label>
							<RedirectTypeSelectContainer
								t={t}
								redirectType={value && value.redirectType ? value.redirectType : ''}
								onRedirectTypeChange={this.onRedirectTypeSelect}
							/>
						</FormGroup>
					</Col>
				</Row>

				<Row>
					<Col>
						<FormGroup>
							<Label htmlFor='canonical_url'>{t('canonical_url')}</Label>
							<Input
								type='text'
								id='canonical_url'
								placeholder={t('canonical_url_placeholder')}
								value={value && value.canonicalUrl ? value.canonicalUrl : ''}
								onChange={this.onCanonicalUrlChange}
							/>
							<ErrorAsync t={t} errorType='canonical_url' />
						</FormGroup>
					</Col>
				</Row>

				{videoUrlsSelectionEnabled && contentType === ContentTypes.VIDEO && (
					<Row>
						<Col>
							<FormGroup>
								<Label htmlFor='title'>{t('video_types')}</Label>
								<VideoUrlsComponent t={t} urls={value && value.videoUrls ? value.videoUrls : []} onVideoUrlsChange={this.onVideoUrlsSelect} />
							</FormGroup>
						</Col>
					</Row>
				)}

				{(contentType === ContentTypes.ARTICLE || contentType === ContentTypes.VIDEO || contentType === ContentTypes.GALLERY) && (
					<>
						<Row className='mb-2'>
							<Col>
								<Label>{t('public_url_desktop')}</Label>
								<SocialMediaComponent t={t} url={value.publicUrlDesktop ? value.publicUrlDesktop : ''} type='desktop' />
							</Col>
						</Row>

						<Row className='mb-2'>
							<Col>
								<Label>{t('public_url_mobile')}</Label>
								<SocialMediaComponent t={t} url={value.publicUrlMobile ? value.publicUrlMobile : ''} type='mobile' />
							</Col>
						</Row>

						<Row className='mb-2'>
							<Col>
								<Label>{t('public_url_amp')}</Label>
								<SocialMediaComponent t={t} url={value.publicUrlAmp ? value.publicUrlAmp : ''} type='amp' />
							</Col>
						</Row>
					</>
				)}
			</div>
		);
	}
}

export default withTranslation()(UrlsComponent) as React.ComponentType<RequiredProperties>;
