import LiveBlogModel from '../../views/Pages/Live Blog/models/live-blog.model';

export const LIVE_BLOG_EDITORIAL_OPEN = '[ACTION] LIVE_BLOG_EDITORIAL_OPEN';
export const LIVE_BLOG_EDITORIAL_CLOSE = '[ACTION] LIVE_BLOG_EDITORIAL_CLOSE';

export function returnObjectForLiveBlogEditorialOpen(liveBlog: LiveBlogModel) {
	return {
		type: LIVE_BLOG_EDITORIAL_OPEN,
		payload: liveBlog,
	};
}

export function returnObjectForLiveBlogEditorialClose() {
	return {
		type: LIVE_BLOG_EDITORIAL_CLOSE,
	};
}
