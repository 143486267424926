import { featuresService } from '../../../../../App';
import CategoryModel from '../../../../../models/v2/category/category.model';
import { SelectMenuOptionType } from '../../../../../models/v2/general/select.model';
import { FeatureTypes } from '../../../../../services/feature-service/features.enum';

export const categoriesToOptions = (categories: CategoryModel[], adminCategories: CategoryModel[]): SelectMenuOptionType[] => {
	const displayInactiveCategories = featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.DISPLAY_INACTIVE_CATEGORIES);

	return categories && categories.length > 0
		? categories
				.filter((category: CategoryModel) => (displayInactiveCategories ? true : category.active))
				.map((category: CategoryModel) => returnMappedCategories(category, adminCategories))
		: [];
};

export const isCategoryInAdminCategories = (category: CategoryModel, adminCategories: CategoryModel[]) => {
	return adminCategories.findIndex((adminCategory) => adminCategory.id === category.id) === -1;
};

export const returnMappedCategories = (category: CategoryModel, adminCategories: CategoryModel[]) => {
	const inactiveText =
		!category.active && featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.DISPLAY_INACTIVE_CATEGORIES) ? '(Inactive)' : '';

	return {
		value: category.id,
		label: `${category.title} ${inactiveText}`,
		data: category,
		isDisabled: isCategoryInAdminCategories(category, adminCategories),
	};
};

export const categoriesFromOptionSelections = (selections: SelectMenuOptionType[]) => {
	return selections ? selections.map((selection: SelectMenuOptionType) => selection.data) : [];
};
