import Social from '../social/social';
import BlacklistModel from './blacklist/blacklist';
import PlayerProfileModel from './player-profile/player-profile';
import EnumItem from '../enum/EnumItem';
import { PlayerManualDataJson } from './player-manual-data.json';
import PlayerManualDataBuilder from './player-manual-data.builder';
import AssetsModel from '../team-manual-data/assets/assets';

export default class PlayerManualDataModel {
	readonly id: string;
	readonly name: string;
	readonly active: boolean;
	readonly country: any;
	readonly birthCity: any;
	readonly birthdate: string;
	readonly profile: PlayerProfileModel;
	readonly position: EnumItem;
	readonly social: Social;
	readonly blacklist: BlacklistModel;
	readonly assets: AssetsModel;
	readonly url_thumb: string;
	readonly url_image: string;
	readonly url_logo: string;

	private constructor(
		id: string,
		name: string,
		active: boolean,
		country: any,
		birthCity: EnumItem,
		birthdate: string,
		profile: PlayerProfileModel,
		position: EnumItem,
		social: Social,
		blacklist: BlacklistModel,
		assets: AssetsModel,
		url_logo: string,
		url_image: string,
		url_thumb: string,
	) {
		this.id = id;
		this.name = name;
		this.active = active;
		this.country = country;
		this.birthCity = birthCity;
		this.birthdate = birthdate;
		this.profile = profile;
		this.position = position;
		this.social = social;
		this.blacklist = blacklist;
		this.assets = assets;
		this.url_logo = url_logo;
		this.url_image = url_image;
		this.url_thumb = url_thumb;
	}

	toJSON(): PlayerManualDataJson {
		return {
			id: this.id,
			name: this.name,
			active: this.active,
			country: this.country,
			birthCity: this.birthCity,
			birthdate: this.birthdate,
			profile: this.profile,
			position: this.position,
			social: this.social,
			blacklist: this.blacklist,
			assets: this.assets,
			url_logo: this.url_logo,
			url_image: this.url_image,
			url_thumb: this.url_thumb,
		};
	}

	static fromJSON(json: PlayerManualDataJson): PlayerManualDataModel {
		return new PlayerManualDataModel(
			json.id,
			json.name,
			json.active,
			json.country,
			json.birthCity,
			json.birthdate,
			json.profile,
			json.position,
			json.social,
			json.blacklist,
			json.assets,
			json.url_logo,
			json.url_image,
			json.url_thumb,
		);
	}

	static builder(player?: PlayerManualDataModel): PlayerManualDataBuilder {
		return new PlayerManualDataBuilder(player);
	}
}
