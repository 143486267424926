import React from 'react';
import { WidgetTypes } from '../constants/block.types';
import OddsWidget from '../blocks/widgets/odds/components/odds.component';
import PlayerH2HWidget from '../blocks/widgets/player-h2h/components/player-h2h-widget.component';
import MatchWidget from '../blocks/widgets/match/components/match.component';
import { ViewTypes } from '../constants/general.constants';
import BlockModel from '../models/block.model';
import TeamProfile from '../blocks/widgets/team-profile/components/team-profile.component';
import StandingsWidget from '../blocks/widgets/standings/components/standings-widget.component';
import TeamPlayersWidget from '../blocks/widgets/team-players/components/team-players-widget.component';
import PlayerProfile from '../blocks/widgets/player-profile/components/player-profile.component';
import TopScorers from '../blocks/widgets/top-scorers/components/top-scorers.component';
import TeamComparisonWidget from '../blocks/widgets/team-comparison/components/team-comparison-widget.component';
import FixturesWidget from '../blocks/widgets/fixtures/components/fixtures-widget-container.component';
import MatchListStageRound from '../blocks/widgets/match-list-stage-round/components/match-list-stage-round.component';
import TeamForm from '../blocks/widgets/team-form/components/team-form.component';
import MostDecoratedPlayersWidget from '../blocks/widgets/most-decorated-players/components/most-decorated-players-widget.component';
import PlayerCareer from '../blocks/widgets/player-career/components/player-career.component';
import SquadStatistics from '../blocks/widgets/squad-statistics/components/squad-statistics.component';

export default class WidgetBlocksGenerator {
	public createBlock = (block: BlockModel, t: any, contentData: any, contentLanguage: string, view: ViewTypes = ViewTypes.normal) => {
		switch (block.data.widget_type) {
			case WidgetTypes.odds: {
				return <OddsWidget block={block} t={t} view={view} contentData={contentData} />;
			}

			case WidgetTypes.widgetTeamComparison: {
				return <TeamComparisonWidget block={block} t={t} view={view} />;
			}

			case WidgetTypes.fixtures: {
				return <FixturesWidget block={block} t={t} view={view} />;
			}

			case WidgetTypes.match: {
				return <MatchWidget block={block} t={t} view={view} contentData={contentData} />;
			}

			case WidgetTypes.playerProfile: {
				return <PlayerProfile block={block} t={t} view={view} />;
			}

			case WidgetTypes.polls: {
				return null;
			}

			case WidgetTypes.standings: {
				return <StandingsWidget block={block} t={t} view={view} />;
			}

			case WidgetTypes.teamPlayers: {
				return <TeamPlayersWidget block={block} t={t} view={view} />;
			}

			case WidgetTypes.teamProfile: {
				return <TeamProfile block={block} t={t} view={view} contentData={contentData} />;
			}

			case WidgetTypes.topScorers: {
				return <TopScorers block={block} t={t} view={view} />;
			}

			case WidgetTypes.widgetPlayerH2H: {
				return <PlayerH2HWidget block={block} t={t} view={view} />;
			}

			case WidgetTypes.widgetMatchListStageRound: {
				return <MatchListStageRound block={block} t={t} view={view} />;
			}

			case WidgetTypes.widgetTeamForm: {
				return <TeamForm block={block} t={t} view={view} />;
			}

			case WidgetTypes.widgetMostDecoratedPlayers: {
				return <MostDecoratedPlayersWidget block={block} t={t} view={view} />;
			}
			case WidgetTypes.widgetPlayerCareer: {
				return <PlayerCareer block={block} t={t} view={view} />;
			}

			case WidgetTypes.widgetSquadStatistics: {
				return <SquadStatistics block={block} t={t} view={view} />;
			}

			default: {
				return null;
			}
		}
	};
}
