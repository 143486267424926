import 'reflect-metadata';
import { jsonMember, jsonObject } from 'typedjson';

@jsonObject()
export default class BlacklistModel {
	@jsonMember(String)
	public id: string | null = null;

	@jsonMember(String, { name: 'entity_id' })
	public entityId: string | null = null;

	@jsonMember(String)
	public entity: string | null = null;

	@jsonMember(String)
	public type: string | null = null;
}
