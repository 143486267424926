export default class TournamentSelectModel {
	constructor(id: string, name: string, countryId: string, countryName: string) {
		this.id = id;
		this.name = name;
		this.country_id = countryId;
		this.country_name = countryName;
	}

	public id = '';
	public name = '';
	public country_id = '';
	public country_name = '';
}
