import React, { useState } from 'react';
import { Button } from 'reactstrap';
import Project from '../../../../../../../models/project/Project';
import MainMediaModel from '../../../../../../../models/v2/main-media/main-media.model';
import { extractMainImageObjectFromArray } from '../../../../../../Partials/Sidebar/media-refactored/helpers/general';
import { DATA_QA_ATTRIBUTES } from '../../../../../../Partials/Sidebar/media-refactored/constants/data-qa.attributes';
import '../styles/style.scss';
import ImageCropContainer from '../../../../../../Partials/Shared/image-crop/image-crop-container';

type Properties = {
	project: Project;
	mainMedia: MainMediaModel[];
	t: any;
};

export const LiveBlogCropMainImage: React.FunctionComponent<Properties> = ({ t, project, mainMedia }) => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const mainMediaImage: MainMediaModel = extractMainImageObjectFromArray(mainMedia) || new MainMediaModel();

	return (
		<>
			<Button
				id={DATA_QA_ATTRIBUTES.CROP_IMAGE}
				block
				className='crop-image-button'
				disabled={!mainMediaImage}
				onClick={() => setIsModalOpen(true)}
			>
				<i className={'fa fa-crop'} /> {t('crop_image')}
			</Button>
			{mainMediaImage && (
				<ImageCropContainer
					t={t}
					isWatermarkAdded={false}
					isWatermarkRemoved={false}
					imageId={mainMediaImage.resourceId || ''}
					currentProject={project}
					open={isModalOpen}
					onClose={() => setIsModalOpen(false)}
				/>
			)}
		</>
	);
};
