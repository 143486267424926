import React, { useEffect } from 'react';
import DangerouslySetInner from '../../../../social-feed-selection-sidebar/subcomponents/dangerously-set-inner.component';
import WidgetLoader from '../../../partials/widget-loader/widget-loader.component';

export type Properties = {
	blockContent: any;
	config?: any;
	lang?: string;
};

export const WidgetPreview: React.FunctionComponent<Properties> = ({ blockContent, config, lang }) => {
	const eventId = config && config.options && config.options.eventId ? config.options.eventId : '';
	useEffect(() => {
		return function cleanup() {
			clearEventRequest(eventId);
		};
	});

	if (blockContent) {
		return (
			<>
				<WidgetLoader lang={lang} />
				<DangerouslySetInner html={blockContent} />
			</>
		);
	}

	return null;
};

const clearEventRequest = (eventId: string) => {
	window.dispatchEvent(
		new CustomEvent('unregister_event', {
			detail: {
				eventId,
			},
		}),
	);

	if (window.removeEventsRegister) {
		window.removeEventsRegister();
		window.removeSingleRequestEventListener();
	}
};
