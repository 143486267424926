import 'reflect-metadata';
import { AnyT, jsonMember, jsonObject } from 'typedjson';

@jsonObject()
export default class GroupModel {
	@jsonMember(AnyT)
	public id: any;
	@jsonMember(String)
	public name: string = '';
	@jsonMember(Number)
	public order_in_stage: number = 0;
	@jsonMember(AnyT)
	public standing: any;
}
