import React, { FunctionComponent, useEffect, useState } from 'react';
import { Button, FormGroup } from 'reactstrap';
import Scrollbar from 'react-custom-scrollbars';
import { extractParticipantIdsFromRedux } from '../../helpers/events.helper';
import { EventStatusesTypes } from '../../../../../../constants/event.types';
import EventRow from './event-row';
import { extractRelatedPropertiesNameByUrl, filterReduxRelatedProperties } from '../../helpers/utils';
import { connect } from 'react-redux';
import EventModel from '../../../../../../models/v2/sports-types/eventModel';
import { relatedConstants } from '../../../../../../constants/related.constants';
import EventsMeta from '../../../../../../models/v2/events-meta/events-meta.model';
import Related from '../../../../../../models/related/Related';
import { loadMoreEvents } from '../../helpers/events-request';
import SportsTypesModel from '../../../../../../models/v2/sports-types/sports-types.model';
import { store } from '../../../../../../store/store';
import { multiLingualService } from '../../../../../../App';

type Properties = {
	t: any;
	activeTab: EventStatusesTypes;
	selectedSport: SportsTypesModel;
	dateFromFilter: string;
	dateToFilter: string;
	participantsIds: string[];
	alreadyAddedEventsIds: string[];
};

const EventsScrollbar: FunctionComponent<Properties> = ({
	t,
	activeTab,
	selectedSport,
	dateFromFilter,
	dateToFilter,
	participantsIds,
	alreadyAddedEventsIds,
}) => {
	const [loading, setLoading] = useState<boolean>(false);
	const [eventsMeta, setEventsMeta] = useState<EventsMeta>(new EventsMeta());
	const [loadedEvents, setLoadedEvents] = useState<EventModel[] | null>(null);
	const areEventsAvailable = !!loadedEvents && loadedEvents.length > 0;
	const loadedEventsWithoutSelected = areEventsAvailable
		? loadedEvents && loadedEvents.filter((loadedEvent) => !alreadyAddedEventsIds.includes(loadedEvent.id))
		: [];
	const currentProject = store.getState().project.currentProject;
	const language =
		multiLingualService.checkIfProjectIsMultiLingual(currentProject.languages) && multiLingualService.checkRoutePath()
			? multiLingualService.setCorrectAcceptLanguageCode(currentProject.languages.defaultLanguageCode.languageCode)
			: currentProject.language.split('-')[0];

	const loadMore = (resetLimit: boolean = false) => {
		setLoading(true);
		loadMoreEvents(
			selectedSport,
			activeTab,
			dateFromFilter,
			dateToFilter,
			participantsIds,
			resetLimit ? new EventsMeta() : eventsMeta,
			setLoadedEvents,
			setEventsMeta,
			setLoading,
			language,
		);
	};

	const resetEntitiesLimitAndLoadData = () => {
		setEventsMeta(new EventsMeta());
		loadMore(true);
	};

	useEffect(() => {
		resetEntitiesLimitAndLoadData();
	}, [selectedSport, activeTab, participantsIds && participantsIds.length, dateFromFilter, dateToFilter]);

	return (
		<FormGroup>
			<Scrollbar style={{ height: areEventsAvailable ? 300 : 100 }} className={loading ? 'loading-overlay' : ''}>
				{areEventsAvailable ? (
					<>
						{loadedEventsWithoutSelected &&
							loadedEventsWithoutSelected.map((event: EventModel) => {
								return <EventRow event={event} key={`event-row-${event.id}`} />;
							})}
						<div className='d-flex justify-content-center'>
							{eventsMeta.limit && eventsMeta.total && eventsMeta.limit < eventsMeta.total && (
								<Button id='load-more-matches-button' color='primary' outline className='w-80 mt-3 mb-2' onClick={() => loadMore()}>
									{t('load_more')}
								</Button>
							)}
						</div>
					</>
				) : (
					<div className='text-center p-3'>{t('no_matches_found')}</div>
				)}
			</Scrollbar>
		</FormGroup>
	);
};

function mapStateToProps(state: any, componentProps: { selectedSport: SportsTypesModel }) {
	const relatedPropertiesNestedProp = extractRelatedPropertiesNameByUrl();
	const reduxRelatedDataForSportsConnection: Related[] | null = relatedPropertiesNestedProp
		? state.tempSportsRelated[relatedPropertiesNestedProp]
		: null;
	const reduxRelatedDataForEvents: Related[] | null = relatedPropertiesNestedProp
		? state.tempMatchRelated[relatedPropertiesNestedProp]
		: null;
	const alreadyAddedEventsInRedux = filterReduxRelatedProperties(componentProps.selectedSport, reduxRelatedDataForEvents, [
		relatedConstants.types.match,
		relatedConstants.types.game,
	]);

	return {
		participantsIds: extractParticipantIdsFromRedux(componentProps.selectedSport, reduxRelatedDataForSportsConnection),
		alreadyAddedEventsIds:
			alreadyAddedEventsInRedux && alreadyAddedEventsInRedux.filtered ? alreadyAddedEventsInRedux.filtered.map((el) => el.data.id) : [],
	};
}

export default connect(mapStateToProps)(EventsScrollbar);
