import Image from '../../../../../../models/image/Image';
import Related from '../../../../../../models/related/Related';
import CaptionBuilder from './caption.builder';

export const displayImageCaption = (imageToEnlarge: Image, imageRelated: Related[], t: any) => {
	if (imageToEnlarge && imageRelated) {
		const caption = new CaptionBuilder();

		return caption
			.withType(imageToEnlarge.type, t)
			.withDescription(imageToEnlarge.description, t)
			.withOwnerName(imageToEnlarge.owner, t)
			.withPlayers(extractPlayerNames(imageRelated), t)
			.withTeams(extractTeamNames(imageRelated), t)
			.build();
	}

	return '';
};

export const extractTeamNames = (related: Related[]) => {
	if (related) {
		return related
			.filter((imageRelated: any) => imageRelated.type === 'team')
			.map((imageRelated: any) => imageRelated.data.name)
			.join(', ');
	}

	return '';
};

export const extractPlayerNames = (related: Related[]) => {
	if (related) {
		return related
			.filter((imageRelated: any) => imageRelated.type === 'player')
			.map((imageRelated: any) => imageRelated.data.name)
			.join(', ');
	}

	return '';
};
