import React from 'react';
import { updateTranslationName } from '../helpers/translations.helpers';
import { Col, Label, Input } from 'reactstrap';
import EnumItem from '../../../../models/enum/EnumItem';

type Properties = {
	t: any;
	language: EnumItem;
	entity: any;
	selectedEntity: any;
	translationChange: (translations: any[]) => void;
	translations: any[];
};

export default class SingleTranslationComponent extends React.Component<Properties> {
	constructor(props: Properties) {
		super(props);
		this.state = {
			translations: props.translations,
		};
	}

	onTranslationChange = (e: any, index: number) => {
		const translations = updateTranslationName(this.props.translations, index, e);
		this.props.translationChange(translations);
	};

	render() {
		const { t, translations } = this.props;

		if (translations && translations.length > 0) {
			return (
				<Col xs='6'>
					<Label>{t('translate')}</Label>
					<Input
						id='translation-input'
						placeholder={t('translate')}
						value={translations[0].content.name}
						onChange={(e: any) => this.onTranslationChange(e, 0)}
					/>
				</Col>
			);
		}

		return null;
	}
}
