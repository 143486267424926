import React, { FunctionComponent, useEffect, useState } from 'react';
import ListModel from '../../../../../../models/v2/list/list';
import { connect } from 'react-redux';
import {
	LIST_ENTITY_RECEIVED,
	LIST_ENTITY_UPDATE,
	LIST_ENTITY_UPDATE_DIRECTLY,
	LIST_ENTITY_UPDATE_SUCCESS,
	updateListInRedux,
} from '../../../../../../store/action-creators/ListActionCreators';
import Loader from 'react-spinners/BeatLoader';
import { Subscription } from 'rxjs';
import { actionService } from '../../../../../../App';
import { DATA_QA_ATTRIBUTES } from '../../../../../Partials/Sidebar/general-content/constants/data-qa.attributes';
import { getListDetails } from '../../../../../Partials/Sidebar/list-refactored/helpers/list-http.helper';
import './style/live-blog-list.style.scss';
import { Col, Row } from 'reactstrap';
import { isListEmpty } from '../../../../../Partials/Sidebar/list-refactored/helpers/general.helper';
import { getPresentInData } from './live-blog-lists.helper';
import { isTempIdOrEmpty } from '../../../../../../global-helpers/global.helpers';

type Properties = {
	t: any;
	liveBlogId: string;
	projectDomain: string;
	updateListInRedux: (list: ListModel) => void;
	setToggleListItemsModal: (isOpen: boolean) => void;
	selectedList: ListModel | null;
};

const LiveBlogListPresentIn: FunctionComponent<Properties> = ({
	t,
	liveBlogId,
	projectDomain,
	updateListInRedux,
	setToggleListItemsModal,
	selectedList,
}) => {
	let listEntitySubscription: Subscription = {} as Subscription;
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [listPresentIn, setListPresentIn] = useState<ListModel[]>([]);

	const fetchPresentInResources = (withDelay = false) => {
		if (!isTempIdOrEmpty(liveBlogId)) {
			setIsLoading(true);
			if (withDelay) {
				// Timeout is necessary because BE list search will not yield up-to-date results immediately after list PATCH
				setTimeout(() => getPresentInData(liveBlogId, projectDomain, setIsLoading, setListPresentIn), 1000);
			} else {
				getPresentInData(liveBlogId, projectDomain, setIsLoading, setListPresentIn);
			}
		}
	};

	useEffect(() => {
		fetchPresentInResources();
	}, []);

	useEffect(() => {
		listEntitySubscription = actionService.onActionReceived().subscribe((action: string) => {
			if (action === LIST_ENTITY_RECEIVED || action === LIST_ENTITY_UPDATE_SUCCESS) {
				fetchPresentInResources(true);
			}
		});

		return () => listEntitySubscription.unsubscribe();
	}, [liveBlogId]);

	const onSelectPresentedList = async (selectedListId: string) => {
		const remappedListData = await getListDetails(selectedListId, projectDomain);
		updateListInRedux(remappedListData);
		setToggleListItemsModal(true);
	};

	return (
		<>
			{listPresentIn
				.filter(({ items }) => items.some(({ data }) => data && data.id === liveBlogId))
				.map((list: ListModel, index) => (
					<div className={`card list-present-in ${isLoading ? 'opacity-03' : ''}`} key={DATA_QA_ATTRIBUTES.LIST_PRESENT_IN_CONTAINER + index}>
						<div className='card-body'>
							<Row>
								<Col>
									<strong>{list.title}</strong>
								</Col>
								<Col md={1}>
									<div onClick={() => onSelectPresentedList(list.id)} className='cursor-pointer'>
										<i className='fa fa-pen p-1' />
									</div>
								</Col>
							</Row>
						</div>
					</div>
				))}
			{isLoading && (
				<div className='position-absolute h-100 d-flex align-items-center opacity-03' style={{ top: '0', right: '50%' }}>
					<Loader size={5} />
				</div>
			)}
		</>
	);
};

function mapStateToProps(state: any) {
	return {
		projectDomain: state.project.currentProject.domain || '',
		selectedList: (!isListEmpty(state.list.list) && state.list.list) || null,
	};
}

function mapDispatchToProps(dispatch: Function) {
	return {
		updateListInRedux: (list: ListModel) => dispatch(updateListInRedux(list)),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(LiveBlogListPresentIn);
