import React, { FunctionComponent, useEffect, useState } from 'react';
import CustomBlockListingRowModel from '../../../models/v2/custom-blocks/custom-block-listing-row/custom-block-listing-row.model';
import { analyticsService, customBlocksService } from '../../../App';
import { DebounceInput } from 'react-debounce-input';
import { Link } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { LoadingOverlayComponent } from '../../Partials/Blocky/loading-overlay/loading-overlay';

export const CustomBlocksListing: FunctionComponent<any> = ({ t, match }) => {
	const [customBlockListing, setCustomBlockListing] = useState<CustomBlockListingRowModel[]>([]);
	const [customBLockListingSearch, setCustomBlockListingSearch] = useState<CustomBlockListingRowModel[]>([]);
	const [searchText, setSearchText] = useState<string>('');
	const [loading, setLoading] = useState(false);

	const loadCustomBlocks = (
		setCustomBlockListing: (arg: CustomBlockListingRowModel[]) => void,
		setCustomBlockListingSearch: (arg: CustomBlockListingRowModel[]) => void,
		setLoading: (arg: boolean) => void,
	) => {
		setLoading(true);
		customBlocksService
			.getCustomBlocksListing()
			.then((customBlocks) => {
				setCustomBlockListing(customBlocks);
				setCustomBlockListingSearch(customBlocks);
				setLoading(false);
			})
			.catch((e: any) => {
				setLoading(false);
			});
	};

	useEffect(() => {
		initPageTitle();
	}, []);

	const initPageTitle = () => {
		document.title = t('custom_blocks');
	};

	const deleteCustomBlock = (id: string, t: any) => {
		setLoading(true);
		customBlocksService.deleteCustomBlock(id, t).then(() => {
			customBlocksService
				.getCustomBlocksListing()
				.then((customBlocks) => {
					setCustomBlockListing(customBlocks);
					setCustomBlockListingSearch(customBlocks);
					setLoading(false);
				})
				.catch((e: any) => {
					console.log(e);
					setLoading(false);
				});
		});
	};

	const SearchCustomBlock: FunctionComponent<any> = ({ t, onSearch }) => {
		return (
			<DebounceInput
				type='text'
				id='search-custom-block-input'
				className='form-control rounded-0 pr-4'
				placeholder={t('content_search_placeholder')}
				value={searchText ? searchText : ''}
				onChange={(evt) => onSearch(evt.target.value)}
				debounceTimeout={500}
			/>
		);
	};

	useEffect(() => {
		loadCustomBlocks(setCustomBlockListing, setCustomBlockListingSearch, setLoading);
	}, [customBlockListing.length]);

	return (
		<div className='card animated fadeIn'>
			<div className='card-header'>
				<div className='d-flex mb-3 mb-sm-0 mb-md-0 mb-lg-0'>
					<span className='mr-auto mb-0'>{t('custom_blocks')}</span>
				</div>
			</div>
			<div className='card-body'>
				<div className='d-flex flex-column flex-sm-row flex-md-row flex-lg-row justify-content-between align-items-center'>
					<div className='col-sm-12 col-md-6'>
						<CreateCustomBlockButton t={t} />
					</div>
					<SearchCustomBlock
						t={t}
						onSearch={(text: string) => {
							setSearchText(text);
							setCustomBlockListingSearch(customBlocksService.customBlockSearch(text, customBlockListing));
						}}
					/>
				</div>
				<div className='px-1 py-3'>
					<Row className='font-weight-bold py-2 border-top border-bottom'>
						<Col className='w-80'>{t('title')}</Col>
						<Col className='text-right w-20 '>{t('actions')}</Col>
					</Row>
					<LoadingOverlayComponent loading={loading}>
						{customBLockListingSearch &&
							customBLockListingSearch.length > 0 &&
							customBLockListingSearch.map((customBlockRow, index) => {
								return (
									<Row
										key={customBlockRow.id}
										id={`custom-block-row-${customBlockRow.id}`}
										className={`py-2 border-bottom align-items-center ${index % 2 === 1 ? '' : 'bg-light'}`}
									>
										<Col className='pl-2'>{customBlockRow.name}</Col>
										<Col className='text-right'>
											<EditCustomBlockButton t={t} id={customBlockRow.id} />

											<Button id={`custom-block-btn-${customBlockRow.id}`} color='danger' onClick={() => deleteCustomBlock(customBlockRow.id, t)}>
												<i className='fa fa-remove p-1' />
												{t('delete')}
											</Button>
										</Col>
									</Row>
								);
							})}
					</LoadingOverlayComponent>
				</div>
			</div>
		</div>
	);
};

const EditCustomBlockButton: FunctionComponent<any> = ({ t, id }) => {
	return (
		<Link
			to={`/smp/custom-blocks/edit/${id}`}
			id='edit-custom-block'
			className='btn btn-warning mr-1'
			onClick={() => analyticsService.sendEvent('Click', 'List Page', 'Edit custom block')}
		>
			<i className={'fa fa-pencil p-1'}>&nbsp;</i>
			{t('edit')}
		</Link>
	);
};

const CreateCustomBlockButton: FunctionComponent<any> = ({ t }) => {
	return (
		<Link
			to={'/smp/custom-blocks/create'}
			id='new-custom-block'
			className='btn btn-primary'
			onClick={() => analyticsService.sendEvent('Click', 'List Page', 'Create custom block')}
		>
			<i className={'fa fa-plus-circle'}>&nbsp;</i>
			{t('new_custom_block')}
		</Link>
	);
};
