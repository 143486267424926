import React from 'react';
import { Col, Label, Row } from 'reactstrap';
import ListModel from '../../../../../../models/v2/list/list';
import { connect } from 'react-redux';
import { autoGenerateReduxPropsNamingForContentModels } from '../../../../../../services/content-models-service/ContentModelService';
import { getImageSrcOrPlaceholderImage, isListEmpty, shouldShowListItem } from '../../helpers/general.helper';
import { extractMainContentTypeBasedOnUrl, isObjectEmpty, isTempIdOrEmpty } from '../../../../../../global-helpers/global.helpers';
import ListItemAddButtons from './list-item-add-buttons';
import { imagePlaceholderBase64 } from '../../../../../../global-helpers/global.constants';

type Properties = {
	t: any;
	selectedList: ListModel | null;
	contentModelId: string;
	contentModelTitle: string;
	contentModelCategory: string;
	contentModelImage: string;
	shouldShowListImages: boolean;
};

const ListAddItemContainer: React.FunctionComponent<Properties> = ({
	t,
	selectedList,
	contentModelId,
	contentModelTitle,
	contentModelCategory,
	contentModelImage,
	shouldShowListImages,
}) => {
	if (!selectedList || isTempIdOrEmpty(contentModelId)) {
		return null;
	}

	const listItemIsAddedToTheList = shouldShowListItem(selectedList, contentModelId);
	if (listItemIsAddedToTheList) {
		return null;
	}
	const contentTypeBasedOnUrl = extractMainContentTypeBasedOnUrl();
	const imageSrc = getImageSrcOrPlaceholderImage(contentModelImage);
	const disabled = !contentModelTitle || !contentModelCategory;
	const labelStyle = disabled ? 'text-danger' : '';

	return (
		<Row>
			<Col>
				<Label className={labelStyle}>
					{disabled ? t(`fill_${contentTypeBasedOnUrl}_required_fileds`) : t(`add_current_${contentTypeBasedOnUrl}_to_list`)}
				</Label>
				{!disabled && (
					<li className='btn list-group-item mb-2 p1 cursor-pointer'>
						<Row>
							<Col className='d-flex'>
								<div className='list-content d-flex align-items-center w-100'>
									<div className='mr-2'>
										<ListItemAddButtons t={t} selectedList={selectedList} />
									</div>
									<div className={'d-flex justify-content-between align-items-center overflow-hidden'}>
										{shouldShowListImages && (
											<div className='image-wrapper mr-2'>
												<img
													alt={contentModelTitle}
													src={imageSrc}
													width='30'
													className='max-width-100 rounded-circle'
													onError={(i: any) => (i.target.src = imagePlaceholderBase64)}
												/>
											</div>
										)}
										<div className={'content-wrapper overflow-hidden'}>
											<h6 className='mb-0 text-truncate'>{contentModelTitle}</h6>
										</div>
									</div>
								</div>
							</Col>
						</Row>
					</li>
				)}
			</Col>
		</Row>
	);
};

function mapStateToProps(state: any) {
	const { tempPropertyNaming, nestedTempPropertyNaming } = autoGenerateReduxPropsNamingForContentModels();

	return {
		selectedList: (!isListEmpty(state.list.list) && state.list.list) || null,
		contentModelId: state[tempPropertyNaming][nestedTempPropertyNaming].id || '',
		contentModelTitle: state[tempPropertyNaming][nestedTempPropertyNaming].title || '',
		contentModelCategory: state[tempPropertyNaming][nestedTempPropertyNaming].category || '',
		contentModelImage:
			(state[tempPropertyNaming][nestedTempPropertyNaming].image &&
				state[tempPropertyNaming][nestedTempPropertyNaming].image.data &&
				state[tempPropertyNaming][nestedTempPropertyNaming].image.data.path) ||
			'',
		shouldShowListImages: state.ui.shouldShowListImages[state.profile.profile.id] || false,
	};
}

export default connect(mapStateToProps)(ListAddItemContainer);
