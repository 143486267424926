import OddsWidgetModel from './odds-widget.model';
import { OddsWidgetJson } from './odds-widget.json';
import MatchModel from '../../../../partials/match-select/models/match.model';
import TeamModel from '../../../../partials/team-select/models/team.model';
import BookmakerModel from '../../../../partials/bookmaker-select/models/bookmaker.model';

export default class OddsWidgetBuilder {
	readonly json: OddsWidgetJson;

	constructor(odds?: OddsWidgetModel | OddsWidgetJson) {
		if (odds && odds instanceof OddsWidgetModel) {
			this.json = odds.toJson();
		} else if (odds) {
			this.json = odds;
		} else {
			this.json = {} as OddsWidgetJson;
		}
	}

	withTeam(team: TeamModel): OddsWidgetBuilder {
		this.json.team = team;

		return this;
	}

	withMatch(match: MatchModel): OddsWidgetBuilder {
		this.json.match = match;

		return this;
	}

	withBookmakers(bookmakers: BookmakerModel[] | null): OddsWidgetBuilder {
		this.json.bookmakers = bookmakers;

		return this;
	}

	withMainBookmaker(mainBookmaker: BookmakerModel | null): OddsWidgetBuilder {
		this.json.mainBookmaker = mainBookmaker;

		return this;
	}

	withDisplayHeader(displayMatchHeader: boolean): OddsWidgetBuilder {
		this.json.displayMatchHeader = displayMatchHeader;

		return this;
	}

	withCanSelectMarkets(canSelectMarkets: boolean): OddsWidgetBuilder {
		this.json.canSelectMarkets = canSelectMarkets;

		return this;
	}

	withMarket(market: any): OddsWidgetBuilder {
		this.json.market = market;

		return this;
	}

	withThreeLetterCodeType(threeLetterCodeType: string): OddsWidgetBuilder {
		this.json.threeLetterCodeType = threeLetterCodeType;

		return this;
	}

	build(): OddsWidgetModel {
		return OddsWidgetModel.fromJson(this.json);
	}
}
