import { PlayerProfileJson } from './player-profile.json';
import PlayerProfileModel from './player-profile';

export default class PlayerProfileBuilder {
	private json: PlayerProfileJson;

	constructor(profile?: PlayerProfileModel | PlayerProfileJson) {
		if (profile && profile instanceof PlayerProfileModel) {
			this.json = profile.toJson();
		} else if (profile) {
			this.json = profile;
		} else {
			this.json = {} as PlayerProfileJson;
		}
	}

	withHeight(height: string): PlayerProfileBuilder {
		this.json.height = height;

		return this;
	}

	withWeight(weight: string): PlayerProfileBuilder {
		this.json.weight = weight;

		return this;
	}

	build(): PlayerProfileModel {
		return PlayerProfileModel.fromJson(this.json);
	}
}
