import { ContentTypes } from '../../../constants/content-types';
import ModelMapper from '../../../models/ModelMapper';
import { responseToLiveBlogCamelCaseModel } from '../../Pages/Live Blog/models/live-blog-camelcase.mapper';
import {
	isPermitted,
	PermissionTypes,
} from '../../Partials/BaseComponents/ConditionalRenderWraper/helpers/ConditionalRenderPermissionsHelper';

export default class QuickAccessListingHelper {
	mapContentFromResponse = (resp: any) => {
		return resp.data.data.items.map((data: any) => {
			return {
				content: this.chooseMapper(data),
				type: data.type,
			};
		});
	};

	chooseMapper = (data: any) => {
		if (data.type === 'ARTICLE') {
			return ModelMapper.remapArticleFromResponse(data.data);
		}
		if (data.type === 'VIDEO') {
			return ModelMapper.remapVideoFromResponse(data.data);
		}
		if (data.type === 'GALLERY') {
			return ModelMapper.remapGalleryFromResponse(data.data);
		}
		if (data.type === ContentTypes.LIVE_BLOG.toUpperCase()) {
			return responseToLiveBlogCamelCaseModel(data.data);
		}
	};

	checkPermission = (permissions: any[], type: string) => {
		const permissionTypes = {
			VIDEO: ['read_videos', 'write_videos', 'delete_videos'],
			ARTICLE: ['read_articles', 'write_articles', 'delete_articles'],
			GALLERY: ['read_galleries', 'write_galleries', 'delete_galleries'],
			LIVE_BLOG: ['read_liveblogposts', 'write_liveblogposts', 'delete_liveblogposts'],
		};

		return {
			canRead: isPermitted(permissionTypes[type], permissions, PermissionTypes.READ),
			canWrite: isPermitted(permissionTypes[type], permissions, PermissionTypes.WRITE),
			canDelete: isPermitted(permissionTypes[type], permissions, PermissionTypes.DELETE),
		};
	};
}
