import { TreeItem } from 'react-sortable-tree';
import MultisportModel from '../models/sport.model';
import MultisportEventModel from '../models/event.model';
import { MultisportCompetitionModel } from '../models/competition.model';

const generateTreeStructureForEvents = (
	events: MultisportEventModel[],
	competition: MultisportCompetitionModel,
	previewSection: string,
) => {
	return events.map((event) => {
		return {
			id: event.id,
			title: event.name,
			expanded: true,
			children: [],
			competitionId: competition.id,
			entityType: event.entity_type,
			previewSection,
			sport: event.sport,
			element: event,
		};
	});
};

export const formatEventsPreviewToDndTree = (previewEvents: MultisportModel): TreeItem[] => {
	const result: TreeItem[] = [];
	const previewSection = previewEvents.sport;

	previewEvents.competitions.forEach(({ competition, events }) => {
		result.push({
			id: competition.id,
			title: competition.name,
			expanded: true,
			entityType: competition.entity_type,
			previewSection,
			children: generateTreeStructureForEvents(events, competition, previewSection),
			element: competition,
		});
	});

	return result;
};
