import TeamPlayerModel from './team-player.model';
import { TeamPlayerJson } from './team-player.json';
import TeamModel from '../../team-select/models/team.model';

export default class TeamPlayerBuilder {
	private json: TeamPlayerJson;

	constructor(teamPlayer?: TeamPlayerModel | TeamPlayerJson) {
		if (teamPlayer && teamPlayer instanceof TeamPlayerModel) {
			this.json = teamPlayer.toJson();
		} else if (teamPlayer) {
			this.json = teamPlayer;
		} else {
			this.json = {} as TeamPlayerJson;
		}
	}

	withId(id: string): TeamPlayerBuilder {
		this.json.id = id;

		return this;
	}

	withName(name: string): TeamPlayerBuilder {
		this.json.name = name;

		return this;
	}

	withLogo(logo: string): TeamPlayerBuilder {
		this.json.logo = logo;

		return this;
	}

	withTeam(team: TeamModel): TeamPlayerBuilder {
		this.json.team = team;

		return this;
	}

	withBirthDate(birthdate: string): TeamPlayerBuilder {
		this.json.birthdate = birthdate;

		return this;
	}

	withPosition(position: string): TeamPlayerBuilder {
		this.json.position = position;

		return this;
	}

	withActive(active: boolean): TeamPlayerBuilder {
		this.json.active = active;

		return this;
	}

	build(): TeamPlayerModel {
		return TeamPlayerModel.fromJson(this.json);
	}
}
