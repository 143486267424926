import React from 'react';
import { ViewTypes } from '../../../../constants/general.constants';
import { Properties } from './properties/top-scorers-widget.properties';
import { TopScorersWidgetView } from './top-scorers-widget-view.component';
import TopScorersWidgetEdit from './top-scorers-widget-edit.component';
import TopScorersWidgetModel from '../models/top-scorers-widget.model';
import { WidgetPreview } from '../../widget-preview/widget-preview.component';
import BlockUpdateController from '../../../block-update-controller.component';

const TopScorers: React.FunctionComponent<Properties> = (props) => {
	if (props.view === ViewTypes.edit) {
		return <TopScorersWidgetEdit block={props.block} t={props.t} />;
	}

	if (props.view === ViewTypes.normal) {
		return (
			<TopScorersWidgetView
				blockPreview={
					props.block.data.preview && props.block.data.preview.topScorers
						? props.block.data.preview.topScorers
						: TopScorersWidgetModel.builder().build()
				}
				t={props.t}
			/>
		);
	}

	if (props.view === ViewTypes.preview) {
		return <WidgetPreview blockContent={props.block.data.content} config={props.block.data.config} />;
	}

	return null;
};

export default BlockUpdateController(TopScorers);
