import MatchModel from '../../views/Resources/match-lineups/models/match/match.model';
import MatchDetailsModel from '../../views/Resources/match-lineups/models/match/match-details.model';
import { MatchesFilters } from '../reducers/match-lineups-edit.reducer';

export const MATCH_LINEUPS_UPDATE = '[ACTION] MATCH_LINEUPS_UPDATE';
export const MATCH_LINEUPS_CREATE = '[ACTION] MATCH_LINEUPS_CREATE';
export const MATCH_LINEUPS_REQUEST = '[ACTION] MATCH_LINEUPS_REQUEST';
export const MATCH_EVENTS_RECEIVED = '[ACTION] MATCH_EVENTS_RECEIVED';
export const MATCH_TEAMS_UPDATE = '[ACTION] MATCH_TEAMS_UPDATE';
export const MATCH_FILTERS_UPDATE = '[ACTION] MATCH_FILTERS_UPDATE';
export const MATCH_DATE_RANGE_UPDATE = '[ACTION] MATCH_DATE_RANGE_UPDATE';

export const MATCH_BLACKLIST_REQUEST = '[ACTION] MATCH_BLACKLIST_REQUEST';
export const MATCH_BLACKLIST_RECEIVED = '[ACTION] MATCH_BLACKLIST_RECEIVED';

export function updateMatchLineup(match: MatchDetailsModel | null) {
	return {
		type: MATCH_LINEUPS_UPDATE,
		payload: match,
	};
}

export function updateMatchCreateLineup(match: MatchDetailsModel | null) {
	return {
		type: MATCH_LINEUPS_CREATE,
		payload: match,
	};
}

export function requestMatchLineup(match: MatchModel) {
	return {
		type: MATCH_LINEUPS_REQUEST,
		payload: match,
	};
}

export function updateMatchEventsList(matches: MatchModel[]) {
	return {
		type: MATCH_EVENTS_RECEIVED,
		payload: matches,
	};
}

export function updateMatchTeams(teams: any[]) {
	return {
		type: MATCH_TEAMS_UPDATE,
		payload: teams,
	};
}

export function updateMatchFilters(filters: MatchesFilters) {
	return {
		type: MATCH_FILTERS_UPDATE,
		payload: filters,
	};
}

export function updateMatchDateRange(dateRange: string[]) {
	return {
		type: MATCH_DATE_RANGE_UPDATE,
		payload: dateRange,
	};
}

export function returnObjectForMatchesBlacklistReceived(matches: any) {
	return {
		type: MATCH_BLACKLIST_RECEIVED,
		payload: matches,
	};
}
