import React from 'react';
import { ErrorAsyncProps, ErrorAsyncState } from './properties/ComponentProps';
import { actionService } from '../../../../App';
import * as helpers from './helpers/helpers';
import { Subscription } from 'rxjs';
import { errorsMap } from './errorsMap';

class ErrorAsync extends React.Component<ErrorAsyncProps, ErrorAsyncState> {
	private subscriberErrors: Subscription = {} as Subscription;
	private subscriberSuccess: Subscription = {} as Subscription;

	constructor(props: any) {
		super(props);
		this.state = {
			errors: null,
		};
	}

	componentDidMount(): void {
		this.initErrorListener();
	}

	componentWillUnmount() {
		this.subscriberErrors.unsubscribe();
		this.subscriberSuccess.unsubscribe();
	}

	initErrorListener() {
		this.subscriberErrors = actionService.onError().subscribe((errors: any) => {
			if (errors.response) {
				this.setState({ errors: errors.response.data });
			}
		});
		this.subscriberSuccess = actionService.onGeneralAction().subscribe((action: any) => {
			const isSuccessAction = action.includes('SUCCESS');

			if (isSuccessAction) {
				this.setState({ errors: null });
			}
		});
	}

	render() {
		let style = this.props.customStyle ? this.props.customStyle : 'alert alert-danger';
		const { customError } = this.props;
		const serverErrorStatusCode = 500;

		if (customError && customError.code && customError.code !== serverErrorStatusCode) {
			const errorMessage = helpers.defineErrorMessage(customError, this.props.errorType);

			return errorMessage ? <div className={style}>{this.props.t(errorsMap(errorMessage, this.props.sources))}</div> : null;
		} else if (customError && customError.code === serverErrorStatusCode) {
			return <div className={style}>{customError.error}</div>;
		}

		if (this.state.errors && this.state.errors.errors) {
			const errorMessage = helpers.defineErrorMessage(this.state.errors, this.props.errorType, this.props.mainMedia, this.props.sources);

			return errorMessage ? <div className={style}>{this.props.t(errorsMap(errorMessage, this.props.sources))}</div> : null;
		}

		if (this.state.errors && this.state.errors.error) {
			const errorMessage = helpers.defineErrorMessage(this.state.errors);

			return errorMessage ? <div className={style}>{this.props.t(errorsMap(errorMessage))}</div> : null;
		}

		if (this.props.errorType === 'error' && this.state.errors && this.state.errors.message && !this.state.errors.errorType) {
			return <div className={style}>{this.state.errors.message}</div>;
		} else {
			return null;
		}
	}
}

export default ErrorAsync;
