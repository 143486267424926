import 'reflect-metadata';
import { AnyT, jsonArrayMember, jsonMember, jsonObject } from 'typedjson';
import Country from '../../Country/Country.model';
import Translation from '../../../../views/Partials/Blocky/partials/sports-team-select/models/translation.model';
import AssetsModel from '../../sports-types/assets.model';

@jsonObject()
export default class PlayerModel {
	@jsonMember(AnyT)
	public id: any = '';

	@jsonMember(String)
	public name: string = '';

	@jsonMember(String)
	public position: string = '';

	@jsonMember(Boolean)
	public active: boolean = false;

	@jsonMember(String)
	public birthdate: string = '';

	@jsonMember(String)
	public gender: string = '';

	@jsonMember(String)
	public height: string = '';

	@jsonMember(String)
	public weight: string = '';

	@jsonMember(Country)
	public country: Country = new Country();

	@jsonMember(String, { name: 'url_image' })
	public urlImage: string = '';

	@jsonMember(String, { name: 'url_thumb' })
	public urlThumb: string = '';

	@jsonMember(String)
	public sport: string = '';

	@jsonArrayMember(Translation)
	public translations: Translation[] = [];

	@jsonMember(AssetsModel, { name: 'display_asset' })
	public displayAsset: AssetsModel = new AssetsModel();

	@jsonMember(String, { name: 'competition_ids' })
	public competitionIds: any;

	@jsonMember(String, { name: 'team_ids' })
	public teamIds: any = '';

	@jsonMember(String, { name: 'country_id' })
	public countryId: string = '';

	@jsonMember(String)
	public slug: string | null = null;
}
