import React from 'react';
import { Row, Col, Label, Button } from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Properties, State } from './Properties/BannerEditProperties';
import { Title } from '../../Partials/Fields/title/TitleComponent';
import Banner from '../../../models/banner/Banner';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Project from '../../../models/project/Project';
import {
	BANNER_ENTITY_RECEIVED,
	BANNER_ENTITY_REQUEST,
	BANNER_ENTITY_UPDATE,
	BANNER_ENTITY_UPDATE_FAILED,
	BANNER_ENTITY_UPDATE_SUCCESS,
	returnObjectForBannerEntityRequest,
	returnObjectForBannerEntityUpdate,
} from '../../../store/action-creators/BannerActionCreators';
import { Code } from '../../Partials/Fields/code/CodeComponent';
import BlockableButtonWrapper from '../../Partials/BaseComponents/BlockableButton/BlockableButtonWrapper';
import { actionService } from '../../../App';
import { Subscription } from 'rxjs';
import StatusSelect from '../../Partials/Sidebar/GeneralContentAttributes/subcomponents/status-select/status-select';
import { ContentTypes } from '../../Partials/Blocky/constants/block.types';
import ContentPropertiesContainer from '../../Partials/Sidebar/GeneralContentAttributes/subcomponents/ContentProperties/ContentPropertiesContainer';
import { DynamicObjectWithKeys } from '../../../constants/content-types';

class BannerEdit extends React.Component<Properties, State> {
	actionServiceSubscription: Subscription = {} as Subscription;

	constructor(props: Properties) {
		super(props);
		this.state = {
			banner: props.banner,
			isContentLoading: false,
		};
		this.registerActionEventListener();
	}

	registerActionEventListener() {
		this.actionServiceSubscription = actionService.onActionReceived().subscribe((action: string) => {
			if (action === BANNER_ENTITY_REQUEST) {
				this.toggleContentLoadingState(true);
			}

			if (action === BANNER_ENTITY_RECEIVED) {
				this.toggleContentLoadingState(false);
			}
		});
	}

	toggleContentLoadingState(isLoading: boolean) {
		this.setState({
			...this.state,
			isContentLoading: isLoading,
		});
	}

	componentDidMount(): void {
		this.initPageTitle();
		this.props.getBanner(this.props.match.params.id, this.props.project);
	}

	componentDidUpdate(prevProps: Readonly<Properties>, prevState: Readonly<State>, snapshot?: any): void {
		if (!Banner.builder(prevProps.banner).equals(this.props.banner)) {
			this.setState({ banner: this.props.banner });
		}
	}

	componentWillUnmount() {
		this.actionServiceSubscription.unsubscribe();
	}

	initPageTitle() {
		document.title = this.props.t('banner_edit');
	}

	onTitleChange(title: string) {
		const banner = Banner.builder(this.state.banner).withTitle(title).build();
		this.setState({ banner });
	}

	onCodeChange(code: string) {
		const banner = Banner.builder(this.state.banner).withCode(code).build();
		this.setState({ banner });
	}

	onDynamicPropertiesChange(dynamicProperties: DynamicObjectWithKeys) {
		try {
			const currentProperties = { ...this.state.banner.properties };
			currentProperties[dynamicProperties.key] = dynamicProperties.checked;
			const formattedData = Object.keys(currentProperties).map((dynamicPropertyKey) => {
				return { name: dynamicPropertyKey, slug: dynamicPropertyKey, value: !!currentProperties[dynamicPropertyKey] };
			});
			const banner = Banner.builder(this.state.banner).withDynamicProperties(formattedData).build();
			this.setState({ banner });
		} catch (e) {}
	}

	onStatusChange(status: string) {
		this.setState({ banner: Banner.builder(this.state.banner).withStatus(status).build() });
	}

	onSubmit() {
		this.props.updateBanner(this.state.banner, this.props.project);
	}

	render() {
		const { t, statuses } = this.props;

		return (
			<div className='animated fadeIn'>
				<Row>
					<Col col='12' lg='12' md='12' sm='12' xs='12'>
						<div className='card'>
							<div className='card-header'>
								<div className={'d-flex align-items-center'}>
									<span className={'mr-auto mb-0 text-truncate'}>
										{t('edit')}: {this.props.banner ? this.props.banner.title : ''}
									</span>
									<BlockableButtonWrapper
										blockOnActions={[BANNER_ENTITY_UPDATE]}
										releaseOnActions={[BANNER_ENTITY_UPDATE_SUCCESS, BANNER_ENTITY_UPDATE_FAILED]}
									>
										<Button size='sm' color='primary' id='banner-edit-save-top' className={'ml-auto text-nowrap'} onClick={this.onSubmit.bind(this)}>
											<i className='fa fa-floppy-o'></i> {t('save_banner')}
										</Button>
									</BlockableButtonWrapper>
								</div>
							</div>
							<div className='card-body'>
								<div className={`${this.state.isContentLoading ? 'loading-overlay' : ''}`}>
									<Row>
										<Col xs='12'>
											<Label htmlFor='title'>{t('title')}</Label>
											<Title t={t} onChange={this.onTitleChange.bind(this)} value={this.state.banner ? this.state.banner.title : ''} />
										</Col>
									</Row>

									<Row>
										<Col xs='12'>
											<Code t={t} onChange={this.onCodeChange.bind(this)} value={this.state.banner ? this.state.banner.code : ''} />
										</Col>
									</Row>
									<Row>
										<Col xs='12'>
											<Label htmlFor='title'>{t('status')}</Label>
											<StatusSelect
												t={t}
												isClearable
												onStatusSelect={this.onStatusChange.bind(this)}
												selectedStatus={this.state.banner.status}
												statuses={statuses && statuses[`${ContentTypes.banner}Statuses`]}
												contentType={ContentTypes.banner}
											/>
										</Col>
									</Row>

									<Row className='mt-4'>
										<Col xs='12'>
											<ContentPropertiesContainer
												t={t}
												onPropertySelected={this.onDynamicPropertiesChange.bind(this)}
												content={{ properties: this.state.banner.properties }}
												contentType={ContentTypes.banner}
											/>
										</Col>
									</Row>
									<Row className={'mt-4'}>
										<Col xs='12'>
											<BlockableButtonWrapper
												blockOnActions={[BANNER_ENTITY_UPDATE]}
												releaseOnActions={[BANNER_ENTITY_UPDATE_SUCCESS, BANNER_ENTITY_UPDATE_FAILED]}
											>
												<Button
													color={'primary'}
													id='banner-edit-save-bottom'
													className={'text-uppercase font-weight-bold'}
													onClick={this.onSubmit.bind(this)}
												>
													<i className='fa fa-floppy-o'></i> {t('save_banner')}
												</Button>
											</BlockableButtonWrapper>
										</Col>
									</Row>
								</div>
							</div>
						</div>
					</Col>
				</Row>
			</div>
		);
	}
}

function mapStateToProps(state: any) {
	return {
		banner: state.banner.banner,
		project: state.project.currentProject,
		statuses: state.statuses,
	};
}

function mapDispatchToProps(dispatch: any) {
	return {
		updateBanner: (banner: Banner, project: Project) => dispatch(returnObjectForBannerEntityUpdate(banner, project)),
		getBanner: (id: string, project: Project) => dispatch(returnObjectForBannerEntityRequest(id, project)),
	};
}

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps), withRouter)(BannerEdit) as React.ComponentType;
