import FilterModel from './filter.model';
import { FilterJson } from './filter.json';

export default class FilterBuilder {
	private json: FilterJson;

	constructor(filter?: FilterModel | FilterJson) {
		if (filter && filter instanceof FilterModel) {
			this.json = filter.toJson();
		} else if (filter) {
			this.json = filter;
		} else {
			this.json = {} as FilterJson;
		}
	}

	withFilterSport(filterSport: any): FilterBuilder {
		this.json.filterSport = filterSport;

		return this;
	}

	withFilterLanguage(filterLanguage: any): FilterBuilder {
		this.json.filterLanguage = filterLanguage;

		return this;
	}

	withFilterCompetition(filterCompetition: any): FilterBuilder {
		this.json.filterCompetition = filterCompetition;

		return this;
	}

	build(): FilterModel {
		return FilterModel.fromJson(this.json);
	}
}
