import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { FormGroup, Label } from 'reactstrap';
import { SelectMenuOptionType } from '../../../../../../models/v2/general/select.model';
import { getSelectedOption } from './helper';

interface Properties {
	value: string;
	onOptionChange: (e: SelectMenuOptionType) => void;
	options: string[];
}

const HeaderDefaultOptionInput: FunctionComponent<Properties> = ({ value, onOptionChange, options }) => {
	const { t } = useTranslation();

	return (
		<FormGroup data-qa='header-default-option-select'>
			<Label>{t('header_default_option')}:</Label>
			<Select
				isClearable
				options={options.map((option) => ({ value: option, label: t(option), data: option }))}
				value={getSelectedOption(value, options)}
				placeholder={t('refresh_time_placeholder')}
				onChange={onOptionChange}
			/>
		</FormGroup>
	);
};

export default HeaderDefaultOptionInput;
