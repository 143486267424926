import * as React from 'react';

type Properties = {
	display: boolean;
	editContentPath: string;
	history: any;
	t: any;
	analytics: any;
	contentId?: string;
	contentType?: string;
};

export const RowEditTranslationButton: React.FunctionComponent<Properties> = ({
	display,
	editContentPath,
	history,
	t,
	analytics,
	contentId,
}) => {
	const onEditContent = () => {
		analytics.sendEvent('Click', 'List Page', 'Edit Translation Button');
		history.push(editContentPath);
	};

	if (display) {
		return (
			<div className='cursor-pointer' id={`edit-translation-article-btn-${contentId}`} onClick={onEditContent}>
				<i className='fa fa-pencil p-1' />
			</div>
		);
	}

	return null;
};
