import React, { FunctionComponent } from 'react';
import { TournamentViews } from './constants/Contstants';
import { TournamentListing } from './TournamentListingComponent';
import { TournamentEdit } from './TournamentEditComponent';
import { Redirect, withRouter } from 'react-router';
import TournamentUiService from './services/TournamentUiService';
import TournamentService from './services/TournamentService';
import { compose } from 'redux';
import { connect } from 'react-redux';

export const uiService = new TournamentUiService();
export const blService = new TournamentService();

type Props = {
	t: any;
	history: any;
	match: any;
	location: any;
	tournamentOrder: string;
	language: string;
};

export const TournamentEditingContainer: FunctionComponent<Props> = ({ t, history, location, match, tournamentOrder, language }) => {
	const view = uiService.generateCurrentView(location.pathname);

	switch (view) {
		case TournamentViews.LIST:
			return <TournamentListing t={t} history={history} tournamentOrder={tournamentOrder} />;
		case TournamentViews.EDIT:
			return <TournamentEdit t={t} routeMatch={match} language={language} />;
		default:
			return <Redirect to='/smp/dashboard' />;
	}
};

function mapStateToProps(state: any) {
	return {
		tournamentOrder: state.project.currentProject.defaultTournamentOrder,
		language: state.project.currentProject.language,
	};
}

export default compose(connect(mapStateToProps), withRouter)(TournamentEditingContainer);
