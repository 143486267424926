import React, { FunctionComponent, useEffect, useState } from 'react';
import Select from 'react-select';
import AdminMapper from '../../../models/v2/admin/admin.mapper';
import CategoriesHttp from '../../../services/rest/CategoriesHttp';
import AdminModel from '../../../models/v2/admin/admin.model';

const getAdmins = async (): Promise<AdminModel[]> => {
	try {
		const mapper = new AdminMapper();
		const response = await new CategoriesHttp().getAdmins();
		return response.map((resp: any) => {
			return mapper.responseToModel(resp);
		});
	} catch (e) {
		return [];
	}
};

export const AdminSelect: FunctionComponent<{
	value: AdminModel;
	onAdminSelect: (admin: AdminModel) => void;
	setDefaultContentType: () => void;
	setDefaultActionType: () => void;
	className: string;
	t: any;
}> = ({ value, onAdminSelect, setDefaultContentType, setDefaultActionType, className, t }) => {
	const [admins, setAdmins] = useState([] as AdminModel[]);

	useEffect(() => {
		getAdmins().then((resp: AdminModel[]) => {
			setAdmins(resp);
		});
	}, [admins.length]);

	const mapper = new AdminMapper();
	const options = admins.map((admin: AdminModel) => {
		return mapper.modelToOption(admin, t);
	});

	const optionValue = mapper.modelToOption(value, t);

	return (
		<Select
			menuPortalTarget={document.body}
			isClearable
			options={options}
			className={className}
			placeholder={t('select')}
			value={value && value.id ? optionValue : null}
			onChange={(selection: any) => {
				if (selection === null) {
					setDefaultContentType();
					setDefaultActionType();
				}
				const admin = selection ? selection.data : {};
				onAdminSelect(admin);
			}}
			on
		/>
	);
};
