import React, { Component } from 'react';
import { Col, FormGroup, Row, Input, Label } from 'reactstrap';
import OddsWidgetEdit from '../../odds/components/odds-widget-edit.component';
import * as helpers from '../helpers/team-comparison-edit.helper';
import TeamSelect from '../../../../partials/team-select/team-select.component';
import TournamentSelectContainer from '../../../../partials/tournament-select/tournament-select-container.component';
import SeasonSelectContainer from '../../../../partials/season-select/season-select-container.component';
import { Properties, State } from './properties/team-comparison-widget-edit.properties';
import Select from 'react-select';
import TournamentModel from '../../player-profile/models/tournament-model/tournament.model';
import SeasonModel from '../../player-profile/models/season/season.model';
import TeamModel from '../../../../partials/team-select/models/team.model';
import { blockWrapperService, blockManagementService } from '../../../../subcomponents/blocky.component';
import TeamComparisonModel from '../models/team-comparison.model';
import OddsWidgetModel from '../../odds/models/odds-widget.model';
import BlockValidation from '../../../../helpers/block-validation.helper';
import MatchModel from '../../../../partials/match-select/models/match.model';
import { featuresService } from '../../../../../../../App';
import { FeatureTypes } from '../../../../../../../services/feature-service/features.enum';
import ThreeLetterCodeTypeSelect from '../../../../partials/three-letter-code-type-select/three-letter-code-type-select';

export default class TeamComparisonWidgetEdit extends Component<Properties, State> {
	constructor(props: Properties) {
		super(props);
		this.state = {
			teamComparisonModel:
				props.block && props.block.data.preview && props.block.data.preview.teamComparison
					? TeamComparisonModel.builder(props.block.data.preview.teamComparison).build()
					: TeamComparisonModel.builder()
							.withStatFields(helpers.optionsToStatFields(helpers.STAT_FIELDS_SELECT_OPTIONS))
							.withDisplayOdds(true)
							.withThreeLetterCodeType('')
							.build(),
			oddsModel:
				props.block && props.block.data.preview && props.block.data.preview.odds
					? OddsWidgetModel.builder(props.block.data.preview.odds).build()
					: OddsWidgetModel.builder().withBookmakers([]).withCanSelectMarkets(false).withDisplayHeader(false).build(),
			isValid: true,
		};
	}

	componentDidMount(): void {
		blockWrapperService.registerOnSaveFunc({ blockId: this.props.block.id, func: this.onSave });
	}

	onSave = (index: number) => {
		const validation = new BlockValidation();
		const data = { teamComparisonModel: this.state.teamComparisonModel, oddsModel: this.state.oddsModel };
		const isValid = validation.validate(this.props.block, data);
		this.setState({ ...this.state, isValid });
		const bookmakerSelectionEnabled = featuresService.areFeaturesEnabled([FeatureTypes.ALLOW_BOOKMAKER_SELECTION]);
		const odds = OddsWidgetModel.builder(this.state.oddsModel)
			.withBookmakers(bookmakerSelectionEnabled ? this.state.oddsModel.bookmakers : [])
			.build();

		if (isValid) {
			const block = Object.assign({}, this.props.block);
			block.data.preview = { teamComparison: this.state.teamComparisonModel, odds };
			blockManagementService.blockUpdate(block, index);
		} else {
			return isValid;
		}
	};

	componentWillUnmount(): void {
		blockWrapperService.unregisterOnSaveFunc(this.props.block.id);
	}

	onOddsWidgetChange = (oddsModel: OddsWidgetModel) => this.setState({ ...this.state, oddsModel });

	updateTeamComparisonModel = (model: TeamComparisonModel) => this.setState({ ...this.state, teamComparisonModel: model });

	updateTeamState = (team: TeamModel, isTeam1: boolean) => {
		const model = helpers.updateModelTeam(this.state.teamComparisonModel, team, isTeam1);
		this.updateTeamComparisonModel(model);
	};

	updateTeamTournament = (tournament: TournamentModel, isTeam1: boolean) => {
		const model = helpers.updateModelTeamTournament(this.state.teamComparisonModel, tournament, isTeam1);
		this.updateTeamComparisonModel(model);
	};

	updateTeamSeason = (season: SeasonModel, isTeam1: boolean) => {
		const model = helpers.updateModelTeamSeason(this.state.teamComparisonModel, season, isTeam1);
		this.updateTeamComparisonModel(model);
	};

	updateTeamComparisonStatFields = (statFieldOptions: string[]) => {
		this.updateTeamComparisonModel(
			TeamComparisonModel.builder(this.state.teamComparisonModel).withStatFields(helpers.optionsToStatFields(statFieldOptions)).build(),
		);
	};

	updateDisplayOddsState = (e: any) => {
		this.updateTeamComparisonModel(TeamComparisonModel.builder(this.state.teamComparisonModel).withDisplayOdds(e.target.checked).build());
	};

	onThreeLetterCodeTypeChange = (value: any) => {
		this.updateTeamComparisonModel(TeamComparisonModel.builder(this.state.teamComparisonModel).withThreeLetterCodeType(value).build());
	};

	render() {
		const { teamComparisonModel, isValid, oddsModel } = this.state;

		return (
			<>
				<Row>
					<Col>
						<TeamSelect
							inputId='team-comperison-team-one-select'
							isValid={isValid}
							isMulti={false}
							t={this.props.t}
							withLabel
							labelText={`${this.props.t('team')} 1`}
							value={teamComparisonModel.team1}
							onSelect={(team: TeamModel) => {
								this.updateTeamState(team, true);
							}}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<FormGroup>
							{teamComparisonModel.team1 && teamComparisonModel.team1.id && (
								<TournamentSelectContainer
									withLabel
									isBlocky
									isValid={isValid}
									t={this.props.t}
									selectedTournament={teamComparisonModel.team1.tournament}
									onTournamentSelect={(tournament: TournamentModel) => {
										this.updateTeamTournament(tournament, true);
									}}
								/>
							)}
						</FormGroup>
					</Col>
				</Row>
				<Row>
					<Col>
						<FormGroup>
							{teamComparisonModel.team1 && teamComparisonModel.team1.tournament && teamComparisonModel.team1.tournament.id && (
								<SeasonSelectContainer
									withLabel
									isValid={isValid}
									t={this.props.t}
									tournamentId={teamComparisonModel.team1.tournament.id}
									selectedSeason={teamComparisonModel.team1.tournament.season}
									onSeasonSelect={(season: SeasonModel) => this.updateTeamSeason(season, true)}
								/>
							)}
						</FormGroup>
					</Col>
				</Row>
				<hr></hr>
				<Row>
					<Col>
						<TeamSelect
							inputId='team-comperison-team-two-select'
							isValid={isValid}
							t={this.props.t}
							isMulti={false}
							withLabel
							labelText={`${this.props.t('team')} 2`}
							value={teamComparisonModel.team2}
							onSelect={(team: TeamModel) => this.updateTeamState(team, false)}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<FormGroup>
							{teamComparisonModel.team2 && teamComparisonModel.team2.id && (
								<TournamentSelectContainer
									withLabel
									isBlocky
									isValid={isValid}
									t={this.props.t}
									selectedTournament={teamComparisonModel.team2.tournament}
									onTournamentSelect={(tournament: TournamentModel) => this.updateTeamTournament(tournament, false)}
								/>
							)}
						</FormGroup>
					</Col>
				</Row>
				<Row>
					<Col>
						<FormGroup>
							{teamComparisonModel.team2 && teamComparisonModel.team2.tournament && teamComparisonModel.team2.tournament.id && (
								<SeasonSelectContainer
									withLabel
									isValid={isValid}
									t={this.props.t}
									tournamentId={teamComparisonModel.team2.tournament.id}
									selectedSeason={teamComparisonModel.team2.tournament.season}
									onSeasonSelect={(season: SeasonModel) => this.updateTeamSeason(season, false)}
								/>
							)}
						</FormGroup>
					</Col>
				</Row>
				<Row>
					<Col>
						<FormGroup>
							<Label>{this.props.t('select_statistics_to_display')}:</Label>
							<Select
								placeholder={this.props.t('select_statistics_to_display')}
								isMulti
								options={helpers.STAT_FIELDS_SELECT_OPTIONS}
								value={helpers.statFieldsToOptions(teamComparisonModel.statFields)}
								onChange={(selection: any) => this.updateTeamComparisonStatFields(selection)}
							/>
						</FormGroup>
					</Col>
				</Row>
				<ThreeLetterCodeTypeSelect
					t={this.props.t}
					isClearable={true}
					onThreeLetterCodeTypeChange={this.onThreeLetterCodeTypeChange}
					selectedThreeLetterCodeType={teamComparisonModel.threeLetterCodeType}
				/>
				<Row className='mb-2'>
					<Col>
						<FormGroup key='cablockn-select-markets' check className='radio'>
							<Input
								key={`display-odds-key-${this.props.block.id}`}
								className='form-check-input'
								type='checkbox'
								id={`display-odds-checkbox-${this.props.block.id}`}
								name='radios'
								onChange={this.updateDisplayOddsState}
								checked={teamComparisonModel.displayOdds}
							/>
							<Label
								key={`display-odds-label-key-${this.props.block.id}`}
								check
								className='form-check-label'
								for={`display-odds-checkbox-${this.props.block.id}`}
							>
								{this.props.t('display_odds')}
							</Label>
						</FormGroup>
					</Col>
				</Row>
				{teamComparisonModel && teamComparisonModel.displayOdds && (
					<OddsWidgetEdit
						isValid={isValid}
						block={this.props.block}
						team={oddsModel && oddsModel.team ? oddsModel.team : teamComparisonModel.team1}
						match={oddsModel && oddsModel.match ? oddsModel.match : MatchModel.builder().build()}
						isStandAlone={false}
						useTeamSelect
						useMatchSelect
						t={this.props.t}
						onOddsWidgetChange={this.onOddsWidgetChange}
						autoSelectFirstMatch
						displayOddsLabel
						displayHeader={false}
					/>
				)}
			</>
		);
	}
}
