import React, { FunctionComponent } from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import { ContentTypes } from '../../../../../../constants/content-types';
import { availableContentTypes } from '../../../../../../services/content-models-service/ContentModelService';
import { featuresService } from '../../../../../../App';
import { FeatureTypes } from '../../../../../../services/feature-service/features.enum';

type Properties = {
	t: any;
	searchType: availableContentTypes;
	setSearchType: (searchType: availableContentTypes) => void;
};

const SearchTypes: FunctionComponent<Properties> = ({ t, searchType, setSearchType }) => {
	const isChecked = (contentType: availableContentTypes): boolean => searchType === contentType;
	const onSearchTypeChange = (contentType: availableContentTypes) => setSearchType(contentType);

	return (
		<FormGroup>
			<FormGroup check inline>
				<Input
					className='form-check-input'
					type='radio'
					id='radio-articles'
					name='search-type-inline-radio'
					checked={isChecked(ContentTypes.ARTICLE)}
					value='articles'
					onChange={() => onSearchTypeChange(ContentTypes.ARTICLE)}
				/>
				<Label className='form-check-label' check htmlFor='radio-articles'>
					{t('articles')}
				</Label>
			</FormGroup>
			{featuresService.areFeaturesEnabled([FeatureTypes.VIDEO]) ? (
				<FormGroup check inline>
					<Input
						className='form-check-input'
						type='radio'
						id='radio-videos'
						name='search-type-inline-radio'
						checked={isChecked(ContentTypes.VIDEO)}
						value='videos'
						onChange={() => onSearchTypeChange(ContentTypes.VIDEO)}
					/>
					<Label className='form-check-label' check htmlFor='radio-videos'>
						{t('videos')}
					</Label>
				</FormGroup>
			) : null}
			<FormGroup check inline>
				<Input
					className='form-check-input'
					type='radio'
					id='radio-galleries'
					name='search-type-inline-radio'
					checked={isChecked(ContentTypes.GALLERY)}
					value='galleries'
					onChange={() => onSearchTypeChange(ContentTypes.GALLERY)}
				/>
				<Label className='form-check-label' check htmlFor='radio-galleries'>
					{t('galleries')}
				</Label>
			</FormGroup>
		</FormGroup>
	);
};

export default SearchTypes;
