export const defineErrorMessage = (errors: any, errorType?: any, mainMedia?: any, sources?: any) => {
	if (mainMedia && errors.errors[errorType]) {
		return errors.errors[errorType][0][mainMedia];
	}

	if (sources && errors.errors[sources + errorType]) {
		return errors.errors[sources + errorType][0];
	}

	if (errorType === 'password' && errors.errors[errorType]) {
		return errors.errors[errorType][0];
	}

	if (errorType !== 'password' && !mainMedia && !errors.error) {
		return errors.errors[errorType] && errors.errors[errorType][0];
	}

	if (errors && errors.error) {
		return errors.error;
	}

	return null;
};
