import { YoutubeSportsJson } from './youtube-sports-json';
import { YoutubeSportsRequestJson } from './youtube-sports-request-json';
import YoutubeSportsModel from './youtube-sports.model';
import YoutubeVideoModel from './youtube-video/youtube-video.model';

export default class YoutubeSportsBuilder {
	private json: YoutubeSportsJson;

	constructor(videos?: YoutubeSportsModel | YoutubeSportsJson) {
		if (videos && videos instanceof YoutubeSportsModel) {
			this.json = videos.toJSON();
		} else if (videos) {
			this.json = videos;
		} else {
			this.json = {} as YoutubeSportsJson;
		}
	}

	public withTitle(title: string) {
		this.json.title = title;

		return this;
	}

	public withVideoList(videoList: YoutubeVideoModel[]) {
		this.json.videoList = videoList;

		return this;
	}

	public withNextPageToken(nextPageToken: string) {
		this.json.nextPageToken = nextPageToken;

		return this;
	}

	public withPrevPageToken(prevPageToken: string) {
		this.json.prevPageToken = prevPageToken;

		return this;
	}

	public withPageInfo(pageInfo: any) {
		this.json.pageInfo = pageInfo;

		return this;
	}

	public build() {
		return YoutubeSportsModel.fromJSON(this.json);
	}

	toRequestJson(): YoutubeSportsRequestJson {
		let json = {} as YoutubeSportsRequestJson;
		json.title = this.json.title;
		json.items = this.json.videoList;
		json.nextPageToken = this.json.nextPageToken;
		json.prevPageToken = this.json.prevPageToken;
		json.pageInfo = this.json.pageInfo;

		return json;
	}
}
