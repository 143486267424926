import { Properties, State } from './properties/RowsContainerProps';
import * as React from 'react';
import {
	mapArticleListToRowModelList,
	mapAuthorListToRowModelList,
	mapBannerListToRowModelList,
	mapGalleryListToRowModelList,
	mapListListingToRowModelList,
	mapTagListToRowModelList,
	mapPlayerListToRowModelList,
	mapVideoListToRowModelList,
	mapPollListToRowModelList,
	mapFeedListToRowModelList,
	mapTeamListToRowModelList,
	mapCategoryListToTreeItemList,
	mapCoachListToRowModelList,
} from './helpers/RowsContainerHelper';
import {
	RowsArticle,
	RowsAuthor,
	RowsBanner,
	RowsCategory,
	RowsGallery,
	RowsList,
	RowsTag,
	RowsVideo,
	RowsPoll,
	RowsFeed,
	RowsTeam,
	RowsPlayer,
	RowsCoach,
} from './Rows';

export default class RowsContainer extends React.Component<Properties, State> {
	private rows: any = {
		article: (props: Properties) => (
			<RowsArticle
				t={props.t}
				analytics={props.analytics}
				history={props.history}
				content={mapArticleListToRowModelList(props.content)}
				contentToCopy={props.content}
				onDelete={this.onContentDelete}
			/>
		),
		gallery: (props: Properties) => (
			<RowsGallery
				t={props.t}
				analytics={props.analytics}
				history={props.history}
				content={mapGalleryListToRowModelList(props.content)}
				contentToCopy={props.content}
				onDelete={this.onContentDelete}
			/>
		),
		video: (props: Properties) => (
			<RowsVideo
				t={props.t}
				analytics={props.analytics}
				history={props.history}
				content={mapVideoListToRowModelList(props.content)}
				contentToCopy={props.content}
				onDelete={this.onContentDelete}
			/>
		),
		banner: (props: Properties) => (
			<RowsBanner
				t={props.t}
				analytics={props.analytics}
				history={props.history}
				content={mapBannerListToRowModelList(props.content)}
				onDelete={this.onContentDelete}
			/>
		),
		list: (props: Properties) => (
			<RowsList
				t={props.t}
				analytics={props.analytics}
				history={props.history}
				content={mapListListingToRowModelList(props.content)}
				onDelete={this.onContentDelete}
			/>
		),
		author: (props: Properties) => (
			<RowsAuthor
				t={props.t}
				analytics={props.analytics}
				history={props.history}
				content={mapAuthorListToRowModelList(props.content)}
				onDelete={this.onContentDelete}
				searchQuery={props.searchQuery}
			/>
		),
		tag: (props: Properties) => (
			<RowsTag
				t={props.t}
				analytics={props.analytics}
				history={props.history}
				content={mapTagListToRowModelList(props.content)}
				onDelete={this.onContentDelete}
				searchQuery={props.searchQuery}
			/>
		),
		category: (props: Properties) => (
			<RowsCategory
				t={props.t}
				analytics={props.analytics}
				history={props.history}
				categoryTree={mapCategoryListToTreeItemList(props.content)}
				onDelete={this.onContentDelete}
			/>
		),
		poll: (props: Properties) => (
			<RowsPoll
				t={props.t}
				analytics={props.analytics}
				history={props.history}
				content={mapPollListToRowModelList(props.content)}
				onDelete={this.onContentDelete}
			/>
		),
		feed: (props: Properties) => (
			<RowsFeed
				t={props.t}
				analytics={props.analytics}
				history={props.history}
				content={mapFeedListToRowModelList(props.content)}
				onDelete={this.onContentDelete}
			/>
		),
		team: (props: Properties) => (
			<RowsTeam
				t={props.t}
				analytics={props.analytics}
				history={props.history}
				content={mapTeamListToRowModelList(props.content)}
				onDelete={this.onContentDelete}
			/>
		),
		player: (props: Properties) => (
			<RowsPlayer
				t={props.t}
				analytics={props.analytics}
				history={props.history}
				content={mapPlayerListToRowModelList(props.content)}
				onDelete={this.onContentDelete}
			/>
		),
		coach: (props: Properties) => (
			<RowsCoach
				t={props.t}
				analytics={props.analytics}
				history={props.history}
				content={mapCoachListToRowModelList(props.content)}
				onDelete={this.onContentDelete}
			/>
		),
	};

	onContentDelete = (id: string, title: string) => {
		this.props.onContentDelete && this.props.onContentDelete(id, title);
	};

	render() {
		return this.rows[this.props.contentType.toLowerCase()](this.props);
	}
}
