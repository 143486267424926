import React from 'react';
import { Col, Row } from 'reactstrap';
import { ViewTypes } from '../../constants/general.constants';
import BlockModel from '../../models/block.model';

type State = {
	isFound: boolean;
};

type Properties = {
	block: BlockModel;
	view: ViewTypes;
	t: any;
	domain: string;
	lang: string;
	contentData?: any;
};

const ContentNotFoundController = (WrappedComponent: any) => {
	class ContentNotFoundControllerHOC extends React.Component<Properties, State> {
		constructor(props: Properties) {
			super(props);
			this.state = { isFound: true };
		}

		setIsContentFound = (isFound: boolean) => this.setState({ ...this.state, isFound: isFound });

		renderView = (element: any) => {
			if (!this.state.isFound) {
				return (
					<Row>
						<Col>
							<p className='text-center text-danger'>
								<b>{this.props.t('block_content_not_found')}</b>
							</p>
						</Col>
					</Row>
				);
			} else {
				return element;
			}
		};

		render() {
			return <WrappedComponent {...this.props} renderView={this.renderView} setIsContentFound={this.setIsContentFound} />;
		}
	}

	return ContentNotFoundControllerHOC;
};

export default ContentNotFoundController;
