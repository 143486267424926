import React from 'react';
import { Col, Row } from 'reactstrap';
import { Properties } from './properties/player-h2h-widget-view.properties';
import { OddsWidgetView } from '../../odds/components/odds-widget-view.component';

export const PlayerH2HWidgetView: React.FunctionComponent<Properties> = ({ previewOdds, preview, t }) => {
	const containerStyle = previewOdds && previewOdds.team ? 'pt-2' : 'py-2';
	const style = { maxWidth: '700px' };

	if (preview.player1 && preview.player1.id && preview.player2 && preview.player2.id) {
		return (
			<>
				<Row className={`d-flex align-items-center ${containerStyle}`} style={style}>
					<Col sm={4} className='text-right'>
						<div className='text-center'>
							<img className='rounded-circle' height='50' src={preview.player1.logo} alt={preview.player1.name} />
							<h4>{preview.player1.name}</h4>
						</div>
					</Col>
					<Col sm={2} className='text-center'>
						<h2 className='text-nowrap'>{t('vs')}</h2>
					</Col>
					<Col sm={4} className='text-left'>
						<div className='text-center'>
							<img className='rounded-circle' height='50' src={preview.player2.logo} alt={preview.player2.name} />
							<h4>{preview.player2.name}</h4>
						</div>
					</Col>
				</Row>
				<Row className='px-4'>
					<Col>
						<hr></hr>
					</Col>
				</Row>
				{preview.threeLetterCodeType && preview.threeLetterCodeType !== '' && (
					<Row className='ml-2'>
						<Col className='text-left'>
							{t('display_team_three_letter_code')}:
							<strong>
								<span className='ml-2'>
									{preview.threeLetterCodeType && preview.threeLetterCodeType.name && t(`${preview.threeLetterCodeType.name}`)}
								</span>
							</strong>
						</Col>
					</Row>
				)}
				{preview.displayOdds && previewOdds.team && previewOdds.team.id && (
					<OddsWidgetView
						isStandAlone={false}
						blockPreview={previewOdds}
						t={t}
						displayThreeLetterCode={preview.threeLetterCodeType && preview.threeLetterCodeType !== '' ? true : false}
					/>
				)}
			</>
		);
	}

	return null;
};
