import { FormGroup, Input, Label } from 'reactstrap';
import moment from 'moment';
import React from 'react';
import MatchModel from '../../../../../../../models/v2/match/entity/match.model';

type Properties = {
	isMulti: boolean;
	onMatchSelect: any;
	onMatchesSelect: any;
	matchesNotStarted: MatchModel[];
	notStartedMatchesAreSelected: any;
	t: any;
	applyBoldCss: (teamName: string) => void;
	selectAllMatches: (matches: any, event: any) => void;
	toggleGoals: any;
	applyInProgressClass: any;
};

export const MatchesNotStarted: React.FunctionComponent<Properties> = (props) => {
	if (props.matchesNotStarted.length < 1) {
		return <div className='text-center p-3'>{props.t('no_matches_found')}</div>;
	}

	return (
		<>
			<div data-qa='matches-not-started-tab'>
				{props.isMulti && (
					<FormGroup className='mb-2 pb-2 border-bottom' check>
						<Input
							className='form-check-input'
							onChange={(event) => props.selectAllMatches(props.matchesNotStarted, event)}
							type='checkbox'
							checked={props.matchesNotStarted[0].isSelected && props.notStartedMatchesAreSelected}
							id={`select-all-not-started-matches${props.matchesNotStarted.length}`}
							name={props.t('select_all_matches')}
						/>
						<Label className='form-check-label' check htmlFor={`select-all-not-started-matches${props.matchesNotStarted.length}`}>
							{props.t('select_all_matches')}
						</Label>
					</FormGroup>
				)}
				{props.matchesNotStarted.map((match) => {
					return (
						<FormGroup key={`key-${match.id}`} className={`mb-2 pb-2 border-bottom ${props.applyInProgressClass(match.type)}`} check>
							<Input
								className='form-check-input'
								onChange={(event) => {
									if (props.isMulti) {
										props.onMatchesSelect(match, event);
									} else {
										props.onMatchSelect(match);
									}
								}}
								type={props.isMulti ? 'checkbox' : 'radio'}
								id={`${match.id}`}
								name={`inline-radio-${match.id}`}
								checked={match.isSelected}
							/>
							<Label className='form-check-label' check htmlFor={`${match.id}`}>
								{`${moment(match.startTime).format('DD MMM YYYY')} - `}
								<span className={`${props.applyBoldCss(match.homeTeam.name)}`}>{match.homeTeam.name}</span>
								{props.toggleGoals(match)}
								<span className={`${props.applyBoldCss(match.awayTeam.name)}`}>{match.awayTeam.name}</span>
							</Label>
						</FormGroup>
					);
				})}
			</div>
		</>
	);
};

export default MatchesNotStarted as React.ComponentType<Properties>;
