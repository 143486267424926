import ContentWidget from '../models/content-widget.model';
import { extractMainImageSrc } from './main-image.helper';
import ModelMapper from '../../../../../../../models/ModelMapper';
import MainMedia from '../../../../../../../models/mainmedia/MainMedia';
import { setIcon } from '../../../../../Sidebar/Media/subcomponents/media-content/helpers/MainContentMediaContainer';

export const SearchEntityTypes = {
	articles: 'articles',
	videos: 'videos',
	galleries: 'galleries',
	polls: 'polls',
};

export const constructContentEntitySearchUrl = (search: string, entityType: string) => {
	const searchVersion = entityType !== SearchEntityTypes.polls ? '/v2/' : '/';

	return `${searchVersion}${SearchEntityTypes[entityType]}/search?page=1&query=${search}`;
};

export const contentToOption = (content: ContentWidget) => {
	if (content && Object.entries(content).length > 0) {
		return {
			value: content.id,
			label: content.title,
			content: remapResponseToContent(content),
			logo: setIcon(content),
		};
	}

	return {};
};

export const checkDuplicateContentId = (responseData: any, callbackFunc: any) => {
	const pageURL = window.location.href;
	// get id from url so it can be used to filter articles/gallery/videos so they don't appear in the search results
	const getItemId: any = pageURL.substr(pageURL.lastIndexOf('/') + 1);

	// check if value is a number so we can determine if the Id is present
	const checkIfValueIsANumber = (variableValue: any) => {
		return variableValue % 2 === 0;
	};

	if (checkIfValueIsANumber(getItemId)) {
		const options = responseData.data.data
			.filter((item: any) => `${item.id}` !== `${getItemId}`)
			.map((item: any) => {
				return contentToOption(item);
			});
		callbackFunc(options);
	} else {
		const options = responseData.data.data.map((item: any) => {
			return contentToOption(item);
		});
		callbackFunc(options);
	}
};

export const remapResponseToContent = (response: any, content?: ContentWidget) => {
	if (response && Object.entries(response).length > 0) {
		return ContentWidget.builder(content)
			.withId(response.id)
			.withImage(
				extractMainImageSrc(
					response.main_media
						? response.main_media.map((media: any) => ModelMapper.remapMainMediaFromResponse(media))
						: MainMedia.builder().build(),
				),
			)
			.withPublishedAt(response.published_at)
			.withSubtitile(response.subtitle)
			.withTitle(response.title)
			.withStatus(response.status)
			.withVideoUrls(response.urls && response.urls.video_files)
			.withMainMedia(response.main_media ? response.main_media : ModelMapper.remapResponseToMainMediaList(response.mainMedia))
			.build();
	}

	return {};
};

export const extractTimeOnly = (startAt: string) => {
	if (startAt) {
		startAt = `${startAt[0]}`.split(' ')[4];
	}

	return startAt;
};
