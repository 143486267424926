import React from 'react';
import { Col, Row } from 'reactstrap';
import FansUnitedWidgetModel from '../../models/fans-united-widget.model';
import moment from 'moment';
import BookmakerModel from '../../../../../partials/bookmaker-select/models/bookmaker.model';
import { imagePlaceHolder } from '../../../../../../../../constants/resource-constants';

type Properties = {
	widgetPlayerPredictions: FansUnitedWidgetModel;
	t: any;
	bookmakers: [];
};

export const WidgetPlayerPredictionsView: React.FunctionComponent<Properties> = ({ widgetPlayerPredictions, t, bookmakers }) => {
	if (widgetPlayerPredictions && widgetPlayerPredictions.match && widgetPlayerPredictions.match.id) {
		return (
			<>
				<Row className='d-flex p-2 text-center align-items-center mb-1'>
					<Col>
						<strong>
							{moment(widgetPlayerPredictions.match.startTime ? widgetPlayerPredictions.match.startTime : '').format('MMM Do YYYY, HH:mm')}
						</strong>
					</Col>
					<Col className='text-right mr-1'>
						<strong className='mr-2'>
							{widgetPlayerPredictions.match && widgetPlayerPredictions.match.homeTeam && widgetPlayerPredictions.match.homeTeam.name}
						</strong>
						<img
							width='40'
							src={widgetPlayerPredictions.match && widgetPlayerPredictions.match.homeTeam && widgetPlayerPredictions.match.homeTeam.logo}
							alt={widgetPlayerPredictions.match && widgetPlayerPredictions.match.homeTeam && widgetPlayerPredictions.match.homeTeam.name}
						/>
					</Col>
					<strong className='text-nowrap'>:</strong>
					<Col className='text-left'>
						<img
							width='40'
							src={widgetPlayerPredictions.match && widgetPlayerPredictions.match.awayTeam && widgetPlayerPredictions.match.awayTeam.logo}
							alt={widgetPlayerPredictions.match && widgetPlayerPredictions.match.awayTeam && widgetPlayerPredictions.match.awayTeam.logo}
						/>
						<strong className='ml-2'>
							{widgetPlayerPredictions.match && widgetPlayerPredictions.match.awayTeam && widgetPlayerPredictions.match.awayTeam.name}
						</strong>
					</Col>
				</Row>
				<Row className='px-4'>
					<Col>
						<hr />
					</Col>
				</Row>
				<Row className='px-4 pt-1'>
					{widgetPlayerPredictions.player && widgetPlayerPredictions.player.name && widgetPlayerPredictions.player.name.length > 0 && (
						<Col className='text-left'>
							<span>{t('player')}: </span>
							<strong>{t(widgetPlayerPredictions.player.name)}</strong>
						</Col>
					)}
				</Row>
				<Row className='px-4 pt-1'>
					{widgetPlayerPredictions.teamAgainst &&
						widgetPlayerPredictions.teamAgainst.name &&
						widgetPlayerPredictions.teamAgainst.name.length > 0 && (
							<Col className='text-left'>
								<span>{t('team_against')} </span>
								<strong>{t(widgetPlayerPredictions.teamAgainst.name)}</strong>
							</Col>
						)}
				</Row>
				{bookmakers && bookmakers.length > 0 && widgetPlayerPredictions.displayOdds && (
					<Row className='mb-2 px-4 pt-1'>
						<Col>
							{t('show')}:
							{bookmakers.map((bookmaker: BookmakerModel) => {
								return (
									<img
										key={bookmaker.id}
										height='20'
										alt={bookmaker.name}
										className='h-20 mx-1'
										src={bookmaker.logo ? bookmaker.logo : imagePlaceHolder}
										title={bookmaker.name}
									/>
								);
							})}
						</Col>
					</Row>
				)}
				<Row className='px-4 pt-1'>
					{widgetPlayerPredictions.market && widgetPlayerPredictions.market.value && widgetPlayerPredictions.market.value.length > 0 && (
						<Col className='text-left'>
							<span>{t('market')}: </span>
							<strong>{t(widgetPlayerPredictions.market.value)}</strong>
						</Col>
					)}
				</Row>
				<Row className='px-4 pt-1'>
					<Col className='text-left'>
						{t('display_odds')}:
						<strong>
							<span className={widgetPlayerPredictions.displayOdds ? 'text-success' : 'text-danger'}>
								{widgetPlayerPredictions.displayOdds ? ` ${t('yes')}` : ` ${t('no')}`}
							</span>
						</strong>
					</Col>
				</Row>
			</>
		);
	}

	return null;
};
