import { PlayerJson } from './player.json';
import PlayerBuilder from './player.builder';

export default class PlayerModel {
	readonly id: string;
	readonly name: string;
	readonly image: string;
	readonly headshot: string;

	private constructor(id: string, name: string, image: string, headshot: string) {
		this.id = id;
		this.name = name;
		this.image = image;
		this.headshot = headshot;
	}

	toJson(): PlayerJson {
		return {
			id: this.id,
			name: this.name,
			url_thumb: this.image,
			url_image: this.headshot,
		};
	}

	static fromJson(json: PlayerJson): PlayerModel {
		return new PlayerModel(json.id, json.name, json.url_image, json.url_thumb);
	}
	static builder(player?: PlayerModel): PlayerBuilder {
		return new PlayerBuilder(player);
	}
}
