import React from 'react';
import { Col, Row } from 'reactstrap';
import { generateRefreshTimeOptionViewText } from '../../partials/refresh-time-input/helper';
import RankingPlayerModel from '../../../../../../models/v2/ranking-player/ranking-player.model';

type Properties = {
	rankingPreview: any;
	t: any;
};

export const TennisRankingWidgetView: React.FunctionComponent<Properties> = ({ rankingPreview, t }) => {
	if (rankingPreview && rankingPreview.limit) {
		return (
			<>
				{rankingPreview.availableStandingType && rankingPreview.availableStandingType.standingType ? (
					<Row className='px-4 pb-2' data-qa='tennis-ranking-view'>
						<Col className='text-left'>
							{t('standing_type')}: <strong>{rankingPreview.availableStandingType.standingType.name}</strong>
						</Col>
					</Row>
				) : null}
				<Row className='px-4 pb-2'>
					<Col>
						{t('highlighted_players')}:
						{rankingPreview.highlightPlayers && rankingPreview.highlightPlayers.length > 0 ? (
							rankingPreview.highlightPlayers.map((player: RankingPlayerModel, index: number) => {
								return (
									<span key={`ranking-players-key-${index}`}>
										<strong>
											<img className='img-circle ml-2' width='20px' src={player.assets.logo.url} alt={player.name} /> {player.name}
											{index + 1 === rankingPreview.highlightPlayers.length ? '' : ','}
										</strong>
									</span>
								);
							})
						) : (
							<span>
								{' '}
								<strong>{t('no_players_selected')}</strong>
							</span>
						)}
					</Col>
				</Row>
				{rankingPreview.offset ? (
					<Row className='px-4 pb-2' data-qa='tennis-ranking-view'>
						<Col className='text-left'>
							{t('start_from_position')}: <strong>{Number(rankingPreview.offset) +1}</strong>
						</Col>
					</Row>
				) : null}
				<Row className='px-4 pb-2' data-qa='tennis-ranking-view'>
					<Col className='text-left'>
						{t('limit')}: <strong>{rankingPreview.limit}</strong>
					</Col>
				</Row>

				<Row className='px-4 pb-4'>
					<Col>
						<hr />
						<strong>{t('current_ranking_message')}</strong>
					</Col>
				</Row>
				{generateRefreshTimeOptionViewText(rankingPreview.refreshTime)}
			</>
		);
	}

	return null;
};
