export const REQUEST_ARTICLE_TYPES_RECEIVED = '[ACTION] REQUEST_ARTICLE_TYPES_RECEIVED';
export const REQUEST_VIDEO_TYPES_RECEIVED = '[ACTION] REQUEST_VIDEO_TYPES_RECEIVED';
export const REQUEST_IMAGE_TYPES_RECEIVED = '[ACTION] REQUEST_IMAGE_TYPES_RECEIVED';
export const REQUEST_GALLERY_TYPES_RECEIVED = '[ACTION] REQUEST_GALLERY_TYPES_RECEIVED';
export const REQUEST_TAG_TYPES_RECEIVED = '[ACTION] REQUEST_TAG_TYPES_RECEIVED';
export const REQUEST_LIST_TYPES_RECEIVED = '[ACTION] REQUEST_LIST_TYPES_RECEIVED';
export const REQUEST_WIKI_PAGES_TYPES_RECEIVED = '[ACTION] REQUEST_WIKI_PAGES_TYPES_RECEIVED';

export const requestArticleTypesReceived = (types: any) => ({
	type: REQUEST_ARTICLE_TYPES_RECEIVED,
	payload: types,
});

export const requestVideoTypesReceived = (types: any) => ({
	type: REQUEST_VIDEO_TYPES_RECEIVED,
	payload: types,
});

export const requestImageTypesReceived = (types: any) => ({
	type: REQUEST_IMAGE_TYPES_RECEIVED,
	payload: types,
});

export const requestGalleryTypesReceived = (types: any) => ({
	type: REQUEST_GALLERY_TYPES_RECEIVED,
	payload: types,
});

export const requestTagTypesReceived = (types: any) => ({
	type: REQUEST_TAG_TYPES_RECEIVED,
	payload: types,
});

export const requestListTypesReceived = (types: any) => ({
	type: REQUEST_LIST_TYPES_RECEIVED,
	payload: types,
});

export const requestWikiPagesTypesReceived = (types: any) => ({
	type: REQUEST_WIKI_PAGES_TYPES_RECEIVED,
	payload: types,
});
