import React, { FC, useEffect } from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

import '../../../style/live-blog-feed.scss';

interface LiveBlogFeedPaginationProps {
	currentPage: number;
	setPage: React.Dispatch<React.SetStateAction<number>>;
	totalPages: number;
}

const LiveBlogFeedPagination: FC<LiveBlogFeedPaginationProps> = ({ currentPage, setPage, totalPages }) => {
	useEffect(() => {
		setPage(Math.min(currentPage, totalPages));
	}, [currentPage, totalPages]);

	if (totalPages < 2) {
		return null;
	}

	let start = currentPage - (currentPage % 5);

	if (currentPage % 5 === 0) {
		start -= 5;
	}

	const end = Math.min(start + 5, totalPages);
	const visiblePageIndexes: number[] = Array(end - start)
		.fill(null)
		.map((value, index) => start + index + 1);

	return (
		<Pagination aria-label='Page navigation example' className='live-blog-feed-pagination'>
			<PaginationItem disabled={currentPage === 1}>
				<PaginationLink first onClick={() => setPage(1)} />
			</PaginationItem>
			<PaginationItem disabled={currentPage === 1}>
				<PaginationLink previous onClick={() => setPage(currentPage - 1)} />
			</PaginationItem>

			{visiblePageIndexes.map((pageIndex, index) => (
				<PaginationItem key={index} active={pageIndex === currentPage}>
					<PaginationLink onClick={() => setPage(pageIndex)}>{pageIndex}</PaginationLink>
				</PaginationItem>
			))}

			<PaginationItem disabled={currentPage === totalPages}>
				<PaginationLink next onClick={() => setPage(currentPage + 1)} />
			</PaginationItem>
			<PaginationItem disabled={currentPage === totalPages}>
				<PaginationLink last onClick={() => setPage(totalPages)} />
			</PaginationItem>
		</Pagination>
	);
};

export default LiveBlogFeedPagination;
