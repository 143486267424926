import React from 'react';

type Properties = {
	title: string;
	character: string;
};

const Emoji = (props: Properties) => (
	<span className='emoji' role='img' aria-label={props.title ? props.title : ''} aria-hidden={props.title ? 'false' : 'true'}>
		{props.character}
	</span>
);

export default Emoji;
