export function redirectTypesToOptions(types: string[], t: any) {
	let options: any[] = [];

	if (types && types.length > 0) {
		options = types.map((type: string) => {
			return { value: type, label: t(type) };
		});
	}

	return options;
}

export function optionToRedirectType(option: any) {
	let redirectType: string = '';

	if (option) {
		redirectType = option.value;
	}

	return redirectType;
}

export function redirectTypeToOption(redirectType: string, t: any) {
	let option: any = {};

	if (redirectType) {
		option = { value: redirectType, label: t(redirectType) };
	}

	return option;
}

export const removeUsedRedirectTypes = (urls: any[], all: any[]) => {
	if (all && all.length > 0) {
		return all.filter((type: string) => {
			const existingType = urls.find((url: any) => url.type === type);
			return !existingType;
		});
	}

	return [];
};
