import 'reflect-metadata';
import { TypedJSON } from 'typedjson';
import AmgVideoModel from './amg-video.model';
import AmgItemDataModel from './amg-item-data.model';
import AmgPagingDataModel from './amg-paging-data.model';

export default class AmgVideoMapper {
	public responseToModel = (response: any): AmgVideoModel => {
		const serializer = new TypedJSON(AmgVideoModel);
		return serializer.parse(response) as AmgVideoModel;
	};
	public modelToRequestBody = (model: AmgVideoModel) => {
		if (model) {
			return {
				itemData: this.amgItemDataToRequest(model.itemData),
				pagingData: this.amgPagingDataToRequest(model.pagingData),
			};
		}
		return new AmgVideoModel();
	};

	private amgItemDataToRequest = (itemData: AmgItemDataModel[]) => {
		return itemData
			? itemData.map((item: AmgItemDataModel) => {
					return {
						id: item.id,
						mediaData: item.mediaData,
						metaData: item.metaData,
					};
			  })
			: [];
	};

	private amgPagingDataToRequest = (pagingData: AmgPagingDataModel) => {
		if (pagingData) {
			return {
				totalCount: pagingData.totalCount,
				itemCount: pagingData.itemCount,
				pageCount: pagingData.pageCount,
				pageSize: pagingData.pageSize,
				pageIndex: pagingData.pageIndex,
			};
		}
	};
}
