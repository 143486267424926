import React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import { Properties } from './properties/search-content-by-type.properties';
import { relatedTypesMap } from './helpers/search-content-by-type-container.helper';

class SearchContentByTagsOrSportsConnections extends React.Component<Properties> {
	onSearchTypeSelect = (event: any) => {
		this.props.onSearchTypeSelect(event.target.value);
	};

	render() {
		const { type } = this.props;

		return (
			<div id='search-content-by-tags-or-sports-connections' className={'mb-3'}>
				<FormGroup>
					<FormGroup check inline>
						<Input
							className='form-check-input'
							type='radio'
							onChange={this.onSearchTypeSelect}
							id='search-type-radio-tags'
							name='inline-radios-search-type'
							checked={type === relatedTypesMap.tags + 's'}
							value='tags'
						/>
						<Label className='form-check-label' check htmlFor='search-type-radio-tags'>
							{this.props.t('tags')}
						</Label>
					</FormGroup>
					<FormGroup check inline>
						<Input
							className='form-check-input'
							type='radio'
							onChange={this.onSearchTypeSelect}
							id='search-type-radio-sports-connections'
							name='inline-radios-search-type'
							checked={type === relatedTypesMap.sports_connections}
							value='sports_connections'
						/>
						<Label className='form-check-label' check htmlFor='search-type-radio-sports-connections'>
							{this.props.t('sports_connections')}
						</Label>
					</FormGroup>
				</FormGroup>
			</div>
		);
	}
}

export default SearchContentByTagsOrSportsConnections;
