import { assetsPlaceholder } from '../../../../../../constants/assetsPlaceholder';
import TeamModel from '../../team-select/models/team.model';

export const standingsTeamToOption = (team: any) => {
	if (team) {
		return {
			value: team.id,
			label: team.name,
			data: team,
			logo: team.logo,
			type: 'team',
		};
	}

	return {};
};

export const selectionsToTeams = (selections: any[]) => {
	if (selections && selections.length > 0) {
		return selections.map((selection: any) => selection.data);
	}

	return [];
};

export const standingsTeamsToOptions = (teams: any[]) => {
	if (teams && teams.length > 0) {
		return teams.map((team: TeamModel) => standingsTeamToOption(team));
	}

	return [];
};

export const remapTeamFromResponse = (response: any) => {
	if (response && response.length > 0) {
		return response.map((team: any) => {
			return TeamModel.builder()
				.withId(team.id)
				.withLogo(team.url_logo ? team.url_logo : assetsPlaceholder.team)
				.withName(team.name)
				.withThreeLetterCode(team.three_letter_code)
				.build();
		});
	}

	return [];
};
