import React from 'react';
import { imagePlaceHolder } from '../../../../../../../constants/resource-constants';
import GalleryBlockModel from '../models/gallery-block.model';

type Properties = {
	item: any;
	gallery: GalleryBlockModel;
	updateGalleryState: (gallery: GalleryBlockModel) => any;
};

const GalleryItems: React.FunctionComponent<Properties> = ({ item, gallery, updateGalleryState }) => {
	const imageSrc = item && item.urls && item.urls.uploaded && item.urls.uploaded.embed ? item.urls.uploaded.embed : imagePlaceHolder;
	const mainImageId = gallery.mainImage && gallery.mainImage.id;

	const onMainImageChange = (mainImage: any) => {
		updateGalleryState(GalleryBlockModel.builder(gallery).withMainImage(mainImage).build());
	};

	return (
		<img
			key={`gallery-item-gallery-block-${item.id}`}
			src={imageSrc}
			onClick={() => onMainImageChange(item)}
			className={`img-fluid max-width-100 cursor-pointer mb-1 mr-1 ${item.id === mainImageId ? 'selected-main-image' : ''}`}
			alt={item}
		/>
	);
};

export default GalleryItems;
