export const statisticsColumns = (t: any) => {
	return [
		{ value: 'played', label: t('played_title') },
		{ value: 'minutes', label: t('minutes_title') },
		{ value: 'goals', label: t('goals_title') },
		{ value: 'assists', label: t('assists_title') },
		{ value: 'yellowCards', label: t('yellowCards_title') },
		{ value: 'redCards', label: t('redCards_title') },
	];
};

export const statisticsColumnsKeepers = (t: any) => {
	return [
		{ value: 'played', label: t('played_title') },
		{ value: 'cleansheets', label: t('cleansheets_title') },
		{ value: 'conceded', label: t('conceded_title') },
		{ value: 'yellowCards', label: t('yellowCards_title') },
		{ value: 'redCards', label: t('redCards_title') },
	];
};
