import React, { FunctionComponent } from 'react';
import StageModel from '../../../../../../models/v2/Stage/Entity/StageModel';
import Loader from '../../../../../Partials/Shared/loader/Loader';
import StageRows from './StagesRow';

type Props = {
	t: any;
	loadingTournamentStages: Array<string>;
	seasonId: number;
	detailedSeasonsInfo: any;
	seasonName: string;
	selectStageToEdit: Function;
	saveSeasonName: Function;
};

//@ts-ignore
export const StagesLoad: FunctionComponent<Props> = ({
	loadingTournamentStages,
	seasonId,
	detailedSeasonsInfo,
	t,
	seasonName,
	selectStageToEdit,
	saveSeasonName,
}) => {
	const getLoadedSeasonStages = (seasonId: number): Array<StageModel> => {
		return detailedSeasonsInfo[seasonId] && detailedSeasonsInfo[seasonId].length > 0
			? [...detailedSeasonsInfo[seasonId]]
			: new Array<StageModel>();
	};

	const saveSeasonNameForModalDisplay = () => {
		saveSeasonName(seasonName);
	};

	if (loadingTournamentStages.includes(seasonId.toString())) {
		return <Loader className='loading-overlay-h-auto loading-overlay-h-fill' />;
	} else {
		let selectedSeasonStages = getLoadedSeasonStages(seasonId);
		if (selectedSeasonStages.length > 0) {
			return selectedSeasonStages.map((stage: StageModel) => {
				return (
					<StageRows key={stage.id} stage={stage} t={t} selectStageToEdit={selectStageToEdit} saveSeasonName={saveSeasonNameForModalDisplay} />
				);
			});
		} else {
			return <span>{t('no_data_found')}</span>;
		}
	}
};
