import MatchModel from './match.model';
import { MatchJson } from './match.json';
import TeamModel from '../../team-select/models/team.model';

export default class MatchBuilder {
	private json: MatchJson;

	constructor(bookmaker?: MatchModel | MatchJson) {
		if (bookmaker && bookmaker instanceof MatchModel) {
			this.json = bookmaker.toJson();
		} else if (bookmaker) {
			this.json = bookmaker;
		} else {
			this.json = {} as MatchJson;
		}
	}

	withId(id: string): MatchBuilder {
		this.json.id = id;

		return this;
	}

	withStartTime(startTime: string): MatchBuilder {
		this.json.startTime = startTime;

		return this;
	}

	withType(type: string): MatchBuilder {
		this.json.type = type;

		return this;
	}

	withHomeTeam(homeTeam: any): MatchBuilder {
		this.json.homeTeam = TeamModel.builder(homeTeam).build();

		return this;
	}

	withAwayTeam(awayTeam: any): MatchBuilder {
		this.json.awayTeam = TeamModel.builder(awayTeam).build();

		return this;
	}

	build(): MatchModel {
		return MatchModel.fromJson(this.json);
	}
}
