import React from 'react';
import { Col, Label, Row } from 'reactstrap';
import { DATA_QA_ATTRIBUTES } from '../../../../../Partials/Sidebar/general-content/constants/data-qa.attributes';
import LiveBlogModel from '../../../models/live-blog.model';
import '../../../style/live-blog.style.scss';

type Properties = {
	t: any;
	liveBlog: LiveBlogModel;
	setLiveBlogMatchHeader: (toggle: boolean) => void;
};

export const LiveBlogMatchHeaderSelect: React.FunctionComponent<Properties> = ({ t, liveBlog, setLiveBlogMatchHeader }) => {
	const onMatchHeaderToggle = () => {
		setLiveBlogMatchHeader(!liveBlog.match_header);
	};

	return (
		<Row className='mb-3 match-header'>
			<Col>
				<Label htmlFor={DATA_QA_ATTRIBUTES.LIVE_BLOG_MATCH_HEADER}>
					<strong>{t('match_header')}</strong>
				</Label>
				<div
					id={DATA_QA_ATTRIBUTES.LIVE_BLOG_MATCH_HEADER}
					className='cursor-pointer p-1 d-flex align-items-center border-muted rounded'
					onClick={onMatchHeaderToggle}
				>
					<i className={`fa fa-2x fa-toggle-${liveBlog.match_header ? 'on text-success' : 'off text-muted'}`} />
					<span className='ml-1'>{t('show_match_header')}</span>
				</div>
			</Col>
		</Row>
	);
};
