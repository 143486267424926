import moment from 'moment';
import Statistics from '../../../../../models/statistics/statistics';
import Statistic from '../../../../../models/statistics/statistic/statistic';
import Category from '../../../../../models/category/Category';

export const selectedTypeToOption = (type: string, t: any) => {
	let selectedOption: any = {};

	if (type) {
		selectedOption = { label: t(type), value: type };
	}

	return selectedOption;
};

export const typesToOptions = (types: string[], t: any) => {
	let options: any[] = [];

	if (types && types.length > 0) {
		options = types.map((type: string) => {
			return { label: t(type), value: type };
		});
	}

	return options;
};

export const footballSelectionToOption = (selection: any[]) => {
	if (selection && selection.length > 0) {
		return {
			label: selection[0].label,
			logo: selection[0].logo,
			id: selection[0].data.id,
			type: selection[0].type,
		};
	}

	return {};
};

export const updateInputsRequests = (props: any, state: any, prevState: any) => {
	const { type, currentProject } = props;
	const { selectedType, selectedFootballOption, selectedDates, selectedCategory } = state;

	if (
		areTeamPlayerFieldsPopulated(selectedType, selectedFootballOption.label, selectedDates.length) &&
		(prevState.selectedFootballOption.id !== state.selectedFootballOption.id ||
			prevState.selectedType !== state.selectedType ||
			prevState.selectedDates[0] !== selectedDates[0] ||
			prevState.selectedDates[1] !== selectedDates[1])
	) {
		type === 'player' &&
			props.getPlayerStatistics &&
			props.getPlayerStatistics(selectedType, type, selectedFootballOption.id, selectedDates[0], selectedDates[1], currentProject);
		type === 'team' &&
			props.getTeamStatistics &&
			props.getTeamStatistics(selectedType, type, selectedFootballOption.id, selectedDates[0], selectedDates[1], currentProject);
	}

	if (
		areCategoryFieldsPopulated(selectedType, selectedCategory, selectedDates.length) &&
		(prevState.selectedCategory !== state.selectedCategory ||
			prevState.selectedType !== state.selectedType ||
			prevState.selectedDates[0] !== selectedDates[0] ||
			prevState.selectedDates[1] !== selectedDates[1])
	) {
		props.getCategoryStatistics &&
			props.getCategoryStatistics(selectedType, selectedDates[0], selectedDates[1], selectedCategory.id, currentProject);
	}
};

export const areTeamPlayerFieldsPopulated = (selectedType: string, label: string, selectedDates: number) => {
	return selectedType !== '' && label !== '' && selectedDates > 0;
};

export const areCategoryFieldsPopulated = (selectedType: string, selectedCategory: any, selectedDates: number) => {
	return selectedType !== '' && selectedCategory !== null && selectedCategory !== undefined && selectedDates > 0;
};

export const shouldShowStatistics = (
	data: any,
	selectedType: string,
	selectedFootballOption: any,
	selectedDates: [],
	selectedCategory: any,
) => {
	return (
		data !== undefined &&
		data.timeseries &&
		selectedType !== undefined &&
		selectedType !== '' &&
		((selectedFootballOption !== undefined && selectedFootballOption.label !== '') ||
			(selectedCategory !== undefined && selectedCategory !== null)) &&
		selectedDates !== undefined &&
		selectedDates.length > 0
	);
};

export const initialStatisticsCount = [[0, 0]];

export const initialStatisticsDates = (language: string) => {
	return [
		[moment().subtract(1, 'days').locale(language.split('_')[0]).format('DD MMM'), moment().locale(language.split('_')[0]).format('DD MMM')],
	];
};

export const extractChartOptionsData = (allStatistic: Statistics, type: string) => {
	if (allStatistic && allStatistic.timeseries) {
		return (
			allStatistic.timeseries &&
			Object.keys(allStatistic.timeseries).map((key) => allStatistic.timeseries[key].map((value: Statistic) => value[type]))
		);
	} else return [];
};

export const types = ['articles', 'videos', 'galleries', 'images'];
