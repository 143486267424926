import 'reflect-metadata';
import { jsonMember, jsonObject } from 'typedjson';
import TournamentResponseModel from '../../Tournament/Entity/response-tournament.model';

@jsonObject()
export default class SeasonResponseModel {
	@jsonMember(Number)
	public id: number = 0;
	@jsonMember(String)
	public name: string = '';
	@jsonMember(Boolean)
	public active: boolean = false;
	@jsonMember(() => TournamentResponseModel)
	public tournament?: TournamentResponseModel;
	@jsonMember(String)
	public slug: string | null = null;
}
