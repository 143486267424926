import { StageJson } from './stage.json';
import StageModel from './stage.model';

export default class StageBuilder {
	readonly json: StageJson;

	constructor(stage?: StageModel | StageJson) {
		if (stage && stage instanceof StageModel) {
			this.json = stage.toJson();
		} else if (stage) {
			this.json = stage;
		} else {
			this.json = {} as StageJson;
		}
	}

	withId(id: string): StageBuilder {
		this.json.id = id;

		return this;
	}

	withName(name: string): StageBuilder {
		this.json.name = name;

		return this;
	}

	build(): StageModel {
		return StageModel.fromJson(this.json);
	}
}
