import ErrorResponseModel from '../../models/error-response/ErrorResponseModel';
import Project from '../../models/project/Project';
import Banner from '../../models/banner/Banner';

export const BANNER_LISTING_REQUEST = '[ACTION] BANNER_LISTING_REQUEST';
export const BANNER_LISTING_RECEIVED = '[ACTION] BANNER_LISTING_RECEIVED';
export const BANNER_LISTING_FAILED = '[ACTION] BANNER_LISTING_FAILED';

export const BANNER_SEARCH = '[ACTION] BANNER_SEARCH';
export const BANNER_SEARCH_FAILED = '[ACTION] BANNER_SEARCH_FAILED';

export const BANNER_ENTITY_DELETE = '[ACTION] BANNER_ENTITY_DELETE';
export const BANNER_ENTITY_DELETE_SUCCESS = '[ACTION] BANNER_ENTITY_DELETE_SUCCESS';
export const BANNER_ENTITY_DELETE_FAILED = '[ACTION] BANNER_ENTITY_DELETE_FAILED';

export const BANNER_ENTITY_CREATE = '[ACTION] BANNER_ENTITY_CREATE';
export const BANNER_ENTITY_CREATE_SUCCESS = '[ACTION] BANNER_ENTITY_CREATE_SUCCESS';
export const BANNER_ENTITY_CREATE_FAILED = '[ACTION] BANNER_ENTITY_CREATE_FAILED';

export const BANNER_ENTITY_UPDATE = '[ACTION] BANNER_ENTITY_UPDATE';
export const BANNER_ENTITY_UPDATE_FAILED = '[ACTION] BANNER_ENTITY_UPDATE_FAILED';
export const BANNER_ENTITY_UPDATE_SUCCESS = '[ACTION] BANNER_ENTITY_UPDATE_SUCCESS';

export const BANNER_ENTITY_REQUEST = '[ACTION] BANNER_ENTITY_REQUEST';
export const BANNER_ENTITY_RECEIVED = '[ACTION] BANNER_ENTITY_RECEIVED';
export const BANNER_ENTITY_REQUEST_FAILED = '[ACTION] BANNER_ENTITY_REQUEST_FAILED';

export function returnObjectForBannerListingReceived(banners: any) {
	return {
		type: BANNER_LISTING_RECEIVED,
		payload: banners,
	};
}

export function returnObjectForBannerListingFailed() {
	return {
		type: BANNER_LISTING_FAILED,
	};
}

export function returnObjectForBannerListingRequest(page: string, project: Project, text: string) {
	return {
		type: BANNER_LISTING_REQUEST,
		payload: { page, project, text },
	};
}

export function returnObjectForBannerEntityCreate(banner: Banner, project: Project) {
	return {
		type: BANNER_ENTITY_CREATE,
		payload: { banner, project },
	};
}

export function returnObjectForBannerEntityCreateSuccess(id: string) {
	return {
		type: BANNER_ENTITY_CREATE_SUCCESS,
		payload: id,
	};
}

export function returnObjectForBannerEntityUpdate(banner: Banner, project: Project) {
	return {
		type: BANNER_ENTITY_UPDATE,
		payload: { banner, project },
	};
}

export function returnObjectForBannerEntityUpdateFailed() {
	return {
		type: BANNER_ENTITY_UPDATE_FAILED,
	};
}

export function returnObjectForBannerEntityUpdateSuccess() {
	return {
		type: BANNER_ENTITY_UPDATE_SUCCESS,
	};
}

export function returnObjectForBannerEntityCreateFailed() {
	return {
		type: BANNER_ENTITY_CREATE_FAILED,
	};
}

export function returnObjectForBannerEntityRequest(id: string, project: Project) {
	return {
		type: BANNER_ENTITY_REQUEST,
		payload: { id, project },
	};
}

export function returnObjectForBannerEntityReceived(banner: any) {
	return {
		type: BANNER_ENTITY_RECEIVED,
		payload: banner,
	};
}

export function returnObjectForBannerSearch(text: string, project: Project) {
	return {
		type: BANNER_SEARCH,
		payload: { text, project },
	};
}

export function returnObjectForBannerSearchFailed(error: any) {
	return {
		type: BANNER_SEARCH_FAILED,
		payload: ErrorResponseModel.builder().fromResponse(error).build(),
	};
}

export function returnObjectForBannerEntityDelete(id: string, project: Project) {
	return {
		type: BANNER_ENTITY_DELETE,
		payload: { id, project },
	};
}

export function returnObjectForBannerEntityDeleteSuccess() {
	return {
		type: BANNER_ENTITY_DELETE_SUCCESS,
	};
}

export function returnObjectForBannerEntityDeleteFailed() {
	return {
		type: BANNER_ENTITY_DELETE_FAILED,
	};
}
