import React, { FunctionComponent } from 'react';
import GeneralModal from '../../../../Partials/design-components/modal/modal';
import { ModalConfig } from '../../../../../store/reducers/multisport.reducer';
import { connect } from 'react-redux';
import { closeModal, removeEntity } from '../../../../../store/action-creators/multisport-action-creator';
import { MultisportModalsType } from './constants';
import i18n from '../../../../../i18n';
import { TOP_EVENTS_SPORT } from '../../helpers/utils';

type Props = {
	modalRemoveEntity: ModalConfig;
	clearRemoveEntityModal: () => void;
	removeEntityFromRedux: () => void;
};

const generateBodyHTML = (section: string = '') => [
	<p key={1}>
		{i18n.t('multisport_remove_modal_body', {
			section: section === TOP_EVENTS_SPORT ? i18n.t('top_events') : i18n.t('sport_preview_dynamic', section),
		})}
	</p>,
	<p key={2}> {i18n.t('remove_approval')} </p>,
];

const RemovePreviewEntities: FunctionComponent<Props> = ({ modalRemoveEntity, clearRemoveEntityModal, removeEntityFromRedux }) => {
	const { displayed, data } = modalRemoveEntity;

	return (
		<GeneralModal
			isDisplayed={displayed}
			actionButtonText={i18n.t('remove_action')}
			isPositiveAction={false}
			title={i18n.t('remove_event')}
			bodyHTML={generateBodyHTML(data && data.previewSection)}
			onClose={clearRemoveEntityModal}
			action={removeEntityFromRedux}
		/>
	);
};

function mapStateToProps(state: any) {
	return {
		modalRemoveEntity: state.multisportConfiguration.modals.removeEntity || {},
	};
}

function mapDispatchToProps(dispatch: any) {
	return {
		removeEntityFromRedux: () => dispatch(removeEntity()),
		clearRemoveEntityModal: () => dispatch(closeModal(MultisportModalsType.RemoveEntity)),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(RemovePreviewEntities);
