import { call, put, takeEvery } from 'redux-saga/effects';
import HttpService from '../../services/rest/HttpService';
import { searchIsApplied, toggleLoadingState } from '../action-creators/UiActionCreator';
import { onError } from '../action-creators/GeneralActions';

import {
	LIVE_BLOGS_LISTING_REQUEST,
	LIVE_BLOGS_SEARCH,
	returnObjectForLiveBlogsListingReceived,
} from '../action-creators/LiveBlogsActionCreator';

function* fetchLiveBlogs(action: any) {
	yield put(toggleLoadingState(true));
	let liveBlogsResponse: any = {};

	try {
		let headers = { Project: action.payload.project.domain };
		const constructURL = action.payload.text && action.payload.text.length > 0 ? `?${action.payload.text}&` : '?';
		const selectedPage = action.payload.page ? action.payload.page : 1;
		liveBlogsResponse = yield call(HttpService.get, `/liveblogs${constructURL}page=${selectedPage}`, null, headers);
		yield put(returnObjectForLiveBlogsListingReceived(liveBlogsResponse));
		yield put(toggleLoadingState(false));
	} catch (error) {
		yield put(onError(error));
	}
}

function* searchLiveBlogs(action: any) {
	yield put(toggleLoadingState(true));
	let liveBlogsResponse: any = {};

	try {
		let headers = { Project: action.payload.project.domain };
		liveBlogsResponse = yield call(HttpService.get, `/liveblogs?${action.payload.text}`, null, headers);
		yield put(returnObjectForLiveBlogsListingReceived(liveBlogsResponse));
		yield put(toggleLoadingState(false));
		yield put(searchIsApplied());
	} catch (error) {
		yield put(onError(error));
	}
}

function* liveBlogsSaga() {
	yield takeEvery(LIVE_BLOGS_LISTING_REQUEST, fetchLiveBlogs);
	yield takeEvery(LIVE_BLOGS_SEARCH, searchLiveBlogs);
}

export default liveBlogsSaga;
