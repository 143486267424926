import React from 'react';
import { Row, Col, Label, Button } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Properties, State } from './properties/feed-edit.properties';
import { Title } from '../../Partials/Fields/title/TitleComponent';
import { compose } from 'redux';
import Project from '../../../models/project/Project';
import BlockableButtonWrapper from '../../Partials/BaseComponents/BlockableButton/BlockableButtonWrapper';
import { actionService } from '../../../App';
import { AppState } from '../../../store/store';
import { connect } from '../../../store/store.connect';
import {
	returnObjectForFeedEntityRequest,
	returnObjectForFeedEntityUpdate,
	returnObjectForUpdateNotifications,
	FEED_ENTITY_UPDATE_SUCCESS,
	FEED_ENTITY_UPDATE,
	FEED_ENTITY_UPDATE_FAILED,
	FEED_ENTITY_REQUEST,
	FEED_ENTITY_RECEIVED,
	returnObjectForSourceEntityRequest,
} from '../../../store/action-creators/feeds-action-creator';
import Feed from '../../../models/feed/feed';
import { Active } from '../../Partials/Fields/active/ActiveComponent';
import SourceListContainer from './feed-source-list/source-list-container';
import Source from '../../../models/feed/sources/source';
import { Subscription } from 'rxjs';
import HoToUseFeedManagement from './how-to-use/how-to.component';

@connect<Properties, AppState>(
	(state) => ({
		feed: state.feed.feed,
		selectedFeed: state.feed.selectedFeed,
		project: state.project.currentProject,
		profileId: state.profile.profile.id,
	}),
	(dispatch) => ({
		dispatch: (action: any) => dispatch(action),
		updateFeed: (feed: Feed, project: Project, profileId: string) => dispatch(returnObjectForFeedEntityUpdate(feed, project, profileId)),
		updateNotifications: (notifications: any[]) => dispatch(returnObjectForUpdateNotifications(notifications)),
		getFeed: (id: string, project: Project, profileId: string) => dispatch(returnObjectForFeedEntityRequest(id, project, profileId)),
		getSource: (location: string, project: Project, profileId: string) =>
			dispatch(returnObjectForSourceEntityRequest(location, project, profileId)),
	}),
)
class FeedEdit extends React.Component<Properties, State> {
	actionServiceSubscription: Subscription = {} as Subscription;

	constructor(props: Properties) {
		super(props);
		this.state = {
			feed: props.feed,
			isContentLoading: false,
		};
	}

	registerActionEventListener() {
		this.actionServiceSubscription = actionService.onActionReceived().subscribe((action: string) => {
			if (action === FEED_ENTITY_REQUEST) {
				this.toggleContentLoadingState(true);
			}

			if (action === FEED_ENTITY_RECEIVED) {
				this.toggleContentLoadingState(false);
			}

			if (action === FEED_ENTITY_UPDATE_SUCCESS) {
				this.props.getFeed(this.props.match.params.id, this.props.project, this.props.profileId);
			}
		});
	}

	toggleContentLoadingState(isLoading: boolean) {
		this.setState({
			...this.state,
			isContentLoading: isLoading,
		});
	}

	componentDidMount(): void {
		this.initPageTitle();
		this.registerActionEventListener();
		this.props.getFeed(this.props.match.params.id, this.props.project, this.props.profileId);
	}

	componentWillUnmount() {
		this.actionServiceSubscription.unsubscribe();
	}

	componentDidUpdate(prevProps: Readonly<Properties>, prevState: Readonly<State>): void {
		if (prevProps.feed !== this.props.feed) {
			this.setState({ feed: this.props.feed });
		}
	}

	initPageTitle() {
		document.title = this.props.t('feed_edit');
	}

	onTitleChange = (title: string) => {
		const feed = Feed.builder(this.state.feed).withName(title).build();
		this.setState({ feed });
	};

	onSortEnd = (sources: Source[]) => {
		this.setState({ feed: Feed.builder(this.state.feed).withSource(sources).build() });
	};

	onActiveChange = (active: boolean) => {
		this.setState({ feed: Feed.builder(this.state.feed).withActive(active).build() });
	};

	onSaveSource = (source: Source, index: number) => {
		let sources = this.state.feed.sources;
		sources[index] = Source.builder(source)
			.withId(source.id)
			.withName(source.name)
			.withType(source.type)
			.withSubtype(source.subtype)
			.withLocation(source.location)
			.withThumbnail(source.thumbnail)
			.build();
		this.setState({
			...this.state,
			feed: Feed.builder(this.state.feed).withSource(sources).build(),
		});
	};

	onFeedSourceRemove = (itemIndex: number) => {
		let sources = this.state.feed.sources;
		sources.splice(itemIndex, 1);
		const feed = Feed.builder(this.state.feed).withSource(sources).build();
		this.setState({ feed });
	};

	addNewSource = () => {
		let sources = this.state.feed.sources;
		sources = sources && sources.concat(Source.builder().build());

		this.setState({
			feed: Feed.builder(this.state.feed).withSource(sources).build(),
		});
	};

	onSubmit = () => {
		this.props.updateFeed(this.state.feed, this.props.project, this.props.profileId);

		if (this.props.selectedFeed.id === this.state.feed.id && !this.state.feed.active) {
			this.props.updateNotifications([]);
		}
	};

	render() {
		const { t } = this.props;

		return (
			<div className='animated fadeIn'>
				<Row>
					<Col col='12' lg='12' md='12' sm='12' xs='12'>
						<div className='card'>
							<div className='card-header'>
								<div className={'d-flex align-items-center'}>
									<span className={'mr-auto mb-0'}>
										{t('edit')}: {this.props.feed ? this.props.feed.name : ''}
									</span>
									<BlockableButtonWrapper
										blockOnActions={[FEED_ENTITY_UPDATE]}
										releaseOnActions={[FEED_ENTITY_UPDATE_SUCCESS, FEED_ENTITY_UPDATE_FAILED]}
									>
										<Button size='sm' color='primary' id='feed-edit-save-top' className={'ml-auto'} onClick={this.onSubmit}>
											<i className='fa fa-floppy-o'></i> {t('save_feed')}
										</Button>
									</BlockableButtonWrapper>
								</div>
							</div>
							<div className='card-body'>
								<div className={`${this.state.isContentLoading ? 'loading-overlay' : ''}`}>
									<Row>
										<Col xs='12'>
											<Label htmlFor='title'>{t('title')}</Label>
											<Title t={t} onChange={this.onTitleChange} value={this.state.feed ? this.state.feed.name : ''} />
										</Col>
									</Row>

									<Row>
										<Col xs='12'>
											<Active t={t} onChange={this.onActiveChange} value={this.state.feed.active} isChecked={this.state.feed.active} />
										</Col>
									</Row>

									<Row>
										<Col xs='12'>
											<SourceListContainer
												sources={this.state.feed.sources}
												onDeleteContent={this.onFeedSourceRemove}
												onSortEnd={this.onSortEnd}
												onSaveSource={this.onSaveSource}
												t={t}
												getSource={this.props.getSource}
												project={this.props.project}
												profileId={this.props.profileId}
											/>
										</Col>
									</Row>

									<Row className={'mt-2'}>
										<Col xs='12'>
											<Button onClick={this.addNewSource} className={'btn btn-outline-secondary'} title={'Add source'} color={'white'}>
												<i className='fa fa-plus'></i>
											</Button>
										</Col>
									</Row>

									<Row className={'mt-4'}>
										<Col xs='12'>
											<BlockableButtonWrapper
												blockOnActions={[FEED_ENTITY_UPDATE]}
												releaseOnActions={[FEED_ENTITY_UPDATE_SUCCESS, FEED_ENTITY_UPDATE_FAILED]}
											>
												<Button color={'primary'} id='feed-edit-save-bottom' className={'text-uppercase font-weight-bold'} onClick={this.onSubmit}>
													<i className='fa fa-floppy-o'></i> {t('save_feed')}
												</Button>
											</BlockableButtonWrapper>
										</Col>
									</Row>
								</div>
							</div>
						</div>
					</Col>
				</Row>
				<Row>
					<Col>
						<HoToUseFeedManagement t={t} />
					</Col>
				</Row>
			</div>
		);
	}
}

export default compose(withTranslation(), withRouter)(FeedEdit) as React.ComponentType;
