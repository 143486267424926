import ModelMapper from '../../models/ModelMapper';
import { toast } from 'react-toastify';
import i18n from '../../i18n';
import { actionService } from '../../App';
import {
	FEED_LISTING_RECEIVED,
	FEED_ENTITY_RECEIVED,
	FEED_ENTITY_CREATE,
	FEED_ENTITY_CREATE_FAILED,
	FEED_ENTITY_CREATE_SUCCESS,
	FEED_ENTITY_DELETE_SUCCESS,
	FEED_ENTITY_UPDATE,
	FEED_ENTITY_UPDATE_SUCCESS,
	FEED_ENTITY_UPDATE_FAILED,
	TEMPLATE_ENTITY_RECEIVED,
	TEMPLATE_LISTING_RECEIVED,
} from '../action-creators/feeds-action-creator';
import Feed from '../../models/feed/feed';

/**
 * Remap data coming from the API to the model defined in the application
 */
export function feedMiddleware() {
	return function (next: any) {
		return function (action: any) {
			if (action) {
				if (action.type === FEED_LISTING_RECEIVED) {
					const paginationData = action.payload.data.meta.pagination;
					action.payload = ModelMapper.remapFeedsFromResponse(action.payload.data);
					action.payload.contentPagination = ModelMapper.remapMetaPagination(paginationData);
				}

				if (action.type === FEED_ENTITY_RECEIVED) {
					action.payload.feed = ModelMapper.remapFeedFromResponse(action.payload);
				}

				if (action.type === FEED_ENTITY_CREATE) {
					actionService.emitGeneralAction(FEED_ENTITY_CREATE);
					action.payload.feed = Feed.builder(action.payload.feed).toRequestJson();
				}

				if (action.type === FEED_ENTITY_CREATE_FAILED) {
					actionService.emitGeneralAction(FEED_ENTITY_CREATE_FAILED);
					toast.error(i18n.t('feed_create_failed'));
				}

				if (action.type === FEED_ENTITY_CREATE_SUCCESS) {
					actionService.emitGeneralAction(FEED_ENTITY_CREATE);
					window.dispatchEvent(new CustomEvent(FEED_ENTITY_CREATE_SUCCESS, { detail: action.payload }));
					toast.success(i18n.t('feed_create_success'));
				}

				if (action.type === FEED_ENTITY_DELETE_SUCCESS) {
					toast.success(i18n.t('feed_deleted_success'));
					window.dispatchEvent(new Event(action.type));
				}

				if (action.type === FEED_ENTITY_UPDATE) {
					actionService.emitGeneralAction(FEED_ENTITY_UPDATE);
					action.payload.feed = Feed.builder(action.payload.feed).toRequestJson();
				}

				if (action.type === FEED_ENTITY_UPDATE_SUCCESS) {
					actionService.emitGeneralAction(FEED_ENTITY_UPDATE_SUCCESS);
					toast.success(i18n.t('feed_update_success'));
				}

				if (action.type === FEED_ENTITY_UPDATE_FAILED) {
					actionService.emitGeneralAction(FEED_ENTITY_UPDATE_FAILED);
					toast.error(i18n.t('feed_update_failed'));
				}

				if (action.type === TEMPLATE_ENTITY_RECEIVED) {
					action.payload.feed = ModelMapper.remapFeedFromResponse(action.payload);
				}

				if (action.type === TEMPLATE_LISTING_RECEIVED) {
					const paginationData = action.payload.data.meta.pagination;
					action.payload = ModelMapper.remapTemplatesFromResponse(action.payload);
					action.payload.contentPagination = ModelMapper.remapMetaPagination(paginationData);
				}
			}

			next(action);
		};
	};
}
