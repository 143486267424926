import React from 'react';
import { Button, Col, FormGroup, Label, Row } from 'reactstrap';
import ImageListingContainer from './subcomponents/image-listing/ImageListingContainer';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import Image from '../../../../../../models/image/Image';
import { Properties, State } from '../../properties/MediaProperties';
import MainMedia from '../../../../../../models/mainmedia/MainMedia';
import AsyncImageContainer from '../../../../BaseComponents/AsyncImage/AsyncImageContainer';
import ErrorAsync from '../../../../BaseComponents/ErrorComponent/ErrorAsync';
import ImageUploadButtonContainer from '../../../../Shared/image-upload/image-upload-button-container';
import ImageCropContainer from '../../../../Shared/image-crop/image-crop-container';
import { featuresService } from '../../../../../../App';
import { FeatureTypes } from '../../../../../../services/feature-service/features.enum';
import ImagoImageModel from '../../../../Blocky/blocks/content/imago-image-block/models/imago-image.model';
import HttpService from '../../../../../../services/rest/HttpService';
import ImagoHttpService from '../../../../../../services/rest/imago/ImagoHttpService';
import {
	addImagoImageIDtoState,
	buildMainMediaFromImago,
	checkIfArrayContainsSameImagoID,
	checkIfOnEditScreen,
	checkIfStateContainsImagoID,
	constructRequestFromFilePath,
	findImageForMainMedia,
	getImagoIDinMainMedia,
} from '../../../../Shared/image-upload/helpers/imago-image-upload.helper';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { checkForWatermarkFeature, getQuickWatermark } from '../../../../../../services/watermark-service/watermark-helper';
import ImageEditModal from '../../../../../Resources/Images/Subcomponents/image-edit-modal/ImageEditModal';
import ConditionalRenderWrapper from '../../../../BaseComponents/ConditionalRenderWraper/ConditionalRenderWrapper';
import { DebounceInput } from 'react-debounce-input';
import { ContentTypes, MainMediaTypes } from '../../../../../../constants/content-types';
import { MainMediaContentModal } from '../media-modals/main-media-content-modal.component';

class MainImageMedia extends React.Component<Properties, State> {
	imagoImagesConfig = featuresService.getFeatureConfig(FeatureTypes.IMAGO_INTEGRATION);
	imagoEnabled = featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.IMAGO_INTEGRATION);
	isQuickWatermarkEnabled = checkForWatermarkFeature() && getQuickWatermark();
	imagoHttp = new ImagoHttpService();

	constructor(props: any) {
		super(props);
		this.state = {
			displayCropModal: false,
			addDescriptionToImage: false,
			imagoCollapseIsOpen: false,
			imagoImageId: '',
			imagoIsUploaded: [],
			addedImagoMainMedia: false,
			showEditModal: false,
			imageId: '',
			watermarkAdded: false,
			watermarkRemoved: false,
			selectedImage: null,
		};
	}

	componentDidMount() {
		if (this.props.applyWatermark) {
			this.onApplyQuickWatermarkChecked();
		}
	}

	onImageSelect = (image: Image) => {
		const { media } = this.props;
		let mainMedia = MainMedia.builder(media)
			.withResourceId(image.id)
			.withProvider('smp')
			.withResourceType('image')
			.withAddWatermarkToImageMedia(this.props.applyWatermark)
			.withResourceSubtype('main_image')
			.withData(image)
			.build();
		this.setState({ addedImagoMainMedia: false });
		this.props.onMainImageMediaUpdate(mainMedia);
	};

	onImagoImageSelect = async (image: ImagoImageModel) => {
		this.setState({ ...this.state, selectedImage: image });
	};

	setImageAsMainMedia = async (image: ImagoImageModel) => {
		this.setState({ ...this.state, imagoCollapseIsOpen: false });
		await this.uploadImagoImage(image);
	};

	uploadImagoImage = async (image: ImagoImageModel) => {
		const { currentProject, media, projectOriginId, contentType } = this.props;
		const headers = { Project: currentProject.domain, 'content-type': 'multipart/form-data' };
		const form = new FormData();
		let imagePaths: string[] = [];

		//Download the Imago Image
		let editScreen = checkIfOnEditScreen(window.location.href.split('/'));
		let imagoID: string[] = getImagoIDinMainMedia(contentType, this.props, editScreen);

		if (!checkIfStateContainsImagoID(image.id, imagoID) && !checkIfArrayContainsSameImagoID(this.state.imagoIsUploaded, image.id)) {
			await this.imagoHttp.downloadImagoImage(image.type, image.id).then(async (res: any) => {
				const payload = res.data;
				if (payload && payload.size && image && image.id) {
					form.append('file', payload, `${image.id}.jpeg`);

					// Post the imago image on the image API
					try {
						await HttpService.postImg('/upload', form, headers).then((res: any) => {
							const imagePath = res.data.path;
							imagePaths.push(imagePath);

							// Create the image on the Content API
							if (imagePaths && imagePaths.length > 0) {
								HttpService.all(constructRequestFromFilePath(image, imagePaths, projectOriginId[0].id, { Project: currentProject.domain })).then(
									(res: any) => {
										//Set image as MainMedia
										let mainMedia = buildMainMediaFromImago(media, res, image);
										this.props.onMainImageMediaUpdate(mainMedia);
										this.setState({ imagoIsUploaded: addImagoImageIDtoState(this.state.imagoIsUploaded, image, mainMedia) });
										toast.success(this.props.t('imago_success'));
									},
								);
							}
						});
					} catch (err) {
						if (err.response) {
							if (err.response.status.toString() === '415') {
								toast.error(this.props.t('imago_upload_fail'));
								toast.info(this.props.t('imago_415_info'));
							} else {
								toast.error(this.props.t('imago_upload_fail'));
							}
						}
					}
				}
			});
		} else {
			if (checkIfArrayContainsSameImagoID(this.state.imagoIsUploaded, image.id)) {
				let reuseMainMedia = findImageForMainMedia(this.state.imagoIsUploaded, image.id);
				this.props.onMainImageMediaUpdate(reuseMainMedia);
				toast.success(this.props.t('imago_success'));
			} else {
				toast.error(this.props.t('image_update_failed'));
			}
		}
	};

	onDescriptionChange = (event: any) => {
		const { media } = this.props;
		//if the description is not empty, add the description to the main media
		let addDescriptionToImage = !!(media && event.target.value !== '' && this.props.displayDescriptionCheckbox);

		let mainMedia = MainMedia.builder(media)
			.withProvider('smp')
			.withResourceType('image')
			.withDescription(event.target.value)
			.withAddWatermarkToImageMedia(this.props.applyWatermark)
			.withAddDescriptionToMedia(addDescriptionToImage)
			.withResourceSubtype('main_image')
			.build();

		this.props.onMainImageMediaUpdate(mainMedia);
		this.setState({ addDescriptionToImage: addDescriptionToImage });
	};

	onImageUpload = (imageId: string, autoCrop: boolean, image: Image) => {
		const { media } = this.props;

		let mainMedia = MainMedia.builder(media)
			.withResourceId(imageId)
			.withProvider('smp')
			.withResourceType('image')
			.withResourceSubtype('main_image')
			.withAddWatermarkToImageMedia(this.props.applyWatermark)
			.withData(image)
			.build();
		window.entityUpdateService.emitImagesUpdated();
		this.props.onMainImageMediaUpdate(mainMedia);
	};

	onCropModalClose = () => {
		this.toggleCropModalState(false);
	};

	toggleCropModalState = (display: boolean) => {
		this.setState({
			...this.state,
			displayCropModal: display,
		});
	};

	isMediaObjEmpty = (media: MainMedia) => {
		return Object.entries(media).length < 1 || !media.resourceId;
	};

	onAddDescriptionToImageChecked = (e: any) => {
		const { media } = this.props;
		this.setState({ addDescriptionToImage: e.target.checked });

		let mainMedia = MainMedia.builder(media)
			.withProvider('smp')
			.withResourceType('image')
			.withAddDescriptionToMedia(e.target.checked)
			.withAddWatermarkToImageMedia(this.props.applyWatermark)
			.withResourceSubtype('main_image')
			.build();

		this.props.onMainImageMediaUpdate(mainMedia);
	};

	toggleImagoCollapse = () => {
		this.setState({
			...this.state,
			imagoCollapseIsOpen: !this.state.imagoCollapseIsOpen,
		});
	};

	onApplyQuickWatermarkChecked = (e?: any) => {
		const { media } = this.props;
		let addWatermark = { mainMediaWatermark: e ? e.target.checked : this.props.applyWatermark };
		let mainMedia = MainMedia.builder(media)
			.withProvider('smp')
			.withResourceType('image')
			.withAddWatermarkToImageMedia(e ? e.target.checked : this.props.applyWatermark)
			.withGeneric(addWatermark)
			.withResourceSubtype('main_image')
			.build();

		this.props.onMainImageMediaUpdate(mainMedia);
	};

	toggleEditModal(imageId: string, isOpen: boolean) {
		imageId = this.props.media.resourceId;
		this.setState({
			...this.state,
			showEditModal: isOpen,
			imageId: imageId,
		});
	}

	render() {
		const { t, currentProject, media, mediaErrorIndex, displayDescriptionCheckbox, contentType } = this.props;
		const { displayCropModal, imagoCollapseIsOpen, showEditModal, imageId } = this.state;
		let displayWatermarkForContent: boolean =
			contentType === 'article' || contentType === 'video' || contentType === 'gallery' || contentType === ContentTypes.WIKI_PAGE;
		return (
			<div id='sidebar-media-main-image'>
				<Row>
					<Col>
						<Label className='font-weight-bold'>{t('main_image')}</Label>
					</Col>
				</Row>
				<Row>
					<Col col='12' sm='12' md='6' className='mb-2'>
						<AsyncImageContainer
							isWatermarkAdded={this.state.watermarkAdded}
							isWatermarkRemoved={this.state.watermarkRemoved}
							imageId={media && media.resourceId ? media.resourceId : ''}
							projectId={currentProject.domain}
						/>
					</Col>
					<Col col='12' sm='12' md='6' className='mb-4'>
						<FormGroup>
							<Label htmlFor='image_description'>{t('image_description')}</Label>
							<DebounceInput
								name='image_description'
								id='image_description'
								element='textarea'
								rows='3'
								value={media && media.description ? media.description : ''}
								onChange={this.onDescriptionChange}
								className='form-control-lg form-control'
								placeholder={t('image_description_placeholder')}
								debounceTimeout={300}
								disabled={!media.resourceId}
							/>
						</FormGroup>
						{displayDescriptionCheckbox && (
							<Row className={'mb-1'}>
								<Col>
									<input
										className={'mr-1'}
										id={`description-to-image`}
										disabled={!(media && media.resourceType)}
										checked={this.state.addDescriptionToImage}
										onChange={this.onAddDescriptionToImageChecked}
										type={'checkbox'}
									/>
									<label htmlFor={`description-to-image`}>{this.props.t('add_description_to_image')}</label>
								</Col>
							</Row>
						)}
						{this.isQuickWatermarkEnabled && displayWatermarkForContent && (
							<Row className={'mb-1'}>
								<Col>
									<input
										checked={this.props.applyWatermark}
										className={'mr-1'}
										type='checkbox'
										id='apply-quick-watermark'
										onChange={this.onApplyQuickWatermarkChecked}
									/>
									<label htmlFor='apply-quick-watermark-items'>{t('quick_watermark')}</label>
								</Col>
							</Row>
						)}
					</Col>
				</Row>
				<Row className={'mb-3'}>
					<Col>
						{!this.props.additionalMainMediaItemsOpen && (
							<ImageUploadButtonContainer
								t={t}
								imageUploadButtonId='main-image-media-image-upload-button'
								project={currentProject}
								blockStyle={true}
								onImageUploadSuccess={this.onImageUpload}
								display
								applyQuickWatermark={this.isQuickWatermarkEnabled && this.props.applyWatermark}
							/>
						)}
						<ConditionalRenderWrapper expectedPermissions={['write_images']}>
							<Button
								id={`main-media-image-edit-btn-${imageId}`}
								disabled={this.isMediaObjEmpty(media)}
								block
								color='warning'
								className='edit-button'
								onClick={() => this.toggleEditModal(imageId, true)}
							>
								<i className='fa fa-pencil' /> {t('edit_image')}
							</Button>
						</ConditionalRenderWrapper>
						<ImageEditModal
							onClose={(imageUpdated: boolean, watermarkAdded: boolean, watermarkRemoved: boolean) => {
								this.toggleEditModal('', false);

								if (watermarkAdded || watermarkRemoved) {
									if (watermarkAdded) {
										this.setState({ watermarkAdded: true, watermarkRemoved: false });
									} else if (watermarkRemoved) {
										this.setState({ watermarkRemoved: true, watermarkAdded: false });
									}
								}
							}}
							imageId={imageId}
							currentProject={currentProject}
							open={showEditModal}
							watermarkEnabled={featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.WATERMARK)}
							t={t}
							isPlayingSurface={false}
							isImagoImage={false}
						/>
						<Button
							className='crop-button'
							block
							color='secondary'
							disabled={this.isMediaObjEmpty(media)}
							onClick={() => {
								this.toggleCropModalState(true);
							}}
						>
							<i className={'fa fa-crop'}></i> {t('crop_image')}
						</Button>
					</Col>
				</Row>
				<ErrorAsync t={t} errorType='main_media' mainMedia={`main_media.${mediaErrorIndex}.description`} />
				<Row>
					<Col>
						<div data-children='.item'>
							<div className='item'>
								<ImageListingContainer currentProject={currentProject} withToggle={true} onImageSelect={this.onImageSelect} t={t} />
							</div>
						</div>
					</Col>
				</Row>
				<Row>
					<Col>
						{this.imagoEnabled && (
							<Button
								className='m-0 p-0'
								color='link'
								onClick={this.toggleImagoCollapse}
								aria-expanded={imagoCollapseIsOpen}
								aria-controls='toggle-sidebar-images-list-for-main-media'
							>
								{t('toggle_imago')}
							</Button>
						)}
						<MainMediaContentModal
							t={t}
							onSelect={this.setImageAsMainMedia}
							isOpen={imagoCollapseIsOpen}
							toggleModal={this.toggleImagoCollapse}
							project={currentProject}
							contentType={MainMediaTypes.IMAGO_IMAGE}
						/>
					</Col>
				</Row>
				<Row>
					<Col className={'text-center my-2'}>
						<hr />
					</Col>
				</Row>

				<ImageCropContainer
					t={t}
					isWatermarkAdded={this.state.watermarkAdded}
					isWatermarkRemoved={this.state.watermarkRemoved}
					imageId={media.resourceId}
					currentProject={currentProject}
					open={displayCropModal}
					onClose={this.onCropModalClose}
				/>
			</div>
		);
	}
}

function mapStateToProps(state: any) {
	return {
		mainMediaGenericFieldArticle: state.tempArticle.article.mainMedia,
		mainMediaGenericFieldArticleEdit: state.tempArticle.articleEdit.mainMedia,
		mainMediaGenericFieldWikiEdit: state.tempWiki.wikiEdit.mainMedia,
		mainMediaGenericFieldVideo: state.tempVideo.video.mainMedia,
		mainMediaGenericFieldVideoEdit: state.tempVideo.videoEdit.mainMedia,
		mainMediaGenericFieldGallery: state.tempGallery.gallery.mainMedia,
		mainMediaGenericFieldGalleryEdit: state.tempGallery.galleryEdit.mainMedia,
		projectOriginId: state.origins.imageOrigins,
	};
}

export default compose(connect(mapStateToProps), withTranslation())(MainImageMedia) as React.ComponentType<any>;
