import REACT_APP_URLS, { Environments } from '../../global-helpers/global-url.helpers';
import { getAnalyticsData } from './widget-analytics';

declare global {
	interface Window {
		gtag: any;
	}
}

export default class GoogleAnalytics {
	projectId = '';
	isProdEnv = REACT_APP_URLS.REACT_APP_ENV === Environments.PRODUCTION;

	constructor(projectId: string) {
		this.projectId = projectId;
	}

	public init(projectId: string) {
		this.projectId = projectId;
		this.isProdEnv && window.gtag('js', new Date());
		this.isProdEnv && window.gtag('config', 'G-Y67V3EZED4');
	}

	public sendPageView(pagePath: string, pageTitle: string) {
		this.isProdEnv &&
			window.gtag('config', 'G-Y67V3EZED4', {
				custom_map: {
					dimension1: 'Project',
					dimension2: 'User',
				},
				Project: this.projectId,
				page_title: pageTitle,
				page_path: pagePath,
			});
	}

	public sendEvent(action: string, category: string, label: string) {
		this.isProdEnv &&
			window.gtag('event', action, {
				event_category: category,
				event_label: label,
				project: this.projectId,
			});
	}

	public sendWidgetAnalytic() {
		const analytics = this.isProdEnv ? getAnalyticsData() : null;

		analytics &&
			// event name should not exceed 40 characters
			window.gtag('event', `W ${analytics.projectName} ${analytics.contentTypeId}`, {
				label: `${analytics.contentType} - ${analytics.contentTypeId} modified by ${analytics.userEmail}`,
				content_type: analytics.contentType,
				content_type_id: analytics.contentTypeId,
				content_status: analytics.contentStatus,
				user_id: analytics.userId,
				user_email: analytics.userEmail,
				project_name: analytics.projectName,
				widgets_count: analytics.widgetsCount,
				...analytics.widgetsAnalytics,
			});
	}
}
