import React, { useEffect, useState } from 'react';
import TournamentOrderModel from '../../../../models/v2/Tournament/TournamentOrder/TournamentOrderModel';
import ContentSortableItem from './SortableItemContent';
import TournamentGroupModel from '../../../../../../models/v2/Tournament/Group/TournamentGroupModel';
import * as ReactSortableHOC from 'react-sortable-hoc';
import { isEmptyTournament, notSelectedTournamentsToOptions } from '../../../helpers/TournamentsGroupsUiService';
import TournamentModel from '../../../../../../models/v2/Tournament/Entity/TournamentModel';
import './SortableList.scss';

type Props = {
	t: any;
	tournamentsGroup: TournamentGroupModel;
	handleDeleteRow: Function;
	pickNewTournament: Function;
};

const ContentSortableList: React.FunctionComponent<Props> = ({ tournamentsGroup, handleDeleteRow, pickNewTournament, t }) => {
	const [fetchedSelectTournaments, setFetchedSelectTournaments] = useState([] as TournamentModel[]); // displayed only in the select options
	const [tournamentOptions, setTournamentOptions] = useState([] as any); // displayed only in the select options

	useEffect(() => {
		// remove/add already selected tournaments in select menu
		const selectMenuOptions = notSelectedTournamentsToOptions(fetchedSelectTournaments, tournamentsGroup.tournaments);
		setTournamentOptions(selectMenuOptions);
	}, [tournamentsGroup.tournaments.length, fetchedSelectTournaments]);

	return (
		<ul className='list-group tournament-list-group'>
			{tournamentsGroup.tournaments.map((tournamentOrder: TournamentOrderModel, index: number) => (
				<ContentSortableItem
					tournamentOrder={tournamentOrder}
					key={`key-name:${tournamentOrder.tournament.type}-key-id:${tournamentOrder.tournament.id}`}
					disabled={isEmptyTournament(tournamentOrder)}
					pickNewTournament={pickNewTournament}
					index={index}
					handleDeleteRow={handleDeleteRow}
					setFetchedSelectTournaments={setFetchedSelectTournaments}
					tournamentOptions={isEmptyTournament(tournamentOrder) ? tournamentOptions : []}
					t={t}
				/>
			))}
		</ul>
	);
};

export default ReactSortableHOC.SortableContainer(ContentSortableList);
