import { MatchWidgetJson } from './match-widget.json';
import MatchWidgetBuilder from './macth-widget.builder';
import MatchModel from '../../../../partials/match-select/models/match.model';
import TeamModel from '../../../../partials/team-select/models/team.model';

export default class MatchWidgetModel {
	readonly match: MatchModel;
	readonly teams: TeamModel[];
	readonly activeTab: string = '';
	readonly hideTabs: string[] = [];
	readonly displayOdds: boolean = true;
	readonly hideEvents: boolean = false;
	readonly homeTeamColor: any = '';
	readonly awayTeamColor: any = '';
	readonly threeLetterCodeType: any = '';

	private constructor(
		match: MatchModel,
		teams: TeamModel[],
		activeTab: string,
		hideTabs: string[],
		displayOdds: boolean,
		hideEvents: boolean,
		homeTeamColor: string,
		awayTeamColor: string,
		threeLetterCodeType: string,
	) {
		this.match = match;
		this.teams = teams;
		this.activeTab = activeTab;
		this.hideTabs = hideTabs;
		this.displayOdds = displayOdds;
		this.hideEvents = hideEvents;
		this.homeTeamColor = homeTeamColor;
		this.awayTeamColor = awayTeamColor;
		this.threeLetterCodeType = threeLetterCodeType;
	}

	toJson(): MatchWidgetJson {
		return {
			match: this.match,
			teams: this.teams,
			activeTab: this.activeTab,
			hideTabs: this.hideTabs,
			displayOdds: this.displayOdds,
			hideEvents: this.hideEvents,
			homeTeamColor: this.homeTeamColor,
			awayTeamColor: this.awayTeamColor,
			threeLetterCodeType: this.threeLetterCodeType,
		};
	}

	static fromJson(json: MatchWidgetJson): MatchWidgetModel {
		return new MatchWidgetModel(
			json.match,
			json.teams,
			json.activeTab,
			json.hideTabs,
			json.displayOdds,
			json.hideEvents,
			json.homeTeamColor,
			json.awayTeamColor,
			json.threeLetterCodeType,
		);
	}

	static builder(matchWidget?: MatchWidgetModel): MatchWidgetBuilder {
		return new MatchWidgetBuilder(matchWidget);
	}
}
