export default class CaptionBuilder {
	private caption: string = '';

	public withType(type: string, t: any): CaptionBuilder {
		if (type && type.length > 0) {
			this.caption += `${t('type')}: ${type} `;
		}

		return this;
	}

	public withDescription(description: string, t: any): CaptionBuilder {
		if (description && description.length > 0) {
			this.caption += `| ${t('description')}: ${description} `;
		}

		return this;
	}

	public withOwnerName(owner: { name: string }, t: any): CaptionBuilder {
		if (owner && owner.name) {
			this.caption += `| ${t('origin')}: ${owner.name} `;
		}

		return this;
	}

	public withPlayers(players: string, t: any): CaptionBuilder {
		if (players && players.length > 0) {
			this.caption += `| ${t('players')}: ${players} `;
		}

		return this;
	}
	public withTeams(teams: string, t: any): CaptionBuilder {
		if (teams && teams.length > 0) {
			this.caption += `| ${t('teams')}: ${teams} `;
		}

		return this;
	}

	public build(): string {
		return this.caption;
	}
}
