import { NodeViewWrapper } from '@tiptap/react';
import classNames from 'classnames';
import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { ContentTypes } from '../../../../../../constants/content-types';
import { ViewTypes } from '../../../../../Partials/Blocky/constants/general.constants';
import ContentBlocksGenerator from '../../../../../Partials/Blocky/generators/content-blocks.generator';
import WidgetBlocksV2Generator from '../../../../../Partials/Blocky/generators/widget-blocks-v2.generator';
import WidgetBlocksGenerator from '../../../../../Partials/Blocky/generators/widget-blocks.generator';
import { LiveBlogEditorialAdminContext } from '../../main-components/live-blog-editorial-admin.component';

export const EditorWidgetPreview: FC<Record<string, any>> = (props) => {
	const [key, setKey] = useState(Math.random());
	const contentBlocksGenerator = useMemo(() => new ContentBlocksGenerator(), []);
	const widgetBlocksGenerator = useMemo(() => new WidgetBlocksGenerator(), []);
	const widgetBlocksV2Generator = useMemo(() => new WidgetBlocksV2Generator(), []);
	const [t] = useTranslation();
	const liveBlogConfiguration = useContext(LiveBlogEditorialAdminContext);
	const dataBlock = props.node.attrs['data-block'];
	const dataContent = props.node.attrs['data-content'];
	const block = useMemo(() => {
		try {
			return JSON.parse(dataBlock);
		} catch (e) {
			toast.error(t('parsing_error'));
			console.error(e);
		}
	}, [dataBlock]);
	const content = useMemo(() => {
		try {
			return JSON.parse(dataContent);
		} catch (e) {
			toast.error(t('parsing_error'));
			console.error(e);
		}
	}, [dataContent]);
	const additionalAttributes = Object.fromEntries(
		Object.entries(props.node.attrs).filter(([name]) => name.startsWith('data-') && !['data-block', 'data-content'].includes(name)),
	);

	// NB: This is a workaround necessary due to the behavior of the ContentBlocksGenerator-constructed component which is not re-rendered on each prop change
	useEffect(() => {
		setKey(Math.random());
	}, [block]);

	if (!liveBlogConfiguration || !block || !content) {
		return null;
	}

	const isContentBlock = !block.type.startsWith('widget_smp');

	return (
		<NodeViewWrapper
			className={classNames('editor-widget-preview', {
				'content-block': isContentBlock,
				'widget-v1': block.type === 'widget_smp',
				'widget-v2': block.type === 'widget_smp_V2',
			})}
			data-block={JSON.stringify(block)}
			data-content={JSON.stringify(content)}
			{...additionalAttributes}
		>
			<React.Fragment key={key}>
				{isContentBlock &&
					contentBlocksGenerator.createBlock(
						block,
						t,
						content,
						undefined,
						liveBlogConfiguration.language,
						ContentTypes.LIVE_BLOG,
						undefined,
						ViewTypes.normal,
					)}
				{block.type === 'widget_smp' && widgetBlocksGenerator.createBlock(block, t, content, liveBlogConfiguration.language, ViewTypes.normal)}
				{block.type === 'widget_smp_V2' &&
					widgetBlocksV2Generator.createBlock(block, t, content, liveBlogConfiguration.language, ViewTypes.normal)}
			</React.Fragment>
		</NodeViewWrapper>
	);
};
