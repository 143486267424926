import React, { FunctionComponent, useEffect, useState } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

type Properties = {
	t: any;
	headerContent?: string;
	bodyContent?: string;
	submitFunction?: () => any;
	beforeCloseFunction?: () => any;
	isOpen: boolean;
};

const GlobalModal: FunctionComponent<Properties> = ({ t, headerContent, bodyContent, submitFunction, beforeCloseFunction, isOpen }) => {
	const [modalOpenFlag, setModalOpenFlag] = useState<boolean>(false);

	useEffect(() => {
		setModalOpenFlag(isOpen);
	}, [isOpen]);

	const onCloseModal = () => {
		beforeCloseFunction && beforeCloseFunction();
		setModalOpenFlag(false);
	};

	const onSaveModal = () => {
		submitFunction && submitFunction();
		setModalOpenFlag(false);
	};

	return (
		<Modal isOpen={modalOpenFlag}>
			{headerContent && <ModalHeader toggle={onCloseModal}>{t(headerContent)}</ModalHeader>}
			{bodyContent && (
				<ModalBody>
					<div>
						<p className='font-weight-bold'>{t(bodyContent)}</p>
					</div>
				</ModalBody>
			)}
			<ModalFooter className='modal-footer-modified'>
				<button className='btn btn-primary' onClick={onSaveModal}>
					{t('save')}
				</button>
				<button className='btn btn-light' onClick={onCloseModal}>
					{t('cancel')}
				</button>
			</ModalFooter>
		</Modal>
	);
};

export default GlobalModal;
