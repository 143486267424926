import React, { useEffect, useRef, useState } from 'react';
import { Col, Card, CardBody, CardHeader, Collapse } from 'reactstrap';
import { SortableContainer, SortEnd } from 'react-sortable-hoc';
import {
	reorderLineups,
	filterPlayers,
	updateFormationPositions,
	addPlayerToLineups,
	defineDefaultPositions,
	removePlaceholdersFromLineups,
	mapPlayerToSubstututeType,
	mapPlayerToStarterType,
} from '../helpers/lists-update.helper';
import HttpService from '../../../../../services/rest/HttpService';
import LineupPlayerModel from '../../models/player-lineup/lineup-player.model';
import TeamFormationSelect from './team-formation-select.component';
import TeamLineupModel from '../../models/team-lineup/team-lineup.model';
import LineupsList from './lineups-list.component';
import SubstitutesList from './substitutes-list.component';
import SquadList from './squad-list.component';
import {
	lineupsTypes,
	startingElevenTypes,
	remapPlayersLineup,
	filterTeamLineupFromSquad,
	filterPlayersByType,
	filterPlayersByMultipleTypes,
	Formation,
	PlayersType,
} from '../helpers/response-remappers.helper';
import './styles/team-formation.scss';
import TeamModel from '../../../../Partials/Blocky/partials/team-select/models/team.model';
import { toast } from 'react-toastify';
import ColorPickerComponent from '../../../../Partials/Blocky/blocks/widgets/match/components/color-picker.component';
import axios from 'axios';
import { TOKEN_KEY } from '../../../../../constants/constants';

type Properties = {
	t: any;
	team: TeamModel;
	teamLineup: TeamLineupModel | null;
	formations: Formation[];
	squad: LineupPlayerModel[];
	substitutes: LineupPlayerModel[];
	setSquad: (players: LineupPlayerModel[]) => void;
	setSubstitutes: (players: LineupPlayerModel[]) => void;
	teamFormation: Formation;
	selectFormation: (formation: Formation) => void;
	playersTypes: PlayersType;
};

const SortableList = SortableContainer(LineupsList);

const Team: React.FunctionComponent<Properties> = (props) => {
	const { t, team, formations, teamLineup, squad, substitutes, teamFormation, playersTypes } = props;
	const { setSquad, setSubstitutes, selectFormation } = props;

	const [isOpen, setIsOpen] = useState(true);

	const toggle = () => setIsOpen(!isOpen);

	// If we have already chosen 11 (or less) players they should be placed in the newly selected formation.
	const formationChange = (formation: Formation | null) =>
		selectFormation(updateFormationPositions(formation, teamFormation.positions, playersTypes));

	const addSquadPlayer = (player: LineupPlayerModel) => {
		// Extracts the real players in the lineups list from the placeholders.
		const lineups = removePlaceholdersFromLineups(teamFormation.positions);

		// Adds the player to starting eleven players if they are less than 11.
		// Case 1: the team has selected formation.
		if (lineups.length < 11 && teamFormation.formation) {
			addPlayerToLineups(teamFormation, player, squad, selectFormation, setSquad);
		} else if (!teamFormation.formation && teamFormation.positions.length < 11) {
			// Case 2: the team has no selected formation.
			selectFormation({ formation: null, positions: [...teamFormation.positions, mapPlayerToStarterType(player, playersTypes.STARTER)] });
			setSquad([...squad.filter(filterPlayers(player.player.id))]);
		} else {
			// If the starting eleven players are greater than 11 the player should be added to the substitutes list.
			setSquad([...squad.filter(filterPlayers(player.player.id))]);
			setSubstitutes([...substitutes, mapPlayerToSubstututeType(player, playersTypes.SUBSTITUTE_PLAYER)]);
		}
	};

	const addSubstitute = (player: LineupPlayerModel) => {
		if (removePlaceholdersFromLineups(teamFormation.positions).length < 11) {
			// Case 1: the team has selected formation.
			if (teamFormation.formation) {
				// Adds the player to team lineups if they are less than 11.
				addPlayerToLineups(teamFormation, player, substitutes, selectFormation, setSubstitutes);
			}
			// Case 2: the team has no selected formation.
			if (!teamFormation.formation) {
				// Adds the player to team lineups if they are less than 11.
				selectFormation({ formation: null, positions: [...teamFormation.positions, mapPlayerToStarterType(player, playersTypes.STARTER)] });
				setSubstitutes([...substitutes.filter(filterPlayers(player.player.id))]);
			}
		} else {
			// The toast notification will show up when adding a player to the full team lineup list.
			toast.error(t('starting_eleven_players_set'));
		}
	};

	const removeLineup = (player: LineupPlayerModel) => {
		// For the removed player should be updated typeId and positinX/positionY, then added to the substitutes list.
		setSubstitutes([...substitutes, mapPlayerToSubstututeType(player, playersTypes.SUBSTITUTE_PLAYER)]);

		// Case 1: the team has selected formation.
		if (teamFormation.formation) {
			// The lineup list is rearranged always to fill the first empty positions with players.
			const positionsWithPlayers = teamFormation.positions.filter(filterPlayers(player.player.id));
			const newFormation = updateFormationPositions(
				defineDefaultPositions(formations, teamFormation.formation),
				positionsWithPlayers,
				playersTypes,
			);

			// Set the new player's positions in the formation.
			selectFormation(newFormation);
		}
		// Case 2: the team has no selected formation.
		if (!teamFormation.formation) {
			const newFormation = { formation: null, positions: teamFormation.positions.filter(filterPlayers(player.player.id)) };
			selectFormation(newFormation);
		}
	};

	const removeSubstitute = (player: LineupPlayerModel) => {
		// The removed player is added to the squad list.
		setSubstitutes([...substitutes.filter(filterPlayers(player.player.id))]);
		setSquad([...squad, player]);
	};

	const onSortEnd = (sortEnd: SortEnd) => {
		// In sorting lineups list we replace the player information (for the corresponding indexes) in the LineupPlayerModel
		// and keep its positionX and positionY.
		selectFormation({ formation: teamFormation.formation, positions: reorderLineups(sortEnd, teamFormation.positions) });
	};

	const getSquad = (id: string) => {
		// Get team squad to fill other team players.
		HttpService.instanceFootball()
			.get(`/v2/teams/${id}/squad?memberStatus=ACTIVE`)
			.then((response: any) => {
				const availableLineup = teamLineup ? teamLineup.players : [];
				// Filter squad response to extract only unused in the team lineup.
				setSquad(filterTeamLineupFromSquad(remapPlayersLineup(response.data.players), availableLineup, lineupsTypes(playersTypes)));

				// Define team lineup players with/without selected formation.
				const formation =
					teamLineup && teamLineup.formation
						? updateFormationPositions(
								// In some cases we have starting 11 players with null value at positionX or positionY property and we need
								// to define team formation from all formations and remap players with the correct positionX and positionY values.
								defineDefaultPositions(formations, teamLineup.formation),
								// Filter only starting eleven players from all lineups.
								filterPlayersByMultipleTypes(availableLineup, startingElevenTypes(playersTypes)),
								playersTypes,
						  )
						: // The formation positions will be updated with the available team lineups (starting eleven) with typeId '1' (STARTER).
						  { formation: null, positions: filterPlayersByType([...availableLineup], playersTypes.STARTER) };

				// Define the team substitutes by filtering the team lineup with the player with typeId '2' (SUBSTITUTE_PLAYER).
				setSubstitutes(filterPlayersByType([...availableLineup], playersTypes.SUBSTITUTE_PLAYER));
				selectFormation(formation);
			})
			.catch((error: unknown) => console.error(error));
	};

	useEffect(() => {
		getSquad(team.id);
	}, [team]);

	return (
		<Col xs={12} md={6}>
			<Card className='animated fadeIn'>
				<CardHeader className='py-2'>
					<div className='d-flex align-items-center'>
						<img className='mr-2' src={team.logo} width={25} />
						<strong className='mr-auto mb-0'>{team.name}</strong>
					</div>
				</CardHeader>
				<CardBody className='p-2'>
					{teamLineup && (
						<>
							<TeamFormationSelect teamId={team.id} onSelect={formationChange} t={t} teamFormation={teamFormation} formations={formations} />

							<Card className='animated fadeIn'>
								<CardHeader className='cursor-pointer d-flex align-items-center' onClick={toggle}>
									{t('starting_eleven')}
									<i className={`ml-auto fa fa-lg fa-angle-${isOpen ? 'down' : 'left'}`} />
								</CardHeader>
								<Collapse isOpen={isOpen}>
									{removePlaceholdersFromLineups(teamFormation.positions).length > 0 ? (
										<SortableList t={t} onSortEnd={onSortEnd} removeLineup={removeLineup} lineups={teamFormation.positions} />
									) : (
										<p className='text-center px-2 py-4'>{t('no_selected_players')}</p>
									)}
								</Collapse>
							</Card>
						</>
					)}
					<SubstitutesList t={t} addSubstitute={addSubstitute} removeSubstitute={removeSubstitute} substitutes={substitutes} />
					<SquadList t={t} addSquadPlayer={addSquadPlayer} squad={squad} />
				</CardBody>
			</Card>
		</Col>
	);
};

export default Team;
