import React from 'react';
import { compose } from 'redux';
import { ViewTypes } from '../../../../constants/general.constants';
import BlockModel from '../../../../models/block.model';
import BlockUpdateController from '../../../../blocks/block-update-controller.component';
import { MonorepoWidgetPreview } from '../../../../blocks/widgets/widget-preview/monorepo-widget-preview.component';
import { SportBlockTypes, SportTypes, WidgetScriptTypes } from '../../../../../../../constants/sport.types';
import FootballMostDecoratedPlayersModel from '../models/football-most-decorated-players.model';
import { FootballMostDecoratedPlayersView } from './football-most-decorated-players-view.component';
import FootballMostDecoratedPlayersEditComponent from './football-most-decorated-players-edit.component';

type Properties = {
	block: BlockModel;
	view: ViewTypes;
	contentData: any;
	t: any;
};

const FootballMostDecoratedPlayers: React.FunctionComponent<Properties> = (props) => {
	const { view, t, block, contentData } = props;

	if (view === ViewTypes.edit) {
		return <FootballMostDecoratedPlayersEditComponent block={block} t={t} contentData={contentData} />;
	}

	if (view === ViewTypes.preview) {
		return (
			<MonorepoWidgetPreview
				blockContent={props.block.data.content}
				config={props.block.data.config}
				type={WidgetScriptTypes.FOOTBALL_WIDGETS}
				blockType={SportBlockTypes.TOURNAMENT_BLOCK}
				sport={SportTypes.FOOTBALL}
			/>
		);
	}

	if (view === ViewTypes.normal) {
		return (
			<FootballMostDecoratedPlayersView
				preview={
					block && block.data && block.data.preview && block.data.preview.widgetModel
						? block.data.preview.widgetModel
						: FootballMostDecoratedPlayersModel.builder().build()
				}
				t={t}
			/>
		);
	}

	return null;
};

export default compose(BlockUpdateController)(FootballMostDecoratedPlayers);
