import ModelMapper from '../../models/ModelMapper';
import { toast } from 'react-toastify';
import i18n from '../../i18n';
import {
	DELETE_LIST_ITEM_REQUEST,
	LIST_ENTITY_CREATE,
	LIST_ENTITY_CREATE_FAILED,
	LIST_ENTITY_CREATE_SUCCESS,
	LIST_ENTITY_DELETE_SUCCESS,
	LIST_ENTITY_RECEIVED,
	LIST_ENTITY_UPDATE,
	LIST_ENTITY_UPDATE_FAILED,
	LIST_ENTITY_UPDATE_SUCCESS,
	LIST_ITEMS_CONTENT_STATISTICS_RECEIVED,
	LIST_LISTING_RECEIVED,
} from '../action-creators/ListActionCreators';
import List from '../../models/list/List';
import { actionService } from '../../App';
import { populateListLockedPositions } from '../../views/Partials/Sidebar/list-refactored/helpers/general.helper';
import { formatScheduleListProperties, removeScheduledListProperties } from '../../views/Resources/Lists/helpers/list.helper';

/**
 * Remap data coming from the API to the model defined in the application
 */
export function listMiddleware() {
	return function (next: any) {
		return function (action: any) {
			if (action) {
				if (action.type === LIST_LISTING_RECEIVED) {
					const paginationData = action.payload.data.meta.pagination;
					action.payload = ModelMapper.remapListsFromResponse(action.payload.data.data);
					action.payload.contentPagination = ModelMapper.remapMetaPagination(paginationData);
				}

				if (action.type === LIST_ENTITY_RECEIVED) {
					action.payload.list = ModelMapper.remapListFromResponse(action.payload.data.data);
					action.payload.list = populateListLockedPositions(action.payload.list);
					actionService.emitListEntityReceived(action.payload.list);
				}

				if (action.type === LIST_ENTITY_CREATE) {
					actionService.emitGeneralAction(LIST_ENTITY_CREATE);
					removeScheduledListProperties(action.payload.list);
					action.payload.list = List.builder(action.payload.list).toRequestJson();
				}

				if (action.type === LIST_ENTITY_CREATE_FAILED) {
					actionService.emitGeneralAction(LIST_ENTITY_CREATE_FAILED);
					toast.error(i18n.t('list_create_failed'));
				}

				if (action.type === LIST_ENTITY_CREATE_SUCCESS) {
					actionService.emitGeneralAction(LIST_ENTITY_CREATE);
					//TODO replace window.dispatchEvent with actionService
					window.dispatchEvent(new CustomEvent(LIST_ENTITY_CREATE_SUCCESS, { detail: action.payload }));
					toast.success(i18n.t('list_create_success'));
				}

				if (action.type === LIST_ENTITY_DELETE_SUCCESS) {
					toast.success(i18n.t('list_deleted_success'));

					//TODO replace window.dispatchEvent with actionService
					window.dispatchEvent(new Event(action.type));
				}

				if (action.type === LIST_ENTITY_UPDATE) {
					actionService.emitGeneralAction(LIST_ENTITY_UPDATE);
					if (action.payload.areOnlyItemsUpdate) {
						// this action property should be passed only when we are managing items elements in List
						// ex. Article edit -> we can only add/remove items in List (we can't modify the whole List configuration)
						action.payload.list = { id: action.payload.list.id, items: List.builder().remapListItemsForRequestJson(action.payload.list.items) };
					} else {
						formatScheduleListProperties(action.payload.list);
						action.payload.list = List.builder(action.payload.list).toRequestJson();
					}
				}

				if (action.type === LIST_ENTITY_UPDATE_SUCCESS) {
					actionService.emitGeneralAction(LIST_ENTITY_UPDATE_SUCCESS);
					toast.success(i18n.t('list_update_success'));
				}

				if (action.type === LIST_ENTITY_UPDATE_FAILED) {
					actionService.emitGeneralAction(LIST_ENTITY_UPDATE_FAILED);
					let errorMessageNotification = i18n.t('list_update_failed');

					if (action.message === "Invalid 'to' date: Should be a future date") {
						errorMessageNotification += ` ${i18n.t('selected_date_should_be_future')}`;
					}

					toast.error(errorMessageNotification);
				}

				if (action.type === DELETE_LIST_ITEM_REQUEST) {
					toast.success(i18n.t('delete_list_items_save'));
				}

				if (action.type === LIST_ITEMS_CONTENT_STATISTICS_RECEIVED) {
					action.payload.list = ModelMapper.remmapListItemsWithContentStatisticsFromResponse(
						action.payload.contentStatistics,
						action.payload.list,
					);
				}
			}

			next(action);
		};
	};
}
