import React from 'react';
import Select from 'react-select';
import { FormFeedback, Label } from 'reactstrap';
import { customOption } from '../shared/custom-select-option';
import HttpService from '../../../../../services/rest/HttpService';
import {
	standingsTeamToOption,
	standingsTeamsToOptions,
	remapTeamFromResponse,
	selectionsToTeams,
} from './helpers/standings-team-select.helper';
import TeamModel from '../team-select/models/team.model';
import StandingsWidgetModel from '../../blocks/widgets/standings/models/standings-widget.model';

type Properties = {
	onTeamsSelect: any;
	value: any;
	t: any;
	stageId: string;
	isMulti: boolean;
	standings: StandingsWidgetModel;
};

type State = {
	teamsOptions: TeamModel[];
};

export default class StandingsTeamsSelectContainer extends React.Component<Properties, State> {
	constructor(props: Properties) {
		super(props);
		this.state = {
			teamsOptions: [],
		};
	}

	componentDidMount() {
		const { stageId } = this.props;

		if (stageId) {
			this.getStageTeams(stageId);
		}
	}

	componentDidUpdate(prevProps: Properties) {
		if (this.props.stageId && prevProps.stageId !== this.props.stageId) {
			this.getStageTeams(this.props.stageId);
		}
	}

	updateTeamsOptionsState = (teams: TeamModel[]) => this.setState({ ...this.state, teamsOptions: teams });

	getStageTeams = (stageId: string) => {
		HttpService.instanceFootball()
			.get(`/tournaments/seasons/stages/${stageId}/teams`)
			.then((response: any) => {
				this.updateTeamsOptionsState(remapTeamFromResponse(response.data));
			})
			.catch((e: any) => e);
	};

	render() {
		const { teamsOptions } = this.state;
		const { onTeamsSelect, stageId, value, t, standings } = this.props;

		if (!stageId) {
			return null;
		}

		return (
			<>
				{this.props.isMulti ? (
					<>
						<Label>{t('pick_teams_highlight')}:</Label>
						<Select
							id='standings-highlight-teams-select'
							options={standingsTeamsToOptions(teamsOptions)}
							value={standingsTeamsToOptions(value)}
							isMulti={true}
							placeholder={t('select')}
							formatOptionLabel={customOption}
							noOptionsMessage={(inputValue) => inputValue && t('no_options')}
							onChange={(selections: any) => onTeamsSelect(selectionsToTeams(selections))}
						/>
					</>
				) : (
					<>
						<Label>{t('pick_team')}:</Label>
						<Select
							inputId='standings-team-select'
							options={standingsTeamsToOptions(teamsOptions)}
							value={value && value.id ? standingsTeamToOption(value) : []}
							isMulti={false}
							placeholder={t('select')}
							formatOptionLabel={customOption}
							isClearable={true}
							noOptionsMessage={(inputValue) => inputValue && t('no_options')}
							onChange={(selection: any) => onTeamsSelect(selection && selection.data)}
						/>
						<FormFeedback>
							{t('field_is_required')}
							{standings.offset && standings.offset.length > 0 && standings.team && !standings.team.id}
						</FormFeedback>
					</>
				)}
			</>
		);
	}
}
