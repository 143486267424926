import * as React from 'react';
import VideoPlayModal from '../video-play/video-play.component';
import VideoRow from './video-row';
import OnnetworkVideoModel from '../../../../../../../models/onnetwork-videos/onnetwork-video.model';
import { copyToClipboard } from '../../dugout-video-block/video-cells/helpers/video-cells.helper';

type Properties = {
	videos: OnnetworkVideoModel[];
	analytics?: any;
	t: any;
	toggleLoadingState: boolean;
	onSelect: (video: OnnetworkVideoModel) => void;
	selectedVideo: OnnetworkVideoModel;
	isMediaSidebar: boolean;
};

export type State = {
	isOpenModal: boolean;
	videoToPlay: OnnetworkVideoModel | null;
};
export default class VideoContainer extends React.Component<Properties, State> {
	constructor(props: any) {
		super(props);
		this.state = {
			isOpenModal: false,
			videoToPlay: null,
		};
	}

	copyId = (e: any, id: string) => {
		copyToClipboard(e, id.replace(/\"/g, ''), this.props.t, 'id');
	};

	copyEmbed = (e: any, embedCode: string) => {
		copyToClipboard(e, embedCode, this.props.t, 'embedCode');
	};

	playVideo = (open: boolean, video: OnnetworkVideoModel) => {
		this.setState({ ...this.state, isOpenModal: open, videoToPlay: video });
	};

	onClose = (open: boolean) => {
		this.setState({ ...this.state, isOpenModal: open, videoToPlay: null });
	};

	render() {
		const { videos, t, onSelect, selectedVideo, isMediaSidebar } = this.props;
		const { isOpenModal, videoToPlay } = this.state;

		return (
			<div className='pl-2'>
				{videos &&
					videos.length > 0 &&
					videos.map((video: OnnetworkVideoModel, index: number) => {
						return (
							<VideoRow
								key={`${video.id + Math.random()}`}
								t={t}
								video={video}
								isMediaSidebar={isMediaSidebar}
								onSelect={onSelect}
								copyEmbed={this.copyEmbed}
								playVideo={this.playVideo}
								copyId={this.copyId}
								index={index}
								selectedVideo={selectedVideo}
							/>
						);
					})}
				{videoToPlay && <VideoPlayModal t={t} onClose={this.onClose} video={videoToPlay} open={isOpenModal} />}
			</div>
		);
	}
}
