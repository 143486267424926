import React from 'react';
import { Col, Row } from 'reactstrap';
import FansUnitedWidgetModel from '../../models/fans-united-widget.model';
import moment from 'moment';
import BookmakerModel from '../../../../../partials/bookmaker-select/models/bookmaker.model';
import { imagePlaceHolder } from '../../../../../../../../constants/resource-constants';

type Properties = {
	widgetDoubleChance: FansUnitedWidgetModel;
	t: any;
	bookmakers: [];
};

export const WidgetDoubleChanceView: React.FunctionComponent<Properties> = ({ widgetDoubleChance, t, bookmakers }) => {
	if (widgetDoubleChance && widgetDoubleChance.match && widgetDoubleChance.match.id) {
		return (
			<>
				<Row className='d-flex p-2 text-center align-items-center mb-1'>
					<Col>
						<strong>{moment(widgetDoubleChance.match.startTime ? widgetDoubleChance.match.startTime : '').format('MMM Do YYYY, HH:mm')}</strong>
					</Col>
					<Col className='text-right mr-1'>
						<strong className='mr-2'>
							{widgetDoubleChance.match && widgetDoubleChance.match.homeTeam && widgetDoubleChance.match.homeTeam.name}
						</strong>
						<img
							width='40'
							src={widgetDoubleChance.match && widgetDoubleChance.match.homeTeam && widgetDoubleChance.match.homeTeam.logo}
							alt={widgetDoubleChance.match && widgetDoubleChance.match.homeTeam && widgetDoubleChance.match.homeTeam.name}
						/>
					</Col>
					<strong className='text-nowrap'>:</strong>

					<Col className='text-left'>
						<img
							width='40'
							src={widgetDoubleChance.match && widgetDoubleChance.match.awayTeam && widgetDoubleChance.match.awayTeam.logo}
							alt={widgetDoubleChance.match && widgetDoubleChance.match.awayTeam && widgetDoubleChance.match.awayTeam.logo}
						/>
						<strong className='ml-2'>
							{widgetDoubleChance.match && widgetDoubleChance.match.awayTeam && widgetDoubleChance.match.awayTeam.name}
						</strong>
					</Col>
				</Row>
				<Row className='px-4'>
					<Col>
						<hr />
					</Col>
				</Row>
				{bookmakers && bookmakers.length > 0 && widgetDoubleChance.displayOdds && (
					<Row className='mb-2 px-4 pt-1'>
						<Col>
							{t('show')}:
							{bookmakers.map((bookmaker: BookmakerModel) => {
								return (
									<img
										key={bookmaker.id}
										height='20'
										alt={bookmaker.name}
										className='h-20 mx-1'
										src={bookmaker.logo ? bookmaker.logo : imagePlaceHolder}
										title={bookmaker.name}
									/>
								);
							})}
						</Col>
					</Row>
				)}
				<Row className='px-4 pt-1'>
					{widgetDoubleChance.market && widgetDoubleChance.market.value && widgetDoubleChance.market.value.length > 0 && (
						<Col className='text-left'>
							<span>{t('market')}: </span>
							<strong>{t(widgetDoubleChance.market.value)}</strong>
						</Col>
					)}
				</Row>
				<Row className='px-4 pt-1'>
					<Col className='text-left'>
						{t('display_odds')}:
						<strong>
							<span className={widgetDoubleChance.displayOdds ? 'text-success' : 'text-danger'}>
								{widgetDoubleChance.displayOdds ? ` ${t('yes')}` : ` ${t('no')}`}
							</span>
						</strong>
					</Col>
				</Row>
			</>
		);
	}

	return null;
};
