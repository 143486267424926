import React from 'react';
import HttpService from '../../../../../services/rest/HttpService';
import OddClientBookmakersModel from './models/odd-client-bookmakers.model';
import { OddClientBookmakersView } from './odd-client-bookmakers-view.component';
import { Label } from 'reactstrap';

type Properties = {
	value: OddClientBookmakersModel | OddClientBookmakersModel[] | null;
	isMulti: boolean;
	label?: string;
	isClearable?: boolean;
	onChange: (arg: OddClientBookmakersModel | OddClientBookmakersModel[] | null) => void;
};

type State = {
	bookmakers: OddClientBookmakersModel[];
};

export default class OddClientBookmakers extends React.Component<Properties, State> {
	constructor(props: Properties) {
		super(props);

		this.state = {
			bookmakers: [],
		};
	}

	componentDidMount(): void {
		this.requestBookmakers();
	}

	private requestBookmakers = () => {
		HttpService.getAllBookmakers()
			.then((response: any) => {
				const bookmakers = this.responseToBookmakersList(response);
				this.setState({ bookmakers });
			})
			.catch((e: any) => {
				this.setState({ bookmakers: [] });
			});
	};

	private responseToBookmakersList = (response: any): OddClientBookmakersModel[] => {
		if (response && response.data && response.data.length > 0) {
			return response.data.map((bookmaker: any) => {
				const model = OddClientBookmakersModel.builder()
					.withId(bookmaker.id)
					.withLogo(bookmaker.url_logo)
					.withName(bookmaker.name)
					.withUrl(bookmaker.url)
					.build();

				return { value: model.id, logo: model.logo, label: model.name, data: model, type: 'bookmaker' };
			});
		} else {
			return [];
		}
	};

	private selectionListToModelList = (value: any): OddClientBookmakersModel[] => {
		return value.map((selection: any) => this.selectionToModel(selection));
	};

	private selectionToModel = (selection: any): OddClientBookmakersModel => {
		return OddClientBookmakersModel.builder(selection.data).build();
	};

	private modelListToSelectionList = (model: OddClientBookmakersModel[]) => {
		return model.map((value: OddClientBookmakersModel) => this.modelToSelection(value));
	};

	private modelToSelection = (model: OddClientBookmakersModel) => {
		return { value: model.id, label: model.name, logo: model.logo, data: model, type: 'bookmaker' };
	};

	private constructSelectValue = (value: OddClientBookmakersModel | OddClientBookmakersModel[] | null) => {
		if (Array.isArray(value)) {
			return this.modelListToSelectionList(value);
		} else if (value) {
			return this.modelToSelection(value);
		} else {
			return null;
		}
	};

	private onChange = (selection: any) => {
		const { onChange } = this.props;

		if (Array.isArray(selection)) {
			onChange(this.selectionListToModelList(selection));
		} else if (selection) {
			onChange(this.selectionToModel(selection));
		} else {
			onChange(null);
		}
	};

	render() {
		const { value, isMulti, isClearable, label } = this.props;
		const { bookmakers } = this.state;

		return (
			<>
				{label && label.length > 0 && (
					<div>
						<Label>{label}</Label>
					</div>
				)}
				<div>
					<OddClientBookmakersView
						isClearable={isClearable}
						onChange={this.onChange}
						isMulti={isMulti}
						value={this.constructSelectValue(value)}
						bookmakers={bookmakers}
					/>
				</div>
			</>
		);
	}
}
