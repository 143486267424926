import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Col, Label, Row } from 'reactstrap';
import HttpService from '../../../../../../services/rest/HttpService';
import GroupModel from '../../../../../../models/v2/Group/entity/group.model';
import { responseToGroupStageModel, stageGroupsToOptions } from '../helpers/group-stage-select/group-stage-select.helper';
import { customOption } from '../../../partials/shared/custom-select-option';

type Properties = {
	stageId: any;
	onStageGroupSelect: any;
	selectedStageGroups: any;
	isMulti: boolean;
	withLabel?: boolean;
	t: any;
	label?: string;
	className?: string;
	isClearable?: boolean;
	id: string;
};

const StageGroupSelectComponent: React.FunctionComponent<Properties> = (props) => {
	const [stageGroupsOptions, setStageGroupsOptions] = useState<GroupModel[]>([]);

	useEffect(() => {
		if (props.stageId) {
			getStageGroups(props.stageId);
		}
	}, []);

	useEffect(() => {
		if (props.stageId) {
			getStageGroups(props.stageId);
		}

		if (!props.stageId) {
			setStageGroupsOptions([]);
		}
	}, [props.stageId]);

	const updateStagesOptionsState = (groups: GroupModel[]) => {
		setStageGroupsOptions(groups);
	};

	const getStageGroups = (stageId: any) => {
		if (stageId) {
			HttpService.getStageById(stageId)
				.then((response: any) => {
					updateStagesOptionsState(response.data.groups.map((item: GroupModel) => responseToGroupStageModel(item)));
				})
				.catch((e: any) => e);
		}
	};

	const onSelect = (selections: any) => {
		const { isMulti, onStageGroupSelect } = props;
		if (selections) {
			onStageGroupSelect(isMulti ? selections.map((selection: any) => selection.data) : selections.data);
		} else {
			onStageGroupSelect(isMulti ? [] : null);
		}
	};

	const { selectedStageGroups, stageId, t, label, withLabel, isMulti, className } = props;

	const validStageProvided =
		stageId === undefined || stageId === null || stageId.length < 1 || (stageGroupsOptions && stageGroupsOptions.length < 1);

	if (validStageProvided) {
		return null;
	}

	return (
		<Row data-qa={props.id}>
			<Col>
				{withLabel && <Label htmlFor={props.id}>{t('stage_groups')}:</Label>}
				{label && <Label htmlFor={props.id}>{t(label)}</Label>}
				<Select
					id={props.id}
					className={className ? className : ''}
					isMulti={isMulti}
					formatOptionLabel={customOption}
					isClearable={props.isClearable}
					options={stageGroupsToOptions(stageGroupsOptions)}
					value={stageGroupsToOptions(selectedStageGroups)}
					onChange={onSelect}
					styles={{
						menuPortal: (baseStyles) => ({
							...baseStyles,
							zIndex: 10000,
						}),
					}}
				/>
			</Col>
		</Row>
	);
};

export default StageGroupSelectComponent as React.ComponentType<Properties>;
