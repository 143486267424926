import React, { useState } from 'react';
import './styles/style.scss';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../../../store/store';
import AdvancedFilterModel from '../../../../../Partials/Shared/advanced-content-filter/models/advanced-content-filter.model';
import {
	checkIfTheFiltersAreEmpty,
	constructSearchContentQuery,
} from '../../../../../Partials/Shared/advanced-content-filter/helpers/advanced-content-filter.helper';
import { Button, Col, FormGroup, Label, Row } from 'reactstrap';
import ConditionalRenderWrapper from '../../../../../Partials/BaseComponents/ConditionalRenderWraper/ConditionalRenderWrapper';
import AdvancedFiltersFields from '../../../../../Partials/Shared/advanced-content-filter/advanced-filters-fields.component';
import { ContentTypes } from '../../../../../../constants/content-types';
import DateTimePickerContainer from '../../../../../Partials/Sidebar/GeneralContentAttributes/subcomponents/DateTimePicker/DateTimePickerContainer';
import { Subject } from 'rxjs';
import { DATA_QA_ATTRIBUTES } from '../../../../../Partials/Sidebar/general-content/constants/data-qa.attributes';

type Properties = {
	t: any;
	searchContent: any;
	updateAdvancedSearchText: any;
	getAllContent: any;
	contentSearchInput: string;
	filtersInSidebar: boolean;
};

export const LiveBlogAdvancedFiltersComponent: React.FunctionComponent<Properties> = ({
	t,
	searchContent,
	updateAdvancedSearchText,
	getAllContent,
	contentSearchInput,
	filtersInSidebar,
}) => {
	const clearService = new Subject<void>();

	const imageOrigins = useSelector((state: any) => state.origins.imageOrigins);
	const allCategories = useSelector((state: AppState) => state.category.allCategories);
	const profileId = useSelector((state: AppState) => state.profile.profile.id);
	const project = useSelector((state: AppState) => state.project.currentProject);
	const statuses = useSelector((state: AppState) => state.statuses);
	const sports = useSelector((state: AppState) => state.sports.sportsTypes);

	const [advancedContentModel, setAdvancedContentModel] = useState<AdvancedFilterModel>({} as AdvancedFilterModel);

	const onDateSelect = (dates: string[]) => {
		const advancedContent = AdvancedFilterModel.builder(advancedContentModel).withDate(dates).build();
		updateAdvancedInputText(advancedContent);
	};

	const updateAdvancedInputText = (advancedContentModel: AdvancedFilterModel) => {
		const advancedContentSearchText = constructSearchContentQuery(advancedContentModel);

		updateAdvancedSearchText(advancedContentSearchText);
		setAdvancedContentModel(advancedContentModel);
		if (contentSearchInput.match(/^ *$/) !== null && advancedContentSearchText.match(/^ *$/) !== null && !advancedContentModel.sportsType) {
			getAllContent();
		}
	};

	const clearAdvancedFilters = () => {
		clearService.next();
		updateAdvancedInputText(AdvancedFilterModel.builder().build());
	};

	const showClearFiltersButton = checkIfTheFiltersAreEmpty(advancedContentModel);

	return (
		<>
			<ConditionalRenderWrapper expectedPermissions={['read_admins']}>
				<Row>
					<AdvancedFiltersFields
						t={t}
						profileId={profileId}
						filtersInSidebar={filtersInSidebar}
						updateAdvancedSearchText={updateAdvancedInputText}
						currentProject={project}
						advancedContentModel={advancedContentModel}
						allCategories={allCategories}
						showOriginSelect={true}
						imageOrigins={imageOrigins}
						videoOrigins={[]}
						calledFrom={'live-blog-image-listing'}
						contentType={ContentTypes.IMAGE}
						articleTypes={[]}
						showTypeSelect={false}
						statuses={statuses}
						sports={sports}
					/>
					<Col col='6' sm='12' md={`${filtersInSidebar ? '0' : '4'}`} className={`${filtersInSidebar ? 'mb-2' : 'mb-0'}`}>
						<FormGroup>
							<Label htmlFor={DATA_QA_ATTRIBUTES.LIVE_BLOG_ADVANCED_SEARCH_DATE}>{t('date_from_to')}</Label>
							<DateTimePickerContainer
								id={DATA_QA_ATTRIBUTES.LIVE_BLOG_ADVANCED_SEARCH_DATE}
								date={advancedContentModel.date}
								dateFormat='Y.m.d'
								resetButton
								clearService={clearService}
								t={t}
								mode='range'
								enableTime={false}
								onDateChange={onDateSelect}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row className='mt-3 mb-3'>
					<Col>
						<Button type='button' color='primary' id='live-blog-advanced-filters-search-button' onClick={searchContent}>
							<i className={'fa fa-search'}>&nbsp;</i>
							{t('search')}
						</Button>
						<Button
							id='live-blog-advanced-filters-clear-button'
							type='button'
							color='white'
							disabled={showClearFiltersButton}
							className='btn btn-outline-danger ld-ext-right px-2 ml-2'
							onClick={clearAdvancedFilters}
						>
							{t('clear_filters')}
						</Button>
					</Col>
				</Row>
			</ConditionalRenderWrapper>
		</>
	);
};
