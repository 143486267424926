import * as React from 'react';
import { Properties, State } from '../image-listing/properties/ImagoImageListingProps';
import { Button, Input, InputGroup, InputGroupAddon, Label } from 'reactstrap';
import ImageTypeSelect from '../../../../../../Blocky/partials/imago-image-select/image-type-select/image-type-select.component';
import { remapImagoImagesFromResponse } from '../../../../../../Blocky/partials/imago-image-select/helpers/imago-image-select.helper';
import ImagoHttpService from '../../../../../../../../services/rest/imago/ImagoHttpService';
import ImagoImageModel from '../../../../../../Blocky/blocks/content/imago-image-block/models/imago-image.model';

export default class ImageListing extends React.Component<Properties, State> {
	httpImago = new ImagoHttpService();
	constructor(props: Properties) {
		super(props);
		this.state = {
			contentSearchInput: props.searchText && props.searchText.length > 0 ? props.searchText : '',
			selectedImageType: props.selectedImageType ? props.selectedImageType : 'sport',
			isOpenDropdown: false,
		};
	}

	componentDidMount() {
		const { searchText, pageOffset } = this.props;

		if (searchText && searchText.length > 0) {
			this.searchImagoImages(searchText, pageOffset);
		}
	}

	componentDidUpdate(prevProps: Readonly<Properties>) {
		const { contentSearchInput } = this.state;
		const { shouldRequest } = this.props;

		if (shouldRequest && prevProps.shouldRequest !== shouldRequest && contentSearchInput.length > 0) {
			this.searchImagoImages(contentSearchInput.trim(), this.props.pageOffset);
		}
	}

	onContentSearchInput = (event: any) => {
		let searchText = event.target.value;
		this.onImagoImagesInputChangeState(event.target.value);

		const { selectedImageType } = this.state;
		const { pageOffset } = this.props;

		this.setState({
			contentSearchInput: event.target.value,
		});

		if (searchText.length < 1) {
			this.props.onImageSearch([], pageOffset, searchText, selectedImageType);
			this.props.onSelectedImagoImageChange(ImagoImageModel.builder().build());
		}
	};

	onContentSearch = (event: any) => {
		if (event.keyCode === 13 && this.state.contentSearchInput.trim().length > 0) {
			this.searchImagoImages(this.state.contentSearchInput, 0);
		}
	};

	clearSearch = () => {
		this.onImagoImagesInputChangeState('');

		this.props.onImageSearch(null, 0, '', '');
		this.props.onSelectedImagoImageChange(ImagoImageModel.builder().build());
	};

	searchImagoImages = (search: string, pageOffset: number) => {
		this.props.toggleLoadingState(true);
		const { selectedImageType } = this.state;

		this.httpImago
			.getImagoImages(search, pageOffset, selectedImageType)
			.then((response: any) => {
				this.props.toggleLoadingState(false);
				this.props.onImageSearch(remapImagoImagesFromResponse(response.data), pageOffset, search, selectedImageType);
			})
			.catch(this.props.toggleLoadingState(true));
	};

	toggleImagesTypeMenu = () => {
		this.setState((state: State) => {
			return { ...state, isOpenDropdown: !this.state.isOpenDropdown };
		});
	};

	changeSelectedImageType = (type: string) => {
		this.setState({ ...this.state, selectedImageType: type });
	};

	onImagoImagesInputChangeState = (input: string) => {
		this.setState({ ...this.state, contentSearchInput: input });

		return input;
	};

	getAllContent = () => {
		this.props.onImageSearch('', 0, '', 'both');
	};

	render() {
		const { t, contentType } = this.props;
		const { contentSearchInput, isOpenDropdown, selectedImageType } = this.state;

		return (
			<>
				<Label>{this.props.t('choose_image')}:</Label>
				<InputGroup className='position-relative d-flex flex-direction-row flex-nowrap'>
					<ImageTypeSelect
						t={t}
						changeSelectedImageType={this.changeSelectedImageType}
						isOpenDropdown={isOpenDropdown}
						toggleImagesTypeMenu={this.toggleImagesTypeMenu}
						selectedType={selectedImageType}
					/>
					<InputGroup className='position-relative d-flex'>
						<Input
							type='text'
							id={`search-input-${contentType ? contentType : ''}`}
							name='search-input'
							className='rounded-0 pr-4'
							placeholder={t('content_search_placeholder')}
							onChange={this.onContentSearchInput}
							onKeyDown={this.onContentSearch}
							value={contentSearchInput}
						/>
						{contentSearchInput && contentSearchInput.length > 0 && (
							<div onClick={this.clearSearch} className='p-2 text-muted position-absolute' style={{ top: 0, right: 0 }}>
								<i className='fa fa-times' />
							</div>
						)}
					</InputGroup>
					<InputGroupAddon addonType='append'>
						<Button
							id='search-imago-images'
							type='button'
							color='primary'
							disabled={contentSearchInput.trim().length === 0}
							onClick={() => this.searchImagoImages(contentSearchInput, 0)}
						>
							<i className={'fa fa-search'}>&nbsp;</i>
							{t('search')}
						</Button>
					</InputGroupAddon>
				</InputGroup>
			</>
		);
	}
}
