import debounce from 'lodash.debounce';
import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import AsyncSelect from 'react-select/async';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import Related from '../../../../../../models/related/Related';
import SportsEntityModel from '../../../../../../models/v2/sports-types/sports-entity.model';
import { store } from '../../../../../../store/store';
import SportsConnectionsHttpService from '../../../../Shared/advanced-content-filter/subcomponents/v2/services/sports-connections-http.service';
import { DATA_QA_ATTRIBUTES } from '../../constants/data-qa';
import { entityTypesWithoutSeason } from '../../helpers/football-connection-select.helper';
import {
	remapReduxSportsConnectionPropertyToModels,
	remapSportConnectionAsRelatedModel,
	remapSportsConnectionsResponseToConnectionsModel,
	sportConnectionsToOptions,
} from '../../helpers/sports-connection-select.helper';
import {
	extractActionForUpdatingSportsConnections,
	extractRelatedPropertiesNameByUrl,
	filterReduxRelatedProperties,
	generateCustomOptionWithImage,
} from '../../helpers/utils';
import { sportsConnectionColorStyles } from '../../constants/styles';
import SportsTypesModel from '../../../../../../models/v2/sports-types/sports-types.model';
import { excludeEntityTypes } from '../../../../Shared/advanced-content-filter/subcomponents/v2/sports-select/sports-connections.helper';
import EntityTypesModel from '../../../../../../models/v2/sports-types/entity-types.model';
import { multiLingualService } from '../../../../../../App';

type Properties = {
	t: any;
	reduxSportConnections: Related[];
	updateSportsConnection: Function;
	selectedSport: SportsTypesModel;
	allowMoreEntityTypes: boolean;
};

const SportsConnectionsSelect: FunctionComponent<Properties> = ({
	t,
	reduxSportConnections,
	updateSportsConnection,
	selectedSport,
	allowMoreEntityTypes,
}) => {
	const filteredData = filterReduxRelatedProperties(selectedSport, reduxSportConnections, entityTypesWithoutSeason);
	const dataToModels = remapReduxSportsConnectionPropertyToModels(filteredData && filteredData.filtered);
	const currentProject = store.getState().project.currentProject;
	const language =
		multiLingualService.checkIfProjectIsMultiLingual(currentProject.languages) && multiLingualService.checkRoutePath()
			? multiLingualService.setCorrectAcceptLanguageCode(currentProject.languages.defaultLanguageCode.languageCode)
			: currentProject.language && currentProject.language.split('-')[0];

	const modifyConnections = (sportsConnectionSelection: any) => {
		let dataForRedux = [];
		if (sportsConnectionSelection && sportsConnectionSelection.length > 0) {
			const selectedSportDataForRedux = sportsConnectionSelection.map(({ data }: any) =>
				remapSportConnectionAsRelatedModel(data as SportsEntityModel, selectedSport),
			);
			dataForRedux =
				filteredData && filteredData.rest.length > 0 ? [...selectedSportDataForRedux, ...filteredData.rest] : selectedSportDataForRedux;
		} else {
			dataForRedux = filteredData && filteredData.rest.length > 0 ? [...filteredData.rest] : [];
		}
		updateSportsConnection(dataForRedux);
	};

	const loadConnectionsOnType = (input: string, callback: any) => {
		let availableSportsEntities: string[] = [];
		let entities = excludeEntityTypes(selectedSport.entity_types, allowMoreEntityTypes);
		entities &&
			entities.map((entity: EntityTypesModel) => {
				return availableSportsEntities.push(entity.name);
			});

		if (input.length > 2) {
			SportsConnectionsHttpService.getSportsData(input, selectedSport.sport, availableSportsEntities, language).then((response: any) => {
				const fetchedSportsData: Array<any> = response.data.results;
				const remappedSportsData = remapSportsConnectionsResponseToConnectionsModel(fetchedSportsData);
				const loadedSportsConnectionsAsOptions = sportConnectionsToOptions(remappedSportsData);
				callback(loadedSportsConnectionsAsOptions);
			});
		}
	};

	return (
		<Row>
			<Col>
				<FormGroup>
					<Label htmlFor={DATA_QA_ATTRIBUTES.SPORT_CONNECTION_SELECT}>{t('sports_connections')}</Label>
					<AsyncSelect
						id={DATA_QA_ATTRIBUTES.SPORT_CONNECTION_SELECT}
						styles={sportsConnectionColorStyles}
						isMulti={true}
						isClearable={true}
						placeholder={t('select')}
						noOptionsMessage={(inputValue) => inputValue && t('no_options')}
						loadOptions={debounce(loadConnectionsOnType, 300)}
						onChange={modifyConnections}
						value={sportConnectionsToOptions(dataToModels)}
						formatOptionLabel={(option) =>
							generateCustomOptionWithImage(option.label, option.value, option.data.display_asset.url, option.data.entity_type)
						}
					/>
				</FormGroup>
			</Col>
		</Row>
	);
};

function mapStateToProps(state: any) {
	const nestedReduxPropertyName = extractRelatedPropertiesNameByUrl();

	return {
		reduxSportConnections: (nestedReduxPropertyName && state.tempSportsRelated[nestedReduxPropertyName]) || [],
	};
}

function mapDispatchToProps(dispatch: any) {
	const updateFootballConnectionRelatedAction = extractActionForUpdatingSportsConnections();

	return {
		updateSportsConnection: (rel: Related[]) => updateFootballConnectionRelatedAction && dispatch(updateFootballConnectionRelatedAction(rel)),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(SportsConnectionsSelect);
