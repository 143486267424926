import { TypedJSON } from 'typedjson';
import ResponseMatchModel from './response-match.model';

export const modelMatchToResponseModel = (response: any): ResponseMatchModel => {
	const serializer = new TypedJSON(ResponseMatchModel);
	const result = serializer.parse(response);

	if (result) {
		return result;
	}

	return new ResponseMatchModel();
};
