import React from 'react';
import SpotlightContainer from './spotlight-audio-container.component';
import { blockManagementService, blockWrapperService } from '../../../../subcomponents/blocky.component';
import BlockValidation from '../../../../helpers/block-validation.helper';
import SpotlightAudioModel from '../../../../../../../models/spotlight-audio/spotlight-audio.model';
import BlockModel from '../../../../models/block.model';
import { ContentTypes } from '../../../../constants/block.types';

export type Properties = {
	block: BlockModel;
	t: any;
	blocks: BlockModel[];
};

export type State = {
	selectedAudio: SpotlightAudioModel;
	isValid: boolean;
};

class SpotlightAudioEdit extends React.Component<Properties, State> {
	constructor(props: Properties) {
		super(props);
		this.state = {
			selectedAudio:
				props.block && props.block.data && props.block.data.id ? props.block.data.preview.audio : SpotlightAudioModel.builder().build(),
			isValid: true,
		};
	}

	componentDidMount(): void {
		blockWrapperService.registerOnSaveFunc({ blockId: this.props.block.id, func: this.onSave });
	}

	onSave = (index: number) => {
		const { selectedAudio } = this.state;
		const data = { spotlightAudio: selectedAudio };
		const validation = new BlockValidation();
		const isValid = validation.validate(this.props.block, data);
		this.setState({ ...this.state, isValid });

		if (isValid) {
			const block = Object.assign({}, this.props.block);
			block.data.id = selectedAudio.matchId;
			block.data.config = selectedAudio.config;
			block.data.preview = { audio: selectedAudio, type: ContentTypes.spotlightAudio };
			blockManagementService.blockUpdate(block, index);
		} else {
			return isValid;
		}
	};

	selectAudio = (audio: SpotlightAudioModel) => {
		this.setState({ ...this.state, selectedAudio: audio });
	};

	componentWillUnmount(): void {
		blockWrapperService.unregisterOnSaveFunc(this.props.block.id);
	}

	render() {
		return (
			<SpotlightContainer
				onSelect={this.selectAudio}
				selectedAudio={this.state.selectedAudio}
				isValid={this.state.isValid}
				inBlocky={true}
				{...this.props}
			/>
		);
	}
}

export default SpotlightAudioEdit;
