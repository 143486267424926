import { UrlsJson } from './UrlsJson';
import Urls from './Urls';
import Seo from '../seo/Seo';
import { SeoJson } from '../seo/SeoJson';
import VideoUrls from './video-urls/video-urls';

export default class UrlsBuilder {
	private json: UrlsJson;

	constructor(urls?: Urls | UrlsJson) {
		if (urls && urls instanceof Urls) {
			this.json = urls.toJSON();
		} else if (urls) {
			this.json = urls;
		} else {
			this.json = {} as UrlsJson;
		}
	}

	withExternalUrl(externalUrl: string): UrlsBuilder {
		this.json.externalUrl = externalUrl;

		return this;
	}

	withCanonicalUrl(canonicalUrl: string): UrlsBuilder {
		this.json.canonicalUrl = canonicalUrl;

		return this;
	}

	withPublicUrlDesktop(publicUrlDesktop: string): UrlsBuilder {
		this.json.publicUrlDesktop = publicUrlDesktop;

		return this;
	}

	withPublicUrlMobile(publicUrlMobile: string): UrlsBuilder {
		this.json.publicUrlMobile = publicUrlMobile;

		return this;
	}

	withPublicUrlAmp(publicUrlAmp: string): UrlsBuilder {
		this.json.publicUrlAmp = publicUrlAmp;

		return this;
	}

	withVideoUrls(videoUrls: VideoUrls[]): UrlsBuilder {
		this.json.videoUrls = videoUrls;

		return this;
	}

	isEmpty(): boolean {
		if (this.json) {
			const isExternalUrlSet = this.json.externalUrl && this.json.externalUrl.length > 0;
			const isCanonicalUrlSet = this.json.canonicalUrl && this.json.canonicalUrl.length > 0;

			return !(isExternalUrlSet || isCanonicalUrlSet);
		}

		return true;
	}

	build(): Urls {
		return Urls.fromJSON(this.json);
	}
}
