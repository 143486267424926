import * as React from 'react';
import { useEffect, useState } from 'react';
import FootballConnectionsContainer from '../../Sidebar/tags/subcomponents/football-connections/football-connections-container';
import { Col, FormGroup, Label } from 'reactstrap';
import ContentTagsContainer from '../../Sidebar/tags/subcomponents/content-tags/content-tags-container';
import { ConditionalRenderContext } from '../../BaseComponents/ConditionalRenderWraper/ConditionalRenderWrapper';
import Project from '../../../../models/project/Project';
import { featuresService, sportsConnectionsService } from '../../../../App';
import SportsTypesModel from '../../../../models/v2/sports-types/sports-types.model';
import { FeatureTypes } from '../../../../services/feature-service/features.enum';
import TypesMultiSelect from '../../Sidebar/GeneralContentAttributes/subcomponents/type-select/TypeSelectMultiContainer';
import WikiPagesAdvancedFilterModel from './models/wiki-pages-advanced-filter.model';
import UserModel from './models/user.model';
import RelatedModel from './models/related.model';
import ContentAttributesModel from './models/content-attributes.model';
import UserSelectContainer from '../advanced-content-filter/subcomponents/user-select/user-select-container';
import { SportsTypeSelect } from '../advanced-content-filter/subcomponents/v2/sports-select/sports-type-select.component';
import { SportsConnectionsContainer } from '../advanced-content-filter/subcomponents/v2/sports-select/sports-connections-container';
import TournamentSelect from '../advanced-content-filter/subcomponents/tournament-select/tournament-select';
import { CompetitionSelect } from '../advanced-content-filter/subcomponents/v2/sports-select/competition-select';
import SeasonSelect from './subcomponents/season-select.container';
import { generateFiltersMdClass } from './helpers/wiki-pages-advanced-content-filter.helper';
import { checkObjectPropertiesExists } from '../../../../global-helpers/global.helpers';
import StatusSelect from '../../Sidebar/GeneralContentAttributes/subcomponents/status-select/status-select';
import ContentAttributes from '../../../../models/content-attributes/content-attributes-model';
import { ContentTypes } from '../../../../constants/content-types';

type Properties = {
	currentProject: Project;
	t: any;
	updateWikiPagesAdvancedSearchText: (wikiPageAdvancedFilterModel: WikiPagesAdvancedFilterModel) => any;
	profileId: string;
	filtersInSidebar: boolean;
	calledFrom?: string;
	wikiPageAdvancedFilterModel: any;
	wikipageTypes: ContentAttributesModel[];
	contentLanguage: string;
	statuses: ContentAttributes[];
	sports: SportsTypesModel[];
};

const WikiPagesAdvancedFiltersFields: React.FunctionComponent<Properties> = (props) => {
	const [sportsType, setSportsType] = useState<SportsTypesModel>(
		props.wikiPageAdvancedFilterModel.sportsType && props.wikiPageAdvancedFilterModel.sportsType.sport
			? props.wikiPageAdvancedFilterModel.sportsType
			: {},
	);

	useEffect(() => {
		setSportsType(
			props.wikiPageAdvancedFilterModel.sportsType && props.wikiPageAdvancedFilterModel.sportsType.sport
				? props.wikiPageAdvancedFilterModel.sportsType
				: new SportsTypesModel(),
		);
	}, [props.wikiPageAdvancedFilterModel]);

	const onSportsDataSelect = (value: RelatedModel[]) => {
		const advancedContentModel = WikiPagesAdvancedFilterModel.builder(props.wikiPageAdvancedFilterModel).withRelatedSports(value).build();
		props.updateWikiPagesAdvancedSearchText(advancedContentModel);
	};

	const onSportsTypeSelect = (type: any) => {
		setSportsType(type);
		const advancedContentModel = WikiPagesAdvancedFilterModel.builder(props.wikiPageAdvancedFilterModel)
			.withSportsType(type)
			.withRelatedSports([])
			.withTournament({} as RelatedModel)
			.withSeason({} as RelatedModel)
			.build();
		props.updateWikiPagesAdvancedSearchText(advancedContentModel);
	};

	const onTournamentDataSelect = (value: RelatedModel) => {
		const advancedContentModel = WikiPagesAdvancedFilterModel.builder(props.wikiPageAdvancedFilterModel)
			.withTournament(value)
			.withSeason({} as RelatedModel)
			.build();
		props.updateWikiPagesAdvancedSearchText(advancedContentModel);
	};

	const onSeasonSelect = (value: RelatedModel) => {
		const advancedContentModel = WikiPagesAdvancedFilterModel.builder(props.wikiPageAdvancedFilterModel).withSeason(value).build();
		props.updateWikiPagesAdvancedSearchText(advancedContentModel);
	};

	const onTagsDataSelect = (tags: RelatedModel[]) => {
		const advancedContentModel = WikiPagesAdvancedFilterModel.builder(props.wikiPageAdvancedFilterModel).withRelatedTags(tags).build();
		props.updateWikiPagesAdvancedSearchText(advancedContentModel);
	};

	const onUserChange = (user: UserModel) => {
		const advancedContentModel = WikiPagesAdvancedFilterModel.builder(props.wikiPageAdvancedFilterModel).withCreatedBy(user).build();
		props.updateWikiPagesAdvancedSearchText(advancedContentModel);
	};

	const onTypeChange = (type: ContentAttributesModel[]) => {
		const advancedContentModel = WikiPagesAdvancedFilterModel.builder(props.wikiPageAdvancedFilterModel).withType(type).build();
		props.updateWikiPagesAdvancedSearchText(advancedContentModel);
	};

	const onStatusSelect = (status: string) => {
		const advancedContentModel = WikiPagesAdvancedFilterModel.builder(props.wikiPageAdvancedFilterModel).withStatus(status).build();
		props.updateWikiPagesAdvancedSearchText(advancedContentModel);
	};

	const {
		currentProject,
		t,
		filtersInSidebar,
		wikipageTypes,
		wikiPageAdvancedFilterModel,
		calledFrom,
		contentLanguage,
		statuses,
		sports,
	} = props;

	return (
		<>
			{!filtersInSidebar && (
				<Col col='6' sm='12' md={generateFiltersMdClass(filtersInSidebar)}>
					<FormGroup>
						<Label htmlFor='tags'>{t('tags')}</Label>
						<ContentTagsContainer
							related={wikiPageAdvancedFilterModel.relatedTags}
							onSelect={onTagsDataSelect}
							currentProject={currentProject}
							t={t}
						/>
					</FormGroup>
				</Col>
			)}
			{wikipageTypes && wikipageTypes.length > 0 && (
				<Col col='6' sm='12' md={generateFiltersMdClass(filtersInSidebar)}>
					<FormGroup key={'formgroup-types-key'}>
						<Label>{t('type')}</Label>
						<TypesMultiSelect
							t={t}
							isClearable
							onTypeSelect={onTypeChange}
							types={wikipageTypes}
							selectedTypes={wikiPageAdvancedFilterModel.type}
						/>
					</FormGroup>
				</Col>
			)}
			<ConditionalRenderContext.Consumer>
				{(value) => {
					return (
						value.hasReadPermission && (
							<Col col='6' sm='12' md={generateFiltersMdClass(filtersInSidebar)}>
								<FormGroup key={'formgroup-select-author-key'}>
									<Label htmlFor='created_by'>{t('created_by')}</Label>
									<UserSelectContainer
										selectedUser={wikiPageAdvancedFilterModel.createdBy}
										onUserSelect={onUserChange}
										isClearable={true}
										profileId={props.profileId}
										t={t}
										currentProject={currentProject}
									/>
								</FormGroup>
							</Col>
						)
					);
				}}
			</ConditionalRenderContext.Consumer>
			<Col col='6' sm='12' md={generateFiltersMdClass(filtersInSidebar)}>
				<FormGroup>
					<SportsTypeSelect
						sports={sports}
						selectedSportsType={wikiPageAdvancedFilterModel.sportsType}
						onSportsTypeSelect={onSportsTypeSelect}
						t={t}
					/>
				</FormGroup>
			</Col>
			{/*{//TODO remove is football selected check when the football data is migrated to sports-search-a}*/}
			{featuresService.areFeaturesEnabled([FeatureTypes.FOOTBALL, FeatureTypes.FOOTBALL_CONNECTIONS]) &&
			sportsConnectionsService.isFootballSelected(sportsType.sport) ? (
				<Col col='6' sm='12' md={generateFiltersMdClass(filtersInSidebar)}>
					<FormGroup>
						<Label htmlFor='football-connections'>{t('football_connections')}</Label>
						<FootballConnectionsContainer
							related={wikiPageAdvancedFilterModel.relatedSports}
							inputId={`wiki-pages-advanced-filters-football-connections-select-${calledFrom ? calledFrom : ''}`}
							onSelect={onSportsDataSelect}
							footballApiUrl={currentProject.footballApiUrl}
							allowMoreFootballConnections={false}
							t={t}
							contentLanguage={contentLanguage}
							inWikiPagesComponent={true}
						/>
					</FormGroup>
				</Col>
			) : (
				sportsType.sport !== '' && (
					<Col col='6' sm='12' md={generateFiltersMdClass(filtersInSidebar)}>
						<FormGroup>
							<Label htmlFor='sports-connections'>{t('sports_connections')}</Label>
							<SportsConnectionsContainer
								related={wikiPageAdvancedFilterModel.relatedSports}
								onSportsConnectionSelect={onSportsDataSelect}
								t={t}
								contentLanguage={contentLanguage}
								sportType={sportsType.sport}
								sportsEntities={sportsType.entity_types}
								allowMoreEntityTypes={false}
							/>
						</FormGroup>
					</Col>
				)
			)}
			{sportsConnectionsService.isFootballSelected(sportsType.sport)
				? !filtersInSidebar && (
						<Col col='6' sm='12' md={generateFiltersMdClass(filtersInSidebar)}>
							<FormGroup>
								<Label htmlFor='tournament-select'>{t('tournament')}</Label>
								<TournamentSelect
									related={wikiPageAdvancedFilterModel.tournament}
									onSelect={onTournamentDataSelect}
									footballApiUrl={currentProject.footballApiUrl}
									t={t}
								/>
							</FormGroup>
						</Col>
				  )
				: !filtersInSidebar &&
				  sportsType.sport && (
						<Col col='6' sm='12' md={generateFiltersMdClass(filtersInSidebar)}>
							<FormGroup>
								<Label htmlFor='competition-select'>{t('competition')}</Label>
								<CompetitionSelect
									related={wikiPageAdvancedFilterModel.tournament}
									onCompetitionSelect={onTournamentDataSelect}
									sportType={sportsType.sport}
									contentLanguage={contentLanguage}
									t={t}
								/>
							</FormGroup>
						</Col>
				  )}
			{sportsConnectionsService.isFootballSelected(sportsType.sport) &&
				!filtersInSidebar &&
				sportsType.sport &&
				checkObjectPropertiesExists(wikiPageAdvancedFilterModel, 'tournament', 'data', 'id') && (
					<Col col='6' sm='12' md={generateFiltersMdClass(filtersInSidebar)}>
						<FormGroup>
							<Label htmlFor='season-select'>{t('season')}</Label>
							<SeasonSelect
								related={wikiPageAdvancedFilterModel.relatedSports}
								onSeasonSelect={onSeasonSelect}
								selectedOption={wikiPageAdvancedFilterModel.season}
								t={t}
								selectedTournament={wikiPageAdvancedFilterModel.tournament}
							/>
						</FormGroup>
					</Col>
				)}
			{!filtersInSidebar && statuses['wikipageStatuses'] && statuses['wikipageStatuses'].length > 0 && (
				<Col col='6' sm='12' md={`${filtersInSidebar ? '0' : '4'}`}>
					<FormGroup>
						<Label htmlFor='date'>{t('select_status')}</Label>
						<StatusSelect
							isClearable
							contentType={ContentTypes.WIKI_PAGE}
							selectedStatus={wikiPageAdvancedFilterModel.status ? wikiPageAdvancedFilterModel.status : ''}
							statuses={statuses && statuses['wikipageStatuses']}
							onStatusSelect={onStatusSelect}
							t={t}
						/>
					</FormGroup>
				</Col>
			)}
		</>
	);
};

export default WikiPagesAdvancedFiltersFields;
