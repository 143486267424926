import React, { FunctionComponent, useEffect, useState } from 'react';
import { Button, Label, Row } from 'reactstrap';
import './styles/main-media-images.style.scss';
import { useTranslation } from 'react-i18next';
import MainMedia from '../../../../../../models/mainmedia/MainMedia';
import {
	filterMainMediaImageSubTypes,
	findEmptyItemArrIndex,
	findMainMediaItemAlreadySelected,
	removeMainMediaAdditionalItem,
} from './helpers/main-media-additional-items.helper';
import { MainMediaAdditionalItemsModal } from './sub-components/main-media-additional-images-modal.component';
import { MainMediaAdditionalItemInfoCard } from './sub-components/main-media-additional-items-info-card.component';
import { featuresService } from '../../../../../../App';
import { FeatureTypes } from '../../../../../../services/feature-service/features.enum';

type Properties = {
	mainMedia: MainMedia[];
	onMediaUpdate: (media: MainMedia) => void;
	onMediaAdd: (media: MainMedia) => void;
	onMediaRemove: (type: string, subType: string) => void;
	additionalMainMediaItemsOpened: (opened: boolean) => void;
};

export const MainMediaAdditionalImageSelect: FunctionComponent<Properties> = ({
	mainMedia,
	onMediaAdd,
	onMediaUpdate,
	onMediaRemove,
	additionalMainMediaItemsOpened,
}) => {
	const featureConfig = featuresService.getFeatureConfig(FeatureTypes.MAIN_MEDIA_ADDITIONAL_IMAGES);
	const [t] = useTranslation();
	const [toggle, setToggle] = useState<boolean>(false);
	const [mainMediaImageItems, setMainMediaImageItems] = useState<MainMedia[]>(
		filterMainMediaImageSubTypes(mainMedia, featureConfig.types && featureConfig.types.length > 0 ? featureConfig.types : []),
	);
	const [selectedMainMediaItemForEdit, setSelectedMainMediaItemForEdit] = useState<MainMedia>({} as MainMedia);

	useEffect(() => {
		setMainMediaImageItems(
			filterMainMediaImageSubTypes(mainMedia, featureConfig.types && featureConfig.types.length > 0 ? featureConfig.types : []),
		);
	}, [mainMedia && mainMedia.length, selectedMainMediaItemForEdit]);

	const handleAddMainMediaItemRow = (item: MainMedia) => {
		if (mainMedia && !findEmptyItemArrIndex(mainMedia)) {
			const newArr = [...mainMedia, item];
			setMainMediaImageItems(newArr);
			onMediaAdd(item);
		}
	};

	const handleDeleteRow = (itemId: string, item: MainMedia) => {
		const newMainMediaItemsArr = removeMainMediaAdditionalItem(mainMediaImageItems, itemId, item.resourceSubtype);
		setMainMediaImageItems(newMainMediaItemsArr);
		onMediaRemove(item.resourceType, item.resourceSubtype);
	};

	const onMainMediaAdditionalImageSelect = (mainMediaItem: MainMedia) => {
		let isAlreadyExist = findMainMediaItemAlreadySelected(mainMediaImageItems, mainMediaItem);

		if (isAlreadyExist) {
			onMediaUpdate(mainMediaItem);
			setSelectedMainMediaItemForEdit({} as MainMedia);
		} else {
			handleAddMainMediaItemRow(mainMediaItem);
			onMediaAdd(mainMediaItem);
			setSelectedMainMediaItemForEdit({} as MainMedia);
		}
	};

	const selectMainMediaItemForEdit = (item: MainMedia, newItem: boolean = true) => {
		newItem ? setSelectedMainMediaItemForEdit({} as MainMedia) : setSelectedMainMediaItemForEdit(item);
	};

	const removeSelectedMainMediaItem = () => {
		setSelectedMainMediaItemForEdit({} as MainMedia);
	};

	const toggleModal = () => {
		setToggle(!toggle);
		additionalMainMediaItemsOpened(!toggle);
	};

	return (
		<>
			<Label className='mb-2 mt-2 flex-row'>
				<strong>{t('additional_main_media_image')}</strong>
			</Label>
			{mainMediaImageItems &&
				mainMediaImageItems.length > 0 &&
				mainMediaImageItems.map(
					(item: MainMedia, index: number) =>
						item.resourceId &&
						item.resourceId.length > 0 && (
							<div className='main-media-images-list' key={index}>
								<MainMediaAdditionalItemInfoCard
									item={item}
									setToggle={setToggle}
									handleDeleteRow={handleDeleteRow}
									index={index}
									setSelectedItemForEdit={selectMainMediaItemForEdit}
								/>
							</div>
						),
				)}
			{toggle && (
				<MainMediaAdditionalItemsModal
					toggleModal={toggleModal}
					isOpen={toggle}
					initialAdd={mainMediaImageItems && mainMediaImageItems.length < 1}
					handleDeleteRow={handleDeleteRow}
					removeSelectedItem={removeSelectedMainMediaItem}
					pickNewItem={onMainMediaAdditionalImageSelect}
					selectedItemForEdit={selectedMainMediaItemForEdit}
					mainMediaItems={mainMediaImageItems}
				/>
			)}
			<Row>
				<Button className='ml-1' color='primary' outline onClick={() => toggleModal()}>
					<i className='fa fa-plus mx-1' />
					{t('add_additional_image')}
				</Button>
			</Row>
		</>
	);
};
