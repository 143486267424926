import Project from '../../models/project/Project';
import ErrorResponseModel from '../../models/error-response/ErrorResponseModel';
import AssetsModel from '../../models/team-manual-data/assets/assets';
import PlayerManualDataModel from '../../models/player-manual-data/player-manual-data.model';
import { COACH_SEARCH_FILTER_UPDATE } from './coach-action-creator';

export const PLAYER_LISTING_REQUEST = '[ACTION] PLAYER_LISTING_REQUEST';
export const PLAYER_LISTING_RECEIVED = '[ACTION] PLAYER_LISTING_RECEIVED';
export const PLAYER_LISTING_FAILED = '[ACTION] PLAYER_LISTING_FAILED';

export const PLAYER_ENTITY_REQUEST = '[ACTION] PLAYER_ENTITY_REQUEST';
export const PLAYER_ENTITY_RECEIVED = '[ACTION] PLAYER_ENTITY_RECEIVED';
export const PLAYER_ENTITY_FAILED = '[ACTION] PLAYER_ENTITY_FAILED';

export const PLAYER_SEARCH = '[ACTION] PLAYER_SEARCH';
export const PLAYER_SEARCH_FAILED = '[ACTION] PLAYER_SEARCH_FAILED';

export const PLAYER_ENTITY_CREATE = '[ACTION] PLAYER_ENTITY_CREATE';
export const PLAYER_ENTITY_CREATE_SUCCESS = '[ACTION] PLAYER_ENTITY_CREATE_SUCCESS';
export const PLAYER_ENTITY_CREATE_FAILED = '[ACTION] PLAYER_ENTITY_CREATE_FAILED';

export const PLAYER_ENTITY_UPDATE = '[ACTION] PLAYER_ENTITY_UPDATE';
export const PLAYER_ENTITY_UPDATE_FAILED = '[ACTION] PLAYER_ENTITY_UPDATE_FAILED';
export const PLAYER_ENTITY_UPDATE_SUCCESS = '[ACTION] PLAYER_ENTITY_UPDATE_SUCCESS';

export const PLAYER_ENTITY_POST_ASSETS = '[ACTION] PLAYER_ENTITY_POST_ASSETS';
export const PLAYER_ENTITY_POST_ASSETS_SUCCESS = '[ACTION] PLAYER_ENTITY_POST_ASSETS_SUCCESS';
export const PLAYER_ENTITY_POST_ASSETS_FAILED = '[ACTION] PLAYER_ENTITY_POST_ASSETS_FAILED';

export const SEARCH_PLAYER_LISTING_RECEIVED = '[ACTION] SEARCH_PLAYER_LISTING_RECEIVED';

export const PLAYER_BLACKLIST_REQUEST = '[ACTION] PLAYER_BLACKLIST_REQUEST';
export const PLAYER_BLACKLIST_RECEIVED = '[ACTION] PLAYER_BLACKLIST_RECEIVED';
export const PLAYER_SEARCH_FILTER_UPDATE = '[ACTION] PLAYER_SEARCH_FILTER_UPDATE';

export function returnObjectForPlayerListingRequest(page: string, project: Project) {
	return {
		type: PLAYER_LISTING_REQUEST,
		payload: { page, project },
	};
}

export function returnObjectForPlayerListingReceived(players: any) {
	return {
		type: PLAYER_LISTING_RECEIVED,
		payload: players,
	};
}

export function returnObjectForPlayerBlacklistReceived(players: any) {
	return {
		type: PLAYER_BLACKLIST_RECEIVED,
		payload: players,
	};
}

export function returnObjectForPlayerListingFailed(error: any) {
	return {
		type: PLAYER_LISTING_FAILED,
		payload: ErrorResponseModel.builder().fromResponse(error).build(),
	};
}

export function returnObjectForPlayerEntityCreate(player: PlayerManualDataModel, project: Project, assets: AssetsModel) {
	return {
		type: PLAYER_ENTITY_CREATE,
		payload: { player, project, assets },
	};
}

export function returnObjectForPlayerEntityCreateSuccess(id: string) {
	return {
		type: PLAYER_ENTITY_CREATE_SUCCESS,
		payload: id,
	};
}

export function returnObjectForPlayerEntityUpdate(player: PlayerManualDataModel, project: Project, assets: AssetsModel) {
	return {
		type: PLAYER_ENTITY_UPDATE,
		payload: { player, project, assets },
	};
}

export function returnObjectForPlayerEntityUpdateSuccess() {
	return {
		type: PLAYER_ENTITY_UPDATE_SUCCESS,
	};
}

export function returnObjectForPlayerEntityUpdateFailed() {
	return {
		type: PLAYER_ENTITY_UPDATE_FAILED,
	};
}

export function returnObjectForPlayerEntityCreateFailed() {
	return {
		type: PLAYER_ENTITY_CREATE_FAILED,
	};
}

export function returnObjectForPlayerEntityRequest(id: string, project: Project) {
	return {
		type: PLAYER_ENTITY_REQUEST,
		payload: { id, project },
	};
}

export function returnObjectForPlayerEntityReceived(player: any) {
	return {
		type: PLAYER_ENTITY_RECEIVED,
		payload: player,
	};
}

export function returnObjectForPlayerSearch(text: string, lang: string, type: string) {
	return {
		type: PLAYER_SEARCH,
		payload: { text, lang, type },
	};
}

export function returnObjectForPlayerSearchFailed(error: any) {
	return {
		type: PLAYER_SEARCH_FAILED,
		payload: ErrorResponseModel.builder().fromResponse(error).build(),
	};
}

export function returnObjectForSearchPlayerListingReceived(players: any) {
	return {
		type: SEARCH_PLAYER_LISTING_RECEIVED,
		payload: players,
	};
}

export function postPlayerAssets(payload: { playerId: string; project: Project; assets: AssetsModel }) {
	return {
		type: PLAYER_ENTITY_POST_ASSETS,
		payload: payload,
	};
}

export function playerAssetsPostSuccess() {
	return {
		type: PLAYER_ENTITY_POST_ASSETS_SUCCESS,
	};
}

export function playerAssetsPostFailed() {
	return {
		type: PLAYER_ENTITY_POST_ASSETS_FAILED,
	};
}

export function playerSearchFilterUpdate(text: string) {
	return {
		type: PLAYER_SEARCH_FILTER_UPDATE,
		payload: text,
	};
}
