import React, { useState } from 'react';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import HttpService from '../../../services/rest/HttpService';
import { RowTitleLink } from '../../Partials/BaseComponents/ListingComponent/subcomponents/Rows/subcomponents/RowTitleLink';
import PopoverItem from '../../Partials/BaseComponents/ListingComponent/subcomponents/Rows/PopoverItem';
import moment from 'moment';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Nav, Table } from 'reactstrap';
import { RowEditButton } from '../../Partials/BaseComponents/ListingComponent/subcomponents/Rows/subcomponents/RowEditButton';
import RowCopyButton from '../../Partials/BaseComponents/ListingComponent/subcomponents/Rows/subcomponents/RowCopyButton';
import { RowDeleteButton } from '../../Partials/BaseComponents/ListingComponent/subcomponents/Rows/subcomponents/RowDeleteButton';
import Profile from '../../../models/profile/Profile';
import Project from '../../../models/project/Project';
import { store } from '../../../store/store';
import QuickAccessListingHelper from './QuickAccessListingHelper';
import './QuickAccessListing.scss';
import {
	ARTICLE_ENTITY_DELETE_SUCCESS,
	returnObjectForArticleDelete,
	returnObjectForArticlePaginationUpdate,
} from '../../../store/action-creators/ArticleActionCreator';
import { returnObjectForVideoPaginationUpdate, VIDEO_DELETE_SUCCESS, videoDelete } from '../../../store/action-creators/VideoActionCreator';
import {
	GALLERY_DELETE_SUCCESS,
	galleryDelete,
	returnObjectForGalleryPaginationUpdate,
} from '../../../store/action-creators/GalleryActionCreator';
import { featuresService } from '../../../App';
import { FeatureTypes } from '../../../services/feature-service/features.enum';
import { contentStatisticsToOptions } from '../../Partials/Shared/search-content-by-type/helpers/search-content-by-type-container.helper';
import {
	constructContentStatisticsSearchQuery,
	reconstructContentForStatistics,
} from '../Lists/ListsContent/subcomponents/helpers/content-item-container.helper';
import RowContentStatisticsComponent from '../../Partials/BaseComponents/ListingComponent/subcomponents/Rows/subcomponents/row-content-statistic/row-content-statistics.component';
import PaginationMeta from '../../../models/pagination/PaginationMeta';
import { returnObjectForImagesPaginationUpdate } from '../../../store/action-creators/ImageActionCreator';
import { cssIconByContentType } from '../Lists/ListsContent/list-content.helper';
import { returnObjectForContentFiltersUpdate } from '../../../store/action-creators/content-resources';
import { remmapContentFilters } from '../Articles/Helpers/ArticleHelper';
import { LIVE_BLOGS_ENTITY_DELETE_SUCCESS } from '../../../store/action-creators/LiveBlogsActionCreator';
import { ContentTypes } from '../../../constants/content-types';
import AdvancedFilterModel from '../../Partials/Shared/advanced-content-filter/models/advanced-content-filter.model';

type State = {
	items: any[];
	isLoading: boolean;
};

type Properties = {
	profile: Profile;
	project: Project;
	match: any;
	t: any;
	history: any;
	analytics: any;
	deleteVideo: any;
	deleteGallery: any;
	deleteArticle: any;
	deleteLiveBlog: () => void;
	updateVideosPagination: (pagination: PaginationMeta) => any;
	updateGalleriesPagination: (pagination: PaginationMeta) => any;
	updateArticlesPagination: (pagination: PaginationMeta) => any;
	updateImagesPagination: (pagination: PaginationMeta) => any;
	updateContentFilters: (filters: any) => any;
};

class QuickAccessListing extends React.Component<Properties, State> {
	private contentStatisticsConfig = featuresService.getFeatureConfig(FeatureTypes.CONTENT_STATISTICS);

	constructor(props: any) {
		super(props);
		this.state = {
			items: [],
			isLoading: true,
		};
	}

	componentDidUpdate(prevProps: Readonly<Properties>, prevState: Readonly<State>, snapshot?: any) {
		if (this.props.match.params.id !== prevProps.match.params.id) {
			this.requestListContent();
		}
	}

	componentWillUnmount() {
		window.removeEventListener(ARTICLE_ENTITY_DELETE_SUCCESS, this.getListsListener);
		window.removeEventListener(VIDEO_DELETE_SUCCESS, this.getListsListener);
		window.removeEventListener(GALLERY_DELETE_SUCCESS, this.getListsListener);
		window.removeEventListener(LIVE_BLOGS_ENTITY_DELETE_SUCCESS, this.getListsListener);
	}

	getListsListener = () => this.requestListContent();

	componentDidMount() {
		this.requestListContent();
		window.addEventListener(ARTICLE_ENTITY_DELETE_SUCCESS, this.getListsListener);
		window.addEventListener(VIDEO_DELETE_SUCCESS, this.getListsListener);
		window.addEventListener(GALLERY_DELETE_SUCCESS, this.getListsListener);
		window.addEventListener(LIVE_BLOGS_ENTITY_DELETE_SUCCESS, this.getListsListener);
		this.props.updateArticlesPagination(PaginationMeta.builder().withCurrentPage(1).build());
		this.props.updateVideosPagination(PaginationMeta.builder().withCurrentPage(1).build());
		this.props.updateGalleriesPagination(PaginationMeta.builder().withCurrentPage(1).build());
		this.props.updateImagesPagination(PaginationMeta.builder().withCurrentPage(1).build());
		const filters = remmapContentFilters({}, {}, {}, {}, {}, {}, {}, {}, {}, {} as AdvancedFilterModel);
		this.props.updateContentFilters(filters);
	}

	requestListContent = () => {
		const projectDomain = this.props.project.domain;
		const headers = { Project: projectDomain };
		HttpService.get(`/lists/${this.props.match.params.id}`, null, headers)
			.then((resp: any) => {
				let items = new QuickAccessListingHelper().mapContentFromResponse(resp);
				const itemsLength = items.filter((content: any) => content.type !== 'TAG' && content.type !== 'RELATEDCONTENT');

				this.setState(
					{
						items: items,
						isLoading: false,
					},
					() => {
						if (
							featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.CONTENT_STATISTICS) &&
							items &&
							items.length > 0 &&
							itemsLength.length > 0
						)
							this.getContentStatistics(items);
					},
				);
			})
			.catch((e: any) => {
				console.log(e);
				this.setState({ isLoading: false });
			});
	};

	private getContentStatistics = (items: any) => {
		let reconstructedContent = reconstructContentForStatistics(items);

		HttpService.getContentStatistics(
			this.contentStatisticsConfig.request_headers[0],
			`${this.contentStatisticsConfig.url}${constructContentStatisticsSearchQuery(reconstructedContent)}`,
		)
			.then((response: any) => {
				const optionsWithStatistics = contentStatisticsToOptions(reconstructedContent, response.data, items);
				reconstructedContent = optionsWithStatistics.map((item: any) => ({ content: item.data, type: item.type }));

				this.setState({
					items: reconstructedContent,
					isLoading: false,
				});
			})
			.catch((e: any) => e);
	};

	onDelete = (contentId: string, contentType: string) => {
		if (contentType === 'ARTICLE') {
			this.props.deleteArticle(contentId, this.props.project);
		}
		if (contentType === 'VIDEO') {
			this.props.deleteVideo(contentId, this.props.project);
		}
		if (contentType === 'GALLERY') {
			this.props.deleteGallery(contentId, this.props.project);
		}
		if (contentType === ContentTypes.LIVE_BLOG.toUpperCase()) {
			this.props.deleteLiveBlog();
		}
	};

	render() {
		const { t, profile, analytics } = this.props;
		const { items, isLoading } = this.state;
		const userPermissions = store.getState().profile.profile.permissions;
		const helper = new QuickAccessListingHelper();
		const itemsLength = items.filter((content: any) => content.type !== 'TAG' && content.type !== 'RELATEDCONTENT');

		return (
			<div className='animated fadeIn'>
				<div className='card'>
					<div className={`${isLoading ? 'loading-overlay' : ''}`}>
						<Table>
							<thead>
								<tr>
									<th className='w-title'>{t('title')}</th>
									<th className='d-none d-sm-table-cell'>{t('active')}</th>
									<th className='w-createdBy d-none d-sm-table-cell'>{t('created_by')}</th>
									<th className='w-date d-none d-sm-table-cell'>{t('published_at')}</th>
									<th className='w-category d-none d-sm-table-cell'>{t('category')}</th>
									{featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.CONTENT_STATISTICS) &&
										itemsLength.length > 0 &&
										this.contentStatisticsConfig.mappings &&
										this.contentStatisticsConfig.mappings.length > 0 &&
										this.contentStatisticsConfig.mappings
											.filter((statistic: any) => statistic.is_main_statistic)
											.map((statistic: any) => {
												return (
													<th key={statistic.display_key} className='display-none d-sm-table-cell align-middle text-center'>
														{t(statistic.display_key)}
													</th>
												);
											})}
									<th className='w-buttons text-right'>{t('actions')}</th>
								</tr>
							</thead>
							<tbody>
								{items && items.length > 0
									? items
											.filter((content: any) => content.type !== 'TAG' && content.type !== 'RELATEDCONTENT')
											.map((data: any, index: number) => {
												const allowedPermissions = helper.checkPermission(userPermissions, data.type);
												return (
													<Row
														content={data.content}
														history={this.props.history}
														type={data.type}
														index={index}
														analytics={analytics}
														hasWritePermission={allowedPermissions.canWrite}
														hasDeletePermission={allowedPermissions.canDelete}
														profile={profile}
														onDelete={this.onDelete}
														t={t}
													/>
												);
											})
									: null}
							</tbody>
						</Table>
					</div>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state: any) {
	return {
		project: state.project.currentProject,
		profile: state.profile.profile,
	};
}

function mapDispatchToProps(dispatch: any) {
	return {
		deleteArticle: (id: string, project: Project) => dispatch(returnObjectForArticleDelete(id, project)),
		deleteVideo: (id: string, project: Project) => dispatch(videoDelete(id, project)),
		deleteGallery: (id: string, project: Project) => dispatch(galleryDelete(id, project)),
		deleteLiveBlog: (id: string, project: Project) => {},
		updateGalleriesPagination: (pagination: PaginationMeta) => dispatch(returnObjectForGalleryPaginationUpdate(pagination)),
		updateVideosPagination: (pagination: PaginationMeta) => dispatch(returnObjectForVideoPaginationUpdate(pagination)),
		updateArticlesPagination: (pagination: PaginationMeta) => dispatch(returnObjectForArticlePaginationUpdate(pagination)),
		updateImagesPagination: (pagination: PaginationMeta) => dispatch(returnObjectForImagesPaginationUpdate(pagination)),
		updateContentFilters: (filters: any) => dispatch(returnObjectForContentFiltersUpdate(filters)),
	};
}

export default compose(
	withTranslation(),
	connect(mapStateToProps, mapDispatchToProps),
	withRouter,
)(QuickAccessListing) as React.ComponentType;

const urlTypes = {
	ARTICLE: 'articles',
	VIDEO: 'videos',
	GALLERY: 'galleries',
	LIVE_BLOG: 'live-blogs',
};

const isLiveBlogType = (contentType: string) => contentType === ContentTypes.LIVE_BLOG.toUpperCase();

const generateRowEditLink = (contentType: string, contentId: string) => {
	if (isLiveBlogType(contentType)) {
		return `/smp/${urlTypes[contentType]}/configuration/edit/${contentId}`;
	}

	return `/smp/${urlTypes[contentType]}/edit/${contentId}`;
};

const Row: React.FunctionComponent<any> = ({
	content,
	index,
	hasWritePermission,
	hasDeletePermission,
	t,
	profile,
	history,
	analytics,
	type,
	onDelete,
}) => {
	const [dropdownOpen, setDropdownOpen] = useState({ id: '', isOpen: false });

	const toggle = (id: string) =>
		setDropdownOpen((prevState: any) => {
			return { id, isOpen: !prevState.isOpen };
		});

	return (
		<tr key={`table-row ${index}`}>
			<td className='align-middle'>
				<i className={`fa ${cssIconByContentType[type.toLowerCase()]} p-1`} title={type}></i>
				<RowTitleLink
					contentTitle={content.title}
					strapline={content.strapline}
					editContentPath={generateRowEditLink(type, content.id)}
					canEditContent={hasWritePermission}
					analytics={analytics}
					contentId={`video-id-${content.id}`}
				/>
			</td>
			<td className='d-none d-sm-table-cell align-middle text-center'>
				{content.status === 'active' ? (
					<i className='fa-lg fa fa-check-circle text-success mx-2' />
				) : (
					<i className='fa-lg fa fa-times-circle text-danger mx-2' />
				)}
			</td>
			<PopoverItem t={t} content={content} lang={profile.language} />
			<td className='d-none d-sm-table-cell align-middle'>
				{moment(content.publishedAt).lang(profile.language.split('_')[0]).format('DD MMM YYYY, HH:mm')}
			</td>
			<td className='d-none d-sm-table-cell align-middle'>{content.category.title}</td>
			<RowContentStatisticsComponent content={content} />
			<td className='align-middle d-sm-table-cell'>
				<Nav className='justify-content-end'>
					<Dropdown nav isOpen={dropdownOpen.id === content.id && dropdownOpen.isOpen} toggle={() => toggle(content.id)}>
						{hasWritePermission && !isLiveBlogType(type) && (
							<DropdownToggle id={`toggle-menu-caret-${content.id}`} nav caret className='d-flex align-items-center p-0 text-secondary'>
								<RowEditButton
									contentId={content.id}
									display={hasWritePermission}
									editContentPath={generateRowEditLink(type, content.id)}
									t={t}
									analytics={analytics}
									history={history}
								/>
							</DropdownToggle>
						)}
						{hasWritePermission && isLiveBlogType(type) && (
							<RowEditButton
								contentId={content.id}
								display={hasWritePermission}
								editContentPath={generateRowEditLink(type, content.id)}
								t={t}
								analytics={analytics}
								history={history}
							/>
						)}
						{!isLiveBlogType(type) && (
							<DropdownMenu className='p-0 quick-access-actions-buttons-wrapper'>
								{hasWritePermission && (
									<DropdownItem className='p-0 border-0'>
										<RowCopyButton
											contentType={type.toLowerCase()}
											createContentPath={`/smp/${urlTypes[type]}/create`}
											content={content}
											history={history}
											display={hasWritePermission}
											t={t}
											analytics={analytics}
										/>
									</DropdownItem>
								)}
								{hasDeletePermission && (
									<DropdownItem className='p-0 border-0'>
										<RowDeleteButton
											contentType={type.toLowerCase()}
											dropdownItem
											analytics={analytics}
											display={hasDeletePermission}
											t={t}
											onDelete={(contentId) => {
												onDelete(contentId, type);
											}}
											contentTitle={content.title}
											contentId={content.id}
										/>
									</DropdownItem>
								)}
							</DropdownMenu>
						)}
					</Dropdown>
				</Nav>
			</td>
		</tr>
	);
};
