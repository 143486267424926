import React, { FunctionComponent, useEffect, useState } from 'react';
import {
	autoGenerateReduxPropsNamingForContentModels,
	availableContentTypes,
} from '../../../../../../../services/content-models-service/ContentModelService';
import Related from '../../../../../../../models/related/Related';
import { extractRelatedPropertiesNameByUrl } from '../../../../tags-refactored/helpers/utils';
import { connect } from 'react-redux';
import { decideSuggestionsPriority, generateFilteredRelatedData } from '../../../helpers/general.helper';
import { requestSuggestedRelatedResources } from '../../../helpers/request.helper';
import RelatedContentList from '../listing';
import PaginationMeta from '../../../../../../../models/v2/Pagination/PaginationModel';
import RelatedContentPagination from '../pagination';

type Properties = {
	t: any;
	searchType: availableContentTypes;
	reduxSportConnections: Related[];
	reduxEventsRelated: Related[];
	projectDomain: string;
	language: string;
};

const SuggestionInsideTab: FunctionComponent<Properties> = ({
	t,
	searchType,
	reduxSportConnections,
	reduxEventsRelated,
	projectDomain,
	language,
}) => {
	const [isContentLoading, setIsContentLoading] = useState(false);
	const [relatedData, setRelatedData] = useState<Related[]>([]);
	const [pagination, setPagination] = useState<PaginationMeta>(new PaginationMeta());

	useEffect(() => {
		setIsContentLoading(true);
		const summarizedRelatedData = generateFilteredRelatedData(reduxSportConnections, reduxEventsRelated);
		const { requestQuery } = decideSuggestionsPriority(summarizedRelatedData);
		const header = { Project: projectDomain };
		requestSuggestedRelatedResources(searchType, requestQuery, header, language, pagination.currentPage)
			.then(({ related, pagination }) => {
				setRelatedData(related);
				setPagination(pagination);
				setIsContentLoading(false);
			})
			.catch(() => setIsContentLoading(false));
	}, [searchType, pagination.currentPage, reduxSportConnections.length, reduxEventsRelated.length]);

	const onPageChange = (selectedPage: number) => setPagination({ ...pagination, currentPage: selectedPage });

	return (
		<div className={`${isContentLoading ? 'loading-overlay' : ''}`}>
			<RelatedContentList t={t} relatedData={relatedData} />
			<RelatedContentPagination t={t} pagination={pagination} onPageChange={onPageChange} />
		</div>
	);
};

function mapStateToProps(state: any) {
	const { tempPropertyNaming, nestedTempPropertyNaming } = autoGenerateReduxPropsNamingForContentModels();
	const nestedReduxPropertyName = extractRelatedPropertiesNameByUrl();

	return {
		contentModelId: state[tempPropertyNaming][nestedTempPropertyNaming].id || '',
		language: state.project.currentProject.language || 'en',
		projectDomain: state.project.currentProject.domain || '',
		reduxSportConnections: (nestedReduxPropertyName && state.tempSportsRelated[nestedReduxPropertyName]) || [],
		reduxEventsRelated: (nestedReduxPropertyName && state.tempMatchRelated[nestedReduxPropertyName]) || [],
		alreadyAddedRelatedContentRedux: (nestedReduxPropertyName && state.tempContentRelated[nestedReduxPropertyName]) || [],
	};
}

export default connect(mapStateToProps)(SuggestionInsideTab);
