import { ImagoImageJson } from './imago-image.json';
import ImagoImageBuilder from './imago-image.builder';

export default class ImagoImageModel {
	readonly id: string = '';
	readonly type: string = '';
	readonly description: string = '';
	readonly width: string = '';
	readonly height: string = '';
	readonly source: string = '';
	readonly image: any = '';
	readonly dateCreated: string = '';

	private constructor(
		id: string,
		description: string,
		type: string,
		width: string,
		height: string,
		source: string,
		image: any,
		dateCreated: string,
	) {
		this.id = id;
		this.type = type;
		this.description = description;
		this.width = width;
		this.height = height;
		this.source = source;
		this.image = image;
		this.dateCreated = dateCreated;
	}

	toJson(): ImagoImageJson {
		return {
			id: this.id,
			description: this.description,
			type: this.type,
			width: this.width,
			height: this.height,
			source: this.source,
			image: this.image,
			dateCreated: this.dateCreated,
		};
	}

	static fromJSON(json: ImagoImageJson): ImagoImageModel {
		return new ImagoImageModel(json.id, json.description, json.type, json.width, json.height, json.source, json.image, json.dateCreated);
	}

	static builder(image?: ImagoImageModel): ImagoImageBuilder {
		return new ImagoImageBuilder(image);
	}
}
