import React from 'react';
import { Properties } from './properties/highlight-block-view.properties';

export const HighlightView: React.FunctionComponent<Properties> = ({ preview, t }) => {
	return (
		<div className='row pl-3 pr-3'>
			<div className='col-12 col-sm-12 col-md-10'>
				<h4 className='mb-2'>
					<i className='fa fa-asterisk'></i> {t('title')}: <strong>{preview.title}</strong>
				</h4>
				<p className='mb-2'>
					{t('description')}: <i>{preview.description}</i>
				</p>
			</div>
		</div>
	);
};
