import { AssetsTypesJson } from './assets-types.json';
import AssetsTypesModel from './assets-types.model';
import { AssetsTypesRequestJson } from './assets-types.request-json';

export default class AssetsTypesBuilder {
	private json: AssetsTypesJson;

	constructor(types?: AssetsTypesModel | AssetsTypesJson) {
		if (types && types instanceof AssetsTypesModel) {
			this.json = types.toJSON();
		} else if (types) {
			this.json = types;
		} else {
			this.json = {} as AssetsTypesJson;
		}
	}

	withEntity(entity: string): AssetsTypesBuilder {
		this.json.entity = entity;

		return this;
	}

	withType(assetType: string): AssetsTypesBuilder {
		this.json.assetType = assetType;

		return this;
	}

	withDimensions(dimensions: any): AssetsTypesBuilder {
		this.json.dimensions = dimensions;

		return this;
	}

	withContexts(contexts: any): AssetsTypesBuilder {
		this.json.contexts = contexts;

		return this;
	}

	build(): AssetsTypesModel {
		return AssetsTypesModel.fromJSON(this.json);
	}

	toRequestJson(): AssetsTypesRequestJson {
		let json = {} as AssetsTypesRequestJson;

		json.entity = this.json.entity ? this.json.entity : '';
		json.asset_type = this.json.assetType ? this.json.assetType : '';
		json.dimensions = this.json.dimensions ? this.json.dimensions : '';
		json.contexts = this.json.contexts ? this.json.contexts : '';

		return json;
	}
}
