import React, { FunctionComponent } from 'react';
import { Col, Row } from 'reactstrap';
import InputDesign from '../../../../../Partials/design-components/input/input';
import { connect } from 'react-redux';
import { DATA_QA } from '../../../helpers/data-qa.properties';
import { capitalize, extractContentModeBasedOnUrl } from '../../../../../../global-helpers/global.helpers';
import { updateCustomEntity } from '../../../../../../store/action-creators/custom-entities-action-creator';
import { UpdateCustomEntityReduxType } from '../../../helpers/custom-entities.helper';
import { ISocialMediaLinks } from '../../../models/models';

const availableSocials = ['facebook', 'youtube', 'whatsapp', 'instagram', 'tiktok', 'x', 'linkedin', 'snapchat', 'telegram'];

type Props = {
	customEntitySocials: ISocialMediaLinks[];
	updateCustomEntity: UpdateCustomEntityReduxType;
};

const CustomEntitySocials: FunctionComponent<Props> = ({ customEntitySocials, updateCustomEntity }) => {
	const socials = availableSocials.map((social) => {
		const alreadyAddedSocial = customEntitySocials.find((el) => el.slug === social);
		return {
			name: capitalize(social),
			slug: social,
			value: alreadyAddedSocial ? alreadyAddedSocial.value : '',
		};
	});

	const updateSocialsInRedux = (social: string, value: string) => {
		const updatedSocialsIndexEl = customEntitySocials.findIndex((el) => el.slug === social);
		const newSocials = [...customEntitySocials];

		if (updatedSocialsIndexEl >= 0) {
			newSocials[updatedSocialsIndexEl] = { slug: social, value };
		} else {
			newSocials.push({ slug: social, value });
		}
		updateCustomEntity({ social_media_links: newSocials });
	};

	return (
		<div id={DATA_QA.SOCIALS}>
			<Row>
				{socials.map((social) => (
					<Col md='6' key={social.slug}>
						<InputDesign
							invokeFunc={(text) => updateSocialsInRedux(social.slug, text)}
							fieldId={social.slug}
							labelText={social.name}
							value={social.value}
							placeholderText={social.name}
						/>
					</Col>
				))}
			</Row>
		</div>
	);
};

function mapStateToProps(state: any) {
	const contentMode = extractContentModeBasedOnUrl();

	return {
		customEntitySocials: state.customEntities[contentMode].social_media_links || [],
	};
}

function mapDispatchToProps(dispatch: any) {
	const contentMode = extractContentModeBasedOnUrl();

	return {
		updateCustomEntity: (dataForUpdate: Record<string, unknown>) => dispatch(updateCustomEntity(contentMode, dataForUpdate)),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomEntitySocials);
