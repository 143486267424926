import React, { Component } from 'react';
import InnerHTML from 'dangerously-set-html-content';
import { multiLingualService } from '../../../../App';
import { store } from '../../../../store/store';

export default class DangerouslySetInner extends Component {
	componentDidMount() {
		const { currentProject } = store.getState().project;

		setTimeout(() => {
			if (window.FB) {
				window.FB.XFBML.parse(document.getElementById('social-facebook'), () => {
					this.setState({ isWidgetLoading: false });
				});
			}
			if (window.SMP365 && window.SMP365.loadWidgets) {
				window.SMP365.loadWidgets();
			}
			if (
				window.SMP365 &&
				window.SMP365.configurator &&
				window.SMP365.configurator.addOrUpdateOption(
					'LANG',
					`${
						multiLingualService.checkIfProjectIsMultiLingual(currentProject.languages) && multiLingualService.checkRoutePath()
							? multiLingualService.setCorrectAcceptLanguageCode(currentProject.languages.defaultLanguageCode.languageCode)
							: currentProject && currentProject.language && currentProject.language.split('-')[0]
					}`,
				)
			) {
				window.SMP365.loadWidgets();
			}
		}, 500);
	}

	render() {
		return (
			<>
				<InnerHTML html={this.props.html} />
			</>
		);
	}
}
