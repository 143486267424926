import React from 'react';
import Select from 'react-select';
import { connect } from 'react-redux';
import { Col, Label, Row } from 'reactstrap';
import HttpService from '../../../../../services/rest/HttpService';
import { Properties, State } from './properties/tournament-select.properties';
import {
	tournamentsToOptions,
	tournamentToOption,
	remapTournamentFromResponse,
	selectionsToTournaments,
} from './helpers/tournament-select.helper';
import TournamentModel from '../../blocks/widgets/player-profile/models/tournament-model/tournament.model';
import { customOption } from '../shared/custom-select-option';
import ErrorHandler from '../error/error-handler-component';

class TournamentSelectContainer extends React.Component<Properties, State> {
	constructor(props: Properties) {
		super(props);
		this.state = {
			tournamentsOptions: [],
		};
	}

	componentDidMount() {
		this.getTournaments(this.props.tournamentOrder);
	}

	updateTournamentsOptionsState = (tournaments: TournamentModel[]) => {
		const { onTournamentSelect, selectedTournament, isBlocky, isMulti } = this.props;
		if (
			(!selectedTournament ||
				(selectedTournament && (selectedTournament.id === undefined || selectedTournament.id.length < 1) && !isMulti) ||
				(isMulti && selectedTournament.length === 0)) &&
			isBlocky
		) {
			onTournamentSelect(tournaments[0]);
		}
		this.setState({ ...this.state, tournamentsOptions: tournaments });
	};

	getTournaments(tournamentOrder: string) {
		HttpService.getTournaments(tournamentOrder)
			.then((response: any) => {
				this.updateTournamentsOptionsState(remapTournamentFromResponse(response.data));
			})
			.catch((e: any) => e);
	}

	render() {
		let { onTournamentSelect, selectedTournament, t, isMulti, isClearable, withLabel, label } = this.props;
		isClearable = isClearable === undefined || isClearable === null ? false : isClearable;
		isMulti = isMulti === undefined || isMulti === null ? false : isMulti;

		return (
			<Row>
				<Col>
					{withLabel && <Label>{t('tournament')}:</Label>}
					{label && <Label>{t(label)}</Label>}
					{isMulti ? (
						<Select
							menuPortalTarget={document.body}
							menuPosition='absolute'
							className='w-100'
							placeholder={t('select')}
							formatOptionLabel={customOption}
							isMulti
							options={tournamentsToOptions(this.state.tournamentsOptions)}
							value={tournamentsToOptions(selectedTournament)}
							noOptionsMessage={(inputValue) => inputValue && t('no_options')}
							isClearable={isClearable}
							onChange={(selections: any) => onTournamentSelect(selectionsToTournaments(selections))}
							styles={{
								menuPortal: (baseStyles) => ({
									...baseStyles,
									zIndex: 10000,
								}),
							}}
						/>
					) : (
						<Select
							menuPortalTarget={document.body}
							menuPosition='absolute'
							className='w-100'
							placeholder={t('select')}
							formatOptionLabel={customOption}
							options={tournamentsToOptions(this.state.tournamentsOptions)}
							value={selectedTournament && selectedTournament.id ? tournamentToOption(selectedTournament) : []}
							noOptionsMessage={(inputValue) => inputValue && t('no_options')}
							isClearable={isClearable}
							onChange={(selection: any) => {
								if (selection) {
									onTournamentSelect(selection.data);
								} else {
									onTournamentSelect(null);
								}
							}}
							styles={{
								menuPortal: (baseStyles) => ({
									...baseStyles,
									zIndex: 10000,
								}),
							}}
						/>
					)}
					{!this.props.isValid && !this.props.selectedTournament && <ErrorHandler t={this.props.t} errorMessage='field_is_required' />}
				</Col>
			</Row>
		);
	}
}

function mapStateToProps(state: any) {
	return {
		tournamentOrder: state.project.currentProject.defaultTournamentOrder,
	};
}

export default connect(mapStateToProps)(TournamentSelectContainer);
