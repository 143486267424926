import React from 'react';
import { Col, Row } from 'reactstrap';

type Properties = {
	block: any;
	t: any;
};

export const SquadStatisticsView: React.FunctionComponent<Properties> = ({ block, t }) => {
	if (block && block.squadStatistics && block.squadStatistics.team && block.squadStatistics.team.id) {
		return (
			<>
				<Row className={'m-2 ml-4 d-flex align-items-center'}>
					<img src={block.squadStatistics.team ? block.squadStatistics.team.logo : ''} height='50' className='mx-1' alt='' />
					<h4>{block.squadStatistics.team ? block.squadStatistics.team.name : ''}</h4>
				</Row>
				<Row className={'px-4 row'}>
					<Col>
						<hr></hr>
					</Col>
				</Row>
				{block.squadStatistics && block.squadStatistics.season && block.squadStatistics.season.value && (
					<>
						<Row className={'px-4 row'}>
							<Col>
								<strong>{t('season')}: </strong>
								<span>{block.squadStatistics.season.label}</span>
							</Col>
						</Row>
						<Row className={'px-4 row'}>
							<Col>
								<hr></hr>
							</Col>
						</Row>
					</>
				)}

				<>
					{block.squadStatistics.team.id &&
						block.squadStatistics.playerPosition &&
						block.squadStatistics.playerPosition.length > 0 &&
						block.squadStatistics.statisticColumns &&
						block.squadStatistics.statisticColumns.length > 0 &&
						block.squadStatistics.playerPosition.map((position: any) => {
							return block.squadStatistics.statisticColumns.map((item: any, index: number) => {
								if (item.position === position.value) {
									return (
										<Row key={index} className='px-4'>
											<Col>
												<strong>{t(position.label)}: </strong>
												{item.columns.map((el: any, i: number) => (
													<span key={i}>{(i ? ', ' : '') + t(el.label)}</span>
												))}
											</Col>
										</Row>
									);
								}
							});
						})}
				</>

				<>
					{block.squadStatistics.team.id && block.squadStatistics.playerPosition && block.squadStatistics.playerPosition.length === 0 && (
						<>
							<Row className='px-4'>
								<Col>
									<strong>{t('keeper')}: </strong>
									<span>{t('name') + ', '}</span>
									<span>{t('birth_date') + ', '}</span>
									<span>{t('nationality') + ', '}</span>
									<span>{t('played_title') + ', '}</span>
									<span>{t('cleansheets_title') + ', '}</span>
									<span>{t('conceded_title')}</span>
								</Col>
							</Row>
							<Row className='px-4'>
								<Col>
									<strong>{t('forward')}: </strong>
									<span>{t('name') + ', '}</span>
									<span>{t('birth_date') + ', '}</span>
									<span>{t('nationality') + ', '}</span>
									<span>{t('played_title') + ', '}</span>
									<span>{t('goals_title') + ', '}</span>
									<span>{t('assists_title')}</span>
								</Col>
							</Row>
							<Row className='px-4'>
								<Col>
									<strong>{t('defender')}: </strong>
									<span>{t('name') + ', '}</span>
									<span>{t('birth_date') + ', '}</span>
									<span>{t('nationality') + ', '}</span>
									<span>{t('played_title') + ', '}</span>
									<span>{t('goals_title') + ', '}</span>
									<span>{t('assists_title')}</span>
								</Col>
							</Row>
							<Row className='px-4'>
								<Col>
									<strong>{t('midfielder')}: </strong>
									<span>{t('name') + ', '}</span>
									<span>{t('birth_date') + ', '}</span>
									<span>{t('nationality') + ', '}</span>
									<span>{t('played_title') + ', '}</span>
									<span>{t('goals_title') + ', '}</span>
									<span>{t('assists_title')}</span>
								</Col>
							</Row>
						</>
					)}
				</>
			</>
		);
	}
	return null;
};
