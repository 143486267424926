import React from 'react';
import BlockModel from '../../../../models/block.model';
import TournamentSelectInstat from '../partials/tournament-select/tournament-select.component';
import TournamentSelectModel from '../partials/tournament-select/tournament-select.model';
import SeasonSelect from '../partials/seasons-in-tournament-select/seasons-in-tournament-select.component';
import TeamSelect from '../../../../partials/team-select/team-select.component';
import MatchSelect from '../partials/matches-in-season-select/matches-in-season-select.component';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import { blockManagementService, blockWrapperService } from '../../../../subcomponents/blocky.component';
import BlockValidation from '../../../../helpers/block-validation.helper';
import InstatHttpService from '../../../../../../../services/rest/instat/InstatHttpService';
import ErrorHandler from '../../../../partials/error/error-handler-component';
import TeamModel from '../../../../partials/team-select/models/team.model';
import { filterStatisticsFromResponse } from './instat-helpers';
import InstatStatisticsMultiSelect from '../partials/stats-select/stats-select.component';
import MatchesContainer from '../../../../partials/matches/matches-container';
import HttpService from '../../../../../../../services/rest/HttpService';

type Properties = {
	t: any;
	block: BlockModel;
};

export default class InstatPostMatchTeamsStatsBlockEdit extends React.Component<any, any> {
	private httpInstat = new InstatHttpService();

	constructor(props: Properties) {
		super(props);
		props.block.data.preview = props.block.data.preview ? props.block.data.preview : {};

		const { selectedTournamentMatch, selectedSeasonMatch, selectedMatch } = props.block.data.preview;
		const { match, team1, team2, team1select, team2select, instatStatistics, matchSMP } = props.block.data;

		this.state = {
			selectedTournamentMatch: selectedTournamentMatch ? selectedTournamentMatch : null,
			selectedSeasonMatch: selectedSeasonMatch,
			selectedMatch: selectedMatch,
			statsMatch: match ? match.stats : null,
			statsTeam1: team1 ? team1.stats : null,
			statsTeam2: team2 ? team2.stats : null,
			isValid: true,
			statsLoading: false,
			team1select: props.block && props.block.data && props.block.data.preview ? team1select : TeamModel.builder().build(),
			team2select: props.block && props.block.data && props.block.data.preview ? team2select : TeamModel.builder().build(),
			selectedSMPMatch:
				props.block.data && matchSMP
					? {
							id: matchSMP ? matchSMP.id : null,
							goalHome: matchSMP ? parseInt(matchSMP.goalHome) : null,
							goalAway: matchSMP ? parseInt(matchSMP.goalAway) : null,
							eventStatus: matchSMP ? matchSMP.eventStatus : null,
							round: matchSMP ? matchSMP.round : null,
							startTime: matchSMP ? matchSMP.startTime : null,
							tournamentName: matchSMP ? matchSMP.tournament : null,
					  }
					: null,
			instatStatistics: instatStatistics ? instatStatistics : {},
		};
	}

	componentDidMount(): void {
		blockWrapperService.registerOnSaveFunc({ blockId: this.props.block.id, func: this.onSave });
	}

	componentWillUnmount(): void {
		blockWrapperService.unregisterOnSaveFunc(this.props.block.id);
	}

	onSave = (index: number) => {
		const block = Object.assign({}, this.props.block);
		const validation = new BlockValidation();
		const { selectedSMPMatch } = this.state;
		const data = {
			match: { info: this.state.selectedMatch, stats: this.state.statsMatch },
			matchSMP: {
				id: selectedSMPMatch ? selectedSMPMatch.id : null,
				goalHome: selectedSMPMatch ? parseInt(selectedSMPMatch.goalHome) : null,
				goalAway: selectedSMPMatch ? parseInt(selectedSMPMatch.goalAway) : null,
				round: selectedSMPMatch && selectedSMPMatch.data ? selectedSMPMatch.data.round : selectedSMPMatch && selectedSMPMatch.round,
				tournament: selectedSMPMatch ? selectedSMPMatch.tournamentName : null,
				eventStatus:
					selectedSMPMatch && selectedSMPMatch.data ? selectedSMPMatch.data.event_status : selectedSMPMatch && selectedSMPMatch.eventStatus,
				startTime: selectedSMPMatch ? selectedSMPMatch.startTime : null,
			},
			team1: {
				info: {
					id: this.state.selectedMatch ? this.state.selectedMatch.team1_id : null,
					name: this.state.selectedMatch ? this.state.selectedMatch.team1_name : null,
					logo: this.state.selectedMatch ? this.state.selectedMatch.team1_logo : null,
				},
				stats: this.state.statsTeam1,
			},
			team2: {
				info: {
					id: this.state.selectedMatch ? this.state.selectedMatch.team2_id : null,
					name: this.state.selectedMatch ? this.state.selectedMatch.team2_name : null,
					logo: this.state.selectedMatch ? this.state.selectedMatch.team2_logo : null,
				},
				stats: this.state.statsTeam2,
			},
			team1select: this.state.team1select ? this.state.team1select : null,
			team2select: this.state.team2select ? this.state.team2select : null,
			statsLoading: this.state.statsLoading ? this.state.statsLoading : false,
			instatStatistics: this.state.instatStatistics ? this.state.instatStatistics : null,
		};
		const isValid = validation.validate(this.props.block, data);
		this.setState({ ...this.state, isValid });

		if (isValid) {
			block.data = data;
			block.data.preview = {
				selectedTournamentMatch: this.state.selectedTournamentMatch,
				selectedSeasonMatch: this.state.selectedSeasonMatch,
				selectedMatch: this.state.selectedMatch,
				statsTeam1: this.state.statsTeam1,
				statsTeam2: this.state.statsTeam2,
				team1select: this.state.team1select,
				team2select: this.state.team2select,
				instatStatistics: this.state.instatStatistics,
			};
			blockManagementService.blockUpdate(block, index);
		} else {
			return isValid;
		}
	};

	onTournamentChange = (tournament: any) => {
		this.setState((state: any) => {
			return {
				...state,
				selectedTournamentMatch: tournament,
				selectedSeasonMatch: null,
				selectedMatch: null,
				statsTeam1: null,
				statsTeam2: null,
				team1select: null,
				team2select: null,
				isValid: true,
			};
		});
	};

	onTeam1Change = (team1: TeamModel) => {
		this.setState((state: any) => {
			return {
				...state,
				team1select: TeamModel.builder(team1)
					.withName(team1.name)
					.withId(team1.id)
					.withType(team1.type)
					.withLogo(team1.logo)
					.withThreeLetterCode(team1.threeLetterCode)
					.build(),
				isValid: true,
			};
		});
	};

	onTeam2Change = (team2: TeamModel) => {
		this.setState((state: any) => {
			return {
				...state,
				team2select: TeamModel.builder(team2)
					.withName(team2.name)
					.withId(team2.id)
					.withType(team2.type)
					.withLogo(team2.logo)
					.withThreeLetterCode(team2.threeLetterCode)
					.build(),
				isValid: true,
			};
		});
	};

	onSeasonChange = (season: any) => {
		this.setState((state: any) => {
			return {
				...state,
				selectedSeasonMatch: season,
				selectedMatch: null,
				selectedSMPMatch: null,
				statsTeam1: null,
				statsTeam2: null,
				team1select: null,
				team2select: null,
				isValid: true,
			};
		});
	};

	onMatchFootballApi = (data: any) => {
		HttpService.getTournamentById(data.data.tournament_season_stage.tournament_id).then((resp: any) => {
			data.tournamentName = resp.data.name;
			this.setState({
				selectedSMPMatch: data,
				statsLoading: false,
			});
		});
	};

	onMatchChange = (match: any) => {
		this.setState(
			(state: any) => {
				return {
					...state,
					selectedMatch: match,
					statsLoading: true,
					isValid: true,
					statsMatch: [],
					statsTeam1: [],
					statsTeam2: [],
					selectedSMPMatch: null,
					team1select: null,
					team2select: null,
					instatStatistics: null,
				};
			},
			() => {
				const { selectedMatch } = this.state;
				if (selectedMatch) {
					this.getMatchGoalsStats(selectedMatch.id);
					this.getMatchTeamsStats(selectedMatch.id);
				} else {
					this.setState({ statsLoading: false, statsMatch: [], statsTeam1: [], statsTeam2: [] });
				}
			},
		);
	};

	private getMatchGoalsStats = (matchId: string) => {
		this.httpInstat.getMatchGoals(matchId).then((data) => {
			this.setState({ statsMatch: data });
			if (data) {
				this.setState({ statsLoading: false });
			}
		});
	};

	private getMatchTeamsStats = (matchId: string) => {
		this.httpInstat.getMatchTeamsStats(matchId).then((data) => {
			const statsTeam1filtered = data && data[0] ? filterStatisticsFromResponse(data[0].param, this.state.instatStatistics) : [];
			const statsTeam2filtered = data && data[1] ? filterStatisticsFromResponse(data[1].param, this.state.instatStatistics) : [];
			this.setState({
				statsTeam1: statsTeam1filtered && statsTeam1filtered.length > 0 ? statsTeam1filtered : [],
				statsTeam2: statsTeam2filtered && statsTeam2filtered.length > 0 ? statsTeam2filtered : [],
				statsLoading:
					data && statsTeam1filtered && statsTeam1filtered.length > 0 && statsTeam2filtered && statsTeam2filtered.length > 0 ? false : true,
			});
		});
	};

	onInstatStatisticsSelect = (instatStatistics: any) => {
		const { selectedMatch } = this.state;

		instatStatistics = instatStatistics ? instatStatistics : {};

		this.setState({
			...this.state,
			instatStatistics: instatStatistics,
			statsLoading: true,
		});
		this.getMatchTeamsStats(selectedMatch.id);
	};

	private isMatchDatAvailable = (statsMatch: any, statsTeam1: any, statsTeam2: any) => {
		const { instatStatistics } = this.state;

		if (statsMatch && statsMatch.length >= 0 && statsTeam1 && statsTeam1.length > 0 && statsTeam2 && statsTeam2.length > 0) {
			return <div>{this.props.t('data_loaded')}</div>;
		} else if (statsMatch && statsMatch.length < 1) {
			return <div>No data available</div>;
		} else if (instatStatistics === null) {
			return <div>{this.props.t('statistics_not_selected')}</div>;
		} else {
			return null;
		}
	};

	render() {
		const { t } = this.props;
		const {
			selectedTournamentMatch,
			selectedSeasonMatch,
			selectedMatch,
			statsMatch,
			statsTeam1,
			statsTeam2,
			statsLoading,
			isValid,
			team1select,
			team2select,
			selectedSMPMatch,
			instatStatistics,
		} = this.state;

		return (
			<div>
				<Row className='mb-3'>
					<Col xs={12} md={6}>
						<Label>{t('tournament')}</Label>
						<TournamentSelectInstat
							value={selectedTournamentMatch}
							onSelect={(selection: TournamentSelectModel | null) => this.onTournamentChange(selection)}
						/>
						<div className='mt-1'>{!isValid && selectedTournamentMatch === null && <ErrorHandler errorMessage='field_is_required' />}</div>
					</Col>
					<Col xs={12} md={6}>
						{selectedTournamentMatch ? (
							<>
								<Label>{t('season')}</Label>
								<SeasonSelect
									value={selectedSeasonMatch}
									tournamentId={selectedTournamentMatch.id}
									onSelect={(season) => this.onSeasonChange(season)}
								/>
								{!isValid && !selectedSeasonMatch && <ErrorHandler errorMessage='field_is_required' />}
							</>
						) : null}
					</Col>
				</Row>
				<Row className='mb-3'>
					<Col xs={12} md={6}>
						{selectedSeasonMatch ? (
							<>
								<Label>{t('match')}</Label>
								<MatchSelect
									value={selectedMatch}
									tournamentId={selectedTournamentMatch && selectedTournamentMatch.id}
									seasonId={selectedSeasonMatch.id}
									onSelect={(match) => this.onMatchChange(match)}
								/>
								{!isValid && !selectedMatch && <ErrorHandler errorMessage='field_is_required' />}
							</>
						) : null}
					</Col>

					<Col xs={12} md={6} className='d-flex flex-column justify-content-center align-items-center pt-4 mb-1'>
						{!statsLoading ? (
							this.isMatchDatAvailable(statsMatch, statsTeam1, statsTeam2)
						) : (
							<i className={'fa fa-refresh fa-spin text-dark pull-right mt-1'} />
						)}
					</Col>
				</Row>
				<Row>
					{selectedMatch ? (
						<>
							<Col>
								<FormGroup>
									<Label>{t('select_statistics_to_display')}</Label>

									<InstatStatisticsMultiSelect
										t={this.props.t}
										isMulti={false}
										value={instatStatistics ? instatStatistics : {}}
										onInstatStatisticsSelect={this.onInstatStatisticsSelect}
									/>
								</FormGroup>
								{!isValid && instatStatistics === null && <ErrorHandler errorMessage='field_is_required' />}
							</Col>
						</>
					) : null}
				</Row>
				<Row className={'mb-1'}>
					<Col>
						{selectedMatch ? (
							<>
								<Label>{t('instat_team1')}</Label>
								<TeamSelect
									inputId='instat-team1-select'
									isMulti={false}
									withLabel={false}
									t={t}
									value={team1select}
									onSelect={(selection: TeamModel) => this.onTeam1Change(selection)}
									isValid={this.state.isValid}
								/>
								{!isValid && team1select && team1select.id === undefined && <ErrorHandler errorMessage='field_is_required' />}
								<Label>{t('instat_team2')}</Label>
								<TeamSelect
									inputId='instat-team2-select'
									isMulti={false}
									withLabel={false}
									t={t}
									value={team2select}
									onSelect={(selection: TeamModel) => this.onTeam2Change(selection)}
									isValid={this.state.isValid}
								/>
								{!isValid && team2select && team2select.id === undefined && <ErrorHandler errorMessage='field_is_required' />}
							</>
						) : null}
					</Col>
				</Row>
				{team1select && team1select.id && team2select && team2select.id ? (
					<Row>
						<Col>
							<MatchesContainer
								t={this.props.t}
								teamIds={[team1select.id, team2select.id]}
								isMulti={false}
								maxNotStartedMatches={200}
								maxFinishedMatches={200}
								maxInterruptedMatches={20}
								teamNames={[]}
								selectedMatchId={selectedSMPMatch ? selectedSMPMatch.id : null}
								onSelect={this.onMatchFootballApi}
							/>
							{!isValid && selectedSMPMatch === null && <ErrorHandler errorMessage='no_match_selected' />}
						</Col>
					</Row>
				) : null}

				{statsLoading &&
					statsMatch &&
					statsMatch.length === 0 &&
					statsTeam1 &&
					statsTeam1.length === 0 &&
					statsTeam2 &&
					statsTeam2.length === 0 && <ErrorHandler errorMessage='whait_for_the_data' />}
			</div>
		);
	}
}
