import React from 'react';
import Select from 'react-select';
import { elementsToOption, elementsToOptions, selectionsToElements } from './widget-elements-select.helper';

type Properties = {
	onSelect: any;
	value: any;
	t: any;
	isMulti: boolean;
	elements: any;
};

export const ElementsSelect: React.FunctionComponent<Properties> = (props) => {
	const { onSelect, value, t, elements } = props;
	return (
		<>
			{props.isMulti ? (
				<Select
					id='columns-select'
					options={elementsToOptions(elements, t)}
					value={elementsToOptions(value, t)}
					isMulti={true}
					placeholder={t('select')}
					noOptionsMessage={(inputValue) => inputValue && t('no_options')}
					onChange={(selections: any) => onSelect(selectionsToElements(selections))}
				/>
			) : (
				<Select
					id='column-select'
					options={elementsToOptions(elements, t)}
					value={value && value.id ? elementsToOption(value, t) : []}
					isMulti={false}
					placeholder={t('select')}
					isClearable={true}
					noOptionsMessage={(inputValue) => inputValue && t('no_options')}
					onChange={(selection: any) => onSelect(selection && selection.data)}
				/>
			)}
		</>
	);
};
