import React from 'react';
import { FunctionComponent } from 'react';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { SelectMenuOptionType } from '../../../../models/v2/general/select.model';
import { ITelephone } from '../../../Pages/CustomEntities/models/models';
import { customStyles, formatLabel, options } from './country-select.helper';

type Props = {
	invokeFunc: (country: ITelephone) => void;
	selectedIsoCode: string;
};

export const CountrySelect: FunctionComponent<Props> = ({ selectedIsoCode, invokeFunc }) => {
	const { t } = useTranslation();
	const selectedOption = selectedIsoCode ? options.find((option) => option.value === selectedIsoCode) : null;

	const onSelect = (option: SelectMenuOptionType) => invokeFunc(option.data as ITelephone);

	return (
		<Select
			id='country-select-prefix'
			value={selectedOption}
			options={options}
			onChange={onSelect}
			styles={customStyles}
			formatOptionLabel={formatLabel}
			placeholder={t('country_code')}
			noOptionsMessage={(inputValue) => inputValue && t('no_options')}
		/>
	);
};
