import { onlyUnique } from '../../../market-value-select/helper/market-value-select.helper';

export const createTournamentGroupOptions = (response: any) => {
	const tournaments = extractTournaments(response);
	const options: any = [];
	tournaments.forEach((tournament: any) => {
		const tournamentGroups: any = [];
		response.forEach((model: any) => {
			if (model.tournament.name === tournament) {
				tournamentGroups.push({
					label: model.tournament.name + ' - ' + model.season.name,
					value: model.season.id,
					data: model.season,
				});
			}
		});
		const option = { label: tournament, options: [...tournamentGroups] };
		options.push(option);
	});

	return options;
};

export const extractTournaments = (modelList: any) => {
	if (modelList && modelList.length > 0) {
		let tournaments = modelList.map((model: any) => model.tournament.name);

		return onlyUnique(tournaments);
	}

	return [];
};

export const contentToOption = (content: any, tournament: any) => {
	if (content && content.id) {
		return {
			label: tournament,
			value: content.id,
			data: content,
		};
	}

	return {};
};
