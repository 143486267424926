export enum TranslationEntities {
	LINEUP_PLAYER_TYPE = 'lineup_player_type',
	COACH = 'coach',
	VENUE = 'venue',
	TOURNAMENT = 'tournament',
	TEAM = 'team',
	COUNTRY = 'country',
	MATCH_STATUS = 'match_status',
	STAGE = 'stage',
	SEASON = 'season',
	GROUP = 'group',
	PLAYER = 'player',
	REFEREE = 'referee',
	PRESIDENT = 'president',
	CITY = 'city',
	STANDING_RULE = 'standing_rule',
}

export enum entityTypeEnum {
	LINEUP_PLAYER_TYPE = 'LINEUP_PLAYER_TYPE',
	COACH = 'COACH',
	VENUE = 'VENUE',
	TOURNAMENT = 'TOURNAMENT',
	TEAM = 'TEAM',
	COUNTRY = 'COUNTRY',
	MATCH_STATUS = 'MATCH_STATUS',
	STAGE = 'STAGE',
	PLAYER = 'PLAYER',
	REFEREE = 'REFEREE',
	PRESIDENT = 'PRESIDENT',
	CITY = 'CITY',
	STANDING_RULE = 'STANDING_RULE',
	TEAM_SHORT_NAME = 'TEAM_SHORT_NAME',
}
