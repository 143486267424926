import React from 'react';
import { Card, CardBody, CardGroup, Col, Container, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Form, Row } from 'reactstrap';
import './ProjectSelect.scss';
import Project from '../../../models/project/Project';
import {
	clearProjectResourcesLoaded,
	currentProjectSelect,
	projectResourcesRequest,
} from '../../../store/action-creators/ProjectResourcesActionCreators';
import { logout } from '../../../store/action-creators/ProfileActionCreators';
import { ProjectSelectProperties, ProjectSelectState } from './properties/ComponentProps';
import { AppState, store } from '../../../store/store';
import { Unsubscribe } from 'redux';
import { withTranslation } from 'react-i18next';
import { connect } from '../../../store/store.connect';

@connect<ProjectSelectProperties, AppState>(
	(state) => ({
		projects: state.project.projects,
		currentProject: state.project.currentProject,
		profile: state.profile.profile,
		projectResourcesLoaded: state.project.projectResourcesLoaded,
		auth: state.auth,
	}),
	(dispatch) => ({
		selectProject: (project: Project) => dispatch(currentProjectSelect(project)),
		getProjectResources: (project: Project, adminId: string, history: any) => dispatch(projectResourcesRequest(project, adminId, history)),
		logout: () => dispatch(logout()),
		clearProjectResourcesLoaded: () => dispatch(clearProjectResourcesLoaded()),
	}),
)
class ProjectSelect extends React.Component<ProjectSelectProperties, ProjectSelectState> {
	private storeUnsubscribe: Unsubscribe = () => undefined;

	constructor(props: any) {
		super(props);
		this.state = {
			dropdownOpen: false,
			selectedProject: this.props.projects[0],
			isLoading: false,
		};
	}

	componentWillUnmount() {
		this.props.clearProjectResourcesLoaded();
		this.storeUnsubscribe();
	}

	componentDidMount() {
		if (this.props.projects && this.props.projects.length === 1) {
			this.props.selectProject(this.props.projects[0]);
			this.props.getProjectResources(this.props.projects[0], this.props.profile.id, this.props.history);
			this.props.history.push('/dashboard');
		}

		this.storeUnsubscribe = store.subscribe(() => this.redirectToDashboard());
		document.title = this.props.t('project_selection_title');
	}

	dropdownItemSelect = (event: any) => {
		let selectedProject = this.props.projects.filter((project) => project.id === event.target.value)[0];
		this.setState({ ...this.state, selectedProject });
	};

	submit = () => {
		if (!this.state.isLoading && this.state.selectedProject) {
			this.toggleLoading(true);
			this.props.selectProject(this.state.selectedProject);
			this.props.getProjectResources(this.state.selectedProject, this.props.profile.id, this.props.history);
		}
	};

	toggle = () => this.setState((prevState) => ({ dropdownOpen: !prevState.dropdownOpen }));

	toggleLoading = (loading: boolean) => this.setState({ ...this.state, isLoading: loading });

	redirectToDashboard = () => {
		if (this.props.projectResourcesLoaded) {
			this.toggleLoading(false);
			this.props.history.push('/dashboard');
		}
	};

	render() {
		if (!this.props.auth.isAuthenticated) {
			this.props.history.push('/login');
		}

		return (
			<div className='app flex-row align-items-center'>
				<Container>
					<Row className='justify-content-center'>
						<Col md='6'>
							<CardGroup>
								<Card className='p-4'>
									<CardBody>
										<Form>
											<h1>{this.props.t('project_selection_title')}</h1>
											{this.props.projects.length > 0 && (
												<>
													<p className='text-muted'>{this.props.t('select_project_to_use')}</p>
													<Dropdown className='mb-3' isOpen={this.state.dropdownOpen} toggle={this.toggle} data-dropup-auto='false'>
														<DropdownToggle caret data-dropup-auto='false' className={'btn-light'}>
															{this.state.selectedProject && this.state.selectedProject.title}
														</DropdownToggle>
														<DropdownMenu
															modifiers={{
																setMaxHeight: {
																	enabled: true,
																	fn: (data: any) => {
																		return {
																			...data,
																			styles: { ...data.styles, overflow: 'auto', maxHeight: 250 },
																		};
																	},
																},
															}}
														>
															{this.props.projects.map((project, index) => (
																<DropdownItem key={`dropdown-key-${index}`} onClick={this.dropdownItemSelect} value={project.id}>
																	{project.title}
																</DropdownItem>
															))}
														</DropdownMenu>
													</Dropdown>
												</>
											)}
											<Row>
												{this.props.projects.length > 0 && (
													<Col xs='6'>
														<div className={`bg-primary btn ld-ext-right px-4 ${this.state.isLoading ? 'running disabled' : ''}`} onClick={this.submit}>
															{this.props.t('continue')}
															<div className='ld ld-ring ld-spin'></div>
														</div>
													</Col>
												)}
												{this.props.projects.length === 0 && (
													<Col xs='6'>
														<p>{this.props.t('project_selection_hint')}</p>
													</Col>
												)}
												<Col xs='6' className={'text-right'}>
													<div className={`btn btn-outline-danger ld-ext-right px-4`} onClick={this.props.logout}>
														{this.props.t('logout')}
														<div className='ld ld-ring ld-spin'></div>
													</div>
												</Col>
											</Row>
										</Form>
									</CardBody>
								</Card>
							</CardGroup>
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}

export default withTranslation()(ProjectSelect) as React.ComponentType;
