import { ReactPaginateProps } from 'react-paginate';

export const PAGINATE_PROPS: ReactPaginateProps = {
	previousLabel: 'previous',
	nextLabel: 'next',
	breakLabel: '...',
	pageCount: 0,
	breakClassName: 'break-me',
	marginPagesDisplayed: 2,
	pageRangeDisplayed: 5,
	containerClassName: 'smp-pagination',
	activeClassName: 'active',
};
