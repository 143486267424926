export enum MatchStatuses {
	UPCOMING = 'notstarted',
	IN_PROGRESS = 'inprogress',
	FINISHED = 'finished',
	INTERRUPTED = 'interrupted',
}

export type MatchStatusesTypes = MatchStatuses.UPCOMING | MatchStatuses.IN_PROGRESS | MatchStatuses.FINISHED | MatchStatuses.INTERRUPTED;

export enum EventStatuses {
	UPCOMING = 'NOT_STARTED',
	FINISHED = 'FINISHED',
	INTERRUPTED = 'INTERRUPTED',
	CANCELLED = 'CANCELLED',
	UNKNOWN = 'UNKNOWN',
	POSTPONED = 'POSTPONED',
	LIVE = 'LIVE',
}

export type EventStatusesTypes =
	| EventStatuses.UPCOMING
	| EventStatuses.FINISHED
	| EventStatuses.INTERRUPTED
	| EventStatuses.CANCELLED
	| EventStatuses.UNKNOWN
	| EventStatuses.POSTPONED
	| EventStatuses.LIVE;
