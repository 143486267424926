import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import React, { useState } from 'react';
import Project from '../../../../../../../models/project/Project';
import ImagoImageModel from '../../../../../Blocky/blocks/content/imago-image-block/models/imago-image.model';
import { MainMediaContentTypes, MainMediaTypes } from '../../../../../../../constants/content-types';
import ImagoImageListingContainer from '../../../../Media/subcomponents/media-main-image/subcomponents/imago-image-listing/ImagoImageListingContainer';
import { DATA_QA_ATTRIBUTES } from '../../../constants/data-qa.attributes';
import { connect } from 'react-redux';

type Properties = {
	t: any;
	onSelect: (item: any) => void;
	isOpen: boolean;
	toggleModal: (isOpen: boolean) => any;
	project: Project;
};

const ImagoModal: React.FunctionComponent<Properties> = ({ t, toggleModal, isOpen, onSelect, project }) => {
	const [selectedMainMediaItem, setSelectedMainMediaItem] = useState<MainMediaContentTypes | null>(null);
	const [mediaId, setMediaId] = useState<string>('');

	const onContentSelect = async (item: MainMediaContentTypes) => {
		setMediaId(item.id);
		setSelectedMainMediaItem(item);
	};

	return (
		<div id={DATA_QA_ATTRIBUTES.IMAGO_MODAL}>
			<Modal size='xl' isOpen={isOpen}>
				<ModalHeader toggle={() => toggleModal(false)}>{t(`${MainMediaTypes.IMAGO_IMAGE}`)}</ModalHeader>
				<ModalBody>
					<div data-children='.item'>
						<div className='item'>
							<ImagoImageListingContainer
								selectedImagoImage={selectedMainMediaItem as ImagoImageModel}
								currentProject={project ? project : ({} as Project)}
								onImageSelect={onContentSelect}
								t={t}
							/>
						</div>
					</div>
				</ModalBody>
				<ModalFooter>
					<Button id={DATA_QA_ATTRIBUTES.IMAGO_MODAL_CLOSE} color='danger' className='text-light' onClick={() => toggleModal(false)}>
						{t('close')}
					</Button>
					<Button
						disabled={!mediaId}
						id={DATA_QA_ATTRIBUTES.IMAGO_MODAL_SAVE}
						color='primary'
						className={'text-light'}
						onClick={() => onSelect(selectedMainMediaItem)}
					>
						{t('save')}
					</Button>
				</ModalFooter>
			</Modal>
		</div>
	);
};

function mapStateToProps(state: any) {
	return {
		project: state.project.currentProject,
	};
}

export default connect(mapStateToProps)(ImagoModal);
