import YoutubeSportsModel from '../../../../../../models/youtube-sports/youtube-sports.model';
import YoutubeVideoModel from '../../../../../../models/youtube-sports/youtube-video/youtube-video.model';

export const remapYoutubeVideosFromResponse = (response: any) => {
	if (response && Object.entries(response).length > 0) {
		return YoutubeSportsModel.builder()
			.withVideoList(remapYoutubeVideoList(response.items))
			.withNextPageToken(response.nextPageToken)
			.withPrevPageToken(response.prevPageToken)
			.withPageInfo(response.pageInfo)
			.build();
	}

	return {};
};

export const remapYoutubeVideoList = (response: any) => {
	if (response && response.length > 0) {
		return response.map((video: any) => remapYoutubeVideoFromResponse(video));
	}

	return [];
};

export const remapYoutubeVideoFromResponse = (response: any) => {
	if (response && Object.entries(response).length > 0) {
		return YoutubeVideoModel.builder().withId(response.id).withEtag(response.etag).withSnippet(response.snippet).build();
	}

	return {};
};
