import { call, put, takeEvery } from 'redux-saga/effects';
import HttpService from '../../services/rest/HttpService';
import { actionService } from '../../App';
import { toggleLoadingState, toggleModal } from '../action-creators/UiActionCreator';
import { onError } from '../action-creators/GeneralActions';
import {
	returnObjectForFeedsListingReceived,
	FEED_LISTING_REQUEST,
	returnObjectForFeedEntityReceived,
	FEED_ENTITY_REQUEST,
	returnObjectForFeedEntityCreateSuccess,
	returnObjectForFeedEntityCreateFailed,
	FEED_ENTITY_CREATE,
	returnObjectForFeedEntityUpdateSuccess,
	returnObjectForFeedEntityUpdateFailed,
	FEED_ENTITY_UPDATE,
	returnObjectForSourceListingReceived,
	SOURCE_LISTING_REQUEST,
	returnObjectForFeedEntityDeleteSuccess,
	FEED_ENTITY_DELETE,
	TEMPLATE_ENTITY_REQUEST,
	returnObjectForTemplatesListingReceived,
	TEMPLATE_LISTING_REQUEST,
} from '../action-creators/feeds-action-creator';

function* fetchFeedListingFromApi(action: any) {
	let headers = { 'X-Project': action.payload.project.domain, 'X-USER': action.payload.profileId };
	yield put(toggleLoadingState(true));

	try {
		const feed = yield call(HttpService.getFeed, `/feeds?page=${action.payload.page}`, null, headers);
		yield put(returnObjectForFeedsListingReceived(feed));
		yield put(toggleLoadingState(false));
	} catch (error) {
		yield put(onError(error));
	}
}

function* fetchFeedEntityFromApi(action: any) {
	let headers = { 'X-Project': action.payload.project.domain, 'X-USER': action.payload.profileId };

	try {
		const feed = yield call(HttpService.getFeed, `/feeds/${action.payload.id}`, null, headers);
		yield put(returnObjectForFeedEntityReceived(feed));
	} catch (error) {
		yield put(onError(error));
	}
}

function* postFeedEntityToApi(action: any) {
	let headers = { 'X-Project': action.payload.project.domain, 'X-USER': action.payload.profileId };
	let feedResponse: any = {};

	try {
		let feedPayload = action.payload.feed;
		feedResponse = yield call(HttpService.postFeed, '/feeds', feedPayload, headers);
		yield put(returnObjectForFeedEntityCreateSuccess(feedResponse.data.data.id));
	} catch (error) {
		yield put(returnObjectForFeedEntityCreateFailed());
		yield put(onError(error));
		actionService.emitError(error);
	}
}

function* putFeedEntityToApi(action: any) {
	let headers = { 'X-Project': action.payload.project.domain, 'X-USER': action.payload.profileId };

	try {
		let feedPayload = action.payload.feed;
		let id = action.payload.feed.id;
		yield call(HttpService.putFeed, `/feeds/${id}`, feedPayload, headers);
		yield put(returnObjectForFeedEntityUpdateSuccess());
	} catch (error) {
		yield put(returnObjectForFeedEntityUpdateFailed());
		yield put(onError(error));
		actionService.emitError(error);
	}
}

function* fetchSourceListingFromApi(action: any) {
	let headers = { 'X-Project': action.payload.project.domain, 'X-USER': action.payload.profileId };

	try {
		const source = yield call(HttpService.getFeed, `/sources`, null, headers);
		yield put(returnObjectForSourceListingReceived(source));
	} catch (error) {
		yield put(onError(error));
	}
}

function* deleteFeedEntityFromApi(action: any) {
	let headers = { 'X-Project': action.payload.project.domain, 'X-USER': action.payload.profileId };

	try {
		let id = action.payload.id;
		yield call(HttpService.deleteFeed, `/feeds/${id}`, null, headers);
		yield put(returnObjectForFeedEntityDeleteSuccess());
		yield put(toggleModal(false));
	} catch (error) {
		yield put(onError(error));
	}
}

function* fetchTemplateListingFromApi(action: any) {
	let headers = { 'X-Project': action.payload.project.domain, 'X-USER': action.payload.profileId };

	try {
		const templates = yield call(HttpService.getFeed, `/templates?page=${action.payload.page}`, null, headers);
		yield put(returnObjectForTemplatesListingReceived(templates));
		yield put(toggleLoadingState(false));
	} catch (error) {
		yield put(onError(error));
	}
}

function* feedSaga() {
	yield takeEvery(FEED_LISTING_REQUEST, fetchFeedListingFromApi);
	yield takeEvery(FEED_ENTITY_REQUEST, fetchFeedEntityFromApi);
	yield takeEvery(FEED_ENTITY_CREATE, postFeedEntityToApi);
	yield takeEvery(FEED_ENTITY_UPDATE, putFeedEntityToApi);
	yield takeEvery(SOURCE_LISTING_REQUEST, fetchSourceListingFromApi);
	yield takeEvery(FEED_ENTITY_DELETE, deleteFeedEntityFromApi);
	yield takeEvery(TEMPLATE_LISTING_REQUEST, fetchTemplateListingFromApi);
}

export default feedSaga;
