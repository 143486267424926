import { MatchSeasonJson } from './match-season.json';
import MatchSeasonBuilder from './match-season.builder';
import TournamentModel from './match-season-tournament.model';

export default class MatchSeasonModel {
	readonly id: string;
	readonly name: string;
	readonly status: string;
	readonly tournament: TournamentModel | null;

	private constructor(id: string, name: string, status: string, tournament: TournamentModel | null) {
		this.id = id;
		this.name = name;
		this.status = status;
		this.tournament = tournament;
	}

	toJson(): MatchSeasonJson {
		return {
			id: this.id,
			name: this.name,
			status: this.status,
			tournament: this.tournament,
		};
	}

	static fromJson(json: MatchSeasonJson): MatchSeasonModel {
		return new MatchSeasonModel(json.id, json.name, json.status, json.tournament);
	}

	static builder(tournament?: MatchSeasonModel | null): MatchSeasonBuilder {
		return new MatchSeasonBuilder(tournament);
	}
}
