import { EDITOR_BLOCK_TYPE } from '../../services/tags/tags.service';
import { EditorTypes } from '../../views/Partials/Blocky/constants/block.types';
import BlockModel from '../../views/Partials/Blocky/models/block.model';

export const filterEmptyBlocks = (body: BlockModel[]) => {
	if (body && body.length > 0) {
		return body.filter((block: BlockModel) => filterEditorBlocks(block) || filterOtherBlocks(block));
	}
	return [];
};

export const filterEditorBlocks = (block: BlockModel) => {
	if (block.type === EDITOR_BLOCK_TYPE && block.data && block.data.type === 'table') {
		return block.data.content && block.data.content.length > 0;
	}

	return block.type === EDITOR_BLOCK_TYPE && block.data && block.data.content && block.data.content.replace(/(<([^>]+)>)/g, '').length > 0;
};

export const filterOtherBlocks = (block: BlockModel) => {
	if (block.type) {
		return block.type !== EDITOR_BLOCK_TYPE && block.data;
	} else {
		return block.type !== EDITOR_BLOCK_TYPE && block.data && block.data.preview;
	}
};

export const getTotalWordCharCountPerBlock = (block: BlockModel) => {
	if (
		block.type === EDITOR_BLOCK_TYPE &&
		(block.data.type === 'paragraph' || block.data.type === 'quote' || block.data.type === 'heading') &&
		block.data &&
		block.data.content
	) {
		return getWordCharCount(block.data.content);
	} else
		return {
			wordCount: 0,
			charCount: 0,
		};
};

export const getWordCharCount = (content: string) => {
	let data = {
		wordCount: 0,
		charCount: 0,
	};

	if (content) {
		let removedHtmlTags = removeHtmlTags(content);
		let removedNbsp = replaceNbsp(removedHtmlTags);
		let removedEmptySpaces = removeEmptySpaces(removedHtmlTags);

		data.wordCount = getWordCount(removedNbsp);
		data.charCount = getCharCount(removedEmptySpaces);

		return data;
	} else {
		return {
			wordCount: 0,
			charCount: 0,
		};
	}
};

export const removedBrTag = (text: string) => {
	return text.replace(/<br\s*\/?>/g, ' ');
};
export const removeHtmlTags = (text: string) => {
	let removeNewRowSymbols = text.replace(/<br>&nbsp;/g, ' ');
	let textWithoutBrTag = removedBrTag(removeNewRowSymbols);
	return textWithoutBrTag.replace(/(<([^>]+)>)/g, '');
};

export const getCharCount = (text: string) => {
	return text.length;
};

export const getWordCount = (text: string) => {
	let words = text.split(/\s+|<br\s*\/?>/);
	var wordCount = 0;
	for (let i = 0; i < words.length; i++) {
		if (words[i] !== '') {
			wordCount++;
		}
	}
	return wordCount;
};

export const replaceNbsp = (str: string) => {
	return str.replace(/&nbsp;/g, '');
};

export const removeEmptySpaces = (str: string) => {
	return str.replace(/\s/g, '').replace(/&nbsp;/g, '');
};

/**
 * Removes the data.tags field from paragraph blocks when preparing the article/gallery/video/wiki body for saving.
 */
export const removeTagsFromParagraphBlocks = (body: BlockModel[]): BlockModel[] => {
	return body.map((block: BlockModel) => {
		if (block.data && block.data.tags && block.data.type === EditorTypes.paragraph) {
			const clonedData = { ...block.data };

			delete clonedData.tags;

			return new BlockModel(block.id, clonedData, block.type);
		}

		return block;
	});
};
