import * as React from 'react';
import Article from '../../../../../../../../models/article/Article';
import Project from '../../../../../../../../models/project/Project';
import HttpService from '../../../../../../../../services/rest/HttpService';
import { contentRemappers, relatedPath } from '../../helpers/RowsContainerHelper';
import moment from 'moment';
import Related from '../../../../../../../../models/related/Related';
import {
	ARTICLE_ENTITY_UPDATE_SUCCESS,
	articleCopyRelatedReceived,
} from '../../../../../../../../store/action-creators/ArticleActionCreator';
import { updateTempArticle } from '../../../../../../../../store/action-creators/ArticleTempActionCreator';
import { connect } from 'react-redux';
import TranslationGroupModel from '../../../../../../../../models/translation-groups/translation-group-model';
import { Button } from 'reactstrap';
import { SaveContentModal } from '../../../../../../Sidebar/translation-groups/subcomponents/save-content-modal';
import { actionService, multiLingualService } from '../../../../../../../../App';
import { Subscription } from 'rxjs';
import { videoCopyRelatedReceived, VIDEO_UPDATE_SUCCESS } from '../../../../../../../../store/action-creators/VideoActionCreator';
import { galleryCopyRelatedReceived, GALLERY_UPDATE_SUCCESS } from '../../../../../../../../store/action-creators/GalleryActionCreator';
import Video from '../../../../../../../../models/video/Video';
import { updateTempVideo } from '../../../../../../../../store/action-creators/VideoTempActionCreator';
import { updateTempGallery } from '../../../../../../../../store/action-creators/GalleryTempActionCreator';
import Gallery from '../../../../../../../../models/gallery/Gallery';
import SportsTypesModel from '../../../../../../../../models/v2/sports-types/sports-types.model';

type Properties = {
	display: boolean;
	t: any;
	analytics: any;
	content: any;
	history: any;
	createContentPath: string;
	contentType: string;
	project: Project;
	languageCode: string;
	translationGroup: TranslationGroupModel;
	showInSidebar?: boolean;
	toggleModal?: (toggle: boolean) => any;
	sports?: SportsTypesModel[];
};

class RowCreateTranslationButton extends React.Component<Properties, any> {
	private actionServiceSubject = new Subscription();

	constructor(props: any) {
		super(props);
		this.state = {
			isOpenModal: false,
		};
		this.registerActionEventListener();
	}

	registerActionEventListener() {
		this.actionServiceSubject = actionService.onActionReceived().subscribe((action: string) => {
			if (
				(action === ARTICLE_ENTITY_UPDATE_SUCCESS || action === VIDEO_UPDATE_SUCCESS || action === GALLERY_UPDATE_SUCCESS) &&
				this.state.isOpenModal
			) {
				this.onCopy();
			}
		});
	}

	componentWillUnmount() {
		this.actionServiceSubject.unsubscribe();
	}

	fetchRelated = async () => {
		let { content } = this.props;
		const headers = { Project: this.props.project.domain };

		return HttpService.get(`/v2/${relatedPath[this.props.contentType]}/${content.id}/related`, null, headers);
	};

	fetchContent = async (id: string, type: string) => {
		if (type === 'article') {
			return HttpService.getArticle(id);
		}

		if (type === 'video') {
			return HttpService.getVideo(id);
		}

		if (type === 'gallery') {
			return HttpService.getGallery(id);
		}
	};

	onCopy = async () => {
		let { t, analytics, content, createContentPath, history, contentType, languageCode, translationGroup, sports } = this.props;
		analytics.sendEvent('Click', 'List Page', 'new "Translation" Button');
		const contentResponse = await this.fetchContent(content.id, contentType);
		const dateNow = moment().utc().format('YYYY-MM-DDTHH:mm:ssZ');
		content = contentRemappers(contentResponse.data.data, contentType, dateNow);
		this.props[`${contentType}Content`](
			multiLingualService.remapContentTranslationToTempCreate(content, contentType, languageCode, translationGroup, t),
		);

		const related = await this.fetchRelated();
		this.props[`${contentType}RelatedReceived`](related, sports);
		history.push(createContentPath);
	};

	onCopyContentAfterConfirmation = (toggleModal: boolean) => {
		this.setState({ ...this.state, isOpenModal: toggleModal });
	};

	toggleModal = (toggle: boolean) => {
		this.setState({ ...this.state, isOpenModal: toggle });
	};

	render() {
		const { display, showInSidebar, contentType, content, t } = this.props;

		if (display && !showInSidebar) {
			return (
				<div className='cursor-pointer' id={`create-translation-article-btn-${content.id}`} onClick={this.onCopy}>
					<i className='fa fa-plus p-1' />
				</div>
			);
		}

		if (showInSidebar) {
			return (
				<>
					<Button
						size='sm'
						color='primary'
						id={`${t(contentType)}-add-translation-btn`}
						className={'ml-auto text-nowrap'}
						onClick={() => this.onCopyContentAfterConfirmation(true)}
					>
						<i className='fa fa-plus'></i> {t('add_translation')}
					</Button>

					{this.state.isOpenModal && (
						<SaveContentModal
							t={t}
							analytics={this.props.analytics}
							type={contentType}
							toggleModal={this.toggleModal}
							isToggledModal={this.state.isOpenModal}
						/>
					)}
				</>
			);
		}

		return null;
	}
}

function mapStateToProps(state: any) {
	return {
		project: state.project.currentProject,
		sports: state.sports.sportsTypes,
	};
}

function mapDispatchToProps(dispatch: any) {
	return {
		articleRelatedReceived: (content: Related[], sports: any) => dispatch(articleCopyRelatedReceived(content, sports)),
		articleContent: (content: Article) => dispatch(updateTempArticle(content)),
		videoRelatedReceived: (content: Related[], sports: any) => dispatch(videoCopyRelatedReceived(content, sports)),
		videoContent: (content: Video) => dispatch(updateTempVideo(content)),
		galleryRelatedReceived: (content: Related[], sports: any) => dispatch(galleryCopyRelatedReceived(content, sports)),
		galleryContent: (content: Gallery) => dispatch(updateTempGallery(content)),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(RowCreateTranslationButton) as React.ComponentType<any>;
