import { TypedJSON } from 'typedjson';
import TennisCompetitionSeasonModel from '../../../../../../models/v2/tennis-competition-season/entity/tennis-competition-season.model';
import TennisSeasonStagesModel from '../../../../../../models/v2/tennis-competition-season/entity/tennis-season-stages.model';

export const responseToSeasonModel = (response: any) => {
	const serializer = new TypedJSON(TennisCompetitionSeasonModel);
	const remmapedSeason = serializer.parse(response) as TennisCompetitionSeasonModel;

	return remmapedSeason;
};

export const responseToStagesModel = (response: any) => {
	const serializer = new TypedJSON(TennisSeasonStagesModel);
	const remmapedStage = serializer.parse(response) as TennisSeasonStagesModel;

	return remmapedStage;
};

export const seasonsToOptions = (seasons: TennisCompetitionSeasonModel[]) => {
	if (seasons && seasons.length > 0) {
		return seasons.map((season) => {
			return seasonToOption(season);
		});
	}

	return [];
};

export const seasonToOption = (season: TennisCompetitionSeasonModel) => {
	if (season) {
		return {
			value: season.value,
			label: season.value,
			data: season,
			type: 'season',
		};
	} else {
		return {};
	}
};
