import React from 'react';
import { Badge, Col, Row } from 'reactstrap';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import FansUnitedWidgetModel from '../../models/fans-united-widget.model';

type Properties = {
	widgetEitherOrGameManager: FansUnitedWidgetModel;
};

export const WidgetEitherOrGameManagerView: React.FunctionComponent<Properties> = ({ widgetEitherOrGameManager }) => {
	const [t] = useTranslation();

	if (widgetEitherOrGameManager && widgetEitherOrGameManager.eitherOrGame && widgetEitherOrGameManager.eitherOrGame.id) {
		return (
			<div data-qa='widget-either-or-game-view'>
				<Row className='d-flex p-2  align-items-center mb-1'>
					<Col className='ml-2'>
						<strong>
							{moment(widgetEitherOrGameManager.eitherOrGame.created_at ? widgetEitherOrGameManager.eitherOrGame.created_at : '').format(
								'MMM Do YYYY, HH:mm',
							)}
						</strong>
					</Col>
					<Col className='mr-1'>
						<strong>{widgetEitherOrGameManager.eitherOrGame.title}</strong>
					</Col>
				</Row>
				<Row className='px-4'>
					<Col>
						<hr />
					</Col>
				</Row>
				<Row className='px-4 pt-1'>
					{widgetEitherOrGameManager.eitherOrGame && widgetEitherOrGameManager.eitherOrGame.status && (
						<Col className='text-left'>
							<span>{t('status')}: </span>
							<Badge
								color={`${widgetEitherOrGameManager.eitherOrGame.status === 'ACTIVE' ? 'success' : 'secondary'} `}
							>{`${widgetEitherOrGameManager.eitherOrGame.status}`}</Badge>
						</Col>
					)}
				</Row>
				<Row className='px-4 pt-1'>
					{widgetEitherOrGameManager.eitherOrGame && widgetEitherOrGameManager.eitherOrGame.description && (
						<Col className='text-left'>
							<span>{t('description')}: </span>
							<strong>{t(widgetEitherOrGameManager.eitherOrGame.description)}</strong>
						</Col>
					)}
				</Row>
				<Row className='px-4 pt-1'>
					{widgetEitherOrGameManager.eitherOrGame && widgetEitherOrGameManager.eitherOrGame.type && (
						<Col className='text-left'>
							<span>{t('type')}: </span>
							<strong>{widgetEitherOrGameManager.eitherOrGame.type}</strong>
						</Col>
					)}
				</Row>
				<Row className='px-4 pt-1'>
					{widgetEitherOrGameManager.eitherOrGame && widgetEitherOrGameManager.eitherOrGame.winning_condition && (
						<Col className='text-left'>
							<span>{t('winning_condition')}: </span>
							<strong>{widgetEitherOrGameManager.eitherOrGame.winning_condition}</strong>
						</Col>
					)}
				</Row>
			</div>
		);
	}

	return null;
};
