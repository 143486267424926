export enum DATA_QA {
	RIGHT_SECTION = 'multisport-widget-right-section',
	MULTISPORT_WRAPPER = 'multisport-widget-wrapper',
	EVENTS_LIST_WRAPPER = 'multisport-widget-events-list-wrapper',
	EVENTS_PREVIEW_WRAPPER = 'multisport-widget-events-preview-wrapper',
	CONFIGURE_DATE_WRAPPER = 'multisport-widget-configure-date-wrapper',
	SPORT_SECTION_EVENTS = 'multisport-widget-sport-section-events',
	SPORT_SECTION_HEADER = 'multisport-widget-sport-data-header',
	SPORT_SECTION_TOURNAMENT_HEADER = 'multisport-widget-tournament-data-header',
	SPORT_SECTION_TOURNAMENT_DATA = 'multisport-widget-tournament-data',
	SPORT_SECTION_TOURNAMENT_DATA_WRAPPER = 'multisport-widget-tournament-data-wrapper',
	EVENTS_LIST_GAME_TIME = 'multisport-widget-events-list-game-date',
	EVENTS_LIST_GAME_TITLE = 'multisport-widget-events-list-game-title',
	EVENTS_LIST_GAME_ICONS = 'multisport-widget-events-list-game-icons-wrapper',
	PREVIEW_TOGGLER = 'multisport-widget-preview-toggler-title-wrapper',
	PREVIEW_TOURNAMENT_DATA = 'multisport-widget-preview-tournament-data-wrapper',
	PREVIEW_TOURNAMENT_HEADER = 'multisport-widget-preview-tournament-header',
	PREVIEW_GAME_HEADER = 'multisport-widget-preview-game-header',
	FOOTER_ACTIONS_WRAPPER = 'multisport-widget-footer-actions-wrapper',
	NO_EVENTS_INFO_WRAPPER = 'multisport-widget-no-events-info-wrapper',
}
