import { ArticleAutoTaggingScope } from '../../models/article/ArticleJson';
import BlockModel from '../../views/Partials/Blocky/models/block.model';

export const SET_BLOCKS_TO_COPY = '[ACTION] SET_BLOCKS_TO_COPY';
export const BLOCKS_ARE_COPIED = '[ACTION] BLOCKS_ARE_COPIED';
export const INITILIZE_AUTOLINKING = '[ACTION] INITILIZE_AUTOLINKING';
export const RESET_AUTOLINKING = '[ACTION] RESET_AUTOLINKING';
export const INITIALIZE_UNDO_REDO_BLOCKY = '[ACTION] INITIALIZE_UNDO_REDO_BLOCKY';
export const UPDATE_PRESENT_UNDO_REDO_BLOCKY = '[ACTION] UPDATE_PRESENT_UNDO_REDO_BLOCKY';
export const UPDATE_UNDO_REDO_BLOCKY = '[ACTION] UPDATE_UNDO_REDO_BLOCKY';
export const RESET_UNDO_REDO_BLOCKY = '[ACTION] RESET_UNDO_REDO_BLOCKY';
export const UNDO_BLOCKY = '[ACTION] UNDO_BLOCKY';
export const REDO_BLOCKY = '[ACTION] REDO_BLOCKY';
export const SET_AUTO_TAGGING_SCOPE = '[ACTION] SET_AUTO_TAGGING_SCOPE';

export const setBlocksToCopy = (blocks: BlockModel[]) => ({
	type: SET_BLOCKS_TO_COPY,
	payload: blocks,
});

export const blocksCopiedToState = (blocksCopiedToStateFlag: boolean) => ({
	type: BLOCKS_ARE_COPIED,
	payload: blocksCopiedToStateFlag,
});

export const initializeAutolinking = (placeholderName: string) => ({
	type: INITILIZE_AUTOLINKING,
	payload: placeholderName,
});

export function initializeBlockyUndoRedo(blockArray: BlockModel[]) {
	return {
		type: INITIALIZE_UNDO_REDO_BLOCKY,
		payload: blockArray,
	};
}

export function updateBlockyUndoRedoPresent(blockArray: BlockModel[]) {
	return {
		type: UPDATE_PRESENT_UNDO_REDO_BLOCKY,
		payload: blockArray,
	};
}

export function updateBlockyUndoRedo(newBlockArray: BlockModel[], oldBlockArray: BlockModel[], maximumRedoUndoStates: number) {
	return {
		type: UPDATE_UNDO_REDO_BLOCKY,
		payload: {
			newBlockArray,
			oldBlockArray,
		},
		maximumRedoUndoStates,
	};
}

export function undoBlocky(maximumRedoUndoStates: number) {
	return {
		type: UNDO_BLOCKY,
		maximumRedoUndoStates,
	};
}

export function redoBlocky(maximumRedoUndoStates: number) {
	return {
		type: REDO_BLOCKY,
		maximumRedoUndoStates,
	};
}

export function resetUndoRedoBlocky() {
	return {
		type: RESET_UNDO_REDO_BLOCKY,
	};
}

export const resetAutolinking = () => ({
	type: RESET_AUTOLINKING,
});

export const setAutoTaggingScope = (autoTaggingScope: ArticleAutoTaggingScope) => ({
	type: SET_AUTO_TAGGING_SCOPE,
	payload: autoTaggingScope,
});
