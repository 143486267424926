export const Widget1x2Market = (t: any) => [
	{ value: 'full-time', label: t('ft'), name: 'FT_1X2', default: true },
	{ value: 'half-time', label: t('ht'), name: 'HT_1X2', default: false },
];

export const WidgetCorrectScoreMarket = (t: any) => [
	{ value: 'correct-score', label: t('correct_score'), name: 'CORRECT_SCORE', default: true },
	{ value: 'correct-score-advanced', label: t('correct_score_advanced'), name: 'CORRECT_SCORE_ADVANCED', default: false },
	{ value: 'correct-score-ht', label: t('correct_score_ht'), name: 'CORRECT_SCORE_HT', default: false },
];

export const WidgetPlayerPredictions = (t: any) => [
	{ value: 'player-to-score', label: t('player_to_score'), name: 'PLAYER_SCORE', default: true },
	{
		value: 'player-to-score-first-goal',
		label: t('player_to_score_first_goal'),
		name: 'PLAYER_SCORE_FIRST_GOAL',
		default: true,
	},
	{ value: 'player-to-score-twice', label: t('player_to_score_twice'), name: 'PLAYER_SCORE_TWICE', default: false },
	{
		value: 'player-to-score-hattrick',
		label: t('player_to_score_hattrick'),
		name: 'PLAYER_SCORE_HATTRICK',
		default: false,
	},
	{ value: 'player-yellow-card', label: t('player_yellow_card'), name: 'PLAYER_YELLOW_CARD', default: false },
	{ value: 'player-red-card', label: t('player_red_card'), name: 'PLAYER_RED_CARD', default: false },
];

export const WidgetTeamPredictions = (t: any) => [
	{ value: 'both-teams-score', label: t('both_teams_score'), name: 'BOTH_TEAMS_SCORE', default: true },
	{ value: 'penalty-match', label: t('penalty_match'), name: 'PENALTY_MATCH', default: true },
	{ value: 'red-card-match', label: t('red_card_match'), name: 'RED_CARD_MATCH', default: false },
	{ value: 'over-0.5-goals', label: t('0_5_goals'), name: 'OVER_GOALS_0_5', default: false },
	{ value: 'over-1.5-goals', label: t('1_5_goals'), name: 'OVER_GOALS_1_5', default: false },
	{ value: 'over-2.5-goals', label: t('2_5_goals'), name: 'OVER_GOALS_2_5', default: false },
	{ value: 'over-3.5-goals', label: t('3_5_goals'), name: 'OVER_GOALS_3_5', default: false },
	{ value: 'over-4.5-goals', label: t('4_5_goals'), name: 'OVER_GOALS_4_5', default: false },
	{ value: 'over-5.5-goals', label: t('5_5_goals'), name: 'OVER_GOALS_5_5', default: false },
	{ value: 'over-6.5-goals', label: t('6_5_goals'), name: 'OVER_GOALS_6_5', default: false },
	{ value: 'over-6.5-corners', label: t('6_5_corners'), name: 'OVER_CORNERS_6_5', default: false },
	{ value: 'over-7.5-corners', label: t('7_5_corners'), name: 'OVER_CORNERS_7_5', default: false },
	{ value: 'over-8.5-corners', label: t('8_5_corners'), name: 'OVER_CORNERS_8_5', default: false },
	{ value: 'over-9.5-corners', label: t('9_5_corners'), name: 'OVER_CORNERS_9_5', default: false },
	{ value: 'over-10.5-corners', label: t('10_5_corners'), name: 'OVER_CORNERS_10_5', default: false },
	{ value: 'over-11.5-corners', label: t('11_5_corners'), name: 'OVER_CORNERS_11_5', default: false },
	{ value: 'over-12.5-corners', label: t('12_5_corners'), name: 'OVER_CORNERS_12_5', default: false },
	{ value: 'over-13.5-corners', label: t('13_5_corners'), name: 'OVER_CORNERS_13_5', default: false },
];

export const WidgetDoubleChance = (t: any) => [
	{
		value: 'double-chance',
		label: t('double_chance'),
		name: 'DOUBLE_CHANCE',
		default: true,
	},
];

export const WidgetHtFt = (t: any) => [{ value: 'ht-ft', label: t('ht_ft'), name: 'HT_FT', default: true }];
