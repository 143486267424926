import React from 'react';
import moment from 'moment';
import { noPreviewImage } from '../../../../../../constants/resource-constants';

export type Properties = {
	preview: any;
	isLoading: boolean;
	t: any;
};

export const GalleryView: React.FunctionComponent<Properties> = ({ preview, isLoading, t }) => {
	return (
		<div className={isLoading ? 'loading-overlay' : ''}>
			<div className='block-content'>
				<div className='d-flex content-container'>
					<div className='position-relative'>
						<img
							className='content-image img-fluid mb-2 mb-md-0 rounded'
							src={preview.mainImage ? preview.mainImage.urls.uploaded.gallery : noPreviewImage}
							alt={preview.mainImage && preview.mainImage.urls.uploaded.gallery}
						/>
						<div className='gallery-number-images position-absolute'>
							<i className='fa fa-camera'></i> {preview.items && preview.items.length}
						</div>
					</div>
					<div className='gallery-title text-left w-100'>
						<h4 className='m-0 p-0'> {preview.title} </h4>
						{preview.subtitle && (
							<p>
								<small>{preview.subtitle}</small>
							</p>
						)}
						<p>
							<small className={'mb-2'}>{moment(preview.publishedAt).format('DD MMMM YYYY, HH:mm')}</small>
						</p>
						<div className='mt-3'>
							<span>{t('image_width')}:</span>
							<strong className='ml-2'>{preview.width}</strong>
						</div>
						<div className='mt-1'>
							<span>{t('image_alignment')}:</span>
							<strong className='ml-2'>{t(preview.alignment)}</strong>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
