import React from 'react';
import { blockManagementService, blockWrapperService } from '../../../../subcomponents/blocky.component';
import BlockValidation from '../../../../helpers/block-validation.helper';
import BlockModel from '../../../../models/block.model';
import ErrorHandler from '../../../../partials/error/error-handler-component';
import HttpService from '../../../../../../../services/rest/HttpService';
import { featuresService } from '../../../../../../../App';
import { FeatureTypes } from '../../../../../../../services/feature-service/features.enum';
import GBVisionAudioModel from '../../../../../../../models/v2/gb-vision-audio/gb-vision-audio-model';
import { responseToModelGBVision } from '../../../../../../../models/v2/gb-vision-audio/gb-vision-audio.mapper';
import { GBVisionAudioContainer } from './gb-vision-audio-rows/gb-vision-audios-container';
import { ContentTypes } from '../../../../constants/block.types';

export type Properties = {
	block: BlockModel;
	t: any;
	blocks: BlockModel[];
	inBlocky: boolean;
};

export type State = {
	selectedAudio: GBVisionAudioModel;
	isValid: boolean;
	audios: GBVisionAudioModel[] | null;
	toggleLoadingState: boolean;
	shouldRequest: boolean;
};

class GBVisionAudioEdit extends React.Component<Properties, State> {
	private audioConfig = featuresService.getFeatureConfig(FeatureTypes.GB_VISION_INTEGRATION);

	constructor(props: Properties) {
		super(props);
		this.state = {
			selectedAudio: props.block && props.block.data && props.block.data.id ? props.block.data.preview.audio : new GBVisionAudioModel(),
			isValid: true,
			audios: null,
			toggleLoadingState: false,
			shouldRequest: false,
		};
	}

	componentDidMount(): void {
		blockWrapperService.registerOnSaveFunc({ blockId: this.props.block.id, func: this.onSave });
		this.getGBVisionAudios();
	}

	toggleLoadingState = (loading: boolean) => this.setState((state: State) => ({ ...state, toggleLoadingState: loading }));

	getGBVisionAudios = () => {
		this.toggleLoadingState(true);
		if (this.audioConfig && this.audioConfig.url) {
			HttpService.getGBVisionAudios(this.audioConfig.url)
				.then((response: any) => {
					const responseData = response.data;
					const mappedData = responseData.map((item: any) => responseToModelGBVision(item)) as GBVisionAudioModel[];
					this.setState({ ...this.state, audios: mappedData });
					this.toggleLoadingState(false);
				})
				.catch(() => this.toggleLoadingState(false));
		}
	};

	onSave = (index: number) => {
		const { selectedAudio } = this.state;
		const data = { gbVisionAudio: selectedAudio };
		const validation = new BlockValidation();
		const isValid = validation.validate(this.props.block, data);
		this.setState({ ...this.state, isValid });

		if (isValid) {
			const block = Object.assign({}, this.props.block);
			block.data.id = selectedAudio.id;
			block.data.config = { audio: selectedAudio };
			block.data.preview = { audio: selectedAudio, type: ContentTypes.gbVision };
			blockManagementService.blockUpdate(block, index);
		} else {
			return isValid;
		}
	};

	selectAudio = (audio: GBVisionAudioModel) => {
		this.setState({ ...this.state, selectedAudio: audio });
	};

	componentWillUnmount(): void {
		blockWrapperService.unregisterOnSaveFunc(this.props.block.id);
	}

	render() {
		const { toggleLoadingState, audios, selectedAudio, isValid } = this.state;
		const { inBlocky, t } = this.props;

		return (
			<>
				<div className={`${toggleLoadingState ? 'loading-overlay rounded opacity-06' : ''} py-2`}>
					{audios && audios.length > 0 ? (
						<div className='pb-2'>
							<div className='bg-white rounded'>
								<GBVisionAudioContainer
									t={t}
									onSelect={this.selectAudio}
									audios={audios}
									toggleLoadingState={toggleLoadingState}
									selectedAudio={selectedAudio}
								/>
							</div>
						</div>
					) : (
						<div className='text-center'>{t('no_data')}</div>
					)}
				</div>
				{inBlocky && (
					<div className='p-1'>
						{!isValid && selectedAudio && (selectedAudio.home === undefined || selectedAudio.home === null) && (
							<ErrorHandler t={t} errorMessage='no_selected_audio' />
						)}
					</div>
				)}
			</>
		);
	}
}

export default GBVisionAudioEdit;
