import LinkBlockBuilder from './link-block.builder';
import { LinkBlockJson } from './link-block.json';

export default class LinkBlockModel {
	readonly content: string;
	readonly link: string;
	readonly linkType: string;
	readonly open_type: string;
	readonly text: string;

	private constructor(content: string, link: string, linkType: string, open_type: string, text: string) {
		this.content = content;
		this.link = link;
		this.linkType = linkType;
		this.open_type = open_type;
		this.text = text;
	}

	toJson(): LinkBlockJson {
		return {
			content: this.content,
			link: this.link,
			linkType: this.linkType,
			open_type: this.open_type,
			text: this.text,
		};
	}

	static fromJson(json: LinkBlockJson): LinkBlockModel {
		return new LinkBlockModel(json.content, json.link, json.linkType, json.open_type, json.text);
	}

	static builder(linkBlock?: LinkBlockJson): LinkBlockBuilder {
		return new LinkBlockBuilder(linkBlock);
	}
}
