import { StatisticJson } from './statistic.json';
import Statistic from './statistic';
import { StatisticRequestJson } from './statistic-request.json';

export default class StatisticBuilder {
	private json: StatisticJson;

	constructor(statistic?: Statistic | StatisticJson) {
		if (statistic && statistic instanceof Statistic) {
			this.json = statistic.toJSON();
		} else if (statistic) {
			this.json = statistic;
		} else {
			this.json = {} as StatisticJson;
		}
	}

	withDate(date: string): StatisticBuilder {
		this.json.date = date;

		return this;
	}

	withCount(count: number): StatisticBuilder {
		this.json.count = count;

		return this;
	}

	withType(type: string): StatisticBuilder {
		this.json.type = type;

		return this;
	}

	build(): Statistic {
		return Statistic.fromJSON(this.json);
	}

	toRequestJson(): StatisticRequestJson {
		let json = {} as StatisticRequestJson;
		json.date = this.json.date;
		json.count = this.json.count;
		json.type = this.json.type;

		return json;
	}
}
