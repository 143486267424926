import React from 'react';
import { Col, Row } from 'reactstrap';
import moment from 'moment';
import { assetsPlaceholder } from '../../../../../../../../../constants/assetsPlaceholder';
import { imagePlaceHolder } from '../../../../../../../../../constants/resource-constants';
import { generateRefreshTimeOptionViewText } from '../../../../../partials/refresh-time-input/helper';
import OddsModelV2 from '../../../models/odds-widget.model';
import { useTranslation } from 'react-i18next';

type Properties = {
	preview: OddsModelV2;
	displayOddsLabel?: boolean;
};

export const OddsV2ViewComponent: React.FunctionComponent<Properties> = ({ preview, displayOddsLabel }) => {
	const [t] = useTranslation();

	if (preview && preview.match && preview.match.id) {
		return (
			<div>
				{displayOddsLabel && (
					<Row className='text-left pt-2'>
						<Col>
							<h6>{t('odds_widget_for_match')}</h6>
						</Col>
					</Row>
				)}
				<Row className={`${displayOddsLabel ? 'pl-4 pr-4' : 'pt-2 pl-4 pr-4'}`}>
					<Col className='text-left'>
						<Row className='mb-1'>
							<Col>
								<div className='mb-1 font-weight-bold d-inline-block mr-5'>
									{preview.match.startTime ? moment(preview.match.startTime).format('MMM Do YYYY, HH:mm') : ''}
								</div>
								<div className='d-inline-block'>
									<strong className='p-1'>{preview.match.homeTeam ? preview.match.homeTeam.name : ''}</strong>
									<img
										height='40'
										alt={preview.match.homeTeam ? preview.match.homeTeam.name : ''}
										src={
											preview.match.homeTeam.urlLogo && preview.match.homeTeam.urlLogo.length > 0
												? preview.match.homeTeam.urlLogo
												: assetsPlaceholder.team
										}
										className='h-40 mx-1'
									/>
								</div>
								<div className='d-inline-block font-weight-bold'>:</div>
								<div className='d-inline-block'>
									<img
										height='40'
										alt={preview.match.awayTeam ? preview.match.awayTeam.name : ''}
										src={
											preview.match.awayTeam.urlLogo && preview.match.awayTeam.urlLogo.length > 0
												? preview.match.awayTeam.urlLogo
												: assetsPlaceholder.team
										}
										className='h-40 mx-1'
									/>
									<strong className='p-1'>{preview.match.awayTeam ? preview.match.awayTeam.name : ''}</strong>
								</div>
							</Col>
						</Row>
						<Row>
							<Col>
								<hr />
							</Col>
						</Row>
						<Row className='mb-2'>
							<Col>
								{t('show')}:
								{preview.bookmakers &&
									preview.bookmakers.length > 0 &&
									preview.bookmakers.map((bookmaker) => (
										<img
											key={bookmaker.id}
											height='20'
											alt={bookmaker.name}
											className='h-20 mx-1'
											src={bookmaker.assets[0] ? bookmaker.assets[0].logo : imagePlaceHolder}
											title={bookmaker.name}
										/>
									))}
							</Col>
						</Row>
						{preview.market && preview.market.name && preview.market.name.length > 0 && (
							<Row>
								<Col className='text-left'>
									<span>{t('default_market')}: </span>
									<strong>{t(preview.market.name)}</strong>
								</Col>
							</Row>
						)}
						{preview.marketValueType && preview.marketValueType.name && preview.marketValueType.name.length > 0 && (
							<Row>
								<Col className='text-left'>
									<span>{t('market_value_type')}: </span>
									<strong>{t(preview.marketValueType.name)}</strong>
								</Col>
							</Row>
						)}
					</Col>
				</Row>
				{generateRefreshTimeOptionViewText(preview.refreshTime)}
			</div>
		);
	}

	return null;
};
