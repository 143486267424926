import React, { FunctionComponent, useEffect, useState } from 'react';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import { DATA_QA_ATTRIBUTES } from '../../constants/data-qa';
import { store } from '../../../../../../store/store';
import HttpService from '../../../../../../services/rest/HttpService';
import {
	extractActionForUpdatingSportsConnections,
	extractRelatedPropertiesNameByUrl,
	filterReduxRelatedProperties,
	generateCustomOptionWithImage,
} from '../../helpers/utils';
import { relatedConstants } from '../../../../../../constants/related.constants';
import { connect } from 'react-redux';
import { ValueType } from 'react-select/src/types';
import {
	extractSeasonModelByReduxStoredProperty,
	extractTournamentModelByReduxStoredProperty,
	generateSeasonAndTournamentForRedux,
	seasonsToOptions,
	tournamentToOptions,
} from '../../helpers/season-select';
import Related from '../../../../../../models/related/Related';
import SeasonResponseModel from '../../../../../../models/v2/Season/Entity/response-season.model';
import { responseToResponseTournamentModel } from '../../../../../../models/v2/Tournament/Entity/response-tournament.mapper';
import { responseToResponseSeasonModel } from '../../../../../../models/v2/Season/Entity/response-season.mapper';
import TournamentResponseModel from '../../../../../../models/v2/Tournament/Entity/response-tournament.model';
import debounce from 'lodash.debounce';
import { sportsConnectionColorStyles } from '../../constants/styles';
import SportsTypesModel from '../../../../../../models/v2/sports-types/sports-types.model';

type Properties = {
	t: any;
	reduxSportConnections: Related[] | null;
	updateSportsConnection: Function;
	selectedSport: SportsTypesModel;
};

const TournamentSelect: FunctionComponent<Properties> = ({ t, reduxSportConnections, updateSportsConnection, selectedSport }) => {
	const filteredData = filterReduxRelatedProperties(selectedSport, reduxSportConnections, [relatedConstants.types.season]);
	const savedSeasonWithTournamentRedux =
		filteredData && filteredData.filtered && filteredData.filtered.length > 0 ? filteredData.filtered[0] : null;
	const [loadedSeasons, setLoadedSeasons] = useState<SeasonResponseModel[] | null>(null);
	const selectedTournamentInitialState = extractTournamentModelByReduxStoredProperty(savedSeasonWithTournamentRedux);
	const selectedSeasonInitialState = extractSeasonModelByReduxStoredProperty(savedSeasonWithTournamentRedux);

	const [selectedTournament, setSelectedTournament] = useState<TournamentResponseModel | null>(selectedTournamentInitialState);
	const [selectedSeason, setSelectedSeason] = useState<SeasonResponseModel | null>(selectedSeasonInitialState);

	useEffect(() => {
		if (selectedTournamentInitialState && selectedSeasonInitialState) {
			setSelectedTournament(selectedTournamentInitialState);
			setSelectedSeason(selectedSeasonInitialState);
		}
	}, [selectedTournamentInitialState && selectedTournamentInitialState.id, selectedSeasonInitialState && selectedSeasonInitialState.id]);

	useEffect(() => {
		selectedTournament &&
			selectedTournament.id &&
			HttpService.getTournamentById(selectedTournament.id).then((response: any) => {
				const seasonsResponseData = response.data.seasons;
				const loadedSeasonsToModel: SeasonResponseModel[] = seasonsResponseData.map((season: any) => responseToResponseSeasonModel(season));
				setLoadedSeasons(loadedSeasonsToModel);
			});
	}, [selectedTournament]);

	const loadTournamentsOnType = (input: string, callback: any) => {
		if (input.length > 2) {
			const language = store.getState().project.currentProject.language;

			HttpService.getFootballData(input, language, relatedConstants.types.tournament).then((response: any) => {
				const fetchedFootballData: Array<any> = response.data;
				const remappedFootballData = fetchedFootballData.map((entity) => responseToResponseTournamentModel(entity));
				const loadedFootballConnectionsAsOptions = tournamentToOptions(remappedFootballData);
				callback(loadedFootballConnectionsAsOptions);
			});
		}
	};

	const onTournamentSelect = (option: ValueType<any>) => {
		if (!option) {
			const dataWithoutSeasonAndTournament = (filteredData && filteredData.rest) || null;
			updateSportsConnection(dataWithoutSeasonAndTournament);
			setSelectedTournament(null);
			setSelectedSeason(null);
		} else {
			setSelectedTournament(option.data);
			setSelectedSeason(null);
		}
	};

	const onSeasonSelect = (option: ValueType<any>) => {
		if (!option) {
			const dataWithoutSeasonAndTournament = (filteredData && filteredData.rest) || null;
			updateSportsConnection(dataWithoutSeasonAndTournament);
			setSelectedSeason(null);
		} else {
			const currentlySelectedSeason = option.data;
			const dataWithoutSeasonAndTournament = (filteredData && filteredData.rest) || null;
			const newSeasonWithTournamentData = generateSeasonAndTournamentForRedux(currentlySelectedSeason, selectedTournament, selectedSport);
			const dataForRedux = dataWithoutSeasonAndTournament
				? [...dataWithoutSeasonAndTournament, newSeasonWithTournamentData]
				: [newSeasonWithTournamentData];
			updateSportsConnection(dataForRedux);
			setSelectedSeason(currentlySelectedSeason);
		}
	};

	return (
		<>
			<Row>
				<Col>
					<FormGroup>
						<Label htmlFor={DATA_QA_ATTRIBUTES.FOOTBALL_CONNECTION_TOURNAMENT_SELECT}>{t('tournament')}</Label>
						<AsyncSelect
							id={DATA_QA_ATTRIBUTES.FOOTBALL_CONNECTION_TOURNAMENT_SELECT}
							styles={sportsConnectionColorStyles}
							isClearable={true}
							placeholder={t('select')}
							noOptionsMessage={(inputValue) => inputValue && t('no_options')}
							loadOptions={debounce(loadTournamentsOnType, 300)}
							onChange={onTournamentSelect}
							value={selectedTournament && tournamentToOptions([selectedTournament])}
							formatOptionLabel={(option) =>
								generateCustomOptionWithImage(option.label, option.value, option.data.url_logo, relatedConstants.types.tournament)
							}
						/>
					</FormGroup>
				</Col>
			</Row>
			{selectedTournament && loadedSeasons && (
				<Row>
					<Col>
						<FormGroup>
							<Label htmlFor={DATA_QA_ATTRIBUTES.FOOTBALL_CONNECTION_TOURNAMENT_SEASON_SELECT}>{t('season')}</Label>
							<Select
								id={DATA_QA_ATTRIBUTES.FOOTBALL_CONNECTION_TOURNAMENT_SEASON_SELECT}
								isClearable={true}
								placeholder={t('select')}
								options={seasonsToOptions(loadedSeasons)}
								onChange={onSeasonSelect}
								value={selectedSeason && seasonsToOptions([selectedSeason])}
							/>
						</FormGroup>
					</Col>
				</Row>
			)}
		</>
	);
};

function mapStateToProps(state: any) {
	const nestedReduxPropertyName = extractRelatedPropertiesNameByUrl();

	return {
		reduxSportConnections: nestedReduxPropertyName && state.tempSportsRelated[nestedReduxPropertyName],
	};
}

function mapDispatchToProps(dispatch: any) {
	const updateFootballConnectionRelatedAction = extractActionForUpdatingSportsConnections();

	return {
		updateSportsConnection: (rel: Related[]) => updateFootballConnectionRelatedAction && dispatch(updateFootballConnectionRelatedAction(rel)),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(TournamentSelect);
