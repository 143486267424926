import React from 'react';
import { toast } from 'react-toastify';
import { Button, Col, FormGroup, Label, Row } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Subscription } from 'rxjs';
import { MandatoryFieldsService } from '../../../../services/mandatory-fields-service/mandatory-fields.service';
import { actionService, auditLogService, featuresService, multiLingualService, wordCountService } from '../../../../App';
import { FeatureTypes } from '../../../../services/feature-service/features.enum';
import { allowRefresh, preventRefresh } from '../../../../global/DomFunctions';
import SeoModel from '../../../../models/seo/Seo';
import { ContentTypes } from '../../../../constants/content-types';
import MainMedia from '../../../../models/mainmedia/MainMedia';
import { RelatedContentModel } from '../../../Partials/Sidebar/related-content/models/related-content.model';
import { AuditLogContainer, DISPLAY_AUDIT } from '../../../Resources/audit-log/audit-log.container';
import BlockableButtonWrapper from '../../../Partials/BaseComponents/BlockableButton/BlockableButtonWrapper';
import { StrapLine } from '../../../Partials/Fields/strap-line/StrapLineComponent';
import WordCountComponent from '../../../Partials/Blocky/blocks/editor/subcomponents/word-count-component';
import { Summary } from '../../../Partials/Fields/summary/SummaryComponent';
import Blocky from '../../../Partials/Blocky/subcomponents/blocky.component';
import SidebarElementsToggle from '../../../Partials/Sidebar/sidebar-collapsible-elements/sidebar-elements-toggle/sidebar-elements-toggle.component';
import SidebarCollapsibleElements from '../../../Partials/Sidebar/sidebar-collapsible-elements/sidebar-collapsible-elements';
import Related from '../../../../models/related/Related';
import Project from '../../../../models/project/Project';
import {
	IMAGE_MEDIA_ADD,
	IMAGE_UPLOAD_FAILED,
	IMAGE_UPLOAD_REQUEST,
	IMAGE_UPLOAD_SUCCESS,
	updateEditContentTempMediaDescription,
} from '../../../../store/action-creators/ImageActionCreator';
import { clearWikiTagTempRelated, updateEditWikiTagTempRelated } from '../../../../store/action-creators/RelatedTagsTempActionCreator';
import {
	clearWikiSportsTempRelated,
	updateWikiEditSportsTempRelated,
} from '../../../../store/action-creators/RelatedSportsTempActionCreator';
import { clearWikiMatchTempRelated, updateWikiEditMatchTempRelated } from '../../../../store/action-creators/RelatedMatchTempActionCreator';
import {
	clearWikiContentTempRelated,
	updateWikiEditContentTempRelated,
} from '../../../../store/action-creators/RelatedContentTempActionCreator';
import { initializeBlockyUndoRedo, resetAutolinking, resetUndoRedoBlocky } from '../../../../store/action-creators/BlockyActionCreator';
import BlockModel from '../../../Partials/Blocky/models/block.model';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Title } from '../../../Partials/Fields/title/TitleComponent';
import UndoRedoBlockyModel from '../../../Partials/Blocky/models/UndoRedoBlockyMode';
import Profile from '../../../../models/profile/Profile';
import Wiki from '../../../../models/wiki/Wiki';
import {
	returnObjectForWikiLEntityRequest,
	returnObjectForWikiLEntityUpdate,
	WIKI_ENTITY_RECEIVED,
	WIKI_ENTITY_REQUEST,
	WIKI_ENTITY_UPDATE,
	WIKI_ENTITY_UPDATE_FAILED,
	WIKI_ENTITY_UPDATE_SUCCESS,
} from '../../../../store/action-creators/WikiActionCreator';
import { updateTempEditWiki, WIKI_TEMP_EDIT_UPDATE_BEFORE_REDIRECTING } from '../../../../store/action-creators/WikiTempActionCreator';
import Flag from 'react-world-flags';
import EditorToolbar from '../../../Partials/EditorToolbar/EditorToolbar';
import {
	ContentSidebarChangeCollapseStatus,
	onChangeCollapseStatus,
	onSidebarElemSort,
	toggleSidebarEditMode,
} from '../../../../services/content-sidebar/content-sidebar.helper';
import { FormattedSidebarSectionValue } from '../../../Partials/Sidebar/sidebar-collapsible-elements/utils/temp-sidebar.types';
import GlobalModal from '../../../Partials/Modals/Global/global-modal';
import {
	appendBeforeUnloadResetTempSidebar,
	defaultTempSidebarFromApiResponse,
	overwriteTempSidebarByContentType,
	saveSidebarSettingsByContentType,
} from '../../../Partials/Sidebar/sidebar-collapsible-elements/utils/temp-sidebar.helper';
import SidebarTags from '../../../Partials/Sidebar/tags-refactored/tags';
import SidebarGeneralContent from '../../../Partials/Sidebar/general-content/GeneralContentAttributes';
import SidebarSEO from '../../../Partials/Sidebar/seo-refactored/seo.component';
import SidebarCustomData from '../../../Partials/Sidebar/custom-data/custom-data.component';
import SidebarContentFooter from '../../../Partials/Sidebar/content-footer-refactored/content-footer.component';
import { renderComponentOnlyIfFeatureEnabled } from '../../../../global-helpers/global-features.helpers';
import SidebarRelatedContent from '../../../Partials/Sidebar/related-content-refactored/related-content';
import SidebarMainMedia from '../../../Partials/Sidebar/media-refactored/media-wrapper';

type Properties = {
	tReady: boolean;
	t: any;
	i18n: any;
	wiki: Wiki;
	blocksUndoRedo: UndoRedoBlockyModel;
	updateTempWiki: any;
	updateTempMediaDescription: any;
	updateWiki: any;
	project: Project;
	profile: Profile;
	relatedContent: Related[];
	relatedSports: Related[];
	relatedTags: Related[];
	relatedMatches: Related[];
	updateTagTempRelated: (arg: Related[]) => any;
	updateSportsTempRelated: (arg: Related[]) => any;
	updateRelatedContentTemp: (arg: Related[]) => any;
	updateMatchTempRelated: (arg: Related[]) => any;
	clearMatchTempRelated: () => any;
	clearSportsTempRelated: () => any;
	clearTagTempRelated: () => any;
	clearContentTempRelated: () => any;
	getWiki: (id: string, project: Project) => any;
	resetAutolinking: () => void;
	initializeBlockyUndoRedo: (blocks: BlockModel[]) => void;
	resetUndoRedoBlocky: () => void;
	match: any;
	history: any;
	analytics: any;
	reduxLoadingState: boolean;
};

type State = {
	isContentLoading: boolean;
	isSidebarInEdit: boolean;
	areMandatoryFieldsFilled: boolean;
	totalWordCount: number;
	totalCharCount: number;
	addWatermarkToImageMedia: boolean;
	openSidebarSettingsModalFlag: boolean;
};

class WikiEdit extends React.Component<Properties, State> {
	private actionServiceSubject = new Subscription();
	private contentValidation: MandatoryFieldsService = new MandatoryFieldsService();

	constructor(props: any) {
		super(props);
		this.state = {
			isContentLoading: true,
			isSidebarInEdit: false,
			areMandatoryFieldsFilled: true,
			totalWordCount: 0,
			totalCharCount: 0,
			addWatermarkToImageMedia: !!(this.props.wiki && this.props.wiki.generic && this.props.wiki.generic.mainMediaWatermark),
			openSidebarSettingsModalFlag: false,
		};
		this.registerActionEventListener();
	}

	componentWillUnmount() {
		this.props.relatedSports && this.props.clearSportsTempRelated();
		this.props.relatedTags && this.props.clearTagTempRelated();
		this.props.relatedMatches && this.props.clearMatchTempRelated();
		this.props.relatedContent && this.props.clearContentTempRelated();
		this.actionServiceSubject.unsubscribe();
		this.props.resetUndoRedoBlocky();
		allowRefresh();
	}

	calculateTotalWordCharCount() {
		let totalWordCharCount = wordCountService.calculateTotalWordCharCount(this.props.wiki.body);
		this.setState(totalWordCharCount);
	}

	registerActionEventListener() {
		this.actionServiceSubject = actionService.onActionReceived().subscribe((action: string) => {
			if (action === WIKI_ENTITY_REQUEST) {
				this.toggleContentLoadingState(true);
			}

			if (action === WIKI_ENTITY_RECEIVED) {
				setTimeout(() => {
					this.toggleContentLoadingState(false);
				}, 800);
			}

			if (action === WIKI_TEMP_EDIT_UPDATE_BEFORE_REDIRECTING) {
				this.onSubmit();
			}
		});
	}

	componentDidUpdate(prevProps: Properties, prevState: State) {
		if (this.props.wiki.body !== prevProps.wiki.body) {
			this.props.updateSportsTempRelated(this.props.relatedSports);
			this.props.updateMatchTempRelated(this.props.relatedMatches);
		}

		if (this.props.relatedMatches !== prevProps.relatedMatches) {
			this.props.updateMatchTempRelated(this.props.relatedMatches);
		}

		if (this.props.wiki.generic != prevProps.wiki.generic) {
			this.setState({ addWatermarkToImageMedia: !!(this.props.wiki.generic && this.props.wiki.generic.mainMediaWatermark) });
		}
		if (prevProps !== this.props && featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.WORDCOUNT)) {
			this.calculateTotalWordCharCount();
		}

		if (this.props.match.params.id !== prevProps.match.params.id) {
			window.scrollTo(0, 0);
			this.toggleContentLoadingState(true);
			this.props.resetAutolinking();
			this.props.getWiki(this.props.match.params.id, this.props.project);
		}
	}

	toggleContentLoadingState(isLoading: boolean) {
		this.setState({
			...this.state,
			isContentLoading: isLoading,
		});
	}

	componentDidMount(): void {
		this.props.updateMatchTempRelated([]);
		this.props.updateSportsTempRelated([]);
		this.props.resetAutolinking();
		this.initPageTitle();
		this.props.getWiki(this.props.match.params.id, this.props.project);
		preventRefresh();
		appendBeforeUnloadResetTempSidebar(ContentTypes.WIKI_PAGE);
		try {
			this.props.wiki.body && this.props.wiki.body.length > 0 && this.props.initializeBlockyUndoRedo([...this.props.wiki.body]);
		} catch {
			this.props.initializeBlockyUndoRedo([]);
		}
	}

	initPageTitle() {
		document.title = this.props.t('wiki_page_edit');
	}

	onTitleChange(title: string) {
		const seo = SeoModel.builder(this.props.wiki.seo).withAutoUpdateSeo(title).build();
		const wiki = Wiki.builder(this.props.wiki).withTitle(title).withSeo(seo).build();
		this.props.updateTempWiki(wiki);
	}

	onStrapLineChange(strapline: string) {
		const wiki = Wiki.builder(this.props.wiki).withStrapline(strapline).build();
		this.props.updateTempWiki(wiki);
	}

	onSummaryChange(summary: string) {
		const wiki = Wiki.builder(this.props.wiki).withSubtitle(summary).build();
		this.props.updateTempWiki(wiki);
	}

	onSubmit() {
		const relatedList = this.mergeRelatedFromProps();
		if (this.props.wiki.mainMedia && this.props.wiki.mainMedia[0] && this.props.wiki.mainMedia[0].addDescriptionToMedia) {
			this.props.updateTempMediaDescription(this.props.wiki.mainMedia, this.props.project);
		}
		if (featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.MANDATORY_FIELDS)) {
			this.contentValidation.validateByContentType(this.props.wiki, ContentTypes.WIKI_PAGE)
				? this.checkForWatermarkChecked(relatedList)
				: this.updateMandatoryFieldsFilled(false);
		} else {
			this.checkForWatermarkChecked(relatedList);
		}
	}

	checkForWatermarkChecked = (relatedList: any) => {
		if (
			//If apply watermark is checked, but no main media image is selected, saving is blocked.
			this.props.wiki.mainMedia &&
			this.props.wiki.mainMedia[0] &&
			this.props.wiki.mainMedia[0].addWatermarkToImageMedia &&
			!this.props.wiki.mainMedia[0].resourceId
		) {
			toast.error(this.props.t('missing_main_image_watermark'));
		} else {
			this.props.updateWiki(this.props.wiki, JSON.parse(JSON.stringify(relatedList)), this.props.project);
			try {
				this.props.wiki.body && this.props.wiki.body.length > 0 && this.props.initializeBlockyUndoRedo([...this.props.wiki.body]);
			} catch {
				this.props.initializeBlockyUndoRedo([]);
			}
		}
	};

	updateMandatoryFieldsFilled = (areFilledFields: boolean) => {
		this.setState({ ...this.state, areMandatoryFieldsFilled: areFilledFields });
	};

	mergeRelatedFromProps() {
		const { relatedContent, relatedMatches, relatedSports, relatedTags } = this.props;
		return [...relatedContent, ...relatedSports, ...relatedTags, ...relatedMatches];
	}

	onBodyChange = (blocks: any) => {
		const wiki = Wiki.builder(this.props.wiki).withBody(blocks).build();
		this.props.updateTempWiki(wiki);
	};

	displayAuditLog = () => {
		actionService.emitUiAction({
			type: DISPLAY_AUDIT,
			data: {
				profileId: this.props.profile.id,
				contentId: this.props.wiki.id,
				contentTitle: this.props.wiki.title,
				contentType: ContentTypes.WIKI_PAGE,
			},
		});
	};

	toggleSidebarEditMode = () => {
		const newState = toggleSidebarEditMode({ ...this.state }, ContentTypes.WIKI_PAGE);
		this.setState(newState);
	};

	render() {
		const { t, wiki, project } = this.props;
		const { isSidebarInEdit, openSidebarSettingsModalFlag } = this.state;

		const sidebarComponentsMap = {
			general: <SidebarGeneralContent t={t} />,
			media: (
				<SidebarMainMedia
					t={t}
					areMandatoryFieldsFilled={this.state.areMandatoryFieldsFilled}
					updateMandatoryFieldsFilled={this.updateMandatoryFieldsFilled}
				/>
			),
			content_footer: renderComponentOnlyIfFeatureEnabled(FeatureTypes.CONTENT_FOOTER, <SidebarContentFooter t={t} />),
			tags: <SidebarTags t={t} />,
			related_content: <SidebarRelatedContent t={t} />,
			seo: <SidebarSEO t={t} />,
			custom_data: <SidebarCustomData t={t} />,
		};

		const saveBlockOnActions = [WIKI_ENTITY_UPDATE, IMAGE_UPLOAD_REQUEST];
		const saveReleaseOnActions = [
			WIKI_ENTITY_UPDATE_SUCCESS,
			WIKI_ENTITY_UPDATE_FAILED,
			IMAGE_UPLOAD_SUCCESS,
			IMAGE_UPLOAD_FAILED,
			IMAGE_MEDIA_ADD,
		];

		return (
			<div className={`animated fadeIn ${this.props.reduxLoadingState && 'loading-overlay'}`}>
				<Row>
					<Col col='8' lg='8' md='12' sm='12' xs='12'>
						<div className='card modified-card-container'>
							<div className='card-header'>
								<div className='row d-flex align-items-center'>
									{multiLingualService.checkIfProjectIsMultiLingual(project.languages) && Object.entries(wiki).length > 0 && wiki.language && (
										<div className='col mr-2 text-truncate d-flex'>
											<Flag code={wiki.language === 'en' ? 'gb' : wiki.language} width='20' fallback={<i className='fa fa-flag ml-2' />} />
											<label className='mb-0 text-truncate ml-2'>
												{t('edit')}: {wiki ? wiki.title : ''}
											</label>
										</div>
									)}
									<div className='py-1 col d-flex'>
										<BlockableButtonWrapper blockOnActions={saveBlockOnActions} releaseOnActions={saveReleaseOnActions}>
											<Button
												size='sm'
												color='primary'
												id='wiki-edit-save-top'
												className='ml-auto mr-2 text-nowrap'
												onClick={this.onSubmit.bind(this)}
											>
												<i className='fa fa-floppy-o'></i> {t('save_wiki')}
											</Button>
										</BlockableButtonWrapper>
										{auditLogService.isFeatureEnabled(featuresService, this.props.profile.id) && (
											<Button size='sm' color='secondary' className='ml-2 text-nowrap' onClick={this.displayAuditLog}>
												<i className={'fa fa-clock-rotate-left text-white'} />
											</Button>
										)}
									</div>
								</div>
							</div>
							<AuditLogContainer t={this.props.t} />

							<div className='card-body'>
								<div>
									<Row>
										<Col xs='12'>
											<Label htmlFor='title'>{t('title')}</Label>
											<Row>
												<Col>
													<StrapLine t={t} onChange={this.onStrapLineChange.bind(this)} value={wiki ? wiki.strapline : ''} />
												</Col>
												<Col>
													<WordCountComponent t={t} wordCount={this.state.totalWordCount} charCount={this.state.totalCharCount} />
												</Col>
											</Row>
											<Title t={t} onChange={this.onTitleChange.bind(this)} value={wiki ? wiki.title : ''} />
										</Col>
									</Row>
									<Row>
										<Col xs='12'>
											<Summary t={t} onChange={this.onSummaryChange.bind(this)} showValidation={true} value={wiki ? wiki.subtitle : ''} />
										</Col>
									</Row>
									<Row className={'mb-3'}>
										<Col xs='12'>
											<FormGroup>
												<Label htmlFor='summary'>{t('body')}</Label>
												<Blocky
													t={t}
													contentType={ContentTypes.WIKI_PAGE}
													contentLanguage={wiki.language}
													entityType={
														multiLingualService.checkIfProjectIsMultiLingual(project.languages) &&
														wiki.translationGroup &&
														wiki.translationGroup.contentType
													}
													blocks={wiki.body ? wiki.body : []}
													onChange={this.onBodyChange}
												/>
											</FormGroup>
										</Col>
									</Row>
									<Row>
										<Col>
											<BlockableButtonWrapper blockOnActions={saveBlockOnActions} releaseOnActions={saveReleaseOnActions}>
												<Button
													color={'primary'}
													id='wiki-edit-save-bottom'
													className={'text-uppercase font-weight-bold mr-2'}
													onClick={this.onSubmit.bind(this)}
												>
													<i className='fa fa-floppy-o'></i> {t('save_wiki')}
												</Button>
											</BlockableButtonWrapper>
										</Col>
										<Col>
											<WordCountComponent t={t} wordCount={this.state.totalWordCount} charCount={this.state.totalCharCount} />
										</Col>
									</Row>
								</div>
							</div>
						</div>
					</Col>
					{!this.state.isContentLoading && (
						<Col col='4' lg='4' md='12' sm='12' xs='12' className='position-relative'>
							<SidebarElementsToggle t={t} toggleSidebarEditMode={this.toggleSidebarEditMode} isSidebarInEdit={isSidebarInEdit} />
							<SidebarCollapsibleElements
								isSidebarInEdit={isSidebarInEdit}
								onElemSort={(settingsValue: FormattedSidebarSectionValue[]) => onSidebarElemSort(settingsValue, ContentTypes.WIKI_PAGE)}
								onChangeCollapseStatus={(data: ContentSidebarChangeCollapseStatus) => onChangeCollapseStatus(data)}
								contentType={ContentTypes.WIKI_PAGE}
								sidebarComponentsMap={sidebarComponentsMap}
								t={t}
								currentProject={project}
							/>
						</Col>
					)}
				</Row>
				<EditorToolbar
					contentType={ContentTypes.WIKI_PAGE}
					onChange={this.onBodyChange}
					save={{
						invokeFunction: this.onSubmit.bind(this),
						blockOnActions: saveBlockOnActions,
						releaseOnActions: saveReleaseOnActions,
						blockUntilCorrectContentIsLoaded: !wiki.id,
					}}
					onPreview={null}
					onCopyToProject={null}
					onAuditLog={this.displayAuditLog}
					t={t}
					showNotifyUsers
					content={wiki}
				/>
				<GlobalModal
					isOpen={openSidebarSettingsModalFlag}
					t={this.props.t}
					headerContent='save_sidebar_settings'
					bodyContent='unsaved_changes'
					submitFunction={() => saveSidebarSettingsByContentType(ContentTypes.WIKI_PAGE, t)}
					beforeCloseFunction={() =>
						overwriteTempSidebarByContentType(ContentTypes.WIKI_PAGE, defaultTempSidebarFromApiResponse[ContentTypes.WIKI_PAGE].value)
					}
				/>
			</div>
		);
	}
}

function mapStateToProps(state: any) {
	return {
		wiki: state.tempWiki.wikiEdit,
		project: state.project.currentProject,
		profile: state.profile.profile,
		relatedContent: state.tempContentRelated.wikiEditRelated,
		relatedSports: state.tempSportsRelated.wikiEditRelated,
		relatedTags: state.tempTagsRelated.wikiEditRelated,
		relatedMatches: state.tempMatchRelated.wikiEditRelated,
		reduxLoadingState: state.ui.contentLoadingState[ContentTypes.WIKI_PAGE],
	};
}

function mapDispatchToProps(dispatch: any) {
	const isUndoRedoEnabled =
		featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.CONTENT_BLOCKY_UNDO_REDO) &&
		featuresService.checkAvailableUndoRedoPageFromFeature(ContentTypes.WIKI_PAGE);
	return {
		updateWiki: (wiki: Wiki, related: Related[], project: Project) => dispatch(returnObjectForWikiLEntityUpdate(wiki, related, project)),
		updateTempWiki: (wiki: Wiki) => dispatch(updateTempEditWiki(wiki)),
		updateTempMediaDescription: (media: MainMedia, project: Project) => dispatch(updateEditContentTempMediaDescription(media, project)),
		updateTagTempRelated: (related: Related[]) => dispatch(updateEditWikiTagTempRelated(related)),
		updateSportsTempRelated: (related: Related[]) => dispatch(updateWikiEditSportsTempRelated(related)),
		updateMatchTempRelated: (related: Related[]) => dispatch(updateWikiEditMatchTempRelated(related)),
		updateRelatedContentTemp: (related: Related[]) => dispatch(updateWikiEditContentTempRelated(related)),
		getWiki: (id: string, project: Project) => dispatch(returnObjectForWikiLEntityRequest(id, project)),
		clearMatchTempRelated: () => dispatch(clearWikiMatchTempRelated()),
		clearTagTempRelated: () => dispatch(clearWikiTagTempRelated()),
		clearContentTempRelated: () => dispatch(clearWikiContentTempRelated()),
		clearSportsTempRelated: () => dispatch(clearWikiSportsTempRelated()),
		resetAutolinking: () => dispatch(resetAutolinking()),
		initializeBlockyUndoRedo: (blocks: BlockModel[]) => isUndoRedoEnabled && dispatch(initializeBlockyUndoRedo(blocks)),
		resetUndoRedoBlocky: () => isUndoRedoEnabled && dispatch(resetUndoRedoBlocky()),
	};
}

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps), withRouter)(WikiEdit) as React.ComponentType;
