import { ErrorJson } from './ErrorJson';
import ErrorBuilder from './ErrorBuilder';

export default class ErrorModel {
	readonly error: string = '';
	readonly code: string = '';
	readonly type: string = '';

	private constructor(error: string, code: string, type: string) {
		this.error = error;
		this.code = code;
		this.type = type;
	}

	toJSON(): ErrorJson {
		return {
			error: this.error,
			code: this.code,
			type: this.type,
		};
	}

	static fromJSON(json: ErrorJson): ErrorModel {
		return new ErrorModel(json.error, json.code, json.type);
	}

	static builder(urls?: ErrorModel): ErrorBuilder {
		return new ErrorBuilder(urls);
	}
}
