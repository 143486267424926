import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Col, Label, Row } from 'reactstrap';
import TournamentModel from '../../../../../../models/v2/Tournament/Entity/TournamentModel';
import HttpService from '../../../../../../services/rest/HttpService';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../../../store/store';
import { customOption } from '../../../partials/shared/custom-select-option';
import {
	responseToTournamentModel,
	tournamentsToOptions,
	tournamentToOption,
} from '../helpers/tournament-select/tournament-select-component.helper';
import ErrorHandler from '../error-handler/error-handler';

type Properties = {
	onTournamentSelect: any;
	selectedTournament: TournamentModel | any;
	t: any;
	isValid: boolean;
	isMulti?: boolean;
	isClearable?: boolean;
	isRequired?: boolean;
	isBlocky: boolean;
	id: string;
};

const TournamentSelectContainer: React.FunctionComponent<Properties> = (props) => {
	const [tournamentsOptions, setTournamentsOptions] = useState<TournamentModel[]>([]);
	const tournamentOrder = useSelector((state: AppState) => state.project.currentProject.defaultTournamentOrder);

	useEffect(() => {
		getTournaments(tournamentOrder);
	}, []);

	const updateTournamentsOptionsState = (tournaments: TournamentModel[]) => {
		const { onTournamentSelect, selectedTournament, isBlocky, isMulti } = props;
		if (
			(!selectedTournament ||
				(selectedTournament && (selectedTournament.id === undefined || selectedTournament.id.length < 1) && !isMulti) ||
				(isMulti && selectedTournament.length === 0)) &&
			isBlocky
		) {
			onTournamentSelect(tournaments[0]);
		}
		setTournamentsOptions(tournaments);
	};

	const getTournaments = (tournamentOrder: string) => {
		HttpService.getTournaments(tournamentOrder)
			.then((response: any) => {
				updateTournamentsOptionsState(response.data.map((item: TournamentModel) => responseToTournamentModel(item)));
			})
			.catch((e: any) => e);
	};

	const tournamentSelected = props.selectedTournament === undefined || props.selectedTournament === null || !props.selectedTournament.id;

	return (
		<Row data-qa={props.id}>
			<Col>
				<Label htmlFor={props.id}>{props.t('tournament')}:</Label>
				{props.isRequired && <span className='text-danger'>*</span>}
				<Select
					id={props.id}
					menuPortalTarget={document.body}
					menuPosition='absolute'
					className='w-100'
					placeholder={props.t('select')}
					formatOptionLabel={customOption}
					options={tournamentsToOptions(tournamentsOptions)}
					value={props.selectedTournament && props.selectedTournament.id ? tournamentToOption(props.selectedTournament) : []}
					noOptionsMessage={(inputValue) => inputValue && props.t('no_options')}
					isClearable={props.isClearable}
					onChange={(selection: any) => {
						if (selection) {
							props.onTournamentSelect(selection.data);
						} else {
							props.onTournamentSelect(null);
						}
					}}
					styles={{
						menuPortal: (baseStyles) => ({
							...baseStyles,
							zIndex: 10000,
						}),
					}}
				/>
				{props.isRequired && (
					<ErrorHandler t={props.t} isValueSelected={!tournamentSelected} arePropsValid={props.isValid} errorMessage='field_is_required' />
				)}
			</Col>
		</Row>
	);
};

export default TournamentSelectContainer as React.ComponentType<Properties>;
