import * as React from 'react';
import { copyToClipboard } from '../../dugout-video-block/video-cells/helpers/video-cells.helper';
import AmgItemDataModel from '../models/amg-item-data.model';
import { AmgVideoRow } from './amg-video-row';
import AmgVideoModel from '../models/amg-video.model';

type Properties = {
	videos: AmgVideoModel[];
	t: any;
	onSelect: (video: AmgItemDataModel) => void;
	selectedVideo: AmgItemDataModel;
	isMediaSidebar: boolean;
};

export const AmgVideoListing: React.FunctionComponent<Properties> = ({ t, onSelect, isMediaSidebar, selectedVideo, videos }) => {
	const copyId = (e: any, id: string) => {
		copyToClipboard(e, id.replace(/\"/g, ''), t, 'id');
	};

	const copyEmbed = (e: any, embedCode: string) => {
		copyToClipboard(e, embedCode, t, 'embedCode');
	};

	return (
		<div className='pl-2' data-qa='amg-videos-listing'>
			{videos &&
				videos[0] &&
				videos[0].itemData &&
				videos[0].itemData.length > 0 &&
				videos[0].itemData.map((video: AmgItemDataModel) => {
					return (
						<AmgVideoRow
							key={`${video.id + Math.random()}`}
							t={t}
							video={video}
							isMediaSidebar={isMediaSidebar}
							onSelect={onSelect}
							copyEmbed={copyEmbed}
							copyId={copyId}
							selectedVideo={selectedVideo}
						/>
					);
				})}
		</div>
	);
};
