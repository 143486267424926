import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import TournamentGroupModel from '../../../../../models/v2/Tournament/Group/TournamentGroupModel';

type Props = {
	t: any;
	tournamentsGroups: TournamentGroupModel[];
};

const TournamentsGroupRows: FunctionComponent<Props> = ({ t, tournamentsGroups }) => {
	return (
		<>
			{tournamentsGroups.length > 0 ? (
				tournamentsGroups.map((tournamentGroup: TournamentGroupModel) => {
					return (
						<Row key={tournamentGroup.code} className='d-flex flex-row align-items-center py-2 px-2 border-bottom tournament-order-item'>
							<Col md='4' className='mb-md-2'>
								<Link to={`/smp/tournament-groups/edit/${tournamentGroup.code}`} className='text-dark'>
									<span className='d-md-none font-weight-bold'>{t('name')}: </span>
									{tournamentGroup.name ? tournamentGroup.name : '-'}
								</Link>
							</Col>
							<Col md='6'>
								<span className='d-md-none font-weight-bold'>{t('description')}: </span>
								{tournamentGroup.description ? tournamentGroup.description : '-'}
							</Col>
							<Col md='2' className='text-md-right'>
								<Link to={`/smp/tournament-groups/edit/${tournamentGroup.code}`}>
									<Button id={`match-edit-btn-${tournamentGroup.code}`} color='warning'>
										<i className='fa fa-pencil p-1' />
										{t('edit')}
									</Button>
								</Link>
							</Col>
						</Row>
					);
				})
			) : (
				<h6 className='d-flex justify-content-center mt-4'>{t('no_data_found')}</h6>
			)}
		</>
	);
};

export default TournamentsGroupRows;
