import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { extractRelatedPropertiesNameByUrl } from '../../../tags-refactored/helpers/utils';
import Related from '../../../../../../models/related/Related';
import * as ReactSortableHOC from 'react-sortable-hoc';
import RelatedContentSortableItem from './sortable-item';
import { DATA_QA_ATTRIBUTES } from '../../constants/data-qa.attributes';
import { extractContentModeBasedOnUrl, extractMainContentTypeBasedOnUrl } from '../../../../../../global-helpers/global.helpers';
import { generateRelatedUpdateActionByContentType } from '../../helpers/general.helper';

type Properties = {
	t: any;
	relatedContentInRedux: Related[];
	updateRelatedContent: (rel: Related[]) => void;
};

const SortableItem = ReactSortableHOC.SortableElement(RelatedContentSortableItem);

const RelatedContentSortableList: FunctionComponent<Properties> = ({ t, relatedContentInRedux, updateRelatedContent }) => {
	const onDeleteRelatedItem = (itemType: string, itemId: string) => {
		const allItems = [...relatedContentInRedux];
		const itemIndexForRemoving = allItems.findIndex((el: Related) => el.data.id === itemId && el.type === itemType);
		itemIndexForRemoving >= 0 && allItems.splice(itemIndexForRemoving, 1);
		updateRelatedContent(allItems);
	};

	if (relatedContentInRedux.length === 0) {
		return <div className='text-left'>{t('no_related_objects_selected')}</div>;
	}

	return (
		<ul className='list-group mb-2' id={DATA_QA_ATTRIBUTES.ADDED_ITEMS}>
			{relatedContentInRedux.map((item: Related, index: number) => (
				<SortableItem
					relatedItem={item}
					onDelete={onDeleteRelatedItem}
					t={t}
					key={`key-sortable-${item.data.id}-${item.data.title}`}
					index={index}
				/>
			))}
		</ul>
	);
};

function mapStateToProps(state: any) {
	const relatedPropertiesNestedProp = extractRelatedPropertiesNameByUrl();
	const relatedContentRedux = relatedPropertiesNestedProp && state.tempContentRelated[relatedPropertiesNestedProp];

	return {
		relatedContentInRedux: relatedContentRedux || [],
	};
}

function mapDispatchToProps(dispatch: Function) {
	const contentType = extractMainContentTypeBasedOnUrl();
	const contentMode = extractContentModeBasedOnUrl();
	const generatedActionByContentType = generateRelatedUpdateActionByContentType(contentType, contentMode);

	return {
		updateRelatedContent: (related: Related[]) => generatedActionByContentType && dispatch(generatedActionByContentType(related)),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(RelatedContentSortableList);
