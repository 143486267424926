import Category from '../../models/category/Category';
import {
	ADMIN_CATEGORY_LISTING_RECEIVED,
	CATEGORY_ENTITY_DELETE_FAILED,
	CATEGORY_ENTITY_DELETE_SUCCESS,
	CATEGORY_ENTITY_RECEIVED,
	ALL_CATEGORY_LISTING_RECEIVED,
	CATEGORY_LISTING_RECEIVED,
} from '../action-creators/CategoriesActionCreators';
import { USER_LOGOUT } from '../action-creators/ProfileActionCreators';
import PaginationMeta from '../../models/pagination/PaginationMeta';

interface InitialState {
	allCategories: Category[];
	adminCategories: Category[];
	categories: Category[];
	category: Category;
	pagination: PaginationMeta;
	categoryDeleted: boolean;
}
const initialState: InitialState = {
	allCategories: [],
	adminCategories: [],
	categories: [],
	category: Category.builder().build(),
	pagination: PaginationMeta.builder().build(),
	categoryDeleted: false,
};

function categoryReducer(state: any = initialState, action: any) {
	if (action.type === ALL_CATEGORY_LISTING_RECEIVED) {
		state = Object.assign({}, state, {
			allCategories: action.payload,
		});
	}

	if (action.type === ADMIN_CATEGORY_LISTING_RECEIVED) {
		state = Object.assign({}, state, {
			adminCategories: action.payload,
		});
	}

	if (action.type === CATEGORY_LISTING_RECEIVED) {
		state = Object.assign({}, state, {
			categories: action.payload.categories,
			pagination: action.payload.contentPagination,
		});
	}

	if (action.type === CATEGORY_ENTITY_RECEIVED) {
		return Object.assign({}, state, {
			category: action.payload.category,
		});
	}

	if (action.type === CATEGORY_ENTITY_DELETE_SUCCESS) {
		return Object.assign({}, state, {
			categoryDeleted: true,
		});
	}

	if (action.type === CATEGORY_ENTITY_DELETE_FAILED) {
		return Object.assign({}, state, {
			categoryDeleted: false,
		});
	}

	if (action.type === USER_LOGOUT) {
		state = initialState;
	}

	return state;
}

export default categoryReducer;
