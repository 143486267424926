import * as React from 'react';
import { Properties, RequiredProperties } from './properties/MainMediaContainerProps';
import { withTranslation } from 'react-i18next';
import MainMedia from '../../../../models/mainmedia/MainMedia';
import {
	addMainMediaIfNotExists,
	extractMainImageMedia,
	removeMainMedia,
	updateMainMediaInList,
} from './helpers/MainMediaListingContainerHelper';
import { MainMediaList } from './MainMediaList';
import { toast } from 'react-toastify';
import { mainMediaHasImage } from '../../../Resources/helpers/resources.helper';
import { IMAGE_MEDIA_ADD } from '../../../../store/action-creators/ImageActionCreator';
import { actionService } from '../../../../App';
import { ContentTypes } from '../../../../constants/content-types';
import { useEffect, useState } from 'react';
import { MainMediaAdditionalImageSelect } from '../media-refactored/subcomponents/additional-image-media/main-media-additional-images.component';
import { checkIfFeatureEnabledAndCorrectContentType } from '../media-refactored/subcomponents/additional-image-media/helpers/main-media-additional-items.helper';

const MainMediaListContainer: React.FunctionComponent<Properties> = (props) => {
	const { blocks, value, currentProject, t, contentType, resourceType = '', areMandatoryFieldsFilled, updateMandatoryFieldsFilled } = props;
	const [additionalMainMediaItemsOpen, setAdditionalMainMediaItemsOpen] = useState(false);

	useEffect(() => {
		const mainImageExists = mainMediaHasImage(value);
		if (mainImageExists.length === 0 && areMandatoryFieldsFilled !== undefined && !areMandatoryFieldsFilled) {
			toast.error(t('main_image_must_not_be_empty'));
			updateMandatoryFieldsFilled(true);
		}
	}, [areMandatoryFieldsFilled]);

	const onMediaUpdate = (media: MainMedia) => {
		const { value } = props;
		const mainMediaList = updateMainMediaInList(media, value);
		if (media.resourceType === ContentTypes.IMAGE) {
			actionService.emitGeneralAction(IMAGE_MEDIA_ADD);
		}
		props.onChange(mainMediaList);
	};

	const onMediaAdd = (media: MainMedia) => {
		const { value } = props;
		const mainMediaList = addMainMediaIfNotExists(media, value);
		if (media.resourceType === ContentTypes.IMAGE) {
			actionService.emitGeneralAction(IMAGE_MEDIA_ADD);
		}
		props.onChange(mainMediaList);
	};

	const onMediaRemove = (type: string, subType: string) => {
		const { value } = props;
		const mainMediaList = removeMainMedia(type, subType, value);
		props.onChange(mainMediaList);
	};

	const onAdditionalItemOpened = (opened: boolean) => {
		setAdditionalMainMediaItemsOpen(opened);
	};

	const mainImageMedia = extractMainImageMedia(value);

	return (
		<>
			<MainMediaList
				applyWatermark={props.applyWatermark}
				project={currentProject}
				t={t}
				mainMediaList={value}
				onRemove={onMediaRemove}
				onMediaUpdate={onMediaUpdate}
				mainImage={mainImageMedia}
				onMediaAdd={onMediaAdd}
				resourceType={resourceType}
				contentType={contentType}
				blocks={blocks}
				additionalMainMediaItemsOpen={additionalMainMediaItemsOpen}
			/>
			{checkIfFeatureEnabledAndCorrectContentType(contentType) && (
				<div>
					<hr className='pad2x pad2y mt-4' />
					<MainMediaAdditionalImageSelect
						mainMedia={value}
						onMediaAdd={onMediaAdd}
						onMediaUpdate={onMediaUpdate}
						onMediaRemove={onMediaRemove}
						additionalMainMediaItemsOpened={onAdditionalItemOpened}
					/>
				</div>
			)}
		</>
	);
};

export default withTranslation()(MainMediaListContainer) as React.ComponentType<RequiredProperties>;
