import * as React from 'react';
import { multiLingualService } from '../../../../../../../App';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../../../../store/store';
import { toast } from 'react-toastify';

type Properties = {
	t: any;
	display: boolean;
	analytics: any;
	onDelete: (id: string, title: string) => any;
	contentId: string;
	contentTitle: string;
	dropdownItem?: boolean;
	contentType?: string;
	content?: any;
};

export const RowDeleteButton: React.FunctionComponent<Properties> = ({
	contentType,
	display,
	analytics,
	onDelete,
	contentId,
	contentTitle,
	dropdownItem,
	content,
	t,
}) => {
	const buttonStyle = 'm-1 btn btn-sm btn-danger d-flex flex-fill justify-content-center cursor-pointer';
	const masterContentId = multiLingualService.getMasterContentId(content);
	const currentProject = useSelector((state: AppState) => state.project.currentProject);
	const validForDeletion = multiLingualService.extractTranslations(
		content && content.translationGroup && content.translationGroup.translations,
		currentProject.languages && currentProject.languages.all,
	);

	const onDeleteContent = () => {
		if (multiLingualService.checkIfProjectIsMultiLingual(currentProject.languages)) {
			if (
				(validForDeletion && validForDeletion.length !== 1 && contentId !== masterContentId) ||
				(validForDeletion && validForDeletion.length === 1)
			) {
				analytics.sendEvent('Click', 'List Page', 'Delete - Open Modal');
				onDelete(contentId, contentTitle);
			} else {
				toast.error(t('master_content_cant_delete'));
			}
		} else {
			analytics.sendEvent('Click', 'List Page', 'Delete - Open Modal');
			onDelete(contentId, contentTitle);
		}
	};

	if (display) {
		return (
			<div id={`content-delete-btn-${contentId}`} className={buttonStyle} onClick={onDeleteContent}>
				<i className={`${dropdownItem ? 'mr-1' : ''} fa fa-trash p-1 text-white`} />
				{dropdownItem && (
					<span>
						{t('delete')} {t(`${contentType}`)}
					</span>
				)}
			</div>
		);
	}

	return null;
};
