import React from 'react';
import { Col, Label, Row } from 'reactstrap';
import WidgetButtonList from './subcomponents/widget-button-list.component';
import { withTranslation } from 'react-i18next';
import WidgetBlocksGenerator from '../../Partials/Blocky/generators/widget-blocks.generator';
import { ODDS_BLOCK } from '../../Partials/Blocky/constants/block-list.constants';
import BlockControlsWrapper from '../../Partials/Blocky/wrappers/block-controls/block-controls.wrapper';
import BlockModel from '../../Partials/Blocky/models/block.model';
import './widgets-embed.component.scss';
import Button from 'reactstrap/lib/Button';
import { toast } from 'react-toastify';
import { store } from '../../../store/store';
import { featuresService, multiLingualService } from '../../../App';
import { FeatureTypes } from '../../../services/feature-service/features.enum';
import { returnObjectForArticlePaginationUpdate } from '../../../store/action-creators/ArticleActionCreator';
import { returnObjectForVideoPaginationUpdate } from '../../../store/action-creators/VideoActionCreator';
import { returnObjectForGalleryPaginationUpdate } from '../../../store/action-creators/GalleryActionCreator';
import PaginationMeta from '../../../models/pagination/PaginationMeta';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { returnObjectForImagesPaginationUpdate } from '../../../store/action-creators/ImageActionCreator';
import { returnObjectForContentFiltersUpdate } from '../../../store/action-creators/content-resources';
import { remmapContentFilters } from '../Articles/Helpers/ArticleHelper';
import Profile from '../../../models/profile/Profile';
import AffiliatesService from '../../../services/affiliates-service/affiliates-service';
import REACT_APP_URLS from '../../../global-helpers/global-url.helpers';

export const affiliatesService: AffiliatesService = new AffiliatesService();

type Properties = {
	i18n: any;
	t: any;
	tReady: boolean;
	updateVideosPagination: (pagination: PaginationMeta) => any;
	updateGalleriesPagination: (pagination: PaginationMeta) => any;
	updateArticlesPagination: (pagination: PaginationMeta) => any;
	updateImagesPagination: (pagination: PaginationMeta) => any;
	updateContentFilters: (filters: any) => any;
	profile: Profile;
};
type State = {
	selectedWidget: any;
	scrollToWidgetConfig: any;
};

class WidgetsEmbedComponent extends React.Component<Properties, State> {
	affiliates = featuresService.getFeatureConfig(FeatureTypes.AFFILIATES);

	constructor(props: Properties) {
		super(props);
		this.state = {
			selectedWidget: JSON.parse(JSON.stringify(ODDS_BLOCK.default_data)),
			scrollToWidgetConfig: React.createRef(),
		};
	}

	componentDidMount(): void {
		// Controls for when the user tries to directly access a video if the video feature is disabled
		if (!featuresService.areFeaturesEnabled([FeatureTypes.WIDGET_EMBED])) {
			// @ts-ignore
			this.props.history.push('/dashboard');
		}
		this.props.updateArticlesPagination(PaginationMeta.builder().withCurrentPage(1).build());
		this.props.updateVideosPagination(PaginationMeta.builder().withCurrentPage(1).build());
		this.props.updateGalleriesPagination(PaginationMeta.builder().withCurrentPage(1).build());
		this.props.updateImagesPagination(PaginationMeta.builder().withCurrentPage(1).build());
		const filters = remmapContentFilters({}, {}, {}, {}, {});
		this.props.updateContentFilters(filters);
	}

	scrollToSelectedWidget = () => {
		this.state.scrollToWidgetConfig.current.scrollIntoView({
			behavior: 'smooth',
			block: 'start',
		});
	};

	constructAffiliateConfig = () => {
		return affiliatesService.constructAffiliateConfig(this.props.profile.id, this.affiliates);
	};

	getLoaderConfig = () => {
		const { currentProject } = store.getState().project;
		return (
			'<script>\n' +
			'    new SMPWidgetsLoader({\n' +
			`        CDN_URL: \'${REACT_APP_URLS.REACT_APP_WIDGETS_CDN_URL}\',\n` +
			`        ODD_CLIENT: \'${currentProject.defaultOddClient}\',\n` +
			`        API_URL: \'${currentProject.footballApiUrl}\',\n` +
			`        AUTHOR: true,\n` +
			"        API_KEY: '2f852b4de2f3a5cf3188e74b33087bb4:2c18422f8e826a7d11aeed52edb2449e',\n" +
			`        LANG: \'${
				multiLingualService.checkIfProjectIsMultiLingual(currentProject.languages) && multiLingualService.checkRoutePath()
					? multiLingualService.setCorrectAcceptLanguageCode(currentProject.languages.defaultLanguageCode.languageCode)
					: currentProject.language.split('-')[0]
			}\',\n` +
			` ${
				featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.AFFILIATES) &&
				affiliatesService.checkIfAdminHasAffiliate(this.props.profile.id, this.affiliates) &&
				this.constructAffiliateConfig().length > 0
					? `       AFFILIATE_CONFIG: \'${this.constructAffiliateConfig()}\',\n`
					: ''
			}` +
			'    });\n' +
			'</script>'
		);
	};

	private setWidgetTypeState = (widget: any) => {
		this.setState({
			selectedWidget: widget,
		});

		this.scrollToSelectedWidget();
	};

	private copyCode = () => {
		const { t } = this.props;

		try {
			navigator.clipboard.writeText(this.getLoaderConfig());
		} catch (e) {}
		toast.success(t('code_widget_loader_copy_success'));
	};

	render() {
		const { selectedWidget } = this.state;
		const { t } = this.props;
		return (
			<div className='widgets-embed-container animated fadeIn'>
				<div className='card'>
					<div className='card-header'>
						<div className={'d-flex align-items-center'}>
							<span className={'mr-auto mb-0'}>{t('widgets_page')}</span>
						</div>
					</div>
					<div className='card-body'>
						<Row>
							<Col>
								<WidgetButtonList t={t} onSelect={(widgetType: any) => this.setWidgetTypeState(widgetType)} />
							</Col>
						</Row>
						<Row>
							<Col>
								<hr />
							</Col>
						</Row>
						<Row>
							<Col>
								<Label>Widget configuration</Label>
							</Col>
						</Row>
						<Row>
							<Col>
								<div ref={this.state.scrollToWidgetConfig} className={'blocky-main-container'}>
									<div id='blocky-wrapper' className={'blocky mb-3'}>
										<BlockControlsWrapper t={t} block={selectedWidget as BlockModel} blocksLength={1} blockIndex={0} singleBlock>
											{new WidgetBlocksGenerator().createBlock(selectedWidget, t)}
										</BlockControlsWrapper>
									</div>
								</div>
							</Col>
						</Row>
						<Row>
							<Col>
								<div className='card'>
									<div className='card-header'>
										<Row>
											<Col className='title-container'>
												<div>{t('widget-loader-config-code')}</div>
											</Col>
											<Col className='text-right'>
												<Button color='success' onClick={this.copyCode}>
													{' '}
													{t('copy_code')}{' '}
												</Button>
											</Col>
										</Row>
									</div>
									<div className='card-body'>
										<div className='mb-2'>{t('widget_loader_info')}</div>
										<div className='widget-loader-code-container'>
											<code className='widget-loader-code'>{this.getLoaderConfig()}</code>
										</div>
									</div>
								</div>
							</Col>
						</Row>
					</div>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state: any) {
	return {
		profile: state.profile.profile,
	};
}

function mapDispatchToProps(dispatch: any) {
	return {
		updateGalleriesPagination: (pagination: PaginationMeta) => dispatch(returnObjectForGalleryPaginationUpdate(pagination)),
		updateVideosPagination: (pagination: PaginationMeta) => dispatch(returnObjectForVideoPaginationUpdate(pagination)),
		updateArticlesPagination: (pagination: PaginationMeta) => dispatch(returnObjectForArticlePaginationUpdate(pagination)),
		updateImagesPagination: (pagination: PaginationMeta) => dispatch(returnObjectForImagesPaginationUpdate(pagination)),
		updateContentFilters: (filters: any) => dispatch(returnObjectForContentFiltersUpdate(filters)),
	};
}

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(WidgetsEmbedComponent) as React.ComponentType;
