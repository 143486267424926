import { FootballSingleEventWidgetJson } from './football-single-event-widget.json';
import FootballSingleEventWidgetModel from './football-single-event-widget.model';
import MatchModel from '../../../../../../../models/v2/match/entity/match.model';
import TeamModel from '../../../../../../../models/v2/Team/Entity/TeamModel';
import { RefreshTimeValuesType } from '../../../partials/refresh-time-input/helper';

export default class FootballSingleEventWidgetBuilder {
	readonly json: FootballSingleEventWidgetJson;

	constructor(footballSingleEvent?: FootballSingleEventWidgetModel | FootballSingleEventWidgetJson) {
		if (footballSingleEvent && footballSingleEvent instanceof FootballSingleEventWidgetModel) {
			this.json = footballSingleEvent.toJson();
		} else if (footballSingleEvent) {
			this.json = footballSingleEvent;
		} else {
			this.json = {} as FootballSingleEventWidgetJson;
		}
	}

	withMatch(match: MatchModel): FootballSingleEventWidgetBuilder {
		this.json.match = match;

		return this;
	}

	withTeams(teams: TeamModel[]): FootballSingleEventWidgetBuilder {
		this.json.teams = teams;

		return this;
	}

	withDisplayOdds(displayOdds: boolean): FootballSingleEventWidgetBuilder {
		this.json.displayOdds = displayOdds;

		return this;
	}

	withFansUnitedEnabled(fansUnitedEnabled: boolean): FootballSingleEventWidgetBuilder {
		this.json.fansUnitedEnabled = fansUnitedEnabled;

		return this;
	}

	withFansUnitedExpanded(fansUnitedExpanded: boolean): FootballSingleEventWidgetBuilder {
		this.json.fansUnitedExpanded = fansUnitedExpanded;

		return this;
	}

	withRefreshTime(refreshTime: RefreshTimeValuesType): FootballSingleEventWidgetBuilder {
		this.json.refreshTime = refreshTime;

		return this;
	}

	build(): FootballSingleEventWidgetModel {
		return FootballSingleEventWidgetModel.fromJson(this.json);
	}
}
