import Image from '../../../../../models/image/Image';
import GalleryItem from '../../../../../models/gallery/item/GalleryItem';
import { featuresService } from '../../../../../App';
import { FeatureTypes } from '../../../../../services/feature-service/features.enum';

export const galleryItemsActions = (t: any) => {
	let defaultActions = [
		{ value: 'origin', label: t('assign_origin'), title: t('origins') },
		{ value: 'type', label: t('assign_type'), title: t('images_type') },
		{ value: 'authors', label: t('author_edit'), title: t('author_edit') },
		featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.WATERMARK) && {
			value: 'addWatermark',
			label: t('add_watermark_multiple'),
			title: t('add_watermark_multiple'),
		},
		{ value: 'delete', label: t('remove_selected'), title: t('remove_action') },
	];
	if (featuresService.areFeaturesEnabled([FeatureTypes.FOOTBALL_CONNECTIONS, FeatureTypes.FOOTBALL])) {
		defaultActions.push({
			value: 'sportsConnections',
			label: t('assign_sports_connections'),
			title: t('sports_connections'),
		});
	}
	if (featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.WATERMARK)) {
		defaultActions.push({
			value: 'deleteWatermark',
			label: t('delete_watermark_multiple'),
			title: t('delete_watermark_multiple'),
		});
	}
	return defaultActions;
};

export const imageToGalleryItem = (image: Image): GalleryItem => {
	return GalleryItem.builder()
		.withType('image')
		.withComment(image.description ? image.description : '')
		.withId(image.id)
		.withGeneric(image.generic)
		.withData(image)
		.build();
};

export const imageTypeToGalleryItem = (image: any, type: string): GalleryItem => {
	return GalleryItem.builder().withType(type).withId(image.id).withData(image).build();
};

export const selectAllKeys = {
	ctrlKey: true,
	metaKey: true,
	shiftKey: true,
	keyCode: 65,
};

export const deselectAllKeys = {
	ctrlKey: true,
	metaKey: true,
	shiftKey: true,
	keyCode: 76,
};

export const ctrlClickKeys = {
	ctrlKey: true,
	metaKey: true,
};

export const selectItemCondition = (e: any, keyMap: any) => {
	if (e && keyMap) {
		return e.ctrlKey === keyMap.ctrlKey || e.metaKey === keyMap.ctrlKey;
	}

	return false;
};

export const selectAllItemsCondition = (e: any, keyMap: any) => {
	if (e && keyMap) {
		return (e.ctrlKey === keyMap.ctrlKey || e.metaKey === keyMap.ctrlKey) && e.shiftKey === keyMap.shiftKey && e.keyCode === keyMap.keyCode;
	}

	return false;
};

export const defineContentToUpdate = (actionValue: string, state: any) => {
	switch (actionValue) {
		case 'origin':
			return state.selectedProvider;
		case 'type':
			return state.selectedType;
		case 'authors':
			return state.selectedAuthors;
		default:
			return state.footballRelated.concat(state.sportsRelated);
	}
};

/* Returns array of image items which contain generic field with watermark:true */
export const filterWatermarkedGalleryItems = (items: GalleryItem[]) => {
	return items.filter((item: GalleryItem) => {
		return item.generic && item.generic.watermark;
	});
};

export const checkIfItemIsWatermarked = (item: GalleryItem) => {
	return item.generic && item.generic.watermark;
};

export const checkIfItemIsNotWatermarked = (item: GalleryItem) => {
	return !item.generic || !item.generic.watermark;
};

//Returns array of file names for all selected images.
export const returnFileNameFromGalleryItems = (imageItems: GalleryItem[]) => {
	return imageItems.map((item) => {
		return item.data.path;
	});
};

/* Merge image ID and file name.
   Returns an object array. Key - Image ID, Value: Path ID + File type.
   Example: [{21265322045533: image.jpg}] */
export const mapImageIDandFileName = (imageIDs: string[], imageFileNames: string[]) => {
	let watermarkedItemMap: object[] = [];
	imageIDs.forEach((image: string, index) => {
		watermarkedItemMap.push({ [image]: imageFileNames[index] });
	});

	return watermarkedItemMap;
};
/** Extracts watermarked item from a gallery item ID **/
export const extractWatermarkedItem = (itemID: string, itemProps: GalleryItem[]) => {
	let watermarkedGalleryItems: GalleryItem[] = [];
	let galleryItemFromID = extractGalleryItemsFromIds(itemID, itemProps);
	if (checkIfItemIsWatermarked(galleryItemFromID)) {
		watermarkedGalleryItems.push(galleryItemFromID);
	}
	return watermarkedGalleryItems;
};

/** Extracts not watermarked item from a gallery item ID **/
export const extractNotWatermarkedItem = (itemID: string, itemProps: GalleryItem[]) => {
	let galleryItemsNotWatermarked: GalleryItem[] = [];
	let galleryItemFromID = extractGalleryItemsFromIds(itemID, itemProps);
	if (checkIfItemIsNotWatermarked(galleryItemFromID)) {
		galleryItemsNotWatermarked.push(galleryItemFromID);
	}
	return galleryItemsNotWatermarked;
};

/** Receives an ID for a selected item in gallery.
 Returns corresponding gallery item for the ID.**/
export const extractGalleryItemsFromIds = (itemID: string, itemsProps: GalleryItem[]) => {
	let galleryItemsArr = itemsProps && itemsProps.filter((galleryItem: GalleryItem) => galleryItem.id === itemID);
	return galleryItemsArr[0];
};

/** Returns a map from imageID and file name.
 Example: [
 {5678987655: "test1.jpg"},
 {2387656789: "test2.jpg"}
 ]
 **/
export const generateMapImageIdFilenameWatermark = (items: GalleryItem[]) => {
	let watermarkedImageItems = filterWatermarkedGalleryItems(items);
	let selectedWatermarkImageIDs = returnIDsFromGalleryItems(watermarkedImageItems);
	let selectedWatermarkImageFileNames = returnFileNameFromGalleryItems(watermarkedImageItems);
	return selectedWatermarkImageFileNames && mapImageIDandFileName(selectedWatermarkImageIDs, selectedWatermarkImageFileNames);
};

/** Returns a map from imageID and file name.
 Example: [
 {5678987655: "test1.jpg"},
 {2387656789: "test2.jpg"}
 ]
 **/
export const generateMapImageIdFilenameForWatermarkAdd = (items: GalleryItem[]) => {
	let selectedImageIDs = returnIDsFromGalleryItems(items);
	let selectedImageFileNames = returnFileNameFromGalleryItems(items);
	return selectedImageFileNames && mapImageIDandFileName(selectedImageIDs, selectedImageFileNames);
};

// Returns an array of image IDs
export const returnIDsFromGalleryItems = (items: GalleryItem[]) => {
	return items.map((item: GalleryItem) => item.data.id);
};

export const returnFileName = (imagePath: string) => {
	let array = imagePath.split('/');
	return array[array.length - 1];
};
