import TranslationTeamBuilder from './translations-team.builder';
import { TranslationTeamJson } from './translations-team.json';

export default class TranslationTeamModel {
	readonly team: any;
	readonly coach: any;
	readonly venue: any;
	readonly referee: any;
	readonly country: any;

	private constructor(team: any, coach: any, venue: any, referee: any, country: any) {
		this.team = team;
		this.coach = coach;
		this.venue = venue;
		this.referee = referee;
		this.country = country;
	}

	toJson(): TranslationTeamJson {
		return {
			team: this.team,
			coach: this.coach,
			venue: this.venue,
			referee: this.referee,
			country: this.country,
		};
	}

	static fromJson(json: TranslationTeamJson): TranslationTeamModel {
		return new TranslationTeamModel(json.team, json.coach, json.venue, json.referee, json.country);
	}

	static builder(translations?: TranslationTeamJson): TranslationTeamBuilder {
		return new TranslationTeamBuilder(translations);
	}
}
