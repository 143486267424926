import * as React from 'react';
import { FormGroup, Label } from 'reactstrap';
import { DebounceInput } from 'react-debounce-input';
import ErrorAsync from '../../BaseComponents/ErrorComponent/ErrorAsync';

type CodeProps = {
	t: any;
	onChange: (text: string) => any;
	value?: string;
};

export const Code: React.FunctionComponent<CodeProps> = ({ onChange, t, value }) => {
	return (
		<FormGroup>
			<Label htmlFor='code'>{t('code')}</Label>
			<DebounceInput
				element='textarea'
				name='code-input'
				id='code-input'
				rows='7'
				className='form-control-lg form-control'
				onChange={(event: any) => onChange(event.target.value)}
				value={value ? value : ''}
				placeholder={t('code_placeholder')}
			/>
			<ErrorAsync t={t} errorType='code' />
		</FormGroup>
	);
};
