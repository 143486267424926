import React from 'react';
import { ViewTypes } from '../../../constants/general.constants';
import BlockModel from '../../../models/block.model';
import AmgVideoEdit from './amg-video-block-edit.component';
import { AmgVideoView } from './amg-video-block-view.component';

export type Properties = {
	t: any;
	view: ViewTypes;
	block: BlockModel;
	blocks: BlockModel[];
};

export const AmgBlockComponent: React.FunctionComponent<Properties> = ({ t, view, block, blocks }) => {
	if (view === ViewTypes.edit) {
		return <AmgVideoEdit t={t} block={block} blocks={blocks} />;
	}

	if (view === ViewTypes.normal) {
		return <AmgVideoView t={t} video={block.data.preview} />;
	}
	return null;
};
