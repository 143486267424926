import * as React from 'react';
import { FormGroup, Label } from 'reactstrap';
import { DebounceInput } from 'react-debounce-input';
import ErrorAsync from '../../BaseComponents/ErrorComponent/ErrorAsync';
import '../summary/summary.style.scss';
import { displayCharacterLimit, setInputBoxBorder } from '../summary/summary-validation.helper';

type DescriptionProps = {
	t: any;
	onChange: (text: string) => any;
	showValidation: boolean;
	value?: string;
};

export const Description: React.FunctionComponent<DescriptionProps> = ({ onChange, t, showValidation, value }) => {
	return (
		<FormGroup>
			<Label htmlFor='code'>{t('description')}</Label>
			<DebounceInput
				element='textarea'
				name='description-input'
				id='description-input'
				rows='7'
				className={`form-control-lg form-control ${showValidation && setInputBoxBorder(value)} ${showValidation && 'summary'}`}
				onChange={(event: any) => onChange(event.target.value)}
				value={value ? value : ''}
				placeholder={t('description_placeholder')}
			/>
			<ErrorAsync t={t} errorType='description' />
			{showValidation && (
				<div className={`flex-row float-right font-weight-bold`}>
					<Label className={value && value.length > 500 && 'limit-exceeded'}>{displayCharacterLimit(value)}</Label>
				</div>
			)}
		</FormGroup>
	);
};
