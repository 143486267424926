// Football widget blocks
import { BugWidget, ContentTypes, EditorTypes, FansUnitedWidgetsTypes, WidgetTypes, WidgetTypesV2 } from './block.types';
import { FeatureTypes } from '../../../../services/feature-service/features.enum';
import { featuresService } from '../../../../App';
import {
	BASKETBALL_LIVESCORE_BLOCK_PREVIEW,
	BASKETBALL_SINGLE_BLOCK_PREVIEW,
	BASKETBALL_STANDINGS_BLOCK_PREVIEW,
	FIXTURES_BLOCK_PREVIEW,
	FOOTBALL_SINGLE_EVENT_BLOCK_PREVIEW,
	FOOTBALL_STANDINGS_BLOCK_PREVIEW,
	FOOTBALL_TEAM_PROGRAMME_PREVIEW_BLOCK,
	FOTBALL_LIVESCORE_BLOCK_PREVIEW,
	MATCH_BLOCK_PREVIEW,
	MATCH_LIST_STAGE_ROUND_BLOCK_PREVIEW,
	MOST_DECORATED_PLAYERS_PREVIEW,
	ODDS_BLOCK_PREVIEW,
	PLAYER_CAREER_BLOCK_PREVIEW,
	PLAYER_H2H_BLOCK_PREVIEW,
	PLAYER_PROFILE_BLOCK_PREVIEW,
	SQUAD_STATISTICS_PREVIEW,
	SQUAD_TEAM_PLAYERS_BLOCK_PREVIEW,
	STANDINGS_BLOCK_PREVIEW,
	TEAM_COMPARISON_BLOCK_PREVIEW,
	TEAM_FORM_BLOCK_PREVIEW,
	TEAM_PROFILE_BLOCK_PREVIEW,
	TENNIS_SINGLE_EVENT_BLOCL_PREVIEW,
	TOP_SCORERS_BLOCK_PREVIEW,
	TENNIS_LIVESCORE_BLOCK_PREVIEW,
	FOOTBALL_TEAM_PROFILE_PREVIEW_BLOCK,
	TENNIS_RANKING_BLOCK_PREVIEW,
	TENNIS_PLAYOFF_BLOCK_PREVIEW,
	BASKETBALL_TOURNAMENT_PROGRAMME_PREVIEW,
	BASKETBALL_TEAM_PROGRAMME_PREVIEW,
	FOOTBALL_TEAM_H2H_BLOCK_PREVIEW,
	FOOTBALL_TEAM_MATCH_STATSBLOCK_PREVIEW,
	TENNIS_ATHLETE_PROGRAMME_BLOCK_PREVIEW,
	TENNIS_TOURNAMENT_PROGRAMME_BLOCK_PREVIEW,
	FOOTBALL_LINEUPS_PREVIEW,
	ODDS_2_BLOCK_PREVIEW,
	STAKES_BLOCK_PREVIEW,
} from './block-list-widget-previews.constants';
import CustomBlockModel from '../../../../models/v2/custom-blocks/custom-block/custom-block.model';
import CustomizationApiHttpService from '../../../../models/v2/custom-blocks/services/custom-blocks-http.service';
import { SportTypes } from '../../../../constants/sport.types';

export const ODDS_BLOCK = {
	name: 'odds',
	feature_id: FeatureTypes.WIDGET_ODDS,
	sport_feature_id: FeatureTypes.FOOTBALL,
	icon: 'fa fa-line-chart',
	description: 'odd_widget_descr',
	preview: ODDS_BLOCK_PREVIEW,
	default_data: { data: { widget_type: WidgetTypes.odds, sport: SportTypes.FOOTBALL }, type: 'widget_smp' },
};

export const MATCH_LIST_STAGE_ROUND = {
	name: 'match_list_stage_round',
	feature_id: FeatureTypes.WIDGET_MATCH_LIST_STAGE_ROUND,
	sport_feature_id: FeatureTypes.FOOTBALL,
	icon: 'fa fa-soccer-ball-o',
	description: 'match_list_stage_round_descr',
	preview: MATCH_LIST_STAGE_ROUND_BLOCK_PREVIEW,
	default_data: { data: { widget_type: WidgetTypes.widgetMatchListStageRound, sport: SportTypes.FOOTBALL }, type: 'widget_smp' },
};

export const TEAM_PROFILE_BLOCK = {
	name: 'team_profile',
	feature_id: FeatureTypes.WIDGET_TEAM_PROFILE,
	sport_feature_id: FeatureTypes.FOOTBALL,
	icon: 'fa fa-people-group',
	description: 'team_profile_widget_descr',
	preview: TEAM_PROFILE_BLOCK_PREVIEW,
	default_data: { data: { widget_type: WidgetTypes.teamProfile, sport: SportTypes.FOOTBALL }, type: 'widget_smp' },
};

export const SQUAD_TEAM_PLAYERS_BLOCK = {
	name: 'team_players',
	feature_id: FeatureTypes.WIDGET_SQUAD,
	sport_feature_id: FeatureTypes.FOOTBALL,
	icon: 'fa fa-users',
	description: 'squad_team_players_widget_descr',
	preview: SQUAD_TEAM_PLAYERS_BLOCK_PREVIEW,
	default_data: { data: { widget_type: WidgetTypes.teamPlayers, sport: SportTypes.FOOTBALL }, type: 'widget_smp' },
};

export const PLAYER_PROFILE_BLOCK = {
	name: 'player_profile',
	feature_id: FeatureTypes.WIDGET_PLAYER_PROFILE,
	sport_feature_id: FeatureTypes.FOOTBALL,
	icon: 'fa fa-user-large',
	description: 'player_profile_widget_descr',
	preview: PLAYER_PROFILE_BLOCK_PREVIEW,
	default_data: { data: { widget_type: WidgetTypes.playerProfile, sport: SportTypes.FOOTBALL }, type: 'widget_smp' },
};

export const MATCH_BLOCK = {
	name: 'match',
	feature_id: FeatureTypes.WIDGET_MATCH,
	sport_feature_id: FeatureTypes.FOOTBALL,
	icon: 'fa fa-soccer-ball-o',
	description: 'match_widget_descr',
	preview: MATCH_BLOCK_PREVIEW,
	default_data: { data: { widget_type: WidgetTypes.match, sport: SportTypes.FOOTBALL }, type: 'widget_smp' },
};

export const FIXTURES_BLOCK = {
	name: 'fixtures',
	feature_id: FeatureTypes.WIDGET_FIXTURES,
	sport_feature_id: FeatureTypes.FOOTBALL,
	icon: 'fa fa-th-list',
	description: 'fixtures_widget_descr',
	preview: FIXTURES_BLOCK_PREVIEW,
	default_data: { data: { widget_type: WidgetTypes.fixtures, sport: SportTypes.FOOTBALL }, type: 'widget_smp' },
};

export const TOP_SCORERS_BLOCK = {
	name: 'top_scorers',
	feature_id: FeatureTypes.WIDGET_TOP_SCORERS,
	sport_feature_id: FeatureTypes.FOOTBALL,
	icon: 'fa fa-users',
	description: 'top_goal_scorers_widget_descr',
	preview: TOP_SCORERS_BLOCK_PREVIEW,
	default_data: { data: { widget_type: WidgetTypes.topScorers, sport: SportTypes.FOOTBALL }, type: 'widget_smp' },
};

export const STANDINGS_BLOCK = {
	name: 'standings',
	feature_id: FeatureTypes.WIDGET_STANDINGS,
	sport_feature_id: FeatureTypes.FOOTBALL,
	icon: 'fa fa-chart-column',
	description: 'stadings_widget_descr',
	preview: STANDINGS_BLOCK_PREVIEW,
	default_data: { data: { widget_type: WidgetTypes.standings, sport: SportTypes.FOOTBALL }, type: 'widget_smp' },
};

export const MOST_DECORATED_PLAYERS_BLOCK = {
	name: 'most_decorated_players',
	feature_id: FeatureTypes.WIDGET_MOST_DECORATED_PLAYERS,
	sport_feature_id: FeatureTypes.FOOTBALL,
	icon: 'fa fa-users',
	description: 'most_decorated_players_widget_descr',
	preview: MOST_DECORATED_PLAYERS_PREVIEW,
	default_data: { data: { widget_type: WidgetTypes.widgetMostDecoratedPlayers, sport: SportTypes.FOOTBALL }, type: 'widget_smp' },
};

export const PLAYER_H2H_BLOCK = {
	name: 'player_H2H',
	feature_id: FeatureTypes.WIDGET_PLAYER_H2H,
	sport_feature_id: FeatureTypes.FOOTBALL,
	icon: 'fa fa-user-group',
	description: 'player_h2h_widget_descr',
	preview: PLAYER_H2H_BLOCK_PREVIEW,
	default_data: { data: { widget_type: WidgetTypes.widgetPlayerH2H, sport: SportTypes.FOOTBALL }, type: 'widget_smp' },
};

export const TEAM_COMPARISON_BLOCK = {
	name: 'team_comparison',
	feature_id: FeatureTypes.WIDGET_TEAM_H2H,
	sport_feature_id: FeatureTypes.FOOTBALL,
	icon: 'fa fa-users',
	description: 'team_comparison_widget_descr',
	preview: TEAM_COMPARISON_BLOCK_PREVIEW,
	default_data: { data: { widget_type: WidgetTypes.widgetTeamComparison, sport: SportTypes.FOOTBALL }, type: 'widget_smp' },
};

export const TEAM_FORM_BLOCK = {
	name: 'team_form',
	feature_id: FeatureTypes.WIDGET_TEAM_FORM,
	sport_feature_id: FeatureTypes.FOOTBALL,
	icon: 'fa fa-percent',
	description: 'team_form_widget_descr',
	preview: TEAM_FORM_BLOCK_PREVIEW,
	default_data: { data: { widget_type: WidgetTypes.widgetTeamForm, sport: SportTypes.FOOTBALL }, type: 'widget_smp' },
};

export const PLAYER_CAREER_BLOCK = {
	name: 'player_career',
	feature_id: FeatureTypes.WIDGET_PLAYER_CAREER,
	sport_feature_id: FeatureTypes.FOOTBALL,
	icon: 'fa fa-user',
	description: 'player_career_widget_descr',
	preview: PLAYER_CAREER_BLOCK_PREVIEW,
	default_data: { data: { widget_type: WidgetTypes.widgetPlayerCareer, sport: SportTypes.FOOTBALL }, type: 'widget_smp' },
};
export const SQUAD_STATISTICS_BLOCK = {
	name: 'squad_statistics',
	feature_id: FeatureTypes.WIDGET_SQUAD_STATISTICS,
	sport_feature_id: FeatureTypes.FOOTBALL,
	icon: 'fa fa-bar-chart',
	description: 'squad_statistics_widget_descr',
	preview: SQUAD_STATISTICS_PREVIEW,
	default_data: { data: { widget_type: WidgetTypes.widgetSquadStatistics, sport: SportTypes.FOOTBALL }, type: 'widget_smp' },
};

// Fans United
export const FANS_UNITED_1X2_BLOCK = {
	name: '1x2',
	feature_id: FeatureTypes.FANS_UNITED,
	icon: 'fa fa-line-chart',
	description: '1x2_widget_descr',
	default_data: { data: { widget_type: FansUnitedWidgetsTypes.widget1x2, sport: SportTypes.FOOTBALL }, type: 'widget_fu' },
};

export const FANS_UNITED_DOUBLE_CHANCE_BLOCK = {
	name: 'double_chance',
	feature_id: FeatureTypes.FANS_UNITED,
	icon: 'fa-solid fa-dice',
	description: 'double_chance_widget_descr',
	default_data: { data: { widget_type: FansUnitedWidgetsTypes.doubleChance, sport: SportTypes.FOOTBALL }, type: 'widget_fu' },
};

export const FANS_UNITED_CORRECT_SCORE_BLOCK = {
	name: 'correct_score',
	feature_id: FeatureTypes.FANS_UNITED,
	icon: 'fa-solid fa-chart-column',
	description: 'correct_score_widget_descr',
	default_data: { data: { widget_type: FansUnitedWidgetsTypes.correctScore, sport: SportTypes.FOOTBALL }, type: 'widget_fu' },
};

export const FANS_UNITED_FT_HT = {
	name: 'ht_ft',
	feature_id: FeatureTypes.FANS_UNITED,
	icon: 'fa-solid fa-hourglass-end',
	description: 'ht_ft_widget_descr',
	default_data: { data: { widget_type: FansUnitedWidgetsTypes.htFt, sport: SportTypes.FOOTBALL }, type: 'widget_fu' },
};

export const FANS_UNITED_PLAYER_PREDICTIONS = {
	name: 'player_predictions',
	feature_id: FeatureTypes.FANS_UNITED,
	icon: 'fa-solid fa-person-running',
	description: 'player_predictions_widget_descr',
	default_data: { data: { widget_type: FansUnitedWidgetsTypes.playerPredictions, sport: SportTypes.FOOTBALL }, type: 'widget_fu' },
};

export const FANS_UNITED_TEAM_PREDICTIONS = {
	name: 'team_predictions',
	feature_id: FeatureTypes.FANS_UNITED,
	icon: 'fa-solid fa-people-group',
	description: 'team_predictions_widget_descr',
	default_data: { data: { widget_type: FansUnitedWidgetsTypes.teamPredictions, sport: SportTypes.FOOTBALL }, type: 'widget_fu' },
};

export const FANS_UNITED_TOP_X_MANAGER = {
	name: 'top_x',
	feature_id: FeatureTypes.FANS_UNITED,
	icon: 'fa fa-globe',
	description: 'top_x_manager_widget_descr',
	default_data: { data: { widget_type: FansUnitedWidgetsTypes.topX, sport: SportTypes.FOOTBALL }, type: 'widget_fu' },
};

export const FANS_UNITED_MATCH_QUIZ_MANAGER = {
	name: 'match_quiz',
	feature_id: FeatureTypes.FANS_UNITED,
	icon: 'fa fa-globe',
	description: 'match_quiz_manager_widget_descr',
	default_data: { data: { widget_type: FansUnitedWidgetsTypes.matchQuiz, sport: SportTypes.FOOTBALL }, type: 'widget_fu' },
};

export const FANS_UNITED_LEADERBOARD_MANAGER = {
	name: 'leaderboard',
	feature_id: FeatureTypes.FANS_UNITED,
	icon: 'fa fa-globe',
	description: 'leaderboard_manager_widget_descr',
	default_data: { data: { widget_type: FansUnitedWidgetsTypes.leaderboard, sport: SportTypes.FOOTBALL }, type: 'widget_fu' },
};

export const FANS_UNITED_CLASSIC_QUIZ_MANAGER = {
	name: 'classic_quiz',
	feature_id: FeatureTypes.FANS_UNITED,
	icon: 'fa fa-globe',
	description: 'classic_quiz_manager_widget_descr',
	default_data: { data: { widget_type: FansUnitedWidgetsTypes.classicQuiz, sport: SportTypes.FOOTBALL }, type: 'widget_fu' },
};

export const FANS_UNITED_EITHER_OR_MANAGER = {
	name: 'either_or',
	feature_id: FeatureTypes.FANS_UNITED,
	icon: 'fa fa-globe',
	description: 'either_or_manager_widget_descr',
	default_data: { data: { widget_type: FansUnitedWidgetsTypes.eitherOr, sport: SportTypes.FOOTBALL }, type: 'widget_fu' },
};

// Content blocks
export const ARTICLE_BLOCK = {
	name: 'article',
	icon: 'fa fa-file-text-o',
	default_data: { data: {}, type: ContentTypes.article },
};

export const VIDEO_BLOCK = {
	name: 'video',
	feature_id: FeatureTypes.VIDEO,
	icon: 'fa fa-play',
	default_data: { data: {}, type: ContentTypes.video },
};

export const INSTAT_TEAM_COMPARISON = {
	name: 'instat_team_comparison',
	feature_id: FeatureTypes.INSTAT_INTEGRATION,
	setAndEnabled: true,
	icon: 'fa fa-bar-chart',
	default_data: { data: {}, type: ContentTypes.instatTeamComparison },
};

export const INSTAT_PLAYER_COMPARISON = {
	name: 'instat_player_comparison',
	feature_id: FeatureTypes.INSTAT_INTEGRATION,
	setAndEnabled: true,
	icon: 'fa fa-bar-chart',
	default_data: { data: {}, type: ContentTypes.instatPlayerComparison },
};

export const INSTAT_PLAYER_SEASON_PERFORMANCE = {
	name: 'instat_player_season_performance',
	feature_id: FeatureTypes.INSTAT_INTEGRATION,
	setAndEnabled: true,
	icon: 'fa fa-bar-chart',
	default_data: { data: {}, type: ContentTypes.instatPlayerSeasonPerformance },
};

export const INSTAT_TEAM_SEASON_PERFORMANCE = {
	name: 'instat_team_season_performance',
	feature_id: FeatureTypes.INSTAT_INTEGRATION,
	setAndEnabled: true,
	icon: 'fa fa-bar-chart',
	default_data: { data: {}, type: ContentTypes.instatTeamSeasonPerformance },
};

export const INSTAT_POST_MATCH_TEAMS_STATS = {
	name: 'instat_post_match_teams_stats',
	feature_id: FeatureTypes.INSTAT_INTEGRATION,
	setAndEnabled: true,
	icon: 'fa fa-bar-chart',
	default_data: { data: {}, type: ContentTypes.instatPostMatchTeamsStats },
};

export const INSTAT_POST_MATCH_PLAYERS_STATS = {
	name: 'instat_post_match_players_stats',
	feature_id: FeatureTypes.INSTAT_INTEGRATION,
	setAndEnabled: true,
	icon: 'fa fa-bar-chart',
	default_data: { data: {}, type: ContentTypes.instatPostMatchPlayersStats },
};

export const GALLERY_BLOCK = {
	name: 'gallery',
	icon: 'fa fa-camera',
	default_data: { data: {}, type: ContentTypes.gallery },
};

export const IMAGE_BLOCK = {
	name: 'image',
	icon: 'fa fa-image',
	default_data: { data: {}, type: ContentTypes.image },
};

export const EMBED_CODE_BLOCK = {
	name: 'embed_code',
	icon: 'fa fa-code',
	default_data: { data: {}, type: ContentTypes.embed },
};

export const HIGHLIGHT_BLOCK = {
	name: 'highlight',
	icon: 'fa fa-asterisk',
	default_data: { data: {}, type: ContentTypes.highlight },
};

export const LINK_BLOCK = {
	name: 'link',
	icon: 'fa fa-link',
	default_data: { data: {}, type: ContentTypes.link },
};

export const BANNER_BLOCK = {
	name: 'banner',
	icon: 'fa fa-square-o',
	default_data: { data: {}, type: ContentTypes.banner },
};

export const DUGOUT_VIDEO_BLOCK = {
	name: 'dugoutVideo',
	icon: 'fa fa-play',
	feature_id: FeatureTypes.DUGOUT_INTEGRATION,
	default_data: { data: {}, type: ContentTypes.dugoutVideo },
};

export const ON_NETWORK_VIDEO_BLOCK = {
	name: 'onnetworkVideo',
	icon: 'fa fa-play',
	feature_id: FeatureTypes.ON_NETWORK_INTEGRATION,
	default_data: { data: {}, type: ContentTypes.onnetworkVideo },
};

export const AUDIO_BLOCK = {
	name: 'audioBlock',
	icon: 'fa fa-microphone',
	feature_id: ContentTypes.audioBlock,
	default_data: { data: {}, type: ContentTypes.audioBlock },
};

export const AMG_BLOCK = {
	name: 'streamAMG',
	icon: 'fa fa-play',
	setAndEnabled: true,
	feature_id: FeatureTypes.AMG_INTEGRATION,
	default_data: { data: {}, type: ContentTypes.amgBlock },
};

export const IMAGO_BLOCK = {
	name: 'imago',
	icon: 'fa fa-image',
	feature_id: FeatureTypes.IMAGO_INTEGRATION,
	default_data: { data: {}, type: ContentTypes.imago },
};

export const GETTY_BLOCK = {
	name: 'getty',
	icon: 'fa-solid fa-camera-retro',
	feature_id: FeatureTypes.GETTY_IMAGES,
	default_data: { data: {}, type: ContentTypes.getty },
};

// Text blocks
export const PARAGRAPH_BLOCK = {
	name: 'paragraph',
	icon: 'fa fa-paragraph',
	default_data: { data: { type: EditorTypes.paragraph, content: '<p></p>' }, type: EditorTypes.editor },
};

export const HEADING_BLOCK = {
	name: 'heading',
	icon: 'fa fa-header',
	default_data: { data: { type: EditorTypes.heading, content: '<h2></h2>' }, type: EditorTypes.editor },
};

export const QUOTE_BLOCK = {
	name: 'quote',
	icon: 'fa fa-quote-left',
	default_data: {
		data: { type: EditorTypes.quote, content: '<blockquote><p></p></blockquote>' },
		type: EditorTypes.editor,
	},
};

export const LIST_BLOCK = {
	name: 'list',
	icon: 'fa fa-list',
	default_data: { data: { type: EditorTypes.list, content: '' }, type: EditorTypes.editor },
};

export const TABLE_BLOCK = {
	name: 'table',
	icon: 'fa fa-table',
	default_data: { data: { type: EditorTypes.table, content: '' }, type: EditorTypes.editor },
};

export const BUG_ARRAY_BLOCK = {
	name: BugWidget.arrayBug,
	icon: 'fa fa-bug',
	default_data: { data: {}, type: BugWidget.arrayBug },
};

export const YOUTUBE_SPORTS_BLOCK = {
	name: 'youtubeSports',
	icon: 'fa fa-play',
	feature_id: FeatureTypes.YOUTUBE_SPORTS,
	default_data: { data: {}, type: ContentTypes.youtubeSports },
};

//Basketball blocks
export const BASKETBALL_SINGLE_EVENT_BLOCK = {
	name: 'basketballSingleEvent',
	icon: 'fa fa-basketball',
	description: 'basketball_single_event_widget_descr',
	feature_id: FeatureTypes.BASKETBALL_SINGLE_EVENT,
	sport_feature_id: FeatureTypes.BASKETBALL,
	preview: BASKETBALL_SINGLE_BLOCK_PREVIEW,
	default_data: { data: { widget_type: WidgetTypesV2.basketballSingleEvent, sport: SportTypes.BASKETBALL }, type: WidgetTypesV2.widget },
};

export const BASKETBALL_LIVESCORE_BLOCK = {
	name: 'basketballLivescore',
	icon: 'fa-solid fa-chart-bar',
	description: 'basketball_livescore_widget_descr',
	feature_id: FeatureTypes.BASKETBALL_LIVESCORE,
	sport_feature_id: FeatureTypes.BASKETBALL,
	preview: BASKETBALL_LIVESCORE_BLOCK_PREVIEW,
	default_data: { data: { widget_type: WidgetTypesV2.basketballLivescore, sport: SportTypes.BASKETBALL }, type: WidgetTypesV2.widget },
};

export const BASKETBALL_STANDINGS_BLOCK = {
	name: 'basketballStandings',
	icon: 'fa fa-chart-column',
	description: 'stadings_widget_descr',
	feature_id: FeatureTypes.BASKETBALL_STANDINGS,
	sport_feature_id: FeatureTypes.BASKETBALL,
	preview: BASKETBALL_STANDINGS_BLOCK_PREVIEW,
	default_data: { data: { widget_type: WidgetTypesV2.basketballStandings, sport: SportTypes.BASKETBALL }, type: WidgetTypesV2.widget },
};

export const BASKETBALL_TOURNAMENT_PROGRAMME_BLOCK = {
	name: 'basketballTournamentProgramme',
	icon: 'fa fa-calendar-days',
	description: 'tournament_programme_widget_descr',
	preview: BASKETBALL_TOURNAMENT_PROGRAMME_PREVIEW,
	feature_id: FeatureTypes.BASKETBALL_TOURNAMENT_PROGRAMME_BLOCK,
	sport_feature_id: FeatureTypes.BASKETBALL,
	default_data: {
		data: { widget_type: WidgetTypesV2.basketballTournamentProgramme, sport: SportTypes.BASKETBALL },
		type: WidgetTypesV2.widget,
	},
};

export const BASKETBALL_TEAM_PROGRAMME_BLOCK = {
	name: 'basketballTeamProgramme',
	icon: 'fa fa-calendar-days',
	description: 'team_programme_widget_descr',
	preview: BASKETBALL_TEAM_PROGRAMME_PREVIEW,
	feature_id: FeatureTypes.BASKETBALL_TEAM_PROGRAMME_BLOCK,
	sport_feature_id: FeatureTypes.BASKETBALL,
	default_data: {
		data: { widget_type: WidgetTypesV2.basketballTeamProgramme, sport: SportTypes.BASKETBALL },
		type: WidgetTypesV2.widget,
	},
};

// Tennis blocks
export const TENNIS_SINGLE_EVENT_BLOCK = {
	name: 'tennisSingleEvent',
	icon: 'fa-solid fa-table-tennis-paddle-ball',
	description: 'tennis_single_event_widget_descr',
	preview: TENNIS_SINGLE_EVENT_BLOCL_PREVIEW,
	feature_id: FeatureTypes.TENNIS_SINGLE_EVENT,
	sport_feature_id: FeatureTypes.TENNIS,
	default_data: { data: { widget_type: WidgetTypesV2.tennisSingleEvent, sport: SportTypes.TENNIS }, type: WidgetTypesV2.widget },
};

export const TENNIS_LIVESCORE_BLOCK = {
	name: 'tennisLivescore',
	icon: 'fa-solid fa-chart-bar',
	description: 'tennis_livescore_widget_descr',
	feature_id: FeatureTypes.TENNIS_LIVESCORE,
	sport_feature_id: FeatureTypes.TENNIS,
	preview: TENNIS_LIVESCORE_BLOCK_PREVIEW,
	default_data: { data: { widget_type: WidgetTypesV2.tennisLivescore, sport: SportTypes.TENNIS }, type: WidgetTypesV2.widget },
};

export const TENNIS_RANKING_BLOCK = {
	name: 'tennisRanking',
	icon: 'fa-solid fa-ranking-star',
	description: 'tennis_ranking_widget_descr',
	feature_id: FeatureTypes.TENNIS_RANKING,
	sport_feature_id: FeatureTypes.TENNIS,
	preview: TENNIS_RANKING_BLOCK_PREVIEW,
	default_data: { data: { widget_type: WidgetTypesV2.tennisRanking, sport: SportTypes.TENNIS }, type: WidgetTypesV2.widget },
};

export const TENNIS_PLAYOFF_BLOCK = {
	name: 'tennisPlayoff',
	icon: 'fa-solid fa-trophy',
	description: 'tennis_playoff_widget_descr',
	feature_id: FeatureTypes.TENNIS_PLAYOFF,
	sport_feature_id: FeatureTypes.TENNIS,
	preview: TENNIS_PLAYOFF_BLOCK_PREVIEW,
	default_data: { data: { widget_type: WidgetTypesV2.tennisPlayoff, sport: SportTypes.TENNIS }, type: WidgetTypesV2.widget },
};

export const TENNIS_ATHLETE_PROGRAMME_BLOCK = {
	name: 'tennisAthleteProgramme',
	icon: 'fa fa-calendar-days',
	description: 'tennis_athlete_programme_widget_descr',
	preview: TENNIS_ATHLETE_PROGRAMME_BLOCK_PREVIEW,
	feature_id: FeatureTypes.TENNIS_ATHLETE_PROGRAMME_BLOCK,
	sport_feature_id: FeatureTypes.TENNIS,
	default_data: { data: { widget_type: WidgetTypesV2.tennisAthleteProgramme, sport: SportTypes.TENNIS }, type: WidgetTypesV2.widget },
};

export const TENNIS_TOURNAMENT_PROGRAMME_BLOCK = {
	name: 'tennisTournamentProgramme',
	icon: 'fa fa-calendar-days',
	description: 'tennis_tournament_programme_widget_descr',
	preview: TENNIS_TOURNAMENT_PROGRAMME_BLOCK_PREVIEW,
	feature_id: FeatureTypes.TENNIS_TOURNAMENT_PROGRAMME_BLOCK,
	sport_feature_id: FeatureTypes.TENNIS,
	default_data: { data: { widget_type: WidgetTypesV2.tennisTournamentProgramme, sport: SportTypes.TENNIS }, type: WidgetTypesV2.widget },
};

//Widgets V2
export const FOOTBALL_SINGLE_EVENT_BLOCK = {
	name: 'footballSingleEvent',
	icon: 'fa fa-soccer-ball-o',
	description: 'football_single_event_widget_descr',
	preview: FOOTBALL_SINGLE_EVENT_BLOCK_PREVIEW,
	feature_id: FeatureTypes.FOOTBALL_SINGLE_EVENT,
	sport_feature_id: FeatureTypes.FOOTBALL_V2,
	default_data: { data: { widget_type: WidgetTypesV2.footballSingleEvent, sport: SportTypes.FOOTBALL }, type: WidgetTypesV2.widget },
};

export const FOOTBALL_LIVESCORE_BLOCK = {
	name: 'footballLivescore',
	icon: 'fa-solid fa-chart-bar',
	description: 'football_livescore_widget_descr',
	preview: FOTBALL_LIVESCORE_BLOCK_PREVIEW,
	feature_id: FeatureTypes.FOOTBALL_LIVESCORE,
	sport_feature_id: FeatureTypes.FOOTBALL_V2,
	default_data: { data: { widget_type: WidgetTypesV2.footballLivescore, sport: SportTypes.FOOTBALL }, type: WidgetTypesV2.widget },
};

export const FOOTBALL_STANDINGS_BLOCK = {
	name: 'footballStandings',
	icon: 'fa fa-chart-column',
	description: 'stadings_widget_descr',
	preview: FOOTBALL_STANDINGS_BLOCK_PREVIEW,
	feature_id: FeatureTypes.FOOTBALL_STANDINGS,
	sport_feature_id: FeatureTypes.FOOTBALL_V2,
	default_data: { data: { widget_type: WidgetTypesV2.footballStandings, sport: SportTypes.FOOTBALL }, type: WidgetTypesV2.widget },
};

export const FOOTBALL_TEAM_PROGRAMME_BLOCK = {
	name: 'footballTeamProgramme',
	icon: 'fa fa-calendar-days',
	description: 'team_programme_widget_descr',
	preview: FOOTBALL_TEAM_PROGRAMME_PREVIEW_BLOCK,
	feature_id: FeatureTypes.FOOTBALL_TEAM_PROGRAMME,
	sport_feature_id: FeatureTypes.FOOTBALL_V2,
	default_data: { data: { widget_type: WidgetTypesV2.footballTeamProgramme, sport: SportTypes.FOOTBALL }, type: WidgetTypesV2.widget },
};

export const FOOTBALL_KNOCKOUT_BLOCK = {
	name: 'footballKnockout',
	icon: 'fa-solid fa-trophy',
	description: 'knockout_widget_descr',
	preview: null,
	feature_id: FeatureTypes.FOOTBALL_KNOCKOUT,
	sport_feature_id: FeatureTypes.FOOTBALL_V2,
	default_data: { data: { widget_type: WidgetTypesV2.footballKnockout, sport: SportTypes.FOOTBALL }, type: WidgetTypesV2.widget },
};

export const FOOTBALL_PLAYER_BLOCK = {
	name: 'footballPlayer',
	icon: 'fa-solid fa-user',
	description: 'player_profile_widget_descr',
	preview: null,
	feature_id: FeatureTypes.FOOTBALL_PLAYER,
	sport_feature_id: FeatureTypes.FOOTBALL_V2,
	default_data: { data: { widget_type: WidgetTypesV2.footballPlayer, sport: SportTypes.FOOTBALL }, type: WidgetTypesV2.widget },
};

export const FOOTBALL_TEAM_BLOCK = {
	name: 'footballTeam',
	icon: 'fa fa-people-group',
	description: 'team_profile_widget_descr',
	preview: FOOTBALL_TEAM_PROFILE_PREVIEW_BLOCK,
	feature_id: FeatureTypes.FOOTBALL_TEAM,
	sport_feature_id: FeatureTypes.FOOTBALL_V2,
	default_data: { data: { widget_type: WidgetTypesV2.footballTeam, sport: SportTypes.FOOTBALL }, type: WidgetTypesV2.widget },
};

export const FOOTBALL_TEAM_SQUAD_BLOCK = {
	name: 'footballTeamSquad',
	icon: 'fa fa-users',
	description: 'squad_team_players_widget_descr',
	preview: null,
	feature_id: FeatureTypes.FOOTBALL_TEAM_SQUAD,
	sport_feature_id: FeatureTypes.FOOTBALL_V2,
	default_data: { data: { widget_type: WidgetTypesV2.footballTeamSquad, sport: SportTypes.FOOTBALL }, type: WidgetTypesV2.widget },
};

export const FOOTBALL_TOURNAMENT_PROGRAMME_BLOCK = {
	name: 'footballTournamentProgramme',
	icon: 'fa fa-calendar-days',
	description: 'tournament_programme_widget_descr',
	preview: null,
	feature_id: FeatureTypes.FOOTBALL_TOURNAMENT_PROGRAMME,
	sport_feature_id: FeatureTypes.FOOTBALL_V2,
	default_data: { data: { widget_type: WidgetTypesV2.footballTournamentProgramme, sport: SportTypes.FOOTBALL }, type: WidgetTypesV2.widget },
};

export const FOOTBALL_PLAYER_H2H_BLOCK = {
	name: 'footballPlayerH2H',
	icon: 'fa fa-user-group',
	description: 'player_h2h_widget_descr',
	preview: null,
	feature_id: FeatureTypes.FOOTBALL_PLAYER_H2H,
	sport_feature_id: FeatureTypes.FOOTBALL_V2,
	default_data: { data: { widget_type: WidgetTypesV2.footballPlayerH2H, sport: SportTypes.FOOTBALL }, type: WidgetTypesV2.widget },
};

export const FOOTBALL_TEAM_H2H_BLOCK = {
	name: 'footballTeamH2H',
	icon: 'fa fa-users',
	description: 'team_comparison_widget_descr',
	preview: FOOTBALL_TEAM_H2H_BLOCK_PREVIEW,
	feature_id: FeatureTypes.FOOTBALL_TEAM_H2H,
	sport_feature_id: FeatureTypes.FOOTBALL_V2,
	default_data: {
		data: { widget_type: WidgetTypesV2.footballTeamH2H, sport: SportTypes.FOOTBALL, subType: WidgetTypesV2.footballTeamSeasonStats },
		type: WidgetTypesV2.widget,
	},
};

export const FOOTBALL_TEAM_MATCH_STATS_BLOCK = {
	name: 'footballTeamMatchStats',
	icon: 'fa fa-percent',
	description: 'team_comparison_widget_descr',
	preview: FOOTBALL_TEAM_MATCH_STATSBLOCK_PREVIEW,
	feature_id: FeatureTypes.FOOTBALL_TEAM_H2H,
	sport_feature_id: FeatureTypes.FOOTBALL_V2,
	default_data: {
		data: { widget_type: WidgetTypesV2.footballTeamH2H, sport: SportTypes.FOOTBALL, subType: WidgetTypesV2.footballTeamMatchStats },
		type: WidgetTypesV2.widget,
	},
};

export const FOOTBALL_MATCHES_H2H_BLOCK = {
	name: 'footballMatchesH2H',
	icon: 'fa fa-soccer-ball-o',
	description: 'matches_comparison_widget_descr',
	preview: null,
	feature_id: FeatureTypes.FOOTBALL_MATCHES_H2H,
	sport_feature_id: FeatureTypes.FOOTBALL_V2,
	default_data: { data: { widget_type: WidgetTypesV2.footballMatchesH2H, sport: SportTypes.FOOTBALL }, type: WidgetTypesV2.widget },
};

export const FOOTBALL_TEAM_FORM_BLOCK = {
	name: 'team_form',
	icon: 'fa fa-percent',
	description: 'team_form_widget_descr',
	preview: null,
	feature_id: FeatureTypes.FOOTBALL_TEAM_FORM,
	sport_feature_id: FeatureTypes.FOOTBALL_V2,
	default_data: { data: { widget_type: WidgetTypesV2.footballTeamForm, sport: SportTypes.FOOTBALL }, type: WidgetTypesV2.widget },
};

export const FOOTBALL_TOP_SCORERS_BLOCK = {
	name: 'top_scorers',
	icon: 'fa fa-users',
	description: 'top_goal_scorers_widget_descr',
	preview: null,
	feature_id: FeatureTypes.FOOTBALL_TOP_SCORERS,
	sport_feature_id: FeatureTypes.FOOTBALL_V2,
	default_data: { data: { widget_type: WidgetTypesV2.footballTopScorers, sport: SportTypes.FOOTBALL }, type: WidgetTypesV2.widget },
};

export const FOOTBALL_MOST_DECORATED_PLAYERS_BLOCK = {
	name: 'widgetFootballMostDecoratedPlayers',
	icon: 'fa fa-users',
	description: 'most_decorated_players_widget_descr',
	preview: null,
	feature_id: FeatureTypes.FOOTBALL_MOST_DECORATED_PLAYERS,
	sport_feature_id: FeatureTypes.FOOTBALL_V2,
	default_data: {
		data: { widget_type: WidgetTypesV2.footballMostDecoratedPlayers, sport: SportTypes.FOOTBALL },
		type: WidgetTypesV2.widget,
	},
};

export const FOOTBALL_LINEUPS_BLOCK = {
	name: 'widgetFootballLineups',
	icon: 'fa fa-people-group',
	description: 'match_widget_descr',
	feature_id: FeatureTypes.FOOTBALL_LINEUPS,
	sport_feature_id: FeatureTypes.FOOTBALL_V2,
	preview: FOOTBALL_LINEUPS_PREVIEW,
	default_data: { data: { widget_type: WidgetTypesV2.footballLineups, sport: SportTypes.FOOTBALL }, type: WidgetTypesV2.widget },
};

export const FOOTBALL_ODDS_BLOCK = {
	name: 'widgetFootballOdds',
	icon: 'fa fa-line-chart',
	description: 'odd_widget_descr',
	feature_id: FeatureTypes.WIDGET_ODDS_V2,
	sport_feature_id: FeatureTypes.FOOTBALL_V2,
	preview: ODDS_2_BLOCK_PREVIEW,
	default_data: { data: { widget_type: WidgetTypesV2.footballOdds, sport: SportTypes.FOOTBALL }, type: WidgetTypesV2.widget },
};

export const FOOTBALL_STAKES_BLOCK = {
	name: 'widgetFootballStakes',
	icon: 'fa fa-dollar-sign',
	description: 'stakes_widget_descr',
	feature_id: FeatureTypes.WIDGET_STAKES,
	sport_feature_id: FeatureTypes.FOOTBALL_V2,
	preview: STAKES_BLOCK_PREVIEW,
	default_data: { data: { widget_type: WidgetTypesV2.footballStakes, sport: SportTypes.FOOTBALL }, type: WidgetTypesV2.widget },
};

const defineEnabledBlocks = (blocks: any[]) =>
	blocks.filter((block: any) => {
		if (block.setAndEnabled) {
			return featuresService.checkFeatureIsSetAndEnabled(block.feature_id);
		}

		if (block.feature_id === FeatureTypes.FANS_UNITED) {
			const fansUnitedFeatureConfig = featuresService.getFeatureConfig(FeatureTypes.FANS_UNITED);
			if (block.name === 'top_x') {
				return (
					featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.FANS_UNITED) &&
					fansUnitedFeatureConfig.hasOwnProperty('managers') &&
					fansUnitedFeatureConfig.managers.hasOwnProperty('top_x') &&
					fansUnitedFeatureConfig.managers.top_x
				);
			} else if (block.name === 'match_quiz') {
				return (
					featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.FANS_UNITED) &&
					fansUnitedFeatureConfig.hasOwnProperty('managers') &&
					fansUnitedFeatureConfig.managers.hasOwnProperty('match_quiz') &&
					fansUnitedFeatureConfig.managers.match_quiz
				);
			} else if (block.name === 'leaderboard') {
				return (
					featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.FANS_UNITED) &&
					fansUnitedFeatureConfig.hasOwnProperty('managers') &&
					fansUnitedFeatureConfig.managers.hasOwnProperty('leaderboard') &&
					fansUnitedFeatureConfig.managers.leaderboard
				);
			} else if (block.name === 'classic_quiz') {
				return (
					featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.FANS_UNITED) &&
					fansUnitedFeatureConfig.hasOwnProperty('managers') &&
					fansUnitedFeatureConfig.managers.hasOwnProperty('classic_quiz') &&
					fansUnitedFeatureConfig.managers.classic_quiz
				);
			} else if (block.name === 'either_or') {
				return (
					featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.FANS_UNITED) &&
					fansUnitedFeatureConfig.hasOwnProperty('managers') &&
					fansUnitedFeatureConfig.managers.hasOwnProperty('either_or') &&
					fansUnitedFeatureConfig.managers.either_or
				);
			}
		}

		if (block.sport_feature_id) {
			return featuresService.areFeaturesEnabled([block.feature_id, block.sport_feature_id]);
		} else {
			return featuresService.areFeaturesEnabled([block.feature_id]);
		}
	});

export const clearCustomBlocks = () => {
	return (customBlocks = []);
};

export const extractCustomBlocks = async () => {
	let result = await CustomizationApiHttpService.getCustomBlocksListing();

	const seen = new Set();
	let filteredArr;

	if (result && result.data && result.data.data && result.data.data.length > 0) {
		result.data.data.forEach((block: CustomBlockModel, index: any) => {
			customBlocks[index] = {
				id: block.id,
				application_url: block.application_url,
				status: block.status,
				name: block.name,
				icon: block.icon.value,
				feature_id: FeatureTypes.CUSTOM_BLOCKS,
				default_data: {
					data: {
						id: block.id,
						application_url: block.application_url,
						name: block.name,
						icon: block.icon.value,
					},
					type: ContentTypes.customBlock,
				},
			};
		});

		filteredArr = customBlocks.filter((el: any) => {
			const duplicate = seen.has(el.id);
			seen.add(el.id);
			return !duplicate;
		});
		customBlocks = filteredArr;
	} else {
		customBlocks = [];
	}
};

let customBlocks: any[] = [];

const mostPopularBlocks = [
	PARAGRAPH_BLOCK,
	HEADING_BLOCK,
	QUOTE_BLOCK,
	EMBED_CODE_BLOCK,
	LINK_BLOCK,
	HIGHLIGHT_BLOCK,
	ARTICLE_BLOCK,
	GALLERY_BLOCK,
	IMAGE_BLOCK,
	VIDEO_BLOCK,
	ODDS_BLOCK,
	MATCH_BLOCK,
	STANDINGS_BLOCK,
];

const textBlocks = [PARAGRAPH_BLOCK, HEADING_BLOCK, QUOTE_BLOCK, LIST_BLOCK, TABLE_BLOCK];

const widgetBlocks = [
	ODDS_BLOCK,
	TEAM_PROFILE_BLOCK,
	SQUAD_TEAM_PLAYERS_BLOCK,
	PLAYER_PROFILE_BLOCK,
	MATCH_BLOCK,
	TOP_SCORERS_BLOCK,
	STANDINGS_BLOCK,
	PLAYER_H2H_BLOCK,
	TEAM_COMPARISON_BLOCK,
	MATCH_LIST_STAGE_ROUND,
	TEAM_FORM_BLOCK,
	MOST_DECORATED_PLAYERS_BLOCK,
	PLAYER_CAREER_BLOCK,
	SQUAD_STATISTICS_BLOCK,
];

const basketballWidgetBlocks = [
	BASKETBALL_SINGLE_EVENT_BLOCK,
	BASKETBALL_LIVESCORE_BLOCK,
	BASKETBALL_STANDINGS_BLOCK,
	BASKETBALL_TOURNAMENT_PROGRAMME_BLOCK,
	BASKETBALL_TEAM_PROGRAMME_BLOCK,
];

const fansUnitedWidgetBlocks = [
	FANS_UNITED_1X2_BLOCK,
	FANS_UNITED_DOUBLE_CHANCE_BLOCK,
	FANS_UNITED_CORRECT_SCORE_BLOCK,
	FANS_UNITED_FT_HT,
	FANS_UNITED_TEAM_PREDICTIONS,
	FANS_UNITED_PLAYER_PREDICTIONS,
	FANS_UNITED_TOP_X_MANAGER,
	FANS_UNITED_MATCH_QUIZ_MANAGER,
	FANS_UNITED_LEADERBOARD_MANAGER,
	FANS_UNITED_CLASSIC_QUIZ_MANAGER,
	FANS_UNITED_EITHER_OR_MANAGER,
];

const contentBlocks = [
	ARTICLE_BLOCK,
	GALLERY_BLOCK,
	IMAGE_BLOCK,
	IMAGO_BLOCK,
	GETTY_BLOCK,
	VIDEO_BLOCK,
	DUGOUT_VIDEO_BLOCK,
	YOUTUBE_SPORTS_BLOCK,
	ON_NETWORK_VIDEO_BLOCK,
	AUDIO_BLOCK,
	// AMG_BLOCK, // Support for AMG video is discontinued
	EMBED_CODE_BLOCK,
	HIGHLIGHT_BLOCK,
	LINK_BLOCK,
	BANNER_BLOCK,
	INSTAT_TEAM_COMPARISON,
	INSTAT_PLAYER_COMPARISON,
	INSTAT_PLAYER_SEASON_PERFORMANCE,
	INSTAT_TEAM_SEASON_PERFORMANCE,
	INSTAT_POST_MATCH_TEAMS_STATS,
	INSTAT_POST_MATCH_PLAYERS_STATS,
];

const widgetsBlocksV2 = [
	FOOTBALL_SINGLE_EVENT_BLOCK,
	FOOTBALL_LIVESCORE_BLOCK,
	FOOTBALL_STANDINGS_BLOCK,
	FOOTBALL_TEAM_PROGRAMME_BLOCK,
	FOOTBALL_KNOCKOUT_BLOCK,
	FOOTBALL_PLAYER_BLOCK,
	FOOTBALL_TEAM_BLOCK,
	FOOTBALL_TEAM_SQUAD_BLOCK,
	FOOTBALL_TOURNAMENT_PROGRAMME_BLOCK,
	FOOTBALL_PLAYER_H2H_BLOCK,
	FOOTBALL_TEAM_H2H_BLOCK,
	FOOTBALL_MATCHES_H2H_BLOCK,
	FOOTBALL_TEAM_MATCH_STATS_BLOCK,
	FOOTBALL_TEAM_FORM_BLOCK,
	FOOTBALL_TOP_SCORERS_BLOCK,
	FOOTBALL_MOST_DECORATED_PLAYERS_BLOCK,
	FOOTBALL_LINEUPS_BLOCK,
	FOOTBALL_ODDS_BLOCK,
	FOOTBALL_STAKES_BLOCK,
];

const tennisWidgetBlocks = [
	TENNIS_SINGLE_EVENT_BLOCK,
	TENNIS_LIVESCORE_BLOCK,
	TENNIS_RANKING_BLOCK,
	TENNIS_PLAYOFF_BLOCK,
	TENNIS_ATHLETE_PROGRAMME_BLOCK,
	TENNIS_TOURNAMENT_PROGRAMME_BLOCK,
];

export const bugBlocks = [BUG_ARRAY_BLOCK];

export const BLOCK_LIST = (extractBlockIcons: boolean) => [
	{
		id: 'most-popular-category-id',
		category_name: 'Most popular',
		category_name_translation: 'blocky_block_label_popular',
		blocks: extractBlockIcons ? mostPopularBlocks : defineEnabledBlocks(mostPopularBlocks),
	},
	{
		id: 'text-category-id',
		category_name: 'Text',
		category_name_translation: 'blocky_block_label_text',
		blocks: extractBlockIcons ? textBlocks : defineEnabledBlocks(textBlocks),
	},
	{
		id: 'football-widgets-category-id',
		feature_id: FeatureTypes.FOOTBALL,
		category_name: 'Football widgets',
		category_name_translation: 'blocky_block_label_football_widgets',
		blocks: extractBlockIcons ? widgetBlocks : defineEnabledBlocks(widgetBlocks),
	},
	{
		id: 'football-widgets-v2-category-id',
		feature_id: FeatureTypes.FOOTBALL_V2,
		category_name: 'Football widgets V2',
		category_name_translation: 'blocky_block_label_football_widgets_v2',
		blocks: extractBlockIcons ? widgetsBlocksV2 : defineEnabledBlocks(widgetsBlocksV2),
	},
	{
		id: 'basketball-widgets-category-id',
		feature_id: FeatureTypes.BASKETBALL,
		category_name: 'Basketball widgets',
		category_name_translation: 'blocky_block_label_basketball_widgets',
		blocks: extractBlockIcons ? basketballWidgetBlocks : defineEnabledBlocks(basketballWidgetBlocks),
	},
	{
		id: 'tennis-widgets-category-id',
		feature_id: FeatureTypes.TENNIS,
		category_name: 'Tennis widgets',
		category_name_translation: 'blocky_block_label_tennis_widgets',
		blocks: extractBlockIcons ? tennisWidgetBlocks : defineEnabledBlocks(tennisWidgetBlocks),
	},
	{
		id: 'fans-united-widgets-category-id',
		feature_id: FeatureTypes.FANS_UNITED,
		category_name: 'Fans united widgets',
		category_name_translation: 'blocky_block_label_fans_united_widgets',
		blocks: extractBlockIcons ? fansUnitedWidgetBlocks : defineEnabledBlocks(fansUnitedWidgetBlocks),
	},
	{
		id: 'content-category-id',
		category_name: 'Content',
		category_name_translation: 'blocky_block_label_content',
		blocks: extractBlockIcons ? contentBlocks : defineEnabledBlocks(contentBlocks),
	},

	{
		id: 'custom-blocks-category-id',
		feature_id: FeatureTypes.CUSTOM_BLOCKS,
		category_name: 'Custom',
		category_name_translation: 'custom_block_label_content',
		blocks: extractBlockIcons ? customBlocks : defineEnabledBlocks(customBlocks),
	},
];
