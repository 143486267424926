import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import MainMediaModel from '../../../../../../models/v2/main-media/main-media.model';
import { autoGenerateReduxPropsNamingForContentModels } from '../../../../../../services/content-models-service/ContentModelService';
import Project from '../../../../../../models/project/Project';
import ImageCropContainer from '../../../../Shared/image-crop/image-crop-container';
import { extractMainImageObjectFromArray } from '../../helpers/general';
import { DATA_QA_ATTRIBUTES } from '../../constants/data-qa.attributes';

type Properties = {
	project: Project;
	mainMediaImage: MainMediaModel | null;
	t: any;
};

const CropMainImage: React.FunctionComponent<Properties> = ({ t, project, mainMediaImage }) => {
	const [isModalOpen, setIsModalOpen] = useState(false);

	return (
		<>
			<Button
				id={DATA_QA_ATTRIBUTES.CROP_IMAGE}
				className='crop-button'
				block
				color='secondary'
				disabled={!mainMediaImage}
				onClick={() => setIsModalOpen(true)}
			>
				<i className={'fa fa-crop'}></i> {t('crop_image')}
			</Button>
			{mainMediaImage && (
				<ImageCropContainer
					t={t}
					isWatermarkAdded={false}
					isWatermarkRemoved={false}
					imageId={mainMediaImage.resourceId || ''}
					currentProject={project}
					open={isModalOpen}
					onClose={() => setIsModalOpen(false)}
				/>
			)}
		</>
	);
};

function mapStateToProps(state: any) {
	const { tempPropertyNaming, nestedTempPropertyNaming } = autoGenerateReduxPropsNamingForContentModels();
	const mainMediaRedux = state[tempPropertyNaming][nestedTempPropertyNaming].mainMedia || [];

	return {
		mainMediaImage: extractMainImageObjectFromArray(mainMediaRedux),
		project: state.project.currentProject,
	};
}

export default connect(mapStateToProps)(CropMainImage);
