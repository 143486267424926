import 'reflect-metadata';
import { jsonMember, jsonObject } from 'typedjson';

@jsonObject()
export default class UrlsModel {
	@jsonMember(String)
	public canonical_url: string | null = null;
	@jsonMember(String)
	public external_url: string | null = null;
}
