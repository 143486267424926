import LineupPlayerModel from '../../models/player-lineup/lineup-player.model';
import { SortEnd } from 'react-sortable-hoc';
import { Formation, PlayersType } from './response-remappers.helper';

// Return the real players in lineups list.
export const removePlaceholdersFromLineups = (positions: LineupPlayerModel[]) => {
	if (positions && positions.length > 0) {
		// Placeholder LineupPlayerModel does not have player data.
		return positions.filter((player: LineupPlayerModel) => player.player.id);
	}
	return [];
};

export const areTeamLineupsFilled = (positions: LineupPlayerModel[]) => {
	const lineups = removePlaceholdersFromLineups(positions);
	return lineups.length > 6 && lineups.length < 12;
};

// Adding a player to lineups list form the substitutes and squad lists is using this method.
export const addPlayerToLineups = (
	selectedFormation: Formation,
	player: LineupPlayerModel,
	playersList: LineupPlayerModel[],
	selectFormation: (formation: Formation) => void,
	setPlayers: (players: LineupPlayerModel[]) => void,
) => {
	// Extracting the real players in the lineups list from the placeholders.
	const lineups = removePlaceholdersFromLineups(selectedFormation.positions);

	if (lineups.length < 11) {
		// If lineups are less than 11 we remove the player from the substitutes/squad list .
		setPlayers([...playersList.filter(filterPlayers(player.player.id))]);

		const positions = [...selectedFormation.positions];

		// Next we add the player to the latest free player position in the team formation positions list (the lineups).
		positions[lineups.length > 0 ? lineups.length : 0] = LineupPlayerModel.builder()
			.withPlayer(player.player)
			.withShirtNumber(player.shirtNumber)
			.withPositionX(positions[lineups.length > 0 ? lineups.length : 0].positionX)
			.withPositionY(positions[lineups.length > 0 ? lineups.length : 0].positionY)
			.withTypeId(positions[lineups.length > 0 ? lineups.length : 0].typeId)
			.build();

		// Update the formation postitions.
		selectFormation({ formation: selectedFormation.formation, positions: positions });
	}
};

// Sorting lineups
export const reorderLineups = (sortEnd: SortEnd, lineups: LineupPlayerModel[] = []) => {
	// The Indexes from the sorting library.
	const { oldIndex, newIndex } = sortEnd;
	const reorderedLineups = [...lineups];
	// Define drag direction (increase or dеcrease indexes).
	const isReverseDrag = oldIndex > newIndex;
	// Define the lineup index used for player data update based on drag direction.
	const defineIndex = (index: number) => (isReverseDrag ? index - 1 : index + 1);

	// Replace the player information for each index and keep the positionX, positionY, and the typeId.
	if (lineups.length > 0) {
		lineups.forEach((lineup: LineupPlayerModel, index: number) => {
			// Replace the player info in items between old and new index with increased/dеcreased drag direction.
			const shouldReplace = (newIndex < index && index < oldIndex) || (oldIndex < index && index < newIndex) || index === oldIndex;

			if (shouldReplace && lineups[defineIndex(index)]) {
				reorderedLineups[index] = LineupPlayerModel.builder()
					.withPlayer(lineups[defineIndex(index)].player)
					.withShirtNumber(lineups[defineIndex(index)].shirtNumber)
					.withPositionX(lineup.positionX)
					.withPositionY(lineup.positionY)
					.withTypeId(lineup.typeId)
					.build();
			} // Replace the player info new index item with old index player information.
			if (index === newIndex) {
				reorderedLineups[index] = LineupPlayerModel.builder()
					.withPlayer(lineups[oldIndex].player)
					.withShirtNumber(lineups[oldIndex].shirtNumber)
					.withPositionX(lineup.positionX)
					.withPositionY(lineup.positionY)
					.withTypeId(lineup.typeId)
					.build();
			}
		});
	}

	// Return remordered lineups.
	return reorderedLineups as LineupPlayerModel[];
};

// The method filters list players by passed player id.
export const filterPlayers = (id: string | undefined) => (item: LineupPlayerModel) => item.player.id !== id;

// The method defines the selected formation positions placeholders.
export const defineDefaultPositions = (allFormations: Formation[], teamFormation: string) => {
	if (allFormations && teamFormation) {
		return allFormations.filter((formation: Formation) => formation.formation === teamFormation)[0] as Formation;
	}
	return { formation: null, positions: [] };
};

// Use selected formation placeholders to update the lineups players with the correct positions in this formation.
export const updateFormationPositions = (formation: Formation | null, lineups: LineupPlayerModel[], playersTypes: PlayersType) => {
	// Case 1: The team has selected formation.
	if (formation) {
		const positions = [...formation.positions];

		// Each position in the formation will be updated with lineup player
		[...lineups].forEach((player: LineupPlayerModel, index: number) => {
			return (positions[index] = LineupPlayerModel.builder()
				.withPlayer(player.player)
				.withShirtNumber(player.shirtNumber)
				.withPositionX(positions[index].positionX)
				.withPositionY(positions[index].positionY)
				.withTypeId(positions[index].typeId)
				.build());
		});

		return { formation: formation.formation, positions: positions } as Formation;
	} else {
		// Case 2: The team has no selected formation.
		// If the formation is cleared the players placeholders should be filtered and lineup players will be updated with player typeId '1' (STARTER).
		const positions = removePlaceholdersFromLineups([...lineups]).map((player: LineupPlayerModel) => {
			return LineupPlayerModel.builder()
				.withPlayer(player.player)
				.withShirtNumber(player.shirtNumber)
				.withPositionX(null)
				.withPositionY(null)
				.withTypeId(playersTypes.STARTER)
				.build();
		});

		return { formation: null, positions: positions } as Formation;
	}
};

export const mapPlayerToSubstututeType = (player: LineupPlayerModel, typeId: string) => {
	return LineupPlayerModel.builder(player).withPositionX(null).withPositionY(null).withTypeId(typeId).build();
};

export const mapPlayerToStarterType = (player: LineupPlayerModel, typeId: string) => {
	return LineupPlayerModel.builder(player).withPositionX(null).withPositionY(null).withTypeId(typeId).build();
};
