import * as React from 'react';
import { Properties, State } from './properties/ImageListingProps';
import { Button, Col, Row, Collapse, FormGroup, Input, InputGroup, InputGroupAddon } from 'reactstrap';
import ReactPaginate from 'react-paginate';
import { PaginationProps } from './properties/ImageListPaginateProps';
import { ImageCells } from './ImageCells';
import ErrorAsync from '../../../../../../BaseComponents/ErrorComponent/ErrorAsync';
import AdvancedContentFilters from '../../../../../../Shared/advanced-content-filter/advanced-content-filter.component';

export default class ImageListing extends React.Component<Properties, State> {
	constructor(props: Properties) {
		super(props);
		this.state = {
			collapseIsOpen: !props.withToggle,
			contentSearchInput: '',
			advancedContentSearchInput: '',
			currentPage: '1',
			pageSearchQuery: '',
		};
	}

	onContentSearchInput = (event: any) => {
		let searchText = event.target.value;
		const { currentPage } = this.state;

		this.setState({
			contentSearchInput: event.target.value,
		});

		if (searchText.length < 1 && this.state.advancedContentSearchInput.length < 0) {
			this.props.onImageSearch(searchText, currentPage);
		}
	};

	onContentSearch = (event: any) => {
		if (event.keyCode === 13 && this.state.contentSearchInput.length > 0) {
			this.onSearchContent();
		}
	};

	toggleCollapse = () => {
		this.props.onImagesOpen(!this.state.collapseIsOpen);
		this.setState({
			...this.state,
			collapseIsOpen: !this.state.collapseIsOpen,
		});
	};

	onSearchContent = () => {
		const { advancedContentSearchInput, contentSearchInput } = this.state;

		if (contentSearchInput.trim().length === 0 && advancedContentSearchInput.trim().length === 0) {
			this.setState({ ...this.state, contentSearchInput: '', advancedContentSearchInput: '', pageSearchQuery: '' });
			this.props.onImageSearch('', this.state.currentPage);
		}

		const contentSearch =
			contentSearchInput.trim().length > 0 ? contentSearchInput + advancedContentSearchInput : `*${advancedContentSearchInput}`;
		(contentSearchInput.trim().length > 0 || advancedContentSearchInput.trim().length > 0) &&
			this.props.onImageSearch(contentSearch, this.state.currentPage);
		this.setState({ ...this.state, pageSearchQuery: contentSearch });
	};

	onPageChange = (page: any) => {
		const { advancedContentSearchInput, contentSearchInput } = this.state;

		if (contentSearchInput.trim().length === 0 && advancedContentSearchInput.trim().length === 0) {
			this.setState({ ...this.state, pageSearchQuery: '' });
			this.props.onPageChange('', page);
		}

		const contentSearch =
			contentSearchInput.trim().length > 0 ? contentSearchInput + advancedContentSearchInput : `*${advancedContentSearchInput}`;
		(contentSearchInput.trim().length > 0 || advancedContentSearchInput.trim().length > 0) && this.props.onPageChange(contentSearch, page);
		this.setState({ ...this.state, pageSearchQuery: contentSearch });
	};

	updateAdvancedInputState = (text: string) => this.setState({ ...this.state, advancedContentSearchInput: text });

	getAllContent = () => {
		this.props.onImageSearch('', this.state.currentPage);
	};

	render() {
		const { t, images, pagination, withToggle, contentType } = this.props;

		return (
			<>
				{withToggle && (
					<Button
						className='m-0 p-0'
						color='link'
						onClick={this.toggleCollapse}
						aria-expanded={this.state.collapseIsOpen}
						aria-controls='toggle-sidebar-images-list-for-main-media'
					>
						{t('toggle_uploaded_images')}
					</Button>
				)}
				<Collapse isOpen={this.state.collapseIsOpen} id='toggle-sidebar-images-list-for-main-media' className='py-2'>
					<FormGroup row className='mb-0'>
						<Col md='12'>
							<InputGroup>
								<Input
									type='text'
									id={`search-input-${contentType ? contentType : ''}`}
									name='search-input'
									placeholder={t('content_search_placeholder')}
									onChange={this.onContentSearchInput}
									onKeyDown={this.onContentSearch}
									value={this.state.contentSearchInput}
								/>
								<InputGroupAddon addonType='append'>
									<Button type='button' color='primary' onClick={this.onSearchContent}>
										<i className={'fa fa-search'}>&nbsp;</i>
										{t('search')}
									</Button>
								</InputGroupAddon>
							</InputGroup>
						</Col>
					</FormGroup>
					<ErrorAsync t={t} errorType='query' />
					<Row className='mb-2'>
						<Col className='ml-auto'>
							<AdvancedContentFilters
								t={t}
								filtersInSidebar={true}
								updateAdvancedSearchText={this.updateAdvancedInputState}
								searchContent={this.onSearchContent}
								getAllContent={this.getAllContent}
								contentSearchInput={this.state.contentSearchInput}
								showOriginSelect={true}
								calledFrom='main-media'
								saveFiltersHistory={false}
							/>
						</Col>
					</Row>
					<div className={`${this.props.isContentLoading ? 'loading-overlay' : ''}`}>
						{images && images.length > 0 && <ImageCells images={images} onImageSelect={this.props.onImageSelect} />}
						{images && images.length === 0 && <h6 className='d-flex justify-content-center mb-2'>{this.props.t('no_data_found')}</h6>}

						<ReactPaginate
							{...PaginationProps(this.props.t, pagination.currentPage)}
							pageCount={pagination.totalPages ? pagination.totalPages : 0}
							onPageChange={(data: any) => this.onPageChange(data.selected + 1)}
						/>
					</div>
				</Collapse>
			</>
		);
	}
}
