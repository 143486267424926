import React, { FunctionComponent, useEffect, useState } from 'react';
import Select from 'react-select';
import { FormGroup, Label, Row } from 'reactstrap';
import * as helpers from '../../../../react-select-helpers/react-select.helpers';
import { isEmptyArray, sportsToOptions, sportToOption } from './sports-connections.helper';

export const SportsTypeSelect: FunctionComponent<{
	selectedSportsType: any;
	onSportsTypeSelect: (type: any) => void;
	t: any;
	sports: any;
}> = ({ selectedSportsType, onSportsTypeSelect, t, sports }) => {
	const [availableSports, setAvailableSports] = useState();

	useEffect(() => {
		setAvailableSports(sports);
	}, []);

	const options = sportsToOptions(availableSports);
	const onSportsSelect = (selection: any) => onSportsTypeSelect(helpers.optionToItem(selection));

	if (isEmptyArray(availableSports)) {
		return null;
	}
	return (
		<FormGroup data-qa='sports-type-select'>
			{!isEmptyArray(availableSports) && (
				<Row>
					<Label htmlFor='sports-type-select' className='ml-1'>
						{t('sports_type')}
					</Label>
				</Row>
			)}
			<Select
				isClearable={true}
				options={options}
				inputId='sports-type-select'
				className='w-100 mb-4'
				placeholder={t('select')}
				value={selectedSportsType && selectedSportsType.title ? sportToOption(selectedSportsType) : null}
				onChange={onSportsSelect}
				on
			/>
		</FormGroup>
	);
};
