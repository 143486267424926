import BlockModel from '../../../../../models/block.model';
import { ViewTypes } from '../../../../../constants/general.constants';
import React from 'react';
import BlockUpdateController from '../../../../block-update-controller.component';
import { compose } from 'redux';
import WidgetCorrectScoreEdit from './widget-correct-score-edit.component';
import { WidgetCorrectScoreView } from './widget-correct-score-view.component';

type Properties = {
	block: BlockModel;
	view: ViewTypes;
	t: any;
	lang: string;
	contentData: any;
	bookmakers: [];
};

const WidgetCorrectScore: React.FunctionComponent<Properties> = (props) => {
	if (props.view === ViewTypes.edit) {
		return <WidgetCorrectScoreEdit contentData={props.contentData} block={props.block} t={props.t} />;
	}

	if (props.view === ViewTypes.normal) {
		return (
			<WidgetCorrectScoreView
				t={props.t}
				widgetCorrectScore={props.block.data.widget}
				bookmakers={props.block.data.widget && props.block.data.widget.odds ? props.block.data.widget.odds : []}
			/>
		);
	}

	return null;
};

export default compose(BlockUpdateController)(WidgetCorrectScore);
