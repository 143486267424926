import React, { ChangeEvent, FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { FormGroup, Label } from 'reactstrap';
import {
	autoGenerateReduxPropsNamingForContentModels,
	generatePropsNamingByContentType,
	generateReduxPropsUpdateActionByContentType,
} from '../../../../../../services/content-models-service/ContentModelService';
import { extractContentModeBasedOnUrl, extractMainContentTypeBasedOnUrl } from '../../../../../../global-helpers/global.helpers';
import ContentAttribute from '../../../../../../models/v2/content-attributes/content-attributes.model';
import { DATA_QA_ATTRIBUTES } from '../../constants/data-qa.attributes';
import ContentProperty from './property';
import { extractPropertyNameFromRedux } from '../../helpers/properties.helper';
import { ReduxGeneralContentProps } from '../../constants/redux-general-content.attributes';

type Properties = {
	t: any;
	contentModelProperties: Record<string, boolean>;
	contentProperties: ContentAttribute[];
	updateTempProperty: (propertyObject: Record<string, any>) => void;
};

const PropertiesCheckboxes: FunctionComponent<Properties> = ({ t, contentModelProperties, contentProperties, updateTempProperty }) => {
	const onPropertyChange = (event: ChangeEvent<HTMLInputElement>) => {
		try {
			const propSlug = event.target.value;
			const propChecked = event.target.checked;
			const newPropertiesObject = { ...contentModelProperties, [propSlug]: propChecked };
			updateTempProperty({ [ReduxGeneralContentProps.PROPERTIES]: newPropertiesObject });
		} catch (error) {}
	};

	return (
		<FormGroup id={DATA_QA_ATTRIBUTES.PROPERTIES}>
			<Label>{t('properties')}</Label>
			{contentModelProperties &&
				Object.keys(contentModelProperties).length > 0 &&
				Object.keys(contentModelProperties).map((propertySlug: string) => {
					return (
						<ContentProperty
							key={propertySlug}
							name={extractPropertyNameFromRedux(contentProperties, propertySlug)}
							value={contentModelProperties[propertySlug]}
							slug={propertySlug}
							onPropertyChange={onPropertyChange}
						/>
					);
				})}
		</FormGroup>
	);
};

function mapStateToProps(state: any) {
	const contentTypeFromUrl = extractMainContentTypeBasedOnUrl() || '';
	const propertiesPropNaming = generatePropsNamingByContentType(contentTypeFromUrl, false) + 'Properties';
	const { tempPropertyNaming, nestedTempPropertyNaming } = autoGenerateReduxPropsNamingForContentModels();

	return {
		contentModelProperties: state[tempPropertyNaming][nestedTempPropertyNaming].properties || [],
		contentProperties: state.contentProperties[propertiesPropNaming] || [],
	};
}

function mapDispatchToProps(dispatch: Function) {
	const contentTypeFromUrl = extractMainContentTypeBasedOnUrl();
	const functionForDispatch = generateReduxPropsUpdateActionByContentType(contentTypeFromUrl);

	return {
		updateTempProperty: (propertyObject: Record<string, any>) =>
			functionForDispatch && dispatch(functionForDispatch(propertyObject, extractContentModeBasedOnUrl())),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(PropertiesCheckboxes);
