import TeamModel from './team.model';
import { TeamJson } from './team.json';
import { assetsPlaceholder } from '../../../../../../constants/assetsPlaceholder';
import TournamentModel from '../../../blocks/widgets/player-profile/models/tournament-model/tournament.model';

export default class TeamBuilder {
	private json: TeamJson;

	constructor(team?: TeamModel | TeamJson) {
		if (team && team instanceof TeamModel) {
			this.json = team.toJson();
		} else if (team) {
			this.json = team;
		} else {
			this.json = {} as TeamJson;
		}
	}

	withId(id: string): TeamBuilder {
		this.json.id = id;

		return this;
	}

	withName(name: string): TeamBuilder {
		this.json.name = name;

		return this;
	}

	withLogo(logo: string): TeamBuilder {
		this.json.url_logo = logo ? logo : assetsPlaceholder.team;

		return this;
	}

	withType(type: string): TeamBuilder {
		this.json.type = type;

		return this;
	}

	withTournament(tournament: TournamentModel): TeamBuilder {
		this.json.tournament = tournament;

		return this;
	}

	withThreeLetterCode(threeLetterCode: string): TeamBuilder {
		this.json.three_letter_code = threeLetterCode;

		return this;
	}

	withShortName(shortName: string): TeamBuilder {
		this.json.short_name = shortName;

		return this;
	}

	build(): TeamModel {
		return TeamModel.fromJson(this.json);
	}
}
