import React from 'react';
import '../Dashboard.scss';
import { Row } from 'reactstrap';
import { Properties } from './properties/global-chart-container.properties';
import GlobalChart from './global-chart';

const GlobalChartContainer: React.FunctionComponent<Properties> = ({ statisticCount, t, statisticDates }) => {
	return (
		<>
			{statisticCount && statisticCount.length > 0 && (
				<Row xs='12'>
					{statisticCount.map((statistic: any, index: number) => (
						<GlobalChart key={index} data={statistic} dates={statisticDates} index={index} t={t} />
					))}
				</Row>
			)}
		</>
	);
};

export default GlobalChartContainer;
