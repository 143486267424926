export default class OneSignalModel {
	// if you change property names, look at 'checkOneSignalNotificationData' function for validations
	public url: string = '';
	public title: string = '';
	public description: string = '';
	public recipients: string[] = [];
	public delivery: string = '';
	public imageURL: string = '';
	public sendDate: string = '';
}
