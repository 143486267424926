import React, { FunctionComponent } from 'react';
import { Button, Col, Input, Label, Row } from 'reactstrap';
import TournamentGroupModel from '../../../../../../models/v2/Tournament/Group/TournamentGroupModel';
import ErrorHandler from '../../../../../Partials/Blocky/partials/error/error-handler-component';
import { createTournamentsGroup, updateTournamentsGroup } from '../../../helpers/TournamentsGroupsService';
import {
	DisabledSaveModel,
	observedCharLengthRestriction,
	shouldSaveTournamentsGroupBeDisabled,
	tournamentGroupInfoChange,
} from '../../../helpers/TournamentsGroupsUiService';

type Props = {
	t: any;
	tournamentsGroup: TournamentGroupModel;
	setTournamentsGroup: Function;
	defaultTournamentsGroup: TournamentGroupModel;
	setDefaultTournamentsGroup: Function;
	disabledSave: DisabledSaveModel;
	setDisabledSave: Function;
	isNewGroup: boolean;
	history: any;
};

const TournamentsGroupInfo: FunctionComponent<Props> = ({
	t,
	tournamentsGroup,
	setTournamentsGroup,
	defaultTournamentsGroup,
	setDefaultTournamentsGroup,
	disabledSave,
	setDisabledSave,
	isNewGroup,
	history,
}) => {
	const nameCharsMaxLength = 256;
	const descriptionCharsMaxLength = 256;

	const handleTournamentGroupChange = (el: HTMLInputElement) => {
		tournamentGroupInfoChange(
			el,
			defaultTournamentsGroup,
			tournamentsGroup,
			setTournamentsGroup,
			disabledSave,
			setDisabledSave,
			nameCharsMaxLength,
			descriptionCharsMaxLength,
			isNewGroup,
			t('english_chars_only'),
		);
	};

	const handleSave = () => {
		isNewGroup
			? createTournamentsGroup(tournamentsGroup, history, t('tournaments_group_create_success'), t('error_creating_tournaments_group'))
			: updateTournamentsGroup(
					tournamentsGroup,
					setDisabledSave,
					setDefaultTournamentsGroup,
					setTournamentsGroup,
					t('tournaments_group_update_success'),
					t('error_updating_tournaments_group'),
			  );
	};

	return (
		<div className='animated fadeIn'>
			<Row>
				<Col col='12' lg='12' md='12' sm='12' xs='12'>
					<div className='card'>
						<div className='card-header d-flex align-items-center '>
							<div className='mr-auto mb-0'>{t('tournament_group_info')}</div>
							<Button
								size='sm'
								color='primary'
								disabled={shouldSaveTournamentsGroupBeDisabled(disabledSave)}
								className='text-uppercase font-weight-bold'
								onClick={handleSave}
								id='tournament-group-manual-data-entry-create-save-top'
							>
								<i className='fa fa-floppy-o' /> {t('save_option')}
							</Button>
						</div>
						<div className='card-body'>
							<Row>
								<div className='alert alert-info w-100' role='alert'>
									{t('enter_name_and_code_in_english')}
								</div>
							</Row>
							<Row>
								<Col xs='6'>
									<Label htmlFor={'name'}>
										{t('name')}
										<span className='text-danger'> *</span>
									</Label>
									<Input
										type='text'
										id='name'
										onChange={(el) => handleTournamentGroupChange(el.target)}
										value={tournamentsGroup.name}
										className='form-control mb-2'
										placeholder={t('name')}
									/>
									{tournamentsGroup.name && !observedCharLengthRestriction(tournamentsGroup.name, nameCharsMaxLength) && (
										<ErrorHandler errorMessage='tournament_groups_name_limit' />
									)}
								</Col>
								<Col xs='6'>
									<Label htmlFor={'tournament_group_code'}>
										{t('tournament_group_code')}
										<span className='text-danger'> *</span>
									</Label>
									<Input type='text' disabled={true} id='code' value={tournamentsGroup.code} className='form-control mb-2' />
								</Col>
							</Row>
							<Row>
								<Col xs='12'>
									<Label htmlFor={'description'}>{t('description')}</Label>
									<Input
										type='text'
										id='description'
										value={tournamentsGroup.description}
										onChange={(el) => handleTournamentGroupChange(el.target)}
										className='form-control mb-2'
										placeholder={t(`description`)}
									/>
									{tournamentsGroup.description && !observedCharLengthRestriction(tournamentsGroup.description, descriptionCharsMaxLength) && (
										<ErrorHandler errorMessage='tournament_groups_description_limit' />
									)}
								</Col>
							</Row>
						</div>
					</div>
				</Col>
			</Row>
		</div>
	);
};

export default TournamentsGroupInfo;
