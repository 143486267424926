import React from 'react';
import { withTranslation } from 'react-i18next';
import { Button, Col, FormGroup, Row } from 'reactstrap';
import { constructMatchesGetUrl, mapFiltersToState } from './subcomponents/helpers/match-search.helper';
import DateTimePickerContainer from '../../Partials/Sidebar/GeneralContentAttributes/subcomponents/DateTimePicker/DateTimePickerContainer';
import MatchesContainer from './subcomponents/match/matches.component';
import {
	updateMatchTeams,
	updateMatchDateRange,
	updateMatchFilters,
	updateMatchEventsList,
	updateMatchCreateLineup,
	returnObjectForMatchesBlacklistReceived,
} from '../../../store/action-creators/match-lineups-action-creator';
import CustomFootballSearch from '../../Partials/Shared/search-entity/football-search.component';
import { Link } from 'react-router-dom';
import { analyticsService, featuresService } from '../../../App';
import MatchDetailsModel from './models/match/match-details.model';
import TournamentSelectContainer from '../../Partials/Blocky/partials/tournament-select/tournament-select-container.component';
import SeasonSelectContainer from '../../Partials/Blocky/partials/season-select/season-select-container.component';
import StageSelectContainer from '../../Partials/Blocky/partials/stage-select/stage-select-container.component';
import RoundSelectContainer from '../../Partials/Blocky/partials/round-select/round-select-container.component';
import RoundModel from '../../Partials/Blocky/blocks/widgets/fixtures/models/round/round.model';
import StageModel from '../../Partials/Blocky/blocks/widgets/fixtures/models/stage/stage.model';
import SeasonModel from '../../Partials/Blocky/blocks/widgets/player-profile/models/season/season.model';
import TournamentModel from '../../Partials/Blocky/blocks/widgets/player-profile/models/tournament-model/tournament.model';
import HttpService from '../../../services/rest/HttpService';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Project from '../../../models/project/Project';
import { MatchesFilters } from '../../../store/reducers/match-lineups-edit.reducer';
import MatchModel from './models/match/match.model';
import { createEmptyMatch } from './subcomponents/helpers/match-details.helper';
import { remapMatchesFromResponse } from './subcomponents/helpers/response-remappers.helper';
import { FeatureTypes } from '../../../services/feature-service/features.enum';
import PaginationMeta from '../../../models/pagination/PaginationMeta';
import { returnObjectForArticlePaginationUpdate } from '../../../store/action-creators/ArticleActionCreator';
import { returnObjectForVideoPaginationUpdate } from '../../../store/action-creators/VideoActionCreator';
import { returnObjectForGalleryPaginationUpdate } from '../../../store/action-creators/GalleryActionCreator';
import { returnObjectForImagesPaginationUpdate } from '../../../store/action-creators/ImageActionCreator';
import { remmapContentFilters } from '../Articles/Helpers/ArticleHelper';
import { returnObjectForContentFiltersUpdate } from '../../../store/action-creators/content-resources';
import { constructRequestFromEntry, remapMatchBlacklisted } from '../manual-data-entries/subcomponents/helpers/manual-data.helper';
import { Title } from '../../Partials/BaseComponents/ListingComponent/subcomponents/ContentTitle/ContentTitle';

type Properties = {
	filters: MatchesFilters;
	teams: any;
	dateRange: string[];
	t: any;
	project: Project;
	updateMatchDateRange: (dates: string[]) => void;
	updateMatchTeams: (teams: any) => void;
	updateMatchLineup: (match: MatchDetailsModel) => void;
	updateMatchFilters: (filters: MatchesFilters) => void;
	updateMatchEventsList: (matches: MatchModel[]) => void;
	matchLineupsCreate: MatchDetailsModel;
	updateVideosPagination: (pagination: PaginationMeta) => any;
	updateGalleriesPagination: (pagination: PaginationMeta) => any;
	updateArticlesPagination: (pagination: PaginationMeta) => any;
	updateImagesPagination: (pagination: PaginationMeta) => any;
	updateContentFilters: (filters: any) => any;
	updateBlacklistedMatches: (matches: any) => any;
};

type State = {
	isLoading: boolean;
};

class MatchLineupsContainer extends React.Component<Properties, State> {
	constructor(props: Properties) {
		super(props);

		this.state = {
			isLoading: false,
		};
	}

	componentDidMount() {
		document.title = this.props.t('matches');
		this.getMatches();
		this.props.updateArticlesPagination(PaginationMeta.builder().withCurrentPage(1).build());
		this.props.updateVideosPagination(PaginationMeta.builder().withCurrentPage(1).build());
		this.props.updateGalleriesPagination(PaginationMeta.builder().withCurrentPage(1).build());
		this.props.updateImagesPagination(PaginationMeta.builder().withCurrentPage(1).build());
		const filters = remmapContentFilters({}, {}, {}, {}, {});
		this.props.updateContentFilters(filters);
	}

	componentWillUnmount() {
		this.props.updateMatchDateRange([]);
	}

	getMatches = () => {
		this.setLoading(true);
		const { filters, teams, dateRange } = this.props;
		const { tournament, season, stage, round } = filters;
		const teamIds = teams && Array.isArray(teams) ? teams.map((team: any) => team.id) : [];
		HttpService.instanceFootball()
			.get(
				constructMatchesGetUrl({
					teamIds: teamIds.join(','),
					tournamentIds: tournament ? tournament.id : '',
					seasonIds: season ? season.id : '',
					stageIds: stage ? stage.id : '',
					roundIds: round ? round.round : '',
					status: '',
					order: 'asc',
					dateFrom: dateRange[0],
					dateTo: dateRange[1],
				}),
			)
			.then((response: any) => {
				this.props.updateMatchEventsList(remapMatchesFromResponse(response.data));
				this.setLoading(false);
				this.getBlacklist(remapMatchesFromResponse(response.data));
			})
			.catch(() => this.setLoading(false));
	};

	getBlacklist = (matches: any) => {
		const matchesForBlacklist = constructRequestFromEntry(matches, 'MATCH', 'ENTITY');

		HttpService.getBlacklist('/v2/blacklist/search', [...matchesForBlacklist])
			.then((response: any) => {
				this.props.updateBlacklistedMatches(remapMatchBlacklisted(matches, response.data));
			})
			.catch((e: any) => e);
	};

	setLoading = (loading: boolean) => this.setState({ ...this.state, isLoading: loading });

	setTeams = (teams: any) => this.props.updateMatchTeams(teams);

	setDate = (dateRange: string[]) => this.props.updateMatchDateRange(dateRange);

	newMatch = () => {
		const match = this.props.matchLineupsCreate;
		this.props.updateMatchLineup(createEmptyMatch(this.props.filters, match));
		analyticsService.sendEvent('Click', 'Match Page', 'Create Button');
	};

	updateMatchFilters = (data: any, type: string) => {
		this.props.updateMatchFilters(mapFiltersToState(this.props.filters, data, type));
	};

	render() {
		const { t, filters, project, teams } = this.props;
		const { tournament, season, stage, round } = filters;
		const isManualDataLimited = featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.LIMIT_FOOTBALL_MANUAL_DATA);

		return (
			<div className='card animated fadeIn'>
				<Title title={t('matches')} />
				<div className='card-body'>
					<Row>
						<Col>
							<Link onClick={this.newMatch} className='btn btn-primary' to='/smp/matches/create'>
								<i className='fa fa-plus-circle mr-1' />
								{t('new_match')}
							</Link>
						</Col>
						{!isManualDataLimited ? (
							<>
								<Col>
									<CustomFootballSearch
										isClearable
										shouldShowLabel={false}
										t={t}
										type='team'
										isMulti
										value={teams}
										onChange={this.setTeams}
										label='team'
										lang={project.language}
									/>
								</Col>
								<Col>
									<DateTimePickerContainer
										t={t}
										mode='range'
										dateFormat='Y.m.d'
										enableTime={false}
										resetButton
										id='matches-lineups-date-search'
										onDateChange={this.setDate}
									/>
								</Col>
							</>
						) : null}
					</Row>
					<Row>
						<Col>
							<FormGroup>
								<TournamentSelectContainer
									t={t}
									label='tournament'
									isBlocky
									isValid
									onTournamentSelect={(tournament: TournamentModel) => this.updateMatchFilters(tournament, 'tournament')}
									selectedTournament={tournament}
								/>
							</FormGroup>
						</Col>
						<Col>
							<FormGroup>
								<SeasonSelectContainer
									label='season'
									t={t}
									isValid
									tournamentId={tournament ? tournament.id : ''}
									selectedSeason={season}
									onSeasonSelect={(season: SeasonModel) => this.updateMatchFilters(season, 'season')}
								/>
							</FormGroup>
						</Col>
						<Col>
							<FormGroup>
								<StageSelectContainer
									label='stage'
									selectedStage={stage}
									seasonId={season ? season.id : ''}
									onStageSelect={(stage: StageModel) => this.updateMatchFilters(stage, 'stage')}
									t={t}
								/>
							</FormGroup>
						</Col>
						<Col>
							<FormGroup>
								<RoundSelectContainer
									label='round'
									selectedRound={round}
									stageId={stage ? stage.id : ''}
									onRoundSelect={(round: RoundModel) => this.updateMatchFilters(round, 'round')}
									t={t}
								/>
							</FormGroup>
						</Col>
						<Col md='2' className='d-flex justify-content-end align-items-end'>
							<FormGroup>
								<Button className='my-1' onClick={this.getMatches} color='primary'>
									<i className='fa fa-search mr-1' />
									{t('search')}
								</Button>
							</FormGroup>
						</Col>
					</Row>
					<MatchesContainer loading={this.state.isLoading} t={t} />
				</div>
			</div>
		);
	}
}

function mapStateToProps(state: any) {
	return {
		filters: state.matchLineupsEdit.filters,
		teams: state.matchLineupsEdit.teams,
		dateRange: state.matchLineupsEdit.dateRange,
		project: state.project.currentProject,
		matchLineupsCreate: state.matchLineupsCreate.match,
	};
}

function mapDispatchToProps(dispatch: any) {
	return {
		updateMatchDateRange: (dates: string[]) => dispatch(updateMatchDateRange(dates)),
		updateMatchTeams: (teams: any) => dispatch(updateMatchTeams(teams)),
		updateMatchLineup: (match: MatchDetailsModel) => dispatch(updateMatchCreateLineup(match)),
		updateMatchFilters: (filters: MatchesFilters) => dispatch(updateMatchFilters(filters)),
		updateMatchEventsList: (matches: MatchModel[]) => dispatch(updateMatchEventsList(matches)),
		updateGalleriesPagination: (pagination: PaginationMeta) => dispatch(returnObjectForGalleryPaginationUpdate(pagination)),
		updateVideosPagination: (pagination: PaginationMeta) => dispatch(returnObjectForVideoPaginationUpdate(pagination)),
		updateArticlesPagination: (pagination: PaginationMeta) => dispatch(returnObjectForArticlePaginationUpdate(pagination)),
		updateImagesPagination: (pagination: PaginationMeta) => dispatch(returnObjectForImagesPaginationUpdate(pagination)),
		updateContentFilters: (filters: any) => dispatch(returnObjectForContentFiltersUpdate(filters)),
		updateBlacklistedMatches: (matches: any) => dispatch(returnObjectForMatchesBlacklistReceived(matches)),
	};
}

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(MatchLineupsContainer);
