import { SeoJson } from './SeoJson';
import SeoBuilder from './SeoBuilder';

export default class Seo {
	readonly title: string;
	readonly description: string;
	readonly slug: string;
	readonly keywords: string[];
	readonly index: boolean;
	readonly follow: boolean;
	readonly redirectType: string;
	readonly autoSlug: boolean;
	readonly autoTitle: boolean;
	readonly jsonld: any;

	private constructor(
		title: string,
		description: string,
		slug: string,
		keywords: string[],
		index: boolean,
		follow: boolean,
		redirectType: string,
		autoSlug: boolean,
		autoTitle: boolean,
		jsonld: any,
	) {
		this.title = title;
		this.description = description;
		this.slug = slug;
		this.keywords = keywords;
		this.index = index;
		this.follow = follow;
		this.redirectType = redirectType;
		this.autoSlug = autoSlug;
		this.autoTitle = autoTitle;
		this.jsonld = jsonld;
	}

	toJSON(): SeoJson {
		return {
			title: this.title,
			description: this.description,
			slug: this.slug,
			keywords: this.keywords,
			index: this.index,
			follow: this.follow,
			redirectType: this.redirectType,
			autoSlug: this.autoSlug,
			autoTitle: this.autoTitle,
			jsonld: this.jsonld,
		};
	}

	static fromJSON(json: SeoJson): Seo {
		return new Seo(
			json.title,
			json.description,
			json.slug,
			json.keywords,
			json.index,
			json.follow,
			json.redirectType,
			json.autoSlug,
			json.autoTitle,
			json.jsonld,
		);
	}

	static builder(seo?: Seo): SeoBuilder {
		return new SeoBuilder(seo);
	}
}
