import 'reflect-metadata';
import { jsonMember, jsonObject } from 'typedjson';

@jsonObject
export class QuizImages {
	@jsonMember(String)
	public main: string | null = null;
	@jsonMember(String)
	public mobile: string | null = null;
	@jsonMember(String)
	public cover: string | null = null;
}

@jsonObject
export default class FansUnitedClassicQuizModel {
	@jsonMember(String)
	public id: string | null = null;
	@jsonMember(String)
	public title: string | null = null;
	@jsonMember(String)
	public description: string | null = null;
	@jsonMember(String)
	public type: string | null = null;
	@jsonMember(String)
	public status: string | null = null;
	@jsonMember(QuizImages)
	public images: QuizImages = new QuizImages();
	@jsonMember(Object)
	public labels: any = {};
	@jsonMember(Number)
	public time: number | null = null;
	@jsonMember(Number)
	public participation_count: number = 0;
	@jsonMember(Number)
	public questions_count: number = 0;
	@jsonMember(Number)
	public average_score: number = 0;
	@jsonMember(Number)
	public perfect_score: number = 0;
	@jsonMember(String)
	public created_at: string = '';
	@jsonMember(String)
	public updated_at: string = '';
	@jsonMember(Boolean)
	public isSelected: boolean = false;
}
