import React, { FunctionComponent } from 'react';
import Select from 'react-select';
import { uiService } from '../../TournamentContainer';
import { SELECT_CONVERT_OPTIONS } from '../../constants/Contstants';
import Country from '../../../../../models/v2/Country/Country.model';

type Props = {
	t: any;
	onChange: (countries: Country[]) => void;
	options: Country[];
	value: Country[];
};

export const CountrySelect: FunctionComponent<Props> = ({ value, options, onChange, t }) => {
	return (
		<Select
			value={uiService.convertForReactSelect(value, SELECT_CONVERT_OPTIONS.TO)}
			options={uiService.convertForReactSelect(options, SELECT_CONVERT_OPTIONS.TO)}
			className='countries-select'
			placeholder={t('select_countries')}
			isClearable
			isMulti
			onChange={(selection: any) => {
				onChange(uiService.convertForReactSelect(selection, SELECT_CONVERT_OPTIONS.FROM));
			}}
		/>
	);
};
