import React, { FunctionComponent } from 'react';
import * as ReactSortableHOC from 'react-sortable-hoc';
import { Button, Col, Input, Row } from 'reactstrap';
import { SortableHandle } from 'react-sortable-hoc';
import TournamentOrderModel from '../../../../../../models/v2/Tournament/TournamentOrder/TournamentOrderModel';
import Select from 'react-select';
import { customOption } from '../../../../../Partials/Blocky/partials/shared/custom-select-option';
import { tournamentLabelWithCountry } from '../../../helpers/TournamentsGroupsUiService';
import { loadSearchTournaments } from '../../../helpers/TournamentsGroupsService';
import TournamentModel from '../../../../../../models/v2/Tournament/Entity/TournamentModel';
import { connect } from 'react-redux';
import debounce from 'lodash.debounce';

const DragHandle = SortableHandle(() => (
	<span className='cursor-draggable col-md-1 text-center'>
		<i className='fa fa-bars'></i>
	</span>
));

type SortableItemProps = {
	pickNewTournament: any;
	handleDeleteRow: Function;
	tournamentOrder: TournamentOrderModel;
	t: any;
	tournamentOptions: any[];
	setFetchedSelectTournaments: Function;
	lang: string;
};

const ContentSortableItem: FunctionComponent<SortableItemProps> = ({
	tournamentOrder,
	t,
	handleDeleteRow,
	pickNewTournament,
	tournamentOptions,
	setFetchedSelectTournaments,
	lang,
}) => {
	const onSelectInputChange = (inputValue: string) => {
		if (inputValue && inputValue.length > 2) {
			loadSearchTournaments(inputValue, lang).then((data: TournamentModel[]) => {
				setFetchedSelectTournaments(data);
			});
		}
	};

	return (
		<li className='list-group-item'>
			<Row>
				{tournamentOrder.tournament.id ? <DragHandle /> : <div className='col-md-1'></div>}
				<Col md={9}>
					{tournamentOrder.tournament.id ? (
						<Input disabled value={tournamentLabelWithCountry(tournamentOrder.tournament)} />
					) : (
						<Select
							noOptionsMessage={(inputValue) => inputValue && t('no_options')}
							onInputChange={debounce(onSelectInputChange, 300)}
							onChange={pickNewTournament}
							options={tournamentOptions}
							formatOptionLabel={customOption}
							placeholder={t('search')}
							maxMenuHeight={160}
						/>
					)}
				</Col>
				<Col md={2} className='btn-remove-wrapper'>
					<Button className='btn btn-danger' onClick={() => handleDeleteRow(tournamentOrder.tournament.id)}>
						{t('remove')}
					</Button>
				</Col>
			</Row>
		</li>
	);
};

function mapStateToProps(state: any) {
	return {
		lang: state.project.currentProject.language,
	};
}

export default connect(mapStateToProps)(ReactSortableHOC.SortableElement(ContentSortableItem));
