import { TypedJSON } from 'typedjson';
import LiveBlogMatchModel from './live-blog-match-model';
import { convertIdToString } from '../../../../helpers/utility.helper';

export const modelMatchToResponseModel = (response: any): LiveBlogMatchModel => {
	const serializer = new TypedJSON(LiveBlogMatchModel);
	const result = serializer.parse(response);

	if (result) {
		result.id = convertIdToString(result.id);
		if (result.home_team) {
			result.home_team.id = convertIdToString(result.home_team.id);
		}
		if (result.away_team) {
			result.away_team.id = convertIdToString(result.away_team.id);
		}

		return result;
	}

	return new LiveBlogMatchModel();
};
