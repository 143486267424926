import React, { FunctionComponent, useState } from 'react';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { Col, Label, Row } from 'reactstrap';
import { AppState } from '../../../../../../store/store';
import { isArrayEmpty } from '../../../../../../global-helpers/global.helpers';
import { DATA_QA_ATTRIBUTES } from '../../../../../Partials/Sidebar/general-content/constants/data-qa.attributes';
import ErrorAsync from '../../../../../Partials/BaseComponents/ErrorComponent/ErrorAsync';
import { categoriesToOptions, SelectCategoryOptionType } from '../../../helpers/categories-select.helper';
import LiveBlogModel from '../../../models/live-blog.model';

type Properties = {
	t: any;
	liveBlog: LiveBlogModel;
	setLiveBlogCategory: any;
};

export const LiveBlogCategorySelect: FunctionComponent<Properties> = ({ t, setLiveBlogCategory, liveBlog }) => {
	const categories = useSelector((state: AppState) => state.category.allCategories);
	const adminCategories = useSelector((state: AppState) => state.category.adminCategories);

	const onCategorySelect = (selectedCategory: SelectCategoryOptionType) => {
		const selected = (selectedCategory && selectedCategory.data) || null;
		setLiveBlogCategory(selected);
	};

	return isArrayEmpty(categories) ? null : (
		<Row className='mb-3 category'>
			<Col>
				<Label htmlFor={DATA_QA_ATTRIBUTES.LIVE_BLOG_CATEGORY}>
					<strong>{t('main_category')}</strong>
				</Label>
				<span className='text-danger'>*</span>
				<Select
					id={DATA_QA_ATTRIBUTES.LIVE_BLOG_CATEGORY}
					value={liveBlog.category && categoriesToOptions([liveBlog.category], adminCategories)}
					options={categoriesToOptions(categories, adminCategories)}
					onChange={(selections: any) => onCategorySelect(selections)}
					placeholder={t('select')}
					className='mb-2 border-dark'
					noOptionsMessage={(inputValue) => inputValue && t('no_options')}
				/>
				<ErrorAsync t={t} errorType='category_id' />
			</Col>
		</Row>
	);
};
