import * as React from 'react';
import { featuresService } from '../../../../../../../../App';
import { FeatureTypes } from '../../../../../../../../services/feature-service/features.enum';
import { extractContentValueByTheGivenKey } from './helpers/row-content-statistics.component.helper';

type Properties = {
	content: any;
};

const RowContentStatisticsComponent: React.FunctionComponent<Properties> = (props) => {
	const contentStatisticsConfig = featuresService.getFeatureConfig(FeatureTypes.CONTENT_STATISTICS);
	const { content } = props;

	if (
		!featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.CONTENT_STATISTICS) ||
		(!featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.CONTENT_STATISTICS) && !contentStatisticsConfig.mappings)
	) {
		return null;
	}

	return contentStatisticsConfig.mappings
		.filter((statistic: any) => statistic.is_main_statistic)
		.map((statistic: any) => {
			return (
				<td key={statistic.json_key} className='display-none d-sm-table-cell align-middle text-center'>
					{extractContentValueByTheGivenKey(content, statistic)}
				</td>
			);
		});
};

export default RowContentStatisticsComponent;
