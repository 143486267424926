import React, { useEffect, useState } from 'react';
import InstatHttpService from '../../../../../../../../services/rest/instat/InstatHttpService';
import Select from 'react-select';

type Properties = {
	value: any;
	teamId: string;
	onSelect: (value: any | null) => void;
};

const selectedPlayerToOption = (value: any) => {
	return value
		? {
				value: value.id,
				label: value.name,
		  }
		: null;
};

const optionToModel = (option: any) => {
	return {
		id: option.id,
		name: option.label,
	};
};

const PlayerSelect: React.FunctionComponent<Properties> = (props) => {
	const [playerOptions, setPlayerOptions] = useState([]);
	const [initialLoad, setInitialLoad] = useState(true);

	const http = new InstatHttpService();

	useEffect(() => {
		if (initialLoad) {
			http
				.getTeamSquad(props.teamId)
				.then((seasons) => {
					const options = seasons.map((player: any) => {
						return {
							id: player.id,
							label: player.firstname + ' ' + player.lastname,
							data: { ...player },
						};
					});
					setInitialLoad(false);
					setPlayerOptions(options);
				})
				.catch((e) => {
					setInitialLoad(false);
					setPlayerOptions([]);
				});
		}
	});

	useEffect(() => {
		http
			.getTeamSquad(props.teamId)
			.then((seasons) => {
				const options = seasons.map((player: any) => {
					return {
						id: player.id,
						label: player.firstname + ' ' + player.lastname,
						data: { ...player },
					};
				});
				setInitialLoad(false);
				setPlayerOptions(options);
			})
			.catch((e) => {
				setInitialLoad(false);
				setPlayerOptions([]);
			});
	}, [props.teamId]);

	return (
		<Select
			value={selectedPlayerToOption(props.value)}
			options={playerOptions}
			isClearable
			onChange={(selection: any) => {
				props.onSelect(selection ? optionToModel(selection) : null);
			}}
		/>
	);
};

export default PlayerSelect;
