import { ListItemMetaJson } from './ListItemMetaJson';
import ListItemMeta from './ListItemMeta';

export default class ListItemMetaBuilder {
	private json: ListItemMetaJson;

	constructor(listItem?: ListItemMeta | ListItemMetaJson) {
		if (listItem && listItem instanceof ListItemMeta) {
			this.json = listItem.toJSON();
		} else if (listItem) {
			this.json = listItem;
		} else {
			this.json = {} as ListItemMetaJson;
		}
	}

	withTitle(title: string): ListItemMetaBuilder {
		this.json.title = title;

		return this;
	}

	withSubtitle(subtitle: string): ListItemMetaBuilder {
		this.json.subtitle = subtitle;

		return this;
	}

	withImageId(imageId: string): ListItemMetaBuilder {
		this.json.imageId = imageId;

		return this;
	}

	withFromDate(from: string | undefined): ListItemMetaBuilder {
		this.json.from = from;

		return this;
	}

	withToDate(to: string | undefined): ListItemMetaBuilder {
		this.json.to = to;

		return this;
	}

	build(): ListItemMeta {
		return ListItemMeta.fromJSON(this.json);
	}
}
