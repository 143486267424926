import { TypedJSON } from 'typedjson';
import TagModel from './tag.model';

export const responseToModelTags = (response: any): TagModel => {
	const serializer = new TypedJSON(TagModel);
	const result = serializer.parse(response);

	if (result) {
		return result;
	}

	return new TagModel();
};
