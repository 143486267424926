export const oneSignalEndpoint = 'https://onesignal.com/api/v1/notifications';
export enum ONESIGNAL_DELIVERY_TYPES {
	IMMEDIATELY = 'immediately',
	INTELLIGENT = 'intelligent',
	DELAYED = 'delayed',
}

export const oneSignalDeliveryTypesArray = [
	ONESIGNAL_DELIVERY_TYPES.IMMEDIATELY,
	ONESIGNAL_DELIVERY_TYPES.INTELLIGENT,
	ONESIGNAL_DELIVERY_TYPES.DELAYED,
];

export const fieldsCharsLength = {
	url: {
		minimum: 3,
		maximum: 255,
	},
	title: {
		minimum: 3,
		maximum: 40,
	},
	description: {
		minimum: 3,
		maximum: 40,
	},
};
