import * as React from 'react';
import { Link } from 'react-router-dom';
import '../styles/rows.scss';

type Properties = {
	canEditContent: boolean;
	editContentPath: string;
	analytics: any;
	contentTitle: string;
	strapline?: string;
	contentId?: string;
	showStatus?: boolean;
	active?: boolean;
	t?: any;
	titleStyle?: string;
	showLanguageFlag?: boolean;
};

export const RowTitleLink: React.FunctionComponent<Properties> = ({
	canEditContent,
	editContentPath,
	contentTitle,
	analytics,
	strapline,
	contentId,
	showStatus,
	active,
	t,
	titleStyle,
}) => {
	return (
		<Link
			className={`${showStatus && !active ? 'inactive' : 'text-dark'}`}
			id={contentId}
			onClick={() => {
				analytics.sendEvent('Click', 'List Page', 'Edit Title');
			}}
			to={canEditContent ? editContentPath : '#'}
		>
			{strapline && strapline !== '' && (
				<span className={'pl-1 pr-1 d-inline-block'} style={{ backgroundColor: '#c5c5c5' }}>{`${strapline}`}</span>
			)}
			<span className={`pl-1 text-break ${titleStyle ? titleStyle : ''}`}>{contentTitle}</span>
			{showStatus && !active && <span className={'pl-1'}>({t('inactive')})</span>}
		</Link>
	);
};
