import VideoUrls from '../../../../../../../models/urls/video-urls/video-urls';
import ContentWidgetBuilder from './content-widget.builder';
import { ContentWidgetJson } from './content-widget.json';
import MainMedia from '../../../../../../../models/mainmedia/MainMedia';

export default class ContentWidget {
	readonly id: string;
	readonly title: string;
	readonly image: string;
	readonly subtitle: string;
	readonly publishedAt: string;
	readonly startAt: string;
	readonly width: string;
	readonly alignment: string;
	readonly status: string;
	readonly videoUrls: VideoUrls[];
	readonly mainMedia: MainMedia[];

	private constructor(
		id: string,
		title: string,
		image: string,
		subtitle: string,
		publishedAt: string,
		startAt: string,
		width: string,
		alignment: string,
		status: string,
		videoUrls: VideoUrls[],
		mainMedia: MainMedia[],
	) {
		this.id = id;
		this.title = title;
		this.image = image;
		this.subtitle = subtitle;
		this.publishedAt = publishedAt;
		this.startAt = startAt;
		this.width = width;
		this.alignment = alignment;
		this.status = status;
		this.videoUrls = videoUrls;
		this.mainMedia = mainMedia;
	}

	toJson(): ContentWidgetJson {
		return {
			id: this.id,
			title: this.title,
			image: this.image,
			subtitle: this.subtitle,
			publishedAt: this.publishedAt,
			startAt: this.startAt,
			width: this.width,
			alignment: this.alignment,
			status: this.status,
			videoUrls: this.videoUrls,
			mainMedia: this.mainMedia,
		};
	}

	static fromJson(json: ContentWidgetJson): ContentWidget {
		return new ContentWidget(
			json.id,
			json.title,
			json.image,
			json.subtitle,
			json.publishedAt,
			json.startAt,
			json.width,
			json.alignment,
			json.status,
			json.videoUrls,
			json.mainMedia,
		);
	}

	static builder(content?: ContentWidget): ContentWidgetBuilder {
		return new ContentWidgetBuilder(content);
	}
}
