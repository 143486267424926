import Article from '../../models/article/Article';
import { ARTICLE_TEMP_UPDATE } from '../action-creators/ArticleTempActionCreator';

export function articleTempMiddleware() {
	return function (next: any) {
		return function (action: any) {
			if (action) {
				if (action.type === ARTICLE_TEMP_UPDATE) {
					const article = Article.builder(action.payload).build();

					if (article.id === undefined || article.id === null || article.id.length < 1) {
						action.payload = Article.builder(article).withId('temp-id').build();
					}
				}
			}

			next(action);
		};
	};
}
