import 'reflect-metadata';
import { jsonArrayMember, jsonMember, jsonObject } from 'typedjson';
import MetaModel from '../../v2/Meta/MetaModel';
import WikiRowModel from '../Row/WikiRowModel';

@jsonObject()
export default class WikiListModel {
	@jsonArrayMember(WikiRowModel)
	public data: WikiRowModel[] = [];
	@jsonMember(MetaModel)
	public meta: MetaModel = new MetaModel();
}
