import React, { FunctionComponent } from 'react';
import Masonry from 'react-masonry-css';
import '../../listing/styles/gallery-block-masonry.scss';
import Image from '../../../../../../../../models/image/Image';
import { imagePlaceholderBase64 } from '../../../../../../../../global-helpers/global.constants';
import { useTranslation } from 'react-i18next';

type ImageCellsProps = {
	galleryItems: Image[];
};

const AddedGalleryItems: FunctionComponent<ImageCellsProps> = ({ galleryItems }) => {
	const [t] = useTranslation();

	return (
		<div className='container'>
			<hr />
			<label className='ml-2'>
				<strong>{t('gallery_items')}:</strong>
			</label>
			<Masonry
				breakpointCols={{
					default: 3,
					1380: 3,
					1300: 2,
					990: 4,
					560: 3,
					520: 2,
				}}
				className='gallery-block-masonry-grid'
				columnClassName='gallery-block-masonry-grid_column'
			>
				{galleryItems.map((image: Image) => {
					if (image && image.urls) {
						const imageSrc = image.urls && image.urls.uploaded && image.urls.uploaded.embed ? image.urls.uploaded.embed : imagePlaceholderBase64;
						return (
							<img
								src={imageSrc}
								id={`image-cell-${image.id}`}
								key={`image-preview-key-${image.id}`}
								className='img-fluid w-100 mb-1'
								alt={image.description || ''}
								onError={(i: any) => (i.target ? (i.target.src = imagePlaceholderBase64) : '')}
							/>
						);
					}
				})}
			</Masonry>
		</div>
	);
};

export default AddedGalleryItems;
