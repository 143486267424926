import Category from '../../../../../../models/category/Category';
import { featuresService } from '../../../../../../App';
import { FeatureTypes } from '../../../../../../services/feature-service/features.enum';

export const emptyOption = { value: 'empty', label: 'Empty', category: null, isDisabled: false };

export function categoryToOption(category: Category) {
	let option = {};
	const inactiveText =
		!category.active && featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.DISPLAY_INACTIVE_CATEGORIES) ? '(Inactive)' : '';

	if (category) {
		option = { label: `${category.title} ${inactiveText}`, value: category.id, category };
	} else {
		option = emptyOption;
	}

	return option;
}

export function categoriesToOptions(categories: Category[], adminCategories: Category[], withEmptyCategory?: boolean) {
	let categorySelectOptions: any[] = [];
	let adminCategoriesIds = categoryAdminIdsFromCategoryAdmins(adminCategories);
	const displayInactiveCategories = featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.DISPLAY_INACTIVE_CATEGORIES);
	if (categories) {
		categorySelectOptions = categories
			.filter((category: Category) => {
				// Display inactive categories if the feature for displaying inactive categories is enabled
				if (displayInactiveCategories) {
					return true;
				} else {
					return category.active;
				}
			})
			.map((category: Category) => returnMappedCategories(category, adminCategoriesIds));
	}

	if (withEmptyCategory !== undefined && withEmptyCategory) {
		return appendEmptyCategoryOption(categorySelectOptions);
	}

	return categorySelectOptions;
}

export function appendEmptyCategoryOption(categoryOptionList: any[]) {
	return categoryOptionList ? [emptyOption].concat(categoryOptionList) : [emptyOption];
}

export function categoryAdminIdsFromCategoryAdmins(adminCategories: Category[]) {
	if (adminCategories) {
		return adminCategories.map((category: Category) => category.id);
	}

	return [];
}

export function optionToCategory(selection: any) {
	let category = {};
	if (selection && selection.category) {
		category = selection.category;
	}

	return category;
}

export const isCategoryDisabled = (adminCategoriesIds: string[], categoryId: string) => {
	return adminCategoriesIds && categoryId ? !adminCategoriesIds.includes(categoryId) : false;
};

export const returnMappedCategories = (category: Category, adminCategoriesIds: string[]) => {
	const inactiveText =
		!category.active && featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.DISPLAY_INACTIVE_CATEGORIES) ? '(Inactive)' : '';
	return {
		value: category.id,
		label: `${category.subIndent ? category.subIndent : ''} ${category.title} ${inactiveText}`,
		category,
		isDisabled: isCategoryDisabled(adminCategoriesIds, category.id),
	};
};
