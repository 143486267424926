import React from 'react';
import { Col, Row } from 'reactstrap';
import { OddsWidgetView } from '../../odds/components/odds-widget-view.component';
import OddsWidgetModel from '../../odds/models/odds-widget.model';
import FixturesModel from '../models/fixtures-widget.model';
import moment from 'moment';

type Properties = {
	oddsPreview: OddsWidgetModel;
	t: any;
	fixturesPreview: FixturesModel;
	showAllBookmakers: boolean;
};

export const FixturesWidgetView: React.FunctionComponent<Properties> = ({ oddsPreview, fixturesPreview, t }) => {
	if (fixturesPreview && fixturesPreview.matches && fixturesPreview.matches.length > 0) {
		return (
			<div className='px-4 mt-3'>
				{fixturesPreview.matches.map((match) => (
					<Row className='mb-1' key={match.id}>
						<Col className='text-left'>
							<Row className='mb-1'>
								<Col md={3} sm={2} xs={12}>
									<div className='mb-1'>{match.startTime ? moment(match.startTime).format('MMM Do YYYY, HH:mm') : ''}</div>
								</Col>
								<Col className='text-right' md={3} sm={5} xs={12}>
									<div className='d-inline-block max-width-150 pr-2 pt-0 font-weight-bold' style={{ lineHeight: '1.2' }}>
										{match.homeTeam ? match.homeTeam.name : ''}
									</div>
									<div className='d-inline-block h-100' style={{ verticalAlign: 'top' }}>
										<img
											height='40'
											alt={match.homeTeam ? match.homeTeam.name : ''}
											src={match.homeTeam ? match.homeTeam.logo : ''}
											className='h-40 mx-1'
										/>
									</div>
								</Col>
								<Col className='text-left' md={6} sm={5} xs={12}>
									<div className='d-inline-block h-100' style={{ verticalAlign: 'top' }}>
										<img
											height='40'
											alt={match.awayTeam ? match.awayTeam.name : ''}
											src={match.awayTeam ? match.awayTeam.logo : ''}
											className='h-40 mx-1'
										/>
									</div>
									<div className='d-inline-block max-width-150 pl-2 pt-0 font-weight-bold' style={{ lineHeight: '1.2' }}>
										{match.awayTeam ? match.awayTeam.name : ''}
									</div>
								</Col>
							</Row>
						</Col>
					</Row>
				))}
				<Row>
					<Col>
						<hr></hr>
					</Col>
				</Row>
				{fixturesPreview.title && fixturesPreview.title.length > 0 && (
					<Row>
						<Col>
							<h5 className={'text-left mt-2 mb-3 '}>
								{t('title')}: <strong>{fixturesPreview.title}</strong>
							</h5>
						</Col>
					</Row>
				)}
				{fixturesPreview.displayOdds && (
					<OddsWidgetView isStandAlone={false} blockPreview={oddsPreview} t={t} displayOddsLabel showAllBookmakers />
				)}
				<Row className={`${!fixturesPreview.displayOdds ? 'text-left' : 'pb-4 pr-4'}`}>
					<Col className='text-left'>
						{t('display_odds')}:
						<strong>
							<span className={fixturesPreview.displayOdds ? 'text-success' : 'text-danger'}>
								{fixturesPreview.displayOdds ? ` ${t('yes')}` : ` ${t('no')}`}
							</span>
						</strong>
					</Col>
				</Row>
			</div>
		);
	}

	return null;
};
