import 'reflect-metadata';
import { AnyT, jsonMember, jsonObject } from 'typedjson';

@jsonObject()
export default class LiveBlogTournamentModel {
	@jsonMember(AnyT)
	public id: string | number = '';
	@jsonMember(String)
	public name: string = '';
	@jsonMember(String)
	public region: string = '';
	@jsonMember(String)
	public gender: string = '';
	@jsonMember(Boolean)
	public regional_league: boolean = false;
	@jsonMember(String)
	public type: string = '';
	@jsonMember(String)
	public url_logo: string = '';
	@jsonMember(String)
	public entity_type?: string = '';
	@jsonMember(String)
	public slug: string | null = null;
}
