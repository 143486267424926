import { USER_LOGOUT } from '../action-creators/ProfileActionCreators';
import Gallery from '../../models/gallery/Gallery';
import {
	GALLERY_TEMP_CLEAR,
	GALLERY_TEMP_EDIT_UPDATE,
	GALLERY_TEMP_PROPERTY_UPDATE,
	GALLERY_TEMP_UPDATE,
} from '../action-creators/GalleryTempActionCreator';
import {
	CONTENT_STATISTICS_GALLERY_ENTITY_RECEIVED,
	GALLERY_CREATE_SUCCESS,
	GALLERY_RECEIVED,
} from '../action-creators/GalleryActionCreator';
import { PROJECT_RESOURCES_REQUEST } from '../../constants/constants';
import { createStartingEntity } from '../../services/content-models-service/ContentModelService';
import { ContentMode, ContentTypes } from '../../constants/content-types';

interface InitialState {
	gallery: Gallery;
	galleryEdit: Gallery;
}

const initialState: InitialState = {
	gallery: createStartingEntity(ContentTypes.GALLERY, true) as Gallery,
	galleryEdit: Gallery.builder().build(),
};

function galleryTempReducer(state: any = initialState, action: any) {
	if (action.type === PROJECT_RESOURCES_REQUEST) {
		return Object.assign({}, state, initialState);
	}

	if (action.type === GALLERY_TEMP_UPDATE) {
		return Object.assign({}, state, {
			gallery: action.payload,
		});
	}

	if (action.type === GALLERY_TEMP_EDIT_UPDATE) {
		return Object.assign({}, state, {
			galleryEdit: action.payload,
		});
	}

	if (action.type === GALLERY_TEMP_PROPERTY_UPDATE) {
		const propertyName = `gallery${action.contentMode === ContentMode.EDIT ? 'Edit' : ''}`;

		return Object.assign({}, state, {
			[propertyName]: { ...state[propertyName], ...action.payload },
		});
	}

	if (action.type === GALLERY_RECEIVED) {
		return Object.assign({}, state, {
			galleryEdit: action.payload.galleryEdit,
		});
	}

	if (action.type === GALLERY_TEMP_CLEAR) {
		return Object.assign({}, state, {
			gallery: initialState.gallery,
		});
	}

	if (action.type === GALLERY_CREATE_SUCCESS) {
		return Object.assign({}, state, {
			gallery: initialState.gallery,
			galleryEdit: initialState.galleryEdit,
		});
	}

	if (action.type === CONTENT_STATISTICS_GALLERY_ENTITY_RECEIVED) {
		return Object.assign({}, state, {
			galleryEdit: action.payload.gallery,
		});
	}

	if (action.type === USER_LOGOUT) {
		state = initialState;
	}

	return state;
}

export default galleryTempReducer;
