import React from 'react';
import moment from 'moment';
import { Properties } from './properties/content-view.properties';
import { noPreviewImage } from '../../../../../../constants/resource-constants';
import PreviewContent from './subcomponents/preview-content.components';

const ContentWidgetView: React.FunctionComponent<Properties> = ({ content, startAt, isLoading, type, t, data }) => {
	return (
		<>
			{type === 'video' ? (
				<div className={`${isLoading ? 'loading-overlay' : ''}`}>
					<div className={'block-content'}>
						<div className='d-flex content-container'>
							<div className='position-relative'>
								<img alt='' src={content.image ? content.image : noPreviewImage} className='content-image img-fluid mb-2 mb-md-0 rounded' />
							</div>
							<div className='gallery-title w-auto text-left w-100'>
								<h4 className='m-0 w-80 p-0'>{content.title}</h4>
								{content.subtitle && (
									<p className='w-80'>
										<small>{content.subtitle}</small>
									</p>
								)}
								{startAt && startAt.length > 0 && (
									<p className={'mb-2'}>
										{t('start_at')} {startAt}
									</p>
								)}
								<p>
									<small className={'mb-2'}>{moment(content.publishedAt).format('DD MMMM YYYY, HH:mm')}</small>
								</p>

								<div className='mt-4'>
									<PreviewContent t={t} videoUrls={content.videoUrls} mainMedia={content.mainMedia} />
								</div>
								{data && data.preview && data.preview.previewContent && (
									<div>
										<div className='mt-3'>
											<span>{t('image_width')}:</span>
											<strong className='ml-2'>{data.preview.previewContent.position && data.preview.previewContent.position.width}</strong>
										</div>
										<div className='mt-1'>
											<span>{t('image_alignment')}:</span>
											<strong className='ml-2'>{t(data.preview.previewContent.position && data.preview.previewContent.position.alignment)}</strong>
										</div>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			) : (
				<div className={isLoading ? 'loading-overlay' : ''}>
					<div className={'block-content'}>
						<div className={'d-flex content-container'}>
							<img alt='' src={content.image ? content.image : noPreviewImage} className='content-image img-fluid mb-2 mb-md-0 rounded' />
							<div className={'gallery-title text-left'}>
								<h4 className={'mb-1'}>{content.title} </h4>
								{content.subtitle && <p className={'mb-2'}>{content.subtitle}</p>}
								{startAt && startAt.length > 0 && (
									<p className={'mb-2'}>
										{t('start_at')} {startAt}
									</p>
								)}
								<p className={'mb-2'}>
									<small>
										{t('published_at')} {moment(content.publishedAt).format('DD MMMM YYYY, HH:mm')}
									</small>
								</p>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default ContentWidgetView;
