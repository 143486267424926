import React from 'react';
import { Col, Row } from 'reactstrap';
import TennisAthleteProgrammeModel from './models/tennis-athlete-programme.model';
import TennisRoundModel from '../../../../../../models/v2/round/tennis-round/tennis-round.model';
import SportOddsBookmakerModel from '../../../../../../models/sport-odds/sport-odds-bookmaker.model';
import { imagePlaceHolder } from '../../../../../../constants/resource-constants';
import { generateRefreshTimeOptionViewText } from '../../partials/refresh-time-input/helper';
import { useTranslation } from 'react-i18next';

type Properties = {
	preview: TennisAthleteProgrammeModel;
};
export const TennisAthleteProgrammeViewComponent: React.FunctionComponent<Properties> = ({ preview }) => {
	const [t] = useTranslation();
	const rounds = preview.rounds && preview.rounds.length > 0 ? preview.rounds : '';
	if (preview && preview.athlete && preview.athlete.id) {
		return (
			<>
				<Row className='px-4' data-qa='tennis-athlete-programme-view'>
					<Col className='text-left'>
						<Row className='mb-2'>
							<Col>
								{preview.athlete && preview.athlete.name && (
									<div className='single-row'>
										<strong>{t('player')}:</strong> {preview.athlete.name}
									</div>
								)}
								{preview.competition && preview.competition.id && (
									<div className='single-row'>
										<strong>{t('competition')}:</strong> {preview.competition.name}
									</div>
								)}
								{preview.season && preview.season.value && (
									<div className='single-row'>
										<strong>{t('season')}:</strong> {preview.season.value}
									</div>
								)}
								{preview.tournament && preview.tournament.name && (
									<div className='single-row'>
										<strong>{t('tournament')}:</strong> {preview.tournament.name}
									</div>
								)}
								{rounds && rounds.length > 0 && (
									<div className='single-row'>
										<strong>{t('rounds')}:</strong>
										{rounds.map((round: TennisRoundModel, index) => {
											return (
												<span key={`${round.name}-${index}`} className='pl-1'>
													{round.name}
													{index + 1 === rounds.length ? '' : ','}
												</span>
											);
										})}
									</div>
								)}
							</Col>
						</Row>
						{preview.sortDirectionFixtures ? (
							<Row className='mb-2'>
								<Col>
									<div className='single-row'>
										<strong>{t('sort_direction_fixtures')}:</strong> {t(preview.sortDirectionFixtures)}
									</div>
								</Col>
							</Row>
						) : null}
						{preview.sortDirectionResults ? (
							<Row className='mb-2'>
								<Col>
									<div className='single-row'>
										<strong>{t('sort_direction_results')}:</strong> {t(preview.sortDirectionResults)}
									</div>
								</Col>
							</Row>
						) : null}
						<hr />
						<Row className='mb-2'>
							<Col className='text-left'>
								{t('display_odds')}:
								<strong>
									<span className={preview.displayOdds ? 'text-success' : 'text-danger'}>
										{preview.displayOdds ? ` ${t('yes')}` : ` ${t('no')}`}
									</span>
								</strong>
							</Col>
						</Row>
						{preview.displayOdds && preview.bookmakers && preview.bookmakers.length > 0 && (
							<Row>
								<Col>
									{t('show')}:
									{preview.bookmakers.map((bookmaker: SportOddsBookmakerModel) => {
										return (
											<img
												key={bookmaker.id}
												height='20'
												alt={bookmaker.name}
												className='h-20 mx-1'
												src={bookmaker.assets && bookmaker.assets[0] && bookmaker.assets[0].logo ? bookmaker.assets[0].logo : imagePlaceHolder}
												title={bookmaker.name}
											/>
										);
									})}
								</Col>
							</Row>
						)}
					</Col>
				</Row>
				{generateRefreshTimeOptionViewText(preview.refreshTime)}
			</>
		);
	}
	return null;
};
