import AssetsTypesModel from '../../../../../../models/team-manual-data/assets/assetsTypes/assets-types.model';
import { Entities, AssetsTypes } from '../constants/assets.constants';

export const remmapAssetsTypes = (assetsTypes: any) => {
	if (assetsTypes && assetsTypes.length > 0) {
		return assetsTypes.map((type: any) =>
			AssetsTypesModel.builder()
				.withEntity(type.entity)
				.withType(type.asset_type)
				.withDimensions(type.dimensions)
				.withContexts(type.contexts)
				.build(),
		);
	}

	return [];
};

export const constructDeleteParamsForAsset = (type: string, entity: string, entityId: string) => {
	if (entity && entity.length > 0) {
		return [{ type: getKeyByValue(AssetsTypes, type), entity: Entities[entity], entity_id: entityId }];
	}

	return [];
};

export const getKeyByValue = (obj: any, value: any) => Object.keys(obj).find((key) => obj[key] === value);
