import 'reflect-metadata';
import { AnyT, jsonArrayMember, jsonMember, jsonObject } from 'typedjson';

@jsonObject
export default class AmgItemDataModel {
	@jsonMember(String)
	public id: string = '';
	@jsonMember(AnyT, { deserializer: (value) => value })
	public mediaData: any;
	@jsonArrayMember(AnyT, { deserializer: (value) => value })
	public metaData: any;
}
