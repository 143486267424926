import ContentAttributes from '../../../../../../../models/content-attributes/content-attributes-model';

export const originToSelectOption = (origin: ContentAttributes) => {
	if (origin && Object.entries(origin).length > 0) {
		return { value: origin.id, label: origin.name, origin };
	}

	return {};
};

export const originsToSelectOptions = (origins: ContentAttributes[]) => {
	if (origins && origins.length > 0) {
		return origins.map((origin: ContentAttributes) => {
			return originToSelectOption(origin);
		});
	}

	return [];
};
