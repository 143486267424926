import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { FormGroup, Label } from 'reactstrap';
import {
	autoGenerateReduxPropsNamingForContentModels,
	generateReduxPropsUpdateActionByContentType,
} from '../../../../../../../services/content-models-service/ContentModelService';
import {
	extractContentModeBasedOnUrl,
	extractMainContentTypeBasedOnUrl,
	isArrayEmpty,
} from '../../../../../../../global-helpers/global.helpers';
import { SelectMenuOptionType } from '../../../../../../../models/v2/general/select.model';
import { DATA_QA_ATTRIBUTES } from '../../../constants/data-qa.attributes';
import CategoryModel from '../../../../../../../models/v2/category/category.model';
import { categoriesToOptions } from '../../../helpers/category-select.helper';
import { ReduxGeneralContentProps } from '../../../constants/redux-general-content.attributes';

type Properties = {
	t: any;
	categoriesRedux: CategoryModel[];
	adminCategoriesRedux: CategoryModel[];
	selectedAdditionalCategories: CategoryModel[];
	updateTempProperty: (propertyObject: Record<string, any>) => void;
};

const AdditionalCategoriesMultiselect: FunctionComponent<Properties> = ({
	t,
	categoriesRedux,
	adminCategoriesRedux,
	selectedAdditionalCategories,
	updateTempProperty,
}) => {
	const onCategorySelect = (selectedCategoriesOption: SelectMenuOptionType[]) => {
		const selectedAdditionalCategories: CategoryModel[] =
			selectedCategoriesOption && selectedCategoriesOption.length > 0 ? selectedCategoriesOption.map((el) => el.data) : [];
		updateTempProperty({ [ReduxGeneralContentProps.ADDITIONAL_CATEGORIES]: selectedAdditionalCategories });
	};

	return isArrayEmpty(categoriesRedux) ? null : (
		<FormGroup>
			<Label htmlFor={DATA_QA_ATTRIBUTES.ADDITIONAL_CATEGORY}>{t('additional_categories')}</Label>
			<Select
				id={DATA_QA_ATTRIBUTES.ADDITIONAL_CATEGORY}
				value={categoriesToOptions(selectedAdditionalCategories, adminCategoriesRedux)}
				options={categoriesToOptions(categoriesRedux, adminCategoriesRedux)}
				onChange={onCategorySelect}
				placeholder={t('select')}
				isMulti
				noOptionsMessage={(inputValue) => inputValue && t('no_options')}
			/>
		</FormGroup>
	);
};

function mapStateToProps(state: any) {
	const { tempPropertyNaming, nestedTempPropertyNaming } = autoGenerateReduxPropsNamingForContentModels();

	return {
		selectedAdditionalCategories: state[tempPropertyNaming][nestedTempPropertyNaming].additionalCategories || [],
		categoriesRedux: state.category.allCategories || [], // all available categories
		adminCategoriesRedux: state.category.adminCategories || [], // categories only for the current user
	};
}

function mapDispatchToProps(dispatch: Function) {
	const contentTypeFromUrl = extractMainContentTypeBasedOnUrl();
	const functionForDispatch = generateReduxPropsUpdateActionByContentType(contentTypeFromUrl);

	return {
		updateTempProperty: (propertyObject: Record<string, any>) =>
			functionForDispatch && dispatch(functionForDispatch(propertyObject, extractContentModeBasedOnUrl())),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(AdditionalCategoriesMultiselect);
