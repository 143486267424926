import { CustomEntitiesListingModel } from './models';

export const normalizeCustomEntitiesListing = ({ results, meta }: CustomEntitiesListingModel) => {
	if (!results) return;

	return {
		results: results,
		meta: meta,
	};
};
