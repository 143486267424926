import classNames from 'classnames';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Button } from 'reactstrap';
import REACT_APP_URLS from '../../../../../../global-helpers/global-url.helpers';
import { store } from '../../../../../../store/store';
import Loader from '../../../../../Partials/Shared/loader/Loader';
import LiveBlogModel from '../../../models/live-blog.model';

interface MatchCenterColumnProps {
	contentLanguage: string;
	events: LiveBlogModel['sport_events'];
	isColumnCollapsed: boolean;
	setIsColumnCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
}

const getWidgetIframeSrcDoc = (event: LiveBlogModel['sport_events'][number], lang: string) => {
	const { currentProject } = store.getState().project;

	return `<!DOCTYPE html>
	<html lang="en">
		<head>
			<script src="${REACT_APP_URLS.REACT_APP_WIDGETS_LOADER_API_URL}"></script>
			<script>
				window.onload = () => {
					new SMPWidgetsLoader({
						CDN_URL: '${REACT_APP_URLS.REACT_APP_WIDGETS_CDN_URL}',
						APP_LINK: 'desktop',
						LINK_OPTIONS: {
							player: 'https://temp-player.com/player-#id#',
							team: 'https://temp-team.com/team-#id#',
							placeholder: '#id#',
							openInNewTab: true,
						},
						ODD_CLIENT: '${currentProject.defaultOddClient}',
						API_URL: '${currentProject.footballApiUrl}',
						API_KEY: '2f852b4de2f3a5cf3188e74b33087bb4:2c18422f8e826a7d11aeed52edb2449e',
						LANG: '${lang}',
						REQUEST_INTERVAL: 30000,
						ENABLE_BET_SLIP: true,
					});
				};
			</script>
			<style>
				html {
					overflow: hidden;
				}
				body {
					margin: 0;
				}
			</style>
		</head>	
		<body><div data-widgetid='widget-match' data-options='{"lang":"${lang}","activeTab":"details","displayOdds":false,"eventId":${event.id},"market":null,"oddProviderIds":[],"canSelectMarkets":false,"displayMatchHeader":true,"hideEvents":false,"displayTeamShortNamesOnMobile":false,"displayTeamShortNamesOnDesktop":false}'></div></body>
	</html>`;
};

const MatchCenterColumn: FC<MatchCenterColumnProps> = ({ contentLanguage, events, isColumnCollapsed, setIsColumnCollapsed }) => {
	const [t] = useTranslation();
	const [isSMPWidgetsLoaderScriptLoaded, setIsSMPWidgetsLoaderScriptLoaded] = useState(false);
	const [selectedEvent, setSelectedEvent] = useState<typeof events[number]>(events[0]);
	const resizeObserverRef = useRef<ResizeObserver>();

	useEffect(() => {
		const interval = setInterval(() => {
			if (window['SMPWidgetsLoader']) {
				setIsSMPWidgetsLoaderScriptLoaded(true);
				clearInterval(interval);
			}
		}, 500);

		return () => {
			clearInterval(interval);
			resizeObserverRef.current && resizeObserverRef.current.disconnect();
		};
	}, []);

	useEffect(() => {
		if (!events.includes(selectedEvent)) {
			setSelectedEvent(events[0]);
		}
	}, [events, selectedEvent]);

	const buttonPositionHandler: React.MouseEventHandler<HTMLDivElement> = (event) => {
		const target = (event.target as HTMLDivElement).closest('.match-widget-preview-event');

		if (!target) {
			return;
		}

		try {
			const button = target.parentElement!.previousElementSibling as HTMLButtonElement;

			if (event.type === 'mouseover') {
				button.style.top = (target as HTMLDivElement).offsetTop + 2 + 'px';
				button.style.visibility = 'visible';
			} else {
				button.style.top = '';
				button.style.visibility = '';
			}
		} catch (e) {
			toast.error(t('error_occurred'));
			console.error(e);
		}
	};

	const iframeOnload: React.ReactEventHandler<HTMLIFrameElement> = (event) => {
		const iframe = event.target as HTMLIFrameElement;
		const resizeObserver = new ResizeObserver((entries) =>
			requestAnimationFrame(() => {
				iframe.style.height = entries[0].target.scrollHeight + 'px';
			}),
		);

		resizeObserverRef.current = resizeObserver;
		resizeObserver.observe(iframe.contentWindow!.document.body);
	};

	return (
		<>
			<div
				className={classNames('match-widget-preview-container', {
					'is-collapsed': isColumnCollapsed,
					'is-loading': !isSMPWidgetsLoaderScriptLoaded,
				})}
			>
				{isSMPWidgetsLoaderScriptLoaded ? (
					<>
						<Button
							color='primary'
							className='live-blog-editorial-admin-misc-container-toggle'
							onClick={() => setIsColumnCollapsed((prev) => !prev)}
						>
							<i className='icon-arrow-right'></i>
						</Button>
						<div className='match-widget-preview-events-list'>
							{events.map((event) => (
								<div
									key={event.id}
									className='match-widget-preview-event'
									onClick={() => {
										setSelectedEvent(event);
										setIsColumnCollapsed(false);
									}}
									onMouseOver={buttonPositionHandler}
									onMouseOut={buttonPositionHandler}
									title={event.home_team && event.away_team ? `${event.home_team.name} ${t('vs')} ${event.away_team.name}` : undefined}
								>
									<div
										className='match-widget-preview-event-team-logo'
										style={{ backgroundImage: event.home_team ? `url("${event.home_team.url_logo}")` : undefined }}
									></div>
									<div
										className='match-widget-preview-event-team-logo'
										style={{ backgroundImage: event.away_team ? `url("${event.away_team.url_logo}")` : undefined }}
									></div>
								</div>
							))}
						</div>

						{events.map((event) => (
							<div key={event.id} className={classNames('widget-preview-wrapper', { 'is-hidden': event !== selectedEvent })}>
								<iframe style={{ width: '100%' }} srcDoc={getWidgetIframeSrcDoc(event, contentLanguage)} onLoad={iframeOnload} />
							</div>
						))}
					</>
				) : (
					<Loader className='loading-overlay-h-auto loading-overlay-h-fill loading-overlay-w-fill' />
				)}
			</div>
		</>
	);
};

export default MatchCenterColumn;
