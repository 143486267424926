import React, { useState } from 'react';
import { Alert, Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import DeleteListItem from './delete-list-item.component';
import ListItem from '../../../../models/list/list-item/ListItem';
import { deleteItemFromList } from './helpers/delete-items-modal.helper';
import List from '../../../../models/list/List';
import { lockPositionsAlert, validateLockPositions } from '../ListsContent/list-content.helper';

type Properties = {
	t: any;
	open: boolean;
	onClose: (open: boolean) => any;
	list: List;
	onListItemsUpdate: (updatedListItems: ListItem[]) => any;
};

const DeleteListItemsModal: React.FunctionComponent<Properties> = (props) => {
	const [listItems, updateListItemsState] = useState<ListItem[]>([]);
	const [showLockPositionErrorMessage, updateLockPositionError] = useState<boolean>(false);
	const { open, t, onClose, onListItemsUpdate, list } = props;

	React.useEffect(() => {
		updateListItemsState([...list.items]);
	}, [list]);

	const deleteListItem = (isChecked: boolean, item: ListItem) => {
		const items = deleteItemFromList(isChecked, item, listItems);
		const updatedList = List.builder(list).withItems(items).build();

		updateListItemsState([...items]);
		updateLockPositionError(validateLockPositions(updatedList));
	};

	return (
		<Modal isOpen={open}>
			<ModalHeader toggle={() => onClose(true)}>{t('delete_list_items')}</ModalHeader>
			<ModalBody>
				<Row className='mb-1'>
					<Col xs='12'>
						{list.items &&
							list.items.map((item: ListItem, index: number) => (
								<DeleteListItem
									t={t}
									key={item.data.id}
									value={item}
									itemIndex={index}
									deleteItem={deleteListItem}
									isLocked={list.lockedPositions.includes(index)}
								/>
							))}
						<Alert color={'danger'} isOpen={showLockPositionErrorMessage}>
							{t('locked_list_items_error', {
								lockPosition: lockPositionsAlert(list),
							})}
						</Alert>
						<Alert color={'danger'} isOpen={list.items.length == list.lockedPositions.length}>
							{t('locked_list_items_delete_error')}
						</Alert>
					</Col>
				</Row>
			</ModalBody>
			<ModalFooter>
				<Button
					color='danger'
					id='list-edit-delete-button'
					className={'text-light'}
					disabled={showLockPositionErrorMessage || +list.configuration.maxItems < listItems.length}
					onClick={() => {
						onListItemsUpdate(listItems);
						onClose(true);
					}}
				>
					{t('delete')}
				</Button>
				<Button id='list-edit-cancel-button' color='secondary' className={'text-light'} onClick={() => onClose(true)}>
					{t('cancel')}
				</Button>
			</ModalFooter>
		</Modal>
	);
};

export default DeleteListItemsModal;
