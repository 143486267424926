import React from 'react';
import { TranslationEntities } from '../../../../Resources/translations/helpers/translations.constants';
import './custom-select-option.scss';

export function customOption(option) {
	const { logo, label, type } = option;
	const excludeLogos = [TranslationEntities.CITY, TranslationEntities.REFEREE];

	return (
		<div className='option-container'>
			{label && label.length > 0 && !excludeLogos.includes(type) && logo && (
				<img width='25' className={`option-logo-${type} mr-1`} src={logo} />
			)}
			<div className='option-label text-break'>{label}</div>
		</div>
	);
}
