import MatchModel from '../models/match.model';
import moment from 'moment';

export const matchListToOptionList = (matchList: MatchModel[]) => {
	if (matchList && matchList.length > 0) {
		return matchList.map((match: MatchModel) => matchToOption(match));
	}

	return [];
};

export const matchToOption = (match: MatchModel) => {
	if (match && match.homeTeam && match.homeTeam.id) {
		return {
			value: match.id,
			label: `${moment(match.startTime).format('DD MMMM YYYY')},
             ${match.homeTeam.name} - ${match.awayTeam.name}`,
			data: match,
		};
	}

	return { value: '', label: '' };
};

export const getLatestNumberOfMatches = (response: any, numberOfMatches: number) => {
	if (numberOfMatches === undefined) {
		numberOfMatches = 6;
	}

	if (response && response.length > 0) {
		return response.slice(0, numberOfMatches);
	}

	return [];
};
