import { ReduxURLsProps } from '../constants/redux-urls.attributes';

const defaultText = 'Public URL';

export const getLabel = (t: any, propertyName: ReduxURLsProps): string => {
	switch (propertyName) {
		case ReduxURLsProps.DESKTOP_URL:
			return t('public_url_desktop');
		case ReduxURLsProps.MOBILE_URL:
			return t('public_url_mobile');
		case ReduxURLsProps.AMP_URL:
			return t('public_url_amp');
		default:
			return defaultText;
	}
};

export const getPlaceholder = (t: any, propertyName: ReduxURLsProps): string => {
	switch (propertyName) {
		case ReduxURLsProps.DESKTOP_URL:
			return t('desktop_url');
		case ReduxURLsProps.MOBILE_URL:
			return t('mobile_url');
		case ReduxURLsProps.AMP_URL:
			return t('amp_url');
		default:
			return defaultText;
	}
};
