import { FeatureJson } from './FeatureJson';
import Feature from './Feature';

export default class FeatureBuilder {
	private json: FeatureJson;

	constructor(feature?: Feature | FeatureJson) {
		if (feature && feature instanceof Feature) {
			this.json = feature.toJSON();
		} else if (feature) {
			this.json = feature;
		} else {
			this.json = {} as FeatureJson;
		}
	}

	public withId(id: string) {
		this.json.id = id;

		return this;
	}

	public withEnabled(enabled: boolean) {
		this.json.enabled = enabled;

		return this;
	}

	public withData(data: any) {
		this.json.data = data;

		return this;
	}

	public build() {
		return Feature.fromJSON(this.json);
	}
}
