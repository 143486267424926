import MatchWidgetModel from './match-widget.model';
import { MatchWidgetJson } from './match-widget.json';
import MatchModel from '../../../../partials/match-select/models/match.model';
import TeamModel from '../../../../partials/team-select/models/team.model';

export default class MatchWidgetBuilder {
	readonly json: MatchWidgetJson;

	constructor(match?: MatchWidgetModel | MatchWidgetJson) {
		if (match && match instanceof MatchWidgetModel) {
			this.json = match.toJson();
		} else if (match) {
			this.json = match;
		} else {
			this.json = {} as MatchWidgetJson;
		}
	}

	withMatch(match: MatchModel): MatchWidgetBuilder {
		this.json.match = match;

		return this;
	}

	withTeams(teams: TeamModel[]): MatchWidgetBuilder {
		this.json.teams = teams;

		return this;
	}

	withActiveTab(activeTab: string): MatchWidgetBuilder {
		this.json.activeTab = activeTab;

		return this;
	}

	withHideTabs(hideTabs: any[]): MatchWidgetBuilder {
		this.json.hideTabs = hideTabs;

		return this;
	}

	withDisplayOdds(displayOdds: boolean): MatchWidgetBuilder {
		this.json.displayOdds = displayOdds;

		return this;
	}

	withHideEvents(hideEvents: boolean): MatchWidgetBuilder {
		this.json.hideEvents = hideEvents;

		return this;
	}

	withHomeTeamColor(homeTeamColor: any): MatchWidgetBuilder {
		this.json.homeTeamColor = homeTeamColor;

		return this;
	}

	withAwayTeamColor(awayTeamColor: any): MatchWidgetBuilder {
		this.json.awayTeamColor = awayTeamColor;

		return this;
	}

	withThreeLetterCodeType(threeLetterCodeType: string): MatchWidgetBuilder {
		this.json.threeLetterCodeType = threeLetterCodeType;

		return this;
	}

	build(): MatchWidgetModel {
		return MatchWidgetModel.fromJson(this.json);
	}
}
