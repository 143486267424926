import { BookmakerJson } from './bookmaker.json';
import BookmakerBuilder from './bookmaker.builder';
import { imagePlaceHolder } from '../../../../../../constants/resource-constants';

export default class BookmakerModel {
	readonly id: string = '';
	readonly name: string = '';
	readonly logo: string = '';
	readonly overUnder: any = { over: {}, under: {} };

	private constructor(id: string, name: string, logo: string, overUnder: any) {
		this.id = id;
		this.name = name;
		this.logo = logo ? logo : imagePlaceHolder;
		this.overUnder.over = overUnder ? overUnder.over : {};
		this.overUnder.under = overUnder ? overUnder.under : {};
	}

	toJson(): BookmakerJson {
		return {
			id: this.id,
			name: this.name,
			logo: this.logo,
			overUnder: this.overUnder,
		};
	}

	static fromJson(json: BookmakerJson): BookmakerModel {
		return new BookmakerModel(json.id, json.name, json.logo, json.overUnder);
	}
	static builder(bookmaker?: BookmakerModel): BookmakerBuilder {
		return new BookmakerBuilder(bookmaker);
	}
}
