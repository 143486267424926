import TournamentModel from '../../../../../../../models/v2/Tournament/Entity/TournamentModel';
import PlayerModel from '../../../../../../../models/v2/player/entity/player.model';
import TeamModel from '../../../../../../../models/v2/Team/Entity/TeamModel';
import { FootballTopScorersJson } from './football-top-scorers.json';
import FootballTopScorersBuilder from './football-top-scorers.builder';
import SeasonModel from '../../../../../../../models/v2/Season/Entity/SeasonModel';
import { RefreshTimeValuesType } from '../../refresh-time-input/helper';

export default class FootballTopScorersModel {
	readonly tournament: TournamentModel;
	readonly season: SeasonModel;
	readonly topScorers: PlayerModel[] = [];
	readonly players: PlayerModel[];
	readonly teams: TeamModel[];
	readonly filter: boolean;
	readonly limit: string;
	readonly startFrom: string = '1';
	readonly stats: string[];
	readonly refreshTime: RefreshTimeValuesType;

	private constructor(
		tournament: TournamentModel,
		season: SeasonModel,
		topScorers: PlayerModel[],
		players: PlayerModel[],
		teams: TeamModel[],
		filter: boolean,
		limit: string,
		startFrom: string,
		stats: string[],
		refreshTime: RefreshTimeValuesType,
	) {
		this.tournament = tournament;
		this.season = season;
		this.topScorers = topScorers;
		this.players = players;
		this.teams = teams;
		this.filter = filter;
		this.limit = limit;
		this.startFrom = startFrom;
		this.stats = stats;
		this.refreshTime = refreshTime;
	}

	toJson(): FootballTopScorersJson {
		return {
			tournament: this.tournament,
			season: this.season,
			topScorers: this.topScorers,
			players: this.players,
			teams: this.teams,
			filter: this.filter,
			limit: this.limit,
			startFrom: this.startFrom,
			stats: this.stats,
			refreshTime: this.refreshTime,
		};
	}

	static fromJson(json: FootballTopScorersJson): FootballTopScorersModel {
		return new FootballTopScorersModel(
			json.tournament,
			json.season,
			json.topScorers,
			json.players,
			json.teams,
			json.filter,
			json.limit,
			json.startFrom,
			json.stats,
			json.refreshTime,
		);
	}

	static builder(topScorers?: FootballTopScorersModel): FootballTopScorersBuilder {
		return new FootballTopScorersBuilder(topScorers);
	}
}
