import React, { ComponentType } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { BlacklistModalProperties, RequiredModalProperties } from './properties/blacklist-modal.properties';

const BlacklistModal: React.FunctionComponent<BlacklistModalProperties> = ({
	t,
	isToggledModal,
	contentToDelete,
	onDeleteContent,
	toggleModal,
	type,
}) => {
	return (
		<Modal isOpen={isToggledModal}>
			<ModalHeader className='pl-3 pr-3 pt-2 pb-2' toggle={() => toggleModal(false)}>
				{t('remove_from_blacklist')}
			</ModalHeader>
			<ModalBody className='pb-2'>{t(`remove_${type}_from_blacklist`)}</ModalBody>
			<ModalFooter className='border-0'>
				<Button onClick={() => toggleModal(false)} className={'ml-auto btn-secondary'}>
					{t('close')}
				</Button>{' '}
				<Button color='danger' onClick={() => onDeleteContent(contentToDelete.blacklist.id, contentToDelete.title)}>
					{t('remove')}
				</Button>{' '}
			</ModalFooter>
		</Modal>
	);
};

export default BlacklistModal as ComponentType<RequiredModalProperties | BlacklistModalProperties>;
