import React from 'react';
import { toast } from 'react-toastify';
import { TOP_EVENTS_SPORT, displayEventPreviewRow, extractLogoFromCompetition, isCompetition, isEvent } from './utils';
import { FullTree, NodeData, OnMovePreviousAndNextLocation, TreeItem } from 'react-sortable-tree';
import i18n from '../../../../i18n';
import { getEditIconAsCssUrl, getStarIconAsCssUrl } from './icons';

export const changeTreeState = (data: NodeData & FullTree & OnMovePreviousAndNextLocation, updateEventsOrderInRedux: Function) => {
	const movedEl = data.node;
	const newParentEl = data.nextParentNode;

	if (isEvent(movedEl.entityType)) {
		// moving an event element

		if (!newParentEl) {
			// moving an event element in same level as competition
			toast.error(i18n.t('event_outside_competition'));
			return;
		}

		if (isEvent(newParentEl.entityType)) {
			// moving an event element inside other event element
			toast.error(i18n.t('event_inside_diff_event'));
			return;
		}

		if (newParentEl.id !== movedEl.competitionId) {
			// moving an event element in different competition
			toast.error(i18n.t('event_inside_diff_competition'));
			return;
		}
	} else {
		// moving a competition element

		if (newParentEl) {
			// moving a competition element inside other element
			toast.error(i18n.t('competition_inside_diff_el'));
			return;
		}
	}

	if (data && data.treeData && data.treeData.length > 0) {
		updateEventsOrderInRedux(data.treeData);
	}
};

export const generateTreeDataTitle = (node: TreeItem, displayRemoveEntityModal: Function) => {
	return (
		<div key={`tree-row-${node.id}`} id={`tree-row-${node.id}`}>
			{isCompetition(node.entityType) && <img className='competition-node-img' src={extractLogoFromCompetition(node.element)} />}
			{isEvent(node.entityType) ? displayEventPreviewRow(node.element) : <span className='event-title'>{node.title}</span>}
			<div
				className='remove-multisport-item-icon'
				style={{
					backgroundImage:
						isEvent(node.entityType) && node.previewSection === TOP_EVENTS_SPORT ? getStarIconAsCssUrl(true) : getEditIconAsCssUrl(),
				}}
				onClick={() => displayRemoveEntityModal(node.element, node.previewSection)}
			/>
		</div>
	);
};
