import React, { Component } from 'react';
import HttpService from '../../../../../services/rest/HttpService';
import { stageGroupsToOptions, remapGroupsFromResponse, stageGroupToOption } from './helpers/stage-groups-select.helper';
import Select from 'react-select';
import { Properties, State } from './properties/stage-group-select.properties';
import { Label } from 'reactstrap';

export default class StageGroupsSelectContainer extends Component<Properties, State> {
	constructor(props: Properties) {
		super(props);
		this.state = {
			stageGroupsOptions: props.isMulti ? [] : null,
		};
	}

	componentDidMount() {
		if (this.props.stageId) {
			this.getStageGroups(this.props.stageId);
		}
	}

	componentDidUpdate(prevProps: Properties) {
		if (prevProps.stageId !== this.props.stageId) {
			this.getStageGroups(this.props.stageId);
		}
	}

	updateStageGroupsOptionsState = (groups: any[]) => {
		const { onStageGroupSelect, selectedStageGroups } = this.props;

		if (selectedStageGroups === undefined || selectedStageGroups === null || selectedStageGroups.length < 1) {
			onStageGroupSelect(this.props.isMulti ? groups : groups.length > 0 ? groups[0] : null);
		}
		this.setState({ ...this.state, stageGroupsOptions: groups });
	};

	getStageGroups = (stageId: string) => {
		if (stageId) {
			HttpService.getStageById(stageId).then((response: any) => {
				this.updateStageGroupsOptionsState(remapGroupsFromResponse(response.data.groups));
			});
		}
	};

	onSelect = (selections: any) => {
		const { isMulti, onStageGroupSelect } = this.props;
		if (selections) {
			onStageGroupSelect(isMulti ? selections.map((selection: any) => selection.data) : selections.data);
		} else {
			onStageGroupSelect(isMulti ? [] : null);
		}
	};

	render() {
		const { selectedStageGroups, stageId, isMulti, withLabel, label, isRequired, className } = this.props;
		const { stageGroupsOptions } = this.state;

		if (stageId === undefined || stageId === null || stageId.length < 1 || (stageGroupsOptions && stageGroupsOptions.length < 1)) {
			return null;
		}

		return (
			<>
				{withLabel && <Label>{this.props.t('stage_groups')}:</Label>}
				{label && <Label>{this.props.t(label)}</Label>}
				{isRequired && <span className='text-danger'>*</span>}
				<Select
					className={className ? className : ''}
					isMulti={isMulti}
					id='stage-group-select'
					options={stageGroupsToOptions(this.state.stageGroupsOptions)}
					value={isMulti ? stageGroupsToOptions(selectedStageGroups) : stageGroupToOption(selectedStageGroups)}
					onChange={this.onSelect}
				/>
			</>
		);
	}
}
