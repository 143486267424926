import { ImagoImagesJson } from './imago-images.json';
import ImagoImagesModel from './imago-images.model';
import ImagoImageModel from './imago-image.model';
import { ImagoImagesRequestJson } from './imago-images-request-json';

export default class ImagoImagesBuilder {
	private json: ImagoImagesJson;

	constructor(images?: ImagoImagesModel | ImagoImagesJson) {
		if (images && images instanceof ImagoImagesModel) {
			this.json = images.toJSON();
		} else if (images) {
			this.json = images;
		} else {
			this.json = {} as ImagoImagesJson;
		}
	}

	public withImagesList(imageList: ImagoImageModel[]) {
		this.json.imageList = imageList;

		return this;
	}

	public withTotalPhotos(totalPhotos: number) {
		this.json.totalPhotos = totalPhotos;

		return this;
	}

	public build() {
		return ImagoImagesModel.fromJSON(this.json);
	}

	toRequestJson(): ImagoImagesRequestJson {
		let json = {} as ImagoImagesRequestJson;
		json.total = this.json.totalPhotos;
		json.pictures = this.json.imageList;

		return json;
	}
}
