import React from 'react';
import { connect } from 'react-redux';
import Project from '../../../models/project/Project';
import ListingComponent from '../../Partials/BaseComponents/ListingComponent/ListingComponent';
import { Properties, State } from './Properties/ListListingProperties';
import {
	LIST_ENTITY_DELETE_SUCCESS,
	returnObjectForListEntityDelete,
	returnObjectForListListingRequest,
	returnObjectForListSearch,
} from '../../../store/action-creators/ListActionCreators';
import { ContentTypes } from '../../../constants/content-types';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import ConditionalRenderWrapper from '../../Partials/BaseComponents/ConditionalRenderWraper/ConditionalRenderWrapper';
import PaginationMeta from '../../../models/pagination/PaginationMeta';
import { returnObjectForArticlePaginationUpdate } from '../../../store/action-creators/ArticleActionCreator';
import { returnObjectForVideoPaginationUpdate } from '../../../store/action-creators/VideoActionCreator';
import { returnObjectForGalleryPaginationUpdate } from '../../../store/action-creators/GalleryActionCreator';
import { returnObjectForImagesPaginationUpdate } from '../../../store/action-creators/ImageActionCreator';
import { returnObjectForContentFiltersUpdate } from '../../../store/action-creators/content-resources';
import { remmapContentFilters } from '../Articles/Helpers/ArticleHelper';
import { returnObjectForWikiPaginationUpdate } from '../../../store/action-creators/WikiActionCreator';
import AdvancedFilterModel from '../../Partials/Shared/advanced-content-filter/models/advanced-content-filter.model';

class ListListing extends React.Component<Properties, State> {
	constructor(props: Properties) {
		super(props);
		this.state = {
			listSearchInput: '',
			toggleModal: false,
			currentPage: '1',
		};
		this.registerActionEventListener();
	}

	getListsListener = () => this.props.getLists(this.state.currentPage, this.props.currentProject);

	registerActionEventListener() {
		window.addEventListener(LIST_ENTITY_DELETE_SUCCESS, this.getListsListener);
	}

	setCurrentPage(page: string) {
		this.props.analytics.sendPageView(this.props.location.pathname, `${parseInt(page) > 1 ? 'ListListing - Page ' + page : 'ListListing'}`);
		this.setState({
			...this.state,
			currentPage: page,
		});
	}

	componentDidMount(): void {
		this.initPageTitle();
		this.props.analytics.sendPageView(this.props.location.pathname, 'ListListing');
		this.props.getLists(this.state.currentPage, this.props.currentProject);
		this.props.updateArticlesPagination(PaginationMeta.builder().withCurrentPage(1).build());
		this.props.updateVideosPagination(PaginationMeta.builder().withCurrentPage(1).build());
		this.props.updateGalleriesPagination(PaginationMeta.builder().withCurrentPage(1).build());
		this.props.updateImagesPagination(PaginationMeta.builder().withCurrentPage(1).build());
		this.props.updateWikiPagesPagination(PaginationMeta.builder().withCurrentPage(1).build());
		const filters = remmapContentFilters({}, {}, {}, {}, {}, {}, {}, {}, {}, {} as AdvancedFilterModel);
		this.props.updateContentFilters(filters);
	}

	componentWillUnmount() {
		window.removeEventListener(LIST_ENTITY_DELETE_SUCCESS, this.getListsListener);
	}

	initPageTitle() {
		document.title = this.props.t('lists');
	}

	render() {
		return (
			<ConditionalRenderWrapper expectedPermissions={['read_lists', 'write_lists', 'delete_lists']}>
				<ListingComponent
					contentType={ContentTypes.LIST}
					getContent={this.props.getLists}
					analytics={this.props.analytics}
					searchContent={this.props.searchLists}
					currentProject={this.props.currentProject}
					showSearchBar={false}
					onPageChange={this.setCurrentPage.bind(this)}
					history={this.props.history}
					onDeleteContent={(id: string) => {
						this.props.deleteList(id, this.props.currentProject);
					}}
					content={this.props.lists}
					contentPagination={this.props.pagination}
					location={this.props.location.pathname}
				/>
			</ConditionalRenderWrapper>
		);
	}
}

function mapStateToProps(state: any) {
	return {
		profile: state.profile.profile,
		lists: state.list.lists,
		listDeleted: state.list.listDeleted,
		currentProject: state.project.currentProject,
		pagination: state.list.pagination,
	};
}

function mapDispatchToProps(dispatch: any) {
	return {
		getLists: (page: string, project: Project) => {
			dispatch(returnObjectForListListingRequest(page, project));
		},
		searchLists: (text: string, project: Project) => dispatch(returnObjectForListSearch(text, project)),
		deleteList: (id: string, project: Project) => dispatch(returnObjectForListEntityDelete(id, project)),
		updateGalleriesPagination: (pagination: PaginationMeta) => dispatch(returnObjectForGalleryPaginationUpdate(pagination)),
		updateVideosPagination: (pagination: PaginationMeta) => dispatch(returnObjectForVideoPaginationUpdate(pagination)),
		updateArticlesPagination: (pagination: PaginationMeta) => dispatch(returnObjectForArticlePaginationUpdate(pagination)),
		updateImagesPagination: (pagination: PaginationMeta) => dispatch(returnObjectForImagesPaginationUpdate(pagination)),
		updateWikiPagesPagination: (pagination: PaginationMeta) => dispatch(returnObjectForWikiPaginationUpdate(pagination)),
		updateContentFilters: (filters: any) => dispatch(returnObjectForContentFiltersUpdate(filters)),
	};
}

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(ListListing) as React.ComponentType;
