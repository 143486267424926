import { Properties, State } from './properties/list-related-content-container.properties';
import * as React from 'react';
import Related from '../../../../models/related/Related';
import { SortEnd } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import ContentSortableList from './subcomponents/content-sortable-list';
import * as ReactSortableHOC from 'react-sortable-hoc';
import { Label } from 'reactstrap';

const SortableList = ReactSortableHOC.SortableContainer(ContentSortableList);
export default class ListRelatedContentContainer extends React.Component<Properties, State> {
	constructor(props: Properties) {
		super(props);
		this.state = {
			relatedContent: this.props.relatedContent,
		};
	}

	onRelatedContentRemove = (related: Related) => {
		this.props.onRelatedContentRemove(related);
	};

	onSortEnd = (sortEnd: SortEnd) => {
		let related = this.props.relatedContent.slice();
		related = arrayMove(related, sortEnd.oldIndex, sortEnd.newIndex);
		this.props.onSortEnd(related);
	};

	render() {
		const { relatedContent, t, type } = this.props;

		return (
			<>
				<Label className='font-weight-bold'>{t(`selected_${type}`)}</Label>
				{relatedContent && relatedContent.length === 0 && <div className={'text-left'}>{this.props.t(`no_${type}_selected`)}</div>}
				{relatedContent && relatedContent.length > 0 && (
					<SortableList t={t} related={relatedContent} useDragHandle onSortEnd={this.onSortEnd} onDelete={this.onRelatedContentRemove} />
				)}
			</>
		);
	}
}
