import React, { useEffect, useState } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import CoachManualDataModel from '../../../../../../models/coach-manual-data/coach-manual-data.model';
import ModelMapper from '../../../../../../models/ModelMapper';
import HttpService from '../../../../../../services/rest/HttpService';
import EntityModel from '../../../../translations/models/entity.model';
import { constructRequestFromEntry, remapCoachBlacklisted } from '../../helpers/manual-data.helper';
import CoachEditComponent from '../../coaches/coach-edit.component';
import { actionService, featuresService } from '../../../../../../App';
import { FeatureTypes } from '../../../../../../services/feature-service/features.enum';
import { Subscription } from 'rxjs';
import {
	COACH_ENTITY_BLACKLIST_REMOVE_SUCCESS,
	COACH_ENTITY_UPDATE_SUCCESS,
	COACH_ENTITY_RECEIVED,
} from '../../../../../../store/action-creators/coach-action-creator';
import BlacklistModel from '../../../../../../models/player-manual-data/blacklist/blacklist';

type Properties = {
	t: any;
	match: any;
	coach: EntityModel;
	teamId: string;
};

const TeamEditCoachComponent: React.FunctionComponent<RouteComponentProps<{}> & Properties> = (props) => {
	const [isContentLoading, updateloading] = useState<boolean>(true);
	const [coach, updateCoach] = useState<CoachManualDataModel>(CoachManualDataModel.builder().build());
	const featureEnabled = featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.MANUAL_DATA_ENTRY_COACH);
	const [isOpenModal, toggleModal] = useState<boolean>(false);
	let registerActionSubscription = new Subscription();

	const { t } = props;

	useEffect(() => {
		if (props.teamId === props.match.params.id) {
			getCoach();
			registerActionEventListener();

			return function cleanup() {
				if (registerActionSubscription) {
					registerActionSubscription.unsubscribe();
				}
			};
		}
	}, []);

	const registerActionEventListener = () => {
		registerActionSubscription = actionService.onActionReceived().subscribe((action: string) => {
			if (action === COACH_ENTITY_UPDATE_SUCCESS) {
				getCoach();
			}

			if (action === COACH_ENTITY_BLACKLIST_REMOVE_SUCCESS) {
				getCoach();
			}
		});
	};

	useEffect(() => {
		props.teamId === props.match.params.id && getCoach();
		registerActionEventListener();
		return function cleanup() {
			if (registerActionSubscription) {
				registerActionSubscription.unsubscribe();
			}
		};
	}, [props.coach && props.coach.id, props.teamId]);

	const getCoach = () => {
		if (props.coach && props.coach.id) {
			HttpService.instanceFootball()
				.get(`/v2/coaches/${props.coach.id}`)
				.then((response: any) => setTeamCoachFromResponse(response.data))
				.catch(() => updateloading(false));
		} else {
			if (coach && coach.id && props.coach && props.coach.id) {
				updateCoach(CoachManualDataModel.builder(coach).withBlacklist(BlacklistModel.builder().build()).build());
			} else {
				updateCoach(CoachManualDataModel.builder().withBlacklist(BlacklistModel.builder().build()).build());
			}
			updateloading(false);
		}
	};

	const setTeamCoachFromResponse = (data: any) => {
		updateloading(false);
		const coach = ModelMapper.remapCoachFromResponse(data);
		updateCoach(coach);
		getBlacklistEntityData(coach);
	};

	const getBlacklistEntityData = (coach: any) => {
		const params = constructRequestFromEntry([coach], 'COACH', 'ENTITY');
		HttpService.getBlacklist(`/v2/blacklist/search`, params)
			.then((response: any) => {
				const blacklist = response.data;
				if (blacklist && blacklist.length > 0) {
					updateCoach(remapCoachBlacklisted([coach], blacklist)[0]);
				} else {
					updateCoach(CoachManualDataModel.builder(coach).withBlacklist(BlacklistModel.builder().build()).build());
				}
			})
			.catch((error: unknown) => console.error(error));
	};

	const onEditCoach = (coach: CoachManualDataModel) => {
		updateCoach(coach);
		toggleModal(true);
	};

	return (
		<div className={`${isContentLoading ? 'loading-overlay' : ''}`}>
			<Row style={{ margin: '0 auto' }} className='font-weight-bold py-2 border-top border-bottom w-100'>
				<Col xs={4} sm={6}>
					{props.t('coach_name')}
				</Col>
				<Col xs={3} sm={2}>
					{props.t('nationality')}
				</Col>
				<Col xs={3} sm={2}>
					{props.t('status')}
				</Col>
				<Col xs={3} sm={2} className='text-right'>
					{props.t('actions')}
				</Col>
			</Row>
			{coach && coach.id ? (
				<Row key={coach.id} id={`coach-row-${coach.id}`} className={`py-2 border-bottom align-items-center w-100 bg-light m-0`}>
					<Col xs={4} sm={6}>
						<div className='text-dark btn btn-link cursor-pointer' onClick={() => (featureEnabled ? toggleModal(true) : null)}>
							<span>{coach.name}</span>
						</div>
					</Col>
					<Col className='pl-2' xs={3} sm={2}>
						{coach.country.name}
					</Col>
					<Col className='pl-2' xs={3} sm={2}>
						{coach.blacklist && coach.blacklist.id && (
							<div className='d-flex'>
								<div className='blacklisted bg-warning text-dark text-center'>{t('blacklisted')}</div>
								<i className='text-white fa fa-user rounded-circle mr-1 team-edit-icon ml-1' />
							</div>
						)}
					</Col>
					<Col xs={3} sm={2} className='text-right'>
						<Button
							disabled={!featureEnabled}
							title={featureEnabled ? 'Edit' : 'No permissions to edit'}
							id={`coach-edit-btn-${coach.id}`}
							className='shadow-none'
							color='warning'
							onClick={() => onEditCoach(coach)}
						>
							<i className='fa fa-pencil p-1' />
							{t('edit')}
						</Button>
					</Col>
				</Row>
			) : (
				<div className='p-4 text-center'>{t('no_added_coach')}</div>
			)}
			<Modal modalClassName='edit-squad-players-modal' isOpen={isOpenModal}>
				<ModalHeader toggle={() => toggleModal(false)}>{t('coach_edit_title')}</ModalHeader>
				<ModalBody>
					<CoachEditComponent isAddedInModal t={t} coachId={coach.id} onClose={toggleModal} />
				</ModalBody>
			</Modal>
		</div>
	);
};

export default withRouter(TeamEditCoachComponent);
