import Article from '../../models/article/Article';
import {
	ARTICLE_TEMP_CLEAR,
	ARTICLE_TEMP_EDIT_UPDATE,
	ARTICLE_TEMP_PROPERTY_UPDATE,
	ARTICLE_TEMP_UPDATE,
} from '../action-creators/ArticleTempActionCreator';
import { USER_LOGOUT } from '../action-creators/ProfileActionCreators';
import {
	ARTICLE_ENTITY_CREATE_SUCCESS,
	ARTICLE_ENTITY_RECEIVED,
	CONTENT_STATISTICS_ARTICLE_ENTITY_RECEIVED,
} from '../action-creators/ArticleActionCreator';
import { PROJECT_RESOURCES_REQUEST } from '../../constants/constants';
import { ContentMode, ContentTypes } from '../../constants/content-types';
import { createStartingEntity } from '../../services/content-models-service/ContentModelService';

interface InitialState {
	article: Article;
	articleEdit: Article;
}

const initialState: InitialState = {
	article: createStartingEntity(ContentTypes.ARTICLE, true) as Article,
	articleEdit: Article.builder().build(),
};

function articleTempReducer(state: any = initialState, action: any) {
	if (action.type === PROJECT_RESOURCES_REQUEST) {
		return Object.assign({}, state, initialState);
	}

	if (action.type === ARTICLE_TEMP_UPDATE) {
		return Object.assign({}, state, {
			article: action.payload,
		});
	}

	if (action.type === ARTICLE_ENTITY_RECEIVED) {
		return Object.assign({}, state, {
			articleEdit: action.payload.articleEdit,
		});
	}

	if (action.type === ARTICLE_TEMP_EDIT_UPDATE) {
		return Object.assign({}, state, {
			articleEdit: action.payload,
		});
	}

	if (action.type === ARTICLE_TEMP_PROPERTY_UPDATE) {
		const propertyName = `article${action.contentMode === ContentMode.EDIT ? 'Edit' : ''}`;

		return Object.assign({}, state, {
			[propertyName]: { ...state[propertyName], ...action.payload },
		});
	}

	if (action.type === ARTICLE_TEMP_CLEAR) {
		return Object.assign({}, state, {
			article: initialState.article,
		});
	}

	if (action.type === ARTICLE_ENTITY_CREATE_SUCCESS) {
		return Object.assign({}, state, {
			article: initialState.article,
			articleEdit: initialState.articleEdit,
		});
	}

	if (action.type === CONTENT_STATISTICS_ARTICLE_ENTITY_RECEIVED) {
		return Object.assign({}, state, {
			articleEdit: action.payload.article,
		});
	}

	if (action.type === USER_LOGOUT) {
		state = initialState;
	}

	return state;
}

export default articleTempReducer;
