import { USER_LOGOUT } from '../action-creators/ProfileActionCreators';
import LiveBlogListingModel from '../../views/Pages/Live Blog/models/live-blog-listing.model';
import PaginationMetaModel from '../../views/Pages/Live Blog/models/live-blog-meta.model';
import LiveBlogAdvancedFiltersModel from '../../views/Pages/Live Blog/models/live-blog-advanced-filters.model';
import {
	LIVE_BLOGS_ENTITY_DELETE_FAILED,
	LIVE_BLOGS_ENTITY_DELETE_SUCCESS,
	LIVE_BLOGS_FILTERS_UPDATE,
	LIVE_BLOGS_LISTING_RECEIVED,
	LIVE_BLOGS_PAGINATION_UPDATE,
} from '../action-creators/LiveBlogsActionCreator';

interface InitialState {
	liveBlogs: LiveBlogListingModel[];
	pagination: PaginationMetaModel;
	liveBlogDeleted: boolean;
	filters: LiveBlogAdvancedFiltersModel;
}

const initialState: InitialState = {
	liveBlogs: [],
	liveBlogDeleted: false,
	pagination: {} as PaginationMetaModel,
	filters: {} as LiveBlogAdvancedFiltersModel,
};

function liveBlogsReducer(state: any = initialState, action: any) {
	if (action.type === LIVE_BLOGS_LISTING_RECEIVED) {
		return Object.assign({}, state, {
			liveBlogs: action.payload.liveBlogs,
			pagination: action.payload.contentPagination,
		});
	}

	if (action.type === LIVE_BLOGS_PAGINATION_UPDATE) {
		return Object.assign({}, state, {
			pagination: action.payload.pagination,
		});
	}

	if (action.type === LIVE_BLOGS_ENTITY_DELETE_SUCCESS) {
		return Object.assign({}, state, {
			liveBlogDeleted: true,
		});
	}

	if (action.type === LIVE_BLOGS_ENTITY_DELETE_FAILED) {
		return Object.assign({}, state, {
			liveBlogDeleted: false,
		});
	}

	if (action.type === LIVE_BLOGS_FILTERS_UPDATE) {
		return Object.assign({}, state, {
			filters: action.payload.filters,
		});
	}

	if (action.type === USER_LOGOUT) {
		state = initialState;
	}

	return state;
}

export default liveBlogsReducer;
