import TennisTournamentModel from '../../../../../../models/v2/tennis-tournament/tennis-tournament.model';

export const tournamentsToOptions = (tournaments: TennisTournamentModel[], isAthleteProgramme: boolean) => {
	if (tournaments && tournaments.length > 0) {
		return tournaments.map((tournament) => {
			return tournamentToOption(tournament, isAthleteProgramme);
		});
	}

	return [];
};

export const tournamentToOption = (tournament: TennisTournamentModel, isAthleteProgramme: boolean) => {
	const showTournamentGender = isAthleteProgramme ? '' : `(${tournament.gender})`;
	if (tournament) {
		return {
			value: tournament.id,
			label: `${showTournamentGender} ${tournament.name}-${tournament.seasonYear}`,
			data: tournament,
			type: 'tournament',
		};
	} else {
		return {};
	}
};
