import { PostRequestJson } from './post-request-json';
import { PostJson } from './post-json';
import Post from './post';

export default class PostBuilder {
	private json: PostJson;

	constructor(feed?: Post | PostJson) {
		if (feed && feed instanceof Post) {
			this.json = feed.toJSON();
		} else if (feed) {
			this.json = feed;
		} else {
			this.json = {} as PostJson;
		}
	}

	withId(id: string): PostBuilder {
		this.json.origin_id = id;

		return this;
	}

	withType(type: string): PostBuilder {
		this.json.type = type;

		return this;
	}

	withSubtype(subtype: string): PostBuilder {
		this.json.subtype = subtype;

		return this;
	}

	withEmbed(embed: string): PostBuilder {
		this.json.embed = embed;

		return this;
	}

	withUrl(url: string): PostBuilder {
		this.json.origin_location = url;

		return this;
	}

	withTimestamp(timestamp: string): PostBuilder {
		this.json.published_at = timestamp;

		return this;
	}

	withSourceLocation(sourceLocation: string): PostBuilder {
		this.json.source_location = sourceLocation;

		return this;
	}

	withSourceName(sourceName: string): PostBuilder {
		this.json.sourceName = sourceName;

		return this;
	}

	withSourceThumbnail(sourceThumbnail: string): PostBuilder {
		this.json.source_thumbnail = sourceThumbnail;

		return this;
	}

	toRequestJson(): PostRequestJson {
		let json = {} as PostRequestJson;

		json.id = this.json.origin_id;
		json.type = this.json.type;
		json.subtype = this.json.subtype;
		json.embed = this.json.embed;
		json.url = this.json.origin_location;
		json.timestamp = this.json.published_at;
		json.sourceLocation = this.json.source_location;
		json.sourceThumbnail = this.json.source_thumbnail;

		return json;
	}

	build(): Post {
		return Post.fromJSON(this.json);
	}
}
