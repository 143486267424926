import React, { FunctionComponent } from 'react';
import ListModel from '../../../../../../models/v2/list/list';
import { connect } from 'react-redux';
import * as ReactSortableHOC from 'react-sortable-hoc';
import { updateListInRedux } from '../../../../../../store/action-creators/ListActionCreators';
import { Col, Row } from 'reactstrap';
import { isListEmpty } from '../../../../../Partials/Sidebar/list-refactored/helpers/general.helper';
import LiveBlogListContentSortableList from './live-blog-sortable-list.component';
import './style/live-blog-list.style.scss';

type Properties = {
	t: any;
	selectedList: ListModel | null;
	updateListInRedux: (list: ListModel) => void;
};

const SortableList = ReactSortableHOC.SortableContainer(LiveBlogListContentSortableList);

const LiveBlogListContentSortableContainer: FunctionComponent<Properties> = ({ t, selectedList, updateListInRedux }) => {
	if (!selectedList) return null;

	const onSortListItem = (sortEnd: ReactSortableHOC.SortEnd) => {
		const allItems = selectedList.items;
		const newListOrder = ReactSortableHOC.arrayMove(allItems, sortEnd.oldIndex, sortEnd.newIndex);
		updateListInRedux({ ...selectedList, items: newListOrder });
	};

	return (
		<Row>
			<Col>
				<SortableList helperClass='sortable-container' t={t} onSortStart={(_, event) => event.preventDefault()} onSortEnd={onSortListItem} />
			</Col>
		</Row>
	);
};

function mapStateToProps(state: any) {
	return {
		selectedList: (!isListEmpty(state.list.list) && state.list.list) || null,
	};
}

function mapDispatchToProps(dispatch: Function) {
	return {
		updateListInRedux: (list: ListModel) => dispatch(updateListInRedux(list)),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(LiveBlogListContentSortableContainer);
