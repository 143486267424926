import React from 'react';
import { connect } from 'react-redux';
import Project from '../../../models/project/Project';
import ListingComponent from '../../Partials/BaseComponents/ListingComponent/ListingComponent';
import { Properties, State } from './Properties/AuthorListingProperties';
import {
	AUTHOR_ENTITY_DELETE_SUCCESS,
	returnObjectForAuthorEntityDelete,
	returnObjectForAuthorListingRequest,
	returnObjectForAuthorSearch,
} from '../../../store/action-creators/AuthorActionCreators';
import { ContentTypes } from '../../../constants/content-types';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import ConditionalRenderWrapper from '../../Partials/BaseComponents/ConditionalRenderWraper/ConditionalRenderWrapper';
import { Subscription } from 'rxjs';
import PaginationMeta from '../../../models/pagination/PaginationMeta';
import { returnObjectForVideoPaginationUpdate } from '../../../store/action-creators/VideoActionCreator';
import { returnObjectForGalleryPaginationUpdate } from '../../../store/action-creators/GalleryActionCreator';
import { returnObjectForArticlePaginationUpdate } from '../../../store/action-creators/ArticleActionCreator';
import { returnObjectForImagesPaginationUpdate } from '../../../store/action-creators/ImageActionCreator';
import { returnObjectForContentFiltersUpdate } from '../../../store/action-creators/content-resources';
import { remmapContentFilters } from '../Articles/Helpers/ArticleHelper';
import { returnObjectForWikiPaginationUpdate } from '../../../store/action-creators/WikiActionCreator';
import AdvancedFilterModel from '../../Partials/Shared/advanced-content-filter/models/advanced-content-filter.model';

class AuthorListing extends React.Component<Properties, State> {
	authorsSubscription: Subscription = {} as Subscription;

	constructor(props: Properties) {
		super(props);
		this.state = {
			authorSearchInput: '',
			toggleModal: false,
			currentPage: '1',
		};
		this.registerActionEventListener();
	}

	getAuthorsListener = () => this.props.getAuthors(this.state.currentPage, this.props.currentProject);

	registerActionEventListener() {
		window.addEventListener(AUTHOR_ENTITY_DELETE_SUCCESS, this.getAuthorsListener);
	}

	setCurrentPage(page: string) {
		this.props.analytics.sendPageView(
			this.props.location.pathname,
			`${parseInt(page) > 1 ? 'AuthorListing - Page ' + page : 'AuthorListing'}`,
		);
		this.setState({
			...this.state,
			currentPage: page,
		});
	}

	componentDidMount(): void {
		this.initPageTitle();
		this.props.analytics.sendPageView(this.props.location.pathname, 'AuthorListing');
		this.props.getAuthors(this.state.currentPage, this.props.currentProject);
		this.props.updateArticlesPagination(PaginationMeta.builder().withCurrentPage(1).build());
		this.props.updateVideosPagination(PaginationMeta.builder().withCurrentPage(1).build());
		this.props.updateGalleriesPagination(PaginationMeta.builder().withCurrentPage(1).build());
		this.props.updateImagesPagination(PaginationMeta.builder().withCurrentPage(1).build());
		this.props.updateWikiPagesPagination(PaginationMeta.builder().withCurrentPage(1).build());
		const filters = remmapContentFilters({}, {}, {}, {}, {}, {}, {}, {}, {}, {} as AdvancedFilterModel);
		this.props.updateContentFilters(filters);
	}

	componentWillUnmount() {
		window.removeEventListener(AUTHOR_ENTITY_DELETE_SUCCESS, this.getAuthorsListener);
	}

	initPageTitle() {
		document.title = this.props.t('authors');
	}

	render() {
		return (
			<ConditionalRenderWrapper expectedPermissions={['read_authors', 'write_authors', 'delete_authors']}>
				<ListingComponent
					contentType={ContentTypes.AUTHOR}
					getContent={this.props.getAuthors}
					analytics={this.props.analytics}
					searchContent={this.props.searchAuthors}
					currentProject={this.props.currentProject}
					onPageChange={this.setCurrentPage.bind(this)}
					history={this.props.history}
					onDeleteContent={(id: string) => {
						this.props.deleteAuthor(id, this.props.currentProject);
					}}
					content={this.props.authors}
					contentPagination={this.props.pagination}
				/>
			</ConditionalRenderWrapper>
		);
	}
}

function mapStateToProps(state: any) {
	return {
		profile: state.profile.profile,
		authors: state.author.authorListing,
		authorDeleted: state.author.authorDeleted,
		currentProject: state.project.currentProject,
		pagination: state.author.pagination,
	};
}

function mapDispatchToProps(dispatch: any) {
	return {
		getAuthors: (page: string, project: Project, text: string) => {
			dispatch(returnObjectForAuthorListingRequest(page, project, text));
		},
		searchAuthors: (text: string, project: Project) => dispatch(returnObjectForAuthorSearch(text, project)),
		deleteAuthor: (id: string, project: Project) => dispatch(returnObjectForAuthorEntityDelete(id, project)),
		updateGalleriesPagination: (pagination: PaginationMeta) => dispatch(returnObjectForGalleryPaginationUpdate(pagination)),
		updateVideosPagination: (pagination: PaginationMeta) => dispatch(returnObjectForVideoPaginationUpdate(pagination)),
		updateArticlesPagination: (pagination: PaginationMeta) => dispatch(returnObjectForArticlePaginationUpdate(pagination)),
		updateWikiPagesPagination: (pagination: PaginationMeta) => dispatch(returnObjectForWikiPaginationUpdate(pagination)),
		updateImagesPagination: (pagination: PaginationMeta) => dispatch(returnObjectForImagesPaginationUpdate(pagination)),
		updateContentFilters: (filters: any) => dispatch(returnObjectForContentFiltersUpdate(filters)),
	};
}

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(AuthorListing) as React.ComponentType;
