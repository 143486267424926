import { CategoryJson } from './CategoryJson';
import Category from './Category';
import Seo from '../seo/Seo';
import Urls from '../urls/Urls';
import MainMedia from '../mainmedia/MainMedia';
import { MainMediaJson } from '../mainmedia/MainMediaJson';
import { CategoryRequestJson } from './CategoryRequestJson';

export default class CategoryBuilder {
	private json: CategoryJson;

	constructor(category?: Category | CategoryJson) {
		if (category && category instanceof Category) {
			this.json = category.toJSON();
		} else if (category) {
			this.json = category;
		} else {
			this.json = {} as CategoryJson;
		}
	}

	withId(id: string): CategoryBuilder {
		this.json.id = id;

		return this;
	}

	withTitle(title: string): CategoryBuilder {
		this.json.title = title;

		return this;
	}

	withDescription(description: string): CategoryBuilder {
		this.json.description = description;

		return this;
	}

	withActive(active: boolean): CategoryBuilder {
		this.json.active = active;

		return this;
	}

	withParentId(parentId: string): CategoryBuilder {
		this.json.parentId = parentId;

		return this;
	}

	withGeneric(generic: string): CategoryBuilder {
		this.json.generic = generic;

		return this;
	}

	withSeo(seo: Seo): CategoryBuilder {
		this.json.seo = seo;

		return this;
	}

	withUrls(urls: Urls): CategoryBuilder {
		this.json.urls = urls;

		return this;
	}

	withCreatedAt(createdAt: string): CategoryBuilder {
		this.json.createdAt = createdAt;

		return this;
	}

	withUpdatedAt(updatedAt: string): CategoryBuilder {
		this.json.updatedAt = updatedAt;

		return this;
	}

	withSubIndent(subIndent: string): CategoryBuilder {
		this.json.subIndent = subIndent;

		return this;
	}

	withMainMedia(mainMedia: MainMedia[]): CategoryBuilder {
		this.json.mainMedia = mainMedia;

		return this;
	}

	build(): Category {
		return Category.fromJSON(this.json);
	}

	equals(category: Category): boolean {
		const idEqual = this.json.id === category.id;
		const titleEqual = this.json.title === category.title;
		const parentIdEqual = this.json.parentId === category.parentId;
		const descriptionEqual = this.json.description === category.description;
		const createdAtEqual = this.json.createdAt === category.createdAt;
		const updatedAttEqual = this.json.updatedAt === category.updatedAt;
		//TODO Add a normal MainMediaCheck
		const isMainMediaSet = this.json.mainMedia === category.mainMedia;

		return idEqual && titleEqual && descriptionEqual && parentIdEqual && isMainMediaSet && createdAtEqual && updatedAttEqual;
	}

	toRequestJson(): CategoryRequestJson {
		let json = {} as CategoryRequestJson;
		json.seo = {
			slug: '',
			title: '',
			description: '',
			keywords: [],
			index: false,
			follow: false,
			redirect_type: '',
		};

		json.id = this.json.id;
		json.title = this.json.title;
		json.description = this.json.description;
		json.active = this.json.active;
		json.parent_id = this.json.parentId;
		json.external_url = this.json.urls && this.json.urls.externalUrl ? this.json.urls.externalUrl : '';
		json.canonical_url = this.json.urls && this.json.urls.canonicalUrl ? this.json.urls.canonicalUrl : '';
		json.seo.slug = this.json.seo && this.json.seo.slug ? this.json.seo.slug.toLowerCase() : '';
		json.seo.title = this.json.seo && this.json.seo.title ? this.json.seo.title : '';
		json.seo.description = this.json.seo && this.json.seo.description ? this.json.seo.description : '';
		json.seo.keywords = this.json.seo && this.json.seo.keywords ? this.json.seo.keywords : [];
		json.seo.index = this.json.seo && this.json.seo.index ? this.json.seo.index : false;
		json.seo.follow = this.json.seo && this.json.seo.follow ? this.json.seo.follow : false;
		json.seo.redirect_type = this.json.seo && this.json.seo.redirectType ? this.json.seo.redirectType : '';
		json.main_media = this.json.mainMedia
			? this.json.mainMedia.map((media: MainMediaJson) => {
					return {
						resource_id: media.resourceId,
						resource_type: media.resourceType,
						resource_subtype: media.resourceSubtype,
						provider: media.provider,
						description: media.description,
						data: media.data,
					};
			  })
			: [];

		return json;
	}
}
