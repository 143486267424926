import { MatchJson } from './match.json';
import MatchModel from './match.model';
import EnumItem from '../../../../../models/enum/EnumItem';
import TeamModel from '../../../../Partials/Blocky/partials/team-select/models/team.model';
import BlacklistModel from '../../../../../models/blacklist/blacklist';

export default class MatchBuilder {
	private json: MatchJson;

	constructor(team?: MatchModel | MatchJson) {
		if (team && team instanceof MatchModel) {
			this.json = team.toJSON();
		} else if (team) {
			this.json = team;
		} else {
			this.json = {} as MatchJson;
		}
	}

	withId(id: string): MatchBuilder {
		this.json.id = id;

		return this;
	}

	withType(type: string): MatchBuilder {
		this.json.type = type;

		return this;
	}

	withMinutes(minutes: number): MatchBuilder {
		this.json.minutes = minutes;

		return this;
	}

	withEventStatus(eventStatus: { id: string; name: string; type: string; code: string }): MatchBuilder {
		this.json.eventStatus = eventStatus;

		return this;
	}

	withStartTime(startTime: string): MatchBuilder {
		this.json.startTime = startTime ? startTime : '';

		return this;
	}

	withFinishedAt(finishedAt: string): MatchBuilder {
		this.json.finishedAt = finishedAt ? finishedAt : '';

		return this;
	}

	withHomeTeam(homeTeam: TeamModel | null): MatchBuilder {
		this.json.homeTeam = homeTeam;

		return this;
	}

	withAwayTeam(awayTeam: TeamModel | null): MatchBuilder {
		this.json.awayTeam = awayTeam;

		return this;
	}

	withReferee(referee: EnumItem | null): MatchBuilder {
		this.json.referee = referee;

		return this;
	}

	withVenue(venue: EnumItem | null): MatchBuilder {
		this.json.venue = venue;

		return this;
	}

	withStage(stage: EnumItem | null): MatchBuilder {
		this.json.stage = stage;

		return this;
	}

	withRound(round: string): MatchBuilder {
		this.json.round = round;

		return this;
	}

	withGoalHome(goalHome: number): MatchBuilder {
		this.json.goalHome = goalHome ? goalHome : 0;

		return this;
	}

	withGoalAway(goalAway: number): MatchBuilder {
		this.json.goalAway = goalAway ? goalAway : 0;

		return this;
	}

	withUpdatedAt(updatedAt: string): MatchBuilder {
		this.json.updatedAt = updatedAt;

		return this;
	}

	withLiveUpdates(liveUpdates: boolean): MatchBuilder {
		this.json.liveUpdates = liveUpdates;

		return this;
	}

	withLineupAvailable(lineupAvailable: boolean): MatchBuilder {
		this.json.lineupAvailable = lineupAvailable;

		return this;
	}

	withBlacklist(blacklist: BlacklistModel): MatchBuilder {
		this.json.blacklist = blacklist;

		return this;
	}

	build(): MatchModel {
		return MatchModel.fromJSON(this.json);
	}
}
