import React, { useState } from 'react';
import { ViewTypes } from '../../../constants/general.constants';
import SpotlightAudioEdit from './spotlight-audio/spotlight-audio-edit.component';
import SpotlightAudioView from './spotlight-audio/sporlight-audio-block-view.component';
import SpotlightAudioModel from '../../../../../../models/spotlight-audio/spotlight-audio.model';
import BlockModel from '../../../models/block.model';
import { ContentTypes } from '../../../constants/block.types';
import { Col, Row } from 'reactstrap';
import AudioTypeSelect, { audioTypes } from '../../../partials/audio-type-select/audio-type-select.component';
import BlockUpdateController from '../../block-update-controller.component';
import { compose } from 'redux';
import GBVisionAudioEdit from './gb-vision/gb-vision-audio-edit.component';
import GBVisionAudioView from './gb-vision/gb-vision-audio-block-view.component';
import GBVisionAudioModel from '../../../../../../models/v2/gb-vision-audio/gb-vision-audio-model';
import { addDefaultType } from '../../../partials/audio-type-select/helpers/audio-type-select.helper';

interface Properties {
	t: any;
	view: ViewTypes;
	block: BlockModel;
	blocks: BlockModel[];
	contentData: any;
}

const SpotlightAudioBlock: React.FunctionComponent<Properties> = (props) => {
	const { t, view, block, blocks } = props;

	const [audioBlockType, updateAudioBlockType] = useState<string>(
		addDefaultType(audioTypes, block.data.preview && block.data.preview.type ? block.data.preview.type : ''),
	);

	const onAudioTypeChange = (type: string) => updateAudioBlockType(type);

	return (
		<>
			{view === ViewTypes.edit ? (
				<Row className='mb-3'>
					<Col>
						<AudioTypeSelect onAudioTypeSelect={onAudioTypeChange} t={t} value={audioBlockType} />
					</Col>
				</Row>
			) : null}
			{audioBlockType === ContentTypes.spotlightAudio ? (
				<>
					{view === ViewTypes.edit ? <SpotlightAudioEdit t={t} block={block} blocks={blocks} /> : null}
					{view === ViewTypes.normal ? (
						<SpotlightAudioView t={t} audio={block.data.preview ? block.data.preview.audio : SpotlightAudioModel.builder().build()} />
					) : null}
				</>
			) : audioBlockType === ContentTypes.gbVision ? (
				<>
					{view === ViewTypes.edit ? <GBVisionAudioEdit t={t} block={block} blocks={blocks} inBlocky={true} /> : null}
					{view === ViewTypes.normal ? (
						<GBVisionAudioView t={t} audio={block.data.preview ? block.data.preview.audio : new GBVisionAudioModel()} />
					) : null}
				</>
			) : null}
		</>
	);
};

export default compose(BlockUpdateController)(SpotlightAudioBlock);
