import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Col, Label, Row } from 'reactstrap';
import SeasonModel from '../../../../../../models/v2/Season/Entity/SeasonModel';
import HttpService from '../../../../../../services/rest/HttpService';
import { responseToSeasonModel, seasonsToOptions, seasonToOption } from '../helpers/season-select/season-select-component.helper';
import { customOption } from '../../../partials/shared/custom-select-option';
import ErrorHandler from '../error-handler/error-handler';

type Properties = {
	onSeasonSelect: any;
	selectedSeason: SeasonModel | any;
	tournamentId: string;
	t: any;
	isValid: boolean;
	withLabel?: boolean;
	label?: string;
	lang?: string;
	isRequired?: boolean;
	isClearable?: boolean;
	isBlocky?: boolean;
	isMulti?: boolean;
	preselectSeason?: boolean;
	id: string;
};

const SeasonSelectComponent: React.FunctionComponent<Properties> = (props) => {
	const [seasonsOptions, setSeasonsOptions] = useState<SeasonModel[]>([]);

	useEffect(() => {
		if (props.tournamentId) {
			getSeasons(props.tournamentId);
		}
	}, []);

	useEffect(() => {
		if (props.tournamentId) {
			getSeasons(props.tournamentId);
		}
	}, [props.tournamentId, props.selectedSeason && props.selectedSeason.id]);

	const updateSeasonsOptionsState = (seasons: SeasonModel[]) => {
		const { onSeasonSelect, selectedSeason, isBlocky, isMulti, preselectSeason } = props;
		let preSelectFirstSeasonOption =
			((preselectSeason && !selectedSeason) ||
				(selectedSeason && (selectedSeason.id === undefined || selectedSeason.id < 1) && !isMulti) ||
				(isMulti && selectedSeason.length === 0)) &&
			isBlocky;

		if (preSelectFirstSeasonOption) {
			onSeasonSelect(seasons[0]);
		}

		setSeasonsOptions(seasons);
	};

	const getSeasons = (tournamentId: string) => {
		if (tournamentId) {
			HttpService.getTournamentById(tournamentId, props.lang)
				.then((response: any) => {
					updateSeasonsOptionsState(response.data.seasons.map((item: SeasonModel) => responseToSeasonModel(item)));
				})
				.catch((e: any) => e);
		}
	};

	const { onSeasonSelect, selectedSeason, tournamentId, t, label, withLabel, isRequired } = props;

	if (tournamentId === undefined || tournamentId === null || tournamentId.length < 1) {
		return null;
	}

	const seasonSelected = props.selectedSeason === undefined || props.selectedSeason === null || !props.selectedSeason.id;
	return (
		<Row data-qa={props.id}>
			<Col>
				{withLabel && <Label htmlFor={props.id}>{t('season')}:</Label>}
				{label && <Label htmlFor={props.id}>{t(label)}</Label>}
				{isRequired && <span className='text-danger'>*</span>}
				<Select
					id={props.id}
					menuPortalTarget={document.body}
					menuPosition='absolute'
					isClearable={props.isClearable}
					formatOptionLabel={customOption}
					placeholder={t('select')}
					options={seasonsToOptions(seasonsOptions)}
					value={seasonToOption(selectedSeason)}
					noOptionsMessage={(inputValue) => inputValue && t('no_options')}
					onChange={(selection: any) => (selection ? onSeasonSelect(selection.data) : onSeasonSelect(null))}
					styles={{
						menuPortal: (baseStyles) => ({
							...baseStyles,
							zIndex: 10000,
						}),
					}}
				/>
				{isRequired && <ErrorHandler t={t} arePropsValid={props.isValid} isValueSelected={seasonSelected} errorMessage='field_is_required' />}
			</Col>
		</Row>
	);
};

export default SeasonSelectComponent as React.ComponentType<Properties>;
