import { TeamJson } from './team.json';
import TeamBuilder from './team.builder';
import TournamentModel from '../../../blocks/widgets/player-profile/models/tournament-model/tournament.model';

export default class TeamModel {
	readonly id: string = '';
	readonly name: string = '';
	readonly logo: string = '';
	readonly type: string = '';
	readonly tournament: TournamentModel;
	readonly threeLetterCode: string = '';
	readonly shortName: string = '';

	private constructor(
		id: string,
		name: string,
		logo: string,
		type: string,
		tournament: TournamentModel,
		threeLetterCode: string,
		shortName: string,
	) {
		this.id = id;
		this.name = name;
		this.logo = logo;
		this.type = type;
		this.tournament = tournament;
		this.threeLetterCode = threeLetterCode;
		this.shortName = shortName;
	}

	toJson(): TeamJson {
		return {
			id: this.id,
			name: this.name,
			url_logo: this.logo,
			type: this.type,
			tournament: this.tournament,
			three_letter_code: this.threeLetterCode,
			short_name: this.shortName,
		};
	}

	static fromJson(json: TeamJson): TeamModel {
		return new TeamModel(json.id, json.name, json.url_logo, json.type, json.tournament, json.three_letter_code, json.short_name);
	}
	static builder(team?: TeamModel): TeamBuilder {
		return new TeamBuilder(team);
	}
}
