import ContentAttributes from '../../../../../models/content-attributes/content-attributes-model';
import { GeneralContentModel } from '../models/GeneralContentModel';
import { Properties } from '../properties/GeneralContentProps';

export const extractDefaultValues = (attributes: ContentAttributes[]) => {
	let defaultValue = {} as ContentAttributes;

	if (attributes && attributes.length > 0) {
		defaultValue = attributes.filter((attribute: ContentAttributes) => attribute.isDefault)[0];

		return defaultValue ? defaultValue.slug : '';
	}

	return '';
};

export const extractDefaultContentAttributesValues = (attributes: ContentAttributes[]) => {
	let defaultValue = [] as ContentAttributes[];

	if (attributes && attributes.length > 0) {
		defaultValue = attributes.filter((attribute: ContentAttributes) => attribute.isDefault);

		return defaultValue ? defaultValue : ([] as ContentAttributes[]);
	}

	return [] as ContentAttributes[];
};

export const extractDefaultContentAttributesValue = (attributes: ContentAttributes[]) => {
	let defaultValue = {} as ContentAttributes;

	if (attributes && attributes.length > 0) {
		defaultValue = attributes.filter((attribute: ContentAttributes) => attribute.isDefault)[0];

		return defaultValue ? defaultValue : ({} as ContentAttributes);
	}

	return {} as ContentAttributes;
};

export const setDefaultGeneralContentAttribute = (
	prevProps: Readonly<Properties>,
	currentProps: Readonly<Properties>,
	contentAttribute: string,
	result: GeneralContentModel,
) => {
	// currentProps.value and prevProps.value does not have 'origins' property but instead have 'origin' property
	const contentAttributeForValueObj = contentAttribute === 'origins' ? 'origin' : contentAttribute;

	// if the prevProps for the contentAttribute is empty
	if (!prevProps.value[contentAttributeForValueObj] || prevProps.value[contentAttributeForValueObj].length === 0) {
		// if the currentProps for the contentAttribute is empty
		if (!currentProps.value[contentAttributeForValueObj]) {
			// get the default value dependant on the contentAttribute (authors, origin, regions, channels, policies and etc)
			// and stack it in the result object
			if (contentAttribute === 'authors') {
				const defaultAuthor = currentProps.defaultAuthor;
				const defaultAuthors = defaultAuthor && Object.keys(defaultAuthor).length > 0 && defaultAuthor.id ? [defaultAuthor] : [];
				result[contentAttributeForValueObj] = currentProps.value[contentAttributeForValueObj]
					? currentProps.value[contentAttributeForValueObj]
					: defaultAuthors;
			} else if (contentAttribute === 'origins' || contentAttribute === 'commentsPolicies') {
				const searchedValue =
					contentAttribute === 'commentsPolicies'
						? currentProps[contentAttribute][`${currentProps.contentType}CommentsPolicies`]
						: currentProps[contentAttribute];

				result[contentAttributeForValueObj] = extractDefaultContentAttributesValue(searchedValue);
			} else {
				const searchedValue = currentProps.project[contentAttribute]; // will get the value from the project
				result[contentAttributeForValueObj] = extractDefaultContentAttributesValues(searchedValue);
			}
		}
	}
};
