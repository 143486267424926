import moment from 'moment';
import { teamPlaceholder } from '../../../../../../constants/resource-constants';
import { MatchModel } from '../../../../Sidebar/tags/subcomponents/matches/models/match.model';

export function convertTeamIdsToMatchSearchUrls(teamIds: string[]) {
	let urls = [];

	if (teamIds && teamIds.length > 0) {
		urls.push(`matches?team_ids=${teamIds.join(',')}&status_types=notstarted`);
		urls.push(`matches?team_ids=${teamIds.join(',')}&status_types=finished`);
		urls.push(`matches?team_ids=${teamIds.join(',')}&status_types=inprogress`);
		urls.push(`matches?team_ids=${teamIds.join(',')}&status_types=interrupted`);
	}

	return urls;
}

export function markMatchModelAsSelected(matches: MatchModel[], matchId: string, matchIds: string[], isMulti: boolean) {
	if (matches && !isMulti) {
		matches = matches.map((match: MatchModel) => {
			match.isSelected = match.id === matchId;

			return match;
		});
	}

	if (matches && isMulti) {
		matches = matches.map((match: MatchModel) => {
			const index = matchIds.findIndex((id) => match.id === id);
			match.isSelected = index > -1;

			return match;
		});
	}

	return matches;
}

export function responseToMatchModel(response: any): MatchModel {
	return {
		id: response.id,
		awayTeam: response.away_team.name,
		homeTeam: response.home_team.name,
		goalAway: response.goal_away ? response.goal_away : '0',
		goalHome: response.goal_home ? response.goal_home : '0',
		finishedAt: response.finished_at ? response.finished_at : '',
		startTime: response.start_time ? response.start_time : '',
		type: response.event_status.type,
		code: response.event_status.code,
		data: response,
		isSelected: false,
		minutes: response.minute,
		homeLogo: response.home_team && response.home_team.url_logo ? response.home_team.url_logo : teamPlaceholder,
		awayLogo: response.away_team && response.away_team.url_logo ? response.away_team.url_logo : teamPlaceholder,
	};
}

// In some cases, the match has had postponed and, its date had passed, but it results in upcoming matches response.
// We filter matches with past date from upcoming and in-progress matches response.
export const removePastMatches = (matches: MatchModel[]) => {
	return matches.filter((match: any) => moment().diff(match.startTime, 'days') < 1 && match.code !== 'postponed');
};
