import TeamModel from '../../../../../../../models/v2/Team/Entity/TeamModel';
import SeasonModel from '../../../../../../../models/v2/Season/Entity/SeasonModel';
import TournamentModel from '../../../../../../../models/v2/Tournament/Entity/TournamentModel';
import { FootballTeamFormJson } from './football-team-form.json';
import FootballTeamFormBuilder from './football-team-form.builder';
import { RefreshTimeValuesType } from '../../refresh-time-input/helper';

export default class FootballTeamFormWidgetModel {
	readonly team: TeamModel;
	readonly tournament: TournamentModel;
	readonly season: SeasonModel;
	readonly limit: string = '15';
	readonly title: string;
	readonly refreshTime: RefreshTimeValuesType;

	private constructor(
		team: TeamModel,
		tournament: TournamentModel,
		season: SeasonModel,
		limit: string,
		title: string,
		refreshTime: RefreshTimeValuesType,
	) {
		this.team = team;
		this.tournament = tournament;
		this.season = season;
		this.limit = limit;
		this.title = title;
		this.refreshTime = refreshTime;
	}

	toJson(): FootballTeamFormJson {
		return {
			team: this.team,
			tournament: this.tournament,
			season: this.season,
			limit: this.limit,
			title: this.title,
			refreshTime: this.refreshTime,
		};
	}

	static fromJson(json: FootballTeamFormJson): FootballTeamFormWidgetModel {
		return new FootballTeamFormWidgetModel(json.team, json.tournament, json.season, json.limit, json.title, json.refreshTime);
	}

	static builder(model?: FootballTeamFormWidgetModel): FootballTeamFormBuilder {
		return new FootballTeamFormBuilder(model);
	}
}
