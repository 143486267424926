import PlayerModel from './player.model';
import { PlayerJson } from './player.json';
import { assetsPlaceholder } from '../../../../../../constants/assetsPlaceholder';
import TeamModel from '../../team-select/models/team.model';

export default class PlayerBuilder {
	private json: PlayerJson;

	constructor(bookmaker?: PlayerModel | PlayerJson) {
		if (bookmaker && bookmaker instanceof PlayerModel) {
			this.json = bookmaker.toJson();
		} else if (bookmaker) {
			this.json = bookmaker;
		} else {
			this.json = {} as PlayerJson;
		}
	}

	withId(id: string): PlayerBuilder {
		this.json.id = id;

		return this;
	}

	withName(name: string): PlayerBuilder {
		this.json.name = name;

		return this;
	}

	withLogo(logo: string): PlayerBuilder {
		this.json.url_thumb = logo ? logo : assetsPlaceholder.player;

		return this;
	}

	withHeadshot(headshot: string): PlayerBuilder {
		this.json.headshot = headshot;

		return this;
	}

	withTeam(team: TeamModel): PlayerBuilder {
		this.json.team = team;

		return this;
	}

	withPosition(position: string): PlayerBuilder {
		this.json.position = position;

		return this;
	}

	build(): PlayerModel {
		return PlayerModel.fromJson(this.json);
	}
}
