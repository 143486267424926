import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Col, Label, Row } from 'reactstrap';
import SportsHttpService from '../../../../../services/rest/sports-http-service';
import { customOption } from '../shared/custom-select-option';
import ErrorHandler from '../error/error-handler-component';
import { responseToSeasonModel, responseToStagesModel, seasonsToOptions, seasonToOption } from './helpers/tennis-season-select.helper';
import TennisPlayoffModel from '../../v2/widgets-v2/tennis-playoff/models/tennis-playoff.model';
import TennisCompetitionSeasonModel from '../../../../../models/v2/tennis-competition-season/entity/tennis-competition-season.model';
import TennisSeasonStagesModel from '../../../../../models/v2/tennis-competition-season/entity/tennis-season-stages.model';
import TennisSeasonSelectModel from './models/tennis-season-select.model';
import { useTranslation } from 'react-i18next';

type Properties = {
	onSeasonAndStagesSelect: (season: TennisCompetitionSeasonModel, stages: TennisSeasonStagesModel[]) => any;
	blockData: TennisPlayoffModel;
	selectedSeason: TennisCompetitionSeasonModel;
	isValid: boolean;
	isClearable?: boolean;
	isRequired?: boolean;
	isBlocky: boolean;
	language: string;
	withLabel?: boolean;
	label?: string;
	sport: string;
	id: string;
};

const TennisCompetitionSeasonSelectComponent: React.FunctionComponent<Properties> = (props) => {
	const [seasonsOptions, setSeasonsOptions] = useState<TennisCompetitionSeasonModel[]>([]);
	const [seasonStages, setSeasonStages] = useState<TennisSeasonStagesModel[]>([]);
	const [t] = useTranslation();

	useEffect(() => {
		if (props.blockData.competition && props.blockData.competition.id) {
			getSeasons(props.sport, props.blockData.competition.id, 'CURRENT', props.language);
		}

		if (!props.blockData.competition) {
			setSeasonsOptions([]);
		}
	}, [props.blockData.competition && props.blockData.competition.id]);

	const updateSeasonsOptionsState = (seasons: TennisCompetitionSeasonModel[]) => {
		setSeasonsOptions(seasons);
	};

	const getSeasons = (sport: string, competitionId: string, season: string, lang: string) => {
		SportsHttpService.getCompetitionSeasons(sport, competitionId, season, lang)
			.then((response: any) => {
				updateSeasonsOptionsState(
					response.data.available_season_years.map((item: TennisCompetitionSeasonModel) => responseToSeasonModel(item)),
				);
				setSeasonStages(response.data.tournaments.map((item: TennisSeasonStagesModel) => responseToStagesModel(item)));
			})
			.catch((e: any) => e);
	};

	return (
		<Row data-id={props.id}>
			<Col>
				<Label htmlFor={props.id}>{t('season')}</Label>
				{props.isRequired && <span className='text-danger'>*</span>}
				<Select
					id={props.id}
					menuPortalTarget={document.body}
					menuPosition='absolute'
					className='w-100'
					placeholder={t('select')}
					formatOptionLabel={customOption}
					options={seasonsToOptions(seasonsOptions)}
					value={props.selectedSeason && props.selectedSeason.value ? seasonToOption(props.selectedSeason) : []}
					noOptionsMessage={(inputValue) => inputValue && t('no_options')}
					isClearable={props.isClearable}
					onChange={(selection: TennisSeasonSelectModel) => {
						if (selection && selection.data !== null) {
							props.onSeasonAndStagesSelect(selection.data, seasonStages);
							getSeasons(props.sport, props.blockData.competition.id, selection.data.value, props.language);
						} else {
							props.onSeasonAndStagesSelect({} as TennisCompetitionSeasonModel, []);
						}
					}}
				/>
				{!props.isValid && !props.selectedSeason && <ErrorHandler t={t} errorMessage='field_is_required' />}
			</Col>
		</Row>
	);
};

export default TennisCompetitionSeasonSelectComponent as React.ComponentType<Properties>;
