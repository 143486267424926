import React from 'react';
import Project from '../../../models/project/Project';
import { Properties, State } from './properties/feed-listing.properties';
import ListingComponent from '../../Partials/BaseComponents/ListingComponent/ListingComponent';
import { withTranslation } from 'react-i18next';
import { ContentTypes } from '../../../constants/content-types';
import { AppState } from '../../../store/store';
import { connect } from '../../../store/store.connect';
import ConditionalRenderWrapper from '../../Partials/BaseComponents/ConditionalRenderWraper/ConditionalRenderWrapper';
import {
	returnObjectForFeedEntityDelete,
	returnObjectForFeedsListingRequest,
	returnObjectForUpdateNotifications,
	FEED_ENTITY_DELETE_SUCCESS,
} from '../../../store/action-creators/feeds-action-creator';
import PaginationMeta from '../../../models/pagination/PaginationMeta';
import { returnObjectForArticlePaginationUpdate } from '../../../store/action-creators/ArticleActionCreator';
import { returnObjectForVideoPaginationUpdate } from '../../../store/action-creators/VideoActionCreator';
import { returnObjectForGalleryPaginationUpdate } from '../../../store/action-creators/GalleryActionCreator';
import { returnObjectForImagesPaginationUpdate } from '../../../store/action-creators/ImageActionCreator';
import { remmapContentFilters } from '../Articles/Helpers/ArticleHelper';
import { returnObjectForContentFiltersUpdate } from '../../../store/action-creators/content-resources';
import AdvancedFilterModel from '../../Partials/Shared/advanced-content-filter/models/advanced-content-filter.model';

@connect<Properties, AppState>(
	(state) => ({
		currentProject: state.project.currentProject,
		feeds: state.feed.feeds,
		feed: state.feed.feed,
		selectedFeed: state.feed.selectedFeed,
		pagination: state.feed.pagination,
		profileId: state.profile.profile.id,
	}),
	(dispatch) => ({
		dispatch: (action: any) => dispatch(action),
		getFeeds: (page: string, project: Project, profileId: string) => dispatch(returnObjectForFeedsListingRequest(page, project, profileId)),
		deleteFeed: (id: string, project: Project, profileId: string) => dispatch(returnObjectForFeedEntityDelete(id, project, profileId)),
		updateNotifications: (notifications: any[]) => dispatch(returnObjectForUpdateNotifications(notifications)),
		updateGalleriesPagination: (pagination: PaginationMeta) => dispatch(returnObjectForGalleryPaginationUpdate(pagination)),
		updateVideosPagination: (pagination: PaginationMeta) => dispatch(returnObjectForVideoPaginationUpdate(pagination)),
		updateArticlesPagination: (pagination: PaginationMeta) => dispatch(returnObjectForArticlePaginationUpdate(pagination)),
		updateImagesPagination: (pagination: PaginationMeta) => dispatch(returnObjectForImagesPaginationUpdate(pagination)),
		updateContentFilters: (filters: any) => dispatch(returnObjectForContentFiltersUpdate(filters)),
	}),
)
class FeedListing extends React.Component<Properties, State> {
	constructor(props: any) {
		super(props);
		this.state = {
			currentPage: '1',
			feed: props.feed,
		};
	}

	componentDidMount(): void {
		this.initPageTitle();
		this.registerActionEventListener();
		this.props.getFeeds(this.state.currentPage, this.props.currentProject, this.props.profileId);
		this.props.updateArticlesPagination(PaginationMeta.builder().withCurrentPage(1).build());
		this.props.updateVideosPagination(PaginationMeta.builder().withCurrentPage(1).build());
		this.props.updateGalleriesPagination(PaginationMeta.builder().withCurrentPage(1).build());
		this.props.updateImagesPagination(PaginationMeta.builder().withCurrentPage(1).build());
		const filters = remmapContentFilters({}, {}, {}, {}, {}, {}, {}, {}, {}, {} as AdvancedFilterModel);
		this.props.updateContentFilters(filters);
	}

	componentWillUnmount() {
		window.removeEventListener(FEED_ENTITY_DELETE_SUCCESS, this.getFeedListener);
	}

	getFeedListener = () => this.props.getFeeds(this.state.currentPage, this.props.currentProject, this.props.profileId);

	registerActionEventListener() {
		window.addEventListener(FEED_ENTITY_DELETE_SUCCESS, this.getFeedListener);
	}

	initPageTitle() {
		document.title = this.props.t('feeds');
	}

	setCurrentPage = (page: string) => {
		this.props.analytics.sendPageView(this.props.location.pathname, `${parseInt(page) > 1 ? 'FeedListting - Page ' + page : 'FeedListing'}`);
		this.setState({
			...this.state,
			currentPage: page,
		});
	};

	onDeleteContent = (id: string) => {
		if (this.props.selectedFeed.id === id) {
			this.props.updateNotifications([]);
		}
		this.props.deleteFeed(id, this.props.currentProject, this.props.profileId);
	};

	render() {
		return (
			<ConditionalRenderWrapper expectedPermissions={['read_feeds', 'write_feeds', 'delete_feeds']}>
				<ListingComponent
					contentType={ContentTypes.FEED}
					getContent={this.props.getFeeds}
					analytics={this.props.analytics}
					currentProject={this.props.currentProject}
					history={this.props.history}
					onPageChange={this.setCurrentPage}
					content={this.props.feeds}
					showSearchBar={false}
					onDeleteContent={(id: string) => {
						this.onDeleteContent(id);
					}}
					contentPagination={this.props.pagination}
				/>
			</ConditionalRenderWrapper>
		);
	}
}

export default withTranslation()(FeedListing) as React.ComponentType<any>;
