import Global from '../../helpers/global.helpers';

export const createEmptyContentBlock = (type: string) => {
	const emptyBlock: any = {};
	emptyBlock.type = type;
	emptyBlock.data = {};
	emptyBlock.data.id = '';
	emptyBlock.id = Global.makeId(6);
	emptyBlock.data.widget_type = type;

	return emptyBlock;
};
