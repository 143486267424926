import React, { useEffect, useState } from 'react';
import InstatHttpService from '../../../../../../../../services/rest/instat/InstatHttpService';
import Select from 'react-select';
import moment from 'moment';

type Properties = {
	value: any;
	tournamentId: string;
	seasonId: string;
	onSelect: (value: any | null) => void;
};

const selectedMatchToOption = (value: any) => {
	return value
		? {
				value: value.id,
				label: `${moment(value.match_date).format('DD MMM YYYY')} - ${value.match_name}`,
				match_name: value.match_name,
				match_date: value.match_date,
				tournament_id: value.tournament_id,
				tournament_name: value.tournament_name,
				season_id: value.season_id,
				season_name: value.season_name,
				round_id: value.round_id,
				round_name: value.round_name,
				team1_id: value.team1_id,
				team1_name: value.team1_name,
				team2_id: value.team2_id,
				team2_name: value.team2_name,
				team1_score: value.team1_score,
				team2_score: value.team2_score,
				status_id: value.status_id,
				status_name: value.status_name,
				referee_id: value.referee_id,
				referee_name: value.referee_name,
				stadium_id: value.stadium_id,
				stadium_name: value.stadium_name,
				duration: value.duration,
				available_events: value.available_events,
				calc_ts_first: value.calc_ts_first,
				ts: value.ts,
				final_score: value.team1_score + ':' + value.team2_score,
				team1_logo: `https://instatscout.com/images/teams/180/${value.team1_id}.png`,
				team2_logo: `https://instatscout.com/images/teams/180/${value.team2_id}.png`,
		  }
		: null;
};

const optionToModel = (option: any) => {
	return {
		id: option.value,
		match_name: option.match_name,
		match_date: option.match_date,
		tournament_id: option.tournament_id,
		tournament_name: option.tournament_name,
		season_id: option.season_id,
		season_name: option.season_name,
		round_id: option.round_id,
		round_name: option.round_name,
		team1_id: option.team1_id,
		team1_name: option.team1_name,
		team2_id: option.team2_id,
		team2_name: option.team2_name,
		team1_score: option.team1_score,
		team2_score: option.team2_score,
		status_id: option.status_id,
		status_name: option.status_name,
		referee_id: option.referee_id,
		referee_name: option.referee_name,
		stadium_id: option.stadium_id,
		stadium_name: option.stadium_name,
		duration: option.duration,
		available_events: option.available_events,
		calc_ts_first: option.calc_ts_first,
		ts: option.ts,
		final_score: option.team1_score + ':' + option.team2_score,
		team1_logo: `https://instatscout.com/images/teams/180/${option.team1_id}.png`,
		team2_logo: `https://instatscout.com/images/teams/180/${option.team2_id}.png`,
	};
};

const MatchSelect: React.FunctionComponent<Properties> = (props) => {
	const [matchesOptions, setTeamsOptions] = useState([]);
	const [initialLoad, setInitialLoad] = useState(true);

	useEffect(() => {
		if (initialLoad) {
			const http = new InstatHttpService();
			http
				.getMatchesBySeason(props.tournamentId, props.seasonId)
				.then((matches) => {
					const options = matches.map((match: any) => selectedMatchToOption(match));
					setInitialLoad(false);
					setTeamsOptions(options);
				})
				.catch(() => {
					setInitialLoad(false);
					setTeamsOptions([]);
				});
		}
	});

	useEffect(() => {
		const http = new InstatHttpService();
		http
			.getMatchesBySeason(props.tournamentId, props.seasonId)
			.then((matches) => {
				const options = matches.map((match: any) => selectedMatchToOption(match));
				setTeamsOptions(options);
			})
			.catch(() => {
				setInitialLoad(false);
				setTeamsOptions([]);
			});
	}, [props.seasonId]);

	return (
		<Select
			value={selectedMatchToOption(props.value)}
			options={matchesOptions}
			isClearable
			onChange={(selection: any) => {
				props.onSelect(selection ? optionToModel(selection) : null);
			}}
		/>
	);
};

export default MatchSelect;
