import TeamPlayersWidgetModel from './team-players-widget.model';
import { TeamPlayersWidgetJson } from './team-players-widget.json';
import TeamModel from '../../../../partials/team-select/models/team.model';
import TeamPlayerModel from '../../../../partials/team-players-select/models/team-player.model';

export default class TeamPlayersWidgetBuilder {
	private json: TeamPlayersWidgetJson;

	constructor(teamPlayers?: TeamPlayersWidgetModel | TeamPlayersWidgetJson) {
		if (teamPlayers && teamPlayers instanceof TeamPlayersWidgetModel) {
			this.json = teamPlayers.toJson();
		} else if (teamPlayers) {
			this.json = teamPlayers;
		} else {
			this.json = {} as TeamPlayersWidgetJson;
		}
	}

	withTeam(team: TeamModel): TeamPlayersWidgetBuilder {
		this.json.team = team;

		return this;
	}

	withPlayers(players: any): TeamPlayersWidgetBuilder {
		this.json.players = players;

		return this;
	}

	withInjuredPlayers(players: TeamPlayerModel[]): TeamPlayersWidgetBuilder {
		this.json.injuredPlayers = players;

		return this;
	}

	withSuspendedPlayers(players: TeamPlayerModel[]): TeamPlayersWidgetBuilder {
		this.json.suspendedPlayers = players;

		return this;
	}

	withDisplayGoalkeepers(displayKeeper: boolean): TeamPlayersWidgetBuilder {
		this.json.displayKeeper = displayKeeper;

		return this;
	}

	withDisplayDefenders(displayDefender: boolean): TeamPlayersWidgetBuilder {
		this.json.displayDefender = displayDefender;

		return this;
	}

	withDisplayMidfielders(displayMidfielder: boolean): TeamPlayersWidgetBuilder {
		this.json.displayMidfielder = displayMidfielder;

		return this;
	}

	withDisplayForwards(displayForward: boolean): TeamPlayersWidgetBuilder {
		this.json.displayForward = displayForward;

		return this;
	}

	withDisplayUnknown(displayUnknown: boolean): TeamPlayersWidgetBuilder {
		this.json.displayUnknown = displayUnknown;

		return this;
	}

	build(): TeamPlayersWidgetModel {
		return TeamPlayersWidgetModel.fromJson(this.json);
	}
}
