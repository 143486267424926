import ErrorModel from './ErrorModel';
import { ErrorJson } from './ErrorJson';

export default class ErrorBuilder {
	private json: ErrorJson;

	constructor(error?: ErrorModel) {
		this.json = error ? error.toJSON() : <ErrorJson>{};
	}

	withError(error: string): ErrorBuilder {
		this.json.error = error;

		return this;
	}

	withCode(code: string): ErrorBuilder {
		this.json.code = code;

		return this;
	}

	withType(type: string): ErrorBuilder {
		this.json.type = type;

		return this;
	}

	build(): ErrorModel {
		return ErrorModel.fromJSON(this.json);
	}
}
