import Profile from '../../models/profile/Profile';
import ErrorResponseModel from '../../models/error-response/ErrorResponseModel';

export const USER_LOGOUT = '[ACTION] USER_LOGOUT';

export const LANGUAGE_UPDATE = '[ACTION] LANGUAGE_UPDATE';
export const LANGUAGE_UPDATE_SUCCESS = '[ACTION] LANGUAGE_UPDATE_SUCCESS';

export const PROFILE_UPDATE = '[ACTION] PROFILE_UPDATE';
export const PROFILE_UPDATE_SUCCESS = '[ACTION] PROFILE_UPDATE_SUCCESS';
export const PROFILE_UPDATE_FAILED = '[ACTION] PROFILE_UPDATE_FAILED';

export const PROFILE_PASSWORD_UPDATE = '[ACTION] PROFILE_PASSWORD_UPDATE';
export const PROFILE_PASSWORD_UPDATE_SUCCESS = '[ACTION] PROFILE_PASSWORD_UPDATE_SUCCESS';
export const PROFILE_PASSWORD_UPDATE_FAILED = '[ACTION] PROFILE_PASSWORD_UPDATE_FAILED';

export const PROFILE_REQUEST = '[ACTION] PROFILE_REQUEST';
export const PROFILE_RECEIVED = '[ACTION] PROFILE_RECEIVED';
export const PROFILE_FAILED = '[ACTION] PROFILE_FAILED';

export function returnObjectForProfileRequest() {
	return {
		type: PROFILE_REQUEST,
	};
}

export function returnObjectForProfileReceived(profile: any, permissions: any[]) {
	return {
		type: PROFILE_RECEIVED,
		payload: profile,
		permissions,
	};
}

export function returnObjectForProfileFailed(error: any) {
	return {
		type: PROFILE_FAILED,
		payload: ErrorResponseModel.builder().fromResponse(error).build(),
	};
}

export function returnObjectForProfileUpdate(profile: Profile) {
	return {
		type: PROFILE_UPDATE,
		payload: { profile },
	};
}

export function returnObjectForProfilePasswordUpdate(profile: Profile, newPassword: string) {
	return {
		type: PROFILE_PASSWORD_UPDATE,
		payload: { profile, password: newPassword },
	};
}

export function logout() {
	return {
		type: USER_LOGOUT,
	};
}
