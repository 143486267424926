import { TeamProfileWidgetJson } from './team-profile-widget.json';
import TeamProfileWidgetBuilder from './team-profile-widget.builder';
import TeamModel from '../../../../partials/team-select/models/team.model';
import SeasonModel from '../../fixtures/models/season/season.model';

export default class TeamProfileWidgetModel {
	readonly team: TeamModel;
	readonly season: SeasonModel;
	readonly displayStatistics: boolean = true;
	readonly displayOdds: boolean = true;
	readonly noData: boolean;
	readonly threeLetterCodeType: any = '';

	private constructor(
		team: TeamModel,
		season: SeasonModel,
		displayStatistics: boolean,
		displayOdds: boolean,
		noData: boolean,
		threeLetterCodeType: any,
	) {
		this.team = team;
		this.season = season;
		this.displayStatistics = displayStatistics;
		this.displayOdds = displayOdds;
		this.noData = noData;
		this.threeLetterCodeType = threeLetterCodeType;
	}

	toJson(): TeamProfileWidgetJson {
		return {
			team: this.team,
			season: this.season,
			displayStatistics: this.displayStatistics,
			displayOdds: this.displayOdds,
			noData: this.noData,
			threeLetterCodeType: this.threeLetterCodeType,
		};
	}

	static fromJson(json: TeamProfileWidgetJson): TeamProfileWidgetModel {
		return new TeamProfileWidgetModel(
			json.team,
			json.season,
			json.displayStatistics,
			json.displayOdds,
			json.noData,
			json.threeLetterCodeType,
		);
	}

	static builder(team?: TeamProfileWidgetModel): TeamProfileWidgetBuilder {
		return new TeamProfileWidgetBuilder(team);
	}
}
