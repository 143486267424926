export default class EntityLinkingServiceHelper {
	removeEntitiesWithNotFullName = (entities) => {
		const nameKeysToRemove = Object.keys(entities).filter((key) => {
			const searchedName = `${key}`;
			const fullName = entities[key] && entities[key][0] ? entities[key][0].name : '';
			return !this.containsPartOfName(fullName, searchedName) && fullName !== searchedName;
		});

		nameKeysToRemove.forEach((nameKey) => {
			delete entities[nameKey];
		});

		return entities;
	};

	containsPartOfName = (name, namePart) => {
		const nameBreakdown = name.split(' ');

		if (nameBreakdown && nameBreakdown.length > 0) {
			return nameBreakdown.includes(namePart);
		} else {
			return nameBreakdown && nameBreakdown === namePart;
		}
	};
}
