import React from 'react';
import { Properties } from './properties/link-block-view.properties';
import { LINK_TYPES, openTypes } from '../../../constants/link.constants';

export const LinkView: React.FunctionComponent<Properties> = ({ preview, t }) => {
	return (
		<div className='row pl-3 pr-3'>
			<div className='col-12 col-sm-12 col-md-10'>
				<h4 className='mb-2'>
					<i className='fa fa-link mr-2' />
					<a href={preview.link} target={'_blank'}>
						{preview.text}
					</a>
				</h4>
				<p className='text-muted mb-2'>
					<small>
						{t('opens_in')}: <strong>{preview.open_type === openTypes.newWindow ? t('new_window') : t('same_window')}</strong>
					</small>
				</p>
				<p className='text-muted mb-2'>
					<small>
						{t('link_type')}: <strong>{t(LINK_TYPES[preview.linkType])}</strong>
					</small>
				</p>
			</div>
		</div>
	);
};
