import 'reflect-metadata';
import { jsonArrayMember, jsonMember, jsonObject } from 'typedjson';
import SeoModel from '../seo/seo.model';
import AuthorSocialModel from './social.model';

@jsonObject
export default class AuthorModel {
	@jsonMember(String)
	public id: string | null = null;
	@jsonMember(String)
	public name: string | null = null;
	@jsonMember(String)
	public bio: string | null = null;
	@jsonMember(Boolean)
	public active: boolean | null = null;
	@jsonMember(Boolean)
	public is_default: boolean | null = null;
	@jsonMember(String)
	public avatarImage: string | null = null;
	@jsonMember(String, { name: 'avatar_url' })
	public avatarUrl: string | null = null;
	@jsonMember(String)
	public generic: string | null = null;
	@jsonMember(String)
	public created_at: string | null = null;
	@jsonMember(String)
	public updated_at: string | null = null;
	@jsonMember(SeoModel)
	public seo: SeoModel | null = null;
	@jsonArrayMember(AuthorSocialModel)
	public social_media_links: AuthorSocialModel[] = [];
}
