import { EnumItemJson } from './EnumItemJson';
import EnumItemBuilder from './EnumItemBuilder';

export default class EnumItem {
	readonly id: string = '';
	readonly name: string = '';

	private constructor(id: string, name: string) {
		this.id = id;
		this.name = name;
	}

	toJSON(): EnumItemJson {
		return {
			id: this.id,
			name: this.name,
		};
	}

	static fromJSON(json: EnumItemJson) {
		return new EnumItem(json.id, json.name);
	}

	static builder(enumItem?: EnumItem): EnumItemBuilder {
		return new EnumItemBuilder(enumItem);
	}
}
