import React from 'react';
import * as helpers from '../helpers/embed-social-view.helper';
// @ts-ignore
import { Youtube, Vimeo } from 'reactjs-social-embed';
import { Col, Row, Button } from 'reactstrap';
import { noPreviewImage } from '..//../../../../../../constants/resource-constants';

import '../styles/embed-block.scss';

type Properties = {
	embed: string;
	t: any;
};

const EmbedVideoView: React.FunctionComponent<Properties> = ({ embed, t }) => {
	const socialVideoType = helpers.extractVideoType(embed);
	const previewRef = React.useRef(null);
	const [preview, updatePreview] = React.useState([]);
	const [collapse, setCollapse] = React.useState(false);
	const [previewHeight, updatePreviewHeight] = React.useState(preview && preview.length > 0 ? 540 : 0);

	React.useEffect(() => {
		getPreview();
		if (previewRef !== null && previewRef.current !== null) {
			// @ts-ignore
			updatePreviewHeight(previewRef.current.clientHeight);
		}
	}, []);

	const toggle = () => setCollapse(!collapse);

	const getPreview = () => {
		socialVideoType.map((embedCode: any) => {
			if (embedCode) {
				switch (embedCode.type) {
					case 'youtube':
						const youtube: any = (
							<Youtube
								key={Math.random()}
								width='100%'
								maxWidth='500px'
								parentClass='p-container'
								iframeClass='v-container'
								id={embedCode.attributes}
							/>
						);
						updatePreview(youtube);
						updatePreviewHeight(youtube);
						break;

					case 'vimeo':
						const vimeo: any = <Vimeo width='100%' id={embedCode.attributes} />;
						updatePreview(vimeo);
						updatePreviewHeight(vimeo);
						break;
					default:
						break;
				}
			}
		});
	};

	return (
		<Col>
			{previewHeight !== 0 ? (
				<>
					{!collapse ? (
						<Row>
							<Col className='px-4 embed-code-preview-short'>{preview}</Col>
						</Row>
					) : (
						<Row>
							<Col className='px-4 embed-code-preview'>{preview}</Col>
						</Row>
					)}

					<Row className='px-4'>
						{!collapse ? (
							<Button color='info' className='mt-4' onClick={toggle}>
								<i className='fa fa-angle-double-down mr-1'></i>
								{t('social_preview_show')}
							</Button>
						) : (
							<Button color='info' onClick={toggle}>
								<i className='fa fa-angle-double-up mr-1'></i>
								{t('social_preview_hide')}
							</Button>
						)}
					</Row>
				</>
			) : (
				<>
					<div className='block-content d-flex content-container'>
						<img className='content-image img-fluid mb-2 mb-md-0 rounded w-100' src={noPreviewImage} />
					</div>
				</>
			)}
		</Col>
	);
};

export default EmbedVideoView;
