import React from 'react';
import i18next from 'i18next';
import { Col, Row } from 'reactstrap';
import { SelectMenuOptionType } from '../../../../../../models/v2/general/select.model';

export const BASKETBALL_STANDINGS_HEADER_DEFAULT_OPTIONS = ['DIVISION', 'CONFERENCE', 'OVERALL'];
export const BASKETBALL_STANDINGS_HEADER_DEFAULT_OPTION = BASKETBALL_STANDINGS_HEADER_DEFAULT_OPTIONS[1];

export const getSelectedOption = (value: string, options: string[]): SelectMenuOptionType | null => {
	const match = options.find((option) => option === value);

	if (match) {
		return {
			value: match,
			label: i18next.t(match),
			data: match,
		};
	}

	return null;
};

export const onSelectionChangeFromMainComponent = (
	selectionFromMenu: SelectMenuOptionType,
	blockyModel: any,
	widgetModelContent: any,
	updateWidgetModelStateFunc: Function,
) => {
	const selectedValue = selectionFromMenu ? selectionFromMenu.value : null;
	const updatedModel = blockyModel.builder(widgetModelContent).withHeaderDefaultOption(selectedValue).build();
	updateWidgetModelStateFunc(updatedModel);
};

export const generateHeaderDefaultOptionViewText = (value: string): JSX.Element | null => {
	if (value) {
		return (
			<Row className='pb-2 pl-4 pr-4' data-qa='refresh-time-view-row'>
				<Col className='text-center'>
					<Row>
						<Col className='text-left'>
							<strong>{i18next.t('header_default_option')}: </strong>
							<span>{i18next.t(value)}</span>
						</Col>
					</Row>
				</Col>
			</Row>
		);
	}
	return null;
};
