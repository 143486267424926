import ErrorResponseModel from '../../models/error-response/ErrorResponseModel';
import Project from '../../models/project/Project';
import List from '../../models/list/List';
import ListModel from '../../models/v2/list/list';
import ListItemModel from '../../models/v2/list/list-item';

export const LIST_LISTING_REQUEST = '[ACTION] LIST_LISTING_REQUEST';
export const LIST_LISTING_UPDATE = '[ACTION] LIST_LISTING_UPDATE';
export const LIST_LISTING_RECEIVED = '[ACTION] LIST_LISTING_RECEIVED';
export const LIST_LISTING_FAILED = '[ACTION] LIST_LISTING_FAILED';

export const LIST_SEARCH = '[ACTION] LIST_SEARCH';
export const LIST_SEARCH_FAILED = '[ACTION] LIST_SEARCH_FAILED';

export const LIST_ENTITY_DELETE = '[ACTION] LIST_ENTITY_DELETE';
export const LIST_ENTITY_DELETE_SUCCESS = '[ACTION] LIST_ENTITY_DELETE_SUCCESS';
export const LIST_ENTITY_DELETE_FAILED = '[ACTION] LIST_ENTITY_DELETE_FAILED';

export const LIST_ENTITY_CREATE = '[ACTION] LIST_ENTITY_CREATE';
export const LIST_ENTITY_CREATE_SUCCESS = '[ACTION] LIST_ENTITY_CREATE_SUCCESS';
export const LIST_ENTITY_CREATE_FAILED = '[ACTION] LIST_ENTITY_CREATE_FAILED';

export const LIST_ENTITY_UPDATE = '[ACTION] LIST_ENTITY_UPDATE';
export const LIST_ENTITY_UPDATE_FAILED = '[ACTION] LIST_ENTITY_UPDATE_FAILED';
export const LIST_ENTITY_UPDATE_SUCCESS = '[ACTION] LIST_ENTITY_UPDATE_SUCCESS';

export const LIST_ENTITY_REQUEST = '[ACTION] LIST_ENTITY_REQUEST';
export const LIST_ENTITY_RECEIVED = '[ACTION] LIST_ENTITY_RECEIVED';
export const LIST_ENTITY_REQUEST_FAILED = '[ACTION] LIST_ENTITY_REQUEST_FAILED';

export const CONTENT_CREATE_LIST_TO_SAVE = '[ACTION] CONTENT_CREATE_LIST_TO_SAVE';

export const DELETE_LIST_ITEM_REQUEST = '[ACTION] DELETE_LIST_ITEM_REQUEST';

export const LIST_ITEMS_CONTENT_STATISTICS_RECEIVED = '[ACTION] LIST_ITEMS_CONTENT_STATISTICS_RECEIVED';
export const LIST_ENTITY_UPDATE_DIRECTLY = '[ACTION] LIST_ENTITY_UPDATE_DIRECTLY';

export function returnObjectForListListingReceived(lists: any) {
	return {
		type: LIST_LISTING_RECEIVED,
		payload: lists,
	};
}

export function returnObjectForListListingFailed(error: any) {
	return {
		type: LIST_LISTING_FAILED,
		payload: ErrorResponseModel.builder().fromResponse(error).build(),
	};
}

export function returnObjectForListListingRequest(page: string, project: Project) {
	return {
		type: LIST_LISTING_REQUEST,
		payload: { page, project },
	};
}

export function returnObjectForListListingUpdate(lists: List[]) {
	return {
		type: LIST_LISTING_UPDATE,
		payload: lists,
	};
}

export function returnObjectForListEntityCreate(list: List, project: Project) {
	return {
		type: LIST_ENTITY_CREATE,
		payload: { list, project },
	};
}

export function returnObjectForListEntityCreateSuccess(id: string) {
	return {
		type: LIST_ENTITY_CREATE_SUCCESS,
		payload: id,
	};
}

export type ListItemsWithId = {
	id: string;
	items: ListItemModel[];
};

export function returnObjectForListEntityUpdate(list: List | ListModel | ListItemsWithId, project: Project, areOnlyItemsUpdate = false) {
	return {
		type: LIST_ENTITY_UPDATE,
		payload: { list, project, areOnlyItemsUpdate },
	};
}

export function returnObjectForListEntityUpdateFailed(errorMessage?: string) {
	return {
		type: LIST_ENTITY_UPDATE_FAILED,
		message: errorMessage,
	};
}

export function returnObjectForListEntityUpdateSuccess() {
	return {
		type: LIST_ENTITY_UPDATE_SUCCESS,
	};
}

export function returnObjectForListEntityCreateFailed() {
	return {
		type: LIST_ENTITY_CREATE_FAILED,
	};
}

export function returnObjectForListEntityRequest(id: string, project: Project) {
	return {
		type: LIST_ENTITY_REQUEST,
		payload: { id, project },
	};
}

export function returnObjectForListEntityReceived(list: any) {
	return {
		type: LIST_ENTITY_RECEIVED,
		payload: list,
	};
}

export function returnObjectForListSearch(text: string, project: Project) {
	return {
		type: LIST_SEARCH,
		payload: { text, project },
	};
}

export function returnObjectForListSearchFailed(error: any) {
	return {
		type: LIST_SEARCH_FAILED,
		payload: ErrorResponseModel.builder().fromResponse(error).build(),
	};
}

export function returnObjectForListEntityDelete(id: string, project: Project) {
	return {
		type: LIST_ENTITY_DELETE,
		payload: { id, project },
	};
}

export function returnObjectForListEntityDeleteSuccess() {
	return {
		type: LIST_ENTITY_DELETE_SUCCESS,
	};
}

export function returnObjectForListEntityDeleteFailed() {
	return {
		type: LIST_ENTITY_DELETE_FAILED,
	};
}

export function contentCreateList(list: List | null) {
	return {
		type: CONTENT_CREATE_LIST_TO_SAVE,
		payload: list,
	};
}

export function returnObjectForListItemsDeleteRequest() {
	return {
		type: DELETE_LIST_ITEM_REQUEST,
	};
}

export function returnObjectForListItemsContentStatisticsReceived(list: any, contentStatistics: any) {
	return {
		type: LIST_ITEMS_CONTENT_STATISTICS_RECEIVED,
		payload: { list, contentStatistics },
	};
}

export function updateListInRedux(list: ListModel | null) {
	return {
		type: LIST_ENTITY_UPDATE_DIRECTLY,
		payload: list,
	};
}
