import Permission from '../../../../../models/permission/Permission';
import Article from '../../../../../models/article/Article';

export enum PermissionTypes {
	READ = 'read',
	WRITE = 'write',
	DELETE = 'delete',
}

export function isPermitted(expectedPermissionList: string[], profilePermissions: Permission[], permissionType: PermissionTypes): boolean {
	if (hasAllPermissions(profilePermissions)) {
		return true;
	}

	if (
		(expectedPermissionList && expectedPermissionList.includes('read_feeds')) ||
		(expectedPermissionList && expectedPermissionList.includes('write_feeds')) ||
		(expectedPermissionList && expectedPermissionList.includes('delete_feeds'))
	) {
		return true;
	}

	const expectedPermission =
		expectedPermissionList && expectedPermissionList.filter((permission: string) => permission.includes(permissionType))[0];

	const permissionIndex =
		profilePermissions && profilePermissions.findIndex((permission: Permission) => permission.name === expectedPermission);

	const hasPermission = permissionIndex > -1;

	return hasPermission;
}

export function hasAllPermissions(profilePermissions: Permission[]) {
	const allPermissions = profilePermissions && profilePermissions.filter((permission: Permission) => permission.name === '*');

	return allPermissions && allPermissions.length > 0;
}

export function extractArticleFromArticleList(articleId?: string, articleList?: Article[]): Article {
	if (articleId && articleList) {
		const articleIndex = articleList.findIndex((article: Article) => article.id === articleId);

		if (articleIndex > -1) {
			return articleList[articleIndex];
		}
	}

	return Article.builder().build();
}
