import React, { ChangeEvent, FunctionComponent, useEffect, useState } from 'react';
import { featuresService } from '../../../../App';
import { FeatureTypes } from '../../../../services/feature-service/features.enum';
import { FormGroup } from 'reactstrap';
import Select from 'react-select';
import {
	ContentFooterType,
	convertContentFooterArrayToString,
	footersFromOptionSelections,
	footersToOptions,
} from './helpers/content-footer.helper';
import { DebounceInput } from 'react-debounce-input';
import { connect } from 'react-redux';
import {
	autoGenerateReduxPropsNamingForContentModels,
	generateReduxPropsUpdateActionByContentType,
} from '../../../../services/content-models-service/ContentModelService';
import { DATA_QA_ATTRIBUTES } from './helpers/data-qa.attributes';
import { extractContentModeBasedOnUrl, extractMainContentTypeBasedOnUrl } from '../../../../global-helpers/global.helpers';
import { ReduxContentFooterProp } from './helpers/constants/redux-footer.attributes';

type Properties = {
	t: any;
	contentFooterRedux: string;
	updateTempProperty: (propertyObject: Record<string, any>) => void;
};

const SidebarContentFooter: FunctionComponent<Properties> = ({ t, contentFooterRedux, updateTempProperty }) => {
	const [availableFooters, setAvailableFooters] = useState<ContentFooterType[]>([]);

	useEffect(() => {
		const config = featuresService.getFeatureConfig(FeatureTypes.CONTENT_FOOTER);
		const availableFootersFromConfig = Object.keys(config).length !== 0 ? config.available_footers : [];
		setAvailableFooters(availableFootersFromConfig);
	}, []);

	const onSelectChange = (data: any) => {
		try {
			const dataAsString = convertContentFooterArrayToString(data);
			updateTempProperty({ [ReduxContentFooterProp]: dataAsString });
		} catch (e) {
			console.error(e);
		}
	};

	const onTextAreaChange = (event: ChangeEvent<HTMLInputElement>) => {
		try {
			const data: string = event.target.value || '';
			updateTempProperty({ [ReduxContentFooterProp]: data });
		} catch (e) {
			console.error(e);
		}
	};

	return (
		<FormGroup>
			{availableFooters && availableFooters.length > 0 ? (
				<Select
					id={DATA_QA_ATTRIBUTES.CONTENT_FOOTER_SELECT}
					isMulti
					options={footersToOptions(availableFooters)}
					value={footersFromOptionSelections(contentFooterRedux, availableFooters)}
					onChange={onSelectChange}
				/>
			) : (
				<DebounceInput
					element='textarea'
					className='form-control mb-2'
					id={DATA_QA_ATTRIBUTES.CONTENT_FOOTER_TEXT_AREA}
					placeholder={t('sidebar_content_footer_placeholder')}
					rows='7'
					debounceTimeout={500}
					value={contentFooterRedux}
					onChange={onTextAreaChange}
				/>
			)}
		</FormGroup>
	);
};

function mapStateToProps(state: any) {
	const { tempPropertyNaming, nestedTempPropertyNaming } = autoGenerateReduxPropsNamingForContentModels();

	return {
		contentFooterRedux: state[tempPropertyNaming][nestedTempPropertyNaming].footer || '',
	};
}

function mapDispatchToProps(dispatch: Function) {
	const contentTypeFromUrl = extractMainContentTypeBasedOnUrl();
	const functionForDispatch = generateReduxPropsUpdateActionByContentType(contentTypeFromUrl);

	return {
		updateTempProperty: (propertyObject: Record<string, any>) =>
			functionForDispatch && dispatch(functionForDispatch(propertyObject, extractContentModeBasedOnUrl())),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(SidebarContentFooter);
