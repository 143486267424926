export const extractSocialType = (embed: string) => {
	const socialRegex = {
		twitter: /https?:\/\/twitter\.com\/(?:#!\/)?(\w+)\/status(es)?\/([0-9]{19})/,
		twitterFromScript: /tweet-([0-9]{19})/,
		facebook: /(?:(?:http|https):\/\/(?:www|m|mbasic|business)\.(?:facebook|fb)\.com\/)(?:photo(?:\.php|s)|permalink\.php|video\.php|media|watch\/|questions|notes|[^\/]+\/(?:activity|posts|videos|photos))[\/?](?:fbid=|story_fbid=|id=|b=|v=|)([\w!#$%&'()*+,./:;=?@\[\]-]+)/,
		instagram: /((https?:\/\/)(www\.)?instagram.com\/(p|reel)\/[a-zA-Z0-9_\-\=]+)(\/\?[a-zA-Z0-9_\-\=]+)/,
		facebookIframe: /(?=.*www\.facebook\.com.*)<iframe .*<\/iframe>/,
	};

	const type: any = Object.keys(socialRegex).map((reg: any) => {
		if (embed.match(socialRegex[reg])) {
			const socials = {
				twitter: extractTwitterId(embed, socialRegex[reg]),
				twitterFromScript: extractTwitterIdFromScript(embed, socialRegex[reg]),
				facebook: extractSocialUrl(embed, socialRegex[reg]),
				instagram: extractSocialUrl(embed, socialRegex[reg]),
				facebookIframe: embed,
			};

			const url = socials[reg];

			const attributes = url ? url.replace(/&amp;/g, '&').replace(/\/$/, '') : null;
			const attributesEncoded = attributes && reg === 'facebook' ? encodeURIComponent(attributes) : attributes;
			let embedObject = { type: reg, attributes: attributesEncoded };

			return embedObject;
		}
		return null;
	});

	const filtered = type.filter(function (el: object) {
		return el != null;
	});

	return filtered;
};

export const extractVideoType = (embed: string) => {
	const videoRegex = {
		youtube: /(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})?/,
		vimeo: /(http|https)?:\/\/(www\.|player\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|video\/|)(\d+)(?:|\/\?)/,
	};

	const type: any = Object.keys(videoRegex).map((reg: any) => {
		if (embed.match(videoRegex[reg])) {
			let attributes = reg === 'youtube' ? extractYoutubeId(embed, videoRegex[reg]) : extractVimeoId(embed, videoRegex[reg]);
			let embedObject = { type: reg, attributes: attributes };
			return embedObject;
		}
		return null;
	});

	const filtered = type.filter(function (el: object) {
		return el != null;
	});

	return filtered;
};

export const extractTwitterId = (link: string, regEx: RegExp) => {
	if (link) {
		let matches = link.match(regEx);
		if (matches && matches.length) {
			return matches[3];
		}
	}
};

export const extractYoutubeId = (link: string, regEx: RegExp) => {
	if (link) {
		let matches = link.match(regEx);
		if (matches && matches.length) {
			return matches[1];
		}
	}
};

export const extractVimeoId = (link: string, regEx: RegExp) => {
	if (link) {
		let matches = link.match(regEx);
		if (matches && matches.length) {
			return matches[4];
		}
	}
};

export const extractSocialUrl = (embed: string, regEx: RegExp) => {
	if (embed) {
		let matches = embed.match(regEx);
		if (matches && matches.length) {
			return matches[0];
		}
	}
};

export const extractTwitterIdFromScript = (link: string, regEx: RegExp) => {
	if (link) {
		let matches = link.match(regEx);
		if (matches && matches.length) {
			return matches[1];
		}
	}
};
