import React from 'react';
import { Stepper } from './stepper.helper';
import { LiveBlogTypes } from '../../helpers/utility.helper';
type Properties = {
	activeStep: any;
	type: string;
	redirect: (pane: any, step: any) => void;
};

export const StepperComponent: React.FunctionComponent<Properties> = ({ type, activeStep, redirect }) => {
	const matchSteps = ['Event details', 'Sport configuration', 'Additional settings'];
	const standardSteps = ['Event details', 'Additional settings'];

	return (
		<div>
			<Stepper type={type} redirect={redirect} steps={type === LiveBlogTypes.MATCH ? matchSteps : standardSteps} activeStep={activeStep} />
		</div>
	);
};
