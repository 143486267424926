import { FeatureJson } from './FeatureJson';
import FeatureBuilder from './FeatureBuilder';

export default class Feature {
	readonly id: string = '';
	readonly enabled: boolean = false;
	readonly data: any = {};

	private constructor(id: string, enabled: boolean, data: any) {
		this.id = id;
		this.enabled = enabled;
		this.data = data;
	}

	toJSON(): FeatureJson {
		return {
			id: this.id,
			enabled: this.enabled,
			data: this.data,
		};
	}

	static fromJSON(json: FeatureJson): FeatureJson {
		return new Feature(json.id, json.enabled, json.data);
	}

	static builder(feature?: Feature): FeatureBuilder {
		return new FeatureBuilder(feature);
	}
}
