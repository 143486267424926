import List from './ListConfiguration';
import ListConfiguration from './ListConfiguration';
import { ListRequestJson } from './ListRequestJson';
import { ListConfigurationJson } from './ListConfigurationJson';
import Category from '../../category/Category';
import Related from '../../related/Related';

export default class ListConfigurationBuilder {
	private json: ListConfigurationJson;

	constructor(listConfig?: ListConfiguration | ListConfigurationJson) {
		if (listConfig && listConfig instanceof ListConfiguration) {
			this.json = listConfig.toJSON();
		} else if (listConfig) {
			this.json = listConfig;
		} else {
			this.json = {} as ListConfigurationJson;
		}
	}

	withMinItems(minItems: string): ListConfigurationBuilder {
		this.json.minItems = minItems;

		return this;
	}

	withMaxItems(maxItems: string): ListConfigurationBuilder {
		this.json.maxItems = maxItems;

		return this;
	}

	withCategory(category: Category): ListConfigurationBuilder {
		this.json.category = category;
		return this;
	}

	withSports(sports: Related[]): ListConfigurationBuilder {
		this.json.sports = sports;
		return this;
	}

	build(): List {
		return List.fromJSON(this.json);
	}

	equals(configuration: ListConfiguration): boolean {
		if (configuration && this.json) {
			const minEqual = this.json.minItems === configuration.minItems;
			const maxEqual = this.json.maxItems === configuration.maxItems;

			return minEqual && maxEqual;
		}

		return true;
	}

	toRequestJson(): ListRequestJson {
		let json = {} as ListRequestJson;
		json.min_items = this.json.minItems;
		json.max_items = this.json.maxItems;
		json.category = this.json.category;
		json.sports = this.json.sports;

		return json;
	}
}
