import HttpService from '../../../../../../services/rest/HttpService';
import { modelMatchToResponseModel } from './models/live-blog-events.mapper';
import LiveBlogMatchModel from './models/live-blog-match-model';
import { SportTypes } from '../../../../../../constants/sport.types';

export enum EventStatuses {
	UPCOMING = 'UPCOMING',
	NOT_STARTED = 'NOT_STARTED',
	FINISHED = 'FINISHED',
	INTERRUPTED = 'INTERRUPTED',
	LIVE = 'LIVE',
}

export enum MatchStatuses {
	UPCOMING = 'notstarted',
	IN_PROGRESS = 'inprogress',
	FINISHED = 'finished',
	INTERRUPTED = 'interrupted',
}

export type MatchStatusesTypes = MatchStatuses.UPCOMING | MatchStatuses.IN_PROGRESS | MatchStatuses.INTERRUPTED;

export type EventStatusesTypes = EventStatuses.UPCOMING | EventStatuses.INTERRUPTED | EventStatuses.LIVE;

export const loadMatches = (
	activeTab: EventStatusesTypes,
	tournamentFilter: string | number,
	seasonFilter: string,
	participantsIds: string[],
	entitiesLimit: number,
	setLoadedMatches: Function,
	setEntitiesLimit: Function,
	setLoading: Function,
	selectedSport: string,
	lang: string,
) => {
	const newLimit = entitiesLimit + 10;
	const dataForRequest = extractDataForFetchingFootballMatches(
		extractStatusesTypesBasedOnActiveTab(selectedSport, activeTab) as MatchStatusesTypes[],
		tournamentFilter,
		seasonFilter,
		participantsIds,
		lang,
		newLimit,
	);

	const processResponse = (response: any) => {
		const responseData: Array<any> | null = (response && response.data) || null;
		const remappedData = responseData ? responseData.map((entity: any) => modelMatchToResponseModel(entity)) : [];
		setLoadedMatches(remappedData);
		setEntitiesLimit(newLimit);
		setLoading(false);
	};

	if (participantsIds && participantsIds.length > 0) {
		HttpService.instanceFootball()
			.get(dataForRequest)
			.then(processResponse)
			.catch(() => {
				setLoading(false);
			});
	} else if (tournamentFilter && seasonFilter) {
		HttpService.instanceFootball()
			.get(dataForRequest)
			.then(processResponse)
			.catch(() => {
				setLoading(false);
			});
	} else {
		setEntitiesLimit(0);
		setLoadedMatches(null);
		setLoading(false);
	}
};

// this method will generate url for fetching football matches
export const extractDataForFetchingFootballMatches = (
	statusTypes: MatchStatusesTypes[],
	tournamentFilter: string | number,
	seasonFilter: string,
	participantsIds: string[],
	lang: string,
	limit: number = 10,
) => {
	const searchUrl = new URLSearchParams('');
	const hardcodedParams = `sort_direction=asc&limit=${limit}`;
	const mainUrl = '/matches?';
	const statusTypesToString = statusTypes.join(',');
	const tournamentId = tournamentFilter || '';
	const seasonId = seasonFilter || '';

	if (tournamentId && tournamentId !== '') {
		searchUrl.append('tournament_ids', tournamentId.toString());
	}

	if (seasonId && seasonId !== '') {
		searchUrl.append('season_ids', seasonId.toString());
	}

	if (statusTypesToString && statusTypesToString.length > 0) {
		searchUrl.append('status_types', statusTypesToString);
	}

	if (participantsIds && participantsIds.length > 0) {
		searchUrl.append('team_ids', participantsIds.join(','));
		searchUrl.append('team_ids_operator', 'OR');
	}

	return `${mainUrl}${hardcodedParams}&${searchUrl.toString()}`;
};

export const extractStatusesTypesBasedOnActiveTab = (selectedSport: string, activeTab: EventStatusesTypes) => {
	const enumToCheck = selectedSport.toLowerCase() === SportTypes.FOOTBALL ? MatchStatuses : EventStatuses;

	switch (activeTab) {
		case EventStatuses.UPCOMING:
			return [enumToCheck.UPCOMING, selectedSport.toLowerCase() === SportTypes.FOOTBALL ? MatchStatuses.IN_PROGRESS : EventStatuses.LIVE];
		case EventStatuses.INTERRUPTED:
			return [enumToCheck.INTERRUPTED];
		default:
			return [];
	}
};

// This method is used to extract saved participants ids for specific sport
export const extractParticipantIds = (teams: any): string[] => {
	return teams && teams.length > 0 && teams.map((x: any) => x.id);
};

export const findEmptyEventArrIndex = (model: any[]) => {
	if (model) {
		return model.findIndex((modelEntity) => modelEntity.id === null && modelEntity.home_team === null && modelEntity.away_team === null);
	}
};

export const emptyEventRowAvailable = (events: any[]) => {
	const newRowIndex = findEmptyEventArrIndex(events);
	return newRowIndex && newRowIndex >= 0;
};

export const removeEventById = (events: any[], searchedEventId: number) => {
	return events.filter((event) => event.id !== searchedEventId);
};

export const findIsEventAlreadySelected = (events: any[], currentlySelectedEl: any) => {
	return events.findIndex((event: any) => event && event.id && event.id == currentlySelectedEl.id) >= 0;
};

export const filterEvents = (selectedEvents: any[], availableEvents: any[]) => {
	return availableEvents.filter((elem) => !selectedEvents.find(({ id }) => elem.id === id));
};

export const toggleGoals = (match: LiveBlogMatchModel, homeScore: number = 0, awayScore: number = 0) => {
	return match.event_status && (match.event_status.code === MatchStatuses.IN_PROGRESS || match.event_status.code === MatchStatuses.FINISHED)
		? ` ${homeScore}:${awayScore} `
		: ` - `;
};

export const printMinutesIfMatchInProgress = (match: LiveBlogMatchModel) => {
	if (match.minute && match.minute > 0) {
		return match.event_status && match.event_status.type === MatchStatuses.IN_PROGRESS ? `(${match.minute})` : '';
	} else {
		return match.minute && match.event_status && match.event_status.type === MatchStatuses.IN_PROGRESS ? `(${match.minute})` : '';
	}
};

export const applyInProgressClass = (type: string | null) => {
	return type === MatchStatuses.IN_PROGRESS ? 'border-pulsate-left' : '';
};
