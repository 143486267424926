import 'reflect-metadata';
import { jsonMember, jsonObject } from 'typedjson';
import ImageUrlsUploadedModel from './image-urls-uploaded.model';

@jsonObject()
export default class ImageUrlsModel {
	@jsonMember(String)
	public cropped: string | null = null;

	@jsonMember(ImageUrlsUploadedModel)
	public uploaded: ImageUrlsUploadedModel | null = null;
}
