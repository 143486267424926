import LineupPlayerModel from '../../models/player-lineup/lineup-player.model';

export const itemsToOptions = (items: any[]) => {
	if (items && items.length > 0) {
		return items.map((item: any) => itemToOption(item));
	}

	return [];
};

export const itemToOption = (data: any) => {
	if (data && data.formation) {
		return { label: data.formation, value: data.formation, data: data };
	}

	return [];
};

export const setMargin = (positionX: number | null, index: number, teamPositions: LineupPlayerModel[]) => {
	if (teamPositions && teamPositions.length > 0 && positionX) {
		// It could have maximum 5 players visualization in a row.
		const possiblePlayersInRow = [1, 2, 3, 4, 5];

		// Map possiblePlayersInRow to compare the positionX property of the current player with previous 1 to 5 players.
		const shouldSetMargin = possiblePlayersInRow.map((position: number) =>
			teamPositions[index - position] ? Number(positionX) - 1 === teamPositions[index - position].positionX : false,
		);
		// Deconstruct the conditions and set margin in each 'true' case.
		const [setMargin1, setMargin2, setMargin3, setMargin4, setMargin5] = shouldSetMargin;
		return setMargin1 || setMargin2 || setMargin3 || setMargin4 || setMargin5 ? '2vh' : '';
	}

	return '';
};
