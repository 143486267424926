import React, { useEffect, useState } from 'react';
import { Col, FormGroup, Row } from 'reactstrap';
import OddsV2Model from '../../models/v2/odds-v2.model';
import BlockModel from '../../../../../models/block.model';
import { featuresService } from '../../../../../../../../App';
import { FeatureTypes } from '../../../../../../../../services/feature-service/features.enum';
import { buildInitialModel } from '../../helper/odds.helper';
import MarketModel from '../../models/v2/market.model';
import { SportTypes } from '../../../../../../../../constants/sport.types';
import { BookmakerSelectComponent } from '../../../../partials/bookmaker-select/bookmaker-select.component';
import { SPORT_SELECT_ATTRIBUTES } from '../../../helpers/component-attributes.constants';
import { marketOptionsV2 } from '../../../../../partials/market-select/helpers/market-select.helpers';
import MarketSelect from '../../../../../partials/market-select/market-select.component';
import SportOddsBookmakerModel from '../../../../../../../../models/sport-odds/sport-odds-bookmaker.model';
import MatchModel from '../../../../../../../../models/v2/match/entity/match.model';

type Properties = {
	block: BlockModel;
	t: any;
	onOddsWidgetChange: Function;
	match?: MatchModel;
	isValid: boolean;
	currentOdds: OddsV2Model;
	widgetType?: string;
	displayOddsLabel: boolean;
	isStandAlone: boolean;
};

export const OddsEditV2Component: React.FunctionComponent<Properties> = (props) => {
	const match = props.match ? props.match : new MatchModel();
	const bookmakerSelectionEnabled = featuresService.areFeaturesEnabled([FeatureTypes.ALLOW_BOOKMAKER_SELECTION]);
	const oddsModel = buildInitialModel(props.currentOdds, match);
	const [oddsWidgetModel, setOddsWidgetModel] = useState<OddsV2Model>(oddsModel);

	useEffect(() => {
		if (props.onOddsWidgetChange && props.match) {
			oddsWidgetModel.match = props.match;
			updateWidgetModelState(oddsWidgetModel);
		}
	}, [props.match, props.onOddsWidgetChange]);

	const updateWidgetModelState = (model: OddsV2Model) => {
		if (!props.isStandAlone) {
			props.onOddsWidgetChange(model);
		}
		setOddsWidgetModel(model);
	};

	const onBookmakerChange = (bookmakers: SportOddsBookmakerModel[]) => {
		if (bookmakers.length === 0) {
			oddsWidgetModel.bookmakers = bookmakers;
			oddsWidgetModel.mainBookmaker = null;
			oddsWidgetModel.market = {} as MarketModel;
			updateWidgetModelState(oddsWidgetModel);
		} else {
			oddsWidgetModel.bookmakers = bookmakers;
			updateWidgetModelState(oddsWidgetModel);
		}
	};

	const onMarketChange = (marketName: string) => {
		oddsWidgetModel.market = { name: marketName, value: marketName };
		updateWidgetModelState(oddsWidgetModel);
	};

	return (
		<>
			<div data-qa={`${SportTypes.FOOTBALL}-odds-edit-component`}>
				{props.displayOddsLabel && (
					<Row className='mb-1 mt-4'>
						<Col>
							<h5>{props.t('odds_data')}</h5>
						</Col>
					</Row>
				)}
				{bookmakerSelectionEnabled && (
					<Row>
						<Col>
							<FormGroup>
								<BookmakerSelectComponent
									t={props.t}
									isMulti
									isValid={props.isValid}
									showAllBookmakers
									onSelect={onBookmakerChange}
									bookmakerSelectionEnabled={bookmakerSelectionEnabled}
									eventId={oddsWidgetModel.match && oddsWidgetModel.match.id ? oddsWidgetModel.match.id : null}
									valueList={oddsWidgetModel.bookmakers}
									id={`${SportTypes.FOOTBALL}-${SPORT_SELECT_ATTRIBUTES.BOOKMAKER_SELECT}`}
								/>
							</FormGroup>
						</Col>
					</Row>
				)}
				{bookmakerSelectionEnabled && oddsWidgetModel.bookmakers && oddsWidgetModel.bookmakers.length > 0 && (
					<Row>
						<Col>
							<FormGroup>
								<MarketSelect
									t={props.t}
									value={oddsWidgetModel.market ? oddsWidgetModel.market.name : marketOptionsV2(props.t)[0].value}
									onMarketSelect={onMarketChange}
									limitTo1x2={false}
									id={`${SportTypes.FOOTBALL}-${SPORT_SELECT_ATTRIBUTES.MARKET_SELECT}`}
								/>
							</FormGroup>
						</Col>
					</Row>
				)}
			</div>
		</>
	);
};
