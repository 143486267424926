export function overUnderValuesToOptions(oddProviders: any) {
	if (oddProviders && oddProviders.length > 0) {
		const keys: any = [];
		oddProviders
			.filter((provider: any) => !provider.overUnder.isEmpty)
			.forEach((provider: any) => {
				if (provider.overUnder.over) {
					keys.push(...Object.keys(provider.overUnder.over));
				}

				if (provider.overUnder.under) {
					keys.push(...Object.keys(provider.overUnder.under));
				}
			});

		const options = onlyUnique(keys).map((key: any) => {
			return { key, label: key };
		});

		return options;
	}

	return [];
}

export function onlyUnique(array: any) {
	return array.filter((value: any, index: any, a: any) => a.indexOf(value) === index);
}

export const extractBookmakersIds = (bookmakers: any) => {
	if (bookmakers && bookmakers.length > 0) {
		let selectedBookmakers = bookmakers.map((bookmaker: any) => bookmaker.id);

		return onlyUnique(selectedBookmakers);
	}

	return [];
};
