import React from 'react';
import { Button, Col, FormGroup, Label, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import Blocky from '../../Partials/Blocky/subcomponents/blocky.component';
import { Title } from '../../Partials/Fields/title/TitleComponent';
import { StrapLine } from '../../Partials/Fields/strap-line/StrapLineComponent';
import { Summary } from '../../Partials/Fields/summary/SummaryComponent';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Related from '../../../models/related/Related';
import MainMedia from '../../../models/mainmedia/MainMedia';
import {
	clearTempGallery,
	GALLERY_TEMP_EDIT_UPDATE_BEFORE_REDIRECTING,
	updateTempEditGallery,
} from '../../../store/action-creators/GalleryTempActionCreator';
import Gallery from '../../../models/gallery/Gallery';
import { clearGalleryTagTempRelated, updateGalleryEditTagTempRelated } from '../../../store/action-creators/RelatedTagsTempActionCreator';
import {
	clearGalleryMatchTempRelated,
	updateGalleryEditMatchTempRelated,
} from '../../../store/action-creators/RelatedMatchTempActionCreator';
import {
	clearGalleryContentTempRelated,
	updateGalleryEditContentTempRelated,
} from '../../../store/action-creators/RelatedContentTempActionCreator';
import {
	clearGallerySportsTempRelated,
	updateGalleryEditSportsTempRelated,
} from '../../../store/action-creators/RelatedSportsTempActionCreator';
import Project from '../../../models/project/Project';
import {
	GALLERY_RECEIVED,
	GALLERY_REQUEST,
	GALLERY_UPDATE,
	GALLERY_UPDATE_FAILED,
	GALLERY_UPDATE_SUCCESS,
	galleryRequest,
	galleryUpdate,
} from '../../../store/action-creators/GalleryActionCreator';
import GalleryItemsContainer from './GalleryItems/GalleryItemsContainer';
import GalleryItem from '../../../models/gallery/item/GalleryItem';
import { Properties, State } from './Properties/GalleryEditProperties';
import BlockableButtonWrapper from '../../Partials/BaseComponents/BlockableButton/BlockableButtonWrapper';
import { isGalleryItemsTabActive } from './Helpers/GalleryHelper';
import { ContentTypes } from '../../../constants/content-types';
import { actionService, auditLogService, featuresService, multiLingualService, wordCountService } from '../../../App';
import SeoModel from '../../../models/seo/Seo';
import { Subscription } from 'rxjs';
import SidebarCollapsibleElements from '../../Partials/Sidebar/sidebar-collapsible-elements/sidebar-collapsible-elements';
import SidebarElementsToggle from '../../Partials/Sidebar/sidebar-collapsible-elements/sidebar-elements-toggle/sidebar-elements-toggle.component';
import {
	IMAGE_MEDIA_ADD,
	IMAGE_UPLOAD_FAILED,
	IMAGE_UPLOAD_REQUEST,
	IMAGE_UPLOAD_SUCCESS,
	updateEditContentTempMediaDescription,
} from '../../../store/action-creators/ImageActionCreator';
import { initializeBlockyUndoRedo, resetAutolinking, resetUndoRedoBlocky } from '../../../store/action-creators/BlockyActionCreator';
import { FeatureTypes } from '../../../services/feature-service/features.enum';
import WordCountComponent from '../../Partials/Blocky/blocks/editor/subcomponents/word-count-component';
import { toast } from 'react-toastify';
import { MandatoryFieldsService } from '../../../services/mandatory-fields-service/mandatory-fields.service';
import { TranslationGroupsContainer } from '../../Partials/Sidebar/translation-groups/translation-groups-container.component';
import Flag from 'react-world-flags';
import { AuditLogContainer, DISPLAY_AUDIT } from '../audit-log/audit-log.container';
import OneSignalModal from '../../Partials/Modals/OneSignal/OneSignalModal';
import EditorToolbar from '../../Partials/EditorToolbar/EditorToolbar';
import BlockModel from '../../Partials/Blocky/models/block.model';
import {
	ContentSidebarChangeCollapseStatus,
	onChangeCollapseStatus,
	onSidebarElemSort,
	toggleSidebarEditMode,
} from '../../../services/content-sidebar/content-sidebar.helper';
import { FormattedSidebarSectionValue } from '../../Partials/Sidebar/sidebar-collapsible-elements/utils/temp-sidebar.types';
import GlobalModal from '../../Partials/Modals/Global/global-modal';
import {
	appendBeforeUnloadResetTempSidebar,
	defaultTempSidebarFromApiResponse,
	overwriteTempSidebarByContentType,
	saveSidebarSettingsByContentType,
} from '../../Partials/Sidebar/sidebar-collapsible-elements/utils/temp-sidebar.helper';
import SidebarTags from '../../Partials/Sidebar/tags-refactored/tags';
import SidebarGeneralContent from '../../Partials/Sidebar/general-content/GeneralContentAttributes';
import SidebarSEO from '../../Partials/Sidebar/seo-refactored/seo.component';
import SidebarCustomData from '../../Partials/Sidebar/custom-data/custom-data.component';
import SidebarURLs from '../../Partials/Sidebar/urls-refactored/urls.component';
import { renderComponentOnlyIfFeatureEnabled } from '../../../global-helpers/global-features.helpers';
import SidebarContentFooter from '../../Partials/Sidebar/content-footer-refactored/content-footer.component';
import SidebarRelatedContent from '../../Partials/Sidebar/related-content-refactored/related-content';
import SidebarList from '../../Partials/Sidebar/list-refactored/list-wrapper';
import SidebarMainMedia from '../../Partials/Sidebar/media-refactored/media-wrapper';

class GalleriesEdit extends React.Component<Properties, State> {
	private actionServiceSubject = new Subscription();
	private contentValidation: MandatoryFieldsService = new MandatoryFieldsService();

	constructor(props: Properties) {
		super(props);
		this.state = {
			toggleDeleteModal: true,
			activeTab: new Array(4).fill('1'),
			isContentLoading: false,
			isSidebarInEdit: false,
			areMandatoryFieldsFilled: true,
			totalWordCount: 0,
			totalCharCount: 0,
			addWatermarkToImageMedia: this.props.gallery.generic && this.props.gallery.generic.mainMediaWatermark,
			openSidebarSettingsModalFlag: false,
		};

		this.registerActionEventListener();
	}

	componentWillUnmount() {
		this.props.relatedSports && this.props.clearSportsTempRelated();
		this.props.relatedTags && this.props.clearTagTempRelated();
		this.props.relatedMatches && this.props.clearMatchTempRelated();
		this.props.relatedContent && this.props.clearContentTempRelated();
		this.actionServiceSubject.unsubscribe();
		this.props.resetUndoRedoBlocky();
	}

	componentDidUpdate(prevProps: Properties, prevState: State) {
		if (this.props.gallery.body !== prevProps.gallery.body) {
			this.props.updateSportsTempRelated(this.props.relatedSports);
			this.props.updateMatchTempRelated(this.props.relatedMatches);
		}

		if (this.props.relatedMatches !== prevProps.relatedMatches) {
			this.props.updateMatchTempRelated(this.props.relatedMatches);
		}

		if (this.props.match.params.id !== prevProps.match.params.id) {
			window.scrollTo(0, 0);
			this.toggleContentLoadingState(true);
			this.props.resetAutolinking();
			this.props.getGallery(this.props.match.params.id, this.props.project);
		}
		if (prevProps !== this.props && featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.WORDCOUNT)) {
			this.calculateTotalWordCharCount();
		}
		if (this.props.gallery.generic != prevProps.gallery.generic) {
			this.setState({ addWatermarkToImageMedia: this.props.gallery.generic && this.props.gallery.generic.mainMediaWatermark });
		}
	}

	calculateTotalWordCharCount() {
		let totalWordCharCount = wordCountService.calculateTotalWordCharCount(this.props.gallery.body);
		this.setState(totalWordCharCount);
	}

	registerActionEventListener() {
		this.actionServiceSubject = actionService.onActionReceived().subscribe((action: string) => {
			if (action === GALLERY_REQUEST) {
				this.toggleContentLoadingState(true);
			}

			if (action === GALLERY_RECEIVED) {
				setTimeout(() => {
					this.toggleContentLoadingState(false);
				}, 800);
			}

			if (action === GALLERY_TEMP_EDIT_UPDATE_BEFORE_REDIRECTING) {
				this.onSubmit();
			}
		});
	}

	toggleContentLoadingState(isLoading: boolean) {
		this.setState({
			...this.state,
			isContentLoading: isLoading,
		});
	}

	componentDidMount(): void {
		this.props.updateMatchTempRelated([]);
		this.props.updateSportsTempRelated([]);
		this.setState({ addWatermarkToImageMedia: this.props.gallery.generic && this.props.gallery.generic.mainMediaWatermark });
		this.props.resetAutolinking();
		this.initPageTitle();
		this.props.getGallery(this.props.match.params.id, this.props.project);
		appendBeforeUnloadResetTempSidebar(ContentTypes.GALLERY);
		try {
			this.props.gallery.body && this.props.gallery.body.length > 0 && this.props.initializeBlockyUndoRedo([...this.props.gallery.body]);
		} catch {
			this.props.initializeBlockyUndoRedo([]);
		}
	}

	initPageTitle() {
		document.title = this.props.t('gallery_edit');
	}

	toggleTab(tabPane: any, tab: any) {
		const newArray = this.state.activeTab.slice();
		newArray[tabPane] = tab;
		this.setState({
			activeTab: newArray,
		});
	}

	onTitleChange(title: string) {
		const seo = SeoModel.builder(this.props.gallery.seo).withAutoUpdateSeo(title).build();
		const gallery = Gallery.builder(this.props.gallery).withTitle(title).withSeo(seo).build();
		this.props.updateTempEditGallery(gallery);
	}

	onStrapLineChange(strapline: string) {
		const gallery = Gallery.builder(this.props.gallery).withStrapline(strapline).build();
		this.props.updateTempEditGallery(gallery);
	}

	onSummaryChange(summary: string) {
		const gallery = Gallery.builder(this.props.gallery).withSubtitle(summary).build();
		this.props.updateTempEditGallery(gallery);
	}

	onBodyChange = (blocks: any) => {
		const gallery = Gallery.builder(this.props.gallery).withBody(blocks).build();
		this.props.updateTempEditGallery(gallery);
	};

	onQuickWatermarkChange = (checked: boolean) => {
		let generic = {
			mainMediaWatermark: this.props.gallery.generic && this.props.gallery.generic.mainMediaWatermark,
			watermark: checked,
		};
		const gallery = Gallery.builder(this.props.gallery).withGeneric(generic).build();
		this.props.updateTempEditGallery(gallery);
	};

	onGalleryItemsChange(items: GalleryItem[]) {
		let gallery = Gallery.builder(this.props.gallery).withItems(items).build();
		this.props.updateTempEditGallery(gallery);
	}

	toggleModal(display: boolean) {
		this.setState({
			...this.state,
			toggleDeleteModal: display,
		});
	}

	displayAuditLog = () => {
		actionService.emitUiAction({
			type: DISPLAY_AUDIT,
			data: {
				profileId: this.props.profile.id,
				contentId: this.props.gallery.id,
				contentTitle: this.props.gallery.title,
				contentType: ContentTypes.GALLERY,
			},
		});
	};

	mergeRelatedFromProps() {
		let { relatedContent, relatedMatches, relatedSports, relatedTags } = this.props;
		relatedContent = relatedContent ? relatedContent : [];
		relatedMatches = relatedMatches ? relatedMatches : ([] as Related[]);
		relatedSports = relatedSports ? relatedSports : [];
		relatedTags = relatedTags ? relatedTags : [];

		return [...relatedContent, ...relatedSports, ...relatedTags, ...relatedMatches];
	}

	onSubmit() {
		const relatedList = this.mergeRelatedFromProps();
		if (this.props.gallery.mainMedia && this.props.gallery.mainMedia[0] && this.props.gallery.mainMedia[0].addDescriptionToMedia) {
			this.props.updateTempMediaDescription(this.props.gallery.mainMedia, this.props.project);
		}

		if (this.props.gallery.items && this.props.gallery.items.length > 0) {
			this.props.gallery.items.forEach((item: any) => {
				if (item.addDescriptionToMedia) {
					if (item.comment) {
						item.description = item.comment;
					}
					this.props.updateTempMediaDescription(item, this.props.project);
				}
			});
		}

		if (featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.MANDATORY_FIELDS)) {
			this.contentValidation.validateByContentType(this.props.gallery, ContentTypes.GALLERY)
				? this.checkForWatermarkChecked(relatedList)
				: this.updateMandatoryFieldsFilled(false);
		} else {
			this.checkForWatermarkChecked(relatedList);
		}
	}

	checkForWatermarkChecked = (relatedList: any) => {
		if (
			//If apply watermark is checked, but no main media image is selected, saving is blocked.
			this.props.gallery.mainMedia &&
			this.props.gallery.mainMedia[0] &&
			this.props.gallery.mainMedia[0].addWatermarkToImageMedia &&
			!this.props.gallery.mainMedia[0].resourceId
		) {
			toast.error(this.props.t('missing_main_image_watermark'));
		} else {
			this.props.updateGallery(this.props.gallery, JSON.parse(JSON.stringify(relatedList)), this.props.project);
			try {
				this.props.gallery.body && this.props.gallery.body.length > 0 && this.props.initializeBlockyUndoRedo([...this.props.gallery.body]);
			} catch {
				this.props.initializeBlockyUndoRedo([]);
			}
		}
	};

	updateMandatoryFieldsFilled = (areFilledFields: boolean) => {
		this.setState({ ...this.state, areMandatoryFieldsFilled: areFilledFields });
	};

	onWatermarkAdd(items: GalleryItem[]) {
		let newItems = items.map((item: GalleryItem) => {
			return GalleryItem.builder(item).withGeneric({ watermark: true }).build();
		});
		let gallery = Gallery.builder(this.props.gallery).withItems(newItems).build();
		this.props.updateTempEditGallery(gallery);
	}

	onWatermarkRemoval(items: GalleryItem[]) {
		let newItems = items.map((item: GalleryItem) => {
			return GalleryItem.builder(item).withGeneric({ watermark: false }).build();
		});
		let gallery = Gallery.builder(this.props.gallery).withItems(newItems).build();
		this.props.updateTempEditGallery(gallery);
	}

	toggleSidebarEditMode = () => {
		const newState = toggleSidebarEditMode({ ...this.state }, ContentTypes.GALLERY);
		this.setState(newState);
	};

	render() {
		const { t, gallery, project } = this.props;
		const { isSidebarInEdit, openSidebarSettingsModalFlag } = this.state;

		const sidebarComponentsMap = {
			general: <SidebarGeneralContent t={t} />,
			translations: (
				<TranslationGroupsContainer
					t={t}
					currentContentTranslations={gallery.translationGroup}
					contentType={'gallery'}
					content={gallery}
					analytics={this.props.analytics}
				/>
			),
			media: (
				<SidebarMainMedia
					t={t}
					areMandatoryFieldsFilled={this.state.areMandatoryFieldsFilled}
					updateMandatoryFieldsFilled={this.updateMandatoryFieldsFilled}
				/>
			),
			content_footer: renderComponentOnlyIfFeatureEnabled(FeatureTypes.CONTENT_FOOTER, <SidebarContentFooter t={t} />),
			tags: <SidebarTags t={t} />,
			related_content: <SidebarRelatedContent t={t} />,
			urls: <SidebarURLs t={t} />,
			seo: <SidebarSEO t={t} />,
			list: <SidebarList t={t} />,
			custom_data: <SidebarCustomData t={t} />,
		};

		const saveBlockOnActions = [GALLERY_UPDATE, IMAGE_UPLOAD_REQUEST];
		const saveReleaseOnActions = [GALLERY_UPDATE_SUCCESS, GALLERY_UPDATE_FAILED, IMAGE_UPLOAD_SUCCESS, IMAGE_UPLOAD_FAILED, IMAGE_MEDIA_ADD];

		return (
			<div id='gallery-form-wrapper' className={`nav-tabs-wrapper animated fadeIn ${this.props.reduxLoadingState && 'loading-overlay'}`}>
				<Nav tabs className='mb-2'>
					<NavItem>
						<NavLink
							className={'bg-transparent'}
							active={this.state.activeTab[0] === '1'}
							onClick={() => {
								this.toggleTab(0, '1');
							}}
						>
							<i className='fa fa-file-text-o'></i> {t('gallery_info')}
						</NavLink>
					</NavItem>
					<NavItem>
						<NavLink
							className={'bg-transparent'}
							active={this.state.activeTab[0] === '2'}
							onClick={() => {
								this.toggleTab(0, '2');
							}}
						>
							<i className='fa fa-list'></i> {t('gallery_items')}
						</NavLink>
					</NavItem>
				</Nav>
				<TabContent activeTab={this.state.activeTab[0]} className={'bg-transparent border-0'}>
					<TabPane tabId='1' className={'px-0'}>
						<Row>
							<Col col='8' lg='8' md='12' sm='12' xs='12'>
								<div className='card modified-card-container'>
									<div className='card-header'>
										<div className={'row d-flex align-items-center'}>
											{multiLingualService.checkIfProjectIsMultiLingual(project.languages) && Object.entries(gallery).length > 0 && gallery.language && (
												<div className='col mr-2 text-truncate d-flex'>
													<Flag code={gallery.language === 'en' ? 'gb' : gallery.language} width='20' fallback={<i className='fa fa-flag ml-2' />} />
													<label className={'ml-2 mb-0 text-truncate'}>
														{t('edit')}: {gallery.title}
													</label>
												</div>
											)}
											<div className='py-1 col d-flex'>
												<BlockableButtonWrapper blockOnActions={saveBlockOnActions} releaseOnActions={saveReleaseOnActions}>
													<Button
														size='sm'
														color='primary'
														id='gallery-edit-save-top'
														className={'ml-auto text-nowrap'}
														onClick={this.onSubmit.bind(this)}
													>
														<i className='fa fa-floppy-o'></i> {t('save_gallery')}
													</Button>
												</BlockableButtonWrapper>
												{featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.ONESIGNAL_INTEGRATION) && (
													<OneSignalModal contentType={ContentTypes.GALLERY} t={t} content={gallery} />
												)}
												{auditLogService.isFeatureEnabled(featuresService, this.props.profile.id) && (
													<Button size='sm' color='secondary' className='ml-2 text-nowrap' onClick={this.displayAuditLog}>
														<i className={'fa fa-clock-rotate-left text-white'} />
													</Button>
												)}
											</div>
										</div>
									</div>
									<AuditLogContainer t={this.props.t} />
									<div className='card-body'>
										<div>
											<Row>
												<Col xs='12'>
													<Label htmlFor='title'>{t('title')}</Label>
													<Row>
														<Col>
															<StrapLine t={t} onChange={this.onStrapLineChange.bind(this)} value={this.props.gallery.strapline} />
														</Col>
														<Col>
															<WordCountComponent t={t} wordCount={this.state.totalWordCount} charCount={this.state.totalCharCount} />
														</Col>
													</Row>
													<Title t={t} onChange={this.onTitleChange.bind(this)} value={this.props.gallery.title} />
												</Col>
											</Row>

											<Row>
												<Col xs='12'>
													<Summary t={t} onChange={this.onSummaryChange.bind(this)} showValidation={true} value={this.props.gallery.subtitle} />
												</Col>
											</Row>

											<Row className={'mb-3'}>
												<Col xs='12'>
													<FormGroup>
														<Label htmlFor='summary'>{t('body')}</Label>
														<Blocky
															contentType={ContentTypes.GALLERY}
															t={t}
															blocks={gallery.body ? gallery.body : []}
															onChange={this.onBodyChange}
															contentLanguage={gallery.language}
															entityType={
																multiLingualService.checkIfProjectIsMultiLingual(project.languages) &&
																gallery.translationGroup &&
																gallery.translationGroup.contentType
															}
														/>
													</FormGroup>
												</Col>
											</Row>

											<Row>
												<Col>
													<BlockableButtonWrapper blockOnActions={saveBlockOnActions} releaseOnActions={saveReleaseOnActions}>
														<Button
															to={'#'}
															color={'primary'}
															id='gallery-edit-save-bottom'
															className={'text-uppercase font-weight-bold'}
															onClick={this.onSubmit.bind(this)}
														>
															<i className='fa fa-floppy-o'></i> {t('save_gallery')}
														</Button>
													</BlockableButtonWrapper>
												</Col>
												<Col>
													<WordCountComponent t={t} wordCount={this.state.totalWordCount} charCount={this.state.totalCharCount} />
												</Col>
											</Row>
										</div>
									</div>
								</div>
							</Col>
							{!this.state.isContentLoading && (
								<Col col='4' lg='4' md='12' sm='12' xs='12' className='position-relative'>
									<SidebarElementsToggle t={t} toggleSidebarEditMode={this.toggleSidebarEditMode} isSidebarInEdit={isSidebarInEdit} />
									<SidebarCollapsibleElements
										isSidebarInEdit={isSidebarInEdit}
										onElemSort={(settingsValue: FormattedSidebarSectionValue[]) => onSidebarElemSort(settingsValue, ContentTypes.GALLERY)}
										onChangeCollapseStatus={(data: ContentSidebarChangeCollapseStatus) => onChangeCollapseStatus(data)}
										contentType={ContentTypes.GALLERY}
										sidebarComponentsMap={sidebarComponentsMap}
										t={t}
										currentProject={project}
									/>
								</Col>
							)}
						</Row>
					</TabPane>
					<TabPane tabId='2' className={'px-0'}>
						<GalleryItemsContainer
							onWatermarkRemoval={this.onWatermarkRemoval.bind(this)}
							onWatermarkAdd={this.onWatermarkAdd.bind(this)}
							quickWatermarkChecked={this.props.gallery.generic && this.props.gallery.generic.watermark}
							onChange={this.onGalleryItemsChange.bind(this)}
							onQuickWatermarkChange={this.onQuickWatermarkChange.bind(this)}
							t={t}
							project={project}
							items={this.props.gallery.items}
							analytics={this.props.analytics}
						>
							<BlockableButtonWrapper blockOnActions={[GALLERY_UPDATE]} releaseOnActions={[GALLERY_UPDATE_SUCCESS, GALLERY_UPDATE_FAILED]}>
								<Button size='sm' color='primary' onClick={this.onSubmit.bind(this)}>
									<i className='fa fa-floppy-o'></i> {t('save_gallery')}
								</Button>
							</BlockableButtonWrapper>
						</GalleryItemsContainer>
					</TabPane>
				</TabContent>
				<EditorToolbar
					contentType={ContentTypes.GALLERY}
					onChange={this.onBodyChange}
					save={{
						invokeFunction: this.onSubmit.bind(this),
						blockOnActions: saveBlockOnActions,
						releaseOnActions: saveReleaseOnActions,
						blockUntilCorrectContentIsLoaded: !gallery.id,
					}}
					onPreview={null}
					onCopyToProject={null}
					onAuditLog={this.displayAuditLog}
					t={t}
					content={this.props.gallery}
					showNotifyUsers
					hideUndoRedoButtons={isGalleryItemsTabActive(this.state.activeTab[0])}
				/>
				<GlobalModal
					isOpen={openSidebarSettingsModalFlag}
					t={this.props.t}
					headerContent='save_sidebar_settings'
					bodyContent='unsaved_changes'
					submitFunction={() => saveSidebarSettingsByContentType(ContentTypes.GALLERY, t)}
					beforeCloseFunction={() =>
						overwriteTempSidebarByContentType(ContentTypes.GALLERY, defaultTempSidebarFromApiResponse[ContentTypes.GALLERY].value)
					}
				/>
			</div>
		);
	}
}

function mapStateToProps(state: any) {
	return {
		gallery: state.tempGallery.galleryEdit,
		project: state.project.currentProject,
		profile: state.profile.profile,
		relatedContent: state.tempContentRelated.galleryEditRelated,
		relatedSports: state.tempSportsRelated.galleryEditRelated,
		relatedTags: state.tempTagsRelated.galleryEditRelated,
		relatedMatches: state.tempMatchRelated.galleryEditRelated,
		reduxLoadingState: state.ui.contentLoadingState[ContentTypes.GALLERY],
	};
}

function mapDispatchToProps(dispatch: any) {
	const isUndoRedoEnabled =
		featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.CONTENT_BLOCKY_UNDO_REDO) &&
		featuresService.checkAvailableUndoRedoPageFromFeature(ContentTypes.GALLERY);
	return {
		updateTempEditGallery: (gallery: Gallery) => dispatch(updateTempEditGallery(gallery)),
		updateTempMediaDescription: (media: MainMedia, project: Project) => dispatch(updateEditContentTempMediaDescription(media, project)),
		getGallery: (id: string, project: Project) => dispatch(galleryRequest(id, project)),
		clearTempGallery: () => dispatch(clearTempGallery()),
		updateGallery: (gallery: Gallery, related: Related[], project: Project) => dispatch(galleryUpdate(gallery, related, project)),
		updateTagTempRelated: (related: Related[]) => dispatch(updateGalleryEditTagTempRelated(related)),
		updateSportsTempRelated: (related: Related[]) => dispatch(updateGalleryEditSportsTempRelated(related)),
		updateMatchTempRelated: (related: Related[]) => dispatch(updateGalleryEditMatchTempRelated(related)),
		updateRelatedContentTemp: (related: Related[]) => dispatch(updateGalleryEditContentTempRelated(related)),
		clearMatchTempRelated: () => dispatch(clearGalleryMatchTempRelated()),
		clearTagTempRelated: () => dispatch(clearGalleryTagTempRelated()),
		clearContentTempRelated: () => dispatch(clearGalleryContentTempRelated()),
		clearSportsTempRelated: () => dispatch(clearGallerySportsTempRelated()),
		resetAutolinking: () => dispatch(resetAutolinking()),
		initializeBlockyUndoRedo: (blocks: BlockModel[]) => isUndoRedoEnabled && dispatch(initializeBlockyUndoRedo(blocks)),
		resetUndoRedoBlocky: () => isUndoRedoEnabled && dispatch(resetUndoRedoBlocky()),
	};
}

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps), withRouter)(GalleriesEdit) as React.ComponentType;
