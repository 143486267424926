import React from 'react';

export function customOption(option: any) {
	const { label, value } = option;

	return (
		<>
			{value === 'delete' && <div className='dropdown-divider p-0 m-0' />}
			<div className='p-2'>{label}</div>
		</>
	);
}
