import React from 'react';
import { contentToOption, contentToOptions } from '../../helpers/select.helper';
import Select from 'react-select';
import { Properties, State } from './properties/team-select.properties';
import HttpService, { PLAYER_INFO_TYPE } from '../../../../../../services/rest/HttpService';

export default class PlayerTeamSelect extends React.Component<Properties, State> {
	constructor(props: Properties) {
		super(props);
		this.state = {
			teamOptions: [],
		};
	}

	componentDidMount() {
		if (this.props.playerId) {
			this.requestPlayerInformation(this.props.playerId);
		}
	}

	componentDidUpdate(prevProps: any, prevState: any) {
		if (this.props.playerId !== prevProps.playerId) {
			this.requestPlayerInformation(this.props.playerId);
		}
	}

	setTeamOptions = (options: []) => {
		this.setState({
			teamOptions: options,
		});
	};

	onTeamSelect = (selection: any) => {
		this.props.onTeamSelect(selection.data);
	};

	requestPlayerInformation = (playerId: string) => {
		HttpService.getPlayerMatchesInfo(playerId, PLAYER_INFO_TYPE.TEAMS, this.props.currentProject.language)
			.then((response: any) => {
				this.setTeamOptions(contentToOptions(response));
			})
			.catch((e: any) => e);
	};

	render() {
		const { teamOptions } = this.state;
		const { selectedTeam } = this.props;

		return <Select options={teamOptions} value={[contentToOption(selectedTeam)]} onChange={this.onTeamSelect} />;
	}
}
