import classNames from 'classnames';
import React, { FC, createContext, useEffect, useMemo, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Button, Input, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { ContentTypes } from '../../../../../constants/content-types';
import {
	returnObjectForLiveBlogEditorialClose,
	returnObjectForLiveBlogEditorialOpen,
} from '../../../../../store/action-creators/LiveBlogEditorialActionCreator';
import { store } from '../../../../../store/store';
import ContentWidget from '../../../../Partials/Blocky/blocks/content/content-block/models/content-widget.model';
import BlockModel from '../../../../Partials/Blocky/models/block.model';
import ErrorHandler from '../../../../Partials/Blocky/partials/error/error-handler-component';
import { blockWrapperService } from '../../../../Partials/Blocky/subcomponents/blocky.component';
import { getLiveBlogById, patchLiveBlog } from '../../helpers/live-blog-main.helper';
import useRestOnDemand from '../../hooks/useRestOnDemand';
import LiveBlogModel from '../../models/live-blog.model';
import { CollaborativeEditorProps, WidgetActionType } from '../subcomponents/collaborative-editor/collaborative-editor';
import ContentBlockEdit from '../subcomponents/content-block-edit/content-block-edit.component';
import LiveBlogEditorialAdminErrorFallback from '../subcomponents/live-blog-editorial-admin-error-fallback/live-blog-editorial-admin-error-fallback.component';
import LiveBlogFeed from '../subcomponents/live-blog-feed/live-blog-feed.component';
import LiveBlogNewPost from '../subcomponents/live-blog-new-post/live-blog-new-post.component';
import MatchCenterColumn from '../subcomponents/match-center-column/match-center-column.component';

import '../../style/live-blog-editorial-admin.style.scss';
import '../../style/live-blog.style.scss';

type Properties = {
	t: any;
	history: any;
};

interface ModalSettings {
	isOpen: boolean;
	block?: BlockModel | null;
	mode: WidgetActionType;
	subDocumentId?: string;
}

export type OpenModalCallback = (block: BlockModel, mode: WidgetActionType, content?: ContentWidget, subDocumentId?: string) => void;

export const LiveBlogEditorialAdminContext = createContext<LiveBlogModel | undefined>(undefined);

const createEmptyContent = () => ContentWidget.builder().build();

const getHeader = (block: BlockModel): string => {
	if (block.type === 'widget_smp' || block.type === 'widget_smp_V2') {
		return block.data.widget_type;
	}

	return block.type;
};

export const LiveBlogEditorialAdmin: FC<Properties> = ({ t, history }) => {
	const [modalSettings, setModalSettings] = useState<ModalSettings>({ isOpen: false, block: null, mode: WidgetActionType.ADD });
	const [content, setContent] = useState<ContentWidget>(createEmptyContent());
	const [lastUpdatedWidget, setLastUpdatedWidget] = useState<CollaborativeEditorProps['lastUpdatedWidget']>();
	const [isThirdColumnCollapsed, setIsThirdColumnCollapsed] = useState(false);
	const [liveBlogTitle, setLiveBlogTitle] = useState<string>('');
	const [liveBlogDescription, setLiveBlogDescription] = useState<string>('');

	useEffect(() => {
		if (!modalSettings.isOpen) {
			setContent(createEmptyContent());
		}
	}, [modalSettings.isOpen]);

	const liveBlogId = useMemo(() => window['location'].hash.split('/').reduce((a, c) => c, ''), []); // Due to old version of react-router-dom; a better alternative is "useParams"

	const { data: liveBlogConfiguration, refetchOnDemand } = useRestOnDemand<LiveBlogModel | undefined>(
		() => getLiveBlogById(liveBlogId, t('error_loading_live_blogs')),
		!liveBlogId,
	);

	useEffect(() => {
		if (liveBlogConfiguration) {
			if (liveBlogConfiguration.title) {
				document.title = liveBlogConfiguration.title;

				const activeBreadcrumb = document.querySelector('.breadcrumb-item.active');

				if (activeBreadcrumb) {
					activeBreadcrumb.innerHTML =
						t('live_blogs_editorial_admin') + ` <strong style="font-variant: small-caps;">(${liveBlogConfiguration.title})</strong>`;
				}
			}

			setLiveBlogTitle(liveBlogConfiguration.title || '');
			setLiveBlogDescription(liveBlogConfiguration.description || '');

			store.dispatch(returnObjectForLiveBlogEditorialOpen(liveBlogConfiguration));

			return () => {
				store.dispatch(returnObjectForLiveBlogEditorialClose());
			};
		}
	}, [liveBlogConfiguration]);

	const openModalCallback: OpenModalCallback = (block, mode, content, subDocumentId) => {
		setModalSettings({
			isOpen: true,
			block,
			mode,
			subDocumentId,
		});

		if (mode === WidgetActionType.EDIT && content) {
			setContent(content);
		}
	};

	const setWidget = () => {
		const savedCorrectly = blockWrapperService.saveAll();

		if (!savedCorrectly) {
			return;
		}

		const attrs = {
			'data-block': JSON.stringify(modalSettings.block),
			'data-content': JSON.stringify(content),
		};

		setLastUpdatedWidget({
			actionType: modalSettings.mode,
			widgetDetails: {
				subDocumentId: modalSettings.subDocumentId,
				attrs,
			},
		});

		setModalSettings({
			isOpen: false,
			block: null,
			mode: WidgetActionType.ADD,
		});
	};

	const onLiveBlogConfigurationUpdate = () => refetchOnDemand();

	const onLiveBlogTitleAndDescriptionSave = () => {
		if (
			(liveBlogConfiguration &&
				((liveBlogConfiguration.title || '') !== liveBlogTitle || (liveBlogConfiguration.description || '') !== liveBlogDescription)) ||
			!liveBlogConfiguration
		) {
			patchLiveBlog(
				{
					id: liveBlogId,
					title: liveBlogTitle || null,
					description: liveBlogDescription || null,
				},
				t('live_blog_title_description_update_success'),
				t('live_blog_title_description_update_error'),
			);
		}
	};

	return (
		<ErrorBoundary fallback={<LiveBlogEditorialAdminErrorFallback />}>
			<LiveBlogEditorialAdminContext.Provider value={liveBlogConfiguration}>
				<div
					className={classNames('live-blog-editorial-admin-container', {
						'two-columns':
							!liveBlogConfiguration || liveBlogConfiguration.type !== 'SPORT_EVENT' || liveBlogConfiguration.sport_events.length === 0,
						'collapsed-third-column': isThirdColumnCollapsed,
					})}
				>
					<div style={{ gridArea: 'new-post', marginBottom: 0 }}>
						<div className='live-blog-editorial-admin-column-header'>{t('new_post')}</div>
						<LiveBlogNewPost openModalCallback={openModalCallback} lastUpdatedWidget={lastUpdatedWidget} />
					</div>
					<div style={{ gridArea: 'title-edit' }}>
						<div className='live-blog-editorial-admin-column-header'>{t('edit_live_blog_title_description')}</div>
						<div className='live-blog-title-description-edit-container'>
							<label htmlFor='live-blog-title-edit'>{t('title')}</label>
							<Input id='live-blog-title-edit' value={liveBlogTitle} onChange={(event) => setLiveBlogTitle(event.target.value)} />
							{!liveBlogTitle && <ErrorHandler errorMessage='fu_titleRequiredFieldValidationMessage' />}
							<label htmlFor='live-blog-description-edit'>{t('description')}</label>
							<textarea
								id='live-blog-description-edit'
								className='form-control'
								value={liveBlogDescription}
								onChange={(event) => setLiveBlogDescription(event.target.value)}
							/>
							<Button
								color='success'
								disabled={
									!liveBlogTitle ||
									(liveBlogConfiguration &&
										(liveBlogConfiguration.title || '') === liveBlogTitle &&
										(liveBlogConfiguration.description || '') === liveBlogDescription)
								}
								onClick={onLiveBlogTitleAndDescriptionSave}
							>
								{t('save')}
							</Button>
						</div>
					</div>
					<div className='live-blog-editorial-admin-post-feed-container' style={{ gridArea: 'feed' }}>
						<div className='live-blog-editorial-admin-column-header'>{t('posts_timeline')}</div>
						<LiveBlogFeed
							onLiveBlogConfigurationUpdate={onLiveBlogConfigurationUpdate}
							widgetEditDetails={{ openModalCallback, lastUpdatedWidget, setLastUpdatedWidget }}
						/>
					</div>
					{liveBlogConfiguration && liveBlogConfiguration.type === 'SPORT_EVENT' && liveBlogConfiguration.sport_events.length > 0 && (
						<div className='live-blog-editorial-admin-misc-container' style={{ gridArea: 'misc' }}>
							<MatchCenterColumn
								contentLanguage={liveBlogConfiguration.language}
								events={liveBlogConfiguration.sport_events}
								isColumnCollapsed={isThirdColumnCollapsed}
								setIsColumnCollapsed={setIsThirdColumnCollapsed}
							/>
						</div>
					)}
				</div>
			</LiveBlogEditorialAdminContext.Provider>
			<Modal className='live-blog-editorial-admin-widget-modal' isOpen={modalSettings.isOpen} size='lg'>
				{modalSettings.block && (
					<>
						<ModalHeader>{t(getHeader(modalSettings.block))}</ModalHeader>
						<ModalBody>
							<ContentBlockEdit
								block={modalSettings.block}
								content={content}
								contentLanguage={liveBlogConfiguration!.language}
								entityType={ContentTypes.LIVE_BLOG}
							/>
						</ModalBody>
					</>
				)}
				<ModalFooter>
					<Button outline onClick={() => setModalSettings({ isOpen: false, block: null, mode: WidgetActionType.ADD })}>
						{t('cancel')}
					</Button>
					<Button color='success' onClick={setWidget}>
						{t(modalSettings.mode)}
					</Button>
				</ModalFooter>
			</Modal>
		</ErrorBoundary>
	);
};
