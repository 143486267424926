import { FeedJson } from './feed.json.js';
import FeedBuilder from './feed.builder';
import Source from './sources/source';

export default class Feed {
	readonly id: string;
	readonly name: string;
	readonly active: boolean;
	readonly sources: Source[];

	private constructor(id: string, name: string, active: boolean, sources: Source[]) {
		this.id = id;
		this.name = name;
		this.active = active;
		this.sources = sources;
	}

	toJson(): FeedJson {
		return {
			id: this.id,
			name: this.name,
			active: this.active,
			sources: this.sources,
		};
	}

	static fromJson(json: FeedJson): Feed {
		return new Feed(json.id, json.name, json.active, json.sources);
	}

	static builder(feed?: Feed): FeedBuilder {
		return new FeedBuilder(feed);
	}
}
