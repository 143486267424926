import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { Button, Col, FormGroup, Label, Row } from 'reactstrap';
import { DebounceInput } from 'react-debounce-input';
import CustomBlockModel from '../../../models/v2/custom-blocks/custom-block/custom-block.model';
import Select from 'react-select';
import { customBlocksService } from '../../../App';
import './custom-block.component.scss';

const statusOptions = (t: any) => [
	{ value: 'ENABLED', label: t('active') },
	{ value: 'DISABLED', label: t('inactive') },
];

type Props = {
	t: any;
	customBlock: CustomBlockModel;
	mode: string;
	onCustomBlockChange: (model: CustomBlockModel) => void;
};

export const CustomBlock: FunctionComponent<Props> = ({ customBlock, onCustomBlockChange, t, mode }) => {
	const [selectedOption, setSelect] = useState({ value: '', label: '' });
	const [selectedIcon, setSelectIcon] = useState('');
	const [showMissingIconMessage, updateIconMessage] = useState<boolean>(false);
	const iconRef = useRef(null);

	useEffect(() => {
		selectedIcon && selectedIcon.length > 0 && iconRef.current.offsetHeight === 0 ? updateIconMessage(true) : updateIconMessage(false);
	}, [iconRef !== null && iconRef.current !== null && iconRef.current.offsetHeight]);

	useEffect(() => {
		setSelect({ value: customBlock.status, label: setCorrectLabel(customBlock.status, t) });
		setSelectIcon(customBlock.icon && customBlock.icon.value);
	}, [customBlock.id, customBlock.name, customBlock.application_url, customBlock.icon, customBlock.status]);

	const onStatusChange = (selected: any) => {
		setSelect({ value: selected.value, label: t(selected.label) });
	};

	const setCorrectLabel = (status: any, t: any) => {
		let labelSig = '';
		if (status && status.length > 0) {
			if (status === 'ENABLED') {
				labelSig = t('active');
			} else if (status === 'DISABLED') {
				labelSig = t('inactive');
			}
		}

		return labelSig;
	};

	const limitNameInput = (input: string) => {
		if (input && input.length > 80) {
			return input.substring(0, 80);
		} else {
			return input;
		}
	};

	return (
		<div className='animated fadeIn'>
			<Row>
				<Col col='8' lg='8' md='12' sm='12' xs='12'>
					<div className='card'>
						<div className='card-header'>
							<div className='d-flex align-items-center'>
								<span className='mr-auto mb-0'>{mode === 'create' ? t('create_custom_block') : t('edit_custom_block')}</span>
								<Button
									color={'primary'}
									id='custom-block-edit-save-bottom'
									className={'text-uppercase font-weight-bold'}
									disabled={
										customBlock.name === undefined ||
										customBlock.application_url === undefined ||
										(customBlock.name && customBlock.name.length < 1) ||
										customBlock.name === '' ||
										(customBlock.application_url && customBlock.application_url.length < 1) ||
										customBlock.application_url === ''
									}
									onClick={() => {
										if (mode === 'edit') {
											return customBlocksService.updatedCustomBlock(
												t,
												customBlock.id,
												customBlock.name,
												customBlock.application_url,
												customBlock.icon,
												customBlock.status,
											);
										} else {
											return customBlocksService.createCustomBlock(
												t,
												customBlock.name,
												customBlock.application_url,
												customBlock.icon,
												customBlock.status,
											);
										}
									}}
								>
									<i className='fa fa-floppy-o' /> {t('save_block')}
								</Button>
							</div>
						</div>
						<div className='card-body'>
							<div className='row mb-3'>
								<div className='col-sm-6 col-xs-6 col-md-6 d-flex flex-column'>
									<Label>{t('block_name')}</Label>
									<DebounceInput
										value={customBlock.name}
										onChange={(evt) => {
											customBlock.name = limitNameInput(evt.target.value);
											onCustomBlockChange(Object.assign({ ...customBlock }));
										}}
										debounceTimeout={500}
										className='form-control'
										type='text'
										placeholder={t('custom_block_name_placeholder')}
									/>
									{(customBlock.name && customBlock.name.length < 1) ||
										(customBlock.name === '' && (
											<label className='text-danger' htmlFor={`custom-block-name-error-message`}>
												{t('custom_block_name_error_message')}
											</label>
										))}
								</div>
							</div>
							<div className='row mb-3 px-1'>
								<Label>{t('application_url')}</Label>
								<DebounceInput
									value={customBlock.application_url}
									className='form-control'
									type='text'
									onChange={(evt) => {
										customBlock.application_url = evt.target.value;
										onCustomBlockChange(Object.assign({ ...customBlock }));
									}}
									placeholder={t('custom_block_url_placeholder')}
								/>
								{(customBlock.application_url && customBlock.application_url.length < 1) ||
									(customBlock.application_url === '' && (
										<label className='text-danger' htmlFor={`custom-block-url-error-message`}>
											{t('custom_block_url_error_message')}
										</label>
									))}
							</div>
							<div className='row mb-3'>
								<div className='col-sm-6 col-xs-6 col-md-6 d-flex flex-column'>
									<Label>{t('block_icon')}</Label>
									<DebounceInput
										value={customBlock.icon ? customBlock.icon.value : ''}
										onChange={(evt) => {
											customBlock.icon = {
												type: 'ICON',
												value: evt.target.value.trim(),
											};
											setSelectIcon(customBlock.icon.value);
											onCustomBlockChange(Object.assign({ ...customBlock }));
										}}
										debounceTimeout={500}
										className='form-control'
										type='text'
										placeholder={t('custom_block_icon_placeholder')}
									/>
									<span style={{ marginLeft: '105%', marginTop: '7%', position: 'absolute' }}>
										<i ref={iconRef} className={`text-black-50 ${selectedIcon} rounded-circle mr-1`} />
									</span>
									{showMissingIconMessage && (
										<label className='text-danger' htmlFor={`custom-block-name-error-message`}>
											{t('missing_fav_icon')}
										</label>
									)}
								</div>
								<div className={`tip-container p-3 mb-2 my-2`}>
									<div className='tip-message pb-1'>
										<i className={`text-black-50 fa fa-info rounded-circle mr-1`} />
										<strong>{t(`icon_select_tip_message_1`)}</strong>
										<strong>
											<a href={'https://fontawesome.com/search?m=free'} target='_blank' rel='noopener noreferrer'>
												{t('icon_link')}
											</a>
										</strong>
										<strong>{t(`icon_select_tip_message_2`)}</strong>
									</div>
								</div>
							</div>
							<div className='row mt-4'>
								<div className='col col-xs-16'>
									<Button
										color={'primary'}
										id='custom-block-edit-save-bottom'
										disabled={
											customBlock.name === undefined ||
											customBlock.application_url === undefined ||
											(customBlock.name && customBlock.name.length < 1) ||
											customBlock.name === '' ||
											(customBlock.application_url && customBlock.application_url.length < 1) ||
											customBlock.application_url === ''
										}
										className={'text-uppercase font-weight-bold'}
										onClick={() => {
											if (mode === 'edit') {
												return customBlocksService.updatedCustomBlock(
													t,
													customBlock.id,
													customBlock.name,
													customBlock.application_url,
													customBlock.icon,
													customBlock.status,
												);
											} else {
												return customBlocksService.createCustomBlock(
													t,
													customBlock.name,
													customBlock.application_url,
													customBlock.icon,
													customBlock.status,
												);
											}
										}}
									>
										<i className='fa fa-floppy-o' /> {t('save_block')}
									</Button>
								</div>
							</div>
						</div>
					</div>
				</Col>
				<Col col='4' lg='4' md='12' sm='12' xs='12' className='position-relative'>
					<div className='card'>
						<div className='card-header'>
							<div className='d-flex align-items-center'>
								<span style={{ marginLeft: '-10px' }}>
									<i className='fa fa-cogs' />
								</span>
								<span style={{ marginLeft: '15px' }} className='mr-auto mb-0'>
									{t('general')}
								</span>
							</div>
						</div>
						<div className='card-body'>
							<div className='col col-xs-16'>
								<FormGroup key={'formgroup-select-status-key'}>
									<Label htmlFor='content-status-select'>{t('status')}</Label>
									<CustomBlockStatusSelect
										t={t}
										isClearable={false}
										statuses={statusOptions(t)}
										onStatusSelect={onStatusChange}
										selectedStatus={selectedOption}
										customBlock={customBlock}
									/>
								</FormGroup>
							</div>
						</div>
					</div>
				</Col>
			</Row>
		</div>
	);
};

const CustomBlockStatusSelect: FunctionComponent<any> = ({ t, selectedStatus, statuses, onStatusSelect, customBlock }) => {
	const onContentChange = (selection: any) => {
		customBlock.status = selection.value;
		onStatusSelect(selection ? selection : '');
	};
	return (
		<Select
			value={selectedStatus ? customBlocksService.contentStatusToOption(selectedStatus, t) : ''}
			options={customBlocksService.contentStatusToOptions(statuses, t)}
			placeholder={t('custom_block_status_placeholder')}
			onChange={onContentChange}
			inputId='custom-block-status-select'
		/>
	);
};
