import { toast } from 'react-toastify';
import { keyMap } from '../../constants/key.constants';
import BlockModel from '../../models/block.model';
import Global from '../../helpers/global.helpers';
import BrowserPermissions, { BrowserPermissionTypes, PermissionState } from '../../helpers/browser-permissions.helper';

export const isSafari = () => /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

export const isJson = (str: string) => {
	try {
		JSON.parse(str);
	} catch (e) {
		return false;
	}

	return true;
};

export const clipboardCondition = (e: any, keyMap: any) => {
	if (e && keyMap) {
		return e.shiftKey === keyMap.shiftKey && e.ctrlKey === keyMap.ctrlKey && e.keyCode === keyMap.keyCode;
	}

	return false;
};

export const changeBlocksIds = (blocks: BlockModel[]) => {
	if (blocks && blocks.length > 0) {
		return blocks.map((block: BlockModel) => {
			return BlockModel.builder().withId(Global.makeId(7)).withData(block.data).withType(block.type).build();
		});
	}

	return [];
};

export const copyToClipboard = (e: any, props: any, copyWithButton: boolean) => {
	e.stopPropagation();

	if (clipboardCondition(e, keyMap.copyKeys) || copyWithButton) {
		isSafari() && toast.error(props.t('not_supported_in_safari'));
		!isSafari() && copyWithPermissions(props);
	}
};

const copyWithPermissions = (props: any) => {
	const permissions = new BrowserPermissions();

	permissions
		.check(BrowserPermissionTypes.clipboardWrite)
		.then((perm: PermissionStatus) => {
			if (perm.state === PermissionState.granted) {
				navigator.clipboard
					.writeText(JSON.stringify({ name: 'blocky', blocks: props.blocksToCopy }))
					.then(() => {
						props.blocksCopiedToState(true);
						toast.success(props.t('copy_blocks_success'));
					})
					.catch((e) => {
						toast.error(props.t('copy_blocks_failed'));
					});
			} else if (perm.state === PermissionState.denied || perm.state === PermissionState.prompt) {
				toast.error(props.t('copy_paste_permissions'));
			}
		})
		.catch((e) => {
			toast.error(props.t('copy_paste_permissions'));
		});
};

export const pasteFromClipboard = (e: any, props: any, pasteTopButton: boolean, pasteBottomButton: boolean) => {
	e.stopPropagation();
	const pasteTop = clipboardCondition(e, keyMap.pasteTop);
	const pasteBottom = clipboardCondition(e, keyMap.pasteBottom);

	if (pasteTop || pasteTopButton || pasteBottom || pasteBottomButton) {
		isSafari() && toast.error(props.t('not_supported_in_safari'));
		!isSafari() && pasteWithPermissions(props, pasteBottom, pasteBottomButton);
	}
};

const pasteWithPermissions = (props: any, pasteBottom: any, pasteBottomButton: any) => {
	new BrowserPermissions()
		.check(BrowserPermissionTypes.clipboardRead)
		.then((perm: PermissionStatus) => {
			if (perm.state === PermissionState.granted) {
				navigator.clipboard.readText().then((text) => {
					if (text && isJson(text) && JSON.parse(text).name && JSON.parse(text).name === 'blocky') {
						const parsedBlocks = JSON.parse(text).blocks;

						if (parsedBlocks && parsedBlocks.length > 0) {
							const blocksToAdd = changeBlocksIds(parsedBlocks);
							const contentBlocks = pasteBottom || pasteBottomButton ? [...props.blocks, ...blocksToAdd] : [...blocksToAdd, ...props.blocks];
							props.updateBlockyUndoRedo([...contentBlocks], [...props.blocks]);
							props.onChange(contentBlocks);
							props.blocksCopiedToState(false);
							toast.success(props.t('paste_blocks_success'));
						}

						navigator.clipboard.writeText('');
						props.updateBlocksToCopy([]);
					}
				});
			} else if (perm.state === PermissionState.denied || perm.state === PermissionState.prompt) {
				toast.error(props.t('copy_paste_permissions'));
			}
		})
		.catch((e) => {
			toast.error(props.t('copy_paste_permissions'));
		});
};
