import TeamModel from '../../team-select/models/team.model';
import MatchListConfigModel from './match-list-config.model';
import { MatchListConfigJson } from './match-list-config.json';
import { OddsConfiguration } from './odds-configuration.json';
import OddClientBookmakersModel from '../../available-bookmakers-select/models/odd-client-bookmakers.model';

export default class MatchListConfigBuilder {
	private json: MatchListConfigJson;

	constructor(model?: MatchListConfigModel | MatchListConfigJson) {
		if (model && model instanceof MatchListConfigModel) {
			this.json = model.toJson();
		} else if (model) {
			this.json = model;
		} else {
			this.json = {} as MatchListConfigJson;
		}
	}

	withView(view: string): MatchListConfigBuilder {
		this.json.view = view;

		return this;
	}

	withGrouping(grouping: string): MatchListConfigBuilder {
		this.json.grouping = grouping;

		return this;
	}

	withOrder(order: string): MatchListConfigBuilder {
		this.json.order = order;

		return this;
	}

	withOrderDirection(orderDirection: string): MatchListConfigBuilder {
		this.json.orderDirection = orderDirection;

		return this;
	}

	withFrom(from: string): MatchListConfigBuilder {
		this.json.from = from;

		return this;
	}

	withTo(to: string): MatchListConfigBuilder {
		this.json.to = to;

		return this;
	}

	withOddsConfiguration(config: OddsConfiguration): MatchListConfigBuilder {
		this.json.oddsConfiguration = config;

		return this;
	}

	withTitle(title: string): MatchListConfigBuilder {
		this.json.title = title;

		return this;
	}

	withViewMoreLabel(viewMoreLabel: string): MatchListConfigBuilder {
		this.json.viewMoreLabel = viewMoreLabel;

		return this;
	}

	withBookmaker(bookmaker: OddClientBookmakersModel | null): MatchListConfigBuilder {
		this.json.bookmaker = bookmaker;

		return this;
	}

	withThreeLetterCodeType(threeLetterCodeType: any): MatchListConfigBuilder {
		this.json.threeLetterCodeType = threeLetterCodeType;

		return this;
	}

	build(): MatchListConfigModel {
		return MatchListConfigModel.fromJson(this.json);
	}
}
