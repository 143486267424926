import { SelectMenuOptionType } from '../../../../models/v2/general/select.model';
import AuthorModel from '../../../../models/v2/author/author.model';
import { assetsPlaceholder } from '../../../../constants/assetsPlaceholder';

export const authorsToOptions = (authors: AuthorModel[]) => {
	if (authors && authors.length > 0) {
		return authors.filter((author: AuthorModel) => author && author.active).map((author: AuthorModel) => returnMappedAuthors(author));
	}
	return [];
};

export const returnMappedAuthors = (author: AuthorModel) => {
	if (author) {
		return {
			value: author.id,
			label: author.name,
			logo: author.avatarImage ? author.avatarImage : assetsPlaceholder.player,
			data: author,
		};
	}
	return {};
};

export const selectedAuthorsToOptions = (authors: AuthorModel[]) => {
	if (authors && authors.length > 0) {
		return authors
			.filter((author: AuthorModel) => author && author.active)
			.map((author: AuthorModel) => {
				return author.avatarImage || author.avatar_image ? returnMappedAuthors(author) : returnSelectedMappedAuthors(author);
			});
	}
	return [];
};

export const returnSelectedMappedAuthors = (author: AuthorModel) => {
	if (author) {
		return {
			value: author.id,
			label: author.name,
			logo: author.avatarUrl ? author.avatarUrl : assetsPlaceholder.player,
			data: author,
		};
	}
	return {};
};

export const authorsFromOptionSelections = (selections: SelectMenuOptionType[]) => {
	return selections ? selections.map((selection: SelectMenuOptionType) => selection.data) : [];
};
