import React from 'react';
import { ContentTypes } from '../constants/block.types';
import BlockModel from '../models/block.model';
import ContentBlock from '../blocks/content/content-block/content-block.component';
import GalleryBlock from '../blocks/content/gallery-block/gallery-block.component';
import ImageBlock from '../blocks/content/image-block/image-block.component';
import LinkBlock from '../blocks/content/link-block/link-block.component';
import BannerBlock from '../blocks/content/banner-block/banner-block.component';
import HighlightBlock from '../blocks/content/highlight-block/highlight-block.component';
import EmbedBlock from '../blocks/content/embed-block/embed-block.component';
import { ViewTypes } from '../constants/general.constants';
import DugoutVideoBlock from '../blocks/content/dugout-video-block/dugout-video-block.component';
import OnnetworkVideoBlock from '../blocks/content/onnetwork-video-block/onnetwork-video-block.component';
import AudioBlock from '../blocks/content/audio-block/audio-block.component';
import ImagoImageBlock from '../blocks/content/imago-image-block/imago-image-block.component';
import InstatTeamComparisonBlock from '../blocks/content/instat/instat-team-comparison-block/instat-team-comparison-block.component';
import InstatPlayerComparisonBlock from '../blocks/content/instat/instat-player-comparison-block/instat-player-comparison-block.component';
import InstatPlayerPerformanceSeasonBlock from '../blocks/content/instat/instat-player-performance-season-block/instat-player-performance-season-block.component';
import InstatTeamPerformanceSeasonBlock from '../blocks/content/instat/instat-team-performance-season-block/instat-team-performance-season-block.component';
import InstatPostMatchTeamsStatsBlock from '../blocks/content/instat/instat-post-match-teams-stats-block/instat-post-match-teams-stats-block.component';
import InstatPostMatchPlayersStatsBlock from '../blocks/content/instat/instat-post-match-players-stats-block/instat-post-match-players-stats-block.component';
import { YoutubeSportsBlock } from '../blocks/content/youtube-sports-block/youtube-sports-block.component';
import { AmgBlockComponent } from '../blocks/content/amg-block/amg-video-block.component';
import GettyImageBlockComponent from '../../../v2/getty/components/blocky/getty-image-block.component';

export default class ContentBlocksGenerator {
	public createBlock = (
		data: BlockModel,
		t?: any,
		contentData?: any,
		blocks?: BlockModel[],
		contentLanguage?: any,
		entityType?: any,
		contentType?: any,
		view: ViewTypes = ViewTypes.edit,
	) => {
		switch (data.type) {
			case ContentTypes.article: {
				return <ContentBlock view={view} block={data} t={t} contentLanguage={contentLanguage} entityType={entityType} />;
			}

			case ContentTypes.banner: {
				return <BannerBlock view={view} block={data} t={t} />;
			}

			case ContentTypes.createGallery: {
				return null;
			}

			case ContentTypes.instatTeamComparison: {
				return <InstatTeamComparisonBlock view={view} block={data} t={t} />;
			}

			case ContentTypes.instatPlayerComparison: {
				return <InstatPlayerComparisonBlock view={view} block={data} t={t} />;
			}

			case ContentTypes.instatPlayerSeasonPerformance: {
				return <InstatPlayerPerformanceSeasonBlock view={view} block={data} t={t} />;
			}

			case ContentTypes.instatTeamSeasonPerformance: {
				return <InstatTeamPerformanceSeasonBlock view={view} block={data} t={t} />;
			}

			case ContentTypes.instatPostMatchTeamsStats: {
				return <InstatPostMatchTeamsStatsBlock view={view} block={data} t={t} />;
			}

			case ContentTypes.instatPostMatchPlayersStats: {
				return <InstatPostMatchPlayersStatsBlock view={view} block={data} t={t} />;
			}

			case ContentTypes.embed: {
				return <EmbedBlock view={view} block={data} t={t} />;
			}

			case ContentTypes.gallery: {
				return <GalleryBlock view={view} block={data} t={t} contentData={contentData} contentLanguage={contentLanguage} />;
			}

			case ContentTypes.highlight: {
				return <HighlightBlock view={view} block={data} t={t} />;
			}

			case ContentTypes.image: {
				return <ImageBlock view={view} block={data} t={t} contentData={contentData} />;
			}

			case ContentTypes.link: {
				return <LinkBlock view={view} block={data} t={t} />;
			}

			case ContentTypes.video: {
				return <ContentBlock view={view} block={data} t={t} contentLanguage={contentLanguage} entityType={entityType} />;
			}

			case ContentTypes.poll: {
				return <ContentBlock view={view} block={data} t={t} />;
			}

			case ContentTypes.dugoutVideo: {
				return <DugoutVideoBlock view={view} block={data} t={t} blocks={blocks} contentData={contentData} />;
			}

			case ContentTypes.onnetworkVideo: {
				return <OnnetworkVideoBlock view={view} block={data} t={t} blocks={blocks} contentData={contentData} />;
			}

			case ContentTypes.audioBlock: {
				return <AudioBlock view={ViewTypes.edit} block={data} t={t} blocks={blocks} contentData={contentData} />;
			}

			case ContentTypes.imago: {
				return <ImagoImageBlock view={view} block={data} t={t} blocks={blocks} contentData={contentData} />;
			}

			case ContentTypes.youtubeSports: {
				return <YoutubeSportsBlock view={view} block={data} t={t} contentData={contentData} />;
			}

			case ContentTypes.amgBlock: {
				return <AmgBlockComponent view={view} block={data} blocks={blocks} t={t} />;
			}

			case ContentTypes.getty: {
				return <GettyImageBlockComponent view={view} block={data} />;
			}

			default: {
				return null;
			}
		}
	};
}
