import * as React from 'react';
import { Link } from 'react-router-dom';
import { analyticsService, featuresService } from '../../../../../../App';
import { FeatureTypes } from '../../../../../../services/feature-service/features.enum';
import { isJWSection } from '../../../../../Resources/Videos/Helpers/VideoHelper';
import { getListTypeCreateUrl } from '../../../../../Resources/Lists/helpers/list.helper';

export type NewContentButton = {
	t: any;
	text?: string;
	url?: string;
	onNewContent?: () => any;
	analytics?: any;
	location?: string;
	contentType?: string;
};

export const NewContentArticle: React.FunctionComponent<NewContentButton> = ({ t, analytics }) => {
	return (
		<React.Fragment>
			<Link
				to={'/smp/articles/create'}
				id='new-content-article'
				className='btn btn-primary'
				onClick={() => {
					analyticsService.sendEvent('Click', 'List Page', 'Create Button');
				}}
			>
				<i className={'fa fa-plus-circle'}>&nbsp;</i>
				{t('new_article')}
			</Link>
		</React.Fragment>
	);
};

export const NewContentVideo: React.FunctionComponent<NewContentButton> = ({ t, analytics }) => {
	return isJWSection() ? null : (
		<React.Fragment>
			<Link
				to={'/smp/videos/create'}
				id='new-content-video'
				className='btn btn-primary'
				onClick={() => {
					analytics.sendEvent('Click', 'List Page', 'Create Button');
				}}
			>
				<i className={'fa fa-plus-circle'}>&nbsp;</i>
				{t('new_video')}
			</Link>
		</React.Fragment>
	);
};

export const NewContentGallery: React.FunctionComponent<NewContentButton> = ({ t, analytics }) => {
	return (
		<React.Fragment>
			<Link
				to={'/smp/galleries/create'}
				id='new-content-gallery'
				className='btn btn-primary'
				onClick={() => {
					analytics.sendEvent('Click', 'List Page', 'Create Button');
				}}
			>
				<i className={'fa fa-plus-circle'}>&nbsp;</i>
				{t('new_gallery')}
			</Link>
		</React.Fragment>
	);
};

export const NewContentBanner: React.FunctionComponent<NewContentButton> = ({ t, analytics }) => {
	return (
		<React.Fragment>
			<Link
				to={'/smp/banners/create'}
				id='new-content-banner'
				className='btn btn-primary'
				onClick={() => {
					analytics.sendEvent('Click', 'List Page', 'Create Button');
				}}
			>
				<i className={'fa fa-plus-circle'}>&nbsp;</i>
				{t('new_banner')}
			</Link>
		</React.Fragment>
	);
};

export const NewContentList: React.FunctionComponent<NewContentButton> = ({ t, analytics, location, contentType }) => {
	const contentPath = getListTypeCreateUrl();
	return (
		<React.Fragment>
			<Link
				to={contentPath}
				id='new-content-list'
				className='btn btn-primary'
				onClick={() => {
					analytics.sendEvent('Click', 'List Page', 'Create Button');
				}}
			>
				<i className={'fa fa-plus-circle'}>&nbsp;</i>
				{t('new_list')}
			</Link>
		</React.Fragment>
	);
};

export const NewContentAuthor: React.FunctionComponent<NewContentButton> = ({ t, analytics }) => {
	return (
		<React.Fragment>
			<Link
				to={'/smp/authors/create'}
				id='new-content-author'
				className='btn btn-primary'
				onClick={() => {
					analytics.sendEvent('Click', 'List Page', 'Create Button');
				}}
			>
				<i className={'fa fa-plus-circle'}>&nbsp;</i>
				{t('new_author')}
			</Link>
		</React.Fragment>
	);
};

export const NewContentTag: React.FunctionComponent<NewContentButton> = ({ t, analytics }) => {
	return (
		<React.Fragment>
			<Link
				to={'/smp/tags/create'}
				id='new-content-tag'
				className='btn btn-primary'
				onClick={() => {
					analytics.sendEvent('Click', 'List Page', 'Create Button');
				}}
			>
				<i className={'fa fa-plus-circle'}>&nbsp;</i>
				{t('new_tag')}
			</Link>
		</React.Fragment>
	);
};

export const NewContentCategory: React.FunctionComponent<NewContentButton> = ({ t, analytics }) => {
	return (
		<React.Fragment>
			<Link
				to={'/smp/categories/create'}
				id='new-content-category'
				className='btn btn-primary'
				onClick={() => {
					analytics.sendEvent('Click', 'List Page', 'Create Button');
				}}
			>
				<i className={'fa fa-plus-circle'}>&nbsp;</i>
				{t('new_category')}
			</Link>
		</React.Fragment>
	);
};

export const NewContentPoll: React.FunctionComponent<NewContentButton> = ({ t, analytics }) => {
	return (
		<React.Fragment>
			<Link
				to={'/smp/polls/create'}
				id='new-content-poll'
				className='btn btn-primary'
				onClick={() => {
					analytics.sendEvent('Click', 'List Page', 'Create Button');
				}}
			>
				<i className={'fa fa-plus-circle'}>&nbsp;</i>
				{t('new_poll')}
			</Link>
		</React.Fragment>
	);
};

export const NewContentFeed: React.FunctionComponent<NewContentButton> = ({ t, analytics }) => {
	return (
		<React.Fragment>
			<Link
				to={'/smp/news-tracker/create'}
				id='new-content-feed'
				className='btn btn-primary'
				onClick={() => {
					analytics.sendEvent('Click', 'Feed Page', 'Create Button');
				}}
			>
				<i className={'fa fa-plus-circle'}>&nbsp;</i>
				{t('new_feed')}
			</Link>
		</React.Fragment>
	);
};

export const NewContentTeam: React.FunctionComponent<NewContentButton> = ({ t, analytics }) => {
	if (featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.LIMIT_FOOTBALL_MANUAL_DATA)) {
		return null;
	}

	return (
		<React.Fragment>
			<Link
				to={'/smp/teams/create'}
				className='btn btn-primary'
				onClick={() => {
					analytics.sendEvent('Click', 'Feed Page', 'Create Button');
				}}
			>
				<i className={'fa fa-plus-circle'}>&nbsp;</i>
				{t('new_team')}
			</Link>
		</React.Fragment>
	);
};

export const NewContentPlayer: React.FunctionComponent<NewContentButton> = ({ t, analytics }) => {
	return (
		<React.Fragment>
			<Link
				to={'/smp/players/create'}
				className='btn btn-primary'
				onClick={() => {
					analytics.sendEvent('Click', 'Feed Page', 'Create Button');
				}}
			>
				<i className={'fa fa-plus-circle'}>&nbsp;</i>
				{t('new_player')}
			</Link>
		</React.Fragment>
	);
};

export const NewContentCoach: React.FunctionComponent<NewContentButton> = ({ t, analytics }) => {
	return (
		<React.Fragment>
			<Link
				to={'/smp/coaches/create'}
				className='btn btn-primary'
				onClick={() => {
					analytics.sendEvent('Click', 'Coach Page', 'Create Button');
				}}
			>
				<i className={'fa fa-plus-circle'}>&nbsp;</i>
				{t('new_coach')}
			</Link>
		</React.Fragment>
	);
};

export const NewContentTournamentGroups: React.FunctionComponent<NewContentButton> = ({ t, analytics }) => {
	return (
		<React.Fragment>
			<Link
				to={'/smp/tournament-groups/create'}
				className='btn btn-primary'
				onClick={() => {
					analytics.sendEvent('Click', 'Feed Page', 'Create Button');
				}}
			>
				<i className={'fa fa-plus-circle'}>&nbsp;</i>
				{t('new_tournament_order')}
			</Link>
		</React.Fragment>
	);
};
