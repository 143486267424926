import { Col, FormGroup, Label, Row } from 'reactstrap';
import React from 'react';
import AsyncSelect from 'react-select/async';
import debounce from 'lodash.debounce';
import { Properties, State } from './properties/content-edit.properties';
import { checkDuplicateContentId, constructContentEntitySearchUrl, contentToOption } from './helpers/content-blocks.helper';
import HttpService from '../../../../../../services/rest/HttpService';
import { blockManagementService, blockWrapperService } from '../../../subcomponents/blocky.component';
import ContentWidget from './models/content-widget.model';
import BlockValidation from '../../../helpers/block-validation.helper';
import ErrorHandler from '../../../partials/error/error-handler-component';
import { ContentTypes } from '../../../constants/block.types';
import StartAt from './start-at.component';
import { ImageProperties } from '../../../partials/image-properties/image-properties.component';
import PreviewContent from './subcomponents/preview-content.components';
import { multiLingualService } from '../../../../../../App';
import { customOptionInBlocky } from '../../../../Sidebar/Media/subcomponents/media-content/helpers/MainContentMediaContainer';

class ContentWidgetEdit extends React.Component<Properties, State> {
	constructor(props: any) {
		super(props);
		this.state = {
			isValid: true,
			startAt: props.block.data ? props.block.data.startAt : '00:00:00',
			position:
				props.block.data.preview && props.block.data.preview.previewContent && props.block.data.preview.previewContent.position
					? props.block.data.preview.previewContent.position
					: ContentWidget.builder(props.content).withWidth('100').withAlignment('center').build(),
		};
	}

	componentDidMount(): void {
		blockWrapperService.registerOnSaveFunc({ blockId: this.props.block.id, func: this.onSave });
	}

	onSave = (index: number) => {
		const data = { contentId: this.props.content.id };
		const validation = new BlockValidation();
		const isValid = validation.validate(this.props.block, data);
		this.setState({ ...this.state, isValid });

		if (isValid) {
			const block = Object.assign({}, this.props.block);
			block.data.id = this.props.content.id;
			block.data.preview = { previewContent: { position: this.state.position } };
			block.data.startAt = this.state.startAt;
			blockManagementService.blockUpdate(block, index);
		} else {
			return isValid;
		}
	};

	componentWillUnmount(): void {
		blockWrapperService.unregisterOnSaveFunc(this.props.block.id);
	}

	searchContent = (search: string, callback: any) => {
		const headers = { Project: this.props.currentProject.domain };
		const entityType = this.props.block.type + 's';
		const type = this.props.entityType;
		if (
			multiLingualService.checkIfProjectIsMultiLingual(this.props.currentProject.languages) &&
			multiLingualService.checkIfContentTypeIsValidForMultiLingual(type)
		) {
			HttpService.get(
				multiLingualService.constructContentEntityMultiLingualSearchUrl(search, entityType, this.props.contentLanguage),
				null,
				headers,
			)
				.then((response: any) => {
					checkDuplicateContentId(response, callback);
				})
				.catch((e: any) => e);
		} else {
			HttpService.get(constructContentEntitySearchUrl(search, entityType), null, headers)
				.then((response: any) => {
					checkDuplicateContentId(response, callback);
				})
				.catch((e: any) => e);
		}
	};

	updateContent(content: ContentWidget) {
		this.props.updateContent(content);
	}

	private onStartAtChange = (startAt: string) => {
		this.setState({
			...this.state,
			startAt,
		});
	};

	updatePositionState = (position: ContentWidget) => {
		this.setState({ ...this.state, position });
		if (position.width) {
			this.props.updateContent(ContentWidget.builder(this.props.content).withWidth(position.width).build());
		}
		if (position.alignment) {
			this.props.updateContent(ContentWidget.builder(this.props.content).withAlignment(position.alignment).build());
		}
	};

	render() {
		const { startAt } = this.state;
		const { block } = this.props;

		return (
			<div className={'mb-2'}>
				<div>
					<Row>
						<Col>
							<FormGroup>
								<Label>{this.props.t(`choose_${this.props.block.type}`)}:</Label>
								<AsyncSelect
									className={'mb-2'}
									noOptionsMessage={(inputValue) => inputValue && this.props.t('no_options')}
									loadOptions={debounce(this.searchContent, 500)}
									value={contentToOption(this.props.content)}
									onChange={(selection: any) => {
										this.updateContent(selection.content);
									}}
									formatOptionLabel={customOptionInBlocky}
								/>
								{!this.state.isValid && !this.props.content.id && <ErrorHandler errorMessage='field_is_required' />}
							</FormGroup>
						</Col>
					</Row>
					<StartAt t={this.props.t} display={block.type === ContentTypes.video} onChange={this.onStartAtChange} time={startAt} />
					{block.type === ContentTypes.video && (
						<>
							<PreviewContent t={this.props.t} videoUrls={this.props.content.videoUrls} mainMedia={this.props.content.mainMedia} />

							<ImageProperties
								isImageBlock={false}
								contentType={'content'}
								t={this.props.t}
								blockId={this.props.block.id}
								blockData={this.state.position}
								authors={[]}
								onChange={this.updatePositionState}
								displayDescriptionCheckbox={false}
							/>
						</>
					)}
				</div>
			</div>
		);
	}
}

export default ContentWidgetEdit;
