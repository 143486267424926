import React from 'react';
import Select from 'react-select';
import { Col, Label, Row } from 'reactstrap';
import HttpService from '../../../../../services/rest/HttpService';
import { Properties, State } from './properties/season-multi-select.properties';
import { contentToOptions, selectionsToSeasons, remapOptions } from './helpers/season-multi-select.helper';

export default class SeasonMultiSelectContainer extends React.Component<Properties, State> {
	constructor(props: Properties) {
		super(props);
		this.state = {
			seasonsOptions: [],
		};
	}

	componentDidMount() {
		if (this.props.tournamentIds) {
			this.getTournamentsSeasons(this.props.tournamentIds);
		}
	}

	componentDidUpdate(prevProps: Properties) {
		if (prevProps.tournamentIds !== this.props.tournamentIds) {
			if (this.props.tournamentIds.length > 0) {
				this.getTournamentsSeasons(this.props.tournamentIds);
			} else {
				this.updateSeasonsState([]);
			}
		}
	}

	getTournamentsSeasons = (tournamentIds: any) => {
		if (tournamentIds && tournamentIds.length > 0) {
			let promises = [];
			let options: any = [];
			promises = tournamentIds.map((tournament: any) => HttpService.getTournamentById(tournament.id));

			Promise.all(promises)
				.then((response: any) => {
					options = response.map((tournament: any) => {
						return {
							label: tournament.data.name,
							options: contentToOptions(remapOptions(tournament.data.seasons, tournament.data.name)),
						};
					});
					this.updateSeasonsState(options);
				})
				.catch((e: any) => e);
		}
	};

	updateSeasonsState = (seasonsOptions: any) => {
		this.setState({
			seasonsOptions,
		});
	};

	onSeasonSelect = (selections: any) => {
		this.props.onSeasonSelect(selectionsToSeasons(selections));
	};

	render() {
		const { t, selectedSeasons, tournamentIds } = this.props;
		if (tournamentIds === undefined || tournamentIds === null || tournamentIds.length < 1) {
			return null;
		}

		if (this.state.seasonsOptions) {
			return (
				<Row>
					<Col>
						<Label>{t('season')}:</Label>
						<Select options={this.state.seasonsOptions} isMulti value={contentToOptions(selectedSeasons)} onChange={this.onSeasonSelect} />
					</Col>
				</Row>
			);
		}

		return null;
	}
}
