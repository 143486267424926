import { jsonObject, jsonMember, jsonArrayMember } from 'typedjson';
import { assetsPlaceholder } from '../../constants/assetsPlaceholder';

@jsonObject
class BookmakerAssets {
	@jsonMember(String)
	public logo: string = '';
}

@jsonObject
export default class SportOddsBookmakerModel {
	@jsonMember(String, { name: 'bookmakerId' })
	public id: string = '';

	@jsonMember(String, { name: 'bookmakerName' })
	public name: string = '';

	@jsonMember(String)
	public homepageUrl: string = '';

	@jsonMember(String)
	public logo: string = '';

	@jsonMember(Number)
	public order: number = 0;

	@jsonMember(String)
	public provider: string = '';

	@jsonArrayMember(BookmakerAssets)
	public assets: BookmakerAssets[] = [];
}
