import { relatedConstants } from '../../../../../constants/related.constants';
import { SportTypes } from '../../../../../constants/sport.types';
import Related from '../../../../../models/related/Related';
import SeasonResponseModel from '../../../../../models/v2/Season/Entity/response-season.model';
import { responseToResponseTournamentModel } from '../../../../../models/v2/Tournament/Entity/response-tournament.mapper';
import TournamentResponseModel from '../../../../../models/v2/Tournament/Entity/response-tournament.model';
import { extractProviderBySportType } from './utils';
import SportsTypesModel from '../../../../../models/v2/sports-types/sports-types.model';

export const seasonsToOptions = (seasons: SeasonResponseModel[] | null) => {
	if (seasons && seasons.length > 0) {
		return seasons.map((season: SeasonResponseModel) => {
			return { value: season.id, label: season.name, data: season };
		});
	}

	return null;
};

export const tournamentToOptions = (tournaments: TournamentResponseModel[] | null) => {
	if (tournaments && tournaments.length > 0) {
		return tournaments.map((tournament: TournamentResponseModel) => {
			return { value: tournament.id, label: tournament.name, data: tournament };
		});
	}

	return null;
};

export const extractSeasonModelByReduxStoredProperty = (tournamentWithSeasonRedux: Related | null): SeasonResponseModel | null => {
	if (tournamentWithSeasonRedux && tournamentWithSeasonRedux.type === relatedConstants.types.season) {
		return {
			id: tournamentWithSeasonRedux.data.id,
			name: tournamentWithSeasonRedux.data.name,
			active: tournamentWithSeasonRedux.data.active || false,
		} as SeasonResponseModel;
	}

	return null;
};

export const extractTournamentModelByReduxStoredProperty = (tournamentWithSeasonRedux: Related | null): TournamentResponseModel | null => {
	if (tournamentWithSeasonRedux && tournamentWithSeasonRedux.type === relatedConstants.types.season) {
		const tournamentData = tournamentWithSeasonRedux.data.tournament;
		if (tournamentData) {
			return responseToResponseTournamentModel(tournamentData);
		}
	}

	return null;
};

export const generateSeasonAndTournamentForRedux = (
	selectedSeason: SeasonResponseModel | null,
	selectedTournament: TournamentResponseModel | null,
	selectedSport: SportsTypesModel,
): Related | null => {
	if (selectedSeason && selectedTournament) {
		return {
			provider: extractProviderBySportType(selectedSport),
			type: relatedConstants.types.season,
			data: { ...selectedSeason, tournament: selectedTournament },
		} as Related;
	}
	return null;
};
