import React from 'react';
import { Button, Col, Label, Row } from 'reactstrap';
import { ContentTypes } from '../../../constants/content-types';
import { withTranslation } from 'react-i18next';
import { Title } from '../../Partials/Fields/title/TitleComponent';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Project from '../../../models/project/Project';
import Tag from '../../../models/tag/Tag';
import { Properties, State } from './Properties/TagCreateProperties';
import Urls from '../../Partials/Sidebar/Urls/urls.component';
import {
	returnObjectForTagEntityCreate,
	TAG_ENTITY_CREATE,
	TAG_ENTITY_CREATE_FAILED,
	TAG_ENTITY_CREATE_SUCCESS,
} from '../../../store/action-creators/TagActionCreators';
import { Description } from '../../Partials/Fields/description/DescriptionComponent';
import { SeoComponentModel } from '../../Partials/Sidebar/Seo/models/SeoComponentModel';
import {
	checkIfValidForSave,
	seoComponentModelToTag,
	tagToSeoComponentModel,
	tagToUrlsComponentModelForEdit,
	urlsComponentModelToCategory,
} from './Helpers/TagHelper';
import Seo from '../../Partials/Sidebar/Seo/SeoComponent';
import { UrlsComponentModel } from '../../Partials/Sidebar/Urls/models/UrlsComponentModel';
import MainMedia from '../../../models/mainmedia/MainMedia';
import MainMediaContainer from '../../Partials/Sidebar/Media/MainMediaListContainer';
import BlockableButtonWrapper from '../../Partials/BaseComponents/BlockableButton/BlockableButtonWrapper';
import SeoModel from '../../../models/seo/Seo';
import SeoBuilder from '../../../models/seo/SeoBuilder';
import SidebarCollapsibleElements from '../../Partials/Sidebar/sidebar-collapsible-elements/sidebar-collapsible-elements';
import GeneralContentTypeAttribute from '../../Partials/Sidebar/general-content-attribute-custom/general-content-attribute-custom';
import { GeneralContentCustomModel } from '../../Partials/Sidebar/general-content-attribute-custom/models/general-content-attribute-custom.model';
import SidebarElementsToggle from '../../Partials/Sidebar/sidebar-collapsible-elements/sidebar-elements-toggle/sidebar-elements-toggle.component';
import { OrderTypeSelect } from '../../Partials/Fields/tag-order-type/order-type-select.component';
import EnumItem from '../../../models/enum/EnumItem';
import HttpService from '../../../services/rest/HttpService';
import {
	ContentSidebarChangeCollapseStatus,
	onChangeCollapseStatus,
	onSidebarElemSort,
	toggleSidebarEditMode,
} from '../../../services/content-sidebar/content-sidebar.helper';
import { FormattedSidebarSectionValue } from '../../Partials/Sidebar/sidebar-collapsible-elements/utils/temp-sidebar.types';
import GlobalModal from '../../Partials/Modals/Global/global-modal';
import {
	appendBeforeUnloadResetTempSidebar,
	defaultTempSidebarFromApiResponse,
	overwriteTempSidebarByContentType,
	saveSidebarSettingsByContentType,
} from '../../Partials/Sidebar/sidebar-collapsible-elements/utils/temp-sidebar.helper';

class TagCreate extends React.Component<Properties, State> {
	initPageTitle() {
		document.title = this.props.t('tag_create');
	}

	constructor(props: Properties) {
		super(props);
		this.state = {
			isSidebarInEdit: false,
			tag: Tag.builder()
				.withSeo(new SeoBuilder().withFollow(true).withIndex(true).build())
				.withOrderType({
					id: 'ordered',
					name: 'ordered',
				} as EnumItem)
				.build(),
			orderedTags: 0,
			openSidebarSettingsModalFlag: false,
		};
	}

	componentDidMount(): void {
		let headers = { Project: this.props.project.domain };
		let totalOrderedNumber;
		this.initPageTitle();
		this.initTagCreateSuccessListener();
		appendBeforeUnloadResetTempSidebar(ContentTypes.TAG);

		HttpService.get(`/tags?order_type=ordered`, null, headers).then((response: any) => {
			if (response && response.data) {
				totalOrderedNumber = response.data.meta && response.data.meta.pagination && response.data.meta.pagination.total;
				this.setState({ orderedTags: totalOrderedNumber });
			}
		});
	}

	initTagCreateSuccessListener() {
		window.addEventListener(TAG_ENTITY_CREATE_SUCCESS, (data: any) => {
			this.props.history.push(`/smp/tags/edit/${data.detail}`);
		});
	}

	onTitleChange(title: string) {
		const seo = SeoModel.builder(this.state.tag.seo).withAutoUpdateSeo(title).build();
		this.setState({ tag: Tag.builder(this.state.tag).withSeo(seo).withTitle(title).build() });
	}

	onOrderTypeChange(orderType: any) {
		this.setState({ ...this.state, tag: Tag.builder(this.state.tag).withOrderType(orderType).build() });
	}

	onDescriptionChange(description: string) {
		this.setState({ tag: Tag.builder(this.state.tag).withDescription(description).build() });
	}

	// TODO refactor onSeoChange for setting default values
	onSeoChange(seoModel: SeoComponentModel) {
		setTimeout(() => {
			const tag = seoComponentModelToTag(seoModel, this.state.tag);
			this.setState({ tag });
		}, 1000);
	}

	onMainMediaChange(mainMedia: MainMedia[]) {
		let tag = Tag.builder(this.state.tag).withMainMedia(mainMedia).build();
		this.setState({ tag });
	}

	onUrlsChange(urlsModel: UrlsComponentModel) {
		const tag = urlsComponentModelToCategory(urlsModel, this.state.tag);
		this.setState({ tag });
	}

	onSubmit() {
		this.props.postTag(this.state.tag, this.props.project);
	}

	onGeneralContentChange(data: GeneralContentCustomModel) {
		let tag = Tag.builder(this.state.tag)
			.withType(data.type ? data.type : '')
			.build();
		this.setState({ ...this.state, tag });
	}

	toggleSidebarEditMode = () => {
		const newState = toggleSidebarEditMode({ ...this.state }, ContentTypes.TAG);
		this.setState(newState);
	};

	render() {
		const { t, project, types } = this.props;
		const { tag, orderedTags, openSidebarSettingsModalFlag } = this.state;
		const seoModel = tagToSeoComponentModel(this.state.tag);
		const urlsModel = tagToUrlsComponentModelForEdit(this.state.tag);
		const sidebarComponentsMap = {
			general: (
				<GeneralContentTypeAttribute
					onChange={this.onGeneralContentChange.bind(this)}
					value={{ type: this.state.tag.type, status: '' }}
					contentType={ContentTypes.TAG}
					isNewContent={true}
				/>
			),
			media: (
				<MainMediaContainer
					applyWatermark={false}
					onChange={this.onMainMediaChange.bind(this)}
					value={this.state.tag.mainMedia}
					currentProject={project}
					contentType={ContentTypes.TAG}
				/>
			),
			urls: <Urls contentType={ContentTypes.TAG} onChange={this.onUrlsChange.bind(this)} value={urlsModel} />,
			seo: <Seo onChange={this.onSeoChange.bind(this)} value={seoModel} />,
		};

		return (
			<div className='animated fadeIn'>
				<Row>
					<Col col='8' lg='8' md='12' sm='12' xs='12'>
						<div className='card'>
							<div className='card-header'>
								<div className={'d-flex align-items-center'}>
									<span className={'mr-auto mb-0'}>{t('tag_create_title')}</span>
									{checkIfValidForSave(tag, orderedTags) ? (
										<Button
											disabled={true}
											size='sm'
											color='primary'
											id='tag-create-save-top'
											className={'ml-auto'}
											onClick={this.onSubmit.bind(this)}
										>
											<i className='fa fa-floppy-o'></i> {t('save_tag')}
										</Button>
									) : (
										<BlockableButtonWrapper
											blockOnActions={[TAG_ENTITY_CREATE]}
											releaseOnActions={[TAG_ENTITY_CREATE_FAILED, TAG_ENTITY_CREATE_SUCCESS]}
										>
											<Button size='sm' color='primary' id='tag-create-save-top' className={'ml-auto'} onClick={this.onSubmit.bind(this)}>
												<i className='fa fa-floppy-o'></i> {t('save_tag')}
											</Button>
										</BlockableButtonWrapper>
									)}
								</div>
							</div>
							<div className='card-body'>
								<Row>
									<Col xs='12'>
										<Label htmlFor='title'>{t('title')}</Label>
										<Title t={t} onChange={this.onTitleChange.bind(this)} value={this.state.tag.title} />
									</Col>
								</Row>
								<Row>
									<Col xs='12'>
										<Label htmlFor='order_type'>{t('order_type')}</Label>
										<OrderTypeSelect t={t} onChange={this.onOrderTypeChange.bind(this)} value={tag.orderType} />
									</Col>
									{checkIfValidForSave(tag, orderedTags) && (
										<Row style={{ marginTop: '-25px', marginLeft: '5px' }}>
											<div className={'alert alert-danger'}>{this.props.t('ordered_tags_limit_exceeded')}</div>
										</Row>
									)}
								</Row>
								<Row>
									<Col xs='12'>
										<Description t={t} onChange={this.onDescriptionChange.bind(this)} showValidation={true} value={this.state.tag.description} />
									</Col>
								</Row>
								{checkIfValidForSave(tag, orderedTags) ? (
									<Row className={'mt-4'}>
										<Col xs='12'>
											<Button
												disabled={true}
												size='sm'
												color='primary'
												id='tag-create-save-top'
												className={'ml-auto'}
												onClick={this.onSubmit.bind(this)}
											>
												<i className='fa fa-floppy-o'></i> {t('save_tag')}
											</Button>
										</Col>
									</Row>
								) : (
									<Row className={'mt-4'}>
										<Col xs='12'>
											<BlockableButtonWrapper
												blockOnActions={[TAG_ENTITY_CREATE]}
												releaseOnActions={[TAG_ENTITY_CREATE_FAILED, TAG_ENTITY_CREATE_SUCCESS]}
											>
												<Button size='sm' color='primary' id='tag-create-save-top' className={'ml-auto'} onClick={this.onSubmit.bind(this)}>
													<i className='fa fa-floppy-o'></i> {t('save_tag')}
												</Button>
											</BlockableButtonWrapper>
										</Col>
									</Row>
								)}
							</div>
						</div>
					</Col>
					<Col col='4' lg='4' md='12' sm='12' xs='12' className='position-relative'>
						<SidebarElementsToggle t={t} toggleSidebarEditMode={this.toggleSidebarEditMode} isSidebarInEdit={this.state.isSidebarInEdit} />
						<SidebarCollapsibleElements
							isSidebarInEdit={this.state.isSidebarInEdit}
							onElemSort={(settingsValue: FormattedSidebarSectionValue[]) => onSidebarElemSort(settingsValue, ContentTypes.TAG)}
							onChangeCollapseStatus={(data: ContentSidebarChangeCollapseStatus) => onChangeCollapseStatus(data)}
							contentType={ContentTypes.TAG}
							sidebarComponentsMap={sidebarComponentsMap}
							t={t}
							types={types[`${ContentTypes.TAG}Types`]}
							statuses={[]}
						/>
					</Col>
				</Row>
				<GlobalModal
					isOpen={openSidebarSettingsModalFlag}
					t={this.props.t}
					headerContent='save_sidebar_settings'
					bodyContent='unsaved_changes'
					submitFunction={() => saveSidebarSettingsByContentType(ContentTypes.TAG, t)}
					beforeCloseFunction={() =>
						overwriteTempSidebarByContentType(ContentTypes.TAG, defaultTempSidebarFromApiResponse[ContentTypes.TAG].value)
					}
				/>
			</div>
		);
	}
}

function mapStateToProps(state: any) {
	return {
		tag: state.tag,
		project: state.project.currentProject,
		profile: state.profile.profile,
		types: state.types,
	};
}

function mapDispatchToProps(dispatch: any) {
	return {
		postTag: (tag: Tag, project: Project) => dispatch(returnObjectForTagEntityCreate(tag, project)),
	};
}

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(TagCreate) as React.ComponentType;
