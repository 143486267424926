import React, { useEffect, useState } from 'react';
import { featuresService } from '../../../../../../../App';
import { FeatureTypes } from '../../../../../../../services/feature-service/features.enum';
import { Col, FormGroup, Row } from 'reactstrap';
import MarketSelect from '../../../../partials/market-select/market-select.component';
import { BookmakerSelectComponent } from '../../../partials/bookmaker-select/bookmaker-select.component';
import OddsModelV2 from '../models/odds-widget.model';
import BlockModel from '../../../../models/block.model';
import { SPORT_SELECT_ATTRIBUTES } from '../../helpers/component-attributes.constants';
import { SportTypes } from '../../../../../../../constants/sport.types';
import MarketModel from '../models/v2/market.model';
import { marketOptionsV2 } from '../../../../partials/market-select/helpers/market-select.helpers';
import MatchModelV2 from '../../football-single-event/models/MatchModelV2.model';
import SportOddsBookmakerModel from '../../../../../../../models/sport-odds/sport-odds-bookmaker.model';

type Properties = {
	block: BlockModel;
	t: any;
	onOddsWidgetChange?: any;
	match?: MatchModelV2;
	isValid: boolean;
	currentOdds?: OddsModelV2;
	widgetType?: string;
	displayOddsLabel: boolean;
	isStandAlone: boolean;
	displayMarketSelection?: boolean;
};

export const OddsEditComponent: React.FunctionComponent<Properties> = (props) => {
	const match = props.match ? props.match : new MatchModelV2();
	const bookmakerSelectionEnabled = featuresService.areFeaturesEnabled([FeatureTypes.ALLOW_BOOKMAKER_SELECTION]);
	const [oddsWidgetModel, setOddsWidgetModel] = useState<OddsModelV2>(
		props.currentOdds
			? OddsModelV2.builder(props.currentOdds)
					.withMatch(props.currentOdds.match ? props.currentOdds.match : match)
					.build()
			: OddsModelV2.builder().withMatch(match).withBookmakers(null).withMarket({ name: '1x2', value: '1x2' }).build(),
	);

	useEffect(() => {
		if (props.onOddsWidgetChange && props.match) {
			updateWidgetModelState(OddsModelV2.builder(oddsWidgetModel).withMatch(props.match).build());
		}
	}, [props.match, props.onOddsWidgetChange]);

	const updateWidgetModelState = (model: OddsModelV2) => {
		if (!props.isStandAlone) {
			props.onOddsWidgetChange(model);
		}

		setOddsWidgetModel(model);
	};

	const onBookmakerChange = (bookmakers: SportOddsBookmakerModel[]) => {
		if (bookmakers.length === 0) {
			updateWidgetModelState(
				OddsModelV2.builder(oddsWidgetModel)
					.withBookmakers(bookmakers)
					.withMainBookmaker({} as SportOddsBookmakerModel)
					.withMarket({} as MarketModel)
					.build(),
			);
		} else {
			updateWidgetModelState(OddsModelV2.builder(oddsWidgetModel).withBookmakers(bookmakers).build());
		}
	};

	const onMarketChange = (marketName: string) => {
		updateWidgetModelState(OddsModelV2.builder(oddsWidgetModel).withMarket({ name: marketName, value: marketName }).build());
	};

	return (
		<>
			<div data-qa={`${SportTypes.FOOTBALL}-odds-edit-component`}>
				{props.displayOddsLabel && (
					<Row className='mb-1 mt-4'>
						<Col>
							<h5>{props.t('odds_data')}</h5>
						</Col>
					</Row>
				)}
				{bookmakerSelectionEnabled && (
					<Row>
						<Col>
							<FormGroup>
								<BookmakerSelectComponent
									t={props.t}
									isValid={props.isValid}
									isMulti
									showAllBookmakers
									onSelect={onBookmakerChange}
									bookmakerSelectionEnabled={bookmakerSelectionEnabled}
									eventId={oddsWidgetModel.match && oddsWidgetModel.match.id ? oddsWidgetModel.match.id : null}
									valueList={oddsWidgetModel.bookmakers}
									id={`${SportTypes.FOOTBALL}-${SPORT_SELECT_ATTRIBUTES.BOOKMAKER_SELECT}`}
								/>
							</FormGroup>
						</Col>
					</Row>
				)}
				{bookmakerSelectionEnabled && oddsWidgetModel.bookmakers && oddsWidgetModel.bookmakers.length > 0 && (
					<Row>
						<Col>
							<FormGroup>
								<MarketSelect
									t={props.t}
									value={oddsWidgetModel.market ? oddsWidgetModel.market.name : marketOptionsV2(props.t)[0].value}
									onMarketSelect={onMarketChange}
									limitTo1x2={false}
									id={`${SportTypes.FOOTBALL}-${SPORT_SELECT_ATTRIBUTES.MARKET_SELECT}`}
								/>
							</FormGroup>
						</Col>
					</Row>
				)}
			</div>
		</>
	);
};
