import { DugoutVideoJson } from './dugout-video.json';
import DugoutVideoBuilder from './dugout-video.builder';

export default class DugoutVideoModel {
	readonly id: string = '';
	readonly title: string = '';
	readonly embedCode: string = '';
	readonly thumbnail: string = '';
	readonly hash: string = '';
	readonly description: string = '';

	private constructor(id: string, title: string, embedCode: string, thumbnail: string, hash: string, description: string) {
		this.id = id;
		this.title = title;
		this.embedCode = embedCode;
		this.thumbnail = thumbnail;
		this.hash = hash;
		this.description = description;
	}

	toJson(): DugoutVideoJson {
		return {
			id: this.id,
			title: this.title,
			embedCode: this.embedCode,
			thumbnail: this.thumbnail,
			hash: this.hash,
			description: this.description,
		};
	}

	static fromJSON(json: DugoutVideoJson): DugoutVideoModel {
		return new DugoutVideoModel(json.id, json.title, json.embedCode, json.thumbnail, json.hash, json.description);
	}

	static builder(video?: DugoutVideoModel): DugoutVideoBuilder {
		return new DugoutVideoBuilder(video);
	}
}
