export const liveBlogStatusOptions: string[] = ['ACTIVE', 'ARCHIVED', 'INACTIVE'];

export const liveBlogStatusDropdownOptions = [
	{
		id: '2',
		name: 'ARCHIVED_title',
		slug: 'archived',
		isDefault: false,
	},
	{
		id: '1',
		name: 'active',
		slug: 'active',
		isDefault: false,
	},
	{
		id: '0',
		name: 'inactive',
		slug: 'inactive',
		isDefault: false,
	},
];

export const selectionsToStatuses = (selections: any[]) => {
	if (selections && selections.length > 0) {
		return selections.map((selection: any) => selection.value);
	}

	return [];
};

export const statusesToOptions = (elements: any[], t: any) => {
	if (elements && elements.length > 0) {
		return elements.map((element: string) => statusesToOption(element, t));
	}

	return [];
};

export const statusesToOption = (element: any, t: any) => {
	if (element) {
		return {
			value: element,
			label: t(`${element}_title`),
		};
	}

	return {};
};
