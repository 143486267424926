import React, { useEffect } from 'react';
import Select from 'react-select';
import { Label } from 'reactstrap';
import { marketValueTypesOptions } from './helpers/market-select.helpers';
import { useTranslation } from 'react-i18next';

type Properties = {
	onMarketValueTypeSelect: Function;
	value: any;
	id: string;
};

const MarketValueTypeSelect: React.FunctionComponent<Properties> = ({ value, onMarketValueTypeSelect, id }) => {
	const [t] = useTranslation();

	useEffect(() => {
		if (value && value.length > 0) {
			onMarketValueTypeSelect(value);
		}
	}, []);

	const propertyValueToOption = (value: any) => {
		if (value && value.length > 0) {
			const marketOptionValue = marketValueTypesOptions(t).find((option) => option.value === value);

			if (marketOptionValue) {
				return marketOptionValue;
			}
		}

		return { value: '', label: '' };
	};

	const marketValueTypeSelect = (selection: any) => {
		if (onMarketValueTypeSelect) {
			onMarketValueTypeSelect(selection.value);
		}
	};

	return (
		<>
			<div data-qa={id}>
				<Label htmlFor={id}>{t('choose_market_value_type')}:</Label>
				<Select
					options={marketValueTypesOptions(t)}
					onChange={marketValueTypeSelect}
					value={propertyValueToOption(value)}
					noOptionsMessage={(inputValue) => inputValue && t('no_options')}
					placeholder={t('select')}
					id={id}
				/>
			</div>
		</>
	);
};

export default MarketValueTypeSelect;
