import { USER_LOGOUT } from '../action-creators/ProfileActionCreators';
import PaginationMeta from '../../models/pagination/PaginationMeta';
import {
	TEAM_LISTING_RECEIVED,
	TEAM_ENTITY_RECEIVED,
	SEARCH_TEAM_LISTING_RECEIVED,
	TEAM_BLACKLIST_RECEIVED,
	TEAM_LISTING_WITH_MANUAL_DATA_FILTER_RECEIVED,
	TEAM_SEARCH_FILTER_UPDATE,
} from '../action-creators/team-action-creator';
import TeamManualDataModel from '../../models/team-manual-data/team-manual-data.model';

interface InitialState {
	teams: TeamManualDataModel[];
	pagination: PaginationMeta;
	teamDeleted: boolean;
	team: TeamManualDataModel;
	searchFilter: string;
}

const initialState: InitialState = {
	teams: [],
	team: TeamManualDataModel.builder().build(),
	teamDeleted: false,
	pagination: PaginationMeta.builder().build(),
	searchFilter: '',
};

function teamReducer(state: any = initialState, action: any) {
	if (action.type === TEAM_LISTING_RECEIVED) {
		return Object.assign({}, state, {
			teams: action.payload.teams,
			pagination: action.payload.contentPagination,
		});
	}

	if (action.type === TEAM_LISTING_WITH_MANUAL_DATA_FILTER_RECEIVED) {
		return Object.assign({}, state, {
			teams: action.payload.teams,
			pagination: action.payload.contentPagination,
		});
	}

	if (action.type === TEAM_BLACKLIST_RECEIVED) {
		return Object.assign({}, state, {
			teams: action.payload,
		});
	}

	if (action.type === SEARCH_TEAM_LISTING_RECEIVED) {
		return Object.assign({}, state, {
			teams: action.payload.data,
		});
	}

	if (action.type === TEAM_ENTITY_RECEIVED) {
		return Object.assign({}, state, {
			team: action.payload.team,
		});
	}

	if (action.type === TEAM_SEARCH_FILTER_UPDATE) {
		return Object.assign({}, state, {
			searchFilter: action.payload,
		});
	}

	if (action.type === USER_LOGOUT) {
		state = initialState;
	}

	return state;
}

export default teamReducer;
