import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SortEnd } from 'react-sortable-hoc';
import { toast } from 'react-toastify';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import HttpService from '../../../../services/rest/HttpService';
import { AppState } from '../../../../store/store';
import Tag from '../../../../models/tag/Tag';
import { returnObjectForTagListingRequest } from '../../../../store/action-creators/TagActionCreators';

type ModalState = {
	isShown: boolean;
	data: { tags: Tag[]; shouldUpdate: boolean };
	tagChanged: boolean;
};

type Properties = {
	showModal: ModalState;
	t: any;
	toggleModal: (showModal: ModalState) => void;
	sortEnd: SortEnd;
	currentPage: string;
};

const TagsReorderModal: React.FunctionComponent<Properties> = ({ t, showModal, toggleModal, sortEnd, currentPage }) => {
	const [prevTags, setTags] = useState<Tag[]>([]);

	const { oldIndex, newIndex } = sortEnd;

	const project = useSelector((state: AppState) => state.project.currentProject);
	const dispatch = useDispatch();

	useEffect(() => {
		showModal.isShown && setTags([...showModal.data.tags]);
	}, []);

	const toggleModalState = (shouldUpdate: boolean, tagChanged: boolean) =>
		toggleModal({
			isShown: false,
			data: { tags: prevTags, shouldUpdate: shouldUpdate },
			tagChanged: tagChanged,
		});

	const updateTags = () => {
		// Define previous item index depending on drag direction.
		const prevItemIndex = newIndex !== 0 && newIndex < oldIndex ? newIndex - 1 : newIndex;
		const params = { previous_id: newIndex !== 0 ? prevTags[prevItemIndex] && prevTags[prevItemIndex].id : null };
		HttpService.patch(`/tags/${prevTags[oldIndex].id}`, params, { Project: project.domain })
			.then(() => {
				// Close the modal and get the updated tag.
				dispatch(returnObjectForTagListingRequest('1', project, ''));
				toggleModalState(false, true);
			})
			.catch((e: any) => {
				e.response.status === 403 && toast.error(t('tags_permissions_error'));
			});
	};

	return (
		<Modal isOpen={showModal.isShown}>
			<ModalHeader className='pl-3 pr-3 pt-2 pb-2 bg-info' toggle={() => toggleModalState(true, false)}>
				{t('rearrange_tags')}
			</ModalHeader>
			<ModalBody className='pb-2'>
				{prevTags[oldIndex] && (
					<>
						<div className='text-center'>{t('rearrange_tags_body')}</div>
						<div className='pt-2 font-weight-bold text-center text-truncate'>{prevTags[oldIndex].title}</div>
					</>
				)}
			</ModalBody>
			<ModalFooter className='border-0'>
				<Button id='modal-tags-cancel-button' color='base' onClick={() => toggleModalState(true, false)} className='ml-auto'>
					{t('close')}
				</Button>
				<Button id='modal-tags-confirm-button' color='info' onClick={updateTags}>
					{t('confirm')}
				</Button>
			</ModalFooter>
		</Modal>
	);
};

export default TagsReorderModal;
