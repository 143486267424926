import React from 'react';
import { Col, Row, Table } from 'reactstrap';
import ReactPaginate, { ReactPaginateProps } from 'react-paginate';
import { Properties, State } from './properties/listing-container.properties';
import ListingHeadersContainer from '../../Partials/BaseComponents/ListingComponent/subcomponents/ListingHeaders/ListingHeadersContainer';
import RowsContainer from '../../Partials/BaseComponents/ListingComponent/subcomponents/Rows/RowsContainer';
import { PAGINATE_PROPS } from '../../Partials/BaseComponents/ListingComponent/subcomponents/AuthorizedListing/properties/PaginateProps';
import NewContentContainer from '../../Partials/BaseComponents/ListingComponent/subcomponents/NewContent/NewContentContainer';
import SearchBar from './subcomponents/searchbar/searchbar-component';
import { ContentTypes } from '../../../constants/content-types';
import { teamSearchFilterUpdate } from '../../../store/action-creators/team-action-creator';
import { connect } from 'react-redux';
import { playerSearchFilterUpdate } from '../../../store/action-creators/player-action-creator';
import { coachSearchFilterUpdate } from '../../../store/action-creators/coach-action-creator';
import { Subscription } from 'rxjs';
import { actionService } from '../../../App';
import * as uiActions from '../../../store/action-creators/UiActionCreator';
import { checkIfPaginationShouldRender } from './subcomponents/helpers/manual-data.helper';
import { getLastVisitedLocations } from '../../../global-helpers/global.helpers';

class ListingComponent extends React.Component<Properties, State> {
	searchSubscription: Subscription = {} as Subscription;

	constructor(props: any) {
		super(props);
		this.state = {
			pageSearchQuery: '',
			contentSearchInput: '',
			searchApplied: false,
			currentLoc: '',
		};
		this.registerActionEventListener();
	}

	registerActionEventListener() {
		this.searchSubscription = actionService.onActionReceived().subscribe((action: string) => {
			if (action === uiActions.SEARCH_IS_APPLIED) {
				this.setState({ searchApplied: true });
			}
		});
	}

	useCorrectSearchTextPerContentType() {
		let searchFilter = '';

		switch (this.props.contentType) {
			case ContentTypes.PLAYER:
				this.props.searchTextPlayer ? (searchFilter = this.props.searchTextPlayer) : (searchFilter = '');
				break;
			case ContentTypes.COACH:
				this.props.searchTextCoach ? (searchFilter = this.props.searchTextCoach) : (searchFilter = '');
				break;
			case ContentTypes.TEAM:
				this.props.searchTextTeam ? (searchFilter = this.props.searchTextTeam) : (searchFilter = '');

				break;
			default:
				searchFilter = '';
		}

		return searchFilter;
	}

	updateCorrectSearchTextPerContentType(text: string) {
		let searchFilter = '';

		switch (this.props.contentType) {
			case ContentTypes.PLAYER:
				this.props.updateSearchFilterPlayer(text);
				break;
			case ContentTypes.COACH:
				this.props.updateSearchFilterCoach(text);
				break;
			case ContentTypes.TEAM:
				this.props.updateSearchFilterTeam(text);
				break;
			default:
				searchFilter = '';
		}
		return searchFilter;
	}

	checkIfPageIsChanged(newLoc: string) {
		//Check if the routing location pathname is changed to another page,
		// in order to know whether to clear the search filter or keep it stored in Redux.
		// Used to check also for whether or not to clear the pagination
		return !newLoc.includes(this.state.currentLoc);
	}

	componentWillUnmount() {
		const newLoc: any = window.location.href.split('/');
		//Check if the page is changed to another content type, so that the search filter can be cleared.
		//If the user navigates to /smp/squad/ the filter should not be cleared.

		if (!newLoc.includes('squad') && this.checkIfPageIsChanged(newLoc)) {
			this.updateCorrectSearchTextPerContentType('');
			this.props.getContent('1', this.props.currentProject);
		}
	}

	componentDidMount() {
		let searchFilter = this.useCorrectSearchTextPerContentType();
		let trimCurrentLoc = this.props.history.location.pathname.split('/').pop();
		this.setState({ contentSearchInput: searchFilter, currentLoc: trimCurrentLoc });
		if (searchFilter && searchFilter.length > 2) {
			this.setState({ contentSearchInput: searchFilter });
			this.searchContent(searchFilter);
		} else {
			this.getAllContent();
		}
	}

	private handlePageClick = (data: any) => {
		const selectedPage = data.selected + 1;
		const searchQuery = this.state.pageSearchQuery.length > 0 ? this.state.pageSearchQuery : '';
		this.props.getContent(selectedPage, this.props.currentProject, searchQuery);

		if (this.props.onPageChange) {
			this.props.onPageChange(selectedPage);
		}
	};

	private reactPaginateProps = (t: any, contentPagination: any): ReactPaginateProps => {
		let pages = Math.floor(contentPagination.total / 20);
		let totalPages = contentPagination ? (contentPagination.total - pages > 0 ? pages + 1 : pages) : 0;

		return {
			...PAGINATE_PROPS,
			previousLabel: t('previous_page'),
			nextLabel: t('next_page'),
			pageCount: totalPages,
			onPageChange: this.handlePageClick,
			forcePage: contentPagination.offset / 20,
		};
	};

	updateInputState = (text: string) => {
		this.updateCorrectSearchTextPerContentType(text);
		this.setState({ contentSearchInput: text });
		if (text === '') {
			this.updateCorrectSearchTextPerContentType(text);
			this.getAllContent();
		}
	};

	updatePageSearchQuery = (text: string) =>
		this.setState((state: State) => {
			return { pageSearchQuery: text };
		});

	searchContent = (searchFilter: string) => {
		let lang = this.props.lang.substring(0, 2);
		let contentType = this.props.contentType;
		if (searchFilter.match(/^ *$/) !== null) {
			this.updateInputState('');
			this.updatePageSearchQuery('');
			this.props.updateSearchFilterTeam('');
			this.getAllContent();
		} else {
			this.props.searchContent(searchFilter, lang, contentType);
			this.updatePageSearchQuery(searchFilter);
		}
	};

	clearSavedPagination() {
		this.getAllContent();
	}

	//Calculate the page on which the listing should render, use offset and page limit from the Store
	setCurrentPage = () => {
		let page = 1;

		const lastVisitedLocations = getLastVisitedLocations();
		const lastVisitedLocationsLength = lastVisitedLocations.length;
		if (lastVisitedLocationsLength > 1) {
			const currentLocation = lastVisitedLocations[lastVisitedLocationsLength - 1];
			const previousLocation = lastVisitedLocations[lastVisitedLocationsLength - 2];
			if (!previousLocation.includes(currentLocation)) {
				return page;
			}
		}

		if (this.props.contentPagination && this.props.contentPagination.offset && this.props.contentPagination.limit) {
			page = this.props.contentPagination.offset / this.props.contentPagination.limit + 1;
		}
		return page;
	};

	getAllContent = () => {
		let page = this.setCurrentPage();
		this.props.getContent(page, this.props.currentProject);
	};

	render() {
		const {
			contentPagination,
			toggleLoadingState,
			analytics,
			contentType,
			content,
			hidePagination,
			showSearchBar,
			t,
			currentProject,
			searchContent,
			getContent,
		} = this.props;
		const searchTextValue = this.useCorrectSearchTextPerContentType();
		return (
			<div className={`${toggleLoadingState ? 'loading-overlay' : ''}`}>
				<Row>
					<Col col='6' sm='12' md='6'>
						<NewContentContainer analytics={analytics} t={t} contentType={contentType} display={true} />
					</Col>
					<Col col='6' sm='12' md='6' className='d-flex flex-column'>
						<SearchBar
							showSearchBar={showSearchBar}
							t={t}
							searchText={searchTextValue}
							currentProject={currentProject}
							searchContent={searchContent}
							getContent={getContent}
							analytics={analytics}
							updateSearchText={this.updateInputState}
							updatePageSearchQuery={this.updatePageSearchQuery}
							lang={this.props.lang}
							type={contentType}
						/>
					</Col>
				</Row>
				<Row className='mt-1'>
					<Col>
						<Table responsive striped>
							<ListingHeadersContainer t={t} contentType={contentType} />
							<tbody>
								{content && content.length > 0 && (
									<RowsContainer t={t} content={content} history={this.props.history} contentType={contentType} analytics={analytics} />
								)}
							</tbody>
						</Table>
						{content && content.length === 0 && <h6 className='d-flex justify-content-center mb-2'>{this.props.t('no_data_found')}</h6>}
						{checkIfPaginationShouldRender(hidePagination, this.state.searchApplied, searchTextValue) && (
							<ReactPaginate {...this.reactPaginateProps(t, contentPagination)} />
						)}
					</Col>
				</Row>
			</div>
		);
	}
}

function mapStateToProps(state: any) {
	return {
		searchTextTeam: state.team.searchFilter,
		searchTextPlayer: state.player.searchFilter,
		searchTextCoach: state.coach.searchFilter,
	};
}

function mapDispatchToProps(dispatch: any) {
	return {
		dispatch: (action: any) => dispatch(action),
		updateSearchFilterTeam: (text: string) => dispatch(teamSearchFilterUpdate(text)),
		updateSearchFilterPlayer: (text: string) => dispatch(playerSearchFilterUpdate(text)),
		updateSearchFilterCoach: (text: string) => dispatch(coachSearchFilterUpdate(text)),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(ListingComponent);
