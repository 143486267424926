import moment from 'moment';
import EnumItem from '../../../../../models/enum/EnumItem';
import { MatchesFilters } from '../../../../../store/reducers/match-lineups-edit.reducer';
import MatchDetailsModel from '../../models/match/match-details.model';
import MatchScoreModel from '../../models/match/match-score.model';
import TournamentModel from '../../models/match/match-season-tournament.model';
import MatchSeasonModel from '../../models/match/match-season.model';
import { remapEnumItem } from './response-remappers.helper';

export type EventStatus = { id: string; name: string; type: string; code: string };

export enum matchCoverageEnum {
	'LIVE' = 'live',
	'NOT_LIVE' = 'not_live',
}
export const scoreUpdateMethods = {
	total: 'withTotal',
	halfTime: 'withHalfTime',
	regularTime: 'withRegularTime',
	extraTime: 'withExtraTime',
	penaltyShootout: 'withPenaltyShootout',
	aggregate: 'withAggregate',
};

export const scoreOptions = [
	{ id: 'total', name: 'total' },
	{ id: 'halfTime', name: 'halfTime' },
	{ id: 'regularTime', name: 'regularTime' },
	{ id: 'extraTime', name: 'extraTime' },
	{ id: 'penaltyShootout', name: 'penaltyShootout' },
	{ id: 'aggregate', name: 'aggregate' },
];

export const matchDetailsValidations = {
	// 1st_half = 5, 19, 18, 2nd_half = 6, extra_time_1st_half = 18,'extra_time_2nd_half = 20
	phaseStartedAt: ['5', '19', '18', '6', '20'],
	// FINISHED = 24, 11, 7, 9, 1, INTERRUPTED = 12, 8, LIVE = 23, 21, 19, 18, 17, 16, 15, 6, 10, 5
	score: ['12', '14', '20', '8', '24', '11', '7', '9', '1', '23', '21', '19', '18', '17', '16', '15', '6', '10', '5', '23', '24'],
	// not_started 3, 4, 13 , cancelled 2
	notStarted: ['13', '3', '4', '2'],
	// finished '1', '7', '9', '11', '24'
	finished: ['1', '7', '9', '11', '24'],
};

export const remapEventStatus = (data: any) => {
	if (data && data.length > 0) {
		return data.map((item: any) => {
			return { code: item.code, id: `${item.id}`, name: item.name, type: item.type };
		});
	}
	return [];
};

// The updateMatchScore method should push the new score value to the existing ones.
export const updateMatchScore = (currentScore: MatchScoreModel | null, scoreToAdd: MatchScoreModel) => {
	return currentScore ? ({ ...currentScore, ...scoreToAdd } as MatchScoreModel) : scoreToAdd;
};

// If the match status is != not_started or cancelled types the total score is required.
// It return false if the score is valid and has set total score type.
// It return true if the score is NOT valid and has NOT set total score type.
export const validateMatchScore = (matchScore: MatchScoreModel | null) => {
	const notNullScore = filterNotNullScores(matchScore);
	return !matchScore || notNullScore.length === 0 || (notNullScore.length > 0 && !notNullScore.includes('total'));
};

// From match score object we filter the score types with value.
export const filterNotNullScores = (score: MatchScoreModel | null) => {
	if (score) {
		return Object.keys(score).filter((item: any) => score[item] !== null);
	}
	return [];
};

// The filterScoreOptions method filters from score dropdown options the model score types that already have values.
export const filterScoreOptions = (matchScore: MatchScoreModel | null) => {
	if (matchScore) {
		const scores = filterNotNullScores(matchScore);
		// Filter already selected and added score option.
		return [...scoreOptions].filter((option: any) => !scores.includes(option.id) || !matchScore[option.id]);
	}
	return [...scoreOptions];
};

// The addScoreTypeToModel method adds to score type { home: 0; away: 0 } object.
export const addScoreTypeToModel = (score: MatchScoreModel | null, scoreType: string) => {
	if (scoreType) {
		return MatchScoreModel.builder(score)[scoreUpdateMethods[scoreType]]({ home: 0, away: 0 }).build();
	}
	return score;
};

export const remapSelectedScore = (match: MatchDetailsModel, score: MatchScoreModel) => {
	return MatchDetailsModel.builder(match).withScore(updateMatchScore(match.score, score)).build();
};

export const remapSelectedTournament = (match: MatchDetailsModel | null, tournament: TournamentModel) => {
	if (tournament && tournament.id && match) {
		return MatchDetailsModel.builder(match)
			.withStage(null)
			.withRound(null)
			.withGroup(null)
			.withSeason(MatchSeasonModel.builder().withTournament(tournament).build())
			.build();
	}
	return null;
};

export const createEmptyMatch = (filters: MatchesFilters, match: MatchDetailsModel) => {
	const kickoffTime = match && match.kickoffTime ? match.kickoffTime : moment().format();

	return MatchDetailsModel.builder(match)
		.withKikoffTime(kickoffTime)
		.withCoverage('NOT_LIVE')
		.withScore(null)
		.withStartTime(kickoffTime)
		.withStage(remapEnumItem(filters.stage))
		.withRound(filters.round)
		.withSeason(MatchSeasonModel.builder(filters.season).withTournament(filters.tournament).build())
		.build();
};

export const remapSelectedSeason = (match: MatchDetailsModel | null, season: MatchSeasonModel) => {
	if (season && season.id && match) {
		return MatchDetailsModel.builder(match)
			.withStage(null)
			.withRound(null)
			.withGroup(null)
			.withSeason(
				MatchSeasonModel.builder()
					.withId(season.id)
					.withName(season.name)
					.withStatus(season.status)
					.withTournament(match.season && match.season.tournament ? match.season.tournament : null)
					.build(),
			)
			.build();
	}
	return null;
};

export const validateByEventStatus = (status: EventStatus, type: string) => {
	if (matchDetailsValidations[type] && status) {
		return matchDetailsValidations[type].includes(status.id);
	}
	return false;
};

export const matchDetailsSaveDisable = (match: MatchDetailsModel, phaseStartedIsNotValid: boolean, scoreIsNotValid: boolean) => {
	return (
		match &&
		(!match.homeTeam ||
			!match.awayTeam ||
			!match.kickoffTime ||
			!match.stage ||
			!match.round ||
			!match.eventStatus ||
			phaseStartedIsNotValid ||
			scoreIsNotValid)
	);
};
