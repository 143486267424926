import { USER_LOGOUT } from '../action-creators/ProfileActionCreators';
import PaginationMeta from '../../models/pagination/PaginationMeta';
import { CLEAR_ERRORS, GENERAL_ERROR, SET_LOADING_STATE } from '../action-creators/GeneralActions';
import ErrorResponseModel from '../../models/error-response/ErrorResponseModel';

interface InitialState {
	pagination: PaginationMeta;
	loading: boolean;
	errors: ErrorResponseModel;
	filteredErrors: any;
}

const initialState: InitialState = {
	pagination: PaginationMeta.builder().build(),
	loading: false,
	errors: ErrorResponseModel.builder().build(),
	filteredErrors: {},
};

function generalReducer(state: any = initialState, action: any) {
	if (action.type === CLEAR_ERRORS) {
		state = Object.assign({}, state.error, {});
	}

	if (action.type === USER_LOGOUT) {
		state = initialState;
	}

	if (action.type === GENERAL_ERROR) {
		state = Object.assign({}, state, {
			errors: action.payload,
		});
	}

	if (action.type === SET_LOADING_STATE) {
		state = Object.assign({}, state, {
			loading: action.payload,
		});
	}

	return state;
}

export default generalReducer;
