import UserModel from '../models/user.model';

export const usersToOptions = (users: UserModel[], profileId: string, t: any) => {
	let usersResult = [];

	if (users && users.length > 0) {
		usersResult = users.filter((user: UserModel) => user.active && user.id === profileId);
		users.filter((user: UserModel) => user.active && user.id !== profileId).forEach((user: UserModel) => usersResult.push(user));

		return usersResult.map((user: UserModel) => userToOption(user, profileId, t));
	}

	return [];
};

export const userToOption = (user: UserModel, profileId: string, t: any) => {
	if (user && Object.entries(user).length > 0) {
		return {
			value: user.id,
			label: user.id === profileId ? t('me') : user.name,
			user,
		};
	}

	return {};
};

export const optionToUser = (selection: any) => {
	if (selection && Object.entries(selection).length > 0) {
		return selection.user;
	}

	return {};
};

export const remapUsersFromResponse = (response: any) => {
	let users = [];

	if (response && response.length > 0) {
		users = response.map((user: any) => remapUserFromResponse(user));
	}

	return users;
};

export const remapUserFromResponse = (user: any) => {
	if (user && Object.entries(user).length > 0) {
		return UserModel.builder().withId(user.id).withActive(user.is_active).withName(user.full_name).build();
	}

	return {};
};
