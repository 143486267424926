import React, { useEffect, useState } from 'react';
import { Col, FormGroup, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import { Scrollbars } from 'react-custom-scrollbars';
import { MatchesFinished } from './match-status-tabs/matches-finished-tab';
import { MatchesNotStarted } from './match-status-tabs/matches-not-started-tab';
import { MatchesInterrupted } from './match-status-tabs/matches-interrupted-tab';
import MatchModel from '../../../../../../models/v2/match/entity/match.model';

type Properties = {
	selectedTeamNames: any;
	isMulti: boolean;
	onMatchSelect: any;
	onMatchesSelect: any;
	matchesFinished: any[];
	matchesNotStarted: any[];
	matchesInterrupted: any[];
	hideMatchStatusTabs?: boolean;
	updateCheckAllMatchesState: any;
	finishedMatchesAreSelected: any;
	upcomingMatchesAreSelected?: any;
	interruptedMatchesAreSelected?: any;
	t: any;
};

export const Matches: React.FunctionComponent<Properties> = (props) => {
	const { matchesFinished, matchesNotStarted, matchesInterrupted, t, hideMatchStatusTabs } = props;

	const [activeTab, setActiveTab] = useState(new Array(4).fill('1'));
	let [matchesState, setMatchesState] = useState(
		[...props.matchesFinished, ...props.matchesNotStarted, ...props.matchesInterrupted].filter((match) => match.isSelected),
	);

	useEffect(() => {
		setMatchesState([...props.matchesFinished, ...props.matchesNotStarted, ...props.matchesInterrupted].filter((match) => match.isSelected));
	}, [props.matchesFinished, props.matchesNotStarted, props.matchesInterrupted]);

	const toggle = (tabPane: number, tab: string) => {
		const newArray = activeTab.slice();
		newArray[tabPane] = tab;

		setActiveTab(newArray);
	};

	const onMatchSelect = (match: MatchModel) => {
		props.onMatchSelect(match);
	};

	const handleMatchCheck = (match: MatchModel, matches: MatchModel[], checked: boolean) => {
		if (checked) {
			matches.push(match);
		} else {
			matches = matches.filter((item) => item.id !== match.id);
		}

		return matches;
	};

	const handleMatchesCheck = (matches: MatchModel[], checked: boolean) => {
		updateCheckAllMatchesState(checked, matches);

		let selectedMatches = [];

		if (!checked) {
			let matchIds = matches.concat(matches).map((match: MatchModel) => match.id);
			const onlyUnique = (value: any, index: any, self: any) => self.indexOf(value) === index;
			const uniqueIds = matchIds.filter(onlyUnique);

			selectedMatches = matchesState.filter((match: MatchModel) => !uniqueIds.includes(match.id));
		} else {
			selectedMatches = matchesState.concat(matches);
		}

		return selectedMatches;
	};

	const updateMatchesState = (matches: MatchModel[]) => setMatchesState(matches);

	const updateCheckAllMatchesState = (checked: boolean, selectedMatches: MatchModel[]) => {
		if (activeTab[0] === '1') {
			props.updateCheckAllMatchesState(checked, 'upcoming', selectedMatches, activeTab[0]);
		} else {
			props.updateCheckAllMatchesState(checked, 'finished', selectedMatches, activeTab[0]);
		}
	};

	const onMatchesSelect = (match: MatchModel, event: any) => {
		matchesState = handleMatchCheck(match, matchesState, event.target.checked);

		updateMatchesState(matchesState);
		updateCheckAllMatchesState(false, matchesState);

		if (props.onMatchesSelect) {
			props.onMatchesSelect(matchesState);
		}
	};

	const selectAllMatches = (selectedMatches: any, event: any) => {
		selectedMatches = handleMatchesCheck(selectedMatches, event.target.checked);

		if (props.onMatchesSelect) {
			props.onMatchesSelect(selectedMatches);
		}
	};

	const applyBoldCss = (teamName: string) => {
		return props.selectedTeamNames.includes(teamName) ? 'font-weight-bold' : '';
	};

	const applyInProgressClass = (type: string) => {
		return type === 'inprogress' ? 'border-pulsate-right' : '';
	};

	const printMinutesIfMatchInProgress = (match: MatchModel) => {
		return match.type === 'inprogress' ? `(${match.minutes}")` : '';
	};

	const toggleGoals = (match: MatchModel) => {
		return match.type === 'inprogress' ? `${match.homeGoal}:${match.awayGoal}` : ' - ';
	};

	return (
		<div id='match-tagging-v2' data-qa='match-tagging'>
			<Row>
				<Col>
					<Nav tabs>
						<NavItem>
							<NavLink active={activeTab[0] === '1'} onClick={() => toggle(0, '1')}>
								<i className='fa fa-calendar'></i> {t('upcoming_matches')}
							</NavLink>
						</NavItem>
						<NavItem>
							{!hideMatchStatusTabs && (
								<NavLink active={activeTab[0] === '2'} onClick={() => toggle(0, '2')}>
									<i className='fa fa-calendar-times-o'></i> {t('past_matches')}
								</NavLink>
							)}
						</NavItem>
						<NavItem>
							{!hideMatchStatusTabs && (
								<NavLink active={activeTab[0] === '3'} onClick={() => toggle(0, '3')}>
									<i className='fa fa-calendar-minus-o'></i> {t('interrupted_matches')}
								</NavLink>
							)}
						</NavItem>
					</Nav>
					<TabContent activeTab={activeTab[0]}>
						<TabPane tabId='1' className='p-2 pb-0 bg-white'>
							<FormGroup className='mt-1'>
								<Scrollbars style={{ height: 300 }}>
									<MatchesNotStarted
										matchesNotStarted={matchesNotStarted}
										t={t}
										isMulti={false}
										onMatchesSelect={onMatchesSelect}
										onMatchSelect={onMatchSelect}
										applyBoldCss={applyBoldCss}
										selectAllMatches={selectAllMatches}
										notStartedMatchesAreSelected={props.upcomingMatchesAreSelected}
										toggleGoals={toggleGoals}
										applyInProgressClass={applyInProgressClass}
									/>
								</Scrollbars>
							</FormGroup>
						</TabPane>
						<TabPane tabId='2' className='p-2 pb-0 bg-white'>
							{!hideMatchStatusTabs && (
								<FormGroup className='mt-1'>
									<Scrollbars style={{ height: 300 }}>
										<MatchesFinished
											matchesFinished={matchesFinished}
											t={t}
											isMulti={false}
											onMatchesSelect={onMatchesSelect}
											onMatchSelect={onMatchSelect}
											applyBoldCss={applyBoldCss}
											selectAllMatches={selectAllMatches}
											finishedMatchesAreSelected={props.finishedMatchesAreSelected}
										/>
									</Scrollbars>
								</FormGroup>
							)}
						</TabPane>
						<TabPane tabId='3' className='p-2 pb-0 bg-white'>
							{!hideMatchStatusTabs && (
								<FormGroup className='mt-1'>
									<Scrollbars style={{ height: 300 }}>
										<MatchesInterrupted
											matchesInterrupted={matchesInterrupted}
											t={t}
											isMulti={false}
											onMatchesSelect={onMatchesSelect}
											onMatchSelect={onMatchSelect}
											applyBoldCss={applyBoldCss}
											selectAllMatches={selectAllMatches}
											interruptedMatchesAreSelected={props.interruptedMatchesAreSelected}
											toggleGoals={toggleGoals}
											applyInProgressClass={applyInProgressClass}
											printMinutesIfMatchInProgress={printMinutesIfMatchInProgress}
										/>
									</Scrollbars>
								</FormGroup>
							)}
						</TabPane>
					</TabContent>
				</Col>
			</Row>
		</div>
	);
};
