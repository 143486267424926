import React, { FunctionComponent, useEffect, useState } from 'react';
import { blService } from './TournamentContainer';
import { ImageUpload } from './subcomponents/ImageUpload/ImageUpload';
import { Stages } from './subcomponents/Stages/Stages';
import { TournamentInfo } from './subcomponents/Tournament/TournamentInfo';
import { Row } from 'reactstrap';
import { toast } from 'react-toastify';
import './styles/TournamentEditComponent.scss';
import TournamentModel from '../../../models/v2/Tournament/Entity/TournamentModel';

type Props = {
	t: any;
	routeMatch: any;
	language: string;
};

export const TournamentEdit: FunctionComponent<Props> = ({ t, routeMatch, language }) => {
	const [tournament, setTournament] = useState(new TournamentModel());
	const [isTournamentFetched, setIsTournamentFetched] = useState(false);

	document.title = t('edit_tournament');

	useEffect(() => {
		blService
			.getTournament(routeMatch && routeMatch.params ? routeMatch.params.id : '', language)
			.then((tournamentModel) => {
				setTournament(tournamentModel);
				setIsTournamentFetched(true);
			})
			.catch(() => {
				toast.error(t('error_occurred'));
			});
	}, []);

	return (
		<>
			<Row>
				<TournamentInfo t={t} tournament={tournament} />
				<ImageUpload tournamentFetchedFlag={isTournamentFetched} tournament={tournament} updateTournament={setTournament} t={t} />
			</Row>
			<Stages t={t} seasons={tournament.seasons}></Stages>
		</>
	);
};
