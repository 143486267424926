import { featuresService } from '../../App';
import { FeatureTypes } from '../feature-service/features.enum';
import HttpService from '../rest/HttpService';

export const getFirstWatermark = (watermarksResponse: any) => {
	return (
		watermarksResponse.data && watermarksResponse.data[0] && watermarksResponse.data[0].id && watermarksResponse.data[0].watermarkImagePath
	);
};

export const getQuickWatermark = () => {
	let data = featuresService.getFeatureConfig(FeatureTypes.WATERMARK);

	return data.quick_watermark;
};

export const checkForWatermarkFeature = () => {
	return featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.WATERMARK) && featuresService.getFeatureConfig(FeatureTypes.WATERMARK);
};

export const setGenericWatermarkToFalse = (imageID: string, imagePath: string) => {
	let imageRequestJson = {
		generic: { watermark: false },
		path: imagePath,
		urls: {
			uploaded: {
				original: imagePath,
				gallery: `${imagePath}?operations=fit(1920:)`,
				thumbnail: `${imagePath}?operations=autocrop(256:256)`,
				embed: `${imagePath}?operations=fit(770:)`,
			},
		},
	};
	if (imageID) {
		return HttpService.patchImage(`/images/${imageID}`, imageRequestJson);
	}
};

export const setGenericWatermarkToTrue = (imageID: string, imagePath: string) => {
	let imageRequestJson = {
		generic: { watermark: true },
		path: imagePath,
		urls: {
			uploaded: {
				original: imagePath,
				gallery: `${imagePath}?operations=fit(1920:)`,
				thumbnail: `${imagePath}?operations=autocrop(256:256)`,
				embed: `${imagePath}?operations=fit(770:)`,
			},
		},
	};
	if (imageID) {
		return HttpService.patchImage(`/images/${imageID}`, imageRequestJson);
	}
};
