import React, { useState } from 'react';
import { Card, CardBody, CardHeader, Collapse, Button } from 'reactstrap';
import LineupPlayerModel from '../../models/player-lineup/lineup-player.model';

type Properties = {
	squad: LineupPlayerModel[];
	addSquadPlayer: (player: LineupPlayerModel) => void;
	t: any;
};

const SquadList: React.FunctionComponent<Properties> = (props) => {
	const { squad, addSquadPlayer, t } = props;
	const [isOpen, setIsOpen] = useState(true);

	const toggle = () => setIsOpen(!isOpen);

	if (squad && squad.length > 0) {
		return (
			<Card className='animated fadeIn'>
				<CardHeader className='cursor-pointer d-flex align-items-center' onClick={toggle}>
					{t('squad_players_not_in_group')}
					<i className={`ml-auto fa fa-lg fa-angle-${isOpen ? 'down' : 'left'}`} />
				</CardHeader>
				<Collapse isOpen={isOpen}>
					<CardBody className='d-flex flex-wrap p-2'>
						{squad.map((player: LineupPlayerModel, idx: number) => (
							<div key={`squad-player-${player.player.id}-${idx}`} id={`squad-player-row-${player.player.id}-${idx}`} className='w-50'>
								<div className={`p-1 border border-light rounded my-1 ${idx % 2 === 1 ? '' : 'mr-1'} align-items-center d-flex`}>
									<Button
										color='light'
										className='mr-2 py-1 px-2'
										id={`add-squad-player-${player.player.id}`}
										onClick={() => addSquadPlayer(player)}
									>
										<i className='fa fa-plus' />
									</Button>
									<span className='pr-2'>{player.player.name}</span>
								</div>
							</div>
						))}
					</CardBody>
				</Collapse>
			</Card>
		);
	}

	return (
		<Card className='animated fadeIn'>
			<CardHeader onClick={toggle} className='cursor-pointer d-flex align-items-center'>
				{t('substitutes')}
				<i className={`ml-auto fa fa-lg fa-angle-${isOpen ? 'down' : 'left'}`} />
			</CardHeader>
			<Collapse isOpen={isOpen}>
				<p className='text-center px-2 py-4'>{t('no_selected_players')}</p>
			</Collapse>
		</Card>
	);
};

export default SquadList;
