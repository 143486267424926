import { SelectMenuOptionType } from '../../../../../models/v2/general/select.model';
import VideoUrlModel from '../../../../../models/v2/video-url/video-url.model';

export const removeUsedRedirectTypes = (urls: VideoUrlModel[], videoTypes: string[]) => {
	if (videoTypes && videoTypes.length > 0) {
		return videoTypes.filter((type: string) => {
			const existingType = urls.find((url: VideoUrlModel) => url.type === type);
			return !existingType;
		});
	}

	return [];
};

export const redirectTypeToOption = (redirectType: string, t: any): SelectMenuOptionType => {
	return { value: redirectType, label: t(redirectType), data: redirectType };
};

export const redirectTypesToOptions = (redirectTypes: string[], t: any): SelectMenuOptionType[] => {
	return redirectTypes.map((redirectType) => redirectTypeToOption(redirectType, t));
};
