import { featuresService } from '../../../../../../App';
import { FeatureTypes } from '../../../../../../services/feature-service/features.enum';

enum AudioFeatureType {
	spotlightAudio = 'SPOTLIGHT_AUDIO_INTEGRATION',
	gbVisionAudio = 'GB_VISION_INTEGRATION',
}

export const mapTypesToOption = (types: any[], t: any) => {
	if (types && types.length > 0)
		return types
			.map((type: any) => {
				if (featuresService.checkFeatureIsSetAndEnabled(type.featureId)) {
					return typeToOption(t, type.value);
				}
				return null;
			})
			.filter((item: any) => item !== null);

	return [];
};

export const typeToOption = (t: any, type: string) => {
	if (type && type.length > 0) return { value: type, label: t(type) };

	return {};
};

export const addDefaultType = (types: any[], value: string) => {
	let defaultType: string = '';

	if (value !== '' && featuresService.checkFeatureIsSetAndEnabled(FeatureTypes[AudioFeatureType[value]])) {
		return value;
	}

	types.forEach((type: any) => {
		if (defaultType === '') {
			if (featuresService.checkFeatureIsSetAndEnabled(type.featureId)) {
				defaultType = type.value;
			}
		}
	});

	return defaultType;
};
