import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Subscription } from 'rxjs';
import { actionService } from '../../../../../App';
import { ARTICLE_ENTITY_UPDATE_SUCCESS } from '../../../../../store/action-creators/ArticleActionCreator';
import { updateTempEditArticleBeforeRedirecting } from '../../../../../store/action-creators/ArticleTempActionCreator';
import { GALLERY_UPDATE_SUCCESS } from '../../../../../store/action-creators/GalleryActionCreator';
import { updateTempEditGalleryBeforeRedirecting } from '../../../../../store/action-creators/GalleryTempActionCreator';
import { VIDEO_UPDATE_SUCCESS } from '../../../../../store/action-creators/VideoActionCreator';
import { updateTempEditVideoBeforeRedirecting } from '../../../../../store/action-creators/VideoTempActionCreator';
import { ContentTypes } from '../../../Blocky/constants/block.types';

export type SaveContentModalProperties = {
	isToggledModal: boolean;
	analytics: any;
	t: any;
	toggleModal: (toggle: boolean) => any;
	type: string;
};

export const SaveContentModal: React.FunctionComponent<SaveContentModalProperties> = (props) => {
	let actionServiceSubject = new Subscription();

	const { t, isToggledModal, toggleModal, type } = props;
	const [showSpinner, updateSpinnerStatus] = useState<boolean>(false);

	const saveContent = () => {
		toggleModal(true);
		updateSpinnerStatus(true);
		type === ContentTypes.article && dispatch(updateTempEditArticleBeforeRedirecting());
		type === ContentTypes.video && dispatch(updateTempEditVideoBeforeRedirecting());
		type === ContentTypes.gallery && dispatch(updateTempEditGalleryBeforeRedirecting());
	};

	const registerActionEventListener = () => {
		actionServiceSubject = actionService.onActionReceived().subscribe((action: string) => {
			if (
				(action === ARTICLE_ENTITY_UPDATE_SUCCESS || action === VIDEO_UPDATE_SUCCESS || action === GALLERY_UPDATE_SUCCESS) &&
				isToggledModal
			) {
				updateSpinnerStatus(false);
			}
		});
	};

	useEffect(() => {
		registerActionEventListener();

		return function cleanup() {
			if (actionServiceSubject) {
				actionServiceSubject.unsubscribe();
			}
		};
	}, []);

	const dispatch = useDispatch();

	return (
		<Modal isOpen={isToggledModal}>
			<ModalHeader className='pl-3 pr-3 pt-2 pb-2' toggle={() => toggleModal(false)}>
				{t(`save_the_${type}`)}
			</ModalHeader>
			<ModalBody className='pb-2'>{t(`save_the_${type}_before_redirecting`)}</ModalBody>
			<ModalFooter className='border-0'>
				<Button onClick={() => saveContent()} className={'ml-auto btn-info'}>
					{showSpinner && <span className='spinner-border spinner-border-sm mr-2' role='status' aria-hidden='true'></span>}
					{t('save')}
				</Button>{' '}
				<Button color='danger' onClick={() => toggleModal(false)}>
					{t('close')}
				</Button>{' '}
			</ModalFooter>
		</Modal>
	);
};
