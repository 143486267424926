import React, { useState } from 'react';
import BlockModel from '../../../../Partials/Blocky/models/block.model';
import GettyImageModel from '../../models/getty-image.model';
import { useTranslation } from 'react-i18next';
import { Button, Col, FormGroup, Row } from 'reactstrap';
import Scrollbar from 'react-custom-scrollbars';
import ErrorHandler from '../../../../Partials/Blocky/partials/error/error-handler-component';
import GettyImagesListingModel from '../../models/getty-images-listing.model';
import { GettyImageCells } from './getty-image-cells';
import { GettyImageSelect } from './getty-image-select.component';
import GettyApiService from '../../helpers/getty-api.service';

type Properties = {
	onSelect: (image: GettyImageModel) => void;
	updateSearchInput?: (searchInput: string, pageOffset: number, selectedImageType: string) => void;
	selectedImage: GettyImageModel;
	inBlocky: boolean;
	gettyInstance: GettyApiService;
	pageOffset?: number;
	onDownLoadSizeSelect?: (size: string) => void;
	isValid?: boolean;
	block?: BlockModel;
	selectedItemDownloadSize?: string;
};

export const GettyImageContainer: React.FunctionComponent<Properties> = (props) => {
	const [t] = useTranslation();
	const [gettyImages, setGettyImages] = useState<GettyImagesListingModel | null>(null);
	const [toggleLoadingState, setToggleLoadingState] = useState(false);
	const [shouldRequest, setShouldRequest] = useState(false);
	const [pageOffset, setPageOffset] = useState(props.pageOffset ? props.pageOffset : 1);

	const setImages = (images: GettyImagesListingModel | null, pageOffset: number, searchInput: string, selectedImageType: string) => {
		setGettyImages(images);
		setPageOffset(pageOffset);
		setShouldRequest(false);
		props.updateSearchInput && props.updateSearchInput(searchInput, pageOffset, selectedImageType);
	};

	const toggleLoading = (loading: boolean) => {
		setToggleLoadingState(loading);
	};

	const onPageUp = () => {
		setShouldRequest(true);
		setPageOffset(pageOffset + 1);
	};

	const onPageDown = () => {
		setShouldRequest(true);
		setPageOffset(pageOffset - 1);
	};

	const { onSelect, onDownLoadSizeSelect, selectedImage, block, inBlocky, gettyInstance, selectedItemDownloadSize } = props;
	const searchPhrase = block && block.data && block.data.preview && block.data.preview.searchInput;

	return (
		<div data-qa='getty-images-listing-container' className='mb-2'>
			<div>
				<Row>
					<Col>
						<FormGroup>
							<GettyImageSelect
								onSelect={setImages}
								shouldRequest={shouldRequest}
								selectedImageType={block && block.data && block.data.preview && block.data.preview.selectedImageType}
								onSelectedImageChange={onSelect}
								toggleLoadingState={toggleLoading}
								searchText={searchPhrase}
								pageOffset={pageOffset}
								gettyInstance={gettyInstance}
							/>
						</FormGroup>
					</Col>
				</Row>
				<hr />
				<div className={`${toggleLoadingState ? 'loading-overlay' : ''}`}>
					{gettyImages && gettyImages.images && gettyImages.images.length > 0 ? (
						<div className='images-container pb-1'>
							<Scrollbar>
								<GettyImageCells
									onSelect={onSelect}
									images={gettyImages.images}
									toggleLoadingState={toggleLoadingState}
									selectedImage={selectedImage}
									onDownLoadSizeSelect={onDownLoadSizeSelect}
									selectedItemDownloadSize={selectedItemDownloadSize}
									inBlocky={inBlocky}
								/>
							</Scrollbar>
						</div>
					) : (
						<div className='text-center'>{t('no_data')}</div>
					)}
					{gettyImages && (
						<div className='m-2 d-flex flex-row justify-content-end'>
							<Button
								id='getty-images-prev-page'
								size='sm'
								outline
								className='mr-2 d-flex align-items-center'
								color='primary'
								disabled={pageOffset === 1}
								onClick={onPageDown}
							>
								<i className='fa fa-2x fa-angle-left px-1' />
								{t('previous')}
							</Button>
							<Button
								id='getty-images-next-page'
								size='sm'
								disabled={!!(gettyImages.resultCount && pageOffset + 1 >= gettyImages.resultCount)}
								className='d-flex align-items-center'
								outline
								color='primary'
								onClick={onPageUp}
							>
								{t('next')}
								<i className='fa fa-2x fa-angle-right px-1' />
							</Button>
						</div>
					)}
				</div>
				{inBlocky && (
					<div className='p-1'>
						{!props.isValid && selectedImage && selectedImage.id === undefined && <ErrorHandler t={t} errorMessage='no_selected_image' />}
					</div>
				)}
			</div>
		</div>
	);
};
