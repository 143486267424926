import 'reflect-metadata';
import { jsonMember, jsonObject } from 'typedjson';
import StandingTypeModel from './standing-type.model';

@jsonObject()
export default class AvailableStandingTypeModel {
	@jsonMember(String)
	public id: string = '';
	@jsonMember(StandingTypeModel, { name: 'standing_type' })
	public standingType: StandingTypeModel = new StandingTypeModel();
}
