import React from 'react';
import { SearchProperties, State } from './properties/search-bar.properties';
import { FormGroup, Input, InputGroupAddon, InputGroup, Button } from 'reactstrap';
import { teamSearchFilterUpdate } from '../../../../../store/action-creators/team-action-creator';
import { connect } from 'react-redux';
import { coachSearchFilterUpdate } from '../../../../../store/action-creators/coach-action-creator';
import { playerSearchFilterUpdate } from '../../../../../store/action-creators/player-action-creator';

class SearchBar extends React.Component<SearchProperties, State> {
	constructor(props: any) {
		super(props);
		this.state = {
			contentSearchInput: this.props.searchText,
		};
	}

	onContentSearchInput = (data: any) => {
		let searchText = data.target.value;
		this.setState({ ...this.state, contentSearchInput: data.target.value });
		this.props.updateSearchText(searchText);

		if (searchText.length < 1) {
			this.props.analytics.sendEvent('Click', 'List Page', 'Search Performed');
			this.resetState();
		}
	};

	onContentSearch = (event: any) => {
		if (event.keyCode === 13 && this.state.contentSearchInput.length > 0) {
			this.searchContent(this.state.contentSearchInput);
		}
	};

	resetState = () => {
		this.props.getContent('1', this.props.currentProject);
		this.props.updateSearchText('');
		this.props.updatePageSearchQuery('');
	};

	clearSearch = () => {
		this.resetState();
		this.setState({ ...this.state, contentSearchInput: '' });
	};

	searchContent = (text: string) => {
		let lang = this.props.lang.substring(0, 2);

		if (text.match(/^ *$/) !== null) {
			this.setState({ ...this.state, contentSearchInput: '' });
			this.props.updateSearchText('');
			this.props.updatePageSearchQuery('');
		} else {
			this.props.searchContent(text, lang, this.props.type);
			this.props.updateSearchText(text);
			this.props.updatePageSearchQuery(text);
		}
	};

	render() {
		const { showSearchBar, t } = this.props;
		const { contentSearchInput } = this.state;

		if (showSearchBar === false) {
			return null;
		}

		return (
			<>
				<FormGroup row className='mb-0'>
					<InputGroup className='position-relative d-flex flex-direction-row flex-nowrap'>
						<InputGroup className='position-relative d-flex'>
							<Input
								type='text'
								id='search-input-manual-data'
								name='search-input'
								className='pr-4'
								placeholder={t(`search_for_${this.props.type}`)}
								onChange={this.onContentSearchInput}
								onKeyDown={this.onContentSearch}
								value={this.state.contentSearchInput}
							/>
							{contentSearchInput && contentSearchInput.length > 0 && (
								<div onClick={this.clearSearch} className='p-2 text-muted position-absolute' style={{ top: 0, right: 0 }}>
									<i className='fa fa-times' />
								</div>
							)}
						</InputGroup>
						<InputGroupAddon addonType='append'>
							<Button type='button' color='primary' onClick={() => this.searchContent(this.state.contentSearchInput)}>
								<i className={'fa fa-search'}>&nbsp;</i>
								{t('search')}
							</Button>
						</InputGroupAddon>
					</InputGroup>
				</FormGroup>
			</>
		);
	}
}

function mapDispatchToProps(dispatch: any) {
	return {
		dispatch: (action: any) => dispatch(action),
		updateSearchFilterTeam: (text: string) => dispatch(teamSearchFilterUpdate(text)),
		updateSearchFilterPlayer: (text: string) => dispatch(playerSearchFilterUpdate(text)),
		updateSearchFilterCoach: (text: string) => dispatch(coachSearchFilterUpdate(text)),
	};
}

export default connect(null, mapDispatchToProps)(SearchBar);
