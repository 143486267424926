import moment from 'moment';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Row } from 'reactstrap';
import React from 'react';
import MatchModel from '../../models/match/match.model';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { requestMatchLineup } from '../../../../../store/action-creators/match-lineups-action-creator';
import { toggleGoals, printMinutesIfMatchInProgress } from '../../../../Partials/Sidebar/tags/subcomponents/matches/helpers/match.helper';
import { AppState } from '../../../../../store/store';
import { featuresService } from '../../../../../App';
import { FeatureTypes } from '../../../../../services/feature-service/features.enum';
import BlacklistModel from '../../../../../models/team-manual-data/blacklist/blacklist';

type Properties = {
	t: any;
	loading: boolean;
	history: any;
};

const MatchesContainer: React.FunctionComponent<RouteComponentProps<{}> & Properties> = (props) => {
	let matches = useSelector((state: AppState) => state.matchLineupsEdit.matches);

	const { t, loading, history } = props;
	const dispatch = useDispatch();

	const onEditMatch = (match: MatchModel) => {
		dispatch(requestMatchLineup(match));
		history.push(`/smp/matches/edit/${match.id}`);
	};

	matches = filterMatchesByTeamsFeature(matches);

	return (
		<div className='px-1 py-3'>
			<Row className='font-weight-bold py-2 border-top border-bottom'>
				<Col xs={3} sm={2}>
					{props.t('date')}
				</Col>
				<Col xs={5} sm={6}>
					{props.t('match')}
				</Col>
				<Col xs={3} sm={2}>
					{t('status')}
				</Col>
				<Col xs={3} sm={2} className='text-right'>
					{props.t('actions')}
				</Col>
			</Row>
			<div className={`${loading ? 'loading-overlay' : ''}`}>
				{matches && matches.length > 0 ? (
					matches.map((match: MatchModel, index: number) => {
						return (
							<Row
								key={match.id}
								id={`match-row-${match.id}`}
								className={`py-2 border-bottom align-items-center ${index % 2 === 1 ? '' : 'bg-light'}`}
							>
								<Col className='pl-2' xs={3} sm={2}>{`${moment(match.startTime).format('DD MMM YYYY')}`}</Col>
								{match.homeTeam && match.awayTeam && (
									<Col xs={5} sm={6}>
										<Link className='text-dark' to={`/smp/matches/edit/${match.id}`} onClick={() => dispatch(requestMatchLineup(match))}>
											<span>{match.homeTeam.name}</span>
											{toggleGoals(match)}
											<span>{match.awayTeam.name}</span>
											{printMinutesIfMatchInProgress(match)}
										</Link>
									</Col>
								)}
								<Col xs={3} sm={2}>
									{match.blacklist && match.blacklist.id && (
										<div className='d-flex'>
											<div className='blacklisted bg-warning text-dark text-center'>{t('blacklisted')}</div>
											<i className='text-white fa fa-futbol-o rounded-circle mr-1 team-edit-icon ml-1' />
										</div>
									)}
								</Col>
								<Col xs={3} sm={2} className='text-right'>
									<Button id={`match-edit-btn-${match.id}`} color='warning' onClick={() => onEditMatch(match)}>
										<i className='fa fa-pencil p-1' />
										{t('edit')}
									</Button>
								</Col>
							</Row>
						);
					})
				) : (
					<div className='p-4 text-center'>{t('no_matches')}</div>
				)}
			</div>
		</div>
	);
};

const filterMatchesByTeamsFeature = (matches: MatchModel[]): MatchModel[] => {
	const teamsToFilter = featuresService.getFeatureConfig(FeatureTypes.LIMIT_FOOTBALL_MANUAL_DATA).teams;
	let filteredMatches: MatchModel[] = [...matches];

	if (featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.LIMIT_FOOTBALL_MANUAL_DATA)) {
		return filteredMatches.filter((match: MatchModel) => {
			const homeTeamId = match.homeTeam ? `${match.homeTeam.id}` : '';
			const awayTeamId = match.awayTeam ? `${match.awayTeam.id}` : '';

			return teamsToFilter.includes(homeTeamId) || teamsToFilter.includes(awayTeamId);
		});
	} else {
		return matches;
	}
};

export default withRouter(MatchesContainer);
