import OnnetworkVideoModel from './onnetwork-video.model';
import { OnnetworkVideoJson } from './onnetwork-video.json';
import { OnnetworkVideoRequestJson } from './onnetwork-video.request-json';

export default class OnnetworkVideoBuilder {
	private json: OnnetworkVideoJson;

	constructor(video?: OnnetworkVideoModel | OnnetworkVideoJson) {
		if (video && video instanceof OnnetworkVideoModel) {
			this.json = video.toJson();
		} else if (video) {
			this.json = video;
		} else {
			this.json = {} as OnnetworkVideoJson;
		}
	}

	public withId(id: string): OnnetworkVideoBuilder {
		this.json.id = id;

		return this;
	}

	public withTitle(title: string): OnnetworkVideoBuilder {
		this.json.title = title;

		return this;
	}

	public withTags(tags: string): OnnetworkVideoBuilder {
		this.json.tags = tags;

		return this;
	}

	public withEmbedCode(embedCode: { script: string; code: string }): OnnetworkVideoBuilder {
		this.json.embedCode = embedCode;

		return this;
	}

	public withDescription(description: string): OnnetworkVideoBuilder {
		this.json.description = description;

		return this;
	}

	toRequestJson(): OnnetworkVideoRequestJson {
		let json = {} as OnnetworkVideoRequestJson;
		json.mediaid = this.json.id;
		json.title = this.json.title;
		json.embed_code = this.json.embedCode.script;
		json.description = this.json.description;

		return json;
	}

	public build(): OnnetworkVideoModel {
		return OnnetworkVideoModel.fromJSON(this.json);
	}
}
