import React, { FunctionComponent, useEffect, useState } from 'react';
import { customBlocksService } from '../../../App';
import CustomBlockModel from '../../../models/v2/custom-blocks/custom-block/custom-block.model';
import { CustomBlock } from './custom-block.component';

export const CustomBlockContainer: FunctionComponent<any> = ({ t }) => {
	const [customBlock, setCustomBlock] = useState({} as CustomBlockModel);
	const [contentId, setContentId] = useState<string>('');
	const [mode, setMode] = useState<string>('');

	useEffect(() => {
		const path = window.location.hash;
		if (path.includes('/edit')) {
			let id = path.split('/')[4];
			setContentId(id.toString());
			setMode('edit');
			initPageTitle('edit');
		} else {
			setMode('create');
			initPageTitle('create');
		}
	}, []);

	const initPageTitle = (mode: string) => {
		document.title = t(`custom_block_${mode}`);
	};

	useEffect(() => {
		if (contentId) {
			customBlocksService
				.getCustomBlock(contentId)
				.then((data) => {
					if (data) {
						setCustomBlock(data);
					}
				})
				.catch((e) => {
					console.error(e);
				});
		}
	}, [contentId.length]);
	return (
		<CustomBlock
			customBlock={customBlock}
			t={t}
			mode={mode}
			onCustomBlockChange={(customBlockChanged: CustomBlockModel) => {
				setCustomBlock(customBlockChanged);
			}}
		/>
	);
};
