import GalleryBlockModel from '../models/gallery-block.model';
import { extractMainImageSrc } from '../../content-block/helpers/main-image.helper';
import PaginationModel from '../../../../../../../models/v2/Pagination/PaginationModel';
import { gettyResource, ImagesV1ModelRequestData, imagoResource } from '../../../../../Sidebar/media-refactored/constants/general';
import Image from '../../../../../../../models/image/Image';
import ModelMapper from '../../../../../../../models/ModelMapper';
import ImagoImageModel from '../../../../../../../models/v2/imago-image/imago-image';
import ImagoHttpService from '../../../../../../../services/rest/imago/ImagoHttpService';
import { toast } from 'react-toastify';
import { store } from '../../../../../../../store/store';
import HttpService from '../../../../../../../services/rest/HttpService';
import { isWatermarkAvailableForContent } from '../../../../../Sidebar/media-refactored/helpers/general';
import { extractMainContentTypeBasedOnUrl } from '../../../../../../../global-helpers/global.helpers';
import i18n from 'i18next';
import GettyImageModel from '../../../../../../v2/getty/models/getty-image.model';
import { gettyToJson, handlePostGettyImageError } from '../../../../../../v2/getty/helpers/getty-images.helper';
import GettyApiService from '../../../../../../v2/getty/helpers/getty-api.service';

export enum DATA_QA_ATTRIBUTES {
	GALLERY_BLOCK_ITEMS_WRAPPER = 'gallery-block-images-content-wrapper',
	TOGGLE_IMAGES = 'gallery-block-toggle-images-list',
	TOGGLE_IMAGES_BUTTON = 'gallery-block-toggle-images-list-button',
	IMAGES_CONTENT_SEARCH = 'gallery-block-images-search-input',
	IMAGES_CONTENT_SEARCH_BUTTON = 'gallery-block-images-search-button',
	IMAGES_ADVANCED_CONTENT_FILTERS = 'gallery-block-images-advanced-content-filters-wrapper',
	TOGGLE_IMAGO_IMAGES = 'gallery-block-toggle-imago-images-list',
	TOGGLE_GETTY_IMAGES = 'gallery-block-toggle-getty-images-list',
	GETTY_IMAGES = 'gallery-block-getty-images-list',
}

export const remapResponceToGallery = (response: any) => {
	if (response && Object.entries(response).length > 0) {
		return GalleryBlockModel.builder()
			.withId(response.id)
			.withTitle(response.title)
			.withSubtitile(response.subtitle)
			.withPublishedAt(response.published_at)
			.withItems(response.items)
			.withStatus(response.status)
			.withCategory(response.category)
			.withAdditionalCategories(response.additional_categories)
			.withImage(extractMainImageSrc(response.main_media))
			.withMainImage(response.image && response.image.data)
			.withLanguage(response.language)
			.build();
	}

	return GalleryBlockModel.builder().build();
};

export const setStateVariablesAfterRequest = (
	responsePromise: Promise<any>,
	setImages: (value: Image[]) => void,
	setPagination: (value: PaginationModel) => void,
	setIsContentLoading: (value: boolean) => void,
) => {
	return responsePromise
		.then((response: any) => {
			const images = ModelMapper.remapImageListFromResponse(response.data.data);
			const pagination = ModelMapper.remapMetaPagination(response.data.meta.pagination);
			return { images, pagination } as ImagesV1ModelRequestData;
		})
		.then(({ images, pagination }) => {
			setImages(images);
			setPagination(pagination);
		})
		.catch((error: unknown) => console.error(error))
		.finally(() => setIsContentLoading(false));
};

const imagoToJson = (image: ImagoImageModel, imagePath: string, projectOriginId: string | null) => {
	return {
		generic: {
			imago_id: image.id,
			type: image.type,
			width: image.width,
			height: image.height,
			image_url: image.image,
			description: image.description,
			source: image.source,
			dateCreated: image.dateCreated,
		},
		origin_id: projectOriginId,
		path: imagePath,
		urls: {
			uploaded: {
				original: imagePath,
				gallery: `${imagePath}?operations=fit(1920:)`,
				thumbnail: `${imagePath}?operations=autocrop(256:256)`,
				embed: `${imagePath}?operations=fit(770:)`,
			},
		},
	};
};

const checkIfArrayContainsSameImageID = (array: object[], imagoID: string) => {
	let imagoImageIsAlreadyUploaded = false;
	array.forEach((imageObject) => {
		if (Object.keys(imageObject).includes(imagoID)) {
			imagoImageIsAlreadyUploaded = true;
		}
	});
	return imagoImageIsAlreadyUploaded;
};

const findImageForGalleryItems = (alreadyUploadedImagoImages: Record<string, Image>[], imagoID: string): Record<string, Image>[] | null => {
	return alreadyUploadedImagoImages[imagoID] || null;
};

const constructRequestFromFilePath = (image: ImagoImageModel, imagePaths: string[], originId: string | null, headers: any) => {
	return imagePaths.map((path: string) => HttpService.post('/images', imagoToJson(image, path, originId), headers));
};

const addImagoImageIDtoState = (
	alreadyUploadedImagoImages: Record<string, Image>[],
	image: ImagoImageModel,
	galleryItemObj: Image,
): Record<string, Image>[] => {
	return [...alreadyUploadedImagoImages, { [image.id]: galleryItemObj }];
};

const handlePostImagoImageError = (err: any) => {
	if (err.response) {
		if (err.response.status == 415) {
			toast.error(i18n.t('imago_upload_fail'));
			toast.info(i18n.t('imago_415_info'));
		} else {
			toast.error(i18n.t('imago_upload_fail'));
		}
	} else {
		console.error('imago error', err);
	}
};

export const getGenericImagoId = (value: any) => {
	return value && value[0] && value[0].generic && value[0].generic.imago_id ? [value[0].generic.imago_id] : [];
};

const checkIfStateContainsImagoID = (imagoID: string, stateImagoID: string[]) => stateImagoID.includes(imagoID);

const getImagoIDinGallery = (genericData: any): Array<any> => {
	const contentTypeFromUrl = extractMainContentTypeBasedOnUrl();
	return isWatermarkAvailableForContent(contentTypeFromUrl) ? getGenericImagoId(genericData) : [];
};

const postImagoImageOnImageApi = (
	image: ImagoImageModel,
	form: FormData,
	alreadyUploadedImagoImages: Record<string, Image>[],
	setAlreadyUploadedImagoImages: (data: Record<string, Image>[]) => void,
	setImageToGalleyItems: (data: Image[]) => void,
): Promise<any> => {
	const projectDomain: string = store.getState().project.currentProject.domain || '';
	const imageOrigins = (store.getState().origins && store.getState().origins.imageOrigins) || [];
	const imageOrigin = imageOrigins.find((el: Record<string, any>) => el.slug === imagoResource) || null;
	const headers = { Project: projectDomain, 'content-type': 'multipart/form-data' };
	let imagePaths: string[] = [];

	// Post the imago image on the image API
	return HttpService.postImg('/upload', form, headers)
		.then((res: any) => {
			const imagePath = res.data.path;
			imagePaths.push(imagePath);
			// Create the image on the Content API
			if (image && imagePaths && imagePaths.length > 0) {
				const requestFromFilePath = constructRequestFromFilePath(image, imagePaths, imageOrigin.id, { Project: projectDomain });
				HttpService.all(requestFromFilePath).then((res: Array<any>) => {
					//Set image as MainMedia
					const responseData = (res && res[0] && res[0].data && res[0].data.data) || null;
					if (responseData) {
						const imagoImageAsImageModel = ModelMapper.remapImageFromResponse(responseData);
						const items = [];
						items.push(imagoImageAsImageModel);
						setImageToGalleyItems(items);
						const addedImagoToAlreadyUploadedArray = addImagoImageIDtoState(alreadyUploadedImagoImages, image, imagoImageAsImageModel);
						setAlreadyUploadedImagoImages(addedImagoToAlreadyUploadedArray);
						toast.success(i18n.t('imago_gallery_success'));
					}
				});
			}
		})
		.catch((err: any) => handlePostImagoImageError(err));
};

export const uploadImagoImage = (
	galleryItems: Image[],
	image: ImagoImageModel,
	genericData: Record<string, string>,
	alreadyUploadedImagoImages: Record<string, Image>[],
	setAlreadyUploadedImagoImages: (data: Record<string, Image>[]) => void,
	setImageToGalleyItems: (data: Image[]) => void,
) => {
	const form = new FormData();
	const imagoHttp = new ImagoHttpService();

	let imagoID: string[] = getImagoIDinGallery(genericData);
	if (!checkIfStateContainsImagoID(image.id, imagoID) && !checkIfArrayContainsSameImageID(alreadyUploadedImagoImages, image.id)) {
		imagoHttp
			.downloadImagoImage(image.type, image.id)
			.then(({ data }) => {
				data && data.size && image && image.id && form.append('file', data, `${image.id}.jpeg`);
			})
			.then(() => postImagoImageOnImageApi(image, form, alreadyUploadedImagoImages, setAlreadyUploadedImagoImages, setImageToGalleyItems))
			.catch(() => toast.error(i18n.t('fetch_image_fail')));
	} else if (checkIfArrayContainsSameImageID(alreadyUploadedImagoImages, image.id)) {
		const reuseGalleryItem = findImageForGalleryItems(alreadyUploadedImagoImages, image.id);
		if (reuseGalleryItem) {
			const items = [];
			items.push(reuseGalleryItem);
			setImageToGalleyItems(items);
			toast.success(i18n.t('imago_gallery_success'));
		}
	} else {
		toast.error(i18n.t('image_update_failed'));
	}
};

// Getty functionality
const constructGettyRequestFromFilePath = (
	image: GettyImageModel,
	imagePaths: string[],
	originId: string | null,
	headers: any,
	downLoadSize: string,
) => {
	return imagePaths.map((path: string) => HttpService.post('/images', gettyToJson(image, path, originId, downLoadSize), headers));
};

const addGettyImageIDtoState = (
	alreadyUploadedImagoImages: Record<string, Image>[],
	image: GettyImageModel,
	galleryItemObj: Image,
): Record<string, Image>[] => {
	return [...alreadyUploadedImagoImages, { [image.id]: galleryItemObj }];
};

export const getGenericGettyId = (value: any) => {
	return value && value[0] && value[0].generic && value[0].generic.getty_id ? [value[0].generic.getty_id] : [];
};

const checkIfStateContainsGettyID = (gettyID: string, stateGettyID: string[]) => stateGettyID.includes(gettyID);

const getGettyIDinGallery = (genericData: any): Array<any> => {
	const contentTypeFromUrl = extractMainContentTypeBasedOnUrl();
	return isWatermarkAvailableForContent(contentTypeFromUrl) ? getGenericGettyId(genericData) : [];
};

const postGettyImageOnImageApi = (
	image: GettyImageModel,
	form: FormData,
	alreadyUploadedImagoImages: Record<string, Image>[],
	setAlreadyUploadedImagoImages: (data: Record<string, Image>[]) => void,
	setImageToGalleyItems: (data: Image[]) => void,
	downLoadSize: string,
): Promise<any> => {
	const projectDomain: string = store.getState().project.currentProject.domain || '';
	const imageOrigins = (store.getState().origins && store.getState().origins.imageOrigins) || [];
	const imageOrigin = imageOrigins.find((el: Record<string, any>) => el.slug === gettyResource) || null;
	const headers = { Project: projectDomain, 'content-type': 'multipart/form-data' };
	let imagePaths: string[] = [];

	// Post the imago image on the image API
	return HttpService.postImg('/upload', form, headers)
		.then((res: any) => {
			const imagePath = res.data.path;
			imagePaths.push(imagePath);
			// Create the image on the Content API
			if (image && imagePaths && imagePaths.length > 0) {
				const requestFromFilePath = constructGettyRequestFromFilePath(
					image,
					imagePaths,
					imageOrigin.id,
					{ Project: projectDomain },
					downLoadSize,
				);
				HttpService.all(requestFromFilePath).then((res: Array<any>) => {
					//Set image as MainMedia
					const responseData = (res && res[0] && res[0].data && res[0].data.data) || null;
					if (responseData) {
						const gettyImageAsImageModel = ModelMapper.remapImageFromResponse(responseData);
						const items = [];
						items.push(gettyImageAsImageModel);
						setImageToGalleyItems(items);
						const addedImagoToAlreadyUploadedArray = addGettyImageIDtoState(alreadyUploadedImagoImages, image, gettyImageAsImageModel);
						setAlreadyUploadedImagoImages(addedImagoToAlreadyUploadedArray);
						toast.success(i18n.t('getty_gallery_success'));
					}
				});
			}
		})
		.catch((err: any) => handlePostGettyImageError(err));
};

export const uploadGettyImage = (
	galleryItems: Image[],
	image: GettyImageModel,
	downloadSize: string,
	genericData: Record<string, string>,
	alreadyUploadedImagoImages: Record<string, Image>[],
	setAlreadyUploadedImagoImages: (data: Record<string, Image>[]) => void,
	setImageToGalleyItems: (data: Image[]) => void,
	gettyInstance: GettyApiService,
	setIsGettyCollapseOpen: (data: boolean) => void,
	setRequestsExecuting: (data: boolean) => void,
) => {
	const form = new FormData();
	//Download the Getty Image
	let gettyID: string[] = getGettyIDinGallery(genericData);
	let productType: string = '';

	productType = image.productTypes[0];

	if (!checkIfStateContainsGettyID(image.id, gettyID) && !checkIfArrayContainsSameImageID(alreadyUploadedImagoImages, image.id)) {
		gettyInstance
			.downloadGettyImage(image.id, downloadSize, productType)
			.then(({ data }) => {
				data && data.size && image && image.id && form.append('file', data, `GettyImages-${image.id}.jpeg`);
			})
			.then(() =>
				postGettyImageOnImageApi(image, form, alreadyUploadedImagoImages, setAlreadyUploadedImagoImages, setImageToGalleyItems, downloadSize),
			)
			.then(() => {
				setRequestsExecuting(false);
			})
			.catch(() => toast.error(i18n.t('fetch_image_fail')));
	} else if (checkIfArrayContainsSameImageID(alreadyUploadedImagoImages, image.id)) {
		const reuseGalleryItem = findImageForGalleryItems(alreadyUploadedImagoImages, image.id);
		if (reuseGalleryItem) {
			const items = [];
			items.push(reuseGalleryItem);
			setImageToGalleyItems(items);
			toast.success(i18n.t('getty_gallery_success'));
		}
	} else {
		toast.error(i18n.t('image_update_failed'));
	}
};
