import TeamModel from '../../team-select/models/team.model';
import MatchListConfigBuilder from './match-list-config.builder';
import { MatchListConfigJson } from './match-list-config.json';
import { OddsConfiguration } from './odds-configuration.json';
import OddClientBookmakersModel from '../../available-bookmakers-select/models/odd-client-bookmakers.model';

export default class MatchListConfigModel {
	readonly view: string;
	readonly grouping: string;
	readonly order: string;
	readonly orderDirection: string;
	readonly oddsConfiguration: OddsConfiguration;
	readonly from: string;
	readonly to: string;
	readonly title: string;
	readonly viewMoreLabel: string;
	readonly bookmaker: OddClientBookmakersModel | null;
	readonly threeLetterCodeType: any = '';

	private constructor(
		view: string,
		grouping: string,
		order: string,
		orderDirection: string,
		from: string,
		to: string,
		oddsConfiguration: OddsConfiguration,
		title: string,
		viewMoreLabel: string,
		bookmaker: OddClientBookmakersModel | null,
		threeLetterCodeType: any,
	) {
		this.view = view;
		this.grouping = grouping;
		this.order = order;
		this.orderDirection = orderDirection;
		this.from = from;
		this.to = to;
		this.oddsConfiguration = oddsConfiguration;
		this.title = title;
		this.viewMoreLabel = viewMoreLabel;
		this.bookmaker = bookmaker;
		this.threeLetterCodeType = threeLetterCodeType;
	}

	toJson(): MatchListConfigJson {
		return {
			view: this.view,
			grouping: this.grouping,
			order: this.order,
			orderDirection: this.orderDirection,
			from: this.from,
			to: this.to,
			oddsConfiguration: this.oddsConfiguration,
			title: this.title,
			viewMoreLabel: this.viewMoreLabel,
			bookmaker: this.bookmaker,
			threeLetterCodeType: this.threeLetterCodeType,
		};
	}

	static fromJson(json: MatchListConfigJson): MatchListConfigModel {
		return new MatchListConfigModel(
			json.view,
			json.grouping,
			json.order,
			json.orderDirection,
			json.from,
			json.to,
			json.oddsConfiguration,
			json.title,
			json.viewMoreLabel,
			json.bookmaker,
			json.threeLetterCodeType,
		);
	}
	static builder(match?: MatchListConfigModel): MatchListConfigBuilder {
		return new MatchListConfigBuilder(match);
	}
}
