import React, { FunctionComponent, useEffect, useState } from 'react';
import { Button, Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import Image from '../../../../../../../models/image/Image';
import { DATA_QA_ATTRIBUTES, uploadImagoImage } from '../helpers/gallery-block.helper';
import { featuresService } from '../../../../../../../App';
import { FeatureTypes } from '../../../../../../../services/feature-service/features.enum';
import ImagoImageModel from '../../imago-image-block/models/imago-image.model';
import ImagoModal from '../../../../../Sidebar/media-refactored/subcomponents/toggle-images/imago-image-listing/imago-modal';

type Properties = {
	onGalleryItemsChange: (data: Image[]) => void;
	galleryItems: Image[];
};

const GalleryBlockImagoImageListing: FunctionComponent<Properties> = ({ onGalleryItemsChange, galleryItems }) => {
	const [t] = useTranslation();
	const imagoEnabled = featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.IMAGO_INTEGRATION);
	const [alreadyUploadedImagoImages, setAlreadyUploadedImagoImages] = useState<Record<number, Image>[]>(galleryItems);
	const [isImagoCollapseOpen, setIsImagoCollapseOpen] = useState(false);

	if (!imagoEnabled) {
		return null;
	}

	const onImagoSelectImage = (image: ImagoImageModel) => {
		uploadImagoImage(galleryItems, image, {}, alreadyUploadedImagoImages, setAlreadyUploadedImagoImages, onGalleryItemsChange);
	};

	return (
		<Row>
			<Col>
				<Button
					className='m-0 p-0'
					color='link'
					onClick={() => setIsImagoCollapseOpen(!isImagoCollapseOpen)}
					id={DATA_QA_ATTRIBUTES.TOGGLE_IMAGO_IMAGES}
				>
					{t('toggle_imago')}
				</Button>
				<ImagoModal t={t} onSelect={onImagoSelectImage} isOpen={isImagoCollapseOpen} toggleModal={setIsImagoCollapseOpen} />
			</Col>
		</Row>
	);
};

export default GalleryBlockImagoImageListing;
