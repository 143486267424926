import { ContentMode } from '../../constants/content-types';
import Wiki from '../../models/wiki/Wiki';

export const WIKI_TEMP_UPDATE = '[ACTION] WIKI_TEMP_UPDATE';
export const WIKI_TEMP_EDIT_UPDATE = '[ACTION] WIKI_TEMP_EDIT_UPDATE';
export const WIKI_TEMP_CLEAR = '[ACTION] WIKI_TEMP_CLEAR';
export const WIKI_TEMP_EDIT_UPDATE_BEFORE_REDIRECTING = '[ACTiON] WIKI_TEMP_EDIT_UPDATE_BEFORE_REDIRECTING';
export const WIKI_TEMP_PROPERTY_UPDATE = '[ACTION] WIKI_TEMP_PROPERTY_UPDATE';

export function updateTempWiki(wiki: Wiki) {
	return {
		type: WIKI_TEMP_UPDATE,
		payload: wiki,
	};
}

export function updateTempEditWiki(wiki: Wiki) {
	return {
		type: WIKI_TEMP_EDIT_UPDATE,
		payload: wiki,
	};
}

export function updateTempWikiProperty(propertyObject: Record<string, any>, contentMode: ContentMode) {
	return {
		type: WIKI_TEMP_PROPERTY_UPDATE,
		payload: propertyObject,
		contentMode,
	};
}

export function updateTempEditWikiBeforeRedirecting() {
	return {
		type: WIKI_TEMP_EDIT_UPDATE_BEFORE_REDIRECTING,
	};
}

export function clearTempWiki() {
	return {
		type: WIKI_TEMP_CLEAR,
	};
}
