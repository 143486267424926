import Project from '../../models/project/Project';
import ErrorResponseModel from '../../models/error-response/ErrorResponseModel';
import Related from '../../models/related/Related';
import Video from '../../models/video/Video';
import List from '../../models/list/List';
import PaginationMeta from '../../models/pagination/PaginationMeta';

export const VIDEOS_REQUEST = '[ACTION] VIDEOS_REQUEST';

export const VIDEO_SEARCH = '[ACTION] VIDEO_SEARCH';
export const VIDEO_SEARCH_FAILED = '[ACTION] VIDEO_SEARCH_FAILED';

export const VIDEOS_RECEIVED = '[ACTION] VIDEOS_RECEIVED';
export const VIDEOS_FAILED = '[ACTION] VIDEOS_FAILED';

export const VIDEO_RELATED_RECEIVED = '[ACTION] VIDEO_RELATED_RECEIVED';
export const VIDEO_COPY_RELATED_RECEIVED = '[ACTION] VIDEO_COPY_RELATED_RECEIVED';

export const VIDEO_UPDATE = '[ACTION] VIDEO_UPDATE';
export const VIDEO_UPDATE_FAILED = '[ACTION] VIDEO_UPDATE_FAILED';
export const VIDEO_UPDATE_SUCCESS = '[ACTION] VIDEO_UPDATE_SUCCESS';

export const VIDEO_CREATE = '[ACTION] VIDEO_CREATE';
export const VIDEO_CREATE_SUCCESS = '[ACTION] VIDEO_CREATE_SUCCESS';
export const VIDEO_CREATE_FAILED = '[ACTION] VIDEO_CREATE_FAILED';

export const VIDEO_RELATED_CREATE_SUCCESS = '[ACTION] VIDEO_RELATED_CREATE_SUCCESS';

export const VIDEO_RELATED_UPDATE_SUCCESS = '[ACTION] VIDEO_RELATED_UPDATE_SUCCESS';

export const VIDEO_REQUEST = '[ACTION] VIDEO_REQUEST';
export const VIDEO_RECEIVED = '[ACTION] VIDEO_RECEIVED';

export const VIDEO_DELETE = '[ACTION] VIDEO_DELETE';
export const VIDEO_DELETE_SUCCESS = '[ACTION] VIDEO_DELETE_SUCCESS';
export const VIDEO_DELETE_FAILED = '[ACTION] VIDEO_DELETE_FAILED';

export const VIDEOS_CONTENT_STATISTICS_RECEIVED = '[ACTION] VIDEOS_CONTENT_STATISTICS_RECEIVED';
export const CONTENT_STATISTICS_VIDEO_ENTITY_RECEIVED = '[ACTION] CONTENT_STATISTICS_VIDEO_ENTITY_RECEIVED';

export const VIDEO_PAGINATION_UPDATE = '[ACTION] VIDEO_PAGINATION_UPDATE';

export const VIDEO_FILTERS_UPDATE = '[ACTION] VIDEO_FILTERS_UPDATE';
export const JW_VIDEO_FILTERS_UPDATE = '[ACTION] JW_VIDEO_FILTERS_UPDATE';
export const VIDEO_ALREADY_EXISTS = '[ACTION] VIDEO_ALREADY_EXISTS';

export const VIDEO_CREATE_RESOURCES_SUCCESS = '[ACTION] VIDEO_CREATE_RESOURCES_SUCCESS';

export function videosRequest(page: string, project: Project, text: string) {
	return {
		type: VIDEOS_REQUEST,
		payload: { page, project, text },
	};
}

export function returnObjectForVideoPaginationUpdate(pagination: PaginationMeta) {
	return {
		type: VIDEO_PAGINATION_UPDATE,
		payload: { pagination },
	};
}

export function videosReceived(videos: any) {
	return {
		type: VIDEOS_RECEIVED,
		payload: videos,
	};
}

export function videosFailed(error: any) {
	return {
		type: VIDEOS_FAILED,
		payload: ErrorResponseModel.builder().fromResponse(error).build(),
	};
}

export function videoCreate(video: Video, related: Related[], project: Project, list: null | List) {
	return {
		type: VIDEO_CREATE,
		payload: { video, related, project, list },
	};
}

export function videoCreateFailed() {
	return {
		type: VIDEO_CREATE_FAILED,
	};
}

export function videoCreateSuccess(id: string) {
	return {
		type: VIDEO_CREATE_SUCCESS,
		payload: id,
	};
}

export function videoRelatedCreateSuccess(id: string) {
	return {
		type: VIDEO_RELATED_CREATE_SUCCESS,
		payload: id,
	};
}

export function videoSearch(text: string, project: Project) {
	return {
		type: VIDEO_SEARCH,
		payload: { text, project },
	};
}

export function videoSearchFailed(error: any) {
	return {
		type: VIDEO_SEARCH_FAILED,
		payload: ErrorResponseModel.builder().fromResponse(error).build(),
	};
}

export function videoRequest(id: string, project: Project) {
	return {
		type: VIDEO_REQUEST,
		payload: { id, project },
	};
}

export function videoReceived(video: any) {
	return {
		type: VIDEO_RECEIVED,
		payload: video,
	};
}

export function videoUpdate(video: Video, related: Related[], project: Project) {
	return {
		type: VIDEO_UPDATE,
		payload: { video, related, project },
	};
}

export function videoUpdateFailed() {
	return {
		type: VIDEO_UPDATE_FAILED,
	};
}

export function videoUpdateSuccess() {
	return {
		type: VIDEO_UPDATE_SUCCESS,
	};
}

export function videoRelatedUpdateSuccess() {
	return {
		type: VIDEO_RELATED_UPDATE_SUCCESS,
	};
}

export function videoRelatedReceived(related: any, sports: any) {
	return {
		type: VIDEO_RELATED_RECEIVED,
		payload: { related, sports },
	};
}

export function videoCopyRelatedReceived(related: any, sports: any) {
	return {
		type: VIDEO_COPY_RELATED_RECEIVED,
		payload: { related, sports },
	};
}

export function videoDelete(id: string, project: Project) {
	return {
		type: VIDEO_DELETE,
		payload: { id, project },
	};
}

export function videoDeleteSuccess() {
	return {
		type: VIDEO_DELETE_SUCCESS,
	};
}

export function videoDeleteFailed() {
	return {
		type: VIDEO_DELETE_FAILED,
	};
}

export function returnObjectForVideosContentStatisticsReceived(videos: Video[], contentStatistics: any) {
	return {
		type: VIDEOS_CONTENT_STATISTICS_RECEIVED,
		payload: { videos, contentStatistics },
	};
}

export function returnObjectForContentStatisticsVideoEntityReceived(video: Video, contentStatistics: any) {
	return {
		type: CONTENT_STATISTICS_VIDEO_ENTITY_RECEIVED,
		payload: { video, contentStatistics },
	};
}

export function returnObjectForVideoFiltersUpdate(filters: any) {
	return {
		type: VIDEO_FILTERS_UPDATE,
		payload: { filters },
	};
}

export function returnObjectForJWVideoFiltersUpdate(filters: any) {
	return {
		type: JW_VIDEO_FILTERS_UPDATE,
		payload: { filters },
	};
}

export function returnObjectForVideoAlreadyExists() {
	return {
		type: VIDEO_ALREADY_EXISTS,
	};
}

export function triggerVideoCreateResourcesSuccess(id: number) {
	return {
		type: VIDEO_CREATE_RESOURCES_SUCCESS,
		payload: id,
	};
}
