import React, { FunctionComponent, useState } from 'react';
import { Col, Row } from 'reactstrap';
import SelectInputDesign from '../../../../../Partials/design-components/select/select';
import InputDesign from '../../../../../Partials/design-components/input/input';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { DATA_QA } from '../../../helpers/data-qa.properties';
import { extractContentModeBasedOnUrl } from '../../../../../../global-helpers/global.helpers';
import { updateCustomEntity } from '../../../../../../store/action-creators/custom-entities-action-creator';
import { CustomEntitiesTypes, UpdateCustomEntityReduxType } from '../../../helpers/custom-entities.helper';
import { ICustomEntityGeneral, ICustomEntityType } from '../../../models/models';

type Props = {
	languages: Record<string, string>[];
	projectDefaultLanguage: string;
	customEntity: ICustomEntityType;
	updateCustomEntity: UpdateCustomEntityReduxType;
};

const CustomEntityTranslations: FunctionComponent<Props> = ({ customEntity, languages, projectDefaultLanguage, updateCustomEntity }) => {
	const { t } = useTranslation();
	const [selectedLanguage, setSelectedLanguage] = useState(projectDefaultLanguage);
	const translations: ICustomEntityGeneral['translations'] = customEntity.translations || [];
	const isMultilingualProject = languages.length > 1;
	const areTranslationsEmpty = !(Array.isArray(translations) && translations.length > 0);
	const translationElIndex = !areTranslationsEmpty ? translations.findIndex((trans) => trans.language === selectedLanguage) : -1;
	const addressInputFlag =
		customEntity.entity_type === CustomEntitiesTypes.ORGANIZATION || customEntity.entity_type === CustomEntitiesTypes.PLACE;

	const languageOptions = languages.map(({ languageCode }) => {
		const isSameAsDefault = languageCode === projectDefaultLanguage;
		return { value: languageCode, name: isSameAsDefault ? `${t(languageCode)} (${t('default').toLocaleLowerCase()})` : t(languageCode) };
	});

	const updateTranslationsInRedux = (inputType: string, value: string) => {
		const newTranslations = !areTranslationsEmpty ? [...translations] : [];

		if (translationElIndex >= 0) {
			newTranslations[translationElIndex] = { ...newTranslations[translationElIndex], [inputType]: value };
		} else {
			newTranslations.push({ language: selectedLanguage, name: '', short_name: '', three_letter_code: '', [inputType]: value });
		}

		const objectForUpdating = { translations: newTranslations, default_language: projectDefaultLanguage };

		if (selectedLanguage === projectDefaultLanguage) {
			// if we selected the default language, we need to update the custom entity object core value also
			// ex: if we update the name in default language, we need to update the name in the custom entity object also (not only in the translations)
			objectForUpdating[inputType] = value;
			objectForUpdating.default_language = selectedLanguage;
		}

		updateCustomEntity(objectForUpdating);
	};

	return (
		<div id={DATA_QA.TRANSLATIONS}>
			<Row>
				<Col>
					<SelectInputDesign
						invokeFunc={(option) => setSelectedLanguage(option.value as string)}
						fieldId='language-select'
						labelText={t('translation_lang')}
						placeholderText={t('select')}
						options={languageOptions}
						selectedValue={selectedLanguage}
						disabled={!isMultilingualProject || !customEntity.name}
						infoMessage={{
							title: t('translation_lang_info_title'),
							description: t('translation_lang_info_description'),
						}}
					/>
				</Col>
			</Row>
			<Row>
				<Col>
					<InputDesign
						invokeFunc={(text) => updateTranslationsInRedux('name', text)}
						fieldId='name-input'
						labelText={t('name')}
						placeholderText={t('name')}
						value={translationElIndex >= 0 ? translations[translationElIndex].name : ''}
					/>
				</Col>
			</Row>
			<Row>
				<Col>
					<InputDesign
						invokeFunc={(text) => updateTranslationsInRedux('short_name', text)}
						fieldId='short-name-input'
						labelText={t('short_name')}
						placeholderText={t('short_name')}
						value={translationElIndex >= 0 ? translations[translationElIndex].short_name : ''}
					/>
				</Col>
				<Col>
					<InputDesign
						invokeFunc={(text) => updateTranslationsInRedux('three_letter_code', text)}
						fieldId='three-letter-code-input'
						labelText={t('three_letter_code')}
						placeholderText={t('three_letter_code')}
						value={translationElIndex >= 0 ? translations[translationElIndex].three_letter_code : ''}
					/>
				</Col>
			</Row>
			{addressInputFlag && (
				<Row>
					<Col>
						<InputDesign
							invokeFunc={(text) => updateTranslationsInRedux('address', text)}
							fieldId='address-input'
							labelText={t('address')}
							placeholderText={t('address')}
							value={translationElIndex >= 0 ? translations[translationElIndex].address : ''}
						/>
					</Col>
				</Row>
			)}
		</div>
	);
};

function mapStateToProps(state: any) {
	const contentMode = extractContentModeBasedOnUrl();
	const projectDefaultLanguage = state.project.currentProject.languages.defaultLanguageCode.languageCode || 'en';

	return {
		languages:
			state.project.currentProject.languages.all.length > 0
				? state.project.currentProject.languages.all
				: [{ languageCode: projectDefaultLanguage }],
		projectDefaultLanguage,
		customEntity: state.customEntities[contentMode] || {},
	};
}

function mapDispatchToProps(dispatch: any) {
	const contentMode = extractContentModeBasedOnUrl();

	return {
		updateCustomEntity: (dataForUpdate: Record<string, unknown>) => dispatch(updateCustomEntity(contentMode, dataForUpdate)),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomEntityTranslations);
