import React, { Component } from 'react';
import LazyLoad from 'react-lazy-load';
import { analyticsService } from '../../../../App';
import DangerouslySetInner from './dangerously-set-inner.component';
import CopyToClipboard from 'react-copy-to-clipboard';
import moment from 'moment';
import { Twitter } from 'reactjs-social-embed';
import './lazyload.scss';
import { extractTwitterIdFromScript } from '../../Blocky/blocks/content/embed-block/helpers/embed-social-view.helper';

const EmbedTypes = {
	TWITTER: 'twitter',
	YOUTUBE: 'youtube',
	INSTAGRAM: 'instagram',
	RSS: 'rss',
};

export default class LazyLoadSMP extends Component {
	eventClick = (text) => analyticsService.sendEvent('Click', 'News tracker', text);

	insertInBody = (embed, type) => {
		this.eventClick('Insert in body');
		type = type.toLowerCase() === 'youtube' ? 'video' : 'social';
		document.dispatchEvent(new CustomEvent('insertEmbedInBody', { detail: { content: embed, type } }));
	};

	shouldComponentUpdate(prevProps, prevState) {
		return prevProps.post.id !== this.props.post.id;
	}

	processInstagramPost() {
		if (window.instgrm) {
			window.instgrm.Embeds.process();
		}
	}

	render() {
		const { post, t, profile } = this.props;
		const twitterFromScriptRegex = /tweet-([0-9]{19})/;

		return (
			<div className={'lazy-load-item-container'}>
				<LazyLoad
					height={200}
					onContentVisible={() => {
						this.processInstagramPost();
					}}
				>
					<div id={post.id} key={post.id + Math.random()} className='shadow-sm p-2 m-2 rounded post-card border border-light'>
						<div className='pl-2 mb-1 text-info'> {post.sourceName}</div>
						<div className={'embed-code'}>
							{post.type.toLowerCase() !== EmbedTypes.RSS ? (
								<DangerouslySetInner html={post.embed} postType={post.type} />
							) : (
								<div dangerouslySetInnerHTML={{ __html: post.embed }} />
							)}
							{(post.type.toLowerCase() === EmbedTypes.YOUTUBE || post.type.toLowerCase() === EmbedTypes.INSTAGRAM) && (
								<span className='time-field'>{moment(post.timestamp).locale(profile.language.split('_')[0]).format('DD-MMM-YYYY HH:mm')}</span>
							)}
							{post.type.toLowerCase() === EmbedTypes.TWITTER && <Twitter id={extractTwitterIdFromScript(post.embed, twitterFromScriptRegex)} />}
						</div>
						<div className='post-buttons'>
							<a
								onClick={() => this.eventClick('Go to post')}
								target='_blank'
								title={t('go_to_post')}
								rel='noopener noreferrer'
								href={`${post.url}`}
							>
								<i className='fa fa-external-link text-info text-black-50' /> {t('go_to_post')}
							</a>
							{post.type.toLowerCase() !== EmbedTypes.RSS && (
								<>
									<div onClick={() => this.eventClick('Copy embed code')}>
										<CopyToClipboard text={post.embed}>
											<span title={t('copy_embed')}>
												<i className='fa fa-copy text-info text-black-50' /> {t('copy_embed')}
											</span>
										</CopyToClipboard>
									</div>
									<div title={t('insert_embed')} onClick={() => this.insertInBody(post.embed, post.type.toLowerCase())}>
										<i className='fa fa-sign-in text-info text-black-50' />
										{t('insert_embed')}
									</div>
								</>
							)}
						</div>
					</div>
				</LazyLoad>
			</div>
		);
	}
}
