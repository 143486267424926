import React from 'react';
import DangerouslySetInner from '../../../../social-feed-selection-sidebar/subcomponents/dangerously-set-inner.component';
import { noPreviewImage } from '../../../../../../constants/resource-constants';
import ImagoImageModel from './models/imago-image.model';
import BlockModel from '../../../models/block.model';
import { useTranslation } from 'react-i18next';
import { OPEN_TYPES } from '../../../partials/image-properties/image-properties.component';
import { openTypes } from '../../../constants/link.constants';

type Properties = {
	isLoading: boolean;
	imagoImage: ImagoImageModel;
	block: BlockModel;
};

const ImagoImageView: React.FunctionComponent<Properties> = ({ isLoading, imagoImage, block }) => {
	const [t] = useTranslation();
	const { link, linkText, linkOpenType } = block.data && Object.keys(block.data).length !== 0 && block.data.preview;
	const target = linkOpenType === OPEN_TYPES.newWindow ? 'target="_blank"' : 'target="_self"';

	if (imagoImage && imagoImage.image) {
		return (
			<div className={`${isLoading ? 'loading-overlay' : ''}`}>
				<div className='block-content'>
					<div className={`d-flex content-container`}>
						{imagoImage.image ? (
							<img className='content-image img-fluid mb-2 mb-md-0 rounded' alt={imagoImage.description} src={imagoImage.image} />
						) : (
							<img alt='' src={noPreviewImage} className='content-image img-fluid mb-2 mb-md-0 rounded' />
						)}
						{imagoImage.description && (
							<div className='gallery-title text-left'>
								<h6 className='m-0 p-0 col-lg-8 col-md-7 col-sm-6'>
									<DangerouslySetInner html={imagoImage.description} />
								</h6>
							</div>
						)}
					</div>
					<div>
						<h4 className='mb-2 mt-2'>
							<i className='fa fa-link mr-2' />
							<a href={link} {...target}>
								{linkText}
							</a>
						</h4>
						<p className='text-muted mb-2'>
							<small>
								{t('opens_in')}: <strong>{linkOpenType === openTypes.newWindow ? t('new_window') : t('same_window')}</strong>
							</small>
						</p>
					</div>
				</div>
			</div>
		);
	}

	return (
		<div className='position-relative text-center'>
			<img alt='' src={noPreviewImage} className='no-preview-image img-fluid mb-2 mb-md-0 rounded' />
		</div>
	);
};

export default ImagoImageView;
