import React, { useState } from 'react';
import { Button, ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import ListModel from '../../../../../../models/v2/list/list';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { updateListInRedux } from '../../../../../../store/action-creators/ListActionCreators';
import LiveBlogModel from '../../../models/live-blog.model';
import { DATA_QA_ATTRIBUTES } from '../../../../../Partials/Sidebar/general-content/constants/data-qa.attributes';
import { generateNewListItem } from './live-blog-lists.helper';

type Properties = {
	t: any;
	selectedList: ListModel;
	liveBlog: LiveBlogModel;
	updateListInRedux: (list: ListModel) => void;
};

const LiveBlogListItemAddButtons: React.FunctionComponent<Properties> = ({ t, selectedList, liveBlog, updateListInRedux }) => {
	const [dropdownOpen, setDropdownOpen] = useState({ isOpen: false, openedDropdownId: '' });
	const contentModelId = liveBlog.id ? liveBlog.id : '';
	const onListItemAdd = (addItemToTop: boolean = false) => {
		const newListItem = generateNewListItem(liveBlog);
		const newItems = addItemToTop ? [newListItem, ...selectedList.items] : [...selectedList.items, newListItem];
		updateListInRedux({ ...selectedList, items: newItems });
	};

	const toggle = (id: string) => {
		setDropdownOpen((prevState: any) => {
			return { isOpen: !prevState.isOpen, openedDropdownId: id };
		});
	};

	const addToTop = () => {
		selectedList.lockedPositions.length === 0 ? onListItemAdd(true) : toast.error(t('first_item_is_locked'));
	};

	return (
		<ButtonDropdown
			isOpen={dropdownOpen.openedDropdownId === contentModelId && dropdownOpen.isOpen}
			toggle={() => toggle(contentModelId)}
			className='ml-auto'
		>
			<Button
				outline
				id={DATA_QA_ATTRIBUTES.LIVE_BLOG_LIST_MODAL_ADD}
				color='primary'
				onClick={() => onListItemAdd()}
				className='list-content-add-btn'
			>
				<i className={'fa fa-plus'} />
			</Button>
			<DropdownToggle outline caret color='primary' className='list-content-add-btn' />
			<DropdownMenu>
				<DropdownItem key='dropdown-item-first-occurrence' id={DATA_QA_ATTRIBUTES.LIVE_BLOG_LIST_ADD_ITEM_TO_TOP} onClick={addToTop}>
					{t('add_to_top')}
				</DropdownItem>
			</DropdownMenu>
		</ButtonDropdown>
	);
};

function mapDispatchToProps(dispatch: Function) {
	return {
		updateListInRedux: (list: ListModel) => dispatch(updateListInRedux(list)),
	};
}

export default connect(null, mapDispatchToProps)(LiveBlogListItemAddButtons);
