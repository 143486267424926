import React, { useState } from 'react';
import { Input } from 'reactstrap';
import Select from 'react-select';
import { blService, uiService } from '../../../TournamentContainer';
import TeamModel from '../../../../../../models/v2/Team/Entity/TeamModel';
import { customOption } from '../../../../../Partials/Blocky/partials/shared/custom-select-option';
import { assetsPlaceholder } from '../../../../../../constants/assetsPlaceholder';

type Properties = {
	defaultStageTeams: TeamModel[];
	stageTeams: TeamModel[];
	setStageTeams: Function;
	setAddNewTeamButtonDisabled: Function;
	t: any;
};

export const ModalContent: React.FunctionComponent<Properties> = ({
	defaultStageTeams,
	stageTeams,
	setStageTeams,
	setAddNewTeamButtonDisabled,
	t,
}) => {
	const [searchedTeams, setSearchedTeams] = useState(new Array<TeamModel>());

	const searchForTeam = (inputValue: string) => {
		if (inputValue.length > 2) {
			blService.searchTournamentStageTeams(inputValue).then((teamsFound) => {
				setSearchedTeams(teamsFound);
			});
		}
	};

	const pickNewTeam = (el: any) => {
		const pickedTeam = el.data;
		const isAlreadyExist = stageTeams.findIndex((team) => team.id === pickedTeam.id) >= 0;
		if (!isAlreadyExist) {
			const withoutPickedTeam = stageTeams.filter((team) => team.id !== ''); // removing old team element without id (used only for input element rendering)
			setStageTeams([...withoutPickedTeam, pickedTeam]); // updating the old element with real data
			setSearchedTeams(new Array<TeamModel>());
			setAddNewTeamButtonDisabled(false);
		} else {
			uiService.clearAlreadyAddedTeamInSelect(el, setSearchedTeams, t('team_already_selected'));
		}
	};

	const removeTeam = (removedTeamId: string) => {
		if (removedTeamId === '') {
			setAddNewTeamButtonDisabled(false);
		}
		const teamWithoutRemoved = stageTeams.filter((el) => el.id !== removedTeamId);
		setStageTeams(teamWithoutRemoved);
	};

	return stageTeams && stageTeams.length > 0 ? (
		stageTeams.map((team: TeamModel) => {
			return (
				<div className='tournaments-modal-inner-wrapper' key={team.id}>
					{team.id && team.name ? (
						<>
							<div
								className='tournament-team-logo'
								style={{ backgroundImage: team.urlLogo ? `url("${team.urlLogo}")` : `url("${assetsPlaceholder.team}")` }}
							></div>
							<Input className='tournaments-modal-select normal-background' defaultValue={team.name} disabled />
						</>
					) : (
						<>
							<div className='tournament-team-logo'></div>
							<Select
								className='tournaments-modal-select'
								noOptionsMessage={(inputValue) => inputValue && t('no_options')}
								onInputChange={searchForTeam}
								onChange={pickNewTeam}
								options={uiService.teamsToOptions(searchedTeams)}
								formatOptionLabel={customOption}
								placeholder={t('search')}
							/>
						</>
					)}
					<button className='btn btn-danger' onClick={() => removeTeam(team.id)}>
						<i className='fa fa-trash'></i>
					</button>
				</div>
			);
		})
	) : (
		<>
			<p>{stageTeams}</p> <h3>{t('no_teams_added')}</h3>
		</>
	);
};
