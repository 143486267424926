import React from 'react';
import { Properties } from './properties/posts.properies';
import LazyLoadSMP from './lazyload';

const Posts: React.FunctionComponent<Properties> = ({ posts, t, profile }) => {
	if (posts.length > 0 && posts[0].id !== undefined) {
		return posts.map((post: any) => {
			return <LazyLoadSMP key={`${post.id + Math.random()}`} post={post} t={t} profile={profile} />;
		});
	}

	return <h6 className='text-center p-3 rounded shadow-sm'>{t('no_posts')}</h6>;
};

export default Posts;
