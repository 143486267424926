import TennisRankingBuilder from './tennis-ranking.builder';
import { TennisRankingWidgetJson } from './tennis-ranking-widget.json';
import AvailableStandingTypeModel from '../../../../../../../models/v2/standing-types/available-standing-type.model';
import RankingPlayerModel from '../../../../../../../models/v2/ranking-player/ranking-player.model';
import { RefreshTimeValuesType } from '../../../partials/refresh-time-input/helper';

export default class TennisRankingModel {
	readonly limit: string;
	readonly refreshTime: RefreshTimeValuesType;
	readonly offset: string;
	readonly availableStandingType: AvailableStandingTypeModel;
	readonly selectPlayers: boolean;
	readonly highlightPlayers: RankingPlayerModel[];

	private constructor(
		limit: string,
		refreshTime: RefreshTimeValuesType,
		offset: string,
		availableStandingType: AvailableStandingTypeModel,
		selectPlayers: boolean,
		highlightPlayers: RankingPlayerModel[],
	) {
		this.limit = limit;
		this.refreshTime = refreshTime;
		this.offset = offset;
		this.availableStandingType = availableStandingType;
		this.selectPlayers = selectPlayers;
		this.highlightPlayers = highlightPlayers;
	}

	toJson(): TennisRankingWidgetJson {
		return {
			limit: this.limit,
			refreshTime: this.refreshTime,
			offset: this.offset,
			availableStandingType: this.availableStandingType,
			selectPlayers: this.selectPlayers,
			highlightPlayers: this.highlightPlayers,
		};
	}

	static fromJson(json: TennisRankingWidgetJson): TennisRankingModel {
		return new TennisRankingModel(
			json.limit,
			json.refreshTime,
			json.offset,
			json.availableStandingType,
			json.selectPlayers,
			json.highlightPlayers,
		);
	}

	static builder(tennisRankingWidget?: TennisRankingModel): TennisRankingBuilder {
		return new TennisRankingBuilder(tennisRankingWidget);
	}
}
