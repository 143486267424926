import React from 'react';
import { multiLingualService } from '../../../../App';
import TranslationGroups from './translation-groups.component';

type Properties = {
	t: any;
	currentContentTranslations: any;
	contentType: string;
	content: any;
	analytics: any;
};

export const TranslationGroupsContainer: React.FunctionComponent<Properties> = (props) => {
	const { t, currentContentTranslations, contentType, content, analytics } = props;

	if (multiLingualService.checkIfContentCanBeTranslated(content)) {
		return (
			<TranslationGroups
				t={t}
				currentContentTranslations={currentContentTranslations}
				contentType={contentType}
				content={content}
				analytics={analytics}
			/>
		);
	}

	return <div>{t(`the_${contentType}_cant_be_translated`)}</div>;
};
