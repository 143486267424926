import React from 'react';
import { connect } from 'react-redux';
import BannerEdit from './banner-edit.component';
import { BannerView } from './banner-view.component';
import BannerBlockModel from './models/banner-block.model';
import { ViewTypes } from '../../../constants/general.constants';
import HttpService from '../../../../../../services/rest/HttpService';
import { Properties, State } from './properties/banner-block.properties';
import { blockManagementService, blockWrapperService } from '../../../subcomponents/blocky.component';
import ContentNotFoundController from '../content-not-found-controller.component';
import BlockValidation from '../../../helpers/block-validation.helper';

class BannerBlock extends React.Component<Properties, State> {
	constructor(props: Properties) {
		super(props);
		this.state = {
			banner:
				props.block.data && props.block.data.preview
					? props.block.data.preview.bannerBlock
					: BannerBlockModel.builder().withId('').withTitle('').build(),
			isLoading: false,
			isValid: true,
		};
	}

	shouldComponentUpdate(nextProps: Readonly<Properties>, nextState: Readonly<State>): boolean {
		return (
			nextProps.block.data.changeId !== this.props.block.data.changeId ||
			this.props.view !== nextProps.view ||
			this.props.lang !== nextProps.lang ||
			this.state.banner !== nextState.banner ||
			this.state.isLoading !== nextState.isLoading ||
			this.state.isValid !== nextState.isValid
		);
	}

	componentDidMount(): void {
		const { block } = this.props;

		if (block.data && block.data.preview && block.data.preview.bannerBlock) {
			this.getBanner(block.data.preview.bannerBlock.id);
		}
		blockWrapperService.registerOnSaveFunc({ blockId: this.props.block.id, func: this.onSave });
	}

	componentDidUpdate(prevProps: Properties, prevState: State) {
		if (this.state.banner.id !== prevState.banner.id) {
			this.getBanner(this.state.banner.id);
		}
	}

	onSave = (index: number) => {
		const data = { bannerId: this.state.banner.id };
		const validation = new BlockValidation();
		const isValid = validation.validate(this.props.block, data);
		this.setState({ ...this.state, isValid });

		if (isValid) {
			const block = Object.assign({}, this.props.block);
			block.data.id = this.state.banner.id;
			block.data.preview = { bannerBlock: this.state.banner };

			blockManagementService.blockUpdate(block, index);
		} else {
			return isValid;
		}
	};

	componentWillUnmount(): void {
		blockWrapperService.unregisterOnSaveFunc(this.props.block.id);
	}

	toggleIsLoadingState = (isLoading: boolean) => {
		this.setState({ ...this.state, isLoading });
	};

	updateBannerState = (banner: BannerBlockModel) => {
		this.setState({ ...this.state, banner });
	};

	getBanner(bannerId: string) {
		this.toggleIsLoadingState(true);
		HttpService.get(`/banners/${bannerId}`, null, { Project: this.props.domain })
			.then((response: any) => {
				this.updateBannerState(BannerBlockModel.builder(response.data.data).build());
				this.toggleIsLoadingState(false);
			})
			.catch((err: any) => {
				err.response && err.response.status === 404 && this.props.setIsContentFound(false);
				this.setState({ ...this.state, banner: BannerBlockModel.builder().build(), isLoading: false });
			});
	}

	render() {
		const { banner, isLoading } = this.state;
		const { domain, t, view, renderView } = this.props;

		if (view === ViewTypes.edit) {
			return <BannerEdit banner={banner} updateBannerState={this.updateBannerState} t={t} domain={domain} isValid={this.state.isValid} />;
		}
		if (view === ViewTypes.normal) {
			return renderView(<BannerView isLoading={isLoading} preview={banner} t={t} />);
		}

		return null;
	}
}

function mapStateToProps(state: any) {
	return {
		domain: state.project.currentProject.domain,
		lang: state.profile.profile.language,
	};
}

export default connect(mapStateToProps)(ContentNotFoundController(BannerBlock)) as React.ComponentType<any>;
