import React from 'react';
import { compose } from 'redux';
import { OddsV2ViewComponent } from './odds-v2-view.component';
import BlockModel from '../../../../../../models/block.model';
import { ViewTypes } from '../../../../../../constants/general.constants';
import { SportBlockTypes, SportTypes, WidgetScriptTypes } from '../../../../../../../../../constants/sport.types';
import { MonorepoWidgetPreview } from '../../../../../../blocks/widgets/widget-preview/monorepo-widget-preview.component';
import OddsV2Model from '../../../models/v2/odds-v2.model';
import BlockUpdateController from '../../../../../../blocks/block-update-controller.component';
import FootballOddsWidgetEdit from './odds-widget-v2-edit.component';

type Properties = {
	block: BlockModel;
	view: ViewTypes;
	t: any;
	contentData: any;
	contentLanguage: string;
};

const FootballOdds: React.FunctionComponent<Properties> = (props) => {
	const { view, t, block, contentData, contentLanguage } = props;

	if (view === ViewTypes.edit) {
		return <FootballOddsWidgetEdit block={block} t={t} contentData={contentData} contentLanguage={contentLanguage} />;
	}

	if (view === ViewTypes.preview) {
		return (
			<MonorepoWidgetPreview
				blockContent={props.block.data.content}
				config={props.block.data.config}
				type={WidgetScriptTypes.FOOTBALL_WIDGETS}
				blockType={SportBlockTypes.EVENT_BLOCK}
				sport={SportTypes.FOOTBALL}
			/>
		);
	}

	if (view === ViewTypes.normal) {
		return (
			<OddsV2ViewComponent
				preview={
					block && block.data && block.data.preview && block.data.preview.widgetModel ? block.data.preview.widgetModel : new OddsV2Model()
				}
			/>
		);
	}

	return null;
};

export default compose(BlockUpdateController)(FootballOdds);
