import { DugoutVideosJson } from './dugout-videos-json';
import DugoutVideosBuilder from './dugout-videos.builder';
import DugoutVideoModel from './dugout-video/dugout-video.model';

export default class DugoutVideosModel {
	readonly title: string;
	readonly videoList: DugoutVideoModel[];

	private constructor(title: string, videoList: DugoutVideoModel[]) {
		this.title = title;
		this.videoList = videoList;
	}

	toJSON(): DugoutVideosJson {
		return {
			title: this.title,
			videoList: this.videoList,
		};
	}

	static fromJSON(json: DugoutVideosJson): DugoutVideosJson {
		return new DugoutVideosModel(json.title, json.videoList);
	}

	static builder(videos?: DugoutVideosModel): DugoutVideosBuilder {
		return new DugoutVideosBuilder(videos);
	}
}
