import 'reflect-metadata';
import { AnyT, jsonMember, jsonObject } from 'typedjson';
import ImageUrlsModel from './image-urls.model';

@jsonObject()
export default class ImageModel {
	@jsonMember(String)
	public id: string | null = null;

	@jsonMember(String)
	public description: string | null = null;
	@jsonMember(String)
	public type: string | null = null;
	@jsonMember(AnyT)
	public owner: any = null;
	@jsonMember(AnyT)
	public generic: any = null;
	@jsonMember(String)
	public alt: string | null = null;
	@jsonMember(String)
	public caption: string | null = null;
	@jsonMember(String, { name: 'created_at' })
	public createdAt: string | null = null;
	@jsonMember(String, { name: 'updated_at' })
	public updatedAt: string | null = null;
	@jsonMember(AnyT)
	public authors: any = null;
	@jsonMember(String)
	public path: string | null = null;

	@jsonMember(ImageUrlsModel)
	public urls: ImageUrlsModel | null = null;
}
