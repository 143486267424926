import React from 'react';
import { Button, Col, Row } from 'reactstrap';
import ListModel from '../../../../../../models/v2/list/list';
import { store } from '../../../../../../store/store';
import { connect } from 'react-redux';
import {
	LIST_ENTITY_UPDATE,
	LIST_ENTITY_UPDATE_DIRECTLY,
	LIST_ENTITY_UPDATE_FAILED,
	LIST_ENTITY_UPDATE_SUCCESS,
	ListItemsWithId,
	returnObjectForListEntityUpdate,
} from '../../../../../../store/action-creators/ListActionCreators';
import Project from '../../../../../../models/project/Project';
import BlockableButtonWrapper from '../../../../BaseComponents/BlockableButton/BlockableButtonWrapper';
import { disableSaveListButton, isListEmpty } from '../../helpers/general.helper';
import { DATA_QA_ATTRIBUTES } from '../../constants/data-qa.attributes';

type Properties = {
	t: any;
	selectedList: ListModel | null;
	updateListWithPatchRequest: (list: ListItemsWithId, project: Project) => void;
};

const ListSave: React.FunctionComponent<Properties> = ({ t, selectedList, updateListWithPatchRequest }) => {
	const onSaveList = () => {
		if (selectedList) {
			const onlyListNeededData = {
				id: selectedList.id || '',
				items: selectedList.items || [],
			};
			updateListWithPatchRequest(onlyListNeededData, { ...store.getState().project.currentProject });
		}
	};

	return (
		<Row>
			<Col>
				<BlockableButtonWrapper
					blockOnActions={[LIST_ENTITY_UPDATE]}
					releaseOnActions={[LIST_ENTITY_UPDATE_FAILED, LIST_ENTITY_UPDATE_SUCCESS, LIST_ENTITY_UPDATE_DIRECTLY]}
					blockOverride={disableSaveListButton(selectedList)}
				>
					<Button
						id={DATA_QA_ATTRIBUTES.LIST_SAVE}
						color='primary'
						className='text-uppercase font-weight-bold mr-2'
						disabled={disableSaveListButton(selectedList)}
						onClick={onSaveList}
					>
						<i className='fa fa-floppy-o'></i> {t('save_list')}
					</Button>
				</BlockableButtonWrapper>
			</Col>
		</Row>
	);
};

function mapStateToProps(state: any) {
	return {
		projectDomain: state.project.currentProject.domain || '',
		selectedList: (!isListEmpty(state.list.list) && state.list.list) || null,
	};
}

function mapDispatchToProps(dispatch: any) {
	return {
		updateListWithPatchRequest: (list: ListItemsWithId, project: Project) => dispatch(returnObjectForListEntityUpdate(list, project, true)),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(ListSave);
