import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import React, { useState } from 'react';
import { DATA_QA_ATTRIBUTES } from '../../../../../Partials/Sidebar/general-content/constants/data-qa.attributes';
import AuthorModel from '../../../../../../models/v2/author/author.model';
import { LiveBlogEventsSelectContainer } from './live-blog-events-select.container';
import LiveBlogModel from '../../../models/live-blog.model';
import { useTranslation } from 'react-i18next';

type Properties = {
	isOpen: boolean;
	toggleModal: (isOpen: boolean) => void;
	pickNewEvent: Function;
	initialAdd: boolean;
	selectedEventForEdit: any;
	removeSelectedEvent: Function;
	liveBlog: LiveBlogModel;
	seasonId: string;
};

export const EventsModal: React.FunctionComponent<Properties> = ({
	toggleModal,
	isOpen,
	pickNewEvent,
	selectedEventForEdit,
	initialAdd,
	removeSelectedEvent,
	liveBlog,
	seasonId,
}) => {
	const [selectedEvent, setSelectedEvent] = useState(initialAdd ? ({} as AuthorModel) : selectedEventForEdit);
	const [selectedEventModel, setSelectedEventModel] = useState(initialAdd ? {} : selectedEventForEdit);
	const [t] = useTranslation();

	const onEventSelect = (data: any) => {
		if (data) {
			setSelectedEvent(data);
			setSelectedEventModel(data);
		}
	};

	return (
		<div data-qa={DATA_QA_ATTRIBUTES.LIVE_BLOG_EVENTS_MODAL}>
			<Modal size='lg' centered={true} fade={true} backdrop={true} isOpen={isOpen}>
				<ModalHeader
					toggle={() => {
						removeSelectedEvent();
						toggleModal(false);
					}}
				>
					{t('add_event')}
				</ModalHeader>
				<ModalBody>
					<div data-children='.item'>
						<div className='item'>
							<LiveBlogEventsSelectContainer
								selectedEvent={selectedEventModel}
								liveBlog={liveBlog}
								setSelectedLiveBlogEvents={onEventSelect}
								seasonId={seasonId}
							/>
						</div>
					</div>
				</ModalBody>
				<ModalFooter>
					<Button
						id={DATA_QA_ATTRIBUTES.LIVE_BLOG_EVENT_MODAL_CLOSE}
						color='danger'
						className='text-light'
						onClick={() => {
							removeSelectedEvent();
							toggleModal(false);
						}}
					>
						{t('close')}
					</Button>
					<Button
						disabled={!selectedEvent || !selectedEvent.id}
						id={DATA_QA_ATTRIBUTES.LIVE_BLOG_EVENT_MODAL_ADD}
						color='primary'
						className='text-light'
						onClick={() => {
							pickNewEvent(selectedEventModel);
							toggleModal(false);
						}}
					>
						{t('add')}
					</Button>
				</ModalFooter>
			</Modal>
		</div>
	);
};
