import debounce from 'lodash.debounce';
import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import AsyncSelect from 'react-select/async';
import { Option } from 'react-select/src/filters';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import Related from '../../../../../../models/related/Related';
import { responseToModelTags } from '../../../../../../models/v2/tag/tag.mapper';
import TagModel from '../../../../../../models/v2/tag/tag.model';
import HttpService from '../../../../../../services/rest/HttpService';
import { store } from '../../../../../../store/store';
import { DATA_QA_ATTRIBUTES } from '../../constants/data-qa';
import {
	generateFunctionForDispatch,
	remapReduxTagsPropertyToTagModel,
	remapTagModelsToReduxRelated,
	tagsResponseToOptions,
} from '../../helpers/tag-select.helper';

type Properties = {
	t: any;
	reduxTags: TagModel[] | null;
	updateTagTempRelated: Function;
};

const TagsSelect: FunctionComponent<Properties> = ({ t, reduxTags, updateTagTempRelated }) => {
	const modifyTags = (tagsSelection: any) => {
		if (tagsSelection && tagsSelection.length > 0) {
			const selectedTags = tagsSelection.map((tag: Option) => tag.data);
			const dataForRedux = remapTagModelsToReduxRelated(selectedTags);
			updateTagTempRelated(dataForRedux);
		} else {
			updateTagTempRelated([]);
		}
	};

	const loadOnType = (input: string, callback: Function) => {
		if (input.length > 2) {
			let headers = { Project: store.getState().project.currentProject.domain };
			HttpService.get(`/tags/search?query=${input.trim()}&page=1&limit=200`, null, headers).then((response: any) => {
				const fetchedTags: Array<any> = response.data.data;
				const remappedTags = fetchedTags.map((fetchedTag) => responseToModelTags(fetchedTag));
				const loadedTagsAsOptions = tagsResponseToOptions(remappedTags);
				callback(loadedTagsAsOptions);
			});
		}
	};

	return (
		<Row>
			<Col>
				<FormGroup>
					<Label htmlFor={DATA_QA_ATTRIBUTES.TAGS_SELECT}>{t('tags')}</Label>
					<AsyncSelect
						id={DATA_QA_ATTRIBUTES.TAGS_SELECT}
						isMulti={true}
						isClearable={true}
						placeholder={t('select')}
						noOptionsMessage={(inputValue) => inputValue && t('no_options')}
						loadOptions={debounce(loadOnType, 300)}
						onChange={modifyTags}
						value={tagsResponseToOptions(reduxTags)}
					/>
				</FormGroup>
			</Col>
		</Row>
	);
};

function mapStateToProps(state: any) {
	return {
		reduxTags: remapReduxTagsPropertyToTagModel(state.tempTagsRelated),
	};
}

function mapDispatchToProps(dispatch: any) {
	const generatedFunctionForUpdatingTagRelated = generateFunctionForDispatch();
	return {
		updateTagTempRelated: (related: Related[]) =>
			generatedFunctionForUpdatingTagRelated && dispatch(generatedFunctionForUpdatingTagRelated(related)),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(TagsSelect);
