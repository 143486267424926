import React, { FunctionComponent, useState } from 'react';
import { Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import { EventStatuses } from '../../../../../../constants/event.types';
import { SportTypes, SportTypesValue } from '../../../../../../constants/sport.types';
import SeasonResponseModel from '../../../../../../models/v2/Season/Entity/response-season.model';
import TournamentResponseModel from '../../../../../../models/v2/Tournament/Entity/response-tournament.model';
import EventsFilters from './events-filter';
import EventsScrollbar from './events-scroll';
import MatchesScrollbar from './matches-scroll';
import SelectedEvents from './selected-events';
import SportsTypesModel from '../../../../../../models/v2/sports-types/sports-types.model';
import { store } from '../../../../../../store/store';

type Properties = {
	t: any;
	selectedSport: SportsTypesModel;
};

const EventsContainer: FunctionComponent<Properties> = ({ t, selectedSport }) => {
	const [activeTab, setActiveTab] = useState<EventStatuses>(EventStatuses.UPCOMING);
	const [tournament, setTournament] = useState<TournamentResponseModel | null>(null);
	const [season, setSeason] = useState<SeasonResponseModel | null>(null);
	const [dateFrom, setDateFrom] = useState('');
	const [dateTo, setDateTo] = useState('');

	return (
		<>
			<Row>
				<SelectedEvents selectedSport={selectedSport} t={t} />
			</Row>
			<EventsFilters
				t={t}
				selectedSport={selectedSport}
				tournament={tournament}
				setTournament={setTournament}
				season={season}
				setSeason={setSeason}
				dateFrom={dateFrom}
				setDateFrom={setDateFrom}
				dateTo={dateTo}
				setDateTo={setDateTo}
			/>
			<Row id='match-tagging'>
				<Col>
					<Nav tabs>
						<NavItem>
							<NavLink
								id={`tab-link-${EventStatuses.UPCOMING}`}
								active={activeTab === EventStatuses.UPCOMING}
								onClick={() => setActiveTab(EventStatuses.UPCOMING)}
							>
								<i className='fa fa-calendar'></i> {t('upcoming_matches')}
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink
								id={`tab-link-${EventStatuses.FINISHED}`}
								active={activeTab === EventStatuses.FINISHED}
								onClick={() => setActiveTab(EventStatuses.FINISHED)}
							>
								<i className='fa fa-calendar-times-o'></i> {t('past_matches')}
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink
								id={`tab-link-${EventStatuses.INTERRUPTED}`}
								active={activeTab === EventStatuses.INTERRUPTED}
								onClick={() => setActiveTab(EventStatuses.INTERRUPTED)}
							>
								<i className='fa fa-calendar-minus-o'></i> {t('interrupted_matches')}
							</NavLink>
						</NavItem>
					</Nav>
					<TabContent activeTab={activeTab}>
						<TabPane tabId={activeTab} id={`tab-pane-matches-${activeTab}`}>
							{selectedSport.sport === SportTypes.FOOTBALL ? (
								<MatchesScrollbar
									t={t}
									activeTab={activeTab}
									tournamentFilter={tournament}
									seasonFilter={season}
									dateFromFilter={dateFrom}
									dateToFilter={dateTo}
									selectedSport={selectedSport}
								/>
							) : (
								<EventsScrollbar t={t} activeTab={activeTab} selectedSport={selectedSport} dateFromFilter={dateFrom} dateToFilter={dateTo} />
							)}
						</TabPane>
					</TabContent>
				</Col>
			</Row>
		</>
	);
};

export default EventsContainer;
