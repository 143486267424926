import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Col, Label, Row } from 'reactstrap';
import { customOption } from '../../../partials/shared/custom-select-option';
import TennisRoundModel from '../../../../../../models/v2/round/tennis-round/tennis-round.model';
import ErrorHandler from '../error-handler/error-handler';
import { tennisRoundsToOptions } from './tennis-rounds-select.helper';
import { SelectMenuOptionType } from '../../../../../../models/v2/general/select.model';
import { useTranslation } from 'react-i18next';

type Properties = {
	onRoundsSelect: (selection: TennisRoundModel[]) => void;
	selectedRounds: TennisRoundModel[];
	isValid: boolean;
	isClearable?: boolean;
	isRequired?: boolean;
	language: string;
	sport: string;
	id: string;
	rounds: TennisRoundModel[];
	preselectRounds: boolean;
};

export const TennisTournamentRoundsSelectComponent: React.FunctionComponent<Properties> = (props) => {
	const [roundsOptions, setRoundsOptions] = useState<TennisRoundModel[]>([]);
	const [t] = useTranslation();
	const { rounds, selectedRounds, onRoundsSelect, preselectRounds } = props;

	useEffect(() => {
		updateRoundsOptionsState(rounds);
	}, [rounds]);

	const updateRoundsOptionsState = (rounds: TennisRoundModel[]) => {
		if ((!selectedRounds || selectedRounds.length < 1) && preselectRounds) {
			onRoundsSelect(rounds);
		}
		setRoundsOptions(rounds);
	};

	const onSelect = (rounds: SelectMenuOptionType[]) => {
		if (rounds && rounds.length > 0) {
			onRoundsSelect(rounds.map((round: SelectMenuOptionType) => round.data));
		} else onRoundsSelect([] as TennisRoundModel[]);
	};

	const roundsSelected = selectedRounds === null || (selectedRounds && selectedRounds.length === 0);
	return (
		<Row data-qa={props.id}>
			<Col>
				<Label htmlFor={props.id}>{t('rounds')}</Label>
				{props.isRequired && <span className='text-danger'>*</span>}
				<Select
					id={props.id}
					menuPosition='absolute'
					className='w-100'
					placeholder={t('select')}
					formatOptionLabel={customOption}
					options={tennisRoundsToOptions(roundsOptions)}
					value={tennisRoundsToOptions(selectedRounds)}
					noOptionsMessage={(inputValue) => inputValue && t('no_options')}
					isClearable={props.isClearable}
					onChange={onSelect}
					isMulti
				/>
				{props.isRequired && (
					<ErrorHandler t={t} arePropsValid={props.isValid} isValueSelected={roundsSelected} errorMessage='field_is_required' />
				)}
			</Col>
		</Row>
	);
};
