import { TypedJSON } from 'typedjson';
import { assetsPlaceholder } from '../../../../../constants/assetsPlaceholder';
import LiveBlogTeamModel from './live-blog-team.model';
import { convertIdToString } from '../../helpers/utility.helper';

export const responseToLiveBlogFootballTeamModel = (response: any) => {
	const serializer = new TypedJSON(LiveBlogTeamModel);
	const remmapedTeam = serializer.parse(response) as LiveBlogTeamModel;

	if (remmapedTeam) {
		remmapedTeam.id = convertIdToString(remmapedTeam.id);
	}
	return remmapedTeam;
};

export const teamsToOptions = (teams: LiveBlogTeamModel[]) => {
	if (teams && teams.length > 0) {
		return teams.map((team) => {
			return teamToOption(team);
		});
	}

	return [];
};

export const teamToOption = (team: LiveBlogTeamModel | any) => {
	if (team && team.id) {
		return {
			value: team.id,
			label: team.name,
			data: team,
			logo: team.urlLogo ? team.urlLogo : team.url_logo ? team.url_logo : assetsPlaceholder.team,
			type: 'team',
		};
	} else {
		return {};
	}
};
