import * as React from 'react';
import { FormGroup, Label, Col, Input } from 'reactstrap';
import ErrorHandler from '../../../../Partials/Blocky/partials/error/error-handler-component';

type WeightSelect = {
	t: any;
	onChange: any;
	value: string;
};

const WeightSelect: React.FunctionComponent<WeightSelect> = ({ onChange, t, value }) => {
	value = value ? value : '';
	const regExpOnlyDigits = /^[0-9]*$/;

	return (
		<Col xs='6'>
			<FormGroup>
				<Label htmlFor='player-manual-data-entry-weight'>{t('weight')}</Label>
				<Input
					type='text'
					id='player-manual-data-entry-weight'
					value={value}
					onChange={onChange}
					className='form-control'
					placeholder={t('in_kilograms')}
				/>
				{!regExpOnlyDigits.test(value.trim()) && <ErrorHandler t={t} errorMessage='value_should_be_digit' />}
			</FormGroup>
		</Col>
	);
};

export default WeightSelect;
