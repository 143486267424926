import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import ListItemModel from '../../../../../../models/v2/list/list-item';
import { Button, Col, Row } from 'reactstrap';
import { capitalize } from '../../../../../../global-helpers/global.helpers';
import moment from 'moment';
import { imagePlaceholderBase64 } from '../../../../../../global-helpers/global.constants';
import { generateContentTypeIcon } from '../../../../../../services/content-models-service/ContentModelService';
import { displayAuthorNamesString } from '../../../../../Partials/Sidebar/list-refactored/helpers/general.helper';
import { DATA_QA_ATTRIBUTES } from '../../../../../Partials/Sidebar/general-content/constants/data-qa.attributes';
import { getImageSrcOrPlaceholderImage, getNotLiveBlogItemImageSrcOrPlaceholderImage, isLiveBlogListItem } from './live-blog-lists.helper';
import placeholderImage from '../../../../../../assets/img/image-placeholder.png';
import { SortableHandle } from 'react-sortable-hoc';
import ContentItemStatisticsComponent from '../../../../../Partials/Sidebar/list-refactored/subcomponents/list-statistics/list-statistics';
import { ContentTypes } from '../../../../../../constants/content-types';
import './style/live-blog-list.style.scss';

type Properties = {
	t: any;
	selectedListItem: ListItemModel;
	itemIndex: number;
	shouldShowListImages: boolean;
	onDelete: (itemType: string, itemId: string) => void;
};

const DragHandle = SortableHandle(() => (
	<span className='cursor-draggable col-md-1 text-center'>
		<i className='fa fa-bars' />
	</span>
));

const LiveBlogListContentSortableItem: FunctionComponent<Properties> = ({
	t,
	selectedListItem,
	itemIndex,
	shouldShowListImages,
	onDelete,
}) => {
	if (!selectedListItem) return null;
	const selectedListItemData = selectedListItem.data;
	const isLocked = typeof selectedListItem.lockedPosition === 'number';
	const authorData =
		(selectedListItemData &&
			selectedListItemData.authors &&
			selectedListItemData.authors.length > 0 &&
			displayAuthorNamesString(t, selectedListItemData.authors)) ||
		'';
	const contentIcon = generateContentTypeIcon(selectedListItem.type.toLowerCase());
	const image =
		selectedListItem.data && selectedListItem.data.main_media && selectedListItem.data.main_media[0] && selectedListItem.data.main_media[0];
	const imageSrc = image ? getImageSrcOrPlaceholderImage(image.data) : placeholderImage;
	const contentImage = getNotLiveBlogItemImageSrcOrPlaceholderImage(
		(selectedListItemData && selectedListItemData.image && selectedListItemData.image.data && selectedListItemData.image.data.path) || '',
	);
	const date =
		selectedListItemData && isLiveBlogListItem(selectedListItem.type.toLowerCase())
			? selectedListItemData.start_time
			: (selectedListItemData && selectedListItemData.published_at) || (selectedListItemData && selectedListItemData.publishedAt);

	return (
		<li className={`live-blog-list-item list-group-item mb-1 p-1 ${isLocked && 'disabled'}`}>
			<Row className='position-relative '>
				<Col className='d-flex justify-content-between align-items-center'>
					<div className='d-flex justify-content-between align-items-center'>
						{isLocked && <i style={{ left: '50%' }} className='position-absolute p-1 fa-4x opacity-02 fa fa-lock text-muted' />}
						<span className='px-1 font-weight-bold'>{itemIndex + 1}.</span>
						{!isLocked && <DragHandle />}
						<div className='d-flex align-items-center overflow-hidden'>
							{shouldShowListImages && (
								<div className='mr-2'>
									<img
										src={isLiveBlogListItem(selectedListItem.type.toLowerCase()) ? imageSrc : contentImage}
										onError={(i: any) => (i.target.src = imagePlaceholderBase64)}
										alt={selectedListItemData && selectedListItemData.title}
										className='live-blog-sortable-list-item-image'
									/>
								</div>
							)}
							<div className='overflow-hidden live-blog-list-item-row'>
								<h6 className='mb-0 d-flex'>
									<i className={contentIcon} title={capitalize(selectedListItemData && selectedListItemData.type)} />
									<label htmlFor={DATA_QA_ATTRIBUTES.LIVE_BLOG_SORTABLE_ITEM} className='text-dark ml-1 mb-0'>
										{selectedListItemData && selectedListItemData.title}
									</label>
								</h6>
								{selectedListItem.type.toLowerCase() !== ContentTypes.LIVE_BLOG && (
									<div className='d-flex'>
										<ContentItemStatisticsComponent t={t} listItemData={selectedListItemData} />
									</div>
								)}
								<div className='flex-column content-item-list-data'>
									<small>{`${t('date_published')}: ${moment(date).format('DD MMMM YYYY, HH:mm')}`}</small>
									<small>{authorData}</small>
								</div>
							</div>
						</div>
					</div>
				</Col>
				<Col className='action-buttons'>
					<div>
						<Button
							disabled={isLocked}
							id={`content-item-image-delete-btn-${itemIndex}`}
							color='danger'
							onClick={() => onDelete(selectedListItem.type, selectedListItemData && selectedListItemData.id)}
							className='mb-0 mr-1'
						>
							<i className='fa fa-trash' />
							{t('remove')}
						</Button>
					</div>
				</Col>
			</Row>
		</li>
	);
};

function mapStateToProps(state: any) {
	return {
		shouldShowListImages: state.ui.shouldShowListImages[state.profile.profile.id] || false,
		profileId: state.profile.profile.id || '',
	};
}

export default connect(mapStateToProps)(LiveBlogListContentSortableItem);
