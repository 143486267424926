import { SpotlightAudioJson } from './spotlight-audio.json';
import SpotlightAudioBuilder from './spotlight-audio.builder';

export default class SpotlightAudioModel {
	readonly homeTeamId: number = 0;
	readonly homeTeam: string = '';
	readonly awayTeamId: number = 0;
	readonly awayTeam: string = '';
	readonly competition: string = '';
	readonly kickOff: string = '';
	readonly language: string = '';
	readonly sport: string = '';
	readonly matchId: number = 0;
	readonly filters: any = [];
	readonly config: any[];

	private constructor(
		homeTeamId: number,
		homeTeam: string,
		awayTeamId: number,
		awayTeam: string,
		competition: string,
		kickOff: string,
		language: string,
		sport: string,
		matchId: number,
		filters: any[],
		config: any[],
	) {
		this.homeTeamId = homeTeamId;
		this.homeTeam = homeTeam;
		this.awayTeamId = awayTeamId;
		this.awayTeam = awayTeam;
		this.competition = competition;
		this.kickOff = kickOff;
		this.language = language;
		this.sport = sport;
		this.matchId = matchId;
		this.filters = filters;
		this.config = config;
	}

	toJson(): SpotlightAudioJson {
		return {
			homeTeamId: this.homeTeamId,
			homeTeam: this.homeTeam,
			awayTeamId: this.awayTeamId,
			awayTeam: this.awayTeam,
			competition: this.competition,
			kickOff: this.kickOff,
			language: this.language,
			sport: this.sport,
			matchId: this.matchId,
			filters: this.filters,
			config: this.config,
		};
	}

	static fromJSON(json: SpotlightAudioJson): SpotlightAudioModel {
		return new SpotlightAudioModel(
			json.homeTeamId,
			json.homeTeam,
			json.awayTeamId,
			json.awayTeam,
			json.competition,
			json.kickOff,
			json.language,
			json.sport,
			json.matchId,
			json.filters,
			json.config,
		);
	}

	static builder(audio?: SpotlightAudioModel): SpotlightAudioBuilder {
		return new SpotlightAudioBuilder(audio);
	}
}
