import 'reflect-metadata';
import { jsonMember, jsonObject } from 'typedjson';
import TournamentModel from '../Entity/TournamentModel';

@jsonObject()
export default class TournamentOrderModel {
	@jsonMember(TournamentModel)
	public tournament: TournamentModel = new TournamentModel();
	@jsonMember(Number, { name: 'sort_order' })
	public sortOrder: Number = 0;
}
