import React, { FunctionComponent } from 'react';
import LiveBlogModel from '../../models/live-blog.model';
import '../../style/live-blog-listing.style.scss';
import '../../style/live-blog.style.scss';
import {
	liveBlogConfigurationAuthorsChange,
	liveBlogConfigurationCollaboratorsChange,
	liveBlogConfigurationLanguageChange,
	liveBlogConfigurationPaginationChange,
} from '../../helpers/utility.helper';
import { Col } from 'reactstrap';
import { LiveBlogCollaboratorsSelect } from '../subcomponents/collaborators/live-blog-collaborators-select.component';
import AuthorModel from '../../../../../models/v2/author/author.model';
import { LiveBlogLanguageSelect } from '../subcomponents/live-blog-language-select/live-blog-langugage-select.component';
import { LiveBlogAuthorsSelect } from '../subcomponents/live-blog-authors-select.component/live-blog-authors-select.component';
import { LiveBlogPaginationSelect } from '../subcomponents/pagination-select/live-blog-pagination-select.component';
import LiveBlogCollaboratorsModel from '../../models/live-blog-collaborators.model';
import LiveBlogListsComponent from '../subcomponents/live-blog-lists/live-blog-lists.component';

type Properties = {
	t: any;
	liveBlog: LiveBlogModel;
	setLiveBlog: any;
};

export const LiveBlogAdditionalConfigurationComponent: FunctionComponent<Properties> = ({ t, liveBlog, setLiveBlog }) => {
	return (
		<>
			<Col xs='6'>
				<LiveBlogLanguageSelect
					t={t}
					liveBlog={liveBlog}
					setLiveBlogLanguage={(language: any) => liveBlogConfigurationLanguageChange(language, liveBlog, setLiveBlog)}
				/>
				<LiveBlogPaginationSelect
					t={t}
					liveBlog={liveBlog}
					setLiveBlogPagination={(pagination: number) => liveBlogConfigurationPaginationChange(pagination, liveBlog, setLiveBlog)}
				/>
				<LiveBlogAuthorsSelect
					t={t}
					liveBlog={liveBlog}
					setLiveBlogAuthors={(authors: AuthorModel[]) => liveBlogConfigurationAuthorsChange(authors, liveBlog, setLiveBlog)}
				/>
				<LiveBlogCollaboratorsSelect
					t={t}
					liveBlog={liveBlog}
					setLiveBlogCollaborators={(collaborators: LiveBlogCollaboratorsModel[]) =>
						liveBlogConfigurationCollaboratorsChange(collaborators, liveBlog, setLiveBlog)
					}
				/>
			</Col>
			<Col xs='6'>
				<LiveBlogListsComponent t={t} liveBlog={liveBlog} />
			</Col>
		</>
	);
};
