import EmbedBlockModel from './embed-block.model';
import { EmbedBlockJson } from './embed-block.json';
import { EmbedBlockRequestJson } from './embed-block-request.json';
import { ValidationTypeJson } from './embed-block-validation-type.json';

export default class EmbedBlockBuilder {
	private json: EmbedBlockJson;

	constructor(embed?: EmbedBlockModel | EmbedBlockJson) {
		if (embed && embed instanceof EmbedBlockModel) {
			this.json = embed.toJson();
		} else if (embed) {
			this.json = embed;
		} else {
			this.json = {} as EmbedBlockJson;
		}
	}

	withType(type: string): EmbedBlockBuilder {
		this.json.type = type;

		return this;
	}

	withContent(content: string): EmbedBlockBuilder {
		this.json.content = content;

		return this;
	}

	withValidationType(validationType: ValidationTypeJson): EmbedBlockBuilder {
		this.json.validationType = validationType;

		return this;
	}

	toRequestJson(): EmbedBlockRequestJson {
		let json = {} as EmbedBlockRequestJson;
		json.embed_type = this.json.type;
		json.content = this.json.content;

		return json;
	}

	build(): EmbedBlockModel {
		return EmbedBlockModel.fromJson(this.json);
	}
}
