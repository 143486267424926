import React from 'react';
import { Subscription } from 'rxjs';
import { loaderUiService } from './../editor-block-edit.component';

export default class AutoLinkingLoader extends React.Component {
	loaderUIServiceSubscription = new Subscription();

	constructor(props) {
		super(props);

		this.state = {
			isLoading: false,
		};
	}

	componentWillUnmount() {
		this.loaderUIServiceSubscription.unsubscribe();
	}

	componentDidMount() {
		this.loaderUIServiceSubscription = loaderUiService.autolinkingSubject.subscribe((isAutoLinking) => {
			this.props.id === isAutoLinking.id && this.toggleLoadingState(isAutoLinking.isLoading);
		});
	}

	toggleLoadingState = (isLoading) => {
		this.setState({
			isLoading,
		});
	};

	render() {
		const { t } = this.props;
		const { isLoading } = this.state;

		return (
			isLoading && (
				<div style={{ position: 'absolute', right: '6px', bottom: '-42px' }}>
					<div className='d-inline-block loading-overlay-small mr-2' style={{ width: '25px', height: '25px' }}></div>
					<div className='d-inline-block' style={{ color: '#c8ced3' }}>
						{t('auto-linking-loader-text')}
					</div>
				</div>
			)
		);
	}
}
