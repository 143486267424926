import * as React from 'react';
import { useSelector } from 'react-redux';
import Flag from 'react-world-flags';
import { multiLingualService } from '../../../../../../App';
import { AppState } from '../../../../../../store/store';
import { getListTypeTranslated } from '../../../../../Resources/Lists/helpers/list.helper';

type TitleProps = {
	title: string;
	contentType?: string;
	t?: any;
	showFlag?: boolean;
	location?: string;
};

export const Title: React.FunctionComponent<TitleProps> = ({ title, showFlag, location, t, contentType }) => {
	const contentLanguages = useSelector((state: AppState) => state.project.currentProject.languages);
	const contentTitle = contentType === 'list' ? getListTypeTranslated() : title;

	return (
		<div className='card-header d-flex'>
			{showFlag && multiLingualService.checkIfProjectIsMultiLingual(contentLanguages) && contentLanguages.defaultLanguageCode.languageCode && (
				<div className='mr-2 d-flex'>
					<Flag
						code={contentLanguages.defaultLanguageCode.languageCode === 'en' ? 'gb' : contentLanguages.defaultLanguageCode.languageCode}
						width='20'
						fallback={<i className='fa fa-flag ml-2' />}
					/>
				</div>
			)}
			{contentTitle}
		</div>
	);
};
