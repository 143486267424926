import 'reflect-metadata';
import { AnyT, jsonArrayMember, jsonMember, jsonObject } from 'typedjson';

@jsonObject
export class DisplaySizes {
	@jsonMember(Boolean, { name: 'is_watermarked' })
	public isWatermarked: boolean | null = false;
	@jsonMember(String)
	public name: string | null = null;
	@jsonMember(String)
	public uri: string | null = null;
}

@jsonObject
export class LargestDownloads {
	@jsonMember(String, { name: 'product_type' })
	public productType: string = '';
	@jsonMember(String)
	public uri: string = '';
}

@jsonObject
export class MaxDimensions {
	@jsonMember(Number)
	public height: number = 0;
	@jsonMember(Number)
	public width: number = 0;
}

@jsonObject
export class Downloads {
	@jsonMember(String, { name: 'product_id' })
	public productId: string = '';
	@jsonMember(String, { name: 'product_type' })
	public productType: string = '';
	@jsonMember(String)
	public uri: string = '';
}

@jsonObject
export class DownloadSizes {
	@jsonMember(String)
	public name: string = '';
	@jsonMember(String, { name: 'media_type' })
	public mediaType: string = '';
	@jsonMember(Number)
	public height: number = 0;
	@jsonMember(Number)
	public width: number = 0;
	@jsonArrayMember(Downloads)
	public downloads: Downloads[] = [];
}

@jsonObject()
export default class GettyImageModel {
	@jsonMember(String)
	public id: string = '';
	@jsonMember(String, { name: 'asset_family' })
	public assetFamily: string = '';
	@jsonMember(String)
	public caption: string | null = null;
	@jsonMember(String)
	public artist: string | null = null;
	@jsonMember(String)
	public copyright: string | null = null;
	@jsonArrayMember(DisplaySizes, { name: 'display_sizes' })
	public displaySizes: DisplaySizes[] = [];
	@jsonArrayMember(LargestDownloads, { name: 'largest_downloads' })
	public largestDownloads: LargestDownloads[] = [];
	@jsonArrayMember(DownloadSizes, { name: 'download_sizes' })
	public downLoadSizes: DownloadSizes[] = [];
	@jsonArrayMember(String, { name: 'product_types' })
	public productTypes: string[] = [];
	@jsonMember(MaxDimensions, { name: 'max_dimensions' })
	public maxDimensions: MaxDimensions = new MaxDimensions();
	@jsonMember(String, { name: 'collection_name' })
	public collectionName: string | null = null;
	@jsonMember(String, { name: 'license_model' })
	public licenseModel: string | null = null;
	@jsonMember(String)
	public title: string | null = null;
}
