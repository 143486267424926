import { relatedConstants } from '../../../../../constants/related.constants';
import Related from '../../../../../models/related/Related';
import { modelFootballConnectionToFootballConnectionResponse } from '../../../../../models/v2/football-connection/response-football-connection.mapper';
import FootballConnectionResponseModel from '../../../../../models/v2/football-connection/response-football-connection.model';
import { extractProviderBySportType } from './utils';
import SportsTypesModel from '../../../../../models/v2/sports-types/sports-types.model';

export const footballConnectionResponseToOptions = (footballConnections: FootballConnectionResponseModel[] | null) => {
	if (footballConnections && footballConnections.length > 0) {
		return footballConnections.map((footballConnection: FootballConnectionResponseModel) => {
			return { value: `${footballConnection.id}${footballConnection.entity_type}`, label: footballConnection.name, data: footballConnection };
		});
	}

	return null;
};

export const footballConnectionResponseToRelatedModel = (
	footballConnection: FootballConnectionResponseModel,
	selectedSport: SportsTypesModel,
): Related => {
	return {
		provider: extractProviderBySportType(selectedSport),
		type: footballConnection.entity_type,
		data: footballConnection,
	} as Related;
};

// Redux store the data in the following format:
// { type: 'tag', provider: 'smp', data: FootballConnectionResponseModel (but not as model) }
export const remapReduxSportsConnectionPropertyToFootballConnectionResponseModel = (
	reduxData: Related[] | null,
): FootballConnectionResponseModel[] | null => {
	if (reduxData) {
		return reduxData.map(({ data }: any) => modelFootballConnectionToFootballConnectionResponse(data));
	}
	return null;
};

// We need to filter related properties without the season because it is appending season data in football connection select
export const entityTypesWithoutSeason = Object.values(relatedConstants.types).filter((type) => type !== relatedConstants.types.season);

export const footballDataTypesForRequest = [
	relatedConstants.types.tournament,
	relatedConstants.types.player,
	relatedConstants.types.team,
	relatedConstants.types.presidentOnly,
	relatedConstants.types.venue,
	relatedConstants.types.coach,
].join(',');
