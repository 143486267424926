import React, { useState } from 'react';
import GettyImageModel from '../../models/getty-image.model';
import { useTranslation } from 'react-i18next';
import { copyToClipboard } from '../../../../Partials/Blocky/blocks/content/imago-image-block/image-cells/helpers/video-cells.helper';
import Masonry from 'react-masonry-css';
import ImagePreviewModal from '../../../../Partials/Shared/image-preview-modal/image-preview-modal';
import '../../styles/getty.styles.scss';
import { retrieveCorrectImageDisplaySize } from '../../helpers/getty-images.helper';
import { noPreviewImage } from '../../../../../constants/resource-constants';
import { GettyLazyLoadImageCell } from './load-images/lazy-load-getty-images';

type Properties = {
	images: GettyImageModel[];
	toggleLoadingState: boolean;
	onSelect: (image: GettyImageModel) => void;
	onDownLoadSizeSelect?: (size: string) => void;
	selectedImage?: GettyImageModel;
	selectedItemDownloadSize?: string;
	inBlocky: boolean;
};

export const GettyImageCells: React.FunctionComponent<Properties> = (props) => {
	const [t] = useTranslation();
	const [isOpenModal, setIsOpenModal] = useState(false);
	const [imageToView, setImageToView] = useState<GettyImageModel | null>(null);

	const previewThumb =
		imageToView && imageToView.displaySizes && imageToView.displaySizes && imageToView.displaySizes.length > 0
			? retrieveCorrectImageDisplaySize(imageToView.displaySizes)
			: noPreviewImage;

	const copyThumbnail = (e: any, thumbnail: string) => {
		copyToClipboard(e, thumbnail, t, 'thumbnail');
	};

	const previewImage = (open: boolean, image: GettyImageModel) => {
		setIsOpenModal(open);
		setImageToView(image);
	};

	const onClose = (open: boolean) => {
		setIsOpenModal(open);
		setImageToView(null);
	};

	const { images, onSelect, onDownLoadSizeSelect, selectedImage, selectedItemDownloadSize, inBlocky } = props;

	return (
		<div className='getty-image-cells'>
			<Masonry breakpointCols={3} className='my-masonry-grid' columnClassName='my-masonry-grid_column'>
				{images &&
					images.length > 0 &&
					images.map((image: GettyImageModel) => {
						return (
							<GettyLazyLoadImageCell
								key={`${image.id}`}
								image={image}
								onSelect={onSelect}
								onDownLoadSizeSelect={onDownLoadSizeSelect}
								copyThumbnail={copyThumbnail}
								previewImage={previewImage}
								selectedImage={selectedImage}
								selectedItemDownloadSize={selectedItemDownloadSize}
								inBlocky={inBlocky}
							/>
						);
					})}
				{imageToView && <ImagePreviewModal t={t} onClose={onClose} url={previewThumb} title={imageToView.title} open={isOpenModal} />}
			</Masonry>
		</div>
	);
};
