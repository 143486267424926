import { TeamComparisonJson } from './team-comparison.json';
import TeamComparisonBuilder from './team-comparison.builder';
import TeamModel from '../../../../partials/team-select/models/team.model';

export default class TeamComparisonModel {
	readonly team1: TeamModel;
	readonly team2: TeamModel;
	readonly statFields: string[] = ['played', 'win', 'draw', 'defeats', 'goalsScored', 'goalsConceded'];
	readonly displayOdds: boolean = true;
	readonly threeLetterCodeType: any = '';

	private constructor(team1: TeamModel, team2: TeamModel, statFields: string[], displayOdds: boolean, threeLetterCodeType: any) {
		this.team1 = team1;
		this.team2 = team2;
		this.statFields = statFields;
		this.displayOdds = displayOdds;
		this.threeLetterCodeType = threeLetterCodeType;
	}

	toJson(): TeamComparisonJson {
		return {
			team1: this.team1,
			team2: this.team2,
			statFields: this.statFields,
			displayOdds: this.displayOdds,
			threeLetterCodeType: this.threeLetterCodeType,
		};
	}

	static fromJson(json: TeamComparisonJson): TeamComparisonModel {
		return new TeamComparisonModel(json.team1, json.team2, json.statFields, json.displayOdds, json.threeLetterCodeType);
	}

	static builder(teamComparison?: TeamComparisonModel): TeamComparisonBuilder {
		return new TeamComparisonBuilder(teamComparison);
	}
}
