import * as React from 'react';
import { FormGroup, Input, Label, Tooltip } from 'reactstrap';
import { Properties, State } from './properties/SlugProperties';
import { DebounceInput } from 'react-debounce-input';
import ErrorAsync from '../../../../BaseComponents/ErrorComponent/ErrorAsync';
import './Slug.scss';

export default class Slug extends React.Component<Properties, State> {
	constructor(props: Properties) {
		super(props);

		this.state = {
			tooltipOpen: false,
		};
	}

	toggle = () => {
		this.setState({ ...this.state, tooltipOpen: !this.state.tooltipOpen });
	};

	onAutoSlugChecked = (event: any) => {
		this.props.onAutoSlugCheckbox(event.target.checked);
	};

	onSlugInputChange = (event: any) => {
		this.props.onSlugInputChange(event.target.value);
	};

	render() {
		const { t, slug, isAutoSlug } = this.props;

		return (
			<>
				<FormGroup className='mb-1'>
					<Label htmlFor='slug'>{t('slug')}</Label>
					<DebounceInput
						type='text'
						id='slug'
						value={slug}
						disabled={isAutoSlug}
						debounceTimeout={300}
						className='form-control-lg form-control sm-text'
						onChange={this.onSlugInputChange.bind(this)}
						placeholder={t('slug_placeholder')}
					/>
					<ErrorAsync t={t} errorType='slug' />
				</FormGroup>
				<FormGroup check inline>
					<Input
						className='form-check-input'
						type='checkbox'
						id='generate-slug-automatically'
						name='generate-slug-automatically'
						checked={isAutoSlug}
						onChange={this.onAutoSlugChecked}
					/>
					<Label className='form-check-label' check htmlFor='generate-slug-automatically'>
						{t('generate_auto_seo_title')}
					</Label>
					<span>
						<i id='tooltip-generate-slug-automatically' className='fa fa-info-circle mx-1'></i>
						<Tooltip placement='top' isOpen={this.state.tooltipOpen} target={'tooltip-generate-slug-automatically'} toggle={this.toggle}>
							{t('generate_auto_seo_title_info')}
						</Tooltip>
					</span>
				</FormGroup>
			</>
		);
	}
}
