import * as React from 'react';
import { featuresService } from '../../../../../App';
import { FeatureTypes } from '../../../../../services/feature-service/features.enum';
import { extractContentValueByTheGivenKey } from '../../../../Partials/BaseComponents/ListingComponent/subcomponents/Rows/subcomponents/row-content-statistic/helpers/row-content-statistics.component.helper';

type Properties = {
	value: any;
	style: string;
	t: any;
};

const ContentItemStatisticsComponent: React.FunctionComponent<Properties> = (props) => {
	const contentStatisticsConfig = featuresService.getFeatureConfig(FeatureTypes.CONTENT_STATISTICS);
	const { value, style, t } = props;

	if (
		!featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.CONTENT_STATISTICS) ||
		(!featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.CONTENT_STATISTICS) && !contentStatisticsConfig.mappings)
	) {
		return null;
	}

	return contentStatisticsConfig.mappings.map((statistic: any) => {
		return (
			<div key={statistic.display_key} className={style}>
				<p className='pr-1 mb-0'>{`${t(statistic.display_key)}: `}</p>
				<strong>{extractContentValueByTheGivenKey(value, statistic)}</strong>
			</div>
		);
	});
};

export default ContentItemStatisticsComponent;
