import TennisRankingModel from './tennis-ranking.model';
import { TennisRankingWidgetJson } from './tennis-ranking-widget.json';
import AvailableStandingTypeModel from '../../../../../../../models/v2/standing-types/available-standing-type.model';
import RankingPlayerModel from '../../../../../../../models/v2/ranking-player/ranking-player.model';
import { RefreshTimeValuesType } from '../../../partials/refresh-time-input/helper';

export default class TennisRankingBuilder {
	readonly json: TennisRankingWidgetJson;

	constructor(ranking?: TennisRankingModel | TennisRankingWidgetJson) {
		if (ranking && ranking instanceof TennisRankingModel) {
			this.json = ranking.toJson();
		} else if (ranking) {
			this.json = ranking;
		} else {
			this.json = {} as TennisRankingWidgetJson;
		}
	}

	withLimit(limit: string): TennisRankingBuilder {
		this.json.limit = limit;

		return this;
	}

	withOffset(offset: string): TennisRankingBuilder {
		this.json.offset = offset;

		return this;
	}

	withStandingType(availableStandingType: AvailableStandingTypeModel): TennisRankingBuilder {
		this.json.availableStandingType = availableStandingType;

		return this;
	}

	withSelectPlayers(selectPlayers: boolean): TennisRankingBuilder {
		this.json.selectPlayers = selectPlayers;

		return this;
	}

	withHighlightPlayers(highlightPlayers: RankingPlayerModel[]): TennisRankingBuilder {
		this.json.highlightPlayers = highlightPlayers;

		return this;
	}

	withRefreshTime(refreshTime: RefreshTimeValuesType): TennisRankingBuilder {
		this.json.refreshTime = refreshTime;

		return this;
	}

	build(): TennisRankingModel {
		return TennisRankingModel.fromJson(this.json);
	}
}
