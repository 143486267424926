import { SeasonJson } from './season.json';
import SeasonModel from './season.model';

export default class SeasonBuilder {
	readonly json: SeasonJson;

	constructor(season?: SeasonModel | SeasonJson) {
		if (season && season instanceof SeasonModel) {
			this.json = season.toJson();
		} else if (season) {
			this.json = season;
		} else {
			this.json = {} as SeasonJson;
		}
	}

	withId(id: string): SeasonBuilder {
		this.json.id = id;

		return this;
	}

	withName(name: string): SeasonBuilder {
		this.json.name = name;

		return this;
	}

	withStage(stage: {}): SeasonBuilder {
		this.json.stage = stage;

		return this;
	}

	withActive(active: boolean): SeasonBuilder {
		this.json.active = active;

		return this;
	}

	build(): SeasonModel {
		return SeasonModel.fromJson(this.json);
	}
}
