import { actionService } from '../../App';
import ModelMapper from '../../models/ModelMapper';
import {
	IMAGES_RECEIVED,
	IMAGE_UPLOAD_FAILED,
	IMAGE_UPLOAD_REQUEST,
	IMAGE_UPLOAD_SUCCESS,
	PLAYING_SURFACE_IMAGES_RECEIVED,
	IMAGO_IMAGES_RECEIVED,
	GETTY_IMAGES_RECEIVED,
} from '../action-creators/ImageActionCreator';

export function imageMiddleware() {
	return function (next: any) {
		return function (action: any) {
			if (action) {
				if (action.type === IMAGES_RECEIVED) {
					action.payload.images = ModelMapper.remapImageListingFromResponse(action.payload.data.data);
					action.payload.contentPagination = ModelMapper.remapMetaPagination(action.payload.data.meta.pagination);
				}

				if (action.type === PLAYING_SURFACE_IMAGES_RECEIVED) {
					action.payload.playingSurfaceImages = ModelMapper.remapImageListFromResponse(action.payload.data.data);
					action.payload.contentPagination = ModelMapper.remapMetaPagination(action.payload.data.meta.pagination);
				}

				if (action.type === IMAGO_IMAGES_RECEIVED) {
					action.payload.imagoImages = ModelMapper.remapImageListFromResponse(action.payload.data.data);
					action.payload.contentPagination = ModelMapper.remapMetaPagination(action.payload.data.meta.pagination);
				}

				if (action.type === GETTY_IMAGES_RECEIVED) {
					action.payload.gettyImages = ModelMapper.remapImageListFromResponse(action.payload.data.data);
					action.payload.contentPagination = ModelMapper.remapMetaPagination(action.payload.data.meta.pagination);
				}

				if (action.type === IMAGE_UPLOAD_REQUEST) {
					actionService.emitGeneralAction(IMAGE_UPLOAD_REQUEST);
				}

				if (action.type === IMAGE_UPLOAD_SUCCESS) {
					actionService.emitGeneralAction(IMAGE_UPLOAD_SUCCESS);
				}

				if (action.type === IMAGE_UPLOAD_FAILED) {
					actionService.emitGeneralAction(IMAGE_UPLOAD_FAILED);
				}
			}

			next(action);
		};
	};
}
