import 'reflect-metadata';
import { jsonMember, jsonObject } from 'typedjson';

@jsonObject()
class Logo {
	@jsonMember(String)
	public url: string = '';
}

@jsonObject()
class Assets {
	@jsonMember(Logo)
	public logo: Logo = new Logo();
}

@jsonObject()
export default class StandingTypeModel {
	@jsonMember(String)
	public id: string = '';
	@jsonMember(String)
	public name: string = '';
	@jsonMember(String)
	public subcategory: string = '';
	@jsonMember(String)
	public category: string = '';
	@jsonMember(String, { name: 'entity_type' })
	public entityType: string = '';
	@jsonMember(Assets)
	public assets: Assets = new Assets();
}
