import 'reflect-metadata';
import { jsonArrayMember, jsonMember, jsonObject } from 'typedjson';
import AssetsModel from '../../../../models/v2/sports-types/assets.model';
import { MultisportCompetitionModel } from './competition.model';
import { ParticipantModelWrapper } from './participant-details';

@jsonObject()
export default class MultisportEventModel {
	@jsonMember(String)
	public id: string = '';
	@jsonMember(String)
	public name: string = '';
	@jsonMember(String)
	public slug: string = '';
	@jsonMember(String)
	public entity_type: string = '';
	@jsonMember(String)
	public sport: string = '';
	@jsonMember(AssetsModel)
	public display_asset: AssetsModel = new AssetsModel();
	@jsonMember(String)
	public status_type: string = '';
	@jsonArrayMember(String)
	public participants: string[] = [];
	@jsonArrayMember(ParticipantModelWrapper)
	public participant_details: ParticipantModelWrapper[] = [];
	// @jsonArrayMember(String)
	// public results: string[] = [];
	@jsonMember(String)
	public start_time: string = '';
	@jsonMember(String)
	public competition_id: string = '';
	@jsonMember(MultisportCompetitionModel)
	public competition: MultisportCompetitionModel = new MultisportCompetitionModel();
}
