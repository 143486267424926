import 'reflect-metadata';
import { jsonArrayMember, jsonMember, jsonObject } from 'typedjson';
import EntityTypesModel from './entity-types.model';
// Sports endpoint Model
@jsonObject
export default class SportsTypesModel {
	@jsonMember(String)
	public sport: string = '';
	@jsonMember(String)
	public title: string = '';
	@jsonArrayMember(EntityTypesModel)
	public entity_types: EntityTypesModel[] = [];
}
