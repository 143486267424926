import Project from '../../models/project/Project';

export const IMAGE_OWNERS_REQUEST = '[ACTION] IMAGE_OWNERS_REQUEST';
export const IMAGE_OWNERS_REQUEST_RECEIVED = '[ACTION] IMAGE_OWNERS_REQUEST_RECEIVED';

export function requestImageOwners(project: Project) {
	return {
		type: IMAGE_OWNERS_REQUEST,
		payload: project,
	};
}

export function imageOwnersReceived(origins: any) {
	return {
		type: IMAGE_OWNERS_REQUEST_RECEIVED,
		payload: origins,
	};
}
