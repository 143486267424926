import React, { useEffect, useState } from 'react';
import UsersListCategoryPermissions from './users-list-category-permissions.component';
import CategoriesList from './categories-list.component';
import './category-permissions.component.scss';
import { Button } from 'reactstrap';
import CategoryPermissionsService from './category-permissions-service/category-permissions.service';
import { toast } from 'react-toastify';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

type Properties = { t: any };

const CategoryPermissions: React.FunctionComponent<Properties> = ({ t }) => {
	//Initializing with an empty id so that CategoriesList diff check for re-render doesn't break with id of null
	const [selectedAdmin, setSelectedAdmin] = useState({ id: '' });
	const permissionsService = new CategoryPermissionsService();

	const updateAdminPermissions = () => {
		if (selectedAdmin && selectedAdmin.id.length > 0) {
			permissionsService.updateAdminPermissions(selectedAdmin).then(() => {
				toast.success(t('category_permissions_successfully_saved'));
				refreshCategoryList();
			});
		} else {
			toast.error(t('category_permissions_no_user_selected'));
		}
	};

	const refreshCategoryList = () => {
		const tempSelectedAdmin = JSON.parse(JSON.stringify(selectedAdmin));
		setSelectedAdmin({ id: '' });
		setSelectedAdmin(tempSelectedAdmin);
	};

	return (
		<div className='animated fadeIn category-listing-container-permissions'>
			<div className='card-header'>
				<div>{t('category_permissions')}</div>
				<div>
					<Button
						color={'primary'}
						id='category-edit-save-top'
						className={'text-uppercase font-weight-bold mr-2'}
						onClick={() => updateAdminPermissions()}
					>
						<i className='fa fa-floppy-o'></i> {t('save_category_permissions')}
					</Button>
				</div>
			</div>
			<div className='card'>
				<div className='d-flex flex-row'>
					<UsersListCategoryPermissions
						t={t}
						onAdminSelect={(admin: any) => {
							setSelectedAdmin(admin);
						}}
						permissionsService={permissionsService}
					/>
					<CategoriesList
						t={t}
						admin={selectedAdmin}
						onCategoriesPermissionsChange={(categories) => permissionsService.setCategories(categories)}
						permissionsService={permissionsService}
					/>
				</div>
			</div>
		</div>
	);
};

export default compose(withTranslation(), withRouter)(CategoryPermissions);
