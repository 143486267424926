import React from 'react';
import { Properties, State } from './properties/team-listing.properties';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Subscription } from 'rxjs';
import { actionService, featuresService } from '../../../../../App';
import Project from '../../../../../models/project/Project';
import {
	returnObjectForTeamListingRequest,
	returnObjectForTeamListingWithManualDataFilterRequest,
	returnObjectForTeamSearch,
} from '../../../../../store/action-creators/team-action-creator';
import ContentTitleContainer from '../../../../Partials/BaseComponents/ListingComponent/subcomponents/ContentTitle/ContentTitleContainer';
import ListingComponent from '../../listing-container.component';
import { ContentTypes } from '../../../../../constants/content-types';
import * as uiActions from '../../../../../store/action-creators/UiActionCreator';
import { FeatureTypes } from '../../../../../services/feature-service/features.enum';
import PaginationMeta from '../../../../../models/pagination/PaginationMeta';
import { returnObjectForArticlePaginationUpdate } from '../../../../../store/action-creators/ArticleActionCreator';
import { returnObjectForVideoPaginationUpdate } from '../../../../../store/action-creators/VideoActionCreator';
import { returnObjectForGalleryPaginationUpdate } from '../../../../../store/action-creators/GalleryActionCreator';
import { returnObjectForImagesPaginationUpdate } from '../../../../../store/action-creators/ImageActionCreator';
import { returnObjectForContentFiltersUpdate } from '../../../../../store/action-creators/content-resources';
import { remmapContentFilters } from '../../../Articles/Helpers/ArticleHelper';
import AdvancedFilterModel from '../../../../Partials/Shared/advanced-content-filter/models/advanced-content-filter.model';

class TeamListingComponent extends React.Component<Properties, State> {
	teamsSubscription: Subscription = {} as Subscription;
	constructor(props: Properties) {
		super(props);
		this.state = {
			toggleModal: false,
			currentPage: '1',
		};
	}

	componentDidMount(): void {
		this.initPageTitle();
		this.props.analytics.sendPageView(this.props.location.pathname, 'TeamListing');
		if (this.props.searchText && this.props.searchText.length === 0) {
			this.getTeams(this.state.currentPage, this.props.currentProject);
		}
		this.props.updateArticlesPagination(PaginationMeta.builder().withCurrentPage(1).build());
		this.props.updateVideosPagination(PaginationMeta.builder().withCurrentPage(1).build());
		this.props.updateGalleriesPagination(PaginationMeta.builder().withCurrentPage(1).build());
		this.props.updateImagesPagination(PaginationMeta.builder().withCurrentPage(1).build());
		const filters = remmapContentFilters({}, {}, {}, {}, {}, {}, {}, {}, {}, {} as AdvancedFilterModel);
		this.props.updateContentFilters(filters);
	}

	setCurrentPage = (page: string) => {
		this.props.analytics.sendPageView(this.props.location.pathname, `${parseInt(page) > 1 ? 'TeamListing - Page ' + page : 'TeamListing'}`);
		this.setState({
			...this.state,
			currentPage: page,
		});
	};

	initPageTitle() {
		document.title = this.props.t('teams');
	}

	private getTeams = (page: string, project: Project) => {
		if (featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.LIMIT_FOOTBALL_MANUAL_DATA)) {
			const teamsToFilter = featuresService.getFeatureConfig(FeatureTypes.LIMIT_FOOTBALL_MANUAL_DATA).teams;
			this.props.getTeamsWithManualFilter(teamsToFilter, project);
		} else {
			this.props.getTeams(page, project);
		}
	};

	render() {
		const { t, analytics, toggleLoadingState, teams } = this.props;

		return (
			<div className='animated fadeIn'>
				<div className='card'>
					<ContentTitleContainer contentType={ContentTypes.TEAM} t={t} />
					<div className='card-body'>
						<ListingComponent
							t={t}
							content={teams}
							contentType={ContentTypes.TEAM}
							analytics={analytics}
							toggleLoadingState={toggleLoadingState}
							history={this.props.history}
							getContent={this.getTeams}
							onPageChange={this.setCurrentPage}
							currentProject={this.props.currentProject}
							contentPagination={this.props.pagination}
							searchContent={this.props.searchTeams}
							lang={this.props.lang}
							showSearchBar={!featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.LIMIT_FOOTBALL_MANUAL_DATA)}
						/>
					</div>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state: any) {
	return {
		teams: state.team.teams,
		currentProject: state.project.currentProject,
		pagination: state.team.pagination,
		isToggledModal: state.ui.isToggleModal,
		toggleLoadingState: state.ui.toggleLoadingState,
		lang: state.project.currentProject.language,
		searchText: state.team.searchFilter,
	};
}

function mapDispatchToProps(dispatch: any) {
	return {
		dispatch: (action: any) => dispatch(action),
		getTeams: (page: string, project: Project) => dispatch(returnObjectForTeamListingRequest(page, project)),
		getTeamsWithManualFilter: (teams: string[], project: Project) =>
			dispatch(returnObjectForTeamListingWithManualDataFilterRequest(teams, project)),
		searchTeams: (text: string, lang: string, type: string) => dispatch(returnObjectForTeamSearch(text, lang, type)),
		updateGalleriesPagination: (pagination: PaginationMeta) => dispatch(returnObjectForGalleryPaginationUpdate(pagination)),
		updateVideosPagination: (pagination: PaginationMeta) => dispatch(returnObjectForVideoPaginationUpdate(pagination)),
		updateArticlesPagination: (pagination: PaginationMeta) => dispatch(returnObjectForArticlePaginationUpdate(pagination)),
		updateImagesPagination: (pagination: PaginationMeta) => dispatch(returnObjectForImagesPaginationUpdate(pagination)),
		updateContentFilters: (filters: any) => dispatch(returnObjectForContentFiltersUpdate(filters)),
	};
}

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(TeamListingComponent) as React.ComponentType;
