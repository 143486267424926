import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import StageModel from '../../../../../../models/v2/Stage/Entity/StageModel';
import { blService, uiService } from '../../../TournamentContainer';
import TeamModel from '../../../../../../models/v2/Team/Entity/TeamModel';
import { toast } from 'react-toastify';
import { ModalContent } from './ModalContent';
import Loader from '../../../../../Partials/Shared/loader/Loader';
import { connect } from 'react-redux';

type Properties = {
	seasonName: string;
	open: boolean;
	closeModal: Function;
	stage: StageModel;
	previousStageForCopyTeams: StageModel;
	copyTeamsButtonVisibleFlag: boolean;
	t: any;
	language: string;
};

const StageTeamsModal: React.FunctionComponent<Properties> = ({
	open,
	closeModal,
	stage,
	t,
	seasonName,
	language,
	previousStageForCopyTeams,
	copyTeamsButtonVisibleFlag,
}) => {
	const [defaultStageTeams, setDefaultStageTeams] = useState(new Array<TeamModel>());
	const [stageTeams, setStageTeams] = useState(new Array<TeamModel>());
	const [addNewTeamButtonDisabled, setAddNewTeamButtonDisabled] = useState(false);
	const [notSavedTeams, setNotSavedTeams] = useState(false);
	const [areTeamsLoading, setAreTeamsLoading] = useState(false);

	const displayNotSavedTeamsWarning = () => {
		const diffFlag = uiService.checkAreThereDiffBetweenTeams(defaultStageTeams, stageTeams);
		setNotSavedTeams(diffFlag);
	};

	useEffect(() => {
		if (stage.id) {
			setAreTeamsLoading(true);
			blService.getTournamentStageTeams(stage.id, language).then((teams) => {
				setStageTeams(teams);
				setDefaultStageTeams(teams);
				displayNotSavedTeamsWarning();
				setAreTeamsLoading(false);
			});
		}
	}, [stage.id]);

	useEffect(() => {
		displayNotSavedTeamsWarning();
	}, [stageTeams]);

	const addTeam = () => {
		const isThereEmptyInputField = stageTeams.findIndex((team) => team.id === '') >= 0;
		if (!isThereEmptyInputField) {
			const newTeam = new TeamModel(); // creating empty team just to render Select field
			setStageTeams((prevTeams: any) => [...prevTeams, newTeam]);
			setAddNewTeamButtonDisabled(true);
		}
	};

	const copyTeamsFromPreviousStage = () => {
		if (previousStageForCopyTeams.id) {
			blService.getTournamentStageTeams(previousStageForCopyTeams.id, language).then((teams) => {
				if (teams && teams.length > 0) {
					setStageTeams(teams);
					displayNotSavedTeamsWarning();
					toast.success(t('stage_teams_copy_success'));
				} else {
					toast.warn(t('stage_teams_copy_no_items'));
				}
			});
		}
	};

	const saveStageTeams = () => {
		const filledStageTeams = [...stageTeams].filter((team) => team.id !== '');
		const teamsId = filledStageTeams.map((team) => {
			return { team_id: team.id };
		});
		blService.updateTournamentStageTeams(stage.id, teamsId).then((response) => {
			if (response.status >= 200 && response.status < 300) {
				setDefaultStageTeams([...filledStageTeams]);
				setNotSavedTeams(false);
				toast.success(t('teams_update_success'));
			} else {
				toast.error(t('error_occurred'));
			}
		});
	};

	const onCloseModal = () => {
		setStageTeams([...defaultStageTeams]);
		setAddNewTeamButtonDisabled(false);
		closeModal();
	};

	if (open) {
		return (
			<Modal isOpen={open}>
				<ModalHeader toggle={onCloseModal}>
					{stage.name} - {seasonName}
				</ModalHeader>
				<ModalBody>
					{areTeamsLoading ? (
						<Loader className='loading-overlay-h-auto loading-overlay-h-fill' />
					) : (
						<div className='tournaments-modal-wrapper'>
							<p className='font-weight-bold'>{t('teams')}</p>
							<ModalContent
								defaultStageTeams={defaultStageTeams}
								stageTeams={stageTeams}
								setStageTeams={setStageTeams}
								setAddNewTeamButtonDisabled={setAddNewTeamButtonDisabled}
								t={t}
							/>
							{notSavedTeams && <div className='tournaments-modal-inner-wrapper alert alert-warning mt-2'>{t('unsaved_changes')}</div>}
							<button disabled={addNewTeamButtonDisabled} className='btn btn-outline-primary tournament-add-team-btn mt-4' onClick={addTeam}>
								{t('add_team')}
							</button>
							{copyTeamsButtonVisibleFlag && (
								<button
									disabled={!previousStageForCopyTeams.id}
									className='btn btn-outline-primary tournament-add-team-btn mt-4 ml-2'
									onClick={copyTeamsFromPreviousStage}
								>
									{t('copy_teams_from_previous_stage')}
								</button>
							)}
						</div>
					)}
				</ModalBody>
				<ModalFooter className='modal-footer-modified'>
					<button className='btn btn-primary' onClick={saveStageTeams}>
						{t('save')}
					</button>
					<button className='btn btn-light' onClick={onCloseModal}>
						{t('cancel')}{' '}
					</button>
				</ModalFooter>
			</Modal>
		);
	} else {
		return <></>;
	}
};

function mapStateToProps(state: any) {
	return {
		language: state.project.currentProject.language,
	};
}

export default connect(mapStateToProps)(StageTeamsModal);
