import * as React from 'react';
import { Properties } from './properties/DateTimePickerContainerProps';
import 'flatpickr/dist/themes/material_green.css';
import Flatpickr from 'react-flatpickr';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Bulgarian } from 'flatpickr/dist/l10n/bg';
import moment from 'moment';
import './DateTimePickerContainer.scss';
import { useEffect } from 'react';
import { Subscription } from 'rxjs';

const DateTimePickerContainer: React.FunctionComponent<Properties> = (props) => {
	let flatPickrInstance: any = null;
	let clearServiceSubscription = new Subscription();

	let parsedDate = null;

	const { date, resetButton, mode, language, t, placeholder, dateFormat, enableTime, id, project, useLocalTimezone } = props;

	useEffect(() => {
		return function cleanup() {
			if (clearServiceSubscription) {
				clearServiceSubscription.unsubscribe();
			}
		};
	});

	if (props.clearService) {
		clearServiceSubscription = props.clearService.subscribe(() => {
			refComp.current !== null && refComp.current.flatpickr.clear();
			if (flatPickrInstance) {
				flatPickrInstance.clear();
			}
		});
	}

	const onDateChange = (date: any) => {
		props.onDateChange(date[0]);
	};

	const onDatesChange = (date: any) => {
		const convertStartDate = moment(date[0]).format('YYYY-MM-DD');
		const convertEndDate = moment(date[1]).format('YYYY-MM-DD');
		props.onDateChange([convertStartDate, convertEndDate]);
	};

	const onReset = () => {
		if (flatPickrInstance) {
			flatPickrInstance.clear();
		}
		refComp.current !== null && refComp.current.flatpickr.clear();
		props.onDateChange('');
	};

	const langs = {
		'bg-BG': Bulgarian,
		'en-US': 'en',
	};

	if (date && !mode) {
		if (useLocalTimezone !== true) {
			//@ts-ignore
			let timezonedDate = moment.tz(moment(date), dateFormat, project.timezone);
			parsedDate = new Date(
				timezonedDate.year(),
				timezonedDate.month(),
				timezonedDate.date(),
				timezonedDate.hour(),
				timezonedDate.minute(),
				timezonedDate.second(),
			);
		} else {
			parsedDate = moment(date).toISOString();
		}
	}

	const refComp = React.useRef(null);

	return (
		<div className='datepicker-container'>
			{!mode ? (
				<Flatpickr
					className={`date-picker ${props.className}`}
					id={id}
					options={{
						defaultDate: date ? date : '',
						enableTime,
						dateFormat,
						time_24hr: true,
						locale: langs[language],
						onReady: (arg1, arg2, instance) => {
							flatPickrInstance = instance;
						},
					}}
					placeholder={placeholder ? placeholder : t('date_mode_format')}
					onChange={onDateChange}
					value={parsedDate}
				/>
			) : (
				<Flatpickr
					ref={refComp}
					className={`date-picker ${props.className}`}
					options={{
						defaultDate: date ? date : '',
						dateFormat,
						mode,
						locale: langs[language],
						onReady: (arg1, arg2, instance) => {
							flatPickrInstance = instance;
						},
					}}
					id={id}
					placeholder={placeholder ? placeholder : t('select_date')}
					onChange={onDatesChange}
				/>
			)}
			{resetButton && (
				<div className='close-button' onClick={onReset}>
					<i className='fa fa-times close-icon' />
				</div>
			)}
		</div>
	);
};

function mapStateToProps(state: any) {
	return {
		language: state.profile.profile.language,
		project: state.project.currentProject,
	};
}
export default compose(connect(mapStateToProps))(DateTimePickerContainer);
