import { StageJson } from './stage.json';
import StageBuilder from './stage.builder';

export default class StageModel {
	readonly id: string;
	readonly name: string;

	private constructor(id: string, name: string) {
		this.id = id;
		this.name = name;
	}

	toJson(): StageJson {
		return {
			id: this.id,
			name: this.name,
		};
	}

	static fromJson(json: StageJson): StageModel {
		return new StageModel(json.id, json.name);
	}

	static builder(stage?: StageModel): StageBuilder {
		return new StageBuilder(stage);
	}
}
