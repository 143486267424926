import 'reflect-metadata';
import { AnyT, jsonMember, jsonObject } from 'typedjson';

@jsonObject
export default class FansUnitedGameModel {
	@jsonMember(String)
	public id: string = '';
	@jsonMember(String)
	public title: string = '';
	@jsonMember(String)
	public description: string = '';
	@jsonMember(String)
	public type: string = '';
	@jsonMember(String)
	public status: string = '';
	@jsonMember(String)
	public predictions_cutoff: string = '';
	@jsonMember(AnyT)
	public fixtures: any;
	@jsonMember(String)
	public rules: string = '';
	@jsonMember(AnyT)
	public flags: any;
	@jsonMember(String)
	public created_at: string = '';
	@jsonMember(String)
	public updated_at: string = '';
	@jsonMember(Boolean)
	public isSelected: boolean = false;
}
