import React, { FunctionComponent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../../../store/store';
import './styles/style.scss';
import ImageModel from '../../../../../../models/v2/image/image.model';
import PaginationMeta from '../../../../../../models/v2/Pagination/PaginationModel';
import HttpService from '../../../../../../services/rest/HttpService';
import { responseToPaginationModel } from '../../../../../../models/v2/Pagination/pagination.mapper';
import { createRequestImageUrl, imageListFromResponse, PaginationProps } from './helpers/live-blog-image.helper';
import { Button, Col, Input, InputGroup, InputGroupAddon, Row } from 'reactstrap';
import { DATA_QA_ATTRIBUTES } from '../../../../../Partials/Sidebar/general-content/constants/data-qa.attributes';
import ReactPaginate from 'react-paginate';
import { LiveBlogImageCells } from './live-blog-image-cells.component';
import { LiveBlogAdvancedFiltersComponent } from './live-blog-image-advanced-search-filters.component';

type Properties = {
	t: any;
	onImageSelect: (image: ImageModel) => void;
	selectedImageId: string | null;
};

export const LiveBlogImageListing: FunctionComponent<Properties> = ({ t, onImageSelect, selectedImageId }) => {
	const project = useSelector((state: AppState) => state.project.currentProject);
	const [contentSearchInput, setContentSearchInput] = useState<string>('');
	const [advancedContentSearchInput, setAdvancedContentSearchInput] = useState<string>('');
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [pagination, setPagination] = useState<PaginationMeta>({} as PaginationMeta);
	const [imageList, setImageList] = useState<ImageModel[]>([]);
	const [toggleFilters, setToggleAdvancedFilters] = useState<boolean>(false);

	useEffect(() => {
		requestImages(0, '', '');
	}, []);

	useEffect(() => {
		if (pagination.currentPage) {
			requestImages(pagination.currentPage, contentSearchInput, advancedContentSearchInput);
		}
	}, [pagination.currentPage]);

	const requestImages = (page: number, textSearch: string, advancedContent: string) => {
		setIsLoading(true);
		const url = createRequestImageUrl(textSearch, advancedContent, page);

		HttpService.get(url, 0, { Project: project.domain })
			.then((response: any) => {
				const imageListResp = imageListFromResponse(response.data.data);
				const paginationResp = responseToPaginationModel(response.data.meta.pagination);
				if (paginationResp) {
					updateImageListAndPaginationState(imageListResp, paginationResp, page);
				}
			})
			.catch((e: any) => e);
	};

	const updateImageListAndPaginationState = (imageListData: ImageModel[], paginationData: PaginationMeta, currentPage: number) => {
		setImageList(imageListData);
		setPagination(paginationData);
		setIsLoading(false);
	};

	const onContentSearchInput = (e: any) => {
		const searchText = e.target.value;

		if (searchText.length < 1) {
			setContentSearchInput(searchText);
			requestImages(1, '', advancedContentSearchInput);
		} else {
			setContentSearchInput(searchText);
		}
	};

	const updateAdvancedInputState = (text: string) => {
		setAdvancedContentSearchInput(text);
	};

	const onContentSearch = (e: any) => {
		if (e.keyCode === 13 && contentSearchInput.length > 0) {
			requestImages(1, contentSearchInput, advancedContentSearchInput);
		}
	};

	const onSearch = () => {
		requestImages(1, contentSearchInput, advancedContentSearchInput);
	};

	const getAll = () => {
		requestImages(1, '*', '');
	};

	const toggleAdvancedFilters = () => {
		setToggleAdvancedFilters(!toggleFilters);
	};

	return (
		<>
			<Row className='mb-3'>
				<Col>
					<InputGroup className='mb-2'>
						<Input
							type='text'
							id={DATA_QA_ATTRIBUTES.LIVE_BLOG_IMAGE_SEARCH}
							name={DATA_QA_ATTRIBUTES.LIVE_BLOG_IMAGE_SEARCH_BUTTON}
							placeholder={`${t('search')}`}
							onInput={onContentSearchInput}
							bssize='sm'
							onKeyDown={onContentSearch}
						/>
						<InputGroupAddon addonType='append'>
							<Button type='button' color='primary' bssize='sm' onClick={onSearch}>
								<i className='fa fa-search pr-1' />
							</Button>
							<Button type='button' color='dark' bssize='sm' onClick={toggleAdvancedFilters}>
								<i className='fa-solid fa-filter pr-1' />
							</Button>
						</InputGroupAddon>
					</InputGroup>
					{toggleFilters && (
						<LiveBlogAdvancedFiltersComponent
							t={t}
							searchContent={onSearch}
							updateAdvancedSearchText={updateAdvancedInputState}
							filtersInSidebar
							getAllContent={getAll}
							contentSearchInput={contentSearchInput}
						/>
					)}
				</Col>
			</Row>
			<Row className={isLoading ? 'loading-overlay' : ' mb-3 text-left'}>
				<Col>
					<LiveBlogImageCells images={imageList} selectedImageId={selectedImageId ? selectedImageId : ''} onSelect={onImageSelect} />
				</Col>
			</Row>
			<Row className='text-left'>
				<Col>
					<ReactPaginate
						{...PaginationProps(t, pagination.currentPage)}
						pageCount={pagination.totalPages ? pagination.totalPages : 0}
						onPageChange={({ selected }) => setPagination({ ...pagination, currentPage: selected + 1 })}
					/>
				</Col>
			</Row>
		</>
	);
};
