import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Col, Label, Row } from 'reactstrap';
import HttpService from '../../../../../../services/rest/HttpService';
import RoundModel from '../../../../../../models/v2/round/entity/round-model';
import { responseToRoundModel, roundsToOptions, roundToOption } from '../helpers/round-select/round-select-component.helper';
import ErrorHandler from '../error-handler/error-handler';
import TeamModel from '../../../../../../models/v2/Team/Entity/TeamModel';

type Properties = {
	onRoundSelect: any;
	selectedRound: RoundModel | any;
	t: any;
	isValid: boolean;
	isMulti?: boolean;
	isClearable?: boolean;
	isRequired?: boolean;
	isBlocky: boolean;
	className?: string;
	stageId: number | null;
	preselectRound?: boolean;
	id: string;
};

const RoundSelectContainer: React.FunctionComponent<Properties> = (props) => {
	const [roundsOptions, setRoundsOptions] = useState<RoundModel[]>([]);

	useEffect(() => {
		if (props.stageId) {
			getStageRounds(props.stageId);
		}
	}, [props.stageId]);

	const updateRoundsOptionsState = (rounds: RoundModel[]) => {
		const { onRoundSelect, selectedRound, isBlocky, isMulti } = props;

		if (
			(!selectedRound ||
				(selectedRound && (selectedRound.id === undefined || selectedRound.id.length < 1) && !isMulti) ||
				(isMulti && selectedRound.length === 0)) &&
			isBlocky
		) {
			onRoundSelect(rounds[0]);
		}
		setRoundsOptions(rounds);
	};

	const getStageRounds = (stageId: number | null) => {
		if (stageId && stageId !== null) {
			HttpService.getRoundsByStageIdV2(stageId)
				.then((response: any) => {
					updateRoundsOptionsState(response.data.rounds.map((item: RoundModel) => responseToRoundModel(item)));
				})
				.catch((e: any) => e);
		}
	};

	const onSelect = (rounds: any) => {
		if (props.isMulti) {
			rounds && rounds.length > 0 ? props.onRoundSelect(rounds.map((round: any) => round.data)) : props.onRoundSelect([]);
		} else {
			rounds && rounds.data && rounds.data.id ? props.onRoundSelect(rounds.data) : props.onRoundSelect(null);
		}
	};

	const { isRequired, t, className, isMulti, selectedRound, isClearable, stageId, isValid } = props;
	const roundSelected = props.selectedRound === undefined || props.selectedRound === null || !props.selectedRound.id;

	return (
		<Row data-qa={props.id}>
			<Col>
				<Label htmlFor={props.id}>{t('round')}:</Label>
				{isRequired && <span className='text-danger'>*</span>}
				<Select
					id={props.id}
					isMulti={isMulti}
					menuPortalTarget={document.body}
					menuPosition='absolute'
					className={className ? className : ''}
					options={stageId ? roundsToOptions(roundsOptions) : []}
					value={isMulti ? roundsToOptions(selectedRound) : roundToOption(selectedRound)}
					noOptionsMessage={(inputValue) => inputValue && props.t('no_options')}
					isClearable={isClearable}
					placeholder={t('select')}
					onChange={onSelect}
				/>

				{isRequired && <ErrorHandler t={t} arePropsValid={isValid} isValueSelected={roundSelected} errorMessage='field_is_required' />}
			</Col>
		</Row>
	);
};

export default RoundSelectContainer as React.ComponentType<Properties>;
