import { TypedJSON } from 'typedjson';
import SeasonModel from '../../../../../../../models/v2/Season/Entity/SeasonModel';

export const responseToSeasonModel = (response: any) => {
	const serializer = new TypedJSON(SeasonModel);
	const remmapedSeason = serializer.parse(response) as SeasonModel;

	return remmapedSeason;
};

export const seasonsToOptions = (seasons: SeasonModel[]) => {
	if (seasons && seasons.length > 0) {
		return seasons.map((season) => {
			return seasonToOption(season);
		});
	}

	return [];
};

export const seasonToOption = (season: SeasonModel) => {
	if (season) {
		return {
			value: season.id,
			label: season.name,
			data: season,
			type: 'season',
		};
	} else {
		return {};
	}
};
