import SportOddsBookmakerModel from '../../../../../../../models/sport-odds/sport-odds-bookmaker.model';
import TeamModel from '../../../../../../../models/v2/Team/Entity/TeamModel';
import MatchModel from '../../../../../../../models/v2/match/entity/match.model';
import { RefreshTimeValuesType } from '../../../partials/refresh-time-input/helper';
import StakesBuilder from './stakes-widget.builder';
import { StakesJson } from './stakes-widget.json';

export default class StakesModel {
	readonly team: TeamModel;
	readonly match: MatchModel;
	readonly amount: number;
	readonly fixedAmount: boolean;
	readonly bookmakers: SportOddsBookmakerModel[] | null;
	readonly refreshTime: RefreshTimeValuesType = null;

	private constructor(
		team: TeamModel,
		match: MatchModel,
		amount: number,
		fixedAmount: boolean,
		bookmakers: SportOddsBookmakerModel[] | null,
		refreshTime: RefreshTimeValuesType,
	) {
		this.team = team;
		this.match = match;
		this.amount = amount;
		this.fixedAmount = fixedAmount;
		this.bookmakers = bookmakers;
		this.refreshTime = refreshTime;
	}

	toJson(): StakesJson {
		return {
			team: this.team,
			match: this.match,
			bookmakers: this.bookmakers,
			refreshTime: this.refreshTime,
			amount: this.amount,
			fixedAmount: this.fixedAmount,
		};
	}

	static fromJson(json: StakesJson): StakesModel {
		return new StakesModel(json.team, json.match, json.amount, json.fixedAmount, json.bookmakers, json.refreshTime);
	}

	static builder(odds?: StakesModel): StakesBuilder {
		return new StakesBuilder(odds);
	}
}
