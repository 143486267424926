import SpotlightAudioModel from '../../../../../../models/spotlight-audio/spotlight-audio.model';
import { getUniqueOnly } from '../../../shared/global-functions/global-functions';
import FilterModel from '../models/filter.model';

export const remapAudioFromResponse = (item: any, sportType: any) => {
	if (item && sportType) {
		const filters: any = FilterModel.builder()
			.withFilterSport(sportType)
			.withFilterLanguage(remapToOption(item, 'language'))
			.withFilterCompetition(remapToOption(item, 'competition'))
			.build();

		return SpotlightAudioModel.builder()
			.withHomeTeamId(item.HomeTeamId)
			.withHomeTeam(item.HomeTeam)
			.withAwayTeam(item.AwayTeam)
			.withAwayTeamId(item.AwayTeamId)
			.withCompetition(item.Competition)
			.withKickOff(item.KickOff)
			.withLanguage(item.Language)
			.withSport(sportType)
			.withMatchId(item.MatchId)
			.withFilters(filters)
			.withConfig(item)
			.build();
	}

	return {};
};

export const remapAudioList = (data: any, sport: any) => {
	if (data && data.length > 0) {
		return data[0].MatchDetails.map((audio: any) => remapAudioFromResponse(audio, sport));
	}
	return [];
};

export const applyFilters = (options: any, sport: any, language: any, competition: any) => {
	if (options && options.length > 0) {
		return options.map((option: any) => {
			return option.language === language.label && option.competition === competition.label && option.sport.label === sport.label
				? option
				: {};
		});
	}

	return [];
};

export const sportToOptions = (data: any) => {
	return getUniqueOnly(extractSportFilter(data).flat(1), 'label');
};

export const languageToOptions = (data: any) => {
	return getUniqueOnly(extractLanguageFilter(data).flat(1), 'label');
};

export const competitionToOptions = (data: any) => {
	return getUniqueOnly(extractCompetitionFilter(data).flat(1), 'label');
};

export const extractSportFilter = (data: any) => {
	if (data && data.SportDetails && data.SportDetails.length > 0) {
		return data.SportDetails.map((sport: any) => remapToOption(sport, 'sport'));
	}

	return [];
};

export const extractLanguageFilter = (data: any) => {
	if (data && data.SportDetails && data.SportDetails.length > 0) {
		return data.SportDetails[0].MatchDetails.map((audio: any) => remapToOption(audio, 'language'));
	}
	return [];
};

export const extractCompetitionFilter = (data: any) => {
	if (data && data.SportDetails && data.SportDetails.length > 0) {
		return data.SportDetails[0].MatchDetails.map((audio: any) => remapToOption(audio, 'competition'));
	}
	return [];
};

export function remapToOption(item: any, type: string) {
	if (item && type) {
		switch (type) {
			case 'language':
				return {
					value: item.LanguageId,
					label: item.Language,
					type: type,
				};
			case 'competition':
				return {
					value: item.CompetitionId,
					label: item.Competition,
					type: type,
				};
			case 'sport':
				return {
					value: item.SportId,
					label: item.Sport,
					type: type,
				};
			default:
				return {
					value: '',
					label: '',
					type: '',
				};
		}
	}

	return {};
}
