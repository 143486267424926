import { TreeItem } from 'react-sortable-tree';
import {
	extractMultisportModel,
	isCompetition,
	isEvent,
	setTopEventSectionAsFirstElement,
} from '../../views/Pages/Multisport-widget/helpers/utils';
import MultisportCompetitionModelWrapper from '../../views/Pages/Multisport-widget/models/competition-wrapper.model';
import MultisportEventModel from '../../views/Pages/Multisport-widget/models/event.model';
import MultisportModel from '../../views/Pages/Multisport-widget/models/sport.model';
import {
	ADD_MULTISPORT_ENTITIES,
	CLOSE_MULTISPORT_MODAL,
	LOAD_MULTISPORT_ENTITIES,
	POPULATE_MULTISPORT_MODAL,
	REMOVE_MULTISPORT_ENTITIES,
	RESET_MULTISPORT_DATA,
	SET_MULTISPORT_CONFIGURATION_DATE,
	SET_MULTISPORT_EVENT_SEARCH_DATE,
	SORT_MULTISPORT_ENTITIES,
} from '../action-creators/multisport-action-creator';

export type ModalConfig = { displayed: boolean; data: any };
export type ModalsType = Record<string, ModalConfig>;
interface InitialState {
	previewData: MultisportModel[];
	modals: ModalsType;
	configurationDate: Date;
	eventSearchDate: Date;
}

const initialState: InitialState = {
	previewData: [],
	modals: {
		removeEntity: {
			displayed: false,
			data: null,
		},
		save: {
			displayed: false,
			data: null,
		},
	},
	configurationDate: new Date(),
	eventSearchDate: new Date(),
};

function multisportReducer(state: any = initialState, action: any) {
	if (action.type === LOAD_MULTISPORT_ENTITIES) {
		return Object.assign({}, state, {
			previewData: action.payload,
		});
	}

	if (action.type === ADD_MULTISPORT_ENTITIES) {
		const payloadSport: string = action.payload.sport;
		const payloadEvents: MultisportEventModel[] = action.payload.events;
		const payloadCompetition = { ...payloadEvents[0].competition }; // you cannot add events from different competitions in one action

		// find the sport in already created sports
		const selectedPreviewData = extractMultisportModel(state.previewData, payloadSport);

		if (selectedPreviewData) {
			// find the competition in already created competitions
			const selectedCompetitionData = selectedPreviewData.competitions.find(
				(competition: MultisportCompetitionModelWrapper) => competition.competition.id === payloadCompetition.id,
			);

			if (selectedCompetitionData) {
				// add events to the existing competition
				selectedCompetitionData.events = [...selectedCompetitionData.events, ...payloadEvents];
			} else {
				// create new competition with the events inside
				selectedPreviewData.competitions = [...selectedPreviewData.competitions, { competition: payloadCompetition, events: payloadEvents }];
			}
		} else {
			// create new sport with the competition inside
			state.previewData = [
				...state.previewData,
				{ sport: payloadSport, competitions: [{ competition: payloadCompetition, events: payloadEvents }] },
			];
		}

		// this is added just to change the reference of the object and trigger the change detection
		return Object.assign({}, state, {
			previewData: setTopEventSectionAsFirstElement(state.previewData),
		});
	}

	if (action.type === SORT_MULTISPORT_ENTITIES) {
		const payloadTreeData: TreeItem[] = action.payload;
		const { previewSection } = payloadTreeData[0];
		const selectedPreviewData = extractMultisportModel(state.previewData, previewSection);

		if (selectedPreviewData) {
			for (let treeItemIndex = 0; treeItemIndex < payloadTreeData.length; treeItemIndex++) {
				const treeCompetition = payloadTreeData[treeItemIndex].element;
				const treeCompetitionEvents = payloadTreeData[treeItemIndex].children as TreeItem[];

				// assign the new order of competitions and events to the already added sport
				selectedPreviewData.competitions[treeItemIndex].competition = treeCompetition;
				if (treeCompetitionEvents.length > 0) {
					selectedPreviewData.competitions[treeItemIndex].events = treeCompetitionEvents.map((event: TreeItem) => event.element);
				}
			}
		}

		// this is added just to change the reference of the array and trigger the change detection
		return Object.assign({}, state, { previewData: [...state.previewData] });
	}

	if (action.type === REMOVE_MULTISPORT_ENTITIES) {
		const removeEntityModalData = state.modals.removeEntity.data;
		const { entity, previewSection } = removeEntityModalData;
		const { id, entity_type } = entity;

		const selectedPreviewData = extractMultisportModel(state.previewData, previewSection);
		if (selectedPreviewData) {
			if (isCompetition(entity_type)) {
				selectedPreviewData.competitions = selectedPreviewData.competitions.filter((comp) => comp.competition.id !== id);
			} else if (isEvent(entity_type)) {
				selectedPreviewData.competitions.forEach((comp) => {
					comp.events = comp.events.filter((event) => event.id !== id);

					// when removing all events from a competition, remove the competition as well
					if (comp.events.length === 0) {
						selectedPreviewData.competitions = selectedPreviewData.competitions.filter((comp) => comp.events.length > 0);
					}
				});
			}

			// when removing all competitions, remove the sport as well
			if (selectedPreviewData.competitions.length === 0) {
				state.previewData.splice(state.previewData.indexOf(selectedPreviewData), 1);
			}
		}

		return Object.assign({}, state, {
			previewData: [...state.previewData],
			modals: { ...state.modals, removeEntity: { displayed: false, data: null } },
		});
	}

	if (action.type === POPULATE_MULTISPORT_MODAL) {
		const { modalType, data } = action.payload;

		return Object.assign({}, state, {
			modals: {
				...state.modals,
				[modalType]: {
					...state.modals[modalType],
					displayed: true,
					data,
				},
			},
		});
	}

	if (action.type === CLOSE_MULTISPORT_MODAL) {
		const modalType = action.payload;

		if (modalType) {
			return Object.assign({}, state, {
				modals: {
					...state.modals,
					[modalType]: {
						displayed: false,
						data: null,
					},
				},
			});
		}
	}

	if (action.type === SET_MULTISPORT_CONFIGURATION_DATE) {
		return Object.assign({}, state, {
			configurationDate: action.payload,
		});
	}

	if (action.type === SET_MULTISPORT_EVENT_SEARCH_DATE) {
		return Object.assign({}, state, {
			eventSearchDate: action.payload,
		});
	}

	if (action.type === RESET_MULTISPORT_DATA) {
		return Object.assign({}, state, { initialState });
	}

	return state;
}

export default multisportReducer;
