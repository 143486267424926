import { OddsJsonV2 } from './odds-widget.json';
import OddsBuilderV2 from './odds-widget.builder';
import TeamModel from '../../../../../../../models/v2/Team/Entity/TeamModel';
import MarketModel from './v2/market.model';
import MatchModelV2 from '../../football-single-event/models/MatchModelV2.model';
import MarketValueTypeModel from './v2/market-value-type.model';
import SportOddsBookmakerModel from '../../../../../../../models/sport-odds/sport-odds-bookmaker.model';
import { RefreshTimeValuesType } from '../../../partials/refresh-time-input/helper';
import MatchModel from '../../../../../../../models/v2/match/entity/match.model';

export default class OddsModelV2 {
	readonly team: TeamModel;
	readonly match: MatchModel;
	readonly mainBookmaker: SportOddsBookmakerModel | null;
	readonly market: MarketModel = { name: '', value: '' };
	readonly marketValueType: MarketValueTypeModel = { name: '', value: '' };
	readonly bookmakers: SportOddsBookmakerModel[] | null;
	readonly refreshTime: RefreshTimeValuesType = null;

	private constructor(
		team: TeamModel,
		match: MatchModel,
		mainBookmaker: SportOddsBookmakerModel | null,
		market: MarketModel,
		marketValueType: MarketValueTypeModel,
		bookmakers: SportOddsBookmakerModel[] | null,
		refreshTime: RefreshTimeValuesType,
	) {
		this.team = team;
		this.match = match;
		this.mainBookmaker = mainBookmaker;
		this.market = market;
		this.marketValueType = marketValueType;
		this.bookmakers = bookmakers;
		this.refreshTime = refreshTime;
	}

	toJson(): OddsJsonV2 {
		return {
			team: this.team,
			match: this.match,
			mainBookmaker: this.mainBookmaker,
			market: this.market,
			marketValueType: this.marketValueType,
			bookmakers: this.bookmakers,
			refreshTime: this.refreshTime,
		};
	}

	static fromJson(json: OddsJsonV2): OddsModelV2 {
		return new OddsModelV2(json.team, json.match, json.mainBookmaker, json.market, json.marketValueType, json.bookmakers, json.refreshTime);
	}

	static builder(odds?: OddsModelV2): OddsBuilderV2 {
		return new OddsBuilderV2(odds);
	}
}
