import {
	CONTENT_STATISTICS_WIKI_ENTITY_RECEIVED,
	WIKI_ALREADY_EXISTS,
	WIKI_CONTENT_STATISTICS_RECEIVED,
	WIKI_COPY_RELATED_RECEIVED,
	WIKI_CREATE_RESOURCES_SUCCESS,
	WIKI_ENTITY_CREATE,
	WIKI_ENTITY_CREATE_FAILED,
	WIKI_ENTITY_CREATE_SUCCESS,
	WIKI_ENTITY_RECEIVED,
	WIKI_ENTITY_RELATED_CREATE_SUCCESS,
	WIKI_ENTITY_RELATED_RECEIVED,
	WIKI_ENTITY_UPDATE,
	WIKI_ENTITY_UPDATE_FAILED,
	WIKI_ENTITY_UPDATE_SUCCESS,
	WIKI_PAGES_ENTITY_DELETE_SUCCESS,
	WIKI_PAGES_LISTING_RECEIVED,
} from '../action-creators/WikiActionCreator';
import ModelMapper from '../../models/ModelMapper';
import { toast } from 'react-toastify';
import i18n from '../../i18n';
import { relatedConstants } from '../../constants/related.constants';
import { actionService, analyticsService } from '../../App';
import { SEARCH_IS_APPLIED } from '../action-creators/UiActionCreator';
import { ContentTypes } from '../../constants/content-types';
import {
	extractSportsRelatedFromResponse,
	extractEventEntitiesFromSports,
} from '../../views/Partials/Shared/advanced-content-filter/subcomponents/v2/sports-select/sports-connections.helper';
import Wiki from '../../models/wiki/Wiki';
import { responseToWikiListModel } from '../../models/Wikipages/List/WikiListMapper';
import { responseToMetaModel } from '../../models/Wikipages/List/MetaMapper';

export function wikiMiddleware() {
	return function (next: any) {
		return function (action: any) {
			if (action) {
				if (action.type === WIKI_PAGES_LISTING_RECEIVED) {
					action.payload.wikiPages = responseToWikiListModel(action.payload.data);
					action.payload.contentPagination = responseToMetaModel(action.payload.data.meta);
				}

				if (action.type === WIKI_ENTITY_RECEIVED) {
					action.payload.wikiEdit = ModelMapper.remapWikiFromResponse(action.payload.data.data);
				}

				if (action.type === WIKI_ENTITY_RELATED_RECEIVED || action.type === WIKI_COPY_RELATED_RECEIVED) {
					const response = action.payload.related.data.data;
					const sportsEventEntities = extractEventEntitiesFromSports(action.payload.sports);

					let allRelated = [];
					const relatedFootball = ModelMapper.extractFootballRelatedFromResponse(response);
					allRelated.push(...relatedFootball);
					const relatedSports = extractSportsRelatedFromResponse(response, action.payload.sports);
					allRelated.push(...relatedSports);

					action.payload.relatedSports = allRelated;

					action.payload.tagsRelated = ModelMapper.extractRelatedTypeFromResponse(
						response,
						relatedConstants.types.tag,
						relatedConstants.providers.smp,
					);

					action.payload.relatedContent = ModelMapper.extractRelatedContentFromResponse(response);

					let relatedEvents = [];
					const relatedMatches = ModelMapper.extractRelatedTypeFromResponse(
						response,
						relatedConstants.types.match,
						relatedConstants.providers.football,
					);
					relatedEvents.push(...relatedMatches);
					const relatedGames = ModelMapper.extractSportsEntitiesRelatedTypeFromResponse(
						response,
						sportsEventEntities.flat(),
						relatedConstants.providers.sports,
					);
					relatedEvents.push(...relatedGames);
					action.payload.matchRelated = relatedEvents;
				}

				if (action.type === WIKI_ENTITY_CREATE) {
					actionService.emitGeneralAction(WIKI_ENTITY_CREATE);
					action.payload.wiki = Wiki.builder(action.payload.wiki).toRequestJson();
					action.payload.related = ModelMapper.remapRelatedListToRelatedJsonList(action.payload.related.slice());
				}

				if (action.type === WIKI_ENTITY_UPDATE) {
					actionService.emitGeneralAction(WIKI_ENTITY_UPDATE);
					action.payload.wiki = Wiki.builder(action.payload.wiki).toRequestJson();
					action.payload.related = ModelMapper.remapRelatedListToRelatedJsonList(action.payload.related);
				}

				if (action.type === WIKI_ENTITY_CREATE_SUCCESS) {
					actionService.emitGeneralAction(WIKI_ENTITY_CREATE_SUCCESS);
					window.dispatchEvent(new CustomEvent(WIKI_ENTITY_CREATE_SUCCESS, { detail: action.payload }));
					analyticsService.sendWidgetAnalytic();
					toast.success(i18n.t('wiki_create_success'));
				}

				if (action.type === WIKI_ENTITY_CREATE_FAILED) {
					actionService.emitGeneralAction(WIKI_ENTITY_CREATE_FAILED);
					//TODO Replace  window.dispatchEvent with the actionService
					window.dispatchEvent(new CustomEvent(WIKI_ENTITY_CREATE_FAILED, { detail: action.payload }));
					toast.error(i18n.t('wiki_create_failed'));
				}

				if (action.type === WIKI_ENTITY_RELATED_CREATE_SUCCESS) {
					//TODO Replace  window.dispatchEvent with the actionService
					window.dispatchEvent(new CustomEvent(WIKI_ENTITY_RELATED_CREATE_SUCCESS, { detail: action.payload }));
				}

				if (action.type === WIKI_CREATE_RESOURCES_SUCCESS) {
					actionService.emitGeneralAction(WIKI_CREATE_RESOURCES_SUCCESS);
					window.dispatchEvent(new CustomEvent(WIKI_CREATE_RESOURCES_SUCCESS, { detail: action.payload }));
					// not sure if its not better to display the toast notification for successfully created wiki here
					// otherwise it can display that the wiki is created successfully but the related data to have a problem..
					// TODO to be discussed with the team
				}

				if (action.type === WIKI_ENTITY_UPDATE_SUCCESS) {
					actionService.emitGeneralAction(WIKI_ENTITY_UPDATE_SUCCESS);
					analyticsService.sendWidgetAnalytic();
					toast.success(i18n.t('wiki_update_success'));
				}

				if (action.type === WIKI_ENTITY_UPDATE_FAILED) {
					actionService.emitGeneralAction(WIKI_ENTITY_UPDATE_FAILED);
					toast.error(i18n.t('wiki_update_failed'));
				}

				if (action.type === WIKI_PAGES_ENTITY_DELETE_SUCCESS) {
					toast.success(i18n.t('wiki_deleted_success'));
					window.dispatchEvent(new CustomEvent(action.type));
				}

				if (action.type === SEARCH_IS_APPLIED) {
					actionService.emitGeneralAction(SEARCH_IS_APPLIED);
				}

				if (action.type === WIKI_CONTENT_STATISTICS_RECEIVED) {
					action.payload.wikiPages = ModelMapper.remmapListingContentWithContentStatisticsFromResponse(
						action.payload.contentStatistics,
						action.payload.wikiPages,
						ContentTypes.WIKI_PAGE,
					);
				}

				if (action.type === CONTENT_STATISTICS_WIKI_ENTITY_RECEIVED) {
					action.payload.wiki = ModelMapper.remmapContentWithContentStatistics(
						action.payload.contentStatistics[0],
						action.payload.wiki,
						ContentTypes.WIKI_PAGE,
					);
				}

				if (action.type === WIKI_ALREADY_EXISTS) {
					actionService.emitGeneralAction(WIKI_ALREADY_EXISTS);
					toast.error(`${i18n.t('wiki_with_this_code_already_exists')}`);
				}
			}

			next(action);
		};
	};
}
