import React from 'react';
import Select from 'react-select';
import { selectedItemsToOptions } from '../championship-filter-select/helpers/championship-filter-select.helper';
import { playerPositionOptions } from './helpers/player-position-multi-select.helper';

type Properties = {
	onPlayerPositionSelect: any;
	value: any[];
	t: any;
	isMulti: boolean;
};

const PlayerPositionMultiSelect: React.FunctionComponent<Properties> = ({ value, t, onPlayerPositionSelect }) => {
	const PlayerPositionSelect = (selections: any) => {
		if (onPlayerPositionSelect) {
			onPlayerPositionSelect(selections);
		}
	};

	return (
		<>
			<Select
				isMulti
				options={playerPositionOptions(t)}
				onChange={PlayerPositionSelect}
				value={selectedItemsToOptions(value)}
				noOptionsMessage={(inputValue) => inputValue && t('no_options')}
			/>
		</>
	);
};

export default PlayerPositionMultiSelect;
