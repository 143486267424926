export type CountryPhoneData = {
	label: string;
	name: string;
	country_code: string;
	country_iso_code: string;
};

export const countryPhoneData: Record<string, CountryPhoneData> = {
	AC: {
		label: 'Ascension Island (+247)',
		name: 'Ascension Island',
		country_code: '+247',
		country_iso_code: 'AC',
	},
	AD: {
		label: 'Andorra (+376)',
		name: 'Andorra',
		country_code: '+376',
		country_iso_code: 'AD',
	},
	AE: {
		label: 'United Arab Emirates (+971)',
		name: 'United Arab Emirates',
		country_code: '+971',
		country_iso_code: 'AE',
	},
	AF: {
		label: 'Afghanistan (+93)',
		name: 'Afghanistan',
		country_code: '+93',
		country_iso_code: 'AF',
	},
	AG: {
		label: 'Antigua and Barbuda (+1)',
		name: 'Antigua and Barbuda',
		country_code: '+1',
		country_iso_code: 'AG',
	},
	AI: {
		label: 'Anguilla (+1)',
		name: 'Anguilla',
		country_code: '+1',
		country_iso_code: 'AI',
	},
	AL: {
		label: 'Albania (+355)',
		name: 'Albania',
		country_code: '+355',
		country_iso_code: 'AL',
	},
	AM: {
		label: 'Armenia (+374)',
		name: 'Armenia',
		country_code: '+374',
		country_iso_code: 'AM',
	},
	AO: {
		label: 'Angola (+244)',
		name: 'Angola',
		country_code: '+244',
		country_iso_code: 'AO',
	},
	AR: {
		label: 'Argentina (+54)',
		name: 'Argentina',
		country_code: '+54',
		country_iso_code: 'AR',
	},
	AS: {
		label: 'American Samoa (+1)',
		name: 'American Samoa',
		country_code: '+1',
		country_iso_code: 'AS',
	},
	AT: {
		label: 'Austria (+43)',
		name: 'Austria',
		country_code: '+43',
		country_iso_code: 'AT',
	},
	AU: {
		label: 'Australia (+61)',
		name: 'Australia',
		country_code: '+61',
		country_iso_code: 'AU',
	},
	AW: {
		label: 'Aruba (+297)',
		name: 'Aruba',
		country_code: '+297',
		country_iso_code: 'AW',
	},
	AX: {
		label: 'Åland Islands (+358)',
		name: 'Åland Islands',
		country_code: '+358',
		country_iso_code: 'AX',
	},
	AZ: {
		label: 'Azerbaijan (+994)',
		name: 'Azerbaijan',
		country_code: '+994',
		country_iso_code: 'AZ',
	},
	BA: {
		label: 'Bosnia and Herzegovina (+387)',
		name: 'Bosnia and Herzegovina',
		country_code: '+387',
		country_iso_code: 'BA',
	},
	BB: {
		label: 'Barbados (+1)',
		name: 'Barbados',
		country_code: '+1',
		country_iso_code: 'BB',
	},
	BD: {
		label: 'Bangladesh (+880)',
		name: 'Bangladesh',
		country_code: '+880',
		country_iso_code: 'BD',
	},
	BE: {
		label: 'Belgium (+32)',
		name: 'Belgium',
		country_code: '+32',
		country_iso_code: 'BE',
	},
	BF: {
		label: 'Burkina Faso (+226)',
		name: 'Burkina Faso',
		country_code: '+226',
		country_iso_code: 'BF',
	},
	BG: {
		label: 'Bulgaria (+359)',
		name: 'Bulgaria',
		country_code: '+359',
		country_iso_code: 'BG',
	},
	BH: {
		label: 'Bahrain (+973)',
		name: 'Bahrain',
		country_code: '+973',
		country_iso_code: 'BH',
	},
	BI: {
		label: 'Burundi (+257)',
		name: 'Burundi',
		country_code: '+257',
		country_iso_code: 'BI',
	},
	BJ: {
		label: 'Benin (+229)',
		name: 'Benin',
		country_code: '+229',
		country_iso_code: 'BJ',
	},
	BL: {
		label: 'Saint Barthélemy (+590)',
		name: 'Saint Barthélemy',
		country_code: '+590',
		country_iso_code: 'BL',
	},
	BM: {
		label: 'Bermuda (+1)',
		name: 'Bermuda',
		country_code: '+1',
		country_iso_code: 'BM',
	},
	BN: {
		label: 'Brunei Darussalam (+673)',
		name: 'Brunei Darussalam',
		country_code: '+673',
		country_iso_code: 'BN',
	},
	BO: {
		label: 'Bolivia (+591)',
		name: 'Bolivia',
		country_code: '+591',
		country_iso_code: 'BO',
	},
	BQ: {
		label: 'Bonaire, Sint Eustatius and Saba (+599)',
		name: 'Bonaire, Sint Eustatius and Saba',
		country_code: '+599',
		country_iso_code: 'BQ',
	},
	BR: {
		label: 'Brazil (+55)',
		name: 'Brazil',
		country_code: '+55',
		country_iso_code: 'BR',
	},
	BS: {
		label: 'Bahamas (+1)',
		name: 'Bahamas',
		country_code: '+1',
		country_iso_code: 'BS',
	},
	BT: {
		label: 'Bhutan (+975)',
		name: 'Bhutan',
		country_code: '+975',
		country_iso_code: 'BT',
	},
	BW: {
		label: 'Botswana (+267)',
		name: 'Botswana',
		country_code: '+267',
		country_iso_code: 'BW',
	},
	BY: {
		label: 'Belarus (+375)',
		name: 'Belarus',
		country_code: '+375',
		country_iso_code: 'BY',
	},
	BZ: {
		label: 'Belize (+501)',
		name: 'Belize',
		country_code: '+501',
		country_iso_code: 'BZ',
	},
	CA: {
		label: 'Canada (+1)',
		name: 'Canada',
		country_code: '+1',
		country_iso_code: 'CA',
	},
	CC: {
		label: 'Cocos (Keeling) Islands (+61)',
		name: 'Cocos (Keeling) Islands',
		country_code: '+61',
		country_iso_code: 'CC',
	},
	CD: {
		label: 'Congo, Democratic Republic of the (+243)',
		name: 'Congo, Democratic Republic of the',
		country_code: '+243',
		country_iso_code: 'CD',
	},
	CF: {
		label: 'Central African Republic (+236)',
		name: 'Central African Republic',
		country_code: '+236',
		country_iso_code: 'CF',
	},
	CG: {
		label: 'Congo (+242)',
		name: 'Congo',
		country_code: '+242',
		country_iso_code: 'CG',
	},
	CH: {
		label: 'Switzerland (+41)',
		name: 'Switzerland',
		country_code: '+41',
		country_iso_code: 'CH',
	},
	CI: {
		label: "Cote d'Ivoire (+225)",
		name: "Cote d'Ivoire",
		country_code: '+225',
		country_iso_code: 'CI',
	},
	CK: {
		label: 'Cook Islands (+682)',
		name: 'Cook Islands',
		country_code: '+682',
		country_iso_code: 'CK',
	},
	CL: {
		label: 'Chile (+56)',
		name: 'Chile',
		country_code: '+56',
		country_iso_code: 'CL',
	},
	CM: {
		label: 'Cameroon (+237)',
		name: 'Cameroon',
		country_code: '+237',
		country_iso_code: 'CM',
	},
	CN: {
		label: 'China (+86)',
		name: 'China',
		country_code: '+86',
		country_iso_code: 'CN',
	},
	CO: {
		label: 'Colombia (+57)',
		name: 'Colombia',
		country_code: '+57',
		country_iso_code: 'CO',
	},
	CR: {
		label: 'Costa Rica (+506)',
		name: 'Costa Rica',
		country_code: '+506',
		country_iso_code: 'CR',
	},
	CU: {
		label: 'Cuba (+53)',
		name: 'Cuba',
		country_code: '+53',
		country_iso_code: 'CU',
	},
	CV: {
		label: 'Cape Verde (+238)',
		name: 'Cape Verde',
		country_code: '+238',
		country_iso_code: 'CV',
	},
	CW: {
		label: 'Curaçao (+599)',
		name: 'Curaçao',
		country_code: '+599',
		country_iso_code: 'CW',
	},
	CX: {
		label: 'Christmas Island (+61)',
		name: 'Christmas Island',
		country_code: '+61',
		country_iso_code: 'CX',
	},
	CY: {
		label: 'Cyprus (+357)',
		name: 'Cyprus',
		country_code: '+357',
		country_iso_code: 'CY',
	},
	CZ: {
		label: 'Czech Republic (+420)',
		name: 'Czech Republic',
		country_code: '+420',
		country_iso_code: 'CZ',
	},
	DE: {
		label: 'Germany (+49)',
		name: 'Germany',
		country_code: '+49',
		country_iso_code: 'DE',
	},
	DJ: {
		label: 'Djibouti (+253)',
		name: 'Djibouti',
		country_code: '+253',
		country_iso_code: 'DJ',
	},
	DK: {
		label: 'Denmark (+45)',
		name: 'Denmark',
		country_code: '+45',
		country_iso_code: 'DK',
	},
	DM: {
		label: 'Dominica (+1)',
		name: 'Dominica',
		country_code: '+1',
		country_iso_code: 'DM',
	},
	DO: {
		label: 'Dominican Republic (+1)',
		name: 'Dominican Republic',
		country_code: '+1',
		country_iso_code: 'DO',
	},
	DZ: {
		label: 'Algeria (+213)',
		name: 'Algeria',
		country_code: '+213',
		country_iso_code: 'DZ',
	},
	EC: {
		label: 'Ecuador (+593)',
		name: 'Ecuador',
		country_code: '+593',
		country_iso_code: 'EC',
	},
	EE: {
		label: 'Estonia (+372)',
		name: 'Estonia',
		country_code: '+372',
		country_iso_code: 'EE',
	},
	EG: {
		label: 'Egypt (+20)',
		name: 'Egypt',
		country_code: '+20',
		country_iso_code: 'EG',
	},
	EH: {
		label: 'Western Sahara (+212)',
		name: 'Western Sahara',
		country_code: '+212',
		country_iso_code: 'EH',
	},
	ER: {
		label: 'Eritrea (+291)',
		name: 'Eritrea',
		country_code: '+291',
		country_iso_code: 'ER',
	},
	ES: {
		label: 'Spain (+34)',
		name: 'Spain',
		country_code: '+34',
		country_iso_code: 'ES',
	},
	ET: {
		label: 'Ethiopia (+251)',
		name: 'Ethiopia',
		country_code: '+251',
		country_iso_code: 'ET',
	},
	FI: {
		label: 'Finland (+358)',
		name: 'Finland',
		country_code: '+358',
		country_iso_code: 'FI',
	},
	FJ: {
		label: 'Fiji (+679)',
		name: 'Fiji',
		country_code: '+679',
		country_iso_code: 'FJ',
	},
	FK: {
		label: 'Falkland Islands (+500)',
		name: 'Falkland Islands',
		country_code: '+500',
		country_iso_code: 'FK',
	},
	FM: {
		label: 'Federated States of Micronesia (+691)',
		name: 'Federated States of Micronesia',
		country_code: '+691',
		country_iso_code: 'FM',
	},
	FO: {
		label: 'Faroe Islands (+298)',
		name: 'Faroe Islands',
		country_code: '+298',
		country_iso_code: 'FO',
	},
	FR: {
		label: 'France (+33)',
		name: 'France',
		country_code: '+33',
		country_iso_code: 'FR',
	},
	GA: {
		label: 'Gabon (+241)',
		name: 'Gabon',
		country_code: '+241',
		country_iso_code: 'GA',
	},
	GB: {
		label: 'United Kingdom (+44)',
		name: 'United Kingdom',
		country_code: '+44',
		country_iso_code: 'GB',
	},
	GD: {
		label: 'Grenada (+1)',
		name: 'Grenada',
		country_code: '+1',
		country_iso_code: 'GD',
	},
	GE: {
		label: 'Georgia (+995)',
		name: 'Georgia',
		country_code: '+995',
		country_iso_code: 'GE',
	},
	GF: {
		label: 'French Guiana (+594)',
		name: 'French Guiana',
		country_code: '+594',
		country_iso_code: 'GF',
	},
	GG: {
		label: 'Guernsey (+44)',
		name: 'Guernsey',
		country_code: '+44',
		country_iso_code: 'GG',
	},
	GH: {
		label: 'Ghana (+233)',
		name: 'Ghana',
		country_code: '+233',
		country_iso_code: 'GH',
	},
	GI: {
		label: 'Gibraltar (+350)',
		name: 'Gibraltar',
		country_code: '+350',
		country_iso_code: 'GI',
	},
	GL: {
		label: 'Greenland (+299)',
		name: 'Greenland',
		country_code: '+299',
		country_iso_code: 'GL',
	},
	GM: {
		label: 'Gambia (+220)',
		name: 'Gambia',
		country_code: '+220',
		country_iso_code: 'GM',
	},
	GN: {
		label: 'Guinea (+224)',
		name: 'Guinea',
		country_code: '+224',
		country_iso_code: 'GN',
	},
	GP: {
		label: 'Guadeloupe (+590)',
		name: 'Guadeloupe',
		country_code: '+590',
		country_iso_code: 'GP',
	},
	GQ: {
		label: 'Equatorial Guinea (+240)',
		name: 'Equatorial Guinea',
		country_code: '+240',
		country_iso_code: 'GQ',
	},
	GR: {
		label: 'Greece (+30)',
		name: 'Greece',
		country_code: '+30',
		country_iso_code: 'GR',
	},
	GT: {
		label: 'Guatemala (+502)',
		name: 'Guatemala',
		country_code: '+502',
		country_iso_code: 'GT',
	},
	GU: {
		label: 'Guam (+1)',
		name: 'Guam',
		country_code: '+1',
		country_iso_code: 'GU',
	},
	GW: {
		label: 'Guinea-Bissau (+245)',
		name: 'Guinea-Bissau',
		country_code: '+245',
		country_iso_code: 'GW',
	},
	GY: {
		label: 'Guyana (+592)',
		name: 'Guyana',
		country_code: '+592',
		country_iso_code: 'GY',
	},
	HK: {
		label: 'Hong Kong (+852)',
		name: 'Hong Kong',
		country_code: '+852',
		country_iso_code: 'HK',
	},
	HN: {
		label: 'Honduras (+504)',
		name: 'Honduras',
		country_code: '+504',
		country_iso_code: 'HN',
	},
	HR: {
		label: 'Croatia (+385)',
		name: 'Croatia',
		country_code: '+385',
		country_iso_code: 'HR',
	},
	HT: {
		label: 'Haiti (+509)',
		name: 'Haiti',
		country_code: '+509',
		country_iso_code: 'HT',
	},
	HU: {
		label: 'Hungary (+36)',
		name: 'Hungary',
		country_code: '+36',
		country_iso_code: 'HU',
	},
	ID: {
		label: 'Indonesia (+62)',
		name: 'Indonesia',
		country_code: '+62',
		country_iso_code: 'ID',
	},
	IE: {
		label: 'Ireland (+353)',
		name: 'Ireland',
		country_code: '+353',
		country_iso_code: 'IE',
	},
	IL: {
		label: 'Israel (+972)',
		name: 'Israel',
		country_code: '+972',
		country_iso_code: 'IL',
	},
	IM: {
		label: 'Isle of Man (+44)',
		name: 'Isle of Man',
		country_code: '+44',
		country_iso_code: 'IM',
	},
	IN: {
		label: 'India (+91)',
		name: 'India',
		country_code: '+91',
		country_iso_code: 'IN',
	},
	IO: {
		label: 'British Indian Ocean Territory (+246)',
		name: 'British Indian Ocean Territory',
		country_code: '+246',
		country_iso_code: 'IO',
	},
	IQ: {
		label: 'Iraq (+964)',
		name: 'Iraq',
		country_code: '+964',
		country_iso_code: 'IQ',
	},
	IR: {
		label: 'Iran (+98)',
		name: 'Iran',
		country_code: '+98',
		country_iso_code: 'IR',
	},
	IS: {
		label: 'Iceland (+354)',
		name: 'Iceland',
		country_code: '+354',
		country_iso_code: 'IS',
	},
	IT: {
		label: 'Italy (+39)',
		name: 'Italy',
		country_code: '+39',
		country_iso_code: 'IT',
	},
	JE: {
		label: 'Jersey (+44)',
		name: 'Jersey',
		country_code: '+44',
		country_iso_code: 'JE',
	},
	JM: {
		label: 'Jamaica (+1)',
		name: 'Jamaica',
		country_code: '+1',
		country_iso_code: 'JM',
	},
	JO: {
		label: 'Jordan (+962)',
		name: 'Jordan',
		country_code: '+962',
		country_iso_code: 'JO',
	},
	JP: {
		label: 'Japan (+81)',
		name: 'Japan',
		country_code: '+81',
		country_iso_code: 'JP',
	},
	KE: {
		label: 'Kenya (+254)',
		name: 'Kenya',
		country_code: '+254',
		country_iso_code: 'KE',
	},
	KG: {
		label: 'Kyrgyzstan (+996)',
		name: 'Kyrgyzstan',
		country_code: '+996',
		country_iso_code: 'KG',
	},
	KH: {
		label: 'Cambodia (+855)',
		name: 'Cambodia',
		country_code: '+855',
		country_iso_code: 'KH',
	},
	KI: {
		label: 'Kiribati (+686)',
		name: 'Kiribati',
		country_code: '+686',
		country_iso_code: 'KI',
	},
	KM: {
		label: 'Comoros (+269)',
		name: 'Comoros',
		country_code: '+269',
		country_iso_code: 'KM',
	},
	KN: {
		label: 'Saint Kitts and Nevis (+1)',
		name: 'Saint Kitts and Nevis',
		country_code: '+1',
		country_iso_code: 'KN',
	},
	KP: {
		label: 'North Korea (+850)',
		name: 'North Korea',
		country_code: '+850',
		country_iso_code: 'KP',
	},
	KR: {
		label: 'South Korea (+82)',
		name: 'South Korea',
		country_code: '+82',
		country_iso_code: 'KR',
	},
	KW: {
		label: 'Kuwait (+965)',
		name: 'Kuwait',
		country_code: '+965',
		country_iso_code: 'KW',
	},
	KY: {
		label: 'Cayman Islands (+1)',
		name: 'Cayman Islands',
		country_code: '+1',
		country_iso_code: 'KY',
	},
	KZ: {
		label: 'Kazakhstan (+7)',
		name: 'Kazakhstan',
		country_code: '+7',
		country_iso_code: 'KZ',
	},
	LA: {
		label: 'Laos (+856)',
		name: 'Laos',
		country_code: '+856',
		country_iso_code: 'LA',
	},
	LB: {
		label: 'Lebanon (+961)',
		name: 'Lebanon',
		country_code: '+961',
		country_iso_code: 'LB',
	},
	LC: {
		label: 'Saint Lucia (+1)',
		name: 'Saint Lucia',
		country_code: '+1',
		country_iso_code: 'LC',
	},
	LI: {
		label: 'Liechtenstein (+423)',
		name: 'Liechtenstein',
		country_code: '+423',
		country_iso_code: 'LI',
	},
	LK: {
		label: 'Sri Lanka (+94)',
		name: 'Sri Lanka',
		country_code: '+94',
		country_iso_code: 'LK',
	},
	LR: {
		label: 'Liberia (+231)',
		name: 'Liberia',
		country_code: '+231',
		country_iso_code: 'LR',
	},
	LS: {
		label: 'Lesotho (+266)',
		name: 'Lesotho',
		country_code: '+266',
		country_iso_code: 'LS',
	},
	LT: {
		label: 'Lithuania (+370)',
		name: 'Lithuania',
		country_code: '+370',
		country_iso_code: 'LT',
	},
	LU: {
		label: 'Luxembourg (+352)',
		name: 'Luxembourg',
		country_code: '+352',
		country_iso_code: 'LU',
	},
	LV: {
		label: 'Latvia (+371)',
		name: 'Latvia',
		country_code: '+371',
		country_iso_code: 'LV',
	},
	LY: {
		label: 'Libya (+218)',
		name: 'Libya',
		country_code: '+218',
		country_iso_code: 'LY',
	},
	MA: {
		label: 'Morocco (+212)',
		name: 'Morocco',
		country_code: '+212',
		country_iso_code: 'MA',
	},
	MC: {
		label: 'Monaco (+377)',
		name: 'Monaco',
		country_code: '+377',
		country_iso_code: 'MC',
	},
	MD: {
		label: 'Moldova (+373)',
		name: 'Moldova',
		country_code: '+373',
		country_iso_code: 'MD',
	},
	ME: {
		label: 'Montenegro (+382)',
		name: 'Montenegro',
		country_code: '+382',
		country_iso_code: 'ME',
	},
	MF: {
		label: 'Saint Martin (French Part) (+590)',
		name: 'Saint Martin (French Part)',
		country_code: '+590',
		country_iso_code: 'MF',
	},
	MG: {
		label: 'Madagascar (+261)',
		name: 'Madagascar',
		country_code: '+261',
		country_iso_code: 'MG',
	},
	MH: {
		label: 'Marshall Islands (+692)',
		name: 'Marshall Islands',
		country_code: '+692',
		country_iso_code: 'MH',
	},
	MK: {
		label: 'North Macedonia (+389)',
		name: 'North Macedonia',
		country_code: '+389',
		country_iso_code: 'MK',
	},
	ML: {
		label: 'Mali (+223)',
		name: 'Mali',
		country_code: '+223',
		country_iso_code: 'ML',
	},
	MM: {
		label: 'Myanmar (+95)',
		name: 'Myanmar',
		country_code: '+95',
		country_iso_code: 'MM',
	},
	MN: {
		label: 'Mongolia (+976)',
		name: 'Mongolia',
		country_code: '+976',
		country_iso_code: 'MN',
	},
	MO: {
		label: 'Macao (+853)',
		name: 'Macao',
		country_code: '+853',
		country_iso_code: 'MO',
	},
	MP: {
		label: 'Northern Mariana Islands (+1)',
		name: 'Northern Mariana Islands',
		country_code: '+1',
		country_iso_code: 'MP',
	},
	MQ: {
		label: 'Martinique (+596)',
		name: 'Martinique',
		country_code: '+596',
		country_iso_code: 'MQ',
	},
	MR: {
		label: 'Mauritania (+222)',
		name: 'Mauritania',
		country_code: '+222',
		country_iso_code: 'MR',
	},
	MS: {
		label: 'Montserrat (+1)',
		name: 'Montserrat',
		country_code: '+1',
		country_iso_code: 'MS',
	},
	MT: {
		label: 'Malta (+356)',
		name: 'Malta',
		country_code: '+356',
		country_iso_code: 'MT',
	},
	MU: {
		label: 'Mauritius (+230)',
		name: 'Mauritius',
		country_code: '+230',
		country_iso_code: 'MU',
	},
	MV: {
		label: 'Maldives (+960)',
		name: 'Maldives',
		country_code: '+960',
		country_iso_code: 'MV',
	},
	MW: {
		label: 'Malawi (+265)',
		name: 'Malawi',
		country_code: '+265',
		country_iso_code: 'MW',
	},
	MX: {
		label: 'Mexico (+52)',
		name: 'Mexico',
		country_code: '+52',
		country_iso_code: 'MX',
	},
	MY: {
		label: 'Malaysia (+60)',
		name: 'Malaysia',
		country_code: '+60',
		country_iso_code: 'MY',
	},
	MZ: {
		label: 'Mozambique (+258)',
		name: 'Mozambique',
		country_code: '+258',
		country_iso_code: 'MZ',
	},
	NA: {
		label: 'Namibia (+264)',
		name: 'Namibia',
		country_code: '+264',
		country_iso_code: 'NA',
	},
	NC: {
		label: 'New Caledonia (+687)',
		name: 'New Caledonia',
		country_code: '+687',
		country_iso_code: 'NC',
	},
	NE: {
		label: 'Niger (+227)',
		name: 'Niger',
		country_code: '+227',
		country_iso_code: 'NE',
	},
	NF: {
		label: 'Norfolk Island (+672)',
		name: 'Norfolk Island',
		country_code: '+672',
		country_iso_code: 'NF',
	},
	NG: {
		label: 'Nigeria (+234)',
		name: 'Nigeria',
		country_code: '+234',
		country_iso_code: 'NG',
	},
	NI: {
		label: 'Nicaragua (+505)',
		name: 'Nicaragua',
		country_code: '+505',
		country_iso_code: 'NI',
	},
	NL: {
		label: 'Netherlands (+31)',
		name: 'Netherlands',
		country_code: '+31',
		country_iso_code: 'NL',
	},
	NO: {
		label: 'Norway (+47)',
		name: 'Norway',
		country_code: '+47',
		country_iso_code: 'NO',
	},
	NP: {
		label: 'Nepal (+977)',
		name: 'Nepal',
		country_code: '+977',
		country_iso_code: 'NP',
	},
	NR: {
		label: 'Nauru (+674)',
		name: 'Nauru',
		country_code: '+674',
		country_iso_code: 'NR',
	},
	NU: {
		label: 'Niue (+683)',
		name: 'Niue',
		country_code: '+683',
		country_iso_code: 'NU',
	},
	NZ: {
		label: 'New Zealand (+64)',
		name: 'New Zealand',
		country_code: '+64',
		country_iso_code: 'NZ',
	},
	OM: {
		label: 'Oman (+968)',
		name: 'Oman',
		country_code: '+968',
		country_iso_code: 'OM',
	},
	PA: {
		label: 'Panama (+507)',
		name: 'Panama',
		country_code: '+507',
		country_iso_code: 'PA',
	},
	PE: {
		label: 'Peru (+51)',
		name: 'Peru',
		country_code: '+51',
		country_iso_code: 'PE',
	},
	PF: {
		label: 'French Polynesia (+689)',
		name: 'French Polynesia',
		country_code: '+689',
		country_iso_code: 'PF',
	},
	PG: {
		label: 'Papua New Guinea (+675)',
		name: 'Papua New Guinea',
		country_code: '+675',
		country_iso_code: 'PG',
	},
	PH: {
		label: 'Philippines (+63)',
		name: 'Philippines',
		country_code: '+63',
		country_iso_code: 'PH',
	},
	PK: {
		label: 'Pakistan (+92)',
		name: 'Pakistan',
		country_code: '+92',
		country_iso_code: 'PK',
	},
	PL: {
		label: 'Poland (+48)',
		name: 'Poland',
		country_code: '+48',
		country_iso_code: 'PL',
	},
	PM: {
		label: 'Saint Pierre and Miquelon (+508)',
		name: 'Saint Pierre and Miquelon',
		country_code: '+508',
		country_iso_code: 'PM',
	},
	PR: {
		label: 'Puerto Rico (+1)',
		name: 'Puerto Rico',
		country_code: '+1',
		country_iso_code: 'PR',
	},
	PS: {
		label: 'Palestine (+970)',
		name: 'Palestine',
		country_code: '+970',
		country_iso_code: 'PS',
	},
	PT: {
		label: 'Portugal (+351)',
		name: 'Portugal',
		country_code: '+351',
		country_iso_code: 'PT',
	},
	PW: {
		label: 'Palau (+680)',
		name: 'Palau',
		country_code: '+680',
		country_iso_code: 'PW',
	},
	PY: {
		label: 'Paraguay (+595)',
		name: 'Paraguay',
		country_code: '+595',
		country_iso_code: 'PY',
	},
	QA: {
		label: 'Qatar (+974)',
		name: 'Qatar',
		country_code: '+974',
		country_iso_code: 'QA',
	},
	RE: {
		label: 'Reunion (+262)',
		name: 'Reunion',
		country_code: '+262',
		country_iso_code: 'RE',
	},
	RO: {
		label: 'Romania (+40)',
		name: 'Romania',
		country_code: '+40',
		country_iso_code: 'RO',
	},
	RS: {
		label: 'Serbia (+381)',
		name: 'Serbia',
		country_code: '+381',
		country_iso_code: 'RS',
	},
	RU: {
		label: 'Russia (+7)',
		name: 'Russia',
		country_code: '+7',
		country_iso_code: 'RU',
	},
	RW: {
		label: 'Rwanda (+250)',
		name: 'Rwanda',
		country_code: '+250',
		country_iso_code: 'RW',
	},
	SA: {
		label: 'Saudi Arabia (+966)',
		name: 'Saudi Arabia',
		country_code: '+966',
		country_iso_code: 'SA',
	},
	SB: {
		label: 'Solomon Islands (+677)',
		name: 'Solomon Islands',
		country_code: '+677',
		country_iso_code: 'SB',
	},
	SC: {
		label: 'Seychelles (+248)',
		name: 'Seychelles',
		country_code: '+248',
		country_iso_code: 'SC',
	},
	SD: {
		label: 'Sudan (+249)',
		name: 'Sudan',
		country_code: '+249',
		country_iso_code: 'SD',
	},
	SE: {
		label: 'Sweden (+46)',
		name: 'Sweden',
		country_code: '+46',
		country_iso_code: 'SE',
	},
	SG: {
		label: 'Singapore (+65)',
		name: 'Singapore',
		country_code: '+65',
		country_iso_code: 'SG',
	},
	SH: {
		label: 'Saint Helena (+290)',
		name: 'Saint Helena',
		country_code: '+290',
		country_iso_code: 'SH',
	},
	SI: {
		label: 'Slovenia (+386)',
		name: 'Slovenia',
		country_code: '+386',
		country_iso_code: 'SI',
	},
	SJ: {
		label: 'Svalbard and Jan Mayen (+47)',
		name: 'Svalbard and Jan Mayen',
		country_code: '+47',
		country_iso_code: 'SJ',
	},
	SK: {
		label: 'Slovakia (+421)',
		name: 'Slovakia',
		country_code: '+421',
		country_iso_code: 'SK',
	},
	SL: {
		label: 'Sierra Leone (+232)',
		name: 'Sierra Leone',
		country_code: '+232',
		country_iso_code: 'SL',
	},
	SM: {
		label: 'San Marino (+378)',
		name: 'San Marino',
		country_code: '+378',
		country_iso_code: 'SM',
	},
	SN: {
		label: 'Senegal (+221)',
		name: 'Senegal',
		country_code: '+221',
		country_iso_code: 'SN',
	},
	SO: {
		label: 'Somalia (+252)',
		name: 'Somalia',
		country_code: '+252',
		country_iso_code: 'SO',
	},
	SR: {
		label: 'Suriname (+597)',
		name: 'Suriname',
		country_code: '+597',
		country_iso_code: 'SR',
	},
	SS: {
		label: 'South Sudan (+211)',
		name: 'South Sudan',
		country_code: '+211',
		country_iso_code: 'SS',
	},
	ST: {
		label: 'Sao Tome and Principe (+239)',
		name: 'Sao Tome and Principe',
		country_code: '+239',
		country_iso_code: 'ST',
	},
	SV: {
		label: 'El Salvador (+503)',
		name: 'El Salvador',
		country_code: '+503',
		country_iso_code: 'SV',
	},
	SX: {
		label: 'Sint Maarten (+1)',
		name: 'Sint Maarten',
		country_code: '+1',
		country_iso_code: 'SX',
	},
	SY: {
		label: 'Syria (+963)',
		name: 'Syria',
		country_code: '+963',
		country_iso_code: 'SY',
	},
	SZ: {
		label: 'Swaziland (+268)',
		name: 'Swaziland',
		country_code: '+268',
		country_iso_code: 'SZ',
	},
	TA: {
		label: 'Tristan da Cunha (+290)',
		name: 'Tristan da Cunha',
		country_code: '+290',
		country_iso_code: 'TA',
	},
	TC: {
		label: 'Turks and Caicos Islands (+1)',
		name: 'Turks and Caicos Islands',
		country_code: '+1',
		country_iso_code: 'TC',
	},
	TD: {
		label: 'Chad (+235)',
		name: 'Chad',
		country_code: '+235',
		country_iso_code: 'TD',
	},
	TG: {
		label: 'Togo (+228)',
		name: 'Togo',
		country_code: '+228',
		country_iso_code: 'TG',
	},
	TH: {
		label: 'Thailand (+66)',
		name: 'Thailand',
		country_code: '+66',
		country_iso_code: 'TH',
	},
	TJ: {
		label: 'Tajikistan (+992)',
		name: 'Tajikistan',
		country_code: '+992',
		country_iso_code: 'TJ',
	},
	TK: {
		label: 'Tokelau (+690)',
		name: 'Tokelau',
		country_code: '+690',
		country_iso_code: 'TK',
	},
	TL: {
		label: 'Timor-Leste (+670)',
		name: 'Timor-Leste',
		country_code: '+670',
		country_iso_code: 'TL',
	},
	TM: {
		label: 'Turkmenistan (+993)',
		name: 'Turkmenistan',
		country_code: '+993',
		country_iso_code: 'TM',
	},
	TN: {
		label: 'Tunisia (+216)',
		name: 'Tunisia',
		country_code: '+216',
		country_iso_code: 'TN',
	},
	TO: {
		label: 'Tonga (+676)',
		name: 'Tonga',
		country_code: '+676',
		country_iso_code: 'TO',
	},
	TR: {
		label: 'Turkey (+90)',
		name: 'Turkey',
		country_code: '+90',
		country_iso_code: 'TR',
	},
	TT: {
		label: 'Trinidad and Tobago (+1)',
		name: 'Trinidad and Tobago',
		country_code: '+1',
		country_iso_code: 'TT',
	},
	TV: {
		label: 'Tuvalu (+688)',
		name: 'Tuvalu',
		country_code: '+688',
		country_iso_code: 'TV',
	},
	TW: {
		label: 'Taiwan (+886)',
		name: 'Taiwan',
		country_code: '+886',
		country_iso_code: 'TW',
	},
	TZ: {
		label: 'Tanzania (+255)',
		name: 'Tanzania',
		country_code: '+255',
		country_iso_code: 'TZ',
	},
	UA: {
		label: 'Ukraine (+380)',
		name: 'Ukraine',
		country_code: '+380',
		country_iso_code: 'UA',
	},
	UG: {
		label: 'Uganda (+256)',
		name: 'Uganda',
		country_code: '+256',
		country_iso_code: 'UG',
	},
	US: {
		label: 'United States (+1)',
		name: 'United States',
		country_code: '+1',
		country_iso_code: 'US',
	},
	UY: {
		label: 'Uruguay (+598)',
		name: 'Uruguay',
		country_code: '+598',
		country_iso_code: 'UY',
	},
	UZ: {
		label: 'Uzbekistan (+998)',
		name: 'Uzbekistan',
		country_code: '+998',
		country_iso_code: 'UZ',
	},
	VA: {
		label: 'Holy See (Vatican City State) (+39)',
		name: 'Holy See (Vatican City State)',
		country_code: '+39',
		country_iso_code: 'VA',
	},
	VC: {
		label: 'Saint Vincent and the Grenadines (+1)',
		name: 'Saint Vincent and the Grenadines',
		country_code: '+1',
		country_iso_code: 'VC',
	},
	VE: {
		label: 'Venezuela (+58)',
		name: 'Venezuela',
		country_code: '+58',
		country_iso_code: 'VE',
	},
	VG: {
		label: 'Virgin Islands, British (+1)',
		name: 'Virgin Islands, British',
		country_code: '+1',
		country_iso_code: 'VG',
	},
	VI: {
		label: 'Virgin Islands, U.S. (+1)',
		name: 'Virgin Islands, U.S.',
		country_code: '+1',
		country_iso_code: 'VI',
	},
	VN: {
		label: 'Vietnam (+84)',
		name: 'Vietnam',
		country_code: '+84',
		country_iso_code: 'VN',
	},
	VU: {
		label: 'Vanuatu (+678)',
		name: 'Vanuatu',
		country_code: '+678',
		country_iso_code: 'VU',
	},
	WF: {
		label: 'Wallis and Futuna (+681)',
		name: 'Wallis and Futuna',
		country_code: '+681',
		country_iso_code: 'WF',
	},
	WS: {
		label: 'Samoa (+685)',
		name: 'Samoa',
		country_code: '+685',
		country_iso_code: 'WS',
	},
	XK: {
		label: 'Kosovo (+383)',
		name: 'Kosovo',
		country_code: '+383',
		country_iso_code: 'XK',
	},
	YE: {
		label: 'Yemen (+967)',
		name: 'Yemen',
		country_code: '+967',
		country_iso_code: 'YE',
	},
	YT: {
		label: 'Mayotte (+262)',
		name: 'Mayotte',
		country_code: '+262',
		country_iso_code: 'YT',
	},
	ZA: {
		label: 'South Africa (+27)',
		name: 'South Africa',
		country_code: '+27',
		country_iso_code: 'ZA',
	},
	ZM: {
		label: 'Zambia (+260)',
		name: 'Zambia',
		country_code: '+260',
		country_iso_code: 'ZM',
	},
	ZW: {
		label: 'Zimbabwe (+263)',
		name: 'Zimbabwe',
		country_code: '+263',
		country_iso_code: 'ZW',
	},
};
