import { ContentLanguagesJson } from './content-languages-json';
import ContentLanguagesModel from './content-languages-model';
import { ContentLanguagesRequestJson } from './content-languages-requst-json';
import LanguageCodeModel from './language-code/language-code-model';

export default class ContentLanguagesBuilder {
	private json: ContentLanguagesJson;

	constructor(languageCodes?: ContentLanguagesModel) {
		if (languageCodes && languageCodes instanceof ContentLanguagesModel) {
			this.json = languageCodes.toJSON();
		} else if (languageCodes) {
			this.json = languageCodes;
		} else {
			this.json = {} as ContentLanguagesJson;
		}
	}

	withAll(all: LanguageCodeModel[]): ContentLanguagesBuilder {
		this.json.all = all;

		return this;
	}

	withDefaultLanguageCode(defaultLanguageCode: LanguageCodeModel): ContentLanguagesBuilder {
		this.json.defaultLanguageCode = defaultLanguageCode;

		return this;
	}

	withMultiple(multiple: boolean): ContentLanguagesBuilder {
		this.json.multiple = multiple;

		return this;
	}

	toRequestJson(): ContentLanguagesRequestJson {
		let json = {} as ContentLanguagesRequestJson;
		json.all = this.json.all;
		json.default = this.json.defaultLanguageCode;
		json.multiple = this.json.multiple;
		return json;
	}

	fromResponse(responseItem: any) {
		this.json.all = responseItem.all;
		this.json.defaultLanguageCode = responseItem.default;
		this.json.multiple = responseItem.multiple;

		return this;
	}

	build(): ContentLanguagesModel {
		return ContentLanguagesModel.fromJSON(this.json);
	}
}
