import React, { useContext } from 'react';
import * as ReactSortableHOC from 'react-sortable-hoc';
import { SortableHandle } from 'react-sortable-hoc';
import { RowTitleLink } from '../../Partials/BaseComponents/ListingComponent/subcomponents/Rows/subcomponents/RowTitleLink';
import { RowListContentEditButton } from '../../Partials/BaseComponents/ListingComponent/subcomponents/Rows/subcomponents/RowListContentEditButton';
import { RowEditButton } from '../../Partials/BaseComponents/ListingComponent/subcomponents/Rows/subcomponents/RowEditButton';
import { RowDeleteButton } from '../../Partials/BaseComponents/ListingComponent/subcomponents/Rows/subcomponents/RowDeleteButton';
import { ConditionalRenderContext } from '../../Partials/BaseComponents/ConditionalRenderWraper/ConditionalRenderWrapper';
import List from '../../../models/list/List';
import { getListTypeEditsUrl } from './helpers/list.helper';

type ListRowProps = {
	content: List;
	onDelete: (id: string, title: string) => any;
	analytics: any;
	history: any;
	t: any;
	itemIndex: number;
};

type RowsProps = {
	content: any;
	onDelete: (id: string, title: string) => any;
	analytics: any;
	history: any;
	t: any;
	itemIndex?: number;
};

const DragHandle = SortableHandle(() => {
	return (
		<span className='cursor-draggable px-2'>
			<i className='fa fa-bars'></i>
		</span>
	);
});

export const ListRow: React.FunctionComponent<ListRowProps> = ({ content, history, analytics, t, onDelete, itemIndex }) => {
	const context = useContext(ConditionalRenderContext);
	const { editUrl, editContentUrl } = getListTypeEditsUrl(content.id);
	const classNameAddon = content.status === 'active' ? 'fa-check-circle text-success' : 'fa-times-circle text-danger';

	return (
		<tr className='w-100 p-0 d-flex bg-white sortable-list-table-row'>
			<td className='w-100 p-0'>
				<div className={`py-2 d-flex border-bottom align-items-center w-100 ${itemIndex % 2 === 1 ? '' : 'bg-light'}`}>
					<div className='align-items-center d-flex w-80 px-2'>
						<DragHandle />
						<RowTitleLink
							contentTitle={content.title}
							editContentPath={editUrl}
							canEditContent={context.hasWritePermission}
							analytics={analytics}
							contentId={`list-id-${content.id}`}
						/>
					</div>
					<div className='w-50 d-flex justify-content-end px-2'>
						<i className={`fa-lg fa mx-2 ${classNameAddon}`}></i>
					</div>
					<div className='w-50 d-flex justify-content-end px-2'>
						<div className='d-flex justify-content-end flex-wrap'>
							<RowListContentEditButton
								contentId={content.id}
								display={context.hasWritePermission}
								t={t}
								editContentPath={editContentUrl}
								history={history}
							/>
							<RowEditButton
								contentId={content.id}
								display={context.hasWritePermission}
								editContentPath={editUrl}
								t={t}
								analytics={analytics}
								history={history}
							/>
							<RowDeleteButton
								analytics={analytics}
								display={context.hasDeletePermission}
								t={t}
								onDelete={onDelete}
								contentTitle={content.title}
								contentId={content.id}
							/>
						</div>
					</div>
				</div>
			</td>
		</tr>
	);
};

const SortableRow = ReactSortableHOC.SortableElement(ListRow);

export const RowsList: React.FunctionComponent<RowsProps> = ({ content, onDelete, analytics, history, t }) => {
	return (
		<div>
			{content.map((list: List, index: number) => {
				return (
					<SortableRow
						key={list.id}
						content={list}
						index={index}
						itemIndex={index}
						onDelete={onDelete}
						t={t}
						analytics={analytics}
						history={history}
					/>
				);
			})}
		</div>
	);
};
