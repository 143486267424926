import { JSONContent } from '@tiptap/core';
import i18next from 'i18next';
import { toast } from 'react-toastify';
import { SportTypes } from '../../../../constants/sport.types';
import Global from '../../../Partials/Blocky/helpers/global.helpers';

enum BlockType {
	HTML_BLOCK = 'html_block',
	SMP_CONTENT_BLOCK = 'smp_content_block',
}

export interface HtmlBlockData {
	type: string;
	content: string;
	object: Record<string, any> | null;
}

export interface ContentBlockData {
	id: string; // Unique identifier of the content, e.g article.id
	object?: Record<string, any>; // Contains a teaser of the object from Content API. Usually not the full data model.
	custom: Record<string, any>; // Contains any custom settings which can override the properties in the object or any other data.
	type: string;
}

export interface WidgetBlockData {
	sport: SportTypes;
	config: {
		options: Record<string, any>;
		widgetId: string;
	};
	content: string;
	type: string;
}

export interface JSONData<T> {
	id: string;
	data: T;
	type: BlockType | string;
}

const BASIC_TYPE_MAP: Record<string, string> = {
	paragraph: 'paragraph',
	heading: 'heading',
	table: 'table',
	blockquote: 'blockquote',
	codeBlock: 'code',
	orderedList: 'ordered_list',
	bulletList: 'unordered_list',
};

const getRelevantAttributes = (object: JSONContent['attrs']): HtmlBlockData['object'] => {
	if (object) {
		const objectToMutate = { ...object };

		delete objectToMutate.level;
		return objectToMutate;
	}

	return null;
};

const constructHTMLBlockData = (htmlElement: ChildNode, elementFromTiptapJson: JSONContent): JSONData<HtmlBlockData> | null => {
	const output = {
		id: Global.makeId(6),
		data: {
			type: BASIC_TYPE_MAP[elementFromTiptapJson.type!] || elementFromTiptapJson.type!,
			content: (htmlElement instanceof HTMLElement ? htmlElement.outerHTML : htmlElement.textContent) || '',
			object: getRelevantAttributes(elementFromTiptapJson.attrs),
		},
		type: BlockType.HTML_BLOCK,
	};

	if (output.data.type === 'paragraph' && output.data.content === '<p></p>') {
		// Discard empty paragraphs
		return null;
	}

	return output;
};

const constructContentBlockData = (dataBlock: Record<string, any>): JSONData<ContentBlockData | HtmlBlockData> => {
	const dataBlockData = dataBlock.data;
	const data: ContentBlockData = {
		id: dataBlockData.id,
		custom: {},
		type: dataBlock.type,
	};

	switch (dataBlock.type) {
		case 'article':
			data.custom.position = dataBlockData.preview.previewContent.position;
			break;
		case 'gallery':
			data.custom = {
				position: {
					width: dataBlockData.preview.gallery.width,
					alignment: dataBlockData.preview.gallery.alignment,
				},
				main_image: dataBlockData.preview.gallery.mainImage,
			};
			break;
		case 'image':
			data.custom = {
				alt: dataBlockData.alt,
				caption: dataBlockData.caption,
				description: dataBlockData.description,
				width: dataBlockData.preview.imageBlock.width,
				authors: dataBlockData.preview.imageBlock.authors,
				alignment: dataBlockData.preview.imageBlock.alignment,
			};
			break;
		case 'video':
			data.custom = {
				position: dataBlockData.preview.previewContent.position,
				starts_at: dataBlockData.startAt,
			};
			break;
		case 'link':
			return {
				id: dataBlock.id,
				data: {
					type: dataBlock.type,
					content: dataBlockData.preview.linkBlock.content,
					object: {
						link: dataBlockData.preview.linkBlock.link,
						text: dataBlockData.preview.linkBlock.text,
						content: dataBlockData.preview.linkBlock.content,
						link_type: dataBlockData.preview.linkBlock.linkType,
						open_type: dataBlockData.preview.linkBlock.open_type,
					},
				},
				type: BlockType.HTML_BLOCK,
			};
		case 'embed':
			return {
				id: dataBlock.id,
				data: {
					type: dataBlock.type,
					content: dataBlockData.content,
					object: {
						type: dataBlockData.preview.embedBlock.type,
						content: dataBlockData.content,
						validation_type: {
							value: dataBlockData.preview.embedBlock.validationType.value,
							default: dataBlockData.preview.embedBlock.validationType.default,
						},
						embed_type: dataBlockData.embed_type,
					},
				},
				type: BlockType.HTML_BLOCK,
			};
		case 'banner':
			// no-op
			break;
	}

	const output: JSONData<ContentBlockData> = {
		id: dataBlock.id,
		data,
		type: BlockType.SMP_CONTENT_BLOCK,
	};

	return output;
};

const constructWidgetBlockData = (dataBlock: Record<string, any>): JSONData<WidgetBlockData> => {
	const output: JSONData<WidgetBlockData> = {
		id: dataBlock.id,
		data: {
			sport: dataBlock.data.sport,
			config: dataBlock.data.config,
			content: dataBlock.data.content,
			type: dataBlock.data.widget_type,
		},
		type: dataBlock.type,
	};

	return output;
};

const constructBlockSpecificData = (
	htmlElement: ChildNode,
	elementFromTiptapJson: JSONContent,
): JSONData<HtmlBlockData | ContentBlockData | WidgetBlockData> | null => {
	if (elementFromTiptapJson.type === 'smp_widget') {
		const dataBlockAttribute = elementFromTiptapJson.attrs!['data-block'];
		let dataBlock: Record<string, any>;

		try {
			dataBlock = JSON.parse(dataBlockAttribute);
		} catch (e) {
			toast.error(i18next.t('parsing_error'));
			console.error(e);
			return null;
		}

		if (dataBlock.type === 'widget_smp' || dataBlock.type === 'widget_smp_V2') {
			return constructWidgetBlockData(dataBlock);
		}

		return constructContentBlockData(dataBlock);
	}

	return constructHTMLBlockData(htmlElement, elementFromTiptapJson);
};

const constructPostJsonData = (html: string, json: JSONContent) => {
	if (!json.content) {
		return [];
	}

	const result: JSONData<HtmlBlockData | ContentBlockData | WidgetBlockData>[] = [];

	const domParser = new DOMParser();

	const parsedDocument = domParser.parseFromString(html, 'text/html');

	const allTopLevelElements = parsedDocument.body.childNodes;

	allTopLevelElements.forEach((htmlElement, index) => {
		const elementFromTiptapJson = json.content![index];
		const blockSpecificData = constructBlockSpecificData(htmlElement, elementFromTiptapJson);

		if (blockSpecificData) {
			result.push(blockSpecificData);
		}
	});

	return result;
};

export default constructPostJsonData;
