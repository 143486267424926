import React from 'react';
import { Col, FormGroup, Row } from 'reactstrap';
import BlockModel from '../../../../../../models/block.model';
import OddsModelV2 from '../../../models/odds-widget.model';
import { WidgetsV2Ids } from '../../../../../../constants/block.types';
import { blockManagementService, blockWrapperService } from '../../../../../../subcomponents/blocky.component';
import { createBlock } from '../../../../../partials/helpers/blocky-sidebar-communication/blocky-sidebar-communication.helper';
import BlockValidation from '../../../../../../helpers/block-validation.helper';
import TeamModel from '../../../../../../../../../models/v2/Team/Entity/TeamModel';
import MarketModel from '../../../models/v2/market.model';
import { SportTypes } from '../../../../../../../../../constants/sport.types';
import { SPORT_SELECT_ATTRIBUTES } from '../../../../helpers/component-attributes.constants';
import TeamSelectComponent from '../../../../../partials/team-select/team-select.component';
import MatchesByTeamIdComponent from '../../../../../partials/matches/matches-by-team-id.component';
import { featuresService } from '../../../../../../../../../App';
import { FeatureTypes } from '../../../../../../../../../services/feature-service/features.enum';
import MarketSelect from '../../../../../../partials/market-select/market-select.component';
import { marketValueTypesOptions } from '../../../../../../partials/market-select/helpers/market-select.helpers';
import MarketValueTypeSelect from '../../../../../../partials/market-select/market-value-type-select.component';
import SportBookmakerSelect from '../../../../../../partials/sport-bookmaker-select/sport-bookmaker-select.component';
import SportOddsBookmakerModel from '../../../../../../../../../models/sport-odds/sport-odds-bookmaker.model';
import { SelectMenuOptionType } from '../../../../../../../../../models/v2/general/select.model';
import { onRefreshTimeChangeFromMainComponent } from '../../../../../partials/refresh-time-input/helper';
import RefreshTimeWrapper from '../../../../../partials/refresh-time-input/refresh-time-wrapper';
import MatchModel from '../../../../../../../../../models/v2/match/entity/match.model';

type Properties = {
	block: BlockModel;
	t: any;
	contentData: any;
	contentLanguage: string;
};

type State = {
	widgetModel: OddsModelV2;
	isValid: boolean;
};

export default class FootballOddsWidgetEdit extends React.Component<Properties, State> {
	constructor(props: Properties) {
		super(props);
		this.state = {
			widgetModel: createBlock(
				WidgetsV2Ids.widgetFootballOdds,
				props.block.data.preview && props.block.data.preview.widgetModel,
				props.contentData,
				props.block,
			),
			isValid: false,
		};
	}

	componentDidMount(): void {
		blockWrapperService.registerOnSaveFunc({ blockId: this.props.block.id, func: this.onSave });
	}

	onSave = (index: number) => {
		const validation = new BlockValidation();
		const data = { widgetModel: this.state.widgetModel };
		const isValid = validation.validate(this.props.block, data) === true;

		this.setState({ ...this.state, isValid });

		if (isValid) {
			const block = Object.assign({}, this.props.block);
			block.data.preview = { widgetModel: this.state.widgetModel };
			blockManagementService.blockUpdate(block, index);
		} else {
			return isValid;
		}
	};

	componentWillUnmount(): void {
		blockWrapperService.unregisterOnSaveFunc(this.props.block.id);
	}

	updateWidgetModelState = (model: OddsModelV2) => {
		this.setState({ ...this.state, widgetModel: model });
	};

	onTeamChange = (team: TeamModel) => {
		this.updateWidgetModelState(
			OddsModelV2.builder(this.state.widgetModel)
				.withTeam(team)
				.withMatch(new MatchModel())
				.withBookmakers([])
				.withMarket(new MarketModel())
				.withMainBookmaker(null)
				.build(),
		);
	};

	onMatchChange = (match: MatchModel) => {
		this.updateWidgetModelState(
			OddsModelV2.builder(this.state.widgetModel)
				.withMatch(match)
				.withBookmakers([])
				.withMarket(new MarketModel())
				.withMainBookmaker(null)
				.build(),
		);
	};

	onBookmakerChange = (bookmakers: SportOddsBookmakerModel[]) => {
		if (bookmakers.length === 0) {
			this.updateWidgetModelState(
				OddsModelV2.builder(this.state.widgetModel).withBookmakers(bookmakers).withMainBookmaker(null).withMarket(new MarketModel()).build(),
			);
		} else {
			this.updateWidgetModelState(OddsModelV2.builder(this.state.widgetModel).withBookmakers(bookmakers).build());
		}
	};

	onMarketChange = (marketName: string) => {
		const market = { name: marketName, value: marketName };
		this.updateWidgetModelState(OddsModelV2.builder(this.state.widgetModel).withMarket(market).build());
	};

	onMarketValueTypeChange = (marketValueType: string) => {
		const market = { name: marketValueType, value: marketValueType };
		this.updateWidgetModelState(OddsModelV2.builder(this.state.widgetModel).withMarketValueType(market).build());
	};

	onRefreshTimeChange = (refreshTimeData: SelectMenuOptionType) => {
		onRefreshTimeChangeFromMainComponent(refreshTimeData, OddsModelV2, this.state.widgetModel, this.updateWidgetModelState);
	};

	render() {
		const { widgetModel, isValid } = this.state;
		const { t } = this.props;
		const bookmakerSelectionEnabled = featuresService.areFeaturesEnabled([FeatureTypes.ALLOW_BOOKMAKER_SELECTION]);
		const bookmakerSelected =
			widgetModel.bookmakers && widgetModel.bookmakers[0] && widgetModel.bookmakers[0].name && widgetModel.bookmakers[0].name.length > 0;

		return (
			<>
				<div data-qa='football-odds-block-edit'>
					<Row className='mb-2'>
						<Col>
							<TeamSelectComponent
								isMulti={false}
								isBlocky
								isValid={isValid}
								selectedTeam={widgetModel.team ? widgetModel.team : {}}
								onTeamSelect={(team: TeamModel) => this.onTeamChange(team)}
								t={t}
								isClearable
								isRequired={true}
								searchByKeyword={true}
								id={`${SportTypes.FOOTBALL}-${SPORT_SELECT_ATTRIBUTES.TEAM_SELECT}`}
							/>
						</Col>
					</Row>
					{widgetModel.team && widgetModel.team.id && (
						<Row className='mb-2'>
							<Col>
								<FormGroup>
									<MarketValueTypeSelect
										value={
											widgetModel.marketValueType && widgetModel.marketValueType.value
												? widgetModel.marketValueType.value
												: marketValueTypesOptions(t)[0].value
										}
										onMarketValueTypeSelect={this.onMarketValueTypeChange}
										id={`${SportTypes.FOOTBALL}-${SPORT_SELECT_ATTRIBUTES.MARKET_VALUE_TYPE_SELECT}`}
									/>
								</FormGroup>
							</Col>
						</Row>
					)}
					{widgetModel.team &&
						widgetModel.team.id &&
						widgetModel.marketValueType &&
						widgetModel.marketValueType.name &&
						widgetModel.marketValueType.name.length > 0 && (
							<Row className='mb-2'>
								<Col>
									<MatchesByTeamIdComponent
										selectedMatch={widgetModel && widgetModel.match ? widgetModel.match : new MatchModel()}
										onMatchSelect={this.onMatchChange}
										marketValueType={widgetModel.marketValueType ? widgetModel.marketValueType.value : ''}
										teamId={widgetModel.team && widgetModel.team.id ? widgetModel.team.id : null}
										isValid={isValid}
										isRequired={false}
										limit={'10'}
										id={`${SportTypes.FOOTBALL}-${SPORT_SELECT_ATTRIBUTES.MATCH}`}
									/>
								</Col>
							</Row>
						)}
					{bookmakerSelectionEnabled && widgetModel.match && widgetModel.match.id && (
						<Row className='mb-2'>
							<Col>
								<SportBookmakerSelect
									isMulti
									isValid={isValid}
									showAllBookmakers={true}
									bookmakerSelectionEnabled={bookmakerSelectionEnabled}
									t={t}
									valueList={widgetModel.bookmakers || []}
									onSelect={this.onBookmakerChange}
									contentLanguage={this.props.contentLanguage}
									sportType={SportTypes.FOOTBALL}
									id={`${SportTypes.FOOTBALL}-${SPORT_SELECT_ATTRIBUTES.BOOKMAKER_SELECT}`}
								/>
							</Col>
						</Row>
					)}
					{!bookmakerSelected && widgetModel.match && widgetModel.match.id && (
						<div className='mt-2'>
							<i className={`text-black-50 fa fa-info rounded-circle mr-1`} />
							<strong>{t(`bookmakers_odds_tip`)}</strong>
						</div>
					)}
					{widgetModel.match && widgetModel.match.id && (
						<Row className='mb-2 mt-2'>
							<Col>
								<FormGroup>
									<MarketSelect
										t={t}
										value={widgetModel.market && widgetModel.market.name && widgetModel.market.name}
										onMarketSelect={this.onMarketChange}
										limitTo1x2={false}
										id={`${SportTypes.FOOTBALL}-${SPORT_SELECT_ATTRIBUTES.MARKET_SELECT}`}
									/>
								</FormGroup>
							</Col>
						</Row>
					)}
					<RefreshTimeWrapper value={this.state.widgetModel.refreshTime} onRefreshTimeChange={this.onRefreshTimeChange} />
				</div>
			</>
		);
	}
}
