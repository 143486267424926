import React from 'react';
import { connect } from 'react-redux';
import { Col, Row, Table } from 'reactstrap';
import ReactPaginate, { ReactPaginateProps } from 'react-paginate';
import { PAGINATE_PROPS } from './properties/PaginateProps';
import { AuthorizedListingProps, State } from './properties/authorizedListingProps';
import { ConditionalRenderContext } from '../../../ConditionalRenderWraper/ConditionalRenderWrapper';
import NewContentContainer from './../NewContent/NewContentContainer';
import ListingHeadersContainer from './../ListingHeaders/ListingHeadersContainer';
import RowsContainer from './../Rows/RowsContainer';
import SearchBar from './../SearchBar/SearchBar';
import { ContentTypes } from '../../../../../../constants/content-types';
import AdvancedFilterModel from '../../../../Shared/advanced-content-filter/models/advanced-content-filter.model';
import { returnObjectForArticleFiltersUpdate } from '../../../../../../store/action-creators/ArticleActionCreator';
import {
	returnObjectForJWVideoFiltersUpdate,
	returnObjectForVideoFiltersUpdate,
} from '../../../../../../store/action-creators/VideoActionCreator';
import { returnObjectForGalleryFiltersUpdate } from '../../../../../../store/action-creators/GalleryActionCreator';
import { extractSearchQueryFromFilters } from '../../../../Shared/advanced-content-filter/helpers/advanced-content-filter.helper';
import { CategoryEditPermissions } from '../Permissions/EditPermissions';
import './styles/authorized-listing.styles.scss';
import AdvancedContentFilter from '../../../../Shared/advanced-content-filter/advanced-content-filter.component';
import { isJWSection } from '../../../../../Resources/Videos/Helpers/VideoHelper';

class AuthorizedListing extends React.Component<AuthorizedListingProps, State> {
	constructor(props: any) {
		super(props);
		this.state = {
			contentSearchInput:
				props[`${this.props.contentType}Filters`] && props[`${this.props.contentType}Filters`].searchText
					? props[`${this.props.contentType}Filters`].searchText
					: '',
			advancedContentSearchInput: '',
			pageSearchQuery: '',
			currentPage: 1,
		};
	}

	static contextType = ConditionalRenderContext;
	context!: React.ContextType<typeof ConditionalRenderContext>;

	private handlePageClick = (data: any) => {
		const { contentType } = this.props;
		const selectedPage = data.selected + 1;
		this.setState({ currentPage: selectedPage });
		const searchQueryFromFilters = extractSearchQueryFromFilters(this.props[`${contentType}Filters`]);
		// Get content with filters
		if (this.props.showSearchBar && searchQueryFromFilters.length > 0 && searchQueryFromFilters !== '*') {
			this.props.getContent(selectedPage, this.props.currentProject, searchQueryFromFilters);
			// Get content without filters
		} else {
			const searchQuery =
				this.state.pageSearchQuery.length > 0 && searchQueryFromFilters.length > 0 && searchQueryFromFilters !== '*'
					? this.state.pageSearchQuery
					: '';
			this.props.getContent(selectedPage, this.props.currentProject, searchQuery);
		}

		if (this.props.onPageChange) {
			this.props.onPageChange(selectedPage);
		}
	};

	private reactPaginateProps = (t: any, contentPagination: any): ReactPaginateProps => {
		return {
			...PAGINATE_PROPS,
			previousLabel: t('previous_page'),
			nextLabel: t('next_page'),
			pageCount: contentPagination ? contentPagination.totalPages : 0,
			onPageChange: this.handlePageClick,
			forcePage: contentPagination.currentPage - 1,
		};
	};

	updateInputState = (text: string, pageSearchQuery: string) => {
		this.updateFilters(text);
		this.setState({ ...this.state, contentSearchInput: text, pageSearchQuery: pageSearchQuery });
		if (pageSearchQuery === '' || pageSearchQuery.length === 0) {
			this.setState({ currentPage: 1 });
		}
	};

	updateAdvancedInputState = (text: string) => {
		this.updateFilters(this.state.contentSearchInput);
		this.setState({ ...this.state, advancedContentSearchInput: text });

		if (text === '' || text.length === 0) {
			this.setState({ currentPage: 1 });
		}
	};

	updateFilters = (searchText: string) => {
		const { contentType } = this.props;
		if (this.props[`${contentType}FiltersUpdate`]) {
			this.props[`${contentType}FiltersUpdate`](
				AdvancedFilterModel.builder(this.props[`${this.props.contentType}Filters`]).withSearchText(searchText).build(),
			);
		}
	};

	updatePageSearchQuery = (text: string) => {
		this.setState({ ...this.state, pageSearchQuery: text });

		if (text === '' || text.length === 0) {
			this.setState({ ...this.state, pageSearchQuery: text, currentPage: 1 });
		}
	};

	searchContent = () => {
		const { advancedContentSearchInput, contentSearchInput, currentPage } = this.state;
		if (contentSearchInput.match(/^ *$/) !== null && advancedContentSearchInput.match(/^ *$/) !== null) {
			this.updateInputState('', '');
			this.updateAdvancedInputState('');
			this.props.getContent(currentPage, this.props.currentProject);
		} else {
			const contentSearch = contentSearchInput.length > 0 ? contentSearchInput + advancedContentSearchInput : `*${advancedContentSearchInput}`;
			this.props.searchContent(contentSearch, this.props.currentProject);
			this.updatePageSearchQuery(contentSearch);
		}
	};

	getAllContent = () => {
		this.props.getContent(this.state.currentPage, this.props.currentProject);
	};

	render() {
		const {
			contentPagination,
			toggleLoadingState,
			analytics,
			contentType,
			content,
			onContentDelete,
			hidePagination,
			showSearchBar,
			t,
			currentProject,
			searchContent,
			getContent,
			location,
		} = this.props;
		const context: React.ContextType<typeof ConditionalRenderContext> = this.context;
		if (context.hasReadPermission) {
			return (
				<div className={`${toggleLoadingState ? 'loading-overlay' : ''}`}>
					<Row>
						<Col col='6' sm='12' md='6'>
							<NewContentContainer
								location={location}
								analytics={analytics}
								t={t}
								contentType={contentType}
								display={context.hasWritePermission}
							/>
						</Col>
						<Col col='6' sm='12' md='6' className='d-flex flex-column'>
							<SearchBar
								showSearchBar={showSearchBar}
								t={t}
								currentProject={currentProject}
								searchContent={searchContent}
								currentPage={this.props.contentPagination.currentPage}
								advancedContentSearchInput={this.state.advancedContentSearchInput}
								getContent={getContent}
								analytics={analytics}
								updateSearchText={this.updateInputState}
								contentSearchInput={this.state.contentSearchInput}
								updateFilters={this.updateFilters}
								videoFilters={this.props.videoFilters}
							/>
							<CategoryEditPermissions contentType={contentType} t={t} analytics={analytics} />
						</Col>
					</Row>
					{showSearchBar && (
						<Row>
							<Col className='ml-auto'>
								<AdvancedContentFilter
									t={t}
									searchContent={this.searchContent}
									updateAdvancedSearchText={this.updateAdvancedInputState}
									filtersInSidebar={false}
									getAllContent={this.getAllContent}
									contentSearchInput={this.state.contentSearchInput}
									showOriginSelect={this.props.contentType === ContentTypes.VIDEO && !isJWSection()}
									showTypeSelect={this.props.contentType === ContentTypes.ARTICLE}
									contentType={this.props.contentType}
								/>
							</Col>
						</Row>
					)}
					<Row className='mt-1'>
						<Col className='listing-table'>
							<Table responsive striped className={`${contentType === ContentTypes.CATEGORY ? 'mb-0' : 'mb-1'}`}>
								<ListingHeadersContainer t={t} contentType={contentType} />
								{content && content.length > 0 && contentType !== ContentTypes.CATEGORY && (
									<tbody>
										<RowsContainer
											t={t}
											onContentDelete={onContentDelete}
											content={content}
											history={this.props.history}
											contentType={contentType}
											analytics={analytics}
											searchQuery={this.state.contentSearchInput}
										/>
									</tbody>
								)}
							</Table>
							{content && content.length > 0 && contentType === ContentTypes.CATEGORY && (
								<RowsContainer
									t={t}
									onContentDelete={onContentDelete}
									content={content}
									history={this.props.history}
									contentType={contentType}
									analytics={analytics}
								/>
							)}
							{content && content.length === 0 && <h6 className='d-flex justify-content-center mb-2'>{this.props.t('no_data_found')}</h6>}
							{!hidePagination && <ReactPaginate {...this.reactPaginateProps(t, contentPagination)} />}
						</Col>
					</Row>
				</div>
			);
		}
		return <div className={'text-center py-5'}>{t('content_read_not_authorized')}</div>;
	}
}

function mapStateToProps(state: any) {
	return {
		articleFilters: state.article.filters,
		videoFilters: isJWSection() ? state.video.jwFilters : state.video.filters,
		galleryFilters: state.gallery.filters,
	};
}

function mapDispatchToProps(dispatch: any) {
	return {
		articleFiltersUpdate: (filters: any) => dispatch(returnObjectForArticleFiltersUpdate(filters)),
		videoFiltersUpdate: (filters: any) =>
			dispatch(isJWSection() ? returnObjectForJWVideoFiltersUpdate(filters) : returnObjectForVideoFiltersUpdate(filters)),
		galleryFiltersUpdate: (filters: any) => dispatch(returnObjectForGalleryFiltersUpdate(filters)),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthorizedListing);
