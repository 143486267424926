import 'reflect-metadata';
import { TypedJSON } from 'typedjson';
import FansUnitedGameModel from './fans-united-game.model';

export default class FansUnitedGameMapper {
	public responseToModel = (response: any): FansUnitedGameModel => {
		const serializer = new TypedJSON(FansUnitedGameModel);
		const remmapedGame = serializer.parse(response) as FansUnitedGameModel;
		remmapedGame['isSelected'] = false;

		return remmapedGame;
	};
	public modelToRequestBody = (model: FansUnitedGameModel) => {
		if (model) {
			return {
				id: model.id,
				title: model.title,
				description: model.description,
				type: model.type,
				status: model.status,
				predictions_cutoff: model.predictions_cutoff,
				fixtures: model.fixtures,
				rules: model.rules,
				flags: model.flags,
				created_at: model.created_at,
				updated_at: model.updated_at,
			};
		}
		return new FansUnitedGameModel();
	};
}
