import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { Col, Label, Row } from 'reactstrap';
import CategoryModel from '../../../../../../models/v2/category/category.model';
import { AppState } from '../../../../../../store/store';
import { isArrayEmpty } from '../../../../../../global-helpers/global.helpers';
import { DATA_QA_ATTRIBUTES } from '../../../../../Partials/Sidebar/general-content/constants/data-qa.attributes';
import ErrorAsync from '../../../../../Partials/BaseComponents/ErrorComponent/ErrorAsync';
import { categoriesToOptions, SelectCategoryOptionType } from '../../../helpers/categories-select.helper';
import LiveBlogModel from '../../../models/live-blog.model';

type Properties = {
	t: any;
	liveBlog: LiveBlogModel;
	setLiveBlogAdditionalCategories: any;
};

export const LiveBlogAdditionalCategoriesSelect: FunctionComponent<Properties> = ({ t, setLiveBlogAdditionalCategories, liveBlog }) => {
	const categories = useSelector((state: AppState) => state.category.allCategories);
	const adminCategories = useSelector((state: AppState) => state.category.adminCategories);

	const onAdditionalCategoriesSelect = (selectedCategories: SelectCategoryOptionType[]) => {
		const selectedAdditionalCategories: CategoryModel[] =
			selectedCategories && selectedCategories.length > 0 ? selectedCategories.map((el) => el.data) : [];
		setLiveBlogAdditionalCategories(selectedAdditionalCategories);
	};

	return isArrayEmpty(categories) ? null : (
		<Row className='mb-3 additional-categories'>
			<Col>
				<Label htmlFor={DATA_QA_ATTRIBUTES.ADDITIONAL_LIVE_BLOG_CATEGORY}>
					<strong>{t('additional_categories')}</strong>
				</Label>
				<Select
					id={DATA_QA_ATTRIBUTES.ADDITIONAL_LIVE_BLOG_CATEGORY}
					value={liveBlog.additional_categories && categoriesToOptions(liveBlog.additional_categories, adminCategories)}
					options={categoriesToOptions(categories, adminCategories)}
					onChange={(selections: any) => onAdditionalCategoriesSelect(selections)}
					placeholder={t('select')}
					noOptionsMessage={(inputValue) => inputValue && t('no_options')}
					isMulti
				/>
				<ErrorAsync t={t} errorType='additional_categories_id' />
			</Col>
		</Row>
	);
};
