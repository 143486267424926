export enum DATA_QA {
	SEARCH_FIELD = 'custom-entities-listing-search-field',
	CUSTOM_ENTITIES_PAGINATION = 'custom-entities-listing-pagination',
	CUSTOM_ENTITIES_LISTING = 'custom-entities-listing',
	CUSTOM_ENTITIES_LISTING_ITEM = 'custom-entities-listing-item',
	CUSTOM_ENTITIES_DELETE_BUTTON = 'custom-entities-delete-button',
	CUSTOM_ENTITIES_EDIT_BUTTON = 'custom-entities-edit-button',
	CUSTOM_ENTITIES_CREATE_BUTTON = 'custom-entities-create-button',
	CUSTOM_ENTITIES_DELETE_MODAL = 'custom-entities-delete-modal',
	CUSTOM_ENTITIES_DOMAIN_SELECT = 'custom-entities-domain-select',
	CUSTOM_ENTITY_WRAPPER = 'custom-entity-wrapper',
	TRANSLATIONS = 'custom-entity-translations-section',
	SOCIALS = 'custom-entity-socials-section',
	ROLES = 'custom-entity-roles-section',
	ROLE_ENTITY = 'custom-entity-role-entity-section',
	ADD_NEW_ROLE_ENTITY_BTN = 'custom-entity-add-new-role-button',
	OPTIONAL_FIELDS = 'custom-entity-optional-fields-section',
	UPLOADS_CONTAINER = 'custom-entity-uploads-container',
	UPLOAD_DISPLAY_ASSET = 'custom-entity-upload-asset',
	UPLOAD_ICON = 'custom-entity-upload-icon',
	CUSTOM_ENTITIES_ENTITY_TYPE_SELECT = 'custom-entities-entity-type-select',
	CUSTOM_ENTITIES_ADVANCED_FILTERS_SEARCH = 'custom-entities-advance-filters-search',
}
