import React, { FunctionComponent } from 'react';
import ListModel from '../../../../../../models/v2/list/list';
import { connect } from 'react-redux';
import ListItemModel from '../../../../../../models/v2/list/list-item';
import * as ReactSortableHOC from 'react-sortable-hoc';
import { updateListInRedux } from '../../../../../../store/action-creators/ListActionCreators';
import { DATA_QA_ATTRIBUTES } from '../../../../../Partials/Sidebar/general-content/constants/data-qa.attributes';
import LiveBlogListContentSortableItem from './live-blog-sortable-item.component';
import { isListEmpty } from '../../../../../Partials/Sidebar/list-refactored/helpers/general.helper';

type Properties = {
	t: any;
	selectedList: ListModel | null;
	updateListInRedux: (list: ListModel) => void;
};

const SortableItem = ReactSortableHOC.SortableElement(LiveBlogListContentSortableItem);

const LiveBlogListContentSortableList: FunctionComponent<Properties> = ({ t, selectedList, updateListInRedux }) => {
	if (!selectedList) return null;

	const onDeleteListItem = (itemType: string, itemId: string) => {
		const allItems = selectedList && selectedList.items && selectedList.items.length > 0 ? [...selectedList.items] : [];
		const itemIndexForRemoving = allItems.findIndex((listItem: ListItemModel) => listItem.data.id === itemId && listItem.type === itemType);
		itemIndexForRemoving >= 0 && allItems.splice(itemIndexForRemoving, 1);
		updateListInRedux({ ...selectedList, items: allItems });
	};

	return (
		<ul className='list-group mb-2' id={DATA_QA_ATTRIBUTES.LIVE_BLOG_SORTABLE_LIST}>
			{selectedList &&
				selectedList.items.map((item: ListItemModel, index: number) => (
					<SortableItem
						disabled={typeof item.lockedPosition === 'number'}
						selectedListItem={item}
						onDelete={onDeleteListItem}
						t={t}
						key={`key-sortable-${item.data && item.data.id}-${item.data && item.data.title}`}
						itemIndex={index}
						index={index}
					/>
				))}
		</ul>
	);
};

function mapStateToProps(state: any) {
	return {
		selectedList: (!isListEmpty(state.list.list) && state.list.list) || null,
	};
}

function mapDispatchToProps(dispatch: Function) {
	return {
		updateListInRedux: (list: ListModel) => dispatch(updateListInRedux(list)),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(LiveBlogListContentSortableList);
