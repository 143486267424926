export const contentToOption = (content: any, t: any) => {
	if (content && content.id) {
		return {
			label: t(content.name),
			value: content.id,
			data: content,
		};
	}

	return {};
};

export const contentToOptions = (response: any, t: any) => {
	if (response && response.length > 0) {
		return response.map((content: any) => {
			return contentToOption(content, t);
		});
	}

	return [];
};
