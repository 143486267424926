import { FootballPlayerH2HJson } from './football-player-h2h.json';
import FootballPlayerH2HWidgetModel from './football-player-h2h.model';
import PlayerModel from '../../../../../../../models/v2/player/entity/player.model';
import EntitySeasonsSelectModel from '../../../../partials/player-seasons/model/entity-seasons-select.model';
import { RefreshTimeValuesType } from '../../refresh-time-input/helper';

export default class FootballPlayerH2HBuilder {
	readonly json: FootballPlayerH2HJson;

	constructor(playerH2H?: FootballPlayerH2HWidgetModel | FootballPlayerH2HJson) {
		if (playerH2H && playerH2H instanceof FootballPlayerH2HWidgetModel) {
			this.json = playerH2H.toJson();
		} else if (playerH2H) {
			this.json = playerH2H;
		} else {
			this.json = {} as FootballPlayerH2HJson;
		}
	}

	withPlayerOne(player1: PlayerModel): FootballPlayerH2HBuilder {
		this.json.player1 = player1;

		return this;
	}

	withPlayerTwo(player2: PlayerModel): FootballPlayerH2HBuilder {
		this.json.player2 = player2;

		return this;
	}

	withPlayerOneSeason(player1TournamentSeason: EntitySeasonsSelectModel): FootballPlayerH2HBuilder {
		this.json.player1TournamentSeason = player1TournamentSeason;

		return this;
	}

	withPlayerTwoSeason(player2TournamentSeason: EntitySeasonsSelectModel): FootballPlayerH2HBuilder {
		this.json.player2TournamentSeason = player2TournamentSeason;

		return this;
	}

	withStats(stats: string[]): FootballPlayerH2HBuilder {
		this.json.stats = stats;

		return this;
	}

	withRefreshTime(refreshTime: RefreshTimeValuesType): FootballPlayerH2HBuilder {
		this.json.refreshTime = refreshTime;

		return this;
	}

	build(): FootballPlayerH2HWidgetModel {
		return FootballPlayerH2HWidgetModel.fromJson(this.json);
	}
}
