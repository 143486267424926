import 'reflect-metadata';
import { jsonArrayMember, jsonMember, jsonObject } from 'typedjson';
import Country from '../Country/Country.model';
import TennisTournamentCategoryModel from '../category/tennis-tournament-category';
import TennisRoundModel from '../round/tennis-round/tennis-round.model';
import CompetitionModel from '../competition/entity/competition.model';

@jsonObject()
export default class TennisTournamentModel {
	@jsonMember(String)
	public id: string = '';
	@jsonMember(String, { name: 'name' })
	public name: string = '';
	@jsonMember(String)
	public region: string = '';
	@jsonMember(String)
	public gender: string = '';
	@jsonMember(Country)
	public country: Country = new Country();
	@jsonMember(CompetitionModel)
	public competition: CompetitionModel = new CompetitionModel();
	@jsonMember(TennisTournamentCategoryModel)
	public category: TennisTournamentCategoryModel = new TennisTournamentCategoryModel();
	@jsonArrayMember(TennisRoundModel)
	public rounds: TennisRoundModel[] = [];
	@jsonMember(String, { name: 'start_date' })
	public startDate: string = '';
	@jsonMember(String, { name: 'end_date' })
	public endDate: string = '';
	@jsonMember(String, { name: 'entity_type' })
	public entityType: string = '';
	@jsonMember(String)
	public slug: string | null = null;
	@jsonMember(String, { name: 'season_year' })
	public seasonYear: string = '';
	@jsonMember(String)
	public surface: string = '';
	@jsonMember(String, { name: 'indoor_outdoor' })
	public inDoorOutDoor: string = '';
}
