import { Properties, State } from '../image-listing/properties/ImagoImageListingContainerProps';
import * as React from 'react';
import ImagoImageListing from './ImagoImageListing';
import ImagoImagesModel from '../../../../../../Blocky/blocks/content/imago-image-block/models/imago-images.model';
import { Button, Col, FormGroup, Row } from 'reactstrap';
import Scrollbar from 'react-custom-scrollbars';
import ImagoImageCells from '../../../../../../Blocky/blocks/content/imago-image-block/image-cells/imago-main-media-image-cells';
import ImagoHttpService from '../../../../../../../../services/rest/imago/ImagoHttpService';
import ImagoImageModel from '../../../../../../Blocky/blocks/content/imago-image-block/models/imago-image.model';

export default class ImagoImageListingContainer extends React.Component<Properties, State> {
	httpImago = new ImagoHttpService();

	constructor(props: any) {
		super(props);
		this.state = {
			toggleLoadingState: false,
			shouldRequest: false,
			pageOffset: props.pageOffset,
			imagoImages: null,
			searchInput: props.searchInput,
		};
	}

	onImageSearch = (imagoImages: ImagoImagesModel, pageOffset: number, searchInput: string, selectedImageType: string) => {
		this.setState((state: State) => ({ ...state, imagoImages, pageOffset, shouldRequest: false }));
		this.props.updateSearchInput && this.props.updateSearchInput(searchInput, pageOffset, selectedImageType);
	};

	onImageSelect = (image: ImagoImageModel) => {
		this.props.onImageSelect(image);
	};

	toggleLoadingState = (loading: boolean) => this.setState({ ...this.state, toggleLoadingState: loading });

	onPageUp = () => this.setState({ ...this.state, shouldRequest: true, pageOffset: this.state.pageOffset + 15 });

	onPageDown = () => this.setState({ ...this.state, shouldRequest: true, pageOffset: this.state.pageOffset - 15 });

	render() {
		const { t, onImageSelect, selectedImagoImage, selectedImageType } = this.props;
		const { imagoImages, toggleLoadingState, shouldRequest, pageOffset, searchInput } = this.state;
		return (
			<div className={'mb-2'}>
				<div>
					<Row>
						<Col>
							<FormGroup>
								<ImagoImageListing
									t={t}
									onImageSearch={this.onImageSearch}
									shouldRequest={shouldRequest}
									selectedImageType={selectedImageType}
									onImageSelect={this.onImageSelect}
									onSelectedImagoImageChange={onImageSelect}
									toggleLoadingState={this.toggleLoadingState}
									searchInput={searchInput}
									pageOffset={pageOffset}
									contentType={this.props.contentType}
								/>
							</FormGroup>
						</Col>
					</Row>
					<Row />
					<div className={`${toggleLoadingState ? 'loading-overlay' : ''}`}>
						{imagoImages && imagoImages.imageList && imagoImages.imageList.length > 0 ? (
							<div style={{ height: '500px' }} className='pb-1'>
								<Scrollbar ref='scrollbars'>
									<ImagoImageCells
										t={t}
										onSelect={this.onImageSelect}
										images={imagoImages.imageList}
										toggleLoadingState={toggleLoadingState}
										selectedImage={selectedImagoImage}
									/>
								</Scrollbar>
							</div>
						) : (
							<div className='text-center'>{t('no_data')}</div>
						)}
						{imagoImages && (
							<div className='m-2 d-flex flex-row justify-content-end'>
								<Button
									id='imagoimages-prev-page'
									size='sm'
									outline
									className='mr-2 d-flex align-items-center'
									color='primary'
									disabled={pageOffset === 0}
									onClick={this.onPageDown}
								>
									<i className='fa fa-2x fa-angle-left px-1' />
									{t('previous')}
								</Button>
								<Button
									id='imagoimages-next-page'
									size='sm'
									disabled={pageOffset + 15 >= imagoImages.totalPhotos}
									className='d-flex align-items-center'
									outline
									color='primary'
									onClick={this.onPageUp}
								>
									{t('next')}
									<i className='fa fa-2x fa-angle-right px-1' />
								</Button>
							</div>
						)}
					</div>
				</div>
			</div>
		);
	}
}
