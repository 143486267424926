import PlayerModel from '../../player-select/models/player.model';
import { assetsPlaceholder } from '../../../../../../constants/assetsPlaceholder';

export function topScorerToOption(topScorer: PlayerModel) {
	if (topScorer) {
		return {
			value: topScorer.id,
			label: topScorer.name,
			data: topScorer,
			logo: topScorer.logo,
			type: 'player',
		};
	}

	return {};
}

export function topScorersToOptions(topScorers: PlayerModel[]) {
	if (topScorers && topScorers.length > 0) {
		return topScorers.map((topScorer) => topScorerToOption(topScorer));
	}

	return [];
}

export function remapPlayerFromResponse(response: any) {
	if (response && response.length > 0) {
		return response.map((topScorers: any) => {
			return PlayerModel.builder()
				.withId(topScorers.player.id)
				.withLogo(topScorers.player.url_thumb ? topScorers.player.url_thumb : assetsPlaceholder.player)
				.withName(topScorers.player.name)
				.withPosition(topScorers.player.position)
				.build();
		});
	}

	return [];
}
