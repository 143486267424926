import React from 'react';
import { featuresService } from '../../../App';
import { FeatureTypes } from '../../../services/feature-service/features.enum';
import { useTranslation } from 'react-i18next';
import { ClassicQuizManager } from 'fansunited-widget-classic-quiz';

export const ClassicQuizManagerComponent: React.FunctionComponent = () => {
	const config = featuresService.getFeatureConfig(FeatureTypes.FANS_UNITED);
	const [t] = useTranslation();
	const labels = {
		userNotFound: t('fu_userNotFound'),
		noGamesFound: t('fu_noGamesFoundClassicQuiz'),
		signOut: t('fu_signOut'),
		timeFormat: t('fu_timeFormat'),
		getClassicQuizzesErrorMessage: t('fu_getClassicQuizzesErrorMessage'),
		title: t('title'),
		status: t('fu_status'),
		participants: t('fu_participants'),
		averageScore: t('fu_averageScore'),
		perfectScore: t('fu_perfectScore'),
		creationDate: t('fu_creationDate'),
		actions: t('actions'),
		create: t('fu_create'),
		edit: t('fu_edit'),
		save: t('fu_save'),
		preview: t('fu_preview'),
		close: t('close'),
		titlePlaceholder: t('fu_titlePlaceholderClassicQuiz'),
		description: t('description'),
		descriptionPlaceholder: t('fu_descriptionPlaceholderClassicQuiz'),
		images: t('fu_images'),
		rules: t('fu_rules'),
		rulesPlaceholder: t('fu_rulesPlaceholderClassicQuiz'),
		adContent: t('fu_adContent'),
		adContentPlaceholder: t('fu_adContentPlaceholderClassicQuiz'),
		questionImages: t('fu_questionImages'),
		optionImages: t('fu_optionImages'),
		main: t('fu_main'),
		cover: t('fu_cover'),
		mobile: t('fu_mobile'),
		imagePlaceholder: t('fu_imagePlaceholder'),
		active: t('fu_active'),
		inactive: t('fu_inactive'),
		statusDescription: t('fu_statusDescriptionClassicQuiz'),
		time: t('fu_time'),
		timePlaceholder: t('fu_timePlaceholder'),
		repeatedValueMessage: t('fu_repeatedValueMessage'),
		flags: t('fu_flags'),
		flagsPlaceholder: t('fu_flagsPlaceholder'),
		labels: t('fu_labels'),
		customFields: t('fu_customFields'),
		dynamicFieldsDescription: t('fu_dynamicFieldsDescriptionClassicQuiz'),
		keyDynamicFields: t('fu_key'),
		valueDynamicFields: t('fu_value'),
		question: t('fu_question'),
		questionNumber: t('fu_questionNumber'),
		questionPlaceholder: t('fu_questionPlaceholderClassicQuiz'),
		questions: t('fu_questions'),
		questionsDescription: t('fu_questionsDescriptionClassicQuiz'),
		questionsParagraph: t('fu_questionsParagraphClassicQuiz'),
		option: t('fu_option'),
		optionNumber: t('fu_optionNumber'),
		optionPlaceholder: t('fu_optionPlaceholder'),
		correct: t('fu_correct'),
		context: t('fu_context'),
		content: t('fu_content'),
		tags: t('fu_tags'),
		entity: t('fu_entity'),
		campaign: t('fu_campaign'),
		tagNumber: t('fu_tagNumber'),
		id: t('fu_id'),
		idContentPlaceholder: t('fu_idContentPlaceholder'),
		idCampaignPlaceholder: t('fu_idCampaignPlaceholder'),
		label: t('fu_label'),
		labelContentPlaceholder: t('fu_labelContentPlaceholder'),
		labelCampaignPlaceholder: t('fu_labelCampaignPlaceholder'),
		type: t('fu_type'),
		typeDescription: t('fu_typeDescriptionClassicQuiz'),
		noType: t('fu_noType'),
		deleteLabel: t('fu_deleteLabel'),
		cancelLabel: t('fu_cancelLabel'),
		confirmationQuestionLabel: t('fu_confirmationQuestionLabel'),
		typeContentPlaceholder: t('fu_typeContentPlaceholder'),
		previewClassicQuiz: t('fu_previewClassicQuiz'),
		getFootballEntitiesErrorMessage: t('fu_getFootballEntitiesErrorMessage'),
		createClassicQuizMessage: t('fu_createClassicQuizMessage'),
		createClassicQuizErrorMessage: t('fu_createClassicQuizErrorMessage'),
		titleRequiredFieldValidationMessage: t('fu_titleRequiredFieldValidationMessage'),
		questionsInvalidValidationMessage: t('fu_questionsInvalidValidationMessage'),
		questionRequiredFieldValidationMessage: 'The question itself is required for each question!',
		optionRequiredFieldValidationMessage: t('fu_optionRequiredFieldValidationMessage'),
		correctInvalidFieldValidationMessage: t('fu_correctInvalidFieldValidationMessage'),
		tagsInvalidFieldValidationMessage: t('fu_tagsInvalidFieldValidationMessage'),
		getClassicQuizErrorMessage: t('fu_getClassicQuizErrorMessage'),
		deleteClassicQuizMessage: t('fu_deleteClassicQuizMessage'),
		deleteClassicQuizErrorMessage: t('fu_deleteClassicQuizErrorMessage'),
		updateClassicQuizMessage: t('fu_updateClassicQuizMessage'),
		updateClassicQuizErrorMessage: t('fu_updateClassicQuizErrorMessage'),
		uploadingImageMessage: t('fu_uploadingImageMessage'),
		generateSignedInUrlErrorMessage: t('fu_generateSignedInUrlErrorMessage'),
		uploadingImageErrorMessage: t('fu_uploadingImageErrorMessage'),
		encryptingImageErrorMessage: t('fu_encryptingImageErrorMessage'),
		clear: t('fu_clear'),
		searchSportal365ImagesErrorMessage: t('fu_searchSportal365ImagesErrorMessage'),
		searchImage: t('fu_searchImage'),
		clearFromToFilterSportal365ImagesMessage: t('fu_clearFromToFilterSportal365ImagesMessage'),
		hideFilters: t('fu_hideFilters'),
		showFilters: t('fu_showFilters'),
		dateFromTo: t('fu_dateFromTo'),
		dateFromToPlaceholder: t('fu_dateFromToPlaceholder'),
	};

	return config &&
		config.configuration &&
		config.configuration.api_key &&
		config.configuration.client_id &&
		config.configuration.language ? (
		<div className='p-4'>
			<ClassicQuizManager
				fansUnitedApiKey={config.configuration.api_key}
				fansUnitedLanguage={config.configuration.language}
				fansUnitedClientId={config.configuration.client_id}
				labels={labels}
			/>
		</div>
	) : null;
};
