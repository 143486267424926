import 'reflect-metadata';
import { AnyT, jsonArrayMember, jsonMember, jsonObject } from 'typedjson';
import BasketballRoundModel from '../../round/entity/basketball-round.model';

@jsonObject()
export default class BasketballStageModel {
	@jsonMember(String)
	public id: string = '';
	@jsonMember(String)
	public name: string = '';
	@jsonMember(String)
	public status: string = '';
	@jsonArrayMember(BasketballRoundModel)
	public rounds: BasketballRoundModel[] = [];
	@jsonMember(String, { name: 'start_date' })
	public startDate: string = '';
	@jsonMember(String, { name: 'end_date' })
	public endDate: string = '';
	@jsonMember(String)
	public type: string = '';
	@jsonMember(String, { name: 'entity_type' })
	public entityType: string = '';
	@jsonMember(String)
	public slug: string | null = null;
}
