import StageModel from '../../../../../../../models/v2/Stage/Entity/StageModel';
import FootballPlayerWidgetModel from './football-player-widget.model';
import { FootballPlayerWidgetJson } from './football-player-widget.json';
import PlayerModel from '../../../../../../../models/v2/player/entity/player.model';
import EntitySeasonsSelectModel from '../../../../partials/player-seasons/model/entity-seasons-select.model';
import MatchModel from '../../../../../../../models/v2/match/entity/match.model';
import { RefreshTimeValuesType } from '../../refresh-time-input/helper';

export default class FootballPlayerWidgetBuilder {
	readonly json: FootballPlayerWidgetJson;

	constructor(model?: FootballPlayerWidgetModel | FootballPlayerWidgetJson) {
		if (model && model instanceof FootballPlayerWidgetModel) {
			this.json = model.toJson();
		} else if (model) {
			this.json = model;
		} else {
			this.json = {} as FootballPlayerWidgetJson;
		}
	}

	withTournamentSeason = (tournamentSeason: EntitySeasonsSelectModel): FootballPlayerWidgetBuilder => {
		this.json.tournamentSeason = tournamentSeason;

		return this;
	};

	withStage = (stage: StageModel): FootballPlayerWidgetBuilder => {
		this.json.stage = stage;

		return this;
	};

	withMatch = (match: MatchModel): FootballPlayerWidgetBuilder => {
		this.json.match = match;

		return this;
	};

	withPlayer = (player: PlayerModel): FootballPlayerWidgetBuilder => {
		this.json.player = player;

		return this;
	};

	withPlayerTeamName = (playerTeamName: string): FootballPlayerWidgetBuilder => {
		this.json.playerTeamName = playerTeamName;

		return this;
	};

	withDisplayTabs(displayTabs: boolean): FootballPlayerWidgetBuilder {
		this.json.displayTabs = displayTabs;

		return this;
	}

	withTabs(tabs: string[]): FootballPlayerWidgetBuilder {
		this.json.tabs = tabs;

		return this;
	}

	withDefaultTab(defaultTab: any): FootballPlayerWidgetBuilder {
		this.json.defaultTab = defaultTab;

		return this;
	}

	withDisplayOdds(displayOdds: boolean): FootballPlayerWidgetBuilder {
		this.json.displayOdds = displayOdds;

		return this;
	}

	withFansUnitedEnabled(fansUnitedEnabled: boolean): FootballPlayerWidgetBuilder {
		this.json.fansUnitedEnabled = fansUnitedEnabled;

		return this;
	}

	withFansUnitedExpanded(fansUnitedExpanded: boolean): FootballPlayerWidgetBuilder {
		this.json.fansUnitedExpanded = fansUnitedExpanded;

		return this;
	}

	withInfoParameters(infoParameters: string[]): FootballPlayerWidgetBuilder {
		this.json.infoParameters = infoParameters;

		return this;
	}

	withStatsParameters(statsParameters: string[]): FootballPlayerWidgetBuilder {
		this.json.statsParameters = statsParameters;

		return this;
	}

	withRefreshTime(refreshTime: RefreshTimeValuesType): FootballPlayerWidgetBuilder {
		this.json.refreshTime = refreshTime;

		return this;
	}

	build(): FootballPlayerWidgetModel {
		return FootballPlayerWidgetModel.fromJson(this.json);
	}
}
