import SportTeamModel from '../../../../partials/sports-team-select/models/sport-team.model';
import GameModel from '../../../../../../../models/v2/game/game.model';
import TennisSingleEventModel from './tennis-single-event.model';
import { TennisWidgetJson } from './tennis-widget.json';
import PlayerModel from '../../../../../../../models/v2/player/entity/player.model';
import { RefreshTimeValuesType } from '../../refresh-time-input/helper';

export default class TennisSingleEventBuilder {
	readonly json: TennisWidgetJson;

	constructor(match?: TennisSingleEventModel | TennisWidgetJson) {
		if (match && match instanceof TennisSingleEventModel) {
			this.json = match.toJson();
		} else if (match) {
			this.json = match;
		} else {
			this.json = {} as TennisWidgetJson;
		}
	}

	withMatch(match: GameModel): TennisSingleEventBuilder {
		this.json.match = match;

		return this;
	}

	withPlayers(players: PlayerModel[]): TennisSingleEventBuilder {
		this.json.players = players;

		return this;
	}

	withDisplayOdds(displayOdds: boolean): TennisSingleEventBuilder {
		this.json.displayOdds = displayOdds;

		return this;
	}

	withRefreshTime(refreshTime: RefreshTimeValuesType): TennisSingleEventBuilder {
		this.json.refreshTime = refreshTime;

		return this;
	}

	build(): TennisSingleEventModel {
		return TennisSingleEventModel.fromJson(this.json);
	}
}
