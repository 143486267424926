import { BlockConfigJson } from './block-config.json';
import BlockConfigBuilder from './block-config-model.builder';

export default class BlockConfigModel {
	readonly options: any = {};
	readonly widgetId: string = '';

	constructor(options: any, widgetId: string) {
		this.options = options;
		this.widgetId = widgetId;
	}

	toJson(): BlockConfigJson {
		return {
			options: this.options,
			widgetId: this.widgetId,
		};
	}

	static fromJson(json: BlockConfigJson): BlockConfigModel {
		return new BlockConfigModel(json.options, json.widgetId);
	}

	static builder(config?: BlockConfigModel): BlockConfigBuilder {
		return new BlockConfigBuilder(config);
	}
}
