import { assetsPlaceholder } from '../../../../constants/assetsPlaceholder';
import { EntityJson } from './entity.json';
import EntityModel from './entity.model';

export default class EntityBuilder {
	private json: EntityJson;

	constructor(entity?: EntityModel | EntityJson) {
		if (entity && entity instanceof EntityModel) {
			this.json = entity.toJson();
		} else if (entity) {
			this.json = entity;
		} else {
			this.json = {} as EntityJson;
		}
	}

	withId(id: string): EntityBuilder {
		this.json.id = id;

		return this;
	}

	withName(name: string): EntityBuilder {
		this.json.name = name;

		return this;
	}

	withImage(url: string, type: string): EntityBuilder {
		this.json.image = url ? url : assetsPlaceholder[type];

		return this;
	}

	withCityName(name: string): EntityBuilder {
		this.json.cityName = name ? name : '';

		return this;
	}

	withCountry(country: any): EntityBuilder {
		this.json.country = country;

		return this;
	}

	withType(type: string): EntityBuilder {
		this.json.type = type;

		return this;
	}

	withBirthdate(birthdate: string): EntityBuilder {
		this.json.birthdate = birthdate;

		return this;
	}

	withShortName(shortName: string): EntityBuilder {
		this.json.shortName = shortName;

		return this;
	}

	build(): EntityModel {
		return EntityModel.fromJSON(this.json);
	}
}
