import ContentWidget from './content-widget.model';
import { ContentRequestJson } from './content-widget-request.json';
import { ContentWidgetJson } from './content-widget.json';
import VideoUrls from '../../../../../../../models/urls/video-urls/video-urls';
import MainMedia from '../../../../../../../models/mainmedia/MainMedia';

export default class ContentWidgetBuilder {
	private json: ContentWidgetJson;

	constructor(content?: ContentWidget | ContentWidgetJson) {
		if (content && content instanceof ContentWidget) {
			this.json = content.toJson();
		} else if (content) {
			this.json = content;
		} else {
			this.json = {} as ContentWidgetJson;
		}
	}

	withId(id: string): ContentWidgetBuilder {
		this.json.id = id;

		return this;
	}

	withTitle(title: string): ContentWidgetBuilder {
		this.json.title = title;

		return this;
	}

	withImage(image: string): ContentWidgetBuilder {
		this.json.image = image;

		return this;
	}

	withSubtitile(subtitle: string): ContentWidgetBuilder {
		this.json.subtitle = subtitle;

		return this;
	}

	withPublishedAt(publishedAt: string): ContentWidgetBuilder {
		this.json.publishedAt = publishedAt;

		return this;
	}

	withStartAt(startAt: string): ContentWidgetBuilder {
		this.json.startAt = startAt;

		return this;
	}

	withWidth(width: string): ContentWidgetBuilder {
		this.json.width = width;

		return this;
	}

	withAlignment(alignment: string): ContentWidgetBuilder {
		this.json.alignment = alignment;

		return this;
	}

	withStatus(status: string): ContentWidgetBuilder {
		this.json.status = status;

		return this;
	}

	withVideoUrls(videoUrls: VideoUrls[]): ContentWidgetBuilder {
		this.json.videoUrls = videoUrls;

		return this;
	}

	withMainMedia(mainMedia: MainMedia[]): ContentWidgetBuilder {
		this.json.mainMedia = mainMedia;

		return this;
	}

	toRequestJson(): ContentRequestJson {
		let json = {} as ContentRequestJson;
		json.id = this.json.id;
		json.title = this.json.title;
		json.image = this.json.image;
		json.subtitle = this.json.subtitle;
		json.published_at = this.json.publishedAt;
		json.video_files = this.json.videoUrls;
		json.main_media = this.json.mainMedia;

		return json;
	}

	build(): ContentWidget {
		return ContentWidget.fromJson(this.json);
	}
}
