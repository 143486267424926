import SuggestedEntitiesModel from '../../../../../models/suggested-entities/suggested-entities.model';
import HttpService from '../../../../../services/rest/HttpService';
import { relatedConstants } from '../../../../../constants/related.constants';

export const remapSuggestedEntity = (response: any, subtype: string) => {
	if (response) {
		response['subtype'] = subtype;
		response['entity_type'] = subtype;
		return response;
	}

	return {};
};

export const getTournamentAndCoachFromTeam = (teamId: string) => {
	const entities: any[] = [];

	return HttpService.getTeamById(teamId)
		.then((response: any) => {
			const tournament = remapSuggestedEntity(
				response.data.current_league && response.data.current_league.tournament,
				relatedConstants.types.tournament,
			);
			entities.push(tournament);

			const coach = remapSuggestedEntity(response.data.coach, relatedConstants.types.coach);
			entities.push(coach);

			return entities;
		})
		.catch((e: any) => e);
};

export const getTeamFromPlayer = (player: any) => {
	const team = remapSuggestedEntity(player.club && player.club.team, relatedConstants.types.team);

	return team;
};

export const getTournamentFromMatches = (tournamentId: string) => {
	HttpService.getTournamentById(tournamentId)
		.then((response: any) => {
			const team = remapSuggestedEntity(response.data, relatedConstants.types.tournament);

			return team;
		})
		.catch((e: any) => e);
};

export const removeTag = (tags: SuggestedEntitiesModel[], tagToRemove: SuggestedEntitiesModel): any => {
	if (tagToRemove && tagToRemove.id && tags && tags.length > 0) {
		tags = tags.filter((tag: SuggestedEntitiesModel) => tag.id !== tagToRemove.id);

		return tags;
	}

	return tags;
};

export const getUniqueOnlyByType = (array: any, filterByProperty: any) => {
	const result = [];
	const map = new Map();
	if (array && array.length > 0 && filterByProperty && filterByProperty.length > 0) {
		for (const item of array) {
			if (!map.has(`${item.subtype}Id-${item[filterByProperty]}`)) {
				map.set(`${item.subtype}Id-${item[filterByProperty]}`, true);
				result.push({ ...item });
			}
		}
	}

	return result;
};
