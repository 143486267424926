import Video from '../../models/video/Video';
import {
	VIDEO_DELETE_SUCCESS,
	VIDEOS_RECEIVED,
	VIDEOS_CONTENT_STATISTICS_RECEIVED,
	VIDEO_PAGINATION_UPDATE,
	VIDEO_FILTERS_UPDATE,
	JW_VIDEO_FILTERS_UPDATE,
} from '../action-creators/VideoActionCreator';
import { USER_LOGOUT } from '../action-creators/ProfileActionCreators';
import PaginationMeta from '../../models/pagination/PaginationMeta';
import AdvancedFilterModel from '../../views/Partials/Shared/advanced-content-filter/models/advanced-content-filter.model';

interface InitialState {
	videos: Video[];
	videoDeleted: boolean;
	pagination: PaginationMeta;
	filters: AdvancedFilterModel;
	jwFilters: AdvancedFilterModel;
}

const initialState: InitialState = {
	videos: [],
	videoDeleted: false,
	pagination: PaginationMeta.builder().build(),
	filters: AdvancedFilterModel.builder().build(),
	jwFilters: AdvancedFilterModel.builder().build(),
};

function videoReducer(state: any = initialState, action: any) {
	if (action.type === VIDEOS_RECEIVED) {
		return Object.assign({}, state, {
			videos: action.payload.videos,
			pagination: action.payload.contentPagination,
		});
	}

	if (action.type === VIDEO_PAGINATION_UPDATE) {
		return Object.assign({}, state, {
			pagination: action.payload.pagination,
		});
	}

	if (action.type === VIDEO_DELETE_SUCCESS) {
		return Object.assign({}, state, {
			videoDeleted: action.payload,
		});
	}

	if (action.type === VIDEOS_CONTENT_STATISTICS_RECEIVED) {
		return Object.assign({}, state, {
			videos: action.payload.videos,
		});
	}

	if (action.type === VIDEO_FILTERS_UPDATE) {
		return Object.assign({}, state, {
			filters: action.payload.filters,
		});
	}

	if (action.type === JW_VIDEO_FILTERS_UPDATE) {
		return Object.assign({}, state, {
			jwFilters: action.payload.filters,
		});
	}

	if (action.type === USER_LOGOUT) {
		state = initialState;
	}

	return state;
}

export default videoReducer;
