export enum SportTypes {
	FOOTBALL = 'football',
	BASKETBALL = 'basketball',
	TENNIS = 'tennis',
	CYCLING = 'cycling',
	HANDBALL = 'handball',
	ICE_HOCKEY = 'ice_hockey',
	MOTORSPORTS = 'motorsports',
	RUGBY_LEAGUE = 'rugby_league',
	RUGBY_UNION = 'rugby_union',
	TABLE_TENNIS = 'table_tennis',
	VOLLEYBALL = 'volleyball',
}

export type SportTypesValue = SportTypes.BASKETBALL | SportTypes.FOOTBALL | SportTypes.TENNIS;

export enum SportBlockTypes {
	EVENT_BLOCK = 'event',
	TOURNAMENT_BLOCK = 'tournament',
}

export enum WidgetScriptTypes {
	WIDGETS = 'widgets-loader-script',
	FOOTBALL_WIDGETS = 'widgets-football-loader-script',
	BASKETBALL_WIDGETS = 'widgets-basketball-loader-script',
	TENNIS_WIDGETS = 'widgets-tennis-loader-script',
}
