import 'reflect-metadata';
import { jsonArrayMember, jsonMember, jsonObject } from 'typedjson';
import Translation from '../../../views/Partials/Blocky/partials/sports-team-select/models/translation.model';

@jsonObject
export default class ResultModel {
	@jsonMember(String)
	public id: string = '';

	@jsonMember(String)
	public name: string = '';

	@jsonMember(String)
	public position: string = '';

	@jsonMember(String)
	public value: string = '';

	@jsonArrayMember(Translation)
	public translations: Translation[] = [];
}
