import { SportEventTypeCode } from '../models/live-blog-post.model';

const getEventIcon = (code: SportEventTypeCode): string => {
	switch (code) {
		case SportEventTypeCode.GOAL:
		case SportEventTypeCode.OWN_GOAL:
		case SportEventTypeCode.PENALTY_GOAL:
		case SportEventTypeCode.PENALTY_SHOOTOUT_SCORED:
			return require('../style/icons/goal.svg');
		case SportEventTypeCode.YELLOW_CARD:
			return require('../style/icons/yellow-card.svg');
		case SportEventTypeCode.YELLOW_CARD_RED:
			return require('../style/icons/yellow-card-second.svg');
		case SportEventTypeCode.RED_CARD:
			return require('../style/icons/red-card.svg');
		case SportEventTypeCode.SUBSTITUTION:
			return require('../style/icons/substitution.svg');
		case SportEventTypeCode.VAR:
			return require('../style/icons/var.svg');
		case SportEventTypeCode.POSSESSION:
			return require('../style/icons/chart.svg');
		case SportEventTypeCode.ABANDONED:
		case SportEventTypeCode.INTERRUPTED:
		case SportEventTypeCode.PLAY_STOPPED:
			return require('../style/icons/alert.svg');
		case SportEventTypeCode.PLAY_RESTARTED:
			return require('../style/icons/play.svg');
		case SportEventTypeCode.KICKOFF:
		case SportEventTypeCode.FIRST_HALF:
		case SportEventTypeCode.HALF_TIME:
		case SportEventTypeCode.SECOND_HALF:
		case SportEventTypeCode.FULL_TIME:
		case SportEventTypeCode.FINISHED_AET:
		case SportEventTypeCode.FINISHED_AP:
		case SportEventTypeCode.FIRST_HALF_EXTRA_TIME:
		case SportEventTypeCode.SECOND_HALF_EXTRA_TIME:
		case SportEventTypeCode.WAITING_EXTRA_TIME:
		case SportEventTypeCode.WAITING_PENALTY_SHOOT_OUT:
		case SportEventTypeCode.END_FIRST_HALF_EXTRA_TIME:
		case SportEventTypeCode.INJURY_TIME:
			return require('../style/icons/stopwatch.svg');
		case SportEventTypeCode.PENALTY_SHOOTOUT:
			return require('../style/icons/penalty.svg');
		case SportEventTypeCode.PENALTY_SHOOTOUT_MISSED:
		case SportEventTypeCode.PENALTY_MISS:
			return require('../style/icons/penalty-red.svg');
		default:
			return '';
	}
};

export default getEventIcon;
