import { BugWidget, ContentTypes, EditorTypes, FansUnitedWidgetsTypes, WidgetTypes } from '../constants/block.types';
import Global from '../helpers/global.helpers';
import TeamProfileWidgetModel from '../blocks/widgets/team-profile/models/team-profile-widget.model';
import TeamModel from '../partials/team-select/models/team.model';
import OddsWidgetModel from '../blocks/widgets/odds/models/odds-widget.model';
import MatchModel from '../partials/match-select/models/match.model';
import BookmakerModel from '../partials/bookmaker-select/models/bookmaker.model';
import TeamPlayersWidgetModel from '../blocks/widgets/team-players/models/team-players-widget.model';
import { assetsPlaceholder } from '../../../../constants/assetsPlaceholder';
import TeamPlayerModel from '../partials/team-players-select/models/team-player.model';
import MatchWidgetModel from '../blocks/widgets/match/models/match-widget.model';
import TopScorersWidgetModel from '../blocks/widgets/top-scorers/models/top-scorers-widget.model';
import TournamentModel from '../blocks/widgets/player-profile/models/tournament-model/tournament.model';
import PlayerModel from '../partials/player-select/models/player.model';
import StandingsWidgetModel from '../blocks/widgets/standings/models/standings-widget.model';
import SeasonModel from '../blocks/widgets/fixtures/models/season/season.model';
import StageModel from '../blocks/widgets/fixtures/models/stage/stage.model';
import PlayerH2hModel from '../blocks/widgets/player-h2h/models/player-h2h.model';
import TeamComparisonModel from '../blocks/widgets/team-comparison/models/team-comparison.model';
import PlayerProfileWidgetModel from '../blocks/widgets/player-profile/models/player-profile-widget.model';
import FixturesModel from '../blocks/widgets/fixtures/models/fixtures-widget.model';
import ImageBlockModel from '../blocks/content/image-block/models/image-block.model';
import Image from '../../../../models/image/Image';
import { createEmptyContentBlock } from './helpers/backward-compatibility.helper';
import EmbedBlockModel from '../blocks/content/embed-block/models/embed-block.model';
import HighlightBlockModel from '../blocks/content/highlight-block/models/highlight-block.model';
import LinkBlockModel from '../blocks/content/link-block/models/link-block.model';
import BannerBlockModel from '../blocks/content/banner-block/models/banner-block.model';
import RoundModel from '../blocks/widgets/fixtures/models/round/round.model';
import GalleryBlockModel from '../blocks/content/gallery-block/models/gallery-block.model';
import BlockModel from '../models/block.model';
import { bugBlocks } from '../constants/block-list.constants';
import FansUnitedWidgetModel from '../blocks/widgets/fans-united/models/fans-united-widget.model';
import FansUnitedGameModel from '../blocks/widgets/fans-united/models/fans-united-game.model';

export default class BackwardCompatibilityService {
	readonly widgetTransformer = new WidgetTransformer();

	transform = (block: any) => {
		if (block === null || block === undefined) {
			block = bugBlocks[0].default_data;
		}

		if (block.id === undefined || block.id === null) {
			if (Array.isArray(block)) {
				const data = { changeId: Global.makeId(7) };
				block = BlockModel.builder().withData(data).withId(Global.makeId(6)).withType(BugWidget.arrayBug).build();
			}

			if (block.type === WidgetTypes.widget) {
				return this.widgetTransformer.transformWidgetBlocks(block);
			}

			if (block.type === FansUnitedWidgetsTypes.widget) {
				return this.widgetTransformer.transformFansUnitedWidgetBlocks(block);
			}

			if (block.type === ContentTypes[block.type]) {
				return this.widgetTransformer.transformContentBlocks(block);
			}

			if (block.data.type === EditorTypes[block.data.type]) {
				return this.widgetTransformer.transformEditorBlocks(block);
			}
		}

		return block;
	};
}

class WidgetTransformer {
	transformWidgetBlocks = (block: any) => {
		switch (block.data.widget_type) {
			case WidgetTypes.odds:
				return this.oddsTransform(block);
			case WidgetTypes.compareOdds:
				return this.oddsTransform(block);
			case WidgetTypes.teamProfile:
				return this.teamProfileTransform(block);
			case WidgetTypes.teamPlayers:
				return this.teamPlayersTransform(block);
			case WidgetTypes.match:
				return this.matchTransform(block);
			case WidgetTypes.topScorers:
				return this.topScorersTransform(block);
			case WidgetTypes.standings:
				return this.standingsTransform(block);
			case WidgetTypes.widgetPlayerH2H:
				return this.playerH2HTransform(block);
			case WidgetTypes.widgetTeamComparison:
				return this.teamComparisonTransform(block);
			case WidgetTypes.playerProfile:
				return this.playerProfileTransform(block);
			case WidgetTypes.fixtures:
				return this.fixturesTransform(block);
			default:
				return {};
		}
	};

	transformFansUnitedWidgetBlocks = (block: any) => {
		switch (block.data.widget_type) {
			case FansUnitedWidgetsTypes.widget1x2:
				return this.widget1x2Transform(block);
			case FansUnitedWidgetsTypes.doubleChance:
				return this.widgetDoubleChanceTransform(block);
			case FansUnitedWidgetsTypes.correctScore:
				return this.widgetCorrectScoreTransform(block);
			case FansUnitedWidgetsTypes.htFt:
				return this.widgetHtFTransform(block);
			case FansUnitedWidgetsTypes.playerPredictions:
				return this.widgetPlayerPredictionsTransform(block);
			case FansUnitedWidgetsTypes.teamPredictions:
				return this.widgetTeamPredictionsTransform(block);
			case FansUnitedWidgetsTypes.topX:
				return this.widgetTopXAndMatchQuizTransform(block, FansUnitedWidgetsTypes.topX);
			case FansUnitedWidgetsTypes.matchQuiz:
				return this.widgetTopXAndMatchQuizTransform(block, FansUnitedWidgetsTypes.matchQuiz);
			case FansUnitedWidgetsTypes.leaderboard:
				return this.widgetLeaderboardTransform(block);
			default:
				return {};
		}
	};

	transformContentBlocks = (block: any) => {
		switch (block.type) {
			case ContentTypes.article:
				return this.contentTransform(block, 'article');
			case ContentTypes.video:
				return this.contentTransform(block, 'video');
			case ContentTypes.image:
				return this.imageTransform(block);
			case ContentTypes.embed:
				return this.embedTransform(block);
			case ContentTypes.highlight:
				return this.highlightTransform(block);
			case ContentTypes.link:
				return this.linkTransform(block);
			case ContentTypes.banner:
				return this.bannerTransform(block);
			case ContentTypes.gallery:
				return this.galleryTransform(block);
			default:
				return {};
		}
	};

	transformEditorBlocks = (block: any) => this.editorBlocksTransform(block);

	editorBlocksTransform = (block: any) => {
		const newBlock: any = {};
		newBlock.type = block.type;
		newBlock.data = block.data;
		newBlock.id = Global.makeId(6);

		return newBlock;
	};

	teamProfileTransform = (block: any) => {
		if (block.data.preview) {
			const bookmakers =
				block.data.preview.selectedBookmakers &&
				block.data.preview.selectedBookmakers.flat(1) &&
				block.data.preview.selectedBookmakers.flat(1).length > 0
					? block.data.preview.selectedBookmakers.map((bookmaker: any) => BookmakerModel.builder(bookmaker).build())
					: null;

			const hasModelProperties = block.data.preview.properties ? true : false;

			const team = block.data.preview.team
				? TeamModel.builder(block.data.preview.team).withLogo(block.data.preview.team.logo).build()
				: TeamModel.builder().build();

			const teamProfileModel = TeamProfileWidgetModel.builder()
				.withDisplayOdds(hasModelProperties && block.data.preview.properties.displayOdds ? block.data.preview.properties.displayOdds : false)
				.withDisplayStatistics(
					hasModelProperties && block.data.preview.properties.displayStatistics ? block.data.preview.properties.displayStatistics : false,
				)
				.withNoData(block.data.preview.noData)
				.withTeam(team)
				.build();

			const oddsWidgetModel = OddsWidgetModel.builder()
				.withBookmakers(bookmakers)
				.withCanSelectMarkets(block.data.preview.canSelectMarkets ? block.data.preview.canSelectMarkets : false)
				.withDisplayHeader(block.data.preview.displayMatchHeader ? block.data.preview.displayMatchHeader : false)
				.withMarket(hasModelProperties && block.data.preview.properties.market ? block.data.preview.properties.market : null)
				.withMatch(block.data.preview.match ? MatchModel.builder(block.data.preview.match).build() : MatchModel.builder().build())
				.withTeam(team)
				.build();

			const newBlock: any = {};
			newBlock.type = block.type;
			newBlock.data = {};
			newBlock.data.changeId = Global.makeId(7);
			newBlock.data.preview = { teamProfile: teamProfileModel, odds: oddsWidgetModel };
			newBlock.data.content = block.data.content;
			newBlock.data.config = block.data.config;
			newBlock.data.widget_type = WidgetTypes.teamProfile;
			newBlock.id = Global.makeId(6);

			return newBlock;
		}

		return block;
	};

	widget1x2Transform = (block: any) => {
		if (block.data) {
			const match = block.data.widget && block.data.widget.match ? block.data.widget.match : null;
			const market = block.data.widget && block.data.widget.market ? block.data.widget.market : null;

			const widget1x2Model = FansUnitedWidgetModel.builder()
				.withMatch(match)
				.withMarket(market)
				.withTeamAgainst({} as TeamModel)
				.withTeams([])
				.withPlayer({} as PlayerModel)
				.build();

			const newBlock: any = {};
			newBlock.type = block.type;
			newBlock.data = {};
			newBlock.data.changeId = Global.makeId(7);
			newBlock.data.widget = { widget1x2: widget1x2Model };
			newBlock.data.content = block.data.content;
			newBlock.data.config = block.data.config;
			newBlock.data.widget_type = FansUnitedWidgetsTypes.widget1x2;
			newBlock.id = Global.makeId(6);

			return newBlock;
		}

		return block;
	};

	widgetPlayerPredictionsTransform = (block: any) => {
		if (block.data) {
			const match = block.data.widget && block.data.widget.match ? block.data.widget.match : null;
			const market = block.data.widget && block.data.widget.market ? block.data.widget.market : null;
			const player = block.data.widget && block.data.widget.player ? block.data.widget.player : null;
			const teamAgainst = block.data.widget && block.data.widget.teamAgainst ? block.data.widget.teamAgainst : null;

			const widgetPlayerPredictionsModel = FansUnitedWidgetModel.builder()
				.withMatch(match)
				.withMarket(market)
				.withTeamAgainst(teamAgainst)
				.withTeams([])
				.withPlayer(player)
				.build();

			const newBlock: any = {};
			newBlock.type = block.type;
			newBlock.data = {};
			newBlock.data.changeId = Global.makeId(7);
			newBlock.data.widget = { widgetPlayerPredictions: widgetPlayerPredictionsModel };
			newBlock.data.content = block.data.content;
			newBlock.data.config = block.data.config;
			newBlock.data.widget_type = FansUnitedWidgetsTypes.playerPredictions;
			newBlock.id = Global.makeId(6);

			return newBlock;
		}

		return block;
	};

	widgetTeamPredictionsTransform = (block: any) => {
		if (block.data) {
			const match = block.data.widget && block.data.widget.match ? block.data.widget.match : null;
			const market = block.data.widget && block.data.widget.market ? block.data.widget.market : null;

			const widgetTeamPredictionsModel = FansUnitedWidgetModel.builder()
				.withMatch(match)
				.withMarket(market)
				.withTeamAgainst({} as TeamModel)
				.withTeams([])
				.withPlayer({} as PlayerModel)
				.build();

			const newBlock: any = {};
			newBlock.type = block.type;
			newBlock.data = {};
			newBlock.data.changeId = Global.makeId(7);
			newBlock.data.widget = { widgetTeamPredictions: widgetTeamPredictionsModel };
			newBlock.data.content = block.data.content;
			newBlock.data.config = block.data.config;
			newBlock.data.widget_type = FansUnitedWidgetsTypes.teamPredictions;
			newBlock.id = Global.makeId(6);

			return newBlock;
		}

		return block;
	};

	widgetDoubleChanceTransform = (block: any) => {
		if (block.data) {
			const match = block.data.widget && block.data.widget.match ? block.data.widget.match : null;

			const widgetDoubleChanceModel = FansUnitedWidgetModel.builder()
				.withMatch(match)
				.withMarket('')
				.withTeamAgainst({} as TeamModel)
				.withTeams([])
				.withPlayer({} as PlayerModel)
				.build();

			const newBlock: any = {};
			newBlock.type = block.type;
			newBlock.data = {};
			newBlock.data.changeId = Global.makeId(7);
			newBlock.data.widget = { doubleChance: widgetDoubleChanceModel };
			newBlock.data.content = block.data.content;
			newBlock.data.config = block.data.config;
			newBlock.data.widget_type = FansUnitedWidgetsTypes.doubleChance;
			newBlock.id = Global.makeId(6);

			return newBlock;
		}

		return block;
	};

	widgetTopXAndMatchQuizTransform = (block: any, type: string) => {
		if (block.data) {
			const game = block.data.widget && block.data.widget.game ? block.data.widget.game : null;

			const widgetModel = FansUnitedWidgetModel.builder()
				.withMatch({} as MatchModel)
				.withGame(game)
				.withMarket('')
				.withTeamAgainst({} as TeamModel)
				.withTeams([])
				.withPlayer({} as PlayerModel)
				.build();

			const newBlock: any = {};
			newBlock.type = block.type;
			newBlock.data = {};
			newBlock.data.changeId = Global.makeId(7);
			newBlock.data.widget = { widgetModel };
			newBlock.data.widget_type = type;
			newBlock.id = Global.makeId(6);

			return newBlock;
		}

		return block;
	};

	widgetLeaderboardTransform = (block: any) => {
		if (block.data) {
			const template = block.data.widget && block.data.widget.template ? block.data.widget.template : null;

			const widgetLeaderboardModel = FansUnitedWidgetModel.builder()
				.withTemplate(template)
				.withMatch({} as MatchModel)
				.withGame({} as FansUnitedGameModel)
				.withMarket('')
				.withTeamAgainst({} as TeamModel)
				.withTeams([])
				.withPlayer({} as PlayerModel)
				.build();

			const newBlock: any = {};
			newBlock.type = block.type;
			newBlock.data = {};
			newBlock.data.changeId = Global.makeId(7);
			newBlock.data.widget = { leaderboard: widgetLeaderboardModel };
			newBlock.data.widget_type = FansUnitedWidgetsTypes.leaderboard;
			newBlock.id = Global.makeId(6);

			return newBlock;
		}

		return block;
	};

	widgetCorrectScoreTransform = (block: any) => {
		if (block.data) {
			const match = block.data.widget && block.data.widget.match ? block.data.widget.match : null;

			const widgetCorrectScoreModel = FansUnitedWidgetModel.builder()
				.withMatch(match)
				.withMarket('')
				.withTeamAgainst({} as TeamModel)
				.withTeams([])
				.withPlayer({} as PlayerModel)
				.build();

			const newBlock: any = {};
			newBlock.type = block.type;
			newBlock.data = {};
			newBlock.data.changeId = Global.makeId(7);
			newBlock.data.widget = { correctScore: widgetCorrectScoreModel };
			newBlock.data.content = block.data.content;
			newBlock.data.config = block.data.config;
			newBlock.data.widget_type = FansUnitedWidgetsTypes.correctScore;
			newBlock.id = Global.makeId(6);

			return newBlock;
		}

		return block;
	};

	widgetHtFTransform = (block: any) => {
		if (block.data) {
			const match = block.data.widget && block.data.widget.match ? block.data.widget.match : null;

			const widgetHtFtModel = FansUnitedWidgetModel.builder()
				.withMatch(match)
				.withMarket('')
				.withTeamAgainst({} as TeamModel)
				.withTeams([])
				.withPlayer({} as PlayerModel)
				.build();

			const newBlock: any = {};
			newBlock.type = block.type;
			newBlock.data = {};
			newBlock.data.changeId = Global.makeId(7);
			newBlock.data.widget = { htFt: widgetHtFtModel };
			newBlock.data.content = block.data.content;
			newBlock.data.config = block.data.config;
			newBlock.data.widget_type = FansUnitedWidgetsTypes.htFt;
			newBlock.id = Global.makeId(6);

			return newBlock;
		}

		return block;
	};

	oddsTransform = (block: any) => {
		if (block.data.preview) {
			const newBlock: any = {};
			const bookmakers =
				block.data.preview.selectedBookmakers && block.data.preview.selectedBookmakers.length > 0
					? block.data.preview.selectedBookmakers.map((bookmaker: any) => BookmakerModel.builder(bookmaker).build())
					: null;

			const team = block.data.preview.team
				? TeamModel.builder(block.data.preview.team).withLogo(block.data.preview.team.logo).build()
				: TeamModel.builder().build();

			const match = block.data.preview.match ? MatchModel.builder(block.data.preview.match).build() : MatchModel.builder().build();

			const oddsWidgetModel = OddsWidgetModel.builder()
				.withBookmakers(bookmakers)
				.withCanSelectMarkets(block.data.preview.properties && block.data.preview.properties.canSelectMarkets ? true : false)
				.withDisplayHeader(block.data.preview.properties && block.data.preview.properties.displayMatchHeader ? true : false)
				.withMarket(block.data.preview.properties && block.data.preview.properties.market ? block.data.preview.properties.market : null)
				.withMatch(match)
				.withTeam(team)
				.build();

			newBlock.type = block.type;
			newBlock.data = {};
			newBlock.data.changeId = Global.makeId(7);
			newBlock.data.widget_type = WidgetTypes.odds;
			newBlock.data.content = block.data.content;
			newBlock.data.config = block.data.config;

			newBlock.data.preview = { odds: oddsWidgetModel };
			newBlock.id = Global.makeId(6);

			return newBlock;
		} else {
			return block;
		}
	};

	teamPlayersTransform = (block: any) => {
		if (block.data.preview) {
			const injuredPlayers =
				block.data.preview.injuredPlayers && block.data.preview.injuredPlayers.length > 0
					? block.data.preview.injuredPlayers.map((player: any) =>
							TeamPlayerModel.builder()
								.withId(player.player.id)
								.withLogo(player.player.url_thumb ? player.player.url_thumb : assetsPlaceholder.player)
								.withName(player.player.name)
								.withBirthDate(player.player.birthdate)
								.withActive(player.player.active)
								.withPosition(player.player.position)
								.build(),
					  )
					: [];

			const suspendedPlayers =
				block.data.preview.suspendedPlayers && block.data.preview.suspendedPlayers.length > 0
					? block.data.preview.suspendedPlayers.map((player: any) =>
							TeamPlayerModel.builder()
								.withId(player.player.id)
								.withLogo(player.player.url_thumb ? player.player.url_thumb : assetsPlaceholder.player)
								.withName(player.player.name)
								.withBirthDate(player.player.birthdate)
								.withActive(player.player.active)
								.withPosition(player.player.position)
								.build(),
					  )
					: [];

			const team = block.data.preview.team
				? TeamModel.builder()
						.withId(block.data.preview.team.id)
						.withLogo(block.data.preview.team.logo ? block.data.preview.team.logo : assetsPlaceholder.team)
						.withName(block.data.preview.team.name)
						.withType(block.data.preview.team.type)
						.build()
				: TeamModel.builder().build();

			const teamPlayers = TeamPlayersWidgetModel.builder()
				.withDisplayUnknown(block.data.preview.properties ? block.data.preview.properties.displayUnknown : false)
				.withDisplayDefenders(block.data.preview.properties ? block.data.preview.properties.displayDefenders : false)
				.withDisplayForwards(block.data.preview.properties ? block.data.preview.properties.displayForwards : false)
				.withDisplayGoalkeepers(block.data.preview.properties ? block.data.preview.properties.displayGoalkeepers : false)
				.withDisplayMidfielders(block.data.preview.properties ? block.data.preview.properties.displayMidfielders : false)
				.withInjuredPlayers(injuredPlayers)
				.withSuspendedPlayers(suspendedPlayers)
				.withTeam(team)
				.build();

			const newBlock: any = {};
			newBlock.data = {};
			newBlock.type = block.type;
			newBlock.data.changeId = Global.makeId(7);
			newBlock.data.preview = { teamPlayers };
			newBlock.data.content = block.data.content;
			newBlock.data.config = block.data.config;
			newBlock.data.widget_type = WidgetTypes.teamPlayers;
			newBlock.id = Global.makeId(6);

			return newBlock;
		}

		return block;
	};

	matchTransform = (block: any) => {
		if (block.data.preview) {
			const bookmakers =
				block.data.preview.selectedBookmakers && block.data.preview.selectedBookmakers.length > 0
					? block.data.preview.selectedBookmakers.map((bookmaker: any) => BookmakerModel.builder(bookmaker).build())
					: null;

			const team = block.data.preview.team ? TeamModel.builder(block.data.preview.team).build() : TeamModel.builder().build();

			const matchWidgetModel = MatchWidgetModel.builder()
				.withActiveTab(block.data.preview.properties ? block.data.preview.properties.activeTab : '')
				.withDisplayOdds(block.data.preview.displayOdds ? block.data.preview.displayOdds : false)
				.withMatch(block.data.preview.match ? MatchModel.builder(block.data.preview.match).build() : MatchModel.builder().build())
				.withTeams([team])
				.build();

			const oddsWidgetModel = OddsWidgetModel.builder()
				.withBookmakers(bookmakers)
				.withCanSelectMarkets(block.data.preview.canSelectMarkets ? block.data.preview.canSelectMarkets : false)
				.withDisplayHeader(block.data.preview.displayMatchHeader ? block.data.preview.displayMatchHeader : false)
				.withMainBookmaker(
					block.data.preview.mainBookmaker ? BookmakerModel.builder(block.data.preview.mainBookmaker).build() : BookmakerModel.builder().build(),
				)
				.withMarket(block.data.preview.market ? block.data.preview.market : null)
				.withMatch(block.data.preview.match ? MatchModel.builder(block.data.preview.match).build() : MatchModel.builder().build())
				.withTeam(team)
				.build();

			const newBlock: any = {};
			newBlock.type = block.type;
			newBlock.data = {};
			newBlock.data.changeId = Global.makeId(7);
			newBlock.data.preview = { match: matchWidgetModel, odds: oddsWidgetModel };
			newBlock.data.content = block.data.content;
			newBlock.data.config = block.data.config;
			newBlock.data.widget_type = WidgetTypes.match;
			newBlock.id = Global.makeId(6);

			return newBlock;
		}

		return block;
	};

	topScorersTransform = (block: any) => {
		if (block.data.preview) {
			const tournament = block.data.preview.tournament
				? TournamentModel.builder(block.data.preview.tournament).withSeason(block.data.preview.tournament.stage).build()
				: TournamentModel.builder().build();
			const topScorers =
				block.data.preview.properties && block.data.preview.properties.topScorers && block.data.preview.properties.topScorers.length > 0
					? block.data.preview.properties.topScorers.map((topScorer: any) =>
							PlayerModel.builder(topScorer.player).withLogo(topScorer.player.url_thumb).build(),
					  )
					: [];
			const selections =
				block.data.preview.properties && block.data.preview.properties.selections ? block.data.preview.properties.selections : [];
			const topScorersModel = TopScorersWidgetModel.builder()
				.withTournament(tournament)
				.withSelections(selections)
				.withTopScorers(topScorers)
				.build();

			const newBlock: any = {};
			newBlock.type = block.type;
			newBlock.data = {};
			newBlock.data.changeId = Global.makeId(7);
			newBlock.data.preview = { topScorers: topScorersModel };
			newBlock.data.content = block.data.content;
			newBlock.data.config = block.data.config;
			newBlock.data.widget_type = WidgetTypes.topScorers;
			newBlock.id = Global.makeId(6);

			return newBlock;
		}

		return block;
	};

	standingsTransform = (block: any) => {
		if (block.data.preview) {
			const tournament = block.data.preview.tournament
				? TournamentModel.builder(block.data.preview.tournament).withSeason(block.data.preview.tournament.season).build()
				: TournamentModel.builder().build();

			const season =
				block.data.preview.tournament && block.data.preview.tournament.season
					? SeasonModel.builder(block.data.preview.tournament.season).build()
					: SeasonModel.builder().build();
			const stage =
				season.id !== undefined ? StageModel.builder(block.data.preview.tournament.season.stage).build() : StageModel.builder().build();
			const standings = stage.id !== undefined ? block.data.preview.tournament.season.stage.standings : [];

			const standingsModel = StandingsWidgetModel.builder()
				.withTournament(tournament)
				.withSeason(season)
				.withStage(stage)
				.withStandings(standings)
				.withLimit(block.data.preview.limit ? block.data.preview.limit : 0)
				.withStartFrom(block.data.preview.offset ? block.data.preview.offset : 0)
				.build();

			const newBlock: any = {};
			newBlock.type = block.type;
			newBlock.data = {};
			newBlock.data.changeId = Global.makeId(7);
			newBlock.data.preview = { standings: standingsModel };
			newBlock.data.content = block.data.content;
			newBlock.data.config = block.data.config;
			newBlock.data.widget_type = WidgetTypes.standings;
			newBlock.id = Global.makeId(6);

			return newBlock;
		}

		return block;
	};

	playerH2HTransform = (block: any) => {
		if (block.data.preview) {
			const hasModelProperties = block.data.preview.properties ? true : false;

			const playerOne =
				hasModelProperties && block.data.preview.properties.player1
					? TeamPlayerModel.builder(block.data.preview.properties.player1).build()
					: TeamPlayerModel.builder().build();

			const playerTwo =
				hasModelProperties && block.data.preview.properties.player2
					? TeamPlayerModel.builder(block.data.preview.properties.player2).build()
					: TeamPlayerModel.builder().build();

			const playerOneSeason =
				hasModelProperties && block.data.preview.properties.player1Seasons && block.data.preview.properties.player1Seasons.length > 0
					? block.data.preview.properties.player1Seasons
					: [];

			const playerTwoSeason =
				hasModelProperties && block.data.preview.properties.player2Seasons && block.data.preview.properties.player2Seasons.length > 0
					? block.data.preview.properties.player2Seasons
					: [];

			const playerH2H = PlayerH2hModel.builder()
				.withDisplayOdds(hasModelProperties && block.data.preview.properties.displayOdds ? true : false)
				.withPlayerOne(playerOne)
				.withPlayerTwo(playerTwo)
				.withPlayerOneSeason(playerOneSeason)
				.withPlayerTwoSeason(playerTwoSeason)
				.build();

			const bookmakers =
				hasModelProperties && block.data.preview.properties.bookmakers && block.data.preview.properties.bookmakers.length > 0
					? block.data.preview.properties.bookmakers.map((bookmaker: any) => BookmakerModel.builder(bookmaker).build())
					: null;

			const team = block.data.preview.team
				? TeamModel.builder(block.data.preview.team).withLogo(block.data.preview.team.logo).build()
				: TeamModel.builder().build();

			const oddsWidgetModel = OddsWidgetModel.builder()
				.withBookmakers(bookmakers)
				.withCanSelectMarkets(
					hasModelProperties && block.data.preview.properties.canSelectMarkets ? block.data.preview.properties.canSelectMarkets : false,
				)
				.withDisplayHeader(
					hasModelProperties && block.data.preview.properties.displayMatchHeader ? block.data.preview.properties.displayMatchHeader : false,
				)
				.withMarket(hasModelProperties && block.data.preview.properties.market ? block.data.preview.properties.market : null)
				.withMatch(block.data.preview.match ? MatchModel.builder(block.data.preview.match).build() : MatchModel.builder().build())
				.withTeam(team)
				.build();

			const newBlock: any = {};
			newBlock.type = block.type;
			newBlock.data = {};
			newBlock.data.changeId = Global.makeId(7);
			newBlock.data.preview = { playerH2H, odds: oddsWidgetModel };
			newBlock.data.content = block.data.content;
			newBlock.data.config = block.data.config;
			newBlock.data.widget_type = WidgetTypes.widgetPlayerH2H;
			newBlock.id = Global.makeId(6);

			return newBlock;
		}

		return block;
	};

	teamComparisonTransform = (block: any) => {
		if (block.data.preview) {
			const hasModelOdds = block.data.preview.odds ? true : false;

			const teamOne = block.data.preview.team1
				? TeamModel.builder(block.data.preview.team1)
						.withLogo(block.data.preview.team1.logo)
						.withTournament(block.data.preview.team1.tournament)
						.build()
				: TeamModel.builder().build();

			const teamTwo = block.data.preview.team2
				? TeamModel.builder(block.data.preview.team2)
						.withLogo(block.data.preview.team2.logo)
						.withTournament(block.data.preview.team2.tournament)
						.build()
				: TeamModel.builder().build();

			const teamComparison = TeamComparisonModel.builder()
				.withDisplayOdds(hasModelOdds && block.data.preview.odds.displayOdds ? true : false)
				.withStatFields(block.data.preview.statFields && block.data.preview.statFields.length > 0 ? block.data.preview.statFields : [])
				.withTeamOne(teamOne)
				.withTeamTwo(teamTwo)
				.build();

			const bookmakers =
				hasModelOdds && block.data.preview.odds.bookmakers && block.data.preview.odds.bookmakers.length > 0
					? block.data.preview.odds.bookmakers.map((bookmaker: any) => BookmakerModel.builder(bookmaker).build())
					: null;

			const oddsWidgetModel = OddsWidgetModel.builder()
				.withBookmakers(bookmakers)
				.withCanSelectMarkets(hasModelOdds && block.data.preview.odds.canSelectMarkets ? block.data.preview.odds.canSelectMarkets : false)
				.withDisplayHeader(hasModelOdds && block.data.preview.odds.displayMatchHeader ? block.data.preview.odds.displayMatchHeader : false)
				.withMarket(hasModelOdds && block.data.preview.odds.market ? block.data.preview.odds.market : null)
				.withMatch(
					hasModelOdds && block.data.preview.odds.match
						? MatchModel.builder(block.data.preview.odds.match).build()
						: MatchModel.builder().build(),
				)
				.withTeam(
					hasModelOdds && block.data.preview.odds.team
						? TeamModel.builder(block.data.preview.odds.team).withLogo(block.data.preview.odds.team.logo).build()
						: TeamModel.builder().build(),
				)
				.build();

			const newBlock: any = {};
			newBlock.type = block.type;
			newBlock.data = {};
			newBlock.data.changeId = Global.makeId(7);
			newBlock.data.preview = { teamComparison, odds: oddsWidgetModel };
			newBlock.data.content = block.data.content;
			newBlock.data.config = block.data.config;
			newBlock.data.widget_type = WidgetTypes.widgetTeamComparison;
			newBlock.id = Global.makeId(6);

			return newBlock;
		}

		return block;
	};

	playerProfileTransform = (block: any) => {
		if (block.data.preview) {
			const bookmakers =
				block.data.preview.selectedBookmakers && block.data.preview.selectedBookmakers.length > 0
					? [BookmakerModel.builder(block.data.preview.selectedBookmakers[0]).build()]
					: [];

			const oddsWidgetModel = OddsWidgetModel.builder()
				.withBookmakers(bookmakers)
				.withCanSelectMarkets(block.data.preview.canSelectMarkets ? block.data.preview.canSelectMarkets : false)
				.withMarket(block.data.preview.properties && block.data.preview.properties.market ? block.data.preview.properties.market : null)
				.withMatch(MatchModel.builder(block.data.preview.match).build())
				.withTeam(
					TeamModel.builder(block.data.preview.team)
						.withLogo(block.data.preview.team && block.data.preview.team.url_logo)
						.build(),
				)
				.build();

			const playerProfile = PlayerProfileWidgetModel.builder()
				.withPlayer(block.data.preview.player)
				.withDisplayOdds(block.data.preview.properties && block.data.preview.properties.displayOdds)
				.withDisplayStatistics(block.data.preview.properties && block.data.preview.properties.displayStatistics)
				.withTeamForOdds(block.data.preview.teamForOdds)
				.withTeam(block.data.preview.team)
				.build();

			const newBlock: any = {};
			newBlock.type = block.type;
			newBlock.data = {};
			newBlock.data.changeId = Global.makeId(7);
			newBlock.data.preview = { playerProfile, odds: oddsWidgetModel };
			newBlock.data.content = block.data.content;
			newBlock.data.config = block.data.config;
			newBlock.data.widget_type = WidgetTypes.playerProfile;
			newBlock.id = Global.makeId(6);

			return newBlock;
		}

		return block;
	};

	contentTransform = (block: any, type: string) => {
		if (block.data.id) {
			const newBlock: any = {};
			newBlock.type = block.type;
			newBlock.data = {};
			newBlock.data.changeId = Global.makeId(7);
			newBlock.data.id = block.data.id;
			newBlock.data.preview = { previewContent: block.data.preview };
			newBlock.id = Global.makeId(6);

			newBlock.data.widget_type = ContentTypes[type];

			return newBlock;
		}

		return createEmptyContentBlock(ContentTypes[type]);
	};

	imageTransform = (block: any) => {
		if (block.data.id && block.data.preview) {
			const imageModel = ImageBlockModel.builder()
				.withDescription(block.data.preview.description ? block.data.preview.description : null)
				.withAlignment(block.data.preview.alignment)
				.withImage(Image.builder().withId(block.data.id).build())
				.withWidth(block.data.preview.width)
				.build();

			const newBlock: any = {};
			newBlock.type = block.type;
			newBlock.data = {};
			newBlock.data.changeId = Global.makeId(7);
			newBlock.data.id = block.data.id;
			newBlock.data.description = block.data.description;
			newBlock.data.preview = { imageBlock: imageModel };
			newBlock.id = Global.makeId(6);

			newBlock.data.widget_type = ContentTypes.image;

			return newBlock;
		}

		return createEmptyContentBlock(ContentTypes.image);
	};

	fixturesTransform = (block: any) => {
		if (block.data.preview) {
			const team = block.data.preview.team
				? TeamModel.builder(block.data.preview.team).withLogo(block.data.preview.team.logo).build()
				: TeamModel.builder().build();

			const matches =
				block.data.preview.matches && block.data.preview.matches.length > 0
					? block.data.preview.matches.map((match: any) => MatchModel.builder(match).build())
					: [];

			const fixtures = FixturesModel.builder()
				.withDisplayOdds(block.data.config.options.displayOdds ? block.data.config.options.displayOdds : false)
				.withMatches(matches)
				.withTeam(team)
				.withTitle(block.data.preview.title ? block.data.preview.title : '')
				.withTournament(
					TournamentModel.builder()
						.withId(block.data.preview.tournament && block.data.preview.tournament.id)
						.withLogo(block.data.preview.tournament && block.data.preview.tournament.url_logo)
						.withName(block.data.preview.tournament && block.data.preview.tournament.name)
						.withSeason(block.data.preview.tournament && block.data.preview.tournament.season)
						.build(),
				)
				.withStage(StageModel.builder(block.data.preview.stage).build())
				.withRound(RoundModel.builder(block.data.preview.round).build())
				.withNoData(block.data.preview.noData)
				.build();

			const oddsWidgetModel = OddsWidgetModel.builder()
				.withBookmakers(block.data.preview.bookmaker ? [BookmakerModel.builder(block.data.preview.bookmaker).build()] : [])
				.build();

			const newBlock: any = {};
			newBlock.type = block.type;
			newBlock.data = {};
			newBlock.data.changeId = Global.makeId(7);
			newBlock.data.preview = { fixtures, odds: oddsWidgetModel };
			newBlock.data.preview.fixtures.upcomingMatchesAreSelected = block.data.preview.upcomingMatchesAreSelected
				? block.data.preview.upcomingMatchesAreSelected
				: false;
			newBlock.data.preview.fixtures.finishedMatchesAreSelected = block.data.preview.finishedMatchesAreSelected
				? block.data.preview.finishedMatchesAreSelected
				: false;
			newBlock.data.content = block.data.content;
			newBlock.data.config = block.data.config;
			newBlock.data.widget_type = WidgetTypes.fixtures;
			newBlock.id = Global.makeId(6);

			return newBlock;
		}

		return block;
	};

	embedTransform = (block: any) => {
		if (!block.data.changeId) {
			const embed = EmbedBlockModel.builder()
				.withContent(block.data.content)
				.withType(block.data.embed_type ? block.data.embed_type : 'other')
				.build();

			const newBlock: any = {};
			newBlock.type = block.type;
			newBlock.data = {};
			newBlock.data.changeId = Global.makeId(7);
			newBlock.data.content = block.data.content;
			newBlock.data.preview = { embedBlock: embed };
			newBlock.data.embed_type = block.data.embed_type;
			newBlock.id = Global.makeId(6);

			return newBlock;
		} else {
			if (block.data.changeId) {
				return block;
			} else {
				return createEmptyContentBlock(ContentTypes.embed);
			}
		}
	};

	highlightTransform = (block: any) => {
		if (!block.data.changeId) {
			const highlight = HighlightBlockModel.builder().withDescription(block.data.description).withTitle(block.data.title).build();

			const newBlock: any = {};
			newBlock.type = block.type;
			newBlock.data = {};
			newBlock.data.changeId = Global.makeId(7);
			newBlock.data.title = block.data.title;
			newBlock.data.description = block.data.description;
			newBlock.data.preview = { highlightBlock: highlight };
			newBlock.id = Global.makeId(6);

			return newBlock;
		} else {
			if (block.data.changeId) {
				return block;
			} else {
				return createEmptyContentBlock(ContentTypes.highlight);
			}
		}
	};

	linkTransform = (block: any) => {
		if (!block.data.changeId) {
			const link = LinkBlockModel.builder()
				.withContent(block.data.content)
				.withLink(block.data.link)
				.withLinkType(block.data.linkType)
				.withOpenType(block.data.open_type)
				.withText(block.data.text)
				.build();

			const newBlock: any = {};
			newBlock.type = block.type;
			newBlock.data = block.data;
			newBlock.data.changeId = Global.makeId(7);
			newBlock.data.id = block.data.id;
			newBlock.data.preview = { linkBlock: link };
			newBlock.id = Global.makeId(6);

			return newBlock;
		} else {
			if (block.data.changeId) {
				return block;
			} else {
				return createEmptyContentBlock(ContentTypes.link);
			}
		}
	};

	bannerTransform = (block: any) => {
		if (!block.data.changeId) {
			const banner = BannerBlockModel.builder().withId(block.data.id).build();

			const newBlock: any = {};
			newBlock.type = block.type;
			newBlock.data = {};
			newBlock.data.changeId = Global.makeId(7);
			newBlock.data.id = block.data.id;
			newBlock.data.preview = { bannerBlock: banner };
			newBlock.id = Global.makeId(6);

			return newBlock;
		} else {
			if (block.data.changeId) {
				return block;
			} else {
				return createEmptyContentBlock(ContentTypes.banner);
			}
		}
	};

	galleryTransform = (block: any) => {
		if (!block.data.changeId && block.data.preview) {
			const gallery = GalleryBlockModel.builder()
				.withId(block.data.id)
				.withMainImage({
					id: block.data.main_image_id,
					urls: { uploaded: { gallery: block.data.preview ? block.data.preview.mainImageUrl : '' } },
				})
				.withTitle(block.data.preview ? block.data.preview.title : '')
				.withAlignment(block.data.preview ? block.data.preview.alignment : '')
				.withWidth(block.data.preview ? block.data.preview.width : '')
				.build();

			const newBlock: any = {};
			newBlock.type = block.type;
			newBlock.data = {};
			newBlock.data.id = block.data.id;
			newBlock.data.preview = { gallery };
			newBlock.data.main_image_id = block.data.main_image_id;
			newBlock.id = Global.makeId(6);
			newBlock.data.changeId = Global.makeId(7);

			return newBlock;
		}

		return block;
	};
}
