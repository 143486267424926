import * as React from 'react';
import * as ReactSortableHOC from 'react-sortable-hoc';
import GallerySortableItem from './GallerySortableItem';
import GalleryItem from '../../../../../models/gallery/item/GalleryItem';
import { selectItemCondition, ctrlClickKeys } from '../helpers/gallery-items.helpers';
import ModalContainer from '../../../../Partials/BaseComponents/ListingComponent/subcomponents/Modal/ModalContainer';
import * as uiActions from '../../../../../store/action-creators/UiActionCreator';
import { connect } from 'react-redux';
import { ContentToDelete } from '../../../../Partials/BaseComponents/ListingComponent/properties/ComponentProps';
import LazyLoad from 'react-lazyload';

type ContentSortableListProps = {
	items: GalleryItem[];
	t: any;
	onDelete: (index: number) => any;
	onItemUpdate: (item: GalleryItem, index: number) => any;
	onEdit: (imageId: string) => any;
	onCrop: (imageId: string) => any;
	onImageClick: (image: any) => any;
	toggleItemsForUpdate: (itemId: string) => any;
	selectedItems: any[];
	isToggledModal: boolean;
	analytics: any;
	dispatch: (action: any) => void;
};

type State = {
	itemToDelete: ContentToDelete;
	itemToDeleteIndex: number;
};

const SortableItem = ReactSortableHOC.SortableElement(GallerySortableItem);

class GalleryItemsSortableList extends React.Component<ContentSortableListProps, State> {
	constructor(props: ContentSortableListProps) {
		super(props);
		this.state = {
			itemToDelete: { id: '', title: '' },
			itemToDeleteIndex: 0,
		};
	}

	onContentDelete = () => {
		const { itemToDelete } = this.state;
		this.deleteItem(itemToDelete.id);
	};

	deleteItem = (itemId: string) => {
		this.selectedItem(itemId) && this.props.toggleItemsForUpdate(itemId);
		this.onDelete(this.state.itemToDeleteIndex);
		this.props.dispatch(uiActions.toggleModal(false));
	};

	toggleModal = (item: GalleryItem, index: number) => {
		this.setState({ ...this.state, itemToDelete: { id: item.data.id, title: this.props.t('image') }, itemToDeleteIndex: index });
		this.props.dispatch(uiActions.toggleModal(true));
	};

	onDelete(index: number) {
		this.props.onDelete(index);
	}

	onItemUpdate(item: GalleryItem, index: number) {
		this.props.onItemUpdate(item, index);
	}

	addImageForAction = (e: any, item: GalleryItem) => {
		e.stopPropagation();

		if (selectItemCondition(e, ctrlClickKeys)) {
			this.props.toggleItemsForUpdate(item.data.id);
		}
	};

	selectedItem = (id: string) => this.props.selectedItems.includes(id);

	render(): JSX.Element {
		const { items, t, analytics } = this.props;

		return (
			<>
				<ul className='list-group'>
					{items.map((item: GalleryItem, index: number) => {
						return (
							<SortableItem
								t={t}
								item={item}
								index={index}
								addImageForAction={this.addImageForAction}
								toggleItemsForUpdate={this.props.toggleItemsForUpdate}
								selected={this.selectedItem(item.data.id)}
								key={`key-index--key-id:${item.data.id}`}
								onEdit={this.props.onEdit}
								onImageClick={this.props.onImageClick}
								onCrop={this.props.onCrop}
								onItemUpdate={(item: GalleryItem) => this.onItemUpdate(item, index)}
								toggleModal={(item: GalleryItem) => this.toggleModal(item, index)}
								displayDescriptionCheckbox={true}
							/>
						);
					})}
				</ul>
				<ModalContainer
					t={t}
					action='remove'
					analytics={analytics}
					contentToDelete={this.state.itemToDelete}
					onDeleteContent={this.onContentDelete}
					isToggledModal={this.props.isToggledModal}
				/>
			</>
		);
	}
}

function mapStateToProps(state: any) {
	return {
		isToggledModal: state.ui.isToggleModal,
	};
}

function mapDispatchToProps(dispatch: any) {
	return {
		dispatch: (action: any) => dispatch(action),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(GalleryItemsSortableList);
