import React from 'react';
import { compose } from 'redux';
import { SportBlockTypes, SportTypes, WidgetScriptTypes } from '../../../../../../../constants/sport.types';
import { ViewTypes } from '../../../../constants/general.constants';
import BlockModel from '../../../../models/block.model';
import BlockUpdateController from '../../../block-update-controller.component';
import { MonorepoWidgetPreview } from '../../widget-preview/monorepo-widget-preview.component';
import LivescoreWidgetModel from '../models/livescore-widget.model';
import BasketballLivescoreWidgetEdit from './basketball-livescore-block-edit.component';
import { BasketballLivescoreWidgetView } from './basketball-livescore-block-view.component';

type Properties = {
	block: BlockModel;
	view: ViewTypes;
	t: any;
	contentData: any;
	contentLanguage: string;
};

const BasketballLivescoreWidget: React.FunctionComponent<Properties> = (props) => {
	const { view, t, block, contentData, contentLanguage } = props;

	if (view === ViewTypes.edit) {
		return <BasketballLivescoreWidgetEdit block={block} t={t} contentData={contentData} contentLanguage={contentLanguage} />;
	}

	if (view === ViewTypes.preview) {
		return (
			<MonorepoWidgetPreview
				blockContent={props.block.data.content}
				config={props.block.data.config}
				type={WidgetScriptTypes.BASKETBALL_WIDGETS}
				blockType={SportBlockTypes.TOURNAMENT_BLOCK}
				sport={SportTypes.BASKETBALL}
			/>
		);
	}

	if (view === ViewTypes.normal) {
		return (
			<BasketballLivescoreWidgetView
				t={props.t}
				livescorePreview={
					props.block.data.preview && props.block.data.preview.widgetModel
						? props.block.data.preview.widgetModel
						: LivescoreWidgetModel.builder().build()
				}
			/>
		);
	}

	return null;
};

export default compose(BlockUpdateController)(BasketballLivescoreWidget);
