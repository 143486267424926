import 'reflect-metadata';
import { jsonArrayMember, jsonMember, jsonObject } from 'typedjson';

@jsonObject
export default class LiveBlogSeoModel {
	@jsonMember(String)
	public title: string | null = null;
	@jsonMember(String)
	public description: string | null = null;
	@jsonMember(String)
	public slug: string | null = null;
	@jsonArrayMember(String)
	public keywords: string[] | null = null;
	@jsonMember(Boolean)
	public index: boolean | null = null;
	@jsonMember(Boolean)
	public follow: boolean | null = null;
	@jsonMember(String)
	public redirect_type: string | null = null;
	@jsonMember(String)
	public jsonld: string | null = null;
}
