import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import ContentWidget from '../../../../../Partials/Blocky/blocks/content/content-block/models/content-widget.model';
import { ViewTypes } from '../../../../../Partials/Blocky/constants/general.constants';
import ContentBlocksGenerator from '../../../../../Partials/Blocky/generators/content-blocks.generator';
import WidgetBlocksV2Generator from '../../../../../Partials/Blocky/generators/widget-blocks-v2.generator';
import WidgetBlocksGenerator from '../../../../../Partials/Blocky/generators/widget-blocks.generator';
import BlockModel from '../../../../../Partials/Blocky/models/block.model';

interface ContentBlockEditProps {
	block: BlockModel;
	content: ContentWidget;
	contentLanguage: string;
	entityType?: string;
}

const ContentBlockEdit: FC<ContentBlockEditProps> = ({ block, content, contentLanguage, entityType }) => {
	const contentBlocksGenerator = useMemo(() => new ContentBlocksGenerator(), []);
	const widgetBlocksGenerator = useMemo(() => new WidgetBlocksGenerator(), []);
	const widgetBlocksV2Generator = useMemo(() => new WidgetBlocksV2Generator(), []);
	const [t] = useTranslation();

	switch (block.type) {
		case 'widget_smp':
			return widgetBlocksGenerator.createBlock(block, t, content, contentLanguage, ViewTypes.edit);
		case 'widget_smp_V2':
			return widgetBlocksV2Generator.createBlock(block, t, content, contentLanguage, ViewTypes.edit);
		default:
			return contentBlocksGenerator.createBlock(block, t, content, undefined, contentLanguage, entityType, undefined, ViewTypes.edit);
	}
};

export default ContentBlockEdit;
