export default class ContentTypeMapper {
	public modelToOption = (model: any, t: any) => {
		if (model) {
			return {
				value: model.value,
				label: t(model.label),
				data: model,
			};
		}

		// just for TS warning handle
		return {
			value: null,
			label: null,
			data: null,
		};
	};
}
