import { Observable, Subject } from 'rxjs';
import SentryService from '../sentry/sentry';

export default class ErrorService {
	private errorSubj: Subject<any> = {} as Subject<any>;

	constructor() {
		this.errorSubj = new Subject<any>();
	}

	init() {
		window.errorService = this;
	}

	emit(error: any): void {
		SentryService.captureException(error);
		this.errorSubj.next(error);
	}

	onError(): Observable<any> {
		return this.errorSubj.asObservable();
	}
}
