import React, { FunctionComponent, useEffect, useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import LiveBlogModel from '../../../models/live-blog.model';

type ModalDeleteProps = {
	t: any;
	selectedRowForDelete: LiveBlogModel | null;
	setSelectedRowForDelete: Function;
	deleteContent: (id: string) => void;
};

export const ModalDelete: FunctionComponent<ModalDeleteProps> = ({ t, selectedRowForDelete, setSelectedRowForDelete, deleteContent }) => {
	const [modalOpenFlag, setModalOpenFlag] = useState(false);

	useEffect(() => {
		setModalOpenFlag(selectedRowForDelete !== null);
	}, [selectedRowForDelete]);

	const deleteEntity = () => {
		const idForDelete = selectedRowForDelete && selectedRowForDelete.id ? selectedRowForDelete.id : '';
		if (idForDelete) {
			deleteContent(idForDelete);
			closeModal();
		}
	};

	const closeModal = () => setSelectedRowForDelete(null);

	return (
		<Modal isOpen={modalOpenFlag} className='modal-danger'>
			<ModalHeader>{t('delete_noun')}</ModalHeader>
			<ModalBody className='text-break'>
				<strong>{t('attention_modal')}</strong> {t('delete_notice')}
				<strong> {selectedRowForDelete && selectedRowForDelete.title} </strong>
				<br /> {t('continue_confirmation')}
				<br />
			</ModalBody>
			<ModalFooter>
				<Button color='danger' className='mr-auto' onClick={deleteEntity}>
					{t('delete')}
				</Button>
				<Button color='base' onClick={closeModal}>
					{t('cancel')}
				</Button>
			</ModalFooter>
		</Modal>
	);
};
