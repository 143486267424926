import React from 'react';

const types = {
	article: 'fa fa-file-text',
	video: 'fa fa-play',
	gallery: 'fa fa-camera',
	live_blog: 'fa-solid fa-newspaper',
};

export function customOption(option: any) {
	const { label, data } = option;
	if (!label) {
		return null;
	}
	return (
		<div>
			<strong>{label}</strong>{' '}
			<small>
				( <i className={`${types[data.type.toLowerCase()]}`} /> {data.data.title} )
			</small>
		</div>
	);
}
