import SeasonModel from '../season/season.model';
import { TournamentJson } from './tournament.json';
import TournamentBuilder from './tournament.builder';
import Country from '../../../../../../../../models/country/country.model';

export default class TournamentModel {
	readonly id: string;
	readonly name: string;
	readonly logo: string;
	readonly season: SeasonModel;
	readonly country: Country | null;

	private constructor(id: string, name: string, logo: string, season: SeasonModel, country: Country | null) {
		this.id = id;
		this.name = name;
		this.logo = logo;
		this.season = season;
		this.country = country;
	}

	toJson(): TournamentJson {
		return {
			id: this.id,
			name: this.name,
			logo: this.logo,
			season: this.season,
			country: this.country,
		};
	}

	static fromJson(json: TournamentJson): TournamentModel {
		return new TournamentModel(json.id, json.name, json.logo, json.season, json.country);
	}

	static builder(tournament?: TournamentModel): TournamentBuilder {
		return new TournamentBuilder(tournament);
	}
}
