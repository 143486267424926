import React, { FunctionComponent } from 'react';
import { Button, Input, InputGroup, InputGroupAddon } from 'reactstrap';

type Props = {
	t: any;
	onChange: (text: string) => void;
	value: string;
};

export const TournamentSearch: FunctionComponent<Props> = ({ value, onChange, t }) => {
	return (
		<InputGroup>
			<Input
				type='text'
				id='tournament-search-input'
				name='tournament-search-input'
				placeholder={t('search_for_tournaments')}
				onChange={(el) => onChange(el.target.value)}
				value={value}
			/>
			<InputGroupAddon addonType='append'>
				<Button type='button' color='primary' id='content-listing-search-button'>
					<i className={'fa fa-search'}>&nbsp;</i>
					{t('search')}
				</Button>
			</InputGroupAddon>
		</InputGroup>
	);
};
