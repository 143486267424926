import React from 'react';
import Select from 'react-select';
import { Label } from 'reactstrap';
import { marketToOption } from './helpers/sport-markets.helper';

type Properties = {
	onMarketSelect: any;
	value: any;
	t: any;
	markets: any;
	id: string;
};

const SportMarketSelect: React.FunctionComponent<Properties> = ({ value, markets, t, id, onMarketSelect }) => {
	const marketSelect = (selection: any) => {
		if (onMarketSelect && selection && selection.value) {
			return onMarketSelect(selection.value);
		}
		return onMarketSelect('');
	};

	return (
		<>
			<Label htmlFor={id}>{t('choose_default_market')}:</Label>
			<Select
				isClearable
				id={id}
				options={markets}
				onChange={marketSelect}
				value={marketToOption(t, value)}
				noOptionsMessage={(inputValue) => inputValue && t('no_options')}
				placeholder={t('select')}
			/>
		</>
	);
};

export default SportMarketSelect;
