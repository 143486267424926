import React from 'react';
import { Col, Row } from 'reactstrap';
import ContentAttribute from '../../../../../models/v2/content-attributes/content-attributes.model';
import { SelectMenuOptionType } from '../../../../../models/v2/general/select.model';
import { DATA_QA_ATTRIBUTES } from '../constants/data-qa.attributes';
import { extractMainContentTypeBasedOnUrl } from '../../../../../global-helpers/global.helpers';
import { ContentTypes } from '../../../../../constants/content-types';

// ContentAttributes data helper
export const contentAttributesToOptions = (contentAttributes: ContentAttribute[]): SelectMenuOptionType[] => {
	return contentAttributes && contentAttributes.length > 0
		? contentAttributes.map((contentAttribute: ContentAttribute) => returnMappedContentAttribute(contentAttribute))
		: [];
};

export const returnMappedContentAttribute = (contentAttribute: ContentAttribute): SelectMenuOptionType => {
	return {
		value: contentAttribute.slug,
		label: contentAttribute.name,
		data: contentAttribute,
	};
};

export const extractSelectedStringAsContentAttribute = (
	selectedString: string,
	contentAttributes: ContentAttribute[],
): ContentAttribute | null => {
	const selectedStrings = contentAttributes.filter((contentAttribute: ContentAttribute) => selectedString === contentAttribute.slug);
	return selectedStrings && selectedStrings.length > 0 ? selectedStrings[0] : null;
};

export const extractDefaultValue = (contentAttributes: ContentAttribute[]): ContentAttribute | null => {
	const defaultValues = contentAttributes.filter((contentAttribute: ContentAttribute) => contentAttribute.isDefault);
	return defaultValues && defaultValues.length > 0 ? defaultValues[0] : null;
};
// ContentAttributes data helper END

const filterComponentsBeforeWrap = (components: JSX.Element[]) => {
	if (components && components.length > 0 && extractMainContentTypeBasedOnUrl() === ContentTypes.WIKI_PAGE) {
		const excludedComponentsKeysForWiki = [
			DATA_QA_ATTRIBUTES.MAIN_CATEGORY,
			DATA_QA_ATTRIBUTES.ADDITIONAL_CATEGORY,
			DATA_QA_ATTRIBUTES.DISTRIBUTION_REGIONS,
			DATA_QA_ATTRIBUTES.DISTRIBUTION_CHANNELS,
			DATA_QA_ATTRIBUTES.ORIGIN,
			DATA_QA_ATTRIBUTES.COMMENTS_POLICIES,
		];

		return components.filter((component) => !excludedComponentsKeysForWiki.includes(component.key as DATA_QA_ATTRIBUTES));
	}

	return components;
};

const wrapComponentsInRow = (components: JSX.Element[]) => {
	const filteredComponents = filterComponentsBeforeWrap(components);

	return (
		<Row>
			{filteredComponents.map((component) => {
				return <Col key={component.key + '-col'}> {component} </Col>;
			})}
		</Row>
	);
};

export const wrapComponents = (componentsArray: JSX.Element[][]) => {
	return componentsArray.map((components) => wrapComponentsInRow(components));
};
