import React from 'react';
import { Name } from '../fields/name';
import { withRouter } from 'react-router-dom';
import {
	checkIfNextPageIsEntityRelated,
	constructRequestFromEntry,
	isSaveDisabled,
	playerSave,
	remapBlacklistFromResponse,
	SOCIAL_TYPES_METHODS,
	SOCIALS_PLAYER,
} from '../helpers/manual-data.helper';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Button, Col, Row } from 'reactstrap';
import CountrySelect from '../fields/country';
import Social from '../fields/social';
import { Properties, State } from './properties/player-edit.properties';
import Project from '../../../../../models/project/Project';
import SidebarCollapsibleElements from '../../../../Partials/Sidebar/sidebar-collapsible-elements/sidebar-collapsible-elements';
import { ContentTypes } from '../../../../Partials/Blocky/constants/block.types';
import SidebarElementsToggle from '../../../../Partials/Sidebar/sidebar-collapsible-elements/sidebar-elements-toggle/sidebar-elements-toggle.component';
import { actionService } from '../../../../../App';
import Blacklisted from '../blacklisted-container.tsx/blacklisted-container';
import HttpService from '../../../../../services/rest/HttpService';
import BlacklistModal from '../blacklist-modal/blacklist-modal';
import BlacklistModel from '../../../../../models/team-manual-data/blacklist/blacklist';
import {
	PLAYER_ENTITY_POST_ASSETS_SUCCESS,
	PLAYER_ENTITY_RECEIVED,
	PLAYER_ENTITY_REQUEST,
	PLAYER_ENTITY_UPDATE_SUCCESS,
	playerSearchFilterUpdate,
	postPlayerAssets,
	returnObjectForPlayerEntityRequest,
	returnObjectForPlayerEntityUpdate,
} from '../../../../../store/action-creators/player-action-creator';
import PlayerPositionSelect from '../fields/player-position';
import ActiveToggle from '../fields/active';
import BirthCitySelect from '../fields/birth-city';
import AddCityButton from '../fields/add-city-button.component';
import BirthDateSelect from '../fields/birth-date';
import PlayerProfileModel from '../../../../../models/player-manual-data/player-profile/player-profile';
import WeightSelect from '../fields/weight-select';
import moment from 'moment';
import HeightSelect from '../fields/height-select';
import PlayerManualDataModel from '../../../../../models/player-manual-data/player-manual-data.model';
import EnumItem from '../../../../../models/enum/EnumItem';
import AssetsModel from '../../../../../models/team-manual-data/assets/assets';
import { AssetsMediaComponent } from '../assets-media/assets-media.component';
import { removeAssetsFromAssetsModel } from '../assets-media/helpers/assets-image-upload.helper';
import { Subscription } from 'rxjs';
import {
	ContentSidebarChangeCollapseStatus,
	onChangeCollapseStatus,
	onSidebarElemSort,
	toggleSidebarEditMode,
} from '../../../../../services/content-sidebar/content-sidebar.helper';
import { FormattedSidebarSectionValue } from '../../../../Partials/Sidebar/sidebar-collapsible-elements/utils/temp-sidebar.types';
import GlobalModal from '../../../../Partials/Modals/Global/global-modal';
import {
	appendBeforeUnloadResetTempSidebar,
	defaultTempSidebarFromApiResponse,
	overwriteTempSidebarByContentType,
	saveSidebarSettingsByContentType,
} from '../../../../Partials/Sidebar/sidebar-collapsible-elements/utils/temp-sidebar.helper';

class PlayerEditComponent extends React.Component<Properties, State> {
	private registerActionSubscription = new Subscription();

	constructor(props: any) {
		super(props);
		this.state = {
			isActive: props.player.active ? true : false,
			isContentLoading: false,
			isSidebarInEdit: false,
			playerDataEntry: PlayerManualDataModel.builder(props.player).build(),
			assets: AssetsModel.builder(props.assets).build(),
			isOpenModal: false,
			openSidebarSettingsModalFlag: false,
		};
	}

	componentDidMount(): void {
		this.initPageTitle();
		this.registerActionEventListener();
		this.props.getPlayerEntity(!this.props.playerId ? this.props.match.params.id : this.props.playerId, this.props.project);
		appendBeforeUnloadResetTempSidebar(ContentTypes.player);
	}

	componentDidUpdate(prevProps: Readonly<Properties>): void {
		if (!PlayerManualDataModel.builder(prevProps.player).equals(this.props.player)) {
			this.setState({
				...this.state,
				playerDataEntry: this.props.player,
				isActive: this.props.player.active ? true : false,
			});
			this.getBlacklistEntityData(this.props.player);
		}
	}

	registerActionEventListener() {
		this.registerActionSubscription = actionService.onActionReceived().subscribe((action: string) => {
			if (action === PLAYER_ENTITY_REQUEST) this.toggleContentLoadingState(true);

			if (action === PLAYER_ENTITY_RECEIVED) this.toggleContentLoadingState(false);

			if (action === PLAYER_ENTITY_UPDATE_SUCCESS || action === PLAYER_ENTITY_POST_ASSETS_SUCCESS) {
				this.props.getPlayerEntity(!this.props.playerId ? this.props.match.params.id : this.props.playerId, this.props.project);
				this.props.isAddedInModal && this.props.getSquads && this.props.getSquads();
			}
		});
	}

	componentWillUnmount() {
		this.registerActionSubscription.unsubscribe();
		checkIfNextPageIsEntityRelated('players') && this.props.updateSearchFilterPlayer('');
	}

	getBlacklistEntityData = (player: PlayerManualDataModel) => {
		const params = constructRequestFromEntry([player], 'PLAYER', 'ENTITY');
		HttpService.getBlacklist(`/v2/blacklist/search`, params)
			.then((response: any) => {
				const blacklist = response.data;

				if (blacklist && blacklist.length > 0) {
					this.setState({
						...this.state,
						playerDataEntry: PlayerManualDataModel.builder(player).withBlacklist(remapBlacklistFromResponse(blacklist[0])).build(),
					});
				}
			})
			.catch((error: unknown) => console.error(error));
	};

	toggleContentLoadingState = (isLoading: boolean) => this.setState({ ...this.state, isContentLoading: isLoading });

	initPageTitle = () => (document.title = this.props.t('player_edit'));

	onNameChange = (e: any) => {
		this.setState({
			...this.state,
			playerDataEntry: PlayerManualDataModel.builder(this.state.playerDataEntry).withName(e.target.value).build(),
		});
	};

	onCountryChange = (country: any) => {
		this.setState({
			...this.state,
			playerDataEntry: PlayerManualDataModel.builder(this.state.playerDataEntry).withCountry(country).build(),
		});
	};

	onBirthCityChange = (birthCity: EnumItem) => {
		this.setState({
			...this.state,
			playerDataEntry: PlayerManualDataModel.builder(this.state.playerDataEntry).withBirthCity(birthCity).build(),
		});
	};

	onBirthDateChange = (date: any) => {
		const localTimezone = moment.tz.guess();

		this.setState({
			...this.state,
			playerDataEntry: PlayerManualDataModel.builder(this.state.playerDataEntry)
				.withBirthdate(date ? moment.tz(date, localTimezone).format('YYYY-MM-DD') : '')
				.build(),
		});
	};

	onPositionChange = (position: any) => {
		this.setState({
			...this.state,
			playerDataEntry: PlayerManualDataModel.builder(this.state.playerDataEntry).withPosition(position).build(),
		});
	};

	onWeightChange = (e: any) => {
		this.setState({
			...this.state,
			playerDataEntry: PlayerManualDataModel.builder(this.state.playerDataEntry)
				.withProfile(PlayerProfileModel.builder(this.state.playerDataEntry.profile).withWeight(e.target.value).build())
				.build(),
		});
	};

	onHeightChange = (e: any) => {
		this.setState({
			...this.state,
			playerDataEntry: PlayerManualDataModel.builder(this.state.playerDataEntry)
				.withProfile(PlayerProfileModel.builder(this.state.playerDataEntry.profile).withHeight(e.target.value).build())
				.build(),
		});
	};

	onSocialChange = (e: any, key: string) => {
		const { playerDataEntry } = this.state;
		const social = SOCIAL_TYPES_METHODS[key](playerDataEntry.social, e.target.value);
		this.setState({
			...this.state,
			playerDataEntry: PlayerManualDataModel.builder(playerDataEntry).withSocial(social).build(),
		});
	};

	toggleSidebarEditMode = () => {
		const newState = toggleSidebarEditMode({ ...this.state }, ContentTypes.player);
		this.setState(newState);
	};

	onActiveChange = () => {
		this.setState({
			...this.state,
			isActive: !this.state.isActive,
			playerDataEntry: PlayerManualDataModel.builder(this.state.playerDataEntry).withActive(!this.state.isActive).build(),
		});
	};

	updateAssets = (assets: AssetsModel) => {
		this.setState({ ...this.state, assets: assets });
	};

	onPlayerSave = () => {
		this.props.playerEntityUpdate(playerSave(this.state.playerDataEntry), this.props.project, this.state.assets);
		this.props.isAddedInModal && this.props.onClose && this.props.onClose(false);
	};

	removeFromBlacklist = () => {
		const { playerDataEntry } = this.state;

		HttpService.removeFromBlacklist(playerDataEntry.blacklist.id)
			.then((response: any) => {
				this.setState({
					...this.state,
					playerDataEntry: PlayerManualDataModel.builder(playerDataEntry).withBlacklist(BlacklistModel.builder().build()).build(),
					isOpenModal: false,
				});
				this.props.isAddedInModal && this.props.getSquads && this.props.getSquads();
			})
			.catch((error: any) => {
				this.setState({ ...this.state, isOpenModal: false });
			});
	};

	toggleModal = (toggle: boolean) => {
		this.setState({ ...this.state, isOpenModal: toggle });
	};

	deleteAssetsFromDataEntry = (type: string) => {
		const { assets } = this.state;

		this.props.getPlayerEntity(!this.props.playerId ? this.props.match.params.id : this.props.playerId, this.props.project);
		this.setState({ ...this.state, assets: removeAssetsFromAssetsModel(type, assets) });
	};

	onAssetsSave = () =>
		this.props.savePlayerAssets({
			playerId: this.state.playerDataEntry.id,
			project: this.props.project,
			assets: this.state.assets,
		});

	render() {
		const { t, project, analytics, isAddedInModal } = this.props;
		const { playerDataEntry, isActive, isSidebarInEdit, isContentLoading, assets, isOpenModal, openSidebarSettingsModalFlag } = this.state;

		const sidebarComponentsMap = {
			media: (
				<AssetsMediaComponent
					t={t}
					currentProject={project}
					assets={assets}
					dataEntry={playerDataEntry}
					updateAssets={this.updateAssets}
					dataType='player'
					deleteAssetsFromDataEntry={this.deleteAssetsFromDataEntry}
				/>
			),
		};
		return (
			<div className='animated fadeIn'>
				<Row>
					<Col col='8' lg='8' md='12' sm='12' xs='12'>
						{playerDataEntry.blacklist && playerDataEntry.blacklist.id && (
							<Blacklisted t={t} type='player' isContentLoading={isContentLoading} toggleModal={this.toggleModal} />
						)}
						<div className='card'>
							<div className='card-header d-flex align-items-center '>
								<div className='mr-auto mb-0'>{t('player_info')}</div>
								<Button
									size='sm'
									color='primary'
									disabled={isSaveDisabled(playerDataEntry, ContentTypes.player)}
									className='text-uppercase font-weight-bold'
									onClick={this.onPlayerSave}
									id='player-manual-data-entry-edit-save-top'
								>
									<i className='fa fa-floppy-o' /> {t('save_player')}
								</Button>
								<Button
									size='sm'
									color='primary'
									className='ml-2 text-uppercase font-weight-bold'
									onClick={this.onAssetsSave}
									id='player-manual-data-entry-edit-save-assets-top'
								>
									<i className='fa fa-floppy-o' /> {t('save_assets')}
								</Button>
							</div>
							<div className={`card-body ${isContentLoading ? 'loading-overlay' : ''}`}>
								<Row>
									<div className='alert alert-info w-100' role='alert'>
										{t('enter_the_player_name_in_english')}
									</div>
								</Row>
								<Row>
									<Name value={playerDataEntry.name} onChange={this.onNameChange} t={t} type='player' />
									<BirthDateSelect value={playerDataEntry.birthdate ? playerDataEntry.birthdate : ''} t={t} onChange={this.onBirthDateChange} />
								</Row>
								<Row>
									<CountrySelect value={playerDataEntry.country} onChange={this.onCountryChange} t={t} />
									<HeightSelect
										value={playerDataEntry.profile && playerDataEntry.profile.height ? playerDataEntry.profile.height : ''}
										onChange={this.onHeightChange}
										t={t}
									/>
								</Row>
								<Row>
									<BirthCitySelect
										value={playerDataEntry.birthCity ? playerDataEntry.birthCity : ''}
										lang={project.language}
										onChange={this.onBirthCityChange}
										t={t}
									/>
									<WeightSelect
										value={playerDataEntry.profile && playerDataEntry.profile.weight ? playerDataEntry.profile.weight : ''}
										onChange={this.onWeightChange}
										t={t}
									/>
								</Row>
								<Row>
									<AddCityButton t={t} preselectCity={this.onBirthCityChange} />
									<ActiveToggle isActive={isActive} t={t} onChange={this.onActiveChange} />
								</Row>
							</div>
						</div>
						<div className='card'>
							<div className='card-header'>{t('professional_experience')}</div>
							<div className='card-body'>
								<Row className='mb-2'>
									<PlayerPositionSelect value={playerDataEntry.position} t={t} onChange={this.onPositionChange} />
								</Row>
							</div>
						</div>
						<div className='card'>
							<div className='card-header'>{t('social_media_highlights')}</div>
							<div className={`card-body ${isContentLoading ? 'loading-overlay' : ''}`}>
								<Row className='mb-2'>
									{Object.keys(SOCIALS_PLAYER).map((key: string) => {
										const value = playerDataEntry.social && playerDataEntry.social[key] ? playerDataEntry.social[key] : '';

										return <Social key={key} t={t} value={value} onChange={(e: any) => this.onSocialChange(e, key)} type={SOCIALS_PLAYER[key]} />;
									})}
								</Row>
								{!isAddedInModal && (
									<Row>
										<Button
											disabled={isSaveDisabled(playerDataEntry, ContentTypes.player)}
											color='primary'
											className='text-uppercase font-weight-bold'
											onClick={this.onPlayerSave}
											id='player-manual-data-entry-edit-save-bottom'
										>
											<i className='fa fa-floppy-o' /> {t('save_player')}
										</Button>
										<Button
											color='primary'
											className='ml-2 text-uppercase font-weight-bold'
											onClick={this.onAssetsSave}
											id='player-manual-data-entry-edit-save-assets-bottom'
										>
											<i className='fa fa-floppy-o' /> {t('save_assets')}
										</Button>
									</Row>
								)}
							</div>
						</div>
					</Col>
					<Col col='4' lg='4' md='12' sm='12' xs='12' className='position-relative'>
						{!isAddedInModal && (
							<SidebarElementsToggle t={t} toggleSidebarEditMode={this.toggleSidebarEditMode} isSidebarInEdit={isSidebarInEdit} />
						)}
						<SidebarCollapsibleElements
							isSidebarInEdit={isSidebarInEdit}
							onElemSort={(settingsValue: FormattedSidebarSectionValue[]) => onSidebarElemSort(settingsValue, ContentTypes.player)}
							onChangeCollapseStatus={(data: ContentSidebarChangeCollapseStatus) => onChangeCollapseStatus(data)}
							contentType={ContentTypes.player}
							sidebarComponentsMap={sidebarComponentsMap}
							t={t}
						/>
					</Col>
				</Row>
				{isAddedInModal && (
					<Row className='border-top border-datepicker-input-color mt-3'>
						<Button
							disabled={isSaveDisabled(playerDataEntry, ContentTypes.player)}
							color='primary'
							className='text-uppercase font-weight-bold mr-1 ml-auto mt-3'
							onClick={this.onPlayerSave}
							id='player-manual-data-entry-edit-save-bottom'
						>
							<i className='fa fa-floppy-o' /> {t('save_player')}
						</Button>
						<Button
							id='squad-player-cancel-button'
							color='secondary'
							className='text-light mt-3'
							onClick={() => this.props.onClose && this.props.onClose(false)}
						>
							{t('cancel')}
						</Button>
					</Row>
				)}
				<BlacklistModal
					t={t}
					type='player'
					analytics={analytics}
					contentToDelete={playerDataEntry}
					onDeleteContent={this.removeFromBlacklist}
					toggleModal={this.toggleModal}
					isToggledModal={isOpenModal}
				/>
				<GlobalModal
					isOpen={openSidebarSettingsModalFlag}
					t={this.props.t}
					headerContent='save_sidebar_settings'
					bodyContent='unsaved_changes'
					submitFunction={() => saveSidebarSettingsByContentType(ContentTypes.player, t)}
					beforeCloseFunction={() =>
						overwriteTempSidebarByContentType(ContentTypes.player, defaultTempSidebarFromApiResponse[ContentTypes.player].value)
					}
				/>
			</div>
		);
	}
}

function mapStateToProps(state: any) {
	return {
		player: state.player.player,
		project: state.project.currentProject,
		profile: state.profile.profile,
	};
}

function mapDispatchToProps(dispatch: any) {
	return {
		dispatch: (action: any) => dispatch(action),
		playerEntityUpdate: (playerData: PlayerManualDataModel, project: Project, assets: AssetsModel) =>
			dispatch(returnObjectForPlayerEntityUpdate(playerData, project, assets)),
		savePlayerAssets: (payload: { playerId: string; project: Project; assets: AssetsModel }) => dispatch(postPlayerAssets(payload)),
		getPlayerEntity: (id: string, project: Project) => dispatch(returnObjectForPlayerEntityRequest(id, project)),
		updateSearchFilterPlayer: (text: string) => dispatch(playerSearchFilterUpdate(text)),
	};
}

export default compose(connect(mapStateToProps, mapDispatchToProps), withRouter)(PlayerEditComponent);
