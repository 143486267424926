import React from 'react';
import { compose } from 'redux';
import { ViewTypes } from '../../../../constants/general.constants';
import BlockModel from '../../../../models/block.model';
import BlockUpdateController from '../../../../blocks/block-update-controller.component';
import { MonorepoWidgetPreview } from '../../../../blocks/widgets/widget-preview/monorepo-widget-preview.component';
import { SportBlockTypes, SportTypes, WidgetScriptTypes } from '../../../../../../../constants/sport.types';
import FootballLineupsModel from '../models/football-lineups.model';
import { FootballLineupsViewComponent } from './football-lineups-view.component';
import FootballLineupsEditComponent from './football-lineups-edit.component';

type Properties = {
	block: BlockModel;
	view: ViewTypes;
	contentData: any;
	contentLanguage: string;
};

const FootballLineupsWidget: React.FunctionComponent<Properties> = (props) => {
	const { view, block, contentData, contentLanguage } = props;
	if (view === ViewTypes.edit) {
		return <FootballLineupsEditComponent block={block} contentData={contentData} contentLanguage={contentLanguage} />;
	}

	if (view === ViewTypes.preview) {
		return (
			<MonorepoWidgetPreview
				blockContent={props.block.data.content}
				config={props.block.data.config}
				type={WidgetScriptTypes.FOOTBALL_WIDGETS}
				blockType={SportBlockTypes.EVENT_BLOCK}
				sport={SportTypes.FOOTBALL}
			/>
		);
	}

	if (view === ViewTypes.normal) {
		return (
			<FootballLineupsViewComponent
				preview={block.data.preview && block.data.preview.widgetModel ? block.data.preview.widgetModel : FootballLineupsModel.builder().build()}
			/>
		);
	}

	return null;
};

export default compose(BlockUpdateController)(FootballLineupsWidget);
