import React from 'react';
import { Tooltip } from 'reactstrap';

type State = {
	tooltipOpen: boolean;
};

type Properties = {
	t: any;
	isSidebarInEdit: boolean;
	toggleSidebarEditMode: () => void;
};

export default class SidebarElementsToggle extends React.Component<Properties, State> {
	constructor(props: any) {
		super(props);
		this.state = {
			tooltipOpen: false,
		};
	}

	toggle = () => {
		this.setState({ ...this.state, tooltipOpen: !this.state.tooltipOpen });
	};

	render() {
		const { tooltipOpen } = this.state;
		const { isSidebarInEdit, t, toggleSidebarEditMode } = this.props;

		return (
			<>
				<div className='position-absolute pr-2' id='sidebar-elements-edit-mode' onClick={toggleSidebarEditMode} style={{ top: -23, right: 0 }}>
					<i className='fa fa-info-circle text-muted mr-1' />
					<i className={`fa fa-toggle-${isSidebarInEdit ? 'on text-success' : 'off text-muted'}`} />
				</div>
				<Tooltip placement='left' isOpen={tooltipOpen} target='sidebar-elements-edit-mode' toggle={this.toggle}>
					{t(`sidebar_edit_mode_${isSidebarInEdit ? 'on' : 'off'}`)}
				</Tooltip>
			</>
		);
	}
}
