import React, { useState } from 'react';
import CountrySelect from '../fields/country';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Col, FormGroup, Label, Row, Input } from 'reactstrap';
import HttpService from '../../../../../services/rest/HttpService';
import { toast } from 'react-toastify';
import EnumItem from '../../../../../models/enum/EnumItem';

type Properties = {
	isToggledModal: boolean;
	t: any;
	toggleModal: (toggle: boolean) => any;
	preselectCity: (city: EnumItem) => void;
};

const AddCityModal: React.FunctionComponent<Properties> = ({ t, isToggledModal, toggleModal, preselectCity }) => {
	const [country, setCounrty] = useState({ id: '', name: '' });
	const [city, setCity] = useState('');

	const addCity = () => {
		HttpService.instanceFootball()
			.post('/v2/cities', JSON.parse(JSON.stringify({ name: city, country_id: `${country.id}` })))
			.then((res: any) => {
				preselectCity(EnumItem.builder().withId(res.data.id).withName(res.data.name).build());
				toast.success(t('add_city_success'));
				toggleModal(false);
			})
			.catch(() => {
				toast.error(t('add_city_error'));
				toggleModal(false);
			});
	};

	const saveDisabled = !country.id || !city.trim();

	return (
		<Modal isOpen={isToggledModal}>
			<ModalHeader className='pl-3 pr-3 pt-2 pb-2 bg-info' toggle={() => toggleModal(false)}>
				{t('add_city')}
			</ModalHeader>
			<ModalBody className='pb-2'>
				<Row>
					<Col xs='6'>
						<FormGroup>
							<Label htmlFor='add-city-name-input'>
								{t('city_name')}
								<span className='text-danger'>*</span>
							</Label>
							<Input
								type='text'
								value={city}
								id='add-city-name-input'
								onChange={(e: any) => setCity(e.target.value)}
								className='form-control'
								placeholder={t('input_city_name')}
							/>
						</FormGroup>
					</Col>
					<CountrySelect t={t} onChange={(country: any) => setCounrty(country)} value={country} />
				</Row>
			</ModalBody>
			<ModalFooter className='border-0'>
				<Button id='add-city-save-button' disabled={saveDisabled} type='submit' color='primary' onClick={addCity}>
					{t('add')}
				</Button>
				<Button id='modal-cancel-button' color='base' onClick={() => toggleModal(false)} className='ml-auto'>
					{t('cancel')}
				</Button>
			</ModalFooter>
		</Modal>
	);
};

export default AddCityModal;
