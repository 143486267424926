import { jsonArrayMember, jsonMember, jsonObject } from 'typedjson';
import MarketModel from './market.model';
import MarketValueTypeModel from './market-value-type.model';
import SportOddsBookmakerModel from '../../../../../../../../models/sport-odds/sport-odds-bookmaker.model';
import MatchModel from '../../../../../../../../models/v2/match/entity/match.model';

@jsonObject
export default class OddsV2Model {
	@jsonMember(MatchModel)
	public match: MatchModel = new MatchModel();

	@jsonMember(SportOddsBookmakerModel)
	public mainBookmaker: SportOddsBookmakerModel | null = null;

	@jsonMember(MarketModel)
	public market: MarketModel = new MarketModel();

	@jsonMember(MarketValueTypeModel)
	public marketValueType: MarketValueTypeModel = new MarketValueTypeModel();

	@jsonArrayMember(SportOddsBookmakerModel)
	public bookmakers: SportOddsBookmakerModel[] = [];

	@jsonMember(Boolean)
	public displayMatchHeader: boolean = true;

	@jsonMember(Boolean)
	public canSelectMarkets: boolean = true;
}
