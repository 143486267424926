import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SortEnd } from 'react-sortable-hoc';
import { toast } from 'react-toastify';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import List from '../../../../models/list/List';
import HttpService from '../../../../services/rest/HttpService';
import { returnObjectForListListingRequest } from '../../../../store/action-creators/ListActionCreators';
import { AppState } from '../../../../store/store';

type ModalState = { isShown: boolean; data: { lists: List[]; shoudUpdate: boolean } };

type Properties = {
	showModal: ModalState;
	t: any;
	toggleModal: (showModal: ModalState) => void;
	sortEnd: SortEnd;
};

const ListsReorderModal: React.FunctionComponent<Properties> = ({ t, showModal, toggleModal, sortEnd }) => {
	const [prevLists, setLists] = useState<List[]>([]);
	const { oldIndex, newIndex } = sortEnd;

	const currentPage = useSelector((state: AppState) => state.list.pagination.currentPage);
	const project = useSelector((state: AppState) => state.project.currentProject);
	const dispatch = useDispatch();

	useEffect(() => {
		showModal.isShown && setLists([...showModal.data.lists]);
	}, []);

	const toggleModalState = (shoudUpdate: boolean) => toggleModal({ isShown: false, data: { lists: prevLists, shoudUpdate: shoudUpdate } });

	const updateList = () => {
		// Define previous item index depending on drag direction.
		const prevItemIndex = newIndex !== 0 && newIndex < oldIndex ? newIndex - 1 : newIndex;
		const params = { previous_id: newIndex !== 0 ? prevLists[prevItemIndex].id : null };

		HttpService.patch(`/lists/${prevLists[oldIndex].id}`, params, { Project: project.domain })
			.then(() => {
				// Close the modal and get the updated list.
				dispatch(returnObjectForListListingRequest(currentPage, project));
				toggleModalState(false);
			})
			.catch((e: any) => {
				e.response.status === 403 && toast.error(t('lists_permissions_error'));
			});
	};

	return (
		<Modal isOpen={showModal.isShown}>
			{/* Close the modal and set the prevList in state. */}
			<ModalHeader className='pl-3 pr-3 pt-2 pb-2 bg-info' toggle={() => toggleModalState(true)}>
				{t('rearrange_lists')}
			</ModalHeader>
			<ModalBody className='pb-2'>
				{prevLists[oldIndex] && (
					<>
						<div className='text-center'>{t('rearrange_lists_body')}</div>
						<div className='pt-2 font-weight-bold text-center text-truncate'>{prevLists[oldIndex].title}</div>
					</>
				)}
			</ModalBody>
			<ModalFooter className='border-0'>
				{/* Close the modal and set the prevList in state. */}
				<Button id='modal-lists-cancel-button' color='base' onClick={() => toggleModalState(true)} className='ml-auto'>
					{t('close')}
				</Button>
				<Button id='modal-lists-confirm-button' color='info' onClick={updateList}>
					{t('confirm')}
				</Button>
			</ModalFooter>
		</Modal>
	);
};

export default ListsReorderModal;
