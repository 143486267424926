import React, { FunctionComponent } from 'react';
import { extractContentModeBasedOnUrl, extractMainContentTypeBasedOnUrl } from '../../../../../../global-helpers/global.helpers';
import { ContentTypes } from '../../../../../../constants/content-types';
import { isTypeAlreadyExist, mainModelToExternalOptions } from '../../helpers/external-main-media';
import { MainMediaSelectMenuOptions } from '../../constants/general';
import { ValueType } from 'react-select/src/types';
import ExternalMainMediaList from './list';
import ExternalMainMediaSelect from './type-select';
import {
	autoGenerateReduxPropsNamingForContentModels,
	generateReduxPropsUpdateActionByContentType,
} from '../../../../../../services/content-models-service/ContentModelService';
import { connect } from 'react-redux';
import MainMediaModel from '../../../../../../models/v2/main-media/main-media.model';
import { ReduxMainMediaProps } from '../../constants/redux-main-media.attributes';

type Properties = {
	t: any;
	mainMediaRedux: MainMediaModel[];
	updateTempProperty: (propertyObject: Record<string, any>) => void;
};

const ExternalMainMediaContainer: FunctionComponent<Properties> = ({ t, mainMediaRedux, updateTempProperty }) => {
	const chosenOptionsFromRedux: MainMediaSelectMenuOptions[] = mainModelToExternalOptions(mainMediaRedux, t);

	if (extractMainContentTypeBasedOnUrl() === ContentTypes.GALLERY) {
		return null;
	}

	const onExternalMainMediaAdd = (selectedOpt: ValueType<MainMediaSelectMenuOptions>) => {
		if (selectedOpt) {
			const toMainMediaSelectMenuOptions = selectedOpt as MainMediaSelectMenuOptions;
			const isAlreadyExist = isTypeAlreadyExist(chosenOptionsFromRedux, toMainMediaSelectMenuOptions);
			if (!isAlreadyExist) {
				// creating empty model only with type and subtype
				// because we are displaying error alert 'Field must not be empty' and prevent saving the content
				const selectedOptionAsEmptyMediaModel: MainMediaModel = new MainMediaModel();
				selectedOptionAsEmptyMediaModel.resourceType = toMainMediaSelectMenuOptions.type;
				selectedOptionAsEmptyMediaModel.resourceSubtype = toMainMediaSelectMenuOptions.subType;
				updateTempProperty({ [ReduxMainMediaProps.MAIN_MEDIA]: [...mainMediaRedux, selectedOptionAsEmptyMediaModel] });
			}
		}
	};

	const onRemoveElement = (type: string, subType: string) => {
		const withoutRemovedEl = mainMediaRedux.filter((el) => !(el.resourceType === type && el.resourceSubtype === subType));
		updateTempProperty({ [ReduxMainMediaProps.MAIN_MEDIA]: withoutRemovedEl });
	};

	return (
		<>
			<ExternalMainMediaSelect t={t} onChange={onExternalMainMediaAdd} />
			<ExternalMainMediaList t={t} onRemoveElement={onRemoveElement} />
		</>
	);
};

function mapStateToProps(state: any) {
	const { tempPropertyNaming, nestedTempPropertyNaming } = autoGenerateReduxPropsNamingForContentModels();

	return {
		mainMediaRedux: state[tempPropertyNaming][nestedTempPropertyNaming].mainMedia || [],
	};
}

function mapDispatchToProps(dispatch: Function) {
	const contentTypeFromUrl = extractMainContentTypeBasedOnUrl();
	const functionForDispatch = generateReduxPropsUpdateActionByContentType(contentTypeFromUrl);

	return {
		updateTempProperty: (propertyObject: Record<string, any>) =>
			functionForDispatch && dispatch(functionForDispatch(propertyObject, extractContentModeBasedOnUrl())),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(ExternalMainMediaContainer);
