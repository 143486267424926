import React from 'react';
import BlockUpdateController from '../../../block-update-controller.component';
import BlockModel from '../../../../models/block.model';
import { ViewTypes } from '../../../../constants/general.constants';
import { WidgetPreview } from '../../widget-preview/widget-preview.component';
import MatchListStageRoundEdit from './match-list-stage-round-edit.component';
import { MatchListStageRoundView } from './match-list-stage-round-view.component';

type Properties = {
	block: BlockModel;
	view: ViewTypes;
	t: any;
	lang: string;
};

const MatchListStageRoundComponent: React.FunctionComponent<Properties> = (props) => {
	if (props.view === ViewTypes.edit) {
		return <MatchListStageRoundEdit t={props.t} block={props.block} />;
	}

	if (props.view === ViewTypes.normal) {
		if (window.SMP365 && window.SMP365.widgets && window.SMP365.widgets.matchList && window.SMP365.widgets.matchList[props.block.id]) {
			window.SMP365.widgets.matchList[props.block.id].stopRequests();
		}

		return <MatchListStageRoundView t={props.t} preview={props.block.data.preview ? props.block.data.preview.matchListStageRound : {}} />;
	}

	if (props.view === ViewTypes.preview) {
		return <WidgetPreview blockContent={props.block.data.content} config={props.block.data.config} />;
	}

	return null;
};

export default BlockUpdateController(MatchListStageRoundComponent);
