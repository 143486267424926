import React from 'react';
import EntityActionService from '../entity-update-service/EntityActionService';
import FeatureService from '../feature-service/feature.service';
import momentTz from 'moment-timezone';
import moment from 'moment';
import 'moment/locale/bg';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Profile from '../../models/profile/Profile';
import { toggleShowListImages } from '../../store/action-creators/UiActionCreator';

export interface Properties {
	actionService: EntityActionService;
	featuresService: FeatureService;
	projectTimezone: string;
	profileLang: string;
	profile: Profile;
	profileId: string;
	shouldShowListImages: boolean;
	toggleShowListImages: (profileId: string) => void;
}

// No need for interface type for State as it is
// used only in the ServiceProvider Component
export type ServiceComponentState = {
	actionService: EntityActionService;
	featuresService: FeatureService;
};

export type RequiredProperties = {
	actionService: EntityActionService;
	featuresService: FeatureService;
};

// Service Context created
export const ServiceContext = React.createContext({
	actionService: new EntityActionService(),
	featuresService: new FeatureService(),
});

class ServiceProvider extends React.Component<Properties, ServiceComponentState> {
	constructor(props: Properties) {
		super(props);
		this.state = {
			actionService: this.props.actionService,
			featuresService: this.props.featuresService,
		};
	}

	componentDidMount() {
		momentTz.tz.setDefault(this.props.projectTimezone);
		this.props.profileLang && moment.locale(this.props.profileLang.split('_')[0]);

		const { shouldShowListImages, profileId } = this.props;

		if (!shouldShowListImages || (shouldShowListImages && !shouldShowListImages[profileId])) {
			this.props.toggleShowListImages(profileId);
		}
	}

	componentDidUpdate(prevProps: any) {
		if (prevProps.projectTimezone !== this.props.projectTimezone) {
			momentTz.tz.setDefault(this.props.projectTimezone);
		}

		if (prevProps.profileLang !== this.props.profileLang) {
			moment.locale(this.props.profileLang ? this.props.profileLang.split('_')[0] : 'en');
		}
	}

	render() {
		return (
			<ServiceContext.Provider value={{ actionService: this.state.actionService, featuresService: this.state.featuresService }}>
				{this.props.children}
			</ServiceContext.Provider>
		);
	}
}

//Connecting to redux store to set default timezone for current project
function mapStateToProps(state: any) {
	return {
		projectTimezone: state.project.currentProject.timezone,
		profileLang: state.profile.profile.language,
		profile: state.profile.profile,
		profileId: state.profile.profile.id,
		shouldShowListImages: state.ui.shouldShowListImages,
	};
}

function mapDispatchToProps(dispatch: any) {
	return {
		toggleShowListImages: (profileId: string) => dispatch(toggleShowListImages(profileId)),
	};
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(ServiceProvider) as React.ComponentType<RequiredProperties>;
