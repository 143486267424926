import React, { useState } from 'react';
import moment from 'moment';
import { RowModel } from './models/RowModel';
import { Button, Popover, PopoverBody } from 'reactstrap';

type Props = {
	content: RowModel;
	lang: string;
	t: any;
};

const PopoverItem: React.FunctionComponent<Props> = ({ content, t, lang }) => {
	const [popoverOpen, setPopoverOpen] = useState(false);
	const toggle = () => setPopoverOpen(!popoverOpen);
	const onHover = () => setPopoverOpen(true);
	const onHoverLeave = () => setPopoverOpen(false);

	if (!content.id) {
		return null;
	}

	return (
		<td className='d-none d-sm-table-cell align-middle'>
			<Button
				color='link'
				className='p-0 text-dark nav-link text-left'
				id={`popover-${content.id}`}
				type='button'
				onMouseEnter={onHover}
				onMouseLeave={onHoverLeave}
			>
				{content.createdBy.full_name || content.createdBy.name}
			</Button>
			<Popover placement='right' isOpen={popoverOpen} target={`popover-${content.id}`} toggle={toggle}>
				<PopoverBody>
					<div>
						<b>{t('created_by')}: </b>
						{content.createdBy.full_name || content.createdBy.name}
					</div>
					<div>
						<b>{t('created_at')}: </b>
						{moment(content.createdAt).lang(lang.split('_')[0]).format('DD MMM YYYY, HH:mm')}
					</div>
					<div>
						<b>{t('updated_at')}: </b>
						{moment(content.updatedAt).lang(lang.split('_')[0]).format('DD MMM YYYY, HH:mm')}
					</div>
				</PopoverBody>
			</Popover>
		</td>
	);
};

export default PopoverItem;
