import 'reflect-metadata';
import { AnyT, jsonMember, jsonObject } from 'typedjson';
import AssetsModel from '../../../../../models/v2/sports-types/assets.model';

@jsonObject()
export default class LiveBlogTeamModel {
	@jsonMember(AnyT)
	public id: string | number = '';
	@jsonMember(String)
	public name: string = '';
	@jsonMember(String)
	public type: string = '';
	@jsonMember(String)
	public gender: string = '';
	@jsonMember(String, { name: 'three_letter_code' })
	public threeLetterCode: string = '';
	@jsonMember(String, { name: 'short_name' })
	public shortName: string = '';
	@jsonMember(String, { name: 'url_logo' })
	public url_logo: string = '';
	@jsonMember(Boolean)
	public undecided: boolean = false;
	@jsonMember(AnyT)
	public data: any;
	@jsonMember(String)
	public slug: string | null = null;
	@jsonMember(AssetsModel)
	public assets: AssetsModel = new AssetsModel();
}
