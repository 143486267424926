export const statisticSquadColumns = (t: any) => {
	return [
		{ value: 'birthDate', label: t('birth_date') },
		{ value: 'nationality', label: t('nationality') },
		{ value: 'played', label: t('played_title') },
		{ value: 'minutes', label: t('minutes_title') },
		{ value: 'goals', label: t('goals_title') },
		{ value: 'assists', label: t('assists_title') },
		{ value: 'yellowCards', label: t('yellowCards_title') },
		{ value: 'redCards', label: t('redCards_title') },
		{ value: 'goalsSubstitute', label: t('goalsSubstitute') },
		{ value: 'minutesSubstitute', label: t('minutesSubstitute') },
		{ value: 'started', label: t('started') },
		{ value: 'substituteIn', label: t('substituteIn') },
		{ value: 'substituteOut', label: t('substituteOut') },
	];
};

export const statisticSquadGoalkeepersColumns = (t: any) => {
	return [
		{ value: 'birthDate', label: t('birth_date') },
		{ value: 'nationality', label: t('nationality') },
		{ value: 'played', label: t('played_title') },
		{ value: 'minutes', label: t('minutes_title') },
		{ value: 'yellowCards', label: t('yellowCards_title') },
		{ value: 'redCards', label: t('redCards_title') },
		{ value: 'cleansheets', label: t('cleansheets_title') },
		{ value: 'conceded', label: t('conceded_title') },
		{ value: 'minutesSubstitute', label: t('minutesSubstitute') },
		{ value: 'started', label: t('started') },
		{ value: 'substituteIn', label: t('substituteIn') },
		{ value: 'substituteOut', label: t('substituteOut') },
	];
};

export function selectedItemsToOptions(values: any[]) {
	let selectedItems: any[] = [];
	if (values && values.length > 0) {
		selectedItems = values.map((value: any[]) => contentToOption(value));
	}

	return selectedItems;
}

export const contentToOption = (content: any) => {
	if (content) {
		if (content.shouldBeDisplayed !== 'false') {
			return {
				value: content.value,
				label: content.label,
			};
		}
	}
	return null;
};
