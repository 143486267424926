import React, { FunctionComponent, useEffect, useState } from 'react';
import Related from '../../../../../../../models/related/Related';
import {
	convertResultToOption,
	excludeEntityTypes,
	filterSelectedSportsRelated,
	selectionToRelated,
	sportsRelatedToOptions,
} from './sports-connections.helper';
import _ from 'lodash';
import { customOption } from '../../../../../Sidebar/tags/subcomponents/football-connections/option-component';
import AsyncSelect from 'react-select/async';
import EntityTypesModel from '../../../../../../../models/v2/sports-types/entity-types.model';
import { sportsConnectionsService } from '../../../../../../../App';

export const SportsConnectionsContainer: FunctionComponent<{
	onSportsConnectionSelect: (related: Related[]) => void;
	related: Related[];
	contentLanguage: string;
	t: any;
	sportType: string;
	sportsEntities: EntityTypesModel[];
	allowMoreEntityTypes: boolean;
}> = ({ onSportsConnectionSelect, t, related, contentLanguage, sportType, sportsEntities, allowMoreEntityTypes }) => {
	const [selectedOptions, setSelectedOptions] = useState([] as Related[]);
	const [newValue, setNewValue] = useState<string>('');
	const [availableSportsEntities, setAvailableSportsEntities] = useState<string[]>([]);
	useEffect(() => {
		setSelectedOptions(sportsRelatedToOptions(related));
	}, []);

	useEffect(() => {
		setSelectedOptions(sportsRelatedToOptions(related));
	}, [related]);

	const onInputChange = (input: string) => {
		setNewValue(input);
		return input;
	};

	const onSelect = (selections: any) => {
		selections = selections === null ? [] : selections;
		const relatedData = selectionToRelated(selections);
		onSportsConnectionSelect(relatedData);
	};

	const loadSportsData = (input: string, callback: any) => {
		let availableSportsEntities: any = [];
		let entities = excludeEntityTypes(sportsEntities, allowMoreEntityTypes);
		entities &&
			entities.map((entity: EntityTypesModel) => {
				return availableSportsEntities.push(entity.name);
			});

		setAvailableSportsEntities(availableSportsEntities);

		if (input.length >= 3) {
			sportsConnectionsService.getSportsData(input, sportType, availableSportsEntities, contentLanguage).then((res) => {
				let data =
					res &&
					res.map((result: any) => {
						return convertResultToOption(result);
					});
				callback([...data]);
			});
		}
	};

	return (
		<AsyncSelect
			noOptionsMessage={(inputValue) => inputValue && t('no_options')}
			value={selectedOptions && Object.entries(selectedOptions).length > 0 ? filterSelectedSportsRelated(selectedOptions, sportType) : null}
			loadOptions={_.debounce(loadSportsData, 500)}
			onInputChange={_.debounce(onInputChange, 500)}
			onChange={onSelect}
			inputId={'sports-connections-select'}
			formatOptionLabel={customOption}
			placeholder={t('select')}
			isClearable={true}
			isMulti={true}
			data-qa='sports-connections-container'
		/>
	);
};
