import React from 'react';
import Select from 'react-select';
import { Col, Label, Row } from 'reactstrap';
import HttpService from '../../../../../services/rest/HttpService';
import { Properties, State } from './properties/season-select.properties';
import { seasonsToOptions, seasonToOption, remapSeasonFromResponse, remapSeasonByIdFromResponse } from './helpers/season-select.helper';
import ErrorHandler from '../error/error-handler-component';
import SeasonModel from '../../blocks/widgets/player-profile/models/season/season.model';

export default class SeasonSelectByIdContainer extends React.Component<Properties, State> {
	constructor(props: Properties) {
		super(props);
		this.state = {
			seasonsOptions: [],
		};
	}

	componentDidMount() {
		if (this.props.teamId) {
			this.getSeasons(this.props.teamId);
		}
	}

	componentDidUpdate(prevProps: Readonly<Properties>, prevState: Readonly<State>) {
		if (
			this.props.teamId !== prevProps.teamId ||
			(this.props.selectedSeason && (!this.props.selectedSeason.id || this.props.selectedSeason.id.length < 1))
		) {
			this.getSeasons(this.props.teamId);
		}
	}

	updateSeasonsOptionsState(seasons: SeasonModel[]) {
		const { onSeasonSelect, selectedSeason } = this.props;

		if (!selectedSeason || (selectedSeason && (selectedSeason.id === undefined || selectedSeason.id.length < 1))) {
			onSeasonSelect(seasons[0]);
		}
		this.setState({ ...this.state, seasonsOptions: seasons });
	}

	getSeasons(teamId: string) {
		if (teamId) {
			HttpService.getSeasonsStatisticsByTeamId(teamId, this.props.lang)
				.then((response: any) => {
					this.updateSeasonsOptionsState(remapSeasonByIdFromResponse(response.data));
				})
				.catch((e: any) => e);
		}
	}

	onSeasonSelect = (selection: any) => {
		this.props.onSeasonSelect(selection.data);
	};

	render() {
		const { onSeasonSelect, selectedSeason, t, label, withLabel } = this.props;
		return (
			<Row>
				<Col>
					{withLabel && <Label>{t('season')}:</Label>}
					{label && <Label>{t(label)}</Label>}
					<Select
						menuPortalTarget={document.body}
						menuPosition='absolute'
						placeholder={t('select')}
						options={seasonsToOptions(this.state.seasonsOptions)}
						value={seasonToOption(selectedSeason)}
						noOptionsMessage={(inputValue) => inputValue && t('no_options')}
						onChange={(selection: any) => onSeasonSelect(selection.data)}
					/>
					{!this.props.isValid && !this.props.selectedSeason && <ErrorHandler t={this.props.t} errorMessage='field_is_required' />}
				</Col>
			</Row>
		);
	}
}
