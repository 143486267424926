import React from 'react';
import { FormGroup } from 'reactstrap';
import { AuthorSocial } from '../../../Partials/Fields/social/social';
import { connect } from 'react-redux';
import AuthorSocialModel from '../../../../models/v2/author/social.model';

type AuthorSocialsProps = {
	socialLinks: AuthorSocialModel[];
	onChange: (slug: string, value: string) => void;
};

const AuthorSocials: React.FunctionComponent<AuthorSocialsProps> = ({ socialLinks, onChange }) => {
	if (socialLinks.length > 0) {
		return (
			<FormGroup>
				{socialLinks.map((social) => {
					return <AuthorSocial social={social} onChange={(slug: string, value: string) => onChange(slug, value)} />;
				})}
			</FormGroup>
		);
	}

	return null;
};

function mapStateToProps(state: any) {
	return {
		defaultSocialsRedux: state.author.defaultSocials || [],
	};
}

export default connect(mapStateToProps)(AuthorSocials);
