import {
	defaultTempSidebarFromApiResponse,
	overwriteTempSidebarByContentType,
	tempSidebar,
	toggleTempSidebarTabData,
} from '../../views/Partials/Sidebar/sidebar-collapsible-elements/utils/temp-sidebar.helper';
import { FormattedSidebarSectionValue } from '../../views/Partials/Sidebar/sidebar-collapsible-elements/utils/temp-sidebar.types';

export type ContentSidebarChangeCollapseStatus = {
	sidebarSettings: FormattedSidebarSectionValue[];
	isSidebarInEdit: boolean;
	contentType: string;
	status: string;
	sequence: number;
};

export const isSidebarTabToggled = (status: string): boolean => {
	return status === 'open';
};

export const toggleSidebarEditMode = (state: any, contentType: string) => {
	let isTempSidebarChanged = false;
	if (state && state.isSidebarInEdit) {
		const dataFromCurrentSidebar = tempSidebar[contentType];
		const dataFromDefaultSidebar = defaultTempSidebarFromApiResponse[contentType];
		isTempSidebarChanged = JSON.stringify(dataFromCurrentSidebar) !== JSON.stringify(dataFromDefaultSidebar);
	}

	return { ...state, isSidebarInEdit: !state.isSidebarInEdit, openSidebarSettingsModalFlag: isTempSidebarChanged };
};

export const onChangeCollapseStatus = (collapseData: ContentSidebarChangeCollapseStatus) => {
	let sidebarSettingsData: FormattedSidebarSectionValue[] = [];
	const sidebarToggledFlag: boolean = isSidebarTabToggled(collapseData.status);
	const contentType = collapseData.contentType;
	if (collapseData && collapseData.sidebarSettings && collapseData.sidebarSettings.length > 0) {
		const indexOfToggledBlock = collapseData.sequence; // sequence === elements index in the array data
		sidebarSettingsData = [...collapseData.sidebarSettings];
		const toggledTabData = toggleTempSidebarTabData(sidebarSettingsData[indexOfToggledBlock], sidebarToggledFlag);
		sidebarSettingsData[indexOfToggledBlock] = toggledTabData;
	}

	overwriteTempSidebarByContentType(contentType, sidebarSettingsData);
};

export const onSidebarElemSort = (changedSidebarSettings: FormattedSidebarSectionValue[], contentType: string) => {
	overwriteTempSidebarByContentType(contentType, changedSidebarSettings);
};
