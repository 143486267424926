import React, { useEffect, useState } from 'react';
import { Button, Col, Label, Row } from 'reactstrap';
import EnumItem from '../../../../../models/enum/EnumItem';
import HttpService from '../../../../../services/rest/HttpService';
import TranslationTeamModel from '../../models/translations-team.model';
import './edit-season-teams.modal.scss';
import TranslationLanguageSelect from '../translation-language-select.component';
import PlayerModel from '../../../../Partials/Blocky/partials/player-select/models/player.model';
import {
	areTranslationsEmpty,
	filterOtherTranslations,
	filterPlayerTranslations,
	mapMultipleTranslations,
	mapTeamTranslations,
	orderTranslationSearchResponse,
	remapTranslationTeamFromResponse,
	updateTranslationLang,
} from '../../helpers/translations.helpers';
import { entityTypeEnum } from '../../helpers/translations.constants';
import Project from '../../../../../models/project/Project';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../../store/store';
import TeamTranslationsContainer from '../team-translations.component';
import { assetsPlaceholder } from '../../../../../constants/assetsPlaceholder';
import TeamPlayersTranslationsContainer from '../team-players-translations.component';
import { withRouter, RouteComponentProps } from 'react-router-dom';

type Properties = {
	t: any;
	searchLanguage: EnumItem;
	team: any;
	languageToTranslate: EnumItem;
	saveTranslation: any;
	getOnlyTeamPlayers?: boolean;
	teamId: string;
	match: any;
};

const TranslateTeam: React.FunctionComponent<RouteComponentProps<{}> & Properties> = (props) => {
	const { t, searchLanguage, team, languageToTranslate, saveTranslation, getOnlyTeamPlayers, teamId, match } = props;

	const [loading, updateLoadingState] = useState<boolean>(false);
	const [translationLanguage, updateTranslationLanguage] = useState<EnumItem>(EnumItem.builder().build());
	const [translations, updateTranslations] = useState<any[]>([]);
	const [teamPlayersTranslation, updateTeamPlayersTranslation] = useState<any[]>([]);

	const [modalState, updateModalState] = useState({
		teamEntity: TranslationTeamModel.builder().build(),
		teamPlayers: [] as PlayerModel[],
	});

	const currentProject: Project = useSelector((store: AppState) => store.project.currentProject);

	const logoOpacity = modalState.teamEntity && modalState.teamEntity.team && !loading ? { opacity: 1 } : { opacity: 0.1 };

	useEffect(() => {
		updateTranslationLanguage(languageToTranslate);
		//Get teams data in translation screens
		if (teamId === match.params.id && getOnlyTeamPlayers) {
			// Get only team players data
			getTeamPlayersData(teamId, team, languageToTranslate);
		}
	}, [team]);

	useEffect(() => {
		// Get Team Data in Tournaments translation -> Team edit modal
		getTeamData(teamId, searchLanguage.id, languageToTranslate);
	}, [teamId]);

	// When the user change translation language we need to search for new translations
	const onLanguageChange = (language: EnumItem) => {
		const updatedTranslations = updateTranslationLang(translations, language);
		updateTranslations(updatedTranslations);
		updateTranslationLanguage(language);
		if (teamEntity) {
			searchTeamPLayersTranslations(teamPlayers, teamEntity, language);
		}
	};
	// When user select team entity we get all team data from footballAPI
	const getTeamData = (id: string, language: string, translationLanguage: EnumItem) => {
		updateLoadingState(true);
		const headers = { Project: currentProject.domain };
		if (teamId) {
			HttpService.instanceFootball(language.split('-')[0])
				.get(`/teams/${id}`, null, headers)
				.then((response: any) => getTeamPlayersData(id, remapTranslationTeamFromResponse(response.data), translationLanguage))
				.catch(() => updateLoadingState(false));
		}
	};

	// When user select team entity we get all team players data from footballAPI
	const getTeamPlayersData = (id: string, teamEntity: TranslationTeamModel, translationLanguage: EnumItem) => {
		const headers = { Project: currentProject.domain };
		HttpService.instanceFootball(searchLanguage.id.split('-')[0])
			.get(`/teams/${id}/players`, null, headers)
			.then((response: any) => {
				const teamPlayers =
					response.data && response.data.length > 0 ? response.data.map((player: any) => PlayerModel.builder(player.player).build()) : [];
				searchTeamPLayersTranslations(teamPlayers, teamEntity, translationLanguage);
			})
			.catch((e: any) => {
				updateLoadingState(false);
			});
	};

	// Search for existing trnaslations for team case and map them to state
	const searchTeamPLayersTranslations = (teamPlayers: PlayerModel[], teamEntity: TranslationTeamModel, language: EnumItem) => {
		const mapEntityToTranslation = [
			...mapTeamTranslations(teamEntity, language),
			...mapMultipleTranslations(teamPlayers, language, entityTypeEnum.PLAYER),
		];
		const translationsForSearch = mapEntityToTranslation.map((translation: any) => translation.key);
		HttpService.instanceFootball()
			.post(`/v2/translations/search`, translationsForSearch)
			.then((response: any) => {
				const orderedResponse = orderTranslationSearchResponse(mapEntityToTranslation, response);
				updateModalState({
					teamEntity,
					teamPlayers,
				});
				updateTeamPlayersTranslation(filterPlayerTranslations(orderedResponse, entityTypeEnum.PLAYER));
				updateTranslations(filterOtherTranslations(orderedResponse, entityTypeEnum.PLAYER));
				updateLoadingState(false);
			})
			.catch(() => updateLoadingState(false));
	};

	const { teamEntity, teamPlayers } = modalState;
	const buttonDisabled = !teamId || areTranslationsEmpty([...translations, ...teamPlayersTranslation]);

	return (
		<Row>
			<Col col='12' lg='12' md='12' sm='12' xs='12'>
				<div className='card'>
					<div className='d-flex align-items-center card-header'>
						<div className='mr-auto'>{t('translations')}</div>
						<Button
							size='sm'
							disabled={buttonDisabled}
							id='upper-save-translation-button'
							color='primary'
							onClick={() => saveTranslation(translations, teamPlayersTranslation)}
						>
							{t('save_translation')}
						</Button>
					</div>
					<div className='card-body'>
						<div>
							<Row>
								<TranslationLanguageSelect language={translationLanguage} isSearchLang={false} t={t} onSelect={onLanguageChange} />
							</Row>
						</div>
					</div>
				</div>
				<div className='card animated fadeIn'>
					<div className='d-flex align-items-center card-header'>
						<Col xs='6'>{t('team')}</Col>
					</div>
					<div className='card-body'>
						<Row className='mb-4'>
							<Col xs='6'>
								<Label>{t('team')}</Label>
								<div className='d-flex flex-direction-row flex-wrap'>
									<div className='form-control'>
										<img
											style={logoOpacity}
											height='15'
											src={teamEntity && teamEntity.team && teamEntity.team.logo ? teamEntity.team.logo : assetsPlaceholder.team}
										/>{' '}
										{teamEntity.team ? teamEntity.team.name : ''}
									</div>
								</div>
							</Col>
							<Col xs='6' className='text-center'>
								<img
									style={logoOpacity}
									height='65'
									src={teamEntity && teamEntity.team && teamEntity.team.logo ? teamEntity.team.logo : assetsPlaceholder.team}
								/>
							</Col>
						</Row>
						<div className={`${loading ? 'loading-overlay' : ''}`}>
							{teamEntity && Object.keys(teamEntity).length > 0 && translations && translations.length > 0 && (
								<TeamTranslationsContainer
									translations={[...translations]}
									t={t}
									teamEntity={teamEntity}
									language={translationLanguage}
									translationChange={updateTranslations}
								/>
							)}
						</div>
						{!teamPlayers && (
							<Button
								disabled={buttonDisabled}
								id='save-translation-button-no-players'
								color='primary'
								onClick={() => saveTranslation(translations, teamPlayersTranslation)}
							>
								{t('save_translation')}
							</Button>
						)}
					</div>
				</div>
				{teamPlayers && teamPlayers.length > 0 && teamPlayersTranslation && teamPlayersTranslation.length > 0 && (
					<div className={`card animated fadeIn fadeOut" ${loading ? 'loading-overlay' : ''}`}>
						<div className='card-header'>{t('team_players')}</div>
						<Row className='m-0 px-3 py-2 border-bottom'>
							<Col xs='6'>{t('player_name')}</Col>
							<Col xs='6'>{t('translation')}</Col>
						</Row>
						<div className='card-body'>
							<TeamPlayersTranslationsContainer
								t={t}
								translations={teamPlayersTranslation}
								teamPlayers={teamPlayers}
								language={translationLanguage}
								translationChange={updateTeamPlayersTranslation}
							/>
							<Button
								id='save-translation-button'
								disabled={buttonDisabled}
								color='primary'
								onClick={() => saveTranslation(translations, teamPlayersTranslation)}
							>
								{t('save_translation')}
							</Button>
						</div>
					</div>
				)}
			</Col>
		</Row>
	);
};
export default withRouter(TranslateTeam);
