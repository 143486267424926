import 'reflect-metadata';
import { jsonMember, jsonObject } from 'typedjson';

@jsonObject()
export default class ImageUrlsUploadedModel {
	@jsonMember(String)
	public embed: string | null = null;

	@jsonMember(String)
	public gallery: string | null = null;
	@jsonMember(String)
	public original: string | null = null;

	@jsonMember(String)
	public thumbnail: string | null = null;
}
