import Feed from '../../../../models/feed/feed';
import Source from '../../../../models/feed/sources/source';

export const dateFilterOptions = (t: any) => {
	return [
		{ value: '12h', label: t('today') },
		{ value: '1d', label: t('yesterday') },
		{ value: '3d', label: t('last_3_days') },
		{ value: '7d', label: t('last_7_days') },
	];
};

export const socialTypes = {
	fb: 'facebook',
	twitter: 'twitter',
	rss: 'rss',
	youtube: 'youtube-play',
	instagram: 'instagram',
};

export const emptyOption = { value: 'empty', label: 'Empty', feed: null, isDisabled: false };

export function feedToOption(feed: Feed) {
	let option = {};

	if (feed) {
		option = { label: feed.name, value: feed.id, feed };
	}

	return option;
}

export const remapFeedsToOptions = (feeds: Feed[]) => {
	if (feeds && feeds.length > 0) {
		return feeds.map((feed: Feed) => {
			return {
				value: feed.id,
				label: feed.name,
				feed,
				isDisabled: !feed.active,
			};
		});
	}

	return [];
};

export const remapDateToOptions = (time: any, t: any) => {
	if (time && time.length > 0) {
		return time.map((time: any) => {
			return {
				value: t(time.value),
				label: t(time.label),
			};
		});
	}

	return [];
};

export function dateToOption(date: any, t: any) {
	let option = {};

	if (date) {
		option = { label: t(date.label), value: date.value };
	}

	return option;
}

const onlyUnique = (value: string, index: number, self: any) => self.indexOf(value) === index;

export const extractTypeFilterOptions = (sources: Source[]) => {
	if (sources) {
		const types = sources.map((source: Source) => source.type).filter(onlyUnique);

		return types.map((type: string) => {
			return { value: type, label: type };
		});
	}

	return [];
};
