import React from 'react';
import { ViewTypes } from '../../../constants/general.constants';
import { Properties, State } from './properties/dugout-video-block.properties';
import { connect } from 'react-redux';
import DugoutVideoEdit from './dugout-video-edit.component';
import DugoutVideoView from './dugout-video-block-view.component';
import DugoutVideoModel from '../../../../../../models/dugout-videos/dugout-video/dugout-video.model';

class DugoutVideoBlock extends React.Component<Properties, State> {
	constructor(props: any) {
		super(props);
		this.state = {
			isLoading: false,
		};
	}

	//TODO store dugout video id in block preview and request details in 'View' mode
	render() {
		const { t, view, block, contentData } = this.props;

		if (view === ViewTypes.edit) {
			return (
				<DugoutVideoEdit t={t} block={block} linkedTags={(contentData && contentData.related && contentData.related.tags) || undefined} />
			);
		} else if (view === ViewTypes.normal) {
			return (
				<DugoutVideoView
					t={t}
					isLoading={this.state.isLoading}
					dugoutVideo={block.data.preview ? block.data.preview.video : DugoutVideoModel.builder().build()}
				/>
			);
		}
	}
}

function mapStateToProps(state: any) {
	return {
		currentProject: state.project.currentProject,
		lang: state.profile.profile.language,
	};
}

export default connect(mapStateToProps)(DugoutVideoBlock) as React.ComponentType<any>;
