import ModelMapper from '../../models/ModelMapper';
import { toast } from 'react-toastify';
import i18n from '../../i18n';
import {
	CONTENT_STATISTICS_VIDEO_ENTITY_RECEIVED,
	VIDEO_ALREADY_EXISTS,
	VIDEO_COPY_RELATED_RECEIVED,
	VIDEO_CREATE,
	VIDEO_CREATE_FAILED,
	VIDEO_CREATE_RESOURCES_SUCCESS,
	VIDEO_CREATE_SUCCESS,
	VIDEO_DELETE_SUCCESS,
	VIDEO_RECEIVED,
	VIDEO_RELATED_CREATE_SUCCESS,
	VIDEO_RELATED_RECEIVED,
	VIDEO_UPDATE,
	VIDEO_UPDATE_FAILED,
	VIDEO_UPDATE_SUCCESS,
	VIDEOS_CONTENT_STATISTICS_RECEIVED,
	VIDEOS_RECEIVED,
} from '../action-creators/VideoActionCreator';
import { relatedConstants } from '../../constants/related.constants';
import Video from '../../models/video/Video';
import { actionService, analyticsService } from '../../App';
import { SEARCH_IS_APPLIED } from '../action-creators/UiActionCreator';
import { ContentTypes } from '../../constants/content-types';
import {
	extractSportsRelatedFromResponse,
	extractEventEntitiesFromSports,
} from '../../views/Partials/Shared/advanced-content-filter/subcomponents/v2/sports-select/sports-connections.helper';

export function videoMiddleware() {
	return function (next: any) {
		return function (action: any) {
			if (action) {
				if (action.type === VIDEOS_RECEIVED) {
					action.payload.videos = ModelMapper.remapVideosListingFromResponse(action.payload.data.data);
					action.payload.contentPagination = ModelMapper.remapMetaPagination(action.payload.data.meta.pagination);
				}

				if (action.type === VIDEO_DELETE_SUCCESS) {
					toast.success(i18n.t('video_deleted_success'));
					//TODO Replace window.dispatchEvent with actionService
					window.dispatchEvent(new Event(action.type));
				}

				if (action.type === VIDEO_RELATED_RECEIVED || action.type === VIDEO_COPY_RELATED_RECEIVED) {
					const response = action.payload.related.data.data;
					const sportsEventEntities = extractEventEntitiesFromSports(action.payload.sports);

					let allRelated = [];
					const relatedFootball = ModelMapper.extractFootballRelatedFromResponse(response);
					allRelated.push(...relatedFootball);
					const relatedSports = extractSportsRelatedFromResponse(response, action.payload.sports);
					allRelated.push(...relatedSports);

					action.payload.relatedSports = allRelated;

					action.payload.tagsRelated = ModelMapper.extractRelatedTypeFromResponse(
						response,
						relatedConstants.types.tag,
						relatedConstants.providers.smp,
					);

					action.payload.relatedContent = ModelMapper.extractRelatedContentFromResponse(response);

					let relatedEvents = [];
					const relatedMatches = ModelMapper.extractRelatedTypeFromResponse(
						response,
						relatedConstants.types.match,
						relatedConstants.providers.football,
					);
					relatedEvents.push(...relatedMatches);
					const relatedGames = ModelMapper.extractSportsEntitiesRelatedTypeFromResponse(
						response,
						sportsEventEntities.flat(),
						relatedConstants.providers.sports,
					);
					relatedEvents.push(...relatedGames);
					action.payload.matchRelated = relatedEvents;
				}

				if (action.type === VIDEO_UPDATE) {
					actionService.emitGeneralAction(VIDEO_UPDATE);
					action.payload.video = Video.builder(action.payload.video).toRequestJson();
					action.payload.related = ModelMapper.remapRelatedListToRelatedJsonList(action.payload.related);
				}

				if (action.type === VIDEO_CREATE) {
					actionService.emitGeneralAction(VIDEO_CREATE);
					action.payload.video = Video.builder(action.payload.video).toRequestJson();
					action.payload.related = ModelMapper.remapRelatedListToRelatedJsonList(action.payload.related.slice());
				}

				if (action.type === VIDEO_CREATE_SUCCESS) {
					actionService.emitGeneralAction(VIDEO_CREATE_SUCCESS);
					//TODO Replace window.dispatchEvent with actionService
					window.dispatchEvent(new CustomEvent(VIDEO_CREATE_SUCCESS, { detail: action.payload }));
					analyticsService.sendWidgetAnalytic();
					toast.success(i18n.t('video_create_success'));
				}

				if (action.type === VIDEO_CREATE_RESOURCES_SUCCESS) {
					actionService.emitGeneralAction(VIDEO_CREATE_RESOURCES_SUCCESS);
					window.dispatchEvent(new CustomEvent(VIDEO_CREATE_RESOURCES_SUCCESS, { detail: action.payload }));
					// not sure if its not better to display the toast notification for successfully created video here
					// otherwise it can display that the video is created successfully but the related data to have a problem..
					// TODO to be discussed with the team
				}

				if (action.type === VIDEO_CREATE_FAILED) {
					actionService.emitGeneralAction(VIDEO_CREATE_FAILED);
					toast.error(i18n.t('video_create_failed'));
				}

				if (action.type === VIDEO_RELATED_CREATE_SUCCESS) {
					//TODO Replace window.dispatchEvent with actionService
					window.dispatchEvent(new CustomEvent(VIDEO_RELATED_CREATE_SUCCESS, { detail: action.payload }));
				}

				if (action.type === VIDEO_UPDATE_SUCCESS) {
					actionService.emitGeneralAction(VIDEO_UPDATE_SUCCESS);
					analyticsService.sendWidgetAnalytic();
					toast.success(i18n.t('video_update_success'));
				}

				if (action.type === VIDEO_UPDATE_FAILED) {
					actionService.emitGeneralAction(VIDEO_UPDATE_FAILED);
					toast.error(i18n.t('video_update_failed'));
				}

				if (action.type === VIDEO_RECEIVED) {
					action.payload.videoEdit = ModelMapper.remapVideoFromResponse(action.payload.data.data);
				}

				if (action.type === SEARCH_IS_APPLIED) {
					actionService.emitGeneralAction(SEARCH_IS_APPLIED);
				}

				if (action.type === VIDEOS_CONTENT_STATISTICS_RECEIVED) {
					action.payload.videos = ModelMapper.remmapListingContentWithContentStatisticsFromResponse(
						action.payload.contentStatistics,
						action.payload.videos,
						ContentTypes.VIDEO,
					);
				}

				if (action.type === CONTENT_STATISTICS_VIDEO_ENTITY_RECEIVED) {
					action.payload.video = ModelMapper.remmapContentWithContentStatistics(
						action.payload.contentStatistics[0],
						action.payload.video,
						ContentTypes.VIDEO,
					);
				}

				if (action.type === VIDEO_ALREADY_EXISTS) {
					actionService.emitGeneralAction(VIDEO_ALREADY_EXISTS);
					toast.error(`${i18n.t('video_with_this_code_already_exists')}`);
				}
			}

			next(action);
		};
	};
}
