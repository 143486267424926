import React, { useEffect, useState } from 'react';
import { Table } from 'reactstrap';
import CategoryPermissionsService from './category-permissions-service/category-permissions.service';
import { DebounceInput } from 'react-debounce-input';

type Properties = {
	t: any;
	admin: any | null;
	onCategoriesPermissionsChange: (categoryPermissions: any[]) => void;
	permissionsService: CategoryPermissionsService;
};

const CategoriesList: React.FunctionComponent<Properties> = ({ t, admin, onCategoriesPermissionsChange, permissionsService }) => {
	const [categories, setCategories] = useState([]);
	const [filteredCategories, setFilteredCategories] = useState([]);
	const [categoryFilter, setCategoryFilter] = useState('');

	useEffect(() => undefined, [categories.length]);

	useEffect(() => {
		if (admin !== null && admin.id && admin.id.length > 0) {
			permissionsService.requestCategories(admin.id).then((requestedCategories: any) => {
				setCategories(requestedCategories);
				setFilteredCategories(requestedCategories);
				setCategoryFilter('');
			});
		}
	}, [admin.id]);

	if (categories.length < 1) {
		return null;
	}

	return (
		<div className='permissions-col-container'>
			<div className='header-category-perms'>
				<div className='title'>{t('category_permissions_categories')}</div>
				<div className='d-flex flex-row align-items-center'>
					<div className='check-all-categories mr-2 d-flex flex-row align-items-center justify-content-center'>
						<label htmlFor='select-all'>{t('category_permissions_select_all')}</label>
						<input
							id='select-all'
							type='checkbox'
							checked={permissionsService.areAllCategoriesPermitted(categories)}
							onChange={(event) => {
								const bulkCategories = permissionsService.permitAllCategories(event.target.checked, categories);
								setCategories(bulkCategories);
								setFilteredCategories(bulkCategories);
							}}
						/>
					</div>
					<DebounceInput
						type='text'
						id='category_list_permissions_search'
						placeholder={t('category_permissions_category_search')}
						className='form-control'
						debounceTimeout={300}
						value={categoryFilter}
						onChange={(event) => {
							const filteredCategoriesByTitle = permissionsService.filterCategoriesByTitle(event.target.value, categories);
							setCategoryFilter(event.target.value);
							setFilteredCategories(filteredCategoriesByTitle);
						}}
					/>
				</div>
			</div>
			<div className='categories-container'>
				<Table>
					<tbody>
						{filteredCategories.map((category: any) => {
							return (
								<tr
									key={`category-key-id-${category.id}`}
									onClick={() => {
										const updatedCategories = permissionsService.permitCategory(category, categories, !category.isPermitted);
										setCategories(updatedCategories);
										onCategoriesPermissionsChange(updatedCategories);
									}}
								>
									<td>
										{category.indent ? category.indent : ''}
										{category.title}
									</td>
									<td>
										<input type='checkbox' onChange={() => undefined} checked={category.isPermitted} />
									</td>
								</tr>
							);
						})}
					</tbody>
				</Table>
			</div>
		</div>
	);
};

export default CategoriesList;
