import { HocuspocusProvider, HocuspocusProviderConfiguration, HocuspocusProviderWebsocket } from '@hocuspocus/provider';
import { useMemo } from 'react';
import REACT_APP_URLS from '../../../../global-helpers/global-url.helpers';

const useHocuspocusWebSocket = (
	name: string | undefined,
	onStateless: HocuspocusProviderConfiguration['onStateless'],
	onConnect?: HocuspocusProviderConfiguration['onConnect'],
	onDisconnect?: HocuspocusProviderConfiguration['onDisconnect'],
) => {
	const socket = useMemo(() => {
		const hocuspocusProviderWebsocket = new HocuspocusProviderWebsocket({
			url: REACT_APP_URLS.REACT_APP_HOCUS_POCUS_WEB_SOCKET_URL,
		});

		hocuspocusProviderWebsocket.shouldConnect = true;

		return hocuspocusProviderWebsocket;
	}, []);

	const provider = useMemo(() => {
		if (!name) {
			return;
		}

		return new HocuspocusProvider({
			websocketProvider: socket,
			name,
			onStateless,
			onConnect: () => {
				console.debug('Hocuspocus WebSocket connected.', new Date().toLocaleString());

				if (onConnect) {
					onConnect();
				}
			},
			onDisconnect: (data) => {
				console.debug('Hocuspocus WebSocket disconnected.', new Date().toLocaleString());

				if (onDisconnect) {
					onDisconnect(data);
				}
			},
		});
	}, [name]);

	return {
		provider,
		socket,
	};
};

export default useHocuspocusWebSocket;
