import 'reflect-metadata';
import { jsonMember, jsonObject } from 'typedjson';

@jsonObject
export default class AuthorSocialModel {
	@jsonMember(String)
	public slug: string = '';
	@jsonMember(String)
	public name: string = '';
	@jsonMember(String)
	public value: string = '';
}
