import * as React from 'react';
import { Label, Col } from 'reactstrap';

type TypeProps = {
	t: any;
	onChange: any;
	isActive: boolean;
};

const ActiveToggle: React.FunctionComponent<TypeProps> = ({ onChange, t, isActive }) => {
	return (
		<Col xs='6' className='d-flex align-items-center'>
			<Label className='mr-2 mb-0 ml-1' htmlFor='player-manual-data-entry-active'>
				{t('active')}
			</Label>
			<div id='player-manual-data-entry-active' className='cursor-pointer' onClick={onChange}>
				<i className={`fa fa-2x fa-toggle-${isActive ? 'on text-success' : 'off text-muted'}`} />
			</div>
		</Col>
	);
};

export default ActiveToggle;
