import { USER_LOGOUT } from '../action-creators/ProfileActionCreators';
import WikiPagesAdvancedFilterModel from '../../views/Partials/Shared/wiki-pages-advanced-content-filter/models/wiki-pages-advanced-filter.model';
import {
	WIKI_PAGES_ENTITY_DELETE_FAILED,
	WIKI_PAGES_ENTITY_DELETE_SUCCESS,
	WIKI_PAGES_FILTERS_UPDATE,
	WIKI_PAGES_LISTING_RECEIVED,
	WIKI_PAGES_PAGINATION_UPDATE,
} from '../action-creators/WikiActionCreator';
import WikiListModel from '../../models/Wikipages/List/WikiListModel';
import MetaModel from '../../models/v2/Meta/MetaModel';

interface InitialState {
	wikiPages: WikiListModel[];
	pagination: MetaModel;
	wikiPageDeleted: boolean;
	filters: WikiPagesAdvancedFilterModel;
}

const initialState: InitialState = {
	wikiPages: [],
	wikiPageDeleted: false,
	pagination: {} as MetaModel,
	filters: WikiPagesAdvancedFilterModel.builder().build(),
};

function wikiPagesReducer(state: any = initialState, action: any) {
	if (action.type === WIKI_PAGES_LISTING_RECEIVED) {
		return Object.assign({}, state, {
			wikiPages: action.payload.wikiPages,
			pagination: action.payload.contentPagination.pagination,
		});
	}

	if (action.type === WIKI_PAGES_PAGINATION_UPDATE) {
		return Object.assign({}, state, {
			pagination: action.payload.pagination,
		});
	}

	if (action.type === WIKI_PAGES_ENTITY_DELETE_SUCCESS) {
		return Object.assign({}, state, {
			wikiPageDeleted: true,
		});
	}

	if (action.type === WIKI_PAGES_ENTITY_DELETE_FAILED) {
		return Object.assign({}, state, {
			wikiPageDeleted: false,
		});
	}

	if (action.type === WIKI_PAGES_FILTERS_UPDATE) {
		return Object.assign({}, state, {
			filters: action.payload.filters,
		});
	}

	if (action.type === USER_LOGOUT) {
		state = initialState;
	}

	return state;
}

export default wikiPagesReducer;
