import { assetsPlaceholder } from '../../../../../../../../constants/assetsPlaceholder';
import PlayerModel from '../../models/player/player.model';

export const remapPlayersFromResponse = (response: any) => {
	if (response && response.length > 0) {
		return response.map((data: any) => {
			return PlayerModel.builder()
				.withId(data.player.id)
				.withHeadshot(data.player.url_thumb ? data.player.url_thumb : assetsPlaceholder.player)
				.withName(data.player.name)
				.build();
		});
	}

	return [];
};
