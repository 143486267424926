import React from 'react';
import { compose } from 'redux';
import { ViewTypes } from '../../../../constants/general.constants';
import BlockModel from '../../../../models/block.model';
import BlockUpdateController from '../../../../blocks/block-update-controller.component';
import FootballTeamProgrammeEditComponent from './football-team-programme-edit.component';
import { FootballTeamProgrammeView } from './football-team-programme-view.component';
import TeamProgrammeWidgetModel from '../models/team-programme-widget.model';
import { MonorepoWidgetPreview } from '../../../../blocks/widgets/widget-preview/monorepo-widget-preview.component';
import { SportBlockTypes, SportTypes, WidgetScriptTypes } from '../../../../../../../constants/sport.types';

type Properties = {
	block: BlockModel;
	view: ViewTypes;
	t: any;
	contentData: any;
	contentLanguage: string;
};

const FootballTeamProgrammeWidget: React.FunctionComponent<Properties> = (props) => {
	const { view, t, block, contentData, contentLanguage } = props;

	if (view === ViewTypes.edit) {
		return <FootballTeamProgrammeEditComponent block={block} t={t} contentData={contentData} contentLanguage={contentLanguage} />;
	}

	if (view === ViewTypes.preview) {
		return (
			<MonorepoWidgetPreview
				blockContent={props.block.data.content}
				config={props.block.data.config}
				type={WidgetScriptTypes.FOOTBALL_WIDGETS}
				blockType={SportBlockTypes.TOURNAMENT_BLOCK}
				sport={SportTypes.FOOTBALL}
			/>
		);
	}

	if (view === ViewTypes.normal) {
		return (
			<FootballTeamProgrammeView
				preview={
					block && block.data && block.data.preview && block.data.preview.widgetModel
						? block.data.preview.widgetModel
						: TeamProgrammeWidgetModel.builder().build()
				}
				t={t}
			/>
		);
	}

	return null;
};

export default compose(BlockUpdateController)(FootballTeamProgrammeWidget);
