import PlayerProfileWidgetModel from './player-profile-widget.model';
import { PlayerProfileWidgetJson } from './player-profile-widget.json';
import TeamModel from '../../../../partials/team-select/models/team.model';
import PlayerModel from '../../../../partials/player-select/models/player.model';
import TournamentModel from './tournament-model/tournament.model';

export default class PlayerProfileWidgetBuilder {
	readonly json: PlayerProfileWidgetJson;

	constructor(playerProfile?: PlayerProfileWidgetModel | PlayerProfileWidgetJson) {
		if (playerProfile && playerProfile instanceof PlayerProfileWidgetModel) {
			this.json = playerProfile.toJson();
		} else if (playerProfile) {
			this.json = playerProfile;
		} else {
			this.json = {} as PlayerProfileWidgetJson;
		}
	}

	withPlayer(player: PlayerModel): PlayerProfileWidgetBuilder {
		this.json.player = player;

		return this;
	}

	withTeam(team: TeamModel): PlayerProfileWidgetBuilder {
		this.json.team = team;

		return this;
	}

	withTeamForOdds(teamForOdds: TeamModel): PlayerProfileWidgetBuilder {
		this.json.teamForOdds = teamForOdds;

		return this;
	}

	withDisplayStatistics(displayStatistics: boolean): PlayerProfileWidgetBuilder {
		this.json.displayStatistics = displayStatistics;

		return this;
	}

	withStatisticType(statisticType: string): PlayerProfileWidgetBuilder {
		this.json.statisticType = statisticType;

		return this;
	}

	withDisplayOdds(displayOdds: boolean): PlayerProfileWidgetBuilder {
		this.json.displayOdds = displayOdds;

		return this;
	}

	withTournament(tournament: TournamentModel): PlayerProfileWidgetBuilder {
		this.json.tournament = tournament;

		return this;
	}

	withThreeLetterCodeType(threeLetterCodeType: string): PlayerProfileWidgetBuilder {
		this.json.threeLetterCodeType = threeLetterCodeType;

		return this;
	}

	build(): PlayerProfileWidgetModel {
		return PlayerProfileWidgetModel.fromJson(this.json);
	}
}
