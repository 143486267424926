import LinkBlockModel from './link-block.model';
import { LinkBlockJson } from './link-block.json';

export default class LinkBlockBuilder {
	private json: LinkBlockJson;

	constructor(linkBlock?: LinkBlockModel | LinkBlockJson) {
		if (linkBlock && linkBlock instanceof LinkBlockModel) {
			this.json = linkBlock.toJson();
		} else if (linkBlock) {
			this.json = linkBlock;
		} else {
			this.json = {} as LinkBlockJson;
		}
	}

	withContent(content: string): LinkBlockBuilder {
		this.json.content = content;

		return this;
	}

	withLink(link: any): LinkBlockBuilder {
		this.json.link = link;

		return this;
	}

	withLinkType(linkType: any): LinkBlockBuilder {
		this.json.linkType = linkType;

		return this;
	}

	withOpenType(open_type: any): LinkBlockBuilder {
		this.json.open_type = open_type;

		return this;
	}

	withText(text: any): LinkBlockBuilder {
		this.json.text = text;

		return this;
	}

	build(): LinkBlockModel {
		return LinkBlockModel.fromJson(this.json);
	}
}
