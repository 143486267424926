import 'reflect-metadata';
import { AnyT, jsonMember, jsonObject } from 'typedjson';
import AssetsModel from '../../sports-types/assets.model';

@jsonObject()
export default class TeamModel {
	@jsonMember(AnyT)
	public id: any = ''; // response is as number and cannot be set empty value if the type is not "any"
	@jsonMember(String)
	public name: string = '';
	@jsonMember(String)
	public type: string = '';
	@jsonMember(String)
	public gender: string = '';
	@jsonMember(String, { name: 'three_letter_code' })
	public threeLetterCode: string = '';
	@jsonMember(String, { name: 'short_name' })
	public shortName: string = '';
	@jsonMember(String, { name: 'url_logo' })
	public urlLogo: string = '';
	@jsonMember(Boolean)
	public undecided: boolean = false;
	@jsonMember(AnyT)
	public data: any;
	@jsonMember(String)
	public slug: string | null = null;
	@jsonMember(AssetsModel)
	public assets: AssetsModel = new AssetsModel();
}
