import { TypedJSON } from 'typedjson';
import RankingPlayerModel from '../../../../../../models/v2/ranking-player/ranking-player.model';

export const responseToRankingPlayersModel = (response: any) => {
	const serializer = new TypedJSON(RankingPlayerModel);
	if (response.rows.length > 0) {
		const remmapedRankingPlayers = response.rows.map((row: any) => serializer.parse(row.athlete) as RankingPlayerModel);
		return remmapedRankingPlayers;
	}

	return [];
};

export const rankingPlayersToOptions = (rankingPlayers: RankingPlayerModel[]) => {
	if (rankingPlayers && rankingPlayers.length > 0) {
		return rankingPlayers.map((player) => {
			return rankingPlayerToOption(player);
		});
	}

	return [];
};

export const rankingPlayerToOption = (player: RankingPlayerModel) => {
	if (player && player.id !== '') {
		return {
			value: player.id,
			label: player.name,
			logo: player.assets.logo.url,
			data: player,
		};
	} else {
		return {};
	}
};
