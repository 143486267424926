import { TypedJSON } from 'typedjson';
import { assetsPlaceholder } from '../../../../../../../constants/assetsPlaceholder';
import PlayerModel from '../../../../../../../models/v2/player/entity/player.model';
import { ContentTypes } from '../../../../../../../constants/content-types';

export const responseToPlayerModel = (response: any) => {
	const serializer = new TypedJSON(PlayerModel);
	const remmapedPlayer = serializer.parse(response) as PlayerModel;

	return remmapedPlayer;
};

export const playersToOptions = (players: PlayerModel[]) => {
	if (players && players.length > 0) {
		return players.map((player) => {
			return playerToOption(player);
		});
	}

	return [];
};

export const playerToOption = (player: PlayerModel) => {
	if (player) {
		return {
			value: player.id,
			label: player.name,
			data: player,
			logo:
				player.urlThumb !== ''
					? player.urlThumb
					: player.displayAsset && player.displayAsset.url && player.displayAsset.url.length > 0
					? player.displayAsset.url
					: assetsPlaceholder.player,
			type: ContentTypes.PLAYER,
		};
	} else {
		return {};
	}
};
