import React from 'react';
import './stepper.style.scss';
import { LiveBlogTypes } from '../../helpers/utility.helper';

export function Stepper({ steps, activeStep, redirect, type }) {
	function getStepClass(step) {
		let cls = 'step';
		if (activeStep === step) {
			cls += ' step-active';
		} else if (activeStep > step) {
			cls += ' step-done';
		} else {
			cls += ' step-inactive';
		}
		return cls;
	}

	function redirectToTab(label) {
		let stepData;
		if (label === 'Event details') {
			stepData = 1;
		} else if (type === LiveBlogTypes.MATCH && label === 'Sport configuration') {
			stepData = 2;
		} else if (type === LiveBlogTypes.LIVE_NEWS && label === 'Additional settings') {
			stepData = 2;
		} else {
			stepData = 3;
		}
		redirect(stepData);
		return stepData;
	}

	return (
		<>
			<div className='steps-container'>
				{steps.map((label, index) => (
					<div className={getStepClass(index)} key={index}>
						<div className='label' onClick={() => redirectToTab(label)}>
							<strong className='name'>{label}</strong>
						</div>
						{index < steps.length && <div className='line' />}
					</div>
				))}
			</div>
		</>
	);
}
