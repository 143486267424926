import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Col, Label, Row } from 'reactstrap';
import { standingTypesToOptions, standingTypeToOption, responseToStandingTypeModel } from './helpers/standing-type-select.helper';
import { customOption } from '../shared/custom-select-option';
import ErrorHandler from '../error/error-handler-component';
import StandingHttpService from '../../../../../services/rest/standings-http-service';
import StandingTypeModel from '../../../../../models/v2/standing-types/standing-type.model';
import AvailableStandingTypeModel from '../../../../../models/v2/standing-types/available-standing-type.model';
import { useTranslation } from 'react-i18next';

type Properties = {
	onStandingTypeSelect: (standingType: AvailableStandingTypeModel) => void;
	selectedStandingType: AvailableStandingTypeModel;
	isValid: boolean;
	isClearable?: boolean;
	isRequired: boolean;
	isBlocky: boolean;
	language: string;
	withLabel?: boolean;
	label?: string;
	sport: string;
	id: string;
	scope: string;
};

const StandingTypesSelectComponent: React.FunctionComponent<Properties> = (props) => {
	const [standingTypeOptions, setStandingTypesOptions] = useState<AvailableStandingTypeModel[]>([]);
	const { language, sport, selectedStandingType, id, isClearable, isRequired, isValid, scope } = props;
	const [t] = useTranslation();

	useEffect(() => {
		getAvailableStandingTypes(sport, scope, language);
	}, []);

	const updateStandingTypesOptionsState = (standingTypes: AvailableStandingTypeModel[]) => {
		setStandingTypesOptions(standingTypes);
	};

	const getAvailableStandingTypes = (sport: string, scope: string, lang: string) => {
		StandingHttpService.getStandingTypes(sport, scope, lang)
			.then((response: any) => {
				updateStandingTypesOptionsState(response.data.data.map((item: StandingTypeModel) => responseToStandingTypeModel(item)));
			})
			.catch((e: any) => e);
	};

	return (
		<Row data-qa={id}>
			<Col>
				<Label htmlFor={id}>{t('standing_type')}</Label>
				{isRequired && <span className='text-danger'>*</span>}
				<Select
					id={id}
					menuPortalTarget={document.body}
					menuPosition='absolute'
					className='w-100'
					placeholder={t('select')}
					formatOptionLabel={customOption}
					options={standingTypesToOptions(standingTypeOptions)}
					value={selectedStandingType && selectedStandingType.id ? standingTypeToOption(selectedStandingType) : []}
					noOptionsMessage={(inputValue) => inputValue && t('no_options')}
					isClearable={isClearable}
					onChange={(selection: any) => {
						if (selection) {
							props.onStandingTypeSelect(selection.data);
						} else {
							props.onStandingTypeSelect({} as AvailableStandingTypeModel);
						}
					}}
				/>
				{!isValid && (!selectedStandingType || !selectedStandingType.id) && <ErrorHandler t={t} errorMessage='field_is_required' />}
			</Col>
		</Row>
	);
};

export default StandingTypesSelectComponent as React.ComponentType<Properties>;
