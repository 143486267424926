import MatchModel from '../../../../../../../models/v2/match/entity/match.model';
import TeamModel from '../../../../../../../models/v2/Team/Entity/TeamModel';
import { FootballTeamWidgetJson } from './football-team.json';
import FootballTeamWidgetBuilder from './football-team.builder';
import EntitySeasonsSelectModel from '../../../../partials/player-seasons/model/entity-seasons-select.model';
import { RefreshTimeValuesType } from '../../../partials/refresh-time-input/helper';

export default class FootballTeamWidgetModel {
	readonly match: MatchModel;
	readonly team: TeamModel;
	readonly tournamentSeason: EntitySeasonsSelectModel;
	readonly displayTabs: boolean;
	readonly tabs: string[];
	readonly defaultTab: any;
	readonly displayOdds: boolean = false;
	readonly fansUnitedEnabled: boolean = false;
	readonly fansUnitedExpanded: boolean = false;
	readonly infoParameters: string[];
	readonly statsParameters: string[];
	readonly refreshTime: RefreshTimeValuesType;

	private constructor(
		tournamentSeason: EntitySeasonsSelectModel,
		match: MatchModel,
		team: TeamModel,
		displayTabs: boolean,
		tabs: string[],
		defaultTab: any,
		displayOdds: boolean,
		fansUnitedEnabled: boolean,
		fansUnitedExpanded: boolean,
		infoParameters: string[],
		statsParameters: string[],
		refreshTime: RefreshTimeValuesType,
	) {
		this.match = match;
		this.team = team;
		this.tournamentSeason = tournamentSeason;
		this.displayTabs = displayTabs;
		this.tabs = tabs;
		this.defaultTab = defaultTab;
		this.displayOdds = displayOdds;
		this.fansUnitedEnabled = fansUnitedEnabled;
		this.fansUnitedExpanded = fansUnitedExpanded;
		this.infoParameters = infoParameters;
		this.statsParameters = statsParameters;
		this.refreshTime = refreshTime;
	}

	toJson(): FootballTeamWidgetJson {
		return {
			match: this.match,
			team: this.team,
			tournamentSeason: this.tournamentSeason,
			displayTabs: this.displayTabs,
			tabs: this.tabs,
			defaultTab: this.defaultTab,
			displayOdds: this.displayOdds,
			fansUnitedEnabled: this.fansUnitedEnabled,
			fansUnitedExpanded: this.fansUnitedExpanded,
			infoParameters: this.infoParameters,
			statsParameters: this.statsParameters,
			refreshTime: this.refreshTime,
		};
	}

	static fromJson(json: FootballTeamWidgetJson): FootballTeamWidgetModel {
		return new FootballTeamWidgetModel(
			json.tournamentSeason,
			json.match,
			json.team,
			json.displayTabs,
			json.tabs,
			json.defaultTab,
			json.displayOdds,
			json.fansUnitedEnabled,
			json.fansUnitedExpanded,
			json.infoParameters,
			json.statsParameters,
			json.refreshTime,
		);
	}

	static builder(model?: FootballTeamWidgetModel): FootballTeamWidgetBuilder {
		return new FootballTeamWidgetBuilder(model);
	}
}
