export const selectOptions = (t) => {
	return [
		{ value: 'media-video', label: t('video'), subType: null, type: 'video' },
		{ value: 'media-gallery', label: t('gallery'), subType: null, type: 'gallery' },
		{ value: 'embed-video', label: t('embed_video'), subType: 'video', type: 'embed' },
	];
};

export const gallerySelectOptions = (t) => {
	return [{ value: 'embed-video', label: t('embed_video'), subType: 'video', type: 'embed' }];
};
