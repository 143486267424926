import PaginationMeta from '../../models/pagination/PaginationMeta';
import { USER_LOGOUT } from '../action-creators/ProfileActionCreators';
import Feed from '../../models/feed/feed';
import {
	FEED_LISTING_RECEIVED,
	FEED_ENTITY_RECEIVED,
	TEMPLATE_ENTITY_RECEIVED,
	SELECTED_DATE_UPDATE,
	SOURCE_TAB_UPDATE,
	SELECTED_FILTER_SOURCES_UPDATE,
	TEMPLATE_LISTING_RECEIVED,
	SELECTED_FEED_UPDATE,
	NOTIFICATIONS_UPDATE,
} from '../action-creators/feeds-action-creator';

interface InitialState {
	feed: Feed;
	feeds: Feed[];
	pagination: PaginationMeta;
	selectedFeed: Feed;
	sourceTab: any;
	selectedDate: any;
	selectedFilterSources: any;
	notifications: any[];
}

const initialState: InitialState = {
	feed: Feed.builder().build(),
	feeds: [],
	pagination: PaginationMeta.builder().build(),
	selectedFeed: Feed.builder().build(),
	sourceTab: { id: 'All', location: '' },
	selectedDate: {},
	notifications: [],
	selectedFilterSources: [],
};

function feedReducer(state: any = initialState, action: any) {
	if (action.type === FEED_LISTING_RECEIVED) {
		return Object.assign({}, state, {
			feeds: action.payload,
			pagination: action.payload.contentPagination,
		});
	}

	if (action.type === FEED_ENTITY_RECEIVED) {
		return Object.assign({}, state, {
			feed: action.payload.data.data,
		});
	}

	if (action.type === TEMPLATE_LISTING_RECEIVED) {
		return Object.assign({}, state, {
			templates: action.payload,
		});
	}

	if (action.type === TEMPLATE_ENTITY_RECEIVED) {
		return Object.assign({}, state, {
			feed: action.payload,
		});
	}

	if (action.type === SELECTED_FEED_UPDATE) {
		return Object.assign({}, state, {
			selectedFeed: action.payload,
		});
	}

	if (action.type === NOTIFICATIONS_UPDATE) {
		return Object.assign({}, state, {
			notifications: action.payload,
		});
	}

	if (action.type === SELECTED_DATE_UPDATE) {
		return Object.assign({}, state, {
			selectedDate: action.payload,
		});
	}

	if (action.type === SOURCE_TAB_UPDATE) {
		return Object.assign({}, state, {
			sourceTab: action.payload,
		});
	}

	if (action.type === SELECTED_FILTER_SOURCES_UPDATE) {
		return Object.assign({}, state, {
			selectedFilterSources: action.payload,
		});
	}

	if (action.type === USER_LOGOUT) {
		state = initialState;
	}

	return state;
}

export default feedReducer;
