import 'reflect-metadata';
import { jsonMember, jsonObject } from 'typedjson';

@jsonObject()
export default class TennisRoundModel {
	@jsonMember(String)
	public id: string = '';
	@jsonMember(String, { name: 'name' })
	public name: string = '';
	@jsonMember(String, { name: 'entity_type' })
	public entityType: string = '';
}
