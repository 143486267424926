import React, { FunctionComponent, useEffect, useState } from 'react';
import Select from 'react-select';
import AdminModel from '../../../../models/v2/admin/admin.model';
import AdminMapper from '../../../../models/v2/admin/admin.mapper';
import CategoriesHttp from '../../../../services/rest/CategoriesHttp';

const getAdmins = async (): Promise<AdminModel[]> => {
	try {
		const mapper = new AdminMapper();
		const response = await new CategoriesHttp().getAdmins();
		return response.map((resp: any) => {
			return mapper.responseToModel(resp);
		});
	} catch (e) {
		return [];
	}
};

export const AdminsSelect: FunctionComponent<{
	value: AdminModel[];
	onAdminSelect: (admins: AdminModel[]) => void;
	className: string;
	t?: any;
}> = ({ value, onAdminSelect, className, t }) => {
	const [admins, setAdmins] = useState([] as AdminModel[]);

	useEffect(() => {
		getAdmins().then((resp: AdminModel[]) => {
			setAdmins(resp);
		});
	}, [admins.length]);

	const mapper = new AdminMapper();
	const options = admins.map((admin: AdminModel) => {
		return mapper.modelToOption(admin);
	});

	const optionsValue = value.map((admin: AdminModel) => mapper.modelToOption(admin));

	return (
		<Select
			options={options}
			className={className}
			value={optionsValue}
			onChange={(selections: any) => {
				const admins = selections ? selections.map((selection: any) => selection.data) : [];
				onAdminSelect(admins);
			}}
			on
			isMulti
		/>
	);
};
