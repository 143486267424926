import React from 'react';
import { ListGroupItem } from 'reactstrap';
import StageModel from '../../../../../../models/v2/Stage/Entity/StageModel';
import './stage-row.scss';

type Props = {
	t: any;
	stage: StageModel;
	selectStageToEdit: Function;
	saveSeasonName: Function;
};

const StageRows: React.FunctionComponent<Props> = ({ stage, t, selectStageToEdit, saveSeasonName }) => {
	return (
		<div className='tournaments-list-wrapper' key={stage.id}>
			<ListGroupItem tag='a' action>
				<span>{stage.name}</span>
				<button
					type='button'
					className='btn btn-warning'
					value={stage.id}
					name={stage.name}
					onClick={() => {
						selectStageToEdit(stage);
						saveSeasonName();
					}}
				>
					{t('edit_teams')}
				</button>
			</ListGroupItem>
		</div>
	);
};

export default StageRows;
