import TranslationTeamModel from './translations-team.model';
import { TranslationTeamJson } from './translations-team.json';

export default class TranslationTeamBuilder {
	private json: TranslationTeamJson;

	constructor(translations?: TranslationTeamModel | TranslationTeamJson) {
		if (translations && translations instanceof TranslationTeamModel) {
			this.json = translations.toJson();
		} else if (translations) {
			this.json = translations;
		} else {
			this.json = {} as TranslationTeamJson;
		}
	}

	withTeam(team: any): TranslationTeamBuilder {
		this.json.team = team;

		return this;
	}

	withCoach(coach: any): TranslationTeamBuilder {
		this.json.coach = coach;

		return this;
	}

	withVenue(venue: any): TranslationTeamBuilder {
		this.json.venue = venue;

		return this;
	}

	withReferee(referee: any): TranslationTeamBuilder {
		this.json.referee = referee;

		return this;
	}

	withCountry(country: any): TranslationTeamBuilder {
		this.json.country = country;

		return this;
	}

	build(): TranslationTeamModel {
		return TranslationTeamModel.fromJson(this.json);
	}
}
