import React from 'react';
import TournamentSelectModel from './tournament-select.model';
import AsyncSelect from 'react-select/async';
import InstatHttpService from '../../../../../../../../services/rest/instat/InstatHttpService';
import _ from 'lodash';
import HttpService from '../../../../../../../../services/rest/HttpService';
import * as helpers from '../../../../../partials/team-select/helpers/team-select.helper';

type Properties = {
	value: TournamentSelectModel;
	onSelect: (value: TournamentSelectModel | null) => void;
};

const selectedTournamentToOption = (value: TournamentSelectModel) => {
	return value
		? {
				value: value.id,
				label: value.name,
				country_id: value.country_id,
				country_name: value.country_name,
		  }
		: null;
};

const optionToModel = (option: any): TournamentSelectModel => {
	return new TournamentSelectModel(option.value, option.label, option.country_id, option.country_name);
};
const searchTournament = (search: string, callback: any) => {
	const http = new InstatHttpService();
	http
		.getAllTournaments(search)
		.then((response: any) => {
			const options = response.map((item: any) => selectedTournamentToOption(item));
			callback(options);
		})
		.catch((e: any) => e);
};

const TournamentSelectInstat: React.FunctionComponent<Properties> = (props) => {
	return (
		<AsyncSelect
			value={selectedTournamentToOption(props.value)}
			loadOptions={_.debounce(searchTournament, 500)}
			isClearable
			onChange={(selection: any) => {
				props.onSelect(selection ? optionToModel(selection) : null);
			}}
		/>
	);
};

export default TournamentSelectInstat;
