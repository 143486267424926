import 'reflect-metadata';
import { jsonArrayMember, jsonMember, jsonObject } from 'typedjson';
import CreatedBy from '../created-by/created-by.model';
import MainMedia from '../main-media/main-media.model';
import Seo from '../seo/seo.model';
import Urls from '../urls/urls.model';

@jsonObject()
export default class TagModel {
	@jsonMember(String)
	public id: string | null = null;
	@jsonMember(String)
	public title: string | null = null;
	@jsonMember(String)
	public description: string | null = null;
	@jsonMember(String)
	public type: string | null = null;
	@jsonMember(String)
	public order_type: string | null = null;
	@jsonMember(String)
	public generic: string | null = null;
	@jsonMember(String)
	public entity_type: string | null = null;
	@jsonMember(String)
	public created_at: string | null = null;
	@jsonMember(String)
	public updated_at: string | null = null;
	@jsonMember(CreatedBy)
	public created_by: CreatedBy | null = null;
	@jsonMember(Seo)
	public seo: Seo | null = null;
	@jsonMember(Urls)
	public urls: Urls | null = null;
	@jsonArrayMember(MainMedia)
	public main_media: MainMedia | null = null;
	@jsonMember(Number)
	public weight: number | null = null;
}
