export const contentToOption = (content: any) => {
	if (content && content.id) {
		return {
			label: content.name,
			value: content.id,
			data: content,
		};
	}

	return {};
};

export const contentToOptions = (response: any) => {
	if (response && response.length > 0) {
		return response.map((content: any) => {
			return contentToOption(content);
		});
	}

	return [];
};
