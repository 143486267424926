import { OddClientBookmakersJson } from './odd-client-bookmakers.json';
import OddClientBookmakersModel from './odd-client-bookmakers.model';

export default class OddClientBookmakersBuilder {
	private json: OddClientBookmakersJson;

	constructor(model?: OddClientBookmakersModel | OddClientBookmakersJson) {
		if (model && model instanceof OddClientBookmakersModel) {
			this.json = model.toJson();
		} else if (model) {
			this.json = model;
		} else {
			this.json = {} as OddClientBookmakersJson;
		}
	}

	withId(id: string): OddClientBookmakersBuilder {
		this.json.id = id;

		return this;
	}

	withName(name: string): OddClientBookmakersBuilder {
		this.json.name = name;

		return this;
	}

	withUrl(url: string): OddClientBookmakersBuilder {
		this.json.url = url;

		return this;
	}

	withLogo(logo: string): OddClientBookmakersBuilder {
		this.json.logo = logo;

		return this;
	}

	build(): OddClientBookmakersModel {
		return OddClientBookmakersModel.fromJson(this.json);
	}
}
