import SportsMapper from '../../../../../../../models/v2/sports-types/sports.mapper';
import { featuresService } from '../../../../../../../App';
import SportsEntityMapper from '../../../../../../../models/v2/sports-types/sports-entity.mapper';
import SportsTypesModel from '../../../../../../../models/v2/sports-types/sports-types.model';
import SportsModel from '../../../../../../../models/v2/sports-types/sports.model';
import SportsConnectionsHttpService from './sports-connections-http.service';
import SportsEntitiesModel from '../../../../../../../models/v2/sports-types/sports-entities.model';
import SportsEntityModel from '../../../../../../../models/v2/sports-types/sports-entity.model';
import { FeatureTypes } from '../../../../../../../services/feature-service/features.enum';

export default class SportsConnectionsService {
	public isFootballSelected = (type: string) => {
		return type === 'football';
	};

	public checkIfFeatureSportsAreEnabled = (sports: SportsTypesModel[]) => {
		if (sports && sports.length > 0) {
			return sports.filter((sport: SportsTypesModel) => {
				const isEnabled =
					sport.sport === FeatureTypes.FOOTBALL
						? featuresService.areFeaturesEnabled([sport.sport as FeatureTypes])
						: featuresService.checkFeatureIsSetAndEnabled(sport.sport);
				return isEnabled;
			});
		}

		return [];
	};

	public getSports = async (): Promise<SportsTypesModel[]> => {
		const sports = await SportsConnectionsHttpService.getSports();
		const mapper = new SportsMapper();
		if (sports && sports.data.results && sports.data.results.length > 0) {
			const sportsMapped: SportsModel = mapper.responseToModel(sports.data);
			return this.checkIfFeatureSportsAreEnabled(sportsMapped.results);
		}

		return [];
	};

	public getCompetition = async (input: string, sportsType: string, langCode: string): Promise<SportsEntityModel[]> => {
		const competition = await SportsConnectionsHttpService.getCompetitionData(input, sportsType, langCode);
		const mapper = new SportsEntityMapper();

		if (competition && competition.data && competition.data.results && competition.data.results.length > 0) {
			const competitionsMapped: SportsEntitiesModel = mapper.responseToModel(competition.data);
			return competitionsMapped.results;
		}

		return [];
	};

	public getSportsData = async (input: string, sportType: string, entityTypes: string[], langCode: string): Promise<SportsEntityModel[]> => {
		const sportsConnectionsData = await SportsConnectionsHttpService.getSportsData(input, sportType, entityTypes, langCode);
		const mapper = new SportsEntityMapper();

		if (
			sportsConnectionsData &&
			sportsConnectionsData.data &&
			sportsConnectionsData.data.results &&
			sportsConnectionsData.data.results.length > 0
		) {
			const sportsConnectionsDataMapped: SportsEntitiesModel = mapper.responseToModel(sportsConnectionsData.data);
			return sportsConnectionsDataMapped.results;
		}
		return [];
	};
}
