import { MatchListStageRoundJson } from './match-list-stage-round-widget.json';
import TournamentModel from '../../player-profile/models/tournament-model/tournament.model';
import MatchListStageRoundWidgetBuilder from './match-list-stage-round-widget.builder';
import SeasonModel from '../../player-profile/models/season/season.model';
import StageModel from '../../fixtures/models/stage/stage.model';
import RoundModel from '../../fixtures/models/round/round.model';
import MatchListConfigModel from '../../../../partials/match-list-config/models/match-list-config.model';

export default class MatchListStageRoundModel {
	readonly tournament: TournamentModel;
	readonly season: SeasonModel;
	readonly stage: StageModel;
	readonly round: RoundModel;
	readonly config: MatchListConfigModel;

	private constructor(tournament: TournamentModel, season: SeasonModel, stage: StageModel, round: RoundModel, config: MatchListConfigModel) {
		this.tournament = tournament;
		this.season = season;
		this.stage = stage;
		this.round = round;
		this.config = config;
	}

	toJson(): MatchListStageRoundJson {
		return {
			tournament: this.tournament,
			season: this.season,
			stage: this.stage,
			round: this.round,
			config: this.config,
		};
	}

	static fromJson(json: MatchListStageRoundJson): MatchListStageRoundModel {
		return new MatchListStageRoundModel(json.tournament, json.season, json.stage, json.round, json.config);
	}

	static builder(model?: MatchListStageRoundModel): MatchListStageRoundWidgetBuilder {
		return new MatchListStageRoundWidgetBuilder(model);
	}
}
