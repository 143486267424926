import { toast } from 'react-toastify';
import BrowserPermissions, { BrowserPermissionTypes, PermissionState } from '../../../../../helpers/browser-permissions.helper';

export const isSafari = () => /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

export const copyToClipboard = (e: any, text: string, t: any, type: string) => {
	e.stopPropagation();

	isSafari() && toast.error(t('not_supported_in_safari'));
	!isSafari() && copyWithPermissions(text, t, type);
};

const copyWithPermissions = (text: any, t: any, type: string) => {
	const permissions = new BrowserPermissions();

	permissions
		.check(BrowserPermissionTypes.clipboardWrite)
		.then((perm: PermissionStatus) => {
			if (perm.state === PermissionState.granted) {
				navigator.clipboard
					.writeText(text)
					.then(() => {
						toast.success(t(`copy_${type}_success`));
					})
					.catch((e) => {
						toast.error(t(`copy_${type}_failed`));
					});
			} else if (perm.state === PermissionState.denied || perm.state === PermissionState.prompt) {
				toast.error(t('copy_paste_permissions'));
			}
		})
		.catch((e) => {
			toast.error(t('copy_paste_permissions'));
		});
};
