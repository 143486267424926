import { TypedJSON } from 'typedjson';
import { assetsPlaceholder } from '../../../../../../constants/assetsPlaceholder';
import SportTeamModel from '../models/sport-team.model';

export const teamToOption = (entity: any) => {
	if (entity && entity.id) {
		return {
			value: entity.id,
			label: entity.name,
			data: entity,
			logo: entity.logo,
			type: entity.type,
		};
	}

	return {};
};

export const entityToOption = (entities: any) => {
	let options: any[] = [];

	if (entities && entities.length > 0) {
		return entities.map((entity: any) => responseToOption(entity, entity.entity_type));
	}

	return options;
};

export const remapEntityFromResponce = (entity: any) => {
	const serializer = new TypedJSON(SportTeamModel);

	return serializer.parse(entity);
};

export const remapEntitiesFromResponse = (entities: any) => entities.map((entity: any) => remapEntityFromResponce(entity));

export const responseToOption = (entity: any, entityType: string) => {
	const logo = entity.display_asset
		? entity.display_asset.url
			? entity.display_asset.url
			: assetsPlaceholder[entityType]
		: entity.displayAsset && entity.displayAsset.url
		? entity.displayAsset.url
		: assetsPlaceholder[entityType];

	if (entity && entity.id) {
		return {
			value: entity.id,
			label: entity.name,
			logo: logo,
			type: entityType,
			data: entity,
		};
	}

	return {};
};
