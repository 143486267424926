import { MatchModel } from '../models/match.model';
import MatchModelExtended from '../../../../../../Resources/match-lineups/models/match/match.model';

export const BORDER_PULSATE_CLASS = 'border-pulsate-right';

export const applyInProgressClass = (type: string) => {
	return type === 'inprogress' ? BORDER_PULSATE_CLASS : '';
};

export const printMinutesIfMatchInProgress = (match: MatchModel | MatchModelExtended) => {
	return match.type === 'inprogress' ? `(${match.minutes}')` : '';
};

export const toggleGoals = (match: MatchModel | MatchModelExtended) => {
	return match.type === 'inprogress' || match.type === 'finished' ? ` ${match.goalHome}:${match.goalAway} ` : ` - `;
};
