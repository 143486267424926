import { ProjectJson } from './ProjectJson';
import Project from './Project';
import Feature from '../feature/Feature';
import ContentAttributes from '../content-attributes/content-attributes-model';
import ContentLanguagesModel from '../content-languages/content-languages-model';

export default class ProjectBuilder {
	private json: ProjectJson;

	constructor(project?: Project) {
		if (project && project instanceof Project) {
			this.json = project.toJSON();
		} else if (project) {
			this.json = project;
		} else {
			this.json = {} as ProjectJson;
		}
	}

	withId(id: string): ProjectBuilder {
		this.json.id = id;

		return this;
	}

	withTitle(title: string): ProjectBuilder {
		this.json.title = title;

		return this;
	}

	withDomain(domain: string): ProjectBuilder {
		this.json.domain = domain;

		return this;
	}

	withLanguage(language: string): ProjectBuilder {
		this.json.language = language;

		return this;
	}

	withLanguages(languages: ContentLanguagesModel): ProjectBuilder {
		this.json.languages = languages;

		return this;
	}

	withColorScheme(colorScheme: string): ProjectBuilder {
		this.json.colorScheme = colorScheme;

		return this;
	}

	withGoogleAnalyticsId(googleAnalyticsId: string): ProjectBuilder {
		this.json.googleAnalyticsId = googleAnalyticsId;

		return this;
	}

	withFacebookPixelCode(facebookPixelCode: string): ProjectBuilder {
		this.json.facebookPixelCode = facebookPixelCode;

		return this;
	}

	withArticleVoting(articleVoting: boolean): ProjectBuilder {
		this.json.articleVoting = articleVoting;

		return this;
	}

	withDomainImages(domainImages: string): ProjectBuilder {
		this.json.domainImages = domainImages;

		return this;
	}

	withDomainVideos(domainVideos: string): ProjectBuilder {
		this.json.domainVideos = domainVideos;

		return this;
	}

	withCommentsApproval(commentsApproval: boolean): ProjectBuilder {
		this.json.commentsApproval = commentsApproval;

		return this;
	}

	withCommentsRegisteredUsersOnly(commentsRegisteredUsersOnly: boolean): ProjectBuilder {
		this.json.commentsRegisteredUsersOnly = commentsRegisteredUsersOnly;

		return this;
	}

	withCommentsRating(commentsRating: boolean): ProjectBuilder {
		this.json.commentsRating = commentsRating;

		return this;
	}

	withCommentsThreading(commentsThreading: boolean): ProjectBuilder {
		this.json.commentsThreading = commentsThreading;

		return this;
	}

	withCommentsViewCensored(commentsViewCensored: boolean): ProjectBuilder {
		this.json.commentsViewCensored = commentsViewCensored;

		return this;
	}

	withDefaultOddClient(defaultOddClient: string): ProjectBuilder {
		this.json.defaultOddClient = defaultOddClient;

		return this;
	}

	withImageApiUrl(imageApiUrl: string): ProjectBuilder {
		this.json.imageApiUrl = imageApiUrl;

		return this;
	}

	withFootballApiUrl(footballApiUrl: string): ProjectBuilder {
		this.json.footballApiUrl = footballApiUrl;

		return this;
	}

	withDefaultTournamentOrder(defaultTournamentOrder: string): ProjectBuilder {
		this.json.defaultTournamentOrder = defaultTournamentOrder;

		return this;
	}

	withTimezone(timezone: string): ProjectBuilder {
		this.json.timezone = timezone;

		return this;
	}

	withPreviewUrl(previewUrl: string) {
		this.json.previewUrl = previewUrl;

		return this;
	}

	withFeatures(features: Feature[]) {
		this.json.features = features;

		return this;
	}

	withDistributionChannels(distributionChannels: ContentAttributes[]) {
		this.json.distributionChannels = distributionChannels;

		return this;
	}

	withDistributionRegions(distributionRegions: ContentAttributes[]) {
		this.json.distributionRegions = distributionRegions;

		return this;
	}

	build(): Project {
		return Project.fromJSON(this.json);
	}
}
