import React from 'react';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { tournamentSeasonNotSelected } from './helpers/top-scorers-widget.helper';
import { Properties, State } from './properties/top-scorers-widget-edit.properties';
import { blockManagementService, blockWrapperService } from '../../../../subcomponents/blocky.component';
import TopScorerSelectContainer from '../../../../partials/top-scorer-players-select/top-scorer-select-container.component';
import SeasonSelectContainer from '../../../../partials/season-select/season-select-container.component';
import SelectionsComponent from './subcomponents/selections.component';
import TopScorersWidgetModel from '../models/top-scorers-widget.model';
import TournamentSelectContainer from '../../../../partials/tournament-select/tournament-select-container.component';
import TournamentModel from '../../player-profile/models/tournament-model/tournament.model';
import SeasonModel from '../../player-profile/models/season/season.model';
import TeamModel from '../../../../partials/team-select/models/team.model';
import TopScorerTeamSelect from '../../../../partials/top-scorer-team-select/top-scorer-team-select.component';
import ThreeLetterCodeTypeSelect from '../../../../partials/three-letter-code-type-select/three-letter-code-type-select';

export default class TopScorersWidgetEdit extends React.Component<Properties, State> {
	constructor(props: Properties) {
		super(props);
		this.state = {
			topScorersModel:
				props.block && props.block.data.preview && props.block.data.preview.topScorers
					? TopScorersWidgetModel.builder(props.block.data.preview.topScorers).build()
					: TopScorersWidgetModel.builder().withSelections([]).withTopScorers([]).withFilter(true).withThreeLetterCodeType('').build(),
			isValid: true,
		};
	}

	componentDidMount(): void {
		blockWrapperService.registerOnSaveFunc({ blockId: this.props.block.id, func: this.onSave });
	}

	componentWillUnmount(): void {
		blockWrapperService.unregisterOnSaveFunc(this.props.block.id);
	}

	onSave = (index: number) => {
		const { topScorersModel } = this.state;
		const isValid = topScorersModel && topScorersModel.tournament && topScorersModel.tournament.id ? true : false;
		this.setState({ ...this.state, isValid });

		if (isValid) {
			const block = Object.assign({}, this.props.block);
			block.data.preview = { topScorers: this.state.topScorersModel };
			blockManagementService.blockUpdate(block, index);
		} else {
			return isValid;
		}
	};

	updateWidgetModelState = (model: TopScorersWidgetModel) => this.setState({ ...this.state, topScorersModel: model });

	onTournamentChange = (tournament: TournamentModel) => {
		this.updateWidgetModelState(
			TopScorersWidgetModel.builder(this.state.topScorersModel)
				.withTournament(tournament)
				.withTeam(TeamModel.builder().build())
				.withTopScorers([])
				.build(),
		);
	};

	onSeasonChange = (season: SeasonModel) => {
		const { topScorersModel } = this.state;
		const tournament = TournamentModel.builder(topScorersModel.tournament).withSeason(season).build();
		this.updateWidgetModelState(
			TopScorersWidgetModel.builder(topScorersModel)
				.withTournament(tournament)
				.withTeam(TeamModel.builder().build())
				.withTopScorers([])
				.build(),
		);
	};

	onTopScorersChange = (topScorers: any[]) => {
		const { topScorersModel } = this.state;
		topScorers = topScorers ? topScorers.map((topScorer: any) => topScorer.data) : [];
		this.updateWidgetModelState(TopScorersWidgetModel.builder(topScorersModel).withTopScorers(topScorers).build());
	};

	onSelectionChange = (selections: any) => {
		this.updateWidgetModelState(TopScorersWidgetModel.builder(this.state.topScorersModel).withSelections(selections).build());
	};

	onTeamChange = (team: TeamModel) => {
		this.updateWidgetModelState(TopScorersWidgetModel.builder(this.state.topScorersModel).withTeam(team).withTopScorers([]).build());
	};

	onFilterStatisticsChange = (e: any) => {
		this.updateWidgetModelState(
			TopScorersWidgetModel.builder(this.state.topScorersModel)
				.withFilter(e.target.checked)
				.withTeam(TeamModel.builder().build())
				.withTopScorers([])
				.build(),
		);
	};

	onThreeLetterCodeTypeChange = (value: any) => {
		this.updateWidgetModelState(TopScorersWidgetModel.builder(this.state.topScorersModel).withThreeLetterCodeType(value).build());
	};

	render() {
		const { t } = this.props;

		return (
			<>
				<Row className='mb-2'>
					<Col>
						<TournamentSelectContainer
							withLabel
							isBlocky
							t={this.props.t}
							selectedTournament={this.state.topScorersModel.tournament}
							onTournamentSelect={this.onTournamentChange}
							isValid={this.state.isValid}
						/>
					</Col>
				</Row>
				<Row className='mb-2'>
					<Col>
						<SeasonSelectContainer
							withLabel
							isValid={this.state.isValid}
							selectedSeason={this.state.topScorersModel.tournament && this.state.topScorersModel.tournament.season}
							tournamentId={this.state.topScorersModel.tournament && this.state.topScorersModel.tournament.id}
							onSeasonSelect={this.onSeasonChange}
							t={this.props.t}
						/>
					</Col>
				</Row>
				{this.state.topScorersModel.tournament && this.state.topScorersModel.tournament.season && (
					<Row className={'mb-2'}>
						<Col>
							<hr></hr>
							<FormGroup key={`filter-statistics-team`} check inline>
								<Label>
									<Input
										key={`display-filter-by-team-input`}
										className='form-check-input'
										type='checkbox'
										id={`filter-by-team-${this.props.block.id}`}
										name='filter-statistics-label-team'
										value='team'
										checked={this.state.topScorersModel.filter}
										onChange={this.onFilterStatisticsChange}
									/>
									{t('filter_statistics_by_team')}
								</Label>
							</FormGroup>
						</Col>
					</Row>
				)}
				{this.state.topScorersModel.tournament && this.state.topScorersModel.tournament.season && this.state.topScorersModel.filter && (
					<Row className='mb-2'>
						<Col>
							<TopScorerTeamSelect
								seasonId={
									this.state.topScorersModel.tournament &&
									this.state.topScorersModel.tournament.season &&
									this.state.topScorersModel.tournament.season.id
								}
								selectedTeam={this.state.topScorersModel.team}
								onTeamSelect={this.onTeamChange}
								t={this.props.t}
							/>
						</Col>
					</Row>
				)}

				<Row className='mb-2'>
					<Col>
						<TopScorerSelectContainer
							teamId={this.state.topScorersModel.team && this.state.topScorersModel.team.id}
							seasonId={
								this.state.topScorersModel.tournament &&
								this.state.topScorersModel.tournament.season &&
								this.state.topScorersModel.tournament.season.id
							}
							selectedTopScorers={this.state.topScorersModel.topScorers}
							onTopScorersSelect={this.onTopScorersChange}
							t={this.props.t}
							isFilterApplied={this.state.topScorersModel.filter}
						/>
					</Col>
				</Row>

				<Row>
					<Col>
						<SelectionsComponent
							t={this.props.t}
							selections={this.state.topScorersModel.selections ? this.state.topScorersModel.selections : []}
							display={!tournamentSeasonNotSelected(this.state.topScorersModel)}
							onSelectionChange={this.onSelectionChange}
						/>
					</Col>
				</Row>

				<ThreeLetterCodeTypeSelect
					t={this.props.t}
					isClearable={true}
					onThreeLetterCodeTypeChange={this.onThreeLetterCodeTypeChange}
					selectedThreeLetterCodeType={this.state.topScorersModel.threeLetterCodeType}
				/>
			</>
		);
	}
}
