import { TeamManualDataJson } from './team-manual-data.json';
import { TeamManualDataRequestJson } from './team-manual-data-request.json';
import TeamManualDataModel from './team-manual-data.model';
import Social from '../social/social';
import BlacklistModel from './blacklist/blacklist';
import EnumItem from '../enum/EnumItem';
import AssetsModel from './assets/assets';

export default class TeamManualDataBuilder {
	private json: TeamManualDataJson;

	constructor(team?: TeamManualDataModel | TeamManualDataJson) {
		if (team && team instanceof TeamManualDataModel) {
			this.json = team.toJSON();
		} else if (team) {
			this.json = team;
		} else {
			this.json = {} as TeamManualDataJson;
		}
	}

	withId(id: string): TeamManualDataBuilder {
		this.json.id = id;

		return this;
	}

	withName(name: string): TeamManualDataBuilder {
		this.json.name = name;

		return this;
	}

	withType(type: EnumItem): TeamManualDataBuilder {
		this.json.type = type;

		return this;
	}

	withCountry(country: any): TeamManualDataBuilder {
		this.json.country = country;

		return this;
	}

	withCoach(coach: any): TeamManualDataBuilder {
		this.json.coach = coach;

		return this;
	}

	withVenue(venue: any): TeamManualDataBuilder {
		this.json.venue = venue;

		return this;
	}

	withPresident(president: any): TeamManualDataBuilder {
		this.json.president = president;

		return this;
	}

	withFounded(founded: string): TeamManualDataBuilder {
		this.json.founded = founded;

		return this;
	}

	withThreeLetterCode(threeLetterCode: any): TeamManualDataBuilder {
		this.json.threeLetterCode = threeLetterCode;

		return this;
	}

	withShirtColors(shirtColor: any): TeamManualDataBuilder {
		this.json.shirtColor = shirtColor;

		return this;
	}

	withSocial(social: Social): TeamManualDataBuilder {
		this.json.social = social;

		return this;
	}

	withBlacklist(blacklist: BlacklistModel): TeamManualDataBuilder {
		this.json.blacklist = blacklist;

		return this;
	}

	withBlacklists(blacklists: BlacklistModel[]): TeamManualDataBuilder {
		this.json.blacklists = blacklists;

		return this;
	}

	withAssets(assets: AssetsModel): TeamManualDataBuilder {
		this.json.assets = assets;

		return this;
	}

	withShortName(shortName: any): TeamManualDataBuilder {
		this.json.shortName = shortName;

		return this;
	}

	build(): TeamManualDataModel {
		return TeamManualDataModel.fromJSON(this.json);
	}

	equals(team: TeamManualDataModel): boolean {
		const idEqual = this.json.id === team.id;
		const nameEqual = this.json.name === team.name;
		const isTypeEqual = this.json.type === team.type;
		const foundedEqual = this.json.founded === team.founded;
		const threeLetterCodeEqual = this.json.threeLetterCode === team.threeLetterCode;
		const countryEqual = this.json.country && this.json.country.id ? this.json.country.id === team.country.id : true;
		const coachEqual = this.json.coach ? this.json.coach === team.coach : true;
		const venueEqual = this.json.venue ? this.json.venue === team.venue : true;
		const isSocialEqueal = this.json.social ? this.json.social === team.social : true;
		const presidentEqual = this.json.president ? this.json.president === team.president : true;
		const shortNameEqual = this.json.shortName === team.shortName;

		return (
			idEqual &&
			nameEqual &&
			foundedEqual &&
			threeLetterCodeEqual &&
			isTypeEqual &&
			countryEqual &&
			coachEqual &&
			isSocialEqueal &&
			venueEqual &&
			presidentEqual &&
			shortNameEqual
		);
	}

	toRequestJson(): TeamManualDataRequestJson {
		let json = {} as TeamManualDataRequestJson;
		json.name = this.json.name;
		json.type = this.json.type.id;
		json.country_id = this.json.country ? `${this.json.country.id}` : '';

		if (this.json.founded) json.founded = this.json.founded;

		json.three_letter_code = this.json.threeLetterCode;
		json.shirt_color = this.json.shirtColor;

		if (this.json.id) json.id = `${this.json.id}`;

		if (this.json.coach && this.json.coach.id) json.coach_id = `${this.json.coach.id}`;

		if (this.json.venue && this.json.venue.id) json.venue_id = `${this.json.venue.id}`;

		if (this.json.social) json.social = Social.builder(this.json.social).toRequestJson();

		json.short_name = this.json.shortName;

		return json;
	}
}
