import React, { FunctionComponent, ChangeEvent } from 'react';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import { DebounceInput } from 'react-debounce-input';
import { DATA_QA_ATTRIBUTES } from '../../constants/data-qa.attributes';
import { ReduxURLsProps } from '../../constants/redux-seo.attributes';
import { autoGenerateReduxPropsNamingForContentModels } from '../../../../../../services/content-models-service/ContentModelService';
import { connect } from 'react-redux';

type Properties = {
	t: any;
	seoJsonLd: string;
	onSeoChange: (data: string, propertyName: ReduxURLsProps) => void;
};

const SeoJsonLDTextArea: FunctionComponent<Properties> = ({ t, seoJsonLd, onSeoChange }) => {
	const onSeoJsonLdChange = (event: ChangeEvent<HTMLInputElement>) => {
		const inputText = event.target.value || '';
		onSeoChange(inputText, ReduxURLsProps.JSON_LD);
	};

	return (
		<Row>
			<Col>
				<FormGroup>
					<Label htmlFor={DATA_QA_ATTRIBUTES.JSON_LD}>{t('seo_jsonld')}</Label>
					<DebounceInput
						element='textarea'
						className='form-control mb-2'
						id={DATA_QA_ATTRIBUTES.JSON_LD}
						placeholder={t('seo_jsonld_placeholder')}
						rows='4'
						debounceTimeout={500}
						value={seoJsonLd}
						onChange={onSeoJsonLdChange}
					/>
				</FormGroup>
			</Col>
		</Row>
	);
};

function mapStateToProps(state: any) {
	const { tempPropertyNaming, nestedTempPropertyNaming } = autoGenerateReduxPropsNamingForContentModels();

	return {
		seoJsonLd:
			(state[tempPropertyNaming][nestedTempPropertyNaming].seo && state[tempPropertyNaming][nestedTempPropertyNaming].seo.jsonld) || '',
	};
}

export default connect(mapStateToProps)(SeoJsonLDTextArea);
