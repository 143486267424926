import { Node, ReactNodeViewRenderer, mergeAttributes } from '@tiptap/react';
import { CustomNode } from '../components/subcomponents/collaborative-editor/collaborative-editor';

const createCustomNodes = (customNodes: CustomNode[]): Node[] => {
	return customNodes.map(({ name, content, group, atom, attributes, parseHTMLDetails, renderDetails, component }) => {
		return Node.create({
			name,
			content,
			group,
			atom,
			addAttributes() {
				return attributes;
			},
			parseHTML() {
				return parseHTMLDetails.map(({ tag, getAttrs }) => ({
					tag,
					getAttrs,
				}));
			},
			renderHTML({ HTMLAttributes }) {
				const result: [string, ...any[]] = [renderDetails.tag, mergeAttributes(HTMLAttributes, renderDetails.additionalAttributes || {})];

				if (content) {
					result.push(0);
				}

				return result;
			},
			addNodeView() {
				return ReactNodeViewRenderer(component);
			},

			addCommands() {
				return {
					insertAdvancedContent: (attributes) => ({ commands }) => {
						return commands.insertContent({
							type: name,
							attrs: {
								...attributes,
							},
						});
					},
				};
			},
		});
	});
};

export default createCustomNodes;
