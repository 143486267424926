import React, { FunctionComponent, useEffect } from 'react';
import { returnObjectForAllCategoryListingRequest } from '../../../../../../store/action-creators/CategoriesActionCreators';
import Project from '../../../../../../models/project/Project';
import { connect } from 'react-redux';
import AdditionalCategoriesMultiselect from './additional-categories/additional-categories-select';
import { DATA_QA_ATTRIBUTES } from '../../constants/data-qa.attributes';
import MainCategorySelect from './main-category/main-category-select';
import { wrapComponents } from '../../helpers/general-content-attributes.helper';

type Properties = {
	t: any;
	project: Project;
	userId: string;
	getCategories: (page: string, project: Project, adminId: string) => void;
};

const CategoriesSelects: FunctionComponent<Properties> = ({ t, project, userId, getCategories }) => {
	// Fetch categories initially
	useEffect(() => getCategories('1', project, userId), []);

	const componentsStartRows = [
		[<MainCategorySelect key={DATA_QA_ATTRIBUTES.MAIN_CATEGORY} t={t} />],
		[<AdditionalCategoriesMultiselect key={DATA_QA_ATTRIBUTES.ADDITIONAL_CATEGORY} t={t} />],
	];

	return <>{wrapComponents(componentsStartRows)}</>;
};

function mapStateToProps(state: any) {
	return {
		userId: state.profile.profile.id || '',
		project: state.project.currentProject,
	};
}

function mapDispatchToProps(dispatch: Function) {
	return {
		getCategories: (page: string, project: Project, adminId: string) => {
			dispatch(returnObjectForAllCategoryListingRequest(page, project, adminId));
		},
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoriesSelects);
