import YoutubeVideoBuilder from './youtube-video.builder';
import { YoutubeVideoJson } from './youtube-video.json';

export default class YoutubeVideoModel {
	readonly id: any;
	readonly etag: string = '';
	readonly snippet: any;

	private constructor(id: string, etag: string, snippet: any) {
		this.id = id;
		this.etag = etag;
		this.snippet = snippet;
	}

	toJson(): YoutubeVideoJson {
		return {
			id: this.id,
			etag: this.etag,
			snippet: this.snippet,
		};
	}

	static fromJSON(json: YoutubeVideoJson): YoutubeVideoModel {
		return new YoutubeVideoModel(json.id, json.etag, json.snippet);
	}

	static builder(video?: YoutubeVideoModel): YoutubeVideoBuilder {
		return new YoutubeVideoBuilder(video);
	}
}
