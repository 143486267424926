import { USER_LOGOUT } from '../action-creators/ProfileActionCreators';
import { PROJECT_RESOURCES_REQUEST } from '../../constants/constants';
import Wiki from '../../models/wiki/Wiki';
import {
	WIKI_TEMP_CLEAR,
	WIKI_TEMP_EDIT_UPDATE,
	WIKI_TEMP_PROPERTY_UPDATE,
	WIKI_TEMP_UPDATE,
} from '../action-creators/WikiTempActionCreator';
import {
	CONTENT_STATISTICS_WIKI_ENTITY_RECEIVED,
	WIKI_ENTITY_CREATE_SUCCESS,
	WIKI_ENTITY_RECEIVED,
} from '../action-creators/WikiActionCreator';
import { ContentMode, ContentTypes } from '../../constants/content-types';
import { createStartingEntity } from '../../services/content-models-service/ContentModelService';

interface InitialState {
	wiki: Wiki;
	wikiEdit: Wiki;
}

const initialState: InitialState = {
	wiki: createStartingEntity(ContentTypes.WIKI_PAGE, true) as Wiki,
	wikiEdit: Wiki.builder().build(),
};

function wikiTempReducer(state: any = initialState, action: any) {
	if (action.type === PROJECT_RESOURCES_REQUEST) {
		return Object.assign({}, state, initialState);
	}

	if (action.type === WIKI_TEMP_UPDATE) {
		return Object.assign({}, state, {
			wiki: action.payload,
		});
	}

	if (action.type === WIKI_TEMP_PROPERTY_UPDATE) {
		const propertyName = `wiki${action.contentMode === ContentMode.EDIT ? 'Edit' : ''}`;

		return Object.assign({}, state, {
			[propertyName]: { ...state[propertyName], ...action.payload },
		});
	}

	if (action.type === WIKI_ENTITY_RECEIVED) {
		return Object.assign({}, state, {
			wikiEdit: action.payload.wikiEdit,
		});
	}

	if (action.type === WIKI_TEMP_EDIT_UPDATE) {
		return Object.assign({}, state, {
			wikiEdit: action.payload,
		});
	}

	if (action.type === WIKI_TEMP_CLEAR) {
		return Object.assign({}, state, {
			wiki: initialState.wiki,
		});
	}

	if (action.type === WIKI_ENTITY_CREATE_SUCCESS) {
		return Object.assign({}, state, {
			wiki: initialState.wiki,
			wikiEdit: initialState.wikiEdit,
		});
	}

	if (action.type === CONTENT_STATISTICS_WIKI_ENTITY_RECEIVED) {
		return Object.assign({}, state, {
			wikiEdit: action.payload.wiki,
		});
	}

	if (action.type === USER_LOGOUT) {
		state = initialState;
	}

	return state;
}

export default wikiTempReducer;
