import 'reflect-metadata';
import { jsonMember, jsonObject } from 'typedjson';

@jsonObject()
export default class CreatedByModel {
	@jsonMember(String)
	public id: string | null = null;
	@jsonMember(String)
	public full_name: string | null = null;
}
