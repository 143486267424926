import React from 'react';
import { css } from '@emotion/core';
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import Cropper from 'cropperjs';
import 'cropperjs/dist/cropper.css';
import Select from 'react-select';
import _ from 'lodash';
import { aspectRatioSelectOptions } from '../../../../../Partials/Shared/image-crop/helpers/aspect-ratio-select-options';
import { getCropModelFromCropperInfo } from '../../../../../Partials/Shared/image-crop/helpers/image-crop-container.helper';
import Project from '../../../../../../models/project/Project';

export type CropModel = {
	left: number;
	top: number;
	right: number;
	bottom: number;
};

export type Properties = {
	t: any;
	currentProject: Project;
	open: boolean;
	onClose: (toggle: boolean) => any;
	url: string;
	updateImage: (url: string, isAutoCrop: boolean, cropInfo: CropModel) => any;
};

export type State = {
	aspectRatioSelected: string;
	cropInfo: CropModel;
};

export default class AssetsImageCropContainer extends React.Component<Properties, State> {
	private cropper = {} as Cropper;

	constructor(props: Properties) {
		super(props);
		this.state = {
			cropInfo: {} as CropModel,
			aspectRatioSelected: '1x1',
		};
	}

	private setAspectRatioSelectedState(aspectRatio: string) {
		this.setState({
			...this.state,
			aspectRatioSelected: aspectRatio,
		});
	}

	private setCropInfoStateDebounced = _.debounce((cropInfo: CropModel) => {
		this.setState({ ...this.state, cropInfo });
	}, 500);

	loadCropper(img: any, aspectRatio: number) {
		let self = this;

		if (typeof this.cropper.destroy === 'function') {
			this.cropper.destroy();
		}

		this.cropper = new Cropper(img, {
			aspectRatio: aspectRatio !== 0 ? aspectRatio : 1 / 1,
			zoomable: false,
			viewMode: 1,
			checkCrossOrigin: false,
			crop(event: any) {
				let cropModel = getCropModelFromCropperInfo(event.detail.x, event.detail.y, event.detail.width, event.detail.height);
				self.setCropInfoStateDebounced(cropModel);
			},
		});
	}

	onAspectRationSelect = (selection: any) => {
		this.setAspectRatioSelectedState(selection.label);
		this.cropper.setAspectRatio(selection.value);
	};

	updateImage = () => {
		this.props.updateImage(this.props.url, false, this.state.cropInfo);
		this.props.onClose(true);
	};

	render() {
		const { t, open, onClose, url } = this.props;

		return (
			<Modal size={'xl'} isOpen={open}>
				<ModalHeader toggle={() => onClose(true)}>{t('image_crop')}</ModalHeader>
				<ModalBody>
					<Row className={'mb-3'}>
						<Col>
							<Select options={aspectRatioSelectOptions} defaultValue={{ value: 1 / 1, label: '1x1' }} onChange={this.onAspectRationSelect} />
						</Col>
					</Row>
					<Row className={'mb-3'}>
						<Col className={'text-center'}>
							{url && url.length > 0 ? (
								<div>
									<img
										className='mw-100'
										id={'image-crop-container'}
										src={url}
										onLoad={(event: any) => this.loadCropper(event.target, 0)}
										alt={'Image for cropping'}
									/>
								</div>
							) : null}
						</Col>
					</Row>
				</ModalBody>
				<ModalFooter>
					<Button className={'btn-info mr-3'} onClick={this.updateImage}>
						{t('save_crop')}
					</Button>

					<Button className={'btn-danger text-light'} onClick={() => onClose(true)}>
						{t('cancel')}
					</Button>
				</ModalFooter>
			</Modal>
		);
	}
}
