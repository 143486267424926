import { Properties, State } from './properties/NewContentContainerProps';
import * as React from 'react';
import {
	NewContentArticle,
	NewContentAuthor,
	NewContentBanner,
	NewContentCategory,
	NewContentCoach,
	NewContentFeed,
	NewContentGallery,
	NewContentList,
	NewContentPlayer,
	NewContentPoll,
	NewContentTag,
	NewContentTeam,
	NewContentVideo,
	NewContentTournamentGroups,
} from './NewContent';

export default class NewContentContainer extends React.Component<Properties, State> {
	private newContentButtons = (t: any): any => {
		return {
			article: <NewContentArticle t={t} analytics={this.props.analytics} />,
			video: <NewContentVideo t={t} analytics={this.props.analytics} />,
			gallery: <NewContentGallery t={t} analytics={this.props.analytics} />,
			banner: <NewContentBanner t={t} analytics={this.props.analytics} />,
			list: <NewContentList contentType={this.props.contentType} location={this.props.location} t={t} analytics={this.props.analytics} />,
			author: <NewContentAuthor t={t} analytics={this.props.analytics} />,
			tag: <NewContentTag t={t} analytics={this.props.analytics} />,
			category: <NewContentCategory t={t} analytics={this.props.analytics} />,
			poll: <NewContentPoll t={t} analytics={this.props.analytics} />,
			feed: <NewContentFeed t={t} analytics={this.props.analytics} />,
			team: <NewContentTeam t={t} analytics={this.props.analytics} />,
			player: <NewContentPlayer t={t} analytics={this.props.analytics} />,
			coach: <NewContentCoach t={t} analytics={this.props.analytics} />,
			tournament_groups: <NewContentTournamentGroups t={t} analytics={this.props.analytics} />,
		};
	};

	render() {
		if (this.props.display) {
			return this.newContentButtons(this.props.t)[this.props.contentType.toLowerCase()];
		}

		return null;
	}
}
