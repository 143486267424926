import 'reflect-metadata';
import { AnyT, jsonArrayMember, jsonMember, jsonObject } from 'typedjson';
import Country from '../../Country/Country.model';
import SeasonModel from '../../Season/Entity/SeasonModel';

@jsonObject()
export default class TournamentModel {
	@jsonMember(AnyT)
	public id: any = '';
	@jsonMember(String, { name: 'name' })
	public name: string = '';
	@jsonMember(String)
	public region: string = '';
	@jsonMember(String)
	public gender: string = '';
	@jsonMember(Boolean, { name: 'regional_league' })
	public regionalLeague: boolean = false;
	@jsonMember(String)
	public type: string = '';
	@jsonMember(String, { name: 'url_logo' })
	public urlLogo: string = '';
	@jsonMember(Country)
	public country: Country = new Country();
	@jsonArrayMember(() => SeasonModel)
	public seasons?: SeasonModel[] = [];
	@jsonMember(String, { name: 'entity_type' })
	public entityType?: string = '';
	@jsonMember(String)
	public slug: string | null = null;
}
