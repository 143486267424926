import React, { FunctionComponent } from 'react';
import { DATA_QA } from '../../helpers/data-qa-attributes';
import SportSection from './sport-section/sport-section';
import MultisportModel from '../../models/sport.model';
import { getInfoIcon } from '../../../../../global-helpers/global-icons.helpers';
import { useTranslation } from 'react-i18next';

type Props = {
	isLoading: boolean;
	sportsEvents: MultisportModel[];
};

const EventsListState: FunctionComponent<Props> = ({ isLoading, sportsEvents }) => {
	const { t } = useTranslation();

	if (sportsEvents.length > 0) {
		return (
			<div className={isLoading ? 'loading-overlay' : ''}>
				{sportsEvents.map((sportEvents: MultisportModel) => (
					<SportSection key={sportEvents.sport} sport={sportEvents.sport} competitions={sportEvents.competitions} />
				))}
			</div>
		);
	}

	return (
		<div id={DATA_QA.NO_EVENTS_INFO_WRAPPER}>
			<div className='info-logo' style={{ backgroundImage: `url("${getInfoIcon}")` }}></div>
			<span> {t('no_events_by_date')} </span>
		</div>
	);
};

export default EventsListState;
