import React, { FunctionComponent, useEffect, useState } from 'react';
import LiveBlogModel from '../../models/live-blog.model';
import '../../style/live-blog-listing.style.scss';
import '../../style/live-blog.style.scss';
import {
	LiveBlogConfigurationDisabledSaveModel,
	liveBlogConfigurationEventsChange,
	liveBlogConfigurationSportChange,
	liveBlogConfigurationTeamsChange,
	liveBlogConfigurationTournamentChange,
} from '../../helpers/utility.helper';
import { Col } from 'reactstrap';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../../store/store';
import SportsTypesModel from '../../../../../models/v2/sports-types/sports-types.model';
import { SportTypes } from '../../../../../constants/sport.types';
import { LiveBlogSportSelect } from '../subcomponents/sport-select/live-blog-sports-select';
import { LiveBlogTournamentSelect } from '../subcomponents/tournament-select/live-blog-tournament-select';
import { LiveBlogTeamsSelect } from '../subcomponents/live-blog-teams-select/live-blog-teams-select.component';
import { returnActiveSeason } from '../subcomponents/live-blog-teams-select/live-blog-teams.helper';
import { LiveBlogEventsSelect } from '../subcomponents/live-blog-events/live-blog-events.component';
import LiveBlogTournamentModel from '../../models/tournaments/live-blog-tournament-model';
import LiveBlogTournamentSeasonResponseModel from '../../models/seasons/live-blog-tournament-season.model';
import LiveBlogTeamModel from '../../models/teams/live-blog-team.model';
import HttpService from '../../../../../services/rest/HttpService';
import { responseToLiveBlogSeasonModel } from '../../models/seasons/live-blog-tournament-season-response.mapper';
import LiveBlogMatchModel from '../subcomponents/live-blog-events/models/live-blog-match-model';

type Properties = {
	t: any;
	liveBlog: LiveBlogModel;
	setLiveBlog: any;
	disabledSave: LiveBlogConfigurationDisabledSaveModel;
	setDisabledSave: any;
};

export const LiveBlogSportsConfigurationComponent: FunctionComponent<Properties> = ({
	t,
	liveBlog,
	setLiveBlog,
	disabledSave,
	setDisabledSave,
}) => {
	const availableSports = useSelector((state: AppState) => state.sports.sportsTypes);
	const selectedSport: SportsTypesModel = availableSports[0];
	const [activeSeason, setActiveSeason] = useState<LiveBlogTournamentSeasonResponseModel | null>();

	useEffect(() => {
		setActiveSeason({} as LiveBlogTournamentSeasonResponseModel);
		if (liveBlog.competitions && liveBlog.competitions[0]) {
			HttpService.getTournamentById(liveBlog.competitions[0].id).then((response: any) => {
				const seasonsResponseData = response.data.seasons;
				const loadedSeasonsToModel: LiveBlogTournamentSeasonResponseModel[] = seasonsResponseData.map((season: any) =>
					responseToLiveBlogSeasonModel(season),
				);
				setActiveSeason(returnActiveSeason(loadedSeasonsToModel));
			});
		}

		if (!liveBlog.competitions) {
			liveBlog.teams = [];
			liveBlog.sport_events = [];
			setActiveSeason({} as LiveBlogTournamentSeasonResponseModel);
		}
	}, [liveBlog.competitions]);

	return (
		<>
			<Col xs='6'>
				{selectedSport && (
					<>
						<LiveBlogSportSelect
							t={t}
							liveBlog={liveBlog}
							setSelectedSport={(sport: SportsTypesModel) =>
								liveBlogConfigurationSportChange(sport, liveBlog, setLiveBlog, disabledSave, setDisabledSave)
							}
						/>
						{selectedSport.sport === SportTypes.FOOTBALL && (
							<LiveBlogTournamentSelect
								liveBlog={liveBlog}
								t={t}
								setSelectedTournaments={(tournament: LiveBlogTournamentModel | null) => {
									liveBlogConfigurationTournamentChange(tournament, liveBlog, setLiveBlog, disabledSave, setDisabledSave);
								}}
							/>
						)}
						{activeSeason && activeSeason.id && selectedSport.sport === SportTypes.FOOTBALL && (
							<LiveBlogTeamsSelect
								liveBlog={liveBlog}
								t={t}
								seasonId={activeSeason.id}
								setSelectedLiveBlogTeams={(teams: LiveBlogTeamModel[]) => liveBlogConfigurationTeamsChange(teams, liveBlog, setLiveBlog)}
							/>
						)}
					</>
				)}
			</Col>
			<Col xs='6'>
				<LiveBlogEventsSelect
					liveBlog={liveBlog}
					seasonId={activeSeason && activeSeason.id ? activeSeason.id : ''}
					setSelectedLiveBlogEvents={(events: LiveBlogMatchModel[]) =>
						liveBlogConfigurationEventsChange(events, liveBlog, setLiveBlog, disabledSave, setDisabledSave)
					}
				/>
			</Col>
		</>
	);
};
