export const statisticColumnsByPosition = (t: any) => {
	return [
		{
			position: 'keeper',
			columns: [{ value: 'name', label: t('name'), shouldBeDisplayed: 'false' }],
		},
		{
			position: 'defender',
			columns: [{ value: 'name', label: t('name'), shouldBeDisplayed: 'false' }],
		},
		{
			position: 'midfielder',
			columns: [{ value: 'name', label: t('name'), shouldBeDisplayed: 'false' }],
		},
		{
			position: 'forward',
			columns: [{ value: 'name', label: t('name'), shouldBeDisplayed: 'false' }],
		},
	];
};

export const defaultValue = (t: any) => {
	return [{ value: 'name', label: t('name'), shouldBeDisplayed: 'false' }];
};

export function removeFromState(prev: any[], current: any[]) {
	if (prev && current) {
		return prev.filter((valuePrev: any) => !current.some((valueCurrent: any) => valueCurrent.value === valuePrev.value));
	}
	return [];
}

export function updateColumns(currentStateColumns: any[], playerPosition: string, statisticColumns: any[]) {
	if (currentStateColumns) {
		let columns = currentStateColumns.map((item, index) => {
			if (item.position === playerPosition) {
				return (currentStateColumns[index] = { position: item.position, columns: statisticColumns });
			} else {
				return (currentStateColumns[index] = {
					position: item.position,
					columns: currentStateColumns[index].columns,
				});
			}
		});

		return columns;
	}
	return [];
}
