import HttpService from '../../../../../../../services/rest/HttpService';
import { toJson } from '../../../../../../Partials/Shared/image-upload/helpers/image-upload-button.helper';
import { responseToModelImage } from '../../../../../../../models/v2/image/image.mapper';
import ImageModel from '../../../../../../../models/v2/image/image.model';
import { ReactPaginateProps } from 'react-paginate';
import '../styles/style.scss';
import MainMediaModel from '../../../../../../../models/v2/main-media/main-media.model';

export const constructRequestFromFilePaths = (imagePaths: string[], headers: any, watermark?: boolean) => {
	return imagePaths.map((path: string) => HttpService.post('/images', toJson(path, watermark), headers));
};

export const normalizeResponse = (responseList: any[]) => {
	if (responseList && responseList.length > 0) {
		return responseList.map((response: any) => responseToModelImage(response.data.data));
	}

	return [];
};

export const setMainMediaData = (liveBlogMainMedia: MainMediaModel[], mainMediaData: any, mainImageMedia: MainMediaModel) => {
	const prevDataWithoutCurrentResourceType = liveBlogMainMedia.filter(
		(el) => !(el.resourceSubtype === mainImageMedia.resourceSubtype && el.resourceType === mainImageMedia.resourceType),
	);
	return [...prevDataWithoutCurrentResourceType, { ...mainMediaData }];
};

export const normalizePage = (page: number) => {
	return page < 1 ? 1 : page;
};

export const imageListFromResponse = (response: any) => {
	if (response) {
		return response.map((image: ImageModel) => {
			return responseToModelImage(image);
		});
	}

	return [];
};

export const createRequestImageUrl = (contentSearchInput: string, advancedContentSearchInput: string, page: number) => {
	advancedContentSearchInput = advancedContentSearchInput ? advancedContentSearchInput : '';

	if (contentSearchInput.length > 0 && advancedContentSearchInput.length > 0) {
		return `/v2/images/search?query=${contentSearchInput}&limit=${10}&page=${normalizePage(page)}${advancedContentSearchInput}`;
	} else if (contentSearchInput.length > 0 && advancedContentSearchInput.length < 1) {
		return `/v2/images/search?query=${contentSearchInput}&limit=${10}&page=${normalizePage(page)}`;
	} else if (contentSearchInput.length < 1 && advancedContentSearchInput.length > 0) {
		return `/v2/images/search?query=*&limit=${10}&page=${normalizePage(page)}${advancedContentSearchInput}`;
	}

	return `/images?limit=${10}&page=${normalizePage(page)}`;
};

export const PaginationProps = (t: any, currentPage: number): ReactPaginateProps => {
	return {
		previousLabel: t('previous_page'),
		nextLabel: t('next_page'),
		breakLabel: '...',
		pageCount: 0,
		breakClassName: 'break-me',
		marginPagesDisplayed: 2,
		pageRangeDisplayed: 1,
		containerClassName: 'smp-pagination',
		activeClassName: 'active',
		forcePage: currentPage - 1,
	};
};
