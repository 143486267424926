import ModelMapper from '../../models/ModelMapper';
import { toast } from 'react-toastify';
import i18n from '../../i18n';
import {
	TAG_ENTITY_CREATE,
	TAG_ENTITY_CREATE_FAILED,
	TAG_ENTITY_CREATE_SUCCESS,
	TAG_ENTITY_DELETE_SUCCESS,
	TAG_ENTITY_RECEIVED,
	TAG_ENTITY_UPDATE,
	TAG_ENTITY_UPDATE_FAILED,
	TAG_ENTITY_UPDATE_SUCCESS,
	TAG_LISTING_RECEIVED,
} from '../action-creators/TagActionCreators';
import Tag from '../../models/tag/Tag';
import { actionService } from '../../App';
import { SEARCH_IS_APPLIED } from '../action-creators/UiActionCreator';

/**
 * Remap data coming from the API to the model defined in the application
 */
export function tagMiddleware() {
	return function (next: any) {
		return function (action: any) {
			if (action) {
				if (action.type === TAG_LISTING_RECEIVED) {
					const paginationData = action.payload.data.meta.pagination;
					action.payload = ModelMapper.remapTagsFromResponse(action.payload.data.data);
					action.payload.contentPagination = ModelMapper.remapMetaPagination(paginationData);
				}

				if (action.type === TAG_ENTITY_RECEIVED) {
					action.payload.tag = ModelMapper.remapTagFromResponse(action.payload.data.data);
				}

				if (action.type === TAG_ENTITY_CREATE) {
					actionService.emitGeneralAction(TAG_ENTITY_CREATE);
					action.payload.tag = Tag.builder(action.payload.tag).toRequestJson();
				}

				if (action.type === TAG_ENTITY_CREATE_SUCCESS) {
					actionService.emitGeneralAction(TAG_ENTITY_CREATE_SUCCESS);
					//TODO replace window.dispatchEvent with actionService
					window.dispatchEvent(new CustomEvent(TAG_ENTITY_CREATE_SUCCESS, { detail: action.payload }));
					toast.success(i18n.t('tag_create_success'));
				}

				if (action.type === TAG_ENTITY_CREATE_FAILED) {
					actionService.emitGeneralAction(TAG_ENTITY_CREATE_FAILED);
					toast.error(i18n.t('tag_create_failed'));
				}

				if (action.type === TAG_ENTITY_DELETE_SUCCESS) {
					toast.success(i18n.t('tag_deleted_success'));
					//TODO replace window.dispatchEvent with actionService
					window.dispatchEvent(new Event(action.type));
				}

				if (action.type === TAG_ENTITY_UPDATE) {
					actionService.emitGeneralAction(TAG_ENTITY_UPDATE);
					action.payload.tag = Tag.builder(action.payload.tag).toRequestJson();
				}

				if (action.type === TAG_ENTITY_UPDATE_FAILED) {
					actionService.emitGeneralAction(TAG_ENTITY_UPDATE_FAILED);
					toast.error(i18n.t('tag_update_failed'));
				}

				if (action.type === TAG_ENTITY_UPDATE_SUCCESS) {
					actionService.emitGeneralAction(TAG_ENTITY_UPDATE_SUCCESS);
					toast.success(i18n.t('tag_update_success'));
				}

				if (action.type === SEARCH_IS_APPLIED) {
					actionService.emitGeneralAction(SEARCH_IS_APPLIED);
				}
			}

			next(action);
		};
	};
}
