import React, { FunctionComponent, useCallback } from 'react';
import './dynamic-select.scss';
import AsyncSelect from 'react-select/async';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { SelectOptions } from '../../../../constants/content-types';
import { OptionTypeBase, OptionsType } from 'react-select/src/types';
import { generateNoOptionsField } from '../../../Pages/CustomEntities/helpers/custom-entities.helper';

type Props = {
	invokeFunc: (option: SelectOptions) => void;
	fieldId: string;
	labelText?: string;
	placeholderText?: string;
	selectedValue?: SelectOptions;
	disabled?: boolean;
	fetchResults: (search: string) => Promise<SelectOptions[]>;
};

const DynamicSelectInputDesign: FunctionComponent<Props> = ({
	invokeFunc,
	fieldId,
	labelText,
	placeholderText,
	selectedValue,
	disabled,
	fetchResults,
}) => {
	const { t } = useTranslation();

	// Define the debounced function outside of your component's return using useCallback
	// This ensures the function is not recreated on every render, but still updates when fetchResults changes
	const debouncedFetchResults = useCallback(
		_.debounce((inputValue, callback) => {
			fetchResults(inputValue).then((results) => callback(results));
		}, 500),
		[fetchResults],
	);

	// loadOptions needs to call the debounced function, but still needs to return a promise immediately.
	// Since debounced function does not return a promise, we handle it using a callback pattern.
	const loadOptions = (inputValue: string, callback: (options: OptionsType<OptionTypeBase>) => void) => {
		debouncedFetchResults(inputValue, callback);
		// Not returning a promise here because we're using callback pattern instead
	};

	return (
		<div className='custom-dynamic-select-input-wrapper'>
			{labelText && <label htmlFor={fieldId}> {labelText}</label>}
			<AsyncSelect
				id={fieldId}
				noOptionsMessage={(inputValue) =>
					inputValue && inputValue.inputValue && inputValue.inputValue.length > 0 ? t('no_options') : generateNoOptionsField(labelText)
				}
				loadOptions={loadOptions}
				placeholder={placeholderText || t('dynamic_select_placeholder')}
				onChange={(opt) => invokeFunc(opt as SelectOptions)}
				value={selectedValue}
				disabled={!!disabled}
			/>
		</div>
	);
};

export default DynamicSelectInputDesign;
