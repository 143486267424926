import MainMedia from '../../../../../../../models/mainmedia/MainMedia';
import { featuresService } from '../../../../../../../App';
import { FeatureTypes } from '../../../../../../../services/feature-service/features.enum';
import { ContentTypes } from '../../../../../../../constants/content-types';

export enum DATA_QA_ATTRIBUTES {
	MAIN_MEDIA_ITEM_SUB_TYPE = 'main-media-image-sub-type-select',
	MAIN_MEDIA_ADDITIONAL_ITEMS_MODAL = 'main-media-additional-items-modal',
	MAIN_MEDIA_ADDITIONAL_IMAGE_SELECT_COMPONENT = 'main-media-additional-image-select-component',
	MAIN_MEDIA_ADDITIONAL_IMAGES_LIST = 'main-media-additional-images-list',
	MAIN_MEDIA_ADDITIONAL_IMAGES_CONTENT_MODAL = 'main-media-additional-images-content-modal',
}

export const DISPLAY_CHECKBOX_CONTENT_TYPES = [ContentTypes.WIKI_PAGE, ContentTypes.GALLERY, ContentTypes.ARTICLE, ContentTypes.VIDEO];

export const checkIfFeatureEnabledAndCorrectContentType = (contentType: string) => {
	const featureConfig = featuresService.getFeatureConfig(FeatureTypes.MAIN_MEDIA_ADDITIONAL_IMAGES);
	const supportedContentTypes = featureConfig.pages ? featureConfig.pages : [];

	return (
		featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.MAIN_MEDIA_ADDITIONAL_IMAGES) &&
		checkIfCorrectTypes(contentType, supportedContentTypes)
	);
};

export const subTypeToOption = (type: string, t: any) => {
	if (type) {
		return {
			value: type,
			label: t(type),
		};
	}
	return {};
};

export const subTypesToOptions = (types: string[], t: any) => {
	if (types && types.length > 0) {
		return types.map((type: string) => subTypeToOption(type, t));
	}
	return [];
};

export const findMainMediaItemAlreadySelected = (mainMediaItems: MainMedia[], currentlySelectedItem: MainMedia) => {
	return (
		mainMediaItems &&
		mainMediaItems.findIndex((item: MainMedia) => item && item.resourceSubtype == currentlySelectedItem.resourceSubtype) >= 0
	);
};

export const findEmptyItemArrIndex = (model: MainMedia[]) => {
	if (model) {
		return model.findIndex((modelData) => modelData.resourceId === '' && modelData.resourceType === '');
	}
};

export const emptyItemRowAvailable = (items: MainMedia[]) => {
	const newRowIndex = findEmptyItemArrIndex(items);
	return newRowIndex && newRowIndex >= 0;
};

export const filterMainMediaImageSubTypes = (items: MainMedia[], availableSubTypes: string[]) => {
	return items && items.filter((item) => checkIfCorrectTypes(item.resourceSubtype, availableSubTypes));
};

export const filterMainMediaImageSubTypesAvailableOptions = (item: MainMedia, availableOptions: string[]) => {
	return availableOptions && availableOptions.filter((option) => option !== item.resourceSubtype);
};

export const removeMainMediaAdditionalItem = (items: MainMedia[], itemToBeRemovedId: string, subType: string) => {
	return items.filter((item) => item.resourceId !== itemToBeRemovedId && item.resourceSubtype !== subType);
};

export const checkIfCorrectTypes = (subType: string, correctSubTypes: string[]) => {
	if (subType) {
		return correctSubTypes.includes(subType);
	}

	return false;
};
