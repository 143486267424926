import React, { FunctionComponent, useEffect, useState } from 'react';
import './content-analytics-card.component.scss';
import { Col, Label, ListGroupItem, CardBody, Row, ListGroupItemHeading } from 'reactstrap';
import { AdminsSelect } from '../../Partials/Shared/admin-select/admins-select.component';
import AdminModel from '../../../models/v2/admin/admin.model';
import { DatePicker } from './date-picker/date-time.component';
import { Line } from 'react-chartjs-2';
import ContentAnalyticsService, { ADMIN_ENTITY, CATEGORY_ENTITY } from './service/content-analytics.service';
import CategorySelectMultiContainer from '../../Partials/Sidebar/GeneralContentAttributes/subcomponents/CategorySelectMulti/CategorySelectMultiContainer';
import { store } from '../../../store/store';
import { useTranslation } from 'react-i18next';
import '../content-analytics/styles/content-analytics.styles.scss';

export const ContentAnalyticsCard: FunctionComponent<{ entityType: string }> = ({ entityType }) => {
	const [selectedEntities, setSelectedEntities] = useState([] as any[]);
	const [stats, setStats] = useState({});
	const [dateRange, setDateRange] = useState([] as any[]);

	const contentAnalyticsService = new ContentAnalyticsService();
	const shouldResetDatasets = () => {
		if (selectedEntities.length == 0) {
			setStats({ labels: [], dataSets: [] });
		}
	};

	useEffect(() => {
		selectedEntities.length > 0 &&
			contentAnalyticsService.getViewsForEntities(selectedEntities, dateRange[0], dateRange[1], entityType).then((resp) => {
				if (resp) {
					setStats(resp);
				}
			});

		shouldResetDatasets();
	}, [selectedEntities.length, dateRange[0], dateRange[1]]);

	return (
		<div className='card'>
			<CardHeader type={entityType} />
			<div className='card-body'>
				<Col>
					<div className='analytics-filters'>
						<EntitiesFilter
							selectedEntities={selectedEntities}
							onEntitiesSelect={(entities: any[]) => setSelectedEntities(entities)}
							type={entityType}
						/>
						<DatePicker
							onDateChange={(date: any[]) => {
								setDateRange(date);
							}}
						/>
					</div>
					<TotalStatsPerEntity chartData={stats} type={entityType} />
					<Line data={{ labels: stats.labels, datasets: stats.dataSets, pointBorderWidth: 100 }} />
				</Col>
			</div>
		</div>
	);
};

const TotalStatsPerEntity: FunctionComponent<{ chartData: any; type: string }> = ({ chartData, type }) => {
	const { t } = useTranslation();
	const [totalEventCount, setTotalEventCount] = useState<any>([]);
	useEffect(() => {
		let totalContentStatsList =
			chartData &&
			chartData.dataSets &&
			chartData.dataSets.map((chartDataSet: any) => {
				let totalValue: number = 0;
				chartDataSet.data.forEach((num: number) => {
					totalValue += Number(num);
				});
				return {
					label: chartDataSet.label,
					totalStats: totalValue,
					color: chartDataSet.borderColor,
				};
			});

		setTotalEventCount(totalContentStatsList);
	}, [chartData]);

	return (
		<>
			{totalEventCount && totalEventCount.length > 0 && (
				<div>
					<Label>{t('total_stats')}</Label>
					<Row className='entity-total-stats-container'>
						{totalEventCount.map((eventCountPerColumn: any) => {
							return (
								<div className='d-inline entity-total-stat'>
									<span className='dot' style={{ backgroundColor: eventCountPerColumn.color }} />
									{eventCountPerColumn.label}: <strong>{eventCountPerColumn.totalStats}</strong>
								</div>
							);
						})}
					</Row>
				</div>
			)}
		</>
	);
};

const CardHeader: FunctionComponent<{ type: string }> = ({ type }) => {
	const { t } = useTranslation();

	if (type === ADMIN_ENTITY) {
		return <div className='card-header'>{t('admins')}</div>;
	}

	if (type === CATEGORY_ENTITY) {
		return <div className='card-header'>{t('categories')}</div>;
	}

	return null;
};

const EntitiesFilter: FunctionComponent<{ selectedEntities: any[]; onEntitiesSelect: (entities: any[]) => void; type: string }> = ({
	selectedEntities,
	onEntitiesSelect,
	type,
}) => {
	const { t, i18n } = useTranslation();
	if (type === ADMIN_ENTITY) {
		return (
			<div className='entity-select'>
				<Label>{t('select_admins')}</Label>
				<AdminsSelect
					className='admin-select mb-4'
					value={selectedEntities}
					onAdminSelect={(admins: AdminModel[]) => onEntitiesSelect(admins)}
				/>
			</div>
		);
	} else if (type === CATEGORY_ENTITY) {
		const adminCategories = store.getState().category.adminCategories;
		const categories = store.getState().category.allCategories;

		return (
			<div className='entity-select'>
				<Label>{t('select_category')}</Label>
				<CategorySelectMultiContainer
					t={i18n.t}
					adminCategories={adminCategories}
					categories={categories}
					className={'admin-select mb-4'}
					selectedCategories={selectedEntities}
					onCategorySelect={(categories: any[]) => onEntitiesSelect(categories)}
				/>
			</div>
		);
	}

	return null;
};
