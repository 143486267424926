import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import ImageEdit from './image-block-edit.component';
import { ImageView } from './image-block-view.component';
import { ViewTypes } from '../../../constants/general.constants';
import { Properties, State } from './properties/image-block.properties';
import BlockUpdateController from '../../block-update-controller.component';
import ImageBlockModel from './models/image-block.model';
import HttpService from '../../../../../../services/rest/HttpService';
import Image from '../../../../../../models/image/Image';
import ModelMapper from '../../../../../../models/ModelMapper';
import ContentNotFoundController from '../content-not-found-controller.component';
import { updateEditContentTempMediaDescription } from '../../../../../../store/action-creators/ImageActionCreator';
import Project from '../../../../../../models/project/Project';
import { openTypes } from '../../../constants/link.constants';

class ImageBlock extends React.Component<Properties, State> {
	constructor(props: Properties) {
		super(props);
		this.state = {
			imageBlock:
				props.block.data && props.block.data.preview
					? props.block.data.preview.imageBlock
					: ImageBlockModel.builder()
							.withWidth('100')
							.withAlignment('center')
							.withCaption('')
							.withAlt('')
							.withRemoveWatermarkChecked(false)
							.withAddWatermarkChecked(false)
							.withImage(Image.builder().withId('').build())
							.withLinkOpenType(openTypes['sameWindow'])
							.build(),
			isLoading: false,
		};
	}

	componentDidMount(): void {
		if (this.state.imageBlock && this.state.imageBlock.image.id) {
			this.getImage(this.props.block.data.preview.imageBlock.image.id);
		}

		if (
			this.props.view === 0 &&
			this.state.imageBlock.addDescriptionToMedia &&
			this.state.imageBlock.description !== this.state.imageBlock.image.description &&
			this.props.block.data.preview.imageBlock.image.description !== this.state.imageBlock.description
		) {
			const imageModel = Image.builder()
				.withId(this.state.imageBlock.image.id)
				.withDescription(
					this.props.block.data.description ? this.props.block.data.description : this.props.block.data.preview.imageBlock.image.description,
				)
				.withAlt(this.props.block.data.alt ? this.props.block.data.alt : this.props.block.data.preview.imageBlock.image.alt)
				.withCaption(this.props.block.data.caption ? this.props.block.data.caption : this.props.block.data.preview.imageBlock.image.caption)
				.build();
			this.props.updateTempMediaDescription(imageModel, this.props.project);
		}
	}

	componentDidUpdate = (prevState: any) => {
		if (prevState.imageBlock && prevState.imageBlock.image.id !== this.state.imageBlock.image.id) {
			this.getImage(this.props.block.data.preview.imageBlock.id);
		}
	};

	toggleIsLoadingState = (isLoading: boolean) => this.setState({ ...this.state, isLoading });

	getImage(imageId: string) {
		if (imageId) {
			this.toggleIsLoadingState(true);
			HttpService.get(`/images/${imageId}`, null, { Project: this.props.domain })
				.then((response: any) => {
					const image = ModelMapper.remapImageFromResponse(response.data.data);
					const imageModel = ImageBlockModel.builder(this.state.imageBlock)
						.withImage(image)
						.withDescription(this.state.imageBlock.description ? this.state.imageBlock.description : image.description)
						.withAlt(this.state.imageBlock.alt ? this.state.imageBlock.alt : image.alt)
						.withCaption(this.state.imageBlock.caption ? this.state.imageBlock.caption : image.caption)
						.withAddWatermarkChecked(false)
						.withRemoveWatermarkChecked(false)
						.build();
					this.updateImageBlockState(imageModel);
					this.toggleIsLoadingState(false);
				})
				.catch((err: any) => {
					err.response && err.response.status === 404 && this.props.setIsContentFound(false);
					this.toggleIsLoadingState(false);
				});
		}
	}

	updateImageBlockState = (model: ImageBlockModel) => this.setState({ imageBlock: model });

	render() {
		if (this.props.view === ViewTypes.edit) {
			return (
				<ImageEdit
					imageBlock={this.state.imageBlock}
					contentData={this.props.contentData}
					block={this.props.block}
					t={this.props.t}
					updateImageBlock={this.updateImageBlockState}
					domain={this.props.domain}
				/>
			);
		} else if (this.props.view === ViewTypes.normal) {
			return this.props.renderView(<ImageView isLoading={this.state.isLoading} preview={this.state.imageBlock} t={this.props.t} />);
		}

		return null;
	}
}

function mapStateToProps(state: any) {
	return {
		domain: state.project.currentProject.domain,
		project: state.project.currentProject,
	};
}

function mapDispatchToProps(dispatch: any) {
	return {
		updateTempMediaDescription: (media: any, project: Project) => dispatch(updateEditContentTempMediaDescription(media, project)),
	};
}

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	BlockUpdateController,
)(ContentNotFoundController(ImageBlock)) as React.ComponentType<any>;
