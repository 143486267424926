import React, { FunctionComponent } from 'react';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import { featuresService } from '../../../../../../App';
import { FeatureTypes } from '../../../../../../services/feature-service/features.enum';
import { extractContentModeBasedOnUrl, extractMainContentTypeBasedOnUrl } from '../../../../../../global-helpers/global.helpers';
import { ContentTypes } from '../../../../../../constants/content-types';
import { autoGenerateReduxPropsNamingForContentModels } from '../../../../../../services/content-models-service/ContentModelService';
import VideoUrlModel from '../../../../../../models/v2/video-url/video-url.model';
import { updateTempVideoProperty } from '../../../../../../store/action-creators/VideoTempActionCreator';
import { connect } from 'react-redux';
import { DATA_QA_ATTRIBUTES } from '../../constants/data-qa.attributes';
import VideoUrlsComponent from './video-urls.component';

type Properties = {
	t: any;
	videoUrls: VideoUrlModel[];
	updateTempVideo: (propertyObject: Record<string, any>) => void;
};

const VideoUrlsContainer: FunctionComponent<Properties> = ({ t, videoUrls, updateTempVideo }) => {
	const isFeatureEnabled = featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.VIDEO_URLS);
	const onVideoUrlsChange = (data: VideoUrlModel[]) => updateTempVideo({ videoFiles: data });

	if (!isFeatureEnabled || extractMainContentTypeBasedOnUrl() !== ContentTypes.VIDEO) {
		return null;
	}

	return (
		<Row>
			<Col>
				<FormGroup>
					<Label htmlFor={DATA_QA_ATTRIBUTES.VIDEO_URLS}>{t('video_types')}</Label>
					<VideoUrlsComponent t={t} urls={[...videoUrls]} onVideoUrlsChange={onVideoUrlsChange} />
				</FormGroup>
			</Col>
		</Row>
	);
};

function mapStateToProps(state: any) {
	const { tempPropertyNaming, nestedTempPropertyNaming } = autoGenerateReduxPropsNamingForContentModels();

	return {
		videoUrls: state[tempPropertyNaming][nestedTempPropertyNaming].videoFiles || [],
	};
}

function mapDispatchToProps(dispatch: Function) {
	return {
		updateTempVideo: (propertyObject: Record<string, any>) =>
			dispatch(updateTempVideoProperty(propertyObject, extractContentModeBasedOnUrl())),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(VideoUrlsContainer);
