import { StandingsWidgetJson } from './standings-widget.json';
import StandingsWidgetBuilder from './standings-widget.builder';
import TournamentModel from '../../player-profile/models/tournament-model/tournament.model';
import SeasonModel from '../../player-profile/models/season/season.model';
import StageModel from '../../fixtures/models/stage/stage.model';
import GroupModel from '../../../../partials/stage-groups-select/models/group.model';
import TeamModel from '../../../../partials/team-select/models/team.model';

export default class StandingsWidgetModel {
	readonly tournament: TournamentModel;
	readonly stage: StageModel;
	readonly season: SeasonModel;
	readonly group: GroupModel[];
	readonly stageStandings: any;
	readonly limit: string = '20';
	readonly startFrom: string = '1';
	readonly highlightTeams: TeamModel[];
	readonly team: TeamModel;
	readonly offset: string;
	readonly displayLastMatchesResults: boolean = false;
	readonly threeLetterCodeType: any = '';

	private constructor(
		tournament: TournamentModel,
		season: SeasonModel,
		stage: StageModel,
		group: GroupModel[],
		stageStandings: any,
		limit: string,
		startFrom: string,
		highlightTeams: TeamModel[],
		team: TeamModel,
		offset: string,
		displayLastMatchesResults: boolean = false,
		threeLetterCodeType: any,
	) {
		this.tournament = tournament;
		this.season = season;
		this.stage = stage;
		this.group = group;
		this.stageStandings = stageStandings;
		this.limit = limit;
		this.startFrom = startFrom;
		this.highlightTeams = highlightTeams;
		this.team = team;
		this.offset = offset;
		this.displayLastMatchesResults = displayLastMatchesResults;
		this.threeLetterCodeType = threeLetterCodeType;
	}

	toJson(): StandingsWidgetJson {
		return {
			tournament: this.tournament,
			season: this.season,
			stage: this.stage,
			group: this.group,
			stageStandings: this.stageStandings,
			limit: this.limit,
			startFrom: this.startFrom,
			highlightTeams: this.highlightTeams,
			team: this.team,
			offset: this.offset,
			displayLastMatchesResults: this.displayLastMatchesResults,
			threeLetterCodeType: this.threeLetterCodeType,
		};
	}

	static fromJson(json: StandingsWidgetJson): StandingsWidgetModel {
		return new StandingsWidgetModel(
			json.tournament,
			json.season,
			json.stage,
			json.group,
			json.stageStandings,
			json.limit,
			json.startFrom,
			json.highlightTeams,
			json.team,
			json.offset,
			json.displayLastMatchesResults,
			json.threeLetterCodeType,
		);
	}

	static builder(standings?: StandingsWidgetModel): StandingsWidgetBuilder {
		return new StandingsWidgetBuilder(standings);
	}
}
