import { TreeItem } from 'react-sortable-tree';
import { MultisportModalsType } from '../../views/Pages/Multisport-widget/components/modals/constants';
import { TOP_EVENTS_SPORT } from '../../views/Pages/Multisport-widget/helpers/utils';
import MultisportEventModel from '../../views/Pages/Multisport-widget/models/event.model';
import MultisportModel from '../../views/Pages/Multisport-widget/models/sport.model';

export const ADD_MULTISPORT_ENTITIES = '[ACTION] ADD_MULTISPORT_ENTITIES';
export const LOAD_MULTISPORT_ENTITIES = '[ACTION] LOAD_MULTISPORT_ENTITIES';
export const SORT_MULTISPORT_ENTITIES = '[ACTION] SORT_MULTISPORT_ENTITIES';
export const REMOVE_MULTISPORT_ENTITIES = '[ACTION] REMOVE_MULTISPORT_ENTITIES';
export const SET_MULTISPORT_CONFIGURATION_DATE = '[ACTION] SET_MULTISPORT_CONFIGURATION_DATE';
export const SET_MULTISPORT_EVENT_SEARCH_DATE = '[ACTION] SET_MULTISPORT_EVENT_SEARCH_DATE';
export const POPULATE_MULTISPORT_MODAL = '[ACTION] POPULATE_MULTISPORT_MODAL';
export const CLOSE_MULTISPORT_MODAL = '[ACTION] CLOSE_MULTISPORT_MODAL';
export const RESET_MULTISPORT_DATA = '[ACTION] RESET_MULTISPORT_DATA';

export function addMultisportEntities(events: MultisportEventModel[], isTopEvents: boolean) {
	if (events.length > 0) {
		return {
			type: ADD_MULTISPORT_ENTITIES,
			payload: { events, sport: isTopEvents ? TOP_EVENTS_SPORT : events[0].sport },
		};
	}
}

export function loadMultisportEntities(data: MultisportModel[]) {
	return {
		type: LOAD_MULTISPORT_ENTITIES,
		payload: data,
	};
}

export function pushDataToModal(modalType: MultisportModalsType, data: any) {
	return {
		type: POPULATE_MULTISPORT_MODAL,
		payload: { data, modalType },
	};
}

export function sortDataInRedux(newTreeData: TreeItem) {
	return {
		type: SORT_MULTISPORT_ENTITIES,
		payload: newTreeData,
	};
}

export function removeEntity() {
	return {
		type: REMOVE_MULTISPORT_ENTITIES,
	};
}

export function setMultisportConfigurationDate(date: Date) {
	return {
		type: SET_MULTISPORT_CONFIGURATION_DATE,
		payload: date,
	};
}

export function setMultisportEventSearchDate(date: Date) {
	return {
		type: SET_MULTISPORT_EVENT_SEARCH_DATE,
		payload: date,
	};
}

export function closeModal(modalType: MultisportModalsType) {
	return {
		type: CLOSE_MULTISPORT_MODAL,
		payload: modalType,
	};
}

export function resetMultisportData() {
	return {
		type: RESET_MULTISPORT_DATA,
	};
}
