import EntitySeasonsSelectModel from '../model/entity-seasons-select.model';

export const mapResponseToModel = (data: any) => {
	const model = new EntitySeasonsSelectModel();

	model.seasonId = data.season.id;
	model.seasonName = data.season.name;
	model.tournamentId = data.tournament.id;
	model.tournamentName = data.tournament.name;

	return model;
};

export const mapResponseListToPlayerSeasonsModel = (response: any) => {
	if (response && response.length > 0) {
		return response.map((item: any) => mapResponseToModel(item));
	} else return [];
};

export const createTournamentGroupOptions = (modelList: EntitySeasonsSelectModel[]) => {
	let options: any = [];

	if (modelList && modelList.length > 0) {
		const tournaments = extractTournaments(modelList);

		tournaments.forEach((tournament: any) => {
			const tournamentGroups: any = [];
			modelList.forEach((model: any) => {
				if (model.tournamentName === tournament) {
					tournamentGroups.push({
						label: model.tournamentName + '-' + model.seasonName,
						value: model.seasonId,
						data: model,
					});
				}
			});

			const option = { label: tournament, options: [...tournamentGroups] };
			options.push(option);
		});
	}

	return options;
};

export const extractTournaments = (modelList: EntitySeasonsSelectModel[]) => {
	if (modelList && modelList.length > 0) {
		let tournaments = modelList.map((model: EntitySeasonsSelectModel) => model.tournamentName);

		return onlyUnique(tournaments);
	}

	return [];
};

const onlyUnique = (array: any[]) => {
	return array.filter((value: any, index: any, a: any) => a.indexOf(value) === index);
};
