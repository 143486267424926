import moment from 'moment';

// Group recieved statistics by resource type /articles, videos, galleries, images/
// when GET request is for all types.
export const groupStatisticsByType = (response: any, resourceType?: string): any => {
	const filteredTimeseries = filterTimeseries(response, resourceType);

	if (response) {
		let statistic = {};

		if (resourceType === 'all') {
			statistic = {
				article: { timeseries: extractTimeseries(filteredTimeseries[0]) },
				video: { timeseries: extractTimeseries(filteredTimeseries[1]) },
				gallery: { timeseries: extractTimeseries(filteredTimeseries[2]) },
				image: { timeseries: extractTimeseries(filteredTimeseries[3]) },
			};
		} else {
			let type = resourceType ? resourceType : '';
			statistic[type] = { timeseries: extractTimeseries(filteredTimeseries) };
		}

		return statistic;
	} else return {};
};

// Take all values from response data timeseries property and flat them in array so we can filter
// the values by resource type /articles, videos, galleries, images/.

export const filterTimeseries = (response: any, resourceType?: string) => {
	const types = ['article', 'video', 'gallery', 'image'];

	if (response && response.timeseries) {
		return resourceType === 'all'
			? types.map((type: string) =>
					Object.values(response.timeseries)
						.flat(1)
						.filter((item: any) => item.type === type),
			  )
			: Object.values(response.timeseries).flat(1);
	} else return [];
};

// After filtering the timeseries by resource type is made the same structure as in the response but
// grouped by type.
export const extractTimeseries = (statisticsByType: any) => {
	let timeseries = {};

	if (statisticsByType && statisticsByType.length > 0) {
		statisticsByType.map((item: any) => (timeseries[item.date] = item));
	}

	return timeseries;
};

// We get start_date and end_date to define all dates between that range so we can add
// missing dates in it.
export const timeRange = (statisticsContext: any) => {
	const timeRangeArray: any = [];

	if (statisticsContext.start_date && statisticsContext.end_date) {
		const dateStart = moment(statisticsContext.start_date);
		const dateEnd = moment(statisticsContext.end_date);

		while (dateEnd > dateStart || dateStart.format('D') === dateEnd.format('D')) {
			timeRangeArray.push(dateStart.format('YYYY-MM-DD'));
			dateStart.add(1, statisticsContext.group_by);
		}
	}

	return timeRangeArray;
};
