import React from 'react';
import { Row, Col, Input, Label } from 'reactstrap';
import { updateTranslationAbbreviation, updateTranslationName, updateTranslationShortName } from '../helpers/translations.helpers';
import TranslationTeamModel from '../models/translations-team.model';
import EnumItem from '../../../../models/enum/EnumItem';

type Properties = {
	t: any;
	language: EnumItem;
	teamEntity: TranslationTeamModel;
	translationChange: (translations: any[]) => void;
	translations: any[];
};

export default class TeamTranslationsContainer extends React.Component<Properties> {
	onTranslationContentNameChange = (e: any, index: number) => {
		const translations = updateTranslationName(this.props.translations, index, e);
		this.props.translationChange(translations);
	};

	onTranslationContentAbbreviationChange = (e: any, index: number) => {
		const translations = updateTranslationAbbreviation(this.props.translations, index, e);
		this.props.translationChange(translations);
	};

	onTranslationContentShortNameChange = (e: any, index: number) => {
		const translations = updateTranslationShortName(this.props.translations, index, e);
		this.props.translationChange(translations);
	};

	render() {
		const { t, teamEntity, translations } = this.props;

		return (
			<div className='animated fadeIn fadeOut'>
				{Object.keys(teamEntity)
					.filter((key: string) => teamEntity[key].id !== undefined)
					.map((key: string, index: number) => {
						if (teamEntity[key].id) {
							return (
								<Row key={key} className='my-2'>
									<Col xs='6'>
										<Label>{t(`${key}_name`)}</Label>
										<Input disabled value={teamEntity[key].name} />
										{key === 'team' && (
											<>
												<Row className='my-2'>
													<Col>
														<Label>{t('team_abbreviation')}</Label>
														<Input disabled value={teamEntity[key].threeLetterCode !== null ? teamEntity[key].threeLetterCode : ''} />
													</Col>
												</Row>
												<Row className='my-2'>
													<Col>
														<Label>{t('team_short_name')}</Label>
														<Input disabled value={teamEntity[key].shortName !== null ? teamEntity[key].shortName : ''} />
													</Col>
												</Row>
											</>
										)}
									</Col>
									<Col xs='6'>
										<Label>{t('translation')}</Label>
										<Input
											placeholder={t('translate')}
											value={translations[index] ? translations[index].content.name : ''}
											onChange={(e: any) => this.onTranslationContentNameChange(e, index)}
										/>

										{key === 'team' && (
											<>
												<Row className='my-2'>
													<Col>
														<Label>{t('translation')}</Label>
														<Input
															placeholder={t('translate')}
															value={translations[index].content.three_letter_code !== null ? translations[index].content.three_letter_code : ''}
															maxLength={3}
															onChange={(e: any) => this.onTranslationContentAbbreviationChange(e, index)}
														/>
														{translations[index].content.three_letter_code &&
														translations[index].content.three_letter_code.length < 3 &&
														translations[index].content.three_letter_code.length > 0 ? (
															<small className='text-danger'>{t('three_letter_validation')}</small>
														) : null}
													</Col>
												</Row>
												<Row className='my-2'>
													<Col>
														<Label>{t('translation')}</Label>
														<Input
															placeholder={t('translate')}
															value={translations[index].content.short_name !== null ? translations[index].content.short_name : ''}
															minLength={3}
															maxLength={20}
															onChange={(e: any) => this.onTranslationContentShortNameChange(e, index)}
														/>
														{translations[index].content.short_name &&
														translations[index].content.short_name.length > 0 &&
														translations[index].content.short_name.length < 3 ? (
															<small className='text-danger'>{t('short_name_validation')}</small>
														) : null}
													</Col>
												</Row>
											</>
										)}
									</Col>
								</Row>
							);
						}
						return null;
					})}
			</div>
		);
	}
}
