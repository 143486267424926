import { BlacklistJson } from './blacklist.json';
import BlacklistBuilder from './blacklist.builder';

export default class BlacklistModel {
	readonly id: string;
	readonly entityId: string;
	readonly entity: string;
	readonly type: string;

	private constructor(id: string, entityId: string, entity: string, type: string) {
		this.id = id;
		this.entityId = entityId;
		this.entity = entity;
		this.type = type;
	}

	toJson(): BlacklistJson {
		return {
			id: this.id,
			entity_id: this.entityId,
			entity: this.entity,
			type: this.type,
		};
	}

	static fromJson(json: BlacklistJson): BlacklistModel {
		return new BlacklistModel(json.id, json.entity_id, json.entity, json.type);
	}
	static builder(status?: BlacklistModel): BlacklistBuilder {
		return new BlacklistBuilder(status);
	}
}
