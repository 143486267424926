import * as React from 'react';
import { useEffect, useState } from 'react';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../../../store/store';
import Project from '../../../../../../models/project/Project';
import LiveBlogAdvancedFiltersModel from '../../../models/live-blog-advanced-filters.model';
import SportsTypesModel from '../../../../../../models/v2/sports-types/sports-types.model';
import { generateFiltersMdClass } from '../../../../../Partials/Shared/wiki-pages-advanced-content-filter/helpers/wiki-pages-advanced-content-filter.helper';
import { SportsTypeSelect } from '../../../../../Partials/Shared/advanced-content-filter/subcomponents/v2/sports-select/sports-type-select.component';
import { featuresService, sportsConnectionsService } from '../../../../../../App';
import { FeatureTypes } from '../../../../../../services/feature-service/features.enum';
import FootballConnectionsContainer from '../../../../../Partials/Sidebar/tags/subcomponents/football-connections/football-connections-container';
import Related from '../../../../../../models/related/Related';
import { DATA_QA_ATTRIBUTES } from '../../../../../Partials/Sidebar/general-content/constants/data-qa.attributes';
import Select from 'react-select';
import { liveBlogStatusOptions, statusesToOption, statusesToOptions } from '../status/status.helper';
import { liveBlogTypesOptions, typesToOption, typesToOptions } from '../live-blog-type-select/live-blog-type-select.helper';
import { SportTypes } from '../../../../../../constants/sport.types';

type Properties = {
	currentProject: Project;
	t: any;
	updateLiveBlogsAdvancedSearchText: (liveBlogsAdvancedFilterModel: LiveBlogAdvancedFiltersModel) => any;
	filtersInSidebar: boolean;
	liveBlogsAdvancedFilterModel: LiveBlogAdvancedFiltersModel;
};

{
	/*{//TODO remove when liveblog supports other sports than football}*/
}
const defaultSportsData = {
	sport: 'football',
	title: 'Football',
	entity_types: [
		{
			name: 'player',
		},
		{
			name: 'team',
			type: 'event_participant',
		},
		{
			name: 'coach',
		},
		{
			name: 'venue',
		},
		{
			name: 'referee',
		},
		{
			name: 'tournament',
		},
		{
			name: 'city',
		},
		{
			name: 'president',
		},
		{
			name: 'country',
		},
	],
} as SportsTypesModel;

export const LiveBlogsAdvancedFiltersFields: React.FunctionComponent<Properties> = ({
	currentProject,
	updateLiveBlogsAdvancedSearchText,
	liveBlogsAdvancedFilterModel,
	filtersInSidebar,
	t,
}) => {
	const sports = useSelector((state: AppState) => state.sports.sportsTypes.filter((sport: any) => sport.sport === SportTypes.FOOTBALL));
	const [sportsType, setSportsType] = useState<SportsTypesModel>(
		liveBlogsAdvancedFilterModel && liveBlogsAdvancedFilterModel.sport && liveBlogsAdvancedFilterModel.sport.sport
			? liveBlogsAdvancedFilterModel.sport
			: defaultSportsData,
	);

	useEffect(() => {
		setSportsType(
			liveBlogsAdvancedFilterModel.sport && liveBlogsAdvancedFilterModel.sport.sport ? liveBlogsAdvancedFilterModel.sport : defaultSportsData,
		);
	}, [liveBlogsAdvancedFilterModel]);

	const onSportsDataSelect = (value: Related[]) => {
		let advancedContentModel = liveBlogsAdvancedFilterModel;
		advancedContentModel.relatedSports = value;
		updateLiveBlogsAdvancedSearchText(advancedContentModel);
	};

	const onSportsTypeSelect = (type: any) => {
		setSportsType(type);
		let advancedContentModel = liveBlogsAdvancedFilterModel;
		advancedContentModel.sport = type;
		advancedContentModel.relatedSports = [];

		updateLiveBlogsAdvancedSearchText(advancedContentModel);
	};

	const onStatusSelect = (status: string) => {
		let advancedContentModel = liveBlogsAdvancedFilterModel;
		advancedContentModel.status = status;
		updateLiveBlogsAdvancedSearchText(advancedContentModel);
	};

	const onTypeSelect = (type: string) => {
		let advancedContentModel = liveBlogsAdvancedFilterModel;
		advancedContentModel.type = type;
		updateLiveBlogsAdvancedSearchText(advancedContentModel);
	};

	return (
		<>
			{/*<Col col='6' sm='12' md={generateFiltersMdClass(filtersInSidebar)}>*/}
			{/*	<FormGroup>*/}
			{/*		<SportsTypeSelect*/}
			{/*			sports={sports}*/}
			{/*			selectedSportsType={liveBlogsAdvancedFilterModel.sport}*/}
			{/*			onSportsTypeSelect={onSportsTypeSelect}*/}
			{/*			t={t}*/}
			{/*		/>*/}
			{/*	</FormGroup>*/}
			{/*</Col>*/}
			{/*{//TODO remove is football selected check when the football data is migrated to sports-search-a}*/}
			{featuresService.areFeaturesEnabled([FeatureTypes.FOOTBALL, FeatureTypes.FOOTBALL_CONNECTIONS]) &&
				sportsConnectionsService.isFootballSelected(sportsType.sport) && (
					<Col col='6' sm='12' md={generateFiltersMdClass(filtersInSidebar)}>
						<FormGroup>
							<Label htmlFor='football-connections'>{t('football_connections')}</Label>
							<FootballConnectionsContainer
								related={liveBlogsAdvancedFilterModel.relatedSports ? liveBlogsAdvancedFilterModel.relatedSports : []}
								inputId={`live-blogs-advanced-filters-football-connections-select`}
								onSelect={onSportsDataSelect}
								footballApiUrl={currentProject.footballApiUrl}
								allowMoreFootballConnections={true}
								t={t}
								contentLanguage={currentProject.language}
								inWikiPagesComponent={false}
							/>
						</FormGroup>
					</Col>
				)}
			<Col col='6' sm='12' md={generateFiltersMdClass(filtersInSidebar)}>
				<Label htmlFor={DATA_QA_ATTRIBUTES.LIVE_BLOG_STATUS}>{t('status')}</Label>
				<Select
					id={DATA_QA_ATTRIBUTES.LIVE_BLOG_STATUS}
					options={statusesToOptions(liveBlogStatusOptions, t)}
					value={statusesToOption(liveBlogsAdvancedFilterModel.status, t)}
					placeholder={t('select')}
					noOptionsMessage={(inputValue) => inputValue && t('no_options')}
					onChange={(selection: any) => onStatusSelect(selection && selection.value)}
				/>
			</Col>
			<Col col='6' sm='12' md={generateFiltersMdClass(filtersInSidebar)}>
				<Label htmlFor={DATA_QA_ATTRIBUTES.LIVE_BLOG_TYPE}>{t('type')}</Label>
				<Select
					id={DATA_QA_ATTRIBUTES.LIVE_BLOG_STATUS}
					options={typesToOptions(liveBlogTypesOptions, t)}
					value={typesToOption(liveBlogsAdvancedFilterModel.type, t)}
					placeholder={t('select')}
					noOptionsMessage={(inputValue) => inputValue && t('no_options')}
					onChange={(selection: any) => onTypeSelect(selection && selection.value)}
				/>
			</Col>
		</>
	);
};
