import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../../../../store/store';
import { CustomEntitiesSearchFiltersModel } from '../../../models/models';
import { constructCustomEntitiesSearchContentQuery } from '../../../helpers/custom-entities.helper';
import { returnObjectForCustomEntitiesFiltersUpdate } from '../../../../../../store/action-creators/custom-entities-action-creator';
import { CustomEntitiesDomainSelect } from './custom-entities-domains-select.component';
import { DATA_QA } from '../../../helpers/data-qa.properties';
import { CustomEntitiesEntityTypesSelect } from './custom-entities-entity-types-select.component';
import '../../../styles/custom-entities-listing.style.scss';

type Properties = {
	updateCustomEntitiesAdvancedSearchText: (input: string) => void;
	contentSearchInput: string;
};

export const CustomEntitiesAdvancedContentFilter: React.FunctionComponent<Properties> = (props) => {
	const dispatch = useDispatch();
	const customEntitiesFilters = useSelector((state: AppState) => state.customEntities.filters);
	const [customEntitiesAdvancedFiltersModel, setCustomEntitiesAdvancedFiltersModel] = useState<CustomEntitiesSearchFiltersModel>(
		customEntitiesFilters ? customEntitiesFilters : ({} as CustomEntitiesSearchFiltersModel),
	);

	useEffect(() => {
		const customEntitiesAdvancedContentSearchText = constructCustomEntitiesSearchContentQuery(customEntitiesAdvancedFiltersModel);
		props.updateCustomEntitiesAdvancedSearchText(customEntitiesAdvancedContentSearchText);
	}, []);

	const updateCustomEntitiesAdvancedSearchText = (advancedContentModel: CustomEntitiesSearchFiltersModel) => {
		const advancedContentSearchText = constructCustomEntitiesSearchContentQuery(advancedContentModel);
		props.updateCustomEntitiesAdvancedSearchText(advancedContentSearchText);

		let filters = advancedContentModel;
		if (filters) {
			filters.search_text = props.contentSearchInput;
		}

		dispatch(returnObjectForCustomEntitiesFiltersUpdate(filters));
		setCustomEntitiesAdvancedFiltersModel(advancedContentModel);
	};

	return (
		<div data-qa={DATA_QA.CUSTOM_ENTITIES_ADVANCED_FILTERS_SEARCH} className='advance-filters-group'>
			<CustomEntitiesDomainSelect
				customEntitiesAdvancedFiltersModel={customEntitiesAdvancedFiltersModel}
				updateCustomEntitiesAdvancedSearchText={updateCustomEntitiesAdvancedSearchText}
			/>
			<CustomEntitiesEntityTypesSelect
				customEntitiesAdvancedFiltersModel={customEntitiesAdvancedFiltersModel}
				updateCustomEntitiesAdvancedSearchText={updateCustomEntitiesAdvancedSearchText}
			/>
		</div>
	);
};
