import 'reflect-metadata';
import { jsonArrayMember, jsonMember, jsonObject } from 'typedjson';
import ListConfigurationModel from './list-configuration';
import ListItemModel from './list-item';

@jsonObject
export default class ListModel {
	@jsonMember(String)
	public id: string = '';

	@jsonMember(String)
	public title: string = '';

	@jsonMember(String, { name: 'created_at' })
	public createdAt: string = '';

	@jsonMember(String, { name: 'updated_at' })
	public updatedAt: string = '';

	@jsonMember(String)
	public slug: string = '';

	@jsonMember(ListConfigurationModel)
	public configuration: ListConfigurationModel | null = null;

	@jsonArrayMember(ListItemModel)
	public items: ListItemModel[] = [];

	@jsonMember(String)
	public type: string = '';

	@jsonMember(String)
	public status: string = '';

	@jsonArrayMember(Number, { name: 'locked_positions' })
	public lockedPositions: number[] = [];

	@jsonArrayMember(String)
	public authors: string[] = [];

	@jsonMember(String)
	public language: string = '';

	@jsonMember(String, { name: 'content_type' })
	public contentType: string = '';
}
