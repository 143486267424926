import React from 'react';
import { Name } from '../fields/name';
import { withRouter } from 'react-router-dom';
import {
	isSaveDisabled,
	remapBlacklistFromResponse,
	coachSave,
	constructRequestFromEntry,
	checkIfNextPageIsEntityRelated,
} from '../helpers/manual-data.helper';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Col, Row, Button } from 'reactstrap';
import CountrySelect from '../fields/country';
import Project from '../../../../../models/project/Project';
import SidebarCollapsibleElements from '../../../../Partials/Sidebar/sidebar-collapsible-elements/sidebar-collapsible-elements';
import { ContentTypes } from '../../../../Partials/Blocky/constants/block.types';
import SidebarElementsToggle from '../../../../Partials/Sidebar/sidebar-collapsible-elements/sidebar-elements-toggle/sidebar-elements-toggle.component';
import { actionService } from '../../../../../App';
import Blacklisted from '../blacklisted-container.tsx/blacklisted-container';
import HttpService from '../../../../../services/rest/HttpService';
import BlacklistModal from '../blacklist-modal/blacklist-modal';
import BlacklistModel from '../../../../../models/team-manual-data/blacklist/blacklist';
import {
	returnObjectForCoachEntityUpdate,
	returnObjectForCoachEntityRequest,
	COACH_ENTITY_REQUEST,
	COACH_ENTITY_RECEIVED,
	COACH_ENTITY_UPDATE_SUCCESS,
	COACH_ENTITY_POST_ASSETS_SUCCESS,
	postCoachAssets,
	coachBlacklistRemoveSuccess,
	coachSearchFilterUpdate,
} from '../../../../../store/action-creators/coach-action-creator';
import BirthDateSelect from '../fields/birth-date';

import moment from 'moment';
import CoachManualDataModel from '../../../../../models/coach-manual-data/coach-manual-data.model';
import AssetsModel from '../../../../../models/team-manual-data/assets/assets';
import { AssetsMediaComponent } from '../assets-media/assets-media.component';
import { removeAssetsFromAssetsModel } from '../assets-media/helpers/assets-image-upload.helper';
import { Subscription } from 'rxjs';
import Profile from '../../../../../models/profile/Profile';
import {
	ContentSidebarChangeCollapseStatus,
	onChangeCollapseStatus,
	onSidebarElemSort,
	toggleSidebarEditMode,
} from '../../../../../services/content-sidebar/content-sidebar.helper';
import { FormattedSidebarSectionValue } from '../../../../Partials/Sidebar/sidebar-collapsible-elements/utils/temp-sidebar.types';
import GlobalModal from '../../../../Partials/Modals/Global/global-modal';
import {
	appendBeforeUnloadResetTempSidebar,
	defaultTempSidebarFromApiResponse,
	overwriteTempSidebarByContentType,
	saveSidebarSettingsByContentType,
} from '../../../../Partials/Sidebar/sidebar-collapsible-elements/utils/temp-sidebar.helper';

export type Properties = {
	t: any;
	match: any;
	coach: CoachManualDataModel;
	project: Project;
	profile: Profile;
	coachEntityUpdate: (coach: CoachManualDataModel, project: Project, assets: AssetsModel) => void;
	getCoachEntity: (id: string, project: Project) => void;
	getBlacklistEntityData: (coach: CoachManualDataModel) => any;
	saveCoachAssets: (payload: { coachId: string; project: Project; assets: AssetsModel }) => void;
	analytics: any;
	dispatch: (action: any) => void;
	isAddedInModal?: boolean;
	coachId?: string;
	onClose?: (toggle: boolean) => any;
	removeCoachFromTheBlacklist: () => any;
	updateSearchFilterCoach: (text: string) => any;
};

export type State = {
	isActive: boolean;
	isSidebarInEdit: boolean;
	isContentLoading: boolean;
	coachDataEntry: CoachManualDataModel;
	assets: AssetsModel;
	isOpenModal: boolean;
	openSidebarSettingsModalFlag: boolean;
};

class CoachEditComponent extends React.Component<Properties, State> {
	private registerActionSubscription = new Subscription();

	constructor(props: any) {
		super(props);
		this.state = {
			isActive: props.coach.active ? true : false,
			isContentLoading: false,
			isSidebarInEdit: false,
			coachDataEntry: CoachManualDataModel.builder(props.coach).build(),
			assets: AssetsModel.builder(props.assets).build(),
			isOpenModal: false,
			openSidebarSettingsModalFlag: false,
		};
	}

	componentDidMount(): void {
		this.initPageTitle();
		this.registerActionEventListener();
		this.props.getCoachEntity(!this.props.coachId ? this.props.match.params.id : this.props.coachId, this.props.project);
		appendBeforeUnloadResetTempSidebar(ContentTypes.coach);
	}

	componentDidUpdate(prevProps: Readonly<Properties>): void {
		if (!CoachManualDataModel.builder(prevProps.coach).equals(this.props.coach)) {
			this.setState({ ...this.state, coachDataEntry: this.props.coach });
			this.getBlacklistEntityData(this.props.coach);
		}
	}

	registerActionEventListener() {
		this.registerActionSubscription = actionService.onActionReceived().subscribe((action: string) => {
			if (action === COACH_ENTITY_REQUEST) this.toggleContentLoadingState(true);

			if (action === COACH_ENTITY_RECEIVED) this.toggleContentLoadingState(false);

			if (action === COACH_ENTITY_UPDATE_SUCCESS || action === COACH_ENTITY_POST_ASSETS_SUCCESS) {
				this.props.getCoachEntity(!this.props.coachId ? this.props.match.params.id : this.props.coachId, this.props.project);
			}
		});
	}

	componentWillUnmount() {
		this.registerActionSubscription.unsubscribe();
		checkIfNextPageIsEntityRelated('coaches') && this.props.updateSearchFilterCoach('');
	}

	getBlacklistEntityData = (coach: CoachManualDataModel) => {
		const params = constructRequestFromEntry([coach], 'COACH', 'ENTITY');
		HttpService.getBlacklist(`/v2/blacklist/search`, params)
			.then((response: any) => {
				const blacklist = response.data;

				if (blacklist && blacklist.length > 0) {
					this.setState({
						...this.state,
						coachDataEntry: CoachManualDataModel.builder(coach).withBlacklist(remapBlacklistFromResponse(blacklist[0])).build(),
					});
				} else {
					this.setState({
						...this.state,
						coachDataEntry: CoachManualDataModel.builder(coach).withBlacklist(BlacklistModel.builder().build()).build(),
					});
				}
			})
			.catch((error: unknown) => console.error(error));
	};

	toggleContentLoadingState = (isLoading: boolean) => this.setState({ ...this.state, isContentLoading: isLoading });

	initPageTitle = () => (document.title = this.props.t('coach_edit'));

	onNameChange = (e: any) => {
		this.setState({
			...this.state,
			coachDataEntry: CoachManualDataModel.builder(this.state.coachDataEntry).withName(e.target.value).build(),
		});
	};

	onCountryChange = (country: any) => {
		this.setState({
			...this.state,
			coachDataEntry: CoachManualDataModel.builder(this.state.coachDataEntry).withCountry(country).build(),
		});
	};

	onBirthDateChange = (date: any) => {
		const localTimezone = moment.tz.guess();

		this.setState({
			...this.state,
			coachDataEntry: CoachManualDataModel.builder(this.state.coachDataEntry)
				.withBirthdate(date ? moment.tz(date, localTimezone).format('YYYY-MM-DD') : '')
				.build(),
		});
	};

	toggleSidebarEditMode = () => {
		const newState = toggleSidebarEditMode({ ...this.state }, ContentTypes.coach);
		this.setState(newState);
	};

	updateAssets = (assets: AssetsModel) => {
		this.setState({ ...this.state, assets: assets });
	};

	onCoachSave = () => {
		this.props.coachEntityUpdate(coachSave(this.state.coachDataEntry), this.props.project, this.state.assets);
		this.props.isAddedInModal && this.props.onClose && this.props.onClose(false);
	};

	removeFromBlacklist = () => {
		const { coachDataEntry } = this.state;

		HttpService.removeFromBlacklist(coachDataEntry.blacklist.id)
			.then((response: any) => {
				this.setState({
					...this.state,
					coachDataEntry: CoachManualDataModel.builder(coachDataEntry).withBlacklist(BlacklistModel.builder().build()).build(),
					isOpenModal: false,
				});
				this.props.removeCoachFromTheBlacklist();
			})
			.catch((error: any) => {
				this.setState({ ...this.state, isOpenModal: false });
			});
	};

	toggleModal = (toggle: boolean) => {
		this.setState({ ...this.state, isOpenModal: toggle });
	};

	deleteAssetsFromDataEntry = (type: string) => {
		const { assets } = this.state;

		this.props.getCoachEntity(!this.props.coachId ? this.props.match.params.id : this.props.coachId, this.props.project);
		this.setState({ ...this.state, assets: removeAssetsFromAssetsModel(type, assets) });
	};

	onAssetsSave = () =>
		this.props.saveCoachAssets({
			coachId: this.state.coachDataEntry.id,
			project: this.props.project,
			assets: this.state.assets,
		});

	render() {
		const { t, project, analytics, isAddedInModal } = this.props;
		const { coachDataEntry, isSidebarInEdit, isContentLoading, assets, isOpenModal, openSidebarSettingsModalFlag } = this.state;

		const sidebarComponentsMap = {
			media: (
				<AssetsMediaComponent
					t={t}
					currentProject={project}
					assets={assets}
					dataEntry={coachDataEntry}
					updateAssets={this.updateAssets}
					dataType='coach'
					deleteAssetsFromDataEntry={this.deleteAssetsFromDataEntry}
				/>
			),
		};

		return (
			<div className='animated fadeIn'>
				<Row>
					<Col col='8' lg='8' md='12' sm='12' xs='12'>
						{coachDataEntry.blacklist && coachDataEntry.blacklist.id && (
							<Blacklisted t={t} type='coach' isContentLoading={isContentLoading} toggleModal={this.toggleModal} />
						)}
						<div className='card'>
							<div className='card-header d-flex align-items-center '>
								<div className='mr-auto mb-0'>{t('coach_info')}</div>
								<Button
									size='sm'
									color='primary'
									disabled={isSaveDisabled(coachDataEntry, ContentTypes.coach)}
									className='text-uppercase font-weight-bold'
									onClick={this.onCoachSave}
									id='coach-manual-data-entry-edit-save-top'
								>
									<i className='fa fa-floppy-o' /> {t('save_coach')}
								</Button>
								<Button
									size='sm'
									color='primary'
									className='ml-2 text-uppercase font-weight-bold'
									onClick={this.onAssetsSave}
									id='coach-manual-data-entry-edit-save-assets-top'
								>
									<i className='fa fa-floppy-o' /> {t('save_assets')}
								</Button>
							</div>
							<div className={`card-body ${isContentLoading ? 'loading-overlay' : ''}`}>
								<Row>
									<div className='alert alert-info w-100' role='alert'>
										{t('enter_the_coach_name_in_english')}
									</div>
								</Row>
								<Row>
									<Name value={coachDataEntry.name} onChange={this.onNameChange} t={t} type='coach' />
									<BirthDateSelect value={coachDataEntry.birthdate ? coachDataEntry.birthdate : ''} t={t} onChange={this.onBirthDateChange} />
								</Row>
								<Row>
									<CountrySelect value={coachDataEntry.country} onChange={this.onCountryChange} t={t} />
								</Row>

								{!isAddedInModal && (
									<Row>
										<Button
											disabled={isSaveDisabled(coachDataEntry, ContentTypes.coach)}
											color='primary'
											className='text-uppercase font-weight-bold'
											onClick={this.onCoachSave}
											id='coach-manual-data-entry-edit-save-bottom'
										>
											<i className='fa fa-floppy-o' /> {t('save_coach')}
										</Button>
										<Button
											color='primary'
											className='ml-2 text-uppercase font-weight-bold'
											onClick={this.onAssetsSave}
											id='coach-manual-data-entry-edit-save-assets-bottom'
										>
											<i className='fa fa-floppy-o' /> {t('save_assets')}
										</Button>
									</Row>
								)}
							</div>
						</div>
					</Col>
					<Col col='4' lg='4' md='12' sm='12' xs='12' className='position-relative'>
						{!isAddedInModal && (
							<SidebarElementsToggle t={t} toggleSidebarEditMode={this.toggleSidebarEditMode} isSidebarInEdit={isSidebarInEdit} />
						)}
						<SidebarCollapsibleElements
							isSidebarInEdit={isSidebarInEdit}
							onElemSort={(settingsValue: FormattedSidebarSectionValue[]) => onSidebarElemSort(settingsValue, ContentTypes.coach)}
							onChangeCollapseStatus={(data: ContentSidebarChangeCollapseStatus) => onChangeCollapseStatus(data)}
							contentType={ContentTypes.coach}
							sidebarComponentsMap={sidebarComponentsMap}
							t={t}
						/>
					</Col>
				</Row>
				{isAddedInModal && (
					<Row className='border-top border-datepicker-input-color mt-3'>
						<Button
							disabled={isSaveDisabled(coachDataEntry, ContentTypes.coach)}
							color='primary'
							className='text-uppercase font-weight-bold mr-1 ml-auto mt-3'
							onClick={this.onCoachSave}
							id='coach-manual-data-entry-edit-save-bottom'
						>
							<i className='fa fa-floppy-o' /> {t('save_coach')}
						</Button>
						<Button
							id='squad-coach-cancel-button'
							color='secondary'
							className='text-light mt-3'
							onClick={() => this.props.onClose && this.props.onClose(false)}
						>
							{t('cancel')}
						</Button>
					</Row>
				)}
				<BlacklistModal
					t={t}
					type='coach'
					analytics={analytics}
					contentToDelete={coachDataEntry}
					onDeleteContent={this.removeFromBlacklist}
					toggleModal={this.toggleModal}
					isToggledModal={isOpenModal}
				/>
				<GlobalModal
					isOpen={openSidebarSettingsModalFlag}
					t={this.props.t}
					headerContent='save_sidebar_settings'
					bodyContent='unsaved_changes'
					submitFunction={() => saveSidebarSettingsByContentType(ContentTypes.coach, t)}
					beforeCloseFunction={() =>
						overwriteTempSidebarByContentType(ContentTypes.coach, defaultTempSidebarFromApiResponse[ContentTypes.coach].value)
					}
				/>
			</div>
		);
	}
}

function mapStateToProps(state: any) {
	return {
		coach: state.coach.coach,
		project: state.project.currentProject,
		profile: state.profile.profile,
	};
}

function mapDispatchToProps(dispatch: any) {
	return {
		dispatch: (action: any) => dispatch(action),
		coachEntityUpdate: (coachData: CoachManualDataModel, project: Project, assets: AssetsModel) =>
			dispatch(returnObjectForCoachEntityUpdate(coachData, project, assets)),
		saveCoachAssets: (payload: { coachId: string; project: Project; assets: AssetsModel }) => dispatch(postCoachAssets(payload)),
		getCoachEntity: (id: string, project: Project) => dispatch(returnObjectForCoachEntityRequest(id, project)),
		removeCoachFromTheBlacklist: () => dispatch(coachBlacklistRemoveSuccess()),
		updateSearchFilterCoach: (text: string) => dispatch(coachSearchFilterUpdate(text)),
	};
}

export default compose(connect(mapStateToProps, mapDispatchToProps), withRouter)(CoachEditComponent);
