import { PollJson } from './poll.json';
import Poll from './poll';
import { PollRequestJson } from './poll-request.json';
import PollOption from './option/poll-option';
import MainMedia from '../mainmedia/MainMedia';
import { MainMediaJson } from '../mainmedia/MainMediaJson';

export default class PollBuilder {
	private json: PollJson;

	constructor(poll?: Poll | PollJson) {
		if (poll && poll instanceof Poll) {
			this.json = poll.toJson();
		} else if (poll) {
			this.json = poll;
		} else {
			this.json = {} as PollJson;
		}
	}

	withId(id: string): PollBuilder {
		this.json.id = id;

		return this;
	}

	withTitle(title: string): PollBuilder {
		this.json.title = title;

		return this;
	}

	withText(text: string): PollBuilder {
		this.json.text = text;

		return this;
	}

	withEntityType(entity_type: string): PollBuilder {
		this.json.entity_type = entity_type;

		return this;
	}

	withGeneric(generic: string): PollBuilder {
		this.json.generic = generic;

		return this;
	}

	withOptions(options: PollOption[]): PollBuilder {
		this.json.options = options;

		return this;
	}

	withStatus(status: string): PollBuilder {
		this.json.status = status;

		return this;
	}

	withCreatedAt(created_at: string): PollBuilder {
		this.json.created_at = created_at;

		return this;
	}

	withUpdatedAt(updated_at: string): PollBuilder {
		this.json.updated_at = updated_at;

		return this;
	}

	withComments(comments: any): PollBuilder {
		this.json.comments = comments;

		return this;
	}

	withMainMedia(mainMedia: MainMedia[]): PollBuilder {
		this.json.main_media = mainMedia;

		return this;
	}

	toRequestJson(): PollRequestJson {
		let json = {} as PollRequestJson;

		json.id = this.json.id;
		json.title = this.json.title;
		json.text = this.json.text;
		json.entity_type = this.json.entity_type;
		json.generic = this.json.generic;
		json.options = this.json.options
			? this.json.options.map((option: PollOption, index: number) => {
					return {
						id: option.id,
						text: option.text,
						entity_type: option.entity_type,
						count: option.count,
						poll_id: option.poll_id,
						weight: index,
					};
			  })
			: [];
		json.status = this.json.status;
		json.created_at = this.json.created_at;
		json.updated_at = this.json.updated_at;
		json.comment_policy_id =
			this.json.comments && this.json.comments.policy && this.json.comments.policy.id ? this.json.comments.policy.id : '';
		json.main_media = this.json.main_media
			? this.json.main_media.map((media: MainMediaJson) => {
					return {
						resource_id: media.resourceId,
						resource_type: media.resourceType,
						resource_subtype: media.resourceSubtype,
						provider: media.provider,
						description: media.description,
						data: media.data,
					};
			  })
			: [];

		return json;
	}

	build(): Poll {
		return Poll.fromJson(this.json);
	}
}
