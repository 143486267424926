import React, { FunctionComponent, useEffect, useState } from 'react';
import './audit-log-style.scss';
import { actionService, auditLogService } from '../../../App';
import AuditLogModel from '../../../models/v2/audit-log/audit-log.model';
import { Col, Row } from 'reactstrap';
import { store } from '../../../store/store';
import momentTz from 'moment-timezone';
import ReactPaginate from 'react-paginate';
import PaginationMeta from '../../../models/pagination/PaginationMeta';
import { PaginationProps } from './services/audit-log.service';
import { DatePicker } from '../content-analytics/date-picker/date-time.component';

export const DISPLAY_AUDIT = 'display_audit_log';

const auditLogRequestData = { profileId: '', contentId: '', contentTitle: '', contentType: '' };

type Props = {
	t: any;
};

export const AuditLogContainer: FunctionComponent<Props> = ({ t }) => {
	const [loading, setLoading] = useState<boolean>(true);
	const [contentRequestData, setContentRequestData] = useState(auditLogRequestData);
	const [auditLog, setAuditLog] = useState<AuditLogModel[]>([]);
	const [currentPage, setCurrentPage] = useState<string>('1');
	const [pagination, setPagination] = useState({} as PaginationMeta);
	const project = store.getState().project.currentProject;
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [dateRange, setDateRange] = useState([] as any[]);
	const [dateFilterApplied, setDateFilterApplied] = useState<boolean>(false);

	momentTz.tz.setDefault(project.timezone);

	useEffect(() => {
		const subscription = actionService.onUIAction().subscribe((action: any) => {
			if (action.type === DISPLAY_AUDIT) {
				setContentRequestData(action.data);
				setIsOpen(true);
				requestActionLogData(setAuditLog, currentPage);
			}
		});

		return () => {
			subscription.unsubscribe();
		};
	}, []);

	useEffect(() => {
		requestActionLogData(setAuditLog, currentPage);
	}, [contentRequestData]);

	useEffect(() => {
		if (dateFilterApplied) {
			requestActionLogData(setAuditLog, currentPage);
		}
	}, [dateRange[0], dateRange[1]]);

	const requestActionLogData = (setAuditLog: (arg: AuditLogModel[]) => void, page: string) => {
		if (contentRequestData.contentId && contentRequestData.contentId.length > 0) {
			setLoading(true);
			auditLogService
				.getActionLogForContent(
					contentRequestData.contentId,
					auditLogService.convertContentType(contentRequestData.contentType),
					dateFilterApplied && dateRange[0] && dateRange[0].length > 0 && dateRange[0],
					dateFilterApplied && dateRange[1] && dateRange[1].length > 0 && dateRange[1],
					page,
				)
				.then((action: AuditLogModel[]) => {
					setAuditLog(action);
					setPagination(auditLogService.pagination);
					setLoading(false);
				})
				.catch((e: any) => {
					console.error(e);
				});
		}
	};

	const onPageChange = (page: any) => {
		setCurrentPage(page);
		requestActionLogData(setAuditLog, page);
	};

	return isOpen ? (
		<div className={`audit-log-container shadow`}>
			<div className='card-header'>
				<label style={{ maxWidth: '80%' }} className='m-0 ml-2 font-weight-bold text-truncate'>
					{t('action_log')}
					{contentRequestData.contentTitle}
				</label>
				<div
					className='toggle-audit-log-btn'
					onClick={() => {
						setIsOpen(false);
						setDateFilterApplied(false);
					}}
				>
					<i className='fa fa-close toggle-audit-log-icon ' />
					{t('close')}
				</div>
			</div>
			<div className='card-body'>
				<div className={loading ? 'loading-overlay-h-auto' : ''}>
					<DatePicker
						onDateChange={(date: any[], dateFilterApplied?: boolean) => {
							setDateFilterApplied(dateFilterApplied ? dateFilterApplied : false);
							setDateRange(date);
						}}
						hidePredefinedFilters={true}
						resetButton={true}
					/>
					<div>
						<Row className='font-weight-bold py-2 border-top border-bottom'>
							<Col className='text-left w-40'>{t('type')}</Col>
							<Col className='text-center w-40 '>{t('admin')}</Col>
							<Col className='text-right w-60 '>{t('action_time')}</Col>
						</Row>
						{auditLog &&
							auditLog.length > 0 &&
							auditLog.map((action: AuditLogModel, index) => {
								return (
									<Row
										key={action.content.id + Math.random()}
										className={`py-2 border-bottom align-items-center ${index % 2 === 1 ? '' : 'bg-light'}`}
									>
										<Col className='text-left'>
											<div className='d-flex'>
												<div className={`audit-log-badge ${auditLogService.setBadgeColor(action.action)}  text-white text-center`}>{action.action}</div>
											</div>
										</Col>
										<Col className='text-center'>{action.actor.name}</Col>
										<Col className='text-right  '>{momentTz(action.action_time).format('DD MMM YYYY, HH:mm')}</Col>
									</Row>
								);
							})}
					</div>
				</div>
				<div className='py-2'>
					<ReactPaginate
						{...PaginationProps(t, pagination.currentPage)}
						pageCount={pagination.totalPages ? pagination.totalPages : 0}
						onPageChange={(data: any) => onPageChange(data.selected + 1)}
					/>
				</div>
			</div>
		</div>
	) : null;
};
