import React from 'react';
import BlockModel from '../../../../models/block.model';
import { ViewTypes } from '../../../../constants/general.constants';
import BlockUpdateController from '../../../block-update-controller.component';
import InstatPlayerComparisonBlockEdit from './instat-player-comparison-block-edit.component';

type Properties = {
	block: BlockModel;
	view: ViewTypes;
	t: any;
};

const InstatPlayerComparisonBlock: React.FunctionComponent<Properties> = (props) => {
	const { view, t, block } = props;

	if (view === ViewTypes.edit) {
		return <InstatPlayerComparisonBlockEdit block={block} t={t} />;
	} else if (view === ViewTypes.normal) {
		return (
			<div className='px-4 d-flex flex-row'>
				<h5 className='mr-3 mb-0'>
					<strong>{block.data.player1 && block.data.player1.info ? block.data.player1.info.name : ''}</strong>
				</h5>
				<strong className='mr-3 d-flex flex-column justify-content-center align-items-center'>vs</strong>
				<h5 className='mb-0'>
					<strong>{block.data.player2 && block.data.player2.info ? block.data.player2.info.name : ''}</strong>
				</h5>
			</div>
		);
	}

	return null;
};

export default BlockUpdateController(InstatPlayerComparisonBlock);
