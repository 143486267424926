import React from 'react';
import { Properties, State } from './Properties/ProfileProperties';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
	PROFILE_PASSWORD_UPDATE,
	PROFILE_PASSWORD_UPDATE_FAILED,
	PROFILE_PASSWORD_UPDATE_SUCCESS,
	PROFILE_UPDATE,
	PROFILE_UPDATE_FAILED,
	PROFILE_UPDATE_SUCCESS,
	returnObjectForProfilePasswordUpdate,
	returnObjectForProfileUpdate,
} from '../../../store/action-creators/ProfileActionCreators';
import { Button, Card, CardBody, Col, FormGroup, Label, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import './Scss/Profile.scss';
import { Title } from '../../Partials/BaseComponents/ListingComponent/subcomponents/ContentTitle/ContentTitle';
import Permission from '../../../models/permission/Permission';
import { Name } from '../../Partials/Fields/name/NameComponent';
import Profile from '../../../models/profile/Profile';
import { Phone } from '../../Partials/Fields/phone/PhoneComponent';
import { Email } from '../../Partials/Fields/email/EmailComponent';
import BlockableButtonWrapper from '../../Partials/BaseComponents/BlockableButton/BlockableButtonWrapper';
import ErrorAsync from '../../Partials/BaseComponents/ErrorComponent/ErrorAsync';

class ProfileManage extends React.Component<Properties, State> {
	constructor(props: Properties) {
		super(props);
		this.state = {
			activeTab: new Array(4).fill('1'),
			profile: props.profile,
			password: '',
			confirmPassword: '',
		};
	}

	toggleTab(tabPane: any, tab: any) {
		const newArray = this.state.activeTab.slice();
		newArray[tabPane] = tab;
		this.setState({
			activeTab: newArray,
		});
	}

	componentDidMount(): void {
		this.initPageTitle();
		this.props.analytics.sendPageView(this.props.location.pathname, 'Profile Management');
	}

	initPageTitle() {
		document.title = this.props.t('profile');
	}

	printPermissions(permissions: Permission[]) {
		return permissions.map((item, key) => (
			<tr key={key}>
				<td>{this.props.t(item.name + '_title')}</td>
				<td>{item.name}</td>
				<td>{this.props.t(item.name + '_description')}</td>
			</tr>
		));
	}

	onNameChange(name: string) {
		const profile = Profile.builder(this.state.profile).withName(name).build();
		this.setState({ profile });
	}

	onPhoneChange(phone: string) {
		const profile = Profile.builder(this.state.profile).withPhone(phone).build();
		this.setState({ profile });
	}

	onEmailChange(email: string) {
		const profile = Profile.builder(this.state.profile).withEmail(email).build();
		this.setState({ profile });
	}

	onLanguageChange(event: any) {
		const profile = Profile.builder(this.state.profile).withLanguage(event.target.value).build();
		this.setState({ profile });
	}

	onPasswordChange(event: any) {
		this.setState({ password: event.target.value });
	}

	onConfirmPasswordChange(event: any) {
		this.setState({ confirmPassword: event.target.value });
	}

	displayStatus(status: boolean) {
		if (status) {
			return <input className={'form-control-lg form-control is-valid'} type={'text'} readOnly value={this.props.t('active')} />;
		} else {
			return <input className={'form-control-lg form-control is-invalid'} type={'text'} readOnly value={this.props.t('inactive')} />;
		}
	}

	displayPasswordDoNotMatchMessage() {
		if (this.state.password !== this.state.confirmPassword) {
			return (
				<p className={'text-danger'}>
					<small>{this.props.t('passwords_not_matching')}</small>
				</p>
			);
		}
	}

	onProfileSubmit() {
		this.props.updateProfile(this.state.profile);
	}

	onPasswordSubmit() {
		if (this.state.password === this.state.confirmPassword) {
			this.props.updateProfilePassword(this.state.profile, this.state.password);
		}
	}

	render() {
		const { t, profile } = this.props;

		return (
			<div id={'profile-wrapper'} className='animated fadeIn'>
				<Nav tabs>
					<NavItem>
						<NavLink
							className={'bg-transparent'}
							active={this.state.activeTab[0] === '1'}
							onClick={() => {
								this.toggleTab(0, '1');
							}}
						>
							<i className='fa fa-user'></i> {t('profile')}
						</NavLink>
					</NavItem>
					<NavItem>
						<NavLink
							className={'bg-transparent'}
							active={this.state.activeTab[0] === '2'}
							onClick={() => {
								this.toggleTab(0, '2');
							}}
						>
							<i className='fa fa-key'></i> {t('update_password')}
						</NavLink>
					</NavItem>
					<NavItem>
						<NavLink
							className={'bg-transparent'}
							active={this.state.activeTab[0] === '3'}
							onClick={() => {
								this.toggleTab(0, '3');
							}}
						>
							<i className='fa fa-lock'></i> {t('permissions')}
						</NavLink>
					</NavItem>
				</Nav>

				<TabContent activeTab={this.state.activeTab[0]} className={'bg-transparent border-0'}>
					<TabPane tabId='1' className={'px-0'}>
						<Row>
							<Col>
								<Card>
									<Title title={t('profile')} />
									<CardBody>
										<Row>
											<Col xs='12'>
												<Label htmlFor='name'>{t('name')}</Label>
												<Name t={t} onChange={this.onNameChange.bind(this)} value={this.state.profile.name} />
											</Col>
										</Row>

										<Row>
											<Col xs='12'>
												<Label htmlFor='name'>{t('email')}</Label>
												<Email t={t} onChange={this.onEmailChange.bind(this)} value={this.state.profile.email} />
											</Col>
										</Row>

										<Row>
											<Col xs='12'>
												<Label htmlFor='name'>{t('phone')}</Label>
												<Phone t={t} onChange={this.onPhoneChange.bind(this)} value={this.state.profile.phone} />
											</Col>
										</Row>

										<Row>
											<Col xs='12'>
												<Label htmlFor='name'>{t('language')}</Label>
												<FormGroup>
													<select value={this.state.profile.language} onChange={this.onLanguageChange.bind(this)} className={'form-control'}>
														<option value={'en-US'}>{t('english')}</option>
														<option value={'bg-BG'}>{t('bulgarian')}</option>
													</select>
												</FormGroup>
											</Col>
										</Row>

										<Row>
											<Col xs='12'>
												<Label htmlFor='name'>{t('status')}</Label>
												<FormGroup>{this.displayStatus(this.state.profile.isActive)}</FormGroup>
											</Col>
										</Row>

										<Row>
											<Col xs='12'>
												<Label htmlFor='name'>{t('organization')}</Label>
												<FormGroup>
													<input className={'form-control-lg form-control'} type={'text'} readOnly value={this.state.profile.organization} />
												</FormGroup>
											</Col>
										</Row>

										<Row>
											<Col xs='12'>
												<BlockableButtonWrapper blockOnActions={[PROFILE_UPDATE]} releaseOnActions={[PROFILE_UPDATE_SUCCESS, PROFILE_UPDATE_FAILED]}>
													<Button color={'primary'} className={'text-uppercase font-weight-bold'} onClick={this.onProfileSubmit.bind(this)}>
														<i className='fa fa-floppy-o'></i> {t('update_profile')}
													</Button>
												</BlockableButtonWrapper>
											</Col>
										</Row>
									</CardBody>
								</Card>
							</Col>
						</Row>
					</TabPane>
					<TabPane tabId='2' className={'px-0'}>
						<Row>
							<Col>
								<Card>
									<Title title={t('update_password')} />
									<CardBody>
										<Row>
											<Col xs='12'>
												<Label htmlFor='name'>{t('password')}</Label>
												<FormGroup>
													<input
														className={'form-control-lg form-control'}
														type={'password'}
														onChange={this.onPasswordChange.bind(this)}
														value={this.state.password}
													/>
													<ErrorAsync t={t} errorType='password' />
												</FormGroup>
											</Col>
										</Row>
										<Row>
											<Col xs='12'>
												<Label htmlFor='name'>{t('confirm_password')}</Label>
												<FormGroup>
													<input
														className={'form-control-lg form-control'}
														type={'password'}
														onChange={this.onConfirmPasswordChange.bind(this)}
														value={this.state.confirmPassword}
													/>
													{this.displayPasswordDoNotMatchMessage()}
												</FormGroup>
											</Col>
										</Row>
										<Row>
											<Col xs='12'>
												<BlockableButtonWrapper
													blockOnActions={[PROFILE_PASSWORD_UPDATE]}
													releaseOnActions={[PROFILE_PASSWORD_UPDATE_SUCCESS, PROFILE_PASSWORD_UPDATE_FAILED]}
												>
													<Button color={'primary'} className={'text-uppercase font-weight-bold'} onClick={this.onPasswordSubmit.bind(this)}>
														<i className='fa fa-floppy-o'></i> {t('update_password')}
													</Button>
												</BlockableButtonWrapper>
											</Col>
										</Row>
									</CardBody>
								</Card>
							</Col>
						</Row>
					</TabPane>
					<TabPane tabId='3' className={'px-0'}>
						<Card>
							<Title title={t('permissions')} />
							<CardBody>
								<table className={'table table-stripped w-100'}>
									<thead>
										<tr>
											<th>{t('permissions')}</th>
											<th>{t('system_name')}</th>
											<th>{t('description')}</th>
										</tr>
									</thead>
									<tbody>{this.printPermissions(profile.permissions)}</tbody>
								</table>
							</CardBody>
						</Card>
					</TabPane>
				</TabContent>
			</div>
		);
	}
}

function mapStateToProps(state: any) {
	return {
		profile: state.profile.profile,
	};
}

function mapDispatchToProps(dispatch: any) {
	return {
		updateProfile: (profile: Profile) => dispatch(returnObjectForProfileUpdate(profile)),
		updateProfilePassword: (profile: Profile, newPassword: string) => dispatch(returnObjectForProfilePasswordUpdate(profile, newPassword)),
	};
}

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(ProfileManage) as React.ComponentType;
