import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { Col, Label, Row } from 'reactstrap';
import { AppState } from '../../../../store/store';
import Flag from 'react-world-flags';
import LanguageCodeModel from '../../../../models/content-languages/language-code/language-code-model';
import TranslationsModel from '../../../../models/translation-groups/translations/translations-model';
import { translationsToOptions, translationToOption } from './helpers/translation-groups.helpers';
import RowCreateTranslationButton from '../../BaseComponents/ListingComponent/subcomponents/Rows/subcomponents/multi-lingual/RowCreateTranslationButton';
import { ContentTypes } from '../../Blocky/constants/block.types';
import { ConditionalRenderContext } from '../../BaseComponents/ConditionalRenderWraper/ConditionalRenderWrapper';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { multiLingualService } from '../../../../App';
import './styles/translation-groups.styles.scss';

type Properties = {
	t: any;
	currentContentTranslations: any;
	contentType: string;
	content: any;
	analytics: any;
	history: any;
};

const TranslationGroups: React.FunctionComponent<RouteComponentProps<{}> & Properties> = (props) => {
	const { t, currentContentTranslations, contentType, content, analytics, history } = props;
	const [missingTranslations, updateMissingTranslations] = useState<LanguageCodeModel[]>([]);
	const [contentLanguageCode, updateContentLanguageCode] = useState<TranslationsModel>(TranslationsModel.builder().build());

	const currentProject = useSelector((state: AppState) => state.project.currentProject);
	const context = useContext(ConditionalRenderContext);
	const showTranslationLabel = multiLingualService.extractMissingTranslations(
		currentContentTranslations.translations,
		currentProject.languages.all,
		content,
	);

	const onContentLanguageCodeChange = (event: any) => {
		const updatedLanguageCode = TranslationsModel.builder(event.data).build();
		updateContentLanguageCode(updatedLanguageCode);
		const editContentPath = multiLingualService.getCorrectContentTranslation(
			content,
			event.data.language,
			`${contentType === ContentTypes.gallery ? 'gallerie' : contentType}s`,
		);
		history.push(editContentPath);
	};

	useEffect(() => {
		updateMissingTranslations(
			multiLingualService.extractMissingTranslations(currentContentTranslations.translations, currentProject.languages.all, content),
		);
		updateContentLanguageCode(multiLingualService.extractContentLanguageCode(currentContentTranslations.translations, content.id));
	}, []);

	return (
		<div>
			<div className='flex-direction-column'>
				<Label>
					<strong>{t('language_of_this_page')}</strong>
				</Label>
				<Select
					inputId='language-code-select'
					options={translationsToOptions(currentContentTranslations.translations, false, t)}
					value={translationToOption(contentLanguageCode, false, t)}
					onChange={onContentLanguageCodeChange}
					placeholder={t('select')}
				/>
			</div>
			<div className='flex-direction-column mt-3'>
				{showTranslationLabel && showTranslationLabel.length > 0 && (
					<Label className='mt-2'>
						<strong>
							{t('translate_this')} {t(contentType).toLowerCase()}
						</strong>
					</Label>
				)}

				{missingTranslations.map((translation: LanguageCodeModel) => (
					<Row className='mb-2' key={translation.languageCode}>
						<Col className='d-flex align-items-center'>
							<div className='mr-2 d-flex flag-image-container'>
								<Flag
									code={translation.languageCode === 'en' ? 'gb' : translation.languageCode}
									width='20'
									fallback={<i className='fa fa-flag ml-2' />}
								/>
							</div>
							{t(translation.languageCode)}
						</Col>
						<Col className='d-flex'>
							<RowCreateTranslationButton
								contentType={ContentTypes[contentType]}
								createContentPath={`/smp/${contentType === ContentTypes.gallery ? 'gallerie' : contentType}s/create`}
								history={history}
								content={content}
								display={context.hasWritePermission}
								t={t}
								analytics={analytics}
								languageCode={translation.languageCode}
								translationGroup={content.translationGroup}
								showInSidebar
							/>
						</Col>
					</Row>
				))}
			</div>
		</div>
	);
};

export default compose(withRouter)(TranslationGroups);
