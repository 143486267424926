import CustomBlockListingRowModel from '../custom-block-listing-row/custom-block-listing-row.model';
import CustomBlockListingRowMapper from '../custom-block-listing-row/custom-block-listing-row.mapper';
import CustomizationApiHttpService from './custom-blocks-http.service';
import CustomBlockModel from '../custom-block/custom-block.model';
import CustomBlockMapper from '../custom-block/custom-block.mapper';
import { toast } from 'react-toastify';
import CustomBlockIconModel from '../custom-block/custom-block-icon.model';
import FeatureService from '../../../../services/feature-service/feature.service';
import CustomBlocksFeatureValidation from '../../../../views/Resources/custom-blocks/services/custom-blocks-feature.validation';

export default class CustomBlocksService {
	public isFeatureEnabled = (featureService: FeatureService) => {
		const featureValidation = new CustomBlocksFeatureValidation();
		return featureValidation.isFeatureEnabled(featureService);
	};
	/**
	 * Requests the custom block listing from the customization api and returns a Promise<CustomBlockListingRowModel[]>
	 */
	public getCustomBlocksListing = async (): Promise<CustomBlockListingRowModel[]> => {
		const customBlockListing = await CustomizationApiHttpService.getCustomBlocksListing();
		const mapper = new CustomBlockListingRowMapper();
		if (customBlockListing.data && customBlockListing.data.data && customBlockListing.data.data.length > 0) {
			return customBlockListing.data.data.map((customBlockRow: any) => mapper.responseToModel(customBlockRow));
		} else {
			return [];
		}
	};

	public deleteCustomBlock = (id: string, t: any) => {
		return CustomizationApiHttpService.deleteCustomBlock(id)
			.then(() => {
				toast.success(t('custom_block_delete_success'));
			})
			.catch((e: any) => {
				console.log(e);
				toast.error(t('custom_block_deletion_failed'));
			});
	};

	public updatedCustomBlock = (
		t: any,
		id: string,
		name: string,
		applicationUrl: string,
		icon: CustomBlockIconModel,
		status: string = 'DISABLED',
	) => {
		return CustomizationApiHttpService.updateCustomBlock(id, name, applicationUrl, icon, status)
			.then(() => {
				toast.success(t('custom_block_update_success'));
			})
			.catch((e: any) => {
				console.log(e.response.data.message);
				toast.error(t('custom_block_update_failed'));
			});
	};

	public createCustomBlock = (t: any, name: string, applicationUrl: string, icon: CustomBlockIconModel, status: string = 'DISABLED') => {
		return CustomizationApiHttpService.postCustomBlock(name, applicationUrl, icon, status)
			.then((response: any) => {
				toast.success(t('custom_block_create_success'));
				window.location.assign(`/#/smp/custom-blocks/edit/${response && response.data.id}`);
			})
			.catch((e: any) => {
				if (e.response.status === 422) {
					console.log(e.response.data.message);
					toast.error(t('custom_block_with_name_exists'));
				} else {
					console.log(e.response.data.message);
					toast.error(t('custom_block_create_failed'));
				}
			});
	};

	/**
	 * Requests the custom block details from the customization api and returns a Promise<CustomBlockModel>
	 */
	public getCustomBlock = async (id: string): Promise<CustomBlockModel | null> => {
		const customBLock = await CustomizationApiHttpService.getCustomBlock(id);
		const mapper = new CustomBlockMapper();
		if (customBLock.data) {
			return mapper.responseToModel(customBLock.data);
		} else {
			return null;
		}
	};

	public contentStatusToOptions = (content: any[], t: any): any[] => {
		return content && content.length > 0 ? content.map((item: any) => this.contentStatusToOption(item, t)) : [];
	};

	public contentStatusToOption = (item: any, t: any) => {
		return {
			value: item.value,
			label: t(item.label),
		};
	};

	/**
	 * Filters through the custom block list for searches and returns matches. If no input is empty, returns the full list of custom blocks
	 */
	public customBlockSearch = (input: string, customBlockList: CustomBlockListingRowModel[]) => {
		if (input === undefined || input === null || input.trim().length < 1) {
			return customBlockList;
		} else {
			return customBlockList.filter((customBlockRow) => (customBlockRow.name ? customBlockRow.name.includes(input) : false));
		}
	};
}
