import React, { FunctionComponent, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Project from '../../../../../models/project/Project';
import WikiRowModel from '../../../../../models/Wikipages/Row/WikiRowModel';
import { returnObjectForWikiDelete } from '../../../../../store/action-creators/WikiActionCreator';

type ModalDeleteProps = {
	t: any;
	selectedRowForDelete: WikiRowModel | null;
	setSelectedRowForDelete: Function;
	project: Project;
	deleteWikiPage: (id: string, project: Project) => any;
};

const ModalDelete: FunctionComponent<ModalDeleteProps> = ({
	t,
	selectedRowForDelete,
	setSelectedRowForDelete,
	project,
	deleteWikiPage,
}) => {
	const [modalOpenFlag, setModalOpenFlag] = useState(false);

	useEffect(() => {
		setModalOpenFlag(selectedRowForDelete !== null);
	}, [selectedRowForDelete]);

	const deleteEntity = () => {
		const idForDelete = selectedRowForDelete && selectedRowForDelete.id ? selectedRowForDelete.id : '';
		if (idForDelete) {
			deleteWikiPage(idForDelete, project);
			closeModal();
		}
	};

	const closeModal = () => setSelectedRowForDelete(null);

	return (
		<Modal isOpen={modalOpenFlag} className='modal-danger'>
			<ModalHeader>{t('delete_noun')}</ModalHeader>
			<ModalBody className='text-break'>
				<strong>{t('attention_modal')}</strong> {t('delete_notice')}
				<strong> {selectedRowForDelete && selectedRowForDelete.title} </strong>
				<br /> {t('continue_confirmation')}
				<br />
			</ModalBody>
			<ModalFooter>
				<Button color='danger' className='mr-auto' onClick={deleteEntity}>
					{t('delete')}
				</Button>
				<Button color='base' onClick={closeModal}>
					{' '}
					{t('cancel')}{' '}
				</Button>
			</ModalFooter>
		</Modal>
	);
};

function mapStateToProps(state: any) {
	return {
		project: state.project.currentProject,
	};
}

function mapDispatchToProps(dispatch: any) {
	return {
		deleteWikiPage: (id: string, project: Project) => dispatch(returnObjectForWikiDelete(id, project)),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalDelete);
