import FixturesModel from './fixtures-widget.model';
import { FixturesJson } from './fixtures-widget.json';
import MatchModel from '../../../../partials/match-select/models/match.model';
import TeamModel from '../../../../partials/team-select/models/team.model';
import TournamentModel from '../../player-profile/models/tournament-model/tournament.model';
import RoundModel from './round/round.model';
import StageModel from './stage/stage.model';
import SeasonModel from './season/season.model';

export default class FixturesBuilder {
	readonly json: FixturesJson;

	constructor(fixture?: FixturesModel | FixturesJson) {
		if (fixture && fixture instanceof FixturesModel) {
			this.json = fixture.toJson();
		} else if (fixture) {
			this.json = fixture;
		} else {
			this.json = {} as FixturesJson;
		}
	}

	withMatches(matches: MatchModel[]): FixturesBuilder {
		this.json.matches = matches;

		return this;
	}

	withTeam(team: TeamModel): FixturesBuilder {
		this.json.team = team;

		return this;
	}

	withTournament(tournament: TournamentModel): FixturesBuilder {
		this.json.tournament = tournament;

		return this;
	}

	withSeason(season: SeasonModel): FixturesBuilder {
		this.json.season = season;

		return this;
	}

	withStage(stage: StageModel): FixturesBuilder {
		this.json.stage = stage;

		return this;
	}

	withRound(round: RoundModel): FixturesBuilder {
		this.json.round = round;

		return this;
	}

	withNoData(noData: boolean): FixturesBuilder {
		this.json.noData = noData;

		return this;
	}

	withDisplayOdds(displayOdds: boolean): FixturesBuilder {
		this.json.displayOdds = displayOdds;

		return this;
	}

	withTitle(title: string): FixturesBuilder {
		this.json.title = title;

		return this;
	}

	build(): FixturesModel {
		return FixturesModel.fromJson(this.json);
	}
}
