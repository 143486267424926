import { Properties, State } from './properties/ContentTitleContainerProps';
import * as React from 'react';
import { Title } from './ContentTitle';
import { isJWSection } from '../../../../../Resources/Videos/Helpers/VideoHelper';

export default class ContentTitleContainer extends React.Component<Properties, State> {
	private titles = {
		article: (t: any) => t('articles'),
		video: (t: any) => (isJWSection() ? t('jw_videos') : t('videos')),
		gallery: (t: any) => t('galleries'),
		banner: (t: any) => t('banners'),
		list: (t: any) => t('lists'),
		author: (t: any) => t('authors'),
		tag: (t: any) => t('tags'),
		category: (t: any) => t('categories'),
		poll: (t: any) => t('polls'),
		feed: (t: any) => t('feeds'),
		team: (t: any) => t('teams'),
		player: (t: any) => t('players'),
		coach: (t: any) => t('coaches'),
		tournament_groups: (t: any) => t('tournament_groups'),
		wikipage: (t: any) => t('wiki_pages'),
	};

	render() {
		const contentType = this.titles[this.props.contentType](this.props.t);
		const showFlag =
			contentType === this.props.t('articles') || contentType === this.props.t('videos') || contentType === this.props.t('galleries')
				? true
				: false;
		return (
			<Title
				contentType={this.props.contentType}
				t={this.props.t}
				location={this.props.location}
				title={this.titles[this.props.contentType](this.props.t)}
				showFlag={showFlag}
			/>
		);
	}
}
