import Project from '../../../../../../models/project/Project';
import AssetsModel from '../../../../../../models/team-manual-data/assets/assets';
import AssetsTypesModel from '../../../../../../models/team-manual-data/assets/assetsTypes/assets-types.model';
import { CropModel } from '../subcomponents/assets-image-crop.component';

export const constructImageUrl = (url: string, currentProject: Project) => {
	if (url && url.length > 0) {
		return url.startsWith('http') ? url : `${currentProject.imageApiUrl}/process/${url}`;
	}
	return '';
};

export const updateAssetsModelWithImageType = (type: string, assets: AssetsModel, url: string) => {
	switch (type) {
		case 'logo': {
			return AssetsModel.builder(assets).withLogo(url).build();
		}
		case 'homeKit': {
			return AssetsModel.builder(assets).withHomeKit(url).build();
		}
		case 'awayKit': {
			return AssetsModel.builder(assets).withAwayKit(url).build();
		}
		case 'squadImage': {
			return AssetsModel.builder(assets).withSquadImage(url).build();
		}
		case 'image': {
			return AssetsModel.builder(assets).withImage(url).build();
		}
		case 'thumbnail': {
			return AssetsModel.builder(assets).withThumbnail(url).build();
		}
	}

	return AssetsModel.builder(assets).build();
};

export const addAssetAutoCropDimensions = (url: string, assetType: AssetsTypesModel) => {
	if (url && url.length > 0) {
		return url + `?operations=autocrop(${assetType.dimensions.width}:${assetType.dimensions.height})`;
	}

	return '';
};

export const addAssetManuallyCropDimensions = (url: string, cropInfo?: CropModel) => {
	if (url && url.length > 0 && cropInfo) {
		return url + `?operations=crop(${cropInfo.top}:${cropInfo.left}:${cropInfo.bottom}:${cropInfo.right})`;
	}

	return '';
};

export const removeAssetsFromAssetsModel = (type: string, assets: AssetsModel) => {
	switch (type) {
		case 'logo': {
			return AssetsModel.builder(assets).withLogo('').build();
		}
		case 'homeKit': {
			return AssetsModel.builder(assets).withHomeKit('').build();
		}
		case 'awayKit': {
			return AssetsModel.builder(assets).withAwayKit('').build();
		}
		case 'squadImage': {
			return AssetsModel.builder(assets).withSquadImage('').build();
		}
		case 'image': {
			return AssetsModel.builder(assets).withImage('').build();
		}
		case 'thumbnail': {
			return AssetsModel.builder(assets).withThumbnail('').build();
		}
	}

	return AssetsModel.builder(assets).build();
};

export const cutOriginalImageUrl = (url: string, project: Project) => {
	if (url && url.startsWith('http') && url.includes('operations')) {
		const stringWithoutCropProperties = url.replace(/[^?]*$/g, '').slice(0, -1);
		return stringWithoutCropProperties;
	}

	if (url && url.includes('operations')) {
		const stringWithoutCropProperties = url.replace(/[^?]*$/g, '').slice(0, -1);
		return constructImageUrl(stringWithoutCropProperties, project);
	}

	return constructImageUrl(url, project);
};
