// This file is create because we have problems testing files where 'featuresService' is imported
import { featuresService } from '../App';
import { FeatureTypes } from '../services/feature-service/features.enum';

export const renderComponentOnlyIfFeatureEnabled = (featureType: FeatureTypes, component: JSX.Element) => {
	if (featuresService.checkFeatureIsSetAndEnabled(featureType)) {
		return component;
	}
	return null;
};
