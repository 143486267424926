import Profile from './profile/Profile';

export default class RequestMapper {
	static mapProfile(profile: Profile) {
		return {
			email: profile.email,
			full_name: profile.name,
			is_active: profile.isActive,
			language: profile.language,
			organization: profile.organization,
			phone: profile.phone,
		};
	}

	static mapProfilePassword(password: string) {
		return {
			password,
		};
	}
}
