import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import React from 'react';
import { DATA_QA_ATTRIBUTES } from '../../../../../Partials/Sidebar/general-content/constants/data-qa.attributes';
import { disableSaveListButton, isListEmpty } from '../../../../../Partials/Sidebar/list-refactored/helpers/general.helper';
import ListModel from '../../../../../../models/v2/list/list';
import {
	LIST_ENTITY_UPDATE,
	LIST_ENTITY_UPDATE_DIRECTLY,
	LIST_ENTITY_UPDATE_FAILED,
	LIST_ENTITY_UPDATE_SUCCESS,
	returnObjectForListEntityUpdate,
} from '../../../../../../store/action-creators/ListActionCreators';
import { connect } from 'react-redux';
import Project from '../../../../../../models/project/Project';
import { store } from '../../../../../../store/store';
import LiveBlogListAddItemContainer from './live-blog-list-add-item.component';
import LiveBlogModel from '../../../models/live-blog.model';
import LiveBlogListContentSortableContainer from './live-blog-sortable-container.component';
import LiveBlogListAlerts from './live-blog-list-alerts.component';
import { isCreateScreen } from '../../../../../../global-helpers/global.helpers';
import './style/live-blog-list.style.scss';
import BlockableButtonWrapper from '../../../../../Partials/BaseComponents/BlockableButton/BlockableButtonWrapper';

type Properties = {
	t: any;
	isOpen: boolean;
	toggleModal: (isOpen: boolean) => void;
	setToggleListItemsModal: (isOpen: boolean) => void;
	selectedList: ListModel | null;
	updateListWithPatchRequest: (list: ListModel, project: Project) => void;
	liveBlog: LiveBlogModel;
};

const LiveBlogListItemsModal: React.FunctionComponent<Properties> = ({
	t,
	isOpen,
	toggleModal,
	selectedList,
	setToggleListItemsModal,
	updateListWithPatchRequest,
	liveBlog,
}) => {
	const isContentLoading = false;

	const onSaveList = () => {
		selectedList && updateListWithPatchRequest(selectedList, { ...store.getState().project.currentProject });
		toggleModal(false);
		setToggleListItemsModal(false);
	};

	return (
		<div data-qa={DATA_QA_ATTRIBUTES.LIST_MODAL}>
			<Modal size='lg' isOpen={isOpen}>
				<ModalHeader
					toggle={() => {
						toggleModal(false);
						setToggleListItemsModal(false);
					}}
				>
					{t('add_to_list')}
				</ModalHeader>
				<ModalBody>
					<div className='animated fadeIn'>
						<div className='mb-2'>{t('list_items')}</div>
						{!selectedList && <h5 className='mb-2'>{t('no_list_selected')}</h5>}
						<div>
							<div className={`${isContentLoading ? 'loading-overlay' : ''}`}>
								<LiveBlogListContentSortableContainer t={t} />
								<LiveBlogListAlerts t={t} />
								<LiveBlogListAddItemContainer liveBlog={liveBlog} t={t} />
							</div>
						</div>
					</div>
				</ModalBody>
				<ModalFooter className='justify-content-center'>
					{isCreateScreen() ? (
						<BlockableButtonWrapper
							blockOnActions={[LIST_ENTITY_UPDATE]}
							releaseOnActions={[LIST_ENTITY_UPDATE_FAILED, LIST_ENTITY_UPDATE_SUCCESS, LIST_ENTITY_UPDATE_DIRECTLY]}
							blockOverride={disableSaveListButton(selectedList)}
						>
							<Button
								id={DATA_QA_ATTRIBUTES.LIVE_BLOG_LIST_MODAL_CLOSE}
								color='success'
								className='text-uppercase font-weight-bold mr-2'
								onClick={() => {
									toggleModal(false);
									setToggleListItemsModal(false);
								}}
							>
								{t('close')}
							</Button>
						</BlockableButtonWrapper>
					) : (
						<Button
							id={DATA_QA_ATTRIBUTES.LIVE_BLOG_LIST_MODAL_CLOSE}
							color='danger'
							className='text-uppercase font-weight-bold mr-2'
							onClick={() => {
								toggleModal(true);
								setToggleListItemsModal(false);
							}}
						>
							{t('cancel')}
						</Button>
					)}
					{!isCreateScreen() && (
						<BlockableButtonWrapper
							blockOnActions={[LIST_ENTITY_UPDATE]}
							releaseOnActions={[LIST_ENTITY_UPDATE_FAILED, LIST_ENTITY_UPDATE_SUCCESS, LIST_ENTITY_UPDATE_DIRECTLY]}
							blockOverride={disableSaveListButton(selectedList)}
						>
							<Button
								id={DATA_QA_ATTRIBUTES.LIVE_BLOG_LIST_SAVE}
								color='success'
								className='text-uppercase font-weight-bold mr-2'
								disabled={disableSaveListButton(selectedList)}
								onClick={onSaveList}
							>
								<i className='fa fa-floppy-o' /> {t('save')}
							</Button>
						</BlockableButtonWrapper>
					)}
				</ModalFooter>
			</Modal>
		</div>
	);
};

function mapStateToProps(state: any) {
	return {
		projectDomain: state.project.currentProject.domain || '',
		selectedList: (!isListEmpty(state.list.list) && state.list.list) || null,
	};
}

function mapDispatchToProps(dispatch: any) {
	return {
		updateListWithPatchRequest: (list: ListModel, project: Project) => dispatch(returnObjectForListEntityUpdate(list, project)),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(LiveBlogListItemsModal);
