import { Properties } from './properties/source-list-container.properties';
import * as React from 'react';
import { SortEnd } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import SourcesLists from './subcomponents/sources-lists';
import * as ReactSortableHOC from 'react-sortable-hoc';

const SortableList = ReactSortableHOC.SortableContainer(SourcesLists);

const SourceListContainer: React.FunctionComponent<Properties> = (props) => {
	const onSortEnd = (sortEnd: SortEnd) => {
		let sources = props.sources.slice();
		sources = arrayMove(props.sources, sortEnd.oldIndex, sortEnd.newIndex);
		props.onSortEnd(sources);
	};

	const { sources } = props;

	return (
		<SortableList
			onSortEnd={onSortEnd}
			useDragHandle
			onDeleteContent={props.onDeleteContent}
			sources={sources}
			onSaveSource={props.onSaveSource}
			t={props.t}
			getSource={props.getSource}
			project={props.project}
			profileId={props.profileId}
		/>
	);
};

export default SourceListContainer;
