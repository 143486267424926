import ModelMapper from '../../../../../../../models/ModelMapper';
import Gallery from '../../../../../../../models/gallery/Gallery';
import Video from '../../../../../../../models/video/Video';
import MainMedia from '../../../../../../../models/mainmedia/MainMedia';
import React from 'react';
import './custom-option.scss';
import { ContentTypes } from '../../../../../Blocky/constants/block.types';
import { Badge } from 'reactstrap';

export const EMBED_ICON = (
	<Badge className='mt-1 text-white mr-2'>
		<i className='fa-lg fa fa-code mx-2' />
	</Badge>
);

export function responseToOptions(response: any, contentType: string) {
	if (contentType === 'gallery' && response) {
		return galleryResponseToOptionList(ModelMapper.remapGalleriesListingFromResponse(response));
	}

	if (contentType === 'video' && response) {
		return videoResponseToOptionList(ModelMapper.remapVideosListingFromResponse(response));
	}

	return [];
}

export const setIcon = (data: any) => {
	if ((data.data && data.data.status === 'active') || (data.content && data.content.status === 'active')) {
		return <i className={`option-logo mr-1 fa-lg fa fa-check-circle text-success mx-2`} />;
	} else {
		return <i className={`option-logo mr-1 fa-lg fa fa-times-circle text-danger mx-2`} />;
	}
};

export const customOption = (item: any) => {
	return (
		<div className='option-container'>
			{item.label && item.label.length > 0 && setIcon(item)}
			{item.data &&
				item.data.mainMedia &&
				item.data.mainMedia.length > 0 &&
				item.data.mainMedia.map((media: any) => {
					return media.resourceType === ContentTypes.embed && EMBED_ICON;
				})}
			<div className='option-label text-break'>{item.label}</div>
		</div>
	);
};

export const customOptionInBlocky = (item: any) => {
	return (
		<div className='option-container'>
			{item.label && item.label.length > 0 && setIcon(item)}
			{item.content &&
				item.content.mainMedia &&
				item.content.mainMedia.map((media: any) => {
					return (media.resourceType === ContentTypes.embed || (media.data.embedCode && media.data.embedCode.length > 0)) && EMBED_ICON;
				})}
			<div className='option-label text-break'>{item.label}</div>
		</div>
	);
};

export function galleryResponseToOptionList(galleryList: Gallery[]) {
	if (galleryList) {
		return galleryList.map((gallery: Gallery) => {
			return { value: gallery.id, label: gallery.title, data: gallery, logo: setIcon(gallery) };
		});
	}

	return [];
}

export function videoResponseToOptionList(videoList: Video[]) {
	if (videoList) {
		return videoList.map((video: Video) => {
			return { value: video.id, label: video.title, data: video, logo: setIcon(video) };
		});
	}

	return [];
}

export function contentToOption(mainMedia: MainMedia) {
	if (mainMedia && mainMedia.data) {
		return { value: mainMedia.resourceId, label: mainMedia.data.title, data: mainMedia.data };
	}

	return { value: '', label: '', data: {} };
}

export const displayContentTitle = (type: string, t: any): string => {
	return (type === 'video' || type === 'gallery') && t ? t(`${type}_from_library`) : '';
};

export const displaySearchTitle = (type: string, t: any) => {
	return (type === 'video' || type === 'gallery') && t ? t(`main_media_search_${type}`) : '';
};
