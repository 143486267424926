import Related from '../../models/related/Related';

export const ARTICLE_RELATED_CONTENT_TEMP_UPDATE = '[ACTION] ARTICLE_RELATED_CONTENT_TEMP_UPDATE';
export const ARTICLE_EDIT_RELATED_CONTENT_TEMP_UPDATE = '[ACTION] ARTICLE_EDIT_RELATED_CONTENT_TEMP_UPDATE';
export const ARTICLE_RELATED_CONTENT_TEMP_CLEAR = '[ACTION] ARTICLE_RELATED_CONTENT_TEMP_CLEAR';

export const VIDEO_RELATED_CONTENT_TEMP_UPDATE = '[ACTION] VIDEO_RELATED_CONTENT_TEMP_UPDATE';
export const VIDEO_EDIT_RELATED_CONTENT_TEMP_UPDATE = '[ACTION] VIDEO_EDIT_RELATED_CONTENT_TEMP_UPDATE';
export const VIDEO_RELATED_CONTENT_TEMP_CLEAR = '[ACTION] VIDEO_RELATED_CONTENT_TEMP_CLEAR';

export const GALLERY_RELATED_CONTENT_TEMP_UPDATE = '[ACTION] GALLERY_RELATED_CONTENT_TEMP_UPDATE';
export const GALLERY_EDIT_RELATED_CONTENT_TEMP_UPDATE = '[ACTION] GALLERY_EDIT_RELATED_CONTENT_TEMP_UPDATE';
export const GALLERY_RELATED_CONTENT_TEMP_CLEAR = '[ACTION] GALLERY_RELATED_CONTENT_TEMP_CLEAR';

export const WIKI_RELATED_CONTENT_TEMP_UPDATE = '[ACTION] WIKI_RELATED_CONTENT_TEMP_UPDATE';
export const WIKI_EDIT_RELATED_CONTENT_TEMP_UPDATE = '[ACTION] WIKI_EDIT_RELATED_CONTENT_TEMP_UPDATE';
export const WIKI_RELATED_CONTENT_TEMP_CLEAR = '[ACTION] WIKI_RELATED_CONTENT_TEMP_CLEAR';

export function updateArticleContentTempRelated(related: Related[]) {
	return {
		type: ARTICLE_RELATED_CONTENT_TEMP_UPDATE,
		payload: related,
	};
}

export function updateArticleEditContentTempRelated(related: Related[]) {
	return {
		type: ARTICLE_EDIT_RELATED_CONTENT_TEMP_UPDATE,
		payload: related,
	};
}

export function clearArticleContentTempRelated() {
	return {
		type: ARTICLE_RELATED_CONTENT_TEMP_CLEAR,
	};
}

export function updateVideoContentTempRelated(related: Related[]) {
	return {
		type: VIDEO_RELATED_CONTENT_TEMP_UPDATE,
		payload: related,
	};
}

export function updateVideoEditContentTempRelated(related: Related[]) {
	return {
		type: VIDEO_EDIT_RELATED_CONTENT_TEMP_UPDATE,
		payload: related,
	};
}

export function clearVideoContentTempRelated() {
	return {
		type: VIDEO_RELATED_CONTENT_TEMP_CLEAR,
	};
}

export function updateGalleryEditContentTempRelated(related: Related[]) {
	return {
		type: GALLERY_EDIT_RELATED_CONTENT_TEMP_UPDATE,
		payload: related,
	};
}

export function updateGalleryContentTempRelated(related: Related[]) {
	return {
		type: GALLERY_RELATED_CONTENT_TEMP_UPDATE,
		payload: related,
	};
}

export function clearGalleryContentTempRelated() {
	return {
		type: GALLERY_RELATED_CONTENT_TEMP_CLEAR,
	};
}

export function updateWikiEditContentTempRelated(related: Related[]) {
	return {
		type: WIKI_EDIT_RELATED_CONTENT_TEMP_UPDATE,
		payload: related,
	};
}

export function updateWikiContentTempRelated(related: Related[]) {
	return {
		type: WIKI_RELATED_CONTENT_TEMP_UPDATE,
		payload: related,
	};
}

export function clearWikiContentTempRelated() {
	return {
		type: WIKI_RELATED_CONTENT_TEMP_CLEAR,
	};
}
