import React, { FunctionComponent, ChangeEvent } from 'react';
import { FormGroup, Label } from 'reactstrap';
import ErrorAsync from '../../../../BaseComponents/ErrorComponent/ErrorAsync';
import { DebounceInput } from 'react-debounce-input';
import { ReduxURLsProps } from '../../constants/redux-urls.attributes';
import { DATA_QA_ATTRIBUTES } from '../../constants/data-qa.attributes';

type Properties = {
	t: any;
	canonicalUrl: string;
	onChange: (data: string, propertyName: ReduxURLsProps) => void;
};

const CanonicalUrlInput: FunctionComponent<Properties> = ({ t, canonicalUrl, onChange }) => {
	const onCanonicalUrlChange = (event: ChangeEvent<HTMLInputElement>) => {
		const inputText = event.target.value || '';
		onChange(inputText, ReduxURLsProps.CANONICAL_URL);
	};

	return (
		<FormGroup>
			<Label htmlFor={DATA_QA_ATTRIBUTES.CANONICAL_URL}>{t('canonical_url')}</Label>
			<DebounceInput
				id={DATA_QA_ATTRIBUTES.CANONICAL_URL}
				debounceTimeout={300}
				type='text'
				className='form-control'
				placeholder={t('canonical_url_placeholder')}
				value={canonicalUrl}
				onChange={onCanonicalUrlChange}
			/>
			<ErrorAsync t={t} errorType='external_url' />
		</FormGroup>
	);
};

export default CanonicalUrlInput;
