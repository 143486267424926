import * as React from 'react';
import { Button, Col, Row } from 'reactstrap';
import AmgItemDataModel from '../models/amg-item-data.model';
import { amgVideosService } from '../../../../../../../App';
import '../style/amg-row.scss';

type Properties = {
	video: AmgItemDataModel;
	t: any;
	onSelect: (video: AmgItemDataModel) => void;
	copyEmbed: (e: any, embedCode: string) => void;
	copyId: (e: any, id: string) => void;
	selectedVideo: AmgItemDataModel;
	isMediaSidebar: boolean;
};

export const AmgVideoRow: React.FunctionComponent<Properties> = (props) => {
	const { video, t, selectedVideo, onSelect, copyEmbed, copyId, isMediaSidebar } = props;
	const isSelectedVideo =
		selectedVideo && selectedVideo.mediaData && video.mediaData && video.mediaData.entryId === selectedVideo.mediaData.entryId;
	const style = 'w-25 p-1 d-flex justify-content-center';

	const selectVideo = () => {
		onSelect(video);
	};

	return (
		<Row title={`${video.metaData.title}`} className='w-100 border-bottom amg-row' style={{ minHeight: '45px' }} data-qa='amg-video-row'>
			<Col
				id='amg-video-select-video'
				className={`${isMediaSidebar ? 'font-size-13' : ''} py-1 pr-0 d-flex align-items-center`}
				sm='7'
				md='7'
				lg='7'
				onClick={selectVideo}
			>
				{video.mediaData.thumbnailUrl && (
					<img className=' img-fluid mb-2 mb-md-0 rounded mr-2' alt={video.metaData.title} src={video.mediaData.thumbnailUrl} />
				)}
				{video.metaData && video.metaData.title && video.metaData.title}
			</Col>
			<Col sm='5' md='5' lg='5' className='pl-0 py-1 d-flex'>
				<div className='d-flex justify-content-end w-100'>
					<div id='amg-video-select-video-icon' onClick={selectVideo} className={style}>
						{isSelectedVideo && <i className={`align-self-center fa-2x fa fa-check-circle text-success ${isMediaSidebar && 'mr-1'}`} />}
					</div>
					<Button
						id='amg-video-copy-embed-button'
						color='warning'
						className={`${style} align-items-center align-self-center mr-1`}
						title={t('copy_embedCode')}
						onClick={(e) => copyEmbed(e, amgVideosService.constructAmgVideoContent(video))}
					>
						<i className='fa fa-copy p-1' />
					</Button>
					<Button
						id='amg-video-copy-id'
						color='secondary'
						className={`${style} align-items-center align-self-center`}
						title={t('copy_id')}
						onClick={(e) => copyId(e, video.mediaData.entryId)}
					>
						<i className='fa fa-clone p-1' />
					</Button>
				</div>
			</Col>
		</Row>
	);
};
