import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import React, { useEffect, useState } from 'react';
import Project from '../../../../../../../models/project/Project';
import { DATA_QA_ATTRIBUTES } from '../helpers/main-media-additional-items.helper';
import ImageListingContainer from '../../../../Media/subcomponents/media-main-image/subcomponents/image-listing/ImageListingContainer';
import Image from '../../../../../../../models/image/Image';
import { useTranslation } from 'react-i18next';
import MainMedia from '../../../../../../../models/mainmedia/MainMedia';

type Properties = {
	onSelect: (item: Image) => void;
	isOpen: boolean;
	toggleModal: (isOpen: boolean) => void;
	project: Project;
	contentType: string;
	selectedMainMediaImage: MainMedia;
};

export const MainMediaAdditionalImageContentModal: React.FunctionComponent<Properties> = ({
	toggleModal,
	contentType,
	isOpen,
	onSelect,
	project,
	selectedMainMediaImage,
}) => {
	const [selectedImage, setSelectedImage] = useState<Image>({} as Image);
	const [mediaId, setMediaId] = useState<string>('');
	const [t] = useTranslation();

	useEffect(() => {
		setSelectedImage(selectedMainMediaImage && selectedMainMediaImage.resourceId ? selectedMainMediaImage.data : ({} as Image));
	}, []);

	const onContentSelect = async (item: Image) => {
		setMediaId(item.id);
		setSelectedImage(item);
	};

	return (
		<div data-qa={DATA_QA_ATTRIBUTES.MAIN_MEDIA_ADDITIONAL_IMAGES_CONTENT_MODAL}>
			<Modal isOpen={isOpen} size='lg'>
				<ModalHeader
					toggle={() => {
						toggleModal(false);
					}}
				>
					{t(`${contentType}`)}
				</ModalHeader>
				<ModalBody>
					<div data-children='.item'>
						<div className='item'>
							<ImageListingContainer
								currentProject={project}
								withToggle={false}
								inAdditionalMedia={true}
								onImageSelect={onContentSelect}
								t={t}
								selectedImageId={selectedImage && selectedImage.id ? selectedImage.id : null}
							/>
						</div>
					</div>
				</ModalBody>
				<ModalFooter>
					<Button
						id='imago-modal-close-button'
						color='danger'
						className={'text-light'}
						onClick={() => {
							toggleModal(false);
						}}
					>
						{t('close')}
					</Button>
					<Button
						disabled={!mediaId}
						id='imago-modal-save-button'
						color='primary'
						className={'text-light'}
						onClick={() => {
							onSelect(selectedImage);
						}}
					>
						{t('save')}
					</Button>
				</ModalFooter>
			</Modal>
		</div>
	);
};
