import React, { FunctionComponent, useState } from 'react';
import { Button, Input, InputGroup, InputGroupAddon, FormGroup, Col } from 'reactstrap';
import Project from '../../../../../models/project/Project';
import { ContentTypes } from '../../../../../constants/content-types';

type Props = {
	t: any;
	currentPage: number;
	project: Project;
	getContent: Function;
	advancedContentSearchInput: string;
	contentSearchInput: string;
	updateSearchText: (text: string, pageSearchQuery: string) => void;
	searchContent: Function;
	setToggle: any;
};

export const LiveBlogSearchComponent: FunctionComponent<Props> = ({
	t,
	advancedContentSearchInput,
	searchContent,
	getContent,
	contentSearchInput,
	currentPage,
	updateSearchText,
	project,
	setToggle,
}) => {
	const [toggleFilters, setToggleAdvancedFilters] = useState<boolean>(false);
	const [searchQuery, setSearchQuery] = useState<string>(contentSearchInput ? contentSearchInput : '');

	const toggleAdvancedFilters = () => {
		setToggleAdvancedFilters(!toggleFilters);
		setToggle(!toggleFilters);
	};

	const onContentSearchInput = (data: any) => {
		let searchText = data.target.value;
		setSearchQuery(data.target.value);

		if (searchText.length < 1 && advancedContentSearchInput.length < 1) {
			getContent(1, project);
			updateSearchText('', '');
		} else {
			const contentSearch = searchText.length > 0 ? `title=${searchText}` + advancedContentSearchInput : `${advancedContentSearchInput}`;
			updateSearchText(searchText, contentSearch);
		}
	};

	const onContentSearch = (event: any) => {
		if (event.keyCode === 13 && (searchQuery.length > 0 || advancedContentSearchInput.length > 0)) {
			search(searchQuery);
		}
	};

	const search = (text: string) => {
		if (text.match(/^ *$/) !== null && advancedContentSearchInput.match(/^ *$/) !== null) {
			setSearchQuery('');
			updateSearchText('', '');
			getContent(currentPage, project);
		} else {
			const contentSearch = text.length > 0 ? `title=${text}` + advancedContentSearchInput : `${advancedContentSearchInput}`;
			searchContent(contentSearch, project);
			updateSearchText(text, contentSearch);
		}
	};

	return (
		<>
			<FormGroup data-qa={`${ContentTypes.LIVE_BLOG}-search`} row className='mb-0'>
				<Col md='12'>
					<InputGroup>
						<Input
							className='rounded'
							type='text'
							id='live-blog-search-input'
							name='live-blog-search-input'
							placeholder={t('search_for_live_blogs')}
							onChange={(el) => onContentSearchInput(el)}
							onKeyDown={onContentSearch}
							value={contentSearchInput}
							data-qa={`${ContentTypes.LIVE_BLOG}-search`}
						/>
						<InputGroupAddon addonType='append'>
							<Button type='button' color='warning' id='live-blog-listing-search-button' onClick={() => search(searchQuery)}>
								<i className={'fa fa-search'}>&nbsp;</i>
							</Button>
							<Button type='button' color='dark' bssize='sm' onClick={toggleAdvancedFilters}>
								<i className='fa-solid fa-filter pr-1' />
							</Button>
						</InputGroupAddon>
					</InputGroup>
				</Col>
			</FormGroup>
		</>
	);
};
