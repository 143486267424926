import React from 'react';
import Select from 'react-select';
import { Label } from 'reactstrap';
import { Properties } from './properties/market-value-select.properties';
import { overUnderValuesToOptions, extractBookmakersIds } from './helper/market-value-select.helper';

const MarketValueSelect: React.FunctionComponent<Properties> = ({ type, bookmakers, market, mainBookmaker, onMarketValueSelect, t }) => {
	{
		const marketValueSelect = (selectedValue: any) => {
			if (onMarketValueSelect) {
				onMarketValueSelect(selectedValue);
			}
		};

		const propertyValueToOption = (value: any) => {
			if (value && value.length > 0) {
				return { value, label: value };
			}

			return { value: '', label: '' };
		};

		const selectedValue = (value: any, bookmakers: any[]) => {
			return market.value ? propertyValueToOption(value) : onMarketValueSelect(overUnderValuesToOptions(bookmakers)[0]);
		};

		let allBookmakers = type && bookmakers.length > 0 ? bookmakers : [];
		const bookmakerIds = type && extractBookmakersIds(bookmakers);
		allBookmakers =
			type && type === 'matchWidget' && mainBookmaker && Object.entries(mainBookmaker).length > 0
				? !bookmakerIds.includes(mainBookmaker.id)
					? allBookmakers.concat(mainBookmaker)
					: allBookmakers
				: allBookmakers;

		return (
			<>
				<Label>{t('choose_default_market_value')}:</Label>
				{type && type === 'matchWidget' ? (
					<Select
						options={overUnderValuesToOptions(allBookmakers)}
						onChange={marketValueSelect}
						value={selectedValue(market.value, allBookmakers)}
						noOptionsMessage={(inputValue) => inputValue && t('no_options')}
					/>
				) : (
					<Select
						options={overUnderValuesToOptions(bookmakers)}
						onChange={marketValueSelect}
						value={selectedValue(market.value, bookmakers)}
						noOptionsMessage={(inputValue) => inputValue && t('no_options')}
					/>
				)}
			</>
		);
	}
};
export default MarketValueSelect;
