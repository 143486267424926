import { assetsPlaceholder } from '../../../../../../../constants/assetsPlaceholder';
import Related from '../../../../../../../models/related/Related';
import { relatedConstants } from '../../../../../../../constants/related.constants';

export const optionToRelatedContent = (option: any) => {
	if (option) {
		return Related.builder().withData(option.data).withProvider(relatedConstants.providers.football).withType(option.type).build();
	}

	return {} as Related;
};

export const playerToOption = (response: any) => {
	if (response) {
		return {
			value: response.id,
			label: response.name,
			logo: response.url_thumb ? response.url_thumb : assetsPlaceholder.player,
			type: relatedConstants.types.player,
			data: response,
		};
	}

	return {};
};

export const convertTeamToOption = (response: any) => {
	if (response) {
		return {
			value: response.id,
			label: response.name,
			logo: response.url_logo ? response.url_logo : assetsPlaceholder.team,
			type: relatedConstants.types.team,
			data: response,
		};
	}

	return {};
};

export const convertResultToOption = (response: any) => {
	if (response && Object.entries(response).length > 0) {
		return {
			value: response.id + relatedConstants.types[response.entity_type],
			label: response.name,
			logo: response.url_thumb
				? response.url_thumb
				: response.url_image
				? response.url_image
				: response.url_logo
				? response.url_logo
				: assetsPlaceholder[response.entity_type],
			type: relatedConstants.types[response.entity_type],
			data: response,
		};
	}

	return {};
};

export const convertFootballResultToOption = (response: any) => {
	if (response && Object.entries(response).length > 0) {
		return {
			value: response.id,
			label: response.name,
			logo: response.url_thumb
				? response.url_thumb
				: response.url_image
				? response.url_image
				: response.url_logo
				? response.url_logo
				: assetsPlaceholder[response.entity_type],
			type: relatedConstants.types[response.entity_type],
			data: response,
		};
	}

	return {};
};

export const checkTagExistsInFootballRelated = (tag: any, sportsRelated: Related[]) => {
	const item = sportsRelated.find(
		(related: Related) => `${related.data.id}` === `${tag.id}` && related.data.entity_type === tag.entity_type,
	);

	return item !== undefined && item !== null;
};

export const footballRelatedToOptions = (related: Related[]) => {
	let options: any[] = [];

	if (related && related.length > 0) {
		options = related
			.filter((related: Related) => related && related.type !== 'season')
			.map((related: Related) => {
				return {
					value: related.data.id + related.type,
					label: related.data.name,
					logo: related.data.url_thumb
						? related.data.url_thumb
						: related.data.url_image
						? related.data.url_image
						: related.data.url_logo
						? related.data.url_logo
						: assetsPlaceholder[related.data.entity_type],
					type: related.type,
					data: related.data,
				};
			});
	}

	return options;
};

export const selectionToRelated = (selections: any) => {
	if (selections) {
		return selections
			.filter((selection: any) => Object.entries(selection).length > 0)
			.map((selection: any) => optionToRelatedContent(selection));
	}

	return [];
};

export const addClubInPlayerFootballConnection = (relatedData: Related[], response: any) => {
	if (relatedData && relatedData.length > 0) {
		const otherRelatedData = relatedData.map((data: Related) => {
			if (data && data.type === relatedConstants.types.player && !data.data.club) {
				let playerClub = response.data && response.filter((player: any) => data.data.id === player.data.id)[0].data.club;
				data.data.club = playerClub;
				return data;
			}
			return data;
		});
		return otherRelatedData;
	}

	return [];
};

export const extractSeasonFromRelated = (relatedList: Related[]) => {
	const tempRelatedList = extractSeasons(relatedList);
	const season = tempRelatedList && tempRelatedList.length > 0 ? tempRelatedList[0] : null;
	return season;
};

export const extractSeasonTournamentFromRelated = (relatedList: Related[]) => {
	const tempRelatedList = extractSeasons(relatedList);
	const season = tempRelatedList && tempRelatedList.length > 0 ? tempRelatedList[0] : null;
	return season ? season.data.tournament : null;
};

export const importTournamentInSeasons = (tournamentSeasons: any) => {
	const tournament = {
		id: tournamentSeasons.id,
		name: tournamentSeasons.name,
		country: tournamentSeasons.country,
		regional_league: tournamentSeasons.regional_league,
		gender: tournamentSeasons.gender,
		type: tournamentSeasons.type,
		region: tournamentSeasons.region,
	};

	return tournamentSeasons.seasons.map((season: any) => {
		season.tournament = tournament;
		return season;
	});
};

export const seasonsToOptions = (seasons: any[]) => {
	return seasons.map((season) => {
		return {
			value: season.id,
			label: season.name,
			data: season,
			type: 'season',
		};
	});
};

export const optionToTournament = (option: any) => {
	return option ? option.data : null;
};

export const tournamentToOption = (tournament: any) => {
	if (tournament) {
		return {
			id: tournament.id,
			label: tournament.name,
			data: tournament,
			logo: tournament.url_logo ? tournament.url_logo : assetsPlaceholder.tournament,
		};
	} else {
		return null;
	}
};

export const seasonToOption = (season: any) => {
	if (season) {
		return {
			value: season.data.id,
			label: season.data.name,
			data: season.data,
			type: 'season',
		};
	} else {
		return null;
	}
};

const extractSeasons = (relatedList: Related[]): Related[] => {
	return relatedList ? relatedList.filter((related: Related) => related && related.type === 'season') : [];
};
