import BlockModel from '../models/block.model';
import { FansUnitedWidgetsTypes } from '../constants/block.types';
import Widget1x2 from '../blocks/widgets/fans-united/components/1x2/widget-1x2.component';
import { ViewTypes } from '../constants/general.constants';
import React from 'react';
import WidgetDoubleChance from '../blocks/widgets/fans-united/components/double-chance/widget-double-chance.component';
import WidgetCorrectScore from '../blocks/widgets/fans-united/components/correct-score/widget-correct-score.component';
import WidgetHtFt from '../blocks/widgets/fans-united/components/ht-ft/widget-ht-ft.component';
import WidgetPlayerPredictions from '../blocks/widgets/fans-united/components/player-predictions/widget-player-predictions.component';
import WidgetTeamPredictions from '../blocks/widgets/fans-united/components/team-predictions/widget-team-predictions.component';
import WidgetTopXManager from '../blocks/widgets/fans-united/components/top-x/widget-topx-manager.component';
import WidgetMatchQuiz from '../blocks/widgets/fans-united/components/match-quiz/widget-match-quiz.component';
import WidgetLeaderboard from '../blocks/widgets/fans-united/components/leaderboard/widget-leaderboard.component';
import ClassicQuizManager from '../blocks/widgets/fans-united/components/classic-quiz/classic-quiz-manager.component';
import { EitherOrManager } from 'fansunited-widget-either-or';
import EitherOrGamesManager from '../blocks/widgets/fans-united/components/either-or/either-or-games-manager.component';

export default class FansUnitedWidgetBlocksGenerator {
	public createBlock = (block: BlockModel, t: any, contentData: any) => {
		switch (block.data.widget_type) {
			case FansUnitedWidgetsTypes.widget1x2: {
				return <Widget1x2 view={ViewTypes.normal} t={t} block={block} contentData={contentData} />;
			}

			case FansUnitedWidgetsTypes.doubleChance: {
				return <WidgetDoubleChance view={ViewTypes.normal} t={t} block={block} contentData={contentData} />;
			}

			case FansUnitedWidgetsTypes.correctScore: {
				return <WidgetCorrectScore view={ViewTypes.normal} t={t} block={block} contentData={contentData} />;
			}

			case FansUnitedWidgetsTypes.htFt: {
				return <WidgetHtFt view={ViewTypes.normal} t={t} block={block} contentData={contentData} />;
			}

			case FansUnitedWidgetsTypes.playerPredictions: {
				return <WidgetPlayerPredictions view={ViewTypes.normal} t={t} block={block} contentData={contentData} />;
			}

			case FansUnitedWidgetsTypes.teamPredictions: {
				return <WidgetTeamPredictions view={ViewTypes.normal} t={t} block={block} contentData={contentData} />;
			}

			case FansUnitedWidgetsTypes.topX: {
				return <WidgetTopXManager view={ViewTypes.normal} t={t} block={block} />;
			}

			case FansUnitedWidgetsTypes.matchQuiz: {
				return <WidgetMatchQuiz view={ViewTypes.normal} t={t} block={block} />;
			}

			case FansUnitedWidgetsTypes.leaderboard: {
				return <WidgetLeaderboard view={ViewTypes.normal} t={t} block={block} />;
			}

			case FansUnitedWidgetsTypes.classicQuiz: {
				return <ClassicQuizManager view={ViewTypes.normal} block={block} />;
			}

			case FansUnitedWidgetsTypes.eitherOr: {
				return <EitherOrGamesManager view={ViewTypes.normal} block={block} />;
			}
		}
	};
}
