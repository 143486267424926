import { ImageUrlsUploadedJson } from './ImageUrlsUploadedJson';
import ImageUrlsUploadedBuilder from './ImageUrlsUploadedBuilder';

export default class ImageUrlsUploaded {
	readonly embed: string;
	readonly gallery: string;
	readonly original: string;
	readonly thumbnail: string;

	private constructor(embed: string, gallery: string, original: string, thumbnail: string) {
		this.embed = embed;
		this.gallery = gallery;
		this.original = original;
		this.thumbnail = thumbnail;
	}

	toJSON(): ImageUrlsUploadedJson {
		return {
			embed: this.embed,
			gallery: this.gallery,
			original: this.original,
			thumbnail: this.thumbnail,
		};
	}

	static fromJSON(json: ImageUrlsUploadedJson): ImageUrlsUploaded {
		return new ImageUrlsUploaded(json.embed, json.gallery, json.original, json.thumbnail);
	}

	static builder(urls?: ImageUrlsUploaded): ImageUrlsUploadedBuilder {
		return new ImageUrlsUploadedBuilder(urls);
	}
}
