import * as React from 'react';
import { Label, FormGroup, Col } from 'reactstrap';
import DateTimePickerContainer from '../../../../Partials/Sidebar/GeneralContentAttributes/subcomponents/DateTimePicker/DateTimePickerContainer';

type BirthDateProps = {
	t: any;
	onChange: (selection: any) => void;
	value: any;
};

const BirthDateSelect: React.FunctionComponent<BirthDateProps> = ({ t, onChange, value }) => {
	return (
		<Col xs='6'>
			<FormGroup>
				<Label htmlFor='player-manual-data-entry-birth-date'>{t('birth_date')}</Label>
				<DateTimePickerContainer
					id='player-manual-data-entry-birth-date'
					date={value}
					dateFormat='Y.m.d'
					placeholder={t('select_birth_date')}
					onDateChange={onChange}
					enableTime={false}
					resetButton
					t={t}
					useLocalTimezone={true}
				/>
			</FormGroup>
		</Col>
	);
};

export default BirthDateSelect;
