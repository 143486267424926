import { TopScorersWidgetJson } from './top-scorers-widget.json';
import TopScorersWidgetBuilder from './top-scorers-widget.builder';
import TournamentModel from '../../player-profile/models/tournament-model/tournament.model';
import PlayerModel from '../../../../partials/player-select/models/player.model';
import TeamModel from '../../../../partials/team-select/models/team.model';

export default class TopScorersWidgetModel {
	readonly tournament: TournamentModel;
	readonly topScorers: PlayerModel[] = [];
	readonly selections: any[] = [];
	readonly team: TeamModel;
	readonly filter: boolean;
	readonly threeLetterCodeType: any = '';

	private constructor(
		tournament: TournamentModel,
		topScorers: PlayerModel[],
		selections: any[],
		team: TeamModel,
		filter: boolean,
		threeLetterCodeType: any,
	) {
		this.tournament = tournament;
		this.topScorers = topScorers;
		this.selections = selections;
		this.team = team;
		this.filter = filter;
		this.threeLetterCodeType = threeLetterCodeType;
	}

	toJson(): TopScorersWidgetJson {
		return {
			tournament: this.tournament,
			topScorers: this.topScorers,
			selections: this.selections,
			team: this.team,
			filter: this.filter,
			threeLetterCodeType: this.threeLetterCodeType,
		};
	}

	static fromJson(json: TopScorersWidgetJson): TopScorersWidgetModel {
		return new TopScorersWidgetModel(json.tournament, json.topScorers, json.selections, json.team, json.filter, json.threeLetterCodeType);
	}

	static builder(topScorers?: TopScorersWidgetModel): TopScorersWidgetBuilder {
		return new TopScorersWidgetBuilder(topScorers);
	}
}
