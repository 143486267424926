import React from 'react';
import Flag from 'react-world-flags';
import './custom-select-option.scss';

function CustomFlagOption(option) {
	let langCodeValue;

	switch (option.value) {
		case 'en':
			langCodeValue = 'gb';
			break;
		case 'el':
			langCodeValue = 'gr';
			break;
		case 'sr':
			langCodeValue = 'rs';
			break;
		case 'sv':
			langCodeValue = 'se';
			break;
		default:
			langCodeValue = option.value;
			break;
	}

	return (
		<div className='d-flex align-items-center'>
			<Flag code={langCodeValue} width='20' height='20' fallback={<i className='fa fa-flag' />} />
			<div className='px-2'>{option.label}</div>
		</div>
	);
}

export default CustomFlagOption;
