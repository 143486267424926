import React from 'react';
import EventModel from '../../models/event/event.model';
import EventRow from './event-row.component';
import * as ReactSortableHOC from 'react-sortable-hoc';
import PlayerManualDataModel from '../../../../../models/player-manual-data/player-manual-data.model';

type Properties = {
	events: EventModel[];
	onEventRemove: (id: string) => void;
	eventPlayers: PlayerManualDataModel[];
	t: any;
	onEventChange: (event: EventModel, index: number) => void;
};
const SortableItem = ReactSortableHOC.SortableElement(EventRow);

const EventsSortableComponent: React.FunctionComponent<Properties> = (props) => {
	const { events, onEventRemove, eventPlayers, t, onEventChange } = props;

	return (
		<div>
			{events.map((event: EventModel, index: number) => {
				return (
					<SortableItem
						index={index}
						itemIndex={index}
						onRemove={onEventRemove}
						key={event.id + index}
						onChange={onEventChange}
						event={event}
						t={t}
						eventPlayers={eventPlayers}
					/>
				);
			})}
		</div>
	);
};

export default EventsSortableComponent;
