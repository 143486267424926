import React from 'react';
import YoutubeSportsContainer from './youtube-sports-container.component';
import { blockManagementService, blockWrapperService } from '../../../subcomponents/blocky.component';
import BlockValidation from '../../../helpers/block-validation.helper';
import BlockModel from '../../../models/block.model';
import YoutubeVideoModel from '../../../../../../models/youtube-sports/youtube-video/youtube-video.model';
import { constructYoutubeEmbedIframe } from './video-rows/youtube-video-row.helper';

export type Properties = {
	block: BlockModel;
	t: any;
	linkedTags: any;
};

export type State = {
	selectedYoutubeVideo: YoutubeVideoModel;
	isValid: boolean;
	searchText: string;
};

class YoutubeSportsEdit extends React.Component<Properties, State> {
	constructor(props: any) {
		super(props);
		this.state = {
			selectedYoutubeVideo:
				props.block && props.block.data && props.block.data.id ? props.block.data.preview.video : YoutubeVideoModel.builder().build(),
			isValid: true,
			searchText: '',
		};
	}

	componentDidMount(): void {
		blockWrapperService.registerOnSaveFunc({ blockId: this.props.block.id, func: this.onSave });
	}

	onSave = (index: number) => {
		const { selectedYoutubeVideo } = this.state;
		const data = { youtubeVideo: selectedYoutubeVideo };
		const validation = new BlockValidation();
		const isValid = validation.validate(this.props.block, data);
		this.setState({ ...this.state, isValid });

		if (isValid) {
			const block = Object.assign({}, this.props.block);
			block.data.id = selectedYoutubeVideo.id.videoId;
			block.data.content = constructYoutubeEmbedIframe(selectedYoutubeVideo);
			block.data.preview = { video: selectedYoutubeVideo, title: this.state.searchText };
			blockManagementService.blockUpdate(block, index);
		} else {
			return isValid;
		}
	};

	selectYoutubeSportsVideo = (video: YoutubeVideoModel) => {
		this.setState({ ...this.state, selectedYoutubeVideo: video });
	};

	componentWillUnmount(): void {
		blockWrapperService.unregisterOnSaveFunc(this.props.block.id);
	}

	updateSearchText = (search: string) => {
		this.setState({ ...this.state, searchText: search });
	};

	render() {
		const { t, linkedTags } = this.props;

		return (
			<div className={'mb-2'}>
				<YoutubeSportsContainer
					t={t}
					onSelect={this.selectYoutubeSportsVideo}
					updateSearchText={this.updateSearchText}
					block={this.props.block}
					selectedYoutubeVideo={this.state.selectedYoutubeVideo}
					isValid={this.state.isValid}
					inBlocky={true}
					linkedTags={linkedTags}
				/>
			</div>
		);
	}
}

export default YoutubeSportsEdit;
