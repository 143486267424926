import { setIcon } from '../../../../../Sidebar/Media/subcomponents/media-content/helpers/MainContentMediaContainer';

export const createOptionsFromData = (data: any) => {
	if (data && data.length > 0) {
		return data.map((item: any) => {
			return createOptionFromData(item);
		});
	}

	return [];
};

export const createOptionFromData = (item: any) => {
	if (item) {
		return { value: item.id, label: item.title, data: item, logo: setIcon(item) };
	}

	return {};
};
