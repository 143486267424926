import { AnyT, jsonMember, jsonObject } from 'typedjson';
import TeamModelV2 from '../../../../../../../models/v2/Team/Entity/TeamModel';
import StageModel from '../../../../../../../models/v2/Stage/Entity/StageModel';
import SeasonModel from '../../../../../../../models/v2/Season/Entity/SeasonModel';
import TournamentModel from '../../../../../../../models/v2/Tournament/Entity/TournamentModel';
import VenueModel from '../../../../blocks/widgets/fixtures/models/venue/venue.model';

@jsonObject
export default class MinuteModel {
	@jsonMember(Number, { name: 'regular_time' })
	public regularTime: number = 0;

	@jsonMember(Number, { name: 'injury_time' })
	public injuryTime: number = 0;
}
