import { TypedJSON } from 'typedjson';
import GroupModel from '../../../../../../../models/v2/Group/entity/group.model';

export const stageGroupToOption = (content: any) => {
	if (content && content.id) {
		return {
			label: content.name,
			value: content.id,
			data: content,
		};
	}

	return {};
};

export const createGroups = (groups: GroupModel[]) => {
	if (groups && groups.length > 0) {
		return groups.map((item: GroupModel, index: number) => (index + 1 < groups.length ? `${item.name}, ` : item.name));
	}

	return null;
};

export const stageGroupsToOptions = (response: any) => {
	if (response && response.length > 0) {
		return response.map((content: any) => {
			return stageGroupToOption(content);
		});
	}

	return [];
};

export const responseToGroupStageModel = (response: any) => {
	const serializer = new TypedJSON(GroupModel);
	const remmapedGroupStage = serializer.parse(response) as GroupModel;

	return remmapedGroupStage;
};
