import FootballTeamFormWidgetModel from './football-team-form.model';
import { FootballTeamFormJson } from './football-team-form.json';
import TeamModel from '../../../../../../../models/v2/Team/Entity/TeamModel';
import TournamentModel from '../../../../../../../models/v2/Tournament/Entity/TournamentModel';
import SeasonModel from '../../../../../../../models/v2/Season/Entity/SeasonModel';
import { RefreshTimeValuesType } from '../../refresh-time-input/helper';

export default class FootballTeamFormBuilder {
	readonly json: FootballTeamFormJson;

	constructor(teamForm?: FootballTeamFormWidgetModel | FootballTeamFormJson) {
		if (teamForm && teamForm instanceof FootballTeamFormWidgetModel) {
			this.json = teamForm.toJson();
		} else if (teamForm) {
			this.json = teamForm;
		} else {
			this.json = {} as FootballTeamFormJson;
		}
	}

	withTeam(team: TeamModel): FootballTeamFormBuilder {
		this.json.team = team;
		return this;
	}

	withTournament(tournament: TournamentModel): FootballTeamFormBuilder {
		this.json.tournament = tournament;
		return this;
	}

	withSeason(season: SeasonModel): FootballTeamFormBuilder {
		this.json.season = season;
		return this;
	}

	withLimit(limit: string): FootballTeamFormBuilder {
		this.json.limit = limit;
		return this;
	}

	withTitle(title: string): FootballTeamFormBuilder {
		this.json.title = title;
		return this;
	}

	withRefreshTime(refreshTime: RefreshTimeValuesType): FootballTeamFormBuilder {
		this.json.refreshTime = refreshTime;

		return this;
	}

	build(): FootballTeamFormWidgetModel {
		return FootballTeamFormWidgetModel.fromJson(this.json);
	}
}
