import moment from 'moment';

export const DATE_DAY = 'day';
export const DATE_WEEK = 'week';
export const DATE_MONTH = 'month';

export default class DateTimeHelper {
	public getDateRange = (dateRange: string) => {
		const date = new Date();

		if (dateRange === DATE_DAY) {
			date.setDate(date.getDate() - 1);
		}
		if (dateRange === DATE_WEEK) {
			date.setDate(date.getDate() - 7);
		}
		if (dateRange === DATE_MONTH) {
			date.setDate(date.getDate() - 30);
		}

		return [this.constructDateString(date), this.constructDateString(new Date())];
	};

	public formatDateOutput = (dates: Date[], type?: boolean) => {
		return dates.map((date: Date) => {
			return this.constructDateString(date, type);
		});
	};

	private constructDateString = (date: Date, type?: boolean): string => {
		if (!type) {
			return `${date.getFullYear()}-${this.addZeroToSingleDigits(`${date.getMonth() + 1}`)}-${this.addZeroToSingleDigits(
				`${date.getDate()}`,
			)}`;
		} else {
			let parsedData = `${date.getFullYear()}-${this.addZeroToSingleDigits(`${date.getMonth() + 1}`)}-${this.addZeroToSingleDigits(
				`${date.getDate()}`,
			)}T${this.addZeroToSingleDigits(`${date.getHours()}`)}:${this.addZeroToSingleDigits(
				`${date.getMinutes()}`,
			)}:${this.addZeroToSingleDigits(`${date.getSeconds()}`)}`;

			parsedData = moment(parsedData).toISOString().substring(0, 18) + moment(parsedData).toISOString().substring(22);

			return parsedData;
		}
	};

	private addZeroToSingleDigits = (number: string) => {
		if (number.length < 2) {
			return `0${number}`;
		} else {
			return number;
		}
	};
}
