import React, { FunctionComponent, useEffect } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { FormGroup, Label } from 'reactstrap';
import {
	autoGenerateReduxPropsNamingForContentModels,
	generatePropsNamingByContentType,
	generateReduxPropsUpdateActionByContentType,
} from '../../../../../../services/content-models-service/ContentModelService';
import {
	extractContentModeBasedOnUrl,
	extractMainContentTypeBasedOnUrl,
	isArrayEmpty,
	isObjectEmpty,
} from '../../../../../../global-helpers/global.helpers';
import { ContentMode } from '../../../../../../constants/content-types';
import { SelectMenuOptionType } from '../../../../../../models/v2/general/select.model';
import ContentAttribute from '../../../../../../models/v2/content-attributes/content-attributes.model';
import {
	contentAttributesToOptions,
	extractDefaultValue,
	extractSelectedStringAsContentAttribute,
} from '../../helpers/general-content-attributes.helper';
import { DATA_QA_ATTRIBUTES } from '../../constants/data-qa.attributes';

type Properties = {
	t: any;
	commentsPoliciesRedux: ContentAttribute[];
	selectedCommentPolicy: ContentAttribute | null;
	updateTempProperty: (propertyObject: Record<string, any>) => void;
};

const CommentsPoliciesSelect: FunctionComponent<Properties> = ({ t, commentsPoliciesRedux, selectedCommentPolicy, updateTempProperty }) => {
	useEffect(() => {
		if (extractContentModeBasedOnUrl() === ContentMode.CREATE && !selectedCommentPolicy) {
			const defaultCommentPolicy = extractDefaultValue(commentsPoliciesRedux);
			defaultCommentPolicy &&
				defaultCommentPolicy.slug &&
				updateTempProperty({
					comments: {
						policy: defaultCommentPolicy.slug,
					},
				});
		}
	}, []);

	const onCommentPolicySelect = (selectedTypeOption: SelectMenuOptionType) => {
		const selectedCommentPolicy = (selectedTypeOption && selectedTypeOption.data) || {};
		updateTempProperty({ comments: { policy: selectedCommentPolicy } });
	};

	const selectMenuValue = selectedCommentPolicy
		? extractSelectedStringAsContentAttribute(selectedCommentPolicy.slug, commentsPoliciesRedux)
		: null;

	return isArrayEmpty(commentsPoliciesRedux) ? null : (
		<FormGroup>
			<Label htmlFor={DATA_QA_ATTRIBUTES.COMMENTS_POLICIES}>{t('comments-policies')}</Label>
			<Select
				id={DATA_QA_ATTRIBUTES.COMMENTS_POLICIES}
				value={selectMenuValue && contentAttributesToOptions([selectMenuValue])}
				options={contentAttributesToOptions(commentsPoliciesRedux)}
				onChange={onCommentPolicySelect}
				placeholder={t('select')}
				noOptionsMessage={(inputValue) => inputValue && t('no_options')}
			/>
		</FormGroup>
	);
};

function mapStateToProps(state: any) {
	const contentTypeFromUrl = extractMainContentTypeBasedOnUrl() || '';
	const commentPoliciesPropNaming = generatePropsNamingByContentType(contentTypeFromUrl) + 'CommentsPolicies';
	const { tempPropertyNaming, nestedTempPropertyNaming } = autoGenerateReduxPropsNamingForContentModels();

	// We are doing this because if there are NOT selected comment policy the value is stored as empty object in Redux
	// So here we add logic to assign 'null' value if it's empty so we should check by null, not by empty object
	// If we change it directly in  redux many problems can occurs
	const selectedCommentPolicy =
		state[tempPropertyNaming][nestedTempPropertyNaming] &&
		state[tempPropertyNaming][nestedTempPropertyNaming].comments &&
		state[tempPropertyNaming][nestedTempPropertyNaming].comments.policy;

	return {
		selectedCommentPolicy: !isObjectEmpty(selectedCommentPolicy) ? state[tempPropertyNaming][nestedTempPropertyNaming].comments.policy : null,
		commentsPoliciesRedux: state.commentsPolicies[commentPoliciesPropNaming] || [],
	};
}

function mapDispatchToProps(dispatch: Function) {
	const contentTypeFromUrl = extractMainContentTypeBasedOnUrl();
	const functionForDispatch = generateReduxPropsUpdateActionByContentType(contentTypeFromUrl);

	return {
		updateTempProperty: (propertyObject: Record<string, any>) =>
			functionForDispatch && dispatch(functionForDispatch(propertyObject, extractContentModeBasedOnUrl())),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(CommentsPoliciesSelect);
