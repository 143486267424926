import * as React from 'react';
import { Link } from 'react-router-dom';
import '../styles/rows.scss';
import { assetsPlaceholder } from '../../../../../../../constants/assetsPlaceholder';
import { Badge, Col, Row } from 'reactstrap';

type Properties = {
	canEditContent: boolean;
	editContentPath: string;
	analytics: any;
	contentTitle: string;
	strapline?: string;
	contentId?: string;
	birthdate?: string;
	showStatus?: boolean;
	active?: boolean;
	t?: any;
	img: any;
	titleStyle?: string;
};

export const RowFootballEntityTitle: React.FunctionComponent<Properties> = ({
	canEditContent,
	editContentPath,
	contentTitle,
	analytics,
	img,
	birthdate,
	contentId,
	showStatus,
	active,
	t,
	titleStyle,
}) => {
	return (
		<Link
			className={`${showStatus && !active ? 'inactive' : 'text-dark'}`}
			id={contentId}
			onClick={() => {
				analytics.sendEvent('Click', 'List Page', 'Edit Title');
			}}
			to={canEditContent ? editContentPath : '#'}
		>
			<Row id={`entity-list-item-${contentId}`} className='pt-2 pr-2 pb-2 text-truncate align-items-center'>
				<div className='mr-2 assetWraper'>
					<img className='mr-2 ml-2 round-pic mx-auto d-block' src={img} />
				</div>
				<Col sm='2' className='align-items-center'>
					<Row>{contentTitle}</Row>
					<Row>{birthdate ? <Badge className='mt-1 text-dark birthdate'>{birthdate}</Badge> : null}</Row>
				</Col>
			</Row>
		</Link>
	);
};
