import { ContentAttributesJson } from './content-attributes-json';
import { ContentAttributesRequestJson } from './content-attributes-request-json';
import ContentAttributes from './content-attributes-model';

export default class ContentAttributesBuilder {
	private json: ContentAttributesJson;

	constructor(attributes?: ContentAttributes | ContentAttributesJson) {
		if (attributes && attributes instanceof ContentAttributes) {
			this.json = attributes.toJSON();
		} else if (attributes) {
			this.json = attributes;
		} else {
			this.json = {} as ContentAttributesJson;
		}
	}

	public withId(id: string) {
		this.json.id = id;

		return this;
	}

	public withName(name: string) {
		this.json.name = name;

		return this;
	}

	public withSlug(slug: string) {
		this.json.slug = slug;

		return this;
	}

	public withCreatedBy(createdBy: string) {
		this.json.createdBy = createdBy;

		return this;
	}

	public withCreatedAt(createdAt: string) {
		this.json.createdAt = createdAt;

		return this;
	}

	public withUpdatedAt(updatedAt: string) {
		this.json.updatedAt = updatedAt;

		return this;
	}

	public withDescription(description: any) {
		this.json.description = description;

		return this;
	}

	public withDefault(isDefault: boolean) {
		this.json.isDefault = isDefault;

		return this;
	}

	public build() {
		return ContentAttributes.fromJSON(this.json);
	}

	toRequestJson(): ContentAttributesRequestJson {
		let json = {} as ContentAttributesRequestJson;
		json.created_by = {
			id: '',
			full_name: '',
		};

		json.id = this.json.id;
		json.name = this.json.name;
		json.slug = this.json.slug;
		json.created_by.id = this.json.createdBy.id;
		json.created_by.full_name = this.json.createdBy.full_name;
		json.created_at = this.json.createdAt;
		json.updated_at = this.json.updatedAt;
		json.description = this.json.description;
		json.default = this.json.isDefault;

		return json;
	}
}
