import { SquadPlayerJson } from './squad-player.json';
import { SquadPlayerRequestJson } from './squad-player-request.json';
import SquadPlayerModel from './squad-player.model';
import moment from 'moment';

export default class SquadPlayerBuilder {
	private json: SquadPlayerJson;

	constructor(player?: SquadPlayerModel | SquadPlayerJson) {
		if (player && player instanceof SquadPlayerModel) {
			this.json = player.toJSON();
		} else if (player) {
			this.json = player;
		} else {
			this.json = {} as SquadPlayerJson;
		}
	}

	withId(id: string): SquadPlayerBuilder {
		this.json.id = id;

		return this;
	}

	withName(name: string): SquadPlayerBuilder {
		this.json.name = name;

		return this;
	}

	withStatus(status: any): SquadPlayerBuilder {
		this.json.status = status;

		return this;
	}

	withContractType(contractType: string): SquadPlayerBuilder {
		this.json.contractType = contractType;

		return this;
	}

	withStartDate(startDate: string): SquadPlayerBuilder {
		this.json.startDate = startDate ? moment(startDate).format('YYYY-MM-DD') : '';

		return this;
	}

	withEndtDate(endDate: string): SquadPlayerBuilder {
		this.json.endDate = endDate ? moment(endDate).format('YYYY-MM-DD') : '';

		return this;
	}

	withShirtNumber(shirtNumber: number): SquadPlayerBuilder {
		this.json.shirtNumber = shirtNumber;

		return this;
	}

	withBirthdate(birthdate: string): SquadPlayerBuilder {
		this.json.birthdate = birthdate;

		return this;
	}

	withImage(image: string): SquadPlayerBuilder {
		this.json.image = image;

		return this;
	}

	build(): SquadPlayerModel {
		return SquadPlayerModel.fromJSON(this.json);
	}

	toRequestJson(): SquadPlayerRequestJson {
		let json = {} as SquadPlayerRequestJson;
		json.player_id = this.json.id.split('-')[1] ? this.json.id.split('-')[1] : this.json.id;
		json.status = this.json.status;

		if (this.json.contractType) {
			json.contract_type = this.json.contractType;
		}

		if (this.json.startDate) {
			json.start_date = this.json.startDate;
		}

		if (this.json.endDate) {
			json.end_date = this.json.endDate;
		}
		json.shirt_number = this.json.shirtNumber > 0 ? Number(this.json.shirtNumber) : null;

		return json;
	}
}
