import 'reflect-metadata';
import { jsonMember, jsonObject } from 'typedjson';

@jsonObject
export default class ContentModel {
	@jsonMember(String)
	public id: string = '';
	@jsonMember(String)
	public name: string = '';
	@jsonMember(String)
	public type: string = '';
}
