import { assetsPlaceholder } from '../../../../../../constants/assetsPlaceholder';
import SportBookmakerModel from '../../../../../../models/sport-odds/sport-bookmaker.model';
import SportOddsBookmakerModel from '../../../../../../models/sport-odds/sport-odds-bookmaker.model';
import { SportTypes } from '../../../../../../constants/sport.types';
import { FeatureTypes } from '../../../../../../services/feature-service/features.enum';

export const bookmakerToOption = (bookmaker: SportBookmakerModel) => {
	if (bookmaker && bookmaker.id) {
		return {
			value: bookmaker.id,
			label: bookmaker.name,
			data: bookmaker,
			logo: bookmaker.assets ? bookmaker.assets[0].logo : assetsPlaceholder.bookmaker,
			type: 'bookmaker',
		};
	}

	return {};
};

export const determineFeatureConfigType = (sportType: any) => {
	switch (sportType) {
		case SportTypes.FOOTBALL:
			return FeatureTypes.FOOTBALL_V2;
		case SportTypes.BASKETBALL:
			return FeatureTypes.BASKETBALL;
		case SportTypes.TENNIS:
			return FeatureTypes.TENNIS;
	}
};

export const optionListToBookmakerList = (selectionList: any) => {
	if (selectionList && selectionList.length > 0) {
		return selectionList.map((selection: any) => {
			return selection.data;
		});
	}

	return [];
};

export const bookmakerListToOptionList = (bookmakerList: SportBookmakerModel[]) => {
	if (bookmakerList && bookmakerList.length > 0) {
		return bookmakerList.map((bookmaker: SportBookmakerModel) => bookmakerToOption(bookmaker));
	}

	return [];
};

export const oddsBookmakerListToOptionList = (bookmakerList: SportOddsBookmakerModel[]) => {
	if (bookmakerList && bookmakerList.length > 0) {
		return bookmakerList.map((bookmaker: SportOddsBookmakerModel) => oddsBookmakerToOption(bookmaker));
	}

	return [];
};

export const oddsBookmakerToOption = (bookmaker: SportOddsBookmakerModel) => {
	if (bookmaker && bookmaker.id) {
		return {
			value: bookmaker.id,
			label: bookmaker.name,
			data: bookmaker,
			logo: bookmaker.assets ? bookmaker.assets[0].logo : assetsPlaceholder.bookmaker,
			type: 'bookmaker',
		};
	}

	return {};
};
