import { call, put, takeEvery } from 'redux-saga/effects';
import HttpService from '../../services/rest/HttpService';
import {
	returnObjectForProjectAuthorListingReceived,
	returnObjectForProjectDefaultAuthorReceived,
} from '../action-creators/AuthorActionCreators';
import {
	projectFeaturesReceived,
	distributionRegionsReceived,
	distributionChannelsReceived,
} from '../action-creators/ProjectResourcesActionCreators';
import {
	returnObjectForAdminCategoryListingReceived,
	returnObjectForAllCategoryListingReceived,
} from '../action-creators/CategoriesActionCreators';
import { POLLING_PROJECT_RESOURCES } from '../../constants/constants';
import {
	requestArticleTypesReceived,
	requestVideoTypesReceived,
	requestImageTypesReceived,
	requestGalleryTypesReceived,
	requestListTypesReceived,
	requestTagTypesReceived,
} from '../action-creators/types-action.creator';
import { imageOwnersReceived } from '../action-creators/ImageOwnersActionCreator';
import {
	requestArticleStatusesReceived,
	requestVideoStatusesReceived,
	requestPollsStatusesReceived,
	requestGalleryStatusesReceived,
	requestListStatusesReceived,
} from '../action-creators/status-action-creator';
import {
	requestArticleOriginsReceived,
	requestVideoOriginsReceived,
	requestGalleryOriginsReceived,
	requestImageOriginsReceived,
} from '../action-creators/origins-action.creator';
import {
	requestArticleCommentsPoliciesReceived,
	requestVideoCommentsPoliciesReceived,
	requestPollsCommentsPoliciesReceived,
	requestGalleryCommentsPoliciesReceived,
} from '../action-creators/comment-policy-action.creator';

function* fetchProjectResources(action: any) {
	// try {
	// 	let headers = { Project: action.payload.project.domain };
	// 	let allAuthors = yield call(HttpService.getAllPages, `/authors`, null, headers, 200);
	// 	let tempAuthors: any[] = [];
	// 	allAuthors = allAuthors.map((item: any) => item.data.data);
	// 	allAuthors.forEach((item: any[]) => tempAuthors.push(...item));
	// 	let defaultAuthor = tempAuthors.filter((author) => author.default);
	// 	yield put(returnObjectForProjectAuthorListingReceived(tempAuthors));
	// 	yield put(returnObjectForProjectDefaultAuthorReceived(defaultAuthor[0]));
	// } catch (error) {}
	// try {
	// 	let headers = { Project: action.payload.project.domain };
	// 	let allCategories = yield call(HttpService.getAllPages, `/categories`, null, headers, 200);
	// 	let tempCategories: any[] = [];
	// 	allCategories = allCategories.map((item: any) => item.data.data);
	// 	allCategories.forEach((item: any[]) => tempCategories.push(...item));
	// 	yield put(returnObjectForAllCategoryListingReceived(tempCategories));
	// } catch (error) {}
	// try {
	// 	let headers = { Project: action.payload.project.domain };
	// 	let allCategories = yield call(HttpService.get, `/categories?page=1&limit=200`, null, headers);
	// 	yield put(returnObjectForAllCategoryListingReceived(allCategories.data.data));
	// } catch (error) {}
	// try {
	// 	const projectId = action.payload.project.id;
	// 	let project = yield call(HttpService.getProjectClientAPI, projectId);
	// 	yield put(projectFeaturesReceived(project.data.features));
	// } catch (error) {}
	// try {
	// 	let headers = { Project: action.payload.project.domain };
	// 	let adminCategories = yield call(HttpService.get, `/admins/${action.payload.adminId}/categories`, null, headers);
	//
	// 	yield put(returnObjectForAdminCategoryListingReceived(adminCategories.data.data));
	// } catch (error) {}
	// try {
	// 	let headers = { Project: action.payload.project.domain };
	// 	const owners = yield call(HttpService.get, `images/origins`, null, headers);
	// 	yield put(imageOwnersReceived(owners));
	// 	yield put(requestImageOriginsReceived(owners));
	// } catch (error) {}
	// try {
	// 	let headers = { Project: action.payload.project.domain };
	// 	const origins = yield call(HttpService.get, `articles/origins`, null, headers);
	// 	yield put(requestArticleOriginsReceived(origins));
	// } catch (error) {}
	// try {
	// 	let headers = { Project: action.payload.project.domain };
	// 	const origins = yield call(HttpService.get, `videos/origins`, null, headers);
	// 	yield put(requestVideoOriginsReceived(origins));
	// } catch (error) {}
	// try {
	// 	let headers = { Project: action.payload.project.domain };
	// 	const origins = yield call(HttpService.get, `galleries/origins`, null, headers);
	// 	yield put(requestGalleryOriginsReceived(origins));
	// } catch (error) {}
	// try {
	// 	let headers = { Project: action.payload.project.domain };
	// 	const regions = yield call(HttpService.get, `distribution/regions`, action.payload.project.id, headers);
	// 	yield put(distributionRegionsReceived(regions.data.data));
	// } catch (error) {}
	// try {
	// 	let headers = { Project: action.payload.project.domain };
	// 	const channels = yield call(HttpService.get, `distribution/channels`, action.payload.project.id, headers);
	// 	yield put(distributionChannelsReceived(channels.data.data));
	// } catch (error) {}
	// try {
	// 	let headers = { Project: action.payload.project.domain };
	// 	const types = yield call(HttpService.get, `/articles/types`, null, headers);
	// 	yield put(requestArticleTypesReceived(types));
	// } catch (error) {}
	// try {
	// 	let headers = { Project: action.payload.project.domain };
	// 	const types = yield call(HttpService.get, `/videos/types`, null, headers);
	// 	yield put(requestVideoTypesReceived(types));
	// } catch (error) {}
	// try {
	// 	let headers = { Project: action.payload.project.domain };
	// 	const types = yield call(HttpService.get, `/images/types`, null, headers);
	// 	yield put(requestImageTypesReceived(types));
	// } catch (error) {}
	// try {
	// 	let headers = { Project: action.payload.project.domain };
	// 	const types = yield call(HttpService.get, `/galleries/types`, null, headers);
	// 	yield put(requestGalleryTypesReceived(types));
	// } catch (error) {}
	// try {
	// 	let headers = { Project: action.payload.project.domain };
	// 	const types = yield call(HttpService.get, `/lists/types`, null, headers);
	// 	yield put(requestListTypesReceived(types));
	// } catch (error) {}
	// try {
	// 	let headers = { Project: action.payload.project.domain };
	// 	const types = yield call(HttpService.get, `/tags/types`, null, headers);
	// 	yield put(requestTagTypesReceived(types));
	// } catch (error) {}
	// try {
	// 	let headers = { Project: action.payload.project.domain };
	// 	const statuses = yield call(HttpService.get, `/articles/statuses`, null, headers);
	// 	yield put(requestArticleStatusesReceived(statuses));
	// } catch (error) {}
	// try {
	// 	let headers = { Project: action.payload.project.domain };
	// 	const statuses = yield call(HttpService.get, `/videos/statuses`, null, headers);
	// 	yield put(requestVideoStatusesReceived(statuses));
	// } catch (error) {}
	// try {
	// 	let headers = { Project: action.payload.project.domain };
	// 	const statuses = yield call(HttpService.get, `/polls/statuses`, null, headers);
	// 	yield put(requestPollsStatusesReceived(statuses));
	// } catch (error) {}
	// try {
	// 	let headers = { Project: action.payload.project.domain };
	// 	const statuses = yield call(HttpService.get, `/galleries/statuses`, null, headers);
	// 	yield put(requestGalleryStatusesReceived(statuses));
	// } catch (error) {}
	// try {
	// 	let headers = { Project: action.payload.project.domain };
	// 	const statuses = yield call(HttpService.get, `/lists/statuses`, null, headers);
	// 	yield put(requestListStatusesReceived(statuses));
	// } catch (error) {}
	// try {
	// 	let headers = { Project: action.payload.project.domain };
	// 	const commentsPolicies = yield call(HttpService.get, `/articles/comments/policies`, null, headers);
	// 	yield put(requestArticleCommentsPoliciesReceived(commentsPolicies));
	// } catch (error) {}
	// try {
	// 	let headers = { Project: action.payload.project.domain };
	// 	const commentsPolicies = yield call(HttpService.get, `/videos/comments/policies`, null, headers);
	// 	yield put(requestVideoCommentsPoliciesReceived(commentsPolicies));
	// } catch (error) {}
	// try {
	// 	let headers = { Project: action.payload.project.domain };
	// 	const commentsPolicies = yield call(HttpService.get, `/polls/comments/policies`, null, headers);
	// 	yield put(requestPollsCommentsPoliciesReceived(commentsPolicies));
	// } catch (error) {}
	// try {
	// 	let headers = { Project: action.payload.project.domain };
	// 	const commentsPolicies = yield call(HttpService.get, `/galleries/comments/policies`, null, headers);
	// 	yield put(requestGalleryCommentsPoliciesReceived(commentsPolicies));
	// } catch (error) {}
}

function* pollingResources() {
	yield takeEvery(POLLING_PROJECT_RESOURCES, fetchProjectResources);
}

export default pollingResources;
