import { ImageUrlsUploadedJson } from './ImageUrlsUploadedJson';
import ImageUrlsUploaded from './ImageUrlsUploaded';

export default class ImageUrlsUploadedBuilder {
	private json: ImageUrlsUploadedJson;

	constructor(urls?: ImageUrlsUploaded | ImageUrlsUploadedJson) {
		if (urls && urls instanceof ImageUrlsUploaded) {
			this.json = urls.toJSON();
		} else if (urls) {
			this.json = urls;
		} else {
			this.json = {} as ImageUrlsUploadedJson;
		}
	}

	withEmbed(embed: string): ImageUrlsUploadedBuilder {
		this.json.embed = embed;

		return this;
	}

	withGallery(gallery: string): ImageUrlsUploadedBuilder {
		this.json.gallery = gallery;

		return this;
	}

	withOriginal(original: string): ImageUrlsUploadedBuilder {
		this.json.original = original;

		return this;
	}

	withThumbnail(thumbnail: string): ImageUrlsUploadedBuilder {
		this.json.thumbnail = thumbnail;

		return this;
	}

	build(): ImageUrlsUploaded {
		return ImageUrlsUploaded.fromJSON(this.json);
	}
}
