import React from 'react';
import Select from 'react-select';
import { selectedItemsToOptions } from '../statistic-columns-squad-select/helpers/statistic-columns-squad.helper';
import { statisticSquadColumns, statisticSquadGoalkeepersColumns } from './helpers/statistic-columns-squad.helper';

type Properties = {
	onStatisticsColumnsSelect: any;
	value: any[];
	t: any;
	isMulti: boolean;
	playerPosition: string;
};

const StatisticSquadColumnsSelect: React.FunctionComponent<Properties> = ({ value, t, onStatisticsColumnsSelect, playerPosition }) => {
	const statisticsColumnsSelect = (selections: any, position: string) => {
		if (onStatisticsColumnsSelect) {
			onStatisticsColumnsSelect(selections);
		}
	};

	const statisticSquadColumnsByPosition = playerPosition === 'keeper' ? statisticSquadGoalkeepersColumns : statisticSquadColumns;

	return (
		<>
			<Select
				isMulti
				options={statisticSquadColumnsByPosition(t)}
				onChange={(selections) => statisticsColumnsSelect(selections, playerPosition)}
				value={selectedItemsToOptions(value)}
				noOptionsMessage={(inputValue) => inputValue && t('no_options')}
			/>
		</>
	);
};

export default StatisticSquadColumnsSelect;
