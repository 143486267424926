import _ from 'lodash';
import debounce from 'lodash.debounce';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PARAGRAPH_BLOCK } from '../../constants/block-list.constants';
import { EditorTypes } from '../../constants/block.types';
import Global from '../../helpers/global.helpers';
import BlockModel from '../../models/block.model';
import { blockManagementService } from '../../subcomponents/blocky.component';
import EditorEditBlock from './editor-block-edit.component';
import EditorHelper from './helpers/editor-block-edit.helper';

import './editor-block.component.scss';

class EditorBlock extends Component {
	constructor(props) {
		super(props);
		const editorHelper = new EditorHelper();
		this.state = {
			linkingConfig: editorHelper.getLinkingFeatureConfig(),
		};
	}

	shouldComponentUpdate(nextProps) {
		const tagsCurrent = this.props.block.data.tags ? this.props.block.data.tags.filter((tag) => !tag.removed) : [];
		const tagsNext = nextProps.block.data.tags ? nextProps.block.data.tags.filter((tag) => !tag.removed) : [];

		return (
			this.props.block.data.content !== nextProps.block.data.content ||
			this.props.block.data.type !== nextProps.block.data.type ||
			!_.isEqual(tagsCurrent, tagsNext) ||
			this.props.profileLang !== nextProps.profileLang
		);
	}

	onTypeChange = (data) => {
		this.onEditorChange(data);
	};

	onEditorChange = (data) => {
		const { block, index } = this.props;
		data.placeholderName = block.id;
		const blockModel = BlockModel.builder().withType(block.type).withData(data).withId(block.id).build();
		blockManagementService.blockUpdate(blockModel, index);
	};

	onEditorChangeDebounce = debounce((data) => {
		this.onEditorChange(data);
	}, 500);

	addParagraphBlock = () => {
		const { index } = this.props;
		const editorHelper = new EditorHelper();
		const content = editorHelper.getContentForNewParagraph();
		const data = { ...PARAGRAPH_BLOCK.default_data.data };
		data.content = content;

		const paragraphBlock = BlockModel.builder()
			.withData(Object.assign({}, data))
			.withId(Global.makeId(6))
			.withType(EditorTypes.editor)
			.build();

		blockManagementService.blockAdd({ block: paragraphBlock, index });

		return paragraphBlock;
	};

	render() {
		const { block, onRemove, t } = this.props;
		const { linkingConfig } = this.state;
		block.data.placeholderName = block.id;

		return (
			<EditorEditBlock
				data={Object.assign({}, block.data)}
				onChange={this.onEditorChangeDebounce}
				onTypeChange={this.onTypeChange}
				linkingConfig={linkingConfig}
				t={t}
				onAddParagraph={this.addParagraphBlock}
				onRemove={onRemove}
			/>
		);
	}
}

function mapStateToProps(state) {
	return {
		profileLang: state.profile.profile.language,
	};
}

export default connect(mapStateToProps, null)(EditorBlock);
