import 'reflect-metadata';
import { AnyT, jsonArrayMember, jsonMember, jsonObject } from 'typedjson';

@jsonObject
export default class RelatedModel {
	@jsonMember(String)
	public provider: string = '';
	@jsonMember(String)
	public type: string = '';
	@jsonMember(AnyT)
	public data: any;
}
