import {
	REQUEST_ARTICLE_ORIGINS_RECEIVED,
	REQUEST_VIDEO_ORIGINS_RECEIVED,
	REQUEST_GALLERY_ORIGINS_RECEIVED,
	REQUEST_IMAGE_ORIGINS_RECEIVED,
} from '../action-creators/origins-action.creator';
import ModelMapper from '../../models/ModelMapper';

export function originsMiddleware() {
	return function (next: any) {
		return function (action: any) {
			if (action) {
				if (action.type === REQUEST_ARTICLE_ORIGINS_RECEIVED) {
					action.payload = ModelMapper.remapResponseToContentAttributesList(action.payload.data.data);
				}

				if (action.type === REQUEST_VIDEO_ORIGINS_RECEIVED) {
					action.payload = ModelMapper.remapResponseToContentAttributesList(action.payload.data.data);
				}

				if (action.type === REQUEST_GALLERY_ORIGINS_RECEIVED) {
					action.payload = ModelMapper.remapResponseToContentAttributesList(action.payload.data.data);
				}

				if (action.type === REQUEST_IMAGE_ORIGINS_RECEIVED) {
					action.payload = ModelMapper.remapResponseToContentAttributesList(action.payload.data.data);
				}
			}
			next(action);
		};
	};
}
