import React from 'react';
import { Name } from '../fields/name';
import { isSaveDisabled, coachSave } from '../helpers/manual-data.helper';
import { connect } from 'react-redux';
import { Col, Row, Button } from 'reactstrap';
import CountrySelect from '../fields/country';
import moment from 'moment';
import { COACH_ENTITY_CREATE_SUCCESS, returnObjectForCoachEntityCreate } from '../../../../../store/action-creators/coach-action-creator';
import Project from '../../../../../models/project/Project';
import SidebarCollapsibleElements from '../../../../Partials/Sidebar/sidebar-collapsible-elements/sidebar-collapsible-elements';
import { ContentTypes } from '../../../../Partials/Blocky/constants/block.types';
import SidebarElementsToggle from '../../../../Partials/Sidebar/sidebar-collapsible-elements/sidebar-elements-toggle/sidebar-elements-toggle.component';
import BirthDateSelect from '../fields/birth-date';
import CoachManualDataModel from '../../../../../models/coach-manual-data/coach-manual-data.model';
import AssetsModel from '../../../../../models/team-manual-data/assets/assets';
import { AssetsMediaComponent } from '../assets-media/assets-media.component';
import Profile from '../../../../../models/profile/Profile';
import {
	ContentSidebarChangeCollapseStatus,
	onChangeCollapseStatus,
	onSidebarElemSort,
	toggleSidebarEditMode,
} from '../../../../../services/content-sidebar/content-sidebar.helper';
import { FormattedSidebarSectionValue } from '../../../../Partials/Sidebar/sidebar-collapsible-elements/utils/temp-sidebar.types';
import GlobalModal from '../../../../Partials/Modals/Global/global-modal';
import {
	appendBeforeUnloadResetTempSidebar,
	defaultTempSidebarFromApiResponse,
	overwriteTempSidebarByContentType,
	saveSidebarSettingsByContentType,
} from '../../../../Partials/Sidebar/sidebar-collapsible-elements/utils/temp-sidebar.helper';

export type Properties = {
	t: any;
	history: any;
	project: Project;
	profile: Profile;
	coachEntityCreate: (coach: CoachManualDataModel, project: Project, assets: AssetsModel) => void;
};

export type State = {
	isActive: boolean;
	isSidebarInEdit: boolean;
	coachDataEntry: CoachManualDataModel;
	assets: AssetsModel;
	openSidebarSettingsModalFlag: boolean;
};

class CoachCreateComponent extends React.Component<Properties, State> {
	constructor(props: any) {
		super(props);
		this.state = {
			isActive: false,
			isSidebarInEdit: false,
			coachDataEntry: CoachManualDataModel.builder().withName('').build(),
			assets: AssetsModel.builder().build(),
			openSidebarSettingsModalFlag: false,
		};
	}

	componentDidMount() {
		window.addEventListener(COACH_ENTITY_CREATE_SUCCESS, (data: any) => {
			this.props.history.push(`/smp/coaches/edit/${data.detail}`);
		});
		appendBeforeUnloadResetTempSidebar(ContentTypes.coach);
	}

	onNameChange = (e: any) => {
		this.setState({
			...this.state,
			coachDataEntry: CoachManualDataModel.builder(this.state.coachDataEntry).withName(e.target.value).build(),
		});
	};

	onCountryChange = (country: any) => {
		this.setState({
			...this.state,
			coachDataEntry: CoachManualDataModel.builder(this.state.coachDataEntry).withCountry(country).build(),
		});
	};

	onBirthDateChange = (date: any) => {
		this.setState({
			...this.state,
			coachDataEntry: CoachManualDataModel.builder(this.state.coachDataEntry)
				.withBirthdate(date ? moment(date).format('YYYY-MM-DD') : '')
				.build(),
		});
	};

	toggleSidebarEditMode = () => {
		const newState = toggleSidebarEditMode({ ...this.state }, ContentTypes.coach);
		this.setState(newState);
	};

	updateAssets = (assets: AssetsModel) => {
		this.setState({ ...this.state, assets: assets });
	};

	onCoachSave = () => this.props.coachEntityCreate(coachSave(this.state.coachDataEntry), this.props.project, this.state.assets);

	render() {
		const { t, project } = this.props;
		const { coachDataEntry, isSidebarInEdit, assets, openSidebarSettingsModalFlag } = this.state;
		const sidebarComponentsMap = {
			media: (
				<AssetsMediaComponent
					t={t}
					currentProject={project}
					assets={assets}
					dataEntry={coachDataEntry}
					updateAssets={this.updateAssets}
					dataType='coach'
				/>
			),
		};

		return (
			<div className='animated fadeIn'>
				<Row>
					<Col col='8' lg='8' md='12' sm='12' xs='12'>
						<div className='card'>
							<div className='card-header d-flex align-items-center '>
								<div className='mr-auto mb-0'>{t('coach_info')}</div>
								<Button
									size='sm'
									color='primary'
									disabled={isSaveDisabled(coachDataEntry, ContentTypes.coach)}
									className='text-uppercase font-weight-bold'
									onClick={this.onCoachSave}
									id='coach-manual-data-entry-create-save-top'
								>
									<i className='fa fa-floppy-o' /> {t('save_coach')}
								</Button>
							</div>
							<div className='card-body'>
								<Row>
									<div className='alert alert-info w-100' role='alert'>
										{t('enter_the_coach_name_in_english')}
									</div>
								</Row>
								<Row>
									<Name value={coachDataEntry.name} onChange={this.onNameChange} t={t} type='coach' />
									<BirthDateSelect value={coachDataEntry.birthdate} t={t} onChange={this.onBirthDateChange} />
								</Row>
								<Row>
									<CountrySelect value={coachDataEntry.country} onChange={this.onCountryChange} t={t} />
								</Row>
								<Row>
									<Button
										disabled={isSaveDisabled(coachDataEntry, ContentTypes.coach)}
										color='primary'
										className='text-uppercase font-weight-bold'
										onClick={this.onCoachSave}
										id='coach-manual-data-entry-create-save-bottom'
									>
										<i className='fa fa-floppy-o' /> {t('save_coach')}
									</Button>
								</Row>
							</div>
						</div>
					</Col>
					<Col col='4' lg='4' md='12' sm='12' xs='12' className='position-relative'>
						<SidebarElementsToggle t={t} toggleSidebarEditMode={this.toggleSidebarEditMode} isSidebarInEdit={isSidebarInEdit} />
						<SidebarCollapsibleElements
							isSidebarInEdit={isSidebarInEdit}
							onElemSort={(settingsValue: FormattedSidebarSectionValue[]) => onSidebarElemSort(settingsValue, ContentTypes.coach)}
							onChangeCollapseStatus={(data: ContentSidebarChangeCollapseStatus) => onChangeCollapseStatus(data)}
							contentType={ContentTypes.coach}
							sidebarComponentsMap={sidebarComponentsMap}
							t={t}
						/>
					</Col>
				</Row>
				<GlobalModal
					isOpen={openSidebarSettingsModalFlag}
					t={this.props.t}
					headerContent='save_sidebar_settings'
					bodyContent='unsaved_changes'
					submitFunction={() => saveSidebarSettingsByContentType(ContentTypes.coach, t)}
					beforeCloseFunction={() =>
						overwriteTempSidebarByContentType(ContentTypes.coach, defaultTempSidebarFromApiResponse[ContentTypes.coach].value)
					}
				/>
			</div>
		);
	}
}

function mapStateToProps(state: any) {
	return {
		project: state.project.currentProject,
		profile: state.profile.profile,
	};
}

function mapDispatchToProps(dispatch: any) {
	return {
		coachEntityCreate: (coachData: CoachManualDataModel, project: Project, assets: AssetsModel) =>
			dispatch(returnObjectForCoachEntityCreate(coachData, project, assets)),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(CoachCreateComponent);
