import FansUnitedWidgetModel from './fans-united-widget.model';
import { FansUnitedWidgetJson } from './fans-united-widget.json';
import MatchModel from '../../../../partials/match-select/models/match.model';
import PlayerModel from '../../../../partials/player-select/models/player.model';
import TeamModel from '../../../../partials/team-select/models/team.model';
import FansUnitedGameModel from './fans-united-game.model';
import FansUnitedLeaderboardTemplatesModel from './fans-united-leaderboard-templates.model';
import FansUnitedClassicQuizModel from './fans-united-classic-quiz.model';
import FansUnitedEitherOrModel from './fans-united-either-or.model';

export default class FansUnitedWidgetBuilder {
	readonly json: FansUnitedWidgetJson;

	constructor(fansUnited?: FansUnitedWidgetModel | FansUnitedWidgetJson) {
		if (fansUnited && fansUnited instanceof FansUnitedWidgetModel) {
			this.json = fansUnited.toJson();
		} else if (fansUnited) {
			this.json = fansUnited;
		} else {
			this.json = {} as FansUnitedWidgetJson;
		}
	}

	/**
	 * Match property is used for events from the football api, game property is used for events from the FU predictions api
	 */
	withMatch(match: MatchModel): FansUnitedWidgetBuilder {
		this.json.match = match;

		return this;
	}

	withGame(game: FansUnitedGameModel): FansUnitedWidgetBuilder {
		this.json.game = game;

		return this;
	}

	withTemplate(template: FansUnitedLeaderboardTemplatesModel): FansUnitedWidgetBuilder {
		this.json.template = template;

		return this;
	}

	withClassicQuiz(quiz: FansUnitedClassicQuizModel): FansUnitedWidgetBuilder {
		this.json.classicQuiz = quiz;

		return this;
	}

	withEitherOrGame(eitherOrGame: FansUnitedEitherOrModel): FansUnitedWidgetBuilder {
		this.json.eitherOrGame = eitherOrGame;

		return this;
	}

	withTeams(teams: any[]): FansUnitedWidgetBuilder {
		this.json.teams = teams;

		return this;
	}

	withPlayer(player: PlayerModel): FansUnitedWidgetBuilder {
		this.json.player = player;

		return this;
	}

	withTeamAgainst(teamAgainst: TeamModel): FansUnitedWidgetBuilder {
		this.json.teamAgainst = teamAgainst;

		return this;
	}

	withMarket(market: any): FansUnitedWidgetBuilder {
		this.json.market = market;

		return this;
	}

	withDisplayOdds(displayOdds: boolean): FansUnitedWidgetBuilder {
		this.json.displayOdds = displayOdds;

		return this;
	}

	build(): FansUnitedWidgetModel {
		return FansUnitedWidgetModel.fromJson(this.json);
	}
}
