import React from 'react';
import { compose } from 'redux';
import { SportTypes, WidgetScriptTypes } from '../../../../../../../constants/sport.types';
import { ViewTypes } from '../../../../constants/general.constants';
import BlockModel from '../../../../models/block.model';
import BlockUpdateController from '../../../block-update-controller.component';
import { MonorepoWidgetPreview } from '../../widget-preview/monorepo-widget-preview.component';
import BasketballStandingsEditComponent from './basketball-standings-edit.component';
import { BasketballStandingsViewComponent } from './basketball-standings-view.component';
import BasketballStandingsModel from '../models/basketball-standings.model';

type Properties = {
	block: BlockModel;
	view: ViewTypes;
	t: any;
	contentData: any;
	contentLanguage: string;
};

const BasketballStandingsWidget: React.FunctionComponent<Properties> = (props) => {
	const { view, t, block, contentData, contentLanguage } = props;

	if (view === ViewTypes.edit) {
		return <BasketballStandingsEditComponent block={block} contentData={contentData} t={t} contentLanguage={contentLanguage} />;
	}

	if (view === ViewTypes.preview) {
		return (
			<MonorepoWidgetPreview
				blockContent={props.block.data.content}
				config={props.block.data.config}
				type={WidgetScriptTypes.BASKETBALL_WIDGETS}
				sport={SportTypes.BASKETBALL}
			/>
		);
	}

	if (view === ViewTypes.normal) {
		return (
			<BasketballStandingsViewComponent
				t={t}
				preview={
					props.block.data.preview && props.block.data.preview.widgetModel
						? props.block.data.preview.widgetModel
						: BasketballStandingsModel.builder().build()
				}
			/>
		);
	}

	return null;
};

export default compose(BlockUpdateController)(BasketballStandingsWidget);
