import * as React from 'react';
import { Col, FormGroup, Input, Label, Row, Button } from 'reactstrap';
import moment from 'moment';
import GBVisionAudioModel from '../../../../../../../../models/v2/gb-vision-audio/gb-vision-audio-model';

type Properties = {
	audio: GBVisionAudioModel;
	t: any;
	onSelect: (audio: GBVisionAudioModel) => void;
	selectedAudio: GBVisionAudioModel;
	index: number;
};

const GBVisionAudioRow: React.FunctionComponent<Properties> = (props) => {
	const { audio, selectedAudio, onSelect } = props;
	const isSelectedAudio = selectedAudio && audio.id === selectedAudio.id;
	const selectAudio = () => {
		onSelect(audio);
	};

	return (
		<FormGroup key={`key-${audio.id}`} className='w-100 pr-2 pt-2 pb-2 border-bottom' check>
			<Input
				className='form-check-input'
				onChange={selectAudio}
				type='radio'
				id={`${audio.id}`}
				checked={isSelectedAudio}
				name={`inline-radio-${audio.id}`}
				value={audio.id}
			/>
			<Label className='form-check-label' check htmlFor={`${audio.id}`}>
				{`${moment(`${audio.date} ${audio.time}`).format('DD MMM YYYY, HH:mm')} - `}
				<span>{audio.home}</span>
				{`${audio.away ? ' - ' : ''}`}
				<span>{audio.away}</span>
			</Label>
		</FormGroup>
	);
};

export default GBVisionAudioRow;
