const colors = {
	team: '#fc1111',
	player: '#27db3f',
	tournament: '#adccf7',
	coach: '#215bac',
	venue: '#000000',
	club: '#fc1111',
	team_president: '#ffea2c',
};

export const sportsConnectionColorStyles = {
	multiValue: (styles: any, state: any) => {
		const dataEntityType = state.data.data.entity_type;
		const color = colors[dataEntityType];

		return {
			...styles,
			borderBottom: `2px solid ${color}`,
		};
	},
};
