import { LineupJson } from './lineup.json';
import { LineupRequestJson } from './lineup-request.json';
import LineupModel from './lineup.model';
import TeamLineupModel from '../team-lineup/team-lineup.model';
import BlacklistModel from '../../../../../models/blacklist/blacklist';

export default class LineupBuilder {
	private json: LineupJson;

	constructor(lineup?: LineupModel | LineupJson) {
		if (lineup && lineup instanceof LineupModel) {
			this.json = lineup.toJSON();
		} else if (lineup) {
			this.json = lineup;
		} else {
			this.json = {} as LineupJson;
		}
	}

	withId(matchId: string): LineupBuilder {
		this.json.matchId = matchId;

		return this;
	}

	withHomeTeam(homeTeam: TeamLineupModel): LineupBuilder {
		this.json.homeTeam = homeTeam;

		return this;
	}

	withAwayTeam(awayTeam: TeamLineupModel): LineupBuilder {
		this.json.awayTeam = awayTeam;

		return this;
	}

	withStatus(status: string): LineupBuilder {
		this.json.status = status;

		return this;
	}

	withBlacklist(blacklist: BlacklistModel): LineupBuilder {
		this.json.blacklist = blacklist;

		return this;
	}

	build(): LineupModel {
		return LineupModel.fromJSON(this.json);
	}

	toRequestJson(): LineupRequestJson {
		let json = {} as LineupRequestJson;
		json.home_team = TeamLineupModel.builder(this.json.homeTeam).toRequestJson();
		json.away_team = TeamLineupModel.builder(this.json.awayTeam).toRequestJson();
		json.status = this.json.status;

		return json;
	}
}
