import DugoutVideoModel from '../../../../../../../models/dugout-videos/dugout-video/dugout-video.model';

export const constructDugoutVideoContent = (video: DugoutVideoModel) => {
	const hash = video.hash;

	if (hash && hash.length > 0) {
		return `<iframe src='https://embed.dugout.com/v2/?p=${hash}' className='iframe-dugout' frameBorder='0' allowFullScreen scrolling='no'></iframe>`;
	}

	return '';
};

export const stripHtml = (text: string): string => text.replace(/(<([^>]+)>)/gi, '');
