import { TypedJSON } from 'typedjson';
import BasketballStageModel from '../../../../../../models/v2/Stage/Entity/basketball-stage.model';
import TennisSeasonStagesModel from '../../../../../../models/v2/tennis-competition-season/entity/tennis-season-stages.model';

export const responseToStageModel = (response: any) => {
	const serializer = new TypedJSON(BasketballStageModel);
	const remmapedStage = serializer.parse(response) as BasketballStageModel;

	return remmapedStage;
};

export const stagesToOptions = (stages: BasketballStageModel[] | TennisSeasonStagesModel[]) => {
	if (stages && stages.length > 0) {
		return stages.map((stage) => {
			return stageToOption(stage);
		});
	}

	return [];
};

export const stageToOption = (stage: BasketballStageModel | TennisSeasonStagesModel) => {
	if (stage) {
		return {
			value: stage.id,
			label: stage.name,
			data: stage,
			type: 'stage',
		};
	} else {
		return {};
	}
};
