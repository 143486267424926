import 'reflect-metadata';
import { TypedJSON } from 'typedjson';
import SportsTypesModel from './sports-types.model';
import EntityTypesModel from './entity-types.model';
import SportsModel from './sports.model';

export default class SportsMapper {
	public responseToModel = (response: any): SportsModel => {
		const serializer = new TypedJSON(SportsModel);
		return serializer.parse(response) as SportsModel;
	};

	public modelToRequestBody = (model: SportsModel) => {
		if (model) {
			return {
				results: this.sportsTypesToRequest(model.results),
			};
		}
		return new SportsModel();
	};

	private sportsTypesToRequest = (sportsTypes: SportsTypesModel[]) => {
		return sportsTypes
			? sportsTypes.map((sport: SportsTypesModel) => {
					return {
						sport: sport.sport,
						title: sport.title,
						entity_types: this.entityTypesToRequest(sport.entity_types),
					};
			  })
			: [];
	};

	private entityTypesToRequest = (entityTypes: EntityTypesModel[]) => {
		return entityTypes
			? entityTypes.map((entity: EntityTypesModel) => {
					return {
						name: entity.name,
						type: entity.type,
					};
			  })
			: [];
	};
}
