import * as React from 'react';
import Matches from './matches';
import {
	convertTeamIdsToMatchSearchUrls,
	markMatchModelAsSelected,
	removePastMatches,
	responseToMatchModel,
} from './helpers/matches-container-helper';
import HttpService from '../../../../../services/rest/HttpService';
import { Properties, State } from './properties/matches-container.properties';

export default class MatchesContainer extends React.Component<Properties, State> {
	private initialState = {
		matchesFinished: [],
		matchesNotStarted: [],
		matchesInterrupted: [],
		selectedTeamNames: [],
		isContentLoading: false,
	};

	constructor(props: any) {
		super(props);
		this.state = { ...this.initialState };
	}

	static getDerivedStateFromProps(props: any, state: any) {
		const { selectedMatchId, selectedMatchIds, isMulti } = props;

		let finished = markMatchModelAsSelected(state.matchesFinished, selectedMatchId, selectedMatchIds, isMulti);
		let notStarted = markMatchModelAsSelected(state.matchesNotStarted, selectedMatchId, selectedMatchIds, isMulti);
		let interrupted = markMatchModelAsSelected(state.matchesInterrupted, selectedMatchId, selectedMatchIds, isMulti);
		state = {
			...state,
			matchesFinished: finished,
			matchesNotStarted: notStarted,
			matchesInterrupted: interrupted,
			selectedTeamNames: state.selectedTeamNames,
		};

		return state;
	}

	componentDidMount() {
		const { isMulti, teamIds, teamNames, selectedMatchId, selectedMatchIds, round } = this.props;

		if (teamIds && teamIds.length > 0) {
			this.setState({ ...this.state, selectedTeamNames: teamNames });
			this.getMatches(convertTeamIdsToMatchSearchUrls(teamIds), selectedMatchId, selectedMatchIds, isMulti);
		}

		if (round && round.round) {
			this.matchesByRound(selectedMatchId, selectedMatchIds, isMulti);
		}
	}

	// Using componentDidUpdate to capture the prop changes received from the parent component
	// and update the current state of this component with the relevant data
	componentDidUpdate(prevProps: any) {
		let teamIds = this.props.teamIds;
		let prevTeamIds = prevProps.teamIds;
		let teamNames = this.props.teamNames;
		let selectedMatchId = this.props.selectedMatchId;
		let selectedMatchIds = this.props.selectedMatchIds;

		// If no teams are selected (additional check form prevTeamIds
		// is performed to escape endless component update)
		if (teamIds && prevTeamIds.length > 0 && teamIds.length < 1) {
			this.setState({ ...this.initialState });
		}

		// Updates the state with the selected team names
		// and when the request for matches is received updates the state with
		// the needed matches using the structure of the @MatchModel
		if (teamIds && JSON.stringify(prevTeamIds) !== JSON.stringify(teamIds)) {
			this.setState({ ...this.state, selectedTeamNames: teamNames });

			if (teamIds.length < 3 && teamIds.length > 0) {
				this.getMatches(convertTeamIdsToMatchSearchUrls(teamIds), selectedMatchId, selectedMatchIds, this.props.isMulti);
			}

			if (teamIds.length < 1) {
				this.setState({ ...this.initialState });
			}
		}

		if (prevProps.round && prevProps.round.round !== this.props.round.round) {
			this.matchesByRound(selectedMatchId, selectedMatchIds, this.props.isMulti);
		}
	}

	matchesByRound = (selectedMatchId: string, selectedMatchIds: string[], isMulti: any) => {
		const { maxNotStartedMatches, maxFinishedMatches, maxInterruptedMatches } = this.props;
		this.setState({ ...this.state, isContentLoading: true });

		HttpService.getRoundEvents(this.props.stageId, this.props.round.round)
			.then((response: any) => {
				let notStarted = response.data
					.reverse()
					.slice(0, maxNotStartedMatches)
					.filter((item: any) => item.event_status.type === 'notstarted')
					.map((item: any) => responseToMatchModel(item));
				let finished = response.data
					.reverse()
					.slice(0, maxFinishedMatches)
					.filter((item: any) => item.event_status.type === 'finished')
					.map((item: any) => responseToMatchModel(item));
				let progress = response.data
					.filter((item: any) => item.event_status.type === 'inprogress')
					.map((item: any) => responseToMatchModel(item));
				let interrupted = response.data
					.reverse()
					.slice(0, maxInterruptedMatches)
					.filter((item: any) => item.event_status.type === 'interrupted')
					.map((item: any) => responseToMatchModel(item));

				notStarted = [...progress, ...removePastMatches([...notStarted])];
				notStarted = markMatchModelAsSelected(notStarted, selectedMatchId, selectedMatchIds, isMulti);
				finished = markMatchModelAsSelected(finished, selectedMatchId, selectedMatchIds, isMulti);
				interrupted = markMatchModelAsSelected(interrupted, selectedMatchId, selectedMatchIds, isMulti);
				this.setState({
					matchesFinished: finished,
					matchesNotStarted: notStarted,
					matchesInterrupted: interrupted,
					isContentLoading: false,
				});
			})
			.catch((e: any) => e);
	};

	getMatches(urls: string[], selectedMatchId: string, selectedMatchIds: string[], isMulti: boolean) {
		const { maxNotStartedMatches, maxFinishedMatches, maxInterruptedMatches, hideMatchStatusTabs } = this.props;
		this.setState({ ...this.state, isContentLoading: true });

		if (hideMatchStatusTabs) {
			HttpService.requestMultipleFootballRequests([urls[0], urls[2]])
				.then((response: any) => {
					let notStarted = response[0].data
						.reverse()
						.slice(0, maxNotStartedMatches)
						.map((item: any) => responseToMatchModel(item));
					let progress = response[1].data.map((item: any) => responseToMatchModel(item));

					notStarted = [...progress, ...removePastMatches([...notStarted])];
					notStarted = markMatchModelAsSelected(notStarted, selectedMatchId, selectedMatchIds, isMulti);
					this.setState({
						matchesNotStarted: notStarted,
						isContentLoading: false,
					});
				})
				.catch((e: any) => e);
		} else {
			HttpService.requestMultipleFootballRequests([...urls])
				.then((response: any) => {
					let notStarted = response[0].data
						.reverse()
						.slice(0, maxNotStartedMatches)
						.map((item: any) => responseToMatchModel(item));
					let finished = response[1].data.slice(0, maxFinishedMatches).map((item: any) => responseToMatchModel(item));
					let progress = response[2].data.map((item: any) => responseToMatchModel(item));
					let interrupted = response[3].data.slice(0, maxInterruptedMatches).map((item: any) => responseToMatchModel(item));

					notStarted = [...progress, ...removePastMatches([...notStarted])];
					notStarted = markMatchModelAsSelected(notStarted, selectedMatchId, selectedMatchIds, isMulti);
					finished = markMatchModelAsSelected(finished, selectedMatchId, selectedMatchIds, isMulti);
					interrupted = markMatchModelAsSelected(interrupted, selectedMatchId, selectedMatchIds, isMulti);
					this.setState({
						matchesFinished: finished,
						matchesNotStarted: notStarted,
						matchesInterrupted: interrupted,
						isContentLoading: false,
					});
				})
				.catch((e: any) => e);
		}
	}

	render() {
		const {
			isMulti,
			onSelect,
			updateCheckAllMatchesState,
			upcomingMatchesAreSelected,
			finishedMatchesAreSelected,
			interruptedMatchesAreSelected,
			hideMatchStatusTabs,
		} = this.props;

		return (
			<div className={`${this.state.isContentLoading ? 'loading-overlay' : ''}`}>
				<Matches
					selectedTeamNames={this.state.selectedTeamNames}
					t={this.props.t}
					hideMatchStatusTabs={hideMatchStatusTabs}
					isMulti={isMulti}
					onMatchSelect={onSelect}
					onMatchesSelect={onSelect}
					matchesFinished={this.state.matchesFinished}
					matchesNotStarted={this.state.matchesNotStarted}
					matchesInterrupted={this.state.matchesInterrupted}
					updateCheckAllMatchesState={updateCheckAllMatchesState}
					upcomingMatchesAreSelected={upcomingMatchesAreSelected}
					finishedMatchesAreSelected={finishedMatchesAreSelected}
					interruptedMatchesAreSelected={interruptedMatchesAreSelected}
				/>
			</div>
		);
	}
}
