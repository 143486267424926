export const extractContentValueByTheGivenKey = (content: any, statistic: any) => {
	let extractedContent = content;
	const statisticKeys = statistic.json_key.replaceAll('.', ' ').split(' ');

	statisticKeys.map((key: any) => {
		extractedContent = extractedContent && extractedContent[key];
	});

	return extractedContent;
};
