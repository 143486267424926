import Author from '../author/Author';
import Urls from '../urls/Urls';
import Seo from '../seo/Seo';
import Category from '../category/Category';
import { GalleryJson } from './GalleryJson';
import GalleryBuilder from './GalleryBuilder';
import { IContent } from '../IContent';
import MainMedia from '../mainmedia/MainMedia';
import GalleryItem from './item/GalleryItem';
import ContentAttributes from '../content-attributes/content-attributes-model';
import BlockModel from '../../views/Partials/Blocky/models/block.model';
import TranslationGroupModel from '../translation-groups/translation-group-model';
import { DynamicObjectWithKeys } from '../../constants/content-types';
import { store } from '../../store/store';

export default class Gallery implements IContent {
	readonly id: string;
	readonly title: string;
	readonly subtitle: string;
	readonly strapline: string;
	readonly type: string;
	readonly body: BlockModel[];
	readonly publishedAt: string;
	readonly createdAt: string;
	readonly updatedAt: string;
	readonly publishedUntil: string;
	readonly status: string;
	readonly comments: any;
	readonly customAuthor: string;
	readonly authors: Author[];
	readonly image: any;
	readonly generic: any;
	readonly urls: Urls;
	readonly seo: Seo;
	readonly category: Category;
	readonly additionalCategories: Category[];
	readonly mainMedia: MainMedia[];
	readonly items: GalleryItem[];
	readonly allowComments: boolean;
	readonly createdBy: any;
	readonly distributionRegions: ContentAttributes[] = [];
	readonly distributionChannels: ContentAttributes[] = [];
	readonly origin: ContentAttributes;
	readonly footer: string;
	readonly viewsCount: any;
	readonly commentsCount: any;
	readonly language: string;
	readonly translationGroup: TranslationGroupModel;
	readonly properties: DynamicObjectWithKeys;

	private constructor(
		id: string,
		title: string,
		subtitle: string,
		strapline: string,
		type: string,
		body: BlockModel[],
		publishedAt: string,
		createdAt: string,
		updatedAt: string,
		publishedUntil: string,
		status: string,
		comments: any,
		customAuthor: string,
		authors: Author[],
		image: any,
		generic: any,
		urls: Urls,
		seo: Seo,
		category: Category,
		additionalCategories: Category[],
		mainMedia: MainMedia[],
		items: GalleryItem[],
		allowComments: boolean,
		createdBy: any,
		distributionRegions: ContentAttributes[],
		distributionChannels: ContentAttributes[],
		origin: ContentAttributes,
		footer: string,
		viewsCount: number,
		commentsCount: number,
		language: string,
		translationGroup: TranslationGroupModel,
		properties: DynamicObjectWithKeys,
	) {
		this.id = id;
		this.title = title;
		this.subtitle = subtitle;
		this.strapline = strapline;
		this.type = type;
		this.body = body;
		this.publishedAt = publishedAt;
		this.createdAt = createdAt;
		this.updatedAt = updatedAt;
		this.publishedUntil = publishedUntil;
		this.status = status;
		this.comments = comments;
		this.customAuthor = customAuthor;
		this.authors = authors;
		this.image = image;
		this.generic = generic;
		this.urls = urls;
		this.seo = seo;
		this.category = category;
		this.additionalCategories = additionalCategories;
		this.mainMedia = mainMedia;
		this.items = items;
		this.allowComments = allowComments;
		this.createdBy = createdBy;
		this.distributionRegions = distributionRegions;
		this.distributionChannels = distributionChannels;
		this.origin = origin;
		this.footer = footer;
		this.viewsCount = viewsCount;
		this.commentsCount = commentsCount;
		this.language = language;
		this.translationGroup = translationGroup;
		this.properties = properties;
	}

	toJSON(): GalleryJson {
		return {
			id: this.id,
			title: this.title,
			subtitle: this.subtitle,
			strapline: this.strapline,
			type: this.type,
			body: this.body,
			publishedAt: this.publishedAt,
			createdAt: this.createdAt,
			updatedAt: this.updatedAt,
			publishedUntil: this.publishedUntil,
			status: this.status,
			comments: this.comments,
			customAuthor: this.customAuthor,
			authors: this.authors,
			image: this.image,
			generic: this.generic,
			urls: this.urls,
			seo: this.seo,
			category: this.category,
			additionalCategories: this.additionalCategories,
			mainMedia: this.mainMedia,
			items: this.items,
			allowComments: this.allowComments,
			createdBy: this.createdBy,
			distributionRegions: this.distributionRegions,
			distributionChannels: this.distributionChannels,
			origin: this.origin,
			footer: this.footer,
			viewsCount: this.viewsCount,
			commentsCount: this.commentsCount,
			language: this.language,
			translationGroup: this.translationGroup,
			properties: this.properties,
		};
	}

	static fromJSON(json: GalleryJson): Gallery {
		return new Gallery(
			json.id,
			json.title,
			json.subtitle,
			json.strapline,
			json.type,
			json.body,
			json.publishedAt,
			json.createdAt,
			json.updatedAt,
			json.publishedUntil,
			json.status,
			json.comments,
			json.customAuthor,
			json.authors,
			json.image,
			json.generic,
			json.urls,
			json.seo,
			json.category,
			json.additionalCategories,
			json.mainMedia,
			json.items,
			json.allowComments,
			json.createdBy,
			json.distributionRegions,
			json.distributionChannels,
			json.origin,
			json.footer,
			json.viewsCount,
			json.commentsCount,
			json.language,
			json.translationGroup,
			json.properties,
		);
	}

	static builder(gallery?: Gallery): GalleryBuilder {
		return new GalleryBuilder(gallery);
	}

	static extractDynamicPropertiesKeys(): string[] {
		const reduxStoreArticleProperties = store.getState().contentProperties.galleryProperties;
		return reduxStoreArticleProperties.map((galleryProperty: ContentAttributes) => galleryProperty.slug);
	}

	static extractDynamicPropertiesKeysWithDescription(): DynamicObjectWithKeys[] {
		const reduxStoreGalleryProperties = store.getState().contentProperties.galleryProperties;
		return reduxStoreGalleryProperties.map((galleryProperty: ContentAttributes) => {
			return { slug: galleryProperty.slug, description: galleryProperty.name };
		});
	}

	static extractDynamicPropertiesAsObjectWithValues(): DynamicObjectWithKeys {
		const reduxStoreGalleryProperties = store.getState().contentProperties.galleryProperties || [];
		return reduxStoreGalleryProperties.reduce(
			(obj: ContentAttributes, item: ContentAttributes) => Object.assign(obj, { [item.slug]: item.isDefault }),
			{},
		);
	}
}
