import { GalleryItemJson } from './GalleryItemJson';
import GalleryItem from './GalleryItem';
import Image from '../../image/Image';
import GalleryBuilder from '../GalleryBuilder';

export default class GalleryItemBuilder {
	private json: GalleryItemJson;

	constructor(galleryItem?: GalleryItem | GalleryItemJson) {
		if (galleryItem && galleryItem instanceof GalleryItem) {
			this.json = galleryItem.toJSON();
		} else if (galleryItem) {
			this.json = galleryItem;
		} else {
			this.json = {} as GalleryItemJson;
		}
	}

	withId(id: string): GalleryItemBuilder {
		this.json.id = id;

		return this;
	}

	withComment(comment: string): GalleryItemBuilder {
		this.json.comment = comment;

		return this;
	}

	withType(type: string): GalleryItemBuilder {
		this.json.type = type;

		return this;
	}

	withData(data: Image): GalleryItemBuilder {
		this.json.data = data;

		return this;
	}

	withAddDescriptionToMedia(addDescriptionToMedia: boolean): GalleryItemBuilder {
		this.json.addDescriptionToMedia = addDescriptionToMedia;

		return this;
	}

	withDescription(description: string): GalleryItemBuilder {
		this.json.description = description;

		return this;
	}

	withGeneric(generic: any): GalleryItemBuilder {
		this.json.generic = generic;

		return this;
	}

	build(): GalleryItem {
		return GalleryItem.fromJSON(this.json);
	}
}
