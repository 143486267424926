import { featuresService } from '../../../../../App';
import { OptionTypeData } from '../../../../../constants/select';
import { SportTypes } from '../../../../../constants/sport.types';
import { capitalize } from '../../../../../global-helpers/global.helpers';
import Related from '../../../../../models/related/Related';
import SportsTypesModel from '../../../../../models/v2/sports-types/sports-types.model';

export const sportTypeToOption = (sport: SportsTypesModel): OptionTypeData => {
	if (sport) {
		return {
			label: capitalize(sport.title),
			value: sport.sport,
			data: sport,
		};
	}

	return {} as OptionTypeData;
};

export const availableSportsToOption = (availableSports: SportsTypesModel[]): OptionTypeData[] => {
	return availableSports.map((availableSport) => {
		return {
			label: capitalize(availableSport.title),
			value: availableSport.sport,
			data: availableSport,
		};
	});
};

const getKeyWithLargestValue = (obj: Record<string, number>): string | null => {
	let maxKey = null;
	let maxValue = 0;

	for (const [key, value] of Object.entries(obj)) {
		if (maxKey === null || value > maxValue) {
			maxKey = key;
			maxValue = value;
		}
	}

	return maxKey;
};

export const getSelectedSport = (
	availableSports: SportsTypesModel[],
	reduxSportConnections: Related[] | null,
	areFootballRelatedFeaturesEnabled: boolean,
): SportsTypesModel | null => {
	if (reduxSportConnections) {
		const sportsConnectionsCountBySport = reduxSportConnections.reduce((acc, connection) => {
			const sportFromConnection = connection.data.sport;
			const currentValue = acc[connection.data.sport] || 0;
			acc[sportFromConnection] = currentValue + 1;
			return acc;
		}, {});

		// get sport with most connections
		const sportStringWithMostConnections = getKeyWithLargestValue(sportsConnectionsCountBySport);
		// get sport model with most connections
		const sportWithMostConnections = availableSports && availableSports.find(({ sport }) => sport === sportStringWithMostConnections);

		// check if there is a sport with most connections
		if (sportWithMostConnections) {
			return sportWithMostConnections;
		}
	}

	if (!reduxSportConnections && areFootballRelatedFeaturesEnabled) {
		const footballSportIndex = availableSports.findIndex(({ sport }) => sport === SportTypes.FOOTBALL);
		return availableSports.findIndex(({ sport }) => sport === SportTypes.FOOTBALL) >= 0
			? availableSports[footballSportIndex]
			: availableSports[0];
	}

	return availableSports.length > 0 ? availableSports[0] : ({} as SportsTypesModel);
};
