import AssetsBuilder from './assets.builder';
import { AssetsJson } from './assets.json';

export default class AssetsModel {
	readonly logo: string;
	readonly homeKit: string;
	readonly awayKit: string;
	readonly squadImage: string;
	readonly image: string;
	readonly thumbnail: string;

	private constructor(logo: string, homeKit: string, awayKit: string, squadImage: string, image: string, thumbnail: string) {
		this.logo = logo;
		this.homeKit = homeKit;
		this.awayKit = awayKit;
		this.squadImage = squadImage;
		this.image = image;
		this.thumbnail = thumbnail;
	}

	toJSON(): AssetsJson {
		return {
			logo: this.logo,
			homeKit: this.homeKit,
			awayKit: this.awayKit,
			squadImage: this.squadImage,
			image: this.image,
			thumbnail: this.thumbnail,
		};
	}

	static fromJSON(json: AssetsJson): AssetsModel {
		return new AssetsModel(json.logo, json.homeKit, json.awayKit, json.squadImage, json.image, json.thumbnail);
	}

	static builder(assets?: AssetsModel): AssetsBuilder {
		return new AssetsBuilder(assets);
	}
}
