import 'reflect-metadata';
import { AnyT, jsonMember, jsonObject } from 'typedjson';
import TournamentResponseModel from '../../../../../models/v2/Tournament/Entity/response-tournament.model';

@jsonObject()
export default class LiveBlogTournamentSeasonResponseModel {
	@jsonMember(AnyT)
	public id: string = '';
	@jsonMember(String)
	public name: string = '';
	@jsonMember(Boolean)
	public active: boolean = false;
	@jsonMember(() => TournamentResponseModel)
	public tournament?: TournamentResponseModel;
	@jsonMember(String)
	public slug: string | null = null;
}
