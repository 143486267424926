import React, { FunctionComponent } from 'react';
import { Button, Col, FormGroup, Row } from 'reactstrap';
import { DebounceInput } from 'react-debounce-input';
import MainMedia from '../../../../../../../models/mainmedia/MainMedia';
import ErrorAsync from '../../../../../../Partials/BaseComponents/ErrorComponent/ErrorAsync';
import { videoEmbedResourceType, videoResourceType } from '../../../../../../Partials/Sidebar/media-refactored/constants/general';

export type Properties = {
	embedCode: string;
	t: any;
	index: number;
	onChange: (media: MainMedia) => any;
	onRemove: (type: string, subType: string) => any;
	contentType: string;
};

function onEmbedCodeChange(value: string, onChange: (media: MainMedia) => any) {
	const media = MainMedia.builder()
		.withData({ embedCode: value })
		.withResourceSubtype('video')
		.withResourceType('embed')
		.withProvider('external')
		.build();
	onChange(media);
}

export const MediaVideoEmbed: FunctionComponent<Properties> = (props) => {
	const { t, onChange, embedCode, onRemove, index, contentType } = props;

	return (
		<div id='sidebar-media-video'>
			{embedCode && embedCode.length > 0 && (
				<Col>
					<Button
						className='pull-right bg-danger border-0 float-right'
						id={'main-media-from-' + contentType + '-library-remove-btn'}
						title={t('button_remove')}
						onClick={() => {
							onRemove(videoEmbedResourceType, videoResourceType);
						}}
					>
						<i className='fa fa-trash' />
					</Button>
				</Col>
			)}
			<Row>
				<Col col='12' sm='12' md='12' className='mb-2'>
					<FormGroup>
						<DebounceInput
							className='form-control'
							element='textarea'
							rows={3}
							value={embedCode ? embedCode : ''}
							placeholder={t('embed_video_placeholder')}
							onChange={(event: any) => onEmbedCodeChange(event.target.value, onChange)}
						/>
					</FormGroup>
					<ErrorAsync t={t} errorType='main_media' mainMedia={`main_media.${index}.provider`} />
				</Col>
			</Row>
		</div>
	);
};
