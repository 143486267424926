import React, { FunctionComponent } from 'react';
import { GenIconButtonProps } from './constants';
import { getStarIcon } from '../../../../global-helpers/global-icons.helpers';
import './icons.scss';

const StarIcon: FunctionComponent<GenIconButtonProps> = ({ size = '16px', action, isActive = false, disabled = false }) => {
	const generatedClass = isActive ? 'star active' : 'star';
	return (
		<img
			src={getStarIcon(isActive)}
			className={generatedClass}
			onClick={!disabled ? action : undefined}
			width={size}
			height={size}
			aria-disabled={disabled}
		/>
	);
};

export default StarIcon;
