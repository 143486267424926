import React, { useState } from 'react';
import { Button, ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import List from '../../../../../models/list/List';

type Properties = {
	currentContent: any;
	onListItemAdd: (currentContent: any, addItemToTop?: boolean) => any;
	t: any;
	list: List;
	toggleFirstLockPositionError: (toggleError: boolean) => any;
	showAddToTopToggle: boolean;
};

const ListItemAddButtons: React.FunctionComponent<Properties> = (props) => {
	const { currentContent, onListItemAdd, t, list, toggleFirstLockPositionError, showAddToTopToggle } = props;

	const [dropdownOpen, setDropdownOpen] = useState({ isOpen: false, openedDropdownId: '' });

	const toggle = (id: string) =>
		setDropdownOpen((prevState: any) => {
			return { isOpen: !prevState.isOpen, openedDropdownId: id };
		});

	const addToTop = () => {
		list.lockedPositions.length === 0 ? onListItemAdd(currentContent, true) : toggleFirstLockPositionError(true);
	};
	return (
		<ButtonDropdown
			isOpen={dropdownOpen.openedDropdownId === currentContent.id && dropdownOpen.isOpen}
			toggle={() => toggle(currentContent.id)}
			className='ml-auto'
		>
			<Button
				outline
				id={`suggested-list-btn-select-${currentContent.id}`}
				color='primary'
				onClick={() => onListItemAdd(currentContent)}
				className='list-content-add-btn'
			>
				<i className={'fa fa-plus'}></i>
			</Button>
			{showAddToTopToggle && <DropdownToggle outline caret color='primary' className='list-content-add-btn' />}
			{showAddToTopToggle && (
				<DropdownMenu>
					<DropdownItem key='dropdown-item-first-occurrence' onClick={() => addToTop()}>
						{t('add_to_top')}
					</DropdownItem>
				</DropdownMenu>
			)}
		</ButtonDropdown>
	);
};

export default ListItemAddButtons;
