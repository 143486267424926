import {
	assetsPlaceholder,
	customEntitiesDomainPlaceholder,
	customEntitiesPlaceholder,
} from '../../../../../../../constants/assetsPlaceholder';
import { IEntity } from '../../../../../../../services/rest/AutoTagHttp';

export const getEntityMedia = (tag: any) => {
	if (tag && tag.display_asset && tag.display_asset.url) {
		return tag.display_asset.url;
	}

	if (tag && tag.icon && tag.icon.url) {
		return tag.icon.url;
	}

	if (assetsPlaceholder[tag.entity_type]) {
		return assetsPlaceholder[tag.entity_type];
	} else if (tag.contained_in_domain) {
		return customEntitiesPlaceholder;
	}

	return null;
};

export const getEntityDomainMedia = (tag: IEntity) => {
	const domain = tag && tag.contained_in_domain && tag.contained_in_domain.name && tag.contained_in_domain.name.toLowerCase();

	if (customEntitiesDomainPlaceholder[domain]) {
		return customEntitiesDomainPlaceholder[domain];
	} else {
		return customEntitiesDomainPlaceholder.default;
	}
};

export const remapAdditionalTags = (tag: any, tagParent: any) => {
	if (tag) {
		return { ...tag, parent: tagParent.id, subtype: tag.entity_type, text: tagParent.text };
	}

	return {};
};
