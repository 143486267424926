import * as React from 'react';
import { Properties, State } from './properties/KeywordsContainerProperties';
import Keywords from './Keywords';

export default class KeywordsContainer extends React.Component<Properties, State> {
	onKeywordsChange = (keywords: string[]) => {
		this.props.onKeywordsChange(keywords);
	};

	render() {
		const { keywords, t } = this.props;

		return <Keywords keywords={keywords ? keywords : []} onKeywordsChange={this.onKeywordsChange} t={t} />;
	}
}
