import { MATCH_LINEUPS_CREATE } from '../action-creators/match-lineups-action-creator';
import MatchDetailsModel from '../../views/Resources/match-lineups/models/match/match-details.model';
import TournamentModel from '../../views/Partials/Blocky/blocks/widgets/player-profile/models/tournament-model/tournament.model';
import StageModel from '../../views/Partials/Blocky/blocks/widgets/fixtures/models/stage/stage.model';
import RoundModel from '../../views/Partials/Blocky/blocks/widgets/fixtures/models/round/round.model';
import MatchSeasonModel from '../../views/Resources/match-lineups/models/match/match-season.model';

export type MatchesFilters = {
	tournament: TournamentModel | null;
	season: MatchSeasonModel | null;
	stage: StageModel | null;
	round: RoundModel | null;
};

interface InitialState {
	match: MatchDetailsModel | null;
}
const initialState: InitialState = {
	match: null,
};

function matchLineupsCreateReducer(state: any = initialState, action: any) {
	if (action.type === MATCH_LINEUPS_CREATE) {
		return Object.assign({}, state, {
			match: action.payload,
		});
	}

	return state;
}

export default matchLineupsCreateReducer;
