import React, { useEffect, useState } from 'react';
import CategoryPermissionsService from './category-permissions-service/category-permissions.service';
import { Table } from 'reactstrap';
import { DebounceInput } from 'react-debounce-input';

type Properties = {
	t: any;
	onAdminSelect: (admin: any) => void;
	permissionsService: CategoryPermissionsService;
};

const UsersListCategoryPermissions: React.FunctionComponent<Properties> = ({ t, onAdminSelect, permissionsService }) => {
	const [admins, setAdmins] = useState([]);
	const [filteredAdmins, setFilteredAdmins] = useState([]);
	const [selectedAdmin, setSelectedAdmin] = useState(null);

	useEffect(() => {
		permissionsService.getAllAdmins().then((admins: any) => {
			setAdmins(admins);
			setFilteredAdmins(admins);
		});
	}, [admins.length]);

	return (
		<div className='permissions-col-container'>
			<div className='header-category-perms'>
				<div className='title'>{t('category_permissions_users')}</div>
				<div>
					<DebounceInput
						type='text'
						id='category_permissions_user_search'
						placeholder={t('category_permissions_search_users')}
						className='form-control'
						debounceTimeout={300}
						onChange={(event) => {
							const filteredAdminsByName = permissionsService.filterAdminsByName(event.target.value, admins);
							setFilteredAdmins(filteredAdminsByName);
						}}
					/>
				</div>
			</div>

			<div className='users-container'>
				<Table>
					<tbody>
						{filteredAdmins.map((admin: any) => {
							return (
								<tr
									key={admin.id}
									className={selectedAdmin !== null && selectedAdmin.id === admin.id ? 'selected' : ''}
									onClick={() => {
										setSelectedAdmin(admin);
										onAdminSelect(admin);
									}}
								>
									<td className=''>{admin.name}</td>
								</tr>
							);
						})}
					</tbody>
				</Table>
			</div>
		</div>
	);
};

export default UsersListCategoryPermissions;
