import * as React from 'react';
import { FunctionComponent } from 'react';
import { FormGroup } from 'reactstrap';
import Select from 'react-select';
import * as helpers from '../../Shared/react-select-helpers/react-select.helpers';
import EnumItem from '../../../../models/enum/EnumItem';

type OrderTypeProps = {
	t: any;
	onChange: (selection: any) => void;
	value: any;
};

export enum OrderTypes {
	ordered = 'ordered',
	unordered = 'unordered',
}

export const OrderTypeSelect: FunctionComponent<OrderTypeProps> = ({ t, onChange, value }) => {
	const options = Object.keys(OrderTypes).map((key: string) => {
		return helpers.itemToOption(EnumItem.builder().withId(OrderTypes[key]).withName(key).build(), false, t, '');
	});
	const onOrderTypeSelect = (selection: any) => onChange(helpers.optionToItem(selection));
	return (
		<FormGroup className='d-flex position-relative'>
			<Select
				options={options}
				inputId='tag-order-type-select'
				className='w-100 mb-4'
				placeholder={t('select')}
				value={
					value
						? helpers.itemToOption(value, false, t, '')
						: helpers.itemToOption(
								{
									id: 'ordered',
									name: 'ordered',
								},
								false,
								t,
								'',
						  )
				}
				onChange={onOrderTypeSelect}
			/>
		</FormGroup>
	);
};
