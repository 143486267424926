import 'reflect-metadata';
import { AnyT, jsonMember, jsonObject } from 'typedjson';
import ActorModel from './actor.model';
import ContentModel from './content.model';

@jsonObject
export default class AuditLogModel {
	@jsonMember(String)
	public action: string = '';
	@jsonMember(ActorModel)
	public actor: ActorModel = new ActorModel();
	@jsonMember(ContentModel)
	public content: ContentModel = new ContentModel();
	@jsonMember(String)
	public project: string = '';
	@jsonMember(AnyT)
	public action_time: any = null;
}
