import React, { FunctionComponent, useState } from 'react';
import { Button, Col, Row } from 'reactstrap';
import ImagoModal from './imago-modal';
import { featuresService } from '../../../../../../../App';
import { FeatureTypes } from '../../../../../../../services/feature-service/features.enum';
import { DATA_QA_ATTRIBUTES } from '../../../constants/data-qa.attributes';
import { uploadImagoImage } from '../../../helpers/requests';
import ImagoImageModel from '../../../../../../../models/v2/imago-image/imago-image';
import { AlreadyUploadedImagoImages } from '../../../constants/general';

type Properties = {
	t: any;
};

const ImagoImageListing: FunctionComponent<Properties> = ({ t }) => {
	const imagoEnabled = featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.IMAGO_INTEGRATION);
	const [alreadyUploadedImagoImages, setAlreadyUploadedImagoImages] = useState<AlreadyUploadedImagoImages>([]);
	const [isImagoCollapseOpen, setIsImagoCollapseOpen] = useState(false);

	if (!imagoEnabled) {
		return null;
	}

	const onImagoSelectImage = (image: ImagoImageModel) =>
		uploadImagoImage(t, image, null, alreadyUploadedImagoImages, setAlreadyUploadedImagoImages, setIsImagoCollapseOpen);

	return (
		<Row>
			<Col>
				<Button
					className='m-0 p-0'
					color='link'
					onClick={() => setIsImagoCollapseOpen(!isImagoCollapseOpen)}
					id={DATA_QA_ATTRIBUTES.TOGGLE_IMAGO_IMAGES}
				>
					{t('toggle_imago')}
				</Button>
				<ImagoModal t={t} onSelect={onImagoSelectImage} isOpen={isImagoCollapseOpen} toggleModal={setIsImagoCollapseOpen} />
			</Col>
		</Row>
	);
};

export default ImagoImageListing;
