import PlayerManualDataModel from '../../../../../models/player-manual-data/player-manual-data.model';
import LineupModel from '../../models/lineup/lineup.model';
import LineupPlayerModel from '../../models/player-lineup/lineup-player.model';
import TeamLineupModel from '../../models/team-lineup/team-lineup.model';
import EnumItem from '../../../../../models/enum/EnumItem';
import MatchModel from '../../models/match/match.model';
import MatchDetailsModel from '../../models/match/match-details.model';
import MatchScoreModel from '../../models/match/match-score.model';
import MatchSeasonModel from '../../models/match/match-season.model';
import TournamentModel from '../../models/match/match-season-tournament.model';
import TeamModel from '../../../../Partials/Blocky/partials/team-select/models/team.model';
import { filterPlayers } from './lists-update.helper';
import RoundModel from '../../../../Partials/Blocky/blocks/widgets/fixtures/models/round/round.model';
import Country from '../../../../../models/country/country.model';

export type PlayersType = {
	DEFENCE: string;
	DOUBTFUL: string;
	FORWARD: string;
	GOALKEEPER: string;
	INJURED: string;
	MIDFIELD: string;
	NATIONAL_TEAM_DUTY: string;
	SECOND_COACH: string;
	STARTER: string;
	SUBSTITUTE_PLAYER: string;
	SUSPENDED: string;
	UNAVAILABLE: string;
};

export enum LineupStatus {
	UNCONFIRMED = 'UNCONFIRMED',
	CONFIRMED = 'CONFIRMED',
	// In some cases, lineup status could be NOT_AVAILABLE, and we need to set it to UNCONFIRMED initially.
	// Otherwise, the lineup could not be saved with the the new changes.
	NOT_AVAILABLE = 'UNCONFIRMED',
}

export type Formation = { formation: string | null; positions: LineupPlayerModel[] };

export const remapMatchLineupFromResponse = (lineup: any, match: MatchModel) => {
	if (lineup && lineup.match_id && match) {
		return LineupModel.builder()
			.withId(lineup.match_id)
			.withHomeTeam(remapTeamLineup(lineup.home_team, match.homeTeam))
			.withAwayTeam(remapTeamLineup(lineup.away_team, match.awayTeam))
			.withStatus(LineupStatus[lineup.status])
			.build();
	}
	return {} as LineupModel;
};

export const remapTeamLineup = (team: any, matchTeam: TeamModel | null) => {
	const players = team && team.players && team.players.length > 0 ? team.players : [];
	const formation = team && team.formation ? team.formation : null;

	if (team && team.team_id) {
		return TeamLineupModel.builder()
			.withId(team.team_id)
			.withCoach(team.coach)
			.withPlayers(remapPlayersLineup(players))
			.withFormation(team.formation)
			.build();
	}
	// If home team or away team are not defined
	if (matchTeam && (!team || team.team_id)) {
		return TeamLineupModel.builder().withId(matchTeam.id).withPlayers(players).withFormation(formation).build();
	}
	return {} as TeamLineupModel;
};

export const remapPlayersLineup = (players: any[]) => {
	if (players && players.length > 0) {
		return players.map((player: any) => remapPlayerLineup(player));
	}
	return [];
};

export const remapPlayerLineup = (player: any) =>
	LineupPlayerModel.builder()
		.withPlayer(remapPlayerProfileLineup(player.player))
		.withPositionX(player.position_x ? player.position_x : null)
		.withPositionY(player.position_y ? player.position_y : null)
		.withShirtNumber(player.shirt_number ? player.shirt_number : null)
		.withTypeId(player.type ? player.type.id : null)
		.build();

export const remapPlayerProfileLineup = (player: any) => {
	if (player && player.id) {
		return PlayerManualDataModel.builder()
			.withId(player.id)
			.withName(player.name)
			.withActive(player.active)
			.withBirthCity(player.birth_city)
			.withBirthdate(player.birthdate)
			.withProfile(player.profile)
			.withSocial(player.social)
			.withCountry(player.country)
			.withPosition(player.position)
			.withAssets(player.assets)
			.build();
	}

	return PlayerManualDataModel.builder().build();
};

// playerTypes GOALKEEPER, DEFENCE, MIDFIELD, FORWARD are the starting eleven players.
export const startingElevenTypes = (types: PlayersType) => [types.GOALKEEPER, types.DEFENCE, types.MIDFIELD, types.FORWARD];

// playerTypes GOALKEEPER, DEFENCE, MIDFIELD, FORWARD, SUBSTITUTE_PLAYER includes the starting eleven players and the substitutes.
export const lineupsTypes = (types: PlayersType) => [
	types.GOALKEEPER,
	types.DEFENCE,
	types.MIDFIELD,
	types.FORWARD,
	types.SUBSTITUTE_PLAYER,
];

// Filter players with typeId included in the playersTypes array.
export const filterPlayersByMultipleTypes = (players: LineupPlayerModel[], playersTypes: string[]) => {
	if (players && players.length > 0) {
		return players.filter((player: LineupPlayerModel) => playersTypes.includes(player.typeId ? player.typeId : ''));
	}
	return [];
};

// Filter the players by passed typeId (GOALKEEPER/DEFENCE/MIDFIELD/FORWARD/...).
export const filterPlayersByType = (lineups: LineupPlayerModel[], type: string) => {
	if (lineups && lineups.length > 0) {
		return lineups.filter((player: LineupPlayerModel) => player.typeId === type);
	}
	return [];
};

export const extractTeamLineupPlayers = (players: LineupPlayerModel[]) => {
	if (players && players.length > 0) {
		return players.map((player: LineupPlayerModel) => player.player.id);
	}
	return [];
};

export const filterTeamLineupFromSquad = (squad: LineupPlayerModel[], lineups: LineupPlayerModel[], playersTypes: string[]) => {
	if (lineups && lineups.length > 0 && squad && squad.length > 0) {
		// Extract only starting eleven and substitutes player.
		const teamLineupPlayersType = filterPlayersByMultipleTypes(lineups, playersTypes);
		// Map the extracted players ids.
		const lineupPlayers = extractTeamLineupPlayers(teamLineupPlayersType);
		// Filter the squad from the extracted players.
		return squad.filter((player: LineupPlayerModel) => !lineupPlayers.includes(player.player.id));
	}
	if ((!lineups || lineups.length === 0) && squad && squad.length > 0) {
		return squad;
	}
	return [];
};

export const remapFormationsFromResponse = (data: any[]) => {
	if (data && data.length > 0) {
		return data.map((formation: any) => {
			return { ...formation, positions: remapPlayerFormationFromResponse(formation.positions) };
		});
	}
	return [];
};

export const remapPlayerFormationFromResponse = (players: any[]) => {
	if (players && players.length > 0) {
		return players.map((player: any) =>
			LineupPlayerModel.builder()
				.withPlayer(remapPlayerProfileLineup(player.player))
				.withPositionX(player.position_x ? player.position_x : null)
				.withPositionY(player.position_y ? player.position_y : null)
				.withShirtNumber(player.shirt_number ? player.shirt_number : null)
				.withTypeId(`${player.type_id}`)
				.build(),
		);
	}
	return [];
};

export const remapTeamFromResponce = (team: any) => {
	if (team && team.id) {
		return TeamModel.builder().withId(team.id).withLogo(team.url_logo).withName(team.name).withType(team.type).build();
	}
	return null;
};

export const remapTeamDetailFromResponce = (team: any) => {
	if (team && team.id) {
		const logo = team.assets && team.assets.logo && team.assets.logo.url ? team.assets.logo.url : '';

		return TeamModel.builder().withId(team.id).withLogo(logo).withName(team.name).withType(team.type).build();
	}
	return null;
};

export const remapEnumItem = (item: any) => {
	if (item && item.id) {
		return EnumItem.builder().withId(item.id).withName(item.name).build();
	}
	return null;
};

export const remapCountry = (country: any) => {
	if (country && country.id) {
		return Country.builder()
			.withName(country.name)
			.withId(country.id)
			.withFlag(country.assets && country.assets.flag ? country.assets.flag.url : '')
			.build();
	}
	return null;
};

export const remapPlayersTypes = (response: any) => {
	if (response && response.length > 0) {
		const playersTypes = response.map((item: any) => ({ [item.code.toUpperCase()]: `${item.id}` }));
		// Return object with keys the players type code and values the players type id (ex. { GOALKEEPER: '3', DEFENCE: '4', ... })
		return Object.assign({}, ...playersTypes);
	}

	return {};
};

export const remapMatchDetailsFromResponse = (match: any) => {
	if (match && match.id) {
		return MatchDetailsModel.builder()
			.withId(match.id)
			.withType(match.status.type)
			.withMinutes(match.minute)
			.withKikoffTime(match.kickoff_time)
			.withGroup(remapEnumItem(match.group))
			.withEventStatus(match.status)
			.withHomeTeam(remapTeamDetailFromResponce(match.home_team))
			.withAwayTeam(remapTeamDetailFromResponce(match.away_team))
			.withStartTime(match.phase_started_at)
			.withReferees(remapEnumItems(match.referees))
			.withSeason(remapMatchSeasonFromResponse(match.season))
			.withFinishedAt(match.finished_at)
			.withRound(remapRoundFromResponse(match.round))
			.withScore(remapMatchScore(match.score))
			.withSpectators(match.spectators)
			.withVenue(remapEnumItem(match.venue))
			.withStage(remapEnumItem(match.stage))
			.withCoverage(match.coverage === 'UNKNOWN' ? 'NOT_LIVE' : match.coverage)
			.build();
	}
	return {} as MatchDetailsModel;
};

export const remapMatchScore = (score: any) => {
	if (score) {
		return MatchScoreModel.builder()
			.withTotal(score.total)
			.withHalfTime(score.half_time)
			.withRegularTime(score.regular_time)
			.withExtraTime(score.extra_time)
			.withPenaltyShootout(score.penalty_shootout)
			.withAggregate(score.aggregate)
			.build();
	}
	return null;
};

export const remapEnumItems = (items: any[]) => {
	if (items && items.length > 0) {
		return items.map((item: any) => remapEnumItem(item)) as EnumItem[];
	}
	return null;
};

export const remapMatchSeasonFromResponse = (season: any) => {
	if (season && season.id) {
		return MatchSeasonModel.builder()
			.withId(season.id)
			.withStatus(season.status)
			.withName(season.name)
			.withTournament(remapTournamentFromResponse(season.tournament))
			.build();
	}
	return null;
};

export const remapTournamentFromResponse = (tournament: any) => {
	if (tournament && tournament.id) {
		const logo = tournament.assets && tournament.assets.logo && tournament.assets.logo.url ? tournament.assets.logo.url : '';

		return TournamentModel.builder()
			.withId(tournament.id)
			.withLogo(logo)
			.withCountry(remapCountry(tournament.country))
			.withName(tournament.name)
			.build();
	}
	return null;
};

export const remapMatchFromResponse = (match: any) => {
	if (match && match.id) {
		return MatchModel.builder()
			.withId(match.id)
			.withType(match.event_status.type)
			.withMinutes(match.minute)
			.withEventStatus(match.event_status)
			.withGoalHome(match.goal_home)
			.withGoalAway(match.goal_away)
			.withHomeTeam(remapTeamFromResponce(match.home_team))
			.withAwayTeam(remapTeamFromResponce(match.away_team))
			.withStartTime(match.start_time)
			.withFinishedAt(match.finished_at)
			.withUpdatedAt(match.updated_at)
			.withLiveUpdates(match.live_updates)
			.withLineupAvailable(match.lineup_available)
			.withRound(match.round)
			.withVenue(remapEnumItem(match.venue))
			.withReferee(remapEnumItem(match.referee))
			.withStage(remapEnumItem(match.tournament_season_stage))
			.build();
	}
	return {} as MatchModel;
};

export const remapRoundFromResponse = (round: any) => {
	if (round && round.key) {
		return RoundModel.builder().withRound(round.key).build();
	}
	return null;
};

export const remapMatchesFromResponse = (matches: any[]) => {
	if (matches && matches.length > 0) {
		return matches.map((match: any) => remapMatchFromResponse(match));
	}
	return [];
};

export const removePostponedMatches = (matches: any[]) => {
	if (matches && matches.length > 0) {
		return remapMatchesFromResponse(matches.filter((match: any) => match.event_status.code !== 'postponed'));
	}
	return [];
};

export const remapTeamLineupToRequest = (team: TeamLineupModel, formation: Formation, substitutes: LineupPlayerModel[]) => {
	// Filtering the empty player placeholders positions from the team lineup players and adding the substitutes.
	const teamPlayers = [...formation.positions, ...substitutes].filter(filterPlayers(undefined));

	return TeamLineupModel.builder()
		.withId(team.teamId)
		.withCoach(team.coach)
		.withPlayers(teamPlayers)
		.withFormation(formation.formation)
		.build();
};
