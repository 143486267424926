import TeamComparisonModel from './team-comparison.model';
import { TeamComparisonJson } from './team-comparison.json';
import TeamModel from '../../../../partials/team-select/models/team.model';

export default class TeamComparisonBuilder {
	readonly json: TeamComparisonJson;

	constructor(teamComparison?: TeamComparisonModel | TeamComparisonJson) {
		if (teamComparison && teamComparison instanceof TeamComparisonModel) {
			this.json = teamComparison.toJson();
		} else if (teamComparison) {
			this.json = teamComparison;
		} else {
			this.json = {} as TeamComparisonJson;
		}
	}

	withTeamOne(team1: TeamModel): TeamComparisonBuilder {
		this.json.team1 = team1;

		return this;
	}

	withTeamTwo(team2: TeamModel): TeamComparisonBuilder {
		this.json.team2 = team2;

		return this;
	}

	withStatFields(statFields: string[]): TeamComparisonBuilder {
		this.json.statFields = statFields;

		return this;
	}

	withDisplayOdds(displayOdds: boolean): TeamComparisonBuilder {
		this.json.displayOdds = displayOdds;

		return this;
	}

	withThreeLetterCodeType(threeLetterCodeType: any): TeamComparisonBuilder {
		this.json.threeLetterCodeType = threeLetterCodeType;

		return this;
	}

	build(): TeamComparisonModel {
		return TeamComparisonModel.fromJson(this.json);
	}
}
