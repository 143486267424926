import React from 'react';
import { Col, FormGroup, Row, Input, Label, FormFeedback } from 'reactstrap';
import { blockWrapperService, blockManagementService } from '../../../../subcomponents/blocky.component';
import BlockValidation from '../../../../helpers/block-validation.helper';
import BlockModel from '../../../../models/block.model';
import { PLAYERS_CARDS_V2 } from '../../../../constants/most-decorated-players.constants';
import FootballMostDecoratedPlayersModel from '../models/football-most-decorated-players.model';
import TournamentModel from '../../../../../../../models/v2/Tournament/Entity/TournamentModel';
import SeasonModel from '../../../../../../../models/v2/Season/Entity/SeasonModel';
import PlayerModel from '../../../../../../../models/v2/player/entity/player.model';
import { SportTypes } from '../../../../../../../constants/sport.types';
import { SPORT_SELECT_ATTRIBUTES } from '../../helpers/component-attributes.constants';
import TournamentSelectComponent from '../../../partials/tournament-select/tournament-select.component';
import ErrorHandler from '../../../partials/error-handler/error-handler';
import SeasonSelectComponent from '../../../partials/season-select/season-select.component';
import { FootballMostDecoratedPlayersSelectContainer } from '../../../partials/most-decorated-players-select/football-most-decorated-player-select-container.component';
import { createBlock } from '../../../partials/helpers/blocky-sidebar-communication/blocky-sidebar-communication.helper';
import { WidgetsV2Ids } from '../../../../constants/block.types';
import { ColumnSelect } from '../../../partials/columns-select/columns-select';
import RefreshTimeWrapper from '../../../partials/refresh-time-input/refresh-time-wrapper';
import { onRefreshTimeChangeFromMainComponent } from '../../../partials/refresh-time-input/helper';
import { SelectMenuOptionType } from '../../../../../../../models/v2/general/select.model';

export type Properties = {
	block: BlockModel;
	contentData: any;
	t: any;
};

export type State = {
	widgetModel: FootballMostDecoratedPlayersModel;
	isValid: boolean;
};

export default class FootballMostDecoratedPlayersEditComponent extends React.Component<Properties, State> {
	constructor(props: Properties) {
		super(props);
		this.state = {
			widgetModel: createBlock(
				WidgetsV2Ids.widgetFootballMostDecoratedPlayers,
				props.block.data.preview && props.block.data.preview.widgetModel,
				props.contentData,
			),
			isValid: true,
		};
	}

	componentDidMount(): void {
		blockWrapperService.registerOnSaveFunc({ blockId: this.props.block.id, func: this.onSave });
	}

	onSave = (index: number) => {
		const validation = new BlockValidation();
		const data = { widgetModel: this.state.widgetModel };
		const isValid = validation.validate(this.props.block, data) === true;

		this.setState({ ...this.state, isValid });

		const mostDecoratedPlayersModel = FootballMostDecoratedPlayersModel.builder(this.state.widgetModel).build();

		if (isValid) {
			const block = Object.assign({}, this.props.block);
			block.data.preview = { widgetModel: mostDecoratedPlayersModel };
			blockManagementService.blockUpdate(block, index);
		} else {
			return isValid;
		}
	};

	componentWillUnmount(): void {
		blockWrapperService.unregisterOnSaveFunc(this.props.block.id);
	}

	updateWidgetModelState = (model: FootballMostDecoratedPlayersModel) => {
		this.setState({ ...this.state, widgetModel: model });
	};

	onTournamentSelect = (tournament: TournamentModel) => {
		this.updateWidgetModelState(
			FootballMostDecoratedPlayersModel.builder(this.state.widgetModel)
				.withTournament(tournament)
				.withSeason({} as SeasonModel)
				.withHighlightPlayers([])
				.withPlayer({} as PlayerModel)
				.withOffset('')
				.withPlayerOffset('')
				.withStartFrom('')
				.build(),
		);
	};

	onSeasonSelect = (season: SeasonModel) => {
		this.updateWidgetModelState(
			FootballMostDecoratedPlayersModel.builder(this.state.widgetModel)
				.withSeason(season)
				.withHighlightPlayers([])
				.withPlayer({} as PlayerModel)
				.withOffset('')
				.withPlayerOffset('')
				.withStartFrom('')
				.build(),
		);
	};

	onLimitChange = (limit: string) => {
		this.updateWidgetModelState(FootballMostDecoratedPlayersModel.builder(this.state.widgetModel).withLimit(limit).build());
	};

	onStartPositionChange = (startFrom: string) => {
		this.updateWidgetModelState(FootballMostDecoratedPlayersModel.builder(this.state.widgetModel).withStartFrom(startFrom).build());
	};

	onHighlightPlayersOptions = (players: PlayerModel[]) => {
		this.updateWidgetModelState(FootballMostDecoratedPlayersModel.builder(this.state.widgetModel).withHighlightPlayers(players).build());
	};

	onColumnsOptions = (columns: string[]) => {
		this.updateWidgetModelState(FootballMostDecoratedPlayersModel.builder(this.state.widgetModel).withColumns(columns).build());
	};

	onPlayersChange = (players: PlayerModel[]) => {
		this.updateWidgetModelState(FootballMostDecoratedPlayersModel.builder(this.state.widgetModel).withPlayers(players).build());
	};

	onRefreshTimeChange = (refreshTimeData: SelectMenuOptionType) => {
		onRefreshTimeChangeFromMainComponent(
			refreshTimeData,
			FootballMostDecoratedPlayersModel,
			this.state.widgetModel,
			this.updateWidgetModelState,
		);
	};

	render() {
		const { widgetModel, isValid } = this.state;
		const placeholder =
			widgetModel.players && widgetModel.players.length
				? `${this.props.t('from')} 1 ${this.props.t('to')} ${widgetModel.players.length}`
				: `1, 4, 7, ${this.props.t('etc')}`;

		return (
			<>
				<Row>
					<Col>
						<FormGroup>
							<TournamentSelectComponent
								isMulti={false}
								isBlocky
								isRequired
								isValid={widgetModel.tournament && widgetModel.tournament.id}
								selectedTournament={widgetModel.tournament}
								onTournamentSelect={this.onTournamentSelect}
								t={this.props.t}
								id={`${SportTypes.FOOTBALL}-${SPORT_SELECT_ATTRIBUTES.TOURNAMENT_SELECT}`}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					<Col>
						<FormGroup>
							<SeasonSelectComponent
								withLabel
								isValid={isValid}
								isBlocky
								selectedSeason={widgetModel.season}
								isClearable={widgetModel.season && !widgetModel.season.id}
								tournamentId={widgetModel.tournament && widgetModel.tournament.id ? widgetModel.tournament.id : ''}
								onSeasonSelect={this.onSeasonSelect}
								t={this.props.t}
								preselectSeason
								id={`${SportTypes.FOOTBALL}-${SPORT_SELECT_ATTRIBUTES.SEASON_SELECT}`}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					<Col>
						<FormGroup>
							<ColumnSelect
								value={this.state.widgetModel.columns}
								onColumnsSelect={this.onColumnsOptions}
								t={this.props.t}
								isMulti={true}
								columns={PLAYERS_CARDS_V2}
							/>
						</FormGroup>
					</Col>
				</Row>
				{widgetModel.season && widgetModel.season.id && (
					<Row>
						<Col>
							<FormGroup>
								<FootballMostDecoratedPlayersSelectContainer
									selectedHighlightedPlayers={widgetModel.highlightPlayers}
									selectedSeason={widgetModel.season}
									onPlayersSelect={this.onHighlightPlayersOptions}
									setPlayers={this.onPlayersChange}
									t={this.props.t}
									isMulti={true}
									player={widgetModel.player}
									offset={widgetModel.offset}
									id={`${SportTypes.FOOTBALL}-${SPORT_SELECT_ATTRIBUTES.MOST_DECORATED_PLAYERS_SELECT}`}
								/>
							</FormGroup>
						</Col>
					</Row>
				)}
				<div className='mb-3'>
					<ErrorHandler
						arePropsValid={isValid}
						isValueSelected={widgetModel.season && !widgetModel.season.id}
						t={this.props.t}
						errorMessage='no_season_selected'
					/>
				</div>
				<Row className='mb-1'>
					<Col>
						<hr />
						<h6>{this.props.t('preview_details')}</h6>
					</Col>
				</Row>
				<Row>
					<Col>
						{/*{//TODO Create reusable StartFromPosition component}*/}
						<FormGroup>
							<Label>{this.props.t('start_from_position')}:</Label>
							<Input
								type='number'
								disabled={
									(widgetModel.player && !!widgetModel.player.id) ||
									!!widgetModel.playerOffset ||
									(widgetModel && widgetModel.players && widgetModel.players.length === 0)
								}
								className='form-control'
								value={widgetModel.startFrom ? widgetModel.startFrom : ''}
								min={1}
								placeholder={placeholder}
								max={widgetModel.players && widgetModel.players.length}
								onChange={(event: any) => this.onStartPositionChange(event.target.value)}
								invalid={
									widgetModel &&
									widgetModel.players &&
									(widgetModel.players.length < +widgetModel.startFrom || (widgetModel.startFrom !== '' && +widgetModel.startFrom <= 0))
								}
							/>
							{widgetModel && widgetModel.players ? (
								<FormFeedback>
									{widgetModel.players.length < +widgetModel.startFrom
										? `${this.props.t('must_be_less_than', { playersLength: widgetModel.players.length + 1 })}`
										: ''}
									{widgetModel.startFrom !== '' && +widgetModel.startFrom <= 0 ? `${this.props.t('must_be_greater_than', { length: '0' })}` : ''}
								</FormFeedback>
							) : null}
						</FormGroup>
					</Col>
					<Col>
						<FormGroup>
							<Label>{this.props.t('show')}:</Label>
							<Input
								type='number'
								disabled={(widgetModel.player && !!widgetModel.player.id) || !!widgetModel.playerOffset}
								className='form-control'
								value={widgetModel.limit ? widgetModel.limit : ''}
								onChange={(event: any) => this.onLimitChange(event.target.value)}
								placeholder={this.props.t('players_in_most_decorated_players')}
							/>
						</FormGroup>
					</Col>
				</Row>
				<RefreshTimeWrapper value={this.state.widgetModel.refreshTime} onRefreshTimeChange={this.onRefreshTimeChange} />
			</>
		);
	}
}
