export enum ReduxRelatedProperties {
	articleRelated = 'articleRelated',
	articleEditRelated = 'articleEditRelated',
	videoRelated = 'videoRelated',
	videoEditRelated = 'videoEditRelated',
	galleryRelated = 'galleryRelated',
	galleryEditRelated = 'galleryEditRelated',
	wikiRelated = 'wikiRelated',
	wikiEditRelated = 'wikiEditRelated',
}
