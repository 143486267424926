import { LivescoreWidgetJson } from './livescore-widget.json';
import LivescoreWidgetBuilder from './livescore-widget.builder';
import SportOddsBookmakerModel from '../../../../../../../models/sport-odds/sport-odds-bookmaker.model';
import { RefreshTimeValuesType } from '../../../../v2/widgets-v2/refresh-time-input/helper';

export default class LivescoreWidgetModel {
	readonly displayOdds: boolean = false;
	readonly date: string = '';
	readonly bookmakers: SportOddsBookmakerModel[] | null;
	readonly refreshTime: RefreshTimeValuesType;

	private constructor(displayOdds: boolean, date: string, bookmakers: SportOddsBookmakerModel[] | null, refreshTime: RefreshTimeValuesType) {
		this.displayOdds = displayOdds;
		this.date = date;
		this.bookmakers = bookmakers;
		this.refreshTime = refreshTime;
	}

	toJson(): LivescoreWidgetJson {
		return {
			displayOdds: this.displayOdds,
			date: this.date,
			bookmakers: this.bookmakers,
			refreshTime: this.refreshTime,
		};
	}

	static fromJson(json: LivescoreWidgetJson): LivescoreWidgetModel {
		return new LivescoreWidgetModel(json.displayOdds, json.date, json.bookmakers, json.refreshTime);
	}

	static builder(livescoreWidget?: LivescoreWidgetModel): LivescoreWidgetBuilder {
		return new LivescoreWidgetBuilder(livescoreWidget);
	}
}
