import { PollJson } from './poll.json.js';
import PollBuilder from './poll.builder';
import PollOption from './option/poll-option';
import MainMedia from '../mainmedia/MainMedia.js';

export default class Poll {
	readonly id: string;
	readonly title: string;
	readonly text: string;
	readonly entity_type: string;
	readonly generic: string;
	readonly options: PollOption[];
	readonly status: string;
	readonly updated_at: string;
	readonly created_at: string;
	readonly comments: any;
	readonly mainMedia: MainMedia[] = [];

	private constructor(
		id: string,
		title: string,
		text: string,
		entity_type: string,
		generic: string,
		options: any[],
		status: string,
		updated_at: string,
		created_at: string,
		comments: any,
		mainMedia: MainMedia[],
	) {
		this.id = id;
		this.title = title;
		this.text = text;
		this.entity_type = entity_type;
		this.generic = generic;
		this.options = options;
		this.status = status;
		this.updated_at = updated_at;
		this.created_at = created_at;
		this.comments = comments;
		this.mainMedia = mainMedia;
	}

	toJson(): PollJson {
		return {
			id: this.id,
			title: this.title,
			text: this.text,
			entity_type: this.entity_type,
			generic: this.generic,
			options: this.options,
			status: this.status,
			updated_at: this.updated_at,
			created_at: this.created_at,
			comments: this.comments,
			main_media: this.mainMedia,
		};
	}

	static fromJson(json: PollJson): Poll {
		return new Poll(
			json.id,
			json.title,
			json.text,
			json.entity_type,
			json.generic,
			json.options,
			json.status,
			json.updated_at,
			json.created_at,
			json.comments,
			json.main_media,
		);
	}

	static builder(poll?: Poll): PollBuilder {
		return new PollBuilder(poll);
	}
}
