import TagBuilder from './TagBuilder';
import { TagJson } from './TagJson';
import Seo from '../seo/Seo';
import Urls from '../urls/Urls';
import MainMedia from '../mainmedia/MainMedia';
import EnumItem from '../enum/EnumItem';

export default class Tag {
	readonly id: string;
	readonly title: string;
	readonly description: string;
	readonly createdAt: string;
	readonly updatedAt: string;
	readonly created_by: any;
	readonly seo: Seo;
	readonly urls: Urls;
	readonly mainMedia: MainMedia[] = [];
	readonly weight: number;
	readonly type: string;
	readonly orderType: EnumItem;

	private constructor(
		id: string,
		title: string,
		description: string,
		seo: Seo,
		urls: Urls,
		createdAt: string,
		updatedAt: string,
		created_by: string,
		mainMedia: MainMedia[],
		weight: number,
		type: string,
		orderType: EnumItem,
	) {
		this.id = id;
		this.title = title;
		this.description = description;
		this.seo = seo;
		this.urls = urls;
		this.createdAt = createdAt;
		this.updatedAt = updatedAt;
		this.created_by = created_by;
		this.mainMedia = mainMedia;
		this.weight = weight;
		this.type = type;
		this.orderType = orderType;
	}

	toJSON(): TagJson {
		return {
			id: this.id,
			title: this.title,
			description: this.description,
			seo: this.seo,
			urls: this.urls,
			createdAt: this.createdAt,
			updatedAt: this.updatedAt,
			created_by: this.created_by,
			mainMedia: this.mainMedia,
			weight: this.weight,
			type: this.type,
			orderType: this.orderType,
		};
	}

	static fromJSON(json: TagJson): Tag {
		return new Tag(
			json.id,
			json.title,
			json.description,
			json.seo,
			json.urls,
			json.createdAt,
			json.updatedAt,
			json.created_by,
			json.mainMedia,
			json.weight,
			json.type,
			json.orderType,
		);
	}

	static builder(banner?: Tag): TagBuilder {
		return new TagBuilder(banner);
	}
}
