import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Col, Label, Row } from 'reactstrap';
import HttpService from '../../../../../../services/rest/HttpService';
import ErrorHandler from '../../../partials/error/error-handler-component';
import {
	createMatchesOptions,
	mapResponseToMatchModelWithUpcoming,
	matchToOption,
} from '../helpers/match-select/match-select-component.helper';
import PlayerModel from '../../../../../../models/v2/player/entity/player.model';
import TeamModel from '../../../../../../models/v2/Team/Entity/TeamModel';
import MatchModel from '../../../../../../models/v2/match/entity/match.model';

type Properties = {
	onMatchSelect: any;
	selectedMatch: MatchModel | any;
	t: any;
	isValid: boolean;
	isMulti?: boolean;
	isClearable?: boolean;
	isRequired?: boolean;
	isBlocky: boolean;
	className?: string;
	stageId?: number | null | undefined;
	seasonId: string | null;
	preselectMatch?: boolean;
	player?: PlayerModel;
	teamName: string;
	entityType: string;
	team?: TeamModel;
	id: string;
};

const MatchByStageOrSeasonSelectContainer: React.FunctionComponent<Properties> = (props) => {
	const [matchesOptions, setMatchesOptions] = useState<any[]>([]);

	useEffect(() => {
		setMatchesOptions([]);
		if (props.stageId && props.teamName && props.teamName.length > 0) {
			getStageMatches(props.stageId);
		} else {
			getSeasonMatches(props.seasonId);
		}
	}, [props.stageId]);

	useEffect(() => {
		setMatchesOptions([]);
		if (props.seasonId && props.teamName && props.teamName.length > 0) {
			getSeasonMatches(props.seasonId);
		}
	}, [props.seasonId]);

	const updateMatchesOptionsState = (matches: any[]) => {
		const { onMatchSelect, selectedMatch, isBlocky, isMulti, preselectMatch } = props;

		if (
			((preselectMatch && !selectedMatch) ||
				(selectedMatch && (selectedMatch.id === undefined || selectedMatch.id.length < 1) && !isMulti) ||
				(isMulti && selectedMatch.length === 0)) &&
			isBlocky
		) {
			onMatchSelect(matches[0].data);
		}
		setMatchesOptions(matches);
	};

	const getStageMatches = (stageId: number | null) => {
		if (stageId) {
			HttpService.getMatchesByStageId(stageId)
				.then((response: any) => {
					const modelList = mapResponseToMatchModelWithUpcoming(response.data);
					const options = createMatchesOptions(modelList);
					const matchesWithPlayerTeamOnly = options.filter((match: any) => match.label.includes(`${props.teamName}`));
					updateMatchesOptionsState(matchesWithPlayerTeamOnly);
				})
				.catch((e: any) => e);
		}
	};

	const getSeasonMatches = (seasonId: number | string | null) => {
		if (seasonId) {
			HttpService.getMatchesBySeasonId(seasonId)
				.then((response: any) => {
					const modelList = mapResponseToMatchModelWithUpcoming(response.data);
					const options = createMatchesOptions(modelList);
					const matchesWithEntityTeamOnly = options.filter((match: any) => match.label.includes(`${props.teamName}`));
					updateMatchesOptionsState(matchesWithEntityTeamOnly);
				})
				.catch((e: any) => e);
		}
	};

	const { isRequired, t, className, onMatchSelect, selectedMatch, id, isClearable, isValid } = props;

	return (
		<Row data-qa={id}>
			<Col>
				<Label htmlFor={id}>{t('match')}:</Label>
				{isRequired && <span className='text-danger'>*</span>}
				<Select
					id={id}
					menuPortalTarget={document.body}
					menuPosition='absolute'
					className={className ? className : ''}
					options={matchesOptions}
					value={selectedMatch && selectedMatch.id ? matchToOption(selectedMatch) : []}
					noOptionsMessage={(inputValue) => inputValue && t('no_options')}
					isClearable={isClearable}
					placeholder={t('select')}
					onChange={(selection: any) => {
						selection ? onMatchSelect(selection.data) : onMatchSelect(null);
					}}
				/>

				{isRequired && !isValid && !selectedMatch && <ErrorHandler t={t} errorMessage='field_is_required' />}
			</Col>
		</Row>
	);
};

export default MatchByStageOrSeasonSelectContainer as React.ComponentType<Properties>;
