import { OddsWidgetJson } from './odds-widget.json';
import OddsWidgetBuilder from './odds-widget.builder';
import BookmakerModel from '../../../../partials/bookmaker-select/models/bookmaker.model';
import MatchModel from '../../../../partials/match-select/models/match.model';
import TeamModel from '../../../../partials/team-select/models/team.model';

export default class OddsWidgetModel {
	readonly team: TeamModel;
	readonly match: MatchModel;
	readonly bookmakers: BookmakerModel[] | null;
	readonly mainBookmaker: BookmakerModel | null;
	readonly displayMatchHeader: boolean = true;
	readonly canSelectMarkets: boolean = true;
	readonly market: any = { name: '', value: '' };
	readonly threeLetterCodeType: any = '';

	private constructor(
		team: TeamModel,
		match: MatchModel,
		bookmakers: BookmakerModel[] | null,
		mainBookmaker: BookmakerModel | null,
		displayMatchHeader: boolean,
		canSelectMarkets: boolean,
		market: any,
		threeLetterCodeType: string,
	) {
		this.match = match;
		this.team = team;
		this.bookmakers = bookmakers;
		this.mainBookmaker = mainBookmaker;
		this.displayMatchHeader = displayMatchHeader;
		this.canSelectMarkets = canSelectMarkets;
		this.market = market;
		this.threeLetterCodeType = threeLetterCodeType;
	}

	toJson(): OddsWidgetJson {
		return {
			team: this.team,
			match: this.match,
			bookmakers: this.bookmakers,
			mainBookmaker: this.mainBookmaker,
			displayMatchHeader: this.displayMatchHeader,
			canSelectMarkets: this.canSelectMarkets,
			market: this.market,
			threeLetterCodeType: this.threeLetterCodeType,
		};
	}

	static fromJson(json: OddsWidgetJson): OddsWidgetModel {
		return new OddsWidgetModel(
			json.team,
			json.match,
			json.bookmakers,
			json.mainBookmaker,
			json.displayMatchHeader,
			json.canSelectMarkets,
			json.market,
			json.threeLetterCodeType,
		);
	}

	static builder(odds?: OddsWidgetModel): OddsWidgetBuilder {
		return new OddsWidgetBuilder(odds);
	}
}
