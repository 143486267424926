import BlockConfigModel from './block-config.model';
import { BlockConfigJson } from './block-config.json';

export default class BlockConfigBuilder {
	readonly json: BlockConfigJson;

	constructor(config?: BlockConfigModel | BlockConfigJson) {
		if (config && config instanceof BlockConfigModel) {
			this.json = config.toJson();
		} else if (config) {
			this.json = config;
		} else {
			this.json = {} as BlockConfigJson;
		}
	}

	withId(widgetId: string) {
		this.json.widgetId = widgetId;

		return this;
	}

	withOptions(options: any) {
		this.json.options = options;

		return this;
	}

	build() {
		return BlockConfigModel.fromJson(this.json);
	}
}
