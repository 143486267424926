import { ContentMode, ContentTypes } from '../../../../../constants/content-types';
import { relatedConstants, relatedConstantsTypes } from '../../../../../constants/related.constants';
import Related from '../../../../../models/related/Related';
import MetaModel from '../../../../../models/v2/Meta/MetaModel';
import { responseToModelMeta } from '../../../../../models/v2/Meta/meta-model.mapper';
import PaginationMeta from '../../../../../models/v2/Pagination/PaginationModel';
import { availableContentTypes } from '../../../../../services/content-models-service/ContentModelService';
import {
	updateArticleContentTempRelated,
	updateArticleEditContentTempRelated,
	updateGalleryContentTempRelated,
	updateGalleryEditContentTempRelated,
	updateVideoContentTempRelated,
	updateVideoEditContentTempRelated,
	updateWikiContentTempRelated,
	updateWikiEditContentTempRelated,
} from '../../../../../store/action-creators/RelatedContentTempActionCreator';

export const generateRelatedUpdateActionByContentType = (
	contentType: availableContentTypes | null,
	contentMode: ContentMode,
): Function | null => {
	const isEditMode = contentMode === ContentMode.EDIT;
	switch (contentType) {
		case ContentTypes.ARTICLE:
			return isEditMode ? updateArticleEditContentTempRelated : updateArticleContentTempRelated;
		case ContentTypes.VIDEO:
			return isEditMode ? updateVideoEditContentTempRelated : updateVideoContentTempRelated;
		case ContentTypes.GALLERY:
			return isEditMode ? updateGalleryEditContentTempRelated : updateGalleryContentTempRelated;
		case ContentTypes.WIKI_PAGE:
			return isEditMode ? updateWikiEditContentTempRelated : updateWikiContentTempRelated;
		default:
			return null;
	}
};

export type RelatedRequestPriorityQuery = { type: string; requestQuery: string };
export type SummarizedRelatedDataByType = {
	teamIds: string[];
	playerIds: string[];
	matchIds: string[];
	gameIds: string[];
	tournamentIds: string[];
};

export const decideSuggestionsPriority = ({
	teamIds,
	playerIds,
	matchIds,
	gameIds,
	tournamentIds,
}: SummarizedRelatedDataByType): RelatedRequestPriorityQuery => {
	if (matchIds && matchIds.length > 0) {
		return { type: relatedConstants.types.match, requestQuery: `&matchId=${matchIds[0]}` };
	} else if (gameIds && gameIds.length > 0) {
		return { type: relatedConstants.types.game, requestQuery: `&gameIds=${gameIds[0]}` };
	} else if (teamIds && teamIds.length > 0) {
		return { type: relatedConstants.types.team, requestQuery: `&teamIds=${teamIds.join(',')}` };
	} else if (playerIds && playerIds.length > 0) {
		return { type: relatedConstants.types.player, requestQuery: `&playerIds=${playerIds.join(',')}` };
	} else if (tournamentIds && tournamentIds.length > 0) {
		return { type: relatedConstants.types.tournament, requestQuery: `&tournamentIds=${tournamentIds.join(',')}` };
	}

	return { type: 'none', requestQuery: '' };
};

export const extractRelatedDataByRelatedType = (relatedContent: Related[], searchedRelatedType: relatedConstantsTypes) => {
	let result: string[] = [];

	if (relatedContent && relatedContent.length > 0) {
		result = relatedContent.filter((related: Related) => related.type === searchedRelatedType).map((related: Related) => related.data.id);
	}

	return result;
};

export const generateFilteredRelatedData = (reduxSportConnections: Related[], reduxEventsRelated: Related[]) => {
	return {
		teamIds: extractRelatedDataByRelatedType(reduxSportConnections, relatedConstants.types.team),
		playerIds: extractRelatedDataByRelatedType(reduxSportConnections, relatedConstants.types.player),
		matchIds: extractRelatedDataByRelatedType(reduxEventsRelated, relatedConstants.types.match),
		gameIds: extractRelatedDataByRelatedType(reduxEventsRelated, relatedConstants.types.game),
		tournamentIds: extractRelatedDataByRelatedType(reduxSportConnections, relatedConstants.types.tournament),
	};
};

export const formatResponseAsRelatedResource = (resp: any): Related[] => {
	const relatedResources = resp.data.data || [];
	return relatedResources.map((el: any) => {
		return { type: el.entity_type, provider: relatedConstants.providers.smp, data: el } as Related;
	});
};

export const formatResponseAsMetaResource = (resp: any): PaginationMeta => {
	const metaResources = resp.data.meta;
	const remappedMetaResources = responseToModelMeta(metaResources) || new MetaModel();
	return remappedMetaResources.pagination;
};

export const showPagination = (pagination: PaginationMeta) => {
	return pagination.currentPage >= 1 && pagination.totalPages > 1;
};
