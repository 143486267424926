import React, { FunctionComponent, useEffect, useState } from 'react';
import { Button, Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import Image from '../../../../../../../models/image/Image';
import { DATA_QA_ATTRIBUTES, uploadGettyImage } from '../helpers/gallery-block.helper';
import { featuresService } from '../../../../../../../App';
import { FeatureTypes } from '../../../../../../../services/feature-service/features.enum';
import GettyImageModel from '../../../../../../v2/getty/models/getty-image.model';
import GettyApiService from '../../../../../../v2/getty/helpers/getty-api.service';
import GettyModal from '../../../../../../v2/getty/components/main-media/getty-modal';

type Properties = {
	onGalleryItemsChange: (data: Image[]) => void;
	galleryItems: Image[];
};

const GalleryBlockGettyImageListing: FunctionComponent<Properties> = ({ onGalleryItemsChange, galleryItems }) => {
	const [t] = useTranslation();
	const gettyEnabled = featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.GETTY_IMAGES);
	const gettyInstance = new GettyApiService();

	const [alreadyUploadedGettyImages, setAlreadyUploadedGettyImages] = useState(galleryItems);
	const [isGettyCollapseOpen, setIsGettyCollapseOpen] = useState(false);
	const [selectedItemDownloadSize, setSelectedItemDownloadSize] = useState<string>('');
	const [requestsExecuting, setRequestsExecuting] = useState(false);

	useEffect(() => {
		setSelectedItemDownloadSize('');
	}, [isGettyCollapseOpen]);

	if (!gettyEnabled) {
		return null;
	}

	const onGettySelectImage = (image: GettyImageModel) =>
		uploadGettyImage(
			galleryItems,
			image,
			selectedItemDownloadSize,
			{},
			alreadyUploadedGettyImages,
			setAlreadyUploadedGettyImages,
			onGalleryItemsChange,
			gettyInstance,
			setIsGettyCollapseOpen,
			setRequestsExecuting,
		);

	const onDownLoadSizeSelect = (size: string) => {
		setSelectedItemDownloadSize(size);
	};

	return (
		<Row data-qa={DATA_QA_ATTRIBUTES.GETTY_IMAGES}>
			<Col>
				<Button
					className='m-0 p-0'
					color='link'
					onClick={() => setIsGettyCollapseOpen(!isGettyCollapseOpen)}
					id={DATA_QA_ATTRIBUTES.TOGGLE_GETTY_IMAGES}
				>
					{t('toggle_getty')}
				</Button>
				<GettyModal
					onSelect={onGettySelectImage}
					isOpen={isGettyCollapseOpen}
					toggleModal={setIsGettyCollapseOpen}
					gettyInstance={gettyInstance}
					onDownLoadSizeSelect={onDownLoadSizeSelect}
					selectedItemDownloadSize={selectedItemDownloadSize}
					requestsExecuting={requestsExecuting}
				/>
			</Col>
		</Row>
	);
};

export default GalleryBlockGettyImageListing;
