import React, { FunctionComponent, useEffect, useState } from 'react';
import { ContentTypes, sidebarIcons } from '../../../../../constants/content-types';
import { connect } from 'react-redux';
import LiveBlogListingModel from '../../models/live-blog-listing.model';
import '../../style/live-blog-listing.style.scss';
import Project from '../../../../../models/project/Project';
import { returnObjectForWikiPaginationUpdate } from '../../../../../store/action-creators/WikiActionCreator';
import PaginationMeta from '../../../../../models/pagination/PaginationMeta';
import { returnObjectForArticlePaginationUpdate } from '../../../../../store/action-creators/ArticleActionCreator';
import { returnObjectForGalleryPaginationUpdate } from '../../../../../store/action-creators/GalleryActionCreator';
import { returnObjectForVideoPaginationUpdate } from '../../../../../store/action-creators/VideoActionCreator';
import { returnObjectForImagesPaginationUpdate } from '../../../../../store/action-creators/ImageActionCreator';
import { returnObjectForContentFiltersUpdate } from '../../../../../store/action-creators/content-resources';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import LiveBlogAdvancedFiltersModel from '../../models/live-blog-advanced-filters.model';
import { extractLiveBlogsSearchQueryFromFilters } from './helpers/live-blog-listing.helper';
import { remmapContentFilters } from '../../../../Resources/Articles/Helpers/ArticleHelper';
import { Subscription } from 'rxjs';
import {
	returnObjectForLiveBlogsFiltersUpdate,
	returnObjectForLiveBlogsListingRequest,
	returnObjectForLiveBlogsSearch,
} from '../../../../../store/action-creators/LiveBlogsActionCreator';
import { LiveBlogTableListingContainer } from './live-blog-table-listing.container';
import Profile from '../../../../../models/profile/Profile';
import ConditionalRenderWrapper from '../../../../Partials/BaseComponents/ConditionalRenderWraper/ConditionalRenderWrapper';
import AdvancedFilterModel from '../../../../Partials/Shared/advanced-content-filter/models/advanced-content-filter.model';

type Properties = {
	t: any;
	pagination: any;
	project: Project;
	updateArticlesPagination: (pagination: PaginationMeta) => any;
	updateVideosPagination: (pagination: PaginationMeta) => any;
	updateGalleriesPagination: (pagination: PaginationMeta) => any;
	updateImagesPagination: (pagination: PaginationMeta) => any;
	updateContentFilters: (filters: any) => any;
	updateWikiPagesPagination: (pagination: PaginationMeta) => any;
	liveBlogsFilters: LiveBlogAdvancedFiltersModel;
	liveBlogsFiltersUpdate: (filters: LiveBlogAdvancedFiltersModel) => void;
	liveBlogs: LiveBlogListingModel;
	getLiveBlogs: any;
	searchLiveBlogs: any;
	profile: Profile;
	errors: any;
};

const LiveBlogListing: FunctionComponent<Properties> = ({
	t,
	pagination,
	project,
	liveBlogsFilters,
	liveBlogsFiltersUpdate,
	updateContentFilters,
	updateWikiPagesPagination,
	updateArticlesPagination,
	updateGalleriesPagination,
	updateVideosPagination,
	updateImagesPagination,
	liveBlogs,
	getLiveBlogs,
	searchLiveBlogs,
	profile,
	errors,
}) => {
	let actionServiceSubject = new Subscription();
	const permissionName = 'read_liveblogs';
	const universalPermission = '*';

	const [selectedPage, setSelectedPage] = useState<number>(pagination.currentPage || 1);
	const permissionForRead = profile.permissions.findIndex((el) => el.name === permissionName || el.name === universalPermission) >= 0;

	useEffect(() => {
		applyFilters(liveBlogsFilters);
		updateArticlesPagination(PaginationMeta.builder().withCurrentPage(1).build());
		updateVideosPagination(PaginationMeta.builder().withCurrentPage(1).build());
		updateGalleriesPagination(PaginationMeta.builder().withCurrentPage(1).build());
		updateImagesPagination(PaginationMeta.builder().withCurrentPage(1).build());
		updateWikiPagesPagination(PaginationMeta.builder().withCurrentPage(1).build());
		const filters = remmapContentFilters({}, {}, {}, {}, {}, {}, {}, liveBlogsFilters, {}, {} as AdvancedFilterModel);
		updateContentFilters(filters);
		initPageTitle();

		return () => {
			actionServiceSubject.unsubscribe();
		};
	}, [pagination.currentPage]);

	const initPageTitle = () => {
		document.title = t('live_blogs');
	};

	const handlePageClick = (page: any) => {
		setSelectedPage(page);
	};

	const applyFilters = (filters: LiveBlogAdvancedFiltersModel) => {
		const query = extractLiveBlogsSearchQueryFromFilters(filters);
		query.length > 0 && query !== '' ? getLiveBlogs(pagination.currentPage, project, query) : getLiveBlogs(pagination.currentPage, project);
	};

	return (
		<div className='animated fadeIn' data-qa={`${ContentTypes.LIVE_BLOG}-listing`}>
			<div className='card'>
				<div className='card-header d-flex bg-dark'>
					<div className='mr-2 d-flex'>
						<span>
							<i className={`${sidebarIcons.live_blogs} rounded-circle mr-1`} />
							<strong>{t('live_blogs')}</strong>
						</span>
					</div>
				</div>
				<ConditionalRenderWrapper expectedPermissions={['read_liveblogs', 'write_liveblogs', 'delete_liveblogs']}>
					{permissionForRead ? (
						<LiveBlogTableListingContainer
							t={t}
							content={liveBlogs}
							handlePageClick={handlePageClick}
							contentPagination={pagination}
							searchContent={searchLiveBlogs}
							filters={liveBlogsFilters}
							getContent={getLiveBlogs}
							updateContentFilters={liveBlogsFiltersUpdate}
							project={project}
							setCurrentPage={setSelectedPage}
							currentPage={selectedPage}
							profile={profile}
						/>
					) : (
						<div className={'text-center py-5'}>{t('content_read_not_authorized')}</div>
					)}
				</ConditionalRenderWrapper>
			</div>
		</div>
	);
};

function mapStateToProps(state: any) {
	return {
		liveBlogs: state.liveBlogs.liveBlogs,
		project: state.project.currentProject,
		liveBlogsFilters: state.liveBlogs.filters,
		pagination: state.liveBlogs.pagination,
		profile: state.profile.profile,
		errors: state.general.errors,
	};
}

function mapDispatchToProps(dispatch: any) {
	return {
		getLiveBlogs: (page: string, project: Project, text: string) => dispatch(returnObjectForLiveBlogsListingRequest(page, project, text)),
		searchLiveBlogs: (text: string, project: Project) => dispatch(returnObjectForLiveBlogsSearch(text, project)),
		updateArticlesPagination: (pagination: PaginationMeta) => dispatch(returnObjectForArticlePaginationUpdate(pagination)),
		updateGalleriesPagination: (pagination: PaginationMeta) => dispatch(returnObjectForGalleryPaginationUpdate(pagination)),
		updateVideosPagination: (pagination: PaginationMeta) => dispatch(returnObjectForVideoPaginationUpdate(pagination)),
		updateImagesPagination: (pagination: PaginationMeta) => dispatch(returnObjectForImagesPaginationUpdate(pagination)),
		updateWikiPagesPagination: (pagination: PaginationMeta) => dispatch(returnObjectForWikiPaginationUpdate(pagination)),
		updateContentFilters: (filters: any) => dispatch(returnObjectForContentFiltersUpdate(filters)),
		liveBlogsFiltersUpdate: (filters: any) => dispatch(returnObjectForLiveBlogsFiltersUpdate(filters)),
	};
}

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(LiveBlogListing);
