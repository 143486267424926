import React, { useState } from 'react';
import { Card, CardBody, CardHeader, Collapse, Button } from 'reactstrap';
import LineupPlayerModel from '../../models/player-lineup/lineup-player.model';

type Properties = {
	t: any;
	substitutes: LineupPlayerModel[];
	removeSubstitute: (player: LineupPlayerModel) => void;
	addSubstitute: (player: LineupPlayerModel) => void;
};

const SubstitutesList: React.FunctionComponent<Properties> = (props) => {
	const { substitutes, removeSubstitute, addSubstitute, t } = props;
	const [isOpen, setIsOpen] = useState(true);

	const toggle = () => setIsOpen(!isOpen);

	if (substitutes && substitutes.length > 0) {
		return (
			<Card className='animated fadeIn'>
				<CardHeader className='cursor-pointer d-flex align-items-center' onClick={toggle}>
					{t('substitutes')}
					<i className={` ml-auto fa fa-lg fa-angle-${isOpen ? 'down' : 'left'}`} />
				</CardHeader>
				<Collapse isOpen={isOpen}>
					<CardBody className='p-2 d-flex flex-wrap'>
						{substitutes.map((player: LineupPlayerModel, idx: number) => (
							<div key={`substitute-${player.player.id}-${idx}`} id={`substitute-row-${player.player.id}-${idx}`} className='w-50'>
								<div className={`p-1 border border-light rounded my-1 ${idx % 2 === 1 ? '' : 'mr-1'} align-items-center d-flex`}>
									<Button color='light' id={`add-substitute-${player.player.id}`} className='mr-2 py-1 px-2' onClick={() => addSubstitute(player)}>
										<i className='fa fa-plus' />
									</Button>
									{player.player.name}
									<Button
										className='ml-auto py-1 px-2'
										id={`remove-substitute-${player.player.id}`}
										color='danger'
										onClick={() => removeSubstitute(player)}
									>
										<i className='fa fa-trash' />
									</Button>
								</div>
							</div>
						))}
					</CardBody>
				</Collapse>
			</Card>
		);
	}
	return (
		<Card className='animated fadeIn'>
			<CardHeader onClick={toggle} className='cursor-pointer d-flex align-items-center'>
				{t('substitutes')}
				<i className={`ml-auto fa fa-lg fa-angle-${isOpen ? 'down' : 'left'}`} />
			</CardHeader>
			<Collapse isOpen={isOpen}>
				<p className='text-center px-2 py-4'>{t('no_selected_players')}</p>
			</Collapse>
		</Card>
	);
};

export default SubstitutesList;
