import React from 'react';
import Select from 'react-select';
import { Col, Label, Row } from 'reactstrap';
import { Properties, State } from './properties/stage-select.properties';
import HttpService from '../../../../../services/rest/HttpService';
import { contentToOption, contentToOptions } from '../helpers/select.helper';

export default class StageSelectContainer extends React.Component<Properties, State> {
	constructor(props: Properties) {
		super(props);
		this.state = {
			stagesOptions: [],
		};
	}

	componentDidMount() {
		if (this.props.seasonId) {
			this.getStages(this.props.seasonId);
		}
	}

	componentDidUpdate(prevProps: Readonly<Properties>, prevState: Readonly<State>) {
		if (prevProps.seasonId !== this.props.seasonId) {
			this.getStages(this.props.seasonId);
		}
	}

	updateStagesOptionsState(stages: any) {
		const { onStageSelect, selectedStage } = this.props;

		if (!selectedStage || (selectedStage && selectedStage.id === undefined) || (selectedStage && selectedStage.id.length < 1)) {
			if (stages[0]) {
				onStageSelect(stages[0].data);
			}
		}
		this.setState({
			...this.state,
			stagesOptions: stages,
		});
	}

	getStages(seasonId: string) {
		if (seasonId) {
			HttpService.getSeasonById(seasonId)
				.then((response: any) => {
					this.updateStagesOptionsState(contentToOptions(response.data.stages));
				})
				.catch((e: any) => e);
		}
	}

	render() {
		const { stagesOptions } = this.state;
		const { onStageSelect, selectedStage, seasonId, t, withLabel, label, isRequired } = this.props;

		if (!seasonId || seasonId.length < 1) {
			return null;
		}

		return (
			<>
				<Row>
					<Col>
						{withLabel && <Label>{t('stage')}:</Label>}
						{label && <Label>{t(label)}</Label>}
						{isRequired && <span className='text-danger'>*</span>}
						<Select
							menuPortalTarget={document.body}
							menuPosition='absolute'
							options={stagesOptions}
							value={[contentToOption(selectedStage)]}
							onChange={(selection: any) => {
								onStageSelect(selection.data);
							}}
						/>
					</Col>
				</Row>
			</>
		);
	}
}
