import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Col, Label, Row } from 'reactstrap';
import { customOption } from '../shared/custom-select-option';
import StandingHttpService from '../../../../../services/rest/standings-http-service';
import { rankingPlayersToOptions, responseToRankingPlayersModel } from './helpers/ranking-players-select.helper';
import RankingPlayersModel from '../../../../../models/v2/ranking-player/ranking-player.model';

type Properties = {
	onRankingPlayersSelect: (rankingPlayers: RankingPlayersModel[]) => any;
	selectedPlayers: RankingPlayersModel[];
	t: any;
	isValid: boolean;
	isClearable?: boolean;
	isRequired: boolean;
	isBlocky: boolean;
	language: string;
	withLabel?: boolean;
	label?: string;
	sport: string;
	id: string;
	offset?: string;
	limit?: string;
	rankingId: string;
	standingTypeName: string;
};

const RankingPlayersSelectComponent: React.FunctionComponent<Properties> = (props) => {
	const [rankingPlayersOptions, setRankingPlayersOptions] = useState<RankingPlayersModel[]>([]);
	const { language, sport, selectedPlayers, id, isClearable, isRequired, t, offset, limit, rankingId, standingTypeName } = props;

	useEffect(() => {
		if (offset !== '' && limit !== '' && standingTypeName !== '') getRankingPlayers();
	}, [standingTypeName, offset, limit]);

	const getRankingPlayers = () => {
		StandingHttpService.getRankingPlayers(sport, rankingId, language, limit, offset)
			.then((response: any) => {
				setRankingPlayersOptions(responseToRankingPlayersModel(response.data));
			})
			.catch((e: any) => e);
	};

	return (
		<Row data-qa={id}>
			<Col>
				<Label htmlFor={id}>{t('pick_players_highlight')}</Label>
				{isRequired && <span className='text-danger'>*</span>}
				<Select
					id={id}
					menuPortalTarget={document.body}
					menuPosition='absolute'
					className='w-100'
					isMulti={true}
					placeholder={props.t('select')}
					formatOptionLabel={customOption}
					options={rankingPlayersToOptions(rankingPlayersOptions)}
					value={rankingPlayersToOptions(selectedPlayers)}
					noOptionsMessage={(inputValue) => inputValue && t('no_options')}
					isClearable={isClearable}
					onChange={(selection: any) => {
						if (selection) {
							props.onRankingPlayersSelect(selection.map((item: any) => item.data));
						} else {
							props.onRankingPlayersSelect([]);
						}
					}}
				/>
			</Col>
		</Row>
	);
};

export default RankingPlayersSelectComponent as React.ComponentType<Properties>;
