import LiveBlogAdvancedFiltersModel from '../../../models/live-blog-advanced-filters.model';
import {
	extractCompetitionIds,
	extractTeamIds,
	extractTournamentIds,
} from '../../../../../Partials/Sidebar/related-content/subcomponents/helpers/content-by-suggestions-container.helper';
import LiveBlogModel from '../../../models/live-blog.model';
import { LiveBlogStatuses } from '../../../helpers/utility.helper';
import { Badge } from 'reactstrap';
import React from 'react';

export const constructLiveBlogSearchContentQuery = (data: LiveBlogAdvancedFiltersModel) => {
	if (data) {
		const teamIds = extractTeamIds(data.relatedSports);
		const tournamentIds = extractTournamentIds(data.relatedSports);
		const competitionIds = extractCompetitionIds(data.relatedSports);

		const teamIdsQuery = teamIds.length > 0 ? `&team_ids=${teamIds}` : '';
		const tournamentIdsQuery = tournamentIds.length > 0 ? `&competition_ids=${tournamentIds}` : '';
		const competitionIdsQuery = competitionIds.length > 0 ? `&competition_ids=${competitionIds}` : '';
		const statusQuery = data.status && data.status.length > 0 ? `&status=${data.status}` : '';
		const typeQuery = data.type && data.type.length > 0 ? `&type=${data.type}` : '';

		return teamIdsQuery + tournamentIdsQuery + competitionIdsQuery + statusQuery + typeQuery;
	}

	return '';
};

export const checkIfLiveBlogFiltersAreEmpty = (model: LiveBlogAdvancedFiltersModel) => {
	if (model && Object.entries(model).length > 0) {
		const isRelatedSportsEmpty = !model.relatedSports || (model.relatedSports && model.relatedSports.length === 0);
		const isStatusEmpty = !model.status || (model.status && model.status.length === 0);
		const isTypeEmpty = !model.type || (model.type && model.type.length === 0);

		return isRelatedSportsEmpty && isStatusEmpty && isTypeEmpty;
	}

	return true;
};

export const extractLiveBlogsSearchQueryFromFilters = (filters: LiveBlogAdvancedFiltersModel) => {
	let query: string = '';

	if (filters && Object.entries(filters).length > 0) {
		const searchText = filters.searchText && filters.searchText.length > 0 ? `title=${filters.searchText}` : '';
		const advancedContentSearchText = constructLiveBlogSearchContentQuery(filters);

		query = searchText + advancedContentSearchText;
	}

	return query;
};

export const determineListingIcon = (liveBlog: LiveBlogModel) => {
	if (liveBlog.status === LiveBlogStatuses.Active) {
		return (
			<Badge color='success' className='mt-1 text-white mr-2 live-blog-type'>
				<i className='fa-lg fa fa-check mx-2' />
			</Badge>
		);
	} else if (liveBlog.status === LiveBlogStatuses.ARCHIVED) {
		return (
			<Badge color='dark' className='mt-1 text-white mr-2 live-blog-type'>
				<i className='fa-lg fa fa-box-archive mx-2' />
			</Badge>
		);
	} else if (liveBlog.status === LiveBlogStatuses.INACTIVE) {
		return (
			<Badge color='danger' className='mt-1 text-white mr-2 live-blog-type'>
				<i className='fa-lg fa fa-xmark mx-2' />
			</Badge>
		);
	}
};
export const isAuthorizedError = (errors: any) => {
	if (!errors || (errors && errors[0] && errors[0].code !== 403)) {
		return true;
	} else {
		return false;
	}
};
