import * as React from 'react';
import { useEffect, useState } from 'react';
import HttpService from '../../../../../services/rest/HttpService';
import { importTournamentInSeasons } from '../../../Sidebar/tags/subcomponents/football-connections/helpers/football-conections-container.helper';
import Select from 'react-select';
import { seasonsToOptions } from '../../../Blocky/partials/season-select/helpers/season-select.helper';

export type Properties = {
	onSeasonSelect: (related: any) => any;
	related: any;
	t: any;
	selectedOption: any;
	selectedTournament: any;
};

const SeasonSelect: React.FunctionComponent<Properties> = (props) => {
	const { t, onSeasonSelect, selectedTournament, selectedOption } = props;
	const [newValue, setNewValue] = useState<string>('');
	const [selectedSeason, setSelectedSeason] = useState<any>(selectedOption ? selectedOption : null);
	const [tournament, setTournament] = useState<any>(selectedTournament ? selectedTournament : null);
	const [seasons, setSeasons] = useState<any[]>([]);

	useEffect(() => {
		setTournament(selectedTournament ? selectedTournament : null);
		if (selectedTournament && selectedTournament.data) {
			loadSeasonsData(tournament.data.id);
		}
	}, []);

	useEffect(() => {
		setTournament(selectedTournament);
		if (selectedTournament && selectedTournament.data && selectedTournament.data.id) {
			loadSeasonsData(selectedTournament.data.id);
		}
	}, [selectedTournament]);

	const onInputChange = (input: string) => {
		setNewValue(input);
		return input;
	};

	const onSeasonChange = (season: any) => {
		setSelectedSeason(season);
		onSeasonSelect(season);
	};

	const seasonToOption = (season: any) => {
		if (season && season.data) {
			return {
				value: season.data.id,
				label: season.data.name,
				data: season.data,
				type: 'season',
			};
		} else {
			return null;
		}
	};

	const loadSeasonsData = (tournamentId: string) => {
		HttpService.getTournamentById(tournamentId).then((response: any) => {
			const seasonsData = importTournamentInSeasons(response.data);
			setSeasons(seasonsData);
		});
	};
	return (
		<Select
			inputId='season-select'
			value={seasonToOption(selectedSeason)}
			onChange={onSeasonChange}
			onInputChange={onInputChange}
			options={seasonsToOptions(seasons)}
			placeholder={t('select')}
			isClearable
			noOptionsMessage={(inputValue: any) => inputValue && t('no_options')}
			isMulti={false}
		/>
	);
};

export default SeasonSelect;
