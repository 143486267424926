import React, { ChangeEvent, KeyboardEvent, useEffect, useState } from 'react';
import { Label, InputGroup, Input, InputGroupAddon, Button } from 'reactstrap';
import '../../styles/getty.styles.scss';
import { useTranslation } from 'react-i18next';
import GettyImageModel from '../../models/getty-image.model';
import GettyApiService from '../../helpers/getty-api.service';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../../store/store';
import { GettyImageSearchTypeSelect } from './getty-image-search-type-select.component';
import { GETTY_IMAGES_TYPES } from '../../helpers/getty-images.helper';
import { responseToGettyImagesListingModel } from '../../models/getty-images-listing.mapper';
import GettyImagesListingModel from '../../models/getty-images-listing.model';

type Properties = {
	onSelect: (image: GettyImagesListingModel | null, pageOffset: number, searchInput: string, selectedImageType: string) => void;
	toggleLoadingState: (loading: boolean) => void;
	selectedImageType: string;
	searchText: string;
	onSelectedImageChange: (image: GettyImageModel) => void;
	pageOffset: number;
	shouldRequest: boolean;
	gettyInstance: GettyApiService;
};

export const GettyImageSelect: React.FunctionComponent<Properties> = (props) => {
	const [t] = useTranslation();
	const project = useSelector((state: AppState) => state.project.currentProject);

	const [contentSearchInput, setContentSearchInput] = useState(props.searchText && props.searchText.length > 0 ? props.searchText : '');
	const [isOpenDropdown, setIsOpenDropdown] = useState(false);
	const [selectedImageType, setSelectedImageType] = useState(props.selectedImageType ? props.selectedImageType : GETTY_IMAGES_TYPES[1]);

	useEffect(() => {
		if (props.searchText && props.searchText.length > 0) {
			searchImagoImages(props.searchText, props.pageOffset);
		}
	}, []);

	useEffect(() => {
		if (props.shouldRequest && contentSearchInput.length > 0) {
			searchImagoImages(contentSearchInput.trim(), props.pageOffset);
		}
	}, [props.shouldRequest]);

	const onContentSearchInput = (event: ChangeEvent<HTMLInputElement>) => {
		onImagesInputChangeState(event.target.value);

		if (event.target.value.length < 1) {
			props.onSelect(null, 0, '', selectedImageType);
			props.onSelectedImageChange(new GettyImageModel());
		}
	};

	const onContentSearch = (event: KeyboardEvent<HTMLInputElement>) => {
		if (event.keyCode === 13 && contentSearchInput.trim().length > 0) {
			searchImagoImages(contentSearchInput, 1);
		}
	};

	const clearSearch = () => {
		onImagesInputChangeState('');
		props.onSelect(null, 0, '', '');
		props.onSelectedImageChange(new GettyImageModel());
	};

	const searchImagoImages = async (search: string, pageOffset: number) => {
		props.toggleLoadingState(true);

		if (selectedImageType === GETTY_IMAGES_TYPES[0]) {
			props.gettyInstance
				.getGettyCreativeImages(search, pageOffset)
				.then((response: any) => {
					props.toggleLoadingState(false);
					props.onSelect(responseToGettyImagesListingModel(response), pageOffset, search, selectedImageType);
				})
				.catch(props.toggleLoadingState(true));
		} else {
			props.gettyInstance
				.getGettyEditorialImages(search, pageOffset)
				.then((response: any) => {
					props.toggleLoadingState(false);
					props.onSelect(responseToGettyImagesListingModel(response), pageOffset, search, selectedImageType);
				})
				.catch(props.toggleLoadingState(true));
		}
	};

	const toggleImagesTypeMenu = () => {
		setIsOpenDropdown(!isOpenDropdown);
	};

	const changeSelectedImageType = (type: string) => {
		setSelectedImageType(type);
	};

	const onImagesInputChangeState = (input: string) => {
		setContentSearchInput(input);
	};

	return (
		<div data-qa='getty-image-select-component'>
			<Label htmlFor='search-getty-image-input'>{t('choose_image')}:</Label>
			<InputGroup className='position-relative d-flex flex-direction-row flex-nowrap'>
				<GettyImageSearchTypeSelect
					changeSelectedImageType={changeSelectedImageType}
					isOpenDropdown={isOpenDropdown}
					toggleImagesTypeMenu={toggleImagesTypeMenu}
					selectedType={selectedImageType}
				/>
				<InputGroup data-qa='search-getty-image-input' className='position-relative d-flex'>
					<Input
						type='text'
						id='search-getty-image-input'
						name='search-input'
						className='rounded-0 pr-4'
						placeholder={t('content_search_placeholder')}
						onChange={onContentSearchInput}
						onKeyDown={onContentSearch}
						value={contentSearchInput}
					/>
					{contentSearchInput && contentSearchInput.length > 0 && (
						<div onClick={clearSearch} className='p-2 text-muted position-absolute' style={{ top: 0, right: 0 }}>
							<i className='fa fa-times' />
						</div>
					)}
				</InputGroup>
				<InputGroupAddon addonType='append'>
					<Button
						className='btn-dark rounded-0'
						id='search-getty-images-button'
						type='button'
						disabled={contentSearchInput.trim().length === 0}
						onClick={() => searchImagoImages(contentSearchInput, 1)}
					>
						<i className='fa fa-search'>&nbsp;</i>
					</Button>
				</InputGroupAddon>
			</InputGroup>
		</div>
	);
};
