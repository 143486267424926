import { BannerJson } from './BannerJson';
import Banner from './Banner';
import { BannerRequestJson } from './BannerRequestJson';
import { DynamicContentProperty, DynamicObjectWithKeys } from '../../constants/content-types';

export default class BannerBuilder {
	private json: BannerJson;

	constructor(banner?: Banner | BannerJson) {
		if (banner && banner instanceof Banner) {
			this.json = banner.toJSON();
		} else if (banner) {
			this.json = banner;
		} else {
			this.json = {} as BannerJson;
		}
	}

	withId(id: string): BannerBuilder {
		this.json.id = id;

		return this;
	}

	withTitle(title: string): BannerBuilder {
		this.json.title = title;

		return this;
	}

	withCode(code: string): BannerBuilder {
		this.json.code = code;

		return this;
	}

	withCreatedAt(createdAt: string): BannerBuilder {
		this.json.createdAt = createdAt;

		return this;
	}

	withUpdatedAt(updatedAt: string): BannerBuilder {
		this.json.updatedAt = updatedAt;

		return this;
	}

	withStatus(status: string): BannerBuilder {
		this.json.status = status;

		return this;
	}

	// this is used to set dynamic 'properties' placed in general content tab
	withDynamicProperties(dynamicProperties: DynamicContentProperty[]): BannerBuilder {
		this.json.properties = dynamicProperties.reduce((obj, item) => Object.assign(obj, { [item.slug]: item.value }), {});

		return this;
	}

	withDynamicPropertiesInitially(dynamicProperties: DynamicObjectWithKeys): BannerBuilder {
		this.json.properties = dynamicProperties;

		return this;
	}

	build(): Banner {
		return Banner.fromJSON(this.json);
	}

	equals(banner: Banner): boolean {
		const idEqual = this.json.id === banner.id;
		const titleEqual = this.json.title === banner.title;
		const codeEqual = this.json.code === banner.code;
		const createdAtEqual = this.json.createdAt === banner.createdAt;
		const updatedAttEqual = this.json.updatedAt === banner.updatedAt;
		const statusEqual = this.json.status === banner.status;

		return idEqual && titleEqual && codeEqual && createdAtEqual && updatedAttEqual && statusEqual;
	}

	toRequestJson(): BannerRequestJson {
		let json = {} as BannerRequestJson;
		json.id = this.json.id;
		json.title = this.json.title;
		json.code = this.json.code;
		json.status = this.json.status;

		// this will extract 'properties' object and will populate the 'json' object with its dynamic entities
		Object.keys(this.json.properties).forEach((propertyKey: string) => (json[propertyKey] = this.json.properties[propertyKey]));

		return json;
	}
}
