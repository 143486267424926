import Article from '../../../../../../../models/article/Article';
import { RowModel } from '../models/RowModel';
import Video from '../../../../../../../models/video/Video';
import Gallery from '../../../../../../../models/gallery/Gallery';
import Banner from '../../../../../../../models/banner/Banner';
import Author from '../../../../../../../models/author/Author';
import Tag from '../../../../../../../models/tag/Tag';
import List from '../../../../../../../models/list/List';
import Category from '../../../../../../../models/category/Category';
import Poll from '../../../../../../../models/polls/poll';
import Feed from '../../../../../../../models/feed/feed';
import BlacklistModel from '../../../../../../../models/team-manual-data/blacklist/blacklist';
import ModelMapper from '../../../../../../../models/ModelMapper';
import { TreeItem } from 'react-sortable-tree';
import PlayerManualDataModel from '../../../../../../../models/player-manual-data/player-manual-data.model';
import TeamManualDataModel from '../../../../../../../models/team-manual-data/team-manual-data.model';
import { categoriesToTree } from '../../../../../../Resources/Categories/Helpers/category-tree.helper';
import { ContentTypes } from '../../../../../../../constants/content-types';
import { assetsPlaceholder } from '../../../../../../../constants/assetsPlaceholder';
import EnumItem from '../../../../../../../models/enum/EnumItem';

export function mapArticleListToRowModelList(articles: Article[]) {
	let rowModelList: RowModel[] = [];

	if (articles) {
		rowModelList = articles.map((article: Article) => mapArticleToRowModel(article));
	}

	return rowModelList;
}

export function mapVideoListToRowModelList(videos: Video[]) {
	let rowModelList: RowModel[] = [];

	if (videos) {
		rowModelList = videos.map((video: Video) => mapVideoToRowModel(video));
	}

	return rowModelList;
}

export function mapGalleryListToRowModelList(galleries: Gallery[]) {
	let rowModelList: RowModel[] = [];

	if (galleries) {
		rowModelList = galleries.map((gallery: Gallery) => mapGalleryToRowModel(gallery));
	}

	return rowModelList;
}

export function mapBannerListToRowModelList(banners: Banner[]) {
	let rowModelList: RowModel[] = [];

	if (banners) {
		rowModelList = banners.map((banner: Banner) => mapBannerToRowModel(banner));
	}

	return rowModelList;
}

export function mapTagListToRowModelList(tags: Tag[]) {
	let rowModelList: RowModel[] = [];

	if (tags) {
		rowModelList = tags.map((tag: Tag) => mapTagToRowModel(tag));
	}

	return rowModelList;
}

export function mapCategoryListToTreeItemList(categories: Category[]) {
	let treeItemList: TreeItem[] = [];
	if (categories && categories.length > 0) {
		treeItemList = categoriesToTree(categories);
	}

	return treeItemList;
}

export function mapAuthorListToRowModelList(authors: Author[]) {
	let rowModelList: RowModel[] = [];

	if (authors) {
		rowModelList = authors.map((author: Author) => mapAuthorToRowModel(author));
	}

	return rowModelList;
}

export function mapListListingToRowModelList(lists: List[]) {
	let rowModelList: RowModel[] = [];

	if (lists) {
		rowModelList = lists.map((list: List) => mapListToRowModel(list));
	}

	return rowModelList;
}

export function mapPollListToRowModelList(polls: Poll[]) {
	let rowModelList: RowModel[] = [];

	if (polls) {
		rowModelList = polls.map((poll: Poll) => mapPollToRowModel(poll));
	}

	return rowModelList;
}

export function mapFeedListToRowModelList(feeds: Feed[]) {
	let rowModelList: RowModel[] = [];

	if (feeds) {
		rowModelList = feeds.map((feed: Feed) => mapFeedToRowModel(feed));
	}

	return rowModelList;
}

export function mapTeamListToRowModelList(teams: TeamManualDataModel[]) {
	let rowModelList: RowModel[] = [];

	if (teams) {
		rowModelList = teams.map((team: TeamManualDataModel) => mapTeamToRowModel(team));
	}

	return rowModelList;
}

export function mapPlayerListToRowModelList(players: PlayerManualDataModel[]) {
	let rowModelList: RowModel[] = [];

	if (players) {
		rowModelList = players.map((player: PlayerManualDataModel) => mapPlayerToRowModel(player));
	}

	return rowModelList;
}

export function mapCoachListToRowModelList(coaches: PlayerManualDataModel[]) {
	let rowModelList: RowModel[] = [];

	if (coaches) {
		rowModelList = coaches.map((coach: PlayerManualDataModel) => mapCoachToRowModel(coach));
	}

	return rowModelList;
}

export function mapArticleToRowModel(article: Article): RowModel {
	return {
		id: article && article.id ? article.id : '',
		mainCategory: article && article.category ? article.category.title : '',
		publishedAt: article && article.publishedAt ? article.publishedAt : '',
		updatedAt: article && article.updatedAt ? article.updatedAt : '',
		createdAt: article && article.createdAt ? article.createdAt : '',
		title: article && article.title ? article.title : '',
		createdBy: article && article.createdBy ? article.createdBy : '',
		strapline: article && article.strapline ? article.strapline : '',
		status: article && article.status ? article.status : '',
		views: article && article.viewsCount ? article.viewsCount : 0,
		comments: article && article.commentsCount ? article.commentsCount : 0,
		translationGroup: article && article.translationGroup ? article.translationGroup : undefined,
	};
}

export function mapPollToRowModel(poll: Poll): RowModel {
	return {
		id: poll && poll.id ? poll.id : '',
		title: poll && poll.title ? poll.title : '',
	};
}

export function mapVideoToRowModel(video: Video): RowModel {
	return {
		id: video && video.id ? video.id : '',
		mainCategory: video && video.category ? video.category.title : '',
		publishedAt: video && video.publishedAt ? video.publishedAt : '',
		title: video && video.title ? video.title : '',
		createdBy: video && video.createdBy ? video.createdBy : '',
		strapline: video && video.strapline ? video.strapline : '',
		status: video && video.status ? video.status : '',
		views: video && video.viewsCount ? video.viewsCount : 0,
		origin: video && video.origin ? video.origin : undefined,
		comments: video && video.commentsCount ? video.commentsCount : 0,
		updatedAt: video && video.updatedAt ? video.updatedAt : '',
		createdAt: video && video.createdAt ? video.createdAt : '',
		translationGroup: video && video.translationGroup ? video.translationGroup : undefined,
		mainMedia: video && video.mainMedia ? video.mainMedia : undefined,
	};
}

export function mapGalleryToRowModel(gallery: Gallery): RowModel {
	return {
		id: gallery && gallery.id ? gallery.id : '',
		mainCategory: gallery && gallery.category ? gallery.category.title : '',
		publishedAt: gallery && gallery.publishedAt ? gallery.publishedAt : '',
		title: gallery && gallery.title ? gallery.title : '',
		createdBy: gallery && gallery.createdBy ? gallery.createdBy : '',
		strapline: gallery && gallery.strapline ? gallery.strapline : '',
		status: gallery && gallery.status ? gallery.status : '',
		views: gallery && gallery.viewsCount ? gallery.viewsCount : 0,
		comments: gallery && gallery.commentsCount ? gallery.commentsCount : 0,
		updatedAt: gallery && gallery.updatedAt ? gallery.updatedAt : '',
		createdAt: gallery && gallery.createdAt ? gallery.createdAt : '',
		translationGroup: gallery && gallery.translationGroup ? gallery.translationGroup : undefined,
	};
}

export function mapBannerToRowModel(banner: Banner): RowModel {
	return {
		id: banner && banner.id ? banner.id : '',
		title: banner && banner.title ? banner.title : '',
	};
}

export function mapListToRowModel(list: List): RowModel {
	return {
		id: list && list.id ? list.id : '',
		title: list && list.title ? list.title : '',
		status: list && list.status ? list.status : '',
	};
}

export function mapAuthorToRowModel(author: Author): RowModel {
	return {
		id: author && author.id ? author.id : '',
		title: author && author.name ? author.name : '',
	};
}

export function mapTagToRowModel(tag: Tag): RowModel {
	return {
		id: tag && tag.id ? tag.id : '',
		title: tag && tag.title ? tag.title : '',
		weight: tag && tag.weight ? tag.weight : 0,
		orderType: tag && tag.orderType ? tag.orderType : ({} as EnumItem),
	};
}

export function mapCategoryToRowModel(category: any): TreeItem {
	let subIndent = category && category.subIndent ? category.subIndent : '';
	let parentId = category && category.parentId ? category.parentId : '';

	return {
		id: category && category.id ? category.id : '',
		title: category && category.title ? subIndent + category.title : '',
		titleInTree: category && category.title ? category.title : '',
		parentId: parentId,
		children: category && category.children ? category.children : [],
		subIndent: subIndent,
		expanded: true,
		active: category && category.active,
	};
}

export function mapFeedToRowModel(feed: Feed): RowModel {
	return {
		id: feed && feed.id ? feed.id : '',
		title: feed && feed.name ? feed.name : '',
	};
}

export function mapTeamToRowModel(team: TeamManualDataModel): RowModel {
	let asset = findAssetUrl(team, ContentTypes.TEAM);
	return {
		id: team && team.id ? team.id : '',
		title: team && team.name ? team.name : '',
		img: asset ? asset : assetsPlaceholder.team,
		country: team && team.country && team.country.name ? team.country.name : '',
		blacklists: team && team.blacklists ? team.blacklists : [],
	};
}

export function mapPlayerToRowModel(player: PlayerManualDataModel): RowModel {
	let asset = findAssetUrl(player, ContentTypes.PLAYER);

	return {
		id: player && player.id ? player.id : '',
		img: asset ? asset : assetsPlaceholder.player,
		birthdate: player.birthdate ? player.birthdate : '',
		title: player && player.name ? player.name : '',
		country: player && player.country && player.country.name ? player.country.name : '',
		blacklist: player && player.blacklist ? player.blacklist : BlacklistModel.builder().build(),
	};
}

export function mapCoachToRowModel(coach: PlayerManualDataModel): RowModel {
	let asset = findAssetUrl(coach, ContentTypes.COACH);
	return {
		id: coach && coach.id ? coach.id : '',
		img: asset ? asset : assetsPlaceholder.coach,
		birthdate: coach.birthdate ? coach.birthdate : '',
		title: coach && coach.name ? coach.name : '',
		country: coach && coach.country && coach.country.name ? coach.country.name : '',
		blacklist: coach && coach.blacklist ? coach.blacklist : BlacklistModel.builder().build(),
	};
}

export const contentBuilders = {
	article: Article,
	video: Video,
	gallery: Gallery,
};

export const contentRemappers = (response: any, contentType: string, dateNow: string) => {
	const remappers = {
		article: ModelMapper.remapArticleFromResponse,
		video: ModelMapper.remapVideoFromResponse,
		gallery: ModelMapper.remapGalleryFromResponse,
	};

	return remappers[contentType](response, dateNow);
};

export const relatedPath = {
	article: 'articles',
	video: 'videos',
	gallery: 'galleries',
};

export const remapContentToTempCreate = (content: any, contentType: string, t: any) => {
	return contentBuilders[contentType]
		.builder(content)
		.withId('temp-copy-id')
		.withTitle(`[${t('copy_content')}] ${content.title}`)
		.build();
};

export function findAssetUrl(entity: any, type: string) {
	if (type === ContentTypes.PLAYER || type === ContentTypes.COACH) {
		if (entity && entity.assets && entity.assets.thumb && entity.assets.thumb.url) {
			return entity.assets.thumb.url;
		} else {
			return entity['url_thumb'];
		}
	} else if (type === ContentTypes.TEAM) {
		if (entity && entity.assets && entity.assets.logo && entity.assets.logo.url) {
			return entity.assets.logo.url;
		} else {
			return entity['url_logo'];
		}
	}
}
