import 'reflect-metadata';
import { TypedJSON } from 'typedjson';
import CustomBlockModel from './custom-block.model';
import CustomBlockIconModel from './custom-block-icon.model';

export default class CustomBlockMapper {
	public responseToModel = (response: any): CustomBlockModel => {
		const serializer = new TypedJSON(CustomBlockModel);
		return serializer.parse(response) as CustomBlockModel;
	};
	public modelToRequestBody = (model: CustomBlockModel) => {
		if (model) {
			return {
				id: model.id,
				name: model.name,
				icon: this.iconToRequest(model.icon),
				application_url: model.application_url,
				status: model.status,
			};
		}
		return new CustomBlockModel();
	};
	/**
	 * @param icon
	 */
	private iconToRequest = (icon: CustomBlockIconModel) => {
		if (icon) {
			return {
				type: icon.type,
				value: icon.value,
			};
		}
	};
}
