import React, { useState } from 'react';
import { Button, ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import ListModel from '../../../../../../models/v2/list/list';
import { autoGenerateReduxPropsNamingForContentModels } from '../../../../../../services/content-models-service/ContentModelService';
import { store } from '../../../../../../store/store';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { updateListInRedux } from '../../../../../../store/action-creators/ListActionCreators';
import { generateNewListItem } from '../../helpers/general.helper';
import { DATA_QA_ATTRIBUTES } from '../../constants/data-qa.attributes';

type Properties = {
	t: any;
	selectedList: ListModel;
	contentModelId: string;
	updateListInRedux: (list: ListModel) => void;
};

const ListItemAddButtons: React.FunctionComponent<Properties> = ({ t, selectedList, contentModelId, updateListInRedux }) => {
	const [dropdownOpen, setDropdownOpen] = useState({ isOpen: false, openedDropdownId: '' });

	const onListItemAdd = (addItemToTop: boolean = false) => {
		const { tempPropertyNaming, nestedTempPropertyNaming } = autoGenerateReduxPropsNamingForContentModels();
		const contentModel = store.getState()[tempPropertyNaming][nestedTempPropertyNaming] || {};
		const newListItem = generateNewListItem(contentModel);
		const newItems = addItemToTop ? [newListItem, ...selectedList.items] : [...selectedList.items, newListItem];
		updateListInRedux({ ...selectedList, items: newItems });
	};

	const toggle = (id: string) => {
		setDropdownOpen((prevState: any) => {
			return { isOpen: !prevState.isOpen, openedDropdownId: id };
		});
	};

	const addToTop = () => {
		selectedList.lockedPositions.length === 0 ? onListItemAdd(true) : toast.error(t('first_item_is_locked'));
	};

	return (
		<ButtonDropdown
			isOpen={dropdownOpen.openedDropdownId === contentModelId && dropdownOpen.isOpen}
			toggle={() => toggle(contentModelId)}
			className='ml-auto'
		>
			<Button outline id={DATA_QA_ATTRIBUTES.LIST_ADD_ITEM} color='primary' onClick={() => onListItemAdd()} className='list-content-add-btn'>
				<i className={'fa fa-plus'}></i>
			</Button>
			<DropdownToggle outline caret color='primary' className='list-content-add-btn' />
			<DropdownMenu>
				<DropdownItem key='dropdown-item-first-occurrence' id={DATA_QA_ATTRIBUTES.LIST_ADD_ITEM_TOP} onClick={addToTop}>
					{t('add_to_top')}
				</DropdownItem>
			</DropdownMenu>
		</ButtonDropdown>
	);
};

function mapStateToProps(state: any) {
	const { tempPropertyNaming, nestedTempPropertyNaming } = autoGenerateReduxPropsNamingForContentModels();

	return {
		contentModelId: state[tempPropertyNaming][nestedTempPropertyNaming].id || '',
	};
}

function mapDispatchToProps(dispatch: Function) {
	return {
		updateListInRedux: (list: ListModel) => dispatch(updateListInRedux(list)),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(ListItemAddButtons);
