import { ImagoImagesJson } from './imago-images.json';
import ImagoImageModel from './imago-image.model';
import ImagoImagesBuilder from './imago-images.builder';

export default class ImagoImagesModel {
	readonly totalPhotos: number;
	readonly imageList: ImagoImageModel[];

	private constructor(totalPhotos: number, imageList: ImagoImageModel[]) {
		this.totalPhotos = totalPhotos;
		this.imageList = imageList;
	}

	toJSON(): ImagoImagesJson {
		return {
			totalPhotos: this.totalPhotos,
			imageList: this.imageList,
		};
	}

	static fromJSON(json: ImagoImagesJson): ImagoImagesJson {
		return new ImagoImagesModel(json.totalPhotos, json.imageList);
	}

	static builder(images?: ImagoImagesModel): ImagoImagesBuilder {
		return new ImagoImagesBuilder(images);
	}
}
