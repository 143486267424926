import * as React from 'react';
import { Properties, State } from './properties/KeywordsProperties';
import TagsInput from 'react-tagsinput';

export default class Keywords extends React.Component<Properties, State> {
	onKeywordsChange(keywords: string[]) {
		this.props.onKeywordsChange(keywords);
	}

	render() {
		const { keywords, t } = this.props;
		const props = {
			className: 'react-tagsinput-input w-100',
			placeholder: t('add_keyword'),
			id: 'content-seo-keywords-input',
		};

		return <TagsInput inputProps={props} value={keywords} onChange={this.onKeywordsChange.bind(this)} />;
	}
}
