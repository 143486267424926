import 'reflect-metadata';
import { jsonArrayMember, jsonMember, jsonObject } from 'typedjson';
import TournamentOrderModel from '../TournamentOrder/TournamentOrderModel';

@jsonObject()
export default class TournamentGroupModel {
	@jsonMember(String)
	public code: string = '';
	@jsonMember(String)
	public name: string = '';
	@jsonMember(String)
	public description: string = '';
	@jsonArrayMember(TournamentOrderModel)
	public tournaments: TournamentOrderModel[] = [];
}
