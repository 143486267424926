import React, { useEffect, useRef } from 'react';
import BlockModel from '../../models/block.model';
import './custom-block.iframe.scss';
import { Alert } from 'reactstrap';
import { CommunicationsLibrary, EVENT_MESSAGE, EventModel } from '@mediaplatform/communications-library';

export type Properties = {
	block: BlockModel;
	title: string;
	id: string;
	index: number;
	children?: any;
	appURL: string;
	onCustomBlockSubmit: (block: BlockModel) => void;
};

const FunctionalIFrameComponent: React.FunctionComponent<Properties> = (props) => {
	const iframeRef = useRef(null);
	useEffect(() => {
		let contentWindow = null;
		let commsLib: any = null;

		if (iframeRef) {
			const iframe = window.document.getElementById(props.block.id);
			contentWindow = iframe && iframe.contentWindow;

			commsLib = new CommunicationsLibrary(new URL(props.appURL).origin, contentWindow);
			commsLib.setParentData(props.block.data.customData, `${props.block.id}`);
			commsLib.initiateHandshake();

			commsLib.listenForEvents((evt: EventModel) => {
				if (evt.type === EVENT_MESSAGE && evt.key === `${props.block.id}`) {
					props.block.data.customData = evt.data;
					props.onCustomBlockSubmit(props.block);
				}
			});
		}

		return () => {
			commsLib.closeConnection();
		};
	}, []);

	return (
		<>
			<div className='custom-block-iframe-wrapper'>
				<iframe
					className='custom-block-iframe'
					id={props.block.id}
					ref={iframeRef}
					title={props.title}
					src={props.appURL + `#${props.block.id}`}
				/>
			</div>
			<div className='payload-output'>
				{props.block.data.customData && props.block.data.customData.length !== 0 ? (
					<Alert color='info'>
						<h4 className='alert-heading'>Application Data:</h4>
						{JSON.stringify(props.block.data.customData)}
					</Alert>
				) : null}
			</div>
		</>
	);
};

export default FunctionalIFrameComponent;
