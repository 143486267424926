import React from 'react';
import { Col, Row } from 'reactstrap';
import moment from 'moment';
import { imagePlaceHolder } from '../../../../../../../constants/resource-constants';
import { Properties } from './properties/odds-widget-view.properties';
import BookmakerModel from '../../../../partials/bookmaker-select/models/bookmaker.model';
import { MarketTypes } from '../../../../constants/market.types.constants';
import { assetsPlaceholder } from '../../../../../../../constants/assetsPlaceholder';

export const OddsWidgetView: React.FunctionComponent<Properties> = ({
	blockPreview,
	t,
	isStandAlone,
	displayHeader,
	displayOddsLabel,
	showAllBookmakers,
	displayThreeLetterCode,
}) => {
	if (blockPreview && blockPreview.match && blockPreview.match.id) {
		return (
			<div>
				{displayOddsLabel && (
					<Row className='text-left pt-2'>
						<Col>
							<h6>{t('odds_widget_for_match')}</h6>
						</Col>
					</Row>
				)}
				<Row className={`${isStandAlone ? 'px-4 py-2' : displayOddsLabel ? 'pl-4 pr-4' : 'pt-2 pl-4 pr-4'}`}>
					<Col className='text-left'>
						<Row className='mb-1'>
							<Col>
								<div className='mb-1 font-weight-bold d-inline-block mr-5'>
									{blockPreview.match.startTime ? moment(blockPreview.match.startTime).format('MMM Do YYYY, HH:mm') : ''}
								</div>
								<div className='d-inline-block'>
									<strong className='p-1'>
										{blockPreview.match.homeTeam
											? ((blockPreview.threeLetterCodeType && blockPreview.threeLetterCodeType !== '') || displayThreeLetterCode) &&
											  blockPreview.match.homeTeam.threeLetterCode !== null
												? blockPreview.match.homeTeam.threeLetterCode
												: blockPreview.match.homeTeam.name
											: ''}
									</strong>
									<img
										height='40'
										alt={blockPreview.match.homeTeam ? blockPreview.match.homeTeam.name : ''}
										src={blockPreview.match.homeTeam.logo ? blockPreview.match.homeTeam.logo : assetsPlaceholder.team}
										className='h-40 mx-1'
									/>
								</div>
								<div className='d-inline-block font-weight-bold'>:</div>
								<div className='d-inline-block'>
									<img
										height='40'
										alt={blockPreview.match.awayTeam ? blockPreview.match.awayTeam.name : ''}
										src={blockPreview.match.awayTeam.logo ? blockPreview.match.awayTeam.logo : assetsPlaceholder.team}
										className='h-40 mx-1'
									/>
									<strong className='p-1'>
										{blockPreview.match.awayTeam
											? ((blockPreview.threeLetterCodeType && blockPreview.threeLetterCodeType !== '') || displayThreeLetterCode) &&
											  blockPreview.match.awayTeam.threeLetterCode !== null
												? blockPreview.match.awayTeam.threeLetterCode
												: blockPreview.match.awayTeam.name
											: ''}
									</strong>
								</div>
							</Col>
						</Row>
						<Row>
							<Col>
								<hr></hr>
							</Col>
						</Row>
						<Row className='mb-2'>
							<Col>
								{t('show')}:
								{blockPreview.bookmakers &&
									blockPreview.bookmakers.length > 0 &&
									blockPreview.bookmakers.map((bookmaker: BookmakerModel) => {
										return (
											<img
												key={bookmaker.id}
												height='20'
												alt={bookmaker.name}
												className='h-20 mx-1'
												src={bookmaker.logo ? bookmaker.logo : imagePlaceHolder}
												title={bookmaker.name}
											/>
										);
									})}
							</Col>
						</Row>
						<Row>
							{displayHeader && (
								<Col className='text-left'>
									<span>{t('display_header')}: </span>
									<strong className={blockPreview.displayMatchHeader ? 'text-success' : 'text-danger'}>
										{blockPreview.displayMatchHeader ? t('yes') : t('no')}
									</strong>
								</Col>
							)}
							{blockPreview.market && blockPreview.market.name === MarketTypes.overUnder && blockPreview.market.value > 0 && (
								<Col className='text-right'>
									{t('default_market_value')}: <strong>{blockPreview.market.value}</strong>
								</Col>
							)}
						</Row>
						<Row>
							<Col className='text-left'>
								<span>{t('display_market_selection')}: </span>
								<strong className={blockPreview.canSelectMarkets ? 'text-success' : 'text-danger'}>
									{blockPreview.canSelectMarkets ? t('yes') : t('no')}
								</strong>
							</Col>
						</Row>
						<Row>
							{blockPreview.market && blockPreview.market.name && blockPreview.market.name.length > 0 && (
								<Col className='text-left'>
									<span>{t('default_market')}: </span>
									<strong>{t(blockPreview.market.name)}</strong>
								</Col>
							)}
						</Row>
						{blockPreview.threeLetterCodeType && blockPreview.threeLetterCodeType !== '' && (
							<Row>
								<Col className='text-left'>
									{t('display_team_three_letter_code')}:
									<strong>
										<span className='ml-2'>
											{blockPreview.threeLetterCodeType && blockPreview.threeLetterCodeType.name && t(`${blockPreview.threeLetterCodeType.name}`)}
										</span>
									</strong>
								</Col>
							</Row>
						)}
					</Col>
				</Row>
			</div>
		);
	}

	if (showAllBookmakers) {
		return (
			<>
				{blockPreview.bookmakers && blockPreview.bookmakers.length > 0 && (
					<div className='d-inline-block text-left bt-2'>
						<div className='d-inline-block'>{t('bookmakers')}: </div>
						<div className='d-inline-block'>
							{blockPreview.bookmakers.map((bookmaker: BookmakerModel) => {
								return (
									<div className='d-inline-block m-2' key={bookmaker.id}>
										<img
											height='20'
											alt={bookmaker.name}
											className='h-20'
											src={bookmaker.logo ? bookmaker.logo : imagePlaceHolder}
											title={bookmaker.name}
										/>
									</div>
								);
							})}
						</div>
					</div>
				)}
			</>
		);
	}

	return null;
};
