import {
	REQUEST_ARTICLE_TYPES_RECEIVED,
	REQUEST_GALLERY_TYPES_RECEIVED,
	REQUEST_IMAGE_TYPES_RECEIVED,
	REQUEST_LIST_TYPES_RECEIVED,
	REQUEST_TAG_TYPES_RECEIVED,
	REQUEST_VIDEO_TYPES_RECEIVED,
	REQUEST_WIKI_PAGES_TYPES_RECEIVED,
} from '../action-creators/types-action.creator';
import { USER_LOGOUT } from '../action-creators/ProfileActionCreators';
import ContentAttributes from '../../models/content-attributes/content-attributes-model';
import ContentAttributesModel from '../../views/Partials/Shared/wiki-pages-advanced-content-filter/models/content-attributes.model';

interface InitialState {
	articleTypes: ContentAttributes[];
	videoTypes: ContentAttributes[];
	imageTypes: ContentAttributes[];
	galleryTypes: ContentAttributes[];
	tagTypes: ContentAttributes[];
	listTypes: ContentAttributes[];
	wikipageTypes: ContentAttributesModel[];
}

const initialState: InitialState = {
	articleTypes: [] as ContentAttributes[],
	videoTypes: [] as ContentAttributes[],
	imageTypes: [] as ContentAttributes[],
	galleryTypes: [] as ContentAttributes[],
	tagTypes: [] as ContentAttributes[],
	listTypes: [] as ContentAttributes[],
	wikipageTypes: [] as ContentAttributesModel[],
};

function typesReducer(state: any = initialState, action: any) {
	if (action.type === REQUEST_ARTICLE_TYPES_RECEIVED) {
		return Object.assign({}, state, {
			articleTypes: action.payload.slice(),
		});
	}

	if (action.type === REQUEST_VIDEO_TYPES_RECEIVED) {
		return Object.assign({}, state, {
			videoTypes: action.payload.slice(),
		});
	}

	if (action.type === REQUEST_IMAGE_TYPES_RECEIVED) {
		return Object.assign({}, state, {
			imageTypes: action.payload.slice(),
		});
	}

	if (action.type === REQUEST_GALLERY_TYPES_RECEIVED) {
		return Object.assign({}, state, {
			galleryTypes: action.payload.slice(),
		});
	}

	if (action.type === REQUEST_TAG_TYPES_RECEIVED) {
		return Object.assign({}, state, {
			tagTypes: action.payload.slice(),
		});
	}

	if (action.type === REQUEST_LIST_TYPES_RECEIVED) {
		return Object.assign({}, state, {
			listTypes: action.payload.slice(),
		});
	}

	if (action.type === REQUEST_WIKI_PAGES_TYPES_RECEIVED) {
		return Object.assign({}, state, {
			wikipageTypes: action.payload.slice(),
		});
	}

	if (action.type === USER_LOGOUT) {
		state = initialState;
	}

	return state;
}

export default typesReducer;
