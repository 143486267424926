import React from 'react';
import './styles/style.scss';
import Masonry from 'react-masonry-css';
import ImageModel from '../../../../../../models/v2/image/image.model';
import { imagePlaceholderBase64 } from '../../../../../../global-helpers/global.constants';

type Properties = {
	images: ImageModel[];
	selectedImageId: string | null;
	onSelect: (image: ImageModel) => void;
};

export const LiveBlogImageCells: React.FunctionComponent<Properties> = ({ onSelect, images, selectedImageId }) => {
	return (
		<Masonry
			breakpointCols={{
				default: 3,
				1380: 3,
				1300: 2,
				990: 4,
				560: 3,
				520: 2,
			}}
			className='live-blog-masonry-grid'
			columnClassName='live-blog-grid-column'
		>
			{images.map((image: ImageModel) => {
				if (image && image.urls) {
					const imageSrc = image.urls && image.urls.uploaded && image.urls.uploaded.embed ? image.urls.uploaded.embed : imagePlaceholderBase64;
					return (
						<img
							alt={`${image.description}`}
							key={`image-cell-key-${image.id}`}
							src={imageSrc}
							onClick={() => onSelect(image)}
							className={`img-fluid w-100 cursor-pointer mb-1 mr-1 ${image.id === selectedImageId ? 'selected-main-image' : ''}`}
						/>
					);
				} else return null;
			})}
		</Masonry>
	);
};
