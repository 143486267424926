import React from 'react';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { Properties, RequiredProperties, State } from './properties/general-content-attribute-custom.properties';
import { actionService } from '../../../../App';
import { AppState } from '../../../../store/store';
import { connect } from '../../../../store/store.connect';
import TypesSelect from '../GeneralContentAttributes/subcomponents/type-select/type-select';
import { LIST_ENTITY_REQUEST, LIST_ENTITY_RECEIVED } from '../../../../store/action-creators/ListActionCreators';
import StatusSelect from '../GeneralContentAttributes/subcomponents/status-select/status-select';
import ContentAttributesSelectContainer from '../GeneralContentAttributes/subcomponents/content-attributes-select/content-attributes-select';
import ContentAttributes from '../../../../models/content-attributes/content-attributes-model';
import {
	extractDefaultValues,
	extractDefaultContentAttributesValue,
} from '../GeneralContentAttributes/helpers/general-content-attributes.helper';
import { Subscription } from 'rxjs';

@connect<Properties, AppState>(
	(state) => ({
		types: state.types,
		statuses: state.statuses,
		commentsPolicies: state.commentsPolicies,
	}),
	(dispatch) => ({
		dispatch: (action: any) => dispatch(action),
	}),
)
class GeneralContentTypeAttribute extends React.Component<Properties, State> {
	actionServiceSubscription: Subscription = {} as Subscription;

	constructor(props: Properties) {
		super(props);
		this.state = {
			isContentLoading: false,
		};
	}

	componentDidMount() {
		const { isNewContent } = this.props;
		this.registerActionEventListener();
		isNewContent && this.addDefaultValues();
	}

	onTypeChange = (type: string) => {
		let generalContent = this.props.value;
		generalContent.type = type;
		this.props.onChange(generalContent);
	};

	onStatusChange = (status: string) => {
		let generalContent = this.props.value;
		generalContent.status = status;
		this.props.onChange(generalContent);
	};

	registerActionEventListener() {
		this.actionServiceSubscription = actionService.onActionReceived().subscribe((action: string) => {
			if (action === LIST_ENTITY_REQUEST) {
				this.toggleContentLoadingState(true);
			}

			if (action === LIST_ENTITY_RECEIVED) {
				this.toggleContentLoadingState(false);
			}
		});
	}

	componentWillUnmount() {
		this.actionServiceSubscription.unsubscribe();
	}

	onContentAttributesSelect = (content: ContentAttributes[], type: string) => {
		let generalContent = this.props.value;
		generalContent[type] = content;
		this.props.onChange(generalContent);
	};

	toggleContentLoadingState(isLoading: boolean) {
		this.setState({
			...this.state,
			isContentLoading: isLoading,
		});
	}

	addDefaultValues = () => {
		const { statuses, contentType, types, commentsPolicies } = this.props;
		let generalContent = this.props.value;

		generalContent.status = extractDefaultValues(statuses[`${contentType}Statuses`]);
		generalContent.type = extractDefaultValues(types[`${contentType}Types`]);
		generalContent.policy = extractDefaultContentAttributesValue(commentsPolicies[`${contentType}CommentsPolicies`]);
		this.props.onChange(generalContent);
	};

	render() {
		const { t, value, contentType, types, statuses, commentsPolicies } = this.props;

		return (
			<div id='sidebar-general-attributes' className={`${this.state.isContentLoading ? 'loading-overlay' : ''}`}>
				{types && types[`${contentType}Types`] && types[`${contentType}Types`].length > 0 && (
					<Row>
						<Col>
							<FormGroup key={'formgroup-types-key'}>
								<Label>{t('type')}</Label>
								<TypesSelect
									t={t}
									isClearable
									onTypeSelect={this.onTypeChange}
									types={types[`${contentType}Types`]}
									selectedType={value.type ? value.type : ''}
								/>
							</FormGroup>
						</Col>
					</Row>
				)}
				{statuses && statuses[`${contentType}Statuses`] && statuses[`${contentType}Statuses`].length > 0 && (
					<Row>
						<Col>
							<FormGroup key={'formgroup-select-status-key'}>
								<Label htmlFor='title'>{t('status')}</Label>
								<StatusSelect
									isClearable
									contentType={contentType}
									selectedStatus={value.status ? value.status : ''}
									statuses={statuses && statuses[`${contentType}Statuses`]}
									onStatusSelect={this.onStatusChange}
									t={t}
								/>
							</FormGroup>
						</Col>
					</Row>
				)}
				{commentsPolicies &&
					commentsPolicies[`${contentType}CommentsPolicies`] &&
					commentsPolicies[`${contentType}CommentsPolicies`].length > 0 && (
						<Row>
							<Col>
								<ContentAttributesSelectContainer
									isMulti={false}
									isClearable
									label={t('comments-policies')}
									placeholder={t('select_comments_policies')}
									contentAttributes={commentsPolicies[`${contentType}CommentsPolicies`]}
									selectedAttributes={value.policy}
									onContentAttributesSelect={this.onContentAttributesSelect}
									type='policy'
								/>
							</Col>
						</Row>
					)}
			</div>
		);
	}
}

export default withTranslation()(GeneralContentTypeAttribute) as React.ComponentType<RequiredProperties>;
