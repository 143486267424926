import { VideoUrlsJson } from './video-urls.json';
import Urls from './video-urls';
import VideoUrls from './video-urls';

export default class VideoUrlsBuilder {
	private json: VideoUrlsJson;

	constructor(videoUrls?: Urls | VideoUrlsJson) {
		if (videoUrls && videoUrls instanceof Urls) {
			this.json = videoUrls.toJSON();
		} else if (videoUrls) {
			this.json = videoUrls;
		} else {
			this.json = {} as VideoUrlsJson;
		}
	}

	withType(type: string): VideoUrlsBuilder {
		this.json.type = type;

		return this;
	}

	withUrl(url: string): VideoUrlsBuilder {
		this.json.url = url;

		return this;
	}

	build(): VideoUrls {
		return VideoUrls.fromJSON(this.json);
	}
}
