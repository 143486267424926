import React from 'react';
import Select from 'react-select';
import HttpService from '../../../../../services/rest/HttpService';
import { Label } from 'reactstrap';
import MatchModel from './models/match.model';
import ErrorHandler from '../error/error-handler-component';
import { Properties, State, RequiredProperties } from './properties/match-select.properties';
import * as helpers from './helpers/match-select.helper';

class MatchSelect extends React.Component<Properties, State> {
	constructor(props: any) {
		super(props);
		this.state = {
			selectedMatch: props.value ? props.value : {},
			matchList: [MatchModel.builder().build()],
		};
	}

	componentDidMount() {
		if (this.props.teamId) {
			this.getMatchesByTeamId(this.props.teamId, true);
		}
	}

	componentDidUpdate(prevProps: any) {
		if (this.props.teamId !== prevProps.teamId) {
			this.getMatchesByTeamId(this.props.teamId, false);
		}
	}

	getMatchesByTeamId(teamId: string, ignoreFirstSelect: boolean) {
		const autoSelectFirstMatch = !this.props.autoSelectFirstMatch || ignoreFirstSelect === true ? false : this.props.autoSelectFirstMatch;

		if (teamId !== null && teamId !== undefined) {
			HttpService.getMatchesFromCurrentDateByTeamId(teamId)
				.then((response: any) => {
					const { maxMatches } = this.props;
					const matchList = helpers
						.getLatestNumberOfMatches(response.data, maxMatches)
						.map((item: any) =>
							MatchModel.builder()
								.withId(item.id)
								.withStartTime(item.start_time)
								.withHomeTeam(item.home_team)
								.withAwayTeam(item.away_team)
								.build(),
						);

					if (matchList && matchList[0] && matchList[0].id && (!ignoreFirstSelect || autoSelectFirstMatch)) {
						this.setState({
							...this.state,
							selectedMatch: matchList[0],
						});

						if (this.props.onSelect) {
							this.props.onSelect(matchList[0]);
						}
					}

					if (matchList && matchList.length === 0) {
						this.setState({
							...this.state,
							selectedMatch: MatchModel.builder().build(),
						});

						if (this.props.onSelect) {
							this.props.onSelect(MatchModel.builder().build());
						}
					}
					this.updateMatchListState(matchList);
				})
				.catch((e: any) => e);
		}
	}

	updateMatchListState(matchList: MatchModel[]) {
		this.setState({ ...this.state, matchList });
	}

	updateSelectedMatch(match: MatchModel) {
		this.setState({ ...this.state, selectedMatch: match });
	}

	displayLabel(display: boolean, text: string) {
		if (display !== undefined && !display) {
			return null;
		}

		if (display && text) {
			return <Label>{text}</Label>;
		}

		return <Label>{this.props.t('match')}:</Label>;
	}

	render() {
		const { matchList, selectedMatch } = this.state;
		const { onSelect, teamId, withLabel, labelText } = this.props;

		if (teamId === undefined || teamId === null) {
			return null;
		}

		return (
			<>
				{this.displayLabel(withLabel, labelText)}
				<Select
					options={helpers.matchListToOptionList(matchList)}
					className='mb-2'
					value={helpers.matchToOption(selectedMatch)}
					noOptionsMessage={(inputValue) => inputValue && this.props.t('no_options')}
					onChange={(selection: any) => {
						this.updateSelectedMatch(selection.data);

						if (onSelect) {
							onSelect(selection.data);
						}
					}}
				/>
				{!this.props.isValid && !selectedMatch.id && <ErrorHandler errorMessage='field_is_required' />}
			</>
		);
	}
}

export default MatchSelect as React.ComponentType<RequiredProperties>;
