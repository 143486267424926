import * as React from 'react';
import { Button, Input, Label, FormGroup } from 'reactstrap';
import { SortableHandle } from 'react-sortable-hoc';
import { Properties, State } from '../properties/sortable-source.properties';
import ErrorAsync from '../../../../Partials/BaseComponents/ErrorComponent/ErrorAsync';
import Source from '../../../../../models/feed/sources/source';
import HttpService from '../../../../../services/rest/HttpService';
import { socialTypes } from '../../../../Partials/social-feed-selection-sidebar/helpers/dropdown-feed.helper';

const DragHandle = SortableHandle(() => (
	<span className={'cursor-draggable'}>
		<i className={'fa fa-bars'}></i>
	</span>
));

class SortableSource extends React.Component<Properties, State> {
	constructor(props: Properties) {
		super(props);
		this.state = {
			source: props.source,
			error: null,
			isDisabledSource: props.source.location ? true : false,
		};
	}

	componentDidUpdate(prevProps: Readonly<Properties>) {
		if (prevProps.source.name !== this.props.source.name) {
			this.setState({ ...this.state, source: this.props.source });
		}
	}

	onLocationChange = (event: any) => {
		this.setState({
			...this.state,
			source: Source.builder(this.state.source).withName('').withLocation(event.target.value).build(),
		});
	};

	onChangeSource = () => {
		this.setState({
			...this.state,
			isDisabledSource: false,
		});
	};

	requestContentData = (source: Source, itemIndex: number) => {
		const { profileId } = this.props;
		let headers = { 'X-Project': this.props.project, 'X-USER': profileId };
		let params = { location: source.location };

		HttpService.postSource(`/sources/resolve`, params, headers)
			.then((response: any) => {
				const content = response.data.data;
				let sourceContent = Source.builder(this.state.source)
					.withId(content.id)
					.withName(this.state.source.name ? this.state.source.name : content.name)
					.withType(content.type)
					.withSubtype(content.subtype)
					.withLocation(content.location)
					.withThumbnail(content.thumbnail)
					.build();
				this.onSaveSource(sourceContent, itemIndex);
			})
			.catch((error: any) => {
				this.setState({
					error: { hasError: true, errors: error.response.data.errors },
				});
			});
	};

	onNameChange = (event: any) => {
		this.setState({
			...this.state,
			source: Source.builder(this.state.source).withName(event.target.value).build(),
		});
	};

	onSaveSource = (sourceContent: Source, itemIndex: number) => {
		this.props.onSaveSource(sourceContent, itemIndex);
		this.setState({
			...this.state,
			source: sourceContent,
			isDisabledSource: true,
		});
	};

	render(): JSX.Element {
		const { source, t, itemIndex } = this.props;

		return (
			<li className='list-group-item p-2 pl-3 pr-3 transparent px-0 bg-'>
				<div className='d-flex align-items-center source-container'>
					<div className='d-flex align-items-center'>
						<div className='mr-3'>
							<DragHandle />
						</div>
						<div title={source.name} className={`source-thumbnail border source-feed-thumbnail`}>
							{source.thumbnail && <img className='rounded-circle' alt={source.name} src={source.thumbnail} width='40' />}
							{!source.thumbnail &&
								source.name &&
								// @ts-ignore
								source.name
									.match(/\b(\w)/g)
									.join('')
									.toUpperCase()}
							{source.type && (
								<span className='type shadow'>
									<i className={`fa fa-${socialTypes[source.type.toLowerCase()]}`} />
								</span>
							)}
						</div>
					</div>
					<div className='d-flex justify-content-between align-items-center w-100'>
						<div className='d-flex flex-row w-100 form-group-container'>
							<FormGroup key={'formgroup-select-location-key'} className='w-50 mr-2'>
								<Label htmlFor='location'>{t('location')}</Label>
								<Input
									disabled={source.location ? this.state.isDisabledSource : false}
									type='text'
									value={this.state.source.location ? this.state.source.location : ''}
									className='form-control pl-2 mr-1'
									onChange={(event: any) => this.onLocationChange(event)}
								/>

								<ErrorAsync
									customStyle='text-danger position-absolute'
									t={t}
									errorType='location'
									customError={this.state.error}
									sources={`sources.${itemIndex}.`}
								/>
							</FormGroup>
							<FormGroup key={'formgroup-select-name-key'} className='w-50 mr-2'>
								<Label htmlFor='name'>{t('name')}</Label>
								<Input
									disabled={source.location ? this.state.isDisabledSource : true}
									type='text'
									value={this.state.source.name ? this.state.source.name : ''}
									className='form-control pl-2 mr-1'
									onChange={(event: any) => this.onNameChange(event)}
								/>
							</FormGroup>
						</div>
					</div>

					<div className='d-flex align-items-center'>
						{(!source.location || !this.state.isDisabledSource) && (
							<Button
								id={`source-save-btn-${itemIndex}`}
								size='sm'
								color='primary'
								className='save-btn mt-1 source-save-btn'
								onClick={() => this.requestContentData(this.state.source, itemIndex)}
							>
								<i className='fa fa-floppy-o'></i>
							</Button>
						)}
						{source.location && this.state.isDisabledSource && (
							<Button
								id={`feed-edit-btn-${itemIndex}`}
								size='sm'
								color='warning'
								className='edit-btn mt-1 source-edit-btn'
								onClick={() => this.onChangeSource()}
							>
								<i className='fa fa-pencil'></i>
							</Button>
						)}
						<Button
							color='danger'
							id={`feed-delete-btn-${itemIndex}`}
							size='sm'
							className='delete-btn mt-1 source-delete-btn'
							onClick={() => this.props.onDeleteContent(itemIndex)}
						>
							<span>
								<i className='fa fa-trash'></i>
							</span>
						</Button>
					</div>
				</div>
			</li>
		);
	}
}

export default SortableSource;
