import { USER_LOGOUT } from '../action-creators/ProfileActionCreators';
import PaginationMeta from '../../models/pagination/PaginationMeta';
import {
	PLAYER_LISTING_RECEIVED,
	PLAYER_ENTITY_RECEIVED,
	SEARCH_PLAYER_LISTING_RECEIVED,
	PLAYER_BLACKLIST_RECEIVED,
} from '../action-creators/player-action-creator';
import PlayerManualDataModel from '../../models/player-manual-data/player-manual-data.model';
import { PLAYER_SEARCH_FILTER_UPDATE } from '../action-creators/player-action-creator';

interface InitialState {
	players: PlayerManualDataModel[];
	pagination: PaginationMeta;
	playerDeleted: boolean;
	player: PlayerManualDataModel;
	searchFilter: string;
}
const initialState: InitialState = {
	players: [],
	player: PlayerManualDataModel.builder().build(),
	playerDeleted: false,
	pagination: PaginationMeta.builder().build(),
	searchFilter: '',
};

function playerReducer(state: any = initialState, action: any) {
	if (action.type === PLAYER_LISTING_RECEIVED) {
		return Object.assign({}, state, {
			players: action.payload.players,
			pagination: action.payload.contentPagination,
		});
	}

	if (action.type === PLAYER_BLACKLIST_RECEIVED) {
		return Object.assign({}, state, {
			players: action.payload,
		});
	}

	if (action.type === SEARCH_PLAYER_LISTING_RECEIVED) {
		return Object.assign({}, state, {
			players: action.payload.data,
		});
	}

	if (action.type === PLAYER_ENTITY_RECEIVED) {
		return Object.assign({}, state, {
			player: action.payload.player,
		});
	}

	if (action.type === PLAYER_SEARCH_FILTER_UPDATE) {
		return Object.assign({}, state, {
			searchFilter: action.payload,
		});
	}

	if (action.type === USER_LOGOUT) {
		state = initialState;
	}

	return state;
}

export default playerReducer;
