import { FeedRequestJson } from './feed-request-json';
import Feed from './feed';
import { FeedJson } from './feed.json';
import Source from './sources/source';

export default class FeedBuilder {
	private json: FeedJson;

	constructor(feed?: Feed | FeedJson) {
		if (feed && feed instanceof Feed) {
			this.json = feed.toJson();
		} else if (feed) {
			this.json = feed;
		} else {
			this.json = {} as FeedJson;
		}
	}

	withId(id: string): FeedBuilder {
		this.json.id = id;

		return this;
	}

	withName(name: string): FeedBuilder {
		this.json.name = name;

		return this;
	}

	withActive(active: boolean): FeedBuilder {
		this.json.active = active;

		return this;
	}

	withSource(sources: Source[]): FeedBuilder {
		this.json.sources = sources;

		return this;
	}

	toRequestJson(): FeedRequestJson {
		let json = {} as FeedRequestJson;
		json.id = this.json.id;
		json.name = this.json.name;
		json.active = this.json.active;
		json.sources = this.json.sources
			? this.json.sources.map((source: Source) => {
					return {
						id: source.id,
						location: source.location,
						name: source.name,
						type: source.type,
						subtype: source.subtype,
						thumbnail: source.thumbnail,
					};
			  })
			: [];

		return json;
	}

	build(): Feed {
		return Feed.fromJson(this.json);
	}
}
