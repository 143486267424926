import React from 'react';
import { store } from '../../../../store/store';
import { isPermitted, PermissionTypes } from './helpers/ConditionalRenderPermissionsHelper';
import { ContentTypes } from '../../../../constants/content-types';

export type Properties = {
	expectedPermissions: string[];
	contentType?: ContentTypes;
	isChildEditButton?: boolean;
	articleId?: string;
};

// Service Context created
export const ConditionalRenderContext = React.createContext({
	hasReadPermission: false,
	hasWritePermission: false,
	hasDeletePermission: false,
});

export default class ConditionalRenderWrapper extends React.Component<Properties, {}> {
	render() {
		const { expectedPermissions } = this.props;
		const permissions = store.getState().profile.profile.permissions;

		return (
			<ConditionalRenderContext.Provider
				value={{
					hasReadPermission: isPermitted(expectedPermissions, permissions, PermissionTypes.READ),
					hasWritePermission: isPermitted(expectedPermissions, permissions, PermissionTypes.WRITE),
					hasDeletePermission: isPermitted(expectedPermissions, permissions, PermissionTypes.DELETE),
				}}
			>
				{this.props.children}
			</ConditionalRenderContext.Provider>
		);
	}
}
