import 'reflect-metadata';
import { AnyT, jsonArrayMember, jsonMember, jsonObject } from 'typedjson';
import CategoryModel from '../../../../models/v2/category/category.model';
import AuthorModel from '../../../../models/v2/author/author.model';
import ImageModel from '../../../../models/v2/image/image.model';
import TeamModel from '../../../../models/v2/Team/Entity/TeamModel';
import MainMediaModel from '../../../../models/v2/main-media/main-media.model';

@jsonObject
export default class AdditionalInfoModel {
	@jsonMember(String)
	public description: string = '';
}
