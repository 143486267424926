import BlockModel from '../../../../../models/block.model';
import { ViewTypes } from '../../../../../constants/general.constants';
import React from 'react';
import BlockUpdateController from '../../../../block-update-controller.component';
import { compose } from 'redux';
import { WidgetEitherOrGameManagerView } from './either-or-games-manager-view.component';
import EitherOrGameManagerEditComponent from './either-or-game-manager-edit.component';

type Properties = {
	block: BlockModel;
	view: ViewTypes;
};

const EitherOrGamesManager: React.FunctionComponent<Properties> = (props) => {
	if (props.view === ViewTypes.edit) {
		return <EitherOrGameManagerEditComponent block={props.block} />;
	}

	if (props.view === ViewTypes.normal) {
		return <WidgetEitherOrGameManagerView widgetEitherOrGameManager={props.block.data.widget} />;
	}

	return null;
};

export default compose(BlockUpdateController)(EitherOrGamesManager);
