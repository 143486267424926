import 'reflect-metadata';
import { AnyT, jsonArrayMember, jsonMember, jsonObject } from 'typedjson';
import { QuizImages } from './fans-united-classic-quiz.model';

@jsonObject
export class Points {
	@jsonMember(Number)
	public correctSteps: number | null = null;
	@jsonMember(Number)
	public score: number | null = null;
}

@jsonObject
export default class FansUnitedEitherOrModel {
	@jsonMember(String)
	public id: string | null = null;
	@jsonMember(String)
	public title: string | null = null;
	@jsonMember(String)
	public description: string | null = null;
	@jsonMember(String)
	public type: string | null = null;
	@jsonMember(String)
	public status: string | null = null;
	@jsonMember(QuizImages)
	public images: QuizImages = new QuizImages();
	@jsonMember(Object)
	public labels: any = {};
	@jsonArrayMember(Points)
	public points: Points[] | null = null;
	@jsonMember(Number)
	public lives: number | null = null;
	@jsonMember(String)
	public winning_condition: string | null = null;
	@jsonMember(Object)
	public custom_fields: any = {};
	@jsonMember(String)
	public created_at: string = '';
	@jsonMember(String)
	public updated_at: string = '';
	@jsonMember(Boolean)
	public isSelected: boolean = false;
}
