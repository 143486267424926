import React from 'react';
import { Col, FormGroup, Row, Input, Label, FormFeedback } from 'reactstrap';
import StageGroupsSelectContainer from '../../../../partials/stage-groups-select/stage-groups-select-container.component';
import StandingsWidgetModel from '../models/standings-widget.model';
import { blockWrapperService, blockManagementService } from '../../../../subcomponents/blocky.component';
import { Properties, State } from './properties/standings-widget-edit.properties';
import TournamentModel from '../../player-profile/models/tournament-model/tournament.model';
import StageModel from '../../fixtures/models/stage/stage.model';
import TournamentSelectContainer from '../../../../partials/tournament-select/tournament-select-container.component';
import SeasonSelectContainer from '../../../../partials/season-select/season-select-container.component';
import StageSelectContainer from '../../../../partials/stage-select/stage-select-container.component';
import SeasonModel from '../../player-profile/models/season/season.model';
import BlockValidation from '../../../../helpers/block-validation.helper';
import ErrorHandler from '../../../../partials/error/error-handler-component';
import StandingsTeamsSelectContainer from '../../../../partials/standings-team-select/standings-team-select-container.component';
import TeamModel from '../../../../partials/team-select/models/team.model';
import OffsetInput from '../../../../partials/offset-input/offset-input.component';
import { extractStangingFromGroup } from './helpers/standings-view.helper';
import GroupModel from '../../../../partials/stage-groups-select/models/group.model';
import ThreeLetterCodeTypeSelect from '../../../../partials/three-letter-code-type-select/three-letter-code-type-select';

export default class StandingsEdit extends React.Component<Properties, State> {
	constructor(props: Properties) {
		super(props);
		this.state = {
			standings:
				props.block && props.block.data.preview && props.block.data.preview.standings
					? StandingsWidgetModel.builder(props.block.data.preview.standings).build()
					: StandingsWidgetModel.builder().withStandings([]).withGroup([]).withHighlightTeams([]).withTeam(TeamModel.builder().build()).build(),
			isValid: true,
		};
	}

	componentDidMount(): void {
		blockWrapperService.registerOnSaveFunc({ blockId: this.props.block.id, func: this.onSave });
	}

	onSave = (index: number) => {
		const validation = new BlockValidation();
		const data = { standings: this.state.standings };
		const isValid = validation.validate(this.props.block, data);

		this.setState({ ...this.state, isValid });

		if (isValid) {
			const block = Object.assign({}, this.props.block);
			block.data.preview = { standings: this.state.standings };
			blockManagementService.blockUpdate(block, index);
		} else {
			return isValid;
		}
	};

	componentWillUnmount(): void {
		blockWrapperService.unregisterOnSaveFunc(this.props.block.id);
	}

	updateWidgetModelState = (model: StandingsWidgetModel) => {
		this.setState({ ...this.state, standings: model });
	};

	onTournamentSelect = (tournament: TournamentModel) => {
		this.updateWidgetModelState(
			StandingsWidgetModel.builder(this.state.standings)
				.withTournament(TournamentModel.builder(tournament).build())
				.withSeason(SeasonModel.builder().build())
				.withHighlightTeams([])
				.withGroup([])
				.withTeam(TeamModel.builder().build())
				.withOffset('')
				.withDisplayLastMatchesResults(false)
				.build(),
		);
	};

	onSeasonSelect = (season: SeasonModel) => {
		this.updateWidgetModelState(
			StandingsWidgetModel.builder(this.state.standings)
				.withSeason(season)
				.withStage(StageModel.builder().build())
				.withHighlightTeams([])
				.withTeam(TeamModel.builder().build())
				.withGroup([])
				.withOffset('')
				.build(),
		);
	};

	onStageSelect = (stage: any) => {
		this.updateWidgetModelState(
			StandingsWidgetModel.builder(this.state.standings)
				.withHighlightTeams([])
				.withTeam(TeamModel.builder().build())
				.withOffset('')
				.withStage(StageModel.builder().withId(stage.id).withName(stage.name).build())
				.withStandings(stage.standing)
				.build(),
		);
	};

	onStageGroupSelect = (groups: GroupModel[]) => {
		groups && groups.length > 0
			? this.updateWidgetModelState(
					StandingsWidgetModel.builder(this.state.standings).withGroup(groups).withStandings(extractStangingFromGroup(groups)).build(),
			  )
			: this.updateWidgetModelState(StandingsWidgetModel.builder(this.state.standings).withGroup(groups).build());
	};

	onLimitChange = (limit: string) => {
		this.updateWidgetModelState(StandingsWidgetModel.builder(this.state.standings).withLimit(limit).build());
	};

	onStartPositionChange = (startFrom: string) => {
		this.updateWidgetModelState(StandingsWidgetModel.builder(this.state.standings).withStartFrom(startFrom).build());
	};

	onHighlightTeamsOptions = (teams: any[]) => {
		this.updateWidgetModelState(StandingsWidgetModel.builder(this.state.standings).withHighlightTeams(teams).build());
	};

	onTeamChange = (team: TeamModel) => {
		this.updateWidgetModelState(StandingsWidgetModel.builder(this.state.standings).withTeam(team).withStartFrom('').withLimit('').build());
	};

	onOffsetChange = (offset: string) => {
		this.updateWidgetModelState(
			StandingsWidgetModel.builder(this.state.standings).withOffset(offset).withStartFrom('').withLimit('').build(),
		);
	};

	onDisplayLastMatchesResultChange = (e: any) => {
		this.updateWidgetModelState(StandingsWidgetModel.builder(this.state.standings).withDisplayLastMatchesResults(e.target.checked).build());
	};

	onThreeLetterCodeTypeChange = (value: any) => {
		this.updateWidgetModelState(StandingsWidgetModel.builder(this.state.standings).withThreeLetterCodeType(value).build());
	};

	render() {
		const { standings, isValid } = this.state;
		const placeholder =
			standings.stageStandings && standings.stageStandings.length
				? `${this.props.t('from')} 1 ${this.props.t('to')} ${standings.stageStandings.length}`
				: `1, 4, 7, ${this.props.t('etc')}`;

		const hasStage = standings.tournament && standings.season && standings.stage;

		return (
			<>
				<Row>
					<Col>
						<FormGroup>
							<TournamentSelectContainer
								withLabel
								isBlocky
								isValid={isValid}
								selectedTournament={standings.tournament}
								onTournamentSelect={this.onTournamentSelect}
								t={this.props.t}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					<Col>
						<FormGroup>
							<SeasonSelectContainer
								withLabel
								isValid={isValid}
								selectedSeason={standings.season}
								tournamentId={standings.tournament && standings.tournament.id ? standings.tournament.id : ''}
								onSeasonSelect={this.onSeasonSelect}
								t={this.props.t}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					<Col>
						<FormGroup>
							<StageSelectContainer
								withLabel
								selectedStage={standings.stage}
								seasonId={standings.season && standings.season.id ? standings.season.id : ''}
								onStageSelect={this.onStageSelect}
								t={this.props.t}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					<Col>
						<FormGroup>
							<StageGroupsSelectContainer
								withLabel
								isMulti
								t={this.props.t}
								selectedStageGroups={hasStage && standings.group ? standings.group : []}
								stageId={hasStage ? standings.stage.id : ''}
								onStageGroupSelect={this.onStageGroupSelect}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					<Col>
						<FormGroup>
							<StandingsTeamsSelectContainer
								stageId={hasStage ? standings.stage.id : ''}
								value={this.state.standings.highlightTeams}
								onTeamsSelect={this.onHighlightTeamsOptions}
								t={this.props.t}
								isMulti={true}
								standings={this.state.standings}
							/>
						</FormGroup>
					</Col>
				</Row>
				{!isValid && (!standings.stageStandings || (standings.stageStandings && standings.stageStandings.length === 0)) && (
					<div className='pb-2'>
						<ErrorHandler errorMessage='no_available_teams' />
					</div>
				)}
				<ThreeLetterCodeTypeSelect
					t={this.props.t}
					isClearable={true}
					onThreeLetterCodeTypeChange={this.onThreeLetterCodeTypeChange}
					selectedThreeLetterCodeType={standings.threeLetterCodeType}
				/>
				{(!standings.group || (standings.group && standings.group.length === 0)) &&
					standings.stageStandings &&
					standings.stageStandings.length > 0 && (
						<>
							<Row className='mb-1'>
								<Col>
									<hr />
									<h6>{this.props.t('preview_details')}</h6>
								</Col>
							</Row>
							<Row>
								<Col>
									<FormGroup>
										<Label>{this.props.t('start_from_position')}:</Label>
										<Input
											type='number'
											disabled={(standings.team && !!standings.team.id) || !!standings.offset}
											className='form-control'
											value={standings.startFrom ? standings.startFrom : ''}
											min={1}
											placeholder={placeholder}
											max={standings.stageStandings && standings.stageStandings.length}
											onChange={(event: any) => this.onStartPositionChange(event.target.value)}
											invalid={standings.stageStandings && standings.stageStandings.length < standings.startFrom}
										/>
										<FormFeedback>
											{this.props.t('must_be_less_than')} {standings.stageStandings && standings.stageStandings.length + 1}
										</FormFeedback>
									</FormGroup>
								</Col>
								<Col>
									<FormGroup>
										<Label>{this.props.t('show')}:</Label>
										<Input
											type='number'
											disabled={(standings.team && !!standings.team.id) || !!standings.offset}
											className='form-control'
											value={standings.limit ? standings.limit : ''}
											onChange={(event: any) => this.onLimitChange(event.target.value)}
											placeholder={this.props.t('teams_in_standings')}
										/>
									</FormGroup>
								</Col>
							</Row>
							<Row className='mb-2'>
								<Col>
									<StandingsTeamsSelectContainer
										stageId={hasStage ? standings.stage.id : ''}
										value={this.state.standings.team}
										onTeamsSelect={this.onTeamChange}
										t={this.props.t}
										isMulti={false}
										standings={this.state.standings}
									/>
								</Col>
								<Col>
									<OffsetInput
										onOffsetChange={this.onOffsetChange}
										value={this.state.standings.offset}
										item={this.state.standings && this.state.standings.team}
										type='standings'
									/>
								</Col>
							</Row>
							<Row className='mb-2'>
								<Col>
									<FormGroup key='display-last-matches-results' check className='radio'>
										<Input
											key='display-last-matches-results-input'
											className='form-check-input'
											type='checkbox'
											id={`display-last-matches-results-checkbox-${this.props.block.id}`}
											name='radios'
											onChange={this.onDisplayLastMatchesResultChange}
											checked={standings.displayLastMatchesResults}
										/>
										<Label
											key='display-last-matches-results-label'
											check
											className='form-check-label'
											for={`display-last-matches-results-checkbox-${this.props.block.id}`}
										>
											{this.props.t('display_form')}
										</Label>
									</FormGroup>
								</Col>
							</Row>
						</>
					)}
			</>
		);
	}
}
