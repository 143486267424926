import { TotalsJson } from './totals.json';
import TotalsBuilder from './totals.builder';

export default class Totals {
	readonly images: number;
	readonly videos: number;
	readonly galleries: number;
	readonly articles: number;

	private constructor(images: number, videos: number, galleries: number, articles: number) {
		this.images = images;
		this.videos = videos;
		this.galleries = galleries;
		this.articles = articles;
	}

	toJSON(): TotalsJson {
		return {
			images: this.images,
			videos: this.videos,
			galleries: this.galleries,
			articles: this.articles,
		};
	}

	static fromJSON(json: TotalsJson): Totals {
		return new Totals(json.images, json.videos, json.galleries, json.articles);
	}

	static builder(totals?: Totals): TotalsBuilder {
		return new TotalsBuilder(totals);
	}
}
