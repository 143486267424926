import { PlayerH2HJson } from './player-h2h.json';
import PlayerH2hBuilder from './player-h2h.builder';
import PlayerTeamModel from '../../../../partials/team-players-select/models/team-player.model';

export default class PlayerH2hModel {
	readonly player1: PlayerTeamModel;
	readonly player2: PlayerTeamModel;
	readonly displayOdds: boolean = true;
	readonly player1Seasons: any[] = [];
	readonly player2Seasons: any[] = [];
	readonly threeLetterCodeType: any = '';

	private constructor(
		player1: PlayerTeamModel,
		player2: PlayerTeamModel,
		player1Seasons: any[],
		player2Seasons: any[],
		displayOdds: boolean,
		threeLetterCodeType: any,
	) {
		this.player1 = player1;
		this.player2 = player2;
		this.player1Seasons = player1Seasons;
		this.player2Seasons = player2Seasons;
		this.displayOdds = displayOdds;
		this.threeLetterCodeType = threeLetterCodeType;
	}

	toJson(): PlayerH2HJson {
		return {
			player1: this.player1,
			player2: this.player2,
			player1Seasons: this.player1Seasons,
			player2Seasons: this.player2Seasons,
			displayOdds: this.displayOdds,
			threeLetterCodeType: this.threeLetterCodeType,
		};
	}

	static fromJson(json: PlayerH2HJson): PlayerH2hModel {
		return new PlayerH2hModel(
			json.player1,
			json.player2,
			json.player1Seasons,
			json.player2Seasons,
			json.displayOdds,
			json.threeLetterCodeType,
		);
	}

	static builder(playerH2H?: PlayerH2hModel): PlayerH2hBuilder {
		return new PlayerH2hBuilder(playerH2H);
	}
}
