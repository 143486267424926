import React from 'react';
import { Col, Row } from 'reactstrap';
import TennisPlayoffModel from './models/tennis-playoff.model';
import { generateRefreshTimeOptionViewText } from '../../partials/refresh-time-input/helper';

type Properties = {
	preview: TennisPlayoffModel;
	t: any;
};

export const TennisPlayoffViewComponent: React.FunctionComponent<Properties> = ({ preview, t }) => {
	if (preview && preview.competition && preview.competition.id) {
		return (
			<>
				<Row className='px-4' data-qa='tennis-playoff-view-component'>
					<Col className='text-left'>
						<Row className='mb-2'>
							<Col>
								<div>
									<strong>{t('competition')}: </strong>
									{preview.competition.name} {preview.season && preview.season.value && `- ${preview.season.value}`}
									{`${preview.stage && preview.stage.name ? `(${preview.stage.name})` : ''}`}
								</div>
							</Col>
						</Row>
					</Col>
				</Row>
				{generateRefreshTimeOptionViewText(preview.refreshTime)}
			</>
		);
	}

	return null;
};
