import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { ViewTypes } from '../../../../constants/general.constants';
import BlockModel from '../../../../models/block.model';
import PlayerCareerEdit from './player-career-edit.component';
import { PlayerCareerView } from './player-career-view.component';
import Project from '../../../../../../../models/project/Project';
import { WidgetPreview } from '../../widget-preview/widget-preview.component';
import BlockUpdateController from '../../../block-update-controller.component';

type Properties = {
	block: BlockModel;
	view: ViewTypes;
	t: any;
	lang: string;
	currentProject: Project;
};

class PlayerCareer extends React.Component<Properties> {
	render() {
		const { view, t, block } = this.props;

		if (view === ViewTypes.edit) {
			return <PlayerCareerEdit block={block} t={t} currentProject={this.props.currentProject} />;
		}

		if (view === ViewTypes.preview) {
			return <WidgetPreview blockContent={this.props.block.data.content} config={this.props.block.data.config} />;
		}

		if (view === ViewTypes.normal) {
			return <PlayerCareerView t={this.props.t} block={this.props.block.data.preview} />;
		}

		return null;
	}
}

function mapStateToProps(state: any) {
	return {
		lang: state.profile.profile.language,
		currentProject: state.project.currentProject,
	};
}

export default compose(connect(mapStateToProps, null), BlockUpdateController)(PlayerCareer);
