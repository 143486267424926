import Related from '../../../../../../../models/related/Related';
import { assetsPlaceholder } from '../../../../../../../constants/assetsPlaceholder';

export const tournamentRelatedToOption = (related: Related) => {
	let option = {};

	if (related && related.data) {
		return {
			value: related.data.id,
			label: related.data.name,
			logo: related.data.url_thumb
				? related.data.url_thumb
				: related.data.url_image
				? related.data.url_image
				: related.data.url_logo
				? related.data.url_logo
				: assetsPlaceholder[related.data.entity_type],
			type: related.type,
			data: related.data,
		};
	}

	return option;
};
