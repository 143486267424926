import * as React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import '../styles/team.style.scss';

type SocialProps = {
	t: any;
	onChange: (e: any) => void;
	value: string;
	type: string;
};

const Social: React.FunctionComponent<SocialProps> = ({ onChange, t, value, type }) => {
	return (
		<FormGroup className='w-50'>
			<Label htmlFor={`manual-data-entry-social-${type}`}>{t(type)}</Label>
			<div className='w-100 d-flex position-relative'>
				<i className={`py-1 fa fa-lg fa-${type} team-social-icons`} />
				<Input
					type='text'
					id={`manual-data-entry-social-${type}`}
					value={value}
					onChange={onChange}
					className='form-control flex-fill pl-5 mr-2'
					placeholder={t(`${type}_placeholder`)}
				/>
			</div>
		</FormGroup>
	);
};

export default Social;
