import BlacklistModel from '../blacklist/blacklist';
import AssetsModel from '../team-manual-data/assets/assets';
import { CoachManualDataJson } from './coach-manual-data.json';
import CoachManualDataBuilder from './coach-manual-data.builder';

export default class CoachManualDataModel {
	readonly id: string;
	readonly name: string;
	readonly country: any;
	readonly birthdate: string;
	readonly blacklist: BlacklistModel;
	readonly assets: AssetsModel;
	readonly url_image: string;
	readonly url_thumb: string;

	private constructor(
		id: string,
		name: string,
		country: any,
		birthdate: string,
		blacklist: BlacklistModel,
		assets: AssetsModel,
		url_image: string,
		url_thumb: string,
	) {
		this.id = id;
		this.name = name;
		this.country = country;
		this.birthdate = birthdate;
		this.blacklist = blacklist;
		this.assets = assets;
		this.url_thumb = url_thumb;
		this.url_image = url_image;
	}

	toJSON(): CoachManualDataJson {
		return {
			id: this.id,
			name: this.name,
			country: this.country,
			birthdate: this.birthdate,
			blacklist: this.blacklist,
			assets: this.assets,
			url_thumb: this.url_thumb,
			url_image: this.url_image,
		};
	}

	static fromJSON(json: CoachManualDataJson): CoachManualDataModel {
		return new CoachManualDataModel(
			json.id,
			json.name,
			json.country,
			json.birthdate,
			json.blacklist,
			json.assets,
			json.url_thumb,
			json.url_image,
		);
	}

	static builder(coach?: CoachManualDataModel): CoachManualDataBuilder {
		return new CoachManualDataBuilder(coach);
	}
}
