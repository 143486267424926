import React from 'react';
import { blockManagementService, blockWrapperService } from '../../../subcomponents/blocky.component';
import BlockValidation from '../../../helpers/block-validation.helper';
import BlockModel from '../../../models/block.model';
import AmgItemDataModel from './models/amg-item-data.model';
import { amgVideosService } from '../../../../../../App';
import { AmgVideoContainer } from './components/amg-video-container.component';

export type Properties = {
	block: BlockModel;
	blocks: BlockModel[];
	t: any;
};

export type State = {
	selectedVideo: AmgItemDataModel;
	isValid: boolean;
	page: number;
};

class AmgVideoEdit extends React.Component<Properties, State> {
	constructor(props: Properties) {
		super(props);
		this.state = {
			selectedVideo:
				props.block && props.block.data && props.block.data.preview ? props.block.data.preview.selectedVideo : ({} as AmgItemDataModel),
			page: props.block && props.block.data && props.block.data.preview ? props.block.data.preview.page : 1,
			isValid: true,
		};
	}

	componentDidMount(): void {
		blockWrapperService.registerOnSaveFunc({ blockId: this.props.block.id, func: this.onSave });
	}

	componentWillUnmount(): void {
		blockWrapperService.unregisterOnSaveFunc(this.props.block.id);
	}

	onSave = (index: number) => {
		const { selectedVideo } = this.state;
		const data = { amgVideo: selectedVideo };
		const validation = new BlockValidation();
		const isValid = validation.validate(this.props.block, data);
		// @ts-ignore
		this.setState({ ...this.state, isValid });

		if (isValid) {
			const block = Object.assign({}, this.props.block);
			block.data.id = selectedVideo.id;
			block.data.content = amgVideosService.constructAmgVideoContent(selectedVideo);
			block.data.preview = { selectedVideo, page: this.state.page };
			blockManagementService.blockUpdate(block, index);
		} else {
			return isValid;
		}
	};

	selectVideo = (video: AmgItemDataModel) => {
		this.setState({ ...this.state, selectedVideo: video });
	};

	updatePreviewState = (page: number) => {
		this.setState({ ...this.state, page: page });
	};

	render() {
		return (
			<AmgVideoContainer
				isMediaSidebar={false}
				onSelect={this.selectVideo}
				updatePreviewState={this.updatePreviewState}
				selectedVideo={this.state.selectedVideo}
				isValid={this.state.isValid}
				inBlocky={true}
				page={this.state.page}
				t={this.props.t}
			/>
		);
	}
}

export default AmgVideoEdit;
