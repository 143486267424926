import React, { FunctionComponent } from 'react';
import { Alert } from 'reactstrap';
import ListModel from '../../../../../../models/v2/list/list';
import { connect } from 'react-redux';
import { isCreateScreen } from '../../../../../../global-helpers/global.helpers';
import {
	areLockPositionsValid,
	areNumberOfItemsValid,
	lockPositionsAlert,
} from '../../../../../Partials/Sidebar/list-refactored/helpers/alerts.helper';
import { isListEmpty } from '../../../../../Partials/Sidebar/list-refactored/helpers/general.helper';
import { ContentTypes } from '../../../../../../constants/content-types';

type Properties = {
	t: any;
	selectedList: ListModel | null;
};

const LiveBlogListAlerts: FunctionComponent<Properties> = ({ t, selectedList }) => {
	if (!selectedList) {
		return null;
	}
	const selectedListConfiguration = selectedList && selectedList.configuration;

	return (
		<>
			<Alert color='warning' isOpen={isCreateScreen()}>
				{t('save_live_blog_before_adding_it_to_list', {
					contentType: ContentTypes.LIVE_BLOG,
				})}
			</Alert>
			<Alert color='danger' isOpen={!areNumberOfItemsValid(selectedList)}>
				{t('min_max_list_items_error', {
					minItems: (selectedListConfiguration && selectedListConfiguration.minItems) || t('no_limit'),
					maxItems: (selectedListConfiguration && selectedListConfiguration.maxItems) || t('no_limit'),
				})}
			</Alert>
			<Alert color='danger' isOpen={!areLockPositionsValid(selectedList)}>
				{t('locked_list_items_error', {
					lockPosition: lockPositionsAlert(selectedList),
				})}
			</Alert>
		</>
	);
};

function mapStateToProps(state: any) {
	return {
		selectedList: (!isListEmpty(state.list.list) && state.list.list) || null,
	};
}

export default connect(mapStateToProps)(LiveBlogListAlerts);
