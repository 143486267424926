import React from 'react';
import { Col, Row } from 'reactstrap';
import { OddsWidgetView } from '../../odds/components/odds-widget-view.component';

type Properties = {
	block: any;
	t: any;
};

export const TeamProfileView: React.FunctionComponent<Properties> = ({ block, t }) => {
	if (block && block.teamProfile && block.teamProfile.team && block.teamProfile.team.id) {
		return (
			<>
				<Row className={'m-2 ml-4 d-flex align-items-center'}>
					<img src={block.teamProfile.team ? block.teamProfile.team.logo : ''} height='50' className='mx-1' alt='' />
					<h4>
						{block.teamProfile.threeLetterCodeType &&
						block.teamProfile.threeLetterCodeType !== '' &&
						block.teamProfile.team.threeLetterCode !== null
							? block.teamProfile.team.threeLetterCode
							: block.teamProfile.team
							? block.teamProfile.team.name
							: ''}
					</h4>
				</Row>
				<Row className='px-4'>
					<Col>
						<hr></hr>
					</Col>
				</Row>
				{block.teamProfile.displayOdds && (
					<OddsWidgetView
						isStandAlone={false}
						blockPreview={block.odds}
						t={t}
						displayOddsLabel={false}
						displayThreeLetterCode={block.teamProfile.threeLetterCodeType && block.teamProfile.threeLetterCodeType !== '' ? true : false}
					/>
				)}
				<div>
					<Row className={`${!block.teamProfile.displayOdds ? 'px-4 py-2 text-left' : 'pb-4 pl-4 pr-4'}`}>
						<Col className='text-left'>
							{block.teamProfile.threeLetterCodeType && block.teamProfile.threeLetterCodeType !== '' && (
								<Row>
									<Col className='text-left'>
										{t('display_team_three_letter_code')}:
										<strong>
											<span className='ml-2'>
												{block.teamProfile.threeLetterCodeType &&
													block.teamProfile.threeLetterCodeType.name &&
													t(`${block.teamProfile.threeLetterCodeType.name}`)}
											</span>
										</strong>
									</Col>
								</Row>
							)}
							<Row>
								<Col className='text-left'>
									{t('display_odds')}:
									<strong>
										<span className={block.teamProfile.displayOdds ? 'text-success' : 'text-danger'}>
											{block.teamProfile.displayOdds ? ` ${t('yes')}` : ` ${t('no')}`}
										</span>
									</strong>
								</Col>
							</Row>
							<Row>
								<Col className='text-left'>
									{t('display_statistics_for')} <strong>{block.teamProfile.season ? block.teamProfile.season.name : t('no_statistics')}</strong>:
									<strong>
										<span className={block.teamProfile.displayStatistics ? 'text-success' : 'text-danger'}>
											{block.teamProfile.displayStatistics ? ` ${t('yes')}` : ` ${t('no')}`}
										</span>
									</strong>
								</Col>
							</Row>
						</Col>
					</Row>
				</div>
			</>
		);
	}

	return null;
};
