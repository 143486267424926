import { SeoJson } from './SeoJson';
import Seo from './Seo';
import Global from '../../views/Partials/Blocky/helpers/global.helpers';
import { slugifyString } from '../../views/Partials/Sidebar/seo-refactored/helpers/slug.helper';

export default class SeoBuilder {
	private json: SeoJson;

	constructor(seo?: Seo | SeoJson) {
		if (seo && seo instanceof Seo) {
			this.json = seo.toJSON();
		} else if (seo) {
			this.json = seo;
		} else {
			this.json = {} as SeoJson;
		}
	}

	withTitle(title: string): SeoBuilder {
		this.json.title = Global.stripEmoji(title);

		return this;
	}

	withDescription(description: string): SeoBuilder {
		this.json.description = description;

		return this;
	}

	withSlug(slug: string): SeoBuilder {
		if (slug) {
			this.json.slug = slugifyString(slug);
		} else {
			this.json.slug = Global.stripEmoji(slug);
		}

		return this;
	}

	withKeywords(keywords: string[]): SeoBuilder {
		this.json.keywords = keywords;

		return this;
	}

	withIndex(index: boolean): SeoBuilder {
		this.json.index = index;

		return this;
	}

	withFollow(follow: boolean): SeoBuilder {
		this.json.follow = follow;

		return this;
	}

	withRedirectType(redirectType: string): SeoBuilder {
		this.json.redirectType = redirectType;

		return this;
	}

	withAutoSlug(autoSlug: boolean): SeoBuilder {
		this.json.autoSlug = autoSlug;

		return this;
	}

	withAutoTitle(autoTitle: boolean): SeoBuilder {
		this.json.autoTitle = autoTitle;

		return this;
	}

	withJsonLD(jsonld: any): SeoBuilder {
		this.json.jsonld = jsonld;

		return this;
	}

	withAutoUpdateSeo(title: string): SeoBuilder {
		this.json.title = this.json.autoTitle ? Global.stripEmoji(title) : Global.stripEmoji(this.json.title);
		this.json.slug = this.json.autoSlug && this.json.autoTitle ? slugifyString(title) : Global.stripEmoji(this.json.slug);

		return this;
	}

	isEmpty(): boolean {
		if (this.json) {
			const isTitleSet = this.json.title && this.json.title.length > 0;
			const isDescriptionSet = this.json.description && this.json.description.length > 0;
			const isSlugSet = this.json.slug && this.json.slug.length > 0;
			const isKeywordsSet = this.json.keywords && this.json.keywords.length > 0;
			const isIndexSet = this.json.index !== undefined && this.json.index !== null ? !this.json.index : false;
			const isFollowSet = this.json.follow !== undefined && this.json.follow !== null ? !this.json.follow : false;
			const isRedirectSet = this.json.redirectType && this.json.redirectType.length > 0;

			return !(isTitleSet || isDescriptionSet || isSlugSet || isKeywordsSet || isIndexSet || isFollowSet || isRedirectSet);
		}

		return true;
	}

	build(): Seo {
		return Seo.fromJSON(this.json);
	}
}
