import React from 'react';
import { Col, Row } from 'reactstrap';
import { Properties } from './properties/standings-widget-view.properties';
import { createGroups } from './helpers/standings-view.helper';

export const StandingsView: React.FunctionComponent<Properties> = ({ preview, t }) => {
	if (preview && preview.tournament && preview.tournament.id) {
		return (
			<Row className='px-4'>
				<Col className='text-left'>
					<Row className='mb-2'>
						<Col>
							<div>
								<strong>{t('tournament')}:</strong>
								<img className='ml-1 mr-1' width='35px' src={preview.tournament.logo} alt={preview.tournament.name} />
								{preview.tournament.name}, {preview.season.name} ({preview.stage.name})
							</div>
						</Col>
					</Row>
					<Row className='mb-2'>
						<Col>
							{preview.tournament && preview.season && preview.stage && preview.group && preview.group.length > 0 && (
								<p>
									<strong>{t('selected_groups')}:</strong> {createGroups(preview.group)}
								</p>
							)}
						</Col>
					</Row>
					<Row className='mb-2'>
						<Col>
							<strong>{t('highlighted_teams')}:</strong>
							{preview.highlightTeams && preview.highlightTeams.length > 0 ? (
								preview.highlightTeams.map((team, index) => {
									return (
										<span key={`team-standings-key-${index}`}>
											<img className='img-circle ml-2' width='20px' src={team.logo} alt={team.name} />{' '}
											{preview.threeLetterCodeType && preview.threeLetterCodeType !== '' && team.threeLetterCode !== null
												? team.threeLetterCode
												: team.name}
											{index + 1 === preview.highlightTeams.length ? '' : ','}
										</span>
									);
								})
							) : (
								<span> {t('no_teams_selected')}</span>
							)}
						</Col>
					</Row>
					<Row>
						{preview.team && preview.team.id && (
							<Col>
								<strong>{t('selected_team')}:</strong>
								<span>
									<img className='img-circle ml-2' width='20px' src={preview.team.logo} alt={preview.team.name} />
									{preview.threeLetterCodeType && preview.threeLetterCodeType !== '' && preview.team.threeLetterCode !== null
										? preview.team.threeLetterCode
										: preview.team.name}
								</span>
							</Col>
						)}
					</Row>
					<Row>
						{preview.offset && preview.offset.length > 0 && (
							<Col>
								<strong>{t('offset')}:</strong>
								<span className='ml-2'>{preview.offset}</span>
							</Col>
						)}
					</Row>
					<Row>
						{preview.group && preview.group.length === 0 && (
							<Col className='text-left'>
								<strong>{t('display_form')}: </strong>
								<strong className={preview.displayLastMatchesResults ? 'text-success' : 'text-danger'}>
									{preview.displayLastMatchesResults ? t('yes') : t('no')}
								</strong>
							</Col>
						)}
					</Row>
					{preview.threeLetterCodeType && preview.threeLetterCodeType !== '' && (
						<Row className='mt-2'>
							<Col className='text-left'>
								<strong>{t('display_team_three_letter_code')}:</strong>
								<span className='ml-2'>
									{preview.threeLetterCodeType && preview.threeLetterCodeType.name && t(`${preview.threeLetterCodeType.name}`)}
								</span>
							</Col>
						</Row>
					)}
				</Col>
			</Row>
		);
	}

	return null;
};
