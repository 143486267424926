import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
	constructRequestFromEntry,
	remapSquadToJson,
	findIndexById,
	defineModalProps,
	disableSquadSave,
	remapBlacklistFromResponse,
	remapSquadPlayers,
	remapSelectionToSquadPlayer,
	checkIfNextPageIsEntityRelated,
} from '../helpers/manual-data.helper';
import { Col, Row, Button } from 'reactstrap';
import Blacklisted from '../blacklisted-container.tsx/blacklisted-container';
import HttpService from '../../../../../services/rest/HttpService';
import BlacklistModal from '../blacklist-modal/blacklist-modal';
import * as uiActions from '../../../../../store/action-creators/UiActionCreator';
import BlacklistModel from '../../../../../models/team-manual-data/blacklist/blacklist';
import TeamManualDataModel from '../../../../../models/team-manual-data/team-manual-data.model';
import SquadPlayerModel from '../../../../../models/team-manual-data/squad-player/squad-player.model';
import ActivePlayers from './active-players.component';
import HistoricalPlayers from './historical-players.component';
import RemovePlayerModal from './remove-player-modal.component';
import Project from '../../../../../models/project/Project';
import { toast } from 'react-toastify';
import ModelMapper from '../../../../../models/ModelMapper';
import { teamSearchFilterUpdate } from '../../../../../store/action-creators/team-action-creator';

type Properties = {
	team: TeamManualDataModel;
	match: any;
	t: any;
	dispatch: any;
	isToggledModal: boolean;
	analytics: any;
	project: Project;
	updateSearchFilterTeam: (text: string) => any;
};

type State = {
	isContentLoading: boolean;
	squadPlayers: SquadPlayerModel[];
	playerToRemove: any;
	teamDataEntry: TeamManualDataModel;
	showModal: boolean;
};

class SquadEditComponent extends React.Component<Properties, State> {
	constructor(props: Properties) {
		super(props);
		this.state = {
			playerToRemove: { player: SquadPlayerModel.builder().build(), action: '' },
			isContentLoading: false,
			showModal: false,
			squadPlayers: [],
			teamDataEntry: TeamManualDataModel.builder().build(),
		};
	}

	componentDidMount(): void {
		this.initPageTitle();
		this.getSquad();
	}

	componentWillUnmount() {
		checkIfNextPageIsEntityRelated('teams') && this.props.updateSearchFilterTeam('');
	}

	initPageTitle = () => (document.title = this.props.t('edit_squad'));

	setTeamSquadFromResponse = (data: any) => {
		const team = ModelMapper.remapTeamFromResponse(data.team);
		this.setState(
			(state: State) => ({ ...state, isContentLoading: false, squadPlayers: remapSquadPlayers(data) }),
			() => this.getBlacklistEntityData(team),
		);
	};

	getSquad = () => {
		this.setState({ ...this.state, isContentLoading: true });
		HttpService.instanceFootball()
			.get(`/v2/teams/${this.props.match.params.id}/squad?memberStatus=ALL`)
			.then((response: any) => this.setTeamSquadFromResponse(response.data))
			.catch(() => this.setState({ ...this.state, isContentLoading: false }));
	};

	saveSquad = () => {
		const squad = remapSquadToJson(this.state.squadPlayers);
		HttpService.instanceFootball()
			.patch(`/v2/teams/${this.props.match.params.id}/squad`, { players: squad })
			.then((response: any) => this.setTeamSquadFromResponse(response.data))
			.then(() => toast.success(this.props.t('squad_upadate_successfull')))
			.catch((error: { response?: { data?: { message?: string } } }) => {
				const DUPLICATE_PLAYERS_ERROR_MESSAGE = 'Duplicate currently active players';
				if (
					error.response &&
					error.response.data &&
					error.response.data.message &&
					error.response.data.message === DUPLICATE_PLAYERS_ERROR_MESSAGE
				) {
					toast.error(this.props.t('duplicated_players_selected_error'));
				} else {
					toast.error(this.props.t('squad_upadate_failed'));
				}
			});
	};

	getBlacklistEntityData = (team: TeamManualDataModel) => {
		const params = constructRequestFromEntry([team], 'TEAM', 'RELATION', 'players');
		HttpService.getBlacklist(`/v2/blacklist/search`, params)
			.then((response: any) => {
				const blacklist = response.data;

				if (blacklist && blacklist.length > 0) {
					this.setState({
						...this.state,
						teamDataEntry: TeamManualDataModel.builder(team).withBlacklist(remapBlacklistFromResponse(blacklist[0])).build(),
					});
				}
			})
			.catch((error: unknown) => console.error(error));
	};

	removeFromBlacklist = () => {
		const { teamDataEntry } = this.state;

		HttpService.removeFromBlacklist(teamDataEntry.blacklist.id)
			.then(() => {
				this.setState({
					...this.state,
					teamDataEntry: TeamManualDataModel.builder(teamDataEntry).withBlacklist(BlacklistModel.builder().build()).build(),
				});
				this.toggleModal(false);
			})
			.catch(() => this.toggleModal(false));
	};

	toggleModal = (toggle: boolean) => this.props.dispatch(uiActions.toggleModal(toggle));

	onContractChange = (player: SquadPlayerModel, contract: string) => {
		const squad = [...this.state.squadPlayers];
		squad[findIndexById(player.id, this.state.squadPlayers)] = SquadPlayerModel.builder(player).withContractType(contract).build();
		this.setState({ ...this.state, squadPlayers: squad });
	};

	onShirtChange = (player: SquadPlayerModel, number: number) => {
		if (number > 99) {
			return;
		}

		const squad = [...this.state.squadPlayers];
		squad[findIndexById(player.id, this.state.squadPlayers)] = SquadPlayerModel.builder(player).withShirtNumber(number).build();
		this.setState({ ...this.state, squadPlayers: squad });
	};

	onStartDateChange = (player: SquadPlayerModel, date: string) => {
		const squad = [...this.state.squadPlayers];
		squad[findIndexById(player.id, this.state.squadPlayers)] = SquadPlayerModel.builder(player).withStartDate(date).build();
		this.setState({ ...this.state, squadPlayers: squad });
	};

	onEndDateChange = (player: SquadPlayerModel, date: string) => {
		const squad = [...this.state.squadPlayers];
		squad[findIndexById(player.id, this.state.squadPlayers)] = SquadPlayerModel.builder(player).withEndtDate(date).build();
		this.setState({ ...this.state, squadPlayers: squad });
	};

	removeFromSquad = () => {
		const { squadPlayers, playerToRemove } = this.state;
		const squad = [...squadPlayers];
		squad[findIndexById(playerToRemove.player.id, squadPlayers)] = SquadPlayerModel.builder(playerToRemove.player)
			.withStatus('INACTIVE')
			.build();
		this.setState({ ...this.state, squadPlayers: squad, showModal: false });
	};

	deleteFromSquad = () => {
		const { squadPlayers, playerToRemove } = this.state;
		const squad = [...squadPlayers].filter((player: SquadPlayerModel) => {
			return player.id.split('-')[1] !== playerToRemove.player.id.split('-')[1];
		});
		this.setState({ ...this.state, squadPlayers: squad, showModal: false });
	};

	setPlayerToRemove = (player: SquadPlayerModel) => this.setState({ ...this.state, playerToRemove: player, showModal: true });

	onPlayerChange = (player: any, tempId: string) => {
		const squad = [...this.state.squadPlayers];
		squad[findIndexById(tempId, squad)] = remapSelectionToSquadPlayer(player, tempId);
		this.setState({ ...this.state, squadPlayers: squad });
	};

	addRow = () => {
		const { squadPlayers } = this.state;
		const squad = [...squadPlayers, SquadPlayerModel.builder().withId(`tempId${squadPlayers.length}`).withStatus('ACTIVE').build()];
		this.setState({ ...this.state, squadPlayers: squad });
	};

	deleteRow = (rowToRemove: SquadPlayerModel) => {
		const { squadPlayers } = this.state;
		const squad = [...squadPlayers].filter((player: SquadPlayerModel) => player.id !== rowToRemove.id);
		this.setState({ ...this.state, squadPlayers: squad, showModal: false });
	};

	toggleRemoveModal = (isShown: boolean) => this.setState({ ...this.state, showModal: isShown });

	render() {
		const { t, isToggledModal, analytics, project } = this.props;
		const { teamDataEntry, isContentLoading, squadPlayers, showModal, playerToRemove } = this.state;
		return (
			<div className='animated fadeIn'>
				<Row>
					<Col col='12'>
						{teamDataEntry.blacklist && teamDataEntry.blacklist.id && (
							<Blacklisted t={t} type='squad' isContentLoading={isContentLoading} toggleModal={this.toggleModal} />
						)}
						<div className='card'>
							<div className='card-header d-flex align-items-center '>
								<div className='mr-auto mb-0'>{t('team_players')}</div>
								<Button
									size='sm'
									onClick={this.saveSquad}
									disabled={disableSquadSave(squadPlayers)}
									color='primary'
									className='text-uppercase font-weight-bold'
									id='squad-manual-data-entry-edit-save-top'
								>
									<i className='fa fa-floppy-o' /> {t('save_squad')}
								</Button>
							</div>
							<ActivePlayers
								t={t}
								project={project}
								isContentLoading={isContentLoading}
								players={squadPlayers}
								playerChange={this.onPlayerChange}
								addRow={this.addRow}
								deleteRow={this.deleteRow}
								removeFromSquad={this.setPlayerToRemove}
								startDateChange={this.onStartDateChange}
								shirtChange={this.onShirtChange}
								onContractChange={this.onContractChange}
							/>
						</div>
						<div className='card'>
							<HistoricalPlayers
								t={t}
								isContentLoading={isContentLoading}
								players={squadPlayers}
								removeFromSquad={this.setPlayerToRemove}
								startDateChange={this.onStartDateChange}
								endDateChange={this.onEndDateChange}
							/>
							<div className='p-3 mt-2'>
								<Button
									disabled={disableSquadSave(squadPlayers)}
									color='primary'
									className='text-uppercase font-weight-bold'
									onClick={this.saveSquad}
									id='squad-manual-data-entry-edit-save-bottom'
								>
									<i className='fa fa-floppy-o' /> {t('save_squad')}
								</Button>
							</div>
						</div>
					</Col>
				</Row>
				<RemovePlayerModal
					t={t}
					buttonText={defineModalProps(playerToRemove.action, t('remove'), t('delete'))}
					bodyText={defineModalProps(playerToRemove.action, t('remove_squad_player_info'), t('delete_squad_player_info'))}
					showModal={showModal}
					removeFromSquad={defineModalProps(playerToRemove.action, this.removeFromSquad, this.deleteFromSquad)}
					toggleModal={this.toggleRemoveModal}
				/>
				<BlacklistModal
					t={t}
					type='squad'
					analytics={analytics}
					contentToDelete={teamDataEntry}
					onDeleteContent={this.removeFromBlacklist}
					toggleModal={this.toggleModal}
					isToggledModal={isToggledModal}
				/>
			</div>
		);
	}
}

function mapStateToProps(state: any) {
	return {
		project: state.project.currentProject,
		isToggledModal: state.ui.isToggleModal,
	};
}

function mapDispatchToProps(dispatch: any) {
	return {
		dispatch: (action: any) => dispatch(action),
		updateSearchFilterTeam: (text: string) => dispatch(teamSearchFilterUpdate(text)),
	};
}

export default compose(connect(mapStateToProps, mapDispatchToProps), withRouter)(SquadEditComponent);
