import { YoutubeVideoJson } from './youtube-video.json';
import YoutubeVideoModel from './youtube-video.model';
import { YoutubeVideoRequestJson } from './youtube-video.request-json';

export default class YoutubeVideoBuilder {
	private json: YoutubeVideoJson;

	constructor(video?: YoutubeVideoModel | YoutubeVideoJson) {
		if (video && video instanceof YoutubeVideoModel) {
			this.json = video.toJson();
		} else if (video) {
			this.json = video;
		} else {
			this.json = {} as YoutubeVideoJson;
		}
	}

	public withId(id: any): YoutubeVideoBuilder {
		this.json.id = id;

		return this;
	}

	public withEtag(etag: string): YoutubeVideoBuilder {
		this.json.etag = etag;

		return this;
	}

	public withSnippet(snippet: any): YoutubeVideoBuilder {
		this.json.snippet = snippet;

		return this;
	}

	toRequestJson(): YoutubeVideoRequestJson {
		let json = {} as YoutubeVideoRequestJson;
		json.id = this.json.id;
		json.etag = this.json.etag;
		json.snippet = this.json.snippet;

		return json;
	}

	public build(): YoutubeVideoModel {
		return YoutubeVideoModel.fromJSON(this.json);
	}
}
