import React from 'react';
import { EditorTypes } from '../constants/block.types';
import BlockModel from '../models/block.model';
import EditorBlock from '../blocks/editor/editor-block.component';

export default class EditorBlocksGenerator {
	public createBlock = (block: BlockModel, index: number, t: any) => {
		switch (block.data.type) {
			case EditorTypes.heading: {
				return <EditorBlock block={block} index={index} t={t} />;
			}

			case EditorTypes.list: {
				return <EditorBlock block={block} index={index} t={t} />;
			}

			case EditorTypes.paragraph: {
				return <EditorBlock block={block} index={index} t={t} />;
			}

			case EditorTypes.quote: {
				return <EditorBlock block={block} index={index} t={t} />;
			}

			case EditorTypes.table: {
				return <EditorBlock block={block} index={index} t={t} />;
			}

			default: {
				return null;
			}
		}
	};
}
