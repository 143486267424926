import React, { FunctionComponent, useState } from 'react';
import TournamentGroupModel from '../../../../../models/v2/Tournament/Group/TournamentGroupModel';
import { DisabledSaveModel } from '../../helpers/TournamentsGroupsUiService';
import TournamentsGroupBody from '../Shared/BodyCard/TournamentsGroupBody';
import TournamentsGroupInfo from '../Shared/InfoCard/TournamentsGroupInfo';

type Props = {
	t: any;
	history: any;
};

const TournamentsGroupsCreate: FunctionComponent<Props> = ({ t, history }) => {
	const createDisabledSaveModel = new DisabledSaveModel();
	createDisabledSaveModel.validNameInputData = false;
	const [disabledSave, setDisabledSave] = useState(createDisabledSaveModel);
	const [defaultTournamentsGroup, setDefaultTournamentsGroup] = useState(new TournamentGroupModel());
	const [tournamentsGroup, setTournamentsGroup] = useState(new TournamentGroupModel());

	document.title = t('new_tournament_order');

	return (
		<>
			<TournamentsGroupInfo
				isNewGroup={true}
				history={history}
				t={t}
				tournamentsGroup={tournamentsGroup}
				setTournamentsGroup={setTournamentsGroup}
				defaultTournamentsGroup={defaultTournamentsGroup}
				setDefaultTournamentsGroup={setDefaultTournamentsGroup}
				disabledSave={disabledSave}
				setDisabledSave={setDisabledSave}
			/>

			<TournamentsGroupBody
				t={t}
				tournamentsGroup={tournamentsGroup}
				setTournamentsGroup={setTournamentsGroup}
				disabledSave={disabledSave}
				setDisabledSave={setDisabledSave}
			/>
		</>
	);
};

export default TournamentsGroupsCreate;
