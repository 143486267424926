import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Col, Label, Row } from 'reactstrap';
import HttpService from '../../../../../../services/rest/HttpService';
import StageModel from '../../../../../../models/v2/Stage/Entity/StageModel';
import { responseToStageModel, stagesToOptions, stageToOption } from '../helpers/stage-select/stage-select.helper';
import { customOption } from '../../../partials/shared/custom-select-option';
import ErrorHandler from '../error-handler/error-handler';

type Properties = {
	onStageSelect: any;
	selectedStage: StageModel;
	seasonId: number | string;
	t: any;
	isValid: boolean;
	withLabel?: boolean;
	label?: string;
	isRequired?: boolean;
	preselectStage?: boolean;
	isClearable?: boolean;
	id: string;
};

const StageSelectComponent: React.FunctionComponent<Properties> = (props) => {
	const [stagesOptions, setStagesOptions] = useState<StageModel[]>([]);

	useEffect(() => {
		if (props.seasonId) {
			getSeasons(props.seasonId);
		}
	}, [props.seasonId]);

	const updateStagesOptionsState = (stages: StageModel[]) => {
		setStagesOptions(stages);
	};

	const getSeasons = (seasonId: number | string) => {
		if (seasonId) {
			HttpService.getSeasonById(seasonId)
				.then((response: any) => {
					updateStagesOptionsState(response.data.stages.map((item: StageModel) => responseToStageModel(item)));
				})
				.catch((e: any) => e);
		}
	};

	const { onStageSelect, selectedStage, seasonId, t, label, withLabel, isValid, isRequired, isClearable } = props;

	if (seasonId === undefined || seasonId === null) {
		return null;
	}

	return (
		<Row data-qa={props.id}>
			<Col>
				{withLabel && <Label htmlFor={props.id}>{t('stage')}:</Label>}
				{label && <Label htmlFor={props.id}>{t(label)}</Label>}
				{isRequired && <span className='text-danger'>*</span>}
				<Select
					id={props.id}
					menuPortalTarget={document.body}
					menuPosition='absolute'
					formatOptionLabel={customOption}
					options={stagesToOptions(stagesOptions)}
					value={props.selectedStage && props.selectedStage.id ? stageToOption(selectedStage) : null}
					onChange={(selection: any) => {
						selection ? onStageSelect(selection.data) : onStageSelect(null);
					}}
					placeholder={props.t('select')}
					isClearable={isClearable}
					styles={{
						menuPortal: (baseStyles) => ({
							...baseStyles,
							zIndex: 10000,
						}),
					}}
				/>
				{isRequired && (
					<ErrorHandler
						isValueSelected={!selectedStage || (props.selectedStage && !props.selectedStage.id)}
						arePropsValid={isValid}
						t={t}
						errorMessage='field_is_required'
					/>
				)}
			</Col>
		</Row>
	);
};

export default StageSelectComponent as React.ComponentType<Properties>;
