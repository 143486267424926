import { TennisPlayoffJson } from './tennis-playoff.json';
import TennisPlayoffModel from './tennis-playoff.model';
import CompetitionModel from '../../../../../../../models/v2/competition/entity/competition.model';
import TennisCompetitionSeasonModel from '../../../../../../../models/v2/tennis-competition-season/entity/tennis-competition-season.model';
import TennisSeasonStagesModel from '../../../../../../../models/v2/tennis-competition-season/entity/tennis-season-stages.model';
import { RefreshTimeValuesType } from '../../refresh-time-input/helper';

export default class TennisplayoffBuilder {
	readonly json: TennisPlayoffJson;

	constructor(playoff?: TennisPlayoffModel | TennisPlayoffJson) {
		if (playoff && playoff instanceof TennisPlayoffModel) {
			this.json = playoff.toJson();
		} else if (playoff) {
			this.json = playoff;
		} else {
			this.json = {} as TennisPlayoffJson;
		}
	}

	withTournament(competition: CompetitionModel): TennisplayoffBuilder {
		this.json.competition = competition;

		return this;
	}

	withSeason(season: TennisCompetitionSeasonModel): TennisplayoffBuilder {
		this.json.season = season;

		return this;
	}

	withStage(stage: TennisSeasonStagesModel): TennisplayoffBuilder {
		this.json.stage = stage;

		return this;
	}

	withStages(stages: TennisSeasonStagesModel[]): TennisplayoffBuilder {
		this.json.stages = stages;

		return this;
	}

	withRefreshTime(refreshTime: RefreshTimeValuesType): TennisplayoffBuilder {
		this.json.refreshTime = refreshTime;

		return this;
	}

	build(): TennisPlayoffModel {
		return TennisPlayoffModel.fromJson(this.json);
	}
}
