import { EditorTypes, FansUnitedWidgetsTypes, WidgetTypes, WidgetTypesV2 } from '../constants/block.types';
import Validation from '../blocks/widgets/validation/widgets.validation';
import ValidationV2 from '../v2/validation/widgets-v2.validation';

export default class BlockValidation {
	validate = (block: any, data: any) => {
		if (block.type === WidgetTypes.widget) {
			const validation = new Validation();

			return validation.validate(block.data.widget_type, data);
		}

		if (block.type === WidgetTypesV2.widget) {
			const validationV2 = new ValidationV2();

			return validationV2.validate(block.data.widget_type, data, block.data.widget_type);
		}

		if (block.type === FansUnitedWidgetsTypes.widget) {
			const validation = new Validation();

			return validation.validate(block.data.widget_type, data);
		}

		if (block.type === EditorTypes.editor) {
			return true;
		}

		const validation = new Validation();

		return validation.validateBlocks(block.type, data);
	};
}
