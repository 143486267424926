import * as React from 'react';
import { Button } from 'reactstrap';
import { analyticsService } from '../../../../../../../App';

type Properties = {
	display: boolean;
	contentId: string;
	editContentPath: string;
	history: any;
	t: any;
};

export const RowListContentEditButton: React.FunctionComponent<Properties> = ({ display, editContentPath, history, t, contentId }) => {
	const editContent = () => {
		analyticsService.sendEvent('Click', 'List Page', 'Edit Content Button');
		history.push(editContentPath);
	};

	if (display) {
		return (
			<Button
				size='sm'
				color='info'
				id={`content-edit-content-btn-${contentId}`}
				className='m-1 d-flex flex-fill align-items-center justify-content-center'
				onClick={editContent}
			>
				<i className='fa fa-list-alt p-1'></i> {t('list_edit_content')}
			</Button>
		);
	}

	return null;
};
