export enum PlayerPositionType {
	goalkeeper = 'KEEPER',
	midfielder = 'MIDFIELDER',
	forward = 'FORWARD',
	defender = 'DEFENDER',
}

export enum PlayerPositions {
	KEEPER = 'goalkeeper',
	MIDFIELDER = 'midfielder',
	FORWARD = 'forward',
	DEFENDER = 'defender',
}

export enum TeamType {
	national = 'NATIONAL',
	club = 'CLUB',
	placeholder = 'PLACEHOLDER',
}

export enum TeamPositions {
	NATIONAL = 'national',
	CLUB = 'club',
}
