import CategoriesHttp from '../../../../services/rest/CategoriesHttp';
import AdminModel from '../../../../models/v2/admin/admin.model';
import AdminMapper from '../../../../models/v2/admin/admin.mapper';
import ContentAnalyticsHttp from '../../../../services/rest/content-analytics.http';
import { uniqueValuesOnly } from '../../../Partials/Blocky/shared/global-functions/global-functions';
const randomColor = require('randomcolor');

export const ADMIN_ENTITY = 'admin';
export const CATEGORY_ENTITY = 'category';

export default class ContentAnalyticsService {
	readonly http = new ContentAnalyticsHttp();

	public getViewsForEntities = async (entities: any[], from: string, to: string, type: string) => {
		try {
			// entities, from, to, type are required parameters for the GET request to Content Analytics
			if (entities.length > 0 && from && to && type) {
				let resp = await this.http.getStatisticsByEntityIdsAndDate(type, entities, from, to);
				return this.contentStatisticsMapper(resp.data.rows, entities, type);
			} else return null;
		} catch (e) {
			console.error(e);
			return null;
		}
	};

	private contentStatisticsMapper = (rows: any[], entities: any[], type: string) => {
		let dataByEntities = this.structureByEntityIds(rows, entities, type);
		const dataSets = this.createDatasets(dataByEntities);

		return { labels: dataByEntities.labels, dataSets: dataSets };
	};

	private createDatasets = (dataByEntities: any) => {
		const dates = dataByEntities.labels;
		const dataSets: any[] = [];

		Object.keys(dataByEntities.entities).forEach((key) => {
			const lineColor = randomColor({ luminosity: 'dark' });
			const dataSet = {
				type: 'line',
				label: dataByEntities.entities[key].label,
				data: [] as any[],
				borderColor: lineColor,
				pointHoverBackgroundColor: lineColor,
			};

			dates.forEach((date: string) => {
				if (dataByEntities.entities[key].hasOwnProperty(date)) {
					dataSet.data.push(dataByEntities.entities[key][date]);
				} else {
					dataSet.data.push('0');
				}
			});
			dataSets.push(dataSet);
		});
		return dataSets;
	};

	private extractEntityName = (entity: any, type: string) => {
		if (type === ADMIN_ENTITY) {
			return entity.fullName;
		} else if (type === CATEGORY_ENTITY) {
			return entity.title;
		}
	};

	private structureByEntityIds = (rows: any[], entities: any[], type: string) => {
		let entityIdData = {};
		let labels: string[] = [];

		rows.forEach((row: any) => {
			const id = row.columns[0].value;
			const date = row.columns[1].value;
			const views = row.metrics[0].value;
			const entity = entities.find((entity: any) => `${entity.id}` === `${id}`);
			labels.push(date);

			entityIdData = this.createObjectIfNoneExists(entityIdData, id);
			entityIdData[id]['label'] = this.extractEntityName(entity, type);
			entityIdData[id][date] = views;
		});

		labels = uniqueValuesOnly(labels);

		return { labels: labels, entities: entityIdData };
	};

	private createObjectIfNoneExists = (obj: any, childObj: string) => {
		if (!obj.hasOwnProperty(childObj)) {
			obj[childObj] = {};
			return obj;
		}

		return obj;
	};
}
