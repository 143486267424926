import { relatedConstants } from '../../../../../constants/related.constants';
import { assetsPlaceholder } from '../../../../../constants/assetsPlaceholder';
import { ContentTypes } from '../../../../../constants/content-types';

export function responseToOption(response: any, type: string) {
	return {
		value: response.id,
		label: response.title,
		publishedAt: type === ContentTypes.LIVE_BLOG ? response.start_time : response.published_at,
		data: response,
		type,
	};
}

export function responseTagToOption(response: any, type: string) {
	return { value: response.id, label: response.title, createdBy: response.created_by, data: response, type };
}

export function responseSportsConnectionToOption(response: any) {
	if (response && Object.entries(response).length > 0) {
		return {
			value: response.id.toString(),
			label: response.name,
			entityType: response.entity_type,
			logo: response.url_thumb
				? response.url_thumb
				: response.url_image
				? response.url_image
				: response.url_logo
				? response.url_logo
				: response.display_asset
				? response.display_asset.url
				: assetsPlaceholder[response.entity_type],
			type: relatedConstants.types[response.entity_type],
			data: response,
		};
	}
}

export function responseToOptions(response: any[], type: string) {
	let options: any[] = [];

	if (response && response.length > 0 && type) {
		options = response.map((item: any) => responseToOption(item, type));
	}

	return options;
}

export function responseTagsToOptions(response: any[], type: string) {
	let options: any[] = [];

	if (response && response.length > 0 && type) {
		options = response.map((item: any) => responseTagToOption(item, type));
	}

	return options;
}

export function responseSportsConnectionsToOptions(response: any[], type: string) {
	let options: any[] = [];

	if (response && response.length > 0 && type) {
		options = response.map((item: any) => responseSportsConnectionToOption(item));
	}

	return options;
}

export const relatedTypesMap = {
	articles: 'article',
	videos: 'video',
	galleries: 'gallery',
	tags: 'tag',
	sports_connections: 'sports_connections',
	live_blogs: 'live_blog',
};

export const extractOptionIds = (options: any) => {
	if (options && options.length > 0) {
		return options.map((option: any) => option.data.id).join();
	}

	return [];
};

const findWithoutStatisticsAndPopulate = (contentStatitstics: any[], items: any[]) => {
	const extractedContentStatistcsIds = contentStatitstics.map((item: any) => item.id);
	const itemsWithoutStatistics = items.filter((item: any) => !extractedContentStatistcsIds.includes(item.data.id));
	return itemsWithoutStatistics.map((item: any) => {
		return {
			item: {
				...item,
				data: {
					...item.data,
					views: 0,
					comments: 0,
				},
			},
		};
	});
};

// You should pass items when you have live_blog item type
// live_blog item type has no views and comments because we are using external API (ex: https://api.sportal.bg/apps/v1/statistics)
// and they are removing this element from the response
export function contentStatisticsToOptions(options: any[], contentStatitstics: any, items?: any[]) {
	let optionsWithStatistics: any[] = [];
	options.length > 0 &&
		options.forEach((item: any) => {
			contentStatitstics.forEach((statistic: any) => {
				if (statistic.id === item.data.id) {
					item.data.views = statistic.data.views;
					item.data.comments = statistic.data.comments;
					optionsWithStatistics.push(item);
				}
			});
		});

	if (items && items.length > 0) {
		const itemsWithoutStatistics = findWithoutStatisticsAndPopulate(contentStatitstics, items);
		return [...optionsWithStatistics, ...itemsWithoutStatistics];
	}

	return optionsWithStatistics;
}
