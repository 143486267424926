import React, { FunctionComponent, useState } from 'react';
import { Card, CardBody, CardHeader, Col, Collapse, ListGroup, ListGroupItem, Row } from 'reactstrap';
import SeasonModel from '../../../../../models/v2/Season/Entity/SeasonModel';
import { store } from '../../../../../store/store';
import StageModel from '../../../../../models/v2/Stage/Entity/StageModel';
import { blService, uiService } from '../../TournamentContainer';
import StageTeamsModal from './modal/StageTeamsModal';
import { StagesLoad } from './row/StagesLoad';

type Props = {
	t: any;
	seasons: SeasonModel[];
};

export const Stages: FunctionComponent<Props> = ({ t, seasons }) => {
	const language = store.getState().project.currentProject.language;
	const [detailedSeasonsInfo, setDetailedSeasonsInfo] = useState({});
	const [loadingTournamentStages, setLoadingTournamentStages] = useState(new Array<string>());
	const [openedStages, setOpenedStages] = useState(new Array<number>());
	// Edit stage teams
	const [toggleModal, setToggleModal] = useState(false);
	const [selectedStageSeasonName, setSelectedStageSeasonName] = useState(''); // used only for displaying season name in modal
	const [selectedStageTeamForEdit, setSelectedStageTeamForEdit] = useState(new StageModel());
	// Copy stage teams
	const [previousStageForCopyTeams, setPreviousStageForCopyTeams] = useState(new StageModel());
	const [copyTeamsButtonVisibleFlag, setCopyTeamsButtonVisibleFlag] = useState(true);

	const editStageTeams = (stage: StageModel) => {
		const { id, stages } = uiService.findSeasonWithAllStagesByStage(detailedSeasonsInfo, stage);
		const { isCurrentSeasonLatest, previousSeasonId } = uiService.extractSeasonIdsInfo(seasons, id);

		if (isCurrentSeasonLatest) {
			const previousStageFromCurrentSeason = uiService.previousStageFromCurrentSeason(stages, stage);
			if (previousStageFromCurrentSeason) {
				setPreviousStageForCopyTeams(previousStageFromCurrentSeason);
			} else if (previousSeasonId) {
				const isAlreadyLoaded = !!detailedSeasonsInfo[previousSeasonId];
				if (!isAlreadyLoaded) {
					blService.getTournamentStages(previousSeasonId.toString(), language).then((stagesData) => {
						setDetailedSeasonsInfo({ ...detailedSeasonsInfo, [previousSeasonId]: stagesData });
						const previousStageFromPreviousSeason = uiService.getEarliestStageFromPreviousSeason(stagesData);
						previousStageFromPreviousSeason && setPreviousStageForCopyTeams(previousStageFromPreviousSeason);
					});
				} else {
					const previousStageFromPreviousSeason = uiService.getEarliestStageFromPreviousSeason(detailedSeasonsInfo[previousSeasonId]);
					setPreviousStageForCopyTeams(previousStageFromPreviousSeason as StageModel);
				}
				setCopyTeamsButtonVisibleFlag(true);
			}
		} else {
			setPreviousStageForCopyTeams(new StageModel());
			setCopyTeamsButtonVisibleFlag(false);
		}

		setSelectedStageTeamForEdit(stage);
		setToggleModal(true);
	};

	const onSeasonClick = (seasonId: number) => {
		requestDetailedInfo(seasonId.toString());
		if (openedStages.includes(seasonId)) {
			const filterArray = [...openedStages].filter((openStage) => openStage !== seasonId);
			setOpenedStages(filterArray);
		} else {
			setOpenedStages([...openedStages, seasonId]);
		}
	};

	const requestDetailedInfo = (seasonId: string) => {
		const alreadyRequestedData = Object.keys(detailedSeasonsInfo).includes(seasonId);
		if (!alreadyRequestedData) {
			setLoadingTournamentStages([...loadingTournamentStages, seasonId]);
			blService.getTournamentStages(seasonId, language).then((stages) => {
				setDetailedSeasonsInfo({ ...detailedSeasonsInfo, [seasonId]: stages });
				const removedSeasonIdFromLoading = [...loadingTournamentStages].filter((loadingSeasonId) => loadingSeasonId !== seasonId);
				setLoadingTournamentStages(removedSeasonIdFromLoading);
			});
		}
	};

	return (
		<>
			<Row>
				<Col col='12' lg='12' md='12' sm='12' xs='12'>
					<div className='animated fadeIn'>
						<Card>
							<CardHeader>
								<b>{t('tournament_seasons')}</b>
							</CardHeader>
							<CardBody>
								<ListGroup>
									{seasons.length > 0 &&
										seasons.map((season) => {
											return (
												<div key={season.id}>
													<ListGroupItem
														style={{ background: '#f0f3f3' }}
														onClick={() => onSeasonClick(season.id)}
														className='cursor-pointer d-flex align-items-center'
													>
														{season.name}
														<i className={`ml-auto fa fa-lg fa-angle-${openedStages.includes(season.id) ? 'down' : 'left'}`} />
													</ListGroupItem>
													<Collapse isOpen={openedStages.includes(season.id)}>
														<div className='text-center px-2 py-4'>
															<StagesLoad
																loadingTournamentStages={loadingTournamentStages}
																seasonId={season.id}
																seasonName={season.name}
																detailedSeasonsInfo={detailedSeasonsInfo}
																selectStageToEdit={editStageTeams}
																saveSeasonName={setSelectedStageSeasonName}
																t={t}
															/>
														</div>
													</Collapse>
												</div>
											);
										})}
								</ListGroup>
							</CardBody>
						</Card>
					</div>
				</Col>
			</Row>
			<StageTeamsModal
				open={toggleModal}
				closeModal={() => setToggleModal(false)}
				stage={selectedStageTeamForEdit}
				previousStageForCopyTeams={previousStageForCopyTeams}
				copyTeamsButtonVisibleFlag={copyTeamsButtonVisibleFlag}
				seasonName={selectedStageSeasonName}
				t={t}
			/>
		</>
	);
};
