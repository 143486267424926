export enum PermissionState {
	granted = 'granted',
	denied = 'denied',
	prompt = 'prompt',
}

export enum BrowserPermissionTypes {
	clipboardWrite = 'clipboard-write',
	clipboardRead = 'clipboard-read',
}

export default class CheckBrowserPermissions {
	public check = (permissionName: string) => {
		return navigator.permissions.query({ name: permissionName } as PermissionDescriptor);
	};
}
