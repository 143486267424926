import React, { FunctionComponent, useEffect, useState } from 'react';
import Flatpickr from 'react-flatpickr';
import { Label } from 'reactstrap';
import './date-time.component.scss';
import DateTimeHelper, { DATE_DAY, DATE_MONTH, DATE_WEEK } from './date-time.helper';
import { useTranslation } from 'react-i18next';
import '../../../Partials/Sidebar/GeneralContentAttributes/subcomponents/DateTimePicker/DateTimePickerContainer.scss';

export const DatePicker: FunctionComponent<{
	onDateChange: (date: any, dateFilterApplied?: boolean) => void;
	hidePredefinedFilters?: boolean;
	auditLogPage?: boolean;
	resetButton?: boolean;
}> = ({ onDateChange, hidePredefinedFilters, auditLogPage, resetButton }) => {
	const { t } = useTranslation();
	const [predefined, setPredefined] = useState('');
	const [dateRange, setDateRange] = useState([] as Date[]);
	const datePickerHelper = new DateTimeHelper();
	const refComp = React.useRef(null);
	let flatPickrInstance: any = null;

	useEffect(() => {
		setPredefinedDate(DATE_MONTH);
	}, []);

	const setPredefinedDate = (date: string) => {
		const constructedDateRange = datePickerHelper.getDateRange(date);
		setPredefined(date);
		setDateRange([]);
		onDateChange(constructedDateRange, false);
	};

	const onReset = () => {
		if (flatPickrInstance) {
			flatPickrInstance.clear();
		}
		refComp && refComp.current !== null && refComp.current.flatpickr.clear();
		onDateChange('', true);
		setDateRange([]);
	};
	return (
		<div className='date-time-filter'>
			{!hidePredefinedFilters && (
				<div className='d-flex flex-column justify-content-start mr-3'>
					<Label>{t('predefined_date_range')}</Label>
					<div className='d-flex flex-row justify-content-between'>
						<div className={`predefined-date ${predefined === DATE_DAY ? 'selected' : ''}`} onClick={() => setPredefinedDate(DATE_DAY)}>
							{t('day')}
						</div>
						<div className={`predefined-date ${predefined === DATE_WEEK ? 'selected' : ''}`} onClick={() => setPredefinedDate(DATE_WEEK)}>
							{t('week')}
						</div>
						<div className={`predefined-date ${predefined === DATE_MONTH ? 'selected' : ''}`} onClick={() => setPredefinedDate(DATE_MONTH)}>
							{t('month')}
						</div>
					</div>
				</div>
			)}
			{auditLogPage ? (
				<Flatpickr
					ref={refComp}
					className={`${hidePredefinedFilters && 'mb-2'} date-picker`}
					id='date-picker'
					options={{
						enableTime: true,
						defaultDate: '',
						locale: 'en',
						mode: 'range',
						time_24hr: true,
						dateFormat: 'Y-m-d, H:i',
						onReady: (arg1, arg2, instance) => {
							flatPickrInstance = instance;
						},
					}}
					placeholder={t('select_date')}
					value={dateRange}
					onChange={(dates) => {
						//Fire event only if two dates have been selected
						if (dates.length === 2) {
							onDateChange(datePickerHelper.formatDateOutput(dates, hidePredefinedFilters), true);
							setDateRange(dates);
							setPredefined('');
						}
					}}
				/>
			) : (
				<div className={`d-flex flex-${!hidePredefinedFilters ? 'column' : 'row'} justify-content-start`}>
					<Label className={`${hidePredefinedFilters && 'mt-2 mr-2'}`}>{!hidePredefinedFilters ? t('select_date') : t('date_from_to')}</Label>
					<Flatpickr
						ref={refComp}
						className={`${hidePredefinedFilters && 'mb-2'} date-picker h-38 w-220`}
						id='date-picker'
						options={{
							enableTime: true,
							defaultDate: '',
							locale: 'en',
							mode: 'range',
							time_24hr: true,
							dateFormat: 'Y-m-d, H:i',
							onReady: (arg1, arg2, instance) => {
								flatPickrInstance = instance;
							},
						}}
						placeholder={t('select_date')}
						value={dateRange}
						onChange={(dates) => {
							//Fire event only if two dates have been selected
							if (dates.length === 2) {
								onDateChange(datePickerHelper.formatDateOutput(dates, hidePredefinedFilters), true);
								setDateRange(dates);
								setPredefined('');
							}
						}}
					/>
				</div>
			)}
			{resetButton && hidePredefinedFilters && (
				<div className={`${auditLogPage ? 'close-button-audit-log-page' : 'close-button-audit-log-container'} mr-1`} onClick={onReset}>
					<i className='fa fa-times close-icon' />
				</div>
			)}
		</div>
	);
};
