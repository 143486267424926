import i18next from 'i18next';
import { toast } from 'react-toastify';
import { OnImageCallback, OnImageUploaded } from './image-dropzone';

export const uploadImageFile = (
	file: File,
	imageApiURL: string,
	projectHeader: string,
	onImageUploaded?: OnImageCallback,
	customImageApiData?: Record<string, string>,
) => {
	let formData = new FormData();
	formData.append('file', file, file.name);

	if (customImageApiData && Object.keys(customImageApiData).length > 0) {
		for (const key in customImageApiData) {
			const value = customImageApiData[key];
			formData.append(key, value);
		}
	}

	fetch(`${imageApiURL}/upload`, {
		method: 'POST',
		body: formData,
		headers: { Project: projectHeader, 'X-Project': projectHeader },
	})
		.then((response) => {
			if (response.status >= 200 && response.status < 300) {
				toast.success(i18next.t('image_upload_success'));
				return response.json();
			} else {
				throw new Error('Error uploading image');
			}
		})
		.then((jsonResponse: OnImageUploaded) => onImageUploaded && onImageUploaded(jsonResponse))
		.catch((error) => {
			toast.error(i18next.t('error_uploading_image'));
			console.error(error);
		});
};

export const generateImagePath = (imageApiURL: string, path: string) => `${imageApiURL}process/${path}`;
