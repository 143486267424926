import { TypedJSON } from 'typedjson';
import MatchModel from '../../../../../../../models/v2/match/entity/match.model';
import moment from 'moment';

export const matchesToOptions = (matches: MatchModel[]) => {
	if (matches && matches.length > 0) {
		return matches.map((match) => {
			return matchToOption(match);
		});
	}

	return [];
};

export const matchToOption = (match: MatchModel) => {
	if (match && match.homeTeam && match.homeTeam.name && match.awayTeam && match.awayTeam.name) {
		return {
			value: match.id,
			label: `${moment(match.startTime).format('DD MMMM YYYY')},
			        ${match.homeTeam.name} - ${match.awayTeam.name}`,
			data: match,
			type: 'match',
		};
	} else {
		return {};
	}
};

export const responseToMatchModel = (response: any) => {
	const serializer = new TypedJSON(MatchModel);
	const remmapedMatch = serializer.parse(response) as MatchModel;

	return remmapedMatch;
};

export const getLatestNumberOfMatches = (response: any, numberOfMatches?: number) => {
	if (numberOfMatches === undefined) {
		numberOfMatches = 6;
	}

	if (response && response.length > 0) {
		return response.reverse().slice(0, numberOfMatches);
	}

	return [];
};
