import { Properties, State } from './properties/SeoTitleContainerProps';
import * as React from 'react';
import SeoTitle from './SeoTitle';

export default class SeoTitleContainer extends React.Component<Properties, State> {
	constructor(props: Properties) {
		super(props);
	}

	onAutoSeoTitleToggle(isAuto: boolean) {
		this.props.onAutoTitleChange(isAuto);
	}

	onTitleChange(seoTitle: string) {
		this.props.onTitleChange(seoTitle);
	}

	render() {
		const { t, title, autoTitle, contentTitle } = this.props;

		return (
			<SeoTitle
				value={autoTitle ? contentTitle : title}
				t={t}
				isAutoTitle={autoTitle}
				onAutoTitleChange={this.onAutoSeoTitleToggle.bind(this)}
				onTitleChange={this.onTitleChange.bind(this)}
			/>
		);
	}
}
