import axios, { AxiosInstance } from 'axios';
import { store } from '../../store/store';
import ModelMapper from '../../models/ModelMapper';
import REACT_APP_URLS from '../../global-helpers/global-url.helpers';

export default class CategoriesHttp {
	private instance: AxiosInstance = axios.create();

	constructor() {
		const CONTENT_API_BASE_URL = REACT_APP_URLS.REACT_APP_CONTENT_API_BASE_URL;
		const PROJECT = store.getState().project.currentProject.domain;
		const TOKEN = localStorage.getItem('token');

		this.instance = axios.create({
			baseURL: CONTENT_API_BASE_URL,
			headers: { Authorization: TOKEN, Project: PROJECT, 'X-Project': PROJECT, 'X-SMP-Cache-Disable': true },
		});
	}

	/**
	 * Returns an array containing both the admin categories and all categories
	 * The returned value is an array 0 index is for the admin categories and 1 index is for all categories
	 * @param adminId
	 */
	public getCategoriesAndAdminCategories = (adminId: string) => {
		return Promise.all([this.getAdminCategories(adminId), this.getAllCategories()]).then((resp) => [
			ModelMapper.getNormalizedCategories(resp[0].data.data),
			ModelMapper.getNormalizedCategories(resp[1].data.data),
		]);
	};

	/**
	 * Get all categories that are available for the specified admin id
	 * @param adminId
	 */
	public getAdminCategories = (adminId: string) => {
		return this.instance.get(`/admins/${adminId}/categories?limit=200`);
	};

	/**
	 * Get all available categories
	 */
	private getAllCategories = () => {
		return this.instance.get(`/categories?limit=200`);
	};

	/**
	 * Request all available admins for the project
	 */
	public getAdmins = () => {
		const PROJECT = store.getState().project.currentProject;
		return this.instance.get(`/projects/${PROJECT.id}/admins?limit=200`).then((resp) => resp.data.data);
	};

	/**
	 * Update the category list which the given admin can access
	 */
	public updateAdminCategoryPermissions = (adminId: string, categories: string[]) => {
		const payload = { categories: categories };
		return this.instance.put(`/admins/${adminId}/categories`, payload);
	};
}
