import * as React from 'react';
import { Col, FormGroup, Input, Row, Label } from 'reactstrap';
import Flatpickr from 'react-flatpickr';
import { extractTimeOnly } from './helpers/content-blocks.helper';
import './styles/content-block-edit.component.scss';

type Properties = {
	display: boolean;
	onChange: (startAt: string) => void;
	time: string;
	t: any;
};

export default class StartAt extends React.Component<Properties, any> {
	private onChange = (time: any) => {
		const { onChange } = this.props;
		onChange(extractTimeOnly(time));
	};

	private onReset = () => {
		const { onChange } = this.props;
		onChange(extractTimeOnly('00:00:00'));
	};

	render() {
		const { display, time, t } = this.props;

		return (
			display && (
				<Row>
					<Col>
						<FormGroup className='start-at-time-picker-container'>
							<Label>{t('start_at')}</Label>
							<div className='date-time-picker-container'>
								<Flatpickr
									className='date-picker'
									options={{
										dateFormat: 'H:i:s',
										enableTime: true,
										noCalendar: true,
										enableSeconds: true,
										time_24hr: true,
										defaultHour: 0,
										defaultMinute: 0,
									}}
									placeholder={'00:00:0'}
									value={time}
									onChange={this.onChange}
								/>
								<div
									className='clear-button'
									onClick={() => {
										this.onReset();
									}}
								>
									<i className='fa fa-times close-icon' />
								</div>
							</div>
						</FormGroup>
					</Col>
				</Row>
			)
		);
	}
}
