import React from 'react';
import Select from 'react-select';
import { Label } from 'reactstrap';
import { customOption } from '../shared/custom-select-option';
import * as helpers from './helpers/sport-bookmakers-select.helper';
import { featuresService } from '../../../../../App';
import SportsHttpService from '../../../../../services/rest/sports-http-service';
import SportOddsBookmakerModel from '../../../../../models/sport-odds/sport-odds-bookmaker.model';
import ErrorHandler from '../../v2/partials/error-handler/error-handler';
import { determineFeatureConfigType } from './helpers/sport-bookmakers-select.helper';
import { responseToBookmakerModel } from '../../v2/partials/bookmaker-select/models/bookmaker.mapper';

type Properties = {
	isMulti?: boolean;
	showAllBookmakers: boolean;
	bookmakerSelectionEnabled: boolean;
	value?: SportOddsBookmakerModel;
	valueList?: SportOddsBookmakerModel[];
	onSelect: Function;
	t: any;
	isValid: boolean;
	matchContentData?: boolean;
	contentLanguage: string;
	sportType: string;
	id: string;
	mandatory?: boolean;
};

type State = {
	bookmakerList: SportOddsBookmakerModel[];
};

class SportBookmakerSelect extends React.Component<Properties, State> {
	constructor(props: any) {
		super(props);
		this.state = {
			bookmakerList: [],
		};
	}

	componentDidMount() {
		this.getOdds(false);
	}

	resetSelectedBookmakerState = () => this.props.onSelect([]);

	getOdds = (preselectBookmaker: boolean) => {
		const featureType = determineFeatureConfigType(this.props.sportType);

		if (featureType) {
			const featureConfig = featuresService.getFeatureConfig(featureType);

			SportsHttpService.getOdds(this.props.sportType, featureConfig.oddClient)
				.then((response: any) => {
					if (response && response.data) {
						if (response.data.bookmakers && response.data.bookmakers.length > 0) {
							const bookmakerList = response.data.bookmakers.map((bookmaker: SportOddsBookmakerModel) => {
								return responseToBookmakerModel(bookmaker);
							});

							preselectBookmaker && this.preselectBookmakers([bookmakerList[0]]);
							this.updateBookmakerListState(bookmakerList);
						} else {
							this.updateBookmakerListState([]);
						}
					}
				})
				.catch((e: any) => e);
		}
	};

	preselectBookmakers = (bookmakerList: SportOddsBookmakerModel[]) => {
		this.props.onSelect(bookmakerList);
	};

	updateBookmakerListState = (bookmakerList: SportOddsBookmakerModel[]) => {
		this.setState({ ...this.state, bookmakerList });
	};

	onSingleSelectChange = (selection: any) => {
		this.props.onSelect(selection && selection.data ? [selection.data] : null);
	};

	onMultiSelectChange = (selections: any) => {
		this.props.onSelect(helpers.optionListToBookmakerList(selections));
	};

	noOptions = (inputValue: any) => inputValue && this.props.t('no_options');

	render() {
		const { isMulti, valueList, bookmakerSelectionEnabled, isValid, mandatory } = this.props;

		if (!bookmakerSelectionEnabled) {
			return null;
		}

		if (isMulti) {
			return (
				<>
					<Label htmlFor={this.props.id}>{this.props.t('select_bookmaker')}:</Label>
					<Select
						id={this.props.id}
						options={helpers.oddsBookmakerListToOptionList(this.state.bookmakerList)}
						isMulti
						className='mb-2'
						placeholder={this.props.t('select')}
						noOptionsMessage={this.noOptions}
						value={helpers.oddsBookmakerListToOptionList(valueList)}
						formatOptionLabel={customOption}
						onChange={this.onMultiSelectChange}
					/>
					{mandatory && (
						<ErrorHandler arePropsValid={this.props.isValid} isValueSelected={!valueList} t={this.props.t} errorMessage='field_is_required' />
					)}
				</>
			);
		}

		const isBookmakerSelected = Boolean(valueList && valueList[0] && valueList[0].id && valueList[0].id.length > 0);

		return (
			<>
				<Label htmlFor={this.props.id}>{this.props.t('select_bookmaker')}:</Label>
				<Select
					id={this.props.id}
					isClearable={isBookmakerSelected}
					className='mb-2'
					options={helpers.oddsBookmakerListToOptionList(this.state.bookmakerList)}
					value={valueList ? helpers.oddsBookmakerToOption(valueList[0]) : []}
					placeholder={this.props.t('select')}
					formatOptionLabel={customOption}
					noOptionsMessage={this.noOptions}
					onChange={this.onSingleSelectChange}
				/>
				{mandatory && (
					<ErrorHandler
						arePropsValid={this.props.isValid}
						isValueSelected={!valueList || (valueList && valueList.length === 0)}
						t={this.props.t}
						errorMessage='field_is_required'
					/>
				)}
			</>
		);
	}
}

export default SportBookmakerSelect;
