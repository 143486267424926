import React, { FunctionComponent } from 'react';
import { Col, Row } from 'reactstrap';
import { SelectMenuOptionType } from '../../../../../../models/v2/general/select.model';
import { RefreshTimeValuesType } from './helper';
import RefreshTimeInput from './refresh-time-input.component';

interface Properties {
	value: RefreshTimeValuesType;
	onRefreshTimeChange: (e: SelectMenuOptionType) => void;
}

const RefreshTimeWrapper: FunctionComponent<Properties> = ({ value, onRefreshTimeChange }) => {
	return (
		<Row className='mb-2'>
			<Col>
				<RefreshTimeInput value={value} onRefreshTimeChange={onRefreshTimeChange} />
			</Col>
		</Row>
	);
};

export default RefreshTimeWrapper;
