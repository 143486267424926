import React from 'react';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import TeamSelect from '../../../../partials/team-select/team-select.component';
import MatchSelect from '../../../../partials/match-select/match-select.component';
import BookmakerSelect from '../../../../partials/bookmaker-select/bookmaker-select.component';
import OddsWidgetModel from '../models/odds-widget.model';
import BookmakerModel from '../../../../partials/bookmaker-select/models/bookmaker.model';
import MarketSelect from '../../../../partials/market-select/market-select.component';
import MarketValueSelect from '../../../../partials/market-value-select/market-value-select.component';
import { blockManagementService, blockWrapperService } from '../../../../subcomponents/blocky.component';
import { Properties, RequiredProperties, State } from './properties/odds-widget-edit.properties';
import TeamModel from '../../../../partials/team-select/models/team.model';
import { MarketTypes } from '../../../../constants/market.types.constants';
import MatchModel from '../../../../partials/match-select/models/match.model';
import BlockValidation from '../../../../helpers/block-validation.helper';
import { marketOptions } from '../../../../partials/market-select/helpers/market-select.helpers';
import { featuresService } from '../../../../../../../App';
import { FeatureTypes } from '../../../../../../../services/feature-service/features.enum';
import { prefillMatchFromRelated } from '../../helpers/widgets-prefill.helpers';
import ThreeLetterCodeTypeSelect from '../../../../partials/three-letter-code-type-select/three-letter-code-type-select';

class OddsWidgetEdit extends React.Component<Properties, State> {
	constructor(props: Properties) {
		super(props);
		const matchContentData =
			props.contentData && props.contentData.related && props.contentData.related.matches
				? prefillMatchFromRelated(props.contentData.related.matches)
				: MatchModel.builder().withHomeTeam(TeamModel.builder().build()).build();

		const match = this.props.match ? this.props.match : matchContentData;
		this.state = {
			oddsWidgetModel:
				props.block && props.block.data.preview && props.block.data.preview.odds
					? OddsWidgetModel.builder(props.block.data.preview.odds)
							.withMatch(props.block.data.preview.odds.match ? props.block.data.preview.odds.match : match)
							.build()
					: OddsWidgetModel.builder()
							.withTeam(this.props.team ? this.props.team : matchContentData.homeTeam)
							.withMatch(match)
							.withBookmakers(null)
							.withMarket({ name: '1x2' })
							.withCanSelectMarkets(false)
							.withDisplayHeader(false)
							.withThreeLetterCodeType('')
							.build(),
			isValid: true,
		};
	}

	componentDidMount(): void {
		if (this.props.isStandAlone) {
			blockWrapperService.registerOnSaveFunc({ blockId: this.props.block.id, func: this.onSave });
		}
	}

	onSave = (index: number) => {
		const data = { oddsModel: this.state.oddsWidgetModel };
		const validation = new BlockValidation();
		const isValid = validation.validate(this.props.block, data);
		const bookmakerSelectionEnabled = featuresService.areFeaturesEnabled([FeatureTypes.ALLOW_BOOKMAKER_SELECTION]);
		this.setState({ ...this.state, isValid });

		if (isValid) {
			const block = Object.assign({}, this.props.block);

			block.data.preview = {
				odds: OddsWidgetModel.builder(this.state.oddsWidgetModel)
					.withBookmakers(bookmakerSelectionEnabled ? this.state.oddsWidgetModel.bookmakers : [])
					.withMarket(bookmakerSelectionEnabled ? this.state.oddsWidgetModel.market : { name: '1x2' })
					.build(),
			};
			blockManagementService.blockUpdate(block, index);
		} else {
			return isValid;
		}
	};

	componentDidUpdate(prevProps: any, prevState: any) {
		if (
			this.props.onOddsWidgetChange &&
			this.props.team &&
			(prevProps.team !== this.props.team || prevProps.team.id !== this.props.team.id)
		) {
			this.updateWidgetModelState(OddsWidgetModel.builder(this.state.oddsWidgetModel).withTeam(this.props.team).build());
		}

		if (this.props.onOddsWidgetChange && this.props.match && prevProps.match.id !== this.props.match.id) {
			this.updateWidgetModelState(OddsWidgetModel.builder(this.state.oddsWidgetModel).withMatch(this.props.match).build());
		}

		if (
			prevState.oddsWidgetModel.bookmakers &&
			prevState.oddsWidgetModel.bookmakers.length === 0 &&
			this.state.oddsWidgetModel.bookmakers &&
			this.state.oddsWidgetModel.bookmakers.length > 0
		) {
			this.updateWidgetModelState(
				OddsWidgetModel.builder(this.state.oddsWidgetModel).withMainBookmaker(this.state.oddsWidgetModel.bookmakers[0]).build(),
			);
		}
	}

	componentWillUnmount(): void {
		if (this.props.isStandAlone) {
			blockWrapperService.unregisterOnSaveFunc(this.props.block.id);
		}
	}

	updateWidgetModelState = (model: OddsWidgetModel) => {
		if (!this.props.isStandAlone) {
			this.props.onOddsWidgetChange(model);
		}
		this.setState({ ...this.state, oddsWidgetModel: model });
	};

	onTeamChange = (team: TeamModel) => {
		this.updateWidgetModelState(OddsWidgetModel.builder(this.state.oddsWidgetModel).withTeam(team).build());
	};

	onMatchChange = (match: MatchModel) => {
		this.updateWidgetModelState(OddsWidgetModel.builder(this.state.oddsWidgetModel).withMatch(match).build());
	};

	onBookmakerChange = (bookmakers: BookmakerModel[]) => {
		if (bookmakers.length === 0) {
			this.updateWidgetModelState(
				OddsWidgetModel.builder(this.state.oddsWidgetModel)
					.withBookmakers(bookmakers)
					.withMainBookmaker(BookmakerModel.builder().build())
					.withMarket('')
					.build(),
			);
		} else {
			this.updateWidgetModelState(OddsWidgetModel.builder(this.state.oddsWidgetModel).withBookmakers(bookmakers).build());
		}
	};

	onMainBookmakerChange = (bookmaker: BookmakerModel) => {
		this.updateWidgetModelState(OddsWidgetModel.builder(this.state.oddsWidgetModel).withMainBookmaker(bookmaker).build());
	};

	onDisplayHeaderChange = (e: any) => {
		this.updateWidgetModelState(OddsWidgetModel.builder(this.state.oddsWidgetModel).withDisplayHeader(e.target.checked).build());
	};

	onCanSelectMarketsChange = (e: any) => {
		this.updateWidgetModelState(OddsWidgetModel.builder(this.state.oddsWidgetModel).withCanSelectMarkets(e.target.checked).build());
	};

	onMarketChange = (marketName: string) => {
		this.updateWidgetModelState(OddsWidgetModel.builder(this.state.oddsWidgetModel).withMarket({ name: marketName }).build());
	};

	onMarketValueChange = (market: any) => {
		this.updateWidgetModelState(
			OddsWidgetModel.builder(this.state.oddsWidgetModel)
				.withMarket({ name: this.state.oddsWidgetModel.market.name, value: market.key })
				.build(),
		);
	};

	onThreeLetterCodeTypeChange = (value: any) => {
		this.updateWidgetModelState(OddsWidgetModel.builder(this.state.oddsWidgetModel).withThreeLetterCodeType(value).build());
	};

	render() {
		const { oddsWidgetModel } = this.state;
		const bookmakerSelectionEnabled = featuresService.areFeaturesEnabled([FeatureTypes.ALLOW_BOOKMAKER_SELECTION]);
		const matchContentData =
			this.props.contentData && this.props.contentData.related && this.props.contentData.related.matches
				? prefillMatchFromRelated(this.props.contentData.related.matches)
				: MatchModel.builder().build();

		return (
			<>
				{this.props.displayOddsLabel && (
					<Row className='mb-1 mt-4'>
						<Col>
							<h5>{this.props.t('odds_data')}</h5>
						</Col>
					</Row>
				)}
				{this.props.useTeamSelect && (
					<Row>
						<Col>
							<FormGroup>
								<TeamSelect
									inputId='odds-team-select'
									isMulti={false}
									t={this.props.t}
									value={oddsWidgetModel.team}
									onSelect={this.onTeamChange}
									isValid={!this.props.isStandAlone ? this.props.isValid : this.state.isValid}
								/>
							</FormGroup>
						</Col>
					</Row>
				)}
				{this.props.useMatchSelect && (
					<Row>
						<Col>
							<FormGroup>
								<MatchSelect
									isValid={!this.props.isStandAlone ? this.props.isValid : this.state.isValid}
									t={this.props.t}
									value={oddsWidgetModel.match}
									teamId={oddsWidgetModel.team ? oddsWidgetModel.team.id : null}
									onSelect={this.onMatchChange}
									autoSelectFirstMatch={this.props.autoSelectFirstMatch}
								/>
							</FormGroup>
						</Col>
					</Row>
				)}
				{bookmakerSelectionEnabled && (
					<Row>
						<Col>
							<FormGroup>
								<BookmakerSelect
									matchContentData={!!matchContentData.id}
									isMulti
									isValid={!this.props.isStandAlone ? this.props.isValid : this.state.isValid}
									showAllBookmakers={false}
									bookmakerSelectionEnabled={bookmakerSelectionEnabled}
									t={this.props.t}
									eventId={oddsWidgetModel.match ? oddsWidgetModel.match.id : null}
									valueList={oddsWidgetModel.bookmakers ? oddsWidgetModel.bookmakers : null}
									value={oddsWidgetModel.mainBookmaker}
									onSelect={this.onBookmakerChange}
								/>
							</FormGroup>
						</Col>
					</Row>
				)}
				{bookmakerSelectionEnabled && this.props.selectMainBookmaker && oddsWidgetModel.bookmakers && oddsWidgetModel.bookmakers.length > 0 && (
					<Row>
						<Col>
							<FormGroup>
								<BookmakerSelect
									isValid={!this.props.isStandAlone ? this.props.isValid : this.state.isValid}
									valueList={null}
									showAllBookmakers={false}
									bookmakerSelectionEnabled
									t={this.props.t}
									isMulti={false}
									eventId={oddsWidgetModel && oddsWidgetModel.match ? oddsWidgetModel.match.id : null}
									value={oddsWidgetModel.mainBookmaker && oddsWidgetModel.bookmakers ? oddsWidgetModel.mainBookmaker : null}
									onSelect={this.onMainBookmakerChange}
								/>
							</FormGroup>
						</Col>
					</Row>
				)}
				{!bookmakerSelectionEnabled && this.props.selectMainBookmaker && (
					<Row>
						<Col>
							<FormGroup>
								<BookmakerSelect
									isValid={!this.props.isStandAlone ? this.props.isValid : this.state.isValid}
									valueList={null}
									showAllBookmakers
									bookmakerSelectionEnabled
									t={this.props.t}
									isMulti={false}
									eventId={null}
									value={oddsWidgetModel.mainBookmaker}
									onSelect={this.onMainBookmakerChange}
								/>
							</FormGroup>
						</Col>
					</Row>
				)}
				{bookmakerSelectionEnabled && oddsWidgetModel.bookmakers && oddsWidgetModel.bookmakers.length > 0 && (
					<Row>
						<Col>
							<FormGroup>
								<MarketSelect
									t={this.props.t}
									value={oddsWidgetModel && oddsWidgetModel.market ? oddsWidgetModel.market.name : marketOptions(this.props.t)[0].value}
									onMarketSelect={this.onMarketChange}
									isWidgetV2={false}
								/>
							</FormGroup>
						</Col>
					</Row>
				)}
				{!bookmakerSelectionEnabled &&
					oddsWidgetModel.bookmakers &&
					Object.entries(oddsWidgetModel.bookmakers).length > 0 &&
					oddsWidgetModel.market &&
					oddsWidgetModel.market.name &&
					oddsWidgetModel.market.name === MarketTypes.overUnder && (
						<Row>
							<Col>
								<FormGroup>
									<MarketValueSelect
										type={this.props.widgetType ? this.props.widgetType : ''}
										mainBookmaker={oddsWidgetModel.mainBookmaker}
										t={this.props.t}
										bookmakers={oddsWidgetModel.bookmakers}
										market={oddsWidgetModel.market}
										onMarketValueSelect={this.onMarketValueChange}
									/>
								</FormGroup>
							</Col>
						</Row>
					)}
				{oddsWidgetModel.displayMatchHeader && oddsWidgetModel.team.id && (
					<ThreeLetterCodeTypeSelect
						t={this.props.t}
						isClearable={true}
						onThreeLetterCodeTypeChange={this.onThreeLetterCodeTypeChange}
						selectedThreeLetterCodeType={oddsWidgetModel.threeLetterCodeType}
					/>
				)}
				{this.props.displayHeader && (
					<Row className='mb-2'>
						<Col>
							<FormGroup key='display-header' check className='radio'>
								<Input
									key='display-header-input'
									className='form-check-input'
									type='checkbox'
									id={`display-header-checkbox-${this.props.block.id}`}
									name='radios'
									onChange={this.onDisplayHeaderChange}
									checked={oddsWidgetModel.displayMatchHeader}
								/>
								<Label key='display-header-label' check className='form-check-label' for={`display-header-checkbox-${this.props.block.id}`}>
									{this.props.t('display_header')}
								</Label>
							</FormGroup>
						</Col>
					</Row>
				)}
				<Row className='mb-2'>
					<Col>
						<FormGroup key='can-select-markets' check className='radio'>
							<Input
								key='can-select-markets-input'
								className='form-check-input'
								type='checkbox'
								id={`can-select-markets-checkbox-${this.props.block.id}`}
								name='radios'
								onChange={this.onCanSelectMarketsChange}
								checked={oddsWidgetModel.canSelectMarkets}
							/>
							<Label key='can-select-markets-label' check className='form-check-label' for={`can-select-markets-checkbox-${this.props.block.id}`}>
								{this.props.t('display_market_selection')}
							</Label>
						</FormGroup>
					</Col>
				</Row>
			</>
		);
	}
}

export default OddsWidgetEdit as React.ComponentType<RequiredProperties>;
