import {
	USER_STATISTICS_CONTENT_RECEIVED,
	ALL_STATISTICS_CONTENT_RECEIVED,
	PLAYER_STATISTICS_RECEIVED,
	CATEGORY_RELATED_STATISTICS_RECEIVED,
	TEAM_STATISTICS_RECEIVED,
} from '../action-creators/statistics-creator';
import Statistics from '../../models/statistics/statistics';

interface InitialState {
	userStatistic: Statistics;
	allStatistic: Statistics;
	playerStatistic: Statistics;
	teamStatistic: Statistics;
	categoryStatistic: Statistics;
}

const initialState: InitialState = {
	userStatistic: Statistics.builder().build(),
	allStatistic: Statistics.builder().build(),
	playerStatistic: Statistics.builder().build(),
	teamStatistic: Statistics.builder().build(),
	categoryStatistic: Statistics.builder().build(),
};

function statisticsReducer(state: any = initialState, action: any) {
	if (action.type === USER_STATISTICS_CONTENT_RECEIVED) {
		state = Object.assign({}, state, {
			userStatistic: action.payload.userStatistic.data,
		});
	}

	if (action.type === ALL_STATISTICS_CONTENT_RECEIVED) {
		state = Object.assign({}, state, {
			allStatistic: action.payload.allStatistic.data,
		});
	}

	if (action.type === PLAYER_STATISTICS_RECEIVED) {
		state = Object.assign({}, state, {
			playerStatistic: action.payload.playerStatistic.data,
		});
	}

	if (action.type === TEAM_STATISTICS_RECEIVED) {
		state = Object.assign({}, state, {
			teamStatistic: action.payload.teamStatistic.data,
		});
	}

	if (action.type === CATEGORY_RELATED_STATISTICS_RECEIVED) {
		state = Object.assign({}, state, {
			categoryStatistic: action.payload.categoryStatistic.data,
		});
	}

	return state;
}

export default statisticsReducer;
