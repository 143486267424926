import { AUTHENTICATED } from '../action-creators/AuthActionCreator';
import { USER_LOGOUT } from '../action-creators/ProfileActionCreators';

interface InitialState {
	isAuthenticated: boolean;
}
const initialState: InitialState = {
	isAuthenticated: false,
};

function authReducer(state: any = initialState, action: any) {
	if (action.type === AUTHENTICATED) {
		return Object.assign({}, state, {
			isAuthenticated: action.payload,
		});
	}

	if (action.type === USER_LOGOUT) {
		state = initialState;
	}

	return state;
}

export default authReducer;
