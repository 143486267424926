import React from 'react';
import Select from 'react-select';
import OddClientBookmakersModel from './models/odd-client-bookmakers.model';
import { customOption } from '../shared/custom-select-option';

type Properties = {
	bookmakers: OddClientBookmakersModel[];
	value: any;
	isMulti: boolean;
	isClearable?: boolean;
	onChange: (args: any) => void;
};

export const OddClientBookmakersView: React.FunctionComponent<Properties> = ({ bookmakers, value, isMulti, onChange, isClearable }) => {
	return (
		<Select
			value={value}
			isMulti={isMulti}
			options={bookmakers}
			onChange={onChange}
			isClearable={isClearable}
			formatOptionLabel={customOption}
		/>
	);
};
