import React from 'react';
import { Col, FormGroup, Label, Row, Input } from 'reactstrap';
import PlayerSelect from '../../../../partials/player-select/player-select.component';
import BlockValidation from '../../../../helpers/block-validation.helper';
import PlayerModel from '../../../../partials/player-select/models/player.model';
import { Properties, State } from './properties/player-career-edit.properties';
import { blockWrapperService, blockManagementService } from '../../../../subcomponents/blocky.component';
import PlayerCareer from '../models/player-career-widget.model';
import ChampionshipFilterSelect from '../../../../partials/championship-filter-select/campionship-filter-select.component';
import StatisticsColumnsSelect from '../../../../partials/statistics-columns-select/statistics-columns.component';
import TableColumnsSelect from '../../../../partials/table-columns-select/table-columns-select.component';
import ThreeLetterCodeTypeSelect from '../../../../partials/three-letter-code-type-select/three-letter-code-type-select';

export default class PlayerCareerEdit extends React.Component<Properties, State> {
	constructor(props: any) {
		super(props);
		this.state = {
			playerCareer:
				props.block && props.block.data && props.block.data.preview && props.block.data.preview.playerCareer
					? PlayerCareer.builder(props.block.data.preview.playerCareer).build()
					: PlayerCareer.builder()
							.withChampionshipFilters([])
							.withStatisticsColumns([])
							.withTableColumns([])
							.withThreeLetterCodeType('')
							.build(),

			isValid: true,
		};
	}

	componentDidMount(): void {
		blockWrapperService.registerOnSaveFunc({ blockId: this.props.block.id, func: this.onSave });
	}

	onSave = (index: number) => {
		const data = { playerCareer: this.state.playerCareer };
		const validation = new BlockValidation();
		const isValid = validation.validate(this.props.block, data);
		this.setState({ ...this.state, isValid });

		if (isValid) {
			const block = Object.assign({}, this.props.block);
			block.data.preview = { playerCareer: this.state.playerCareer };

			blockManagementService.blockUpdate(block, index);
		} else {
			return isValid;
		}
	};

	componentWillUnmount(): void {
		blockWrapperService.unregisterOnSaveFunc(this.props.block.id);
	}

	onPlayerChange = (player: PlayerModel) => {
		this.setState({
			...this.state,
			playerCareer: PlayerCareer.builder(this.state.playerCareer)
				.withPlayer(player)
				.withChampionshipFilters([])
				.withStatisticsColumns([])
				.withTableColumns([])
				.build(),
		});
	};

	onCareerFilterChange = (careerFilters: any[]) => {
		careerFilters = careerFilters ? careerFilters : [];
		this.setState({
			...this.state,
			playerCareer: PlayerCareer.builder(this.state.playerCareer).withChampionshipFilters(careerFilters).build(),
		});
	};

	onStatisticsColumnsChange = (statisticsColumns: any[]) => {
		statisticsColumns = statisticsColumns ? statisticsColumns : [];
		this.setState({
			...this.state,
			playerCareer: PlayerCareer.builder(this.state.playerCareer).withStatisticsColumns(statisticsColumns).build(),
		});
	};

	onTableColumnsChange = (tableColumns: any[]) => {
		tableColumns = tableColumns ? tableColumns : [];
		this.setState({
			...this.state,
			playerCareer: PlayerCareer.builder(this.state.playerCareer).withTableColumns(tableColumns).build(),
		});
	};

	onThreeLetterCodeTypeChange = (value: any) => {
		this.setState({
			...this.state,
			playerCareer: PlayerCareer.builder(this.state.playerCareer).withThreeLetterCodeType(value).build(),
		});
	};

	render() {
		const { playerCareer } = this.state;
		const { t } = this.props;

		return (
			<>
				<Row>
					<Col>
						<FormGroup>
							<PlayerSelect
								inputId='player-career-player-select'
								t={t}
								value={playerCareer.player ? playerCareer.player : {}}
								onSelect={(player: PlayerModel) => this.onPlayerChange(player)}
								isValid={this.state.isValid}
							/>
						</FormGroup>
					</Col>
				</Row>
				{playerCareer.player && (
					<>
						<Row>
							<Col>
								<FormGroup>
									<Label>{t('championship_filter')}</Label>
									<ChampionshipFilterSelect
										t={this.props.t}
										isMulti={true}
										value={playerCareer.championshipFilters ? playerCareer.championshipFilters : []}
										onChampionshipFilterSelect={this.onCareerFilterChange}
									/>
								</FormGroup>
							</Col>
						</Row>
						<Row>
							<Col>
								<FormGroup>
									<Label>{t('statistic_columns')}</Label>
									<StatisticsColumnsSelect
										t={this.props.t}
										isMulti={true}
										value={playerCareer.statisticColumns ? playerCareer.statisticColumns : []}
										onStatisticsColumnsSelect={this.onStatisticsColumnsChange}
										playerPosition={playerCareer.player.position ? playerCareer.player.position : ''}
									/>
								</FormGroup>
							</Col>
						</Row>
						<Row>
							<Col>
								<FormGroup>
									<Label>{t('table_columns')}</Label>
									<TableColumnsSelect
										t={this.props.t}
										isMulti={true}
										value={playerCareer.tableColumns ? playerCareer.tableColumns : []}
										onTableColumnsSelect={this.onTableColumnsChange}
									/>
								</FormGroup>
							</Col>
						</Row>
						<ThreeLetterCodeTypeSelect
							t={this.props.t}
							isClearable={true}
							onThreeLetterCodeTypeChange={this.onThreeLetterCodeTypeChange}
							selectedThreeLetterCodeType={playerCareer.threeLetterCodeType}
						/>
					</>
				)}
			</>
		);
	}
}
