import ImageBlockModel from './image-block.model';
import { ImageBlockJson } from './image-block.json';
import Image from '../../../../../../../models/image/Image';
import Author from '../../../../../../../models/author/Author';

export default class ImageBlockBuilder {
	private json: ImageBlockJson;

	constructor(imageBlock?: ImageBlockModel | ImageBlockJson) {
		if (imageBlock && imageBlock instanceof ImageBlockModel) {
			this.json = imageBlock.toJson();
		} else if (imageBlock) {
			this.json = imageBlock;
		} else {
			this.json = {} as ImageBlockJson;
		}
	}

	withImage(image: Image): ImageBlockBuilder {
		this.json.image = image;

		return this;
	}

	withWidth(width: string): ImageBlockBuilder {
		this.json.width = width;

		return this;
	}

	withAlignment(alignment: string): ImageBlockBuilder {
		this.json.alignment = alignment;

		return this;
	}

	withAlt(alt: string): ImageBlockBuilder {
		this.json.alt = alt;

		return this;
	}

	withCaption(caption: string): ImageBlockBuilder {
		this.json.caption = caption;

		return this;
	}

	withDescription(description: any): ImageBlockBuilder {
		this.json.description = description;

		return this;
	}

	withAuthors(authors: Author[]): ImageBlockBuilder {
		this.json.authors = authors;

		return this;
	}

	withImageRatio(imageRatio: any): ImageBlockBuilder {
		this.json.imageRatio = imageRatio;

		return this;
	}

	withAddDescriptionToMedia(addDescriptionToMedia: boolean): ImageBlockBuilder {
		this.json.addDescriptionToMedia = addDescriptionToMedia;

		return this;
	}

	withAddWatermarkChecked(addWatermarkChecked: boolean): ImageBlockBuilder {
		this.json.addWatermarkChecked = addWatermarkChecked;

		return this;
	}

	withRemoveWatermarkChecked(removeWatermarkChecked: boolean): ImageBlockBuilder {
		this.json.removeWatermarkChecked = removeWatermarkChecked;

		return this;
	}

	withLink(link: string): ImageBlockBuilder {
		this.json.link = link;

		return this;
	}

	withLinkText(linkText: string): ImageBlockBuilder {
		this.json.linkText = linkText;

		return this;
	}

	withLinkOpenType(linkOpenType: string): ImageBlockBuilder {
		this.json.linkOpenType = linkOpenType;

		return this;
	}

	withLinkContent(linkContent: string): ImageBlockBuilder {
		this.json.linkContent = linkContent;

		return this;
	}

	build(): ImageBlockModel {
		return ImageBlockModel.fromJson(this.json);
	}
}
