import Seo from '../seo/Seo';
import Urls from '../urls/Urls';
import { CategoryJson } from './CategoryJson';
import CategoryBuilder from './CategoryBuilder';
import MainMedia from '../mainmedia/MainMedia';

export default class Category {
	readonly id: string;
	readonly title: string;
	readonly description: string;
	readonly active: boolean;
	readonly parentId: string;
	readonly generic: string;
	readonly seo: Seo;
	readonly urls: Urls;
	readonly mainMedia: MainMedia[] = [];
	readonly createdAt: string;
	readonly updatedAt: string;
	readonly subIndent: string;

	private constructor(
		id: string,
		title: string,
		description: string,
		active: boolean,
		parentId: string,
		generic: string,
		seo: Seo,
		urls: Urls,
		createdAt: string,
		updatedAt: string,
		subIndent: string,
		mainMedia: MainMedia[],
	) {
		this.id = id;
		this.title = title;
		this.description = description;
		this.active = active;
		this.parentId = parentId;
		this.generic = generic;
		this.seo = seo;
		this.urls = urls;
		this.mainMedia = mainMedia;
		this.createdAt = createdAt;
		this.updatedAt = updatedAt;
		this.subIndent = subIndent;
	}

	toJSON(): CategoryJson {
		return {
			id: this.id,
			title: this.title,
			description: this.description,
			active: this.active,
			parentId: this.parentId,
			generic: this.generic,
			seo: this.seo,
			urls: this.urls,
			subIndent: this.subIndent,
			createdAt: this.createdAt,
			updatedAt: this.updatedAt,
			mainMedia: this.mainMedia,
		};
	}

	static fromJSON(json: CategoryJson): Category {
		return new Category(
			json.id,
			json.title,
			json.description,
			json.active,
			json.parentId,
			json.generic,
			json.seo,
			json.urls,
			json.createdAt,
			json.updatedAt,
			json.subIndent,
			json.mainMedia,
		);
	}

	static builder(author?: Category): CategoryBuilder {
		return new CategoryBuilder(author);
	}
}
