import GameModel from '../../../../../../../models/v2/game/game.model';
import PlayerModel from '../../../../../../../models/v2/player/entity/player.model';
import { TennisWidgetJson } from './tennis-widget.json';
import TennisSingleEventBuilder from './tennis-single-event.builder';
import { RefreshTimeValuesType } from '../../refresh-time-input/helper';

export default class TennisSingleEventModel {
	readonly match: GameModel;
	readonly players: PlayerModel[];
	readonly displayOdds: boolean = false;
	readonly refreshTime: RefreshTimeValuesType;

	private constructor(match: GameModel, players: PlayerModel[], displayOdds: boolean, refreshTime: RefreshTimeValuesType) {
		this.match = match;
		this.players = players;
		this.displayOdds = displayOdds;
		this.refreshTime = refreshTime;
	}

	toJson(): TennisWidgetJson {
		return {
			match: this.match,
			players: this.players,
			displayOdds: this.displayOdds,
			refreshTime: this.refreshTime,
		};
	}

	static fromJson(json: TennisWidgetJson): TennisSingleEventModel {
		return new TennisSingleEventModel(json.match, json.players, json.displayOdds, json.refreshTime);
	}

	static builder(tennisSingleEventWidget?: TennisSingleEventModel): TennisSingleEventBuilder {
		return new TennisSingleEventBuilder(tennisSingleEventWidget);
	}
}
