export const search = (input: string, t: any, itemsList: any, prop: string): Promise<any[]> => {
	return new Promise((accept: any) => {
		let list = [];
		const SEARCHED_LIST = JSON.parse(JSON.stringify(itemsList));

		if (input && input.length > 0) {
			const escapedText = input.replace(/([.^$|*+?()\[\]{}\\-])/g, '\\$1');
			const regExp = new RegExp(`${escapedText}`, 'i');
			list = SEARCHED_LIST.filter((item: any) => regExp.test(t(item[prop])));
		} else {
			list = itemsList;
		}
		accept(list);
	});
};
