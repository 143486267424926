import React from 'react';
import Project from '../../../../../models/project/Project';
import AsyncImageContainer from '../../../../Partials/BaseComponents/AsyncImage/AsyncImageContainer';
import { Button, Col } from 'reactstrap';
import ListItemMeta from '../../../../../models/list/list-item/list-item-meta/ListItemMeta';
import './Scss/ContentItemOverride.scss';
import moment from 'moment';

type Properties = {
	meta: ListItemMeta;
	project: Project;
	t: any;
	onClear: () => any;
};

function itemHasOverrideValues(meta: ListItemMeta): boolean {
	if (meta) {
		const hasTitle = meta.title !== undefined && meta.title !== null && meta.title.length > 0;
		const hasSubtitle = meta.subtitle !== undefined && meta.subtitle !== null && meta.subtitle.length > 0;
		const hasImageId = meta.imageId !== undefined && meta.imageId !== null && meta.imageId.length > 0;
		const hasDateTo = meta.to !== undefined && meta.to !== null && meta.to.length > 0;
		const hasDateFrom = meta.from !== undefined && meta.from !== null && meta.from.length > 0;

		return hasTitle || hasSubtitle || hasImageId || hasDateFrom || hasDateTo;
	}

	return false;
}

function displayImageIfAvailable(imageId: string, projectId: string) {
	if (imageId !== undefined && imageId.length > 0) {
		return <AsyncImageContainer imageId={imageId} projectId={projectId} imageClassName={'max-width-100'} />;
	}

	return null;
}

export const ContentItemOverride: React.FunctionComponent<Properties> = ({ meta, project, t, onClear }) => {
	if (!itemHasOverrideValues(meta)) {
		return null;
	}

	const formatDateDisplay = (date: string): string => {
		return `${moment(date).format('DD.MM.YYYY HH:mm')} (DD.MM.YYYY)`;
	};

	return (
		<div className={'overridden-content-item bg-light p-4 mt-3 mx-4 rounded position-relative'}>
			<h6 className={'font-weight-bold mb-3'}>{t('overridden_content')}</h6>
			<div className='list-content d-flex justify-content-between'>
				<div className={'d-inline-block'}>
					<div className={'d-inline-block image-wrapper mr-3'}>{displayImageIfAvailable(meta.imageId, project.domain)}</div>
					<div className={'d-inline-block content-wrapper'}>
						{meta.title && meta.title.length > 0 && (
							<h5>
								{t('title')}: <strong>{meta.title}</strong>
							</h5>
						)}
						{meta.subtitle && meta.subtitle.length > 0 && (
							<p>
								{t('summary')}: <strong>{meta.subtitle}</strong>
							</p>
						)}
						{meta.from && (
							<p>
								{t('add_from')}: <strong>{formatDateDisplay(meta.from)}</strong>
							</p>
						)}
						{meta.to && (
							<p>
								{t('added_until')}: <strong>{formatDateDisplay(meta.to)}</strong>
							</p>
						)}
					</div>
				</div>
			</div>
			<div className='overriding-actions position-absolute'>
				<Button style={{ pointerEvents: 'all' }} size={'sm'} color={'danger'} onClick={() => onClear()}>
					<i className={'fa fa-trash'}></i> {t('clear')}
				</Button>
			</div>
		</div>
	);
};
