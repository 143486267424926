import { call, put, select, takeEvery } from 'redux-saga/effects';
import HttpService from '../../services/rest/HttpService';
import {
	ARTICLE_ENTITY_CREATE,
	ARTICLE_ENTITY_DELETE,
	ARTICLE_ENTITY_REQUEST,
	ARTICLE_ENTITY_UPDATE,
	ARTICLE_LISTING_REQUEST,
	ARTICLE_SEARCH,
	returnObjectForArticleAlreadyExists,
	returnObjectForArticleContentStatisticsReceived,
	returnObjectForArticleDeleteSuccess,
	returnObjectForArticleLEntityCreateFailed,
	returnObjectForArticleLEntityCreateSuccess,
	returnObjectForArticleLEntityReceived,
	returnObjectForArticleLEntityRelatedCreateSuccess,
	returnObjectForArticleLEntityRelatedReceived,
	returnObjectForArticleLEntityRelatedUpdateSuccess,
	returnObjectForArticleLEntityUpdateFailed,
	returnObjectForArticleLEntityUpdateSuccess,
	returnObjectForArticleListingReceived,
	returnObjectForContentStatisticsArticleEntityReceived,
	triggerArticleCreateResourcesSuccess,
} from '../action-creators/ArticleActionCreator';
import { searchIsApplied, toggleContentTypeLoadingState, toggleLoadingState, toggleModal } from '../action-creators/UiActionCreator';
import { onError } from '../action-creators/GeneralActions';
import { actionService, featuresService, multiLingualService } from '../../App';
import { returnObjectForListEntityUpdate } from '../action-creators/ListActionCreators';
import List from '../../models/list/List';
import ListItem from '../../models/list/list-item/ListItem';
import { FeatureTypes } from '../../services/feature-service/features.enum';
import { extractIds } from '../../views/Resources/Articles/Helpers/ArticleHelper';
import { getSports, modifyContentResponseAsRelated, transferRefactoredWidgetsAsV2 } from './helpers/saga.helper';
import { ContentTypes } from '../../constants/content-types';
import { toast } from 'react-toastify';
import i18n from '../../i18n';

function* fetchArticles(action: any) {
	yield put(toggleLoadingState(true));
	let articlesResponse: any = {};

	try {
		let headers = { Project: action.payload.project.domain };
		let contentLang = multiLingualService.checkIfProjectIsMultiLingual(action.payload.project.languages)
			? action.payload.project.languages.defaultLanguageCode.languageCode
			: action.payload.project.language;
		let contentLangQuery = `language=${contentLang}`;

		const constructURL = action.payload.text && action.payload.text.length > 0 ? `/search?query=${action.payload.text}&` : '?';

		if (multiLingualService.checkIfProjectIsMultiLingual(action.payload.project.languages)) {
			articlesResponse = yield call(
				HttpService.get,
				`/v2/articles${constructURL}${contentLangQuery}&page=${action.payload.page}`,
				null,
				headers,
			);
		} else {
			articlesResponse = yield call(HttpService.get, `/v2/articles${constructURL}page=${action.payload.page}`, null, headers);
		}

		yield put(returnObjectForArticleListingReceived(articlesResponse));
		yield put(toggleLoadingState(false));
	} catch (error) {
		yield put(onError(error));
	}

	try {
		if (
			featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.CONTENT_STATISTICS) &&
			articlesResponse &&
			articlesResponse.data &&
			articlesResponse.data.data &&
			articlesResponse.data.data.length > 0
		) {
			const contentStatisticsConfig = featuresService.getFeatureConfig(FeatureTypes.CONTENT_STATISTICS);
			const articlesContentStatistics = yield call(
				HttpService.getContentStatistics,
				contentStatisticsConfig.request_headers[0],
				`${contentStatisticsConfig.url}?articles=${extractIds(articlesResponse.data.data)}`,
			);
			yield put(returnObjectForArticleContentStatisticsReceived(articlesResponse.articles, articlesContentStatistics.data));
		}
	} catch (error) {
		yield put(onError(error));
	}
}

function* searchArticles(action: any) {
	yield put(toggleLoadingState(true));
	let articlesResponse: any = {};

	try {
		let headers = { Project: action.payload.project.domain };
		let contentLang = multiLingualService.checkIfProjectIsMultiLingual(action.payload.project.languages)
			? action.payload.project.languages.defaultLanguageCode.languageCode
			: action.payload.project.language;
		let contentLangQuery = `&language=${contentLang}`;

		if (multiLingualService.checkIfProjectIsMultiLingual(action.payload.project.languages)) {
			articlesResponse = yield call(HttpService.get, `/v2/articles/search?query=${action.payload.text}${contentLangQuery}`, null, headers);
		} else {
			articlesResponse = yield call(HttpService.get, `/v2/articles/search?query=${action.payload.text}`, null, headers);
		}

		yield put(returnObjectForArticleListingReceived(articlesResponse));
		yield put(toggleLoadingState(false));
		yield put(searchIsApplied());
	} catch (error) {
		yield put(onError(error));
	}

	try {
		if (
			featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.CONTENT_STATISTICS) &&
			articlesResponse &&
			articlesResponse.data &&
			articlesResponse.data.data &&
			articlesResponse.data.data.length > 0
		) {
			const contentStatisticsConfig = featuresService.getFeatureConfig(FeatureTypes.CONTENT_STATISTICS);
			const articlesContentStatistics = yield call(
				HttpService.getContentStatistics,
				contentStatisticsConfig.request_headers[0],
				`${contentStatisticsConfig.url}?articles=${extractIds(articlesResponse.data.data)}`,
			);
			yield put(returnObjectForArticleContentStatisticsReceived(articlesResponse.articles, articlesContentStatistics.data));
		}
	} catch (error) {
		yield put(onError(error));
	}
}

function* fetchArticle(action: any) {
	yield put(toggleLoadingState(true));
	yield put(toggleContentTypeLoadingState(ContentTypes.ARTICLE, true));
	let articleResponse: any = {};

	try {
		let headers = { Project: action.payload.project.domain };
		articleResponse = yield call(HttpService.get, `/articles/${action.payload.id}?optional_data=related_content`, null, headers);
		// format old article's refactored widgets as V2
		if (articleResponse && articleResponse.data && articleResponse.data.data && articleResponse.data.data.body) {
			articleResponse.data.data.body = transferRefactoredWidgetsAsV2(articleResponse.data.data.body);
		}

		// set article without related
		yield put(returnObjectForArticleLEntityReceived(articleResponse));

		// set related data
		const articleRelated = modifyContentResponseAsRelated(articleResponse);
		const sports = yield select(getSports);
		yield put(returnObjectForArticleLEntityRelatedReceived(articleRelated, sports));
		yield put(toggleLoadingState(false));
	} catch (error) {
		yield put(onError(error));
	}

	try {
		if (featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.CONTENT_STATISTICS)) {
			const articleId = articleResponse.data.data.id;
			const contentStatisticsConfig = featuresService.getFeatureConfig(FeatureTypes.CONTENT_STATISTICS);

			const articleContentStatistics = yield call(
				HttpService.getContentStatistics,
				contentStatisticsConfig.request_headers[0],
				`${contentStatisticsConfig.url}?articles=${articleId}`,
			);
			yield put(returnObjectForContentStatisticsArticleEntityReceived(articleResponse.articleEdit, articleContentStatistics.data));
		}
	} catch (error) {
		yield put(onError(error));
	}

	yield put(toggleContentTypeLoadingState(ContentTypes.ARTICLE, false));
}

function* postArticle(action: any) {
	let headers = { Project: action.payload.project.domain };
	let articleResponse: any = {};
	let articleId = null;

	try {
		let articlePayload = action.payload.article;
		articleResponse = yield call(HttpService.post, '/articles', articlePayload, headers);
		articleId = articleResponse.data.data.id;
		if (action.payload.list) {
			const listItems = action.payload.list.items.map((item: ListItem) => {
				if (item.data.id === 'temp-id') {
					return ListItem.builder(item)
						.withData({ ...item.data, id: articleResponse.data.data.id })
						.build();
				}
				return item;
			});
			const list = List.builder(action.payload.list).withItems(listItems).build();
			yield put(returnObjectForListEntityUpdate(list, action.payload.project));
		}
		yield put(returnObjectForArticleLEntityCreateSuccess(articleId));
	} catch (error) {
		if (error.response.data.message.startsWith('A resource with the language code')) {
			yield put(returnObjectForArticleAlreadyExists());
		} else {
			yield put(returnObjectForArticleLEntityCreateFailed());
		}
		actionService.emitError(error);
	}

	if (articleId) {
		try {
			let relatedPayload = action.payload.related;
			yield call(HttpService.post, `articles/${articleId}/related`, relatedPayload, headers);
			yield put(returnObjectForArticleLEntityRelatedCreateSuccess(articleId));
			yield put(triggerArticleCreateResourcesSuccess(articleId));
		} catch (error) {
			yield put(onError(error));
			actionService.emitError(error);
		}
	}
}

function* patchArticle(action: any) {
	let headers = { Project: action.payload.project.domain };
	let articleResponse: any = {};

	try {
		let articlePayload = action.payload.article;
		let id = action.payload.article.id;
		articleResponse = yield call(HttpService.patch, `/articles/${id}`, articlePayload, headers);
		yield put(returnObjectForArticleLEntityReceived(articleResponse));
		yield put(returnObjectForArticleLEntityUpdateSuccess());
	} catch (error) {
		yield put(returnObjectForArticleLEntityUpdateFailed());
		yield put(onError(error));
		actionService.emitError(error);
	}

	try {
		let relatedPayload = action.payload.related;
		yield call(HttpService.post, `articles/${articleResponse.data.data.id}/related`, relatedPayload, headers);
		yield put(returnObjectForArticleLEntityRelatedUpdateSuccess());
	} catch (error) {
		yield put(onError(error));
	}

	try {
		if (featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.CONTENT_STATISTICS)) {
			const articleId = articleResponse.data.data.id;
			const contentStatisticsConfig = featuresService.getFeatureConfig(FeatureTypes.CONTENT_STATISTICS);

			const articleContentStatistics = yield call(
				HttpService.getContentStatistics,
				contentStatisticsConfig.request_headers[0],
				`${contentStatisticsConfig.url}?articles=${articleId}`,
			);
			yield put(returnObjectForContentStatisticsArticleEntityReceived(articleResponse.articleEdit, articleContentStatistics.data));
		}
	} catch (error) {
		yield put(onError(error));
	}
}

function* deleteArticle(action: any) {
	try {
		let headers = { Project: action.payload.project.domain };
		let id = action.payload.id;
		yield call(HttpService.delete, `/articles/${id}`, null, headers);
		yield put(returnObjectForArticleDeleteSuccess());
		yield put(toggleModal(false));
	} catch (error) {
		const err: any = error;

		yield put(onError(error));

		if (err && err.message && err.message.includes('409')) {
			// Error 409 - Attempting to delete a locked article
			yield put(toggleModal(false));
			toast.error(i18n.t('cannot_delete_locked_article'));
		}
	}
}

function* articleSaga() {
	yield takeEvery(ARTICLE_LISTING_REQUEST, fetchArticles);
	yield takeEvery(ARTICLE_SEARCH, searchArticles);
	yield takeEvery(ARTICLE_ENTITY_REQUEST, fetchArticle);
	yield takeEvery(ARTICLE_ENTITY_CREATE, postArticle);
	yield takeEvery(ARTICLE_ENTITY_UPDATE, patchArticle);
	yield takeEvery(ARTICLE_ENTITY_DELETE, deleteArticle);
}

export default articleSaga;
