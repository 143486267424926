import GettyImageModel, { DisplaySizes } from '../models/getty-image.model';
import { featuresService } from '../../../../App';
import { FeatureTypes } from '../../../../services/feature-service/features.enum';
import { noPreviewImage } from '../../../../constants/resource-constants';
import { extractMainContentTypeBasedOnUrl, isEditScreen } from '../../../../global-helpers/global.helpers';
import { imageToMainImageModel, isWatermarkAvailableForContent } from '../../../Partials/Sidebar/media-refactored/helpers/general';
import HttpService from '../../../../services/rest/HttpService';
import { AlreadyUploadedGettyImages, gettyResource } from '../../../Partials/Sidebar/media-refactored/constants/general';
import MainMediaModel from '../../../../models/v2/main-media/main-media.model';
import { toast } from 'react-toastify';
import { store } from '../../../../store/store';
import { responseToModelImage } from '../../../../models/v2/image/image.mapper';
import { onMainImageMediaUpdate } from '../../../Partials/Sidebar/media-refactored/helpers/redux-updater';
import GettyApiService from './getty-api.service';
import i18n from 'i18next';
import { populateMainMedia3rdPartyAttributes } from '../../../Partials/Sidebar/media-refactored/helpers/requests';

export const GETTY_IMAGES_TYPES = ['creative', 'editorial'];

export const retrieveCorrectImageDisplaySize = (availableDisplaySizes: DisplaySizes[]): string => {
	const gettyFeatureConfigDisplaySize = featuresService.getFeatureConfig(FeatureTypes.GETTY_IMAGES).displaySize;
	const defaultDisplaySize =
		availableDisplaySizes && availableDisplaySizes[0] && availableDisplaySizes[0].uri ? availableDisplaySizes[0].uri : noPreviewImage;

	const displaySize = availableDisplaySizes.find((displaySize: DisplaySizes) => displaySize.name === gettyFeatureConfigDisplaySize);
	return displaySize && displaySize.uri ? displaySize.uri : defaultDisplaySize;
};

// Getty requests helper
export const getGenericGettyId = (value: Record<string, any>) => {
	if (isEditScreen()) {
		const dataGenericID =
			value && value[0] && value[0].data && value[0].data.generic && value[0].data.generic.getty_id ? value[0].data.generic.getty_id : '';
		const mainMediaGenericID = value && value[0] && value[0].getty_id && value[0].generic.getty_id ? value[0].generic.getty_id : '';
		return [dataGenericID, mainMediaGenericID];
	} else return value && value[0] && value[0].generic && value[0].generic.getty_id ? [value[0].generic.getty_id] : [];
};

export const getGettyIDinMainMedia = (genericData: Record<string, any>): Array<any> => {
	const contentTypeFromUrl = extractMainContentTypeBasedOnUrl();
	return isWatermarkAvailableForContent(contentTypeFromUrl) ? getGenericGettyId(genericData) : [];
};

export const checkIfStateContainsGettyID = (gettyID: string, stateGettyID: string[]) => stateGettyID.includes(gettyID);

export const checkIfArrayContainsSameGettyID = (array: object[], gettyID: string) => {
	let gettyImageIsAlreadyUploaded = false;
	array.forEach((imageObject) => {
		if (Object.keys(imageObject).includes(gettyID)) {
			gettyImageIsAlreadyUploaded = true;
		}
	});
	return gettyImageIsAlreadyUploaded;
};

export const constructRequestFromFilePath = (
	image: GettyImageModel,
	imagePaths: string[],
	originId: string | null,
	headers: any,
	downLoadSize: string,
) => {
	return imagePaths.map((path: string) => HttpService.post('/images', gettyToJson(image, path, originId, downLoadSize), headers));
};

export const gettyToJson = (image: GettyImageModel, imagePath: string, projectOriginId: string | null, downLoadSize: string) => {
	return {
		generic: {
			getty_id: image.id,
			title: image.title,
			width: image.maxDimensions.width,
			height: image.maxDimensions.height,
			image_url: retrieveCorrectImageDisplaySize(image.displaySizes),
			description: image.caption,
			artist: image.artist || '',
			copyRight: image.copyright || '',
			productType: image.productTypes && image.productTypes.length > 0 ? image.productTypes[0] : '',
			asset_family: image.assetFamily,
			source: `(Photo by ${image.artist}/${image.copyright})`,
		},
		origin_id: projectOriginId,
		path: imagePath,
		urls: {
			uploaded: {
				original: imagePath,
				gallery: `${imagePath}?operations=fit(1920:)`,
				thumbnail: `${imagePath}?operations=autocrop(256:256)`,
				embed: `${imagePath}?operations=fit(770:)`,
			},
		},
	};
};

export const findImageForMainMedia = (alreadyUploadedGettyImages: AlreadyUploadedGettyImages, gettyID: string): MainMediaModel | null => {
	const isAlreadyUploaded = Object.keys(alreadyUploadedGettyImages).includes(gettyID);
	return isAlreadyUploaded ? alreadyUploadedGettyImages[gettyID] : null;
};

export const addGettyImageIDtoState = (
	alreadyUploadedGettyImages: AlreadyUploadedGettyImages,
	image: GettyImageModel,
	mainMediaObj: MainMediaModel,
): AlreadyUploadedGettyImages => {
	return [...alreadyUploadedGettyImages, { [image.id]: mainMediaObj }];
};

export const handlePostGettyImageError = (err: any) => {
	if (err.response) {
		if (err.response.status == 401) {
			toast.error(i18n.t('getty_unauthorized'));
		} else {
			toast.error(i18n.t('getty_upload_fail'));
		}
	} else {
		console.error('Error making API request:', err);
	}
};

export const handleGettyImageApiError = (err: any) => {
	if (err.response) {
		if (err.response.status == 401) {
			toast.error(i18n.t('getty_unauthorized'));
		} else {
			toast.error(i18n.t('getty_request_fail'));
		}
	} else {
		console.error('Error making API request:', err);
	}
};

export const postGettyImageOnImageApi = (
	image: GettyImageModel,
	form: FormData,
	alreadyUploadedGettyImages: AlreadyUploadedGettyImages,
	setAlreadyUploadedGettyImages: (data: AlreadyUploadedGettyImages) => void,
	downLoadSize: string,
): Promise<any> => {
	const projectDomain: string = store.getState().project.currentProject.domain || '';
	const imageOrigins = (store.getState().origins && store.getState().origins.imageOrigins) || [];
	const imageOrigin = imageOrigins.find((el: Record<string, any>) => el.slug === gettyResource) || null;
	const headers = { Project: projectDomain, 'content-type': 'multipart/form-data' };
	let imagePaths: string[] = [];
	// Post the imago image on the image API
	return HttpService.postImg('/upload', form, headers)
		.then((res: any) => {
			const imagePath = res.data.path;
			imagePaths.push(imagePath);
			// Create the image on the Content API
			if (image && imagePaths && imagePaths.length > 0) {
				const requestFromFilePath = constructRequestFromFilePath(image, imagePaths, imageOrigin.id, { Project: projectDomain }, downLoadSize);
				HttpService.all(requestFromFilePath).then((res: Array<any>) => {
					//Set image as MainMedia
					const responseData = (res && res[0] && res[0].data && res[0].data.data) || null;
					if (responseData) {
						const gettyImageAsImageModel = responseToModelImage(responseData);
						let mainMedia = imageToMainImageModel(gettyImageAsImageModel);
						mainMedia = populateMainMedia3rdPartyAttributes(mainMedia);
						onMainImageMediaUpdate(mainMedia);
						const addedGettyToAlreadyUploadedArray = addGettyImageIDtoState(alreadyUploadedGettyImages, image, mainMedia);
						setAlreadyUploadedGettyImages(addedGettyToAlreadyUploadedArray);
						toast.success(i18n.t('getty_success'));
					}
				});
			}
		})
		.catch((err: any) => handlePostGettyImageError(err));
};

export const uploadGettyImage = async (
	image: GettyImageModel,
	downloadSize: string,
	genericData: Record<string, string>,
	alreadyUploadedGettyImages: AlreadyUploadedGettyImages,
	setAlreadyUploadedGettyImages: (data: AlreadyUploadedGettyImages) => void,
	gettyInstance: GettyApiService,
	setIsGettyCollapseOpen: (data: boolean) => void,
	setRequestsExecuting: (data: boolean) => void,
) => {
	const form = new FormData();

	//Download the Getty Image
	let gettyID: string[] = getGettyIDinMainMedia(genericData);
	let productType: string = '';

	productType = image.productTypes[0];

	if (!checkIfStateContainsGettyID(image.id, gettyID) && !checkIfArrayContainsSameGettyID(alreadyUploadedGettyImages, image.id)) {
		setRequestsExecuting(true);
		gettyInstance
			.downloadGettyImage(image.id, downloadSize, productType)
			.then(({ data }) => {
				data && data.size && image && image.id && form.append('file', data, `GettyImages-${image.id}.jpeg`);
			})
			.then(() => postGettyImageOnImageApi(image, form, alreadyUploadedGettyImages, setAlreadyUploadedGettyImages, downloadSize))
			.then(() => {
				setRequestsExecuting(false);
				setIsGettyCollapseOpen(false);
			})
			.catch(() => toast.error(i18n.t('fetch_image_fail')));
	} else {
		if (checkIfArrayContainsSameGettyID(alreadyUploadedGettyImages, image.id)) {
			const reuseMainMedia = findImageForMainMedia(alreadyUploadedGettyImages, image.id);
			if (reuseMainMedia) {
				onMainImageMediaUpdate(reuseMainMedia);
				toast.success(i18n.t('getty_success'));
			}
		} else {
			toast.error(i18n.t('image_update_failed'));
		}
	}
};
// Getty requests helper end
