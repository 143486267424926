import CompetitionModel from '../../../../../../../models/v2/competition/entity/competition.model';
import { TennisPlayoffJson } from './tennis-playoff.json';
import TennisPlayoffBuilder from './tennis-playoff.builder';
import TennisCompetitionSeasonModel from '../../../../../../../models/v2/tennis-competition-season/entity/tennis-competition-season.model';
import TennisSeasonStagesModel from '../../../../../../../models/v2/tennis-competition-season/entity/tennis-season-stages.model';
import { RefreshTimeValuesType } from '../../refresh-time-input/helper';

export default class TennisPlayoffModel {
	readonly competition: CompetitionModel;
	readonly season: TennisCompetitionSeasonModel;
	readonly stage: TennisSeasonStagesModel;
	readonly stages: TennisSeasonStagesModel[];
	readonly refreshTime: RefreshTimeValuesType;

	private constructor(
		competition: CompetitionModel,
		season: TennisCompetitionSeasonModel,
		stage: TennisSeasonStagesModel,
		stages: TennisSeasonStagesModel[],
		refreshTime: RefreshTimeValuesType,
	) {
		this.competition = competition;
		this.season = season;
		this.stage = stage;
		this.stages = stages;
		this.refreshTime = refreshTime;
	}

	toJson(): TennisPlayoffJson {
		return {
			competition: this.competition,
			season: this.season,
			stage: this.stage,
			stages: this.stages,
			refreshTime: this.refreshTime,
		};
	}

	static fromJson(json: TennisPlayoffJson): TennisPlayoffModel {
		return new TennisPlayoffModel(json.competition, json.season, json.stage, json.stages, json.refreshTime);
	}

	static builder(playoffWidget?: TennisPlayoffModel): TennisPlayoffBuilder {
		return new TennisPlayoffBuilder(playoffWidget);
	}
}
