import { call, put, takeEvery } from 'redux-saga/effects';
import HttpService from '../../services/rest/HttpService';
import {
	returnObjectForTagEntityCreateFailed,
	returnObjectForTagEntityCreateSuccess,
	returnObjectForTagEntityDeleteSuccess,
	returnObjectForTagEntityReceived,
	returnObjectForTagEntityUpdateFailed,
	returnObjectForTagEntityUpdateSuccess,
	returnObjectForTagListingReceived,
	TAG_ENTITY_CREATE,
	TAG_ENTITY_DELETE,
	TAG_ENTITY_REQUEST,
	TAG_ENTITY_UPDATE,
	TAG_LISTING_REQUEST,
	TAG_SEARCH,
} from '../action-creators/TagActionCreators';
import { searchIsApplied, toggleLoadingState, toggleModal } from '../action-creators/UiActionCreator';
import { onError } from '../action-creators/GeneralActions';
import { actionService } from '../../App';

function* fetchTagListingFromApi(action: any) {
	yield put(toggleLoadingState(true));

	try {
		let headers = { Project: action.payload.project.domain };
		const constructURL = action.payload.text && action.payload.text.length > 0 ? `/search?query=${action.payload.text}&` : '?';
		const tags = yield call(HttpService.get, `/tags${constructURL}page=${action.payload.page}&limit=200`, null, headers);
		yield put(returnObjectForTagListingReceived(tags));
		yield put(toggleLoadingState(false));
	} catch (error) {
		yield put(onError(error));
	}
}

function* searchTagFromApi(action: any) {
	yield put(toggleLoadingState(true));

	try {
		let headers = { Project: action.payload.project.domain };
		const tags = yield call(HttpService.get, `/tags/search?query=${action.payload.text}`, null, headers);
		yield put(returnObjectForTagListingReceived(tags));
		yield put(toggleLoadingState(false));
		yield put(searchIsApplied());
	} catch (error) {
		yield put(onError(error));
	}
}

function* fetchTagEntityFromApi(action: any) {
	yield put(toggleLoadingState(true));

	try {
		let headers = { Project: action.payload.project.domain };
		const tag = yield call(HttpService.get, `/tags/${action.payload.id}`, null, headers);
		yield put(returnObjectForTagEntityReceived(tag));
		yield put(toggleLoadingState(false));
	} catch (error) {
		yield put(onError(error));
	}
}

function* postTagEntityToApi(action: any) {
	let headers = { Project: action.payload.project.domain };
	let tagResponse: any = {};

	try {
		let tagPayload = action.payload.tag;
		tagResponse = yield call(HttpService.post, '/tags', tagPayload, headers);
		yield put(returnObjectForTagEntityCreateSuccess(tagResponse.data.data.id));
	} catch (error) {
		yield put(returnObjectForTagEntityCreateFailed());
		yield put(onError(error));
		actionService.emitError(error);
	}
}

function* patchTagEntityToApi(action: any) {
	let headers = { Project: action.payload.project.domain };

	try {
		let tagPayload = action.payload.tag;
		let id = action.payload.tag.id;
		yield call(HttpService.patch, `/tags/${id}`, tagPayload, headers);
		yield put(returnObjectForTagEntityUpdateSuccess());
	} catch (error) {
		yield put(returnObjectForTagEntityUpdateFailed());
		yield put(onError(error));
		actionService.emitError(error);
	}
}

function* deleteTagEntityFromApi(action: any) {
	try {
		let headers = { Project: action.payload.project.domain };
		let id = action.payload.id;
		yield call(HttpService.delete, `/tags/${id}`, null, headers);
		yield put(returnObjectForTagEntityDeleteSuccess());
		yield put(toggleModal(false));
	} catch (error) {
		yield put(onError(error));
	}
}

function* tagSaga() {
	yield takeEvery(TAG_LISTING_REQUEST, fetchTagListingFromApi);
	yield takeEvery(TAG_SEARCH, searchTagFromApi);
	yield takeEvery(TAG_ENTITY_REQUEST, fetchTagEntityFromApi);
	yield takeEvery(TAG_ENTITY_CREATE, postTagEntityToApi);
	yield takeEvery(TAG_ENTITY_UPDATE, patchTagEntityToApi);
	yield takeEvery(TAG_ENTITY_DELETE, deleteTagEntityFromApi);
}

export default tagSaga;
