import React from 'react';
import { Col, Label, Row } from 'reactstrap';
import BlockModel from '../../../../models/block.model';
import { blockManagementService, blockWrapperService } from '../../../../subcomponents/blocky.component';
import BlockValidation from '../../../../helpers/block-validation.helper';
import TeamSelectComponent from '../../../partials/team-select/team-select.component';
import { SportTypes } from '../../../../../../../constants/sport.types';
import { SPORT_SELECT_ATTRIBUTES } from '../../helpers/component-attributes.constants';
import { createBlock } from '../../../partials/helpers/blocky-sidebar-communication/blocky-sidebar-communication.helper';
import { WidgetsV2Ids } from '../../../../constants/block.types';
import FootballLineupsModel from '../models/football-lineups.model';
import {
	areColorsTheSame,
	generateCorrectError,
	returnErrorForMatchCenterColors,
} from '../../../../blocks/widgets/match/helpers/match-widget.helper';
import TeamColorSelectComponent from '../../../partials/team-color-select/team-color-select.component';
import ErrorHandler from '../../../partials/error-handler/error-handler';
import { SelectMenuOptionType } from '../../../../../../../models/v2/general/select.model';
import RefreshTimeWrapper from '../../../partials/refresh-time-input/refresh-time-wrapper';
import { onRefreshTimeChangeFromMainComponent } from '../../../partials/refresh-time-input/helper';
import TeamModel from '../../../../../../../models/v2/Team/Entity/TeamModel';
import { LINEUPS_ELEMENTS, LineupsElementsSelect } from '../../../partials/lineups-elements/lineups-elements-toggle.component';
import { withTranslation } from 'react-i18next';
import MatchModel from '../../../../../../../models/v2/match/entity/match.model';
import { MatchesContainer } from '../../../partials/matches/matches-container';

type Properties = {
	block: BlockModel;
	t: any;
	contentData: any;
	contentLanguage: string;
};

type State = {
	isValid: boolean;
	widgetModel: FootballLineupsModel;
	displayHomeTeamColorPicker: boolean;
	displayAwayTeamColorPicker: boolean;
	requestMatchColors: boolean;
};

class FootballLineupsEditComponent extends React.Component<Properties, State> {
	constructor(props: Properties) {
		super(props);
		this.state = {
			widgetModel: createBlock(
				WidgetsV2Ids.widgetFootballLineups,
				props.block.data.preview && props.block.data.preview.widgetModel,
				props.contentData,
			),
			isValid: true,
			displayHomeTeamColorPicker: false,
			displayAwayTeamColorPicker: false,
			requestMatchColors: false,
		};
	}

	componentDidMount(): void {
		blockWrapperService.registerOnSaveFunc({ blockId: this.props.block.id, func: this.onSave });
	}

	componentWillUnmount(): void {
		blockWrapperService.unregisterOnSaveFunc(this.props.block.id);
	}

	onSave = (index: number) => {
		const data = { widgetModel: this.state.widgetModel };
		const validation = new BlockValidation();
		const isValid = validation.validate(this.props.block, data) === true;

		this.setState({ ...this.state, isValid });

		if (isValid) {
			const block = Object.assign({}, this.props.block);
			block.data.preview = { widgetModel: this.state.widgetModel };
			blockManagementService.blockUpdate(block, index);
		} else {
			return isValid;
		}
	};

	setMatchBlockColors = (homeTeamColor: string, awayTeamColor: string) => {
		this.updateWidgetModelState(
			FootballLineupsModel.builder(this.state.widgetModel).withHomeTeamColor(homeTeamColor).withAwayTeamColor(awayTeamColor).build(),
		);

		this.setState({
			...this.state,
			requestMatchColors: false,
			displayHomeTeamColorPicker: false,
		});
	};

	updateWidgetModelState = (model: FootballLineupsModel) =>
		this.setState({
			...this.state,
			widgetModel: model,
		});

	onTeamChange = (teams: TeamModel[]) => {
		this.updateWidgetModelState(
			FootballLineupsModel.builder(this.state.widgetModel)
				.withTeams(teams)
				.withMatch(new MatchModel())
				.withHomeTeamColor('')
				.withAwayTeamColor('')
				.build(),
		);
	};

	onMatchChange = (match: MatchModel) => {
		const widgetModel = FootballLineupsModel.builder(this.state.widgetModel).withMatch(match).build();
		this.setState({
			...this.state,
			isValid: true,
			requestMatchColors: true,
			displayHomeTeamColorPicker: true,
			widgetModel,
		});
	};

	handleHomeTeamChangeColor = (color: any) => {
		this.updateWidgetModelState(FootballLineupsModel.builder(this.state.widgetModel).withHomeTeamColor(color.hex).build());
	};

	handleAwayTeamChangeColor = (color: any) => {
		this.updateWidgetModelState(FootballLineupsModel.builder(this.state.widgetModel).withAwayTeamColor(color.hex).build());
	};

	handleRequestTeamsChangeColor = (request: boolean) => {
		this.setState({ ...this.state, requestMatchColors: request });
	};

	toggleHomeTeamColorPicker = (toggle: boolean) => {
		this.setState({ ...this.state, displayHomeTeamColorPicker: toggle });
	};

	toggleAwayTeamColorPicker = (toggle: boolean) => {
		this.setState({ ...this.state, displayAwayTeamColorPicker: toggle });
	};

	onRefreshTimeChange = (refreshTimeData: SelectMenuOptionType) => {
		onRefreshTimeChangeFromMainComponent(refreshTimeData, FootballLineupsModel, this.state.widgetModel, this.updateWidgetModelState);
	};

	handleCoachElementChange = (element: string) => {
		this.updateWidgetModelState(FootballLineupsModel.builder(this.state.widgetModel).withCoachParameters({ name: element }).build());
	};

	handleFirstTeamNameElementChange = (element: string) => {
		this.updateWidgetModelState(FootballLineupsModel.builder(this.state.widgetModel).withFirstTeamParameters({ name: element }).build());
	};

	handleFirstTeamStateElementChange = (element: string) => {
		this.updateWidgetModelState(
			FootballLineupsModel.builder(this.state.widgetModel)
				.withFirstTeamParameters({
					name: this.state.widgetModel.firstTeamParameters && this.state.widgetModel.firstTeamParameters.name,
					status: element,
				})
				.build(),
		);
	};

	handleSubstitutesNameElementChange = (element: string) => {
		this.updateWidgetModelState(FootballLineupsModel.builder(this.state.widgetModel).withSubstitutesParameters({ name: element }).build());
	};

	handleSubstitutesStateElementChange = (element: string) => {
		this.updateWidgetModelState(
			FootballLineupsModel.builder(this.state.widgetModel)
				.withSubstitutesParameters({
					name: this.state.widgetModel.substitutesParameters && this.state.widgetModel.substitutesParameters.name,
					status: element,
				})
				.build(),
		);
	};

	render() {
		const { isValid, widgetModel, displayAwayTeamColorPicker, displayHomeTeamColorPicker, requestMatchColors } = this.state;
		const { t, block } = this.props;
		const hasMatch = !!widgetModel.match;
		const homeTeamColor = widgetModel.homeTeamColor ? widgetModel.homeTeamColor : '';
		const awayTeamColor = widgetModel.awayTeamColor ? widgetModel.awayTeamColor : '';
		const homeTeamName = hasMatch && widgetModel.match.homeTeam ? widgetModel.match.homeTeam.name : '';
		const awayTeamName = hasMatch && widgetModel.match.awayTeam ? widgetModel.match.awayTeam.name : '';

		return (
			<div data-qa='football-lineups-edit'>
				<Row>
					<Col>
						<TeamSelectComponent
							isMulti={true}
							isBlocky
							isValid={isValid}
							selectedTeam={widgetModel.teams ? widgetModel.teams : []}
							onTeamSelect={(team: TeamModel[]) => this.onTeamChange(team)}
							t={t}
							isClearable
							isRequired
							searchByKeyword={true}
							id={`${SportTypes.FOOTBALL}-${SPORT_SELECT_ATTRIBUTES.TEAM_SELECT}`}
						/>
					</Col>
				</Row>
				{widgetModel.teams && widgetModel.teams.length > 0 && (
					<Row>
						<Col>
							<MatchesContainer
								t={t}
								teamIds={
									widgetModel && widgetModel.teams && widgetModel.teams.length > 0
										? [widgetModel.teams.filter((team) => team).map((team: TeamModel) => team.id)]
										: []
								}
								isMulti={false}
								maxNotStartedMatches={20}
								maxFinishedMatches={20}
								maxInterruptedMatches={20}
								teamNames={
									widgetModel && widgetModel.teams && widgetModel.teams.length > 0
										? widgetModel.teams.filter((team) => team).map((team: TeamModel) => team.name)
										: ['']
								}
								selectedMatchId={widgetModel && widgetModel.match ? widgetModel.match.id : null}
								onSelect={this.onMatchChange}
							/>
						</Col>
					</Row>
				)}
				<div className='mb-3 mt-2'>
					<ErrorHandler
						arePropsValid={isValid}
						isValueSelected={widgetModel.match && !widgetModel.match.id}
						t={t}
						errorMessage='no_matches_selected'
					/>
				</div>
				{widgetModel.match && widgetModel.match.id && widgetModel.match.id > 0 && (
					<Row className='mb-2 mt-2'>
						<Col>
							<div className='d-flex align-items-center'>
								<Label htmlFor={`${SportTypes.FOOTBALL}-${SPORT_SELECT_ATTRIBUTES.HOME_TEAM_COLOR}`} className='mr-2 mb-0'>
									{homeTeamName} {t('color')}:
								</Label>
								<div onClick={() => this.toggleHomeTeamColorPicker(true)} className='swatch teams-color-picker-box'>
									<div className={'color ' + (!homeTeamColor ? 'no-color-selected-bg' : '')} style={{ background: homeTeamColor }} />
								</div>
								{displayHomeTeamColorPicker && (
									<div className='popover ' style={requestMatchColors ? { visibility: 'hidden' } : { visibility: 'unset' }}>
										<div className='cover' onClick={() => this.toggleHomeTeamColorPicker(false)} />
										<TeamColorSelectComponent
											setMatchColor={this.setMatchBlockColors}
											match={widgetModel.match}
											color='transparent'
											handleChangeColor={this.handleHomeTeamChangeColor}
											toggleTeamColorPicker={this.toggleHomeTeamColorPicker}
											toggle={displayHomeTeamColorPicker}
											handleColorsRequest={this.handleRequestTeamsChangeColor}
											requestMatchColors={requestMatchColors}
											blockId={block.id}
										/>
									</div>
								)}
							</div>
							<div className='mt-1'>
								<ErrorHandler
									t={t}
									errorMessage={generateCorrectError(homeTeamColor, awayTeamColor)}
									arePropsValid={isValid}
									isValueSelected={returnErrorForMatchCenterColors(homeTeamColor)}
								/>
							</div>
						</Col>
						<Col>
							<div className='d-flex align-items-center'>
								<Label htmlFor={`${SportTypes.FOOTBALL}-${SPORT_SELECT_ATTRIBUTES.AWAY_TEAM_COLOR}`} className='mr-2 mb-0'>
									{awayTeamName} {t('color')}:
								</Label>
								<div onClick={() => this.toggleAwayTeamColorPicker(true)} className='swatch teams-color-picker-box'>
									<div className={'color ' + (!awayTeamColor ? 'no-color-selected-bg' : '')} style={{ background: awayTeamColor }} />
								</div>
								{displayAwayTeamColorPicker && (
									<div className='popover'>
										<div className='cover' onClick={() => this.toggleAwayTeamColorPicker(false)} />
										<TeamColorSelectComponent
											setMatchColor={this.setMatchBlockColors}
											match={widgetModel.match}
											color='transparent'
											handleChangeColor={this.handleAwayTeamChangeColor}
											toggleTeamColorPicker={this.toggleAwayTeamColorPicker}
											toggle={displayAwayTeamColorPicker}
											handleColorsRequest={this.handleRequestTeamsChangeColor}
											requestMatchColors={requestMatchColors}
											blockId={block.id}
										/>
									</div>
								)}
							</div>
							<div className='mt-1'>
								<ErrorHandler
									t={t}
									errorMessage={generateCorrectError(homeTeamColor, awayTeamColor)}
									arePropsValid={isValid}
									isValueSelected={returnErrorForMatchCenterColors(awayTeamColor)}
								/>
							</div>
						</Col>
					</Row>
				)}
				<div className='mt-1'>
					<ErrorHandler
						t={t}
						errorMessage={generateCorrectError(homeTeamColor, awayTeamColor)}
						arePropsValid={isValid}
						isValueSelected={areColorsTheSame(homeTeamColor, awayTeamColor)}
					/>
				</div>
				<hr />
				<Row>
					<Col>
						<LineupsElementsSelect
							setElement={this.handleCoachElementChange}
							label={'show_coach'}
							propertyToSet={LINEUPS_ELEMENTS.coach}
							toggledProperty={!!(widgetModel.coachParameters && widgetModel.coachParameters.name && widgetModel.coachParameters.name.length > 0)}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<LineupsElementsSelect
							setElement={this.handleFirstTeamNameElementChange}
							label={'show_first_team'}
							propertyToSet={LINEUPS_ELEMENTS.firstTeam}
							toggledProperty={
								!!(widgetModel.firstTeamParameters && widgetModel.firstTeamParameters.name && widgetModel.firstTeamParameters.name.length > 0)
							}
						/>
					</Col>
					{widgetModel.firstTeamParameters && widgetModel.firstTeamParameters.name && (
						<Col>
							<LineupsElementsSelect
								setElement={this.handleFirstTeamStateElementChange}
								label={'toggle_first_team_open'}
								propertyToSet={
									widgetModel.firstTeamParameters &&
									widgetModel.firstTeamParameters.status &&
									widgetModel.firstTeamParameters.status === LINEUPS_ELEMENTS.open
										? LINEUPS_ELEMENTS.open
										: LINEUPS_ELEMENTS.closed
								}
								toggledProperty={
									!!(
										widgetModel.firstTeamParameters &&
										widgetModel.firstTeamParameters.status &&
										widgetModel.firstTeamParameters.status === LINEUPS_ELEMENTS.open
									)
								}
							/>
						</Col>
					)}
				</Row>
				<Row>
					<Col>
						<LineupsElementsSelect
							setElement={this.handleSubstitutesNameElementChange}
							label={'show_substitutes'}
							propertyToSet={LINEUPS_ELEMENTS.substitutes}
							toggledProperty={
								!!(widgetModel.substitutesParameters && widgetModel.substitutesParameters.name && widgetModel.substitutesParameters.name.length > 0)
							}
						/>
					</Col>
					{widgetModel.substitutesParameters && widgetModel.substitutesParameters.name && (
						<Col>
							<LineupsElementsSelect
								setElement={this.handleSubstitutesStateElementChange}
								label={'toggle_show_substitutes_open'}
								propertyToSet={
									widgetModel.substitutesParameters &&
									widgetModel.substitutesParameters.status &&
									widgetModel.substitutesParameters.status === LINEUPS_ELEMENTS.open
										? LINEUPS_ELEMENTS.open
										: LINEUPS_ELEMENTS.closed
								}
								toggledProperty={
									!!(
										widgetModel.substitutesParameters &&
										widgetModel.substitutesParameters.status &&
										widgetModel.substitutesParameters.status === LINEUPS_ELEMENTS.open
									)
								}
							/>
						</Col>
					)}
				</Row>
				<hr />
				<RefreshTimeWrapper value={this.state.widgetModel.refreshTime} onRefreshTimeChange={this.onRefreshTimeChange} />
			</div>
		);
	}
}

export default withTranslation()(FootballLineupsEditComponent);
