import * as React from 'react';
import './video-cells.scss';
import DugoutVideoModel from '../../../../../../../models/dugout-videos/dugout-video/dugout-video.model';
import { copyToClipboard } from './helpers/video-cells.helper';
import LazyLoadVideoCell from './lazy-load-video-cell';
import VideoPlayModal from '../../../../../Shared/video-play-modal/video-play-modal.component';

type Properties = {
	videos: DugoutVideoModel[];
	analytics?: any;
	t: any;
	toggleLoadingState: boolean;
	onSelect: (video: DugoutVideoModel) => void;
	selectedVideo: DugoutVideoModel;
	isMediaSidebar: boolean;
};

export type State = {
	isOpenModal: boolean;
	videoToPlay: DugoutVideoModel | null;
};
export default class VideoCells extends React.Component<Properties, State> {
	constructor(props: any) {
		super(props);
		this.state = {
			isOpenModal: false,
			videoToPlay: null,
		};
	}

	copyId = (e: any, id: string) => {
		copyToClipboard(e, id.replace(/\"/g, ''), this.props.t, 'id');
	};

	copyEmbed = (e: any, embedCode: string) => {
		copyToClipboard(e, embedCode, this.props.t, 'embedCode');
	};

	playVideo = (open: boolean, video: DugoutVideoModel) => {
		this.setState({ ...this.state, isOpenModal: open, videoToPlay: video });
	};

	onClose = (open: boolean) => {
		this.setState({ ...this.state, isOpenModal: open, videoToPlay: null });
	};

	render() {
		const { videos, t, onSelect, selectedVideo, isMediaSidebar } = this.props;
		const { isOpenModal, videoToPlay } = this.state;

		return (
			<div className='video-cells-container'>
				{videos &&
					videos.length > 0 &&
					videos.map((video: DugoutVideoModel, index: number) => {
						return (
							<LazyLoadVideoCell
								key={`${video.id + Math.random()}`}
								t={t}
								video={video}
								isMediaSidebar={isMediaSidebar}
								onSelect={onSelect}
								copyEmbed={this.copyEmbed}
								playVideo={this.playVideo}
								copyId={this.copyId}
								index={index}
								selectedVideo={selectedVideo}
							/>
						);
					})}
				{videoToPlay && (
					<VideoPlayModal
						t={t}
						onClose={this.onClose}
						url={`https://embed.dugout.com/v2/?p=${videoToPlay.hash}`}
						title={videoToPlay.title}
						open={isOpenModal}
					/>
				)}
			</div>
		);
	}
}
