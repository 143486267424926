import DecoratedPlayersBuilder from './decorated-players-widget.builder';
import TournamentModel from '../../player-profile/models/tournament-model/tournament.model';
import SeasonModel from '../../player-profile/models/season/season.model';
import PlayerModel from './player/player.model';
import { DecoratedPlayersWidgetJson } from './decorated-players-widget.json';
import TeamModel from '../../../../partials/team-select/models/team.model';

export default class DecoratedPlayersWidgetModel {
	readonly tournament: TournamentModel;
	readonly season: SeasonModel;
	readonly limit: string = '20';
	readonly startFrom: string = '1';
	readonly highlightPlayers: PlayerModel[];
	readonly player: PlayerModel;
	readonly offset: string;
	readonly team: TeamModel;
	readonly players: PlayerModel[];
	readonly columns: string[];
	readonly card: string;
	readonly playerOffset: string;
	readonly threeLetterCodeType: any = '';

	private constructor(
		tournament: TournamentModel,
		season: SeasonModel,
		limit: string,
		startFrom: string,
		highlightPlayers: PlayerModel[],
		player: PlayerModel,
		offset: string,
		team: TeamModel,
		players: PlayerModel[],
		columns: string[],
		card: string,
		playerOffset: string,
		threeLetterCodeType: any,
	) {
		this.tournament = tournament;
		this.season = season;
		this.limit = limit;
		this.startFrom = startFrom;
		this.highlightPlayers = highlightPlayers;
		this.player = player;
		this.offset = offset;
		this.team = team;
		this.players = players;
		this.columns = columns;
		this.card = card;
		this.playerOffset = playerOffset;
		this.threeLetterCodeType = threeLetterCodeType;
	}

	toJson(): DecoratedPlayersWidgetJson {
		return {
			tournament: this.tournament,
			season: this.season,
			limit: this.limit,
			startFrom: this.startFrom,
			highlightPlayers: this.highlightPlayers,
			player: this.player,
			offset: this.offset,
			team: this.team,
			players: this.players,
			columns: this.columns,
			card: this.card,
			playerOffset: this.playerOffset,
			threeLetterCodeType: this.threeLetterCodeType,
		};
	}

	static fromJson(json: DecoratedPlayersWidgetJson): DecoratedPlayersWidgetModel {
		return new DecoratedPlayersWidgetModel(
			json.tournament,
			json.season,
			json.limit,
			json.startFrom,
			json.highlightPlayers,
			json.player,
			json.offset,
			json.team,
			json.players,
			json.columns,
			json.card,
			json.playerOffset,
			json.threeLetterCodeType,
		);
	}

	static builder(decoratedPlayers?: DecoratedPlayersWidgetModel): DecoratedPlayersBuilder {
		return new DecoratedPlayersBuilder(decoratedPlayers);
	}
}
