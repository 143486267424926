import PlayerModel from '../../../blocks/widgets/most-decorated-players/models/player/player.model';

export const mostDecoratedPlayerToOption = (player: any) => {
	if (player) {
		return {
			value: player.id,
			label: player.name,
			data: player,
			logo: player.headshot,
			type: 'player',
		};
	}

	return {};
};

export const selectionsToPlayers = (selections: any[]) => {
	if (selections && selections.length > 0) {
		return selections.map((selection: any) => selection.data);
	}

	return [];
};

export const mostDecoratedPlayersToOptions = (players: any[]) => {
	if (players && players.length > 0) {
		return players.map((player: PlayerModel) => mostDecoratedPlayerToOption(player));
	}

	return [];
};
