import { ImageJson } from './ImageJson';
import Image from './Image';
import EnumItem from '../enum/EnumItem';
import ImageUrls from './urls/ImageUrls';

export default class ImageBuilder {
	private json: ImageJson;

	constructor(image?: Image | ImageJson) {
		if (image && image instanceof Image) {
			this.json = image.toJSON();
		} else if (image) {
			this.json = image;
		} else {
			this.json = {} as ImageJson;
		}
	}

	withId(id: string): ImageBuilder {
		this.json.id = id;

		return this;
	}

	withDescription(description: string): ImageBuilder {
		this.json.description = description;

		return this;
	}

	withOwner(owner: EnumItem): ImageBuilder {
		this.json.owner = owner;

		return this;
	}

	withType(type: string): ImageBuilder {
		this.json.type = type;

		return this;
	}

	withUrls(urls: ImageUrls): ImageBuilder {
		this.json.urls = urls;

		return this;
	}

	withGeneric(generic: any): ImageBuilder {
		this.json.generic = generic;

		return this;
	}

	withAlt(alt: any): ImageBuilder {
		this.json.alt = alt;

		return this;
	}

	withCaption(caption: any): ImageBuilder {
		this.json.caption = caption;

		return this;
	}

	withCreatedAt(createdAt: string): ImageBuilder {
		this.json.createdAt = createdAt;

		return this;
	}

	withUpdatedAt(updatedAt: string): ImageBuilder {
		this.json.updatedAt = updatedAt;

		return this;
	}

	withAuthors(authors: any): ImageBuilder {
		this.json.authors = authors;

		return this;
	}

	withPath(path: string): ImageBuilder {
		this.json.path = path;

		return this;
	}

	build(): Image {
		return Image.fromJSON(this.json);
	}
}
