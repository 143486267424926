import * as React from 'react';
import Select from 'react-select';
import { Properties } from './properties/CategorySelectMultiContainerProps';
import { categoriesToOptions, selectedCategoriesToOptions, selectedOptionsToCategories } from './CategorySelectMultiHelper';

const CategorySelectMultiContainer: React.FunctionComponent<Properties> = (props) => {
	const { categories, adminCategories, selectedCategories, className } = props;
	const onCategorySelect = (selections: any[]) => {
		props.onCategorySelect(selectedOptionsToCategories(selections));
	};

	return (
		<Select
			className={className}
			inputId='content-additional-categories-select'
			options={categoriesToOptions(categories, adminCategories)}
			onChange={onCategorySelect}
			value={selectedCategoriesToOptions(selectedCategories)}
			isMulti={true}
			placeholder={props.t('select')}
			noOptionsMessage={(inputValue) => inputValue && props.t('no_options')}
		/>
	);
};

export default CategorySelectMultiContainer;
