import { TeamLineupJson } from './team-lineup.json';
import { TeamLineupRequestJson } from './team-lineup-request.json';
import TeamLineupModel from './team-lineup.model';
import LineupPlayerModel from '../player-lineup/lineup-player.model';

export default class TeamLineupBuilder {
	private json: TeamLineupJson;

	constructor(lineup?: TeamLineupModel | TeamLineupJson) {
		if (lineup && lineup instanceof TeamLineupModel) {
			this.json = lineup.toJSON();
		} else if (lineup) {
			this.json = lineup;
		} else {
			this.json = {} as TeamLineupJson;
		}
	}

	withId(teamId: string): TeamLineupBuilder {
		this.json.teamId = teamId;

		return this;
	}

	withCoach(coach: any): TeamLineupBuilder {
		this.json.coach = coach;

		return this;
	}

	withPlayers(players: LineupPlayerModel[]): TeamLineupBuilder {
		this.json.players = players;

		return this;
	}

	withFormation(formation: string | null): TeamLineupBuilder {
		this.json.formation = formation;

		return this;
	}

	build(): TeamLineupModel {
		return TeamLineupModel.fromJSON(this.json);
	}

	toRequestJson(): TeamLineupRequestJson {
		let json = {} as TeamLineupRequestJson;
		json.players = this.json.players.map((player: LineupPlayerModel) => LineupPlayerModel.builder(player).toRequestJson());
		json.formation = this.json.formation;

		if (this.json.coach && this.json.coach.id) {
			json.coach_id = this.json.coach.id;
		}

		return json;
	}
}
