import React from 'react';
import { Properties, State } from './Properties/LightboxProperties';
import Lightbox from 'react-image-lightbox';
import * as helpers from './helpers/lightboxHelpers';
import Image from '../../../../../models/image/Image';
import ModelMapper from '../../../../../models/ModelMapper';
import HttpService from '../../../../../services/rest/HttpService';

class LightboxContainer extends React.Component<Properties, State> {
	constructor(props: Properties) {
		super(props);

		this.state = {
			imageRelated: [],
			isLoading: false,
			imageToEnlarge: props.imageToEnlarge,
		};
	}

	updateLoadingState = (isLoading: boolean) => {
		this.setState((state: any) => {
			return {
				...state,
				isLoading,
			};
		});
	};

	imageRelatedRequest() {
		const { currentProject, imageToEnlarge } = this.props;
		const imageId = imageToEnlarge.id;

		if (imageId && imageId.length > 0) {
			this.updateLoadingState(true);
			const headers = { Project: currentProject.domain };
			HttpService.get(`/v2/images/${imageId}/related`, null, headers)
				.then((response: any) => {
					const related = ModelMapper.extractFootballRelatedFromResponse(response.data.data);
					this.setState((state: any) => {
						return { ...state, imageRelated: related, isLoading: false };
					});
				})
				.catch((error: any) => {});
		}
	}

	imageRequest() {
		const { currentProject, imageToEnlarge } = this.props;
		const imageId = imageToEnlarge.id;

		if (imageId && imageId.length > 0) {
			this.updateLoadingState(true);
			const headers = { Project: currentProject.domain };
			HttpService.get(`/images/${imageId}`, null, headers).then((response: any) => {
				this.setState((state: any) => {
					return {
						...state,
						imageToEnlarge: ModelMapper.remapImageFromResponse(response.data.data),
					};
				});
			});
		}
	}

	componentDidMount(): void {
		this.imageRelatedRequest();
		this.imageRequest();
	}

	render() {
		const { imageRelated, isLoading, imageToEnlarge } = this.state;

		if (isLoading) {
			return null;
		}

		return (
			<Lightbox
				imageCaption={helpers.displayImageCaption(imageToEnlarge, imageRelated, this.props.t)}
				mainSrc={imageToEnlarge.urls.uploaded.gallery}
				onCloseRequest={() => {
					this.props.toggleImageEnlarge({} as Image, false);
				}}
			/>
		);
	}
}

export default LightboxContainer;
