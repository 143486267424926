import React from 'react';
import { createTournamentGroupOptions, contentToOption } from './helpers/player-season-select.helper';
import Select from 'react-select';
import { Properties, State } from './properties/season-select.properties';
import HttpService from '../../../../../../services/rest/HttpService';

export default class PlayerSeasonSelect extends React.Component<Properties, State> {
	constructor(props: any) {
		super(props);
		this.state = {
			options: [],
		};
	}

	componentDidMount() {
		if (this.props.playerId) {
			this.requestPlayerInformation(this.props.playerId);
		}
	}

	componentDidUpdate(prevProps: Readonly<Properties>, prevState: Readonly<State>) {
		if (this.props.playerId !== prevProps.playerId) {
			this.requestPlayerInformation(this.props.playerId);
		}
	}

	updateSeasonsState = (options: any) => {
		this.setState({
			options,
		});
	};

	onSeasonSelect = (selection: any) => {
		selection.data.tournament = selection.label ? selection.label : '';
		this.props.onSeasonSelect(selection.data);
	};

	requestPlayerInformation = (playerId: string) => {
		HttpService.getPlayerSeasonStatistics(playerId, this.props.currentProject.language)
			.then((response: any) => {
				const options = createTournamentGroupOptions(response.data);
				this.updateSeasonsState(options);
			})
			.catch((e: any) => e);
	};

	render() {
		if (this.state.options) {
			return (
				<Select
					options={this.state.options}
					value={[contentToOption(this.props.value, this.props.selectedTournamentName)]}
					onChange={this.onSeasonSelect}
				/>
			);
		}

		return null;
	}
}
