import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import ListItemModel from '../../../../../../models/v2/list/list-item';
import { Button, Col, Row } from 'reactstrap';
import { capitalize, extractMainContentTypeBasedOnUrl } from '../../../../../../global-helpers/global.helpers';
import moment from 'moment';
import { displayAuthorNamesString, getImageSrcOrPlaceholderImage } from '../../helpers/general.helper';
import { imagePlaceholderBase64 } from '../../../../../../global-helpers/global.constants';
import { generateContentTypeIcon } from '../../../../../../services/content-models-service/ContentModelService';
import ContentItemStatisticsComponent from '../list-statistics/list-statistics';

type Properties = {
	t: any;
	selectedListItem: ListItemModel;
	itemIndex: number;
	shouldShowListImages: boolean;
	onDelete: (itemType: string, itemId: string) => void;
};

const ListContentSortableItem: FunctionComponent<Properties> = ({ t, selectedListItem, itemIndex, shouldShowListImages, onDelete }) => {
	if (!selectedListItem) return null;
	const selectedListItemData = selectedListItem.data;
	const isLocked = typeof selectedListItem.lockedPosition === 'number';
	const authorData =
		(selectedListItemData.authors && selectedListItemData.authors.length > 0 && displayAuthorNamesString(t, selectedListItemData.authors)) ||
		'';
	const contentIcon = generateContentTypeIcon(selectedListItem.data.entity_type || extractMainContentTypeBasedOnUrl());
	const contentImage = getImageSrcOrPlaceholderImage(
		(selectedListItemData.image && selectedListItemData.image.data && selectedListItemData.image.data.path) || '',
	);
	return (
		<li className={`list-group-item mb-1 p-1 ${isLocked && 'disabled'}`}>
			<Row className='position-relative '>
				<Col className='d-flex justify-content-between align-items-center'>
					<div className='list-content d-flex justify-content-between align-items-center w-80'>
						{isLocked && <i style={{ left: '50%' }} className='position-absolute p-1 fa-4x opacity-02 fa fa-lock text-muted' />}
						<span className='px-1 font-weight-bold'>{itemIndex + 1}.</span>
						<div className='d-inline-flex w-100'>
							<div className='d-flex align-items-center overflow-hidden'>
								{shouldShowListImages && (
									<div className='image-wrapper mr-2'>
										<img
											src={contentImage}
											height='60'
											width='60'
											className='max-width-100 rounded-circle'
											onError={(i: any) => (i.target.src = imagePlaceholderBase64)}
										/>
									</div>
								)}
								<div className='content-wrapper overflow-hidden'>
									<h6 className='mb-0'>
										<i className={contentIcon} title={capitalize(selectedListItemData.type)}></i>
										<label className='text-dark ml-1 mb-0'>{selectedListItemData.title}</label>
									</h6>
									<div className='d-flex'>
										<ContentItemStatisticsComponent t={t} listItemData={selectedListItemData} />
									</div>
									<div className='flex-column content-item-list-data'>
										<small>{`${t('date_published')}: ${moment(selectedListItemData.published_at || selectedListItemData.publishedAt).format(
											'DD MMMM YYYY, HH:mm',
										)}`}</small>
										<small>{authorData}</small>
									</div>
								</div>
							</div>
						</div>
					</div>
				</Col>
				<Col className='action-buttons'>
					<div>
						<Button
							disabled={isLocked}
							id={`content-item-image-delete-btn-${itemIndex}`}
							color='danger'
							onClick={() => onDelete(selectedListItem.type, selectedListItemData.id)}
							className='mb-0'
						>
							<i className='fa fa-trash'></i>
						</Button>
					</div>
				</Col>
			</Row>
		</li>
	);
};

function mapStateToProps(state: any) {
	return {
		shouldShowListImages: state.ui.shouldShowListImages[state.profile.profile.id] || false,
		profileId: state.profile.profile.id || '',
	};
}

export default connect(mapStateToProps)(ListContentSortableItem);
