import React from 'react';
import Select from 'react-select';
import { Col, Row, Button, Input, Badge } from 'reactstrap';
import SquadPlayerModel from '../../../../../models/team-manual-data/squad-player/squad-player.model';
import DateTimePickerContainer from '../../../../Partials/Sidebar/GeneralContentAttributes/subcomponents/DateTimePicker/DateTimePickerContainer';
import EntitySearch from '../../../../Partials/Shared/search-entity/search-entity.component';
import Project from '../../../../../models/project/Project';
import { itemsToOptions } from '../../../../Partials/Shared/react-select-helpers/react-select.helpers';
import { contractTypes, contractValue } from '../helpers/manual-data.helper';
import { assetsPlaceholder } from '../../../../../constants/assetsPlaceholder';

type Properties = {
	players: SquadPlayerModel[];
	onContractChange: (player: SquadPlayerModel, contract: string) => void;
	startDateChange: (player: SquadPlayerModel, date: string) => void;
	shirtChange: (player: SquadPlayerModel, number: number) => void;
	removeFromSquad: (player: any) => void;
	playerChange: (player: SquadPlayerModel, tempId: string) => void;
	isContentLoading: boolean;
	addRow: () => void;
	deleteRow: (player: SquadPlayerModel) => void;
	project: Project;
	t: any;
};

const ActivePlayers: React.FunctionComponent<Properties> = (props) => {
	const {
		players,
		t,
		onContractChange,
		startDateChange,
		shirtChange,
		addRow,
		deleteRow,
		playerChange,
		removeFromSquad,
		isContentLoading,
		project,
	} = props;

	const activePlayers = players && players.length > 0 ? players.filter((player: SquadPlayerModel) => player.status === 'ACTIVE') : [];

	if (activePlayers.length === 0) {
		return (
			<div className={`card-body ${isContentLoading ? 'loading-overlay' : ''}`}>
				<p className='text-center pt-3'>{t('no_active_players')}</p>
				<Button className='m-3' color='primary' outline onClick={addRow}>
					<i className='fa fa-plus mx-1' />
					{t('add_row')}
				</Button>
			</div>
		);
	}

	return (
		<div className={`card-body p-1 ${isContentLoading ? 'loading-overlay' : ''}`}>
			<Row className='border-bottom px-3 py-2 mb-1 font-weight-bold'>
				<Col sm='3'>{t('player')}</Col>
				<Col sm='2'>{t('start_date')}</Col>
				<Col sm='1'>{t('shirt_number')}</Col>
				<Col sm='2'>{t('on_loan')}</Col>
				<Col sm='4' className='text-right'>
					{t('actions')}
				</Col>
			</Row>
			{activePlayers.map((player: SquadPlayerModel, index: number) => (
				<Row key={`${player.id}-${index}`} id={`active-player-row-${player.id}-${index}`} className='px-3 py-1 align-items-center'>
					<Col sm='3' className='mb-1'>
						{!player.id.match(/^tempId/) && (
							<Row title={player.name} id={`active-player-${player.id}-${index}`} className='pt-2 pr-2 pb-2 text-truncate align-items-center'>
								<img width='50' className='mr-2' src={player.image ? player.image : assetsPlaceholder.player} />

								<Col sm='2' className='align-items-center'>
									<Row>{player.name}</Row>
									<Row>
										<Badge className='mt-1' color='light'>
											{player.birthdate}
										</Badge>
									</Row>
								</Col>
							</Row>
						)}
						{player.id.match(/^tempId/) && (
							<EntitySearch id={player.id} t={t} value={player} entityType='player' inputLanguage={project.language} onChange={playerChange} />
						)}
					</Col>
					<Col sm='2' className='mb-1'>
						<DateTimePickerContainer
							id={`active-player-start-date-${player.id}-${index}`}
							enableTime={false}
							dateFormat='Y.m.d'
							date={player.startDate}
							t={t}
							placeholder={t('select_date')}
							onDateChange={(date: string) => startDateChange(player, date)}
						/>
					</Col>
					<Col sm='1' className='mb-1'>
						<Input
							onChange={(e: any) => shirtChange(player, e.target.value)}
							id={`shirt-number-input-${player.id}-${index}`}
							type='number'
							min={0}
							max={99}
							value={player.shirtNumber ? player.shirtNumber : ''}
						/>
					</Col>
					<Col sm='2' className='mb-1'>
						<Select
							inputId='player-manual-data-entry-contract-type'
							value={contractValue(player, t)}
							options={itemsToOptions(contractTypes, false, t, '')}
							onChange={(selection: any) => onContractChange(player, selection.value)}
							placeholder={t('select')}
						/>
					</Col>
					<Col sm='4' className='mb-1'>
						<Row className='justify-content-end'>
							{player.name && (
								<>
									<Button
										onClick={() => removeFromSquad({ player, action: 'remove' })}
										color='warning'
										id={`active-player-remove-from-squad-${player.id}-${index}`}
										className='px-1 py-2  m-1'
									>
										<i className='fa fa-times-circle mx-1' />
										{t('remove_from_squad')}
									</Button>
									<Button
										color='danger'
										onClick={() => removeFromSquad({ player, action: 'delete' })}
										id={`active-player-delete-association-${player.id}-${index}`}
										className='px-1 py-2 m-1'
									>
										<i className='fa fa-trash mx-1' />
										{player.name && t('delete_association')}
									</Button>
								</>
							)}
							{!player.name && (
								<Button
									color='base'
									onClick={() => deleteRow(player)}
									id={`empty-row-delete-${player.id}-${index}`}
									className={`px-1 py-2 ${!player.name ? 'ml-auto' : ''}`}
								>
									<i className='fa fa-times mx-1' />
									{player.name && t('delete_association')}
								</Button>
							)}
						</Row>
					</Col>
				</Row>
			))}
			<Button className='m-3' color='primary' outline onClick={addRow}>
				<i className='fa fa-plus mx-1' />
				{t('add_row')}
			</Button>
		</div>
	);
};

export default ActivePlayers;
