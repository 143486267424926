import { noPreviewImage } from '../../../../../../../../constants/resource-constants';

export const contentToOptions = (response: any) => {
	if (response && response.length > 0) {
		return response.map((content: any) => {
			return contentToOption(content);
		});
	}

	return [];
};

export const contentToOption = (content: any) => {
	if (content && content.url) {
		return {
			label: content.url,
			value: content.url,
			data: content,
		};
	}

	return {};
};

export const buildResizedIframe = (code: any) => {
	if (code && code.data && code.data.embedCode) {
		const isIframe = checkForIframe(code.data.embedCode);
		const url = extractSrc(code.data.embedCode);

		if (url && isIframe) {
			const resizedIframe = `<iframe
			width='100%'
			height='112px'
			frameBorder='0'
			allow='encrypted-media'
			src=${url}></iframe>`;

			return resizedIframe;
		}
	}

	return `<img src=${noPreviewImage} />`;
};

export const extractSrc = (iframe: any) => {
	if (iframe && iframe.length > 0) {
		const newIframe = iframe.match(/src=(".*?")/);
		const urlIframe = newIframe && newIframe[1] ? newIframe[1] : '';

		return urlIframe;
	}

	return null;
};

export const checkForIframe = (embed: any) => {
	if (embed && embed.length > 0) {
		const frameEmbed = embed.match(/(?:(?:<iframe.*?src="))/);
		const isIframe = frameEmbed ? true : false;

		return isIframe;
	}

	return false;
};

export const extractVideoType = (data: string) => {
	if (data && data.length > 0) {
		const extension = data.split('.') && data.split('.').length > 1 ? data.split('.').pop() : '';
		return extension ? extension : '';
	}
	return '';
};
