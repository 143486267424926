import * as React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';

type ActiceProps = {
	t: any;
	onChange: (isDefault: boolean) => any;
	isChecked: boolean;
	value?: boolean;
};

export const Active: React.FunctionComponent<ActiceProps> = ({ onChange, t, isChecked }) => {
	return (
		<FormGroup key={`is-active`} check className='radio'>
			<Input
				key={`is-active-input`}
				className='form-check-input'
				type='checkbox'
				id={`is-active-id`}
				name='radios'
				onChange={(event: any) => onChange(event.target.checked)}
				checked={isChecked}
			/>
			<Label key={`is-active-label`} check className='form-check-label' for={`is-active-id`}>
				{t('is_active')}
			</Label>
		</FormGroup>
	);
};
