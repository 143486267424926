import * as React from 'react';
import { FunctionComponent, useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import Cropper from 'react-cropper';
import Select from 'react-select';

export const CropperModal: FunctionComponent<any> = ({ imageUrl, isOpen, toggle, onCrop, t }) => {
	const ratios = [
		{ label: '23x9', value: 23 / 9 },
		{ label: '16x9', value: 16 / 9 },
		{ label: '9x16', value: 9 / 16 },
		{ label: '4x3', value: 4 / 3 },
		{ label: '3x4', value: 3 / 4 },
		{ label: '2x3', value: 2 / 3 },
		{ label: '3x2', value: 3 / 2 },
		{ label: '1x1', value: 1 / 1 },
	];
	const [aspectRatio, setAspectRatio] = useState(ratios[1].value);
	const [cropper, setCropper] = useState({} as Cropper);
	const [cropData, setCropData] = useState({} as any);

	const findSelectedCropRation = () => {
		return ratios.find((ratio) => ratio.value === aspectRatio);
	};

	return (
		<Modal fullscreen isOpen={isOpen} toggle={() => toggle(false)}>
			<ModalBody>
				<Select
					defaultValue={findSelectedCropRation()}
					options={ratios}
					onChange={(selection: any) => {
						setAspectRatio(selection.value);
						cropper.setAspectRatio(selection.value);
					}}
				/>
				<Cropper
					src={imageUrl}
					aspectRatio={aspectRatio}
					onInitialized={(cropperData: any) => setCropper(cropperData)}
					crop={(data: CustomEvent) => setCropData(data)}
				/>
			</ModalBody>
			<ModalFooter>
				<Button color='danger' onClick={() => toggle(false)}>
					{t('cancel')}
				</Button>
				<Button
					color='primary'
					onClick={() => {
						onCrop(cropData);
						toggle(false);
					}}
				>
					{t('crop_image')}
				</Button>
			</ModalFooter>
		</Modal>
	);
};
