export enum MultisportModalsType {
	RemoveEntity = 'removeEntity',
	Save = 'save',
}

export type PreviewEventForSave = {
	eventId: string;
	order: number;
};

export type PreviewDataForSave = {
	sport: string;
	events: PreviewEventForSave[];
};
