import TeamComparisonModel from '../models/team-comparison.model';
import TournamentModel from '../../player-profile/models/tournament-model/tournament.model';
import TeamModel from '../../../../partials/team-select/models/team.model';
import SeasonModel from '../../player-profile/models/season/season.model';

export const STAT_FIELDS_SELECT_OPTIONS = [
	{
		value: 'played',
		label: 'Matches played',
	},
	{
		value: 'win',
		label: 'Wins',
	},
	{
		value: 'draw',
		label: 'Draws',
	},
	{
		value: 'defeats',
		label: 'Defeats',
	},
	{
		value: 'goalsScored',
		label: 'Goals scored',
	},
	{
		value: 'goalsConceded',
		label: 'Goals conceded',
	},
];

export const STAT_FIELDS = {
	played: {
		value: 'played',
		label: 'Matches played',
	},
	win: {
		value: 'win',
		label: 'Wins',
	},
	draw: {
		value: 'draw',
		label: 'Draws',
	},
	defeats: {
		value: 'defeats',
		label: 'Defeats',
	},
	goalsScored: {
		value: 'goalsScored',
		label: 'Goals scored',
	},
	goalsConceded: {
		value: 'goalsConceded',
		label: 'Goals conceded',
	},
};

export const statFieldsToOptions = (stats: string[]) => {
	if (stats && stats.length > 0) {
		return stats.map((stat: string) => {
			return STAT_FIELDS[stat];
		});
	} else return [];
};

export const optionsToStatFields = (options: any[]) => {
	if (options && options.length > 0) {
		return options.map((option: any) => {
			return option.value;
		});
	} else return [];
};

export const updateModelTeamTournament = (
	model: TeamComparisonModel,
	tournament: TournamentModel,
	isTeam1: boolean,
): TeamComparisonModel => {
	if (isTeam1) {
		return TeamComparisonModel.builder(model).withTeamOne(TeamModel.builder(model.team1).withTournament(tournament).build()).build();
	} else {
		return TeamComparisonModel.builder(model).withTeamTwo(TeamModel.builder(model.team2).withTournament(tournament).build()).build();
	}
};

export const updateModelTeamSeason = (model: TeamComparisonModel, season: SeasonModel, isTeam1: boolean): TeamComparisonModel => {
	if (isTeam1) {
		return TeamComparisonModel.builder(model)
			.withTeamOne(
				TeamModel.builder(model.team1).withTournament(TournamentModel.builder(model.team1.tournament).withSeason(season).build()).build(),
			)
			.build();
	} else {
		return TeamComparisonModel.builder(model)
			.withTeamTwo(
				TeamModel.builder(model.team2).withTournament(TournamentModel.builder(model.team2.tournament).withSeason(season).build()).build(),
			)
			.build();
	}
};

export const updateModelTeam = (model: TeamComparisonModel, team: TeamModel, isTeam1: boolean): TeamComparisonModel => {
	if (isTeam1) {
		return TeamComparisonModel.builder(model).withTeamOne(TeamModel.builder(team).build()).build();
	} else {
		return TeamComparisonModel.builder(model).withTeamTwo(TeamModel.builder(team).build()).build();
	}
};
