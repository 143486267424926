import 'reflect-metadata';
import { TypedJSON } from 'typedjson';
import FansUnitedLeaderboardTemplatesModel from './fans-united-leaderboard-templates.model';

export default class FansUnitedLeaderboardTemplatesMapper {
	public responseToModel = (response: any): FansUnitedLeaderboardTemplatesModel => {
		const serializer = new TypedJSON(FansUnitedLeaderboardTemplatesModel);
		const remmapedTemplate = serializer.parse(response) as FansUnitedLeaderboardTemplatesModel;

		remmapedTemplate['isSelected'] = false;

		return remmapedTemplate;
	};
	public modelToRequestBody = (model: FansUnitedLeaderboardTemplatesModel) => {
		if (model) {
			return {
				id: model.id,
				name: model.name,
				type: model.type,
				from_date: model.from_date,
				to_date: model.to_date,
				markets: model.markets,
				team_ids: model.team_ids,
				game_ids: model.game_ids,
				match_ids: model.match_ids,
				game_types: model.game_types,
				competition_ids: model.competition_ids,
				rules: model.rules,
				flags: model.flags,
				description: model.description,
				created_at: model.created_at,
				updated_at: model.updated_at,
			};
		}
		return new FansUnitedLeaderboardTemplatesModel();
	};
}
