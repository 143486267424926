export const CHARACTER_LIMIT = 500;
export const DESCRIPTION_LIMIT = 255;

export const setInputBoxBorder = (summary: string | undefined) => {
	if (summary && summary.length > 500) {
		return 'border-danger';
	}
};

export const displayCharacterLimit = (summary: string | undefined) => {
	if (summary && summary.length > 0) {
		return `${summary.length}/${CHARACTER_LIMIT}`;
	} else {
		return `0/${CHARACTER_LIMIT}`;
	}
};

export const displayMainMediaDescriptionLimit = (description: string | undefined) => {
	if (description && description.length > 0) {
		return `${description.length}/${DESCRIPTION_LIMIT}`;
	} else {
		return `0/${DESCRIPTION_LIMIT}`;
	}
};
