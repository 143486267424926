import DugoutVideosModel from '../../../../../../models/dugout-videos/dugout-videos.model';
import DugoutVideoModel from '../../../../../../models/dugout-videos/dugout-video/dugout-video.model';

export const remapDugoutVideosFromResponse = (response: any) => {
	if (response && Object.entries(response).length > 0) {
		return DugoutVideosModel.builder().withTitle(response.title).withVideoList(remapDugoutVideoList(response.playlist)).build();
	}

	return {};
};

export const remapDugoutVideoList = (response: any) => {
	if (response && response.length > 0) {
		return response.map((video: any) => remapDugoutVideoFromResponse(video));
	}

	return [];
};

export const remapDugoutVideoFromResponse = (response: any) => {
	if (response && Object.entries(response).length > 0) {
		return DugoutVideoModel.builder()
			.withId(response.mediaid)
			.withTitle(response.title)
			.withEmbedCode(response.embed_code)
			.withTumbnail(response.thumbnail_320)
			.withHash(response.embed_hash)
			.withDescription(response.description)
			.build();
	}

	return {};
};
