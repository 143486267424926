import SpotlightAudioModel from './spotlight-audio.model';
import { SpotlightAudioJson } from './spotlight-audio.json';
import { SpotlightAudioRequestJson } from './spotlight-audio.request-json';

export default class SpotlightAudioBuilder {
	private json: SpotlightAudioJson;

	constructor(audio?: SpotlightAudioModel | SpotlightAudioJson) {
		if (audio && audio instanceof SpotlightAudioModel) {
			this.json = audio.toJson();
		} else if (audio) {
			this.json = audio;
		} else {
			this.json = {} as SpotlightAudioJson;
		}
	}

	public withHomeTeamId(homeTeamId: number): SpotlightAudioBuilder {
		this.json.homeTeamId = homeTeamId;

		return this;
	}

	public withHomeTeam(homeTeam: string): SpotlightAudioBuilder {
		this.json.homeTeam = homeTeam;

		return this;
	}

	public withAwayTeam(awayTeam: string): SpotlightAudioBuilder {
		this.json.awayTeam = awayTeam;

		return this;
	}

	public withAwayTeamId(awayTeamId: number): SpotlightAudioBuilder {
		this.json.awayTeamId = awayTeamId;

		return this;
	}

	public withCompetition(competition: string): SpotlightAudioBuilder {
		this.json.competition = competition;

		return this;
	}

	public withKickOff(kickOff: string): SpotlightAudioBuilder {
		this.json.kickOff = kickOff;

		return this;
	}

	public withLanguage(language: string): SpotlightAudioBuilder {
		this.json.language = language;

		return this;
	}

	public withSport(sport: string): SpotlightAudioBuilder {
		this.json.sport = sport;

		return this;
	}

	public withMatchId(matchId: number): SpotlightAudioBuilder {
		this.json.matchId = matchId;

		return this;
	}

	public withFilters(filters: any[]): SpotlightAudioBuilder {
		this.json.filters = filters;

		return this;
	}

	public withConfig(config: any[]): SpotlightAudioBuilder {
		this.json.config = config;

		return this;
	}

	toRequestJson(): SpotlightAudioRequestJson {
		let json = {} as SpotlightAudioRequestJson;
		json.homeTeamId = this.json.homeTeamId;
		json.homeTeam = this.json.homeTeam;
		json.awayTeamId = this.json.awayTeamId;
		json.awayTeam = this.json.awayTeam;
		json.competition = this.json.competition;
		json.kickOff = this.json.kickOff;
		json.language = this.json.language;
		json.sport = this.json.sport;
		json.matchId = this.json.matchId;
		json.filters = this.json.filters;
		json.config = this.json.config;

		return json;
	}

	public build(): SpotlightAudioModel {
		return SpotlightAudioModel.fromJSON(this.json);
	}
}
