import 'reflect-metadata';
import { jsonMember, jsonObject } from 'typedjson';

@jsonObject()
export default class TennisSeasonStagesModel {
	@jsonMember(String)
	public id: string = '';

	@jsonMember(String)
	public name: string = '';
}
