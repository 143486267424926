import React from 'react';
import { State } from './properties/team-form-edit.properties';
import { Properties } from './properties/team-form-edit.properties';
import { Col, FormGroup, Label, Row, Input } from 'reactstrap';
import TeamSelect from '../../../../partials/team-select/team-select.component';
import TournamentSelectContainer from '../../../../partials/tournament-select/tournament-select-container.component';
import TeamModel from '../../../../partials/team-select/models/team.model';
import TournamentModel from '../../player-profile/models/tournament-model/tournament.model';
import TeamFormWidgetModel from '../models/team-form-widget.model';
import { blockManagementService, blockWrapperService } from '../../../../subcomponents/blocky.component';
import BlockValidation from '../../../../helpers/block-validation.helper';
import SeasonModel from '../../player-profile/models/season/season.model';
import SeasonMultiSelectContainer from '../../../../partials/season-multi-select/season-multi-select-container.component';
import ThreeLetterCodeTypeSelect from '../../../../partials/three-letter-code-type-select/three-letter-code-type-select';

export default class TeamFormEdit extends React.Component<Properties, State> {
	constructor(props: Properties) {
		super(props);

		this.state = {
			teamForm:
				props.block && props.block.data && props.block.data.preview
					? TeamFormWidgetModel.builder(props.block.data.preview.teamForm).build()
					: TeamFormWidgetModel.builder()
							.withTeam(TeamModel.builder().build())
							.withSeason(SeasonModel.builder().build())
							.withLimit('15')
							.withTitle('')
							.withThreeLetterCodeType('')
							.build(),
			isValid: true,
		};
	}

	componentDidMount(): void {
		blockWrapperService.registerOnSaveFunc({ blockId: this.props.block.id, func: this.onSave });
	}

	onSave = (index: number) => {
		const data = { teamForm: this.state.teamForm };
		const validation = new BlockValidation();
		const isValid = validation.validate(this.props.block, data);
		this.setState({ ...this.state, isValid: isValid });

		if (isValid) {
			const block = Object.assign({}, this.props.block);
			block.data.preview = { teamForm: this.state.teamForm };

			blockManagementService.blockUpdate(block, index);
		} else {
			return isValid;
		}
	};

	componentWillUnmount(): void {
		blockWrapperService.unregisterOnSaveFunc(this.props.block.id);
	}

	onTeamChange = (team: TeamModel) => {
		this.setState({
			...this.state,
			teamForm: TeamFormWidgetModel.builder(this.state.teamForm)
				.withTeam(team)
				.withTournament([])
				.withSeason(SeasonModel.builder().build())
				.build(),
			isValid: true,
		});
	};

	onTournamentChange = (tournament: any) => {
		if (tournament.length > 0) {
			this.setState({
				...this.state,
				teamForm: TeamFormWidgetModel.builder(this.state.teamForm).withTournament(tournament).build(),
				isValid: true,
			});
		} else {
			this.setState({
				...this.state,
				teamForm: TeamFormWidgetModel.builder(this.state.teamForm).withTournament([]).withSeason(SeasonModel.builder().build()).build(),
				isValid: true,
			});
		}
	};

	onSeasonChange = (season: SeasonModel) => {
		this.setState({
			...this.state,
			teamForm: TeamFormWidgetModel.builder(this.state.teamForm).withSeason(season).build(),
			isValid: true,
		});
	};

	onLimitChange = (limit: string) => {
		this.setState({
			...this.state,
			teamForm: TeamFormWidgetModel.builder(this.state.teamForm).withLimit(limit).build(),
			isValid: true,
		});
	};

	onTitleChange = (title: string) => {
		this.setState({ ...this.state, teamForm: TeamFormWidgetModel.builder(this.state.teamForm).withTitle(title).build() });
	};

	onThreeLetterCodeTypeChange = (value: any) => {
		this.setState({
			...this.state,
			teamForm: TeamFormWidgetModel.builder(this.state.teamForm).withThreeLetterCodeType(value).build(),
		});
	};

	render() {
		const { teamForm } = this.state;
		const { t } = this.props;

		return (
			<>
				<Row>
					<Col>
						<FormGroup>
							<TeamSelect
								inputId='team-form-team-select'
								isMulti={false}
								t={t}
								value={teamForm.team ? teamForm.team : {}}
								onSelect={(team: TeamModel) => this.onTeamChange(team)}
								isValid={this.state.isValid}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					<Col>
						<FormGroup>
							<TournamentSelectContainer
								isBlocky
								withLabel
								t={t}
								isMulti={true}
								selectedTournament={teamForm.tournament}
								onTournamentSelect={this.onTournamentChange}
								isValid={this.state.isValid}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					<Col>
						<FormGroup>
							<SeasonMultiSelectContainer
								t={t}
								selectedSeasons={teamForm.tournament && teamForm.season}
								tournamentIds={teamForm.tournament}
								onSeasonSelect={this.onSeasonChange}
								isValid={this.state.isValid}
							/>
						</FormGroup>
					</Col>
				</Row>
				<ThreeLetterCodeTypeSelect
					t={this.props.t}
					isClearable={true}
					onThreeLetterCodeTypeChange={this.onThreeLetterCodeTypeChange}
					selectedThreeLetterCodeType={teamForm.threeLetterCodeType}
				/>
				<Row>
					<Col>
						<FormGroup>
							<Label>{t('limit')}:</Label>
							<Input
								type='number'
								disabled={!teamForm.team.id}
								className='form-control'
								value={teamForm.limit ? teamForm.limit : ''}
								max={15}
								min={1}
								onChange={(event: any) => this.onLimitChange(event.target.value)}
								placeholder={`${t('from')} 1 ${t('to')} 15`}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					<Col>
						<FormGroup>
							<Label>{t('title')}:</Label>
							<Input
								id='form-widget-title-input'
								type='text'
								className='form-control'
								value={teamForm.title}
								onChange={(event: any) => this.onTitleChange(event.target.value)}
								placeholder={t('title')}
							/>
						</FormGroup>
					</Col>
				</Row>
			</>
		);
	}
}
