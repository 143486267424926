import React, { FunctionComponent, useMemo, useState } from 'react';
import { DATA_QA } from '../../../helpers/data-qa-attributes';
import { chevronStyleGenerator } from '../../../helpers/icons';
import { connect } from 'react-redux';
import MultisportEventModel from '../../../models/event.model';
import { addMultisportEntities } from '../../../../../../store/action-creators/multisport-action-creator';
import CountBubble from '../../../../../Partials/design-components/count-bubble/count-bubble';
import MultisportCompetitionModelWrapper from '../../../models/competition-wrapper.model';
import { getHoursAndMinutesFromDate, isDateInRange } from '../../../../../../global-helpers/global-dates.helper';
import MultisportModel from '../../../models/sport.model';
import { TOP_EVENTS_SPORT, checkIfEntityExistInPreviewSection, extractLogoFromCompetition } from '../../../helpers/utils';
import CirclePlusIcon from '../../../../../Partials/design-components/icons/circle-plus';
import StarIcon from '../../../../../Partials/design-components/icons/star';
import { useTranslation } from 'react-i18next';

type Props = {
	addMultisportEntities: (events: MultisportEventModel[], isTopEvents: boolean) => void;
	competition: MultisportCompetitionModelWrapper;
	previewData: MultisportModel[];
	configurationDate: Date;
	eventSearchDate: Date;
};

const TournamentSection: FunctionComponent<Props> = ({
	addMultisportEntities,
	competition,
	previewData,
	configurationDate,
	eventSearchDate,
}) => {
	const { t } = useTranslation();
	const [isTournamentSectionVisible, setIsTournamentSectionVisible] = useState(true);
	const notAddedEvents = useMemo(() => competition.events.filter((ev) => !checkIfEntityExistInPreviewSection(ev.sport, ev, previewData)), [
		competition.events,
		previewData,
	]);

	const toggleTournamentSection = () => setIsTournamentSectionVisible(!isTournamentSectionVisible);
	const addEventToSection = (event: MultisportEventModel, isTopEvents: boolean = false) => addMultisportEntities([event], isTopEvents);
	const addGameToTopEvents = (event: MultisportEventModel) => addEventToSection(event, true);
	const addGameToSportEvents = (event: MultisportEventModel) => addEventToSection(event);

	return (
		<>
			<div className={DATA_QA.SPORT_SECTION_TOURNAMENT_HEADER}>
				<img className='competition-node-img' src={extractLogoFromCompetition(competition.competition)} />
				<span> {competition.competition.name}</span>
				<CirclePlusIcon
					action={() => notAddedEvents.length > 0 && addMultisportEntities(notAddedEvents, false)}
					disabled={notAddedEvents.length === 0 || !isDateInRange(configurationDate, eventSearchDate)}
				/>
				<CountBubble count={competition.events.length} title={t('competition_events_count')} />
				<i className={`${chevronStyleGenerator(isTournamentSectionVisible)} icon-end`} onClick={toggleTournamentSection} />
			</div>
			{isTournamentSectionVisible &&
				competition.events.map((event: MultisportEventModel) => (
					<div key={event.id} className={DATA_QA.SPORT_SECTION_TOURNAMENT_DATA}>
						<span className={DATA_QA.EVENTS_LIST_GAME_TIME}> {getHoursAndMinutesFromDate(event.start_time)}</span>
						<span className={DATA_QA.EVENTS_LIST_GAME_TITLE}>{event.name}</span>
						<div className={DATA_QA.EVENTS_LIST_GAME_ICONS}>
							<StarIcon
								action={() => !checkIfEntityExistInPreviewSection(TOP_EVENTS_SPORT, event, previewData) && addGameToTopEvents(event)}
								isActive={checkIfEntityExistInPreviewSection(TOP_EVENTS_SPORT, event, previewData)}
							/>
							<CirclePlusIcon
								action={() => !checkIfEntityExistInPreviewSection(event.sport, event, previewData) && addGameToSportEvents(event)}
								isActive={checkIfEntityExistInPreviewSection(event.sport, event, previewData)}
								disabled={!isDateInRange(configurationDate, event.start_time)}
							/>
						</div>
					</div>
				))}
		</>
	);
};

function mapStateToProps(state: any) {
	return {
		previewData: state.multisportConfiguration.previewData || [],
		configurationDate: state.multisportConfiguration.configurationDate
			? new Date(state.multisportConfiguration.configurationDate)
			: new Date(),
		eventSearchDate: state.multisportConfiguration.eventSearchDate ? new Date(state.multisportConfiguration.eventSearchDate) : new Date(),
	};
}

function mapDispatchToProps(dispatch: any) {
	return {
		addMultisportEntities: (events: MultisportEventModel[], isTopEvents: boolean) => dispatch(addMultisportEntities(events, isTopEvents)),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(TournamentSection);
