import React from 'react';
import { ContentTypes } from '../constants/block.types';
import BlockModel from '../models/block.model';
import { ViewTypes } from '../constants/general.constants';
import CustomBlock from '../blocks/content/custom-block/custom-block.component';

export default class CustomBlocksGenerator {
	public createBlock = (data: BlockModel, t: any, index: number, contentData?: any) => {
		if (data.type === ContentTypes.customBlock) {
			return <CustomBlock blockIndex={index} t={t} block={data} view={ViewTypes.normal} />;
		}
		return null;
	};
}
