import 'reflect-metadata';
import { jsonArrayMember, jsonMember, jsonObject } from 'typedjson';
import MultisportEventModel from './event.model';
import { MultisportCompetitionModel } from './competition.model';

@jsonObject()
export default class MultisportCompetitionModelWrapper {
	@jsonMember(MultisportCompetitionModel)
	public competition: MultisportCompetitionModel = new MultisportCompetitionModel();
	@jsonArrayMember(MultisportEventModel)
	public events: MultisportEventModel[] = [];
}
