import React, { FunctionComponent, useEffect } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { FormGroup, Label } from 'reactstrap';
import {
	autoGenerateReduxPropsNamingForContentModels,
	extractActionDraftDeleteTypeBasedOnUrl,
	generatePropsNamingByContentType,
	generateReduxPropsUpdateActionByContentType,
} from '../../../../../../services/content-models-service/ContentModelService';
import {
	extractContentModeBasedOnUrl,
	extractMainContentTypeBasedOnUrl,
	isArrayEmpty,
} from '../../../../../../global-helpers/global.helpers';
import { ContentMode } from '../../../../../../constants/content-types';
import { SelectMenuOptionType } from '../../../../../../models/v2/general/select.model';
import ContentAttribute from '../../../../../../models/v2/content-attributes/content-attributes.model';
import {
	contentAttributesToOptions,
	extractDefaultValue,
	extractSelectedStringAsContentAttribute,
} from '../../helpers/general-content-attributes.helper';
import { DATA_QA_ATTRIBUTES } from '../../constants/data-qa.attributes';
import { ReduxGeneralContentProps } from '../../constants/redux-general-content.attributes';
import { Subscription } from 'rxjs';
import { actionService } from '../../../../../../App';

type Properties = {
	t: any;
	statusesRedux: ContentAttribute[];
	selectedStatus: string;
	updateTempProperty: (propertyObject: Record<string, any>) => void;
};

const StatusSelect: FunctionComponent<Properties> = ({ t, statusesRedux, selectedStatus, updateTempProperty }) => {
	let entitySubscription: Subscription = {} as Subscription;

	useEffect(() => {
		if (extractContentModeBasedOnUrl() === ContentMode.CREATE && !selectedStatus) {
			const defaultStatus = extractDefaultValue(statusesRedux);
			defaultStatus && defaultStatus.slug && updateTempProperty({ [ReduxGeneralContentProps.STATUS]: defaultStatus.slug });
		}
	}, []);

	useEffect(() => {
		entitySubscription = actionService.onActionReceived().subscribe((action: string) => {
			if (action === extractActionDraftDeleteTypeBasedOnUrl()) {
				if (extractContentModeBasedOnUrl() === ContentMode.CREATE) {
					const defaultStatus = extractDefaultValue(statusesRedux);
					defaultStatus && defaultStatus.slug && updateTempProperty({ [ReduxGeneralContentProps.STATUS]: defaultStatus.slug });
				}
			}
		});

		return () => entitySubscription.unsubscribe();
	}, [selectedStatus]);

	const onStatusSelect = (selectedStatusOption: SelectMenuOptionType) => {
		const selectedStatus = (selectedStatusOption && selectedStatusOption.data && selectedStatusOption.data.slug) || '';
		updateTempProperty({ [ReduxGeneralContentProps.STATUS]: selectedStatus });
	};

	const selectMenuValue = extractSelectedStringAsContentAttribute(selectedStatus, statusesRedux);

	return isArrayEmpty(statusesRedux) ? null : (
		<FormGroup>
			<Label htmlFor={DATA_QA_ATTRIBUTES.STATUS}>{t('status')}</Label>
			<Select
				id={DATA_QA_ATTRIBUTES.STATUS}
				value={selectMenuValue && contentAttributesToOptions([selectMenuValue])}
				options={contentAttributesToOptions(statusesRedux)}
				onChange={onStatusSelect}
				placeholder={t('select')}
				noOptionsMessage={(inputValue) => inputValue && t('no_options')}
			/>
		</FormGroup>
	);
};

function mapStateToProps(state: any) {
	const contentTypeFromUrl = extractMainContentTypeBasedOnUrl() || '';
	const statusesPropNaming = generatePropsNamingByContentType(contentTypeFromUrl, false) + 'Statuses';
	const { tempPropertyNaming, nestedTempPropertyNaming } = autoGenerateReduxPropsNamingForContentModels();

	return {
		selectedStatus: state[tempPropertyNaming][nestedTempPropertyNaming].status || '',
		statusesRedux: state.statuses[statusesPropNaming] || [],
	};
}

function mapDispatchToProps(dispatch: Function) {
	const contentTypeFromUrl = extractMainContentTypeBasedOnUrl();
	const functionForDispatch = generateReduxPropsUpdateActionByContentType(contentTypeFromUrl);

	return {
		updateTempProperty: (propertyObject: Record<string, any>) =>
			functionForDispatch && dispatch(functionForDispatch(propertyObject, extractContentModeBasedOnUrl())),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(StatusSelect);
