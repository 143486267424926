export const roundToOption = (round: any) => {
	if (round && Object.entries(round).length > 0) {
		return { value: round.round, label: round.round, data: round };
	}

	return null;
};

export const roundsToOptions = (rounds: any) => {
	if (rounds && rounds.length > 0) {
		return rounds.map((round: any) => {
			return roundToOption(round);
		});
	}

	return [];
};
