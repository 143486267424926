import TournamentModel from '../../../../../../../models/v2/Tournament/Entity/TournamentModel';
import StageModel from '../../../../../../../models/v2/Stage/Entity/StageModel';
import SeasonModel from '../../../../../../../models/v2/Season/Entity/SeasonModel';
import { KnockoutWidgetV2Json } from './knockout-widget-v2.json';
import KnockoutWidgetV2Builder from './knockout-widget-v2.builder';
import { RefreshTimeValuesType } from '../../refresh-time-input/helper';

export default class KnockoutWidgetV2Model {
	readonly tournament: TournamentModel;
	readonly season: SeasonModel;
	readonly stage: StageModel;
	readonly refreshTime: RefreshTimeValuesType;

	private constructor(tournament: TournamentModel, season: SeasonModel, stage: StageModel, refreshTime: RefreshTimeValuesType) {
		this.tournament = tournament;
		this.season = season;
		this.stage = stage;
		this.refreshTime = refreshTime;
	}

	toJson(): KnockoutWidgetV2Json {
		return {
			tournament: this.tournament,
			season: this.season,
			stage: this.stage,
			refreshTime: this.refreshTime,
		};
	}

	static fromJson(json: KnockoutWidgetV2Json): KnockoutWidgetV2Model {
		return new KnockoutWidgetV2Model(json.tournament, json.season, json.stage, json.refreshTime);
	}

	static builder(knockoutWidget?: KnockoutWidgetV2Model): KnockoutWidgetV2Builder {
		return new KnockoutWidgetV2Builder(knockoutWidget);
	}
}
