import { PermissionJson } from './PermissionJson';
import Permission from './Permission';

export default class PermissionBuilder {
	private json: PermissionJson;

	constructor(permission?: Permission) {
		this.json = permission ? permission.toJSON() : <PermissionJson>{};
	}

	withId(id: string): PermissionBuilder {
		this.json.id = id;

		return this;
	}

	withName(name: string): PermissionBuilder {
		this.json.name = name;

		return this;
	}

	withTitle(title: string): PermissionBuilder {
		this.json.title = title;

		return this;
	}

	build(): Permission {
		return Permission.fromJSON(this.json);
	}
}
