import React from 'react';
import ButtonDropdown from 'reactstrap/lib/ButtonDropdown';
import DropdownToggle from 'reactstrap/lib/DropdownToggle';
import DropdownMenu from 'reactstrap/lib/DropdownMenu';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import { Button, Input, Tooltip } from 'reactstrap';
import '../styles/add-block.component.scss';
import { BLOCK_LIST } from '../../../../constants/block-list.constants';
import BlockListCategory from './block-list-category.component';
import BlockModelBuilder from '../../../../models/block-model.builder';
import { blockManagementService } from '../../../../subcomponents/blocky.component';
import Global from '../../../../helpers/global.helpers';
import { featuresService } from '../../../../../../../App';
import { displayBlockCategory } from './hepers/block-list-category.helper';

type Properties = {
	t: any;
	blockIndex: number;
	contentType?: string;
};

type State = {
	isDropDownOpen: boolean;
	accordion: boolean[];
	blockListState: any[];
	tooltipOpen: string;
	isShown: boolean;
	customBlockRequested: boolean;
};

const initialAccordion = [true, false, false, false, false, false, false, false, false];

class AddBlock extends React.Component<Properties, State> {
	constructor(props: any) {
		super(props);
		this.state = {
			isDropDownOpen: false,
			accordion: initialAccordion,
			blockListState: [],
			tooltipOpen: '',
			isShown: false,
			customBlockRequested: false,
		};
		this.toggle = this.toggle.bind(this);
	}

	onBlockSearch = (event: any) => {
		this.search(event.target.value.trim(), this.props.t).then((list: any[]) => {
			const accordion = list.length > 0 && list.length === 1 ? list.map((el: any) => true) : initialAccordion;
			this.setState({
				blockListState: list,
				accordion,
			});
		});
	};

	componentDidMount() {
		this.setState({
			blockListState: BLOCK_LIST(false),
		});
	}

	//TODO Move to a separate class for unit testing
	search = (input: string, t: any): Promise<any[]> => {
		return new Promise((accept: any, reject: any) => {
			let list = [];
			let SEARCHED_LIST;

			SEARCHED_LIST = JSON.parse(
				JSON.stringify(
					BLOCK_LIST(false).filter((block: any) => featuresService.areFeaturesEnabled([block && block.feature_id]) && block !== null),
				),
			);
			if (input && input.length > 0) {
				const escapedText = input.replace(/([.^$|*+?()\[\]{}\\-])/g, '\\$1');
				const regExp = new RegExp(`${escapedText}`, 'i');
				list = SEARCHED_LIST.map((category: any) => {
					const blocks = category.blocks.filter((block: any) => {
						return regExp.test(t(block.name));
					});

					if (blocks && blocks.length > 0) {
						category.blocks = blocks;

						return category;
					}
				}).filter((category: any) => category);
			} else {
				list = BLOCK_LIST(false);
			}
			accept(list);
		});
	};

	toggleAccordion(toggle: boolean, index: number) {
		const accordion = JSON.parse(JSON.stringify(this.state.accordion));

		for (let i = 0; i < accordion.length; i++) {
			if (index !== i) {
				accordion[i] = false;
			} else {
				accordion[i] = toggle;
			}
		}

		this.setState({
			accordion,
		});
	}

	toggle(toggle: boolean) {
		this.setState({
			isDropDownOpen: toggle,
			blockListState: BLOCK_LIST(false),
			accordion: initialAccordion,
		});
	}

	setIsShown = (bool: boolean, id: string) => {
		this.setState({ ...this.state, tooltipOpen: id, isShown: bool });
	};

	private onBlockAdd = (data: any) => {
		this.toggle(false);
		data.id = `${Global.makeId(6)}`;
		const block = new BlockModelBuilder(data).build();
		blockManagementService.blockAdd({ block, index: this.props.blockIndex });
	};

	render() {
		const { t, contentType } = this.props;

		const { blockListState, accordion, tooltipOpen, isShown } = this.state;
		return (
			<div className='add-block text-center mb-2'>
				<Row>
					{BLOCK_LIST(false)[0] &&
						BLOCK_LIST(false)[0].blocks &&
						BLOCK_LIST(false)[0].blocks.map((block: any, index) => {
							return (
								<div key={index}>
									<Button
										id={`tooltip-${block.name}-${this.props.blockIndex}-${index}`}
										key={JSON.stringify(block)}
										caret='true'
										bssize='sm'
										className='mb-1 ml-2 shortcut-btn'
										color='outline-secondary'
										onClick={() => {
											this.onBlockAdd(JSON.parse(JSON.stringify(block.default_data)));
										}}
										onMouseOver={() => this.setIsShown(true, `tooltip-${block.name}-${this.props.blockIndex}-${index}`)}
										onMouseLeave={() => this.setIsShown(false, '')}
									>
										<i className={block.icon}></i>
									</Button>
									{isShown && (
										<Tooltip
											placement='top'
											isOpen={tooltipOpen === `tooltip-${block.name}-${this.props.blockIndex}-${index}`}
											target={`tooltip-${block.name}-${this.props.blockIndex}-${index}`}
											key={`tooltip-${block.name}-${this.props.blockIndex}-${index}`}
										>
											{t(block.name)}
										</Tooltip>
									)}
								</div>
							);
						})}
					<ButtonDropdown
						isOpen={this.state.isDropDownOpen}
						toggle={() => {
							this.toggle(!this.state.isDropDownOpen);
						}}
						className='ml-2 mb-2'
					>
						<DropdownToggle caret bssize='sm' className='mb-1' color='outline-secondary' title={t('add_block')}>
							<i className={'fa fa-plus'}></i>
						</DropdownToggle>
						<DropdownMenu className={'min-width-300 border-1 p-0 category-listing'}>
							<Container bssize='sm' className='p-2 h-50'>
								<Row className='mb-1'>
									<Col>
										<Input
											type='text'
											id={`search-blocks-input-${this.props.blockIndex}`}
											name={`search-blocks-input-${this.props.blockIndex}`}
											className='form-control-m'
											placeholder={t('blocky_block_search_placeholder')}
											onChange={this.onBlockSearch}
										/>
									</Col>
								</Row>
								{blockListState
									.filter((block: any) =>
										displayBlockCategory(block)
											? featuresService.checkFeatureIsSetAndEnabled(block.feature_id)
											: featuresService.areFeaturesEnabled([block && block.feature_id]),
									)
									.map((category: any, index: number) => {
										return (
											<BlockListCategory
												onBlockAdd={this.onBlockAdd}
												t={t}
												key={category && category.id}
												onToggleAccordion={(toggle: boolean) => this.toggleAccordion(toggle, index)}
												isOpen={accordion[index]}
												blockListCategory={category}
												id={`${category && category.id}-${this.props.blockIndex}`}
												contentType={contentType ? contentType : ''}
											/>
										);
									})}
							</Container>
						</DropdownMenu>
					</ButtonDropdown>
				</Row>
			</div>
		);
	}
}

export default AddBlock;
