import Project from '../../models/project/Project';
import {
	AVAILABLE_PROJECTS,
	CLEAR_PROJECTS_RESOURCES_LOADED,
	POLLING_PROJECT_RESOURCES,
	PROJECT_RESOURCES_REQUEST,
} from '../../constants/constants';
import ErrorResponseModel from '../../models/error-response/ErrorResponseModel';
import { toast } from 'react-toastify';
import ContentAttributes from '../../models/content-attributes/content-attributes-model';

export const PROJECT_RESOURCES_RECEIVED = '[ACTION] PROJECT_RESOURCES_RECEIVED';
export const PROJECT_RESOURCES_FAILED = '[ACTION] PROJECT_RESOURCES_FAILED';
export const PROJECT_FEATURES_RECEIVED = '[ACTION] PROJECT_FEATURES_RECEIVED';
export const CURRENT_PROJECT_SELECT = '[ACTION] CURRENT_PROJECT_SELECT';
export const DISTRIBUTION_REGIONS_RECEIVED = '[ACTION] DISTRIBUTION_REGIONS_RECEIVED';
export const DISTRIBUTION_CHANNELS_RECEIVED = '[ACTION] DISTRIBUTION_CHANNELS_RECEIVED';

export const LANGUAGE_CODE_UPDATE = '[ACTION] LANGUAGE_CODE_UPDATE';
export const LANGUAGE_CODE_UPDATE_SUCCESS = '[ACTION] LANGUAGE_CODE_UPDATE_SUCCESS';

export const SPORTS_RECEIVED = '[ACTION] SPORTS_RECEIVED';

export function projectFeaturesReceived(features: any) {
	return {
		type: PROJECT_FEATURES_RECEIVED,
		payload: features ? [...features] : [],
	};
}

export function projectResourcesRequest(project: Project, id: string, history: any) {
	return {
		type: PROJECT_RESOURCES_REQUEST,
		payload: {
			project,
			adminId: id,
			history,
		},
	};
}

export function pollingProjectResources(project: Project, id: string) {
	return {
		type: POLLING_PROJECT_RESOURCES,
		payload: {
			project,
			adminId: id,
		},
	};
}

export function projectResourcesReceived() {
	return {
		type: PROJECT_RESOURCES_RECEIVED,
		payload: true,
	};
}

export function projectResourcesFailed(error: any) {
	toast.error('error.response.data.error');

	return {
		type: PROJECT_RESOURCES_FAILED,
		payload: ErrorResponseModel.builder().fromResponse(error).build(),
	};
}

export function clearProjectResourcesLoaded() {
	return {
		type: CLEAR_PROJECTS_RESOURCES_LOADED,
	};
}

export function currentProjectSelect(project: Project) {
	return {
		type: CURRENT_PROJECT_SELECT,
		payload: project,
	};
}

export function availableProjects() {
	return {
		type: AVAILABLE_PROJECTS,
	};
}

export function distributionRegionsReceived(regions: ContentAttributes) {
	return {
		type: DISTRIBUTION_REGIONS_RECEIVED,
		payload: regions,
	};
}

export function distributionChannelsReceived(channels: ContentAttributes) {
	return {
		type: DISTRIBUTION_CHANNELS_RECEIVED,
		payload: channels,
	};
}

export function projectLanguageCodeUpdate(project: Project) {
	return {
		type: LANGUAGE_CODE_UPDATE,
		payload: project,
	};
}

export function projectLanguageCodeUpdateSuccess() {
	return {
		type: LANGUAGE_CODE_UPDATE_SUCCESS,
	};
}

export const sportsReceived = (sports: any) => ({
	type: SPORTS_RECEIVED,
	payload: sports ? [...sports] : [],
});
