import { TeamFormWidgetJson } from './team-form-widget.json';
import TeamFormWidgetBuilder from './team-form-widget.builder';
import TeamModel from '../../../../partials/team-select/models/team.model';
import TournamentModel from '../../player-profile/models/tournament-model/tournament.model';
import SeasonModel from '../../player-profile/models/season/season.model';

export default class TeamFormWidgetModel {
	readonly team: TeamModel;
	readonly tournament: TournamentModel[];
	readonly season: SeasonModel;
	readonly limit: string = '15';
	readonly noData: boolean;
	readonly title: string;
	readonly threeLetterCodeType: any = '';

	private constructor(
		team: TeamModel,
		tournament: TournamentModel[],
		season: SeasonModel,
		limit: string,
		noData: boolean,
		title: string,
		threeLetterCodeType: any,
	) {
		this.team = team;
		this.tournament = tournament;
		this.season = season;
		this.limit = limit;
		this.noData = noData;
		this.title = title;
		this.threeLetterCodeType = threeLetterCodeType;
	}

	toJson(): TeamFormWidgetJson {
		return {
			team: this.team,
			tournament: this.tournament,
			season: this.season,
			limit: this.limit,
			noData: this.noData,
			title: this.title,
			threeLetterCodeType: this.threeLetterCodeType,
		};
	}

	static fromJson(json: TeamFormWidgetJson): TeamFormWidgetModel {
		return new TeamFormWidgetModel(json.team, json.tournament, json.season, json.limit, json.noData, json.title, json.threeLetterCodeType);
	}

	static builder(team?: TeamFormWidgetModel): TeamFormWidgetBuilder {
		return new TeamFormWidgetBuilder(team);
	}
}
