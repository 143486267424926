import React from 'react';
import { blockManagementService, blockWrapperService } from '../../../../../subcomponents/blocky.component';
import BlockValidation from '../../../../../helpers/block-validation.helper';
import { Col, FormGroup, Row } from 'reactstrap';
import FansUnitedWidgetModel from '../../models/fans-united-widget.model';
import ErrorHandler from '../../../../../partials/error/error-handler-component';
import BlockModel from '../../../../../models/block.model';
import { FansUnitedContentContainer } from '../../../../../partials/fans-united/fu-managers-content-container';
import { FansUnitedWidgetsTypes } from '../../../../../constants/block.types';
import i18n from 'i18next';
import FansUnitedEitherOrModel from '../../models/fans-united-either-or.model';

type Properties = {
	block: BlockModel;
};

type State = {
	widgetModel: FansUnitedWidgetModel;
	isValid: boolean;
};

export default class EitherOrGameManagerEditComponent extends React.Component<Properties, State> {
	constructor(props: Properties) {
		super(props);
		this.state = {
			widgetModel:
				props.block && props.block.data.widget && props.block.data.widget.eitherOrGame && props.block.data.widget.eitherOrGame.id
					? FansUnitedWidgetModel.builder(props.block.data.widget).build()
					: FansUnitedWidgetModel.builder().build(),
			isValid: true,
		};
	}

	componentDidMount(): void {
		blockWrapperService.registerOnSaveFunc({ blockId: this.props.block.id, func: this.onSave });
	}

	componentWillUnmount(): void {
		blockWrapperService.unregisterOnSaveFunc(this.props.block.id);
	}

	onSave = (index: number) => {
		const data = { widget: this.state.widgetModel };
		const validation = new BlockValidation();
		const isValid = validation.validate(this.props.block, data) === true;

		this.setState({ ...this.state, isValid });

		if (isValid) {
			const block = Object.assign({}, this.props.block);
			block.data.widget = {
				eitherOrGame: this.state.widgetModel.eitherOrGame,
			};
			blockManagementService.blockUpdate(block, index);
		} else {
			return isValid;
		}
	};

	onEitherOrGameChange = (game: FansUnitedEitherOrModel) => {
		const widgetModel = FansUnitedWidgetModel.builder(this.state.widgetModel).withEitherOrGame(game).build();
		this.setState({ ...this.state, isValid: true, widgetModel });
	};

	render() {
		const { widgetModel, isValid } = this.state;

		return (
			<div data-qa='widget-either-or-game-edit'>
				<Row>
					<Col>
						<FormGroup>
							<FansUnitedContentContainer
								selectedId={widgetModel && widgetModel.eitherOrGame && widgetModel.eitherOrGame.id ? widgetModel.eitherOrGame.id : ''}
								onSelect={this.onEitherOrGameChange}
								maxItems={30}
								componentType={FansUnitedWidgetsTypes.eitherOr}
							/>
						</FormGroup>
					</Col>
				</Row>
				{!isValid && ((widgetModel.eitherOrGame && !widgetModel.eitherOrGame.id) || !widgetModel.eitherOrGame) && (
					<div className='mb-3'>
						<ErrorHandler errorMessage='no_either_or_selected' />
					</div>
				)}
			</div>
		);
	}
}
