import Category from '../../../../../../models/category/Category';
import { featuresService } from '../../../../../../App';
import { FeatureTypes } from '../../../../../../services/feature-service/features.enum';
import { appendEmptyCategoryOption } from '../CategorySelect/CategorySelectHelper';

export function categoryAdminIdsFromCategoryAdmins(adminCategories: Category[]) {
	if (adminCategories) {
		return adminCategories.map((category: Category) => category.id);
	}

	return [];
}

export function categoriesToOptions(categories: Category[], adminCategories: Category[]) {
	let options: any[] = [];
	const adminCategoriesIds = categoryAdminIdsFromCategoryAdmins(adminCategories);
	const displayInactiveCategories = featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.DISPLAY_INACTIVE_CATEGORIES);
	if (categories) {
		options = categories
			.filter((category: Category) => {
				// Display inactive categories if the feature for displaying inactive categories is enabled
				if (displayInactiveCategories) {
					return true;
				} else {
					return category.active;
				}
			})
			.map((category: Category) => returnMappedCategories(category, adminCategoriesIds));
	}

	return options;
}

export const isCategoryDisabled = (adminCategoriesIds: string[], categoryId: string) => {
	return adminCategoriesIds && categoryId ? !adminCategoriesIds.includes(categoryId) : false;
};

export const returnMappedCategories = (category: Category, adminCategoriesIds: string[]) => {
	const inactiveText =
		!category.active && featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.DISPLAY_INACTIVE_CATEGORIES) ? '(Inactive)' : '';

	return {
		value: category.id,
		label: `${category.subIndent ? category.subIndent : ''} ${category.title} ${inactiveText}`,
		category,
		isDisabled: isCategoryDisabled(adminCategoriesIds, category.id),
	};
};

export function selectedCategoriesToOptions(categories: Category[]) {
	let selectedOptions: any[] = [];

	if (categories) {
		selectedOptions = categories.map((category: Category) => categoryToOption(category));
	}

	return selectedOptions;
}

export function categoryToOption(category: Category) {
	let option = {};

	if (category) {
		option = { label: category.title, value: category.id, category };
	}

	return option;
}

export function selectedOptionsToCategories(selections: any[]) {
	let categories: Category[] = [];

	if (selections) {
		categories = selections.map((selection: any) => selection.category);
	}

	return categories;
}
