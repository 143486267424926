import 'reflect-metadata';
import { jsonArrayMember, jsonMember, jsonObject } from 'typedjson';
import CategoryModel from '../category/category.model';
import RelatedModel from '../related/related';

@jsonObject
export default class ListConfigurationModel {
	@jsonMember(String, { name: 'min_items' })
	public minItems: string = '';

	@jsonMember(String, { name: 'max_items' })
	public maxItems: string = '';

	@jsonMember(CategoryModel)
	public category: CategoryModel | null = null;

	@jsonArrayMember(RelatedModel)
	public sports: RelatedModel[] = [];
}
