import React from 'react';
import PlayerH2HWidgetEdit from './player-h2h-widget-edit.component';
import { PlayerH2HWidgetView } from './player-h2h-widget-view.component';
import { Properties } from './properties/player-h2h-widget.properties';
import { ViewTypes } from '../../../../constants/general.constants';
import OddsWidgetModel from '../../odds/models/odds-widget.model';
import MatchWidgetModel from '../../match/models/match-widget.model';
import { WidgetPreview } from '../../widget-preview/widget-preview.component';
import BlockUpdateController from '../../../block-update-controller.component';

const PlayerH2HWidget: React.FunctionComponent<Properties> = (props) => {
	if (props.view === ViewTypes.edit) {
		return <PlayerH2HWidgetEdit block={props.block} t={props.t} />;
	}

	if (props.view === ViewTypes.normal) {
		return (
			<PlayerH2HWidgetView
				previewOdds={
					props.block.data.preview && props.block.data.preview.odds ? props.block.data.preview.odds : OddsWidgetModel.builder().build()
				}
				preview={
					props.block.data.preview && props.block.data.preview.playerH2H
						? props.block.data.preview.playerH2H
						: MatchWidgetModel.builder().build()
				}
				t={props.t}
			/>
		);
	}

	if (props.view === ViewTypes.preview) {
		return <WidgetPreview blockContent={props.block.data.content} config={props.block.data.config} />;
	}

	return null;
};

export default BlockUpdateController(PlayerH2HWidget);
