import React, { FunctionComponent, useEffect, useState } from 'react';
import Related from '../../../../../../../models/related/Related';
import { sportsConnectionsService } from '../../../../../../../App';
import { competitionRelatedToOption, convertResultToOption, optionToRelatedContent } from './sports-connections.helper';
import _ from 'lodash';
import { customOption } from '../../../../../Sidebar/tags/subcomponents/football-connections/option-component';
import AsyncSelect from 'react-select/async';

export const CompetitionSelect: FunctionComponent<{
	onCompetitionSelect: (type: any) => void;
	related: Related;
	t: any;
	sportType: string;
	contentLanguage: string;
}> = ({ onCompetitionSelect, t, related, sportType, contentLanguage }) => {
	const [selectedCompetition, setSelectedCompetition] = useState();
	const [newValue, setNewValue] = useState<string>('');

	useEffect(() => {
		setSelectedCompetition(competitionRelatedToOption(related));
	}, []);

	useEffect(() => {
		setSelectedCompetition(competitionRelatedToOption(related));
	}, [related]);

	const onInputChange = (input: string) => {
		setNewValue(input);

		return input;
	};

	const onSelect = (selections: any) => {
		selections = selections === null ? [] : selections;
		const relatedData = optionToRelatedContent(selections);
		onCompetitionSelect(relatedData);
	};

	const loadCompetitionData = (input: string, callback: any) => {
		if (input.length >= 3) {
			sportsConnectionsService.getCompetition(input, sportType, contentLanguage).then((res) => {
				let options =
					res &&
					res.map((result: any) => {
						return convertResultToOption(result);
					});

				callback([...options]);
			});
		}
	};

	return (
		<AsyncSelect
			noOptionsMessage={(inputValue) => inputValue && t('no_options')}
			value={selectedCompetition && Object.entries(selectedCompetition).length > 0 ? selectedCompetition : null}
			loadOptions={_.debounce(loadCompetitionData, 500)}
			onInputChange={_.debounce(onInputChange, 500)}
			onChange={onSelect}
			inputId={'advance-filters-competition-select'}
			formatOptionLabel={customOption}
			placeholder={t('select')}
			isClearable={true}
		/>
	);
};
