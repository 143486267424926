import React, { FunctionComponent, useEffect } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { FormGroup, Label } from 'reactstrap';
import {
	autoGenerateReduxPropsNamingForContentModels,
	extractActionDraftDeleteTypeBasedOnUrl,
	generatePropsNamingByContentType,
	generateReduxPropsUpdateActionByContentType,
} from '../../../../../../services/content-models-service/ContentModelService';
import {
	extractContentModeBasedOnUrl,
	extractMainContentTypeBasedOnUrl,
	isArrayEmpty,
} from '../../../../../../global-helpers/global.helpers';
import { ContentMode } from '../../../../../../constants/content-types';
import { SelectMenuOptionType } from '../../../../../../models/v2/general/select.model';
import ContentAttribute from '../../../../../../models/v2/content-attributes/content-attributes.model';
import {
	contentAttributesToOptions,
	extractDefaultValue,
	extractSelectedStringAsContentAttribute,
} from '../../helpers/general-content-attributes.helper';
import { DATA_QA_ATTRIBUTES } from '../../constants/data-qa.attributes';
import { ReduxGeneralContentProps } from '../../constants/redux-general-content.attributes';
import { actionService } from '../../../../../../App';
import { Subscription } from 'rxjs';

type Properties = {
	t: any;
	typesRedux: ContentAttribute[];
	selectedType: string;
	updateTempProperty: (propertyObject: Record<string, any>) => void;
};

const TypeSelect: FunctionComponent<Properties> = ({ t, typesRedux, selectedType, updateTempProperty }) => {
	let entitySubscription: Subscription = {} as Subscription;

	useEffect(() => {
		if (extractContentModeBasedOnUrl() === ContentMode.CREATE && !selectedType) {
			const defaultType = extractDefaultValue(typesRedux);
			defaultType && defaultType.slug && updateTempProperty({ [ReduxGeneralContentProps.TYPE]: defaultType.slug });
		}
	}, []);

	useEffect(() => {
		entitySubscription = actionService.onActionReceived().subscribe((action: string) => {
			if (action === extractActionDraftDeleteTypeBasedOnUrl()) {
				if (extractContentModeBasedOnUrl() === ContentMode.CREATE) {
					const defaultType = extractDefaultValue(typesRedux);
					defaultType && defaultType.slug && updateTempProperty({ [ReduxGeneralContentProps.TYPE]: defaultType.slug });
				}
			}
		});

		return () => entitySubscription.unsubscribe();
	}, [selectedType]);

	const onTypeSelect = (selectedTypeOption: SelectMenuOptionType | null) => {
		const selectedType = (selectedTypeOption && selectedTypeOption.data && selectedTypeOption.data.slug) || '';
		updateTempProperty({ [ReduxGeneralContentProps.TYPE]: selectedType });
	};

	const selectMenuValue = extractSelectedStringAsContentAttribute(selectedType, typesRedux);

	return isArrayEmpty(typesRedux) ? null : (
		<FormGroup>
			<Label htmlFor={DATA_QA_ATTRIBUTES.TYPE}>{t('type')}</Label>
			<Select
				id={DATA_QA_ATTRIBUTES.TYPE}
				value={selectMenuValue && contentAttributesToOptions([selectMenuValue])}
				options={contentAttributesToOptions(typesRedux)}
				onChange={onTypeSelect}
				isClearable
				placeholder={t('select')}
				noOptionsMessage={(inputValue) => inputValue && t('no_options')}
			/>
		</FormGroup>
	);
};

function mapStateToProps(state: any) {
	const contentTypeFromUrl = extractMainContentTypeBasedOnUrl() || '';
	const statusesPropNaming = generatePropsNamingByContentType(contentTypeFromUrl, false) + 'Types';
	const { tempPropertyNaming, nestedTempPropertyNaming } = autoGenerateReduxPropsNamingForContentModels();

	return {
		selectedType: state[tempPropertyNaming][nestedTempPropertyNaming].type || '',
		typesRedux: state.types[statusesPropNaming] || [],
	};
}

function mapDispatchToProps(dispatch: Function) {
	const contentTypeFromUrl = extractMainContentTypeBasedOnUrl();
	const functionForDispatch = generateReduxPropsUpdateActionByContentType(contentTypeFromUrl);

	return {
		updateTempProperty: (propertyObject: Record<string, any>) =>
			functionForDispatch && dispatch(functionForDispatch(propertyObject, extractContentModeBasedOnUrl())),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(TypeSelect);
