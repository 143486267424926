import React from 'react';
import { contentToOption, contentToOptions } from '../helpers/select.helper';
import Select from 'react-select';
import { Properties, State } from './properties/top-scorer-team-select.properties';
import HttpService from '../../../../../services/rest/HttpService';
import { Col, Label, Row } from 'reactstrap';
import TeamModel from '../team-select/models/team.model';
import { remapTeamsFromResponce } from '../team-select/helpers/team-select.helper';

export default class TopScorerTeamSelect extends React.Component<Properties, State> {
	constructor(props: Properties) {
		super(props);
		this.state = {
			teamOptions: [],
		};
	}

	componentDidMount() {
		if (this.props.seasonId) {
			this.getTeams(this.props.seasonId);
		}
	}

	componentDidUpdate(prevProps: Properties) {
		if (this.props.seasonId !== prevProps.seasonId) {
			this.getTeams(this.props.seasonId);
		}
	}

	updateTeamOptionsState(team: TeamModel[]) {
		const { onTeamSelect, selectedTeam } = this.props;

		if (selectedTeam === undefined || selectedTeam.id === undefined || selectedTeam.id.length < 1) {
			onTeamSelect(team[0]);
		}
		this.setState({ ...this.state, teamOptions: team });
	}

	getTeams = (seasonId: string) => {
		if (seasonId !== undefined) {
			HttpService.getTopScorersTeamBySeasonId(seasonId)
				.then((response: any) => {
					this.updateTeamOptionsState(remapTeamsFromResponce(response));
				})
				.catch((e: any) => e);
		}
	};

	render() {
		const { teamOptions } = this.state;
		const { onTeamSelect, selectedTeam, t } = this.props;

		return (
			<Row>
				<Col>
					<Label>{t('team')}</Label>
					<Select
						placeholder={t('select')}
						options={contentToOptions(teamOptions)}
						value={contentToOption(selectedTeam)}
						onChange={(selection: any) => onTeamSelect(selection ? selection.data : null)}
					/>
				</Col>
			</Row>
		);
	}
}
