import React from 'react';
import { Col, Row } from 'reactstrap';
import { OddsWidgetView } from '../../odds/components/odds-widget-view.component';

type Properties = {
	block: any;
	t: any;
};

export const PlayerProfileView: React.FunctionComponent<Properties> = ({ block, t }) => {
	if (block && block.playerProfile && block.playerProfile.player && block.playerProfile.player.id) {
		return (
			<>
				<Row className={'m-2 ml-4 d-flex align-items-center'}>
					<img src={block.playerProfile.player ? block.playerProfile.player.logo : ''} height='50' className='mx-1 rounded-circle' alt='' />
					<h4>{block.playerProfile.player ? block.playerProfile.player.name : ''}</h4>
				</Row>
				<Row className='px-4'>
					<Col>
						<hr></hr>
					</Col>
				</Row>
				{block.playerProfile.displayOdds && (
					<OddsWidgetView
						isStandAlone={false}
						blockPreview={block.odds}
						t={t}
						displayThreeLetterCode={block.playerProfile.threeLetterCodeType && block.playerProfile.threeLetterCodeType !== '' ? true : false}
					/>
				)}
				<div>
					<Row className={`${!block.playerProfile.displayOdds ? 'p-4 text-center' : 'pb-4 pl-4 pr-4'}`}>
						<Col className='text-center'>
							{block.playerProfile.threeLetterCodeType && block.playerProfile.threeLetterCodeType !== '' && (
								<Row>
									<Col className='text-left'>
										{t('display_team_three_letter_code')}:
										<strong>
											<span className='ml-2'>
												{block.playerProfile.threeLetterCodeType &&
													block.playerProfile.threeLetterCodeType.name &&
													t(`${block.playerProfile.threeLetterCodeType.name}`)}
											</span>
										</strong>
									</Col>
								</Row>
							)}
							<Row>
								<Col className='text-left'>
									{t('display_odds')}:
									<strong>
										<span className={block.playerProfile.displayOdds ? 'text-success' : 'text-danger'}>
											{block.playerProfile.displayOdds ? ` ${t('yes')}` : ` ${t('no')}`}
										</span>
									</strong>
								</Col>
							</Row>
							<Row>
								<Col className='text-left'>
									{t('display_statistics')}:
									<strong>
										<span className={block.playerProfile.displayStatistics ? 'text-success' : 'text-danger'}>
											{block.playerProfile.displayStatistics ? ` ${t('yes')}` : ` ${t('no')}`}
										</span>
									</strong>
								</Col>
							</Row>
						</Col>
					</Row>
				</div>
			</>
		);
	}

	return null;
};
