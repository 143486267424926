import React from 'react';
import Select from 'react-select';
import { Col, Label, Row } from 'reactstrap';
import { Properties, State } from './properties/round-select.properties';
import HttpService from '../../../../../services/rest/HttpService';
import { roundsToOptions, roundToOption } from './helpers/round-select.helper';

export default class RoundSelectContainer extends React.Component<Properties, State> {
	constructor(props: Properties) {
		super(props);
		this.state = {
			roundsOptions: [],
		};
	}

	componentDidMount() {
		if (this.props.stageId) {
			this.getRounds(this.props.stageId);
		}
	}

	componentDidUpdate(prevProps: Readonly<Properties>, prevState: Readonly<State>) {
		if (prevProps.stageId !== this.props.stageId) {
			this.getRounds(this.props.stageId);
		}
	}

	updateRoundsOptionsState(rounds: any) {
		const { onRoundSelect, selectedRound } = this.props;
		let isSelectedRound = selectedRound && selectedRound.round !== undefined;

		if (rounds.length < 1) {
			this.setState({
				...this.state,
				roundsOptions: [],
			});
		} else {
			!isSelectedRound ? onRoundSelect(rounds[0].data) : onRoundSelect(selectedRound);
			this.setState({
				...this.state,
				roundsOptions: rounds,
			});
		}
	}

	getRounds(stageId: string) {
		if (stageId) {
			HttpService.getRoundsByStageId(stageId)
				.then((response: any) => {
					this.updateRoundsOptionsState(roundsToOptions(response.data));
				})
				.catch((e: any) => e);
		}
	}

	render() {
		const { roundsOptions } = this.state;
		let { onRoundSelect, selectedRound, stageId, isClearable, withLabel, label, isRequired, className } = this.props;
		isClearable = isClearable === undefined || isClearable === null ? false : isClearable;

		if (!stageId || stageId.length < 1) {
			return null;
		}

		return (
			<>
				<Row>
					<Col>
						{withLabel && <Label>{this.props.t('round')}:</Label>}
						{label && <Label>{this.props.t(label)}</Label>}
						{isRequired && <span className='text-danger'>*</span>}
						<Select
							menuPortalTarget={document.body}
							menuPosition='absolute'
							className={className ? className : ''}
							options={roundsOptions}
							value={[roundToOption(selectedRound)]}
							isClearable={isClearable}
							onChange={(selection: any) => {
								if (selection) {
									onRoundSelect(selection.data);
								} else {
									onRoundSelect(null);
								}
							}}
						/>
					</Col>
				</Row>
			</>
		);
	}
}
