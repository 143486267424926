import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import PlayerManualDataModel from '../../../../../../models/player-manual-data/player-manual-data.model';
import PlayerEditComponent from '../../players/player-edit.component';
import './edit-squad-players-modal.scss';

type Properties = {
	t: any;
	open: boolean;
	onClose: (open: boolean) => any;
	getSquads: () => any;
	player: PlayerManualDataModel;
};

const EditSquadPlayerModal: React.FunctionComponent<Properties> = (props) => {
	const { open, t, onClose, player, getSquads } = props;

	return (
		<Modal modalClassName='edit-squad-players-modal' isOpen={open}>
			<ModalHeader toggle={() => onClose(false)}>{t('player_edit_title')}</ModalHeader>
			<ModalBody>
				<PlayerEditComponent isAddedInModal t={t} playerId={player.id} onClose={onClose} getSquads={getSquads} />
			</ModalBody>
		</Modal>
	);
};

export default EditSquadPlayerModal;
