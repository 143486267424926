import React, { FunctionComponent } from 'react';
import './count-bubble.scss';
import classNames from 'classnames';

type Props = {
	count: number;
	title?: string;
};

export const CountBubble: FunctionComponent<Props> = ({ count, title }) => {
	const classNameResult = classNames({
		'bubble-displayed-count': true,
		neutral: count === 0,
	});

	return (
		<div className={classNameResult} title={title || 'Count'}>
			{count}
		</div>
	);
};

export default CountBubble;
