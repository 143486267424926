import { featuresService } from '../../../../../../App';
import { FeatureTypes } from '../../../../../../services/feature-service/features.enum';

export const liveBlogTypesOptions: string[] = ['SPORT_EVENT', 'STANDARD'];
export const liveBlogTypesOptionsWithoutFootball: string[] = ['STANDARD'];

export const selectionsToTypes = (selections: any[]) => {
	if (selections && selections.length > 0) {
		return selections.map((selection: any) => selection.value);
	}

	return [];
};

export const typesToOptions = (elements: any[], t: any) => {
	if (elements && elements.length > 0) {
		return elements.map((element: string) => typesToOption(element, t));
	}

	return [];
};

export const typesToOption = (element: any, t: any) => {
	if (element) {
		return {
			value: element,
			label: t(`${element}_title`),
		};
	}

	return {};
};

export const returnOptionsByFeature = () => {
	if (featuresService.areFeaturesEnabled([FeatureTypes.FOOTBALL])) {
		return liveBlogTypesOptions;
	} else {
		return liveBlogTypesOptionsWithoutFootball;
	}
};
