import { getDeleteIcon, getStarIcon } from '../../../../global-helpers/global-icons.helpers';

export const chevronStyleGenerator = (whenTrue: boolean) => {
	return whenTrue ? 'fas fa-chevron-up' : 'fas fa-chevron-down';
};

export const getEditIconAsCssUrl = (): string => {
	return `url("${getDeleteIcon}")`;
};

export const getStarIconAsCssUrl = (isActive: boolean = false): string => {
	return `url("${getStarIcon(isActive)}")`;
};
