import * as React from 'react';
import { ButtonGroup, Button } from 'reactstrap';
import './image-cells.scss';
import { assetsPlaceholder } from '../../../../../../../constants/assetsPlaceholder';
import { stripHtml } from '../helpers/imago-image-edit.helper';
import './masonry-style.scss';

export default class LazyLoadImageCell extends React.Component {
	onImageSelect = () => {
		this.props.onSelect(this.props.image);
	};

	render() {
		const { image, t, selectedImage } = this.props;
		const isSelectedImage = selectedImage && image.id === selectedImage.id;
		const addItemStyle = isSelectedImage ? 'selected-item' : '';

		return (
			<div className='image-container' title={image.id}>
				<div onClick={this.onImageSelect} className={`add-item position-relative text-center ${addItemStyle}`}>
					{isSelectedImage && <i className='fa-4x fa fa-check-circle selected-video text-warning animated fadeIn' />}
					<img
						className='img-fluid w-100 image rounded-top'
						onError={(i) => (i.target.src = assetsPlaceholder.blankPlaceholder)}
						alt={image.id}
						src={image.image}
					/>
				</div>
				<div className='image-footer'>
					{image.source && image.source.length > 0 && (
						<div className={'image-description px-2 py-1 small overflow-hidden text-wrap'}>{stripHtml(image.source)}</div>
					)}
					<ButtonGroup className='actions-wrapper position-absolute display-block w-100'>
						<Button
							block
							color='secondary'
							className='p-0 py-1 m-0'
							title={t('preview_image')}
							onClick={() => this.props.previewImage(true, image)}
						>
							<i className='fa fa-image' />
						</Button>
						<Button
							block
							color='warning'
							className='p-0 py-1 m-0'
							title={t('copy_thumbnail')}
							onClick={(e) => this.props.copyThumbnail(e, image.image)}
						>
							<i className='fa fa-copy' />
						</Button>
					</ButtonGroup>
				</div>
			</div>
		);
	}
}
