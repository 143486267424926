import Banner from '../../models/banner/Banner';
import {
	BANNER_ENTITY_DELETE_FAILED,
	BANNER_ENTITY_DELETE_SUCCESS,
	BANNER_ENTITY_RECEIVED,
	BANNER_LISTING_RECEIVED,
} from '../action-creators/BannerActionCreators';
import { USER_LOGOUT } from '../action-creators/ProfileActionCreators';
import PaginationMeta from '../../models/pagination/PaginationMeta';

interface InitialState {
	banner: Banner;
	banners: Banner[];
	pagination: PaginationMeta;
	bannerDeleted: boolean;
}

const initialState: InitialState = {
	banner: Banner.builder().build(),
	banners: [],
	bannerDeleted: false,
	pagination: PaginationMeta.builder().build(),
};

function bannerReducer(state: any = initialState, action: any) {
	if (action.type === BANNER_LISTING_RECEIVED) {
		state = Object.assign({}, state, {
			banners: action.payload,
			pagination: action.payload.contentPagination,
		});
	}

	if (action.type === BANNER_ENTITY_RECEIVED) {
		return Object.assign({}, state, {
			banner: action.payload.banner,
		});
	}

	if (action.type === BANNER_ENTITY_DELETE_SUCCESS) {
		return Object.assign({}, state, {
			bannerDeleted: true,
		});
	}

	if (action.type === BANNER_ENTITY_DELETE_FAILED) {
		return Object.assign({}, state, {
			bannerDeleted: false,
		});
	}

	if (action.type === USER_LOGOUT) {
		state = initialState;
	}

	return state;
}

export default bannerReducer;
