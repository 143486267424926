import 'reflect-metadata';
import { jsonMember, jsonObject } from 'typedjson';
import CustomBlockIconModel from './custom-block-icon.model';

@jsonObject
export default class CustomBlockModel {
	@jsonMember(String)
	public id: string = '';
	@jsonMember(String)
	public name: string = '';
	@jsonMember(String)
	public application_url: string = '';
	@jsonMember(CustomBlockIconModel)
	public icon: CustomBlockIconModel = new CustomBlockIconModel();
	@jsonMember(String)
	public status: string = '';
}
