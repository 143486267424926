import React from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import PaginationMeta from '../../../models/pagination/PaginationMeta';
import { returnObjectForArticlePaginationUpdate } from '../../../store/action-creators/ArticleActionCreator';
import { returnObjectForContentFiltersUpdate } from '../../../store/action-creators/content-resources';
import { returnObjectForGalleryPaginationUpdate } from '../../../store/action-creators/GalleryActionCreator';
import { returnObjectForVideoPaginationUpdate } from '../../../store/action-creators/VideoActionCreator';
import { remmapContentFilters } from '../Articles/Helpers/ArticleHelper';

type Properties = {
	i18n: any;
	t: any;
	tReady: boolean;
};

const PlayingSurfaceIframePage: React.FunctionComponent<Properties> = () => {
	const dispatch = useDispatch();

	React.useEffect(() => {
		dispatch(returnObjectForGalleryPaginationUpdate(PaginationMeta.builder().withCurrentPage(1).build()));
		dispatch(returnObjectForVideoPaginationUpdate(PaginationMeta.builder().withCurrentPage(1).build()));
		dispatch(returnObjectForArticlePaginationUpdate(PaginationMeta.builder().withCurrentPage(1).build()));

		const filters = remmapContentFilters({}, {}, {}, {}, {});
		dispatch(returnObjectForContentFiltersUpdate(filters));
	}, []);

	return (
		<div className='card animated fadeIn'>
			<div className='card-header'>
				<div className={'d-flex align-items-center'}>
					<span className={'mr-auto mb-0'}>Playing Surface</span>
				</div>
			</div>
			<div className='card-body'>
				<div className='iframe-container'>
					<div className='iframe-inner-container'>
						<iframe
							src='https://live.playingsurface.net'
							title='Playing Surface'
							className='iframe'
							width='100%'
							frameBorder='0'
							allowFullScreen
							scrolling='yes'
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default withTranslation()(PlayingSurfaceIframePage);
