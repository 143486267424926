import React from 'react';
import { Properties, State } from './properties/image-upload-button-container.properties';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import HttpService from '../../../../services/rest/HttpService';
import { toast } from 'react-toastify';
import Dropzone from 'dropzone';
import * as helpers from './helpers/image-upload-button.helper';
import './image-upload-button-container.scss';
import {
	returnObjectForImageUploadFailed,
	returnObjectForImageUploadRequest,
	returnObjectForImageUploadSuccess,
} from '../../../../store/action-creators/ImageActionCreator';
import WatermarkService from '../../../../services/watermark-service/WatermarkService';
import Watermark from '../../../../models/watermarks/Watermark';
import { WATERMARK_ID, WATERMARK_POSITION, WATERMARK_PROJECT } from '../../../../services/watermark-service/watermark-constants';
import { checkForWatermarkFeature } from '../../../../services/watermark-service/watermark-helper';
import { checkIfWatermarkIsValid } from './helpers/imago-image-upload.helper';
import REACT_APP_URLS from '../../../../global-helpers/global-url.helpers';

class ImageUploadButtonContainer extends React.Component<Properties, State> {
	private dropzone: Dropzone = {} as Dropzone;
	private watermarkService: WatermarkService = {} as WatermarkService;
	watermarkEnabled = checkForWatermarkFeature();
	constructor(props: Properties) {
		super(props);
		Dropzone.autoDiscover = false;
		this.state = {
			isFileUploading: false,
			displayError: false,
			errorText: '',
			defaultWatermark: Watermark.builder().build(),
		};
	}

	checkWatermarkEnabled() {
		if (this.props.applyQuickWatermark) {
			this.initWaterMarkService() &&
				this.watermarkService.getDefaultWatermark().then((defaultWatermark) => {
					this.setState({ defaultWatermark: defaultWatermark });
				});
		}
	}

	componentDidMount(): void {
		if (this.props.display) {
			this.initDropzone();
		}
		this.checkWatermarkEnabled();
	}

	componentDidUpdate(prevProps: Readonly<Properties>, prevState: Readonly<State>, snapshot?: any) {
		if (prevProps.applyQuickWatermark !== this.props.applyQuickWatermark) {
			this.checkWatermarkEnabled();
		}
	}

	initWaterMarkService() {
		if (checkForWatermarkFeature()) {
			this.watermarkService = new WatermarkService();
			return true;
		} else return false;
	}

	//TODO: REFACTOR
	initDropzone() {
		const { project, imageUploadButtonId, assetsProject } = this.props;
		const imageApiBaseUrl = this.props.imageApiURL ? this.props.imageApiURL : REACT_APP_URLS.REACT_APP_IMAGE_API_BASE_URL;
		const projectHeader = assetsProject ? 'assets' : project.domain;

		this.dropzone = new Dropzone(`#${imageUploadButtonId}`, {
			url: imageApiBaseUrl + '/upload',
			method: 'post',
			maxFilesize: 15,
			maxThumbnailFilesize: 15,
			createImageThumbnails: true,
			timeout: 300000,
			parallelUploads: 10,
			headers: { Project: projectHeader, 'X-Project': projectHeader },
			acceptedFiles: 'image/*',
			accept: (file: any, done: Function) => {
				file.accept = done;
				file.reject = () => {
					done(helpers.FILE_DIMENS_TOO_BIG);
					this.props.imageUploadFailed();
				};
				file.rejectTimeout = () => {
					done(helpers.FILE_UPLOAD_TIMEOUT);
					this.props.imageUploadFailed();
				};
			},
			dictFileTooBig: 'File is too big',
		});

		this.initDropzoneListeners();
	}

	//TODO: REFACTOR
	initDropzoneListeners() {
		let imagePaths: string[] = [];
		const dropZoneOnAddFile = 'addedfile';
		const dropZoneOnError = 'error';
		const dropZoneOnSuccess = 'success';
		const dropZoneOnQueueComplete = 'queuecomplete';
		const dropZoneOnSending = 'sending';
		const dropZoneOnThumbnail = 'thumbnail';

		this.dropzone.on(dropZoneOnAddFile, () => {
			this.toggleUploadingState(true);
			this.toggleErrorState('', false);
			this.props.imageUploadRequest();
		});

		this.dropzone.on(dropZoneOnError, (error: any, imageProcessError: any) => {
			this.toggleUploadingState(false);
			this.toggleImageUploadErrorToast(imageProcessError);
			this.props.imageUploadFailed();
		});

		this.dropzone.on(dropZoneOnSuccess, (response: any) => {
			const imagePath = response && response.xhr && response.xhr.response ? JSON.parse(response.xhr.response).path : '';
			imagePaths.push(imagePath);
		});

		this.dropzone.on(dropZoneOnQueueComplete, (response: any) => {
			this.props.assetsProject ? this.props.onImageUploadSuccess(imagePaths[0], true) : this.uploadToContentApi(imagePaths);
			imagePaths = [];
			this.toggleUploadingState(false);
		});

		this.dropzone.on(dropZoneOnSending, (file: any, xhr, formData) => {
			this.props.applyQuickWatermark && this.state.defaultWatermark.id && formData.append(WATERMARK_ID, this.state.defaultWatermark.id);
			this.props.applyQuickWatermark &&
				this.state.defaultWatermark.project &&
				formData.append(WATERMARK_PROJECT, this.state.defaultWatermark.project);
			this.props.applyQuickWatermark &&
				this.state.defaultWatermark.position &&
				formData.append(WATERMARK_POSITION, this.state.defaultWatermark.position);
		});

		this.dropzone.on(dropZoneOnThumbnail, (file: any) => {
			if (file.width * file.height > helpers.MAX_DIMENS_SIZE) {
				file.reject();
			} else {
				file.accept();
			}
		});
	}

	uploadToContentApi(imagePaths: string[]) {
		if (imagePaths.length > 0) {
			const headers = { Project: this.props.project.domain };
			HttpService.all(
				helpers.constructRequestsFromFilePaths(
					imagePaths,
					headers,
					this.props.applyQuickWatermark && checkIfWatermarkIsValid(this.state.defaultWatermark),
				),
			)
				.then((response: any) => {
					this.toggleUploadingState(false);
					this.props.onImageUploadSuccess(response[response.length - 1].data.data.id, false, response[response.length - 1].data.data);
					this.onImageListReceived(response);
					this.props.imageUploadSuccess();
					toast.success(this.props.t('image_upload_success'));
				})
				.catch((error: any) => {
					this.toggleUploadingState(false);
					this.props.toggleUploading(false);
					this.props.imageUploadFailed();
					helpers.handleErrors(helpers.FILE_UPLOAD_FAILED_CONTENT_API, this.props.t);
				});
		}
	}

	onImageListReceived(responseList: any[]) {
		if (this.props.onMultiImageUploadSuccess) {
			this.props.onMultiImageUploadSuccess(helpers.imageListMultipleResponseToImageList(responseList));
		}
	}

	toggleUploadingState(isUploading: boolean) {
		this.props.toggleUploading && this.props.toggleUploading(isUploading);
		this.setState({
			isFileUploading: isUploading,
		});
	}

	toggleErrorState(error: string, display: boolean) {
		this.setState({
			...this.state,
			errorText: error,
			displayError: display,
		});
	}

	toggleImageUploadErrorToast(error: any) {
		if (typeof error !== 'string' && error.message) {
			helpers.handleErrors(error.message, this.props.t);
		} else {
			helpers.handleErrors(error, this.props.t);
		}
	}

	render() {
		const { t, blockStyle, imageUploadButtonId, display, size, label } = this.props;
		const { isFileUploading, displayError, errorText } = this.state;

		if (!display) {
			return null;
		}

		return (
			<div className={'scoped-dropzone-style'}>
				{label && label === 'browse' ? (
					<Button id={imageUploadButtonId} color='info' size={size ? size : ''} className={'text-white browse-btn'}>
						{t('browse')}
					</Button>
				) : (
					<Button id={imageUploadButtonId} color='info' size={size ? size : ''} className={blockStyle ? 'btn-block text-white' : `text-white`}>
						<i className={helpers.appendUploadingClass(isFileUploading)} /> {t('upload_image')}
					</Button>
				)}
				{displayError && (
					<div className={'alert alert-danger'} role='alert'>
						{errorText}
					</div>
				)}
			</div>
		);
	}
}

function mapDispatchToProps(dispatch: any) {
	return {
		imageUploadRequest: () => dispatch(returnObjectForImageUploadRequest()),
		imageUploadSuccess: () => dispatch(returnObjectForImageUploadSuccess()),
		imageUploadFailed: () => dispatch(returnObjectForImageUploadFailed()),
	};
}

function mapStateToProps(state: any) {
	return {
		imageApiURL: state.project.currentProject.imageApiUrl,
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(ImageUploadButtonContainer);
