import * as React from 'react';
import Select from 'react-select';
import * as helpers from '../../../Partials/Shared/react-select-helpers/react-select.helpers';
import HttpService from '../../../../services/rest/HttpService';
import { Label, FormGroup, Col } from 'reactstrap';
import { remapEntitiesFromResponse } from '../helpers/translations.helpers';
import EntityModel from '../models/entity.model';
import EnumItem from '../../../../models/enum/EnumItem';

type Properties = {
	t: any;
	onChange: (selection: EntityModel) => void;
	value: EntityModel;
	searchLanguage: EnumItem;
};

const LineupPlayerType: React.FunctionComponent<Properties> = ({ t, onChange, value, searchLanguage }) => {
	const [options, setOptions] = React.useState([]);

	const onLineupPlayerType = (selection: any) => onChange(helpers.optionToItem(selection));

	React.useEffect(() => {
		HttpService.instanceFootball(searchLanguage.id)
			.get('/event_player_types')
			.then((response: any) => {
				const options: any = helpers.itemsToOptions(remapEntitiesFromResponse(response.data, 'lineup-player-type'), false, t, '');
				setOptions(options);
			})
			.catch((error: unknown) => console.error(error));
	}, [t, searchLanguage]);

	return (
		<Col xs='6'>
			<FormGroup>
				<Label htmlFor='lineup-player-type-select'>{t('lineup_player_type')}</Label>
				<Select
					inputId='lineup-player-type-select'
					value={helpers.itemToOption(value, false, t, '')}
					options={options}
					onChange={onLineupPlayerType}
					placeholder={t('select_lineup_player_type')}
					noOptionsMessage={(inputValue) => inputValue && t('no_options')}
				/>
			</FormGroup>
		</Col>
	);
};

export default LineupPlayerType;
