import React, { useEffect, useState } from 'react';
import HttpService from '../../../../../../services/rest/HttpService';
import * as helpers from './helpers/bookmaker-select.helper';
import { Label } from 'reactstrap';
import Select from 'react-select';
import { customOption } from '../../../partials/shared/custom-select-option';
import { SelectMenuOptionType } from '../../../../../../models/v2/general/select.model';
import BookmakerModel from './models/bookmaker.model';

type Properties = {
	eventId: string | number | null;
	isMulti: boolean;
	showAllBookmakers: boolean;
	bookmakerSelectionEnabled: boolean;
	value?: BookmakerModel[];
	valueList?: any;
	onSelect: any;
	t: any;
	isValid: boolean;
	matchContentData?: boolean;
	id: string;
};

export const BookmakerSelectComponent: React.FunctionComponent<Properties> = (props) => {
	const [bookmakerList, setBookmakerList] = useState<BookmakerModel[]>([]);
	const [initialEventId, setInitialEventId] = useState(props.eventId ? props.eventId : null);

	useEffect(() => {
		if (props.eventId) {
			getOddsByMatchId(props.eventId);
		}

		return () => setBookmakerList([]);
	}, []);

	useEffect(() => {
		if (props.eventId && props.eventId !== initialEventId) {
			resetSelectedBookmakerState();
			getOddsByMatchId(props.eventId);
		}
	}, [props.eventId]);

	const resetSelectedBookmakerState = () => {
		props.onSelect([]);
	};

	const getOddsByMatchId = (eventId: any) => {
		if (eventId) {
			HttpService.getEventOdds(eventId)
				.then((response: any) => {
					if (response && response.data) {
						const bookmakerList = response.data.map((bookmaker: Record<string, object>) =>
							helpers.responseToBookmakerModel(bookmaker.odd_provider),
						);

						updateBookmakerListState(bookmakerList);
					}
				})
				.catch((e: Error) => e.message);
		}
	};

	const updateBookmakerListState = (bookmakerList: BookmakerModel[]) => {
		setBookmakerList(bookmakerList);
	};

	const onBookmakersChange = (selections: SelectMenuOptionType[]) => {
		if (selections && selections.length > 0) {
			props.onSelect(selections.map((selection: SelectMenuOptionType) => selection.data));
		} else {
			props.onSelect([]);
		}
	};

	const noOptions = (inputValue: any) => inputValue && props.t('no_options');

	if (!props.eventId && !props.bookmakerSelectionEnabled) {
		return null;
	}

	if (props.isMulti) {
		return (
			<>
				<div data-qa={props.id}>
					<Label htmlFor={props.id}>{props.t('select_bookmaker')}:</Label>
					<Select
						options={helpers.bookmakerListToOptionList(bookmakerList)}
						isMulti
						className='mb-2'
						placeholder={props.t('select')}
						noOptionsMessage={noOptions}
						value={helpers.bookmakerListToOptionList(props.valueList)}
						formatOptionLabel={customOption}
						onChange={onBookmakersChange}
						id={props.id}
						isClearable
					/>
				</div>
			</>
		);
	}
};
