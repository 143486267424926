import StageModel from '../../../../../../../models/v2/Stage/Entity/StageModel';
import { FootballPlayerWidgetJson } from './football-player-widget.json';
import PlayerModel from '../../../../../../../models/v2/player/entity/player.model';
import EntitySeasonsSelectModel from '../../../../partials/player-seasons/model/entity-seasons-select.model';
import MatchModel from '../../../../../../../models/v2/match/entity/match.model';
import FootballPlayerWidgetBuilder from './football-player-widget.builder';
import { RefreshTimeValuesType } from '../../refresh-time-input/helper';

export default class FootballPlayerWidgetModel {
	readonly tournamentSeason: EntitySeasonsSelectModel;
	readonly stage: StageModel;
	readonly match: MatchModel;
	readonly player: PlayerModel;
	readonly playerTeamName: string;
	readonly displayTabs: boolean;
	readonly tabs: string[];
	readonly defaultTab: any;
	readonly displayOdds: boolean = false;
	readonly fansUnitedEnabled: boolean = false;
	readonly fansUnitedExpanded: boolean = false;
	readonly infoParameters: string[];
	readonly statsParameters: string[];
	readonly refreshTime: RefreshTimeValuesType;

	private constructor(
		tournamentSeason: EntitySeasonsSelectModel,
		stage: StageModel,
		match: MatchModel,
		player: PlayerModel,
		playerTeamName: string,
		displayTabs: boolean,
		tabs: string[],
		defaultTab: any,
		displayOdds: boolean,
		fansUnitedEnabled: boolean,
		fansUnitedExpanded: boolean,
		infoParameters: string[],
		statsParameters: string[],
		refreshTime: RefreshTimeValuesType,
	) {
		this.tournamentSeason = tournamentSeason;
		this.stage = stage;
		this.match = match;
		this.player = player;
		this.playerTeamName = playerTeamName;
		this.displayTabs = displayTabs;
		this.tabs = tabs;
		this.defaultTab = defaultTab;
		this.displayOdds = displayOdds;
		this.fansUnitedEnabled = fansUnitedEnabled;
		this.fansUnitedExpanded = fansUnitedExpanded;
		this.infoParameters = infoParameters;
		this.statsParameters = statsParameters;
		this.refreshTime = refreshTime;
	}

	toJson(): FootballPlayerWidgetJson {
		return {
			tournamentSeason: this.tournamentSeason,
			stage: this.stage,
			match: this.match,
			player: this.player,
			playerTeamName: this.playerTeamName,
			displayTabs: this.displayTabs,
			tabs: this.tabs,
			defaultTab: this.defaultTab,
			displayOdds: this.displayOdds,
			fansUnitedEnabled: this.fansUnitedEnabled,
			fansUnitedExpanded: this.fansUnitedExpanded,
			infoParameters: this.infoParameters,
			statsParameters: this.statsParameters,
			refreshTime: this.refreshTime,
		};
	}

	static fromJson(json: FootballPlayerWidgetJson): FootballPlayerWidgetModel {
		return new FootballPlayerWidgetModel(
			json.tournamentSeason,
			json.stage,
			json.match,
			json.player,
			json.playerTeamName,
			json.displayTabs,
			json.tabs,
			json.defaultTab,
			json.displayOdds,
			json.fansUnitedEnabled,
			json.fansUnitedExpanded,
			json.infoParameters,
			json.statsParameters,
			json.refreshTime,
		);
	}

	static builder(model?: FootballPlayerWidgetModel): FootballPlayerWidgetBuilder {
		return new FootballPlayerWidgetBuilder(model);
	}
}
