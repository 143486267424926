import React from 'react';
import { Label, Row, Col } from 'reactstrap';
import Select from 'react-select';
import HttpService from '../../../../../services/rest/HttpService';
import {
	remapAudioList,
	languageToOptions,
	applyFilters,
	competitionToOptions,
	sportToOptions,
	remapToOption,
} from './helpers/spotlight-audio-select.helper';
import { featuresService } from '../../../../../App';
import { FeatureTypes } from '../../../../../services/feature-service/features.enum';
import SpotlightAudioModel from '../../../../../models/spotlight-audio/spotlight-audio.model';
import BlockModel from '../../models/block.model';
import FilterModel from './models/filter.model';

export type Properties = {
	setAudios: (audio: SpotlightAudioModel[] | null) => void;
	t: any;
	toggleLoadingState: (loading: boolean) => any;
	blocks: BlockModel[];
	onSelectedAudioChange: (audio: SpotlightAudioModel) => any;
	shouldRequest: boolean;
	filters: FilterModel;
};

export type State = {
	company: string;
	languageOptions: any[];
	competitionOptions: any[];
	sportOptions: any[];
	data: {};
	filters: FilterModel;
};

export default class SpotlightAudioSelect extends React.Component<Properties, State> {
	private audioConfig = featuresService.getFeatureConfig(FeatureTypes.SPOTLIGHT_AUDIO_INTEGRATION);

	constructor(props: Properties) {
		super(props);
		this.state = {
			company: this.audioConfig.company,
			sportOptions: [],
			languageOptions: [],
			competitionOptions: [],
			data: {},
			filters: props.filters
				? FilterModel.builder()
						.withFilterSport(props.filters.filterSport)
						.withFilterLanguage(props.filters.filterLanguage)
						.withFilterCompetition(props.filters.filterCompetition)
						.build()
				: FilterModel.builder().withFilterSport([]).withFilterLanguage([]).withFilterCompetition([]).build(),
		};
	}

	componentDidMount() {
		this.getAllAudios();
	}

	componentDidUpdate(prevProps: Readonly<Properties>, prevState: Readonly<State>) {
		const { filters } = this.state;

		if (filters && prevState.filters.filterSport !== filters.filterSport) {
			this.setState({
				...this.state,
				filters: FilterModel.builder().withFilterSport(filters.filterSport).withFilterLanguage([]).withFilterCompetition([]).build(),
			});
			this.getAllAudios();
		}

		if (filters && prevState.filters.filterLanguage !== filters.filterLanguage) {
			this.setState({
				...this.state,
				filters: FilterModel.builder()
					.withFilterSport(filters.filterSport)
					.withFilterLanguage(filters.filterLanguage)
					.withFilterCompetition([])
					.build(),
			});
			this.getAudiosWithLanguage(filters.filterSport.label, filters.filterLanguage.label);
		}

		if (filters && prevState.filters.filterCompetition !== filters.filterCompetition) {
			this.setState({
				...this.state,
				filters: FilterModel.builder()
					.withFilterSport(filters.filterSport)
					.withFilterLanguage(filters.filterLanguage)
					.withFilterCompetition(filters.filterCompetition)
					.build(),
			});

			this.setAudios(this.state.data);
		}
	}

	getAllAudios() {
		const { company } = this.state;
		this.props.toggleLoadingState(true);
		if (company) {
			HttpService.getSpotlightAudios(`https://sport.mediaondemand.net/feed/json/${company}/all/all/hls`)
				.then((response: any) => {
					this.updateData(response.data);
					this.extractSports(response.data);
				})
				.catch(() => this.props.toggleLoadingState(false));
		}
	}

	getAudiosWithSport(sport: string) {
		const { company } = this.state;
		this.props.toggleLoadingState(true);
		if (company && sport) {
			HttpService.getSpotlightAudios(`https://sport.mediaondemand.net/feed/json/${company}/${sport}/all/hls`)
				.then((response: any) => {
					this.updateData(response.data);
					this.extractLanguages(response.data);
				})
				.catch(() => this.props.toggleLoadingState(false));
		}
	}

	getAudiosWithLanguage(sport: string, language: string) {
		const { company } = this.state;
		this.props.toggleLoadingState(true);
		if (company && sport && language) {
			HttpService.getSpotlightAudios(`https://sport.mediaondemand.net/feed/json/${company}/${sport}/${language}/hls`)
				.then((response: any) => {
					this.updateData(response.data);
					this.extractCompetitions(response.data);
				})
				.catch(() => this.props.toggleLoadingState(false));
		}
	}

	extractSports = (data: any) => {
		this.setState({
			...this.state,
			sportOptions: sportToOptions(data),
		});

		this.state.filters && this.state.filters.filterSport && this.state.filters.filterSport.length === 0
			? this.onSportFilterChange(sportToOptions(data)[0])
			: this.getAudiosWithSport(this.state.filters.filterSport.label);
	};

	extractLanguages = (data: any) => {
		this.setState({
			...this.state,
			languageOptions: languageToOptions(data),
		});

		this.state.filters && this.state.filters.filterLanguage && this.state.filters.filterLanguage.length === 0
			? this.onLanguageFilterChange(languageToOptions(data)[0])
			: this.getAudiosWithLanguage(this.state.filters.filterSport.label, this.state.filters.filterLanguage.label);
	};

	extractCompetitions = (data: any) => {
		this.setState({
			...this.state,
			competitionOptions: competitionToOptions(data),
		});

		this.state.filters && this.state.filters.filterCompetition && this.state.filters.filterCompetition.length === 0
			? this.onCompetitionFilterChange(competitionToOptions(data)[0])
			: this.onCompetitionFilterChange(this.state.filters.filterCompetition);

		if (data) {
			this.setAudios(this.state.data);
		}
	};

	onSportFilterChange = (sport: any) => {
		const modelFilterBuilder = FilterModel.builder(this.state.filters);
		modelFilterBuilder.withFilterSport(sport);
		this.setState({ ...this.state, filters: modelFilterBuilder.build() });
	};

	onLanguageFilterChange = (language: any) => {
		const modelFilterBuilder = FilterModel.builder(this.state.filters);
		modelFilterBuilder.withFilterLanguage(language);
		this.setState({ ...this.state, filters: modelFilterBuilder.build() });
	};

	onCompetitionFilterChange = (competition: any) => {
		const modelFilterBuilder = FilterModel.builder(this.state.filters);
		modelFilterBuilder.withFilterCompetition(competition);
		this.setState({ ...this.state, filters: modelFilterBuilder.build() });
	};

	setAudios(data: any) {
		const { filters } = this.state;

		if (filters.filterSport && filters.filterLanguage && filters.filterCompetition) {
			this.props.toggleLoadingState(true);
			this.props.setAudios(
				applyFilters(
					remapAudioList(data.SportDetails, filters.filterSport),
					filters.filterSport,
					filters.filterLanguage,
					filters.filterCompetition,
				),
			);
			this.props.toggleLoadingState(false);
		}
	}

	updateData(data: any) {
		this.setState({ ...this.state, data: data });
		return data;
	}

	render() {
		const { languageOptions, competitionOptions, sportOptions, filters } = this.state;

		return (
			<>
				<Label>{this.props.t('filters')}:</Label>
				<Row>
					<Col>
						<Label className='mt-2'>{this.props.t('sport')}:</Label>
						<Select value={filters.filterSport} options={sportOptions} onChange={this.onSportFilterChange} />
					</Col>
				</Row>
				<Row>
					<Col>
						<Label className='mt-2'>{this.props.t('language')}:</Label>
						<Select value={filters.filterLanguage} options={languageOptions} onChange={this.onLanguageFilterChange} />
					</Col>
				</Row>
				<Row>
					<Col>
						<Label className='mt-2'>{this.props.t('competition')}:</Label>
						<Select value={filters.filterCompetition} options={competitionOptions} onChange={this.onCompetitionFilterChange} />
					</Col>
				</Row>
			</>
		);
	}
}
