import React from 'react';
import * as helpers from '../helpers/embed-social-view.helper';
// @ts-ignore
import { Twitter } from 'reactjs-social-embed';
import { Col, Row, Button } from 'reactstrap';
import InstagramEmbed from 'react-instagram-embed';
import '../styles/embed-block.scss';
import { noPreviewImage } from '..//../../../../../../constants/resource-constants';

type Properties = {
	embed: string;
	t: any;
};

const EmbedSocialView: React.FunctionComponent<Properties> = ({ embed, t }) => {
	const socialEmbedType = helpers.extractSocialType(embed);
	const previewRef = React.useRef(null);
	const [preview, updatePreview] = React.useState([]);
	const [collapse, setCollapse] = React.useState(false);
	const [previewHeight, updatePreviewHeight] = React.useState(preview && preview.length > 0 ? 540 : 0);
	const clientAccessToken = '632829914125642|600c122f0d4782e1ae9fe96052504596';

	React.useEffect(() => {
		getPreview();
		if (previewRef !== null && previewRef.current !== null) {
			// @ts-ignore
			updatePreviewHeight(previewRef.current.clientHeight);
		}
	}, [previewRef]);

	const toggle = () => setCollapse(!collapse);

	const getPreview = () => {
		socialEmbedType.map((embedCode: any) => {
			if (embedCode) {
				switch (embedCode.type) {
					case 'twitter':
						const twitter: any = (
							<div ref={previewRef}>
								<Twitter key={Math.random()} id={embedCode.attributes} />
							</div>
						);
						updatePreview(twitter);
						updatePreviewHeight(twitter);
						break;
					case 'twitterFromScript':
						const twitterFromScript: any = (
							<div ref={previewRef}>
								<Twitter key={Math.random()} id={embedCode.attributes} />
							</div>
						);
						updatePreview(twitterFromScript);
						updatePreviewHeight(twitterFromScript);
						break;
					case 'instagram':
						const instagram: any = (
							<div ref={previewRef}>
								<InstagramEmbed
									key={Math.random()}
									url={embedCode.attributes}
									clientAccessToken={clientAccessToken}
									maxWidth={500}
									hideCaption={false}
									containerTagName='div'
									protocol=''
									injectScript
								/>
							</div>
						);

						updatePreview(instagram);
						updatePreviewHeight(instagram);
						break;

					case 'facebook':
						const facebook: any = (
							<>
								<iframe
									width='100%'
									height='540px'
									frameBorder='0'
									allow='encrypted-media'
									src={`${'https://www.facebook.com/plugins/post.php?href='}${embedCode.attributes}`}
								></iframe>
							</>
						);
						updatePreview(facebook);
						updatePreviewHeight(facebook);
						break;
					case 'facebookIframe':
						const facebookIframe: any = <div dangerouslySetInnerHTML={{ __html: embedCode.attributes }} />;
						updatePreview(facebookIframe);
						updatePreviewHeight(facebookIframe);
						break;
					default:
						break;
				}
			}
		});
	};

	return (
		<Col>
			{previewHeight !== 0 ? (
				<>
					{!collapse ? (
						<Row>
							<Col className='px-4 embed-code-preview-short'>{preview}</Col>
						</Row>
					) : (
						<Row>
							<Col className='px-4 embed-code-preview'>{preview}</Col>
						</Row>
					)}
					<Row className='px-4'>
						{!collapse ? (
							<Button color='info' className='mt-4' onClick={toggle}>
								<i className='fa fa-angle-double-down mr-1'></i>
								{t('social_preview_show')}
							</Button>
						) : (
							<Button color='info' onClick={toggle}>
								<i className='fa fa-angle-double-up mr-1'></i>
								{t('social_preview_hide')}
							</Button>
						)}
					</Row>
				</>
			) : (
				<>
					<div className='block-content d-flex content-container'>
						<img className='content-image img-fluid mb-2 mb-md-0 rounded w-100' src={noPreviewImage} />
					</div>
				</>
			)}
		</Col>
	);
};

export default EmbedSocialView;
