import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
import React, { useState } from 'react';
import { connect } from 'react-redux';
import MainMediaModel from '../../../../../../models/v2/main-media/main-media.model';
import { autoGenerateReduxPropsNamingForContentModels } from '../../../../../../services/content-models-service/ContentModelService';
import { extractMainImageObjectFromArray } from '../../helpers/general';
import { assetsPlaceholder } from '../../../../../../constants/assetsPlaceholder';

type Properties = {
	mainMediaImage: MainMediaModel | null;
};

const ImageDisplayContainer: React.FunctionComponent<Properties> = ({ mainMediaImage }) => {
	const [enlargeImage, setEnlargeImage] = useState(false);
	const mainImageUploadedArray = mainMediaImage && mainMediaImage.data && mainMediaImage.data.urls && mainMediaImage.data.urls.uploaded;

	const extractImageSrc = (): string => {
		let thumbnail = assetsPlaceholder.imagePlaceholder;
		if (mainImageUploadedArray && mainImageUploadedArray.thumbnail) {
			thumbnail = mainImageUploadedArray.thumbnail;
		}

		return thumbnail;
	};

	if (mainMediaImage === null) {
		return <img src={extractImageSrc()} className='w-100' alt='No image uploaded' />;
	}

	return (
		<>
			<div className='expand-item' onClick={() => setEnlargeImage(true)}>
				<img src={extractImageSrc()} className='w-100' alt={mainMediaImage.description || ''} />
			</div>
			{enlargeImage && (
				<Lightbox mainSrc={mainImageUploadedArray && mainImageUploadedArray.gallery} onCloseRequest={() => setEnlargeImage(false)} />
			)}
		</>
	);
};

function mapStateToProps(state: any) {
	const { tempPropertyNaming, nestedTempPropertyNaming } = autoGenerateReduxPropsNamingForContentModels();
	const mainMediaRedux = state[tempPropertyNaming][nestedTempPropertyNaming].mainMedia || [];

	return {
		mainMediaImage: extractMainImageObjectFromArray(mainMediaRedux),
	};
}

export default connect(mapStateToProps)(ImageDisplayContainer);
