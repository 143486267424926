import LivescoreWidgetModel from './livescore-widget.model';
import { LivescoreWidgetJson } from './livescore-widget.json';
import SportOddsBookmakerModel from '../../../../../../../models/sport-odds/sport-odds-bookmaker.model';
import { RefreshTimeValuesType } from '../../../../v2/widgets-v2/refresh-time-input/helper';

export default class LivescoreWidgetBuilder {
	readonly json: LivescoreWidgetJson;

	constructor(livescore?: LivescoreWidgetModel | LivescoreWidgetJson) {
		if (livescore && livescore instanceof LivescoreWidgetModel) {
			this.json = livescore.toJson();
		} else if (livescore) {
			this.json = livescore;
		} else {
			this.json = {} as LivescoreWidgetJson;
		}
	}

	withDisplayOdds(displayOdds: boolean): LivescoreWidgetBuilder {
		this.json.displayOdds = displayOdds;

		return this;
	}

	withDate(date: string): LivescoreWidgetBuilder {
		this.json.date = date;

		return this;
	}

	withBookmakers(bookmakers: SportOddsBookmakerModel[] | null): LivescoreWidgetBuilder {
		this.json.bookmakers = bookmakers;

		return this;
	}

	withRefreshTime(refreshTime: RefreshTimeValuesType): LivescoreWidgetBuilder {
		this.json.refreshTime = refreshTime;

		return this;
	}

	build(): LivescoreWidgetModel {
		return LivescoreWidgetModel.fromJson(this.json);
	}
}
