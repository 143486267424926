import GroupModel from '../models/group.model';

export function stageGroupToOption(group: GroupModel) {
	if (group && Object.entries(group).length > 0) {
		return { value: group.id, label: group.name, data: group };
	}

	return {};
}

export function stageGroupsToOptions(groups: GroupModel[] | null) {
	if (groups && groups.length > 0) {
		return groups.map((group: GroupModel) => {
			return stageGroupToOption(group);
		});
	}

	return [];
}

export function remapGroupsFromResponse(groups: any): any[] {
	if (groups && groups.length > 0) {
		return groups.map((group: any) => GroupModel.builder().withId(group.id).withName(group.name).withStanding(group.standing).build());
	}

	return [];
}
