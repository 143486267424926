import React, { useState } from 'react';
import { ViewTypes } from '../../../constants/general.constants';
import YoutubeSportsEdit from './youtube-sports-block-edit.component';
import YoutubeSportsView from './youtube-sports-block-view.component';
import BlockModel from '../../../models/block.model';
import YoutubeVideoModel from '../../../../../../models/youtube-sports/youtube-video/youtube-video.model';

export type Properties = {
	t: any;
	view: ViewTypes;
	block: BlockModel;
	contentData: any;
};

export const YoutubeSportsBlock: React.FunctionComponent<Properties> = (props) => {
	const { t, view, block, contentData } = props;

	if (view === ViewTypes.edit) {
		return (
			<YoutubeSportsEdit t={t} block={block} linkedTags={(contentData && contentData.related && contentData.related.tags) || undefined} />
		);
	} else if (view === ViewTypes.normal) {
		return <YoutubeSportsView t={t} youtubeVideo={block.data.preview ? block.data.preview.video : YoutubeVideoModel.builder().build()} />;
	}
	return null;
};
