import { MatchScoreJson } from './match-score.json';
import MatchscoreBuilder from './match-score.builder';

export default class MatchScoreModel {
	readonly total: { home: number; away: number } | null;
	readonly halfTime: { home: number; away: number } | null;
	readonly regularTime: { home: number; away: number } | null;
	readonly extraTime: { home: number; away: number } | null;
	readonly penaltyShootout: { home: number; away: number } | null;
	readonly aggregate: { home: number; away: number } | null;

	private constructor(
		total: { home: number; away: number } | null,
		halfTime: { home: number; away: number } | null,
		regularTime: { home: number; away: number } | null,
		extraTime: { home: number; away: number } | null,
		penaltyShootout: { home: number; away: number } | null,
		aggregate: { home: number; away: number } | null,
	) {
		this.total = total;
		this.halfTime = halfTime;
		this.regularTime = regularTime;
		this.extraTime = extraTime;
		this.penaltyShootout = penaltyShootout;
		this.aggregate = aggregate;
	}

	toJSON(): MatchScoreJson {
		return {
			total: this.total,
			halfTime: this.halfTime,
			regularTime: this.regularTime,
			extraTime: this.extraTime,
			penaltyShootout: this.penaltyShootout,
			aggregate: this.aggregate,
		};
	}

	static fromJSON(json: MatchScoreJson): MatchScoreModel {
		return new MatchScoreModel(json.total, json.halfTime, json.regularTime, json.extraTime, json.penaltyShootout, json.aggregate);
	}

	static builder(team?: MatchScoreModel | null): MatchscoreBuilder {
		return new MatchscoreBuilder(team);
	}
}
