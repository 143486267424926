import React, { useEffect, useState } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import ModelMapper from '../../../../../../models/ModelMapper';
import PlayerManualDataModel from '../../../../../../models/player-manual-data/player-manual-data.model';
import HttpService from '../../../../../../services/rest/HttpService';
import { constructRequestFromEntry, remapPlayerBlacklisted } from '../../helpers/manual-data.helper';
import EditSquadPlayerModal from './edit-squad-players.modal';
import { featuresService } from '../../../../../../App';
import { FeatureTypes } from '../../../../../../services/feature-service/features.enum';

type Properties = {
	t: any;
	match: any;
};

const TeamEditSquadsComponent: React.FunctionComponent<RouteComponentProps<{}> & Properties> = (props) => {
	const [isContentLoading, updateloading] = useState<boolean>(true);
	const [squadPlayers, updateSquadPlayers] = useState<PlayerManualDataModel[]>([]);
	const [isOpenModal, toggleModal] = useState<boolean>(false);
	const [playerForEdit, updatePlayerForEdit] = useState<PlayerManualDataModel>(PlayerManualDataModel.builder().build());
	const featureEnabled = featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.MANUAL_DATA_ENTRY_PLAYER);
	const { t, match } = props;

	useEffect(() => {
		getSquads();
	}, []);

	const getSquads = () => {
		HttpService.instanceFootball()
			.get(`/v2/teams/${match.params.id}/squad?memberStatus=ACTIVE`)
			.then((response: any) => setTeamSquadFromResponse(response.data))
			.catch(() => updateloading(false));
	};

	const setTeamSquadFromResponse = (data: any) => {
		updateloading(false);
		const players = ModelMapper.remapSquadPlayersFromResponse(data.players);
		updateSquadPlayers(players);
		getBlacklistEntityData(players);
	};

	const getBlacklistEntityData = (players: PlayerManualDataModel[]) => {
		const params = constructRequestFromEntry(players, 'PLAYER', 'ENTITY');
		HttpService.getBlacklist(`/v2/blacklist/search`, params)
			.then((response: any) => {
				const blacklist = response.data;

				if (blacklist && blacklist.length > 0) {
					updateSquadPlayers(remapPlayerBlacklisted(players, blacklist));
				}
			})
			.catch((error: unknown) => console.error(error));
	};

	const onEditPlayer = (player: PlayerManualDataModel) => {
		updatePlayerForEdit(player);
		toggleModal(true);
	};

	return (
		<div className={`${isContentLoading ? 'loading-overlay' : ''}`}>
			<Row style={{ margin: '0 auto' }} className='font-weight-bold py-2 border-top border-bottom w-100'>
				<Col xs={4} sm={6}>
					{props.t('player_name')}
				</Col>
				<Col xs={3} sm={2}>
					{props.t('nationality')}
				</Col>
				<Col xs={3} sm={2}>
					{props.t('status')}
				</Col>
				<Col xs={3} sm={2} className='text-right'>
					{props.t('actions')}
				</Col>
			</Row>
			{squadPlayers && squadPlayers.length > 0 ? (
				squadPlayers.map((player: PlayerManualDataModel, index: number) => {
					return (
						<Row
							key={player.id}
							id={`player-row-${player.id}`}
							className={`py-2 border-bottom align-items-center w-100 ${index % 2 === 1 ? '' : 'bg-light'} m-0`}
						>
							<Col xs={4} sm={6}>
								<div className='text-dark btn btn-link cursor-pointer' onClick={() => (featureEnabled ? onEditPlayer(player) : null)}>
									<span>{player.name}</span>
								</div>
							</Col>
							<Col className='pl-2' xs={3} sm={2}>
								{player.country.name}
							</Col>
							<Col className='pl-2' xs={3} sm={2}>
								{player.blacklist && player.blacklist.id && (
									<div className='d-flex'>
										<div className='blacklisted bg-warning text-dark text-center'>{t('blacklisted')}</div>
										<i className='text-white fa fa-user rounded-circle mr-1 team-edit-icon ml-1' />
									</div>
								)}
							</Col>
							<Col xs={3} sm={2} className='text-right'>
								<Button
									disabled={!featureEnabled}
									title={featureEnabled ? 'Edit' : 'No permissions to edit'}
									id={`player-edit-btn-${player.id}`}
									className='shadow-none'
									color='warning'
									onClick={() => onEditPlayer(player)}
								>
									<i className='fa fa-pencil p-1' />
									{t('edit')}
								</Button>
							</Col>
						</Row>
					);
				})
			) : (
				<div className='p-4 text-center'>{t('no_active_players')}</div>
			)}
			<EditSquadPlayerModal t={t} open={isOpenModal} onClose={() => toggleModal(false)} player={playerForEdit} getSquads={getSquads} />
		</div>
	);
};

export default withRouter(TeamEditSquadsComponent);
