import { PermissionJson } from './PermissionJson';
import PermissionBuilder from './PermissionBuilder';

export default class Permission {
	readonly id: string;
	readonly name: string;
	readonly title: string;

	private constructor(id: string, name: string, title: string) {
		this.id = id;
		this.name = name;
		this.title = title;
	}

	toJSON(): PermissionJson {
		return {
			id: this.id,
			name: this.name,
			title: this.title,
		};
	}

	static fromJSON(json: PermissionJson): Permission {
		return new Permission(json.id, json.name, json.title);
	}

	static builder(permission?: Permission): PermissionBuilder {
		return new PermissionBuilder(permission);
	}
}
