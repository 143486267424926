import { HocuspocusProvider, HocuspocusProviderConfiguration, HocuspocusProviderWebsocket } from '@hocuspocus/provider';
import lodash from 'lodash';
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ToastId, toast } from 'react-toastify';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Profile from '../../../../models/profile/Profile';
import SentryService from '../../../../services/sentry/sentry';
import { AppState } from '../../../../store/store';
import useHocuspocusWebSocket from '../../../Pages/Live Blog/hooks/useHocuspocusWebSocket';
import { CollaborationAPIWebSocketPayload } from '../../../Pages/Live Blog/models/collaboration-api-web-socket.model';

import './ArticleLockOverlay.scss';

const HEARTBEAT_TIMEOUT = 10000; // 10 seconds
const AUTO_UNLOCK_TIMEOUT = 3 * 60000; // 3 minutes

const WarningMessage: FC<{ initialTime: number }> = ({ initialTime }) => {
	const [t] = useTranslation();
	const [remainingTime, setRemainingTime] = useState(initialTime);

	useEffect(() => {
		const interval = setInterval(() => {
			setRemainingTime((prev) => prev - 1);
		}, 60000);

		return () => clearInterval(interval);
	}, []);

	return <span>{t('article_to_be_unlocked', { minutes: remainingTime })}</span>;
};

const ArticleLockOverlay: FC<{ history: any; refreshArticle: () => void; onChange: (applyOverlay: boolean) => void }> = ({
	history,
	refreshArticle,
	onChange,
}) => {
	const [t] = useTranslation();
	const [socketConnected, setSocketConnected] = useState(false);
	const [locked, setLocked] = useState(false); // Article is locked (someone is editing it at the moment)
	const [lockedBy, setLockedBy] = useState<CollaborationAPIWebSocketPayload['admin']>();
	const [lockExpiresAt, setLockExpiresAt] = useState<Date>();
	const [lockedByThisInstance, setLockedByThisInstance] = useState(false); // Article is locked by this tab (this user is editing it at the moment)
	const [unlockedViaMessage, setUnlockedViaMessage] = useState(false); // Article has been unlocked (someone finished editing it)
	const [unlockedViaTimeout, setUnlockedViaTimeout] = useState(false); // Article has been unlocked automatically by BE timeout
	const [isTabActive, setIsTabActive] = useState(true); // Browser tab is active (user is currently viewing it)
	const warningToastId = useRef<ToastId>();
	const finalState = useRef<{
		hocuspocusProvider?: HocuspocusProvider;
		isTabActive?: boolean;
		locked?: boolean;
		lockedByThisInstance?: boolean;
		socket?: HocuspocusProviderWebsocket;
	}>({});
	const articleId = useMemo(() => window['location'].hash.split('/').reduce((a, c) => c, ''), []); // Due to old version of react-router-dom; a better alternative is "useParams"
	const profile = useSelector((state: AppState) => state.profile.profile as Profile);

	const processWebSocketMessages: HocuspocusProviderConfiguration['onStateless'] = useCallback(({ payload }) => {
		let data: CollaborationAPIWebSocketPayload | undefined = undefined;

		try {
			data = JSON.parse(payload);
		} catch (e) {
			toast.error(t('parsing_error'));
			console.error(e);
		}

		if (!data) {
			return;
		}

		switch (data.action) {
			case 'LOCK':
				const admin = typeof data.admin === 'string' ? (JSON.parse(data.admin) as CollaborationAPIWebSocketPayload['admin']) : data.admin;

				setLockedBy(admin);
				setLocked(true);
				setUnlockedViaMessage(false);
				data.expires_at && setLockExpiresAt(new Date(data.expires_at));
				break;
			case 'UNLOCK':
				setUnlockedViaMessage(true);

				if (data.reason === 'TIMEOUT') {
					setUnlockedViaTimeout(true);
				}

				break;
		}
	}, []);

	const { provider: hocuspocusProvider, socket } = useHocuspocusWebSocket(articleId, processWebSocketMessages, () =>
		setSocketConnected(true),
	);

	const toggleLock = (action: 'LOCK' | 'UNLOCK') => {
		if (navigator.onLine && hocuspocusProvider) {
			hocuspocusProvider.sendStateless(
				JSON.stringify({
					action,
					entity_type: 'DOCUMENT',
					entity_id: articleId,
					document_id: articleId,
					admin: {
						id: profile.id,
						name: profile.name,
					},
				}),
			);

			SentryService.setContext('Article Edit', { id: articleId, action });
		}
	};

	useEffect(() => {
		const timeout = setTimeout(() => {
			if (hocuspocusProvider && isTabActive && socketConnected && !locked) {
				// Automatic lock, if the article is not locked yet
				toggleLock('LOCK');
				setLockedByThisInstance(true);
			}
		}, 500);

		return () => clearTimeout(timeout);
	}, [hocuspocusProvider, isTabActive, locked, socketConnected]);

	useEffect(() => {
		finalState.current = { hocuspocusProvider, isTabActive, locked, lockedByThisInstance, socket };
	}, [hocuspocusProvider, isTabActive, locked, lockedByThisInstance, socket]);

	useEffect(() => {
		const handleVisibilityChange: EventListener = () => {
			setIsTabActive(document.visibilityState === 'visible');
		};

		document.addEventListener('visibilitychange', handleVisibilityChange);

		return () => {
			document.removeEventListener('visibilitychange', handleVisibilityChange);

			if (finalState.current.hocuspocusProvider && finalState.current.socket && finalState.current.isTabActive) {
				if (finalState.current.locked && finalState.current.lockedByThisInstance) {
					toggleLock('UNLOCK'); // Unlock on navigate away
				}

				finalState.current.hocuspocusProvider.disconnect();
				finalState.current.socket.shouldConnect = false;
				finalState.current.socket.disconnect();
			}

			SentryService.removeContext('Article Edit');
		};
	}, []);

	useEffect(() => {
		if (locked && lockedByThisInstance) {
			const beforeunloadHandler = () => {
				toggleLock('UNLOCK'); // Unlock on tab close
			};

			window.addEventListener('beforeunload', beforeunloadHandler);

			return () => window.removeEventListener('beforeunload', beforeunloadHandler);
		}
	}, [locked, lockedByThisInstance]);

	useEffect(() => {
		if (!isTabActive || !lockedByThisInstance) {
			return;
		}

		const eventHandler = lodash.throttle(() => {
			// Sends LOCK message in order to keep the article from being automatically unlocked
			toggleLock('LOCK');
			warningToastId.current && toast.dismiss(warningToastId.current);
		}, HEARTBEAT_TIMEOUT);

		window.addEventListener('mousemove', eventHandler);
		window.addEventListener('click', eventHandler);
		window.addEventListener('keydown', eventHandler);

		return () => {
			window.removeEventListener('mousemove', eventHandler);
			window.removeEventListener('click', eventHandler);
			window.removeEventListener('keydown', eventHandler);
		};
	}, [isTabActive, lockedByThisInstance]);

	useEffect(() => {
		if (!lockedByThisInstance || !lockExpiresAt || unlockedViaMessage) {
			return;
		}

		const msToHideMessageAt = lockExpiresAt.getTime() - new Date().getTime();
		const msToShowMessageAt = msToHideMessageAt - AUTO_UNLOCK_TIMEOUT;
		const messageShowTimeout = setTimeout(() => {
			warningToastId.current && toast.dismiss(warningToastId.current);
			warningToastId.current = toast.warn(<WarningMessage initialTime={Math.floor(AUTO_UNLOCK_TIMEOUT / 60000)} />, {
				autoClose: false,
				className: 'article-lock-warning',
			});
		}, msToShowMessageAt);
		const messageHideTimeout = setTimeout(() => {
			if (!navigator.onLine) {
				// Fallback to manually redirect to the articles list if the user is offline
				setUnlockedViaTimeout(true);
			}
		}, msToHideMessageAt);

		return () => {
			clearTimeout(messageShowTimeout);
			clearTimeout(messageHideTimeout);
		};
	}, [lockedByThisInstance, lockExpiresAt, unlockedViaMessage]);

	useEffect(() => {
		if (lockedByThisInstance && unlockedViaTimeout) {
			warningToastId.current && toast.dismiss(warningToastId.current);
			history.push('/smp/articles');
		}
	}, [lockedByThisInstance, unlockedViaTimeout]);

	useEffect(() => {
		const cleanup = () => {
			document.querySelectorAll('.app-body, .breadcrumb').forEach((element) => element.classList.remove('article-locked'));
		};

		if (!locked || lockedByThisInstance) {
			onChange(false);
			cleanup();
		} else {
			onChange(true);
			document.querySelectorAll('.app-body, .breadcrumb').forEach((element) => element.classList.add('article-locked'));
		}

		return cleanup;
	}, [locked, lockedByThisInstance, unlockedViaMessage]);

	if (!locked || lockedByThisInstance) {
		return null;
	}

	return (
		<>
			<Modal className='article-lock-overlay article-lock-overlay-modal' isOpen={unlockedViaMessage} size='m' centered>
				<ModalHeader>{t('article_unlocked_header')}</ModalHeader>
				<ModalBody>
					<Trans i18nKey='article_unlocked_description' />
				</ModalBody>
				<ModalFooter>
					<Button
						color='primary'
						onClick={() => {
							setLockExpiresAt(undefined);
							toggleLock('LOCK');
							setUnlockedViaTimeout(false);
							setLockedByThisInstance(true);
							refreshArticle();
						}}
					>
						{t('article_take_access')}
					</Button>
				</ModalFooter>
			</Modal>
			{!unlockedViaMessage && (
				<div className='article-lock-overlay article-lock-overlay-message'>
					<i className='fa fa-lock' />
					{lockedBy && lockedBy.id !== profile.id ? (
						<Trans i18nKey='article_currently_edited_by' values={{ editorName: lockedBy.name }} />
					) : (
						<Trans i18nKey='article_currently_edited_another_tab' />
					)}
				</div>
			)}
		</>
	);
};

export default ArticleLockOverlay;
