import axios from 'axios';
import { featuresService, multiLingualService } from '../../App';
import { store } from '../../store/store';
import { SportTypes } from '../../constants/sport.types';
import { determineFeatureConfigType } from '../../views/Partials/Blocky/partials/sport-bookmaker-select/helpers/sport-bookmakers-select.helper';
import REACT_APP_URLS from '../../global-helpers/global-url.helpers';
import { FeatureTypes } from '../feature-service/features.enum';

const BASKETBALL_SEARCH_API_URL = REACT_APP_URLS.REACT_APP_BASKETBALL_API_URL;
const ODDS_SEARCH_API_URL = REACT_APP_URLS.REACT_APP_ODDS_API_URL;
const TENNIS_SEARCH_API_URL = REACT_APP_URLS.REACT_APP_TENNIS_API_URL;

export default class SportsHttpService {
	static instance = (sport: string, acceptLang?: any) => {
		const config = featuresService.getFeatureConfig(sport);

		return axios.create({
			baseURL: sport && sport === SportTypes.BASKETBALL ? BASKETBALL_SEARCH_API_URL : TENNIS_SEARCH_API_URL,
			timeout: 30000,
			headers: {
				'X-Project': `${store.getState().project.currentProject.domain}`,
				Authorization: `Basic ${Buffer.from(
					`${config.authentication ? `${config.authentication.username}:${config.authentication.password}` : ''}`,
				).toString('base64')}`,
				'Accept-Language': acceptLang
					? acceptLang
					: `${
							multiLingualService.checkIfProjectIsMultiLingual(store.getState().project.currentProject.languages) &&
							multiLingualService.checkRoutePath()
								? multiLingualService.setCorrectAcceptLanguageCode(
										store.getState().project.currentProject.languages.defaultLanguageCode.languageCode,
								  )
								: store.getState().project.currentProject.language
								? store.getState().project.currentProject.language.split('-')[0]
								: 'en'
					  }`,
			},
		});
	};

	static oddsInstance = (sport: string, acceptLang?: any) => {
		const featureType = determineFeatureConfigType(sport);
		const config = featuresService.getFeatureConfig(featureType);

		return axios.create({
			baseURL: ODDS_SEARCH_API_URL,
			timeout: 30000,
			headers: {
				'X-Project': `${store.getState().project.currentProject.domain}`,
				Authorization: `Basic ${Buffer.from(
					`${config.odds_authentication ? `${config.odds_authentication.username}:${config.odds_authentication.password}` : ''}`,
				).toString('base64')}`,
				'Accept-Language': acceptLang
					? acceptLang
					: `${
							multiLingualService.checkIfProjectIsMultiLingual(store.getState().project.currentProject.languages) &&
							multiLingualService.checkRoutePath()
								? multiLingualService.setCorrectAcceptLanguageCode(
										store.getState().project.currentProject.languages.defaultLanguageCode.languageCode,
								  )
								: store.getState().project.currentProject.language
								? store.getState().project.currentProject.language.split('-')[0]
								: 'en'
					  }`,
			},
		});
	};

	static getEventById = (gameId: string, lang: string, sport: string, oddClient?: string) => {
		const eventType = sport && sport === SportTypes.BASKETBALL ? 'games' : 'matches';
		return SportsHttpService.instance(sport).get(
			`/${eventType}/${gameId}${oddClient || lang ? '?' : ''}${oddClient ? `odd_client=${oddClient}` : ''}${oddClient && lang ? '&' : ''}${
				lang ? `translation_language=${lang}` : ''
			}`,
		);
	};

	static getOdds = (sportType: string, oddClient?: string) => {
		return SportsHttpService.oddsInstance(sportType).get(`/configurations/${sportType}/${oddClient ? oddClient : ''}`);
	};

	static getCompetitions = (sport: string, lang: string) => {
		let resultUrl = `/competitions?${lang ? `translation_language=${lang}` : ''}&limit=1000&offset=0`;
		try {
			const sportFeatureString = sport === SportTypes.FOOTBALL ? FeatureTypes.FOOTBALL_V2 : sport;
			const featureData = featuresService.checkFeatureDataField(sportFeatureString) as Record<string, any>;
			const featureCompetitionList = featureData.competitionList || null;
			const competitionListQuery = featureCompetitionList ? `&competition_list=${featureCompetitionList}` : '';
			resultUrl += competitionListQuery;
		} catch (error) {}
		return SportsHttpService.instance(sport).get(resultUrl);
	};

	static getSeasons = (sport: string, competitionId?: string, lang?: string, teamId?: string) => {
		const competitionQuery = competitionId && competitionId.length > 0 ? `&competition_id=${competitionId}` : '';
		const teamQuery = teamId && teamId.length > 0 ? `&team_id=${teamId}` : '';
		return SportsHttpService.instance(sport).get(
			`/seasons?limit=200&offset=0${competitionQuery}${teamQuery}&${lang ? `translation_language=${lang}` : ''}`,
		);
	};

	static getSeasonsDetails = (sport: string, seasonId: string, lang: string) => {
		return SportsHttpService.instance(sport).get(`/seasons/details?season_id=${seasonId}${lang ? `&translation_language=${lang}` : ''}`);
	};

	static getCompetitionSeasons = (sport: string, competitionId: string, season: string, lang: string) => {
		return SportsHttpService.instance(sport).get(
			`/competitions/details?competition_id=${competitionId}&season_year=${season}${lang ? `&translation_language=${lang}` : ''}`,
		);
	};

	static getTournamentsByPlayerId = (sport: string, playerId: string, lang: string, limit: string) => {
		return SportsHttpService.instance(sport).get(
			`/tournaments?player_id=${playerId}${lang ? `&translation_language=${lang}` : ''}&offset=0&limit=${limit}`,
		);
	};

	static getMatchesByPlayerId = (sport: string, tournamentId: string, playerId: string, lang: string) => {
		return SportsHttpService.instance(sport).get(
			`/matches?tournament_ids=${tournamentId}&player_id=${playerId}${lang ? `&translation_language=${lang}` : ''}&offset=0&limit=200`,
		);
	};
}
