import React from 'react';
import { Properties } from './properties/embed-block-view.properties';

export const EmbedView: React.FunctionComponent<Properties> = ({ preview, t }) => {
	return (
		<div className='row pl-3 pr-3'>
			<div className='col-12 col-sm-12 col-md-10'>
				<h4 className='mb-2'>
					<i className='fa fa-code'></i> {t('embed_code')}
				</h4>
				<p className='mb-2'>
					{t('type')}:<span className='font-weight-bold text-capitalize'> {preview && preview.type ? t(preview.type) : 'N/A'}</span>
				</p>
			</div>
		</div>
	);
};
