import { CropModel } from '../properties/image-crop-container.properties';
import ImageUrls from '../../../../../models/image/urls/ImageUrls';
import ImageRatio from '../../../../../models/image/urls/ratio/ImageRatio';

export const updateImageUrlsWithAspectRatio = {
	'23x9': (urls: ImageUrls, cropModel: CropModel): ImageUrls => {
		const imageRatio = ImageRatio.builder()
			.withTop('' + cropModel.top)
			.withBottom('' + cropModel.bottom)
			.withLeft('' + cropModel.left)
			.withRight('' + cropModel.right)
			.build();

		return ImageUrls.builder(urls).with23x9(imageRatio).build();
	},
	'16x9': (urls: ImageUrls, cropModel: CropModel): ImageUrls => {
		const imageRatio = ImageRatio.builder()
			.withTop('' + cropModel.top)
			.withBottom('' + cropModel.bottom)
			.withLeft('' + cropModel.left)
			.withRight('' + cropModel.right)
			.build();

		return ImageUrls.builder(urls).with16x9(imageRatio).build();
	},
	'9x16': (urls: ImageUrls, cropModel: CropModel): ImageUrls => {
		const imageRatio = ImageRatio.builder()
			.withTop('' + cropModel.top)
			.withBottom('' + cropModel.bottom)
			.withLeft('' + cropModel.left)
			.withRight('' + cropModel.right)
			.build();

		return ImageUrls.builder(urls).with9x16(imageRatio).build();
	},
	'4x3': (urls: ImageUrls, cropModel: CropModel): ImageUrls => {
		const imageRatio = ImageRatio.builder()
			.withTop('' + cropModel.top)
			.withBottom('' + cropModel.bottom)
			.withLeft('' + cropModel.left)
			.withRight('' + cropModel.right)
			.build();

		return ImageUrls.builder(urls).with4x3(imageRatio).build();
	},
	'3x4': (urls: ImageUrls, cropModel: CropModel): ImageUrls => {
		const imageRatio = ImageRatio.builder()
			.withTop('' + cropModel.top)
			.withBottom('' + cropModel.bottom)
			.withLeft('' + cropModel.left)
			.withRight('' + cropModel.right)
			.build();

		return ImageUrls.builder(urls).with3x4(imageRatio).build();
	},
	'3x2': (urls: ImageUrls, cropModel: CropModel): ImageUrls => {
		const imageRatio = ImageRatio.builder()
			.withTop('' + cropModel.top)
			.withBottom('' + cropModel.bottom)
			.withLeft('' + cropModel.left)
			.withRight('' + cropModel.right)
			.build();

		return ImageUrls.builder(urls).with3x2(imageRatio).build();
	},
	'2x3': (urls: ImageUrls, cropModel: CropModel): ImageUrls => {
		const imageRatio = ImageRatio.builder()
			.withTop('' + cropModel.top)
			.withBottom('' + cropModel.bottom)
			.withLeft('' + cropModel.left)
			.withRight('' + cropModel.right)
			.build();

		return ImageUrls.builder(urls).with2x3(imageRatio).build();
	},
	'1x1': (urls: ImageUrls, cropModel: CropModel): ImageUrls => {
		const imageRatio = ImageRatio.builder()
			.withTop(`${cropModel.top}`)
			.withBottom(`${cropModel.bottom}`)
			.withLeft(`${cropModel.left}`)
			.withRight(`${cropModel.right}`)
			.build();

		return ImageUrls.builder(urls).with1x1(imageRatio).build();
	},
};

export function imageUrlsToRequestImageUrls(cropInfo: CropModel, aspectRatio: string) {
	return {
		urls: {
			[aspectRatio]: {
				left: cropInfo.left,
				top: cropInfo.top,
				right: cropInfo.right,
				bottom: cropInfo.bottom,
			},
		},
	};
}

export function getCropModelFromCropperInfo(x: number, y: number, width: number, height: number): CropModel {
	let top = Math.trunc(x);
	let left = Math.trunc(y);
	let bottom = Math.trunc(x + width);
	let right = Math.trunc(y + height);

	top = top > 0 ? top : 0;
	left = left > 0 ? left : 0;
	bottom = bottom > 0 ? bottom : 0;
	right = right > 0 ? right : 0;

	return {
		left,
		top,
		right,
		bottom,
	};
}
