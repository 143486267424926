import React from 'react';
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import '../dugout-video-select.scss';

const languages = ['en', 'it', 'es', 'pt', 'ar', 'tk', 'id', 'vi', 'fr'];

type Properties = {
	t: any;
	changeSelectedLanguage: any;
	isOpenDropdown: any;
	toggleLanguagesMenu: any;
	selectedLanguage: any;
};

export default class LanguageSelect extends React.Component<Properties> {
	render() {
		return (
			<ButtonDropdown isOpen={this.props.isOpenDropdown} toggle={this.props.toggleLanguagesMenu} className='lang-dropdown'>
				<DropdownToggle caret data-dropup-auto='false' className={'dropdown-btn-lang'}>
					{this.props.t(this.props.selectedLanguage)}
				</DropdownToggle>
				<DropdownMenu>
					{languages.map((lang) => (
						<DropdownItem title={this.props.t(lang)} key={lang} onClick={() => this.props.changeSelectedLanguage(lang)}>
							{this.props.t(lang)}
						</DropdownItem>
					))}
				</DropdownMenu>
			</ButtonDropdown>
		);
	}
}
