import Wiki from '../../models/wiki/Wiki';
import { WIKI_TEMP_UPDATE } from '../action-creators/WikiTempActionCreator';

export function wikiTempMiddleware() {
	return function (next: any) {
		return function (action: any) {
			if (action) {
				if (action.type === WIKI_TEMP_UPDATE) {
					const wiki = Wiki.builder(action.payload).build();

					if (wiki.id === undefined || wiki.id === null || wiki.id.length < 1) {
						action.payload = Wiki.builder(wiki).withId('temp-id').build();
					}
				}
			}

			next(action);
		};
	};
}
