import ErrorModel from '../error/ErrorModel';
import { ErrorResponseJson } from './ErrorResponseJson';
import ErrorResponseBuilder from './ErrorResponseBuilder';

export default class ErrorResponseModel {
	readonly errors: ErrorModel[] = [];

	private constructor(errors: ErrorModel[]) {
		this.errors = errors;
	}

	toJSON(): ErrorResponseJson {
		return {
			errors: this.errors,
		};
	}

	static fromJSON(json: ErrorResponseJson): ErrorResponseModel {
		return new ErrorResponseModel(json.errors);
	}

	static builder(error?: ErrorResponseModel): ErrorResponseBuilder {
		return new ErrorResponseBuilder(error);
	}
}
