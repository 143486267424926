import { USER_LOGOUT } from '../action-creators/ProfileActionCreators';
import {
	REQUEST_VIDEO_STATUSES_RECEIVED,
	REQUEST_ARTICLE_STATUSES_RECEIVED,
	REQUEST_POLLS_STATUSES_RECEIVED,
	REQUEST_GALLERY_STATUSES_RECEIVED,
	REQUEST_LIST_STATUSES_RECEIVED,
	REQUEST_WIKI_STATUSES_RECEIVED,
	REQUEST_BANNER_STATUSES_RECEIVED,
} from '../action-creators/status-action-creator';
import ContentAttributes from '../../models/content-attributes/content-attributes-model';

interface InitialState {
	articleStatuses: ContentAttributes[];
	videoStatuses: ContentAttributes[];
	imageStatuses: ContentAttributes[];
	galleryStatuses: ContentAttributes[];
	pollStatuses: ContentAttributes[];
	listStatuses: ContentAttributes[];
	wikipageStatuses: ContentAttributes[];
	bannerStatuses: ContentAttributes[];
}

const initialState: InitialState = {
	articleStatuses: [] as ContentAttributes[],
	videoStatuses: [] as ContentAttributes[],
	imageStatuses: [] as ContentAttributes[],
	galleryStatuses: [] as ContentAttributes[],
	pollStatuses: [] as ContentAttributes[],
	listStatuses: [] as ContentAttributes[],
	wikipageStatuses: [] as ContentAttributes[],
	bannerStatuses: [] as ContentAttributes[],
};

function statusReducer(state: any = initialState, action: any) {
	if (action.type === REQUEST_ARTICLE_STATUSES_RECEIVED) {
		return Object.assign({}, state, {
			articleStatuses: action.payload.slice(),
		});
	}

	if (action.type === REQUEST_VIDEO_STATUSES_RECEIVED) {
		return Object.assign({}, state, {
			videoStatuses: action.payload.slice(),
		});
	}

	if (action.type === REQUEST_POLLS_STATUSES_RECEIVED) {
		return Object.assign({}, state, {
			pollStatuses: action.payload.slice(),
		});
	}

	if (action.type === REQUEST_GALLERY_STATUSES_RECEIVED) {
		return Object.assign({}, state, {
			galleryStatuses: action.payload.slice(),
		});
	}

	if (action.type === REQUEST_WIKI_STATUSES_RECEIVED) {
		return Object.assign({}, state, {
			wikipageStatuses: action.payload.slice(),
		});
	}

	if (action.type === REQUEST_LIST_STATUSES_RECEIVED) {
		return Object.assign({}, state, {
			listStatuses: action.payload.slice(),
		});
	}

	if (action.type === REQUEST_BANNER_STATUSES_RECEIVED) {
		return Object.assign({}, state, {
			bannerStatuses: action.payload.slice(),
		});
	}

	if (action.type === USER_LOGOUT) {
		state = initialState;
	}

	return state;
}

export default statusReducer;
