import React from 'react';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { blockManagementService, blockWrapperService } from '../../../../subcomponents/blocky.component';
import BlockValidation from '../../../../helpers/block-validation.helper';
import BlockModel from '../../../../models/block.model';
import TournamentModel from '../../../../../../../models/v2/Tournament/Entity/TournamentModel';
import SeasonModel from '../../../../../../../models/v2/Season/Entity/SeasonModel';
import TournamentSelectComponent from '../../../partials/tournament-select/tournament-select.component';
import SeasonSelectComponent from '../../../partials/season-select/season-select.component';
import ErrorHandler from '../../../partials/error-handler/error-handler';
import { SportTypes } from '../../../../../../../constants/sport.types';
import { SPORT_SELECT_ATTRIBUTES } from '../../helpers/component-attributes.constants';
import FootballTeamFormWidgetModel from '../models/football-team-form.model';
import TeamSelectComponent from '../../../partials/team-select/team-select.component';
import TeamModel from '../../../../../../../models/v2/Team/Entity/TeamModel';
import { createBlock } from '../../../partials/helpers/blocky-sidebar-communication/blocky-sidebar-communication.helper';
import { WidgetsV2Ids } from '../../../../constants/block.types';
import { onRefreshTimeChangeFromMainComponent } from '../../../partials/refresh-time-input/helper';
import { SelectMenuOptionType } from '../../../../../../../models/v2/general/select.model';
import RefreshTimeWrapper from '../../../partials/refresh-time-input/refresh-time-wrapper';
type Properties = {
	block: BlockModel;
	t: any;
	contentData: any;
	contentLanguage: string;
};

type State = {
	widgetModel: FootballTeamFormWidgetModel;
	isValid: boolean;
};

export default class FootballTeamFormEditComponent extends React.Component<Properties, State> {
	constructor(props: Properties) {
		super(props);
		this.state = {
			widgetModel: createBlock(
				WidgetsV2Ids.widgetFootballTeamForm,
				props.block.data.preview && props.block.data.preview.widgetModel,
				props.contentData,
			),
			isValid: true,
		};
	}

	componentDidMount(): void {
		blockWrapperService.registerOnSaveFunc({ blockId: this.props.block.id, func: this.onSave });
	}

	onSave = (index: number) => {
		const validation = new BlockValidation();
		const data = { widgetModel: this.state.widgetModel };
		const isValid = validation.validate(this.props.block, data) === true;

		this.setState({ ...this.state, isValid });

		const teamFormModel = FootballTeamFormWidgetModel.builder(this.state.widgetModel).build();

		if (isValid) {
			const block = Object.assign({}, this.props.block);
			block.data.preview = { widgetModel: teamFormModel };
			blockManagementService.blockUpdate(block, index);
		} else {
			return isValid;
		}
	};

	componentWillUnmount(): void {
		blockWrapperService.unregisterOnSaveFunc(this.props.block.id);
	}

	updateWidgetModelState = (model: FootballTeamFormWidgetModel) => {
		this.setState({ ...this.state, widgetModel: model });
	};

	onTeamChange = (team: TeamModel) => {
		this.updateWidgetModelState(
			FootballTeamFormWidgetModel.builder(this.state.widgetModel)
				.withTeam(team)
				.withTournament({} as TournamentModel)
				.withSeason({} as SeasonModel)
				.build(),
		);
	};

	onTournamentSelect = (tournament: TournamentModel) => {
		this.updateWidgetModelState(
			FootballTeamFormWidgetModel.builder(this.state.widgetModel)
				.withTournament(tournament)
				.withSeason({} as SeasonModel)
				.build(),
		);
	};

	onSeasonSelect = (season: SeasonModel) => {
		this.updateWidgetModelState(FootballTeamFormWidgetModel.builder(this.state.widgetModel).withSeason(season).build());
	};

	onLimitChange = (limit: string) => {
		+limit > 0 &&
			+limit <= 15 &&
			this.updateWidgetModelState(FootballTeamFormWidgetModel.builder(this.state.widgetModel).withLimit(limit).build());
	};

	onTitleChange = (title: string) => {
		this.updateWidgetModelState(FootballTeamFormWidgetModel.builder(this.state.widgetModel).withTitle(title).build());
	};

	onRefreshTimeChange = (refreshTimeData: SelectMenuOptionType) => {
		onRefreshTimeChangeFromMainComponent(refreshTimeData, FootballTeamFormWidgetModel, this.state.widgetModel, this.updateWidgetModelState);
	};

	render() {
		const { widgetModel, isValid } = this.state;

		return (
			<>
				<div data-qa='football-team-form-edit-component'>
					<Row>
						<Col>
							<FormGroup>
								<TeamSelectComponent
									isMulti={false}
									isBlocky
									isValid={isValid}
									selectedTeam={widgetModel.team ? widgetModel.team : {}}
									onTeamSelect={(team: TeamModel) => this.onTeamChange(team)}
									t={this.props.t}
									isClearable
									isRequired
									searchByKeyword={true}
									id={`${SportTypes.FOOTBALL}-${SPORT_SELECT_ATTRIBUTES.TEAM_SELECT}`}
								/>
							</FormGroup>
						</Col>
					</Row>
					{widgetModel.team && widgetModel.team.id && (
						<Row>
							<Col>
								<FormGroup>
									<TournamentSelectComponent
										isMulti={false}
										isBlocky
										isRequired
										isValid={isValid}
										selectedTournament={widgetModel.tournament}
										onTournamentSelect={this.onTournamentSelect}
										t={this.props.t}
										id={`${SportTypes.FOOTBALL}-${SPORT_SELECT_ATTRIBUTES.TOURNAMENT_SELECT}`}
									/>
								</FormGroup>
							</Col>
						</Row>
					)}
					{widgetModel && widgetModel.tournament && widgetModel.tournament.id && (
						<Row>
							<Col>
								<FormGroup>
									<SeasonSelectComponent
										withLabel
										isValid={isValid}
										isBlocky
										selectedSeason={widgetModel.season}
										isClearable={widgetModel.season && !widgetModel.season.id}
										tournamentId={widgetModel.tournament && widgetModel.tournament.id ? widgetModel.tournament.id : ''}
										onSeasonSelect={this.onSeasonSelect}
										t={this.props.t}
										preselectSeason
										id={`${SportTypes.FOOTBALL}-${SPORT_SELECT_ATTRIBUTES.SEASON_SELECT}`}
									/>
								</FormGroup>
							</Col>
						</Row>
					)}
					<Row>
						<Col>
							<FormGroup>
								<Label>{this.props.t('limit')}:</Label>
								<Input
									type='number'
									disabled={!widgetModel.team}
									className='form-control'
									value={widgetModel.limit ? widgetModel.limit : ''}
									max={15}
									min={1}
									onChange={(event: any) => this.onLimitChange(event.target.value)}
									placeholder={`${this.props.t('from')} 1 ${this.props.t('to')} 15`}
								/>
							</FormGroup>
						</Col>
					</Row>
					<Row>
						<Col>
							<FormGroup>
								<Label>{this.props.t('title')}:</Label>
								<Input
									id='form-widget-title-input'
									type='text'
									className='form-control'
									value={widgetModel.title}
									onChange={(event: any) => this.onTitleChange(event.target.value)}
									placeholder={this.props.t('title')}
								/>
							</FormGroup>
						</Col>
					</Row>
					<div className='mb-3'>
						<ErrorHandler
							arePropsValid={isValid}
							isValueSelected={widgetModel.team && !widgetModel.team.id}
							t={this.props.t}
							errorMessage='no_team_selected'
						/>
					</div>
					<RefreshTimeWrapper value={this.state.widgetModel.refreshTime} onRefreshTimeChange={this.onRefreshTimeChange} />
				</div>
			</>
		);
	}
}
