import { Button, Label, Modal, ModalBody, ModalFooter, ModalHeader, Tooltip } from 'reactstrap';
import React, { useEffect, useState } from 'react';
import { DATA_QA_ATTRIBUTES } from '../../../../../Partials/Sidebar/general-content/constants/data-qa.attributes';
import Select from 'react-select';
import { isListEmpty } from '../../../../../Partials/Sidebar/list-refactored/helpers/general.helper';
import ListModel from '../../../../../../models/v2/list/list';
import { updateListInRedux } from '../../../../../../store/action-creators/ListActionCreators';
import { toggleShowListImages } from '../../../../../../store/action-creators/UiActionCreator';
import { connect } from 'react-redux';
import { ValueType } from 'react-select/src/types';
import { SelectMenuOptionType } from '../../../../../../models/v2/general/select.model';
import { listsToOptions, listToOption } from '../../../../../Partials/Sidebar/list-refactored/helpers/list-select.helper';
import { getListsContent, returnListDetails } from './live-blog-lists.helper';

type Properties = {
	t: any;
	isOpen: boolean;
	toggleModal: (isOpen: boolean) => void;
	setToggleListItemsModal: (isOpen: boolean) => void;
	selectedList: ListModel | null;
	projectDomain: string;
	shouldShowListImages: boolean;
	profileId: string;
	updateListInRedux: (list: ListModel) => void;
	toggleShowListImages: (profileId: string) => void;
};

const LiveBlogListsModal: React.FunctionComponent<Properties> = ({
	t,
	isOpen,
	toggleModal,
	setToggleListItemsModal,
	selectedList,
	projectDomain,
	updateListInRedux,
	shouldShowListImages,
	profileId,
	toggleShowListImages,
}) => {
	const [tooltipOpen, setTooltipOpen] = useState<boolean>(false);
	const [lists, setLists] = useState<ListModel[]>([]);

	useEffect(() => {
		getListsContent(projectDomain, setLists);
	}, []);

	const onChangeList = async (option: ValueType<SelectMenuOptionType>) => {
		// @ts-ignore -> because it doesn't find '.data' but its in the model
		const selectedListId = (option && option.value) || null;
		if (selectedListId) {
			const remappedListData = await returnListDetails(selectedListId, projectDomain);
			updateListInRedux(remappedListData);
		}
	};

	const isListSelected = !selectedList || !selectedList.id;
	return (
		<div data-qa={DATA_QA_ATTRIBUTES.LIST_MODAL}>
			<Modal size='lg' isOpen={isOpen}>
				<ModalHeader
					toggle={() => {
						toggleModal(false);
						setToggleListItemsModal(false);
					}}
				>
					{t('add_to_list')}
				</ModalHeader>
				<ModalBody>
					<div data-children='.item'>
						<div className='item'>
							<div className='d-flex'>
								<Label htmlFor={DATA_QA_ATTRIBUTES.LIST_SELECT}>
									<strong>{t('select_list')}</strong>
								</Label>
								<span className='text-danger'>*</span>
								<div
									id={DATA_QA_ATTRIBUTES.LIST_SHOW_IMAGES}
									className='ml-auto'
									onClick={() => toggleShowListImages(profileId)}
									style={{ top: -23, right: 0 }}
								>
									<i className='fa fa-info-circle text-muted mr-1' />
									<i className={`fa fa-toggle-${shouldShowListImages ? 'on text-success' : 'off text-muted'}`} />
								</div>
								<Tooltip
									placement='left'
									isOpen={tooltipOpen}
									target={DATA_QA_ATTRIBUTES.LIST_SHOW_IMAGES}
									toggle={() => setTooltipOpen(!tooltipOpen)}
								>
									{t(`${shouldShowListImages ? 'show' : 'hide'}_images`)}
								</Tooltip>
							</div>
							<div className='mb-2'>
								<Select
									id={DATA_QA_ATTRIBUTES.LIST_SELECT}
									options={listsToOptions(lists)}
									onChange={onChangeList}
									value={selectedList && listToOption(selectedList)}
									placeholder={t('select')}
									noOptionsMessage={(inputValue) => inputValue && t('no_options')}
								/>
							</div>
						</div>
					</div>
				</ModalBody>
				<ModalFooter>
					<Button
						id={DATA_QA_ATTRIBUTES.LIVE_BLOG_LIST_MODAL_CLOSE}
						color='danger'
						className='text-light'
						onClick={() => {
							toggleModal(false);
							setToggleListItemsModal(false);
						}}
					>
						{t('close')}
					</Button>
					<Button
						disabled={isListSelected}
						id={DATA_QA_ATTRIBUTES.LIVE_BLOG_LIST_MODAL_ADD}
						color='success'
						className='text-light'
						onClick={() => {
							toggleModal(false);
							setToggleListItemsModal(true);
						}}
					>
						{t('add_to_list')}
					</Button>
				</ModalFooter>
			</Modal>
		</div>
	);
};

function mapStateToProps(state: any) {
	return {
		shouldShowListImages: state.ui.shouldShowListImages[state.profile.profile.id] || false,
		profileId: state.profile.profile.id || '',
		projectDomain: state.project.currentProject.domain || '',
		selectedList: (!isListEmpty(state.list.list) && state.list.list) || null,
	};
}

function mapDispatchToProps(dispatch: Function) {
	return {
		updateListInRedux: (list: ListModel) => dispatch(updateListInRedux(list)),
		toggleShowListImages: (profileId: string) => dispatch(toggleShowListImages(profileId)),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(LiveBlogListsModal);
