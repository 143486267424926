import FootballConnectionResponseModel from '../../models/v2/football-connection/response-football-connection.model';
import { isEntityTypeValidForSuggestedEntities } from '../../views/Partials/Sidebar/tags-refactored/helpers/suggested-entities.helper';

export const UPDATE_SUGGESTED_ENTITIES = '[ACTION] UPDATE_SUGGESTED_ENTITIES';
export const ADD_SUGGESTED_ENTITY = '[ACTION] ADD_SUGGESTED_ENTITY';
export const REMOVE_SUGGESTED_ENTITIES = '[ACTION] REMOVE_SUGGESTED_ENTITIES';
export const HIDE_SUGGESTED_ENTITY = '[ACTION] HIDE_SUGGESTED_ENTITY';
export const CLEAR_SUGGESTED_ENTITIES = '[ACTION] CLEAR_SUGGESTED_ENTITIES';

export function updateSuggestedEntities(suggestedEntities: FootballConnectionResponseModel[]) {
	return {
		type: UPDATE_SUGGESTED_ENTITIES,
		payload: suggestedEntities,
	};
}

export function addSuggestedEntity(suggestedEntity: FootballConnectionResponseModel) {
	if (isEntityTypeValidForSuggestedEntities(suggestedEntity.entity_type || '')) {
		return {
			type: ADD_SUGGESTED_ENTITY,
			payload: suggestedEntity,
		};
	}
}

export function removeSuggestedEntitiesByOrigin(suggestedEntityId: string) {
	return {
		type: REMOVE_SUGGESTED_ENTITIES,
		teamId: suggestedEntityId,
	};
}

export function hideSuggestedEntity(suggestedEntity: FootballConnectionResponseModel) {
	if (isEntityTypeValidForSuggestedEntities(suggestedEntity.entity_type || '')) {
		return {
			type: HIDE_SUGGESTED_ENTITY,
			payload: suggestedEntity,
		};
	}
}

export function clearSuggestedEntities() {
	return {
		type: CLEAR_SUGGESTED_ENTITIES,
	};
}
