import * as React from 'react';
import { Properties } from './properties/CategorySelectContainerProps';
import Select from 'react-select';
import { categoriesToOptions, categoryToOption, optionToCategory } from './CategorySelectHelper';
import ErrorAsync from '../../../../BaseComponents/ErrorComponent/ErrorAsync';
import { customOption } from '../../../../Blocky/partials/shared/custom-select-option';

/**
 * TODO move to shared folder
 */
const CategorySelectContainer: React.FunctionComponent<Properties> = (props) => {
	const onCategorySelect = (selection: any) => {
		props.onCategorySelect(optionToCategory(selection));
	};

	const { categories, adminCategories, withEmptyCategory, selectedCategory, isClearable, inputId } = props;

	return (
		<>
			<Select
				menuPortalTarget={document.body}
				menuPosition='absolute'
				options={categoriesToOptions(categories, adminCategories, withEmptyCategory)}
				onChange={onCategorySelect}
				inputId={inputId}
				value={selectedCategory.id ? categoryToOption(selectedCategory) : null}
				placeholder={props.t('select_category')}
				noOptionsMessage={(inputValue) => inputValue && props.t('no_options')}
				isClearable={isClearable}
				formatOptionLabel={customOption}
				styles={{
					menuPortal: (baseStyles) => ({
						...baseStyles,
						zIndex: 10000,
					}),
				}}
			/>
			<ErrorAsync t={props.t} errorType='category_id' />
		</>
	);
};

export default CategorySelectContainer;
