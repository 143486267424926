import { jsonObject, jsonMember, AnyT } from 'typedjson';
import BookmakerModel from './sport-bookmaker.model';

@jsonObject
export default class OddsModel {
	@jsonMember(AnyT, { deserializer: (value) => value })
	public markets?: any;

	@jsonMember(BookmakerModel)
	public bookmaker?: BookmakerModel | null = null;

	@jsonMember(String)
	public type: string | null = null;
}
