import * as React from 'react';
import { Properties } from './properties/ContentPropertiesProperties';
import { FormGroup, Input, Label } from 'reactstrap';
import { RadioSelectionModel } from './model/RadioSelectionModel';

const ContentProperty: React.FunctionComponent<Properties> = (props) => {
	const onPropertySelect = (event: any) => {
		const propValue = event.target.value;
		const propChecked = event.target.checked;

		const selected: RadioSelectionModel = {
			key: propValue,
			checked: propChecked,
		};

		props.onPropertySelect(selected);
	};

	const { value, uniqueKey, t, name, isChecked } = props;

	return (
		<FormGroup key={`article-type-key-${uniqueKey}`} check className='radio'>
			<Input
				key={`article-type-key-input-${uniqueKey}`}
				className='form-check-input'
				type='checkbox'
				id={value}
				name='radios'
				value={value}
				onChange={onPropertySelect}
				checked={isChecked}
			/>
			<Label key={`article-type-key-label-${uniqueKey}`} check className='form-check-label' for={value}>
				{t(name)}
			</Label>
		</FormGroup>
	);
};

export default ContentProperty;
