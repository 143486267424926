import React from 'react';
import Select from 'react-select';
import { FormFeedback, Label } from 'reactstrap';
import { customOption } from '../shared/custom-select-option';
import {
	selectionsToPlayers,
	mostDecoratedPlayerToOption,
	mostDecoratedPlayersToOptions,
} from './helpers/most-decorated-player-select.helper';
import TeamModel from '../team-select/models/team.model';
import DecoratedPlayersWidgetModel from '../../blocks/widgets/most-decorated-players/models/decorated-players-widget.model';

type Properties = {
	onPlayersSelect: any;
	value: any;
	t: any;
	isMulti: boolean;
	decoratedPlayers: DecoratedPlayersWidgetModel;
};

export const MostDecoratedPlayerSelectContainer: React.FunctionComponent<Properties> = (props) => {
	const { onPlayersSelect, value, t, decoratedPlayers } = props;

	return (
		<>
			{props.isMulti ? (
				<>
					<Label>{t('pick_players_highlight')}:</Label>
					<Select
						id='most-decrated-highlight-players-select'
						options={mostDecoratedPlayersToOptions(decoratedPlayers.players)}
						value={mostDecoratedPlayersToOptions(value)}
						isMulti={true}
						placeholder={t('select')}
						formatOptionLabel={customOption}
						noOptionsMessage={(inputValue) => inputValue && t('no_options')}
						onChange={(selections: any) => onPlayersSelect(selectionsToPlayers(selections))}
					/>
				</>
			) : (
				<>
					<Label>{t('pick_player')}:</Label>
					<Select
						id='most-decorated-player-select'
						options={mostDecoratedPlayersToOptions(decoratedPlayers.players)}
						value={value && value.id ? mostDecoratedPlayerToOption(value) : []}
						isMulti={false}
						placeholder={t('select')}
						formatOptionLabel={customOption}
						isClearable={true}
						noOptionsMessage={(inputValue) => inputValue && t('no_options')}
						onChange={(selection: any) => onPlayersSelect(selection && selection.data)}
					/>
					<FormFeedback>
						{t('field_is_required')}
						{decoratedPlayers.offset && decoratedPlayers.offset.length > 0 && decoratedPlayers.player && !decoratedPlayers.player.id}
					</FormFeedback>
				</>
			)}
		</>
	);
};
