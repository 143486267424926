import EntityBuilder from './entity.builder';
import { EntityJson } from './entity.json';

export default class EntityModel {
	readonly id: string = '';
	readonly name: string = '';
	readonly image: string = '';
	readonly cityName: string = '';
	readonly country: any = {};
	readonly type: string = '';
	readonly birthdate: string = '';
	readonly shortName: string = '';

	private constructor(
		id: string,
		name: string,
		url: string,
		cityName: string,
		country: any,
		type: string,
		birthdate: string,
		shortName: string,
	) {
		this.id = id;
		this.name = name;
		this.image = url;
		this.cityName = cityName;
		this.country = country;
		this.type = type;
		this.birthdate = birthdate;
		this.shortName = shortName;
	}

	toJson(): EntityJson {
		return {
			id: this.id,
			name: this.name,
			image: this.image,
			cityName: this.cityName,
			country: this.country,
			type: this.type,
			birthdate: this.birthdate,
			shortName: this.shortName,
		};
	}

	static fromJSON(json: EntityJson): EntityModel {
		return new EntityModel(json.id, json.name, json.image, json.cityName, json.country, json.type, json.birthdate, json.shortName);
	}

	static builder(item?: EntityJson): EntityBuilder {
		return new EntityBuilder(item);
	}
}
