import { TreeItem } from 'react-sortable-tree';
import { mapCategoryToRowModel } from '../../../Partials/BaseComponents/ListingComponent/subcomponents/Rows/helpers/RowsContainerHelper';

export const extractParams = (movedNode: any) => {
	const parent_id = movedNode.nextParentNode !== null ? movedNode.nextParentNode.id : null;
	// New position of the movedNode
	const treeIndex = findIndexOfNode(movedNode.treeData, movedNode.node.id);

	// Construct params if node is moved inside its parent
	if (movedNode && movedNode.nextParentNode !== null) {
		let indexOfMoveNode = findIndexOfNode(movedNode.nextParentNode.children, movedNode.node.id);
		if (indexOfMoveNode === 0) {
			return {
				parent_id: movedNode.nextParentNode.id,
			};
		}
		return {
			parent_id: movedNode.nextParentNode.id,
			previous_id: movedNode.nextParentNode.children[indexOfMoveNode - 1].id,
		};
	}

	//Construct params if node is moved over its parent
	return {
		parent_id: parent_id,
		previous_id: treeIndex > 0 ? movedNode.treeData[treeIndex - 1].id : null,
	};
};

export const findIndexOfNode = (treeData: TreeItem[], nodeId: string) => {
	if (nodeId !== '' && treeData && treeData.length > 0) {
		return treeData.findIndex((item: TreeItem) => item.id === nodeId);
	}
	return -1;
};

export const extractLastChildIndexOfParentNode = (children: any[], movedNode: TreeItem) => {
	if (children && children.length > 0 && movedNode && movedNode.parentId) {
		return children.length - 1;
	}
	return null;
};

export const categoriesToTree = (categories: any[]) => {
	let map = {},
		node,
		roots: any[] = [],
		i;

	if (categories && categories.length > 0) {
		for (i = 0; i < categories.length; i += 1) {
			map[categories[i].id] = i; // initialize the map
			categories[i].children = []; // initialize the children
		}
		for (i = 0; i < categories.length; i += 1) {
			node = categories[i];
			if (node.parentId !== null) {
				node = mapCategoryToRowModel(node);
				categories[map[node.parentId]].children.push(node);
			} else {
				node = mapCategoryToRowModel(node);
				roots.push(node);
			}
		}
		return roots;
	}

	return roots;
};
