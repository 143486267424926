import { call, put, takeEvery } from 'redux-saga/effects';
import HttpService from '../../services/rest/HttpService';
import { toggleLoadingState, searchIsApplied } from '../action-creators/UiActionCreator';
import { onError } from '../action-creators/GeneralActions';
import {
	returnObjectForCoachListingReceived,
	returnObjectForCoachEntityCreateSuccess,
	returnObjectForCoachEntityUpdateSuccess,
	returnObjectForCoachEntityUpdateFailed,
	returnObjectForCoachEntityCreateFailed,
	returnObjectForCoachEntityReceived,
	coachAssetsPostSuccess,
	coachAssetsPostFailed,
	COACH_ENTITY_POST_ASSETS,
	COACH_LISTING_REQUEST,
	returnObjectForSearchCoachListingReceived,
	COACH_SEARCH,
	COACH_ENTITY_CREATE,
	COACH_ENTITY_REQUEST,
	COACH_ENTITY_UPDATE,
	returnObjectForCoachBlacklistReceived,
} from '../action-creators/coach-action-creator';
import { actionService } from '../../App';
import {
	remapCoachBlacklisted,
	constructRequestFromAssets,
	constructRequestFromEntry,
} from '../../views/Resources/manual-data-entries/subcomponents/helpers/manual-data.helper';

function* fetchCoaches(action: any) {
	yield put(toggleLoadingState(true));

	try {
		const coaches = yield call(HttpService.instanceFootball().get, `/v2/coaches?limit=20&offset=${(action.payload.page - 1) * 20}`);
		const coachesForBlacklist = constructRequestFromEntry(coaches.data.coaches, 'COACH', 'ENTITY');
		const blacklist = yield call(HttpService.getBlacklist, `/v2/blacklist/search`, coachesForBlacklist);
		yield put(returnObjectForCoachListingReceived(coaches));
		yield put(returnObjectForCoachBlacklistReceived(remapCoachBlacklisted(coaches.data.coaches, blacklist.data)));
		yield put(toggleLoadingState(false));
	} catch (error) {
		yield put(onError(error));
	}
}

function* fetchCoachEntityFromApi(action: any) {
	try {
		let headers = { Project: action.payload.project.domain };
		const coach = yield call(HttpService.instanceFootball().get, `/v2/coaches/${action.payload.id}`, null, headers);
		yield put(returnObjectForCoachEntityReceived(coach));
	} catch (error) {
		yield put(onError(error));
	}
}

function* searchCoaches(action: any) {
	yield put(toggleLoadingState(true));

	try {
		let text = action.payload.text;
		let lang = action.payload.lang;
		let type = action.payload.type;
		const coaches = yield call(HttpService.getFootballData, text, lang, type);
		yield put(returnObjectForSearchCoachListingReceived(coaches));
		yield put(toggleLoadingState(false));
		const coachesForBlacklist = constructRequestFromEntry(coaches.coaches, 'COACH', 'ENTITY');
		const blacklist = yield call(HttpService.getBlacklist, `/v2/blacklist/search`, coachesForBlacklist);
		yield put(returnObjectForCoachBlacklistReceived(remapCoachBlacklisted(coaches.coaches, blacklist.data)));
		yield put(searchIsApplied());
	} catch (error) {
		yield put(onError(error));
	}
}

function* postCoachEntityToApi(action: any) {
	let headers = { Project: action.payload.project.domain };
	let coachResponse: any = {};

	try {
		let coachPayload = action.payload.coach;
		coachResponse = yield call(HttpService.instanceFootball().post, '/v2/coaches', coachPayload, headers);

		let assets = constructRequestFromAssets(action.payload.assets, 'COACH', coachResponse.data.id);
		yield call(HttpService.instanceFootball().post, `/v2/assets/`, assets, headers);

		yield put(returnObjectForCoachEntityCreateSuccess(coachResponse.data.id));
	} catch (error) {
		yield put(returnObjectForCoachEntityCreateFailed());
		yield put(onError(error));
		actionService.emitError(error);
	}
}

function* patchCoachEntityToApi(action: any) {
	let headers = { Project: action.payload.project.domain };

	try {
		let coachPayload = action.payload.coach;
		let id = action.payload.coach.id;

		yield call(HttpService.instanceFootball().put, `/v2/coaches/${id}`, coachPayload, headers);
		yield put(returnObjectForCoachEntityUpdateSuccess());
	} catch (error) {
		yield put(returnObjectForCoachEntityUpdateFailed());
		yield put(onError(error));
		actionService.emitError(error);
	}
}
function* patchCoachAssets(action: any) {
	let headers = { Project: action.payload.project.domain };

	try {
		let assets = constructRequestFromAssets(action.payload.assets, 'COACH', action.payload.coachId);
		yield call(HttpService.instanceFootball().post, `/v2/assets/`, assets, headers);
		yield put(coachAssetsPostSuccess());
	} catch (error) {
		yield put(coachAssetsPostFailed());
		yield put(onError(error));
		actionService.emitError(error);
	}
}

function* coachSaga() {
	yield takeEvery(COACH_LISTING_REQUEST, fetchCoaches);
	yield takeEvery(COACH_SEARCH, searchCoaches);
	yield takeEvery(COACH_ENTITY_CREATE, postCoachEntityToApi);
	yield takeEvery(COACH_ENTITY_REQUEST, fetchCoachEntityFromApi);
	yield takeEvery(COACH_ENTITY_UPDATE, patchCoachEntityToApi);
	yield takeEvery(COACH_ENTITY_POST_ASSETS, patchCoachAssets);
}

export default coachSaga;
