import { assetsPlaceholder } from '../../../../constants/assetsPlaceholder';
import { TranslationEntities } from '../../../Resources/translations/helpers/translations.constants';

const setLabel = (data: any, type: string, t: any) => {
	if (type === TranslationEntities.TOURNAMENT && data.country && data.country.name) {
		return `${t(data.name)} ${`(${t(data.country.name)})`}`;
	}

	if (type === TranslationEntities.VENUE && data.cityName) {
		return `${t(data.name)} ${`(${t(data.cityName)})`}`;
	}

	if (type === TranslationEntities.PLAYER && data.birthdate) {
		return `${t(data.name)} ${`(${t(data.birthdate)})`}`;
	}

	return t(data.name);
};

const setLogo = (data: any, type: string) => {
	if (data && data.image) {
		return data.image;
	}

	if (data && data.assets && data.assets.thumb && data.assets.thumb.url && type === 'player') {
		return data.assets.thumb.url;
	}

	return assetsPlaceholder[type] ? assetsPlaceholder[type] : null;
};

export const itemToOption = (data: any, isMulti: boolean, t: any, type: string) => {
	if (data && data.id) {
		return { label: setLabel(data, type, t), value: data.id, data, logo: setLogo(data, type), type };
	}

	return isMulti ? {} : [];
};

export const itemsToOptions = (items: any[], isMulti: boolean, t: any, type: string) => {
	if (items && items.length > 0) {
		return items.map((item: any) => itemToOption(item, isMulti, t, type));
	}

	return [];
};

export const optionsToItems = (selection: any) => {
	if (selection && selection.length > 0) {
		return selection.map((item: any) => item.data);
	}

	return {};
};

export const optionToItem = (selection: any) => {
	if (selection && selection.data) {
		return selection.data;
	}

	return {};
};
