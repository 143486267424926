import TranslationTeamModel from '../models/translations-team.model';
import { entityTypeEnum, TranslationEntities } from './translations.constants';
import EnumItem from '../../../../models/enum/EnumItem';
import EntityModel from '../models/entity.model';

export const urlProp = {
	flag: 'url_flag',
	venue: 'url_flag',
	coach: 'url_thumb',
	player: 'url_thumb',
	team: 'url_logo',
	country: 'url_flag',
	tournament: 'url_logo',
	stage: 'url_logo',
	president: 'url_thumb',
	referee: 'url_thumb',
	city: 'url_image',
};

export const updateTranslationName = (translations: any, index: number, e: any) => {
	if (translations && translations.length > 0 && translations[index]) {
		const newTranslations = [...translations];
		newTranslations[index].content.name = e.target.value;

		return newTranslations;
	}

	return [];
};

export const updateTranslationAbbreviation = (translations: any, index: number, e: any) => {
	if (translations && translations.length > 0 && translations[index]) {
		const newTranslations = [...translations];
		newTranslations[index].content.three_letter_code = e.target.value === '' ? null : e.target.value;
		return newTranslations;
	}

	return [];
};

export const updateTranslationShortName = (translations: any, index: number, e: any) => {
	if (translations && translations.length > 0 && translations[index]) {
		const newTranslations = [...translations];
		newTranslations[index].content.short_name = e.target.value === '' ? null : e.target.value;

		return newTranslations;
	}

	return [];
};

export const updateTranslationLang = (translations: any[], language: EnumItem) => {
	if (translations && translations.length > 0) {
		return translations.map((translation: any) => {
			const newTranslation = { ...translation };
			translation.key.language = language.id;

			return newTranslation;
		});
	}

	return [];
};

export const mapMultipleTranslations = (items: any[], language: EnumItem, entityType: string) => {
	if (items && items.length > 0) {
		return items.map((item: any) => mapTranslation(item, language, entityType));
	}

	return [];
};

export const mapTeamTranslations = (team: TranslationTeamModel, language: EnumItem) => {
	return Object.keys(team)
		.filter((key: string) => team[key].id !== undefined)
		.map((key: string) => mapTranslation(team[key], language, team[key].entityType));
};

export const mapTranslation = (item: any, language: EnumItem, entityType: string) => {
	return {
		key: {
			entity: entityType,
			entity_id: item.id,
			language: language.id,
		},
		content:
			entityType === entityTypeEnum.TEAM
				? {
						name: '',
						three_letter_code: null,
						short_name: null,
				  }
				: {
						name: '',
				  },
	};
};

export const remapTranslationTeamFromResponse = (team: any, logo?: string) => {
	return TranslationTeamModel.builder()
		.withTeam({
			id: team.id,
			name: team.name,
			logo: logo ? logo : team.url_logo,
			threeLetterCode: team.three_letter_code ? team.three_letter_code : team.threeLetterCode,
			entityType: entityTypeEnum.TEAM,
			shortName: team.short_name ? team.short_name : team.shortName,
		})
		.withCoach({ ...team.coach, ...{ entityType: entityTypeEnum.COACH } })
		.withVenue({ ...team.venue, ...{ entityType: entityTypeEnum.VENUE } })
		.withReferee({ ...team.referee, ...{ entityType: entityTypeEnum.REFEREE } })
		.withCountry({ ...team.country, ...{ entityType: entityTypeEnum.COUNTRY } })
		.build();
};

export const remapTranslationTypeFromResponse = (response: any) => {
	if (response && response.data) {
		return response.data.map((entity: any) => EnumItem.builder().withId(entity.entity).withName(TranslationEntities[entity.entity]).build());
	}

	return [];
};

const defineCityLabel = (city: any) => {
	if (city && !city.name) {
		return city;
	} else if (city && city.name) {
		return city.name;
	}

	return '';
};

export const remapEntitiesFromResponse = (data: any, entityType: string) => {
	if (data && data.length > 0) {
		return data.map((item: any) => remapEntityFromResponse(item, entityType));
	}

	return [];
};

export const remapEntityFromResponse = (item: any, entityType: string) => {
	if (item) {
		const image = entityType === TranslationEntities.VENUE && item.country ? item.country[urlProp[entityType]] : item[urlProp[entityType]];
		return EntityModel.builder()
			.withId(item.id)
			.withName(item.name)
			.withImage(image, entityType)
			.withCityName(defineCityLabel(item.city))
			.withCountry(item.country ? item.country : null)
			.withType(item.entity_type)
			.withBirthdate(item.birthdate)
			.withShortName(item.short_name)
			.build();
	}

	return EntityModel.builder().build();
};

export const orderTranslationSearchResponse = (mappedEntities: any, response: any) => {
	return mappedEntities != null
		? mappedEntities.map((translation: any) => {
				const newTranslation = translation;
				const translationKeyEntityId = typeof translation.key.entity_id === 'string' ? +translation.key.entity_id : translation.key.entity_id;

				response.data.map((responseTranslation: any) => {
					if (
						translationKeyEntityId === Number(responseTranslation.key.entity_id) &&
						translation.key.entity === responseTranslation.key.entity
					) {
						newTranslation.content.name = responseTranslation.content.name;

						if (translation.key.entity === entityTypeEnum.TEAM) {
							newTranslation.content.three_letter_code =
								responseTranslation.content.three_letter_code !== null ? responseTranslation.content.three_letter_code : null;
							newTranslation.content.short_name = responseTranslation.content.short_name !== null ? responseTranslation.content.short_name : null;
						}
					}
				});

				return newTranslation;
		  })
		: [];
};

export const orderTranslationSearchOtherResponse = (mappedEntities: any, response: any) => {
	return mappedEntities !== null
		? mappedEntities.map((translation: any) => {
				const newTranslation = translation;
				response.data.map((responseTranslation: any) => {
					if (translation.key.entity_id === responseTranslation.key.entity_id && translation.key.entity === responseTranslation.key.entity) {
						newTranslation.content.name = responseTranslation.content.name;
					}
				});

				return newTranslation;
		  })
		: [];
};

export const filterPlayerTranslations = (translations: any[], entityType: string) => {
	if (translations && translations.length > 0) {
		return translations.filter((translation: any) => translation.key.entity === entityType);
	}

	return [];
};

export const filterOtherTranslations = (translations: any[], entityType: string) => {
	if (translations && translations.length > 0) {
		return translations.filter((translation: any) => translation.key.entity !== entityType);
	}

	return [];
};

export const extractNotEmptyTranslations = (translations: any) => {
	if (translations && translations.length > 0) {
		return translations.filter((translation: any) => translation.content && translation.content.name.trim().length > 0);
	}

	return [];
};

export const areTranslationsEmpty = (translations: any[]) => {
	return (
		translations &&
		translations.length > 0 &&
		translations.filter((translation: any) => translation.content.name.trim().length > 0).length === 0
	);
};
