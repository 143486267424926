import React from 'react';
import { Label, InputGroup, InputGroupAddon, Button, Row } from 'reactstrap';
import HttpService from '../../../../../services/rest/HttpService';
import VideoAutoLinking from '../video-autolinking/video-autolink';
import { featuresService } from '../../../../../App';
import { FeatureTypes } from '../../../../../services/feature-service/features.enum';
import SuggestedEntitiesModel from '../../../../../models/suggested-entities/suggested-entities.model';
import YoutubeVideoModel from '../../../../../models/youtube-sports/youtube-video/youtube-video.model';
import { remapYoutubeVideosFromResponse } from './helpers/youtube-video-select.helper';
import { DebounceInput } from 'react-debounce-input';
import { toast } from 'react-toastify';

export type Properties = {
	onSelect: (video: any, title: string) => any;
	t: any;
	toggleLoadingState: (loading: boolean) => any;
	searchText?: string;
	onSelectedYoutubeVideoChange: (video: YoutubeVideoModel) => any;
	linkedTags: SuggestedEntitiesModel[];
	pageToken: string;
};

export type State = {
	contentSearchInput: string;
};

export default class YoutubeVideoSelect extends React.Component<Properties, State> {
	youtubeSportsConfig = featuresService.getFeatureConfig(FeatureTypes.YOUTUBE_SPORTS);

	constructor(props: Properties) {
		super(props);
		this.state = {
			contentSearchInput: props.searchText && props.searchText.length > 0 ? props.searchText : '',
		};
	}

	componentDidMount() {
		const { searchText, pageToken } = this.props;

		if (searchText && searchText.length > 0) {
			this.searchYoutubeVideos(searchText, pageToken);
		}
	}

	componentDidUpdate(prevProps: Readonly<Properties>) {
		const { contentSearchInput } = this.state;
		const { pageToken } = this.props;

		if (pageToken && prevProps.pageToken !== pageToken && contentSearchInput.length > 0) {
			this.searchYoutubeVideos(contentSearchInput.trim(), pageToken);
		}
	}

	onContentSearchInput = (event: any) => {
		this.onVideoInputChangeState(event.target.value);

		if (event.target.value.length < 1) {
			this.props.onSelect([], '');
			this.props.onSelectedYoutubeVideoChange(YoutubeVideoModel.builder().build());
		}
	};

	onContentSearch = (event: any) => {
		if (event.keyCode === 13 && this.state.contentSearchInput.trim().length > 0) {
			this.searchYoutubeVideos(this.state.contentSearchInput, this.props.pageToken);
		}
	};

	clearSearch = () => {
		this.onVideoInputChangeState('');
		this.props.onSelect([], '');
		this.props.onSelectedYoutubeVideoChange(YoutubeVideoModel.builder().build());
	};

	searchYoutubeVideos(search: string, pageToken: string) {
		this.props.toggleLoadingState(true);
		HttpService.getYoutubeSportsVideos(
			'https://www.googleapis.com/youtube/v3/search',
			`?part=snippet&q=${search}&type=video&videoCategoryId=17&key=${this.youtubeSportsConfig.key}${
				pageToken && pageToken.length > 0 ? `&pageToken=${pageToken}` : ''
			}`,
		)
			.then((response: any) => {
				this.props.toggleLoadingState(false);
				this.props.onSelect(remapYoutubeVideosFromResponse(response.data), search);
			})
			.catch((e: any) => toast.error(e.message));
	}

	onVideoInputChangeState = (input: string) => {
		this.setState({ ...this.state, contentSearchInput: input });

		return input;
	};

	updateSearchWithTag = (input: string) => {
		const search = this.state.contentSearchInput + ' ' + input;
		this.setState({ ...this.state, contentSearchInput: search });
		this.searchYoutubeVideos(search, this.props.pageToken);
	};

	render() {
		const { t, linkedTags, pageToken } = this.props;
		const { contentSearchInput } = this.state;

		return (
			<>
				<Label>{t('choose_video')}:</Label>
				<InputGroup className='position-relative d-flex flex-direction-row flex-nowrap'>
					<InputGroup className='position-relative d-flex'>
						<DebounceInput
							type='text'
							id='search-dugout-input'
							className='form-control rounded-0 pr-4'
							placeholder={t('content_search_placeholder')}
							onChange={this.onContentSearchInput}
							onKeyDown={this.onContentSearch}
							debounceTimeout={500}
							value={contentSearchInput}
						/>
						{contentSearchInput && contentSearchInput.length > 0 && (
							<div onClick={this.clearSearch} className='p-2 text-muted position-absolute' style={{ top: 0, right: 0 }}>
								<i className='fa fa-times' />
							</div>
						)}
					</InputGroup>
					<InputGroupAddon addonType='append'>
						<Button
							id='search-youtube-video'
							type='button'
							color='primary'
							disabled={contentSearchInput.trim().length === 0}
							onClick={() => this.searchYoutubeVideos(contentSearchInput, pageToken)}
						>
							<i className={'fa fa-search'}>&nbsp;</i>
							{t('search')}
						</Button>
					</InputGroupAddon>
				</InputGroup>
				<Row className={'m-0 mt-2'}>
					<VideoAutoLinking featureType={FeatureTypes.YOUTUBE_SPORTS} updateSearchWithTag={this.updateSearchWithTag} linkedTags={linkedTags} />
				</Row>
			</>
		);
	}
}
