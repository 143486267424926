import { getTotalWordCharCountPerBlock } from '../../models/helpers/content-helpers';

export default class WordCountService {
	calculateTotalWordCharCount(contentBody: any) {
		let sumWordsAllBlocks = 0;
		let sumCharsAllBlocks = 0;

		//sum the total words and chars by iterating through all editor blocks within the article/video/gallery
		contentBody &&
			contentBody.map((block: any) => {
				let data: any = getTotalWordCharCountPerBlock(block);
				sumWordsAllBlocks += data.wordCount;
				sumCharsAllBlocks += data.charCount;
			});

		return { totalWordCount: sumWordsAllBlocks, totalCharCount: sumCharsAllBlocks };
	}
}
