import React, { useState } from 'react';
import { Col, Label, Row } from 'reactstrap';
import VideoUrls from '../../../../../../../models/urls/video-urls/video-urls';
import '../subcomponents/preview-video-modal/video-play.scss';
import MainMedia from '../../../../../../../models/mainmedia/MainMedia';
import { noPreviewImage } from '../../../../../../../constants/resource-constants';
import { buildResizedIframe, extractVideoType } from '../subcomponents/helpers/preview-content.helper';
import VideoElement from '../../../../../Sidebar/Urls/subcomponents/video-urls/video-element.component';

type Properties = {
	videoUrls: VideoUrls[];
	mainMedia: MainMedia[];
	t: any;
};
const styleObj = {
	maxWidth: '200px',
	maxHeight: '112px',
	overflow: 'auto',
};

const PreviewContent: React.FunctionComponent<Properties> = (props) => {
	const { videoUrls, mainMedia, t } = props;

	const extractMainMadiaEmbed = () => {
		if (mainMedia && mainMedia.length > 0) {
			return mainMedia.filter((item: any) => item && item.resource_type && item.resource_type === 'embed');
		}
		return [];
	};

	const mainMediaEmbed = extractMainMadiaEmbed();

	return (
		<div className='video-preview-content'>
			{videoUrls && videoUrls[0] ? (
				<Row className='mb-2'>
					<Col>
						<Label>{t('preview_video_url')}:</Label>
						<div className='d-flex'>
							<VideoElement url={videoUrls[0].url} videoType={extractVideoType(videoUrls[0].url)} />
						</div>
					</Col>
				</Row>
			) : (
				mainMedia &&
				mainMedia.length > 0 && (
					<Row className='mb-2'>
						<Col>
							<Label>{t('embed_video_title')}:</Label>
							<div className='d-flex'>
								<div
									dangerouslySetInnerHTML={{
										__html: mainMediaEmbed && mainMediaEmbed.length > 0 ? buildResizedIframe(mainMediaEmbed[0]) : `<img src=${noPreviewImage} />`,
									}}
									style={styleObj}
								/>
							</div>
						</Col>
					</Row>
				)
			)}
		</div>
	);
};

export default PreviewContent;
