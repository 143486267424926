import React, { FunctionComponent } from 'react';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import { DATA_QA_ATTRIBUTES } from '../../../../../Partials/Sidebar/general-content/constants/data-qa.attributes';
import ErrorAsync from '../../../../../Partials/BaseComponents/ErrorComponent/ErrorAsync';
import LiveBlogModel from '../../../models/live-blog.model';
import DateTimeSelect from '../../../../../Partials/Sidebar/general-content/subcomponents/date-time/date-time-select';
import moment from 'moment';

type Properties = {
	t: any;
	liveBlog: LiveBlogModel;
	setLiveBlogStartTime: any;
};

export const LiveBlogStartTimeSelect: FunctionComponent<Properties> = ({ t, setLiveBlogStartTime, liveBlog }) => {
	const onStartTimeSelect = (dates: Date[] | null) => {
		const selectedDate = dates && dates.length >= 0 ? dates[0] : '';
		setLiveBlogStartTime(moment(selectedDate).toISOString());
	};
	return (
		<Row className='mb-3'>
			<Col>
				<FormGroup>
					<Label htmlFor={DATA_QA_ATTRIBUTES.LIVE_BLOG_START_TIME}>
						<strong>{t('start_time')}</strong>
					</Label>
					<span className='text-danger'>*</span>

					<DateTimeSelect
						t={t}
						date={liveBlog.start_time ? liveBlog.start_time : ''}
						onChange={onStartTimeSelect}
						id={DATA_QA_ATTRIBUTES.LIVE_BLOG_START_TIME}
					/>
					<ErrorAsync t={t} errorType='date_id' />
				</FormGroup>
			</Col>
		</Row>
	);
};
