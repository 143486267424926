import React, { FunctionComponent } from 'react';
import { Col, Label, Row } from 'reactstrap';
import { assetsPlaceholder } from '../../../../../../../constants/assetsPlaceholder';
import MainMedia from '../../../../../../../models/mainmedia/MainMedia';
import { useTranslation } from 'react-i18next';
import '../styles/main-media-images.style.scss';
import { DATA_QA_ATTRIBUTES } from '../helpers/main-media-additional-items.helper';

type Properties = {
	item: MainMedia;
	handleDeleteRow: (id: string, item: MainMedia) => void;
	setToggle: Function;
	index: number;
	setSelectedItemForEdit: (item: MainMedia, newItem: boolean) => void;
};

export const MainMediaAdditionalItemInfoCard: FunctionComponent<Properties> = ({
	item,
	handleDeleteRow,
	index,
	setToggle,
	setSelectedItemForEdit,
}) => {
	const [t] = useTranslation();
	const placeholder = assetsPlaceholder.imagePlaceholder;
	const thumbImage = item && item.data && item.data.urls && item.data.urls.uploaded ? item.data.urls.uploaded.thumbnail : placeholder;

	return (
		<div className='card-body' id={DATA_QA_ATTRIBUTES.MAIN_MEDIA_ADDITIONAL_IMAGES_LIST}>
			<Row>
				<Col>
					{item.resourceId && (
						<div id={`${item.resourceId}-${index + 1}`}>
							<Row>
								<Col col='12' sm='12' md='6' className='max-width-100'>
									<img
										className='additional-image mt-2'
										src={thumbImage}
										onError={(i: any) => (i.target.src = assetsPlaceholder.imagePlaceholder)}
										alt={item.resourceId ? item.resourceId : ''}
									/>
								</Col>
								<Col col='12' sm='12' md='6' className='max-width-300'>
									<Row className='mt-3'>
										<Label className='mb-1 flex-row'>
											<strong>{t('type')}:</strong>
										</Label>
										<span className='ml-1'>{t(`${item.resourceSubtype}`)}</span>
									</Row>
									{item.description && item.description.length > 0 && (
										<Row className='main-media-additional-image-info-card-row-description'>
											<Label className='mb-1 flex-row'>
												<strong>{t('description')}:</strong>
											</Label>
											<span className='ml-1 max-width-300'>{item.description}</span>
										</Row>
									)}
								</Col>
							</Row>
						</div>
					)}
				</Col>
				<Col md={1}>
					<div
						className='cursor-pointer'
						onClick={() => {
							setSelectedItemForEdit(item, false);
							setToggle(true);
						}}
					>
						<i className='fa fa-pen p-1' />
					</div>
				</Col>
				<Col md={1}>
					<div className='cursor-pointer' onClick={() => handleDeleteRow(item.resourceId, item)}>
						<i className='fa fa-trash-can p-1' />
					</div>
				</Col>
			</Row>
		</div>
	);
};
