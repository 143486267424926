import { call, put, takeEvery } from 'redux-saga/effects';
import HttpService from '../../services/rest/HttpService';
import { MATCH_LINEUPS_REQUEST, updateMatchLineup } from '../action-creators/match-lineups-action-creator';
import { remapMatchDetailsFromResponse } from './../../views/Resources/match-lineups/subcomponents/helpers/response-remappers.helper';

function* fetchMatchLineup(action: any) {
	try {
		const match = yield call(HttpService.instanceFootball().get, `/v2/matches/${action.payload.id}`);
		yield put(updateMatchLineup(remapMatchDetailsFromResponse(match.data)));
	} catch (error) {}
}

function* matchSaga() {
	yield takeEvery(MATCH_LINEUPS_REQUEST, fetchMatchLineup);
}

export default matchSaga;
