import React, { useEffect, useState, FunctionComponent } from 'react';
import { Button, Col, FormGroup, Label, Row } from 'reactstrap';
import DateTimePickerContainer from '../../../GeneralContentAttributes/subcomponents/DateTimePicker/DateTimePickerContainer';
import Select from 'react-select';
import HttpService from '../../../../../../services/rest/HttpService';
import { seasonsToOptions, tournamentToOptions } from '../../helpers/season-select';
import { store } from '../../../../../../store/store';
import { checkAreFiltersSelected } from '../../helpers/events.helper';
import { SportTypes, SportTypesValue } from '../../../../../../constants/sport.types';
import { ValueType } from 'react-select/src/types';
import TournamentResponseModel from '../../../../../../models/v2/Tournament/Entity/response-tournament.model';
import { responseToResponseTournamentModel } from '../../../../../../models/v2/Tournament/Entity/response-tournament.mapper';
import { generateCustomOptionWithImage } from '../../helpers/utils';
import { DATA_QA_ATTRIBUTES } from '../../constants/data-qa';
import SeasonResponseModel from '../../../../../../models/v2/Season/Entity/response-season.model';
import { responseToResponseSeasonModel } from '../../../../../../models/v2/Season/Entity/response-season.mapper';
import SportsTypesModel from '../../../../../../models/v2/sports-types/sports-types.model';

export type Properties = {
	t: any;
	selectedSport: SportsTypesModel;
	tournament: TournamentResponseModel | null;
	season: SeasonResponseModel | null;
	dateFrom: string;
	dateTo: string;
	setTournament: Function;
	setSeason: Function;
	setDateFrom: Function;
	setDateTo: Function;
};

const EventsFilters: FunctionComponent<Properties> = ({
	t,
	selectedSport,
	tournament,
	season,
	dateFrom,
	dateTo,
	setTournament,
	setSeason,
	setDateFrom,
	setDateTo,
}) => {
	const [showFilters, toggleShowFilters] = useState(false);
	const iconStyle = `${showFilters ? 'fa-angle-down' : 'fa-angle-left'} text-muted fa fa-2x p-2`;
	const [loadedTournaments, setLoadedTournaments] = useState<TournamentResponseModel[] | null>(null);
	const [loadedSeasons, setLoadedSeasons] = useState<SeasonResponseModel[] | null>(null);

	const resetForm = () => {
		setTournament(null);
		setSeason(null);
		setDateFrom('');
		setDateTo('');
	};

	useEffect(() => {
		const tournamentOrder = store.getState().project.currentProject.defaultTournamentOrder || '';
		HttpService.getTournaments(tournamentOrder)
			.then((response: any) => {
				const tournamentsData: Array<any> = response.data;
				const tournamentsDataToModel: TournamentResponseModel[] = tournamentsData.map((tournament: any) =>
					responseToResponseTournamentModel(tournament),
				);
				setLoadedTournaments(tournamentsDataToModel);
			})
			.catch((error: unknown) => console.error(error));
	}, []);

	const onTournamentChange = (tournament: ValueType<any>) => {
		setTournament(tournament);
		if (tournament) {
			HttpService.getTournamentById(tournament.id)
				.then((response: any) => {
					const seasonsResponseData = response.data.seasons;
					const loadedSeasonsToModel: SeasonResponseModel[] = seasonsResponseData.map((season: any) => responseToResponseSeasonModel(season));
					setLoadedSeasons(loadedSeasonsToModel);
					setSeason((loadedSeasonsToModel && loadedSeasonsToModel[0]) || null);
				})
				.catch((e: any) => e);
		}
	};

	return (
		<>
			<Label id='show-match-list-filters' className='d-flex w-100 m-0 align-items-center pl-2 border rounded mb-2' htmlFor='filter-matches'>
				<span className='mr-auto'>{t('filter_matches')}</span>
				{!showFilters && checkAreFiltersSelected(tournament, season, dateFrom, dateTo) && (
					<div className='animated fadeIn text-warning px-2'>
						<Button color='danger' onClick={resetForm} outline size='sm'>
							{t('clear_filters')}
						</Button>
					</div>
				)}
				<div className='cursor-pointer' onClick={() => toggleShowFilters(!showFilters)}>
					<i style={{ opacity: 0.4 }} className={iconStyle} />
				</div>
			</Label>
			{showFilters && (
				<div className='p-2 border rounded mb-2 animated fadeIn'>
					{selectedSport.sport === SportTypes.FOOTBALL && (
						<>
							<FormGroup>
								<Label htmlFor={DATA_QA_ATTRIBUTES.TOURNAMENT_FILTER_SELECT}> {t('tournament')} </Label>
								<Select
									id={DATA_QA_ATTRIBUTES.TOURNAMENT_FILTER_SELECT}
									placeholder={t('select')}
									formatOptionLabel={(option) =>
										generateCustomOptionWithImage(option.label, option.value, option.data.url_logo, option.data.entity_type || '')
									}
									options={tournamentToOptions(loadedTournaments)}
									value={tournament && tournamentToOptions([tournament])}
									onChange={(selection: any) => onTournamentChange(selection.data)}
								/>
							</FormGroup>
							{tournament && (
								<FormGroup>
									<Label htmlFor={DATA_QA_ATTRIBUTES.TOURNAMENT_SEASON_FILTER_SELECT}> {t('season')} </Label>
									<Select
										id={DATA_QA_ATTRIBUTES.TOURNAMENT_SEASON_FILTER_SELECT}
										placeholder={t('select')}
										options={seasonsToOptions(loadedSeasons)}
										value={season && seasonsToOptions([season])}
										onChange={(selection: any) => setSeason(selection.data)}
									/>
								</FormGroup>
							)}
						</>
					)}
					<Row>
						<Col>
							<FormGroup>
								<Label htmlFor={DATA_QA_ATTRIBUTES.DATE_FROM_FILTER_SELECT}>{t('date_from')}:</Label>
								<DateTimePickerContainer
									id={DATA_QA_ATTRIBUTES.DATE_FROM_FILTER_SELECT}
									dateFormat='Y.m.d'
									resetButton
									date={dateFrom}
									t={t}
									enableTime={false}
									placeholder={t('select_date')}
									onDateChange={setDateFrom}
								/>
							</FormGroup>
						</Col>
						<Col>
							<FormGroup>
								<Label htmlFor={DATA_QA_ATTRIBUTES.DATE_TO_FILTER_SELECT}>{t('date_to')}:</Label>
								<DateTimePickerContainer
									id={DATA_QA_ATTRIBUTES.DATE_TO_FILTER_SELECT}
									dateFormat='Y.m.d'
									resetButton
									date={dateTo}
									t={t}
									enableTime={false}
									placeholder={t('select_date')}
									onDateChange={setDateTo}
								/>
							</FormGroup>
						</Col>
					</Row>
					<Row className='d-flex justify-content-end p-2'>
						<Button className='ml-2' id='clear-tag-matches-filters' onClick={resetForm} color='danger' outline>
							{t('clear_filters')}
						</Button>
					</Row>
				</div>
			)}
		</>
	);
};

export default EventsFilters;
