import Project from '../../models/project/Project';
import ErrorResponseModel from '../../models/error-response/ErrorResponseModel';
import Gallery from '../../models/gallery/Gallery';
import Related from '../../models/related/Related';
import List from '../../models/list/List';
import PaginationMeta from '../../models/pagination/PaginationMeta';

export const GALLERIES_REQUEST = '[ACTION] GALLERIES_REQUEST';
export const GALLERIES_RECEIVED = '[ACTION] GALLERIES_LOADED';
export const GALLERIES_FAILED = '[ACTION] GALLERIES_FAILED';

export const GALLERY_REQUEST = '[ACTION] GALLERY_REQUEST';
export const GALLERY_RECEIVED = '[ACTION] GALLERY_RECEIVED';
export const GALLERY_REQUEST_FAILED = '[ACTION] GALLERY_REQUEST_FAILED';

export const GALLERY_RELATED_RECEIVED = '[ACTION] GALLERY_RELATED_RECEIVED';
export const GALLERY_COPY_RELATED_RECEIVED = '[ACTION] GALLERY_COPY_RELATED_RECEIVED';
export const GALLERY_RELATED_REQUEST_FAILED = '[ACTION] GALLERY_RELATED_REQUEST_FAILED';

export const GALLERY_SEARCH = '[ACTION] GALLERY_SEARCH';
export const GALLERY_SEARCH_FAILED = '[ACTION] GALLERY_SEARCH_FAILED';

export const GALLERY_CREATE = '[ACTION] GALLERY_CREATE';
export const GALLERY_CREATE_SUCCESS = '[ACTION] GALLERY_CREATE_SUCCESS';
export const GALLERY_CREATE_FAILED = '[ACTION] GALLERY_CREATE_FAILED';

export const GALLERY_RELATED_CREATE_SUCCESS = '[ACTION] GALLERY_RELATED_CREATE_SUCCESS';

export const GALLERY_UPDATE = '[ACTION] GALLERY_UPDATE';
export const GALLERY_UPDATE_SUCCESS = '[ACTION] GALLERY_UPDATE_SUCCESS';
export const GALLERY_UPDATE_FAILED = '[ACTION] GALLERY_UPDATE_FAILED';

export const GALLERY_RELATED_UPDATE_SUCCESS = '[ACTION] GALLERY_RELATED_UPDATE_SUCCESS';
export const GALLERY_RELATED_UPDATE_FAILED = '[ACTION] GALLERY_RELATED_UPDATE_FAILED';

export const GALLERY_DELETE = '[ACTION] GALLERY_DELETE';
export const GALLERY_DELETE_SUCCESS = '[ACTION] GALLERY_DELETE_SUCCESS';
export const GALLERY_DELETE_FAILED = '[ACTION] GALLERY_DELETE_FAILED';

export const GALLERIES_CONTENT_STATISTICS_RECEIVED = '[ACTION] GALLERIES_CONTENT_STATISTICS_RECEIVED';
export const CONTENT_STATISTICS_GALLERY_ENTITY_RECEIVED = '[ACTION] CONTENT_STATISTICS_GALLERY_ENTITY_RECEIVED';

export const GALLERY_PAGINATION_UPDATE = '[ACTION] GALLERY_PAGINATION_UPDATE';

export const GALLERY_FILTERS_UPDATE = '[ACTION] GALLERY_FILTERS_UPDATE';
export const GALLERY_ALREADY_EXISTS = '[ACTION] GALLERY_ALREADY_EXISTS';

export const GALLERY_CREATE_RESOURCES_SUCCESS = '[ACTION] GALLERY_CREATE_RESOURCES_SUCCESS';

export function galleriesRequest(page: string, project: Project, text: string) {
	return {
		type: GALLERIES_REQUEST,
		payload: { page, project, text },
	};
}

export function returnObjectForGalleryPaginationUpdate(pagination: PaginationMeta) {
	return {
		type: GALLERY_PAGINATION_UPDATE,
		payload: { pagination },
	};
}

export function galleriesReceived(galleries: any) {
	return {
		type: GALLERIES_RECEIVED,
		payload: galleries,
	};
}

export function galleriesFailed(error: any) {
	return {
		type: GALLERIES_FAILED,
		payload: ErrorResponseModel.builder().fromResponse(error).build(),
	};
}

export function galleryCreate(gallery: Gallery, related: Related[], project: Project, list: null | List = null) {
	return {
		type: GALLERY_CREATE,
		payload: { gallery, project, related, list },
	};
}

export function galleryCreateSuccess(id: string) {
	return {
		type: GALLERY_CREATE_SUCCESS,
		payload: id,
	};
}

export function galleryRelatedCreateSuccess(id: string) {
	return {
		type: GALLERY_RELATED_CREATE_SUCCESS,
		payload: id,
	};
}

export function galleryCreateFailed() {
	return {
		type: GALLERY_CREATE_FAILED,
	};
}

export function gallerySearch(text: string, project: Project) {
	return {
		type: GALLERY_SEARCH,
		payload: { text, project },
	};
}

export function gallerySearchFailed(error: any) {
	return {
		type: GALLERY_SEARCH_FAILED,
		payload: ErrorResponseModel.builder().fromResponse(error).build(),
	};
}

export function galleryDelete(id: string, project: Project) {
	return {
		type: GALLERY_DELETE,
		payload: { id, project },
	};
}

export function galleryDeleteSuccess() {
	return {
		type: GALLERY_DELETE_SUCCESS,
	};
}

export function galleryDeleteFailed() {
	return {
		type: GALLERY_DELETE_FAILED,
	};
}

export function galleryRequest(id: string, project: Project) {
	return {
		type: GALLERY_REQUEST,
		payload: { id, project },
	};
}

export function galleryReceived(gallery: any) {
	return {
		type: GALLERY_RECEIVED,
		payload: gallery,
	};
}

export function galleryRequestFailed(gallery: any) {
	return {
		type: GALLERY_REQUEST_FAILED,
		payload: gallery,
	};
}

export function galleryRelatedReceived(related: any, sports: any) {
	return {
		type: GALLERY_RELATED_RECEIVED,
		payload: { related, sports },
	};
}

export function galleryCopyRelatedReceived(related: any, sports: any) {
	return {
		type: GALLERY_COPY_RELATED_RECEIVED,
		payload: { related, sports },
	};
}

export function galleryRelatedRequestFailed(related: any) {
	return {
		type: GALLERY_RELATED_REQUEST_FAILED,
		payload: related,
	};
}

export function galleryUpdate(gallery: Gallery, related: Related[], project: Project) {
	return {
		type: GALLERY_UPDATE,
		payload: { gallery, project, related },
	};
}

export function galleryUpdateSuccess() {
	return {
		type: GALLERY_UPDATE_SUCCESS,
	};
}

export function galleryUpdateFailed() {
	return {
		type: GALLERY_UPDATE_FAILED,
	};
}

export function galleryRelatedUpdateSuccess() {
	return {
		type: GALLERY_RELATED_UPDATE_SUCCESS,
	};
}

export function galleryRelatedUpdateFailed() {
	return {
		type: GALLERY_RELATED_UPDATE_FAILED,
	};
}

export function returnObjectForGalleriesContentStatisticsReceived(galleries: Gallery[], contentStatistics: any) {
	return {
		type: GALLERIES_CONTENT_STATISTICS_RECEIVED,
		payload: { galleries, contentStatistics },
	};
}

export function returnObjectForContentStatisticsGalleryEntityReceived(gallery: Gallery, contentStatistics: any) {
	return {
		type: CONTENT_STATISTICS_GALLERY_ENTITY_RECEIVED,
		payload: { gallery, contentStatistics },
	};
}

export function returnObjectForGalleryFiltersUpdate(filters: any) {
	return {
		type: GALLERY_FILTERS_UPDATE,
		payload: { filters },
	};
}

export function returnObjectForGalleryAlreadyExists() {
	return {
		type: GALLERY_ALREADY_EXISTS,
	};
}

export function triggerGalleryCreateResourcesSuccess(id: number) {
	return {
		type: GALLERY_CREATE_RESOURCES_SUCCESS,
		payload: id,
	};
}
