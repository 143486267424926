import { TotalsJson } from './totals.json';
import Totals from './totals';
import { TotalsRequestJson } from './totals-request.json';

export default class TotalsBuilder {
	private json: TotalsJson;

	constructor(totals?: Totals | TotalsJson) {
		if (totals && totals instanceof Totals) {
			this.json = totals.toJSON();
		} else if (totals) {
			this.json = totals;
		} else {
			this.json = {} as TotalsJson;
		}
	}

	withImages(images: number): TotalsBuilder {
		this.json.images = images;

		return this;
	}

	withVideos(videos: number): TotalsBuilder {
		this.json.videos = videos;

		return this;
	}

	withGalleries(galleries: number): TotalsBuilder {
		this.json.galleries = galleries;

		return this;
	}

	withArticles(articles: number): TotalsBuilder {
		this.json.articles = articles;

		return this;
	}

	build(): Totals {
		return Totals.fromJSON(this.json);
	}

	toRequestJson(): TotalsRequestJson {
		let json = {} as TotalsRequestJson;
		json.images = this.json.images;
		json.videos = this.json.videos;
		json.galleries = this.json.galleries;
		json.articles = this.json.articles;

		return json;
	}
}
