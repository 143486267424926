import React, { FunctionComponent, useEffect, useState } from 'react';
import './search-sport-extended.scss';
import SearchInputDesign from '../search/search';
import SportsConnectionsHttpService from '../../Shared/advanced-content-filter/subcomponents/v2/services/sports-connections-http.service';
import { responseToMultisportEvent } from '../../../Pages/Multisport-widget/models/event.mapper';
import MultisportEventModel from '../../../Pages/Multisport-widget/models/event.model';
import EventsSearchOutput from './sport-entity';
import { featuresService } from '../../../../App';
import { FeatureTypes } from '../../../../services/feature-service/features.enum';
import { toast } from 'react-toastify';
import i18n from '../../../../i18n';

type Props = {
	fieldId: string;
	invokeFuncTime?: number;
	labelText?: string;
	placeholderText?: string;
	configurationDate: Date;
};

const sameSportStyle = (selectedSport: string, sport: string) => (selectedSport === sport ? 'active' : '');

export const SearchBySportInputExtended: FunctionComponent<Props> = ({ invokeFuncTime, fieldId, placeholderText, configurationDate }) => {
	// Input search
	const [isInputFieldFocused, setIsInputFieldFocused] = useState(false);
	const [isInputFieldExtenderFocused, setIsInputFieldExtenderFocused] = useState(false);
	const [searchedText, setSearchedText] = useState('');
	const [areResultsLoaded, setAreResultsLoaded] = useState(true);
	// Sports related
	const availableSports = featuresService.checkFeatureDataField(FeatureTypes.MULTISPORT_WIDGET_CONFIGURATION).sports || [];
	const [selectedSport, setSelectedSport] = useState<string>(availableSports[0]);
	const [events, setEvents] = useState<MultisportEventModel[]>([]);
	// Extender display
	const shouldExtenderBeDisplayed = isInputFieldFocused || isInputFieldExtenderFocused;

	const onInputFocus = (isFocused: boolean) => {
		setIsInputFieldFocused(isFocused);

		if (isFocused) {
			setIsInputFieldExtenderFocused(true);
		}
	};

	const onSportSelect = (sport: string) => setSelectedSport(sport);

	const onSearchText = (searchedText: string) => {
		setSearchedText(searchedText);
		setIsInputFieldExtenderFocused(true);
	};

	useEffect(() => {
		if (searchedText && selectedSport && configurationDate) {
			setAreResultsLoaded(false);
			SportsConnectionsHttpService.getSportsDataForMultisport(searchedText, selectedSport, configurationDate)
				.then((response: any) => {
					const responseEventsData = (response && response.data && response.data.results) || [];
					const mappedData = responseEventsData.map((entity: any) => responseToMultisportEvent(entity));
					setEvents(mappedData);
				})
				.catch(() => toast.error(i18n.t('error_fetching_data')))
				.finally(() => setAreResultsLoaded(true));
		}
	}, [selectedSport, searchedText]);

	return (
		<div className='custom-search-input-extended-sport-wrapper'>
			<SearchInputDesign
				invokeFunc={onSearchText}
				invokeFuncTime={invokeFuncTime}
				fieldId={fieldId}
				invokeFocus={onInputFocus}
				placeholderText={placeholderText}
			/>
			{shouldExtenderBeDisplayed && (
				<div className='custom-input-extender-by-sport-wrapper' onMouseLeave={() => setIsInputFieldExtenderFocused(false)}>
					<div className='extender-by-sport'>
						{availableSports.map((sport) => (
							<span key={sport} className={`extender-by-sport-label ${sameSportStyle(selectedSport, sport)}`} onClick={() => onSportSelect(sport)}>
								{sport}
							</span>
						))}
					</div>
					<EventsSearchOutput events={events} areResultsLoaded={areResultsLoaded} />
				</div>
			)}
		</div>
	);
};

export default SearchBySportInputExtended;
