import Permission from '../permission/Permission';
import { ProfileJson } from './ProfileJson';
import ProfileBuilder from './ProfileBuilder';

export default class Profile {
	readonly isLoggedIn: boolean;
	readonly isAuthenticated: boolean;
	readonly areProjectResourcesReady: boolean;
	readonly selectedProjectId: string;
	readonly email: string;
	readonly name: string;
	readonly id: string;
	readonly isActive: boolean;
	readonly language: string;
	readonly organization: string;
	readonly lastLoginDate: string;
	readonly phone: string;
	readonly permissions: Permission[];

	private constructor(
		isLoggedIn: boolean,
		isAuthenticated: boolean,
		areProjectResourcesReady: boolean,
		selectedProjectId: string,
		email: string,
		name: string,
		id: string,
		isActive: boolean,
		language: string,
		organization: string,
		lastLoginDate: string,
		phone: string,
		permissions: Permission[],
	) {
		this.isLoggedIn = isLoggedIn;
		this.isAuthenticated = isAuthenticated;
		this.areProjectResourcesReady = areProjectResourcesReady;
		this.selectedProjectId = selectedProjectId;
		this.email = email;
		this.name = name;
		this.id = id;
		this.isActive = isActive;
		this.language = language;
		this.organization = organization;
		this.lastLoginDate = lastLoginDate;
		this.phone = phone;
		this.permissions = permissions;
	}

	toJSON(): ProfileJson {
		return {
			isLoggedIn: this.isLoggedIn,
			isAuthenticated: this.isAuthenticated,
			areProjectResourcesReady: this.areProjectResourcesReady,
			selectedProjectId: this.selectedProjectId,
			email: this.email,
			name: this.name,
			id: this.id,
			isActive: this.isActive,
			language: this.language,
			organization: this.organization,
			lastLoginDate: this.lastLoginDate,
			phone: this.phone,
			permissions: this.permissions,
		};
	}

	static fromJSON(json: ProfileJson): Profile {
		return new Profile(
			json.isLoggedIn,
			json.isAuthenticated,
			json.areProjectResourcesReady,
			json.selectedProjectId,
			json.email,
			json.name,
			json.id,
			json.isActive,
			json.language,
			json.organization,
			json.lastLoginDate,
			json.phone,
			json.permissions,
		);
	}

	static builder(profile?: Profile): ProfileBuilder {
		return new ProfileBuilder(profile);
	}
}
