import { CountryJson } from './country.json';
import CountryBuilder from './country.builder';

export default class Country {
	readonly id: string = '';
	readonly name: string = '';
	readonly flag: string = '';

	private constructor(id: string, name: string, flag: string) {
		this.id = id;
		this.name = name;
		this.flag = flag;
	}

	toJSON(): CountryJson {
		return {
			id: this.id,
			name: this.name,
			flag: this.flag,
		};
	}

	static fromJSON(json: CountryJson) {
		return new Country(json.id, json.name, json.flag);
	}

	static builder(country?: Country): CountryBuilder {
		return new CountryBuilder(country);
	}
}
