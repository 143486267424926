import React from 'react';
import { Label, InputGroup, Input, InputGroupAddon, Button } from 'reactstrap';
import { featuresService } from '../../../../../App';
import { FeatureTypes } from '../../../../../services/feature-service/features.enum';
import BlockModel from '../../models/block.model';
import './imago-image-select.scss';
import { remapImagoImagesFromResponse } from './helpers/imago-image-select.helper';
import ImagoImageModel from '../../blocks/content/imago-image-block/models/imago-image.model';
import ImagoHttpService from '../../../../../services/rest/imago/ImagoHttpService';
import ImageTypeSelect from './image-type-select/image-type-select.component';

export type Properties = {
	onSelect: (image: any, pageOffset: number, searchInput: string, selectedImageType: string) => void;
	t: any;
	toggleLoadingState: (loading: boolean) => any;
	selectedImageType: string;
	searchText?: string;
	searchInput: string;
	blocks: BlockModel[];
	onSelectedImagoImageChange: (image: ImagoImageModel) => any;
	pageOffset: number;
	shouldRequest: boolean;
};

export type State = {
	contentSearchInput: string;
	searchIn: string;
	isOpenDropdown: boolean;
	selectedImageType: string;
};

export default class ImagoImageSelect extends React.Component<Properties, State> {
	imagoImagesConfig = featuresService.getFeatureConfig(FeatureTypes.IMAGO_INTEGRATION);
	httpImago = new ImagoHttpService();

	constructor(props: Properties) {
		super(props);
		this.state = {
			contentSearchInput: props.searchText && props.searchText.length > 0 ? props.searchText : '',
			searchIn: props.searchInput && props.searchInput.length > 0 ? props.searchInput : '',
			isOpenDropdown: false,
			selectedImageType: props.selectedImageType ? props.selectedImageType : 'sport',
		};
	}

	componentDidMount() {
		const { searchText, pageOffset } = this.props;

		if (searchText && searchText.length > 0) {
			this.searchImagoImages(searchText, pageOffset);
		}
	}

	componentDidUpdate(prevProps: Readonly<Properties>) {
		const { contentSearchInput } = this.state;
		const { shouldRequest } = this.props;

		if (shouldRequest && prevProps.shouldRequest !== shouldRequest && contentSearchInput.length > 0) {
			this.searchImagoImages(contentSearchInput.trim(), this.props.pageOffset);
		}
	}

	onContentSearchInput = (event: any) => {
		this.onImagoImagesInputChangeState(event.target.value);

		if (event.target.value.length < 1) {
			this.props.onSelect([], 0, '', 'sport');
			this.props.onSelectedImagoImageChange(ImagoImageModel.builder().build());
		}
	};

	onContentSearch = (event: any) => {
		if (event.keyCode === 13 && this.state.contentSearchInput.trim().length > 0) {
			this.searchImagoImages(this.state.contentSearchInput, 0);
		}
	};

	clearSearch = () => {
		this.onImagoImagesInputChangeState('');
		this.props.onSelect(null, 0, '', '');
		this.props.onSelectedImagoImageChange(ImagoImageModel.builder().build());
	};

	searchImagoImages = (search: string, pageOffset: number) => {
		this.props.toggleLoadingState(true);
		const { selectedImageType } = this.state;

		this.httpImago
			.getImagoImages(search, pageOffset, selectedImageType)
			.then((response: any) => {
				this.props.toggleLoadingState(false);
				this.props.onSelect(remapImagoImagesFromResponse(response.data), pageOffset, search, selectedImageType);
			})
			.catch(this.props.toggleLoadingState(true));
	};

	toggleImagesTypeMenu = () => {
		this.setState((state: State) => {
			return { ...state, isOpenDropdown: !this.state.isOpenDropdown };
		});
	};

	changeSelectedImageType = (type: string) => {
		this.setState({ ...this.state, selectedImageType: type });
	};

	onImagoImagesInputChangeState = (input: string) => {
		this.setState({ ...this.state, contentSearchInput: input, searchIn: input });

		return input;
	};

	render() {
		const { t } = this.props;
		const { contentSearchInput, isOpenDropdown, selectedImageType } = this.state;

		return (
			<>
				<Label>{this.props.t('choose_image')}:</Label>
				<InputGroup className='position-relative d-flex flex-direction-row flex-nowrap'>
					<ImageTypeSelect
						t={t}
						changeSelectedImageType={this.changeSelectedImageType}
						isOpenDropdown={isOpenDropdown}
						toggleImagesTypeMenu={this.toggleImagesTypeMenu}
						selectedType={selectedImageType}
					/>
					<InputGroup className='position-relative d-flex'>
						<Input
							type='text'
							id='search-imago-image-input'
							name='search-input'
							className='rounded-0 pr-4'
							placeholder={t('content_search_placeholder')}
							onChange={this.onContentSearchInput}
							onKeyDown={this.onContentSearch}
							value={contentSearchInput}
						/>
						{contentSearchInput && contentSearchInput.length > 0 && (
							<div onClick={this.clearSearch} className='p-2 text-muted position-absolute' style={{ top: 0, right: 0 }}>
								<i className='fa fa-times' />
							</div>
						)}
					</InputGroup>
					<InputGroupAddon addonType='append'>
						<Button
							id='search-imago-images'
							type='button'
							color='primary'
							disabled={contentSearchInput.trim().length === 0}
							onClick={() => this.searchImagoImages(contentSearchInput, 0)}
						>
							<i className={'fa fa-search'}>&nbsp;</i>
							{t('search')}
						</Button>
					</InputGroupAddon>
				</InputGroup>
			</>
		);
	}
}
